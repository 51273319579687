import PostAnalytics from "./components/postAnalytics";
import OverViewSection from "./components/overviewSection";
import DashboardFunctions from "../../../dashboardFunctions";
import CommonFunctions from "pages/Monitors/Components/commonFunctions";
import SnackBar from "components/snackBar";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import PopupModal from "components/popupModal";
import { useEffect } from "react";

const AccountBody = (props) => {
  const intl = useIntl();
  const { getIcons } = DashboardFunctions();
  let { dashboardDynamicData, widgetsName, monitorType, isPublicAccount } =
    props;
  const metricsValues = dashboardDynamicData?.metrics_values;
  const {
    updateAnalysis,
    handleCloseSnackBar,
    showAnalyzeTweetSnackBar,
    analyzeTweetSnackBar,
    stats,
    handleCloseCreateModal,
    modalBody,
  } = CommonFunctions();

  return (
    <>
      <OverViewSection
        widgetsName={widgetsName}
        getIcons={getIcons}
        metricsValues={metricsValues}
        monitorType={monitorType}
        isPublicAccount={isPublicAccount}
        {...props}
      />
      <PostAnalytics
        widgetsName={widgetsName}
        getIcons={getIcons}
        monitorType={monitorType}
        metricsValues={metricsValues}
        updateAnalysis={updateAnalysis}
        isPublicAccount={isPublicAccount}
        {...props}
      />

      {showAnalyzeTweetSnackBar && (
        <SnackBar
          open={true}
          handleClose={handleCloseSnackBar}
          title={CheckValueLocale(analyzeTweetSnackBar.title, "", {}, intl)}
          message={CheckValueLocale(analyzeTweetSnackBar.message, "", {}, intl)}
          severity={analyzeTweetSnackBar.severity}
        />
      )}
      <PopupModal
        title={CheckValueLocale("single_tweet_analysis", "", {}, intl)}
        body={modalBody}
        open={stats?.openCreateModal}
        close={handleCloseCreateModal}
        warning="warning"
        classeName="create-historical-popup"
        addClasses="create-single-post-analysis-modal"
      />
    </>
  );
};

export default AccountBody;
