import { api } from "./newServices";

const getAllAgentStatuses = () => {
  return api.get("api/v6/engagements/agent/statuses");
};

const updateAgentStatus = (queryData) => {
  return api.post("api/v6/user/agent_info", queryData);
};

const getCurrentAgentStatus = () => {
  return api.get("api/v6/user/agent_info");
};

export const agentStatusServices = {
  getAllAgentStatuses,
  updateAgentStatus,
  getCurrentAgentStatus,
};
