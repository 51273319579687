import { Box } from "@mui/material";
import { useState } from "react";
import { MediaPreviewer } from "pages/engagements/components/mediaPreviewer/mediaPreviewer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import NewsBlogPlaceholder from "images/placeholders/placeholder_nb.svg";

export const handleClassNameForMediaPreview = (postMediaLength) => {
  return `post-image-box ${
    postMediaLength == 1
      ? "one-img"
      : postMediaLength == 2
        ? "two-img"
        : postMediaLength == 3
          ? "three-img"
          : postMediaLength >= 4
            ? "four-img"
            : ""
  }`;
};

const DisplayMediaCard = ({ mediaUrls, tweetText, monitorDataSource }) => {
  const [showMediaPreviewer, setShowMediaPreviewer] = useState(false);
  const [media, setMedia] = useState([]);
  const [defaultMediaIndex, setDefaultMediaIndex] = useState(0);

  //Handle Media Click [ imgs - videos ]
  const handleMediaClick = (mediaArray, index) => {
    if (mediaArray?.length > 0) {
      setShowMediaPreviewer(true);
      const formattedMedia = mediaArray?.map((item) => ({
        url: item?.url,
        type:
          item?.type === "video" || item?.type === "animated_gif"
            ? "video"
            : "image",
      }));
      setMedia(formattedMedia);
    }
    setDefaultMediaIndex(index);
  };

  const handleImageError = (event) => {
    event.target.src = NewsBlogPlaceholder;
    event.target.alt = "placeholder-image";
  };

  return (
    <Box
      className={`post-image-box ${handleClassNameForMediaPreview(
        Object.keys(mediaUrls)?.length,
      )}`}
    >
      {Object.keys(mediaUrls)
        ?.slice(0, 4)
        ?.map((key, index) => {
          const image = mediaUrls[key];
          const mediaArray = Object.values(mediaUrls);
          return (
            <Box
              key={image?.id}
              className="image-container-card-body"
              onClick={() => {
                handleMediaClick(mediaArray, index);
              }}
            >
              {image?.type === "video" || image?.type === "animated_gif" ? (
                <Box component={"span"} className="video-icon">
                  <FontAwesomeIcon icon={faPlay} />
                </Box>
              ) : null}
              <Box
                component={"img"}
                onClick={() => {
                  handleMediaClick(mediaArray, index);
                }}
                src={image?.url}
                alt="media-image"
                onError={handleImageError}
              />
              {index === 3 && mediaUrls?.length > 4 ? (
                <Box className="image-container-card-body-overlay">
                  +{mediaUrls?.length - 4}
                </Box>
              ) : null}
            </Box>
          );
        })}

      {/* Hanlde Media previewer component */}
      {showMediaPreviewer ? (
        <MediaPreviewer
          media={media}
          postText={tweetText}
          showMediaPreviewer={showMediaPreviewer}
          setShowMediaPreviewer={setShowMediaPreviewer}
          defaultIndex={defaultMediaIndex} // default is 0
          monitorDataSource={monitorDataSource}
        />
      ) : null}
    </Box>
  );
};

export default DisplayMediaCard;
