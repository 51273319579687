import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { sidebarOptionsMapper } from "shared/customDateRangePicker/config/sidebarOptions";

const syncUnixToUTC = (unix, type, hasTime) => {
  if (hasTime) return moment.unix(unix).utc()?.unix();
  if (type === "start") {
    return moment.unix(unix).utc()?.startOf("day")?.unix();
  }
  return moment.unix(unix).utc()?.endOf("day")?.unix();
};

const initialState = {
  startDateUnix: syncUnixToUTC(moment().subtract(6, "d").unix(), "start"),
  endDateUnix: syncUnixToUTC(moment().unix(), "end"),
  startDateUTC: moment().subtract(6, "d").unix(),
  endDateUTC: moment().unix(),
  isInitialCall: true,
  startTime: null, // hours:minutes:seconds
  endTime: null,
  startDateUnixToDisplay: moment().subtract(6, "d").unix(),
  endDateUnixToDisplay: moment().unix(),
  startDate: null,
  endDate: null,
  filterFields: [],
  filterParams: {
    gender_filter: [],
    main_dialect_filter: [],
    sub_dialect_filter: [],
    monitor_list: [],
    sentiment_filter: [],
    sub_themes_filter: [],
    themes_filter: [],
    waiting_filter: [],
    cities_filter: [],
    countries_filter: [],
    languages_filter: [],
    categories: [],
    interaction_type: [],
    dm_list: [],
    channels: [],
    locations: [],
    with_comment: [],
    manual_topics: [],
    call_status: [],
    keyword: [],
    question_status: [],
    parent_id: "",
  },
  filterDropDownParams: {
    gender_filter: [],
    main_dialect_filter: [],
    sub_dialect_filter: [],
    monitor_list: [],
    sentiment_filter: [],
    sub_themes_filter: [],
    themes_filter: [],
    waiting_filter: [],
    cities_filter: [],
    countries_filter: [],
    languages_filter: [],
    categories: [],
    interaction_type: [],
    dm_list: [],
    channels: [],
    locations: [],
    with_comment: [],
    manual_topics: [],
    call_status: [],
    keyword: [],
    question_status: [],
    parent_id: "",
  },
  filterDropDownParamsCopy: {
    gender_filter: [],
    main_dialect_filter: [],
    sub_dialect_filter: [],
    monitor_list: [],
    sentiment_filter: [],
    sub_themes_filter: [],
    themes_filter: [],
    waiting_filter: [],
    cities_filter: [],
    countries_filter: [],
    languages_filter: [],
    categories: [],
    interaction_type: [],
    dm_list: [],
    channels: [],
    locations: [],
    with_comment: [],
    manual_topics: [],
    call_status: [],
    keyword: [],
    question_status: [],
    parent_id: "",
  },
  filterDropDown: {
    gender_filter: [],
    main_dialect_filter: [],
    sub_dialect_filter: [],
    monitor_list: [],
    sentiment_filter: [],
    sub_themes_filter: [],
    themes_filter: [],
    waiting_filter: [],
    cities_filter: [],
    countries_filter: [],
    languages_filter: [],
    categories: [],
    interaction_type: [],
    dm_list: [],
    channels: [],
    locations: [],
    with_comment: [],
    manual_topics: [],
    call_status: [],
    keyword: [],
    question_status: [],
    parent_id: "",
  },
  filterDropDownApplyFilter: {
    gender_filter: [],
    main_dialect_filter: [],
    sub_dialect_filter: [],
    monitor_list: [],
    sentiment_filter: [],
    sub_themes_filter: [],
    themes_filter: [],
    waiting_filter: [],
    cities_filter: [],
    countries_filter: [],
    languages_filter: [],
    categories: [],
    interaction_type: [],
    dm_list: [],
    channels: [],
    locations: [],
    with_comment: [],
    manual_topics: [],
    call_status: [],
    keyword: [],
    question_status: [],
    parent_id: "",
  },
  pickerState: sidebarOptionsMapper.lastsevendays,
  showDeleteFilterBtn: false,
  dataSource: "",
  filterParamsCount: 0,
  applicableFilters: {},
  themesOptions: [],
};

const channelAnalyticsSlice = createSlice({
  name: "channelAnalytics",
  initialState,
  reducers: {
    clear: (state, action) => {
      state.filterParams = {
        gender_filter: [],
        main_dialect_filter: [],
        sub_dialect_filter: [],
        monitor_list: [],
        sentiment_filter: [],
        sub_themes_filter: [],
        themes_filter: [],
        waiting_filter: [],
        cities_filter: [],
        countries_filter: [],
        languages_filter: [],
        categories: [],
        interaction_type: [],
        dm_list: [],
        channels: [],
        locations: [],
        with_comment: [],
        manual_topics: [],
        call_status: [],
        keyword: [],
        question_status: [],
        parent_id: "",
      };
      state.filterDropDownParams = {
        gender_filter: [],
        main_dialect_filter: [],
        sub_dialect_filter: [],
        monitor_list: [],
        sentiment_filter: [],
        sub_themes_filter: [],
        themes_filter: [],
        waiting_filter: [],
        cities_filter: [],
        countries_filter: [],
        languages_filter: [],
        categories: [],
        interaction_type: [],
        dm_list: [],
        channels: [],
        locations: [],
        with_comment: [],
        manual_topics: [],
        call_status: [],
        keyword: [],
        question_status: [],
        parent_id: "",
      };
      state.filterDropDownParamsCopy = {
        gender_filter: [],
        main_dialect_filter: [],
        sub_dialect_filter: [],
        monitor_list: [],
        sentiment_filter: [],
        sub_themes_filter: [],
        themes_filter: [],
        waiting_filter: [],
        cities_filter: [],
        countries_filter: [],
        languages_filter: [],
        categories: [],
        interaction_type: [],
        dm_list: [],
        channels: [],
        locations: [],
        with_comment: [],
        manual_topics: [],
        call_status: [],
        keyword: [],
        question_status: [],
        parent_id: "",
      };
      state.filterDropDown = {
        gender_filter: [],
        main_dialect_filter: [],
        sub_dialect_filter: [],
        monitor_list: [],
        sentiment_filter: [],
        sub_themes_filter: [],
        themes_filter: [],
        waiting_filter: [],
        cities_filter: [],
        countries_filter: [],
        languages_filter: [],
        categories: [],
        interaction_type: [],
        dm_list: [],
        channels: [],
        locations: [],
        with_comment: [],
        manual_topics: [],
        call_status: [],
        keyword: [],
        question_status: [],
        parent_id: "",
      };
      state.filterDropDownApplyFilter = {
        gender_filter: [],
        main_dialect_filter: [],
        sub_dialect_filter: [],
        monitor_list: [],
        sentiment_filter: [],
        sub_themes_filter: [],
        themes_filter: [],
        waiting_filter: [],
        cities_filter: [],
        countries_filter: [],
        languages_filter: [],
        categories: [],
        interaction_type: [],
        dm_list: [],
        channels: [],
        locations: [],
        with_comment: [],
        manual_topics: [],
        call_status: [],
        keyword: [],
        question_status: [],
        parent_id: "",
      };
      if (action?.payload !== "clearingFilter") {
        state.startDateUnix = syncUnixToUTC(
          moment().subtract(6, "d").unix(),
          "start",
        );
        state.endDateUnix = syncUnixToUTC(moment().unix(), "end");
        state.pickerState = sidebarOptionsMapper.lastsevendays;
        state.startTime = null;
        state.endTime = null;
        state.startDateUTC = moment().subtract(6, "d").unix();
        state.endDateUTC = moment().unix();
      }
      state.showDeleteFilterBtn = false;
      state.filterParamsCount = 0;
    },
    filters: (state, action) => {
      state.filterParams = action?.payload;
    },
    filtersDropDown: (state, action) => {
      state.filterDropDownParams = action?.payload;
    },
    filtersDropDownCopy: (state, action) => {
      state.filterDropDownParamsCopy = action?.payload;
    },
    filtersDropDownApplyFilter: (state, action) => {
      state.filterDropDownApplyFilter = action?.payload;
    },
    filtersDropDownToDisplay: (state, action) => {
      state.filterDropDown = action?.payload;
    },
    filtersCount: (state, action) => {
      state.filterParamsCount = action?.payload;
    },
    reduxApplicableFilters: (state, action) => {
      state.applicableFilters = action?.payload;
    },
    reduxDatesUnixCA: (state, action) => {
      state.startDateUnix = action?.payload?.startDate;
      state.endDateUnix = action?.payload?.endDate;
    },
    reduxDatesUnixToDisplayCA: (state, action) => {
      state.startDateUnixToDisplay = action?.payload?.startDate;
      state.endDateUnixToDisplay = action?.payload?.endDate;
    },
    reduxDatesCA: (state, action) => {
      state.startDate = action?.payload?.startCustomDate;
      state.endDate = action?.payload?.endCustomDate;
    },
    reduxDatePickerStateCA: (state, action) => {
      state.pickerState = action?.payload;
    },
    reduxDatasource: (state, action) => {
      state.dataSource = action?.payload;
      state.filterParams = {};
      state.showDeleteFilterBtn = false;
    },
    reduxThemesOptions: (state, action) => {
      state.themesOptions = action?.payload;
    },
    reduxSetTimes: (state, action) => {
      state.startTime = action?.payload?.startTime;
      state.endTime = action?.payload?.endTime;
    },
    reduxSetDatesUTC: (state, action) => {
      state.startDateUTC = action?.payload?.startDate;
      state.endDateUTC = action?.payload?.endDate;
    },
    resetInitialCall: (state, action) => {
      state.isInitialCall = action?.payload || false;
    },
  },
});

export default channelAnalyticsSlice.reducer;
export const {
  clear,
  filtersFields,
  filters,
  filtersDropDown,
  activeFilters,
  filtersCount,
  reduxApplicableFilters,
  reduxDatesCA,
  reduxDatePickerStateCA,
  reduxDatesUnixCA,
  reduxDatesUnixToDisplayCA,
  reduxDatasource,
  filtersDropDownToDisplay,
  filtersDropDownCopy,
  filtersDropDownApplyFilter,
  reduxThemesOptions,
  reduxSetTimes,
  reduxSetDatesUTC,
  resetInitialCall,
} = channelAnalyticsSlice.actions;
