import { Alert, Box, Grid, Tooltip } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import AiAgentsFields from "../aiAgentsFields";
import SurveyField from "./surveyField";
import { endTheConversationAfterOptions } from "../../../../consts/endConversationAfterOptions";
import LucButton from "shared/lucButton/lucButton";
import SurveyDemo from "pages/engagements/components/setup-flow/edit/egagementSurvey/SurveyDemo";

const EndConversation = ({
  formik,
  aiAgentshandleChange,
  customHandleChange,
  isFormDisabled,
}) => {
  const intl = useIntl();

  var activeProducts = JSON.parse(window?.localStorage?.activeProducts || null);
  let surveyActive = activeProducts?.find(
    (product) => product?.name == "SURVEY",
  );

  return (
    <Box className="ai-agents-form-box-wrapper">
      <Box className="ai-agents-form-box-title" component="h4">
        {CheckValueLocale("end_the_conversation", "", {}, intl)}
      </Box>
      <Box className="ai-agents-form-box">
        <Grid container spacing={3}>
          <Grid item xs={5}>
            <Box className="ai-agents-form-box-description" component="p">
              {CheckValueLocale(
                "end_ai_agent_conversation_description",
                "",
                {},
                intl,
              )}
            </Box>
          </Grid>
          <Grid item xs={7}>
            <Box className="ai-agent-end-conversation-spacer">
              <AiAgentsFields
                disabled={isFormDisabled}
                type="select"
                id="ai-agent-end-conversation-select"
                selectOptions={endTheConversationAfterOptions(intl)}
                label={CheckValueLocale(
                  "end_the_conversation_after",
                  "",
                  {},
                  intl,
                )}
                name="conversation_end_time"
                value={formik?.values?.conversation_end_time}
                onChange={aiAgentshandleChange}
                error={!!formik?.errors?.conversation_end_time}
                onBlur={formik?.handleBlur}
                helperText={CheckValueLocale(
                  formik?.errors?.conversation_end_time,
                  "",
                  {},
                  intl,
                )}
                required
              />
            </Box>

            <Box className="ai-agent-end-conversation-spacer">
              <AiAgentsFields
                disabled={isFormDisabled}
                type="textarea"
                id="end-conversation-auto-reply"
                label={CheckValueLocale(
                  "ai_agent_end_conversation_auto_reply_label",
                  "",
                  {},
                  intl,
                )}
                labelHint={CheckValueLocale("option", "", {}, intl)}
                placeholder={CheckValueLocale("write_here", "", {}, intl)}
                name="auto_reply"
                value={formik?.values?.auto_reply}
                onChange={aiAgentshandleChange}
                error={!!formik?.errors?.auto_reply}
                onBlur={formik?.handleBlur}
                helperText={CheckValueLocale(
                  formik?.errors?.auto_reply,
                  "",
                  {},
                  intl,
                )}
              />
            </Box>

            <Box className="ai-agent-end-conversation-spacer">
              <AiAgentsFields
                disabled={isFormDisabled}
                type="select"
                id="ai-agent-auto-send-survey-select"
                label={CheckValueLocale("auto_send_survey", "", {}, intl)}
                placeholder={CheckValueLocale("write_here", "", {}, intl)}
                labelHint={CheckValueLocale("option", "", {}, intl)}
                name="auto_send_survey"
                value={formik?.values?.auto_send_survey}
                onChange={aiAgentshandleChange}
                error={!!formik?.errors?.auto_send_survey}
                onBlur={formik?.handleBlur}
                selectOptions={[
                  {
                    id: "csat",
                    name: CheckValueLocale("send_in_chat", "", {}, intl),
                  },
                  {
                    id: "survey",
                    name: CheckValueLocale("share_servy_link", "", {}, intl),
                  },
                ]}
                helperText={CheckValueLocale(
                  formik?.errors?.auto_send_survey,
                  "",
                  {},
                  intl,
                )}
                clearable
              />
            </Box>
            {formik?.values?.auto_send_survey == "survey" ? (
              <Box className="ai-agent-end-conversation-spacer">
                {surveyActive ? (
                  <SurveyField
                    disabled={isFormDisabled}
                    formik={formik}
                    surveyActive={surveyActive}
                    customHandleChange={customHandleChange}
                  />
                ) : (
                  <SurveyDemo />
                )}
              </Box>
            ) : formik?.values?.auto_send_survey == "csat" ? (
              <Box className="ai-agent-end-conversation-spacer">
                <Alert
                  severity="warning"
                  className="ai-agent-csat-warning"
                  action={
                    <Tooltip
                      title={CheckValueLocale(
                        !handlelUserRoles("ENGAGEMENTS", "ENGAGEMENT_SETTINGS")
                          ? "view_settings_permission"
                          : "",
                        "",
                        {},
                        intl,
                      )}
                      arrow
                      placement="top"
                    >
                      <Box>
                        <LucButton
                          variant="flat"
                          size="small"
                          type="secondary"
                          disabled={
                            !handlelUserRoles(
                              "ENGAGEMENTS",
                              "ENGAGEMENT_SETTINGS",
                            )
                          }
                          onClick={() => {
                            window.open("/engagements/edit", "_blank");
                          }}
                          className="ai-agent-csat-warning-btn"
                          id="go-to-engag-settings"
                        >
                          {CheckValueLocale("view_settings", "", {}, intl)}
                        </LucButton>
                      </Box>
                    </Tooltip>
                  }
                >
                  {CheckValueLocale("ai_agent_csat_warning", "", {}, intl)}
                </Alert>
              </Box>
            ) : null}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default EndConversation;
