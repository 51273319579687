import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import MonitorList, {
  getMonitorListTableHeader,
} from "./components/monitorList/monitorList";
import MonitorsListHeader from "../components/monitorListHeader/monitorsListHeader";
import { Box } from "@mui/system";
import Snackbar from "components/snackBar";
import { useIntl } from "react-intl";
import { useNavigate, useLocation } from "react-router-dom";
import "./monitorSocial.scss";
import { CheckValueLocale } from "utils/helpers";
import { useDispatch } from "react-redux";
import { clear } from "utils/redux/features/SocialListening/socialListeningSlice.js";
import { useSortableTable } from "hooks/useSortableTable";
import BenchmarkController from "services/controllers/benchmarkController";
import { CustomPagination } from "components/CustomPagination/CustomPagination";
import NotFound from "pages/not-found/NotFoundPage";
import { useGetMonitors } from "../hooks/useGetMonitors";

const MonitorListPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const pageUrlParam = searchParams?.get("page");
  const sortUrlParam = searchParams?.get("sort_by");
  const sortOrderUrlParam = searchParams?.get("order_by");
  const searchUrlParam = searchParams?.get("q");
  const needsAttentionData = location?.state?.needsAttentionData;

  const [companies, setCompanies] = useState([]);
  const [success, setSuccess] = useState(null);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [searchActivated, setSearchActivated] = useState(false);
  const [searchText, setSearchText] = useState(searchUrlParam || "");
  const [isBenchmarkPolicy, setIsBenchmarkPolicy] = useState(false);
  const isLucidyaAccount = window.localStorage.is_lucidya_account;

  const setUrlParams = (key, value) => {
    searchParams?.set(key, value);
  };

  const onSortChangeCallback = (newSortColumn, newSortDirection) => {
    setUrlParams("page", 1);
    setUrlParams("sort_by", newSortColumn);
    setUrlParams("order_by", newSortDirection);
    navigate({ search: searchParams.toString(), needsAttentionData });
  };

  const handlePageChange = (event, value) => {
    setUrlParams("page", value);
    navigate({ search: searchParams.toString() });
  };

  const { columns: tableHead } = useSortableTable({
    initialColumns: getMonitorListTableHeader(intl),
    onSortChangeCallback,
    defaultSelectedColumn: sortUrlParam || "created_at",
    defaultDirection: sortOrderUrlParam || "desc",
  });

  useEffect(() => {
    let language = window.localStorage.lang;
    var arabicAlphabetDigits =
      /[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufc3f]|[\ufe70-\ufefc]|[\u0200]|[\u00A0]/g;

    if (location?.state?.monitorName) {
      setSuccess(true);
      setSuccessMsg(
        language !== "ar"
          ? CheckValueLocale("your_new_monitor", "", {}, intl) +
              " " +
              `${location?.state?.monitorName}` +
              " " +
              CheckValueLocale("was_created_successfully", "", {}, intl)
          : arabicAlphabetDigits?.test(location?.state?.monitorName)
            ? CheckValueLocale("your_new_monitor", "", {}, intl) +
              " " +
              `${location?.state?.monitorName}` +
              " " +
              CheckValueLocale("was_created_successfully", "", {}, intl)
            : CheckValueLocale("was_created_successfully", "", {}, intl) +
              " " +
              `${location?.state?.monitorName}` +
              " " +
              CheckValueLocale("your_new_monitor", "", {}, intl),
      );

      setTimeout(() => {
        setSuccess(null);
        setSuccessMsg("");
      }, 10000);
    } else if (location?.state?.fail) {
      setSuccess(false);
      setErrorMsg(CheckValueLocale(location?.state?.fail, "", {}, intl));
      setTimeout(() => {
        setSuccess(null);
        setErrorMsg("");
      }, 10000);
    }
  }, [location]);

  useEffect(() => {
    if (
      localStorage.edit_monitor === "true" &&
      window.location.pathname === "/social/monitor_list"
    ) {
      setSuccess(true);
      setSuccessMsg("monitor_edited");
      setTimeout(() => {
        setSuccess(null);
        window.localStorage.setItem("edit_monitor", false);
      }, 10000);
    }
  }, [location]);

  useEffect(() => {
    dispatch(clear());
  }, []);

  const queryParams = {
    params: {
      page: pageUrlParam || 1,
      sort_by: sortUrlParam || "created_at",
      order_by: sortOrderUrlParam || "desc",
      search: searchUrlParam || "",
      product_id: window.localStorage.getItem("sm_id"),
      redirected_monitor: needsAttentionData?.redirected_monitor || undefined,
    },
  };

  const {
    data: newMonitorData,
    isPending: isMonitorsListLoading,
    isError,
    error,
  } = useGetMonitors(queryParams);

  const monitors = newMonitorData?.data || [];
  const companyCanCreateMonitor =
    newMonitorData?.company_can_create_monitor || false;
  const pagination = newMonitorData?.pagination || 1;
  const isInvalidURL = ["WRONG_PAGE_PARAMS", "INVALID_SORTING_KEY"].includes(
    error?.response?.data?.exception?.message,
  );

  const [monitorSnackBar, setMonitorSnackBar] = useState({
    message: "",
    severity: "",
    title: "",
    openSnackBar: false,
  });

  useEffect(() => {
    if (isError) {
      setMonitorSnackBar({
        message: "try_again_error_message",
        severity: "error",
        title: "failed_error_message",
        openSnackBar: true,
      });
    }
  }, [isError]);

  const handleCloseMonitorSnackbar = () => {
    setMonitorSnackBar({
      openSnackBar: false,
    });
  };

  //This part is related to Edit monitor settings  Page in case of twitter monitor if user selected (save and compute)
  //user gets directed to monitor list page and Success snack bar appears
  const [showMonitorRecomputedSnackBar, setShowMonitorRecomputedSnackBar] =
    useState(false);
  const handleCloseMonitorRecomputedSnackBar = () => {
    setShowMonitorRecomputedSnackBar(false);
    window.localStorage.setItem("monitorRecomputed", false);
  };
  useEffect(() => {
    setShowMonitorRecomputedSnackBar(window.localStorage.monitorRecomputed);
    // }
  }, [window.localStorage.monitorRecomputed]);
  // ________________________________________________________________

  //benchmark policy popup
  const getBenchmarkPolicy = () => {
    BenchmarkController.getApplyPolicy().then((result) => {
      if (result?.data?.length > 0) {
        result?.data?.map((item) => {
          if (item?.policy_type == "benchmark_v4") {
            setIsBenchmarkPolicy(false);
          }
        });
      } else {
        setIsBenchmarkPolicy(true);
      }
    });
  };

  useEffect(() => {
    getBenchmarkPolicy();
  }, []);

  return isInvalidURL ? (
    <NotFound />
  ) : (
    <Container maxWidth="xl">
      <Box>
        <MonitorsListHeader
          totalMonitors={pagination?.count}
          monitors={monitors}
          searchText={searchText}
          setSearchActivated={setSearchActivated}
          setSearchText={setSearchText}
          companyCanCreateMonitor={companyCanCreateMonitor}
          isSortableLoading={isMonitorsListLoading}
          searchUrlParam={searchUrlParam}
        />
        <MonitorList
          page={pageUrlParam || 1}
          monitors={monitors}
          setSuccess={setSuccess}
          setSuccessMsg={setSuccessMsg}
          setErrorMsg={setErrorMsg}
          getMonitors={() => {}}
          setIsSortableLoading={() => {}}
          searchActivated={searchActivated}
          searchText={searchText}
          tableHead={tableHead}
          isSortableLoading={isMonitorsListLoading}
          setIsBenchmarkPolicy={setIsBenchmarkPolicy}
          isBenchmarkPolicy={isBenchmarkPolicy}
        />
        {pagination?.pages > 1 && !isMonitorsListLoading ? (
          <CustomPagination
            onChange={handlePageChange}
            page={pageUrlParam || 1}
            pagination={pagination}
          />
        ) : (
          <div className="pagination-one-page"></div>
        )}
      </Box>
      {success != null ? (
        <Snackbar
          lang={window.localStorage.lang}
          open={success != null ? true : false}
          handleClose={() => {
            setSuccess(null);
          }}
          severity={success ? "success" : "error"}
          message={success ? successMsg : errorMsg}
          alertStyle={
            !companyCanCreateMonitor
              ? { width: "100%", marginTop: "85px" }
              : { width: "100%" }
          }
        />
      ) : (
        ""
      )}
      {showMonitorRecomputedSnackBar == "true" && (
        <Snackbar
          open={showMonitorRecomputedSnackBar}
          handleClose={handleCloseMonitorRecomputedSnackBar}
          severity={"success"}
          message={CheckValueLocale(
            "save_monitor_settings_compute",
            "",
            {},
            intl,
          )}
        />
      )}
      <Snackbar
        open={monitorSnackBar?.openSnackBar}
        severity={monitorSnackBar?.severity}
        message={CheckValueLocale(monitorSnackBar?.message, "", {}, intl)}
        title={CheckValueLocale(monitorSnackBar?.title, "", {}, intl)}
        handleClose={handleCloseMonitorSnackbar}
      />
    </Container>
  );
};

export default MonitorListPage;
