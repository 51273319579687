export const handleCommentsViewResponse = (response, dispatch) => {
  if (response.eventName === "Twitter__PostsPage__top_posts_comments") {
    dispatch({
      type: "SET_TOP_POSTS",
      payload: response.eventData.top_posts,
    });

    dispatch({
      type: "SET_TOP_POSTS_COUNT",
      payload: response.eventData.top_posts_count,
    });

    dispatch({
      type: "SET_TOP_POSTS_PRELOADER",
      payload: [response.monitor_id],
    });
  } else {
    return null;
  }
};
