// src/components/CustomCalendarContainer.jsx
import SidebarViewer from "./sidebar/sidebarViewer";
import MonthPicker from "./pickers/MonthPicker";
import YearPicker from "./pickers/YearPicker";
import TimeInputs from "./timeInputs/TimeInputs";
import { memo } from "react";

function CustomCalendarContainer({
  className = "",
  children,

  // ephemeral states
  tempSidebarLabel,
  onSidebarClick,
  tempStartTime,
  tempEndTime,
  setTempStartTime,
  setTempEndTime,
  handleApplyClick,

  // month/year pickers
  isMonthPickerOpen,
  isYearPickerOpen,
  openMonthPicker,
  openYearPicker,
  handleMonthSelect,
  handleYearSelect,
  yearRange,
  handleYearRangeChange,
  selectedMoment,
  maxDate,
  minDate,
  // for i18n & config
  sidebarOptions,
  intl,
  // Conditionaly show the time picker
  hasTimePicker,
  hasSidebarOnly,
}) {
  return (
    <div
      className={`custom-datepicker-container ${hasSidebarOnly ? "sidebar-only" : ""} ${className}`}
    >
      {hasSidebarOnly ? (
        <SidebarViewer
          sidebarOptions={sidebarOptions}
          tempSidebarLabel={tempSidebarLabel}
          onSidebarClick={onSidebarClick}
          intl={intl}
        />
      ) : (
        <>
          <SidebarViewer
            sidebarOptions={sidebarOptions}
            tempSidebarLabel={tempSidebarLabel}
            onSidebarClick={onSidebarClick}
            intl={intl}
          />
          <div className="datepicker-wrapper">
            {isMonthPickerOpen ? (
              <MonthPicker
                selectedMoment={selectedMoment}
                onYearClick={openYearPicker}
                onMonthSelect={handleMonthSelect}
                intl={intl}
                maxDate={maxDate}
                minDate={minDate}
              />
            ) : null}

            {isYearPickerOpen ? (
              <YearPicker
                selectedMoment={selectedMoment}
                yearRange={yearRange}
                handleYearRangeChange={handleYearRangeChange}
                onMonthClick={openMonthPicker}
                onYearSelect={handleYearSelect}
                maxDate={maxDate}
                minDate={minDate}
              />
            ) : null}

            {!isMonthPickerOpen && !isYearPickerOpen ? (
              <>
                {children /* The normal day grid from react-datepicker */}
                <TimeInputs
                  tempStartTime={tempStartTime}
                  tempEndTime={tempEndTime}
                  setTempStartTime={setTempStartTime}
                  setTempEndTime={setTempEndTime}
                  onApply={handleApplyClick}
                  intl={intl}
                  hasTimePicker={hasTimePicker}
                />
              </>
            ) : null}
          </div>
        </>
      )}
    </div>
  );
}

export default memo(CustomCalendarContainer);
