import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import { Box, Pagination } from "@mui/material";
import { useSortableTable } from "hooks/useSortableTable";
import { handlelUserRoles } from "utils/helpers";
import CircularLoading from "components/circularProgress";
import UsersAccountsData, {
  getUsersAccountsTableHeader,
} from "./usersAccountsData";
import { useCompanyApps } from "../../hooks/useCompanyApps";
import { useUsersAccounts } from "../../hooks/useUsersAccounts";

const UsersAccountsTab = () => {
  const intl = useIntl();
  const location = useLocation();
  const [pageNum, setPageNum] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const [usersAccounts, setUsersAccounts] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [searchText, setSearchText] = useState("");
  const [isNeedsAttentionData, setIsNeedsAttentionData] = useState(false);

  const { data: companyAppsResponse } = useCompanyApps();
  const apps = companyAppsResponse?.data?.data || [];

  const onSortChangeCallback = () => {
    setIsSortableLoading(true);
    setPageNum(1);
  };
  const {
    columns: tableHead,
    sortColumn,
    sortDirection,
    isSortableLoading,
    setIsSortableLoading,
  } = useSortableTable({
    initialColumns: getUsersAccountsTableHeader(intl),
    onSortChangeCallback,
    defaultSelectedColumn: "configuration_date",
    defaultDirection: "desc",
  });

  const {
    data: userAccountsResponse,
    isPending: isLoading,
    isError: isErroruserAccount,
  } = useUsersAccounts(
    sortColumn,
    sortDirection,
    pageNum,
    searchInput,
    location?.state?.needsAttentionData?.created_by_id,
    location?.state?.needsAttentionData?.data_source,
  );

  useEffect(() => {
    if (isErroruserAccount) {
      setUsersAccounts([]);
      setPaginationData({});
    } else if (!!userAccountsResponse) {
      setUsersAccounts(userAccountsResponse?.users_social_accounts);
      setPaginationData(userAccountsResponse?.pagination);
      // Set flag for empty response in case of Needs Attentin redirection
      if (
        location?.state?.needsAttentionData &&
        location?.state?.needsAttentionData?.created_by_id &&
        !userAccountsResponse?.users_social_accounts?.length
      ) {
        setIsNeedsAttentionData(true);
      }
    }
    if (!isLoading) setIsSortableLoading(isLoading);
  }, [userAccountsResponse, isErroruserAccount]);

  useEffect(() => {
    if (
      location?.state?.needsAttentionData?.created_by_id &&
      handlelUserRoles("GENERAL", "VIEW_OTHERS_AUTH")
    ) {
      setSearchInput(location?.state?.needsAttentionData?.search_account_name);
      setPageNum(1);
    }
  }, [location]);

  const handleChangePagination = (e, value) => setPageNum(value);

  return (
    <Box id="users-accounts-tab">
      {isLoading && !isSortableLoading ? (
        <CircularLoading />
      ) : (
        <Box>
          <UsersAccountsData
            usersAccounts={usersAccounts}
            tableHead={tableHead}
            isSortableLoading={isSortableLoading}
            apps={apps}
            searchText={searchText}
            setSearchText={setSearchText}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            page={pageNum || 1}
            setPage={setPageNum}
            isNeedsAttentionData={isNeedsAttentionData}
          />
          {usersAccounts?.length && paginationData?.pages > 1 ? (
            <Pagination
              className="pagination-monitor-list settings-page-content-pagination"
              variant="outlined"
              count={paginationData?.pages}
              page={pageNum || 1}
              onChange={handleChangePagination}
            />
          ) : null}
        </Box>
      )}
    </Box>
  );
};

export default UsersAccountsTab;
