const NoDataLinks = {
  kwowledge_link: "https://knowledge.lucidya.com/",
  monitor_list: "https://knowledge.lucidya.com/كيفية-إنشاء-شاشة-رصد-جديدة/",
  interaction_list_link:
    "https://knowledge.lucidya.com/كيفية-إضافة-قناة-جديدة-في-تحليل-قنوات-التواصل",
  alerts_link: "https://knowledge.lucidya.com/كيفية-إنشاء-التنبيهات",
  insights_link:
    "https://knowledge.lucidya.com/كيفية-إضافة-قناة-جديدة-في-تحليل-قنوات-التواصل",
  manage_channel_link: "https://knowledge.lucidya.com/مقال-إدارة-القنوات",
  reports_link: "https://knowledge.lucidya.com/كيفية-إنشاء-التقارير-المجدولة",
  historical_request_link:
    "https://knowledge.lucidya.com/كيف-يمكنني-طلب-بيانات-تاريخية",
  api_list:
    "https://knowledge.lucidya.com/كيف-يمكن-للمستخدمين-الوصول-إلى-لوسيديا-api",
  user_management:
    "https://knowledge.lucidya.com/كيف-أقوم-بدعوة-مستخدمين-على-المنصة",
  benchmark_link:
    "https://knowledge.lucidya.com/كيفية-المقارنة-بين-شاشاة-الرصد",
  survey_link: "https://knowledge.lucidya.com/كيفية-إنشاء-إستبيان",
  accounts_list_link: "https://knowledge.lucidya.com/كيفية-إنشاء-قوائم-الحسابات",
};

export default NoDataLinks;
