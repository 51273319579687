import { Box, Checkbox, Menu, MenuItem, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import TruncatedTooltip from "shared/truncatedTooltip/truncatedTooltip";
import UserAvatar from "shared/unifiedUserImage/userAvatar";
import { CheckValueLocale } from "utils/helpers";

export const EmailNotifierPopup = ({
  options,
  onClose,
  handleSelect,
  selectedOptions,
  anchorEl,
  open,
}) => {
  const intl = useIntl();
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      transformOrigin={{
        vertical: "top", // Ensures it opens downward
        horizontal: intl?.locale === "ar" ? "left" : "right", // Matches the anchor position
      }}
      classes={{ paper: "notified-users-menu-paper" }}
    >
      <Typography className="note-users-drop-menu-header">
        {CheckValueLocale("email_notification", "", {}, intl)}
      </Typography>
      <Box className="notified-menu-items-container">
        {options?.map((option) => (
          <MenuItem key={option?.email} onClick={() => handleSelect(option)}>
            <Checkbox
              checked={selectedOptions?.some((o) => o?.email === option?.email)}
              className="notified-users-checkbox"
            />
            <Box className="notified-users-profile">
              <UserAvatar
                fullName={option?.name}
                avatarSrc={option?.profile_picture}
                sizes={{ ratio: "34px", fontSize: "14px" }}
                showStatusIndicator={true}
                statusIndicatorValue={
                  options?.status === "available" ? "available" : "unavailable"
                }
              />
              <Box className="notified-users-info">
                <TruncatedTooltip
                  enablePropperProps={true}
                  title={option?.name}
                  className="note-users-name"
                />
                <TruncatedTooltip
                  enablePropperProps={true}
                  title={option?.email}
                  className="note-users-email"
                />
              </Box>
            </Box>
          </MenuItem>
        ))}
      </Box>
    </Menu>
  );
};
