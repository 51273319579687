import { useQuery } from "@tanstack/react-query";
import { MainMonitorsPage } from "services/api/mainMonitorsPage";

export const useTopicsMonitors = (queryData) => {
  return useQuery({
    queryKey: ["topicsMonitors", queryData],
    queryFn: () => MainMonitorsPage.getTopicsMonitors(queryData),
    select: (data) => data?.data,
  });
};
