import { Paper, Step, StepButton, Stepper } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const renderStep = (label, index, activeStep, intl) => (
  <Step key={label} completed={activeStep > index}>
    <StepButton color="inherit">
      {CheckValueLocale(label, "", {}, intl)}
    </StepButton>
  </Step>
);

const AddChannelCustomStepper = ({
  isPrivateChannel,
  newSteps,
  activeStep,
  socialMediaDataSource,
  selectedAccount,
  activeDataSourceName,
}) => {
  const intl = useIntl();
  const stepperClassName =
    (isPrivateChannel || activeDataSourceName === "WHATSAPP") &&
    newSteps.length > 2 &&
    activeStep !== 0
      ? "add-private-steps add-channel-steps"
      : "add-channel-steps";

  const isPublicOrPrivateAccount =
    selectedAccount?.type === "public" || selectedAccount?.type === "private";
  const isPrivateAccount = selectedAccount?.type === "private";

  return (
    <Paper elevation={3} className="panel-header">
      <Stepper nonLinear activeStep={activeStep} className={stepperClassName}>
        {newSteps.map((label, index) => {
          if (!isPrivateChannel || !socialMediaDataSource) {
            return index < 2 || activeDataSourceName === "WHATSAPP"
              ? renderStep(label, index, activeStep, intl)
              : null;
          }
          if (isPublicOrPrivateAccount) {
            if (isPrivateAccount) {
              return renderStep(label, index, activeStep, intl);
            }
            return index < 2
              ? renderStep(label, index, activeStep, intl)
              : null;
          }
          return isPrivateChannel
            ? renderStep(label, index, activeStep, intl)
            : null;
        })}
      </Stepper>
    </Paper>
  );
};

export default AddChannelCustomStepper;
