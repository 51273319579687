import _ from "lodash";
import { Box } from "@mui/material";
import FetchingData from "../../components/fetchingData/fetchingData";
import WordCloud from "../echarts/WordCloud.js";
import { useIntl } from "react-intl";
import WidgetHeader from "./widgetHeader.js";
import { isEmpty } from "lodash";
import NoDataFound from "components/no-Data/noDataFound";

const WordCloudWidget = (props) => {
  const intl = useIntl();
  return (
    <div className="chart" id={props.title}>
      <WidgetHeader
        showGroupBy={props.showGroupBy}
        title={props.title}
        titleToolTip={props.titleToolTip}
        showAI={props.showAI}
        showDownloadIcon={props.showDownloadIcon}
        chartId={props.title}
        bigImg={props.bigImg}
        totalLabel={
          _.isEqual(
            props?.wordCloudPreLoaderDataSources?.sort(),
            props?.preLoaderTrackerDataSources?.sort(),
          ) !== true ||
          !props?.wordCloudPreLoaderDataSources?.length ||
          !props?.preLoaderTrackerDataSources?.length
            ? undefined
            : props?.totalLabel
        }
        totalValue={
          _.isEqual(
            props?.wordCloudPreLoaderDataSources?.sort(),
            props?.preLoaderTrackerDataSources?.sort(),
          ) !== true ||
          !props?.wordCloudPreLoaderDataSources?.length ||
          !props?.preLoaderTrackerDataSources?.length
            ? undefined
            : props?.totalValue
        }
      />
      {_.isEqual(
        props?.wordCloudPreLoaderDataSources?.sort(),
        props?.preLoaderTrackerDataSources?.sort(),
      ) !== true ||
      !props?.wordCloudPreLoaderDataSources?.length ||
      !props?.preLoaderTrackerDataSources?.length ? (
        <Box sx={{ width: "100%", marginTop: "5%", marginBottom: "8%" }}>
          <FetchingData />
        </Box>
      ) : isEmpty(props?.data) ? (
        <NoDataFound />
      ) : (
        //data is array of objects with name and value params,
        //[{name:...,value:...},{name:...,value:...}]
        <WordCloud data={props.data} />
      )}
    </div>
  );
};

export default WordCloudWidget;
