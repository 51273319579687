import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Box, TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { faCircleX } from "@fortawesome/pro-solid-svg-icons";
import { CheckValueLocale } from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";

const SocialMediaQuestion = (props) => {
  const {
    filteredSections,
    setSurveySections,
    surveySections,
    question,
    questionIndex,
    sectionIndex,
    previewMode,
  } = props;

  const intl = useIntl();
  const [platforms, setPlatforms] = useState([
    "facebook",
    "instagram",
    "twitter",
    "tiktok",
    "snapchat",
  ]);
  const [editable, setEditable] = useState(false);
  const [platformsAdded, setPlatformsAdded] = useState([]);
  const [answers, setAnswers] = useState({
    facebook: "",
    twitter: "",
    instagram: "",
    tiktok: "",
    snapchat: "",
  });

  useEffect(() => {
    if (question) {
      setEditable(question?.editable);
    }
  }, [question]);

  useEffect(() => {
    if (
      surveySections &&
      filteredSections[sectionIndex].questions.filter(
        (ques) => ques?.is_deleted !== true,
      )[questionIndex]?.question?.constraints?.platforms
    )
      setPlatformsAdded(
        filteredSections[sectionIndex].questions.filter(
          (ques) => ques?.is_deleted !== true,
        )[questionIndex]?.question?.constraints?.platforms,
      );
  }, [surveySections]);

  const handlePlatformsAdded = (pf) => {
    if (editable !== false) {
      setPlatformsAdded([...platformsAdded, pf]);
      filteredSections[sectionIndex].questions.filter(
        (ques) => ques?.is_deleted !== true,
      )[questionIndex].question.constraints.platforms = [...platformsAdded, pf];
      setSurveySections([...surveySections]);
    }
  };

  const handleRemovePlatform = (pf) => {
    const index = platformsAdded?.indexOf(pf);
    platformsAdded?.splice(index, 1);
    if (editable !== false) {
      setPlatformsAdded([...platformsAdded]);
      filteredSections[sectionIndex].questions.filter(
        (ques) => ques?.is_deleted !== true,
      )[questionIndex].question.constraints.platforms = platformsAdded;
      setSurveySections([...surveySections]);
    }
  };

  return (
    <Box className="survey-builder-social-media-question">
      {platforms
        ?.filter((item) =>
          previewMode ? question?.constraints?.platforms?.includes(item) : true,
        )
        ?.map((pf, index) => {
          const pfImg = pf === "twitter" ? "x-platform" : pf;

          const addedPlatform = platformsAdded?.includes(pf);

          return (
            <Box key={index} className="platform-container">
              <Box
                className={`platform-input-container ${previewMode ? "preview" : platformsAdded?.includes(pf) ? "platform-added" : ""}`}
              >
                <img
                  className="platform-logo"
                  src={`/survey_builder_icons/${pfImg}.png`}
                  alt="logo"
                />
                <TextField
                  id="survey-builder-question-platform-input"
                  className="survey-builder-question-platform-input"
                  value={answers?.[pf] || ""}
                  onChange={
                    previewMode
                      ? (e) =>
                          setAnswers((prev) => ({
                            ...prev,
                            [pf]: e.target.value,
                          }))
                      : null
                  }
                  placeholder={
                    pf === "twitter"
                      ? "@user_name"
                      : CheckValueLocale(
                          "write_your_username_or_profile_link",
                          "",
                          {},
                          intl,
                        )
                  }
                  variant="standard"
                />
                {!previewMode && platformsAdded?.includes(pf) ? (
                  <FontAwesomeIcon
                    icon={faCircleX}
                    className="remove-platform-icon"
                    onClick={() => handleRemovePlatform(pf)}
                  />
                ) : null}
              </Box>

              {!previewMode ? (
                <LucButton
                  id="survey-builder-question-platform-add-btn"
                  className={addedPlatform ? "added-btn" : ""}
                  startIcon={
                    <FontAwesomeIcon
                      icon={addedPlatform ? faCheckDouble : faPlus}
                    />
                  }
                  type="secondary"
                  variant="flat"
                  disabled={addedPlatform || editable == false}
                  onClick={() =>
                    !previewMode && !addedPlatform && handlePlatformsAdded(pf)
                  }
                >
                  {CheckValueLocale(
                    platformsAdded?.includes(pf) ? "added" : "add",
                    "",
                    {},
                    intl,
                  )}
                </LucButton>
              ) : null}
            </Box>
          );
        })}
    </Box>
  );
};

export default SocialMediaQuestion;
