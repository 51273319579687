import Reports from "../api/report";

var ReportController = {};

ReportController.getTwitterReport = function (queryData) {
  return new Promise(function (resolve) {
    Reports.getTwitterReport(queryData)
      .then(function (response) {
        resolve({
          response: response,
          status: response.status,
        });
      })
      .catch(function (error) {
        resolve({
          success: false,
          errorMsg: error,
          status: error.status,
        });
      });
  });
};

ReportController.exportReportFromFile = function (
  monitor_id,
  type_report,
  queryData,
) {
  return new Promise(function (resolve) {
    Reports.exportReportFromFile(queryData)
      .then(function (response) {
        return response.json();
      })
      .then(function (json) {
        var params = {
          filename: json.filename,
          path: json.path,
          monitor_id: monitor_id,
          type_report: type_report,
        };
        resolve({
          params: params,
        });
      })
      .catch(function (error) {
        resolve({
          success: false,
          errorMsg: error,
          status: error.status,
        });
      });
  });
};

ReportController.getPptLink = function (queryData) {
  return new Promise(function (resolve, reject) {
    Reports.getPptLink(queryData)
      .then(function (response) {
        window.open(response.data.data.link, "_blank");
        resolve({
          success: true,
          status: response.status,
        });
      })
      .catch(function (error) {
        resolve({
          success: false,
          errorMsg: error,
          status: error.status,
        });
      });
  });
};

ReportController.getMonitorSentimentCategories = function (monitorId) {
  return new Promise(function (resolve, reject) {
    Reports.getMonitorSentimentCategories(monitorId)
      .then(function (response) {
        resolve({
          categories: response.data,
          status: response.status,
        });
      })
      .catch(function (error) {
        resolve({
          success: false,
          errorMsg: error,
          status: error.status,
        });
      });
  });
};

export default ReportController;
