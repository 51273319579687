import {
  Alert,
  Box,
  Button,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import "./suggestionTabs.scss";
import SettingsIcon from "@mui/icons-material/Settings";
import RefreshIcon from "@mui/icons-material/Refresh";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { useIntl } from "react-intl";
import saveMark from "images/engagements-images/book-mark.svg";
import ezgif from "images/engagements-images/ezgif.gif";
import NoData from "images/engagements-images/book-mark2.svg";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import SouthWestIcon from "@mui/icons-material/SouthWest";
import MonitorsController from "services/controllers/monitorsController";
import NewReplayPopup from "../../setup-flow/setup/newReplayPopup";
import { useNavigate } from "react-router-dom";

import {
  faArrowDown,
  faCommentAltLines,
  faPlus,
  faSpinnerThird,
  faTrash,
} from "@fortawesome/pro-regular-svg-icons";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import SnackBar from "components/snackBar";
import EngagementsController from "services/controllers/engagementsController";
import PopupModal from "components/popupModal";
import LucButton from "shared/lucButton/lucButton";
import Services from "services/api/Services";
import { useAllCollections } from "../../setup-flow/setup/replayCards/hooks/useAllCollections";
import { useCollections } from "../../setup-flow/setup/replayCards/hooks/useCollections";
import { useCreateReplaies } from "../../setup-flow/setup/replayCards/hooks/useCreateReplaies";

const SuggestionTabs = (props) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const {
    suggestionsData,
    setText,
    saveData,
    setSaveData,
    aiSuggestionsData,
    AiSavedResponses,
    loading,
    setSuggestionsData,
    setOpenDetailsSideBarReplay,
    setOpenSidebar,
    suggestionAiData,
    insertedReplyId,
    setInsertedReplyId,
    ToggleState,
    setToggleState,
    replyBoxInputTextRef,
  } = props;
  const [newRepPopup, setNewRepPopup] = useState({
    open: false,
    id: null,
    isDuplicate: false,
    title: "",
    contant: "",
    ai: false,
    loading: false,
    done: false,
  });
  const [snackBar, setSnackBar] = useState({
    open: false,
    title: "",
    message: "",
    severity: "",
  });
  const [isReplayLimit, setIsReplayLimit] = useState(true);

  const [allCollections, setAllCollections] = useState([]);

  const { mutate: getAllCollectionsMutation } = useAllCollections();

  const getAllCollections = () => {
    getAllCollectionsMutation(
      { product_id: window?.localStorage?.engagements_id },
      {
        onSuccess: ({ data }) => {
          let arrList = data?.data?.map((item) => item?.attributes) || [];
          setAllCollections([...arrList]);
        },
      },
    );
  };

  const { mutate: getCollectionsMutation } = useCollections();

  const getCollections = (pageNumber = 1, prevList = [], textSearch = "") => {
    getCollectionsMutation(
      {
        page: pageNumber,
        product_id: window?.localStorage?.engagements_id,
        search_title: textSearch?.length ? textSearch : undefined,
      },
      {
        onSuccess: ({ data }) => {
          setNewRepPopup({
            open: false,
            id: null,
            isDuplicate: false,
            title: "",
            contant: "",
            ai: false,
            loading: false,
            done: false,
          });
          setIsReplayLimit(data?.saved_replies_limit_reached);
        },
      },
    );
  };

  const getActiveClass = (index, className) =>
    ToggleState === index ? className : "";

  const hadleRefresh = () => {
    aiSuggestionsData();
  };
  const handleSetText = (text) => {
    const pointerPosition =
      replyBoxInputTextRef?.current?.getCaretPosition?.() || 0;
    setText((prevText) => {
      let newText =
        prevText?.slice(0, pointerPosition) +
        text +
        prevText?.slice(pointerPosition);
      return newText;
    });
    replyBoxInputTextRef?.current?.focus?.(pointerPosition + text?.length);
  };

  const { mutate: createReplaiesMutation } = useCreateReplaies();

  const handleCreateReplies = () => {
    createReplaiesMutation(
      {
        product_id: window?.localStorage?.engagements_id,
        title: newRepPopup?.title?.trim(),
        text: newRepPopup?.contant?.trim(),
        collection_id: newRepPopup?.collection_id,
      },
      {
        onSuccess: ({ data }) => {
          let array = suggestionsData?.filter(
            (item, i) => i !== newRepPopup?.id,
          );
          setSuggestionsData([...array]);
          getCollections();

          getAllCollections();

          setSnackBar({
            open: true,
            title: "",
            message: "new_rep_added",
            severity: "success",
          });
        },
        onError: (error) => {
          let isDuplicate =
            error?.response?.data?.exception?.message ==
            "DUPLICATE_REPLY_FOUND";
          if (isDuplicate) {
            setNewRepPopup({
              ...newRepPopup,
              isDuplicate: true,
              loading: false,
              done: false,
            });
          } else {
            setNewRepPopup({
              open: false,
              id: null,
              isDuplicate: false,
              title: "",
              contant: "",
              ai: false,
              loading: false,
              done: false,
            });
            setSnackBar({
              open: true,
              title: "wrong_request_parameter",
              message: "please_try_again",
              severity: "error",
            });
          }
        },
      },
    );
    setTimeout(() => {
      setSnackBar({
        open: false,
        title: "",
        message: "",
        severity: "",
      });
    }, 7000);
  };

  useEffect(() => {
    getAllCollections();
    getCollections();
  }, []);

  useEffect(() => {
    if (newRepPopup?.done) {
      handleCreateReplies();
    }
  }, [newRepPopup]);

  return (
    <Box className="details-side-bar-tabs">
      <Box className="content-container">
        <Box className={`content ${getActiveClass(2, "active-content")}`}>
          {!loading ? (
            suggestionsData?.length ? (
              <Box>
                <Box className="action-button">
                  <Button
                    id="suggestion-ai-settings"
                    onClick={() => navigate("/engagements/setup")}
                  >
                    <SettingsIcon />
                    {CheckValueLocale("suggestion_ai_settings", "", {}, intl)}
                  </Button>
                  <Button onClick={() => hadleRefresh()}>
                    <RefreshIcon />
                    {CheckValueLocale("suggestion_refresh", "", {}, intl)}
                  </Button>
                </Box>
                <Box className="item-group">
                  {suggestionsData?.map((item, index) => {
                    // because of the id doesn't exist in the response, we use the index as an identifier
                    const identifier = `${index}-${item?.text}`;
                    const isInserted = insertedReplyId === identifier;
                    return (
                      <Box className="content-box">
                        <Box className="head-content">
                          <Box className="ai-icon">
                            <img src={ezgif} />

                            {CheckValueLocale("ai", "", {}, intl)}
                          </Box>
                        </Box>
                        <Box className="body-content">
                          <Typography component={"span"}></Typography>
                          <Typography id={index}>{item?.text}</Typography>
                        </Box>
                        <Box className="footer-content">
                          <Box className="start-box">
                            <Button
                              onClick={() => {
                                handleSetText(item?.text);
                                setInsertedReplyId(identifier);
                              }}
                              className={
                                isInserted ? "inserted-replay-button" : ""
                              }
                              disabled={isInserted}
                            >
                              <FontAwesomeIcon icon={faArrowDown} />
                              {CheckValueLocale(
                                isInserted
                                  ? "suggestion_inserted"
                                  : "suggestion_insert",
                                "",
                                {},
                                intl,
                              )}
                            </Button>
                            <Tooltip
                              title={CheckValueLocale(
                                "confidence_rate",
                                "",
                                {},
                                intl,
                              )}
                              placement="bottom"
                              arrow
                            >
                              <Box
                                component={"span"}
                                className={
                                  item?.score * 100 >= 90
                                    ? "score-green"
                                    : item?.score * 100 >= 60
                                      ? "score-Warning"
                                      : "score-red"
                                }
                              >
                                <Typography>
                                  {(item?.score * 100).toFixed()}%
                                </Typography>
                              </Box>
                            </Tooltip>
                          </Box>
                          <Box className="end-box">
                            {handlelUserRoles(
                              "SAVED_REPLIES",
                              "CREATE_SAVED_REPLY",
                            ) ||
                            handlelUserRoles("SAVED_REPLIES", "FULL_ACCESS") ? (
                              <Tooltip
                                title={CheckValueLocale(
                                  isReplayLimit
                                    ? "limit_saved_replais"
                                    : "save_reply",
                                  "",
                                  {},
                                  intl,
                                )}
                                placement="bottom"
                                arrow
                              >
                                <Button
                                  disabled={isReplayLimit}
                                  onClick={() =>
                                    setNewRepPopup({
                                      open: true,
                                      id: index,
                                      isDuplicate: false,
                                      title: "",
                                      contant: item?.text,
                                      ai: false,
                                      loading: false,
                                      done: false,
                                    })
                                  }
                                >
                                  <FontAwesomeIcon
                                    className="awesome-icon"
                                    icon={faCommentAltLines}
                                  />
                                </Button>
                              </Tooltip>
                            ) : null}
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            ) : (
              <Box className="no-data">
                <img src={NoData} alt="" />
                {Services.showThirdCompany ? (
                  <Typography>
                    {CheckValueLocale("no_data_ai_suggestions", "", {}, intl)}
                  </Typography>
                ) : null}
                <Button
                  id="suggestion-ai-settings"
                  onClick={() => navigate("/engagements/setup")}
                >
                  <SettingsIcon />
                  {CheckValueLocale("suggestion_ai_settings", "", {}, intl)}
                </Button>
              </Box>
            )
          ) : (
            <Box className="replies-way">
              <img src={ezgif} />
              <Typography>
                {CheckValueLocale("replies_way", "", {}, intl)}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      {newRepPopup?.open ? (
        <NewReplayPopup
          newRepPopup={newRepPopup}
          setNewRepPopup={setNewRepPopup}
          collections={allCollections}
        />
      ) : null}
      <SnackBar
        open={snackBar?.open}
        severity={snackBar?.severity}
        message={CheckValueLocale(snackBar?.message, "", {}, intl)}
        title={CheckValueLocale(snackBar?.title, "", {}, intl)}
      />
    </Box>
  );
};
export default SuggestionTabs;
