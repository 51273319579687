import moment from "moment";
import { useIntl } from "react-intl";
import { useState } from "react";
import { Box, Button, Alert } from "@mui/material";
import {
  calculateUnixTimeZone,
  CheckValueLocale,
  formatTimeZone,
} from "utils/helpers";
import { Save } from "@mui/icons-material";
import "./exploreSearch.scss";

const ExploreSearchHeader = ({
  exploreDataCount,
  exploreDate,
  setShowSaveResults,
  checkedSaved,
  topPostsSumCount,
}) => {
  const intl = useIntl();
  const [searchFoundMsgAlert, setSearchFoundMsgAlert] = useState(true);

  const OnCloseExploreAlert = () => {
    setSearchFoundMsgAlert(false);
  };

  const sumDataCount = exploreDataCount?.reduce((acc, curr) => {
    return acc + curr?.collected_count;
  }, 0);

  return (
    <>
      <Box className="explore-header">
        {CheckValueLocale("search_results", "", {}, intl)}
        <Button
          onClick={() => setShowSaveResults(true)}
          variant="outlined"
          className="save-results-btn"
          startIcon={<Save className="search-icon" />}
          disabled={checkedSaved || topPostsSumCount == 0}
        >
          {CheckValueLocale(
            checkedSaved ? "save_results_saved_btn" : "save_results",
            "",
            {},
            intl,
          )}
        </Button>
      </Box>
      {searchFoundMsgAlert ? (
        <Alert
          open={searchFoundMsgAlert}
          onClose={OnCloseExploreAlert}
          severity="info"
        >
          {CheckValueLocale("search_results_msg", "", {}, intl)}
          <Box component="span"> {topPostsSumCount} </Box>
          {CheckValueLocale("search_results_msg_date", "", {}, intl)}
          <Box component="span">
            {
              formatTimeZone(
                calculateUnixTimeZone(moment(exploreDate).unix(), {}),
                intl,
                {},
              ).date
            }
            .
          </Box>
        </Alert>
      ) : null}
    </>
  );
};

export default ExploreSearchHeader;
