export const TAPS_TYPE = {
  ANALYTICS: "ANALYTICS",
  COMMENTS: "COMMENTS",
};

export const SPATapsData = [
  {
    label: "spa_analytics",
    value: TAPS_TYPE.ANALYTICS,
  },
  {
    label: "spa_post_engagement",
    value: TAPS_TYPE.COMMENTS,
  },
];
