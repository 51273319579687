import { Box } from "@mui/material";
import "./desginPDF.scss";
import HeaderPagePdf from "./components/headerPdf";

const DesginPDF = ({ coverPdf }) => {
  return (
    <Box id="main-pdf-container-page">
      {coverPdf ? (
        <Box id="main-pdf-content-cover">
          <HeaderPagePdf />
          {coverPdf}
          <Box className="fixed-border-bottom" />
        </Box>
      ) : null}

      <Box id="main-pdf-content-page">
        <HeaderPagePdf />

        <Box id="main-pdf-content-widgets" />

        <Box className="fixed-border-bottom" />
      </Box>
    </Box>
  );
};

export default DesginPDF;
