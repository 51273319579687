import { useQuery } from "@tanstack/react-query";
import { ReportServices } from "services/api/reports";

export const useReportsFilterDatasources = () => {
  return useQuery({
    queryKey: ["reportsFilterDatasources"],
    queryFn: () => ReportServices.getReportsFilterDatasources(),
    select: (data) =>
      data?.data?.data
        ?.filter(
          (item) => item?.attributes?.source?.toLowerCase?.() != "whatsapp",
        )
        ?.map((item) => item?.attributes) || [],
  });
};
