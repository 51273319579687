import { Box, InputLabel, MenuItem, Select, Tooltip } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useEffect, useState } from "react";

const SurveySetting = ({ surveyData, setSurveyData, selectedTap }) => {
  const intl = useIntl();
  const [fields, setFields] = useState([
    "send_survey_in_engag",
    "unsupported_languages_engag",
    "delivery_method_engag",
    "expiry_time_engag",
    "invalid_responses_engag",
  ]);

  const fieldsData = surveyData?.mainData;
  const selectedTabData = surveyData?.selectedData?.[selectedTap];

  const handleChangeSelect = (event, field) => {
    surveyData.selectedData[selectedTap][field] = event.target.value;
    setSurveyData({ ...surveyData });
  };

  useEffect(() => {
    let renderedFields = [
      "send_survey_in_engag",
      "unsupported_languages_engag",
      "delivery_method_engag",
      "expiry_time_engag",
      "invalid_responses_engag",
    ];
    if (
      surveyData.selectedData[selectedTap]["invalid_responses_engag"] ==
      "resend_question"
    ) {
      renderedFields.push("resend_attempts_engag");
    }
    setFields([...renderedFields]);
  }, [surveyData.selectedData[selectedTap]["invalid_responses_engag"]]);

  const selectShared = (fieled) => {
    return (
      <Box className={`survey-setting-field`}>
        <Box className="survey-chat-text-title">
          <InputLabel>{CheckValueLocale(fieled, "", {}, intl)}</InputLabel>
          <Tooltip
            placement="top"
            arrow
            title={CheckValueLocale(`${fieled}_tooltip`, "", {}, intl)}
          >
            <ErrorOutlineIcon />
          </Tooltip>
        </Box>
        <Select
          value={selectedTabData?.[fieled]}
          onChange={(e) => handleChangeSelect(e, fieled)}
        >
          {fieldsData?.[fieled]?.map((item) => (
            <MenuItem value={item}>
              {fieled == "resend_attempts_engag"
                ? item
                : fieled == "expiry_time_engag"
                  ? `${item / 60} ${CheckValueLocale(item == 60 ? "minute_engag" : "minutes_engag", "", {}, intl)}`
                  : CheckValueLocale(
                      fieled == "unsupported_languages_engag"
                        ? `un_${item}`
                        : `${item}_engag`,
                      "",
                      {},
                      intl,
                    )}
            </MenuItem>
          ))}
        </Select>
      </Box>
    );
  };

  return (
    <Box className="survey-chat-fields-container">
      {fields?.map((field) => selectShared(field))}
    </Box>
  );
};

export default SurveySetting;
