import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { CheckValueLocale, classNames } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartSimple,
  faClock,
  faUser,
  faUserGroup,
  faMessageLines,
} from "@fortawesome/pro-solid-svg-icons";

let iconsObject = {
  inbox: <FontAwesomeIcon icon={faMessageLines} />,
  slas: <FontAwesomeIcon icon={faClock} />,
  teams: <FontAwesomeIcon icon={faUserGroup} />,
  agents: <FontAwesomeIcon icon={faUser} />,
  Insights: <FontAwesomeIcon icon={faChartSimple} />,
};

const SideBar = ({ tabs, selectedTab }) => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <Box className="analytics-sidebar">
      <Box className="menu-analytics-sidebar">
        {CheckValueLocale("menu", "", {}, intl)}
      </Box>
      {tabs?.map((item) => (
        <Box
          key={item}
          className={classNames(
            "menu-analytics-sidebar-item",
            item == selectedTab ? "selected-analytics-item" : "",
          )}
          onClick={() => navigate(`/engagements/analytics/${item}`)}
          id={`analytics-sidebar-${item}`}
        >
          <Box className="main-tab-details">
            {iconsObject[item]}
            {CheckValueLocale(`${item}_analytics`, "", {}, intl)}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default SideBar;
