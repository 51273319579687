import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./style.scss";
import { List, Box } from "@mui/material";
//fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine, faList } from "@fortawesome/pro-regular-svg-icons";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";

const SideMenuTabs = (props) => {
  const intl = useIntl();
  const {
    dashboardDynamicData,
    setActiveTab,
    handleClearFilterParams,
    dynamicDataLoader,
  } = props;
  const urlParams = useParams();
  const navigate = useNavigate();

  const { monitorType, dashboardID, dashboardActiveTab } = urlParams;
  // _______________________________________________________________

  const handleSideBarRedirection = (tabName) => {
    if (!dynamicDataLoader) {
      setActiveTab(tabName);
      handleClearFilterParams();
      navigate(`/dashboard/${monitorType}/${tabName}/${dashboardID}`);
    }
  };

  return (
    <>
      <List className="sidebar-list">
        {dashboardDynamicData?.page_side_bar?.map((tab, i) => {
          let tabsTitle = Object.keys(tab)[0];
          tabsTitle = tabsTitle + "_title";
          return (
            <Box component="div" key={i}>
              <Box component="div" className="sidebar-titles">
                <FontAwesomeIcon
                  icon={
                    tabsTitle === "analytics_title"
                      ? faChartLine //icon beside analutics word
                      : faList //icon beside lists word
                  }
                  solid
                />
                <Box component="span">
                  {CheckValueLocale(tabsTitle, "", {}, intl)}
                </Box>
              </Box>
              {/*_______________________________________________________________ */}

              <Box
                className={`${
                  dynamicDataLoader ? "disable-sidebar-tabs" : ""
                } sidebar-tabs`}
              >
                {tab[Object.keys(tab)]?.map((tabName, i) => {
                  return (
                    <p
                      key={i}
                      className={`
                        ${
                          tabName?.tabName === dashboardActiveTab &&
                          "active_tab"
                        }
                        ${i === 0 && "first-tab"}`}
                      onClick={() => handleSideBarRedirection(tabName?.tabName)}
                      id={`tab-${i}`}
                    >
                      {CheckValueLocale(tabName?.tabName, "", {}, intl)}
                    </p>
                  );
                })}
              </Box>
            </Box>
          );
        })}
      </List>
    </>
  );
};
export default SideMenuTabs;
