import React, { useEffect, useState } from "react";
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  FormControl,
  MenuItem,
  Box,
} from "@mui/material";
import { useIntl } from "react-intl";
import {
  getCompanyApps,
  facebookAuth,
  gmailAuth,
  twitterAuth,
  gmbAuth,
  linkedInAuth,
  tiktokAuth,
} from "pages/channelAnalytics/manageChannels/manageChannels.hooks";
import { getSocialIcon, CheckValueLocale } from "utils/helpers/index";
import MonitorsController from "services/controllers/monitorsController";
import LucButton from "shared/lucButton/lucButton";

const AddNewAccount = (props) => {
  const intl = useIntl();
  const [companyApps, setCompanyApps] = useState([]);
  const [serviceProvider, setProvider] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [providers, setProviders] = useState([]);
  const [whatsappDisableReauth, setWhatsappDisableReauth] = useState("");
  const [whatsappDisableUnauth, setWhatsappDisableUnauth] = useState("");

  useEffect(() => {
    let accounts = props?.companySocialAccounts;
    accounts?.map((acc) => {
      setWhatsappDisableReauth(acc?.attributes?.disable_reauth);
      setWhatsappDisableUnauth(acc?.attributes?.disable_unauth);
    });
  }, []);

  const getCompanySources = () => {
    if (
      window.localStorage.cxm_id ||
      (window.localStorage.sm_id && window.localStorage.engagements_id)
    ) {
      MonitorsController.getCompanySources(
        window.localStorage.cxm_id,
        true,
      ).then((data) => {
        if (data) {
          setProviders(data?.data?.company_allowed_sources?.data);
        }
      });
    }
  };

  const changeHandler = (e) => {
    setProvider(e.target.value);
  };
  const checkProductId = () => {
    let productId;
    if (window.localStorage.cxm_id) {
      productId = false;
    } else {
      productId = true;
    }
    return productId;
  };

  const AddNewAccount = () => {
    if (serviceProvider === "") {
      setErrorMsg(CheckValueLocale("choose_account_type", "", {}, intl));
    } else {
      props?.handleDialog();
      const redirectUrl =
        window.location.origin +
        `/settings/accounts_management/company_accounts`;

      switch (serviceProvider) {
        case "TWITTER":
          twitterAuth(
            companyApps,
            redirectUrl,
            serviceProvider,
            checkProductId(),
          );
          break;
        case "FACEBOOK":
          facebookAuth(
            companyApps,
            redirectUrl,
            serviceProvider,
            checkProductId(),
          );
          break;
        case "GMAIL":
          gmailAuth(
            companyApps,
            redirectUrl,
            serviceProvider,
            checkProductId(),
          );
          break;
        case "INTERCOM":
          // handleInterComDialog();
          break;
        case "INSTAGRAM":
          facebookAuth(
            companyApps,
            redirectUrl,
            serviceProvider,
            checkProductId(),
          );
          break;
        case "GOOGLEMYBUSINESS":
          gmbAuth(companyApps, redirectUrl, serviceProvider, checkProductId());
          break;
        case "WHATSAPP":
          facebookAuth(
            companyApps,
            redirectUrl,
            serviceProvider,
            checkProductId(),
            undefined,
            "whatsapp",
          );
          break;
        case "LINKEDIN":
          linkedInAuth(
            companyApps,
            redirectUrl,
            serviceProvider,
            checkProductId(),
          );
          break;
        case "GENESYS":
          props?.setOpenGenesysModal(true);
          break;
        case "TIKTOK":
          tiktokAuth(
            redirectUrl,
            props?.tiktokDataSourceId,
            props?.setFailSnackBarData,
            props?.setOpenSnackBarAllSource,
          );
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    getCompanyApps(setCompanyApps);
    getCompanySources();
  }, []);

  const checkGoogleMyBusinessAccounts = () => {
    let accounts = props?.companySocialAccounts,
      gmbAccount = false;
    accounts?.map((account) => {
      if (account?.attributes?.account_type === "GOOGLEMYBUSINESS") {
        gmbAccount = true;
      }
    });
    return gmbAccount;
  };

  const displayIconsWithText = (providerName) => {
    if (providerName === "GOOGLEMYBUSINESS") {
      providerName = "google";
    }
    return (
      <Box className="new-account-icons">
        {getSocialIcon(providerName?.toUpperCase())}
        <Box className={`icons-account-text ${providerName?.toLowerCase()}`}>
          {CheckValueLocale(providerName, "", {}, intl)}
        </Box>
      </Box>
    );
  };

  return (
    <Dialog open={props?.open} onClose={props?.handleDialog}>
      <Box id="settings-new-account-dialog">
        <DialogTitle className="dialog-title">
          {CheckValueLocale("add_company_account", "", {}, intl)}
        </DialogTitle>
        <DialogContent>
          <Typography className="select-title">
            {CheckValueLocale("account_type", "", {}, intl)}:
            <Box component={"span"} className="required">
              *
            </Box>
          </Typography>
          <FormControl fullWidth>
            <Select
              value={serviceProvider}
              onChange={changeHandler}
              size="small"
              displayEmpty
              renderValue={
                serviceProvider !== ""
                  ? undefined
                  : () =>
                      CheckValueLocale(
                        "settings_choose_account_type",
                        "",
                        {},
                        intl,
                      )
              }
            >
              {providers?.map((provider) => {
                if (
                  provider?.attributes?.source !== "INTERCOM" &&
                  provider?.attributes?.source !== "WHATSAPP"
                )
                  return (
                    <MenuItem
                      key={provider?.attributes?.id}
                      value={provider?.attributes?.source}
                      id={`settings-company-info-new-account-${provider?.attributes?.source}`}
                    >
                      {provider?.attributes?.source
                        ? displayIconsWithText(provider?.attributes?.source)
                        : ""}
                    </MenuItem>
                  );
              })}
            </Select>
          </FormControl>
          <Typography variant="h5" className="error-msg">
            {errorMsg !== "" ? errorMsg : null}
          </Typography>
        </DialogContent>
        <DialogActions className="dialog-actions">
          <LucButton
            id="settings-new-company-account-cancel-btn"
            variant="flat"
            type="secondary"
            onClick={props?.handleDialog}
          >
            {CheckValueLocale("cancel", "", {}, intl)}
          </LucButton>
          <LucButton
            id="settings-new-company-account-next-btn"
            onClick={AddNewAccount}
            disabled={
              serviceProvider === "GOOGLEMYBUSINESS" &&
              checkGoogleMyBusinessAccounts()
            }
          >
            {CheckValueLocale("next", "", {}, intl)}
          </LucButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default AddNewAccount;
