import { useMutation } from "@tanstack/react-query";
import { MainMonitorsPage } from "services/api/mainMonitorsPage";

export const useGetTopicsForMonitor = () => {
  return useMutation({
    mutationFn: ({ monitor_id, queryData }) => {
      return MainMonitorsPage.getTopicsForMonitor(monitor_id, queryData);
    },
  });
};
