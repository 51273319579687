import React, { Component } from "react";
import { Navigate, Link } from "react-router-dom";
import Notfound from "images/shared-images/404.svg";
import "./NotFound.scss";
import { FormattedMessage } from "react-intl";
import { Box, Typography } from "@mui/material";
class NotFound extends Component {
  state = {
    redirect: false,
  };

  handleRedirect = () => {
    this.setState({ redirect: true });
  };

  render() {
    if (this.state.redirect) {
      return <Navigate to="/insights/main" />;
    }
    return (
      <>
        <div className="not-found">
          <img src={Notfound} />

          <Box className="not-found-empty-box">
            <Typography component={"h3"} className="no-data-found">
              <FormattedMessage id="page_not_found" />
            </Typography>
            <Typography component={"span"} className="try-adjust-date">
              <FormattedMessage id="page_not_found_dec" />
              <Link to="/home_page">
                <FormattedMessage id="home_page" />
              </Link>
            </Typography>
          </Box>
        </div>
      </>
    );
  }
}
export default NotFound;
