import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Input,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const ChoiceSectionPreviwMode = (props) => {
  const { question, rootQuestion, handleProceedAnswer } = props;

  const intl = useIntl();

  const [selectedValue, setSelectedValue] = useState([]);
  const [otherAnswer, setOtherAnswer] = useState("");
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (question?.constraints?.randomaize === true) {
      const shuffleArray = (array) => {
        for (let i = array?.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
      };
      const randomizedItems = shuffleArray([...question?.items]);
      if (randomizedItems?.length !== 0) setOptions(randomizedItems);
      return;
    } else return setOptions(question?.items);
  }, [question]);

  const handleSelection = (selectedIndex) => {
    const { multiple_selection } = question?.constraints;
    let arr = [];
    if (!multiple_selection) {
      arr?.push(selectedIndex);
      setSelectedValue(arr);
      if (rootQuestion?.is_proceed_on_answer)
        handleProceedAnswer(options[selectedIndex]?.next_section);
    } else if (multiple_selection) {
      arr = [...selectedValue];
      if (arr?.includes(selectedIndex)) {
        const index = arr?.indexOf(selectedIndex);
        arr?.splice(index, 1);
        setSelectedValue(arr);
      } else {
        setSelectedValue(selectedValue?.concat(selectedIndex));
      }
      if (rootQuestion?.is_proceed_on_answer) handleProceedAnswer(null);
    }
  };

  const handleOtherAnswer = (e) => setOtherAnswer(e.target.value);
  let multipleSelection = question?.constraints?.multiple_selection;

  return (
    <Box className="preview-choice-container">
      {options?.length &&
        options?.map((val, index) => (
          <>
            {multipleSelection === true ? (
              <Box className="radio-btn-box">
                <FormControlLabel
                  label={CheckValueLocale(Object.values(val)[0], "", {}, intl)}
                  control={
                    <Checkbox
                      onChange={() => handleSelection(index)}
                      checked={val?.selected}
                    />
                  }
                />
              </Box>
            ) : (
              <RadioGroup value={val} onChange={() => handleSelection(index)}>
                <FormControlLabel
                  key={index}
                  value={val}
                  control={
                    <Radio
                      checked={
                        selectedValue?.length && selectedValue[0] == index
                          ? true
                          : false
                      }
                    />
                  }
                  label={Object.values(val)[0]}
                />
              </RadioGroup>
            )}
          </>
        ))}
      {question?.constraints?.other_option ? (
        <Box
          className="radio-btn-box"
          onClick={() => {
            handleSelection("other");
            handleProceedAnswer(null);
          }}
        >
          {!multipleSelection ? (
            <FormControlLabel
              label={CheckValueLocale("other", "", {}, intl)}
              control={
                <Radio
                  checked={selectedValue?.includes("other") ? true : false}
                />
              }
            />
          ) : null}
        </Box>
      ) : null}
      {selectedValue?.includes("other") &&
      question?.constraints?.other_option ? (
        <Box className="other-choice-text-field-wrapper">
          <Input
            className="other-choice-text-field"
            placeholder={CheckValueLocale(
              "type_your_answer_here",
              "",
              {},
              intl,
            )}
            variant="standard"
            value={otherAnswer}
            onChange={handleOtherAnswer}
          />
        </Box>
      ) : null}
    </Box>
  );
};

export default ChoiceSectionPreviwMode;
