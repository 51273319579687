import moment from "moment";
import { isEmptyArray } from "utils/helpers";
import {
  handleAuthorActivity,
  handleXlsData,
  handleSortedCase,
} from "../excelCommonFn";

const engagements_fb_MP = (response, lang) => {
  if (isEmptyArray(response)) {
    return [];
  } else {
    var commentsVolume = [],
      reactions = [],
      sentimentAnalysis = [],
      commentsInteractions = [],
      topKeywords = [],
      topHashtags = [],
      associatedTopics = [],
      engagersVolume = [],
      genderDistribution = [],
      topLanguages = [],
      dialects = [],
      subDialects = [],
      topCountries = [],
      topCities = [],
      accountAuthorActivity = [],
      topPosts = [],
      allSheetData = [];
    //_______________________________________________________________________________
    response?.map((i) => {
      Object.entries(i)?.forEach(([key, value]) => {
        switch (key) {
          case "commentsVolume":
            value?.map((i) => {
              commentsVolume.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.comments]: i?.value,
              });
            });
            allSheetData?.push([commentsVolume, lang.comments_volume]);
            break;
          //_______________________________________________________________________________

          case "dialects":
            handleSortedCase(value, dialects, lang.dialect, lang.number);
            allSheetData.push([dialects, lang.main_dialect_filter]);
            break;
          //_______________________________________________________________________________
          case "subDialects":
            handleSortedCase(
              value,
              subDialects,
              lang.sub_dialects,
              lang.number,
            );
            allSheetData.push([subDialects, lang.sub_dialects]);
            break;
          //_______________________________________________________________________________

          case "sentimentAnalysis":
            value?.negative?.map((i, index) => {
              if (i?.name == value?.neutral[index]?.name) {
                let total =
                  value?.neutral[index]?.value +
                  value?.positive[index]?.value +
                  value?.negative[index]?.value;
                sentimentAnalysis.push({
                  [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                  [lang.neutral]:
                    total !== 0
                      ? ((value?.neutral[index]?.value / total) * 100).toFixed(
                          2,
                        ) + "%"
                      : 0,

                  [lang.positive]:
                    total !== 0
                      ? ((value.positive[index]?.value / total) * 100)?.toFixed(
                          2,
                        ) + "%"
                      : 0,

                  [lang.negative]:
                    total !== 0
                      ? ((value.negative[index]?.value / total) * 100)?.toFixed(
                          2,
                        ) + "%"
                      : 0,
                });
              }
            });
            allSheetData.push([
              sentimentAnalysis,
              lang.comments_sentiment_analysis,
            ]);
            break;
          //_______________________________________________________________________________

          case "commentsInteractions":
            value?.map((i) => {
              commentsInteractions?.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.comments]: i?.value[0],
                [lang.likes]: i?.value[1],
                [lang.total]: i?.value[0] + i?.value[1],
              });
            });
            allSheetData?.push([
              commentsInteractions,
              lang.comments_interactions,
            ]);
            break;
          //_______________________________________________________________________________

          case "associatedTopics":
            value?.map((i) => {
              associatedTopics?.push({
                [lang.topic]: i?.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData?.push([
              associatedTopics,
              lang.comments_associated_topics,
            ]);
            break;

          //_______________________________________________________________________________

          case "engagersVolume":
            value?.map((i) => {
              engagersVolume?.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.engagers]: i?.value,
              });
            });
            allSheetData?.push([engagersVolume, lang?.engagers_volume]);
            break;
          //_______________________________________________________________________________

          case "genderDistribution":
            value?.map((i) => {
              genderDistribution?.push({
                [lang.age]: i?.name,
                [lang.male]: i?.value[0],
                [lang.female]: i?.value[1],
                [lang.total]: i?.value[0] + i?.value[1],
              });
            });
            allSheetData?.push([genderDistribution, lang.gender_age_dist]);
            break;
          //_______________________________________________________________________________

          case "topLanguages":
            value?.map((i) => {
              topLanguages?.push({
                [lang.language]: i?.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData?.push([topLanguages, lang?.top_languages]);
            break;
          //_______________________________________________________________________________

          case "reactions":
            value?.map((i) => {
              reactions?.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.like_reaction]: i?.value[0],
                [lang.love_reaction]: i?.value[1],
                [lang.care_reaction]: i?.value[2],
                [lang.haha_reaction]: i?.value[3],
                [lang.wow_reaction]: i?.value[4],
                [lang.angry_reaction]: i?.value[5],
                [lang.total]:
                  i?.value[0] +
                  i?.value[1] +
                  i?.value[2] +
                  i?.value[3] +
                  i?.value[4] +
                  i?.value[5],
              });
            });
            allSheetData?.push([reactions, lang.comments_reactions]);
            break;
          //_______________________________________________________________________________

          case "topCountries":
            value?.map((i) => {
              topCountries?.push({
                [lang.countries]: i?.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData?.push([topCountries, lang?.top_countries]);
            break;
          //_______________________________________________________________________________

          case "topCities":
            value?.map((i) => {
              topCities?.push({
                [lang.cities]: i?.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData?.push([topCities, lang?.top_cities]);
            break;
          // _______________________________________________________________________________

          case "topKeywords":
            value?.map((i) => {
              topKeywords?.push({
                [lang.keywords]: i?.id,
                [lang.xlsx_number_of_posts]: i?.stats_count,
              });
            });
            allSheetData?.push([
              topKeywords,
              lang?.comments_top_keywords_xlsx_sheetname,
            ]);

            break;
          // _______________________________________________________________________________

          case "topHashtags":
            value?.map((i) => {
              topHashtags?.push({
                [lang.xlsx_hashtags]: i?.id,
                [lang.xlsx_number_of_posts]: i?.stats_count,
              });
            });
            allSheetData?.push([
              topHashtags,
              lang?.comments_top_hashtags_xlsx_sheetname,
            ]);

            break;
          // _______________________________________________________________________________

          case "accountAuthorActivity":
            handleAuthorActivity(value[0], value[1], value[2], lang)?.map(
              (i) => {
                accountAuthorActivity?.push({
                  [lang.day]: i[0],
                  [lang.hour_group]: i[1],
                  [lang.no_of_activity]: i[2],
                });
              },
            );
            allSheetData?.push([
              accountAuthorActivity,
              lang?.engagers_activity,
            ]);
            break;

          // _______________________________________________________________________________

          case "topPosts":
            value?.map((i) => {
              topPosts?.push({ [lang.top_comments_mentions]: i?.tweet_link });
            });
            allSheetData?.push([topPosts, lang?.top_comments_mentions]);
            break;
          //_______________________________________________________________________________
          default:
            break;
        }
      });
    });
    //_______________________________________________________________________________

    return handleXlsData(allSheetData);
  }
};
export { engagements_fb_MP };
