import { useMutation, useQueryClient } from "@tanstack/react-query";
import AccountsListController from "services/controllers/accountsListController";

export const useManageLists = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ username, account_list_ids }) =>
      AccountsListController?.updateUsernameLists(username, account_list_ids),

    onSuccess: () => {
      queryClient?.invalidateQueries({
        queryKey: ["account_lists"],
      });
    },
  });
};
