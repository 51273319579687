import { useMutation, useQueryClient } from "@tanstack/react-query";
import UserAccount from "services/controllers/userAccountController";

export const useEnableTwoFactorAuthMethod = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (queryData) => UserAccount?.enableUser2FA(queryData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["userTwoFactorAuth"],
      });
    },
  });
};
