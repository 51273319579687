export const handleSingleTweetAnalysisResponse = (response, dispatch) => {
  switch (response?.eventName) {
    case "Twitter__SingleTweetAnalysisPage__top_keywords":
      const topKeywords = response?.eventData?.top_keywords || [];
      dispatch({
        type: "SET_TOP_KEYWORDS",
        payload: topKeywords,
      });
      dispatch({
        type: "SET_TOP_KEYWORDS_PRELOADER",
        payload: response?.monitor_id,
      });
      break;
    case "Twitter__SingleTweetAnalysisPage__top_hashtags":
      const topHashtags = response?.eventData?.top_hashtags || [];
      dispatch({
        type: "SET_TOP_HASHTAGS",
        payload: topHashtags,
      });
      dispatch({
        type: "SET_TOP_HASHTAGS_PRELOADER",
        payload: response?.monitor_id,
      });
      break;
    case "Twitter__SingleTweetAnalysisPage__top_countries":
      const topCountries = response?.eventData?.top_countries || [];
      dispatch({
        type: "SET_TOP_COUNTRIES",
        payload: topCountries,
      });
      dispatch({
        type: "SET_TOP_COUNTRIES_PRELOADER",
        payload: response?.monitor_id,
      });
      break;
    case "Twitter__SingleTweetAnalysisPage__top_cities":
      const topCities = response?.eventData?.top_cities || [];
      dispatch({
        type: "SET_TOP_CITIES",
        payload: topCities,
      });
      dispatch({
        type: "SET_TOP_CITIES_PRELOADER",
        payload: response?.monitor_id,
      });
      break;
    case "Twitter__SingleTweetAnalysisPage__top_languages":
      const topLanguages = response?.eventData?.top_languages || [];
      dispatch({
        type: "SET_TOP_LANGUAGES",
        payload: topLanguages,
      });
      dispatch({
        type: "SET_TOP_LANGUAGES_PRELOADER",
        payload: response?.monitor_id,
      });
      break;
    case "Twitter__SingleTweetAnalysisPage__dialects_and_sub_dialects":
      const dialects = response?.eventData?.dialects || [];
      const subDialects = response?.eventData?.sub_dialects || [];
      dispatch({
        type: "SET_DIALECTS",
        payload: dialects,
      });
      dispatch({
        type: "SET_SUB_DIALECTS",
        payload: subDialects,
      });
      dispatch({
        type: "SET_DIALECTS_SUB_DIALECTS_PRELOADER",
        payload: response?.monitor_id,
      });
      break;
    case "Twitter__SingleTweetAnalysisPage__gender_distribution":
      const topGenders = response?.eventData?.top_genders || [];
      dispatch({
        type: "SET_GENDER",
        payload: topGenders,
      });
      dispatch({ type: "SET_GENDER_PRELOADER", payload: response?.monitor_id });
      break;
    case "Twitter__SingleTweetAnalysisPage__account_types":
      const accountTypes = response?.eventData?.account_types || [];
      dispatch({
        type: "SET_ACCOUNT_TYPES",
        payload: accountTypes,
      });
      dispatch({
        type: "SET_ACCOUNT_TYPES_PRELOADER",
        payload: response?.monitor_id,
      });
      break;
    case "Twitter__SingleTweetAnalysisPage__top_engagers":
      const topEngagers = response?.eventData?.top_engagers || [];
      dispatch({
        type: "SET_TOP_ENGAGERS",
        payload: topEngagers,
      });
      dispatch({
        type: "SET_TOP_ENGAGERS_PRELOADER",
        payload: response?.monitor_id,
      });
      break;
    case "Twitter__SingleTweetAnalysisPage__top_verified_engagers":
      const topVerifiedEngagers =
        response?.eventData?.top_verified_engagers || [];
      dispatch({
        type: "SET_TOP_VERIFIED_ENGAGERS",
        payload: topVerifiedEngagers,
      });
      dispatch({
        type: "SET_TOP_VERIFIED_ENGAGERS_PRELOADER",
        payload: response?.monitor_id,
      });
      break;
    case "Twitter__SingleTweetAnalysisPage__top_influencers":
      const topInfluencers = response?.eventData?.top_influencers || [];
      dispatch({
        type: "SET_TOP_INFLUENCERS",
        payload: topInfluencers,
      });
      dispatch({
        type: "SET_TOP_INFLUENCERS_PRELOADER",
        payload: response?.monitor_id,
      });
      break;
    case "Twitter__SingleTweetAnalysisPage__volume_overtime":
      const postsOverTime = response?.eventData?.posts_over_time || [];
      const totalPostsCount = response?.eventData?.total_posts_count || 0;
      dispatch({
        type: "SET_POSTS_VOLUME",
        payload: postsOverTime,
      });
      dispatch({
        type: "SET_TOTAL_POSTS_VOLUME",
        payload: totalPostsCount,
      });
      dispatch({
        type: "SET_COMMENTS_OVER_TIME_PRELOADER",
        payload: response?.monitor_id,
      });
      break;
    case "Twitter__SingleTweetAnalysisPage__sentiment_analysis":
      const sentimentOvertime = response?.eventData?.sentiment_overtime || [];
      const sentimentPiechart = response?.eventData?.sentiment_piechart;
      dispatch({
        type: "SET_SENTIMENT_ANALYSIS",
        payload: sentimentOvertime,
      });
      dispatch({
        type: "SET_SENTIMENT_ANALYSIS_PIECHART",
        payload: sentimentPiechart,
      });
      dispatch({
        type: "SET_SENTIMENT_ANALYSIS_PRELOADER",
        payload: response?.monitor_id,
      });

      break;
    default:
      break;
  }
};
