import { Chip } from "@mui/material";

export const SourceOption = ({ source, isSelected, onSelect }) => (
  <Chip
    key={source?.id}
    label={source?.link?.replace("https://" , "")?.replace("http://" , "")}
    disabled={isSelected}
    variant="outlined"
    className="sources-option-chip"
    onClick={() => (!isSelected ? onSelect(source) : null)}
  />
);
