import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { EditReportContext } from "pages/reports/context/reportContext";
import LucButton from "shared/lucButton/lucButton";

const EditReportFooter = () => {
  const { formik, editReportLoading } = useContext(EditReportContext);

  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <Box className="edit-report-footer-wrapper">
      <LucButton
        id="edit-report-cancel-button"
        type="secondary"
        variant="flat"
        onClick={() => navigate("/reports/list", { replace: true, state: {} })}
      >
        {CheckValueLocale("cancel", "", {}, intl)}
      </LucButton>
      <LucButton
        id="edit-report-save-button"
        loading={editReportLoading}
        onClick={formik?.handleSubmit}
      >
        {CheckValueLocale("save_changes", "", {}, intl)}
      </LucButton>
    </Box>
  );
};

export default EditReportFooter;
