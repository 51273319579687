import { useIntl } from "react-intl";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";

import "./manageAudienceHeader.scss";
import { CheckValueLocale } from "utils/helpers";

const ManageAudienceHeader = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <>
      <Box className="manage-audience-container">
        <Box container className="manage-audience-title manage-audience-info">
          <Typography variant="h3">
            {CheckValueLocale("manage_audience", "", {}, intl)}
          </Typography>
          <Typography variant="span">
            {CheckValueLocale(
              "manage_segments_imports_duplicates",
              "",
              {},
              intl,
            )}
          </Typography>
        </Box>
        <Button
          className="back-audience-manage  manage-audience-info"
          onClick={() => {
            navigate(`/audience/list`);
          }}
          id="manage-audience-back-to-audience-btn"
        >
          {CheckValueLocale("back_to_audience", "", {}, intl)}
          <ArrowForwardIos className="profile-icon" />
        </Button>
      </Box>
    </>
  );
};

export default ManageAudienceHeader;
