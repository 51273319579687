import { Box, Stack, Typography } from "@mui/material";
import CustomizedTooltip, {
  customizedStyledTooltip,
} from "components/customizedTooltip";
import UserAvatar from "shared/unifiedUserImage/userAvatar";
import { useNavigate } from "react-router-dom";
import { maskEmail } from "utils/helpers";

const showTooltipText = (text, textLength, onClick) => {
  return (
    <Box className="report-name-container" onClick={onClick}>
      {text?.length > textLength ? (
        <CustomizedTooltip
          title={text}
          arrow
          placement="top"
          styles={customizedStyledTooltip}
        >
          <Box component="span">{text?.substring(0, textLength)}...</Box>
        </CustomizedTooltip>
      ) : (
        <Typography component="p">{text}</Typography>
      )}
    </Box>
  );
};
const AvatarBodyCell = ({ user }) => {
  const navigate = useNavigate();

  return (
    <Stack
      className="user-management-avatar-wrapper"
      direction="row"
      justifyContent="flex-start"
      spacing={2}
    >
      <UserAvatar
        fullName={user?.attributes?.name}
        avatarSrc={user?.attributes?.avatar}
        sizes={{ ratio: "32px", fontSize: "12px" }}
      />
      <Stack direction="column" className="user-info-style">
        <Box className="user-management-head">
          {showTooltipText(
            !user?.attributes?.name?.trim()?.length
              ? "—"
              : user?.attributes?.name,
            30,
            () => navigate(`/settings/user_managements/view/${user?.id}`),
          )}
        </Box>
        <Box className="user-management-email">
          {showTooltipText(
            !user?.attributes?.email?.trim()?.length
              ? "—"
              : maskEmail(user?.attributes?.email),
            30,
          )}
        </Box>
      </Stack>
    </Stack>
  );
};

export default AvatarBodyCell;
