import {
  Box,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  TextField,
  Tooltip,
} from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
import { useEffect } from "react";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const SurveyContent = ({
  surveyData,
  setSurveyData,
  selectedTap,
  selectedTapContent,
  setSelectedTapContent,
}) => {
  const intl = useIntl();

  const fieldsData = surveyData?.mainData;
  const selectedTabData = surveyData?.selectedData?.[selectedTap];

  useEffect(() => {
    setSelectedTapContent("en_content");
  }, [selectedTap]);

  const handleChangeSelect = (event, field) => {
    surveyData.selectedData[selectedTap][field] = event.target.value;
    setSurveyData({ ...surveyData });
  };

  const selectShared = (fieled) => {
    return (
      <Box
        className={`survey-chat-field ${selectedTapContent === "en_content" ? "en-contant" : "ar-contant"}`}
      >
        <InputLabel>
          {CheckValueLocale(
            selectedTapContent === "en_content"
              ? `${fieled}_en`
              : `${fieled}_ar`,
            "",
            {},
            intl,
          )}
        </InputLabel>
        <Select
          value={
            selectedTabData?.[
              selectedTapContent === "en_content"
                ? `${fieled}_en`
                : `${fieled}_ar`
            ]
          }
          onChange={(e) =>
            handleChangeSelect(
              e,
              selectedTapContent === "en_content"
                ? `${fieled}_en`
                : `${fieled}_ar`,
            )
          }
          MenuProps={{
            MenuListProps: {
              id: `${selectedTapContent === "en_content" ? "en-contant-survey-menu-list" : "ar-contant-survey-menu-list"}`, // Set your desired ID here
            },
          }}
        >
          {fieldsData?.[
            selectedTapContent === "en_content"
              ? `${fieled}_en`
              : `${fieled}_ar`
          ]?.map((item) => (
            <MenuItem value={item?.id}>{item?.text}</MenuItem>
          ))}
        </Select>
      </Box>
    );
  };

  const handleChangeText = (event, field) => {
    surveyData.selectedData[selectedTap][field] = event.target.value;
    setSurveyData({ ...surveyData });
  };

  const textShared = (fieled) => {
    let limitEror =
      selectedTabData?.[
        selectedTapContent === "en_content" ? `${fieled}_en` : `${fieled}_ar`
      ]?.trim()?.length >= fieldsData?.[`${selectedTap?.toLowerCase()}_limit`];
    return (
      <Box
        className={`survey-chat-field ${selectedTapContent === "en_content" ? "en-contant" : "ar-contant"}`}
      >
        <Box className="survey-chat-text-title">
          <InputLabel>
            {CheckValueLocale(
              selectedTapContent === "en_content"
                ? `${fieled}_en`
                : `${fieled}_ar`,
              "",
              {},
              intl,
            )}
          </InputLabel>
          <Tooltip
            placement="top"
            arrow
            title={CheckValueLocale(
              selectedTapContent === "en_content"
                ? `${fieled}_en_tooltip`
                : `${fieled}_ar_tooltip`,
              "",
              {},
              intl,
            )}
          >
            <ErrorOutlineIcon />
          </Tooltip>
        </Box>

        <TextField
          error={limitEror}
          onChange={(e) =>
            handleChangeText(
              e,
              selectedTapContent === "en_content"
                ? `${fieled}_en`
                : `${fieled}_ar`,
            )
          }
          value={
            selectedTabData?.[
              selectedTapContent === "en_content"
                ? `${fieled}_en`
                : `${fieled}_ar`
            ]
          }
          variant="outlined"
          helperText={
            limitEror
              ? CheckValueLocale(
                  selectedTapContent === "en_content"
                    ? "survey_text_limit_en"
                    : "survey_text_limit_ar",
                  "",
                  { num: fieldsData?.[`${selectedTap?.toLowerCase()}_limit`] },
                  intl,
                )
              : null
          }
        />
      </Box>
    );
  };

  return (
    <>
      <TabContext value={selectedTapContent}>
        <Box className="contant-tap-container">
          <TabList onChange={(_, newValue) => setSelectedTapContent(newValue)}>
            {["en_content", "ar_content"]?.map((tap) => (
              <Tab
                key={tap}
                label={CheckValueLocale(tap, "", {}, intl)}
                value={tap}
              />
            ))}
          </TabList>
        </Box>
      </TabContext>
      <Box className="survey-chat-fields-container">
        {selectShared("rate_qs")}
        {selectShared("feed_qs")}
        {textShared("thankMsg")}
        {textShared("expiredMsg")}
      </Box>
    </>
  );
};

export default SurveyContent;
