import { useQuery } from "@tanstack/react-query";
import { MainMonitorsPage } from "services/api/mainMonitorsPage";

export const useMonitorInfo = ({ monitorId, queryData, enabled = true }) => {
  return useQuery({
    queryKey: ["monitors", { monitorId, queryData }],
    queryFn: () => MainMonitorsPage.getMonitorInfo({ monitorId, queryData }),
    enabled,
    select: (data) => data?.data?.data,
  });
};
