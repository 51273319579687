import { useState, useEffect } from "react";
import _ from "lodash";
import facebook from "images/engagements-images/facebook.svg";
import Instagram from "images/engagements-images/Instagram.svg";
import xPlatform from "images/social-icons/x-platform-icon.svg";
import facebookCxm from "images/engagements-images/fb-dm-eng.svg";
import InstagramCxm from "images/engagements-images/ig-dm-eng.svg";
import twitterCxm from "images/engagements-images/xp-dm-eng.svg";
import * as Yup from "yup";
import {
  CheckValueLocale,
  classNames,
  getDatasourceIconName,
  getSocialIcon,
  isArabic,
} from "utils/helpers";
import { Box, Typography, Checkbox } from "@mui/material";
import TruncatedTooltip from "shared/truncatedTooltip/truncatedTooltip.js";

export const useFilteredDataBySource = (selectedData, dataType) => {
  // dataType: monitors or channels
  const [filteredData, setFilteredData] = useState({
    facebook: [],
    instagram: [],
    twitter: [],
  }); //the object where we will store the filtered data and return it.

  useEffect(() => {
    // Convert stepOne monitor/channel IDs to strings for comparison
    const stepOneIds = selectedData?.stepOne[dataType]?.map(String);

    // Filter data based on selected IDs and data source
    const filteredDataBySource = selectedData?.mainData[dataType]?.reduce(
      (acc, item) => {
        if (
          stepOneIds?.includes(item?.id) &&
          item?.attributes?.data_sources?.length > 0 &&
          !item?.attributes?.is_dm
        ) {
          item?.attributes?.data_sources?.map?.((el) => {
            const sourceName = el?.toLowerCase?.();
            let isIgKWMonitor =
              item?.attributes?.monitor_type_name == "KEYWORD" &&
              sourceName == "instagram" &&
              dataType == "monitors";
            if (!isIgKWMonitor) acc[sourceName]?.push(item);
          });
        }
        return acc;
      },
      { facebook: [], instagram: [], twitter: [] }, // Initialize accumulator with empty arrays
    );

    // Set the filtered data with duplicates removed
    setFilteredData({
      facebook: _.uniqBy(filteredDataBySource?.facebook, (item) => item?.id),
      instagram: _.uniqBy(filteredDataBySource?.instagram, (item) => item?.id),
      twitter: _.uniqBy(filteredDataBySource?.twitter, (item) => item?.id),
      isSetupFilteredDone: true,
    });
  }, [selectedData?.stepOne?.[dataType]]);

  return filteredData;
};

export const useSocialAccounts = (selectedData, socialNetwork) => {
  // this function is used to get the accounts for the selected social network
  // by combining the accounts from the two arrays ${socialNetwork}_CxmAcc and ${socialNetwork}_SlaAcc steps and removing the duplicates
  const [accounts, setAccounts] = useState([]);
  const [isSetupFilteredDone, setIsSetupFilteredDone] = useState(false);

  useEffect(() => {
    // Combine both arrays into one
    const combined = [
      ...selectedData?.stepOne?.[`${socialNetwork}_CxmAcc`],
      ...selectedData?.stepOne?.[`${socialNetwork}_SlAcc`],
    ];
    setAccounts(_.uniqBy(combined, (account) => account?.id));
    setIsSetupFilteredDone(true);
  }, [
    selectedData?.stepOne?.[`${socialNetwork}_CxmAcc`],
    selectedData?.stepOne?.[`${socialNetwork}_SlAcc`],
  ]);

  return { accounts, isSetupFilteredDone };
};

export const countChangesInAutomatedPublicReplies = (
  oldState,
  newState,
  areNotSame,
) => {
  let changes = 0;
  // this function is used to count the changes in the automated public replies
  // by comparing the old state with the new state and counting the changes in the status, accounts, monitors and replies
  // we need this change count to show the user in the mainEditFooter component.
  for (let dataSource in newState) {
    if (newState[dataSource]?.status !== oldState[dataSource]?.status) {
      changes += 1;
    }
    if (
      areNotSame(oldState[dataSource]?.accounts, newState[dataSource]?.accounts)
    ) {
      changes += 1;
    }
    if (
      areNotSame(oldState[dataSource]?.monitors, newState[dataSource]?.monitors)
    ) {
      changes += 1;
    }
    if (
      areNotSame(
        oldState[dataSource]?.replies,
        newState[dataSource]?.replies,
        false,
        true,
      )
    ) {
      changes += 1;
    } else if (
      oldState[dataSource]?.replies?.length ===
      newState[dataSource]?.replies?.length
    ) {
      let replyChanged = false;
      newState[dataSource]?.replies?.forEach((reply, index) => {
        if (
          reply?.reply_text !== oldState[dataSource]?.replies[index]?.reply_text
        ) {
          changes += 1;
          replyChanged = true; // Set a flag to indicate a change
        }
      });

      if (replyChanged) {
        break; // Break out of the outer loop if any reply has changed
      }
    }
  }

  return changes;
};

export const getAutomatedPublicRepliesState = (requesData) => {
  // this function is used to get the automated public replies state from the response data
  //and make the data more readable by converting the array of automated public replies to an object
  const result = {
    TWITTER: {
      accounts: [],
      monitors: [],
    },
    FACEBOOK: {
      accounts: [],
      monitors: [],
    },
    INSTAGRAM: {
      accounts: [],
      monitors: [],
    },
  };
  requesData?.data?.data?.automated_public_replies?.forEach((item) => {
    const { data_source_name } = item?.attributes;
    if (result[data_source_name]) {
      result[data_source_name] = { ...item?.attributes };
    }
  });

  return { ...result };
};

export const socialIcons = (type) => {
  let icons = {
    FACEBOOK: <img src={facebook} />,
    INSTAGRAM: <img src={Instagram} />,
    TWITTER: <img src={xPlatform} alt="x-platform-logo" />,
    FACEBOOK_cxm: <img src={facebookCxm} />,
    INSTAGRAM_cxm: <img src={InstagramCxm} />,
    TWITTER_cxm: <img src={twitterCxm} alt="x-platform-dm-logo" />,
  };
  return icons[type];
};

export const publicReplyValidationSchema = Yup.object({
  // this is the validation schema for the public reply form
  // used in the add - edit public reply modal
  message: Yup.string()
    .test("is-not-all-spaces", "", (value) => {
      return value.trim().length > 0;
    })
    .max(280, "public_replies_max_280")
    .required("required"),
  dataSource: Yup.string().required("required"),
});

export const transformAutomatedPublicRepliesPayload = (data) => {
  // this function is used to transform the automated public replies data to the format that the backend expects
  const automatedReplies = {};
  for (const dataSource in data) {
    if (data?.hasOwnProperty(dataSource)) {
      const dataSourceData = data[dataSource];
      const replies = {
        deleted: [],
        updated: [],
        new: [],
      };

      if (dataSourceData?.replies && Array?.isArray(dataSourceData?.replies)) {
        // Check if replies exists and is an array
        dataSourceData?.replies?.forEach((reply) => {
          if (reply?.updated) {
            replies?.updated?.push({
              id: reply?.id,
              reply_text: reply?.reply_text,
            });
          } else if (reply?.new || !reply?.id) {
            replies?.new?.push(reply?.reply_text);
          }
        });
      }

      if (
        dataSourceData?.deletedReplies &&
        Array?.isArray(dataSourceData?.deletedReplies)
      ) {
        dataSourceData?.deletedReplies?.forEach((reply) => {
          replies?.deleted?.push(reply);
        });
      }

      automatedReplies[dataSource] = {
        status: dataSourceData?.status,
        monitors: dataSourceData?.monitors,
        reply_by: dataSourceData?.accounts,
        replies: replies,
      };
    }
  }
  return { automated_replies: automatedReplies };
};

export const automatedReplyDataNotCompleted = (data) => {
  // when sending the automated_replies object data to the backend we need to validate if one of
  // monitors and reply_by(accounts) fileds arrays is empty (when the status of this data_source is true) we will show an error message to the user
  // and after knowing that there is an error by this function we will use the getAutomatedReplyEmptyFields function
  // to know which fields are empty
  const automatedReplies = data?.automated_replies;

  for (const dataSource in automatedReplies) {
    if (automatedReplies?.hasOwnProperty(dataSource)) {
      const dataSourceData = automatedReplies[dataSource];
      if (dataSourceData?.status) {
        // Check first if status is true
        if (
          dataSourceData?.monitors?.length === 0 ||
          dataSourceData?.reply_by?.length === 0
        ) {
          return true; // Return true if either array is empty
        }
      }
    }
  }
  return false; // Return false if no empty arrays are found
};

export const getAutomatedReplyEmptyFields = (data) => {
  // when sending the automated_replies object data to the backend we need to validate if one of
  // monitors and reply_by(accounts) fileds arrays is empty we will show an error message to the user
  // and after knowing that there is an error by the (automatedReplyDataNotCompleted) function
  // we will use this function to know which fields are empty

  const automatedReplies = data?.automated_replies;
  const emptyFields = {};

  for (const dataSource in automatedReplies) {
    const dataSourceData = automatedReplies[dataSource];
    if (dataSourceData?.status) {
      // Check if status is true
      // as if it is falsse there is no problem if monitors or reply_by(accounts) are empty
      const errorFields = {};

      if (dataSourceData?.monitors?.length === 0) {
        errorFields.monitors = true;
      }

      if (dataSourceData?.reply_by?.length === 0) {
        errorFields.reply_by = true;
      }

      if (Object.keys(errorFields)?.length > 0) {
        emptyFields[dataSource] = errorFields;
      }
    }
  }

  return emptyFields;
};

export const getActualSlectedMonitors = (ids, monitors, channels) => {
  // this function is used to get the actual selected monitors and channels
  // as the backend send the selected monitors and channels as an array of ids
  // but the user may select and unselect some monitors and channels so we need to know the actual selected monitors and channels.
  let count = 0;

  for (const id of ids) {
    const foundInMonitors = monitors?.some(
      (monitor) => monitor?.attributes?.id === id,
    );
    const foundInChannels = channels?.some(
      (channel) => channel?.attributes?.id === id,
    );

    if (foundInMonitors || foundInChannels) {
      count++;
    }
  }

  return count;
};

export const getActualSelectedAccounts = (ids, accounts) => {
  //the same as the getActualSlectedMonitors function but for the accounts
  let count = 0;

  for (const id of ids) {
    const foundInAccounts = accounts?.some(
      (account) => account?.attributes?.id === id,
    );

    if (foundInAccounts) {
      count++;
    }
  }

  return count;
};

export const filterMonitorsAndIDs = (
  selectedMonitorsChannels,
  allMonitorsChannels,
) => {
  return selectedMonitorsChannels.filter((item) =>
    allMonitorsChannels?.some((element) => +element.id === +item),
  );
};

export const socialMediaPlatforms = (intl) => [
  {
    label: CheckValueLocale("instagram", "", {}, intl),
    id: "instagram",
    icon: getDatasourceIconName("INSTAGRAM"),
  },
  {
    label: CheckValueLocale("facebook", "", {}, intl),
    id: "facebook",
    icon: getDatasourceIconName("FACEBOOK"),
  },
  {
    label: CheckValueLocale("twitter", "", {}, intl),
    id: "twitter",
    icon: getDatasourceIconName("XPLATFORM"),
  },
];

export const MonitorAndChannelsMenuItem = ({ item, isSelected }) => {
  const { name, paused, data_sources, is_dm } = item?.attributes;
  return (
    <>
      <Checkbox checked={isSelected} />
      <Box className="engagement-filter-menu-item-option-container">
        <Box className="engagement-menu-item-option">
          <Box
            className={classNames(
              "engagement-menu-item-option-status",
              paused ? "paused" : "",
            )}
          />
          <TruncatedTooltip
            title={name}
            enablePropperProps={true}
            tooltipClassName={classNames(
              "engagement-menu-item-option-tooltip",
              isArabic(name) ? "arabic-tooltip" : "",
            )}
            placement="top"
            className="engagement-menu-item-option-name"
          />
          <Box className="engagement-setting-datasources">
            {data_sources?.map?.((data_source) => {
              let dataSource = data_source?.toUpperCase();
              if (is_dm) {
                dataSource += "_private";
              }
              return <Box key={dataSource}>{getSocialIcon(dataSource)}</Box>;
            })}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export const SelectItemsTitle = ({ title, intl }) => (
  <Typography className="menu-item-header">
    {CheckValueLocale(title, "", {}, intl)}
  </Typography>
);
