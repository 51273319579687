import { useEffect, useState } from "react";
import LoginController from "services/controllers/loginController.js";
import CompaniesController from "services/controllers/companiesController.js";
import { CheckValueLocale, emailValidator } from "utils/helpers/index.js";
import UserAccount from "services/controllers/userAccountController";
import Services from "services/api/Services.js";
import { Userpilot } from "userpilot";
import { useIntl } from "react-intl";
import { useNormalLogin } from "../hooks/useNormalLogin";
import { use2FAStepsLogin } from "../hooks/use2FAStepsLogin";
import { useHubSpotLogin } from "../hooks/useHubSpotLogin";
import { use2FAStepsHubSpotLogin } from "../hooks/use2FAStepsHubSpotLogin";

const LoginFormFunctions = () => {
  const intl = useIntl();

  //To Handle 2FA in Login page
  const [data2FALoginPage, setData2FALoginPage] = useState({
    title: "",
    description: "",
    step: 0,
  });
  const [codeVerify, setCodeVerify] = useState("");
  const [errorCodeMsg, setErrorCodeMsg] = useState("");
  const [snackBar, setSnackBar] = useState({
    open: false,
    msg: "",
    title: "",
    severity: "",
  });
  /////////////////////// Hooks ///////////////////////////////
  // Normal Login
  const { mutate: handleNormalLogin, isPending: isLoadingNormalLogin } =
    useNormalLogin();
  const { mutate: handle2FAStepsLogin, isPending: isLoading2FAStepsLogin } =
    use2FAStepsLogin();
  // HubSpot Login
  const { mutate: handleHubSpotLogin, isPending: isLoadingHubSpotLogin } =
    useHubSpotLogin();
  const {
    mutate: handle2FAStepsHubSpotLogin,
    isPending: isLoading2FAStepsHubSpotLogin,
  } = use2FAStepsHubSpotLogin();

  //this state to handle mutate call in the step login
  const [handleTwoFactorAuthState, setHandleTwoFactorAuthState] =
    useState("login1");

  const [stats, setStats] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    wrongInput: false,
    errorText: CheckValueLocale("invalid_email_or_password", "", {}, intl),
    activeProducts: [],
    showForgetPassword: false,
    showSnackBarError: null,
    showSnackBarWarning: null,
    showSnackBarSuccess: null,
    checked: Services?.passCheckBox ? true : false,
    lang:
      window?.localStorage?.lang === undefined
        ? window?.navigator?.language?.split("-")[0]
        : window?.localStorage?.lang,
    isForgetPassword: false,
    loginLoading: false,
    forgetPasswordLoading: false,
    resetPasswordLoading: false,
    newUserLoading: false,
    ssoLoader: false,
    isResetPassword: false,
    isNewUser: false,
    verify: false,
  });
  const intialStats = {
    email: "",
    password: "",
    confirmPassword: "",
    wrongInput: false,
    errorText: CheckValueLocale("invalid_email_or_password", "", {}, intl),
    verify: false,
  };
  const [errors, setErrors] = useState([]);
  const [errorFields, setErrorFields] = useState({});
  const validatePassword = (passwordText) => {
    const regex_uppercase = /[A-Z]/;
    const regex_lowercase = /[a-z]/;
    const regex_number = /[0-9]/;

    let errors = [];

    if (passwordText?.length < 8) {
      errors?.push("chars_num_err");
    }
    if (!passwordText?.match(regex_uppercase)) {
      errors?.push("uppercase_err");
    }
    if (!passwordText?.match(regex_lowercase)) {
      errors?.push("lowercase_err");
    }
    if (!passwordText?.match(regex_number)) {
      errors?.push("number_err");
    }
    setErrors(errors);
  };
  const handleSnackbarClose = () => {
    setStats({
      ...stats,
      showSnackBarError: null,
      showSnackBarWarning: null,
      showSnackBarSuccess: null,
    });
  };
  const handleInputChange = (event) => {
    if (event?.target?.name === "password") {
      validatePassword(event?.target?.value);
    }
    if (event?.target?.name === "checked") {
      setStats({ ...stats, checked: event?.target?.checked });
    } else {
      setStats({ ...stats, [event?.target?.name]: event?.target?.value });
    }
  };
  const handleVerifySuccess = () => {
    setStats({
      ...stats,
      verify: true,
    });
  };

  const handleVerifyExpire = () => {
    setStats({
      ...stats,
      verify: false,
    });
  };

  const callApiHubspotLogin = (url) => {
    let urlParams = new URLSearchParams(url);
    let redirectUrl = urlParams?.get("redirect_url");
    const queryData = {
      email: stats?.email,
      password: stats?.password,
      lang: stats?.lang,
      redirect_url: redirectUrl,
    };
    callApiLogin2FA(queryData, true);
  };

  //handle errors with 2FA verify code
  const handleCodeErrors = (error) => {
    setErrorCodeMsg({
      msg:
        error?.message == "OTP Code is incorrect"
          ? error?.data?.remaining_attempts < 4
            ? "error_two_factor_incorrect_remaining_attempts"
            : "error_two_factor_incorrect"
          : error?.message == "OTP code is expired"
            ? "error_two_factor_code_expired"
            : error?.message, // need to handle

      remaining_attempts: error?.data?.remaining_attempts,
    });
  };

  // handle 2FA and login with normal and hubSpot
  const callApiLogin2FA = (queryData, hubSpotLogin) => {
    // when hubSpotLogin is true it will call the first api hubSpot login
    // when handleTwoFactorAuthState === "hubSpot2" it will call the steps 2FA for hubSpot login
    // when handleTwoFactorAuthState === "login1" it will call the first api normal login
    // when handleTwoFactorAuthState === "login2" it will call the steps 2FA for normal login
    const handleCallApiLogin2FA = hubSpotLogin
      ? handleHubSpotLogin
      : handleTwoFactorAuthState === "hubSpot2"
        ? handle2FAStepsHubSpotLogin
        : handleTwoFactorAuthState === "login1"
          ? handleNormalLogin
          : handle2FAStepsLogin;

    handleCallApiLogin2FA(
      {
        ...queryData,
      },
      {
        onSuccess: (response) => {
          const data = response?.data?.data;
          //hubSpotLogin = true only for first step hubSpot login
          handleTwoFactorAuthSteps(data, hubSpotLogin);
        },
        onError: (error) => {
          const errorMsg = error?.errorMsg?.response?.data;

          // to appear snackBar in the correct place
          localStorage?.setItem("lang", stats?.lang);
          // to handle Unauthorized error for 2FA login (reload page)
          if (
            data2FALoginPage?.step !== 0 &&
            errorMsg?.exception?.status === 401
          ) {
            window?.location?.reload();
          }

          if (
            errorMsg?.message === "Cannot request OTP before last one expires"
          ) {
            // to show error snackBar when user try to login before the last OTP expires
            handleErrorSnackBar(
              "previous_otp_not_expired_to_select_another_method",
            );
          } else if (
            errorMsg?.message ===
              "Maximum unverified OTP requests limit reached" ||
            errorMsg?.exception?.message === "ACCOUNT_IS_LOCKED_FOR_OTP"
          ) {
            // to show error snackBar when acount is blocked
            handleErrorSnackBar(
              "email_bloced_description",
              "email_bloced_header",
            );
          } else if (errorMsg?.message === "User email is bounced") {
            handleErrorSnackBar(
              "email_bounced_description",
              "email_bounced_title",
            );
          } else if (errorMsg?.message === "Failed to send OTP") {
            // to show error snackBar when acount is blocked
            handleErrorSnackBar(
              "phone_number_failed_description",
              "phone_number_failed_title",
            );
          }
          //handle error for normal and hobSpot login in first step (old errors before 2FA)
          else if (errorMsg?.exception?.message === "COMPANY_NOT_ENABLED") {
            return setStats({
              ...stats,
              showSnackBarError: "showCxmCore",
              wrongInput: false,
            });
          } else if (errorMsg?.exception?.message === "COMPANY_NOT_STARTED") {
            return setStats({
              ...stats,
              showSnackBarError: "showNotStarted",
              wrongInput: false,
            });
          } else if (errorMsg?.exception?.message === "SUSPENDED_COMPANY") {
            return setStats({
              ...stats,
              showSnackBarError: "showError",
              wrongInput: false,
            });
          } else if (
            errorMsg?.exception?.exception_class === "deactivated_user_error"
          ) {
            return setStats({
              ...stats,
              showSnackBarWarning: "showDeactivated",
              wrongInput: false,
            });
          } else if (errorMsg?.exception?.message === "ACCOUNT_IS_LOCKED") {
            return setStats({
              ...stats,
              errorText: CheckValueLocale("failed_login_locked", "", {}, intl),
              wrongInput: true,
            });
          } else if (data2FALoginPage?.step === 0) {
            return setStats({
              ...stats,
              errorText: CheckValueLocale(
                "invalid_email_or_password",
                "",
                {},
                intl,
              ),
              wrongInput: true,
            });
          } else {
            // show error for 6 digits code
            if (data2FALoginPage?.step === 2 || data2FALoginPage?.step === 5)
              handleCodeErrors(error?.errorMsg?.response?.data);
            // to show error snackBar
            else handleErrorSnackBar("try_again", "something_went_wrong");
          }
        },
      },
    );
  };

  // To Handle staps of 2FA
  const handleTwoFactorAuthSteps = (response, hubSpotLogin) => {
    switch (response?.data?.step) {
      case 1:
        // static page or list of methods
        setData2FALoginPage({
          title: "two_factor_auth_enabled_required",
          description: "two_factor_auth_enabled_required_description",
          textBtn: "enable_now_btn",
          step: 1,
          methods: response?.data?.enabled_2fa_types || [],
        });

        // to handle steps for normal or hubSpot login
        setHandleTwoFactorAuthState(hubSpotLogin ? "hubSpot2" : "login2");
        break;
      case 2:
        // first step verify by email to (SMS or Auth APP)
        setData2FALoginPage({
          title: "verify_your_identity_title",
          description: "text_enable_two_factor_auth_email",
          textBtn: "verify",
          contact: response?.data?.send_to || "",
          showSixCodeInput: true,
          showResendCode: true,
          expires_at: response?.data?.expires_at,
          step: 2,
        });
        break;
      case 3:
        // this step for enter phone number
        setData2FALoginPage({
          title: "add_your_phone_number",
          description: "text_enter_phone_number_to_enable_sms",
          textBtn: "next_step",
          showPhoneNumber: true,
          step: 3,
        });
        break;
      case 4:
        //this step for scan QR code
        setData2FALoginPage({
          title: "set_up_authenticator_app",
          description: "text_scan_qr_code_to_enable_app",
          textBtn: "next_step",
          qr_code: response?.data?.qr_code, ///////
          totp_secret: response?.data?.totp_secret, ///////
          step: 4,
        });
        break;
      case 5:
        // this step for the last step for verify all methods
        setData2FALoginPage({
          title: "verify_your_identity_title",
          description: `text_enable_two_factor_auth_${response?.data?.method}${response?.data?.method === "email" ? "_login" : ""}`,
          textBtn: "verify",
          contact: response?.data?.send_to,
          expires_at: response?.data?.expires_at,
          showSixCodeInput: true,
          showRememberMe: response?.data?.show_remember_me_option,
          showResendCode: response?.data?.method !== "totp",
          showTryAnotherWay: response?.data?.show_try_another_way_option,
          step: 5,
        });

        // to handle steps for normal login if user second login with 2FA
        setHandleTwoFactorAuthState(hubSpotLogin ? "hubSpot2" : "login2");
        break;

      default:
        if (
          data2FALoginPage?.step === 5 &&
          localStorage?.getItem("two_fa_success_login") === false
        )
          localStorage?.setItem("two_fa_success_login", true);
        // handle old Code
        if (hubSpotLogin || handleTwoFactorAuthState == "hubSpot2") {
          //hubSpot login
          if (response?.data?.id) createHutspotUser();
        } else {
          //normal login
          Userpilot.identify(response?.data?.id);
          if (response?.data?.id) return getCompanyProducts();
        }
    }
  };

  //if u need to display snackBar error
  const handleErrorSnackBar = (msg, title) => {
    setSnackBar({
      open: true,
      msg: msg || "",
      title: title || "",
      severity: "error",
    });
  };

  const handleCloseSnackBar = () => {
    setSnackBar({
      open: false,
      msg: "",
      title: "",
      severity: "",
    });
  };

  const handleUserLogin = () => {
    let errorField = {};
    if (!stats?.email) {
      errorField["email"] = "this_field_required";
    }
    if (!stats?.password) {
      errorField["password"] = "this_field_required";
    }
    if (window?.location?.hostname !== "testing-cxm.lucidya.com") {
      if (!stats?.verify) {
        errorField["verify"] = "this_field_required";
      }
    }
    setErrorFields(errorField);
    if (!Object.keys(errorField)?.length) {
      let url = window?.location?.search;
      let language = localStorage?.getItem("lang");
      localStorage?.clear();

      localStorage?.setItem("lang", language || "en");

      //check user hubspot or not
      if (url?.includes("?redirect_url")) {
        callApiHubspotLogin(url);
      } else {
        callApiLogin2FA({
          email: stats?.email,
          password: stats?.password,
          lang: stats?.lang,
        });
      }
    }
  };
  useEffect(() => {
    setStats((prev) => ({
      ...prev,
      loginLoading:
        isLoadingNormalLogin ||
        isLoading2FAStepsLogin ||
        isLoadingHubSpotLogin ||
        isLoading2FAStepsHubSpotLogin,
    }));
  }, [
    isLoadingNormalLogin,
    isLoading2FAStepsLogin,
    isLoadingHubSpotLogin,
    isLoading2FAStepsHubSpotLogin,
  ]);

  const getCompanyProducts = () => {
    CompaniesController.getCompanyProducts().then((data) => {
      const activeProducts = data?.data?.filter((product) => product?.active);
      //SET ALL PRODUCTS ID IN LOCAL STORAGE TEMPORARLY TO TEST BACKEND IN INSIGHTS
      // eslint-disable-next-line
      var systemsList = new Array();

      activeProducts?.map((product) => {
        systemsList?.push(product?.name);
        if (product?.name === "CXM") {
          return window?.localStorage?.setItem(
            "cxm_id",
            JSON.stringify(product?.id),
          );
        } else if (product?.name === "SM") {
          window?.localStorage?.setItem("sm_id", JSON.stringify(product?.id));
        }
      });

      //END OF TEMP. TESTING CODE
      if (activeProducts?.length > 0) {
        window?.localStorage?.setItem(
          "activeProducts",
          JSON.stringify(activeProducts),
        );
        if (systemsList?.length >= 0) {
          window.location.href = `/home_page`;
        }
        setStats({ ...stats, loginLoading: false });
      }
    });
  };
  //create user hutspot
  const createHutspotUser = () => {
    UserAccount.createUserHutspot().then((result) => {
      if (result?.data) {
        window?.open(result?.data?.url, "_self");
      }
    });
  };

  const handleShowForgetPassword = () => {
    setStats({
      ...stats,
      ...intialStats,
      showForgetPassword: true,
    });
    setErrors([]);
    setErrorFields({});
  };

  const handleBkToSignIn = () => {
    setStats({
      ...stats,
      ...intialStats,
      showForgetPassword: false,
    });
    setErrors([]);
    setErrorFields({});
  };

  const handleUserForgetPassword = () => {
    let errorField = {};
    if (!emailValidator(stats?.email)) {
      errorField["email"] = "email_format_is_incorrect";
    }
    if (!stats?.email) {
      errorField["email"] = "this_field_required";
    }
    if (!stats?.verify) {
      errorField["verify"] = "this_field_required";
    }
    setErrorFields(errorField);
    if (!Object.keys(errorField)?.length) {
      setStats({ ...stats, forgetPasswordLoading: true });
      LoginController.userForgetPassword(stats?.email).then((data) => {
        if (data?.data?.status == 200) {
          return setStats({
            ...stats,
            forgetPasswordLoading: false,
            showForgetPassword: false,
            isForgetPassword: true,
            showSnackBarSuccess: "showSuccess",
          });
        } else {
          return setStats({
            ...stats,
            forgetPasswordLoading: false,
            wrongInput: true,
            errorText: CheckValueLocale(
              "please_enter_valid_email_address_login",
              "",
              {},
              intl,
            ),
          });
        }
      });
    }
  };
  const handleUserResetPassword = (token) => {
    let errorField = {};
    if (stats?.password !== stats?.confirmPassword) {
      errorField["confirmPassword"] = "confirm_not_match_new_password";
    }
    if (!stats?.password) {
      errorField["password"] = "this_field_required";
    }
    if (!stats?.confirmPassword) {
      errorField["confirmPassword"] = "this_field_required";
    }
    if (errors.length) {
      errorField["errorsPassword"] = "password_weak";
    }
    setErrorFields(errorField);
    if (!Object.keys(errorField)?.length) {
      setStats({ ...stats, resetPasswordLoading: true });
      LoginController.userResetPassword(token, stats?.password).then((data) => {
        if (data?.data?.status === 200) {
          return setStats({
            ...stats,
            isResetPassword: true,
            showSnackBarSuccess: "showSuccess",
          });
        } else {
          return setStats({
            ...stats,
            resetPasswordLoading: false,
            wrongInput: true,
          });
        }
      });
    }
  };

  const handleActivateSignUp = (invitationToken, userEmail) => {
    let errorField = {};
    if (stats?.password !== stats?.confirmPassword) {
      errorField["confirmPassword"] = "confirm_not_match_new_password";
    }
    if (!stats?.password) {
      errorField["password"] = "this_field_required";
    }
    if (!stats?.confirmPassword) {
      errorField["confirmPassword"] = "this_field_required";
    }
    if (errors?.length) {
      errorField["errorsPassword"] = "password_weak";
    }
    if (!stats?.checked) {
      errorField["checked"] = "this_field_required";
    }
    setErrorFields(errorField);
    if (!Object.keys(errorField)?.length) {
      setStats({ ...stats, newUserLoading: true });
      LoginController.activateSignUp(
        invitationToken,
        userEmail,
        stats?.password,
      ).then((data) => {
        if (data?.data?.status == 200) {
          return setStats({
            ...stats,
            isNewUser: true,
            showSnackBarSuccess: "showSuccess",
          });
        } else {
          return setStats({
            ...stats,
            newUserLoading: false,
            wrongInput: true,
            errorText: CheckValueLocale(
              data?.errorMsg?.response?.data?.msg?.toLocaleLowerCase(),
              "",
              {},
              intl,
            ),
          });
        }
      });
    }
  };

  const handleSSOlogin = () => {
    setStats({ ...stats, ssoLoader: true });
    window.location.href = Services?.baseRoute + "/api/v3/auth/sso/login";
  };

  return {
    stats,
    errors,
    handleInputChange,
    handleUserLogin,
    handleSnackbarClose,
    errorFields,
    handleShowForgetPassword,
    handleBkToSignIn,
    handleUserForgetPassword,
    handleUserResetPassword,
    handleActivateSignUp,
    handleSSOlogin,
    handleVerifySuccess,
    handleVerifyExpire,
    data2FALoginPage,
    setData2FALoginPage,
    callApiLogin2FA,
    errorCodeMsg,
    setErrorCodeMsg,
    codeVerify,
    setCodeVerify,
    snackBar,
    handleErrorSnackBar,
    handleCloseSnackBar,
  };
};
export default LoginFormFunctions;
