import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { Box, Typography } from "@mui/material";
import "./delete-accounts-list.scss";
import PopupModal from "components/popupModal";
import "./duplicate-accounts-list.scss";

const DuplicateAccountsListModal = ({
  modalData,
  handleClose,
  handleDuplicateAccountsList,
  buttonLoading,
}) => {
  const intl = useIntl();
  let title = CheckValueLocale("accounts_list_duplicate_text1", "", {}, intl);
  title = title?.replace("#", `${modalData?.name}`);
  let modalBody = (
    <Box className="shared-dialog-body">
      <Box component={"p"}>{title}</Box>
      <Box>
        <Box>
          <Typography
            variant="body1"
            component="span"
            className="typography-message-bold"
          >
            {CheckValueLocale(
              "duplicate_accounts_list_general_message",
              "",
              {},
              intl,
            )}
          </Typography>
        </Box>
      </Box>
    </Box>
  );

  return (
    <PopupModal
      title={CheckValueLocale("duplicate_accounts_list", "", {}, intl)}
      body={modalBody}
      leftBtn={CheckValueLocale("cancel", "", {}, intl)}
      rightBtn={CheckValueLocale(
        "duplicate_account_list_confirm",
        "",
        {},
        intl,
      )}
      open={modalData?.open || false}
      close={handleClose}
      accept={handleDuplicateAccountsList}
      minWidth="600px"
      classeName="accounts-list-duplicate-dialog actions-buttons-shared"
      addClasses="accounts-list-duplicate-dialog-container"
      isRightBtnLoading={buttonLoading}
      disabled={buttonLoading}
    />
  );
};
export default DuplicateAccountsListModal;
