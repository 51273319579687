import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { FiltersServices } from "services/api/filterServices";

let getMonitorFiltersFiledsCancelToken;
export const useGetMonitorFiltersFileds = () => {
  return useMutation({
    mutationFn: ({ section, monitor_id, source, product_id, monitor_type }) => {
      if (getMonitorFiltersFiledsCancelToken) {
        getMonitorFiltersFiledsCancelToken.cancel(
          "Operation canceled due to a new request.",
        );
      }
      getMonitorFiltersFiledsCancelToken = axios?.CancelToken?.source();
      const queryData = {
        params: {
          section,
          monitor_id,
          product_id,
          monitor_type,
        },
        cancelToken: getMonitorFiltersFiledsCancelToken?.token
      };
      return FiltersServices.getMonitorFiltersFileds({ source, queryData });
    },
  });
};
