import { api } from "./newServices";

const createAnalysisSingleTweet = (queryData) => {
  return api.post("api/v4/single_tweet_analysis_v2", queryData);
};


const getSingleTweetAnalysis = async (tweetId) => {
  const response = await api.get(`api/v4/single_tweet_analysis_v2/${tweetId}`);
  return response?.data;
};

const getSingleTweetAnalysisComments = async ({ monitorID, params }) => {
  const response = await api.get(
    `api/v3/social_listening/twitter/${monitorID}`,
    {
      params,
    },
  );
  return response?.data;
};

export const SingleTweetAnalysisServices = {
  getSingleTweetAnalysis,
  getSingleTweetAnalysisComments,
  createAnalysisSingleTweet
};
