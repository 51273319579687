import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import "./settings.scss";
import LuciController from "services/controllers/luciController";
import SettingsHeader from "./components/settingsHeader";
import SettingsFooter from "./components/settingsFooter";
import CircularLoading from "components/circularProgress";
import StepOne from "./steps/stepOne";
import StepTwo from "./steps/stepTwo";
import StepThree from "./steps/stepThree";
import StepFour from "./steps/stepFour";

const LuciSettings = (props) => {
  const { handleCloseSettings, getLuciStaticQuestions, pageParams } = props;
  const [stepNum, setStepNum] = useState(1);
  const [loading, setLoading] = useState(true);
  const [isSetup, setIsSetup] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [informerSteps, setInformerSteps] = useState({});
  const [selectedData, setSelectedData] = useState({});
  const [stepFieldsErr, setStepFieldsErr] = useState([]);

  let step1Req = [
    "industry",
    "business_type",
    "business_hq",
    "main_customer_facing_channels",
  ];
  let step4Req = [
    "customers_geographic",
    "customer_demographics_age",
    "customer_demographics_gender",
    "customer_demographics_income",
    "customer_demographics_Behaviors_preferences",
  ];

  useEffect(() => {
    getBusinessInformer();
  }, []);

  useEffect(() => {
    if (Object?.keys(informerSteps)?.length !== 0) {
      if (!isSetup) getBusinessDetails();
      else {
        Object.keys(informerSteps)?.forEach((step) => {
          if (step !== "step3") {
            informerSteps[step]?.map((field) => {
              if (
                field?.type === "multiple_choices" ||
                field?.type === "input_add"
              )
                selectedData[field?.key] = [];
              else selectedData[field?.key] = "";
            });
          } else
            selectedData["competitors"] = [
              {
                name: "",
                website_url: "",
                social_media_handler: [],
              },
            ];

          setSelectedData({ ...selectedData });
        });
        setLoading(false);
      }
    }
  }, [informerSteps]);

  const getBusinessInformer = () => {
    setLoading(true);
    LuciController.LuciBusinessDetailsInformel().then((data) => {
      setInformerSteps(data?.data?.informer);
      localStorage.setItem(
        "isLuciBusDet",
        data?.data?.luci_company_business_detail,
      );
      if (data?.data?.luci_company_business_detail === true) {
        setIsSetup(false);
        getBusinessDetails();
      } else setIsSetup(true);
    });
  };

  let companyId = window.localStorage.getItem("company_id");
  const getBusinessDetails = () => {
    LuciController.showLuciBusinessDetails(companyId).then((data) => {
      setSelectedData(data?.data?.business_details);
      setLoading(false);
    });
  };

  const validateURL = (url) => {
    const urlPattern =
      /^(https?:\/\/)?([a-zA-Z0-9]+\.)?[a-zA-Z0-9]+\.[a-zA-Z]{2,}(\/?)$/;
    return urlPattern.test(url);
  };

  const validateStepFields = (stepNum) => {
    setStepFieldsErr([]);
    const regex =
      /^(?! +$)[a-zA-Z0-9۰-۹"'@#^_() ]+(?: [a-zA-Z0-9۰-۹"'@#^_() ]+)*$/;
    const numOnlyReg = /^[0-9]+$/;

    if (stepNum === 1) {
      let stepOneErr = [];
      step1Req?.map((fieldKey) => {
        if (fieldKey === "business_hq") {
          if (selectedData[fieldKey]?.length < 1)
            stepOneErr?.push(`${fieldKey}_required`);
          else if (!regex.test(selectedData[fieldKey]))
            stepOneErr?.push(fieldKey);
        } else {
          if (
            selectedData[fieldKey] === "" ||
            selectedData[fieldKey]?.length === 0
          ) {
            stepOneErr?.push(fieldKey);
          }
        }
        setStepFieldsErr(stepOneErr);
      });

      if (
        selectedData["business_website"]?.length !== 0 &&
        !validateURL(selectedData["business_website"])
      )
        stepOneErr?.push("business_website");

      if (stepOneErr?.length > 0) return false;
      else return true;
    } else if (stepNum === 2) {
      let stepTwoErr = [];
      let numFields = [
        "number_of_employees",
        "annual_revenue",
        "years_in_operation",
      ];
      numFields?.map((fieldKey) => {
        if (selectedData[fieldKey]?.length > 0)
          if (!numOnlyReg.test(selectedData[fieldKey]))
            stepTwoErr?.push(fieldKey);
        setStepFieldsErr(stepTwoErr);
      });
      if (stepTwoErr?.length > 0) return false;
      else return true;
    } else if (stepNum === 4) {
      let stepFourErr = [];
      step4Req?.map((fieldKey) => {
        if (fieldKey === "customers_geographic") {
          if (selectedData[fieldKey]?.length < 1)
            stepFourErr?.push(`${fieldKey}_required`);
          else if (!regex.test(selectedData[fieldKey]))
            stepFourErr?.push(fieldKey);
        } else {
          if (
            selectedData[fieldKey] === "" ||
            selectedData[fieldKey]?.length === 0
          ) {
            stepFourErr?.push(fieldKey);
          }
        }
        setStepFieldsErr(stepFourErr);
      });
      if (stepFourErr?.length > 0) return false;
      else return true;
    }
  };

  const handleLuciSettings = () => {
    setSaveLoading(true);
    LuciController.updateLuciBusinessDetails(companyId, {
      business_details: selectedData,
    }).then((data) => {
      setSaveLoading(false);
      if (!data?.errorMsg) {
        handleCloseSettings();
        getLuciStaticQuestions(pageParams);
      }
    });
  };

  const handleLuciSetup = () => {
    setSaveLoading(true);
    LuciController.createLuciBusinessDetails({
      business_details: selectedData,
    }).then((data) => {
      setSaveLoading(false);
      if (!data?.errorMsg) {
        handleCloseSettings();
        getLuciStaticQuestions(pageParams);
      }
    });
  };

  const handleSettingsNext = () => {
    if (stepNum === 1) {
      if (!validateStepFields(1)) return;
      else setStepNum(stepNum + 1);
    } else if (stepNum === 2) {
      if (!validateStepFields(2)) return;
      else setStepNum(stepNum + 1);
    } else if (stepNum === 4) {
      if (!validateStepFields(4)) return;
      else {
        if (!isSetup) handleLuciSettings();
        else handleLuciSetup();
      }
    } else setStepNum(stepNum + 1);
  };

  const handleSettingsBack = () => {
    if (stepNum === 1) return handleCloseSettings();
    else setStepNum(stepNum - 1);
  };

  return (
    <Box className="luci-settings-tab">
      <SettingsHeader
        loading={loading}
        isSetup={isSetup}
        handleCloseSettings={handleCloseSettings}
        stepNum={stepNum}
      />
      {loading ? (
        <CircularLoading />
      ) : stepNum === 1 ? (
        <StepOne
          stepFields={informerSteps?.step1}
          requiredFields={step1Req}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          stepFieldsErr={stepFieldsErr}
        />
      ) : stepNum === 2 ? (
        <StepTwo
          stepFields={informerSteps?.step2}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          stepFieldsErr={stepFieldsErr}
        />
      ) : stepNum === 3 ? (
        <StepThree
          selectedData={selectedData}
          setSelectedData={setSelectedData}
        />
      ) : (
        <StepFour
          stepFields={informerSteps?.step4}
          requiredFields={step4Req}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          stepFieldsErr={stepFieldsErr}
        />
      )}
      {!loading ? (
        <SettingsFooter
          isSetup={isSetup}
          stepNum={stepNum}
          handleSettingsNext={handleSettingsNext}
          handleSettingsBack={handleSettingsBack}
          saveLoading={saveLoading}
        />
      ) : null}
    </Box>
  );
};

export default LuciSettings;
