import { Box, Typography } from "@mui/material";
import { useRef, useState } from "react";
import {
  CheckValueLocale,
  syncUnixToUTC,
  calculateUnixTimeZone,
  formatTimeZone,
} from "utils/helpers";
import profileImg from "images/engagements-images/profileImg.png";
import { useIntl } from "react-intl";
import CompaniesController from "services/controllers/companiesController";
import "./activityLog.scss";
import moment from "moment";
import Filter from "shared/general-filter/filter";
import NoDataFoundProducts from "components/no-Data";
import CircularLoading from "components/circularProgress";
import UserAvatar from "shared/unifiedUserImage/userAvatar";
import { useGetUserActivityLogs } from "../../../hooks/useGetUserActivityLogs";
import { CustomPagination } from "components/CustomPagination/CustomPagination";
import CustomDateRangePicker from "shared/customDateRangePicker/customDateRangePicker";
import {
  sidebarOptionsMapper,
  sidebarTodayYesterdayOptions,
} from "shared/customDateRangePicker/config/sidebarOptions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";

import DOMPurify from "dompurify";
const ActivityLog = (props) => {
  const { isUserActivityLog, isTeamActivityLog, teamId, userData } = props;
  const intl = useIntl();
  const todayDate = new Date();
  const [filterFields, setFilterFields] = useState([]);
  const [filterActivityLog, setFilterActivityLog] = useState({});
  const [filterParams, setFilterParams] = useState({});
  const [filterParamsApply, setFilterParamsApply] = useState({});
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState(moment().subtract(0, "d").unix());
  const [endDate, setEndDate] = useState(moment().unix());
  const [filtersFailsStatus, setFiltersFailsStatus] = useState(false);
  const dateRangePickerParamsRef = useRef();
  const getFiltersFileds = () => {
    CompaniesController.getFilterActivityLogs().then((res) => {
      if (res?.data?.status === 200) {
        const updatedFilterFields = res.data.data.data.map((filter) => {
          if (filter.name === "product") {
            return {
              ...filter,
              options: filter.options.map((option) => ({
                ...option,
                label: productNameMapping[option.label] || option.label, // Apply mapping if exists
                value: productNameMapping[option.value] || option.value,
              })),
            };
          }
          return filter;
        });
        setFilterFields(updatedFilterFields);
        setFiltersFailsStatus(true);
      } else setFiltersFailsStatus(false);
    });
  };

  const productNameMapping = {
    "Social Listening": "social_listening_v2",
    CDP: "profiles",
    Survey: "survey_v2",
  };

  let filtersObj = {};
  Object?.keys(filterParamsApply)?.map((key) => {
    filtersObj[key] = filterParamsApply?.[key]?.join(",");
  });

  const user_ids =
    !isUserActivityLog && !isTeamActivityLog
      ? Array.isArray(filterParamsApply?.user)
        ? filtersObj?.user?.replace(/""/g, "")
        : undefined
      : userData?.id;

  const products = Array.isArray(filterParamsApply?.product)
    ? filtersObj?.product?.replace(/""/g, "")
    : undefined;

  const { startTimeUTC, endTimeUTC } = dateRangePickerParamsRef?.current || {};

  const {
    data: dataUserActivityLogs,
    isPending: isGetUserActivityLogsPending,
  } = useGetUserActivityLogs(
    startTimeUTC || syncUnixToUTC(startDate, "start"),
    endTimeUTC || syncUnixToUTC(endDate, "end"),
    page,
    user_ids,
    products,
    isTeamActivityLog ? teamId : undefined,
  );

  const getInitials = (sentence) => {
    return sentence
      .split(" ")
      .map((word) => word[0])
      .join("");
  };
  const OmniChannel = localStorage.getItem("cxm_id");
  const checkStatusMsg = (
    action,
    entity,
    name,
    controllerName,
    subentity,
    dataSource,
    newAccess,
    oldAccess,
    productId,
    themeName,
    logItem = {},
  ) => {
    const productNameMapping = {
      Social_Listening: "Social_Listening_v2",
      Ai_Agent: "Ai_Agent_v2",
      CDP: "CDP_v2",
    };
    const entityLocale = entity?.replace(/ /g, "_") || "";
    const subentityLocale = subentity?.replace(/ /g, "_");
    const actionLocale = action?.replace(/ /g, "_");
    const { engagement_id, monitor_name } = logItem;
    const actionCheckLocale = {
      // social & omni
      MonitorsController: `activity_log_${actionLocale}`,
      UserSessionsController: `activity_log_${actionLocale}`,
      OmniauthCallbacksController: `activity_log_${
        subentity == "company account authentication"
          ? actionLocale + "_auth"
          : actionLocale
      }`,
      TwitterController: `activity_log_${subentity == "powerpoint file" ? actionLocale + "_powerp" : actionLocale}`,
      InstagramController: `activity_log_${subentity == "powerpoint file" ? actionLocale + "_powerp" : actionLocale}`,
      FacebookController: `activity_log_${subentity == "powerpoint file" ? actionLocale + "_powerp" : actionLocale}`,
      MonitorsController: `activity_log_${entity == "Omnichannel" ? actionLocale + "_onni" : actionLocale}`,
      SingleTweetAnalysisV2Controller: `activity_log_${actionLocale}_single_post_analysis`,
      DashboardsController: `activity_log_${actionLocale}_${subentity == "custom dashboard" ? "custom_dashboards" : "dashboards"}`,
      // LiveDashboardController: `live_dashboard_access_${actionLocale}`,
      CustomDashboardsController: `activity_log_${actionLocale}_custom_live_dashboard`,
      ExcelController: `activity_log_${actionLocale}`,
      //omniChannel
      DmConfigurationsController: `activity_log_${actionLocale}-dm`,
      // setting
      UserManagementsController: `activity_log_${actionLocale}_user`,
      CompanyController: `activity_log_custom_domain_${actionLocale}`,
      CompanyController: `activity_log_${subentity == "Two Factor Authentication" ? actionLocale + "_2fa" : actionLocale}`,
      UserProfilesController: `activity_log_2fa_user_${actionLocale}`,
      MailsController: `activity_log_request_sms_${actionLocale}`,

      // UserSessionsController: `activity_log_${actionLocale}_cdp`,
      XlsxController: `activity_log_${subentity == "excel file" ? actionLocale + "_excel" : actionLocale}`,
      // CDP
      SegmentsController: `activity_log_${actionLocale}_cdp`,
      // Surveys
      SurveysController: `activity_log_${entity == "Survey" ? actionLocale + "_survey" : actionLocale}`,
      // Utilities
      ReportsController: `activity_log_${entity == "Utilities" ? actionLocale + "_report" : actionLocale}`,
      AlertsController: `activity_log_${entity == "Utilities" ? actionLocale + "_alerts" : actionLocale}`,
      //luci
      QuestionsController: `activity_log_${actionLocale}`,
      TermPoliciesController: `activity_log_${actionLocale}`,
      //teams
      TeamsController: `activity_log_${actionLocale}_team`,
      // Historical Requests
      HistoricalRequestsController: `activity_log_${actionLocale}_historical`,
      //lucidya api
      PublicApisController: `activity_log_${actionLocale}_apis`,
      EngagementsController: `activity_log_${actionLocale}_${entityLocale.toLowerCase()}`,
      // Account List Controller
      AccountListsController: `activity_log_${subentity == "Account Lists" ? actionLocale + "_Account_Lists" : actionLocale}`,
      // OmniServe
      TagsController: `activity_log_${actionLocale}ـtag`,
      ProfilesController: `activity_log_${actionLocale}_profiles`,
    };

    // for archive and delete survey
    if (entity === "Survey") {
      if (action === "deleted") {
        return (
          <Box className="msg-content">
            {CheckValueLocale(
              "activity_log_deleted_survey",
              "",
              {
                survey_name: `"${name}"`,
              },
              intl,
            ) + " "}
            <strong>
              {CheckValueLocale("activity_log_Survey", "", {}, intl)}
            </strong>
          </Box>
        );
      }
      if (action === "unpublished") {
        return (
          <Box className="msg-content">
            {CheckValueLocale(
              "activity_log_archived_survey",
              "",
              { survey_name: `"${name}"` },
              intl,
            ) + " "}
            <strong>
              {CheckValueLocale("activity_log_Survey", "", {}, intl)}
            </strong>
          </Box>
        );
      }
    }

    if (controllerName === "EngagementsController") {
      if (
        [
          "Outside Hours Messaging Enabled",
          "Outside Hours Messaging Disabled",
        ]?.includes(entity)
      ) {
        return (
          <Box className="msg-content">
            {name ? CheckValueLocale(`${name}`, "", {}, intl) : null}
            {CheckValueLocale(
              `activity_log_outside_hours_messaging_${action}`,
              "",
              {
                dataSource: (
                  <Box component={"strong"}>
                    {CheckValueLocale(dataSource || "", "", {}, intl)}
                  </Box>
                ),
                product: (
                  <Box component={"strong"}>
                    {CheckValueLocale("omniServe", "", {}, intl)}
                  </Box>
                ),
              },
              intl,
            )}
          </Box>
        );
      }
      if (action === "Automated public Replies") {
        const actionMessage = entity?.includes(
          "disabled automated public replies",
        )
          ? "disabled"
          : "enabled";
        return (
          <Box className="msg-content">
            {name ? CheckValueLocale(`${name}`, "", {}, intl) : null}{" "}
            {CheckValueLocale(
              `activity_log_automated_public_replies_${actionMessage}`,
              "",
              {},
              intl,
            )}
          </Box>
        );
      }
      if (
        entity.includes("Greeting Message") ||
        entity.includes("Farewell Message")
      ) {
        const messageType = entity.includes("Greeting")
          ? "greeting"
          : "farewell";
        const actionType = action === "enabled" ? "enabled" : "disabled";
        const messageAction =
          action === "Message Selected" ? "selected" : actionType;

        return (
          <Box className="msg-content">
            {name ? CheckValueLocale(`${name}`, "", {}, intl) : null}{" "}
            {CheckValueLocale(
              `activity_log_${messageAction}_${messageType}_message`,
              "",
              {},
              intl,
            )}{" "}
            <Box component={"strong"}>
              {CheckValueLocale(
                `farewell_greeting_${dataSource}`,
                "",
                {},
                intl,
              )}
              {` `}
            </Box>
            {CheckValueLocale(
              `activity_log_from_engagement_settings`,
              "",
              {},
              intl,
            )}
          </Box>
        );
      }
      if (
        entity == "Farewell Message Enabled" ||
        entity == "Engagement Survey Enabled" ||
        entity == "Engagement Survey Disabled"
      ) {
        const messageType = entity.includes("Engagement Survey Disabled")
          ? "engagement_survey-disabled"
          : logItem?.is_csat_survey == true
            ? "farewell_message_enabled"
            : logItem?.is_csat_survey == false
              ? "engagement_survey_enabled"
              : "";
        return (
          <Box className="msg-content">
            {name ? CheckValueLocale(`${name}`, "", {}, intl) : null}{" "}
            {CheckValueLocale(`activity_log_${messageType}`, "", {}, intl)}{" "}
            {CheckValueLocale(logItem?.data_source, "", {}, intl)}{" "}
            {CheckValueLocale(`activity_log_in`, "", {}, intl)}{" "}
            <Box component={"strong"}>
              {CheckValueLocale(`activity_log_omniserve`, "", {}, intl)}
            </Box>
          </Box>
        );
      }
    }

    return action == "Successful log in" && entity == "Settings" ? (
      <Box className="msg-content">
        {CheckValueLocale(`activity_log_${actionLocale}`, "", {}, intl)}{" "}
      </Box>
    ) : action == "Unsuccessful log in attempt" && entity == "Settings" ? (
      <Box className="msg-content">
        {CheckValueLocale(`activity_log_${actionLocale}`, "", {}, intl)}{" "}
      </Box>
    ) : action == "Successful log in" && entity == "Omnichannel" ? (
      <Box className="msg-content">
        {CheckValueLocale(actionCheckLocale[controllerName], "", {}, intl) +
          " "}
        {name ? CheckValueLocale(`"${name}"`, "", {}, intl) : null}{" "}
        {CheckValueLocale("activity_log_in", "", {}, intl)}{" "}
        <Box component={"strong"} className="entity">
          {CheckValueLocale(`activity_log_${entityLocale}`, "", {}, intl)}{" "}
        </Box>
      </Box>
    ) : entity === "Ai Agent" ? (
      <Box className="msg-content">
        {CheckValueLocale(`ai_agent_activity_log_${action}`, "", {}, intl) +
          " "}
        {name ? CheckValueLocale(`"${name}"`, "", {}, intl) : null}{" "}
        {CheckValueLocale("activity_log_in", "", {}, intl)}{" "}
        <Box component={"strong"}>
          {CheckValueLocale(
            `activity_log_${productNameMapping[entityLocale] || entityLocale}`,
            "",
            {},
            intl,
          )}{" "}
        </Box>
      </Box>
    ) : controllerName === "CollectionsController" ? (
      [
        "Saved Reply Collection Created",
        "Saved Reply Collection Renamed",
        "Saved Reply Collection Deleted",
      ].includes(entity) ? (
        <Box className="msg-content">
          {CheckValueLocale(
            `activity_log_${action?.toLowerCase()}_collection`,
            "",
            { collection_name: name },
            intl,
          )}
          <Box component={"strong"}>
            {CheckValueLocale("omniServe", "", {}, intl)}{" "}
          </Box>
        </Box>
      ) : (
        <Box className="msg-content">
          {CheckValueLocale(
            `activity_log_${action?.toLowerCase()}_saved_replies`,
            "",
            {
              collection_name: logItem?.inside_subentity_name,
              old_collection_name: logItem?.inside_subentity_name?.[0],
              new_collection_name: logItem?.inside_subentity_name?.[1],
            },
            intl,
          )}
          <Box component={"strong"}>
            {CheckValueLocale("omniServe", "", {}, intl)}{" "}
          </Box>
        </Box>
      )
    ) : controllerName === "SavedRepliesController" ? (
      <Box className="msg-content">
        {CheckValueLocale(
          `activity_log_${action?.toLowerCase()}_saved_reply`,
          "",
          { collection_name: name },
          intl,
        )}
        <Box component={"strong"}>
          {CheckValueLocale("omniServe", "", {}, intl)}
        </Box>
      </Box>
    ) : controllerName === "LiveDashboardController" ? (
      <Box className="msg-content">
        {CheckValueLocale("changed_livedashbaord_access", "", {}, intl)}{" "}
        {name ? name : ""} {CheckValueLocale("from", "", {}, intl)}
        {CheckValueLocale(`live_dashboard_access_${oldAccess}`, "", {}, intl)}
        {CheckValueLocale("to_word", "", {}, intl)}
        {CheckValueLocale(`live_dashboard_access_${newAccess}`, "", {}, intl)}
      </Box>
    ) : entity === "Utilities" && subentity === "theme" ? (
      <Box className="msg-content">
        {CheckValueLocale(`themes_activity_log_${action}`, "", {}, intl) + " "}
        {themeName
          ? CheckValueLocale(`"${themeName}"`, "", {}, intl)
          : null}{" "}
        {CheckValueLocale("activity_log_in", "", {}, intl)}{" "}
        <Box component={"strong"}>
          {CheckValueLocale(
            `activity_log_${productNameMapping[entityLocale] || entityLocale}`,
            "",
            {},
            intl,
          )}{" "}
        </Box>
      </Box>
    ) : entity === "Settings" && action == "stop" ? (
      <Box className="msg-content">
        {CheckValueLocale(
          `activity_log_${actionLocale}_${productId == OmniChannel ? "channel" : "monitor"}_historical`,
          "",
          {},
          intl,
        ) + " "}
        {name ? CheckValueLocale(`"${name}"`, "", {}, intl) : null}{" "}
        {CheckValueLocale("activity_log_in", "", {}, intl)}{" "}
        <Box component={"strong"}>
          {CheckValueLocale(
            `activity_log_${productNameMapping[entityLocale] || entityLocale}`,
            "",
            {},
            intl,
          )}{" "}
        </Box>
      </Box>
    ) : entity === "Utilities" && subentity === "Account Lists" ? (
      <Box className="msg-content">
        {CheckValueLocale(actionCheckLocale[controllerName], "", {}, intl) +
          " "}
        {name ? CheckValueLocale(`"${name}"`, "", {}, intl) : null}{" "}
        {CheckValueLocale("activity_log_in", "", {}, intl)}{" "}
        <Box component={"strong"}>
          {CheckValueLocale(`activity_log_${subentityLocale}`, "", {}, intl)}{" "}
        </Box>
      </Box>
    ) : controllerName == "NotesController" ? (
      <Box
        className="msg-content"
        id="msg-content-notes"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            CheckValueLocale(
              engagement_id
                ? `activity_log_notes_feature_${action?.toLowerCase()}_engagememt_id`
                : `activity_log_notes_feature_${action?.toLowerCase()}_engagememt`,
              "",
              {
                name: monitor_name,
                id: engagement_id,
                b: "<strong>",
                be: "</strong>",
              },
              intl,
            ),
          ),
        }}
      />
    ) : action?.includes("Engagement historical request") ? (
      <Box
        className="msg-content"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            CheckValueLocale(
              engagement_id
                ? "activity_log_historical_engagement_id"
                : "activity_log_historical_engagement",
              "",
              {
                name: monitor_name,
                id: engagement_id,
                b: "<b>",
                be: "</b>",
              },
              intl,
            ),
          ),
        }}
      />
    ) : controllerName === "TagsController" ? (
      <Box className="msg-content">
        {CheckValueLocale(actionCheckLocale[controllerName], "", {}, intl) +
          " "}
        {name ? CheckValueLocale(`[${name}]`, "", {}, intl) : null}{" "}
        {CheckValueLocale("activity_log_in", "", {}, intl)}{" "}
        <Box component={"strong"}>
          {CheckValueLocale(`activity_log_${controllerName}`, "", {}, intl)}{" "}
        </Box>
      </Box>
    ) : controllerName === "SingleTweetAnalysisV2Controller" ? (
      <Box className="msg-content">
        {CheckValueLocale(
          actionCheckLocale[controllerName],
          "",
          {
            user: name,
          },
          intl,
        ) + " "}
        {CheckValueLocale("activity_log_in", "", {}, intl)}{" "}
        <Box component={"strong"}>
          {CheckValueLocale(`activity_log_${entityLocale}`, "", {}, intl)}{" "}
        </Box>
      </Box>
    ) : subentity === "Two Factor Authentication" ? (
      <Box className="msg-content">
        {CheckValueLocale(actionCheckLocale?.[controllerName], "", {}, intl)}{" "}
      </Box>
    ) : subentity === "Two Factor Authentication SMS" ? (
      <Box className="msg-content">
        {CheckValueLocale(actionCheckLocale?.[controllerName], "", {}, intl)}{" "}
      </Box>
    ) : ["Omniserve", "Profiles"].includes(entity) && action === "edited" ? (
      <Box className="msg-content">
        {CheckValueLocale(
          actionCheckLocale[controllerName],
          "",
          { user: name },
          intl,
        ) + " "}
        {CheckValueLocale("activity_log_in", "", {}, intl)}{" "}
        <Box component={"strong"}>
          {CheckValueLocale(
            entity === "Profiles" ? "profiles" : "omniServe",
            "",
            {},
            intl,
          )}{" "}
        </Box>
      </Box>
    ) : (
      <Box className="msg-content">
        {CheckValueLocale(actionCheckLocale[controllerName], "", {}, intl) +
          " "}
        {name ? CheckValueLocale(`"${name}"`, "", {}, intl) : null}{" "}
        {CheckValueLocale("activity_log_in", "", {}, intl)}{" "}
        <Box component={"strong"}>
          {CheckValueLocale(
            `activity_log_${productNameMapping[entityLocale] || entityLocale}`,
            "",
            {},
            intl,
          )}{" "}
        </Box>
      </Box>
    );
  };

  const handleClearFilterParams = () => {
    setFilterParams({});
  };

  const applyFilter = (startDate, endDate) => {
    setPage(1);
    setStartDate(startDate);
    setEndDate(endDate);
  };

  //to call API with change in filter params
  const applyFilterParams = (_, __, filterParams) => {
    setPage(1);
    setFilterParamsApply(filterParams);
  };

  //pagination
  const handleChangePage = (event, page) => {
    if (Boolean(page) === false) return;
    setPage(page);
  };

  const handleImageError = (event) => {
    event.target.src = profileImg;
    event.target.alt = "placeholder-image";
  };

  const checekDay = (createdAt) => {
    let value =
      moment(createdAt)?.format("DD/MM/yyyy") ==
      moment(todayDate)?.format("DD/MM/yyyy")
        ? true
        : false;

    return value;
  };

  return (
    <Box className="activity-log-container">
      <Box className="activity-log-head">
        <Typography>
          {CheckValueLocale(
            isUserActivityLog
              ? "user_activity_history"
              : "users_activity_history",
            "",
            {},
            intl,
          )}
        </Typography>
        <Box className="activity-log-datepicker-filter">
          <CustomDateRangePicker
            initialSidebarLabel={sidebarOptionsMapper.today}
            startDate={startDate}
            endDate={endDate}
            applyFilter={applyFilter}
            maxDate={new Date()}
            minDate={dataUserActivityLogs?.oldest_date}
            dateRangePickerParamsRef={dateRangePickerParamsRef}
            sidebarOptions={sidebarTodayYesterdayOptions}
            customStyles={{
              type: "secondary",
              variant: "outline",
              size: "large",
              fixedWidth: "221px",
              startIcon: (
                <FontAwesomeIcon
                  icon={faCalendar}
                  className="custom-input-icon"
                />
              ),
            }}
          />
          {!isUserActivityLog && !isTeamActivityLog ? (
            <Filter
              handleClearFilterParams={handleClearFilterParams}
              filterParams={filterParams}
              filterFields={filterFields}
              setFilterParams={setFilterParams}
              monitorActiveTabFilterID={"user-activity-filter"}
              isUserActivity
              assignActiveFilters={filterActivityLog}
              setAssignActiveFilters={setFilterActivityLog}
              applyFilter={applyFilterParams}
              getFiltersFileds={getFiltersFileds}
              startDate={startDate}
              endDate={endDate}
              disabled={!filtersFailsStatus}
            />
          ) : null}
        </Box>
      </Box>

      {isGetUserActivityLogsPending ? (
        <CircularLoading />
      ) : dataUserActivityLogs?.data?.length > 0 ? (
        Object.entries(dataUserActivityLogs?.data).map(
          ([date, activities], index) => {
            const createdAt = activities?.attributes?.created_at;
            const companyTimeZone = parseInt(
              localStorage.getItem("companyInfo"),
            );
            const dataInUnix = moment(createdAt).unix();
            const calculatedTime = calculateUnixTimeZone(dataInUnix, {});
            const { completeFormat } = formatTimeZone(
              calculatedTime,
              intl,
              {},
              companyTimeZone,
            );
            const [datePart, timeWithGMT] = completeFormat.split(", ");
            return (
              <Box key={index}>
                <Box
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="accordion-tab"
                ></Box>
                <Box className="accordion-content">
                  <Box
                    className={
                      isUserActivityLog
                        ? "user-item user-single-page"
                        : "user-item"
                    }
                    key={activities?.id}
                  >
                    <Box>
                      {isUserActivityLog ? (
                        <Box className="view-user-embty-img"> </Box>
                      ) : (
                        <Box className="user-img-wrapper">
                          <UserAvatar
                            avatarSrc={activities?.attributes?.avatar}
                            fullName={activities?.attributes?.user_name}
                            sizes={{ ratio: "32px", fontSize: "11px" }}
                          />
                        </Box>
                      )}
                      <Typography component={"strong"} className="user-name">
                        {activities?.attributes?.user_name}
                      </Typography>
                      {checkStatusMsg(
                        activities?.attributes?.action,
                        activities?.attributes?.entity,
                        activities?.attributes?.inside_subentity_name,
                        activities?.attributes?.controller_name,
                        activities?.attributes?.subentity,
                        activities?.attributes?.data_source,
                        activities?.attributes?.new_access,
                        activities?.attributes?.old_access,
                        activities?.attributes?.product_id,
                        activities?.attributes?.theme_name,
                        activities?.attributes,
                      )}
                    </Box>
                    {checekDay(createdAt) ? timeWithGMT : null}
                    {!checekDay(createdAt) ? completeFormat : null}
                  </Box>
                </Box>
              </Box>
            );
          },
        )
      ) : (
        <Box className="empty-box" id="empty-activity-log">
          <NoDataFoundProducts
            image="userActivity"
            title="no_data_found"
            description="no_activity_logs_found_msg"
          />
        </Box>
      )}
      {dataUserActivityLogs?.pagination?.pages > 1 ? (
        <CustomPagination
          onChange={handleChangePage}
          page={page}
          pagination={dataUserActivityLogs?.pagination}
        />
      ) : null}
    </Box>
  );
};

export default ActivityLog;
