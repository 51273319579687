import React, { useEffect, useState } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";
import "./monitorSideBar.scss";
import { List, AccordionDetails, Box } from "@mui/material";
import {
  AccordionSummaryMonitor,
  ListItemWrapper,
  AccordionWrapper,
} from "./monitor.styles.js";
//fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faInstagram,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";
import {
  faChartLine,
  faNewspaper,
  faList,
} from "@fortawesome/pro-regular-svg-icons";
import { useIntl } from "react-intl";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { data } from "./dataSourceTabs";
import UserAccount from "services/controllers/userAccountController";
import Services from "services/api/Services";
import {
  CheckValueLocale,
  convertTwitterToXPlatform,
  convertXPlatformToTwitter,
} from "utils/helpers";
import publicFacebook from "images/social-icons/facebook-public-icon.png";
import publicInstagram from "images/social-icons/instagram-public-icon.svg";

import xPlatform from "images/social-icons/x-platform-icon.svg";

const SideMenuTabs = (props) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const monitorDetails = props.monitorData;
  const urlParams = useParams();
  const monitorDataSource = convertXPlatformToTwitter(
    urlParams.monitorDataSource,
  );
  const [expanded, setExpanded] = useState(monitorDataSource);
  const handleChange = (dataSource) => {
    if (expanded === dataSource) {
      setExpanded("none");
    } else {
      setExpanded(dataSource);
    }
  };
  const [dataSourcesDetails, setDataSourcesDetails] = useState([]);
  const [preUrl, setPreUrl] = useState("");

  // _______________________________________________________________
  useEffect(() => {
    let dataSourcesDetails = [];
    monitorDetails?.attributes?.data_sources?.map((dataSource) => {
      let item = {
        dataSource:
          dataSource === "TALKWALKER" || dataSource === "NEWSBLOGS"
            ? "NEWSBLOGS"
            : dataSource,
        name: dataSource?.toLowerCase(),
        icon:
          dataSource === "TWITTER"
            ? faTwitter
            : dataSource === "FACEBOOK"
              ? faFacebook
              : dataSource === "INSTAGRAM"
                ? faInstagram
                : faNewspaper,
        tabs: `${dataSource}_${monitorDetails.attributes.monitor_type_name}`,
      };
      dataSourcesDetails.push(item);
      setDataSourcesDetails(dataSourcesDetails);
    });
  }, [monitorDetails]);
  // _______________________________________________________________

  const handleSideBarRedirection = (dataSource, tabName) => {
    let newUrl = `/monitor/${urlParams?.monitorType}/${convertTwitterToXPlatform(dataSource)}/${tabName}/${urlParams?.monitorId}`;
    if (preUrl !== newUrl) {
      setPreUrl(newUrl);
      props?.setExcelSheetData([]);
    }
    navigate(newUrl, { state: { ...monitorDetails, ...urlParams } });
  };

  return (
    <>
      <List sx={{ paddingBottom: 6 }} className="list-sidebar">
        {/* data source header (AccordionWrapper)_______________________________________________________ */}
        {dataSourcesDetails?.map((dataSource, i) => {
          return (
            <>
              <ListItemWrapper button focusVisible={false} disableRipple>
                <AccordionWrapper
                  expanded={expanded === dataSource.dataSource}
                  className={`data-source-accordion ${
                    expanded !== "none" && " expanded"
                  }`}
                  onChange={() => handleChange(dataSource.dataSource)}
                  key={i}
                >
                  <AccordionSummaryMonitor expandIcon={<ExpandMoreIcon />}>
                    <Box className="monitor_pages_head">
                      <Box className="monitor_head_wrapper">
                        <Box className="monitor_icon_wrapper">
                          {dataSource.dataSource === "FACEBOOK" ? (
                            <img
                              className={
                                expanded !== dataSource.dataSource &&
                                "not-active-icon"
                              }
                              src={publicFacebook}
                            />
                          ) : null}
                          {dataSource.dataSource === "INSTAGRAM" ? (
                            <img
                              className={
                                expanded !== dataSource.dataSource &&
                                "not-active-icon"
                              }
                              src={publicInstagram}
                            />
                          ) : null}
                          {dataSource.dataSource === "TWITTER" ? (
                            <img
                              className={
                                expanded !== dataSource.dataSource &&
                                "not-active-icon"
                              }
                              src={xPlatform}
                              alt="x-platform-logo"
                            />
                          ) : null}
                          {dataSource.dataSource === "TALKWALKER" ||
                          dataSource.dataSource === "NEWSBLOGS" ? (
                            <FontAwesomeIcon
                              icon={dataSource.icon}
                              className={
                                expanded === dataSource.dataSource
                                  ? `${dataSource.name}_icon`
                                  : "icon"
                              }
                              solid
                            />
                          ) : null}
                        </Box>
                        <Box
                          className={`monitor_typename ${
                            expanded === dataSource.dataSource && " active"
                          }`}
                        >
                          {CheckValueLocale(dataSource.name, "", {}, intl)}
                          <Box component="span">
                            {monitorDetails.attributes?.third_party_unique_id &&
                              `/${monitorDetails.attributes?.third_party_unique_id}`}
                          </Box>
                          {monitorDetails.attributes?.keywords_stats && (
                            <Box component="span">
                              {dataSource.dataSource === "INSTAGRAM"
                                ? `${monitorDetails.attributes?.keywords_stats.instagram_hashtags_count} ` +
                                  CheckValueLocale("hashtags", "", {}, intl)
                                : dataSource.dataSource === "TWITTER"
                                  ? `${monitorDetails.attributes?.keywords_stats.twitter_keywords_count} ` +
                                    CheckValueLocale("keywords", "", {}, intl)
                                  : dataSource.dataSource === "FACEBOOK"
                                    ? `${monitorDetails.attributes?.keywords_stats.facebook_keywords_count} ` +
                                      CheckValueLocale("keywords", "", {}, intl)
                                    : dataSource.dataSource === "TALKWALKER" ||
                                        dataSource.dataSource === "NEWSBLOGS"
                                      ? `${monitorDetails.attributes?.keywords_stats.talkwalker_sites_count} ` +
                                        CheckValueLocale(
                                          "websites",
                                          "",
                                          {},
                                          intl,
                                        )
                                      : null}
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </AccordionSummaryMonitor>
                  {/*_______________________________________________________________ */}

                  {data?.map((item, i) => {
                    //'data' here is static array of object for all avaliable tabs for each datasource as per the its monitorType
                    return (
                      item[dataSource?.tabs] &&
                      item[dataSource?.tabs].map((tab, i) => {
                        return (
                          <Box component="div" key={i}>
                            <AccordionDetails className="accordion-details">
                              <Box component="div" className="accordion_icon">
                                <FontAwesomeIcon
                                  icon={
                                    Object.keys(tab)[0] === "analytics"
                                      ? faChartLine //icon beside analutics word
                                      : faList //icon beside lists word
                                  }
                                  solid
                                />
                                <Box component="span">
                                  {CheckValueLocale(
                                    Object.keys(tab),
                                    "",
                                    {},
                                    intl,
                                  )}
                                </Box>
                              </Box>
                              {/*_______________________________________________________________ */}

                              <Box className="accordion_wrapper">
                                {tab[Object.keys(tab)].map((tabName, i) => {
                                  return (
                                    tabName !== false &&
                                    tabName?.tabName !== "live_dashboard" && (
                                      <p
                                        key={i}
                                        className={
                                          tabName?.tabName ===
                                            urlParams.activeTab &&
                                          dataSource.dataSource ===
                                            monitorDataSource &&
                                          "active_tab"
                                        }
                                        onClick={() => {
                                          handleSideBarRedirection(
                                            dataSource.dataSource,
                                            tabName?.tabName,
                                          );
                                        }}
                                        id={`monitor-sidebar-${dataSource?.dataSource?.toLowerCase() ?? "data-source"}-${tabName?.tabName ?? "tab-name"}-tab`}
                                      >
                                        {CheckValueLocale(
                                          tabName?.tabName,
                                          "",
                                          {},
                                          intl,
                                        )}

                                        {tabName?.icon && (
                                          <FontAwesomeIcon
                                            icon={tabName?.icon}
                                            className="live-dashboard-tab-icon"
                                          />
                                        )}
                                      </p>
                                    )
                                  );
                                })}
                              </Box>
                            </AccordionDetails>
                          </Box>
                        );
                      })
                    );
                  })}
                </AccordionWrapper>
              </ListItemWrapper>
            </>
          );
        })}
      </List>
    </>
  );
};
export default SideMenuTabs;
