import {
  Box,
  Tooltip,
  Button,
  Divider,
  Checkbox,
  Typography,
} from "@mui/material";
import "./replayCard.scss";
import { useState } from "react";
import {
  CheckValueLocale,
  handlelUserRoles,
  truncate,
} from "../../../../../../utils/helpers/index.js";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCommentAltLines,
  faTrash,
  faPenToSquare,
} from "@fortawesome/pro-regular-svg-icons";

const ReplayCard = ({
  item,
  isAi,
  setNewRepPopup,
  selectedRepsList,
  setSelectedRepsList,
  setDelPopup,
  isReplayLimit,
  collectionId,
}) => {
  const intl = useIntl();
  const [isHovered, setIsHovered] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleSelect = () => {
    let updatedArr = [...(selectedRepsList || [])];
    if (selectedRepsList?.includes(item?.id)) {
      updatedArr = selectedRepsList?.filter((id) => id != item?.id);
    } else {
      updatedArr.push(item?.id);
    }
    setSelectedRepsList([...(updatedArr || [])]);
  };
  return (
    <Box
      key={item?.id}
      className={`rep-card-countainer ${!isAi && selectedRepsList?.includes(item?.id) ? "selected" : ""} ${
        !isAi &&
        (selectedRepsList?.includes(item?.id) ||
          (selectedRepsList?.length > 0 && selectedRepsList?.length < 10))
          ? "clicked"
          : ""
      }`}
      onMouseEnter={() => !isAi && setIsHovered(true)}
      onMouseLeave={() => !isAi && setIsHovered(false)}
      onClick={() =>
        !isAi &&
        (selectedRepsList?.includes(item?.id) ||
          (selectedRepsList?.length > 0 && selectedRepsList?.length < 10)) &&
        handleSelect()
      }
    >
      {!isAi ? (
        <Box className="rep-card-header">
          <Box>{item?.title}</Box>
        </Box>
      ) : null}
      <Box className="rep-card-contant">
        <Typography component="span">
          {expanded ? item?.text : truncate(item?.text, 250)}
        </Typography>
        {item?.text?.length > 250 ? (
          <Typography
            component="span"
            className="see-more-less-btn"
            onClick={() => setExpanded((prev) => !prev)}
          >
            {` ${CheckValueLocale(expanded ? "see_less" : "see_more", "", {}, intl)}`}
          </Typography>
        ) : null}
      </Box>
      {!isAi &&
      (handlelUserRoles("SAVED_REPLIES", "FULL_ACCESS") ||
        handlelUserRoles("COLLECTION_MANAGEMENT", "FULL_ACCESS") ||
        handlelUserRoles("SAVED_REPLIES", "DELETE_SAVED_REPLY") ||
        handlelUserRoles("SAVED_REPLIES", "FULL_ACCESS") ||
        handlelUserRoles("COLLECTION_MANAGEMENT", "EDIT_COLLECTION")) ? (
        <Box className="rep-saved-card-footer">
          {isHovered || selectedRepsList?.length ? (
            <Checkbox
              checked={selectedRepsList?.includes(item?.id)}
              disabled={
                !selectedRepsList?.includes(item?.id) &&
                selectedRepsList?.length >= 10
              }
              onClick={(event) => {
                event?.stopPropagation?.();
                handleSelect();
              }}
            />
          ) : (
            <Box></Box>
          )}
          <Box className="card-footer-options">
            {handlelUserRoles("SAVED_REPLIES", "EDIT_SAVED_REPLY") ||
            handlelUserRoles("SAVED_REPLIES", "FULL_ACCESS") ? (
              <Tooltip
                title={CheckValueLocale("dashboard_edit", "", {}, intl)}
                placement="bottom"
                arrow
              >
                <FontAwesomeIcon
                  icon={faPenToSquare}
                  onClick={(event) => {
                    event?.stopPropagation?.();
                    setNewRepPopup({
                      open: true,
                      id: item?.id,
                      isDuplicate: false,
                      title: item?.title,
                      contant: item?.text,
                      collection_id: collectionId,
                      isEdit: true,
                      initialData: {
                        title: item?.title,
                        contant: item?.text,
                        collection_id: collectionId,
                      },
                      ai: false,
                      loading: false,
                      done: false,
                    });
                  }}
                />
              </Tooltip>
            ) : null}
            {handlelUserRoles("SAVED_REPLIES", "FULL_ACCESS") ||
            (handlelUserRoles("SAVED_REPLIES", "EDIT_SAVED_REPLY") &&
              handlelUserRoles("SAVED_REPLIES", "DELETE_SAVED_REPLY")) ? (
              <Divider orientation="vertical" />
            ) : null}
            {handlelUserRoles("SAVED_REPLIES", "DELETE_SAVED_REPLY") ||
            handlelUserRoles("SAVED_REPLIES", "FULL_ACCESS") ? (
              <Tooltip
                title={CheckValueLocale("dashboard_delete", "", {}, intl)}
                placement="bottom"
                arrow
              >
                <FontAwesomeIcon
                  icon={faTrash}
                  onClick={(event) => {
                    event?.stopPropagation?.();
                    setDelPopup({
                      open: true,
                      item: { ...item },
                    });
                  }}
                />
              </Tooltip>
            ) : null}
          </Box>
        </Box>
      ) : null}

      {isAi &&
      (handlelUserRoles("SAVED_REPLIES", "CREATE_SAVED_REPLY") ||
        handlelUserRoles("SAVED_REPLIES", "FULL_ACCESS")) ? (
        <Box className="rep-card-footer">
          <Tooltip
            title={
              isReplayLimit
                ? CheckValueLocale("limit_saved_replais", "", {}, intl)
                : null
            }
            placement="top"
            arrow
          >
            <Box className="rep-card-ai-btn">
              <Button
                disabled={isReplayLimit}
                onClick={(event) => {
                  event?.stopPropagation?.();
                  setNewRepPopup({
                    open: true,
                    id: item?.id,
                    isDuplicate: false,
                    title: "",
                    contant: item?.text,
                    ai: true,
                    loading: false,
                    done: false,
                  });
                }}
              >
                <Box>
                  <FontAwesomeIcon icon={faCommentAltLines} />
                </Box>
                <Box>{CheckValueLocale("add_rep", "", {}, intl)}</Box>
              </Button>
            </Box>
          </Tooltip>
        </Box>
      ) : null}
    </Box>
  );
};

export default ReplayCard;
