import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import SnackBar from "components/snackBar";
import CommonFunctions from "pages/Monitors/Components/commonFunctions";
import { useIntl } from "react-intl";
import "../../../monitors.scss";
import QuickInspect from "pages/Monitors/Components/quickInspect/quickInspect";
import AuthorAnalyticsSection from "./authorAnalyticsSection";
import OverViewSection from "./overviewSection";
import {
  isEmptyObj,
  dateFormRefactor,
  isEmptyArray,
  convertXPlatformToTwitter,
} from "utils/helpers";
import mqttRabbitMQResponce from "services/controllers/mqttRabbitMQResponce";
import TopicsSection from "../../../Components/topicsSection";
import { CheckValueLocale } from "utils/helpers";
import { useSelector } from "react-redux";
import PopupModal from "components/popupModal";
import xPlatform from "images/social-icons/x-platform-icon.svg";
import { useGetQuickInspectData } from "pages/Monitors/hooks/useGetQuickInspectData";

const EngagementsBody = (props) => {
  const intl = useIntl();
  const urlParams = useParams();
  const monitorDataSource = convertXPlatformToTwitter(
    urlParams.monitorDataSource,
  );
  const monitorId = urlParams.monitorId;

  const [postsAnalyticsData, setPostsAnalyticsData] = useState([]);
  const [overViewData, setOverViewData] = useState([]);
  const [commentsVolume, setCommentsVolume] = useState([]);
  const [commentsInteractions, setCommentsInteractions] = useState([]);
  const [commentsInteractionsPreLoader, setCommentsInteractionsPreLoader] =
    useState([]);
  const [commentsInteractionsLegend, setCommentsInteractionsLegend] = useState(
    [],
  );
  const [sentimentAnalysis, setSentimentAnalysis] = useState([]);
  const [sentimentAnalysisPreLoader, setSentimentAnalysisPreLoader] = useState(
    [],
  );
  const [isSentimentAnalysisHourlyFormat, setIsSentimentAnalysisHourlyFormat] =
    useState(false);
  const [themeTrend, setThemeTrend] = useState([]);
  const [themeAnalysis, setThemeAnalysis] = useState([]);
  const [themeTrendPreLoader, setThemeTrendPreLoader] = useState([]);
  const [themeAnalysisPreLoader, setThemeAnalysisPreLoader] = useState([]);
  const [themeXlxs, setThemeXlxs] = useState([]);

  const [topVideos, setTopVideos] = useState([]);
  const [topVideosPreLoader, setTopVideosPreLoader] = useState([]);
  const [topImages, setTopImages] = useState([]);
  const [topImagesPreLoader, setTopImagesPreLoader] = useState([]);
  const [topKeywords, setTopKeywords] = useState([]);
  const [topKeywordsPreLoader, setTopKeywordsPreLoader] = useState([]);
  const [topHashtags, setTopHashtags] = useState([]);
  const [topHashtagsPreLoader, setTopHashtagsPreLoader] = useState([]);
  const [commentsVolumePreLoader, setCommentsVolumePreLoader] = useState([]);
  const [associatedTopics, setAssociatedTopics] = useState([]);
  const [associatedTopicsPreLoader, setAssociatedTopicsPreLoader] = useState(
    [],
  );
  const [contentStyles, setContentStyles] = useState([]);
  const [contentStylesPreLoader, setContentStylesPreLoader] = useState([]);
  const [reachFunnel, setReachFunnel] = useState([]);

  const [reachFunnelPreLoaderDataSources, setReachFunnelPreLoaderDataSources] =
    useState([]);

  const [topPosts, setTopPosts] = useState([]);
  const [topTopPostsPreLoaderDataSources, setTopPostsPreLoaderDataSources] =
    useState([]);

  const [topLanguages, setTopLanguages] = useState([]);
  const [topLanguagesPreLoader, setTopLanguagesPreLoader] = useState([]);
  const [dialects, setDialects] = useState([]);
  const [subDialects, setSubDialects] = useState([]);
  const [dialectsPreLoader, setDialectsPreLoader] = useState([]);
  const [topCities, setTopCities] = useState([]);
  const [topCitiesPreLoader, setTopCitiesPreLoader] = useState([]);
  const [topCountries, setTopCountries] = useState([]);
  const [topCountriesPreLoader, setTopCountriesPreLoader] = useState([]);
  const [genderDistribution, setGenderDistribution] = useState([]);
  const [genderDistributionPreLoader, setGenderDistributionPreLoader] =
    useState([]);
  const [accountTypes, setAccountTypes] = useState([]);
  const [accountTypesPreLoader, setAccountTypesPreLoader] = useState([]);
  const [topSources, setTopSources] = useState([]);
  const [topSourcesPreLoader, setTopSourcesPreLoader] = useState([]);
  const [topVerifiedEngagers, setTopVerifiedEngagers] = useState([]);
  const [topVerifiedEngagersPreLoader, setTopVerifiedEngagersPreLoader] =
    useState([]);
  const [topEngagers, setTopEngagers] = useState([]);
  const [topEngagersPreLoader, setTopEngagersPreLoader] = useState([]);
  const [topInfleuncers, setTopInfleuncers] = useState([]);
  const [topInfleuncersPreLoader, setTopInfleuncersPreLoader] = useState([]);

  const [open, setOpen] = useState(false);
  const [inspectorName, setInspectorName] = useState("");
  const [inspectorValue, setInspectorValue] = useState("");
  const [quickResponse, setQuickResponse] = useState([]);
  const mqttConnectionClosed = React.useRef(false);
  const [date, setDate] = useState();
  const [dataValue, setDataValue] = useState("");
  const clientMQTT = React.useRef();
  const [color, setColor] = useState("");
  const [
    preLoaderTrackerQuickDataSources,
    setPreLoaderTrackerQuickDataSources,
  ] = useState([]);
  const { changeFilterParamsToString } = CommonFunctions();

  const [dataToSend, setDataToSend] = useState({
    languages: [],
    countries: [],
    cities: [],
    sources: [],
  });

  //  Start Redux Declarations
  const reduxFilterStartDateUX = useSelector(
    (state) => state?.socialListening?.startDateUnix,
  );
  const reduxFilterEndDateUX = useSelector(
    (state) => state?.socialListening?.endDateUnix,
  );
  //  End Redux Declarations

  //this function is used for open the quick-inspect-model
  const handleOpenQuickInspect = (value, name, sentimentValue) => {
    switch (true) {
      case name === "comments_overtime":
        setDataValue(
          props.engagementsKeyword ? "posts_mentions" : "comments_mentions",
        );
        setInspectorValue(props.engagementsKeyword ? "posts" : "comments");
        setColor("#2AAAE2");
        setDate(value.split("-").reverse().join("/"));
        break;
      case name === "sentiment_overtime":
        setDataValue(sentimentValue);
        setInspectorValue(
          CheckValueLocale(sentimentValue, "", {}, intl) +
            " " +
            CheckValueLocale("volume", "", {}, intl),
        );
        sentimentValue === "positive"
          ? setColor("#BAD532")
          : sentimentValue === "negative"
            ? setColor("#ED1846")
            : setColor("#F5B819");
        setDate(value.split("-").reverse().join("/"));

        break;
      //incase of new design for quick inspect from sentiment legend
      case name === "sentiment_legend":
        setDataValue(
          sentimentValue + " " + CheckValueLocale(value, "", {}, intl),
        );
        setInspectorValue(CheckValueLocale(value + "_volume", "", {}, intl));
        value === "positive"
          ? setColor("#8CC63F")
          : value === "negative"
            ? setColor("#ED1846")
            : setColor("#F5B819");

        setDate(
          CheckValueLocale("all_time_sentiment", "", {}, intl) +
            dateFormRefactor(reduxFilterStartDateUX) +
            " - " +
            dateFormRefactor(reduxFilterEndDateUX),
        );
        break;
      case name === "top_hashtags" || name === "top_keywords":
        setDataValue(value);
        setInspectorValue(value);
        setColor("#2AAAE2");
        setDate(
          dateFormRefactor(reduxFilterStartDateUX) +
            "-" +
            dateFormRefactor(reduxFilterEndDateUX),
        );
        break;
      default:
        setDataValue(sentimentValue + "% " + value);
        setInspectorValue(value);
        setColor("#2AAAE2");
        setDate(
          dateFormRefactor(reduxFilterStartDateUX) +
            "-" +
            dateFormRefactor(reduxFilterEndDateUX),
        );
    }
    setQuickResponse([]);
    setInspectorName(name);
    setOpen(true);

    let valueToSend;
    if (dataToSend[name] !== undefined && dataToSend[name].length !== 0) {
      valueToSend = dataToSend[name].filter(
        (data) =>
          CheckValueLocale(data.name, "", {}, intl).toUpperCase() ===
          value.toUpperCase(),
      );
    }
    value =
      valueToSend && valueToSend.length !== 0 ? valueToSend[0].name : value;
    name === "sentiment_overtime"
      ? getQuickInspectData(
          reduxFilterStartDateUX,
          reduxFilterEndDateUX,
          name,
          value,
          sentimentValue,
        )
      : getQuickInspectData(
          reduxFilterStartDateUX,
          reduxFilterEndDateUX,
          name,
          value,
        );
  };
  const { mutate: getQuickInspectDataAction } = useGetQuickInspectData();

  const getQuickInspectData = (
    reduxFilterStartDateUX,
    reduxFilterEndDateUX,
    inspectorName,
    inspectorVal,
    sentimentValue,
  ) => {
    const queryDataParams = {
      monitor_id: monitorId,
      product_id: window.localStorage.sm_id,
      start_date: reduxFilterStartDateUX,
      end_date: reduxFilterEndDateUX,
      inspector_name: inspectorName,
      inspector_value: inspectorVal,
      sentiment_value: sentimentValue,
      filters:
        props?.filterParams &&
        //format FilterParams to be string instead of array and remove empty array params
        JSON.stringify(changeFilterParamsToString(props?.filterParams)),
    };

    setQuickResponse([]); //empty reponse wuth each new call
    getQuickInspectDataAction(
      {
        queryDataParams,
        monitorDataSource,
        monitor_id: monitorId,
      },
      {
        onSuccess: ({ data }) => {
          if (!isEmptyObj(data.data)) {
            setPreLoaderTrackerQuickDataSources([parseInt(monitorId)]);
            mqttRabbitMQResponce(clientMQTT, data.data, setQuickResponse);
          } else {
            mqttConnectionClosed.current = true;
          }
        },
      },
    );
  };
  //___________________________________________________________________________________

  const twitterIcon = (
    <span className="topics-twitter-icon">
      <img
        src={xPlatform}
        alt="x-platform-logo"
        className={"icon-after-title twitter-icon"}
      />
    </span>
  );

  //___________________________________________________________________________________

  useEffect(() => {
    if (!isEmptyArray(postsAnalyticsData) && !isEmptyArray(overViewData)) {
      props.setExcelSheetData(overViewData.concat(postsAnalyticsData));
    }
  }, [overViewData, postsAnalyticsData]);

  const childProps = {
    setOverViewData,
    commentsVolume,
    setCommentsVolume,
    commentsInteractions,
    setCommentsInteractions,
    commentsInteractionsPreLoader,
    setCommentsInteractionsPreLoader,
    commentsInteractionsLegend,
    setCommentsInteractionsLegend,
    sentimentAnalysis,
    setSentimentAnalysis,
    sentimentAnalysisPreLoader,
    setSentimentAnalysisPreLoader,
    isSentimentAnalysisHourlyFormat,
    setIsSentimentAnalysisHourlyFormat,
    topVideos,
    setTopVideos,
    topVideosPreLoader,
    setTopVideosPreLoader,
    topImages,
    setTopImages,
    topImagesPreLoader,
    setTopImagesPreLoader,
    topKeywords,
    setTopKeywords,
    topKeywordsPreLoader,
    setTopKeywordsPreLoader,
    topHashtags,
    setTopHashtags,
    topHashtagsPreLoader,
    setTopHashtagsPreLoader,
    commentsVolumePreLoader,
    setCommentsVolumePreLoader,
    associatedTopics,
    setAssociatedTopics,
    associatedTopicsPreLoader,
    setAssociatedTopicsPreLoader,
    contentStyles,
    setContentStyles,
    contentStylesPreLoader,
    setContentStylesPreLoader,
    reachFunnel,
    setReachFunnel,
    reachFunnelPreLoaderDataSources,
    setReachFunnelPreLoaderDataSources,
    topPosts,
    setTopPosts,
    topTopPostsPreLoaderDataSources,
    setTopPostsPreLoaderDataSources,
    topLanguages,
    setTopLanguages,
    topLanguagesPreLoader,
    setTopLanguagesPreLoader,
    dialects,
    setDialects,
    subDialects,
    setSubDialects,
    dialectsPreLoader,
    setDialectsPreLoader,
    topCities,
    setTopCities,
    topCitiesPreLoader,
    setTopCitiesPreLoader,
    topCountries,
    setTopCountries,
    topCountriesPreLoader,
    setTopCountriesPreLoader,
    genderDistribution,
    setGenderDistribution,
    genderDistributionPreLoader,
    setGenderDistributionPreLoader,
    accountTypes,
    setAccountTypes,
    accountTypesPreLoader,
    setAccountTypesPreLoader,
    topSources,
    setTopSources,
    topSourcesPreLoader,
    setTopSourcesPreLoader,
    topVerifiedEngagers,
    setTopVerifiedEngagers,
    topVerifiedEngagersPreLoader,
    setTopVerifiedEngagersPreLoader,
    topEngagers,
    setTopEngagers,
    topEngagersPreLoader,
    setTopEngagersPreLoader,
    topInfleuncers,
    setTopInfleuncers,
    topInfleuncersPreLoader,
    setTopInfleuncersPreLoader,
    themeTrend,
    setThemeTrend,
    themeAnalysis,
    setThemeAnalysis,
    themeTrendPreLoader,
    setThemeTrendPreLoader,
    themeAnalysisPreLoader,
    setThemeAnalysisPreLoader,
    themeXlxs,
    setThemeXlxs,
  };

  const {
    updateAnalysis,
    handleCloseSnackBar,
    showAnalyzeTweetSnackBar,
    analyzeTweetSnackBar,
    stats,
    handleCloseCreateModal,
    modalBody,
  } = CommonFunctions();

  useEffect(() => {
    let totalPreLoaderDataSouces = [];

    totalPreLoaderDataSouces.push(
      topTopPostsPreLoaderDataSources,
      topInfleuncersPreLoader,
      topEngagersPreLoader,
      topVerifiedEngagersPreLoader,
      topSourcesPreLoader,
      accountTypesPreLoader,
      genderDistributionPreLoader,
      topCountriesPreLoader,
      topCitiesPreLoader,
      dialectsPreLoader,
      topLanguagesPreLoader,
      reachFunnelPreLoaderDataSources,
      contentStylesPreLoader,
      associatedTopicsPreLoader,
      commentsVolumePreLoader,
      topHashtagsPreLoader,
      topKeywordsPreLoader,
      topImagesPreLoader,
      topVideosPreLoader,
      sentimentAnalysisPreLoader,
      commentsInteractionsPreLoader,
    );

    props.checkAllDataFounded(
      totalPreLoaderDataSouces,
      props.preLoaderTrackerDataSources,
    );

    props.checkDataIsFounded(
      topPosts,
      topInfleuncers,
      topEngagers,
      topVerifiedEngagers,
      topSources,
      accountTypes,
      genderDistribution,
      topCountries,
      topCities,
      subDialects,
      dialects,
      topLanguages,
      reachFunnel,
      contentStyles,
      associatedTopics,
      topHashtags,
      topKeywords,
      topImages,
      topVideos,
      sentimentAnalysis,
      commentsInteractionsLegend,
      commentsInteractions,
    );
  }, [
    topPosts,
    topInfleuncers,
    topEngagers,
    topVerifiedEngagers,
    topSources,
    accountTypes,
    genderDistribution,
    topCountries,
    topCities,
    subDialects,
    dialects,
    topLanguages,
    reachFunnel,
    contentStyles,
    associatedTopics,
    topHashtags,
    topKeywords,
    topImages,
    topVideos,
    sentimentAnalysis,
    commentsInteractionsLegend,
    commentsInteractions,
    topTopPostsPreLoaderDataSources,
    topInfleuncersPreLoader,
    topEngagersPreLoader,
    topVerifiedEngagersPreLoader,
    topSourcesPreLoader,
    accountTypesPreLoader,
    genderDistributionPreLoader,
    topCountriesPreLoader,
    topCitiesPreLoader,
    dialectsPreLoader,
    topLanguagesPreLoader,
    reachFunnelPreLoaderDataSources,
    contentStylesPreLoader,
    associatedTopicsPreLoader,
    commentsVolumePreLoader,
    topHashtagsPreLoader,
    topKeywordsPreLoader,
    topImagesPreLoader,
    topVideosPreLoader,
    sentimentAnalysisPreLoader,
    commentsInteractionsPreLoader,
  ]);

  //Luci check [ noDataFound , Analyzing ]
  useEffect(() => {
    let x = props.checkLuciAllDataFounded(
      topTopPostsPreLoaderDataSources,
      props.preLoaderTrackerDataSources,
    );
    let y = props.checkLuciDataIsFounded([topPosts]);
    props.setLuciCheckAllDataExist(x);
    props.setLuciCheckDataIsEmpty(y);
  }, [
    topPosts,
    topTopPostsPreLoaderDataSources,
    props.preLoaderTrackerDataSources,
  ]);

  return (
    <Box mb={5}>
      <OverViewSection
        twitterIcon={twitterIcon}
        updateAnalysis={updateAnalysis}
        handleOpenQuickInspect={handleOpenQuickInspect}
        {...props}
        {...childProps}
      />
      <AuthorAnalyticsSection
        setPostsAnalyticsData={setPostsAnalyticsData}
        twitterIcon={twitterIcon}
        setDataToSend={setDataToSend}
        dataToSend={dataToSend}
        handleOpenQuickInspect={handleOpenQuickInspect}
        {...props}
        {...childProps}
      />

      <TopicsSection
        iconToDisplay={twitterIcon}
        audienceDistribution={true}
        {...props}
      />

      <QuickInspect
        {...props}
        handleOpenQuickInspect={handleOpenQuickInspect}
        open={open}
        setOpen={setOpen}
        preLoaderTrackerDataSources={preLoaderTrackerQuickDataSources}
        quickResponse={quickResponse}
        inspectorName={inspectorName}
        inspectorValue={inspectorValue}
        twitterIcon={twitterIcon}
        dataValue={dataValue}
        date={date}
        color={color}
        singleTweetAnalysis={props.singleTweetAnalysis}
        quickInspectKeywordMonitor // to handle locals of widgets in keyword ONLY
      />
      {showAnalyzeTweetSnackBar && (
        <SnackBar
          open={true}
          handleClose={handleCloseSnackBar}
          title={CheckValueLocale(analyzeTweetSnackBar.title, "", {}, intl)}
          message={CheckValueLocale(analyzeTweetSnackBar.message, "", {}, intl)}
          severity={analyzeTweetSnackBar.severity}
        />
      )}
      <PopupModal
        title={CheckValueLocale("single_tweet_analysis", "", {}, intl)}
        body={modalBody}
        open={stats?.openCreateModal}
        close={handleCloseCreateModal}
        warning="warning"
        classeName="create-historical-popup"
        addClasses="create-single-post-analysis-modal"
      />
    </Box>
  );
};

export default EngagementsBody;
