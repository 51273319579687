import {
  Box,
  Button,
  InputAdornment,
  OutlinedInput,
  Tooltip,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomizedTooltip, {
  customizedStyledTooltip,
} from "components/customizedTooltip";
import DOMPurify from "dompurify";
import { useFormik } from "formik";
import KeywordPopupModal from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/keywordPoupModal/keywordPopupModal";
import CopyIcon from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/keywordTypeComponent/copyIcon";
import KeywordTooltipChip from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/keywordTypeComponent/keywordTooltipChip";
import { handleInputMouseDown } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/kewordsSharedFunctions";
import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { addAccountListUsernameValidationSchema } from "../accountsListsValidation/addAccountListUsernameValidationSchema";
import "../accounts-list.scss";

const AccountInputs = ({ usernames, setUsernames }) => {
  const intl = useIntl();

  const [focused, setFocused] = useState(false);
  const [copiedTooltipVisible, setCopiedTooltipVisible] = useState(false);

  const handleSubmit = (values) => {
    if (values?.username?.length > 0) {
      if (values?.username?.includes(",")) {
        const usernameArray = values.username
          ?.split(",")
          ?.map((item) => item?.trim())
          ?.filter((item) => item?.length > 0);
        setUsernames([...usernames, ...usernameArray]);
      } else {
        setUsernames([...usernames, values.username]);
      }
    }

    formik?.resetForm();
    setCopiedTooltipVisible(false);
  };

  const handleDeleteUsername = (e, index) => {
    const newUsernames = [...usernames];
    newUsernames?.splice(index, 1);
    setUsernames(newUsernames);
    setCopiedTooltipVisible(false);
  };

  const handleDeleteAllUsernames = () => {
    setUsernames([]);
    setCopiedTooltipVisible(false);
  };

  const handleCopy = () => {
    navigator?.clipboard?.writeText(usernames?.join(","));
    setCopiedTooltipVisible(true);
  };

  const formik = useFormik({
    initialValues: { username: "" },
    validationSchema: addAccountListUsernameValidationSchema(usernames),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    // this line is important to validate usernames length to display error message --> SQS8-446
    formik.validateForm("username");
  }, [usernames]);

  return (
    <Box className="accounts-list-box-input-fields">
      <Typography
        variant="caption"
        className="accounts-list accounts-list-head-title"
      >
        <CustomizedTooltip
          title={CheckValueLocale(
            "accounts_lists_usernames_tooltip_text",
            "",
            {},
            intl,
          )}
          arrow
          placement="top"
          styles={{
            ...customizedStyledTooltip,
            maxWidth: "200px",
          }}
        >
          <Typography
            variant="caption"
            className="accounts-lists-username-input-label"
          >
            {CheckValueLocale("accounts_lists_usernames", "", {}, intl)}
          </Typography>
          <Typography component="span" className="required">
            *
          </Typography>
        </CustomizedTooltip>
      </Typography>
      <OutlinedInput
        name="username"
        startAdornment={
          focused ? (
            <Typography className="accounts-list-input-startlogo">@</Typography>
          ) : null
        }
        variant="outlined"
        className="accounts-list-field accounts-list-input-field"
        onFocus={() => {
          // to remove duplicated error MSG
          setFocused(true);
        }}
        onBlur={() => {
          // Delete Error message when leaving the Keyword box
          setFocused(false);
        }}
        endAdornment={
          focused && (
            <InputAdornment onMouseDown={handleInputMouseDown}>
              <Button
                className="add-btn-with-plus"
                onClick={() => {
                  formik.handleSubmit();
                }}
                id="monitor-options-tw-add-keyword-btn"
              >
                {CheckValueLocale("add_plus", "", {}, intl)}
              </Button>
            </InputAdornment>
          )
        }
        error={formik?.errors?.username}
        placeholder={CheckValueLocale(
          "accounts_lists_usernames_input_details",
          "",
          {},
          intl,
        )}
        onChange={formik?.handleChange}
        value={formik?.values?.username}
        onKeyDown={(event) => {
          if (event?.key === "Enter") {
            formik?.handleSubmit();
          }
        }}
      />
      {formik?.errors?.username ? (
        <Box
          className="accounts-list-error-msg-form"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              CheckValueLocale(formik?.errors?.username, "", {}, intl),
            ),
          }}
        />
      ) : null}
      <Box className="accountsList-list-wrapper">
        <Box className="accounts-list-spam-wrapper">
          {usernames?.slice(0, 6)?.map((labels, index) => (
            <KeywordTooltipChip
              key={labels}
              onDelete={(e) => handleDeleteUsername(e, index)}
              index={index}
              labels={labels}
            />
          ))}
          <KeywordPopupModal
            keywords={usernames}
            handleDelete={handleDeleteUsername}
            keywordType={"accounts_lists_usernames"}
            showListNumber={true}
          />
          {usernames?.length !== 0 && (
            <CopyIcon
              checkCopyValue={copiedTooltipVisible}
              handleData={handleCopy}
            />
          )}
          <Box className="username-list-trash">
            {usernames?.length !== 0 && (
              <Tooltip
                title={
                  <Box component="span" className="tooltipIcon">
                    {CheckValueLocale("remove_accounts", "", {}, intl)}
                  </Box>
                }
                placement="top"
                arrow
              >
                <DeleteIcon
                  className="trash-icon"
                  onClick={handleDeleteAllUsernames}
                />
              </Tooltip>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default AccountInputs;
