import { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import { useIntl } from "react-intl";
import LucButton from "shared/lucButton/lucButton";
import { Divider, InputAdornment, TextField, Typography } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import AddTagBody from "./addTagBody";
import NewTagButton from "./newTagButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faSpinner,
} from "@fortawesome/pro-regular-svg-icons";
import CustomizedTooltip from "components/customizedTooltip";
import SharedMenuTags from "./sharedMenuTags";

const OptionDropdownTags = ({
  selectedOptions,
  applyOpacity,
  handleCreateTag,
  menuType,
  informalTags,
  setSelectedOptions,
  initialvlue,
  loading,
  setLoading,
  selectedColor,
  setSelectedColor,
  tagName,
  setTagName,
  handleApplyTags,
  editTagData,
  setSnackBar,
  anchorElTagList,
  handleClose,
  setAnchorElTagList,
  setSnackBarTagsTable,
  checkHiddenNewTag,
  itemData,
  tagsTableData,
  handleOpenColorPicker,
  colorPickerAnchor,
  popupRef,
  setColorPickerAnchor,
  tagsLimitReached,
  informalTagsLoading,
}) => {
  const intl = useIntl();
  const [selectedTag, setSelectedTag] = useState(null);
  const [sortedTags, setSortedTags] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  // Sorts tags, moving selected ones to the top
  useEffect(() => {
    if (informalTags?.length) {
      // Create a sorted copy of informalTags
      const sorted = [...informalTags]?.sort((a, b) => {
        // Check if each tag is selected
        const aSelected = selectedOptions?.includes(a?.id);
        const bSelected = selectedOptions?.includes(b?.id);
        // Prioritize selected tags by placing them at the top
        if (aSelected !== bSelected) {
          return bSelected - aSelected;
        }
        // Ensure names are defined to prevent errors
        const nameA = a?.name ?? "";
        const nameB = b?.name ?? "";
        // Sort alphabetically by name if both tags have the same selection status
        return nameA?.localeCompare(nameB);
      });
      // Update the state with the sorted tags
      setSortedTags(sorted);
    }
  }, [selectedOptions, informalTags]);

  // Toggles tag selection (max 10)
  const handleSelectTags = (id) => {
    setSelectedOptions((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id); // Remove if selected
      } else if (prev.length < 10) {
        return [...prev, id]; // Add if under limit
      } else {
        return prev; // No change if at limit
      }
    });
  };
  const areSelectedEqual = (selectedOptions, initialvlue) =>
    selectedOptions?.length === initialvlue?.length &&
    initialvlue?.every((id) => selectedOptions?.includes(id));

  const tagsToHide = informalTags?.filter(
    (tag) =>
      !itemData?.tags?.some((item) => item?.id === tag?.id) && //	Ensure the tag is NOT in itemData.tags
      !selectedOptions?.includes(tag?.id) && // Ensure the tag is NOT selected
      tag.is_enabled === false, // Ensure the tag is_enabled = false
  );

  // Finds the index of the last selected tag in the list.
  // Returns -1 if no tag is selected.
  const lastSelectedIndex = sortedTags?.findLastIndex((tag) =>
    selectedOptions?.includes(tag?.id),
  );

  const filteredTags = sortedTags?.filter((tag) =>
    tag?.label?.toLowerCase()?.includes(searchQuery?.toLowerCase()),
  );

  // search input
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // check disabled or enabled checkbox
  const checkDesabled = (tag) => {
    return selectedOptions?.length >= 10 && !selectedOptions?.includes(tag?.id);
  };
  const checkSelectedDisabled = (tag) => {
    return !selectedOptions?.includes(tag?.id) && !tag?.is_enabled;
  };

  return (
    <>
      <SharedMenuTags
        id={"menu-tags-list"}
        anchorEl={anchorElTagList}
        setAnchorEl={setAnchorElTagList}
        close={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: intl?.locale == "en" ? "right" : "left",
        }}
        transformOrigin={{
          vertical: "top",

          horizontal: intl?.locale == "en" ? "right" : "left",
        }}
      >
        <Box className="engagement-tag-container">
          <Box className="option-dropdown-tags-container">
            {menuType == "tags" ? (
              <>
                {informalTags?.length >= 15 ? (
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder={CheckValueLocale(
                      "search_for_tags",
                      "",
                      {},
                      intl,
                    )}
                    className="search-input-tag"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faMagnifyingGlass} />
                        </InputAdornment>
                      ),
                    }}
                  />
                ) : null}

                <Box className="menu-tag-container">
                  {searchQuery && filteredTags?.length === 0 ? null : (
                    <NewTagButton
                      handleApply={handleCreateTag}
                      checkHiddenNewTag={checkHiddenNewTag}
                      showTooltip={informalTags?.length >= 350}
                    />
                  )}
                  {informalTagsLoading || loading ? (
                    <Box className="loading-box">
                      <FontAwesomeIcon icon={faSpinner} />
                    </Box>
                  ) : informalTags?.length && !informalTagsLoading ? (
                    filteredTags?.length ? (
                      filteredTags?.map((tag, index) => {
                        if (
                          tagsToHide.some(
                            (hiddenTag) => hiddenTag.id === tag.id,
                          )
                        ) {
                          return null; // Skip rendering this tag
                        }
                        return (
                          <>
                            <CustomizedTooltip
                              id={
                                checkDesabled(tag)
                                  ? "tooltip-tag-limit"
                                  : "tooltip-tag-disabled"
                              }
                              title={CheckValueLocale(
                                checkDesabled(tag)
                                  ? "tag_limit_tooltip"
                                  : checkSelectedDisabled(tag)
                                    ? "tag_is_disabled_tooltip"
                                    : "",
                                "",
                                {},
                                intl,
                              )}
                              placement={
                                intl?.locale == "en" ? "left" : "right"
                              }
                              arrow
                            >
                              <Box
                                key={tag?.id}
                                onClick={() => {
                                  if (
                                    !checkSelectedDisabled(tag) ||
                                    tag?.is_enabled
                                  ) {
                                    handleSelectTags(tag?.id);
                                  }
                                }}
                                disabled={
                                  checkSelectedDisabled(tag) ||
                                  checkDesabled(tag)
                                }
                                className={`options-container ${
                                  checkSelectedDisabled(tag) ||
                                  checkDesabled(tag)
                                    ? "options-container-disabled"
                                    : ""
                                }`}
                              >
                                <Checkbox
                                  checked={selectedOptions?.includes(tag?.id)}
                                  disabled={
                                    checkSelectedDisabled(tag) ||
                                    checkDesabled(tag)
                                  }
                                />
                                <Box
                                  className="tag-label-box"
                                  sx={{
                                    backgroundColor: applyOpacity(
                                      tag?.color,
                                      0.1,
                                    ),
                                  }}
                                >
                                  <Box
                                    className="color-tag"
                                    sx={{
                                      backgroundColor: tag?.color,
                                    }}
                                    onClick={() =>
                                      setSelectedTag(
                                        selectedTag === tag?.id
                                          ? null
                                          : tag?.id,
                                      )
                                    }
                                  />
                                  <ListItemText primary={tag?.label} />
                                </Box>
                              </Box>
                            </CustomizedTooltip>
                            {/* Divider after the 10th selected tag */}
                            {index === lastSelectedIndex ? (
                              <Divider className="menu-tag-divider" />
                            ) : null}
                          </>
                        );
                      })
                    ) : (
                      <Box className="no-tags-found">
                        {CheckValueLocale("no_tags_found", "", {}, intl)}
                      </Box>
                    )
                  ) : (
                    <Box className="option-dropdown-tags-container">
                      <Box className="tags-empty-case">
                        <Typography component={"h4"}>
                          {CheckValueLocale("no_tags_yet", "", {}, intl)}
                        </Typography>
                        <Typography>
                          {CheckValueLocale("no_tags_yet_msg", "", {}, intl)}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
                {searchQuery &&
                filteredTags?.length === 0 ? null : informalTags?.length ? (
                  <Box className="apply-tag-container">
                    <LucButton
                      id="apply-tag"
                      className="apply-tag-button"
                      type="primary"
                      size="small"
                      variant="filled"
                      onClick={handleApplyTags}
                      disabled={areSelectedEqual(selectedOptions, initialvlue)}
                    >
                      {CheckValueLocale("avg_apply", "", {}, intl)}
                    </LucButton>
                  </Box>
                ) : null}
              </>
            ) : null}
            {menuType == "addTag" ? (
              <AddTagBody
                loading={loading}
                setLoading={setLoading}
                selectedColor={selectedColor}
                setSelectedColor={setSelectedColor}
                tagName={tagName}
                setTagName={setTagName}
                informalTags={informalTags}
                editTagData={editTagData}
                setSnackBar={setSnackBar}
                setSnackBarTagsTable={setSnackBarTagsTable}
                handleClose={handleClose}
                tagsTableData={tagsTableData}
                handleOpenColorPicker={handleOpenColorPicker}
                colorPickerAnchor={colorPickerAnchor}
                popupRef={popupRef}
                setColorPickerAnchor={setColorPickerAnchor}
                tagsLimitReached={tagsLimitReached}
              />
            ) : null}
          </Box>
        </Box>
      </SharedMenuTags>
    </>
  );
};

export default OptionDropdownTags;
