import { useIntl } from "react-intl";
import {
  Button,
  Grid,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
  Tooltip,
  CircularProgress,
  FormControl,
  ListItemText,
} from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import FormHelperError from "./formHelperError";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import LucButton from "shared/lucButton/lucButton";

const WebhooksAlertsFields = (props) => {
  const intl = useIntl();
  const {
    isCreateAlert,
    formFieldErrors,
    alertEditData,
    enableWebhooks,
    lucidyaApisList,
    keyLocationList,
    apiKey,
    setApiKey,
    method,
    setMethod,
    url,
    setUrl,
    verificationKey,
    testWebhooksResult,
    setTestWebhooksResult,
    testWebhooksLoading,
    handleTestUrl,
    checkDisableTestUrl,
    deleteWebhooksLoading,
    handleDeleteWebhooks,
    handleEnableDisableWebhooks,
    enableWebhooksLoading,
    disableWebhooksLoading,
    setFormFieldErrors,
    handleVerificatinKeyChange,
    authVarName,
    handleAuthVarName,
    authLocation,
    handleAuthLocationChange,
    activeAlertType,
  } = props;

  const errorwebhooksObjMsg = {
    passed: "msg-success",
    failed: "msg-failed",
    warning: "msg-warning",
  };

  const errorwebhooksObj = {
    passed: "url_passed_successful",
    failed: "url_passed_failed",
    warning: "test_your_url",
  };
  let errorwebhooks = formFieldErrors?.url
    ? formFieldErrors?.url
    : testWebhooksResult == ""
      ? ""
      : errorwebhooksObj[testWebhooksResult];

  const checkWarningUrlResult = () =>
    testWebhooksResult === "passed" && setTestWebhooksResult("warning");
  const isXTopTrendsSelected = activeAlertType === "x top trends";

  return (
    <>
      <Typography component="p" className="alerts-divider"></Typography>
      <Grid item xs={12}>
        <Typography component="h6" className="alert-advanced-title">
          {CheckValueLocale("webhooks", "", {}, intl)}
        </Typography>
        <Typography component="p" className="alert-advanced-description">
          {CheckValueLocale(
            "allows_send_notifications_to_external_service",
            "",
            {},
            intl,
          )}{" "}
          <a
            href="https://docs.lucidya.com/"
            target="_blank"
            rel="noreferrer"
            className="api-documentation-link"
          >
            {CheckValueLocale("learn_more", "", {}, intl)}
          </a>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormGroup className="Checkbox-wrapper">
          <FormControlLabel
            control={
              <Checkbox
                className="alerts-checked-email-notification"
                checked={enableWebhooks}
                onChange={handleEnableDisableWebhooks}
                disabled={
                  isXTopTrendsSelected ||
                  (enableWebhooks
                    ? enableWebhooksLoading
                    : disableWebhooksLoading)
                }
              />
            }
            label={CheckValueLocale("enable_webhooks", "", {}, intl)}
          />
          {enableWebhooksLoading || disableWebhooksLoading ? (
            <CircularProgress className="circular-progress" />
          ) : null}
        </FormGroup>
        {enableWebhooks ? (
          <Box className="Webhooks-wrapper">
            <Box className="heading-wrapper">
              <Typography component="h7" className="alert-advanced-sub-title">
                {CheckValueLocale("setup_your_webhook", "", {}, intl)}
              </Typography>
              <Tooltip
                title={CheckValueLocale("delete_webhook", "", {}, intl)}
                placement="top"
                arrow
              >
                <Button
                  variant="outlined"
                  onClick={handleDeleteWebhooks}
                  disabled={
                    deleteWebhooksLoading ||
                    isCreateAlert ||
                    !alertEditData?.webhook_info?.id
                  }
                  className="webhooks-delete"
                  id="webhooks-delete-btn"
                >
                  {deleteWebhooksLoading ? (
                    <CircularProgress className="circular-progress-error circular-progress" />
                  ) : null}
                  <FontAwesomeIcon icon={faTrashAlt} />
                </Button>
              </Tooltip>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box className="input-form-control">
                  <InputLabel>
                    {CheckValueLocale("api_key", "", {}, intl)}
                    <Typography component="span" className="required">
                      *
                    </Typography>
                  </InputLabel>
                  <Select
                    value={apiKey}
                    onChange={(e) => {
                      setApiKey(e?.target?.value);
                      checkWarningUrlResult();
                      setFormFieldErrors({
                        ...formFieldErrors,
                        apiKey: "",
                      });
                    }}
                    fullWidth
                    className="alert-form-select"
                    labelId="alert-api-key-select-label"
                    id="alert-api-key-select"
                    displayEmpty
                    error={formFieldErrors?.apiKey !== ""}
                    renderValue={
                      apiKey !== ""
                        ? undefined
                        : () =>
                            CheckValueLocale("select_placeholder", "", {}, intl)
                    }
                  >
                    {lucidyaApisList?.length ? (
                      lucidyaApisList?.map((val) =>
                        val?.attributes?.is_valid ||
                        alertEditData?.webhook_info?.token_id == val?.id ? (
                          <MenuItem
                            className={`alert-form-field 
                              ${!val?.attributes?.is_valid && alertEditData?.webhook_info?.token_id == val?.id ? "alert-field-info" : ""}`}
                            disabled={
                              !val?.attributes?.is_valid &&
                              alertEditData?.webhook_info?.token_id == val?.id
                            }
                            key={`api-key-type-${val?.id}`}
                            value={val?.id}
                            id={`alert-api-key-type-${val?.attributes?.token_name ?? "alert-info"}`}
                          >
                            {val?.attributes?.token_name}
                          </MenuItem>
                        ) : null,
                      )
                    ) : (
                      <MenuItem disabled value="" className="">
                        {CheckValueLocale("no_api_keys_found", "", {}, intl)}
                      </MenuItem>
                    )}
                  </Select>
                </Box>
                <FormHelperError value={formFieldErrors?.apiKey} />
              </Grid>
              <Grid item xs={6}>
                <Box className="input-form-control">
                  <InputLabel>
                    {CheckValueLocale("method", "", {}, intl)}
                    <Typography component="span" className="required">
                      *
                    </Typography>
                    <Tooltip
                      title={
                        <span className="method-tooltip">
                          {CheckValueLocale("method_hint", "", {}, intl)}
                        </span>
                      }
                      placement="bottom"
                      arrow
                    >
                      <Typography component="span" className="info-icon">
                        <FontAwesomeIcon icon={faInfoCircle} />
                      </Typography>
                    </Tooltip>
                  </InputLabel>
                  <Select
                    value={method}
                    onChange={(e) => {
                      setMethod(e?.target?.value);
                      checkWarningUrlResult();
                    }}
                    fullWidth
                    displayEmpty
                    className="alert-form-select"
                    labelId="alert-method-select-label"
                    id="alert-method-simple-select"
                  >
                    <MenuItem value="POST">HTTP Post</MenuItem>
                    <MenuItem value="GET">HTTP Get</MenuItem>
                  </Select>
                </Box>
              </Grid>
              <Grid item xs={6} mt={1}>
                <Box className="input-form-control">
                  <InputLabel>
                    {CheckValueLocale("auth_variable_name", "", {}, intl)}
                    <Typography component="span" className="required">
                      *
                    </Typography>
                  </InputLabel>
                  <TextField
                    fullWidth
                    labelId="alert-auth-var-name-label"
                    id="alert-auth-var-name-input"
                    value={authVarName}
                    onChange={(e) => {
                      handleAuthVarName(e);
                    }}
                    error={formFieldErrors?.authVarName ? true : false}
                  />
                  <FormHelperError value={formFieldErrors?.authVarName} />
                </Box>
              </Grid>
              <Grid item xs={6} mt={1}>
                <Box className="input-form-control">
                  <InputLabel>
                    {CheckValueLocale("auth_location", "", {}, intl)}
                    <Typography component="span" className="required">
                      *
                    </Typography>
                  </InputLabel>
                  <FormControl fullWidth>
                    <Select
                      labelId="auth-location-multiple-select-label"
                      id="auth-location-multiple-select"
                      renderValue={(selected) =>
                        selected
                          ?.map(
                            (id) =>
                              keyLocationList?.find((item) => item?.id === id)
                                ?.name,
                          )
                          .join(", ")
                      }
                      multiple
                      value={authLocation}
                      onChange={(e) => {
                        handleAuthLocationChange(e);
                      }}
                    >
                      <MenuItem
                        value={
                          authLocation?.length >= keyLocationList?.length
                            ? "unselect_all"
                            : "select_all"
                        }
                      >
                        <Checkbox
                          checked={
                            keyLocationList?.length === authLocation?.length
                          }
                        />
                        <ListItemText
                          primary={CheckValueLocale(
                            "auth_location_select_all",
                            "",
                            {},
                            intl,
                          )}
                          sx={{ margin: "0 10px" }}
                        />
                      </MenuItem>
                      {keyLocationList?.map((item, index) => {
                        return (
                          <MenuItem
                            key={index}
                            name={item?.name}
                            value={item?.id}
                          >
                            <Checkbox
                              checked={authLocation.indexOf(item?.id) > -1}
                            />
                            <ListItemText
                              primary={item?.name}
                              sx={{ margin: "0 10px" }}
                            />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} mt={1}>
                <Box className="input-form-control">
                  <InputLabel>
                    {CheckValueLocale("ai_api_verification_key", "", {}, intl)}
                    <Typography component="span" className="required">
                      *
                    </Typography>
                    <Tooltip
                      title={
                        <Box component="span" className="method-tooltip">
                          {CheckValueLocale(
                            "ai_api_verification_key_tooltip",
                            "",
                            {},
                            intl,
                          )}
                        </Box>
                      }
                      placement="top"
                      arrow
                    >
                      <Typography component="span" className="info-icon">
                        <FontAwesomeIcon icon={faInfoCircle} />
                      </Typography>
                    </Tooltip>
                  </InputLabel>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        labelId="alert-url-select-label"
                        id="alert-url-simple-select"
                        error={formFieldErrors?.verificationKey ? true : false}
                        value={verificationKey}
                        onChange={(e) => {
                          handleVerificatinKeyChange(e);
                        }}
                      />
                    </Grid>
                  </Grid>

                  <FormHelperError value={formFieldErrors?.verificationKey} />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box className="input-form-control">
                  <InputLabel>
                    {CheckValueLocale("url_webhooks", "", {}, intl)}
                    <Typography component="span" className="required">
                      *
                    </Typography>
                  </InputLabel>
                  <Grid container spacing={2}>
                    <Grid item xs={9}>
                      <TextField
                        fullWidth
                        labelId="alert-url-select-label"
                        id="alert-url-simple-select"
                        className={`alert-title-field ${testWebhooksResult !== "" ? errorwebhooksObjMsg[testWebhooksResult] : ""}`}
                        error={
                          formFieldErrors?.url ||
                          testWebhooksResult === "failed"
                            ? true
                            : false
                        }
                        value={url}
                        onChange={(e) => {
                          setUrl(e?.target?.value);
                          checkWarningUrlResult();
                        }}
                        placeholder="https://www.exmaple.com/webhook"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <LucButton
                        variant="outline"
                        type="secondary"
                        size="small"
                        id="test-url"
                        disabled={testWebhooksLoading || checkDisableTestUrl()}
                        onClick={handleTestUrl}
                        loading={testWebhooksLoading}
                      >
                        {CheckValueLocale(
                          testWebhooksLoading ? "loading" : "test_this_url",
                          "",
                          {},
                          intl,
                        )}
                      </LucButton>
                    </Grid>
                  </Grid>
                  <FormHelperError
                    value={errorwebhooks}
                    icon
                    className={`${testWebhooksResult !== "" ? errorwebhooksObjMsg[testWebhooksResult] : ""}`}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        ) : null}
      </Grid>
    </>
  );
};
export default WebhooksAlertsFields;
