import { sanitizeEngagementChatMessage } from "utils/helpers";
import { TranslationText } from "../translationText";

export function TextMessage({
  text,
  showTranslationText = true,
  message,
  selectedCard,
  index,
  translationActive,
  translationTarget,
  translationSource,
  setStats,
}) {
  if (!text) {
    return null;
  }

  return (
    <>
      <p
        className="data-content-text"
        dangerouslySetInnerHTML={{
          __html: sanitizeEngagementChatMessage(text),
        }}
      />
      {/* handle text translation */}
      {showTranslationText ? (
        <TranslationText
          data={message}
          index={index}
          selectedCard={selectedCard}
          setStats={setStats}
          translationTarget={translationTarget}
          translationActive={translationActive}
          translationSource={translationSource}
          text={text}
        />
      ) : null}
    </>
  );
}
