import { Box, Tooltip, Typography } from "@mui/material";
import { SelectFieldDropdown } from "pages/settings/pages/inboundRoutingPage/components/selectFieldDropdown/selectFieldDropdown";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 188,
    },
  },
};

export const AgentStatus = ({
  updateAgentsStatus,
  value,
  agentStatusOptions,
}) => {
  const intl = useIntl();
  const handleChange = (event) => {
    const status = event?.target?.value;
    updateAgentsStatus(status);
  };

  const getStatusColor = (value) => {
    let statusColorMapper = {
      available: "#4CAF50",
      unavailable: "#94A3B8",
      default: "#ED6C02",
    };
    return statusColorMapper[value] || statusColorMapper.default;
  };

  const agentStatusOptionsReshaped = agentStatusOptions?.map((item) => {
    return {
      name: `agent_status_${item}`,
      color: getStatusColor(item),
      id: item,
    };
  });
  return (
    <Box className="agent-status-dropdown-container">
      <SelectFieldDropdown
        options={agentStatusOptionsReshaped}
        value={value}
        multiple={false}
        label={CheckValueLocale("agent_status_label", "", {}, intl)}
        onChange={handleChange}
        rootClassName="agent-status-dropdown-root"
        menuProps={MenuProps}
        renderValue={(selected) => {
          // Render the selected value without a tooltip
          const selectedOption = agentStatusOptionsReshaped?.find(
            (option) => option?.id === selected,
          );
          return (
            <Box className="agent-status-dropdown-item">
              <Box
                className="agent-status-dropdown-item-circle"
                sx={{ background: selectedOption?.color }}
                component="span"
              />
              <Typography
                component="span"
                className="agent-status-dropdown-item-title"
              >
                {CheckValueLocale(selectedOption?.name, "", {}, intl)}
              </Typography>
            </Box>
          );
        }}
        renderItem={(option) => {
          return (
            <Tooltip
              title={CheckValueLocale(
                option?.id === "available"
                  ? "agent_status_enable_auto_assign"
                  : "agent_status_disable_auto_assign",
                "",
                {},
                intl,
              )}
              placement="left"
              arrow
              id="navbar-agent-status-tooltip"
            >
              <Box className="agent-status-dropdown-item">
                <Box
                  className="agent-status-dropdown-item-circle"
                  sx={{ background: option?.color }}
                  component="span"
                />
                <Typography
                  component="span"
                  className="agent-status-dropdown-item-title"
                >
                  {CheckValueLocale(option?.name, "", {}, intl)}
                </Typography>
              </Box>
            </Tooltip>
          );
        }}
      />
    </Box>
  );
};
