import {
  Box,
  List,
  ListItem,
  Checkbox,
  ListItemText,
  ListItemAvatar,
  Typography,
  Avatar,
} from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import PopupModal from "components/popupModal";
import { useIntl } from "react-intl";
import { useEffect, useState, useRef } from "react";
import { CheckValueLocale } from "utils/helpers";
import "./bouncedEmailsAlert.scss";
import { useFormik } from "formik";
import { useGetBouncedAccountsList } from "../../hooks/useGetBouncedAccountsList";
import { useUnblockBouncedEmails } from "../../hooks/useUnblockBouncedEmails";
import SnackBar from "components/snackBar";
import UserAvatar from "shared/unifiedUserImage/userAvatar";
const BouncedEmailsPopup = (props) => {
  const intl = useIntl();
  const [accountsLists, setAccountsLists] = useState([]);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const listContainerRef = useRef(null);
  const [openSnackBar, setOpenSnackBar] = useState({});
  const handleClose = () => {
    props?.setOpen(false);
    formik?.resetForm();
  };
  const { data, isError } = useGetBouncedAccountsList(page, {
    email_status: "bounced",
  });
  useEffect(() => {
    if (isError) {
      setOpenSnackBar({
        open: true,
        type: "error",
        message: "try_again",
        title: "something_went_wrong",
      });
    }
  }, [isError]);
  const unblockEmails = useUnblockBouncedEmails();
  const unblockEmailsHandler = () => {
    unblockEmails?.mutate(formik?.values, {
      onSuccess: (res) => {
        handleClose();
        setOpenSnackBar({
          open: true,
          type: "success",
          message: "sent_unblock_request",
        });
      },
      onError: () => {
        setOpenSnackBar({
          open: true,
          type: "error",
          message: "try_again",
          title: "something_went_wrong",
        });
      },
    });
  };
  const formik = useFormik({
    initialValues: {
      ids: [],
    },
    onSubmit: unblockEmailsHandler,
  });
  useEffect(() => {
    if (data) {
      setAccountsLists((prevAccounts) => [
        ...prevAccounts,
        ...(data?.data || []),
      ]);
      props?.setNumOfBouncedEmails(data?.pagination?.count || 0);
      setMaxPage(data?.pagination?.pages || 0);
      setIsLoading(false);
    }
  }, [data]);
  const goNextPage = () => {
    if (page < maxPage && !isLoading) {
      setIsLoading(true);
      setPage((prevPage) => prevPage + 1);
    }
  };
  const handleCheck = (account) => {
    if (formik?.values?.ids?.includes(account?.attributes?.id)) {
      formik?.setFieldValue(
        "ids",
        formik?.values?.ids?.filter((id) => id !== account?.attributes?.id),
      );
    } else {
      formik?.setFieldValue("ids", [
        ...formik?.values?.ids,
        account?.attributes?.id,
      ]);
    }
  };
  const handleScroll = () => {
    const listContainer = listContainerRef?.current;
    if (listContainer && !isLoading) {
      const { scrollTop, scrollHeight, clientHeight } = listContainer;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        goNextPage();
      }
    }
  };
  const modalBody = (
    <Box
      id="bounce-emails-popup-body"
      onScroll={handleScroll}
      ref={listContainerRef}
    >
      <Typography className="account-list-desc">
        {CheckValueLocale("bounced_emails_desc", "", {}, intl)}
      </Typography>
      <Typography className="account-list-header">
        {CheckValueLocale("accounts_list", "", {}, intl)}
      </Typography>
      <List className="list-container">
        {accountsLists?.map((account) => (
          <ListItem
            key={account?.attributes?.id}
            className={`account-list-item ${formik?.values?.ids?.includes(account?.attributes?.id) ? "checked" : ""}`}
            onClick={() => handleCheck(account)}
          >
            <ListItemAvatar>
              <UserAvatar
                fullName={account?.attributes?.name}
                avatarSrc={account?.attributes?.avatar}
                sizes={{ ratio: "40px", fontSize: "12px" }}
              />
            </ListItemAvatar>

            <ListItemText
              primary={account?.attributes?.name}
              secondary={account?.attributes?.email}
            />
            <Checkbox
              checked={formik?.values?.ids?.includes(account?.attributes?.id)}
              onChange={() => handleCheck(account)}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
  return (
    <>
      <PopupModal
        title={CheckValueLocale("bounced_emails", "", {}, intl)}
        body={modalBody}
        leftBtn={CheckValueLocale("cancel", "", {}, intl)}
        rightBtn={CheckValueLocale("unblock_email_address", "", {}, intl)}
        open={props?.open}
        close={handleClose}
        disabled={!formik?.dirty}
        accept={formik?.handleSubmit}
        addClasses="bounced-emails-popup"
        enableDivider={true}
      />
      <SnackBar
        open={openSnackBar?.open}
        handleClose={() => setOpenSnackBar(false)}
        message={CheckValueLocale(openSnackBar?.message, "", {}, intl)}
        severity={openSnackBar?.type}
        title={CheckValueLocale(openSnackBar?.title, "", {}, intl)}
      />
    </>
  );
};
export default BouncedEmailsPopup;
