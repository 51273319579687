import Services from "./Services";

var Surveys = {};

Surveys.getSurveysList = (queryData) => {
  return Services.getData("api/v3/surveys", queryData);
};

Surveys.getSurveyTypes = () => {
  return Services.getData("api/v5/surveys/types");
};

Surveys.getSurveyFilters = () => {
  return Services.getData("api/v5/surveys/filters");
};

Surveys.getEmbeddedSurveyOptions = () => {
  return Services.getData("api/v5/surveys/embedded_options");
};

Surveys.getEmbeddedSurveyFonts = () => {
  return Services.getData("api/v5/surveys/font_types");
};

Surveys.getGetCompanyDomains = (queryData) => {
  return Services.getData("api/v5/surveys/company_domains", queryData);
};

Surveys.createCompanyDomain = (queryData) => {
  return Services.postData("api/v5/surveys/company_domains", queryData);
};

Surveys.deleteCompanyDomain = (id) => {
  return Services.deleteData(`api/v5/surveys/company_domains/${id}`);
};

Surveys.getDomainVerificationStatus = (queryData) => {
  return Services.getData("api/v5/surveys/company_domains/verify", queryData);
};

Surveys.getSurveysById = (id, queryData) => {
  return Services.getData(`api/v3/surveys/${id}`, queryData);
};

Surveys.createSurveys = (queryData) => {
  return Services.postData("api/v3/surveys", queryData);
};

Surveys.saveSurveyChanges = (id, queryData) => {
  return Services.patchData(`api/v3/surveys/${id}`, queryData);
};

Surveys.archiveSurvey = (id) => {
  return Services.postData(`api/v3/surveys/${id}/unpublish`);
};

Surveys.deleteSurvey = (id, queryData) => {
  return Services.deleteData(`api/v5/surveys/${id}`, queryData);
};

Surveys.getSurveyInsights = (queryData, cancelToken) => {
  return Services.getData(
    `api/v3/surveys/insights`,
    queryData,
    undefined,
    cancelToken,
  );
};

Surveys.editSentiment = (queryData) => {
  return Services.putData(
    "api/v3/surveys/interactions/edit_sentiment_interactions",
    queryData,
  );
};

Surveys.editCategories = (queryData) => {
  return Services.putData(
    "api/v3/surveys/interactions/edit_themes_interactions",
    queryData,
  );
};

Surveys.editThemes = (queryData) => {
  return Services.putData(
    "api/v3/surveys/interactions/edit_sub_themes_interactions",
    queryData,
  );
};

Surveys.exportSurvey = (queryData) => {
  return Services.postData(`api/v3/export/xlsx`, queryData);
};

Surveys.excelExport = (queryData) => {
  return Services.postData(`api/v3/export/xlsx`, queryData);
};

Surveys.duplicateSurvey = (id, queryData) => {
  return Services.postData(`api/v3/surveys/${id}/duplicate`, queryData);
};

Surveys.getSurveyspanelList = (queryData) => {
  return Services.getData("api/v4/survey/survey_panel/list", queryData);
};

Surveys.submitSurveyspanel = (queryData) => {
  return Services.postData(`api/v4/survey/survey_panel`, queryData);
};
Surveys.SurveyspanelById = (id, queryData) => {
  return Services.getData(`api/v4/survey/survey_panel/${id}`, queryData);
};
Surveys.submitSurveysSkip = (id, queryData) => {
  return Services.postData(`api/v4/survey/survey_panel/skip/${id}`, queryData);
};

export default Surveys;
