import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import InteractionsList from "components/interactions-list/InteractionsList.js";
import OverView from "components/widgets/overViewWidget";
import {
  CheckValueLocale,
  handleUnixFormat,
  translateLocalsFromRabbitMQ,
  getNoOfDays,
} from "utils/helpers";
import LuciFunctions from "pages/luci/components/luciFunctions/luciFunction";
import ActiveFilters from "shared/channel-analytics-filter/activeFilters/activeFilters";
import AccountSection from "./components/accountSection";
import xPlatform from "images/social-icons/x-platform-icon.svg";
import EngagementsSection from "./components/engagementsSection";
import CustomerCareSection from "./components/customerCareSection";
import { checkIsDataFecthing } from "pages/channelAnalytics/InsightsPage/insightsCommonFunctions";
import _ from "lodash";

const TwitterBody = (props) => {
  const intl = useIntl();
  let {
    response,
    startDate,
    endDate,
    setCheckAllDataFetchingExport,
    setExcelSheetData,
  } = props;

  const twitterIcon = (
    <span className="topics-twitter-icon">
      <img
        src={xPlatform}
        alt="x-platform-logo"
        className={"icon-after-title twitter-icon"}
      />
    </span>
  );

  const initialWidgetsState = {
    overview: [],
    overViewPreLoaderDataSources: [],

    followersGrowth: [],
    totalFollowersFollowersGrowth: 0,
    maxChangeFollowersGrowth: 0,
    avgChangeFollowersGrowth: 0,
    followersGrowthPreLoaderDataSources: [],

    authorActivity: [],
    minValueAuthorActivity: 0,
    maxValueAuthorActivity: 0,
    authorActivityPreLoaderDataSources: [],

    reachFunnel: [],
    reachFunnelPreLoaderDataSources: [],

    postsVolume: [],
    totalPostsVolumeCount: [],
    postsVolumePreLoaderDataSources: [],
    isPostsVolumeHourlyFormat: false,

    postsInteractions: [],
    maxValuePostsInteractions: 0,
    maxValueInteractionsDate: 0,
    minValueInteractionsDate: 0,
    minValuePostsInteractions: 0,
    avgValuePostsInteractions: 0,
    postInteractionsLegend: [],
    postsInteractionsPreLoaderDataSources: [],
    isPostsInteractionsHourlyFormat: false,

    postsContentType: [],
    contentTypeLegend: [],
    postsContentTypePreLoaderDataSources: [],

    topVideos: [],
    topVideosPreLoaderDataSources: [],

    topImages: [],
    topImagesPreLoaderDataSources: [],

    totalCommentsVolumeCount: [],
    totalInteractions: 0,
    avgInteractions: 0,
    commentsVolume: [],
    commentsVolumePreLoader: [],
    isCommentsVolumeHourlyFormat: false,

    sentimentAnalysis: [],
    sentimentPieChart: [],
    sentimentAnalysisPreLoader: [],
    isSentimentAnalysisHourlyFormat: false,
    negativeArray: [],
    positiveArray: [],
    neutralArray: [],

    themeXlxs: [],
    themeAnalysis: [],
    themeTrend: [],
    themeAnalysisPreLoader: [],
    themeTrendPreLoader: [],

    contentStyles: [],
    excelContentStyles: [],
    contentStylesPreLoader: [],

    associatedTopics: [],
    excelAssociatedTopics: [],
    associatedTopicsPreLoader: [],

    topKeywords: [],
    topKeywordsPreLoader: [],

    topHashtags: [],
    topHashtagsPreLoader: [],

    topLanguages: [],
    excelTopLanguages: [],
    topLanguagesPreLoader: [],

    excelDialects: [],
    excelSubDialects: [],
    dialects: [],
    subDialects: [],
    dialectsPreLoader: [],

    topCountries: [],
    excelTopCountries: [],
    topCountriesPreLoader: [],

    topCities: [],
    excelTopCities: [],
    topCitiesPreLoader: [],

    genderDistribution: [],
    excelGenderDistribution: [],
    genderDistributionPreLoader: [],

    accountTypes: [],
    excelAccountTypes: [],
    accountTypesPreLoader: [],

    topEngagers: [],
    topEngagersPreLoader: [],

    topSources: [],
    excelTopSources: [],
    topSourcesPreLoader: [],

    topInfleuncers: [],
    topInfleuncersPreLoader: [],

    topVerifiedEngagers: [],
    topVerifiedEngagersPreLoader: [],

    customerCareData: [],
    customerCarePerformance: [],
    customerCarePreloader: [],
    respRate: 0,

    latestInteractions: [],
    latestInteractionsCount: 0,
    latestInteractionsPreLoaderDataSources: [],
  };

  const [twitterWidgets, setTwitterWidgets] = useState(initialWidgetsState);
  const { checkLuciDataIsFounded, checkLuciAllDataFounded } = LuciFunctions();

  useEffect(() => {
    switch (response?.eventName) {
      case "Twitter__CASingleDataSourceInsightsPage__overview":
        if (response?.eventData) {
          let overViewArray = Object?.entries(
            response?.eventData?.overview,
          )?.map((key) => ({
            name: key[0],
            value: key[1],
          }));
          setTwitterWidgets({
            ...twitterWidgets,
            overview: [...overViewArray],
            overViewPreLoaderDataSources: [response?.data_source],
          });
        }
        break;
      case "Twitter__AccountPage__followers_growth":
        setTwitterWidgets({
          ...twitterWidgets,
          followersGrowth: response?.eventData?.followers_change_over_time,
          totalFollowersFollowersGrowth: response?.eventData?.total_followers,
          maxChangeFollowersGrowth:
            response?.eventData?.max_change_of_followers,
          avgChangeFollowersGrowth: response?.eventData?.avg_chage_of_day,
          followersGrowthPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Twitter__AccountPage__account_author_activity":
        setTwitterWidgets({
          ...twitterWidgets,
          authorActivity: response?.eventData?.account_author_activity,
          minValueAuthorActivity: response?.eventData?.min_value,
          maxValueAuthorActivity: response?.eventData?.max_value,
          authorActivityPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Twitter__AccountPage__reach_funnel":
        setTwitterWidgets({
          ...twitterWidgets,
          reachFunnel: response?.eventData?.reach_funnel,
          reachFunnelPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Twitter__AccountPage__volume_overtime":
        setTwitterWidgets({
          ...twitterWidgets,
          postsVolume: response?.eventData?.posts_over_time,
          totalPostsVolumeCount: response?.eventData?.total_posts_count,
          postsVolumePreLoaderDataSources: [response?.data_source],
          isPostsVolumeHourlyFormat: response?.eventData?.is_24_hours_format,
        });
        break;
      case "Twitter__AccountPage__posts_interactions":
        let dateMaxValue = handleUnixFormat(
          response?.eventData?.posts_interactions?.max_interaction_date,
        );

        let dateMinValue = handleUnixFormat(
          response?.eventData?.posts_interactions?.min_interaction_date,
        );
        setTwitterWidgets({
          ...twitterWidgets,
          postsInteractions:
            response?.eventData?.posts_interactions
              ?.posts_interactions_over_time,
          maxValuePostsInteractions:
            response?.eventData?.posts_interactions?.max_interactions,
          maxValueInteractionsDate: dateMaxValue,
          minValueInteractionsDate: dateMinValue,
          minValuePostsInteractions:
            response?.eventData?.posts_interactions?.min_interactions,
          avgValuePostsInteractions:
            response?.eventData?.posts_interactions?.average_interactions,
          postInteractionsLegend:
            response?.eventData?.posts_interactions?.posts_interactions_legends,
          postsInteractionsPreLoaderDataSources: [response?.data_source],
          isPostsInteractionsHourlyFormat:
            response?.eventData?.is_24_hours_format,
        });
        break;
      case "Twitter__AccountPage__content_type":
        setTwitterWidgets({
          ...twitterWidgets,
          postsContentType: response?.eventData?.posts_content_type,
          contentTypeLegend: response?.eventData?.posts_content_types_legends,
          postsContentTypePreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Twitter__AccountPage__top_videos":
        setTwitterWidgets({
          ...twitterWidgets,
          topVideos: response?.eventData?.top_videos,
          topVideosPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Twitter__AccountPage__top_images":
        setTwitterWidgets({
          ...twitterWidgets,
          topImages: response?.eventData?.top_images,
          topImagesPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Twitter__EngagementsPage__volume_overtime":
        const noOfDays = getNoOfDays(startDate, endDate);
        const avg = response?.eventData?.total_posts_count / noOfDays;
        setTwitterWidgets({
          ...twitterWidgets,
          avgInteractions: avg % 1 !== 0 ? avg.toFixed(2) : avg,
          totalInteractions: response?.eventData?.total_posts_count,
          totalCommentsVolumeCount: response?.eventData?.total_posts_count,
          commentsVolume: response?.eventData?.posts_over_time,
          commentsVolumePreLoader: [response?.data_source],
          isCommentsVolumeHourlyFormat: response?.eventData?.is_24_hours_format,
        });
        break;
      case "Twitter__EngagementsPage__sentiment_analysis":
        Object.entries(response?.eventData?.sentiment_overtime)?.forEach(
          ([key, value]) => {
            switch (key) {
              case "negative":
                value?.map((i) => {
                  return twitterWidgets?.negativeArray.push(i);
                });
                break;
              case "positive":
                value?.map((i) => {
                  return twitterWidgets?.positiveArray.push(i);
                });
                break;
              case "neutral":
                value?.map((i) => {
                  return twitterWidgets?.neutralArray.push(i);
                });
                break;
              default:
                break;
            }
          },
        );

        setTwitterWidgets({
          ...twitterWidgets,
          sentimentAnalysis: {
            negative: twitterWidgets?.negativeArray,
            positive: twitterWidgets?.positiveArray,
            neutral: twitterWidgets?.neutralArray,
          },
          sentimentPieChart: response?.eventData?.sentiment_piechart,
          sentimentAnalysisPreLoader: [response?.data_source],
          isSentimentAnalysisHourlyFormat:
            response?.eventData?.is_24_hours_format,
        });
        break;
      case "Twitter__EngagementsPage__themes_and_sub_themes":
        setTwitterWidgets({
          ...twitterWidgets,
          themeXlxs: response?.eventData?.themes,
          themeAnalysis: response?.eventData?.themes?.theme_analysis,
          themeTrend: response?.eventData?.themes?.theme_trend,
          themeAnalysisPreLoader: [response?.data_source],
          themeTrendPreLoader: [response?.data_source],
        });
        break;
      case "Twitter__EngagementsPage__content_style":
        setTwitterWidgets({
          ...twitterWidgets,
          excelContentStyles: _.cloneDeep([...response.eventData]),
          contentStyles: response?.eventData,
          contentStylesPreLoader: [response?.data_source],
        });
        break;
      case "Twitter__EngagementsPage__associated_topics":
        setTwitterWidgets({
          ...twitterWidgets,
          excelAssociatedTopics: _.cloneDeep([
            ...response.eventData.associated_topics,
          ]),
          associatedTopics: response?.eventData?.associated_topics,
          associatedTopicsPreLoader: [response?.data_source],
        });
        break;
      case "Twitter__EngagementsPage__top_keywords":
        setTwitterWidgets({
          ...twitterWidgets,
          topKeywords: response?.eventData?.top_keywords,
          topKeywordsPreLoader: [response?.data_source],
        });
        break;
      case "Twitter__EngagementsPage__top_hashtags":
        setTwitterWidgets({
          ...twitterWidgets,
          topHashtags: response?.eventData?.top_hashtags,
          topHashtagsPreLoader: [response?.data_source],
        });
        break;
      case "Twitter__EngagementsPage__top_languages":
        setTwitterWidgets({
          ...twitterWidgets,
          excelTopLanguages: _.cloneDeep([...response.eventData.top_languages]),
          topLanguages: translateLocalsFromRabbitMQ(
            "Twitter__EngagementsPage__top_languages",
            response.eventData.top_languages,
          ),
          topLanguagesPreLoader: [response?.data_source],
        });
        break;
      case "Twitter__EngagementsPage__dialects_and_sub_dialects":
        setTwitterWidgets({
          ...twitterWidgets,
          excelDialects: _.cloneDeep([...response.eventData.dialects]),
          excelSubDialects: _.cloneDeep([...response.eventData.sub_dialects]),
          dialects: response?.eventData?.dialects,
          subDialects: response?.eventData?.sub_dialects,
          dialectsPreLoader: [response?.data_source],
        });
        break;
      case "Twitter__EngagementsPage__top_countries":
        setTwitterWidgets({
          ...twitterWidgets,
          topCountries: response.eventData.top_countries,
          excelTopCountries: _.cloneDeep([...response.eventData.top_countries]),
          topCountriesPreLoader: [response?.data_source],
        });
        break;
      case "Twitter__EngagementsPage__top_cities":
        setTwitterWidgets({
          ...twitterWidgets,
          excelTopCities: _.cloneDeep([...response.eventData.top_cities]),
          topCities: response?.eventData?.top_cities,
          topCitiesPreLoader: [response?.data_source],
        });
        break;
      case "Twitter__EngagementsPage__gender_distribution":
        setTwitterWidgets({
          ...twitterWidgets,
          excelGenderDistribution: _.cloneDeep([
            ...response.eventData.top_genders,
          ]),
          genderDistribution: response?.eventData?.top_genders,
          genderDistributionPreLoader: [response?.data_source],
        });
        break;
      case "Twitter__EngagementsPage__account_types":
        setTwitterWidgets({
          ...twitterWidgets,
          excelAccountTypes: _.cloneDeep([...response.eventData.account_types]),
          accountTypes: response?.eventData?.account_types,
          accountTypesPreLoader: [response?.data_source],
        });
        break;
      case "Twitter__EngagementsPage__top_engagers":
        setTwitterWidgets({
          ...twitterWidgets,
          topEngagers: response?.eventData?.top_engagers,
          topEngagersPreLoader: [response?.data_source],
        });
        break;
      case "Twitter__EngagementsPage__top_sources":
        setTwitterWidgets({
          ...twitterWidgets,
          excelTopSources: _.cloneDeep([...response.eventData.top_sources]),
          topSources: response?.eventData?.top_sources,
          topSourcesPreLoader: [response?.data_source],
        });
        break;
      case "Twitter__EngagementsPage__top_influencers":
        setTwitterWidgets({
          ...twitterWidgets,
          topInfleuncers: response?.eventData?.top_influencers,
          topInfleuncersPreLoader: [response?.data_source],
        });
        break;
      case "Twitter__EngagementsPage__top_verified_engagers":
        setTwitterWidgets({
          ...twitterWidgets,
          topVerifiedEngagers: response?.eventData?.top_verified_engagers,
          topVerifiedEngagersPreLoader: [response?.data_source],
        });
        break;
      case "Twitter__CASingleDataSourceInsightsPage__customer_care_performance":
        let data = [];
        if (response?.eventData?.response_time_segment) {
          Object.keys(response?.eventData?.response_time_segment).map(
            (val, index) => {
              data.push({
                name: val,
                value: Object.values(
                  response?.eventData?.response_time_segment,
                )[index],
              });
            },
          );
        }
        setTwitterWidgets({
          ...twitterWidgets,
          customerCareData: data,
          customerCarePerformance: response?.eventData,
          respRate:
            response?.eventData?.number_of_answered_questions /
            response?.eventData?.total_number_of_questions,
          customerCarePreloader: [response?.data_source],
        });
        break;
      case "Twitter__EngagementsPage__top_posts_comments":
        setTwitterWidgets({
          ...twitterWidgets,
          latestInteractions: response?.eventData?.top_posts,
          latestInteractionsPreLoaderDataSources: [response?.data_source],
          latestInteractionsCount: response?.eventData?.top_posts_count,
        });
        break;
      default:
        break;
    }
  }, [response]);

  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (props.resetAllFields !== 1) {
      setTwitterWidgets({ ...initialWidgetsState });
    }
  }, [props.resetAllFields]);

  useEffect(() => {
    let x = checkLuciAllDataFounded(
      twitterWidgets?.latestInteractionsPreLoaderDataSources,
      props.preLoaderTrackerDataSources,
    );
    let y = checkLuciDataIsFounded([twitterWidgets?.latestInteractions]);
    props.setCheckAllDataExist(x);
    props.setCheckDataIsEmpty(y);
  }, [
    twitterWidgets?.latestInteractions,
    twitterWidgets?.latestInteractionsPreLoaderDataSources,
    props.preLoaderTrackerDataSources,
  ]);

  // check fetching for export excel
  useEffect(() => {
    let totalPreLoaderDataSouces = [
      twitterWidgets?.overViewPreLoaderDataSources,
      twitterWidgets?.followersGrowthPreLoaderDataSources,
      twitterWidgets?.authorActivityPreLoaderDataSources,
      twitterWidgets?.reachFunnelPreLoaderDataSources,
      twitterWidgets?.postsVolumePreLoaderDataSources,
      twitterWidgets?.postsInteractionsPreLoaderDataSources,
      twitterWidgets?.postsContentTypePreLoaderDataSources,
      twitterWidgets?.topVideosPreLoaderDataSources,
      twitterWidgets?.topImagesPreLoaderDataSources,
      twitterWidgets?.sentimentAnalysisPreLoader,
      twitterWidgets?.commentsVolumePreLoader,
      twitterWidgets?.themeAnalysisPreLoader,
      twitterWidgets?.themeTrendPreLoader,
      twitterWidgets?.contentStylesPreLoader,
      twitterWidgets?.associatedTopicsPreLoader,
      twitterWidgets?.topKeywordsPreLoader,
      twitterWidgets?.topHashtagsPreLoader,
      twitterWidgets?.topLanguagesPreLoader,
      twitterWidgets?.dialectsPreLoader,
      twitterWidgets?.topCountriesPreLoader,
      twitterWidgets?.topCitiesPreLoader,
      twitterWidgets?.genderDistributionPreLoader,
      twitterWidgets?.accountTypesPreLoader,
      twitterWidgets?.topEngagersPreLoader,
      twitterWidgets?.topSourcesPreLoader,
      twitterWidgets?.topInfleuncersPreLoader,
      twitterWidgets?.topVerifiedEngagersPreLoader,
      twitterWidgets?.customerCarePreloader,
      twitterWidgets?.latestInteractionsPreLoaderDataSources,
    ];

    let result = checkIsDataFecthing(
      totalPreLoaderDataSouces,
      props?.preLoaderTrackerDataSources,
    );
    setCheckAllDataFetchingExport(result);
  }, [
    twitterWidgets?.overViewPreLoaderDataSources,
    twitterWidgets?.followersGrowthPreLoaderDataSources,
    twitterWidgets?.authorActivityPreLoaderDataSources,
    twitterWidgets?.reachFunnelPreLoaderDataSources,
    twitterWidgets?.postsVolumePreLoaderDataSources,
    twitterWidgets?.postsInteractionsPreLoaderDataSources,
    twitterWidgets?.postsContentTypePreLoaderDataSources,
    twitterWidgets?.topVideosPreLoaderDataSources,
    twitterWidgets?.topImagesPreLoaderDataSources,
    twitterWidgets?.sentimentAnalysisPreLoader,
    twitterWidgets?.commentsVolumePreLoader,
    twitterWidgets?.themeAnalysisPreLoader,
    twitterWidgets?.themeTrendPreLoader,
    twitterWidgets?.contentStylesPreLoader,
    twitterWidgets?.associatedTopicsPreLoader,
    twitterWidgets?.topKeywordsPreLoader,
    twitterWidgets?.topHashtagsPreLoader,
    twitterWidgets?.topLanguagesPreLoader,
    twitterWidgets?.dialectsPreLoader,
    twitterWidgets?.topCountriesPreLoader,
    twitterWidgets?.topCitiesPreLoader,
    twitterWidgets?.genderDistributionPreLoader,
    twitterWidgets?.accountTypesPreLoader,
    twitterWidgets?.topEngagersPreLoader,
    twitterWidgets?.topSourcesPreLoader,
    twitterWidgets?.topInfleuncersPreLoader,
    twitterWidgets?.topVerifiedEngagersPreLoader,
    twitterWidgets?.customerCarePreloader,
    twitterWidgets?.latestInteractionsPreLoaderDataSources,
  ]);
  useEffect(() => {
    let excelData = { ...twitterWidgets };
    let authorAcc = [
      twitterWidgets?.authorActivity,
      twitterWidgets?.minValueAuthorActivity,
      twitterWidgets?.maxValueAuthorActivity,
    ];
    excelData["authorActivity"] = [...authorAcc];
    setExcelSheetData([{ ...excelData }]);
  }, [twitterWidgets]);
  const unixDate = {
    start: startDate,
    end: endDate,
  };
  return (
    <>
      <OverView
        data={twitterWidgets?.overview}
        overViewPreLoaderDataSources={
          twitterWidgets?.overViewPreLoaderDataSources
        }
        preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        directMessage={false}
      />

      <AccountSection
        preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        TwitterIcon={twitterIcon}
        twitterWidgets={twitterWidgets}
      />

      <EngagementsSection
        preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        TwitterIcon={twitterIcon}
        twitterWidgets={twitterWidgets}
      />
      <CustomerCareSection
        preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        TwitterIcon={twitterIcon}
        twitterWidgets={twitterWidgets}
      />
      <Box my={4}>
        <InteractionsList
          showProfile={true}
          unixDate={unixDate}
          dataSourceId={props.dataSourceId}
          data={twitterWidgets?.latestInteractions?.slice(0, 5)}
          title={"latest_interactions"}
          dataSources={props.dataSources}
          isPublicTwitter
          latestInteractionsPreLoaderDataSources={
            twitterWidgets?.latestInteractionsPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          showViewMoreButton={twitterWidgets?.latestInteractionsCount > 5}
        />
      </Box>
    </>
  );
};

export default TwitterBody;
