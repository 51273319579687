const NameKeyWidget = (name, monitorType) => {
  const NameKeyObj = {
    Twitter__EngagementsPage__volume_overtime:
      monitorType === "KEYWORD" ? "posts_volume_kw" : "comments_volume",
    Twitter__AccountPage__volume_overtime: "posts_volume",
    Twitter__AccountPage__content_type: "posts_content_type",
    Facebook__AccountPage__content_type: "posts_content_type",
    Instagram__AccountPage__content_type: "posts_content_type",
    Instagram__EngagementsPage__content_type: "posts_content_type",

    Facebook__EngagementsPage__volume_overtime:
      monitorType === "KEYWORD" ? "posts_volume_kw" : "comments_volume",
    Facebook__EngagementsPage__posts_interactions:
      monitorType === "KEYWORD"
        ? "posts_interactions"
        : "comments_interactions",
    Facebook__EngagementsPage__reactions:
      monitorType === "KEYWORD" ? "posts_reactions" : "comments_reactions",
    Facebook__EngagementsPage__sentiment_analysis:
      monitorType === "KEYWORD"
        ? "posts_sentiment_analysis"
        : "comments_sentiment_analysis",
    Facebook__EngagementsPage__top_keywords:
      monitorType === "KEYWORD"
        ? "posts_top_keywords"
        : "comments_top_keywords",
    Facebook__EngagementsPage__top_hashtags:
      monitorType === "KEYWORD"
        ? "posts_top_hashtags"
        : "comments_top_hashtags",
    Facebook__EngagementsPage__associated_topics:
      monitorType === "KEYWORD"
        ? "posts_associated_topics"
        : "comments_associated_topics",
    Facebook__EngagementsPage__dialects_and_sub_dialects:
      "dialects_subdialects",
    Facebook__EngagementsPage__account_author_activity: "engagers_activity",
    Facebook__EngagementsPage__gender_distribution: "gender_age_dist",
    Facebook__AccountPage__volume_overtime: "posts_volume",
    Facebook__AccountPage__reach_funnel: "posts_reach_impressions",
    Facebook__AccountPage__reactions: "posts_reactions",
    Facebook__AccountPage__content_type: "posts_content_type",
    Facebook__AccountPage__page_videos_views: "videos_views",
    Facebook__AccountPage__top_keywords: "posts_top_keywords",
    Facebook__AccountPage__top_hashtags: "posts_top_hashtags",
    Facebook__CustomerCarePage__customer_care_performance:
      "customer_performance",

    Instagram__EngagementsPage__gender_distribution: "gender_age_dist",
    Instagram__EngagementsPage__dialects_and_sub_dialects:
      "dialects_subdialects",
    Instagram__EngagementsPage__top_engagers: "top_authors",
    Instagram__EngagementsPage__volume_overtime:
      monitorType === "KEYWORD" ? "posts_volume_kw" : "comments_volume",
    Instagram__EngagementsPage__sentiment_analysis:
      monitorType === "KEYWORD"
        ? "posts_sentiment_analysis"
        : "comments_sentiment_analysis",
    Instagram__EngagementsPage__top_keywords:
      monitorType === "KEYWORD"
        ? "posts_top_keywords"
        : "comments_top_keywords",
    Instagram__EngagementsPage__top_hashtags:
      monitorType === "KEYWORD"
        ? "posts_top_hashtags"
        : "comments_top_hashtags",
    Instagram__EngagementsPage__associated_topics:
      monitorType === "KEYWORD"
        ? "posts_associated_topics"
        : "comments_associated_topics",
    Instagram__EngagementsPage__content_type: "posts_content_type",
    Instagram__AccountPage__volume_overtime: "posts_volume",
    Instagram__AccountPage__reach_funnel: "posts_only_reach",
    Instagram__AccountPage__content_type: "posts_content_type",
    Instagram__AccountPage__top_keywords: "posts_top_keywords",
    Instagram__CustomerCarePage__customer_care_performance:
      "customer_performance",

    Talkwalker__EngagementsPage__volume_overtime: "posts_volume_kw",
    Talkwalker__EngagementsPage__sentiment_analysis:
      "comments_sentiment_analysis_nb",
    Talkwalker__EngagementsPage__reach_funnel: "posts_reach",

    Twitter__EngagementsPage__content_style:
      monitorType === "KEYWORD"
        ? "comments_content_style_kw"
        : "comments_content_style",
    Twitter__EngagementsPage__associated_topics:
      monitorType === "KEYWORD"
        ? "posts_associated_topics"
        : "comments_associated_topics",
    Twitter__EngagementsPage__dialects_and_sub_dialects: "dialects_subdialects",
    Twitter__EngagementsPage__volume_overtime:
      monitorType === "KEYWORD" ? "posts_volume_kw" : "comments_volume",
    Twitter__EngagementsPage__comments_interactions:
      monitorType === "KEYWORD"
        ? "posts_interactions"
        : "comments_interactions",
    Twitter__EngagementsPage__sentiment_analysis:
      monitorType === "KEYWORD"
        ? "posts_sentiment_analysis"
        : "comments_sentiment_analysis",
    Twitter__EngagementsPage__top_keywords:
      monitorType === "KEYWORD"
        ? "posts_top_keywords"
        : "comments_top_keywords",
    Twitter__EngagementsPage__top_hashtags:
      monitorType === "KEYWORD"
        ? "posts_top_hashtags"
        : "comments_top_hashtags",
    Twitter__CustomerCarePage__customer_care_performance:
      "customer_performance",
    Twitter__AccountPage__volume_overtime: "posts_volume",
    Twitter__AccountPage__content_type: "posts_content_type",
    Facebook__CustomerCarePage__latest_unanswered_questions: "latest_questions",
    Twitter__CustomerCarePage__latest_unanswered_questions: "latest_questions",
    Instagram__CustomerCarePage__latest_unanswered_questions:
      "latest_questions",
    Twitter__QuestionsPage__latest_unanswered_questions: "questions",
    Facebook__QuestionsPage__latest_unanswered_questions: "questions",
    Instagram__QuestionsPage__latest_unanswered_questions: "questions",
    Facebook__CommentsMentionsPage__top_posts_comments: "comments_mentions",
    Facebook__EngagementsPage__top_posts_comments:
      monitorType === "KEYWORD" ? "top_posts" : "top_comments_mentions",
    Facebook__PostsPage__top_posts_comments: "posts",
    Instagram__EngagementsPage__top_posts_comments:
      monitorType === "KEYWORD" ? "top_posts" : "top_comments_mentions",
    Talkwalker__EngagementsPage__top_posts_comments: "top_articles",
    Talkwalker__PostsPage__top_posts_comments: "posts",
    Twitter__EngagementsPage__top_posts_comments:
      monitorType === "KEYWORD" ? "top_posts" : "top_comments_mentions",
    Twitter__PostsPage__top_posts_comments: "posts",
    Facebook__AccountPage__top_posts_comments: "posts",
    Instagram__AccountPage__top_posts_comments: "top_posts",
    Instagram__CommentsMentionsPage__top_posts_comments: "comments_mentions",
    Instagram__PostsPage__top_posts_comments: "posts",
    Twitter__AccountPage__top_posts_comments: "top_posts",
    Twitter__CommentsMentionsPage__top_posts_comments: "comments_mentions",
    Instagram__EngagementsPage__top_videos: "top_reels",
    Instagram__AccountPage__top_videos: "top_reels",
    Instagram__AccountPage__top_hashtags: "posts_top_hashtags",
  };
  return NameKeyObj[name];
};

export default NameKeyWidget;
