import moment from "moment";
import { isEmptyArray, convertToDhmsExcelSheet } from "utils/helpers";
import {
  handleXlsData,
  handleSortedCase,
  handleAuthorActivity,
} from "../excelCommonFn";

const twitter_private = (
  response,
  lang,
  themesTranslations,
  isSentimentAnalysisHourlyFormat,
) => {
  if (isEmptyArray(response)) {
    return [];
  } else {
    var overview = [],
      interactions = [],
      agentPerformanceExcel = [],
      sentimentOvertime = [],
      theme = [],
      subTheme = [],
      themesTrend = [],
      subThemesTrend = [],
      excelDialects = [],
      excelSubDialects = [],
      channelAccounts = [],
      excelContentType = [],
      authorActivity = [],
      topKeywords = [],
      excelGenderDistribution = [],
      mostEngagedContacts = [],
      mostInfluentialContacts = [],
      verifiedContacts = [],
      allSheetData = [];

    //_______________________________________________________________________________
    response?.map((i) => {
      Object.entries(i).forEach(([key, value]) => {
        switch (key) {
          case "overview":
            value?.map((i) => {
              overview.push({
                [lang.xlsx_name]: lang[i?.name] ? lang[i?.name] : i?.name,
                [lang.xlsx_value]:
                  i?.name == "net_sentiment"
                    ? lang[i?.value[0]?.name]
                    : i?.value,
              });
            });
            allSheetData.push([overview, lang.xlxs_overview]);
            break;

          case "interactions":
            value?.map((i) => {
              interactions.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.posts_word]: i?.value,
              });
            });
            allSheetData.push([interactions, lang.conversation_interactions]);
            break;
          case "agentPerformanceExcel":
            value?.map((i) => {
              let resalt = i?.value;
              if (
                i?.name == "average_waiting_time" ||
                i?.name == "average_handling_time"
              ) {
                resalt = convertToDhmsExcelSheet(i.value, lang);
              }
              agentPerformanceExcel.push({
                [lang.xlsx_name]: lang[i?.name] ? lang[i?.name] : i?.name,
                [lang.xlsx_value]: resalt,
              });
            });
            allSheetData.push([agentPerformanceExcel, lang.agent_performance]);
            break;
          case "sentimentOvertime":
            value?.negative?.map((i, index) => {
              if (i?.name == value?.neutral[index].name) {
                let total =
                  value?.neutral[index]?.value +
                  value?.positive[index]?.value +
                  value?.negative[index]?.value;
                sentimentOvertime.push({
                  [lang.date]: isSentimentAnalysisHourlyFormat
                    ? moment.unix(i?.name)?.utc()?.format("hh") +
                      lang?.[
                        moment.unix(i?.name)?.utc()?.format("A")
                      ]?.toLowerCase()
                    : moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                  [lang.neutral]:
                    total !== 0
                      ? ((value?.neutral[index]?.value / total) * 100).toFixed(
                          2,
                        ) + "%"
                      : 0,
                  [lang.positive]:
                    total !== 0
                      ? ((value?.positive[index]?.value / total) * 100).toFixed(
                          2,
                        ) + "%"
                      : 0,
                  [lang.negative]:
                    total !== 0
                      ? ((value?.negative[index]?.value / total) * 100).toFixed(
                          2,
                        ) + "%"
                      : 0,
                });
              }
            });
            allSheetData.push([sentimentOvertime, lang.sentiment_analysis]);
            break;

          case "themesXlsx":
            //themes
            let mainThemes = value?.theme_analysis?.main_themes_view;
            mainThemes &&
              Object.entries(mainThemes)?.forEach(([key1, value1]) => {
                let subThemes = value1?.sub_themes;
                subThemes &&
                  Object.entries(subThemes)?.forEach(([key2, value2]) => {
                    theme?.push({
                      [lang?.excel_categories]: `${lang?.[key1]} (${themesTranslations?.[key2]})`,
                      [lang?.total_interactions]: value2,
                    });
                  });
              });
            allSheetData?.push([theme, lang?.excel_categories]);

            //sub-themes
            let mainSubThemes = value?.theme_analysis?.sub_themes_view;
            mainSubThemes &&
              Object.entries(mainSubThemes)?.forEach(([key1, value1]) => {
                let mainThemes = value1?.main_themes;
                mainThemes &&
                  Object.entries(mainThemes)?.forEach(([key2, value2]) => {
                    subTheme?.push({
                      [lang?.excel_themes]: `${themesTranslations?.[key1]} (${lang?.[key2]})`,
                      [lang?.total_interactions]: value2,
                    });
                  });
              });
            allSheetData?.push([subTheme, lang?.excel_themes]);

            //themes-trend
            let mainthemesTrend = value?.theme_trend;
            mainthemesTrend &&
              Object.entries(mainthemesTrend)?.forEach(([key1, value1]) => {
                let mainThemes = value1?.main_themes_view;
                let rawObj = {};
                mainThemes &&
                  Object.entries(mainThemes)?.forEach(([key2, value2]) => {
                    let subThemes = value2?.sub_themes;
                    subThemes &&
                      Object.entries(subThemes)?.forEach(([key3, value3]) => {
                        rawObj = {
                          ...rawObj,
                          [`${lang?.[key2]} (${themesTranslations?.[key3]})`]:
                            value3,
                        };
                      });
                  });
                themesTrend?.push({
                  [lang?.date]: moment.unix(key1).utc().format("YYYY-MM-DD"),
                  ...rawObj,
                });
              });
            allSheetData?.push([themesTrend, lang?.excel_categories_trend]);

            //sub-themes-trend
            mainthemesTrend &&
              Object.entries(mainthemesTrend)?.forEach(([key1, value1]) => {
                let subThemesTrendObj = value1?.sub_themes_view;
                let rawObj = {};
                subThemesTrendObj &&
                  Object.entries(subThemesTrendObj)?.forEach(
                    ([key2, value2]) => {
                      let mainThemes = value2?.main_themes;
                      mainThemes &&
                        Object.entries(mainThemes)?.forEach(
                          ([key3, value3]) => {
                            rawObj = {
                              ...rawObj,
                              [`${themesTranslations?.[key2]} (${lang?.[key3]})`]:
                                value3,
                            };
                          },
                        );
                    },
                  );
                subThemesTrend?.push({
                  [lang?.date]: moment.unix(key1).utc().format("YYYY-MM-DD"),
                  ...rawObj,
                });
              });
            allSheetData?.push([subThemesTrend, lang?.excel_themes_trend]);
            break;
          case "excelDialects":
            handleSortedCase(
              value,
              excelDialects,
              lang.dialect,
              lang.main_dialect_filter,
              lang,
            );
            allSheetData.push([excelDialects, lang.main_dialect_filter]);
            break;
          // //_______________________________________________________________________________

          case "excelSubDialects":
            handleSortedCase(
              value,
              excelSubDialects,
              lang.sub_dialects,
              lang.number,
              lang,
            );
            allSheetData.push([excelSubDialects, lang.sub_dialects]);
            break;
          case "channelAccounts":
            value?.map((i) => {
              let name = i?.name?.replace("@", "");
              channelAccounts.push({
                [lang.xlsx_name]: "@" + name,
                [lang.xlsx_value]: i?.value,
              });
            });
            allSheetData.push([channelAccounts, lang.channel_accounts]);
            break;
          case "excelContentType":
            value?.map((i) => {
              excelContentType.push({
                [lang.xls_account_types]: lang[i?.name]
                  ? lang[i?.name]
                  : i?.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData.push([excelContentType, lang["content_type"]]);
            break;
          case "authorActivity":
            handleAuthorActivity(value[0], value[1], value[2], lang)?.map(
              (i) => {
                authorActivity?.push({
                  [lang.day]: i[0],
                  [lang.hour_group]: i[1],
                  [lang.no_of_activity]: i[2],
                });
              },
            );
            allSheetData.push([authorActivity, lang.contact_activity]);
            break;

          case "topKeywords":
            value?.map((i) => {
              topKeywords.push({
                [lang.keywords]: i?.id,
                [lang.xlsx_number_of_posts]: i?.stats_count,
              });
            });
            allSheetData.push([topKeywords, lang.top_keywords_xlsx_sheetname]);
            break;

          case "excelGenderDistribution":
            value?.map((i) => {
              excelGenderDistribution.push({
                [lang.gender]: lang[i?.name] ? lang[i?.name] : i?.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData.push([
              excelGenderDistribution,
              lang.genders_distribution,
            ]);
            break;

          case "mostEngagedContacts":
            value?.map((i) => {
              mostEngagedContacts.push({
                [lang.top_engagers]: i?.id,
                [lang.users]: i?.name,
                [lang.interactions]: i?.value,
              });
            });
            allSheetData.push([
              mostEngagedContacts,
              lang.most_engaged_contacts,
            ]);
            break;

          case "mostInfluentialContacts":
            value?.map((i) => {
              mostInfluentialContacts.push({
                [lang.top_engagers]: i?.id,
                [lang.users]: i?.name,
                [lang.personal_followers]: i?.value,
              });
            });
            allSheetData.push([
              mostInfluentialContacts,
              lang.most_influential_contacts,
            ]);
            break;

          case "verifiedContacts":
            value?.authors?.map((i) => {
              verifiedContacts.push({
                [lang.top_engagers]: i?.id,
                [lang.users]: i?.name,
                [lang.personal_followers]: i?.value,
              });
            });
            allSheetData.push([verifiedContacts, lang.verified_contacts]);
            break;
          default:
            break;
        }
      });
    });
    return handleXlsData(allSheetData);
  }
};
export { twitter_private };
