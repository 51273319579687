import { useMutation } from "@tanstack/react-query";
import { CompaniesServices } from "services/api/companiesServices";

export const useGetCompanyDataQuota = () => {
  return useMutation({
    mutationFn: ({ startDate, endDate }) => {
      return CompaniesServices.getCompanyDataInsightsQuota({
        start_date: startDate,
        end_date: endDate,
      });
    },
  });
};
