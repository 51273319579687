import moment from "moment";
import "moment-timezone";

// Declare All Dates States
const todayStates = ["today", "Today", "اليوم"];
const yesterdayStates = ["yesterday", "Yesterday", "أمس"];
const weekStates = ["last_7_days", "Last 7 Days", "آخر ٧ أيام"];
const fifteenDaysStates = ["last_15_days", "Last 15 Days", "آخر ١٥ يوم"];
const monthStates = ["last_30_days", "Last 30 Days", "آخر ٣٠ يوم"];
const allStates = ["all_time", "All Time", "كل الأوقات"];
const hourStates = ["last_hour", "Last Hour", "آخر ساعة"];
const customeDatesStates = ["Custom Dates", "custom_dates"];

const getStartEndDates = (
  daysToSubtract,
  isYesterday,
  allTimeStartDate,
  customDateValue,
  lastHourEnabled,
) => {
  //today
  var startDate = moment()?.tz("utc", true)?.startOf("day");
  var endDate = moment()?.tz("utc", true)?.endOf("day");

  //yesterday, week, 15days, month
  if (daysToSubtract > 0) {
    startDate = moment()
      .subtract(daysToSubtract, "d")
      ?.tz("utc", true)
      ?.startOf("day");
    if (isYesterday) {
      endDate = moment()
        .subtract(daysToSubtract, "d")
        ?.tz("utc", true)
        ?.endOf("day");
    }
  }
  //all time
  if (allTimeStartDate) {
    startDate = moment
      .unix(parseInt(allTimeStartDate))
      .tz("utc", true)
      .startOf("day");
    endDate = moment().tz("utc", true).endOf("day");
  }
  if (lastHourEnabled) {
    startDate = moment().subtract(1, "hours");
    endDate = moment();
  }
  //custom dates
  else if (customDateValue && customDateValue.length > 0) {
    startDate = moment(customDateValue[0])?.tz("utc", true);
    endDate = moment(customDateValue[1])?.tz("utc", true)?.endOf("day");
  }

  return {
    StartDate: startDate,
    EndDate: endDate,
  };
};

const handleSelectedDate = (
  pickerState,
  customDateValue,
  handleDateSubmit,
  allTimeStartDate,
  lastHourEnabled,
) => {
  var dates = {};

  if (todayStates.includes(pickerState)) {
    dates = getStartEndDates(0, false, null, [], "");
  } else if (yesterdayStates.includes(pickerState)) {
    dates = getStartEndDates(1, true, "", [], "");
  } else if (weekStates.includes(pickerState)) {
    dates = getStartEndDates(6, false, "", [], "");
  } else if (fifteenDaysStates.includes(pickerState)) {
    dates = getStartEndDates(14, false, "", [], "");
  } else if (monthStates.includes(pickerState)) {
    dates = getStartEndDates(29, false, "", [], "");
  } else if (allStates.includes(pickerState)) {
    dates = getStartEndDates(0, false, allTimeStartDate, [], "");
  } else if (hourStates.includes(pickerState)) {
    dates = getStartEndDates(0, false, "", [], lastHourEnabled);
  } else if (customeDatesStates.includes(pickerState)) {
    dates = getStartEndDates(0, false, "", customDateValue, "");
  } else {
    dates = getStartEndDates(0, false, "", customDateValue, "");
  }
  handleDateSubmit(
    moment(dates.StartDate).unix(),
    moment(dates.EndDate).unix(),
  );
  return;
};

export { handleSelectedDate };
