export const singleTweetCommentsInitialState = {
  topPosts: [],
  topPostsCount: null,
  topTopPostsPreLoaderDataSources: [],
};

export const commentsViewReducer = (state, action) => {
  switch (action.type) {
    case "SET_TOP_POSTS":
      return { ...state, topPosts: action.payload };
    case "SET_TOP_POSTS_COUNT":
      return { ...state, topPostsCount: action.payload };
    case "SET_TOP_POSTS_PRELOADER":
      return { ...state, topTopPostsPreLoaderDataSources: action.payload };
    case "RESET_COMMENTS_VIEW":
      return { ...singleTweetCommentsInitialState };
    default:
      return state;
  }
};
