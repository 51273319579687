import { useIntl } from "react-intl";
import { Box, Button, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faTimes } from "@fortawesome/pro-regular-svg-icons";
import LuciListQuestions from "./components/ListQuestions/luciListQuestion";
import HeaderTokens from "./components/headerTokens/headerTokens";
import Greetings from "./components/greetings/greetings";
import Requests from "./components/requests/requests";
import "./components/ListQuestions/listQuestions.scss";
import PopUpSharedContent from "./components/popupSharedContent/popupSharedContent";
import ConfirmationPopUp from "./components/confirmationTokens/confirmationToken";
import AppliedFilters from "./components/appliedFilters.js/appliedFiltersContent";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import LuciThinkingPage from "./components/luciThinkingPage/thinkingPage";
import LuciAnswer from "./components/answer/luciAnswer";
import { faHistory } from "@fortawesome/pro-regular-svg-icons";
import InActiveQuestions from "./components/ListQuestions/inActiveQuestions/inactiveQuestions";
import BusinessDetails from "./components/businessDetails/businessDetails";
import CircularLoading from "./../../components/circularProgress";
import FailedAnswer from "./components/ListQuestions/components/failedAnswer/failedAnswer";
import SuccessAnswer from "./components/ListQuestions/components/successAnswer/successAnswer";
import LuciApplyingOn from "./components/ApplyingOn/luciApplyingOn";
import { useState } from "react";
import LucButton from "shared/lucButton/lucButton";
import LuciSettings from "./components/settings";

const LuciSideBar = ({
  close,
  filterParams,
  startDate,
  endDate,
  showHeaderFilters,
  setShowHeaderFilters,
  showRequests,
  setShowRequests,
  isInactive,
  pageParams,
  luciStaticQuestions,
  questionsLoading,
  isUserThinking,
  activeQuestion,
  setActiveQuestion,
  getActiveQuestionName,
  createLuciQuestion,
  freeQuestionId,
  currentToken,
  setCurrentToken,
  energyTokens,
  setEenergyTokens,
  isCreateQuestionLoading,
  showLuciAnswers,
  setShowLuciAnswers,
  questionNameLocale,
  handleCreateFreeQuestions,
  notificationTitle,
  notificationBody,
  notificationStatus,
  notificationquestionId,
  setShowConfirmationPopup,
  showConfirmationPopup,
  showBusinessFormPopup,
  setShowBusinessFormPopup,
  formData,
  questionIdAnswer,
  isCAPage,
  setQuestionIdAnswer,
  handleRequestClick,
  setSnackbarShow,
  showSnackbarShow,
  getLuciStaticQuestions,
  currentTokensInactive,
  questionStatusNotification,
  questionNotificationId,
  isSurvey,
  individualSurvey,
  setIndividualSurvey,
  page,
  setPage,
  isBenchmark,
  settingsOpen,
  handleOpenSettings,
  handleCloseSettings,
}) => {
  const intl = useIntl();
  const [showSurvey, setShowSurvey] = useState({
    show: false,
    question: {},
  });

  const childProps = {
    setActiveQuestion,
    setShowConfirmationPopup,
    showConfirmationPopup,
    filterParams,
    startDate,
    endDate,
    close,
    setShowRequests,
    luciStaticQuestions,
    questionsLoading,
    getActiveQuestionName,
    createLuciQuestion,
    pageParams,
    freeQuestionId,
    currentToken,
    setCurrentToken,
    energyTokens,
    setEenergyTokens,
    isCreateQuestionLoading,
    showLuciAnswers,
    setShowLuciAnswers,
    questionNameLocale,
    handleCreateFreeQuestions,
    notificationStatus,
    showBusinessFormPopup,
    setShowBusinessFormPopup,
    questionIdAnswer,
    handleRequestClick,
    notificationquestionId,
    questionStatusNotification,
    questionNotificationId,
    isSurvey,
    individualSurvey,
    setIndividualSurvey,
    page,
    setPage,
  };

  const userRoles = JSON.parse(window?.localStorage?.getItem("user_roles"));

  const details = () => {
    return (
      <Box id="business-pop-up">
        <BusinessDetails
          formData={formData}
          setShowConfirmationPopup={setShowConfirmationPopup}
          setShowBusinessFormPopup={setShowBusinessFormPopup}
        />
      </Box>
    );
  };
  const handleCloseSideBar = () => {
    close();
    setShowConfirmationPopup(false);
    setShowHeaderFilters(false);
  };
  const getConfirmTokenBodyData = () => {
    return (
      <ConfirmationPopUp
        {...childProps}
        isSurvey={isSurvey}
        tokens={activeQuestion?.token}
        isCAPage={isCAPage}
        isBenchmark={isBenchmark}
      />
    );
  };
  const getFiltersHeaderData = () => {
    return (
      <Box className="confirmation-pop-up-container">
        <Box className="confirm-content-conainer">
          <Button
            id="btn-close-confirm-dialog"
            onClick={() => setShowHeaderFilters(false)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
          <Typography component={"p"} className="applied-filters-title">
            {CheckValueLocale("luci_applied_filters_pop", "", {}, intl)}
          </Typography>
          <AppliedFilters
            filterParams={filterParams}
            startDate={startDate}
            endDate={endDate}
          />
          <Box className="show-apply-filters-btn">
            <Button
              id="luci-confirm-request"
              onClick={() => setShowHeaderFilters(false)}
            >
              {CheckValueLocale("luci_done_show_filters", "", {}, intl)}
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };
  const getNoDataSurvey = () => {
    return (
      <Box className="confirmation-pop-up-container">
        <Box className="confirm-content-conainer">
          <Button
            id="btn-close-confirm-dialog"
            onClick={() =>
              setShowSurvey({
                ...showSurvey,
                isNoQusetioin: false,
                show: false,
              })
            }
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
          <Box className="no-data-survey-parant">
            <Typography component={"h1"}>
              {CheckValueLocale("luci_no_questions_found", "", {}, intl)}
            </Typography>
            <Typography>
              {CheckValueLocale(
                "luci_no_questions_try_another_page",
                "",
                {},
                intl,
              )}
            </Typography>
          </Box>
          <Box className="show-apply-filters-btn">
            <Button
              id="luci-confirm-request"
              onClick={() =>
                setShowSurvey({
                  ...showSurvey,
                  isNoQusetioin: false,
                  show: false,
                })
              }
            >
              {CheckValueLocale("luci_done_show_filters", "", {}, intl)}
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <>
      {settingsOpen ? (
        <LuciSettings
          handleCloseSettings={handleCloseSettings}
          getLuciStaticQuestions={getLuciStaticQuestions}
          pageParams={pageParams}
        />
      ) : showSurvey?.show ? (
        <>
          {showConfirmationPopup ? (
            <PopUpSharedContent
              bodyContent={getConfirmTokenBodyData()}
              handleClose={() => setShowConfirmationPopup(false)}
            />
          ) : showHeaderFilters ? (
            <PopUpSharedContent
              bodyContent={getFiltersHeaderData()}
              handleClose={() => setShowHeaderFilters(false)}
            />
          ) : null}
          {showLuciAnswers ? (
            <LuciAnswer
              showLuciAnswers
              questionIdAnswer={questionIdAnswer}
              filterParams={filterParams}
              startDate={startDate}
              endDate={endDate}
              handleCloseSideBar={handleCloseSideBar}
              setShowLuciAnswers={setShowLuciAnswers}
              setShowConfirmationPopup={setShowConfirmationPopup}
              setShowRequests={setShowRequests}
              isAnswerPage
              questionStatusNotification={questionStatusNotification}
              questionNotificationId={questionNotificationId}
              showSurveyquestion={showSurvey?.question}
              isBenchmark={isBenchmark}
            />
          ) : questionsLoading ? (
            <CircularLoading />
          ) : (
            <LuciApplyingOn
              isSurvey={isSurvey}
              individualSurvey={individualSurvey}
              setIndividualSurvey={setIndividualSurvey}
              page={page}
              setPage={setPage}
              handleCreateFreeQuestions={handleCreateFreeQuestions}
              showConfirmationPopup={showConfirmationPopup}
              showSurvey={showSurvey}
              setShowSurvey={setShowSurvey}
              handleCloseSideBar={handleCloseSideBar}
            />
          )}
        </>
      ) : (
        <Box className="luci-side-bar-wrapper">
          {showRequests ? (
            <Requests
              handleCloseSideBar={handleCloseSideBar}
              setShowRequests={setShowRequests}
              setShowConfirmationPopup={setShowConfirmationPopup}
              handleRequestClick={handleRequestClick}
              getLuciStaticQuestions={getLuciStaticQuestions}
              pageParams={pageParams}
              isInactive={isInactive}
            />
          ) : showLuciAnswers ? (
            <LuciAnswer
              showLuciAnswers
              questionIdAnswer={questionIdAnswer}
              filterParams={filterParams}
              startDate={startDate}
              endDate={endDate}
              handleCloseSideBar={handleCloseSideBar}
              setShowLuciAnswers={setShowLuciAnswers}
              setShowConfirmationPopup={setShowConfirmationPopup}
              setShowRequests={setShowRequests}
              isAnswerPage
              questionStatusNotification={questionStatusNotification}
              questionNotificationId={questionNotificationId}
              isBenchmark={isBenchmark}
            />
          ) : questionsLoading ? (
            <CircularLoading />
          ) : (
            <>
              <Box
                className={
                  !isInactive ? "side-bar-header" : "side-bar-header-inactive"
                }
              >
                <Button
                  className="btn-close-side-bar"
                  onClick={handleCloseSideBar}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </Button>
                <Box className="luci-btns-container">
                  <HeaderTokens
                    tokens={
                      isInactive
                        ? currentTokensInactive?.current_token
                        : currentToken
                    }
                  />
                  {userRoles?.GENERAL !== undefined &&
                    handlelUserRoles("GENERAL", "VIEW_SETTING") &&
                    handlelUserRoles("GENERAL", "EDIT_SETTING") && (
                      <LucButton
                        id="luci-settings-btn"
                        className="luci-settings-btn"
                        variant="flat"
                        type="secondary"
                        minWidth={35}
                        onClick={handleOpenSettings}
                      >
                        <FontAwesomeIcon icon={faCog} size="lg" />
                      </LucButton>
                    )}
                </Box>
              </Box>
              {isUserThinking && !isInactive ? (
                <LuciThinkingPage />
              ) : (
                <>
                  <Greetings
                    pageSection={pageParams?.sectionName}
                    channelAnayticsName={pageParams?.channelAnayticsName}
                    monitorName={pageParams?.monitorName}
                    productName={pageParams?.monitorProduct}
                    overAllChannels={pageParams?.overAllChannels}
                    setShowHeaderFilters={setShowHeaderFilters}
                    isInactive={isInactive}
                    isChannelAnalytics={pageParams?.isChannelAnalytics}
                    isBenchmark={isBenchmark}
                  />
                  <>
                    {notificationStatus !== undefined &&
                    showSnackbarShow &&
                    notificationStatus == "done" ? (
                      <SuccessAnswer
                        setSnackbarShow={setSnackbarShow}
                        setShowLuciAnswers={setShowLuciAnswers}
                      />
                    ) : null}

                    {notificationStatus !== undefined &&
                    showSnackbarShow &&
                    notificationStatus == "failed" ? (
                      <FailedAnswer setSnackbarShow={setSnackbarShow} />
                    ) : null}

                    {!isInactive ? (
                      <>
                        <LuciListQuestions
                          isInactive={isInactive}
                          isSurvey={isSurvey}
                          individualSurvey={individualSurvey}
                          setIndividualSurvey={setIndividualSurvey}
                          page={page}
                          setPage={setPage}
                          showConfirmationPopup={showConfirmationPopup}
                          showSurvey={showSurvey}
                          setShowSurvey={setShowSurvey}
                          {...childProps}
                        />
                        {showConfirmationPopup ? (
                          <PopUpSharedContent
                            bodyContent={getConfirmTokenBodyData()}
                            handleClose={() => setShowConfirmationPopup(false)}
                          />
                        ) : showHeaderFilters ? (
                          <PopUpSharedContent
                            bodyContent={getFiltersHeaderData()}
                            handleClose={() => setShowHeaderFilters(false)}
                          />
                        ) : showSurvey?.isNoQusetioin ? (
                          <PopUpSharedContent bodyContent={getNoDataSurvey()} />
                        ) : null}
                      </>
                    ) : (
                      <>
                        <InActiveQuestions />
                      </>
                    )}
                  </>
                </>
              )}
              {!questionsLoading || isInactive ? (
                <Button
                  className={`show-prev-requests-btn ${
                    isUserThinking && !isInactive
                      ? "show-requests-thinking"
                      : ""
                  }`}
                  onClick={() => {
                    setShowRequests(true);
                  }}
                >
                  <FontAwesomeIcon icon={faHistory} />
                  {CheckValueLocale("luci_show_prev_requests", "", {}, intl)}
                </Button>
              ) : null}
              {showBusinessFormPopup ? (
                <PopUpSharedContent
                  bodyContent={details()}
                  handleClose={() => setShowBusinessFormPopup(false)}
                />
              ) : null}
            </>
          )}
        </Box>
      )}
    </>
  );
};
export default LuciSideBar;
