import { Box, Typography } from "@mui/material";
import "./mainSetup.scss";
import { CheckValueLocale } from "../../../../../utils/helpers/index.js";
import { useIntl } from "react-intl";
import GreetingAndFarewell from "../edit/greetingAndFarewell";
import ReplaySection from "./replayCards/replaySection";

const StepThree = ({
  selectedData,
  setSelectedData,
  messagesData,
  setMessagesData,
  surveyData,
  setSurveyData,
}) => {
  const intl = useIntl();

  const activeProducts = JSON.parse(
    window?.localStorage?.activeProducts || null,
  );
  const aiAgentActive = activeProducts?.find(
    (product) => product?.name == "AI_AGENT",
  );

  return (
    <Box className="main-step-three">
      <Box className="step-three-header">
        <Box className="step-three-title">
          {CheckValueLocale("step3_title", "", {}, intl)}
        </Box>
        <Box className="step-three-pargragh">
          {CheckValueLocale("step3_paragragh", "", {}, intl)}
        </Box>
      </Box>
      <ReplaySection
        selectedData={selectedData}
        setSelectedData={setSelectedData}
      />
      <Box className="greeting-farewell-setup-container">
        <Typography className="greeting-farewell-setup-title">
          {CheckValueLocale("greeting_message_section_title", "", {}, intl)}
        </Typography>
        <GreetingAndFarewell
          messagesData={messagesData}
          setMessagesData={setMessagesData}
          surveyData={surveyData}
          setSurveyData={setSurveyData}
          className="greeting-farewell-setup-messages-container"
          aiAgentActive={aiAgentActive}
        />
      </Box>
    </Box>
  );
};

export default StepThree;
