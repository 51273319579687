import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Tooltip } from "@mui/material";
import { engagementTabsActions } from "pages/engagements/providers/engagementsTabsProvider";
import { classNames } from "utils/helpers";

import { ReactComponent as TabCurveIconLeft } from "images/engagements-images/tab-curve-left.svg";
import { ReactComponent as TabCurveIconRight } from "images/engagements-images/tab-curve-right.svg";
import { useEngagementTabsResizable } from "./useEngagementTabsResizable";
import { EngagmentMenuTabs } from "./engagementMenuTabs";
import {
  EngagementTabTooltitBody,
  getEngagementTabInfo,
  getSocialIconForEngagementTabs,
} from "./engagementTabsHelpers";
import { useRef, useState } from "react";

export const EngagementsTabs = () => {
  const {
    engagementTabsRef,
    engagementsInMenu,
    handleEngagementTabs,
    visibleEngagementTabs,
    containerRef,
    activeEngagement,
    engagementTabs,
  } = useEngagementTabsResizable();

  return (
    <Box className="engagement-tabs-root">
      <EngagementTabRow
        activeEngagement={activeEngagement}
        containerRef={containerRef}
        engagementTabs={engagementTabs}
        engagementTabsRef={engagementTabsRef}
        handleEngagementTabs={handleEngagementTabs}
        engagementsInMenu={[]}
        isHidden={true}
      />

      <EngagementTabRow
        activeEngagement={activeEngagement}
        engagementTabs={visibleEngagementTabs}
        handleEngagementTabs={handleEngagementTabs}
        engagementsInMenu={engagementsInMenu}
        isHidden={false}
      />
    </Box>
  );
};

const EngagementTabRow = ({
  activeEngagement,
  handleEngagementTabs,
  containerRef,
  engagementTabs,
  engagementTabsRef,
  engagementsInMenu,
  isHidden,
}) => {
  // ref for the last divider in the tabs list, used to manupulate when hovering or clicking on the menu icon.
  const refForLastDivider = useRef(null);
  // if the list is hidden we need to show the menu icon.
  // and if the list is visible we will only show the menu icon if there are engagements in the menu.
  const shouldHideMenuTabs = !engagementsInMenu?.length && !isHidden;

  return (
    <Box
      className={classNames("engagement-tabs", isHidden ? "hidden-tabs" : "")}
      ref={containerRef}
    >
      <TabsList
        engagements={engagementTabs}
        activeEngagement={activeEngagement}
        handleEngagementTabs={handleEngagementTabs}
        engagementsLength={engagementTabs?.length}
        tabsRef={engagementTabsRef}
        refForLastDivider={isHidden ? null : refForLastDivider}
      />
      {shouldHideMenuTabs ? null : (
        <EngagmentMenuTabs
          engagements={engagementsInMenu}
          isHidden={isHidden}
          handleEngagementTabs={handleEngagementTabs}
          visibleEngagementTabsCount={engagementTabs?.length}
          refForLastDivider={refForLastDivider}
        />
      )}
    </Box>
  );
};

const TabsList = ({
  engagements,
  activeEngagement,
  handleEngagementTabs,
  tabsRef,
  refForLastDivider,
}) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => setHoveredIndex(index);
  const handleMouseLeave = () => setHoveredIndex(null);

  return (
    <Box className="engagement-tabs-list" ref={tabsRef}>
      {engagements?.map?.((engagement, idx) => {
        // here we want when the current tab is active or hovered to hide the divider between the tabs.
        // so we have defined two classes for the divider, one for the previous divider and one for the next divider.
        // the previous divider will be hidden when the next tab is active or hovered.
        const currentActiveOrHovered =
          activeEngagement.engId === engagement?.engId || hoveredIndex === idx;
        const nextActiveOrHovered =
          hoveredIndex === idx + 1 ||
          engagements?.[idx + 1]?.engId === activeEngagement?.engId;

        return (
          <>
            <EngagementTab
              key={engagement?.engId}
              engagement={engagement}
              active={activeEngagement?.engId === engagement?.engId}
              handleEngagementTabs={handleEngagementTabs}
              onMouseEnter={() => handleMouseEnter(idx)}
              onMouseLeave={handleMouseLeave}
            />
            <Box
              className={classNames(
                "custom-tabs-divider",
                nextActiveOrHovered ? "prev-divider" : "",
                currentActiveOrHovered ? "next-divider" : "",
              )}
              ref={idx === engagements?.length - 1 ? refForLastDivider : null} // ref for the last divider in the tabs list that will be controlled by the menu icon component.
            >
              <Box className="engagement-tab-divider" />
            </Box>
          </>
        );
      })}
    </Box>
  );
};

const EngagementTab = ({
  engagement,
  active,
  handleEngagementTabs,
  onMouseEnter,
  onMouseLeave,
}) => {
  const { displayName } = getEngagementTabInfo(engagement);

  const handleTabClick = () => {
    handleEngagementTabs?.(engagementTabsActions.add_tab, { engagement });
  };

  const handleTabRemove = (e) => {
    e?.stopPropagation();
    handleEngagementTabs?.(engagementTabsActions.remove_tab, {
      id: engagement?.engId,
    });
  };

  return (
    <Tooltip
      title={<EngagementTabTooltitBody engagement={engagement} />}
      arrow
      placement="bottom"
      classes={{
        tooltip: "engagement-tabs-list-item-tooltip",
      }}
    >
      <Box
        className={classNames(
          "engagement-tabs-list-item",
          active ? "active-tab" : "",
        )}
        onClick={handleTabClick}
        id={`engagement-tab-${engagement?.engId}`}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <TabCurveIconLeft className="engagement-tabs-list-item-curve-left" />
        <TabCurveIconRight className="engagement-tabs-list-item-curve-right" />
        <Box
          className={classNames(
            "engagement-tabs-list-item-box",
            active ? "active-box" : "",
          )}
        >
          <Box className="engagement-tabs-list-item-box-datasource">
            {getSocialIconForEngagementTabs(engagement?.data_source)}
          </Box>
          <Box className="engagement-tabs-list-item-box-name">
            {displayName}
          </Box>
          <Box
            className="engagement-tabs-list-item-box-close"
            onClick={handleTabRemove}
            id={`engagement-tab-close-${engagement?.engId}`}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Box>
          <Box className="engagement-tabs-list-item-box-bottom" />
        </Box>
      </Box>
    </Tooltip>
  );
};
