import { workgingHoursData } from "pages/settings/pages/userManagementPage/subPages/shardComponents/tabs/workingHours";
export const userFormInitialValues = {
  first_name: "",
  last_name: "",
  mail: "",
  phone: "",
  job_title: "",
};

export const initialStepTwoData = {
  general_setting: "moderator",
  social_listening: "moderator",
  channel_analytics: "moderator",
  surveys: "moderator",
  audience: "moderator",
  engagement: "no_acc",
  ai_agent: "no_acc",
  lucidya_api: "moderator",
};

export const initialAccTableDetailsData = {
  account_access: [],
  account_access_page: 1,
  account_access_counter: 0,
  account_access_pages: 0,
  account_access_loading: false,

  monitors_access: [],
  monitors_access_page: 1,
  monitors_access_counter: 0,
  monitors_access_pages: 0,
  monitors_access_loading: false,

  channels_access: [],
  channels_access_page: 1,
  channels_access_counter: 0,
  channels_access_pages: 0,
  channels_access_loading: false,
};

export const initialAccTableData = {
  engagement_features: [],
  account_access: [],
  monitors_access: [],
  channels_access: [],
};

export const initialAccTableSelectedData = {
  engagement_features: {
    engag_acc_title: [],
    engag_tabs: [],
    saved_reps_mang: [],
    saved_replies_collections: [],
    export_engagements: [],
    engagement_tag: [],
  },
  account_access: {},
  monitors_access: {},
  channels_access: {},
};

export const initialTeamsData = {
  teamsOptions: [],
  teamsSelected: [],
};

export const initialAdvancedTabsData = {
  languages: [{ value: "", subValue: "" }],
  skills: [{ value: "", subValue: "" }],
  workingHours: workgingHoursData,
};

export const initialInfoTabsData = {
  sentiments: [],
  themes: [],
  segments: [],
  topics: [
    {
      id: "",
      name: "",
      topics: [],
    },
  ],
  dialects: [],
};

export const initialTabsErrors = {
  topicsPreError: false,
  topics: false,
  workgingHoursPreError: false,
  workingHours: false,
};

export const initialAccEngagId = {
  engag_acc_title: null,
  engag_tabs: null,
  saved_reps_mang: null,
  saved_replies_collections: null,
  export_engagements: null,
  engagement_tag: null,
};
