import { Box, Divider } from "@mui/material";
import CountryAndLanguageFooter from "./subComponents/footerArticleCard/countryAndLanguageFooter";
import {
  faComments,
  faEye,
  faBoltLightning,
} from "@fortawesome/pro-solid-svg-icons";
import { faGlobeAsia } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngry,
  faLaughBeam,
  faMeh,
} from "@fortawesome/pro-regular-svg-icons";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { formatDate } from "../../../../../utils/formatDateUtlit";

const iconWithValue = {
  comments_count: faComments,
  views_count: faEye,
  engagement_count: faBoltLightning,
  reach_count: faGlobeAsia,
};

const sentimentIcons = {
  positive: faLaughBeam,
  neutral: faMeh,
  negative: faAngry,
};

const FooterArticleCard = ({
  countryName,
  language,
  publishDate,
  statisticArticle,
  sentimentValue = "neutral",
}) => {
  const intl = useIntl();
  return (
    <Box className="footer-article-card">
      <CountryAndLanguageFooter
        label="country"
        value={CheckValueLocale(countryName?.toLowerCase(), "", {}, intl)}
      />
      <Divider orientation="vertical" className="footer-article-divider" />
      <CountryAndLanguageFooter
        label="language"
        value={CheckValueLocale(language?.toLowerCase(), "", {}, intl)}
      />
      <Divider orientation="vertical" className="footer-article-divider" />
      <CountryAndLanguageFooter
        label="publish_date"
        value={formatDate(publishDate, intl?.locale)}
      />

      {statisticArticle ? (
        <>
          <Divider orientation="vertical" className="footer-article-divider" />
          <Box className={`footer-item`}>
            {Object?.entries(statisticArticle)?.map(([key, value]) => (
              <Box component={"span"} className="statistic-article-footer-card">
                <FontAwesomeIcon icon={iconWithValue?.[key]} />
                <Box component={"span"}>
                  {parseInt(value || 0)?.toLocaleString()}
                </Box>
              </Box>
            ))}
          </Box>
        </>
      ) : null}

      {sentimentIcons?.[sentimentValue] ? (
        <>
          <Divider orientation="vertical" className="footer-article-divider" />
          <Box
            className={`footer-item sentiment-icons-article ${sentimentValue}`}
          >
            <FontAwesomeIcon icon={sentimentIcons?.[sentimentValue]} />
            <Box component={"span"}>
              {CheckValueLocale(sentimentValue, "", {}, intl)}
            </Box>
          </Box>
        </>
      ) : null}
    </Box>
  );
};

export default FooterArticleCard;
