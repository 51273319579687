import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ReportServices } from "services/api/reports";

export const useEditReport = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, queryData }) =>
      ReportServices.editReport({ id, queryData }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["reportsList"] });
      queryClient.invalidateQueries({ queryKey: ["reportRecords"] });
    },
  });
};
