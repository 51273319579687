import React, { useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { Box, Typography } from "@mui/material";

const NpsQuestion = (props) => {
  const { question, previewMode, handleProceedAnswer, rootQuestion } = props;

  const intl = useIntl();

  const [selectedAnswer, setSelectedAnswer] = useState(null);

  const answers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  let checkQuestion = question?.answer_list;

  const filteredAnswers = answers?.filter((answer) => answer === checkQuestion);

  const isColored = question?.constraints?.colored_theme;

  return (
    <Box className="survey-builder-question-nps-scale">
      <Box className="question-nps-scale-items-container">
        {question?.items?.map((item, index) => {
          const itemValue = item?.[index];
          const nextSection = item?.next_section;

          return (
            <Box
              key={index}
              component="span"
              className={`nps-scale-item ${itemValue == filteredAnswers ? "" : isColored ? (itemValue == 7 || itemValue == 8 ? "yellow" : itemValue == 9 || itemValue == 10 ? "green" : "red") : ""} ${selectedAnswer == itemValue ? "selected-nps-scale-item" : ""}`}
              onClick={() => {
                if (previewMode) {
                  setSelectedAnswer(itemValue);
                  if (rootQuestion?.is_proceed_on_answer)
                    handleProceedAnswer(nextSection);
                }
              }}
            >
              <Typography className="nps-scale-item-value">
                {itemValue}
              </Typography>
            </Box>
          );
        })}
      </Box>
      <Box className="question-nps-scale-items-text-container">
        <Typography component="body2" className="nps-scale-item-text">
          {CheckValueLocale("unlikely", "", {}, intl)}
        </Typography>
        <Typography component="body2" className="nps-scale-item-text">
          {CheckValueLocale("very_likely", "", {}, intl)}
        </Typography>
      </Box>
    </Box>
  );
};

export default NpsQuestion;
