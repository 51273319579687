import { useState } from "react";
import PopupModal from "components/popupModal";
import { CheckValueLocale } from "../../../../utils/helpers/index.js";
import { useIntl } from "react-intl";
import {
  Checkbox,
  Typography,
  Select,
  MenuItem,
  ListItemText,
  OutlinedInput,
  InputLabel,
  FormControl,
  Box,
} from "@mui/material";
import AudienceController from "services/controllers/audienceController";
import CommonFunctions from "pages/Monitors/Components/commonFunctions";

export const addSegmentBody = ({
  intl,
  isSegementProfile,
  isSelectAllProfiles,
  count,
  selectedUsersId,
  selectedSegment,
  handleFormMultipleSelection,
  segmentList,
  MenuProps,
  isSegementEngagements,
}) => (
  <Box
    className={`add-segment-pop ${isSegementEngagements ? "add-segment-engagements" : ""}`}
  >
    {!isSegementEngagements ? (
      <Typography className="body-input-label">
        {intl.formatMessage(
          {
            id: isSegementProfile
              ? "segments_select_segment_profile"
              : "select_the_segment",
          },
          {
            num: !isSegementProfile && (
              <Box component="span" className="segment-num-deleted-users">
                {isSelectAllProfiles ? count : selectedUsersId?.length}
              </Box>
            ),
          },
        )}
      </Typography>
    ) : null}
    <FormControl className="segment-formControl">
      <Select
        id="Segmant-select"
        fullWidth
        multiple
        name="segmentList"
        value={selectedSegment}
        onChange={handleFormMultipleSelection}
        renderValue={(selected) => {
          return selected?.length > 0
            ? selected.map((item) => item.name).join(", ")
            : CheckValueLocale("select_add_segment", "", {}, intl);
        }}
        className="add-segment-select"
        displayEmpty
        MenuProps={MenuProps}
      >
        {segmentList?.map((val) => (
          <MenuItem
            className="segment-menu-item"
            key={`Segmant-${val.id}`}
            id={val.id}
            value={val}
          >
            <Checkbox
              checked={
                selectedSegment?.findIndex((item) => item.id === val.id) >= 0
              }
            />
            <ListItemText
              className="selected-name-segment"
              primary={val.name}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Box>
);

const AddSegment = (props) => {
  const intl = useIntl();

  const {
    segmentList,
    open,
    close,
    selectedUsersId,
    setAddSegmentFlag,
    create,
    startDate,
    endDate,
    identifier,
    filters,
    isSelectAllProfiles,
    count,
    isSegementProfile,
  } = props;
  const [selectedSegment, setSelectedSegment] = useState([]);
  const [selectedSegmentIds, setSelectedSegmentIds] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const { changeFilterParamsToString } = CommonFunctions();
  const [buttonLoading, setButtonLoading] = useState(false);

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 220,
        width: 300,
      },
    },
  };

  const handleCreate = () => {
    addSegmantProfile();
  };
  const addSegmantProfile = () => {
    setButtonLoading(true);
    AudienceController.addSegmantProfile(
      selectedSegmentIds,
      selectedUsersId,
      isSelectAllProfiles,
      startDate,
      endDate,
      identifier,
      filters && changeFilterParamsToString(filters, true),
    ).then((result) => {
      if (result?.data?.status === 200) {
        create(
          "success",
          isSegementProfile
            ? "add_to_segmet_profile_msg_alert"
            : "add_to_segmet_msg_alert",
          "",
          true,
        );
      } else {
        create(
          "error",
          "try_again_error_message",
          "failed_error_message",
          "",
          false,
        );
      }
      close();
      setButtonLoading(false);
    });
  };
  const handleFormMultipleSelection = (event) => {
    let selectedValue = event.target.value;
    let selectedIds = [];
    let selectedSegment = [];
    setDisabled(true);

    selectedValue.forEach((item) => {
      selectedIds.push(item.id);
      selectedSegment.push(item);
      setDisabled(false);
    });
    setSelectedSegmentIds(selectedIds);
    setSelectedSegment(selectedSegment);
  };

  const body = addSegmentBody({
    intl,
    isSegementProfile,
    isSelectAllProfiles,
    count,
    selectedUsersId,
    selectedSegment,
    handleFormMultipleSelection,
    segmentList,
    MenuProps,
  });

  return (
    <PopupModal
      title={CheckValueLocale("add_to_segment", "", {}, intl)}
      body={body}
      leftBtn={CheckValueLocale("cancel", "", {}, intl)}
      rightBtn={CheckValueLocale("add_segment_btn", "", {}, intl)}
      open={open}
      close={close}
      accept={handleCreate}
      minWidth="600px"
      disabled={disabled || buttonLoading}
      leftBtnId="audience-segment-pop-up-cancel-left-btn"
      rightBtnId="audience-segment-pop-up-add-segment-right-btn"
      classeName="actions-buttons-shared"
      isRightBtnLoading={buttonLoading}
    />
  );
};

export default AddSegment;
