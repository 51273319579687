import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faChevronsLeft,
  faChevronsRight,
} from "@fortawesome/pro-regular-svg-icons";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
const DatePickerCustomHeader = ({
  date,
  decreaseMonth,
  increaseMonth,
  decreaseYear,
  increaseYear,
  intl,
  moment,
  setIsMonthPickerOpen,
  setIsYearPickerOpen,
}) => {
  return (
    <Box className="custom-header">
      <Stack direction="row" spacing={1}>
        <Tooltip
          title={CheckValueLocale("picker_previous_year_tooltip", "", {}, intl)}
          placement="bottom"
          arrow
        >
          <Box
            className="nav-button"
            id="date-picker-previous-year-button"
            onClick={decreaseYear}
          >
            <FontAwesomeIcon icon={faChevronsLeft} />
          </Box>
        </Tooltip>
        <Tooltip
          title={CheckValueLocale(
            "picker_previous_month_tooltip",
            "",
            {},
            intl,
          )}
          placement="bottom"
          arrow
        >
          <Box
            className="nav-button"
            id="date-picker-previous-month-button"
            onClick={decreaseMonth}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </Box>
        </Tooltip>
      </Stack>
      <Box className="header-labels">
        <Typography
          variant="span"
          className="header-label clickable"
          onClick={() => {
            setIsMonthPickerOpen(true);
            setIsYearPickerOpen(false);
          }}
        >
          {CheckValueLocale(
            `month_name_${moment(date)?.format("MMMM")?.toLowerCase()}`,
            "",
            {},
            intl,
          )}
        </Typography>
        <Typography
          variant="span"
          className="header-label year-label clickable"
          onClick={() => {
            setIsYearPickerOpen(true);
            setIsMonthPickerOpen(false);
          }}
        >
          {moment(date)?.format("YYYY")}
        </Typography>
      </Box>
      <Stack direction="row" spacing={1}>
        <Tooltip
          title={CheckValueLocale("picker_next_month_tooltip", "", {}, intl)}
          placement="bottom"
          arrow
        >
          <Box
            className="nav-button"
            id="date-picker-next-month-button"
            onClick={increaseMonth}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </Box>
        </Tooltip>
        <Tooltip
          title={CheckValueLocale("picker_next_year_tooltip", "", {}, intl)}
          placement="bottom"
          arrow
        >
          <Box
            className="nav-button"
            id="date-picker-next-year-button"
            onClick={increaseYear}
          >
            <FontAwesomeIcon icon={faChevronsRight} />
          </Box>
        </Tooltip>
      </Stack>
    </Box>
  );
};

export default DatePickerCustomHeader;
