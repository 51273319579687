import { useQuery } from "@tanstack/react-query";
import { ReportServices } from "services/api/reports";

export const useReportCategories = ({ queryData }) => {
  return useQuery({
    queryKey: ["reportCategories"],
    queryFn: () => ReportServices.getReportCategories(queryData),
    enabled: !!queryData?.product_id,
    select: (data) => {
      const groupedCategories = data?.data?.data?.reduce((acc, item) => {
        const { category, source } = item;
        if (!!category && !["WHATSAPP"]?.includes(source)) {
          acc[category] = acc?.[category] || [];
          acc?.[category]?.push(item);
        }
        return acc;
      }, {});

      const namesToIds = {};
      data?.data?.data?.map((item) => (namesToIds[item?.source] = item?.id));

      return { groupedCategories, namesToIds };
    },
  });
};
