import { Box, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { CheckValueLocale } from "utils/helpers";
import "./showRefreshAlert.scss";
import { useIntl } from "react-intl";
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";

const ShowRefreshAlert = ({ setShowAlert }) => {
  const intl = useIntl();
  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  return (
    <Box className="refresh-rate-alert">
      <FontAwesomeIcon icon={faExclamationTriangle} className="refresh-rate-alert-icon" />
      <Typography className="refresh-rate-alert-messege">
        {CheckValueLocale("refresh_rate_alerts", "", {}, intl)}
      </Typography>
      <FontAwesomeIcon onClick={handleCloseAlert} icon={faTimes} className="close-icon"/>
    </Box>
  );
};

export default ShowRefreshAlert;
