import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useIntl } from "react-intl";
import StackedBarWidget from "components/widgets/stackedBarWidget";
import "../../../monitors.scss";
import PieChartWidget from "components/widgets/pieChartWidget";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import { useParams, Link } from "react-router-dom";
import faInstagram from "images/social-icons/ig-profile-icon.svg";
import QuestionCard from "pages/Monitors/Components/questionCard/index";
import { CheckValueLocale } from "utils/helpers";

const AuthorAnalyticsSection = (props) => {
  const intl = useIntl();
  let {
    insightsID,
    insightsResponse,
    dates,
    response,
    dialects,
    setDialects,
    subDialects,
    setSubDialects,
    dialectsPreLoader,
    setDialectsPreLoader,
    topCities,
    setTopCities,
    topCitiesPreLoader,
    setTopCitiesPreLoader,
    topCountries,
    setTopCountries,
    topCountriesPreLoader,
    setTopCountriesPreLoader,
    commentsMentions,
    setCommentsMentions,
    commentsMentionsPreloader,
    setCommentsMentionsPreloader,
    genderAgeDistribution,
    setGenderAgeDistribution,
    genderAgeDistributionPreLoader,
    setGenderAgeDistributionPreLoader,
    topAuthors,
    setTopAuthors,
    topAuthorsPreLoader,
    setTopAuthorsPreLoader,
  } = props;
  const { monitorType, monitorDataSource, monitorId } = useParams();
  const monitorDetails = props.monitorData;

  const [genderPieChart, setGenderAnalysisPieChart] = useState([]);
  const [totalPage, setTotalPage] = useState(0);

  // ___________________________________________________________

  //handle response with useEffect (when load page & any update)
  const handleResponseFunc = async () => {
    if (
      response.eventName === "Instagram__EngagementsPage__gender_distribution"
    ) {
      if (response.eventData.gender_distribution) {
        response.eventData.gender_distribution.map((gd) => {
          Object.keys(gd).map((val, index) => {
            setGenderAgeDistribution((oldArray) => [
              ...oldArray,
              {
                name: val,
                value: Object.values(gd)[index],
              },
            ]);
          });
        });
      }
      setGenderAgeDistributionPreLoader((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
      setGenderAnalysisPieChart(response.eventData.gender_piechart);
    } else if (
      response.eventName ===
      "Instagram__EngagementsPage__dialects_and_sub_dialects"
    ) {
      setDialects(response.eventData.dialects);
      setSubDialects(response.eventData.sub_dialects);
      setDialectsPreLoader((oldArray) => [...oldArray, response.monitor_id]);
      // ___________________________________________________________
    } else if (
      response.eventName === "Instagram__EngagementsPage__top_cities"
    ) {
      setTopCities(response.eventData.top_cities);
      setTopCitiesPreLoader((oldArray) => [...oldArray, response.monitor_id]);
      // ___________________________________________________________
    } else if (
      response.eventName === "Instagram__EngagementsPage__top_countries"
    ) {
      setTopCountries(response.eventData.top_countries);
      setTopCountriesPreLoader((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
      // ___________________________________________________________
    } else if (
      response.eventName === "Instagram__EngagementsPage__top_engagers"
    ) {
      setTopAuthors(response.eventData.top_engagers);
      setTopAuthorsPreLoader((oldArray) => [...oldArray, response.monitor_id]);
      // ___________________________________________________________
    } else if (
      response.eventName === "Instagram__EngagementsPage__top_posts_comments"
    ) {
      setCommentsMentions(response.eventData.top_posts);
      setTotalPage(response.eventData.top_posts_count);
      setCommentsMentionsPreloader((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else {
      return null;
    }
  };
  useEffect(() => {
    handleResponseFunc();
  }, [response]);
  // ________________________________________________________________________________
  //getting Data to export xls sheet
  useEffect(() => {
    props.setPostsAnalyticsData([
      { genderAgeDistribution: genderAgeDistribution },
      { dialects: dialects },
      { subDialects: subDialects },
      { topAuthors: topAuthors },
      { topCities: topCities },
      { topCountries: topCountries },
      { commentsMentions: commentsMentions },
    ]);
  }, [
    genderAgeDistribution,
    dialects,
    subDialects,
    topAuthors,
    topCities,
    topCountries,
    commentsMentions,
  ]);

  // ________________________________________________________________________________
  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (props.resetAllFields !== 1) {
      setDialects([]);
      setSubDialects([]);
      setDialectsPreLoader([]);
      setTopCities([]);
      setTopCitiesPreLoader([]);
      setTopCountries([]);
      setTopCountriesPreLoader([]);
      setTopAuthors([]);
      setTopAuthorsPreLoader([]);
      setGenderAgeDistribution([]);
      setGenderAgeDistributionPreLoader([]);
      setGenderAnalysisPieChart([]);
      setCommentsMentions([]);
      setTotalPage(0);
      setCommentsMentionsPreloader([]);
    }
  }, [props.resetAllFields]);

  return (
    <>
      <Typography className="section-name">
        {CheckValueLocale("authors_analytics", "", {}, intl).toUpperCase()}
      </Typography>
      <Box my={3}>
        <StackedBarWidget
          title={"gender_age_dist"}
          titleToolTip={"gender_age_dist_ig_tooltip"}
          showDownloadIcon
          bigImg
          posts={genderAgeDistribution}
          colors={["#2AAAE2", "#FF4081"]}
          showZoomPercentage
          iconAfterTitle={props.instagramIcon}
          postsColumnPreLoaderDataSources={genderAgeDistributionPreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          showScales
          dataPiechart={genderPieChart}
          hideLegend
          labels={["male", "female"]}
          showReportIcon
          reachFunnel
          isGenderAgeSorted
        />
      </Box>
      <Box mt={3}>
        {/* _____________________________________________________ */}
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <PieChartWidget
              title={"dialects_subdialects"}
              titleToolTip={"dialects_subdialects_tooltip"}
              data={dialects}
              subData={subDialects}
              areaStyle={true}
              toolTipLabel={"toolTipLabel"}
              showAI
              showDownloadIcon
              pieChartPreLoaderDataSources={dialectsPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              twitterIcon={props.twitterIcon}
              iconAfterTitle={props.instagramIcon}
            />
          </Grid>
          <Grid item xs={6}>
            <TableWidget
              wordsList
              title={"top_authors"}
              titleToolTip={"top_authors_ig_tooltip"}
              data={topAuthors}
              leftColomnTitle={"username"}
              rightColomnTitle={"number_of_posts"}
              tableChartPreLoaderDataSources={topAuthorsPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              twitterIcon={props.twitterIcon}
              showDownloadIcon
              tablePagination
              showAtIcon
              count={topAuthors.length}
              iconAfterTitle={props.instagramIcon}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={3}>
        {/* _____________________________________________________ */}
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <PieChartWidget
              title={"top_cities"}
              titleToolTip={"top_cities_ig_tooltip"}
              data={topCities}
              areaStyle={true}
              toolTipLabel={"toolTipLabel"}
              showDownloadIcon
              pieChartPreLoaderDataSources={topCitiesPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              twitterIcon={props.twitterIcon}
              iconAfterTitle={props.instagramIcon}
              showReportIcon
            />
          </Grid>
          <Grid item xs={6}>
            <PieChartWidget
              title={"top_countries"}
              titleToolTip={"top_countries_ig_tooltip"}
              data={topCountries}
              areaStyle={true}
              toolTipLabel={"toolTipLabel"}
              showDownloadIcon
              pieChartPreLoaderDataSources={topCountriesPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              twitterIcon={props.twitterIcon}
              iconAfterTitle={props.instagramIcon}
              showReportIcon
            />
          </Grid>
        </Grid>
        {/* _____________________________________________________ */}

        <Box mt={3}>
          <Box className="top-posts-head">
            <Box>{CheckValueLocale("top_comments_mentions", "", {}, intl)}</Box>
            <Link
              to={{
                pathname: `/monitor/${monitorType}/${monitorDataSource}/comments_mentions/${monitorId}`,
                state: monitorDetails,
              }}
              className="top-posts-all"
              id={`check-mentions-comments-${
                monitorType?.toLowerCase() ?? "monitor-type"
              }-${
                monitorDataSource?.toLowerCase() ?? "monitor-data-source"
              }-comments-mentions-${monitorId ?? "monitor-id"}`}
            >
              {CheckValueLocale(
                "comments_mentions_all_comments_mentions",
                "",
                {},
                intl,
              )}
              ( {totalPage} )
            </Link>
          </Box>
          <QuestionCard
            unixDate={props.unixDate}
            showProfile={true}
            productId={window.localStorage?.getItem("sm_id")}
            latestUnanswer={commentsMentions}
            unAnsweredQuestion={totalPage}
            icon={faInstagram}
            iconColor={"instagram_icon"}
            showRetweet={true}
            showHeart={true}
            showDefaultImg={true}
            widget="comments_mentions"
            latestUnanswerPreloader={commentsMentionsPreloader}
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
            insightsID={insightsID}
            response={insightsResponse}
            dates={dates}
            noOfChar={15}
          />
        </Box>
      </Box>
    </>
  );
};

export default AuthorAnalyticsSection;
