import { Box } from "@mui/material";
import dummyVideo from "images/shared-images/video-placeholder.svg";
import { faPlay } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dummyImage from "images/shared-images/image-placeholder.svg";

const ImageMessage = ({ url, handleMediaClick }) => {
  return (
    <img
      width="228"
      height="165"
      className="engagement-chat-message-media-image"
      src={url}
      onClick={handleMediaClick}
      onError={({ currentTarget }) => {
        currentTarget.src = dummyImage;
      }}
    />
  );
};

const VideoMessage = ({ url, handleMediaClick }) => {
  return (
    <Box
      className="engagement-chat-message-media-video-container"
      onClick={handleMediaClick}
    >
      <video
        width="228"
        height="165"
        controls={false}
        className="engagement-chat-message-media-video"
      >
        <source src={url} type="video/mp4" />
        <source src={url} type="video/ogg" />
        <source src={url} type="video/avi" />
        <img src={dummyVideo} />
      </video>
      <Box className="play-button-container">
        <Box className="play-button">
          <FontAwesomeIcon icon={faPlay} />
        </Box>
      </Box>
    </Box>
  );
};

export const MediaMessage = ({ message, handleMediaClick, dataSource }) => {
  if (!message?.media?.media_url) {
    return null;
  }

  const { media, type } = message;

  const handleOnMedidaClick = () => {
    const updatedMedia = {
      ...media,
      media_url: media?.media_url,
    };
    handleMediaClick(updatedMedia);
  };

  const MediaComponent = type === "video" ? VideoMessage : ImageMessage;

  return (
    <MediaComponent
      url={media?.media_url}
      handleMediaClick={handleOnMedidaClick}
    />
  );
};
