import { Box, Button, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import "../../userProfile/userProfileHeader.scss";
import { CheckValueLocale } from "utils/helpers";

const SurveyHeader = (props) => {
  const intl = useIntl();
  const navigate = useNavigate();

  let { profile, detail } = props;

  return (
    <Box className="user-profile-header">
      <Box className="user-profile">
        <Box className="user-profile-info audience-profile">
          <Typography variant="h6">
            {detail?.survey_name
              ? detail?.survey_name
              : CheckValueLocale("no_name", "", {}, intl)}
          </Typography>
        </Box>
        <Button
          className="user-profile-info back-audience"
          onClick={() => {
            navigate(`/audience/user-profile/${profile.data.id}`);
          }}
          id="manage-audience-back-to-user-profile-btn"
        >
          {CheckValueLocale("back_to_userprofile", "", {}, intl)}
          <ArrowForwardIos className="profile-icon" />
        </Button>
      </Box>
    </Box>
  );
};

export default SurveyHeader;
