import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Box, Menu, MenuItem, Tooltip } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { NestedMenuItem } from "mui-nested-menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import LucButton from "shared/lucButton/lucButton";
import "./contentTab/contentTab.scss";

const ContentAddBtn = (props) => {
  const { handleAddQuestion } = props;

  const intl = useIntl();

  const [questionsAnchorEl, setQuestionsAnchorEl] = useState(null);
  const questionsOpen = Boolean(questionsAnchorEl);

  const dropdownOptions = {
    mainOptions: [
      "yes_no",
      "multiple_choice",
      "dropdown",
      "number",
      "short_text",
    ],
    contactInfoOptions: ["email", "phone_number", "name", "social_media"],
    NPSquestion: ["nps"],
    scaleQuestion: ["scale"],
  };

  const handleClick = (e) => setQuestionsAnchorEl(e.currentTarget);
  const handleClose = () => setQuestionsAnchorEl(null);

  const handleNewQuestion = async (e, type) => {
    e.stopPropagation();
    handleAddQuestion(type, 0);
    await setQuestionsAnchorEl(null);
  };

  return (
    <>
      <Tooltip
        placement="bottom"
        arrow
        title={CheckValueLocale("new_question", "", {}, intl)}
      >
        <Box>
          <LucButton
            className="add-question-btn"
            variant="flat"
            size="small"
            onClick={handleClick}
            minWidth={36}
          >
            <FontAwesomeIcon icon={faPlus} size="lg" />
          </LucButton>
        </Box>
      </Tooltip>
      <Menu
        id="survey-control-section-long-button"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={questionsAnchorEl}
        open={questionsOpen}
        onClose={handleClose}
      >
        <NestedMenuItem
          label={CheckValueLocale("contact_info", "", {}, intl)}
          parentMenuOpen={questionsOpen}
          style={{ padding: "7px 8px" }}
        >
          {dropdownOptions?.contactInfoOptions?.map((option, i) => (
            <MenuItem
              key={i}
              onClick={(e) => handleNewQuestion(e, option)}
              id={`survey-builder-contact-info-${option ?? "option"}`}
            >
              {CheckValueLocale(option, "", {}, intl)}
            </MenuItem>
          ))}
        </NestedMenuItem>
        {dropdownOptions?.mainOptions?.map((option, index) => (
          <MenuItem
            key={option}
            onClick={(e) => handleNewQuestion(e, option)}
            id={`survey-builder-options-${option ?? "option"}`}
          >
            {CheckValueLocale(option, "", {}, intl)}
          </MenuItem>
        ))}
        {dropdownOptions?.NPSquestion?.map((option, index) => (
          <MenuItem
            key={option}
            onClick={(e) => handleNewQuestion(e, option)}
            id={`survey-builder-nps-mark-${option ?? "option"}`}
          >
            {CheckValueLocale("nps_mark", "", {}, intl)}
          </MenuItem>
        ))}
        {dropdownOptions?.scaleQuestion?.map((option, index) => (
          <MenuItem
            key={option}
            onClick={(e) => handleNewQuestion(e, option)}
            id={`survey-builder-nps-mark-${option ?? "option"}`}
          >
            {CheckValueLocale("scale", "", {}, intl)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
export default ContentAddBtn;
