import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import {
  MenuItem,
  Checkbox,
  Box,
  Tooltip,
  Menu,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";
import CircularLoading from "components/circularProgress";

const ThemesMenu = (props) => {
  const {
    btnId,
    tooltipText,
    options,
    optionsLoading,
    selectedThemes,
    handleChange,
    loading,
    disabled,
    isOnlyView,
  } = props;

  const intl = useIntl();

  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpenMenu = (e) => {
    if (isOnlyView) return;
    setAnchorEl(e?.currentTarget);
  };
  const handleCloseMenu = () => setAnchorEl(null);
  const open = Boolean(anchorEl);

  const maxSelectedThemes = 3; // Maximum number of themes that can be selected by user

  const handleMenuItemClick = (theme) => {
    const updatedThemes = selectedThemes?.includes(theme)
      ? selectedThemes?.filter((t) => t !== theme)
      : [...selectedThemes, theme];

    if (!updatedThemes?.length) return;

    handleChange(updatedThemes);
  };

  const tooltipThemesNames = options
    ?.filter(
      (option) =>
        selectedThemes?.includes(option?.value) &&
        option?.value !== selectedThemes?.[0],
    )
    ?.map((option) => option?.sub_theme_name);

  const tooltipTitle = tooltipThemesNames?.join(", ");

  return (
    <Box>
      <Tooltip
        title={CheckValueLocale(
          tooltipText && !open ? tooltipText : "",
          "",
          {},
          intl,
        )}
        placement="top"
        arrow
      >
        <Box>
          <LucButton
            id={btnId}
            className={`themes-select-dropdown-btn ${
              isOnlyView ? "single-post-analysis" : ""
            }`}
            type="secondary"
            variant="flat"
            size="small"
            minWidth={60}
            aria-controls={open ? "fade-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            disabled={disabled}
            disableRipple={isOnlyView}
            onClick={handleOpenMenu}
            endIcon={
              !isOnlyView ? (
                <FontAwesomeIcon icon={faCaretDown} fixedWidth />
              ) : null
            }
          >
            <Box className="rendered-value-wrapper">
              {selectedThemes?.length > 0 ? (
                <Box
                  className="theme-color-box rendered-value"
                  style={{
                    backgroundColor: options?.filter(
                      (option) => option?.value == selectedThemes?.[0],
                    )?.[0]?.sub_theme_color,
                  }}
                />
              ) : null}
              <Typography variant="p" className="selected-title">
                {options?.find((option) => option?.value == selectedThemes?.[0])
                  ?.sub_theme_name ||
                  CheckValueLocale("select_theme", "", {}, intl)}
              </Typography>
              {selectedThemes?.length > 1 ? (
                <Tooltip arrow placement="top" title={isOnlyView ? tooltipTitle : null}>
                  <Box className="theme-count-box">
                    {selectedThemes?.length > 1
                      ? `+${selectedThemes?.length - 1}`
                      : null}
                  </Box>
                </Tooltip>
              ) : null}
            </Box>
          </LucButton>
        </Box>
      </Tooltip>
      <Menu
        id="themes-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: intl?.locale !== "ar" ? "left" : "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: intl?.locale !== "ar" ? "left" : "right",
        }}
      >
        {optionsLoading || loading ? (
          <Box className="circular-loading-wrapper">
            <CircularLoading />
          </Box>
        ) : (
          <>
            {options?.map((theme, index) => {
              // To disable not selected themes in case we reach the max selected themes defined above (maxSelectedThemes)
              const disabledItem =
                !selectedThemes?.includes(theme?.value) &&
                selectedThemes?.length >= maxSelectedThemes;

              return (
                <MenuItem
                  key={index}
                  id={`themes-menu-item-${theme}`}
                  className={`themes-menu-item`}
                  disabled={disabledItem}
                  onClick={() => handleMenuItemClick(theme?.value)}
                >
                  <Checkbox checked={selectedThemes?.includes(theme?.value)} />
                  <Box
                    className="theme-color-box"
                    style={{ backgroundColor: theme?.sub_theme_color }}
                  />
                  {theme?.sub_theme_name}
                </MenuItem>
              );
            })}
            {handlelUserRoles("GENERAL", "EDIT_SUBTHEME") ? (
              <Link
                id="customized-themes-link-btn"
                to={"/themes"}
                target="_blank"
              >
                {CheckValueLocale("create_new_theme", "", {}, intl)}
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
              </Link>
            ) : null}
          </>
        )}
      </Menu>
    </Box>
  );
};

export default ThemesMenu;
