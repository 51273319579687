import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { Box, Alert, AlertTitle } from "@mui/material";
import "./delete-accounts-list.scss";
import PopupModal from "components/popupModal";

const DeleteAccountsListModal = ({
  modalData,
  handleClose,
  handleDeleteAccountsList,
  buttonLoading,
}) => {
  const intl = useIntl();
  let title = CheckValueLocale("accounts_list_delete_text1", "", {}, intl);
  title = title?.replace("#", `${modalData?.name}`);
  let modalBody = (
    <Box className="shared-dialog-body">
      <Box component={"p"}>{title}</Box>
      <Box>
        <Alert severity="warning" className="accounts-list-delete-dialog-alert">
          <AlertTitle>
            {modalData?.isLinkedToSavedFilter
              ? CheckValueLocale(
                  "delete_accounts_list_linked_with_saved_filter_message",
                  "",
                  {},
                  intl,
                )
              : CheckValueLocale(
                  "delete_accounts_list_general_message",
                  "",
                  {},
                  intl,
                )}
          </AlertTitle>
        </Alert>
      </Box>
    </Box>
  );

  return (
    <PopupModal
      title={CheckValueLocale("delete_accounts_list", "", {}, intl)}
      body={modalBody}
      leftBtn={CheckValueLocale("cancel", "", {}, intl)}
      rightBtn={CheckValueLocale("delete_confirm", "", {}, intl)}
      open={modalData?.open || false}
      close={handleClose}
      accept={handleDeleteAccountsList}
      minWidth="600px"
      classeName="accounts-list-delete-dialog actions-buttons-shared"
      warning
      addClasses="accounts-list-delete-dialog-container"
      isRightBtnLoading={buttonLoading}
      disabled={buttonLoading}
    />
  );
};
export default DeleteAccountsListModal;
