import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import {
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Checkbox,
  ListItemIcon,
  Collapse,
  Box,
} from "@mui/material";
import { CheckValueLocale, isEmptyArray } from "utils/helpers";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import TwitterKeyword from "./dataSourcesComponents/twitterKeyword";
import InstagramKeyword from "./dataSourcesComponents/instagramKeyword";
import FaceBookKeyword from "./dataSourcesComponents/faceBookKeyword";
import NewsBlogKeyword from "./dataSourcesComponents/newBlogsKeyword/newsBlogKeyword";
import CommonFuntions from "../../createCommonFunctions/createMonitorCommonFunctions";
import { getCompanyApps } from "pages/channelAnalytics/manageChannels/manageChannels.hooks";
import SnackBar from "components/snackBar";

import "../../../../../../shared/createHeader/createHeader.scss";
import "./keywords.scss";
import NeedHelpChooseSourceHeader from "./components/keywordHeader/needHelpHeader";
import KeywordListItemComponent from "./components/keywordDataSourceListItem/keywordListItemComponent";
import {
  checkCompanyDatasources,
  checkKeywordsSource,
  checkKeywordsLimits,
} from "../../../MonitorsShared/SharedFunctions/components/kewordsSharedFunctions";

import Topic from "../../../MonitorsShared/SharedComponent/manualTopics/index";
import Services from "services/api/Services.js";
import { useGetNBMonitorData } from "pages/socialListing/MonitorsOptions/hooks/useGetNBMonitorData";

const KeywordsMonitorDataSources = (props) => {
  const {
    keywords,
    setSQuestion,
    setCreateMonitorBtn,
    companyAllowedSources,
    wordCountFacebook,
    wordCountInstagram,
    language,
    setLanguage,
    countries,
    setCountries,
    majorSource,
    setMajorSource,
    nbRanks,
    setNbRanks,
    nbDuplicated,
    setNbDuplicated,
    nbExcludedSource,
    setNbExcludedSource,
    titleSearchKeyword,
    setTitleSearchKeyword,
    newsBlogCount,
    checked,
    setChecked,
    arrowChecked,
    setArrowChecked,
    setSelectedSource,
    selectedSource,
    setAuthorizedAccounts,
    titleSearchKeywordError,
    nbExcludedSourceError,
    setWordCountInstagram,
    setnewsBlogCount,
    setWordCountFacebook,
    setWordCountNews,
    monitorTopics,
    setMonitorTopics,
    errorKeywordsMsg,
    setErrorKewyordsMsg,
    errorExKeywordsMsg,
    setExErrorKewyordsMsg,
    displayNameMsgError,
    setDisplayNameMsgError,
    monitorErrors,
    disabledManualTopic,
    manualTopicStats,
    twitterWordsCounter,
    setTwitterWordsCounter,
    selectedFacebookPages,
    monitorTwitterOtherFieldLimit,
    setTwitterKeywordCounter,
    monitorInstagramHashtagLimit,
    monitorFaceBookKeywordLimit,
    handleMonitorTopicsUsage,
  } = props;

  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const [companyApps, setCompanyApps] = useState([]);
  const { getAuthorizedAccount, authorizedAccounts } = CommonFuntions();

  const [selectedActiveDatasource, setSelectedActiveDatasource] = useState("");
  const [showFbAlert, setShowFbAlert] = useState(true);
  const [showIgAlert, setShowIgAlert] = useState(true);

  //___________________________get News&blogs sources__________
  const queryData = { data_source: "talkwalker" };
  const { data: nbMonitorData } = useGetNBMonitorData(queryData);
  const resp = nbMonitorData?.data;

  //keywords limit
  let twLimit =
    selectedSource === "twitter" &&
    twitterWordsCounter >= monitorTwitterOtherFieldLimit;
  let fbLimit =
    selectedSource === "facebook" &&
    wordCountFacebook >= monitorFaceBookKeywordLimit;
  let igLimit =
    selectedSource === "instagram" &&
    wordCountInstagram >= monitorInstagramHashtagLimit;
  let nbLimit =
    selectedActiveDatasource === "news_and_blogs" &&
    newsBlogCount > resp?.allowed_conditions &&
    resp?.allowed_conditions;

  //___________________________________________________________

  useEffect(() => {
    getAuthorizedAccount();
    getCompanyApps(setCompanyApps);
  }, []);
  setAuthorizedAccounts(authorizedAccounts);
  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = (value, type) => () => {
    let value1 = value;
    setSelectedSource(value1);
    setSelectedActiveDatasource(value);
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    const currentIndex1 = arrowChecked.indexOf(value);
    const newArrow = [...arrowChecked];
    if (value1 === "news_and_blogs") {
      value1 = "newsBlogs";
    }

    let data = [];
    keywords.map((keys, index) => {
      if (Object.keys(keys)[0] === value1) {
        Object.values(keys).map((val, index) => {
          Object.values(val).map((i) => {
            if (Object.values(i)[0].length > 0) {
              data.push(Object.values(i)[0]);
            } else {
              if (value1 === "newsBlogs") {
                language.length > 0 && data.push(language);
                majorSource.length > 0 && data.push(majorSource);
                countries.length > 0 && data.push(countries);
                nbExcludedSource.length > 0 && data.push(nbExcludedSource);
                selectedSource === "major_sources" && data.push(selectedSource);
              }
            }
          });
        });
      }
    });

    if (currentIndex === -1 && data.length === 0) {
      newChecked.push(value);
    }
    if (currentIndex === -1 && data.length > 0 && type === "arrow") {
      newChecked.push(value);
    }
    if (currentIndex !== -1) {
      newChecked.splice(currentIndex, 1);
    }

    if (currentIndex1 === -1 && type !== "checked") {
      let arr;
      keywords.map((keys, index) => {
        if (Object.keys(keys)[0] === value1) {
          Object.values(keys).map((val, index) => {
            Object.values(val).map((i) => {
              if (
                Object.values(i)[0].length > 0 ||
                Object.values(i)[0].length === 0
              ) {
                arr = value;
              }
            });
          });
        }
      });
      if (arr !== undefined) {
        newArrow.push(arr);
      }
    } else {
      keywords.map((keys, index1) => {
        if (Object.keys(keys)[0] === value1) {
          Object.values(keys).map((val) => {
            Object.values(val).map((i, index) => {
              if (Object.values(i)[0].length > 0 && type === "checked") {
                i[Object.keys(i)[0]] = [];
                i[Object.keys(i)[1]] = "";
                i[Object.keys(i)[3]] = "";
                if (value1 === "newsBlogs") {
                  setLanguage([]);
                  setCountries([]);
                  setMajorSource([]);
                  setNbRanks([]);
                  setNbExcludedSource([]);
                  setSelectedSource("");
                  setTitleSearchKeyword([]);
                }
              } else {
                if (type !== "arrow") {
                  i[Object.keys(i)[0]] = [];
                  i[Object.keys(i)[1]] = "";
                  i[Object.keys(i)[3]] = "";
                  if (value1 === "newsBlogs") {
                    setLanguage([]);
                    setCountries([]);
                    setMajorSource([]);
                    setNbRanks([]);
                    setNbExcludedSource([]);
                    setSelectedSource("");
                    setTitleSearchKeyword([]);
                  }
                }
              }
            });
          });

          if (currentIndex1 !== -1 && type == "checked") {
            newArrow.splice(currentIndex1, 1);
          }
        }
      });
    }

    setArrowChecked(newArrow);
    setChecked(newChecked);
    // Reset count in case of reset and deselect the option
    if (type === "checked" && currentIndex !== -1) {
      if (value === "twitter") {
        setTwitterWordsCounter(0);
        setTwitterKeywordCounter(0);
        props?.setTwitterCountries?.([]);
        props?.setTiwtterLanguages?.([]);
        props?.setIgnoreTweetsFlag?.(false);
        props?.setIsVarifiedAccounts?.(false);
      } else if (value === "instagram") {
        setWordCountInstagram(0);
      } else if (value === "news_and_blogs") {
        setnewsBlogCount(0);
        setWordCountNews(0);
      } else if (value === "facebook") {
        setWordCountFacebook(0);
      }
    }
  };

  //check error msg exist in all datasources
  let allErrorMsg = [],
    newsErrorMsg = false;
  keywords[0]?.twitter?.map((obj) => {
    allErrorMsg.push(obj.errorMsg);
  });
  keywords[1]?.instagram?.map((obj) => {
    allErrorMsg.push(obj.errorMsg);
  });
  keywords[2]?.facebook?.map((obj) => {
    allErrorMsg.push(obj.errorMsg);
  });
  keywords[3]?.newsBlogs?.map((obj) => {
    let newsKeywords = obj?.keywords,
      titleORKeywordsError = CheckValueLocale(
        "keywords_is_required_to_monitor_data",
        "",
        {},
        intl,
      );

    if (
      (obj.errorMsg === titleORKeywordsError &&
        titleSearchKeyword.length !== 0) ||
      (obj.errorMsg === titleORKeywordsError && newsKeywords.length !== 0)
    ) {
      newsErrorMsg = true;
      allErrorMsg.push("");
    } else {
      allErrorMsg.push(obj.errorMsg);
    }
  });

  let hasErrorMsg = allErrorMsg.every((el) => el == "");

  useEffect(() => {
    if (
      checked.length > 1 ||
      arrowChecked.length > 1 ||
      !Object.values(monitorErrors).every((str) => str === "")
    ) {
      // Ensure that some of the keyword boxes checked and we have at least one keyword added in Each data source checked
      if (
        (keywords[0]?.twitter[0]?.keywords.length === 0 &&
          checked.includes("twitter")) ||
        (keywords[1]?.instagram[0]?.hashtags.length === 0 &&
          checked.includes("instagram")) ||
        ((keywords[2]?.facebook[0]?.keywords.length === 0 ||
          (selectedFacebookPages?.length === 0 &&
            !Services.enableExtraSources)) &&
          checked.includes("facebook")) ||
        ((keywords[3]?.newsBlogs[0]?.keywords?.length === 0 ||
          (selectedSource === "major_sources" && majorSource?.length === 0)) &&
          checked.includes("news_and_blogs")) ||
        !Object.values(monitorErrors).every((str) => str === "") ||
        nbLimit
      ) {
        setCreateMonitorBtn(false);
      } else {
        setCreateMonitorBtn(true);
      }
    } else {
      setCreateMonitorBtn(false);
    }

    if (
      titleSearchKeywordError !== "" ||
      nbExcludedSourceError !== "" ||
      hasErrorMsg === false ||
      !Object.values(monitorErrors).every((str) => str === "")
    ) {
      setCreateMonitorBtn(false);
    }

    if (newsErrorMsg === true) {
      if (
        hasErrorMsg === false ||
        !Object.values(monitorErrors).every((str) => str === "")
      ) {
        setCreateMonitorBtn(false);
      } else {
        setCreateMonitorBtn(true);
      }
    }
  }, [
    checked,
    arrowChecked,
    hasErrorMsg,
    newsErrorMsg,
    nbExcludedSourceError,
    allErrorMsg,
  ]);

  useEffect(() => {
    if (
      newsBlogCount >= resp?.allowed_conditions ||
      wordCountInstagram >= monitorInstagramHashtagLimit ||
      twitterWordsCounter >= monitorTwitterOtherFieldLimit ||
      wordCountFacebook >= monitorFaceBookKeywordLimit
    ) {
      setOpen(true);
    }
  }, [
    checked,
    arrowChecked,
    newsBlogCount,
    wordCountInstagram,
    twitterWordsCounter,
    wordCountFacebook,
  ]);

  const { checkExceedLimited, messageLimits, titleLimits } =
    checkKeywordsLimits(twLimit, fbLimit, false, nbLimit);

  return (
    <>
      <Box className="monitor-type">
        <>
          {/* SnackBar that appear when get maximum of wordCount  */}
          {checkExceedLimited && (
            <>
              <SnackBar
                open={open}
                handleClose={handleClose}
                message={
                  <p className="error-snackbar-message">
                    {selectedSource === "twitter"
                      ? CheckValueLocale(
                          messageLimits,
                          "",
                          { limit: monitorTwitterOtherFieldLimit },
                          intl,
                        )
                      : CheckValueLocale(messageLimits, "", {}, intl)}
                  </p>
                }
                title={CheckValueLocale(titleLimits, "", {}, intl)}
                severity="error"
              />
            </>
          )}
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <NeedHelpChooseSourceHeader />
            <Grid item xs={12} className="grid-keywords">
              <List className="list container-check-list">
                {checkCompanyDatasources(companyAllowedSources).map(
                  (value, index) => {
                    const labelId = `checkbox-list-label-${CheckValueLocale(
                      value,
                      "",
                      {},
                      intl,
                    )}`;
                    return (
                      <>
                        <ListItem
                          key={value}
                          disablePadding
                          onClick={() =>
                            value === "news_and_blogs"
                              ? setSQuestion("newsBlogs")
                              : setSQuestion(value.toLocaleLowerCase())
                          }
                          className="list-item-keyword"
                          disableRipple
                        >
                          <ListItemButton
                            dense
                            className="list-item-keyword-type"
                          >
                            <ListItemIcon className="list-item-cliked">
                              <Checkbox
                                edge="start"
                                onClick={handleToggle(value, "checked")}
                                checked={
                                  checked.indexOf(value) !== -1 ||
                                  arrowChecked.indexOf(value) !== -1
                                }
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              id={labelId}
                              primary={CheckValueLocale(value, "", {}, intl)}
                              sx={{ textAlign: "initial" }}
                              style={{ marginLeft: "-15px" }}
                              className={`list-item-keyword-type ${
                                checked.indexOf(value) !== -1
                                  ? "source-style"
                                  : ""
                              }`}
                            />
                            {checked?.indexOf(value) !== -1 ? (
                              <KeywordListItemComponent
                                {...props}
                                resp={resp}
                                value={value}
                                instagramAccounts={
                                  authorizedAccounts?.instagramAccounts
                                }
                              />
                            ) : null}
                            <ListItemIcon
                              onClick={handleToggle(value, "arrow")}
                              className="arrow-icon-keyword list-item-cliked"
                            >
                              {checked.indexOf(value) !== -1 ? (
                                <ExpandMore />
                              ) : (
                                <ExpandLess />
                              )}
                            </ListItemIcon>
                          </ListItemButton>
                          {/* DATA SOURCES COMPONENTS */}
                          {CheckValueLocale(value, "", {}, intl) ===
                            CheckValueLocale("twitter", "", {}, intl) && (
                            <Collapse
                              in={checked.indexOf(value) !== -1}
                              timeout="auto"
                              unmountOnExit
                            >
                              <TwitterKeyword
                                indexes={
                                  value === "twitter" &&
                                  checkKeywordsSource(keywords).indexOf(value)
                                }
                                twitterAccounts={
                                  authorizedAccounts?.twitterAccounts
                                }
                                companyApps={companyApps}
                                {...props}
                              />
                            </Collapse>
                          )}

                          {/* INSTAGRAM */}
                          {CheckValueLocale(value, "", {}, intl) ===
                            CheckValueLocale("instagram", "", {}, intl) && (
                            <Collapse
                              in={checked.indexOf(value) !== -1}
                              timeout="auto"
                              unmountOnExit
                            >
                              <InstagramKeyword
                                indexes={
                                  value === "instagram" &&
                                  checkKeywordsSource(keywords).indexOf(value)
                                }
                                instagramAccounts={
                                  authorizedAccounts?.instagramAccounts
                                }
                                companyApps={companyApps}
                                {...props}
                                setShowIgAlert={setShowIgAlert}
                                showIgAlert={showIgAlert}
                              />
                            </Collapse>
                          )}
                          {/* FACEBOOK */}
                          {CheckValueLocale(value, "", {}, intl) ===
                            CheckValueLocale("facebook", "", {}, intl) && (
                            <Collapse
                              in={checked.indexOf(value) !== -1}
                              timeout="auto"
                              unmountOnExit
                            >
                              <FaceBookKeyword
                                indexes={
                                  value === "facebook" &&
                                  checkKeywordsSource(keywords).indexOf(value)
                                }
                                facebookAccounts={
                                  authorizedAccounts?.facebookAccounts
                                }
                                companyApps={companyApps}
                                {...props}
                                setShowFbAlert={setShowFbAlert}
                                showFbAlert={showFbAlert}
                              />
                            </Collapse>
                          )}
                          {/* NEWS & BLOGS */}

                          {CheckValueLocale(value, "", {}, intl) ===
                            CheckValueLocale(
                              "news_and_blogs",
                              "",
                              {},
                              intl,
                            ) && (
                            <Collapse
                              in={checked.indexOf(value) !== -1}
                              timeout="auto"
                              unmountOnExit
                            >
                              <NewsBlogKeyword
                                indexes={
                                  value === "news_and_blogs" &&
                                  checkKeywordsSource(keywords)?.indexOf(
                                    "newsBlogs",
                                  )
                                }
                                resp={resp}
                                {...props}
                              />
                            </Collapse>
                          )}
                        </ListItem>
                      </>
                    );
                  },
                )}
              </List>
            </Grid>
          </Grid>
        </>
      </Box>

      <Box>
        <Topic
          monitorTopics={monitorTopics}
          setMonitorTopics={setMonitorTopics}
          errorKeywordsMsg={errorKeywordsMsg}
          setErrorKewyordsMsg={setErrorKewyordsMsg}
          errorExKeywordsMsg={errorExKeywordsMsg}
          setExErrorKewyordsMsg={setExErrorKewyordsMsg}
          displayNameMsgError={displayNameMsgError}
          setDisplayNameMsgError={setDisplayNameMsgError}
          disabledManualTopic={disabledManualTopic}
          manualTopicStats={manualTopicStats}
          handleMonitorTopicsUsage={handleMonitorTopicsUsage}
        />
      </Box>
    </>
  );
};

export default KeywordsMonitorDataSources;
