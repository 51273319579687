import { addSegmentBody } from "pages/audience/audiencePage/segmentOption/addSegmentPopUp";
import { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import AudienceController from "services/controllers/audienceController";
import { EditEngagerProfileFooter } from "./editEngagerProfileFooter";
import { CheckValueLocale } from "utils/helpers";
import { EditContext } from "../context";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 220,
      width: 300,
    },
  },
};

export const EditEngagerProfileSegments = ({
  data,
  handleCancel,
  formMode,
}) => {
  const {
    setIsEditing,
    setCurrentEditingSection,
    callSingleinteraction,
    userId,
    setStats,
  } = useContext(EditContext);
  const intl = useIntl();
  const [segmentList, setSegmentList] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [selectedSegment, setSelectedSegment] = useState(data || []);

  const getSegmentList = () => {
    AudienceController?.getSegmentList().then((result) => {
      setSegmentList(result?.data?.data);
    });
  };

  useEffect(() => {
    if (formMode === "select") {
      getSegmentList();
    }
  }, []);

  const handleFormMultipleSelection = (event, child) => {
    const clickedId = child.props.id;

    const newSelectedSegment = selectedSegment.some(
      (item) => item.id === clickedId,
    )
      ? selectedSegment.filter((item) => item.id !== clickedId)
      : [...selectedSegment, segmentList.find((item) => item.id === clickedId)];

    setSelectedSegment(newSelectedSegment);
    setDisabled(false);
  };

  const handleSuccess = () => {
    callSingleinteraction();
    setStats((prev) => ({
      ...prev,
      open: true,
      severity: "success",
      title: "",
      body: CheckValueLocale("profile_updated_success", "", {}, intl),
    }));
    setTimeout(() => {
      setStats((prev) => ({ ...prev, open: false }));
    }, 7000);

    setIsEditing(false);
    setCurrentEditingSection(null);
  };

  const handleError = () => {
    setStats((prev) => ({
      ...prev,
      open: true,
      severity: "error",
      title: CheckValueLocale("failed_to_delete_survey", "", {}, intl),
      body: CheckValueLocale("try_again", "", {}, intl),
    }));
    setTimeout(() => {
      setStats((prev) => ({ ...prev, open: false }));
    }, 7000);
  };

  const handleUpdateSegment = () => {
    const deletedSegment = data.filter(
      (item) => !selectedSegment.some((selected) => selected.id === item.id),
    );
    const addedSegment = selectedSegment.filter(
      (item) => !data.some((selected) => selected.id === item.id),
    );
    if (addedSegment.length > 0) {
      AudienceController.addSegmantProfile(
        addedSegment.map((item) => item.id),
        [userId],
        false,
        null,
        null,
        "",
        null,
      ).then((result) => {
        if (result?.data?.status === 200) {
          handleSuccess();
        } else {
          handleError();
        }
      });
    }
    if (deletedSegment.length > 0) {
      AudienceController.excludeSegmantProfile(
        deletedSegment.map((item) => item.id),
        [userId],
        false,
        null,
        null,
        "",
        null,
      ).then((data) => {
        if (data?.data?.status === 204 || data?.data?.status === 200) {
          handleSuccess();
        } else {
          handleError();
        }
      });
    }
  };

  const body = addSegmentBody({
    intl,
    isSegementProfile: false,
    isSelectAllProfiles: false,
    count: 0,
    selectedUsersId: userId,
    selectedSegment,
    handleFormMultipleSelection,
    segmentList,
    MenuProps,
    isSegementEngagements: true,
  });
  return (
    <div>
      {body}
      <EditEngagerProfileFooter
        handleCancel={handleCancel}
        submitButtonDisabled={disabled}
        onSave={handleUpdateSegment}
      />
    </div>
  );
};
