import * as Yup from "yup";
import { useState } from "react";
import { useFormik } from "formik";
import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale, validateUrlInjection } from "utils/helpers";
import { isPublicSpecialCharactersOrEmojis } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/specialCharOrEmojis";
import { useAddCustomDomain } from "pages/settings/pages/companyPage/hooks/useAddCustomDomain";
import { useVerifyCustomDomain } from "pages/settings/pages/companyPage/hooks/useVerifyCustomDomain";
import CustomDomainInfo from "./components/customDomainInfo";
import CustomDomainStepOne from "./components/customDomainStepOne";
import CustomDomainStepTwo from "./components/customDomainStepTwo";
import PopupModal from "components/popupModal";
import LucButton from "shared/lucButton/lucButton";
import "./style.scss";

const CustomDomain = (props) => {
  const { customDomainDetails, setSnackbar } = props;

  const intl = useIntl();

  const [openPopup, setOpenPopup] = useState(false);
  const [step, setStep] = useState(1);

  const { mutate: addCustomDomain, isPending: addCustomDomainLoading } =
    useAddCustomDomain();

  const handleAddCustomDomain = () => {
    addCustomDomain(
      { custom_domain: `survey.${formik?.values?.custom_domain}` },
      {
        onSuccess: () => setStep(2),
        onError: () =>
          setSnackbar({
            open: true,
            severity: "error",
            title: "something_went_wrong",
            message: "try_again",
          }),
      },
    );
  };

  const { mutate: verifyCustomDomain, isPending: verifyLoading } =
    useVerifyCustomDomain();

  const handleVerifyCustomDomain = () => {
    verifyCustomDomain(
      {},
      {
        onError: () =>
          setSnackbar({
            open: true,
            severity: "error",
            title: "something_went_wrong",
            message: "try_again",
          }),
        onSettled: () => closeModal(),
      },
    );
  };

  const handleView = () => {
    setStep(2);
    setOpenPopup(true);
  };

  const handleVerify = () => {
    if (customDomainDetails?.frontend_status == "pending")
      handleVerifyCustomDomain();
    else closeModal();
  };

  const formik = useFormik({
    initialValues: { custom_domain: "" },
    validationSchema: Yup.object({
      custom_domain: Yup.string()
        .required("")
        .test(
          "validate",
          "custom_domain_validat",
          (value) =>
            !isPublicSpecialCharactersOrEmojis(value) &&
            !value?.startsWith("www.") &&
            !value?.startsWith("http://") &&
            !value?.startsWith("https://") &&
            !value?.includes("/") &&
            !validateUrlInjection(value)?.error,
        ),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: () => handleAddCustomDomain(),
  });

  const closeModal = () => {
    setOpenPopup(null);
    formik?.resetForm();
  };

  const stepTwoData = [
    {
      label: "hostname_txt_name",
      value: customDomainDetails?.txt_record_name || "",
    },
    {
      label: "hostname_txt_value",
      value: customDomainDetails?.txt_record_value || "",
    },
    { label: "cname_key", value: customDomainDetails?.custom_domain || "" },
    { label: "cname_value", value: customDomainDetails?.target_value || "" },
  ];

  return (
    <Box>
      <Typography variant="h6" className="settings-section-title">
        {CheckValueLocale("company_custom_domain_title", "", {}, intl)}
      </Typography>
      <Box id="custom-domain-container">
        {/* No custom domain added yet */}
        {!customDomainDetails?.custom_domain ? (
          <Box className="add-custom-domain-wrapper">
            <Typography className="add-custom-domain-desc">
              {CheckValueLocale("personalize_survey_link", "", {}, intl)}
            </Typography>
            <LucButton
              id="add-custom-domain-btn"
              variant="outline"
              loading={addCustomDomainLoading}
              onClick={() => {
                setOpenPopup(true);
                setStep(1);
              }}
            >
              {CheckValueLocale("add_domain", "", {}, intl)}
            </LucButton>
          </Box>
        ) : (
          // Custom domain added and showing action buttons
          <Box>
            <Typography className="connected-domain-description">
              {CheckValueLocale(
                customDomainDetails?.frontend_status === "pending"
                  ? "your_custom_domain_pending"
                  : customDomainDetails?.frontend_status === "failed"
                    ? "your_custom_domain_failed"
                    : "your_custom_domain_connected",
                "",
                {},
                intl,
              )}
            </Typography>
            <CustomDomainInfo
              customDomainDetails={customDomainDetails}
              handleView={handleView}
              setSnackbar={setSnackbar}
            />
          </Box>
        )}
      </Box>

      {openPopup ? (
        <PopupModal
          title={CheckValueLocale(
            step === 1 ? "add_domain" : "domain_details",
            "",
            {},
            intl,
          )}
          body={
            <Box id="custom-domain-add-verify-wrapper">
              {step === 2 ? (
                <Typography className="custom-domain-verify-desc">
                  {CheckValueLocale("access_your_website_host", "", {}, intl)}
                </Typography>
              ) : null}

              {step === 1 ? (
                <CustomDomainStepOne formik={formik} />
              ) : (
                <Box>
                  {stepTwoData?.map((field, index) => (
                    <CustomDomainStepTwo
                      key={index}
                      label={field?.label}
                      value={field?.value}
                      index={index}
                    />
                  ))}
                </Box>
              )}
            </Box>
          }
          leftBtn={step === 1 ? CheckValueLocale("cancel", "", {}, intl) : null}
          rightBtn={CheckValueLocale(
            step === 1
              ? "add"
              : customDomainDetails?.frontend_status == "pending"
                ? "custom_domain_verify"
                : "close",
            "",
            {},
            intl,
          )}
          disabled={
            step === 1 && (!formik?.dirty || formik?.errors?.custom_domain)
          }
          isRightBtnLoading={verifyLoading || addCustomDomainLoading}
          open={openPopup}
          close={closeModal}
          accept={() => (step === 1 ? formik?.handleSubmit() : handleVerify())}
          addClasses="actions-buttons-shared"
        />
      ) : null}
    </Box>
  );
};

export default CustomDomain;
