import { useQuery } from "@tanstack/react-query";
import { ReportServices } from "services/api/reports";

export const useReportProducts = () => {
  return useQuery({
    queryKey: ["reportProducts"],
    queryFn: () => ReportServices.getReportProducts(),
    select: (data) => {
      const filteredData = data?.data?.data
        ?.filter((item) => ["SM", "CXM"]?.includes(item?.attributes?.name))
        ?.map((item) => item?.attributes);

      const idsToNames = filteredData?.reduce((acc, item) => {
        acc[item?.id] = item?.name;
        return acc;
      }, {});

      const namesToIds = filteredData?.reduce((acc, item) => {
        acc[item?.name] = item?.id;
        return acc;
      }, {});

      return { filteredData, idsToNames, namesToIds };
    },
  });
};
