import { useQuery } from "@tanstack/react-query";
import ThemesController from "services/controllers/themesController";

export const useGetThemes = () => {
  return useQuery({
    queryKey: ["themes"],
    queryFn: () => ThemesController.getThemesList(),
    select: (data) => data?.data,
    retry: false,
  });
};
