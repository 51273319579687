import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Box, Button, IconButton, Menu, Typography } from "@mui/material";
import { SketchPicker } from "react-color";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/pro-regular-svg-icons";
import LucButton from "shared/lucButton/lucButton";
import { CheckValueLocale } from "utils/helpers";
import CustomizedTooltip from "components/customizedTooltip";
import SharedMenuTags from "./sharedMenuTags";
import { faPlusCircle } from "@fortawesome/pro-solid-svg-icons";

// Allows users to select a color for tags, either from a predefined list or a custom color picker.
const TagColorPicker = (props) => {
  const intl = useIntl();
  const {
    applyOpacity,
    tagName,
    selectedColor,
    setSelectedColor,
    colorsTags,
    editTagData,
    handleOpenColorPicker,
    colorPickerAnchor,
    setColorPickerAnchor,
  } = props;
  const [customColor, setCustomColor] = useState(null);

  const handleCloseMenu = (event) => {
    setColorPickerAnchor(null);
  };

  // Sets a random default color if no color is selected.
  useEffect(() => {
    if (!selectedColor && colorsTags?.length > 0) {
      const randomColor =
        colorsTags[Math?.floor(Math?.random() * colorsTags?.length)]?.attributes
          ?.value;
      if (randomColor) {
        setSelectedColor(randomColor);
      }
    }
  }, [colorsTags, selectedColor]);

  return (
    <Box className="tag-color-picker-container">
      {/*  Displays the currently selected color and tag name */}
      <Box
        className="tag-selected-color-box"
        style={{ backgroundColor: applyOpacity(selectedColor, 0.1) }}
      >
        <Box
          className="tag-color-preview"
          style={{ backgroundColor: selectedColor }}
        />
        <Typography
          style={
            tagName || editTagData
              ? null
              : { color: applyOpacity(selectedColor, 0.4) }
          }
        >
          {tagName
            ? tagName
            : editTagData
              ? editTagData?.label
              : CheckValueLocale("name", "", {}, intl)}
        </Typography>
      </Box>
      {/* Displays available color options */}
      <Box className="tag-color-options">
        {colorsTags?.map((color) => (
          <Box
            className="tag-color-border"
            sx={{
              "&:hover": {
                backgroundColor: applyOpacity(color?.attributes?.value, 0.2),
              },
            }}
            style={{
              borderColor: "transparent",
              ...(selectedColor === color?.attributes?.value && {
                backgroundColor: applyOpacity(color?.attributes?.value, 0.1),
                borderColor: color?.attributes?.value,
              }),
            }}
            onClick={() => setSelectedColor(color?.attributes?.value)}
          >
            <Box
              key={color?.id}
              className={`tag-color-circle ${selectedColor === color?.attributes?.value ? "tag-selected" : ""}`}
              style={{
                backgroundColor: color?.attributes?.value,
              }}
            />
          </Box>
        ))}
        <CustomizedTooltip
          id="custom_color"
          title={CheckValueLocale("custom_color", "", {}, intl)}
          placement={intl?.locale == "en" ? "right" : "left"}
          arrow
        >
          <Box
            className={`add-tag-color-box ${colorPickerAnchor !== null ? "add-tag-color-box-selected" : ""}`}
          >
            <LucButton
              variant="Flat"
              id="add-tag-color-btn"
              className="add-tag-color-btn"
              onClick={handleOpenColorPicker}
            >
              <FontAwesomeIcon
                icon={colorPickerAnchor !== null ? faPlusCircle : faCirclePlus}
              />
            </LucButton>
          </Box>
        </CustomizedTooltip>
      </Box>
      <SharedMenuTags
        id={"tag-name-color-picker"}
        anchorEl={colorPickerAnchor}
        setAnchorEl={setColorPickerAnchor}
        close={handleCloseMenu}
        anchorOrigin={{
          vertical: "center",
          horizontal: intl?.locale == "en" ? "left" : "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: intl?.locale == "en" ? "right" : "left",
        }}
      >
        <SketchPicker
          color={customColor || selectedColor}
          disableAlpha={true}
          onChange={(color) => setCustomColor(color.hex)}
          onChangeComplete={(color) => {
            setSelectedColor(color.hex);
          }}
        />
      </SharedMenuTags>
    </Box>
  );
};

export default TagColorPicker;
