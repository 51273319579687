import { api } from "./newServices";

const getCompanyDataInsightsQuota = (queryData) => {
  return api.get("api/v3/historical_requests/company_quota", {
    params: queryData,
  });
};

const getCompanySingleTweetsQuota = ({ tweetId, queryData }) => {
  return api.get(
    `api/v3/social_media/twitter/single_tweets/${tweetId}/get_quota_consumption`,
    {
      params: queryData,
    },
  );
};

export const CompaniesServices = {
  getCompanyDataInsightsQuota,
  getCompanySingleTweetsQuota,
};
