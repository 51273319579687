import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useNavigate } from "react-router-dom";
import CompaniesController from "services/controllers/companiesController";
import SnackBar from "components/snackBar.js";
import { useState } from "react";
import LucButton from "shared/lucButton/lucButton";
import { generateUserPayload } from "../sharedFunction/inviteUserAdapter";

const StepsFooter = ({
  activeStep,
  setActiveStep,
  isStepOneValid,
  steps,
  callApiDone,
  nextLoading,
  getTablesData,
  stepOneData,
  stepTwoData,
  accTableSelectedData,
  accEngagId,
  setNextLoading,
  advancedTabsData,
  infoTabsData,
  tabsErrors,
  teamsData,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [openSnackBar, setOpenSnackBar] = useState({
    open: false,
    tit: null,
    msg: null,
  });

  const handleSteps = () => {
    if (activeStep == 2 || (activeStep == 1 && steps?.length == 2)) {
      setNextLoading(true);
      let payLoadObj = generateUserPayload({
        stepTwoData,
        stepOneData,
        isEngagement: activeStep == 2,
        accTableSelectedData,
        infoTabsData,
        advancedTabsData,
        teamsData,
        accEngagId,
      });
      CompaniesController.createCompanyUsers(payLoadObj).then((result) => {
        if (result?.data) {
          if (result?.data?.id) {
            navigate(
              `/settings/user_managements/view/${result?.data?.id}?status=added_successfully`,
            );
          }
        } else {
          if (
            result?.errorMsg?.response?.data?.exception?.message?.includes(
              "ALREADY_EXIST",
            )
          ) {
            setOpenSnackBar({
              open: true,
              tit: "email_already_token",
              msg: null,
            });
          } else {
            setOpenSnackBar({
              open: true,
              tit: "wrong_request_parameter",
              msg: "report_fail_try_later",
            });
          }
        }
        setNextLoading(false);
      });
    } else if (activeStep == 1 && steps?.length == 3 && !callApiDone) {
      getTablesData();
    } else {
      setActiveStep(activeStep + 1);
    }
  };
  return (
    <>
      <Box className="main-steps-footer">
        {activeStep ? (
          <LucButton
            variant="outline"
            onClick={() => setActiveStep(activeStep - 1)}
            disabled={nextLoading}
          >
            {CheckValueLocale("add_team_back", "", {}, intl)}
          </LucButton>
        ) : (
          <Box></Box>
        )}
        <Box>
          <LucButton
            type="secondary"
            variant="flat"
            className="steps-cancel-btn"
            disabled={nextLoading}
            onClick={() => navigate("/settings/user_managements")}
          >
            {CheckValueLocale("cancel_btn", "", {}, intl)}
          </LucButton>
          <LucButton
            variant="filled"
            disabled={
              nextLoading ||
              !isStepOneValid ||
              (activeStep === 2 &&
                (tabsErrors?.topicsPreError ||
                  tabsErrors?.workgingHoursPreError))
            }
            onClick={!nextLoading && handleSteps}
            loading={nextLoading}
          >
            {CheckValueLocale(
              activeStep == 2 || (activeStep == 1 && steps?.length == 2)
                ? "invite"
                : "next_Step",
              "",
              {},
              intl,
            )}
          </LucButton>
        </Box>
      </Box>
      <SnackBar
        open={openSnackBar?.open}
        severity={"error"}
        message={CheckValueLocale(openSnackBar?.msg, "", {}, intl)}
        title={CheckValueLocale(openSnackBar?.tit, "", {}, intl)}
        handleClose={() => setOpenSnackBar({ open: false })}
      />
    </>
  );
};
export default StepsFooter;
