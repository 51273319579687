import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { Box, Chip, Tooltip, Typography } from "@mui/material";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import LucButton from "shared/lucButton/lucButton";
import EmbeddedPublishBtn from "./embeddedPublish/embeddedPublishBtn";
import "../surveyBuilder.scss";

const Header = (props) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const {
    surveyData,
    saveChangesFlag,
    handleSaveChanges,
    handlePublishBtn,
    setPreview,
    surveyLimitReach,
    publishFlag,
    saveLoading,
    isEmbedded,
    currentDomainsPage,
    setCurrentDomainsPage,
    companyDomainsList,
    setCompanyDomainsList,
    companyDomainsData,
    setCompanyDomainsData,
    openEmbeddedPublishModal,
    setEmbeddedPublishModal,
    embeddedPublishStep,
    setEmbeddedPublishStep,
    handlePublishEmbedded,
    embeddedPublishLoading,
    selectedDomains,
    setSelectedDomains,
  } = props;

  const [isHovered, setIsHovered] = useState(false);

  const disabledPublish =
    !publishFlag ||
    (surveyLimitReach && surveyData?.status !== "draft") ||
    saveLoading;

  return (
    <Box className="header">
      <Box component="span" className="survey-builder-back-wrapper">
        <LucButton
          id="survey-builder-header-back-btn"
          className="builder-header-back-btn"
          type="secondary"
          variant="flat"
          onClick={() => navigate(`/survey/list`)}
          minWidth={36}
          size="small"
        >
          <FontAwesomeIcon
            icon={intl?.locale === "ar" ? faArrowRight : faArrowLeft}
          />
        </LucButton>
        <Typography variant="h6" className="survey-builder-header-title">
          {surveyData?.name || ""}
          {/* Untill BE Supports in Editing Name */}
          {/* <FontAwesomeIcon icon={faPenLine} /> */}
        </Typography>
        {!!surveyData?.status ? (
          <Chip
            className={`survey-status-chip ${surveyData?.status || ""}`}
            label={CheckValueLocale(
              surveyData?.status + "_status_survey",
              "",
              {},
              intl,
            )}
          />
        ) : null}
      </Box>
      <Box className="header-btns">
        <LucButton
          id="survey-builder-preview-btn"
          data-testid="save"
          type="secondary"
          variant="flat"
          size="small"
          minWidth={36}
          onClick={() => setPreview(true)}
        >
          <RemoveRedEyeIcon />
        </LucButton>
        <LucButton
          id="survey-builder-save-btn"
          data-testid="save"
          variant="outline"
          size="small"
          disabled={saveChangesFlag ? false : true}
          loading={saveLoading}
          onClick={handleSaveChanges}
        >
          {CheckValueLocale("save", "", {}, intl)}
        </LucButton>
        {handlelUserRoles("GENERAL", "CREATE_UTILITIES") && (
          <Box
            component={"span"}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <Tooltip
              title={CheckValueLocale("max_survey_count", "", {}, intl)}
              placement="bottom"
              arrow
              id="survey-max-btn-tooltip"
              open={
                surveyLimitReach && isHovered && surveyData?.status !== "draft"
              }
            >
              <Box data-testid="publish">
                {isEmbedded ? (
                  <EmbeddedPublishBtn
                    surveyData={surveyData}
                    currentDomainsPage={currentDomainsPage}
                    setCurrentDomainsPage={setCurrentDomainsPage}
                    companyDomainsList={companyDomainsList}
                    setCompanyDomainsList={setCompanyDomainsList}
                    companyDomainsData={companyDomainsData}
                    setCompanyDomainsData={setCompanyDomainsData}
                    openEmbeddedPublishModal={openEmbeddedPublishModal}
                    setEmbeddedPublishModal={setEmbeddedPublishModal}
                    embeddedPublishStep={embeddedPublishStep}
                    setEmbeddedPublishStep={setEmbeddedPublishStep}
                    disabledPublish={disabledPublish}
                    embeddedPublishLoading={embeddedPublishLoading}
                    handlePublishEmbedded={handlePublishEmbedded}
                    saveLoading={saveLoading}
                    selectedDomains={selectedDomains}
                    setSelectedDomains={setSelectedDomains}
                  />
                ) : (
                  <LucButton
                    id="survey-builder-publish-btn"
                    size="small"
                    onClick={handlePublishBtn}
                    disabled={disabledPublish}
                  >
                    {CheckValueLocale("publish", "", {}, intl)}
                  </LucButton>
                )}
              </Box>
            </Tooltip>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Header;
