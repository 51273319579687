import { Box, Tooltip, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

export const CustomSourcesInputTitle = ({ showCustomSourcesInputTitle }) => {
  const intl = useIntl();
  return (
    <Typography
      variant="caption"
      className={`keyword-head-title ${showCustomSourcesInputTitle ? "exclude-sources-title" : ""}`}
    >
      {
        <Tooltip
          title={
            <Box className="title-search-tooltip">
              {CheckValueLocale("exclude_sources_tooltip", "", {}, intl)}
            </Box>
          }
          arrow
          placement="bottom"
        >
          <Typography variant="caption" className="media-input-label">
            {CheckValueLocale("exclude_sources", "", {}, intl)}
          </Typography>
        </Tooltip>
      }
    </Typography>
  );
};
