import "./twoFAMethodNameWithIcon.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Chip } from "@mui/material";
import {
  faEnvelope,
  faMessageSms,
  faLaptopMobile,
} from "@fortawesome/pro-regular-svg-icons";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";

const iconMethods = {
  email: faEnvelope,
  sms: faMessageSms,
  totp: faLaptopMobile,
};

const TwoFAMethodNameWithIcon = ({ method, disabled, chipLabel, page }) => {
  const intl = useIntl();
  return (
    <Box
      id="two-fa-method-name-with-icon"
      className={disabled ? "disabled-two-fa-method" : ""}
    >
      <Box>
        <FontAwesomeIcon
          icon={iconMethods?.[method]}
          className="two-fa-method-icon"
        />
      </Box>
      <Box>
        <Box className="title-two-fa-method">
          {CheckValueLocale(`title_two_fa_method_${method}`, "", {}, intl)}
          {chipLabel ? (
            <Chip
              label={CheckValueLocale(chipLabel, "", {}, intl)}
              size="small"
              className={`feature-status-chip ${chipLabel}`}
            />
          ) : null}
        </Box>
        <Box className="description-two-fa-method">
          {CheckValueLocale(
            `description_two_fa_method_${method}${page ? `_${page}` : ""}`,
            "",
            {},
            intl,
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default TwoFAMethodNameWithIcon;
