import "components/media/Media.scss";
import { Box } from "@mui/material";
import FetchingData from "components/fetchingData/fetchingData";
import NoDataFound from "components/no-Data/noDataFound";
import _ from "lodash";
import { isEmptyArray } from "utils/helpers";
import MediaInstaItem from "components/media/media-insta/mediaInstaItem.jsx";
import MonitorMediaInstaItem from "components/media/media-insta/monitorMediainstaItem/monitorMediaInstaItem.jsx";

const MediaInsta = (props) => {
  const {
    hideMoreInsights,
    questions,
    insightsID,
    response,
    datasourcePostsType,
    dates,
    data,
    monitorInstagramMedia,
    mediaInstaPreLoaderDataSources,
    preLoaderTrackerDataSources,
  } = props;
  return (
    <>
      {_.isEqual(
        mediaInstaPreLoaderDataSources?.sort(),
        preLoaderTrackerDataSources?.sort(),
      ) !== true ||
      !mediaInstaPreLoaderDataSources?.length ||
      !preLoaderTrackerDataSources?.length ? (
        <Box>
          <FetchingData />
        </Box>
      ) : isEmptyArray(data) || data == null ? (
        <NoDataFound />
      ) : monitorInstagramMedia ? (
        <div className="topmedia-monitor-wrapper">
          {data?.map((media, index) => (
            <MonitorMediaInstaItem
              key={index}
              media={media}
              question={hideMoreInsights ? {} : questions[index]}
              hideMoreInsights={hideMoreInsights}
              insightsID={insightsID}
              response={response}
              dates={dates}
              datasourcePostsType={datasourcePostsType}
            />
          ))}
        </div>
      ) : (
        <div className="topmedia-wrapper">
          {data?.map((media, index) => (
            <MediaInstaItem key={index} media={media} />
          ))}
        </div>
      )}
    </>
  );
};

export default MediaInsta;
