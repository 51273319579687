import { faPenToSquare, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import LucButton from "shared/lucButton/lucButton";
import { CheckValueLocale } from "utils/helpers";

const PublicReplyBox = ({
  message,
  handleEditClick,
  disableDelete,
  handleDelete,
}) => {
  const intl = useIntl();
  return (
    <Box className="public-reply-box-container">
      <Box className="public-reply-box-content">
        <Typography component="pre" className="greeting-farewell-message-text">
          {message}
        </Typography>
      </Box>
      <Box className="public-reply-box-footer">
        <LucButton
          startIcon={<FontAwesomeIcon icon={faPenToSquare} />}
          variant="text"
          onClick={handleEditClick}
          id="edit-reply-button"
          type="secondary"
        >
          {CheckValueLocale("edit", "", {}, intl)}
        </LucButton>
        <LucButton
          startIcon={<FontAwesomeIcon icon={faTrash} />}
          variant="text"
          onClick={handleDelete}
          disabled={disableDelete}
          id="delete-reply-button"
          type="secondary"
        >
          {CheckValueLocale("delete", "", {}, intl)}
        </LucButton>
      </Box>
    </Box>
  );
};

export default PublicReplyBox;
