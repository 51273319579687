import moment from "moment";
import { CheckValueLocale } from "utils/helpers";

/**
 * displayRemainingTime - takes the remainingInSeconds and returns the remaining in the appropriate format.
 * @param {string} remainingInSeconds - The remaining time in seconds.
 * @returns {string} - The remaining time in the appropriate format.
 * @example
 * if the difference is in seconds it will return the seconds: 10s
 * if the difference is in minutes it will return the minutes and will neglect the seconds if there are some remaining: 10m
 * if the difference is in hours it will return the hours and the minutes and will neglect the seconds if there are some remaining: 10h, 30m
 * if the difference is in days it will return the days and will neglect the hours, minutes and seconds if there are some remaining: 10d
 */
export const displayRemainingTime = (remainingInSeconds) => {
  const duration = moment?.duration(remainingInSeconds, "seconds");
  const years = duration?.years();
  const months = duration?.months();
  const days = duration?.days();
  const hours = duration?.hours();
  const minutes = duration?.minutes();
  const seconds = duration?.seconds();
  const times = [];
  if (days || months || years) {
    times.push({
      type: "d",
      value: days + months * 30 + years * 365,
    });
  }
  if (hours) {
    times.push({
      type: "h",
      value: hours,
    });
  }
  if (minutes) {
    times.push({
      type: "m",
      value: minutes,
    });
  }

  if (seconds || !times.length) {
    times.push({
      type: "s",
      value: seconds,
    });
  }

  return times;
};

/**
 * displayRangeDate - takes the startDate and endDate in unix format and returns the range of the date. e.g. 01/01/2021 - 01/02/2021
 * @param {*} startDate
 * @param {*} endDate
 */
export const displayRangeDate = (startDate, endDate) => {
  return `${moment.unix(startDate).format("DD/MM/YYYY")} - ${moment
    .unix(endDate)
    .format("DD/MM/YYYY")}`;
};

export const renderRemainingTime = (times, intl) => {
  const comma = intl?.locale === "ar" ? "، " : ", ";
  return times
    ?.map((time) => {
      return CheckValueLocale(
        "engag_time_" + time?.type,
        "",
        {
          time: time?.value,
        },
        intl,
      );
    })
    ?.join(comma);
};
