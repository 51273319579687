import { Box } from "@mui/material";
import { isValidPhoneNumber } from "libphonenumber-js";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import { useEffect, useState } from "react";
import "./phoneNumberInput.scss";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { useFormik } from "formik";
import * as Yup from "yup";

const PhoneNumberInput = ({
  formik = useFormik({
    initialValues: {
      phone_number: "",
    },
    validationSchema: Yup.object().shape({
      phone_number: Yup?.string()
        ?.required("required_phone_number")
        ?.test("isValidPhone", "invalid_phone_number", (value) => {
          if (!value) return true;
          return isValidPhoneNumber(value);
        }),
    }),
    initialErrors: { phone_number: "required_phone_number" },
  }),
  setDisabledBtn,
  setPhoneNumber,
}) => {
  const intl = useIntl();
  const [phoneValue, setPhoneValue] = useState("");

  const changePhoneNumber = (e) => {
    try {
      const regex = /^[0-9\s]*$/; // can change with only number or spacing
      if (regex?.test(e?.[1])) {
        setPhoneValue(e?.[1]);
        formik?.setFieldValue(
          "phone_number",
          e?.[1] ? e?.[3]?.replace(/[^+\d]+/g, "") : "",
        );
      }
    } catch {}
  };

  const changeFlagHandler = (e) => {
    try {
      setPhoneValue(e?.[0]);
      formik?.setFieldValue(
        "phone_number",
        e?.[0] ? e?.[2]?.replace(/[^+\d]+/g, "") : "",
      );
    } catch {}
  };

  useEffect(() => {
    if (formik?.errors?.phone_number) {
      setDisabledBtn && setDisabledBtn(true);
    } else {
      setDisabledBtn && setDisabledBtn(false);
      setPhoneNumber && setPhoneNumber(formik?.values?.phone_number);
    }
  }, [formik?.errors?.phone_number, formik?.values?.phone_number]);

  return (
    <Box onBlur={formik?.handleBlur} id="phone-number-input-two-factor-auth">
      <IntlTelInput
        containerClassName="intl-tel-input"
        inputClassName={`react-tel-input ${!!formik?.errors?.phone_number && !!formik?.touched?.phone_number ? "user-phone-error" : ""}`}
        separateDialCode
        defaultCountry={"sa"}
        excludeCountries={["il"]}
        autoHideDialCode
        nationalMode
        fieldName="phone_number"
        value={phoneValue || ""}
        onPhoneNumberChange={(...args) => {
          changePhoneNumber(args);
        }}
        onSelectFlag={(...args) => {
          changeFlagHandler(args);
        }}
        enableSearch
      />

      {formik?.errors?.phone_number && formik?.touched?.phone_number ? (
        <Box className="user-phone-error-msg">
          {CheckValueLocale(formik?.errors?.phone_number, "", {}, intl)}
        </Box>
      ) : null}
    </Box>
  );
};

export default PhoneNumberInput;
