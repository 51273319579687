import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { hexToRgba } from "utils/helpers";
import SnackBar from "components/snackBar";
import CircularLoading from "components/circularProgress";
import PublishSurveyModal from "./Header/publishSurveyModal";
import Header from "./Header/header";
import SurveySettingsSidebar from "./SurveySettingsSidebar/SurveySettingsSidebar";
import PreviewPage from "./PreviewSurvey/pages/index";
import ControlSection from "./ControlSection/controlSection";
import EditSection from "./EditSection/editSection";
import SurveyFunctions from "./surveyFunctions";
import { useNavigate } from "react-router-dom";
import DeletedAlertView from "pages/alerts/components/alertTypesView/components/deletedAlertView/deletedAlertView";
import { useEmbeddedOptions } from "./hooks/useEmbeddedOptions";
import { useEmbeddedFonts } from "./hooks/useEmbeddedFonts";
import EmbeddedEditor from "./EditSection/embeddedEditor/embeddedEditor";

const SurveyBuilder = () => {
  const {
    id,
    isLoading,
    preview,
    setPreview,
    logoData,
    surveyData,
    setSurveyData,
    savedSurveyData,
    showPoweredBy,
    saveChangesFlag,
    handlePublishBtn,
    surveyLimitReach,
    handleSaveChanges,
    handleDeleteQuestion,
    setLogoData,
    setSQIndex,
    sqIndex,
    ssIndex,
    setSSIndex,
    setOperationPerformed,
    multiRes,
    handleMultiRes,
    setSaveChangesFlag,
    handleCardClick,
    selectedCountryCode,
    setSelectedCountryCode,
    operationPerformed,
    setOpenPublishSurveyModal,
    setIsLucidyaDomain,
    customDomain,
    customDomainStepLoading,
    setCustomDomainStep,
    customDomainStep,
    isSurveyEnabled,
    isSkeipped,
    setIsSkeipped,
    openPublishSurveyModal,
    surveySections,
    setSurveySections,
    filteredSections,
    handleNewSection,
    handleDuplicateSection,
    handleDeleteSection,
    emptySection,
    setEmptySection,
    handleSectionTitle,
    handleQuestionTitle,
    handleQuestionDesc,
    scrollToIdFunc,
    publishFlag,
    handleChangeProceed,
    proceedError,
    afterSecError,
    secTitleErr,
    editRef,
    snackBar,
    setSnackBar,
    saveLoading,
    handleSectionDir,
    setControlTab,
    controlTab,
    handleChangeControlTab,
    customDomainStatus,
    setDiscriptionError,
    discriptionError,
    isSurveyDeleted,
    isEmbedded,
    settingsTab,
    setSettingsTab,
    handleChangeSettingsTab,
    handleAddQuestion,
    embeddedRef,
    openEmbeddedPublishModal,
    setEmbeddedPublishModal,
    embeddedPublishStep,
    setEmbeddedPublishStep,
    handlePublishEmbedded,
    embeddedPublishLoading,
    currentDomainsPage,
    setCurrentDomainsPage,
    companyDomainsList,
    setCompanyDomainsList,
    companyDomainsData,
    setCompanyDomainsData,
    selectedDomains,
    setSelectedDomains,
  } = SurveyFunctions();

  const navigate = useNavigate();

  // This useEffect is used to hide the swipe area when the component is mounted and reset the display style when the component is unmounted
  // The issue is : Luci Swipe area is overlapping the survey builder scroll controls
  useEffect(() => {
    const swipeArea = document.querySelector(".PrivateSwipeArea-root");
    let originalDisplay = "";

    if (swipeArea) {
      originalDisplay = getComputedStyle(swipeArea).display || "block"; // Store the initial display value (default: block if empty)
      swipeArea.style.display = "none"; // Hide the swipe area
    }

    // Restore the original display style
    return () => {
      if (swipeArea) swipeArea.style.display = originalDisplay;
    };
  }, []);

  useEffect(() => {
    if (surveyData?.published == false) {
      navigate(`/survey/add-survey/survey-builder/${id}`);
    } else if (surveyData?.published == true) {
      navigate(`/survey/edit-survey/survey-builder/${id}`);
    }
  }, [surveyData?.published]);

  // To get embedded options dummy and default data from BE
  const { data: embeddedOptions, isLoading: embeddedOptionsLoading } =
    useEmbeddedOptions({ enabled: isEmbedded });

  // To get embedded fonts from BE
  const { data: embeddedFonts, isLoading: embeddedFontsLoading } =
    useEmbeddedFonts({ enabled: isEmbedded });

  useEffect(() => {
    const { survey_type, embedded_config } = surveyData || {};

    if (survey_type?.toLowerCase() == "embedded_survey") {
      const styles = {
        "--survey-primary-color": embedded_config?.popup_style?.primary_color,
        "--survey-font-color": embedded_config?.font_style?.color,
        "--survey-secondary-font-color": hexToRgba(
          embedded_config?.font_style?.color,
          0.6,
        ),
        "--embedded-popup-font": embedded_config?.font_style?.font_type,
        "--embedded-button-font": embedded_config?.button_style?.font_type,
      };

      Object.keys(styles)?.forEach((key) =>
        document.documentElement.style.setProperty(key, styles?.[key]),
      );
    } else {
      const styles = {
        "--survey-primary-color": "#456eb2",
        "--survey-font-color": "#000000",
        "--survey-secondary-font-color": "rgba(0, 0, 0, 0.6)",
      };
      Object.keys(styles)?.forEach((key) =>
        document.documentElement.style.setProperty(key, styles?.[key]),
      );
    }
  }, [surveyData]);

  if (isSurveyDeleted) {
    return <DeletedAlertView deletedSurvey={true} />;
  }

  return (
    <>
      {isLoading || embeddedOptionsLoading || embeddedFontsLoading ? (
        <CircularLoading />
      ) : (
        <>
          {preview ? (
            <PreviewPage
              surveyData={surveyData}
              filteredSections={filteredSections}
              setPreview={setPreview}
              logoData={logoData}
              showPoweredBy={showPoweredBy}
              isEmbedded={isEmbedded}
            />
          ) : (
            <>
              <Header
                surveyData={surveyData}
                setSurveyData={setSurveyData}
                saveChangesFlag={saveChangesFlag}
                publishFlag={publishFlag}
                handleSaveChanges={handleSaveChanges}
                handlePublishBtn={handlePublishBtn}
                id={id}
                preview={preview}
                setPreview={setPreview}
                surveyLimitReach={surveyLimitReach}
                saveLoading={saveLoading}
                isEmbedded={isEmbedded}
                openEmbeddedPublishModal={openEmbeddedPublishModal}
                setEmbeddedPublishModal={setEmbeddedPublishModal}
                embeddedPublishStep={embeddedPublishStep}
                setEmbeddedPublishStep={setEmbeddedPublishStep}
                embeddedPublishLoading={embeddedPublishLoading}
                handlePublishEmbedded={handlePublishEmbedded}
                currentDomainsPage={currentDomainsPage}
                setCurrentDomainsPage={setCurrentDomainsPage}
                companyDomainsList={companyDomainsList}
                setCompanyDomainsList={setCompanyDomainsList}
                companyDomainsData={companyDomainsData}
                setCompanyDomainsData={setCompanyDomainsData}
                selectedDomains={selectedDomains}
                setSelectedDomains={setSelectedDomains}
              />
              <Box className="survey-builder-container">
                <ControlSection
                  surveySections={surveySections}
                  setSurveySections={setSurveySections}
                  filteredSections={filteredSections}
                  handleNewSection={handleNewSection}
                  handleDuplicateSection={handleDuplicateSection}
                  handleDeleteSection={handleDeleteSection}
                  emptySection={emptySection}
                  proceedError={proceedError}
                  afterSecError={afterSecError}
                  secTitleErr={secTitleErr}
                  setEmptySection={setEmptySection}
                  handleDeleteQuestion={handleDeleteQuestion}
                  setSurveyData={setSurveyData}
                  setLogoData={setLogoData}
                  logoData={logoData}
                  handleSaveChanges={handleSaveChanges}
                  setSQIndex={setSQIndex}
                  sqIndex={sqIndex}
                  setSSIndex={setSSIndex}
                  ssIndex={ssIndex}
                  surveyData={surveyData}
                  setOperationPerformed={setOperationPerformed}
                  multiRes={multiRes}
                  handleMultiRes={handleMultiRes}
                  setSaveChangesFlag={setSaveChangesFlag}
                  scrollToIdFunc={scrollToIdFunc}
                  handleSectionDir={handleSectionDir}
                  handleAddQuestion={handleAddQuestion}
                  setControlTab={setControlTab}
                  controlTab={controlTab}
                  handleChangeControlTab={handleChangeControlTab}
                  isEmbedded={isEmbedded}
                />

                {isEmbedded ? (
                  <EmbeddedEditor
                    surveyData={surveyData}
                    setSurveyData={setSurveyData}
                    handleAddQuestion={handleAddQuestion}
                    surveySections={surveySections}
                    setSurveySections={setSurveySections}
                    filteredSections={filteredSections}
                    handleSectionTitle={handleSectionTitle}
                    handleQuestionTitle={handleQuestionTitle}
                    handleQuestionDesc={handleQuestionDesc}
                    sqIndex={sqIndex}
                    ssIndex={ssIndex}
                    setSaveChangesFlag={setSaveChangesFlag}
                    emptySection={emptySection}
                    handleCardClick={handleCardClick}
                    handleChangeProceed={handleChangeProceed}
                    proceedError={proceedError}
                    afterSecError={afterSecError}
                    secTitleErr={secTitleErr}
                    handleSectionDir={handleSectionDir}
                    embeddedRef={embeddedRef}
                    isEmbedded={isEmbedded}
                    scrollToIdFunc={scrollToIdFunc}
                  />
                ) : (
                  <EditSection
                    surveyData={surveyData}
                    setSurveyData={setSurveyData}
                    surveySections={surveySections}
                    setSurveySections={setSurveySections}
                    filteredSections={filteredSections}
                    handleSectionTitle={handleSectionTitle}
                    handleQuestionTitle={handleQuestionTitle}
                    handleQuestionDesc={handleQuestionDesc}
                    setSQIndex={setSQIndex}
                    sqIndex={sqIndex}
                    setSSIndex={setSSIndex}
                    ssIndex={ssIndex}
                    setSaveChangesFlag={setSaveChangesFlag}
                    emptySection={emptySection}
                    proceedError={proceedError}
                    afterSecError={afterSecError}
                    secTitleErr={secTitleErr}
                    handleCardClick={handleCardClick}
                    editRef={editRef}
                    handleChangeProceed={handleChangeProceed}
                    multiRes={multiRes}
                    handleMultiRes={handleMultiRes}
                    handleSectionDir={handleSectionDir}
                    isEmbedded={isEmbedded}
                    handleAddQuestion={handleAddQuestion}
                    embeddedRef={embeddedRef}
                    setDiscriptionError={setDiscriptionError}
                    discriptionError={discriptionError}
                  />
                )}

                <SurveySettingsSidebar
                  settingsTab={settingsTab}
                  setSettingsTab={setSettingsTab}
                  handleChangeSettingsTab={handleChangeSettingsTab}
                  surveyData={surveyData}
                  setSurveyData={setSurveyData}
                  savedSurveyData={savedSurveyData}
                  isEmbedded={isEmbedded}
                  filteredSections={filteredSections}
                  surveySections={surveySections}
                  setSurveySections={setSurveySections}
                  sqIndex={sqIndex}
                  setSQIndex={setSQIndex}
                  ssIndex={ssIndex}
                  setSSIndex={setSSIndex}
                  selectedCountryCode={selectedCountryCode}
                  setSelectedCountryCode={setSelectedCountryCode}
                  embeddedOptions={embeddedOptions}
                  embeddedFonts={embeddedFonts}
                />

                {openPublishSurveyModal && (
                  <PublishSurveyModal
                    openPublishSurveyModal={openPublishSurveyModal}
                    setOpenPublishSurveyModal={setOpenPublishSurveyModal}
                    surveyData={surveyData}
                    setIsLucidyaDomain={setIsLucidyaDomain}
                    customDomain={customDomain}
                    handleSaveChanges={handleSaveChanges}
                    customDomainStepLoading={customDomainStepLoading}
                    setCustomDomainStep={setCustomDomainStep}
                    customDomainStep={customDomainStep}
                    isSurveyEnabled={isSurveyEnabled}
                    isSkeipped={isSkeipped}
                    setIsSkeipped={setIsSkeipped}
                    customDomainStatus={customDomainStatus}
                  />
                )}
              </Box>
            </>
          )}
        </>
      )}
      <SnackBar
        autoHideDuration={5000}
        message={snackBar?.message}
        title={snackBar?.title}
        open={snackBar?.open}
        severity={snackBar?.severity}
        handleClose={() => setSnackBar({ open: false, title: "", msg: "" })}
        alertStyle={{ maxWidth: "320px" }}
      />
    </>
  );
};

export default SurveyBuilder;
