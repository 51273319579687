import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import { Box } from "@mui/material";
import SnackBar from "components/snackBar";
import LucButton from "shared/lucButton/lucButton";
import NoDataFoundProducts from "components/no-Data";
import CircularLoading from "components/circularProgress";
import CustomDashboardFunctions from "./customDashboardFunctions";
import CustomDashboardHeader from "../components/customDashboardHeader";
import WidgetsContainer from "./components/widgetsContainer";
import "./style.scss";
import NotFoundDashboard from "../components/not-found/NotFoundDashboard";
import _ from "lodash";
import { handelMonitorsIDList } from "./components/widgetComponent";
import { ExcelFileProvider } from "./../components/exportButton/excel/context/ExcelFileContext";
import DesginPDF from "./../components/exportButton/pdf/desginPDF/desginPDF";
import moment from "moment";

const CustomDashboardView = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const intl = useIntl();
  const [loadingExport, setLoadingExport] = useState(true);
  const {
    isLoading,
    dashboardInfo,
    startDate,
    endDate,
    applyFilter,
    dashboardWidgets,
    widgetsMqttResponses,
    customizedWidgets,
    isLiveData,
    handleChangeLiveData,
    snackBarData,
    setSnackBarData,
    disabledLive,
    getCustomDashboardView,
    notFoundDashboard,
    getListOfMonitorMQMainWidget,
    dashboardListMonitors,
    dateRangePickerParamsRef,
  } = CustomDashboardFunctions(params);

  let slProduct = +localStorage?.getItem("sm_id");

  let enabledEdit =
    slProduct == dashboardInfo?.product_id
      ? handlelUserRoles("SM", "DESTROY_DASHBOARD")
        ? true
        : dashboardInfo?.dashboard_owner &&
          handlelUserRoles("SM", "EDIT_DASHBOARD")
      : handlelUserRoles("CXM", "DESTROY_DASHBOARD")
        ? true
        : dashboardInfo?.dashboard_owner &&
          handlelUserRoles("CXM", "EDIT_DASHBOARD");

  useEffect(() => {
    if (location?.state?.successEdit) {
      setSnackBarData({
        open: true,
        severity: "success",
        title: "",
        message: "custom_dashboard_updated_successfully_msg",
      });
    }
  }, [location]);

  // to check loading all widgets in the dashboard from RabbitMQ for export button loading
  useEffect(() => {
    setLoadingExport(
      !dashboardWidgets?.every((widget) => {
        return _.isEqual(
          getListOfMonitorMQMainWidget(widget?.attributes)?.sort(), //to get all monitors id that have been response from RabbitMQ for the "widget"
          handelMonitorsIDList(widget?.attributes)?.sort(), //to get all monitors id that have been response from "API BackEnd" for the "widget"
        );
      }),
    );
  }, [widgetsMqttResponses, customizedWidgets]);

  return (
    <>
      {notFoundDashboard ? (
        <NotFoundDashboard />
      ) : (
        <ExcelFileProvider>
          <Box>
            <CustomDashboardHeader
              isLoading={isLoading}
              dashboardInfo={dashboardInfo}
              applyFilter={applyFilter}
              startDate={startDate}
              endDate={endDate}
              openCustomDateLeft={true}
              isLiveData={isLiveData}
              hasWidgets={dashboardWidgets?.length}
              disabledLive={disabledLive}
              handleChangeLiveData={handleChangeLiveData}
              dashboardWidgets={dashboardWidgets}
              loadingExport={loadingExport}
              dateRangePickerParamsRef={dateRangePickerParamsRef}
            />
            {isLoading ? (
              <CircularLoading />
            ) : !dashboardWidgets?.length ? (
              <Box id="empty-widgets-dashboard">
                <NoDataFoundProducts
                  image="customDashboard"
                  title="no_widgets_added"
                  description="adding_widgets_dashboard"
                />
                <LucButton
                  id="empty-custom-dashboard-add-widgets-btn"
                  disabled={!enabledEdit}
                  onClick={() =>
                    navigate(`/dashboard/custom/edit/${dashboardInfo?.id}`)
                  }
                >
                  {CheckValueLocale(
                    "custom_dashboard_add_widgets_btn",
                    "",
                    {},
                    intl,
                  )}
                </LucButton>
              </Box>
            ) : (
              <>
                <WidgetsContainer
                  dashboardInfo={dashboardInfo}
                  dashboardWidgets={dashboardWidgets}
                  widgetsMqttResponses={widgetsMqttResponses}
                  getCustomDashboardView={getCustomDashboardView}
                  setSnackBarData={setSnackBarData}
                  customizedWidgets={customizedWidgets}
                />
                <DesginPDF
                  startDate={moment
                    ?.unix(startDate)
                    ?.utc()
                    ?.format("YYYY-MM-DD")}
                  endDate={moment?.unix(endDate)?.utc()?.format("YYYY-MM-DD")}
                  dashboardInfo={dashboardInfo}
                  dashboardWidgets={dashboardWidgets}
                />
              </>
            )}
            <SnackBar
              open={snackBarData?.open}
              severity={snackBarData?.severity}
              title={CheckValueLocale(snackBarData?.title, "", {}, intl)}
              message={CheckValueLocale(snackBarData?.message, "", {}, intl)}
              handleClose={() => setSnackBarData({})}
              alertStyle={{ maxWidth: "372px" }}
            />
          </Box>
        </ExcelFileProvider>
      )}
    </>
  );
};

export default CustomDashboardView;
