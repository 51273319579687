import React, { useState, useEffect, forwardRef } from "react";
import "react-phone-number-input/style.css";
import { Box, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import englishCountries from "react-phone-number-input/locale/en";
import Input, { getCountryCallingCode } from "react-phone-number-input/input";
import "./phoneNumber.scss";
import { classNames } from "utils/helpers";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useIntl } from "react-intl";
import { InputWithShrinkLabel } from "./inputLabel/inputLabelShrink";

const countries = Object.entries(englishCountries)
  .sort((a, b) => {
    return a[1]?.localeCompare(b[1]);
  })
  .reduce((count, [key, value]) => {
    let dialCode;
    try {
      dialCode = getCountryCallingCode(key);
    } catch (e) {
      return count;
    }
    return {
      ...count,
      [key]: {
        value,
        dialCode,
        key,
      },
    };
  }, {});
const getCountryDialCodeFromPhoneNum = (phoneNum) => {
  const country = Object.values(countries).find((country) => {
    return phoneNum?.startsWith("+" + country.dialCode);
  });
  return country?.key;
};

const useOnClickOutside = (ref, handler) => {
  React.useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};

export const PhoneNumberInput = ({
  defaultValue,
  label,
  placeholder,
  onChange,
  containerClassName = "",
  isEditUser,
  masked = false,
  name,
  onBlur,
  onChangeHandler,
}) => {
  const intl = useIntl();
  const reef = React.useRef(null);
  const isRTL = intl?.locale === "ar";
  let defaultCountry =
    defaultValue?.length > 0
      ? getCountryDialCodeFromPhoneNum(defaultValue)
      : "SA";
  const [value, setValue] = useState(defaultValue);
  const [countryCode, setCountryCode] = useState(defaultCountry);
  const [open, setOpen] = useState(false);
  const [isMaskedInputShown, setIsMaskedInputShown] = useState(true);
  const wapperRef = React.useRef(null);
  const handleInputFocus = () => {
    // add class to wapperRef.current?
    wapperRef.current?.classList?.add("wrapper-focused");
  };
  const handleInputBlur = (e) => {
    // remove class to wapperRef.current?
    wapperRef.current?.classList?.remove("wrapper-focused");
    onBlur && onBlur?.(e);
  };

  const handleChange = (e) => {
    let value = e.target.value;
    if (value !== countryCode) {
      setValue("");
      handleOnChange("");
    }
    setCountryCode(value);
  };
  useEffect(() => {
    if (isEditUser) {
      setCountryCode(
        defaultValue?.length > 0
          ? getCountryDialCodeFromPhoneNum(defaultValue)
          : "SA",
      );
      setValue(defaultValue);
    }
  }, [defaultValue]);
  const handleClick = (event) => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOnChange = (value) => {
    // Special case for whatsapp account popup
    onChangeHandler && onChangeHandler(value);
    setValue(() => value);
    onChange && onChange(value || "");
  };

  useOnClickOutside(reef, () => {
    setIsMaskedInputShown(true);
  });

  return (
    <Box className={classNames("phone-number-container", containerClassName)}>
      <Box
        className={classNames(
          "phone-number-input-wrapper",
          open && "wrapper-opened",
          isRTL && "rtl",
        )}
        ref={wapperRef}
      >
        <Box
          className={classNames("phone-number-details", isRTL && "rtl")}
          onClick={handleClick}
        >
          <Typography
            variant="body1"
            component="span"
            className="phone-number-country"
          >
            {countryCode} +{countries?.[countryCode]?.dialCode}
          </Typography>
          {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </Box>
        {masked && isMaskedInputShown ? (
          <InputWithShrinkLabel
            onClick={() => {
              setIsMaskedInputShown(false);
            }}
            value={value ? "**********" : ""}
            placeholder={placeholder}
          />
        ) : (
          <Input
            label={label}
            ref={reef}
            value={value}
            onChange={handleOnChange}
            defaultCountry={defaultCountry}
            inputComponent={InputWithShrinkLabel}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            country={countryCode}
            placeholder={placeholder}
            className="phone-number-users-label"
            name={name}
          />
        )}

        <Select
          className="phone-number-country-select"
          defaultValue={defaultCountry}
          onChange={handleChange}
          open={open}
          onClose={handleClose}
          // add a className to the select dropdown
          MenuProps={{
            className: classNames("phone-number-country-select-menu"),
          }}
        >
          {Object?.entries(countries)?.map(([key, value]) => {
            return (
              <MenuItem key={key} value={key}>
                {value?.value} ({key} +{value?.dialCode})
              </MenuItem>
            );
          })}
        </Select>
      </Box>
    </Box>
  );
};
