import { Box, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import "./searchableMultiSelectFilter.scss";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";

const SearchableMultiSelectFilter = (props) => {
  const intl = useIntl();

  // The props used in this component are essential for its functionality:
  // - filterName: the name of the filter being applied.
  // - checkDisableOptions: a function to determine if options should be disabled.
  // - disable: a boolean to disable the entire component.
  // - handleListChange: a function to handle changes in the selected options.
  // - valueToRender: a function to get the values to be rendered.
  // - renderOption: a function to customize the rendering of options.
  // - optionsData: the initial options data.
  // - style: custom styles for the component.
  const {
    filterName = "",
    checkDisableOptions = () => false,
    disable = false,
    handleListChange = () => {},
    valueToRender = () => [],
    renderOption = () => {},
    optionList = [],
    style = {},
    className = "",
    noOptionsText = () =>
      CheckValueLocale(
        "searchable_multi_select_filter_no_options",
        "",
        {},
        intl,
      ),
  } = props;

  const [isFocused, setIsFocused] = useState(false);

  // This can be used to implement infinite scrolling

  // const handleOnRowsScrollEnd = (event) => {
  //   const listboxNode = event.target;
  //   if (
  //     (page <= maxPage || maxPage == 0) &&
  //     listboxNode.scrollHeight - listboxNode.scrollTop <=
  //       listboxNode.clientHeight + 100
  //   ) {
  //     setPage(page + 1);
  //   }
  // };

  const inputRef = useRef(null);
  useEffect(() => {
    if (isFocused) {
      inputRef.current?.focus();
    }
  }, [isFocused]);

  return (
    <Box
      onClick={() => {
        setIsFocused(true);
      }}
      onBlur={() => {
        setIsFocused(false);
      }}
    >
      <Autocomplete
        className={`autocomplete-account-list monitor-filter-item ${className}`}
        style={style}
        multiple
        options={optionList}
        getOptionDisabled={checkDisableOptions}
        disableCloseOnSelect
        size="small"
        disableClearable={true}
        id="checkboxes-tags-demo"
        value={valueToRender(filterName)}
        isOptionEqualToValue={(option, value) => option?.value === value?.value}
        renderTags={(value) => {
          return (
            <Box className="tags-container">
              {value?.map((option) => option?.label)?.join(", ")}
            </Box>
          );
        }}
        openOnFocus={isFocused}
        noOptionsText={noOptionsText()}
        ListboxProps={{
          style: { whiteSpace: "nowrap", maxHeight: "200px" },
        }}
        renderOption={renderOption}
        onChange={(event, value) => {
          handleListChange(value, filterName);
        }}
        renderInput={(params) => (
          <TextField
            inputRef={inputRef}
            {...params}
            className={
              isFocused || valueToRender(filterName).length == 0
                ? "enable-input-field"
                : "disable-input-field"
            }
            label={CheckValueLocale(filterName, "", {}, intl)}
          />
        )}
        getOptionLabel={(option) => option?.label}
        // This can be used to implement infinite scrolling
        // ListboxProps={{
        //   onScroll: handleOnRowsScrollEnd,
        // }}
        clearOnBlur={false}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.defaultMuiPrevented = true;
          }
        }}
        disabled={disable}
      />
    </Box>
  );
};

export default SearchableMultiSelectFilter;
