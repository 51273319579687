import * as Yup from "yup";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Container } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { useEditReportValidation } from "./hooks/useEditReportValitionSchema";
import { useReportRecords } from "./hooks/useReportRecords";
import { useReportUsers } from "../create/hooks/useReportUsers";
import { useReportFrequencies } from "../create/hooks/useReportFrequencies";
import { useReportLuciTokens } from "../create/hooks/useReportLuciTokens";
import { useEditReport } from "./hooks/useEditReport";
import { EditReportContext } from "pages/reports/context/reportContext";
import NotFound from "pages/not-found/NotFoundPage";
import CircularLoading from "components/circularProgress";
import SnackBar from "components/snackBar";
import EditReportHeader from "./components/editReportHeader";
import EditReportInformationStep from "./components/editReportInformationStep";
import EditReportSettingStep from "./components/editReportSettingStep";
import EditReportDeliveryStep from "./components/editReportDeliveryStep";
import EditReportFooter from "./components/editReportFooter";
import "./style.scss";

const EditReport = () => {
  const navigate = useNavigate();

  const intl = useIntl();

  const { id } = useParams();

  const [showCustomDaySelect, setShowCustomDaySelect] = useState(false);
  const [snackbar, setSnackbar] = useState({});

  const {
    data: reportDetailsData,
    isLoading: reportDetailsLoading,
    isError: reportDetailsError,
  } = useReportRecords({ queryData: { report_id: id } });

  const reportDetails =
    reportDetailsData?.find((item) => item?.hasOwnProperty("report_info"))
      ?.report_info?.attributes || {};

  // Check if the report type is newsletter
  const isNewsletterReport =
    reportDetails?.report_type?.toLowerCase() === "newsletter";

  // Check if the selected channel_type is ACCOUNT_ANALYSIS and data source is instagram
  const isPublicAccountInstagram =
    reportDetails?.monitor_type?.name?.toLowerCase() === "account_analysis" &&
    reportDetails?.data_source_id?.[0]?.toLowerCase() === "instagram";

  /** -----------------------------------------------------
   * Get available users step (Report Delivery)
   * ----------------------------------------------------- */
  const {
    data: availableUsers,
    isLoading: availableUsersLoading,
    isError: availableUsersError,
  } = useReportUsers();

  /** -----------------------------------------------------
   * Get available frequencies step (Report Delivery)
   * ----------------------------------------------------- */
  const {
    data: reportFrequencies,
    isLoading: reportFrequenciesLoading,
    isError: reportFrequenciesError,
  } = useReportFrequencies();

  const customDayFrequencyId = reportFrequencies
    ?.find((item) => item?.name === "monthly")
    ?.report_recurrences?.find((item) => item?.recurrence_type === "day")?.id; // Getting the custom day frequency ID from reportFrequencies

  const isSpecificDay =
    customDayFrequencyId == reportDetails?.report_recurrence?.id; // Check if the report is on a specific day

  const customDay = Number(reportDetails?.send_date?.slice(8, 10)); // Getting the selected custom day by slicing the day from send_date

  // Show custom day select if the report is on a specific day and not (First of the month or Last of the month)
  useEffect(() => {
    if (isSpecificDay && !!customDay) {
      if (![1, 31]?.includes(customDay)) setShowCustomDaySelect(true);
    }
  }, [isSpecificDay, customDay]);

  /** -----------------------------------------------------
   * Create report mutation hook
   * ----------------------------------------------------- */
  const { mutate: editReport, isPending: editReportLoading } = useEditReport();

  const handleEditReport = () => {
    editReport(
      {
        id,
        queryData: {
          ...formik?.values,
          start_date_time: undefined, // Avoid sending start_date_time key to Backend
          send_date: formik?.values?.start_date_time?.unix(),
        },
      },
      {
        onSuccess: () => {
          navigate("/reports/list", {
            state: {
              edit_report: { status: "success", name: formik?.values?.title },
            },
          });
        },
        onError: () => {
          navigate("/reports/list", {
            state: {
              edit_report: { status: "error", name: formik?.values?.title },
            },
          });
        },
      },
    );
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: reportDetails?.title || "",
      users: reportDetails?.users?.map((item) => item?.id) || [],
      language: reportDetails?.report_extra?.language || "",
      frequency: reportDetails?.frequency?.toLowerCase() || "",
      report_recurrence_id: reportDetails?.report_recurrence?.id || "",
      on_specific_day: isSpecificDay ? customDay : "",
      start_date_time:
        dayjs(new Date(reportDetails?.send_date)) || dayjs(new Date()),
      is_branding: reportDetails?.is_branding || false,
      is_luci_report: reportDetails?.is_luci_report || false,
      report_type_id: reportDetails?.report_type_id || "",
    },
    validationSchema: useEditReportValidation() || Yup.object({}),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: handleEditReport,
  });

  /** -----------------------------------------------------
   * Get available LUCI Tokens step (Report Delivery)
   * ----------------------------------------------------- */
  const {
    data: reportLuciTokens,
    isError: reportLuciTokensError,
    isLoading: reportLuciTokensLoading,
  } = useReportLuciTokens({
    queryData: { report_type_id: reportDetails?.report_type_id },
  });

  useEffect(() => {
    if (
      reportDetailsError ||
      availableUsersError ||
      reportFrequenciesError ||
      reportLuciTokensError
    ) {
      setSnackbar({
        open: true,
        severity: "error",
        title: "something_went_wrong",
        message: "try_again",
      });
    }
  }, [
    reportDetailsError,
    availableUsersError,
    reportFrequenciesError,
    reportLuciTokensError,
  ]);

  const contextProps = {
    formik,
    reportDetails,
    availableUsers,
    availableUsersLoading,
    reportFrequencies,
    reportFrequenciesLoading,
    isNewsletterReport,
    reportLuciTokens,
    reportLuciTokensLoading,
    isPublicAccountInstagram,
    showCustomDaySelect,
    setShowCustomDaySelect,
    editReportLoading,
  };

  if (reportDetailsError) return <NotFound />; // To show not found page

  return (
    <EditReportContext.Provider value={contextProps || {}}>
      <Container maxWidth="xl">
        {reportDetailsLoading ||
        availableUsersLoading ||
        reportFrequenciesLoading ||
        reportLuciTokensLoading ? (
          <CircularLoading />
        ) : (
          <Box id="edit-report-page-container">
            <EditReportHeader />
            <Box id="edit-report-content-container">
              <Box className="edit-report-step-content">
                <EditReportInformationStep />
                <EditReportSettingStep />
                <EditReportDeliveryStep />
              </Box>
              <EditReportFooter />
            </Box>
          </Box>
        )}
        {snackbar?.open ? (
          <SnackBar
            open={snackbar?.open}
            severity={snackbar?.severity}
            title={CheckValueLocale(snackbar?.title, "", {}, intl)}
            message={CheckValueLocale(snackbar?.message, "", {}, intl)}
            handleClose={() => setSnackbar({})}
          />
        ) : null}
      </Container>
    </EditReportContext.Provider>
  );
};

export default EditReport;
