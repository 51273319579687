import "./engagementAnalyticsBanner.scss";
import { useIntl } from "react-intl";
import { Box, Typography } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import PopupModal from "components/popupModal";
import { ReactComponent as EngagementAnalyticsBannerEn } from "images/engagements-images/engag-analytics-banner-en.svg";
import { ReactComponent as EngagementAnalyticsBannerAr } from "images/engagements-images/engag-analytics-banner-ar.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import LucButton from "shared/lucButton/lucButton";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import InsightsController from "services/controllers/insightsController";

const engagContentList = [
  "engagement_analytics_banner_list_1",
  "engagement_analytics_banner_list_2",
  "engagement_analytics_banner_list_3",
];

const engagementAnalyticsBannerKey = "engagementAnalyticsBanner";
const engagementAnalyticsPolicy = "engagement_analytics";
const EngagementAnalyticsBanner = () => {
  const [isBannerOpen, setIsBannerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();
  const navigate = useNavigate();

  const isRTL = intl?.locale === "ar";

  const getEngagementAnalyticsPolicy = () => {
    InsightsController.getApplyPolicy().then((result) => {
      let found = false;
      if (result?.data?.length > 0) {
        result?.data?.map((item) => {
          if (item?.policy_type == engagementAnalyticsPolicy) {
            found = true;
          }
        });
      }
      setIsBannerOpen(!found);
    });
  };

  const handleExploreMore = () => {
    // 1- call the api to get the data
    setIsLoading(true);
    InsightsController.getPolicy(engagementAnalyticsPolicy).then(() => {
      setIsLoading(false);
      navigate("/engagements/analytics/inbox");
    });
  };
  const handleRemindLater = () => {
    if (isLoading) return;
    // 1- mark the banner as read in the local storage
    localStorage.setItem(engagementAnalyticsBannerKey, "read");
    // 2- close the banner
    setIsBannerOpen(false);
  };

  useEffect(() => {
    // read the local storage to check if the banner is read before
    const isBannerRead =
      localStorage.getItem(engagementAnalyticsBannerKey) === "read";
    if (!isBannerRead) {
      getEngagementAnalyticsPolicy();
    }
  }, []);
  return (
    <PopupModal
      open={isBannerOpen}
      close={handleRemindLater}
      classeName="actions-buttons-shared"
      addClasses="engagement-analytics-banner-outer"
      body={
        <Box className="engagement-analytics-banner-body">
          <Box className="engagement-analytics-banner-body-cover">
            {isRTL ? (
              <EngagementAnalyticsBannerAr />
            ) : (
              <EngagementAnalyticsBannerEn />
            )}
            <Box className="engagement-analytics-banner-body-cover-bg" />
            <Box
              className="engagement-analytics-banner-body-cover-close"
              onClick={handleRemindLater}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Box>
          </Box>
          <Box className="engagement-analytics-banner-body-content">
            <Typography
              variant="h4"
              className="engagement-analytics-banner-title"
            >
              {CheckValueLocale(
                "engagement_analytics_banner_title",
                "",
                {},
                intl,
              )}
            </Typography>
            <Box className="engagement-analytics-banner-list">
              <Typography
                className="engagement-analytics-banner-list-header"
                variant="h6"
              >
                {CheckValueLocale(
                  "engagement_analytics_banner_list",
                  "",
                  {},
                  intl,
                )}
              </Typography>
              <Box className="engagement-analytics-banner-list-items">
                {engagContentList.map((item, index) => (
                  <Typography
                    key={index}
                    variant="body1"
                    className="engagement-analytics-banner-list-item"
                  >
                    {CheckValueLocale(item, "", {}, intl)}
                  </Typography>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      }
      leftBtn={CheckValueLocale(
        "engagement_analytics_banner_remind_later",
        "",
        {},
        intl,
      )}
      rightBtn={CheckValueLocale(
        "engagement_analytics_banner_explore_now",
        "",
        {},
        intl,
      )}
      accept={handleExploreMore}
      leftBtnId="engagement-analytics-banner-close"
      rightBtnId="engagement-analytics-banner-explore-now"
      disableLeftBtn={isLoading}
      leftBtnType="primary"
      isRightBtnLoading={isLoading}
    />
  );
};

export default EngagementAnalyticsBanner;
