import Login from "../api/login.js";
import { SetUserData } from "../../shared/set-user-data/set-user-data.js";

var LoginController = {};

LoginController.userLogin = (queryData) => {
  return new Promise(function (resolve, reject) {
    Login.userLogin(queryData)
      .then((response) => {
        if (response?.status !== 200) {
          reject({
            errorMsg: response,
          });
        } else {
          // CHECK ENV TO FIRE POPUP
          if (!response?.data?.data?.step) {
            process.env.REACT_APP_ENV == "production"
              ? window.localStorage.setItem("productionEnv", "true")
              : process.env.REACT_APP_ENV == "production_local"
                ? window.localStorage.setItem("productionEnv", "true")
                : window.localStorage.setItem("productionEnv", "false");

            SetUserData(response);
            window.localStorage.setItem(
              "companyName",
              response?.data?.included[0]?.attributes?.name,
            );
          }
          resolve({
            data: response,
          });
        }
      })
      .catch((error) => {
        reject({
          errorMsg: error,
        });
      });
  });
};

LoginController.user2FAStepsLogin = (queryData) => {
  return new Promise(function (resolve, reject) {
    Login.user2FAStepsLogin(queryData)
      .then((response) => {
        if (response?.status !== 200) {
          reject({
            errorMsg: response,
          });
        } else {
          // CHECK ENV TO FIRE POPUP
          if (!response?.data?.data?.step) {
            process.env.REACT_APP_ENV == "production"
              ? window.localStorage.setItem("productionEnv", "true")
              : process.env.REACT_APP_ENV == "production_local"
                ? window.localStorage.setItem("productionEnv", "true")
                : window.localStorage.setItem("productionEnv", "false");

            SetUserData(response);
            window.localStorage.setItem(
              "companyName",
              response?.data?.included[0]?.attributes?.name,
            );
          }
          resolve({
            data: response,
          });
        }
      })
      .catch((error) => {
        reject({
          errorMsg: error,
        });
      });
  });
};

LoginController.userlogout = (monitorId) => {
  return new Promise(function (resolve) {
    Login.userlogout(monitorId)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
LoginController.userForgetPassword = (email) => {
  var queryData = { email };
  return new Promise(function (resolve) {
    Login.userForgetPassword(queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
LoginController.userResetPassword = (token, new_password) => {
  var queryData = { password_reset_token: token, new_password };
  return new Promise(function (resolve) {
    Login.userResetPassword(queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
LoginController.activateSignUp = (
  invitation_token,
  user_email,
  user_password,
) => {
  var queryData = { invitation_token, user_email, user_password };
  return new Promise(function (resolve) {
    Login.activateSignUp(queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

//login hubspot
LoginController.userLoginHubspot = (queryData) => {
  return new Promise(function (resolve, reject) {
    Login.userLoginHubspot(queryData)
      .then((response) => {
        if (response?.status !== 200 && response?.status !== 204) {
          reject({
            errorMsg: response,
          });
        } else {
          resolve({
            data: response,
            status: response.status,
          });
          SetUserData(response);
        }
      })
      .catch((error) => {
        reject({
          errorMsg: error,
        });
      });
  });
};
LoginController.user2FAStepsHubSpotLogin = (queryData) => {
  return new Promise(function (resolve, reject) {
    Login.user2FAStepsHubSpotLogin(queryData)
      .then((response) => {
        if (response?.status !== 200 && response?.status !== 204) {
          reject({
            errorMsg: response,
          });
        } else {
          resolve({
            data: response,
            status: response.status,
          });
          SetUserData(response);
        }
      })
      .catch((error) => {
        reject({
          errorMsg: error,
        });
      });
  });
};
LoginController.refreshToken = (queryData) => {
  return new Promise(function (resolve, reject) {
    Login.refreshToken(queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        reject({
          data: [],
          errorMsg: error,
        });
      });
  });
};

LoginController.resendCode2FA = () => {
  return new Promise(function (resolve, reject) {
    Login.resendCode2FA()
      .then((response) => {
        if (response?.status == 401) {
          reject({
            error: response,
          });
        }
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        reject({
          data: [],
          errorMsg: error,
        });
      });
  });
};
export default LoginController;
