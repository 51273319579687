import { useQuery } from "@tanstack/react-query";
import { ReportServices } from "services/api/reports";

export const useReportMonitorTypes = ({ enabled, productId }) => {
  return useQuery({
    queryKey: ["reportMonitorTypes", productId],
    queryFn: () => ReportServices.getReportMonitorTypes(productId),
    enabled: enabled && !!productId,
    select: (data) => {
      const seenMonitorTypeIds = new Set();
      const idsToNames = {};
      const namesToIds = {};

      const filteredData = data?.data?.data
        ?.filter((item) => {
          const { monitor_type_id, monitor_type_name } = item?.attributes;
          if (!seenMonitorTypeIds?.has(monitor_type_id)) {
            idsToNames[item?.id] = monitor_type_name;
            namesToIds[monitor_type_name] = monitor_type_id;
            // Region is not a monitor type and MANAGED_PAGE is dealing like MANAGED_ACCOUNT so we exclude it
            if (!["MANAGED_PAGE", "REGION"].includes(monitor_type_name)) {
              seenMonitorTypeIds?.add(monitor_type_id);
              return true;
            }
          }
        })
        ?.map((item) => item?.attributes);

      return { filteredData, idsToNames, namesToIds };
    },
  });
};
