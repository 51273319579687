import Services from "./Services";

var AccountsLists = {};

AccountsLists.getAccountsList = (queryData) => {
  return Services?.getData("api/v3/account_lists", queryData);
};

AccountsLists.editAccountsList = (id, queryData) => {
  return Services?.patchData(`api/v3/account_lists/${id}`, queryData);
};

AccountsLists.deleteAccountsList = (id) => {
  return Services?.deleteData(`api/v3/account_lists/${id}`);
};

AccountsLists.getAccountsListById = (id) => {
  return Services?.getData(`api/v3/account_lists/${id}`);
};

AccountsLists.getAccountsListByUsername = (id) => {
  return Services?.getData(`api/v3/account_lists/${id}/username_lists`);
};

AccountsLists.createAccountsList = (queryData) => {
  return Services?.postData("api/v3/account_lists", queryData);
};

AccountsLists.duplicateAccountsList = (id) => {
  return Services?.postData(`api/v3/account_lists/${id}/duplicate`);
};

AccountsLists.updateUsernameLists = (username, account_list_ids) => {
  return Services?.patchData(`api/v3/account_lists/update_username_lists`, {
    username,
    account_list_ids,
  });
};

AccountsLists.getUsernameAccountLists = (username, search) => {
  return Services?.getData(`api/v3/account_lists/index_for_dropdown`, {
    username,
    search,
  });
};

AccountsLists.getXlsxGuide = () => {
  return Services.getData(`api/v3/account_lists/xlsx_guide`);
};

export default AccountsLists;
