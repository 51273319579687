import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { MainMonitorsPage } from "services/api/mainMonitorsPage";

let getQuickInspectDataCancelToken;
export const useGetQuickInspectData = () => {
  return useMutation({
    mutationFn: ({ monitor_id, monitorDataSource, queryDataParams }) => {
      if (getQuickInspectDataCancelToken) {
        getQuickInspectDataCancelToken.cancel(
          "Operation canceled due to a new request.",
        );
      }
      getQuickInspectDataCancelToken = axios?.CancelToken?.source();
      const queryData = {
        params: {
          ...queryDataParams
        },
        cancelToken: getQuickInspectDataCancelToken?.token
      };
      return MainMonitorsPage.getQuickInspectData({
        monitor_id,
        monitorDataSource,
        queryData,
      });
    },
  });
};
