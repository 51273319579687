import { useQuery } from "@tanstack/react-query";
import Engagements from "services/api/engagements";

export const useSurveyCharacterLimits = (payLoad) => {
  return useQuery({
    queryKey: ["SurveyCharacterLimits"],
    queryFn: () => Engagements.getSurveyCharacterLimits(payLoad),
    enabled: !!payLoad,
  });
};
