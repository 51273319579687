import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import { CheckValueLocale } from "utils/helpers";
import ExploreSearchHeader from "./components/exploreSearchHeader/exploreSearchHeader";
import OverviewExplore from "./components/overviewExplore/overviewExplore";
import SaveResultsPopup from "./components/saveResultsPopup/saveResultsPopup";
import SnackBar from "components/snackBar";

const ExploreSearch = ({
  response,
  resetAllFields,
  exploreDataCount,
  exploreDate,
  topPosts,
  setTopPosts,
  pageNumber,
  setPageNumber,
  topPostsPreloader,
  setTopPostsPreloader,
  topPostsCount,
  setTopPostsCount,
  maxData,
  setMaxData,
  totalPage,
  setTotalPage,
  exploreId,
  getShowExploreData,
  preLoaderTrackerDataSources,
  netSentiment,
  overViewPreLoaderDataSources,
  overview,
  setOverview,
  overviewArray,
  setOverviewArray,
  noOfDatasources,
  setNoOfDatasources,
  sentimentPieChart,
  setSentimentPieChart,
  sentimentAnalysisCopy,
  setSentimentAnalysisCopy,
  interactionsPreLoader,
  setInteractionsPreLoader,
  interactions,
  setInteractions,
  interactionsLegend,
  setInteractionsLegend,
  isInteractionsHourlyFormat,
  setNetSentiment,
  setOverViewPreLoaderDataSources,
  setActiveExploreStep,
  sentimentAnalysisPreLoader,
  setSentimentAnalysisPreLoader,
  sentimentAnalysis,
  setSentimentAnalysis,
  isSentimentAnalysisHourlyFormat,
  activeExploreStep,
  topPostsSumCount,
  getFiltersFileds,
  filterParams,
}) => {
  const childProps = {
    response,
    resetAllFields,
    exploreDataCount,
    exploreDate,
    topPosts,
    setTopPosts,
    pageNumber,
    setPageNumber,
    topPostsPreloader,
    setTopPostsPreloader,
    topPostsCount,
    setTopPostsCount,
    maxData,
    setMaxData,
    totalPage,
    setTotalPage,
    exploreId,
    getShowExploreData,
    preLoaderTrackerDataSources,
    netSentiment,
    overViewPreLoaderDataSources,
    overview,
    setOverview,
    overviewArray,
    setOverviewArray,
    noOfDatasources,
    setNoOfDatasources,
    sentimentPieChart,
    setSentimentPieChart,
    sentimentAnalysisCopy,
    setSentimentAnalysisCopy,
    interactionsPreLoader,
    setInteractionsPreLoader,
    interactions,
    setInteractions,
    interactionsLegend,
    setInteractionsLegend,
    isInteractionsHourlyFormat,
    setNetSentiment,
    setOverViewPreLoaderDataSources,
    setActiveExploreStep,
    sentimentAnalysisPreLoader,
    setSentimentAnalysisPreLoader,
    sentimentAnalysis,
    setSentimentAnalysis,
    isSentimentAnalysisHourlyFormat,
    activeExploreStep,
    topPostsSumCount,
    getFiltersFileds,
    filterParams,
  };

  const intl = useIntl();
  const [showSaveResults, setShowSaveResults] = useState(false);
  const [checkedSaved, setCheckedSaved] = useState(false);
  const [alertData, setAlertData] = useState({
    number: "",
    message: "",
    title: "",
    severity: "",
    openAlert: false,
  });
  const handleSaveResultCreate = (severity, msg, tit) => {
    setCheckedSaved(true);
    handleSaveRestlusAlert(severity, msg, tit);
  };
  const handleSaveRestlusAlert = (severity, msg, tit) => {
    setAlertData({
      title: tit,
      message: msg,
      severity: severity,
      openAlert: severity && msg ? true : false,
    });
  };

  useEffect(() => {
    if (exploreId && activeExploreStep == 3) {
      getFiltersFileds(exploreId);
    }
  }, [exploreId, activeExploreStep]);

  return (
    <Box className="explore-search-container">
      <ExploreSearchHeader
        exploreDataCount={exploreDataCount}
        exploreDate={exploreDate}
        setShowSaveResults={setShowSaveResults}
        checkedSaved={checkedSaved}
        topPostsSumCount={topPostsSumCount}
      />
      <OverviewExplore {...childProps} />
      {showSaveResults && (
        <SaveResultsPopup
          open={() => setShowSaveResults(true)}
          close={() => setShowSaveResults(false)}
          create={handleSaveResultCreate}
          exploreId={exploreId}
        />
      )}
      <SnackBar
        open={alertData?.openAlert}
        handleClose={() =>
          setAlertData({
            number: "",
            message: "",
            title: "",
            severity: "",
            openAlert: false,
          })
        }
        severity={alertData.severity}
        title={CheckValueLocale(alertData.title, "", {}, intl)}
        message={CheckValueLocale(alertData.message, "", {}, intl)}
      />
    </Box>
  );
};

export default ExploreSearch;
