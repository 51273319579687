import {
  Box,
  Typography,
  Divider,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  TextField,
  Popover,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { faFaceGrinWide } from "@fortawesome/free-regular-svg-icons";
import "./noteCardItem.scss";
import {
  CheckValueLocale,
  calculateUnixTimeZone,
  formatTimeZone,
} from "utils/helpers";
import { useIntl } from "react-intl";
import { useEffect, useRef, useState } from "react";
import useDeleteNote from "./hooks/useDeleteNote";
import PopupModal from "components/popupModal";
import SnackBar from "components/snackBar";
import { useFormik } from "formik";
import * as Yup from "yup";
import useUpdateNote from "./hooks/useUpdateNote";
import UserAvatar from "shared/unifiedUserImage/userAvatar";
import data from "@emoji-mart/data";
import LucButton from "shared/lucButton/lucButton";
import Picker from "@emoji-mart/react";

const NoteCardItem = ({
  note,
  showDivider,
  requestParams,
  onDelete, // Function to notify parent of note deletion id
  onUpdate, // Function to notify parent of note update id
  isEditing, // Whether this note is being edited
  onEdit, // Function to notify parent of edit mode
  onCancel, // Function to notify parent of cancel
  onShowSnackbar, // Function to notify parent snackbar
}) => {
  const { mutate: deleteNote, isPending: deleteLoading } = useDeleteNote();
  const { mutate: updateNote, isPending: isUpdating } = useUpdateNote();
  const intl = useIntl();
  const inputRef = useRef();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [anchorElEmoji, setAnchorElEmoji] = useState(null);
  const [anchorPosition, setAnchorPosition] = useState(null);

  const isNoteCreator = +localStorage.getItem("user_id") == +note?.userId;

  let open = Boolean(anchorEl);
  // Open menu handler
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Close menu handler
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
    handleMenuClose(); // Close the dropdown menu
  };

  const handleCloseDeleteModal = () => {
    if (deleteLoading) {
      return;
    }
    setOpenDeleteModal(false);
  };

  const handleDelete = () => {
    deleteNote(
      { params: requestParams, noteId: note?.id },
      {
        onSuccess: (data) => {
          setOpenDeleteModal(false);
          onDelete(note?.id);
          onShowSnackbar({
            message: "delete_note_success_snackbar",
            severity: "success",
            open: true,
          });
        },
        onError: () => {
          setOpenDeleteModal(false);
          onShowSnackbar({
            title: "delete_note_failed_snackbar_title",
            message: "delete_note_failed_snackbar_body",
            severity: "error",
            open: true,
          });
        },
      },
    );
  };
  const formik = useFormik({
    initialValues: { text: note?.text },
    validationSchema: Yup.object({
      text: Yup.string().trim().max(500, "max_note_length_error"),
    }),
    onSubmit: (values) => {
      updateNote(
        {
          params: { ...requestParams, text: values?.text?.trim() },
          noteId: note.id,
        },
        {
          onSuccess: (data) => {
            onCancel();
            onUpdate(note.id, { ...data?.data });
            formik.setValues({ text: data?.data?.text });
          },
          onError: () => {
            onShowSnackbar({
              message: "try_again_error_message",
              severity: "error",
              title: "failed_error_message",
              open: true,
            });
          },
        },
      );
    },
  });

  const handleOpenEmoji = (event) => {
    if (!event?.currentTarget) return;

    // Get the emoji button's position
    const rect = event?.currentTarget?.getBoundingClientRect();

    if (intl?.locale === "en") {
      setAnchorPosition({
        top: rect?.bottom - 80, // Position below the button
        left: rect?.left - 65, // Adjust horizontal position
      });
    } else {
      setAnchorPosition({
        top: rect?.bottom - 80, // Position below the button
        left: rect?.left + 450, // Adjust horizontal position
      });
    }
    setAnchorElEmoji(event?.currentTarget);
  };
  const handleCloseEmoji = () => {
    setAnchorElEmoji(null);
    inputRef.current?.focus?.();
  };

  // Handle starting edit mode
  const handleEditClick = () => {
    onEdit(note?.id);
  };
  // Handle cancelling edit mode
  const handleCancelEdit = () => {
    onCancel();
    formik.resetForm();
  };

  useEffect(() => {
    if (!isEditing) {
      formik.setValues({ text: note?.text });
    }
  }, [isEditing]);

  const getNoteData = (dataVal) => {
    let { date, time } = formatTimeZone(
      calculateUnixTimeZone(dataVal, {
        utc: true,
      }),
      intl,
      {},
    );
    return `${date}, ${time}`;
  };

  return (
    <>
      <Box className="note-card">
        <Box className="note-avatar">
          <UserAvatar
            fullName={note?.author}
            avatarSrc={note?.profilePicture}
            sizes={{ ratio: "28px", fontSize: "12px" }}
            isBgDark={true}
          />
        </Box>
        <Popover
          open={Boolean(anchorElEmoji)}
          onClose={handleCloseEmoji}
          anchorReference="anchorPosition" // Use fixed position reference
          anchorPosition={anchorPosition}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          classes={{
            paper: "reply-box-emoji-popover",
            root: "reply-box-emoji-popover-root",
          }}
        >
          <Picker
            data={data}
            onEmojiSelect={(emoji) => {
              formik.setFieldValue("text", formik.values.text + emoji.native);
            }}
          />
        </Popover>
        {isEditing ? (
          <Box className="edit-note-form">
            <TextField
              fullWidth
              name="text"
              className="edit-input"
              inputRef={inputRef}
              multiline
              minRows={2}
              value={formik?.values?.text}
              onChange={formik?.handleChange}
              error={!!formik.errors.text}
              helperText={CheckValueLocale(formik?.errors?.text, "", {}, intl)}
              InputProps={{
                endAdornment: (
                  <Box
                    className="update-input-buttons-holder"
                    onClick={() => {
                      inputRef.current?.focus?.();
                    }}
                  >
                    {/* emoji icon */}
                    <Tooltip
                      title={CheckValueLocale("notes_emoji_icon", "", {}, intl)}
                      placement="bottom"
                      arrow
                      classes={{ popper: "notes-icons-tooltip" }}
                    >
                      <Box>
                        <LucButton
                          onClick={handleOpenEmoji}
                          type="secondary"
                          variant="text"
                          className="notes-footer-button"
                          id="notes-footer-emoji-button"
                          minWidth={32}
                          onMouseDown={(e) => e.preventDefault()}
                        >
                          <FontAwesomeIcon icon={faFaceGrinWide} />
                        </LucButton>
                      </Box>
                    </Tooltip>
                    <Box className="edit-actions">
                      <LucButton
                        type="secondary"
                        variant="flat"
                        size="small"
                        onClick={handleCancelEdit}
                        className="edit-action-cancel"
                        id="notes-footer-cancel-button"
                      >
                        {CheckValueLocale("cancel", "", {}, intl)}
                      </LucButton>
                      <LucButton
                        type="primary" // Primary color
                        variant="filled" // Matches MUI's contained button
                        size="small"
                        loading={isUpdating} // Shows loading spinner if submitting
                        className="edit-action-save"
                        onClick={formik.handleSubmit} // Submits form on click
                        disabled={
                          formik.values.text.trim() === note.text.trim() ||
                          !formik?.values?.text?.trim?.() ||
                          formik?.errors?.text
                        }
                        id="save-note-btn"
                      >
                        {CheckValueLocale("save", "", {}, intl)}
                      </LucButton>
                    </Box>
                  </Box>
                ),
              }}
            />
          </Box>
        ) : (
          <Box className="note-content">
            <Box className="note-header">
              <Typography className="note-author">
                <Typography component="span" className="note-author-name">
                  {note?.author}
                </Typography>
                {note?.is_edit ? (
                  <Box component="span" className="is-edited">
                    {CheckValueLocale("is_note_edited", "", {}, intl)}
                  </Box>
                ) : null}
              </Typography>
              {isNoteCreator ? (
                <Tooltip
                  title={CheckValueLocale("notes_more_icon", "", {}, intl)}
                  placement="bottom"
                  arrow
                  classes={{ popper: "notes-icons-tooltip" }}
                >
                  <IconButton
                    className={`note-options ${open ? "open" : null}`}
                    onClick={handleMenuOpen}
                  >
                    <FontAwesomeIcon icon={faEllipsisH} />
                  </IconButton>
                </Tooltip>
              ) : null}
            </Box>
            <Typography component={"pre"} className="note-text">
              {note?.text}
            </Typography>
            <Typography className="note-timestamp">
              {getNoteData(note?.timestamp)}
            </Typography>
          </Box>
        )}
      </Box>
      {/* More options menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        className="note-card-item-menu"
        elevation={2}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          onClick={() => {
            handleEditClick();
            handleMenuClose();
          }}
        >
          {CheckValueLocale("edit", "", {}, intl)}
        </MenuItem>
        <MenuItem onClick={handleOpenDeleteModal} className="delete-option">
          {CheckValueLocale("delete", "", {}, intl)}
        </MenuItem>
      </Menu>
      {showDivider ? (
        <Divider className="note-divider" sx={{ bgcolor: "#e2e8f0" }} />
      ) : null}
      <PopupModal
        title={CheckValueLocale("delete_note", "", {}, intl)}
        body={CheckValueLocale("delete_note_text", "", {}, intl)}
        leftBtn={CheckValueLocale("cancel", "", {}, intl)}
        rightBtn={CheckValueLocale("delete", "", {}, intl)}
        open={openDeleteModal}
        close={handleCloseDeleteModal}
        accept={handleDelete}
        minWidth="600px"
        warning
        isRightBtnLoading={deleteLoading}
        disabled={deleteLoading}
        classeName="actions-buttons-shared"
      />
    </>
  );
};

export default NoteCardItem;
