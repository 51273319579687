import { useIntl } from "react-intl";
import {
  Box,
  FormControl,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import ColorCodeAndPicker from "./components/colorCodeAndPicker";

const BackdropOptions = (props) => {
  const { surveyData, setSurveyData, savedSurveyData } = props;

  const intl = useIntl();

  const selectedOptions = surveyData?.embedded_config?.backdrop || {};

  const handleChangeOption = (key, value) => {
    const numberRegex = /^(\d+(\.\d*)?|\.?)$/; // Allows numbers, decimals, and just a dot
    if (key === "opacity" && !numberRegex?.test(value)) return;

    setSurveyData((prev) => ({
      ...prev,
      embedded_config: {
        ...prev?.embedded_config,
        backdrop: { ...prev?.embedded_config?.backdrop, [key]: value },
      },
    }));
  };

  const handleBlurOpacity = (key, value) => {
    if (value?.length < 2)
      setSurveyData((prev) => ({
        ...prev,
        embedded_config: {
          ...prev?.embedded_config,
          backdrop: {
            ...prev?.embedded_config?.backdrop,
            [key]: savedSurveyData?.embedded_config?.backdrop?.[key],
          },
        },
      }));
  };

  return (
    <Box className="display-settings-options-accordion-content">
      <Box className="display-settings-options-accordion-content-option-wrapper">
        <Typography className="display-settings-options-subtitle">
          {CheckValueLocale("display_settings_color", "", {}, intl)}
        </Typography>
        <ColorCodeAndPicker
          id="backdrop-style-color-field"
          value={selectedOptions?.color}
          onChange={(e) => handleChangeOption("color", e?.target?.value)}
          defaultValue={savedSurveyData?.embedded_config?.backdrop?.color}
        />
      </Box>

      <Box className="display-settings-options-accordion-content-option-wrapper">
        <Typography className="display-settings-options-subtitle">
          {CheckValueLocale("display_settings_opacity", "", {}, intl)}
        </Typography>
        <FormControl
          id="embedded-survey-display-settings-opacity-form-control"
          variant="outlined"
          size="small"
          fullWidth
        >
          <TextField
            id="embedded-survey-display-settings-opacity-field"
            className="embedded-survey-display-settings-text-field opacity-text-field"
            value={selectedOptions?.opacity}
            onChange={(e) => handleChangeOption("opacity", e?.target?.value)}
            onBlur={(e) => handleBlurOpacity("opacity", e?.target?.value)}
            variant="outlined"
            size="small"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment
                  className="display-settings-adornment"
                  position="start"
                  size="small"
                >
                  %
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </Box>
    </Box>
  );
};

export default BackdropOptions;
