import { useIntl } from "react-intl";
import { Box, Typography } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import DisplayOptionSelect from "./components/displayOptionSelect";
import ColorCodeAndPicker from "./components/colorCodeAndPicker";

const FontStyle = (props) => {
  const { surveyData, setSurveyData, savedSurveyData, embeddedFonts } = props;

  const intl = useIntl();

  const selectedOptions = surveyData?.embedded_config?.font_style || {};

  const handleChangeOption = (key, value) => {
    setSurveyData((prev) => ({
      ...prev,
      embedded_config: {
        ...prev?.embedded_config,
        font_style: { ...prev?.embedded_config?.font_style, [key]: value },
      },
    }));
  };

  return (
    <Box className="display-settings-options-accordion-content">
      <Box className="display-settings-options-accordion-content-option-wrapper">
        <Typography className="display-settings-options-subtitle">
          {CheckValueLocale("display_settings_type", "", {}, intl)}
        </Typography>
        <DisplayOptionSelect
          id="display-settings-font-type-select"
          className="font-type-select"
          options={embeddedFonts?.map((font) => {
            return {
              label: font?.attributes?.font_name,
              value: font?.attributes?.css_identifier,
            };
          })}
          value={surveyData?.embedded_config?.font_style?.font_type}
          onChange={(e) => handleChangeOption("font_type", e?.target?.value)}
          fontsList
        />
      </Box>

      <Box className="display-settings-options-accordion-content-option-wrapper">
        <Typography className="display-settings-options-subtitle">
          {CheckValueLocale("display_settings_color", "", {}, intl)}
        </Typography>
        <ColorCodeAndPicker
          id="font-style-color-field"
          value={selectedOptions?.color}
          onChange={(e) => handleChangeOption("color", e?.target?.value)}
          defaultValue={savedSurveyData?.embedded_config?.font_style?.color}
        />
      </Box>
    </Box>
  );
};

export default FontStyle;
