import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ReportServices } from "services/api/reports";

export const usePauseResumeReport = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, queryData }) =>
      ReportServices.updateReportStatus({ id, queryData }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["reportsList"] });
    },
  });
};
