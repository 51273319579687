import { useState } from "react";
import {
  Box,
  Menu,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import LucButton from "shared/lucButton/lucButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { CheckValueLocale } from "utils/helpers/index.js";
import { useIntl } from "react-intl";
import "./exportButton.scss";

const ExportButton = ({
  loadingExport,
  dropDownTypes,
  languagesList,
  langSelect,
  handleClickExport,
}) => {
  const intl = useIntl();
  const [anchorExport, setAnchorExport] = useState(null);
  const openExport = Boolean(anchorExport);
  const menuDirection = intl?.locale === "en" ? "right" : "left";

  const handleOpenExport = (event) => {
    if (anchorExport !== event?.currentTarget)
      setAnchorExport(event?.currentTarget);
  };
  const handleCloseExport = () => setAnchorExport(null);

  return (
    <Box>
      <LucButton
        variant="outline"
        type="secondary"
        size="small"
        aria-owns={anchorExport}
        aria-haspopup="true"
        aria-expanded={openExport}
        onClick={handleOpenExport}
        startIcon={<FontAwesomeIcon icon={faArrowDownToLine} />}
        endIcon={<FontAwesomeIcon icon={faCaretDown} />}
        loading={loadingExport}
        id="export-button-newsletter-report"
      >
        {CheckValueLocale("export", "", {}, intl)}
      </LucButton>
      <Menu
        id="export-menu-newsletter-report"
        MenuListProps={{
          "aria-labelledby": "export-button-newsletter-report",
          onMouseLeave: handleCloseExport,
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: menuDirection,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: menuDirection,
        }}
        anchorEl={anchorExport}
        open={openExport}
        onClose={handleCloseExport}
      >
        <Box className="export-menu-newsletter-report-body">
          <Box className="tabs-container-type">
            <Box className="export-options-title-type">
              {CheckValueLocale("export_options", "", {}, intl)}
            </Box>
            <Box className="export-item-types">
              {dropDownTypes?.map((type, index) => (
                <Box key={index} className="export-choose">
                  <img src={type?.imageType} alt="pdf" />
                  {CheckValueLocale(type?.name, "", {}, intl)}
                </Box>
              ))}
            </Box>
          </Box>

          <Divider className="divider-item" />

          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            className="language-radio-buttons"
            value={langSelect}
          >
            {languagesList?.map((language, index) => (
              <FormControlLabel
                key={index}
                value={language?.value}
                control={<Radio />}
                disabled={language?.disabled}
                label={language?.label}
              />
            ))}
          </RadioGroup>

          <Box className="export-current-page-only">
            {CheckValueLocale("export_current_page_only", "", {}, intl)}
          </Box>

          <LucButton
            id="export-file-btn"
            variant="filled"
            loading={loadingExport}
            loadingPosition="start"
            onClick={handleClickExport}
          >
            {CheckValueLocale("export", "", {}, intl)}
          </LucButton>
        </Box>
      </Menu>
    </Box>
  );
};

export default ExportButton;
