import React from "react";
import { useIntl } from "react-intl";
import { Box, CircularProgress, Switch, Typography } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import "./style.scss";
import Services from "services/api/Services";

const ReportLuciSelect = (props) => {
  const {
    value = false,
    handleChange = () => {},
    loading = false,
    tokens = 0,
  } = props;

  const intl = useIntl();

  return (
    <Box id="report-luci-select-container">
      <Box className="content-container">
        <Box className="luci-icon">
          <img src={Services?.aiProductLogo} alt="" />
        </Box>
        <Box className="content-text">
          <Typography className="title">
            {CheckValueLocale("luci_in_reports", "", {}, intl)}
            <Box component={"span"}>
              <Switch size="small" checked={value} onChange={handleChange} />
            </Box>
          </Typography>
          <Typography className="info">
            {CheckValueLocale("luci_in_reports_info_1", "", {}, intl)}
            <Box component={"span"}>🚀✨</Box>
            <br />
            {CheckValueLocale("luci_in_reports_info_2", "", {}, intl)}
          </Typography>
          <Typography className="tokens-container">
            <Box component="span" className="tokens-num">
              <Box component="span">
                {loading ? <CircularProgress /> : tokens || 0}
              </Box>
              <img src={Services?.tokensIconBlue} alt="" />
            </Box>
            <Box component="span" className="tokens-per-report">
              {CheckValueLocale("per_report", "", {}, intl)}
            </Box>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ReportLuciSelect;
