import { useState } from "react";
import { Container, Box } from "@mui/material";
import "../../../../../../shared/createHeader/createHeader.scss";
import MonitorInfoSections from "../../Components/MonitorInfoSections/monitorInfoSections";
import ManagedAccountDataSources from "./managedAccountDataSources-SM";
import AddAutoSummaryWidget from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/AddAutoSummaryWidget/addAutoSummaryWidget";
import CommonFunctions from "../../createCommonFunctions/createMonitorCommonFunctions";

const ManagedAccount = (props) => {
  const [value, setValue] = useState(2);
  const {
    monitorData,
    setMonitorData,
    setMonitorErrors,
    monitorErrors,
    handleCreateMonitorBtn,
    companyAllowedSources,
    monitorTopics,
    setMonitorTopics,
    errorKeywordsMsg,
    setErrorKewyordsMsg,
    errorExKeywordsMsg,
    setExErrorKewyordsMsg,
    displayNameMsgError,
    setDisplayNameMsgError,
    disabledManualTopic,
    manualTopicStats,
    summarySmTokensManagedAccount,
    handleMonitorTopicsUsage,
  } = props;
  const { getDataSourceName } = CommonFunctions();
  return (
    <Box className="monitor-type">
      <Container>
        <MonitorInfoSections
          monitorErrors={monitorErrors}
          monitorData={monitorData}
          setMonitorData={setMonitorData}
          setMonitorErrors={setMonitorErrors}
          handleCreateMonitorBtn={handleCreateMonitorBtn}
        />
        <ManagedAccountDataSources
          handleCreateMonitorBtn={handleCreateMonitorBtn}
          monitorData={monitorData}
          setMonitorData={setMonitorData}
          companyAllowedSources={companyAllowedSources}
          dataSourcesId={props.dataSourcesId}
          setActiveDataSourceId={props.setActiveDataSourceId}
          activeDataSourceId={props.activeDataSourceId}
          isClikedFb={props.isClikedFb}
          setIsClikedFb={props.setIsClikedFb}
          isClikedIg={props.isClikedIg}
          setIsClikedIg={props.setIsClikedIg}
          monitorTopics={monitorTopics}
          setMonitorTopics={setMonitorTopics}
          errorKeywordsMsg={errorKeywordsMsg}
          setErrorKewyordsMsg={setErrorKewyordsMsg}
          errorExKeywordsMsg={errorExKeywordsMsg}
          setExErrorKewyordsMsg={setExErrorKewyordsMsg}
          displayNameMsgError={displayNameMsgError}
          setDisplayNameMsgError={setDisplayNameMsgError}
          disabledManualTopic={disabledManualTopic}
          manualTopicStats={manualTopicStats}
          value={value}
          setValue={setValue}
          setMonitorErrors={setMonitorErrors}
          handleMonitorTopicsUsage={handleMonitorTopicsUsage}
        />
        {props?.autoSummaryWidgetPackage ? (
          <AddAutoSummaryWidget
            setWidgetData={setMonitorData}
            widgetData={monitorData}
            summaryTokens={
              summarySmTokensManagedAccount?.[getDataSourceName(value)]
            }
          />
        ) : null}
      </Container>
    </Box>
  );
};

export default ManagedAccount;
