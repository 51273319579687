import { useQuery } from "@tanstack/react-query";
import Engagements from "services/api/engagements";

export const useSurveyCsatOptions = (payLoad) => {
  return useQuery({
    queryKey: ["SurveyCsatOptions"],
    queryFn: () => Engagements.getSurveyCsatOptions(payLoad),
    enabled: !!payLoad,
  });
};
