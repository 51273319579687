import { Box, Divider } from "@mui/material";
import styles from "./Header.module.css";

const Header = (props) => {
  const { surveyData, logoData } = props;

  return (
    <Box>
      <Box className={styles.pageStyle}>
        {logoData?.logoImage && (
          <>
            <img
              src={
                logoData?.logoImage instanceof File
                  ? URL.createObjectURL(
                      logoData?.logoImage ? logoData?.logoImage : "",
                    )
                  : logoData?.logoImage
              }
              alt={
                logoData?.logoImage ? logoData?.logoImage?.name : "Not Found"
              }
              className={styles.imgStyle}
            />
            <Divider className={styles.subDivider} />
          </>
        )}
        <Box>
          <Box className={styles.mainHeader}>{surveyData?.title}</Box>
          <Box className={styles.subText}>{surveyData?.subtitle}</Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
