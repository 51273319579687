import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import { Add } from "@mui/icons-material";
import { CheckValueLocale } from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";
import ChoiceSection from "./choiceSection";
import ChoiceSectionPreviwMode from "./choiceSectionPreviwMode";

const MultipleChoiceQuestion = (props) => {
  const {
    filteredSections,
    rootQuestion,
    question,
    questionIndex,
    sectionIndex,
    setSaveChangesFlag,
    handleProceedAnswer,
    previewMode,
  } = props;

  const intl = useIntl();

  const [choices, setChoices] = useState([]);
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    if (question) {
      setChoices(question?.items);
      setEditable(question?.editable);
    }
  }, [question]);

  const handleAddChoice = () => {
    if (editable !== false) {
      if (choices?.length <= 9) {
        const myKey = choices?.length + 1;
        const mcqValue = `${CheckValueLocale("dropdown_option", "", {}, intl)} ${choices?.length + 1}`;
        var obj = { [myKey]: mcqValue };
        const proceedOnAnswer = filteredSections[sectionIndex].questions.filter(
          (ques) => ques?.is_deleted !== true,
        )[questionIndex].is_proceed_on_answer;
        if (proceedOnAnswer) {
          if (sectionIndex === filteredSections?.length - 1)
            obj.next_section = -1;
          else obj.next_section = filteredSections[sectionIndex].order + 1;
        }
        setChoices([...choices, obj]);
        filteredSections[sectionIndex].questions.filter(
          (ques) => ques?.is_deleted !== true,
        )[questionIndex].question.items = [...choices, obj];
      }
      setSaveChangesFlag(true);
    } else return false;
  };

  return (
    <Box className="survey-builder-mcq-question">
      {!previewMode ? (
        <ChoiceSection
          {...props}
          choices={choices}
          setChoices={setChoices}
          editable={editable}
        />
      ) : (
        <ChoiceSectionPreviwMode
          question={question}
          rootQuestion={rootQuestion}
          handleProceedAnswer={handleProceedAnswer}
        />
      )}

      {!previewMode ? (
        <Box className="add-choice-btn-wrapper">
          <LucButton
            variant="flat"
            size="small"
            startIcon={<Add />}
            onClick={handleAddChoice}
            disabled={editable === false && true}
          >
            {CheckValueLocale("add_choice", "", {}, intl)}
          </LucButton>
        </Box>
      ) : null}
    </Box>
  );
};

export default MultipleChoiceQuestion;
