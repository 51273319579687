import { Popover, Box, Typography } from "@mui/material";
import { CustomSourcesLoading } from "./Loading";
import { NoResults } from "./NoResults";
import { SourceOption } from "./sourceOption";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";

export const OptionsPopover = ({
  open,
  anchorEl,
  onClose,
  data,
  isPending,
  isOptionSelected,
  onOptionSelect,
  isExcludedSources,
  handleOpenRequestVerificationModal,
}) => {
  const intl = useIntl();
  const showNoResults = !isPending && data?.length === 0;
  const showRelevantSources = !isPending && data?.length > 0;

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      slotProps={{
        paper: {
          className: "news-sources-input-options-paper",
          sx: {
            width: anchorEl?.offsetWidth,
          },
        },
      }}
      disableAutoFocus
      disableEnforceFocus
    >
      <Box className="news-sources-input-options-container">
        {showRelevantSources ? (
          <Typography className="news-relevant-sources-title">
            {CheckValueLocale(
              isExcludedSources ? "relevant_websites" : "relevant_sources",
              "",
              {},
              intl,
            )}
          </Typography>
        ) : null}

        {isPending ? (
          <CustomSourcesLoading />
        ) : showNoResults ? (
          <NoResults
            handleOpenRequestVerificationModal={
              handleOpenRequestVerificationModal
            }
          />
        ) : (
          data?.map((option) => (
            <SourceOption
              key={option?.id}
              source={option}
              isSelected={isOptionSelected(option?.id, option?.link)}
              onSelect={onOptionSelect}
            />
          ))
        )}
      </Box>
    </Popover>
  );
};
