import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Box, Menu, MenuItem, Tooltip } from "@mui/material";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngry,
  faLaughBeam,
  faMeh,
} from "@fortawesome/pro-regular-svg-icons";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LucButton from "shared/lucButton/lucButton";

const SentimentsMenu = (props) => {
  const {
    btnId,
    tooltipText,
    options,
    optionsLoading,
    selectedSentiment,
    handleChange,
    loading,
    disabled,
    isOnlyView,
  } = props;

  const intl = useIntl();

  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpenMenu = (e) => {
    if (isOnlyView) return;
    setAnchorEl(e?.currentTarget);
  };
  const handleCloseMenu = () => setAnchorEl(null);
  const open = Boolean(anchorEl);

  const sentimentsArray = Object.keys(options)?.map((key) => ({
    name: key,
    id: options?.[key],
  }));

  const idsToNames = Object?.fromEntries(
    Object?.entries(options)?.map(([key, value]) => [value, key]),
  );

  const sentimentIcons = {
    positive: faLaughBeam,
    neutral: faMeh,
    negative: faAngry,
  };

  return (
    <Box>
      <Tooltip
        title={CheckValueLocale(
          tooltipText && !open ? tooltipText : "",
          "",
          {},
          intl,
        )}
        placement="top"
        arrow
      >
        <Box>
          <LucButton
            id={btnId}
            className={`sentiments-menu-btn themes-select-dropdown-btn ${
              isOnlyView ? "single-post-analysis" : ""
            }`}
            type="secondary"
            variant="flat"
            size="small"
            minWidth={60}
            aria-controls={open ? "fade-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            loading={loading}
            disabled={disabled}
            disableRipple={isOnlyView}
            onClick={handleOpenMenu}
            endIcon={
              !isOnlyView ? (
                <FontAwesomeIcon
                  className={`${idsToNames?.[selectedSentiment] ? idsToNames?.[selectedSentiment] : "caret-icon"}`}
                  icon={faCaretDown}
                  fixedWidth
                />
              ) : null
            }
          >
            {sentimentIcons?.[idsToNames?.[selectedSentiment]] !== undefined ? (
              <FontAwesomeIcon
                className={`smiliesStyle ${idsToNames?.[selectedSentiment] ? idsToNames?.[selectedSentiment] : ""}`}
                icon={sentimentIcons?.[idsToNames?.[selectedSentiment]]}
                fixedWidth
              />
            ) : (
              <HelpOutlineIcon
                className="smiliesStyle"
                style={{ color: "#64748B" }}
              />
            )}
          </LucButton>
        </Box>
      </Tooltip>
      <Menu
        id="sentiments-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: intl?.locale !== "ar" ? "left" : "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: intl?.locale !== "ar" ? "left" : "right",
        }}
      >
        {sentimentsArray?.map((sentiment, index) => {
          return (
            <MenuItem
              key={index}
              id={`sentiment-select-menu-item-${sentiment?.name}`}
              className={`sentiments-menu-item ${sentiment?.name}`}
              onClick={async () => {
                await handleChange(sentiment?.id); // this function returns from props
                handleCloseMenu();
              }}
            >
              <FontAwesomeIcon
                className={`smiliesStyle ${sentiment?.name ? sentiment?.name : ""}`}
                icon={sentimentIcons?.[sentiment?.name]}
                fixedWidth
              />
              {CheckValueLocale(sentiment?.name, "", {}, intl)}
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};

export default SentimentsMenu;
