import { CheckValueLocale } from "utils/helpers";
import { Avatar, Box, Skeleton, Tooltip } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import TitleBox from "../header/titleBox";
import "./notifications.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Navigation } from "swiper/modules";
import SingleTweetAnalysisIcon from "images/notification-icons/SingleTweetAnalysis.svg";
import NewsletterReportIcon from "images/notification-icons/NewsletterReport.svg";
import AlertHighVolumeIcon from "images/notification-icons/AlertHighVolume.svg";
import AlertViralTweetIcon from "images/notification-icons/AlertViralTweet.svg";
import AlertHighNegativeTweetIcon from "images/notification-icons/AlertHighNegativeTweet.svg";
import VerifiedAuthorAlertIcon from "images/notification-icons/VerifiedAuthorAlert.svg";
import InfluencerAlertIcon from "images/notification-icons/InfluencerAlert.svg";
import AlertHighReachIcon from "images/notification-icons/AlertHighReach.svg";
import HighVolumeReachIcon from "images/notification-icons/HighVolumeReach.svg";
import AlertNewPostIcon from "images/notification-icons/AlertNewPost.svg";
import PublishSurveyIcon from "images/notification-icons/notification_survey_icon.svg";
import ExcelExport from "images/notification-icons/exportExcel.svg";
import EnagementNewIcon from "images/notification-icons/enagement_new_icon.svg";
import EnagementCompleteIcon from "images/notification-icons/enagement_complete_icon.svg";
import AlertsFunctions from "pages/alerts/components/alertsFormFunctions/alertsFunctions";
import ViewDownloadComponent from "pages/notifications/list/components/viewDownloadComponent";
import { useLocation, useNavigate } from "react-router-dom";

SwiperCore.use([Navigation]);

const Notifications = (props) => {
  const { alertTypeLocale } = AlertsFunctions();
  const { loading, notifications, loadingDelay, setSnackBar } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const intl = useIntl();

  const dataSkeleton = [
    { id: "1" },
    { id: "2" },
    { id: "3" },
    { id: "4" },
    { id: "5" },
  ];
  const swiperSkeletonfun = () => {
    return (
      <Swiper
        navigation
        observer={true}
        spaceBetween={15}
        slidesPerView={3.6}
        modules={[Navigation]}
        key={intl.locale}
      >
        {dataSkeleton?.map((el) => {
          return (
            <SwiperSlide key={el?.id}>
              <Box className="skeleton-parant">
                <Box className="skeleton-head">
                  <Skeleton variant="circular" width="30px" height="30px">
                    <Avatar />
                  </Skeleton>
                  <Skeleton width="140px" height="13px"></Skeleton>
                </Box>
                <Box>
                  <Skeleton width="140px" height="13px"></Skeleton>
                  <Skeleton width="140px" height="13px"></Skeleton>
                  <Skeleton width="100px" height="13px"></Skeleton>
                </Box>
                <Skeleton width="100px" height="13px"></Skeleton>
              </Box>
            </SwiperSlide>
          );
        })}
      </Swiper>
    );
  };
  const showNotificationType = (status, isRead, productName) => {
    return (
      <>
        {["single_tweet_analysis", "single_tweet_analysis_v2"].includes(
          status,
        ) && <img src={SingleTweetAnalysisIcon} />}
        {status === "nb_report" && <img src={NewsletterReportIcon} />}
        {status === "Spikes" && <img src={AlertHighVolumeIcon} />}
        {(status === "CustomTweetsCount" || status === "Trending") && (
          <img src={AlertHighVolumeIcon} />
        )}
        {status === "High Volume Reach" && <img src={HighVolumeReachIcon} />}
        {(status === "AutoViralTweets" || status === "Viral Tweet") && (
          <img src={AlertViralTweetIcon} />
        )}
        {status === "CustomViralTweets" && <img src={AlertViralTweetIcon} />}
        {(status === "AutoNegativeTweetsCount" ||
          status === "Negative Posts") && (
          <img src={AlertHighNegativeTweetIcon} />
        )}
        {status === "CustomNegativeTweetsCount" && (
          <img src={AlertHighNegativeTweetIcon} />
        )}
        {(status === "VerifiedAuthor" || status === "Verified Author") && (
          <img src={VerifiedAuthorAlertIcon} />
        )}
        {(status === "Influence" || status === "Influencer") && (
          <img src={InfluencerAlertIcon} />
        )}
        {status === "AutoReachCount" && <img src={AlertHighReachIcon} />}
        {status === "CustomReachCount" && <img src={AlertHighReachIcon} />}
        {productName?.toLowerCase() !== "survey" &&
          productName?.toLowerCase() !== "engagements" &&
          (status === "NewPost" || status === "New Post") && (
            <img src={AlertNewPostIcon} />
          )}
        {productName?.toLowerCase() === "engagements" &&
          (status === "NewPost" || status === "New Post") && (
            <img src={EnagementNewIcon} />
          )}
        {productName?.toLowerCase() === "engagements" &&
          (status === "Completed Conversation" ||
            status === "CompletedConversation") && (
            <img src={EnagementCompleteIcon} />
          )}
        {productName?.toLowerCase() === "survey" && (
          <img src={PublishSurveyIcon} />
        )}
        {(productName?.toLowerCase() === "cdp" ||
          productName?.toLowerCase() === "x platform" ||
          productName?.toLowerCase() === "news&blogs") &&
          status == "excel_export" && <img src={ExcelExport} />}
      </>
    );
  };
  function handleMaxLength(text) {
    if (text?.length <= 10) {
      return text;
    }

    return (
      <Tooltip title={text} placement="top">
        {text?.substring(0, 10) + "..."}
      </Tooltip>
    );
  }
  const notificationType = (item) => {
    return (
      <Box className="notification-type-parent">
        <Box className="notification-head">
          <Box className={item?.is_read === 1 ? "type-td red-dot" : "type-td"}>
            {showNotificationType(
              item?.notification_type === "alert_v2"
                ? item?.alert_type
                : item?.notification_type,
              item?.is_read,
              item?.product_name,
            )}
          </Box>
          <Box className="notification-type">
            {item?.product_name?.toLowerCase() === "survey" &&
            item?.notification_type?.toLowerCase() !== "published_survey"
              ? CheckValueLocale(
                  "notification_new_response_survey",
                  "",
                  {},
                  intl,
                )
              : item?.notification_type == "alert_v2" &&
                  item?.product_name?.toLowerCase() === "engagements"
                ? alertTypeLocale(item?.alert_type, "engagements")
                : item?.notification_type == "alert_v2"
                  ? alertTypeLocale(item?.alert_type)
                  : CheckValueLocale(
                      `${item?.notification_type}`,
                      "",
                      {},
                      intl,
                    )}
          </Box>
        </Box>
        <Box>
          <Box className="notification-message">
            {showNotificationMessage(item, intl)}
          </Box>
        </Box>
      </Box>
    );
  };
  const showNotificationMessage = (item, intl) => {
    if (
      ["single_tweet_analysis", "single_tweet_analysis_v2"].includes(
        item?.notification_type,
      )
    ) {
      return (
        <FormattedMessage
          id="notification_message_single_tweet"
          values={{
            username: <b>[@{handleMaxLength(item?.screen_name)}]</b>,
          }}
        />
      );
    } else if (item?.notification_type === "nb_report") {
      return (
        <FormattedMessage
          id="notification_message_newsletter"
          values={{
            frequency: <b>[{item?.frequency}]</b>,
            name: <b>[{handleMaxLength(item?.name)}]</b>,
          }}
        />
      );
    } else if (item?.product_name?.toLowerCase() === "engagements") {
      let message =
        item?.alert_type === "NewPost" || item?.alert_type === "New Post"
          ? "notification_message_new_post_engagements"
          : item?.alert_type === "Negative Posts"
            ? "notification_message_negative_posts_engagements"
            : item?.alert_type === "Completed Conversation"
              ? "notification_message_completed_engagements"
              : "notification_message_new_post";

      return (
        <FormattedMessage
          id={message}
          values={{
            alert_name: <b>[ #{handleMaxLength(item?.alert_name)} ]</b>,
            monitor_name: <b>[ #{handleMaxLength(item?.monitor_name)} ]</b>,
          }}
        />
      );
    } else if (
      item?.product_name?.toLowerCase() !== "survey" &&
      (item?.alert_type === "NewPost" || item?.alert_type === "New Post")
    ) {
      return (
        <FormattedMessage
          id={
            item?.product_name?.toLowerCase() === "engagements"
              ? "notification_message_new_post_engagements"
              : "notification_message_new_post"
          }
          values={{
            alert_name: <b>[ #{handleMaxLength(item?.alert_name)} ]</b>,
            monitor_name: <b>[ #{handleMaxLength(item?.monitor_name)} ]</b>,
          }}
        />
      );
    } else if (
      item?.alert_type === "CustomTweetsCount" ||
      item?.alert_type === "Spikes" ||
      item?.alert_type === "Trending"
    ) {
      return (
        <FormattedMessage
          id="notification_message_high_volume"
          values={{
            percentage:
              item?.percentage > 0 ? Math.round(item?.percentage) : "0",
            alert_name: <b>[ #{handleMaxLength(item?.alert_name)} ]</b>,
          }}
        />
      );
    } else if (
      item?.alert_type === "AutoViralTweets" ||
      item?.alert_type === "CustomViralTweets" ||
      item?.alert_type === "Viral Tweet"
    ) {
      return (
        <FormattedMessage
          id="notification_message_viral_tweet"
          values={{
            alert_name: <b>[ #{handleMaxLength(item?.alert_name)} ]</b>,
            tweets_count: <b>{handleMaxLength(item?.tweets_count)}</b>,
          }}
        />
      );
    } else if (
      item?.alert_type === "AutoReachCount" ||
      item?.alert_type === "CustomReachCount" ||
      item?.alert_type === "High Volume Reach"
    ) {
      return (
        <FormattedMessage
          id="notification_message_high_reach"
          values={{
            alert_name: <b>[ #{handleMaxLength(item?.alert_name)} ]</b>,
            percentage:
              item?.percentage > 0 ? Math.round(item?.percentage) : "0",
          }}
        />
      );
    } else if (
      item?.alert_type === "CustomNegativeTweetsCount" ||
      item?.alert_type === "AutoNegativeTweetsCount" ||
      item?.alert_type === "Negative Posts"
    ) {
      return (
        <FormattedMessage
          id="notification_message_high_negative_tweet"
          values={{
            alert_name: <b>[ #{handleMaxLength(item?.alert_name)} ]</b>,
            percentage:
              item?.percentage > 0 ? Math.round(item?.percentage) : "0",
          }}
        />
      );
    } else if (
      item?.alert_type === "VerifiedAuthor" ||
      item?.alert_type === "Verified Author"
    ) {
      return (
        <FormattedMessage
          id="notification_message_verified_author"
          values={{
            alert_name: <b>[ #{handleMaxLength(item?.alert_name)} ]</b>,
            monitor_name: <b>[ #{handleMaxLength(item?.monitor_name)} ]</b>,
          }}
        />
      );
    } else if (
      item?.alert_type === "Influence" ||
      item?.alert_type === "Influencer"
    ) {
      return (
        <FormattedMessage
          id="notification_message_influence"
          values={{
            alert_name: <b>[ #{handleMaxLength(item?.alert_name)} ]</b>,
            monitor_name: <b>[ #{handleMaxLength(item?.monitor_name)} ]</b>,
          }}
        />
      );
    } else if (item?.notification_type === "excel_export") {
      return (
        <FormattedMessage
          id="notification_message_excel_export"
          values={{
            monitor_name: <b>[ #{handleMaxLength(item?.monitor_name)} ]</b>,
          }}
        />
      );
    } else if (item?.notification_type === "published_survey") {
      return (
        <FormattedMessage
          id="notification_message_published_survey"
          values={{
            monitor_name: <b>[{handleMaxLength(item?.monitor_name)}]</b>,
          }}
        />
      );
    } else if (item?.product_name?.toLowerCase() === "survey") {
      return (
        <FormattedMessage
          id="notification_message_new_response_survey"
          values={{
            monitor_name: <b>[{handleMaxLength(item?.monitor_name)}]</b>,
          }}
        />
      );
    } else {
      return item?.alert_name;
    }
  };

  return (
    <Box className="section-slide needs-attention">
      <TitleBox
        title={"home_notifications"}
        tooltipText={"updates_triggered_alerts"}
      />
      <Box className="slider-container">
        {loading || loadingDelay ? (
          swiperSkeletonfun()
        ) : (
          <Swiper
            navigation
            observer={true}
            spaceBetween={15}
            slidesPerView={3.6}
            modules={[Navigation]}
            key={intl.locale}
          >
            {notifications?.length ? (
              notifications?.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <Box className="item-slide-notifications">
                      {notificationType(item)}
                      <ViewDownloadComponent
                        item={item}
                        navigate={navigate}
                        intl={intl}
                        setSnackbar={setSnackBar}
                        isHomepage
                      />
                    </Box>
                  </SwiperSlide>
                );
              })
            ) : (
              <Box className="no-data-notifications">
                {CheckValueLocale("no_notifications", "", {}, intl)}
              </Box>
            )}
          </Swiper>
        )}
      </Box>
    </Box>
  );
};
export default Notifications;
