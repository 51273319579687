import { useIntl } from "react-intl";
import { Box, TextField } from "@mui/material";
import { CheckValueLocale, classNames } from "utils/helpers";
import PopupModal from "components/popupModal";
import "./whatsappNewAccPopup.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import { PhoneNumberInput } from "pages/settings/pages/userManagementPage/subPages/shardComponents/userForm/components/phoneNumberField/phoneNumberInput";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useAddWhatsappAccount } from "../../hooks/useAddWhatsappAccount";

const rexSpecialChar = /^[a-zA-Z0-9]+$/;
const addWhatsappAccountValidationSchema = Yup.object({
  phone_number: Yup.string()
    .required("required")
    .test("phone_number", "whatsapp_popup_number_validation", (value) => {
      return isValidPhoneNumber(value || ""); // value should be a string or it will throw an error
    }),
  account_name: Yup.string()
    .required("required")
    .min(6, "whatsapp_popup_name_min_max_validation")
    .max(150, "whatsapp_popup_name_min_max_validation")
    .matches(rexSpecialChar, "whatsapp_popup_name_validation"),
});

const WhatsappNewAccPopup = (props) => {
  const intl = useIntl();
  const { mutate: addWhatsappAccount, isPending } = useAddWhatsappAccount();

  const handleSubmit = (data) => {
    addWhatsappAccount(
      {
        ...data,
        phone_number: data.phone_number?.replace?.("+", ""),
      },
      {
        onSuccess: () => {
          // 1- show a snack bar that the account has been added successfully
          props?.setAddWhatsappUserSnackBar({
            openSnackBar: true,
            severity: "success",
            title: "",
            message: "add_new_whatsapp_account_popup_success",
          });
          // 2-call the getAuthorizedAccounts to get the new account that has been added
          props?.getAuthorizedAccounts();
          props?.setOpenWhatsAppPopup(false);
        },
        onError: (err) => {
          const accessErrMsg =
            err?.errorMsg?.response?.data?.exception?.message ||
            err?.errorMsg?.response?.data?.message;
          const errorMessages = Array.isArray(accessErrMsg)
            ? accessErrMsg
            : [accessErrMsg];

          errorMessages?.forEach((message) => {
            switch (message) {
              case "ACCOUNT_NAME_IS_FOUND_in_DB":
                formik?.setFieldError(
                  "account_name",
                  "whatsapp_popup_name_found_db_validation",
                );
                break;
              case "PHONE_NUMBER_FOUND":
                formik?.setFieldError(
                  "phone_number",
                  "whatsapp_popup_number_found_db_validation",
                );
                break;
              case "Bot Already Exists":
              case "ACCOUNT_NAME_IS_FOUND":
                formik?.setFieldError(
                  "account_name",
                  "whatsapp_popup_name_found_system_validation",
                );
                break;
              case "Invalid phone number format":
                formik?.setFieldError(
                  "phone_number",
                  "whatsapp_popup_number_validation",
                );
                break;
              case "App name should be between 6 to 150 charecters in length":
                formik?.setFieldError(
                  "account_name",
                  "whatsapp_popup_name_min_max_validation",
                );
                break;
              case "Invalid characters used in app name":
                formik?.setFieldError(
                  "account_name",
                  "whatsapp_popup_name_validation",
                );
                break;
              case "Too Many Requests":
                props?.setAddWhatsappUserSnackBar({
                  openSnackBar: true,
                  severity: "error",
                  title: "add_new_whatsapp_popup_limit_failed_title",
                  message: "add_new_whatsapp_popup_limit_failed_msg",
                });
                break;
              default:
                props?.setAddWhatsappUserSnackBar({
                  openSnackBar: true,
                  severity: "error",
                  title: "add_new_whatsapp_account_popup_failed_title",
                  message: "add_new_whatsapp_account_popup_failed",
                });
                break;
            }
          });
        },
      },
    );
  };

  const formik = useFormik({
    initialValues: {
      account_name: "",
      phone_number: "",
    },
    validationSchema: addWhatsappAccountValidationSchema,
    onSubmit: handleSubmit,
  });

  const handleCloseModal = () => {
    props?.setOpenWhatsAppPopup(false);
    formik?.resetForm();
  };

  const nameError =
    formik?.touched?.account_name &&
    Boolean(formik?.errors?.account_name) &&
    formik?.errors?.account_name !== "required"
      ? formik?.errors?.account_name
      : null;
  const phoneError =
    formik?.touched?.phone_number &&
    Boolean(formik?.errors?.phone_number) &&
    formik?.errors?.phone_number !== "required"
      ? formik?.errors?.phone_number
      : null;
  return (
    <>
      <PopupModal
        title={CheckValueLocale(
          "add_whatsapp_account_popup_title",
          "",
          {},
          intl,
        )}
        body={
          <Box className="add-whatsapp-account-form">
            <Box className="add-whatsapp-account-name">
              <RequireLabel
                label={CheckValueLocale(
                  "add_whatsapp_account_popup_name",
                  "",
                  {},
                  intl,
                )}
              />
              <TextField
                id="whatsapp-new-account-name"
                margin="none"
                size="small"
                fullWidth
                className="add-user-field"
                variant="outlined"
                name="account_name"
                placeholder={CheckValueLocale(
                  "type_whatsapp_name",
                  "",
                  {},
                  intl,
                )}
                value={formik?.values?.account_name}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                error={!!nameError}
              />
              <ErrorLabel label={nameError} />
            </Box>
            <Box
              className={classNames(
                "add-whatsapp-account-phone",
                phoneError ? "whatsapp-auth-error" : "",
              )}
            >
              <RequireLabel
                label={CheckValueLocale(
                  "add_whatsapp_account_popup_phone",
                  "",
                  {},
                  intl,
                )}
              />
              <PhoneNumberInput
                name="phone_number"
                placeholder={"0"}
                onBlur={formik?.handleBlur}
                onChangeHandler={(value) => {
                  formik?.setFieldValue("phone_number", value);
                }}
              />
              <ErrorLabel label={phoneError} />
            </Box>
          </Box>
        }
        rightBtn={CheckValueLocale("luci_add", "", {}, intl)}
        leftBtn={CheckValueLocale("cancel", "", {}, intl)}
        open={props?.openWhatsAppPopup}
        accept={formik?.handleSubmit}
        close={handleCloseModal}
        disabled={
          formik?.isValidating ||
          !formik?.isValid ||
          isPending ||
          formik?.values?.account_name === "" ||
          formik?.values?.phone_number === ""
        }
        isRightBtnLoading={isPending}
        disableLeftBtn={isPending}
        leftBtnId="whatsapp-add-new-acc-popup-left-btn"
        rightBtnId="whatsapp-add-new-acc-popup-right-btn"
        classeName="whatsapp-auth-modal"
        addClasses="whatsapp-auth-buttons"
      />
    </>
  );
};

const RequireLabel = ({ label }) => {
  const intl = useIntl();
  return (
    <Box className="add-whatsapp-account-label">
      {CheckValueLocale(label, "", {}, intl)}
      <span className="required-astric">*</span>
    </Box>
  );
};

const ErrorLabel = ({ label }) => {
  const intl = useIntl();
  if (!label) return null;

  return (
    <Box className="whatsapp-auth-error-label">
      {CheckValueLocale(label, "", {}, intl)}
    </Box>
  );
};

export default WhatsappNewAccPopup;
