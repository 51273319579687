import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { Box, FormControl, TextField, Typography } from "@mui/material";

const AddDomainModalBody = ({ formik }) => {
  const intl = useIntl();

  return (
    <Box className="add-domain-modal-body">
      <Typography variant="h5" className="add-domain-description">
        {CheckValueLocale("embedded_survey_add_your_domain_desc", "", {}, intl)}
      </Typography>
      <FormControl fullWidth size="small" className="add-domain-form-control">
        <Typography className="add-domain-input-label">
          {CheckValueLocale("embedded_survey_domain_name", "", {}, intl)}
          <Box component="span" className="required">
            *
          </Box>
        </Typography>
        <TextField
          id="survey-builder-embedded-domain-name-field"
          name="domain"
          placeholder={CheckValueLocale(
            "embedded_survey_domain_name_placeholder",
            "",
            {},
            intl,
          )}
          value={formik?.values?.domain}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          error={
            Boolean(formik?.errors?.domain) && Boolean(formik?.touched?.domain)
          }
          helperText={
            Boolean(formik?.errors?.domain) && Boolean(formik?.touched?.domain)
              ? CheckValueLocale(formik?.errors?.domain, "", {}, intl)
              : ""
          }
          size="small"
          fullWidth
        />
      </FormControl>
    </Box>
  );
};

export default AddDomainModalBody;
