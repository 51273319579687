import moment from "moment";
import { isEmptyArray } from "utils/helpers";
import {
  handleAuthorActivity,
  handleXlsData,
  handleSortedCase,
} from "../excelCommonFn";
import { handleShowRemainingTime } from "./inbox";
import { displayRemainingTime } from "pages/engagements/components/engagementAnalytics/sharedFunctions/datesUtils";

export const engagementAnalyticsAgents = (response, lang) => {
  if (isEmptyArray(response)) {
    return [];
  } else {
    let agentPerformance = [],
      engagAgentsOverView = [],
      agentsDistribution = [],
      hitsAndMisses = [],
      agentInboxPerformance = [],
      csatScore = [],
      csatOverTime = [],
      agentCsatData = [],
      agentStatusOverView = [],
      agentStatusSummary = [],
      allSheetData = [];
    const typesMapper = {
      time_to_complete: "avgs_time_to_complete",
      next_response_time: "avgs_next_response_time",
      first_response_time: "avgs_first_response_time",
    };

    const agentStatusesMapper = {
      total_available_time: "excel_key_available_time",
      total_busy_time: "excel_key_busy_time",
      total_meeting_time: "excel_key_meeting_time",
      total_break_time: "excel_key_break_time",
      total_unavailable_time: "excel_key_unavailable_time",
    };

    Object.entries(response)?.forEach(([key, value]) => {
      switch (key) {
        case "agentInboxPerformance":
          value?.assigned?.map((item, i) => {
            agentInboxPerformance.push({
              [lang.date_quick]: moment
                .unix(item?.name)
                .utc()
                .format("YYYY-MM-DD"),
              [lang.assigned]: item?.value,
              [lang.completed]: value?.completed?.[i]?.value,
            });
          });
          allSheetData.push([
            agentInboxPerformance,
            lang?.agent_inbox_performance,
          ]);
          break;
        case "agentPerformance":
          const { data: agentsStats, agents } = value;
          agentPerformance = handleAgentPerformanceExcelPage(
            agentsStats,
            agents,
            lang,
          );
          allSheetData.push([
            agentPerformance,
            lang?.agent_tab_performance_title,
          ]);
          break;
        case "engagOverView":
          Object.keys(value)?.map((i) => {
            let overviewItemLang =
              i == "total_reopened" ? lang["reopens_engag"] : lang[i];
            let num = value[i];
            if (i === "avg_engag") {
              num = handleShowRemainingTime(
                displayRemainingTime(+(value?.[i] || 0)),
                lang,
              );
            }
            engagAgentsOverView.push({
              [lang.engag_metric]: overviewItemLang,
              [lang.xlsx_value]: num ? num : 0,
            });
          });
          allSheetData.push([
            engagAgentsOverView,
            lang.xlsx_engag_agents_overview,
          ]);
          break;
        case "agentsDistribution":
          value?.map((i) => {
            agentsDistribution?.push({
              [lang?.agents_disribution_xlsx_name]: i?.name,
              [lang?.agents_disribution_xlsx_val]: i?.value,
            });
          });
          allSheetData.push([agentsDistribution, lang?.agents_disribution]);
          break;

        case "hitsAndMisses":
          Object.entries(value)?.map(([key, i]) => {
            const type = typesMapper[key];
            hitsAndMisses.push({
              [lang.slas_hits_and_misses_table_type]: lang[type]
                ? lang[type]
                : type,
              [lang.avg_slas_table_column]: handleShowRemainingTime(
                displayRemainingTime(i?.avgTime ? i?.avgTime : 0),
                lang,
              ),
              [lang.hits]: i?.hits,
              [lang.misses]: i?.misses,
            });
          });
          allSheetData.push([
            hitsAndMisses,
            lang?.hits_misses_activity_sheet_title,
          ]);
          break;

        case "csatScore":
          value?.map((i) => {
            csatScore?.push({
              [""]: lang[i?.name],
              [lang?.response]: i?.value,
            });
          });
          allSheetData.push([csatScore, lang?.agents_csat_score]);
          break;

        case "csatOverTime":
          value?.data?.map((item) => {
            csatOverTime.push({
              [lang.date]: moment.unix(item?.name).utc().format("YYYY-MM-DD"),
              [lang[value?.pieChart?.[0]?.name]]: item?.value?.[0],
              [lang[value?.pieChart?.[1]?.name]]: item?.value?.[1],
              [lang[value?.pieChart?.[2]?.name]]: item?.value?.[2],
              [lang[value?.pieChart?.[3]?.name]]: item?.value?.[3],
              [lang[value?.pieChart?.[4]?.name]]: item?.value?.[4],
            });
          });
          allSheetData.push([csatOverTime, lang?.agents_csat_overtime]);
          break;

        case "agentCsatData":
          value?.map((item) => {
            agentCsatData.push({
              [lang.data_time]: moment
                .unix(item?.created_at)
                .utc()
                .format("YYYY-MM-DD hh:mm"),
              [lang.engegements_id]: item?.engagement_id,
              [lang.pdf_data_sources]: lang[item?.itemData?.data_source],
              [lang.customer_response]: item?.itemData?.message,
              [lang.engag_agents]: item?.itemData?.agent,
              [lang.csats_score]: lang[item?.itemData?.csat_score],
              [lang.routing_table_team]: item?.itemData?.team,
              [lang.customer_name]: item?.user_name,
              [lang.customer_handler]: item?.screen_name,
            });
          });
          allSheetData.push([agentCsatData, lang?.agent_csat_feedback_xlsx]);
          break;
        case "agentStatusOverview":
          Object.entries(value)?.map(([key, i]) => {
            let overviewAgentStatusLang = lang[agentStatusesMapper[key]];
            let totalNum = i;
            let avgNum = i;
            let count = value["count"];
            let daysCount = value["numOfDays"];
            totalNum = handleShowRemainingTime(
              displayRemainingTime(+(i || 0)),
              lang,
            );
            avgNum = handleShowRemainingTime(
              displayRemainingTime(+(i / (count * daysCount) || 0)),
              lang,
            );

            if (key !== "count" && key !== "numOfDays")
              agentStatusOverView.push({
                [lang.engag_metric]: overviewAgentStatusLang,
                [lang.xlsx_agent_status_avg_time]: avgNum ? avgNum : 0,
                [lang.xlsx_agent_status_total_time]: totalNum ? totalNum : 0,
              });
          });
          allSheetData.push([
            agentStatusOverView,
            lang.xlsx_engag_agents_status_overview,
          ]);
          break;
        case "agentStatusSummary":
          const { data: agentsStatusSummary, agents: users } = value;
          agentStatusSummary = handleAgentStatusSummaryExcelPage(
            agentsStatusSummary,
            users,
            lang,
          );
          allSheetData.push([
            agentStatusSummary,
            lang?.agent_status_summary_title,
          ]);
          break;
        default:
          break;
      }
    });
    return handleXlsData(allSheetData);
  }
};

const handleAgentPerformanceExcelPage = (agentsPerformance, agents, lang) => {
  const data = [];
  agentsPerformance?.map((item, i) => {
    const agent = agents?.find((agent) => +agent?.id === +item?.agent_id);
    // if agent is not found we will skip this iteration since we can't find the agent
    if (!agent) return;
    data.push({
      [lang?.agent_performance_grouped_agents_column]: agent?.name,
      [lang?.agent_performance_frt]: handleShowRemainingTime(item?.frt, lang),
      [lang?.agent_performance_nrt]: handleShowRemainingTime(item?.nrt, lang),
      [lang?.agent_performance_ttc]: handleShowRemainingTime(item?.ttc, lang),
      [lang?.agent_performance_hits]: item?.hits_count,
      [lang?.agent_performance_misses]: item?.misses_count,
      [lang?.agent_performance_compliance_rate]: item?.compliance_rate,
      [lang?.avg_engag]: handleShowRemainingTime(item?.avg_handle, lang),
      [lang?.agent_performance_manual_assign]: item?.manual_assign,
      [lang?.agent_performance_auto_assign]: item?.auto_assign,
      [lang?.agent_performance_total_assignments]: item?.total_assignments,
      [lang?.csats_score]: `${(!item?.total_satisfied_responses || !item?.total_csat_responses ? 0 : (item?.total_satisfied_responses / item?.total_csat_responses) * 100)?.toFixed(0)}%`,
      [lang?.survey_sent]: item?.surveys_sent_count,
      [lang?.survey_response]: item?.surveys_responses_count,
    });
  });
  return data;
};

const handleAgentStatusSummaryExcelPage = (
  agentsStatusSummary,
  agents,
  lang,
) => {
  const data = [];
  agentsStatusSummary?.map((item, i) => {
    const agent = agents?.find((agent) => +agent?.id === +item?.user_id);
    // if agent is not found we will skip this iteration since we can't find the agent
    if (!agent) return;
    data.push({
      [lang?.agent_performance_grouped_agents_column]: agent?.name,
      [lang?.total_available_time]: handleShowRemainingTime(
        displayRemainingTime(item?.available),
        lang,
      ),
      [lang?.total_busy_time]: handleShowRemainingTime(
        displayRemainingTime(item?.busy),
        lang,
      ),
      [lang?.total_meeting_time]: handleShowRemainingTime(
        displayRemainingTime(item?.meeting),
        lang,
      ),
      [lang?.total_break_time]: handleShowRemainingTime(
        displayRemainingTime(item?.break),
        lang,
      ),
      [lang?.total_unavailable_time]: handleShowRemainingTime(
        displayRemainingTime(item?.unavailable),
        lang,
      ),
    });
  });
  return data;
};
