import "./main.scss";
import { useState, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import SideBar from "./sideBar/sideBar";
import { EngagementAnalyticsHeader } from "./components/engagementAnalyticsHeader";
import moment from "moment";
import { getActiveParams } from "shared/general-filter/activeFilters/activeFilters";
import EngagementsController from "services/controllers/engagementsController";
import mqttRabbitMQResponce from "services/controllers/mqttRabbitMQResponce";
import InboxTab from "./contant/tabs/inboxTab";
import SLAsTab from "./contant/tabs/slasTab";
import AgentsTab from "./contant/tabs/agentsTab";
import TeamsTab from "./contant/tabs/teamsTab";
import InsightsTab from "./contant/tabs/insightsTab";
import FiltersController from "services/controllers/filtersController";
import SetupFlow from "../setup-flow/setupFlow";
import { handlelUserRoles, syncUnixToUTC, isEmptyObj } from "utils/helpers";
import Footer from "shared/footer/Footer";
import Header from "./contant/header";
import { sidebarOptionsMapper } from "shared/customDateRangePicker/config/sidebarOptions";

const tabsArr = ["inbox", "slas", "agents", "teams", "Insights"];
const allowedTabs = ["inbox", "slas", "agents"];

const tabsComponents = {
  inbox: InboxTab,
  slas: SLAsTab,
  agents: AgentsTab,
  teams: TeamsTab,
  Insights: InsightsTab,
};

const EngagementAnalytics = () => {
  const intl = useIntl();
  const location = useLocation();

  const navigate = useNavigate();

  const mqttConnectionClosed = useRef(false);
  const clientMQTT = useRef();
  // isInitialLoading used for showing a loader when the user open the tabs for the first time
  // it's setted to true when the user open the tab and setted to false when the data is loaded
  const [isInitialLoading, setIsInitialLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState("");
  const [filterFields, setFilterFields] = useState([]);
  const [resetAllFields, setResetAllFields] = useState(0);
  const [startDate, setStartDate] = useState(moment().subtract(6, "d").unix());
  const [endDate, setEndDate] = useState(moment().unix());
  const [filterParams, setFilterParams] = useState({});
  const [preLoaderTrackerDataSources, setPreLoaderTrackerDataSources] =
    useState([]);
  const [assignActiveFilters, setAssignActiveFilters] = useState({});
  const [pickerStateApply, setPickerStateApply] = useState(
    sidebarOptionsMapper.lastsevendays,
  );
  const [response, setResponse] = useState({});
  const [openSetupFlow, setOpenSetupFlow] = useState(false);
  const [isDoneSetup, setIsDoneSetup] = useState(true);
  const [querySendedData, setQuerySendedData] = useState({});

  const dateRangePickerParamsRef = useRef();
  const [deploymentDate, setDeploymentDate] = useState(null);
  // ddl filter
  const selectedOptions = useRef({});
  const { hasStartTime, hasEndTime } = dateRangePickerParamsRef?.current || {};
  const hasAppliedTimeFilter = hasStartTime || hasEndTime;

  const handleSeletedOptions = (key, value) => {
    if (!value) {
      delete selectedOptions.current[key];
    } else {
      selectedOptions.current[key] = value;
    }
    applyFilter(startDate, endDate, filterParams, 1, true);
  };

  const handleClearFilterParams = () => {
    setFilterParams({});
  };
  const getFiltersFileds = () => {
    FiltersController.getEngagementAnalyticsFiltersFields(
      window?.localStorage?.engagements_id,
      selectedTab,
    ).then((data) => {
      if (!data?.errorMsg && data?.data) {
        setFilterFields(data?.data);
      }
    });
  };
  const applyFilter = (
    startDate,
    endDate,
    activeFilterParams,
    pageNo,
    isAppliedFilter,
    isInitialLoad = false,
  ) => {
    setQuerySendedData({});

    setStartDate(startDate);
    setEndDate(endDate);
    if (!selectedTab) return;

    let selectedFilterParams = !isAppliedFilter
      ? assignActiveFilters
      : activeFilterParams !== undefined
        ? activeFilterParams
        : filterParams;

    getCredentials(startDate, endDate, selectedFilterParams, isInitialLoad);
    setAssignActiveFilters(selectedFilterParams);
    setResetAllFields((prev) => prev + 1);
  };

  const getCredentials = (startDate, endDate, filters, isInitialLoad) => {
    if (isInitialLoad) setIsInitialLoading(true);

    const { startTimeUTC, endTimeUTC, formattedSidebarLabel } =
      dateRangePickerParamsRef.current || {};
    const sidebarLabelOption = formattedSidebarLabel || pickerStateApply;

    setPickerStateApply(sidebarLabelOption?.split(",")?.[0]?.replace(" ", "")); // to remove the space from so that the excel does't crash (31 characters limit)

    let queryData = {
      product_id: window?.localStorage?.engagements_id,
      start_date: startTimeUTC || syncUnixToUTC(startDate, "start"),
      end_date: endTimeUTC || syncUnixToUTC(endDate, "end"),
      monitors: filters?.engagement_analytics_monitors?.join(",") || undefined,
      filters: JSON.stringify({
        data_sources: filters?.engagement_data_sources?.join(",") || undefined,
        engagement_types: filters?.engagement_type?.join(",") || undefined,
        routings_ids: filters?.engagement_routing?.join(",") || undefined,
        tags_ids:
          selectedTab === "inbox"
            ? filterParams?.engagement_tags?.includes("Untagged")
              ? ""
              : filters?.engagement_tags?.join(",") || undefined
            : undefined,
        exact_match:
          selectedTab === "inbox"
            ? filterParams?.exact_match || false
            : undefined,
        untagged_engagements:
          selectedTab === "inbox"
            ? filterParams?.engagement_tags?.includes("Untagged") || false
            : undefined,
        ...selectedOptions.current,
      }),
    };
    setQuerySendedData({
      ...queryData,
      filters: {
        data_sources: filters?.engagement_data_sources?.join(",") || undefined,
        engagement_types: filters?.engagement_type?.join(",") || undefined,
        routings_ids: filters?.engagement_routing?.join(",") || undefined,
        ...selectedOptions.current,
      },
    });
    EngagementsController.getEngagementAnalyticsData(selectedTab, {
      ...queryData,
    }).then((data) => {
      if (!data?.errorMsg && data?.data) {
        setPreLoaderTrackerDataSources(data?.data?.data_sources);
        setResponse({});
        setDeploymentDate(data?.data?.deployment_date);
        mqttRabbitMQResponce(clientMQTT, data?.data, setResponse);
      } else {
        mqttConnectionClosed.current = true;
      }
      if (isInitialLoad) setIsInitialLoading(false);
    });
  };

  useEffect(() => {
    // reset the selected options when the tab is changed
    selectedOptions.current = {};
    if (selectedTab) {
      setQuerySendedData({});
      applyFilter(startDate, endDate, filterParams, 1, undefined, true);
    }
    if (selectedTab !== "") getFiltersFileds();
  }, [selectedTab, intl.locale]);

  useEffect(() => {
    let tabsName = allowedTabs?.filter(
      (item) => `/engagements/analytics/${item}` == location.pathname,
    );
    if (tabsName?.length) {
      setQuerySendedData({});
      setSelectedTab(tabsName[0]);
    } else {
      navigate(`/engagements/analytics/inbox`);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (handlelUserRoles("ENGAGEMENTS", "ENGAGEMENT_SETTINGS"))
      EngagementsController.getSavedMonitors(
        window?.localStorage?.engagements_id,
      ).then((data) => {
        setIsDoneSetup(data?.data?.engagements_setuped);
      });
  }, []);

  // this handle open popup to redirect to engagement setup
  useEffect(() => {
    if (!isDoneSetup) {
      setOpenSetupFlow(true);
    }
  }, [isDoneSetup]);

  const SelectedTabComponent = tabsComponents[selectedTab];
  let noFiltersHasBeenApplied = isEmptyObj(assignActiveFilters);
  return (
    <Box className={"engag-analytics-main"}>
      <EngagementAnalyticsHeader
        filterFields={filterFields}
        applyFilter={applyFilter}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        assignActiveFilters={assignActiveFilters}
        setAssignActiveFilters={setAssignActiveFilters}
        handleClearFilterParams={handleClearFilterParams}
        getFiltersFileds={getFiltersFileds}
        allActiveFilters={getActiveParams(assignActiveFilters, filterFields)}
        getSelectedDate={setPickerStateApply}
        pickerStateApply={pickerStateApply}
        selectedTab={selectedTab}
        dateRangePickerParamsRef={dateRangePickerParamsRef}
        isEngagmentAnalyticsPage
      />
      <Box className={"analytics-main-contant"}>
        <SideBar selectedTab={selectedTab} tabs={allowedTabs} />
        <Box className="engag-analytics-contant">
          {SelectedTabComponent ? (
            <>
              <SelectedTabComponent
                response={response}
                selectedTab={selectedTab}
                preLoaderTrackerDataSources={preLoaderTrackerDataSources}
                isLoading={isInitialLoading}
                resetAllFields={resetAllFields}
                currDate={pickerStateApply}
                startDate={startDate}
                endDate={endDate}
                handleSeletedOptions={handleSeletedOptions}
                querySendedData={querySendedData}
                hasAppliedTimeFilter={hasAppliedTimeFilter}
                deploymentDate={deploymentDate}
                noFiltersHasBeenApplied={noFiltersHasBeenApplied}
              />
            </>
          ) : null}
          {/* Main App Footer as it is excluded from App.js */}
          <Footer isEngagementAnalytics={true} />
        </Box>
      </Box>
      {openSetupFlow &&
      handlelUserRoles("ENGAGEMENTS", "ENGAGEMENT_SETTINGS") &&
      (handlelUserRoles("ENGAGEMENT_ACCESS", "FULL_ACCESS") ||
        handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX") ||
        handlelUserRoles("ENGAGEMENT_ACCESS", "PUBLISH") ||
        handlelUserRoles("ENGAGEMENT_ACCESS", "ENGAGEMENT_ANALYTICS")) ? (
        <SetupFlow
          setClose={() => setOpenSetupFlow(false)}
          isDoneSetup={isDoneSetup}
        />
      ) : null}
    </Box>
  );
};

export default EngagementAnalytics;
