import { Tooltip } from "@mui/material";
import profileImg from "images/engagements-images/profileImg.png";
import "./profileUser.scss";
import {
  CheckValueLocale,
  classNames,
  extractDisplayInfoFromDataSource,
  isArabic,
} from "utils/helpers";
import { useIntl } from "react-intl";
import followers from "images/engagements-images/followers.svg";
import total from "images/engagements-images/total.svg";
import sentiment from "images/engagements-images/sentiment.svg";
import AnonymousUser from "./anonymousUser";
import ProfileLoading from "./profileLoading";
import TruncatedTooltip from "shared/truncatedTooltip/truncatedTooltip";

const ProfileUser = ({
  fetchProfileData,
  profileSideBarData,
  showTotalEngagementsHandler,
  selectedCard,
}) => {
  const intl = useIntl();
  const profileData = profileSideBarData?.profile_data;
  const userData = profileData?.user_data;

  const handleImageError = (event) => {
    event.target.src = profileImg;
  };
  const isTwitter = selectedCard?.data_source?.toLowerCase?.() === "twitter";
  const isInstagram =
    selectedCard?.data_source?.toLowerCase?.() === "instagram";
  const isWhatsapp = selectedCard?.data_source?.toLowerCase?.() === "whatsapp";
  const isDM = selectedCard?.isDm;
  const isRTL = intl?.locale === "ar";
  const { profilePicture, displayName, subName } =
    extractDisplayInfoFromDataSource(selectedCard, {
      displayName: selectedCard?.user_name
        ? isDM && (isTwitter || isInstagram)
          ? selectedCard?.name
          : selectedCard?.user_name
        : "user",
      subName:
        isTwitter || isInstagram
          ? isDM
            ? selectedCard?.user_name
            : selectedCard?.screen_name
          : null,
      profilePicture: userData?.profile_image
        ? userData?.profile_image
        : profileImg,
    });
  return (
    <div className="profile-dropdown-box">
      {!fetchProfileData ? (
        profileData?.length !== 0 ? (
          <>
            <div className="profile-dropdown-heading">
              <div className="profile-dropdown-img">
                <img
                  src={profilePicture || profileImg}
                  onError={handleImageError}
                />
              </div>
              <div className="profile-dropdown-text">
                <TruncatedTooltip
                  title={displayName}
                  placement="top"
                  className={classNames(
                    "profile-name",
                    isArabic(displayName) ? "arabic-name" : "",
                  )}
                  tooltipClassName="engagement-profile-dropdown-name-tooltip"
                />
                {(isTwitter || (isInstagram && isDM) || isWhatsapp) &&
                subName ? (
                  <span className="profile-sub-name">
                    {isWhatsapp
                      ? subName
                      : isRTL
                        ? `${subName}@`
                        : `@${subName}`}
                  </span>
                ) : null}
              </div>
            </div>
            <div className="profile-dropdown-content">
              {isWhatsapp ? null : (
                <div className="content-box">
                  <span className="title-box">
                    <img src={followers} />
                    {CheckValueLocale(
                      "followers_engagements_profile",
                      "",
                      {},
                      intl,
                    )}
                    :
                  </span>
                  <span className="value-box">
                    {selectedCard?.followers_count ? (
                      <>
                        {+selectedCard?.followers_count >= 1000
                          ? `${
                              (
                                +selectedCard?.followers_count / 1000
                              ).toFixed() > 1000
                                ? (
                                    (
                                      +selectedCard?.followers_count / 1000
                                    ).toFixed() / 1000
                                  ).toFixed() + "m "
                                : (
                                    +selectedCard?.followers_count / 1000
                                  ).toFixed() + "k "
                            }`
                          : `${selectedCard?.followers_count} `}
                      </>
                    ) : (
                      "-"
                    )}
                  </span>
                </div>
              )}
              <div className="content-box">
                <Tooltip
                  title={
                    <span className="total-tooltip">
                      {CheckValueLocale(
                        "total_engagements_profile_hint",
                        "",
                        {},
                        intl,
                      )}
                    </span>
                  }
                  arrow
                  placement={
                    window?.localStorage?.lang === "en"
                      ? "left-start"
                      : "right-start"
                  }
                >
                  <span className="title-box">
                    <img src={total} />
                    {CheckValueLocale(
                      "total_engagements_profile",
                      "",
                      {},
                      intl,
                    )}
                    :
                  </span>
                </Tooltip>
                <span
                  className="value-box show-total"
                  onClick={showTotalEngagementsHandler}
                >
                  {CheckValueLocale("show", "", {}, intl)}
                </span>
              </div>
              {profileData?.sentiment && !isWhatsapp ? (
                <div className="content-box">
                  <span className="title-box">
                    <img src={sentiment} />
                    {CheckValueLocale(
                      "sentiment_engagements_profile",
                      "",
                      {},
                      intl,
                    )}
                    :
                  </span>
                  <span className="value-box">
                    {profileData?.sentiment?.length > 0
                      ? CheckValueLocale(profileData?.sentiment, "", {}, intl)
                      : "-"}
                  </span>
                </div>
              ) : null}
            </div>
          </>
        ) : (
          <AnonymousUser />
        )
      ) : (
        <ProfileLoading />
      )}
    </div>
  );
};

export default ProfileUser;
