import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CreateMonitorServices } from "services/api/createMonitorServices";

export const useEditMonitorInfo = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ monitor_id, queryData }) => {
      return CreateMonitorServices.editMonitorInfo(monitor_id, queryData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["monitors"],
      });
      queryClient.invalidateQueries({
        queryKey: ["hashtagsLimit"],
      });
      queryClient.invalidateQueries({
        queryKey: ["topicsUsage"],
      });
    },
  });
};
