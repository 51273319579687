import { useState } from "react";
import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import SectionHeader from "./sectionHeader";
import SectionFooter from "./sectionFooter";
import SurveyBuilderQuestion from "../questions/surveyBuilderQuestion";
import EmptyEmbeddedSection from "../../embeddedEditor/components/emptyEmbeddedSection/emptyEmbeddedSection";
import AddQuestionsToggle from "../../embeddedEditor/components/addQuestionsToggle/addQuestionsToggle";
import "./style.scss";

const SectionContainer = (props) => {
  const {
    surveySections,
    setSurveySections,
    filteredSections,
    section,
    sectionIndex,
    handleQuestionTitle,
    handleQuestionDesc,
    handleSectionTitle,
    sqIndex,
    ssIndex,
    setSaveChangesFlag,
    emptySection,
    handleCardClick,
    handleChangeProceed,
    proceedError,
    afterSecError,
    secTitleErr,
    handleSectionDir,
    surveyData,
    isEmbedded,
    handleAddQuestion,
    scrollToIdFunc,
    hideSecHeader,
    previewMode,
    setErrorForm,
  } = props;

  const [addQuestionExpanded, setAddQuestionExpanded] = useState(false);

  let directionSection = filteredSections?.[sectionIndex]?.text_direction;
  let surveyDirection = surveyData?.text_direction;

  const intl = useIntl();

  let sectionSurveyDirection =
    filteredSections?.length <= 1
      ? surveyDirection
      : directionSection
        ? directionSection
        : surveyDirection
          ? surveyDirection
          : intl?.locale == "en"
            ? "ltr"
            : "rtl";

  const sectionQuestions = section?.questions?.filter(
    (question) => question?.is_deleted !== true,
  );

  return (
    <Box
      className={`section-container section-direction-${sectionSurveyDirection}`}
    >
      {filteredSections?.length > 1 && !hideSecHeader ? (
        <SectionHeader
          section={section}
          filteredSections={filteredSections}
          sectionIndex={sectionIndex}
          emptySection={emptySection}
          secTitleErr={secTitleErr}
          handleSectionTitle={handleSectionTitle}
          handleSectionDir={handleSectionDir}
          hideNumber={isEmbedded}
          previewMode={previewMode}
        />
      ) : null}

      {sectionQuestions?.length
        ? sectionQuestions
            ?.filter((question) =>
              previewMode &&
              question?.question?.type === "social_media" &&
              !question?.question?.constraints?.platforms?.length
                ? false
                : true,
            )
            ?.map((question, index) => {
              return (
                <SurveyBuilderQuestion
                  {...props}
                  key={`${sectionIndex}__${index}`}
                  question={question}
                  questionIndex={index}
                  sectionIndex={sectionIndex}
                  handleCardClick={handleCardClick}
                  handleQuestionTitle={handleQuestionTitle}
                  handleQuestionDesc={handleQuestionDesc}
                  isEmbedded={isEmbedded}
                  filteredSections={filteredSections}
                  setSurveySections={setSurveySections}
                  surveySections={surveySections}
                  setSaveChangesFlag={setSaveChangesFlag}
                  proceedError={proceedError}
                  ssIndex={ssIndex}
                  sqIndex={sqIndex}
                  handleChangeProceed={handleChangeProceed}
                  previewMode={previewMode}
                  setErrorForm={setErrorForm}
                />
              );
            })
        : null}

      {previewMode ? null : isEmbedded && !sectionQuestions?.length ? (
        <EmptyEmbeddedSection
          addQuestionExpanded={addQuestionExpanded}
          setAddQuestionExpanded={setAddQuestionExpanded}
          handleAddQuestion={handleAddQuestion}
          scrollToIdFunc={scrollToIdFunc}
        />
      ) : isEmbedded ? (
        <AddQuestionsToggle
          addQuestionExpanded={addQuestionExpanded}
          setAddQuestionExpanded={setAddQuestionExpanded}
          handleAddQuestion={handleAddQuestion}
          scrollToIdFunc={scrollToIdFunc}
        />
      ) : null}

      {filteredSections?.length > 1 && !previewMode ? (
        <SectionFooter
          section={section}
          sectionIndex={sectionIndex}
          filteredSections={filteredSections}
          surveySections={surveySections}
          setSurveySections={setSurveySections}
          afterSecError={afterSecError}
        />
      ) : null}
    </Box>
  );
};

export default SectionContainer;
