import { commasAfterDigit } from "utils/helpers";
import { faClock } from "@fortawesome/pro-regular-svg-icons";
import { displayRemainingTime } from "./datesUtils";

const sortingPriority = {
  time_to_complete: 0,
  first_response_time: 1,
  next_response_time: 2,
  unassigned_response_time: 3,
  hits_count: 4,
  misses_count: 5,
};
/**
 * handleAvgSLAs - This function is used to calculate the average SLAs for the given data
 * @param {*} data an object that contains to object  current_period and previous_period in which each object contains the calc for the sla
 * @param {*} inconmingData an object that contains the current_period and previous_period
 * @returns an array of objects that contains the avg sla for each sla
 */
export const handleAvgSLAs = (avgSLasData, inconmingData = {}) => {
  // newAvgSLAsData is used to store the new data after the calculation and aggregation
  const newAvgSLAsData = [];
  const { current_period, previous_period } = inconmingData;
  const currentPeriod = Object.keys(current_period ? current_period : {});

  currentPeriod.forEach((key) => {
    // newEmptyObj is used to store the current sla data
    // it will be inserted in the newAvgSLAsData array if it's the frist response for this sla
    const newEmptyObj = {
      id: key,
      name: "avgs_" + key,
      desc: "avgs_" + key + "_desc",
      currSum: 0, // accumulate the sum of the current sla
      currCount: 0, // accumulate the count of the current sla
      currValue: 0, // will hold the average value of the current sla .e.g 2h, 10m
      prevSum: 0,
      prevCount: 0,
      prevValue: 0, // will hold the average value of the previous sla .e.g 2h, 10m
      percentage: 0, // will hold the percentage of the current sla compared to the previous sla
    };
    if (key === "hits_count" || key === "misses_count") {
      const currentValue = +current_period?.[key] || 0; // Safeguard in case `current_period?.[key]` is `undefined` or `null`
      const hitsCount = currentValue;
      const prevCount = +previous_period?.[key];
      const prevHitsCount = prevCount;
      let existedObj = avgSLasData?.find?.((item) => item?.id === key);

      // if the sla is not existed in the avgSLasData array, make it equal to the newEmptyObj
      if (!existedObj) {
        existedObj = newEmptyObj;
      }
      existedObj.currCount += hitsCount;
      existedObj.currValue += hitsCount;
      existedObj.prevValue += prevHitsCount;
      existedObj.percentage =
        existedObj.prevValue > 0
          ? ((existedObj.currCount - existedObj.prevValue) /
              existedObj.prevValue) *
            100
          : existedObj.currCount
            ? 100
            : 0;
      newAvgSLAsData.push(existedObj);
      return;
    }

    const newSum = +current_period?.[key]?.sum;
    const newCount = +current_period?.[key]?.count;
    const prevSum = +previous_period?.[key]?.sum;
    const prevCount = +previous_period?.[key]?.count;
    let existedObj = avgSLasData?.find?.((item) => item?.id === key);
    // if the sla is not existed in the avgSLasData array, make it equal to the newEmptyObj
    if (!existedObj) {
      existedObj = newEmptyObj;
    }

    existedObj.currSum += newSum;
    existedObj.currCount += newCount;
    existedObj.prevSum += prevSum;
    existedObj.prevCount += prevCount;
    const newCurrValue = existedObj?.currCount
      ? Math.round(existedObj?.currSum / existedObj?.currCount)
      : 0;
    const newPrevValue = existedObj?.prevCount
      ? Math.round(existedObj?.prevSum / existedObj?.prevCount)
      : 0;

    existedObj.currValue = displayRemainingTime(newCurrValue);
    existedObj.prevValue = displayRemainingTime(newPrevValue);
    existedObj.percentage =
      newPrevValue > 0
        ? ((newCurrValue - newPrevValue) / newPrevValue) * 100
        : newCurrValue
          ? 100
          : 0;
    newAvgSLAsData.push(existedObj);
  });

  return newAvgSLAsData?.sort((a, b) => {
    const priorityA = sortingPriority[a?.id];
    const priorityB = sortingPriority[b?.id];
    return priorityA - priorityB;
  });
};

export const orderObjectByArray = (obj, orderArr) => {
  const result = {};
  orderArr?.forEach((item) => {
    const name = item;
    if (name in obj) {
      result[name] = obj[name];
    }
  });

  return result;
};

const mergeHitsAndMissesValues = (item1, ietm2) => {
  const result = {
    hits: 0,
    misses: 0,
    sla: { count: 0, sum: 0 },
    avgTime: 0,
  };
  result.hits = item1?.hits + ietm2?.hits;
  result.misses = item1?.misses + ietm2?.misses;
  result.sla.count = item1?.sla?.count + ietm2?.sla?.count;
  result.sla.sum = item1?.sla?.sum + ietm2?.sla?.sum;
  result.avgTime = calcAverage(result.sla.sum, result.sla.count);
  return result;
};

export const handleHitsAndMisses = (
  newHitsAndMisses = {},
  accumaltedHitsAndMisses = {},
) => {
  const newAccumaltedHitsAndMisses = { ...accumaltedHitsAndMisses };

  Object.entries(newHitsAndMisses)?.forEach(([key, value]) => {
    if (newAccumaltedHitsAndMisses[key]) {
      newAccumaltedHitsAndMisses[key] = mergeHitsAndMissesValues(
        newAccumaltedHitsAndMisses[key],
        value,
      );
    } else {
      newAccumaltedHitsAndMisses[key] = {
        ...(value || {}),
        avgTime: calcAverage(value?.sla?.sum, value?.sla?.count),
      };
    }
  });

  return newAccumaltedHitsAndMisses;
};

export const mergeHandleAvgTime = (data = [], handleAvgTime = {}) => {
  if (!data?.length || !Object.keys(handleAvgTime)?.length) return data;
  const generatedHandleAvgTime = {
    id: "avg_engag",
    name: "avg_engag",
    desc: "avg_engag_tooltip",
    notClickable: true,
    currValue: displayRemainingTime(handleAvgTime?.totalAvgTime),
    hidePercentage: true,
    percentage: 0,
    icon: faClock,
  };
  return [...data, generatedHandleAvgTime];
};

/*************handle sorting array******** */
// to handle sorting of array [{name:"", value:""}]  accoding to sorted array above
export const getHandleSortedArray = (customData, orginalData) => {
  const sortedData = orginalData?.sort((a, b) => {
    return customData?.indexOf(a?.name) - customData.indexOf(b?.name);
  });
  return sortedData;
};

/* handleBigTableFiltersAggregation - This function is used to aggregate the filters data
 * @param {Array} newData - an array of objects that contains the filters data.e.g. [{agents: [{...}, {...}], {routings: [{...}, {...}]}]
 * @param {Object} acculmatedData - an object that contains the accumulated data.e.g. {agents: [{...}, {...}], {routings: [{...}, {...}]}
 * @returns {Object} - an object that contains the accumulated data
 */

export const handleBigTableFiltersAggregation = (
  newData = [],
  acculmatedData = {},
  isCompleted = false,
) => {
  const newAccumulatedData = { ...acculmatedData };
  // Loop through the newData array, and do the following for every object in the array
  // e.g. [{agents: [{...}, {...}], {routings: [{...}, {...}]}, ...etc]
  newData?.forEach((itemObj) => {
    // for every object in the array, extract the key and the value.e.g {agents: [{...}, {...}]}
    Object.entries(itemObj).forEach(([key, value]) => {
      // if the key is not existed in the newAccumulatedData object, create it as an empty array
      // e.g. newAccumulatedData = {agents: [], routings: []}
      if (!newAccumulatedData[key]) {
        newAccumulatedData[key] = [];
      }

      // then check if the value is an array, we will do the following with this key.
      // the value is an array of objects that contains the data that we need to aggregate for each types.
      // e.g. [{agent_id, frt, nrt, etc}, {agent_id, frt, nrt, etc}]
      if (Array.isArray(value)) {
        // First we will check if the object id is existed one of the objects in the newAccumulatedData[key]
        // and if it's existed we will update the merge the object's value with the new value
        // .e.g {agent_id: 1, frt: 1, nrt:8} => {agent_id: 1, frt: 2, nrt: 8} then we will merge it to be {agent_id: 1, frt: 3, nrt: 8}
        // and if it's not existed we will push it to the newAccumulatedData[key]
        value?.forEach?.((newValue) => {
          if (!newValue) return;
          const existedValue = newAccumulatedData[key]?.find((item) => {
            // item is concatenation between the key with remove the last 's' and add '_id' to it
            const itemId = key?.slice(0, -1) + "_id";
            return !!item?.[itemId] && item?.[itemId] === newValue?.[itemId];
          });
          if (existedValue) {
            const mergeTwoObjectsValues = (obj1, obj2) => {
              const newObj = {};
              Object.entries(obj1)?.forEach(([key, value]) => {
                if (key?.includes?.("_id")) {
                  newObj[key] = value;
                  return;
                }
                newObj[key] = +value + +obj2?.[key];
              });
              return newObj;
            };

            // update the object in the newAccumulatedData[key] with the new value
            newAccumulatedData[key] = newAccumulatedData[key]?.map((item) => {
              const itemId = key?.slice(0, -1) + "_id";
              if (!!item?.[itemId] && item?.[itemId] === newValue?.[itemId]) {
                return mergeTwoObjectsValues(item, newValue);
              }
              return item;
            });
          } else {
            // push the new value to the newAccumulatedData[key]
            newAccumulatedData[key].push(newValue);
          }
        });
      }
    });
  });
  // if the isCompleted is true, we will aggregate the data
  if (isCompleted) {
    return aggregateData(newAccumulatedData);
  }
  return newAccumulatedData;
};

export const calcAverage = (sum, count) => {
  return +count ? Math.round(+sum / +count) : 0;
};

const aggregateData = (data = {}) => {
  const newAggregatedData = {};
  Object.entries(data)?.forEach(([key, value]) => {
    newAggregatedData[key] = value?.map?.((item = {}) => {
      return aggregateItemData(item);
    });
  });
  return newAggregatedData;
};

const aggregateItemData = (item = {}) => {
  const {
    first_response_time_sum = 0,
    next_response_time_sum = 0,
    time_to_complete_sum = 0,
    total_unassigned_response_time_sum = 0,
    first_response_time_count = 0,
    next_response_time_count = 0,
    time_to_complete_count = 0,
    total_unassigned_response_time_count = 0,
    misses_count = 0,
    hits_count = 0,
    total_assigned_engagements_count = 0,
    manual_assignment = 0,
    auto_assignment = 0,
  } = item;

  const tut = calcAverage(
    total_unassigned_response_time_sum,
    total_unassigned_response_time_count,
  );

  const ttc = calcAverage(time_to_complete_sum, time_to_complete_count);

  const frt = calcAverage(first_response_time_sum, first_response_time_count);

  const nrt = calcAverage(next_response_time_sum, next_response_time_count);

  const totalSum = time_to_complete_sum - total_unassigned_response_time_sum;
  const totalCompletedEngagements = total_unassigned_response_time_count;
  const avg_handle = calcAverage(totalSum, totalCompletedEngagements);

  const hitsAndMisses = +hits_count + +misses_count;
  const compliance_rate = !hitsAndMisses
    ? 0
    : (+hits_count / hitsAndMisses) * 100;

  return {
    ...item,
    tut: displayRemainingTime(tut),
    nrt: displayRemainingTime(nrt),
    frt: displayRemainingTime(frt),
    ttc: displayRemainingTime(ttc),
    compliance_rate: `%${Math.round(compliance_rate)}`,
    avg_handle: displayRemainingTime(avg_handle),
    manual_assign: commasAfterDigit(+manual_assignment),
    auto_assign: commasAfterDigit(+auto_assignment),
    total_assignments: commasAfterDigit(+manual_assignment + +auto_assignment),
  };
};

/**
 * handleAgentPerformanceAggregation is used to aggregate the agent performance data
 * the function will accumulate the data for each agent and calculate the average for each sla
 * @param {*} newAgents an array of agents objects that contains the data about the agent performance
 * @param {*} acculmatedAgents an array of agents objects that contains the accumulated data about the agent performance
 * @param {*} isCompleted  a boolean that indicates if the data is completed or not
 */
export const handleAgentPerformanceAggregation = (
  newAgents = [],
  acculmatedAgents = [],
) => {
  const newAccumulatedAgents = [...acculmatedAgents];

  newAgents?.forEach((agent) => {
    // for each new agent check if the agent is existed in the newAccumulatedAgents array
    // if existed we will accumulate the data for the agent; otherwise, we will push the agent to the newAccumulatedAgents array
    const existedAgent = newAccumulatedAgents?.find((item) => {
      return item?.agent_id === agent?.agent_id;
    });

    if (existedAgent) {
      Object.entries(agent).forEach(([key, value]) => {
        if (key === "agent_id") return;
        existedAgent[key] = +existedAgent[key] + +value;
      });
    } else {
      newAccumulatedAgents.push(agent);
    }
  });

  return newAccumulatedAgents?.map?.((agent) => {
    return aggregateItemData(agent);
  });
};
