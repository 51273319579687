import { useMutation, useQueryClient } from "@tanstack/react-query";
import Engagements from "../../../../../services/api/engagements";

export const useDeleteTag = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id }) => Engagements.deleteTag(id),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["engagementTagsList"] });
    },
  });
};
