import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Box, TextField, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import PopupModal from "components/popupModal";
import { CheckValueLocale } from "utils/helpers";
import { useCreateNewSurvey } from "../hooks/useCreateNewSurvey";
import { useDuplicateSurvey } from "../hooks/useDuplicateSurvey";

const CreateSurveyModal = (props) => {
  const {
    open,
    setOpen,
    setSnackbar,
    isDuplicateSurvey,
    surveyToDuplicate,
    surveyTypeId,
  } = props;

  const intl = useIntl();
  const navigate = useNavigate();

  useEffect(() => {
    if (open && isDuplicateSurvey && surveyToDuplicate) {
      formik?.setValues({ name: `copy of ${surveyToDuplicate?.name}` });
    } else formik?.resetForm();
  }, [open, isDuplicateSurvey, surveyToDuplicate]); // To reset form on modal open and close

  const { mutate: createNewSurvey, isLoading: createLoading } =
    useCreateNewSurvey();

  const handleCreateSurvey = () => {
    createNewSurvey(
      {
        queryData: {
          survey: {
            ...formik?.values,
            ...(surveyTypeId
              ? { survey_type_id: surveyTypeId?.toString() }
              : {}),
          },
        },
      },
      {
        onSuccess: (data) =>
          navigate(
            `/survey/add-survey/survey-builder/${data?.data?.data?.data?.id}`,
          ),
        onError: () =>
          setSnackbar({
            open: true,
            severity: "error",
            title: "something_went_wrong",
            message: "try_again",
          }),
      },
    );
  };

  const { mutate: duplicateSurvey, isLoading: duplicateLoading } =
    useDuplicateSurvey();

  const handleDuplicateSurvey = () => {
    duplicateSurvey(
      { id: surveyToDuplicate?.id, queryData: { ...formik?.values } },
      {
        onSuccess: (data) => {
          setSnackbar({
            open: true,
            severity: "success",
            title: "",
            message: "survey_duplicated_success",
          });
          setTimeout(() => {
            navigate(`/survey/survey-builder/${data?.data?.id}`);
          }, 3000);
        },
        onError: () => {
          setSnackbar({
            open: true,
            severity: "error",
            title: "",
            message: "failed_error",
          });
        },
        onSettled: () => setOpen(false),
      },
    );
  };

  const formik = useFormik({
    initialValues: { name: "", lang: localStorage?.lang },
    validationSchema: Yup?.object({
      name: Yup?.string()
        ?.required("required_field")
        ?.max(50, "sruvey_title_limit"),
    }),
    enableReinitialize: true, // To reinitialize form on duplicate current survey
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: isDuplicateSurvey ? handleDuplicateSurvey : handleCreateSurvey,
  });

  return (
    <PopupModal
      open={open}
      close={() => setOpen(false)}
      title={CheckValueLocale(
        isDuplicateSurvey ? "duplicate_survey" : "create_survey",
        "",
        {},
        intl,
      )}
      body={
        <Box>
          <Typography className="body-input-label show-red-icon">
            {CheckValueLocale("survey_name", "", {}, intl)}
          </Typography>
          <TextField
            id="create-survey-name-field"
            className="create-survey-name-field"
            name="name"
            placeholder={CheckValueLocale(
              "please_enter_your_survey_name",
              "",
              {},
              intl,
            )}
            value={formik?.values?.name || ""}
            onChange={(e) => formik?.setFieldValue("name", e.target.value)}
            onBlur={formik?.handleBlur}
            error={
              formik?.touched?.["name"] && Boolean(formik?.errors?.["name"])
            }
            helperText={
              formik?.touched?.["name"]
                ? CheckValueLocale(formik?.errors?.["name"], "", {}, intl)
                : ""
            }
            variant="outlined"
            size="small"
            fullWidth
          />
        </Box>
      }
      leftBtn={CheckValueLocale("cancel", "", {}, intl)}
      rightBtn={CheckValueLocale(
        isDuplicateSurvey ? "survey_duplicated" : "create",
        "",
        {},
        intl,
      )}
      accept={() => formik?.handleSubmit()}
      addClasses="actions-buttons-shared"
      isRightBtnLoading={createLoading || duplicateLoading}
      minWidth="600px"
    />
  );
};
export default CreateSurveyModal;
