import { useQuery } from "@tanstack/react-query";
import { InsightsServices } from "services/api/insightsServices";

export const useGetThemesPolicy = ({ enabled }) => {
  return useQuery({
    queryKey: ["themes"],
    queryFn: InsightsServices.getApplyPolicy,
    enabled,
    select: (data) => data?.data,
  });
};
