import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale, handleDateLangFormat } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowToBottom } from "@fortawesome/pro-regular-svg-icons";
import { formatDate } from "pages/reports/utils/formatDateUtlit";
import { useExportEnhancedReport } from "../hooks/useExportEnhancedReport";
import { ViewReportContext } from "pages/reports/context/reportContext";
import CustomizedTooltip from "components/customizedTooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LucButton from "shared/lucButton/lucButton";
import ReportController from "services/controllers/reportController";

const ViewReportRecordItem = ({ recordItem }) => {
  const { reportId, reportInfo, setSnackbar } = useContext(ViewReportContext);

  const intl = useIntl();

  const navigate = useNavigate();

  const customizedTooltipStyle = {
    whiteSpace: "pre-line",
    padding: "12px",
    lineHeight: "14px",
    fontWeight: "400",
    fontSize: "12px",
  };

  const { mutate: exportEnhanced, isPending: exportEnhancedLoading } =
    useExportEnhancedReport();

  const exportEnhancedReport = (report_data_id) => {
    exportEnhanced(
      { queryData: { report_data_id } },
      {
        onSuccess: (data) => {
          data?.data?.data?.map((item) =>
            item?.url?.length ? window.open(item?.url, "_blank") : null,
          );
          setSnackbar({
            open: true,
            severity: "success",
            title: "report_generated",
            message: "report_generated_body",
          });
        },
        onError: () => {
          setSnackbar({
            open: true,
            severity: "error",
            title: "report_fail",
            message: "report_fail_try_later",
          });
        },
      },
    );
  };

  const exportOldReport = (report) => {
    let type_report = reportInfo?.report_extra?.branding_type,
      reportId = reportId,
      monitorName = reportInfo?.monitors && reportInfo?.monitors[0]?.name,
      dataFileName = report?.data_file_name,
      monitor_id = reportInfo?.monitors && reportInfo?.monitors[0]?.id;
    var user_language = "";
    if (window?.localStorage?.lang === "en") {
      user_language = "en-US";
    } else {
      user_language = "ar-SA";
    }

    var monitor_sections = reportInfo?.monitor_sections;
    var data_source_name;

    for (let i = 0; i < monitor_sections?.length; i++) {
      data_source_name = monitor_sections[i]?.data_source_name;
      for (let x = 0; x < monitor_sections[i]?.sections?.length; x++) {
        getMonitorSentimentCategories(
          monitor_id,
          reportId,
          monitorName,
          dataFileName,
          user_language,
          type_report,
          monitor_sections[i].sections[x],
          data_source_name,
        );
      }
    }
  };

  const getMonitorSentimentCategories = (
    monitor_id,
    reportId,
    moniotr_name,
    dataFileName,
    user_language,
    type_report,
    section_name,
    data_source_name,
  ) => {
    ReportController.getMonitorSentimentCategories(monitor_id).then((data) => {
      if (data?.categories === undefined) {
        // this.handleSuccessErrorMsg(false)
      } else {
        let sentiment_categories = JSON.stringify(data?.categories);
        let suggested_name = reportId + "-" + moniotr_name;
        var queryData = {
          reportId,
          dataFileName,
          user_language,
          monitor_id,
          suggested_name,
          sentiment_categories,
          type_report,
          section_name,
          data_source_name,
        };
        if (data_source_name == "TWITTER" && section_name == "METRICS") {
          ReportController.getTwitterReport(queryData).then((res) => {
            if (res?.status === 200) {
              setSnackbar({
                open: true,
                severity: "success",
                title: "report_generated",
                message: "report_generated_body",
              });
            } else {
              setSnackbar({
                open: true,
                severity: "error",
                title: "report_fail",
                message: "report_fail_try_later",
              });
            }
          });
        } else {
          ReportController.exportReportFromFile(
            monitor_id,
            type_report,
            queryData,
          ).then((res) => {
            if (res?.params === undefined) {
              setSnackbar({
                open: true,
                severity: "error",
                title: "report_fail",
                message: "report_fail_try_later",
              });
            } else {
              handleGetPptLink(res?.params);
            }
          });
        }
      }
    });
  };

  const handleGetPptLink = (queryData) => {
    ReportController.getPptLink(queryData).then((res) => {
      if (res?.status === 200) {
        setSnackbar({
          open: true,
          severity: "success",
          title: "report_generated",
          message: "report_generated_body",
        });
      } else {
        setSnackbar({
          open: true,
          severity: "error",
          title: "report_fail",
          message: "report_fail_try_later",
        });
      }
    });
  };

  return (
    <Box>
      <Box className="list-card">
        <Typography variant="subtitle2">
          {CheckValueLocale("report_for", "", {}, intl)}
          <Box component={"span"} className="date-card">
            {CheckValueLocale(
              handleDateLangFormat(
                recordItem?.created_at,
                "dddd",
                intl?.locale,
              ),
              "",
              {},
              intl,
            )}
          </Box>
          {", "}
          <Box component={"span"} className="date-card">
            {formatDate(recordItem?.created_at, intl?.locale)}
          </Box>
        </Typography>

        {reportInfo?.report_type?.toLowerCase() === "newsletter" ? (
          <CustomizedTooltip
            title={CheckValueLocale("view_report", "", {}, intl)}
            arrow
            placement="top"
            styles={customizedTooltipStyle || {}}
          >
            <Box>
              <LucButton
                id="report-view-page-view-report-button"
                variant="flat"
                type="secondary"
                minWidth={36}
                onClick={() =>
                  navigate(
                    `/reports/newslettersreport_details/${reportId}?report_data_id=${recordItem?.report_data_id}`,
                  )
                }
              >
                <VisibilityIcon />
              </LucButton>
            </Box>
          </CustomizedTooltip>
        ) : ["dynamic_design", "enhanced_design"]?.includes(
            reportInfo?.report_extra?.report_template?.toLowerCase(),
          ) ? (
          <CustomizedTooltip
            title={CheckValueLocale("download", "", {}, intl)}
            placement="bottom"
            arrow
            styles={customizedTooltipStyle || {}}
          >
            <Box>
              <LucButton
                id="report-view-page-download-report-button"
                variant="flat"
                type="secondary"
                minWidth={36}
                loading={exportEnhancedLoading}
                onClick={() => exportEnhancedReport(recordItem?.report_data_id)}
              >
                <FontAwesomeIcon icon={faArrowToBottom} />
              </LucButton>
            </Box>
          </CustomizedTooltip>
        ) : (
          <CustomizedTooltip
            title={CheckValueLocale("download", "", {}, intl)}
            placement="bottom"
            arrow
            styles={customizedTooltipStyle || {}}
          >
            <Box>
              <LucButton
                id="report-view-page-download-report-button"
                variant="flat"
                type="secondary"
                minWidth={36}
                onClick={() => exportOldReport(recordItem)}
              >
                <FontAwesomeIcon icon={faArrowToBottom} />
              </LucButton>
            </Box>
          </CustomizedTooltip>
        )}
      </Box>
    </Box>
  );
};

export default ViewReportRecordItem;
