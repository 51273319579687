import { useEffect, useState } from "react";
import {
  Paper,
  Grid,
  Tabs,
  Tab,
  Typography,
  Divider,
  Button,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Box,
  TabsTypeMap,
} from "@mui/material";
import CircularLoading from "components/circularProgress";

//Locals
import { useIntl } from "react-intl";
import "./addChannel.scss";
import { Add } from "@mui/icons-material";
import { AddAccountDialog, InterComDialog } from "./dialog";
import { ReactComponent as Google } from "images/social-icons/Google_icon.svg";
import { AuthorizedAccounts, CateogrySource, UserAccount } from "utils/types";
import AuthController from "services/controllers/authController.js";
import CompaniesController from "services/controllers/companiesController.js";
import { SxProps } from "@mui/system";
import {
  facebookAuth,
  getCompanyApps,
  gmailAuth,
  twitterAuth,
  gmbAuth,
  linkedInAuth,
  tiktokAuth,
} from "../manageChannels.hooks";
import {
  handlelUserRoles,
  CheckValueLocale,
  getResetPhoneNumber,
} from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faIntercom,
  faWhatsapp,
  faGoogle,
  faLinkedin,
  faFacebookMessenger,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import GenesysPopup from "./genesysPopup";
import googleMyBusinessImg from "../../../../images/social-icons/google_my_business_icon.svg";
import SnackBar from "components/snackBar";
import PublicIcon from "@mui/icons-material/Public";
import MailIcon from "@mui/icons-material/Mail";
import _ from "lodash";
import Snackbar from "components/snackBar";
import Genesys from "images/shared-images/genesys-icon.svg";
import WhatsappNewAccPopup from "./whatsappAuthPopup/whatsappNewAccPopup";
import CustomerCareChannel from "./customerCareChannel";
import LucButton from "shared/lucButton/lucButton";
import xPlatform from "images/social-icons/x-platform-icon.svg";
import { FormikContextType } from "formik";
import InputWithAddButton from "components/inputWithAddButton/InputWithAddButton";
import {
  handleAddKeywordsTolist,
  handleChangeInput,
} from "components/inputWithAddButton/inputWithAddButtonUtils.js";
import DefaultUserAvtar from "images/shared-images/user-management-default.svg";

type Props = {
  readonly activeDataSourceId: string;
  readonly activeDataSourceName: string;
  readonly categotyDataSources: readonly CateogrySource[];
  readonly getAuthorizedAccounts: AuthorizedAccounts;
  readonly showEmptyAccountScreen: boolean;
  readonly authorizedAccountsLoading: boolean;
  readonly authorizedAccounts: UserAccount[];
  readonly includedAccounts: any;
  readonly activeCategoryName: string;
  readonly accounts: any;
  readonly item: any;
  readonly stats: any;
  readonly setGenesysCodeLoading: any;
  readonly genesysDataSourceId: any;
  readonly genesysStatus: any;
  readonly code: any;
  readonly failSnackBarData: any;
  readonly whatsappFailSnackBarData: any;
  readonly openSnackBarAllSource: any;
  readonly setOpenSnackBarAllSource: any;
  readonly setFailSnackBarData: any;
  readonly setSelectedAcount: any;
  readonly selectedAcount: any;
  readonly tiktokDataSourceId: any;
  readonly setCoustomerCareVal: any;
  readonly coustomerCareVal: any;
  readonly setMonitorData: any;
  readonly monitorData: any;
  readonly setMonitorErrors: any;
  readonly monitorErrors: any;
  readonly formik: FormikContextType<any>;
  readonly setStats: any;
};

// May be use it later

// const simulateMouseClickFunction = (element: any) => {
//   const mouseClickEvents = ["mousedown", "click", "mouseup"];
//   mouseClickEvents?.forEach((mouseEventType) =>
//     element?.dispatchEvent(
//       new MouseEvent(mouseEventType, {
//         view: window,
//         bubbles: true,
//         cancelable: true,
//         buttons: 1,
//       })
//     )
//   );
// };

const SetUpChannelStep = (props: Props) => {
  const formik = props?.formik;
  const intl = useIntl();
  const redirectUrl = window.location.href;

  const [unAuthAccountsMsg, setUnAuthAccountsMsg] = useState<boolean>(false);
  const [companyApps, setCompanyApps] = useState<any[]>([]);
  const [openSuccesInterCom, setOpenSuccesInterCom] = useState(false);
  const [checkedType, setCheckedType] = useState<any>([]);
  const [openPendingWhatsappAlert, setOpenPendingWhatsappAlert] =
    useState(false);
  const [interComDialogState, setInterComDialogState] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const [activeDataSource, setActiveDataSource] = useState({
    id: props.activeDataSourceId,
    name: props.activeDataSourceName,
  });
  const [usedIn, setUsedIn] = useState<any>([]);
  const [checked, setChecked] = useState<any>("");
  const [types, setTypes] = useState<any>("");
  const [ignoreTweet, setIgnoreTweet] = useState<string>("");
  const [publicSlide, setPublicSlide] = useState(false);
  const [privateSlide, setPrivateSlide] = useState(false);
  let socialMediaDataSource = [
    "TWITTER",
    "FACEBOOK",
    "INSTAGRAM",
    "LINKEDIN",
  ].includes(props.activeDataSourceName);

  const [accountAuthenticated, setAccountAuthenticated] = useState(false);
  const [openAccountAuthSnakbar, setOpenAccountAuthSnakbar] = useState(false);
  const [openGenesysModal, setOpenGenesysModal] = useState(false);

  //Whatsapp Modal State
  const [openWhatsAppPopup, setOpenWhatsAppPopup] = useState(false);
  const [whatsAppdirectActive, setwhatsAppDirectActive] = useState(0);
  const [addWhatsappUserSnackBar, setAddWhatsappUserSnackBar] = useState<any>({
    openSnackBar: false,
    severity: null,
    title: "",
    message: "",
  });
  let activeSourceName = activeDataSource?.name?.toLocaleLowerCase();

  //function to add [ used_in flag ] to IG & FB as it not included ..
  const FaceBookInstagramMig = () => {
    const incl = props.includedAccounts;
    const auth = props.authorizedAccounts;

    if (incl && auth) {
      auth.forEach((authElement: any) => {
        incl.forEach((inclElement: any) => {
          if (
            authElement.attributes.id === inclElement.attributes.account_info_id
          ) {
            inclElement.attributes["used_in"] = authElement.attributes.used_in;
          }
        });
      });
    }
    return incl;
  };

  const checkGoogleMyBusinessAccounts = () => {
    let googleMyBusinessAccounts = props.authorizedAccounts,
      accountsDataSource = props.activeDataSourceName,
      gmbAccount = 0;

    if (googleMyBusinessAccounts !== undefined) {
      googleMyBusinessAccounts?.map((account: any) => {
        if (accountsDataSource === "GOOGLEMYBUSINESS") {
          gmbAccount++;
        }
      });
    }

    return gmbAccount > 0;
  };

  const accounts =
    activeDataSource.name === "FACEBOOK" ||
    activeDataSource.name === "INSTAGRAM" ||
    activeDataSource.name === "LINKEDIN"
      ? FaceBookInstagramMig()
      : props.authorizedAccounts;

  useEffect(() => {
    getCompanyApps(setCompanyApps);
    // Choose Data Source according to selected before Authentication

    // if (localStorage.getItem("add_data_source_id_step")) {
    //   let next_step_button = document.getElementsByClassName(
    //     `${localStorage
    //       .getItem("add_data_source_name_step")!
    //       .toLowerCase()}-tab`
    //   )[0];
    //   // simulateMouseClickFunction(next_step_button);
    //   localStorage.removeItem("add_data_source_id_step");
    //   localStorage.removeItem("add_data_source_name_step");
    // }
    localStorage.removeItem("redirection_done");
  }, [props.activeDataSourceName]);

  useEffect(() => {
    //check if authorizedAccounts return with [flag : user_in > 0] to handle appear of Account Msg.
    let userAuthFlag = true;

    accounts.forEach((account: any) => {
      // change the condition here for FB & IG cases as they didn't have used_in attribute
      if (
        (!socialMediaDataSource && account.attributes.used_in === 0) ||
        (socialMediaDataSource &&
          account.attributes.used_in_public_channel === 0) ||
        (socialMediaDataSource &&
          account.attributes.used_in_private_channel === 0)
      ) {
        userAuthFlag = false;
      }
      if (socialMediaDataSource) {
        if (
          account.attributes.used_in_private_channel == 0 &&
          account.attributes.used_in_public_channel == 0
        ) {
          // to create 2 copies from one account that contains private & public channels
          let copied_account: any = _.cloneDeep(account);
          copied_account["type"] = "private";
          account["type"] = "public";
          accounts.push(copied_account);
        }
      }
    });
    setUnAuthAccountsMsg(userAuthFlag);
  }, [accounts, socialMediaDataSource]);

  const categoryName = (id: string) => {
    return props?.categotyDataSources?.find((i) => i.id == id);
  };

  const handleChange: TabsTypeMap["props"]["onChange"] = (event, newValue) => {
    setActiveDataSource({
      id: newValue,
      name: categoryName(newValue)?.name ?? "",
    });
    if (categoryName(newValue)?.name === "WHATSAPP") {
      props.setStats({
        activeDataSourceId: newValue,
        activeDataSourceName: categoryName(newValue)?.name ?? "",
      });
    }

    formik.setFieldValue("IS_PRIVATE_CHANNEL", false);
    props.setSelectedAcount({
      id: "",
      type: "",
      item: {},
      attributes: {
        used_in: 0,
        name: "",
        user_name: "",
        image_url: "",
        used_in_public_channel: 0,
        used_in_private_channel: 0,
        id_public_2: "",
        id_public: 0,
        id_private: 0,
      },
    });

    props.getAuthorizedAccounts(
      newValue,
      "1",
      categoryName(newValue)?.name as string,
    );
  };

  const handleAuth = (dataSource: string) => {
    if (dataSource === "twitter") {
      twitterAuth(companyApps, redirectUrl, dataSource);
    } else if (dataSource === "facebook") {
      facebookAuth(companyApps, redirectUrl, dataSource);
    } else if (dataSource === "gmail") {
      gmailAuth(companyApps, redirectUrl, dataSource);
    } else if (dataSource === "googlemybusiness") {
      gmbAuth(companyApps, redirectUrl, dataSource);
    } else if (dataSource === "intercom") {
      handleInterComDialog();
    } else if (dataSource === "instagram") {
      facebookAuth(companyApps, redirectUrl, dataSource);
    } else if (dataSource === "linkedin") {
      linkedInAuth(companyApps, redirectUrl, dataSource);
    } else if (dataSource === "tiktok") {
      tiktokAuth(
        redirectUrl,
        props?.tiktokDataSourceId,
        props?.setFailSnackBarData,
        props?.setOpenSnackBarAllSource,
      );
    }
  };

  // start Linked Authentication Error Messages Section
  const [errorMsgTitle, setErrorMsgTitle] = useState("");
  const [errorMsgText, setErrorMsgText] = useState("");
  const [openErrorLinkedInAuthAlert, setOpenErrorLinkedInAuthAlert] =
    useState(false);

  // This to handle the cases for duplicated organizations names [one or more organizations]
  // add organizations list are between brackets for more than one, no bracket for one organization
  var urlAuthLink = window.location.href,
    // urlEncodedLink = encodeURI(urlAuthLink),
    urlDecodedLink = decodeURI(urlAuthLink),
    orgsNamesList = urlAuthLink?.includes(
      "/add-channel?message=duplicated_organizations",
    )
      ? urlDecodedLink.split("duplicated_organizations:")[1]
      : "",
    organizationList =
      orgsNamesList !== ""
        ? orgsNamesList.split(",").length === 1
          ? orgsNamesList
          : `[${orgsNamesList}]`
        : "",
    newRedirectLink = urlDecodedLink.split("?message")[0];

  // These Conditions to prepare the message title for the three cases in Linkedin Authentication.
  var errorMessageTitle =
    errorMsgTitle === "auth_adding_new_pages_failed_duplicated"
      ? organizationList.includes("[")
        ? CheckValueLocale(
            "auth_name_of_duplicated_pages",
            "",
            {
              organizationList: organizationList,
            },
            intl,
          )
        : CheckValueLocale(
            "auth_name_of_duplicated_page",
            "",
            {
              organizationList: organizationList,
            },
            intl,
          )
      : CheckValueLocale(errorMsgText, "", {}, intl);

  useEffect(() => {
    setOpenErrorLinkedInAuthAlert(true);
    var linkedInRedirectionCondition = false;
    if (urlDecodedLink.includes("/add-channel?message=no_ogranizations")) {
      linkedInRedirectionCondition = true;
      setErrorMsgTitle("auth_failed");
      setErrorMsgText("auth_account_has_no_pages");
    } else if (
      urlDecodedLink.includes("/add-channel?message=all_ogranizations_exist")
    ) {
      linkedInRedirectionCondition = true;
      setErrorMsgTitle("auth_adding_new_pages_failed");
      setErrorMsgText("auth_all_selected_pages_are_already_added");
    } else if (
      urlDecodedLink.includes("/add-channel?message=duplicated_organizations")
    ) {
      linkedInRedirectionCondition = true;
      setErrorMsgTitle("auth_adding_new_pages_failed_duplicated");
    } else {
      setOpenErrorLinkedInAuthAlert(false);
    }
    setTimeout(() => {
      setErrorMsgTitle("");
      setErrorMsgText("");
      linkedInRedirectionCondition && window.location.replace(newRedirectLink);
    }, 7000);
  }, [urlDecodedLink]);

  // end Linked Authentication Error Messages Section

  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const handleCloseSnackBar = () => {
    props?.setOpenSnackBarAllSource(false);
  };

  const handleAccept = () => {
    handleDialogClose();
    props.getAuthorizedAccounts(
      activeDataSource.id,
      "2",
      activeDataSource.name,
    );
  };

  const handleAccountSelect = (account, selectedAcount, accountType) => {
    if (
      account.attributes.used_in_private_channel === 0 &&
      account.attributes.used_in_public_channel === 0
    ) {
      if (accountType === "public") {
        setPublicSlide(true);
        formik.setFieldValue("IS_PRIVATE_CHANNEL", false);
      } else {
        setPublicSlide(false);
        formik.setFieldValue("IS_PRIVATE_CHANNEL", false);
      }

      if (accountType === "private") {
        setPrivateSlide(true);
        formik.setFieldValue("IS_PRIVATE_CHANNEL", true);
      } else {
        setPrivateSlide(false);
        formik.setFieldValue("IS_PRIVATE_CHANNEL", false);
      }
    } else {
      if (accountType === "") {
        if (account.attributes.used_in_private_channel === 0) {
          formik.setFieldValue("IS_PRIVATE_CHANNEL", true);
        } else if (account.attributes.used_in_public_channel === 0) {
          formik.setFieldValue("IS_PRIVATE_CHANNEL", false);
        } else {
          formik.setFieldValue("IS_PRIVATE_CHANNEL", false);
        }
        setPublicSlide(false);
        setPrivateSlide(false);
      }
    }
    props?.setSelectedAcount(account);
  };

  const showSelectedAcount = (
    accountId: string,
    account: any,
    accountType: boolean,
  ): SxProps => {
    if (socialMediaDataSource) {
      if (
        account.attributes.used_in_public_channel === 0 &&
        account.attributes.used_in_private_channel === 0
      ) {
        if (
          accountType &&
          account?.type === props?.selectedAcount?.type &&
          account?.id === props?.selectedAcount?.id
        ) {
          return {
            border: "2px solid #456EB2 ",
            boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.11)",
          };
        } else {
          return {};
        }
      } else {
        if (accountId === props?.selectedAcount?.id) {
          return {
            border: "2px solid #456EB2 ",
            boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.11)",
          };
        }
      }
    } else {
      if (accountId === props?.selectedAcount?.id) {
        return {
          border: "2px solid #456EB2 ",
          boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.11)",
        };
      }
    }
    return {};
  };

  const handleInterComDialog = () => {
    setInterComDialogState((p) => !p);
  };

  const acceptInterComDialog = (objData: any) => {
    AuthController.interCom(objData).then((data) => {
      if (data?.response?.status === 204) {
        handleInterComDialog();
        setOpenSuccesInterCom(true);
        props.getAuthorizedAccounts(
          activeDataSource.id,
          "1",
          categoryName(activeDataSource?.id)?.name as string,
        );
      }
    });
  };

  const handleCloseSuccessIntercom = () => setOpenSuccesInterCom(false);
  const handleClosePendingWhatsapp = () => setOpenPendingWhatsappAlert(false);
  const handleCloseAuthMsgLinkedIn = () => setOpenErrorLinkedInAuthAlert(false);

  const setSocialLocalStorages = () => {
    // Add active category name to localstorage to be used later
    // localStorage.setItem("add_category_step", props.activeCategoryName);
    // localStorage.setItem("add_data_source_id_step", activeDataSource.id);
    // localStorage.setItem("add_data_source_name_step", activeDataSource.name);
    localStorage.setItem("active_datasource", activeDataSource.name);
    localStorage.setItem("active_category", props.activeCategoryName);
    localStorage.setItem("active_source_id", activeDataSource.id);
    localStorage.setItem("redirection_done", "true");
  };

  let icons: any[];
  if (props.activeCategoryName === "Social Media") {
    icons = [faTwitter, faFacebook, faInstagram, faLinkedin, faTiktok];
  } else if (props.activeCategoryName === "chats") {
    icons = [faIntercom, faWhatsapp];
  } else if (props.activeCategoryName === "emails") {
    icons = [faGoogle];
  } else if (props.activeCategoryName === "Ratings") {
    icons = ["", googleMyBusinessImg];
  }

  const handleCloseAuthSnackBar = () => {
    setOpenAccountAuthSnakbar(false);
  };

  const handleCategoryNameIcon = (categoryName, i) => {
    return categoryName === "GENESYS" ? (
      <img src={Genesys} alt={"Genesys"} className="genesys-add-channel-icon" />
    ) : categoryName === "TWITTER" ? (
      <img
        src={xPlatform}
        alt={"Twitter"}
        className="twitter-add-channel-icon"
      />
    ) : categoryName !== "Ratings" ? (
      <FontAwesomeIcon icon={icons?.[i]} />
    ) : (
      ""
    );
  };
  useEffect(() => {
    if (window.location.href.includes("/add-channel?success=1")) {
      setAccountAuthenticated(true);
      setOpenAccountAuthSnakbar(true);
      window.history.pushState({}, "", window.location.href.split("?")[0]);
    } else if (
      window.location.href.includes("/add-channel?success=0") ||
      window.location.href.includes("/add-channel?auth=failed")
    ) {
      setAccountAuthenticated(false);
      setOpenAccountAuthSnakbar(true);
      window.history.pushState({}, "", window.location.href.split("?")[0]);
    }
  }, [window.location.href]);

  return (
    <Paper elevation={3} className="panel-body">
      <Typography
        sx={{
          fontSize: "13px",
          marginBottom: "20px",
          color: "#232B34",
          fontWeight: 600,
        }}
      >
        2. {CheckValueLocale("setup_channel", "", {}, intl)}
      </Typography>

      <div>
        <Tabs
          onChange={handleChange}
          value={activeDataSource.id}
          aria-label="Tabs where selection follows focus"
        >
          {props?.categotyDataSources?.map((category, i) =>
            category["active"] === true ? (
              <Tab
                key={i}
                value={category?.id}
                icon={handleCategoryNameIcon(category?.name, i)}
                label={
                  <span className="tap-box">
                    {category?.name?.toLocaleLowerCase() ===
                    "googlemybusiness" ? (
                      <img
                        src={googleMyBusinessImg}
                        alt=""
                        className={"googleMyBusinessImage"}
                      />
                    ) : (
                      ""
                    )}
                    <span className="tap-text">
                      {CheckValueLocale(
                        category?.name?.toLocaleLowerCase(),
                        "",
                        {},
                        intl,
                      )}
                    </span>
                  </span>
                }
                className={
                  category?.name === "TWITTER"
                    ? "twitter-tab"
                    : category?.name === "FACEBOOK"
                      ? "facebook-tab"
                      : category?.name === "INSTAGRAM"
                        ? "instagram-tab"
                        : category?.name === "INTERCOM"
                          ? "intercom-tab"
                          : category?.name === "WHATSAPP"
                            ? "whatsapp-tab"
                            : category?.name === "GMAIL"
                              ? "gmail-tab"
                              : category?.name === "GOOGLEMYBUSINESS"
                                ? "google-my-business-tab"
                                : category?.name === "LINKEDIN"
                                  ? "linkedin-tab"
                                  : category?.name === "GENESYS"
                                    ? "genesys-tab"
                                    : category.name === "TIKTOK"
                                      ? "tiktok-tab"
                                      : ""
                }
                id={`channel-analytics-add-channel-${
                  category?.name?.toLowerCase() ?? "category-name"
                }`}
              />
            ) : (
              ""
            ),
          )}
        </Tabs>
        {(handlelUserRoles("GENERAL", "VIEW_INTEGRATION") ||
          handlelUserRoles("GENERAL", "VIEW_COMPANY_AUTH")) && (
          <>
            <Divider />
            <Typography sx={{ margin: "revert" }} className="select-acc">
              {intl.formatMessage(
                {
                  id:
                    props?.activeDataSourceName === "WHATSAPP"
                      ? "select_your_whatsapp_account"
                      : "select_your_account",
                },
                {
                  activeDataSourceName: CheckValueLocale(
                    activeDataSource?.name?.toLocaleLowerCase(),
                    "",
                    {},
                    intl,
                  ),
                },
              )}
            </Typography>

            {props?.showEmptyAccountScreen ? (
              <Typography sx={{ margin: "revert" }} className="auth-acc-msg">
                {activeSourceName === "googlemybusiness" ? (
                  <span>
                    {CheckValueLocale(
                      "authenticated_google_account_msg",
                      "",
                      {},
                      intl,
                    )}
                    <span>
                      <Google className="google-icon-account-icon" />
                    </span>
                    {CheckValueLocale("sign_in_with_google", "", {}, intl)}.
                  </span>
                ) : (
                  intl.formatMessage(
                    { id: "authenticated_account_msg" },
                    {
                      activeDataSourceName: CheckValueLocale(
                        activeDataSource?.name.toLocaleLowerCase(),
                        "",
                        {},
                        intl,
                      ),
                    },
                  )
                )}
              </Typography>
            ) : (
              <Grid
                container
                rowSpacing={3}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ marginBottom: "60px" }}
              >
                {props.authorizedAccountsLoading ? (
                  <Box sx={{ margin: "auto" }} className="add-channel-loading">
                    <CircularLoading />
                  </Box>
                ) : (
                  accounts.map((account: any, i: any) =>
                    !socialMediaDataSource &&
                    account.attributes.used_in >
                      0 ? null : socialMediaDataSource &&
                      account.attributes.used_in_public_channel === 1 &&
                      account.attributes.used_in_private_channel ===
                        1 ? null : socialMediaDataSource &&
                      account.attributes.used_in_public_channel === 0 &&
                      account.attributes.used_in_private_channel === 0 ? (
                      <>
                        {account.type === "public" && (
                          <Grid item xs={4} key={i}>
                            <List
                              className={
                                account?.attributes.id_public ===
                                props?.selectedAcount?.attributes?.id_public
                                  ? "bord account-item"
                                  : "account-item"
                              }
                              onClick={() =>
                                handleAccountSelect(
                                  account,
                                  props?.selectedAcount,
                                  "public",
                                )
                              }
                              sx={{
                                border: "2px solid #E2E8F0",
                                borderRadius: "4px",
                                cursor: "pointer",
                                ...showSelectedAcount(
                                  account?.id,
                                  account,
                                  account?.type === "public",
                                ),
                              }}
                            >
                              <ListItem sx={{ padding: "12px 10px" }}>
                                {props.activeDataSourceName !== "WHATSAPP" && (
                                  <ListItemAvatar>
                                    <Avatar
                                      src={account.attributes.image_url}
                                    />
                                  </ListItemAvatar>
                                )}

                                {props.activeDataSourceName !== "WHATSAPP" ? (
                                  <ListItemText
                                    primary={account.attributes.name}
                                    secondary={account.attributes.user_name}
                                  />
                                ) : (
                                  <ListItemText
                                    primary={account.attributes.name}
                                    secondary={""}
                                    sx={{ padding: "12px 30px" }}
                                  />
                                )}

                                <PublicIcon className="public-icon add-channel-icon" />
                                {account?.attributes?.id_public ===
                                  props?.selectedAcount?.attributes
                                    ?.id_public && publicSlide ? (
                                  <Box className="selected-account">
                                    <FontAwesomeIcon icon={faCheck} />
                                  </Box>
                                ) : publicSlide === false &&
                                  privateSlide === false &&
                                  props?.selectedAcount?.type === "public" &&
                                  account?.attributes?.id_public ===
                                    props?.selectedAcount?.attributes
                                      ?.id_public ? (
                                  <Box className="selected-account">
                                    <FontAwesomeIcon icon={faCheck} />
                                  </Box>
                                ) : null}
                              </ListItem>
                            </List>
                          </Grid>
                        )}

                        {account.type === "private" &&
                        props.activeDataSourceName ? (
                          <Grid item xs={4} key={i}>
                            <List
                              className={
                                account?.attributes.id_public ===
                                props?.selectedAcount?.attributes?.id_public
                                  ? "bord account-item"
                                  : "account-item"
                              }
                              onClick={() =>
                                handleAccountSelect(
                                  account,
                                  props?.selectedAcount,
                                  "private",
                                )
                              }
                              sx={{
                                border: "2px solid #E2E8F0",
                                borderRadius: "4px",
                                cursor: "pointer",
                                ...showSelectedAcount(
                                  account?.id,
                                  account,
                                  account?.type === "private",
                                ),
                              }}
                            >
                              <ListItem sx={{ padding: "12px 10px" }}>
                                {props.activeDataSourceName !== "WHATSAPP" && (
                                  <ListItemAvatar>
                                    <Avatar
                                      src={account.attributes.image_url}
                                    />
                                  </ListItemAvatar>
                                )}

                                {props.activeDataSourceName !== "WHATSAPP" ? (
                                  <ListItemText
                                    id={account?.attributes?.id}
                                    primary={account.attributes.name}
                                    secondary={account.attributes.user_name}
                                  />
                                ) : (
                                  <ListItemText
                                    primary={account.attributes.name}
                                    secondary={""}
                                    sx={{ padding: "12px 30px" }}
                                  />
                                )}

                                {props.activeDataSourceName === "FACEBOOK" ||
                                props.activeDataSourceName === "INSTAGRAM" ||
                                props.activeDataSourceName === "LINKEDIN" ? (
                                  <FontAwesomeIcon
                                    icon={faFacebookMessenger}
                                    className="messenger-icon add-channel-icon"
                                  />
                                ) : (
                                  <MailIcon className="mail-icon add-channel-icon" />
                                )}
                                {account?.attributes?.id_private ===
                                  props?.selectedAcount?.attributes
                                    ?.id_private && privateSlide ? (
                                  <Box className="selected-account">
                                    <FontAwesomeIcon icon={faCheck} />
                                  </Box>
                                ) : publicSlide === false &&
                                  privateSlide === false &&
                                  props?.selectedAcount?.type === "private" &&
                                  account?.attributes?.id_private ===
                                    props?.selectedAcount?.attributes
                                      ?.id_private ? (
                                  <Box className="selected-account">
                                    <FontAwesomeIcon icon={faCheck} />
                                  </Box>
                                ) : null}
                              </ListItem>
                            </List>
                          </Grid>
                        ) : null}
                      </>
                    ) : (
                      <Grid item xs={4} key={i}>
                        <List
                          className="account-item"
                          onClick={() =>
                            handleAccountSelect(
                              account,
                              props?.selectedAcount,
                              "",
                            )
                          }
                          sx={{
                            border: "2px solid #E2E8F0",
                            borderRadius: "4px",
                            cursor: "pointer",
                            ...showSelectedAcount(account?.id, account, false),
                          }}
                        >
                          <ListItem sx={{ padding: "12px 10px" }}>
                            <ListItemAvatar>
                              <Avatar
                                src={
                                  props.activeDataSourceName !== "WHATSAPP"
                                    ? account.attributes.image_url
                                    : DefaultUserAvtar
                                }
                              />
                            </ListItemAvatar>

                            {props.activeDataSourceName !== "WHATSAPP" ? (
                              <ListItemText
                                primary={account.attributes.name}
                                secondary={account.attributes.user_name}
                              />
                            ) : props.activeDataSourceName == "WHATSAPP" ? (
                              <ListItemText
                                className="whatsapp-list-content-card"
                                primary={account?.attributes?.name}
                                secondary={getResetPhoneNumber(
                                  account?.attributes?.user_name,
                                )}
                              />
                            ) : (
                              <ListItemText
                                primary={account.attributes.name}
                                secondary={""}
                                sx={{ padding: "12px 30px" }}
                              />
                            )}

                            {socialMediaDataSource &&
                            account.attributes.used_in_public_channel === 0 &&
                            account.attributes.used_in_private_channel === 1 &&
                            props.activeDataSourceName !== "WHATSAPP" ? (
                              <PublicIcon className="public-icon add-channel-icon" />
                            ) : socialMediaDataSource &&
                              account.attributes.used_in_private_channel ===
                                0 ? (
                              <>
                                {props.activeDataSourceName === "FACEBOOK" ||
                                props.activeDataSourceName === "INSTAGRAM" ||
                                props.activeDataSourceName === "LINKEDIN" ? (
                                  <FontAwesomeIcon
                                    icon={faFacebookMessenger}
                                    className="messenger-icon add-channel-icon"
                                  />
                                ) : (
                                  <MailIcon className="mail-icon add-channel-icon" />
                                )}
                              </>
                            ) : null}

                            {account?.id === props?.selectedAcount?.id ? (
                              <Box className="selected-account">
                                <FontAwesomeIcon icon={faCheck} />
                              </Box>
                            ) : null}
                          </ListItem>
                        </List>
                      </Grid>
                    ),
                  )
                )}

                {unAuthAccountsMsg &&
                props.authorizedAccountsLoading === false ? (
                  <Grid item xs={12} className="authorized-accounts-msg">
                    {activeSourceName === "googlemybusiness" ? (
                      <span>
                        {CheckValueLocale(
                          "authenticated_google_account_msg",
                          "",
                          {},
                          intl,
                        )}
                        <span>
                          <Google className="google-icon-account-icon" />
                        </span>
                        {CheckValueLocale("sign_in_with_google", "", {}, intl)}.
                      </span>
                    ) : (
                      intl.formatMessage(
                        {
                          id:
                            activeSourceName === "whatsapp"
                              ? "non_added_whatsapp_number_msg"
                              : "authenticated_account_msg",
                        },
                        {
                          activeDataSourceName: CheckValueLocale(
                            activeDataSource.name.toLocaleLowerCase(),
                            "",
                            {},
                            intl,
                          ),
                        },
                      )
                    )}
                  </Grid>
                ) : null}
              </Grid>
            )}
          </>
        )}

        {(handlelUserRoles("GENERAL", "CREATE_INTEGRATION") ||
          handlelUserRoles("GENERAL", "CREATE_COMPANY_AUTH")) && (
          <>
            {(activeDataSource?.name == "TWITTER" ||
              activeDataSource?.name == "FACEBOOK" ||
              activeDataSource?.name == "INSTAGRAM") &&
            props?.selectedAcount?.type == "public" ? (
              <>
                <Divider />
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={6} className="add-channel-form-create-mergin">
                    <CustomerCareChannel
                      monitorData={props?.monitorData}
                      setMonitorData={props?.setMonitorData}
                      setMonitorErrors={props?.setMonitorErrors}
                      monitorErrors={props?.monitorErrors}
                    />
                  </Grid>
                  {activeDataSource?.name === "TWITTER" ? (
                    <Grid
                      item
                      xs={6}
                      className="add-channel-form-create-mergin"
                    >
                      <InputWithAddButton
                        labelName={"ignore_tweets"}
                        setMonitorErrors={props?.setMonitorErrors}
                        monitorErrors={props?.monitorErrors}
                        setMonitorData={props?.setMonitorData}
                        monitorData={props?.monitorData}
                        keyFeild={"ignoreTweets"}
                        placeholder={
                          "insert_username_then_enter_with_add_button"
                        }
                        handleChange={(e: any) =>
                          handleChangeInput(
                            e,
                            setIgnoreTweet,
                            "ignoreTweets",
                            [props?.monitorData?.customerCareVal],
                            props?.setMonitorErrors,
                            props?.monitorData,
                          )
                        }
                        handleAddKeywordsTolist={() =>
                          handleAddKeywordsTolist(
                            ignoreTweet,
                            setIgnoreTweet,
                            "ignoreTweets",
                            [props?.monitorData?.customerCareVal],
                            props?.setMonitorErrors,
                            props?.setMonitorData,
                            props?.monitorData,
                            100,
                          )
                        }
                        value={ignoreTweet}
                        limitCount={100}
                      />
                    </Grid>
                  ) : null}
                </Grid>
              </>
            ) : null}

            <Divider />
            <div style={{ textAlign: "start" }}>
              <Typography sx={{ margin: "revert" }}>
                {CheckValueLocale(
                  activeSourceName === "whatsapp"
                    ? "add_your_whatsapp_account"
                    : "auth_your_account",
                  "",
                  {},
                  intl,
                )}
              </Typography>

              {activeSourceName === "googlemybusiness" ? (
                <LucButton
                  startIcon={<Google className="google-icon-sign-in" />}
                  variant="outline"
                  disabled={checkGoogleMyBusinessAccounts()}
                  onClick={() => {
                    setSocialLocalStorages();
                    handleAuth(activeSourceName);
                  }}
                  id="channel-analytics-set-up-channel-sign-in-with-google-btn"
                >
                  <span className="google-sign-in-text">
                    {CheckValueLocale("sign_in_with_google", "", {}, intl)}
                  </span>
                </LucButton>
              ) : activeSourceName === "genesys" ? (
                <LucButton
                  variant="outline"
                  startIcon={<Add />}
                  disabled={checkGoogleMyBusinessAccounts()}
                  onClick={() => {
                    setOpenGenesysModal(true);
                  }}
                  id="channel-analytics-set-up-channel-auth-new-account-btn"
                >
                  {intl.formatMessage(
                    { id: "auth_new_account" },
                    {
                      activeDataSourceName: CheckValueLocale(
                        activeDataSource.name.toLocaleLowerCase(),
                        "",
                        {},
                        intl,
                      ),
                    },
                  )}
                </LucButton>
              ) : activeSourceName === "whatsapp" ? (
                <LucButton
                  variant="outline"
                  startIcon={<Add />}
                  // disabled={checkGoogleMyBusinessAccounts()}
                  onClick={() => {
                    setOpenWhatsAppPopup(true);
                  }}
                >
                  {intl.formatMessage(
                    { id: "auth_new_account" },
                    {
                      activeDataSourceName: CheckValueLocale(
                        activeDataSource?.name?.toLocaleLowerCase(),
                        "",
                        {},
                        intl,
                      ),
                    },
                  )}
                </LucButton>
              ) : (
                <LucButton
                  startIcon={<Add />}
                  variant="outline"
                  disabled={checkGoogleMyBusinessAccounts()}
                  onClick={() => {
                    setSocialLocalStorages();
                    handleAuth(activeSourceName);
                  }}
                  id="channel-analytics-set-up-channel-auth-new-account-btn"
                >
                  {intl.formatMessage(
                    { id: "auth_new_account" },
                    {
                      activeDataSourceName: CheckValueLocale(
                        activeDataSource.name.toLocaleLowerCase(),
                        "",
                        {},
                        intl,
                      ),
                    },
                  )}
                </LucButton>
              )}
            </div>
          </>
        )}
      </div>

      <AddAccountDialog
        onClose={handleDialogClose}
        open={dialogOpen}
        onAccept={handleAccept}
      />
      <InterComDialog
        onClose={handleInterComDialog}
        open={interComDialogState}
        onAccept={acceptInterComDialog}
      />
      <SnackBar
        open={openSuccesInterCom}
        severity="success"
        handleClose={handleCloseSuccessIntercom}
        message={CheckValueLocale("intercom_added_success", "", {}, intl)}
      />
      {openGenesysModal ? (
        <GenesysPopup
          openGenesysModal={openGenesysModal}
          setOpenGenesysModal={setOpenGenesysModal}
          genesysDataSourceId={props?.genesysDataSourceId}
          setSocialLocalStorages={setSocialLocalStorages}
          setFailSnackBarData={props?.setFailSnackBarData}
          setOpenSnackBarAllSource={props?.setOpenSnackBarAllSource}
        />
      ) : null}

      <SnackBar
        open={openPendingWhatsappAlert}
        severity="error"
        handleClose={handleClosePendingWhatsapp}
        message={CheckValueLocale("account_pending", "", {}, intl)}
      />
      {/* LinkedIn Auth Alert */}
      {errorMessageTitle !== "" && (
        <SnackBar
          open={openErrorLinkedInAuthAlert}
          severity="error"
          handleClose={handleCloseAuthMsgLinkedIn}
          title={CheckValueLocale(errorMsgTitle, "", {}, intl)}
          message={errorMessageTitle}
          snackBarId="snackbar-linkedin-auth-alert-msg"
        />
      )}

      <Snackbar
        open={openAccountAuthSnakbar}
        severity={accountAuthenticated ? "success" : "error"}
        message={
          accountAuthenticated
            ? CheckValueLocale("authenticate_success_msg", "", {}, intl)
            : CheckValueLocale("authenticate_error_msg", "", {}, intl)
        }
        handleClose={handleCloseAuthSnackBar}
      />
      {props?.openSnackBarAllSource ? (
        <SnackBar
          open={props?.openSnackBarAllSource}
          severity={props?.failSnackBarData?.severity}
          message={CheckValueLocale(
            props?.failSnackBarData?.message,
            "",
            {},
            intl,
          )}
          title={CheckValueLocale(props?.failSnackBarData?.title, "", {}, intl)}
          handleClose={handleCloseSnackBar}
        />
      ) : null}

      {/* Whatsapp Auth Popup & SnackBar */}
      {openWhatsAppPopup ? (
        <WhatsappNewAccPopup
          openWhatsAppPopup={openWhatsAppPopup}
          setOpenWhatsAppPopup={setOpenWhatsAppPopup}
          setwhatsAppDirectActive={setwhatsAppDirectActive}
          whatsAppdirectActive={whatsAppdirectActive}
          getAuthorizedAccounts={() => {
            props?.getAuthorizedAccounts?.(
              activeDataSource.id,
              "1",
              categoryName(activeDataSource?.id)?.name as string,
            );
          }}
          accounts={accounts}
          setAddWhatsappUserSnackBar={setAddWhatsappUserSnackBar}
        />
      ) : (
        ""
      )}

      <SnackBar
        snackBarId="add-new-whatsapp-user-submit-snackbar"
        open={addWhatsappUserSnackBar?.openSnackBar}
        handleClose={() => setAddWhatsappUserSnackBar({})}
        severity={addWhatsappUserSnackBar?.severity}
        title={CheckValueLocale(addWhatsappUserSnackBar?.title, "", {}, intl)}
        message={CheckValueLocale(
          addWhatsappUserSnackBar?.message,
          "",
          {},
          intl,
        )}
      />
    </Paper>
  );
};
export default SetUpChannelStep;
