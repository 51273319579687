import {
  Box,
  Typography,
  Button,
  InputLabel,
  Checkbox,
  ListItemText,
  Tooltip,
  ClickAwayListener,
} from "@mui/material";
import {
  CheckValueLocale,
  classNames,
  getSocialIcon,
  handleTwitterURLPaste,
  isArabic,
} from "../../../../../utils/helpers/index.js";
import { useState } from "react";
import { useIntl } from "react-intl";
import "./filters.scss";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import CancelIcon from "@mui/icons-material/Cancel";
import InputAdornment from "@mui/material/InputAdornment";
import filterIcon from "images/engagements-images/Path 2044.svg";
import LucButton from "shared/lucButton/lucButton.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCommentMedical,
  faFilter,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import DefaultUser from "images/shared-images/user-management-default.svg";
import TruncatedTooltip from "shared/truncatedTooltip/truncatedTooltip.js";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  style: { zIndex: 2000 },
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// this use to clear all selcted data

const initialSavedFilters = {
  data_sources: [],
  company_monitors: {
    monitors: [],
    channels: [],
  },
  engagement_types: [],
  completion_reasons: [],
  engegements_id: "",
  themes: [],
  sub_themes: [],
  sentiments: [],
  questions_only: false,
  verified_accounts_only: false,
  min_followers: "0",
  parent_id: "0",
  users_accounts: [],
  sla: [],
  team: [],
  assign: [],
  tags: [],
};

const Filters = ({
  filters,
  setFilters,
  closeFilter,
  savedFilters,
  setSavedFilters,
  enabledFilters,
  applyFilters,
}) => {
  const intl = useIntl();
  // hide sla filters if the active sla tab is not all or overdue tab.
  const activeSLaTab = filters?.sla_status?.replace?.("_sla", "");
  const slaFilters = ["all", "overdue"].includes(activeSLaTab)
    ? {
        SLAs: {
          sla: enabledFilters?.sla?.length ? [...enabledFilters?.sla] : [],
          team: enabledFilters?.teams?.length ? [...enabledFilters?.teams] : [],
          assign: enabledFilters?.assignee?.length
            ? [...enabledFilters?.assignee]
            : [],
        },
      }
    : {};

  // filter fields data
  const filterData = {
    sources_monitors: {
      data_sources: enabledFilters?.data_sources?.length
        ? [...enabledFilters?.data_sources]
        : [],
      company_monitors: {
        monitors: enabledFilters?.company_monitors?.monitors?.length
          ? [...enabledFilters?.company_monitors?.monitors]
          : [],
        channels: enabledFilters?.company_monitors?.channels?.length
          ? [...enabledFilters?.company_monitors?.channels]
          : [],
      },
    },
    engegements: {
      engagement_types: enabledFilters?.engagement_types?.length
        ? [...enabledFilters?.engagement_types]
        : [],
      engegements_id: "",
      completion_reasons: enabledFilters?.completion_reasons?.length
        ? [...enabledFilters?.completion_reasons]
        : [],
    },
    ...slaFilters,
    themes: {
      themes: enabledFilters?.themes?.length ? [...enabledFilters?.themes] : [],
      sub_themes: enabledFilters?.sub_themes?.length
        ? [...enabledFilters?.sub_themes]
        : [],
      tags: enabledFilters?.tags?.length ? [...enabledFilters?.tags] : [],
      sentiments: enabledFilters?.sentiments?.length
        ? [...enabledFilters?.sentiments]
        : [],
      questions_only: false,
      verified_accounts_only: false,
    },
    users: {
      min_followers: 0,
      users_accounts: [],
    },
    parentId: {
      parent_id: 0,
    },
  };
  const [userAccount, setUserAccount] = useState("");
  const [isParentIdTooltipOpen, setIsParentIdTooltipOpen] = useState();

  // handle change for multi select inputs
  const handleChange = (event, perant) => {
    let selectedItem = event?.target?.value[event?.target?.value?.length - 1];
    if (event?.target?.name === "company_monitors") {
      if (selectedItem?.type == "monitor") {
        let isExistMonitors = savedFilters?.company_monitors?.monitors?.filter(
          (item) => item?.id == selectedItem?.id,
        );
        if (isExistMonitors?.length) {
          setSavedFilters({
            ...savedFilters,
            company_monitors: {
              ...savedFilters?.company_monitors,
              monitors: savedFilters?.company_monitors?.monitors?.filter(
                (item) => item?.id !== selectedItem?.id,
              ),
            },
          });
        } else {
          setSavedFilters({
            ...savedFilters,
            company_monitors: {
              ...savedFilters?.company_monitors,
              monitors: [
                ...savedFilters?.company_monitors?.monitors,
                selectedItem,
              ],
            },
          });
        }
      }
      if (selectedItem?.type == "channels") {
        let isExistMonitors = savedFilters?.company_monitors?.channels?.filter(
          (item) => item?.id == selectedItem?.id,
        );
        if (isExistMonitors?.length) {
          setSavedFilters({
            ...savedFilters,
            company_monitors: {
              ...savedFilters?.company_monitors,
              channels: savedFilters?.company_monitors?.channels?.filter(
                (item) => item?.id !== selectedItem?.id,
              ),
            },
          });
        } else {
          setSavedFilters({
            ...savedFilters,
            company_monitors: {
              ...savedFilters?.company_monitors,
              channels: [
                ...savedFilters?.company_monitors?.channels,
                selectedItem,
              ],
            },
          });
        }
      }
    } else {
      if (selectedItem == "all") {
        if (
          savedFilters[event?.target?.name]?.length ===
          filterData[perant][event?.target?.name]?.length
        ) {
          setSavedFilters({
            ...savedFilters,
            [event?.target?.name]: [],
          });
        } else {
          setSavedFilters({
            ...savedFilters,
            [event?.target?.name]: [...filterData[perant][event?.target?.name]],
          });
        }
      } else if (event?.target?.name === "tags") {
        setSavedFilters({
          ...savedFilters,
          tags:
            selectedItem === "allTags"
              ? savedFilters?.tags?.length === filterData[perant]?.tags?.length
                ? []
                : filterData[perant]?.tags?.map((tag) => tag?.id) || []
              : selectedItem === "Untagged"
                ? !filterData[perant]?.tags?.includes("Untagged")
                  ? ["Untagged"]
                  : []
                : event.target.value,
        });
      } else {
        setSavedFilters({
          ...savedFilters,
          [event?.target?.name]: [...event?.target?.value],
        });
      }
    }
  };

  // shared fun handle multi select input
  const multiSelect = (perant, selectName) => {
    return (
      <Box key={perant + selectName}>
        <FormControl
          key={perant + selectName}
          sx={{ m: 1, width: 300 }}
          className="select-menu"
        >
          <Select
            key={perant + selectName}
            id={`demo-multiple-checkbox-${selectName}`}
            className="select-input"
            displayEmpty
            multiple
            value={
              selectName == "company_monitors"
                ? [
                    ...savedFilters?.company_monitors?.monitors,
                    ...savedFilters?.company_monitors?.channels,
                  ]
                : savedFilters[selectName]
            }
            name={selectName}
            disabled={false}
            onChange={(e) => handleChange(e, perant)}
            input={<OutlinedInput />}
            renderValue={() => {
              return selectName == "company_monitors" ? (
                savedFilters?.company_monitors?.monitors?.length ||
                savedFilters?.company_monitors?.channels?.length ? (
                  [
                    ...savedFilters?.company_monitors?.monitors,
                    ...savedFilters?.company_monitors?.channels,
                  ]
                    ?.map((item) => item?.name)
                    .join(", ")
                ) : (
                  <span className="select-placeholder">
                    {CheckValueLocale("select", "", {}, intl)}
                  </span>
                )
              ) : perant == "SLAs" && savedFilters[selectName]?.length ? (
                filterData[perant][selectName]
                  ?.filter((item) =>
                    savedFilters[selectName]?.includes(item?.id),
                  )
                  ?.map((item) => item?.name)
                  .join(", ")
              ) : selectName == "sub_themes" &&
                savedFilters?.[selectName]?.length ? (
                filterData?.[perant]?.[selectName]
                  ?.filter((item) =>
                    savedFilters?.[selectName]?.includes(item?.value),
                  )
                  ?.map((item) =>
                    intl?.locale === "ar" ? item?.label_ar : item?.label_en,
                  )
                  ?.join(", ")
              ) : perant !== "SLAs" &&
                savedFilters[selectName]?.length &&
                selectName !== "tags" ? (
                savedFilters[selectName]
                  ?.map((item) => CheckValueLocale(item, "", {}, intl))
                  .join(", ")
              ) : selectName == "tags" && savedFilters[selectName]?.length ? (
                savedFilters[selectName]?.length ===
                filterData[perant]?.tags?.length ? (
                  CheckValueLocale("all", "", {}, intl)
                ) : savedFilters[selectName]?.includes("Untagged") ? (
                  CheckValueLocale("untagged_engagements", "", {}, intl)
                ) : (
                  savedFilters[selectName]
                    ?.map(
                      (item) =>
                        filterData?.[perant]?.[selectName]?.find(
                          (item2) => item2?.id == item,
                        )?.label || "Unknown",
                    )
                    .join(", ")
                )
              ) : (
                <span className="select-placeholder">
                  {CheckValueLocale("select", "", {}, intl)}
                </span>
              );
            }}
            MenuProps={MenuProps}
            inputProps={{ "aria-label": "Without label" }}
          >
            {selectName == "company_monitors" &&
            filterData[perant][selectName]?.monitors?.length ? (
              <Typography className="menu-item-header">
                {CheckValueLocale("select_engagement_monitors", "", {}, intl)}
              </Typography>
            ) : null}
            {selectName == "company_monitors"
              ? filterData[perant][selectName]?.monitors?.map((item) => {
                  return (
                    <MenuItem
                      key={selectName + item?.id}
                      value={{ ...item, type: "monitor" }}
                      className="engagement-filter-monitors-menu-item"
                    >
                      <MonitorAndChannelsMenuItem
                        key={selectName + item?.id}
                        item={item}
                        isSelected={savedFilters?.company_monitors?.monitors
                          ?.map((val) => val?.id)
                          .includes(item?.id)}
                      />
                    </MenuItem>
                  );
                })
              : null}
            {selectName == "company_monitors" &&
            filterData[perant][selectName]?.channels?.length ? (
              <Typography className="menu-item-header">
                {CheckValueLocale("select_engagement_channels", "", {}, intl)}
              </Typography>
            ) : null}
            {selectName == "company_monitors"
              ? filterData[perant][selectName]?.channels?.map((item) => {
                  return (
                    <MenuItem
                      key={selectName + item?.id}
                      value={{ ...item, type: "channels" }}
                      className="engagement-filter-monitors-menu-item"
                    >
                      <MonitorAndChannelsMenuItem
                        key={selectName + item?.id}
                        item={item}
                        isSelected={savedFilters?.company_monitors?.channels
                          ?.map((val) => val?.id)
                          .includes(item?.id)}
                      />
                    </MenuItem>
                  );
                })
              : null}
            {selectName == "engagement_types" ||
            selectName == "completion_reasons" ? (
              <MenuItem key={selectName + "all" + perant} value={"all"}>
                <Box className="engagement-menu-item-countainer">
                  <Checkbox
                    checked={
                      savedFilters[selectName]?.length ==
                      filterData[perant][selectName]?.length
                    }
                  />
                  <ListItemText
                    primary={CheckValueLocale("all", "", {}, intl)}
                  />
                </Box>
              </MenuItem>
            ) : null}
            {selectName == "company_monitors" ||
            selectName == "sub_themes" ||
            perant == "SLAs" ||
            selectName == "tags"
              ? null
              : filterData[perant][selectName]?.map((item, index) => {
                  return (
                    <MenuItem
                      key={selectName + index + perant}
                      value={item}
                      id={`engagement-filter-${selectName}-${item}`}
                    >
                      <Box className="engagement-menu-item-countainer">
                        <Checkbox
                          checked={savedFilters[selectName]?.includes(item)}
                          id={`engagement-filter-${selectName}-${item}-checkbox`}
                        />
                        <ListItemText
                          primary={CheckValueLocale(
                            item == "posts"
                              ? "post_content_type"
                              : item === "direct_messages"
                                ? "direct_messages_new"
                                : item,
                            "",
                            {},
                            intl,
                          )}
                        />
                      </Box>
                    </MenuItem>
                  );
                })}
            {perant == "SLAs"
              ? filterData[perant][selectName]?.map((item, index) => {
                  return (
                    <MenuItem
                      key={selectName + index + perant}
                      value={item?.id}
                    >
                      <Box className="engagement-menu-item-countainer">
                        <Checkbox
                          checked={savedFilters[selectName]?.includes(item?.id)}
                        />
                        {selectName == "assign" ? (
                          <Box className="assign-item-countainer">
                            <Box className="assign-item-details">
                              <Box>{item?.name}</Box>
                              <Box className="assign-item-state-container">
                                <Box
                                  className={classNames(
                                    "status-circle",
                                    item?.agent_status,
                                  )}
                                />
                                <Box className="assign-item-state">
                                  {CheckValueLocale(
                                    `agent_status_${item?.agent_status}`,
                                    "",
                                    {},
                                    intl,
                                  )}
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        ) : (
                          <ListItemText primary={item?.name} />
                        )}
                      </Box>
                    </MenuItem>
                  );
                })
              : null}
            {selectName === "sub_themes"
              ? filterData?.themes?.[selectName]?.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item?.value}>
                      <Box className="engagement-menu-item-countainer">
                        <Checkbox
                          checked={savedFilters?.[selectName]?.includes(
                            item?.value,
                          )}
                        />
                        <ListItemText
                          primary={
                            intl?.locale === "ar"
                              ? item?.label_ar
                              : item?.label_en
                          }
                        />
                      </Box>
                    </MenuItem>
                  );
                })
              : null}
            {selectName === "tags" ? (
              <MenuItem
                value={"Untagged"}
                key={"untagged"}
                className="engagement-tags untagged-engagements"
                disabled={
                  savedFilters?.tags?.length ===
                  filterData?.themes?.tags?.length
                }
              >
                <Box className="engagement-menu-item-countainer tag-filter-container">
                  <Checkbox
                    checked={savedFilters?.tags?.includes("Untagged")}
                  />
                  <Typography>
                    {CheckValueLocale("untagged_engagements", "", {}, intl)}
                  </Typography>
                </Box>
              </MenuItem>
            ) : null}
            {selectName === "tags" ? (
              <MenuItem
                value={"allTags"}
                key={"allTags"}
                disabled={savedFilters?.tags?.includes("Untagged")}
              >
                <Box className="engagement-menu-item-countainer tag-filter-container">
                  <Checkbox
                    checked={
                      savedFilters?.tags?.length ===
                      filterData?.themes?.tags?.length
                    }
                  />
                  <Typography>
                    {CheckValueLocale("all_tags", "", {}, intl)}
                  </Typography>
                </Box>
              </MenuItem>
            ) : null}
            {selectName === "tags"
              ? filterData?.themes?.tags?.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={item?.id}
                      disabled={savedFilters?.tags?.includes("Untagged")}
                    >
                      <Box className="engagement-menu-item-countainer tag-filter-container">
                        <Checkbox
                          checked={savedFilters?.tags?.includes(item?.id)}
                        />
                        <Box
                          className="color-tag-box"
                          style={{ backgroundColor: item?.color }}
                        ></Box>
                        <ListItemText primary={item?.label} />
                      </Box>
                    </MenuItem>
                  );
                })
              : null}
          </Select>
          {selectName === "tags" ? (
            <Box className="exact-match-container">
              <Box>
                <Typography component={"h4"}>
                  {CheckValueLocale("match_all_selected_tags", "", {}, intl)}
                </Typography>
                <Typography>
                  {CheckValueLocale(
                    "match_all_selected_tags_dec",
                    "",
                    {},
                    intl,
                  )}
                </Typography>
              </Box>
              <Switch
                checked={savedFilters?.exact_match}
                onChange={() => {
                  setSavedFilters({
                    ...savedFilters,
                    exact_match: !savedFilters?.exact_match,
                  });
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Box>
          ) : null}
        </FormControl>
      </Box>
    );
  };

  const handleMinFollowersAndParentId = (e) => {
    let val = e.target.value.trim();
    let selectedValue = "";
    const name = e.target.name;
    if (!val.length) {
      selectedValue = "";
    } else {
      if (val.length == 1 && val[0] == "0") {
        selectedValue = "0";
      } else {
        // prevent start number with 0
        if (val[0] == "0") {
          selectedValue = "";
        } else {
          val?.split("")?.map((dig) => {
            if (/^\d+$/.test(dig)) {
              selectedValue += dig;
            }
          });
        }
      }
    }
    setSavedFilters({ ...savedFilters, [name]: selectedValue });
  };

  const handleUsersAccounts = (e) => {
    if ((e.charCode == 13 || e.code == "Enter") && userAccount?.length) {
      if (!savedFilters?.users_accounts?.includes(userAccount)) {
        setSavedFilters({
          ...savedFilters,
          users_accounts: [...savedFilters?.users_accounts, userAccount],
        });
      }
      setUserAccount("");
    }
  };

  const handleApplyFilters = () => {
    filters.page = 1;
    filters.company_monitors = {
      monitors: [
        ...savedFilters?.company_monitors?.monitors?.map((item) => item?.id),
      ],
      channels: [
        ...savedFilters?.company_monitors?.channels?.map((item) => item?.id),
      ],
    };
    setFilters({
      ...filters,
    });
    applyFilters();
    closeFilter();
  };

  const handlePasteTwitterURL = (event) => {
    const pastedText = event.clipboardData.getData("text");
    event.preventDefault();
    const { extractedId, message } = handleTwitterURLPaste(pastedText);
    setIsParentIdTooltipOpen(message);
    handleMinFollowersAndParentId({
      target: {
        value: extractedId || "",
        name: "parent_id",
      },
    });
  };

  return (
    <>
      <Box
        className="main-engagement-filter-container"
        onClick={() => {
          closeFilter();
        }}
      ></Box>
      <Box className="main-engagement-filter">
        <Box className="main-filter-header">
          <Box className="main-filter-header-title">
            <FontAwesomeIcon icon={faFilter} />
            <Typography className="filter-main-title">
              {CheckValueLocale("filters_engagment", "", {}, intl)}
            </Typography>
          </Box>
          <LucButton
            variant="outline"
            size="small"
            type="secondary"
            onClick={() => {
              closeFilter();
            }}
            id="engagements-filter-hide-btn"
          >
            {CheckValueLocale("hide", "", {}, intl)}
          </LucButton>
        </Box>
        <Box className="main-filter-contant">
          {Object.keys(filterData).map(function (key, index) {
            return typeof filterData[key] == "object" ? (
              <Box key={key + index} className="filter-part">
                {Object.keys(filterData[key]).map(function (item, index2) {
                  return typeof filterData[key][item] == "object" &&
                    item !== "users_accounts" ? (
                    <Box key={item + index2}>
                      <Typography className="item-header">
                        {CheckValueLocale(
                          item === "themes"
                            ? "filter_category"
                            : item === "sub_themes"
                              ? "filter_themes"
                              : item,
                          "",
                          {},
                          intl,
                        )}
                      </Typography>
                      {multiSelect(key, item)}
                    </Box>
                  ) : item == "engegements_id" ? (
                    <Box className="filter-input">
                      <Typography className="item-header">
                        {CheckValueLocale(item, "", {}, intl)}
                      </Typography>
                      <OutlinedInput
                        placeholder={CheckValueLocale(
                          "engegements_id_ex",
                          "",
                          {},
                          intl,
                        )}
                        value={savedFilters?.engegements_id}
                        onChange={(e) =>
                          setSavedFilters({
                            ...savedFilters,
                            engegements_id: e.target.value,
                          })
                        }
                      />
                    </Box>
                  ) : typeof filterData[key][item] == "boolean" ? (
                    <Box key={item} className="filter-boolean">
                      <FormControlLabel
                        value={item}
                        control={
                          <Switch
                            checked={savedFilters[item]}
                            color="primary"
                            onChange={(e) =>
                              setSavedFilters({
                                ...savedFilters,
                                [item]: !savedFilters[item],
                              })
                            }
                          />
                        }
                        label={CheckValueLocale(item, "", {}, intl)}
                        labelPlacement="start"
                      />
                    </Box>
                  ) : item == "min_followers" || item == "parent_id" ? (
                    <Box className="filter-input">
                      {item === "parent_id" ? (
                        <Box className="engagement-twitter-parent-id-title">
                          <Typography className="item-header">
                            {CheckValueLocale(item, "", {}, intl)}
                          </Typography>
                          <Tooltip
                            arrow
                            placement="top"
                            id="engagement-tooltip-parent-id"
                            title={CheckValueLocale(
                              "parent_id_filter_tooltip_title",
                              "",
                              {},
                              intl,
                            )}
                          >
                            <FontAwesomeIcon
                              icon={faCircleInfo}
                              style={{ color: "rgba(148, 163, 184, 1)" }}
                            />
                          </Tooltip>
                        </Box>
                      ) : (
                        <Typography className="item-header">
                          {CheckValueLocale(item, "", {}, intl)}
                        </Typography>
                      )}
                      <ClickAwayListener
                        onClickAway={() => setIsParentIdTooltipOpen(false)}
                      >
                        <Tooltip
                          arrow
                          id="engagement-tooltip-parent-id"
                          placement="left"
                          onClose={() => setIsParentIdTooltipOpen(false)}
                          open={!!isParentIdTooltipOpen && item === "parent_id"}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          title={CheckValueLocale(
                            isParentIdTooltipOpen,
                            "",
                            {},
                            intl,
                          )}
                        >
                          <OutlinedInput
                            value={savedFilters?.[item]}
                            placeholder={CheckValueLocale(item, "", {}, intl)}
                            name={item}
                            inputProps={
                              item === "parent_id"
                                ? {
                                    maxLength: 19,
                                  }
                                : undefined
                            }
                            onPaste={(e) => {
                              if (item === "parent_id") {
                                handlePasteTwitterURL(e);
                              }
                            }}
                            onChange={(e) => handleMinFollowersAndParentId(e)}
                          />
                        </Tooltip>
                      </ClickAwayListener>

                      {item === "parent_id" ? (
                        <Typography className="user-click-add">
                          {CheckValueLocale(
                            "engagement_parent_id_helper_text",
                            "",
                            {},
                            intl,
                          )}
                        </Typography>
                      ) : null}
                    </Box>
                  ) : item == "users_accounts" ? (
                    <Box className="filter-input">
                      <Typography className="item-header">
                        {CheckValueLocale(item, "", {}, intl)}
                      </Typography>
                      <OutlinedInput
                        value={userAccount}
                        startAdornment={
                          <InputAdornment position="start">@</InputAdornment>
                        }
                        placeholder={CheckValueLocale(
                          "search_by_username_input_ingagement",
                          "",
                          {},
                          intl,
                        )}
                        onKeyPress={(e) => handleUsersAccounts(e)}
                        onChange={(e) => setUserAccount(e.target.value)}
                      />

                      {!savedFilters?.users_accounts?.length ? (
                        <Typography className="user-click-add">
                          {CheckValueLocale(
                            "click_add_text_ingagement",
                            "",
                            {},
                            intl,
                          )}
                        </Typography>
                      ) : (
                        <Box>
                          {savedFilters?.users_accounts?.map((item, index) => (
                            <Button
                              key={index}
                              variant="outlined"
                              className="selected-users-accounts-lable"
                              endIcon={
                                <CancelIcon
                                  onClick={() =>
                                    setSavedFilters({
                                      ...savedFilters,
                                      users_accounts:
                                        savedFilters?.users_accounts?.filter(
                                          (i) => item !== i,
                                        ),
                                    })
                                  }
                                />
                              }
                              id="report-edit-report-selected-email-btn"
                            >
                              {item}
                            </Button>
                          ))}
                        </Box>
                      )}
                    </Box>
                  ) : null;
                })}
              </Box>
            ) : null;
          })}
        </Box>
        <Box className="filter-part main-buttons" display="flex">
          <LucButton
            variant="filled"
            size="large"
            onClick={handleApplyFilters}
            fullWidth={true}
            id={"monitor-active-Tab-filter-id-apply-btn"}
          >
            {CheckValueLocale("apply_ingagement", "", {}, intl)}
          </LucButton>
          <LucButton
            variant="flat"
            type="secondary"
            size="large"
            onClick={() => {
              setSavedFilters({ ...initialSavedFilters });
            }}
            fullWidth={true}
            id={"monitor-active-Tab-filter-id-clear-btn"}
          >
            {CheckValueLocale("clear_all", "", {}, intl)}
          </LucButton>
        </Box>
      </Box>
    </>
  );
};

const MonitorAndChannelsMenuItem = ({ item, isSelected }) => {
  const { name, paused, data_sources, is_dm } = item;
  return (
    <>
      <Checkbox checked={isSelected} />
      <Box className="engagement-filter-menu-item-option-container">
        <Box className="engagement-menu-item-option">
          <Box
            className={classNames(
              "engagement-menu-item-option-status",
              paused ? "paused" : "",
            )}
          />
          <TruncatedTooltip
            title={name}
            enablePropperProps={true}
            tooltipClassName={classNames(
              "engagement-menu-item-option-tooltip",
              isArabic(name) ? "arabic-tooltip" : "",
            )}
            placement="top"
            className="engagement-menu-item-option-name"
          />
          <Box className="engagement-menu-item-option-datasources">
            {data_sources?.map?.((data_source) => {
              let dataSource = data_source?.toUpperCase();
              if (is_dm) {
                dataSource += "_private";
              }
              return <Box key={dataSource}>{getSocialIcon(dataSource)}</Box>;
            })}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Filters;
