import React from "react";
import { Box, Tooltip } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale, commasAfterDigit } from "utils/helpers";
import "./headerTokens.scss";
import Services from "services/api/Services";

const HeaderTokens = ({ tokens }) => {
  const intl = useIntl();

  return (
    <Box
      className="header-tokens"
      onClick={() => window.open("/settings/subscription", "_blank")}
    >
      <Tooltip
        title={CheckValueLocale("luci_check_my_energy", "", {}, intl)}
        arrow
        placement={"bottom"}
        PopperProps={{ style: { zIndex: 9999 } }}
      >
        <Box className="header-tokens-wrapper">
          <img src={Services?.tokensIcon} alt="token icon" />
          <span className="header-tokens-value">
            {tokens ? commasAfterDigit(tokens) : "0"}
          </span>
        </Box>
      </Tooltip>
    </Box>
  );
};

export default HeaderTokens;
