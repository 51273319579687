import { Alert, Box } from "@mui/material";
import "./bouncedEmailsAlert.scss";
import { useIntl } from "react-intl";
import LucButton from "shared/lucButton/lucButton";
import { CheckValueLocale } from "utils/helpers";
import BouncedEmailsPopup from "./bouncedEmailsPopup";
import { useState } from "react";

const BouncedEmailsAlert = () => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const [numOfBouncedEmails, setNumOfBouncedEmails] = useState(0);
  return (
    <>
      {numOfBouncedEmails > 0 && (
        <Alert id="bounced-emails-alert" severity="warning">
          <Box className="bounced-emails-alert-text">
            <Box component={"span"}>
              {CheckValueLocale(
                "bounced_emails_with_count",
                "",
                { num: numOfBouncedEmails },
                intl,
              )}
            </Box>
            {CheckValueLocale("bounced_emails_text_alert", "", {}, intl)}
          </Box>
          <LucButton
            size="small"
            variant="flat"
            className="bounced-emails-alert-btn"
            onClick={() => setOpen(true)}
          >
            {CheckValueLocale("bounced_emails_view", "", {}, intl)}
          </LucButton>
        </Alert>
      )}
      <BouncedEmailsPopup
        open={open}
        setOpen={setOpen}
        setNumOfBouncedEmails={setNumOfBouncedEmails}
      />
    </>
  );
};
export default BouncedEmailsAlert;
