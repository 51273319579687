import { useQuery } from "@tanstack/react-query";
import SurveyController from "services/controllers/surveyController";

export const useEmbeddedFonts = ({ enabled }) => {
  return useQuery({
    queryKey: ["embeddedFonts"],
    queryFn: () => SurveyController.getEmbeddedSurveyFonts(),
    select: (data) => data?.data?.data,
    enabled: enabled,
    retry: false,
  });
};
