import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { MainMonitorsPage } from "services/api/mainMonitorsPage";

let socialMediaMonitorDataCancelToken;
export const useSocialMediaMonitorData = () => {
  return useMutation({
    mutationFn: ({ monitor_id, monitorDataSource, queryDataParams }) =>{
      if (socialMediaMonitorDataCancelToken) {
        socialMediaMonitorDataCancelToken.cancel(
          "Operation canceled due to a new request.",
        );
      }
      socialMediaMonitorDataCancelToken = axios?.CancelToken?.source();
      const queryData = {
        params: {
          ...queryDataParams
        },
        cancelToken: socialMediaMonitorDataCancelToken?.token
      };
      return MainMonitorsPage.getSocialMediaMonitorData({
        monitor_id,
        monitorDataSource,
        queryData,
      });
    }
  });
};
