import { useMutation } from "@tanstack/react-query";
import { MainMonitorsPage } from "services/api/mainMonitorsPage";

export const useTestKeywords = () => {
  return useMutation({
    mutationFn: (queryData) => {
      return MainMonitorsPage.testKeywords(queryData);
    },
  });
};
