import { Box, Button } from "@mui/material";
import "./mainEdit.scss";
import { useState, useEffect } from "react";
import { CheckValueLocale } from "../../../../../utils/helpers/index.js";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinnerThird,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-regular-svg-icons";
import EngagementsController from "services/controllers/engagementsController";
import SnackBar from "components/snackBar.js";
import SmIcon from "images/engagements-images/SmIcon.svg";
import CxmIcon from "images/engagements-images/CxmIcon.svg";
import AiSection from "./aiSection";
import CompaniesController from "services/controllers/companiesController";
import MainSelsctCard from "./mainSelsctCard";
import MainEditFooter from "./mainEditFooter";
import { useEngagementPermissions } from "hooks/useEngagementPermissions";
import GreetingAndFarewell from "./greetingAndFarewell";
import { processFarewellGreetingMessagesData } from "../utils/farewellGreetingUtils";
import AutomatedPublicReplies from "./automatedPublicreplies";
import { getAutomatedPublicRepliesState } from "../utils/publicRepliesUtils";
import { useSurveyCharacterLimits } from "./egagementSurvey/useSurveyChartsLimits";
import { useSurveyCsatOptions } from "./egagementSurvey/useSurveyCsatOptions";
import { useSurveyPredefinedOptions } from "./egagementSurvey/useSurveyPredefinedOptions";
import { useSurveySelectedData } from "./egagementSurvey/useSurveySelectedData";
import { useSurveysPublishedList } from "../../aiAgents/aiAgentsHooks/useSurveysPublishedList";
import _ from "lodash";
import { useQueryClient } from "@tanstack/react-query";
import EngagementTagsListPage from "../../engagementTags/tagList/engagementTagsListPage";
import ReplaySection from "../setup/replayCards/replaySection";

const MainEdit = () => {
  const queryClient = useQueryClient();

  const activeProducts = JSON.parse(
    window?.localStorage?.activeProducts || null,
  );
  const aiAgentActive = activeProducts?.find(
    (product) => product?.name == "AI_AGENT",
  );
  const surveyActive = activeProducts?.find(
    (product) => product?.name == "SURVEY",
  );
  const surveyFetchingCount = !!surveyActive ? 5 : 4;

  const intl = useIntl();
  const navigate = useNavigate();

  const [isSetup, setIsSetup] = useState(false);
  const [messagesData, setMessagesData] = useState({
    farewell_greeting_messages: [],
    farewell_greeting_enabled: {},
    lookup_messages: [],
    engagements_outside_hours_settings: {},
  });
  const [surveyData, setSurveyData] = useState({
    mainData: {
      sendAs: [
        { id: 1, text: "send_in_chat" },
        { id: 2, text: "share_servy_link" },
      ],
      rate_qs_en: [],
      rate_qs_ar: [],
      feed_qs_en: [],
      feed_qs_ar: [],
      send_survey_in_engag: [],
      unsupported_languages_engag: [],
      delivery_method_engag: [],
      delivery_method_engag_survey: [],
      expiry_time_engag: [],
      invalid_responses_engag: [],
      resend_attempts_engag: [],
      surveys_engag: [],
      instagram_limit: 200,
      facebook_limit: 200,
      twitter_limit: 200,
      whatsapp_limit: 200,
      feaching: 0,
    },
    selectedData: {
      instagram: {
        isEnabled: false,
        sendAs: 1,
        rate_qs_en: null,
        rate_qs_ar: null,
        feed_qs_en: null,
        feed_qs_ar: null,
        send_survey_in_engag: null,
        unsupported_languages_engag: null,
        delivery_method_engag: null,
        delivery_method_engag_survey: null,
        expiry_time_engag: null,
        invalid_responses_engag: null,
        resend_attempts_engag: null,
        thankMsg_en: "",
        thankMsg_ar: "",
        expiredMsg_en: "",
        expiredMsg_ar: "",
        surveys_engag: null,
      },
      facebook: {
        isEnabled: false,
        sendAs: 1,
        rate_qs_en: null,
        rate_qs_ar: null,
        feed_qs_en: null,
        feed_qs_ar: null,
        send_survey_in_engag: null,
        unsupported_languages_engag: null,
        delivery_method_engag: null,
        delivery_method_engag_survey: null,
        expiry_time_engag: null,
        invalid_responses_engag: null,
        resend_attempts_engag: null,
        thankMsg_en: "",
        thankMsg_ar: "",
        expiredMsg_en: "",
        expiredMsg_ar: "",
        surveys_engag: null,
      },
      twitter: {
        isEnabled: false,
        sendAs: 1,
        rate_qs_en: null,
        rate_qs_ar: null,
        feed_qs_en: null,
        feed_qs_ar: null,
        send_survey_in_engag: null,
        unsupported_languages_engag: null,
        delivery_method_engag: null,
        delivery_method_engag_survey: null,
        expiry_time_engag: null,
        invalid_responses_engag: null,
        resend_attempts_engag: null,
        thankMsg_en: "",
        thankMsg_ar: "",
        expiredMsg_en: "",
        expiredMsg_ar: "",
        surveys_engag: null,
      },
      whatsapp: {
        isEnabled: false,
        sendAs: 1,
        rate_qs_en: null,
        rate_qs_ar: null,
        feed_qs_en: null,
        feed_qs_ar: null,
        send_survey_in_engag: null,
        unsupported_languages_engag: null,
        delivery_method_engag: null,
        delivery_method_engag_survey: null,
        expiry_time_engag: null,
        invalid_responses_engag: null,
        resend_attempts_engag: null,
        thankMsg_en: "",
        thankMsg_ar: "",
        expiredMsg_en: "",
        expiredMsg_ar: "",
        surveys_engag: null,
      },
    },
  });
  const [initialSurveySelectedData, setInitialSurveySelectedData] = useState(
    _.cloneDeep({
      ...surveyData?.selectedData,
    }),
  );
  const [automatedPublicRepliesData, setAutomatedPublicRepliesData] = useState(
    {},
  );
  const [autoReplySelectedTab, setAutoReplySelectedTab] = useState("instagram");
  const [autoReplyErrorFields, setAutoReplyErrorFields] = useState({});
  const [snackBar, setSnackBar] = useState({
    open: false,
    title: "",
    message: "",
    severity: "",
  });
  const [selectedData, setSelectedData] = useState({
    mainData: {
      accounts: [],
      feaching: true,
      monitors: [],
      channels: [],
      company_products: {},
    },
    stepOne: {
      isPublishError: false,
      isCxmAcc: false,
      isEmptyError: false,
      isAccError: false,
      monitors: [],
      TWITTER_SlAcc: [],
      INSTAGRAM_SlAcc: [],
      FACEBOOK_SlAcc: [],
      channels: [],
      TWITTER_CxmAcc: [],
      INSTAGRAM_CxmAcc: [],
      FACEBOOK_CxmAcc: [],
      TWITTER_PubAcc: [],
      INSTAGRAM_PubAcc: [],
      FACEBOOK_PubAcc: [],
    },
    stepTwo: {
      luci_industry: "",
      luci_business_type: "",
      luci_business_hq: "",
      brand_tone: "",
      aboutYour: "",
      knowledge_base_url: "",
      isFileChanged: false,
      files: [],
      isFileUploaded: false,
      fetching: false,
      enabled: false,
    },
  });
  const [initialselectedData, setInitialselectedData] = useState({
    changedNum: 0,
    stepOne: {
      monitors: [],
      TWITTER_SlAcc: [],
      INSTAGRAM_SlAcc: [],
      FACEBOOK_SlAcc: [],
      cxm: [],
      TWITTER_CxmAcc: [],
      INSTAGRAM_CxmAcc: [],
      FACEBOOK_CxmAcc: [],
      TWITTER_PubAcc: [],
      INSTAGRAM_PubAcc: [],
      FACEBOOK_PubAcc: [],
    },
    stepTwo: {
      luci_industry: "",
      luci_business_type: "",
      luci_business_hq: "",
      brand_tone: "",
      aboutYour: "",
      knowledge_base_url: "",
      fileName: "",
      fileSize: 0,
    },
    farewell_greeting_messages: [],
    farewell_greeting_enabled: {},
    engagements_outside_hours_settings: {},
    lookup_messages: [],
    public_replies: [],
    public_replies_enabled: {},
  });

  const [slDemoDiasable, setSlDemoDiasable] = useState(false);
  const [cxmDemoDiasable, setCxmDemoDiasable] = useState(false);
  const { engagementPriorityProductPath } = useEngagementPermissions();
  //start of check if user set setup before, if not , redirect to setup page
  useEffect(() => {
    let engagementsProduct = JSON.parse(
      window?.localStorage?.getItem("activeProducts"),
    );
    let engagementsProductId = engagementsProduct?.filter(
      (item) => item?.name == "ENGAGEMENTS" && item?.active,
    );
    if (!engagementsProductId?.length) {
      navigate("/social/monitor_list");
    } else {
      setSelectedData({
        ...selectedData,
        mainData: {
          ...selectedData?.mainData,
          feaching: true,
        },
      });
      EngagementsController.getSavedMonitors(
        window?.localStorage?.engagements_id,
      ).then((data) => {
        if (!data?.data?.engagements_setuped) {
          navigate("/engagements/setup");
        } else {
          setIsSetup(true);
        }
      });
    }
  }, []);
  //end of check if user set setup before, if not , redirect to setup page

  //start of get all data and handle it

  const { data: charLimitResponse, isSuccess: isCharLimitSuccess } =
    useSurveyCharacterLimits(
      isSetup
        ? {
            product_id: window?.localStorage?.engagements_id,
            engagement_type: "direct_message",
          }
        : null,
    );

  const { data: csatOptionsResponse, isSuccess: isCsatOptionsSuccess } =
    useSurveyCsatOptions(
      isSetup && isCharLimitSuccess
        ? {
            product_id: window?.localStorage?.engagements_id,
          }
        : null,
    );

  const {
    data: predefinedOptionsResponse,
    isSuccess: isPredefinedOptionsSuccess,
  } = useSurveyPredefinedOptions(
    isSetup && isCharLimitSuccess && isCsatOptionsSuccess
      ? {
          product_id: window?.localStorage?.engagements_id,
        }
      : null,
  );

  const { data: surveySelectedData, isSuccess: isSurveySelectedDataSuccess } =
    useSurveySelectedData(
      isSetup &&
        isPredefinedOptionsSuccess &&
        isCsatOptionsSuccess &&
        isCharLimitSuccess
        ? {
            product_id: window?.localStorage?.engagements_id,
          }
        : null,
    );

  const { data: surveysList, isSuccess: isSurveysListSuccess } =
    useSurveysPublishedList(
      !!surveyActive &&
        isSetup &&
        isPredefinedOptionsSuccess &&
        isCsatOptionsSuccess &&
        isCharLimitSuccess &&
        isSurveySelectedDataSuccess,
    );

  useEffect(() => {
    return () => {
      queryClient.resetQueries();
    };
  }, [queryClient]);
  useEffect(() => {
    if (isSurveysListSuccess) {
      surveyData.mainData.surveys_engag = surveysList;
      surveyData.mainData.feaching = surveyData?.mainData?.feaching + 1;
      setSurveyData({ ...surveyData });
    }
  }, [surveysList, isSurveysListSuccess]);

  useEffect(() => {
    let selectedDataResponse = surveySelectedData?.data?.engagement_surveys;

    if (isSurveySelectedDataSuccess) {
      Object.keys(selectedDataResponse)?.map((dataSorce) => {
        surveyData.selectedData[dataSorce?.toLowerCase()].surveys_engag =
          selectedDataResponse?.[dataSorce]?.survey_link?.id;
        surveyData.selectedData[dataSorce?.toLowerCase()].isEnabled =
          selectedDataResponse?.[dataSorce]?.is_farewell_survey_enabled;
        surveyData.selectedData[dataSorce?.toLowerCase()].sendAs =
          selectedDataResponse?.[dataSorce]?.csat_survey?.is_csat_enabled
            ? 1
            : selectedDataResponse?.[dataSorce]?.survey_link?.id
              ? 2
              : 1;

        surveyData.selectedData[
          dataSorce?.toLowerCase()
        ].delivery_method_engag =
          selectedDataResponse?.[
            dataSorce
          ]?.csat_survey?.csat_config?.delivery_method;

        surveyData.selectedData[
          dataSorce?.toLowerCase()
        ].delivery_method_engag_survey =
          selectedDataResponse?.[dataSorce]?.survey_link?.delivery_mode;

        surveyData.selectedData[dataSorce?.toLowerCase()].expiry_time_engag =
          selectedDataResponse?.[
            dataSorce
          ]?.csat_survey?.csat_config?.expiry_time;

        surveyData.selectedData[
          dataSorce?.toLowerCase()
        ].invalid_responses_engag =
          selectedDataResponse?.[
            dataSorce
          ]?.csat_survey?.csat_config?.invalid_responses;

        surveyData.selectedData[
          dataSorce?.toLowerCase()
        ].resend_attempts_engag =
          selectedDataResponse?.[
            dataSorce
          ]?.csat_survey?.csat_config?.resend_attempts;
        surveyData.selectedData[dataSorce?.toLowerCase()].send_survey_in_engag =
          selectedDataResponse?.[
            dataSorce
          ]?.csat_survey?.csat_config?.send_survey_in;
        surveyData.selectedData[
          dataSorce?.toLowerCase()
        ].unsupported_languages_engag =
          selectedDataResponse?.[
            dataSorce
          ]?.csat_survey?.csat_config?.unsupported_languages;

        surveyData.selectedData[dataSorce?.toLowerCase()].expiredMsg_ar =
          selectedDataResponse?.[dataSorce]?.csat_survey?.expiry_message?.ar;
        surveyData.selectedData[dataSorce?.toLowerCase()].expiredMsg_en =
          selectedDataResponse?.[dataSorce]?.csat_survey?.expiry_message?.en;

        surveyData.selectedData[dataSorce?.toLowerCase()].thankMsg_en =
          selectedDataResponse?.[dataSorce]?.csat_survey?.thank_message?.en;
        surveyData.selectedData[dataSorce?.toLowerCase()].thankMsg_ar =
          selectedDataResponse?.[dataSorce]?.csat_survey?.thank_message?.ar;

        surveyData.selectedData[dataSorce?.toLowerCase()].feed_qs_en =
          selectedDataResponse?.[
            dataSorce
          ]?.csat_survey?.predefined_question?.feedback?.en;
        surveyData.selectedData[dataSorce?.toLowerCase()].feed_qs_ar =
          selectedDataResponse?.[
            dataSorce
          ]?.csat_survey?.predefined_question?.feedback?.ar;
        surveyData.selectedData[dataSorce?.toLowerCase()].rate_qs_en =
          selectedDataResponse?.[
            dataSorce
          ]?.csat_survey?.predefined_question?.rating?.en;
        surveyData.selectedData[dataSorce?.toLowerCase()].rate_qs_ar =
          selectedDataResponse?.[
            dataSorce
          ]?.csat_survey?.predefined_question?.rating?.ar;
      });
      surveyData.mainData.feaching = surveyData?.mainData?.feaching + 1;
      setInitialSurveySelectedData(
        _.cloneDeep({ ...surveyData?.selectedData }),
      );
      setSurveyData({ ...surveyData });
    }
  }, [surveySelectedData, isSurveySelectedDataSuccess]);

  useEffect(() => {
    let predefinedData = predefinedOptionsResponse?.data?.data;

    if (isPredefinedOptionsSuccess) {
      surveyData.mainData.rate_qs_ar = predefinedData?.rating?.ar;
      surveyData.mainData.rate_qs_en = predefinedData?.rating?.en;
      surveyData.mainData.feed_qs_ar = predefinedData?.feedback?.ar;
      surveyData.mainData.feed_qs_en = predefinedData?.feedback?.en;

      Object.keys(surveyData?.selectedData)?.map((dataSorce) => {
        surveyData.selectedData[dataSorce].rate_qs_ar =
          predefinedData?.rating?.ar?.[0]?.id;
        surveyData.selectedData[dataSorce].rate_qs_en =
          predefinedData?.rating?.en?.[0]?.id;
        surveyData.selectedData[dataSorce].feed_qs_ar =
          predefinedData?.feedback?.ar?.[0]?.id;
        surveyData.selectedData[dataSorce].feed_qs_en =
          predefinedData?.feedback?.en?.[0]?.id;
      });
      surveyData.mainData.feaching = surveyData?.mainData?.feaching + 1;
      setInitialSurveySelectedData(
        _.cloneDeep({ ...surveyData?.selectedData }),
      );
      setSurveyData({ ...surveyData });
    }
  }, [predefinedOptionsResponse, isPredefinedOptionsSuccess]);

  useEffect(() => {
    let csatData = csatOptionsResponse?.data?.data;
    if (isCsatOptionsSuccess && csatData) {
      Object.keys(csatData)?.map((key) => {
        if (key == "delivery_method") {
          surveyData.mainData[`${key}_engag_survey`] = csatData?.[key]?.options;
          Object.keys(surveyData?.selectedData)?.map((dataSorce) => {
            surveyData.selectedData[dataSorce][`${key}_engag_survey`] =
              csatData?.[key]?.default;
          });
        }
        surveyData.mainData[`${key}_engag`] = csatData?.[key]?.options;
        Object.keys(surveyData?.selectedData)?.map((dataSorce) => {
          surveyData.selectedData[dataSorce][`${key}_engag`] =
            csatData?.[key]?.default;
        });
      });
      surveyData.mainData.feaching = surveyData?.mainData?.feaching + 1;
      setInitialSurveySelectedData(
        _.cloneDeep({ ...surveyData?.selectedData }),
      );

      setSurveyData({ ...surveyData });
    }
  }, [csatOptionsResponse, isCsatOptionsSuccess]);

  useEffect(() => {
    let limitData = charLimitResponse?.data?.data;
    if (isCharLimitSuccess && limitData?.length) {
      limitData?.map(
        (item) =>
          (surveyData.mainData[
            `${item?.attributes?.data_source?.toLowerCase()}_limit`
          ] = item?.attributes?.text_length || 200),
      );
      surveyData.mainData.feaching = surveyData?.mainData?.feaching + 1;
      setSurveyData({ ...surveyData });
    }
  }, [charLimitResponse, isCharLimitSuccess]);

  useEffect(() => {
    if (isSetup) {
      EngagementsController.getUserInfo({
        product_id: window?.localStorage?.engagements_id,
      }).then((data) => {
        let farwellData = {};
        Object.keys(data?.data?.data?.farewell_greeting_enabled)?.map(
          (item) => {
            if (!item?.includes("survey_id")) {
              farwellData[item] =
                data?.data?.data?.farewell_greeting_enabled?.[item];
            }
          },
        );
        const messageResponseData = {
          farewell_greeting_messages:
            data?.data?.data?.farewell_greeting_messages,
          farewell_greeting_enabled: farwellData,
          lookup_messages: data?.data?.data?.lookup_messages,
          engagements_outside_hours_settings:
            data?.data?.data?.engagements_outside_hours_settings,
        };
        setMessagesData(
          processFarewellGreetingMessagesData(messageResponseData),
        );

        // setting the public reples state
        setAutomatedPublicRepliesData(getAutomatedPublicRepliesState(data));

        if (data?.data?.data?.engagements_monitors) {
          let mainData = { ...data?.data?.data };
          let TWITTER_SlAcc = [],
            INSTAGRAM_SlAcc = [],
            FACEBOOK_SlAcc = [],
            TWITTER_CxmAcc = [],
            INSTAGRAM_CxmAcc = [],
            FACEBOOK_CxmAcc = [],
            TWITTER_PubAcc = [],
            INSTAGRAM_PubAcc = [],
            FACEBOOK_PubAcc = [],
            accountsArr = [];

          let monitors = [...mainData?.engagements_monitors?.monitors?.data];
          let channels = [...mainData?.engagements_monitors?.channels?.data];
          let monitorsSelected = monitors?.filter(
            (item) => item?.attributes?.is_engagement_monitor,
          );

          let channelsSelected = channels?.filter(
            (item) => item?.attributes?.is_engagement_monitor,
          );
          mainData?.engagements_accounts?.TWITTER?.data?.map((item) => {
            accountsArr.push({
              ...item,
              attributes: {
                ...item?.attributes,
                data_sources: ["TWITTER"],
                data_source: "TWITTER",
                account_error:
                  item?.attributes?.status == "Re-authenticate"
                    ? ["TWITTER"]
                    : [],
              },
            });
            if (item?.attributes?.engagements_products?.includes("SM")) {
              TWITTER_SlAcc.push({
                ...item,
                attributes: {
                  ...item?.attributes,
                  data_sources: ["TWITTER"],
                  data_source: "TWITTER",
                  account_error:
                    item?.attributes?.status == "Re-authenticate"
                      ? ["TWITTER"]
                      : [],
                },
              });
            }
            if (item?.attributes?.engagements_products?.includes("CXM")) {
              TWITTER_CxmAcc.push({
                ...item,
                attributes: {
                  ...item?.attributes,
                  data_sources: ["TWITTER"],
                  data_source: "TWITTER",
                  account_error:
                    item?.attributes?.status == "Re-authenticate"
                      ? ["TWITTER"]
                      : [],
                },
              });
            }
            if (item?.attributes?.can_publish) {
              TWITTER_PubAcc.push({
                ...item,
                attributes: {
                  ...item?.attributes,
                  data_sources: ["TWITTER"],
                  data_source: "TWITTER",
                  account_error:
                    item?.attributes?.status == "Re-authenticate"
                      ? ["TWITTER"]
                      : [],
                },
              });
            }
          });
          mainData?.engagements_accounts?.FACEBOOK?.included?.map((item) => {
            accountsArr.push({
              ...item,
              attributes: {
                ...item?.attributes,
                data_sources: ["FACEBOOK"],
                data_source: "FACEBOOK",
                account_error:
                  item?.attributes?.status == "Re-authenticate"
                    ? ["FACEBOOK"]
                    : [],
              },
            });
            if (item?.attributes?.engagements_products?.includes("SM")) {
              FACEBOOK_SlAcc.push({
                ...item,
                attributes: {
                  ...item?.attributes,
                  data_sources: ["FACEBOOK"],
                  data_source: "FACEBOOK",
                  account_error:
                    item?.attributes?.status == "Re-authenticate"
                      ? ["FACEBOOK"]
                      : [],
                },
              });
            }
            if (item?.attributes?.engagements_products?.includes("CXM")) {
              FACEBOOK_CxmAcc.push({
                ...item,
                attributes: {
                  ...item?.attributes,
                  data_sources: ["FACEBOOK"],
                  data_source: "FACEBOOK",
                  account_error:
                    item?.attributes?.status == "Re-authenticate"
                      ? ["FACEBOOK"]
                      : [],
                },
              });
            }
            if (item?.attributes?.can_publish) {
              FACEBOOK_PubAcc.push({
                ...item,
                attributes: {
                  ...item?.attributes,
                  data_sources: ["FACEBOOK"],
                  data_source: "FACEBOOK",
                  account_error:
                    item?.attributes?.status == "Re-authenticate"
                      ? ["FACEBOOK"]
                      : [],
                },
              });
            }
          });
          mainData?.engagements_accounts?.INSTAGRAM?.included?.map((item) => {
            accountsArr.push({
              ...item,
              attributes: {
                ...item?.attributes,
                data_sources: ["INSTAGRAM"],
                data_source: "INSTAGRAM",
                account_error:
                  item?.attributes?.status == "Re-authenticate"
                    ? ["INSTAGRAM"]
                    : [],
              },
            });
            if (item?.attributes?.engagements_products?.includes("SM")) {
              INSTAGRAM_SlAcc.push({
                ...item,
                attributes: {
                  ...item?.attributes,
                  data_sources: ["INSTAGRAM"],
                  data_source: "INSTAGRAM",
                  account_error:
                    item?.attributes?.status == "Re-authenticate"
                      ? ["INSTAGRAM"]
                      : [],
                },
              });
            }
            if (item?.attributes?.engagements_products?.includes("CXM")) {
              INSTAGRAM_CxmAcc.push({
                ...item,
                attributes: {
                  ...item?.attributes,
                  data_sources: ["INSTAGRAM"],
                  data_source: "INSTAGRAM",
                  account_error:
                    item?.attributes?.status == "Re-authenticate"
                      ? ["INSTAGRAM"]
                      : [],
                },
              });
            }
            if (item?.attributes?.can_publish) {
              INSTAGRAM_PubAcc.push({
                ...item,
                attributes: {
                  ...item?.attributes,
                  data_sources: ["INSTAGRAM"],
                  data_source: "INSTAGRAM",
                  account_error:
                    item?.attributes?.status == "Re-authenticate"
                      ? ["INSTAGRAM"]
                      : [],
                },
              });
            }
          });
          setInitialselectedData({
            changedNum: 0,
            stepOne: {
              monitors: monitorsSelected?.length
                ? monitorsSelected?.map((item) => item?.attributes?.id)
                : [],
              TWITTER_SlAcc,
              INSTAGRAM_SlAcc,
              FACEBOOK_SlAcc,
              channels: channelsSelected?.length
                ? channelsSelected?.map((item) => item?.attributes?.id)
                : [],
              TWITTER_CxmAcc,
              INSTAGRAM_CxmAcc,
              FACEBOOK_CxmAcc,
              TWITTER_PubAcc,
              INSTAGRAM_PubAcc,
              FACEBOOK_PubAcc,
            },
            stepTwo: {
              luci_industry: mainData?.ai_info?.data?.attributes?.industry,
              luci_business_type:
                mainData?.ai_info?.data?.attributes?.business_type,
              luci_business_hq:
                mainData?.ai_info?.data?.attributes?.business_location,
              brand_tone: mainData?.ai_info?.data?.attributes?.brand_voice,
              aboutYour:
                mainData?.ai_info?.data?.attributes?.ai_company_knowledge,
              knowledge_base_url:
                mainData?.ai_info?.data?.attributes?.knowledge_base_file
                  ?.content,
              enabled: mainData?.ai_info?.data?.attributes?.should_learn,
              fileName:
                mainData?.ai_info?.data?.attributes?.knowledge_base_file
                  ?.file_name,
              fileSize:
                mainData?.ai_info?.data?.attributes?.knowledge_base_file
                  ?.file_size,
              enabled: mainData?.ai_info?.data?.attributes?.should_learn,
            },
            ...processFarewellGreetingMessagesData(messageResponseData),
            engagements_outside_hours_settings: _.cloneDeep(
              messageResponseData?.engagements_outside_hours_settings || {},
            ),
            initialAutomatedPublicReplies: {
              ...getAutomatedPublicRepliesState(data),
            },
            initaledDataDone: true,
          });
          setSelectedData({
            ...selectedData,
            mainData: {
              accounts: [...accountsArr],
              feaching: false,
              monitors: [...monitors],
              channels: [...channels],
              company_products: {
                ...mainData?.engagements_monitors?.company_products,
              },
            },
            stepOne: {
              ...selectedData?.stepOne,
              monitors: monitorsSelected?.length
                ? monitorsSelected?.map((item) => item?.attributes?.id)
                : [],
              TWITTER_SlAcc,
              INSTAGRAM_SlAcc,
              FACEBOOK_SlAcc,
              channels: channelsSelected?.length
                ? channelsSelected?.map((item) => item?.attributes?.id)
                : [],
              TWITTER_CxmAcc,
              INSTAGRAM_CxmAcc,
              FACEBOOK_CxmAcc,
              TWITTER_PubAcc,
              INSTAGRAM_PubAcc,
              FACEBOOK_PubAcc,
            },
            stepTwo: {
              ...selectedData?.stepTwo,
              luci_industry: mainData?.ai_info?.data?.attributes?.industry,
              luci_business_type:
                mainData?.ai_info?.data?.attributes?.business_type,
              luci_business_hq:
                mainData?.ai_info?.data?.attributes?.business_location,
              brand_tone: mainData?.ai_info?.data?.attributes?.brand_voice,
              aboutYour:
                mainData?.ai_info?.data?.attributes?.ai_company_knowledge,
              knowledge_base_url:
                mainData?.ai_info?.data?.attributes?.knowledge_base_file
                  ?.content,
              enabled: mainData?.ai_info?.data?.attributes?.should_learn,
              fileName:
                mainData?.ai_info?.data?.attributes?.knowledge_base_file
                  ?.file_name,
              fileSize:
                mainData?.ai_info?.data?.attributes?.knowledge_base_file
                  ?.file_size,
              isFileChanged: false,
            },
          });
        }
      });
    }
  }, [isSetup]);

  //end of get all data and handle it

  //start of handle request demo this will run only if the user have not one of SM or CXM
  const requestDemo = (type) => {
    let queryData = {
      service_name: type,
    };
    if (type == "request_social_listening_demo") {
      setSlDemoDiasable(true);
    } else {
      setCxmDemoDiasable(true);
    }
    CompaniesController.requestService(queryData).then((result) => {
      if (!result.errorMsg) {
        setSnackBar({
          open: true,
          title: "request_access_success_heading",
          message: "demo_success",
          severity: "success",
        });
      } else {
        setSnackBar({
          open: true,
          title: "something_wrong",
          message: "wrong_msg",
          severity: "error",
        });
      }
      setSlDemoDiasable(false);
      setCxmDemoDiasable(false);
    });
    setTimeout(() => {
      setSnackBar({
        open: false,
        severity: "",
        title: "",
        message: "",
      });
    }, 7000);
  };
  //end of handle request demo this will run only if the user have not one of SM or CXM

  return (
    <Box className="main-edit-engagment">
      <Box className="main-edit-header">
        <Box>{CheckValueLocale("engag_setting", "", {}, intl)}</Box>
        <Box
          className="edit-header-btn"
          onClick={() => navigate(engagementPriorityProductPath)}
        >
          {CheckValueLocale("back_main", "", {}, intl)}
          <FontAwesomeIcon
            icon={intl.locale == "en" ? faChevronRight : faChevronLeft}
          />
        </Box>
      </Box>

      {!selectedData?.mainData?.feaching &&
      surveyData?.mainData?.feaching == surveyFetchingCount ? (
        <Box>
          <Box className="main-edit-step">
            {CheckValueLocale("inbox_setting", "", {}, intl)}
            <Box className="main-step-countainer">
              <Box className="main-step-header">
                <img src={SmIcon} />
                {CheckValueLocale("social_listening_v2", "", {}, intl)}
              </Box>
              {!selectedData?.mainData?.company_products?.SM ? (
                <Box className="req-demo">
                  {CheckValueLocale("sm_demo", "", {}, intl)}
                  <Button
                    onClick={() => requestDemo("request_social_listening_demo")}
                    disabled={slDemoDiasable}
                  >
                    {CheckValueLocale("request_demo", "", {}, intl)}
                  </Button>
                </Box>
              ) : (
                <Box>
                  <MainSelsctCard
                    type="monitors"
                    isAcc={false}
                    isborder={true}
                    selectedData={selectedData}
                    setSelectedData={setSelectedData}
                    selectId="engagement-settings-monitors-select"
                  />
                  <MainSelsctCard
                    type="SlAcc"
                    isAcc={true}
                    isborder={false}
                    selectedData={selectedData}
                    setSelectedData={setSelectedData}
                    selectId="engagement-settings-sl-acc-select"
                    companyAuthId="engagement-settings-sl-company-auth"
                  />
                </Box>
              )}
            </Box>
            <Box className="main-step-countainer">
              <Box className="main-step-header">
                <img src={CxmIcon} />
                {CheckValueLocale("CXM", "", {}, intl)}
              </Box>
              {!selectedData?.mainData?.company_products?.CXM ? (
                <Box className="req-demo">
                  {CheckValueLocale("cxm_demo", "", {}, intl)}
                  <Button
                    onClick={() =>
                      requestDemo("request_channel_analytics_demo")
                    }
                    disabled={cxmDemoDiasable}
                  >
                    {CheckValueLocale("request_demo", "", {}, intl)}
                  </Button>
                </Box>
              ) : (
                <Box>
                  <MainSelsctCard
                    type="channels"
                    isAcc={false}
                    isborder={selectedData?.stepOne?.isCxmAcc}
                    selectedData={selectedData}
                    setSelectedData={setSelectedData}
                    selectId="engagement-settings-channels-select"
                  />
                  {selectedData?.stepOne?.isCxmAcc ? (
                    <MainSelsctCard
                      type="CxmAcc"
                      isAcc={true}
                      isborder={false}
                      selectedData={selectedData}
                      setSelectedData={setSelectedData}
                      selectId="engagement-settings-cxm-acc-select"
                      companyAuthId="engagement-settings-cxm-company-auth"
                    />
                  ) : null}
                </Box>
              )}
            </Box>
          </Box>
          <Box className="main-edit-step">
            {CheckValueLocale("pub_setting", "", {}, intl)}
            <Box className="main-step-countainer">
              <MainSelsctCard
                type="PubAcc"
                isAcc={true}
                isborder={false}
                selectedData={selectedData}
                setSelectedData={setSelectedData}
                selectId="engagement-settings-public-acc-select"
                companyAuthId="engagement-settings-public-company-auth"
              />
            </Box>
          </Box>
          <Box className="main-edit-step">
            {CheckValueLocale("AI_Assistant", "", {}, intl)}
            <Box className="main-step-countainer">
              <AiSection
                selectedData={selectedData}
                setSelectedData={setSelectedData}
              />
            </Box>
          </Box>

          <Box className="main-edit-step">
            {CheckValueLocale("greeting_message_section_title", "", {}, intl)}
            <Box className="main-step-countainer">
              <GreetingAndFarewell
                aiAgentActive={aiAgentActive}
                messagesData={messagesData}
                setMessagesData={setMessagesData}
                surveyData={surveyData}
                setSurveyData={setSurveyData}
              />
            </Box>
          </Box>

          {!aiAgentActive ? (
            <Box className="main-edit-step">
              {CheckValueLocale("automated_public_replies_title", "", {}, intl)}
              <Box className="main-step-countainer">
                <AutomatedPublicReplies
                  selectedData={selectedData}
                  automatedPublicRepliesData={automatedPublicRepliesData}
                  setAutomatedPublicRepliesData={setAutomatedPublicRepliesData}
                  autoReplySelectedTab={autoReplySelectedTab}
                  setAutoReplySelectedTab={setAutoReplySelectedTab}
                  autoReplyErrorFields={autoReplyErrorFields}
                  setAutoReplyErrorFields={setAutoReplyErrorFields}
                  setInitialselectedData={setInitialselectedData}
                  initialselectedData={initialselectedData}
                />
              </Box>
            </Box>
          ) : null}
          <Box className="main-edit-step">
            {CheckValueLocale("tags_management", "", {}, intl)}
            <Box className="main-step-countainer">
              <EngagementTagsListPage />
            </Box>
          </Box>
          <Box className="main-edit-step">
            {CheckValueLocale("saved_replies", "", {}, intl)}
            <Box className="main-step-countainer">
              <ReplaySection
                selectedData={selectedData}
                setSelectedData={setSelectedData}
              />
            </Box>
          </Box>
          <MainEditFooter
            surveyData={surveyData}
            setSurveyData={setSurveyData}
            isSurveyFeaching={
              !(surveyData?.mainData?.feaching === surveyFetchingCount)
            }
            initialSurveySelectedData={initialSurveySelectedData}
            setSnackBar={setSnackBar}
            messageData={messagesData}
            automatedPublicRepliesData={automatedPublicRepliesData}
            initialselectedData={initialselectedData}
            setInitialselectedData={setInitialselectedData}
            selectedData={selectedData}
            aiAgentActive={aiAgentActive}
            autoReplyErrorFields={autoReplyErrorFields}
            setAutoReplyErrorFields={setAutoReplyErrorFields}
            autoReplySelectedTab={autoReplySelectedTab}
            setAutoReplySelectedTab={setAutoReplySelectedTab}
          />
        </Box>
      ) : (
        <Box className="fetching-loader">
          <FontAwesomeIcon icon={faSpinnerThird} spin />
          {CheckValueLocale("setup_load", "", {}, intl)}
        </Box>
      )}
      <SnackBar
        open={snackBar?.open}
        severity={snackBar?.severity}
        title={CheckValueLocale(snackBar?.title, "", {}, intl)}
        message={CheckValueLocale(snackBar?.message, "", {}, intl)}
        handleClose={() => {
          setSnackBar({
            open: false,
            title: "",
            message: "",
            severity: "",
          });
        }}
        snackBarId={snackBar?.limitWidth ? "main-edit-settings-snackbar" : ""}
      />
    </Box>
  );
};

export default MainEdit;
