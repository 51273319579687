import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { Box, Typography } from "@mui/material";
import { useReportUsers } from "../hooks/useReportUsers";
import { useReportFrequencies } from "../hooks/useReportFrequencies";
import { useReportLuciTokens } from "../hooks/useReportLuciTokens";
import { CreateReportContext } from "pages/reports/context/reportContext";
import ReportDeliverySharedStep from "pages/reports/components/reportDeliverySharedStep";

const ReportDeliveryStep = () => {
  const { formik, setSnackbar, availableReportTypes, availableDatasources } =
    useContext(CreateReportContext);

  const intl = useIntl();

  const [showCustomDaySelect, setShowCustomDaySelect] = useState(false);

  // Check if the selected report type is newsletter
  const isNewsletterReport =
    formik?.values?.report_type_id ===
    availableReportTypes?.namesToIds?.["newsletter"];

  // Check if the selected channel_type is ACCOUNT_ANALYSIS and data source is instagram
  const isPublicAccountInstagram =
    formik?.values?.channel_type === "ACCOUNT_ANALYSIS" &&
    availableDatasources?.idsToNames?.[formik?.values?.data_source_id?.[0]] ===
      "INSTAGRAM";

  /** -----------------------------------------------------
   * Get available users step (Report Delivery)
   * ----------------------------------------------------- */
  const {
    data: availableUsers,
    isLoading: availableUsersLoading,
    isError: availableUsersError,
  } = useReportUsers();

  /** -----------------------------------------------------
   * Get available frequencies step (Report Delivery)
   * ----------------------------------------------------- */
  const {
    data: reportFrequencies,
    isLoading: reportFrequenciesLoading,
    isError: reportFrequenciesError,
  } = useReportFrequencies();

  /** -----------------------------------------------------
   * Get available LUCI Tokens step (Report Delivery)
   * ----------------------------------------------------- */
  const {
    data: reportLuciTokens,
    isError: reportLuciTokensError,
    isLoading: reportLuciTokensLoading,
  } = useReportLuciTokens({
    queryData: { report_type_id: formik?.values?.report_type_id },
  });

  useEffect(() => {
    if (
      availableUsersError ||
      reportFrequenciesError ||
      reportLuciTokensError
    ) {
      setSnackbar({
        open: true,
        severity: "error",
        title: "something_went_wrong",
        message: "try_again",
      });
    }
  }, [availableUsersError, reportFrequenciesError, reportLuciTokensError]);

  return (
    <Box>
      <Typography variant="h4" className="create-report-section-title">
        {CheckValueLocale("delivery_requirements", "", {}, intl)}:
      </Typography>
      <ReportDeliverySharedStep
        formik={formik}
        availableUsers={availableUsers}
        availableUsersLoading={availableUsersLoading}
        reportFrequencies={reportFrequencies}
        reportFrequenciesLoading={reportFrequenciesLoading}
        isNewsletterReport={isNewsletterReport}
        reportLuciTokens={reportLuciTokens}
        reportLuciTokensLoading={reportLuciTokensLoading}
        isPublicAccountInstagram={isPublicAccountInstagram}
        showCustomDaySelect={showCustomDaySelect}
        setShowCustomDaySelect={setShowCustomDaySelect}
      />
    </Box>
  );
};

export default ReportDeliveryStep;
