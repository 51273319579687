import { useIntl } from "react-intl";
import { convertDateTimeFormat } from "./chatHelpers";
import { Fragment } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import {
  CheckValueLocale,
  classNames,
  getResetPhoneNumber,
} from "utils/helpers";
import { MessageContentRenderer } from "./components/messageContentRenderer";
import { DMStatusLogs } from "./components/dmStatusLogs";
import { useState } from "react";
import { MediaPreviewer } from "../../mediaPreviewer/mediaPreviewer";

export const ChatMessagesContainer = ({
  chatData = [],
  mainDates = {},
  selectedCard = {},
  setStats,
  translationTarget,
  translationActive,
  translationSource,
  deletedMessagesIds = [],
  statusLogs = [],
}) => {
  const intl = useIntl();
  const [currentPlayedAudioId, setCurrentPlayedAudioId] = useState(null);
  const [showMediaPreviewer, setShowMediaPreviewer] = useState(false);
  const [media, setMedia] = useState([]);

  const handleMediaClick = (media) => {
    if (media?.media_url) {
      setShowMediaPreviewer(true);
      setMedia([
        {
          type: media?.isVideo ? "video" : "image",
          url: media?.media_url,
        },
      ]);
    }
  };
  return (
    <>
      {showMediaPreviewer ? (
        <MediaPreviewer
          showMediaPreviewer={showMediaPreviewer}
          setShowMediaPreviewer={setShowMediaPreviewer}
          media={media}
        />
      ) : null}
      {chatData?.map((message, index) => {
        const isMessageDeleted = deletedMessagesIds?.includes(
          message?.message_id,
        );
        const { day, monthName, time, year } =
          convertDateTimeFormat(message?.created_at, intl.locale) || {};
        // Determine message alignment based on direction
        const isInbound = message?.direction === "inbound";
        // class names for message box
        const messageClasses = classNames(
          "chat-main-box clearfix",
          isInbound
            ? "interaction-list-header"
            : "interaction-list__header chat-main-box-right",
          message?.is_temporary ? "temp-massage" : "",
        );
        const messageBoxClasses = classNames(
          "chat-message",
          `chat-message-${selectedCard?.data_source?.toLowerCase?.()}`,
        );
        const dateClasses = classNames(
          message?.direction === "inbound" ? "date-chat" : "date-chat right",
        );
        // Handle user message name
        const isWhatsApp = selectedCard?.data_source === "WHATSAPP";
        const phoneNumber = message?.username;
        const whatsappNumber = phoneNumber?.length
          ? getResetPhoneNumber(phoneNumber)
          : "";
        const name = message?.name;
        const displayName = isWhatsApp
          ? isInbound
            ? whatsappNumber
            : `${whatsappNumber ? "(" + whatsappNumber + ")" : ""} ${message?.system_replies?.replier_info?.app_name || ""}`
          : name;
        const keyId = message?.message_id || index;
        return (
          <Fragment key={keyId}>
            {mainDates[index] ? (
              <Box className="main-massage-date">
                <Box>{`${day} ${monthName} ${year} `}</Box>
              </Box>
            ) : null}
            <Box>
              <Box pt={2} className={messageClasses} key={message?.message_id}>
                <span className="interaction-list-user-data">
                  <span className="label">{displayName}</span>
                </span>
                <Box className={messageBoxClasses}>
                  <MessageContentRenderer
                    message={message}
                    index={index}
                    selectedCard={selectedCard}
                    setStats={setStats}
                    translationTarget={translationTarget}
                    translationActive={translationActive}
                    translationSource={translationSource}
                    handleMediaClick={handleMediaClick}
                    isMessageDeleted={isMessageDeleted}
                    currentPlayedAudioId={currentPlayedAudioId}
                    setCurrentPlayedAudioId={setCurrentPlayedAudioId}
                  />
                </Box>
                <Box className={dateClasses}>
                  <span className="time-zone-space">{time}</span>
                  {message?.content?.automated_by ? (
                    <Box className="replied-by-email">
                      <Typography>
                        {`${CheckValueLocale(`automated_${message?.content?.automated_by}_message`, "", {}, intl)}`}
                      </Typography>
                    </Box>
                  ) : message?.system_replies?.replier_info?.email ? (
                    <Box className="replied-by-email">
                      <Typography className="card-themes-label">
                        {`${CheckValueLocale("replied_by", "", {}, intl)} : `}
                      </Typography>
                      <Typography className="card-themes-replied-by">
                        {message?.system_replies?.replier_info?.email}
                      </Typography>
                    </Box>
                  ) : null}
                </Box>
              </Box>
              {/* Logs handling */}
              <DMStatusLogs
                statusLogs={statusLogs}
                message={message}
                index={index}
                selectedCard={selectedCard}
                chatData={chatData}
              />
            </Box>
          </Fragment>
        );
      })}
    </>
  );
};
