import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import engagementTagsFunction from "./engagementTagsFunction";
import CustomSelectTags from "./components/customSelectTags";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import SnackBar from "components/snackBar";
import { useApplyTags } from "./hooks/useApplyTags";
import "./style.scss";
import { useGetInformalTags } from "./hooks/useGetInformalTags";

const EngagementTags = (props) => {
  const intl = useIntl();
  const {
    selectedCard,
    isSettingPage,
    isOptionTable,
    setIsEnabledEdit,
    editTagData,
    dropdownEvent,
    setSnackBarTagsTable,
    itemData,
    tagsLimitReached,
    tagsTableData,
    hideNote,
  } = props;

  const { applyOpacity } = engagementTagsFunction();
  const productId = window.localStorage.getItem("engagementsProductId");
  const { mutate: ApplyTags } = useApplyTags();

  // State management
  const [menuType, setMenuType] = useState("tags");
  const [loading, setLoading] = useState(false);
  const [initialvlue, setInitialvlue] = useState(
    itemData?.tags?.map((tag) => tag?.id) || [],
  );
  const [selectedOptions, setSelectedOptions] = useState(
    itemData?.tags?.map((tag) => tag?.id) || [],
  );
  const [selectedColor, setSelectedColor] = useState("");
  const [anchorElTagList, setAnchorElTagList] = useState(null);
  const [tagName, setTagName] = useState("");
  const [snackBar, setSnackBar] = useState({
    open: false,
    title: "",
    severity: "",
    message: "",
    tagName: "",
  });

  // Check user permissions
  const enabledEdit = handlelUserRoles("ENGAGEMENT_TAG_MANAGEMENT", "EDIT_TAG");
  const enabledCreate = handlelUserRoles(
    "ENGAGEMENT_TAG_MANAGEMENT",
    "CREATE_TAG",
  );
  const enabledDelete = handlelUserRoles(
    "ENGAGEMENT_TAG_MANAGEMENT",
    "DELETE_DISABLE_TAG",
  );

  // Fetch informal tags from API
  const { data: informalTags, isLoading: informalTagsLoading } =
    useGetInformalTags({
      callInformalTags:
        (menuType === "tags" && !isSettingPage && anchorElTagList !== null) ||
        false,
    });

  // Apply selected tags to engagement
  const handleApplyTags = () => {
    setLoading(true);
    const requestData = {
      monitor_id: selectedCard?.tracker_id,
      product_id: productId,
      interaction_id: selectedCard?.id || selectedCard?.tweet_id,
      data_source_name: selectedCard?.data_source?.toUpperCase(),
      new_tags: selectedOptions,
    };

    ApplyTags(requestData, {
      onError: (res) => {
        if (res?.status == 404) {
          setInitialvlue(selectedOptions);
          setSnackBar({
            open: true,
            title: "",
            severity: "error",
            message: "you_have_selected_deleted",
          });
        } else if (res?.status == 403) {
          setSnackBar({
            open: true,
            title: "",
            severity: "error",
            message: "you_have_selected_disabled",
          });
        } else {
          setSnackBar({
            open: true,
            title: "something_went_wrong",
            severity: "error",
            message: "try_again",
          });
        }
      },
      onSettled: () => {
        setLoading(false);
        setAnchorElTagList(null);
      },
    });
  };

  return (
    <Box
      className={`engagement-tag-container ${isOptionTable ? "edit-tag-container" : ""}`}
    >
      <CustomSelectTags
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        initialvlue={initialvlue}
        applyOpacity={applyOpacity}
        menuType={menuType}
        informalTags={informalTags}
        loading={loading}
        informalTagsLoading={informalTagsLoading}
        setLoading={setLoading}
        enabledCreate={enabledCreate}
        setMenuType={setMenuType}
        selectedColor={selectedColor}
        setSelectedColor={setSelectedColor}
        tagName={tagName}
        setTagName={setTagName}
        handleApplyTags={handleApplyTags}
        isSettingPage={isSettingPage}
        isOptionTable={isOptionTable}
        setIsEnabledEdit={setIsEnabledEdit}
        editTagData={editTagData}
        setSnackBar={setSnackBar}
        setSnackBarTagsTable={setSnackBarTagsTable}
        dropdownEvent={dropdownEvent}
        anchorElTagList={anchorElTagList}
        setAnchorElTagList={setAnchorElTagList}
        itemData={itemData}
        tagsLimitReached={tagsLimitReached}
        tagsTableData={tagsTableData}
        hideTagText={hideNote}
      />
      <SnackBar
        open={snackBar?.open}
        title={CheckValueLocale(snackBar?.title, "", {}, intl)}
        message={CheckValueLocale(
          snackBar?.message,
          "",
          { tagName: snackBar?.tagName },
          intl,
        )}
        severity={snackBar?.severity}
        handleClose={() => setSnackBar({ ...snackBar, open: false })}
      />
    </Box>
  );
};

export default EngagementTags;
