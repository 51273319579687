import { useQueryClient } from "@tanstack/react-query";
import { useRefreshToken } from "pages/loginPage/refreshToken/hooks/useRefreshToken";
import { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";
import LoginController from "services/controllers/loginController";
import { io } from "socket.io-client";
import { deleteCookie } from "utils/helpers";
import { resetChatMessages } from "utils/redux/features/AiAgentChat/AiAgentChatSlice";

export const useAISocketConnection = ({
  aiAgentId,
  aiAgentDetails,
  onError,
  onMessage,
  runTestAction,
  Services,
  intl,
  CheckValueLocale,
  runTestErrorMap,
}) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [retry, setRetry] = useState(0);
  const { mutate: refreshToken, isPending } = useRefreshToken();
  const [connectionState, setConnectionState] = useState({
    isConnected: false,
    isLoading: false,
    isThinking: false,
    isModalOpen: false,
  });

  const handleSignOut = () => {
    const currentLang = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : window.navigator.language.split("-")[0];
    LoginController.userlogout().then((data) => {
      dispatch(resetChatMessages());
      localStorage.clear();
      deleteCookie("email");
      window.localStorage.setItem("lang", currentLang);
      window.location.href = "/login";
    });
  };

  const logToConsole = (...args) => {
    if (window.location.host !== "cxm.lucidya.com") {
      console.log(...args);
    }
  };

  const handleConnect = () => {
    logToConsole("Socket connected");
  };

  const handleConnectError = (err) => {
    logToConsole("Socket connect_error:", err);
    // Capture the error in Sentry
    // Sentry.captureException(err);
  };

  const handleError = (err) => {
    logToConsole("Socket error:", err);
    // Capture the error in Sentry
    // Sentry.captureException(err);
  };

  const socket = useMemo(() => {
    if (connectionState.isConnected) {
      return io(Services.socketUrl + "engagements", {
        path: "/ws/",
        transports: ["websocket"],
        auth: {
          "luc-authorization": localStorage.getItem("user_token"),
        },
      });
    }
    return null;
  }, [connectionState.isConnected, Services.socketUrl, retry]);

  const autoSendToken = useCallback(() => {
    if (isPending) return;

    refreshToken(
      {},
      {
        onSuccess: (data) => {
          const token = data?.data?.data?.["luc-authorization"];
          if (token) {
            localStorage.setItem("user_token", token);
            setRetry((prev) => prev + 1);
            setConnectionState((prev) => ({ ...prev, isConnected: true }));
          } else {
            handleSignOut();
          }
        },
        onError: () => {
          if (navigator.onLine) {
            handleSignOut();
          }
        },
      },
    );
  }, [isPending, refreshToken, handleSignOut]);

  const handleSocketConnection = useCallback(() => {
    handleConnect();
    socket?.emit("join_ai_room", {
      company_id: localStorage.getItem("company_id"),
      ai_agent_id: aiAgentId,
      user_id: localStorage.getItem("user_id"),
    });
  }, [socket, aiAgentId]);

  const handleAIResponse = (data) => {
    if (data?.status === false) {
      socket?.disconnect();
      queryClient.invalidateQueries({
        queryKey: ["aiAgentResources", { ai_agent_id: aiAgentDetails?.id }],
      });
      queryClient.invalidateQueries({
        queryKey: ["aiAgentDetails", aiAgentDetails?.id],
      });
      setConnectionState({
        isConnected: false,
        isLoading: false,
        isThinking: false,
        isModalOpen: false,
      });

      onError({
        success: false,
        title: CheckValueLocale("unable_to_run_test", "", {}, intl),
        message: CheckValueLocale(
          `ai_agent_run_test_${runTestErrorMap[data?.response?.error_code || 0]}` ||
            "try_again_error_message",
          "",
          {
            files: data?.response?.resource_names?.join(", "),
          },
          intl,
        ),
      });
    } else {
      if (data?.response && Object.keys(data.response).length > 0) {
        onMessage(data.response);
      }
      setConnectionState((prev) => ({
        ...prev,
        isThinking: false,
        isLoading: false,
        isModalOpen: true,
      }));
    }
  };

  const handleRoomJoined = () => {
    runTestAction(aiAgentDetails?.id, {
      onSuccess: () => {
        setConnectionState((prev) => ({ ...prev, isConnected: true }));
      },
      onError: () => {
        socket?.disconnect();
        setConnectionState({
          isConnected: false,
          isLoading: false,
          isThinking: false,
          isModalOpen: false,
        });
        onError({
          success: false,
          title: CheckValueLocale("failed_error_message", "", {}, intl),
          message: CheckValueLocale("try_again_error_message", "", {}, intl),
        });
      },
    });
  };

  const handleDisconnect = useCallback(
    (...res) => {
      logToConsole("Socket disconnected:", res);
      if (retry < 3) {
        setTimeout(() => {
          setRetry((prev) => prev + 1);
        }, 1000);
      } else if (retry === 3) {
        autoSendToken();
      } else {
        closeConnection();
      }
    },
    [retry, connectionState?.isConnected],
  );

  useEffect(() => {
    if (socket && connectionState.isConnected) {
      socket.on("connect", handleSocketConnection);
      socket.on("connect_error", handleConnectError);
      socket.on("error", handleError);
      socket.on("join_ai_room", handleRoomJoined);
      socket.on("ai_agent_response", handleAIResponse);
      socket.on("disconnect", handleDisconnect);

      return () => {
        socket.off("connect");
        socket.off("join_ai_room");
        socket.off("disconnect", handleDisconnect);
        socket.off("ai_agent_response");
        socket.off("connect_error", handleConnectError);
        socket.off("error", handleError);
        socket.disconnect();
      };
    }
  }, [socket, connectionState.isConnected, retry]);

  const closeConnection = () => {
    if (socket) {
      socket.disconnect();
    }
    setConnectionState({
      isConnected: false,
      isLoading: false,
      isThinking: false,
      isModalOpen: false,
    });
    setRetry(0);
  };

  const startConnection = () => {
    setConnectionState((prev) => ({
      ...prev,
      isLoading: true,
      isConnected: true,
    }));
  };

  const setThinking = (isThinking) => {
    setConnectionState((prev) => ({
      ...prev,
      isThinking,
    }));
  };

  return {
    ...connectionState,
    socket,
    startConnection,
    closeConnection,
    setThinking,
  };
};
