import { useQuery } from "@tanstack/react-query";
import { ReportServices } from "services/api/reports";

export const useReportRecords = ({ queryData }) => {
  return useQuery({
    queryKey: ["reportRecords", queryData?.report_id, queryData?.page],
    queryFn: () => ReportServices.getReportRecords(queryData),
    enabled: !!queryData?.report_id,
    select: (data) => {
      const reportInfo = data?.data?.data?.find((item) =>
        item?.hasOwnProperty("report_info"),
      )?.report_info?.attributes;

      const reportRecords = data?.data?.data?.find((item) =>
        item?.hasOwnProperty("records"),
      )?.records;

      return {
        reportInfo,
        reportRecords,
        pagination: data?.data?.pagination,
      };
    },
  });
};
