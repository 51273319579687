import {
  Grid,
  Box,
  MenuItem,
  Select,
  ListItemText,
  Typography,
} from "@mui/material";
import { useIntl } from "react-intl";
import "../../surveyInsights.scss";
import { CheckValueLocale, isEmptyArray } from "utils/helpers";
import OneQuestionsData from "./oneQuestionsData";
import FetchingData from "components/fetchingData/fetchingData";
import _ from "lodash";
import NoDataFound from "components/no-Data/noDataFound";
import { useEffect, useState } from "react";
import { CustomPagination } from "components/CustomPagination/CustomPagination";

const QuestionsTab = (props) => {
  const intl = useIntl();
  const data = props?.questions;

  const [paginationCounter, setPaginationCounter] = useState(0);

  useEffect(() => {
    let conunter = 0;
    Object.keys(props?.questions)?.map((question, i) => {
      conunter += props?.questions[question]?.questions?.length;
    });
    setPaginationCounter(conunter);
  }, [props?.questions]);

  const selectedSection = Object.values(data)?.find((section) =>
    section?.questions?.some(
      (question) =>
        question?.question_id === props?.selectedQuestionHeader?.question_id,
    ),
  );

  return (
    <>
      {_.isEqual(
        props?.questionsPreLoader?.sort(),
        props?.preLoaderTrackerDataSources?.sort(),
      ) !== true ||
      !props?.questionsPreLoader?.length ||
      !props?.preLoaderTrackerDataSources?.length ? (
        <Box className="fetching-questions">
          <FetchingData />
        </Box>
      ) : props?.questions?.length === 0 || props?.questions == {} ? (
        <NoDataFound />
      ) : (
        <>
          <Box className="questions-box">
            <Grid container>
              <Grid item xs={6} md={4} mb={3}>
                <Select
                  name="questions-dropdown"
                  value={props?.selectedQuestionHeader}
                  className="questions-dropdown"
                  renderValue={(selected) =>
                    props?.selectedQuestionHeader?.question
                  }
                >
                  {Object.keys(data)?.map((question, i) => {
                    return (
                      <>
                        {Object.keys(data)?.length > 1 && (
                          <MenuItem
                            key={i}
                            disabled
                            id="survey-insights-section-name"
                          >
                            <ListItemText
                              primary={
                                CheckValueLocale("section", "", {}, intl) +
                                " " +
                                (i + 1) +
                                " " +
                                `(${data[question]?.section_name})`
                              }
                            />
                          </MenuItem>
                        )}
                        {data[question]?.questions?.map((ques, i) => (
                          <MenuItem
                            key={i}
                            selected={
                              ques?.question_id ==
                              props?.selectedQuestionHeader?.question_id
                            }
                            value={ques}
                            onClick={() => props?.handleChangeQuestions(ques)}
                            id={`survey-insights-${
                              ques?.question ?? "question"
                            }`}
                          >
                            <ListItemText
                              primary={i + 1 + ". " + ques?.question}
                            />
                          </MenuItem>
                        ))}
                      </>
                    );
                  })}
                </Select>
              </Grid>

              <Grid item xs={6} className="pagination-card">
                {paginationCounter > 1 && (
                  <CustomPagination
                    page={props?.page}
                    onChange={props?.handleChangePage}
                    pagesCount={paginationCounter}
                    itemsCount={paginationCounter}
                    itemsPerPage={1}
                  />
                )}
              </Grid>
            </Grid>
          </Box>

          <Box
            className={
              props?.selectedQuestion?.type === "nps"
                ? "answer-box-nps "
                : "answers-box " +
                  (selectedSection?.text_direction === "rtl"
                    ? "question-direction-rtl"
                    : "question-direction-ltr")
            }
          >
            {props?.selectedQuestion?.type === "scale" && (
              <>
                <Typography component={"h2"} mx={2} fontWeight={"500"} mb={1}>
                  {props?.selectedQuestion?.question}
                </Typography>
                <Typography component={"h3"} mx={2} fontWeight={"400"}>
                  {CheckValueLocale(
                    "responses_num",
                    "",
                    { num: props?.selectedQuestion?.total_answer[0] },
                    intl,
                  )}
                </Typography>
              </>
            )}
            {props?.selectedQuestion?.type !== "nps" &&
              props?.selectedQuestion?.type !== "scale" && (
                <div className="answers-header">
                  {props?.selectedQuestion?.total_answer}{" "}
                </div>
              )}
            {props?.selectedQuestion?.type !== "scale"}
            {!isEmptyArray(props?.questions) && (
              <OneQuestionsData
                selectedQuestion={props?.selectedQuestion}
                isSummary={false}
                id={props?.id}
                productId={props?.productId}
                sentimentsOptions={props?.sentimentsOptions}
                categoriesOptions={props?.categoriesOptions}
                themesOptions={props?.themesOptions}
              />
            )}
          </Box>
        </>
      )}
    </>
  );
};
export default QuestionsTab;
