import { useQuery } from "@tanstack/react-query";
import { MainMonitorsPage } from "services/api/mainMonitorsPage";

export const useGetAllawedMonitors = ({ enabled, queryData }) => {
  return useQuery({
    queryKey: ["allowedMonitors"],
    queryFn: () => MainMonitorsPage.getAllawedMonitors({ queryData }),
    enabled,
    select: (data) => data?.data,
  });
};
