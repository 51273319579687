import { noOnlySpaces } from "pages/engagements/components/aiAgents/aiAgentsValidation/publishValidationSchema";
import {
  emailIsValid,
  isOnlyNumbers,
  isValidPhone,
  isEmojis,
  checkSpecialCharExist,
  charForNumbers,
  whiteSpaces,
} from "utils/helpers";
import * as Yup from "yup";

export const nameValidationSchema = Yup.object({
  items: Yup.array().of(
    Yup.object({
      value: Yup.string().when("editable", {
        is: true, // Only apply validations when editable is true
        then: (schema) =>
          schema
            .required("this_field_is_required")
            .concat(noOnlySpaces("this_field_is_required"))
            .test("no-emojis", "emoji", (value) => value && !isEmojis(value))
            .test(
              "only-letters",
              "only_letters_and_spaces_allowed",
              (value) => value && isOnlyNumbers(value),
            )
            .max(40, "name_maximum_40_characters_allowed")
            .test(
              "is-unique",
              "this_name_already_exist",
              function (value, context) {
                if (!value) return true;
                const { path } = context;
                const items = this.from[1].value.items;
                const currentIndex = parseInt(path.split("[")[1]);
                const firstIndex = items.findIndex(
                  (item) => item.value === value,
                );
                return firstIndex === currentIndex;
              },
            ),
        otherwise: (schema) => schema,
      }),
      editable: Yup.boolean(),
      id: Yup.mixed().nullable(),
    }),
  ),
});

export const phoneValidationSchema = Yup.object({
  items: Yup.array().of(
    Yup.object({
      value: Yup.string().when("editable", {
        is: true,
        then: (schema) =>
          schema
            .required("this_field_is_required")
            .concat(noOnlySpaces("this_field_is_required"))
            .test("no-emojis", "emoji", (value) => value && !isEmojis(value))
            .test(
              "valid-phone",
              "only_numbers_plus_sign_and_dash_allowed",
              (value) => value && !isValidPhone(value),
            )
            .min(7, "engager_profile_mobile_number_length_error")
            .max(17, "engager_profile_mobile_number_length_error")
            .test(
              "is-unique",
              "this_mobile_number_already_exist",
              function (value, context) {
                if (!value) return true;
                const { path } = context;
                const items = this.from[1].value.items;
                const currentIndex = parseInt(path.split("[")[1]);
                const firstIndex = items.findIndex(
                  (item) => item.value === value,
                );
                return firstIndex === currentIndex;
              },
            ),
        otherwise: (schema) => schema,
      }),
      editable: Yup.boolean(),
      id: Yup.mixed().nullable(),
    }),
  ),
});

export const emailValidationSchema = Yup.object({
  items: Yup.array().of(
    Yup.object({
      value: Yup.string().when("editable", {
        is: true,
        then: (schema) =>
          schema
            .required("this_field_is_required")
            .concat(noOnlySpaces("this_field_is_required"))
            .test("no-emojis", "emoji", (value) => value && !isEmojis(value))
            .test(
              "valid-email",
              "engager_profile_email_error",
              (value) => value && !emailIsValid(value),
            )
            .max(65, "social_media_username_length_error")
            .test(
              "is-unique",
              "this_email_already_exist",
              function (value, context) {
                if (!value) return true;
                const { path } = context;
                const items = this.from[1].value.items;
                const currentIndex = parseInt(path.split("[")[1]);
                const firstIndex = items.findIndex(
                  (item) => item.value === value,
                );
                return firstIndex === currentIndex;
              },
            ),
        otherwise: (schema) => schema,
      }),
      editable: Yup.boolean(),
      id: Yup.mixed().nullable(),
    }),
  ),
});
// ///////////////////////////////////////////////////////

export const facebookValidationSchema = Yup.object({
  items: Yup.array().of(
    Yup.object({
      value: Yup.string().when("editable", {
        is: true,
        then: (schema) =>
          schema
            .required("this_field_is_required")
            .concat(noOnlySpaces("this_field_is_required"))
            .test("no-emojis", "emoji", (value) => value && !isEmojis(value))
            .test(
              "no-white-spaces",
              "white_spaces",
              (value) => value && !whiteSpaces(value),
            )
            .test(
              "valid-facebook",
              "engager_profile_social_allowed_letters",
              (value) => value && checkSpecialCharExist(value),
            )
            .max(65, "social_media_username_length_error")
            .test(
              "is-unique",
              "engager_profile_social_username_already_exist",
              function (value, context) {
                if (!value) return true;
                const { path } = context;
                const items = this.from[1].value.items;
                const currentIndex = parseInt(path.split("[")[1]);
                const firstIndex = items.findIndex(
                  (item) => item.value === value,
                );
                return firstIndex === currentIndex;
              },
            ),
        otherwise: (schema) => schema,
      }),
      editable: Yup.boolean(),
      id: Yup.mixed().nullable(),
    }),
  ),
});

export const twitterValidationSchema = Yup.object({
  items: Yup.array().of(
    Yup.object({
      value: Yup.string().when("editable", {
        is: true,
        then: (schema) =>
          schema
            .required("this_field_is_required")
            .concat(noOnlySpaces("this_field_is_required"))
            .test("no-emojis", "emoji", (value) => value && !isEmojis(value))
            .test(
              "no-white-spaces",
              "white_spaces",
              (value) => value && !whiteSpaces(value),
            )
            .test(
              "valid-twitter",
              "engager_profile_social_allowed_letters",
              (value) => value && checkSpecialCharExist(value),
            )
            .max(15, "twitter_username_length_error")
            .test(
              "is-unique",
              "engager_profile_social_username_already_exist",
              function (value, context) {
                if (!value) return true;
                const { path } = context;
                const items = this.from[1].value.items;
                const currentIndex = parseInt(path.split("[")[1]);
                const firstIndex = items.findIndex(
                  (item) => item.value === value,
                );
                return firstIndex === currentIndex;
              },
            ),
        otherwise: (schema) => schema,
      }),
      editable: Yup.boolean(),
      id: Yup.mixed().nullable(),
    }),
  ),
});

export const instagramValidationSchema = Yup.object({
  items: Yup.array().of(
    Yup.object({
      value: Yup.string().when("editable", {
        is: true,
        then: (schema) =>
          schema
            .required("this_field_is_required")
            .concat(noOnlySpaces("this_field_is_required"))
            .test("no-emojis", "emoji", (value) => value && !isEmojis(value))
            .test(
              "no-white-spaces",
              "white_spaces",
              (value) => value && !whiteSpaces(value),
            )
            .test(
              "valid-instagram",
              "engager_profile_social_allowed_letters",
              (value) => value && checkSpecialCharExist(value),
            )
            .max(65, "social_media_username_length_error")
            .test(
              "is-unique",
              "engager_profile_social_username_already_exist",
              function (value, context) {
                if (!value) return true;
                const { path } = context;
                const items = this.from[1].value.items;
                const currentIndex = parseInt(path.split("[")[1]);
                const firstIndex = items.findIndex(
                  (item) => item.value === value,
                );
                return firstIndex === currentIndex;
              },
            ),
        otherwise: (schema) => schema,
      }),
      editable: Yup.boolean(),
      id: Yup.mixed().nullable(),
    }),
  ),
});
export const whatsappValidationSchema = Yup.object({
  items: Yup.array().of(
    Yup.object({
      value: Yup.string().when("editable", {
        is: true,
        then: (schema) =>
          schema
            .required("this_field_is_required")
            .concat(noOnlySpaces("this_field_is_required"))
            .test("no-emojis", "emoji", (value) => value && !isEmojis(value))
            .test(
              "valid-phone",
              "only_numbers_plus_sign_and_dash_allowed",
              (value) => value && !isValidPhone(value),
            )
            .test(
              "valid-phone",
              "wrong_lang",
              (value) => value && !charForNumbers(value),
            )
            .min(7, "wrong_phone")
            .max(17, "wrong_phone")
            .test(
              "is-unique",
              "this_mobile_number_already_exist",
              function (value, context) {
                if (!value) return true;
                const { path } = context;
                const items = this.from[1].value.items;
                const currentIndex = parseInt(path.split("[")[1]);
                const firstIndex = items.findIndex(
                  (item) => item.value === value,
                );
                return firstIndex === currentIndex;
              },
            ),
        otherwise: (schema) => schema,
      }),
      editable: Yup.boolean(),
      id: Yup.mixed().nullable(),
    }),
  ),
});

export const tiktokValidationSchema = Yup.object({
  items: Yup.array().of(
    Yup.object({
      value: Yup.string().when("editable", {
        is: true,
        then: (schema) =>
          schema
            .required("this_field_is_required")
            .concat(noOnlySpaces("this_field_is_required"))
            .test("no-emojis", "emoji", (value) => value && !isEmojis(value))
            .test(
              "valid-tiktok",
              "engager_profile_social_allowed_letters",
              (value) => value && checkSpecialCharExist(value),
            )
            .test(
              "is-unique",
              "engager_profile_social_username_already_exist",
              function (value, context) {
                if (!value) return true;
                const { path } = context;
                const items = this.from[1].value.items;
                const currentIndex = parseInt(path.split("[")[1]);
                const firstIndex = items.findIndex(
                  (item) => item.value === value,
                );
                return firstIndex === currentIndex;
              },
            ),
        otherwise: (schema) => schema,
      }),
      editable: Yup.boolean(),
      id: Yup.mixed().nullable(),
    }),
  ),
});

export const socialMediaValidationSchemaMap = {
  fb: facebookValidationSchema,
  ig: instagramValidationSchema,
  gmail: emailValidationSchema,
  intercom: emailValidationSchema,
  whatsapp: whatsappValidationSchema,
  tw: twitterValidationSchema,
  tiktok: tiktokValidationSchema,
};
