import { useMutation } from "@tanstack/react-query";
import SurveyController from "services/controllers/surveyController";

export const useDuplicateSurvey = () => {
  return useMutation({
    mutationFn: ({ id, queryData }) =>
      SurveyController.duplicateSurvey(id, queryData),
    select: (data) => data?.data,
    retry: false,
  });
};
