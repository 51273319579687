import { Box, Tooltip } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const AddKnowledgeBaseStatusCell = ({ status, sourceType }) => {
  const intl = useIntl();
  const isFailed = status === "failed";
  const isURL = sourceType === "url";
  return (
    <Tooltip
      arrow
      placement="left-start"
      title={
        isFailed
          ? isURL
            ? CheckValueLocale(
                "knowledge_base_url_resource_failed_status_tooltip",
                "",
                {},
                intl,
              )
            : CheckValueLocale(
                "knowledge_base_tex_file_resource_failed_status_tooltip",
                "",
                {},
                intl,
              )
          : ""
      }
    >
      <Box className={`status ${status}`}>
        {CheckValueLocale(status, "", {}, intl)}
      </Box>
    </Tooltip>
  );
};

export default AddKnowledgeBaseStatusCell;
