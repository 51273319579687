import React, { useContext } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { EditReportContext } from "pages/reports/context/reportContext";
import ReportTextInput from "pages/reports/components/reportTextInput";
import ReportSelectInput from "pages/reports/components/reportSelectInput";

const EditReportInformationStep = () => {
  const { formik, reportDetails } = useContext(EditReportContext);

  const intl = useIntl();

  return (
    <Box className="edit-report-step-wrapper">
      <Typography variant="h6" className="edit-report-step-title">
        1. {CheckValueLocale("report_information", "", {}, intl)}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ReportTextInput
            id="edit-report-title-text-input"
            label="edit_report_name"
            placeholder="write_your_report_name"
            name="title"
            value={formik?.values?.title}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            error={formik?.touched?.title && Boolean(formik?.errors?.title)}
            helperText={formik?.touched?.title && formik?.errors?.title}
            isEdit={true}
          />
        </Grid>
        <Grid item xs={6}>
          <ReportSelectInput
            id="edit-report-platform-input"
            label="edit_report_platform"
            renderValue={() =>
              CheckValueLocale(
                `reports_${reportDetails?.platform}`,
                "",
                {},
                intl,
              )
            }
            disabled={true}
            isEdit={true}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditReportInformationStep;
