import { Grid, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { handlelUserRoles } from "utils/helpers";
import CardHeader from "./cardHeader";
import CardData from "./cardData";

const SurveyCards = (props) => {
  const navigate = useNavigate();
  const { surveys, surveyLimitReach, setSnackbar } = props;

  const handleCardClick = (survey) => {
    if (survey?.status === "draft") {
      navigate(`/survey/add-survey/survey-builder/${survey?.id}`);
    } else {
      navigate(`/survey/survey-insights/${survey?.id}`, { state: survey });
    }
  };

  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      {surveys?.map((survey, i) => {
        const clickableCard =
          survey?.status === "draft"
            ? handlelUserRoles("SURVEY", "EDIT_SURVEY")
            : true;

        return (
          <Grid item xs={6} key={i} className="survey-card-body">
            <Box className={`survey-card ${clickableCard ? "clickable" : ""}`}>
              {/* Adding onClick on overlay to avoid going into e.stopPropagation for each button and popup functionallity inside CardHeader */}
              <Box
                className="survey-card-overlay"
                onClick={() => {
                  if (clickableCard) handleCardClick(survey);
                }}
              />

              <CardHeader
                survey={survey}
                surveyLimitReach={surveyLimitReach}
                setSnackbar={setSnackbar}
              />
              <CardData survey={survey} />
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};
export default SurveyCards;
