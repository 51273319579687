import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import SharedTable from "../../../../shared/table/sharedTable";
import moment from "moment";
import { Box, Tooltip, Button, Zoom, Typography } from "@mui/material";
import "./alert-list.scss";
import AlertOptions from "./AlertOptions";
import {
  CheckValueLocale,
  getSocialIcon,
  newProductNameMap,
} from "utils/helpers";
import ErrorIcon from "@mui/icons-material/Error";
import { sortedColumnTypes } from "hooks/useSortableTable";
import NoDataFoundProducts from "components/no-Data";
import NoDataLinks from "components/no-Data/noDataLinks";

export const getAlertTableHead = (intl) => {
  return [
    {
      body: CheckValueLocale("alert_id", "", {}, intl),
      name: "id",
      type: sortedColumnTypes.number,
    },
    {
      body: CheckValueLocale("alert", "", {}, intl),
      width: "30%",
      name: "name",
      type: sortedColumnTypes.string,
    },
    {
      body: CheckValueLocale("alert_product", "", {}, intl),
      width: "15%",
      name: "product",
      type: sortedColumnTypes.string,
    },
    {
      body: CheckValueLocale("source", "", {}, intl),
      name: "source",
      type: sortedColumnTypes.number,
    },
    {
      body: CheckValueLocale("create_date_alert", "", {}, intl),
      width: "20%",
      name: "created_at",
      type: sortedColumnTypes.date,
    },
    {
      body: <></>,
      width: "4%",
    },
  ];
};
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses?.arrow}`]: {
    color: "rgba(97, 97, 97, 0.9)",
  },
  [`& .${tooltipClasses?.tooltip}`]: {
    backgroundColor: "rgba(97, 97, 97, 0.9)",
    fontSize: "12px",
    lineHeight: "14px",
    padding: "4px 8px",
    fontWeight: "500",
  },
}));

const showAlertMonitorsData = (monitor_models) => {
  const monitors = monitor_models?.map((val) => val?.name);
  const monitorText = monitors ? monitors?.join(", ") : "--";
  const showMonitorsTooltip = monitorText?.length > 25;
  let textCount = 0;
  const remainTextArrayAccount = monitors?.findIndex((text) => {
    textCount += text?.length;
    textCount += 2;
    return textCount >= 25;
  });
  return (
    <>
      <Typography className="monitor-name" component="span" dir="ltr">
        {monitorText?.substring(0, 25)}
        {showMonitorsTooltip ? ".." : ""}
      </Typography>
      {showMonitorsTooltip ? (
        <BootstrapTooltip
          title={monitors?.slice(remainTextArrayAccount)?.join(", ")}
          arrow
          TransitionComponent={Zoom}
          placement="top"
        >
          <Button id="alert-list-monitor-names-slice-btn" disableRipple>
            +{monitors?.slice(remainTextArrayAccount)?.length}
          </Button>
        </BootstrapTooltip>
      ) : null}
    </>
  );
};

const showAlertDataSourcesData = (sourceName) => {
  return (
    <>
      <Box className="alerts-social">
        <Box className="icon-after-title">{getSocialIcon(sourceName)}</Box>
      </Box>
    </>
  );
};
const AlertList = (props) => {
  const { alerts, alertTypeLocale, tableHead, isSortableLoading } = props;
  const intl = useIntl();
  const [tableLoading, setTableLoading] = useState(true);
  const [tableBody, setTableBody] = useState([]);
  const [tableEmptyBody, setTableEmptyBody] = useState([]);

  const nameValid = (name, type, datasourceName) => {
    return (
      <>
        <Typography className="alert-type">
          {type == "—" ? type : alertTypeLocale(type, datasourceName)}
        </Typography>
        <br />
        <Typography className="alert-name">{name?.substring(0, 40)}</Typography>
      </>
    );
  };
  const showAlersProductsData = (prod, sources) => {
    return (
      <>
        <Typography className="alert-type">
          {CheckValueLocale(newProductNameMap[prod] || prod, "", {}, intl)}
        </Typography>
        <br />
        {sources == "—"
          ? sources
          : sources?.toLowerCase() === "survey"
            ? null
            : showAlertDataSourcesData(sources)}
      </>
    );
  };

  const showAlersCreatedData = (date) => {
    const createDate = moment?.unix(date)?.format("DD-MM-YYYY , hh:mm a");

    const arr = createDate?.split(",");
    return (
      <>
        <Typography className="alert-type">{arr[0]}</Typography>
        <br />
        <Typography className="alert-name">{arr[1]}</Typography>
      </>
    );
  };

  const alertOpts = (item) => {
    return <AlertOptions alertData={item} {...props} />;
  };

  const showAlersIdData = (id, valid, enabled) => {
    const warningId = valid == false && enabled == true;
    return (
      <>
        <Typography className="alert-id">
          {warningId ? (
            <BootstrapTooltip
              title={
                <span className="invalid-tooltip">
                  {CheckValueLocale(
                    "issue_detected_connecting_api_endpoint",
                    "",
                    {},
                    intl,
                  )}
                </span>
              }
              placement="bottom"
              arrow
              TransitionComponent={Zoom}
            >
              <Box className="warning-icon-countainer">
                <ErrorIcon
                  className="dashboard-warning-icon"
                  fontSize="small"
                />
              </Box>
            </BootstrapTooltip>
          ) : null}
          {id}
        </Typography>
      </>
    );
  };
  useEffect(() => {
    setTableLoading(true);
    const tabBody = alerts?.map((item) => {
      const warningRow =
        item?.attributes?.webhook_info?.is_valid == false &&
        item?.attributes?.webhook_info?.enabled == true;
      return {
        id: item?.id,
        className: warningRow ? "warning" : "",
        data: [
          {
            body: showAlersIdData(
              item?.id,
              item?.attributes?.webhook_info?.is_valid,
              item?.attributes?.webhook_info?.enabled,
            ),
          },
          {
            body: nameValid(
              item?.attributes?.name?.length ? item?.attributes?.name : "—",
              item?.attributes?.alert_type?.name?.length
                ? item?.attributes?.alert_type?.name
                : "—",
              item?.attributes?.data_source?.name?.toLowerCase(),
            ),
          },
          {
            body: showAlersProductsData(
              item?.attributes?.products?.name?.length
                ? item?.attributes?.products?.name
                : "—",
              item?.attributes?.data_source?.name?.length
                ? item?.attributes?.data_source?.name
                : "—",
            ),
          },
          {
            body:
              item?.attributes?.alert_type?.name?.toLowerCase() ===
              "x top trends"
                ? CheckValueLocale("top_trends", "", {}, intl)
                : item?.attributes?.monitors?.length > 0
                  ? showAlertMonitorsData(item?.attributes?.monitors)
                  : "—",
            className: "alert-monitor-td",
          },
          {
            body: item?.attributes?.created_at
              ? showAlersCreatedData(item?.attributes?.created_at)
              : "—",
            className: "date-direction",
          },
          {
            body: alertOpts(item),
          },
        ],
      };
    });
    setTableBody(tabBody);
    setTableEmptyBody(emptyBody(alerts));

    setTableLoading(false);
  }, [alerts, intl?.locale]);

  const emptyBody = (alerts) => {
    return (
      <>
        {props?.selectedTypes?.length > 0 && alerts?.length == 0 ? (
          <Box className="no-data-cdp">
            <NoDataFoundProducts
              image={"noDataImg"}
              title={"no_data_found"}
              subTitle="sorry_adjust_your_search"
            />
          </Box>
        ) : alerts?.length == 0 ? (
          <Box className="empty-item">
            <NoDataFoundProducts
              image="alert"
              title="create_your_alert"
              description="create_your_alert_dec"
              linkOne="how_to_use"
              linkOneHref={NoDataLinks.alerts_link}
              orCase="or_visit_the"
              linkTwo="knowledge_base"
            />
          </Box>
        ) : null}
      </>
    );
  };
  return (
    <>
      <SharedTable
        tableHeader={tableHead}
        tableBody={tableBody}
        className={"alert-table"}
        emptyBody={tableEmptyBody}
        isSharedStyle
        {...props}
        loading={tableLoading || isSortableLoading}
      />
    </>
  );
};
export default AlertList;
