import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/pro-regular-svg-icons";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { PlayArrow, Pause } from "@mui/icons-material";
import CustomizedTooltip from "components/customizedTooltip";
import DeleteReportModal from "./deleteReportModal";
import PauseResumeModal from "./pauseResumeModal";

const ReportsListOptionsDropdown = ({ id, title, paused, setSnackbar }) => {
  const navigate = useNavigate();
  const intl = useIntl();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openPauseResumeModal, setOpenPauseResumeModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleChangeMenu = (e) => {
    if (!anchorEl) setAnchorEl(e.currentTarget);
    else setAnchorEl(null);
  };

  const menuOptions = [
    {
      id: "reports-list-options-view-report-button",
      title: CheckValueLocale("view_reports", "", {}, intl),
      icon: <VisibilityIcon />,
      onClick: () => navigate(`/reports/view-report/${id}`),
    },
    {
      id: "reports-list-options-edit-report-button",
      title: CheckValueLocale("edit", "", {}, intl),
      icon: <EditIcon />,
      onClick: () => navigate(`/reports/edit-report/${id}`),
    },
    {
      id: paused
        ? "reports-list-options-resume-report-button"
        : "reports-list-options-pause-report-button",
      title: CheckValueLocale(paused ? "resume" : "pause", "", {}, intl),
      icon: paused ? <PlayArrow /> : <Pause />,
      onClick: () => setOpenPauseResumeModal(true),
    },
    {
      id: "reports-list-options-delete-report-button",
      title: CheckValueLocale("delete", "", {}, intl),
      icon: <DeleteIcon />,
      onClick: () => setOpenDeleteModal(true),
    },
  ];

  return (
    <Box>
      <CustomizedTooltip
        title={CheckValueLocale("items_ellipsis", "", {}, intl)}
        styles={{ fontSize: "12px", lineHeight: "14px", padding: "12px" }}
        placement="bottom"
        arrow
      >
        <IconButton
          id="demo-customized-button"
          className={`menu-items menu-item-container ${open ? "menu-item-active" : ""}`}
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="contained"
          onClick={handleChangeMenu}
        >
          <FontAwesomeIcon className="ellipsis-icon" icon={faEllipsisH} />
        </IconButton>
      </CustomizedTooltip>
      <Menu
        id="reports-list-options-dropdown-menu"
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: intl?.locale === "ar" ? "left" : "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: intl?.locale === "ar" ? "left" : "right",
        }}
      >
        {menuOptions?.map((item) => (
          <MenuItem
            id={item?.id}
            key={item?.id}
            onClick={() => {
              setAnchorEl(null);
              item?.onClick();
            }}
            disableRipple
          >
            {item?.icon ? item?.icon : null}
            <Typography>{item?.title}</Typography>
          </MenuItem>
        ))}
      </Menu>

      <PauseResumeModal
        id={id}
        title={title}
        paused={paused}
        open={openPauseResumeModal}
        setOpen={setOpenPauseResumeModal}
        setSnackbar={setSnackbar}
      />

      <DeleteReportModal
        id={id}
        title={title}
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        setSnackbar={setSnackbar}
      />
    </Box>
  );
};

export default ReportsListOptionsDropdown;
