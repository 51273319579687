import { jwtDecode } from "jwt-decode";
import { useEffect, useState, useCallback } from "react";
import { useRefreshToken } from "./hooks/useRefreshToken";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetChatMessages } from "utils/redux/features/AiAgentChat/AiAgentChatSlice";
import { deleteCookie } from "utils/helpers";
import LoginController from "services/controllers/loginController";
import moment from "moment";
import { useMemoizedFn } from "hooks/useMemoizedFn";
import { useMemoizedValue } from "hooks/useMemoizedValue";

const RefreshToken = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [decodedToken, setDecodedToken] = useState(null);

  // Use the refreshToken mutation from your custom hook
  const { mutate: refreshToken, isPending } = useRefreshToken();

  //Use this function to sign out the user
  const handleSignOut = () => {
    let currentLang = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : window.navigator.language.split("-")[0];
    LoginController.userlogout().then((data) => {
      dispatch(resetChatMessages());
      localStorage.clear();
      deleteCookie("email");
      window.localStorage.setItem("lang", currentLang);
      window.location.href = "/login";
    });
  };

  const autoSendToken = useMemoizedFn(() => {
    if (isPending) return; // Prevent multiple API calls if already fetching

    refreshToken(
      {},
      {
        onSuccess: (data) => {
          const token = data?.data?.data?.["luc-authorization"];
          if (token) {
            const decoded = jwtDecode(token);
            setDecodedToken(decoded); // Update the state with the decoded token
            localStorage.setItem("user_token", token);
          } else {
            handleSignOut();
          }
        },
        onError: () => {
          if (navigator.onLine) {
            handleSignOut();
          }
        },
      },
    );
  });

  const decodedTokenRef = useMemoizedValue(decodedToken);

  useEffect(() => {
    const token = localStorage.getItem("user_token");
    if (token) {
      const decoded = jwtDecode(token);
      setDecodedToken(decoded);
    }
  }, []);

  useEffect(() => {
    const checkTokenAndRefresh = () => {
      const unixTimestamp = moment().utc().unix(); // Current time in Unix timestamp
      const tokenExpirationBuffer = 60; // 1-minute buffer
      const expiryAt = decodedTokenRef?.exp;

      if (expiryAt && expiryAt - unixTimestamp <= tokenExpirationBuffer) {
        autoSendToken();
      }
    };

    const handleOnlineConnection = () => {
      checkTokenAndRefresh();
    };

    window.addEventListener("online", handleOnlineConnection);
    return () => {
      window.removeEventListener("online", handleOnlineConnection);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const unixTimestamp = moment().utc().unix(); // Current time in Unix timestamp
      const expiryAt = decodedToken?.exp;
      const tokenExpirationBuffer = 60; // 1-minute buffer
      if (
        navigator.onLine &&
        expiryAt &&
        expiryAt - unixTimestamp <= tokenExpirationBuffer
      ) {
        autoSendToken();
      }
    }, 1000); // Check every minute

    return () => clearInterval(interval);
  }, [decodedToken]);

  return null;
};

export default RefreshToken;
