import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Tooltip,
  Chip,
} from "@mui/material";
import { useIntl } from "react-intl";
import { startWithEnglishCharacter } from "../../SharedFunctions/components/keywordLanguage";
import { getKeywordsLength } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/keywordsLength";
import "./keywordPopupModal.scss";
import { CheckValueLocale } from "utils/helpers";
import Divider from "@mui/material/Divider";

const KeywordPopupModal = (props) => {
  const intl = useIntl();
  const {
    keywords,
    dataSourceType,
    keywordType,
    handleDelete,
    dataSourceIndex,
    isTitleSearch,
    usedkeywords = [],
    settingkeywords = [],
    showListNumber = false,
  } = props;

  const [open, setOpen] = useState(false);
  // ____________________________________________________________________

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <>
        <Box style={{ right: "6px" }}>
          {keywords.length > 6 && (
            <Button
              color="inherit"
              variant="outlined"
              className="show-all-btn"
              onClick={handleClickOpen}
              id="monitor-options-show-all-keyword-btn"
            >
              {showListNumber ? (
                <>
                  {CheckValueLocale("show_all_kw", "", {}, intl)} (
                  {keywords?.length})
                </>
              ) : (
                CheckValueLocale("show_all_kw", "", {}, intl)
              )}
            </Button>
          )}
        </Box>

        <Dialog
          id="testKeywords"
          className="test-keyword-dialog"
          open={open}
          onClose={handleClose}
        >
          {/* dialog title */}
          <DialogTitle className="keyword-tester-title">
            <Box className="keyword-popup-data-source">
              {CheckValueLocale(keywordType, "", {}, intl)}
              <Box component="span">
                {CheckValueLocale(dataSourceType, "", {}, intl)}
              </Box>
            </Box>
          </DialogTitle>

          {/* dialog Content */}
          <Box className="test-keyword-content">
            <Divider flexItem />
            <DialogContent className="testkeywordDialogContent keyword-popup-content">
              {keywords?.map((obj, index) => {
                const usedLabel =
                  usedkeywords?.includes(obj?.replace("#", "")) &&
                  !settingkeywords?.includes(obj.replace("#", ""));
                const usedChipClass = usedLabel ? "used-chip-style" : "";
                return (
                  <Tooltip
                    title={
                      // If the keyword length is larger than 36, we will display tooltip because chip styles have==> {overflow: hidden} style
                      obj.length > 36
                        ? obj
                        : usedLabel
                          ? CheckValueLocale(
                              "used_hashtags_ig_tooltip",
                              "",
                              {},
                              intl,
                            )
                          : ""
                    }
                    placement="top"
                    arrow
                  >
                    <Chip
                      label={obj}
                      className={`
                        ${usedChipClass} 
                        ${
                          startWithEnglishCharacter(obj, "twitter")
                            ? "chip-style"
                            : "chip-style-arabic"
                        }
                      `}
                      onDelete={
                        isTitleSearch
                          ? () => handleDelete(index)
                          : (e) =>
                              handleDelete(
                                e,
                                index,
                                keywordType,
                                dataSourceIndex,
                                dataSourceType,
                              )
                      }
                    />
                  </Tooltip>
                );
              })}
            </DialogContent>
            <Divider flexItem />
            {/* dialog action */}
            <DialogActions className="text-keyword-btns-actions keyword-popup">
              <Box component="p" className="keyword-modal-length">
                {keywords && getKeywordsLength(keywords)}{" "}
                {CheckValueLocale(
                  keywordType === "collect_tweets" ||
                    keywordType === "ignore_tweets"
                    ? "users"
                    : keywordType === "accounts_lists_usernames"
                      ? "accounts_lists_popups_usernames"
                      : "keywords",
                  "",
                  {},
                  intl,
                )}
              </Box>
              <Button
                color="inherit"
                variant="outlined"
                className="test-keyword-dialog-cancel-btn keyword-popup-done-btn"
                onClick={handleClose}
                id="monitor-options-done-keyword-btn"
              >
                {CheckValueLocale("done", "", {}, intl)}
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      </>
      {/* )} */}
    </>
  );
};

export default KeywordPopupModal;
