import * as Yup from "yup";
import { CheckValueLocale } from "utils/helpers";
import { isPublicSpecialCharactersOrEmojis } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/specialCharOrEmojis";
import { useIntl } from "react-intl";

export const searchAccountsListsValidationSchema = () => {
  const intl = useIntl();

  const isCorrectFormation = (value) => {
    if (!value) return true;
    return !isPublicSpecialCharactersOrEmojis(value, undefined, true);
  };

  return Yup.object({
    search: Yup.string()?.test(
      "special-characters-description",
      CheckValueLocale("account_lists_characters_allowed", "", {}, intl),
      isCorrectFormation,
    ),
  });
};
