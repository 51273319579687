import React, { useContext } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Alert, Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import { ViewReportContext } from "pages/reports/context/reportContext";
import CustomizedTooltip from "components/customizedTooltip";

const ViewReportAlerts = () => {
  const { reportInfo, reportRecords } = useContext(ViewReportContext);

  const intl = useIntl();

  const { platform, next_delivery } = reportInfo || {};

  const isSocialListening = platform?.toLowerCase() === "sm";

  const renderAlertContent = () => {
    const {
      issues,
      platform,
      manual_status,
      is_luci_report,
      has_luci_quota,
      created_at,
    } = reportInfo || {};

    if (issues?.is_limit_reached) {
      return CheckValueLocale(
        "view_report_paused_due_to_limit_reached",
        "",
        {},
        intl,
      );
    }

    if (issues?.are_monitors_deleted) {
      return CheckValueLocale(
        isSocialListening
          ? "view_report_paused_due_to_monitor_deleted"
          : "view_report_paused_due_to_channel_deleted",
        "",
        {
          link: (
            <Link to="/reports/add-report" className="create-report-link">
              "{CheckValueLocale("create_report", "", {}, intl)}"
            </Link>
          ),
        },
        intl,
      );
    }

    if (issues?.paused_monitors?.length) {
      return (
        <Box className="view-report-warning-msg-wrapper deleted-monitors">
          <Typography>
            {CheckValueLocale(
              isSocialListening
                ? "view_report_paused_due_to_monitor_paused"
                : "view_report_paused_due_to_channel_paused",
              "",
              {
                paused: (
                  <Box component="span">
                    {issues?.paused_monitors?.length >= 3 ? (
                      <CustomizedTooltip
                        title={issues?.paused_monitors?.join(" / ")}
                        placement="bottom"
                        arrow
                        styles={{ height: "auto", padding: "10px" }}
                      >
                        {`${issues?.paused_monitors?.slice(0, 2)?.join(", ")}... (+${issues?.paused_monitors?.length - 2})`}
                      </CustomizedTooltip>
                    ) : (
                      issues?.paused_monitors?.join(", ")
                    )}
                  </Box>
                ),
              },
              intl,
            )}
          </Typography>
          {isSocialListening && handlelUserRoles("SM", "VIEW_MONITOR_LIST") ? (
            <Link to="/social/monitor_list">
              {CheckValueLocale("view_report_monitors_list", "", {}, intl)}
            </Link>
          ) : platform === "CXM" &&
            (handlelUserRoles("CXM", "CREATE_CHANNEL") ||
              handlelUserRoles("CXM", "EDIT_CHANNEL") ||
              handlelUserRoles("CXM", "DESTROY_CHANNEL")) ? (
            <Link to="/manage-channels/list">
              {CheckValueLocale("view_report_manage_channels", "", {}, intl)}
            </Link>
          ) : null}
        </Box>
      );
    }

    if (is_luci_report && !has_luci_quota) {
      return (
        <Box className="view-report-warning-msg-wrapper luci-quota">
          <Typography>
            {CheckValueLocale("luci_report_energy_warning", "", {}, intl)}
          </Typography>
          {handlelUserRoles("GENERAL", "VIEW_SETTING") ? (
            <Link to="/settings/subscription">
              {CheckValueLocale("upgrade_luci_energy", "", {}, intl)}
            </Link>
          ) : null}
        </Box>
      );
    }

    if (!manual_status) {
      return CheckValueLocale(
        "reports_was_paused",
        "",
        {
          date: moment(created_at)?.format("YYYY-MM-DD") || "",
        },
        intl,
      );
    }

    return null;
  };

  return (
    <Box>
      {reportInfo && renderAlertContent() ? (
        <Box my={2}>
          <Alert id="view-report-warning-alert" severity="warning">
            {renderAlertContent()}
          </Alert>
        </Box>
      ) : null}

      {/* Next Delivery Date shows only when no reports are available and next delivery date is available from BE */}
      {!reportRecords?.length && next_delivery && next_delivery !== "N/A" ? (
        <Box className="report-date-empty-box">
          <Typography variant="subtitle2" component="div">
            {CheckValueLocale(
              "report_next_delivery_msg",
              "",
              {
                date:
                  moment(next_delivery)
                    ?.utcOffset(next_delivery)
                    ?.format("YYYY-MM-DD (h:mma)") || "",
              },
              intl,
            )}
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};

export default ViewReportAlerts;
