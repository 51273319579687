import {
  Box,
  MenuItem,
  Select,
  Typography,
  Tooltip,
  InputLabel,
  Checkbox,
} from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import FormControl from "@mui/material/FormControl";
import profileImg from "images/engagements-images/profileImg.png";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Chip from "@mui/material/Chip";
import _ from "lodash";
import DOMPurify from "dompurify";

const AccountsSelect = ({
  accountData,
  accountsPublishSelected,
  allawedAccountData,
  setAccountsPublishSelected,
  handleAccounts,
  openPopupPublish,
  openPopup,
  setOpenPublish,
  handleDatasourcesIcon,
  setContentErrorMedia,
  publishTypeEvent,
}) => {
  const intl = useIntl();
  const handleSelectionChange = (event) => {
    setContentErrorMedia("");
    const { value } = event.target;
    let filteredArray = [...value];
    let checkExistVal = filteredArray?.filter(
      (el) =>
        el.reference_id ==
          filteredArray[filteredArray?.length - 1]?.reference_id &&
        el.data_source == filteredArray[filteredArray?.length - 1]?.data_source,
    );
    if (checkExistVal?.length == 2) {
      filteredArray = filteredArray?.filter(
        (el) =>
          !(
            el.reference_id ==
              filteredArray[filteredArray?.length - 1]?.reference_id &&
            el.data_source ==
              filteredArray[filteredArray?.length - 1]?.data_source
          ),
      );
    }
    setAccountsPublishSelected([...filteredArray]);
  };

  const removeAccount = (index) => {
    setContentErrorMedia("");
    let filteredAccounts = _.cloneDeep([...accountsPublishSelected]);
    filteredAccounts.splice(index, 1);

    setAccountsPublishSelected([...filteredAccounts]);
  };
  let dataAccounts =
    publishTypeEvent == "edit_publish" ? accountData : allawedAccountData;
  return (
    <Box className="publish-new-post-accounts-container">
      <Typography component={"p"} className="publish-new-post-accounts-title ">
        {CheckValueLocale("publish_accounts", "", {}, intl)}
      </Typography>
      <Box>
        <Box className="input-form-control">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              {CheckValueLocale("select_publish_accounts_popup", "", {}, intl)}
            </InputLabel>
            <Select
              id="demo-multiple-checkbox"
              className={`accounts-select-publish-items ${
                !accountsPublishSelected?.length &&
                "accounts-select-publish-items-empty "
              } ${
                publishTypeEvent == "edit_publish" &&
                "accounts-select-publish-items-edit"
              }`}
              multiple
              displayEmpty
              value={accountsPublishSelected}
              onChange={(e) => handleSelectionChange(e)}
              disabled={publishTypeEvent == "edit_publish"}
              renderValue={(selected) => {
                return (
                  <Box
                    className={`account-select-publish-container ${
                      publishTypeEvent == "edit_publish" &&
                      "account-select-publish-container-edit"
                    }`}
                  >
                    {selected?.map((val, index) => (
                      <Chip
                        key={index}
                        label={
                          <>
                            <img
                              src={val?.imageUrl ? val?.imageUrl : profileImg}
                              className="accounts-publish-avatar-popup"
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = profileImg;
                              }}
                            />
                            <Typography
                              component={"span"}
                              className="account-name-content-title-popup"
                            >
                              {val?.name}
                            </Typography>
                            <Typography
                              component={"span"}
                              className="account-content-close-icon"
                              onClick={() => removeAccount(index)}
                            >
                              <CloseIcon />
                            </Typography>
                          </>
                        }
                        onMouseDown={(event) => {
                          event.stopPropagation();
                        }}
                      />
                    ))}
                  </Box>
                );
              }}
            >
              {dataAccounts?.length ? (
                dataAccounts?.map((item) => (
                  <MenuItem
                    key={`account-type-${item?.id}`}
                    className="accounts-publish-inside-item"
                    value={{
                      reference_id: item?.id,
                      data_source: item?.attributes?.data_source,
                      imageUrl: item?.attributes?.image_url,
                      name: item?.attributes?.name,
                    }}
                    disabled={
                      item?.attributes?.status?.toLowerCase() ==
                      "re-authenticate"
                    }
                  >
                    <Box className="publish-accounts-wrapper">
                      <Box className="publish-account-wrapper-item">
                        <Checkbox
                          checked={accountsPublishSelected?.some(
                            (val) =>
                              val.reference_id == item?.id &&
                              val.data_source == item?.attributes?.data_source,
                          )}
                        />
                        <Typography
                          component={"span"}
                          className="accounts-name-content-pop-up"
                        >
                          <img
                            src={
                              item?.attributes?.image_url
                                ? item?.attributes?.image_url
                                : profileImg
                            }
                            alt="profile-image"
                            className="accounts-publish-avatar-inside"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = profileImg;
                            }}
                          />
                          <img
                            src={handleDatasourcesIcon(
                              item?.attributes?.data_source?.toLowerCase(),
                            )}
                            className="accounts-datasource-icon"
                          />
                          <Box>
                            <Typography
                              component={"p"}
                              className="account-publish-name-pop-up"
                            >
                              {item?.attributes?.name}
                            </Typography>
                            <Typography
                              component={"p"}
                              className="account-publish-name-pop-up-datasource"
                            >
                              {CheckValueLocale(
                                item?.attributes?.data_source,
                                "",
                                {},
                                intl,
                              )}
                            </Typography>
                          </Box>
                        </Typography>
                      </Box>
                      {item?.attributes?.status?.toLowerCase() ==
                      "re-authenticate" ? (
                        <Tooltip
                          title={CheckValueLocale(
                            "publish_warning_icon_tooltip",
                            "",
                            {},
                            intl,
                          )}
                          placement="left"
                          arrow
                        >
                          <ErrorOutlineIcon className="publish-warning-icon" />
                        </Tooltip>
                      ) : null}
                    </Box>
                  </MenuItem>
                ))
              ) : (
                <MenuItem
                  key={`account-type-empty`}
                  className="accounts-publish-inside-item empty"
                  disabled={true}
                  value={null}
                >
                  <Box
                    className="accounts-publish-empty"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        CheckValueLocale(
                          !accountData?.length
                            ? "no_accounts"
                            : "no_permission",
                          "",
                          {},
                          intl,
                        ),
                      ),
                    }}
                  />
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

export default AccountsSelect;
