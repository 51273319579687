import { useQuery } from "@tanstack/react-query";
import { CreateMonitorServices } from "services/api/createMonitorServices";

export const useGetKeywordsLimit = () => {
  return useQuery({
    queryKey: ["keywordsLimit"],
    queryFn: CreateMonitorServices.getKeywordsLimit,
    select: (data) => data?.data,
  });
};
