import { useQuery } from "@tanstack/react-query";
import { MainMonitorsPage } from "services/api/mainMonitorsPage";

export const useGetMonitors = (queryData) => {
  return useQuery({
    queryKey: ["monitors", queryData],
    queryFn: () => MainMonitorsPage.getMonitors(queryData),
    select: (data) => data?.data,
  });
};
