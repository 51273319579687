import { Box, Typography, CircularProgress } from "@mui/material";
import moment from "moment";
import {
  commasAfterDigit,
  CheckValueLocale,
  secondsToHms,
  removeTrailingZeros,
  calculateUnixTimeZone,
  formatTimeZone,
} from "utils/helpers/index";
import DOMPurify from "dompurify";
import ExportButton from "./exportButton/exportButton";
import Filter from "shared/general-filter/filter.js";
import FiltersController from "services/controllers/filtersController";
import { useEffect, useState } from "react";
import BenchmarkController from "services/controllers/benchmarkController";
import Services from "services/api/Services";

const BenchmarkResultHeader = ({
  monitorPublicType,
  benchmarkHeadData,
  monitor_type_name,
  intl,
  setIsExportLoading,
  isExportLoading,
  setErrorExportMsg,
  setSuccessExportMsg,
  setSuccess,
  allMonitorData,
  showPDFBtn,
  reachOverData,
  engagementData,
  customerData,
  sentimentPdfData,
  type,
  monitorType,
  vsTypeData,
  anotherTypeData,
  comparisonType,
  startDate,
  endDate,
  filterBenchmark,
  setFilterBenchmark,
  filterParams,
  setFilterParams,
  filterFlag,
  setFilterFlag,
}) => {
  const pdfData = { monitors: [], dates: [], id: [], data_sources: [] };
  benchmarkHeadData?.monitorData?.map((data, index) => {
    if (index % 5 === 0) pdfData?.monitors?.push([]);
    pdfData?.monitors[pdfData?.monitors?.length - 1]?.push(data?.monitor_name);
    pdfData?.data_sources?.push(data?.data_source_name);
    if (index === 0) pdfData?.id?.push(data?.monitor_id);
  });
  pdfData?.dates?.push(
    moment.unix(benchmarkHeadData?.fromDate).utc().format("YYYY-MM-DD"),
  );
  pdfData?.dates?.push(
    moment.unix(benchmarkHeadData?.toDate).utc().format("YYYY-MM-DD"),
  );

  const uniqueEnagagersData = reachOverData?.uniqueEnagagersData;
  const reachData = reachOverData?.reachData;
  const impressionData = reachOverData?.impressionData;

  const totalPostsData = engagementData?.totalPostsData;
  const avgPostsPerDay = engagementData?.avgPostsPerDay;
  const totalEngagments = engagementData?.totalEngagments;
  const avgEngagmentPerTweetData = engagementData?.avgEngagmentPerTweetData;
  const totalEngagmentFollowertData =
    engagementData?.totalEngagmentFollowertData;

  const totalAskedQuestionsData = customerData?.totalAskedQuestionsData;
  const responseRateData = customerData?.responseRateData;
  const responseTimeQuestionsData = customerData?.responseTimeQuestionsData;

  const positiveSentimentData = sentimentPdfData?.positiveSentimentData;
  const neutralSentimentData = sentimentPdfData?.neutralSentimentData;
  const negativeSentimentData = sentimentPdfData?.negativeSentimentData;

  const totalPostsDataSummation = totalPostsData?.reduce(
    (total, num) => total + num,
    0,
  );
  const shareOfVoiceDataArr = totalPostsData?.map((val) =>
    totalPostsDataSummation != 0
      ? ((val / totalPostsDataSummation) * 100)?.toFixed(2)
      : 0,
  );
  const shareOfVoiceData = shareOfVoiceDataArr;

  const reachTableRows = [
    {
      name: type === "sm" ? "pdf_reach" : "pdf_total_interactions",
      value: reachData,
    },
    {
      name:
        type === "sm" ? "pdf_impression" : "pdf_average_change_in_followers",
      value: impressionData,
    },
  ];

  if (type === "sm") {
    reachTableRows?.unshift({
      name: "pdf_authors",
      value: uniqueEnagagersData,
    });
  }

  const engagementTableRows = [
    { name: "pdf_total_posts", value: totalPostsData },
    { name: "pdf_avg_posts", value: avgPostsPerDay },
    { name: "pdf_total_engagement", value: totalEngagments },
    {
      name: "pdf_average_engagement_per_tweet",
      value: avgEngagmentPerTweetData,
    },
    {
      name: "pdf_total_engagement_per_follower",
      value: totalEngagmentFollowertData,
    },
  ];

  let pageData = [
    { name: "pdf_engagement_overview", value: engagementTableRows, style: "" },
  ];

  const customerTableRows = [
    { name: "pdf_total_asked_questions", value: totalAskedQuestionsData },
    { name: "pdf_response_rate", value: responseRateData },
    { name: "pdf_resp_time", value: responseTimeQuestionsData },
  ];

  const sentimentTableRows = [
    {
      name: "Positive",
      value: positiveSentimentData,
      classStyle: "text-green",
    },
    { name: "neutral", value: neutralSentimentData, classStyle: "text-gold" },
    { name: "Negative", value: negativeSentimentData, classStyle: "text-red" },
  ];

  let displayFromIndex = 0,
    displayToIndex = 4;

  if (
    !(
      monitorPublicType === "Positive_vs_Negative" &&
      benchmarkHeadData.monitor_type_name === "KEYWORD"
    )
  ) {
    pageData = [
      ...pageData,
      {
        name: "pdf_customer_overview",
        value: customerTableRows,
        style: "reach-table",
      },
    ];
  }
  if (
    !(
      monitorPublicType === "Positive_vs_Negative" &&
      (benchmarkHeadData.monitor_type_name === "KEYWORD" ||
        benchmarkHeadData.monitor_type_name === "ACCOUNT_ANALYSIS") &&
      benchmarkHeadData?.monitorData[0]?.data_source_name === "FACEBOOK"
    )
  ) {
    pageData = [
      {
        name: "pdf_reach_overview",
        value: reachTableRows,
        style: "reach-table",
      },
      ...pageData,
    ];
  }
  if (monitorPublicType !== "Positive_vs_Negative") {
    pageData = [
      ...pageData,
      { name: "pdf_sentiment_overview", value: sentimentTableRows, style: "" },
    ];
  }

  if (monitorPublicType === "Monitor_vs_Monitors") {
    reachTableRows?.unshift({
      name: "pdf_data_sources",
      value: pdfData?.data_sources,
    });
    engagementTableRows?.unshift({
      name: "pdf_share_of_voice",
      value: shareOfVoiceData,
    });
  } else if (monitorPublicType === "Positive_vs_Negative") {
    reachTableRows?.unshift({
      name: "pdf_metrics",
      value: ["negative", "positive"],
    });
    engagementTableRows?.unshift({
      name: "pdf_metrics",
      value: ["positive", "negative"],
    });
    customerTableRows?.unshift({
      name: "pdf_metrics",
      value: ["negative", "positive"],
    });
  } else if (monitorPublicType === "Time_Period_vs_Time_Period") {
    let diff =
      86400000 *
      (moment
        .unix(benchmarkHeadData?.toDate)
        .utcOffset(0)
        .diff(moment.unix(benchmarkHeadData?.fromDate).utcOffset(0), `days`) +
        1);
    let periodsArr = [
      `${moment
        .unix((benchmarkHeadData?.fromDate * 1000 - diff) / 1000)
        .utcOffset(0)
        .format("D MMM YYYY")} - ${moment
        .unix((benchmarkHeadData?.fromDate * 1000 - 86400000) / 1000)
        .utcOffset(0)
        .format("D MMM YYYY")}`,
      `${moment
        .unix(benchmarkHeadData?.fromDate)
        .utcOffset(0)
        .format("D MMM YYYY")} - ${moment
        .unix(benchmarkHeadData?.toDate)
        .utcOffset(0)
        .format("D MMM YYYY")}`,
    ];
    reachTableRows?.unshift({ name: "pdf_metrics", value: periodsArr });
  }

  const sentimentPer = (val, x) => {
    const percent =
      val > 0
        ? (
            (val /
              (positiveSentimentData[x] +
                negativeSentimentData[x] +
                neutralSentimentData[x])) *
            100
          )?.toFixed(2)
        : 0;
    return removeTrailingZeros(percent);
  };

  const showTimeFormate = (time) => {
    let formattedTime,
      timeData = [];
    if (time?.toString()?.includes(":")) {
      timeData = time ? time?.split(":") : [];
    } else {
      formattedTime = secondsToHms(time);
      timeData = formattedTime;
    }
    let timetext = "";
    if (timeData[0] && timeData[0] > 0) {
      timetext +=
        `${Number(timeData[0])} ${CheckValueLocale("hrs", "", {}, intl)}` + " ";
    }
    if (timeData[1] && timeData[1] > 0) {
      timetext +=
        `${Number(timeData[1])} ${CheckValueLocale("min", "", {}, intl)}` + " ";
    }
    if (timeData[2] && timeData[2] > 0) {
      timetext += `${Number(timeData[2])} ${CheckValueLocale(
        "sec",
        "",
        {},
        intl,
      )}`;
    }
    return timetext;
  };
  const [filterFields, setFilterFields] = useState([]);

  const handleMonitorList = () => {
    const monitorList = {};
    benchmarkHeadData?.monitorData?.forEach((monitor) => {
      const dataSourceName = monitor?.data_source_name;
      if (!monitorList[dataSourceName]) {
        monitorList[dataSourceName] = [];
      }
      monitorList[dataSourceName].push(monitor?.monitor_id);
    });
    return monitorList;
  };

  const getFiltersFileds = () => {
    let arr = [];
    arr?.push(monitorType);
    FiltersController.getBenchmarkFiltersFileds(
      handleMonitorList(),
      type == "sm" ? window.localStorage.sm_id : window.localStorage.cxm_id,
      arr,
    ).then((data) => {
      if (data?.data?.length) setFilterFields(data?.data);
    });
  };
  const handleClearFilterParams = () => {
    setFilterParams({});
  };
  const applyFilter = () => {};
  useEffect(() => {
    if (filterFlag === true) getFiltersFileds();
    setFilterFlag(false);
  }, [filterFlag]);

  const calculatedFromTime = calculateUnixTimeZone(
    benchmarkHeadData?.fromDate,
    { utc: true },
  );
  const calculatedToTime = calculateUnixTimeZone(benchmarkHeadData?.toDate, {
    utc: true,
  });
  const FormattedFromTime = formatTimeZone(calculatedFromTime, intl, {}).date;
  const FormattedToTime = formatTimeZone(calculatedToTime, intl, {}).date;
  return (
    <>
      <Box className="benchmark-head">
        <Box className="benchmark-head-text">
          {monitorPublicType === "Positive_vs_Negative" &&
            CheckValueLocale("benchmarking_positive_negative", "", {}, intl)}
          {monitorPublicType === "Time_Period_vs_Time_Period" &&
            CheckValueLocale("benchmarking_last_period", "", {}, intl)}
          {monitorPublicType === "Monitor_vs_Monitors" &&
            CheckValueLocale("benchmarking_monitors", "", {}, intl)}
          {benchmarkHeadData?.monitorData?.length > 0 && (
            <Box
              component="span"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  " <b>" +
                    benchmarkHeadData?.monitorData
                      ?.map((val) => val?.monitor_name)
                      ?.join("</b> vs <b>") +
                    "</b>",
                ),
              }}
            ></Box>
          )}

          {CheckValueLocale("from", "", {}, intl)}
          <Box component="b">{FormattedFromTime}</Box>
          {`-`}
          <Box component="b">{FormattedToTime}</Box>
        </Box>
        <Box className="benchmark-filter-box">
          {filterFields?.length > 0 ? (
            <Filter
              handleClearFilterParams={handleClearFilterParams}
              isBenchmarkPage
              filterParams={filterParams}
              filterFields={filterFields}
              setFilterParams={setFilterParams}
              monitorActiveTabFilterID={"benchmark-list-filter-btn"}
              assignActiveFilters={filterBenchmark}
              setAssignActiveFilters={setFilterBenchmark}
              applyFilter={applyFilter}
              startDate={startDate}
              endDate={endDate}
            />
          ) : null}
          <ExportButton
            benchmarkHeadData={benchmarkHeadData}
            pdfData={pdfData}
            showPDFBtn={showPDFBtn}
            pageData={pageData}
            monitorPublicType={monitorPublicType}
            typeTab={type}
          />
        </Box>
      </Box>
      {isExportLoading ? (
        <Box className="loading-export-pdf">
          <CircularProgress />
          <Typography variant="span" className="loading-msg-export-pdf">
            {intl.formatMessage({ id: "loading_export_please_wait" })}
          </Typography>
        </Box>
      ) : null}
      <div id="pdf-content-parent">
        <div className="main-pdf-div" id="pdf-content">
          <table className="main-pdf-div-table">
            <thead>
              <tr className="pdf-footer-text pdf-logo">
                <td>
                  <img src={Services.projectLogoSVG} alt="" srcSet="" />
                </td>
                {Services.showPoweredBy ? (
                  <td>{CheckValueLocale("pdf_footer_text", "", {}, intl)}</td>
                ) : null}
              </tr>
              <tr>
                <td className="pdf-footer"></td>
              </tr>
              <tr>
                <th>
                  <h3 className="pdf-title display-in-row">
                    {intl.locale === "en"
                      ? CheckValueLocale("benchmark", "", {}, intl)
                      : CheckValueLocale("pdf_title", "", {}, intl)}
                    {intl.locale === "en" ? (
                      <span className="pdf-margin">Report</span>
                    ) : (
                      ""
                    )}
                  </h3>
                  <p className="detail">
                    {CheckValueLocale("pdf_subtitle", "", {}, intl)}
                  </p>
                  <p className="pdf-subtitle">
                    {CheckValueLocale("pdf_selected_monitors", "", {}, intl)}
                  </p>
                  <p className="detail">
                    {pdfData?.monitors?.map((monitors, index) => {
                      return (
                        <>
                          {monitors?.map((monitor, i) => {
                            return <p className="vs-style">{`${monitor}`}</p>;
                          })}
                        </>
                      );
                    })}
                  </p>
                  <p className="pdf-subtitle">
                    {CheckValueLocale("date_quick", "", {}, intl)} :
                  </p>
                  <p className="display-in-row pdf-date">
                    <span>{CheckValueLocale("from", "", {}, intl)}</span>
                    <span className="pdf-date-range pdf-date-margin">
                      {pdfData?.dates[0]}
                    </span>
                    <span className="pdf-date-margin">
                      {CheckValueLocale("pdf_to", "", {}, intl)}
                    </span>
                    <span className="pdf-date-range pdf-date-margin">
                      {pdfData?.dates[1]}
                    </span>
                  </p>
                </th>
              </tr>
            </thead>
          </table>
        </div>
        {pdfData?.monitors?.map((monitors, index) => {
          if (index !== 0) {
            displayFromIndex = displayFromIndex + 5;
            displayToIndex = displayToIndex + 5;
          }
          return (
            <div
              className="pdf-tables-main-div"
              id="pdf-content"
              style={{ width: "100%", height: "100%" }}
            >
              <table className="pdf-tables">
                <tbody className="pdf-table-body">
                  <tr className="pdf-table-title">
                    <td td colspan="6">
                      <img src={Services.projectLogoSVG} alt="" srcSet="" />
                    </td>
                  </tr>
                  <tr className="pdf-selected-monitors">
                    <td></td>
                    {monitors?.map((monitor) => {
                      return <td>{monitor}</td>;
                    })}
                  </tr>
                  {pageData?.map((data) => (
                    <>
                      <tr className="pdf-table-title">
                        <td colspan="6">
                          {CheckValueLocale(data?.name, "", {}, intl)}
                        </td>
                      </tr>
                      {data?.value?.map((tableData, place) => {
                        return (
                          <tr
                            className={`pdf-table-rows ${
                              place === data?.value?.length - 1
                                ? "pdf-table-last-row"
                                : ""
                            }`}
                          >
                            <td>
                              {CheckValueLocale(tableData?.name, "", {}, intl)}
                            </td>
                            {tableData?.value?.map((val, i) => {
                              if (
                                i >= displayFromIndex &&
                                i <= displayToIndex
                              ) {
                                return (
                                  <td
                                    className={`
                                    ${
                                      CheckValueLocale(
                                        data?.name,
                                        "",
                                        {},
                                        intl,
                                      ) === "SENTIMENT OVERVIEW" && val > 0
                                        ? tableData?.classStyle
                                        : ""
                                    } ${tableData?.name === "pdf_metrics" && monitorPublicType === "Time_Period_vs_Time_Period" && "metrics-date"}`}
                                  >
                                    {val != null &&
                                    !isNaN(val) &&
                                    val !== undefined ? (
                                      <>
                                        {tableData?.name ===
                                        "pdf_share_of_voice"
                                          ? `${Number(val)?.toFixed(2)}%`
                                          : tableData?.name ===
                                              "pdf_average_engagement_per_tweet"
                                            ? val != null
                                              ? intl.formatNumber(val)
                                              : "—"
                                            : data?.name ===
                                                "pdf_reach_overview"
                                              ? val % 1 === 0
                                                ? commasAfterDigit(val)
                                                : commasAfterDigit(
                                                    val?.toFixed(2),
                                                  )
                                              : data?.name ===
                                                  "pdf_sentiment_overview"
                                                ? `${sentimentPer(val, i)}% ${
                                                    monitorPublicType ===
                                                    "Time_Period_vs_Time_Period"
                                                      ? `(${val} ${CheckValueLocale("pdf_posts", "", {}, intl)})`
                                                      : ""
                                                  }`
                                                : tableData?.name ===
                                                    "pdf_resp_time"
                                                  ? showTimeFormate(val) || 0
                                                  : tableData?.name ===
                                                      "pdf_response_rate"
                                                    ? `${(val * 100) % 1 === 0 ? val * 100 : (val * 100)?.toFixed(2) || 0}%`
                                                    : tableData?.name ===
                                                        "pdf_total_engagement_per_follower"
                                                      ? val % 1 === 0
                                                        ? commasAfterDigit(val)
                                                        : commasAfterDigit(
                                                            val?.toFixed(3),
                                                          )
                                                      : val % 1 === 0
                                                        ? commasAfterDigit(val)
                                                        : commasAfterDigit(
                                                            val?.toFixed(2),
                                                          )}
                                      </>
                                    ) : tableData?.name === "pdf_metrics" ||
                                      tableData?.name === "pdf_data_sources" ? (
                                      CheckValueLocale(val, "", {}, intl)
                                    ) : (
                                      "—"
                                    )}
                                  </td>
                                );
                              }
                            })}
                          </tr>
                        );
                      })}
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default BenchmarkResultHeader;
