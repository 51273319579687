import * as Yup from "yup";

export const addAccountListUsernameValidationSchema = (usernames) => {
  const checkLengthForUsername = (value) => {
    if (!value) return true;
    return value.split(",").every((segment) => segment.trim().length <= 50);
  };

  const getNumberOfOccurrences = (array, element) => {
    return array?.filter((arrayElement) => arrayElement === element)?.length;
  };

  const validateUsernamesListLength = (value) => {
    if (!value) return true;

    const values = value?.split(",");
    return values?.length + usernames?.length <= 200;
  };

  const validateDuplication = (value) => {
    if (value == null || value === "") {
      return true;
    }

    const values = value.split(",");

    for (const item of values) {
      if (getNumberOfOccurrences(values, item) > 1) {
        return false;
      } else if (usernames.includes(item)) {
        return false;
      }
    }

    return true;
  };

  return Yup.object().shape({
    username: Yup.string()
      ?.matches(
        /^[a-zA-Z0-9_,]*$/,
        "account_lists_username_characters_validation",
      )
      ?.test(
        "check-segment-length",
        "exceeds_accounts_list_username_length",
        checkLengthForUsername,
      )
      ?.test(
        "test-for-duplication",
        "userNames_collect_ignore_error_exists",
        validateDuplication,
      )
      ?.test(
        "check-list-length",
        "account_list_limit_reached",
        validateUsernamesListLength,
      ),
  });
};
