import { faCopy, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Chip, IconButton, Tooltip } from "@mui/material";
import { ViewAllCustomSourcesModal } from "./viewSourcesModal";
import { useState } from "react";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";

export const SelectedSourcesList = ({ sources, onDelete, handleDeleteAll }) => {
  const intl = useIntl();
  const [isViewAllSourcesModalOpen, setIsViewAllSourcesModalOpen] =
    useState(false);
  const sourcesToShow = sources?.slice(0, 6);
  const isOtherOptionsShown = sources?.length > 6;

  const handleViewAllSourcesModalOpen = () =>
    setIsViewAllSourcesModalOpen(true);

  const handleCopyAll = () => {
    const sourcesToCopy = sources?.map((source) => source?.link || source);
    navigator.clipboard.writeText(sourcesToCopy?.join(", "));
  };

  return (
    <Box component="section">
      <Box component="section" className="news-sources-input-chips-container">
        {sourcesToShow?.map((source, index) => {
          const sourceLink = (source?.link && typeof source?.link === 'string') ? source?.link : source
          return (
            <Chip
              key={source?.id || source}
              label={sourceLink?.replace("https://" , "")?.replace("http://" , "")}
              onDelete={() => onDelete(source?.id, index)}
              variant="outlined"
              color="primary"
              className="news-sources-input-chip"
            />
          );
        })}
      </Box>
      {isOtherOptionsShown ? (
        <Box
          component="section"
          className="news-sources-input-actions-container"
        >
          <Chip
            size="small"
            className="show-all-sources-chip"
            label={CheckValueLocale("custom_sources_show_all", "", {}, intl)}
            onClick={handleViewAllSourcesModalOpen}
          />
          <Tooltip
            arrow
            onClick={handleCopyAll}
            title={CheckValueLocale("custom_sources_copy_all", "", {}, intl)}
          >
            <IconButton>
              <ContentCopyIcon className="news-sources-input-actions-icon" />
            </IconButton>
          </Tooltip>
          <Tooltip
            onClick={handleDeleteAll}
            arrow
            title={CheckValueLocale("custom_sources_delete_all", "", {}, intl)}
          >
            <IconButton>
              <DeleteIcon className="news-sources-input-actions-icon" />
            </IconButton>
          </Tooltip>
        </Box>
      ) : null}
      <ViewAllCustomSourcesModal
        open={isViewAllSourcesModalOpen}
        setOpen={setIsViewAllSourcesModalOpen}
        sources={sources}
        onDelete={onDelete}
      />
    </Box>
  );
};
