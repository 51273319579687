import {
  Box,
  Typography,
  Tooltip,
  Button,
  FormHelperText,
} from "@mui/material";
import "./mainSetup.scss";
import { useState, useEffect } from "react";
import {
  CheckValueLocale,
  newProductNameMap,
} from "../../../../../utils/helpers/index.js";
import { useIntl, FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";
import inboxIcon from "images/engagements-images/engagInbox.svg";
import publishIcon from "images/engagements-images/publish.svg";
import facebook from "images/engagements-images/facebook.svg";
import Instagram from "images/engagements-images/Instagram.svg";
import twitter from "images/engagements-images/twitter.svg";
import facebookCxm from "images/engagements-images/fb-dm-eng.svg";
import InstagramCxm from "images/engagements-images/ig-dm-eng.svg";
import twitterCxm from "images/engagements-images/twitterCxm.svg";
import WhatsAppCXM from "images/social-icons/whatsapp-icon.svg";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ErrorIcon from "@mui/icons-material/Error";
import { Link } from "react-router-dom";
import CompaniesController from "services/controllers/companiesController";
import Snackbar from "components/snackBar";
import DOMPurify from "dompurify";

const socialIcons = (type) => {
  let icons = {
    FACEBOOK: <img src={facebook} />,
    INSTAGRAM: <img src={Instagram} />,
    TWITTER: <img src={twitter} />,
    FACEBOOK_cxm: <img src={facebookCxm} />,
    INSTAGRAM_cxm: <img src={InstagramCxm} />,
    TWITTER_cxm: <img src={twitterCxm} />,
    WHATSAPP_cxm: <img src={WhatsAppCXM} />,
    WHATSAPP: <img src={WhatsAppCXM} />,
  };
  return icons[type];
};

const StepOne = ({ selectedData, setSelectedData }) => {
  const intl = useIntl();
  const [expanded, setExpanded] = useState(false);
  const [slDemoDiasable, setSlDemoDiasable] = useState(false);
  const [cxmDemoDiasable, setCxmDemoDiasable] = useState(false);
  const [isCxmAcc, setIsCxmAcc] = useState(false);
  const [snakState, setSnakState] = useState({ open: false, severity: "" });
  const [value, setValue] = useState("1");

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeCollapse = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    setExpanded("SL");
    handleChangeCollapse("SL");
  }, []);

  useEffect(() => {
    window.scrollTo(0, 200);
  }, [expanded]);

  useEffect(() => {
    if (selectedData?.stepOne?.cxm?.length) {
      let ispublic = false;
      selectedData?.stepOne?.cxm?.map((item1) => {
        selectedData?.mainData?.monitorsAndChannels?.channels?.data?.map(
          (item2) => {
            if (item2?.attributes?.id == item1) {
              if (!item2?.attributes?.is_dm) {
                ispublic = true;
              }
            }
          },
        );
      });
      if (ispublic) {
        setSelectedData({
          ...selectedData,
          stepOne: {
            ...selectedData?.stepOne,
            isCxmAcc: true,
          },
        });
      } else {
        setSelectedData({
          ...selectedData,
          stepOne: {
            ...selectedData?.stepOne,
            isCxmAcc: false,
            TWITTER_CxmAcc: [],
            INSTAGRAM_CxmAcc: [],
            FACEBOOK_CxmAcc: [],
          },
        });
      }
    } else {
      setSelectedData({
        ...selectedData,
        stepOne: {
          ...selectedData?.stepOne,
          isCxmAcc: false,
          TWITTER_CxmAcc: [],
          INSTAGRAM_CxmAcc: [],
          FACEBOOK_CxmAcc: [],
        },
      });
    }
  }, [selectedData?.stepOne?.cxm]);

  const handleSelect = (id, type) => {
    if (
      selectedData?.stepOne[type]?.length &&
      selectedData?.stepOne[type]?.includes(id)
    ) {
      setSelectedData({
        ...selectedData,
        stepOne: {
          ...selectedData?.stepOne,
          [type]: selectedData?.stepOne[type]?.filter((item) => item !== id),
        },
      });
    } else {
      setSelectedData({
        ...selectedData,
        stepOne: {
          ...selectedData?.stepOne,
          [type]: [...selectedData?.stepOne[type], id],
        },
      });
    }
  };
  const skipStatus = (status) => {
    return (
      status == "paused" || status == "collecting" || status == "under approval"
    );
  };
  const getMsgStatusFormating = (status) => {
    const statuses = {
      "Fetching GNIP": CheckValueLocale("fetching_gnip", "", {}, intl),
      "Updating Tracker": CheckValueLocale("updating_tracker", "", {}, intl),
      "Authentication error!": CheckValueLocale(
        "authentication_error",
        "",
        {},
        intl,
      ),
      paused: CheckValueLocale("paused", "", {}, intl),
      "non-social": CheckValueLocale(
        "non_social_medias_data_limit_reached",
        "",
        {},
        intl,
      ),
      social: CheckValueLocale(
        "social_medias_data_limit_reached",
        "",
        {},
        intl,
      ),
      "Invalid twitter handler name!": CheckValueLocale(
        "Invalid_twitter_handler_name",
        "",
        {},
        intl,
      ),
      monitor_list_social_twitter_account_locked: CheckValueLocale(
        "monitor_list_social_twitter_account_locked",
        "",
        {},
        intl,
      ),
    };
    return statuses[status];
  };
  const monitorErorrs = (monitor) => {
    let errors = [];
    let accErrors = [];
    if (monitor?.limit_error && monitor?.limit_error?.length) {
      monitor?.limit_error?.map((limit_error) => {
        errors.push(limit_error);
      });
    }
    if (
      !skipStatus(monitor?.status) &&
      getMsgStatusFormating(monitor?.status)
    ) {
      errors.push(monitor?.status);
    }
    if (monitor?.account_error && monitor?.account_error?.length) {
      monitor?.account_error?.map((err) => {
        accErrors.push(err);
      });
    }
    if (monitor?.account_valid_error && monitor?.account_valid_error?.length) {
      monitor?.account_valid_error?.map((err) => {
        accErrors.push(err);
      });
    }
    return [[...errors], [...accErrors]];
  };

  let card = (item, showDetails, type) => {
    let errorList = monitorErorrs(item);
    return (
      <Box
        key={item?.id}
        className={
          selectedData?.stepOne[type]?.length &&
          selectedData?.stepOne[type]?.includes(item?.id)
            ? !showDetails
              ? "main-card selected small"
              : "main-card selected"
            : !showDetails
              ? "main-card small"
              : "main-card"
        }
        onClick={() => handleSelect(item?.id, type)}
      >
        <Box>
          <Box className="main-card-name-icon">
            <Box className="main-card-name">
              {item?.name?.length < 18 ? (
                item?.name
              ) : (
                <Tooltip title={item?.name} arrow placement="top">
                  {`${item?.name?.substring(0, 18)}...`}
                </Tooltip>
              )}
            </Box>
            <Box
              className={
                type == "cxm" &&
                item?.is_dm &&
                item?.data_sources[0] == "TWITTER"
                  ? "main-card-icons twitter-cxm"
                  : "main-card-icons"
              }
            >
              {item?.data_sources?.map((source) =>
                socialIcons(
                  type == "cxm"
                    ? item?.is_dm
                      ? `${source}_cxm`
                      : source
                    : source,
                ),
              )}
            </Box>
          </Box>
          {showDetails ? (
            <Box className="main-card-details">
              <Box className={item?.paused ? "paused" : "collecting"}>
                {CheckValueLocale(
                  item?.paused ? "paused" : "collecting",
                  "",
                  {},
                  intl,
                )}
              </Box>
              <Box className="dotted"></Box>
              <Box className="item-type">
                {CheckValueLocale(
                  type == "cxm"
                    ? item?.is_dm
                      ? "direct_message"
                      : "pub_ch"
                    : item?.monitor_type_name == "MANAGED_PAGE"
                      ? "MANAGED_ACCOUNT"
                      : item?.monitor_type_name,
                  "",
                  {},
                  intl,
                )}
              </Box>
            </Box>
          ) : null}
        </Box>
        {errorList[0]?.length || errorList[1]?.length ? (
          <Box>
            <Tooltip
              title={
                <>
                  {errorList[0]?.map((error, i) => {
                    return (
                      <Box key={"0" + i} className="worning-text">
                        {getMsgStatusFormating(error)}
                      </Box>
                    );
                  })}
                  {errorList[1]?.map((error, i) => {
                    return (
                      <Box key={"1" + i} className="worning-text">
                        <FormattedMessage
                          id="auth_err"
                          values={{
                            datasource: CheckValueLocale(error, "", {}, intl),
                          }}
                        />
                      </Box>
                    );
                  })}
                </>
              }
              arrow
              placement="top"
            >
              <Box className="warning-icon-countainer">
                <ErrorIcon
                  className="dashboard-warning-icon"
                  fontSize="small"
                />
              </Box>
            </Tooltip>
          </Box>
        ) : null}
      </Box>
    );
  };

  const requestDemo = (type) => {
    let queryData = {
      service_name: type,
    };
    if (type == "request_social_listening_demo") {
      setSlDemoDiasable(true);
    } else {
      setCxmDemoDiasable(true);
    }
    CompaniesController.requestService(queryData).then((result) => {
      if (!result.errorMsg) {
        setSnakState({ open: true, severity: "success" });
      } else {
        setSnakState({ open: true, severity: "error" });
      }
      setSlDemoDiasable(false);
      setCxmDemoDiasable(false);
    });
    setTimeout(() => {
      setSnakState({ open: false, severity: "" });
    }, 7000);
  };

  const snakBody = (
    <Box className="main-snackbar">
      <Box className="snackbar-title">
        {CheckValueLocale(
          snakState?.severity == "success"
            ? "request_access_success_heading"
            : "something_wrong",
          "",
          {},
          intl,
        )}
      </Box>
      <Box className="snackbar-body">
        {CheckValueLocale(
          snakState?.severity == "success" ? "demo_success" : "wrong_msg",
          "",
          {},
          intl,
        )}
      </Box>
    </Box>
  );

  return (
    <Box className="main-step-one">
      <Box className="step-one-header">
        <Box className="step-one-title">
          {CheckValueLocale("step_one_header", "", {}, intl)}
        </Box>
        <Box className="step-one-pragragh">
          {CheckValueLocale("step_one_pragragh", "", {}, intl)}
        </Box>
      </Box>
      <Box className="step-one-feture">
        <Box>
          <img src={inboxIcon} />
        </Box>
        <Box className="feture-header">
          <Box className="feture-title">
            {CheckValueLocale("inbox", "", {}, intl)}
          </Box>
          <Box className="feture-pragragh">
            {CheckValueLocale("inbox_pragragh", "", {}, intl)}
          </Box>
        </Box>
      </Box>
      {selectedData?.stepOne?.isEmptyError ||
      selectedData?.stepOne?.isAccError ? (
        <FormHelperText error className="step1-error-msg inbox-error">
          {CheckValueLocale(
            selectedData?.stepOne?.isEmptyError
              ? "inbox_empty"
              : "ibox_acc_error",
            "",
            {},
            intl,
          )}
        </FormHelperText>
      ) : null}
      <Box className="step-one-products">
        {selectedData?.mainData?.monitorsAndChannels?.company_products?.SM ? (
          <Accordion
            expanded={expanded === "SL"}
            className={
              expanded === "SL" ? "main-product opend" : "main-product"
            }
            onChange={handleChangeCollapse("SL")}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className="product-title">
                {CheckValueLocale(newProductNameMap["SM"], "", {}, intl)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box className="main-product-countainer">
                <Box className="main-product-sec first-sec">
                  <Typography className="prod-sec-header">
                    {CheckValueLocale("sl_header", "", {}, intl)}
                  </Typography>
                  <Box className="prod-sec-tabs">
                    {!selectedData?.mainData?.monitorsAndChannels?.monitors
                      ?.data?.MANAGED_ACCOUNT?.length &&
                    !selectedData?.mainData?.monitorsAndChannels?.monitors?.data
                      ?.ACCOUNT_ANALYSIS?.length &&
                    !selectedData?.mainData?.monitorsAndChannels?.monitors?.data
                      ?.KEYWORD?.length ? (
                      <Box className="cards-container empty">
                        <Box className="cards-empty">
                          {CheckValueLocale("all_monitors_empty", "", {}, intl)}
                        </Box>
                        <Box className="cards-create-monitor">
                          <Link
                            target="_blank"
                            to="/social/monitors/create_monitor"
                            className=""
                          >
                            <Box className="link-text">
                              <Box className="link-plus">+</Box>
                              <Box className="link-create-monitor">
                                {CheckValueLocale(
                                  "create_monitor",
                                  "",
                                  {},
                                  intl,
                                )}
                              </Box>
                            </Box>
                            <Box>
                              <FontAwesomeIcon icon={faExternalLink} />
                            </Box>
                          </Link>
                        </Box>
                      </Box>
                    ) : (
                      <TabContext value={value}>
                        <Box>
                          <TabList
                            className="tabs-btns"
                            onChange={handleChangeTabs}
                            aria-label="lab API tabs example"
                          >
                            <Tab
                              label={CheckValueLocale("manged", "", {}, intl)}
                              value="1"
                            />
                            <Tab
                              label={CheckValueLocale("public", "", {}, intl)}
                              value="2"
                            />
                            <Tab
                              label={CheckValueLocale("KEYWORD", "", {}, intl)}
                              value="3"
                            />
                          </TabList>
                        </Box>
                        <TabPanel value="1" sx={{ padding: "0" }}>
                          <Box
                            className={
                              selectedData?.mainData?.monitorsAndChannels
                                ?.monitors?.data?.MANAGED_ACCOUNT &&
                              selectedData?.mainData?.monitorsAndChannels
                                ?.monitors?.data?.MANAGED_ACCOUNT?.length
                                ? "cards-container"
                                : "cards-container empty"
                            }
                          >
                            {selectedData?.mainData?.monitorsAndChannels
                              ?.monitors?.data?.MANAGED_ACCOUNT &&
                            selectedData?.mainData?.monitorsAndChannels
                              ?.monitors?.data?.MANAGED_ACCOUNT?.length ? (
                              selectedData?.mainData?.monitorsAndChannels?.monitors?.data?.MANAGED_ACCOUNT?.map(
                                (item) =>
                                  card(item?.attributes, true, "monitors"),
                              )
                            ) : (
                              <Box className="cards-empty">
                                {CheckValueLocale(
                                  "monitors_empty",
                                  "",
                                  {},
                                  intl,
                                )}
                              </Box>
                            )}
                            <Box className="cards-create-monitor">
                              <Link
                                target="_blank"
                                to="/social/monitors/create_monitor"
                                className=""
                              >
                                <Box className="link-text">
                                  <Box className="link-plus">+</Box>
                                  <Box className="link-create-monitor">
                                    {CheckValueLocale(
                                      "create_monitor",
                                      "",
                                      {},
                                      intl,
                                    )}
                                  </Box>
                                </Box>
                                <Box>
                                  <FontAwesomeIcon icon={faExternalLink} />
                                </Box>
                              </Link>
                            </Box>
                          </Box>
                        </TabPanel>
                        <TabPanel value="2" sx={{ padding: "0" }}>
                          <Box
                            className={
                              selectedData?.mainData?.monitorsAndChannels
                                ?.monitors?.data?.ACCOUNT_ANALYSIS &&
                              selectedData?.mainData?.monitorsAndChannels
                                ?.monitors?.data?.ACCOUNT_ANALYSIS?.length
                                ? "cards-container"
                                : "cards-container empty"
                            }
                          >
                            {selectedData?.mainData?.monitorsAndChannels
                              ?.monitors?.data?.ACCOUNT_ANALYSIS &&
                            selectedData?.mainData?.monitorsAndChannels
                              ?.monitors?.data?.ACCOUNT_ANALYSIS?.length ? (
                              selectedData?.mainData?.monitorsAndChannels?.monitors?.data?.ACCOUNT_ANALYSIS?.map(
                                (item) =>
                                  card(item?.attributes, true, "monitors"),
                              )
                            ) : (
                              <Box className="cards-empty">
                                {CheckValueLocale(
                                  "monitors_empty",
                                  "",
                                  {},
                                  intl,
                                )}
                              </Box>
                            )}
                            <Box className="cards-create-monitor">
                              <Link
                                target="_blank"
                                to="/social/monitors/create_monitor"
                                className=""
                              >
                                <Box className="link-text">
                                  <Box className="link-plus">+</Box>
                                  <Box className="link-create-monitor">
                                    {CheckValueLocale(
                                      "create_monitor",
                                      "",
                                      {},
                                      intl,
                                    )}
                                  </Box>
                                </Box>
                                <Box>
                                  <FontAwesomeIcon icon={faExternalLink} />
                                </Box>
                              </Link>
                            </Box>
                          </Box>
                        </TabPanel>
                        <TabPanel value="3" sx={{ padding: "0" }}>
                          <Box
                            className={
                              selectedData?.mainData?.monitorsAndChannels
                                ?.monitors?.data?.KEYWORD &&
                              selectedData?.mainData?.monitorsAndChannels
                                ?.monitors?.data?.KEYWORD?.length
                                ? "cards-container"
                                : "cards-container empty"
                            }
                          >
                            {selectedData?.mainData?.monitorsAndChannels
                              ?.monitors?.data?.KEYWORD &&
                            selectedData?.mainData?.monitorsAndChannels
                              ?.monitors?.data?.KEYWORD?.length ? (
                              selectedData?.mainData?.monitorsAndChannels?.monitors?.data?.KEYWORD?.map(
                                (item) =>
                                  card(item?.attributes, true, "monitors"),
                              )
                            ) : (
                              <Box className="cards-empty">
                                {CheckValueLocale(
                                  "monitors_empty",
                                  "",
                                  {},
                                  intl,
                                )}
                              </Box>
                            )}
                            <Box className="cards-create-monitor">
                              <Link
                                target="_blank"
                                to="/social/monitors/create_monitor"
                                className=""
                              >
                                <Box className="link-text">
                                  <Box className="link-plus">+</Box>
                                  <Box className="link-create-monitor">
                                    {CheckValueLocale(
                                      "create_monitor",
                                      "",
                                      {},
                                      intl,
                                    )}
                                  </Box>
                                </Box>
                                <Box>
                                  <FontAwesomeIcon icon={faExternalLink} />
                                </Box>
                              </Link>
                            </Box>
                          </Box>
                        </TabPanel>
                      </TabContext>
                    )}
                  </Box>
                </Box>
                <Box className="main-product-sec">
                  <Typography className="prod-sec-header">
                    {CheckValueLocale("acc_header", "", {}, intl)}
                    <Box className="cards-container">
                      {selectedData?.mainData?.accounts?.map((item) =>
                        card(
                          item?.attributes,
                          false,
                          `${item?.attributes?.data_sources[0]}_SlAcc`,
                        ),
                      )}
                    </Box>
                    <Box className="cards-link-auth">
                      <Box>
                        {CheckValueLocale(
                          !selectedData?.mainData?.accounts?.length
                            ? "empty_acc"
                            : "new_acc",
                          "",
                          {},
                          intl,
                        )}
                      </Box>
                      <Link
                        target="_blank"
                        to="/settings"
                        className="link-auth"
                      >
                        <Box className="link-text">
                          {CheckValueLocale("company_auth", "", {}, intl)}
                        </Box>
                        <Box>
                          <FontAwesomeIcon icon={faExternalLink} />
                        </Box>
                      </Link>
                    </Box>
                  </Typography>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        ) : null}
        {selectedData?.mainData?.monitorsAndChannels?.company_products?.CXM ? (
          <Accordion
            expanded={expanded === "CXM"}
            className={
              expanded === "CXM" ? "main-product opend" : "main-product"
            }
            onChange={handleChangeCollapse("CXM")}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className="product-title">
                {CheckValueLocale("CXM", "", {}, intl)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box className="main-product-countainer">
                <Box className="main-product-sec">
                  <Typography className="prod-sec-header">
                    {CheckValueLocale("cxm_header", "", {}, intl)}
                    {selectedData?.mainData?.monitorsAndChannels?.channels?.data
                      ?.length ? (
                      <Box className="cxm-note">
                        {CheckValueLocale("cxm_note", "", {}, intl)}
                        <Link
                          target="_blank"
                          to="/manage-channels/list"
                          className=""
                        >
                          {CheckValueLocale("manage_channels", "", {}, intl)}
                        </Link>
                      </Box>
                    ) : null}
                    <Box
                      className={
                        !selectedData?.mainData?.monitorsAndChannels?.channels
                          ?.data?.length ||
                        !selectedData?.mainData?.monitorsAndChannels?.channels
                          ?.data
                          ? "cards-container empty"
                          : "cards-container"
                      }
                    >
                      {selectedData?.mainData?.monitorsAndChannels?.channels?.data?.map(
                        (item) => card(item?.attributes, true, "cxm"),
                      )}
                      {!selectedData?.mainData?.monitorsAndChannels?.channels
                        ?.data?.length ||
                      !selectedData?.mainData?.monitorsAndChannels?.channels
                        ?.data ? (
                        <Box className="cards-empty">
                          {CheckValueLocale("all_channel_empty", "", {}, intl)}
                        </Box>
                      ) : null}
                      <Box className="cards-create-monitor">
                        <Link
                          target="_blank"
                          to="/manage-channels/add-channel"
                          className=""
                        >
                          <Box className="link-text">
                            <Box className="link-plus">+</Box>
                            <Box className="link-create-monitor">
                              {CheckValueLocale("new_channel", "", {}, intl)}
                            </Box>
                          </Box>
                          <Box>
                            <FontAwesomeIcon icon={faExternalLink} />
                          </Box>
                        </Link>
                      </Box>
                    </Box>
                  </Typography>
                </Box>
                {selectedData?.stepOne?.isCxmAcc ? (
                  <Box className="main-product-sec border-top">
                    <Typography className="prod-sec-header">
                      <Typography
                        className="prod-sec-header"
                        variant="body1"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            CheckValueLocale("acc_header_cxm", "", {}, intl),
                          ),
                        }}
                      ></Typography>
                      <Box className="cards-container">
                        {selectedData?.mainData?.accounts?.map((item) =>
                          card(
                            item?.attributes,
                            false,
                            `${item?.attributes?.data_sources[0]}_CxmAcc`,
                          ),
                        )}
                      </Box>
                      <Box className="cards-link-auth">
                        <Box>
                          {CheckValueLocale(
                            !selectedData?.mainData?.accounts?.length
                              ? "empty_acc"
                              : "new_acc",
                            "",
                            {},
                            intl,
                          )}
                        </Box>
                        <Link
                          target="_blank"
                          to="/settings"
                          className="link-auth"
                        >
                          <Box className="link-text">
                            {CheckValueLocale("company_auth", "", {}, intl)}
                          </Box>
                          <Box>
                            <FontAwesomeIcon icon={faExternalLink} />
                          </Box>
                        </Link>
                      </Box>
                    </Typography>
                  </Box>
                ) : null}
              </Box>
            </AccordionDetails>
          </Accordion>
        ) : null}
        {!selectedData?.mainData?.monitorsAndChannels?.company_products?.SM ? (
          <Box className="req-demo">
            <Typography className="product-title">
              {CheckValueLocale("SM", "", {}, intl)}
            </Typography>
            <Button
              onClick={() => requestDemo("request_social_listening_demo")}
              disabled={slDemoDiasable}
            >
              {CheckValueLocale("request_demo", "", {}, intl)}
            </Button>
          </Box>
        ) : null}
        {!selectedData?.mainData?.monitorsAndChannels?.company_products?.CXM ? (
          <Box className="req-demo">
            <Typography className="product-title">
              {CheckValueLocale("CXM", "", {}, intl)}
            </Typography>
            <Button
              onClick={() => requestDemo("request_channel_analytics_demo")}
              disabled={cxmDemoDiasable}
            >
              {CheckValueLocale("request_demo", "", {}, intl)}
            </Button>
          </Box>
        ) : null}
      </Box>
      <Box className="step-one-feture publish-sec">
        <Box>
          <img src={publishIcon} />
        </Box>
        <Box className="feture-header">
          <Box className="feture-title">
            {CheckValueLocale("publish_header", "", {}, intl)}
          </Box>
          <Box className="feture-pragragh">
            {CheckValueLocale("publish_pragragh", "", {}, intl)}
          </Box>
        </Box>
      </Box>
      {selectedData?.stepOne?.isPublishError ? (
        <FormHelperText error className="step1-error-msg">
          {CheckValueLocale("publish_error", "", {}, intl)}
        </FormHelperText>
      ) : null}
      <Box className="step-one-products">
        <Box className="main-product publ-sec">
          <Box className="main-product-countainer">
            <Box className="main-product-sec">
              <Typography className="prod-sec-header">
                {CheckValueLocale("publish_head", "", {}, intl)}
                <Box className="cards-container">
                  {selectedData?.mainData?.accounts?.map((item) =>
                    card(
                      item?.attributes,
                      false,
                      `${item?.attributes?.data_sources[0]}_PubAcc`,
                    ),
                  )}
                </Box>
                <Box className="cards-link-auth">
                  <Box>
                    {CheckValueLocale(
                      !selectedData?.mainData?.accounts?.length
                        ? "empty_acc"
                        : "new_acc",
                      "",
                      {},
                      intl,
                    )}
                  </Box>
                  <Link target="_blank" to="/settings" className="link-auth">
                    <Box className="link-text">
                      {CheckValueLocale("company_auth", "", {}, intl)}
                    </Box>
                    <Box>
                      <FontAwesomeIcon icon={faExternalLink} />
                    </Box>
                  </Link>
                </Box>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Snackbar
        open={snakState?.open}
        severity={snakState?.severity}
        message={snakBody}
      />
    </Box>
  );
};

export default StepOne;
