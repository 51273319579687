import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import { forwardRef, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale, classNames, isArabic } from "utils/helpers";
import "./dropdownFilter.scss";
import LucButton from "shared/lucButton/lucButton";
import TruncatedTooltip from "shared/truncatedTooltip/truncatedTooltip";

// Default render tags function
const defaultRenderTags = (
  value,
  _getTagProps,
  isOpen,
  intl,
  totalLength,
  selectedOpitonsNum,
) => {
  const selectedNumber =
    selectedOpitonsNum >= 0 ? selectedOpitonsNum : value?.length;
  if (!isOpen && selectedNumber > 0) {
    return (
      <Typography className="engagement-analytics-select-controll-tags">
        {selectedNumber === totalLength
          ? CheckValueLocale("all", "", {}, intl)
          : `${selectedNumber} ${CheckValueLocale("selected_num", "", {}, intl)}`}
      </Typography>
    );
  }
  return null;
};

const ListboxComponent = forwardRef(({ children, ...props }, ref) => {
  const intl = useIntl();
  const handlePaperClick = (e) => {
    e?.stopPropagation(); // Stop event propagation
  };

  return (
    <Box
      className={classNames(
        "engagement-analytics-dropdown-controll-paper",
        props?.hasOptions ? "with-options" : "",
      )}
      onClick={handlePaperClick}
      ref={ref}
    >
      <ul {...props}>{children}</ul>
      {props?.hasOptions ? (
        <Box
          className="engagement-analytics-option-apply"
          onMouseDown={props?.onMouseDown}
        >
          <LucButton
            size="small"
            onClick={(e) => {
              e.stopPropagation(); // Prevents the event from bubbling up
              props?.handleApplyClick?.();
            }}
            id="dropdown-apply-button"
          >
            {CheckValueLocale("avg_apply", "", {}, intl)}
          </LucButton>
        </Box>
      ) : null}
    </Box>
  );
});

const DropdownFilter = ({
  options,
  value,
  label,
  emptyListMessage,
  onApply,
  nameKey = "name",
  idKey = "id",
  disabled,
  onChange,
  placeholder,
  isLoading,
  loadingMessage,
  onClose,
  selectedOpitonsNum,
  ...props
}) => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const inputRef = useRef(null);

  const handleClose = () => {
    setOpen(false);
    onClose && setTimeout(() => onClose(), 0); // Defer until the dropdown is closed
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleChange = (_event, newSelectedOptions) => {
    const isAllSelected =
      newSelectedOptions?.some?.((item) => item?.[idKey] === "all") === true;
    if (isAllSelected) {
      newSelectedOptions = options;
    }
    onChange && onChange?.(newSelectedOptions);
  };
  const optionItemRender = (props, option, { selected }) => {
    const isAll = option?.[idKey] === "all";
    const isAllSelected = value?.length === options?.length;
    const name = option?.[nameKey];
    const isArabicName = isArabic(name) ? "arabic-name" : "";
    return (
      <li
        {...props}
        className="engagement-analytics-option-item"
        key={option?.[idKey]}
        onClick={(event) => {
          if (isAll && isAllSelected) {
            handleChange(event, []);
          } else {
            props?.onClick?.(event);
          }
        }}
        aria-selected={
          isAll ? isAllSelected : props?.["aria-selected"] || selected
        }
      >
        <>
          <Checkbox checked={isAll ? isAllSelected : selected} />
          <Box className="engagement-analytics-option-title">
            <TruncatedTooltip
              title={name}
              placement="top"
              arrow
              className={isArabicName}
              enablePropperProps={true}
            />
          </Box>
        </>
      </li>
    );
  };
  const getOptionSelected = (option, anotherOption) => {
    return option?.[idKey] === anotherOption?.[idKey];
  };

  const handleApplyClick = () => {
    // close the dropdown
    setOpen(false);
    // call the onApply function
    onApply && onApply();
    // unfocus the input
    inputRef?.current?.blur();
  };
  const inputRender = (params) => (
    <TextField
      {...params}
      label={label}
      placeholder={placeholder}
      error={props?.error}
      helperText={props?.helperText}
      inputRef={inputRef}
      className={classNames(
        "engagement-analytics-controll-dropdown-input",
        open || value?.length === 0 ? "" : "selected",
      )}
    />
  );
  return (
    <Box className="engagement-analytics-dropdown-container">
      <FormControl
        className={classNames(
          "engagement-analytics-dropdown-controll-wrapper",
          label && "with-label",
        )}
      >
        <Autocomplete
          disableClearable
          disableCloseOnSelect={true}
          autoComplete
          open={open}
          autoHighlight={false}
          className="engagement-analytics-dropdown-controll-select"
          id="engagement-analytics-dropdown"
          options={
            options?.length
              ? [
                  {
                    [nameKey]: CheckValueLocale("all", "", {}, intl),
                    [idKey]: "all",
                  },
                  ...options,
                ]
              : []
          }
          onClose={handleClose}
          onOpen={handleOpen}
          value={value}
          getOptionLabel={(option) => option?.[nameKey]}
          onChange={handleChange}
          multiple={true}
          renderOption={optionItemRender}
          renderGroup={(params) => {
            return <>{params?.children}</>;
          }}
          groupBy={(option) => option?.typeOf}
          isOptionEqualToValue={getOptionSelected}
          renderInput={inputRender}
          disabled={disabled}
          noOptionsText={isLoading ? loadingMessage : emptyListMessage}
          renderTags={(value, getTagProps) =>
            defaultRenderTags(
              value,
              getTagProps,
              open,
              intl,
              options?.length,
              selectedOpitonsNum,
            )
          }
          classes={{
            paper: classNames(
              "engagement-analytics-dropdown-controll-paper-menu",
              props?.paperClassName,
            ),
            listbox: classNames(
              "engagement-analytics-dropdown-menu-listbox",
              props?.listboxClassName,
            ),
          }}
          size="small"
          ListboxComponent={ListboxComponent}
          ListboxProps={{
            handleApplyClick,
            hasOptions: options?.length,
            selectedOpitonsNum,
          }}
        />
      </FormControl>
    </Box>
  );
};

export default DropdownFilter;
