import { useEffect, useRef, useState } from "react";
import { Box, Grid } from "@mui/material";
import Header from "../header";
import { checkIsDataFecthing } from "pages/channelAnalytics/InsightsPage/insightsCommonFunctions";
import {
  calcAverage,
  handleAgentPerformanceAggregation,
  handleHitsAndMisses,
} from "../../sharedFunctions/aggregateFunctions";
import EngagementsController from "services/controllers/engagementsController";
import { AgentTabPerformanceTable } from "../../components/agentsTabPerformanceTable";
import DropdownFilter from "../../components/dropdownFilter/dropdownFilter";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { commasAfterDigit } from "utils/helpers";
import {
  faMessage,
  faComments,
  faGlobe,
  faReply,
  faCircleCheck,
  faClock,
  faReplyAll,
  faFolderOpen,
  faGroupArrowsRotate,
  faCircle,
} from "@fortawesome/pro-solid-svg-icons";
import {
  displayRemainingTime,
  renderRemainingTime,
} from "../../sharedFunctions/datesUtils";
import StatisticsWidget from "components/widgets/statisticsWidget/statisticsWidget";
import {
  faFileExport,
  faFileImport,
  faPeopleArrows,
} from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import PieChartWidget from "components/widgets/pieChartWidget";
import mqttRabbitMQResponce from "services/controllers/mqttRabbitMQResponce";
import {
  handleAggregatePieChart,
  handleAggregatePunchCardCharts,
  handleAggregateLegends,
  handleAggregateLineChartMultipleValues,
  handleAggregateBarCharts,
} from "utils/helpers/aggregateFunctions";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import StackedLineWidget from "components/widgets/stackedLineWidget";
import {
  getHandleSortedArray,
  orderObjectByArray,
} from "../../sharedFunctions/aggregateFunctions";
import CircularLoading from "components/circularProgress";
import { AgentCsatFeedback } from "../../components/surveyWidget/AgentCsatFeedback";
import StackedBarWidget from "components/widgets/stackedBarWidget";
import CompaniesController from "services/controllers/companiesController";
import MonitorsController from "services/controllers/monitorsController";
import AgentStatusSummaryTable from "../../components/agentStatusSummaryTable";
import moment from "moment";

// for agent inbox performance chart widget
const COLORS_ARR = ["#304D7D", "#6CADE9"];
let typesSorted = ["assigned", "completed"];
const groupByOptions = [
  "agent_status_overview_grouped_avg",
  "agent_status_overview_grouped_total",
];

const AgentsTab = ({
  selectedTab,
  preLoaderTrackerDataSources,
  response,
  startDate,
  endDate,
  handleSeletedOptions,
  resetAllFields,
  isLoading,
  querySendedData,
  hasAppliedTimeFilter,
  deploymentDate,
  noFiltersHasBeenApplied,
}) => {
  const intl = useIntl();
  const mqttCsatConnectionClosed = useRef(false);
  const clientCsatMQTT = useRef();
  // excel State
  const [excelSheetData, setExcelSheetData] = useState([]);
  const [checkAllDataFetchingExport, setCheckAllDataFetchingExport] =
    useState(false);

  // Overview widget states
  const [engagOverView, setEngagOverView] = useState({});
  const [engagOverViewPreLoader, setEngagOverViewPreLoader] = useState([]);

  // Agent Status
  const [agentsStatusOverView, setAgentsStatusOverView] = useState({});
  const [agentStatusOverViewPreLoader, setAgentStatusOverViewPreLoader] =
    useState([]);
  // Agents Performance Table
  const [agents, setAgents] = useState([]);
  const [agentPerformance, setAgentPerformance] = useState([]);
  const [agentPerformancePreLoader, setAgentPerformancePreLoader] = useState(
    [],
  );
  // Agents Status Summary Table
  const [agentStatusSummary, setAgentStatusSummary] = useState([]);
  const [agentStatusSummaryPreLoader, setAgentStatusSummaryPreLoader] =
    useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const appliedSelectedAgents = useRef([]);
  //Csat
  const [teams, setTeams] = useState([]);
  const [csatResponse, setCsatResponse] = useState({});
  const [agentCsatPage, setAgentCsatPage] = useState(1);
  const [isAgentCsatPagination, setIsAgentCsatPagination] = useState(false);
  const [agentCsatCount, setAgentCsatCount] = useState(0);
  const [agentCsatResponseData, setAgentCsatResponseData] = useState([]);
  const [agentCsatData, setAgentCsatData] = useState([]);
  const [agentCsatPaginationQuery, setAgentCsatPaginationQuery] = useState({});
  const [agentCsatLoading, setAgentCsatLoading] = useState(true);
  const [allawedTrackers, setAllawedTrackers] = useState({
    data: [],
    loading: true,
  });

  const [csatOverTime, setCsatOverTime] = useState([]);
  const [csatOverTimeLegends, setCsatOverTimeLegends] = useState([]);
  const [csatOverTimePreLoader, setCsatOverTimePreLoader] = useState([]);

  const [csatScore, setCsatScore] = useState([]);
  const [csatScoreCopy, setCsatScoreCopy] = useState([]);
  const [csatScoreXlxs, setCsatScoreXlxs] = useState([]);
  const [csatScorePreLoader, setCsatScorePreLoader] = useState([]);
  // agent distribution pie chart
  const [agentsDistribution, setAgentsDistribution] = useState([]);
  const [agentsDistributionPreLoader, setAgentsDistributionPreLoader] =
    useState([]);
  const [agentsDistributionCopy, setAgentsDistributionCopy] = useState([]);
  const [agentsDistributionXlxs, setAgentsDistributionXlxs] = useState([]);
  const [isLoadingAgents, setIsloadingAgents] = useState(0);
  const isAgentsLoading = isLoadingAgents === 0;

  // agent inbox performance
  const [agentInboxPerformance, setAgentInboxPerformance] = useState([]);
  const [agentInboxPerformancePreLoader, setAgentInboxPerformancePreLoader] =
    useState([]);
  const [agentInboxPerformanceChart, setagentInboxPerformanceChart] = useState(
    [],
  );

  // hist and misses table
  const [hitsAndMisses, setHitsAndMisses] = useState({});
  const [hitsAndMissesPreLoader, setHitsAndMissesPreLoader] = useState([]);

  const agentCsatLabels = [
    { name: "csat_very_dissatisfied", value: "#F72549" },
    { name: "csat_dissatisfied", value: "#F86A82" },
    { name: "csat_neutral", value: "#FBDD6C" },
    { name: "csat_satisfied", value: "#2CD9BE" },
    { name: "csat_very_satisfied", value: "#5BB4A7" },
  ];
  // Group by
  const [selectedGroupBy, setSelectedGroupBy] = useState(groupByOptions[0]);

  let StartDateMoment = moment.unix(startDate);
  let EndDateMoment = moment.unix(endDate);
  const numberOfDaysExclusive = EndDateMoment.diff(StartDateMoment, "days");
  const numberOfDaysInclusive = numberOfDaysExclusive + 1;

  const handleTableData = () => {
    const sortedTableData = [
      {
        type: "avgs_time_to_complete",
        avg_slas: renderRemainingTime(
          displayRemainingTime(
            hitsAndMisses["time_to_complete"]?.avgTime
              ? hitsAndMisses["time_to_complete"]?.avgTime
              : 0,
          ),
          intl,
        ),
        hits: hitsAndMisses["time_to_complete"]?.hits,
        misses: hitsAndMisses["time_to_complete"]?.misses,
      },
      {
        type: "avgs_first_response_time",
        avg_slas: renderRemainingTime(
          displayRemainingTime(
            hitsAndMisses["first_response_time"]?.avgTime
              ? hitsAndMisses["first_response_time"]?.avgTime
              : 0,
          ),
          intl,
        ),
        hits: hitsAndMisses["first_response_time"]?.hits,
        misses: hitsAndMisses["first_response_time"]?.misses,
      },
      {
        type: "avgs_next_response_time",
        avg_slas: renderRemainingTime(
          displayRemainingTime(
            hitsAndMisses["next_response_time"]?.avgTime
              ? hitsAndMisses["next_response_time"]?.avgTime
              : 0,
          ),
          intl,
        ),
        hits: hitsAndMisses["next_response_time"]?.hits,
        misses: hitsAndMisses["next_response_time"]?.misses,
      },
    ];
    return sortedTableData;
  };
  const handleDropdwonChange = (newValue) => {
    setSelectedAgents(newValue);
  };
  const handleDropdownApply = () => {
    appliedSelectedAgents.current = selectedAgents;
    handleSeletedOptions(
      "assignees_ids",
      selectedAgents?.map((item) => item?.id)?.join(","),
    );
  };
  const handleDropdownClose = () => {
    setSelectedAgents(appliedSelectedAgents.current);
  };

  useEffect(() => {
    setAgentCsatLoading(true);
    if (querySendedData?.product_id) {
      let query = { ...querySendedData, page_number: agentCsatPage };
      query.filters = JSON.stringify({
        ...query.filters,
      });
      query.last_operation =
        agentCsatPage > 1 ? agentCsatPaginationQuery?.last_operation || 0 : 0;
      query.survey_response_id =
        agentCsatPage > 1
          ? JSON.stringify({
              ...(agentCsatPaginationQuery?.survey_response_id || {}),
            })
          : undefined;

      EngagementsController.getCsatFeedback(query).then((res) => {
        if (res?.data?.data) {
          setCsatResponse({});

          mqttRabbitMQResponce(
            clientCsatMQTT,
            res?.data?.data,
            setCsatResponse,
          );
        } else {
          mqttCsatConnectionClosed.current = true;
        }
      });
    }
  }, [querySendedData, agentCsatPage]);

  const handleChangePage = (page) => {
    setAgentCsatLoading(true);
    setAgentCsatPage(page);
  };

  useEffect(() => {
    switch (csatResponse?.eventName) {
      case "EngagementSurveyFeedback":
        setAgentCsatPaginationQuery({
          last_operation: csatResponse?.eventData?.last_operation || 0,
          survey_response_id: {
            ...(csatResponse?.eventData?.survey_response_id || {}),
          },
        });
        setIsAgentCsatPagination(csatResponse?.eventData?.next_page);
        setAgentCsatCount(
          csatResponse?.eventData?.survey_feedbacks_count?.total_responses ||
            agentCsatCount,
        );
        if (!csatResponse?.eventData?.survey_feedbacks?.length) {
          setAgentCsatLoading(false);
        }
        setAgentCsatResponseData([
          ...(csatResponse?.eventData?.survey_feedbacks || []),
        ]);
        break;
      default:
        break;
    }
  }, [csatResponse]);

  useEffect(() => {
    if (
      teams?.length &&
      agents?.length &&
      agentCsatResponseData?.length &&
      !allawedTrackers?.loading
    ) {
      let agentsData = [...(agentCsatData || [])];
      agentCsatResponseData?.map((item) => {
        agentsData.push({
          ...item,
          itemData: {
            isAllawedMonitor: allawedTrackers?.data?.includes(item?.tracker_id),
            data_source: item?.data_source?.split("_")?.[0]?.toLowerCase(),
            name: item?.name,
            verified: item?.verified,
            image_url: item?.user_profile_image_url,
            engagement_id: item?.engagement_id,
            message: item?.message?.content?.text,
            sentiment: [item?.sentiment],
            v2_themes: [...(item?.v2_themes || [])],
            v2_categories: [...(item?.v2_categories || [])],
            csat_score: item?.csat_score,
            agent:
              agents?.filter(
                (agentItem) => item?.agent_id == agentItem?.id,
              )?.[0]?.name || "--",
            team:
              teams?.filter((teamsItem) => item?.team_id == teamsItem?.id)?.[0]
                ?.name || "--",
          },
          screen_name: item?.user_name,
          user_name: item?.name,
        });
      });
      setAgentCsatData([...(agentsData || [])]);
      setAgentCsatLoading(false);
    }
  }, [teams, agents, agentCsatResponseData, allawedTrackers]);

  useEffect(() => {
    switch (response?.eventName) {
      case "Twitterprivate__EngagementAnalyticsV2Page__AGENT_PAGE__agents_performance":
      case "Twitter__EngagementAnalyticsV2Page__AGENT_PAGE__agents_performance":
      case "Facebookprivate__EngagementAnalyticsV2Page__AGENT_PAGE__agents_performance":
      case "Facebook__EngagementAnalyticsV2Page__AGENT_PAGE__agents_performance":
      case "Instagramprivate__EngagementAnalyticsV2Page__AGENT_PAGE__agents_performance":
      case "Instagram__EngagementAnalyticsV2Page__AGENT_PAGE__agents_performance":
      case "Whatsappprivate__EngagementAnalyticsV2Page__AGENT_PAGE__agents_performance":
        const accumulatedData = handleAgentPerformanceAggregation(
          response?.eventData,
          agentPerformance,
        );
        setAgentPerformance(accumulatedData);
        setAgentPerformancePreLoader((prev) => [
          ...prev,
          response?.data_source,
        ]);
        break;
      case "Twitter__EngagementAnalyticsV2Page__AGENT_PAGE__overview":
      case "Facebook__EngagementAnalyticsV2Page__AGENT_PAGE__overview":
      case "Twitterprivate__EngagementAnalyticsV2Page__AGENT_PAGE__overview":
      case "Facebookprivate__EngagementAnalyticsV2Page__AGENT_PAGE__overview":
      case "Instagram__EngagementAnalyticsV2Page__AGENT_PAGE__overview":
      case "Instagramprivate__EngagementAnalyticsV2Page__AGENT_PAGE__overview":
      case "Whatsappprivate__EngagementAnalyticsV2Page__AGENT_PAGE__overview":
        setEngagOverView({
          manual_assignment:
            (+engagOverView?.manual_assignment || 0) +
            (+response?.eventData?.overview_result?.manual_assignment || 0),
          auto_assignment_total:
            (+engagOverView?.auto_assignment_total || 0) +
            (+response?.eventData?.overview_result?.auto_assignment || 0),
          total_replied:
            (+engagOverView?.total_replied || 0) +
            (+response?.eventData?.overview_result?.total_replied || 0),
          total_completed:
            (+engagOverView?.total_completed || 0) +
            (+response?.eventData?.overview_result?.total_completed || 0),
          total_avg_value:
            (+engagOverView?.total_avg_value || 0) +
            (+response?.eventData?.overview_result?.handling_time?.value || 0),
          total_avg_count:
            (+engagOverView?.total_avg_count || 0) +
            (+response?.eventData?.overview_result?.handling_time?.count || 0),
          total_reopened:
            (+engagOverView?.total_reopened || 0) +
            (+response?.eventData?.overview_result?.total_reopened || 0),
          surveys_sent_count:
            (+engagOverView?.surveys_sent_count || 0) +
            (+response?.eventData?.overview_result?.surveys_sent_count || 0),
          surveys_responses_count:
            (+engagOverView?.surveys_responses_count || 0) +
            (+response?.eventData?.overview_result?.surveys_responses_count ||
              0),
        });
        setEngagOverViewPreLoader([
          ...engagOverViewPreLoader,
          response?.data_source,
        ]);
        break;

      case "Twitter__EngagementAnalyticsV2Page__AGENT_PAGE__engagement_distribution_over_agents":
      case "Twitterprivate__EngagementAnalyticsV2Page__AGENT_PAGE__engagement_distribution_over_agents":
      case "Facebook__EngagementAnalyticsV2Page__AGENT_PAGE__engagement_distribution_over_agents":
      case "Facebookprivate__EngagementAnalyticsV2Page__AGENT_PAGE__engagement_distribution_over_agents":
      case "Instagram__EngagementAnalyticsV2Page__AGENT_PAGE__engagement_distribution_over_agents":
      case "Instagramprivate__EngagementAnalyticsV2Page__AGENT_PAGE__engagement_distribution_over_agents":
      case "Whatsappprivate__EngagementAnalyticsV2Page__AGENT_PAGE__engagement_distribution_over_agents":
        const newAgentDist =
          response?.eventData?.data?.agents?.map?.((item) => {
            return {
              name: item?.user_id,
              value: item?.value,
            };
          }) || [];
        handleAggregatePieChart(newAgentDist, agentsDistributionCopy);
        setAgentsDistributionPreLoader((prev) => [
          ...prev,
          response?.data_source,
        ]);
        break;

      case "Facebookprivate__EngagementAnalyticsV2Page__AGENT_PAGE__SLAsHitsAndMisses__Overview":
      case "Twitter__EngagementAnalyticsV2Page__AGENT_PAGE__SLAsHitsAndMisses__Overview":
      case "Twitterprivate__EngagementAnalyticsV2Page__AGENT_PAGE__SLAsHitsAndMisses__Overview":
      case "Facebook__EngagementAnalyticsV2Page__AGENT_PAGE__SLAsHitsAndMisses__Overview":
      case "Instagram__EngagementAnalyticsV2Page__AGENT_PAGE__SLAsHitsAndMisses__Overview":
      case "Whatsappprivate__EngagementAnalyticsV2Page__AGENT_PAGE__SLAsHitsAndMisses__Overview":
      case "Instagramprivate__EngagementAnalyticsV2Page__AGENT_PAGE__SLAsHitsAndMisses__Overview":
        const responseArr = response?.eventData?.data;
        const accumaltedData = handleHitsAndMisses(responseArr, hitsAndMisses);
        setHitsAndMisses(accumaltedData);
        setHitsAndMissesPreLoader((prev) => [...prev, response?.data_source]);
        break;

      case "Facebook__EngagementAnalyticsV2Page__AGENT_PAGE__agent_inbox_performance":
      case "Facebookprivate__EngagementAnalyticsV2Page__AGENT_PAGE__agent_inbox_performance":
      case "Twitter__EngagementAnalyticsV2Page__AGENT_PAGE__agent_inbox_performance":
      case "Twitterprivate__EngagementAnalyticsV2Page__AGENT_PAGE__agent_inbox_performance":
      case "Instagram__EngagementAnalyticsV2Page__AGENT_PAGE__agent_inbox_performance":
      case "Instagramprivate__EngagementAnalyticsV2Page__AGENT_PAGE__agent_inbox_performance":
      case "Whatsappprivate__EngagementAnalyticsV2Page__AGENT_PAGE__agent_inbox_performance":
        handleAggregateLegends(
          response?.eventData?.data?.agent_inbox_legends,
          agentInboxPerformanceChart,
        );
        handleAggregateLineChartMultipleValues(
          response?.eventData?.data?.posts_over_time,
          agentInboxPerformance,
          setAgentInboxPerformance,
        );
        setAgentInboxPerformancePreLoader((oldArray) => [
          ...oldArray,
          response?.data_source,
        ]);
        break;
      case "Facebook__EngagementAnalyticsV2Page__AGENT_PAGE__agents_csat_score":
      case "Facebookprivate__EngagementAnalyticsV2Page__AGENT_PAGE__agents_csat_score":
      case "Twitter__EngagementAnalyticsV2Page__AGENT_PAGE__agents_csat_score":
      case "Twitterprivate__EngagementAnalyticsV2Page__AGENT_PAGE__agents_csat_score":
      case "Instagram__EngagementAnalyticsV2Page__AGENT_PAGE__agents_csat_score":
      case "Instagramprivate__EngagementAnalyticsV2Page__AGENT_PAGE__agents_csat_score":
      case "Whatsappprivate__EngagementAnalyticsV2Page__AGENT_PAGE__agents_csat_score":
        const newCsatScore = response?.eventData?.data?.scores;
        handleAggregatePieChart(newCsatScore, csatScoreCopy);
        setCsatScoreXlxs(_.cloneDeep(csatScoreCopy));
        setCsatScore(_.cloneDeep(csatScoreCopy));
        setCsatScorePreLoader((prev) => [...prev, response?.data_source]);
        break;
      case "Facebook__EngagementAnalyticsV2Page__AGENT_PAGE__agents_csat_score_overtime":
      case "Facebookprivate__EngagementAnalyticsV2Page__AGENT_PAGE__agents_csat_score_overtime":
      case "Twitter__EngagementAnalyticsV2Page__AGENT_PAGE__agents_csat_score_overtime":
      case "Twitterprivate__EngagementAnalyticsV2Page__AGENT_PAGE__agents_csat_score_overtime":
      case "Instagram__EngagementAnalyticsV2Page__AGENT_PAGE__agents_csat_score_overtime":
      case "Instagramprivate__EngagementAnalyticsV2Page__AGENT_PAGE__agents_csat_score_overtime":
      case "Whatsappprivate__EngagementAnalyticsV2Page__AGENT_PAGE__agents_csat_score_overtime":
        handleAggregateBarCharts(
          response?.eventData?.csat_scores_over_time?.map((item) => {
            return { name: item?.date, value: item?.scores };
          }),
          csatOverTime,
        );
        handleAggregateLegends(
          response?.eventData?.csat_scores_piechart,
          csatOverTimeLegends,
        );

        setCsatOverTimePreLoader((oldArray) => [
          ...oldArray,
          response?.data_source,
        ]);
        break;
      case "EngagementAnalyticsV2Page__agent_status_overview":
        response?.eventData?.data?.forEach((ele) => {
          setAgentsStatusOverView((prev) => ({
            ...prev,
            ["total_" + ele.status + "_time"]: ele.total_sum,
            count: response?.eventData?.count,
            numOfDays: numberOfDaysInclusive,
          }));
        });
        setAgentStatusOverViewPreLoader([...preLoaderTrackerDataSources]);
        break;
      case "EngagementAnalyticsV2Page__agent_status_performance":
        setAgentStatusSummary(response?.eventData?.data);
        setAgentStatusSummaryPreLoader([...preLoaderTrackerDataSources]);
        break;
      default:
        break;
    }
  }, [response]);

  useEffect(() => {
    if (
      _.isEqual(
        engagOverViewPreLoader?.sort(),
        preLoaderTrackerDataSources?.sort(),
      ) &&
      engagOverViewPreLoader?.length
    ) {
      let overViewData = {
        ...engagOverView,
        avg_engag: calcAverage(
          engagOverView?.total_avg_value || 0,
          engagOverView?.total_avg_count || 0,
        ),
      };
      delete overViewData.total_avg_count;
      delete overViewData.total_avg_value;
      setEngagOverView({ ...overViewData });
    }
  }, [engagOverViewPreLoader, preLoaderTrackerDataSources]);

  // handle excel data
  useEffect(() => {
    let totalPreLoadersDataSources = [
      agentPerformancePreLoader,
      engagOverViewPreLoader,
      agentsDistributionPreLoader,
      hitsAndMissesPreLoader,
      agentInboxPerformancePreLoader,
      csatScorePreLoader,
      csatOverTimePreLoader,
      agentStatusOverViewPreLoader,
      agentStatusSummaryPreLoader,
    ]; // all preloader  added here

    const isAllPreLoadersLoaded =
      checkIsDataFecthing(
        totalPreLoadersDataSources,
        preLoaderTrackerDataSources,
      ) &&
      isLoadingAgents >= 2 &&
      !agentCsatLoading &&
      !allawedTrackers?.loading;
    setCheckAllDataFetchingExport(isAllPreLoadersLoaded);

    const excelData = {
      engagOverView: engagOverView,
      agentStatusOverview: agentsStatusOverView,
      agentInboxPerformance: agentInboxPerformance,
      hitsAndMisses: hitsAndMisses,
      agentPerformance: { data: agentPerformance, agents },
      agentStatusSummary: { data: agentStatusSummary, agents },
      agentsDistribution: agentsDistributionXlxs,
      csatScore: csatScoreXlxs,
      csatOverTime: { data: csatOverTime, pieChart: csatOverTimeLegends },
      agentCsatData: agentCsatData,
    };
    setExcelSheetData(excelData);
  }, [
    agentPerformance,
    agentPerformancePreLoader,
    agentStatusSummary,
    agentStatusSummaryPreLoader,
    engagOverView,
    engagOverViewPreLoader,
    agentsStatusOverView,
    agentsDistributionXlxs,
    agentsDistributionPreLoader,
    isLoadingAgents,
    hitsAndMisses,
    hitsAndMissesPreLoader,
    agentInboxPerformance,
    agentInboxPerformancePreLoader,
    csatScoreXlxs,
    csatScorePreLoader,
    csatOverTime,
    csatOverTimeLegends,
    csatOverTimePreLoader,
    agentCsatData,
    agentCsatLoading,
    allawedTrackers?.loading,
  ]);

  useEffect(() => {
    const getAgents = () => {
      // Call API to get agents
      EngagementsController.getUsers(window.localStorage.engagements_id).then(
        (res) => {
          if (!res?.errorMsg && res?.data?.data?.length) {
            const agents = res?.data?.data?.map((agent) => ({
              ...(agent?.attributes || {}),
            }));
            setAgents(agents);
          }
          setIsloadingAgents(1);
        },
      );
    };

    const getTeams = () => {
      CompaniesController.getAllCompanyTeams().then((res) => {
        if (!res?.errorMsg && res?.data?.teams?.length) {
          setTeams(res?.data?.teams);
        }
      });
    };
    const getAllawedMonitors = () => {
      MonitorsController.getAllawedMonitors(
        window?.localStorage?.engagements_id,
      ).then((AllawedData) => {
        if (AllawedData?.data?.data?.monitors_list) {
          setAllawedTrackers({
            data: [...(AllawedData?.data?.data?.monitors_list || [])],
            loading: false,
          });
        }
      });
    };
    getAllawedMonitors();
    getTeams();
    getAgents();
  }, []);

  // handle agents distribution
  useEffect(() => {
    if (
      isLoadingAgents == 1 &&
      preLoaderTrackerDataSources?.length &&
      _.isEqual(
        agentsDistributionPreLoader?.sort(),
        preLoaderTrackerDataSources?.sort(),
      )
    ) {
      const newAgentDist = agentsDistributionCopy
        ?.map((item) => {
          const agentName =
            agents?.find((agent) => agent?.id == item?.name)?.name || null;
          if (agentName) {
            return {
              name: agentName,
              value: item?.value,
            };
          }
          return null;
        })
        ?.filter(Boolean);
      setAgentsDistributionXlxs(_.cloneDeep(newAgentDist));
      setAgentsDistribution(_.cloneDeep(newAgentDist));
      setIsloadingAgents(2);
    }
  }, [agentsDistributionPreLoader, isLoadingAgents]);

  // reset all states
  useEffect(() => {
    if (resetAllFields !== 1) {
      setAgentPerformance([]);
      setAgentPerformancePreLoader([]);
      setEngagOverView({});
      setEngagOverViewPreLoader([]);
      setAgentsStatusOverView({});
      setAgentStatusOverViewPreLoader([]);
      setAgentsDistribution([]);
      setAgentsDistributionPreLoader([]);
      setAgentsDistributionCopy([]);
      setAgentsDistributionXlxs([]);

      setCsatScore([]);
      setCsatScorePreLoader([]);
      setCsatScoreCopy([]);
      setCsatScoreXlxs([]);
      setCsatOverTime([]);
      setCsatOverTimeLegends([]);
      setCsatOverTimePreLoader([]);

      setCsatResponse({});
      setAgentCsatPage(1);
      setIsAgentCsatPagination(false);
      setAgentCsatCount(0);
      setAgentCsatResponseData([]);
      setAgentCsatData([]);
      setAgentCsatPaginationQuery({});
      setAgentCsatLoading(true);

      isLoadingAgents && setIsloadingAgents(1);
      setHitsAndMisses({});
      setHitsAndMissesPreLoader([]);
      setAgentInboxPerformance([]);
      setagentInboxPerformanceChart([]);
      setAgentInboxPerformancePreLoader([]);
      setAgentStatusSummaryPreLoader([]);
      setAgentStatusSummary([]);
    }
  }, [resetAllFields]);

  if (isLoading) {
    return <CircularLoading />;
  }

  const handleGroupByChange = (groupBy) => {
    setSelectedGroupBy(groupBy);
  };

  let startDateInUtc = moment.unix(startDate).utc();
  let deploymentDateInUtc = moment.unix(deploymentDate).utc();
  const outdatedDateRange = startDateInUtc.isBefore(deploymentDateInUtc);

  return (
    <>
      <Header
        selectedTab={selectedTab}
        checkAllDataExist={checkAllDataFetchingExport}
        excelSheetData={excelSheetData}
        checkDataIsEmpty={
          +engagOverView?.manual_assignment > 0 ||
          +engagOverView?.auto_assignment_total > 0
        }
        startDate={startDate}
        endDate={endDate}
      />
      <Box className="engag-dashboard-widgets-container">
        <Box mb={5}>
          <DropdownFilter
            options={agents}
            value={selectedAgents}
            label={CheckValueLocale("engag_select_agent", "", {}, intl)}
            emptyListMessage={CheckValueLocale("engag_no_agents", "", {}, intl)}
            onChange={handleDropdwonChange}
            onApply={handleDropdownApply}
            onClose={handleDropdownClose}
            selectedOpitonsNum={appliedSelectedAgents.current?.length}
            disabled={isAgentsLoading}
          />
        </Box>
        <Box mb={5}>
          <StatisticsWidget
            title={"engag_agents_overview"}
            titleToolTip={"engag_agents_overview_tooltip"}
            chartId={"agent_performance"}
            toolTipLabel={"agent_overview_tooltip"}
            showDownloadIcon
            bigImg
            statsData={[
              {
                name: "manual_assignment",
                value: engagOverView?.manual_assignment
                  ? commasAfterDigit(engagOverView?.manual_assignment)
                  : 0,
                icon: faPeopleArrows,
                titleToolTip: "manual_assignment_tooltip",
              },
              {
                name: "auto_assignment_total",
                value: engagOverView?.auto_assignment_total
                  ? commasAfterDigit(engagOverView?.auto_assignment_total)
                  : 0,
                icon: faGroupArrowsRotate,
                titleToolTip: "auto_assignment_tooltip",
              },
              {
                name: "total_replied",
                value: engagOverView?.total_replied
                  ? commasAfterDigit(engagOverView?.total_replied)
                  : 0,
                icon: faReplyAll,
                titleToolTip: "total_replied_agents_tooltip",
              },
              {
                name: "total_completed",
                value: engagOverView?.total_completed
                  ? commasAfterDigit(engagOverView?.total_completed)
                  : 0,
                icon: faCircleCheck,
                titleToolTip: "total_completed_agents_tooltip",
              },
              {
                name: "avg_engag",
                value: renderRemainingTime(
                  displayRemainingTime(
                    engagOverView?.avg_engag ? engagOverView?.avg_engag : 0,
                  ),
                  intl,
                ),
                icon: faClock,
                titleToolTip: "avg_engag_agents_tooltip",
              },
              {
                name: "reopens_engag",
                value:
                  engagOverView?.total_reopened || 0
                    ? commasAfterDigit(engagOverView?.total_reopened)
                    : 0,
                icon: faFolderOpen,
                titleToolTip: "reopens_engag_tooltip",
              },
              {
                name: "surveys_sent_count",
                value:
                  engagOverView?.surveys_sent_count || 0
                    ? commasAfterDigit(engagOverView?.surveys_sent_count)
                    : 0,
                icon: faFileExport,
                titleToolTip: "surveys_sent_count_tooltip",
              },
              {
                name: "surveys_responses_count",
                value:
                  engagOverView?.surveys_responses_count || 0
                    ? commasAfterDigit(engagOverView?.surveys_responses_count)
                    : 0,
                icon: faFileImport,
                titleToolTip: "surveys_responses_count_tooltip",
              },
            ]}
            containerClassName={"engag-overview"}
            statisticsPreLoaderDataSources={engagOverViewPreLoader}
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
            toolTipPosition="top"
            isFourAtRow
          />
        </Box>
        <Box mb={5}>
          <StatisticsWidget
            title={"agents_status_overview"}
            titleToolTip={"agents_status_overview_tooltip"}
            chartId={"agent_status"}
            showDownloadIcon
            bigImg
            statsData={[
              {
                name:
                  selectedGroupBy === "agent_status_overview_grouped_total"
                    ? "total_available_time"
                    : "avg_available_time",
                value: renderRemainingTime(
                  displayRemainingTime(
                    selectedGroupBy === "agent_status_overview_grouped_total"
                      ? agentsStatusOverView?.total_available_time
                      : agentsStatusOverView?.total_available_time /
                          (agentsStatusOverView?.count * numberOfDaysInclusive),
                  ),
                  intl,
                ),
                icon: faCircle,
                titleToolTip:
                  selectedGroupBy === "agent_status_overview_grouped_total"
                    ? "total_available_time_tooltip"
                    : "avg_available_time_tooltip",
              },
              {
                name:
                  selectedGroupBy === "agent_status_overview_grouped_total"
                    ? "total_busy_time"
                    : "avg_busy_time",
                value: renderRemainingTime(
                  displayRemainingTime(
                    selectedGroupBy === "agent_status_overview_grouped_total"
                      ? agentsStatusOverView?.total_busy_time
                      : agentsStatusOverView?.total_busy_time /
                          (agentsStatusOverView?.count * numberOfDaysInclusive),
                  ),
                  intl,
                ),
                icon: faCircle,
                titleToolTip:
                  selectedGroupBy === "agent_status_overview_grouped_total"
                    ? "total_busy_time_tooltip"
                    : "avg_busy_time_tooltip",
              },
              {
                name:
                  selectedGroupBy === "agent_status_overview_grouped_total"
                    ? "total_meeting_time"
                    : "avg_meeting_time",
                value: renderRemainingTime(
                  displayRemainingTime(
                    selectedGroupBy === "agent_status_overview_grouped_total"
                      ? agentsStatusOverView?.total_meeting_time
                      : agentsStatusOverView?.total_meeting_time /
                          (agentsStatusOverView?.count * numberOfDaysInclusive),
                  ),
                  intl,
                ),
                icon: faCircle,
                titleToolTip:
                  selectedGroupBy === "agent_status_overview_grouped_total"
                    ? "total_meeting_time_tooltip"
                    : "avg_meeting_time_tooltip",
              },
              {
                name:
                  selectedGroupBy === "agent_status_overview_grouped_total"
                    ? "total_break_time"
                    : "avg_break_time",
                value: renderRemainingTime(
                  displayRemainingTime(
                    selectedGroupBy === "agent_status_overview_grouped_total"
                      ? agentsStatusOverView?.total_break_time
                      : agentsStatusOverView?.total_break_time /
                          (agentsStatusOverView?.count * numberOfDaysInclusive),
                  ),
                  intl,
                ),
                icon: faCircle,
                titleToolTip:
                  selectedGroupBy === "agent_status_overview_grouped_total"
                    ? "total_break_time_tooltip"
                    : "avg_break_time_tooltip",
              },
              {
                name:
                  selectedGroupBy === "agent_status_overview_grouped_total"
                    ? "total_unavailable_time"
                    : "avg_unavailable_time",
                value: renderRemainingTime(
                  displayRemainingTime(
                    selectedGroupBy === "agent_status_overview_grouped_total"
                      ? agentsStatusOverView?.total_unavailable_time
                      : agentsStatusOverView?.total_unavailable_time /
                          (agentsStatusOverView?.count * numberOfDaysInclusive),
                  ),
                  intl,
                ),
                icon: faCircle,
                titleToolTip:
                  selectedGroupBy === "agent_status_overview_grouped_total"
                    ? "total_unavailable_time_tooltip"
                    : "avg_unavailable_time_tooltip",
              },
            ]}
            showReportIcon={!noFiltersHasBeenApplied}
            groupByOptions={groupByOptions}
            selectedGroupBy={selectedGroupBy}
            onGroupByChange={handleGroupByChange}
            containerClassName={"engag-overview"}
            noGroubByLabel={true}
            statisticsPreLoaderDataSources={agentStatusOverViewPreLoader}
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
            toolTipPosition="top"
            alertType="warning"
            alertMessage={outdatedDateRange ? "date_range_accurate_data" : ""}
            alertMessagedynamicValues={{
              deploymentDate: moment.unix(deploymentDate).format("DD-MM-YYYY"),
            }}
          />
        </Box>
        <Box mb={5}>
          <StackedLineWidget
            title="agent_inbox_performance"
            titleToolTip={"agent_inbox_performance_tooltip"}
            data={orderObjectByArray(agentInboxPerformance, typesSorted)}
            areaStyle={true}
            isTopicSection
            hideLegend
            showDownloadIcon
            bigImg
            stackedLineWidgetPreLoaderDataSources={
              agentInboxPerformancePreLoader
            }
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
            isSwitchCase
            hidePercentage
            isSentimentAnalysis={false}
            dataPiechart={getHandleSortedArray(
              typesSorted,
              agentInboxPerformanceChart,
            )}
            labelColors={COLORS_ARR}
            colors={COLORS_ARR}
            isCityAnalysisWidget={true}
            showReportIcon={hasAppliedTimeFilter}
            reportIconTooltip={"time_picker_no_data_affect"}
          />
        </Box>
        <Box mb={5}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TableWidget
                wordsList
                showDownloadIcon
                title={"slas_hits_and_misses_table"}
                isSlasAgents
                titleToolTip={"slas_hits_and_misses_table_tooltip"}
                data={handleTableData()}
                leftColomnTitle={"slas_hits_and_misses_table_type"}
                rightColomnTitle={"avg_slas_table_column"}
                thirdColomnTitle={"table_hits_title"}
                fourthColomnTitle={"table_misses_title"}
                tableChartPreLoaderDataSources={hitsAndMissesPreLoader}
                preLoaderTrackerDataSources={preLoaderTrackerDataSources}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mb={5}>
          <AgentTabPerformanceTable
            agentPerformanceData={agentPerformance}
            agents={agents}
            statisticsPreLoaderDataSources={agentPerformancePreLoader}
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
            isLoading={isAgentsLoading}
          />
        </Box>
        <Box mb={5}>
          <AgentStatusSummaryTable
            agentStatusSummaryeData={agentStatusSummary}
            agents={agents}
            statisticsPreLoaderDataSources={agentStatusSummaryPreLoader}
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
            isLoading={isAgentsLoading}
            deploymentDate={deploymentDate}
            outdatedDateRange={outdatedDateRange}
            noFiltersHasBeenApplied={noFiltersHasBeenApplied}
          />
        </Box>
        <Box mb={5}>
          <Grid container spacing={3}>
            <Grid item md={6}>
              <PieChartWidget
                title="agents_disribution"
                titleToolTip="agents_disribution_tooltip"
                data={agentsDistribution}
                areaStyle={true}
                toolTipLabel="toolTipLabel"
                showDownloadIcon
                bigImg
                disableLocales
                pieChartPreLoaderDataSources={
                  isLoadingAgents < 2 ? [] : agentsDistributionPreLoader
                }
                preLoaderTrackerDataSources={preLoaderTrackerDataSources}
              />
            </Grid>
            <Grid item md={6}>
              <PieChartWidget
                title="agents_csat_score"
                titleToolTip="agents_csat_score_tooltip"
                data={csatScore}
                areaStyle={true}
                toolTipLabel="toolTipLabel"
                showDownloadIcon
                bigImg
                pieChartPreLoaderDataSources={csatScorePreLoader}
                preLoaderTrackerDataSources={preLoaderTrackerDataSources}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mb={5}>
          <StackedBarWidget
            title={"agents_csat_overtime"}
            titleToolTip="agents_csat_overtime_tooltip"
            showDownloadIcon
            bigImg
            posts={csatOverTime}
            showZoomPercentage
            postsColumnPreLoaderDataSources={csatOverTimePreLoader}
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
            showGroupByClientAgent={true}
            showScales
            labels={agentCsatLabels
              ?.map((item) => item?.name)
              ?.sort(
                (a, b) =>
                  csatOverTimeLegends?.map((item) => item?.name)?.indexOf(a) -
                  csatOverTimeLegends?.map((item) => item?.name)?.indexOf(b),
              )}
            hideLegend
            dataPiechart={csatOverTimeLegends}
            colors={agentCsatLabels
              ?.sort(
                (a, b) =>
                  csatOverTimeLegends
                    ?.map((item) => item?.name)
                    ?.indexOf(a?.name) -
                  csatOverTimeLegends
                    ?.map((item) => item?.name)
                    ?.indexOf(b?.name),
              )
              ?.map((item) => item?.value)}
          />
        </Box>
        <Box mb={5}>
          <AgentCsatFeedback
            unixDate={{ start: startDate, end: endDate }}
            title="agent_csat_feedback"
            titleToolTip="agent_csat_feedback_tooltip"
            agentCsatPage={agentCsatPage}
            handleChangePage={handleChangePage}
            agentCsatCount={agentCsatCount}
            data={agentCsatData}
            agentCsatLoading={agentCsatLoading}
            isAgentCsatPagination={isAgentCsatPagination}
          />
        </Box>
      </Box>
    </>
  );
};

export default AgentsTab;
