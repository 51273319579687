import Engagements from "../../../../../services/api/engagements";
import { useQuery } from "@tanstack/react-query";

export const useGetEngagementTags = ({ queryData }) => {
  return useQuery({
    queryKey: ["engagementTagsList", queryData],
    queryFn: () => Engagements.getEngagementTags(queryData),
    select: (data) => data?.data?.data,
  });
};
