import { useEffect, useMemo, useRef, useState } from "react";
import { Box } from "@mui/material";
import CircularLoading from "components/circularProgress";
import { checkIsDataFecthing } from "pages/channelAnalytics/InsightsPage/insightsCommonFunctions";
import EngagementsController from "services/controllers/engagementsController";
import StackedLineWidget from "components/widgets/stackedLineWidget";
import {
  getHandleSortedArray,
  handleAvgSLAs,
  handleBigTableFiltersAggregation,
  orderObjectByArray,
} from "../../sharedFunctions/aggregateFunctions";

import { displayRangeDate } from "../../sharedFunctions/datesUtils";
import AvgStatswWidget from "components/widgets/avgStatsWidget/avgStatsWidget";
import Header from "../header";
import PunchCardWidget from "components/widgets/punchCardWidget";
import { CheckValueLocale, handleMinMaxValues } from "utils/helpers";
import { AgentPerformanceTable } from "../../components/agentsPerfomanceTable";
import CompaniesController from "services/controllers/companiesController";
import DropdownFilter from "../../components/dropdownFilter/dropdownFilter";
import { useIntl } from "react-intl";

import {
  handleAggregateBarCharts,
  handleAggregateLegends,
  handleAggregatePunchCardCharts,
  handleAggregateLineChartMultipleValues,
} from "utils/helpers/aggregateFunctions";
import StackedBarWidget from "components/widgets/stackedBarWidget";

const COLORS_ARR = ["#304D7D", "#6CADE9"];

const SLAsTab = ({
  response,
  selectedTab,
  preLoaderTrackerDataSources,
  isLoading,
  resetAllFields,
  currDate,
  startDate,
  endDate,
  handleSeletedOptions,
  hasAppliedTimeFilter,
}) => {
  const intl = useIntl();
  const [excelSheetData, setExcelSheetData] = useState([]);
  const [checkAllDataFetchingExport, setCheckAllDataFetchingExport] =
    useState(false);
  const [hitsActivity, setHitsActivity] = useState([]);
  const [
    hitsActivityPreLoaderDataSources,
    setHitsActivityPreLoaderDataSources,
  ] = useState([]);
  const [minValueHitsActivity, setMinValueHitsActivity] = useState(0);
  const [maxValueHitsActivity, setMaxValueHitsActivity] = useState(0);

  const [missesActivity, setMissesActivity] = useState([]);
  const [
    missesActivityPreLoaderDataSources,
    setMissesActivityPreLoaderDataSources,
  ] = useState([]);
  const [minValueMissesActivity, setMinValueMissesActivity] = useState(0);
  const [maxValueMissesActivity, setMaxValueMissesActivity] = useState(0);
  const [slaTimeDistribution, setSlaTimeDistribution] = useState([]);
  const [slaTimeDistributionLegends, setSlaTimeDistributionLegends] = useState(
    [],
  );

  const [
    slaTimeDistributionPreLoaderDataSources,
    setSlaTimeDistributionPreLoaderDataSources,
  ] = useState([]);

  const [engagOvertimeChart, setEngagOvertimeChart] = useState([]);
  const [engagVolume, setEngagVolume] = useState([]);
  const [engagVolumePreLoader, setEngagVolumePreLoader] = useState([]);
  const [avgStatsData, setAvgStatsData] = useState([]);
  const [avgStatsPrevDates, setAvgStatsPrevDates] = useState({
    prevStartDate: 0,
    prevEndDate: 0,
  });
  const [avgStatsDataPreLoader, setAvgStatsDataPreLoader] = useState([]);
  // Avergae SLAs Performance Table
  const [agentPerformance, setAgentPerformance] = useState({});
  const [agentPerformancePreLoader, setAgentPerformancePreLoader] = useState(
    [],
  );
  // Handling the apis for the sorting
  const [teams, setTeams] = useState([]);
  const [agents, setAgents] = useState([]);
  const [routings, setRoutings] = useState([]);
  const [slas, setSlas] = useState([]);
  const [loadingApis, setLoadingApis] = useState({
    teams: true,
    agents: true,
    routings: true,
    slas: true,
  });
  const isAllApisLoading = useMemo(() => {
    return Object.values(loadingApis)?.some((value) => value === true);
  }, [loadingApis]);

  // DDL for SLAs
  const [selectedSlas, setSelectedSlas] = useState([]);
  const appliedSelectedSlas = useRef([]);
  const isSlasLoading = loadingApis?.slas;

  let typesSorted = ["hits", "misses"];

  const prevDate = displayRangeDate(
    avgStatsPrevDates?.prevStartDate,
    avgStatsPrevDates?.prevEndDate,
  );

  const handleDropdwonChange = (newValue) => {
    setSelectedSlas(newValue);
  };
  const handleDropdownApply = () => {
    appliedSelectedSlas.current = selectedSlas;
    handleSeletedOptions(
      "slas_ids",
      selectedSlas?.map((item) => item?.id)?.join(","),
    );
  };
  const handleDropdownClose = () => {
    setSelectedSlas(appliedSelectedSlas.current);
  };

  useEffect(() => {
    switch (response?.eventName) {
      case "Twitter__EngagementAnalyticsV2Page__SLA_PAGE__average_slas":
      case "Twitterprivate__EngagementAnalyticsV2Page__SLA_PAGE__average_slas":
      case "Instagram__EngagementAnalyticsV2Page__SLA_PAGE__average_slas":
      case "Facebook__EngagementAnalyticsV2Page__SLA_PAGE__average_slas":
      case "Facebookprivate__EngagementAnalyticsV2Page__SLA_PAGE__average_slas":
      case "Instagramprivate__EngagementAnalyticsV2Page__SLA_PAGE__average_slas":
      case "Whatsappprivate__EngagementAnalyticsV2Page__SLA_PAGE__average_slas":
        const aggAvgStatsData = handleAvgSLAs(
          avgStatsData,
          response?.eventData?.data,
        );
        const prevStartData =
          response?.eventData?.data?.previous_period?.previous_start_date ||
          avgStatsPrevDates?.prevStartDate;
        const prevEndData =
          response?.eventData?.data?.previous_period?.previous_end_date ||
          avgStatsPrevDates?.prevEndDate;
        setAvgStatsData(aggAvgStatsData);
        setAvgStatsPrevDates({
          prevStartDate: prevStartData,
          prevEndDate: prevEndData,
        });
        setAvgStatsDataPreLoader((prev) => [...prev, response?.data_source]);
        break;

      case "Twitter__EngagementAnalyticsV2Page__hit_or_miss_engagements_overtime":
      case "Facebook__EngagementAnalyticsV2Page__hit_or_miss_engagements_overtime":
      case "Twitterprivate__EngagementAnalyticsV2Page__hit_or_miss_engagements_overtime":
      case "Facebookprivate__EngagementAnalyticsV2Page__hit_or_miss_engagements_overtime":
      case "Instagram__EngagementAnalyticsV2Page__hit_or_miss_engagements_overtime":
      case "Instagramprivate__EngagementAnalyticsV2Page__hit_or_miss_engagements_overtime":
      case "Whatsappprivate__EngagementAnalyticsV2Page__hit_or_miss_engagements_overtime":
        handleAggregateLegends(
          response?.eventData?.data?.engagements_overtime_legends,
          engagOvertimeChart,
        );

        handleAggregateLineChartMultipleValues(
          response?.eventData?.data?.posts_over_time,
          engagVolume,
          setEngagVolume,
        );

        setEngagVolumePreLoader((oldArray) => [
          ...oldArray,
          response?.data_source,
        ]);
        break;
      case "Twitter__EngagementAnalyticsV2Page__hits_activity":
      case "Twitterprivate__EngagementAnalyticsV2Page__hits_activity":
      case "Facebook__EngagementAnalyticsV2Page__hits_activity":
      case "Facebookprivate__EngagementAnalyticsV2Page__hits_activity":
      case "Instagram__EngagementAnalyticsV2Page__hits_activity":
      case "Instagramprivate__EngagementAnalyticsV2Page__hits_activity":
      case "Whatsappprivate__EngagementAnalyticsV2Page__hits_activity":
        handleAggregatePunchCardCharts(
          response?.eventData?.hits_activity,
          hitsActivity,
        );
        handleMinMaxValues(
          response?.eventData?.max_value,
          undefined,
          setMaxValueHitsActivity,
          maxValueHitsActivity,
          undefined,
          "max",
        );
        handleMinMaxValues(
          response?.eventData?.min_value,
          undefined,
          setMinValueHitsActivity,
          minValueHitsActivity,
          undefined,
          "min",
        );
        setHitsActivityPreLoaderDataSources([
          ...hitsActivityPreLoaderDataSources,
          response?.data_source,
        ]);
        break;
      case "Twitter__EngagementAnalyticsV2Page__missess_activity":
      case "Twitterprivate__EngagementAnalyticsV2Page__missess_activity":
      case "Facebook__EngagementAnalyticsV2Page__missess_activity":
      case "Facebookprivate__EngagementAnalyticsV2Page__missess_activity":
      case "Instagram__EngagementAnalyticsV2Page__missess_activity":
      case "Instagramprivate__EngagementAnalyticsV2Page__missess_activity":
      case "Whatsappprivate__EngagementAnalyticsV2Page__missess_activity":
        handleAggregatePunchCardCharts(
          response?.eventData?.missess_activity,
          missesActivity,
        );
        handleMinMaxValues(
          response?.eventData?.max_value,
          undefined,
          setMaxValueMissesActivity,
          maxValueMissesActivity,
          undefined,
          "max",
        );
        handleMinMaxValues(
          response?.eventData?.min_value,
          undefined,
          setMinValueMissesActivity,
          minValueMissesActivity,
          undefined,
          "min",
        );
        setMissesActivityPreLoaderDataSources([
          ...missesActivityPreLoaderDataSources,
          response?.data_source,
        ]);
        break;

      case "Facebook__EngagementAnalyticsV2Page__SLA_PAGE__agents_performance":
      case "Facebookprivate__EngagementAnalyticsV2Page__SLA_PAGE__agents_performance":
      case "Twitter__EngagementAnalyticsV2Page__SLA_PAGE__agents_performance":
      case "Twitterprivate__EngagementAnalyticsV2Page__SLA_PAGE__agents_performance":
      case "Instagram__EngagementAnalyticsV2Page__SLA_PAGE__agents_performance":
      case "Instagramprivate__EngagementAnalyticsV2Page__SLA_PAGE__agents_performance":
      case "Whatsappprivate__EngagementAnalyticsV2Page__SLA_PAGE__agents_performance":
        const isAgentsPreLoadersLoaded =
          preLoaderTrackerDataSources?.length &&
          agentPerformancePreLoader?.length ===
            preLoaderTrackerDataSources?.length - 1;
        const newAgentsPerformance = handleBigTableFiltersAggregation(
          response?.eventData,
          agentPerformance,
          isAgentsPreLoadersLoaded,
        );
        setAgentPerformance(newAgentsPerformance);
        setAgentPerformancePreLoader((prev) => [
          ...prev,
          response?.data_source,
        ]);
        break;
      case "Twitter__EngagementAnalyticsV2Page__slas_time_distribution":
      case "Twitterprivate__EngagementAnalyticsV2Page__slas_time_distribution":
      case "Facebook__EngagementAnalyticsV2Page__slas_time_distribution":
      case "Facebookprivate__EngagementAnalyticsV2Page__slas_time_distribution":
      case "Instagram__EngagementAnalyticsV2Page__slas_time_distribution":
      case "Instagramprivate__EngagementAnalyticsV2Page__slas_time_distribution":
      case "Whatsappprivate__EngagementAnalyticsV2Page__slas_time_distribution":
        handleAggregateBarCharts(
          response?.eventData?.data?.sla_categories,
          slaTimeDistribution,
        );
        handleAggregateLegends(
          response?.eventData?.data?.sla_legends,
          slaTimeDistributionLegends,
        );

        setSlaTimeDistributionPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response?.data_source,
        ]);
        break;

      default:
        break;
    }
  }, [response]);

  // handle excel data
  useEffect(() => {
    let totalPreLoadersDataSources = [
      avgStatsDataPreLoader,
      engagVolumePreLoader,
      hitsActivityPreLoaderDataSources,
      missesActivityPreLoaderDataSources,
      agentPerformancePreLoader,
    ]; // all preloader  added here

    const isAllPreLoadersLoaded = checkIsDataFecthing(
      totalPreLoadersDataSources,
      preLoaderTrackerDataSources,
    );

    setCheckAllDataFetchingExport(isAllPreLoadersLoaded);
    const excelData = {
      avgSla: {
        data: avgStatsData,
        currDate,
        prevDate,
      },
      engagVolume: engagVolume,
      avgPerformanceAgents: {
        data: agentPerformance?.agents,
        agents,
      },
      avgPerformanceRoutings: {
        data: agentPerformance?.routings,
        routings,
      },
      avgPerformanceSlas: {
        data: agentPerformance?.slas,
        slas,
      },
      avgPerformanceTeams: {
        data: agentPerformance?.teams,
        teams,
      },
      slaTimeDistribution: slaTimeDistribution,
      hitsActivity: [hitsActivity, minValueHitsActivity, maxValueHitsActivity],
      missesActivity: [
        missesActivity,
        minValueMissesActivity,
        maxValueMissesActivity,
      ],
    };
    setExcelSheetData(excelData);
  }, [
    avgStatsDataPreLoader,
    engagVolume,
    engagVolumePreLoader,
    avgStatsData,
    hitsActivity,
    maxValueHitsActivity,
    minValueHitsActivity,
    missesActivity,
    maxValueMissesActivity,
    minValueMissesActivity,
    hitsActivityPreLoaderDataSources,
    missesActivityPreLoaderDataSources,
    agentPerformance,
    agentPerformancePreLoader,
    slaTimeDistribution,
    slaTimeDistributionPreLoaderDataSources,
  ]);

  // reset all states
  useEffect(() => {
    if (resetAllFields !== 1) {
      setAvgStatsDataPreLoader([]);
      setAvgStatsPrevDates({ prevStartDate: 0, prevEndDate: 0 });
      setAvgStatsData([]);
      setEngagVolume([]);
      setEngagOvertimeChart([]);
      setEngagVolumePreLoader([]);
      setHitsActivity([]);
      setMissesActivity([]);
      setMaxValueHitsActivity(0);
      setMinValueHitsActivity(0);
      setMaxValueMissesActivity(0);
      setMinValueMissesActivity(0);
      setHitsActivityPreLoaderDataSources([]);
      setMissesActivityPreLoaderDataSources([]);
      setAgentPerformance([]);
      setAgentPerformancePreLoader([]);
      setSlaTimeDistribution([]);
      setSlaTimeDistributionLegends([]);
      setSlaTimeDistributionPreLoaderDataSources([]);
    }
  }, [resetAllFields]);

  useEffect(() => {
    const getAgents = () => {
      EngagementsController.getUsers(window.localStorage.engagements_id).then(
        (res) => {
          if (!res?.errorMsg && res?.data?.data?.length) {
            const agents = res?.data?.data.map((agent) => ({
              ...(agent?.attributes || {}),
            }));
            setAgents(agents);
          }
          setLoadingApis((prev) => ({ ...prev, agents: false }));
        },
      );
    };

    const getSLAs = () => {
      EngagementsController.getCompanySLAs().then((res) => {
        if (!res?.errorMsg && res?.data?.data?.length) {
          const slas = res?.data?.data?.map((agent) => ({
            ...(agent?.attributes || {}),
          }));
          setSlas(slas);
        }
        setLoadingApis((prev) => ({ ...prev, slas: false }));
      });
    };

    const getTeams = () => {
      CompaniesController.getAllCompanyTeams().then((res) => {
        if (!res?.errorMsg && res?.data?.teams?.length) {
          setTeams(res?.data?.teams);
        }

        setLoadingApis((prev) => ({ ...prev, teams: false }));
      });
    };
    const getRouting = () => {
      CompaniesController.getAllCompanyRoutings(
        window?.localStorage?.engagements_id,
      ).then((res) => {
        if (!res?.errorMsg && res?.data?.data?.length) {
          const routings = res?.data?.data?.map((agent) => ({
            ...(agent?.attributes || {}),
            id: agent?.id,
          }));

          setRoutings(routings);
        }
        setLoadingApis((prev) => ({ ...prev, routings: false }));
      });
    };

    getAgents();
    getSLAs();
    getTeams();
    getRouting();
  }, []);

  if (isLoading) {
    return <CircularLoading />;
  }

  return (
    <>
      <Header
        selectedTab={selectedTab}
        checkAllDataExist={checkAllDataFetchingExport}
        excelSheetData={excelSheetData}
        checkDataIsEmpty={
          avgStatsData?.find((obj) => obj?.id === "hits_count")?.currValue >
            0 ||
          avgStatsData?.find((obj) => obj?.id === "misses_count")?.currValue > 0
        }
        startDate={startDate}
        endDate={endDate}
      />
      <Box className="engag-dashboard-widgets-container">
        <Box mb={5}>
          <DropdownFilter
            options={slas}
            value={selectedSlas}
            label={CheckValueLocale("avg_select_sla", "", {}, intl)}
            emptyListMessage={CheckValueLocale("avg_no_sla", "", {}, intl)}
            onChange={handleDropdwonChange}
            onApply={handleDropdownApply}
            onClose={handleDropdownClose}
            selectedOpitonsNum={appliedSelectedSlas.current?.length}
            disabled={isSlasLoading}
          />
        </Box>
        <Box mb={5}>
          <AvgStatswWidget
            title="avg_slas_overview"
            titleToolTip="avg_slas_overview_desc"
            chartId="insight-tab-avg-slas"
            statsData={avgStatsData}
            dates={{
              currDate: currDate,
              prevDate: prevDate,
            }}
            statisticsPreLoaderDataSources={avgStatsDataPreLoader}
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          />
        </Box>
        <Box mb={5}>
          <StackedLineWidget
            title={"engag_overtime"}
            titleToolTip={"engag_overtime_tooltip"}
            data={orderObjectByArray(engagVolume, typesSorted)}
            areaStyle={true}
            isTopicSection
            hideLegend
            showDownloadIcon
            bigImg
            stackedLineWidgetPreLoaderDataSources={engagVolumePreLoader}
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
            isSwitchCase
            hidePercentage
            isSentimentAnalysis={false}
            showGroupByClientAgent={true}
            dataPiechart={getHandleSortedArray(typesSorted, engagOvertimeChart)}
            labelColors={COLORS_ARR}
            colors={COLORS_ARR}
            isCityAnalysisWidget={true}
            showReportIcon={hasAppliedTimeFilter}
            reportIconTooltip={"time_picker_no_data_affect"}
          />
        </Box>
        <Box mb={5}>
          <AgentPerformanceTable
            agentPerformanceData={agentPerformance}
            agents={agents}
            teams={teams}
            routings={routings}
            slas={slas}
            statisticsPreLoaderDataSources={agentPerformancePreLoader}
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
            isLoading={isAllApisLoading}
          />
        </Box>
        <Box mb={5}>
          <StackedBarWidget
            title="slas_time_distribution"
            titleToolTip="slastime_distribution_tooltip"
            labels={slaTimeDistributionLegends}
            isQuickExplore
            posts={slaTimeDistribution}
            showDownloadIcon
            bigImg
            showGroupBy={false}
            isInteractionStatsWrapperStyle
            showPostsInteractionStats
            hideLegend
            xAxisValue
            colors={["#304D7D", "#5692D7", "#A1CFF3", "#C3E1F8"]}
            dataPiechart={slaTimeDistributionLegends}
            showLogarithmic
            postsColumnPreLoaderDataSources={
              slaTimeDistributionPreLoaderDataSources
            }
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
            hidePercentage
          />
        </Box>
        <Box mb={5}>
          <PunchCardWidget
            title="hits_activity"
            titleToolTip="hits_activity_tooltip"
            clientActivity={hitsActivity}
            minValueClient={minValueHitsActivity}
            maxValueClient={maxValueHitsActivity}
            showDownloadIcon
            bigImg
            authorActivityPreLoaderDataSources={
              hitsActivityPreLoaderDataSources
            }
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          />
        </Box>
        <Box mb={5}>
          <PunchCardWidget
            title="misses_activity"
            titleToolTip="misses_activity_tooltip"
            clientActivity={missesActivity}
            minValueClient={minValueMissesActivity}
            maxValueClient={maxValueMissesActivity}
            showDownloadIcon
            bigImg
            danger
            authorActivityPreLoaderDataSources={
              missesActivityPreLoaderDataSources
            }
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          />
        </Box>
      </Box>
    </>
  );
};

export default SLAsTab;
