import { useIntl } from "react-intl";
import { Box, Container, Typography } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import "./details.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import LucButton from "shared/lucButton/lucButton";
import { useUpdateAgentStatus } from "shared/mainHeader/mainHeaderHooks/useUpdateAgentStatus";
import { memo, useLayoutEffect, useRef, useState } from "react";
import SnackBar from "components/snackBar";
import UserAvatar from "shared/unifiedUserImage/userAvatar";
import { useGetCurrentAgentStatus } from "shared/mainHeader/mainHeaderHooks/useGetAgentCurrentStatus";
import SuccessAnimationImg from "./success-animation.webm";
// make the SuccessAnimation with memo

const SuccessAnimation = memo(() => {
  return (
    <video
      className="no-data-engagement-available-animation"
      autoPlay
      muted
      playsInline
    >
      <source src={SuccessAnimationImg} type="video/webm" />
      {/* Fallback source if the browser doesn't support WebM */}
      <source src={SuccessAnimationImg} type="video/mp4" />
    </video>
  );
});

const animationTextClassName = "animate-data-text";

const NoDataDetails = ({ fetchData }) => {
  const intl = useIntl();
  const textAnimationRef = useRef(null);
  const isMounted = useRef(false);
  const userFullName = localStorage.getItem("name");
  const avatar = localStorage.getItem("avatar");
  const [snackbarState, setSnackbarState] = useState({
    message: "",
    severity: "",
    title: "",
    openSnackBar: false,
  });

  const handleCloseSnackBar = () => {
    setSnackbarState({
      openSnackBar: false,
    });
  };

  const { data: agentStatus } = useGetCurrentAgentStatus({
    enabled: false,
  });

  const { mutate: setAgentAvailable } = useUpdateAgentStatus();
  const setAgentStatusAvailable = () => {
    setAgentAvailable(
      { status: "available" },
      {
        onSuccess: () => {
          let title = `agent_status_snackbar_available_title`;
          let message = "agent_available_status_snackbar";
          setSnackbarState({
            message,
            title,
            openSnackBar: true,
            severity: "info",
          });
        },
        onError: () => {
          setSnackbarState({
            message: "try_again_error_message",
            severity: "error",
            title: "failed_error_message",
            openSnackBar: true,
          });
        },
      },
    );
  };

  useLayoutEffect(() => {
    if (!agentStatus) return;
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }
    if (agentStatus === "available") {
      textAnimationRef.current?.classList?.add?.(animationTextClassName);
      setTimeout(() => {
        textAnimationRef.current?.classList?.remove?.(animationTextClassName);
      }, 1000);
    }
  }, [agentStatus]);
  return (
    <Container maxWidth="xl" className="no-data-details-container">
      <Box className="no-data-details-wrapper">
        {!fetchData ? (
          <Box className="no-data-text" ref={textAnimationRef}>
            <Box className="user-avatar-animation-container">
              <UserAvatar
                avatarSrc={avatar}
                fullName={userFullName}
                showStatusIndicator={agentStatus !== "available" ? true : false}
                statusIndicatorValue={agentStatus}
                sizes={{ ratio: "80px" }}
              />
              {agentStatus === "available" ? (
                <Box className="success-assign-animation">
                  <SuccessAnimation />
                </Box>
              ) : null}
            </Box>

            <Typography
              variant="h2"
              className={`text-heading ${agentStatus === "available" ? "available" : ""}`}
            >
              {agentStatus === "available" ? (
                <Typography variant="h2" className="text-heading-title">
                  {CheckValueLocale(
                    "engagement_no_data_active_agent_guide_title",
                    "",
                    {},
                    intl,
                  )}
                </Typography>
              ) : null}
              {CheckValueLocale(
                agentStatus === "available"
                  ? "engagement_no_data_active_agent_guide_msg"
                  : "engagement_no_data_set_active_guide",
                "",
                {},
                intl,
              )}
            </Typography>
            {agentStatus !== "available" ? (
              <LucButton
                type="primary"
                size="large"
                id="set-yourself-to-available-no-data-button"
                onClick={() => setAgentStatusAvailable()}
              >
                {CheckValueLocale(
                  "engagement_no_data_set_active_button",
                  "",
                  {},
                  intl,
                )}
              </LucButton>
            ) : null}
          </Box>
        ) : (
          <Box className="pagination-loader">
            <FontAwesomeIcon icon={faSpinnerThird} spin />
            {CheckValueLocale("loading_engagement", "", {}, intl)}
          </Box>
        )}
        <SnackBar
          open={snackbarState?.openSnackBar}
          severity={snackbarState?.severity}
          message={CheckValueLocale(snackbarState?.message, "", {}, intl)}
          title={CheckValueLocale(snackbarState?.title, "", {}, intl)}
          handleClose={handleCloseSnackBar}
        />
      </Box>
    </Container>
  );
};
export default NoDataDetails;
