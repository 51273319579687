import { Box } from "@mui/material";
import { ReportContext } from "pages/reports/pages/viewNewsLetter/context/reportContext";
import { useContext } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import "./coverPdfNewsLetter.scss";
import { dateHourFormat } from "pages/reports/utils/formatDateUtlit";

const ItemMonitorReport = ({ label, value }) => {
  const intl = useIntl();
  return (
    <Box component={"li"}>
      <Box component={"span"}>
        {`${CheckValueLocale(label, "", {}, intl)}: `}{" "}
      </Box>
      <Box component={"span"}>{value} </Box>
    </Box>
  );
};

const CoverPdfNewsLetter = () => {
  const intl = useIntl();
  const { reportData, activeMonitor } = useContext(ReportContext);
  const { report_name, reportType, startDate, endDate, isUnixDate } =
    reportData;

  return (
    <Box className="pdf-cover-news-letter">
      <Box className="main-title-pdf-cover-news-letter">
        <Box className="title">
          {`"${report_name}" `}
          {CheckValueLocale("reports_newsletter", "", {}, intl)}
        </Box>
        <Box className="description">
          {CheckValueLocale("newsletter_report_description", "", {}, intl)}
        </Box>
      </Box>

      <Box component={"ul"} className="details-active-monitor-report">
        <ItemMonitorReport label={"monitor_name"} value={activeMonitor?.name} />
        <ItemMonitorReport
          label={"number_of_articles"}
          value={activeMonitor?.count_articles}
        />
        <ItemMonitorReport
          label={"frequency"}
          value={CheckValueLocale(reportType?.toLowerCase(), "", {}, intl)}
        />
        <ItemMonitorReport
          label={"date_period"}
          value={`${dateHourFormat(
            startDate,
            intl?.locale,
            isUnixDate,
          )} ${CheckValueLocale("to_lang", "", {}, intl)} ${dateHourFormat(
            endDate,
            intl?.locale,
            isUnixDate,
          )} `}
        />
      </Box>
    </Box>
  );
};

export default CoverPdfNewsLetter;
