import PopupModal from "components/popupModal";
import "./popupToEnable2FA.scss";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import PopupHeader from "./popupHeader/popupHeader";
import TextContent2FA from "./popupBody/TextContent2FA/TextContent2FA";
import { Box } from "@mui/material";
import SixDigitsCodeInput from "./popupBody/sixDigitsCodeInput/sixDigitsCodeInput";
import ResendCode from "./popupBody/resendCode/resendCode";
import { useEffect, useState } from "react";
import PhoneNumberInput from "./popupBody/phoneNumberInput/phoneNumberInput";
import QRTwoFactorAuthCode from "./popupBody/qrTwoFactorAuthCode/QRTwoFactorAuthCode";

const PopupToEnable2FA = ({
  modelData,
  handleClose,
  handleAcceptPopup,
  codeVerify,
  setCodeVerify,
  setErrorCodeMsg,
  errorCodeMsg,
  popupBody,
  isBtnLoading,
  isLoadingResendCode,
  setPhoneNumber,
  handleResendCode,
  showQRcode,
  setShowQRcode,
  disableButtonStatus,
  enableDivider,
}) => {
  const intl = useIntl();
  const [disabledBtn, setDisabledBtn] = useState(false);

  useEffect(() => {
    if (!modelData?.open) {
      setDisabledBtn(false);
    }
  }, [modelData?.open]);

  const modalBody = (
    <Box id="body-popup-two-factor-auth">
      <TextContent2FA
        bodyText={modelData?.bodyText}
        contact={modelData?.contact}
      />
      {modelData?.showSixCodeInput ? (
        <SixDigitsCodeInput
          setCodeVerify={setCodeVerify}
          codeVerify={codeVerify}
          errorCodeMsg={errorCodeMsg}
          setErrorCodeMsg={setErrorCodeMsg}
        />
      ) : null}
      {modelData?.showResendCodeBtn ? (
        <ResendCode
          textDisplay={"code_expires_in"}
          expiredDate={modelData?.expires_at}
          handleResendCode={handleResendCode}
          loadingBtn={isLoadingResendCode}
        />
      ) : null}

      {modelData?.showPhoneNumberInput ? (
        <PhoneNumberInput
          setPhoneNumber={setPhoneNumber}
          setDisabledBtn={setDisabledBtn}
        />
      ) : null}

      {modelData?.qr_code ? (
        <QRTwoFactorAuthCode
          qrCode={modelData?.qr_code}
          totpSecret={modelData?.totp_secret}
          showQRcode={showQRcode}
          setShowQRcode={setShowQRcode}
        />
      ) : null}
    </Box>
  );

  return (
    <PopupModal
      title={
        <PopupHeader step={modelData?.step} titleKey={modelData?.headerTitle} />
      }
      body={popupBody ? popupBody : modalBody}
      leftBtn={CheckValueLocale("cancel", "", {}, intl)}
      rightBtn={CheckValueLocale(modelData?.textButton, "", {}, intl)}
      isRightBtnLoading={isBtnLoading}
      disabled={
        (modelData?.showSixCodeInput && codeVerify?.length !== 6) ||
        errorCodeMsg ||
        disableButtonStatus || //this is used to control whether the button is disabled or not in enable and edit cases
        disabledBtn // to phone number
      }
      open={modelData?.open || false}
      warning={modelData?.warning}
      close={handleClose}
      accept={handleAcceptPopup}
      addClasses={"popup-to-enable-two-factor-auth"}
      enableDivider={enableDivider}
    />
  );
};

export default PopupToEnable2FA;
