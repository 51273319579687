import moment from "moment";
import { isEmptyArray } from "utils/helpers";
import {
  handleAuthorActivity,
  handleXlsData,
  handleSortedCase,
} from "../excelCommonFn";

const engagements_fb_AA = (response, lang) => {
  if (isEmptyArray(response)) {
    return [];
  } else {
    var postsVolume = [],
      sentimentAnalysis = [],
      topKeywords = [],
      topHashtags = [],
      authorActivity = [],
      dialects = [],
      subDialects = [],
      topComments = [],
      allSheetData = [];
    //_______________________________________________________________________________
    response?.map((i) => {
      Object.entries(i)?.forEach(([key, value]) => {
        switch (key) {
          case "postsVolume":
            value?.map((i) => {
              postsVolume?.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.posts_word]: i?.value,
              });
            });
            allSheetData?.push([postsVolume, lang?.comments_volume]);
            break;
          // _______________________________________________________________________________

          case "topComments":
            value?.map((i) => {
              topComments?.push({ [lang.links]: i?.tweet_link });
            });
            allSheetData?.push([topComments, lang?.top_comments_mentions]);

            break;
          //_______________________________________________________________________________

          case "dialects":
            handleSortedCase(value, dialects, lang.dialect, lang.number);
            allSheetData?.push([dialects, lang?.main_dialect_filter]);
            break;
          //_______________________________________________________________________________

          case "subDialects":
            handleSortedCase(
              value,
              subDialects,
              lang.sub_dialects,
              lang.number,
            );
            allSheetData?.push([subDialects, lang?.sub_dialects]);
            break;

          //_______________________________________________________________________________

          case "sentimentAnalysis":
            value?.negative?.map((i, index) => {
              if (i.name == value.neutral[index].name) {
                let total =
                  value.neutral[index].value +
                  value.positive[index].value +
                  value.negative[index].value;
                sentimentAnalysis?.push({
                  [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                  [lang.neutral]:
                    total !== 0
                      ? ((value.neutral[index].value / total) * 100).toFixed(
                          2,
                        ) + "%"
                      : 0,
                  [lang.positive]:
                    total !== 0
                      ? ((value.positive[index].value / total) * 100).toFixed(
                          2,
                        ) + "%"
                      : 0,
                  [lang.negative]:
                    total !== 0
                      ? ((value.negative[index].value / total) * 100).toFixed(
                          2,
                        ) + "%"
                      : 0,
                });
              }
            });
            allSheetData?.push([
              sentimentAnalysis,
              lang.comments_sentiment_analysis,
            ]);
            break;

          //_______________________________________________________________________________

          case "topKeywords":
            value?.map((i) => {
              topKeywords?.push({
                [lang.keywords]: i?.id,
                [lang.xlsx_number_of_posts]: i?.stats_count,
              });
            });
            allSheetData?.push([
              topKeywords,
              lang?.posts_top_keywords_xlsx_sheetname,
            ]);

            break;
          // _______________________________________________________________________________

          case "topHashtags":
            value?.map((i) => {
              topHashtags?.push({
                [lang.xlsx_hashtags]: i?.id,
                [lang.xlsx_number_of_posts]: i?.stats_count,
              });
            });
            allSheetData?.push([
              topHashtags,
              lang?.comments_top_hashtags_xlsx_sheetname,
            ]);

            break;
          // _______________________________________________________________________________

          case "authorActivity":
            handleAuthorActivity(value[0], value[1], value[2], lang)?.map(
              (i) => {
                authorActivity?.push({
                  [lang.day]: i[0],
                  [lang.hour_group]: i[1],
                  [lang.no_of_activity]: i[2],
                });
              },
            );
            allSheetData?.push([authorActivity, lang?.engagers_activity]);

            break;
          //_______________________________________________________________________________

          default:
            break;
        }
      });
    });

    return handleXlsData(allSheetData);
  }
};
export { engagements_fb_AA };
