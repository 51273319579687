export const surveyValidation = (surveyData) => {
  let surveyDataSorceLimitError = "";
  let surveySelectedData = surveyData?.selectedData;
  let updatedSurveyData = { ...surveyData };
  let queryDataFormat = { engagement_surveys: {} };

  Object.keys(surveySelectedData)?.map((dataSorce) => {
    let dataSorceSelectedData = surveySelectedData?.[dataSorce];
    if (
      !surveyDataSorceLimitError?.length &&
      dataSorceSelectedData?.isEnabled
    ) {
      if (dataSorceSelectedData?.sendAs == 1) {
        if (
          dataSorceSelectedData?.thankMsg_en?.trim()?.length >=
          surveyData?.mainData?.[`${dataSorce}_limit`]
        ) {
          surveyDataSorceLimitError = `${dataSorce}_thankMsg_en_error`;
        } else if (
          dataSorceSelectedData?.thankMsg_ar?.trim()?.length >=
          surveyData?.mainData?.[`${dataSorce}_limit`]
        ) {
          surveyDataSorceLimitError = `${dataSorce}_thankMsg_ar_error`;
        } else if (
          dataSorceSelectedData?.expiredMsg_en?.trim()?.length >=
          surveyData?.mainData?.[`${dataSorce}_limit`]
        ) {
          surveyDataSorceLimitError = `${dataSorce}_expiredMsg_en_error`;
        } else if (
          dataSorceSelectedData?.expiredMsg_ar?.trim()?.length >=
          surveyData?.mainData?.[`${dataSorce}_limit`]
        ) {
          surveyDataSorceLimitError = `${dataSorce}_expiredMsg_ar_error`;
        }
      } else {
        const activeProducts = JSON.parse(
          window?.localStorage?.activeProducts || null,
        );
        const surveyActive = activeProducts?.find(
          (product) => product?.name == "SURVEY",
        );
        if (!!surveyActive) {
          if (!dataSorceSelectedData?.surveys_engag) {
            surveyDataSorceLimitError = `${dataSorce}_surveys_engag_error`;
          }
        } else {
          updatedSurveyData.selectedData[dataSorce].isEnabled = false;
        }
      }
    }
  });
  Object.keys(updatedSurveyData?.selectedData)?.map((dataSorce) => {
    let dataSorceSelectedData = updatedSurveyData?.selectedData?.[dataSorce];
    queryDataFormat.engagement_surveys[dataSorce.toUpperCase()] = {
      survey_link: {
        id: dataSorceSelectedData?.surveys_engag
          ? +dataSorceSelectedData?.surveys_engag
          : null,
        delivery_mode: dataSorceSelectedData?.delivery_method_engag_survey,
      },
      is_farewell_survey_enabled: dataSorceSelectedData?.isEnabled,
      csat_survey: {
        is_csat_enabled: +dataSorceSelectedData?.sendAs === 1,
        csat_config: {
          expiry_time: +dataSorceSelectedData?.expiry_time_engag,
          send_survey_in: dataSorceSelectedData?.send_survey_in_engag,
          delivery_method: dataSorceSelectedData?.delivery_method_engag,
          resend_attempts: +dataSorceSelectedData?.resend_attempts_engag,
          invalid_responses: dataSorceSelectedData?.invalid_responses_engag,
          unsupported_languages:
            dataSorceSelectedData?.unsupported_languages_engag,
        },
        thank_message: {
          en: dataSorceSelectedData?.thankMsg_en?.trim(),
          ar: dataSorceSelectedData?.thankMsg_ar?.trim(),
        },
        expiry_message: {
          en: dataSorceSelectedData?.expiredMsg_en?.trim(),
          ar: dataSorceSelectedData?.expiredMsg_ar?.trim(),
        },

        predefined_question: {
          feedback: {
            en: +dataSorceSelectedData?.feed_qs_en,
            ar: +dataSorceSelectedData?.feed_qs_ar,
          },
          rating: {
            en: +dataSorceSelectedData?.rate_qs_en,
            ar: +dataSorceSelectedData?.rate_qs_ar,
          },
        },
      },
    };
  });
  updatedSurveyData = { ...queryDataFormat };
  return { surveyDataSorceLimitError, updatedSurveyData };
};
