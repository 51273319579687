import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ReportServices } from "services/api/reports";

export const useCreateReport = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ queryData }) => ReportServices.createReport(queryData),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["reportsList"] });
    },
  });
};
