import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { faAngleRight, faAngleLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ViewReportContext } from "pages/reports/context/reportContext";
import LucButton from "shared/lucButton/lucButton";

const ViewReportHeader = () => {
  const { reportInfo } = useContext(ViewReportContext);

  const navigate = useNavigate();

  const intl = useIntl();

  const { title, frequency } = reportInfo || {};

  return (
    <Box className="view-report-page-header-wrapper">
      <Typography variant="h2" className="view-report-header-title">
        {title || ""}
        {!!frequency ? (
          <Box component="span" className="view-report-header-frequency-chip">
            {CheckValueLocale(
              `report_${frequency?.toLowerCase()}`,
              "",
              {},
              intl,
            )}
          </Box>
        ) : null}
      </Typography>
      <LucButton
        type="secondary"
        size="small"
        variant="flat"
        endIcon={
          <FontAwesomeIcon
            icon={intl?.locale === "ar" ? faAngleLeft : faAngleRight}
          />
        }
        onClick={() => navigate(`/reports/list`)}
      >
        {CheckValueLocale("back_to_reports", "", {}, intl)}
      </LucButton>
    </Box>
  );
};

export default ViewReportHeader;
