import Services from "./Services";

var User = {};

// Get User Info
User.getUserInfo = (queryData) => {
  return Services.getData("api/v3/user/info", queryData);
};
// Get User Info
User.getUserInfoV4 = (queryData) => {
  return Services.getData("api/v4/user/info", queryData);
};

// Get User Teams
User.getUserTeams = (queryData) => {
  return Services.getData("api/v4/user/teams", queryData);
};

// Edit User Info
User.editUserInfo = (queryData) => {
  return Services.putData("api/v4/user/info", queryData);
};

// Get User Authorized Accounts
User.getAuthorizedAccounts = (queryData) => {
  return Services.getData("api/v3/user/authorized_accounts");
};

// Get New User Data
User.getNewUserData = (queryData) => {
  return Services.getData("api/v3/auth/user/me");
};

User.getSmData = (queryData) => {
  return Services.getSmData(queryData);
};
//set user language
User.setUserLang = function (queryData) {
  return Services.putData("api/v3/user/language", queryData);
};
// create hubspot user
User.createUserHutspot = (queryData) => {
  return Services.postData(
    "api/v4/auth/user/hubspot/create_hubspot_user_session",
    queryData,
  );
};

// get 2FA
User.getUser2FA = () => {
  return Services.getData("api/v3/user/security");
};

// enable 2FA
User.enableUser2FA = (queryData) => {
  return Services.postData("api/v3/user/setup_two_fa", queryData);
};

// disable 2FA
User.disableUser2FAFirst = (queryData) => {
  return Services.postData("api/v3/user/disable_two_fa", queryData);
};
// verify disable 2FA
User.disableUser2FAVerify = (queryData) => {
  return Services.postData("api/v3/user/verify_disable_two_fa", queryData);
};

// resend code 2FA
User.resendCode2FA = () => {
  return Services.postData("api/v3/user/resend_otp");
};

export default User;
