export const initialState = {
  topKeywords: [],
  topKeywordsPreLoaderDataSources: [],
  topHashtags: [],
  topHashtagsPreLoaderDataSources: [],
  topCountries: [],
  topCountriesPreLoaderDataSources: [],
  topCities: [],
  topCitiesPreLoaderDataSources: [],
  topLanguages: [],
  topLanguagesPreLoaderDataSources: [],
  dialects: [],
  subDialects: [],
  dialectsSubDialectsPreLoaderDataSources: [],
  gender: [],
  genderPreLoaderDataSources: [],
  accountTypes: [],
  accountTypesPreLoaderDataSources: [],
  topEngagers: [],
  topEngagersPreLoaderDataSources: [],
  topVerifiedEngagers: [],
  topVerifiedEngagersPreLoaderDataSources: [],
  topInfluencers: [],
  topInfluencersPreLoaderDataSources: [],
  postsVolume: [],
  totalPostsVolume: 0,
  commentsOverTimePreLoaderDataSources: [],
  sentimentAnalysis: [],
  sentimentAnalysisPieChart: [],
  sentimentAnalysisPreLoader: [],
};

export const analyticsReducer = (state, action) => {
  switch (action?.type) {
    case "SET_TOP_KEYWORDS":
      return { ...state, topKeywords: action?.payload };
    case "SET_TOP_KEYWORDS_PRELOADER":
      return { ...state, topKeywordsPreLoaderDataSources: action?.payload };
    case "SET_TOP_HASHTAGS":
      return { ...state, topHashtags: action?.payload };
    case "SET_TOP_HASHTAGS_PRELOADER":
      return { ...state, topHashtagsPreLoaderDataSources: action?.payload };
    case "SET_TOP_COUNTRIES":
      return { ...state, topCountries: action?.payload };
    case "SET_TOP_COUNTRIES_PRELOADER":
      return { ...state, topCountriesPreLoaderDataSources: action?.payload };
    case "SET_TOP_CITIES":
      return { ...state, topCities: action?.payload };
    case "SET_TOP_CITIES_PRELOADER":
      return { ...state, topCitiesPreLoaderDataSources: action?.payload };
    case "SET_TOP_LANGUAGES":
      return { ...state, topLanguages: action?.payload };
    case "SET_TOP_LANGUAGES_PRELOADER":
      return { ...state, topLanguagesPreLoaderDataSources: action?.payload };
    case "SET_DIALECTS":
      return { ...state, dialects: action?.payload };
    case "SET_SUB_DIALECTS":
      return { ...state, subDialects: action?.payload };
    case "SET_DIALECTS_SUB_DIALECTS_PRELOADER":
      return {
        ...state,
        dialectsSubDialectsPreLoaderDataSources: action?.payload,
      };
    case "SET_GENDER":
      return { ...state, gender: action?.payload };
    case "SET_GENDER_PRELOADER":
      return { ...state, genderPreLoaderDataSources: action?.payload };
    case "SET_ACCOUNT_TYPES":
      return { ...state, accountTypes: action?.payload };
    case "SET_ACCOUNT_TYPES_PRELOADER":
      return { ...state, accountTypesPreLoaderDataSources: action?.payload };
    case "SET_TOP_ENGAGERS":
      return { ...state, topEngagers: action?.payload };
    case "SET_TOP_ENGAGERS_PRELOADER":
      return { ...state, topEngagersPreLoaderDataSources: action?.payload };
    case "SET_TOP_VERIFIED_ENGAGERS":
      return { ...state, topVerifiedEngagers: action?.payload };
    case "SET_TOP_VERIFIED_ENGAGERS_PRELOADER":
      return {
        ...state,
        topVerifiedEngagersPreLoaderDataSources: action?.payload,
      };
    case "SET_TOP_INFLUENCERS":
      return { ...state, topInfluencers: action?.payload };
    case "SET_TOP_INFLUENCERS_PRELOADER":
      return { ...state, topInfluencersPreLoaderDataSources: action?.payload };
    case "SET_POSTS_VOLUME":
      return { ...state, postsVolume: action?.payload };
    case "SET_TOTAL_POSTS_VOLUME":
      return { ...state, totalPostsVolume: action?.payload };
    case "SET_COMMENTS_OVER_TIME_PRELOADER":
      return {
        ...state,
        commentsOverTimePreLoaderDataSources: action?.payload,
      };
    case "SET_SENTIMENT_ANALYSIS":
      return { ...state, sentimentAnalysis: action?.payload };
    case "SET_SENTIMENT_ANALYSIS_PIECHART":
      return { ...state, sentimentAnalysisPieChart: action?.payload };
    case "SET_SENTIMENT_ANALYSIS_PRELOADER":
      return { ...state, sentimentAnalysisPreLoader: action?.payload };
    case "RESET_SENTIMENT_ANALYSIS_PRELOADER":
      return initialState;
    default:
      return state;
  }
};
