// src/components/sidebar/Sidebar.jsx
import { Box, Tooltip, Typography } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function SidebarViewer({
  sidebarOptions,
  tempSidebarLabel,
  onSidebarClick,
  intl,
}) {
  return (
    <Box className="sidebar">
      {sidebarOptions?.map((option) => {
        const isActive = option?.label === tempSidebarLabel;
        return (
          <Box
            key={option?.label}
            className={`sidebar-button ${option?.icon ? "has-icon" : ""} ${
              isActive ? "active" : ""
            }`}
            onClick={() => onSidebarClick(option?.label)}
            id={`${option?.label?.replace(/_/g, "-")}-button`}
          >
            <Typography component="span" className="date-picker-label-option">
              {CheckValueLocale(option?.label, "", {}, intl)}
            </Typography>
            {option?.icon && (
              <Tooltip
                title={CheckValueLocale(
                  option?.iconTooltip || "",
                  "",
                  {},
                  intl,
                )}
                id="picker-siderbar-date-till-today-button-tooltip"
                arrow
                placement="top"
              >
                <FontAwesomeIcon icon={option?.icon} />
              </Tooltip>
            )}
          </Box>
        );
      })}
    </Box>
  );
}

export default SidebarViewer;
