import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useIntl } from "react-intl";
import "../../../monitors.scss";
import PieChartWidget from "components/widgets/pieChartWidget";
import {
  translateLocalsFromRabbitMQ,
  CheckValueLocale,
} from "../../../../../utils/helpers/index";

const AuthorAnalyticsSection = (props) => {
  const intl = useIntl();
  let {
    response,
    topLanguages,
    setTopLanguages,
    topLanguagesPreLoader,
    topCountries,
    setTopCountries,
    topCountriesPreLoader,
    setTopCountriesPreLoader,
    setTopLanguagesPreLoader,
  } = props;
  //handle response

  //handle response with useEffect (when load page & any update)
  const handleResponseFunc = async () => {
    switch (response.eventName) {
      case "Talkwalker__EngagementsPage__top_languages":
        setTopLanguages(
          translateLocalsFromRabbitMQ(
            "Talkwalker__EngagementsPage__top_languages",
            response.eventData.top_languages,
          ),
        );
        setTopLanguagesPreLoader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Talkwalker__EngagementsPage__top_countries":
        setTopCountries(response.eventData.top_countries);
        setTopCountriesPreLoader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    handleResponseFunc();
  }, [response]);
  // _______________________________________________________________________
  //getting Data to export xls sheet
  useEffect(() => {
    props.setPostsAnalyticsData([
      { topLanguages: topLanguages },
      { topCountries: topCountries },
    ]);
  }, [topLanguages, topCountries]);

  // _______________________________________________________________________

  //Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (props.resetAllFields !== 1) {
      setTopLanguages([]);
      setTopLanguagesPreLoader([]);

      setTopCountries([]);
      setTopCountriesPreLoader([]);
    }
  }, [props.resetAllFields]);

  return (
    <>
      <Typography className="section-name">
        {CheckValueLocale("authors_analytics", "", {}, intl).toUpperCase()}
      </Typography>

      <Box mt={3}>
        {/* _____________________________________________________ */}
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <PieChartWidget
              title={"top_languages"}
              titleToolTip={"top_languages_nb_tooltip"}
              data={topLanguages}
              areaStyle={true}
              toolTipLabel={"toolTipLabel"}
              showDownloadIcon
              showAI
              pieChartPreLoaderDataSources={topLanguagesPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              iconAfterTitle={props.newsBlogIcon}
            />
          </Grid>
          <Grid item xs={6}>
            <PieChartWidget
              title={"top_countries"}
              titleToolTip={"top_countries_nb_tooltip"}
              data={topCountries}
              areaStyle={true}
              toolTipLabel={"toolTipLabel"}
              pieChartPreLoaderDataSources={topCountriesPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              iconAfterTitle={props.newsBlogIcon}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AuthorAnalyticsSection;
