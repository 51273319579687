import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import InteractionsList from "components/interactions-list/InteractionsList.js";
import OverView from "components/widgets/overViewWidget";
import MediaFb from "components/media/media-fb/mediaFb";
import { CheckValueLocale, getNoOfDays, handleUnixFormat } from "utils/helpers";
import LuciFunctions from "pages/luci/components/luciFunctions/luciFunction";
import AccountOverView from "./components/accountOverViewSection";
import PostsAnalytics from "./components/postsAnalyticsSection";
import EngagementsSection from "./components/engagementsSection";
import CustomerCareSection from "./components/customerCareSection";
import _ from "lodash";
import "../../socialMediaPage.scss";
import { checkIsDataFecthing } from "pages/channelAnalytics/InsightsPage/insightsCommonFunctions";

const FacebookBody = (props) => {
  const intl = useIntl();
  let {
    response,
    startDate,
    endDate,
    FacebookIcon,
    preLoaderTrackerDataSources,
    setCheckAllDataFetchingExport,
  } = props;

  const initialWidgetsState = {
    // {/* // NOTE: we commented this code until BE fixed these widgets. */}

    //pageViews widget
    // totalPageViewsCount: 0,
    // pageViews: [],
    // pageViewsPreLoaderDataSources: [],

    //pageReachImpression widget
    // pageReachImpression: [],
    // pageReachImpressionLegend: [],
    // pageReachImpressionPreLoader: [],

    //pageLikeUnlike widget
    // pageLikeUnlike: [],
    // pageLikeUnlikeLegendPreLoaderDataSources: [],

    //pageContactDetailsInteractions widget
    // pageContactDetailsInteractions: [],
    // pageActionsLegend: [],
    // pageContactDetailsLegend: [],
    // pageContactDetailsInteractionsPreLoader: [],

    //pageActionsPreLoader widget
    // pageActions: [],
    // pageActionsPreLoader: [],

    //pageActionsPreLoader widget
    // pageLikeUnlikeLegend: [],
    // pageActionsPreLoader: [],
    // minValueAuthorActivity: 0,
    // maxValueAuthorActivity: 0,

    //postReachImpression widget
    // postReachImpression: [],
    // totalPostReachImpression: [],
    // postReachImpressionPreLoader: [],

    //videosViews widget
    // videosViews: [],
    // totalVideosViewsCount: [],
    // videosViewsPreLoader: [],

    //genderDistribution widget
    // genderDistribution: [],
    // genderDistributionPieChartValue: [],
    // genderDistributionPreLoader: [],

    //topLanguages widget
    // topLanguages: [],
    // topLanguagesPreLoader: [],

    // topCities widget
    // topCities: [],
    // topCitiesPreLoader: [],

    //topCountries widget
    // topCountries: [],
    // topCountriesPreLoader: [],

    //overview widget
    overView: [],
    overViewPreLoaderDataSources: [],

    //postsVolume widget
    postsVolume: [],
    totalPostsVolumeCount: [],
    postsVolumePreLoader: [],

    //postsInteractions widget
    postsInteractions: [],
    postsInteractionsLegend: [],
    maxValuePostsInteractions: 0,
    maxValueInteractionsDate: 0,
    minValuePostsInteractions: 0,
    minValueInteractionsDate: 0,
    avgValuePostsInteractions: 0,
    postsInteractionsPreLoader: [],

    // reactions widget
    reactions: [],
    pageReactionPieChartValue: [],
    reactionsPreLoader: [],

    //[Engagement] reactions widget
    reactionsEngagement: [],
    pageReactionPieChartValueEngagement: [],
    reactionsPreLoaderEngagement: [],

    //themes widget
    themeXlxs: [],
    themeAnalysis: [],
    themeTrend: [],
    themeAnalysisTrendPreLoaderDataSources: [],

    //postsContentType widget
    postsContentType: [],
    postContentPieChartValue: [],
    postsContentTypePreLoader: [],

    //topKeywords widget
    topKeywords: [],
    topKeywordsPreLoader: [],

    //topHashtags widget
    topHashtags: [],
    topHashtagsPreLoader: [],

    //topVideos widget
    topVideos: [],
    topVideosPreLoader: [],

    //topImages widget
    topImages: [],
    topImagesPreLoader: [],

    //commentsVolume widget
    commentsVolume: [],
    totalInteraction: 0,
    avgInteractions: 0,
    commentsVolumePreLoader: [],

    //sentimentAnalysis widget
    sentimentAnalysis: [],
    sentimentPieChart: [],
    sentimentAnalysisPreLoader: [],
    negativeArray: [],
    positiveArray: [],
    neutralArray: [],

    //commentsInteractions widget
    commentsInteractions: [],
    commentsInteractionsLegend: [],
    maxValueCommentsInteractions: 0,
    maxValueCommentsInteractionsDate: 0,
    minValueCommentsInteractions: 0,
    minValueCommentsInteractionsDate: 0,
    avgValueCommentsInteractions: 0,
    commentsInteractionsPreLoader: [],

    //associatedTopics widget
    associatedTopics: [],
    associatedTopicsDeep: [],
    associatedTopicsPreLoader: [],

    //topHashtage - topKeyword widget
    engagamentsTopKeywords: [],
    engagamentsTopKeywordsPreLoader: [],
    engagamentsTopHashtags: [],
    engagamentsTopHashtagsPreLoader: [],

    //engagersVolume widget
    engagersVolume: [],
    totalEngagersVolumeCount: [],
    engagersVolumePreLoader: [],

    //dialects widget
    dialects: [],
    dialectsDeep: [],
    subDialects: [],
    subDialectsDeep: [],
    dialectsSubDialetsPreLoader: [],

    //accountAuthorActivity widget
    accountAuthorActivity: [],
    minValueAuthorActivity: 0,
    maxValueAuthorActivity: 0,
    accountAuthorActivityPreLoader: [],

    //engagersActivity widget
    engagersActivity: [],
    minValueEngagersActivity: 0,
    maxValuEngagerActivity: 0,
    engagerActivityPreLoader: [],

    //CustomerCare
    customerCareData: [],
    customerCarePerformance: {},
    respRate: 0,
    totalQuestion: 0,
    customerCarePreloader: [],

    //latestInteractions widget
    latestInteractions: [],
    latestInteractionsCount: 0,
    latestInteractionsPreLoaderDataSources: [],
  };

  const [faceBookWidgets, setFaceBookWidgets] = useState(initialWidgetsState);
  const { checkLuciDataIsFounded, checkLuciAllDataFounded } = LuciFunctions();

  useEffect(() => {
    switch (response?.eventName) {
      case "Facebook__CASingleDataSourceInsightsPage__overview":
        let overViewArray = Object?.entries(response?.eventData?.overview)?.map(
          (key) => {
            const condition =
              key[1] === "None" || key[1] === null || key[1] === 0;
            if (key[0] === "average_waiting_time" && condition) {
              return {
                name: key[0],
                value: 0.0,
              };
            } else
              return {
                name: key[0],
                value: key[1],
              };
          },
        );
        setFaceBookWidgets({
          ...faceBookWidgets,
          overView: [...overViewArray],
          overViewPreLoaderDataSources: [response?.data_source],
        });
        break;

      //Account Page Widgets
      case "Facebook__AccountPage__account_author_activity":
        setFaceBookWidgets({
          ...faceBookWidgets,
          accountAuthorActivity: response?.eventData?.account_author_activity,
          minValueAuthorActivity: response?.eventData?.min_value,
          maxValueAuthorActivity: response?.eventData?.max_value,
          accountAuthorActivityPreLoader: [response?.data_source],
        });
        break;
      case "Facebook__AccountPage__content_type":
        setFaceBookWidgets({
          ...faceBookWidgets,
          postsContentType:
            response?.eventData?.posts_content_type?.posts_content_type,
          postContentPieChartValue:
            response?.eventData?.posts_content_type
              ?.posts_content_types_legends,
          postsContentTypePreLoader: [response?.data_source],
        });
        break;
      case "Facebook__AccountPage__volume_overtime":
        setFaceBookWidgets({
          ...faceBookWidgets,
          postsVolume: response?.eventData?.posts_over_time,
          totalPostsVolumeCount: response?.eventData?.total_posts_count,
          postsVolumePreLoader: [response?.data_source],
        });
        break;
      case "Facebook__AccountPage__top_videos":
        setFaceBookWidgets({
          ...faceBookWidgets,
          topVideos: response?.eventData?.top_videos,
          topVideosPreLoader: [response?.data_source],
        });
        break;
      case "Facebook__AccountPage__top_images":
        setFaceBookWidgets({
          ...faceBookWidgets,
          topImages: response?.eventData?.top_images,
          topImagesPreLoader: [response?.data_source],
        });
        break;
      case "Facebook__AccountPage__top_keywords":
        setFaceBookWidgets({
          ...faceBookWidgets,
          topKeywords: response?.eventData?.top_keywords,
          topKeywordsPreLoader: [response?.data_source],
        });
        break;
      case "Facebook__AccountPage__top_hashtags":
        setFaceBookWidgets({
          ...faceBookWidgets,
          topHashtags: response?.eventData?.top_hashtags,
          topHashtagsPreLoader: [response?.data_source],
        });
        break;
      case "Facebook__AccountPage__reactions":
        setFaceBookWidgets({
          ...faceBookWidgets,
          reactions:
            response?.eventData?.posts_reactions?.posts_reactions_over_time,
          pageReactionPieChartValue:
            response?.eventData?.posts_reactions?.posts_reactions_piechart,
          reactionsPreLoader: [response?.data_source],
        });
        break;
      case "Facebook__AccountPage__posts_interactions":
        setFaceBookWidgets({
          ...faceBookWidgets,
          postsInteractions:
            response?.eventData?.posts_interactions
              ?.posts_interactions_over_time,
          postsInteractionsLegend:
            response?.eventData?.posts_interactions?.posts_interactions_legends,
          avgValuePostsInteractions:
            response?.eventData?.posts_interactions?.average_interactions,
          maxValuePostsInteractions:
            response?.eventData?.posts_interactions?.max_interactions,
          minValueInteractionsDate: handleUnixFormat(
            response?.eventData?.posts_interactions?.min_interaction_date,
          ),
          minValuePostsInteractions:
            response?.eventData?.posts_interactions?.min_interactions,
          maxValueInteractionsDate: handleUnixFormat(
            response?.eventData?.posts_interactions?.max_interaction_date,
          ),
          postsInteractionsPreLoader: [response?.data_source],
        });
        break;

      //Engagement Page Widgets
      case "Facebook__EngagementsPage__volume_overtime":
        const noOfDays = getNoOfDays(startDate, endDate);
        const avg = response?.eventData?.total_posts_count / noOfDays;
        setFaceBookWidgets({
          ...faceBookWidgets,
          commentsVolume: response?.eventData?.posts_over_time,
          totalInteraction: response?.eventData?.total_posts_count,
          avgInteractions: avg % 1 !== 0 ? avg?.toFixed(2) : avg,
          commentsVolumePreLoader: [response?.data_source],
        });
        break;
      case "Facebook__EngagementsPage__top_hashtags":
        setFaceBookWidgets({
          ...faceBookWidgets,
          engagamentsTopHashtags: response?.eventData?.top_hashtags,
          engagamentsTopHashtagsPreLoader: [response?.data_source],
        });
        break;
      case "Facebook__EngagementsPage__top_keywords":
        setFaceBookWidgets({
          ...faceBookWidgets,
          engagamentsTopKeywords: response?.eventData?.top_keywords,
          engagamentsTopKeywordsPreLoader: [response?.data_source],
        });
        break;
      case "Facebook__EngagementsPage__associated_topics":
        setFaceBookWidgets({
          ...faceBookWidgets,
          associatedTopics: response?.eventData?.associated_topics,
          associatedTopicsDeep: _.cloneDeep([
            ...response.eventData?.associated_topics,
          ]),
          associatedTopicsPreLoader: [response?.data_source],
        });
        break;
      case "Facebook__EngagementsPage__engagers_volume":
        setFaceBookWidgets({
          ...faceBookWidgets,
          engagersVolume:
            response?.eventData?.engagers_volume?.engagers_volume_over_time,
          totalEngagersVolumeCount:
            response?.eventData?.engagers_volume?.total_engagers,
          engagersVolumePreLoader: [response?.data_source],
        });
        break;
      case "Facebook__EngagementsPage__reactions":
        setFaceBookWidgets({
          ...faceBookWidgets,
          reactionsEngagement:
            response?.eventData?.posts_reactions?.posts_reactions_over_time,
          pageReactionPieChartValueEngagement:
            response?.eventData?.posts_reactions?.posts_reactions_piechart,
          reactionsPreLoaderEngagement: [response?.data_source],
        });
        break;
      case "Facebook__EngagementsPage__dialects_and_sub_dialects":
        setFaceBookWidgets({
          ...faceBookWidgets,
          dialects: response?.eventData?.dialects,
          dialectsDeep: _.cloneDeep([...response.eventData?.dialects]),
          subDialects: response?.eventData?.sub_dialects,
          subDialectsDeep: _.cloneDeep([...response.eventData?.sub_dialects]),
          dialectsSubDialetsPreLoader: [response?.data_source],
        });
        break;
      case "Facebook__EngagementsPage__sentiment_analysis":
        Object.entries(response?.eventData?.sentiment_overtime)?.forEach(
          ([key, value]) => {
            switch (key) {
              case "negative":
                value?.map((i) => {
                  return faceBookWidgets?.negativeArray.push(i);
                });
                break;
              case "positive":
                value?.map((i) => {
                  return faceBookWidgets?.positiveArray.push(i);
                });
                break;
              case "neutral":
                value?.map((i) => {
                  return faceBookWidgets?.neutralArray.push(i);
                });
                break;
              default:
                break;
            }
          },
        );

        setFaceBookWidgets({
          ...faceBookWidgets,
          sentimentAnalysis: {
            negative: faceBookWidgets?.negativeArray,
            positive: faceBookWidgets?.positiveArray,
            neutral: faceBookWidgets?.neutralArray,
          },
          sentimentPieChart: response?.eventData?.sentiment_piechart,
          sentimentAnalysisPreLoader: [response?.data_source],
        });

        break;
      case "Facebook__EngagementsPage__themes_and_sub_themes":
        setFaceBookWidgets({
          ...faceBookWidgets,
          themeXlxs: response?.eventData?.themes,
          themeAnalysis: response?.eventData?.themes?.theme_analysis,
          themeTrend: response?.eventData?.themes?.theme_trend,
          themeAnalysisTrendPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Facebook__EngagementsPage__posts_interactions":
        setFaceBookWidgets({
          ...faceBookWidgets,
          commentsInteractions:
            response?.eventData?.posts_interactions
              ?.posts_interactions_over_time,
          commentsInteractionsLegend:
            response?.eventData?.posts_interactions?.posts_interactions_legends,
          avgValueCommentsInteractions:
            response?.eventData?.posts_interactions?.average_interactions,
          maxValueCommentsInteractions:
            response?.eventData?.posts_interactions?.max_interactions,
          maxValueCommentsInteractionsDate: handleUnixFormat(
            response?.eventData?.posts_interactions?.max_interaction_date,
          ),
          minValueCommentsInteractionsDate: handleUnixFormat(
            response?.eventData?.posts_interactions?.min_interaction_date,
          ),
          minValueCommentsInteractions:
            response?.eventData?.posts_interactions?.min_interactions,
          commentsInteractionsPreLoader: [response?.data_source],
        });
        break;
      case "Facebook__EngagementsPage__account_author_activity":
        setFaceBookWidgets({
          ...faceBookWidgets,
          engagersActivity: response?.eventData?.account_author_activity,
          minValueEngagersActivity: response?.eventData?.min_value,
          maxValuEngagerActivity: response?.eventData?.max_value,
          engagerActivityPreLoader: [response?.data_source],
        });
        break;
      //CustomerCare Page Widgets
      case "Facebook__CASingleDataSourceInsightsPage__customer_care_performance":
        let arrCustomerCare = [];
        if (response?.eventData?.response_time_segment) {
          Object.keys(response.eventData?.response_time_segment).map(
            (val, index) => {
              arrCustomerCare.push({
                name: val,
                value: Object.values(response.eventData?.response_time_segment)[
                  index
                ],
              });
            },
          );
        }

        setFaceBookWidgets({
          ...faceBookWidgets,
          customerCarePerformance: response?.eventData,
          customerCareData: arrCustomerCare,
          respRate:
            response?.eventData?.number_of_answered_questions /
            response?.eventData?.total_number_of_questions,
          totalQuestion: response?.eventData?.total_number_of_questions,
          customerCarePreloader: [response?.data_source],
        });
        break;

      //Latest Interaction
      case "Facebook__EngagementsPage__top_posts_comments":
        setFaceBookWidgets({
          ...faceBookWidgets,
          latestInteractions: response?.eventData?.top_posts,
          latestInteractionsPreLoaderDataSources: [response?.data_source],
          latestInteractionsCount: response?.eventData?.top_posts_count,
        });
        break;

      default:
        break;
    }
  }, [response]);

  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (props?.resetAllFields !== 1) {
      setFaceBookWidgets({ ...initialWidgetsState });
    }
  }, [props?.resetAllFields]);

  useEffect(() => {
    let x = checkLuciAllDataFounded(
      faceBookWidgets.latestInteractionsPreLoaderDataSources,
      preLoaderTrackerDataSources,
    );
    let y = checkLuciDataIsFounded([faceBookWidgets?.latestInteractions]);
    props.setCheckAllDataExist(x);
    props.setCheckDataIsEmpty(y);
  }, [faceBookWidgets]);

  useEffect(() => {
    let excelData = { ...faceBookWidgets };
    let authorActivityArr = [
      faceBookWidgets?.accountAuthorActivity,
      faceBookWidgets?.minValueAuthorActivity,
      faceBookWidgets?.maxValueAuthorActivity,
    ];
    let engagerActivityArr = [
      faceBookWidgets?.engagersActivity,
      faceBookWidgets?.minValueEngagersActivity,
      faceBookWidgets?.maxValuEngagerActivity,
    ];
    excelData["authorActivity"] = [...authorActivityArr];
    excelData["engagersActivity"] = [...engagerActivityArr];

    props?.setExcelSheetData([{ ...excelData }]);
  }, [faceBookWidgets]);

  // check fetching for export excel
  useEffect(() => {
    let totalPreLoaderDataSouces = [
      faceBookWidgets?.overViewPreLoaderDataSources,
      faceBookWidgets?.postsVolumePreLoader,
      faceBookWidgets?.postsInteractionsPreLoader,
      faceBookWidgets?.reactionsPreLoader,
      faceBookWidgets?.reactionsPreLoaderEngagement,
      faceBookWidgets?.postsContentTypePreLoader,
      faceBookWidgets?.topKeywordsPreLoader,
      faceBookWidgets?.topHashtagsPreLoader,
      faceBookWidgets?.topVideosPreLoader,
      faceBookWidgets?.topImagesPreLoader,
      faceBookWidgets?.commentsVolumePreLoader,
      faceBookWidgets?.themeAnalysisTrendPreLoaderDataSources,
      faceBookWidgets?.sentimentAnalysisPreLoader,
      faceBookWidgets?.commentsInteractionsPreLoader,
      faceBookWidgets?.associatedTopicsPreLoader,
      faceBookWidgets?.engagamentsTopHashtagsPreLoader,
      faceBookWidgets?.engagamentsTopKeywordsPreLoader,
      faceBookWidgets?.engagersVolumePreLoader,
      faceBookWidgets?.dialectsSubDialetsPreLoader,
      faceBookWidgets?.accountAuthorActivityPreLoader,
      faceBookWidgets?.engagerActivityPreLoader,
      faceBookWidgets?.customerCarePreloader,
      faceBookWidgets?.latestInteractionsPreLoaderDataSources,
    ];

    let result = checkIsDataFecthing(
      totalPreLoaderDataSouces,
      preLoaderTrackerDataSources,
    );
    setCheckAllDataFetchingExport(result);
  }, [
    faceBookWidgets?.overViewPreLoaderDataSources,
    faceBookWidgets?.postsVolumePreLoader,
    faceBookWidgets?.postsInteractionsPreLoader,
    faceBookWidgets?.reactionsPreLoader,
    faceBookWidgets?.reactionsPreLoaderEngagement,
    faceBookWidgets?.postsContentTypePreLoader,
    faceBookWidgets?.topKeywordsPreLoader,
    faceBookWidgets?.topHashtagsPreLoader,
    faceBookWidgets?.topVideosPreLoader,
    faceBookWidgets?.topImagesPreLoader,
    faceBookWidgets?.commentsVolumePreLoader,
    faceBookWidgets?.themeAnalysisTrendPreLoaderDataSources,
    faceBookWidgets?.sentimentAnalysisPreLoader,
    faceBookWidgets?.commentsInteractionsPreLoader,
    faceBookWidgets?.associatedTopicsPreLoader,
    faceBookWidgets?.engagamentsTopHashtagsPreLoader,
    faceBookWidgets?.engagamentsTopKeywordsPreLoader,
    faceBookWidgets?.engagersVolumePreLoader,
    faceBookWidgets?.dialectsSubDialetsPreLoader,
    faceBookWidgets?.accountAuthorActivityPreLoader,
    faceBookWidgets?.engagerActivityPreLoader,
    faceBookWidgets?.customerCarePreloader,
    faceBookWidgets?.latestInteractionsPreLoaderDataSources,
  ]);

  return (
    <>
      <OverView
        data={faceBookWidgets?.overView}
        directMessage={false}
        overViewPreLoaderDataSources={
          faceBookWidgets?.overViewPreLoaderDataSources
        }
        preLoaderTrackerDataSources={preLoaderTrackerDataSources}
      />

      <AccountOverView
        faceBookWidgets={faceBookWidgets}
        FacebookIcon={FacebookIcon}
        preLoaderTrackerDataSources={preLoaderTrackerDataSources}
      />

      <PostsAnalytics
        faceBookWidgets={faceBookWidgets}
        FacebookIcon={FacebookIcon}
        preLoaderTrackerDataSources={preLoaderTrackerDataSources}
      />

      <EngagementsSection
        faceBookWidgets={faceBookWidgets}
        FacebookIcon={FacebookIcon}
        preLoaderTrackerDataSources={preLoaderTrackerDataSources}
      />

      <CustomerCareSection
        faceBookWidgets={faceBookWidgets}
        FacebookIcon={FacebookIcon}
        preLoaderTrackerDataSources={preLoaderTrackerDataSources}
      />

      <Box my={3}>
        <InteractionsList
          showProfile={true}
          unixDate={props.unixDate}
          data={faceBookWidgets?.latestInteractions?.slice(0, 5)}
          title={"latest_interactions"}
          dataSources={props?.dataSources}
          latestInteractionsPreLoaderDataSources={
            faceBookWidgets?.latestInteractionsPreLoaderDataSources
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          isPublicFacebook
          showViewMoreButton={faceBookWidgets?.latestInteractionsCount > 5}
        />
      </Box>
    </>
  );
};

export default FacebookBody;
