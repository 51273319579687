import { useMutation } from "@tanstack/react-query";
import { FiltersServices } from "services/api/filterServices";

export const useDeleteSavedFilterData = () => {
  return useMutation({
    mutationFn: ({
      saved_filter_id,
      monitor_id,
      product_id,
      data_source_id,
    }) => {
      const queryData = {
        params: {
          monitor_id,
          product_id,
          data_source_id,
        },
      };
      return FiltersServices.deleteSavedFilterData({
        saved_filter_id,
        queryData,
      });
    },
  });
};
