import { useMutation } from "@tanstack/react-query";
import Engagements from "services/api/engagements";

const useCreateEngagementNote = () => {
  return useMutation({
    mutationFn: (params) => Engagements.createNote(params),
  });
};

export default useCreateEngagementNote;
