import React, { useContext } from "react";
import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { EditReportContext } from "pages/reports/context/reportContext";
import ReportDeliverySharedStep from "pages/reports/components/reportDeliverySharedStep";

const EditReportDeliveryStep = () => {
  const {
    formik,
    availableUsers,
    availableUsersLoading,
    reportFrequencies,
    reportFrequenciesLoading,
    isNewsletterReport,
    reportLuciTokens,
    reportLuciTokensLoading,
    isPublicAccountInstagram,
    showCustomDaySelect,
    setShowCustomDaySelect,
  } = useContext(EditReportContext);

  const intl = useIntl();

  return (
    <Box className="edit-report-step-wrapper">
      <Typography variant="h6" className="edit-report-step-title">
        3. {CheckValueLocale("delivery_requirment", "", {}, intl)}
      </Typography>
      <ReportDeliverySharedStep
        formik={formik}
        availableUsers={availableUsers}
        availableUsersLoading={availableUsersLoading}
        reportFrequencies={reportFrequencies}
        reportFrequenciesLoading={reportFrequenciesLoading}
        isNewsletterReport={isNewsletterReport}
        reportLuciTokens={reportLuciTokens}
        reportLuciTokensLoading={reportLuciTokensLoading}
        isPublicAccountInstagram={isPublicAccountInstagram}
        showCustomDaySelect={showCustomDaySelect}
        setShowCustomDaySelect={setShowCustomDaySelect}
        isEdit={true}
      />
    </Box>
  );
};

export default EditReportDeliveryStep;
