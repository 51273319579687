import React, { useEffect, useState, useRef } from "react";
import {
  CheckValueLocale,
  handlelUserRoles,
  isEmptyObj,
  validateUrlInjection,
} from "utils/helpers";
import { useNavigate, useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import SurveyController from "services/controllers/surveyController";
import { dummyQuestions } from "./dummyQuestions";
import { useGetCompanyDomains } from "./Header/embeddedPublish/hooks/useGetCompanyDomains";

const SurveyFunctions = () => {
  const intl = useIntl();
  const editRef = useRef(null);
  const navigate = useNavigate();
  const embeddedRef = useRef(null);

  const urlParams = useParams();
  const id = urlParams?.id;
  const [isLoading, setIsLoading] = useState(true);
  const [saveChangesFlag, setSaveChangesFlag] = useState(false);
  const [logoData, setLogoData] = useState({
    logoUploaded: false,
    logoUploading: false,
  });
  const [surveyData, setSurveyData] = useState({});
  const [savedSurveyData, setSavedSurveyData] = useState({});
  const [showPoweredBy, setShowPoweredBy] = useState(false);
  const [sqIndex, setSQIndex] = useState(1); // Selected question Index
  const [ssIndex, setSSIndex] = useState(1); // Survey selected section Index
  const [selectedCountryCode, setSelectedCountryCode] = useState("SA");
  const [openPublishSurveyModal, setOpenPublishSurveyModal] = useState(false);
  const [preview, setPreview] = useState(false);
  const [operationPerformed, setOperationPerformed] = useState("");
  const [count, setCount] = useState(0);
  let surveyTitleLocales = [
    "Untitled Survey",
    "Survey Description (Optional)",
    "نموذج بدون عنوان",
    "وصف الاستبيان (اختياري)",
  ];
  const [isLucidyaDomain, setIsLucidyaDomain] = useState(true);
  const [customDomain, setCustomDomain] = useState("");
  const [customDomainStepLoading, setCustomDomainStepLoading] = useState(false);
  const [customDomainStep, setCustomDomainStep] = useState(false);
  const [surveyLimitReach, setSurveyLimitReach] = useState(false);
  const [publishFlag, setPublishFlag] = useState(true);
  const [isPublished, setIsPublished] = useState(false);
  const [multiRes, setMultiRes] = useState(false);
  const [isSurveyEnabled, setIsSurveyEnabled] = useState(false);
  const [isSkeipped, setIsSkeipped] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [filteredSections, setFilteredSections] = useState([]);
  const [surveySections, setSurveySections] = useState([]);
  const [emptySection, setEmptySection] = useState(null);
  const [proceedError, setProceedError] = useState(null);
  const [afterSecError, setAfterSecError] = useState(null);
  const [secTitleErr, setSecTitleErr] = useState(null);
  const [discriptionError, setDiscriptionError] = useState(null);
  const [customDomainStatus, setCustomDomainStatus] = useState("");
  const [snackBar, setSnackBar] = useState({
    open: false,
    title: "",
    msg: "",
    severity: "success",
  });

  // Control Section Tabs States
  const [controlTab, setControlTab] = useState("content");
  const [isSurveyDeleted, setIsSurveyDeleted] = useState(false);
  const handleChangeControlTab = (e, newVal) => setControlTab(newVal);

  // Settings Sidebar Tabs States
  const [settingsTab, setSettingsTab] = useState("display");
  const handleChangeSettingsTab = (e, newVal) => setSettingsTab(newVal);

  const [isEmbedded, setIsEmbedded] = useState(false);
  const [openEmbeddedPublishModal, setEmbeddedPublishModal] = useState(false); // Open Embedded Publish Modal
  const [embeddedPublishStep, setEmbeddedPublishStep] = useState(null); // Active Step In Publish Modal
  const [currentDomainsPage, setCurrentDomainsPage] = useState(1); // Current Domains Page
  const [companyDomainsList, setCompanyDomainsList] = useState([]); // Company Domains List
  const [companyDomainsData, setCompanyDomainsData] = useState({}); // Company Domains Data Object and Pagination
  const [selectedDomains, setSelectedDomains] = useState([]); // Selected Domains

  // Get Company Domains List
  const { mutate: getCompanyDomains, isPending: embeddedPublishLoading } =
    useGetCompanyDomains();

  const handleOpenEmbeddedPublishModal = async (specificStep) => {
    if (!!specificStep) {
      // Case of Step 2 Publish Btn to open verify_domain Step
      setEmbeddedPublishStep(specificStep);
    } else if (surveyData?.selected_domains?.length > 0) {
      // Case of Survey already connected to domain to open select_display step
      setEmbeddedPublishStep("select_display");
      await setEmbeddedPublishModal(true);
    } else {
      // If Survey not connected to a domain get domains list and if no domains available open add_domain step else open select_domain step
      getCompanyDomains(
        { page: 1 },
        {
          onSuccess: async (data) => {
            // If domains available open select_domain step else open add_domain step
            if (data?.data?.domains?.data?.length > 0)
              setEmbeddedPublishStep("select_domain");
            else setEmbeddedPublishStep("add_domain");

            setCompanyDomainsData(data?.data?.domains);
            setCompanyDomainsList((prev) => [
              ...prev,
              ...data?.data?.domains?.data,
            ]);
            await setEmbeddedPublishModal(true);
          },
          onError: () => {
            setSnackBar({
              open: true,
              severity: "error",
              title: CheckValueLocale("something_went_wrong", "", {}, intl),
              message: CheckValueLocale("try_again", "", {}, intl),
            });
          },
        },
      );
    }
  };

  useEffect(() => {
    if (surveyData?.survey_type?.toLowerCase() === "embedded_survey")
      setIsEmbedded(true);
    else setIsEmbedded(false);
  }, [surveyData?.survey_type]);

  const handleSectionDir = (value, sIndex) => {
    filteredSections[sIndex].text_direction = value;
    setSurveySections([...surveySections]);
  };
  useEffect(() => {
    if (surveySections) {
      let filteredArray = surveySections?.filter(
        (obj) => obj?.is_deleted !== true,
      );
      setFilteredSections(filteredArray);
    }
  }, [surveySections]);

  useEffect(() => {
    if (filteredSections) {
      // Reset order key for all sections
      filteredSections?.map((sec, index) => (sec.order = index + 1));

      // Only one section set next_section -1 and disable proceed questions
      if (filteredSections?.length === 1) {
        filteredSections[0].next_section = -1;
        filteredSections[0]?.questions
          ?.filter((question) => question?.is_deleted !== true)
          ?.forEach((question) => {
            if (filteredSections[0]?.name?.length < 1)
              filteredSections[0].name = `${CheckValueLocale(
                "Untitled Section",
                "",
                {},
                intl,
              )} 1`;
            if (question?.is_proceed_on_answer === true) {
              question.is_proceed_on_answer = false;
              question?.question?.items?.forEach((item) => {
                delete item["next_section"];
                delete item["is_conditional"];
              });
              setSurveySections([...surveySections]);
            }
          });
      }

      // Reset all errors to null after any edits
      setEmptySection(null);
      setProceedError(null);
      setAfterSecError(null);
      setSecTitleErr(null);
    }
  }, [filteredSections]);

  const newSection = {
    survey: id,
    name: `${CheckValueLocale("Untitled Section", "", {}, intl)} ${
      filteredSections?.length + 1
    }`,
    order: filteredSections?.length + 1,
    next_section: -1,
    is_deleted: false,
    questions: [],
    text_direction: intl?.locale == "ar" ? "rtl" : "ltr",
  };

  const handleNewSection = async () => {
    let lastSec = filteredSections[filteredSections?.length - 1];
    if (lastSec?.next_section === -1 || lastSec?.next_section === null) {
      lastSec.next_section = lastSec?.order + 1;
    }

    let newQuestions = [];
    newSection.questions = newQuestions;

    setSurveySections([...surveySections, newSection]);

    setSSIndex(filteredSections?.length + 1);
    setSQIndex(1);

    scrollToIdFunc(`section-header-${filteredSections?.length}`);
  };

  const handleDuplicateSection = (e, sIndex) => {
    e.stopPropagation();
    let newQues = [];
    filteredSections[sIndex]?.questions
      ?.filter((question) => question?.is_deleted !== true)
      ?.forEach((question) => {
        // Map over question items and delete specific properties related to proceed_on_answer
        const updatedItems = question?.question?.items?.map((item) => {
          const newItem = { ...item };
          delete newItem?.next_section;
          delete newItem?.is_conditional;
          return newItem;
        });

        newQues?.push({
          question: {
            ...question?.question,
            editable: true,
            items: updatedItems,
          },
          is_deleted: false,
          ...(question?.is_proceed_on_answer !== undefined && {
            is_proceed_on_answer: false,
          }),
          ...(question?.is_proceed_editable !== undefined && {
            is_proceed_editable: true,
          }),
        });
      });

    const oldSec = filteredSections[sIndex];
    const mainIndex = surveySections?.findIndex((sec) => sec === oldSec);
    const duplicated = {
      name: `${oldSec?.name} (${CheckValueLocale(
        "survey_copy",
        "",
        {},
        intl,
      )})`,
      survey: oldSec?.survey,
      is_deleted: false,
      order: oldSec?.order + 1,
      next_section:
        oldSec?.order === filteredSections?.length ? -1 : oldSec?.order + 2,
      questions: newQues,
    };

    filteredSections?.forEach((sec) => {
      if (sec?.next_section > oldSec?.order) sec.next_section += 1;
      sec?.questions
        ?.filter((question) => question?.is_deleted !== true)
        ?.forEach((question) => {
          question?.question?.items?.forEach((item) => {
            if (item?.next_section > oldSec?.order) item.next_section += 1;
          });
        });
    });
    surveySections?.splice(mainIndex + 1, 0, duplicated);
    setSurveySections([...surveySections]);
  };

  const handleDeleteSection = (e, sIndex) => {
    if (sIndex === 0) setSSIndex(1);
    else setSSIndex(sIndex);
    setSQIndex(1);
    // Reset all next_section with next_section -= 1 and next_section = order with null
    filteredSections?.forEach((sec, secIndex) => {
      if (sec?.next_section === sIndex + 1) sec.next_section = null;
      else if (sec?.next_section > sIndex + 1) sec.next_section -= 1;
      sec?.questions
        ?.filter((ques) => ques?.is_deleted !== true)
        ?.forEach((ques) => {
          const oldRemaining = ques?.question?.items?.filter(
            (item) => !!item?.next_section && !item?.is_conditional,
          )?.[0]?.next_section;

          const deletedSecOrder = sIndex + 1; // deleted section index + 1
          const nextSecOrder = secIndex + 2; // Meaning (continue to next section) >> current secIndex is 0 so its order is 1 and nextSecOrder is 2

          const newRemaining =
            oldRemaining === deletedSecOrder
              ? nextSecOrder
              : deletedSecOrder < oldRemaining
                ? oldRemaining - 1
                : oldRemaining;

          if (ques?.is_proceed_on_answer)
            ques?.question?.items?.forEach((item) => {
              if (
                ["nps", "scale"]?.includes(ques?.question?.type?.toLowerCase())
              ) {
                if (!item?.is_conditional) {
                  if (deletedSecOrder === item?.next_section) {
                    if (sIndex < secIndex) item.next_section = secIndex + 1;
                    else item.next_section = newRemaining;
                  } else if (deletedSecOrder < item?.next_section)
                    item.next_section -= 1;
                } else {
                  if (deletedSecOrder === item?.next_section) {
                    if (sIndex < secIndex) item.next_section = secIndex + 1;
                    else item.next_section = newRemaining;
                    delete item["is_conditional"];
                  } else if (deletedSecOrder < item?.next_section)
                    item.next_section -= 1;
                }
              } else {
                if (item?.next_section === sIndex + 1) item.next_section = null;
                else if (item?.next_section > sIndex + 1)
                  item.next_section -= 1;
              }
            });
        });
    });
    filteredSections[sIndex].is_deleted = true;
    filteredSections[sIndex]?.questions?.forEach((question) => {
      question.is_deleted = true;
    });
    setSurveySections([...surveySections]);
  };

  const handleSectionTitle = (e, sIndex) => {
    filteredSections[sIndex].name = e.target.value;
    setSurveySections([...surveySections]);
  };

  const handleQuestionTitle = (e, sIndex, qIndex) => {
    filteredSections[sIndex].questions.filter(
      (ques) => ques?.is_deleted !== true,
    )[qIndex].question.text = e.target.value;
    setSurveySections([...surveySections]);
  };

  const handleQuestionDesc = (e, sIndex, qIndex) => {
    filteredSections[sIndex].questions.filter(
      (ques) => ques?.is_deleted !== true,
    )[qIndex].question.description = e.target.value;
    setSurveySections([...surveySections]);
  };

  const handleDeleteQuestion = (sIndex, qIndex) => {
    if (
      "question_id" in
      filteredSections[sIndex].questions.filter(
        (ques) => ques?.is_deleted !== true,
      )[qIndex]
    )
      filteredSections[sIndex].questions.filter(
        (ques) => ques?.is_deleted !== true,
      )[qIndex].is_deleted = true;
    else filteredSections[sIndex].questions.splice(qIndex, 1);
    setSurveySections([...surveySections]);
    if (qIndex === 0) setSQIndex(1);
    if ((isEmbedded && embeddedRef) || editRef)
      scrollToIdFunc(`section-${sIndex}-question-${qIndex - 1}`);
  };

  const handleChangeProceed = (e, sIndex, qIndex, itemIndex) => {
    filteredSections[sIndex].questions.filter(
      (question) => question?.is_deleted !== true,
    )[qIndex].question.items[itemIndex].next_section = e.target.value;
    setSurveySections([...surveySections]);
  };

  const handleCardClick = (sIndex, qIndex) => {
    setSSIndex(sIndex + 1);
    setSQIndex(qIndex + 1);
  };

  const scrollToIdFunc = (id) => {
    setTimeout(() => {
      const parentElement = isEmbedded ? embeddedRef.current : editRef.current;
      const childElement = document.getElementById(id);
      if (parentElement && childElement) {
        const parentRect = parentElement?.getBoundingClientRect();
        const childRect = childElement?.getBoundingClientRect();
        const scrollTop =
          childRect?.top -
          parentRect?.top +
          parentElement?.scrollTop -
          parentRect?.height / 2 +
          childRect?.height / 2;

        parentElement?.scrollTo({ top: scrollTop, behavior: "smooth" });
      }
    }, 100);
  };

  useEffect(() => {
    if (surveyData)
      if (surveyData?.title?.trim()?.length < 1) {
        setSaveChangesFlag(false);
        setPublishFlag(false);
        return;
      } else {
        setSaveChangesFlag(true);
        setPublishFlag(true);
      }

    // Validation of sections and next_sections
    if (filteredSections)
      if (
        filteredSections?.length <= 1 &&
        filteredSections[0]?.questions?.filter(
          (question) => question?.is_deleted !== true,
        )?.length < 1
      ) {
        setSaveChangesFlag(false);
        setPublishFlag(false);
        return;
      } else {
        setSaveChangesFlag(true);
        setPublishFlag(true);
      }
  }, [surveyData, filteredSections]);

  const validateEmptySections = () => {
    setProceedError(null);
    setEmptySection(null);
    setAfterSecError(null);
    let hasError = false;
    filteredSections?.some((sec, sIndex) => {
      if (
        sec?.questions?.filter((question) => question?.is_deleted !== true)
          ?.length < 1
      ) {
        hasError = true;
        setEmptySection(sIndex);
        scrollToIdFunc(`section-header-${sIndex}`);
        setOpenPublishSurveyModal(false);
        return true;
      }
      return false;
    });
    return !hasError;
  };

  const setErrorForQuestion = (
    secIndex,
    questionIndex,
    titleError,
    messageError,
  ) => {
    setSSIndex(secIndex + 1);
    setSQIndex(questionIndex + 1);
    setProceedError(questionIndex);
    scrollToIdFunc(`section-${secIndex}-question-${questionIndex}`);
    if (titleError || messageError) {
      setSnackBar({
        open: true,
        severity: "error",
        message: CheckValueLocale(messageError, "", {}, intl),
        title: CheckValueLocale(titleError, "", {}, intl),
      });
    }
    setOpenPublishSurveyModal(false);
    setEmbeddedPublishModal(false);
  };
  const validateProceedOnSave = () => {
    setEmptySection(null);
    setProceedError(null);
    setAfterSecError(null);
    let hasError = false;
    filteredSections?.some((sec, secIndex) => {
      return sec?.questions
        ?.filter((question) => question?.is_deleted !== true)
        ?.some((question, index) => {
          if (!question?.question?.text) {
            hasError = true;
            setErrorForQuestion(
              secIndex,
              index,
              "title_labels_required_question_title",
              "title_labels_required_question_msg",
            );
            return true;
          }
          if (question?.question?.type === "multiple_choice") {
            question?.question?.items?.some((item, labelIndex) => {
              if (item?.[labelIndex + 1] === "") {
                hasError = true;
                setErrorForQuestion(
                  secIndex,
                  index,
                  "title_labels_required_question_title",
                  "title_labels_required_question_msg",
                );
                return true;
              }
            });
          }

          if (
            question?.question?.type === "social_media" &&
            !question.question.constraints.platforms.length
          ) {
            setErrorForQuestion(secIndex, index);
            hasError = true;
            return true;
          }

          if (question?.is_proceed_on_answer === true)
            return question?.question?.items?.some((item) => {
              if (item?.next_section === null) {
                hasError = true;
                setErrorForQuestion(
                  secIndex,
                  index,
                  "proceed_error_title",
                  "proceed_error_msg",
                );
                return true;
              }
              return false;
            });
          return false;
        });
    });
    return !hasError;
  };

  const validateAfterSecOnSave = () => {
    setEmptySection(null);
    setProceedError(null);
    setAfterSecError(null);
    let hasError = false;
    filteredSections?.some((sec, sIndex) => {
      if (sec?.next_section === null) {
        hasError = true;
        setSSIndex(sIndex + 1);
        setAfterSecError(sIndex);
        scrollToIdFunc(`section-footer-${sIndex}`);
        setOpenPublishSurveyModal(false);
        setSnackBar({
          open: true,
          severity: "error",
          title: CheckValueLocale("proceed_error_title", "", {}, intl),
          message: CheckValueLocale("after_section_error_msg", "", {}, intl),
        });
        return true;
      }
      return false;
    });
    return !hasError;
  };

  const validateEmptySecTitle = () => {
    setEmptySection(null);
    setProceedError(null);
    setAfterSecError(null);
    setSecTitleErr(null);
    let hasError = false;
    filteredSections?.some((sec, sIndex) => {
      if (sec?.name?.trim()?.length < 1) {
        hasError = true;
        setSQIndex(null);
        setSSIndex(sIndex + 1);
        setSecTitleErr(sIndex);
        scrollToIdFunc(`section-header-${sIndex}`);
        return true;
      }
      return false;
    });
    return !hasError;
  };

  const validateSecNameDuplication = () => {
    setEmptySection(null);
    setProceedError(null);
    setAfterSecError(null);
    setSecTitleErr(null);
    let hasError = false;
    const nameOccurrences = {};
    filteredSections?.forEach(
      (sec) =>
        (nameOccurrences[sec?.name] = (nameOccurrences[sec?.name] || 0) + 1),
    );
    const duplicatedNames = Object.keys(nameOccurrences)?.filter(
      (name) => nameOccurrences[name] > 1,
    );
    if (duplicatedNames?.length > 0) {
      hasError = true;
      setSnackBar({
        open: true,
        severity: "error",
        message: CheckValueLocale(
          "section_title_duplication_error_msg",
          "",
          {},
          intl,
        ),
      });
    }
    return !hasError;
  };

  // ----------------------------------------------------------------------

  useEffect(() => {
    if (!handlelUserRoles("SURVEY", "EDIT_SURVEY")) navigate("/survey/list");
    if (count === 1) getSurveysById(id);
    else setCount(count + 1);
  }, [count]);

  const getSurveysById = (id) => {
    SurveyController.getSurveysById(id).then((data) => {
      if (data !== undefined) {
        setSurveySections(
          data?.data?.sections?.length > 0
            ? data?.data?.sections
            : [newSection],
        );
        setCustomDomainStatus(data?.custom_domain_status);
        setSurveyLimitReach(data?.survey_limit_reach);
        setIsSurveyEnabled(data?.is_survey_panel_enabled);
        setIsSkeipped(data?.is_skipped);
        setCustomDomain(data?.custom_domain);
        setSurveyData(data?.data);
        setSavedSurveyData(data?.data);
        setSelectedDomains(data?.data?.selected_domains || []);
        setShowPoweredBy(data?.data?.show_powered_by);
        setLogoData({
          ...logoData,
          logoUploaded: data?.data?.logo ? true : false,
          logoImage: data?.data?.logo,
        });
        setIsPublished(data?.data?.published);
        setIsLoading(false);
        setSaveChangesFlag(false);
        if (data?.errorMsg?.status === 404) {
          setIsSurveyDeleted(true);
        }
      }
    });
  };

  const handleSaveChanges = (newLogo, published, publishBtn, embeddedStep) => {
    if (!validateEmptySections()) return;
    else if (!validateProceedOnSave()) return;
    else if (!validateAfterSecOnSave()) return;
    else if (!validateEmptySecTitle()) return;
    else if (!validateSecNameDuplication()) return;
    else if (
      validateUrlInjection(surveyData?.redirection_url, false, 992)?.error
    ) {
      setControlTab("configs");
      setSnackBar({
        open: true,
        severity: "error",
        title: CheckValueLocale(
          "redirection_url_snackbar_error_title",
          "",
          {},
          intl,
        ),
        message: CheckValueLocale(
          "redirection_url_snackbar_error_msg",
          "",
          {},
          intl,
        ),
      });
      return;
    }
    if (discriptionError) return;
    setSaveLoading(true);
    let survey = {};
    var formData = new FormData();
    if (published === true || isPublished) survey.published = true;
    else survey.published = false;
    survey.title = surveyData?.title?.trim();
    survey.subtitle = surveyData?.subtitle?.trim();
    survey.lang = surveyData?.lang;
    survey.lucidya_domain = isLucidyaDomain;
    survey.is_multiresponses = multiRes;
    survey.text_direction = surveyData?.text_direction;
    survey.redirection_url = surveyData?.redirection_url?.trim()?.length
      ? "https://" +
        surveyData?.redirection_url?.replace(/^(https?:\/\/)?/, "")?.trim()
      : "";

    if (surveyData?.survey_type?.toLowerCase() === "embedded_survey") {
      survey.embedded_config = surveyData?.embedded_config;
      formData.append("selected_domains", JSON.stringify(selectedDomains));
    }

    formData.append("survey", JSON.stringify(survey));
    formData.append("sections", JSON.stringify(surveySections));
    if (logoData?.logoImage && typeof logoData?.logoImage === "object")
      formData.append("logo", logoData?.logoImage);
    else if (logoData?.logoImage === "") formData.append("logo", "");

    handleSaveSurveyChanges(formData, published, publishBtn, embeddedStep);
  };

  const handleSaveSurveyChanges = (
    formData,
    published,
    publishBtn,
    embeddedStep,
  ) => {
    SurveyController.saveSurveyChanges(surveyData?.id, formData).then(
      (data) => {
        setCustomDomainStepLoading(true);
        if (data?.status === "success") {
          setSurveySections(
            data?.data?.sections?.length > 0
              ? data?.data?.sections
              : [newSection],
          );
          setCustomDomainStepLoading(false);
          if (published && !isEmbedded) setOpenPublishSurveyModal(true);
          setLogoData({
            ...logoData,
            logoUploaded: data?.data?.logo ? true : false,
            logoUploading: false,
            logoImage: data?.data?.logo,
          });
          setSurveyData(data?.data);
          setSavedSurveyData(data?.data);
          setSelectedDomains(data?.data?.selected_domains || []);
          setIsPublished(data?.data?.published);
          setSaveChangesFlag(false);
          setSaveLoading(false);
          if (publishBtn) {
            if (data?.data?.published) {
              setSnackBar({
                open: true,
                severity: "success",
                title: "",
                message: CheckValueLocale(
                  "survey_published_snackbar_msg",
                  "",
                  {},
                  intl,
                ),
              });
            }
            if (isEmbedded) handleOpenEmbeddedPublishModal(embeddedStep);
            else setOpenPublishSurveyModal(true);
          }
        } else {
          setSaveLoading(false);
          if (
            data?.errorMsg?.response?.data?.survey?.data &&
            Object?.keys(
              data?.errorMsg?.response?.data?.survey?.data,
            )?.includes("redirection_url")
          ) {
            return setSnackBar({
              open: true,
              severity: "error",
              title: CheckValueLocale(
                "redirection_url_snackbar_error_title",
                "",
                {},
                intl,
              ),
              message: CheckValueLocale(
                "redirection_url_snackbar_error_msg",
                "",
                {},
                intl,
              ),
            });
          } else if (
            data?.errorMsg?.response?.data?.exception?.message ==
            "FILE_SIZE_LIMIT_EXCEED"
          ) {
            setSnackBar({
              open: true,
              message: CheckValueLocale("media_logo_size_limit", "", {}, intl),
              severity: "error",
            });
          } else if (
            data?.errorMsg?.response?.data?.exception?.message ==
            "UNSUPPORTED_FILE_TYPE"
          ) {
            setSnackBar({
              open: true,
              message: CheckValueLocale("unsupported_file_type", "", {}, intl),
              severity: "error",
            });
          } else if (
            data?.errorMsg?.response?.data?.exception?.message ==
            "RECORD_NOT_FOUND"
          ) {
            setSnackBar({
              open: true,
              message: CheckValueLocale(
                "failed_to_delete_survey",
                "",
                {},
                intl,
              ),
              severity: "error",
            });
          } else {
            setSnackBar({
              open: true,
              severity: "error",
              title: CheckValueLocale("something_went_wrong", "", {}, intl),
              message: CheckValueLocale("try_again", "", {}, intl),
            });
          }
        }
      },
    );
  };

  useEffect(() => {
    if (!isEmptyObj(surveyData)) {
      let titleValue = surveyTitleLocales?.includes(surveyData?.title)
        ? CheckValueLocale("Untitled Survey", "", {}, intl)
        : surveyData?.title;
      let subTitleValue = surveyTitleLocales?.includes(surveyData?.subtitle)
        ? CheckValueLocale("Survey Description (Optional)", "", {}, intl)
        : surveyData?.subtitle;
      setSurveyData({
        ...surveyData,
        title: titleValue,
        subtitle: subTitleValue,
      });
    }
  }, [intl?.locale, surveyData?.title, surveyData?.subtitle]);

  const handlePublishEmbedded = (specificStep) => {
    if (specificStep === "verify_domain")
      handleSaveChanges("", true, true, "verify_domain");
    else if (saveChangesFlag) handleSaveChanges("", "", true);
    else handleOpenEmbeddedPublishModal();
  };

  const handlePublishBtn = () => {
    if (saveChangesFlag) handleSaveChanges("", "", true);
    else setOpenPublishSurveyModal(true);
  };

  useEffect(() => {
    if (surveyData?.is_multiresponses)
      setMultiRes(surveyData?.is_multiresponses);
  }, [surveyData?.is_multiresponses]);

  const handleMultiRes = (e) => {
    setSaveChangesFlag(true);
    setMultiRes(e.target.checked);
    setSurveyData({ ...surveyData, is_multiresponses: e.target.checked });
  };

  const handleAddQuestion = async (type, sectionIndex = ssIndex - 1) => {
    let sectionQuestions = [...filteredSections?.[sectionIndex]?.questions];
    dummyQuestions?.map((question) => {
      if (question?.question?.type === type) {
        const items = {};
        items.question = {};
        items.question.text = CheckValueLocale(
          question?.question?.text,
          "",
          {},
          intl,
        );
        items.question.description = question?.question?.description;
        items.question.editable = question?.question?.editable;
        items.question.type = question?.question?.type;
        items.is_deleted = question?.is_deleted;
        if (question?.is_proceed_on_answer !== undefined)
          items.is_proceed_on_answer = false;
        if (question?.is_proceed_editable !== undefined)
          items.is_proceed_editable = true;
        items.question.constraints = {};
        Object.keys(question?.question?.constraints).forEach((key) => {
          if (key === "min_number" || key === "max_number") {
            items.question.constraints[key] = {};
            items.question.constraints[key].checked =
              question?.question?.constraints[key]?.checked;
            items.question.constraints[key].value =
              question?.question?.constraints[key]?.value;
          } else {
            items.question.constraints[key] =
              question?.question?.constraints[key];
          }
        });
        if (
          question?.question?.items !== undefined ||
          question?.question?.items?.length >= 0
        ) {
          let arr = [];
          if (question?.question?.items?.length > 0) {
            question.question.items.map((i) => {
              const items = {};
              items[Object.keys(i)[0]] = Object.values(i)[0];
              arr.push(items);
            });
          }
          items.question.items = arr;
        }
        sectionQuestions?.push(items);
      }
    });

    filteredSections[sectionIndex].questions = sectionQuestions;
    await setSurveySections([...surveySections]);
    setSSIndex(sectionIndex + 1);
    setSQIndex(sectionQuestions?.length);
    scrollToIdFunc(
      `section-${sectionIndex}-question-${sectionQuestions?.length - 1}`,
    );
  };

  return {
    id,
    isLoading,
    preview,
    setPreview,
    surveySections,
    logoData,
    surveyData,
    setSurveyData,
    savedSurveyData,
    setSavedSurveyData,
    showPoweredBy,
    saveChangesFlag,
    handlePublishBtn,
    surveyLimitReach,
    handleSaveChanges,
    handleDeleteQuestion,
    setLogoData,
    setSQIndex,
    sqIndex,
    ssIndex,
    setSSIndex,
    setOperationPerformed,
    multiRes,
    handleMultiRes,
    setSaveChangesFlag,
    handleCardClick,
    selectedCountryCode,
    setSelectedCountryCode,
    operationPerformed,
    setOpenPublishSurveyModal,
    setIsLucidyaDomain,
    isLucidyaDomain,
    customDomain,
    customDomainStepLoading,
    setCustomDomainStep,
    customDomainStep,
    isSurveyEnabled,
    isSkeipped,
    setIsSkeipped,
    openPublishSurveyModal,
    surveySections,
    setSurveySections,
    filteredSections,
    handleNewSection,
    handleDuplicateSection,
    handleDeleteSection,
    emptySection,
    setEmptySection,
    handleSectionTitle,
    handleQuestionTitle,
    handleQuestionDesc,
    scrollToIdFunc,
    publishFlag,
    handleChangeProceed,
    proceedError,
    afterSecError,
    secTitleErr,
    editRef,
    snackBar,
    setSnackBar,
    saveLoading,
    handleSectionDir,
    controlTab,
    setControlTab,
    handleChangeControlTab,
    customDomainStatus,
    setDiscriptionError,
    discriptionError,
    isSurveyDeleted,
    isEmbedded,
    settingsTab,
    setSettingsTab,
    handleChangeSettingsTab,
    handleAddQuestion,
    embeddedRef,
    openEmbeddedPublishModal,
    setEmbeddedPublishModal,
    embeddedPublishStep,
    setEmbeddedPublishStep,
    handlePublishEmbedded,
    embeddedPublishLoading,
    currentDomainsPage,
    setCurrentDomainsPage,
    companyDomainsList,
    setCompanyDomainsList,
    companyDomainsData,
    setCompanyDomainsData,
    selectedDomains,
    setSelectedDomains,
  };
};

export default SurveyFunctions;
