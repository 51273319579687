import { Box } from "@mui/material";
import { useState } from "react";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import LucButton from "shared/lucButton/lucButton";
import survey from "images/engagements-images/survey/survey.svg";
import { useSurveyRequest } from "pages/engagements/components/aiAgents/aiAgentsHooks/useSurveyRequest";
import SnackBar from "components/snackBar";
import "./engagementSurvey.scss";

const SurveyDemo = () => {
  const intl = useIntl();

  const {
    mutate: createSurveyRequest,
    isPending: createSurveyRequestLoading,
    isSuccess,
    isError,
  } = useSurveyRequest();
  const [snackBar, setSnackBar] = useState({
    open: false,
    message: "",
  });

  const requestSurveyDemo = () => {
    let queryData = {
      service_name: "request_survey_demo",
    };
    createSurveyRequest(queryData, {
      onSuccess: (data) => {
        if (!data?.errorMsg) {
          setSnackBar({
            open: true,
            message: "farewell_greeting_survey_request_success",
          });
        } else {
          setSnackBar({
            open: false,
            message: "try_again_error_message",
          });
        }
      },
    });
  };

  return (
    <>
      <Box className="engag-survey-no-cdp">
        <img src={survey} />
        <Box className="engag-survey-no-cdp-details">
          <Box className="no-cdp-details-tit">
            {CheckValueLocale("share_survey_no_cdp_tit", "", {}, intl)}
          </Box>
          <Box className="no-cdp-details-desc">
            <Box>
              {CheckValueLocale("share_survey_no_cdp_desc_one", "", {}, intl)}
            </Box>
            <Box>
              {CheckValueLocale("share_survey_no_cdp_desc", "", {}, intl)}
            </Box>
          </Box>
          <LucButton
            loading={createSurveyRequestLoading}
            disabled={createSurveyRequestLoading}
            onClick={() => requestSurveyDemo()}
          >
            {CheckValueLocale("requst_demo", "", {}, intl)}
          </LucButton>
        </Box>
      </Box>
      <SnackBar
        open={snackBar?.open}
        handleClose={() => {
          setSnackBar({ open: false });
        }}
        severity={isSuccess ? "success" : isError ? "error" : undefined}
        message={
          <Box className="ai-agent-snackBar-message">
            {CheckValueLocale(snackBar?.message, "", {}, intl)}
          </Box>
        }
      />
    </>
  );
};

export default SurveyDemo;
