import { useState } from "react";
import { useIntl } from "react-intl";
import { Button, Box, Typography, Divider } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { CheckValueLocale, commasAfterDigit } from "utils/helpers";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AppliedFilters from "../appliedFilters.js/appliedFiltersContent";
import "./confirmationTokens.scss";
import { LoadingButton } from "@mui/lab";
import Services from "services/api/Services";

const ConfirmationPopUp = ({
  setShowConfirmationPopup,
  startDate,
  endDate,
  filterParams,
  tokens,
  setActiveQuestion,
  activeQuestion,
  createLuciQuestion,
  pageParams,
  currentToken,
  isCreateQuestionLoading,
  isCAPage,
  isSurvey,
  isBenchmark,
}) => {
  const intl = useIntl();
  const [showAppliedFilters, setShowAppliedFilters] = useState(true);

  const handleClose = () => {
    setShowConfirmationPopup(false);
    setActiveQuestion({});
  };
  const handleTokens = () => {
    if (currentToken >= tokens) {
      createLuciQuestion(pageParams, isCAPage);
      if (isSurvey) {
        handleClose();
      }
    } else {
      handleClose();
    }
  };
  return (
    <Box className="confirmation-pop-up-container">
      <Box className="confirm-content-conainer">
        <Button id="btn-close-confirm-dialog" onClick={handleClose}>
          <FontAwesomeIcon icon={faTimes} />
        </Button>
        <Typography
          id="luci-confirm-dialog-title"
          className="luci-energy-not-enough"
          component={"p"}
        >
          {CheckValueLocale(
            currentToken >= tokens
              ? "luci_confirim_dialg_title"
              : "luci_energy_noy_enough",
            "",
            {},
            intl,
          )}
        </Typography>
        {currentToken < tokens ? (
          <Typography
            id="luci-confirm-dialog-title"
            className="luci-energy-not-enough-desc"
            component={"p"}
          >
            {CheckValueLocale("luci_confirim_desc_dialg_title", "", {}, intl)}
          </Typography>
        ) : null}
        <Box className="confirm-tokens-container">
          <Box className="confirm-tokens-content">
            <Box className="confirm-tokens-energy-level">
              <Typography component={"span"} className="confirm-token-value">
                {CheckValueLocale("luci_energy_level", "", {}, intl)}
              </Typography>
              <Box className="confirm-tokens-header">
                <Typography component={"span"} id="confirm-token-count">
                  {currentToken ? commasAfterDigit(currentToken) : 0}
                </Typography>
                <img
                  src={Services?.tokenIcon}
                  alt="token icon"
                  id="confirm-token-icon"
                />
              </Box>
            </Box>

            <Box className="confirm-tokens-request-energy">
              <Typography component={"span"} className="confirm-token-value">
                {CheckValueLocale("luci_request_energy", "", {}, intl)}
              </Typography>
              <Box className="confirm-tokens-header">
                <Typography component={"span"} id="confirm-token-count">
                  {tokens ? commasAfterDigit(tokens) : 0}
                </Typography>
                <img
                  src={Services?.tokenIcon}
                  alt="token icon"
                  id="confirm-token-icon"
                />
              </Box>
            </Box>
            {currentToken >= tokens ? (
              <Divider className="confirm-pop-up-divider" />
            ) : null}
            {!showAppliedFilters ? (
              <AppliedFilters
                filterParams={filterParams}
                startDate={startDate}
                endDate={endDate}
              />
            ) : null}

            {currentToken >= tokens ? (
              <Box id="luci-applied-filters">
                <Typography
                  className={
                    showAppliedFilters
                      ? "luci-show-applied-filters"
                      : "luci-hide-applied-filters"
                  }
                  onClick={() => setShowAppliedFilters(!showAppliedFilters)}
                >
                  {CheckValueLocale(
                    showAppliedFilters
                      ? isBenchmark
                        ? "benchmark_criteria"
                        : "luci_show_applied_filters"
                      : "luci_hide_applied_filters",
                    "",
                    {},
                    intl,
                  )}
                  <ArrowDropDownIcon />
                </Typography>
              </Box>
            ) : null}
          </Box>
        </Box>
        <Box>
          {currentToken < tokens ? (
            <Button
              id="luci-recharge-energy"
              onClick={() => window.open("/settings/subscription", "_blank")}
            >
              {CheckValueLocale("luci_recharge_energy", "", {}, intl)}
            </Button>
          ) : null}
          <LoadingButton
            id="luci-confirm-request"
            onClick={handleTokens}
            variant="contained"
            loading={isCreateQuestionLoading}
            className={isCreateQuestionLoading && "luci-request-loaded"}
          >
            {!isCreateQuestionLoading
              ? CheckValueLocale(
                  currentToken >= tokens
                    ? "luci_confirm_request"
                    : "luci_confirm_understand",
                  "",
                  {},
                  intl,
                )
              : null}
            {currentToken >= tokens && !isCreateQuestionLoading ? (
              <ArrowForwardIcon />
            ) : null}
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};
export default ConfirmationPopUp;
