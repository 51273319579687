import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import OverView from "components/widgets/overViewWidget.js";
import StackedBarWidget from "components/widgets/stackedBarWidget";
import StackedLineWidget from "components/widgets/stackedLineWidget";
import ExlporeTopPosts from "./exlporeTopPosts/exlporeTopPosts";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import _ from "lodash";
import "./overviewExplore.scss";

const OverviewExplore = ({
  preLoaderTrackerDataSources,
  topPostsPreloader,
  setTopPostsPreloader,
  topPostsCount,
  setTopPostsCount,
  topPosts,
  setTopPosts,
  exploreId,
  getShowExploreData,
  resetAllFields,
  netSentiment,
  overViewPreLoaderDataSources,
  overview,
  setOverview,
  overviewArray,
  setOverviewArray,
  noOfDatasources,
  setNoOfDatasources,
  sentimentPieChart,
  setSentimentPieChart,
  setSentimentAnalysisCopy,
  interactionsPreLoader,
  setInteractionsPreLoader,
  interactions,
  setInteractions,
  interactionsLegend,
  setInteractionsLegend,
  isInteractionsHourlyFormat,
  setNetSentiment,
  setOverViewPreLoaderDataSources,
  sentimentAnalysisCopy,
  sentimentAnalysisPreLoader,
  setSentimentAnalysisPreLoader,
  sentimentAnalysis,
  setSentimentAnalysis,
  isSentimentAnalysisHourlyFormat,
  setDisableCreateExplore,
  filterParams,
  setPageNumber,
  pageNumber,
}) => {
  const intl = useIntl();
  const [showAnalytics, setShowAnalytics] = useState(false);
  ///

  const [sortBy, setSortBy] = useState("latest_posts");
  const sortByData = ["latest_posts", "most_interactions", "newest"];
  // Child Props
  const childProps = {
    overview,
    setOverview,
    sentimentAnalysis,
    setSentimentAnalysis,
    sentimentPieChart,
    setSentimentPieChart,
    sentimentAnalysisPreLoader,
    setSentimentAnalysisPreLoader,
    setSentimentAnalysisCopy,
    noOfDatasources,
    setNoOfDatasources,
    overviewArray,
    setOverviewArray,
    netSentiment,
    setNetSentiment,
    overViewPreLoaderDataSources,
    setOverViewPreLoaderDataSources,
    showAnalytics,
    setShowAnalytics,
    interactionsPreLoader,
    setInteractionsPreLoader,
    interactions,
    setInteractions,
    interactionsLegend,
    setInteractionsLegend,
    setTopPostsPreloader,
    setTopPostsCount,
    setTopPosts,
    preLoaderTrackerDataSources,
    topPostsPreloader,
    topPostsCount,
    topPosts,
    exploreId,
    getShowExploreData,
    resetAllFields,
    setDisableCreateExplore,
    filterParams,
    setPageNumber,
    pageNumber,
  };

  useEffect(() => {
    if (
      _.isEqual(
        sentimentAnalysisPreLoader?.sort(),
        preLoaderTrackerDataSources?.sort(),
      ) === true
    ) {
      let updatedSentimentAnalysisCopy = {
        negative: [],
        positive: [],
        neutral: [],
      };
      handleSentimentAggregate(
        sentimentAnalysisCopy?.negative,
        updatedSentimentAnalysisCopy.negative,
      );
      handleSentimentAggregate(
        sentimentAnalysisCopy?.neutral,
        updatedSentimentAnalysisCopy.neutral,
      );
      handleSentimentAggregate(
        sentimentAnalysisCopy?.positive,
        updatedSentimentAnalysisCopy.positive,
      );
      setSentimentAnalysis(updatedSentimentAnalysisCopy);
    }
  }, [sentimentAnalysisPreLoader]);

  const handleSentimentAggregate = (valueToAggregate, updatedValue) => {
    valueToAggregate?.map((arr, index) => {
      let updatedVal = 0;
      if (noOfDatasources[index] !== 0)
        updatedVal = arr?.value / noOfDatasources[index];
      else updatedVal = arr?.value;
      updatedValue.push({
        name: arr?.name,
        value: updatedVal,
      });
    });
  };

  return (
    <Box className="overview-analytics-search">
      <Box className="overview-section-search">
        {/* Overview Section */}
        <Box className="overview-section-head">
          {CheckValueLocale("overview", "", {}, intl)}
        </Box>
        <Box>
          <OverView
            title={"Analytics"}
            data={overview}
            isInsightsPage={true}
            isQuickExplore
            overViewPreLoaderDataSources={overViewPreLoaderDataSources}
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          />
        </Box>

        {showAnalytics ? (
          <Box className="analytics-section-search">
            <Box className="overview-section-head">
              {CheckValueLocale("analytics", "", {}, intl)}
            </Box>

            <Grid container spacing={2.5} mb={2.5}>
              <Grid item xs={6}>
                <StackedBarWidget
                  title={"mention_overtime"}
                  titleToolTip={"mention_overtime_tooltip"}
                  showDownloadIcon
                  bigImg
                  showScales
                  posts={interactions}
                  colors={["#696D72", "#1DA1F2"]}
                  labels={interactionsLegend}
                  showGroupBy={false}
                  showZoomPercentage
                  dataPiechart={interactionsLegend}
                  postsColumnPreLoaderDataSources={interactionsPreLoader}
                  preLoaderTrackerDataSources={preLoaderTrackerDataSources}
                  hideLegend
                  isQuickExplore
                  isHourlyFormat={isInteractionsHourlyFormat}
                />
              </Grid>
              <Grid item xs={6}>
                <StackedLineWidget
                  title={"sentiment_analysis"}
                  titleToolTip={"quick_search_sentiment_tooltip"}
                  data={sentimentAnalysis}
                  dataPiechart={sentimentPieChart}
                  showGroupBy={false}
                  areaStyle={true}
                  toolTipLabel={"toolTipLabel"}
                  showAI
                  showDownloadIcon
                  bigImg
                  isSentimentAnalysisWidget
                  isSentimentAnalysisShowCharts
                  isSwitchCase
                  hideLegend
                  labelColors={["#89BB2A", "#E50035", "#F9A700"]}
                  isQuickExplore
                  stackedLineWidgetPreLoaderDataSources={
                    sentimentAnalysisPreLoader
                  }
                  preLoaderTrackerDataSources={preLoaderTrackerDataSources}
                  isHourlyFormat={isSentimentAnalysisHourlyFormat}
                />
              </Grid>
            </Grid>
          </Box>
        ) : null}

        <Button
          onClick={() => setShowAnalytics(!showAnalytics)}
          variant="outlined"
          className="hide-show-analytics"
          startIcon={
            !showAnalytics ? (
              <KeyboardArrowDownIcon className="arrow-analytic-icon" />
            ) : (
              <KeyboardArrowUpIcon className="arrow-analytic-icon" />
            )
          }
        >
          {CheckValueLocale(
            showAnalytics ? "hide_analytics" : "show_analytics",
            "",
            {},
            intl,
          )}
        </Button>
      </Box>

      {/* Posts Section */}
      <ExlporeTopPosts
        setPageNumber={setPageNumber}
        pageNumber={pageNumber}
        sortBy={sortBy}
        setSortBy={setSortBy}
        sortByData={sortByData}
        {...childProps}
      />
    </Box>
  );
};

export default OverviewExplore;
