import { Box, Popover, TextField, Tooltip, Typography } from "@mui/material";
import {
  CheckValueLocale,
  classNames,
  isEmptyObj,
} from "utils/helpers/index.js";
import { useIntl } from "react-intl";
import { faSpinnerThird, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faCircleArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import { faFaceGrinWide } from "@fortawesome/free-regular-svg-icons";
import { faAt } from "@fortawesome/pro-regular-svg-icons";
import "./noteSidebar.scss";
import { useEffect, useRef, useState } from "react";
import NoteSidebarCardLoader from "./noteSidebarCardLoader";
import UserAvatar from "shared/unifiedUserImage/userAvatar";
import { useFormik } from "formik";
import * as Yup from "yup";
import useCreateEngagementNote from "./hooks/useCreateEngagementNote";
import useGetNotesUsersList from "./hooks/useGetNotesUsersList";
import NoteCardItem from "./noteCardItem";
import useGetNotesList from "./hooks/useGetNotesList";
import mqttRabbitMQResponce from "services/controllers/mqttRabbitMQResponce";
import EngagementsController from "services/controllers/engagementsController";
import Picker from "@emoji-mart/react";
import emojiData from "@emoji-mart/data"; // renamed to avoid conflicts
import SnackBar from "components/snackBar";
import LucButton from "shared/lucButton/lucButton";
import { EmailNotifierPopup } from "./components/emailNotifierPopup";
import _ from "lodash";

const NotesSidebar = ({
  setOpenNotesSidebar,
  monitorId,
  interactionId,
  dataSourceName,
  productId,
  userId,
  setNotesCount,
  interactionCreatedAt,
  currentUserId,
}) => {
  const intl = useIntl();
  const fullName = localStorage.getItem("name");
  const [isLoadingNotes, setIsLoadingNotes] = useState(true);
  const [isFocused, setIsFocused] = useState(false);
  const [openMentionMenu, setOpenMentionMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [response, setResponse] = useState({});
  const clientMQTT = useRef();
  const [formattedNotes, setFormattedNotes] = useState([]);
  const inputRef = useRef();
  const [listOfUsers, setListOfUsers] = useState([]);
  const [anchorElEmoji, setAnchorElEmoji] = useState(null);
  const [anchorPosition, setAnchorPosition] = useState(null);
  const [syncNoteWithAgents, setSyncNoteWithAgents] = useState(false);
  const [snackbarConfig, setSnackbarConfig] = useState({
    message: "",
    body: "",
    severity: "", // Default severity
  });
  const [hasVerticalScrollbar, setHasVerticalScrollbar] = useState(false);
  const [loadMore, setLoadMore] = useState(false); // Load more state
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const [hasNextPage, setHasNextPage] = useState(false); // Track if more pages exist

  const [currentlyEditedNoteId, setCurrentlyEditedNoteId] = useState(null);
  const isLoading = isLoadingNotes;

  const { mutate: createNote, isPending: isSubmitting } =
    useCreateEngagementNote();

  const { data: usersList } = useGetNotesUsersList({
    monitor_id: monitorId,
    product_id: productId,
    data_source_name: dataSourceName,
    interaction_id: interactionId,
  });

  const { data: notesList, refetch: refetchNotes } = useGetNotesList({
    monitor_id: monitorId,
    product_id: productId,
    data_source_name: dataSourceName,
    interaction_id: interactionId,
    page_number: currentPage,
    exclude_note_id:
      formattedNotes?.length && currentPage > 1
        ? formattedNotes?.[formattedNotes?.length - 1]?.id
        : undefined,
  });

  useEffect(() => {
    if (notesList?.data?.data?.password) {
      if (currentPage === 1 || isSubmitting) {
        setIsLoadingNotes(true);
      }
      mqttRabbitMQResponce(clientMQTT, notesList?.data?.data, setResponse);
    }
  }, [notesList]);

  useEffect(() => {
    const getAgents = () => {
      // Call API to get agents
      EngagementsController.getUsers(window.localStorage.engagements_id).then(
        (res) => {
          if (!res?.errorMsg && res?.data?.data?.length) {
            const agents = res?.data?.data?.map((agent) => ({
              ...(agent?.attributes || {}),
            }));
            setListOfUsers(agents);
          }
        },
      );
    };
    getAgents();
  }, []);

  useEffect(() => {
    if (!isEmptyObj(response)) {
      switch (response?.eventName) {
        case "Twitter_public__EngagementsNotesPage":
        case "Instagram_public__EngagementsNotesPage":
        case "Facebook_public__EngagementsNotesPage":
        case "Facebook__EngagementsNotesPage":
        case "Instagram__EngagementsNotesPage":
        case "Twitter__EngagementsNotesPage":
        case "Instagramprivate__EngagementsNotesPage":
        case "Twitterprivate__EngagementsNotesPage":
        case "Facebookprivate__EngagementsNotesPage":
        case "Whatsappprivate__EngagementsNotesPage": {
          // should be changed to be simple array not array of array
          const eventData = response?.eventData?.notes_list || [];
          const renderedNotes =
            eventData?.map((item = {}) => {
              return {
                id: item?.note_id,
                author: "",
                text: item?.text,
                timestamp: new Date(item?.created_at),
                profilePicture: "",
                userId: item?.user_id,
                is_edit: item?.is_edit,
                updatedAt: new Date(item?.updated_at),
              };
            }) || []; // reverse should be removed after the backend fix the order
          if (response?.eventData?.notes_count) {
            setNotesCount(response?.eventData?.notes_count);
          }

          if (currentPage === 1) {
            setFormattedNotes(renderedNotes);
          } else {
            setFormattedNotes((prevNotes) => {
              return [...prevNotes, ...renderedNotes];
            });
          }
          setHasNextPage(response?.eventData?.next_page);
          setLoadMore(false); // Reset loadMore after fetching
        }
      }
      setSyncNoteWithAgents(true);
    }
  }, [response]);

  const listRef = useRef(null); // Ref for the notes container
  const loadMoreRef = useRef(null); // Ref for the load more button
  useEffect(() => {
    const element = listRef.current;

    const checkScroll = () => {
      const element = listRef.current;
      const btn = loadMoreRef.current;
      if (element) {
        let btnHeight = btn?.clientHeight ? btn?.clientHeight : 20;
        setHasVerticalScrollbar(
          element.scrollHeight > element.clientHeight + btnHeight,
        );
      }
    };

    if (element) {
      // element.addEventListener("scroll", checkScroll);
      window.addEventListener("resize", checkScroll);

      return () => {
        // element.removeEventListener("scroll", checkScroll);
        window.removeEventListener("resize", checkScroll);
      };
    }
  }, [formattedNotes]);

  useEffect(() => {
    if (syncNoteWithAgents && listOfUsers?.length) {
      setFormattedNotes((previous) => {
        return previous.map((item) => {
          const foundedAgent = listOfUsers?.find?.(
            (agent) => agent?.id == item?.userId,
          );
          return {
            ...item,
            author: foundedAgent?.name || "user",
            profilePicture: foundedAgent?.avatar || "",
          };
        });
      });
      setIsLoadingNotes(false);
      setSyncNoteWithAgents(false);
    }
  }, [syncNoteWithAgents, listOfUsers?.length]);

  const handleShowSnackbar = (config) => {
    setSnackbarConfig({ ...config, open: true });
  };

  const deleteNoteById = (id) => {
    setFormattedNotes((previous) =>
      previous?.filter?.((item) => item?.id != id),
    );
    setNotesCount((notes) => notes - 1);
  };

  const updateNoteId = (id, updatedNote = {}) => {
    setFormattedNotes((previous) =>
      previous.map((item) => {
        return item?.id == id ? { ...item, ...updatedNote } : item;
      }),
    );
  };

  const formik = useFormik({
    initialValues: { note: "" },
    validationSchema: Yup.object({
      note: Yup.string().trim().max(500, "max_note_length_error"),
    }),
    onSubmit: (values, { resetForm }) => {
      handleSubmit(values?.note?.trim(), resetForm);
    },
  });

  const assignedUser = currentUserId != userId && +userId ? [+userId] : [];

  // Separate function to handle form submission
  const handleSubmit = (text, resetForm) => {
    // Check if a note is being edited
    if (currentlyEditedNoteId) {
      // Reset the currently edited note
      setCurrentlyEditedNoteId(null); // Exit edit mode
    }
    const createParams = {
      monitor_id: monitorId,
      interaction_assigned_user_id: userId || 0,
      interaction_id: interactionId,
      data_source_name: dataSourceName,
      product_id: productId,
      text,
      notified_users: _.uniq([
        ...(selectedUsers?.map?.((user) => user?.id) || []),
        ...assignedUser,
      ]),
      interaction_created_at: interactionCreatedAt,
    };
    createNote(createParams, {
      onSuccess: () => {
        resetForm(); // Reset form properly
        setIsFocused(false);
        setSelectedUsers([]);

        if (currentPage == 1) {
          refetchNotes();
        }
        setCurrentPage(1);
        if (inputRef.current) {
          inputRef.current?.blur();
        }
      },
      onError: () => {
        setSnackbarConfig({
          message: "try_again_error_message",
          severity: "error",
          title: "failed_error_message",
          open: true,
        });
      },
    });
  };

  const isMentionOrEmojiClick = useRef(false);
  const handleBlur = (e) => {
    if (!isMentionOrEmojiClick.current) {
      const trimmedValue = formik.values.note?.trim(); // Remove spaces and empty lines
      if (!trimmedValue) {
        formik.setFieldValue("note", "", false); // Reset field to truly empty
        setIsFocused(false);
      }
    }
    isMentionOrEmojiClick.current = false;
  };

  // Handle Opening the Mention Menu
  const handleMentionOpen = (event) => {
    isMentionOrEmojiClick.current = true;
    setAnchorEl(event.currentTarget);
    setOpenMentionMenu(true);
  };

  // Handle Closing the Mention Menu
  const handleMentionClose = () => {
    setOpenMentionMenu(false);
    setAnchorEl(null);
    inputRef.current?.focus?.();
    setIsFocused(true);
  };
  const handleUserSelect = (user) => {
    setSelectedUsers((prevUsers) => {
      const isSelected = prevUsers?.some((u) => u?.email === user?.email);
      return isSelected
        ? prevUsers.filter((u) => u?.email !== user?.email)
        : [...prevUsers, user];
    });
  };
  const requestParams = {
    monitor_id: monitorId,
    product_id: productId,
    data_source_name: dataSourceName,
    interaction_id: interactionId,
    interaction_assigned_user_id: userId || 0,
    notified_users: _.uniq([
      ...(selectedUsers?.map?.((user) => user?.id) || []),
      ...assignedUser,
    ]),
  };

  // Handle Open Emoji Picker
  const handleOpenEmoji = (event) => {
    isMentionOrEmojiClick.current = true;

    if (!event?.currentTarget) return;

    // Get the emoji button's position
    const rect = event?.currentTarget?.getBoundingClientRect();

    if (intl?.locale === "en") {
      setAnchorPosition({
        top: rect?.bottom - 50, // Position below the button
        left: rect?.left - 25, // Adjust horizontal position
      });
    } else {
      setAnchorPosition({
        top: rect?.bottom - 50, // Position below the button
        left: rect?.left + 410, // Adjust horizontal position
      });
    }
    setAnchorElEmoji(event?.currentTarget);
  };

  // Handle Close Emoji Picker
  const handleCloseEmoji = () => {
    setAnchorElEmoji(null);
    inputRef.current?.focus?.();
    setIsFocused(true);
  };
  // Handle Emoji Selection
  const handleEmojiSelect = (emoji) => {
    formik.setFieldValue("note", formik?.values?.note + emoji?.native);
  };

  const handlePagination = () => {
    if (!isLoading && !loadMore && hasNextPage) {
      setLoadMore(true);
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };
  const handleScroll = (event) => {
    const target = event.target;
    if (
      Math.trunc(target.scrollHeight) -
        Math.trunc(target.scrollTop) -
        Math.trunc(target.clientHeight) <=
      40
    ) {
      handlePagination();
    }
  };
  return (
    <Box className="notes-sidebar-wrapper">
      <Popover
        open={Boolean(anchorElEmoji)}
        onClose={handleCloseEmoji}
        anchorReference="anchorPosition" // Use fixed position reference
        anchorPosition={anchorPosition}
        transformOrigin={{
          vertical: "top", // Ensures it opens downward
          horizontal: "right", // Matches the anchor position
        }}
        // anchorEl={anchorElEmoji}
        classes={{
          paper: "reply-box-emoji-popover",
          root: "reply-box-emoji-popover-root",
        }}
      >
        <Picker data={emojiData} onEmojiSelect={handleEmojiSelect} />
      </Popover>
      <Box className="notes-content-sidebar-wrapper">
        <Box className="notes-header">
          {CheckValueLocale("notes_button_tooltip", "", {}, intl)}
          <FontAwesomeIcon
            icon={faTimes}
            onClick={() => setOpenNotesSidebar(false)}
            cursor={"pointer"}
          />
        </Box>
        {/* input field */}
        <Box className="sticky-input-wrapper">
          {/* Icons inside input field */}
          <TextField
            fullWidth
            name="note"
            placeholder={CheckValueLocale("add_note", "", {}, intl)}
            className={`custom-textfield note-text-field-custom ${isFocused ? "expanded" : "default"}`}
            multiline
            minRows={1}
            inputRef={inputRef}
            maxRows={5}
            InputProps={{
              className: `custom-input-text ${isFocused ? "expanded" : "default"}`,
              endAdornment: (
                <Box
                  className={`icons-wrapper ${formik.values.note?.length || isFocused ? "expanded" : "default"}`}
                >
                  {isFocused || formik.values.note?.length ? ( // Icons appear only when input is focused
                    <Box
                      className="note-icons-left"
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => {
                        inputRef.current?.focus?.();
                        setIsFocused(true);
                      }}
                    >
                      {/* emoji icon */}
                      <Tooltip
                        title={CheckValueLocale(
                          "notes_emoji_icon",
                          "",
                          {},
                          intl,
                        )}
                        placement="bottom"
                        arrow
                        classes={{ popper: "notes-icons-tooltip" }}
                      >
                        <Box>
                          <LucButton
                            disabled={isSubmitting}
                            onClick={handleOpenEmoji}
                            type="secondary"
                            variant="text"
                            className="notes-footer-button"
                            id="notes-footer-emoji-button"
                            minWidth={32}
                            onMouseDown={(e) => e.preventDefault()}
                          >
                            <FontAwesomeIcon icon={faFaceGrinWide} />
                          </LucButton>
                        </Box>
                      </Tooltip>
                      {/* email notification icon */}
                      <Tooltip
                        title={CheckValueLocale(
                          "email_notification",
                          "",
                          {},
                          intl,
                        )}
                        placement="bottom"
                        arrow
                        classes={{ popper: "notes-icons-tooltip" }}
                      >
                        <Box>
                          <LucButton
                            disabled={isSubmitting}
                            onClick={handleMentionOpen}
                            type="secondary"
                            variant="text"
                            className="notes-footer-button mention-icon "
                            id="notes-footer-mention-button"
                            minWidth={32}
                            onMouseDown={(e) => e.preventDefault()}
                          >
                            <FontAwesomeIcon icon={faAt} />
                            {selectedUsers?.length > 0 ? (
                              <Typography className="mention-notified-count">
                                {CheckValueLocale(
                                  "notified_users",
                                  "",
                                  {
                                    num: selectedUsers?.length,
                                  },
                                  intl,
                                )}
                              </Typography>
                            ) : null}
                          </LucButton>
                        </Box>
                      </Tooltip>
                      <Box className="send-icon-expand">
                        <LucButton
                          disabled={
                            !!formik?.errors?.note ||
                            !formik.values.note?.trim().length
                          }
                          onClick={() => !isSubmitting && formik.handleSubmit()}
                          type="secondary"
                          variant="text"
                          className="notes-footer-button send-button-note"
                          id="notes-footer-send-button"
                          minWidth={32}
                          loading={isSubmitting}
                          size="small"
                          onMouseDown={(e) => e.preventDefault()}
                        >
                          <FontAwesomeIcon icon={faCircleArrowDown} />
                        </LucButton>
                      </Box>
                    </Box>
                  ) : null}
                  <Box className="send-icon">
                    <LucButton
                      disabled={true}
                      type="secondary"
                      variant="text"
                      className={classNames(
                        "notes-footer-button send-button-note send-button",
                        formik?.values?.note || isFocused ? "active" : "",
                      )}
                      id="notes-footer-send-button-hidden"
                      minWidth={32}
                      size="small"
                    >
                      <FontAwesomeIcon icon={faCircleArrowDown} />
                    </LucButton>
                  </Box>
                </Box>
              ),
            }}
            onFocus={() => setIsFocused(true)}
            onBlur={handleBlur}
            value={formik.values.note}
            onChange={formik.handleChange}
            error={!!formik.errors.note}
            helperText={CheckValueLocale(formik.errors.note, "", {}, intl)}
          />
        </Box>
        {/* Mention dropdown */}
        <EmailNotifierPopup
          options={usersList?.users || []}
          open={openMentionMenu}
          onClose={handleMentionClose}
          anchorEl={anchorEl}
          handleSelect={handleUserSelect}
          selectedOptions={selectedUsers}
        />
        {/* Content Area */}
        {isLoading ? (
          <NoteSidebarCardLoader />
        ) : (
          <Box
            className={`notes-content ${formattedNotes?.length > 0 ? "with-notes" : "empty"}`}
            ref={listRef}
            onScroll={handleScroll}
          >
            {formattedNotes?.length > 0 ? (
              formattedNotes?.map((noteItem = {}, index) => (
                <NoteCardItem
                  key={noteItem?.id}
                  note={noteItem}
                  showDivider={index !== formattedNotes?.length - 1} // No divider after last item
                  requestParams={requestParams}
                  onDelete={deleteNoteById}
                  onUpdate={updateNoteId}
                  isEditing={currentlyEditedNoteId === noteItem?.id}
                  onEdit={() => setCurrentlyEditedNoteId(noteItem?.id)}
                  onCancel={() => setCurrentlyEditedNoteId(null)}
                  onShowSnackbar={handleShowSnackbar}
                />
              ))
            ) : (
              <Box className="note-text-content">
                <UserAvatar
                  fullName={fullName}
                  avatarSrc={localStorage.getItem("avatar")}
                  sizes={{ ratio: "40px", fontSize: "16px" }}
                  isBgDark={true}
                />
                <Typography className="notes-empty-state-title">
                  {CheckValueLocale("add_note_empty_state_title", "", {}, intl)}
                </Typography>
                <Typography className="notes-empty-state-subtitle">
                  {CheckValueLocale(
                    "add_note_empty_state_sub_title",
                    "",
                    {},
                    intl,
                  )}
                </Typography>
              </Box>
            )}
            {hasNextPage && (!hasVerticalScrollbar || loadMore) ? (
              <Box className="pagination-loader" ref={loadMoreRef}>
                <Box
                  id={"notes-load-more"}
                  className={`pagination-loader-btn ${loadMore && "disabled"}`}
                  onClick={!loadMore && handlePagination}
                >
                  {loadMore ? (
                    <FontAwesomeIcon icon={faSpinnerThird} spin />
                  ) : (
                    <FontAwesomeIcon icon={faArrowDown} />
                  )}
                </Box>
              </Box>
            ) : null}
          </Box>
        )}
      </Box>
      <SnackBar
        handleClose={() =>
          setSnackbarConfig({ ...snackbarConfig, open: false })
        }
        open={snackbarConfig?.open}
        severity={snackbarConfig?.severity}
        message={CheckValueLocale(snackbarConfig?.message, "", {}, intl)}
        title={CheckValueLocale(snackbarConfig?.title, "", {}, intl)}
      />
    </Box>
  );
};

export default NotesSidebar;
