import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Box, Typography } from "@mui/material";
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckValueLocale } from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";
import LinkedPreview from "../components/linkedPreview";
import EmbeddedPreview from "../components/embeddedPreview";
import "../style.scss";

const PreviewPage = (props) => {
  const {
    surveyData,
    filteredSections,
    setPreview,
    logoData,
    showPoweredBy,
    isEmbedded,
  } = props;

  const intl = useIntl();

  const [sectionsStack, setSectionsStack] = useState([0]);
  const [sectionIndex, setSectionIndex] = useState(null);
  const [afterSection, setAfterSection] = useState(null);
  const [nextSection, setNextSection] = useState(null);
  const [btnVal, setBtnVal] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [errorForm, setErrorForm] = useState({});
  const [progressValue, setProgressValue] = useState(0);
  const handleBackToBuilder = () => setPreview(false);

  const [openedPopup, setOpenedPopup] = useState(false);

  useEffect(() => setSectionIndex(0), []);

  useEffect(() => {
    if (filteredSections?.length < 2) {
      setNextSection(null);
      setAfterSection(-1);
      setBtnVal(-1);
      return;
    } else {
      setNextSection(null);
      setAfterSection(filteredSections[sectionIndex]?.next_section);
      setBtnVal(filteredSections[sectionIndex]?.next_section);
    }

    const newQuestionErrors = {};
    filteredSections?.[sectionIndex]?.questions
      ?.filter((ques) => ques?.is_deleted !== true)
      ?.forEach((question, index) => {
        newQuestionErrors[index + 1] = false;
      });
    setErrorForm(newQuestionErrors);
  }, [sectionIndex]);

  useEffect(() => {
    if (nextSection !== null) return setBtnVal(nextSection);
    else if (afterSection !== null) return setBtnVal(afterSection);
    else setBtnVal(-1);
  }, [afterSection, nextSection]);

  useEffect(() => {
    if (btnVal === -1 || btnVal === null) setProgressValue(100);
    else {
      let val = (sectionIndex / (filteredSections?.length - 1)) * 100;
      setProgressValue(val);
    }
  }, [btnVal]);

  const handleProceedAnswer = (nextSection) => {
    if (nextSection === null) return setNextSection(null);
    else setNextSection(Number(nextSection));
  };

  const handleSubmit = (e) => {
    const errorQuestions = Object?.values(errorForm)?.some((value) => value);
    if (!errorQuestions) setSubmitted(true);
  };
  const handleClickNext = () => {
    const errorQuestions = Object?.values(errorForm)?.some((value) => value);
    if (btnVal !== null && !errorQuestions) {
      setSectionIndex(btnVal - 1);
      let stackArr = [...sectionsStack];
      stackArr?.push(btnVal - 1);
      setSectionsStack(stackArr);
    }
  };
  const handleClickBack = (e) => {
    setSectionIndex(sectionsStack?.slice(-2)[0]);
    let newStack = [...sectionsStack];
    newStack?.pop();
    setSectionsStack(newStack);
  };

  const handleCloseEmbedded = async () => {
    setOpenedPopup(false);
    setSectionsStack([0]);
    setSectionIndex(0);
    setAfterSection(null);
    setNextSection(null);
    setBtnVal(null);
    setProgressValue(0);
    await setSubmitted(false);
  };

  let directionSection = filteredSections?.[sectionIndex]?.text_direction;
  let surveyDirection = surveyData?.text_direction;

  let sectionSurveyDirection =
    filteredSections?.length <= 1
      ? surveyDirection
      : directionSection
        ? directionSection
        : surveyDirection
          ? surveyDirection
          : intl?.locale == "en"
            ? "ltr"
            : "rtl";

  const childProps = {
    filteredSections,
    sectionIndex,
    surveyData,
    logoData,
    handleBackToBuilder,
    submitted,
    surveyDirection,
    sectionSurveyDirection,
    sectionsStack,
    progressValue,
    handleClickNext,
    handleClickBack,
    handleProceedAnswer,
    btnVal,
    handleSubmit,
    showPoweredBy,
    setErrorForm,
  };

  return (
    <Box id="survey-live-preview-container">
      <Box className="survey-live-preview-header">
        <Box>
          <LucButton
            id="survey-live-preview-header-back-btn"
            variant="flat"
            type="secondary"
            size="small"
            minWidth={36}
            onClick={handleBackToBuilder}
          >
            <FontAwesomeIcon
              icon={intl?.locale == "ar" ? faArrowRight : faArrowLeft}
            />
          </LucButton>
        </Box>
        <Box component="span">
          <Typography className="survey-live-preview-header-title">
            {CheckValueLocale("live_preview", "", {}, intl)}
          </Typography>
        </Box>
        <Box />
      </Box>

      {isEmbedded ? (
        <EmbeddedPreview
          {...childProps}
          openedPopup={openedPopup}
          setOpenedPopup={setOpenedPopup}
          handleCloseEmbedded={handleCloseEmbedded}
        />
      ) : (
        <LinkedPreview {...childProps} />
      )}
    </Box>
  );
};

export default PreviewPage;
