import { useQuery } from "@tanstack/react-query";
import { useIntl } from "react-intl";
import { SingleTweetAnalysisServices } from "services/api/singleTweetAnalysisServices";

export const useSingleTweetAnalysisData = (tweetId) => {
  const intl = useIntl();
  const local = intl?.locale;
  return useQuery({
    enabled: !!tweetId,
    queryKey: ["singleTweetAnalysis", tweetId, local],
    queryFn: () => SingleTweetAnalysisServices.getSingleTweetAnalysis(tweetId),
  });
};
