// src/components/pickers/MonthPicker.jsx
import { Box, Button, Typography } from "@mui/material";
import moment from "moment";
import { CheckValueLocale } from "utils/helpers";

function MonthPicker({
  selectedMoment,
  onYearClick,
  onMonthSelect,
  intl,
  // Add the new prop for maxDate
  maxDate,
  minDate,
}) {
  // Convert maxDate to a moment, if provided
  const maxM = maxDate ? moment(maxDate) : null;
  const minM = minDate ? moment(minDate) : null;

  // Decide if a given year/month is out of the allowed date range
  function isMonthDisabled(year, monthIndex) {
    // Build a moment for the first day of that year-month
    const compareM = moment([year, monthIndex, 1]);

    // If we have a minDate, disable months that are before it
    if (minM && compareM.isBefore(minM, "month")) {
      return true;
    }

    // If we have a maxDate, disable months that are after it
    if (maxM && compareM.isAfter(maxM, "month")) {
      return true;
    }

    // Otherwise, do not disable
    return false;
  }

  const currentYear = selectedMoment?.year();

  return (
    <Box className="month-picker">
      <Box className="month-picker-header">
        <Typography
          variant="h2"
          className="picker-header clickable header-label"
          onClick={() => onYearClick && onYearClick()}
        >
          {currentYear}
        </Typography>
      </Box>

      <Box className="month-picker-grid">
        {Array.from({ length: 12 }).map((_, idx) => {
          // Check if that month is beyond maxDate
          const disabled = isMonthDisabled(currentYear, idx);

          return (
            <Box className={`picker-button ${disabled ? "disabled" : ""}`}>
              <Typography
                component="span"
                key={idx}
                onClick={() => {
                  if (!disabled) {
                    onMonthSelect(idx);
                  }
                }}
                disabled={disabled}
              >
                {CheckValueLocale(
                  `month_name_${moment()?.month(idx)?.format("MMMM")?.toLowerCase()}`,
                  "",
                  {},
                  intl,
                )}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

export default MonthPicker;
