import * as Yup from "yup";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useFormik } from "formik";
import { Box } from "@mui/material";
import { useCompanyInfo } from "./hooks/useCompanyInfo";
import { useCustomerSuccessInfo } from "./hooks/useCustomerSuccessInfo";
import { useUpdateCompanyInfo } from "./hooks/useUpdateCompanyInfo";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import SnackBar from "components/snackBar";
import CircularLoading from "components/circularProgress";
import PageHeader from "components/pageHeader";
import CompanyInfo from "./components/companyInfo";
import CustomDomain from "./components/customDomain";
import CustomerSuccessManager from "./components/customerSuccessManager";
import SaveChangesFooter from "pages/settings/components/saveChangesFooter";
import "./style.scss";

const CompanyPage = () => {
  const intl = useIntl();

  const [snackbar, setSnackbar] = useState({});

  const userCanEdit = handlelUserRoles("GENERAL", "EDIT_COMPANY_BASIC_INFO");

  const { data: companyInfo, isPending: infoPending } = useCompanyInfo();
  const { data: csmInfo, isPending: csmPending } = useCustomerSuccessInfo();

  const { mutate: updateCompanyInfo, isPending: updateInfoLoading } =
    useUpdateCompanyInfo();

  const handleUpdateCompanyInfo = () =>
    userCanEdit &&
    updateCompanyInfo(
      { ...formik?.values },
      {
        onSuccess: () => {
          setSnackbar({
            open: true,
            severity: "success",
            title: "",
            message: "company_info_updated_successfully",
          }),
            // Update local storage after successful API call
            localStorage.setItem("companyInfo", JSON.stringify(formik?.values.time_zone));
        },
        onError: () =>
          setSnackbar({
            open: true,
            severity: "error",
            title: "settings_some_thing_went_wrong",
            message: "settings_please_try_again_later",
          }),
      },
    );

  const formik = useFormik({
    initialValues: {
      id: companyInfo?.id || null,
      name: companyInfo?.name || "",
      time_zone: companyInfo?.time_zone || "",
    },
    validationSchema: Yup?.object({
      id: Yup?.number(),
      name: Yup?.string()?.required("this_field_required"),
      time_zone: Yup?.string()?.required("this_field_required"),
    }),
    enableReinitialize: true,
    onSubmit: handleUpdateCompanyInfo,
  });

  return (
    <Box id="company-page-container">
      {infoPending || csmPending ? (
        <CircularLoading />
      ) : (
        <Box id="company-page">
          <PageHeader title="company_title" titleId="secondary-pages-header" />
          <Box className="company-page-sections-container">
            <CompanyInfo formik={formik} userCanEdit={userCanEdit} />

            {handlelUserRoles("SURVEY", "VIEW_SURVEY_LIST") &&
            localStorage.getItem("survey_id") ? (
              <CustomDomain
                customDomainDetails={companyInfo?.custom_domain_object}
                setSnackbar={setSnackbar}
              />
            ) : null}

            <CustomerSuccessManager
              companyInfo={companyInfo}
              csmInfo={csmInfo}
            />
          </Box>
        </Box>
      )}

      {userCanEdit && formik?.dirty ? (
        <SaveChangesFooter
          handleSaveChanges={formik?.handleSubmit}
          disabledSaveChanges={!formik?.isValid || !formik?.dirty}
          loadingSaveChanges={updateInfoLoading}
          handleCancelChanges={formik?.resetForm}
        />
      ) : null}

      <SnackBar
        open={snackbar?.open}
        handleClose={() => setSnackbar({})}
        severity={snackbar?.severity}
        title={CheckValueLocale(snackbar?.title, "", {}, intl)}
        message={CheckValueLocale(snackbar?.message, "", {}, intl)}
      />
    </Box>
  );
};

export default CompanyPage;
