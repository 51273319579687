import React, { useEffect, useState } from "react";
import QuestionCard from "pages/Monitors/Components/questionCard/index";
import SnackBar from "components/snackBar";
import { useIntl } from "react-intl";
import CommonFunctions from "pages/Monitors/Components/commonFunctions";
import { CheckValueLocale } from "utils/helpers";
import PopupModal from "components/popupModal";
import xPlatform from "images/social-icons/x-platform-white-icon.svg";
import { CustomPagination } from "components/CustomPagination/CustomPagination";
import { useLocation, useNavigate } from "react-router-dom";

const KeywordsPostsBody = (props) => {
  const {
    response,
    preLoaderTrackerDataSources,
    resetAllFields,
    changePagination,
    pageNumber,
    setTotalPage,
    totalPage,
  } = props;
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();

  const [cardData, setCardData] = useState([]);
  const [postsPreloader, setPostsPreloader] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (resetAllFields !== 1) {
      setPostsPreloader([]);
      setCardData([]);
    }
  }, [resetAllFields]);

  const handleResponseFunc = async () => {
    if (response.eventName === "Twitter__PostsPage__top_posts_comments") {
      setCardData(response?.eventData?.top_posts);
      if (pageNumber === 1) {
        setTotalPage(response.eventData.top_posts_count);
        setPage(1);
      }
      if (!postsPreloader.includes(parseInt(response?.monitor_id))) {
        setPostsPreloader([...postsPreloader, parseInt(response.monitor_id)]);
      }
      // reset url to remove search param
      navigate(window.location.pathname);
    } else {
      return null;
    }
  };
  useEffect(() => {
    handleResponseFunc();
  }, [response]);

  const handleChangePage = (event, page) => {
    if (Boolean(page) === false) return;
    setPage(page);
    changePagination(page);
  };
  const {
    updateAnalysis,
    handleCloseSnackBar,
    showAnalyzeTweetSnackBar,
    analyzeTweetSnackBar,
    stats,
    handleCloseCreateModal,
    modalBody,
    openSnack,
    handleHideSnackBar,
  } = CommonFunctions();

  useEffect(() => {
    let totalPreLoaderDataSouces = [];

    totalPreLoaderDataSouces.push(postsPreloader);

    props.checkAllDataFounded(
      totalPreLoaderDataSouces,
      props.preLoaderTrackerDataSources,
    );

    props.checkDataIsFounded(cardData);
  }, [cardData, postsPreloader]);

  //Luci check [ noDataFound , Analyzing ]
  useEffect(() => {
    let x = props.checkLuciAllDataFounded(
      postsPreloader,
      props.preLoaderTrackerDataSources,
    );
    let y = props.checkLuciDataIsFounded([cardData]);
    props.setLuciCheckAllDataExist(x);
    props.setLuciCheckDataIsEmpty(y);
  }, [cardData, postsPreloader, props.preLoaderTrackerDataSources]);

  useEffect(() => {
    props?.setTotalPostsCount?.(totalPage);
  }, [totalPage]);
  const showUserSnackbar = location?.state?.showUserSnackbar;

  return (
    <div className="authors-margin">
      <QuestionCard
        unixDate={props.unixDate}
        showProfile={true}
        productId={window.localStorage?.getItem("sm_id")}
        latestUnanswer={cardData}
        latestUnanswerPreloader={postsPreloader}
        preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        icon={xPlatform}
        iconColor={"twitter_icon"}
        showRetweet={true}
        showBanIcon={true}
        showScreenName={true}
        updateAnalysis={updateAnalysis}
        singleTweetAnalysis={props.singleTweetAnalysis}
        noOfChar={15}
        hideWhenRetweeted
        showHeart
        showDefaultImg
        getFiltersFileds={props?.getFiltersFileds}
      />
      {cardData?.length > 0 && Math.ceil(totalPage / 10) > 1 && (
        <CustomPagination
          onChange={handleChangePage}
          page={page}
          pagesCount={Math.ceil(totalPage / 10)}
          itemsCount={totalPage}
        />
      )}
      {showUserSnackbar ? (
        <SnackBar
          open={openSnack}
          handleClose={handleHideSnackBar}
          message={CheckValueLocale(
            "page_displays_data_based_on_tooltip",
            "",
            {},
            intl,
          )}
          severity={"info"}
        />
      ) : null}
      {showAnalyzeTweetSnackBar && (
        <SnackBar
          open={true}
          handleClose={handleCloseSnackBar}
          title={CheckValueLocale(analyzeTweetSnackBar.title, "", {}, intl)}
          message={CheckValueLocale(analyzeTweetSnackBar.message, "", {}, intl)}
          severity={analyzeTweetSnackBar.severity}
        />
      )}
      <PopupModal
        title={CheckValueLocale("single_tweet_analysis", "", {}, intl)}
        body={modalBody}
        open={stats?.openCreateModal}
        close={handleCloseCreateModal}
        warning="warning"
        classeName="create-historical-popup"
        addClasses="create-single-post-analysis-modal"
      />
    </div>
  );
};

export default KeywordsPostsBody;
