// style
import "../Media.scss";
// Sub Component
import { Box } from "@mui/material";
import FetchingData from "components/fetchingData/fetchingData";
import NoDataFound from "components/no-Data/noDataFound";
import WidgetHeader from "components/widgets/widgetHeader";
import _ from "lodash";
import { useIntl } from "react-intl";
import { CheckValueLocale, isEmptyArray } from "utils/helpers";
import MediaInstaItem from "./mediaInstaItem";
import MonitorMediaInstaItem from "./monitorMediainstaItem/monitorMediaInstaItem";

const MediaInsta = (props) => {
  const intl = useIntl();

  let errorMessage = CheckValueLocale("no_data_found", "", {}, intl);
  if (props.errorMessage) {
    errorMessage = props.errorMessage;
  }

  return (
    <div className="chart_media">
      <WidgetHeader
        title={props.title}
        showDownloadIcon={props.showDownloadIcon}
        iconAfterTitle={props.iconAfterTitle}
        titleToolTip={props.titleToolTip}
        showNotAppliedFilter={props?.showNotAppliedFilter}
        widgetSection={props?.widgetSection}
        isCustomDashboard={props?.isCustomDashboard} // To identify that widget will be rendered in Custom Dashboard
        isControlWidget={props?.isControlWidget} // To identify that widget will be rendered in Custom Dashboard control widget sidebar
        customDashboardData={props?.customDashboardData} // The needed data in widget component for case of Custom Dashboard only
      />
      {_.isEqual(
        props?.mediaInstaPreLoaderDataSources?.sort(),
        props?.preLoaderTrackerDataSources?.sort(),
      ) !== true ||
      !props?.mediaInstaPreLoaderDataSources?.length ||
      !props?.preLoaderTrackerDataSources?.length ? (
        <Box>
          <FetchingData />
        </Box>
      ) : isEmptyArray(props.data) || props.data == null ? (
        <NoDataFound />
      ) : (
        <div className="media-instagram-wrapper">
          {props.monitorInstagramMedia ? (
            <div className="topmedia-monitor-wrapper">
              {props?.data?.map((media, index) => (
                <MonitorMediaInstaItem
                  key={index}
                  media={media}
                  question={
                    props.hideMoreInsights ? {} : props?.questions[index]
                  }
                  hideMoreInsights={props.hideMoreInsights}
                  insightsID={props?.insightsID}
                  response={props?.response}
                  dates={props?.dates}
                  datasourcePostsType={props?.datasourcePostsType}
                />
              ))}
            </div>
          ) : (
            <div className="topmedia-wrapper">
              {props?.data?.map((media, index) => (
                <MediaInstaItem key={index} media={media} />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MediaInsta;
