import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Box, Container} from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { useSurveyLists } from "./hooks/useSurveyLists";
import { useSurveyTypes } from "./hooks/useSurveyTypes";
import { useSurveyFilters } from "./hooks/useSurveyFilters";
import CircularLoading from "components/circularProgress";
import SnackBar from "components/snackBar";
import SurveyListHeader from "./components/surveyListHeader";
import SurveyCards from "./surveyCards/surveyCards";
import CreateSurveyModal from "./components/createSurveyModal";
import NoDataFoundProducts from "components/no-Data";
import NoDataLinks from "components/no-Data/noDataLinks";
import NotFound from "pages/not-found/NotFoundPage";
import { CustomPagination } from "components/CustomPagination/CustomPagination";

const SurveyMainPage = () => {
  const intl = useIntl();

  const navigate = useNavigate();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const pageUrlParam = searchParams?.get("page");
  const searchUrlParam = searchParams?.get("q");

  const [tempSearchText, setTempSearchText] = useState(searchUrlParam || "");
  const [searchText, setSearchText] = useState(searchUrlParam || "");
  const [searchBoxClicked, setSearchBoxClicked] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [surveyTypeId, setSurveyTypeId] = useState(null);
  const [snackbar, setSnackbar] = useState({});

  const handlePageChange = (event, value) => {
    searchParams?.set("page", value);
    navigate({ search: searchParams.toString() });
  };

  const {
    data: listData,
    isPending: listLoading,
    isError: listError,
  } = useSurveyLists({
    page: pageUrlParam || 1,
    name_search: searchUrlParam?.length ? searchUrlParam : undefined, // To avoid sending it to backend if not searched yet
    types_ids: selectedFilters?.types?.length
      ? selectedFilters?.types?.sort()?.join(",")
      : undefined, // To avoid sending it to backend if not selected any type
    statuses: selectedFilters?.statuses?.length
      ? selectedFilters?.statuses?.sort()?.join(",")
      : undefined, // To avoid sending it to backend if not selected any status
  });

  const isInvalidURL = listData?.surveys?.detail === "Invalid page.";

  const listPages =
    Math.ceil(listData?.surveys?.count / listData?.surveys?.page_size) || 0; // Total number of pages

  // Get Survey Types
  const {
    data: surveyTypes,
    isPending: surveyTypesLoading,
    isError: surveyTypesError,
  } = useSurveyTypes();

  // Get Survey Filters
  const {
    data: surveyFilters,
    isPending: surveyFiltersLoading,
    isError: surveyFiltersError,
  } = useSurveyFilters();

  // Show snackbar on error
  useEffect(() => {
    if (listError || surveyTypesError || surveyFiltersError) {
      setSnackbar({
        open: true,
        severity: "error",
        title: "something_went_wrong",
        message: "try_again",
      });
    }
  }, [listError, surveyTypesError, surveyFiltersError]);

  return isInvalidURL ? (
    <NotFound />
  ) : (
    <>
      <Container maxWidth="xl" className="container-survey-header">
        <SurveyListHeader
          listLoading={listLoading}
          listData={listData}
          surveyTypes={surveyTypes}
          surveyFilters={surveyFilters}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          tempSearchText={tempSearchText}
          setTempSearchText={setTempSearchText}
          searchText={searchText}
          setSearchText={setSearchText}
          searchBoxClicked={searchBoxClicked}
          setSearchBoxClicked={setSearchBoxClicked}
          setOpenCreateModal={setOpenCreateModal}
          setSurveyTypeId={setSurveyTypeId}
        />
        {listLoading || surveyTypesLoading || surveyFiltersLoading ? (
          <CircularLoading />
        ) : (
          <>
            {searchText?.length && !listData?.surveys?.data?.length ? (
              <Box className="empty-item empty-item-survey">
                <NoDataFoundProducts
                  image={"survey"}
                  title={"no_data_found"}
                  subTitle="no_data_matching_your_search"
                />
              </Box>
            ) : !searchText?.length && !listData?.surveys?.data?.length ? (
              <Box className="empty-item empty-item-survey">
                <NoDataFoundProducts
                  image="EmptySurvey"
                  title="create_your_survey"
                  description="create_your_survey_dec"
                  linkOne="how_to_use"
                  linkOneHref={NoDataLinks.survey_link}
                  orCase="or_visit_the"
                  linkTwo="knowledge_base"
                />
              </Box>
            ) : (
              <SurveyCards
                surveyLimitReach={listData?.survey_limit_reach}
                surveys={listData?.surveys?.data}
                setSnackbar={setSnackbar}
              />
            )}
          </>
        )}
        <CreateSurveyModal
          open={openCreateModal}
          setOpen={setOpenCreateModal}
          surveyTypeId={surveyTypeId}
          setSnackbar={setSnackbar}
        />
        {!listLoading && listPages > 1 ? (
          <Box
            sx={{
              marginTop: "60px",
            }}
          >
            <CustomPagination
              onChange={handlePageChange}
              page={Number(pageUrlParam) || 1}
              pagesCount={listPages}
              itemsCount={listData?.surveys?.count}
              itemsPerPage={listData?.surveys?.page_size}
            />
          </Box>
        ) : null}
      </Container>

      {snackbar?.open ? (
        <SnackBar
          open={snackbar?.open}
          handleClose={() => setSnackbar({})}
          severity={snackbar?.severity}
          title={CheckValueLocale(snackbar?.title, "", {}, intl)}
          message={CheckValueLocale(snackbar?.message, "", {}, intl)}
        />
      ) : null}
    </>
  );
};
export default SurveyMainPage;
