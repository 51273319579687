import { useContext, useEffect, useState } from "react";
import { Box, Chip, Tooltip, Typography, IconButton } from "@mui/material";
import { useIntl } from "react-intl";
import {
  CheckValueLocale,
  handlelUserRoles,
  handleThemes,
} from "utils/helpers";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EditEngagerProfileForm } from "./editEngagerProfileForm";
import { EditEngagerProfileSegments } from "./editEngagerProfileSegments";
import { EditContext } from "../context";

export const UserInfoItemList = ({
  title,
  data = [],
  withColor,
  themesOptions,
  emptyText,
  isSocial,
  titleIcon,
  validationSchema,
  hideTitle = false,
  formMode,
  onItemClick,
  fieldType,
}) => {
  const intl = useIntl();
  const [visibleItems, setVisibleItems] = useState(10);

  const {
    isEditing,
    setIsEditing,
    currentEditingSection,
    setCurrentEditingSection,
    hasCDPSubscription,
  } = useContext(EditContext);

  const handleShowMore = () => {
    setVisibleItems(visibleItems + 10);
  };

  const handleShowLess = () => {
    setVisibleItems(10);
  };

  const showMoreLessChipVisible = data?.length > 10;
  const showMoreLessChipLabel =
    visibleItems < data?.length
      ? CheckValueLocale("show_more_btn", "", {}, intl)
      : CheckValueLocale("show_less_btn", "", {}, intl);
  const showMoreLessChipOnClick =
    visibleItems < data?.length ? handleShowMore : handleShowLess;

  const hasThemes = themesOptions?.length > 0;

  const getSelectedTheme = (item) => {
    return themesOptions?.find((theme) => theme?.value === item) || "";
  };

  const isUserNotViewer = handlelUserRoles("CDP", "EDIT_PROFILE");

  const handleEditClick = () => {
    if (!hasCDPSubscription || !isUserNotViewer) return;
    setIsEditing(true);
    setCurrentEditingSection(fieldType);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setCurrentEditingSection(null);
  };

  const isPhoneNumber = fieldType === "phone_number";

  return (
    <Box>
      {title && !hideTitle ? (
        <Typography className="engager-user-info-item-list-label">
          {titleIcon ? titleIcon : null}
          {title}
          {!isEditing && (
            <Tooltip
              arrow
              placement="top"
              title={
                isUserNotViewer
                  ? CheckValueLocale(
                      hasCDPSubscription
                        ? "edit"
                        : "engager_profile_cdp_not_subscribed",
                      "",
                      {},
                      intl,
                    )
                  : ""
              }
            >
              <FontAwesomeIcon
                onClick={handleEditClick}
                icon={faPen}
                size="xs"
                className={`engager-edit-title-section ${
                  isUserNotViewer ? "engager-field-enabled" : "engager-field-disabled"
                }`}
              />
            </Tooltip>
          )}
        </Typography>
      ) : null}
      {isEditing && currentEditingSection === fieldType ? (
        formMode === "select" ? (
          <EditEngagerProfileSegments
            data={data}
            handleCancel={handleCancel}
            formMode={formMode}
          />
        ) : (
          <EditEngagerProfileForm
            data={data}
            handleCancel={handleCancel}
            title={title}
            validationSchema={validationSchema}
            setVisibleItems={setVisibleItems}
            visibleItems={visibleItems}
            fieldType={fieldType}
          />
        )
      ) : (
        <>
          {data?.length > 0 ? (
            data?.slice(0, visibleItems)?.map((item, index) => {
              return (
                <Chip
                  key={item?.id || index}
                  size="small"
                  onClick={() => {
                    if (onItemClick) {
                      onItemClick(item);
                    }
                  }}
                  label={
                    withColor
                      ? `${CheckValueLocale(item, "", {}, intl)} `
                      : item?.value || item?.name
                  }
                  variant="outlined"
                  className={`engager-user-info-item-list-value ${
                    withColor ? "with-color" : isSocial ? "social-chip" : ""
                  } ${isPhoneNumber ? "engager-field-phone-number" : ""}`}
                  icon={
                    withColor ? (
                      <Box
                        style={{
                          backgroundColor: hasThemes
                            ? getSelectedTheme(item)?.sub_theme_color
                            : handleThemes(item),
                        }}
                        className="hint-color"
                      />
                    ) : null
                  }
                />
              );
            })
          ) : (
            <Typography className="engager-user-empty-text">
              {emptyText || "-"}
            </Typography>
          )}
          {showMoreLessChipVisible && (
            <Box>
              <Chip
                size="small"
                label={showMoreLessChipLabel}
                variant="outlined"
                onClick={showMoreLessChipOnClick}
                className="engager-user-info-item-list-show-more-less-chip"
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
