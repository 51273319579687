import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/material";
import PageHeader from "components/pageHeader.js";
import Snackbar from "components/snackBar";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import LucButton from "shared/lucButton/lucButton";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import ListSearchFilter from "./accountListSearch";
import "./AccountsListsList.scss";

const ListHeader = ({
  handelTextSearch,
  searchText,
  setSearchText,
  accountslists,
  searchInput,
  totalAccountsLists,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <>
      <Box container className="title-header-box">
        <Box className="accounts-list-tilte-header-box">
          <PageHeader
            className="accounts-list-page-header"
            title="account_lists"
            subTitle={CheckValueLocale(
              "account_lists_describtion",
              "",
              {},
              intl,
            )}
            subTitleStyle="account-list-page-header-sub-title"
          />
        </Box>
        <Box id="title-button-box">
          <LucButton
            startIcon={<AddIcon />}
            onClick={() => navigate("/accounts-list/add-accounts-list")}
            disabled={
              totalAccountsLists >= 100 ||
              !handlelUserRoles("GENERAL", "CREATE_ACCOUNT_LIST")
            }
          >
            {CheckValueLocale("new_list", "", {}, intl)}
          </LucButton>
        </Box>
      </Box>
      {accountslists?.length > 0 || searchInput ? (
        <Box>
          <ListSearchFilter
            handelTextSearch={handelTextSearch}
            searchText={searchText}
            setSearchText={setSearchText}
          />
        </Box>
      ) : null}
    </>
  );
};

export default ListHeader;
