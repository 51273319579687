import { Box, TextField } from "@mui/material";
import "./sixDigitsCodeInput.scss";
import { useState, useRef, useEffect } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import DOMPurify from "dompurify";

const SixDigitsCodeInput = ({
  setCodeVerify,
  codeVerify,
  errorCodeMsg,
  setErrorCodeMsg,
}) => {
  const intl = useIntl();
  const [code, setCode] = useState(new Array(6)?.fill(""));
  const inputRefs = useRef([]);

  // Focus the first input field when the component is rendered
  useEffect(() => {
    inputRefs?.current?.[0]?.focus();
  }, []);

  // Handle input change
  const handleChange = (value, index) => {
    if (!/^\d?$/?.test(value)) return; // Allow only one digit
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);
    // Focus the next input field
    if (value && index < 5) {
      inputRefs?.current?.[index + 1]?.focus();
    }
  };

  // Handle paste event
  const handlePaste = (e) => {
    const paste = e?.clipboardData?.getData("text");
    if (/^\d*$/.test(paste)) {
      const newCode = paste?.split("", 6);
      let indexStop = 5;
      if (newCode?.length < 6) {
        indexStop = newCode?.length;
        newCode.push(...new Array(6 - newCode?.length)?.fill(""));
      }
      setCode(newCode);
      inputRefs?.current?.[indexStop]?.focus();
    }
    e?.preventDefault();
  };

  useEffect(() => {
    let newCode = "";
    code?.forEach((num) => (newCode += num));
    setCodeVerify && setCodeVerify(newCode);
    setErrorCodeMsg && setErrorCodeMsg("");
  }, [code]);

  useEffect(() => {
    // when resend button click
    if (!codeVerify) {
      setCode(new Array(6)?.fill(""));
    }
  }, [codeVerify]);

  // Handle key press (e.g., backspace)
  const handleKeyDown = (e, index) => {
    if (e?.key === "Backspace" && !code?.[index] && index > 0) {
      inputRefs?.current[index - 1]?.focus();
    }
  };

  return (
    <Box id="six-digits-code-input-container">
      <Box className="code-input-container" onPaste={handlePaste}>
        {code?.map((digit, index) => (
          <TextField
            key={index}
            inputRef={(ref) => (inputRefs.current[index] = ref)}
            value={digit}
            onChange={(e) => handleChange(e?.target?.value, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            inputProps={{
              maxLength: 1,
              className: "code-input",
            }}
            error={!!errorCodeMsg?.msg}
          />
        ))}
      </Box>
      {errorCodeMsg?.msg ? (
        <Box
          className="error-code-input"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              CheckValueLocale(
                errorCodeMsg?.msg,
                "",
                { count: errorCodeMsg?.remaining_attempts },
                intl,
              ),
            ),
          }}
        />
      ) : null}
    </Box>
  );
};

export default SixDigitsCodeInput;
