import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { Add, Close } from "@mui/icons-material";
import { CheckValueLocale } from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";

const DropdownQuestion = (props) => {
  const {
    filteredSections,
    setSurveySections,
    surveySections,
    rootQuestion,
    question,
    questionIndex,
    sectionIndex,
    handleChangeProceed,
    handleProceedAnswer,
    previewMode,
  } = props;

  const intl = useIntl();
  const [choices, setChoices] = useState([]);
  const [editable, setEditable] = useState(false);
  const [proceedSelects, setProceedSelects] = useState([]);
  const [selectedAnswer, setSelectedAnswer] = useState(null);

  const MenuProps = { PaperProps: { style: { maxHeight: 265 } } };

  const [options, setOptions] = useState([]);

  useEffect(() => {
    const { alpabetical_order, randomaize } = question?.constraints;

    if (question) {
      setEditable(question?.editable);
      setChoices(question?.items);

      if (alpabetical_order) {
        let sortedItems = question?.items?.sort((a, b) =>
          Object.values(a)[0].localeCompare(Object.values(b)[0]),
        );
        if (sortedItems?.length !== 0) setOptions(sortedItems);
        return;
      } else if (randomaize) {
        const shuffleArray = (array) => {
          for (let i = array?.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
          }
          return array;
        };
        const randomizedItems = shuffleArray([...question?.items]);
        if (randomizedItems?.length !== 0) setOptions(randomizedItems);
        return;
      } else return setOptions(question?.items);
    }
  }, [question]);

  const handleChangeSelected = (e) => {
    setSelectedAnswer(e.target.value);
    if (rootQuestion?.is_proceed_on_answer)
      handleProceedAnswer(options[e.target.value]?.next_section);
  };

  useEffect(() => {
    if (filteredSections) {
      let selectsArray = [];
      filteredSections?.map((sec, index) => {
        if (index !== sectionIndex && index !== sectionIndex + 1)
          selectsArray?.push(sec);
      });
      setProceedSelects(selectsArray);
    }
  }, [filteredSections?.length]);

  const handleAddChoice = () => {
    let maxKey = null;
    choices?.forEach((obj) => {
      const key = parseInt(Object.keys(obj)[0]);
      if (maxKey === null || key > maxKey) maxKey = key;
    });
    let obj = {
      [maxKey + 1]: `${CheckValueLocale("dropdown_option", "", {}, intl)} ${
        choices?.length + 1
      }`,
    };
    const proceedOnAnswer = filteredSections[sectionIndex].questions.filter(
      (ques) => ques?.is_deleted !== true,
    )[questionIndex]?.is_proceed_on_answer;
    if (proceedOnAnswer) {
      if (sectionIndex === filteredSections?.length - 1) obj.next_section = -1;
      else obj.next_section = filteredSections[sectionIndex]?.order + 1;
    }
    setChoices([...choices, obj]);
    filteredSections[sectionIndex].questions.filter(
      (ques) => ques?.is_deleted !== true,
    )[questionIndex].question.items = [...choices, obj];
    setSurveySections([...surveySections]);
  };

  const handleChangeChoice = (e, choice) => {
    const key = Object.keys(choice)[0];
    if (e.target.value) {
      setChoices((prevChoices) => {
        const updatedChoices = prevChoices.map((item) => {
          if (Object.keys(item)[0] === key)
            return { ...item, [key]: e.target.value };
          return item;
        });
        filteredSections[sectionIndex].questions.filter(
          (ques) => ques?.is_deleted !== true,
        )[questionIndex].question.items = [...updatedChoices];
        setSurveySections([...surveySections]);
        return updatedChoices;
      });
    }
  };

  const handleRemoveChoice = (index) => {
    if (editable !== false) {
      choices?.splice(index, 1);
      setChoices([...choices]);
      // Update keys of remaining objects in choices array to be as new index + 1
      choices?.forEach((choice, choiceIndex) => {
        const nIndex = choiceIndex + 1;
        const oldIndex = choiceIndex + 2;
        if (choice[oldIndex]) {
          choice[nIndex] = choice[oldIndex];
          delete choice[oldIndex];
        }
      });
      filteredSections[sectionIndex].questions.filter(
        (ques) => ques?.is_deleted !== true,
      )[questionIndex].question.items = choices;
      setSurveySections([...surveySections]);
    }
  };

  return (
    <Box className="survey-builder-question-dropdown">
      {!previewMode ? (
        <Box className="editor-options-wrapper">
          {choices?.map((choice, index) => {
            return (
              <Box className="answer-container" key={index}>
                <TextField
                  variant="standard"
                  className={`survey-builder-question-dropdown-input choice-textfield ${rootQuestion?.is_proceed_on_answer ? "proceed-select-container" : ""}`}
                  value={CheckValueLocale(
                    Object.values(choice)[0],
                    "",
                    {},
                    intl,
                  )}
                  onChange={(e) => {
                    handleChangeChoice(e, choice);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        disabled={editable === false && true}
                      >
                        {index + 1}.
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        disabled={editable === false && true}
                        onClick={() => handleRemoveChoice(index)}
                      >
                        <IconButton disabled={editable === false && true}>
                          <Close />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  disabled={editable === false && true}
                  fullWidth
                />
                {rootQuestion?.is_proceed_on_answer ? (
                  <Box component="span" className="proceed-select-container">
                    <FormControl fullWidth size="small">
                      <Select
                        id={`dropdown-next-section-select-${sectionIndex}`}
                        MenuProps={MenuProps}
                        value={choice?.next_section}
                        onChange={(e) =>
                          handleChangeProceed(
                            e,
                            sectionIndex,
                            questionIndex,
                            index,
                          )
                        }
                        variant="standard"
                        size="small"
                        fullWidth
                      >
                        {sectionIndex !== filteredSections?.length - 1 && (
                          <MenuItem
                            value={filteredSections[sectionIndex]?.order + 1}
                          >
                            {`${CheckValueLocale(
                              "continue_next_section",
                              "",
                              {},
                              intl,
                            )} (${filteredSections[sectionIndex + 1]?.name})`}
                          </MenuItem>
                        )}
                        <MenuItem value={-1}>
                          {CheckValueLocale("submit_survey", "", {}, intl)}
                        </MenuItem>
                        {proceedSelects?.map((section, i) => {
                          return (
                            <MenuItem key={i} value={section?.order}>
                              {`${CheckValueLocale(
                                "go_to_section",
                                "",
                                {},
                                intl,
                              )} ${section?.order} (${section?.name})`}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                ) : null}
              </Box>
            );
          })}

          <Box className="add-option-btn-wrapper">
            <LucButton
              variant="flat"
              size="small"
              startIcon={<Add />}
              onClick={handleAddChoice}
              disabled={editable === false && true}
            >
              {CheckValueLocale("add_option", "", {}, intl)}
            </LucButton>
          </Box>
        </Box>
      ) : (
        <FormControl
          className="survey-builder-question-dropdown-preview-select-form-control"
          variant="outlined"
          size="small"
        >
          <InputLabel id="survey-builder-question-dropdown-preview-select-label">
            {CheckValueLocale("choose", "", {}, intl)}
          </InputLabel>
          <Select
            id="survey-builder-question-dropdown-preview-select-label"
            labelId="survey-builder-question-dropdown-preview-select-label"
            input={
              <OutlinedInput label={CheckValueLocale("choose", "", {}, intl)} />
            }
            value={selectedAnswer}
            onChange={(e) => handleChangeSelected(e)}
          >
            {options?.map((val, i) => {
              return (
                <MenuItem
                  id="survey-builder-question-dropdown-preview-select-item"
                  value={i}
                  key={i}
                >
                  {CheckValueLocale(Object.values(val)[0], "", {}, intl)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    </Box>
  );
};

export default DropdownQuestion;
