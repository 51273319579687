import React, { useContext } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { ViewReportContext } from "pages/reports/context/reportContext";
import CustomizedTooltip from "components/customizedTooltip";

const ViewReportInfoCards = () => {
  const { reportInfo } = useContext(ViewReportContext);

  const intl = useIntl();

  const { platform, report_type, monitor_type, monitors, data_sources } =
    reportInfo || {};

  const isSocialListening = platform?.toLowerCase() === "sm";

  const handleMonitorNames = (monitors) => {
    const reachedMax = monitors?.length >= 4;
    return (
      <CustomizedTooltip
        title={reachedMax ? monitors?.map((val) => val?.name)?.join(" / ") : ""}
        styles={{
          backgroundColor: "rgba(97, 97, 97, 0.9)",
          padding: "10px",
          width: 160,
          lineHeight: 1.5,
          borderRadius: "4px",
        }}
        placement="bottom"
        arrow
      >
        <Box>
          {monitors
            ?.slice(0, 3)
            ?.map((monitor) => monitor?.name)
            ?.join(" / ")}
          {reachedMax
            ? `... (+${monitors?.slice(3, monitors?.length)?.length})`
            : null}
        </Box>
      </CustomizedTooltip>
    );
  };

  const handleDatasources = (datasources) => {
    const reachedMax = datasources?.length >= 4;

    const tooltipText = datasources
      ?.map((val) =>
        val?.name === "TALKWALKER" || val?.name === "NEWSBLOGS"
          ? CheckValueLocale("news_blogs", "", {}, intl)
          : CheckValueLocale(val?.name, "", {}, intl),
      )
      ?.join(", ");

    return (
      <CustomizedTooltip
        title={reachedMax ? tooltipText : ""}
        styles={{
          backgroundColor: "rgba(97, 97, 97, 0.9)",
          padding: "10px",
          width: 160,
          lineHeight: 1.5,
          borderRadius: "4px",
        }}
        placement="bottom"
        arrow
      >
        <Box>
          {datasources
            ?.slice(0, 3)
            ?.map((datasource) =>
              CheckValueLocale(
                datasource?.type === "private"
                  ? `${datasource?.name?.toLowerCase()}_dm`
                  : datasource?.name?.toLowerCase(),
                "",
                {},
                intl,
              ),
            )
            ?.join(" / ")}
          {reachedMax
            ? `... (+${datasources?.slice(3, datasources?.length)?.length})`
            : null}
        </Box>
      </CustomizedTooltip>
    );
  };

  const dummyInfo = [
    {
      id: "view-report-page-info-card-report-type",
      title: "report_type",
      value: CheckValueLocale(`${report_type}_report_type`, "", {}, intl),
      view: !!report_type,
    },
    {
      id: "view-report-page-info-card-monitor-type",
      title: "monitor_type",
      value: CheckValueLocale(monitor_type?.name, "", {}, intl),
      view: isSocialListening && !!monitor_type?.name,
    },
    {
      id: "view-report-page-info-card-report-type",
      title: isSocialListening
        ? "report_monitor_name"
        : "reports_view_accounts",
      value: handleMonitorNames(monitors),
      view: monitors?.length >= 1,
    },
    {
      id: "view-report-page-info-card-report-type",
      title: isSocialListening ? "data_sources" : "reports_view_channels",
      value: handleDatasources(data_sources),
      view: data_sources?.length >= 1,
    },
  ];

  return (
    <Grid className="view-report-card">
      {dummyInfo
        ?.filter((item) => item?.view)
        ?.map((item) => (
          <Grid key={item?.id} id={item?.id} className="report-detail" item>
            <Typography variant="subtitle2">
              {CheckValueLocale(item?.title, "", {}, intl)}
            </Typography>
            <Typography variant="subtitle1">{item?.value}</Typography>
          </Grid>
        ))}
    </Grid>
  );
};

export default ViewReportInfoCards;
