import { useEffect, useState } from "react";
import { Box, Grid, Breadcrumbs, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import QuickInspectPopupModal from "./quickInspectModal";
import "./quickInspect.scss";
import CustomCard from "components/customCard";
import VolumeSentimentModal from "./volumeSentimentModal";
import KeywordsHashtagModal from "./keywordsHashtagModal";
import TwitterIcon from "@mui/icons-material/Twitter";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import FetchingData from "components/fetchingData/fetchingData";
import _ from "lodash";
import { CheckValueLocale } from "utils/helpers";
import xPlatform from "images/social-icons/x-platform-icon.svg";

const QuickInspect = (props) => {
  const intl = useIntl();
  let {
    preLoaderTrackerDataSources,
    open,
    inspectorName,
    quickResponse,
    setOpen,
    inspectorValue,
    dataValue,
    date,
    color,
    quickInspectKeywordMonitor,
    monitorType,
  } = props;

  const [dataPreLoader, setDataPreLoader] = useState([]);
  const [data, setData] = useState([{}, {}]);
  const handleResponseFunc = async () => {
    if (quickResponse.eventName === "quick_inspect_header" && open) {
      data.splice(0, 1, {
        data_type: dataValue,
        date_quick: date,
      });
      data.splice(1, 1, quickResponse.eventData);
      !dataPreLoader.includes(quickResponse.monitor_id) &&
        setDataPreLoader((oldArray) => [...oldArray, quickResponse.monitor_id]);
    }
  };
  useEffect(() => {
    handleResponseFunc();
  }, [quickResponse]);

  const handleClose = () => {
    data.splice(0, 1, {});
    data.splice(1, 1, {});
    setData(data);
    setDataPreLoader([]);
    setOpen(false);
  };

  const twitterIcon = (
    <>
      <img
        src={xPlatform}
        alt="x-platform-logo"
        className="icon-after-title twitter-icon"
      />
    </>
  );
  const links = [
    inspectorName == "comments_overtime" && monitorType == "KEYWORD"
      ? CheckValueLocale("posts_volume", "", {}, intl)
      : quickInspectKeywordMonitor
        ? CheckValueLocale("posts_top_keywords", "", {}, intl)
        : inspectorName === "sentiment_legend" ||
            inspectorName === "sentiment_overtime"
          ? CheckValueLocale("sentiment_overtime_legend", "", {}, intl)
          : inspectorName,
    inspectorValue,
  ];

  let gridSize = inspectorName === "sentiment_legend" ? [6, 5.8] : [4, 7.8];

  let customerCard = data.map((cardData, index) => (
    <>
      {_.isEqual(
        dataPreLoader.sort(),
        props?.preLoaderTrackerDataSources?.sort(),
      ) !== true ||
      !dataPreLoader?.length ||
      !preLoaderTrackerDataSources?.length ? (
        <Box className="quick-inspect-fetching">
          <FetchingData />
        </Box>
      ) : (
        <>
          <CustomCard
            cardData={cardData}
            gridSize={gridSize[index]}
            isDataType={
              index === 0 &&
              Object.keys(cardData).includes("data_type") &&
              "data_type"
            }
            color={props.color}
            otherColor={"#334155"}
          />
        </>
      )}
    </>
  ));

  let inspectComponent =
    inspectorName === "sentiment_overtime" ||
    inspectorName === "sentiment_legend" ||
    inspectorName === "comments_overtime" ? (
      <VolumeSentimentModal
        response={quickResponse}
        twitterIcon={twitterIcon}
        preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        inspectorName={inspectorName}
        open={open}
        color={color}
        singleTweetAnalysis={props.singleTweetAnalysis}
        quickInspectKeywordMonitor={quickInspectKeywordMonitor}
        inspectorValue={inspectorValue}
      />
    ) : (
      <KeywordsHashtagModal
        response={quickResponse}
        twitterIcon={twitterIcon}
        preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        inspectorName={inspectorName}
        open={open}
        singleTweetAnalysis={props.singleTweetAnalysis}
        quickInspectKeywordMonitor={quickInspectKeywordMonitor}
      />
    );

  let modalBody = (
    <Box>
      <Breadcrumbs
        aria-label="breadcrumb"
        className="bread-crumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        {links.map((link, i) => {
          return (
            <Typography color="inherit">
              {link?.length > 0 && CheckValueLocale(link, "", {}, intl)}
              {/* {link?.length > 0
                ? intl.formatMessage({
                    id:
                      inspectorName == "comments_overtime" &&
                      monitorType == "KEYWORD"
                        ? intl.formatMessage({ id: "posts_volume" })
                        : intl.formatMessage({ id: link }),
                  })
                : intl.formatMessage({ id: link })} */}
            </Typography>
          );
        })}
      </Breadcrumbs>
      <Grid
        className={`quick-inspect-card ${
          inspectorName === "sentiment_legend" && "quick-inspect-card-legends"
        }`}
        container={
          _.isEqual(
            dataPreLoader.sort(),
            props.preLoaderTrackerDataSources.sort(),
          ) !== true ||
          !dataPreLoader?.length ||
          !preLoaderTrackerDataSources?.length
            ? false
            : true
        }
      >
        {customerCard}
      </Grid>
      {inspectComponent}
    </Box>
  );
  return (
    <>
      <QuickInspectPopupModal
        title={CheckValueLocale("quick_inspect", "", {}, intl)}
        open={open}
        close={handleClose}
        accept={handleClose}
        body={modalBody}
      />
    </>
  );
};

export default QuickInspect;
