import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import LineChartWidget from "components/widgets/lineChartWidget";
import PieChartWidget from "components/widgets/pieChartWidget";
import StackedLineWidget from "components/widgets/stackedLineWidget";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import ThemesTrends from "components/widgets/themes/themesTrends/ThemesTrends.js";
import ThemeAnalysis from "components/widgets/themes/themeAnalysis/ThemeAnalysis.js";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const EngagementsSection = (props) => {
  let { tikTokIcon, tikTokWidgets, preLoaderTrackerDataSources } = props;
  const intl = useIntl();

  const chartStatsData = [
    {
      name: "total_comments",
      value: tikTokWidgets?.totalInteractions,
    },
    {
      name: "insights_avg_comments_per_day",
      value: tikTokWidgets?.avgInteractions,
    },
  ];

  return (
    <>
      <Typography className="section-name">
        {CheckValueLocale("engagements_page", "", {}, intl).toUpperCase()}
      </Typography>

      <Box mt={3}>
        <LineChartWidget
          title={"comments_volume_tik"}
          titleToolTip={"comm_tolt"}
          totalLabel={"comments"}
          totalValue={tikTokWidgets?.totalCommentsVolumeCount}
          data={tikTokWidgets?.commentsVolume}
          showGroupBy={true}
          toolTipLabel={"comments"}
          showDownloadIcon
          bigImg
          lineChartPreLoaderDataSources={tikTokWidgets?.commentsVolumePreLoader}
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          iconAfterTitle={tikTokIcon}
          haveData
          labelColor={"#2AAAE2"}
          showChartDetailsWidget
          statsDetailsData={chartStatsData}
          redirectTo={"/interactions/social-media/tikTok-public/"}
        />
      </Box>
      <Box my={3}>
        <StackedLineWidget
          title={"comments_sentiment_analysis"}
          titleToolTip={"sent_tolt"}
          data={tikTokWidgets?.sentimentAnalysis}
          showGroupBy={true}
          areaStyle={true}
          toolTipLabel={"toolTipLabel"}
          showAI
          showDownloadIcon
          bigImg
          isSwitchCase
          showScales
          iconAfterTitle={tikTokIcon}
          dataPiechart={tikTokWidgets?.sentimentPieChart}
          hideLegend
          labelColors={["#89BB2A", "#E50035", "#F9A700"]}
          stackedLineWidgetPreLoaderDataSources={
            tikTokWidgets?.sentimentAnalysisPreLoader
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          isSentimentAnalysisShowCharts
        />
      </Box>
      <Box mt={3}>
        <ThemeAnalysis
          title={"monitor_theme_title"}
          titleToolTip="them_tolt"
          data={tikTokWidgets?.themeAnalysis}
          themeAnalysisPreLoaderDataSources={
            tikTokWidgets?.themeAnalysisPreLoader
          }
          showAI
          showDownloadIcon
          bigImg
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          iconAfterTitle={tikTokIcon}
        />
      </Box>
      <Box mt={3}>
        <ThemesTrends
          title={"monitor_theme_trend_title"}
          titleToolTip="them_trend_tolt"
          data={tikTokWidgets?.themeTrend}
          showGroupBy={false}
          showAI
          showDownloadIcon
          bigImg
          themeTrendPreLoaderDataSources={tikTokWidgets?.themeTrendPreLoader}
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          iconAfterTitle={tikTokIcon}
        />
      </Box>
      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TableWidget
              wordsList
              title={"insights_top_keywords"}
              titleToolTip={"comments_top_keywords_tooltip"}
              showDownloadIcon={true}
              data={tikTokWidgets?.topKeywords}
              leftColomnTitle={"keywords"}
              rightColomnTitle={"number_of_mentions_tiktok"}
              tableChartPreLoaderDataSources={
                tikTokWidgets?.topKeywordsPreLoader
              }
              preLoaderTrackerDataSources={preLoaderTrackerDataSources}
              keywords
              tablePagination
              count={tikTokWidgets?.topKeywords?.length}
              iconAfterTitle={tikTokIcon}
              dynamicClass={"rowid-name-quick-inspect"}
              withWordCloud
            />
          </Grid>
          <Grid item xs={6}>
            <TableWidget
              wordsList
              title={"insights_top_hashtags"}
              titleToolTip={"comments_top_hashtags_tooltip"}
              showDownloadIcon={true}
              data={tikTokWidgets?.topHashtags}
              leftColomnTitle={"hashtags"}
              rightColomnTitle={"number_of_mentions_tiktok"}
              tableChartPreLoaderDataSources={
                tikTokWidgets?.topHashtagsPreLoader
              }
              preLoaderTrackerDataSources={preLoaderTrackerDataSources}
              hashtags
              tablePagination
              count={tikTokWidgets?.topHashtags?.length}
              iconAfterTitle={tikTokIcon}
              dynamicClass={"rowid-name-quick-inspect"}
              withWordCloud
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <PieChartWidget
              title={"com_gender"}
              titleToolTip={"gender_tolt"}
              data={tikTokWidgets?.genderDistribution}
              areaStyle={true}
              toolTipLabel={"toolTipLabel"}
              showDownloadIcon
              color={["#2AAAE2", "#FF4081"]}
              pieChartPreLoaderDataSources={
                tikTokWidgets?.genderDistributionPreLoader
              }
              preLoaderTrackerDataSources={preLoaderTrackerDataSources}
              iconAfterTitle={tikTokIcon}
            />
          </Grid>
          <Grid item xs={6}>
            <PieChartWidget
              title={"comments_associated_topics"}
              titleToolTip={"assoc_tolt"}
              data={tikTokWidgets?.associatedTopics}
              areaStyle={true}
              toolTipLabel={"toolTipLabel"}
              showDownloadIcon
              pieChartPreLoaderDataSources={
                tikTokWidgets?.associatedTopicsPreLoader
              }
              preLoaderTrackerDataSources={preLoaderTrackerDataSources}
              iconAfterTitle={tikTokIcon}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <PieChartWidget
              title={"com_top_langs"}
              titleToolTip={"lang_tolt"}
              data={tikTokWidgets?.topLanguages}
              areaStyle={true}
              toolTipLabel={"toolTipLabel"}
              showDownloadIcon
              pieChartPreLoaderDataSources={
                tikTokWidgets?.topLanguagesPreLoader
              }
              preLoaderTrackerDataSources={preLoaderTrackerDataSources}
              iconAfterTitle={tikTokIcon}
            />
          </Grid>
          <Grid item xs={6}>
            <PieChartWidget
              title={"dialects_subdialects"}
              titleToolTip={"dialct_tolt"}
              data={tikTokWidgets?.dialects}
              subData={tikTokWidgets?.subDialects}
              areaStyle={true}
              toolTipLabel={"toolTipLabel"}
              showDownloadIcon
              pieChartPreLoaderDataSources={tikTokWidgets?.dialectsPreLoader}
              preLoaderTrackerDataSources={preLoaderTrackerDataSources}
              iconAfterTitle={tikTokIcon}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TableWidget
              itemsList
              title={"top_engagers"}
              titleToolTip={"engager_tolt"}
              data={tikTokWidgets?.topEngagers}
              leftColomnTitle={"users"}
              rightColomnTitle={"engagements"}
              tableChartPreLoaderDataSources={
                tikTokWidgets?.topEngagersPreLoader
              }
              preLoaderTrackerDataSources={preLoaderTrackerDataSources}
              isTiktok
              twitterIcon={tikTokIcon}
              tablePagination
              count={tikTokWidgets?.topEngagers?.length}
              iconAfterTitle={tikTokIcon}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default EngagementsSection;
