import { useQuery } from "@tanstack/react-query";
import { agentStatusServices } from "services/api/agentStatus";

export const useGetAllAgentStatus = ({ enabled }) => {
  return useQuery({
    queryKey: ["agentStatus"],
    queryFn: () => agentStatusServices.getAllAgentStatuses(),
    enabled,
  });
};
