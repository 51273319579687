import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Box, FormHelperText, TextField } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";

const FreeAnswerQuestion = (props) => {
  const { question, questionIndex, setErrorForm, previewMode } = props;

  const intl = useIntl();

  const [answer, setAnswer] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const handleChangeAnswer = (e) => {
    const value = e?.target?.value;

    setAnswer(value);

    if (question?.constraints?.max_chars > 0) {
      if (value?.length > question?.constraints?.max_chars) {
        setErrorMsg("error_msg_free_text_survey");
        setErrorForm((prev) => ({ ...prev, [questionIndex + 1]: true }));
      } else {
        setErrorMsg("");
        setErrorForm((prev) => ({ ...prev, [questionIndex + 1]: false }));
      }
    }
  };

  return (
    <Box className="survey-builder-question-free-text">
      <TextField
        id="survey-builder-question-free-text-input"
        className="survey-builder-question-free-text-input"
        placeholder={CheckValueLocale("type_your_answer_here", "", {}, intl)}
        value={answer}
        onChange={previewMode ? handleChangeAnswer : null}
        variant="standard"
        error={errorMsg?.length}
      />

      {errorMsg?.length ? (
        <FormHelperText error={true}>
          {CheckValueLocale(
            errorMsg,
            "",
            { max: question?.constraints?.max_chars },
            intl,
          )}
        </FormHelperText>
      ) : null}
    </Box>
  );
};

export default FreeAnswerQuestion;
