import { useFormik } from "formik";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import PopupModal from "components/popupModal";
import ManageUserAccountListsDialogBody from "./manageUserAccountListsDialogBody";
import { Box, Typography } from "@mui/material";
import { useManageLists } from "pages/accountsLists/hooks/useManageLists";
import "./manageUserAccountListsDialog.scss";
import { useEffect, useState } from "react";
import SnackBar from "components/snackBar";

const ManageUserAccountListsDialog = (props) => {
  const { openDialog, setOpenDialog, post } = props;
  const [snackBarData, setSnackBarData] = useState({
    message: "",
    severity: "",
    title: "",
    openSnackBar: false,
  });
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  const [originalSelectedAccountList, setOriginalSelectedAccountList] =
    useState([]);
  const intl = useIntl();

  const handleCloseDialog = async () => {
    setOpenDialog(false);
  };

  const handleCloseSnackBar = () => {
    setSnackBarData({
      openSnackBar: false,
    });
  };

  const { mutate: manageAccountLists } = useManageLists();

  const formik = useFormik({
    initialValues: { selectedAccountList: [] },
    onSubmit: () => onSubmitSelectedAccountLists(),
  });

  const onSubmitSelectedAccountLists = () => {
    let account_list_ids = formik?.values?.selectedAccountList?.map(
      (item) => item?.value,
    );

    manageAccountLists(
      { username: post?.screen_name, account_list_ids },
      {
        onSuccess: () => {
          handleCloseDialog();
          setSnackBarData({
            openSnackBar: true,
            severity: "success",
            title: "",
            message: "manage_lists_success_msg",
          });

          props?.getFiltersFileds && props?.getFiltersFileds();
        },
        onError: () => {
          setSnackBarData({
            openSnackBar: true,
            severity: "error",
            title: "something_went_wrong",
            message: "try_again",
          });
        },
      },
    );
  };

  const handleListChange = (value) => {
    formik?.setFieldValue("selectedAccountList", value);
  };

  const responseOptionsTransformer = (fetchedOptions) => {
    return fetchedOptions?.data?.data?.map((item) => ({
      value: item?.id,
      label: item?.attributes?.name,
      count: item?.attributes?.usernames_count,
      usernames_full: item?.attributes?.usernames_count >= 200,
      is_selected: item?.attributes?.is_selected,
    }));
  };

  const valueToRender = () => {
    return formik?.values?.selectedAccountList;
  };

  const handleOnOptionsFetched = (fetchedOptions) => {
    const selectedAccountList = fetchedOptions.data?.data?.filter(
      (item) => item.attributes?.is_selected,
    );
    formik.setFieldValue(
      "selectedAccountList",
      responseOptionsTransformer({ data: { data: selectedAccountList } }),
    );
    setOriginalSelectedAccountList(
      responseOptionsTransformer({ data: { data: selectedAccountList } }),
    );
  };

  useEffect(() => {
    if (
      (formik?.values?.selectedAccountList?.length == 0 &&
        originalSelectedAccountList.length == 0) ||
      JSON.stringify(originalSelectedAccountList) ===
        JSON.stringify(formik?.values?.selectedAccountList)
    ) {
      setDisableSaveButton(true);
    } else {
      setDisableSaveButton(false);
    }
  }, [formik?.values?.selectedAccountList]);

  const popupProps = {
    title: "manage-lists",
    leftBtnId: "account_lists-cancel-button",
    rightBtn: "save",
    rightBtnId: "account-lists-save-button",
  };

  return (
    <>
      <PopupModal
        open={openDialog}
        close={handleCloseDialog}
        title={
          <Box className="account-lists-dialog-popup-title">
            {CheckValueLocale("manage_lists_title", "", {}, intl)}{" "}
            <Typography
              component="span"
              className="account-lists-dialog-popup-username-title"
            >
              @{post?.screen_name}
            </Typography>
          </Box>
        }
        classeName="account-lists-dialog-popup"
        body={
          <ManageUserAccountListsDialogBody
            openDialog={openDialog}
            setSnackBarData={setSnackBarData}
            post={post}
            handleListChange={handleListChange}
            valueToRender={valueToRender}
            responseOptionsTransformer={responseOptionsTransformer}
            handleOnOptionsFetched={handleOnOptionsFetched}
          />
        }
        modalStyle="account-lists-dialog-content"
        leftBtnId={CheckValueLocale(popupProps?.leftBtnId, "", {}, intl)}
        leftBtn={CheckValueLocale("cancel", "", {}, intl)}
        rightBtnId={CheckValueLocale(popupProps?.rightBtnId, "", {}, intl)}
        rightBtn={CheckValueLocale(popupProps?.rightBtn, "", {}, intl)}
        isRightBtnLoading={false}
        disabled={disableSaveButton}
        minWidth="600px"
        addClasses="actions-buttons-shared"
        accept={formik?.handleSubmit}
        enableDivider={true}
      />
      <SnackBar
        open={snackBarData?.openSnackBar}
        severity={snackBarData?.severity}
        message={CheckValueLocale(snackBarData?.message, "", {}, intl)}
        title={CheckValueLocale(snackBarData?.title, "", {}, intl)}
        handleClose={handleCloseSnackBar}
      />
    </>
  );
};

export default ManageUserAccountListsDialog;
