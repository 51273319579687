import { Box, Container, Typography } from "@mui/material";
import "./viewUser.scss";
import { ViewUserHeader } from "./components/viewUserHeader";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import CircularLoading from "components/circularProgress";
import { CheckValueLocale, getSortedArray } from "utils/helpers";
import ViewUserMainCard from "./components/ViewUserMainCard";
import SnackBar from "components/snackBar";
import { AdvancedPermissions } from "./components/advancedPermissions";
import { ViewUserTabs } from "./components/ViewUserTabs";
import { useGetUserView } from "../../hooks/useGetUserView";
import ProductsPermissions from "../addNewUser/components/productsPermissions/productsPermissions";
import ActivityLog from "../../components/userManagementTable/userActivityLog/activityLog";

export const ViewUser = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const intl = useIntl();
  const activityLogSectionRef = useRef(null);
  const location = useLocation();
  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    mail: "",
    phone: "",
    job_title: "",
    Valid: false,
  });

  const [snackBar, setSnackBar] = useState({
    message: "",
    severity: "",
    title: "",
    openSnackBar: false,
  });
  const [selectedPermissions, setSelectedPermissions] = useState({
    general_setting: "moderator",
    social_listening: "moderator",
    channel_analytics: "moderator",
    surveys: "moderator",
    audience: "moderator",
    engagement: "no_acc",
    lucidya_api: "moderator",
    ai_agent: "no_acc",
  });

  const [selectedAccessData, setSelectedAccessData] = useState({
    engagement_features: [],
    accounts_access: [],
    monitors_access: [],
    channels_access: [],
  });

  const [collapseNumber, setCollapseNumber] = useState([]);
  const {
    data: userViewInfo,
    isError: isUserViewInfoError,
    isPending: isLoading,
  } = useGetUserView(id);

  useEffect(() => {
    if (!!userViewInfo && !isUserViewInfoError) {
      const newCollapseNumber = [];
      const newSelectedAccessData = {
        engagement_features: [],
        accounts_access: [],
        monitors_access: [],
        channels_access: [],
      };
      setUserData({
        id: id,
        name: `${userViewInfo?.included?.[0]?.attributes?.first_name?.toString()} ${userViewInfo?.included?.[0]?.attributes?.last_name?.toString()}`,
        first_name:
          userViewInfo?.included?.[0]?.attributes?.first_name?.toString(),
        last_name:
          userViewInfo?.included?.[0]?.attributes?.last_name?.toString(),
        mail: userViewInfo?.data?.attributes?.email?.toString(),
        phone:
          userViewInfo?.included?.[0]?.attributes?.phone_number?.toString(),
        job_title:
          userViewInfo?.included?.[0]?.attributes?.job_title?.toString(),
        Valid: userViewInfo?.included?.[0]?.attributes?.status?.toString(),
        status: userViewInfo?.data?.attributes?.status?.toString(),
        avatar: userViewInfo?.data?.attributes?.avatar,
        teams: userViewInfo?.data?.attributes?.user_teams,
        languages: userViewInfo?.data?.attributes?.user_languages,
        skills: userViewInfo?.data?.attributes?.user_skills,
        permissions: userViewInfo?.data?.attributes?.permissions,
        dialects: userViewInfo?.data?.attributes?.user_dialects,
        themes: userViewInfo?.data?.attributes?.user_themes,
        topics: userViewInfo?.data?.attributes?.topics_monitors,
        segments: userViewInfo?.data?.attributes?.user_segments,
        working_time: userViewInfo?.data?.attributes?.user_working_time,
        sentiments: userViewInfo?.data?.attributes?.user_sentiments,
        hasEngagements: userViewInfo?.data?.attributes?.permissions?.some(
          (permission) => permission?.product === "ENGAGEMENTS",
        ),
      });

      //permissions
      let productNames = {
        GENERAL: "general_setting",
        CXM: "channel_analytics",
        SM: "social_listening",
        SURVEY: "surveys",
        CDP: "audience",
        ENGAGEMENTS: "engagement",
        PUBLIC_API: "lucidya_api",
        AI_API: "lucidya_api",
        AI_AGENT: "ai_agent",
      };
      let roleMapper = {
        product_admin: "manager",
        viewer: "viwer",
        user: "moderator",
        team_leader_egag: "team_leader",
        agent_egag: "agent_egagment",
        no_access_egag: "no_acc",
      };
      let selectedObj = {};
      userViewInfo?.data?.attributes?.permissions?.map((item) => {
        selectedObj[productNames[item?.product]] =
          roleMapper[
            item?.product == "ENGAGEMENTS" || item?.product == "AI_AGENT"
              ? `${item?.role}_egag`
              : item?.role
          ];
      });
      if (!selectedObj?.engagement) {
        selectedObj.engagement = "no_acc";
      }
      if (!selectedObj?.ai_agent) {
        selectedObj.ai_agent = "no_acc";
      }
      setSelectedPermissions({ ...selectedObj });

      //tables integration
      if (
        userViewInfo?.data?.attributes?.engagements_permissions
          ?.engagement_features?.length
      ) {
        const rowsData =
          userViewInfo?.data?.attributes?.engagements_permissions
            ?.engagement_features;
        let sortedRowsData = [];
        const isEngagAccess = rowsData?.filter(
          (row) => row?.feature_name == "ENGAGEMENT_ACCESS",
        );
        isEngagAccess?.length && sortedRowsData.push(...isEngagAccess);
        const isAccessTabs = rowsData?.filter(
          (row) => row?.feature_name == "ENGAGEMENT_TABS",
        );
        isAccessTabs?.length && sortedRowsData.push(...isAccessTabs);
        const isSavedReplies = rowsData?.filter(
          (row) => row?.feature_name == "SAVED_REPLIES",
        );
        isSavedReplies?.length && sortedRowsData.push(...isSavedReplies);
        const isCollectionAccess = rowsData?.filter(
          (row) => row?.feature_name == "COLLECTION_MANAGEMENT",
        );
        isCollectionAccess?.length &&
          sortedRowsData.push(...isCollectionAccess);

        const isExportEngagements = rowsData?.filter(
          (row) => row?.feature_name == "EXPORT_ENGAGEMENTS",
        );
        isExportEngagements?.length &&
          sortedRowsData.push(...isExportEngagements);
        const isTagEngagements = rowsData?.filter(
          (row) => row?.feature_name == "ENGAGEMENT_TAG_MANAGEMENT",
        );
        isTagEngagements?.length && sortedRowsData.push(...isTagEngagements);
        sortedRowsData?.map((row, i) => {
          newSelectedAccessData.engagement_features[i] = [
            row?.feature_name,
            ...getSortedArray(
              row?.feature_name == "SAVED_REPLIES"
                ? [
                    "FULL_ACCESS",
                    "CREATE_SAVED_REPLY",
                    "EDIT_SAVED_REPLY",
                    "DELETE_SAVED_REPLY",
                  ]
                : row?.feature_name == "ENGAGEMENT_ACCESS"
                  ? [
                      "FULL_ACCESS",
                      "INBOX",
                      "PUBLISH",
                      "ENGAGEMENT_ANALYTICS",
                      "SL_ENGAGEMENT",
                      "OC_ENGAGEMENT",
                    ]
                  : row?.feature_name == "EXPORT_ENGAGEMENTS"
                    ? ["EXPORT_ENGAGEMENTS"]
                    : row?.feature_name == "ENGAGEMENT_TAG_MANAGEMENT"
                      ? [
                          "FULL_ACCESS",
                          "CREATE_TAG",
                          "EDIT_TAG",
                          "DELETE_DISABLE_TAG",
                        ]
                      : row?.feature_name == "COLLECTION_MANAGEMENT"
                        ? [
                            "FULL_ACCESS",
                            "CREATE_COLLECTION",
                            "EDIT_COLLECTION",
                            "DELETE_COLLECTION",
                          ]
                        : ["FULL_ACCESS", "ALL_TAB", "UNASSIGNED_TAB"],

              row?.feature_permissions?.filter((el) => el !== "FULL_ACCESS"),
            ),
          ];
        });
      }

      if (
        userViewInfo?.data?.attributes?.engagements_permissions?.accounts_access
          ?.data?.length
      ) {
        userViewInfo?.data?.attributes?.engagements_permissions?.accounts_access?.data?.map(
          (row, i) => {
            if (row?.attributes?.access_permissions?.length) {
              newSelectedAccessData.accounts_access[i] = [
                { ...row?.attributes },
                ...getSortedArray(
                  ["FULL_ACCESS", "REPLY", "PUBLISH"],
                  row?.attributes?.access_permissions?.filter(
                    (el) => el !== "FULL_ACCESS",
                  ),
                ),
              ];
            }
          },
        );
      }

      if (newSelectedAccessData.accounts_access?.length) {
        newCollapseNumber?.push("accounts_access");
      }
      if (
        userViewInfo?.data?.attributes?.engagements_permissions?.monitors_access
          ?.data?.length
      ) {
        userViewInfo?.data?.attributes?.engagements_permissions?.monitors_access?.data?.map(
          (row, i) => {
            newSelectedAccessData.monitors_access[i] = { ...row?.attributes };
          },
        );
        newCollapseNumber?.push("monitors_access");
      }
      if (
        userViewInfo?.data?.attributes?.engagements_permissions?.channels_access
          ?.data?.length
      ) {
        userViewInfo?.data?.attributes?.engagements_permissions?.channels_access?.data?.map(
          (row, i) => {
            newSelectedAccessData.channels_access[i] = { ...row?.attributes };
          },
        );
        newCollapseNumber?.push("channels_access");
      }
      setCollapseNumber([...newCollapseNumber]);
      setSelectedAccessData({ ...newSelectedAccessData });
    }
  }, [userViewInfo]);

  useEffect(() => {
    const search = location?.search;
    let snackbarSuccessMessage;
    if (search && search?.includes("status=added_successfully")) {
      snackbarSuccessMessage = "user_added_succ";
    } else if (search && search?.includes("status=updated_successfully")) {
      snackbarSuccessMessage = "team_edits_saved_successfully";
    }

    if (snackbarSuccessMessage) {
      // show the message and then remove it from the url
      setSnackBar({
        message: snackbarSuccessMessage,
        severity: "success",
        openSnackBar: true,
      });
      navigate(window.location.pathname);
    }
  }, []);

  const handleCloseSnackBar = () => {
    setSnackBar({
      openSnackBar: false,
    });
  };
  useEffect(() => {
    if (
      location?.dataState &&
      location?.dataState?.scrollToSection === "user-activity-log-section" &&
      activityLogSectionRef?.current
    ) {
      activityLogSectionRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [activityLogSectionRef?.current]);

  const activeProducts = JSON.parse(window?.localStorage?.activeProducts);
  const isEngagActive = !!activeProducts?.filter(
    (item) => item?.name == "ENGAGEMENTS" && item?.active,
  )?.length;

  return isLoading || !userData?.id ? (
    <CircularLoading />
  ) : (
    <Container maxWidth="xl" className="view-user-container">
      <ViewUserHeader user={userData} id={id} />
      {/* UserBox */}
      <ViewUserMainCard userData={userData} />
      {/*user engagement info box  */}
      <ViewUserTabs userData={userData} />
      <Box className="main-view-user">
        <Box className="main-edit-user-card">
          <Typography className="edit-sec-tit">
            {CheckValueLocale("prod_permissions", "", {}, intl)}
          </Typography>
          <Box className="edit-sec-container">
            <ProductsPermissions
              stepTwoData={selectedPermissions}
              setStepTwoData={setSelectedPermissions}
              isView={true}
            />
          </Box>
        </Box>
        <Box className="main-activity-user-card" ref={activityLogSectionRef}>
          <Typography variant="h3">
            {CheckValueLocale("user_activity_log", "", {}, intl)}
          </Typography>
          <Box className="edit-sec-container">
            <ActivityLog isUserActivityLog userData={userData} />
          </Box>
        </Box>
      </Box>
      {userData?.hasEngagements &&
      isEngagActive &&
      (collapseNumber?.length ||
        selectedAccessData?.engagement_features?.length) ? (
        <AdvancedPermissions
          selectedAccessData={selectedAccessData}
          collapseNumber={collapseNumber}
        />
      ) : null}
      <SnackBar
        open={snackBar?.openSnackBar}
        severity={snackBar?.severity}
        message={CheckValueLocale(snackBar?.message, "", {}, intl)}
        title={CheckValueLocale(snackBar?.title, "", {}, intl)}
        handleClose={handleCloseSnackBar}
      />
    </Container>
  );
};
