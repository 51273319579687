import { Tooltip } from "@mui/material";
import moment from "moment";
import {
  calculateUnixTimeZone,
  formatTimeZone,
  handleDateLangFormat,
} from "utils/helpers";

export const tooltipCell = (text, textLength) => {
  return text?.length > textLength ? (
    <Tooltip title={text} placement="top" arrow>
      <span>{text?.substring(0, textLength)}...</span>
    </Tooltip>
  ) : (
    text
  );
};
export const ipSubnetCell = (arr) => {
  let ipSubnetText = "";
  arr?.map((ip, i) => {
    ipSubnetText =
      i == arr?.length - 1
        ? ipSubnetText + ip?.replaceAll('"', "")
        : ipSubnetText + ip?.replaceAll('"', "") + ", ";
  });
  return tooltipCell(ipSubnetText, 20);
};
export const dateCell = (date, appLang, intl) => {
  const timezone = parseInt(localStorage.getItem("companyInfo"));
  const unixTime = moment(date).unix();
  const calculatedTime = calculateUnixTimeZone(unixTime, {
    utc: true,
  });
  const { completeFormat } = formatTimeZone(calculatedTime, intl, {}, timezone);
  const [datePart, timeWithGMT] = completeFormat.split(", ");

  return (
    <>
      {datePart}
      <span className="time-span">{timeWithGMT}</span>
    </>
  );
};

export const statusCell = (status) => (status ? "active" : "deActiveStatus");

export const lucidyaApisTypeLocale = (locale) => locale + "_API";
