import { Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

export const NoResults = ({ handleOpenRequestVerificationModal }) => {
  const intl = useIntl();

  return (
    <>
      <Typography className="no-results-text">
        {CheckValueLocale("this_source_not_found_in_database", "", {}, intl)}
      </Typography>
      <Typography className="no-results-text">
        {CheckValueLocale("submit_verification_request", "", {}, intl)}{" "}
        <Typography
          onClick={handleOpenRequestVerificationModal}
          component="span"
          className="submit-request-link"
        >
          {CheckValueLocale("submit_request", "", {}, intl)}
        </Typography>
      </Typography>
    </>
  );
};
