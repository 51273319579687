import { CheckValueLocale } from "utils/helpers";

export const endTheConversationAfterOptions = (intl) => [
  {
    name: CheckValueLocale("end_after_mins", "", { mins: 10 }, intl),
    id: "after_10_minutes",
  },
  {
    name: CheckValueLocale("end_after_mins", "", { mins: 20 }, intl),
    id: "after_20_minutes",
  },
  {
    name: CheckValueLocale("end_after_mins", "", { mins: 30 }, intl),
    id: "after_30_minutes",
  },
  {
    name: CheckValueLocale("end_after_mins", "", { mins: 40 }, intl),
    id: "after_40_minutes",
  },
  {
    name: CheckValueLocale("end_after_mins", "", { mins: 50 }, intl),
    id: "after_50_minutes",
  },
  {
    name: CheckValueLocale("end_after_mins", "", { mins: 60 }, intl),
    id: "after_60_minutes",
  },
];
