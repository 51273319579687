import React, { useEffect, useState } from "react";
import { FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import ReportSelectInput from "pages/reports/components/reportSelectInput";

const monthDays = Array.from({ length: 28 }, (_, i) => i + 1);

const weekDays = [
  { value: 0, label: "Sunday" },
  { value: 1, label: "Monday" },
  { value: 2, label: "Tuesday" },
  { value: 3, label: "Wednesday" },
  { value: 4, label: "Thursday" },
  { value: 5, label: "Friday" },
  { value: 6, label: "Saturday" },
];

const ReportMonthlyOptionsSelect = ({
  formik,
  recurrences,
  showCustomDaySelect,
  setShowCustomDaySelect,
  isEdit,
}) => {
  const intl = useIntl();

  const { frequency, report_recurrence_id, on_specific_day } =
    formik?.values || {};

  // const [showCustomDaySelect, setShowCustomDaySelect] = useState(false); // Show custom day select

  const [everyDayType, setEveryDayType] = useState("first_day"); // First or Last day
  const [everyDaySelect, setEveryDaySelect] = useState(0); // Day of the week ex: (Sunday, Monday, Tuesday, ...)

  // Check if the report is on a specific day or every day
  const isSpecific = !!on_specific_day;

  // Recurrence ID for custom day "recurrence_type": "day" ex:("first_of_month", "end_of_month", "custom_day")
  const customDayReccurenceId = recurrences?.find(
    (item) => item?.recurrence_type == "day",
  )?.id;

  // Recurrence ID for First Sunday "recurrence_type": "first_day" && "recurrence": "0"
  const firstSundayReccurenceId = recurrences?.find(
    (item) => item?.recurrence_type == "first_day" && item?.recurrence == "0",
  )?.id;

  // Set the default values for the select inputs
  useEffect(() => {
    if (frequency == "monthly") {
      if (!on_specific_day) {
        const { recurrence_type, recurrence } =
          recurrences?.find((item) => item?.id == report_recurrence_id) || {};

        setEveryDayType(recurrence_type);
        setEveryDaySelect(Number(recurrence));
        setShowCustomDaySelect(false);
      }
    } else {
      setShowCustomDaySelect(false);
      setEveryDayType("first_day");
      setEveryDaySelect(0);
    }
  }, [recurrences, frequency, report_recurrence_id, on_specific_day]);

  const handleChangeMonthlyRecurrenceType = (e) => {
    const value = e?.target?.value;
    setEveryDayType("first_day");
    setEveryDaySelect(0);
    if (value == "specific") {
      formik?.setFieldValue("on_specific_day", 1);
      formik?.setFieldValue("report_recurrence_id", customDayReccurenceId);
    } else {
      formik?.setFieldValue("on_specific_day", "");
      formik?.setFieldValue("report_recurrence_id", firstSundayReccurenceId);
    }
  };

  const handleChangeSpecificDayType = async (e) => {
    const value = e?.target?.value;
    if (value == "custom") {
      setShowCustomDaySelect(true);
      await formik?.setFieldValue("on_specific_day", 1);
    } else {
      setShowCustomDaySelect(false);
      await formik?.setFieldValue("on_specific_day", value);
    }
  };

  const handleChangeEveryDayType = (e) => {
    const value = e?.target?.value;
    setEveryDayType(value);
    const recurrenceId = recurrences?.find(
      (item) =>
        item?.recurrence_type == value && item?.recurrence == everyDaySelect,
    )?.id;
    formik?.setFieldValue("report_recurrence_id", recurrenceId);
  };

  const handleChangeEveryDaySelect = (e) => {
    const value = e?.target?.value;
    setEveryDaySelect(value);
    const recurrenceId = recurrences?.find(
      (item) =>
        item?.recurrence_type == everyDayType && item?.recurrence == value,
    )?.id;
    formik?.setFieldValue("report_recurrence_id", recurrenceId);
  };

  return (
    <Grid container spacing={2}>
      <Grid item lg={6} xl={6}>
        <RadioGroup
          name="radio-buttons-group"
          value={!!on_specific_day ? "specific" : "every"}
          onChange={handleChangeMonthlyRecurrenceType}
        >
          <FormControlLabel
            id="radio-button-on-spec-day"
            label={CheckValueLocale("on_spec_day", "", {}, intl)}
            control={<Radio />}
            value="specific"
            sx={{ mt: 1 }}
          />
          <FormControlLabel
            id="radio-button-every"
            label={CheckValueLocale("every", "", {}, intl)}
            control={<Radio />}
            value="every"
            sx={{ mt: 2 }}
          />
        </RadioGroup>
      </Grid>
      <Grid item lg={6} xl={6}>
        <Grid container spacing={1}>
          <Grid
            item
            lg={showCustomDaySelect ? 6 : 12}
            xl={showCustomDaySelect ? 6 : 12}
          >
            <ReportSelectInput
              id="report-monthly-recurrence-specific-select-day-select"
              options={[
                { value: 1, label: "first_of_month" },
                { value: 31, label: "end_of_month" },
                { value: "custom", label: "custom_day" },
              ]}
              value={showCustomDaySelect ? "custom" : on_specific_day}
              onChange={handleChangeSpecificDayType}
              disabled={!isSpecific}
              isEdit={true}
            />
          </Grid>
          {showCustomDaySelect ? (
            <Grid item lg={6} xl={6}>
              <ReportSelectInput
                id="report-monthly-recurrence-specific-day-select"
                options={[
                  ...monthDays?.map((item) => {
                    return { value: item, label: item };
                  }),
                  {
                    value: 29,
                    label: `29 (${CheckValueLocale("or_end_of_the_month", "", {}, intl)})`,
                  },
                  {
                    value: 30,
                    label: `30 (${CheckValueLocale("or_end_of_the_month", "", {}, intl)})`,
                  },
                  {
                    value: 31,
                    label: `31 (${CheckValueLocale("or_end_of_the_month", "", {}, intl)})`,
                  },
                ]}
                value={on_specific_day}
                onChange={(e) =>
                  formik?.setFieldValue("on_specific_day", e.target.value)
                }
                disabled={!isSpecific}
                isEdit={true}
              />
            </Grid>
          ) : null}

          <Grid item lg={12} xl={12}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item lg={6} xl={6}>
                <ReportSelectInput
                  id="report-monthly-recurrence-specific-day-type-select"
                  options={[
                    { value: "first_day", label: "first" },
                    { value: "last_day", label: "last" },
                  ]}
                  disabled={isSpecific}
                  value={everyDayType}
                  onChange={handleChangeEveryDayType}
                  isEdit={true}
                />
              </Grid>
              <Grid item lg={6} xl={6}>
                <ReportSelectInput
                  id="report-monthly-recurrence-specific-day-select"
                  options={weekDays || []}
                  disabled={isSpecific}
                  value={everyDaySelect}
                  onChange={handleChangeEveryDaySelect}
                  isEdit={true}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReportMonthlyOptionsSelect;
