import React, { useEffect, useState } from "react";
const engagementTagsFunction = () => {
  const applyOpacity = (color, opacity) =>
    color?.startsWith("#")
      ? `rgba(${parseInt(color?.slice(1, 3), 16)}, ${parseInt(color?.slice(3, 5), 16)},
      ${parseInt(color?.slice(5, 7), 16)}, ${opacity})`
      : color?.replace(
          /rgba?\((\d+), (\d+), (\d+)(?:, [\d.]+)?\)/,
          `rgba($1, $2, $3, ${opacity})`,
        );
  return {
    applyOpacity,
  };
};

export default engagementTagsFunction;
