import { Box, Card } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const CardSecuritySection = ({ title, description, bodyCard }) => {
  const intl = useIntl();
  return (
    <Card className="card-security-section">
      <Box className="card-security-section-header">
        <Box className="card-security-section-tiltle">
          {CheckValueLocale(title, "", {}, intl)}
        </Box>
        <Box className="card-security-section-description">
          {CheckValueLocale(description, "", {}, intl)}
        </Box>
      </Box>
      {bodyCard}
    </Card>
  );
};

export default CardSecuritySection;
