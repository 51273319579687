import { useIntl } from "react-intl";
import PopupModal from "components/popupModal";
import { useArchiveSurvey } from "../hooks/useArchiveSurvey";
import { useDeleteSurvey } from "../hooks/useDeleteSurvey";
import { CheckValueLocale } from "utils/helpers";
import { Box } from "@mui/material";
import DOMPurify from "dompurify";

const DeleteArchiveSurveyModal = (props) => {
  const {
    surveyId,
    surveyName,
    openArchiveSurveyModal,
    setOpenArchiveSurveyModal,
    openDeleteSurveyModal,
    setOpenDeleteSurveyModal,
    isFarewellSurvey,
    surveyAiAgentName,
    setSnackbar,
  } = props;

  const intl = useIntl();

  const { mutate: archiveSurvey, isPending: archiveLoading } =
    useArchiveSurvey();

  const { mutate: deleteSurvey, isPending: deleteLoading } = useDeleteSurvey();

  const selectedSurveyHasAiAgent = surveyAiAgentName?.length > 0;

  const archiveModalBody = (
    <Box className="shared-dialog-body">
      {selectedSurveyHasAiAgent ? (
        <Box
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              CheckValueLocale(
                "archive_survey_connected_ai_agent_message",
                "",
                {
                  aiAgentNames: surveyAiAgentName
                    ?.map((name) => `[${name}]`)
                    ?.join(", "),
                },
                intl,
              ),
            ),
          }}
        />
      ) : isFarewellSurvey ? (
        <Box
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              CheckValueLocale(
                "archive_survey_connected_farewell_message",
                "",
                {},
                intl,
              ),
            ),
          }}
        />
      ) : (
        `${CheckValueLocale("are_you_sure_to_archive", "", { Name: surveyName }, intl)}`
      )}
    </Box>
  );
  const deleteModalBody = (
    <Box className="shared-dialog-body">
      {selectedSurveyHasAiAgent ? (
        <Box
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              CheckValueLocale(
                "delete_survey_connected_ai_agent_message",
                "",
                {
                  aiAgentNames: surveyAiAgentName
                    ?.map((name) => `[${name}]`)
                    ?.join(", "),
                },
                intl,
              ),
            ),
          }}
        />
      ) : isFarewellSurvey ? (
        <Box
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              CheckValueLocale(
                "delete_survey_connected_farewell_message",
                "",
                {},
                intl,
              ),
            ),
          }}
        />
      ) : (
        `${CheckValueLocale("are_you_sure_to_delete", "", { Name: surveyName }, intl)}`
      )}
    </Box>
  );

  const handleArchiveSurvey = async () => {
    archiveSurvey(
      { id: surveyId },
      {
        onSuccess: () => {
          setSnackbar({
            open: true,
            severity: "success",
            title: "",
            message: "survey_archived_successfully",
          });
        },
        onError: () => {
          setSnackbar({
            open: true,
            severity: "error",
            title: "failed_to_archive",
            message: "",
            surveyName: surveyName,
          });
        },
        onSettled: () => {
          setOpenArchiveSurveyModal(false);
          setTimeout(() => setSnackbar({}), 3000);
        },
      },
    );
  };

  const handleDeleteSurvey = async () => {
    deleteSurvey(
      { id: surveyId },
      {
        onSuccess: () => {
          setSnackbar({
            open: true,
            severity: "success",
            title: "",
            message: "survey_deleted_successfully",
          });
        },
        onError: () => {
          setSnackbar({
            open: true,
            severity: "error",
            title: "failed_to_delete_survey",
            message: "please_try_again",
          });
        },
        onSettled: () => {
          setOpenDeleteSurveyModal(false);
          setTimeout(() => setSnackbar({}), 3000);
        },
      },
    );
  };

  return (
    <>
      {openArchiveSurveyModal ? (
        <PopupModal
          title={CheckValueLocale("archive_survey", "", {}, intl)}
          body={archiveModalBody}
          leftBtn={CheckValueLocale("cancel", "", {}, intl)}
          rightBtn={CheckValueLocale("archive", "", {}, intl)}
          open={openArchiveSurveyModal}
          disabled={archiveLoading}
          isRightBtnLoading={archiveLoading}
          close={() => {
            if (!archiveLoading) setOpenArchiveSurveyModal(false);
          }}
          accept={handleArchiveSurvey}
          warning={surveyAiAgentName ? false : true}
          addClasses="actions-buttons-shared"
          minWidth="600px"
        />
      ) : null}
      {openDeleteSurveyModal ? (
        <PopupModal
          title={CheckValueLocale("delete_survey", "", {}, intl)}
          body={deleteModalBody}
          childrenModal={
            selectedSurveyHasAiAgent || isFarewellSurvey ? (
              <strong>
                {CheckValueLocale(
                  "are_you_sure_you_want_to_proceed",
                  "",
                  {},
                  intl,
                )}
              </strong>
            ) : (
              <strong>
                {CheckValueLocale("action_can_not_reverse", "", {}, intl)}
              </strong>
            )
          }
          leftBtn={CheckValueLocale("cancel", "", {}, intl)}
          rightBtn={
            selectedSurveyHasAiAgent || isFarewellSurvey
              ? CheckValueLocale("delete", "", {}, intl)
              : CheckValueLocale("confirm_delete_survey", "", {}, intl)
          }
          open={openDeleteSurveyModal}
          disabled={deleteLoading}
          isRightBtnLoading={deleteLoading}
          close={() => {
            if (!deleteLoading) setOpenDeleteSurveyModal(false);
          }}
          accept={handleDeleteSurvey}
          warning={true}
          addClasses="actions-buttons-shared"
          minWidth="600px"
        />
      ) : null}
    </>
  );
};
export default DeleteArchiveSurveyModal;
