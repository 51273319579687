import { Box, Input, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import "./style.scss";

const SurveyQuestionTitle = (props) => {
  const {
    number,
    title,
    subtitle,
    handleChangeTitle,
    handleChangeSubtitle,
    hideSubtitle,
    hideNumber,
    previewMode,
    required,
  } = props;

  const intl = useIntl();

  return (
    <Box className="survey-question-title-subtitle-wrapper">
      <Box className="survey-question-title-wrapper">
        {!hideNumber && !!Number(number) ? (
          <Box component="span" className="survey-question-number">
            {`${number}. `}
          </Box>
        ) : null}
        {previewMode ? (
          <Typography className="survey-builder-preview-question-title">
            {title}
            {required ? (
              <Box component="span" className="required-question-title">
                {` *`}
              </Box>
            ) : null}
          </Typography>
        ) : (
          <Box className="survey-question-title">
            <Input
              className="survey-question-title-input"
              placeholder={CheckValueLocale("what_is_your_name", "", {}, intl)}
              value={title}
              onChange={handleChangeTitle}
              disableUnderline={true}
              fullWidth
              multiline
              readOnly={previewMode}
            />
          </Box>
        )}
      </Box>
      {hideSubtitle || (previewMode && !subtitle?.length) ? null : (
        <Box className="survey-question-subtitle-wrapper">
          <Input
            className="survey-question-subtitle"
            variant="outlined"
            placeholder={CheckValueLocale("question_description", "", {}, intl)}
            value={subtitle}
            onChange={handleChangeSubtitle}
            disableUnderline={true}
            readOnly={previewMode}
          />
        </Box>
      )}
    </Box>
  );
};

export default SurveyQuestionTitle;
