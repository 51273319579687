import { useQuery } from "@tanstack/react-query";
import { newsBlogsService } from "services/api/monitors";

export const useNewsBlogs = ({ queryData, enabled }) => {
  return useQuery({
    queryKey: ["newsBlogs", { ...queryData }],
    queryFn: () => newsBlogsService(queryData),
    enabled: enabled,
    select: (data) => data?.data,
  });
};
