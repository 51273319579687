import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Radio,
  Tooltip,
  Typography,
} from "@mui/material";
import { CheckValueLocale, getSocialIcon } from "utils/helpers";
import { useReportCategories } from "../hooks/useReportCategories";
import { useReportChannels } from "../hooks/useReportChannels";
import { CreateReportContext } from "pages/reports/context/reportContext";
import CreateReportChannelsSelect from "../components/createReportChannelsSelect";
import CircularLoading from "components/circularProgress";
import ShareIcon from "@mui/icons-material/Share";
import EmailIcon from "@mui/icons-material/Email";
import SmsIcon from "@mui/icons-material/Sms";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import StarsIcon from "@mui/icons-material/Stars";
import CallIcon from "@mui/icons-material/Call";

const enabledCategories = [
  "social media",
  "chats",
  "calls",
  "ratings",
  "emails",
];

const cateoriesIcons = {
  "all channels": <SmsIcon />,
  "social media": <ShareIcon />,
  chats: <ChatBubbleIcon />,
  emails: <EmailIcon />,
  ratings: <StarsIcon />,
  calls: <CallIcon />,
};

const dataSourcesHasDms = ["TWITTER", "FACEBOOK", "INSTAGRAM"]; // Data sources that has DM

const ReportSettingsOmnichannel = () => {
  const { formik, setSnackbar } = useContext(CreateReportContext);

  const intl = useIntl();

  const [disableDms, setDisableDms] = useState(false);

  // This is to disable DMS if the selected category is not in the list of available dms platforms
  useEffect(() => {
    if (
      !dataSourcesIdsHasDms?.some((platform) =>
        formik?.values?.data_source_id?.includes(platform),
      )
    ) {
      setDisableDms(true);
      formik?.setFieldValue("data_included", "public");
    } else setDisableDms(false);
  }, [formik?.values?.data_source_id]);

  const {
    data: availableCategories,
    isLoading: availableCategoriesLoading,
    isError: availableCategoriesError,
  } = useReportCategories({
    queryData: { product_id: formik?.values?.product_id },
  });

  // Get data sources ids that has DM
  const dataSourcesIdsHasDms =
    dataSourcesHasDms?.map(
      (platform) => availableCategories?.namesToIds?.[platform],
    ) || [];

  const handleSelectDataSource = (e) => {
    const value = Number(e?.target?.value);
    const selected = formik?.values?.data_source_id || [];
    formik?.setTouched({});
    formik?.setSubmitting(false);

    // Updated data_source_id
    const updatedDatasources = selected?.includes(value)
      ? selected?.filter((id) => id !== value)
      : [...selected, value];

    // Update data_source_id
    formik?.setFieldValue("data_source_id", updatedDatasources || []);
  };

  const handleChangeDataIncluded = (e) => {
    const value = e?.target?.value;
    formik?.setFieldValue("data_included", value);
    if (value === "private") {
      const updatedDatasources = formik?.values?.data_source_id?.filter((id) =>
        dataSourcesIdsHasDms?.includes(id),
      );
      formik?.setFieldValue("data_source_id", updatedDatasources || []);
    }
  };

  const {
    data: availableChannels,
    isLoading: availableChannelsLoading,
    isError: availableChannelsError,
  } = useReportChannels({
    datasourcesIds: availableCategories?.namesToIds || {},
    queryData: {
      product_id: formik?.values?.product_id,
      data_source_id: formik?.values?.data_source_id?.join(",") || "",
      data_source_type: formik?.values?.data_included,
    },
  });

  useEffect(() => {
    if (availableCategoriesError || availableChannelsError) {
      setSnackbar({
        open: true,
        severity: "error",
        title: "something_went_wrong",
        message: "try_again",
      });
    }
  }, [availableCategoriesError, availableChannelsError]);

  const handleChangeChannels = (e) => {
    const value = e?.target?.value;
    if (value?.includes("select_all"))
      formik?.setFieldValue("monitor_list", availableChannels);
    else if (value?.includes("unselect_all"))
      formik?.setFieldValue("monitor_list", []);
    else formik?.setFieldValue("monitor_list", value);
  };

  // This is to filter the monitors based on the selected data_source_id and data_included
  useEffect(() => {
    const selectedDatasources = formik?.values?.data_source_id || [];
    const dataIncluded = formik?.values?.data_included?.split(",") || [];
    const selectedMonitors = formik?.values?.monitor_list || [];

    const filteredMonitors = selectedMonitors?.filter(
      (monitor) =>
        selectedDatasources?.includes(monitor?.data_source_id) &&
        dataIncluded?.includes(monitor?.type || "public"),
    );

    formik?.setFieldValue("monitor_list", filteredMonitors || []);
  }, [formik?.values?.data_source_id, formik?.values?.data_included]);

  return (
    <Box>
      {availableCategoriesLoading ? (
        <CircularLoading />
      ) : (
        <Box className="create-report-omnichannel-report-settings-step-container">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>
                {CheckValueLocale("select_channel_type", "", {}, intl)}:
              </Typography>
            </Grid>
            {Object?.keys(availableCategories?.groupedCategories)?.map(
              (item) => {
                const isDisabledCategory = !enabledCategories?.includes(
                  item?.toLowerCase(),
                );

                const tooltipText = isDisabledCategory
                  ? CheckValueLocale("report_disabled_type", "", {}, intl)
                  : "";

                return (
                  <Grid key={item} item xs={4}>
                    <Tooltip title={tooltipText} placement="bottom" arrow>
                      <FormControlLabel
                        id={`create-report-omnichannel-category-item-${item?.toLowerCase()}`}
                        className={`create-report-omnichannel-category-item ${isDisabledCategory ? "category-item-disabled" : ""} ${formik?.values?.channel_type === item ? "category-item-selected" : ""}`}
                        onClick={() => {
                          {
                            formik?.setValues({
                              ...formik?.values,
                              channel_type: item,
                              data_source_id: [],
                              monitor_list: [],
                              data_included: "public",
                            });
                            formik?.setTouched({});
                            formik?.setSubmitting(false);
                          }
                        }}
                        label={
                          <Box className="category-item-title-icon-wrapper">
                            {cateoriesIcons?.[item?.toLowerCase()] || null}
                            <Typography>
                              {CheckValueLocale(item, "", {}, intl)}
                            </Typography>
                          </Box>
                        }
                        value={item}
                        control={<Radio />}
                      />
                    </Tooltip>
                  </Grid>
                );
              },
            )}
            {formik?.touched?.channel_type && !!formik?.errors?.channel_type ? (
              <Grid item xs={12}>
                <FormHelperText error>
                  {CheckValueLocale(formik?.errors?.channel_type, "", {}, intl)}
                </FormHelperText>
              </Grid>
            ) : null}

            {!!formik?.values?.channel_type ? (
              <Grid item xs={6} mt={2}>
                <InputLabel className="report-monthly-recurrence-select-input-label">
                  {CheckValueLocale("select_channels", "", {}, intl)}
                </InputLabel>
                <Box className="create-report-omnichannel-select-channels-wrapper">
                  {availableCategories?.groupedCategories?.[
                    formik?.values?.channel_type
                  ]?.map((item) => {
                    // If the selected data_included is "private" and the current data_source
                    // is not in the list of available dms platforms, skip it
                    if (
                      formik?.values?.data_included === "private" &&
                      formik?.values?.channel_type === "Social Media" &&
                      !dataSourcesHasDms?.includes(item?.source)
                    )
                      return null;
                    else
                      return (
                        <FormControlLabel
                          key={item?.source}
                          id={`create-report-omnichannel-select-channel-${item?.source}-datasource`}
                          onChange={handleSelectDataSource}
                          value={item?.id}
                          control={
                            <Checkbox
                              checked={formik?.values?.data_source_id?.includes(
                                item?.id,
                              )}
                            />
                          }
                          label={getSocialIcon(item?.source)}
                        />
                      );
                  })}
                </Box>
                {formik?.touched?.data_source_id &&
                !!formik?.errors?.data_source_id ? (
                  <FormHelperText error>
                    {CheckValueLocale(
                      formik?.errors?.data_source_id,
                      "",
                      {},
                      intl,
                    )}
                  </FormHelperText>
                ) : null}
              </Grid>
            ) : null}

            {/* Check if the selected type is Social Media */}
            {formik?.values?.channel_type === "Social Media" ? (
              <Grid item xs={6} mt={2}>
                <InputLabel className="report-monthly-recurrence-select-input-label">
                  {CheckValueLocale("data_included", "", {}, intl)}
                </InputLabel>
                <Box className="create-report-omnichannel-select-channels-wrapper">
                  {[
                    {
                      value: "private,public",
                      label: "all_data",
                      disabled: false,
                    },
                    { value: "public", label: "only_posts", disabled: false },
                    {
                      value: "private",
                      label: "only_dms",
                      disabled: disableDms,
                    },
                  ]?.map((item) => {
                    return (
                      <FormControlLabel
                        key={item?.value}
                        id={`create-report-omnichannel-select-channel-${item?.value}-datasource`}
                        name="data_included"
                        onChange={(e) => handleChangeDataIncluded(e)}
                        onBlur={formik?.handleBlur}
                        value={item?.value}
                        checked={formik?.values?.data_included === item?.value}
                        control={<Radio />}
                        disabled={item?.disabled}
                        label={CheckValueLocale(item?.label, "", {}, intl)}
                      />
                    );
                  })}
                </Box>
              </Grid>
            ) : (
              <Grid item xs={6} />
            )}

            <Grid item xs={6}>
              {formik?.values?.data_source_id?.length >= 1 ? (
                <CreateReportChannelsSelect
                  formik={formik}
                  options={availableChannels}
                  value={formik?.values?.monitor_list}
                  onChange={handleChangeChannels}
                  onBlur={formik?.handleBlur}
                  loading={availableChannelsLoading}
                  error={
                    formik?.touched?.monitor_list &&
                    formik?.errors?.monitor_list
                  }
                  helperText={
                    Boolean(formik?.touched?.monitor_list) &&
                    formik?.errors?.monitor_list
                  }
                />
              ) : null}
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default ReportSettingsOmnichannel;
