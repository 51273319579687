import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import FormTitle from "./components/formTitle";
import FormDescription from "./components/formDescription";
import FormField from "./components/formField";
import FormButton from "./components/formButton";
import { CheckValueLocale } from "utils/helpers";
import SnackBar from "components/snackBar";
import FormCloudFlare from "./components/formCloudFlare";
import Services from "services/api/Services";

const FormLogin = ({
  stats,
  handleUserLogin,
  handleInputChange,
  errorFields,
  handleShowForgetPassword,
  handleSSOlogin,
  handleVerifySuccess,
  handleVerifyExpire,
}) => {
  const intl = useIntl();
  return (
    <>
      <Box className="form-login-wrapper">
        <Box className="form-login-text-box">
          <FormTitle
            text={CheckValueLocale("welcome_back_to_Lucidya", "", {}, intl)}
          />
          <FormDescription demo />
        </Box>
        <FormField
          name="email"
          label={CheckValueLocale("email", "", {}, intl)}
          placeholder={CheckValueLocale("enter_your_email", "", {}, intl)}
          type="text"
          onChange={handleInputChange}
          error={
            stats?.wrongInput ||
            stats?.showSnackBarError === "showError" ||
            stats?.showSnackBarWarning === "showDeactivated" ||
            errorFields?.email
          }
          errorField={errorFields?.email}
        />
        <FormField
          name="password"
          label={CheckValueLocale("password", "", {}, intl)}
          placeholder={CheckValueLocale("enter_your_password", "", {}, intl)}
          type="password"
          onChange={handleInputChange}
          error={
            stats?.wrongInput ||
            stats?.showSnackBarError === "showError" ||
            stats?.showSnackBarWarning === "showDeactivated" ||
            errorFields?.password
          }
          forget={handleShowForgetPassword}
          errorField={errorFields?.password}
        />
        {stats?.wrongInput ? (
          <Box component="p" className="error-text-msg main-error">
            {" "}
            {stats?.errorText}{" "}
          </Box>
        ) : null}
        {window?.location?.hostname !== "testing-cxm.lucidya.com" ? (
          <FormCloudFlare
            handleVerifySuccess={handleVerifySuccess}
            handleVerifyExpire={handleVerifyExpire}
            errorField={errorFields?.verify}
          />
        ) : null}
        <FormButton
          text={CheckValueLocale("log_in", "", {}, intl)}
          variant="filled"
          onClick={handleUserLogin}
          loading={stats?.loginLoading}
        />
        {Services?.project_name_en === "Lucidya" ? (
          <>
            <Box className="divider-wrapper">
              <Box component="span" className="box-line first-line"></Box>
              <Box component="span" className="box-or">
                {" "}
                {CheckValueLocale("or_login", "", {}, intl)}{" "}
              </Box>
              <Box component="span" className="box-line sec-line"></Box>
            </Box>
            <FormButton
              onClick={handleSSOlogin}
              text={CheckValueLocale("log_in_with_sso", "", {}, intl)}
              variant="outline"
              loading={stats?.ssoLoader}
            />
          </>
        ) : null}
      </Box>
      <SnackBar
        open={window?.localStorage?.getItem("loginRedirection") === "true"}
        severity={"error"}
        title={CheckValueLocale("please_log_in_again", "", {}, intl)}
        message={CheckValueLocale("session_time_ended", "", {}, intl)}
      />
      <SnackBar
        open={window?.localStorage?.getItem("sessionExpired") === "true"}
        severity={"error"}
        message={CheckValueLocale("server_error_body", "", {}, intl)}
        title={CheckValueLocale("server_error_head", "", {}, intl)}
        alertStyle={{ width: "459px", top: "-13px" }}
      />
    </>
  );
};
export default FormLogin;
