import { CheckValueLocale } from "utils/helpers";
import { faCheckSquare, faPlug } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Tooltip, Typography, tooltipClasses } from "@mui/material";
import styled from "@emotion/styled";
import { useIntl } from "react-intl";
import TitleBox from "../header/titleBox";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "./../navigation.min.css";
import { Skeleton } from "@mui/material";
import SnackBar from "components/snackBar";
import { useLocation, useNavigate } from "react-router-dom";

SwiperCore.use([Navigation]);
const NeedsAttentionSection = (props) => {
  const { loading, needsAttentionData, loadingDelay, setSnackBar, snackBar } =
    props;
  const navigate = useNavigate();
  const location = useLocation();

  const intl = useIntl();
  function getErrorMessage(
    auth_error_login,
    account_error,
    account_valid_error,
    auth_error_permission,
    is_paused,
    checkSM,
    account_tiktok_token_error,
  ) {
    if (!account_error?.length == 0) {
      return checkSM
        ? "home_page_account_error"
        : "home_page_account_error_channel";
    } else if (!auth_error_login?.length == 0) {
      return "home_page_auth_error_login";
    } else if (!auth_error_permission?.length == 0) {
      return "home_page_auth_error_permission";
    } else if (!account_valid_error?.length == 0) {
      return checkSM
        ? "home_page_account_valid_error"
        : "home_page_account_valid_error_channel";
    } else if (!is_paused?.length == 0) {
      return checkSM ? "home_page_is_paused" : "home_page_is_paused_channel";
    } else if (!account_tiktok_token_error?.length == 0) {
      return "channel_is_paused";
    }
  }
  const sm = localStorage.getItem("sm_id");

  const dataSkeleton = [
    { id: "1" },
    { id: "2" },
    { id: "3" },
    { id: "4" },
    { id: "5" },
  ];

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses?.arrow}`]: {
      color: "rgba(97, 97, 97, 0.9)",
    },
    [`& .${tooltipClasses?.tooltip}`]: {
      maxWidth: "194px",
      padding: "10px",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
    },
  }));

  const swiperSkeletonfun = () => {
    return (
      <Swiper
        navigation
        observer={true}
        spaceBetween={15}
        slidesPerView={4.3}
        modules={[Navigation]}
        key={intl.locale}
      >
        {dataSkeleton?.map((el) => {
          return (
            <SwiperSlide>
              <Box className="skeleton-parant">
                <Box className="skeleton-head-need">
                  <Skeleton width="100%" height="13px"></Skeleton>
                  <Skeleton width="104px" height="13px"></Skeleton>
                </Box>
                <Box className="skeleton-head">
                  <Skeleton
                    width="20px"
                    height="20px"
                    variant="circular"
                  ></Skeleton>
                  <Skeleton width="100px" height="13px"></Skeleton>
                </Box>
              </Box>
            </SwiperSlide>
          );
        })}
      </Swiper>
    );
  };

  const disabledUpgrade = !needsAttentionData?.limit_error?.can_request_upgrade;

  const limitErrorFun = () => {
    return needsAttentionData?.limit_error?.sm_limit_error ? (
      <SwiperSlide>
        <BootstrapTooltip
          arrow
          placement="top"
          title={
            disabledUpgrade
              ? CheckValueLocale(
                  "needs_attention_limit_error_tooltip",
                  "",
                  {},
                  intl,
                )
              : ""
          }
        >
          <Box
            onClick={() => {
              if (!disabledUpgrade)
                navigate("/settings/subscription", {
                  state: {
                    ...location.state,
                    needsAttentionLimitError: "sm_limit",
                  },
                });
            }}
            className={`item-slide needs-attention-item ${disabledUpgrade ? "disabled-item" : ""}`}
          >
            <Typography>
              <strong>
                {CheckValueLocale(
                  "home_page_needs_attention_request_upgrade",
                  "",
                  {},
                  intl,
                )}
              </strong>
              <br />
              <br />
              {CheckValueLocale(
                "home_page_needs_attention_limit_reached",
                "",
                {
                  product: (
                    <strong>
                      {CheckValueLocale("social_listening_v2", "", {}, intl)}
                    </strong>
                  ),
                },
                intl,
              )}
            </Typography>
            <Box>
              <Typography component={"h3"}>
                <FontAwesomeIcon icon={faPlug} />
                {CheckValueLocale("social_listening_v2", "", {}, intl)}
              </Typography>
            </Box>
          </Box>
        </BootstrapTooltip>
      </SwiperSlide>
    ) : null;
  };
  const newsBblogsLimit = () => {
    return needsAttentionData?.limit_error?.news_blogs_limit_error ? (
      <SwiperSlide>
        <BootstrapTooltip
          arrow
          placement="top"
          title={
            disabledUpgrade
              ? CheckValueLocale(
                  "needs_attention_limit_error_tooltip",
                  "",
                  {},
                  intl,
                )
              : ""
          }
        >
          <Box
            onClick={() => {
              if (!disabledUpgrade)
                navigate("/settings/subscription", {
                  state: {
                    ...location?.state,
                    needsAttentionLimitError: "sm_limit",
                  },
                });
            }}
            className={`item-slide needs-attention-item ${disabledUpgrade ? "disabled-item" : ""}`}
          >
            <Typography>
              <strong>
                {CheckValueLocale(
                  "home_page_needs_attention_request_upgrade",
                  "",
                  {},
                  intl,
                )}
              </strong>
              <br />
              <br />
              {CheckValueLocale(
                "home_page_needs_attention_limit_reached",
                "",
                {
                  product: (
                    <strong>
                      {CheckValueLocale(
                        "home_page_news_and_blogs",
                        "",
                        {},
                        intl,
                      )}
                    </strong>
                  ),
                },
                intl,
              )}
            </Typography>
            <Box>
              <Typography component={"h3"}>
                <FontAwesomeIcon icon={faPlug} />
                {CheckValueLocale("social_listening_v2", "", {}, intl)}
              </Typography>
            </Box>
          </Box>
        </BootstrapTooltip>
      </SwiperSlide>
    ) : null;
  };
  const cxmLimitError = () => {
    return needsAttentionData?.limit_error?.cxm_limit_error ? (
      <SwiperSlide>
        <BootstrapTooltip
          arrow
          placement="top"
          title={
            disabledUpgrade
              ? CheckValueLocale(
                  "needs_attention_limit_error_tooltip",
                  "",
                  {},
                  intl,
                )
              : ""
          }
        >
          <Box
            onClick={() => {
              if (!disabledUpgrade)
                navigate("/settings/subscription", {
                  state: {
                    ...location.state,
                    needsAttentionLimitError: "cxm_limit",
                  },
                });
            }}
            className={`item-slide needs-attention-item ${disabledUpgrade ? "disabled-item" : ""}`}
          >
            <Typography>
              <strong>
                {CheckValueLocale(
                  "home_page_needs_attention_request_upgrade",
                  "",
                  {},
                  intl,
                )}
              </strong>
              <br />
              <br />
              {CheckValueLocale(
                "home_page_needs_attention_limit_reached",
                "",
                {
                  product: (
                    <strong>{CheckValueLocale("CXM", "", {}, intl)}</strong>
                  ),
                },
                intl,
              )}
            </Typography>
            <Box>
              <Typography component={"h3"}>
                <FontAwesomeIcon icon={faPlug} />
                {CheckValueLocale("CXM", "", {}, intl)}
              </Typography>
            </Box>
          </Box>
        </BootstrapTooltip>
      </SwiperSlide>
    ) : null;
  };

  const redirectionError = (
    productId,
    monitorName,
    monitorId,
    accountIdentifier,
    errors,
    element,
  ) => {
    const errorType = getErrorMessage(
      errors.auth_error_login,
      errors.account_error,
      errors.account_valid_error,
      errors.auth_error_permission,
      errors.is_paused,
      errors.checkSM,
    );
    let pathname;
    switch (errorType) {
      case "home_page_auth_error_login":
      case "home_page_auth_error_permission":
      case "home_page_account_error":
      case "home_page_account_error_channel":
      case "home_page_account_valid_error":
      case "home_page_account_valid_error_channel":
        pathname =
          productId == sm
            ? "/settings/accounts_management/users_accounts"
            : "/settings/accounts_management/company_accounts";
        break;

      case "home_page_is_paused":
      case "home_page_is_paused_channel":
        pathname =
          productId == sm ? "/social/monitor_list" : "/manage-channels/list";
        break;
    }
    let checkErrorUserAccount =
      pathname == "/settings/accounts_management/users_accounts";
    let checkErrorCompanyAccounts =
      pathname == "/settings/accounts_management/company_accounts";

    navigate(pathname, {
      state: {
        ...location.state,

        needsAttentionData: {
          product_id: productId,
          search_account_name:
            productId == sm ? monitorName : accountIdentifier,
          redirected_monitor: monitorId,
          search:
            productId == sm
              ? undefined
              : checkErrorUserAccount || checkErrorCompanyAccounts
                ? accountIdentifier
                : undefined,
          data_source:
            checkErrorUserAccount || checkErrorCompanyAccounts
              ? element?.data_sources?.[0]
              : undefined,
          created_by_id: element?.action_needed_by?.id,
          isCompanyAccounts: pathname?.includes("company_accounts"),
        },
      },
    });
  };

  return (
    <Box className="section-slide needs-attention">
      <TitleBox title={"needs_attention"} tooltipText={"requiring_action"} />
      <Box className="slider-container">
        {loading || loadingDelay ? (
          swiperSkeletonfun()
        ) : needsAttentionData?.monitors?.length > 0 ? (
          <Swiper
            navigation
            observer={true}
            spaceBetween={15}
            slidesPerView={4.3}
            modules={[Navigation]}
            key={intl.locale}
          >
            {limitErrorFun()}
            {needsAttentionData?.monitors?.map((el) => {
              const cardError = getErrorMessage(
                el?.errors?.auth_error_login,
                el?.errors?.account_error,
                el?.errors?.account_valid_error,
                el?.errors?.auth_error_permission,
                el?.errors?.is_paused,
                el?.product_id == sm,
                el?.errors?.account_tiktok_token_error,
                el,
              );

              const disabledCard =
                cardError === "home_page_is_paused" ||
                cardError === "home_page_is_paused_channel"
                  ? !el?.can_resume
                  : !el?.can_reauth;

              const tooltipText =
                cardError === "home_page_is_paused"
                  ? "needs_attention_paused_monitor_tooltip"
                  : cardError === "home_page_is_paused_channel"
                    ? "needs_attention_paused_channel_tooltip"
                    : "needs_attention_other_errors_tooltip";

              return (
                <SwiperSlide>
                  <BootstrapTooltip
                    arrow
                    placement="top"
                    title={
                      disabledCard
                        ? CheckValueLocale(
                            tooltipText,
                            "",
                            {
                              creatorName: el?.action_needed_by?.name,
                              userName: el?.action_needed_by?.name,
                            },
                            intl,
                          )
                        : ""
                    }
                  >
                    <Box
                      onClick={() =>
                        disabledCard
                          ? null
                          : redirectionError(
                              el?.product_id,
                              el?.monitor_name,
                              el?.monitor_id,
                              el?.account_identifier,
                              el?.errors,
                              el,
                            )
                      }
                      className={`item-slide needs-attention-item ${disabledCard ? "disabled-item" : ""}`}
                    >
                      <Typography>
                        {CheckValueLocale(
                          getErrorMessage(
                            el?.errors?.auth_error_login,
                            el?.errors?.account_error,
                            el?.errors?.account_valid_error,
                            el?.errors?.auth_error_permission,
                            el?.errors?.is_paused,
                            el?.product_id == sm,
                            el?.errors?.account_tiktok_token_error,
                            el,
                          ) === "home_page_account_error" &&
                            (!el?.account_identifier ||
                              el?.accountIdentifier?.length == 0)
                            ? "home_page_account_error_without_account_identifier"
                            : getErrorMessage(
                                el?.errors?.auth_error_login,
                                el?.errors?.account_error,
                                el?.errors?.account_valid_error,
                                el?.errors?.auth_error_permission,
                                el?.errors?.is_paused,
                                el?.product_id == sm,
                                el?.errors?.account_tiktok_token_error,
                                el,
                              ),
                          "",
                          {
                            monitorName: <strong>{el?.monitor_name}</strong>,
                            accountName: (
                              <strong>{el?.action_needed_by?.name}</strong>
                            ),
                            dateSource: (
                              <strong>
                                {`${
                                  el?.data_sources?.[0]
                                    ? CheckValueLocale(
                                        el?.data_sources?.[0],
                                        "",
                                        {},
                                        intl,
                                      )
                                    : ""
                                }${
                                  el?.is_dm === true
                                    ? ` (${CheckValueLocale(
                                        "home_page_dms",
                                        "",
                                        {},
                                        intl,
                                      )})`
                                    : ""
                                }`}
                              </strong>
                            ),
                            accountIdentifier: (
                              <strong>{el?.account_identifier}</strong>
                            ),
                            br: <br />,
                          },
                          intl,
                        )}
                      </Typography>
                      <Box>
                        <Typography component={"h3"}>
                          <FontAwesomeIcon icon={faPlug} />
                          {el?.product_id == sm
                            ? CheckValueLocale(
                                "social_listening_v2",
                                "",
                                {},
                                intl,
                              )
                            : CheckValueLocale("CXM", "", {}, intl)}
                        </Typography>
                      </Box>
                    </Box>
                  </BootstrapTooltip>
                </SwiperSlide>
              );
            })}
            {newsBblogsLimit()}
            {cxmLimitError()}
          </Swiper>
        ) : (
          <Box className="no-data-needs-attention">
            <FontAwesomeIcon icon={faCheckSquare} />
            {CheckValueLocale("everythings_perfect", "", {}, intl)}
          </Box>
        )}
      </Box>
      <SnackBar
        className="home-page-snackbar"
        open={snackBar?.open}
        severity={snackBar?.severity}
        message={CheckValueLocale(snackBar?.message, "", {}, intl)}
        title={CheckValueLocale(snackBar?.title, "", {}, intl)}
      />
    </Box>
  );
};

export default NeedsAttentionSection;
