import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import YesNo from "../ConstraintsSection/constraints/yesNo";
import Email from "../ConstraintsSection/constraints/email";
import Name from "../ConstraintsSection/constraints/name";
import PhoneNo from "../ConstraintsSection/constraints/phoneNo";
import FreeAnswer from "../ConstraintsSection/constraints/freeAnswer";
import Number from "../ConstraintsSection/constraints/number";
import Mcq from "../ConstraintsSection/constraints/mcq";
import SocialMedia from "../ConstraintsSection/constraints/socialMedia";
import Dropdown from "../ConstraintsSection/constraints/dropDown";
import ConstraintsHeader from "./constraintsHeader";
import Nps from "./constraints/NPS/nps";
import LinearScale from "./constraints/scale/LinearScale";

const ConstraintsSection = (props) => {
  const {
    filteredSections,
    surveySections,
    setSurveySections,
    sqIndex,
    ssIndex,
    selectedCountryCode,
    setSelectedCountryCode,
  } = props;

  const [hasProceedSec, setHasProceedSec] = useState(null);
  const [hasProceedQues, setHasProceedQues] = useState(null);

  useEffect(() => {
    if (filteredSections) {
      let hasProceedSet = false;
      filteredSections?.[ssIndex - 1]?.questions
        ?.filter((question) => question?.is_deleted !== true)
        ?.forEach((question, qIndex) => {
          if (question?.is_proceed_on_answer === true) {
            setHasProceedSec(ssIndex - 1);
            setHasProceedQues(qIndex);
            hasProceedSet = true;
            return;
          }
        });
      if (!hasProceedSet) {
        setHasProceedSec(null);
        setHasProceedQues(null);
      }
    }
  }, [filteredSections, ssIndex]);

  return (
    <Box className="constraints-section-container">
      <ConstraintsHeader
        filteredSections={filteredSections}
        surveySections={surveySections}
        setSurveySections={setSurveySections}
        ssIndex={ssIndex}
        sqIndex={sqIndex}
      />

      {filteredSections?.map((section, sIndex) => {
        if (ssIndex === sIndex + 1)
          return (
            <Box className="parent-settings" key={sIndex}>
              {section?.questions
                ?.filter((question) => question?.is_deleted !== true)
                ?.map((question, qIndex) => {
                  if (sqIndex === qIndex + 1) {
                    if (question?.question?.type === "dropdown")
                      return (
                        <Dropdown
                          key={qIndex}
                          rootQuestion={question}
                          question={question?.question}
                          filteredSections={filteredSections}
                          surveySections={surveySections}
                          setSurveySections={setSurveySections}
                          sectionIndex={sIndex}
                          questionIndex={qIndex}
                          hasProceedSec={hasProceedSec}
                          hasProceedQues={hasProceedQues}
                        />
                      );
                    else if (question?.question?.type === "yes_no")
                      return (
                        <YesNo
                          key={qIndex}
                          rootQuestion={question}
                          question={question?.question}
                          filteredSections={filteredSections}
                          surveySections={surveySections}
                          setSurveySections={setSurveySections}
                          sectionIndex={sIndex}
                          questionIndex={qIndex}
                          hasProceedSec={hasProceedSec}
                          hasProceedQues={hasProceedQues}
                        />
                      );
                    else if (question?.question?.type === "email")
                      return (
                        <Email
                          key={qIndex}
                          question={question?.question}
                          filteredSections={filteredSections}
                          surveySections={surveySections}
                          setSurveySections={setSurveySections}
                          sectionIndex={sIndex}
                          questionIndex={qIndex}
                        />
                      );
                    else if (question?.question?.type === "name")
                      return (
                        <Name
                          key={qIndex}
                          question={question?.question}
                          filteredSections={filteredSections}
                          surveySections={surveySections}
                          setSurveySections={setSurveySections}
                          sectionIndex={sIndex}
                          questionIndex={qIndex}
                        />
                      );
                    else if (question?.question?.type === "social_media")
                      return (
                        <SocialMedia
                          key={qIndex}
                          question={question?.question}
                          filteredSections={filteredSections}
                          surveySections={surveySections}
                          setSurveySections={setSurveySections}
                          sectionIndex={sIndex}
                          questionIndex={qIndex}
                        />
                      );
                    else if (question?.question?.type === "multiple_choice")
                      return (
                        <Mcq
                          key={qIndex}
                          rootQuestion={question}
                          question={question?.question}
                          filteredSections={filteredSections}
                          surveySections={surveySections}
                          setSurveySections={setSurveySections}
                          sectionIndex={sIndex}
                          questionIndex={qIndex}
                          hasProceedSec={hasProceedSec}
                          hasProceedQues={hasProceedQues}
                        />
                      );
                    else if (question?.question?.type === "short_text")
                      return (
                        <FreeAnswer
                          key={qIndex}
                          question={question?.question}
                          filteredSections={filteredSections}
                          surveySections={surveySections}
                          setSurveySections={setSurveySections}
                          sectionIndex={sIndex}
                          questionIndex={qIndex}
                        />
                      );
                    else if (question?.question?.type === "number")
                      return (
                        <Number
                          key={qIndex}
                          question={question?.question}
                          filteredSections={filteredSections}
                          surveySections={surveySections}
                          setSurveySections={setSurveySections}
                          sectionIndex={sIndex}
                          questionIndex={qIndex}
                        />
                      );
                    else if (question?.question?.type === "phone_number")
                      return (
                        <PhoneNo
                          key={qIndex}
                          question={question?.question}
                          filteredSections={filteredSections}
                          surveySections={surveySections}
                          setSurveySections={setSurveySections}
                          sectionIndex={sIndex}
                          questionIndex={qIndex}
                          selectedCountryCode={selectedCountryCode}
                          setSelectedCountryCode={setSelectedCountryCode}
                        />
                      );
                    else if (question?.question?.type === "nps")
                      return (
                        <Nps
                          key={qIndex}
                          question={question?.question}
                          filteredSections={filteredSections}
                          surveySections={surveySections}
                          setSurveySections={setSurveySections}
                          sectionIndex={sIndex}
                          questionIndex={qIndex}
                          rootQuestion={question}
                          hasProceedSec={hasProceedSec}
                          hasProceedQues={hasProceedQues}
                        />
                      );
                    else if (question?.question?.type === "scale")
                      return (
                        <LinearScale
                          key={qIndex}
                          question={question?.question}
                          filteredSections={filteredSections}
                          surveySections={surveySections}
                          setSurveySections={setSurveySections}
                          sectionIndex={sIndex}
                          questionIndex={qIndex}
                          rootQuestion={question}
                          hasProceedSec={hasProceedSec}
                          hasProceedQues={hasProceedQues}
                        />
                      );
                    else return;
                  }
                })}
            </Box>
          );
      })}
    </Box>
  );
};

export default ConstraintsSection;
