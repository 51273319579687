import {
  Box,
  Grid,
  Tooltip,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  FormControl,
} from "@mui/material";
import { useEffect, useState } from "react";
import { CheckValueLocale, isEmptyObj } from "utils/helpers";
import { useIntl } from "react-intl";
import customDashboardController from "services/controllers/customDashboardController";
import SnackBar from "components/snackBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import LucButton from "shared/lucButton/lucButton";
import MonitorCard from "./MonitorCard";
import { Link } from "react-router-dom";
import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";

function CustomDashboardMonitors(props) {
  const {
    isEditDashboard,
    dashboardData,
    selectedOptions,
    setSelectedOptions,
    setIsCustomDashboard,
    loading,
    setLoading,
    selectedProduct,
    setSelectedProduct,
    slProduct,
    cxmProduct,
    snackBar,
    setSnackBar,
    isSMEnabled,
    isCxmEnabled,
  } = props;

  const intl = useIntl();
  const [page, setPage] = useState(1);
  const [monitorData, setMonitorData] = useState([]);
  const [accountData, setAccountData] = useState([]);
  const [maxMonitors, setMaxMonitors] = useState(0);
  const [pagination, setPagination] = useState({});

  let smId = localStorage?.getItem("sm_id");
  let cxmId = localStorage?.getItem("cxm_id");

  const getCustomDashboardMonitors = (page, showMore) => {
    setLoading(true);
    setIsCustomDashboard(true);
    customDashboardController
      .getCustomDashboardMonitors(smId, page)
      .then((result) => {
        if (!result?.errorMsg) {
          setPagination(result?.data?.pagination);
          setMaxMonitors(result?.data?.max_monitors);
          if (showMore)
            setMonitorData((prevData) => [...prevData, ...result?.data?.data]);
          else setMonitorData(result?.data?.data);
        }
        setLoading(false);
      });
  };

  const getCustomDashboardAccounts = () => {
    setLoading(true);
    customDashboardController
      .getCustomDashboardAccounts(cxmId)
      .then((result) => {
        if (!result?.errorMsg) {
          setAccountData(result?.data);
          setMaxMonitors(result?.data?.max_monitors);
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!isEditDashboard) {
      setSelectedOptions([]);
      if (selectedProduct === slProduct)
        getCustomDashboardMonitors(1); // 1 equals page number
      else if (selectedProduct === cxmProduct) getCustomDashboardAccounts();
    }
  }, [selectedProduct]);

  const handleChangeProduct = (event) => {
    setSelectedProduct(event.target.value);
    setPage(1);
  };
  const handleCloseSnackBar = () => {
    setSnackBar({ open: false });
  };

  let urls = {
    slLink: "/social/monitors/create_monitor",
    cxmLink: "/manage-channels/add-channel",
  };

  const handleShowMonitors = () => {
    setPage(page + 1);
    getCustomDashboardMonitors(page + 1, true);
  };

  const checkGroupedMonitorsLength = () => {
    if (accountData?.grouped_monitors)
      if (Object.keys(accountData?.grouped_monitors)?.length) {
        return Object.values(accountData?.grouped_monitors)?.some(
          (val) => val?.length > 0,
        );
      } else return false;
    else return false;
  };

  return (
    <>
      <Grid
        item
        xs={12}
        className={`social-media-field-grid ${
          isEditDashboard && "monitors-types-dashboard-edit"
        }`}
      >
        <Typography
          variant="caption"
          sx={{ display: "inline-block" }}
          className={`dashboard-title  ${
            isEditDashboard && "dashboard-title-type-edit"
          }`}
        >
          {`3. ${CheckValueLocale("dashboards_select_product", "", {}, intl)}`}
        </Typography>
      </Grid>

      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-form-control-label-placement"
          name="position"
          defaultValue="top"
          value={selectedProduct}
          className="select-product-radio"
          onChange={handleChangeProduct}
        >
          {isSMEnabled ? (
            <FormControlLabel
              value={slProduct}
              control={<Radio />}
              label={CheckValueLocale("social_listening_v2", "", {}, intl)}
            />
          ) : null}
          {isCxmEnabled ? (
            <FormControlLabel
              value={cxmProduct}
              control={<Radio />}
              label={CheckValueLocale("CXM", "", {}, intl)}
            />
          ) : null}
        </RadioGroup>
      </FormControl>

      <Divider id="dashboard-divider-info" sx={{ bgcolor: "#EAEDF1" }} />

      <Grid item xs={12} className="social-media-field-grid">
        <Typography
          variant="caption"
          sx={{ display: "inline-block" }}
          className="dashboard-sub-title-note dashboard-title"
        >
          {`4. ${CheckValueLocale(
            isEditDashboard
              ? "monitors"
              : selectedProduct == slProduct
                ? "select_monitors"
                : "dashboards_select_accounts",
            "",
            {},
            intl,
          )}:`}
          <Box className="tooltip-custom-dash">
            <Tooltip
              placement="top"
              arrow
              title={CheckValueLocale(
                selectedProduct == slProduct
                  ? "dashboards_monitors_tooltip"
                  : "dashboards_accounts_tooltip",
                "",
                {},
                intl,
              )}
            >
              <Typography component={"h4"}>
                <FontAwesomeIcon icon={faInfoCircle} />
              </Typography>
            </Tooltip>
          </Box>
        </Typography>
      </Grid>

      {!loading ? (
        <Grid item xs={12} className="social-media-field-grid">
          <Box
            className={`dashboard-sub-title-container ${
              (selectedProduct == slProduct && !monitorData?.length) ||
              (selectedProduct == cxmProduct && !checkGroupedMonitorsLength())
                ? " no-dashboard-data"
                : ""
            }`}
          >
            <Typography
              variant="caption"
              sx={{ display: "inline-block" }}
              className="dashboard-sub-title"
            >
              {selectedProduct === slProduct
                ? CheckValueLocale(
                    monitorData?.length
                      ? "you_can_select_monitors_msg"
                      : "you_dont_have_any_monitors_msg",
                    "",
                    {
                      max: maxMonitors || 0,
                      selected: selectedOptions?.length || 0,
                    },
                    intl,
                  )
                : CheckValueLocale(
                    checkGroupedMonitorsLength()
                      ? "you_can_select_accounts_msg"
                      : "you_dont_have_any_accounts_msg",
                    "",
                    {
                      max: maxMonitors || 0,
                      selected: selectedOptions?.length || 0,
                    },
                    intl,
                  )}
            </Typography>

            <Link
              target="_blank"
              to={urls[selectedProduct == slProduct ? "slLink" : "cxmLink"]}
              className=""
            >
              <LucButton
                variant="outline"
                startIcon={<FontAwesomeIcon icon={faExternalLink} />}
              >
                {CheckValueLocale(
                  `dashboard_new_${selectedProduct == slProduct ? "monitor" : "channel"}`,
                  "",
                  {},
                  intl,
                )}
              </LucButton>
            </Link>
          </Box>
        </Grid>
      ) : null}

      <MonitorCard
        selectedProduct={selectedProduct}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        setSnackBar={setSnackBar}
        monitorData={monitorData}
        isCXM
        loading={loading}
        dashboardData={dashboardData}
        accountData={accountData}
        maxMonitors={maxMonitors}
      />
      {selectedProduct == slProduct && pagination?.next ? (
        <Box className="btn-show-more-container">
          <LucButton
            variant="filled"
            id="dashboard-show-more-monitors-btn"
            onClick={handleShowMonitors}
          >
            {`${CheckValueLocale("dashboard_show_more", "", {}, intl)} (+${
              pagination?.items
            })`}
          </LucButton>
        </Box>
      ) : null}
      <Box className="dashboard-snackbar-box">
        <SnackBar
          open={snackBar?.open}
          severity={snackBar?.severity}
          title={CheckValueLocale(
            snackBar?.title,
            "",
            { maxMonitors: snackBar?.maxMonitors },
            intl,
          )}
          message={CheckValueLocale(snackBar?.message, "", {}, intl)}
          handleClose={handleCloseSnackBar}
        />
      </Box>
    </>
  );
}

export default CustomDashboardMonitors;
