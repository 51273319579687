import { useQuery } from "@tanstack/react-query";
import UserAccount from "services/controllers/userAccountController";

export const useGetUserTwoFactorAuth = () => {
  return useQuery({
    queryKey: ["userTwoFactorAuth"],
    queryFn: () => UserAccount?.getUser2FA(),
    select: (data) => data?.data,
    retry: false,
  });
};
