import { useIntl } from "react-intl";
import WidgetHeader from "../widgetHeader";
import { Box } from "@mui/system";
import FetchingData from "components/fetchingData/fetchingData";
import _ from "lodash";
import NoDataFound from "components/no-Data/noDataFound";
import ChartStatsWidget from "../customStatisctics/chartStatsWidget";
import { Alert } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";

const StatisticsWidget = ({
  alertMessagedynamicValues,
  alertType,
  alertMessage,
  ...props
}) => {
  const intl = useIntl();

  return (
    <div className="chart agent-performance" id={props.chartId}>
      <WidgetHeader
        title={props.title}
        showDownloadIcon
        iconAfterTitle={props.iconAfterTitle}
        titleToolTip={props.titleToolTip}
        chartId={props.chartId}
        bigImg={props.bigImg}
        generalGroupByOptions={props?.groupByOptions}
        showGeneralGroupBy={props?.groupByOptions?.length}
        selectedGeneralGroupBy={props?.selectedGroupBy}
        handleGeneralGroupByChange={props?.onGroupByChange}
        noGroubByLabel={props?.noGroubByLabel}
        showReportIcon={props?.showReportIcon}
      />
      {_.isEqual(
        props?.statisticsPreLoaderDataSources?.sort(),
        props?.preLoaderTrackerDataSources?.sort(),
      ) !== true ||
      !props?.statisticsPreLoaderDataSources?.length ||
      !props?.preLoaderTrackerDataSources?.length ? (
        <Box>
          <FetchingData />
        </Box>
      ) : (
        <>
          {alertMessage ? (
            <Alert severity={alertType} id="statistics-widget-table-alert">
              {CheckValueLocale(
                alertMessage,
                "",
                alertMessagedynamicValues,
                intl,
              )}
            </Alert>
          ) : null}
          <ChartStatsWidget
            title={props.title}
            statsData={props.statsData}
            toolTipPosition={props?.toolTipPosition}
            tooltipID={props?.tooltipID}
            containerClassName={props?.containerClassName}
            isFourAtRow={props?.isFourAtRow}
          />
        </>
      )}
    </div>
  );
};
export default StatisticsWidget;
