import { Box } from "@mui/material";
import moment, { utc } from "moment";
import { useIntl } from "react-intl";
import {
  calculateUnixTimeZone,
  CheckValueLocale,
  formatTimeZone,
} from "utils/helpers";
import "../../../style.scss";

const DateBodyCell = ({ user }) => {
  const intl = useIntl();
  const lastSeen = user?.attributes?.last_seen;
  const companyTimeZone = parseInt(localStorage.getItem("companyInfo"));
  const dataInUnix = moment(lastSeen).unix();
  const calculatedTime = calculateUnixTimeZone(dataInUnix, {});
  const { completeFormat } = formatTimeZone(
    calculatedTime,
    intl,
    {},
    companyTimeZone,
  );
  const [datePart, timeWithGMT] = completeFormat.split(", ");
  return (
    <Box>
      {user?.attributes?.last_seen == null ? (
        CheckValueLocale("never_seen", "", {}, intl)
      ) : (
        <Box>
          <Box>{datePart}</Box>
          <span className="time-with-gmt-table">{timeWithGMT}</span>
        </Box>
      )}
    </Box>
  );
};

export default DateBodyCell;
