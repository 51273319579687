import { createContext, useContext, useRef, useState } from "react";
import { getEngagementId } from "utils/helpers";

export const EngagementTabsContext = createContext();

// obj to store all possible actions
export const engagementTabsActions = {
  add_tab: "add_tab", // append a new tab to the beginning of the list
  replace_current_tab_with: "replace_current_tab_with", // replace the current tab with a new tab
  remove_tab: "remove_tab", // remove a tab from the list
  update_current_tab: "update_current_tab", // update the current tab with new data and merge with existing data
  switch_two_tabs: "switch_two_tabs", // switch two tabs positions
  move_tab: "move_tab", // moves an existing tab to the beginning and pushes the rest to the right
};

export const engagementStatusMapper = {
  reopen: "active",
  close: "complete",
  complete: "complete",
  all: "all",
  active: "active",
};

// max number of tabs
export const engagementTabsLimit = 10;

const EngagementTabsProvider = ({ children }) => {
  const [engagementTabs, setEngagementTabs] = useState([]);
  const [activeEngagement, setActiveEngagement] = useState({});
  const [cardState, setCardState] = useState({});
  const hiddenTabsIds = useRef([]); // a list of hidden tabs that are not visible, only visible in the menu

  // Handle Add Tab
  const handleAddTab = (engagement = {}, info = {}, action) => {
    const engId = getEngagementId(engagement);
    // check if the tab already exists
    const existedEngagement = engagementTabs?.find?.(
      (tab) => tab?.engId === engId,
    );

    // make a new engagement object with the new data
    const cardIndex =
      info?.index || engagement?.cardIndex || existedEngagement?.cardIndex || 0; // get the card index
    const cardInitialState =
      info?.status ||
      engagement?.cardInitialState ||
      existedEngagement?.cardInitialState; // get the card status

    const newEngagement = {
      ...engagement,
      engId,
      sidebarTab: info?.sidebarTab,
      currentActiveTab: info?.currentActiveTab,
      cardIndex,
      cardInitialState,
    };
    const newCardState = {
      ...cardState,
      cardIndex,
      cardInitialState,
      cardData: { ...newEngagement },
      changeStateDone: false,
      opendFromCard: !!info?.opendFromCard, // check if the tab is opened from a card, a tab can be opened from a card list or from the engagement tabs
    };

    // check if tab is already opened, just set it as active
    if (existedEngagement) {
      setActiveEngagement(existedEngagement);
      newCardState.cardData = { ...existedEngagement };
      setCardState(newCardState);
      // check if the tab is hidden, remove it and add it to the beginning
      if (hiddenTabsIds.current?.includes?.(engId)) {
        // when the engagementTabs is set, the hiddenTabsIds will be updated dynamically with the new hidden tabs
        setEngagementTabs((prevTabs = []) => [
          existedEngagement,
          ...prevTabs?.filter?.((tab) => tab?.engId !== engId),
        ]);
      }
      return;
    }

    // check if it's a replacement
    if (action === engagementTabsActions.replace_current_tab_with) {
      const newEngagements = engagementTabs?.map?.((tab) => {
        if (tab?.engId === activeEngagement?.engId) {
          return newEngagement;
        }
        return tab;
      });

      setEngagementTabs([...newEngagements]);
      setActiveEngagement(newEngagement);
      setCardState(newCardState);
      if (engagementTabs?.length === 0 || !activeEngagement?.engId) {
        setEngagementTabs((prevTabs = []) => [newEngagement, ...prevTabs]);
      }
      return;
    }

    // check if tabs limit is reached
    if (engagementTabs.length < engagementTabsLimit) {
      setEngagementTabs((prevTabs) => [newEngagement, ...prevTabs]);
      setActiveEngagement(newEngagement);
      setCardState(newCardState);
    }
  };

  // handle remove tab
  const handleRemoveTab = (engId) => {
    // first check if the tab to be removed is the active tab
    // if it is, set the active tab to the right, if there is no right, set it to the left
    if (activeEngagement?.engId === engId) {
      const activeTabIndex = engagementTabs.findIndex(
        (tab) => tab?.engId === engId,
      );
      const newActiveEngagement =
        engagementTabs[activeTabIndex + 1] ||
        engagementTabs[activeTabIndex - 1];

      setActiveEngagement(newActiveEngagement || {});
      setCardState(
        newActiveEngagement?.engId
          ? {
              ...cardState,
              cardIndex: newActiveEngagement?.cardIndex,
              cardInitialState: newActiveEngagement?.cardInitialState,
              cardData: { ...newActiveEngagement },
              changeStateDone: false,
            }
          : {
              activeTab: cardState?.activeTab,
            },
      );
    }
    // remove the tab
    setEngagementTabs((prevTabs) =>
      prevTabs?.filter?.((tab) => tab?.engId !== engId),
    );
  };

  // handle update tab
  const handleUpdateTab = (engId, data = {}) => {
    setEngagementTabs((prevTabs) =>
      prevTabs?.map?.((tab) => {
        if (tab?.engId === engId) {
          return { ...tab, ...data };
        }
        return tab;
      }),
    );
  };

  // handle tab move
  const handleMoveTab = (payload) => {
    const { srcIdx, destIdx, activateTab } = payload;
    const newEngagementTabs = [...engagementTabs];
    const [removed] = newEngagementTabs?.splice?.(srcIdx, 1);
    if (!removed) return;
    newEngagementTabs?.splice?.(destIdx, 0, removed);
    setEngagementTabs(newEngagementTabs);
    if (activateTab) {
      setActiveEngagement(newEngagementTabs[destIdx]);
      setCardState({
        ...cardState,
        cardIndex: newEngagementTabs[destIdx]?.cardIndex,
        cardInitialState: newEngagementTabs[destIdx]?.cardInitialState,
        cardData: { ...newEngagementTabs[destIdx] },
        changeStateDone: false,
        opendFromCard: false,
      });
    }
  };

  // handle tab switch
  const handleSwitchTwoTabs = (payload) => {
    const { srcIdx, destIdx } = payload;
    // implement later
  };

  const handleEngagementTabs = (action, payload = {}) => {
    switch (action) {
      case engagementTabsActions.add_tab:
      case engagementTabsActions.replace_current_tab_with:
        handleAddTab(payload?.engagement, payload?.info, action);
        break;
      case engagementTabsActions.remove_tab:
      case engagementTabsActions.remove_tab:
        handleRemoveTab(payload?.id);
        break;
      case engagementTabsActions.update_current_tab:
        handleUpdateTab(activeEngagement?.engId, payload);
        break;
      case engagementTabsActions.move_tab:
        handleMoveTab(payload);
        break;
      case engagementTabsActions.switch_two_tabs:
        handleSwitchTwoTabs(payload);
        break;
      default:
        return;
    }
  };

  return (
    <EngagementTabsContext.Provider
      value={{
        engagementTabs,
        setEngagementTabs,
        activeEngagement,
        setActiveEngagement,
        handleEngagementTabs,
        cardState,
        setCardState,
        hiddenTabsIds,
      }}
    >
      {children}
    </EngagementTabsContext.Provider>
  );
};

export const useEngagementTabs = () => {
  const context = useContext(EngagementTabsContext);

  if (context === undefined) {
    throw new Error(
      "useEngagementTabs must be used within an EngagementTabsProvider",
    );
  }

  return context;
};

export default EngagementTabsProvider;
