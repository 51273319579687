import { useQuery } from "@tanstack/react-query";
import SurveyController from "services/controllers/surveyController";

export const useSurveyLists = ({ page, name_search, types_ids, statuses }) => {
  return useQuery({
    queryKey: ["surveysList", page, name_search, types_ids, statuses],
    queryFn: () =>
      SurveyController.getSurveysList({
        page,
        name_search,
        types_ids,
        statuses,
      }),
    enabled: !!page,
    select: (data) => data?.data,
    retry: false,
  });
};
