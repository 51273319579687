// src/components/pickers/YearPicker.jsx
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronsLeft,
  faChevronsRight,
} from "@fortawesome/pro-regular-svg-icons";
import { Box, Button, Typography } from "@mui/material";

function YearPicker({
  yearRange,
  handleYearRangeChange,
  onMonthClick,
  onYearSelect,
  // Add the new prop for maxDate
  maxDate,
  minDate,
}) {
  // Convert maxDate to a moment if provided
  const maxM = maxDate ? moment(maxDate) : null;
  const minM = minDate ? moment(minDate) : null;

  // Decide if a year is beyond maxDate
  function isYearDisabled(year) {
    // If this year is strictly greater than maxDate's year, disable
    if (maxM && year > maxM?.year()) {
      return true;
    }

    if (minM && year < minM.year()) {
      return true;
    }

    return false;
  }

  return (
    <Box className="year-picker">
      <Box className="year-picker-header">
        <Box
          className="nav-button"
          onClick={() => handleYearRangeChange("previous")}
          id="year-picker-previous-year-button"
        >
          <FontAwesomeIcon icon={faChevronsLeft} />
        </Box>
        <Typography component="span" className="picker-header">
          {yearRange?.[0]} - {yearRange?.[1]}
        </Typography>
        <Box
          className="nav-button"
          onClick={() => handleYearRangeChange("next")}
          id="year-picker-next-year-button"
        >
          <FontAwesomeIcon icon={faChevronsRight} />
        </Box>
      </Box>

      <Box className="year-picker-grid">
        {Array.from({ length: yearRange?.[1] - yearRange?.[0] + 1 })?.map(
          (_, idx) => {
            const year = yearRange?.[0] + idx;
            const disabled = isYearDisabled(year);

            return (
              <Box className={`picker-button ${disabled ? "disabled" : ""}`}>
                <Typography
                  component="span"
                  key={year}
                  onClick={() => {
                    if (!disabled) {
                      onYearSelect(year);
                      onMonthClick && onMonthClick();
                    }
                  }}
                  disabled={disabled}
                >
                  {year}
                </Typography>
              </Box>
            );
          },
        )}
      </Box>
    </Box>
  );
}

export default YearPicker;
