import { useContext } from "react";
import { Box, Step, StepLabel, Stepper } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { CreateReportContext } from "pages/reports/context/reportContext";

const CreateReportStepperHeader = () => {
  const { step } = useContext(CreateReportContext);

  const intl = useIntl();

  return (
    <Box className="create-report-stepper-header">
      <Stepper activeStep={step}>
        {["report_information", "report_setting", "report_delivery"]?.map(
          (label) => (
            <Step key={label}>
              <StepLabel className="create-report-stepper-header-item">
                {CheckValueLocale(label, "", {}, intl)}
              </StepLabel>
            </Step>
          ),
        )}
      </Stepper>
    </Box>
  );
};

export default CreateReportStepperHeader;
