import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Box,
  CircularProgress,
  Alert,
} from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale, classNames } from "utils/helpers";

export const BigTable = ({
  tableHeaders = [],
  tableData = [],
  count,
  stickyColumn = false,
  isLoading = false,
  disabled = false,
  handleOnPageChange,
  paginationData,
  alertMessage,
  alertType,
  alertMessagedynamicValues,
}) => {
  const intl = useIntl();
  const handleChangePage = (event, newPage) => {
    handleOnPageChange && handleOnPageChange(newPage);
  };

  return (
    <Box className="big-table-container-wrapper">
      {alertMessage ? (
        <Alert
          severity={alertType ? alertType : "info"}
          id="big-table-alert"
          className={`${alertType ? `big-table-${alertType}-alert` : "big-table-alert"}`}
        >
          {CheckValueLocale(alertMessage, "", alertMessagedynamicValues, intl)}
        </Alert>
      ) : null}

      <TableContainer component={Paper} className="big-table-container">
        <Table aria-label="simple table" className="big-table">
          <TableHead className="big-table-header">
            <TableRow className="big-table-row big-table-row-header">
              {tableHeaders?.map?.((header, index) => (
                <TableCell
                  key={index}
                  className={classNames(
                    "big-table-cell big-table-cell-header",
                    stickyColumn && index === 0 ? "sticky-cell" : "",
                  )}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="big-table-body">
            {tableData?.map?.((row, index) => (
              <TableRow
                key={index}
                className={classNames(
                  "big-table-row big-table-row-body",
                  index === tableData.length - 1 ? "last-row" : "",
                )}
              >
                {row?.map((cell, index) => (
                  <TableCell
                    key={index}
                    className={classNames(
                      "big-table-cell big-table-cell-body",
                      stickyColumn && index === 0 ? "sticky-cell" : "",
                    )}
                  >
                    {cell}
                  </TableCell>
                ))}
              </TableRow>
            ))}
            {isLoading ? (
              <Box className="big-table-loader-circular">
                <CircularProgress id="circular-progress-component" />
              </Box>
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
      <Box class="big-table-pagination">
        <TablePagination
          count={count}
          page={paginationData?.page}
          onPageChange={handleChangePage}
          rowsPerPage={paginationData?.rowsPerPage}
          rowsPerPageOptions={false}
          disabled={disabled || isLoading}
        />
      </Box>
    </Box>
  );
};
