import {
  Box,
  Button,
  Checkbox,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale, newProductNameMap } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleX } from "@fortawesome/pro-solid-svg-icons";
import "./style.scss";

const ReportSelectInput = ({
  id,
  className,
  label,
  labelClassName,
  name,
  options = [],
  value,
  loading = false,
  disabled,
  onChange = () => {},
  onBlur = () => {},
  error = false,
  helperText,
  multiple,
  disableTranslation = false,
  renderValue,
  isEdit,
  showChips,
}) => {
  const intl = useIntl();

  return (
    <Box className="report-input-wrapper">
      <InputLabel
        className={`report-input-label ${labelClassName || ""}`}
        disabled={disabled && isEdit}
      >
        {CheckValueLocale(label, "", {}, intl)}
      </InputLabel>
      <Select
        className={`${isEdit ? "edit-report-select-input" : "create-report-select-input"} ${className || ""}`}
        variant="outlined"
        size="small"
        fullWidth
        id={id || ""}
        name={name || ""}
        value={value || ""}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled || false}
        error={error || false}
        renderValue={(value) => {
          if (renderValue) return renderValue();
          else if (showChips) return CheckValueLocale("select", "", {}, intl);
          else if (multiple) {
            if (!value?.length) return CheckValueLocale("select", "", {}, intl);
            else
              return value
                ?.map((val) => {
                  const selectedLabel = options?.find(
                    (option) => option?.value === val,
                  )?.label;
                  return disableTranslation
                    ? selectedLabel
                    : CheckValueLocale(selectedLabel, "", {}, intl);
                })
                ?.join(", ");
          } else {
            const selectedLabel = options?.find(
              (option) => option?.value === value,
            )?.label;
            return CheckValueLocale(
              newProductNameMap[selectedLabel] || "select",
              "",
              {},
              intl,
            );
          }
        }}
        displayEmpty
        multiple={multiple || false}
        MenuProps={{
          PaperProps: { style: { maxHeight: 160, maxWidth: 100 } },
          getContentAnchorEl: null,
          anchorOrigin: { vertical: "bottom" },
          transformOrigin: { vertical: "top" },
        }}
      >
        {loading ? (
          <MenuItem id="create-report-select-input-menu-item" disabled>
            {CheckValueLocale("dropdown_loading", "", {}, intl)}
          </MenuItem>
        ) : options?.length >= 1 ? (
          options?.map((option) => (
            <MenuItem
              key={option?.value}
              id="create-report-select-input-menu-item"
              className={
                multiple
                  ? "create-report-multiple-select-input-menu-item"
                  : "create-report-select-input-menu-item"
              }
              value={option?.value}
            >
              {multiple ? (
                <Checkbox
                  checked={
                    Array?.isArray(value)
                      ? value?.includes(option?.value)
                      : false
                  }
                />
              ) : null}
              {disableTranslation
                ? option?.label
                : CheckValueLocale(
                    newProductNameMap[option?.label] || option?.label,
                    "",
                    {},
                    intl,
                  )}
            </MenuItem>
          ))
        ) : (
          <MenuItem id="create-report-select-input-menu-item" disabled>
            {CheckValueLocale(
              "searchable_multi_select_filter_no_options",
              "",
              {},
              intl,
            )}
            ...
          </MenuItem>
        )}
      </Select>
      <FormHelperText error>
        {CheckValueLocale(helperText || "", "", {}, intl)}
      </FormHelperText>
      {showChips && value?.length > 0 ? (
        <Box className="report-select-input-chips-container">
          {value?.map((item) => {
            const itemLabel = options?.find(
              (option) => option?.value === item,
            )?.label;
            return (
              <Button
                key={item}
                id="report-select-input-chip"
                variant="outlined"
                size="small"
                endIcon={
                  <FontAwesomeIcon
                    icon={faCircleX}
                    fixedWidth
                    onClick={() =>
                      onChange({
                        target: { value: value?.filter((val) => val !== item) },
                      })
                    }
                  />
                }
              >
                <Typography>
                  {disableTranslation
                    ? itemLabel
                    : CheckValueLocale(itemLabel, "", {}, intl)}
                </Typography>
              </Button>
            );
          })}
        </Box>
      ) : null}
    </Box>
  );
};

export default ReportSelectInput;
