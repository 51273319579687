import { useQuery } from "@tanstack/react-query";
import UserAccount from "services/controllers/userAccountController";

export const useGetuseInfo = () => {
  return useQuery({
    queryKey: ["userInfo"],
    queryFn: () => UserAccount?.getUserInfoProfile(),
    select: (data) => data?.data?.data?.attributes,
    retry: false,
  });
};
