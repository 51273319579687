import { useState } from "react";
import { useIntl } from "react-intl";
import { Button, Stack, Box, Grid, Tooltip, InputLabel } from "@mui/material";
import { CheckValueLocale, maskEmail } from "utils/helpers";

import ImageModal from "./imageEditorComponents/imageModal";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import "react-intl-tel-input/dist/main.css";
import CircularLoading from "components/circularProgress";

// Controllers
import UserAvatar from "shared/unifiedUserImage/userAvatar";
import HeaderSectionProfile from "./shared/headerSectionProfile";
import InputLabelFormik from "pages/settings/pages/userManagementPage/subPages/shardComponents/userForm/components/inputLabelFormik/inputLabelFormik";
import UserPhoneNumber from "./userPhoneNumber/userPhoneNumber";
import CustomizedTooltip, {
  customizedStyledTooltip,
} from "components/customizedTooltip";

const UserInfo = ({ formik, tempUserInfo, isLoading }) => {
  const intl = useIntl();

  const [openModal, setModal] = useState(false);

  //Handle Edit Image
  const setImageUpdate = async (newImage) => {
    formik?.setFieldValue("avatar", newImage);
    handleModal();
  };

  //Handle Modal
  const handleModal = () => {
    if (openModal) {
      setModal(false);
    } else {
      setModal(true);
    }
  };

  const handelErrorMsg = (nameField) => {
    return formik?.touched?.[nameField] ? formik?.errors?.[nameField] : "";
  };
  return (
    <>
      {isLoading ? (
        <Box className="user-info-loader">
          <CircularLoading />
        </Box>
      ) : (
        <Box className="user-info-section">
          <HeaderSectionProfile titleHeader="user_info" />

          <Grid container spacing={2}>
            {/* <Grid container spacing={2}> */}
            {/* Photo Part */}
            <Grid item align="center" lg={3} md={4} xs={12}>
              <Stack direction="row" justifyContent="center">
                <UserAvatar
                  fullName={`${formik?.values?.first_name || ""} ${formik?.values?.last_name || ""}`}
                  avatarSrc={
                    formik?.values?.avatar instanceof File
                      ? URL.createObjectURL(formik?.values?.avatar)
                      : formik?.values?.avatar
                  }
                  sizes={{
                    ratio: "200px",
                    fontSize: "52px",
                  }}
                />
              </Stack>
              <Box id="button">
                <Tooltip title={CheckValueLocale("edit_photo", "", {}, intl)}>
                  <Button
                    onClick={() => {
                      handleModal();
                    }}
                    id="profile-user-info-edit-photo-btn"
                  >
                    <CameraAltIcon className="cameraIcon" />
                  </Button>
                </Tooltip>
              </Box>
            </Grid>

            <Grid item lg={9} md={8} xs={12} alignContent="center">
              <Stack direction="row" justifyContent="center">
                <Grid container spacing={2}>
                  {/* First Name */}
                  <Grid item lg={4} sm={6} xs={12}>
                    <InputLabelFormik
                      value={formik?.values?.first_name}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      labelId={"first_name"}
                      name={"first_name"}
                      placeHolderId={"first_name_placeholder"}
                      errorMsg={handelErrorMsg("first_name")}
                      required
                    />
                  </Grid>

                  {/* Last Name */}
                  <Grid item lg={4} sm={6} xs={12}>
                    <InputLabelFormik
                      value={formik?.values?.last_name}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      labelId={"last_name"}
                      name={"last_name"}
                      placeHolderId={"last_name_placeholder"}
                      errorMsg={handelErrorMsg("last_name")}
                      required
                    />
                  </Grid>

                  {/* Email */}
                  <Grid item lg={4} sm={6} xs={12}>
                    <InputLabelFormik
                      value={maskEmail(formik?.values?.email) || ""}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      labelId={"email"}
                      name={"email"}
                      placeHolderId={"email_address_placeholder"}
                      errorMsg={handelErrorMsg("email")}
                      disabled
                    />
                  </Grid>

                  {/* Job Title */}
                  <Grid item lg={4} sm={6} xs={12}>
                    <InputLabelFormik
                      value={formik?.values?.job_title}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      labelId={"job_title"}
                      name={"job_title"}
                      placeHolderId={"job_title_placeholder"}
                      errorMsg={handelErrorMsg("job_title")}
                    />
                  </Grid>

                  {/* Phone Number */}
                  <Grid item lg={4} sm={6} xs={12}>
                    <InputLabel
                      className={`user-info-tel-label ${tempUserInfo?.phone_number_disable_reason ? "disabled-label" : ""}`}
                    >
                      {CheckValueLocale("mobile_number", "", {}, intl)}
                    </InputLabel>
                    <CustomizedTooltip
                      title={CheckValueLocale(
                        tempUserInfo?.phone_number_disable_reason || "",
                        "",
                        {},
                        intl,
                      )}
                      arrow
                      placement="bottom"
                      styles={{
                        ...customizedStyledTooltip,
                        maxWidth: "232px",
                      }}
                    >
                      <Box>
                        <UserPhoneNumber
                          phoneNumber={tempUserInfo?.phone_number}
                          setPhoneNumber={(value) =>
                            formik?.setFieldValue("phone_number", value)
                          }
                          formik={formik}
                          disabled={!!tempUserInfo?.phone_number_disable_reason}
                        />
                      </Box>
                    </CustomizedTooltip>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
          </Grid>

          {openModal ? (
            <ImageModal
              open={openModal}
              handleModal={handleModal}
              avatar={formik?.values?.avatar}
              setImage={setImageUpdate}
              id="user-profile-image-modal"
            />
          ) : (
            ""
          )}
        </Box>
      )}
    </>
  );
};
export default UserInfo;
