import { Box } from "@mui/material";
import NewsBlogPlaceholder from "images/placeholders/news_blogs_placeholder.svg";
import ExtrnalLinkIcon from "./subComponents/extrnalLinkIcon";

const HeaderArticleCard = ({
  title,
  publisher,
  publisherImage,
  articleLink,
}) => {
  const handleImageError = (event) => {
    event.target.src = NewsBlogPlaceholder;
    event.target.alt = "placeholder-image";
  };
  return (
    <Box className="header-article-card">
      <Box className="publisher-title-image-header-article">
        <Box className="publisher-image">
          <Box
            component={"img"}
            src={publisherImage || NewsBlogPlaceholder}
            onError={handleImageError}
            alt="publisher image"
          />
        </Box>
        <Box className="publisher-title">
          <a href={articleLink} target="_blank" rel="noreferrer">
            {title}
          </a>

          {publisher ? (
            <Box className="publisher-article-card">{publisher}</Box>
          ) : null}
        </Box>
      </Box>
      <Box className="link-header-article">
        <ExtrnalLinkIcon urlLink={articleLink} />
      </Box>
    </Box>
  );
};

export default HeaderArticleCard;
