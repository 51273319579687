import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserInfoItemList } from "./userInfoItemList";
import { useContext } from "react";
import { EditContext } from "../context";

const Category = ({ categoryData }) => {
  const intl = useIntl();
  const { isEditing } = useContext(EditContext);

  return (
    <div>
      <Accordion
        disabled={isEditing}
        classes={{ disabled: "engager-user-info-accordion-disabled" }}
        defaultExpanded
        className="engager-user-info-accordion"
      >
        <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
          <Typography className="engager-user-info-title">
            {CheckValueLocale("category", "", {}, intl)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <UserInfoItemList
            data={categoryData}
            withColor
            fieldType="category"
            emptyText={CheckValueLocale(
              "no_attached_categories_yet",
              "",
              {},
              intl,
            )}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
export default Category;
