import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedCard: {},
  sla: {},
  selectedCardsBulk: [],
  cardsAssignAndState: {},
  aiAgent: null,
};

const engagementsSlice = createSlice({
  name: "engagementsSlice",
  initialState,
  reducers: {
    clear: (state) => {
      state.selectedCard = {};
    },
    cardData: (state, action) => {
      state.selectedCard = { ...action?.payload };
    },
    clearSla: (state) => {
      state.sla = {};
    },
    slaData: (state, action) => {
      state.sla = { ...action?.payload };
    },
    aiAgentData: (state, action) => {
      state.aiAgent = action?.payload;
    },
    clearAiAgent: (state) => {
      state.aiAgent = null;
    },
    clearBulk: (state) => {
      state.selectedCardsBulk = [];
    },
    bulkData: (state, action) => {
      state.selectedCardsBulk = [...action?.payload];
    },
    clearCardsAssignAndState: (state) => {
      state.cardsAssignAndState = {};
    },
    cardsAssignAndStateData: (state, action) => {
      state.cardsAssignAndState = { ...action?.payload };
    },
  },
});

export default engagementsSlice.reducer;
export const {
  clear,
  cardData,
  clearSla,
  slaData,
  aiAgentData,
  clearAiAgent,
  clearBulk,
  bulkData,
  clearCardsAssignAndState,
  cardsAssignAndStateData,
} = engagementsSlice.actions;
