import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import "./sideBarTabs.scss";
import empty from "images/engagements-images/slaEmpty.svg";
import noData from "images/noDataFound/noDataCase.svg";
import DOMPurify from "dompurify";
import LucButton from "shared/lucButton/lucButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const NoDataSideBarTab = ({ filters }) => {
  const intl = useIntl();

  return (
    <Box className="no-data-side-bar-wrapper">
      <img src={!filters?.keywords?.length ? empty : noData} />
      {filters?.keywords?.length ? (
        <Box className="no-data-side-bar-search">
          {CheckValueLocale(`no_replies_found`, "", {}, intl)}
        </Box>
      ) : null}
      <Box
        className="no-data-text"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            CheckValueLocale(
              filters?.keywords?.length
                ? "no_data_matching"
                : `${filters?.sla_status}_empty`,
              "",
              {},
              intl,
            ),
          ),
        }}
      />
    </Box>
  );
};
export default NoDataSideBarTab;
