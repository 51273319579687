import React, { useMemo } from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import markerIcon from "images/engagements-images/marker.svg";
import { Box } from "@mui/material";

const createLeafIcon = (iconUrl) =>
  new L.Icon({
    iconUrl,
    iconSize: [20, 26],
  });

export const LocationMessage = ({ message = {}, messageId }) => {
  const payload = message?.payload || {};
  const { latitude, longitude } = payload;
  const position = [latitude, longitude];
  const icon = useMemo(() => createLeafIcon(markerIcon), []);
  if (!latitude || !longitude) {
    return null;
  }
  const handleMapClick = () => {
    const googleMapUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
    window.open(googleMapUrl, "_blank", "noopener,noreferrer");
  };

  return (
    <Box
      className="chat-location-message"
      onClick={handleMapClick}
      id={`location-${messageId}`}
    >
      <MapContainer
        center={position}
        zoom={13}
        className="chat-location-message-map"
        attributionControl={false} // Hide Leaflet attribution
        zoomControl={false} // Hide Leaflet zoom control
        dragging={false} // diable map dragging
      >
        <TileLayer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png" />
        <Marker position={position} icon={icon}></Marker>
      </MapContainer>
    </Box>
  );
};
