import { Box, Divider, Tooltip } from "@mui/material";
import SentimentCategoriesThemes from "components/sentimentCategoriesThemes";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale, handlelUserRoles, isArabic } from "utils/helpers";
import profileImg from "images/engagements-images/profileImg.png";
import publicInstagram from "images/engagements-images/ig-icon-eng-round.svg";
import publicFacebook from "images/engagements-images/fb-icon-eng-round.svg";
import publicXPlatform from "images/engagements-images/xp-icon-eng-round.svg";
import { ReactComponent as WhatsappIcon } from "images/social-icons/whatsapp-icon.svg";
import verviedIcon from "images/engagements-images/vervied.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone } from "@fortawesome/pro-regular-svg-icons";
import LucButton from "shared/lucButton/lucButton";
import { useDispatch } from "react-redux";
import { cardData } from "utils/redux/features/Engagements/EngagementsSlice.js";
import { useNavigate } from "react-router-dom";
import UserProfileSideBar from "shared/userProfileSideBar/UserProfileSideBar";
import { useEditInteraction } from "pages/engagements/components/details/hooks/useEditInteraction";

const socialAndSentimentIcons = (type) => {
  const types = {
    facebook: <img src={publicFacebook} />,
    twitter: <img src={publicXPlatform} alt="x-platform-logo" />,
    instagram: <img src={publicInstagram} />,
    whatsapp: <WhatsappIcon />,
  };
  return types[type];
};

export const CsatFeedbackItem = ({
  unixDate,
  themesOptions,
  categoriesOptions,
  sentimentsOptions,
  data,
}) => {
  let itemData = data?.itemData;
  const intl = useIntl();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const haveInbox =
    handlelUserRoles("ENGAGEMENT_ACCESS", "FULL_ACCESS") ||
    handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX");
  const [copied, setCopied] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([
    ...itemData?.v2_categories,
  ]);
  const [selectedThemes, setSelectedThemes] = useState([
    ...itemData?.v2_themes,
  ]);
  const [selectedSentiment, setSelectedSentiment] = useState([
    ...itemData?.sentiment,
  ]);

  const [themesLoading, setThemesLoading] = useState(false);
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [sentimentLoading, setSentimentLoading] = useState(false);

  const { mutate: editInteractions, isPending: editInteractionLoading } =
    useEditInteraction();

  const handleSelectTheme = (updatedThemes) => {
    setThemesLoading(true);

    editInteractions(
      {
        queryData: {
          product_id: +window.localStorage.getItem("engagementsProductId"),
          monitor_id: +data?.tracker_id,
          id: data?.conversation_id,
          data_source_name: itemData?.data_source?.toUpperCase(),
          new_label: updatedThemes,
          old_label: selectedThemes,
          interaction_type: "sub_themes",
        },
      },
      {
        onSuccess: () => {
          setSelectedThemes(updatedThemes);
        },
        onSettled: () => {
          setThemesLoading(false);
        },
      },
    );
  };

  const handleSelectCategory = (updatedCategories) => {
    setCategoriesLoading(true);

    editInteractions(
      {
        queryData: {
          product_id: +window.localStorage.getItem("engagementsProductId"),
          monitor_id: +data?.tracker_id,
          id: data?.conversation_id,
          data_source_name: itemData?.data_source?.toUpperCase(),
          new_label: updatedCategories,
          old_label: selectedCategories,
          interaction_type: "themes",
        },
      },
      {
        onSuccess: () => {
          setSelectedCategories(updatedCategories);
        },
        onSettled: () => {
          setCategoriesLoading(false);
        },
      },
    );
  };

  const handleEditSentiment = (updatedSentiment) => {
    setSentimentLoading(true);
    editInteractions(
      {
        queryData: {
          product_id: +window.localStorage.getItem("engagementsProductId"),
          monitor_id: +data?.tracker_id,
          id: data?.conversation_id,
          data_source_name: itemData?.data_source?.toUpperCase(),
          new_label: updatedSentiment,
          old_label: selectedSentiment,
          interaction_type: "sentiment",
        },
      },
      {
        onSuccess: () => {
          setSelectedSentiment(updatedSentiment);
        },
        onSettled: () => {
          setSentimentLoading(false);
        },
      },
    );
  };
  const handleRedirectToEngagement = () => {
    dispatch(
      cardData({
        system_replies: { engagement_id: data?.engagement_id },
        tracker_id: data?.tracker_id,
        created_at: data?.created_at,
      }),
    );
    navigate("/engagements/main/inbox");
  };

  const handleCopy = () => {
    setCopied(true);
    navigator.clipboard.writeText(itemData?.engagement_id);

    setTimeout(() => {
      setCopied(false);
    }, 2300);
  };
  return (
    <Box className="csat-main-item">
      {openProfile ? (
        <UserProfileSideBar
          dataSourceName={itemData?.data_source?.toUpperCase()}
          unixDate={unixDate}
          openProfile={openProfile}
          setOpenProfile={setOpenProfile}
          selectedCard={data}
          monitorDataSource={data?.data_source}
          monitorId={data?.tracker_id}
          productId={+window.localStorage.getItem("engagementsProductId")}
        />
      ) : null}
      <Box className="csat-item-header">
        <Box
          className={`csat-header-profile ${itemData?.data_source}`}
          onClick={() =>
            itemData?.data_source != "whatsapp" && setOpenProfile(true)
          }
        >
          <Box className="main-img">
            <img
              src={itemData?.image_url || profileImg}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = profileImg;
              }}
            />
            <Box className={"main-icon "}>
              {socialAndSentimentIcons(itemData?.data_source)}
            </Box>
          </Box>
          <Box className="main-acc-name">{itemData?.name}</Box>
          {itemData?.verified ? (
            <img className="main-img-vervied" src={verviedIcon} />
          ) : null}
        </Box>
        {itemData?.engagement_id ? (
          <Box className="csat-header-inbox">
            <Box className="header-engag-id">
              {CheckValueLocale("engag_id_csat", "", {}, intl)}
              <Tooltip
                title={
                  copied
                    ? CheckValueLocale("copied_sla", "", {}, intl)
                    : CheckValueLocale("take_copy", "", {}, intl)
                }
                id="engag-copy-id"
                placement="bottom"
                arrow
              >
                <FontAwesomeIcon onClick={handleCopy} icon={faClone} />
              </Tooltip>
              <Box className="engag-id">
                <Tooltip title={itemData?.engagement_id} placement="top" arrow>
                  {`${itemData?.engagement_id?.substring(0, 8)}...`}
                </Tooltip>
              </Box>
            </Box>

            <Tooltip
              title={
                !haveInbox || !itemData?.isAllawedMonitor
                  ? CheckValueLocale(
                      !haveInbox
                        ? "no_permission_inbox"
                        : "no_permission_inbox",
                      "",
                      {},
                      intl,
                    )
                  : ""
              }
              placement="top"
              arrow
            >
              <Box>
                <LucButton
                  variant="outline"
                  type="secondary"
                  size="small"
                  disabled={!haveInbox || !itemData?.isAllawedMonitor}
                  onClick={handleRedirectToEngagement}
                  id="open-engag-inbox-with-id"
                >
                  {CheckValueLocale("view_engagement", "", {}, intl)}
                </LucButton>
              </Box>
            </Tooltip>
          </Box>
        ) : null}
      </Box>
      <Box className="csat-item-contant">
        <Box
          className="item-contant-text"
          textAlign={isArabic(itemData?.message) ? "right" : "left"}
        >
          {itemData?.message}
        </Box>
        <Divider />
        <Box className="item-contant-footer">
          <Box className="contant-footer-item">
            {CheckValueLocale("csat_score", "", {}, intl)}
            <Box className="footer-item-value">
              {CheckValueLocale(itemData?.csat_score || "", "", {}, intl)}
            </Box>
          </Box>
          <Box className="contant-footer-item">
            {CheckValueLocale("assigned_agent", "", {}, intl)}
            <Box className="footer-item-value">{itemData?.agent}</Box>
          </Box>
          <Box className="contant-footer-item">
            {CheckValueLocale("assigned_team", "", {}, intl)}
            <Box className="footer-item-value">{itemData?.team}</Box>
          </Box>
        </Box>
      </Box>
      <Box className="csat-item-footer">
        <SentimentCategoriesThemes
          themesData={{
            btnId: "-interactions-list-item-themes-btn",
            options: themesOptions,
            selected: selectedThemes,
            handleChange: handleSelectTheme,
            loading: themesLoading,
          }}
          categoriesData={{
            btnId: "-interactions-list-item-categories-btn",
            options: categoriesOptions,
            selected: selectedCategories,
            handleChange: handleSelectCategory,
            loading: categoriesLoading,
          }}
          sentimentData={{
            btnId: "-interactions-list-item-sentiment-btn",
            options: sentimentsOptions,
            selected: selectedSentiment,
            handleChange: handleEditSentiment,
            loading: sentimentLoading,
          }}
          dividers={[1, 2]}
        />
      </Box>
    </Box>
  );
};
