import { useIntl } from "react-intl";
import { Box, Typography } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import TextDirectionSwitcher from "pages/SurveyBuilder/EditSection/textDirectionSwitcher";
import ColorCodeAndPicker from "./components/colorCodeAndPicker";
import DisplayOptionSelect from "./components/displayOptionSelect";

const PopupStyleOptions = (props) => {
  const { embeddedOptions, surveyData, setSurveyData, savedSurveyData } = props;

  const intl = useIntl();

  const selectedOptions = surveyData?.embedded_config?.popup_style || {};

  const handleChangeOptions = (key, value) => {
    setSurveyData((prev) => ({
      ...prev,
      embedded_config: {
        ...prev?.embedded_config,
        popup_style: {
          ...prev?.embedded_config?.popup_style,
          [key]: value,
        },
      },
    }));
  };

  return (
    <Box className="display-settings-options-accordion-content">
      <Box className="survey-alignment-wrapper">
        <Typography className="display-settings-options-subtitle">
          {CheckValueLocale("display_settings_alignment", "", {}, intl)}
        </Typography>
        <TextDirectionSwitcher
          className="display-settings-alignment-option-switch"
          value={surveyData?.text_direction}
          handleChangeDir={(dir) =>
            setSurveyData({ ...surveyData, text_direction: dir })
          }
        />
      </Box>

      <Box className="display-settings-options-accordion-content-option-wrapper">
        <Typography className="display-settings-options-subtitle">
          {CheckValueLocale("display_settings_primary_color", "", {}, intl)}
        </Typography>
        <ColorCodeAndPicker
          id="popup-style-primary-color-field"
          value={selectedOptions?.primary_color}
          onChange={(e) =>
            handleChangeOptions("primary_color", e?.target?.value)
          }
          defaultValue={
            savedSurveyData?.embedded_config?.popup_style?.primary_color
          }
        />
      </Box>

      <Box className="survey-alignment-wrapper">
        <Typography className="display-settings-options-subtitle">
          {CheckValueLocale("display_settings_corner_radius", "", {}, intl)}
        </Typography>
        <DisplayOptionSelect
          id="display-settings-corner-radius-select"
          options={(
            embeddedOptions?.popup_style?.corner_radius?.values?.sort(
              (a, b) => a?.id - b?.id,
            ) || []
          )?.map((option) => {
            return {
              value: option?.value,
              label: `display_settings_corner_radius_${option?.value}`,
            };
          })}
          value={selectedOptions?.corner_radius}
          onChange={(e) =>
            handleChangeOptions("corner_radius", e?.target?.value)
          }
        />
      </Box>
    </Box>
  );
};

export default PopupStyleOptions;
