import { Box, Switch } from "@mui/material";
import { useEffect, useState } from "react";
import TwoFAMethodNameWithIcon from "./components/twoFAMethodNameWithIcon/twoFAMethodNameWithIcon";
import LoadingSpinner from "components/loadingSpinner/loadingSpinner";
import PopupToEnable2FA from "./components/popupToEnable2FA/popupToEnable2FA";
import { useGetUserTwoFactorAuth } from "pages/profile/hooks/useGetUserTwoFactorAuth";
import { useEnableTwoFactorAuthMethod } from "pages/profile/hooks/useEnableTwoFactorAuthMethod";
import { useQueryClient } from "@tanstack/react-query";
import CircularLoading from "components/circularProgress";
import CustomizedTooltip, {
  customizedStyledTooltip,
} from "components/customizedTooltip";
import { useResendCode } from "pages/profile/hooks/useResendCode";
import { CheckValueLocale, differenceSecondsBetweenDates } from "utils/helpers";
import { useIntl } from "react-intl";
import OpenDisablePopup from "./components/popupToEnable2FA/popupBody/disableFeature/openDisablePopup";
import { useDisableTwoFactorAuthFirstStep } from "pages/profile/hooks/useDisableTwoFactorAuthFirstStep";
import { useDisableTwoFactorAuthVerify } from "pages/profile/hooks/useDisableTwoFactorAuthVerify";

//popupData?.step ---> from front
//    -step=0 : this frist step for verify email with three methods
//    -step=1 : this for (enter phone number if SMS) or (scan QR code if TOTP)
//    -step=2 : this to verfiy SMS or TOTP

const TwoFactorAuthentication = ({
  handleErrorSnackBar,
  handleSuccessSnackBar,
}) => {
  const intl = useIntl();
  const [codeVerify, setCodeVerify] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorCodeMsg, setErrorCodeMsg] = useState("");
  const [showQRcode, setShowQRcode] = useState(true);
  const [popupData, setPopupData] = useState({
    open: false,
  });

  ///////////// Hooks///////////////
  const queryClient = useQueryClient();
  const {
    data: user2FAData,
    isFetching: isLoading2FA,
    isLoading: isLoading2FAFirst,
  } = useGetUserTwoFactorAuth();
  //to enable 2FA
  const { mutate: enableUser2FA, isPending: isLoadingEnable } =
    useEnableTwoFactorAuthMethod();
  //to disable 2FA
  const {
    mutate: disableUser2FAFirstStep,
    isPending: isLoadingDisableFirstStep,
  } = useDisableTwoFactorAuthFirstStep();
  const { mutate: disableUser2FAVerify, isPending: isLoadingDisableVerify } =
    useDisableTwoFactorAuthVerify();
  //to resend code
  const { mutate: resenCodeUser2FA, isPending: isLoadingResendCode } =
    useResendCode();

  /////////////end Hooks///////////////

  // frist step to handle enable or disabled
  const handleChangeChecked = (event) => {
    const methodName = event?.target?.name;
    //first call api by type and open popup
    if (event?.target?.checked) {
      //call enable for "methodName"
      enableUser2FA(
        { type: methodName },
        {
          onSuccess: (response) => {
            const data = response?.data?.data;
            setPopupData({
              headerTitle: "enable_two_factor_auth_for_your_account",
              open: true,
              type: methodName,
              step: 0,
              showSixCodeInput: true, // email , sms , totp
              showResendCodeBtn: true, // email , sms
              textButton: "verify",
              bodyText: "text_enable_two_factor_auth_email",
              contact: data?.send_to,
              expires_at: data?.expires_at,
            });
          },
          onError: (error) => {
            if (error?.errorMsg === "Method is already enabled") {
              //to handle if user enable this featur before
              queryClient?.invalidateQueries({
                queryKey: ["userTwoFactorAuth"],
              });
            } else if (error?.errorMsg === "User email is bounced") {
              handleErrorSnackBar(
                "email_bounced_description",
                "email_bounced_title",
              );
            } else handleErrorSnackBar("try_again", "something_went_wrong");
          },
        },
      );
    } else {
      //call disable for "methodName"
      setPopupData({
        headerTitle: "disable_two_fa_your_account_title",
        open: true,
        type: methodName,
        step: 1,
        textButton: "disable",
        warning: "true",
      });
    }
  };

  const handleCodeErrors = (error, disabled) => {
    setErrorCodeMsg({
      msg:
        error?.errorMsg == "OTP Code is incorrect"
          ? error?.data?.remaining_attempts < 4
            ? "error_two_factor_incorrect_remaining_attempts"
            : "error_two_factor_incorrect"
          : error?.errorMsg == "OTP code is expired"
            ? "error_two_factor_code_expired"
            : error?.errorMsg, // need to handle

      remaining_attempts: error?.data?.remaining_attempts,
    });
    handleErrorSnackBar(
      disabled
        ? "two_factor_auth_disabled_unsuccessfully"
        : "two_factor_auth_enabled_unsuccessfully",
      "something_went_wrong",
    );
  };

  //last step for 3 methods
  const handleEnableMethod = () => {
    enableUser2FA(
      { otp: codeVerify },
      {
        onSuccess: () => {
          handleClose();
          handleSuccessSnackBar("two_factor_auth_enabled_successfully");
          // to refresh user info for disabled phone number input
          if (popupData?.type === "sms") {
            queryClient?.invalidateQueries({
              queryKey: ["userInfo"],
            });
          }
        },
        onError: (error) => {
          handleCodeErrors(error);
        },
      },
    );
  };

  //verify email for (sms - totp)
  const handleVerifyEmailToNextStep = (methodName) => {
    enableUser2FA(
      { otp: codeVerify },
      {
        onSuccess: (response) => {
          const data = response?.data?.data; // to read url for QR
          setPopupData((prev) => ({
            ...prev,
            step: 1,
            showSixCodeInput: false,
            showResendCodeBtn: false,
            textButton: "next_step",
            showPhoneNumberInput: methodName === "sms",
            bodyText:
              methodName === "sms"
                ? "text_enter_phone_number_to_enable_sms"
                : "text_scan_qr_code_to_enable_app",
            qr_code: data?.qr_code,
            totp_secret: data?.totp_secret,
          }));
        },
        onError: (error) => {
          handleCodeErrors(error);
        },
      },
    );
  };

  //handle next step for phone number or QR
  const handleNextStep2FA = (methodName) => {
    const queryData = methodName === "sms" ? { phone_number: phoneNumber } : {};
    enableUser2FA(
      { ...queryData },
      {
        onSuccess: (response) => {
          const data = response?.data?.data;
          setPopupData((prev) => ({
            ...prev,
            step: 2,
            showSixCodeInput: true,
            showResendCodeBtn: popupData?.type === "sms" ? true : false,
            showPhoneNumberInput: false,
            textButton: "verify",
            bodyText: `text_enable_two_factor_auth_${popupData?.type}`,
            contact: data?.send_to,
            expires_at:
              popupData?.type === "sms" ? data?.expires_at : undefined,
            qr_code: "",
            totp_secret: "",
          }));
        },
        onError: (error) => {
          if (error?.errorMsg === "Failed to send OTP")
            handleErrorSnackBar(
              "phone_number_failed_description",
              "phone_number_failed_title",
            );
          else if (error?.errorMsg === "User email is bounced") {
            handleErrorSnackBar(
              "email_bounced_description",
              "email_bounced_title",
            );
          } else
            handleErrorSnackBar(
              "two_factor_auth_enabled_unsuccessfully",
              "something_went_wrong",
            );
        },
      },
    );
  };

  //to handle disable 2FA
  //first step to disable
  const hanldeFristStepDisable = () => {
    disableUser2FAFirstStep(
      { type: popupData?.type },
      {
        onSuccess: (response) => {
          const data = response?.data?.data;
          setPopupData((prev) => ({
            ...prev,
            step: 2,
            showSixCodeInput: true,
            showResendCodeBtn: true,
            textButton: "confirm_disable",
            bodyText: "text_disable_two_factor_auth_email",
            contact: data?.send_to,
            expires_at: data?.expires_at,
          }));
        },
        onError: (error) => {
          if (error?.errorMsg === "Method is already disabled") {
            //to handle if user disabled this featur before
            queryClient?.invalidateQueries({
              queryKey: ["userTwoFactorAuth"],
            });
            setPopupData({ open: false });
          } else if (error?.errorMsg === "User email is bounced") {
            handleErrorSnackBar(
              "email_bounced_description",
              "email_bounced_title",
            );
          } else handleErrorSnackBar("try_again", "something_went_wrong");
        },
      },
    );
  };
  //second step to disable
  const handleDisableVerify = () => {
    disableUser2FAVerify(
      { otp: codeVerify },
      {
        onSuccess: () => {
          handleClose();
          handleSuccessSnackBar("two_factor_auth_disabled_successfully");
          // to refresh user info for enabled phone number input
          if (popupData?.type === "sms") {
            queryClient?.invalidateQueries({
              queryKey: ["userInfo"],
            });
          }
        },
        onError: (error) => {
          handleCodeErrors(error, true);
        },
      },
    );
  };

  // to handle all step for enable 2FA and disabled
  const handleAcceptPopup = () => {
    if (popupData?.textButton === "disable") {
      hanldeFristStepDisable();
    } else if (popupData?.textButton === "confirm_disable") {
      handleDisableVerify();
    } else if (popupData?.type === "email") {
      //to check with back end
      handleEnableMethod();
    } else {
      // sms or AuthApp
      switch (popupData?.step) {
        case 0:
          //to check with back-end if success
          handleVerifyEmailToNextStep(popupData?.type);
          break;

        case 1:
          //to check with back-end if success
          handleNextStep2FA(popupData?.type);
          break;

        case 2:
          //to handle verify for SMS or TOTP
          handleEnableMethod();
      }
    }
  };

  const handleClose = () => {
    setPopupData((prev) => ({
      ...prev,
      open: false,
      step: 0,
    }));

    setPhoneNumber(""); // To reset
  };

  //handle resend code
  const handleResendCode = () => {
    resenCodeUser2FA(undefined, {
      onSuccess: (response) => {
        const data = response?.data?.data;
        setCodeVerify("");
        setErrorCodeMsg("");
        setPopupData((prev) => ({
          ...prev,
          expires_at: data?.expires_at,
        }));
      },
      onError: (error) => {
        if (error?.errorMsg === "User email is bounced") {
          handleErrorSnackBar(
            "email_bounced_description",
            "email_bounced_title",
          );
        } else handleErrorSnackBar("try_again", "something_went_wrong");
      },
    });
  };

  // to call get 2FA after code is expired
  useEffect(() => {
    let setTimeVar;
    if (user2FAData?.otp_enabled_at) {
      const timeCount = differenceSecondsBetweenDates(
        new Date(),
        new Date(user2FAData?.otp_enabled_at),
      );
      setTimeVar = setTimeout(
        () => {
          //to get enable from  Back-end after code is expired
          queryClient?.invalidateQueries({
            queryKey: ["userTwoFactorAuth"],
          });
        },
        (timeCount + 1) * 1000,
      );
    }

    return () => {
      clearTimeout(setTimeVar);
    };
  }, [user2FAData?.otp_enabled_at]);

  //to change text for popup with QR code or TOTP
  useEffect(() => {
    setPopupData((prev) => ({
      ...prev,
      bodyText: showQRcode
        ? "text_scan_qr_code_to_enable_app"
        : "text_enter_totp_secret_to_enable_app",
    }));
  }, [showQRcode]);

  return (
    <Box className="two-factor-authentation-card">
      {/* user2FAData --> 2fa : {email: {} , sms:{} , totp:{}} */}
      {isLoading2FAFirst ? (
        <CircularLoading />
      ) : (
        Object.keys(user2FAData?.["2fa"] || {})?.map((method, index) => (
          <Box className="two-factor-authentation-method" key={index}>
            <TwoFAMethodNameWithIcon
              method={method}
              disabled={!user2FAData?.["2fa"]?.[method]?.enabled}
            />
            {isLoading2FA ? (
              <LoadingSpinner />
            ) : (
              <CustomizedTooltip
                title={
                  !user2FAData?.["2fa"]?.[method]?.enabled
                    ? CheckValueLocale(
                        user2FAData?.["2fa"]?.[method]?.disable_reason,
                        "",
                        {},
                        intl,
                      )
                    : ""
                }
                arrow
                placement="top"
                styles={{
                  ...customizedStyledTooltip,
                  maxWidth: "200px",
                }}
              >
                <Box>
                  <Switch
                    name={method}
                    size="small"
                    checked={user2FAData?.["2fa"]?.[method]?.on}
                    onChange={handleChangeChecked}
                    disabled={
                      !user2FAData?.["2fa"]?.[method]?.enabled ||
                      isLoadingEnable
                    }
                  />
                </Box>
              </CustomizedTooltip>
            )}
          </Box>
        ))
      )}

      <PopupToEnable2FA
        handleClose={handleClose}
        modelData={popupData}
        handleAcceptPopup={handleAcceptPopup}
        codeVerify={codeVerify}
        setCodeVerify={setCodeVerify}
        setErrorCodeMsg={setErrorCodeMsg}
        errorCodeMsg={errorCodeMsg}
        isBtnLoading={
          isLoadingEnable || isLoadingDisableFirstStep || isLoadingDisableVerify
        }
        isLoadingResendCode={isLoadingResendCode}
        setPhoneNumber={setPhoneNumber}
        handleResendCode={handleResendCode}
        showQRcode={showQRcode}
        setShowQRcode={setShowQRcode}
        popupBody={
          popupData?.textButton === "disable" ? <OpenDisablePopup /> : null
        }
      />
    </Box>
  );
};

export default TwoFactorAuthentication;
