import { Box, Chip, Typography } from "@mui/material";
import PopupModal from "components/popupModal";
import { useIntl } from "react-intl";
import LucButton from "shared/lucButton/lucButton";
import { CheckValueLocale } from "utils/helpers";

export const ViewAllCustomSourcesModal = ({
  open,
  setOpen,
  sources,
  onDelete,
}) => {
  const intl = useIntl();

  const closeModal = () => setOpen(false);

  const modalBody = (
    <Box>
      <Box>
        <Box className="view-all-news-sources-input-chips-container">
          {sources?.map((source, index) => {
            return (
              <Chip
                key={source?.id || source}
                label={source?.name || source}
                onDelete={() => onDelete(source?.id, index)}
                variant="outlined"
                color="primary"
                className="news-sources-input-chip"
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );

  const modalFooter = (
    <Box className="view-all-news-sources-input-chips-footer">
      <Typography>
        {CheckValueLocale(
          "number_of_selected_custom_sources",
          "",
          {
            num: sources?.length,
          },
          intl,
        )}
      </Typography>
      <LucButton
        onClick={closeModal}
        id="pop-up-right-btn"
        variant="filled"
        type="primary"
        className="popup__rightbtn"
      >
        {CheckValueLocale("done", "", {}, intl)}
      </LucButton>
    </Box>
  );

  return (
    <PopupModal
      title={CheckValueLocale("custom_sources_modal_title", "", {}, intl)}
      body={modalBody}
      open={open || false}
      close={closeModal}
      accept={closeModal}
      topicSelectedCounter={modalFooter}
      topicsExist={true}
    />
  );
};
