import { useState } from "react";
import {
  Box,
  Dialog,
  FormControl,
  InputAdornment,
  TextField,
} from "@mui/material";
import { SketchPicker } from "react-color";

const ColorCodeAndPicker = (props) => {
  const [openedColorPicker, setOpenedColorPicker] = useState(false);

  const handleColorCodeChange = (e) => {
    let newValue = e.target.value;
    if (!newValue?.startsWith("#")) newValue = `#${newValue}`;

    if (newValue?.length > 7) return;

    const hexRegex = /^#([A-Fa-f0-9]*)$/;
    if (hexRegex?.test(newValue))
      props?.onChange?.({ target: { value: newValue } });
  };

  const handleBlurColorCode = (e) => {
    const newValue = e.target.value;
    const coloCodeRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
    if (!coloCodeRegex?.test(newValue) && props?.defaultValue?.length)
      props?.onChange?.({ target: { value: props?.defaultValue } });
  };

  return (
    <Box id="color-code-and-picker-wrapper">
      <FormControl
        className="display-settings-color-picker-field"
        variant="outlined"
        size="small"
        fullWidth
      >
        <TextField
          id={props?.id || "display-settings-color-picker-field"}
          variant="outlined"
          size="small"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                size="small"
                onClick={() => setOpenedColorPicker(true)}
              >
                <Box
                  className="selected-color-preview-square"
                  sx={{ bgcolor: props?.value || "" }}
                  onClick={() => {}}
                />
              </InputAdornment>
            ),
          }}
          {...props}
          onChange={handleColorCodeChange}
          onBlur={handleBlurColorCode}
        />
      </FormControl>

      {openedColorPicker ? (
        <Dialog
          id="survey-display-settings-color-picker-modal"
          open={openedColorPicker}
          onClose={() => setOpenedColorPicker(false)}
        >
          <SketchPicker
            width="300px"
            color={props?.value || ""}
            onChange={(color) =>
              props?.onChange?.({ target: { value: color?.hex } })
            }
            onClose={() => setOpenedColorPicker(false)}
          />
        </Dialog>
      ) : null}
    </Box>
  );
};

export default ColorCodeAndPicker;
