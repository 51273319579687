import { useState } from "react";
import { Menu, MenuItem, IconButton, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faPen,
  faEye,
  faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";
//Locals
import { useIntl } from "react-intl";
import { handlelUserRoles, CheckValueLocale } from "utils/helpers";
import CustomizedTooltip from "components/customizedTooltip";
import {
  faRectangleHistoryCircleUser,
  faRotate,
} from "@fortawesome/pro-solid-svg-icons";
import { faLinkSlash, faRepeat } from "@fortawesome/pro-regular-svg-icons";

const OptionsDropDown = (props) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const [stats, setStats] = useState({
    anchorEl: null,
  });
  const open = Boolean(stats?.anchorEl);

  const handleClick = (event) => {
    setStats({ anchorEl: event?.currentTarget });
  };
  const handleClose = () => {
    setStats({ anchorEl: null });
  };
  window.addEventListener("scroll", () => {
    if (open) {
      handleClose();
    }
  });

  return (
    <Box className="user-management-menu-wrapper">
      <CustomizedTooltip
        id="menu-item-list-tooltip"
        title={
          <Box className="item-title-tooltip">
            {CheckValueLocale("items_ellipsis", "", {}, intl)}
          </Box>
        }
        arrow
        placement="bottom"
      >
        <IconButton
          className={
            "menu-items menu-item-container " + (open ? "menu-item-active" : "")
          }
          aria-label="more"
          id="settings-dropdown-user-management-long-button"
          aria-controls="long-menu"
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          disabled={props?.disabledOptions}
        >
          <FontAwesomeIcon
            className="ellipsis-icon"
            icon={faEllipsisH}
            fixedWidth
          />
        </IconButton>
      </CustomizedTooltip>
      <Menu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: intl?.locale == "ar" ? "left" : "right",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: intl?.locale == "ar" ? "left" : "right",
        }}
        anchorEl={stats?.anchorEl}
        open={open}
        onClose={handleClose}
        className="user-management-list"
      >
        <MenuItem
          onClick={() =>
            navigate(`/settings/user_managements/view/${props?.id}`)
          }
          disableRipple
          className="user-management-options-menu-item"
          id={`user-management-dropdown-veiw-${props?.id ?? "id"}`}
        >
          <FontAwesomeIcon icon={faEye} fixedWidth />
          {CheckValueLocale("view_team", "", {}, intl)}
        </MenuItem>
        {/* Edit */}
        <MenuItem
          disableRipple
          disabled={props?.status == "invited" ? true : false}
          className="user-management-options-menu-item"
          id={`user-management-dropdown-edit-${props?.id ?? "id"}`}
          onClick={() => {
            navigate(`/settings/user_managements/edit/${props?.id}`, {
              state: { id: props?.id },
            });
          }}
        >
          <FontAwesomeIcon icon={faPen} fixedWidth />
          {CheckValueLocale("edit", "", {}, intl)}
        </MenuItem>
        {/* User Activity log */}
        <MenuItem
          disableRipple
          className="user-management-options-menu-item"
          id={`user-management-dropdown-activity-log-${props?.id ?? "id"}`}
          onClick={() => {
            navigate(`/settings/user_managements/view/${props?.id}`, {
              dataState: { scrollToSection: "user-activity-log-section" },
            });
          }}
        >
          <FontAwesomeIcon icon={faRectangleHistoryCircleUser} fixedWidth />
          {CheckValueLocale("user_activity_log_option", "", {}, intl)}
        </MenuItem>
        {props?.status !== "deactivated" ? (
          <MenuItem
            onClick={() => {
              props?.deactiveUser(props?.id);
              handleClose();
            }}
            disableRipple
            disabled={props?.status === "invited"}
            className="user-management-options-menu-item"
            id={`user-management-dropdown-de-active-${props?.id ?? "id"}`}
          >
            <FontAwesomeIcon icon={faLinkSlash} />
            {CheckValueLocale("deactive", "", {}, intl)}
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => {
              props?.activeUser(props?.id);
              handleClose();
            }}
            disableRipple
            className="user-management-options-menu-item"
            id={`user-management-dropdown-re-active-${props?.id ?? "id"}`}
          >
            <FontAwesomeIcon icon={faRepeat} />
            {CheckValueLocale("re_active", "", {}, intl)}
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            props?.inviteUser(props?.id);
            handleClose();
          }}
          disableRipple
          disabled={props?.status !== "invited" ? true : false}
          className="user-management-options-menu-item"
          id={`user-management-dropdown-re-invite-${props?.id ?? "id"}`}
        >
          <FontAwesomeIcon icon={faRotate} fixedWidth />
          {CheckValueLocale("reinvite", "", {}, intl)}
        </MenuItem>
        {handlelUserRoles("GENERAL", "DESTROY_USER") && (
          <>
            <MenuItem
              onClick={() => {
                props?.openDeleteModal(
                  props?.id,
                  props?.index,
                  props?.page,
                  props?.name,
                );
                handleClose();
              }}
              disableRipple
              className="user-management-options-menu-item"
              id={`user-management-dropdown-delete-${props?.id ?? "id"}`}
            >
              <FontAwesomeIcon icon={faTrash} fixedWidth />
              {CheckValueLocale("delete", "", {}, intl)}
            </MenuItem>
          </>
        )}
      </Menu>
    </Box>
  );
};
export default OptionsDropDown;
