import React, { useContext } from "react";
import { Box, Grid, Pagination } from "@mui/material";
import { ViewReportContext } from "pages/reports/context/reportContext";
import ViewReportRecordItem from "./viewReportRecordItem";
import CircularLoading from "components/circularProgress";

const ViewReportRecordsTable = () => {
  const { reportDataLoading, reportRecords, pagination, page, setPage } =
    useContext(ViewReportContext);

  return (
    <>
      {reportDataLoading ? (
        <CircularLoading />
      ) : (
        <Box className="view-report-page-records-table-wrapper">
          <Grid container className="">
            {reportRecords?.map((recordItem) => (
              <ViewReportRecordItem
                key={recordItem?.report_data_id}
                recordItem={recordItem}
              />
            ))}
          </Grid>
          {pagination?.pages > 1 ? (
            <Pagination
              variant="outlined"
              className="pagination-monitor-list"
              page={page}
              count={pagination?.pages}
              onChange={(e, page) => setPage(Number(page))}
            />
          ) : null}
        </Box>
      )}
    </>
  );
};

export default ViewReportRecordsTable;
