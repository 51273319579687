import { useQuery } from "@tanstack/react-query";
import { ReportServices } from "services/api/reports";

export const useReportChannels = ({ datasourcesIds, queryData }) => {
  const { product_id, data_source_id, data_source_type } = queryData || {};

  return useQuery({
    queryKey: ["reportChannels", product_id, data_source_id, data_source_type],
    queryFn: () => ReportServices.getReportChannels(queryData),
    enabled: !!product_id && !!data_source_id?.length && !!data_source_type,
    select: (data) => {
      const groupedChannels = data?.data?.data || {};

      const concatedChannels = Object?.keys(groupedChannels)?.reduce(
        (acc, key) => {
          const mappedChannels = groupedChannels?.[key]?.map((channel) => ({
            ...channel,
            data_source: key,
            data_source_id: datasourcesIds?.[key],
          }));
          return acc?.concat(mappedChannels || []);
        },
        [],
      );

      return concatedChannels;
    },
  });
};
