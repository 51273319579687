import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import EngagementTagsList from "./engagementTagsList";
import { useGetEngagementTags } from "../hooks/useGetEngagementTags";
import { useSortableTable } from "hooks/useSortableTable";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import "./tagList.scss";
import SnackBar from "components/snackBar";
import CustomizedTooltip from "components/customizedTooltip";
import { sortedColumnTypes } from "hooks/useSortableTable";
import { useUpdateStatus } from "../hooks/useUpdateStatus";

const tooltipTableHead = (name, intl) => {
  return (
    <CustomizedTooltip
      id="tag-table-head-tooltip"
      title={CheckValueLocale(
        name == "tag_status"
          ? "tag_status_tooltip"
          : "total_tagged_engagements_tooltip",
        "",
        {},
        intl,
      )}
      placement="bottom"
      arrow
    >
      <Box>{CheckValueLocale(name, "", {}, intl)}</Box>
    </CustomizedTooltip>
  );
};
const getTagsListTableHeader = (intl) => {
  return [
    {
      body: CheckValueLocale("tag_name", "", {}, intl),
      width: "20.33%",
      name: "label",
      type: sortedColumnTypes.string,
      className: "tags-table-head",
    },
    {
      body: CheckValueLocale("list_creator", "", {}, intl),
      width: "20.33%",
      className: "tags-table-head",
    },
    {
      body: CheckValueLocale("create_date_alert", "", {}, intl),
      name: "created_at",
      width: "20.33%",
      type: sortedColumnTypes.date,
      className: "tags-table-head",
    },
    {
      body: tooltipTableHead("total_tagged_engagements", intl),
      width: "23.05%",
      name: "total_engagements",
      type: sortedColumnTypes.number,
      className: "tags-table-head tagged-border",
    },

    {
      body: tooltipTableHead("tag_status", intl),
      width: "9.89%",
      name: "is_enabled",
      type: sortedColumnTypes.number,
      className: "tags-table-head tagged-border",
    },

    {
      body: null,
      width: "6.08%",
      className: "action-table-head tags-table-head",
    },
  ];
};
const EngagementTagsListPage = () => {
  const intl = useIntl();

  // State variables for managing data and UI state
  const [selectedTag, setSelectedTag] = useState(null);
  const [tableBody, setTableBody] = useState([]);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [snackBarTagsTable, setSnackBarTagsTable] = useState({
    open: false,
    title: "",
    severity: "",
    message: "",
    tagName: "",
  });
  const [popUpState, setPopUpState] = useState({
    title: "",
    leftBtn: "",
    rightBtn: "",
    status: "",
  });
  const [contentModalBody, setContentModalBody] = useState({
    title: "",
    subTitle: "",
    listOne: "",
    listTwo: "",
    listThree: "",
    lastText: "",
  });

  // API hooks
  const { mutate: SwitchStatus, Loading: isLoadingStatus } = useUpdateStatus();

  // User role permissions
  const enabledEdit = handlelUserRoles("ENGAGEMENT_TAG_MANAGEMENT", "EDIT_TAG");
  const enabledCreate = handlelUserRoles(
    "ENGAGEMENT_TAG_MANAGEMENT",
    "CREATE_TAG",
  );
  const enabledDelete = handlelUserRoles(
    "ENGAGEMENT_TAG_MANAGEMENT",
    "DELETE_DISABLE_TAG",
  );

  // Sorting and table management
  const {
    columns: tableHead,
    sortColumn,
    sortDirection,
    isSortableLoading,
    setIsSortableLoading,
  } = useSortableTable({
    initialColumns: getTagsListTableHeader(intl),
    onSortChangeCallback: () => {
      setIsSortableLoading(true);
      if (pageNumber !== 1) {
        setPageNumber(1);
      }
    },
    defaultSelectedColumn: "created_at",
    defaultDirection: "desc",
  });

  // Fetch tags list
  const { data: tagsDataList, isLoading: tagsDataLoading } =
    useGetEngagementTags({
      queryData: {
        page: pageNumber,
        order_by: sortDirection,
        sort_by: sortColumn,
      },
    });

  // Handle popup open/close
  const handleClosePopup = () => {
    setOpenPopUp(false);
    setSelectedTag(null);
  };

  const handleOpenPopUp = (data) => {
    setOpenPopUp(true);
    setSelectedTag(data);

    setPopUpState({
      title: data?.is_enabled ? "disable_tag" : "enable_tag",
      leftBtn: "cancel",
      rightBtn: data?.is_enabled ? "disable" : "enable_tag_btn",
    });
    setContentModalBody({
      title: data?.is_enabled ? "disable_tag_title" : "enable_tag_title",
      subTitle: data?.is_enabled ? "disable_tag_dec" : "enable_tag_dec",
      listOne: data?.is_enabled ? "disable_tag_dec_one" : "enable_tag_dec_one",
      listTwo: data?.is_enabled ? "disable_tag_dec_two" : "enable_tag_dec_two",
      listThree: data?.is_enabled ? "disable_tag_dec_three" : "",
      lastText: "",
    });
  };
  // Handle switch toggle action
  const handleSwitchClick = () => {
    SwitchStatus(
      { data: { is_enabled: !selectedTag?.is_enabled }, id: selectedTag?.id },
      {
        onSuccess: () => {
          setSnackBarTagsTable({
            open: true,
            message: selectedTag?.is_enabled
              ? "disabled_successfully"
              : "enabled_successfully",
            severity: "success",
            tagName: selectedTag?.label,
          });
          setOpenPopUp(false);
        },
        onError: () => {
          setSnackBarTagsTable({
            open: true,
            title: "failed_error_message",
            message: "try_again_error_message",
            severity: "error",
          });
        },
      },
    );
    setTimeout(
      () => setSnackBarTagsTable({ open: false, title: "", severity: "" }),
      3000,
    );
  };

  return (
    <Box>
      <EngagementTagsList
        enabledEdit={enabledEdit}
        enabledDelete={enabledDelete}
        enabledCreate={enabledCreate}
        tableBody={tableBody}
        tagsData={tagsDataList}
        setTableBody={setTableBody}
        pagination={tagsDataList?.pagination}
        tableHead={tableHead}
        isSortableLoading={isSortableLoading}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        openPopUp={openPopUp}
        handleSwitchClick={handleSwitchClick}
        handleOpenPopUp={handleOpenPopUp}
        selectedTag={selectedTag}
        snackBarTagsTable={snackBarTagsTable}
        setSnackBarTagsTable={setSnackBarTagsTable}
        loading={tagsDataLoading}
        popUpState={popUpState}
        setOpenPopUp={setOpenPopUp}
        handleClosePopup={handleClosePopup}
        contentModalBody={contentModalBody}
        setContentModalBody={setContentModalBody}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        setPopUpState={setPopUpState}
        setSelectedTag={setSelectedTag}
        tagsDataLoading={tagsDataLoading}
        isLoadingStatus={isLoadingStatus}
        tagsLimitReached={tagsDataList?.tags_limit_reached}
      />
      <SnackBar
        open={snackBarTagsTable?.open}
        title={CheckValueLocale(
          snackBarTagsTable?.title,
          "",
          { tagName: snackBarTagsTable?.tagName },
          intl,
        )}
        message={CheckValueLocale(
          snackBarTagsTable?.message,
          "",
          { tagName: snackBarTagsTable?.tagName },
          intl,
        )}
        severity={snackBarTagsTable?.severity}
        handleClose={() =>
          setSnackBarTagsTable({ ...snackBarTagsTable, open: false })
        }
      />
    </Box>
  );
};

export default EngagementTagsListPage;
