import React, { useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";
import SnackBar from "components/snackBar";
import EmbeddedPublishModal from "./embeddedPublishModal";
import "./style.scss";

const EmbeddedPublishBtn = (props) => {
  const {
    surveyData,
    currentDomainsPage,
    setCurrentDomainsPage,
    companyDomainsList,
    setCompanyDomainsList,
    companyDomainsData,
    setCompanyDomainsData,
    openEmbeddedPublishModal,
    setEmbeddedPublishModal,
    embeddedPublishStep,
    setEmbeddedPublishStep,
    disabledPublish,
    embeddedPublishLoading,
    handlePublishEmbedded,
    saveLoading,
    selectedDomains,
    setSelectedDomains,
  } = props;

  const intl = useIntl();

  const [snackBar, setSnackBar] = useState({});

  return (
    <>
      <LucButton
        id="survey-builder-embedded-publish-btn"
        size="small"
        disabled={disabledPublish}
        loading={embeddedPublishLoading}
        onClick={handlePublishEmbedded}
      >
        {CheckValueLocale("publish", "", {}, intl)}
      </LucButton>

      {openEmbeddedPublishModal && !!embeddedPublishStep ? (
        <EmbeddedPublishModal
          surveyData={surveyData}
          step={embeddedPublishStep}
          setStep={setEmbeddedPublishStep}
          openedEmbeddedPublish={openEmbeddedPublishModal}
          setOpenedEmbeddedPublish={setEmbeddedPublishModal}
          currentDomainsPage={currentDomainsPage}
          setCurrentDomainsPage={setCurrentDomainsPage}
          companyDomainsList={companyDomainsList}
          setCompanyDomainsList={setCompanyDomainsList}
          companyDomainsData={companyDomainsData}
          setCompanyDomainsData={setCompanyDomainsData}
          selectedDomains={selectedDomains}
          setSelectedDomains={setSelectedDomains}
          handlePublishEmbedded={handlePublishEmbedded}
          saveLoading={saveLoading}
          setSnackBar={setSnackBar}
        />
      ) : null}

      {snackBar?.open ? (
        <SnackBar
          open={snackBar?.open}
          severity={snackBar?.severity}
          title={CheckValueLocale(snackBar?.title, "", {}, intl)}
          message={CheckValueLocale(snackBar?.message, "", {}, intl)}
          handleClose={() => setSnackBar({})}
        />
      ) : null}
    </>
  );
};

export default EmbeddedPublishBtn;
