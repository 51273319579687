import moment from "moment";
import { isEmptyArray } from "utils/helpers";
import { handleXlsData } from "../excelCommonFn";

const account_ig_AA = (response, lang) => {
  if (isEmptyArray(response)) {
    return [];
  } else {
    var followersGrowth = [],
      postsVolume = [],
      postsContentType = [],
      topKeywords = [],
      topHashtags = [],
      topPosts = [],
      topImages = [],
      topVideos = [],
      allSheetData = [];
    //_______________________________________________________________________________
    response?.map((i) => {
      Object.entries(i)?.forEach(([key, value]) => {
        switch (key) {
          case "followersGrowth":
            value?.map((i) => {
              followersGrowth?.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.number]: i?.value,
              });
            });
            allSheetData?.push([followersGrowth, lang?.followers_growth]);
            break;
          //_______________________________________________________________________________

          case "postsContentType":
            value?.map((i) => {
              postsContentType?.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.post_content_type]: i?.value[0],
                [lang.photo_content_type]: i?.value[1],
                [lang.video_content_type]: i?.value[2],
                [lang.total]: i?.value[0] + i?.value[1] + i?.value[2],
              });
            });
            allSheetData?.push([postsContentType, lang?.posts_content_type]);
            break;
          // _______________________________________________________________________________

          case "topImages":
            value?.map((i) => {
              topImages?.push({ [lang.image]: i?.permalink });
            });
            allSheetData?.push([topImages, lang.posts_top_images]);
            break;
          // _______________________________________________________________________________

          case "topVideos":
            value?.map((i) => {
              topVideos.push({ [lang.video]: i?.permalink });
            });
            allSheetData.push([topVideos, lang.posts_top_videos]);
            break;
          // _______________________________________________________________________________

          case "postsVolume":
            value?.map((i) => {
              postsVolume?.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.posts_word]: i?.value,
              });
            });
            allSheetData?.push([postsVolume, lang?.posts_volume]);
            break;

          //_______________________________________________________________________________

          case "topKeywords":
            value?.map((i) => {
              topKeywords?.push({
                [lang.keywords]: i?.id,
                [lang.xlsx_number_of_posts]: i?.stats_count,
              });
            });
            allSheetData?.push([
              topKeywords,
              lang?.posts_top_keywords_xlsx_sheetname,
            ]);

            break;
          // _______________________________________________________________________________

          case "topHashtags":
            value?.map((i) => {
              topHashtags?.push({
                [lang.xlsx_hashtags]: i?.id,
                [lang.xlsx_number_of_posts]: i?.stats_count,
              });
            });
            allSheetData?.push([topHashtags, lang?.posts_top_hashtags]);

            break;

          // _______________________________________________________________________________

          case "topPosts":
            value?.map((i) => {
              topPosts?.push({ [lang.posts_word]: i?.tweet_link });
            });
            allSheetData?.push([topPosts, lang?.top_posts]);

            break;
          //_______________________________________________________________________________
          default:
            break;
        }
      });
    });
    //_______________________________________________________________________________

    return handleXlsData(allSheetData);
  }
};
export { account_ig_AA };
