export const processEngagerSocialMediaData = (sideBarSocialMediaData) => {
  const result = { ...sideBarSocialMediaData };
  const platforms = ["fb", "tw", "ig"];

  platforms.forEach((platform) => {
    const dmKey = `${platform}_dm`;

    // Ensure platform data is an array
    result[platform] = Array.isArray(result[platform])
      ? result[platform]
      : result[platform]
        ? [result[platform]]
        : [];

    // Ensure DM data is an array
    const dmData = Array.isArray(result[dmKey])
      ? result[dmKey]
      : result[dmKey]
        ? [result[dmKey]]
        : [];

    // Combine platform and DM data
    result[platform] = [...result[platform], ...dmData];

    // Remove the DM key
    delete result[dmKey];
  });

  // Handle remaining fields
  Object.keys(result).forEach((key) => {
    if (!platforms.includes(key)) {
      result[key] = Array.isArray(result[key]) ? result[key] : [];
    }
  });

  return result;
};
