import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LucButton from "shared/lucButton/lucButton";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import Chip from "@mui/material/Chip";
import CustomizedTooltip, {
  customizedStyledTooltip,
} from "components/customizedTooltip";

const FeatureOptionBody = (props) => {
  const intl = useIntl();
  return (
    <>
      <Box className="details-container">
        <Typography className="feature-name">
          {CheckValueLocale(props?.featureName, "", {}, intl)}
          {!props?.isAvailable && (
            <Chip
              label={CheckValueLocale("soon", "", {}, intl)}
              size="small"
              className="not-active-feature"
            />
          )}
        </Typography>
        <Box className="description-container">
          <Typography className="description-details">
            {CheckValueLocale(props?.featureDescription, "", {}, intl)}
          </Typography>
        </Box>
      </Box>
      <Box className="feature-buttons">
        {props?.isAvailable ? (
          <CustomizedTooltip
            title={
              props?.disabled
                ? CheckValueLocale("previous_otp_not_expired", "", {}, intl)
                : ""
            }
            arrow
            placement="bottom"
            styles={{
              ...customizedStyledTooltip,
            }}
          >
            <Box>
              <LucButton
                variant="outline"
                type="secondary"
                size="small"
                onClick={props?.openMethodPopup}
                disabled={props?.disabled}
              >
                {CheckValueLocale(props?.buttonTitle, "", {}, intl)}
              </LucButton>
            </Box>
          </CustomizedTooltip>
        ) : null}
        {props?.openDisable ? (
          <CustomizedTooltip
            title={
              props?.disabled
                ? CheckValueLocale("previous_otp_not_expired", "", {}, intl)
                : ""
            }
            arrow
            placement="bottom"
            styles={{
              ...customizedStyledTooltip,
            }}
          >
            <Box>
              <LucButton
                variant="outline"
                type="red"
                size="small"
                onClick={props?.openDisable}
                disabled={props?.disabled}
              >
                {CheckValueLocale("disable", "", {}, intl)}
              </LucButton>
            </Box>
          </CustomizedTooltip>
        ) : null}
      </Box>
    </>
  );
};
export default FeatureOptionBody;
