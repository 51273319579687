import {
  Box,
  FormHelperText,
  Grid,
  InputLabel,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { useState } from "react";
import { useIntl } from "react-intl";
import LucButton from "shared/lucButton/lucButton";
import { CheckValueLocale } from "utils/helpers";
import AccountInputs from "../components/AccountInputs.js";
import AccountsListImportExcelPopUp from "../components/accountsListImportPopUp/index.js";
import ImportFunctions from "../components/accountsListImportPopUp/components/importSharedFunction.js";
import "./createAndEditAccountsLists.scss";

const CreateAndEditAccountsListsForm = ({ setSnackBarData, formik }) => {
  let {
    setFiles,
    excelFileName,
    excelFileError,
    excelFileErrorMsg,
    isFileUploaded,
    errorMessages,
    importedUsernames,
    handleImportAccountsList,
    handleResetImportPopUp,
    setOpenImportPopUp,
    openImportPopUp,
    handleExcelGuideData,
    handleCancelUploadFile,
    excelGuideData,
    excelGuideLoading,
  } = ImportFunctions();

  const isImportedUsernameValid = (username, counter) => {
    const strUsername = username?.toString();
    if (formik?.values?.usernames?.includes(strUsername)) {
      return false;
    }
    if (formik?.values?.usernames?.length + counter?.value > 200) {
      return false;
    }
    counter.value++;
    return true;
  };

  const handleXlsImportedUsernames = (newUsernames) => {
    const counter = { value: 1 };
    const validUsernames = _.uniq(newUsernames)
      ?.filter((user) => isImportedUsernameValid(user, counter))
      ?.map((user) => user?.toString());
    formik?.setFieldValue("usernames", [
      ...formik?.values?.usernames,
      ...validUsernames,
    ]);
    return validUsernames;
  };

  const childProps = {
    setFiles,
    excelFileName,
    excelFileError,
    excelFileErrorMsg,
    isFileUploaded,
    errorMessages,
    importedUsernames,
    handleImportAccountsList,
    handleResetImportPopUp,
    setOpenImportPopUp,
    openImportPopUp,
    handleExcelGuideData,
    handleCancelUploadFile,
    excelGuideData,
    handleXlsImportedUsernames,
    stepOneLists: formik.values.usernames,
    setSnackBarData,
    excelGuideLoading,
  };

  const intl = useIntl();

  const [isRequestError, setIsRequestError] = useState(false);

  return (
    <>
      <Typography variant="h6" className="add-accounts-label">
        {CheckValueLocale("add_accounts_list_informations", "", {}, intl)}
      </Typography>
      <Grid
        container
        spacing={2}
        className="create-edit-accounts-lists-form-grid"
      >
        <Grid item xs={12} md={6}>
          <Box className="text-field">
            <InputLabel>
              <Box component="label">
                {CheckValueLocale("list_name", "", {}, intl)}
                <Box component="span" className="required-asterisk">
                  *
                </Box>
              </Box>
            </InputLabel>
            <TextField
              fullWidth
              placeholder={CheckValueLocale(
                "write_your_list_name",
                "",
                {},
                intl,
              )}
              name="name"
              value={formik?.values?.["name"] || ""}
              onChange={(e) => {
                formik?.setFieldTouched("name", true);
                formik?.handleChange(e);
              }}
              onBlur={formik?.handleBlur}
              error={
                formik?.touched?.name && formik?.errors?.["name"] ? true : false
              }
            />
            {formik?.touched?.name && formik?.errors?.["name"] && (
              <FormHelperText error>
                {CheckValueLocale(formik?.errors?.["name"], "", {}, intl)}
              </FormHelperText>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="text-field">
            <InputLabel>
              {CheckValueLocale("description", "", {}, intl)}
            </InputLabel>
            <TextField
              fullWidth
              placeholder={CheckValueLocale(
                "write_your_list_description",
                "",
                {},
                intl,
              )}
              name="description"
              value={formik?.values?.["description"] || ""}
              onChange={(e) => {
                formik?.setFieldTouched("description", true);
                formik?.handleChange(e);
              }}
              onBlur={formik?.handleBlur}
              error={
                formik?.touched?.description && formik?.errors?.["description"]
                  ? true
                  : false
              }
            />
            {formik?.touched?.description &&
              formik?.errors?.["description"] && (
                <FormHelperText error>
                  {CheckValueLocale(
                    formik?.errors?.["description"],
                    "",
                    {},
                    intl,
                  )}
                </FormHelperText>
              )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className="create-edit-accounts-lists-import-container">
            <Box className="create-edit-accounts-lists-import-msg-container">
              <Typography
                variant="h6"
                color="black"
                className="add-accounts-label"
              >
                {CheckValueLocale("account_list_import_title", "", {}, intl)}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {CheckValueLocale("import_clarification", "", {}, intl)}
              </Typography>
            </Box>
            <Box
              className="text-field"
              display="flex"
              justifyContent="flex-end"
            >
              {openImportPopUp ? (
                <AccountsListImportExcelPopUp {...childProps} />
              ) : null}
              <LucButton
                variant="outline"
                id="import-accounts-btn"
                onClick={handleImportAccountsList}
              >
                {CheckValueLocale("import_file", "", {}, intl)}
              </LucButton>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <AccountInputs
            usernames={formik?.values?.usernames}
            setUsernames={(newUsernames) =>
              formik?.setFieldValue("usernames", newUsernames)
            }
          />
        </Grid>
      </Grid>
      {isRequestError && (
        <Snackbar
          lang={window?.localStorage?.lang}
          open={true}
          handleClose={() => {
            setIsRequestError(!!false);
          }}
          severity="error"
          message={CheckValueLocale(
            "something_went_wrong_try_agin",
            "",
            {},
            intl,
          )}
        />
      )}
    </>
  );
};

export default CreateAndEditAccountsListsForm;
