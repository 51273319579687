import { useIntl } from "react-intl";
import { Grid, Box } from "@mui/material";
import { CheckValueLocale, maskEmail } from "utils/helpers";

import "./UserForm.scss";
import InputLabelFormik from "./components/inputLabelFormik/inputLabelFormik";
import UserPhoneNumber from "pages/profile/components/userPhoneNumber/userPhoneNumber";

const UserForm = ({ isEditUser, formik, stepOneIntialData }) => {
  const intl = useIntl();
  const handelErrorMsg = (nameField) => {
    return formik?.touched?.[nameField] ? formik?.errors?.[nameField] : "";
  };

  return (
    <>
      <Box className="add-user-form">
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Grid item md={6}>
            <InputLabelFormik
              value={formik?.values?.first_name}
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              labelId={"first_name"}
              name={"first_name"}
              placeHolderId={"first_name_placeholder"}
              errorMsg={handelErrorMsg("first_name")}
              required
            />
          </Grid>
          <Grid item md={6}>
            <InputLabelFormik
              value={formik?.values?.last_name}
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              labelId={"last_name"}
              placeHolderId={"last_name_placeholder"}
              errorMsg={handelErrorMsg("last_name")}
              required
            />
          </Grid>

          <Grid item md={6}>
            <InputLabelFormik
              value={
                isEditUser
                  ? maskEmail(formik?.values?.mail)
                  : formik?.values?.mail
              }
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              labelId={"email"}
              name={"mail"}
              placeHolderId={"email_address_placeholder"}
              errorMsg={handelErrorMsg("mail")}
              disabled={isEditUser}
              required
            />
          </Grid>
          <Grid item md={6}>
            <InputLabelFormik
              value={formik?.values?.job_title}
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              labelId={"job_title"}
              name={"job_title"}
              errorMsg={handelErrorMsg("job_title")}
              placeHolderId={"job_title_placeholder"}
            />
          </Grid>
          <Grid item md={6}>
            <Box className="input-user-settings-label">
              {CheckValueLocale("mobile_number", "", {}, intl)}
            </Box>
            {(isEditUser && formik?.dirty) || !isEditUser ? (
              <UserPhoneNumber
                phoneNumber={stepOneIntialData?.phone}
                setPhoneNumber={(value) =>
                  formik?.setFieldValue("phone", value)
                }
                formik={formik}
                keyName={"phone"}
              />
            ) : null}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default UserForm;
