import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Typography, Grid, Box } from "@mui/material";
import LineChartWidget from "components/widgets/lineChartWidget";
import StackedLineWidget from "components/widgets/stackedLineWidget.js";
import PieChartWidget from "components/widgets/pieChartWidget.js";
import InteractionsList from "components/interactions-list/InteractionsList.js";
import ThemesAnalysis from "components/widgets/themes/themeAnalysis/ThemeAnalysis";
import ThemesTrends from "components/widgets/themes/themesTrends/ThemesTrends";
import { handleArraySum, aggregate, output } from "../insightsCommonFunctions";
import OverView from "components/widgets/overViewWidget.js";
import PunchCardWidget from "components/widgets/punchCardWidget";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import moment from "moment";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import publicFacebook from "images/social-icons/facebook-public-icon.png";
import publicInstagram from "images/social-icons/instagram-public-icon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { getNoOfDays } from "utils/helpers";
import _ from "lodash";
import { checkIsDataFecthing } from "pages/channelAnalytics/InsightsPage/insightsCommonFunctions";
import LuciFunctions from "pages/luci/components/luciFunctions/luciFunction";
import publicTikTok from "images/shared-images/public-tikTok.svg";
import xPlatform from "images/social-icons/x-platform-icon.svg";

const SocialMediaBody = (props) => {
  const intl = useIntl();
  let {
    response,
    preLoaderTrackerDataSources,
    startDate,
    endDate,
    dialectsSubDialectsData,
    setExcelSheetData,
  } = props;

  const [topKeywords, setTopkeywords] = useState([]);
  const [topKeywordsArray, setTopkeywordsArray] = useState([]);
  const [dialects, setDialects] = useState([]);
  const [dialectsXlxs, setDialectsXlsx] = useState([]);
  const [subDialects, setSubDialects] = useState([]);
  const [dialectsArray, setDialectsArray] = useState([]);
  const [subDialectsArray, setSubDialectsArray] = useState([]);
  const [latestInteractions, setLatestInteractions] = useState([]);
  const [interactionsArray, setInteractionsArray] = useState([]);
  const [totalInteractions, setTotalInteractions] = useState(0);
  const [avgInteractions, setAvgInteraction] = useState(0);
  const [interactions, setInteractions] = useState([]);
  const [summedInteractions, setSummedInteractions] = useState([]);
  const [sentimentOvertime, setSentimentOvertime] = useState([]);
  const [sentimentPiechart, setSentimentPiechart] = useState([]);
  const [sentimentPieArray, setSentimentPieArray] = useState([]);
  const [negativeArray, setNegativeArray] = useState([]);
  const [positiveArray, setPositiveArray] = useState([]);
  const [neutralArray, setNeutralArray] = useState([]);
  const [themeAnalysis, setThemeAnalysis] = useState([]);

  const [overviewArray, setOverviewArray] = useState([]);
  const [overview, setOverview] = useState([]);
  const [netSentiment, setNetSentiment] = useState({
    negative: 0,
    positive: 0,
    neutral: 0,
  });
  const [languagesArray, setLanguagesArray] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [gendersArray, setGendersArray] = useState([]);
  const [genders, setGenders] = useState([]);
  const [topCitiesArray, setTopCitiesArray] = useState([]);
  const [topCities, setTopCities] = useState([]);
  const [topCitiesXlxs, setTopCitiesXlxs] = useState([]);
  const [topCountriesArray, setTopCountriesArray] = useState([]);
  const [topCountries, setTopCountries] = useState([]);
  const [topCountriesXlxs, setTopCountriesXlxs] = useState([]);
  const [channelsArray, setChannelsArray] = useState([]);
  const [channels, setChannels] = useState([]);
  const [channelsXlxs, setChannelsXlxs] = useState([]);
  const [contactActivity, setContactActivity] = useState([]);
  const [minValueContactActivity, setMinValueContactActivity] = useState(0);
  const [maxValueContactActivity, setMaxValueContactActivity] = useState(0);

  const [mostEngagedContacts, setMostEngagedContacts] = useState([]);
  const [mostInfluentialContacts, setMostInfluentialContacts] = useState([]);
  useState([]);
  const [verifiedContacts, setVerifiedContacts] = useState([]);
  const [percentageVerifiedAuthors, setPercentageVerifiedAuthors] = useState(
    [],
  );
  const [summedVerifiedPercentage, setSummedVerifiedPercentage] = useState(0);
  const [iconOnlyDatasource, setIconOnlyDatasource] = useState("");

  // Values of DataSources totrack each widget preloader
  const [
    mostEngagedContactsPreLoaderDataSources,
    setMostEngagedContactsPreLoaderDataSources,
  ] = useState([]);
  const [
    mostInfluentialContactsPreLoaderDataSources,
    setMostInfluentialContactsPreLoaderDataSources,
  ] = useState([]);
  const [
    verifiedContactsPreLoaderDataSources,
    setVerifiedContactsPreLoaderDataSources,
  ] = useState([]);
  const [dialectsPreLoaderDataSources, setDialectsPreLoaderDataSources] =
    useState([]);
  const [
    interactionsPreLoaderDataSources,
    setInteractionsPreLoaderDataSources,
  ] = useState([]);
  const [
    latestInteractionsPreLoaderDataSources,
    setLatestInteractionsPreLoaderDataSources,
  ] = useState([]);
  const [
    sentimentAnalysisPreLoaderDataSources,
    setSentimentAnalysisPreLoaderDataSources,
  ] = useState([]);
  const [themeTrendPreLoaderDataSources, setThemeTrendPreLoaderDataSources] =
    useState([]);
  const [
    themeAnalysisPreLoaderDataSources,
    setThemeAnalysisPreLoaderDataSources,
  ] = useState([]);
  const [overViewPreLoaderDataSources, setOverViewPreLoaderDataSources] =
    useState([]);
  const [languagesPreLoaderDataSources, setLanguagesPreLoaderDataSources] =
    useState([]);
  const [channelsPreLoaderDataSources, setChannelsPreLoaderDataSources] =
    useState([]);

  const [gendersPreLoaderDataSources, setGendersPreLoaderDataSources] =
    useState([]);
  const [topCitiesPreLoaderDataSources, setTopCitiesPreLoaderDataSources] =
    useState([]);
  const [
    topCountriesPreLoaderDataSources,
    setTopCountriesPreLoaderDataSources,
  ] = useState([]);
  const [
    contactActivityPreLoaderDataSources,
    setContactActivityPreLoaderDataSources,
  ] = useState([]);
  const [topKeywordsPreLoaderDataSources, setTopKeywordsPreLoaderDataSources] =
    useState([]);
  const [themeData, setThemeData] = useState({});

  const { checkLuciDataIsFounded, checkLuciAllDataFounded } = LuciFunctions();

  var dialectsResult = [],
    subDialectsResult = [],
    sentimentPieResult = [],
    interactionsResult = [],
    interactionsArray2 = [],
    interactions2 = [],
    overviewResult = [],
    languagesResult = [],
    channelsResult = [],
    gendersResult = [],
    topCitiesResult = [],
    topCountriesResult = [],
    topKeywordsResult = [];

  const handleResponseFunc = async () => {
    if (response?.eventName === "dialects") {
      if (preLoaderTrackerDataSources?.includes(response?.data_source)) {
        response?.eventData.dialects?.map((i) => {
          return dialectsArray.push(i);
        });
        response?.eventData.sub_dialects?.map((i) => {
          return subDialectsArray.push(i);
        });
        handleArraySum(
          _.cloneDeep([...dialectsArray]),
          _.cloneDeep([...dialectsResult]),
          setDialectsXlsx,
        );
        handleArraySum(dialectsArray, dialectsResult, setDialects);
        handleArraySum(subDialectsArray, subDialectsResult, setSubDialects);
        setDialectsPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response?.data_source,
        ]);
      }
    } else if (response?.eventName === "latest_interactions") {
      if (preLoaderTrackerDataSources?.includes(response?.data_source)) {
        response?.eventData.latest_interactions?.map((interaction) => {
          setLatestInteractions((oldArray) => [...oldArray, interaction]);
        });
        setLatestInteractionsPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response?.data_source,
        ]);
      }
    } else if (response?.eventName === "interactions") {
      if (preLoaderTrackerDataSources?.includes(response?.data_source)) {
        response?.eventData.interactions?.map((i) => {
          return interactionsArray.push(i);
        });
        interactionsArray.map((data) => [
          interactionsArray2.push({
            name: moment.unix(Number(data.name)).utc().format("YYYY-MM-DD"),
            value: data.value,
          }),
        ]);

        handleArraySum(
          interactionsArray2,
          interactionsResult,
          setInteractions,
          true,
        );
        setInteractionsPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response?.data_source,
        ]);
      }
    } else if (response?.eventName === "sentiment") {
      if (preLoaderTrackerDataSources?.includes(response?.data_source)) {
        response?.eventData.sentiment_piechart?.map((i) => {
          sentimentPieArray.push(i);
        });
        handleArraySum(
          sentimentPieArray,
          sentimentPieResult,
          setSentimentPiechart,
        );
        Object.entries(response?.eventData.sentiment_overtime).forEach(
          ([key, value]) => {
            switch (key) {
              case "negative":
                value?.map((i) => {
                  return negativeArray.push(i);
                });
                break;
              case "positive":
                value?.map((i) => {
                  return positiveArray.push(i);
                });
                break;
              case "neutral":
                value?.map((i) => {
                  return neutralArray.push(i);
                });
                break;
              default:
                break;
            }
          },
        );
        setSentimentOvertime({
          negative: negativeArray,
          positive: positiveArray,
          neutral: neutralArray,
        });
        setSentimentAnalysisPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response?.data_source,
        ]);
      }
    } else if (response?.eventName === "themes") {
      if (preLoaderTrackerDataSources?.includes(response?.data_source)) {
        //we make this condition for themes&themes trend to contain only firstly datasource once fix them later

        setThemeAnalysis((oldArray) => [
          ...oldArray,
          response?.eventData.themes,
        ]);
        setThemeTrendPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response?.data_source,
        ]);

        setThemeAnalysisPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response?.data_source,
        ]);
      }
    } else if (response?.eventName === "overview") {
      if (preLoaderTrackerDataSources?.includes(response?.data_source)) {
        Object.entries(response?.eventData?.overview)?.map((i) => {
          if (i[0] === "net_sentiment") {
            i[1]?.map((sentiment) => {
              if (sentiment.name == "negative") {
                setNetSentiment((netSentiment) => ({
                  ...netSentiment,
                  negative: netSentiment["negative"] + sentiment.value,
                }));
              }
              if (sentiment.name == "positive") {
                setNetSentiment((netSentiment) => ({
                  ...netSentiment,
                  positive: netSentiment["positive"] + sentiment.value,
                }));
              }
              if (sentiment.name == "neutral") {
                setNetSentiment((netSentiment) => ({
                  ...netSentiment,
                  neutral: netSentiment["neutral"] + sentiment.value,
                }));
              }
            });
          } else {
            const condition = i[1] === "None" || i[1] === null || i[1] === 0;
            if (i[0] === "average_waiting_time" && condition) {
              overviewArray.push({ name: i[0], value: 0.0 });
            } else overviewArray.push({ name: i[0], value: i[1] });
          }
        });
        setOverViewPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response?.data_source,
        ]);
      }
    }
    //  else if (response?.eventName === "languages") {
    //   if (preLoaderTrackerDataSources.includes(response?.data_source)) {
    //     response?.eventData?.languages?.map((i) => {
    //       languagesArray.push(i);
    //     });
    //     handleArraySum(languagesArray, languagesResult, setLanguages);
    //     setLanguagesPreLoaderDataSources((oldArray) => [
    //       ...oldArray,
    //       response?.data_source,
    //     ]);
    //   }
    // }
    else if (response?.eventName === "channels") {
      if (preLoaderTrackerDataSources?.includes(response?.data_source)) {
        for (let [key, values] of Object.entries(
          response?.eventData?.channels,
        )) {
          channelsArray.push({ name: key, value: values });
        }
        handleArraySum(channelsArray, channelsResult, setChannels);
        handleArraySum(
          _.cloneDeep([...channelsArray]),
          _.cloneDeep([...channelsResult]),
          setChannelsXlxs,
        );
        setChannelsPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response?.data_source,
        ]);
      }
    }
    // } else if (response?.eventName === "genders") {
    //   if (preLoaderTrackerDataSources.includes(response?.data_source)) {
    //     response?.eventData.genders?.map((i) => {
    //       gendersArray.push(i);
    //     });
    //     handleArraySum(gendersArray, gendersResult, setGenders);
    //     setGendersPreLoaderDataSources((oldArray) => [
    //       ...oldArray,
    //       response?.data_source,
    //     ]);
    //   }
    else if (response?.eventName === "countries") {
      if (preLoaderTrackerDataSources.includes(response?.data_source)) {
        response?.eventData.countries?.map((i) => {
          topCountriesArray.push(i);
        });
        handleArraySum(
          _.cloneDeep([...topCountriesArray]),
          _.cloneDeep([...topCountriesResult]),
          setTopCountriesXlxs,
        );
        handleArraySum(topCountriesArray, topCountriesResult, setTopCountries);
        setTopCountriesPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response?.data_source,
        ]);
      }
    } else if (response?.eventName === "cities") {
      if (preLoaderTrackerDataSources.includes(response?.data_source)) {
        response?.eventData.cities?.map((i) => {
          topCitiesArray.push(i);
        });
        handleArraySum(
          _.cloneDeep([...topCitiesArray]),
          _.cloneDeep([...topCitiesResult]),
          setTopCitiesXlxs,
        );
        handleArraySum(topCitiesArray, topCitiesResult, setTopCities);
        setTopCitiesPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response?.data_source,
        ]);
      }
    }
    // } else if (response?.eventName === "channel_activity") {
    //   if (preLoaderTrackerDataSources.includes(response?.data_source)) {
    //     setMinValueContactActivity(
    //       response?.eventData.channel_activity.min_value_client
    //     );
    //     setMaxValueContactActivity(
    //       response?.eventData.channel_activity.max_value_client
    //     );
    //     setContactActivity(response?.eventData.channel_activity.client);

    //     setContactActivityPreLoaderDataSources((oldArray) => [
    //       ...oldArray,
    //       response?.data_source,
    //     ]);
    //   }
    // }
    //  else if (response?.eventName === "top_engaged_authors") {
    //   if (preLoaderTrackerDataSources.includes(response?.data_source)) {
    //     response?.eventData?.top_engaged_authors?.map((author) => {
    //       response?.data_source.includes("twitter")
    //         ? (author.sourceIcon = twitterIcon)
    //         : response?.data_source.includes("facebook")
    //         ? (author.sourceIcon = facebookIcon)
    //         : response?.data_source.includes("instagram")
    //         ? (author.sourceIcon = instagramIcon)
    //         : (author.sourceIcon = "");
    //       setMostEngagedContacts((oldArray) => [...oldArray, author]);
    //     });
    //     setMostEngagedContactsPreLoaderDataSources((oldArray) => [
    //       ...oldArray,
    //       response?.data_source,
    //     ]);
    //   }
    // } else if (response?.eventName === "top_influencers") {
    //   if (preLoaderTrackerDataSources.includes(response?.data_source)) {
    //     response?.eventData?.top_influencers?.map((author) => {
    //       response?.data_source.includes("twitter")
    //         ? (author.sourceIcon = twitterIcon)
    //         : response?.data_source.includes("facebook")
    //         ? (author.sourceIcon = facebookIcon)
    //         : response?.data_source.includes("instagram")
    //         ? (author.sourceIcon = instagramIcon)
    //         : (author.sourceIcon = "");
    //       setMostInfluentialContacts((oldArray) => [...oldArray, author]);
    //     });
    //     setMostInfluentialContactsPreLoaderDataSources((oldArray) => [
    //       ...oldArray,
    //       response?.data_source,
    //     ]);
    //   }
    // } else if (response?.eventName === "verified_authors") {
    //   if (preLoaderTrackerDataSources.includes(response?.data_source)) {
    //     let allVerifiedPercentage = [];
    //     allVerifiedPercentage.push(
    //       response?.eventData.verified_authors.percentage
    //     );
    //     response?.eventData.verified_authors.authors?.map((author) => {
    //       response?.data_source.includes("twitter")
    //         ? (author.sourceIcon = twitterIcon)
    //         : response?.data_source.includes("facebook")
    //         ? (author.sourceIcon = facebookIcon)
    //         : response?.data_source.includes("instagram")
    //         ? (author.sourceIcon = instagramIcon)
    //         : (author.sourceIcon = "");
    //       setVerifiedContacts((oldArray) => [...oldArray, author]);
    //     });
    //     setPercentageVerifiedAuthors(allVerifiedPercentage);
    //     setVerifiedContactsPreLoaderDataSources((oldArray) => [
    //       ...oldArray,
    //       response?.data_source,
    //     ]);
    //   }
    else if (response?.eventName === "top_keywords") {
      if (preLoaderTrackerDataSources.includes(response?.data_source)) {
        response?.eventData.top_keywords?.map((i) => {
          setTopkeywords((oldArray) => [...oldArray, i]);
        });
        setTopKeywordsPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response?.data_source,
        ]);
      }
    } else {
      return null;
    }
  };
  useEffect(() => {
    handleResponseFunc();
  }, [response]);

  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (props.resetAllFields !== 1) {
      setDialects([]);
      setSubDialects([]);
      setTopkeywords([]);
      setTopkeywordsArray([]);
      setLatestInteractions([]);
      setSentimentOvertime([]);
      setSentimentPiechart([]);
      setSentimentPieArray([]);
      setThemeAnalysis([]);
      setTotalInteractions(0);
      setAvgInteraction(0);
      setInteractionsArray([]);
      setDialectsArray([]);
      setSubDialectsArray([]);
      setNegativeArray([]);
      setPositiveArray([]);
      setNeutralArray([]);

      setOverviewArray([]);
      setOverview([]);
      setNetSentiment({
        negative: 0,
        positive: 0,
        neutral: 0,
      });
      setLanguagesArray([]);
      setLanguages([]);
      setChannelsArray([]);
      setChannels([]);
      setGendersArray([]);
      setGenders([]);
      setTopCitiesArray([]);
      setTopCities([]);
      setTopCountriesArray([]);
      setTopCountries([]);
      setContactActivity([]);
      setMinValueContactActivity([0]);
      setMaxValueContactActivity([0]);
      setMostEngagedContacts([]);
      setMostInfluentialContacts([]);
      setVerifiedContacts([]);
      setPercentageVerifiedAuthors([]);
      setSummedVerifiedPercentage(0);
      // Values of DataSources totrack each widget preloader
      setDialectsPreLoaderDataSources([]);
      setInteractionsPreLoaderDataSources([]);
      setLatestInteractionsPreLoaderDataSources([]);
      setSentimentAnalysisPreLoaderDataSources([]);
      setThemeTrendPreLoaderDataSources([]);
      setThemeAnalysisPreLoaderDataSources([]);
      setOverViewPreLoaderDataSources([]);
      setLanguagesPreLoaderDataSources([]);
      setChannelsPreLoaderDataSources([]);
      setGendersPreLoaderDataSources([]);
      setTopCountriesPreLoaderDataSources([]);
      setTopCitiesPreLoaderDataSources([]);
      setContactActivityPreLoaderDataSources([]);
      setMostEngagedContactsPreLoaderDataSources([]);
      setMostInfluentialContactsPreLoaderDataSources([]);
      setVerifiedContactsPreLoaderDataSources([]);
      setTopKeywordsPreLoaderDataSources([]);
    }
  }, [props.resetAllFields]);

  useEffect(() => {
    // Avoid Empty Array state
    let sum;
    let interactions_sum = 0;
    if (interactions.length > 0) {
      interactions.map((data) => {
        // Push data to interactions2
        interactions2.push({
          name: moment(data.name).unix(),
          value: data.value,
        });
        interactions_sum += data.value;
      });
      setSummedInteractions(interactions2);

      // Here we just need new Array of Refactor to be One Array
      setTotalInteractions(interactions_sum);
      const noOfDays = getNoOfDays(startDate, endDate);
      const avg = interactions_sum / noOfDays;
      setAvgInteraction(avg % 1 !== 0 ? avg.toFixed(2) : avg);
      //END TOTAL INTERACTIONS COUNT
    }
  }, [interactions]);

  useEffect(() => {
    handleNetSentimnet(netSentiment);
  }, [netSentiment]);

  const handleNetSentimnet = (netSentiment) => {
    let highest_sentiment = Object.keys(netSentiment).reduce(function (a, b) {
      return netSentiment[a] > netSentiment[b] ? a : b;
    });
    // Don't put any widget if it is equals to 0
    if (netSentiment[highest_sentiment] <= 0) {
      highest_sentiment = "none";
    }
    handleArraySum(
      overviewArray,
      overviewResult,
      setOverview,
      false,
      highest_sentiment,
    );
  };

  // to add all percentage values
  useEffect(() => {
    let sum = 0;
    percentageVerifiedAuthors.map((item) => {
      sum += item;
    });
    setSummedVerifiedPercentage(sum);
  }, [verifiedContacts]);

  useEffect(() => {
    if (
      _.isEqual(
        themeAnalysisPreLoaderDataSources?.sort(),
        preLoaderTrackerDataSources?.sort(),
      ) == true &&
      preLoaderTrackerDataSources.length &&
      themeAnalysisPreLoaderDataSources.length
    ) {
      aggregate(themeAnalysis, setThemeData);
    }
  }, [themeAnalysisPreLoaderDataSources]);

  const chartStatsData = [
    {
      name: "total_interactions",
      value: totalInteractions,
    },
    {
      name: "insights_avg_interactions_per_day",
      value: avgInteractions,
    },
  ];

  useEffect(() => {
    let x = checkLuciAllDataFounded(
      latestInteractionsPreLoaderDataSources,
      preLoaderTrackerDataSources,
    );
    let y = checkLuciDataIsFounded([latestInteractions]);
    props.setCheckAllDataExist(x);
    props.setCheckDataIsEmpty(y);
  }, [
    latestInteractions,
    latestInteractionsPreLoaderDataSources,
    preLoaderTrackerDataSources,
  ]);

  const [dialectsData, setDialectsData] = useState([]);
  const [subDialectsData, setSubDialectsData] = useState([]);
  let arrSubDialectsClone = [];
  useEffect(() => {
    if (
      _.isEqual(
        dialectsPreLoaderDataSources?.sort(),
        preLoaderTrackerDataSources?.sort(),
      ) == true
    ) {
      dialects?.map((item) => {
        subDialects?.map((item2) => {
          if (item?.name in dialectsSubDialectsData) {
            if (
              dialectsSubDialectsData[item?.name]?.includes(
                item2?.name?.toLowerCase(),
              )
            ) {
              arrSubDialectsClone.push(item2);
            }
          }
        });
      });
      setSubDialectsData(arrSubDialectsClone);
      setDialectsData(dialects);
    }
  }, [dialects, subDialects, dialectsPreLoaderDataSources]);

  useEffect(() => {
    let totalPreLoaderDataSouces = [
      overViewPreLoaderDataSources,
      interactionsPreLoaderDataSources,
      themeAnalysisPreLoaderDataSources,
      dialectsPreLoaderDataSources,
      channelsPreLoaderDataSources,
      topCitiesPreLoaderDataSources,
      topCountriesPreLoaderDataSources,
      topKeywordsPreLoaderDataSources,
      latestInteractionsPreLoaderDataSources,
    ];
    let result = checkIsDataFecthing(
      totalPreLoaderDataSouces,
      preLoaderTrackerDataSources,
    );
    props?.setCheckAllDataFetchingExport(result);
    let excelData = {
      preLoaderTrackerDataSources,
      overview,
      summedInteractions,
      themeData,
      dialectsXlxs,
      subDialectsData,
      channelsXlxs,
      topCitiesXlxs,
      topCountriesXlxs,
      topKeywords,
      latestInteractions,
    };
    setExcelSheetData([{ ...excelData }]);
  }, [
    preLoaderTrackerDataSources,
    overview,
    overViewPreLoaderDataSources,
    summedInteractions,
    interactionsPreLoaderDataSources,
    themeData,
    themeAnalysisPreLoaderDataSources,
    dialectsXlxs,
    subDialectsData,
    dialectsPreLoaderDataSources,
    channelsXlxs,
    channelsPreLoaderDataSources,
    topCitiesXlxs,
    topCitiesPreLoaderDataSources,
    topCountriesXlxs,
    topCountriesPreLoaderDataSources,
    topKeywords,
    topKeywordsPreLoaderDataSources,
    latestInteractions,
    latestInteractionsPreLoaderDataSources,
  ]);
  const unixDate = { start: startDate, end: endDate };
  return (
    <>
      <OverView
        data={overview}
        isSocialMedia={true}
        overViewPreLoaderDataSources={overViewPreLoaderDataSources}
        preLoaderTrackerDataSources={preLoaderTrackerDataSources}
      />
      <Box my={4}>
        <LineChartWidget
          title={"interactions"}
          titleToolTip="interactions_tooltip"
          data={summedInteractions}
          labelColor={"#2AAAE2"}
          showGroupBy={true}
          showChartDetailsWidget
          statsDetailsData={chartStatsData}
          redirectTo={"/interactions/social-media/"}
          toolTipLabel={"total_interactions"}
          showDownloadIcon
          bigImg
          lineChartPreLoaderDataSources={interactionsPreLoaderDataSources}
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Box>
      <Typography
        variant="h6"
        mt={5}
        mb={3}
        sx={{ color: "#A4A9AF", fontSize: "14px", fontWeight: 500 }}
      >
        {intl
          .formatMessage({ id: "interactions_closed_conversation" })
          .toUpperCase()}
      </Typography>
      <Box>
        {/* <StackedLineWidget
          title={"sentiment_analysis"}
          titleToolTip="sentiments_tooltip"
          data={sentimentOvertime}
          dataPiechart={sentimentPiechart}
          showGroupBy={true}
          areaStyle={true}
          toolTipLabel={"toolTipLabel"}
          showAI={false}
          hideLegend
          showDownloadIcon
          bigImg
          stackedLineWidgetPreLoaderDataSources={
            sentimentAnalysisPreLoaderDataSources
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          isSwitchCase
          showScales
          labelColors={["#89BB2A", "#E50035", "#F9A700"]}
          isSentimentAnalysis
        /> */}
      </Box>
      {/* Themes & Themes-Trend Statues CHART */}
      <Box mt={3}>
        <ThemesAnalysis
          title={"themes"}
          titleToolTip="themes_tooltip"
          data={themeData?.theme_analysis ? themeData?.theme_analysis : []}
          showAI
          showDownloadIcon
          bigImg
          themeAnalysisPreLoaderDataSources={themeAnalysisPreLoaderDataSources}
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Box>
      <Box mt={3}>
        <ThemesTrends
          title={"themes_trend"}
          titleToolTip="themes_trend_tooltip"
          data={themeData?.theme_trend ? themeData?.theme_trend : []}
          showGroupBy={false}
          showAI
          showDownloadIcon
          bigImg
          themeTrendPreLoaderDataSources={themeTrendPreLoaderDataSources}
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Box>

      <Grid container spacing={2} my={3}>
        <Grid item md={6}>
          <PieChartWidget
            title={"dialects_subdialects"}
            titleToolTip="dialects_subdialects_tooltip"
            data={dialectsData}
            subData={subDialectsData}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            showAI
            bigImg
            showDownloadIcon
            pieChartPreLoaderDataSources={dialectsPreLoaderDataSources}
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          />
        </Grid>
        <Grid item md={6}>
          <PieChartWidget
            title={"channels"}
            titleToolTip="channels_tooltip"
            data={channels}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            color={["#6D737A", "#009BDF", "#F680BC"]}
            showDownloadIcon
            bigImg
            pieChartPreLoaderDataSources={channelsPreLoaderDataSources}
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          />
        </Grid>
        {/*Note: All commented code may be use them later */}
        {/* <Grid item md={6}>
          <PieChartWidget
            title={"language_distribution"}
            titleToolTip="language_distribution_tooltip"
            data={languages}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            color={["#009BDF", "#003D59", "#45B9E9", "#97D7F3"]}
            showDownloadIcon
            pieChartPreLoaderDataSources={languagesPreLoaderDataSources}
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          />
        </Grid> */}
      </Grid>
      {/* <Box my={4} className="channel-activity">
        <PunchCardWidget
          title={"contact_activity"}
          titleToolTip="channel_activity_tooltip"
          clientActivity={contactActivity}
          minValueClient={minValueContactActivity}
          maxValueClient={maxValueContactActivity}
          showDownloadIcon
          bigImg
          authorActivityPreLoaderDataSources={
            contactActivityPreLoaderDataSources
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Box> */}

      <Grid container spacing={2} my={4}>
        {/* <Grid item md={4}>
          <PieChartWidget
            title={"genders_distribution"}
            titleToolTip="genders_tooltip"
            data={genders}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            color={["#2AAAE2", "#FF4081"]}
            chartType={"gender"}
            showDownloadIcon
            pieChartPreLoaderDataSources={gendersPreLoaderDataSources}
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          />
        </Grid> */}
        <Grid item md={6}>
          <PieChartWidget
            title={"top_cities"}
            titleToolTip="top_cities_tooltip"
            data={topCities}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            showDownloadIcon
            pieChartPreLoaderDataSources={topCitiesPreLoaderDataSources}
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          />
        </Grid>
        <Grid item md={6}>
          <PieChartWidget
            title={"top_countries"}
            titleToolTip="top_countries_tooltip"
            data={topCountries}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            showDownloadIcon
            pieChartPreLoaderDataSources={topCountriesPreLoaderDataSources}
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TableWidget
            wordsList
            title={"insights_top_keywords"}
            titleToolTip="top_kewords_tooltip"
            data={topKeywords}
            leftColomnTitle={"keywords_tab_title"}
            rightColomnTitle={"number_of_conversations"}
            tableChartPreLoaderDataSources={topKeywordsPreLoaderDataSources}
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
            keywords
            bigImg
            tablePagination
            showDownloadIcon
            count={topKeywords.length}
            socialKeywordName
            withWordCloud
          />
        </Grid>
      </Grid>

      {/* <Grid container spacing={2} my={4}>
        <Grid item xs={4}>
          <TableWidget
            itemsList
            title={"most_engaged_contacts"}
            titleToolTip="most_engaged_tooltip"
            data={mostEngagedContacts}
            leftColomnTitle={"user_author"}
            rightColomnTitle={"interactions"}
            tableChartPreLoaderDataSources={
              mostEngagedContactsPreLoaderDataSources
            }
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
            tablePagination
            bigImg
            showDownloadIcon
            count={mostEngagedContacts?.length}
            isSorted
          />
        </Grid>
        <Grid item md={4}>
          <TableWidget
            itemsList
            title={"most_influential_contacts"}
            titleToolTip="most_influential_tooltip"
            data={mostInfluentialContacts}
            leftColomnTitle={"users"}
            rightColomnTitle={"followers"}
            tableChartPreLoaderDataSources={
              mostInfluentialContactsPreLoaderDataSources
            }
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
            tablePagination
            bigImg
            showDownloadIcon
            count={mostInfluentialContacts?.length}
          />
        </Grid>

        <Grid item md={4}>
          <TableWidget
            itemsList
            title={"verified_contacts"}
            titleToolTip="verified_contacts_tooltip"
            data={verifiedContacts}
            leftColomnTitle={"users"}
            rightColomnTitle={"followers"}
            tableChartPreLoaderDataSources={
              verifiedContactsPreLoaderDataSources
            }
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
            tablePagination
            showDownloadIcon
            bigImg
            count={verifiedContacts?.length}
            statsDetailsData={[
              {
                name: "verified_contacts",
                value: verifiedContacts?.length,
              },
              {
                name: "total_accounts",
                value: summedVerifiedPercentage + "%",
                showStringValue: true,
              },
            ]}
            showChartDetailsWidget
            isVerifiedContacts
          />
        </Grid>
      </Grid> */}
      <Box my={4}>
        <InteractionsList
          showProfile={true}
          unixDate={unixDate}
          data={latestInteractions.slice(0, 10)}
          title={"latest_interactions"}
          dataSources={props.dataSources}
          isSocialMedia
          latestInteractionsPreLoaderDataSources={
            latestInteractionsPreLoaderDataSources
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          showViewMoreButton={totalInteractions > 10}
        />
      </Box>
    </>
  );
};

export default SocialMediaBody;
