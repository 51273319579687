import {
  Box,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
  Tab,
  Typography,
} from "@mui/material";
import GreetingFarewellMessage from "../components/greetingFarewellMessage";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import EditGreetingFarewellMessageModal from "./editGreetingFarewellMessageModal";
import { TabContext, TabList } from "@mui/lab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import { useIntl } from "react-intl";
import { faTriangleExclamation } from "@fortawesome/pro-regular-svg-icons";
import { greetingFarewellMessagesTaps } from "../utils/farewellGreetingUtils";
import SnackBar from "components/snackBar";
import EngagementSurvey from "./egagementSurvey/EngagementSurvey";
import greating from "images/engagements-images/survey/greating.svg";
import unavailability from "images/engagements-images/survey/unavailability.svg";
import farwell from "images/engagements-images/survey/farwell.svg";
import survey from "images/engagements-images/survey/survey.svg";

const GreetingAndFarewellBasicSwitch = ({
  title,
  description,
  checked,
  handleChange,
  injectedDesc = {},
}) => {
  let icon = {
    send_greeting_message: greating,
    send_farewell_message: farwell,
    send_unavailability_message: unavailability,
    engag_survey_tit: survey,
  };

  const intl = useIntl();
  return (
    <Box>
      <Box className="main-swich-container">
        <img src={icon[title]} />
        <FormControlLabel
          className="greeting-farewell-message-switch-form-control-label"
          classes={{ label: "greeting-farewell-message-switch-label" }}
          control={
            <Switch
              size="small"
              className="greeting-farewell-message-switch"
              checked={checked}
              onChange={handleChange}
            />
          }
          labelPlacement="start"
          label={CheckValueLocale(title, "", {}, intl)}
        />
        {title === "engag_survey_tit" ? (
          <Box className="main-swich-new-item">
            {CheckValueLocale("company_branding_new", "", {}, intl)}
          </Box>
        ) : null}
      </Box>
      <FormHelperText className="greeting-farewell-message-switch-helperText">
        {CheckValueLocale(description, "", { ...injectedDesc }, intl)}
      </FormHelperText>
    </Box>
  );
};

const GreetingAndFarewell = ({
  className,
  messagesData,
  setMessagesData,
  surveyData,
  setSurveyData,
  aiAgentActive,
}) => {
  const intl = useIntl();
  const [isEditMessageModalOpen, setIsEditMessageModalOpen] = useState(false);
  const [selectedTap, setSelectedTap] = useState("instagram");
  const [selectedItemDate, setSelectedItemDate] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState({
    success: false,
    type: "",
  });

  const greetingFarewellEnabledObject = messagesData?.farewell_greeting_enabled;
  const outsideHoursObject = messagesData?.engagements_outside_hours_settings;
  const lowerCaseSelectedTap = selectedTap?.toLowerCase();
  const upperCaseSelectedTap = selectedTap?.toUpperCase();

  const handleSwitchChange = (type) => {
    setMessagesData({
      ...messagesData,
      farewell_greeting_enabled: {
        ...greetingFarewellEnabledObject,
        [`${selectedTap}_${type}_message_enabled`]:
          !greetingFarewellEnabledObject[
            `${selectedTap}_${type}_message_enabled`
          ],
      },
    });
  };

  const handleChangeMessageText = ({ messageItem, newText }) => {
    handleSelectedMessage(messageItem?.id, messageItem?.message_type, newText);
    setIsEditMessageModalOpen(false);
    setSnackbarOpen({
      success: true,
      type: messageItem?.message_type,
    });
  };

  const handleSelectedMessage = (id, messageType, newText) => {
    const currentSelectedTapDate =
      messagesData?.farewell_greeting_messages?.find(
        (message) => message.data_source_name === upperCaseSelectedTap,
      );
    const currentSelectedTapMessages = currentSelectedTapDate?.messages;
    const updatedMessages = currentSelectedTapMessages?.map((message) => {
      if (message.id === id) {
        return {
          ...message,
          selected: message?.id === id,
          message: newText || message?.message,
        };
      }
      if (message?.message_type === messageType) {
        return {
          ...message,
          selected: false,
        };
      }
      return message;
    });

    setMessagesData({
      ...messagesData,
      farewell_greeting_messages: messagesData?.farewell_greeting_messages?.map(
        (message) => {
          if (message?.data_source_name === upperCaseSelectedTap) {
            return {
              ...message,
              messages: updatedMessages,
            };
          }
          return message;
        },
      ),
    });
  };

  const activeProducts = JSON.parse(
    window?.localStorage?.activeProducts || null,
  );
  const surveyActive = activeProducts?.find(
    (product) => product?.name == "SURVEY",
  );

  const getMessages = ({ type }) => {
    return messagesData.farewell_greeting_messages
      .find((message) => message?.data_source_name === upperCaseSelectedTap)
      ?.messages?.filter((message) => message?.message_type === type);
  };

  const isTwitterSelected = selectedTap === "twitter";
  const isWhatsAppSelected = selectedTap === "whatsapp";

  useEffect(() => {
    if (surveyData?.limit && surveyData?.limit?.length) {
      setSurveyData({ ...surveyData, limit: "" });
      setSelectedTap(surveyData?.limit?.split("_")?.[0]);
      const box = document?.getElementById("main-engagement-survey");
      if (box) {
        window.scrollTo({
          top: box.offsetTop - 100,
          behavior: "smooth",
        });
      }
    }
  }, [surveyData?.limit]);

  return (
    <Box className={`greeting-farewell-message-wrapper ${className}`}>
      <TabContext value={selectedTap}>
        <Box className="data-sources-tap-container">
          <TabList onChange={(_, newValue) => setSelectedTap(newValue)}>
            {greetingFarewellMessagesTaps(intl)?.map((tap) => (
              <Tab
                key={tap?.label}
                label={tap?.label}
                icon={<FontAwesomeIcon icon={tap?.icon} />}
                iconPosition="start"
                value={tap?.id}
              />
            ))}
          </TabList>
        </Box>
      </TabContext>
      {isWhatsAppSelected || aiAgentActive ? null : (
        <>
          <Box className={"main-grating-countainer"}>
            <GreetingAndFarewellBasicSwitch
              title="send_greeting_message"
              description="send_greeting_message_description"
              checked={
                greetingFarewellEnabledObject[
                  `${lowerCaseSelectedTap}_greeting_message_enabled`
                ]
              }
              handleChange={() => handleSwitchChange("greeting")}
            />
            {greetingFarewellEnabledObject[
              `${lowerCaseSelectedTap}_greeting_message_enabled`
            ] ? (
              <Box className="greeting-messages-farewell-background-container">
                {isTwitterSelected ? (
                  <Box className="farewell-disclaimer-container">
                    <FontAwesomeIcon
                      className="farewell-disclaimer-icon"
                      icon={faTriangleExclamation}
                    />
                    <Typography className="farewell-disclaimer-text">
                      {CheckValueLocale(
                        "farewell_x_limit_rate_disclaimer",
                        "",
                        {},
                        intl,
                      )}
                    </Typography>
                  </Box>
                ) : null}
                <Grid container alignItems="stretch" spacing="18px">
                  {getMessages({
                    type: "greeting",
                  })?.map((message) => (
                    <Grid key={message?.id} item xs={6}>
                      <GreetingFarewellMessage
                        key={message.id}
                        handleEditClick={(e) => {
                          e.stopPropagation();
                          setIsEditMessageModalOpen(true);
                          setSelectedItemDate(message);
                        }}
                        isSelected={message?.selected}
                        message={message?.message}
                        handleSelectedMessage={() => {
                          handleSelectedMessage(message?.id, "greeting");
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ) : null}
          </Box>
          <Divider className={"main-grating-countainer-divider"} />

          <Box className={"main-grating-countainer"}>
            <GreetingAndFarewellBasicSwitch
              title="send_unavailability_message"
              description="send_unavailability_message_description"
              checked={
                outsideHoursObject?.[upperCaseSelectedTap]
                  ?.after_hours_enabled || false
              }
              handleChange={(e) => {
                messagesData.engagements_outside_hours_settings[
                  upperCaseSelectedTap
                ].after_hours_enabled = e.target.checked;
                setMessagesData({ ...messagesData });
              }}
              injectedDesc={{
                teams: handlelUserRoles("GENERAL", "VIEW_USER") ? (
                  <Link
                    className="page-link"
                    to="/settings/teams"
                    target="_blank"
                  >
                    {CheckValueLocale("teams_title", "", {}, intl)}
                  </Link>
                ) : (
                  CheckValueLocale("teams_title", "", {}, intl)
                ),
              }}
            />
          </Box>
          <Divider className={"main-grating-countainer-divider"} />

          <Box className={"main-grating-countainer"}>
            <GreetingAndFarewellBasicSwitch
              title="send_farewell_message"
              description="send_farewell_message_description"
              checked={
                greetingFarewellEnabledObject[
                  `${lowerCaseSelectedTap}_farewell_message_enabled`
                ]
              }
              handleChange={() => handleSwitchChange("farewell")}
            />
            {greetingFarewellEnabledObject[
              `${lowerCaseSelectedTap}_farewell_message_enabled`
            ] ? (
              <Box className="greeting-messages-farewell-background-container">
                <Grid container alignItems="stretch" spacing="18px">
                  {getMessages({
                    type: "farewell",
                  })?.map((message) => (
                    <Grid key={message?.id} item xs={6}>
                      <GreetingFarewellMessage
                        key={message?.id}
                        handleEditClick={(e) => {
                          e.stopPropagation();
                          setIsEditMessageModalOpen(true);
                          setSelectedItemDate(message);
                        }}
                        isSelected={message?.selected}
                        message={message?.message}
                        handleSelectedMessage={() => {
                          handleSelectedMessage(message?.id, "farewell");
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ) : null}
          </Box>
          <Divider className={"main-grating-countainer-divider"} />

          {isEditMessageModalOpen ? (
            <EditGreetingFarewellMessageModal
              close={() => setIsEditMessageModalOpen(false)}
              open={isEditMessageModalOpen}
              message={selectedItemDate}
              handleChangeMessageText={handleChangeMessageText}
            />
          ) : null}
        </>
      )}
      <EngagementSurvey
        selectedTap={selectedTap}
        surveyData={surveyData}
        setSurveyData={setSurveyData}
        GreetingAndFarewellBasicSwitch={GreetingAndFarewellBasicSwitch}
      />
      {snackbarOpen?.success ? (
        <SnackBar
          open={snackbarOpen?.success}
          handleClose={() => {
            setSnackbarOpen({
              success: false,
              message: "",
              type: "",
            });
          }}
          severity="success"
          message={
            <Box className="ai-agent-snackBar-message">
              {CheckValueLocale(
                `${
                  snackbarOpen?.type && snackbarOpen?.type === "greeting"
                    ? "greeting"
                    : "farewell"
                }_message_edited_successfully`,
                "",
                {},
                intl,
              )}
            </Box>
          }
        />
      ) : null}
    </Box>
  );
};

export default GreetingAndFarewell;
