import { AppBar, Button, Toolbar, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import LucButton from "shared/lucButton/lucButton";
import { CheckValueLocale } from "utils/helpers";

const SaveChangesFooter = (props) => {
  const intl = useIntl();
  const {
    setUpdatedParams,
    updatedParams,
    errorKeywordsMsg,
    errorExKeywordsMsg,
    displayNameMsgError,
    handleTopicsData,
    monitorTopics,
    monitorErrors,
    nbExeedLimit,
    twitterWordsExceedLimit,
    selectedSource,
    majorSource,
    disableSave,
  } = props;
  const number = Object.keys(updatedParams).length;
  let checkAllData =
    updatedParams?.keywords?.TALKWALKER === "" || //save will not work if Keywords is empty
    updatedParams?.keywords?.TWITTER === "" || //save will not work if Keywords is empty
    updatedParams?.keywords?.FACEBOOK === "" || //save will not work if Keywords is empty
    updatedParams?.keywords?.INSTAGRAM === "" || //save will not work if Keywords is empty
    updatedParams?.account_name === "" || //save will not work if account_name is empty
    !handleTopicsData(monitorTopics) || // save will not work in case of invalid topic
    errorKeywordsMsg.errorMsg !== "" ||
    errorExKeywordsMsg?.errorMsg !== "" ||
    displayNameMsgError?.errorMsg !== "" ||
    nbExeedLimit ||
    twitterWordsExceedLimit ||
    (selectedSource === "major_sources" && majorSource?.length === 0) ||
    disableSave ||
    !Object.values(monitorErrors).every((str) => str === "");

  const handleSaveBtn = () => {
    if (checkAllData) {
      return false;
    } else {
      props.handleSaveSettings();
    }
  };

  return (
    <AppBar className="save-footer">
      <Toolbar className="save-footer-toolbar">
        <LucButton
          onClick={handleSaveBtn}
          id="monitor-options-save-monitor-settings-btn"
          disabled={checkAllData || props?.isLoading}
          loading={props?.isLoading}
        >
          {CheckValueLocale("save", "", {}, intl)}
        </LucButton>

        <LucButton
          className="cancel-btn"
          variant="flat"
          type={"secondary"}
          onClick={() => {
            setUpdatedParams({});
            window.location.reload();
          }}
          id="monitor-options-cancel-monitor-settings-btn"
        >
          {CheckValueLocale("cancel", "", {}, intl)}
        </LucButton>

        <Typography>
          {CheckValueLocale(
            "you_have_changed_setting",
            "",
            { number: number },
            intl,
          )}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default SaveChangesFooter;
