import React from "react";
import IntlTelInput from "react-intl-tel-input";
import { Box, FormControl } from "@mui/material";

const PhoneNumberQuestion = (props) => {
  const { question, previewMode } = props;

  return (
    <Box className="survey-builder-question-phone-number phone-number-question editor-question">
      <FormControl fullWidth>
        <IntlTelInput
          containerClassName="intl-tel-input"
          inputClassName="form-control react-tel-input phoneInput"
          separateDialCode
          defaultCountry={question?.constraints?.country_code?.toLowerCase()}
          excludeCountries={["il"]}
          fieldName="phone_number"
          placeholder=""
          disabled={!previewMode}
        />
      </FormControl>
    </Box>
  );
};

export default PhoneNumberQuestion;
