import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Typography, Grid, Box } from "@mui/material";
import LineChartWidget from "components/widgets/lineChartWidget";
import StackedLineWidget from "components/widgets/stackedLineWidget.js";
import PieChartWidget from "components/widgets/pieChartWidget.js";
import InteractionsList from "components/interactions-list/InteractionsList.js";
import ThemesAnalysis from "components/widgets/themes/themeAnalysis/ThemeAnalysis";
import ThemesTrends from "components/widgets/themes/themesTrends/ThemesTrends";
import ActiveFilters from "shared/channel-analytics-filter/activeFilters/activeFilters";

import OverView from "components/widgets/overViewWidget.js";
import PunchCardWidget from "components/widgets/punchCardWidget";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import moment from "moment";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { getNoOfDays } from "utils/helpers";
import Genesys from "../../../../images/shared-images/genesys-icon.svg";

import _ from "lodash";
import { Margin } from "@mui/icons-material";
import xPlatform from "images/social-icons/x-platform-icon.svg";

const CallsBody = (props) => {
  const intl = useIntl();
  let { response, preLoaderTrackerDataSources, startDate, endDate } = props;

  const [topKeywords, setTopkeywords] = useState([]);
  const [topKeywordsArray, setTopkeywordsArray] = useState([]);
  const [dialects, setDialects] = useState([]);
  const [subDialects, setSubDialects] = useState([]);
  const [dialectsArray, setDialectsArray] = useState([]);
  const [subDialectsArray, setSubDialectsArray] = useState([]);
  const [latestInteractions, setLatestInteractions] = useState([]);
  const [interactionsArray, setInteractionsArray] = useState([]);
  const [totalInteractions, setTotalInteractions] = useState(0);
  const [avgInteractions, setAvgInteraction] = useState(0);
  const [interactions, setInteractions] = useState([]);
  const [summedInteractions, setSummedInteractions] = useState({});
  const [sentimentOvertime, setSentimentOvertime] = useState([]);
  const [sentimentPiechart, setSentimentPiechart] = useState([]);
  const [sentimentPieArray, setSentimentPieArray] = useState([]);
  const [negativeArray, setNegativeArray] = useState([]);
  const [positiveArray, setPositiveArray] = useState([]);
  const [neutralArray, setNeutralArray] = useState([]);
  const [themeAnalysis, setThemeAnalysis] = useState([]);

  const [overviewArray, setOverviewArray] = useState([]);
  const [overview, setOverview] = useState([]);
  const [netSentiment, setNetSentiment] = useState({
    negative: 0,
    positive: 0,
    neutral: 0,
  });
  const [languagesArray, setLanguagesArray] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [gendersArray, setGendersArray] = useState([]);
  const [genders, setGenders] = useState([]);
  const [topCitiesArray, setTopCitiesArray] = useState([]);
  const [topCities, setTopCities] = useState([]);
  const [topCountriesArray, setTopCountriesArray] = useState([]);
  const [topCountries, setTopCountries] = useState([]);
  const [channelsArray, setChannelsArray] = useState([]);
  const [channels, setChannels] = useState([]);
  const [contactActivity, setContactActivity] = useState([]);
  const [minValueContactActivity, setMinValueContactActivity] = useState(0);
  const [maxValueContactActivity, setMaxValueContactActivity] = useState(0);

  const [mostEngagedContacts, setMostEngagedContacts] = useState([]);
  const [mostInfluentialContacts, setMostInfluentialContacts] = useState([]);
  useState([]);
  const [verifiedContacts, setVerifiedContacts] = useState([]);
  const [percentageVerifiedAuthors, setPercentageVerifiedAuthors] = useState(
    [],
  );
  const [summedVerifiedPercentage, setSummedVerifiedPercentage] = useState(0);
  const [iconOnlyDatasource, setIconOnlyDatasource] = useState("");

  // Values of DataSources totrack each widget preloader
  const [
    mostEngagedContactsPreLoaderDataSources,
    setMostEngagedContactsPreLoaderDataSources,
  ] = useState([]);
  const [
    mostInfluentialContactsPreLoaderDataSources,
    setMostInfluentialContactsPreLoaderDataSources,
  ] = useState([]);
  const [
    verifiedContactsPreLoaderDataSources,
    setVerifiedContactsPreLoaderDataSources,
  ] = useState([]);
  const [dialectsPreLoaderDataSources, setDialectsPreLoaderDataSources] =
    useState([]);
  const [
    interactionsPreLoaderDataSources,
    setInteractionsPreLoaderDataSources,
  ] = useState([]);
  const [
    latestInteractionsPreLoaderDataSources,
    setLatestInteractionsPreLoaderDataSources,
  ] = useState([]);
  const [
    sentimentAnalysisPreLoaderDataSources,
    setSentimentAnalysisPreLoaderDataSources,
  ] = useState([]);
  const [themeTrendPreLoaderDataSources, setThemeTrendPreLoaderDataSources] =
    useState([]);
  const [
    themeAnalysisPreLoaderDataSources,
    setThemeAnalysisPreLoaderDataSources,
  ] = useState([]);
  const [overViewPreLoaderDataSources, setOverViewPreLoaderDataSources] =
    useState([]);
  const [languagesPreLoaderDataSources, setLanguagesPreLoaderDataSources] =
    useState([]);
  const [channelsPreLoaderDataSources, setChannelsPreLoaderDataSources] =
    useState([]);

  const [gendersPreLoaderDataSources, setGendersPreLoaderDataSources] =
    useState([]);
  const [topCitiesPreLoaderDataSources, setTopCitiesPreLoaderDataSources] =
    useState([]);
  const [
    topCountriesPreLoaderDataSources,
    setTopCountriesPreLoaderDataSources,
  ] = useState([]);
  const [
    contactActivityPreLoaderDataSources,
    setContactActivityPreLoaderDataSources,
  ] = useState([]);
  const [topKeywordsPreLoaderDataSources, setTopKeywordsPreLoaderDataSources] =
    useState([]);

  var dialectsResult = [],
    subDialectsResult = [],
    sentimentPieResult = [],
    interactionsResult = [],
    interactionsArray2 = [],
    interactions2 = [],
    overviewResult = [],
    languagesResult = [],
    channelsResult = [],
    gendersResult = [],
    topCitiesResult = [],
    topCountriesResult = [],
    updatedPreLoaderTracker = [...preLoaderTrackerDataSources],
    topKeywordsResult = [];

  const index = updatedPreLoaderTracker.indexOf("facebook_public");
  if (index !== -1) updatedPreLoaderTracker.splice(index, 1);

  //datasources
  const twitterIcon = (
    <>
      <img
        src={xPlatform}
        alt="x-platform-logo"
        className="icon-after-title twitter-icon twitter-datasource-icon"
      />
    </>
  );

  const facebookIcon = (
    <>
      <FontAwesomeIcon
        className="icon-after-title facebook facebook-datasource-icon"
        icon={faFacebook}
      />
    </>
  );

  const instagramIcon = (
    <>
      <InstagramIcon className="icon-after-title instagram instagram-datasource-icon" />
    </>
  );

  const linkedInIcon = (
    <>
      <FontAwesomeIcon
        className="icon-after-title linkedIn linkedIn-datasource-icon"
        icon={faLinkedin}
      />
    </>
  );

  //make condition to show datasources dynamic
  let activeDatasourcesIcons = [];
  if (
    preLoaderTrackerDataSources?.includes("twitter_private") ||
    preLoaderTrackerDataSources?.includes("twitter_public")
  ) {
    activeDatasourcesIcons.push(twitterIcon);
  }
  if (
    preLoaderTrackerDataSources?.includes("facebook_private") ||
    preLoaderTrackerDataSources?.includes("facebook_public")
  ) {
    activeDatasourcesIcons.push(facebookIcon);
  }
  if (
    preLoaderTrackerDataSources?.includes("instagram_private") ||
    preLoaderTrackerDataSources?.includes("instagram_public")
  ) {
    activeDatasourcesIcons.push(instagramIcon);
  }
  if (props.preLoaderTrackerDataSources?.includes("linkedin_public")) {
    activeDatasourcesIcons.push(linkedInIcon);
  }

  const genesysIcons = (
    <img src={Genesys} alt="icon-genesys" width={"20px"} height={"17px"} />
  );

  // useEffect(() => {
  //   if (response?.eventName === "dialects") {
  //     if (preLoaderTrackerDataSources?.includes(response?.data_source)) {
  //       response?.eventData.dialects?.map((i) => {
  //         return dialectsArray.push(i);
  //       });
  //       response?.eventData.dialects?.map((dialect) => {
  //         dialect.sub_dialects?.map((sub) => {
  //           return subDialectsArray.push(sub);
  //         });
  //       });
  //       handleArraySum(dialectsArray, dialectsResult, setDialects);
  //       handleArraySum(subDialectsArray, subDialectsResult, setSubDialects);
  //       setDialectsPreLoaderDataSources((oldArray) => [
  //         ...oldArray,
  //         response?.data_source,
  //       ]);
  //     }
  //   } else if (response?.eventName === "latest_interactions") {
  //     if (preLoaderTrackerDataSources?.includes(response?.data_source)) {
  //       response?.eventData.latest_interactions?.map((interaction) => {
  //         setLatestInteractions((oldArray) => [...oldArray, interaction]);
  //       });
  //       setLatestInteractionsPreLoaderDataSources((oldArray) => [
  //         ...oldArray,
  //         response?.data_source,
  //       ]);
  //     }
  //   } else if (response?.eventName === "interactions") {
  //     if (preLoaderTrackerDataSources?.includes(response?.data_source)) {
  //       response?.eventData.interactions?.map((i) => {
  //         return interactionsArray.push(i);
  //       });
  //       interactionsArray.map((data) => [
  //         interactionsArray2.push({
  //           name: data.name,
  //           value: data.value,
  //         }),
  //       ]);
  //       handleArraySum(
  //         interactionsArray2,
  //         interactionsResult,
  //         setInteractions,
  //         true
  //       );
  //       setInteractionsPreLoaderDataSources((oldArray) => [
  //         ...oldArray,
  //         response?.data_source,
  //       ]);
  //     }
  //   } else if (response?.eventName === "sentiment") {
  //     if (preLoaderTrackerDataSources?.includes(response?.data_source)) {
  //       response?.eventData.sentiment_piechart?.map((i) => {
  //         sentimentPieArray.push(i);
  //       });
  //       handleArraySum(
  //         sentimentPieArray,
  //         sentimentPieResult,
  //         setSentimentPiechart
  //       );
  //       Object.entries(response?.eventData.sentiment_overtime).forEach(
  //         ([key, value]) => {
  //           switch (key) {
  //             case "negative":
  //               value?.map((i) => {
  //                 return negativeArray.push(i);
  //               });
  //               break;
  //             case "positive":
  //               value?.map((i) => {
  //                 return positiveArray.push(i);
  //               });
  //               break;
  //             case "neutral":
  //               value?.map((i) => {
  //                 return neutralArray.push(i);
  //               });
  //               break;
  //             default:
  //               break;
  //           }
  //         }
  //       );
  //       setSentimentOvertime({
  //         negative: negativeArray,
  //         positive: positiveArray,
  //         neutral: neutralArray,
  //       });
  //       setSentimentAnalysisPreLoaderDataSources((oldArray) => [
  //         ...oldArray,
  //         response?.data_source,
  //       ]);
  //     }
  //   } else if (response?.eventName === "themes") {
  //     if (preLoaderTrackerDataSources?.includes(response?.data_source)) {
  //       //we make this condition for themes&themes trend to contain only firstly datasource once fix them later

  //       setThemeAnalysis((oldArray) => [
  //         ...oldArray,
  //         response?.eventData.themes,
  //       ]);
  //       setThemeTrendPreLoaderDataSources((oldArray) => [
  //         ...oldArray,
  //         response?.data_source,
  //       ]);

  //       setThemeAnalysisPreLoaderDataSources((oldArray) => [
  //         ...oldArray,
  //         response?.data_source,
  //       ]);
  //     }
  //   } else if (response?.eventName === "overview") {
  //     if (preLoaderTrackerDataSources?.includes(response?.data_source)) {
  //       Object.entries(response?.eventData?.overview)?.map((i) => {
  //         if (i[0] === "net_sentiment") {
  //           i[1]?.map((sentiment) => {
  //             if (sentiment.name == "negative") {
  //               setNetSentiment((netSentiment) => ({
  //                 ...netSentiment,
  //                 negative: netSentiment["negative"] + sentiment.value,
  //               }));
  //             }
  //             if (sentiment.name == "positive") {
  //               setNetSentiment((netSentiment) => ({
  //                 ...netSentiment,
  //                 positive: netSentiment["positive"] + sentiment.value,
  //               }));
  //             }
  //             if (sentiment.name == "neutral") {
  //               setNetSentiment((netSentiment) => ({
  //                 ...netSentiment,
  //                 neutral: netSentiment["neutral"] + sentiment.value,
  //               }));
  //             }
  //           });
  //         } else {
  //           const condition = i[1] === "None" || i[1] === null || i[1] === 0;
  //           if (i[0] === "average_waiting_time" && condition) {
  //             overviewArray.push({ name: i[0], value: 0.0 });
  //           } else overviewArray.push({ name: i[0], value: i[1] });
  //         }
  //       });
  //       setOverViewPreLoaderDataSources((oldArray) => [
  //         ...oldArray,
  //         response?.data_source,
  //       ]);
  //     }
  //   }
  //   //  else if (response?.eventName === "languages") {
  //   //   if (preLoaderTrackerDataSources.includes(response?.data_source)) {
  //   //     response?.eventData?.languages?.map((i) => {
  //   //       languagesArray.push(i);
  //   //     });
  //   //     handleArraySum(languagesArray, languagesResult, setLanguages);
  //   //     setLanguagesPreLoaderDataSources((oldArray) => [
  //   //       ...oldArray,
  //   //       response?.data_source,
  //   //     ]);
  //   //   }
  //   // }
  //   else if (response?.eventName === "channels") {
  //     if (preLoaderTrackerDataSources?.includes(response?.data_source)) {
  //       for (let [key, values] of Object.entries(
  //         response?.eventData?.channels
  //       )) {
  //         channelsArray.push({ name: key, value: values });
  //       }
  //       handleArraySum(channelsArray, channelsResult, setChannels);
  //       setChannelsPreLoaderDataSources((oldArray) => [
  //         ...oldArray,
  //         response?.data_source,
  //       ]);
  //     }
  //   }
  //   // } else if (response?.eventName === "genders") {
  //   //   if (preLoaderTrackerDataSources.includes(response?.data_source)) {
  //   //     response?.eventData.genders?.map((i) => {
  //   //       gendersArray.push(i);
  //   //     });
  //   //     handleArraySum(gendersArray, gendersResult, setGenders);
  //   //     setGendersPreLoaderDataSources((oldArray) => [
  //   //       ...oldArray,
  //   //       response?.data_source,
  //   //     ]);
  //   //   }
  //   else if (response?.eventName === "countries") {
  //     if (preLoaderTrackerDataSources.includes(response?.data_source)) {
  //       response?.eventData.countries?.map((i) => {
  //         topCountriesArray.push(i);
  //       });
  //       handleArraySum(topCountriesArray, topCountriesResult, setTopCountries);
  //       setTopCountriesPreLoaderDataSources((oldArray) => [
  //         ...oldArray,
  //         response?.data_source,
  //       ]);
  //     }
  //   } else if (response?.eventName === "cities") {
  //     if (preLoaderTrackerDataSources.includes(response?.data_source)) {
  //       response?.eventData.cities?.map((i) => {
  //         topCitiesArray.push(i);
  //       });
  //       handleArraySum(topCitiesArray, topCitiesResult, setTopCities);
  //       setTopCitiesPreLoaderDataSources((oldArray) => [
  //         ...oldArray,
  //         response?.data_source,
  //       ]);
  //     }
  //   }
  //   // } else if (response?.eventName === "channel_activity") {
  //   //   if (preLoaderTrackerDataSources.includes(response?.data_source)) {
  //   //     setMinValueContactActivity(
  //   //       response?.eventData.channel_activity.min_value_client
  //   //     );
  //   //     setMaxValueContactActivity(
  //   //       response?.eventData.channel_activity.max_value_client
  //   //     );
  //   //     setContactActivity(response?.eventData.channel_activity.client);

  //   //     setContactActivityPreLoaderDataSources((oldArray) => [
  //   //       ...oldArray,
  //   //       response?.data_source,
  //   //     ]);
  //   //   }
  //   // }
  //   //  else if (response?.eventName === "top_engaged_authors") {
  //   //   if (preLoaderTrackerDataSources.includes(response?.data_source)) {
  //   //     response?.eventData?.top_engaged_authors?.map((author) => {
  //   //       response?.data_source.includes("twitter")
  //   //         ? (author.sourceIcon = twitterIcon)
  //   //         : response?.data_source.includes("facebook")
  //   //         ? (author.sourceIcon = facebookIcon)
  //   //         : response?.data_source.includes("instagram")
  //   //         ? (author.sourceIcon = instagramIcon)
  //   //         : (author.sourceIcon = "");
  //   //       setMostEngagedContacts((oldArray) => [...oldArray, author]);
  //   //     });
  //   //     setMostEngagedContactsPreLoaderDataSources((oldArray) => [
  //   //       ...oldArray,
  //   //       response?.data_source,
  //   //     ]);
  //   //   }
  //   // } else if (response?.eventName === "top_influencers") {
  //   //   if (preLoaderTrackerDataSources.includes(response?.data_source)) {
  //   //     response?.eventData?.top_influencers?.map((author) => {
  //   //       response?.data_source.includes("twitter")
  //   //         ? (author.sourceIcon = twitterIcon)
  //   //         : response?.data_source.includes("facebook")
  //   //         ? (author.sourceIcon = facebookIcon)
  //   //         : response?.data_source.includes("instagram")
  //   //         ? (author.sourceIcon = instagramIcon)
  //   //         : (author.sourceIcon = "");
  //   //       setMostInfluentialContacts((oldArray) => [...oldArray, author]);
  //   //     });
  //   //     setMostInfluentialContactsPreLoaderDataSources((oldArray) => [
  //   //       ...oldArray,
  //   //       response?.data_source,
  //   //     ]);
  //   //   }
  //   // } else if (response?.eventName === "verified_authors") {
  //   //   if (preLoaderTrackerDataSources.includes(response?.data_source)) {
  //   //     let allVerifiedPercentage = [];
  //   //     allVerifiedPercentage.push(
  //   //       response?.eventData.verified_authors.percentage
  //   //     );
  //   //     response?.eventData.verified_authors.authors?.map((author) => {
  //   //       response?.data_source.includes("twitter")
  //   //         ? (author.sourceIcon = twitterIcon)
  //   //         : response?.data_source.includes("facebook")
  //   //         ? (author.sourceIcon = facebookIcon)
  //   //         : response?.data_source.includes("instagram")
  //   //         ? (author.sourceIcon = instagramIcon)
  //   //         : (author.sourceIcon = "");
  //   //       setVerifiedContacts((oldArray) => [...oldArray, author]);
  //   //     });
  //   //     setPercentageVerifiedAuthors(allVerifiedPercentage);
  //   //     setVerifiedContactsPreLoaderDataSources((oldArray) => [
  //   //       ...oldArray,
  //   //       response?.data_source,
  //   //     ]);
  //   //   }
  //   else if (response?.eventName === "top_keywords") {
  //     if (preLoaderTrackerDataSources.includes(response?.data_source)) {
  //       response?.eventData.top_keywords?.map((i) => {
  //         setTopkeywords((oldArray) => [...oldArray, i]);
  //       });
  //       setTopKeywordsPreLoaderDataSources((oldArray) => [
  //         ...oldArray,
  //         response?.data_source,
  //       ]);
  //     }
  //   } else {
  //     return null;
  //   }
  // }, [response]);

  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (props.resetAllFields !== 1) {
      setDialects([]);
      setSubDialects([]);
      setTopkeywords([]);
      setTopkeywordsArray([]);
      setLatestInteractions([]);
      setSentimentOvertime([]);
      setSentimentPiechart([]);
      setSentimentPieArray([]);
      setThemeAnalysis([]);
      setTotalInteractions(0);
      setAvgInteraction(0);
      setInteractionsArray([]);
      setDialectsArray([]);
      setSubDialectsArray([]);
      setNegativeArray([]);
      setPositiveArray([]);
      setNeutralArray([]);

      setOverviewArray([]);
      setOverview([]);
      setNetSentiment({
        negative: 0,
        positive: 0,
        neutral: 0,
      });
      setLanguagesArray([]);
      setLanguages([]);
      setChannelsArray([]);
      setChannels([]);
      setGendersArray([]);
      setGenders([]);
      setTopCitiesArray([]);
      setTopCities([]);
      setTopCountriesArray([]);
      setTopCountries([]);
      setContactActivity([]);
      setMinValueContactActivity([0]);
      setMaxValueContactActivity([0]);
      setMostEngagedContacts([]);
      setMostInfluentialContacts([]);
      setVerifiedContacts([]);
      setPercentageVerifiedAuthors([]);
      setSummedVerifiedPercentage(0);
      // Values of DataSources totrack each widget preloader
      setDialectsPreLoaderDataSources([]);
      setInteractionsPreLoaderDataSources([]);
      setLatestInteractionsPreLoaderDataSources([]);
      setSentimentAnalysisPreLoaderDataSources([]);
      setThemeTrendPreLoaderDataSources([]);
      setThemeAnalysisPreLoaderDataSources([]);
      setOverViewPreLoaderDataSources([]);
      setLanguagesPreLoaderDataSources([]);
      setChannelsPreLoaderDataSources([]);
      setGendersPreLoaderDataSources([]);
      setTopCountriesPreLoaderDataSources([]);
      setTopCitiesPreLoaderDataSources([]);
      setContactActivityPreLoaderDataSources([]);
      setMostEngagedContactsPreLoaderDataSources([]);
      setMostInfluentialContactsPreLoaderDataSources([]);
      setVerifiedContactsPreLoaderDataSources([]);
      setTopKeywordsPreLoaderDataSources([]);
    }
  }, [props.resetAllFields]);

  useEffect(() => {
    // Avoid Empty Array state
    let sum;
    let interactions_sum = 0;
    if (interactions.length > 0) {
      interactions.map((data) => {
        // Push data to interactions2
        interactions2.push({
          name: moment(data.name).unix(),
          value: data.value,
        });
        interactions_sum += data.value;
      });
      setSummedInteractions(interactions2);

      // Here we just need new Array of Refactor to be One Array
      setTotalInteractions(interactions_sum);
      const noOfDays = getNoOfDays(startDate, endDate);
      const avg = interactions_sum / noOfDays;
      setAvgInteraction(avg % 1 !== 0 ? avg.toFixed(2) : avg);
      //END TOTAL INTERACTIONS COUNT
    }
  }, [interactions]);

  useEffect(() => {
    handleNetSentimnet(netSentiment);
  }, [netSentiment]);

  const handleNetSentimnet = (netSentiment) => {
    let highest_sentiment = Object.keys(netSentiment).reduce(function (a, b) {
      return netSentiment[a] > netSentiment[b] ? a : b;
    });
    // Don't put any widget if it is equals to 0
    if (netSentiment[highest_sentiment] <= 0) {
      highest_sentiment = "none";
    }
    // handleArraySum(
    //   overviewArray,
    //   overviewResult,
    //   setOverview,
    //   false,
    //   highest_sentiment
    // );
  };

  // to add all percentage values
  useEffect(() => {
    let sum = 0;
    percentageVerifiedAuthors.map((item) => {
      sum += item;
    });
    setSummedVerifiedPercentage(sum);
  }, [verifiedContacts]);

  useEffect(() => {
    if (
      _.isEqual(
        themeAnalysisPreLoaderDataSources?.sort(),
        preLoaderTrackerDataSources?.sort(),
      ) == true
    ) {
      // aggregate(themeAnalysis);
    }
  }, [themeAnalysisPreLoaderDataSources]);

  return (
    <>
      {/* <ActiveFilters
        assignActiveFilters={props.assignActiveFilters}
        startDate={props.startDate}
        endDate={props.endDate}
        applyFilter={props.applyFilter}
        allActiveFilters={props.allActiveFilters}
        filterFields={props.filterFields}
        socialMediaFilter={true}
      /> */}
      <OverView
        //data={overview}
        isGenesysPage={true}
        //overViewPreLoaderDataSources={overViewPreLoaderDataSources}
        //preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        data={[]}
        overViewPreLoaderDataSources={[1]}
        preLoaderTrackerDataSources={[1]}
      />
      <Typography
        variant="h6"
        mt={5}
        mb={3}
        sx={{ color: "#A4A9AF", fontSize: "14px", fontWeight: 500 }}
      >
        {intl.formatMessage({ id: "interactions_overview" }).toUpperCase()}
      </Typography>
      <Box my={4}>
        <LineChartWidget
          title={"interactions"}
          titleToolTip="interaction_linkedin_tooltip"
          data={[]}
          labelColor={"#2AAAE2"}
          showGroupBy={true}
          showChartDetailsWidget
          statsDetailsData={[]}
          redirectTo={"/interactions/social-media/"}
          toolTipLabel={"total_interactions"}
          showDownloadIcon
          bigImg
          //lineChartPreLoaderDataSources={interactionsPreLoaderDataSources}
          //preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          lineChartPreLoaderDataSources={[1]}
          preLoaderTrackerDataSources={[1]}
          iconAfterTitle={genesysIcons}
        />
      </Box>
      <Box my={4}>
        <StackedLineWidget
          title={"calls_sentiment_analysis"}
          titleToolTip="sentiments_genesys_tooltip"
          data={[]}
          dataPiechart={[]}
          showGroupBy={true}
          areaStyle={true}
          toolTipLabel={"toolTipLabel"}
          showAI={false}
          hideLegend
          showDownloadIcon
          bigImg
          stackedLineWidgetPreLoaderDataSources={[1]}
          preLoaderTrackerDataSources={[1]}
          isSwitchCase
          iconAfterTitle={genesysIcons}
          showScales
          labelColors={["#89BB2A", "#E50035", "#F9A700"]}
          isSentimentAnalysis
          isSentimentAnalysisShowCharts
        />
      </Box>
      {/* Themes & Themes-Trend Statues CHART */}
      <Box mt={3}>
        <ThemesAnalysis
          title={"themes"}
          titleToolTip="themes_genesys_tooltip"
          //data={output?.theme_analysis ? output?.theme_analysis : []}
          data={[]}
          showAI
          showDownloadIcon
          bigImg
          iconAfterTitle={genesysIcons}
          // themeAnalysisPreLoaderDataSources={themeAnalysisPreLoaderDataSources}
          // preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          themeAnalysisPreLoaderDataSources={[1]}
          preLoaderTrackerDataSources={[1]}
        />
      </Box>
      <Box mt={3}>
        <ThemesTrends
          title={"themes_trend"}
          titleToolTip="themes_trend_genesys_tooltip"
          //data={output?.theme_trend ? output?.theme_trend : []}
          data={[]}
          showGroupBy={false}
          showAI
          showDownloadIcon
          bigImg
          iconAfterTitle={genesysIcons}
          // themeTrendPreLoaderDataSources={themeTrendPreLoaderDataSources}
          // preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          themeTrendPreLoaderDataSources={[1]}
          preLoaderTrackerDataSources={[1]}
        />
      </Box>

      <Grid container spacing={2} my={3}>
        <Grid item md={6}>
          <PieChartWidget
            title={"channel_accounts"}
            titleToolTip="channel_genesys_tooltip"
            //data={channels}
            data={[]}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            color={["#6D737A", "#009BDF", "#F680BC"]}
            showDownloadIcon
            bigImg
            iconAfterTitle={genesysIcons}
            // pieChartPreLoaderDataSources={channelsPreLoaderDataSources}
            // preLoaderTrackerDataSources={preLoaderTrackerDataSources}
            pieChartPreLoaderDataSources={[1]}
            preLoaderTrackerDataSources={[1]}
          />
        </Grid>
        <Grid item md={6}>
          <PieChartWidget
            title={"dialects_subdialects"}
            titleToolTip="dialects_subdialects_genesys_tooltip"
            // data={dialects}
            // subData={subDialects}
            data={[]}
            subData={[]}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            showAI
            bigImg
            showDownloadIcon
            iconAfterTitle={genesysIcons}
            // pieChartPreLoaderDataSources={dialectsPreLoaderDataSources}
            // preLoaderTrackerDataSources={preLoaderTrackerDataSources}
            pieChartPreLoaderDataSources={[1]}
            preLoaderTrackerDataSources={[1]}
          />
        </Grid>
      </Grid>
      <Box my={4} className="channel-activity">
        <PunchCardWidget
          title={"contact_activity"}
          titleToolTip="channel_activity_genesys_tooltip"
          // clientActivity={contactActivity}
          clientActivity={[]}
          // minValueClient={minValueContactActivity}
          // maxValueClient={maxValueContactActivity}
          minValueClient={5}
          maxValueClient={13}
          showDownloadIcon
          bigImg
          // authorActivityPreLoaderDataSources={
          //   contactActivityPreLoaderDataSources
          // }
          // preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          authorActivityPreLoaderDataSources={[1]}
          preLoaderTrackerDataSources={[1]}
          iconAfterTitle={genesysIcons}
        />
      </Box>
      <Typography
        variant="h6"
        mt={5}
        mb={3}
        sx={{ color: "#A4A9AF", fontSize: "14px", fontWeight: 500 }}
      >
        {intl
          .formatMessage({ id: "interactions_in_depth_analysis" })
          .toUpperCase()}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TableWidget
            wordsList
            title={"insights_top_keywords"}
            titleToolTip="top_kewords_genesys_tooltip"
            // data={topKeywords}
            data={[]}
            leftColomnTitle={"keywords_tab_title"}
            rightColomnTitle={"number_of_mentions"}
            // tableChartPreLoaderDataSources={topKeywordsPreLoaderDataSources}
            // preLoaderTrackerDataSources={preLoaderTrackerDataSources}
            tableChartPreLoaderDataSources={[1]}
            preLoaderTrackerDataSources={[1]}
            keywords
            bigImg
            tablePagination
            showDownloadIcon
            // count={topKeywords.length}
            count={0}
            iconAfterTitle={genesysIcons}
            socialKeywordName
          />
        </Grid>
      </Grid>
      <Typography
        variant="h6"
        mt={5}
        mb={3}
        sx={{ color: "#A4A9AF", fontSize: "14px", fontWeight: 500 }}
      >
        {intl.formatMessage({ id: "contact_overview_linkedin" }).toUpperCase()}
      </Typography>
      <Box my={4}>
        <LineChartWidget
          title={"contacts_trend_linkedin"}
          titleToolTip="contacts_trend_genesys_tooltip"
          data={[]}
          labelColor={"#2AAAE2"}
          showGroupBy={true}
          showChartDetailsWidget
          statsDetailsData={[]}
          redirectTo={"/interactions/social-media/"}
          toolTipLabel={"total_interactions"}
          showDownloadIcon
          bigImg
          //lineChartPreLoaderDataSources={interactionsPreLoaderDataSources}
          //preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          lineChartPreLoaderDataSources={[1]}
          preLoaderTrackerDataSources={[1]}
          iconAfterTitle={genesysIcons}
        />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <PieChartWidget
            title={"top_countries"}
            titleToolTip="top_countries_genesys_tooltip"
            // data={topCountries}
            data={[]}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            showDownloadIcon
            iconAfterTitle={genesysIcons}
            // pieChartPreLoaderDataSources={topCountriesPreLoaderDataSources}
            // preLoaderTrackerDataSources={preLoaderTrackerDataSources}
            pieChartPreLoaderDataSources={[1]}
            preLoaderTrackerDataSources={[1]}
          />
        </Grid>
      </Grid>
      <Typography
        variant="h6"
        mt={5}
        mb={3}
        sx={{ color: "#A4A9AF", fontSize: "14px", fontWeight: 500 }}
      >
        {intl.formatMessage({ id: "contacts_in_depth_analysis" }).toUpperCase()}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TableWidget
            wordsList
            title={"most_engaged_contacts"}
            titleToolTip="most_engaged_genesys_tooltip"
            // data={topKeywords}
            data={[]}
            leftColomnTitle={"user"}
            rightColomnTitle={"calls"}
            // tableChartPreLoaderDataSources={topKeywordsPreLoaderDataSources}
            // preLoaderTrackerDataSources={preLoaderTrackerDataSources}
            tableChartPreLoaderDataSources={[1]}
            preLoaderTrackerDataSources={[1]}
            keywords
            bigImg
            tablePagination
            showDownloadIcon
            // count={topKeywords.length}
            count={0}
            iconAfterTitle={genesysIcons}
            socialKeywordName
          />
        </Grid>
      </Grid>
      <Box my={4}>
        <InteractionsList
          showProfile={true}
          data={[]}
          title={"latest_interactions"}
          dataSources={props.dataSources}
          isGenesysPage
          //iconAfterTitle={genesysIcons}
          // latestInteractionsPreLoaderDataSources={
          //   latestInteractionsPreLoaderDataSources
          // }
          // preLoaderTrackerDataSources={updatedPreLoaderTracker}
          latestInteractionsPreLoaderDataSources={[1]}
          preLoaderTrackerDataSources={[1]}
          // TODO: when filling data, remember to add the below condition
          // which specifies that no more data to show, so the to not show the
          // "view more" button
          // showViewMoreButton={}
        />
      </Box>
    </>
  );
};

export default CallsBody;
