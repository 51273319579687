import { useQuery } from "@tanstack/react-query";
import Engagements from "../../../../../services/api/engagements";

export const useGetInformalTags = ({ queryData, callInformalTags }) => {
  return useQuery({
    queryKey: ["engagementTagsInformal", queryData],
    queryFn: () => Engagements.getInformalTags(queryData),
    enabled: callInformalTags,
    select: (data) => data?.data?.data?.tags,
  });
};
