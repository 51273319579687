import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useIntl } from "react-intl";
import { Link, useParams } from "react-router-dom";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import "../../../monitors.scss";
import LineChartWidget from "components/widgets/lineChartWidget";
import StackedBarWidget from "components/widgets/stackedBarWidget";
import StackedLineWidget from "components/widgets/stackedLineWidget";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import MediaFb from "components/media/media-fb/mediaFb";
import QuestionCard from "pages/Monitors/Components/questionCard";
import { handleUnixFormat, CheckValueLocale } from "utils/helpers";

const PostsAnalyticsSection = (props) => {
  const intl = useIntl();
  const { monitorType, monitorDataSource, monitorId } = useParams();
  let {
    response,
    insightsID,
    insightsResponse,
    dates,
    postsVolume,
    setPostsVolume,
    totalPostsVolumeCount,
    setTotalPostsVolumeCount,
    postsVolumePreLoader,
    setPostsVolumePreLoader,
    videosViews,
    setVideosViews,
    totalVideosViewsCount,
    videosViewsPreLoader,
    setVideosViewsPreLoader,
    postReachImpression,
    setTotalVideosViewsCount,
    setPostReachImpression,
    totalPostReachImpression,
    setTotalPostReachImpression,
    postReachImpressionPreLoader,
    setPostReachImpressionPreLoader,
    reachAnalyticsArray,
    setReachAnalyticsArray,
    impressionAnalyticsArray,
    setImpressionAnalyticsArray,
    postsInteractions,
    setPostsInteractions,
    postsInteractionsPreLoader,
    setPostsInteractionsPreLoader,
    postsInteractionsLegend,
    setPostsInteractionsLegend,
    reactions,
    setReactions,
    reactionsPreLoader,
    setReactionsPreLoader,
    postsContentType,
    setPostsContentType,
    postsContentTypePreLoader,
    setPostsContentTypePreLoader,
    topKeywords,
    setTopKeywords,
    topKeywordsPreLoader,
    setTopKeywordsPreLoader,
    topHashtags,
    setTopHashtags,
    topHashtagsPreLoader,
    setTopHashtagsPreLoader,
    topPosts,
    setTopPosts,
    topPostsPreLoader,
    setTopPostsPreLoader,
    topVideos,
    setTopVideos,
    topVideosPreLoader,
    setTopVideosPreLoader,
    topReels,
    setTopReels,
    topReelsPreLoader,
    setTopReelsPreLoader,
    topImages,
    setTopImages,
    topImagesPreLoader,
    setTopImagesPreLoader,
  } = props;

  const monitorDetails = props.monitorData;

  // ___________________________________________________________
  const [topPostsCount, setTopPostsCount] = useState([]);
  const [maxValuePostsInteractions, setMaxValuePostsInteractions] = useState(0);
  const [minValuePostsInteractions, setMinValuePostsInteractions] = useState(0);
  const [avgValuePostsInteractions, setAvgValuePostsInteractions] = useState(0);
  const [maxValueInteractionsDate, setMaxValueInteractionsDate] = useState(0);
  const [minValueInteractionsDate, setMinValueInteractionsDate] = useState(0);
  const [pageReactionPieChartValue, setPageReactionPieChartValue] = useState(
    [],
  );
  const [postContentPieChartValue, setPostContentPieChartValue] = useState([]);

  //handle response with useEffect (when load page & any update)
  const handleResponseFunc = async () => {
    switch (response.eventName) {
      case "Facebook__AccountPage__volume_overtime":
        setPostsVolume(response.eventData.posts_over_time);
        setTotalPostsVolumeCount(response.eventData.total_posts_count);
        setPostsVolumePreLoader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Facebook__AccountPage__reach_funnel":
        Object.entries(response.eventData.reach_funnel_overtime).forEach(
          ([key, value]) => {
            switch (key) {
              case "page_posts_impressions_unique":
                value?.map((i) => {
                  return reachAnalyticsArray.push(i);
                });
                break;
              case "page_posts_impressions":
                value?.map((i) => {
                  return impressionAnalyticsArray.push(i);
                });
                break;

              default:
                break;
            }
          },
        );

        (reachAnalyticsArray.length > 0 ||
          impressionAnalyticsArray.length > 0) &&
          setPostReachImpression({
            reach: reachAnalyticsArray,
            impression: impressionAnalyticsArray,
          });

        setTotalPostReachImpression(response.eventData.reach_funnel_legends);
        setPostReachImpressionPreLoader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Facebook__AccountPage__posts_interactions":
        setPostsInteractions(
          response.eventData.posts_interactions.posts_interactions_over_time,
        );
        setPostsInteractionsLegend(
          response.eventData.posts_interactions.posts_interactions_legends,
        );
        setPostsInteractionsPreLoader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        setMaxValuePostsInteractions(
          response.eventData.posts_interactions.max_interactions,
        );
        setMinValuePostsInteractions(
          response.eventData.posts_interactions.min_interactions,
        );
        setAvgValuePostsInteractions(
          response.eventData.posts_interactions.average_interactions,
        );
        let date = handleUnixFormat(
          response.eventData.posts_interactions.max_interaction_date,
        );
        setMaxValueInteractionsDate(date);
        date = handleUnixFormat(
          response.eventData.posts_interactions.min_interaction_date,
        );
        setMinValueInteractionsDate(date);
        break;
      case "Facebook__AccountPage__reactions":
        setReactions(
          response.eventData.posts_reactions.posts_reactions_over_time,
        );
        setReactionsPreLoader((oldArray) => [...oldArray, response.monitor_id]);
        setPageReactionPieChartValue(
          response.eventData.posts_reactions.posts_reactions_piechart,
        );
        break;
      case "Facebook__AccountPage__content_type":
        setPostsContentType(
          response.eventData.posts_content_type.posts_content_type,
        );
        setPostsContentTypePreLoader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        setPostContentPieChartValue(
          response.eventData.posts_content_type.posts_content_types_legends,
        );
        break;
      case "Facebook__AccountPage__page_videos_views":
        setVideosViews(response.eventData.page_videos_views_overtime);
        setTotalVideosViewsCount(
          response.eventData.total_page_videos_views[0].value,
        );
        setVideosViewsPreLoader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Facebook__AccountPage__top_keywords":
        setTopKeywords(response.eventData.top_keywords);
        setTopKeywordsPreLoader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Facebook__AccountPage__top_hashtags":
        setTopHashtags(response.eventData.top_hashtags);
        setTopHashtagsPreLoader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Facebook__AccountPage__top_videos":
        setTopVideos(response.eventData.top_videos);
        setTopVideosPreLoader((oldArray) => [...oldArray, response.monitor_id]);
        break;
      case "Facebook__AccountPage__top_reels":
        setTopReels(response?.eventData?.top_reels);
        setTopReelsPreLoader((oldArray) => [...oldArray, response.monitor_id]);
        break;
      case "Facebook__AccountPage__top_images":
        setTopImages(response.eventData.top_images);
        setTopImagesPreLoader((oldArray) => [...oldArray, response.monitor_id]);
        break;
      case "Facebook__AccountPage__top_posts_comments":
        setTopPosts(response.eventData.top_posts);
        setTopPostsCount(response.eventData.top_posts_count);
        setTopPostsPreLoader((oldArray) => [...oldArray, response.monitor_id]);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    handleResponseFunc();
  }, [response]);
  // _______________________________________________________________________
  //getting Data to export xls sheet
  useEffect(() => {
    props.setPostsAnalyticsData([
      { postsVolume: postsVolume },
      { postReachImpression: postReachImpression },
      { postsInteractions: postsInteractions },
      { reactions: reactions },
      { postsContentType: postsContentType },
      { videosViews: videosViews },
      { topKeywords: topKeywords },
      { topHashtags: topHashtags },
      { topImages: topImages },
      { topVideos: topVideos },
      { topPosts: topPosts },
    ]);
  }, [
    postsVolume,
    postReachImpression,
    postsInteractions,
    reactions,
    postsContentType,
    videosViews,
    topKeywords,
    topHashtags,
    topImages,
    topVideos,
    topPosts,
  ]);

  // _______________________________________________________________________
  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (props.resetAllFields !== 1) {
      setPostsVolume([]);
      setTotalPostsVolumeCount([]);
      setPostsVolumePreLoader([]);

      setPostReachImpression([]);
      setImpressionAnalyticsArray([]);
      setReachAnalyticsArray([]);
      setPostReachImpressionPreLoader([]);
      setTotalPostReachImpression([]);

      setPostsInteractions([]);
      setPostsInteractionsLegend([]);
      setPostsInteractionsPreLoader([]);
      setMaxValuePostsInteractions(0);
      setMinValuePostsInteractions(0);
      setAvgValuePostsInteractions(0);
      setMaxValueInteractionsDate(0);
      setMinValueInteractionsDate(0);

      setReactions([]);
      setReactionsPreLoader([]);
      setPageReactionPieChartValue([]);

      setPostsContentType([]);
      setPostsContentTypePreLoader([]);
      setPostContentPieChartValue([]);
      setVideosViews([]);
      setTotalVideosViewsCount([]);
      setVideosViewsPreLoader([]);

      setTopKeywords([]);
      setTopKeywordsPreLoader([]);
      setTopHashtags([]);
      setTopHashtagsPreLoader([]);

      setTopVideos([]);
      setTopVideosPreLoader([]);
      setTopReels([]);
      setTopReelsPreLoader([]);
      setTopImages([]);
      setTopImagesPreLoader([]);

      setTopPosts([]);
      setTopPostsCount([]);
      setTopPostsPreLoader([]);
    }
  }, [props.resetAllFields]);

  return (
    <>
      <Typography className="section-name">
        {CheckValueLocale("posts_analytics", "", {}, intl).toUpperCase()}
      </Typography>
      <Box mt={3}>
        <LineChartWidget
          title={"posts_volume"}
          totalLabel={"posts"}
          totalValue={totalPostsVolumeCount}
          data={postsVolume}
          showGroupBy={true}
          toolTipLabel={"posts"}
          showDownloadIcon
          bigImg
          hideLegend
          lineChartPreLoaderDataSources={postsVolumePreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          iconAfterTitle={props.FacebookIcon}
          labelColor={"#2AAAE2"}
          titleToolTip={"posts_volume_fb_tooltip"}
        />
      </Box>
      <Box mt={3}>
        <StackedLineWidget
          title={"posts_reach_impressions"}
          data={postReachImpression}
          showGroupBy={true}
          areaStyle={true}
          toolTipLabel={"toolTipLabel"}
          showAI={false}
          showDownloadIcon
          bigImg
          isPostsReachImpression
          stackedLineWidgetPreLoaderDataSources={postReachImpressionPreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          isSwitchCase
          iconAfterTitle={props.FacebookIcon}
          showGroupByClientAgent={true}
          showScales
          labels={["reach", "impressions"]}
          labelColors={["#2AAAE2", "#80868C"]}
          colors={["#2AAAE2", "#80868C"]}
          titleToolTip={"posts_reach_impressions_fb_tooltip"}
          hideLegend
          dataPiechart={totalPostReachImpression}
        />
      </Box>
      <Box my={3}>
        <StackedBarWidget
          title={"posts_interactions"}
          showDownloadIcon
          bigImg
          posts={postsInteractions}
          showChartDetailsWidget
          showScales
          showGroupBy={false}
          statsDetailsData={[
            {
              name: "max_interaction",
              value: maxValuePostsInteractions,
              date: maxValueInteractionsDate,
            },
            {
              name: "min_interaction",
              value: minValuePostsInteractions,
              date: minValueInteractionsDate,
            },
            {
              name: "avg_interaction_per_day",
              value: avgValuePostsInteractions,
            },
          ]}
          colors={["#2AAAE2", "#80868C"]}
          labels={["comments", "reactions"]}
          showPostsInteractionStats
          showZoomPercentage
          iconAfterTitle={props.FacebookIcon}
          postsColumnPreLoaderDataSources={postsInteractionsPreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          hideLegend
          dataPiechart={postsInteractionsLegend}
          titleToolTip={"posts_interactions_fb_tooltip"}
        />
      </Box>
      <Box my={3}>
        <StackedBarWidget
          title={"posts_reactions"}
          showDownloadIcon
          bigImg
          posts={reactions}
          showZoomPercentage
          iconAfterTitle={props.FacebookIcon}
          postsColumnPreLoaderDataSources={reactionsPreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          showGroupByClientAgent={true}
          showScales
          labels={[
            "like_reaction",
            "love_reaction",
            "care_reaction",
            "haha_reaction",
            "wow_reaction",
            "angry_reaction",
            "sad_reaction",
          ]}
          hideLegend
          dataPiechart={pageReactionPieChartValue}
          colors={[
            "#1892F2",
            "#FB5C77",
            "#F7B94D",
            "#FEDC5F",
            "#00D7B5",
            "#EC8A92",
            "#1C404E",
          ]}
          titleToolTip={"reactions_fb_tooltip"}
        />
      </Box>
      <Box my={3}>
        <StackedBarWidget
          title={"posts_content_type"}
          showDownloadIcon
          bigImg
          posts={postsContentType}
          labels={[
            "post_content_type",
            "photo_content_type",
            "video_content_type",
          ]}
          hideLegend
          dataPiechart={postContentPieChartValue}
          colors={["#2AAAE2", "#80868C", "#334155"]}
          showZoomPercentage
          iconAfterTitle={props.FacebookIcon}
          postsColumnPreLoaderDataSources={postsContentTypePreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          showGroupByClientAgent={true}
          showScales
          titleToolTip={"posts_content_type_fb_tooltip"}
        />
      </Box>

      <Box mt={3}>
        <LineChartWidget
          title={"videos_views"}
          totalLabel={"video_content_type"}
          totalValue={totalVideosViewsCount}
          data={videosViews}
          showGroupBy={true}
          toolTipLabel={"video_content_type"}
          showDownloadIcon
          bigImg
          lineChartPreLoaderDataSources={videosViewsPreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          iconAfterTitle={props.FacebookIcon}
          labelColor={"#2AAAE2"}
          showReportIcon
          titleToolTip={"videos_views_fb_tooltip"}
        />
      </Box>

      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TableWidget
              wordsList
              title={"posts_top_keywords"}
              data={topKeywords}
              leftColomnTitle={"keywords"}
              rightColomnTitle={"number_of_posts"}
              tableChartPreLoaderDataSources={topKeywordsPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              keywords
              tablePagination
              count={topKeywords.length}
              showDownloadIcon
              iconAfterTitle={props.FacebookIcon}
              titleToolTip={"posts_top_keywords_fb_tooltip"}
              withWordCloud
            />
          </Grid>
          <Grid item xs={6}>
            <TableWidget
              wordsList
              title={"posts_top_hashtags"}
              data={topHashtags}
              leftColomnTitle={"hashtags"}
              rightColomnTitle={"number_of_posts"}
              tableChartPreLoaderDataSources={topHashtagsPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              hashtags
              tablePagination
              count={topHashtags.length}
              showDownloadIcon
              iconAfterTitle={props.FacebookIcon}
              titleToolTip={"posts_top_hashtags_fb_tooltip"}
              withWordCloud
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={3}>
        <MediaFb
          title={CheckValueLocale("top_images", "", {}, intl)}
          data={topImages}
          errorMessage={CheckValueLocale("no_images_found", "", {}, intl)}
          iconAfterTitle={props.FacebookIcon}
          mediaFbPreLoaderDataSources={topImagesPreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          showDownloadIcon
          questions={topImages}
          titleToolTip={"posts_top_images_tooltip"}
          insightsID={insightsID}
          response={insightsResponse}
          dates={dates}
        />
      </Box>
      <Box mt={3}>
        <MediaFb
          title={CheckValueLocale("top_videos", "", {}, intl)}
          data={topVideos}
          errorMessage={CheckValueLocale("no_videos_found", "", {}, intl)}
          iconAfterTitle={props.FacebookIcon}
          mediaFbPreLoaderDataSources={topVideosPreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          showDownloadIcon
          questions={topVideos}
          titleToolTip={"posts_top_videos_tooltip"}
          insightsID={insightsID}
          response={insightsResponse}
          dates={dates}
        />
      </Box>
      <Box mt={3}>
        <MediaFb
          title={CheckValueLocale("top_reels", "", {}, intl)}
          data={topReels}
          iconAfterTitle={props.FacebookIcon}
          mediaFbPreLoaderDataSources={topReelsPreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          showDownloadIcon
          questions={topReels}
          titleToolTip={"posts_top_reels_tooltip"}
          insightsID={insightsID}
          response={insightsResponse}
          dates={dates}
        />
      </Box>
      <Box className="top-posts-head">
        <Box>{CheckValueLocale("top_posts", "", {}, intl)}</Box>
        <Link
          to={{
            pathname: `/monitor/${monitorType}/${monitorDataSource}/posts/${monitorId}`,
            state: monitorDetails,
          }}
          className="top-posts-all"
          id={`check-all-posts-${
            monitorType?.toLowerCase() ?? "monitor-type"
          }-${
            monitorDataSource?.toLowerCase() ?? "monitor-data-source"
          }-posts-${monitorId ?? "monitor-id"}`}
        >
          {CheckValueLocale("check_all_posts", "", {}, intl)} ({topPostsCount})
        </Link>
      </Box>
      <Box>
        <QuestionCard
          latestUnanswer={topPosts}
          widget={"top_posts"}
          latestUnanswerPreloader={topPostsPreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          icon={faFacebook}
          iconColor={"facebook_icon"}
          showHeart={true}
          showDefaultImg={true}
          className={"no-data-found-question"}
          insightsID={insightsID}
          response={insightsResponse}
          dates={dates}
          hideChangeSentiment
        />
      </Box>
    </>
  );
};

export default PostsAnalyticsSection;
