import {
  Alert,
  Autocomplete,
  Box,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { useEffect, useMemo, useState } from "react";
import CompaniesController from "services/controllers/companiesController";
import EngagementsController from "services/controllers/engagementsController";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";
import { useDispatch } from "react-redux";
import defaultAvatar from "images/engagements-images/profileImg.png";

const SelectedAlgorithmCard = ({ title, desc, selected, onSelect }) => {
  const intl = useIntl();
  return (
    <Box
      className={`routing-auto-assign-member-cards-item ${selected ? "selected" : ""}`}
      onClick={onSelect}
    >
      <Typography className="routing-auto-assign-member-cards-item-title">
        {CheckValueLocale(title, "", {}, intl)}
      </Typography>
      <Typography className="routing-auto-assign-member-cards-item-desc">
        {CheckValueLocale(desc, "", {}, intl)}
      </Typography>
    </Box>
  );
};

const LockedEngagementAssignModal = ({
  userSignedIn,
  isTeamLeader,
  reduxSlaKey,
  getAssigneeTeamData,
  getAssigneeUserData,
  getTeamsDataToUpdate,
  agentEmptyError,
  teamEmptyError,
  trackerId,
  isBulk,
  reduxBulkData,
  teamAlgorithms,
  choosenAlgorithmId,
  setChoosenAlgorithmId,
  choosenAssign,
  setChoosenAssign,
}) => {
  const reduxSlaData = useSelector((state) => state?.engagements?.sla);
  const slaDataInRedux = isBulk ? {} : reduxSlaData?.[reduxSlaKey];
  const isTeamAssigned = isBulk ? null : slaDataInRedux?.teamId;
  const intl = useIntl();
  const [preSelectedUser, setPreSelectedUser] = useState(null);
  const [allTeams, setAllTeams] = useState([]);
  const [availableTeams, setAvailableTeams] = useState([]);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [teamsOptions, setTeamsOptions] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [choosenTeamId, setChoosenTeamId] = useState(null);
  const [choosenAgentId, setChoosenAgentId] = useState(null);
  const [teamsAreLoading, setTeamsAreLoading] = useState(false);
  const dispatch = useDispatch();
  const assignOptions = [
    { id: 1, label: "auto_assign" },
    { id: 2, label: "manual_assign" },
  ];
  const getAvailableTeamsToSelectFrom = () => {
    setChoosenAgentId(null);
    const teamsFromRedux = reduxSlaData?.teams?.map((team) => {
      return {
        label: team?.name,
        id: team?.id,
        usersCount: team?.user_teams_count,
      };
    });

    teamsOptions?.length == 0 && setTeamsOptions([...teamsFromRedux]);

    const teamsContainUser = isTeamLeader
      ? reduxSlaData?.teams?.map((team) => team?.id)
      : reduxSlaData?.teams
          ?.filter((team) => team?.user_ids?.includes(+userSignedIn))
          ?.map((team) => team?.id);

    teamsOptions?.length == 0 && setAvailableTeams([...teamsContainUser]);
    const isAssignedTeamLegal = teamsContainUser?.includes(+isTeamAssigned);
    if (isTeamAssigned && isAssignedTeamLegal) {
      getAssigneeTeamData(+isTeamAssigned);
    }
  };

  const getSignedInUserToAssign = () => {
    const matchedUser = reduxSlaData?.users?.filter((user) => {
      return +user?.id == +userSignedIn;
    });
    setPreSelectedUser({
      label: matchedUser?.[0]?.attributes?.name,
      id: matchedUser?.[0]?.attributes?.id,
    });
    getAssigneeUserData(matchedUser?.[0]?.attributes?.id);
  };

  const fetchData = async () => {
    setTeamsOptions([]);
    setTeamsAreLoading(true);
    try {
      const teamsRes = await CompaniesController.getAllCompanyTeams();
      if (!teamsRes?.errorMsg && teamsRes?.data?.teams?.length) {
        getTeamsDataToUpdate({ teams: [...teamsRes?.data?.teams] });
        setAllTeams([...teamsRes?.data?.teams]);
        let teamsOptions = teamsRes?.data?.teams?.map((team) => {
          return {
            label: team?.name,
            id: team?.id,
            usersCount: team?.user_teams_count,
          };
        });
        setTeamsOptions([...teamsOptions]);
        const teamsContainUser = isTeamLeader
          ? teamsRes?.data?.teams?.map((team) => team?.id)
          : teamsRes?.data?.teams
              ?.filter((team) => team?.user_ids?.includes(+userSignedIn))
              ?.map((team) => team?.id);

        setAvailableTeams([...teamsContainUser]);
        // dispatch(slaData(updatedReduxSlaData));
      } else {
        setAllTeams([]);
      }
    } catch (error) {
      // console.error("Error fetching data", error);
    } finally {
      setTeamsAreLoading(false);
    }
  };

  const fetchUserData = (teamId) => {
    const checkIsAccessible = (trackersId) => {
      let isAccess = true;
      reduxBulkData?.map((item) => {
        if (!trackersId?.includes(+item?.tracker_id)) {
          isAccess = false;
        }
      });
      return isAccess;
    };
    EngagementsController.getTeamUsers(
      +teamId,
      window.localStorage.getItem("engagementsProductId"),
    ).then((res) => {
      if (!res?.errorMsg && res?.data?.data?.attributes?.users?.data?.length) {
        const reshapedUsers = res?.data?.data?.attributes?.users?.data?.map(
          (user) => {
            return {
              label: user?.attributes?.name,
              id: +user?.id,
              avatar: user?.attributes?.avatar,
              availabilityStatus: user?.attributes?.availability_status,
            };
          },
        );
        setAllUsers([...reshapedUsers]);
        const usersCanAccessMonitor = res?.data?.data?.attributes?.users?.data
          ?.filter((user) =>
            isBulk
              ? checkIsAccessible(user?.attributes?.accessible_monitors_ids)
              : user?.attributes?.accessible_monitors_ids?.includes(+trackerId),
          )
          ?.map((user) => +user?.id);
        setAvailableUsers([...usersCanAccessMonitor]);
      }
    });
  };

  useEffect(() => {
    getAvailableTeamsToSelectFrom();

    if (!isTeamLeader) {
      getSignedInUserToAssign();
    }
  }, []);

  useEffect(() => {
    if (isTeamAssigned && isTeamLeader) {
      fetchUserData(+isTeamAssigned);
    }
  }, [isTeamAssigned, isTeamLeader]);

  const selectedTeamValue = useMemo(
    () =>
      isTeamAssigned && availableTeams?.includes(+isTeamAssigned)
        ? teamsOptions?.find((team) => +team?.id == +isTeamAssigned)
        : null,
    [isTeamAssigned, teamsOptions, availableTeams],
  );

  const handleImageError = (event) => {
    event.target.src = defaultAvatar;
  };

  const handleTeamChange = (event, newValue) => {
    setChoosenAgentId(null);
    setChoosenTeamId(newValue);
    getAssigneeTeamData(+newValue?.id);
    if (isTeamLeader) {
      getAssigneeUserData(null);
      fetchUserData(+newValue?.id);
    }
  };

  const handleAgentChange = (event, newValue) => {
    setChoosenAgentId(newValue);
    getAssigneeUserData(+newValue?.id);
  };

  return (
    <Box className="main-sla-contant with-border">
      <FormControl id="locked-eng-modal-assigns-selects">
        <Box className="locked-eng-modal-label">
          {CheckValueLocale("locked_eng_modal_team_label", "", {}, intl)}
          <Box component="span">*</Box>
        </Box>
        <Autocomplete
          disableClearable
          options={teamsOptions || []}
          value={choosenTeamId || selectedTeamValue}
          onChange={handleTeamChange}
          onOpen={() => fetchData()}
          getOptionDisabled={(option) => {
            return (
              !availableTeams ||
              availableTeams?.length === 0 ||
              (!availableTeams?.includes(option?.id) && !isTeamLeader)
            );
          }}
          renderInput={(params) => {
            return (
              <>
                <TextField
                  {...params}
                  label=""
                  placeholder={CheckValueLocale(
                    "locked_eng_modal_team_select",
                    "",
                    {},
                    intl,
                  )}
                  InputProps={{
                    ...params.InputProps,
                    sx: {
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: teamEmptyError
                          ? "1px solid rgba(244, 67, 54, 1)"
                          : "1px solid #ccc",
                      },
                      "& .MuiInputBase-input::placeholder": {
                        color: teamEmptyError
                          ? "rgba(244, 67, 54, 1)"
                          : "rgba(0, 0, 0, 0.38)", // Change placeholder color when error
                      },
                    },
                  }}
                />
                {teamEmptyError ? (
                  <Box
                    className="locked-eng-modal-empty-field-error"
                    component="span"
                  >
                    {CheckValueLocale(
                      "locked_eng_modal_agent_field_error",
                      "",
                      {},
                      intl,
                    )}
                  </Box>
                ) : null}
              </>
            );
          }}
          getOptionLabel={(option) => {
            return option?.label || "";
          }}
          renderOption={(props, option) => {
            const isDisabled =
              !availableTeams ||
              (!availableTeams?.includes(option?.id) && !isTeamLeader);
            return (
              <MenuItem {...props}>
                <Box className="locked-eng-modal-option-label">
                  <Box component="p">{option?.label}</Box>
                  {option?.usersCount > 0 ? (
                    <Box component="div">
                      <Box
                        component="span"
                        className={`status-circle ${isDisabled ? "not-available" : "available"}`}
                      ></Box>
                      <Box component="span">
                        {CheckValueLocale(
                          "locked_popup_users_num",
                          "",
                          { num: option?.usersCount },
                          intl,
                        )}
                      </Box>
                    </Box>
                  ) : (
                    <Box component="span">
                      {CheckValueLocale(
                        "locked_popup_no_users_num",
                        "",
                        {},
                        intl,
                      )}
                    </Box>
                  )}
                </Box>
              </MenuItem>
            );
          }}
          noOptionsText={
            teamsAreLoading ? (
              <Box className="locked-eng-modal-empty-teams-list-loading">
                <FontAwesomeIcon icon={faSpinner} spin />
              </Box>
            ) : (
              <Box
                className="locked-eng-modal-add-new-team-btn locked-eng-modal-btn-no-options"
                component="span"
                onClick={() => {
                  window.open("/settings/teams/add", "_blank");
                }}
              >
                <FontAwesomeIcon icon={faExternalLink} />
                <Box component="span">
                  {CheckValueLocale(
                    "locked_eng_modal_add_new_team_btn",
                    "",
                    {},
                    intl,
                  )}
                </Box>
              </Box>
            )
          }
          groupBy={(option) => option?.typeOf}
          renderGroup={(params) => {
            return (
              <>
                <Box
                  className="locked-eng-modal-add-new-team-btn"
                  component="button"
                  onClick={() => {
                    window.open("/settings/teams/add", "_blank");
                  }}
                >
                  <FontAwesomeIcon icon={faExternalLink} />
                  <Box component="span">
                    {CheckValueLocale(
                      "locked_eng_modal_add_new_team_btn",
                      "",
                      {},
                      intl,
                    )}
                  </Box>
                </Box>
                {params?.children}
              </>
            );
          }}
        />
        {isBulk && isTeamLeader && choosenTeamId ? (
          <Box className="main-assign-options">
            <Box className="locked-eng-modal-assign-label">
              {CheckValueLocale(
                "locked_eng_modal_bulk_assign_label",
                "",
                {},
                intl,
              )}
            </Box>
            <FormControl>
              <RadioGroup
                row
                value={choosenAssign}
                onChange={(e) => setChoosenAssign(e?.target?.value)}
              >
                {assignOptions?.map((item) => (
                  <FormControlLabel
                    value={item?.id}
                    control={<Radio />}
                    label={CheckValueLocale(item?.label, "", {}, intl)}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <Alert
              severity={choosenAssign == 1 ? "info" : "warning"}
              className="survey-egag-alert"
            >
              {CheckValueLocale(
                choosenAssign == 1 ? "bulk_assign_info" : "bulk_assign_warning",
                "",
                {},
                intl,
              )}
            </Alert>
            {choosenAssign == 1 ? (
              <Box className="routing-auto-assign-member-cards">
                {teamAlgorithms?.map((algorithm) => (
                  <SelectedAlgorithmCard
                    key={algorithm?.id}
                    title={algorithm?.name}
                    desc={algorithm?.desc}
                    selected={choosenAlgorithmId === algorithm?.id}
                    onSelect={() => setChoosenAlgorithmId(algorithm?.id)}
                  />
                ))}
              </Box>
            ) : null}
          </Box>
        ) : null}
        {!isBulk || !isTeamLeader || (isBulk && choosenAssign == 2) ? (
          <>
            <Box className="locked-eng-modal-label">
              {CheckValueLocale("locked_eng_modal_agent_label", "", {}, intl)}
              <Box component="span">*</Box>
            </Box>
            <Autocomplete
              options={allUsers || [preSelectedUser]}
              disabled={!isTeamLeader || allUsers?.length <= 0}
              value={choosenAgentId || preSelectedUser}
              onChange={handleAgentChange}
              disableClearable
              getOptionDisabled={(option) => {
                return (
                  !availableUsers ||
                  availableUsers?.length === 0 ||
                  !availableUsers?.includes(+option?.id)
                );
              }}
              getOptionLabel={(option) => {
                return option?.label || "";
              }}
              renderInput={(params) => {
                return (
                  <>
                    <TextField
                      {...params}
                      label=""
                      placeholder={CheckValueLocale(
                        "locked_eng_modal_team_select",
                        "",
                        {},
                        intl,
                      )}
                      InputProps={{
                        ...params.InputProps,
                        sx: {
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: !isTeamLeader
                              ? "1px dotted #000"
                              : agentEmptyError
                                ? "1px solid rgba(244, 67, 54, 1)"
                                : "1px solid #ccc",
                          },
                          "& .MuiInputBase-input::placeholder": {
                            color: agentEmptyError
                              ? "rgba(244, 67, 54, 1)"
                              : "rgba(0, 0, 0, 0.38)", // Change placeholder color when error
                          },
                        },
                      }}
                    />
                    {agentEmptyError ? (
                      <Box
                        className="locked-eng-modal-empty-field-error"
                        component="span"
                      >
                        {CheckValueLocale(
                          "locked_eng_modal_agent_field_error",
                          "",
                          {},
                          intl,
                        )}
                      </Box>
                    ) : null}
                  </>
                );
              }}
              renderOption={(props, option) => {
                return (
                  <MenuItem {...props}>
                    <Box className="locked-eng-modal-option-label-user">
                      <Box
                        className="locked-eng-modal-option-label-user-avatar"
                        component="div"
                      >
                        <img
                          src={option?.avatar || defaultAvatar}
                          alt="userAvatar"
                          onError={handleImageError}
                        />
                      </Box>
                      <Box
                        className="locked-eng-modal-option-label-user-info"
                        component="div"
                      >
                        <Typography component="p">{option?.label}</Typography>
                        <Typography component="span">
                          {CheckValueLocale(
                            `agent_status_${option?.availabilityStatus}`,
                            "",
                            {},
                            intl,
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  </MenuItem>
                );
              }}
              groupBy={(option) => option?.typeOf}
              renderGroup={(params) => {
                return (
                  <>
                    <Box
                      className="locked-eng-modal-add-new-team-btn"
                      component="button"
                      onClick={() => {
                        window.open("/settings/user_managements/add", "_blank");
                      }}
                    >
                      <FontAwesomeIcon icon={faExternalLink} />
                      <Box component="span">
                        {CheckValueLocale(
                          "locked_eng_modal_add_new_user_btn",
                          "",
                          {},
                          intl,
                        )}
                      </Box>
                    </Box>
                    {params?.children}
                  </>
                );
              }}
              noOptionsText={
                <Box
                  className="locked-eng-modal-add-new-team-btn locked-eng-modal-btn-no-options"
                  component="span"
                  onClick={() => {
                    window.open("/settings/user_managements/add", "_blank");
                  }}
                >
                  <FontAwesomeIcon icon={faExternalLink} />
                  <Box component="span">
                    {CheckValueLocale(
                      "locked_eng_modal_add_new_user_btn",
                      "",
                      {},
                      intl,
                    )}
                  </Box>
                </Box>
              }
            />
          </>
        ) : null}
      </FormControl>
    </Box>
  );
};

export default LockedEngagementAssignModal;
