import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Divider,
  TextField,
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  Alert,
} from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { CheckValueLocale, isEmptyObj } from "utils/helpers";
import { useIntl } from "react-intl";
import AccountsSelect from "./accountsPopupSelection";
import CloseIcon from "@mui/icons-material/Close";
import PublicIcon from "@mui/icons-material/Public";
import _ from "lodash";
import NewDMContentMedia from "./newDMContentMedia";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";
import { faSearch, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import XPIcon from "images/engagements-images/xp-icon-eng-round.svg";
import FBIcon from "images/engagements-images/fb-round-icon.svg";
import IGIcon from "images/engagements-images/ig-round-icon.svg";
import profileImg from "images/engagements-images/profileImg.png";
import MonitorsController from "services/controllers/monitorsController";
import "./newConversationPopup.scss";
import "../../publish/componenets/sidebarContent/components/publishPopup/publishPopup.scss";
import LucButton from "shared/lucButton/lucButton";

const NewConversationContent = (props) => {
  const intl = useIntl();
  const {
    popupTitle,
    handleClose,
    setOpenPublish,
    publishedTypeSelected,
    contentSelected,
    accountsPublishSelected,
    contentErrormedia,
    setContentErrorMedia,
    selectedFile,
    twLimitMedia,
    fbLimitMedia,
    igLimitMedia,
    text,
    isPostsLoading,
    setIsPostsLoading,
    setRecevierAccountSelect,
    recevierAccountSelect,
    senderAccountSelect,
    setContentSelected,
    getUserList,
    setReceiverAccountList,
    receiverAccountList,
    receiverAccountLoading,
    setAttachments,
    attachments,
    setReceiverAccountFoucs,
    receiverAccountFoucs,
    setCaretHidden,
    caretHidden,
    setStopPagination,
    stopPagination,
    setLoadMore,
    loadMore,
    receiverAccountPage,
    setReceiverAccountPage,
    setSendBtnDisabled,
    sendBtnDisabled,
    loading,
    setLoading,
    setOpenSnackBarDelete,
    setRecevierItem,
    recevierItem,
    setSnackBarData,
    contentSelectedArr,
    setContentSelectedArr,
    isListEmpty,
    setIsListEmpty,
  } = props;

  let timeZone = window.localStorage.companyInfo;

  // here we check limits for media content
  const getMediaLessLimits = () => {
    let twText = twLimitMedia?.attributes?.text_length;
    let twMedia = twLimitMedia?.attributes?.attachments?.number_of_media;
    let twImgSize =
      twLimitMedia?.attributes?.attachments?.media_validations?.image?.size;
    let fbText = fbLimitMedia?.attributes?.text_length;
    let fbMedia = fbLimitMedia?.attributes?.attachments?.number_of_media;
    let fbImgSize =
      fbLimitMedia?.attributes?.attachments?.media_validations?.image?.size;
    let igText = igLimitMedia?.attributes?.text_length;
    let igMedia = igLimitMedia?.attributes?.attachments?.number_of_media;
    let igImgSize =
      igLimitMedia?.attributes?.attachments?.media_validations?.image?.size;
    let minimumValueText = twText;
    let minimumValueNoMedia = twText;
    let minimumValueImgSize = twImgSize;
    let datasourceKey = "tw";
    if (accountsPublishSelected?.data_source?.toLowerCase() == "twitter") {
      minimumValueText = twText;
      minimumValueNoMedia = twMedia;
      minimumValueImgSize = twImgSize;
      datasourceKey = "tw";
    } else if (
      accountsPublishSelected?.data_source?.toLowerCase() == "facebook"
    ) {
      minimumValueText = fbText;
      minimumValueNoMedia = fbMedia;
      minimumValueImgSize = fbImgSize;
      datasourceKey = "fb";
    } else {
      minimumValueText = igText;
      minimumValueNoMedia = igMedia;
      minimumValueImgSize = igImgSize;
      datasourceKey = "ig";
    }

    return {
      minimumValueText,
      minimumValueNoMedia,
      datasourceKey,
      minimumValueImgSize,
    };
  };
  let convertFileToKB =
    getMediaLessLimits()?.minimumValueImgSize / Math.pow(1024, 2);
  //check media errors
  const handleMediaErrorMsg = () => {
    let errorMediaMsg;
    let resLimit = getMediaLessLimits();
    if (!selectedFile?.length && !text?.length) {
      errorMediaMsg = "publish_content_needed_error";
    } else if (selectedFile?.length > resLimit?.minimumValueNoMedia) {
      errorMediaMsg = `publish_${resLimit?.datasourceKey}_limit_images`;
    } else if (text?.length > resLimit?.minimumValueText) {
      errorMediaMsg = `publish_content_limit_text`;
    } else {
      errorMediaMsg = "";
    }
    setContentErrorMedia(errorMediaMsg);
    return errorMediaMsg;
  };

  let checkDisabledScheduleBtn =
    isEmptyObj(accountsPublishSelected) ||
    contentErrormedia ||
    sendBtnDisabled ||
    !text ||
    !receiverAccountList?.length;

  const handleChange = (event) => {
    setRecevierAccountSelect(event.target.value);
    setCaretHidden(true);
  };

  //send new Conversation
  const handleSendConversation = () => {
    setLoading(true);
    setIsPostsLoading(true);
    handleMediaErrorMsg();
    MonitorsController.postReplies(
      +window.localStorage.engagements_id,
      +accountsPublishSelected?.reference_id,
      accountsPublishSelected?.user_name,
      accountsPublishSelected?.data_source,
      parseInt(accountsPublishSelected?.monitor_id),
      undefined,
      "message",
      text?.trimStart(),
      recevierItem?.user_id,
      attachments,
    ).then((data) => {
      if (data?.data?.status === 201) {
        setLoading(false);
        handleClose();
        setIsPostsLoading(false);
        setOpenSnackBarDelete(true);
        setSnackBarData({
          severity: "success",
          title: "new_conv_success",
        });
      } else {
        setOpenSnackBarDelete(true);
        if (
          data?.errorMsg?.response?.data?.msg ===
          "You do not have permission to DM one or more participants."
        ) {
          setSnackBarData({
            severity: "error",
            title: "",
            message: "can't_send_dm_to_account",
          });
        } else {
          setSnackBarData({
            severity: "error",
            title: "something_went_wrong",
            message: "wrong_msg",
          });
        }
        setLoading(false);
        setIsPostsLoading(false);
      }
    });
  };

  const handleDatasourcesIcon = (datasource) => {
    let datasourcesLIst = {
      twitter: XPIcon,
      facebook: FBIcon,
      instagram: IGIcon,
    };
    return datasourcesLIst[datasource] || datasourcesLIst.default;
  };

  const handleScroll = (event) => {
    const target = event.target;
    if (
      Math.trunc(target.scrollHeight) -
        Math.trunc(target.scrollTop) -
        Math.trunc(target.clientHeight) <=
      4
    ) {
      if (!loadMore && receiverAccountList?.length && !stopPagination) {
        setLoadMore(true);
        getUserList(receiverAccountPage + 1);
        setReceiverAccountPage(receiverAccountPage + 1);
      }
    }
  };

  return (
    <Box className="publish-pop-up-wrapper">
      <Box className="publish-pop-up-header">
        <Typography component={"p"} className="publish-new-post-title">
          {CheckValueLocale(popupTitle, "", {}, intl)}
        </Typography>
        <Box onClick={handleClose} className="publish-close-icon">
          <CloseIcon />
        </Box>
      </Box>
      <Divider className="publish-new-post-divider" />
      <Box className="publish-popup-content-container">
        <Box className="publish-pop-up-main-content">
          <AccountsSelect {...props} setOpenPublish={setOpenPublish} />
        </Box>
        <Box className="new-conv-recveier-acc">
          <Typography
            component={"p"}
            className="publish-new-post-accounts-title "
          >
            {CheckValueLocale("new_conv_receiver_account", "", {}, intl)}
          </Typography>
          <FormControl fullWidth>
            <TextField
              value={recevierAccountSelect}
              onChange={handleChange}
              variant="outlined"
              placeholder={CheckValueLocale(
                "new_conv_receiver_account_placeholder",
                "",
                {},
                intl,
              )}
              className="recevier-account-content"
              disabled={
                isEmptyObj(accountsPublishSelected) ||
                receiverAccountLoading ||
                loading
              }
              onFocus={() => {
                setContentSelectedArr(!contentSelectedArr);
                if (receiverAccountList?.length) {
                  setIsListEmpty([]);
                } else {
                  setIsListEmpty(isListEmpty);
                }
              }}
              autoFocus={receiverAccountFoucs}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disableRipple
                      onClick={() => {
                        if (
                          caretHidden &&
                          recevierAccountSelect?.trim()?.length
                        ) {
                          setReceiverAccountPage(1);
                          setReceiverAccountList([]);
                          getUserList(1);
                        }
                      }}
                      disabled={
                        isEmptyObj(accountsPublishSelected) ||
                        !recevierAccountSelect ||
                        receiverAccountLoading ||
                        loading
                      }
                    >
                      {receiverAccountLoading ? (
                        <FontAwesomeIcon icon={faSpinner} spin />
                      ) : caretHidden || recevierAccountSelect?.length == 0 ? (
                        <FontAwesomeIcon icon={faSearch} />
                      ) : (
                        <FontAwesomeIcon icon={faCaretDown} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                sx: {
                  "&::placeholder": {
                    color: "#00000099 !important",
                    opacity: 1,
                    fontSize: 14,
                    fontFamily: 400,
                  },
                },
              }}
            />
          </FormControl>

          {contentSelectedArr &&
          (isListEmpty?.length || receiverAccountList?.length) ? (
            <Box
              className={`${receiverAccountList?.length ? "reciver-accounts-wrapper" : "reciver-accounts-wrapper-no-data"}`}
              onScroll={handleScroll}
            >
              <ClickAwayListener onClickAway={() => {}}>
                <Box>
                  {receiverAccountList?.length && !isListEmpty?.length ? (
                    receiverAccountList?.map((item) => (
                      <Box
                        key={`account-type-${item?.user_id}`}
                        className="accounts-publish-inside-item"
                        onClick={() => {
                          setRecevierItem(item);
                          setRecevierAccountSelect(item?.name);
                          setContentSelectedArr(false);
                        }}
                      >
                        <Box className="publish-accounts-wrapper">
                          <Box className="publish-account-wrapper-item">
                            <Typography
                              component={"span"}
                              className="accounts-name-content-pop-up"
                            >
                              <img
                                src={
                                  item?.profile_image
                                    ? item?.profile_image
                                    : profileImg
                                }
                                alt="profile-image"
                                className="accounts-publish-avatar-inside"
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = profileImg;
                                }}
                              />
                              <img
                                src={handleDatasourcesIcon(
                                  accountsPublishSelected?.data_source?.toLowerCase(),
                                )}
                                alt="datasource-icon"
                                className="accounts-datasource-icon"
                              />
                              <Box>
                                <Typography
                                  component={"p"}
                                  className="account-publish-name-pop-up"
                                >
                                  {item?.name}
                                </Typography>
                                <Typography
                                  component={"p"}
                                  className="account-publish-name-pop-up-datasource"
                                >
                                  {CheckValueLocale(
                                    accountsPublishSelected?.data_source,
                                    "",
                                    {},
                                    intl,
                                  )}
                                </Typography>
                              </Box>
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    ))
                  ) : (
                    <Box className="receiver-no-users">
                      {CheckValueLocale("no_users_found", "", {}, intl)}
                    </Box>
                  )}

                  {loadMore ? (
                    <Box className="receiver-load-more">
                      <FontAwesomeIcon icon={faSpinner} spin />
                    </Box>
                  ) : null}
                </Box>
              </ClickAwayListener>
            </Box>
          ) : null}
        </Box>

        {!isEmptyObj(accountsPublishSelected) ? (
          <Box className="recevier-account-alert">
            <Alert severity="info">
              {CheckValueLocale(
                `${accountsPublishSelected?.data_source == "TWITTER" ? "new_conv_tw_tooltip_msg" : "new_conv_fb_ig_tooltip_msg"}`,
                "",
                {},
                intl,
              )}
            </Alert>
          </Box>
        ) : null}

        <Box>
          <Box className="publish-content-info">
            <Typography component={"p"} className="publish-content-media-title">
              {CheckValueLocale("message", "", {}, intl)}
            </Typography>
          </Box>
          <Box
            className={`publish-reply-content-container ${
              contentSelected && "focused"
            } ${contentErrormedia && "error"}`}
          >
            <NewDMContentMedia
              setContentErrorMedia={setContentErrorMedia}
              getMediaLessLimits={getMediaLessLimits}
              {...props}
            />
          </Box>
          {contentErrormedia ? (
            <Box className="content-error-msg-media">
              {CheckValueLocale(
                contentErrormedia,
                "",
                {
                  limit: getMediaLessLimits()?.minimumValueText,
                  media_size: convertFileToKB,
                },
                intl,
              )}
            </Box>
          ) : null}
        </Box>
      </Box>

      {/* Footer */}
      <Box className="publish-pop-up-footer">
        <Box className="time-zone-content">
          <PublicIcon className="public-gmt-icon" />
          <Typography component={"p"} className="publish-gmt-title">
            {`${CheckValueLocale("gmt", "", {}, intl)}
            ${timeZone >= 0 ? "+" + timeZone : timeZone}`}
          </Typography>
        </Box>
        <Box>
          <LucButton
            variant="flat"
            type="secondary"
            size="small"
            onClick={handleClose}
            id={`${publishedTypeSelected}_publish-pop-up-cancel`}
          >
            {CheckValueLocale("cancel", "", {}, intl)}
          </LucButton>
          <LucButton
            size="small"
            onClick={handleSendConversation}
            loading={isPostsLoading}
            className={"btn-create-scedule-popup"}
            id={`${publishedTypeSelected}_publish-pop-up-create`}
            disabled={checkDisabledScheduleBtn}
          >
            {CheckValueLocale(`send`, "", {}, intl)}
          </LucButton>
        </Box>
      </Box>
    </Box>
  );
};

export default NewConversationContent;
