import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams, useNavigate } from "react-router-dom";
import { faQuestion, faPercent } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";
import QuestionCard from "pages/Monitors/Components/questionCard/index";
import { Box } from "@mui/system";
import BarChartWidget from "components/widgets/barChartWidget";
import { commasAfterDigit, CheckValueLocale } from "utils/helpers";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import publicInstagram from "images/social-icons/instagram-public-icon.svg";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { Button, Typography } from "@mui/material";
const CustomerCareBody = (props) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const { monitorType, monitorDataSource, monitorId } = useParams();
  const monitorDetails = props.monitorData;
  const { response, preLoaderTrackerDataSources, resetAllFields } = props;

  const [latestUnanswer, setLatestUnanswer] = useState([]);
  const [customerCarePerformance, setCustomercarePerformance] = useState({});
  const [latestUnanswerPreloader, setLatestUnanswerPreloader] = useState([]);
  const [customerCarePreloader, setCustomercarePreloader] = useState([]);
  const [unAnsweredQuestion, setUnanswerQuestion] = useState(0);
  const [respRate, setRespRate] = useState(0);
  const [totalQuestion, setTotalQuestion] = useState(0);
  const [customerCareData, setcustomerCareData] = useState([]);

  useEffect(() => {
    if (resetAllFields !== 1) {
      setLatestUnanswer([]);
      setCustomercarePreloader({});
      setLatestUnanswerPreloader([]);
      setCustomercarePreloader([]);
      setUnanswerQuestion(0);
      setcustomerCareData([]);
    }
  }, [resetAllFields]);

  const handleResponseFunc = async () => {
    if (
      response.eventName ===
      "Instagram__CustomerCarePage__customer_care_performance"
    ) {
      setCustomercarePerformance(response.eventData);
      setCustomercarePreloader([
        ...customerCarePreloader,
        parseInt(response.monitor_id),
      ]);
      if (response?.eventData?.response_time_segment) {
        Object.keys(response.eventData?.response_time_segment).map(
          (val, index) => {
            setcustomerCareData((oldArray) => [
              ...oldArray,
              {
                name: val,
                value: Object.values(response.eventData?.response_time_segment)[
                  index
                ],
              },
            ]);
          },
        );
      }
      setRespRate(
        response.eventData.number_of_answered_questions /
          response.eventData.total_number_of_questions,
      );
      setTotalQuestion(response.eventData.total_number_of_questions);
    } else if (
      response.eventName ===
      "Instagram__CustomerCarePage__latest_unanswered_questions"
    ) {
      setLatestUnanswer(response.eventData.latest_unanswered_questions);
      setUnanswerQuestion(response.eventData.number_of_unanswered_questions);
      setLatestUnanswerPreloader([
        ...latestUnanswerPreloader,
        parseInt(response.monitor_id),
      ]);
    } else {
      return null;
    }
  };
  useEffect(() => {
    handleResponseFunc();
  }, [response]);

  useEffect(() => {
    let totalPreLoaderDataSouces = [];

    totalPreLoaderDataSouces.push(
      customerCarePreloader,
      latestUnanswerPreloader,
    );

    props.checkAllDataFounded(
      totalPreLoaderDataSouces,
      props.preLoaderTrackerDataSources,
    );

    if (totalQuestion > 0) {
      props.setCheckDataIsEmpty(true);
    }
  }, [totalQuestion, customerCarePreloader, latestUnanswerPreloader]);

  //Luci check [ noDataFound , Analyzing ]
  useEffect(() => {
    let x = props.checkLuciAllDataFounded(
      latestUnanswerPreloader,
      props.preLoaderTrackerDataSources,
    );
    let y = props.checkLuciDataIsFounded([latestUnanswer]);
    props.setLuciCheckAllDataExist(x);
    props.setLuciCheckDataIsEmpty(y);
  }, [
    latestUnanswer,
    latestUnanswerPreloader,
    props.preLoaderTrackerDataSources,
  ]);
  useEffect(() => {
    props?.setTotalPostsCount?.(unAnsweredQuestion);
  }, [unAnsweredQuestion]);

  const handleCloseAlert = () => {
    props?.setHiddenCustomerCareAlert(true);
  };
  return (
    <>
      {!props?.hiddenCustomerCareAlert ? (
        <>
          {props?.customerCareFlag == false ? (
            <Box className="customer-care-alert margin-b">
              <Box className="start-box">
                <InfoOutlinedIcon />
                <Typography>
                  {CheckValueLocale("customer_care_alerts", "", {}, intl)}
                </Typography>
              </Box>
              <Box className="end-box">
                <Button
                  onClick={() =>
                    navigate("/monitor/monitor-settings/" + monitorId)
                  }
                >
                  {CheckValueLocale("monitor_setting", "", {}, intl)}
                </Button>
                <FontAwesomeIcon
                  onClick={() => handleCloseAlert()}
                  icon={faTimes}
                />
              </Box>
            </Box>
          ) : null}
        </>
      ) : null}
      <Box mt={3}>
        <div className="chart-body">
          <BarChartWidget
            title={"customer_performance"}
            titleToolTip={"customer_performance_ig_tooltip"}
            data={customerCareData}
            showAI={true}
            showDownloadIcon={true}
            showChartStatsWidget={true}
            showZoomPercentage
            customerCareChart
            gridHeight={"70%"}
            respTimeDistributionTooltip="respone_time_distribution_tooltip"
            statsData={[
              {
                name: "total_ques",
                value:
                  customerCarePerformance?.total_number_of_questions &&
                  commasAfterDigit(
                    customerCarePerformance?.total_number_of_questions,
                  ),
                icon: faQuestion,
                titleToolTip: "number_of_ques_tooltip",
              },
              {
                name: "number_of_answered_questions",
                value: isNaN(respRate * 100)
                  ? 0 + "%"
                  : respRate.toFixed(2) * 100 + "%",
                icon: faPercent,
                titleToolTip: "response_rate_tooltip",
              },
              {
                name: "avg_response_time_in_seconds",
                value: customerCarePerformance?.avg_response_time_in_seconds,
                icon: faClock,
                type: "time",
                titleToolTip: "avg_response_time_tooltip",
              },
            ]}
            dataColor="#2AAAE2"
            toolTipLabel={"posts"}
            isTwitterAccount
            barChartPreLoaderDataSources={customerCarePreloader}
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
            iconAfterTitle={
              <img src={publicInstagram} className="instagram-icon-style" />
            }
          />
        </div>
        <Box className="top-posts-head">
          <Box>{CheckValueLocale("latest_questions", "", {}, intl)}</Box>
          <Link
            to={{
              pathname: `/monitor/${monitorType}/${monitorDataSource}/questions/${monitorId}`,
              state: monitorDetails,
            }}
            className="top-posts-all"
            id={`check-un-answered-questions-${
              monitorType?.toLowerCase() ?? "monitor-type"
            }-${
              monitorDataSource?.toLowerCase() ?? "monitor-data-source"
            }-questions-${monitorId ?? "monitor-id"}`}
          >
            {CheckValueLocale("check_all_questions", "", {}, intl)} (
            {unAnsweredQuestion})
          </Link>
        </Box>

        <QuestionCard
          unixDate={props.unixDate}
          showProfile={true}
          productId={window.localStorage?.getItem("sm_id")}
          latestUnanswer={latestUnanswer}
          latestUnanswerPreloader={latestUnanswerPreloader}
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          icon={<img src={publicInstagram} />}
          iconColor={"instagram_icon"}
          showHeart={true}
          showDefaultImg={true}
          hideChartBar={true}
          className={"no-data-found-question"}
          widget="comments_mentions"
        />
      </Box>
    </>
  );
};

export default CustomerCareBody;
