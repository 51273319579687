import {
  faClockRotateLeft,
  faSpinnerThird,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { useIntl } from "react-intl";
import LucButton from "shared/lucButton/lucButton";
import { CheckValueLocale, classNames } from "utils/helpers";
import { postTypes } from "../postAndRepliesUtils";
import PopupModal from "components/popupModal";

export const MissingReply = ({
  type,
  isRoot,
  disableRequest,
  onLoadMore,
  isLoading,
  getHistoricalRequestInteraction,
  disablePagination,
  isMonitorPaused,
}) => {
  const intl = useIntl();
  const [isRequestDataPopupOpen, setIsRequestDataPopupOpen] = useState(false);

  const handleConfirmRequestData = () => {
    getHistoricalRequestInteraction(isRoot);
    setIsRequestDataPopupOpen(false);
  };

  const handleOpenRequestData = () => setIsRequestDataPopupOpen(true);
  const handleCloseRequestData = () => setIsRequestDataPopupOpen(false);

  return (
    <>
      <Box className="posts-rep">
        <Box
          className={classNames(
            "missing-reply-container",
            type === postTypes.missingReply && isRoot
              ? "missing-reply-root"
              : "",
          )}
        >
          <LoadMoreMessages
            onRequestData={handleOpenRequestData}
            onLoadMore={onLoadMore}
            disableRequest={disableRequest}
            isLoading={isLoading}
            type={type}
            disablePagination={disablePagination}
            isRoot={isRoot}
            isMonitorPaused={isMonitorPaused}
          />
        </Box>
      </Box>
      <PopupModal
        title={CheckValueLocale("request_missing_data", "", {}, intl)}
        body={CheckValueLocale("request_missing_data_body", "", {}, intl)}
        leftBtn={CheckValueLocale("cancel", "", {}, intl)}
        rightBtn={CheckValueLocale("request_missing_data_button", "", {}, intl)}
        open={isRequestDataPopupOpen}
        close={handleCloseRequestData}
        accept={handleConfirmRequestData}
        minWidth="600px"
        addClasses="delete-engagement-buttons"
      />
    </>
  );
};

const LoadMoreMessages = ({
  isLoading,
  type,
  onRequestData,
  disableRequest,
  onLoadMore,
  disablePagination,
  isRoot,
  isMonitorPaused,
}) => {
  const intl = useIntl();
  if (isLoading) {
    return (
      <Box className="missing-reply-loading">
        <FontAwesomeIcon icon={faSpinnerThird} spin />
      </Box>
    );
  }
  switch (type) {
    case postTypes.missingReply:
      return (
        <Box className="missing-reply-info-container">
          <Box className="missing-reply-info-avatar" />
          <Box className="missing-reply-info">
            <Typography className="missing-reply-info-title">
              {CheckValueLocale(
                isRoot ? "missing_replies" : "missing_post_reply",
                "",
                {},
                intl,
              )}
            </Typography>
            <Typography className="missing-reply-info-text">
              {CheckValueLocale(
                isRoot ? "missing_replies_info" : "missing_post_reply_info",
                "",
                {},
                intl,
              )}
            </Typography>
          </Box>
          <DisableRequestTooltip
            disabled={disableRequest}
            isMonitorPaused={isMonitorPaused}
          >
            <Box>
              <LucButton
                variant="text"
                onClick={onRequestData}
                disabled={disableRequest}
              >
                {CheckValueLocale("load_more_request", "", {}, intl)}
              </LucButton>
            </Box>
          </DisableRequestTooltip>
        </Box>
      );
    case postTypes.loadMoreReplies:
      return (
        <DisableRequestTooltip
          disabled={disablePagination}
          isMonitorPaused={isMonitorPaused}
        >
          <Box>
            <LucButton
              variant="text"
              startIcon={<FontAwesomeIcon icon={faClockRotateLeft} />}
              onClick={onLoadMore}
              disabled={disablePagination}
            >
              {CheckValueLocale("load_more_missing_replies", "", {}, intl)}
            </LucButton>
          </Box>
        </DisableRequestTooltip>
      );
    default:
      return null;
  }
};

const DisableRequestTooltip = ({ children, disabled, isMonitorPaused }) => {
  const intl = useIntl();
  return (
    <Tooltip
      title={
        disabled
          ? CheckValueLocale(
              isMonitorPaused
                ? "historical_request_paused_toolip"
                : "disabled_missing_reply_tooltip",
              "",
              {},
              intl,
            )
          : null
      }
      arrow
      placement="bottom"
      classes={{ tooltip: "missing-reply-request-tooltip" }}
    >
      {children}
    </Tooltip>
  );
};
