import { useState } from "react";
import { useIntl } from "react-intl";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Switch,
  Typography,
} from "@mui/material";
import {
  faAngleDown,
  faBarsProgress,
  faGear,
  faHeading,
  faRectanglePro,
  faRotateLeft,
  faSendBackward,
  faText,
  faTimer,
  faWindow,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckValueLocale } from "utils/helpers";
import EmbedOptions from "./options/embedOptions";
import PopupStyleOptions from "./options/popupStyleOptions";
import ButtonStyleOptions from "./options/buttonStyleOptions";
import FontStyle from "./options/fontStyle";
import BackdropOptions from "./options/backdropOptions";
import OpeningOptions from "./options/openingOptions";
import FrequencyOptions from "./options/frequencyOptions";
import "./style.scss";

const DisaplySettingsTab = (props) => {
  const { surveyData, setSurveyData } = props;

  const displaySettingsAccordions = [
    {
      title: "display_settings_embed_options",
      icon: faGear,
      component: EmbedOptions,
    },
    {
      title: "display_settings_button_style",
      icon: faRectanglePro,
      component: ButtonStyleOptions,
      hide: surveyData?.embedded_config?.embed_options?.type !== "button",
    },
    {
      title: "display_settings_popup_style",
      icon: faWindow,
      component: PopupStyleOptions,
    },
    {
      title: "display_settings_font_style",
      icon: faText,
      component: FontStyle,
    },
    {
      title: "display_settings_backdrop",
      icon: faSendBackward,
      component: BackdropOptions,
    },
    {
      title: "display_settings_progress_bar",
      icon: faBarsProgress,
      component: null,
      type: "switch",
      value: surveyData?.embedded_config?.progress_bar?.enabled,
      onChange: () =>
        setSurveyData((prev) => ({
          ...prev,
          embedded_config: {
            ...prev?.embedded_config,
            progress_bar: {
              ...prev?.embedded_config?.progress_bar,
              enabled: !prev?.embedded_config?.progress_bar?.enabled,
            },
          },
        })),
    },
    {
      title: "display_settings_hide_sections_header",
      icon: faHeading,
      component: null,
      type: "switch",
      value: surveyData?.embedded_config?.hide_sections_header?.enabled,
      onChange: () =>
        setSurveyData((prev) => ({
          ...prev,
          embedded_config: {
            ...prev?.embedded_config,
            hide_sections_header: {
              ...prev?.embedded_config?.hide_sections_header,
              enabled: !prev?.embedded_config?.hide_sections_header?.enabled,
            },
          },
        })),
    },
    {
      title: "display_settings_opening_settings",
      icon: faTimer,
      component: OpeningOptions,
    },
    {
      title: "display_settings_frequency_settings",
      icon: faRotateLeft,
      component: FrequencyOptions,
    },
  ];

  const intl = useIntl();

  const [expanded, setExpanded] = useState(
    displaySettingsAccordions?.filter((item) => !item?.hide)?.[0]?.title ||
      null,
  );

  const handleChangeAccordion = (value) => {
    if (expanded === value) setExpanded(null);
    else setExpanded(value);
  };

  return (
    <Box className="survey-builder-display-settings-tab-wrapper">
      {displaySettingsAccordions
        ?.filter((item) => !item?.hide)
        ?.map((item) => {
          const ComponentToRender = item?.component ? item?.component : null;

          if (item?.type === "switch")
            return (
              <Box
                id="display-settings-options-switch-accordion"
                key={item?.title}
              >
                <Box className="display-settings-switch-accordion-header-container">
                  <Box className="display-settings-options-title">
                    <FontAwesomeIcon icon={item?.icon} fixedWidth />
                    <Typography className="display-settings-options-title">
                      {CheckValueLocale(item?.title, "", {}, intl)}
                    </Typography>
                  </Box>
                  <Switch
                    checked={item?.value}
                    onChange={item?.onChange}
                    size="small"
                  />
                </Box>
                {item?.component ? (
                  <Box className="display-settings-switch-accordion-content-container">
                    <ComponentToRender {...props} />
                  </Box>
                ) : null}
              </Box>
            );

          return (
            <Accordion
              key={item?.title}
              id="display-settings-options-accordion"
              expanded={expanded === item?.title}
              onChange={() => handleChangeAccordion(item?.title)}
            >
              <AccordionSummary
                className="display-settings-options-accordion-header"
                expandIcon={
                  <FontAwesomeIcon icon={faAngleDown} fontSize={12} />
                }
              >
                <Box className="options-accordion-title-wrapper">
                  <FontAwesomeIcon icon={item?.icon} fixedWidth />
                  <Typography className="display-settings-options-accordion-title">
                    {CheckValueLocale(item?.title, "", {}, intl)}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails className="display-settings-options-accordion-body-wrapper">
                {ComponentToRender ? <ComponentToRender {...props} /> : null}
              </AccordionDetails>
            </Accordion>
          );
        })}
    </Box>
  );
};

export default DisaplySettingsTab;
