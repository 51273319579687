import { Box, FormControl } from "@mui/material";
import { parsePhoneNumber } from "libphonenumber-js";
import { useEffect, useState } from "react";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import "./userPhoneNumber.scss";
import { CheckValueLocale, maskPhone } from "utils/helpers";
import { useIntl } from "react-intl";

function UserPhoneNumber({
  phoneNumber,
  setPhoneNumber,
  formik,
  disabled,
  keyName = "phone_number",
}) {
  const intl = useIntl();
  const [isMaskedInputShown, setIsMaskedInputShown] = useState(true);
  const [phoneValue, setPhoneValue] = useState("");

  const changePhoneNumber = (e) => {
    try {
      const regex = /^[0-9\s]*$/; // can change with only number or spacing
      if (regex?.test(e?.[1])) {
        setPhoneValue(e?.[1]);
        setPhoneNumber(e?.[1] ? e?.[3]?.replace(/[^+\d]+/g, "") : "");
      }
    } catch {}
  };

  const changeFlagHandler = (e) => {
    try {
      setPhoneValue(e?.[0]);
      setPhoneNumber(e?.[0] ? e?.[2]?.replace(/[^+\d]+/g, "") : "");
    } catch {}
  };

  const handleCountryPhoneNumber = (phoneNumber) => {
    try {
      return parsePhoneNumber(phoneNumber)?.country?.toLowerCase();
    } catch {}
  };

  useEffect(() => {
    try {
      //use (!formik?.dirty) to reset PhoneValue when reset formik
      // if (!formik?.dirty)
      setPhoneValue(
        phoneNumber ? parsePhoneNumber(phoneNumber)?.formatNational() : "",
      );
    } catch {}
  }, [phoneNumber, formik?.dirty]);

  return (
    <Box id="user-phone-number-input">
      <FormControl>
        {isMaskedInputShown ? (
          <Box onClick={() => setIsMaskedInputShown(false)}>
            <IntlTelInput
              containerClassName="intl-tel-input"
              inputClassName="form-control react-tel-input"
              separateDialCode
              defaultCountry={
                phoneNumber ? handleCountryPhoneNumber(phoneNumber) : "sa"
              }
              excludeCountries={["il"]}
              autoHideDialCode
              nationalMode
              autocomplete="off"
              // format
              fieldName={keyName}
              disabled={disabled}
              value={phoneValue ? maskPhone(phoneValue) : ""}
              onPhoneNumberChange={() => {}}
              onSelectFlag={() => {}}
              enableSearch
            />
          </Box>
        ) : (
          <Box
            onClick={() => setIsMaskedInputShown(false)}
            onBlur={formik?.handleBlur}
            name={keyName}
          >
            <IntlTelInput
              containerClassName="intl-tel-input"
              inputClassName={`form-control react-tel-input ${!!formik?.errors?.[keyName] && !!formik?.touched?.[keyName] ? "user-phone-error" : ""}`}
              separateDialCode
              defaultCountry={
                phoneNumber ? handleCountryPhoneNumber(phoneNumber) : "sa"
              }
              excludeCountries={["il"]}
              autoHideDialCode
              nationalMode
              autocomplete="off"
              // format
              fieldName={keyName}
              value={phoneValue || ""}
              disabled={disabled}
              onPhoneNumberChange={(...args) => {
                changePhoneNumber(args);
              }}
              onSelectFlag={(...args) => {
                changeFlagHandler(args);
              }}
              enableSearch
            />
          </Box>
        )}
        {formik?.errors?.[keyName] && formik?.touched?.[keyName] ? (
          <Box className="user-phone-error-msg">
            {CheckValueLocale(formik?.errors?.[keyName], "", {}, intl)}
          </Box>
        ) : null}
      </FormControl>
    </Box>
  );
}

export default UserPhoneNumber;
