import React from "react";
import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { useDeleteReport } from "../../hooks/useDeleteReport";
import PopupModal from "components/popupModal";

const DeleteReportModal = ({ id, title, open, setOpen, setSnackbar }) => {
  const intl = useIntl();

  const { mutate: deleteReport, isPending: deleteLoading } = useDeleteReport();

  const handleDeleteReport = () => {
    deleteReport(
      { id },
      {
        onSuccess: () => {
          setSnackbar({
            open: true,
            severity: "success",
            title: "",
            message: "report_delete_success",
          });
        },
        onError: () => {
          setSnackbar({
            open: true,
            severity: "error",
            title: "",
            message: "report_delete_success",
          });
        },
        onSettled: () => setOpen(false),
      },
    );
  };

  return (
    <PopupModal
      open={open}
      close={() => setOpen(false)}
      title={CheckValueLocale("delete_report", "", {}, intl)}
      body={
        <Box id="delete-report-modal-body">
          <Typography>
            {CheckValueLocale(
              "report_delete_text1",
              "",
              { reportName: title },
              intl,
            )}
          </Typography>
          <Typography variant="h6">
            {CheckValueLocale("report_delete_text2", "", {}, intl)}
          </Typography>
        </Box>
      }
      minWidth="600px"
      classeName="actions-buttons-shared "
      warning={true}
      leftBtn={CheckValueLocale("cancel", "", {}, intl)}
      rightBtn={CheckValueLocale("delete_confirm", "", {}, intl)}
      isRightBtnLoading={deleteLoading}
      accept={() => handleDeleteReport()}
    />
  );
};

export default DeleteReportModal;
