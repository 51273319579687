import { Box, Tooltip } from "@mui/material";

import PDFFileIcon from "images/engagements-images/file-icons/pdf.png";
import TXTFileIcon from "images/engagements-images/file-icons/txt.png";
import PPTFileIcon from "images/engagements-images/file-icons/ppt.png";
import DOCFileIcon from "images/engagements-images/file-icons/doc.png";
import EXCELFileIcon from "images/engagements-images/file-icons/excel.png";
import GenericFileIcon from "images/engagements-images/file-icons/general.svg";

import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons";
import TruncatedTooltip from "shared/truncatedTooltip/truncatedTooltip";

// Mapping of MIME types to file type labels
const mimeTypeMapping = {
  // Documents
  "application/pdf": "PDF",
  "application/msword": "Doc",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    "Doc",
  "application/vnd.ms-excel": "Excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "Excel",
  "application/vnd.ms-powerpoint": "PPT",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    "PPT",
  "text/plain": "TXT",
  "text/csv": "CSV",

  // Images
  "image/jpeg": "JPEG",
  "image/jpg": "JPG",
  "image/png": "PNG",
  "image/webp": "WEBP",
  "image/svg+xml": "SVG",
  "image/gif": "GIF",

  // Audio
  "audio/mpeg": "MP3",
  "audio/aac": "AAC",
  "audio/wav": "WAV",
  "audio/ogg": "OGG",
  "audio/flac": "FLAC",

  // Video
  "video/mp4": "MP4",
  "video/x-msvideo": "AVI",
  "video/quicktime": "MOV",

  // Archives
  "application/zip": "Zip",
  "application/x-rar-compressed": "RAR",
  "application/x-7z-compressed": "7Z",

  // Others
  "application/vnd.android.package-archive": "APK",
  "text/html": "HTML",
  "application/json": "JSON",
  "application/xml": "XML",
  "application/yaml": "YAML",
};

// Mapping of file types to their corresponding icons
const fileIconMapping = {
  PDF: PDFFileIcon,
  TXT: TXTFileIcon,
  PPT: PPTFileIcon,
  Doc: DOCFileIcon,
  Excel: EXCELFileIcon,
};

const getFileType = (mimeType) =>
  mimeTypeMapping[mimeType] ||
  mimeType?.split("/")?.[1]?.toUpperCase() ||
  "FILE";

const getFileIcon = (fileType) => fileIconMapping[fileType] || GenericFileIcon;

export const FileMessage = ({ message = {}, messageId }) => {
  const intl = useIntl();
  const { media: file, payload } = message;

  if (!file?.media_url) {
    return null;
  }

  const fileType = getFileType(file?.contentType || payload?.contentType);

  const handleFileDownload = () => {
    let downloadableURL = file?.media_url;
    // in case the donwload query param is set to false, set it to true
    try {
      const url = new URL(downloadableURL);
      const downloadQueryParam = url.searchParams.get("download");
      if (downloadQueryParam === "false") {
        url.searchParams.set("download", "true");
      }
      downloadableURL = url.toString();
    } catch (err) {
      // noop
    }
    //open it in a new tab this will download the file
    window.open(downloadableURL, "_blank");
  };

  return (
    <Box className="chat-file-message">
      <Box className="chat-file-message-icon">
        <img src={getFileIcon(fileType)} alt="file-icon" />
      </Box>
      <Box className="chat-file-message-info">
        <TruncatedTooltip
          className="chat-file-message-info-name"
          title={payload?.name}
          placement="bottom"
          arrow
        />
        <TruncatedTooltip
          className="chat-file-message-info-type"
          title={fileType}
          placement="bottom"
          arrow
        />
      </Box>
      <Tooltip
        title={CheckValueLocale("download_file", "", {}, intl)}
        arrow
        placement="bottom"
        classes={{ tooltip: "chat-media-tooltip-button" }}
      >
        <Box>
          <LucButton
            variant="text"
            onClick={handleFileDownload}
            minWidth={33}
            size="small"
            className="chat-file-message-download"
            id={`download-file-${messageId}`}
          >
            <FontAwesomeIcon icon={faArrowDownToLine} />
          </LucButton>
        </Box>
      </Tooltip>
    </Box>
  );
};
