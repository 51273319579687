import { CheckValueLocale } from "utils/helpers";

export const responseTimeOptions = (intl) => [
  {
    name: CheckValueLocale("instant_reply", "", {}, intl),
    id: "instant_reply",
  },
  {
    name: CheckValueLocale(
      "ai_agent_response_time_end_after_three_seconds",
      "",
      {},
      intl,
    ),
    id: "three_seconds",
  },
  {
    name: CheckValueLocale(
      "ai_agent_response_time_end_after_five_seconds",
      "",
      {},
      intl,
    ),
    id: "five_seconds",
  },
  {
    name: CheckValueLocale(
      "ai_agent_response_time_end_after_ten_seconds",
      "",
      {},
      intl,
    ),
    id: "ten_seconds",
  },
  {
    name: CheckValueLocale(
      "ai_agent_response_time_end_after_fifteen_seconds",
      "",
      {},
      intl,
    ),
    id: "fifteen_seconds",
  },
  {
    name: CheckValueLocale(
      "ai_agent_response_time_end_after_thirty_seconds",
      "",
      {},
      intl,
    ),
    id: "thirty_seconds",
  },
  {
    name: CheckValueLocale(
      "ai_agent_response_time_end_after_one_minute",
      "",
      { minutes: 1 },
      intl,
    ),
    id: "one_minute",
  },
  {
    name: CheckValueLocale(
      "ai_agent_response_time_end_after_five_minutes",
      "",
      { minutes: 5 },
      intl,
    ),
    id: "five_minutes",
  },
];
