import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import NewsBlogPlaceholder from "images/placeholders/news_blogs_placeholder.svg";
import corsErrorImage from "images/placeholders/cors_error.svg";

async function checkWidgetForCORSErrors(widgetElement) {
  const images = widgetElement.querySelectorAll("img"); // Get all images in the widget

  for (const img of images) {
    const isCORSValid = await checkImageCORS(img.src);
    if (!isCORSValid) {
      const parentClass = img?.parentElement?.className;
      img.src = parentClass?.includes("publisher-image")
        ? NewsBlogPlaceholder
        : corsErrorImage;
    }
  }
}

async function checkImageCORS(imageUrl) {
  return new Promise((resolve) => {
    const img = new Image();
    img.crossOrigin = "anonymous"; // Set crossorigin attribute for CORS check
    img.src = imageUrl;

    img.onload = () => resolve(true); // Image loaded successfully
    img.onerror = () => resolve(false); // Image failed to load due to CORS error
  });
}

// Helper function to ensure all images are loaded
async function preloadImages(container) {
  const images = container.querySelectorAll("img");
  const promises = Array.from(images).map(
    (img) =>
      new Promise((resolve, reject) => {
        if (img.complete) {
          resolve();
        } else {
          img.onload = resolve;
          img.onerror = () => {
            resolve(); // Skip failing images
          };
        }
      }),
  );
  return Promise.all(promises);
}

const toAddNewPagePdf = async (
  element,
  containerWidgets,
  pageWidth,
  doc,
  lastPage,
) => {
  const canvas = await html2canvas(element, { scale: 2 });
  const imgData = canvas?.toDataURL("image/png");
  const imgHeight = (canvas?.height * pageWidth) / canvas?.width;

  await doc?.addImage(imgData, "PNG", 0, 0, pageWidth, imgHeight);
  !lastPage && (await doc?.addPage());
  containerWidgets.replaceChildren(); // Clears all children
};

export const exportPDF = async (
  widgets,
  fileName,
  setPdfLoading,
  handleSnackBarSuccess,
  handleSnackBarError,
  showCover,
  widgetIdForElement,
) => {
  setPdfLoading(true);
  try {
    const doc = new jsPDF("p", "mm", "a4", true);
    const pageWidth = 210;

    //////////////// add cover to Report /////////////////
    if (showCover) {
      const coverReport = document?.getElementById(`main-pdf-content-cover`);
      const canvas = await html2canvas(coverReport, { scale: 2 });
      const pageWidth = 210;
      const imgHeight = (canvas?.height * pageWidth) / canvas?.width;
      const imgData = canvas?.toDataURL("image/png");
      await doc?.addImage(
        imgData,
        "PNG",
        0,
        0,
        pageWidth,
        imgHeight,
        "",
        "SLOW",
      );
      await doc?.addPage();
    }

    //////////////// add widgets to Report /////////////////
    //this is the container for widgets
    const containerWidgetsReport = document?.getElementById(
      `main-pdf-content-widgets`,
    );
    //this is the page for PDF
    const pageContainerWidgetsReport = document?.getElementById(
      `main-pdf-content-page`,
    );

    //this is the page for PDF
    const containerWidget = document?.getElementById(
      `articles-container-cards`,
    );

    let containerWidgetsReportHeight = 1300; // this for height for container of widgets for each new page
    //loop on all wigets in dashboard
    for (let index = 0; index < widgets?.length; index++) {
      //hold each widget by id from file "WidgetComponent"
      const widgetElement = document
        ?.getElementById(`${widgetIdForElement}${index}`)
        ?.cloneNode(true);
      //add this calss name to donnot show in dom
      widgetElement.className += " not-show-in-dom";
      // to render in the dom
      containerWidget?.appendChild(widgetElement);

      await checkWidgetForCORSErrors(widgetElement); //to replace image is error by placeholder
      // break; // don't add these widgets to PDF file
      // Ensure all images are loaded
      await preloadImages(widgetElement);

      const widgetHeight = widgetElement?.offsetHeight + 40;

      // page in pdf 1200px , content header & border-footer (100px)
      if (0 > containerWidgetsReportHeight - widgetHeight) {
        // if height(1100) of PDF page is  the smaller than height of (containerWidgetsReport + newWidget)
        await toAddNewPagePdf(
          pageContainerWidgetsReport, // to add this page
          containerWidgetsReport, //to remove widgets for this page after add this page to pdf
          pageWidth,
          doc,
        ); // to add this page to pdf and add new blank page

        containerWidgetsReportHeight = 1300; //reset height for new page
      }

      // to tranform the widget to image
      const canvasWidget = await html2canvas(widgetElement, {
        scale: 2,
        useCORS: true, // Allow cross-origin images
        allowTaint: false, // Prevent tainting issues
      });

      containerWidget?.removeChild(widgetElement); // remove from dom after take the canvas

      const imgDataa = new Image();
      imgDataa.src = canvasWidget?.toDataURL("image/png");
      const newWidget = document?.createElement("div"); // to appand the image and can control the style
      containerWidgetsReportHeight -= widgetHeight;

      newWidget?.appendChild(imgDataa);
      containerWidgetsReport?.appendChild(newWidget); // to add new widget to container
    }

    await toAddNewPagePdf(
      pageContainerWidgetsReport, // to add this page
      containerWidgetsReport, //to remove widgets for this page after add this page to pdf
      pageWidth,
      doc,
      true,
    ); // to add the final page before save
    await doc?.save(fileName ? fileName : "report"); // the final to save and print pdf

    handleSnackBarSuccess();
  } catch (error) {
    handleSnackBarError();
  } finally {
    setPdfLoading(false);
  }
};
