import { useQuery } from "@tanstack/react-query";
import { ReportServices } from "services/api/reports";

export const useReportFrequencies = () => {
  return useQuery({
    queryKey: ["reportFrequencies"],
    queryFn: () => ReportServices.getReportFrequencies(),
    select: (data) => data?.data,
  });
};
