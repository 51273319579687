import { useQuery } from "@tanstack/react-query";
import { CreateMonitorServices } from "services/api/createMonitorServices";

export const useGetSummarySmTokens = () => {
  return useQuery({
    queryKey: ["summarySmTokens"],
    queryFn: CreateMonitorServices.getSummarySmTokens,
    select: (data) => data?.data,
  });
};
