import { useIntl } from "react-intl";
import { Checkbox, FormControl, MenuItem, Select } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";

const DisplayOptionSelect = (props) => {
  const intl = useIntl();

  return (
    <FormControl
      className={`display-settings-options-select ${props?.className || ""}`}
      size="small"
    >
      <Select
        size="small"
        MenuProps={{
          anchorOrigin: { vertical: props?.menuDirection || "bottom" },
          transformOrigin: {
            vertical: props?.menuDirection === "top" ? "bottom" : "top",
          },
          getContentAnchorEl: null,
          PaperProps: { style: { maxHeight: "170px" } },
        }}
        {...props}
      >
        {props?.options?.map((option) => (
          <MenuItem
            key={option?.value}
            id="display-settings-options-select-menu-item"
            className={props?.fontsList ? "fonts-select" : ""}
            style={props?.fontsList ? { fontFamily: option?.value } : {}}
            value={option?.value}
            disabled={
              props?.disabledMenuItems && props?.value?.includes(option?.value)
            }
            size="small"
          >
            {props?.multiple ? (
              <Checkbox
                className="display-settings-options-select-checkbox"
                size="small"
                checked={props?.value?.includes(option?.value)}
              />
            ) : null}
            {CheckValueLocale(option?.label, "", {}, intl)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DisplayOptionSelect;
