import { useQuery } from "@tanstack/react-query";
import SurveyController from "services/controllers/surveyController";

export const useEmbeddedOptions = ({ enabled }) => {
  return useQuery({
    queryKey: ["embeddedOptions"],
    queryFn: () => SurveyController.getEmbeddedSurveyOptions(),
    select: (data) => data?.data?.filters,
    enabled: enabled,
    retry: false,
  });
};
