import { Typography, Card } from "@mui/material";

const CardStatistic = ({ title, value }) => {
  return (
    <Card className="report-detail">
      <Typography variant="subtitle2">{title?.toUpperCase()}</Typography>
      <Typography variant="subtitle1">{value}</Typography>
    </Card>
  );
};

export default CardStatistic;
