import { useIntl } from "react-intl";
import HashtagsHeader from "./components/hashtagsHeader/hashtagsHeader";
import HashtagsTable, {
  getHashtagsListTableHeader,
} from "./components/hashtagsTable/hashtagsTable";
import "./style.scss";
import { useSortableTable } from "hooks/useSortableTable";
import { useEffect, useState } from "react";
import { Pagination } from "@mui/material";
import CircularLoading from "components/circularProgress";
import HashtagsFilter from "./components/hashtagsFilter/hashtagsFilter";
import SnackBar from "components/snackBar";
import { useGetHashtags } from "./hooks/useGetHashtags";
import { useGetHeaderHashtags } from "./hooks/useGetHeaderHashtags";

const InstagramHashtags = () => {
  const intl = useIntl();

  const [resultCompanyHashtags, setResultCompanyHashtags] = useState({});
  const [searchHashtags, setSearchHashtags] = useState("");
  const [statusHashtags, setStatusHashtags] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(1);
  const [snakBarDelete, setSnakBarDelete] = useState({
    open: false,
    title: "",
    message: "",
    severity: "error",
  });

  const onSortChangeCallback = () => setPage(1);

  const {
    columns: tableHead,
    sortColumn,
    sortDirection,
  } = useSortableTable({
    initialColumns: getHashtagsListTableHeader(intl),
    onSortChangeCallback,
    defaultSelectedColumn: "id",
    defaultDirection: "desc",
  });

  const { data: hashtagsData, isPending: isPendingCompanyHashtags } =
    useGetHashtags({
      searchInput,
      statusHashtags,
      page,
      sortColumn,
      sortDirection,
    });

  useEffect(() => {
    if (hashtagsData) setResultCompanyHashtags(hashtagsData);
  }, [hashtagsData]);

  const handleChangePage = (event, page) => {
    if (Boolean(page) === false) return;
    setPage(page);
  };

  const { data: hashtagsHeader, isPending: headerLoading } =
    useGetHeaderHashtags();

  const hanldeSearchIconClick = () => {
    setSearchInput(searchHashtags);
  };

  const handleCloseSnackbar = () => {
    setSnakBarDelete({
      open: false,
      title: "",
      message: "",
      severity: "error",
    });
  };
  const checkResultCompanyHashtags = resultCompanyHashtags?.data?.length;

  return (
    <>
      {headerLoading && isPendingCompanyHashtags ? (
        <CircularLoading />
      ) : (
        <>
          <HashtagsHeader
            hashtagsHeader={hashtagsHeader}
            hashtags={
              resultCompanyHashtags?.data?.map((item) => item?.attributes) || []
            }
            searchInput={searchInput}
          />
          <>
            {checkResultCompanyHashtags ||
            statusHashtags?.length ||
            searchInput ? (
              <HashtagsFilter
                hashtagsHeader={hashtagsHeader}
                searchHashtags={searchHashtags}
                setSearchHashtags={setSearchHashtags}
                hanldeSearchIconClick={hanldeSearchIconClick}
                statusHashtags={statusHashtags}
                setStatusHashtags={setStatusHashtags}
                resultCompanyHashtags={resultCompanyHashtags}
                searchInput={searchInput}
                checkResultCompanyHashtags={checkResultCompanyHashtags}
              />
            ) : null}
            <HashtagsTable
              hashtags={
                resultCompanyHashtags?.data?.map((item) => item?.attributes) ||
                []
              }
              tableHead={tableHead}
              loading={isPendingCompanyHashtags}
              searchText={searchHashtags}
              statusHashtags={statusHashtags}
              setSnakBarDelete={setSnakBarDelete}
              page={page}
              setPage={setPage}
            />
            {resultCompanyHashtags?.pagination?.pages > 1 ? (
              <Pagination
                onChange={handleChangePage}
                page={page}
                className="pagination-monitor-list settings-page-content-pagination"
                count={resultCompanyHashtags?.pagination?.pages}
                variant="outlined"
                rowsPerPage={resultCompanyHashtags?.pagination?.items}
              />
            ) : (
              <div className="pagination-one-page"></div>
            )}
          </>
        </>
      )}
      <SnackBar
        open={snakBarDelete?.open}
        severity={snakBarDelete?.severity}
        message={snakBarDelete?.message}
        title={snakBarDelete?.title}
        handleClose={handleCloseSnackbar}
      />
    </>
  );
};
export default InstagramHashtags;
