import { useQuery } from "@tanstack/react-query";
import { agentStatusServices } from "services/api/agentStatus";

export const useGetCurrentAgentStatus = ({ enabled }) => {
  return useQuery({
    queryKey: ["agentCurrentStatus"],
    queryFn: () => agentStatusServices.getCurrentAgentStatus(),
    select: (data) => data?.data?.data?.attributes?.status,
    enabled,
  });
};
