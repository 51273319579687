import { useEffect, useState, useRef } from "react";
import axios from "axios";
import VideoFrame from "react-video-thumbnail";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import { useIntl } from "react-intl";
import {
  TextField,
  Breadcrumbs,
  Link,
  Box,
  MenuItem,
  Select,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  Divider,
  Popover,
  Button,
  Tooltip,
} from "@mui/material";
import "./replyModal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import {
  faPaperPlane,
  faImage,
  faVideo,
  faSpinner,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import gifSvg from "images/gif-icon.svg";
import {
  CheckValueLocale,
  convertXPlatformToTwitter,
  getNumberOfChar,
  handlelUserRoles,
} from "utils/helpers";
import MonitorsController from "services/controllers/monitorsController";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import DefaultAccountImg from "./defaultAccountImg";
import SavedRepliesModal from "./savedRepliesModal";
import CreateRepliesModal from "./createRepliesModal";
import PopupModal from "components/popupModal";
import SnackBar from "components/snackBar.js";
import { useDispatch } from "react-redux";
import { cardData } from "utils/redux/features/Engagements/EngagementsSlice.js";
import DOMPurify from "dompurify";

const ReplyModal = (props) => {
  const intl = useIntl();
  const urlParams = useParams();
  const navigate = useNavigate();

  const inputRefGif = useRef(null);
  const inputRefVideo = useRef(null);
  const inputRefImg = useRef(null);
  const dispatch = useDispatch();

  const monitorId = urlParams.monitorId;
  const monitorDataSource = convertXPlatformToTwitter(
    urlParams.monitorDataSource,
  );
  const activeTab = urlParams.activeTab;

  const {
    question,
    showSuccessModal,
    setShowSuccessModal,
    monitorDataSourceType,
    index,
    replyModalData,
    setShowErrorSnackBar,
    setAnchorEl,
    setText,
    text,
  } = props;
  const repliedDate = question?.system_replies?.created_at;
  const replyBy = question?.system_replies?.replier_info?.email;
  const repliedAccount = question?.system_replies?.replier_info?.publisher_name;

  const [maxChar, setMaxChar] = useState(280);
  const [loading, setLoading] = useState(false);
  const [savedRepliesloader, setSavedRepliesLoader] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [accounts, setAccounts] = useState([]);
  const [accountsStatus, setAccountsStatus] = useState([]);
  const [accountsPageIds, setAccountsPageIds] = useState([]);
  const [selectedPageId, setSelectedPageId] = useState("");
  const [accountsImg, setAccountsImg] = useState([]);
  const [selectedAccountImg, setSelectedAccountImg] = useState("");
  const [accountsIds, setAccountsIds] = useState([]);
  const [selectedAccountId, setSelectedAccountId] = useState();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [iconToDisplay, setIconToDisplay] = useState(null);
  const [anchorEll, setAnchorEll] = useState(null);
  const [showCreateRepliesModal, setShowCreateRepliesModal] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [replyToDelete, setReplyToDelete] = useState("");
  const [engagementId, setEngagementId] = useState("");
  const [savedReplies, setSavedReplies] = useState([]);
  const [savedReplyIds, setSavedReplyIds] = useState([]);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackBarSeverity, setSnackbarSeverity] = useState("");
  const [snackBarMessage, setSnackbarMessage] = useState("");
  const [snackBarTitle, setSnackbarTitle] = useState("");
  const [injectedWord, setInjectedWord] = useState({});

  const [selectedFile, setSelectedFile] = useState([]);
  const [base64Code, setBase64Code] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [sendBtnDisabled, setSendBtnDisabled] = useState(false);
  const [mediaErr, setMediaErr] = useState("");
  const [getAllAccounts, setGetAllAccounts] = useState([]);
  const [allawedAccounts, setAllawedAccounts] = useState([]);

  const isImageMimeType = (mimeType) => {
    return /^image\/\*/.test(mimeType);
  };

  const handleCloseSnack = () => {
    setShowSnackBar(false);
  };

  const handleClick = (event) => {
    setAnchorEll(event.currentTarget);
  };

  const handleCloseSavedReplies = () => {
    setAnchorEll(null);
    setShowCreateRepliesModal(false);
  };

  const open = Boolean(anchorEll);
  const id = open ? "simple-popover" : undefined;

  const handleChange = (event) => {
    setText(event.target.value);
  };

  const handleAttachmentsUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (Math.round(file?.size / (1024 * 1024)) > 10) {
        setMediaErr("size_media_err");
      } else if (
        event.target.id === "gif-input" &&
        file?.type !== "image/gif"
      ) {
        setMediaErr("gif_media_err");
      } else if (
        event.target.id === "video-input" &&
        file?.type !== "video/mp4"
      ) {
        setMediaErr("vid_media_err");
      } else if (
        event.target.id === "image-input" &&
        file?.type !== "image/png" &&
        file?.type !== "image/jpeg"
      ) {
        setMediaErr("img_media_err");
      } else {
        setMediaErr("");
        const reader = new FileReader();
        reader.onload = () => {
          setBase64Code([...base64Code, reader.result]);
        };
        reader.readAsDataURL(file);
        setSelectedFile([...selectedFile, file]);
      }
      const formData = new FormData();

      formData.append("upload_file", file);
      formData.append("publisher_name", selectedValue);
      formData.append("account_info_id", selectedAccountId);
      if (monitorDataSource !== "TWITTER")
        formData.append("page_id", selectedPageId);
      formData.append("monitor_id", parseInt(monitorId));
      formData.append("data_source_name", monitorDataSource);
      formData.append("product_id", window.localStorage.sm_id);
      formData.append("media_type", file?.type);
      //stg env
      //https://engupload.lucidya.com/upload
      try {
        setSendBtnDisabled(true);
        const response = await axios.post(
          "https://eng-upload.prod.lucidya.com/upload",
          formData,
          {
            headers: {
              "luc-authorization": window.localStorage.user_token,
            },
          },
        );
        setAttachments([...attachments, response?.data]);
        setSendBtnDisabled(false);
      } catch (error) {
        setSendBtnDisabled(false);
      }
    }
  };
  const handleRemoveAttachments = (index) => {
    const attachments = [...selectedFile];
    attachments.splice(index, 1);
    setSelectedFile(attachments);

    const base64 = [...base64Code];
    base64.splice(index, 1);
    setBase64Code(base64);

    const attachment = [...attachments];
    attachment.splice(index, 1);
    setAttachments(attachment);

    if (inputRefGif?.current) {
      inputRefGif.current.value = null;
    }
    if (inputRefVideo?.current) {
      inputRefVideo.current.value = null;
    }
    if (inputRefImg?.current) {
      inputRefImg.current.value = null;
    }
  };

  const handleChangeDropdown = (event) => {
    setSelectedValue(event.target.value);
    replyModalData[index] = {
      selectedValue: event.target.value,
      replyTime: replyModalData[index].replyTime,
    };
  };

  useEffect(() => {
    if (
      !showSuccessModal &&
      (question?.system_replies?.is_replied === undefined ||
        question?.system_replies?.is_replied === 0)
    ) {
      let accountsData = [...accounts];
      let accountsProfileImg = [...accountsImg];
      let accountInfoId = [...accountsIds];
      let accountStatus = [...accountsStatus];
      let accountPageIds = [...accountsPageIds];
      let newDataAccounts = [];

      setLoading(true);
      MonitorsController.getAllawedAccounts(
        window?.localStorage?.sm_id,
        "reply",
      ).then((AllawedData) => {
        let allawedAccountArr =
          AllawedData?.data?.data?.user_accounts?.[monitorDataSource];
        MonitorsController.getAllAccounts(window.localStorage.sm_id)
          .then((data) => {
            if (data?.data?.status === 200) {
              let dropdownData = data?.data;
              if (
                monitorDataSource === "FACEBOOK" ||
                monitorDataSource === "INSTAGRAM"
              ) {
                dropdownData?.data[monitorDataSource]?.included?.map(
                  (accountData) => {
                    // commented for now, may be needed later
                    // if (
                    //   accountData?.attributes?.used_in_private_channel === 0 &&
                    //   accountData?.attributes?.used_in_public_channel === 0
                    // ) {
                    if (allawedAccountArr?.includes(+accountData?.id)) {
                      if (monitorDataSource === "INSTAGRAM")
                        accountsData?.push(accountData?.attributes?.user_name);
                      else accountsData?.push(accountData?.attributes?.name);
                      accountsProfileImg?.push(
                        accountData?.attributes?.image_url,
                      );
                      accountInfoId?.push(
                        accountData?.attributes?.account_info_id,
                      );
                      accountStatus?.push(accountData?.attributes?.status);
                      accountPageIds?.push(accountData?.attributes?.page_id);
                    }
                    newDataAccounts.push(accountData);

                    // }
                  },
                );
              } else {
                dropdownData?.data[monitorDataSource]?.data?.map(
                  (accountData) => {
                    // commented for now, may be needed later
                    // if (
                    //   accountData?.attributes?.used_in_private_channel === 0 &&
                    //   accountData?.attributes?.used_in_public_channel === 0
                    // ) {
                    if (allawedAccountArr?.includes(+accountData?.id)) {
                      accountsData?.push(accountData?.attributes?.user_name);
                      accountsProfileImg?.push(
                        accountData?.attributes?.image_url,
                      );
                      accountInfoId?.push(accountData?.attributes?.id);
                      accountStatus?.push(accountData?.attributes?.status);
                    }
                    newDataAccounts.push(accountData);

                    // }
                  },
                );
              }
              let allawedArr = [];
              newDataAccounts?.map((item) => {
                if (allawedAccountArr?.includes(+item?.id)) {
                  allawedArr.push(+item?.id);
                }
              });
              setAllawedAccounts(allawedArr);
              setGetAllAccounts([...newDataAccounts]);
              setAccounts(accountsData);
              setAccountsImg(accountsProfileImg);
              setAccountsIds(accountInfoId);
              setAccountsStatus(accountStatus);
              setAccountsPageIds(accountPageIds);
              setLoading(false);
            } else {
              setShowErrorSnackBar(true);
              setAnchorEl(null);
              setLoading(false);
            }
          })
          .catch((error) => {
            setLoading(false);
          });
      });
    }
  }, []);

  const getAllSavedReplies = () => {
    setSavedRepliesLoader(true);
    MonitorsController.getAllSavedReplies(window.localStorage.sm_id)
      .then((data) => {
        setSavedReplies(data?.data?.data);
        setSavedRepliesLoader(false);
      })
      .catch((err) => {
        setSavedRepliesLoader(false);
        setAnchorEll(null);
        setShowSnackBar(true);
        setSnackbarSeverity("error");
        setSnackbarMessage("please_try_again");
        setSnackbarTitle("wrong_request_parameter");
      });
  };

  useEffect(() => {
    const index = accounts.indexOf(selectedValue);
    if (index !== -1) {
      setSelectedAccountImg(accountsImg[index]);
      setSelectedAccountId(accountsIds[index]);
      setSelectedPageId(accountsPageIds[index]);
    }
  }, [selectedValue]);

  const handleReply = () => {
    if (
      (text?.trim()?.length === 0 ||
        selectedValue === "" ||
        text?.length > maxChar) &&
      !base64Code?.length
    )
      return;
    else {
      setLoading(true);
      MonitorsController.postReplies(
        window.localStorage.sm_id,
        selectedAccountId,
        selectedValue,
        monitorDataSource,
        parseInt(monitorId),
        question?.tweet_id,
        question?.tweet_id === null
          ? "post"
          : activeTab === "posts" || activeTab === "account"
            ? "comment"
            : "reply",
        text?.trimStart(),
        undefined,
        attachments,
      ).then((data) => {
        if (data?.data?.status === 201) {
          setEngagementId(data?.data?.data?.engagement_id);
          setShowSuccessModal(true);
          setShowErrorModal(false);
          replyModalData[index] = {
            selectedValue: replyModalData[index].selectedValue,
            replyTime: moment
              .utc()
              .add(parseInt(localStorage.getItem("companyInfo")), "hours")
              .format("DD/MM/YYYY - hh:mm A"),
          };
          setText("");
        } else {
          setShowErrorModal(true);
          setShowSuccessModal(false);
        }
        setLoading(false);
      });
    }
  };
  const handleRedirectToEngagement = () => {
    dispatch(
      cardData({
        ...question,
        system_replies: {
          ...question?.system_replies,
          engagement_id: question?.system_replies?.engagement_id
            ? question?.system_replies?.engagement_id
            : engagementId,
        },
      }),
    );
    navigate("/engagements/main/inbox");
  };
  const successModal = () => (
    <Box>
      <Box className="replied-success-modal">
        <FontAwesomeIcon icon={faCheckCircle} />
        <Box component="span">
          {CheckValueLocale("replied_success", "", {}, intl)}
        </Box>
        <Box>{`[${CheckValueLocale("replied_using", "", {}, intl)} ${
          repliedAccount ? repliedAccount : replyModalData[index]?.selectedValue
        }]`}</Box>
      </Box>
      <Divider className="success-modal-divider" />
      <Box className="success-modal-description">
        <Box component="span">{CheckValueLocale("by", "", {}, intl)}</Box>
        <Box>{replyBy ? replyBy : localStorage.getItem("email")}</Box>
      </Box>
      <Box className="success-modal-description">
        <Box component="span">
          {CheckValueLocale("reply_date", "", {}, intl)}
        </Box>
        <Box>
          {repliedDate
            ? moment?.unix(repliedDate).utc()?.format("DD/MM/YYYY - hh:mm A")
            : replyModalData[index]?.replyTime}
        </Box>
      </Box>
      {monitorDataSourceType == "TWITTER" &&
      (question?.system_replies?.engagement_id || engagementId.length) &&
      (handlelUserRoles("ENGAGEMENT_ACCESS", "FULL_ACCESS") ||
        handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX")) ? (
        <Box
          className="view-engagement-details"
          onClick={handleRedirectToEngagement}
        >
          {CheckValueLocale("view_engagement_details", "", {}, intl)}
        </Box>
      ) : null}
    </Box>
  );

  const errorModal = () => (
    <Box>
      <Box className="replied-error-modal">
        <CancelIcon />
        <Box component="span">
          {CheckValueLocale("wrong_request_parameter", "", {}, intl)}
        </Box>
      </Box>
      <Divider className="success-modal-divider" />
      <Box className="error-modal-description">
        <Box component="span">{CheckValueLocale("please", "", {}, intl)}</Box>
        <Box onClick={() => setShowErrorModal(false)}>
          {CheckValueLocale("reply_try_again", "", {}, intl)}
        </Box>
      </Box>
    </Box>
  );

  const deleteModalBody = () => {
    return (
      <Box className="delete-modal-content">
        <Box>
          {CheckValueLocale("delete_saved_replies_body", "", {}, intl)}
          <Box component="span">{replyToDelete?.attributes?.title}</Box>
        </Box>
        <Box>
          {CheckValueLocale("delete_saved_replies_description", "", {}, intl)}
        </Box>
      </Box>
    );
  };

  const unAuthenticatedAccountModal = () => (
    <Box>
      <Box className="unauthenticated-accounts-modal-heading">
        <ErrorOutlineOutlinedIcon />
        <Box component="span">
          {CheckValueLocale("authentication_required", "", {}, intl)}
        </Box>
      </Box>
      <Box className="max-width-auth">
        <Box component="span" className="unauthenticated-accounts-modal-sl">
          {CheckValueLocale("all_accounts_unauthenticated", "", {}, intl)}
        </Box>
        <Box
          component="span"
          className="companies-settings-style-sl"
          onClick={() => navigate("/settings")}
        >
          {CheckValueLocale("replies_company_settings", "", {}, intl)}
        </Box>
      </Box>
    </Box>
  );

  useEffect(() => {
    setIconToDisplay(null);
    (async () => {
      const image = new Image();
      image.src = selectedAccountImg;
      image.onload = () => {
        setIconToDisplay(selectedAccountImg);
      };
    })();
  }, [selectedAccountImg]);

  const handleCloseDeleteModal = () => {
    setShowDeletePopup(false);
  };

  const handleDeleteSavedReplies = () => {
    setInjectedWord({});
    MonitorsController.deleteSavedReplies(
      replyToDelete?.attributes?.id,
      window.localStorage.engagements_id,
    )
      .then((data) => {
        getAllSavedReplies();
        setShowSnackBar(true);
        setSnackbarSeverity("success");
        setSnackbarTitle("saved_replies_deleted_successfully");
        setInjectedWord({ reply: replyToDelete?.attributes?.title });
        setShowDeletePopup(false);
      })
      .catch((error) => {
        setShowSnackBar(true);
        setSnackbarSeverity("error");
        setSnackbarMessage("please_try_again");
        setSnackbarTitle("wrong_request_parameter");
        setShowDeletePopup(false);
      });
  };

  const anchorPosition =
    intl.locale === "en"
      ? {
          vertical: "top",
          horizontal: "right",
        }
      : {
          vertical: "top",
          horizontal: "left",
        };

  const transformPosition =
    intl.locale === "en"
      ? {
          vertical: "bottom",
          horizontal: "right",
        }
      : {
          vertical: "bottom",
          horizontal: "left",
        };

  const disabledCondition = selectedValue === "";
  const disabledStyle = disabledCondition ? "disabled-icons" : "modal-icons";
  const disabledStyleFirst = disabledCondition
    ? "disabled-icons"
    : "first-icon";

  return (
    <Box className="reply-modal reply-modal-social-listening">
      {loading ? (
        <Box className="loading-style">
          <FontAwesomeIcon icon={faSpinner} pulse />
        </Box>
      ) : !showSuccessModal &&
        !showErrorModal &&
        (question?.system_replies?.is_replied === undefined ||
          question?.system_replies?.is_replied === 0) ? (
        <>
          <Box
            className={
              selectedValue === ""
                ? "reply-modal-dropdown"
                : "reply-modal-dropdown-icon"
            }
          >
            {selectedValue !== "" && (
              <List>
                <ListItem sx={{ padding: "12px 10px" }}>
                  <ListItemAvatar className="account-avtar">
                    {iconToDisplay !== null ? (
                      <Avatar src={selectedAccountImg} variant="rounded" />
                    ) : (
                      <div className="account-default-img">
                        <DefaultAccountImg selectedValue={selectedValue} />
                      </div>
                    )}
                  </ListItemAvatar>
                </ListItem>
              </List>
            )}
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedValue}
              onChange={handleChangeDropdown}
              displayEmpty
              IconComponent={ArrowDropDownRoundedIcon}
            >
              <MenuItem disabled value="" style={{ display: "none" }}>
                <em>{CheckValueLocale("select_account", "", {}, intl)}</em>
              </MenuItem>
              {accounts?.length ? (
                accounts?.map((account, i) => {
                  let option = getNumberOfChar(account, intl);
                  return (
                    <MenuItem
                      value={account}
                      disabled={accountsStatus[i] !== "active"}
                    >
                      {option}
                    </MenuItem>
                  );
                })
              ) : (
                <MenuItem disabled value={null}>
                  <Box
                    id={"empty-reply-accounts"}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        CheckValueLocale(
                          !getAllAccounts?.length
                            ? "no_accounts"
                            : !allawedAccounts?.length
                              ? "no_permission"
                              : "no_accounts",
                          "",
                          {},
                          intl,
                        ),
                      ),
                    }}
                  />
                </MenuItem>
              )}
            </Select>
          </Box>
          <Box className="textfield-container">
            <Box
              className={
                selectedFile?.length > 0 ? "textfield-with-attachments" : ""
              }
            >
              <TextField
                value={text}
                onChange={handleChange}
                multiline
                maxRows={6}
                variant="outlined"
                placeholder={CheckValueLocale("enter_reply", "", {}, intl)}
                className="reply-modal-textarea"
              />
            </Box>
            <Box className="attachments-with-textfield">
              {base64Code?.length !== 0 &&
                base64Code?.map((base64, index) => {
                  return (
                    <Box className="attachments-container">
                      {selectedFile[index]?.type === "image/jpeg" ||
                      selectedFile[index]?.type === ".gif" ? (
                        <Box className="attachments-style">
                          <img src={base64} alt="Uploaded" />
                        </Box>
                      ) : (
                        <VideoFrame
                          videoUrl={base64}
                          thumbnailHandler={(thumbnail) => (
                            <img
                              src={thumbnail}
                              alt="Video Thumbnail"
                              style={{ maxWidth: "100%" }}
                            />
                          )}
                          width={33}
                          height={33}
                        />
                      )}
                      <CancelRoundedIcon
                        className="remove-attachments"
                        onClick={() => handleRemoveAttachments(index)}
                      />
                    </Box>
                  );
                })}
            </Box>
          </Box>
          {mediaErr ? (
            <span className="media-err">
              {CheckValueLocale(mediaErr, "", {}, intl)}
            </span>
          ) : null}
          <Box className="single-intr-wrapper reply-modal-footer">
            <Breadcrumbs
              aria-label="breadcrumb"
              className={
                text?.length > maxChar
                  ? "text-reached-limit"
                  : "reply-modal-breadcrumbs"
              }
            >
              <Link color="inherit">
                {text?.trim()?.length === 0 ? 0 : text?.trimStart()?.length}
              </Link>
              <Link color="inherit">{maxChar}</Link>
            </Breadcrumbs>
            <Box className="display-in-row">
              {/* <label className="saved-replied-btn" onClick={handleClick}>
                {CheckValueLocale("saved_replies", "", {}, intl)}
              </label>
              <Divider
                orientation="vertical"
                className="saved-replied-divider"
              /> */}
              <Popover
                anchorOrigin={anchorPosition}
                transformOrigin={transformPosition}
                onClose={handleCloseSavedReplies}
                id={id}
                open={open}
                anchorEl={anchorEll}
                className="saved-replies-popover"
              >
                {!showCreateRepliesModal ? (
                  <SavedRepliesModal
                    setText={setText}
                    setAnchorEl={setAnchorEll}
                    getAllSavedReplies={getAllSavedReplies}
                    savedReplies={savedReplies}
                    savedRepliesloader={savedRepliesloader}
                    setSavedReplyIds={setSavedReplyIds}
                    setReplyToDelete={setReplyToDelete}
                    setShowDeletePopup={setShowDeletePopup}
                    setShowCreateRepliesModal={setShowCreateRepliesModal}
                  />
                ) : (
                  <CreateRepliesModal
                    savedReplies={savedReplies}
                    setSavedReplies={setSavedReplies}
                    setShowSnackBar={setShowSnackBar}
                    setSnackbarMessage={setSnackbarMessage}
                    setSnackbarTitle={setSnackbarTitle}
                    setSnackbarSeverity={setSnackbarSeverity}
                    setShowCreateRepliesModal={setShowCreateRepliesModal}
                  />
                )}
              </Popover>

              {monitorDataSource !== "INSTAGRAM" ? (
                <>
                  <Tooltip
                    title={
                      attachments?.length === 4
                        ? CheckValueLocale("max_media_msg", "", {}, intl)
                        : ""
                    }
                    placement="top"
                    arrow
                  >
                    <label htmlFor="gif-input" className={disabledStyleFirst}>
                      <img src={gifSvg} />
                      <input
                        id="gif-input"
                        type="file"
                        accept=".gif, image/gif"
                        onChange={handleAttachmentsUpload}
                        className="modal-input-style"
                        disabled={setSendBtnDisabled || attachments === 4}
                        ref={inputRefGif}
                      />
                    </label>
                  </Tooltip>
                  <Tooltip
                    title={
                      attachments?.length === 4
                        ? CheckValueLocale("max_media_msg", "", {}, intl)
                        : ""
                    }
                    placement="top"
                    arrow
                  >
                    <label htmlFor="video-input" className={disabledStyle}>
                      <FontAwesomeIcon icon={faVideo} />
                      <input
                        id="video-input"
                        type="file"
                        accept="video/mp4,video/x-m4v,video/*"
                        onChange={handleAttachmentsUpload}
                        className="modal-input-style"
                        disabled={setSendBtnDisabled || attachments === 4}
                        ref={inputRefVideo}
                      />
                    </label>
                  </Tooltip>
                  <Tooltip
                    title={
                      attachments?.length === 4
                        ? CheckValueLocale("max_media_msg", "", {}, intl)
                        : ""
                    }
                    placement="top"
                    arrow
                  >
                    <label htmlFor="image-input" className={disabledStyle}>
                      <FontAwesomeIcon icon={faImage} />
                      <input
                        id="image-input"
                        type="file"
                        accept="image/png,image/jpg,image/jpeg"
                        onChange={handleAttachmentsUpload}
                        className="modal-input-style"
                        disabled={setSendBtnDisabled || attachments === 4}
                        ref={inputRefImg}
                      />
                    </label>
                  </Tooltip>
                </>
              ) : null}
              {/* <Box
                component="span"
                className={
                  text?.trim()?.length === 0 ||
                  selectedValue === "" ||
                  text?.length > maxChar ||
                  disableUploadBtn
                    ? "send-reply-btn"
                    : "send-reply-btn-active"
                }
                onClick={handleReply}
              >
                <FontAwesomeIcon icon={faPaperPlane} />
              </Box> */}
              <Button
                className="reply-modal-btn"
                onClick={handleReply}
                disabled={
                  (text?.trim()?.length === 0 && !base64Code?.length) ||
                  selectedValue === "" ||
                  text?.length > maxChar ||
                  sendBtnDisabled ||
                  attachments === 4
                }
              >
                <FontAwesomeIcon icon={faPaperPlane} />
              </Button>
            </Box>
          </Box>
        </>
      ) : showSuccessModal || question?.system_replies?.is_replied === 1 ? (
        successModal()
      ) : accounts?.length === 0 || !accountsStatus?.includes("active") ? (
        unAuthenticatedAccountModal()
      ) : (
        errorModal()
      )}
      {showDeletePopup ? (
        <PopupModal
          title={CheckValueLocale("delete_saved_replies", "", {}, intl)}
          body={deleteModalBody()}
          leftBtn={CheckValueLocale("cancel_btn", "", {}, intl)}
          rightBtn={CheckValueLocale("delete_btn", "", {}, intl)}
          open={showDeletePopup}
          close={handleCloseDeleteModal}
          accept={() => handleDeleteSavedReplies()}
          rightColor="error"
          warning
          leftBtnId="saved-replies-pop-up-cancel-left-btn"
          rightBtnId="saved-replies-pop-up-delete-right-btn"
        />
      ) : null}
      {showSnackBar && (
        <SnackBar
          open={showSnackBar}
          autoHideDuration={5000}
          severity={snackBarSeverity}
          message={CheckValueLocale(snackBarMessage, "", {}, intl)}
          title={CheckValueLocale(snackBarTitle, "", injectedWord, intl)}
          handleClose={handleCloseSnack}
        />
      )}
    </Box>
  );
};

export default ReplyModal;
