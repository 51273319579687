import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { faArrowToBottom } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckValueLocale } from "utils/helpers";
import CustomizedTooltip from "components/customizedTooltip";

const DownloadFileExcel = (props) => {
  const intl = useIntl();
  const { excelGuideData } = props;

  return (
    <Box className="accounts_list-download-file-container">
      <Typography component="span" className="download-file-title">
        {CheckValueLocale("accounts_list_download_file_title", "", {}, intl)}
      </Typography>
      <Box className="download-file-card">
        <Typography component="p" className="download-file-header">
          {excelGuideData?.name ? excelGuideData?.name : ""}
        </Typography>
        <CustomizedTooltip
          title={
            <Box className="item-title-tooltip">
              {CheckValueLocale("download_import_tooltip", "", {}, intl)}
            </Box>
          }
          arrow
          placement="bottom"
        >
          <Box className="import-download-icon">
            <a
              href={excelGuideData?.link}
              className={`${!excelGuideData?.link && "download-icon-fail"}`}
            >
              <FontAwesomeIcon icon={faArrowToBottom} />
            </a>
          </Box>
        </CustomizedTooltip>
      </Box>
    </Box>
  );
};

export default DownloadFileExcel;
