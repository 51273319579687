import React, { useContext } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import ReportSelectInput from "pages/reports/components/reportSelectInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { EditReportContext } from "pages/reports/context/reportContext";

const EditReportSettingStep = () => {
  const { reportDetails } = useContext(EditReportContext);

  const intl = useIntl();

  const isSocialListening = reportDetails?.platform?.toLowerCase() === "sm";
  const showMonitorTypeInput = isSocialListening
    ? !!reportDetails?.monitor_type?.name
    : !!reportDetails?.channel_type;

  return (
    <Box className="edit-report-step-wrapper">
      <Typography variant="h6" className="edit-report-step-title">
        2. {CheckValueLocale("report_setting", "", {}, intl)}
      </Typography>
      <Grid container spacing={2}>
        {showMonitorTypeInput ? (
          <Grid item xs={6}>
            <ReportSelectInput
              id="edit-report-monitor-type-input"
              label={
                isSocialListening ? "monitor_type" : "reports_edit_channel_type"
              }
              renderValue={() =>
                CheckValueLocale(
                  isSocialListening
                    ? `${reportDetails?.monitor_type?.name?.toLowerCase()}_title`
                    : reportDetails?.channel_type,
                  "",
                  {},
                  intl,
                )
              }
              disabled={true}
              isEdit={true}
            />
          </Grid>
        ) : null}
        <Grid item xs={6}>
          <ReportSelectInput
            id="edit-report-report-type-input"
            label="reports_edit_type"
            renderValue={() =>
              CheckValueLocale(
                `${reportDetails?.report_type}_report_type`,
                "",
                {},
                intl,
              )
            }
            disabled={true}
            isEdit={true}
          />
        </Grid>
        <Grid item xs={6}>
          <ReportSelectInput
            id="edit-report-report-type-input"
            label={isSocialListening ? "monitor" : "reports_channel_account"}
            renderValue={() =>
              CheckValueLocale("reports_choose_account", "", {}, intl)
            }
            disabled={true}
            isEdit={true}
          />
          {reportDetails?.monitors?.length > 0 && (
            <Box className="edit-report-selected-monitors-wrapper">
              {reportDetails?.monitors?.map((monitor) => (
                <Button
                  key={monitor?.name}
                  id="edit-report-selected-monitors-chip"
                  className="edit-report-selected-monitor-chip"
                  variant="outlined"
                  endIcon={<FontAwesomeIcon fixedWidth icon={faXmarkCircle} />}
                  disabled
                >
                  <Typography>{monitor?.name}</Typography>
                </Button>
              ))}
            </Box>
          )}
        </Grid>
        <Grid item xs={6}>
          <ReportSelectInput
            id="edit-report-report-type-input"
            label={
              isSocialListening ? "data_source" : "reports_select_data_included"
            }
            renderValue={() =>
              reportDetails?.data_sources
                ?.map((monitor) =>
                  CheckValueLocale(
                    monitor?.type?.toLowerCase() === "private"
                      ? `${monitor?.name?.toLowerCase()}_dm`
                      : monitor?.name?.toLowerCase(),
                    "",
                    {},
                    intl,
                  ),
                )
                ?.join(", ")
            }
            disabled={true}
            isEdit={true}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditReportSettingStep;
