import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import LucButton from "shared/lucButton/lucButton";
import SurveyPopup from "./components/surveyPopup/surveyPopup";
import "./style.scss";

const EmbeddedEditor = (props) => {
  const { surveyData } = props;

  const [openedPopup, setOpenedPopup] = useState(true);

  const { color, opacity } = surveyData?.embedded_config?.backdrop || {};
  const { button_title, font_color, background_color, corner_radius } =
    surveyData?.embedded_config?.button_style || {};

  const btnSize =
    surveyData?.embedded_config?.embed_options?.embed_size || "medium";

  useEffect(() => {
    if (surveyData?.embedded_config?.embed_options?.type === "pop-up") {
      if (!openedPopup) setOpenedPopup(true);
    }
  }, [openedPopup, surveyData?.embedded_config?.embed_options?.type]);

  return (
    <Box id="embedded-survey-edit-section-container">
      <Box
        className="embedded-edit-section-content-wrapper"
        sx={{
          "&::before": {
            display: openedPopup ? "block" : "none",
            backgroundColor: color || "none",
            opacity: opacity / 100 || 0,
          },
        }}
      >
        <Box className="embedded-edit-section-content">
          {surveyData?.embedded_config?.embed_options?.type === "button" ? (
            <Box
              id="survey-embedded-feedback-button-wrapper"
              className={
                surveyData?.embedded_config?.embed_options?.embed_position || ""
              }
            >
              <LucButton
                id="survey-embedded-feedback-button"
                className={`${corner_radius?.toLowerCase() || ""} ${btnSize}`}
                style={{ color: font_color, backgroundColor: background_color }}
                onClick={() => setOpenedPopup(true)}
              >
                {button_title || ""}
              </LucButton>
            </Box>
          ) : null}

          <SurveyPopup
            {...props}
            openedPopup={openedPopup}
            setOpenedPopup={setOpenedPopup}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default EmbeddedEditor;
