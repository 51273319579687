import { Box, Container } from "@mui/material";
import "./createInboundRouting.scss";
import Header from "shared/createHeader/createHeader";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Steppers from "shared/steppers/steppers";
import { CreateRouteFooter } from "./createInboundRoutingFooter";
import { useSharedInboundRouting } from "../sharedLogic/useSharedInboundRouting";
import { RoutingDetailsStep } from "./routingDetailsStep";
import CircularLoading from "components/circularProgress";
import SnackBar from "components/snackBar";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { TeamAndSLAsStep } from "./teamAndSLAsStep";
import { useCheckNameExistence } from "../hooks/useCheckNameExistence";
import { useCreateInboundRouting } from "../hooks/useCreateInboundRouting";
import { serializeConditionsValuesForBackend } from "../sharedLogic/inboundRouteAdapter";

// for the stepper
const steps = ["inbound_routing_details", "teams_and_slas_assigning"];

const urlsStepParams = {
  step1: "step_1",
  step2: "step_2",
};

export const CreateInboundRouting = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  // this will holds the current state for completed steps.e.g. {0: true, 1: true}
  const [completedSteps, setCompletedSteps] = useState({});
  const {
    generalInfo,
    handleGeneralInfoChange,
    conditions,
    setConditions,
    validationErrors,
    routingError,
    setRoutingError,
    isConditionsLoading,
    conditionsOptions,
    getConditions,
    teamAndSLA,
    setTeamAndSLA,
    getTeamsAndSLAsData,
    teamAndSLAsLoading,
    getSLAs,
    getTeams,
  } = useSharedInboundRouting();
  const handleCloseSnackBar = () => {
    setRoutingError({
      openSnackBar: false,
    });
  };
  // every time the go to step 2 we need to make sure that the teams and slas are loaded.
  // hence if temas and slas already loaded it will not call the api again.
  const goToNextStep = (Nextstep) => {
    setActiveStep(Nextstep);
    getTeamsAndSLAsData();
  };

  const { mutate: createRouting, isPending: isCreatingRoutingLoading } =
    useCreateInboundRouting();

  const createInboundRouting = () => {
    const createInboundRoutingData = {
      name: generalInfo?.name?.trim(),
      description: generalInfo?.description?.trim(),
      team_id: teamAndSLA?.selectedTeam?.id || null,
      sla_id: teamAndSLA?.selectedSLA?.id || null,
      algorithm_type_id: teamAndSLA?.autoAssigned
        ? teamAndSLA?.selectedAlgorithm
        : null,
      conditions: serializeConditionsValuesForBackend(conditions),
    };

    createRouting(
      { queryData: createInboundRoutingData },
      {
        onSuccess: () => {
          navigate(`/settings/inbound_routing?status=added_successfully`);
          window.scrollTo(0, 0);
        },
        onError: (error) => {
          const isNameExist =
            error?.errorMsg?.response?.data?.exception?.message ===
            "INBOUND_ROUTING_NAME_ALREADY_EXIST";
          if (isNameExist) {
            setRoutingError({
              message: "routing_name_already_exist",
              severity: "error",
              openSnackBar: true,
            });
          } else {
            setRoutingError({
              message: "routing_creation_failed_body",
              severity: "error",
              title: "routing_creation_failed_title",
              openSnackBar: true,
            });
          }
        },
      },
    );
  };

  const { mutate: checkNameExistence, isPending: isCheckNameExistence } =
    useCheckNameExistence();

  const handleChangeStep = (step) => {
    // when go back to previous step
    if (step < activeStep) {
      setActiveStep(step);
      // in the final step, means it's completed
    } else if (step === steps.length) {
      // handle create inbound routing
      createInboundRouting();
    } else if (completedSteps[activeStep]) {
      // check if the current step is completed, this happen when click on next step from the stepper
      // if the user is in the first step, we need to check the name existence in the backend
      if (activeStep === 0) {
        checkNameExistence(
          { queryData: { name: generalInfo?.name?.trim() } },
          {
            onSuccess: (data) => {
              const isNameExist = data?.data?.inbound_routing?.name;
              if (isNameExist) {
                setRoutingError({
                  routingNameError: "routing_name_already_exist",
                });
                window.scrollTo(0, 0);
              } else goToNextStep(step);
            },
            onError: () =>
              setRoutingError({
                message: "try_again_error_message",
                severity: "error",
                title: "failed_error_message",
                openSnackBar: true,
              }),
          },
        );
      } else {
        goToNextStep(step);
      }
    }
  };
  const handleCancel = () => {
    // go to inbound routing list
    navigate("/settings/inbound_routing");
    window.scrollTo(0, 0);
  };

  // useEffect to hande the validation for each step
  useEffect(() => {
    // validate the first step
    if (activeStep === 0) {
      // check all required fields to be able to go the next step
      // both name and description are required
      setCompletedSteps((prev) => ({
        ...prev,
        0:
          validationErrors?.isAllConditionsValid &&
          validationErrors?.isGeneralInfoValid,
      }));
    } else if (activeStep === 1) {
      // validate the second step
      // check if the team and sla are selected
      setCompletedSteps((prev) => ({
        ...prev,
        1: validationErrors?.isTeamAndSLAValid,
      }));
    }
  }, [validationErrors]);

  useEffect(() => {
    getConditions();
  }, []);

  useEffect(() => {
    // when change the step, we need to scroll to the top of the page
    window.scrollTo(0, 0);
    let currentStepURL = urlsStepParams.step1;
    if (activeStep === 1) {
      currentStepURL = urlsStepParams.step2;
    }

    navigate(`/settings/inbound_routing/create/${currentStepURL}`);
  }, [activeStep]);

  if (isConditionsLoading) {
    return <CircularLoading />;
  }
  return (
    <Container maxWidth="xl" className="create-inbound-routing-container">
      <Header
        mainHeaderTitle="settings"
        subHeaderTitle="inbound_routing"
        subHeaderLink="/settings/inbound_routing"
        mainHeaderLink="/settings"
        cardHeaderHeading="create_new_inbound_routing"
        isCreateScreen
      />
      <Box className="inbound-routing-main-container">
        <Steppers
          steps={steps}
          activeStep={activeStep}
          setActiveStep={handleChangeStep}
          completed={completedSteps}
          setCompleted={setCompletedSteps}
        />
        <Box className="inbound-routing-steps-container">
          <Container>
            {activeStep === 0 ? (
              <RoutingDetailsStep
                generalInfo={generalInfo}
                handleGeneralInfoChange={handleGeneralInfoChange}
                routingNameError={routingError?.routingNameError}
                routingDescriptionError={routingError?.routingDescriptionError}
                conditions={conditions}
                setConditions={setConditions}
                isAllConditionsValid={validationErrors?.isAllConditionsValid}
                conditionsOptions={conditionsOptions}
              />
            ) : (
              <TeamAndSLAsStep
                teamAndSLA={teamAndSLA}
                setTeamAndSLA={setTeamAndSLA}
                teamAndSLAsLoading={teamAndSLAsLoading}
                getSLAs={getSLAs}
                getTeams={getTeams}
              />
            )}
          </Container>
        </Box>
        <CreateRouteFooter
          activeStep={activeStep}
          disableNextStep={!completedSteps[activeStep]}
          handleCancel={handleCancel}
          handleChangeStep={handleChangeStep}
          btnText={activeStep < steps.length - 1 ? "next_Step" : "create"}
          isLoading={isCheckNameExistence || isCreatingRoutingLoading}
        />
      </Box>
      <SnackBar
        open={routingError?.openSnackBar}
        severity={routingError?.severity}
        message={CheckValueLocale(routingError?.message, "", {}, intl)}
        title={CheckValueLocale(routingError?.title, "", {}, intl)}
        handleClose={handleCloseSnackBar}
      />
    </Container>
  );
};
