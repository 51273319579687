import { Alert, Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { CheckValueLocale } from "utils/helpers";
import LinkWithCopyButton from "./components/linkWithCopyBotton";
import "./popupBodyShareLink.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomizedTooltip, {
  customizedStyledTooltip,
} from "components/customizedTooltip";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import dayjs from "dayjs";
import { faLightbulbOn } from "@fortawesome/pro-solid-svg-icons";

const PopupBodyShareLink = ({ monitorLiveDashboardDetails, showEditBtn }) => {
  const intl = useIntl();
  const accessTypeLinkDashboard =
    monitorLiveDashboardDetails?.[0]?.dashboard_sharing_option;
  const startDate = dayjs?.unix(
    monitorLiveDashboardDetails?.[0]?.custom_date?.split("-")?.[0],
  );
  const endDate = dayjs?.unix(
    monitorLiveDashboardDetails?.[0]?.custom_date?.split("-")?.[1],
  );
  const navigate = useNavigate();
  const urlParams = useParams();
  const location = useLocation();

  return (
    <Box className="popup-body-share-link">
      {showEditBtn ? (
        <Box className="text-customize-redirected-edit-the-live-dashboard">
          {CheckValueLocale("if_customize_the_live_dashboard", "", {}, intl)}
          <Link
            to={{
              pathname: `/edit-live-dashboard/${urlParams?.monitorType}/${urlParams?.monitorId}/${urlParams?.userDashboardId}`,
              state: location.state,
            }}
            className="link-customize-redirected-edit-the-live-dashboard"
          >
            {CheckValueLocale("edit_live_dashboard_link", "", {}, intl)}
          </Link>
        </Box>
      ) : null}
      <Box>
        <Typography variant="caption" className="label-popup-live-dashboard">
          {CheckValueLocale("label_Internal_link_live_dashboard", "", {}, intl)}
        </Typography>
        <LinkWithCopyButton
          typeLink="team"
          dashboardUrl={window?.location?.href}
        />
      </Box>

      {accessTypeLinkDashboard === "public_option" ? (
        <>
          <Box>
            <Typography
              variant="caption"
              className="label-popup-live-dashboard"
            >
              {CheckValueLocale(
                "label_public_link_live_dashboard",
                "",
                {},
                intl,
              )}
            </Typography>
            <LinkWithCopyButton
              typeLink="public"
              dashboardUrl={window?.location?.href?.replace(
                "private",
                "public",
              )}
            />
          </Box>
          <Box>
            <Typography
              variant="caption"
              className="label-popup-live-dashboard"
            >
              {CheckValueLocale(
                "dates_range_popup_live_dashboed",
                "",
                {},
                intl,
              )}
              <CustomizedTooltip
                title={CheckValueLocale("date_range_tooltip", "", {}, intl)}
                arrow
                placement={"top"}
                styles={customizedStyledTooltip}
              >
                <FontAwesomeIcon icon={faCircleInfo} />
              </CustomizedTooltip>
            </Typography>
            <Box className="date-range-popup-live-dashboed">
              {dayjs(startDate)?.format("DD/MM/YYYY")}{" "}
              {CheckValueLocale("hour_to", "", {}, intl)}{" "}
              {dayjs(endDate)?.format("DD/MM/YYYY")}
            </Box>
          </Box>
        </>
      ) : null}
      {accessTypeLinkDashboard !== "public_option" && showEditBtn ? (
        <Alert
          className="alert-genetate-public-link"
          icon={<FontAwesomeIcon icon={faLightbulbOn} />}
          severity="info"
        >
          {CheckValueLocale("you_can_generate_public_link", "", {}, intl)}
          <Link
            to={{
              pathname: `/edit-live-dashboard/${urlParams?.monitorType}/${urlParams?.monitorId}/${urlParams?.userDashboardId}`,
              state: location.state,
            }}
            className="link-customize-redirected-edit-the-live-dashboard"
          >
            {CheckValueLocale("edit_live_dashboard_link", "", {}, intl)}
          </Link>
        </Alert>
      ) : null}
    </Box>
  );
};

export default PopupBodyShareLink;
