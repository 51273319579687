import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { importExcelFileValidationSchema } from "pages/accountsLists/accountsListsValidation/importExcelFileValidationSchema";
import { useGetAccountsListExcelGuide } from "pages/accountsLists/hooks/useGetAccountsListExcelGuide";

const ImportFunctions = () => {
  const intl = useIntl();
  const [files, setFiles] = useState([]);
  const [openImportPopUp, setOpenImportPopUp] = useState(false);
  const [excelGuideData, setExcelGuideData] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [importedUsernames, setImportedUsernames] = useState([]);
  const { data, isLoading: excelGuideLoading } =
    useGetAccountsListExcelGuide(openImportPopUp);

  let excelFileName = files && files[0]?.file?.name;
  let excelFileError = files && files[0]?.errors;
  let excelFileErrorMsg = excelFileError && files[0]?.errors[0]?.code;
  let fileName = excelFileName && excelFileName?.split(".")[0];
  useEffect(() => {
    if (files?.length > 0) {
      importExcelFileValidationSchema(
        files,
        excelFileName,
        intl,
        setErrorMessages,
        setImportedUsernames,
        isValidFileName,
        excelFileErrorMsg,
      );
    }
  }, [files]);
  const isValidFileName = (fileName) => {
    return /\.(xls|xlsx|csv)$/i.test(fileName);
  };

  const handleImportAccountsList = () => {
    setOpenImportPopUp(true);
  };

  const handleResetImportPopUp = () => {
    setOpenImportPopUp(false);
    setFiles([]);
    setErrorMessages([]);
  };

  const handleExcelGuideData = () => {
    setExcelGuideData([]);
    setExcelGuideData(data?.data);
  };

  const handleCancelUploadFile = () => {
    setFiles([]);
    setErrorMessages([]);
  };

  let isFileUploaded =
    excelFileName &&
    files?.length &&
    !excelFileErrorMsg &&
    errorMessages?.length === 0;
  return {
    files,
    setFiles,
    excelFileName,
    excelFileError,
    excelFileErrorMsg,
    isFileUploaded,
    fileName,
    errorMessages,
    importedUsernames,
    handleImportAccountsList,
    handleResetImportPopUp,
    setOpenImportPopUp,
    openImportPopUp,
    handleExcelGuideData,
    handleCancelUploadFile,
    excelGuideData,
    setExcelGuideData,
    excelGuideLoading,
  };
};

export default ImportFunctions;
