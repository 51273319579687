import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserInfoItemList } from "./userInfoItemList";
import { useContext } from "react";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { EditContext } from "../context";

const UserSegments = ({ userSegmentsData }) => {
  const intl = useIntl();

  const {
    isEditing,
    setIsEditing,
    setCurrentEditingSection,
    hasCDPSubscription,
  } = useContext(EditContext);

  const title = CheckValueLocale("segments", "", {}, intl);

  const isUserNotViewer = handlelUserRoles("CDP", "EDIT_PROFILE");

  const handleEditClick = (e) => {
    e?.stopPropagation()
    if (!isUserNotViewer || !hasCDPSubscription) return;
    setIsEditing(true);
    setCurrentEditingSection("segments");
  };

  return (
    <>
      <div>
        <Accordion
          disabled={isEditing}
          classes={{ disabled: "engager-user-info-accordion-disabled" }}
          defaultExpanded
          className="engager-user-info-accordion"
        >
          <AccordionSummary
            expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
          >
            <Typography className="engager-user-info-title">
              {CheckValueLocale("user_segments", "", {}, intl)}
              {!isEditing ? (
                <Tooltip
                  arrow
                  placement="top"
                  onClick={handleEditClick}
                  title={
                    isUserNotViewer
                      ? CheckValueLocale(
                          hasCDPSubscription
                            ? "edit"
                            : "engager_profile_cdp_not_subscribed",
                          "",
                          {},
                          intl,
                        )
                      : ""
                  }
                >
                  <FontAwesomeIcon
                    icon={faPen}
                    size="xs"
                    className="copy-engager-profile-link-icon"
                  />
                </Tooltip>
              ) : null}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <UserInfoItemList
              title={title}
              fieldType="segments"
              data={userSegmentsData}
              hideTitle
              formMode="select"
              emptyText={CheckValueLocale(
                "no_attached_segments_yet",
                "",
                {},
                intl,
              )}
            />
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};

export default UserSegments;
