import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import moment from "moment";
import { Container, Box } from "@mui/material";
import { getTimeZone, isEmptyObj } from "utils/helpers";
import { useParams } from "react-router-dom";
import { Link } from "@mui/material";
import { CheckValueLocale } from "../../../../../../../utils/helpers/index";
import InteractionsController from "services/controllers/interactionsController";
import { useCategoriesOptions } from "components/sentimentCategoriesThemes/hooks/useCategoriesOptions";
import { useThemesOptions } from "components/sentimentCategoriesThemes/hooks/useThemesOptions";
import { useEditCategories } from "pages/channelAnalytics/InsightsPage/hooks/useEditCategories";
import { useEditThemes } from "pages/channelAnalytics/InsightsPage/hooks/useEditThemes";
import SentimentCategoriesThemes from "components/sentimentCategoriesThemes";
import CircularLoading from "components/circularProgress";
import SingleInteractionHeader from "components/interactions-list/components/single-interaction/SingleInteractionHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIntercom } from "@fortawesome/free-brands-svg-icons";
import Luci from "pages/luci";
import "components/interactions-list/components/single-interaction/SingleInteraction.scss";
import DOMPurify from "dompurify";

import UserProfileSideBar from "shared/userProfileSideBar/UserProfileSideBar";

const SingleIntercomInteraction = () => {
  const urlParams = useParams();
  const interactionId = urlParams.id;
  const trackerId = urlParams.tracker_id;
  const dataSourceId = urlParams.data_source_id;

  //to check if category has only one datasource or not , then redirect user to datasource when pressed category link.
  let hasDatasourceOnly = localStorage.getItem("has_datasource_only");

  const links = [
    {
      name: "interactions",
      url: "interactions",
    },
    {
      name: "chats",
      url: `insights/chat${
        hasDatasourceOnly === "true" ? `/intercom/${dataSourceId}` : ""
      }`,
    },
    {
      name: "intercom",
      url: "insights/chat/intercom/" + dataSourceId,
    },
  ];
  const intl = useIntl();
  const [conversation, setConversation] = useState({});
  const [openProfile, setOpenProfile] = useState(false);
  let apiVars = "chat/intercom";
  useEffect(() => {
    InteractionsController.getSingleInteraction(
      interactionId,
      trackerId,
      dataSourceId,
      window.localStorage.cxm_id,
      apiVars,
    ).then((result) => {
      if (result !== undefined && !isEmptyObj(result.data)) {
        setConversation(result.data);
      }
    });
  }, []);

  const { data: categoriesOptions } = useCategoriesOptions({ enabled: true });
  const { data: themesOptions } = useThemesOptions({ enabled: true });

  const { mutate: editThemes, isPending: isThemesPending } = useEditThemes();
  const { mutate: editCategories, isPending: isCategoriesPending } =
    useEditCategories();

  const preQueryData = {
    id: conversation?.id,
    product_id: window.localStorage.cxm_id,
    tracker_id: conversation?.account_id,
    data_source_id: conversation?.channel_id,
    created_at: conversation?.created_at,
    comment_id: conversation?.id,
    account_id: conversation?.account_id,
  };

  const handleEditCategories = (updatedCategories) => {
    editCategories(
      {
        apiVars: `channel_analytics/${apiVars}/interactions`,
        queryData: {
          ...preQueryData,
          new_label: updatedCategories,
          old_label: conversation?.themes || [],
        },
      },
      {
        onSuccess: () =>
          setConversation((prev) => ({ ...prev, themes: updatedCategories })),
      },
    );
  };

  const handleEditTheme = (updatedThemes) => {
    editThemes(
      {
        apiVars: `channel_analytics/${apiVars}/interactions`,
        queryData: {
          ...preQueryData,
          new_label: updatedThemes,
          old_label: conversation?.sub_themes || [],
        },
      },
      {
        onSuccess: () =>
          setConversation((prev) => ({ ...prev, sub_themes: updatedThemes })),
      },
    );
  };

  return (
    <>
      {openProfile ? (
        <UserProfileSideBar
          openProfile={openProfile}
          setOpenProfile={setOpenProfile}
          selectedCard={{
            screen_name: conversation,
            profile_image: "",
          }}
          dataSourceName={"WHATSAPP"}
          monitorId={trackerId}
          productId={window.localStorage?.getItem("cxm_id")}
        />
      ) : null}
      <Container maxWidth="xl">
        <Luci isInactive={true} />
        {isEmptyObj(conversation) ? (
          <CircularLoading />
        ) : (
          <div>
            <SingleInteractionHeader links={links} dataSource="intercom" />
            <div className="single-intr">
              <Box className="single-intr-wrapper">
                <Box className={`user-single-header `}>
                  <span className="single-intr__icon ">
                    <FontAwesomeIcon icon={faIntercom} className="intercom" />
                  </span>
                  <span className="single-intr__title">
                    {CheckValueLocale("intercom", "", {}, intl)}
                  </span>
                </Box>
                <Box>
                  <SentimentCategoriesThemes
                    themesData={{
                      btnId: "intercom-single-interaction-themes-btn",
                      options: themesOptions,
                      selected: conversation?.sub_themes,
                      handleChange: handleEditTheme,
                      loading: isThemesPending,
                    }}
                    categoriesData={{
                      btnId: "intercom-single-interaction-categories-btn",
                      options: categoriesOptions,
                      selected: conversation?.themes,
                      handleChange: handleEditCategories,
                      loading: isCategoriesPending,
                    }}
                    sentimentData={{
                      btnId: "intercom-single-interaction-sentiment-btn",
                      selected: conversation?.sentiment,
                      disabled: true,
                    }}
                    dividers={[1, 2]}
                  />
                </Box>
              </Box>
              <div className="single-intr__body">
                <div>
                  {conversation.messages !== undefined &&
                    conversation.messages.map((object, index) => (
                      <div
                        key={index}
                        className={
                          object.conversation_author_type === "user" ||
                          object.conversation_author_type === "lead"
                            ? "single-intr__CstBlock"
                            : "single-intr__Agentblock"
                        }
                      >
                        {object.show_author !== false && (
                          <div>
                            <Link
                              to="/userProfile"
                              className="single-intr__name"
                            >
                              {object.conversation_author_type === "user" ||
                              object.conversation_author_type === "lead"
                                ? object.conversation_author_name
                                : object.conversation_author_type === "bot"
                                  ? object.conversation_author_name + "(bot)"
                                  : object.conversation_author_name + "(Agent)"}
                            </Link>

                            <h5>{object.conversation_author_email}</h5>
                          </div>
                        )}
                        <div
                          className={
                            object.conversation_author_type === "user" ||
                            object.conversation_author_type === "lead"
                              ? "single-intr__body--intercomCst"
                              : "single-intr__body--intercomAgent"
                          }
                        >
                          {object.message_body && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(object.message_body),
                              }}
                            />
                          )}

                          <span>
                            {moment
                              .unix(Number(object.message_created_at))
                              .utc()
                              .format("YYYY/MM/DD, LT")}
                            {"GMT " + getTimeZone()}
                          </span>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </Container>
    </>
  );
};

export default SingleIntercomInteraction;
