import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import CircularLoading from "components/circularProgress";
import LucButton from "shared/lucButton/lucButton";
import Header from "./Header/Header";
import SectionContainer from "pages/SurveyBuilder/EditSection/components/section/sectionContainer";
import Footer from "./Footer";

const LinkedPreview = (props) => {
  const {
    filteredSections,
    sectionIndex,
    surveyData,
    logoData,
    handleBackToBuilder,
    submitted,
    surveyDirection,
    sectionSurveyDirection,
    sectionsStack,
    progressValue,
    handleClickNext,
    handleClickBack,
    handleProceedAnswer,
    btnVal,
    handleSubmit,
    showPoweredBy,
    setErrorForm,
  } = props;

  const intl = useIntl();

  return (
    <Box className="linked-survey-live-preview-container">
      {!filteredSections ? (
        <CircularLoading />
      ) : (
        <Box
          className={`all-section-direction-${
            surveyDirection
              ? surveyDirection
              : intl?.locale == "en"
                ? "ltr"
                : "rtl"
          }`}
        >
          <Header surveyData={surveyData} logoData={logoData} />

          {submitted ? (
            <Box className="submit-box">
              <Typography>
                {CheckValueLocale("submission_complete", "", {}, intl)}
              </Typography>
              <LucButton
                className={"back-to-survey-btn"}
                variant="flat"
                minWidth={126}
                onClick={handleBackToBuilder}
              >
                {CheckValueLocale("back_to_survey", "", {}, intl)}
              </LucButton>
            </Box>
          ) : (
            <Box>
              <Box className={`section-direction-${sectionSurveyDirection}`}>
                <SectionContainer
                  surveyData={surveyData}
                  filteredSections={filteredSections}
                  section={filteredSections[sectionIndex]}
                  sectionIndex={sectionIndex}
                  hideSecHeader={
                    surveyData?.emebedded_config?.hide_sections_header?.enabled
                  }
                  handleProceedAnswer={handleProceedAnswer}
                  setErrorForm={setErrorForm}
                  previewMode
                />
              </Box>
            </Box>
          )}
          {!submitted ? (
            <Footer
              filteredSections={filteredSections}
              sectionsStack={sectionsStack}
              submitted={submitted}
              progressValue={progressValue}
              btnVal={btnVal}
              handleClickNext={handleClickNext}
              handleClickBack={handleClickBack}
              handleSubmit={handleSubmit}
              showPoweredBy={showPoweredBy}
              directionFooter={surveyData?.text_direction}
            />
          ) : null}
        </Box>
      )}
    </Box>
  );
};

export default LinkedPreview;
