import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  FormControl,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { SketchPicker } from "react-color";
import { useIntl } from "react-intl";
import { CheckValueLocale, hexToHexa } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlus,
  faPenToSquare,
} from "@fortawesome/pro-regular-svg-icons";
import CustomizedTooltip from "components/customizedTooltip";

const ThemeColorMenu = ({ colors = [], formik, themeDialogStats }) => {
  const intl = useIntl();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClickColorMenu = (event) => setAnchorEl(event.currentTarget);
  const handleCloseColorMenu = () => setAnchorEl(null);

  const [menuDir, setMenuDir] = useState("right");
  const [pickerOpen, setPickerOpen] = useState(false);
  const [pickerAnchorEl, setPickerAnchorEl] = useState(null);

  const [customColor, setCustomColor] = useState(null);
  const [showCustomColor, setShowCustomColor] = useState(false);

  useEffect(() => {
    if (intl.locale == "ar") setMenuDir("left");
    else setMenuDir("right");
  }, [intl.locale]);

  const handleOpenColorPicker = (event) => {
    setPickerAnchorEl(event?.currentTarget);
    setPickerOpen(true);
  };

  const handleCloseColorPicker = () => {
    setPickerAnchorEl(null);
    setPickerOpen(false);
    setShowCustomColor(true);
  };

  const isEdit = themeDialogStats?.isEdit; // Check if the dialog is in edit mode

  const editThemeSavedColor = themeDialogStats?.themeToEdit?.sub_theme_color; // Get the saved color from BE for this theme

  // List of colors to map on the select
  const colorsToMap =
    isEdit &&
    !colors?.map((color) => color?.value)?.includes(editThemeSavedColor) // Check if the saved color is not in the colors array from Backend
      ? [...colors, { value: editThemeSavedColor }] || [] // Add the saved color to the colors array
      : colors || []; // Return the colors array

  return (
    <Grid item xs={2}>
      <Typography className="theme-dialog-input-label">
        {CheckValueLocale("color", "", {}, intl)}
        <Typography component="span" className="required">
          *
        </Typography>
      </Typography>
      <FormControl className="theme-dialog-form-control" size="small">
        <Select
          id="theme-form-colors-select"
          size="small"
          open={false}
          onOpen={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleClickColorMenu(e);
          }}
          onClose={handleCloseColorMenu}
          displayEmpty
          renderValue={() => (
            <Box
              className="theme-color-item color-rendere-value"
              sx={{ background: formik?.values?.sub_theme_color }}
            />
          )}
          error={
            formik?.touched?.["sub_theme_color"] &&
            Boolean(formik?.errors?.["sub_theme_color"])
          }
        />
      </FormControl>

      <Menu
        id="themes-color-select-menu"
        className={isEdit ? "edit-theme-color-select-menu" : ""}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseColorMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: intl?.locale == "ar" ? "right" : "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: intl?.locale == "ar" ? "right" : "left",
        }}
      >
        {colorsToMap?.map((color, index) => {
          const selected = formik?.values?.sub_theme_color === color?.value;
          const disabledColor = selected ? false : color?.status === "used";

          return (
            <MenuItem
              key={index}
              id="theme-color-menu-item"
              className={`${selected ? "selected-theme-color" : ""} ${disabledColor ? "theme-color-item-disabled" : ""}`}
              sx={{
                "&:hover": { background: hexToHexa(color?.value, 0.1) },
                "&.selected-theme-color": {
                  background: hexToHexa(color?.value, 0.1),
                },
              }}
              disabled={disabledColor}
              onClick={() => {
                formik?.setFieldValue("sub_theme_color", color?.value);
                handleCloseColorMenu();
              }}
            >
              <Box
                className="theme-color-item-container"
                sx={{ borderColor: selected ? color?.value : "transparent" }}
              >
                <Box
                  className="theme-color-item"
                  sx={{ backgroundColor: color?.value }}
                />
              </Box>
            </MenuItem>
          );
        })}

        {!isEdit && !!customColor && showCustomColor ? (
          <Box className="theme-color-custom-color-wrapper">
            <TextField
              id="theme-custom-color-field"
              size="small"
              value={customColor?.toUpperCase()}
              disabled
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    size="small"
                    onClick={() =>
                      formik?.setFieldValue("sub_theme_color", customColor)
                    }
                  >
                    <Box
                      className="theme-color-item-container"
                      sx={{
                        borderColor:
                          formik?.values?.sub_theme_color === customColor
                            ? customColor
                            : "transparent",
                      }}
                    >
                      <Box
                        className="theme-color-item"
                        sx={{ backgroundColor: customColor }}
                      />
                    </Box>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end" size="small">
                    <Box onClick={handleOpenColorPicker}>
                      <FontAwesomeIcon icon={faPenToSquare} />
                    </Box>
                  </InputAdornment>
                ),
              }}
              error={Boolean(formik?.errors?.["sub_theme_color"])}
              helperText={
                Boolean(formik?.errors?.["sub_theme_color"])
                  ? CheckValueLocale(
                      formik?.errors?.["sub_theme_color"],
                      "",
                      {},
                      intl,
                    )
                  : null
              }
            />
          </Box>
        ) : !isEdit ? (
          <CustomizedTooltip
            title={CheckValueLocale(
              "theme_dialog_color_picker_tooltip",
              "",
              {},
              intl,
            )}
            placement={intl?.locale == "ar" ? "right" : "left"}
            arrow
            styles={{ fontWeight: 500, fontSize: "12px", padding: "8px 10px" }}
          >
            <MenuItem
              id="theme-color-menu-item"
              className="theme-color-menu-item-custom-color-btn"
              onClick={(e) => {
                setShowCustomColor(true);
                setCustomColor("#000000");
                formik?.setFieldValue("sub_theme_color", "#000000");
                handleOpenColorPicker(e);
              }}
            >
              <FontAwesomeIcon icon={faCirclePlus} />
            </MenuItem>
          </CustomizedTooltip>
        ) : null}

        <Dialog
          id="theme-custom-color-picker-dialog"
          open={pickerOpen}
          onClose={handleCloseColorPicker}
        >
          <SketchPicker
            color={formik?.values?.sub_theme_color || "#000000"}
            onChange={(color) => {
              setCustomColor(color?.hex);
              formik?.setFieldValue("sub_theme_color", color?.hex);
            }}
            styles={{
              default: {
                alpha: { display: "none" },
                hue: { marginTop: "6px" },
              },
            }}
          />
        </Dialog>
      </Menu>
    </Grid>
  );
};

export default ThemeColorMenu;
