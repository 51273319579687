import { useState, useRef, useEffect } from "react";
import { TextField, Box, ClickAwayListener } from "@mui/material";
import { useFormik } from "formik";
import { useDebounce } from "use-debounce";
import {
  newsSourcesValidationSchema,
  DEBOUNCE_DELAY,
  SNACKBAR_INITIAL_STATE,
} from "./utils";
import { useNewsBlogs } from "../../../hooks/useNewsBlogs";
import { OptionsPopover } from "./components/optionsPopover";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { SelectedSourcesList } from "./components/sourcesList";
import { RequestSourceVerification } from "./components/requestSourceVerification";
import SnackBar from "components/snackBar";
import { CustomSourcesInputTitle } from "./components/customSourcesInputTitle";

const CustomSourcesChipsInput = ({
  majorSource,
  handleChangeDropdown,
  showCustomSourcesInputTitle,
  type = "includeSources",
  nbExcludedSource,
  handleSourceskeyboardPress,
  handleDeleteExcludedSource,
}) => {
  const isExcludedSources = type === "excludeSources";
  const initialData = isExcludedSources ? nbExcludedSource : majorSource;
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const [selectedSources, setSelectedSources] = useState(initialData);
  const [isRequestVerificationModalOpen, setIsRequestVerificationModalOpen] =
    useState(false);
  const [snackbarState, setSnackbarState] = useState(SNACKBAR_INITIAL_STATE);

  useEffect(() => {
    if (isExcludedSources) {
      setSelectedSources(nbExcludedSource);
    }
  }, [nbExcludedSource]);
  const containerRef = useRef(null);

  const formik = useFormik({
    initialValues: {
      sourceUrl: "",
    },
    validationSchema: newsSourcesValidationSchema,
    validateOnChange: true,
  });

  const [debouncedSourceUrl] = useDebounce(
    formik?.values?.sourceUrl,
    DEBOUNCE_DELAY,
  );

  // check if the source starts with http:// or https:// and ends with /
  // then delete it before sending it to the be
  const clearSourceURL = debouncedSourceUrl
    ?.replace(/^https?:\/\//, "")
    ?.replace(/\/$/, "");

  const { data: newsBlogsData = [], isPending } = useNewsBlogs({
    queryData: {
      page: 1,
      query: clearSourceURL,
    },
    enabled:
      debouncedSourceUrl?.length > 0 &&
      !formik?.errors?.sourceUrl &&
      formik?.values?.sourceUrl?.trim() !== "" &&
      formik?.values?.sourceUrl !== "https://",
  });

  const handleInputChange = (event) => {
    const trimmedValue = event?.target?.value?.trim();
    formik.setFieldValue("sourceUrl", trimmedValue);
    setOpen(!!trimmedValue);
  };

  const handleCloseAndResetForm = () => {
    formik.resetForm();
    setOpen(false);
  };

  const handleExcludeSourcesOptionSelect = (option) => {
    handleSourceskeyboardPress({ target: { value: option?.link } }, "btn");
  };

  const handleOptionSelect = (option) => {
    const allNewOptions = [...selectedSources, option];
    const allIds = allNewOptions?.map((source) => source?.id);
    setSelectedSources(allNewOptions);

    handleChangeDropdown(
      {
        target: {
          value: allIds,
        },
      },
      "major_sources",
    );
  };

  const handleChangeSelector = (option) => {
    if (isExcludedSources) {
      handleExcludeSourcesOptionSelect(option);
    } else {
      handleOptionSelect(option);
    }
  };

  const handleDeleteIncludeSource = (sourceId) => {
    const allIdsOptions = selectedSources?.filter(
      (source) => source?.id !== sourceId,
    );
    const allIds = allIdsOptions?.map((source) => source?.id);
    setSelectedSources(allIdsOptions);
    handleChangeDropdown(
      {
        target: {
          value: allIds,
        },
      },
      "major_sources",
    );
  };

  const handleSourceDelete = (deleteSourceId, index) => {
    if (isExcludedSources) {
      handleDeleteExcludedSource(index);
    } else {
      handleDeleteIncludeSource(deleteSourceId);
    }
  };

  const handleDeleteAll = () => {
    if (isExcludedSources) {
      nbExcludedSource?.forEach((_, index) => {
        handleDeleteExcludedSource(index);
      });
    } else {
      handleChangeDropdown(
        {
          target: {
            value: [],
          },
        },
        "major_sources",
      );
    }
    setSelectedSources([]);
  };

  const isOptionSelected = (optionId, link) => {
    // check if the source ends with / then extract it and compare
    // this is required since in the excluded resources
    // we don't have an id to compare with
    const checkLink = link?.endsWith("/") ? link?.slice(0, -1) : link;
    return selectedSources?.some(
      (source) => source === checkLink || source?.id === optionId,
    );
  };
  const isPopoverShown = open && !formik?.errors?.sourceUrl;

  const handleOpenRequestVerificationModal = () => {
    setIsRequestVerificationModalOpen(true);
    setOpen(false);
  };

  const handleCloseRequestVerificationModal = () => {
    setIsRequestVerificationModalOpen(false);
    formik.resetForm();
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Box component="section" className="news-sources-search-container">
        {showCustomSourcesInputTitle ? (
          <CustomSourcesInputTitle
            showCustomSourcesInputTitle={showCustomSourcesInputTitle}
          />
        ) : null}
        <TextField
          fullWidth
          name="sourceUrl"
          value={formik?.values?.sourceUrl}
          error={!!formik?.errors?.sourceUrl}
          helperText={CheckValueLocale(formik?.errors?.sourceUrl, "", {}, intl)}
          size="small"
          onChange={handleInputChange}
          onClick={() => (formik?.values?.sourceUrl ? setOpen(true) : null)}
          placeholder={CheckValueLocale(
            isExcludedSources
              ? "exclude_sources_input_placeholder"
              : "custom_sources_placeholder",
            "",
            {},
            intl,
          )}
          component="section"
          ref={containerRef}
          className="news-sources-search-input"
        />

        <OptionsPopover
          open={isPopoverShown}
          anchorEl={containerRef?.current}
          onClose={() => handleCloseAndResetForm()}
          data={newsBlogsData}
          isPending={isPending}
          isOptionSelected={isOptionSelected}
          onOptionSelect={handleChangeSelector}
          isExcludedSources={isExcludedSources}
          handleOpenRequestVerificationModal={
            handleOpenRequestVerificationModal
          }
        />

        {selectedSources?.length > 0 && (
          <SelectedSourcesList
            sources={selectedSources}
            onDelete={handleSourceDelete}
            handleDeleteAll={handleDeleteAll}
          />
        )}
        <RequestSourceVerification
          open={isRequestVerificationModalOpen}
          onClose={handleCloseRequestVerificationModal}
          setSnackbarState={setSnackbarState}
        />
        <SnackBar
          open={snackbarState?.open}
          handleClose={() => setSnackbarState(SNACKBAR_INITIAL_STATE)}
          severity={snackbarState?.severity}
          title={snackbarState?.title}
          message={snackbarState?.message}
        />
      </Box>
    </ClickAwayListener>
  );
};

export default CustomSourcesChipsInput;
