import { Box, Typography, Switch, Checkbox } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import TwoFAMethodNameWithIcon from "../../../../profile/components/securityProfileSection/components/twoFactorAuthentication/components/twoFAMethodNameWithIcon/twoFAMethodNameWithIcon";
import LucButton from "shared/lucButton/lucButton";
import { useRequestEnableSMS } from "../hooks/useRequestEnableSMS";
import SnackBar from "components/snackBar.js";
import { useState, useEffect } from "react";

const EditAndEnablePopupBody = (props) => {
  const intl = useIntl();
  const [openSnackBar, setOpenSnackBar] = useState();
  const methodStatus = { sms: props?.smsEnabled ? null : "paid_two" }; // will be removed when the real data is available
  const [smsRequested, setSmsRequested] = useState(false);
  const requestEnableSms = useRequestEnableSMS();

  useEffect(() => {
    if (props?.notSelectAnyMethod) {
      props?.formik?.setFieldValue("rememberMe", false);
    }
  }, [props?.notSelectAnyMethod]);

  const handleRequestEnableSms = () => {
    requestEnableSms?.mutate(
      { service_name: "request_2fa_sms" },
      {
        onSuccess: () => {
          setSmsRequested(true);
          setOpenSnackBar({
            open: true,
            message: `requested_enable_sms`,
            type: "success",
          });
        },
        onError: () => {
          setOpenSnackBar({
            open: true,
            message: "something_went_wrong_request_please_try_again",
            type: "error",
            title: "request_failed",
          });
        },
      },
    );
  };

  return (
    <Box id="two-factor-authentication-popup">
      <Typography className="security-desc">
        {CheckValueLocale("two_factor_authentication_popup_desc", "", {}, intl)}
      </Typography>
      {/* ============================================================================================== */}
      {["email", "sms", "totp"]?.map((method, index) => (
        <Box className="two-factor-authentation-method" key={index}>
          <Checkbox
            name={method}
            className="two-factor-authentation-method-checkbox"
            onChange={props?.formik?.handleChange}
            checked={props?.formik?.values?.[method]}
            disabled={method === "sms" && !props?.smsEnabled}
          />
          <TwoFAMethodNameWithIcon
            method={method}
            chipLabel={methodStatus?.[method]}
            page={"settings"}
          />
          {method === "sms" && !props?.smsEnabled ? (
            <LucButton
              className={`request-btn ${smsRequested ? "requested" : ""}`}
              variant="flat"
              onClick={() => handleRequestEnableSms()}
              disabled={smsRequested}
              loading={requestEnableSms?.isPending}
            >
              {smsRequested
                ? CheckValueLocale("requested_service", "", {}, intl)
                : CheckValueLocale("request_service", "", {}, intl)}
            </LucButton>
          ) : null}
        </Box>
      ))}
      {/* ============================================================================================== */}
      <Box className="remember-me">
        <Switch
          name="rememberMe"
          checked={props?.formik?.values?.rememberMe}
          onChange={props?.formik?.handleChange}
          size="small"
          disabled={props?.notSelectAnyMethod}
        />
        <Typography className="remember-me-title">
          {CheckValueLocale("remember_me", "", {}, intl)}
        </Typography>
      </Box>
      <Typography className="remember-me-desc">
        {CheckValueLocale("remember_me_desc", "", {}, intl)}
      </Typography>
      {/* ============================================================================================== */}
      <SnackBar
        open={openSnackBar?.open}
        handleClose={() => setOpenSnackBar(false)}
        message={CheckValueLocale(openSnackBar?.message, "", {}, intl)}
        severity={openSnackBar?.type}
        title={CheckValueLocale(openSnackBar?.title, "", {}, intl)}
      />
    </Box>
  );
};
export default EditAndEnablePopupBody;
