import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CopyIcon from "@mui/icons-material/FileCopy";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import CustomizedTooltip from "components/customizedTooltip";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";

const ListOptions = ({
  accountsListData,
  handleOpenDeleteModal,
  handleOpenDuplicateModal,
  navigate,
  totalAccountsLists,
}) => {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event?.currentTarget);
  };
  const [isLimitReached, setIsLimitReached] = useState(false);
  useEffect(() => {
    if (totalAccountsLists >= 100) {
      setIsLimitReached(true);
    } else {
      setIsLimitReached(false);
    }
  }, [totalAccountsLists]);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleEditList = (id) => {
    navigate(`/accounts-list/edit-accounts-list/${id}`);
  };

  return (
    <>
      <CustomizedTooltip
        id="menu-item-list-tooltip"
        title={
          <Box className="item-title-tooltip">
            {CheckValueLocale("items_ellipsis", "", {}, intl)}
          </Box>
        }
        arrow
        placement="bottom"
      >
        <IconButton
          className={
            "menu-items menu-item-container " + (open ? "menu-item-active" : "")
          }
          id="demo-customized-button"
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="contained"
          onClick={handleClick}
        >
          <FontAwesomeIcon className="ellipsis-icon" icon={faEllipsisH} />
        </IconButton>
      </CustomizedTooltip>

      <Menu
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="accounts-list-action-dropdown"
      >
        <MenuItem
          onClick={() => {
            handleEditList(accountsListData?.id);
            handleClose();
          }}
          disableRipple
          disabled={!handlelUserRoles("GENERAL", "EDIT_ACCOUNT_LIST")}
        >
          <EditIcon />
          {CheckValueLocale("edit", "", {}, intl)}
        </MenuItem>
        <MenuItem
          className="drop-down-btn-servey"
          onClick={() => {
            handleOpenDuplicateModal({
              id: accountsListData?.id,
              name: accountsListData?.attributes?.name,
            });
            handleClose();
          }}
          id={`surveys-cards-dropdown-edit-survey-id}`}
          disabled={
            isLimitReached ||
            !handlelUserRoles("GENERAL", "CREATE_ACCOUNT_LIST")
          }
        >
          <FontAwesomeIcon className={"header-icons onhover_class"} />
          <CopyIcon />
          {CheckValueLocale("duplicate", "", {}, intl)}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            handleOpenDeleteModal({
              id: accountsListData?.id,
              name: accountsListData?.attributes?.name,
              isLinkedToSavedFilter:
                accountsListData?.attributes?.is_linked_to_saved_filters,
            });
          }}
          disableRipple
          disabled={!handlelUserRoles("GENERAL", "DESTROY_ACCOUNT_LIST")}
        >
          <DeleteIcon />
          {CheckValueLocale("delete", "", {}, intl)}
        </MenuItem>
      </Menu>
    </>
  );
};

export default ListOptions;
