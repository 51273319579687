import ReactEcharts from "echarts-for-react";
import moment from "moment";
import { useIntl } from "react-intl";
import { calculateUnixTimeZone, CheckValueLocale, formatTimeZone } from "utils/helpers";

const LineChart = (props) => {
  const intl = useIntl();
  const onChartClick = (params) => {
    //here it is used for open quick-inspect-model
    const xAxisDataUnix = props?.xAxisDataObj?.find( item => item?.key === params.name)?.value
    const formattedDateName = formatTimeZone(
      calculateUnixTimeZone(Number(xAxisDataUnix), { utc: true }),
      intl,
      {
        dateFormat: intl.locale === "ar" ? "YYYY/MM/DD" : "DD/MM/YYYY",
      },
    ).date;
    const formattedDate = moment(formattedDateName, "DD/MM/YYYY").format(
      "YYYY-MM-DD",
    );

    if (props.duration === undefined || props.duration === "day") {
      props.handleOpenQuickInspect &&
        props.handleOpenQuickInspect(formattedDate, props.inspectorName);
    }
  };

  const formatToolTip = (data, toolTipLabel) => {
    let data_value = data[0].value;
    if (toolTipLabel) {
      return (
        `${data[0].name} <br /> ${data[0].marker} ${toolTipLabel} : ${Number(
          data_value,
        ).toLocaleString()}` +
        `${
          props.handleOpenQuickInspect
            ? "<br />" +
              `<strong>${CheckValueLocale(
                "click_for_details",
                "",
                {},
                intl,
              )}</strong>`
            : ""
        }`
      );
    }
    return (
      `${data[0].marker} ${data[0].name}: ${Number(
        data_value,
      ).toLocaleString()}` +
      `${
        props.handleOpenQuickInspect
          ? "<br />" +
            `<strong>${CheckValueLocale(
              "click_for_details",
              "",
              {},
              intl,
            )}</strong>`
          : ""
      }`
    );
  };

  return (
    <ReactEcharts
      option={{
        grid: {
          x: 65,
          x2: 45,
          y: 35,
          y2: 65,
          left: "3%",
          right: "3%",
        },
        silent: props.haveData ? false : true,
        dataZoom: [
          {
            show: props.showDataZoom === false ? false : true,
            start: props.zoomPercentage,
            end: 100,
            bottom: 5,
            left: 60,
            right: 45,
            fillerColor: "rgba(42, 170, 226, 0.06)",
            dataBackground: {
              areaStyle: {
                color: "transparent",
              },
            },
            selectedDataBackground: {
              lineStyle: {
                color: "rgba(203, 213, 225, 1)",
              },
              areaStyle: {
                color: "transparent",
              },
            },
          },
        ],
        tooltip: {
          show: true,
          backgroundColor: "#fff",
          textStyle: {
            fontSize: 12,
          },
          trigger: "axis",
          padding: [10, 15],
          formatter: (val) =>
            formatToolTip(
              val,
              CheckValueLocale(props.toolTipLabel, "", {}, intl),
            ),
        },
        color: props.color,
        barMaxWidth: 24,
        barMinWidth: 18,
        xAxis: {
          type: "category",
          data: props.xAxisData,
          name: props.xAxisName,
          boundaryGap: false,
          axisLabel: {
            textStyle: {
              color: "rgba(0, 0, 0, 0.42)",
            },
          },
          axisLine: {
            lineStyle: {
              width: 1,
              color: "#80868C",
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: "value",
          name: props.yAxisName,
          axisLine: {
            lineStyle: {
              width: 1,
            },
          },
          axisLabel: {
            formatter: props.soundLevel === true ? "" : "{value}",
            textStyle: {
              color: "rgba(0, 0, 0, 0.42)",
            },
          },
          axisTick: {
            show: false,
          },
        },

        series: [
          {
            data: props.seriesData,
            type: "line",
            smooth: true,
            cursor: props.handleOpenQuickInspect ? "pointer" : "default",
            //props.haveData used to set size of symbol on hover
            symbolSize: props?.checkSymbolZize ? 10 : props.haveData ? 1 : 0,
            emphasis: {
              itemStyle: props.handleOpenQuickInspect && {
                borderWidth: 10,
              },
            },
          },
        ],
      }}
      style={{ height: props.height || "330px", width: "100%" }}
      theme="custom_theme"
      opts={{ renderer: "canvas" }}
      onEvents={{
        click: onChartClick,
      }} //add this event for onClick work in quick-inspect
    />
  );
};

export default LineChart;
