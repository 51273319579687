import { useMutation, useQueryClient } from "@tanstack/react-query";
import AccountsListController from "services/controllers/accountsListController";

export const useCreatAccountsList = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (saveData) => {
      await AccountsListController.createAccountsList(saveData);
      queryClient.invalidateQueries("accountsList");
    },
  });
};
