import { useQuery } from "@tanstack/react-query";
import AccountsListController from "services/controllers/accountsListController";

export const useGetAccountsListById = (id, enabled) => {
  return useQuery({
    queryKey: ["accountsList", id],
    queryFn: () => AccountsListController.getAccountsListById(id),
    retry: false,
    enabled: enabled,
  });
};
