import { useState, useEffect } from "react";
import moment from "moment";
import {
  Typography,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
} from "@mui/material";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngry, faSmile } from "@fortawesome/free-regular-svg-icons";
import ReachIcon from "images/benchmark-images/reach.svg";
import ImpressionIcon from "images/benchmark-images/impression.svg";
import UniqueEngagersIcon from "images/benchmark-images/users.svg";
import FetchingData from "components/fetchingData/fetchingData";
import {
  dateTotext,
  CheckValueLocale,
  commasAfterDigit,
  dateTotextMinusLastDay,
} from "utils/helpers";
const ReachOverview = (props) => {
  const {
    type,
    monitorPublicType,
    intl,
    preloaderDatasource,
    benchmarkHeadData,
    setReachOverData,
    reachOverData,
    rmqResponse,
    resetAllFields,
    setAllDataExist,
  } = props;

  const [maxUniqueEnagagersCount, setMaxUniqueEnagagersCount] = useState(0);
  const [maxReachCount, setMaxReachCount] = useState(0);
  const [maxImpressionCount, setMaxImpressionCount] = useState(0);
  const [tableHeadCellData, setTableHeadCellData] = useState([]);

  const lastPeriodDates = preloaderDatasource;
  const monitorsID = benchmarkHeadData?.monitorData?.map(
    (x) =>
      `${x?.monitor_id}_${x?.data_source_name?.charAt(0)?.toUpperCase() + x?.data_source_name?.slice(1)?.toLowerCase()}`,
  );

  const [uniqueEnagagersData, setUniqueEnagagersData] = useState([]);
  const [reachData, setReachData] = useState([]);
  const [impressionData, setImpressionData] = useState([]);
  const [reachOverviewPreloader, setReachOverviewPreloader] = useState([]);
  const [reachOverviewData, setReachOverviewData] = useState([]);
  const [reachOverViewId, setReachOverViewId] = useState([]);

  const handleReachOverviewData = (response, order, tableHeadData) => {
    if (order !== undefined) {
      switch (order) {
        case lastPeriodDates[0]:
          tableHeadCellData?.unshift(tableHeadData);
          setTableHeadCellData(tableHeadCellData);
          response?.eventData?.reach_funnel?.map((reachTableData) => {
            let finalVal = null;
            if (reachTableData?.value) {
              finalVal = reachTableData?.value[0] + reachTableData?.value[1];
            }
            if (reachTableData?.name === "authors") {
              const authorsArr = uniqueEnagagersData;
              authorsArr?.unshift(finalVal);
              setUniqueEnagagersData(authorsArr);
            } else if (reachTableData?.name === "potential_reach") {
              const reachArr = reachData;
              reachArr?.unshift(finalVal);
              setReachData(reachArr);
            } else if (reachTableData?.name === "potential_impression") {
              const impressionsArr = impressionData;
              impressionsArr?.unshift(finalVal);
              setImpressionData(impressionsArr);
            }
          });
          break;
        case lastPeriodDates[1]:
          tableHeadCellData?.push(tableHeadData);
          setTableHeadCellData(tableHeadCellData);
          response?.eventData?.reach_funnel?.map((reachTableData) => {
            let finalVal = null;
            if (reachTableData?.value) {
              finalVal = reachTableData?.value[0] + reachTableData?.value[1];
            }
            if (reachTableData?.name === "authors") {
              const authorsArr = uniqueEnagagersData;
              authorsArr?.push(finalVal);
              setUniqueEnagagersData(authorsArr);
            } else if (reachTableData?.name === "potential_reach") {
              const reachArr = reachData;
              reachArr?.push(finalVal);
              setReachData(reachArr);
            } else if (reachTableData?.name === "potential_impression") {
              const impressionsArr = impressionData;
              impressionsArr?.push(finalVal);
              setImpressionData(impressionsArr);
            }
          });
          break;
        default:
          break;
      }
    } else {
      response?.map((reachTableData) => {
        let finalVal = null;
        if (reachTableData?.value) {
          finalVal =
            Math.trunc(reachTableData?.value[0]) +
            Math.trunc(reachTableData?.value[1]);
        }
        if (reachTableData?.name === "authors") {
          const authorsArr = uniqueEnagagersData;
          reachOverData?.uniqueEnagagersData?.push(finalVal);
          authorsArr?.push(finalVal);
          setReachOverData(reachOverData);
          setUniqueEnagagersData(authorsArr);
        } else if (reachTableData?.name === "potential_reach") {
          const reachArr = reachData;
          reachOverData?.reachData?.push(finalVal);
          reachArr?.push(finalVal);
          setReachOverData(reachOverData);
          setReachData(reachArr);
        } else if (reachTableData?.name === "potential_impression") {
          const impressionsArr = impressionData;
          reachOverData?.impressionData?.push(finalVal);
          impressionsArr?.push(finalVal);
          setReachOverData(reachOverData);
          setImpressionData(impressionsArr);
        }
      });
    }
  };

  useEffect(() => {
    if (
      monitorPublicType === "Time_Period_vs_Time_Period" ||
      monitorPublicType === "Positive_vs_Negative"
    ) {
      setReachOverData({
        ...reachOverData,
        uniqueEnagagersData: uniqueEnagagersData,
        reachData: reachData,
        impressionData: impressionData,
      });
    }
  }, [uniqueEnagagersData, reachData, impressionData]);

  useEffect(() => {
    switch (rmqResponse?.eventName) {
      case "Twitter__VsAnotherBenchmarkV4Page__reach_funnel":
      case "Facebook__VsAnotherBenchmarkV4Page__reach_funnel":
      case "Instagram__VsAnotherBenchmarkV4Page__reach_funnel":
      case "Talkwalker__VsAnotherBenchmarkV4Page__reach_funnel":
        setAllDataExist(false);
        reachOverViewId?.push(
          `${rmqResponse?.monitor_id}_${rmqResponse?.data_source}`,
        );
        setReachOverViewId(reachOverViewId);
        reachOverviewData?.push(rmqResponse?.eventData?.reach_funnel);
        setReachOverviewData(reachOverviewData);
        if (reachOverViewId?.length == monitorsID?.length) {
          let checkPreLoader = [];
          monitorsID?.map((x) => {
            reachOverViewId?.map((y) =>
              y == x ? checkPreLoader?.push(y) : null,
            );
          });
          if (checkPreLoader?.length == monitorsID?.length) {
            monitorsID?.map((id1) => {
              reachOverViewId?.map((id2, x) => {
                if (id1 == id2) {
                  let data = reachOverviewData[x];
                  handleReachOverviewData(data);
                }
              });
            });
          }
        }
        setReachOverviewPreloader((oldArray) => [
          ...oldArray,
          `${rmqResponse?.monitor_id}_${rmqResponse?.data_source?.toLowerCase()}`,
        ]);
        break;
      case "Twitter__LastPeriodBenchmarkV4Page__reach_funnel":
      case "Facebook__LastPeriodBenchmarkV4Page__reach_funnel":
      case "Instagram__LastPeriodBenchmarkV4Page__reach_funnel":
      case "Talkwalker__LastPeriodBenchmarkV4Page__reach_funnel":
        setAllDataExist(false);
        const tableHeadData =
          monitorPublicType === "Time_Period_vs_Time_Period"
            ? dateTotextMinusLastDay(rmqResponse?.period)
            : dateTotext(rmqResponse?.period);
        handleReachOverviewData(
          rmqResponse,
          rmqResponse?.period,
          tableHeadData,
        );
        setReachOverviewPreloader((oldArray) => [
          ...oldArray,
          rmqResponse?.period,
        ]);
        break;
      case "Twitter__PositiveNegativeBenchmarkV4Page__reach_funnel":
      case "Facebook__PositiveNegativeBenchmarkV4Page__reach_funnel":
      case "Instagram__PositiveNegativeBenchmarkV4Page__reach_funnel":
      case "Talkwalker__PositiveNegativeBenchmarkV4Page__reach_funnel":
        const sentimentTableHeadData = rmqResponse?.sentiment?.toLowerCase();
        handleReachOverviewData(
          rmqResponse,
          rmqResponse?.sentiment,
          sentimentTableHeadData,
        );
        setReachOverviewPreloader((oldArray) => [
          ...oldArray,
          rmqResponse?.sentiment,
        ]);
        break;
      default:
        break;
    }
  }, [rmqResponse]);

  useEffect(() => {
    // Avoid Initial State Calling
    if (resetAllFields !== 1) {
      setUniqueEnagagersData([]);
      setReachData([]);
      setImpressionData([]);
      setReachOverviewPreloader([]);
      setTableHeadCellData([]);
      setReachOverviewData([]);
      setReachOverViewId([]);
      setReachOverData({
        uniqueEnagagersData: [],
        reachData: [],
        impressionData: [],
        tableHeadCellData: [],
        maxUniqueEnagagersCount: 0,
        maxReachCount: 0,
        maxImpressionCount: 0,
      });
      setAllDataExist(false);
    }
  }, [resetAllFields]);

  useEffect(() => {
    setAllDataExist(false);
    if (
      _.isEqual(reachOverviewPreloader?.sort(), preloaderDatasource?.sort()) ||
      reachOverviewPreloader?.length ||
      preloaderDatasource?.length
    ) {
      setAllDataExist(true);
      reachOverData.maxUniqueEnagagersCount = Math.max(...uniqueEnagagersData);
      reachOverData.maxReachCount = Math.max(...reachData);
      reachOverData.maxImpressionCount = Math.max(...impressionData);
      setReachOverData(reachOverData);
      setMaxUniqueEnagagersCount(Math.max(...uniqueEnagagersData));
      setMaxReachCount(Math.max(...reachData));
      setMaxImpressionCount(Math.max(...impressionData));
    }
  }, [reachOverviewPreloader]);

  return (
    <>
      <TableRow className="body-tr-title">
        <TableCell
          className={`${
            monitorPublicType === "Monitor_vs_Monitors" && "sticky"
          } widget-title-benchmark`}
          colSpan={8}
        >
          {CheckValueLocale("reach_overview", "", {}, intl)}
        </TableCell>
      </TableRow>
      {_.isEqual(
        reachOverviewPreloader?.sort(),
        preloaderDatasource?.sort(),
      ) !== true ||
      !reachOverviewPreloader?.length ||
      !preloaderDatasource?.length ? (
        <TableRow className="body-tr-head fetching-tr">
          <TableCell className="feching-td">
            <FetchingData />
          </TableCell>
        </TableRow>
      ) : (
        <TableBody>
          {monitorPublicType !== "Monitor_vs_Monitors" && (
            <>
              <TableRow className="body-tr-head">
                <TableCell component="th" className="title-section">
                  <Typography component="span">
                    {CheckValueLocale("metrics", "", {}, intl)}
                  </Typography>
                </TableCell>
                {tableHeadCellData?.map((val, index) => (
                  <TableCell
                    component="th"
                    key={`reach-metrics-th-${index}`}
                    align={"center"}
                  >
                    {monitorPublicType === "Positive_vs_Negative" && (
                      <FontAwesomeIcon
                        icon={val === "positive" ? faSmile : faAngry}
                        className={`${val}_icon`}
                      />
                    )}
                    <Typography
                      component="span"
                      dir={
                        monitorPublicType === "Time_Period_vs_Time_Period" &&
                        "ltr"
                      }
                    >
                      {CheckValueLocale(val, "", {}, intl)}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </>
          )}
          {type === "sm" ? (
            <TableRow className="body-tr-data">
              <TableCell component="th" scope="row">
                <img src={UniqueEngagersIcon} className="unique-author-icon" />
                <Typography component="span">
                  {CheckValueLocale("unique_engagers", "", {}, intl)}
                </Typography>
              </TableCell>
              {uniqueEnagagersData?.map((val, index) => {
                return (
                  <TableCell
                    key={`unique-engagers-${index}`}
                    align="center"
                    className={
                      val > 0 && val === maxUniqueEnagagersCount
                        ? "highlight-td"
                        : ""
                    }
                  >
                    {val != null
                      ? val % 1 === 0
                        ? commasAfterDigit(val)
                        : commasAfterDigit(val?.toFixed(2))
                      : "—"}
                  </TableCell>
                );
              })}
            </TableRow>
          ) : null}
          <TableRow className="body-tr-data">
            <TableCell component="th" scope="row">
              <img src={ReachIcon} />
              <Typography component="span" className="reach-title">
                {CheckValueLocale(
                  type === "sm"
                    ? "potential_reach_quick"
                    : "total_interactions",
                  "",
                  {},
                  intl,
                )}
              </Typography>
            </TableCell>
            {reachData?.map((val, index) => {
              return (
                <TableCell
                  key={`reach-${index}`}
                  align="center"
                  className={
                    val > 0 && val === maxReachCount ? "highlight-td" : ""
                  }
                >
                  {val != null
                    ? val % 1 == 0
                      ? commasAfterDigit(val)
                      : commasAfterDigit(val?.toFixed(2))
                    : "—"}
                </TableCell>
              );
            })}
          </TableRow>
          <TableRow className="body-tr-data">
            <TableCell component="th" scope="row">
              <img src={ImpressionIcon} />
              <Typography component="span">
                {CheckValueLocale(
                  type === "sm"
                    ? "potential_impression_quick"
                    : "average_change_in_followers",
                  "",
                  {},
                  intl,
                )}
              </Typography>
            </TableCell>
            {impressionData?.map((val, index) => {
              return (
                <TableCell
                  key={`impression-${index}`}
                  align="center"
                  className={
                    val > 0 && val === maxImpressionCount ? "highlight-td" : ""
                  }
                >
                  {val != null
                    ? val % 1 == 0
                      ? commasAfterDigit(val)
                      : commasAfterDigit(val?.toFixed(2))
                    : "—"}
                </TableCell>
              );
            })}
          </TableRow>
        </TableBody>
      )}
    </>
  );
};

export default ReachOverview;
