import { useQuery } from "@tanstack/react-query";
import AccountsListController from "services/controllers/accountsListController";

export const useGetAccountsListExcelGuide = (openImportPopUp) => {
  return useQuery({
    queryKey: ["accountsListExcelGuide"],
    queryFn: () => AccountsListController.getXlsxGuide(),
    retry: false,
    enabled: !!openImportPopUp,
  });
};
