import { useQuery } from "@tanstack/react-query";
import SettingsController from "services/controllers/settingsController";
export const useGetBouncedAccountsList = (page, filters) => {
  return useQuery({
    queryKey: ["BouncedEmailsList", page, filters],
    queryFn: () => SettingsController?.bouncedAccountsList(page, filters),
    select: (data) => data?.data,
    retry: false,
  });
};
