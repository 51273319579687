import { useState, createContext } from "react";

export const EditContext = createContext({
  isEditing: false,
  setIsEditing: () => {},
  currentEditingSection: null,
  setCurrentEditingSection: () => {},
  callSingleinteraction: () => {},
  userId: null,
  setStats: () => {},
  hasCDPSubscription: false,
});

export const EditProvider = ({
  children,
  callSingleinteraction,
  userId,
  setStats,
  hasCDPSubscription,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentEditingSection, setCurrentEditingSection] = useState(null);

  return (
    <EditContext.Provider
      value={{
        isEditing,
        setIsEditing,
        currentEditingSection,
        setCurrentEditingSection,
        callSingleinteraction,
        userId,
        setStats,
        hasCDPSubscription,
      }}
    >
      {children}
    </EditContext.Provider>
  );
};
