import { Box } from "@mui/material";
import QRCode from "react-qr-code";
import "./qrTwoFactorAuthCode.scss";
import LucButton from "shared/lucButton/lucButton";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";

const QRTwoFactorAuthCode = ({
  qrCode,
  totpSecret,
  showQRcode,
  setShowQRcode,
}) => {
  const intl = useIntl();
  return (
    <Box className="qr-two-factor-auth-container">
      {showQRcode ? (
        <Box className="margin-bottom">
          <QRCode size={128} value={qrCode} />
        </Box>
      ) : (
        <Box className="margin-bottom totp-secret-code">{totpSecret}</Box>
      )}

      <LucButton
        variant="flat"
        size="small"
        onClick={() => setShowQRcode((prev) => !prev)}
      >
        {CheckValueLocale(
          `${showQRcode ? "can’t_scan_qr_code" : "try_to_scan_qr_code"}`,
          "",
          {},
          intl,
        )}
      </LucButton>
    </Box>
  );
};

export default QRTwoFactorAuthCode;
