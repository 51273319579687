// ************** Settings Page **************
// This page is used to show the main settings page layout
// and render the sidebar at the left side of the page
// and the content at the right side mapping on the settingsNavItems
// array and returning the corresponding component for each item with its route
// *******************************************

import { Box } from "@mui/material";
import {
  Navigate,
  Redirect,
  Route,
  Routes,
  Switch,
  useLocation,
} from "react-router-dom";
import { settingsNavItems } from "./components/sidebar/settingsNavItems";
import SettingsSidebar from "./components/sidebar/index";
import Footer from "shared/footer/Footer";
import NotFoundPage from "pages/not-found/NotFoundPage.js";
import "./style.scss";
import { settingsRoutes } from "utils/routes/component/pathes";
import PrivateRoute from "shared/privateRoute/privateRoute";
import { EditInboundRouting } from "./pages/inboundRoutingPage/editInboundRouting/editInboundRouting";
import { CreateInboundRouting } from "./pages/inboundRoutingPage/createInboundRouting/createInboundRouting";
import { handlelUserRoles } from "utils/helpers";
const Settings = () => {
  const location = useLocation();
  const showSideBar = [
    "/settings/company",
    "/settings/user_managements",
    "/settings/teams",
    "/settings/security",
    "/settings/subscription",
    "/settings/accounts_management/company_accounts",
    "/settings/accounts_management/users_accounts",
    "/settings/branding",
    "/settings/historical_data",
    "/settings/instagram_hashtags",
    "/settings/inbound_routing",
    "/settings/3rd_party_integrations",
    "/settings/lucidya_api",
  ]?.includes(location?.pathname);
  const firstMenuItemPath = settingsNavItems
    ?.flatMap((group) => group?.items)
    ?.find((item) => item?.show)?.path;

  return (
    <Box id={`${showSideBar ? "settings-page" : ""}`}>
      {/* Settings Page Sidebar */}
      {showSideBar ? (
        <SettingsSidebar settingsNavItems={settingsNavItems} />
      ) : null}

      {/* Settings Page Content */}
      <Box id={`${showSideBar ? "settings-page-content-wrapper" : ""}`}>
        <Box className={`${showSideBar ? "settings-page-content" : ""}`}>
          {/* Redirect to first menu item when accessing /settings */}
          <Routes>
            <Route
              path=""
              element={<Navigate to={firstMenuItemPath} replace />}
            />
            {/* Render the corresponding component for each item with its route */}
            {settingsNavItems
              ?.filter((group) => group?.items?.some((item) => item?.show))
              ?.map((group) =>
                group?.items
                  ?.filter((item) => item?.show)
                  ?.map((item) => (
                    <Route
                      key={item?.name}
                      path={item?.path}
                      element={item?.component}
                    />
                  )),
              )}
            {settingsRoutes?.map(({ path, component: Component }) => (
              <Route
                key={path}
                path={path}
                element={
                  <PrivateRoute
                    element={<Component />}
                    isAuthenticated={true}
                  />
                }
              />
            ))}
            <Route
              path="inbound_routing/create/:step"
              element={
                <PrivateRoute
                  element={
                    handlelUserRoles(
                      "ENGAGEMENTS",
                      "ACCESS_ENGAGEMENT_SYSTEM",
                    ) &&
                    (handlelUserRoles("ENGAGEMENT_ACCESS", "FULL_ACCESS") ||
                      handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX")) ? (
                      <CreateInboundRouting />
                    ) : (
                      NotFoundPage
                    )
                  }
                  isAuthenticated={true}
                />
              }
            />
            <Route
              path="inbound_routing/edit/:id"
              element={
                <PrivateRoute
                  element={
                    handlelUserRoles(
                      "ENGAGEMENTS",
                      "ACCESS_ENGAGEMENT_SYSTEM",
                    ) &&
                    (handlelUserRoles("ENGAGEMENT_ACCESS", "FULL_ACCESS") ||
                      handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX")) ? (
                      <EditInboundRouting />
                    ) : (
                      <NotFoundPage />
                    )
                  }
                  isAuthenticated={true}
                />
              }
            />
            {/* Not found page */}
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Box>

        {/* Main App Footer as it is excluded from App.js */}
        {showSideBar ? <Footer isSettingsContent={true} /> : null}
      </Box>
    </Box>
  );
};

export default Settings;
