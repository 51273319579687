import { useEffect, useState } from "react";
import { Typography, Box, Table, TableContainer, Paper } from "@mui/material";

import { useIntl } from "react-intl";
import BenchmarkHeader from "../components/benchmark_header";
import "../benchmark.scss";
/*------------------------------------------------*/

import EmptyBench from "images/benchmark-images/empty-bench.svg";
import ReachOverview from "../widgets/reach-overview/reach-overview";
import CustomerCareOverview from "../widgets/customer-care-overview/customer-care-overview";
import EngagementOverview from "../widgets/engagement-overview/engagement-overview";
import SentimentOverview from "../widgets/sentiment-overview/sentiment-overview";

/*------------------------------------------------*/
import CircularLoading from "components/circularProgress";
import BenchmarkResultHeader from "../components/BenchmarkResultHeader";
/*------------------------------------------------*/

import BenchmarkTableHead from "../components/BenchmarkTableHead";
import Snackbar from "components/snackBar";
import { CheckValueLocale, syncUnixToUTC } from "utils/helpers";
import NoDataFoundProducts from "components/no-Data";
import NoDataLinks from "components/no-Data/noDataLinks";

const CxmTabContent = ({
  comparisonTypes,
  informalList,
  setLuciDataLoading,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  setComparisonType,
  setCheckDataArr,
  checkNoDataFound,
  setCheckNoDataFound,
  setBenchmarkData,
  setLuciMonitorType,
  setLuciComparisonType,
  setDataSourceName,
  vsTypeData,
  setVsTypeData,
  anotherTypeData,
  setAnotherTypeData,
  monitorType,
  setMonitorType,
  comparisonType,
  filterBenchmark,
  setFilterBenchmark,
  filterParams,
  setFilterParams,
  filterFlag,
  setFilterFlag,
  pickerStartDate,
  pickerEndDate,
  setPickerStartDate,
  setPickerEndDate,
}) => {
  const intl = useIntl();
  const [monitorPublicType, setMonitorPublicType] = useState("");
  const [monitorLoading, setMonitorLoading] = useState(false);
  const [emptyMsg, setEmptyMsg] = useState(true);
  const [allMonitorData, setAllMonitorData] = useState({});
  const [showBenchmarkData, setShowBenchmarkData] = useState(false);
  const [benchmarkHeadData, setBenchmarkHeadData] = useState({
    monitorData: [],
    fromDate: "",
    toDate: "",
    monitor_type_name: "",
  });
  const [errorExportMsg, setErrorExportMsg] = useState("");
  const [successExportMsg, setSuccessExportMsg] = useState("");
  const [success, setSuccess] = useState(null);
  const [isExportLoading, setIsExportLoading] = useState();

  const [rmqResponse, setRmqResponse] = useState([]);
  const [preloaderDatasource, setPreloaderDatasource] = useState([]);
  const [resetAllFields, setResetAllFields] = useState(0);
  const [resetTablesData, setResetTablesData] = useState(false);
  // data for PDF
  const [sentimentPdfData, setSentimentPdfData] = useState({
    positiveSentimentData: [],
    neutralSentimentData: [],
    negativeSentimentData: [],
    sentimentTableHeadCellData: [],
    maxPositiveSentimentCount: 0,
    maxNeutralSentimentCount: 0,
    maxNegativeSentimentCount: 0,
  });

  const [customerData, setCustomerData] = useState({
    totalAskedQuestionsData: [],
    responseRateData: [],
    responseTimeQuestionsData: [],
    customerCareTableHeadCellData: [],
    maxTotalAskedQuestionsCount: 0,
    maxResponseRateCount: 0,
    maxResponseTimeQuestionsCount: 0,
  });

  const [engagementData, setEngagementData] = useState({
    shareOfVoiceData: [],
    totalPostsData: [],
    avgPostsPerDay: [],
    totalEngagments: [],
    avgEngagmentPerTweetData: [],
    totalEngagmentFollowertData: [],
    engagementtableHeadCellData: [],
    maxShareOfVoiceDataCount: 0,
    maxTotalPostsDataCount: 0,
    maxAvgPostsPerDayCount: 0,
    maxTotalEngagmentsCount: 0,
    maxAvgEngagmentPerTweetCount: 0,
    maxTotalEngagmentFollowertDataCount: 0,
  });

  const [reachOverData, setReachOverData] = useState({
    uniqueEnagagersData: [],
    reachData: [],
    impressionData: [],
    tableHeadCellData: [],
    maxUniqueEnagagersCount: 0,
    maxReachCount: 0,
    maxImpressionCount: 0,
  });
  //for loading export PDF
  const [allSentimentDataExist, setAllSentimentDataExist] = useState(false);
  const [allCustomerExist, setAllCustomerExist] = useState(false);
  const [allEngagementExist, setAllEngagementExist] = useState(false);
  const [allReachExist, setAllReachExist] = useState(false);
  const [showPDFBtn, setShowPDFBtn] = useState(true);

  let customerCareCondition = !(
    monitorPublicType === "Positive_vs_Negative" &&
    benchmarkHeadData.monitor_type_name === "KEYWORD"
  );

  let reachOverviewCondition = !(
    monitorPublicType === "Positive_vs_Negative" &&
    (benchmarkHeadData.monitor_type_name === "KEYWORD" ||
      benchmarkHeadData.monitor_type_name === "ACCOUNT_ANALYSIS") &&
    benchmarkHeadData?.monitorData[0]?.data_source_name === "FACEBOOK"
  );

  let sentimentCondition = monitorPublicType !== "Positive_vs_Negative";

  useEffect(() => {
    if (monitorPublicType !== "Monitor_vs_Monitors") {
      setReachOverData({
        uniqueEnagagersData: [],
        reachData: [],
        impressionData: [],
        tableHeadCellData: [],
        maxUniqueEnagagersCount: 0,
        maxReachCount: 0,
        maxImpressionCount: 0,
      });
      setEngagementData({
        shareOfVoiceData: [],
        totalPostsData: [],
        avgPostsPerDay: [],
        totalEngagments: [],
        avgEngagmentPerTweetData: [],
        totalEngagmentFollowertData: [],
        engagementtableHeadCellData: [],
        maxShareOfVoiceDataCount: 0,
        maxTotalPostsDataCount: 0,
        maxAvgPostsPerDayCount: 0,
        maxTotalEngagmentsCount: 0,
        maxAvgEngagmentPerTweetCount: 0,
        maxTotalEngagmentFollowertDataCount: 0,
      });
      setCustomerData({
        totalAskedQuestionsData: [],
        responseRateData: [],
        responseTimeQuestionsData: [],
        customerCareTableHeadCellData: [],
        maxTotalAskedQuestionsCount: 0,
        maxResponseRateCount: 0,
        maxResponseTimeQuestionsCount: 0,
      });
      setSentimentPdfData({
        positiveSentimentData: [],
        neutralSentimentData: [],
        negativeSentimentData: [],
        sentimentTableHeadCellData: [],
        maxPositiveSentimentCount: 0,
        maxNeutralSentimentCount: 0,
        maxNegativeSentimentCount: 0,
      });
    } else if (benchmarkHeadData?.monitor_type_name == "KEYWORD") {
      setCustomerData({
        totalAskedQuestionsData: [],
        responseRateData: [],
        responseTimeQuestionsData: [],
        customerCareTableHeadCellData: [],
        maxTotalAskedQuestionsCount: 0,
        maxResponseRateCount: 0,
        maxResponseTimeQuestionsCount: 0,
      });
    }

    if (monitorPublicType) {
      setComparisonType(monitorPublicType);
    }
  }, [monitorPublicType, benchmarkHeadData?.monitor_type_name]);

  useEffect(() => {
    let show = allEngagementExist;
    if (customerCareCondition) {
      show = show && allCustomerExist;
    }
    if (reachOverviewCondition) {
      show = show && allReachExist;
    }
    if (sentimentCondition) {
      show = show && allSentimentDataExist;
    }
    setShowPDFBtn(show);
    if (show === true) setLuciDataLoading(false);
  }, [
    allSentimentDataExist,
    allReachExist,
    allCustomerExist,
    allReachExist,
    allEngagementExist,
  ]);

  useEffect(() => {
    if (benchmarkHeadData) setBenchmarkData(benchmarkHeadData);
  }, [benchmarkHeadData]);

  const checkDataFound = (data) => {
    return data?.some((value) => value !== 0 && value !== null);
  };

  useEffect(() => {
    // Checking length of any data to check benchmarking start
    if (engagementData?.totalPostsData)
      setCheckDataArr(engagementData?.totalPostsData);

    if (engagementData && checkNoDataFound) {
      let noDataFound = true;
      if (
        checkDataFound(engagementData?.totalPostsData) ||
        checkDataFound(engagementData?.totalEngagments)
      )
        noDataFound = false;

      setCheckNoDataFound(noDataFound);
    }
  }, [engagementData]);

  useEffect(() => {
    if (reachOverData && checkNoDataFound) {
      let noDataFound = true;
      if (checkDataFound(reachOverData?.reachData)) noDataFound = false;
      setCheckNoDataFound(noDataFound);
    }
  }, [reachOverData, checkDataFound]);

  return (
    <>
      {success != null ? (
        <Snackbar
          lang={window.localStorage.lang}
          open={success != null ? true : false}
          handleClose={() => {
            setSuccess(null);
          }}
          severity={success ? "success" : "error"}
          message={
            success ? (
              <span
                style={{
                  fontWeight: "400",
                  fontSize: "14px",
                  color: "#000",
                }}
              >
                {CheckValueLocale(successExportMsg, "", {}, intl)}
              </span>
            ) : (
              <div>
                <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                  {CheckValueLocale(errorExportMsg, "", {}, intl)}
                  <br />
                </span>
                {CheckValueLocale("update_not", "", {}, intl)}
              </div>
            )
          }
        />
      ) : (
        ""
      )}
      <BenchmarkHeader
        type="cxm"
        comparisonTypes={comparisonTypes}
        informalList={informalList}
        setBenchmarkHeadData={setBenchmarkHeadData}
        setMonitorLoading={setMonitorLoading}
        setMonitorPublicType={setMonitorPublicType}
        setAllMonitorData={setAllMonitorData}
        setEmptyMsg={setEmptyMsg}
        setShowBenchmarkData={setShowBenchmarkData}
        setPreloaderDatasource={setPreloaderDatasource}
        setResetAllFields={setResetAllFields}
        setRmqResponse={setRmqResponse}
        setResetTablesData={setResetTablesData}
        resetTablesData={resetTablesData}
        setLuciDataLoading={setLuciDataLoading}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        setLuciMonitorType={setLuciMonitorType}
        setLuciComparisonType={setLuciComparisonType}
        setDataSourceName={setDataSourceName}
        vsTypeData={vsTypeData}
        setVsTypeData={setVsTypeData}
        anotherTypeData={anotherTypeData}
        setAnotherTypeData={setAnotherTypeData}
        monitorType={monitorType}
        setMonitorType={setMonitorType}
        comparisonType={comparisonType}
        setComparisonType={setComparisonType}
        filterBenchmark={filterBenchmark}
        setFilterBenchmark={setFilterBenchmark}
        setFilterFlag={setFilterFlag}
        pickerStartDate={pickerStartDate}
        pickerEndDate={pickerEndDate}
        setPickerStartDate={setPickerStartDate}
        setPickerEndDate={setPickerEndDate}
      />
      {emptyMsg && !monitorLoading && (
        <Box className="empty-bench-msg">
          <NoDataFoundProducts
            image="emptyBenchmark"
            title="benchmark_your_monitors"
            description="benchmark_your_monitors_dec"
            linkOne="how_to_use"
            linkOneHref={NoDataLinks.benchmark_link}
            orCase="or_visit_the"
            linkTwo="knowledge_base"
          />
        </Box>
      )}
      {monitorLoading ? (
        <CircularLoading />
      ) : (
        <>
          {showBenchmarkData && (
            <>
              <BenchmarkResultHeader
                type="cxm"
                monitorPublicType={monitorPublicType}
                benchmarkHeadData={benchmarkHeadData}
                setIsExportLoading={setIsExportLoading}
                isExportLoading={isExportLoading}
                intl={intl}
                monitor_type_name={benchmarkHeadData?.monitor_type_name}
                setSuccessExportMsg={setSuccessExportMsg}
                setErrorExportMsg={setErrorExportMsg}
                setSuccess={setSuccess}
                showPDFBtn={showPDFBtn}
                reachOverData={reachOverData}
                engagementData={engagementData}
                customerData={customerData}
                sentimentPdfData={sentimentPdfData}
                vsTypeData={vsTypeData}
                anotherTypeData={anotherTypeData}
                monitorType={monitorType}
                comparisonType={comparisonType}
                startDate={startDate}
                endDate={endDate}
                filterBenchmark={filterBenchmark}
                setFilterBenchmark={setFilterBenchmark}
                filterParams={filterParams}
                setFilterParams={setFilterParams}
                filterFlag={filterFlag}
                setFilterFlag={setFilterFlag}
              />

              <TableContainer
                className={`benchmark-monitor-table-container ${
                  monitorPublicType === "Monitor_vs_Monitors"
                    ? "sticky-benchmark-table"
                    : ""
                }`}
                component={Paper}
              >
                <Table
                  aria-label="simple table"
                  className={`${
                    monitorPublicType !== "Monitor_vs_Monitors"
                      ? "table_wrapper"
                      : " "
                  }`}
                >
                  {monitorPublicType === "Monitor_vs_Monitors" && (
                    <BenchmarkTableHead
                      monitorPublicType={monitorPublicType}
                      allMonitorData={allMonitorData}
                      benchmarkHeadData={benchmarkHeadData}
                      intl={intl}
                    />
                  )}
                  {reachOverviewCondition ? (
                    <ReachOverview
                      type="cxm"
                      monitorPublicType={monitorPublicType}
                      intl={intl}
                      rmqResponse={rmqResponse}
                      resetAllFields={resetAllFields}
                      preloaderDatasource={preloaderDatasource}
                      benchmarkHeadData={benchmarkHeadData}
                      allDataExist={allReachExist}
                      setAllDataExist={setAllReachExist}
                      setReachOverData={setReachOverData}
                      reachOverData={reachOverData}
                    />
                  ) : null}
                  <EngagementOverview
                    allMonitorData={allMonitorData}
                    monitorPublicType={monitorPublicType}
                    benchmarkHeadData={benchmarkHeadData}
                    rmqResponse={rmqResponse}
                    resetTablesData={resetTablesData}
                    preloaderDatasource={preloaderDatasource}
                    intl={intl}
                    allDataExist={allEngagementExist}
                    setAllDataExist={setAllEngagementExist}
                    engagementData={engagementData}
                    setEngagementData={setEngagementData}
                  />
                  {customerCareCondition ? (
                    <CustomerCareOverview
                      monitorPublicType={monitorPublicType}
                      intl={intl}
                      rmqResponse={rmqResponse}
                      resetAllFields={resetAllFields}
                      preloaderDatasource={preloaderDatasource}
                      benchmarkHeadData={benchmarkHeadData}
                      allDataExist={allCustomerExist}
                      setAllDataExist={setAllCustomerExist}
                      customerData={customerData}
                      setCustomerData={setCustomerData}
                    />
                  ) : null}
                  {sentimentCondition ? (
                    <SentimentOverview
                      allMonitorData={allMonitorData}
                      monitorPublicType={monitorPublicType}
                      benchmarkHeadData={benchmarkHeadData}
                      rmqResponse={rmqResponse}
                      resetTablesData={resetTablesData}
                      preloaderDatasource={preloaderDatasource}
                      intl={intl}
                      allDataExist={allSentimentDataExist}
                      setAllDataExist={setAllSentimentDataExist}
                      sentimentPdfData={sentimentPdfData}
                      setSentimentPdfData={setSentimentPdfData}
                    />
                  ) : null}
                </Table>
              </TableContainer>
            </>
          )}
        </>
      )}
    </>
  );
};

export default CxmTabContent;
