import { Box, InputLabel, MenuItem, Select, Tooltip } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import shareSurvey from "images/engagements-images/survey/shareSurvey.svg";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import SurveyDemo from "./SurveyDemo";

const ShareSurvey = ({ surveyData, setSurveyData, selectedTap }) => {
  const intl = useIntl();

  const activeProducts = JSON.parse(
    window?.localStorage?.activeProducts || null,
  );
  const surveyActive = activeProducts?.find(
    (product) => product?.name == "SURVEY",
  );

  const fields = ["surveys_engag", "delivery_method_engag_survey"];

  const fieldsData = surveyData?.mainData;
  const selectedTabData = surveyData?.selectedData?.[selectedTap];

  const handleChangeSelect = (event, field) => {
    surveyData.selectedData[selectedTap][field] = event.target.value;
    setSurveyData({ ...surveyData });
  };

  const selectShared = (fieled) => {
    return (
      <Box className={`survey-setting-field`}>
        <Box className="survey-chat-text-title">
          <InputLabel>{CheckValueLocale(fieled, "", {}, intl)}</InputLabel>
          {fieled !== "surveys_engag" ? (
            <Tooltip
              placement="top"
              arrow
              title={CheckValueLocale(`${fieled}_tooltip`, "", {}, intl)}
            >
              <ErrorOutlineIcon />
            </Tooltip>
          ) : null}
        </Box>
        <Select
          value={selectedTabData?.[fieled]}
          onChange={(e) => handleChangeSelect(e, fieled)}
          placeholder={CheckValueLocale("survey_select", "", {}, intl)}
        >
          {fieldsData?.[fieled]?.map((item) => (
            <MenuItem value={fieled !== "surveys_engag" ? item : item?.id}>
              {fieled !== "surveys_engag"
                ? CheckValueLocale(`${item}_engag`, "", {}, intl)
                : item?.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
    );
  };

  return (
    <Box className="main-chat-survey">
      <Box className="engag-survey-disc">
        {CheckValueLocale("share_survey_disc", "", {}, intl)}
      </Box>
      {!surveyActive ? (
        <SurveyDemo />
      ) : (
        <Box className="chat-survey-container">
          <Box className="share-survey-container">
            {CheckValueLocale("survey_setting", "", {}, intl)}

            {fields?.map((field) => selectShared(field))}
          </Box>
          <Box className="chat-survey-img share-servay">
            <Box>
              {CheckValueLocale("branding_preview_title", "", {}, intl)}
            </Box>
            <img src={shareSurvey} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ShareSurvey;
