import { useQuery } from "@tanstack/react-query";
import Engagements from "services/api/engagements";

const useGetNotesList = ({ exclude_note_id, ...params }) => {
  return useQuery({
    queryKey: ["notesList", params],
    queryFn: () => Engagements.getNotesList({ exclude_note_id, ...params }),
    staleTime: 0,
  });
};

export default useGetNotesList;
