import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import LucButton from "shared/lucButton/lucButton";
import { Box } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import CustomizedTooltip from "components/customizedTooltip";

const NewTagButton = (props) => {
  const intl = useIntl();
  const { handleApply, checkHiddenNewTag, showTooltip } = props;

  return (
    <Box className="new-tag-btn-container">
      <CustomizedTooltip
        id="tag-menu-list-tooltip"
        title={
          showTooltip ? (
            <Box className="item-title-tooltip">
              {CheckValueLocale(
                showTooltip ? "maximum_limit_tags" : "",
                "",
                {},
                intl,
              )}
            </Box>
          ) : null
        }
        placement="top"
        arrow
      >
        <Box>
          <LucButton
            id="new-tag-btn"
            className="new-tag-btn"
            variant="Flat"
            size="large"
            onClick={handleApply}
            disabled={checkHiddenNewTag || showTooltip}
            startIcon={<FontAwesomeIcon icon={faPlus} />}
          >
            {CheckValueLocale("new_tag", "", {}, intl)}
          </LucButton>
        </Box>
      </CustomizedTooltip>
    </Box>
  );
};

export default NewTagButton;
