import _ from "lodash";
import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import popupImg from "images/survey/popup.svg";
import buttonImg from "images/survey/button.svg";

const EmbedOptions = (props) => {
  const { embeddedOptions, surveyData, setSurveyData } = props;

  const intl = useIntl();

  const selectedOptions = surveyData?.embedded_config?.embed_options || {};

  const handleChangeEmbedOptions = (key, value) => {
    setSurveyData((prev) => ({
      ...prev,
      embedded_config: {
        ...prev?.embedded_config,
        embed_options: {
          ...prev?.embedded_config?.embed_options,
          [key]: value,
        },
      },
    }));

    if (key === "type" && value === "pop-up") {
      setSurveyData((prev) => ({
        ...prev,
        embedded_config: {
          ...prev?.embedded_config,
          embed_options: {
            ...prev?.embedded_config?.embed_options,
            embed_position: "center",
          },
        },
      }));
    }

    if (key === "type" && value === "button") {
      // To set default button style depending on embedded options api reponse
      if (_.isEmpty(surveyData?.embedded_config?.button_style)) {
        Object?.keys(embeddedOptions?.button_style)?.forEach((key) => {
          setSurveyData((prev) => ({
            ...prev,
            embedded_config: {
              ...prev?.embedded_config,
              button_style: {
                ...prev?.embedded_config?.button_style,
                [key]: embeddedOptions?.button_style?.[key]?.["default_value"],
              },
            },
          }));
        });
      }

      // To avoid error when embed_position is top-center, center or bottom-center with button type
      if (
        ["top-center", "center", "bottom-center"]?.includes(
          selectedOptions?.embed_position,
        )
      ) {
        setSurveyData((prev) => ({
          ...prev,
          embedded_config: {
            ...prev?.embedded_config,
            embed_options: {
              ...prev?.embedded_config?.embed_options,
              embed_position: "middle-right",
            },
          },
        }));
      }
    }
  };

  return (
    <Box className="display-settings-options-accordion-content">
      <Box className="embed-type-wrapper">
        <Typography className="display-settings-options-subtitle">
          {CheckValueLocale("display_settings_embed_type", "", {}, intl)}
        </Typography>
        <Box className="embed-type-options-wrapper">
          {(
            embeddedOptions?.embed_options?.type?.values?.sort(
              (a, b) => a?.id - b?.id,
            ) || []
          )?.map((item) => (
            <Box
              key={item?.id}
              className={`embed-type-option ${selectedOptions?.type === item?.value ? "active" : ""}`}
              onClick={() => handleChangeEmbedOptions("type", item?.value)}
            >
              <Box className="embed-type-option-img-wrapper">
                <img
                  src={item?.value === "button" ? buttonImg : popupImg}
                  alt=""
                />
              </Box>
              <Typography>
                {CheckValueLocale(
                  `display_settings_embed_type_${item?.value}`,
                  "",
                  {},
                  intl,
                )}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>

      <Box className="embed-size-wrapper">
        <Typography className="display-settings-options-subtitle">
          {CheckValueLocale("display_settings_size", "", {}, intl)}
        </Typography>
        <Box className="embed-size-options-wrapper">
          {(
            embeddedOptions?.embed_options?.embed_size?.values?.sort(
              (a, b) => a?.id - b?.id,
            ) || []
          )?.map((item) => (
            <Box
              key={item?.id}
              className={`embed-size-option ${selectedOptions?.embed_size === item?.value ? "active" : ""}`}
              onClick={() =>
                handleChangeEmbedOptions("embed_size", item?.value)
              }
            >
              <Typography>
                {CheckValueLocale(
                  `display_settings_size_${item?.value}`,
                  "",
                  {},
                  intl,
                )}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>

      <Box className="embed-position-wrapper">
        <Typography className="display-settings-options-subtitle">
          {CheckValueLocale("display_settings_postion", "", {}, intl)}
        </Typography>

        <Box className="embed-position-options-wrapper">
          {(
            embeddedOptions?.embed_options?.embed_position?.values?.sort(
              (a, b) => a?.id - b?.id,
            ) || []
          )?.map((item) => {
            const activeItem = selectedOptions?.embed_position === item?.value;

            const disabledItem =
              selectedOptions?.type === "button" &&
              [1, 4, 7]?.includes(item?.id);

            return (
              <Box
                key={item}
                className={`embed-position-option ${activeItem ? "active" : disabledItem ? "disabled" : ""}`}
                onClick={() =>
                  !disabledItem &&
                  handleChangeEmbedOptions("embed_position", item?.value)
                }
              >
                {activeItem ? (
                  <FontAwesomeIcon icon={faCheck} fixedWidth />
                ) : null}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default EmbedOptions;
