import React, { useContext, useState } from "react";
import { Box, SwipeableDrawer, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/pro-solid-svg-icons";
import { ReportListContext } from "pages/reports/context/reportContext";
import LucButton from "shared/lucButton/lucButton";
import ReportSelectInput from "pages/reports/components/reportSelectInput";
import "./style.scss";

const ReportsListFilters = () => {
  const {
    appliedFilters,
    setAppliedFilters,
    handleApplyFilters,
    availableProducts,
    availableMonitorTypes,
    availableDatasources,
    availableUsers,
    availableProductsLoading,
    availableMonitorTypesLoading,
    availableDatasourcesLoading,
    availableUsersLoading,
  } = useContext(ReportListContext);

  const intl = useIntl();

  const [openFilters, setOpenFilters] = useState(false);

  const handleClearFilters = () => {
    setAppliedFilters({
      product_ids: [],
      monitor_type_ids: [],
      data_source_ids: [],
      frequencies: [],
      user_ids: [],
      statuses: [],
    });
  };

  const dummyFiltersInputs = [
    {
      id: "reports-list-filters-platform-select",
      label: "platform",
      name: "product_ids",
      value: appliedFilters?.product_ids || [],
      onChange: (e) =>
        setAppliedFilters({
          ...appliedFilters,
          product_ids: e.target.value || [],
        }),
      options: availableProducts?.filteredData?.map((item) => ({
        value: item?.id,
        label: item?.name,
      })),
      multiple: true,
    },
    {
      id: "reports-list-filters-monitor-type-select",
      label: "monitor_type",
      name: "monitor_type_ids",
      value: appliedFilters?.monitor_type_ids || [],
      onChange: (e) =>
        setAppliedFilters({
          ...appliedFilters,
          monitor_type_ids: e.target.value || [],
        }),
      options: availableMonitorTypes?.filteredData?.map((item) => ({
        value: item?.monitor_type_id,
        label: item?.monitor_type_name,
      })),
      multiple: true,
    },
    {
      id: "reports-list-filters-data-source-select",
      label: "data_source",
      name: "data_source_ids",
      value: appliedFilters?.data_source_ids || [],
      onChange: (e) =>
        setAppliedFilters({
          ...appliedFilters,
          data_source_ids: e.target.value || [],
        }),
      options: availableDatasources?.map((item) => ({
        value: item?.id,
        label: item?.source?.toLowerCase(),
      })),
      multiple: true,
    },
    {
      id: "reports-list-filters-frequency-select",
      label: "frequency",
      name: "frequencies",
      value: appliedFilters?.frequencies || [],
      onChange: (e) =>
        setAppliedFilters({
          ...appliedFilters,
          frequencies: e.target.value || [],
        }),
      options: [
        { value: 0, label: "daily" },
        { value: 1, label: "weekly" },
        { value: 2, label: "monthly" },
      ],
      multiple: true,
    },
    {
      id: "reports-list-filters-creator-select",
      label: "creator",
      name: "user_ids",
      value: appliedFilters?.user_ids || [],
      onChange: (e) =>
        setAppliedFilters({
          ...appliedFilters,
          user_ids: e.target.value || [],
        }),
      options: availableUsers?.map((item) => ({
        value: item?.id,
        label: item?.name,
      })),
      multiple: true,
      showChips: true,
      disableTranslation: true,
    },
    {
      id: "reports-list-filters-status-select",
      label: "status",
      name: "statuses",
      value: appliedFilters?.statuses || [],
      onChange: (e) =>
        setAppliedFilters({
          ...appliedFilters,
          statuses: e.target.value || [],
        }),
      options: [
        { value: "paused", label: "paused" },
        { value: "active", label: "active" },
      ],
      multiple: true,
      showChips: true,
    },
  ];

  return (
    <>
      <LucButton
        id="report-create-new-filters-btn"
        variant="outline"
        size="small"
        startIcon={<FontAwesomeIcon icon={faFilter} />}
        loading={
          availableProductsLoading ||
          availableMonitorTypesLoading ||
          availableDatasourcesLoading ||
          availableUsersLoading
        }
        onClick={() => setOpenFilters(true)}
      >
        {CheckValueLocale("filters", "", {}, intl)}
      </LucButton>

      <SwipeableDrawer
        open={openFilters}
        onClose={() => setOpenFilters(false)}
        anchor={intl?.locale === "ar" ? "left" : "right"}
        id="reports-list-filters-swipeable-container"
      >
        <Box role="presentation">
          <Box className="reports-filters-header-container">
            <Box className="reports-list-filter-swipeable-header-title">
              <FontAwesomeIcon icon={faFilter} />
              <Typography>
                {CheckValueLocale("filter", "", {}, intl)}
              </Typography>
            </Box>
            <LucButton
              id="report-list-hide-btn"
              type="secondary"
              variant="outline"
              size="small"
              onClick={() => setOpenFilters(false)}
            >
              {CheckValueLocale("hide", "", {}, intl)}
            </LucButton>
          </Box>
          <Box className="reports-filters-swipeable-content-container">
            {dummyFiltersInputs?.map((input) => {
              return (
                <ReportSelectInput
                  key={input?.label}
                  id={input?.id}
                  className="reports-list-page-filter-select-input"
                  name={input?.name}
                  label={input?.label}
                  labelClassName="reports-list-page-filter-select-input-label"
                  value={input?.value || []}
                  onChange={input?.onChange}
                  options={input?.options}
                  multiple={input?.multiple}
                  showChips={input?.showChips}
                  disableTranslation={input?.disableTranslation}
                />
              );
            })}
          </Box>
          <Box className="reports-list-filters-swipeable-footer-container">
            <LucButton
              id="reports-list-filter-apply-btn"
              size="small"
              fullWidth
              onClick={(e) => handleApplyFilters(e, 1)}
            >
              {CheckValueLocale("apply", "", {}, intl)}
            </LucButton>
            <LucButton
              id="reports-list-filter-clear-btn"
              type="secondary"
              variant="flat"
              size="small"
              fullWidth
              onClick={handleClearFilters}
            >
              {CheckValueLocale("clear", "", {}, intl)}
            </LucButton>
          </Box>
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export default ReportsListFilters;
