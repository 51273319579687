import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers/index.js";
import DateRangePicker from "../../../shared/dateRangePicker/dateRangePicker.js";
import "./userActivitesHeader.scss";
import {
  sidebarAllTimeOptions,
  sidebarOptionsMapper,
} from "shared/customDateRangePicker/config/sidebarOptions.js";
import CustomDateRangePicker from "shared/customDateRangePicker/customDateRangePicker.js";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo } from "react";
import { useMemoizedFn } from "hooks/useMemoizedFn.js";

const UserActivitesHeader = (props) => {
  const intl = useIntl();

  useEffect(() => {
    props?.applyFilter(props?.startDate, props?.endDate);
  }, [intl.locale]);

  const newDate = useMemo(() => {
    return new Date();
  }, []);
  const buttonProps = useMemo(() => {
    return {
      type: "secondary",
      variant: "outline",
      size: "large",
      fixedWidth: "221px",
      startIcon: (
        <FontAwesomeIcon icon={faCalendar} className="custom-input-icon" />
      ),
    };
  }, []);
  const applyFilterMemo = useMemoizedFn(props?.applyFilter);

  return (
    <Box container className="activites-header">
      <Box>
        <Typography className="activites-title" variant="h6">
          {CheckValueLocale(props.title, "", {}, intl)}
        </Typography>
      </Box>

      <Box className="audience-date">
        <CustomDateRangePicker
          initialSidebarLabel={sidebarOptionsMapper.alltime}
          startDate={props?.pickerStartDate}
          endDate={props?.pickerEndDate}
          applyFilter={applyFilterMemo}
          maxDate={newDate}
          dateRangePickerParamsRef={props?.dateRangePickerParamsRef}
          allTimeStartDate={props?.allTimeStartDate}
          sidebarOptions={sidebarAllTimeOptions}
          customStyles={buttonProps}
        />
      </Box>
    </Box>
  );
};

export default UserActivitesHeader;
