import { Box } from "@mui/material";
import NoDataDetails from "./noDetails/noDataDetails";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import PostsAndReplies from "./postsAndReplies/postsAndReplies";
import EngagementHeader from "./engagementHeader/engagementHeader";
import Chat from "./chatDm/chat";
import EngagementsController from "services/controllers/engagementsController";
import { connectWithRabbitMQ } from "services/controllers/rabbitMQResponce";
import { CheckValueLocale, deleteCookie, isEmptyObj } from "utils/helpers";
import DetailsSideBar from "../detailsSideBar/detailsSideBar";
import SnackBar from "components/snackBar";
import DetailsSideBarReply from "../detailsSideBar/detailsSideBarReply";
import AiSuggestions from "../aiSuggestions/aiSuggestions";
import languagesData from "./engagementTranslation/languages.json";
import mqttRabbitMQResponce from "services/controllers/mqttRabbitMQResponce";
import SlaDetailsSideBar from "./../detailsSideBar/sla/slaDetailsSideBar";
import { useDispatch, useSelector } from "react-redux";
import {
  slaData,
  clearSla,
  cardsAssignAndStateData,
} from "utils/redux/features/Engagements/EngagementsSlice.js";
import io from "socket.io-client";
import Services from "services/api/Services.js";

import { useIntl } from "react-intl";
import {
  engagementStatusMapper,
  engagementTabsActions,
  useEngagementTabs,
} from "pages/engagements/providers/engagementsTabsProvider";
import { jwtDecode } from "jwt-decode";
import LoginController from "services/controllers/loginController";
import { useNavigate } from "react-router-dom";
import { resetChatMessages } from "utils/redux/features/AiAgentChat/AiAgentChatSlice";
import { useRefreshToken } from "pages/loginPage/refreshToken/hooks/useRefreshToken";
import NotesSidebar from "../NotesSidebar/NotesSidebar";
import {
  historicalRequestSnackbars,
  postTypes,
  publicThreadInteractionDataHandler,
} from "./postsAndReplies/postAndRepliesUtils";
import * as Sentry from "@sentry/react";

const EngagementsDetails = ({
  setRefresh,
  setTabValue,
  setComplete,
  setTotalEngagements,
  dates,
  openSidebar,
  setOpenSidebar,
  checkAiSuggestions,
  setCheckAiSuggestions,
  isSLASideBarOpen,
  openSidebarToAssignTeam,
  setOpenSidebarToAssignTeam,
  aiAgentList,
  setOpenSnackBarAiAgent,
  setSnackBarDataAiAgent,
  setInjectedMessage,
  aiAgentId,
}) => {
  const intl = useIntl();
  const {
    cardState,
    setCardState,
    activeEngagement: selectedCard,
    handleEngagementTabs,
  } = useEngagementTabs();
  const mqttConnectionClosed = React.useRef(false);
  const clientMQTT = React.useRef();
  const [response, setResponse] = useState({});
  const [profileSideBarData, setProfileSideBarData] = useState({});
  const [preLoader, setPreLoader] = useState("");
  const [itemData, setItemData] = useState({});
  const [fetchData, setFetchData] = useState(false);
  const [fetchLogsData, setFetchLogsData] = useState(false);
  const [fetchProfileData, setFetchProfileData] = useState(true);
  const [headerData, setHeaderData] = useState();
  const [statusChanged, setStatusChanged] = useState(false);
  const [savedSuggTab, setSavedSuggTab] = useState(false);
  const [savedSuggTabDate, setSavedSuggTabDate] = useState(0);
  const [isCashedReplays, setIsCashedReplays] = useState(false);
  const [aiRepsTab, setAiRepsTab] = useState(false);
  const [aiReplaysRefresh, setAiReplaysRefresh] = useState(false);
  const [missingReplyLoadingType, setMissingReplyLoadingType] = useState(null);

  const [notesCount, setNotesCount] = useState("");
  const [page, setPage] = useState(1);
  const [text, setText] = useState("");
  // replyBoxInputTextRef will be passed to the reply box component and reply box component will register some functions to it
  // so that we can now some information about the reply box from the parent component. e.g. where is the cursor in the reply box, if we want to focus on the reply box, ...etc
  const replyBoxInputTextRef = React.useRef({});
  const [openDetailsSideBarReplay, setOpenDetailsSideBarReplay] =
    useState(false);
  const [selectedValue, setSelectedValue] = useState({});
  const [chatData, setChatData] = useState([]);
  const [selectedCardIteractionID, setSelectedCardIteractionID] = useState(0);
  const [translationActive, setTranslationActive] = useState(false);
  const [translationSource, setTranslationSource] = useState("");
  const [translationTarget, setTranslationTarget] = useState("");
  const [stats, setStats] = useState({
    open: false,
    title: "",
    body: "",
    severity: "",
  });
  const [snackBarData, setSnackBarData] = useState({
    open: false,
    title: "",
    body: "",
    severity: "",
  });
  const [openDetailsSideBar, setOpenDetailsSideBar] = useState({
    open: false,
    type: "",
  });
  const [openSlaSideBar, setOpenSlaSideBar] = useState(false);
  const [openNotesSidebar, setOpenNotesSidebar] = useState(false);
  const [hideNote, setHideNote] = useState(false);
  const dispatch = useDispatch();
  const reduxSlaData = useSelector((state) => state?.engagements?.sla);
  const [isLoadingDmHistory, setIsLoadingDmHistory] = useState(false);
  const languagesArraySource = ["auto_detect", ...languagesData];
  const languagesArrayTarget = [...languagesData];
  const [slaLogs, setSlaLogs] = useState({});
  const [showAllIcon, setShowAllIcon] = useState(false);
  const slaKeyValue = `${selectedCard?.tweet_id ? selectedCard?.tweet_id : selectedCard?.id}-${selectedCard?.tracker_id}`;
  const reduxCardsAssignAndState = useSelector(
    (state) => state?.engagements?.cardsAssignAndState,
  );
  const callSingleinteraction = (pageNumber = page, options = {}) => {
    if (!isEmptyObj(selectedCard)) {
      // don't show the loader
      if (pageNumber == 1) {
        setFetchData(true);
        setFetchLogsData(true);
        setFetchProfileData(true);
      }
      let signleInteractionApi = EngagementsController.getSingleInteraction;
      let publicTwitterAdditionalProps = {};
      if (
        selectedCard?.data_source?.toLowerCase?.() === "twitter" &&
        !selectedCard?.isDm
      ) {
        setMissingReplyLoadingType(postTypes.reply);
        publicTwitterAdditionalProps = {
          root_id: selectedCard?.root_id,
          interaction_source_parent_id:
            options?.lastReplyParentId ||
            selectedCard?.interaction_source_parent_id,
          interaction_id: options?.tweet_id || selectedCard?.tweet_id,
          page_number: options?.page_number || 1,
        };
        // in case of twitter public we will use the the single interaction v5
        signleInteractionApi = EngagementsController.getSingleInteractionV5;
      }

      let queryData = {
        parent_id: selectedCard?.tweet_id || selectedCard?.id,
        product_id: window.localStorage.getItem("engagementsProductId"),
        monitor_id: selectedCard?.tracker_id,
        interaction_id: selectedCard?.tweet_id || selectedCard?.id,
        data_source_name: selectedCard?.data_source?.toUpperCase(),
        page_number: selectedCard?.isDm ? pageNumber : 1,
        user_name: selectedCard?.screen_name
          ? selectedCard?.screen_name
          : selectedCard?.user_name,
        start_date: dates?.startDate,
        routing_id:
          selectedCard?.assigning_info?.routing_id ||
          selectedCard?.sla_info?.routing_id,
        sla_id:
          selectedCard?.sla_info?.sla_id ||
          selectedCard?.assigning_info?.sla_id,
        interaction_created_at: selectedCard?.created_at,
        ...publicTwitterAdditionalProps,
      };
      // we will handle the pagination for all datasources dm only for now
      if (pageNumber > 1) {
        queryData.total_interactions =
          itemData?.total_interactions || undefined;
        queryData.last_message_id = chatData?.length
          ? chatData[0]?.message_id
          : undefined;
        queryData.last_message_created_at = chatData?.length
          ? chatData[0]?.message_created_at
          : undefined;
        queryData.last_created_at = chatData?.length
          ? chatData[0]?.created_at
          : undefined;
      }
      signleInteractionApi({ ...queryData }).then((data) => {
        if (data?.data?.password && !isEmptyObj(data?.data)) {
          // maybe need it later
          // setSavedSuggTab(data?.data?.has_saved_responses);
          // setSavedSuggTabDate(data?.data?.cached_responses_date);
          // setAiRepsTab(data?.data?.has_cached_answers );
          setHeaderData(data?.data);
          setPreLoader(data?.data?.monitor_name);
          setResponse({});
          // connectWithRabbitMQ(
          //   mqttConnectionClosed,
          //   clientMQTT,
          //   data?.data,
          //   setResponse
          // );
          mqttRabbitMQResponce(clientMQTT, data?.data, setResponse);
        } else {
          mqttConnectionClosed.current = true;
          // window.location.reload();
        }
      });
    } else if (pageNumber > 1 || !isEmptyObj(itemData) || fetchData) {
      setPage(1);
      setFetchData(false);
      setItemData({});
    }
  };

  const getHistoricalRequestInteraction = (options = {}) => {
    const isRoot = options?.isRoot || false;
    setMissingReplyLoadingType(isRoot ? postTypes.root : postTypes.reply);
    const queryData = {
      parent_id: selectedCard?.tweet_id || selectedCard?.id,
      product_id: window.localStorage.getItem("engagementsProductId"),
      monitor_id: selectedCard?.tracker_id,
      interaction_id: options?.lastReplyParentId,
      data_source_name: selectedCard?.data_source?.toUpperCase(),
      page_number: 1, // we will always get the frist page
      user_name: selectedCard?.screen_name,
      routing_id:
        selectedCard?.assigning_info?.routing_id ||
        selectedCard?.sla_info?.routing_id,
      sla_id:
        selectedCard?.sla_info?.sla_id || selectedCard?.assigning_info?.sla_id,
      root_id: selectedCard?.root_id,
      interaction_source_parent_id: selectedCard?.interaction_source_parent_id,
      historical_root: isRoot,
      historical_thread: !isRoot,
      full_thread: itemData?.full_thread,
      has_next_level: itemData?.has_next_level,
      root_request_historical: itemData?.root_request_historical,
      thread_request_historical: itemData?.thread_request_historical,
      engagement_id: itemData?.system_replies?.engagement_id || null,
    };
    EngagementsController.getHistoricalRequestInteraction({
      ...queryData,
    }).then((data) => {
      if (data?.data?.password && !isEmptyObj(data?.data)) {
        setResponse({});
        mqttRabbitMQResponce(clientMQTT, data?.data, setResponse);
      } else if (data?.errorMsg) {
        // handling errors cases
        const snackBarData =
          historicalRequestSnackbars[data?.errorMsg?.response?.data?.error] ||
          historicalRequestSnackbars.SOMETHING_WENT_WRONG;
        setSnackBarData({
          open: true,
          title: snackBarData?.title,
          body: snackBarData?.message,
          severity: "error",
        });
        setMissingReplyLoadingType(null);
      }
    });
  };

  const getInteractionDmHistory = () => {
    setIsLoadingDmHistory(true);
    const queryData = {
      parent_id:
        itemData?.tweet_id || itemData?.id || itemData?.conversation_id,
      monitor_id: itemData?.tracker_id,
      client_id: itemData?.client_id,
      agent_id: itemData?.agent_id || undefined,
      product_id: window.localStorage.getItem("engagementsProductId"),
      data_source_name: itemData?.data_source?.toUpperCase(),
      last_message_id: chatData?.length ? chatData[0]?.message_id : undefined,
      last_message_created_at: chatData?.length
        ? chatData[0]?.message_created_at
        : undefined,
      last_created_at: chatData?.length ? chatData[0]?.created_at : undefined,
      interaction_created_at: selectedCard?.created_at,
    };
    EngagementsController.getInteractionDmHistory(queryData).then((data) => {
      if (data?.data?.password && !isEmptyObj(data?.data)) {
        setResponse({});

        mqttRabbitMQResponce(clientMQTT, data?.data, setResponse);
      } else {
        setIsLoadingDmHistory(false);
      }
    });
  };
  // reset all states data on change selected card
  useEffect(() => {
    setPage(1);
    setChatData([]);
    setSelectedCardIteractionID(0);
    setItemData({});
    setText("");
    setSelectedValue({});
    setOpenDetailsSideBar({
      open: false,
      type: "",
    });
    setOpenDetailsSideBarReplay(false);
    setOpenSlaSideBar(false);
    setOpenSidebar(false);
    setTranslationSource("");
    setTranslationTarget("");
    setTranslationActive(false);
    setSlaLogs({});
    callSingleinteraction(1);
    setIsLoadingDmHistory(false);
    setOpenNotesSidebar(false);
    setMissingReplyLoadingType(null);
    setSnackBarData({ ...snackBarData, open: false });
  }, [selectedCard]);

  const [retry, setRetry] = useState(0);
  // Use the refreshToken mutation from your custom hook
  const { mutate: refreshToken, isFetching } = useRefreshToken();
  const navigate = useNavigate();
  //connect to socket
  ///engagements
  const socket = useMemo(() => {
    const authToken = localStorage.getItem("user_token");
    const isProd = window.location.host === "cxm.lucidya.com";

    const logDebug = (...args) => {
      if (!isProd) console.log(...args);
    };

    if (!authToken) {
      logDebug("Socket initialization failed: No auth token found");
      return null;
    }

    logDebug("Initializing socket connection...");
    return io(Services.socketUrl + "engagements", {
      path: "/ws/",
      // Start with websocket but with quick fallback to polling if needed
      transports: ["websocket", "polling"],
      reconnection: true,
      reconnectionAttempts: 10, // Increased for slow networks
      reconnectionDelay: 1000, // Start with 1s delay
      reconnectionDelayMax: 10000, // Max 10s delay for very slow networks
      timeout: 20000, // Increased timeout
      auth: {
        "luc-authorization": authToken,
      },
      autoConnect: true,
      withCredentials: true,
      forceNew: true,
      // Add these for better slow network handling
      pingInterval: 10000, // More frequent ping in slow networks
      pingTimeout: 15000, // Longer ping timeout
      upgradeTimeout: 10000, // More time for transport upgrades
      rememberUpgrade: false, // Don't remember upgrades in slow networks
    });
  }, [retry]);

  //Use this function to sign out the user
  const handleSignOut = () => {
    let currentLang = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : window.navigator.language.split("-")[0];
    LoginController.userlogout().then((data) => {
      dispatch(resetChatMessages());
      localStorage.clear();
      deleteCookie("email");
      window.localStorage.setItem("lang", currentLang);
      window.location.href = "/login";
    });
  };
  const autoSendToken = useCallback(() => {
    if (isFetching) return; // Prevent multiple API calls if already fetching

    refreshToken(
      {},
      {
        onSuccess: (data) => {
          const token = data?.data?.data?.["luc-authorization"];
          if (token) {
            localStorage.setItem("user_token", token);
            //  reload the page to reconnect the socket
            setRetry(4);
          } else {
            handleSignOut();
          }
        },
        onError: () => {
          if (navigator.onLine) {
            handleSignOut();
          }
        },
      },
    );
  }, [isFetching, navigate, refreshToken]);

  useEffect(() => {
    const isProd = window.location.host === "cxm.lucidya.com";
    const logToSentry = (errMSg, errorType) => {
      if (isProd) {
        Sentry.withScope((scope) => {
          scope.setTag("socket", "engagements");
          scope.setUser({
            id: localStorage.getItem("user_id"),
            email: localStorage.getItem("email"),
            company_id: localStorage.getItem("company_id"),
          });

          const error = new Error(errMSg);
          Sentry.captureException(error, {
            extra: {
              errorDetails: {
                customMessage: errMSg,
                timestamp: new Date().toISOString(),
                errorType,
                borswer: navigator?.userAgent,
              },
            },
          });
        });
      }
    };
    const logDebug = (...args) => {
      if (!isProd) console.log(...args);
    };

    if (retry === 3) {
      logDebug("Maximum retries reached, attempting token refresh...");
      autoSendToken();
      return;
    }

    if (!socket) {
      logDebug("Socket not initialized");
      return;
    }

    const handleConnect = () => {
      logDebug(`Socket connected successfully (Attempt ${retry + 1})`);
      if (retry !== 0) setRetry(0);
    };

    const handleConnectError = (error) => {
      logDebug("Socket connection error:", error.message);
      const message =
        typeof error?.message === "string"
          ? error.message
          : "Socket connection error";
      logToSentry(message, "connect_error");
      if (error.message?.includes("authentication")) {
        autoSendToken();
        return;
      }

      // More aggressive reconnection for slow networks
      if (retry < 3 && navigator.onLine) {
        // Exponential backoff with initial shorter delay
        const reconnectDelay = Math.min(1000 * Math.pow(1.5, retry), 5000);
        setTimeout(() => {
          if (socket && !socket.connected) {
            // Try to reconnect with current transport
            socket.connect();
            setRetry((prev) => prev + 1);
          }
        }, reconnectDelay);
      }
    };

    const handleDisconnect = (reason) => {
      logDebug("Socket disconnected:", reason);
      const reasonMessage =
        typeof reason === "string" ? reason : "Socket disconnected";
      logToSentry(reasonMessage, "disconnect");
      if (
        reason === "io client disconnect" ||
        reason === "io server disconnect"
      ) {
        return;
      }

      // Quick reconnection attempt for slow networks
      if (retry < 3 && navigator.onLine) {
        const reconnectDelay = Math.min(1000 * Math.pow(1.5, retry), 5000);
        setTimeout(() => {
          if (socket && !socket.connected) {
            socket.connect();
            setRetry((prev) => prev + 1);
          }
        }, reconnectDelay);
      }
    };

    // Handle transport errors specifically
    const handleTransportError = (error) => {
      logDebug("Transport error - attempting fallback");
      let errorMsg = "";
      switch (typeof error) {
        case "string":
          errorMsg = error;
          break;
        case "object":
          errorMsg = error?.message || "Transport error";
          break;
        default:
          errorMsg = "Transport error";
          break;
      }
      logToSentry(errorMsg, "transport_error");
      if (socket.io.engine.transport.name === "websocket") {
        // Force fallback to polling
        socket.io.engine.transport.name = "polling";
        socket.connect();
      }
    };

    socket.on("connect", handleConnect);
    socket.on("connect_error", handleConnectError);
    socket.on("disconnect", handleDisconnect);
    socket.on("transport_error", handleTransportError);

    // Monitor connection quality
    const connectionMonitor = setInterval(() => {
      if (socket.connected) {
        // Send a test ping
        const startTime = Date.now();
        socket.emit("ping", () => {
          const latency = Date.now() - startTime;
          if (
            latency > 5000 &&
            socket.io.engine.transport.name === "websocket"
          ) {
            // High latency, consider falling back to polling
            logDebug("High latency detected, considering transport fallback");
            socket.io.engine.transport.name = "polling";
          }
        });
      }
    }, 10000);

    return () => {
      clearInterval(connectionMonitor);
      socket.off("connect", handleConnect);
      socket.off("connect_error", handleConnectError);
      socket.off("disconnect", handleDisconnect);
      socket.off("transport_error", handleTransportError);
      socket.disconnect();
    };
  }, [socket, retry, autoSendToken]);

  // Add network status monitoring
  useEffect(() => {
    if (!socket) return;

    const handleOnline = () => {
      if (!socket.connected) {
        socket.connect();
      }
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", () => {
      const isProd = window.location.host === "cxm.lucidya.com";
      if (!isProd) console.log("Network connection lost");
    });

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", () => {});
    };
  }, [socket]);

  // handle open ai Suggestions side bar and close others
  const handelOpenSidebar = () => {
    setCheckAiSuggestions(false);
    setOpenDetailsSideBarReplay(false);
    setOpenDetailsSideBar({
      open: false,
      type: "",
    });
    setOpenSlaSideBar(false);
    setOpenNotesSidebar(false);
    setOpenSidebar(true);
  };

  //handle Toggle sla sideBar
  const handleToggleSlaSideBar = () => {
    setCheckAiSuggestions(false);
    setOpenDetailsSideBarReplay(false);
    setOpenDetailsSideBar({
      open: false,
      type: "",
    });
    setOpenSidebar(false);
    setOpenNotesSidebar(false);
    setOpenSlaSideBar(!openSlaSideBar);
  };

  // handle toggle notes sidebar
  const handleToggleNotesSidebar = () => {
    setCheckAiSuggestions(false);
    setOpenDetailsSideBarReplay(false);
    setOpenDetailsSideBar({
      open: false,
      type: "",
    });
    setOpenSidebar(false);
    setOpenSlaSideBar(false);
    setOpenNotesSidebar(!openNotesSidebar);
  };

  // call single interaction for selected card paginaition at DM
  useEffect(() => {
    if (page > 1) {
      callSingleinteraction();
    }
  }, [page]);
  useEffect(() => {
    if (!isEmptyObj(response) && !isEmptyObj(selectedCard)) {
      switch (response.eventName) {
        case "Twitterprivate__EngagementsSingleInteractionDMHistoryPage":
        case "Facebookprivate__EngagementsSingleInteractionDMHistoryPage":
        case "Instagramprivate__EngagementsSingleInteractionDMHistoryPage":
        case "Whatsappprivate__EngagementsSingleInteractionDMHistoryPage":
          let data = response?.eventData?.single_interaction;
          setItemData((prevData) => ({
            ...prevData,
            conversation: data?.conversation || [],
            staticUpdated: false,
          }));
          setFetchData(false);
          break;

        case "Twitter_public__EngagementsSingleInteractionPage":
        case "Instagram_public__EngagementsSingleInteractionPage":
        case "Facebook_public__EngagementsSingleInteractionPage":
        case "Facebook__EngagementsSingleInteractionPage":
        case "Instagram__EngagementsSingleInteractionPage":
        case "Twitter__EngagementsSingleInteractionPage":
        case "Instagramprivate__EngagementsSingleInteractionPage":
        case "Twitterprivate__EngagementsSingleInteractionPage":
        case "Facebookprivate__EngagementsSingleInteractionPage":
        case "Whatsappprivate__EngagementsSingleInteractionPage":
        case "Twitter_public__EngagementsSingleInteractionV5Page":
          if (
            response?.monitor_id == selectedCard?.tracker_id ||
            selectedCard?.id ==
              response?.eventData?.single_interaction?.conversation_id
          ) {
            setNotesCount(response?.eventData?.notes_count);
            // handling public interaction thread
            const eventData = response?.eventData;
            const MainReplyInThreadPost =
              eventData?.thread?.[0] || eventData?.root_interaction || null;
            // handle the case where we are supporting the thread in publich interaction
            let intercationPbulicData = eventData?.parent_single_interaction;
            // if we have a thread post we will merge the data with the main post and make the object more general
            if (MainReplyInThreadPost) {
              intercationPbulicData = publicThreadInteractionDataHandler(
                itemData,
                eventData,
                selectedCard?.tweet_id,
              );
              if (intercationPbulicData?.error) {
                const snackbarError =
                  historicalRequestSnackbars[intercationPbulicData?.error] ||
                  historicalRequestSnackbars.SOMETHING_WENT_WRONG;
                setSnackBarData({
                  open: true,
                  title: snackbarError?.title,
                  body: snackbarError?.message,
                  severity: "error",
                });
              } else if (eventData?.request_type === "HISTORICAL") {
                setSnackBarData({
                  open: true,
                  title: "full_thread_requested_data",
                  body: "full_thread_requested_data_body",
                  severity: "success",
                });
              }
            }

            let data = intercationPbulicData
              ? {
                  ...intercationPbulicData,
                  isDm: false,
                }
              : {
                  ...eventData?.single_interaction,
                  isDm: true,
                };
            setSelectedCardIteractionID(
              data?.system_replies?.interaction_id || 0,
            );
            // we don't update redux in case of twitter public interaction pagination and hitoical
            if (
              eventData?.request_type !== "HISTORICAL" &&
              eventData?.request_type !== "PAGINATION"
            ) {
              let slaObj = { ...reduxSlaData };
              slaObj[
                `${selectedCard?.tweet_id ? selectedCard?.tweet_id : selectedCard?.id}-${selectedCard?.tracker_id}`
              ] = {
                ...slaObj[
                  `${selectedCard?.tweet_id ? selectedCard?.tweet_id : selectedCard?.id}-${selectedCard?.tracker_id}`
                ],
                slaId: data?.sla_info?.sla_id || data?.assigning_info?.sla_id,
                userId: data?.assigning_info?.user_id,
                teamId: data?.assigning_info?.team_id,
              };
              dispatch(slaData(slaObj));
            }
            let statusItemCard = data?.system_replies?.status
              ? data?.system_replies?.status
              : data?.status;
            if (cardState?.opendFromCard) {
              if (cardState?.activeTab !== "all") {
                if (
                  statusItemCard == "complete" &&
                  cardState?.activeTab !== "complete"
                ) {
                  setStats({
                    ...stats,
                    open: true,
                    title: "",
                    body: CheckValueLocale("engag_to_complete", "", {}, intl),
                    severity: "error",
                  });
                  setCardState({
                    ...cardState,
                    changeStateTo: statusItemCard,
                    changeStateDone: true,
                  });
                } else if (
                  statusItemCard == "reopen" &&
                  cardState?.activeTab !== "active"
                ) {
                  setStats({
                    ...stats,
                    open: true,
                    title: "",
                    body: CheckValueLocale("engag_to_active", "", {}, intl),
                    severity: "error",
                  });
                  setCardState({
                    ...cardState,
                    changeStateTo: statusItemCard,
                    changeStateDone: true,
                  });
                }
                setTimeout(() => {
                  setStats({
                    ...stats,
                    open: false,
                  });
                }, 7000);
              } else {
                if (
                  (statusItemCard == "complete" &&
                    cardState?.cardInitialState !== "complete") ||
                  (statusItemCard == "reopen" &&
                    cardState?.cardInitialState !== "reopen")
                ) {
                  setCardState({
                    ...cardState,
                    changeStateTo: statusItemCard,
                    changeStateDone: true,
                    notClose: true,
                  });
                }
              }
            }
            handleEngagementTabs(engagementTabsActions.update_current_tab, {
              engagementStatus: engagementStatusMapper[statusItemCard] || "new",
            });
            setItemData({
              ...data,
              data_source: selectedCard?.data_source?.toUpperCase(),
              user_mention: selectedCard?.isDm
                ? selectedCard?.user_name
                : selectedCard?.screen_name,
              // pages and total interactions will only be received in the first page
              pages: response?.eventData?.pages || itemData?.pages,
              // total_interactions indicate to the number of message in this conversation, will only be received in the first page
              total_interactions:
                response?.eventData?.total_messages_count ||
                itemData?.total_interactions,
              // has_multiple_conversations indicates if the conversation has multiple conversations which we can show the full dm history
              has_multiple_conversations:
                response?.eventData?.has_multiple_conversations ||
                itemData?.has_multiple_conversations,
            });
            dispatch(
              cardsAssignAndStateData({
                ...reduxCardsAssignAndState,
                [`${selectedCard?.tweet_id ? selectedCard?.tweet_id : selectedCard?.id}-${selectedCard?.tracker_id}`]:
                  {
                    userId: data?.assigning_info?.user_id,
                    teamId: data?.assigning_info?.team_id,
                    statusItemCard: statusItemCard,
                  },
              }),
            );
            setFetchData(false);
            setMissingReplyLoadingType(null);
          }
          break;
        case "Twitter_public__EngagementsProfileDataPage":
        case "Instagram_public__EngagementsProfileDataPage":
        case "Facebook_public__EngagementsProfileDataPage":
        case "Instagramprivate__EngagementsProfileDataPage":
        case "Twitterprivate__EngagementsProfileDataPage":
        case "Facebookprivate__EngagementsProfileDataPage":
        case "Whatsappprivate__EngagementsProfileDataPage":
          setProfileSideBarData(response?.eventData);
          setFetchProfileData(false);
          break;

        case "Twitter_public__EngagementsRoutingsLogsPage":
        case "Instagram_public__EngagementsRoutingsLogsPage":
        case "Facebook_public__EngagementsRoutingsLogsPage":
        case "Facebook__EngagementsRoutingsLogsPage":
        case "Instagram__EngagementsRoutingsLogsPage":
        case "Twitter__EngagementsRoutingsLogsPage":
        case "Instagramprivate__EngagementsRoutingsLogsPage":
        case "Twitterprivate__EngagementsRoutingsLogsPage":
        case "Facebookprivate__EngagementsRoutingsLogsPage":
        case "Whatsappprivate__EngagementsRoutingsLogsPage":
          if (page == 1) {
            setSlaLogs(response?.eventData?.engagements_routings_logs);
            let data = response?.eventData?.engagements_routings_logs?.sla_data;
            let slaObj = { ...reduxSlaData };
            slaObj[
              `${selectedCard?.tweet_id ? selectedCard?.tweet_id : selectedCard?.id}-${selectedCard?.tracker_id}`
            ] = {
              ...slaObj[
                `${selectedCard?.tweet_id ? selectedCard?.tweet_id : selectedCard?.id}-${selectedCard?.tracker_id}`
              ],
              frtSec: data?.first_response_time?.time_value,
              frtDate: data?.first_response_time?.created_at,
              ttcSec: data?.time_to_complete?.time_value,
              ttcCompletedDate: data?.time_to_complete?.created_at,
              nrtList: data?.next_response_time?.length
                ? [...data?.next_response_time]
                : [],
              tutSec: data?.unassigned_response_time?.time_value,
              tutDate: data?.unassigned_response_time?.created_at,
            };
            dispatch(slaData(slaObj));
            setFetchLogsData(false);
          }
          break;
        default:
          break;
      }
    }
  }, [response]);

  //handle show more from profile sideBar
  const showTotalEngagementsHandler = () => {
    setTotalEngagements({
      user_name:
        selectedCard?.data_source === "twitter" && !selectedCard?.isDm
          ? selectedCard?.screen_name
          : selectedCard?.user_name,
      data_sources: selectedCard?.data_source?.toUpperCase(),
    });
    setStats({
      ...stats,
      open: true,
      body: CheckValueLocale("show_engagements_snackBar", "", {}, intl),
      severity: "info",
    });
    setTimeout(() => {
      setStats({
        ...stats,
        open: false,
      });
    }, 7000);
  };

  const handelEngagementsDetailsPostwidth = () => {
    let engagementsDetailsPostwidth = window?.innerWidth - 336;
    if (isSLASideBarOpen) {
      engagementsDetailsPostwidth -= 175;
    }
    if (
      openDetailsSideBar?.open ||
      openDetailsSideBarReplay ||
      openSidebar ||
      openSlaSideBar
    ) {
      engagementsDetailsPostwidth -= 290;
    }

    if (engagementsDetailsPostwidth > 750) {
      setShowAllIcon(true);
    } else {
      setShowAllIcon(false);
    }
  };
  useEffect(() => {
    handelEngagementsDetailsPostwidth();
    window.addEventListener("resize", handelEngagementsDetailsPostwidth);
    return () => {
      window.removeEventListener("resize", handelEngagementsDetailsPostwidth);
    };
  }, [
    openDetailsSideBar?.open,
    openDetailsSideBarReplay,
    openSidebar,
    openSlaSideBar,
    isSLASideBarOpen,
  ]);
  useEffect(() => {
    // If any sidebar is open, hide the note text in the header
    if (
      isSLASideBarOpen &&
      ((openDetailsSideBar && openDetailsSideBar.open) ||
        openDetailsSideBarReplay ||
        openSidebar ||
        openSlaSideBar ||
        openNotesSidebar)
    ) {
      setHideNote(true);
    } else {
      setHideNote(false);
    }
  }, [
    openDetailsSideBar,
    openDetailsSideBarReplay,
    openSidebar,
    openSlaSideBar,
    openNotesSidebar,
    isSLASideBarOpen,
  ]);
  return (
    <Box className="engagements-details-wrapper">
      {(itemData?.tweet_id || itemData?.conversation_id) && !fetchData ? (
        <div className="engagements-details-box">
          <div
            className={`details-box ${
              openDetailsSideBar?.open ||
              openDetailsSideBarReplay ||
              openSidebar ||
              openNotesSidebar ||
              openSlaSideBar
                ? "open-details-box"
                : ""
            }`}
          >
            <EngagementHeader
              selectedCard={selectedCard}
              cardState={cardState}
              setCardState={setCardState}
              selectedCardIteractionID={selectedCardIteractionID}
              profileSideBarData={profileSideBarData}
              fetchProfileData={fetchProfileData}
              showTotalEngagementsHandler={showTotalEngagementsHandler}
              itemData={itemData}
              setItemData={setItemData}
              stats={stats}
              setStats={setStats}
              setTabValue={setTabValue}
              setComplete={setComplete}
              headerData={headerData}
              statusChanged={statusChanged}
              setStatusChanged={setStatusChanged}
              setRefresh={setRefresh}
              setOpenDetailsSideBar={setOpenDetailsSideBar}
              setOpenDetailsSideBarReplay={setOpenDetailsSideBarReplay}
              setOpenSidebar={setOpenSidebar}
              setOpenSlaSideBar={setOpenSlaSideBar}
              openSlaSideBar={openSlaSideBar}
              handleToggleSlaSideBar={handleToggleSlaSideBar}
              setTranslationActive={setTranslationActive}
              translationSource={translationSource}
              setTranslationSource={setTranslationSource}
              translationTarget={translationTarget}
              setTranslationTarget={setTranslationTarget}
              languagesArraySource={languagesArraySource}
              languagesArrayTarget={languagesArrayTarget}
              translationActive={translationActive}
              isLogsFetched={!fetchLogsData || page > 1}
              openSidebarToAssignTeam={openSidebarToAssignTeam}
              setOpenSidebarToAssignTeam={setOpenSidebarToAssignTeam}
              aiAgentId={aiAgentId}
              slaKeys={slaKeyValue}
              chatData={chatData}
              notesCount={notesCount}
              openNotesSidebar={openNotesSidebar}
              setOpenNotesSidebar={setOpenNotesSidebar}
              handleToggleNotesSidebar={handleToggleNotesSidebar}
              hideNote={hideNote}
              setHideNote={setHideNote}
              socket={socket}
            />
            {itemData?.isDm ? (
              <Chat
                selectedCard={itemData}
                itemSelected={selectedCard}
                setSelectedCardIteractionID={setSelectedCardIteractionID}
                headerData={headerData}
                setPage={setPage}
                page={page}
                setOpenDetailsSideBar={setOpenDetailsSideBar}
                setOpenDetailsSideBarReplay={setOpenDetailsSideBarReplay}
                setOpenSlaSideBar={setOpenSlaSideBar}
                openSlaSideBar={openSlaSideBar}
                text={text}
                setText={setText}
                replyBoxInputTextRef={replyBoxInputTextRef}
                checkAiSuggestions={checkAiSuggestions}
                setCheckAiSuggestions={setCheckAiSuggestions}
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
                chatData={chatData}
                openSidebar={openSidebar}
                setChatData={setChatData}
                setOpenSidebar={setOpenSidebar}
                handelOpenSidebar={handelOpenSidebar}
                translationActive={translationActive}
                setTranslationActive={setTranslationActive}
                translationSource={translationSource}
                setTranslationSource={setTranslationSource}
                translationTarget={translationTarget}
                setTranslationTarget={setTranslationTarget}
                stats={stats}
                setStats={setStats}
                languagesArraySource={languagesArraySource}
                languagesArrayTarget={languagesArrayTarget}
                statusChanged={statusChanged}
                setStatusChanged={setStatusChanged}
                setRefresh={setRefresh}
                profileSideBarData={profileSideBarData}
                fetchProfileData={fetchProfileData}
                showTotalEngagementsHandler={showTotalEngagementsHandler}
                openDetailsSideBarReplay={openDetailsSideBarReplay}
                savedSuggTab={savedSuggTab}
                aiRepsTab={aiRepsTab}
                savedSuggTabDate={savedSuggTabDate}
                isCashedReplays={isCashedReplays}
                setIsCashedReplays={setIsCashedReplays}
                setAiReplaysRefresh={setAiReplaysRefresh}
                showAllIcon={showAllIcon}
                isSidebarsOpened={
                  isSLASideBarOpen &&
                  (openDetailsSideBar?.open ||
                    openDetailsSideBarReplay ||
                    openSidebar ||
                    openSlaSideBar)
                }
                aiAgentList={aiAgentList}
                setOpenSnackBarAiAgent={setOpenSnackBarAiAgent}
                setSnackBarDataAiAgent={setSnackBarDataAiAgent}
                setInjectedMessage={setInjectedMessage}
                aiAgentId={aiAgentId}
                slaKey={slaKeyValue}
                isLoadingDmHistory={isLoadingDmHistory}
                getInteractionDmHistory={getInteractionDmHistory}
                setIsLoadingDmHistory={setIsLoadingDmHistory}
                socket={socket}
              />
            ) : (
              <PostsAndReplies
                selectedCard={itemData}
                setSelectedCard={setItemData}
                itemSelected={selectedCard}
                headerData={headerData}
                setOpenDetailsSideBar={setOpenDetailsSideBar}
                setSelectedCardIteractionID={setSelectedCardIteractionID}
                setOpenDetailsSideBarReplay={setOpenDetailsSideBarReplay}
                setOpenSlaSideBar={setOpenSlaSideBar}
                openSlaSideBar={openSlaSideBar}
                text={text}
                setText={setText}
                replyBoxInputTextRef={replyBoxInputTextRef}
                openSidebar={openSidebar}
                setOpenSidebar={setOpenSidebar}
                checkAiSuggestions={checkAiSuggestions}
                setCheckAiSuggestions={setCheckAiSuggestions}
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
                handelOpenSidebar={handelOpenSidebar}
                translationActive={translationActive}
                setTranslationActive={setTranslationActive}
                translationSource={translationSource}
                setTranslationSource={setTranslationSource}
                translationTarget={translationTarget}
                setTranslationTarget={setTranslationTarget}
                stats={stats}
                setStats={setStats}
                languagesArraySource={languagesArraySource}
                languagesArrayTarget={languagesArrayTarget}
                openDetailsSideBarReplay={openDetailsSideBarReplay}
                savedSuggTab={savedSuggTab}
                aiRepsTab={aiRepsTab}
                setIsCashedReplays={setIsCashedReplays}
                showAllIcon={showAllIcon}
                isSidebarsOpened={
                  isSLASideBarOpen &&
                  (openDetailsSideBar?.open ||
                    openDetailsSideBarReplay ||
                    openSidebar ||
                    openSlaSideBar)
                }
                aiAgentList={aiAgentList}
                setRefresh={setRefresh}
                setOpenSnackBarAiAgent={setOpenSnackBarAiAgent}
                setSnackBarDataAiAgent={setSnackBarDataAiAgent}
                setInjectedMessage={setInjectedMessage}
                aiAgentId={aiAgentId}
                slaKey={slaKeyValue}
                callSingleinteraction={callSingleinteraction}
                getHistoricalRequestInteraction={
                  getHistoricalRequestInteraction
                }
                missingReplyLoadingType={missingReplyLoadingType}
                socket={socket}
              />
            )}
          </div>
          {openDetailsSideBarReplay ? (
            <DetailsSideBarReply
              setOpenDetailsSideBarReplay={setOpenDetailsSideBarReplay}
              text={text}
              setText={setText}
              replyBoxInputTextRef={replyBoxInputTextRef}
            />
          ) : openDetailsSideBar?.open ? (
            <DetailsSideBar
              selectedCard={selectedCard}
              profileSideBarData={profileSideBarData}
              fetchSideBar={fetchProfileData}
              openDetailsSideBar={openDetailsSideBar}
              setOpenDetailsSideBar={setOpenDetailsSideBar}
              showTotalEngagementsHandler={showTotalEngagementsHandler}
              callSingleinteraction={callSingleinteraction}
              setStats={setStats}
            />
          ) : openSidebar ? (
            <AiSuggestions
              setOpenSidebar={setOpenSidebar}
              text={text}
              selectedValue={selectedValue?.displayName}
              itemData={itemData}
              chatData={chatData}
              setText={setText}
              replyBoxInputTextRef={replyBoxInputTextRef}
              setOpenDetailsSideBarReplay={setOpenDetailsSideBarReplay}
              setCheckAiSuggestions={setCheckAiSuggestions}
              monitor_id={selectedCard?.tracker_id}
              interaction_id={selectedCard?.tweet_id || selectedCard?.id}
              data_source_name={selectedCard?.data_source?.toUpperCase()}
              savedSuggTab={savedSuggTab}
              aiRepsTab={aiRepsTab}
              setSavedSuggTab={setSavedSuggTab}
              setAiRepsTab={setAiRepsTab}
              setSavedSuggTabDate={setSavedSuggTabDate}
              isCashedReplays={isCashedReplays}
              setIsCashedReplays={setIsCashedReplays}
              aiReplaysRefresh={aiReplaysRefresh}
              setAiReplaysRefresh={setAiReplaysRefresh}
            />
          ) : openSlaSideBar ? (
            <SlaDetailsSideBar
              setOpenSlaSideBar={setOpenSlaSideBar}
              selectedCard={selectedCard}
              headerData={headerData}
              slaLogs={slaLogs}
              aiAgentId={aiAgentId}
            />
          ) : openNotesSidebar ? (
            <NotesSidebar
              setOpenNotesSidebar={setOpenNotesSidebar}
              monitorId={itemData?.tracker_id}
              interactionId={selectedCard?.tweet_id || selectedCard?.id}
              dataSourceName={itemData?.data_source?.toUpperCase()}
              productId={window.localStorage.getItem("engagementsProductId")}
              userId={itemData?.assigning_info?.user_id}
              currentUserId={+localStorage.getItem("user_id")}
              setNotesCount={setNotesCount}
              notesCount={notesCount}
              interactionCreatedAt={selectedCard?.created_at}
            />
          ) : null}
        </div>
      ) : (
        <NoDataDetails fetchData={fetchData} />
      )}
      <SnackBar
        open={stats?.open}
        title={stats?.title}
        severity={stats?.severity}
        message={stats?.body}
        handleClose={() => setStats({ ...stats, open: false })}
        alertStyle={{ maxWidth: "300px" }}
      />
      <SnackBar
        open={snackBarData?.open}
        title={
          snackBarData?.title
            ? CheckValueLocale(snackBarData?.title, "", {}, intl)
            : null
        }
        severity={snackBarData?.severity}
        message={
          snackBarData?.body
            ? CheckValueLocale(snackBarData?.body, "", {}, intl)
            : null
        }
        handleClose={() => {
          setSnackBarData({ ...snackBarData, open: false });
        }}
      />
    </Box>
  );
};
export default EngagementsDetails;
