import { Box, Checkbox } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const RememberMeCheckBox = ({ rememberMe, setRememberMe, disabled }) => {
  const intl = useIntl();
  return (
    <Box>
      <Checkbox
        onClick={() => setRememberMe((prev) => !prev)}
        checked={rememberMe}
        disabled={disabled}
      />
      <Box component={"span"} className="remember-me-option-two-factor-auth">
        {CheckValueLocale("remember_me_option_two_factor_auth", "", {}, intl)}
      </Box>
    </Box>
  );
};

export default RememberMeCheckBox;
