import { Box, Chip, Tooltip, IconButton, TextField } from "@mui/material";
import { Formik, Form, FieldArray } from "formik";
import LucButton from "shared/lucButton/lucButton";
import {
  faCircleMinus,
  faDoNotEnter,
} from "@fortawesome/pro-regular-svg-icons";
import AudienceController from "services/controllers/audienceController";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useIntl } from "react-intl";
import { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckValueLocale } from "utils/helpers";
import { EditEngagerProfileFooter } from "./editEngagerProfileFooter";
import { EditContext } from "../context";

export const EditEngagerProfileForm = ({
  data,
  handleCancel,
  validationSchema,
  setVisibleItems,
  visibleItems,
  fieldType,
}) => {
  const {
    setIsEditing,
    setCurrentEditingSection,
    callSingleinteraction,
    userId,
    setStats,
  } = useContext(EditContext);
  const intl = useIntl();
  return (
    <Formik
      initialValues={{
        items:
          data?.length > 0
            ? [...data]
            : [{ value: "", editable: true, id: Date.now() }],
      }}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={(values, { setSubmitting }) => {
        const originalData = data?.map((item) => ({ ...item }));
        const updatedData = values?.items;
        const payload = { data: [], is_omniserve: true };

        // Check for added items
        updatedData.forEach((item) => {
          if (!originalData?.some((orig) => orig?.id === item?.id)) {
            payload.data.push({
              field_value: item?.value,
              field_name: fieldType,
              action: "add",
            });
          }
        });

        // Check for deleted items
        originalData.forEach((item) => {
          if (!updatedData.some((updated) => updated?.id === item?.id)) {
            payload.data.push({
              field_value: item?.value,
              field_name: fieldType,
              action: "delete",
              id: item?.id,
            });
          }
        });

        // Check for updated items
        updatedData.forEach((item) => {
          const originalItem = originalData.find(
            (orig) => orig?.id === item?.id,
          );
          if (originalItem && originalItem?.value !== item?.value) {
            payload.data.push({
              field_name: fieldType,
              field_value: item?.value,
              id: item?.id,
              action: "edit",
            });
          }
        });

        AudienceController.editProfileData(userId, payload).then((data) => {
          if (data?.data?.status === 204 || data?.data?.status === 200) {
            callSingleinteraction();
            setStats((prev) => ({
              ...prev,
              open: true,
              severity: "success",
              title: "",
              body: CheckValueLocale("profile_updated_success", "", {}, intl),
            }));
            setTimeout(() => {
              setStats((prev) => ({ ...prev, open: false }));
            }, 7000);
          } else {
            setStats((prev) => ({
              ...prev,
              open: true,
              severity: "error",
              title: CheckValueLocale("failed_to_delete_survey", "", {}, intl),
              body: CheckValueLocale("try_again", "", {}, intl),
            }));
            setTimeout(() => {
              setStats((prev) => ({ ...prev, open: false }));
            }, 7000);
          }
        });
        setIsEditing(false);
        setCurrentEditingSection(null);
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        isValid,
        dirty,
        handleChange,
        handleSubmit,
        handleBlur,
      }) => (
        <Form>
          <FieldArray name="items">
            {({ push, remove }) => {
              const totalItems = values?.items?.length;
              return (
                <Box className="engager-profile-form-container">
                  {Array.isArray(values?.items) &&
                    values?.items.slice(0, visibleItems).map((item, index) => (
                      <Box
                        key={`item-${index}`}
                        className="engager-user-info-item-list-item"
                      >
                        <Tooltip
                          title={
                            !item?.editable
                              ? CheckValueLocale(
                                  "engager_profile_disable_edit_item",
                                  "",
                                  {},
                                  intl,
                                )
                              : ""
                          }
                          arrow
                          placement="left"
                        >
                          <TextField
                            fullWidth
                            size="small"
                            name={`items.${index}.value`}
                            value={item?.value || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={!item?.editable}
                            error={
                              touched.items?.[index]?.value &&
                              Boolean(errors?.items?.[index]?.value)
                            }
                            helperText={
                              touched.items?.[index]?.value &&
                              errors?.items?.[index]?.value
                                ? CheckValueLocale(
                                    errors?.items?.[index]?.value,
                                    "",
                                    {},
                                    intl,
                                  )
                                : ""
                            }
                            autoFocus={
                              item?.value === "" &&
                              index === values?.items?.length - 1
                            }
                            className={`engager-user-info-item-list-text-field ${
                              item?.editable ? "" : "edit-disabled"
                            } ${fieldType === "phone_number" ? "engager-field-phone-number" : ""}`}
                          />
                        </Tooltip>
                        {item?.editable ? (
                          <Tooltip
                            title={CheckValueLocale("delete", "", {}, intl)}
                            arrow
                            placement="top"
                          >
                            <FontAwesomeIcon
                              icon={faCircleMinus}
                              onClick={() => remove(index)}
                              className="engager-delete-item-icon"
                            />
                          </Tooltip>
                        ) : (
                          <Box className="engager-profile-disabled-item-placeholder" />
                        )}
                      </Box>
                    ))}
                  {totalItems > 10 && (
                    <Box className="engager-user-info-item-list-show-more-less">
                      <Chip
                        size="small"
                        label={
                          visibleItems < totalItems
                            ? CheckValueLocale("show_more_btn", "", {}, intl)
                            : CheckValueLocale("show_less_btn", "", {}, intl)
                        }
                        variant="outlined"
                        onClick={
                          visibleItems < totalItems
                            ? () => setVisibleItems(visibleItems + 10)
                            : () => setVisibleItems(10)
                        }
                        className="engager-user-info-item-list-show-more-less-chip"
                      />
                    </Box>
                  )}
                  <LucButton
                    variant="outline"
                    fullWidth
                    startIcon={<FontAwesomeIcon icon={faPlus} />}
                    size="small"
                    className="engager-profile-add-new-item"
                    onClick={() => {
                      push({
                        value: "",
                        editable: true,
                        id: Date.now(),
                      });
                      if (visibleItems === 10 && totalItems >= 10) {
                        setVisibleItems(totalItems + 1);
                      }
                    }}
                  >
                    {CheckValueLocale("add_new_item", "", {}, intl)}
                  </LucButton>
                  <EditEngagerProfileFooter
                    handleCancel={handleCancel}
                    submitButtonDisabled={!dirty || !isValid}
                    onSave={() => handleSubmit()}
                  />
                </Box>
              );
            }}
          </FieldArray>
        </Form>
      )}
    </Formik>
  );
};
