import { Box } from "@mui/material";
import "./slaDetailsSideBar.scss";
import React, { useState, useEffect } from "react";
import {
  CheckValueLocale,
  getTimeZone,
  differenceBetweenDates,
  extractDisplayInfoFromDataSource,
  timeCounter,
  convertToSeconds,
  isNullish,
} from "utils/helpers/index.js";
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useDispatch, useSelector } from "react-redux";
import {
  slaData,
  clearSla,
} from "utils/redux/features/Engagements/EngagementsSlice.js";
import { getWorkingSecondsInTimeRange } from "utils/helpers/sla/slaCalculator";

const SlaCounters = ({ slaTimes, selectedCard }) => {
  const intl = useIntl();
  const [displayCounterFrt, setDisplayCounterFrt] = useState([
    0,
    "S",
    100,
    100,
    true,
  ]);
  const [displayCounterTtc, setDisplayCounterTtc] = useState([
    0,
    "S",
    100,
    100,
    true,
  ]);
  const [displayCounterTut, setDisplayCounterTut] = useState([
    0,
    "S",
    100,
    100,
    true,
  ]);
  const [displayCounterNrt, setDisplayCounterNrt] = useState([
    0,
    "S",
    100,
    100,
    true,
  ]);
  const [ttcExpectedDate, setTtcExpectedDate] = useState(0);
  const [tutExpectedDate, setTutExpectedDate] = useState(0);
  const [nrtExpectedDate, setNrtExpectedDate] = useState(0);
  const [nrtSec, setNrtSec] = useState(null);
  const [nrtDate, setNrtDate] = useState(0);
  const [nrtClosedBlocks, setNrtClosedBlocks] = useState([]);

  const dispatch = useDispatch();
  let statusItemCard = selectedCard?.system_replies
    ? selectedCard?.system_replies?.status
    : selectedCard?.status;
  const reduxSlaData = useSelector((state) => state?.engagements?.sla);
  const slaKey = `${selectedCard?.tweet_id ? selectedCard?.tweet_id : selectedCard?.id}-${
    selectedCard?.tracker_id
  }`;
  let frtSec = reduxSlaData[slaKey]?.frtSec;
  frtSec = isNullish(frtSec) ? null : frtSec;
  let ttcSec = reduxSlaData[slaKey]?.ttcSec;
  ttcSec = isNullish(ttcSec) ? null : ttcSec;
  let ttcCompletedDate = reduxSlaData[slaKey]?.ttcCompletedDate;
  let tutSec = reduxSlaData[slaKey]?.tutSec;
  tutSec = isNullish(tutSec) ? null : tutSec;
  let nrtList = reduxSlaData[slaKey]?.nrtList;
  let frtDate = reduxSlaData[slaKey]?.frtDate;
  let tutDate = reduxSlaData[slaKey]?.tutDate;
  let reload = reduxSlaData[slaKey]?.reload;
  let teamId = reduxSlaData[slaKey]?.teamId;
  let hasTeamsLoaded = reduxSlaData?.teamsLoaded;
  let slaId = reduxSlaData[slaKey]?.slaId;

  let intervalFrt;
  let intervalTtc;
  let intervalNrt;
  let intervalTut;

  const getAgentWorkingHoursInfoForInteraction = (
    interactionCreatedAtUnix,
    endTimeUnix,
  ) => {
    const teamWorkingHours = reduxSlaData?.teams?.find(
      (team) => +team?.id === +teamId,
    )?.team_working_time?.[0]?.working_time;
    return getWorkingSecondsInTimeRange(
      interactionCreatedAtUnix,
      endTimeUnix,
      teamWorkingHours,
    );
  };

  useEffect(() => {
    let slaObj = { ...reduxSlaData };
    slaObj[slaKey] = {
      ...reduxSlaData[slaKey],
      reload: reload + 1,
    };
    dispatch(slaData(slaObj));
  }, []);

  useEffect(() => {
    if (!hasTeamsLoaded) return;
    setDisplayCounterNrt(["-", "", 0, 0, true]);
    if (
      nrtList?.length &&
      slaTimes?.id &&
      slaId &&
      selectedCard?.type == "dm" &&
      !isNullish(frtSec)
    ) {
      let nrtArr = [...nrtList];
      setNrtSec(
        isNullish(nrtList?.[0]?.time_value) ? null : nrtList?.[0]?.time_value,
      );
      setNrtDate(nrtList?.[0]?.created_at);
      nrtArr = [...nrtList?.slice(1, nrtList?.length)];
      if (nrtArr?.length) {
        let arr = [];
        nrtArr?.map((item) => {
          let nrtExpectedDate =
            item?.created_at -
            item?.time_value +
            convertToSeconds({ ...slaTimes?.next_response_time });
          const currentUnixDate = item?.created_at;
          let { value, timer, percentage, progress } = timeCounter(
            nrtExpectedDate,
            currentUnixDate,
            convertToSeconds({ ...slaTimes?.next_response_time }),
          );
          if (percentage === 100 && item?.time_value === 0) {
            percentage = 0.01;
            progress = 0.01;
          }
          arr.push([
            ...value,
            percentage,
            progress,
            false,
            true,
            item?.created_at,
          ]);
        });
        setNrtClosedBlocks([...arr]);
      }
    } else {
      setNrtSec(null);
      setNrtDate(0);
      setDisplayCounterNrt(["-", "", 0, 0, false]);
    }
  }, [nrtList, slaTimes, frtSec, slaId, teamId, hasTeamsLoaded]);

  // handle live counter every time its value updated at redux
  useEffect(() => {
    if (!hasTeamsLoaded) return;
    // here we check if we run frt counter live or not
    if (isNullish(frtSec) && slaId && slaTimes?.id) {
      //by default we run live time = 1000
      startIntervalFrt(1000);
    } else {
      clearInterval(intervalFrt);
      if (slaId && slaTimes?.id) {
        let frtExpectedDate =
          selectedCard?.created_at +
          convertToSeconds({ ...slaTimes?.first_response_time });
        const currentUnixDate = selectedCard?.created_at + frtSec;
        //timeCounter fun use to return counter data and it is need to give it two unix dates and sla time at seconds
        let { value, timer, percentage, progress } = timeCounter(
          frtExpectedDate,
          currentUnixDate,
          convertToSeconds({ ...slaTimes?.first_response_time }),
        );

        if (percentage === 100 && frtSec === 0) {
          percentage = 0.01;
          progress = 0.01;
        }
        setDisplayCounterFrt([...value, percentage, progress, false]);
      }
    }

    return () => {
      // when close sideBar we stop interval
      clearInterval(intervalFrt);
    };
  }, [frtSec, slaId, slaTimes, reload, hasTeamsLoaded, teamId]);

  useEffect(() => {
    if (!hasTeamsLoaded) return;
    if (
      (isNullish(ttcSec) || statusItemCard !== "complete") &&
      slaId &&
      slaTimes?.id
    ) {
      let ttcDate =
        selectedCard?.created_at +
        convertToSeconds({ ...slaTimes?.time_to_complete });
      setTtcExpectedDate(ttcDate);
      if (!isNullish(ttcSec)) {
        let countedPeriod =
          convertToSeconds({ ...slaTimes?.time_to_complete }) - ttcSec;
        ttcDate =
          moment().unix() +
          parseInt(localStorage.companyInfo) * 60 * 60 +
          countedPeriod;
      }
      setTtcExpectedDate(ttcDate);
      startIntervalTtc(1000, ttcDate);
    } else {
      clearInterval(intervalTtc);
      if (slaId && slaTimes?.id) {
        let ttcExDate =
          selectedCard?.created_at +
          convertToSeconds({ ...slaTimes?.time_to_complete });
        const currentUnixDate = selectedCard?.created_at + ttcSec;
        let { value, timer, percentage, progress } = timeCounter(
          ttcExDate,
          currentUnixDate,
          convertToSeconds({ ...slaTimes?.time_to_complete }),
        );
        if (percentage === 100 && ttcSec === 0) {
          percentage = 0.01;
          progress = 0.01;
        }
        setDisplayCounterTtc([
          ...value,
          percentage,
          progress,
          false,
          false,
          ttcCompletedDate,
        ]);
      }
    }

    return () => {
      clearInterval(intervalTtc);
    };
  }, [
    ttcSec,
    slaId,
    slaTimes,
    ttcCompletedDate,
    reload,
    hasTeamsLoaded,
    teamId,
  ]);

  useEffect(() => {
    if (!hasTeamsLoaded) return;
    //(!tutSec || !userId)
    if (isNullish(tutSec) && slaId && slaTimes?.id) {
      let tutDate =
        selectedCard?.created_at +
        convertToSeconds({ ...slaTimes?.total_unassigned_time });
      if (tutSec) {
        let countedPeriod =
          convertToSeconds({ ...slaTimes?.total_unassigned_time }) - tutSec;
        tutDate =
          moment().unix() +
          parseInt(localStorage.companyInfo) * 60 * 60 +
          countedPeriod;
      }
      setTutExpectedDate(tutDate);
      startIntervalTut(1000, tutDate);
    } else {
      clearInterval(intervalTut);
      if (slaId && slaTimes?.id) {
        let tutExDate =
          selectedCard?.created_at +
          convertToSeconds({ ...slaTimes?.total_unassigned_time });
        const currentUnixDate = selectedCard?.created_at + tutSec;
        let { value, timer, percentage, progress } = timeCounter(
          tutExDate,
          currentUnixDate,
          convertToSeconds({ ...slaTimes?.total_unassigned_time }),
        );
        if (percentage === 100 && tutSec === 0) {
          percentage = 0.01;
          progress = 0.01;
        }

        setDisplayCounterTut([...value, percentage, progress, false]);
      }
    }

    return () => {
      clearInterval(intervalTut);
    };
  }, [tutSec, slaId, slaTimes, reload, hasTeamsLoaded, teamId]);

  useEffect(() => {
    if (!hasTeamsLoaded) return;
    if (nrtDate && !isNullish(frtSec) && selectedCard?.type == "dm") {
      setNrtExpectedDate(
        nrtDate + convertToSeconds({ ...slaTimes?.next_response_time }),
      );
      if (
        isNullish(nrtSec) &&
        nrtDate &&
        !isNullish(frtSec) &&
        selectedCard?.type == "dm" &&
        slaId &&
        slaTimes?.id
      ) {
        startIntervalNrt(
          1000,
          nrtDate + convertToSeconds({ ...slaTimes?.next_response_time }),
        );
      } else {
        clearInterval(intervalNrt);
        if (slaId && slaTimes?.id) {
          let nrtExpectedDate =
            nrtDate -
            nrtSec +
            convertToSeconds({ ...slaTimes?.next_response_time });
          const currentUnixDate = nrtDate;
          let { value, timer, percentage, progress } = timeCounter(
            nrtExpectedDate,
            currentUnixDate,
            convertToSeconds({ ...slaTimes?.next_response_time }),
          );
          if (percentage === 100 && nrtSec === 0) {
            percentage = 0.01;
            progress = 0.01;
          }

          setDisplayCounterNrt([
            ...value,
            percentage,
            progress,
            false,
            false,
            nrtDate,
          ]);
        }
      }
    }

    return () => {
      clearInterval(intervalNrt);
    };
  }, [
    nrtSec,
    nrtDate,
    slaId,
    slaTimes,
    frtSec,
    reload,
    hasTeamsLoaded,
    teamId,
  ]);

  const handleCounterNrt = (date) => {
    let newDate = date;
    let currentUnixDate =
      moment().unix() + parseInt(localStorage.companyInfo) * 60 * 60;
    const slaNRT = convertToSeconds({ ...slaTimes?.next_response_time });
    const {
      workingSeconds,
      isAgentWorkingNow,
      secondsUntilNextShift,
      hasWorkingHours,
      teamWorkingHours,
    } = getAgentWorkingHoursInfoForInteraction(
      newDate - slaNRT,
      currentUnixDate,
    );
    if (hasWorkingHours) {
      newDate = slaNRT;
      currentUnixDate = workingSeconds;
    }
    let { value, timer, percentage, progress } = timeCounter(
      newDate,
      currentUnixDate,
      convertToSeconds({ ...slaTimes?.next_response_time }),
    );
    if (percentage === 100 && hasWorkingHours && workingSeconds === 0) {
      percentage = 0.01;
      progress = 0.01;
    }

    setDisplayCounterNrt([...value, percentage, progress, false]);
    startIntervalNrt(
      statusItemCard !== "complete"
        ? hasWorkingHours && !isAgentWorkingNow // if agent is not working now we need to update the counter every secondsUntilNextShift to recalculate the remaining seconds
          ? secondsUntilNextShift * 1000
          : timer
        : 0,
      date,
    );
  };

  const startIntervalNrt = (timer = 0, date = nrtExpectedDate) => {
    clearInterval(intervalNrt);

    if (!timer) {
      // console.log(`  Sla sideBar  Clearing interval Nrt...`);
      return;
    }
    // console.log(` Sla sideBar  Nrt every ${timer / 1000} S`);
    intervalNrt = setInterval(() => {
      handleCounterNrt(date);
    }, timer);
  };

  const handleCounterTut = (date) => {
    let newDate = date;
    let currentUnixDate =
      moment().unix() + parseInt(localStorage.companyInfo) * 60 * 60;
    const slaTUT = convertToSeconds({ ...slaTimes?.total_unassigned_time });
    const TUTSec = tutSec || 0;
    const {
      workingSeconds,
      isAgentWorkingNow,
      secondsUntilNextShift,
      hasWorkingHours,
      teamWorkingHours,
    } = getAgentWorkingHoursInfoForInteraction(
      newDate - slaTUT + TUTSec, // resetting the added seconds to the current date from the expected date
      currentUnixDate,
    );

    if (hasWorkingHours) {
      newDate = slaTUT - TUTSec;
      currentUnixDate = workingSeconds;
    }
    let { value, timer, percentage, progress } = timeCounter(
      newDate,
      currentUnixDate,
      convertToSeconds({ ...slaTimes?.total_unassigned_time }),
    );
    if (percentage === 100 && hasWorkingHours && workingSeconds === 0) {
      percentage = 0.01;
      progress = 0.01;
    }

    setDisplayCounterTut([...value, percentage, progress, false]);
    startIntervalTut(
      statusItemCard !== "complete"
        ? hasWorkingHours && !isAgentWorkingNow
          ? secondsUntilNextShift * 1000
          : timer
        : 0,
      date,
    );
  };

  const startIntervalTut = (timer = 0, date = tutExpectedDate) => {
    clearInterval(intervalTut);

    if (!timer) {
      // console.log(` Sla sideBar Clearing interval Tut...`);
      return;
    }
    // console.log(`Sla sideBar Tut every ${timer / 1000} S`);
    intervalTut = setInterval(() => {
      handleCounterTut(date);
    }, timer);
  };

  const handleCounterTtc = (date) => {
    let newDate = date;
    let currentUnixDate =
      moment().unix() + parseInt(localStorage.companyInfo) * 60 * 60;
    const slaTTC = convertToSeconds({ ...slaTimes?.time_to_complete });
    const TTCSec = ttcSec || 0;
    const {
      workingSeconds,
      isAgentWorkingNow,
      secondsUntilNextShift,
      hasWorkingHours,
      teamWorkingHours,
    } = getAgentWorkingHoursInfoForInteraction(
      newDate - slaTTC + TTCSec, // resetting the added seconds to the current date from the expected date
      currentUnixDate,
    );
    if (hasWorkingHours) {
      newDate = slaTTC - TTCSec;
      currentUnixDate = workingSeconds;
    }
    let { value, timer, percentage, progress } = timeCounter(
      newDate,
      currentUnixDate,
      convertToSeconds({ ...slaTimes?.time_to_complete }),
    );

    if (
      percentage === 100 &&
      !ttcSec &&
      hasWorkingHours &&
      workingSeconds === 0
    ) {
      percentage = 0.01;
      progress = 0.01;
    }

    setDisplayCounterTtc([...value, percentage, progress, false]);
    startIntervalTtc(
      hasWorkingHours && !isAgentWorkingNow
        ? secondsUntilNextShift * 1000
        : timer,
      date,
    );
  };

  const startIntervalTtc = (timer = 0, date = ttcExpectedDate) => {
    clearInterval(intervalTtc);

    if (!timer) {
      // console.log(` Sla sideBar Clearing interval Ttc...`);
      return;
    }
    // console.log(`Sla sideBar Ttc every ${timer / 1000} S`);
    intervalTtc = setInterval(() => {
      handleCounterTtc(date);
    }, timer);
  };

  const handleCounterFrt = () => {
    // at this fun we handle dates sended to timeCounter to calculate live counter displaied data
    let frtExpectedDate =
      selectedCard?.created_at +
      convertToSeconds({ ...slaTimes?.first_response_time });
    // is change every time we call handleCounterFrt fun
    let currentUnixDate =
      moment().unix() + parseInt(localStorage.companyInfo) * 60 * 60;
    let {
      workingSeconds,
      isAgentWorkingNow,
      secondsUntilNextShift,
      hasWorkingHours,
      teamWorkingHours,
    } = getAgentWorkingHoursInfoForInteraction(
      selectedCard?.created_at,
      currentUnixDate,
    );
    // if this interaction has working hours we need to calculate the remaining seconds instead of the whole seconds range between the interaction created at and now
    if (hasWorkingHours) {
      frtExpectedDate = convertToSeconds({ ...slaTimes?.first_response_time });
      currentUnixDate = workingSeconds;
    }

    let { value, timer, percentage, progress } = timeCounter(
      frtExpectedDate,
      currentUnixDate,
      convertToSeconds({ ...slaTimes?.first_response_time }),
    );
    if (percentage === 100 && hasWorkingHours && workingSeconds === 0) {
      percentage = 0.01;
      progress = 0.01;
    }
    setDisplayCounterFrt([...value, percentage, progress, false]);

    // here we call again startIntervalFrt but by new timer return from timeCounter fun.
    // if state of the card is completed we run live counter once and stop it
    startIntervalFrt(
      statusItemCard !== "complete"
        ? hasWorkingHours && !isAgentWorkingNow // if agent is not working now we need to update the counter every secondsUntilNextShift to recalculate the remaining seconds
          ? secondsUntilNextShift * 1000
          : timer
        : 0,
    );
  };

  const startIntervalFrt = (timer = 0) => {
    clearInterval(intervalFrt);
    //  if we call startIntervalFrt fun with time = 0 this mean stop live
    if (!timer) {
      // console.log(`Sla sideBar Clearing interval Frt...`);
      return;
    }
    // console.log(`Sla sideBar Frt every ${timer / 1000} S`);
    intervalFrt = setInterval(() => {
      handleCounterFrt();
    }, timer);
  };

  const showEndTimeForCounter = (sectime, type) => {
    let date = selectedCard?.created_at + sectime;
    if (type === "frt" && frtDate) {
      date = frtDate;
    } else if (type === "ttc" && ttcCompletedDate) {
      date = ttcCompletedDate;
    } else if (type === "tut" && tutDate) {
      date = tutDate;
    } else if (type === "nrt" && nrtDate) {
      date = nrtDate;
    }
    return date - getTimeZone() * 60 * 60;
  };
  const mainCounter = (liveCounter, intialCounter, islive, sectime, type) => {
    return (
      <Box
        className={`sla-counter-progress ${
          islive && !liveCounter?.[4] && liveCounter[0] !== "-"
            ? liveCounter[2] >= 0 && liveCounter[2] <= 90
              ? "green"
              : liveCounter[2] <= 95
                ? "orange"
                : "red"
            : ""
        }`}
      >
        {!liveCounter?.[5] ? (
          !islive && liveCounter[0] !== "-" ? (
            <CheckCircleIcon className="sla-counter-progress-done" />
          ) : (
            <Box sx={{ position: "relative" }}>
              <CircularProgress
                variant="determinate"
                sx={{
                  color: (theme) =>
                    theme.palette.grey[
                      theme.palette.mode === "light" ? 200 : 800
                    ],
                }}
                thickness={10}
                value={100}
              />
              <CircularProgress
                variant="determinate"
                className="sla-progress-live"
                value={!liveCounter?.[4] ? liveCounter?.[3] : 0}
                sx={{
                  position: "absolute",
                  left: 0,
                }}
                thickness={10}
              />
            </Box>
          )
        ) : null}
        <Box className="sla-counter-time">
          {liveCounter?.[4] ? (
            <FontAwesomeIcon icon={faSpinnerThird} spin />
          ) : (
            <>
              <Box dir={"ltr"}>{liveCounter?.[0]}</Box>
              <Box>{CheckValueLocale(liveCounter?.[1], "", {}, intl)}</Box>
            </>
          )}
          <Box className="sla-counter-slash">/</Box>
          <Box dir={"ltr"}>{intialCounter?.[0]}</Box>
          <Box>{CheckValueLocale(intialCounter?.[1], "", {}, intl)}</Box>
        </Box>
        {!islive && liveCounter[0] !== "-" ? (
          <Box className="sla-counter-date">
            {moment
              ?.unix(
                liveCounter?.[6]
                  ? liveCounter?.[6] - getTimeZone() * 60 * 60
                  : showEndTimeForCounter(sectime, type),
              )
              ?.format("D/M/YYYY, h:mma")}
          </Box>
        ) : null}
      </Box>
    );
  };
  return (
    <Box className="main-sla-contant">
      <Box className="sla-contant-title" id="sla-sidebar-sla-title">
        <Tooltip
          title={CheckValueLocale("slas_tooltip", "", {}, intl)}
          placement="bottom"
          arrow
        >
          {CheckValueLocale("slas", "", {}, intl)}
        </Tooltip>
      </Box>
      {!slaTimes?.id || !slaId ? (
        <Box className="main-sla-counter-empty">
          {CheckValueLocale("slas_empty", "", {}, intl)}
        </Box>
      ) : (
        <Box className="main-sla-counter">
          <Box className={`main-sla-counter-item`}>
            {CheckValueLocale("ttc_sla", "", {}, intl)}
            {mainCounter(
              displayCounterTtc,
              [
                slaTimes?.time_to_complete?.value,
                slaTimes?.time_to_complete?.unit[0]?.toUpperCase(),
              ],
              isNullish(ttcSec) || statusItemCard !== "complete",
              ttcSec,
              "ttc",
            )}
          </Box>
          <Box className={`main-sla-counter-item`}>
            {CheckValueLocale("frt_sla", "", {}, intl)}
            {mainCounter(
              displayCounterFrt,
              [
                slaTimes?.first_response_time?.value,
                slaTimes?.first_response_time?.unit[0]?.toUpperCase(),
              ],
              isNullish(frtSec),
              frtSec,
              "frt",
            )}
          </Box>
          {selectedCard?.type == "dm" ? (
            <Box className={`main-sla-counter-item`}>
              {CheckValueLocale("nrt_sla", "", {}, intl)}
              {mainCounter(
                displayCounterNrt,
                [
                  slaTimes?.next_response_time?.value,
                  slaTimes?.next_response_time?.unit[0]?.toUpperCase(),
                ],
                isNullish(nrtSec),
                nrtSec,
                "nrt",
              )}
              {nrtClosedBlocks?.length ? (
                <Box className={`nrt-blocks-list`}>
                  {nrtClosedBlocks?.map((item) =>
                    mainCounter(
                      item,
                      [
                        slaTimes?.next_response_time?.value,
                        slaTimes?.next_response_time?.unit[0]?.toUpperCase(),
                      ],
                      false,
                      0,
                      "nrt_list",
                    ),
                  )}
                </Box>
              ) : null}
            </Box>
          ) : null}
          <Box className={`main-sla-counter-item last-item`}>
            {CheckValueLocale("tut_sla", "", {}, intl)}
            {mainCounter(
              displayCounterTut,
              [
                slaTimes?.total_unassigned_time?.value,
                slaTimes?.total_unassigned_time?.unit[0]?.toUpperCase(),
              ],
              isNullish(tutSec),
              tutSec,
              "tut",
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default SlaCounters;
