import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale, truncate } from "utils/helpers/index.js";
import CustomizedTooltip, {
  customizedStyledTooltip,
} from "components/customizedTooltip";

const CountryAndLanguageFooter = ({ label, value }) => {
  const intl = useIntl();
  return (
    <Box className="footer-item border-end">
      <Box component={"span"} className="label-footer-item">
        {CheckValueLocale(label, "", {}, intl)}:
      </Box>
      <CustomizedTooltip
        title={value?.length > 15 ? value : ""}
        styles={customizedStyledTooltip}
        arrow
        placement="top"
      >
        <Box component={"span"}>{truncate(value, 15)}</Box>
      </CustomizedTooltip>
    </Box>
  );
};

export default CountryAndLanguageFooter;
