import { useIntl } from "react-intl";
import { Box, Grid, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  fa00,
  faAngleDoubleDown,
  faAt,
  faEllipsis,
  faFileUser,
  faFontCase,
  faHundredPoints,
  faPhone,
  faPlus,
  faSquareCheck,
  faT,
  faX,
  faY,
} from "@fortawesome/pro-regular-svg-icons";
import { CheckValueLocale } from "utils/helpers";
import CustomizedTooltip from "components/customizedTooltip";
import "./style.scss";

const AddQuestionsToggle = (props) => {
  const {
    addQuestionExpanded,
    setAddQuestionExpanded,
    handleAddQuestion,
    scrollToIdFunc,
  } = props;

  const intl = useIntl();

  const questionTypes = [
    {
      question_title: "embedded_question_name_title",
      icon: faT,
      question_key: "name",
    },
    {
      question_title: "embedded_question_email",
      icon: faAt,
      question_key: "email",
    },
    {
      question_title: "embedded_question_phone",
      icon: faPhone,
      question_key: "phone_number",
    },
    {
      question_title: "embedded_question_social_media",
      icon: faFileUser,
      question_key: "social_media",
    },
    {
      question_title: "embedded_question_yes_no",
      icon: faY,
      question_key: "yes_no",
    },
    {
      question_title: "embedded_question_number",
      icon: fa00,
      question_key: "number",
    },
    {
      question_title: "embedded_question_short_text",
      icon: faFontCase,
      question_key: "short_text",
    },
    {
      question_title: "embedded_question_mcq",
      icon: faSquareCheck,
      question_key: "multiple_choice",
    },
    {
      question_title: "embedded_question_scale",
      icon: faEllipsis,
      question_key: "scale",
    },
    {
      question_title: "embedded_question_nps",
      icon: faHundredPoints,
      question_key: "nps",
    },
    {
      question_title: "embedded_question_dropdown",
      icon: faAngleDoubleDown,
      question_key: "dropdown",
    },
  ];

  return (
    <Box id="embedded-add-questions-continer">
      <Box id="add-questions-toggle-wrapper">
        <Box
          className={`add-start-end-line ${addQuestionExpanded ? "expanded" : ""}`}
        />
        <CustomizedTooltip
          title={CheckValueLocale(
            addQuestionExpanded ? "" : "embedded_survey_new_question",
            "",
            {},
            intl,
          )}
          placement="bottom"
          arrow
          styles={{ padding: "8px 10px" }}
        >
          <Box
            className="add-questions-toggle-btn"
            onClick={() => {
              scrollToIdFunc("embedded-add-questions-list");
              setAddQuestionExpanded(!addQuestionExpanded);
            }}
          >
            <FontAwesomeIcon icon={addQuestionExpanded ? faX : faPlus} />
          </Box>
        </CustomizedTooltip>
        <Box
          className={`add-start-end-line ${addQuestionExpanded ? "expanded" : ""}`}
        />
      </Box>
      {addQuestionExpanded ? (
        <Grid
          container
          rowSpacing={2}
          id="embedded-add-questions-list"
          className="add-questions-list"
        >
          {questionTypes?.map((item, index) => (
            <Grid
              item
              xs={2}
              key={index}
              className="add-questions-item-wrapper"
            >
              <Box
                className="add-questions-item"
                onClick={async (e) => {
                  await handleAddQuestion(item?.question_key);
                  setAddQuestionExpanded(false);
                }}
              >
                <Box className="add-questions-icon">
                  <FontAwesomeIcon icon={item?.icon} fixedWidth />
                </Box>
                <Typography className="add-questions-title">
                  {CheckValueLocale(item?.question_title, "", {}, intl)}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      ) : null}
    </Box>
  );
};

export default AddQuestionsToggle;
