import { useQuery } from "@tanstack/react-query";
import AudienceController from "services/controllers/audienceController";

const useAudienceSurveyDetails = (profileId, surveyId, respondantId) => {
  return useQuery({
    queryKey: ["audienceSurveyDetails", profileId, surveyId, respondantId],
    queryFn: () =>
      AudienceController.getAudienceSurveyDetails(profileId, {
        survey_id: surveyId,
        respondant_id: respondantId,
      }),
    enabled: !!profileId && !!surveyId && !!respondantId,
    select: (response) => response?.data?.data,
    retry: false,
  });
};

export default useAudienceSurveyDetails;
