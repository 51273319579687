import { Box, Typography } from "@mui/material";
import CustomizedTooltip, {
  customizedStyledTooltip,
} from "components/customizedTooltip";
import { useIntl } from "react-intl";
import SearchableMultiSelectFilter from "shared/reduxComponents/filters/general/sharedFilters/searchableMultiSelectFilter/component/searchableMultiSelectFilter.js";
import { CheckValueLocale, truncate } from "utils/helpers";
import dummyImage from "images/monitor_revamp/user.svg";
import { useGetUsernameAccountLists } from "pages/accountsLists/hooks/useGetUsernameAccountLists";
import { useEffect, useState } from "react";
import { Checkbox } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import createListButton from "pages/accountsLists/sharedComponents/createListButton";

const ManageUserAccountListsDialogBody = (props) => {
  const {
    post,
    handleListChange,
    valueToRender,
    responseOptionsTransformer,
    handleOnOptionsFetched,
  } = props;
  const intl = useIntl();

  const renderOption = (props, option, { selected }) => {
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const { key, ...optionProps } = props;
    return (
      <Box
        key={key}
        onKeyDown={(e) => {
          if (e?.key === "Enter") {
            e?.preventDefault();
          }
        }}
        component="li"
        {...optionProps}
      >
        <Box className="manager-account-lists-dialog-body">
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            className="manager-account-lists-dialog-body-checkbox"
            checked={selected}
          />
          <Box className="options-with-count-label">{option?.label}</Box>
          <Box className="options-with-count">({option?.count})</Box>
          {option?.usernames_full && (
            <Box className="account-list-chip">
              {CheckValueLocale("manage_lists_full", "", {}, intl)}
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  const { data: fetchedOptions } = useGetUsernameAccountLists(
    post?.screen_name,
  );

  const checkDisableOptions = (option) => {
    return option?.usernames_full && !option?.is_selected;
  };

  const [optionList, setOptionList] = useState([]);
  useEffect(() => {
    if (fetchedOptions?.data) {
      setOptionList(responseOptionsTransformer(fetchedOptions));
      handleOnOptionsFetched(fetchedOptions);
    }
  }, [fetchedOptions]);

  let message = (
    <Box component="span">
      {CheckValueLocale("manage_lists_message", "", {}, intl)}
    </Box>
  );

  const handleImageError = (event) => {
    event.target.src = dummyImage;
    event.target.alt = "placeholder-image";
  };

  return (
    <Box className="manager-account-lists-dialog-body">
      <Box>{message}</Box>

      <Box className="user-info-rectangle">
        <Box className="image-section">
          <img
            src={post?.user_profile_image_url}
            alt="User Profile"
            className="profile-image"
            onError={handleImageError}
          />
        </Box>

        <Box className="content-section">
          <Box className="rectangle-section name-section">
            <CustomizedTooltip
              title={post?.user_name?.length > 20 && post?.user_name}
              arrow
              placement={"top"}
              styles={customizedStyledTooltip}
            >
              <Typography component="span" className="manage-lists-name">
                {truncate(post?.user_name, 20) || "N/A"}
              </Typography>
            </CustomizedTooltip>
            <Typography component="span" className="section-value">
              @{post?.screen_name || "N/A"}
            </Typography>
          </Box>

          <Box className="rectangle-section followers-section">
            <Typography component="span" className="label">
              {CheckValueLocale("manage_lists_followers", "", {}, intl)}
            </Typography>
            <Typography component="span" className="section-value">
              {post?.followers_count ||
                CheckValueLocale("manage_lists_followers_NA", "", {}, intl)}
            </Typography>
          </Box>

          <Box className="rectangle-section location-section">
            <Typography component="span" className="label">
              {CheckValueLocale("manage_lists_location", "", {}, intl)}
            </Typography>
            <Typography component="span" className="section-value">
              {post?.location ||
                CheckValueLocale("manage_lists_location_NA", "", {}, intl)}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box>
        <CustomizedTooltip>
          <Box className="tooltip-custom-new">
            <Typography variant="h8">
              {CheckValueLocale("manage_lists_account_lists", "", {}, intl)}
            </Typography>

            <SearchableMultiSelectFilter
              className="accounts-lists-searchable-multi-select-filter"
              filterName={"manage_lists_select"}
              handleListChange={handleListChange}
              valueToRender={valueToRender}
              renderOption={renderOption}
              checkDisableOptions={checkDisableOptions}
              optionList={optionList}
              noOptionsText={createListButton}
            />
          </Box>
        </CustomizedTooltip>
      </Box>
    </Box>
  );
};

export default ManageUserAccountListsDialogBody;
