// dateUtils.js

import moment from "moment";
import { CheckValueLocale } from "utils/helpers";

// Use "DD/MM/YYYY" for day/month/year
const DATE_FORMAT = "DD/MM/YYYY";

// Use "DD/MM/YYYY h:mm a" if you ever need date+time in a single format
// but for times in the final range, we will manually localize AM/PM.
const DATE_TIME_FORMAT = "DD/MM/YYYY h:mm a";

/**
 * Helper function that applies hour, minute, and second
 * to a Moment object based on a time string in the "HH:MM" format (24-hour).
 * If the string is invalid, it does nothing.
 */
function applyTimeToMoment(dateTimeMoment, timeMoment) {
  if (!dateTimeMoment || !dateTimeMoment.isValid() || !timeMoment)
    return dateTimeMoment;

  const [hoursString, minutesString] = timeMoment
    ?.format("HH:mm")
    ?.split?.(":");
  if (!isNaN(+hoursString) && !isNaN(+minutesString)) {
    dateTimeMoment.hour(+hoursString).minute(+minutesString).second(0);
  }
  return dateTimeMoment;
}

/**
 * Safely format a Moment object using a specified format pattern.
 * Returns an empty string if the Moment is invalid or falsy.
 */
function safelyFormatMoment(dateTimeMoment, formatPattern) {
  if (!dateTimeMoment || !dateTimeMoment.isValid()) return "";
  return dateTimeMoment.format(formatPattern);
}

/**
 * Format a Moment date with an optional time component.
 * If includeTime=true, it uses DATE_TIME_FORMAT ("DD/MM/YYYY h:mm a").
 * Otherwise, it uses DATE_FORMAT ("DD/MM/YYYY").
 */
function formatMomentDate(dateTimeMoment, includeTime = false) {
  return includeTime
    ? safelyFormatMoment(dateTimeMoment, DATE_TIME_FORMAT)
    : safelyFormatMoment(dateTimeMoment, DATE_FORMAT);
}

export function formatFullRange(
  startDateTimeMoment,
  endDateTimeMoment,
  startTimeMoment,
  endTimeMoment,
  intl,
) {
  // Return an empty string if either date is missing
  // if (!startDateTimeMoment || !endDateTimeMoment) return "";

  // Clone to avoid mutating original moment objects
  const adjustedStartDateTime = applyTimeToMoment(
    startDateTimeMoment.clone(),
    startTimeMoment,
  );
  const adjustedEndDateTime = applyTimeToMoment(
    endDateTimeMoment.clone(),
    endTimeMoment,
  );

  // 1) Format the date range -> "DD/MM/YYYY – DD/MM/YYYY"
  const formattedDateRange =
    intl?.locale === "en"
      ? `${formatMomentDate(adjustedStartDateTime)} – ${formatMomentDate(
          adjustedEndDateTime,
        )}`
      : `${formatMomentDate(adjustedEndDateTime)} – ${formatMomentDate(adjustedStartDateTime)}`;

  // 2) If both times exist, format them manually to localize AM/PM
  let formattedTimeRange = "";
  if (startTimeMoment && endTimeMoment) {
    // a) Numeric portion: "h:mm"
    const startTimeNumeric = adjustedStartDateTime.format("h:mm");
    const endTimeNumeric = adjustedEndDateTime.format("h:mm");

    // b) Extract "AM" / "PM"
    const startMeridiem = isAM(adjustedStartDateTime.format("A"))
      ? "hour_AM"
      : "hour_PM"; // "AM" or "PM"
    const endMeridiem = isAM(adjustedEndDateTime.format("A"))
      ? "hour_AM"
      : "hour_PM"; // "AM" or "PM"

    // c) Localize them using CheckValueLocale function
    const localizedStartMeridiem = CheckValueLocale(
      startMeridiem,
      "",
      {},
      intl,
    );
    const localizedEndMeridiem = CheckValueLocale(endMeridiem, "", {}, intl);

    // d) Build final time strings, e.g. "4:30 ص" and "11:00 م"
    const startTime = `${startTimeNumeric} ${localizedStartMeridiem}`;
    const endTime = `${endTimeNumeric} ${localizedEndMeridiem}`;

    // e) Combine into e.g. "4:30 ص – 11:00 م"
    formattedTimeRange = `${startTime} – ${endTime}`;
  }

  // 3) Return either just the date range, or date+time range separated by a comma
  //    e.g. "19/10/2024 – 15/01/2024, 4:30 ص – 11:00 م"
  return formattedTimeRange
    ? `${formattedDateRange}, ${formattedTimeRange}`
    : formattedDateRange;
}

export const isAM = (formatMeridiem) => {
  return ["am", "ص"].includes(formatMeridiem?.toLowerCase?.());
};

export const handleSidebarAvailableOptions = (sidebarOptions , minDate) => {
  const today = moment().startOf("day");
  const minDateDay = moment(minDate, "YYYY/MM/DD").startOf("day");
  const daysDifference = today.diff(minDateDay, "days");
  const availableOptions = 
    [...sidebarOptions]?.filter( option => !option?.daysAgoStart || option?.daysAgoStart <= daysDifference )
  return availableOptions
};