import AccountsLists from "../api/accountsList";

var AccountsListController = {};

AccountsListController.getAccountsList = (search, page_number, username) => {
  const queryData = {
    search,
    page_number,
    username,
  };
  return new Promise((resolve, reject) => {
    AccountsLists.getAccountsList(queryData)
      ?.then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

AccountsListController.editAccountsList = (id, queryData) => {
  return new Promise((resolve, reject) => {
    AccountsLists?.editAccountsList(id, queryData)
      ?.then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

AccountsListController.getAccountsListById = (id) => {
  return new Promise((resolve, reject) => {
    AccountsLists?.getAccountsListById(id)
      ?.then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

AccountsListController.deleteAccountsList = (id) => {
  return new Promise((resolve, reject) => {
    AccountsLists?.deleteAccountsList(id)
      ?.then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

//-----------------------

AccountsListController.createAccountsList = (queryData) => {
  return new Promise((resolve, reject) => {
    AccountsLists?.createAccountsList(queryData)
      ?.then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

AccountsListController.duplicateAccountsList = (id) => {
  return new Promise((resolve, reject) => {
    AccountsLists?.duplicateAccountsList(id)
      ?.then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

AccountsListController.updateUsernameLists = (username, account_list_ids) => {
  return new Promise((resolve, reject) => {
    AccountsLists?.updateUsernameLists(username, account_list_ids)
      ?.then((response) => {
        resolve({
          data: response?.data,
        });
      })
      ?.catch((error) => {
        reject({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AccountsListController.getUsernameAccountLists = (username, search) => {
  return new Promise((resolve, reject) => {
    AccountsLists?.getUsernameAccountLists(username, search)
      ?.then((response) => {
        resolve({
          data: response?.data,
        });
      })
      ?.catch((error) => {
        reject({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AccountsListController.getXlsxGuide = () => {
  return new Promise((resolve, reject) => {
    AccountsLists.getXlsxGuide()
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        reject({
          data: [],
          errorMsg: error,
        });
      });
  });
};

export default AccountsListController;
