import { useQuery } from "@tanstack/react-query";
import { CreateMonitorServices } from "services/api/createMonitorServices";

export const useGetNBMonitorData = (queryData) => {
  return useQuery({
    queryKey: ["nbSources", queryData],
    queryFn: async () => CreateMonitorServices.getNBMonitorData(queryData),
    select: (data) => data?.data,
  });
};
