import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Snackbar from "components/snackBar";
import PopupModal from "components/popupModal";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { Search } from "@mui/icons-material";
import {
  Grid,
  TextField,
  Skeleton,
  InputAdornment,
  IconButton,
  Box,
  Alert,
  Tab,
} from "@mui/material";
import "../../../../../socialListing/MonitorList/monitorsListPage.scss";
import SharedTable from "../../../../../../shared/table/sharedTable";
//Controllers
import { handlelUserRoles, CheckValueLocale } from "utils/helpers";
import { sortedColumnTypes, useSortableTable } from "hooks/useSortableTable";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ActivityLog from "./userActivityLog/activityLog";
import OptionsDropDown from "./components/optionsDropDown";
import PermissionBodyCell from "./components/permissionBodyCell";
import AvatarBodyCell from "./components/avatarBodyCell";
import TeamsBodyCell from "./components/teamsBodyCell";
import { FirstBodyCellLoading } from "./components/tableLoading";
import TableEmptyBody from "./components/tableEmptyBody";
import StateCell from "./components/stateCell";
import DateBodyCell from "./components/dateBodyCell";
import { useGetCompanyUsers } from "../../hooks/useGetCompanyUsers";
import { useDeactiveUser } from "../../hooks/useDeactiveUser";
import { useActiveUser } from "../../hooks/useActiveUser";
import { useAInviteUser } from "../../hooks/useAInviteUser";
import { useDeleteUser } from "../../hooks/useDeleteUser";
import { CustomPagination } from "components/CustomPagination/CustomPagination";

const UserManagementTable = (props) => {
  const { companyUsers, setCompanyUsers, setCompanyReachedMaxUser } = props;

  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const [tableBody, setTableBody] = useState([]);
  const [searched, setSearched] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(1);
  const [openPopup, setOpenPopup] = useState(false);
  const [success, setSuccess] = useState(null);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const skeletonArray = Array(10).fill("");
  const [tabValue, setTabValue] = useState("userList");

  const [stats, setStats] = useState({
    user_id: null,
    user_name: "",
    anchorEl: null,
    openDeleteModal: false,
    modalTitle: "",
    modalBody: "",
    modalRightBtn: "",
    modalLeftBtn: "",
    index: "",
    page: "",
  });
  const [pagination, setPagination] = useState(1);
  const [companyActiveProduct, setCompanyActiveProduct] = useState([]);
  const [openUpgradeSnackBar, setOpenUpgradeSnackBar] = useState(false);
  const [snackBarData, setSnackBarData] = useState({
    message: "",
    severity: "",
    title: "",
    openSnackBar: false,
  });
  const handleCloseSnackBar = () => {
    setSnackBarData({
      openSnackBar: false,
    });
  };
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const onSortChangeCallback = () => {
    setPage(1);
  };
  const {
    columns: tableHead,
    sortColumn,
    sortDirection,
    isSortableLoading,
  } = useSortableTable({
    initialColumns: [
      {
        body: CheckValueLocale("user_info", "", {}, intl),
        className: "first user-management-table-data",
        sx: { width: "200px", textAlign: "left" },
        name: "name",
        type: sortedColumnTypes?.string,
      },
      {
        body: CheckValueLocale("permission", "", {}, intl),
        className: "user-management-table-data",
        sx: { textAlign: "left", width: "36%", paddingLeft: "0px" },
      },
      {
        body: CheckValueLocale("teams", "", {}, intl),
        className: "user-management-table-data",
        sx: { textAlign: "left", paddingLeft: "0px" },
      },
      {
        body: CheckValueLocale("two_factor_auth", "", {}, intl),
        className: "user-management-table-data",
        sx: { textAlign: "left", paddingLeft: "0px" },
        name: "has_enabled_2fa", // need to check with back-end to sort
        type: sortedColumnTypes?.string,
      },
      {
        body: CheckValueLocale("last_seen", "", {}, intl),
        className: "user-management-table-data",
        sx: { textAlign: "left", width: "150px", paddingLeft: "1px" },
        name: "last_seen",
        type: sortedColumnTypes?.date,
      },
      {
        body: CheckValueLocale("status", "", {}, intl),
        className: "user-management-table-data",
        sx: { textAlign: "left", width: "120px", paddingLeft: "1px" },
        name: "status",
        type: sortedColumnTypes?.string,
      },
      {
        body: null,
        permission:
          handlelUserRoles("GENERAL", "EDIT_USER") &&
          handlelUserRoles("GENERAL", "DESTROY_USER"),
        className: "user-management-table-data",
        sx: { textAlign: "center", width: "100px" },
      },
    ],
    onSortChangeCallback,
  });

  const handleCloseDeleteModal = () => {
    setStats({ openDeleteModal: false });
  };

  const handleCloseUpgradeSnackBar = () => {
    setOpenUpgradeSnackBar(false);
  };
  const handleChangePage = (event, page) => {
    if (Boolean(page) === false) return;
    setPage(page);
  };
  const hanldeSearchIconClick = () => {
    setTabValue("userList");
    setSearchInput(searched);
    if (page !== 1) {
      setPage(1);
    }
  };

  // Delete Authorized Account
  const handleDeleteUserAccount = (id, index, page, name) => {
    setOpenPopup(true);
    setStats({
      user_id: id,
      user_name: name,
      openDeleteModal: true,
      modalTitle: CheckValueLocale("remove_user", "", {}, intl),
      modalBody: (
        <Box className="shared-dialog-body">
          <p>{`${CheckValueLocale("delete_user_account", "", { name }, intl)}`}</p>
          <h2 className="sub-text-desc">
            {CheckValueLocale("deleting_msg", "", {}, intl)}
          </h2>
        </Box>
      ),

      modalRightBtn: CheckValueLocale("delete_confirm", "", {}, intl),
      modalLeftBtn: CheckValueLocale("cancel", "", {}, intl),
      index: index,
      page: page,
    });
  };

  // Deactivate User
  const { mutate: handleDeactiveUser, isPending: isDeactiveUserPending } =
    useDeactiveUser();
  const deactiveUser = (userId) => {
    handleDeactiveUser(
      { userId },
      {
        onSuccess: () => {
          setSuccessMsg("user_deactivation_success");
          setSuccess(true);
        },
        onError: () => {
          setErrorMsg("user_deactivation_failed");
          setSuccess(false);
        },
      },
    );
  };

  // Activate User
  const { mutate: handleActiveUser, isPending: isActiveUserPending } =
    useActiveUser();
  const activeUser = async (userId) => {
    handleActiveUser(
      { userId },
      {
        onSuccess: () => {
          setSuccessMsg("user_activation_success");
          setSuccess(true);
        },
        onError: () => {
          setErrorMsg("user_activation_failed");
          setSuccess(false);
        },
      },
    );
  };

  // Send User Invitation
  const { mutate: handleInviteUser, isPending: isInviteUserPending } =
    useAInviteUser();
  const inviteUser = async (userId) => {
    handleInviteUser(
      { userId },
      {
        onSuccess: () => {
          setSuccessMsg("user_invitation_success");
          setSuccess(true);
        },
        onError: () => {
          setErrorMsg("user_invitation_failed");
          setSuccess(false);
        },
      },
    );
  };

  // Delete User
  const { mutate: handleDeleteUser, isPending: isDeleteUserPending } =
    useDeleteUser();
  const deleteUser = () => {
    const userId = stats?.user_id;
    handleDeleteUser(
      { userId },
      {
        onSuccess: () => {
          handleCloseDeleteModal();
          setSuccessMsg(
            intl.formatMessage(
              { id: "user_deletion_success" },
              {
                monitorname: CheckValueLocale(stats?.user_name, "", {}, intl),
              },
            ),
          );
          setSuccess(true);
        },
        onError: () => {
          setErrorMsg("user_deletion_failed");
          setSuccess(false);
        },
      },
    );
  };

  //Change Handler
  const changeHandler = (e) => {
    setSearched(e.target.value);
  };

  const {
    data: resultCompanyUsers,
    isPending: isCompanyUsersPending,
    isError: isErrorCompanyUsers,
  } = useGetCompanyUsers({
    search: searchInput,
    page,
    sort_by: sortColumn,
    order_by: sortDirection,
  });

  // handle response of from useGetCompanyUsers
  useEffect(() => {
    if (!isErrorCompanyUsers && !!resultCompanyUsers) {
      if (resultCompanyUsers?.flags?.includes("USERS_COUNT_NOT_ENOUGH")) {
        setCompanyReachedMaxUser(true);
        setOpenUpgradeSnackBar(true);
      } else {
        setCompanyReachedMaxUser(false);
        setOpenUpgradeSnackBar(false);
      }

      setCompanyUsers(resultCompanyUsers?.data);
      setPagination(resultCompanyUsers?.pagination);
    } else if (isErrorCompanyUsers) {
      setCompanyUsers([]);
      setPagination(1);
      setErrorMsg("connection_failed");
      setSuccess(false);
    }
  }, [resultCompanyUsers, isErrorCompanyUsers]);

  useEffect(() => {
    const activeProducts = window.localStorage?.activeProducts
      ? JSON.parse(window.localStorage?.activeProducts)
      : [];
    setCompanyActiveProduct(
      activeProducts
        ?.filter((product) => product?.active)
        ?.map((val) => val?.name),
    );
  }, []);

  useEffect(() => {
    // if the search has a created_successfully or updated_successfully message status,
    // then show a snackbar with the message
    const search = location?.search;
    let snackbarSuccessMessage;
    if (search && search?.includes("status=deleted_successfully")) {
      snackbarSuccessMessage = "user_deleted_success";
    }

    if (snackbarSuccessMessage) {
      // show the message and then remove it from the url
      setSnackBarData({
        message: snackbarSuccessMessage,
        severity: "success",
        openSnackBar: true,
      });
      navigate(window.location?.pathname);
    }
  }, []);

  const optionsBodyCell = (user, index) => {
    return (
      <OptionsDropDown
        status={user?.attributes?.status}
        open={false}
        user={user}
        id={user?.attributes?.id}
        name={user?.attributes?.name}
        index={index}
        page={page}
        openDeleteModal={handleDeleteUserAccount}
        deactiveUser={deactiveUser}
        activeUser={activeUser}
        inviteUser={inviteUser}
        disabledOptions={
          isDeactiveUserPending ||
          isActiveUserPending ||
          isInviteUserPending ||
          isDeleteUserPending
        }
        component={"userManagement"}
      />
    );
  };

  useEffect(() => {
    const tabBody = isCompanyUsersPending
      ? skeletonArray?.map((item, index) => {
          return {
            id: `skeleton-${index}`,
            data: [
              {
                body: <FirstBodyCellLoading />,
              },
              {
                body: <Skeleton />,
              },
              {
                body: <Skeleton />,
              },
              {
                body: <Skeleton />,
              },
              {
                body: <Skeleton />,
              },
              {
                body: <Skeleton />,
              },
              {
                body: <Skeleton />,
              },
            ],
          };
        })
      : companyUsers?.length == 0
        ? []
        : companyUsers?.map((item, index) => {
            return {
              id: `users-${index}`,
              data: [
                {
                  body: <AvatarBodyCell user={item} />,
                },
                {
                  body: (
                    <PermissionBodyCell
                      companyActiveProduct={companyActiveProduct}
                      user={item}
                      index={index}
                    />
                  ),
                },
                {
                  body: <TeamsBodyCell user={item} />,
                },
                {
                  body: (
                    <Box>
                      {CheckValueLocale(
                        item?.attributes?.has_enabled_2fa
                          ? "enabled"
                          : "disabled",
                        "",
                        {},
                        intl,
                      )}
                    </Box>
                  ),
                },
                {
                  body: <DateBodyCell user={item} />,
                },
                {
                  body: <StateCell user={item} />,
                },
                {
                  body: optionsBodyCell(item, index),
                  permission:
                    handlelUserRoles("GENERAL", "EDIT_USER") &&
                    handlelUserRoles("GENERAL", "DESTROY_USER"),
                },
              ],
            };
          });
    setTableBody(tabBody);
  }, [
    companyUsers,
    page,
    intl.locale,
    isCompanyUsersPending,
    isActiveUserPending,
    isDeactiveUserPending,
    isInviteUserPending,
    isDeleteUserPending,
  ]);

  return (
    <Box>
      {openUpgradeSnackBar && (
        <Alert
          onClose={handleCloseUpgradeSnackBar}
          severity={"info"}
          className="upgrade-snackbar-alert"
        >
          <Box id="settings-user-management-upgrate-request-link">
            {CheckValueLocale("reached_max_num_users", "", {}, intl)}
            <Link to="/settings/subscription">
              {CheckValueLocale("reached_max_num_subscription", "", {}, intl)}
            </Link>
          </Box>
        </Alert>
      )}
      <Grid container spacing={2} mb={2}>
        <Grid item xs={6}>
          {(companyUsers?.length > 0 || searchInput) &&
          tabValue === "userList" ? (
            <TextField
              placeholder={CheckValueLocale(
                "search_by_username_placeholder",
                "",
                {},
                intl,
              )}
              onInput={changeHandler}
              onPaste={changeHandler}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  hanldeSearchIconClick();
                }
              }}
              name="searched"
              value={searched}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={hanldeSearchIconClick}>
                      <Search id="settings-user-management-upgrate-user-name-search-icon" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              id="settings-user-management-upgrate-user-name-search-input"
              className="users-search-input"
            />
          ) : null}
        </Grid>
      </Grid>
      <TabContext value={tabValue}>
        <Box className="tab-content-container">
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab
              label={CheckValueLocale("user_list", "", {}, intl)}
              value="userList"
            />

            <Tab
              label={CheckValueLocale("activity_log", "", {}, intl)}
              value="activityLog"
            />
          </TabList>
        </Box>
        <TabPanel value="userList" className="tab-panel-container">
          {handlelUserRoles("GENERAL", "VIEW_USER") && (
            <>
              <SharedTable
                className={" "}
                headClassName={"table-head"}
                tableAriaLabel={"customized table"}
                tableHeader={tableHead}
                tableBody={tableBody}
                isSharedStyle
                emptyBody={
                  <TableEmptyBody
                    searchInput={searchInput}
                    companyUsers={companyUsers}
                  />
                }
                loading={isSortableLoading}
              />
            </>
          )}
        </TabPanel>
        <TabPanel className="tab-panel" value="activityLog">
          <ActivityLog />
        </TabPanel>
      </TabContext>

      {pagination?.pages > 1 &&
      !isSortableLoading &&
      tabValue !== "activityLog" ? (
        <CustomPagination
          onChange={handleChangePage}
          page={page}
          pagination={pagination}
        />
      ) : null}
      {openPopup ? (
        <PopupModal
          title={stats?.modalTitle}
          body={stats?.modalBody}
          leftBtn={stats?.modalLeftBtn}
          rightBtn={stats?.modalRightBtn}
          open={stats?.openDeleteModal}
          close={handleCloseDeleteModal}
          accept={deleteUser}
          warning
          minWidth="600px"
          classeName="actions-buttons-shared"
        />
      ) : (
        ""
      )}
      {success != null && (
        <Snackbar
          open={success != null ? true : false}
          handleClose={() => {
            setSuccess(null);
          }}
          severity={success ? "success" : "error"}
          message={
            success ? (
              CheckValueLocale(successMsg, "", {}, intl)
            ) : (
              <Box>
                <Box
                  component={"span"}
                  className="error-message-actions-user-page"
                >
                  {CheckValueLocale(errorMsg, "", {}, intl)}
                </Box>
                <Box component={"span"}>
                  <br />
                  {CheckValueLocale("try_again", "", {}, intl)}
                </Box>
              </Box>
            )
          }
        />
      )}
      <Snackbar
        open={snackBarData?.openSnackBar}
        severity={snackBarData?.severity}
        message={CheckValueLocale(snackBarData?.message, "", {}, intl)}
        title={CheckValueLocale(snackBarData?.title, "", {}, intl)}
        handleClose={handleCloseSnackBar}
      />
    </Box>
  );
};

export default UserManagementTable;
