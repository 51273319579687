import React, { useState } from "react";
import { Box, TextField } from "@mui/material";

const EmailQuestion = (props) => {
  const { previewMode } = props;

  const [answer, setAnswer] = useState("");

  const handleAnswers = (e) => setAnswer(e.target.value);

  return (
    <Box className="survey-builder-question-email">
      <TextField
        id="survey-builder-question-email-input"
        className="survey-builder-question-email-input"
        variant="standard"
        placeholder="name@example.com"
        value={answer}
        onChange={previewMode ? handleAnswers : null}
      />
    </Box>
  );
};

export default EmailQuestion;
