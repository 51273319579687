import { useMutation } from "@tanstack/react-query";
import SettingsController from "services/controllers/settingsController";

export const useResendVerificationCode = () => {
  return useMutation({
    mutationFn: () => {
      return SettingsController?.resendVerificationCode();
    },
  });
};
