import { Box, Card } from "@mui/material";
import { useContext } from "react";
import "./style.scss";
import HeaderArticleCard from "./components/headerArticleCard";
import BodyArticleCard from "./components/bodyArticleCard";
import FooterArticleCard from "./components/footerArticleCard";
import { ReportContext } from "../../context/reportContext";
import { truncate } from "utils/helpers";
import { useIntl } from "react-intl";
import NoDataFoundProducts from "components/no-Data";

const ArticlesCard = () => {
  const intl = useIntl();
  const { activeMonitor } = useContext(ReportContext);
  const { articles } = activeMonitor;

  const getStatisticArticle = (article) => {
    // if old report don't have statistic and i will not show in ui
    if (article?.comments_count === undefined) {
      return undefined;
    }

    // if new report have statistic and i will show in ui
    return {
      comments_count: article?.comments_count,
      views_count: article?.alexa_pageviews,
      engagement_count: article?.total_engagement,
      reach_count: article?.reach,
    };
  };

  return (
    <Box className="articles-container-cards" id="articles-container-cards">
      {articles?.length ? (
        articles?.map((article, index) => (
          <Card
            key={index}
            className="article-card"
            id={`article-card-${index}`}
          >
            <HeaderArticleCard
              title={article?.title}
              publisher={article?.publication_name}
              publisherImage={article?.publication_image_url || null}
              articleLink={article?.article_url}
            />

            <BodyArticleCard
              mediaUrls={
                article?.article_image_url && [
                  {
                    url: article?.article_image_url,
                  },
                ]
              }
              tweetText={truncate(article?.content, 700)}
            />

            <FooterArticleCard
              countryName={
                intl?.locale == "ar"
                  ? article?.country_ar || article?.country
                  : article?.country
              }
              language={intl?.locale == "ar" ? article?.lang_ar : article?.lang}
              publishDate={article?.published}
              statisticArticle={getStatisticArticle(article)}
              sentimentValue={article?.sentiment}
            />
          </Card>
        ))
      ) : (
        <Box className="repport-empty-case">
          <NoDataFoundProducts
            image="Monitor"
            title="no_results_found"
            description="no_articles_found_description"
          />
        </Box>
      )}
    </Box>
  );
};

export default ArticlesCard;
