import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  Box,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { CheckValueLocale } from "utils/helpers";

const SectionFooter = (props) => {
  const {
    section,
    sectionIndex,
    filteredSections,
    surveySections,
    setSurveySections,
    afterSecError,
  } = props;

  const intl = useIntl();
  const [afterSecSelects, setAfterSecSelects] = useState([]);

  useEffect(() => {
    if (filteredSections) {
      let selectsArray = [];
      filteredSections?.map((sec, index) => {
        if (index !== sectionIndex && index !== sectionIndex + 1)
          selectsArray?.push(sec);
      });
      setAfterSecSelects(selectsArray);
    }
  }, [filteredSections?.length, sectionIndex]);

  const MenuProps = {
    anchorOrigin: { vertical: "top", horizontal: "left" },
    transformOrigin: { vertical: "bottom", horizontal: "left" },
    PaperProps: { style: { maxHeight: 265 } },
    getContentAnchorEl: null,
  };

  const handleAfterSectionChange = (e) => {
    filteredSections[sectionIndex].next_section = e.target.value;
    setSurveySections([...surveySections]);
  };

  return (
    <Box id={`section-footer-${sectionIndex}`} className="section-footer">
      <Typography component="p" className="after-section-title">
        {CheckValueLocale("after_section", "", {}, intl)} {sectionIndex + 1}
      </Typography>
      <FormControl error={afterSecError === sectionIndex && true}>
        <Select
          id={`after-section-${sectionIndex}-select`}
          className={`after-section-select ${
            afterSecError !== sectionIndex && `no-error`
          }`}
          variant="standard"
          MenuProps={MenuProps}
          onChange={(e) => handleAfterSectionChange(e)}
          value={section?.next_section}
        >
          {sectionIndex !== filteredSections?.length - 1 && (
            <MenuItem value={section?.order + 1}>
              {`${CheckValueLocale("continue_next_section", "", {}, intl)} (${
                filteredSections[sectionIndex + 1]?.name
              })`}
            </MenuItem>
          )}
          <MenuItem value={-1}>
            {CheckValueLocale("submit_survey", "", {}, intl)}
          </MenuItem>
          {afterSecSelects?.map((section, i) => {
            return (
              <MenuItem key={i} value={section?.order}>
                {`${CheckValueLocale("go_to_section", "", {}, intl)} ${
                  section?.order
                } (${section?.name})`}
              </MenuItem>
            );
          })}
        </Select>
        {afterSecError === sectionIndex && (
          <FormHelperText className="error-msg">
            {CheckValueLocale("after_section_select_error_msg", "", {}, intl)}
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
};

export default SectionFooter;
