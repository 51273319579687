import { CheckValueLocale } from "utils/helpers";
import { Box, Typography } from "@mui/material";
import LucButton from "shared/lucButton/lucButton";
import { useIntl } from "react-intl";
import AddIcon from "@mui/icons-material/Add";
import "./createListButton.scss";
import { useNavigate } from "react-router-dom";

const createListButton = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  return (
    <Box className="manage-user-account-list-create-button">
      <LucButton
        onClick={() => navigate("/accounts-list/list")}
        variant="text"
        startIcon={<AddIcon />}
        className="create-list-button"
      >
        {CheckValueLocale("create_new_list", "", {}, intl)}
      </LucButton>
      <Typography className="empty-list-message">
        {CheckValueLocale("create_new_list_empty_message", "", {}, intl)}
      </Typography>
    </Box>
  );
};

export default createListButton;
