import { Box } from "@mui/material";
import DataSourceContent from "./dataSourceContent";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";

const XPlatformDataSource = ({
  getSocialIcon,
  resetAllFields,
  response,
  preLoaderTrackerDataSources,
  unixDate,
  singleTweetAnalysis,
  xPlatformWidgetTypes,
  setXPlatformWidgetTypes,
  sortWidgets,
  getNameKeyWidget,
  showEditBtn,
  setTotalStatisticWidgets,
  setLoadingStatisticWidgets,
  themesOptions,
}) => {
  const intl = useIntl();
  return (
    <>
      <Box className="live-dashboard-data-source">
        <Box className="data-source-heading">
          {CheckValueLocale("TWITTER", "", {}, intl)}
          {getSocialIcon("TWITTER")}
        </Box>
        <DataSourceContent
          resetAllFields={resetAllFields}
          response={response}
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          unixDate={unixDate}
          singleTweetAnalysis={singleTweetAnalysis}
          xPlatformWidgetTypes={xPlatformWidgetTypes}
          setXPlatformWidgetTypes={setXPlatformWidgetTypes}
          sortWidgets={sortWidgets}
          getNameKeyWidget={getNameKeyWidget}
          showEditBtn={showEditBtn}
          setTotalStatisticWidgets={setTotalStatisticWidgets}
          setLoadingStatisticWidgets={setLoadingStatisticWidgets}
          themesOptions={themesOptions}
        />
      </Box>
    </>
  );
};
export default XPlatformDataSource;
