import { Box, Container, Tab, Tabs } from "@mui/material";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import "./singleTweetAnalysis.scss";
import { SinglePostAnalysisOverviewSection } from "./components/singlePostAnalysisOverviewSection";
import { useSingleTweetAnalysisData } from "./hooks/useSingleTweetAnalysisData";
import mqttRabbitMQResponse from "services/controllers/mqttRabbitMQResponce";
import { useEffect, useReducer, useRef, useState } from "react";
import { CheckValueLocale } from "utils/helpers";
import { AnalyticsView } from "./components/analyticsView";
import { CommentsView } from "./components/commentsView";
import { SPATapsData, TAPS_TYPE } from "./utils/taps";
import CircularLoading from "components/circularProgress";
import {
  analyticsReducer,
  initialState,
} from "./utils/singleTweetAnalysisReducer";
import { handleSingleTweetAnalysisResponse } from "./utils/handleSingleTweetAnalysisResponse";

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const SingleTweetAnalysisRevamp = () => {
  const intl = useIntl();
  const { id } = useParams();
  const clientMQTT = useRef();
  const [response, setResponse] = useState({});
  const [preLoaderTrackerDataSources, setPreLoaderTrackerDataSources] =
    useState([]);
  const [selectedTab, setSelectedTab] = useState(TAPS_TYPE.ANALYTICS);
  const [page, setPage] = useState(1);
  const {
    data: singleDetailsData,
    isPending: isSPADataPending,
    isFetching: isSPADataFetching,
    isError: isSPADataError,
  } = useSingleTweetAnalysisData(id);
  const [filterParams, setFilterParams] = useState({});

  const startDate = singleDetailsData?.data?.start_date;
  const endDate = singleDetailsData?.data?.end_date;
  const monitorID = singleDetailsData?.data?.monitor_id;

  useEffect(() => {
    if (singleDetailsData) {
      setPreLoaderTrackerDataSources([
        parseInt(singleDetailsData?.data?.monitor_id),
      ]);
      mqttRabbitMQResponse(clientMQTT, singleDetailsData?.data, setResponse);
    }
  }, [singleDetailsData]);

  const monitorName = singleDetailsData?.data?.monitor_name;
  const isLoading = isSPADataPending || isSPADataError || isSPADataFetching;

  const [state, dispatch] = useReducer(analyticsReducer, initialState);

  useEffect(() => {
    handleSingleTweetAnalysisResponse(response, dispatch);
  }, [response]);

  useEffect(() => {
    if (isSPADataFetching) {
      dispatch({ type: "RESET_SENTIMENT_ANALYSIS_PRELOADER" });
    }
  }, [isSPADataFetching]);

  return isLoading ? (
    <CircularLoading />
  ) : (
    <Container maxWidth="xl" className="spa-main-container">
      <SinglePostAnalysisOverviewSection
        response={response}
        preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        monitorName={monitorName}
        monitorID={monitorID}
      />
      <Box className="spa-tabs">
        <Tabs
          value={selectedTab}
          onChange={(_, newValue) => {
            setSelectedTab(newValue);
          }}
        >
          {SPATapsData.map((tab) => (
            <Tab
              key={tab.value}
              label={CheckValueLocale(tab.label, "", {}, intl)}
              value={tab.value}
            />
          ))}
        </Tabs>
        <CustomTabPanel value={selectedTab} index={TAPS_TYPE.ANALYTICS}>
          <AnalyticsView
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
            state={state}
          />
        </CustomTabPanel>
        <CustomTabPanel value={selectedTab} index={TAPS_TYPE.COMMENTS}>
          <CommentsView
            startDate={startDate}
            endDate={endDate}
            setPage={setPage}
            page={page}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            monitorID={monitorID}
          />
        </CustomTabPanel>
      </Box>
    </Container>
  );
};

export default SingleTweetAnalysisRevamp;
