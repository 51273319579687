import { useIntl } from "react-intl";
import { Box, FormControlLabel, Switch, Tooltip } from "@mui/material";
import rocket from "images/summary-widget/emoji-rocket-emoji.svg";
import sparkles from "images/summary-widget/emoji-sparkles.svg";
import { CheckValueLocale } from "utils/helpers";
import "./addAutoSummaryWidget.scss";
import Services from "services/api/Services";

const AddAutoSummaryWidget = ({
  setWidgetData,
  widgetData,
  monitorSettings,
  summaryTokens,
}) => {
  const intl = useIntl();
  return (
    <Box
      className={
        monitorSettings
          ? "main-settings-summary-wrapper"
          : "main-add-summary-wrapper"
      }
    >
      <Box
        className={
          monitorSettings ? "main-settings-heading" : "main-add-heading"
        }
        component="p"
      >
        {CheckValueLocale("auto_summary_widget", "", {}, intl)}
        <Box component="span" className="optional-style">
          ({CheckValueLocale("optional", "", {}, intl)})
        </Box>
      </Box>
      <Box className="add-summary-wrapper">
        <Box className="add-summary-heading">
          <Box className="add-heading" component="h5">
            {CheckValueLocale("summary_by_luci", "", {}, intl)}
          </Box>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={widgetData?.auto_summary_widget}
                onChange={(e) =>
                  monitorSettings
                    ? setWidgetData({
                        ...widgetData,
                        auto_summary_widget: !widgetData?.auto_summary_widget,
                        updated: true,
                      })
                    : setWidgetData({
                        ...widgetData,
                        auto_summary_widget: !widgetData?.auto_summary_widget,
                      })
                }
              />
            }
          />
        </Box>
        <Box className="add-summary-description" component="p">
          <Box component="span">
            {CheckValueLocale("unlock_powerful_summaries", "", {}, intl)}{" "}
            <img src={rocket} alt="rocket" />
            <img src={sparkles} alt="sparkles" />
          </Box>
          {CheckValueLocale("summary_widget_utilizes", "", {}, intl)}
        </Box>
        <Box className="add-summary-hint">
          <Tooltip
            title={
              <Box className="add-summary-tooltip" component="span">
                {CheckValueLocale(
                  "energy_tokens_to_be_automatically",
                  "",
                  {},
                  intl,
                )}
              </Box>
            }
            placement="top"
            arrow
          >
            <Box className="add-hint" component="span">
              {CheckValueLocale("estimated_energy", "", {}, intl)}:
            </Box>
          </Tooltip>
          <Box className="no-hint" component="span">
            {summaryTokens || "No"}
          </Box>
          <img src={Services?.tokensIconBlue} alt="tokensIcon" />
        </Box>
      </Box>
    </Box>
  );
};

export default AddAutoSummaryWidget;
