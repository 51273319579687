import { Box } from "@mui/material";
import FeatureOptionBody from "./featureOptionBody";

const OptionsCard = (props) => {
  return (
    <Box className="security-page-sections-container">
      <Box className="security-page-section-box">
        <FeatureOptionBody
          featureName={props?.featureName}
          featureDescription={props?.featureDescription}
          openMethodPopup={props?.openEnable}
          isAvailable={props?.isAvailable}
          buttonTitle={"activate_two"}
          disabled={props?.disabled}
        />
      </Box>
    </Box>
  );
};
export default OptionsCard;
