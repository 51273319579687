import { isValidPhoneNumber } from "libphonenumber-js";
import * as Yup from "yup";

const emailRegex =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

const rexSpecialChar = /^[\u0621-\u064A0-9a-zA-Z0-9-_ ]+$/;

export const userFormSchema = Yup.object().shape({
  first_name: Yup?.string()
    ?.trim()
    ?.required("this_field_required")
    ?.max(30, "Name_must_be_under_30_characters")
    ?.matches(rexSpecialChar, "please_avoid_using_special_characters"),
  last_name: Yup?.string()
    ?.trim()
    ?.required("this_field_required")
    ?.max(30, "Name_must_be_under_30_characters")
    ?.matches(rexSpecialChar, "please_avoid_using_special_characters"),
  mail: Yup?.string()
    ?.required("this_field_required")
    ?.matches(emailRegex, "please_enter_a_valid_email_address")
    ?.email("please_enter_a_valid_email_address"),
  phone: Yup?.string()?.test(
    "isValidPhone",
    "invalid_phone_number",
    (value) => {
      if (!value) return true;
      return isValidPhoneNumber(value);
    },
  ),
  job_title: Yup?.string()?.matches(
    rexSpecialChar,
    "please_avoid_using_special_characters",
  ),
});
