import { useMutation } from "@tanstack/react-query";
import { SummaryWidgetServices } from "services/api/summaryWidgetService";

export const useSummaryStaticQuestions = () => {
  return useMutation({
    mutationFn: ({ queryData }) =>
      SummaryWidgetServices.getSummaryStaticQuestions({
        queryData,
      }),
  });
};
