import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ReportServices } from "services/api/reports";

export const useDeleteReport = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id }) => ReportServices.deleteReport(id),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["reportsList"] });
    },
  });
};
