import { useState, useEffect } from "react";
import OverViewSection from "./components/overviewSection";
import AuthorAnalyticsSection from "./components/authorAnalyticsSection";
import DashboardFunctions from "../../../dashboardFunctions";
import CommonFunctions from "pages/Monitors/Components/commonFunctions";
import SnackBar from "components/snackBar";
import { CheckValueLocale } from "utils/helpers/index.js";
import { useIntl } from "react-intl";
import PopupModal from "components/popupModal";

const EngagementsBody = (props) => {
  const intl = useIntl();
  const { getIcons } = DashboardFunctions();
  let {
    dashboardDynamicData,
    widgetsName,
    monitorType,
    dashboardID,
    insightsID,
    insightsResponse,
    dates,
    response,
    singleTweetAnalysis,
    startDate,
    endDate,
    unixDate,
  } = props;

  const metricsValues = dashboardDynamicData?.metrics_values;

  const isKeywordType = monitorType === "KEYWORD";
  let widgetsTypeTitle = isKeywordType ? "posts" : "comments";

  const {
    updateAnalysis,
    handleCloseSnackBar,
    showAnalyzeTweetSnackBar,
    analyzeTweetSnackBar,
    stats,
    handleCloseCreateModal,
    modalBody,
  } = CommonFunctions();

  const [volumeOvertime, setVolumeOvertime] = useState([]);
  const [totalVolumeOvertimeCount, setTotalVolumeOvertimeCount] = useState(0);
  const [overView, setOverView] = useState([]);
  const [interactions, setInteractions] = useState([]);
  const [interactionsLegend, setInteractionsLegend] = useState([]);
  const [sentimentAnalysis, setSentimentAnalysis] = useState([]);
  const [negativeArray, setNegativeArray] = useState([]);
  const [positiveArray, setPositiveArray] = useState([]);
  const [neutralArray, setNeutralArray] = useState([]);
  const [sentimentPieChart, setSentimentPieChart] = useState([]);
  const [topVideos, setTopVideos] = useState([]);
  const [topImages, setTopImages] = useState([]);
  const [topKeywords, setTopKeywords] = useState([]);
  const [topHashtags, setTopHashtags] = useState([]);
  const [associatedTopics, setAssociatedTopics] = useState([]);
  const [contentStyles, setContentStyles] = useState([]);
  const [contentType, setContentType] = useState([]);
  const [topLanguages, setTopLanguages] = useState([]);
  const [dialects, setDialects] = useState([]);
  const [subDialects, setSubDialects] = useState([]);
  const [topCities, setTopCities] = useState([]);
  const [topCountries, setTopCountries] = useState([]);
  const [genderDistribution, setGenderDistribution] = useState([]);
  const [genderPieChart, setGenderAnalysisPieChart] = useState([]);
  const [accountTypes, setAccountTypes] = useState([]);
  const [topSources, setTopSources] = useState([]);
  const [topVerifiedEngagers, setTopVerifiedEngagers] = useState([]);
  const [topEngagers, setTopEngagers] = useState([]);
  const [topInfleuncers, setTopInfleuncers] = useState([]);
  const [articlesEngagements, setArticlesEngagements] = useState([]);
  const [reactions, setReactions] = useState([]);
  const [pageReactionPieChartValue, setPageReactionPieChartValue] = useState(
    [],
  );
  const [reachFunnel, setReachFunnel] = useState([]);
  const [topEngaged, setTopEngaged] = useState([]);
  const [topSites, setTopSites] = useState([]);
  const [twTopPosts, setTwTopPosts] = useState([]);
  const [igTopPosts, setIgTopPosts] = useState([]);
  const [fbTopPosts, setFbTopPosts] = useState([]);
  const [nbTopPosts, setNbTopPosts] = useState([]);
  const [topPostsCount, setTopPostsCount] = useState(0);
  const [engagersVolume, setEngagersVolume] = useState([]);
  const [totalEngagersVolumeCount, setTotalEngagersVolumeCount] = useState([]);
  const [genderAgeDistribution, setGenderAgeDistribution] = useState([]);
  const [genderAgePieChart, setGenderAgePieChart] = useState([]);
  const [minValueAuthorActivity, setMinValueAuthorActivity] = useState(0);
  const [maxValueAuthorActivity, setMaxValueAuthorActivity] = useState(0);
  const [accountAuthorActivity, setAccountAuthorActivity] = useState([]);
  const [commentsMentions, setCommentsMentions] = useState([]);
  const [commentsMentionsCount, setCommentsMentionsCount] = useState(0);
  const [reachFunnelLegacy, setReachFunnelLegacy] = useState([]);
  const [twTopImages, setTwTopImages] = useState([]);
  const [fbTopImages, setFbTopImages] = useState([]);
  const [igTopImages, setIgTopImages] = useState([]);
  const [twTopVideos, setTwTopVideos] = useState([]);
  const [fbTopVideos, setFbTopVideos] = useState([]);
  const [igTopVideos, setIgTopVideos] = useState([]);
  const [topAuthors, setTopAuthors] = useState([]);

  //preloader
  const [volumeOvertimePreLoader, setVolumeOvertimePreLoader] = useState([]);
  const [overViewPreLoader, setOverViewPreLoader] = useState([]);
  const [interactionsPreLoader, setInteractionsPreLoader] = useState([]);
  const [sentimentAnalysisPreLoader, setSentimentAnalysisPreLoader] = useState(
    [],
  );
  const [topKeywordsPreLoader, setTopKeywordsPreLoader] = useState([]);
  const [topHashtagsPreLoader, setTopHashtagsPreLoader] = useState([]);
  const [associatedTopicsPreLoader, setAssociatedTopicsPreLoader] = useState(
    [],
  );
  const [contentStylesPreLoader, setContentStylesPreLoader] = useState([]);
  const [contentTypePreLoader, setContentTypePreLoader] = useState([]);
  const [topLanguagesPreLoader, setTopLanguagesPreLoader] = useState([]);
  const [dialectsPreLoader, setDialectsPreLoader] = useState([]);
  const [topCitiesPreLoader, setTopCitiesPreLoader] = useState([]);
  const [topCountriesPreLoader, setTopCountriesPreLoader] = useState([]);
  const [genderDistributionPreLoader, setGenderDistributionPreLoader] =
    useState([]);
  const [accountTypesPreLoader, setAccountTypesPreLoader] = useState([]);
  const [topSourcesPreLoader, setTopSourcesPreLoader] = useState([]);
  const [topVerifiedEngagersPreLoader, setTopVerifiedEngagersPreLoader] =
    useState([]);
  const [topEngagersPreLoader, setTopEngagersPreLoader] = useState([]);
  const [topInfleuncersPreLoader, setTopInfleuncersPreLoader] = useState([]);
  const [commentsMentionsPreloader, setCommentsMentionsPreloader] = useState(
    [],
  );
  const [articlesEngagementsPreloader, setArticlesEngagementsPreloader] =
    useState([]);
  const [reactionsPreLoader, setReactionsPreLoader] = useState([]);
  const [reachFunnelPreLoaderDataSources, setReachFunnelPreLoaderDataSources] =
    useState([]);
  const [topEngagedPreLoader, setTopEngagedPreLoader] = useState([]);
  const [topSitesPreLoader, setTopSitesPreLoader] = useState([]);
  const [twTopPostsPreloader, setTwTopPostsPreloader] = useState([]);
  const [igTopPostsPreloader, setIgTopPostsPreloader] = useState([]);
  const [fbTopPostsPreloader, setFbTopPostsPreloader] = useState([]);
  const [nbTopPostsPreloader, setNbTopPostsPreloader] = useState([]);
  const [twTopImagesPreLoader, setTwTopImagesPreLoader] = useState([]);
  const [fbTopImagesPreLoader, setFbTopImagesPreLoader] = useState([]);
  const [igTopImagesPreLoader, setIgTopImagesPreLoader] = useState([]);
  const [twTopVideosPreLoader, setTwTopVideosPreLoader] = useState([]);
  const [fbTopVideosPreLoader, setFbTopVideosPreLoader] = useState([]);
  const [igTopVideosPreLoader, setIgTopVideosPreLoader] = useState([]);
  const [engagersVolumePreLoader, setEngagersVolumePreLoader] = useState([]);
  const [genderAgeDistributionPreLoader, setGenderAgeDistributionPreLoader] =
    useState([]);

  const [accountAuthorActivityPreLoader, setAccountAuthorActivityPreLoader] =
    useState([]);
  const [commentsMentionsPreLoader, setCommentsMentionsPreLoader] = useState(
    [],
  );
  const [
    reachFunnelLegacyPreLoaderDataSources,
    setReachFunnelLegacyPreLoaderDataSources,
  ] = useState([]);
  const [topAuthorsPreLoader, setTopAuthorsPreLoader] = useState([]);

  const childProps = {
    overView,
    setOverView,
    overViewPreLoader,
    setOverViewPreLoader,
    contentStylesPreLoader,
    setContentStylesPreLoader,
    contentStyles,
    setContentStyles,
    associatedTopicsPreLoader,
    setAssociatedTopicsPreLoader,
    associatedTopics,
    setAssociatedTopics,
    volumeOvertimePreLoader,
    setVolumeOvertimePreLoader,
    topHashtagsPreLoader,
    setTopHashtagsPreLoader,
    topHashtags,
    setTopHashtags,
    topKeywordsPreLoader,
    setTopKeywordsPreLoader,
    topKeywords,
    setTopKeywords,
    topImages,
    setTopImages,
    topVideos,
    setTopVideos,
    neutralArray,
    setNeutralArray,
    positiveArray,
    setPositiveArray,
    negativeArray,
    setNegativeArray,
    sentimentAnalysisPreLoader,
    setSentimentAnalysisPreLoader,
    sentimentAnalysis,
    setSentimentAnalysis,
    interactionsLegend,
    setInteractionsLegend,
    interactionsPreLoader,
    setInteractionsPreLoader,
    interactions,
    setInteractions,
    totalVolumeOvertimeCount,
    setTotalVolumeOvertimeCount,
    volumeOvertime,
    setVolumeOvertime,
    commentsMentionsPreloader,
    setCommentsMentionsPreloader,
    topInfleuncersPreLoader,
    setTopInfleuncersPreLoader,
    topInfleuncers,
    setTopInfleuncers,
    topEngagersPreLoader,
    setTopEngagersPreLoader,
    topEngagers,
    setTopEngagers,
    topVerifiedEngagersPreLoader,
    setTopVerifiedEngagersPreLoader,
    topVerifiedEngagers,
    setTopVerifiedEngagers,
    topSourcesPreLoader,
    setTopSourcesPreLoader,
    topSources,
    setTopSources,
    accountTypesPreLoader,
    setAccountTypesPreLoader,
    accountTypes,
    setAccountTypes,
    genderDistributionPreLoader,
    setGenderDistributionPreLoader,
    genderDistribution,
    setGenderDistribution,
    topCountriesPreLoader,
    setTopCountriesPreLoader,
    topCountries,
    setTopCountries,
    topCitiesPreLoader,
    setTopCitiesPreLoader,
    topCities,
    setTopCities,
    dialectsPreLoader,
    setDialectsPreLoader,
    subDialects,
    setSubDialects,
    dialects,
    setDialects,
    topLanguagesPreLoader,
    setTopLanguagesPreLoader,
    topLanguages,
    setTopLanguages,
    articlesEngagements,
    setArticlesEngagements,
    articlesEngagementsPreloader,
    setArticlesEngagementsPreloader,
    reactions,
    setReactions,
    reachFunnel,
    setReachFunnel,
    reactionsPreLoader,
    setReactionsPreLoader,
    reachFunnelPreLoaderDataSources,
    setReachFunnelPreLoaderDataSources,
    topEngaged,
    setTopEngaged,
    topSites,
    setTopSites,
    topEngagedPreLoader,
    setTopEngagedPreLoader,
    topSitesPreLoader,
    setTopSitesPreLoader,
    twTopPosts,
    setTwTopPosts,
    igTopPosts,
    setIgTopPosts,
    fbTopPosts,
    setFbTopPosts,
    nbTopPosts,
    setNbTopPosts,
    twTopPostsPreloader,
    setTwTopPostsPreloader,
    igTopPostsPreloader,
    setIgTopPostsPreloader,
    fbTopPostsPreloader,
    setFbTopPostsPreloader,
    nbTopPostsPreloader,
    setNbTopPostsPreloader,
    engagersVolume,
    setEngagersVolume,
    totalEngagersVolumeCount,
    setTotalEngagersVolumeCount,
    engagersVolumePreLoader,
    setEngagersVolumePreLoader,
    genderAgeDistribution,
    setGenderAgeDistribution,
    genderAgeDistributionPreLoader,
    setGenderAgeDistributionPreLoader,
    minValueAuthorActivity,
    setMinValueAuthorActivity,
    maxValueAuthorActivity,
    setMaxValueAuthorActivity,
    accountAuthorActivity,
    setAccountAuthorActivity,
    accountAuthorActivityPreLoader,
    setAccountAuthorActivityPreLoader,
    commentsMentions,
    setCommentsMentions,
    commentsMentionsCount,
    setCommentsMentionsCount,
    commentsMentionsPreLoader,
    setCommentsMentionsPreLoader,
    pageReactionPieChartValue,
    setPageReactionPieChartValue,
    sentimentPieChart,
    setSentimentPieChart,
    contentType,
    setContentType,
    contentTypePreLoader,
    setContentTypePreLoader,
    reachFunnelLegacy,
    setReachFunnelLegacy,
    reachFunnelLegacyPreLoaderDataSources,
    setReachFunnelLegacyPreLoaderDataSources,
    twTopImages,
    setTwTopImages,
    fbTopImages,
    setFbTopImages,
    igTopImages,
    setIgTopImages,
    twTopImagesPreLoader,
    setTwTopImagesPreLoader,
    fbTopImagesPreLoader,
    setFbTopImagesPreLoader,
    igTopImagesPreLoader,
    setIgTopImagesPreLoader,
    twTopVideos,
    setTwTopVideos,
    fbTopVideos,
    setFbTopVideos,
    igTopVideos,
    setIgTopVideos,
    twTopVideosPreLoader,
    setTwTopVideosPreLoader,
    fbTopVideosPreLoader,
    setFbTopVideosPreLoader,
    igTopVideosPreLoader,
    setIgTopVideosPreLoader,
    genderPieChart,
    setGenderAnalysisPieChart,
    genderAgePieChart,
    setGenderAgePieChart,
    topAuthors,
    setTopAuthors,
    topAuthorsPreLoader,
    setTopAuthorsPreLoader,
    setTopPostsCount,
    topPostsCount,
    startDate,
    endDate,
    unixDate,
  };

  return (
    <>
      <OverViewSection
        widgetsName={widgetsName}
        getIcons={getIcons}
        metricsValues={metricsValues}
        dashboardID={dashboardID}
        monitorType={monitorType}
        widgetsTypeTitle={widgetsTypeTitle}
        isKeywordType={isKeywordType}
        updateAnalysis={updateAnalysis}
        insightsID={insightsID}
        insightsResponse={insightsResponse}
        dates={dates}
        response={response}
        singleTweetAnalysis={singleTweetAnalysis}
        {...childProps}
      />

      <AuthorAnalyticsSection
        widgetsName={widgetsName}
        getIcons={getIcons}
        metricsValues={metricsValues}
        dashboardID={dashboardID}
        monitorType={monitorType}
        widgetsTypeTitle={widgetsTypeTitle}
        isKeywordType={isKeywordType}
        dashboardDynamicData={dashboardDynamicData}
        updateAnalysis={updateAnalysis}
        insightsID={insightsID}
        insightsResponse={insightsResponse}
        dates={dates}
        response={response}
        singleTweetAnalysis={singleTweetAnalysis}
        {...childProps}
      />
      {showAnalyzeTweetSnackBar && (
        <SnackBar
          open={true}
          handleClose={handleCloseSnackBar}
          title={CheckValueLocale(analyzeTweetSnackBar.title, "", {}, intl)}
          message={CheckValueLocale(analyzeTweetSnackBar.message, "", {}, intl)}
          severity={analyzeTweetSnackBar.severity}
        />
      )}
      <PopupModal
        title={CheckValueLocale("single_tweet_analysis", "", {}, intl)}
        body={modalBody}
        open={stats?.openCreateModal}
        close={handleCloseCreateModal}
        warning="warning"
        classeName="create-historical-popup"
        addClasses="create-single-post-analysis-modal"
      />
    </>
  );
};

export default EngagementsBody;
