import { Box, Tooltip } from "@mui/material";
import { CheckValueLocale, handleActiveFeature } from "utils/helpers";
import { handelViewLink } from "../sharedFunctions/viewDownloadFunctions";
import { useGetDownloadableExcel } from "pages/notifications/hooks/useGetDownloadableExcel";
import LucButton from "shared/lucButton/lucButton";

const ViewDownloadComponent = ({
  item,
  navigate,
  intl,
  setSnackbar,
  isHomepage,
}) => {
  const { mutate: getDownloadableExcel, isPending: isGetFileLoading } =
    useGetDownloadableExcel(); // API call to get excel sheet body and name to use it in downloading it

  const isDownload = item?.notification_type === "excel_export";
  const isXTopTrendsAlert = item?.alert_type_name === "XTopTrends";
  const hasXTopTrends = handleActiveFeature("SM", "twitter_top_hashtags");

  return (
    <Box>
      <Tooltip
        arrow
        placement="top"
        classes={{ tooltip: "notification-download-view-btn-tooltip" }}
        title={
          isXTopTrendsAlert && !hasXTopTrends
            ? CheckValueLocale(
                "x_top_trends_not_included_notification_msg",
                "",
                {},
                intl,
              )
            : null
        }
      >
        <Box>
          <LucButton
            id="notification-download-view-btn"
            type="primary"
            variant={isHomepage ? "flat" : isDownload ? "outline" : "filled"}
            size={isHomepage ? "large" : "small"}
            fullWidth
            loading={isGetFileLoading}
            disabled={isXTopTrendsAlert && !hasXTopTrends}
            onClick={() =>
              handelViewLink(item, navigate, setSnackbar, getDownloadableExcel)
            }
          >
            {CheckValueLocale(
              isDownload ? "download" : "view_notification",
              "",
              {},
              intl,
            )}
          </LucButton>
        </Box>
      </Tooltip>
    </Box>
  );
};

export default ViewDownloadComponent;
