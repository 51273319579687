import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Box,
  ClickAwayListener,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faTimes } from "@fortawesome/pro-regular-svg-icons"; // Import the clear icon

// MUI date/time pickers (DesktopTimePicker)
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";

import LucButton from "shared/lucButton/lucButton";
import { CheckValueLocale } from "utils/helpers";
import { faCircleXmark } from "@fortawesome/pro-solid-svg-icons";
import { isAM } from "shared/customDateRangePicker/utils/dateUtils";

/**
 * Safely parse a prop value (string or moment) into a valid Moment or null.
 * Adjust "HH:mm" / "h:mm A" depending on how you store times.
 */
function ensureMoment(value) {
  if (!value) return null;
  if (moment.isMoment(value)) return value;

  const parsed = moment(value, ["HH:mm", "h:mm A"], true);
  return parsed.isValid() ? parsed : null;
}

/**
 * Display the selected value in the input field
 * e.g 1739053200000 -> 12:00:00
 */
function displaySelectedValue(momentValue) {
  // handle the case where 00:00:00 is selected to be 12:00:00
  const formatted12HourTime = momentValue
    ?.format?.("h:mm:ss A")
    ?.split(" ")?.[0];
  if (formatted12HourTime.slice(0, 2) === "00") {
    return "12" + formatted12HourTime.slice(2);
  }
  return formatted12HourTime;
}

function TimeInputs({
  tempStartTime, // can be moment or string
  tempEndTime, // can be moment or string
  setTempStartTime,
  setTempEndTime,
  onApply,
  intl,
  hasTimePicker,
}) {
  // Initialize with the current moment or an appropriate default
  const [openStart, setOpenStart] = useState(false);
  const [openEnd, setOpenEnd] = useState(false);
  const [isHoveringStart, setIsHoveringStart] = useState(false); // Track hover state for Start Time
  const [isHoveringEnd, setIsHoveringEnd] = useState(false); // Track hover state for End Time
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  // Convert incoming props to moment or null
  const startValue = React.useMemo(
    () => ensureMoment(tempStartTime),
    [tempStartTime],
  );
  const endValue = React.useMemo(
    () => ensureMoment(tempEndTime),
    [tempEndTime],
  );

  const periodStart = startValue ? startValue.format("A") : "AM";
  const periodEnd = endValue ? endValue.format("A") : "PM";

  // Handler: decide if you store as moment or as "HH:mm" in the parent
  const handleStartChange = (newValue) => {
    setStartTime(newValue);
  };

  const handleEndChange = (newValue) => {
    setEndTime(newValue);
  };

  const handleStartClose = () => {
    // Only set the tempStartTime if it's valid AND not after the current endTime
    const isBeforeOrSameAsEndTime =
      tempEndTime && tempEndTime.isValid?.()
        ? startTime?.isBefore?.(tempEndTime) || startTime?.isSame?.(tempEndTime)
        : true;

    if (startTime && startTime.isValid?.() && isBeforeOrSameAsEndTime) {
      setTempStartTime(startTime);
    }
    setOpenStart(false);
  };

  const handleEndClose = () => {
    const isAfterStartTime =
      tempStartTime && tempStartTime.isValid?.()
        ? endTime?.isAfter?.(tempStartTime) || endTime?.isSame?.(tempStartTime)
        : true;
    if (endTime && endTime?.isValid?.() && isAfterStartTime) {
      setTempEndTime(endTime);
    }
    setOpenEnd(false);
  };
  // Clear the Start Time input
  const handleClearStartTime = () => {
    setTempStartTime(null); // Clear the start time
  };

  // Clear the End Time input
  const handleClearEndTime = () => {
    setTempEndTime(null); // Clear the end time
  };

  // Ensure minTime and maxTime are valid moment objects
  const minTimeForEnd = startValue ? startValue : moment("00:00", "HH:mm");
  const maxTimeForStart = endValue ? endValue : moment("23:59", "HH:mm");

  // adding custom locale to moment that will not effect other components
  moment.defineLocale("date-picker-custom", {
    parentLocale: "en",
    meridiem: function (hour) {
      if (hour < 12) {
        return CheckValueLocale("hour_AM", "", {}, intl);
      }
      return CheckValueLocale("hour_PM", "", {}, intl);
    },
  });

  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      adapterLocale="date-picker-custom"
    >
      <Box className="time-inputs">
        <Box
          className={`time-input-group ${hasTimePicker ? "has-time-picker" : ""}`}
        >
          {hasTimePicker ? (
            <Box className="time-input-items">
              {/* START TIME (DesktopTimePicker) */}
              <Box
                className={`time-picker-wrapper ${openStart ? "opened" : ""}`}
                onClick={() => setOpenStart(true)}
                id="date-picker-start-time-input"
                onMouseEnter={() => setIsHoveringStart(true)}
                onMouseLeave={() => setIsHoveringStart(false)}
              >
                <DesktopTimePicker
                  ampm
                  value={startValue}
                  inputFormat="HH:mm"
                  onChange={handleStartChange}
                  views={["hours", "minutes", "seconds"]}
                  maxTime={maxTimeForStart}
                  open={openStart}
                  minutesStep={1}
                  timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                  closeOnSelect={false}
                  onClose={handleStartClose}
                  slotProps={{
                    popper: {
                      disablePortal: true,
                      placement: "top",
                      modifiers: [
                        { name: "flip", enabled: false },
                        { name: "preventOverflow", enabled: false },
                        // Optional offset so it's not flush against the input
                        { name: "offset", options: { offset: [12, 8] } },
                      ],
                      sx: {
                        zIndex: 100000000,
                      },
                    },
                    textField: (params) => {
                      return {
                        inputProps: {
                          placeholder: "--:--",
                          value: startValue
                            ? displaySelectedValue(startValue)
                            : "--:--",
                        },
                      };
                    },
                  }}
                />
                <Box
                  className="time-picker-icon"
                  onClick={handleClearStartTime}
                >
                  {isHoveringStart && startValue ? (
                    <FontAwesomeIcon icon={faCircleXmark} />
                  ) : (
                    CheckValueLocale(
                      isAM(periodStart) ? "hour_AM" : "hour_PM",
                      "",
                      {},
                      intl,
                    )?.toLowerCase()
                  )}
                </Box>
              </Box>

              {/* ARROW ICON */}
              <Typography component="span" className="time-separator">
                <FontAwesomeIcon icon={faArrowRight} />
              </Typography>

              {/* END TIME (DesktopTimePicker) */}
              <Box
                className={`time-picker-wrapper ${openEnd ? "opened" : ""}`}
                onClick={() => setOpenEnd(true)}
                id="date-picker-end-time-input"
                onMouseEnter={() => setIsHoveringEnd(true)}
                onMouseLeave={() => setIsHoveringEnd(false)}
              >
                <DesktopTimePicker
                  ampm
                  views={["hours", "minutes", "seconds"]}
                  value={endValue}
                  onChange={handleEndChange}
                  inputFormat="HH:mm" // 24-hour format
                  minTime={minTimeForEnd}
                  minutesStep={1}
                  timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                  open={openEnd}
                  closeOnSelect={false}
                  onClose={handleEndClose}
                  slotProps={{
                    popper: {
                      disablePortal: true,
                      placement: "top",
                      modifiers: [
                        { name: "flip", enabled: false },
                        { name: "preventOverflow", enabled: false },
                        // Optional offset so it's not flush against the input
                        { name: "offset", options: { offset: [12, 8] } },
                      ],
                      sx: {
                        zIndex: 100000000,
                      },
                    },
                    textField: (params) => {
                      return {
                        inputProps: {
                          placeholder: "--:--",
                          value: endValue
                            ? displaySelectedValue(endValue)
                            : "--:--",
                        },
                      };
                    },
                  }}
                />
                <Box className="time-picker-icon" onClick={handleClearEndTime}>
                  {isHoveringEnd && endValue ? (
                    <FontAwesomeIcon icon={faCircleXmark} />
                  ) : (
                    CheckValueLocale(
                      isAM(periodEnd) ? "hour_AM" : "hour_PM",
                      "",
                      {},
                      intl,
                    )?.toLowerCase()
                  )}
                </Box>
              </Box>
            </Box>
          ) : null}

          {/* APPLY BUTTON */}
          <LucButton
            className="apply-btn"
            type="primary"
            id="date-range-picker-apply-button"
            onClick={onApply}
          >
            {CheckValueLocale("date_range_picker_apply_button", "", {}, intl)}
          </LucButton>
        </Box>
      </Box>
    </LocalizationProvider>
  );
}

export default TimeInputs;
