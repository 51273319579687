import moment from "moment";
import { isEmptyArray } from "utils/helpers";
import { handleAuthorActivity, handleXlsData } from "../excelCommonFn";

const account_fb_MP = (response, lang) => {
  if (isEmptyArray(response)) {
    return [];
  } else {
    var pageViews = [],
      pageLikeUnlike = [],
      pageReachImpression = [],
      pageContactDetailsInteractions = [],
      pageActions = [],
      postsVolume = [],
      postReachImpression = [],
      postsInteractions = [],
      reactions = [],
      postsContentType = [],
      videosViews = [],
      topKeywords = [],
      topHashtags = [],
      topImages = [],
      topVideos = [],
      topPosts = [],
      accountAuthorActivity = [],
      allSheetData = [];
    //_______________________________________________________________________________
    response?.map((i) => {
      Object.entries(i).forEach(([key, value]) => {
        switch (key) {
          case "pageViews":
            value?.map((i) => {
              pageViews.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.impression]: i?.value,
              });
            });
            allSheetData.push([pageViews, lang.page_views]);
            break;
          //_______________________________________________________________________________
          case "accountAuthorActivity":
            handleAuthorActivity(value[0], value[1], value[2], lang)?.map(
              (i) => {
                accountAuthorActivity?.push({
                  [lang.day]: i[0],
                  [lang.hour_group]: i[1],
                  [lang.no_of_activity]: i[2],
                });
              },
            );
            allSheetData?.push([
              accountAuthorActivity,
              lang?.account_authors_activity,
            ]);
            break;
          // _______________________________________________________________________________

          case "pageLikeUnlike":
            value?.map((i) => {
              pageLikeUnlike?.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.likes_count]: i?.value[0],
                [lang.unlike_page_over_time]: i?.value[1],
                [lang.total]: i?.value[0] + i?.value[1],
              });
            });
            allSheetData?.push([pageLikeUnlike, lang?.page_like_unlikes]);

            break;
          //_______________________________________________________________________________
          case "pageReachImpression":
            value?.impression?.map((i, index) => {
              if (i?.name == value?.reach[index]?.name) {
                pageReachImpression.push({
                  [lang?.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                  [lang?.reach]: i?.value,
                  [lang?.impression]: value?.reach[index]?.value,
                });
              }
            });
            allSheetData?.push([
              pageReachImpression,
              lang?.page_reach_impressions,
            ]);
            break;
          //_______________________________________________________________________________

          case "pageContactDetailsInteractions":
            value?.map((i) => {
              pageContactDetailsInteractions.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.page_website_clicks_over_time]: i?.value[0],
                [lang.page_call_phone_clicks_over_time]: i?.value[1],
                [lang.page_directions_clicks_over_time]: i?.value[2],
              });
            });
            allSheetData.push([
              pageContactDetailsInteractions,
              lang.page_contact_details_interactions_xlsx_sheetname,
            ]);
            break;
          //_______________________________________________________________________________

          case "pageActions":
            value?.map((i) => {
              pageActions.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.page_negative_interactions_over_time]: i?.value[0],
                [lang.page_positive_interactions_over_time]: i?.value[1],
                [lang.total]: i?.value[0] + i?.value[1],
              });
            });
            allSheetData?.push([pageActions, lang.page_actions]);
            break;

          //_______________________________________________________________________________

          case "postsVolume":
            value?.map((i) => {
              postsVolume.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.posts_word]: i?.value,
              });
            });
            allSheetData.push([postsVolume, lang.posts_volume]);
            break;
          //_______________________________________________________________________________

          case "postReachImpression":
            value?.impression?.map((i, index) => {
              if (i.name == value.reach[index].name) {
                postReachImpression.push({
                  [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                  [lang.reach]: i?.value,
                  [lang.impressions]: value.reach[index].value,
                });
              }
            });
            allSheetData.push([
              postReachImpression,
              lang.posts_reach_impressions,
            ]);
            break;
          //_______________________________________________________________________________

          case "postsInteractions":
            value?.map((i) => {
              postsInteractions.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.comments]: i?.value[0],
                [lang.reactions]: i?.value[1],
                [lang.total]: i?.value[0] + i?.value[1],
              });
            });
            allSheetData.push([postsInteractions, lang.posts_interactions]);
            break;
          //_______________________________________________________________________________

          case "reactions":
            value?.map((i) => {
              reactions.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.like_reaction]: i?.value[0],
                [lang.love_reaction]: i?.value[1],
                [lang.care_reaction]: i?.value[2],
                [lang.haha_reaction]: i?.value[3],
                [lang.wow_reaction]: i?.value[4],
                [lang.angry_reaction]: i?.value[5],
                [lang.total]:
                  i?.value[0] +
                  i?.value[1] +
                  i?.value[2] +
                  i?.value[3] +
                  i?.value[4] +
                  i?.value[5],
              });
            });
            allSheetData.push([reactions, lang.posts_reactions]);
            break;
          // //_______________________________________________________________________________

          case "postsContentType":
            value?.map((i) => {
              postsContentType.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.post_content_type]: i?.value[0],
                [lang.photo_content_type]: i?.value[1],
                [lang.video_content_type]: i?.value[2],
                [lang.total]: i?.value[0] + i?.value[1] + i?.value[2],
              });
            });
            allSheetData.push([postsContentType, lang.posts_content_type]);
            break;
          // _______________________________________________________________________________

          case "videosViews":
            value?.map((i) => {
              videosViews.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.views]: i?.value,
              });
            });
            allSheetData.push([videosViews, lang.videos_views]);
            break;
          // _______________________________________________________________________________

          case "topKeywords":
            value?.map((i) => {
              topKeywords.push({
                [lang.keywords]: i?.id,
                [lang.xlsx_number_of_posts]: i?.stats_count,
              });
            });
            allSheetData.push([
              topKeywords,
              lang.comments_top_keywords_xlsx_sheetname,
            ]);

            break;
          // _______________________________________________________________________________

          case "topHashtags":
            value?.map((i) => {
              topHashtags.push({
                [lang.xlsx_hashtags]: i?.id,
                [lang.xlsx_number_of_posts]: i?.stats_count,
              });
            });
            allSheetData.push([topHashtags, lang.posts_top_hashtags]);

            break;
          // _______________________________________________________________________________

          case "topImages":
            value?.map((i) => {
              topImages?.push({ [lang.image]: i?.post_url });
            });
            allSheetData.push([topImages, lang.top_images]);

            break;
          // _______________________________________________________________________________

          case "topVideos":
            value?.map((i) => {
              topVideos.push({ [lang.video]: i?.post_url });
            });
            allSheetData.push([topVideos, lang.top_videos]);

            break;
          // _______________________________________________________________________________

          case "topPosts":
            value?.map((i) => {
              topPosts.push({ [lang.posts_word]: i?.tweet_link });
            });
            allSheetData.push([topPosts, lang.top_posts]);

            break;
          //_______________________________________________________________________________
          default:
            break;
        }
      });
    });
    //_______________________________________________________________________________

    return handleXlsData(allSheetData);
  }
};
export { account_fb_MP };
