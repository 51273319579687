import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import InteractionsList from "components/interactions-list/InteractionsList.js";
import OverView from "components/widgets/overViewWidget";
import {
  CheckValueLocale,
  handleUnixFormat,
  translateLocalsFromRabbitMQ,
  getNoOfDays,
} from "utils/helpers";
import LuciFunctions from "pages/luci/components/luciFunctions/luciFunction";
import ActiveFilters from "shared/channel-analytics-filter/activeFilters/activeFilters";
import AccountSection from "./components/accountSection";
import EngagementsSection from "./components/engagementsSection";
import CustomerCareSection from "./components/customerCareSection";
import { checkIsDataFecthing } from "pages/channelAnalytics/InsightsPage/insightsCommonFunctions";
import _ from "lodash";
import publicTikTok from "images/shared-images/public-tikTok.svg";

const TikTokBody = (props) => {
  const intl = useIntl();
  let {
    response,
    startDate,
    endDate,
    setCheckAllDataFetchingExport,
    setExcelSheetData,
  } = props;

  const tikTokIcon = (
    <img
      src={publicTikTok}
      alt="icon-tikTok"
      width={"15px"}
      className={"icon-after-title tikTok-icon"}
    />
  );

  const initialWidgetsState = {
    overview: [],
    overViewPreLoaderDataSources: [],

    postsVolume: [],
    totalPostsVolumeCount: [],
    postsVolumePreLoaderDataSources: [],

    followersGrowth: [],
    totalFollowersFollowersGrowth: 0,
    maxChangeFollowersGrowth: 0,
    avgChangeFollowersGrowth: 0,
    followersGrowthPreLoaderDataSources: [],

    authorActivity: [],
    minValueAuthorActivity: 0,
    maxValueAuthorActivity: 0,
    authorActivityPreLoaderDataSources: [],

    postsInteractions: [],
    maxValuePostsInteractions: 0,
    maxValueInteractionsDate: 0,
    minValueInteractionsDate: 0,
    minValuePostsInteractions: 0,
    avgValuePostsInteractions: 0,
    postInteractionsLegend: [],
    postsInteractionsPreLoaderDataSources: [],

    topVideos: [],
    topVideosPreLoaderDataSources: [],

    totalCommentsVolumeCount: [],
    totalInteractions: 0,
    avgInteractions: 0,
    commentsVolume: [],
    commentsVolumePreLoader: [],

    sentimentAnalysis: [],
    sentimentPieChart: [],
    sentimentAnalysisPreLoader: [],

    themeXlxs: [],
    themeAnalysis: [],
    themeTrend: [],
    themeAnalysisPreLoader: [],
    themeTrendPreLoader: [],

    topKeywords: [],
    topKeywordsPreLoader: [],

    topHashtags: [],
    topHashtagsPreLoader: [],

    genderDistribution: [],
    excelGenderDistribution: [],
    genderDistributionPreLoader: [],

    associatedTopics: [],
    excelAssociatedTopics: [],
    associatedTopicsPreLoader: [],

    topLanguages: [],
    excelTopLanguages: [],
    topLanguagesPreLoader: [],

    excelDialects: [],
    excelSubDialects: [],
    dialects: [],
    subDialects: [],
    dialectsPreLoader: [],

    topEngagers: [],
    topEngagersPreLoader: [],

    customerCareData: [],
    customerCarePerformance: [],
    customerCarePreloader: [],
    respRate: 0,

    latestInteractions: [],
    latestInteractionsCount: 0,
    latestInteractionsPreLoaderDataSources: [],

    negativeArray: [],
    positiveArray: [],
    neutralArray: [],
  };

  const [tikTokWidgets, setTikTokWidgets] = useState(initialWidgetsState);
  const { checkLuciDataIsFounded, checkLuciAllDataFounded } = LuciFunctions();

  useEffect(() => {
    switch (response?.eventName) {
      case "Tiktok__CASingleDataSourceInsightsPage__overview":
        if (response?.eventData) {
          let overViewArray = Object?.entries(
            response?.eventData?.overview,
          )?.map((key) => ({
            name: key[0],
            value: key[1],
          }));
          setTikTokWidgets({
            ...tikTokWidgets,
            overview: [...overViewArray],
            overViewPreLoaderDataSources: [response?.data_source],
          });
        }
        break;
      case "Tiktok__CASingleDataSourceInsightsPage__volume_overtime":
        setTikTokWidgets({
          ...tikTokWidgets,
          postsVolume: response?.eventData?.posts_over_time,
          totalPostsVolumeCount: response?.eventData?.total_posts_count,
          postsVolumePreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Tiktok__CASingleDataSourceInsightsPage__comments_overtime":
        const noOfDays = getNoOfDays(startDate, endDate);
        const avg = response?.eventData?.total_interactions / noOfDays;
        setTikTokWidgets({
          ...tikTokWidgets,
          avgInteractions: avg % 1 !== 0 ? avg.toFixed(2) : avg,
          totalInteractions: response?.eventData?.total_interactions,
          totalCommentsVolumeCount: response?.eventData?.total_interactions,
          commentsVolume: response?.eventData?.interactions,
          commentsVolumePreLoader: [response?.data_source],
        });
        break;
      case "Tiktok__CASingleDataSourceInsightsPage__top_videos":
        setTikTokWidgets({
          ...tikTokWidgets,
          topVideos: response?.eventData?.top_videos,
          topVideosPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "tiktok__CASingleDataSourceInsightsPage__themes_and_sub_themes":
        setTikTokWidgets({
          ...tikTokWidgets,
          themeXlxs: response?.eventData?.themes,
          themeAnalysis: response?.eventData?.themes?.theme_analysis,
          themeTrend: response?.eventData?.themes?.theme_trend,
          themeAnalysisPreLoader: [response?.data_source],
          themeTrendPreLoader: [response?.data_source],
        });
        break;
      case "Tiktok__CASingleDataSourceInsightsPage__top_languages":
        setTikTokWidgets({
          ...tikTokWidgets,
          excelTopLanguages: _.cloneDeep([...response.eventData.top_languages]),
          topLanguages: translateLocalsFromRabbitMQ(
            "Tiktok__CASingleDataSourceInsightsPage__top_languages",
            response.eventData.top_languages,
          ),
          topLanguagesPreLoader: [response?.data_source],
        });
        break;
      case "Tiktok__CASingleDataSourceInsightsPage__top_dialects":
        setTikTokWidgets({
          ...tikTokWidgets,
          excelDialects: _.cloneDeep([...response.eventData.dialects]),
          excelSubDialects: _.cloneDeep([...response.eventData.sub_dialects]),
          dialects: response?.eventData?.dialects,
          subDialects: response?.eventData?.sub_dialects,
          dialectsPreLoader: [response?.data_source],
        });
        break;
      case "Tiktok__CASingleDataSourceInsightsPage__account_author_activity":
        setTikTokWidgets({
          ...tikTokWidgets,
          authorActivity: response?.eventData?.account_author_activity,
          minValueAuthorActivity: response?.eventData?.min_value,
          maxValueAuthorActivity: response?.eventData?.max_value,
          authorActivityPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Tiktok__CASingleDataSourceInsightsPage__videos_interactions":
        let dateMaxValue = handleUnixFormat(
          response?.eventData?.max_interaction_date,
        );

        let dateMinValue = handleUnixFormat(
          response?.eventData?.min_interaction_date,
        );
        setTikTokWidgets({
          ...tikTokWidgets,
          postsInteractions: response?.eventData?.videos_interactions_over_time,
          maxValuePostsInteractions: response?.eventData?.max_interactions,
          maxValueInteractionsDate: dateMaxValue,
          minValueInteractionsDate: dateMinValue,
          minValuePostsInteractions: response?.eventData?.min_interactions,
          avgValuePostsInteractions: response?.eventData?.average_interactions,
          postInteractionsLegend:
            response?.eventData?.videos_interactions_over_time_legends,
          postsInteractionsPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Tiktok__CASingleDataSourceInsightsPage__sentiment_analysis":
        Object.entries(response?.eventData?.sentiment_overtime)?.forEach(
          ([key, value]) => {
            switch (key) {
              case "negative":
                value?.map((i) => {
                  return tikTokWidgets?.negativeArray.push(i);
                });
                break;
              case "positive":
                value?.map((i) => {
                  return tikTokWidgets?.positiveArray.push(i);
                });
                break;
              case "neutral":
                value?.map((i) => {
                  return tikTokWidgets?.neutralArray.push(i);
                });
                break;
              default:
                break;
            }
          },
        );

        setTikTokWidgets({
          ...tikTokWidgets,
          sentimentAnalysis: {
            negative: tikTokWidgets?.negativeArray,
            positive: tikTokWidgets?.positiveArray,
            neutral: tikTokWidgets?.neutralArray,
          },
          sentimentPieChart: response?.eventData?.sentiment_piechart,
          sentimentAnalysisPreLoader: [response?.data_source],
        });
        break;
      case "Tiktok__CASingleDataSourceInsightsPage__gender_distribution":
        setTikTokWidgets({
          ...tikTokWidgets,
          excelGenderDistribution: _.cloneDeep([
            ...response?.eventData?.genders,
          ]),
          genderDistribution: response?.eventData?.genders,
          genderDistributionPreLoader: [response?.data_source],
        });
        break;
      case "Tiktok__CASingleDataSourceInsightsPage__top_keywords":
        setTikTokWidgets({
          ...tikTokWidgets,
          topKeywords: response?.eventData?.top_keywords,
          topKeywordsPreLoader: [response?.data_source],
        });
        break;
      case "Tiktok__CASingleDataSourceInsightsPage__top_hashtags":
        setTikTokWidgets({
          ...tikTokWidgets,
          topHashtags: response?.eventData?.top_hashtags,
          topHashtagsPreLoader: [response?.data_source],
        });
        break;
      case "Tiktok__CASingleDataSourceInsightsPage__top_engagers":
        setTikTokWidgets({
          ...tikTokWidgets,
          topEngagers: response?.eventData?.top_engagers,
          topEngagersPreLoader: [response?.data_source],
        });
        break;
      case "Tiktok__CASingleDataSourceInsightsPage__latest_interactions":
        setTikTokWidgets({
          ...tikTokWidgets,
          latestInteractions: response?.eventData?.latest_interactions,
          latestInteractionsCount: response?.eventData?.interactions_count,
          latestInteractionsPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Tiktok__CASingleDataSourceInsightsPage__customer_care_performance":
        let data = [];
        if (response?.eventData?.response_time_segment) {
          Object.keys(response?.eventData?.response_time_segment).map(
            (val, index) => {
              data.push({
                name: val,
                value: Object.values(
                  response?.eventData?.response_time_segment,
                )[index],
              });
            },
          );
        }
        setTikTokWidgets({
          ...tikTokWidgets,
          customerCareData: data,
          customerCarePerformance: response?.eventData,
          respRate:
            response?.eventData?.number_of_answered_questions /
            response?.eventData?.total_number_of_questions,
          customerCarePreloader: [response?.data_source],
        });
        break;
      case "Tiktok__CASingleDataSourceInsightsPage__associated_topics":
        setTikTokWidgets({
          ...tikTokWidgets,
          excelAssociatedTopics: _.cloneDeep([
            ...response.eventData.associated_topics,
          ]),
          associatedTopics: response?.eventData?.associated_topics,
          associatedTopicsPreLoader: [response?.data_source],
        });
        break;
      case "Tiktok__CASingleDataSourceInsightsPage__follower_growth_overtime":
        setTikTokWidgets({
          ...tikTokWidgets,
          followersGrowth: response?.eventData?.follower_growth_overtime,
          totalFollowersFollowersGrowth: response?.eventData?.total_followers,
          maxChangeFollowersGrowth:
            response?.eventData?.max_change_of_followers,
          avgChangeFollowersGrowth: response?.eventData?.avg_chage_of_day,
          followersGrowthPreLoaderDataSources: [response?.data_source],
        });
        break;
      default:
        break;
    }
  }, [response]);

  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (props.resetAllFields !== 1) {
      setTikTokWidgets({ ...initialWidgetsState });
    }
  }, [props.resetAllFields]);

  useEffect(() => {
    let x = checkLuciAllDataFounded(
      tikTokWidgets?.latestInteractionsPreLoaderDataSources,
      props.preLoaderTrackerDataSources,
    );
    let y = checkLuciDataIsFounded([tikTokWidgets?.latestInteractions]);
    props.setCheckAllDataExist(x);
    props.setCheckDataIsEmpty(y);
  }, [
    tikTokWidgets?.latestInteractions,
    tikTokWidgets?.latestInteractionsPreLoaderDataSources,
    props.preLoaderTrackerDataSources,
  ]);

  // check fetching for export excel
  useEffect(() => {
    let totalPreLoaderDataSouces = [
      tikTokWidgets?.overViewPreLoaderDataSources,
      tikTokWidgets?.followersGrowthPreLoaderDataSources,
      tikTokWidgets?.authorActivityPreLoaderDataSources,
      tikTokWidgets?.postsVolumePreLoaderDataSources,
      tikTokWidgets?.postsInteractionsPreLoaderDataSources,
      tikTokWidgets?.topVideosPreLoaderDataSources,
      tikTokWidgets?.sentimentAnalysisPreLoader,
      tikTokWidgets?.commentsVolumePreLoader,
      tikTokWidgets?.themeAnalysisPreLoader,
      tikTokWidgets?.themeTrendPreLoader,
      tikTokWidgets?.associatedTopicsPreLoader,
      tikTokWidgets?.topKeywordsPreLoader,
      tikTokWidgets?.topHashtagsPreLoader,
      tikTokWidgets?.topLanguagesPreLoader,
      tikTokWidgets?.dialectsPreLoader,
      tikTokWidgets?.genderDistributionPreLoader,
      tikTokWidgets?.topEngagersPreLoader,
      tikTokWidgets?.customerCarePreloader,
      tikTokWidgets?.latestInteractionsPreLoaderDataSources,
    ];

    let result = checkIsDataFecthing(
      totalPreLoaderDataSouces,
      props?.preLoaderTrackerDataSources,
    );
    setCheckAllDataFetchingExport(result);
  }, [
    tikTokWidgets?.overViewPreLoaderDataSources,
    tikTokWidgets?.followersGrowthPreLoaderDataSources,
    tikTokWidgets?.authorActivityPreLoaderDataSources,
    tikTokWidgets?.postsVolumePreLoaderDataSources,
    tikTokWidgets?.postsInteractionsPreLoaderDataSources,
    tikTokWidgets?.topVideosPreLoaderDataSources,
    tikTokWidgets?.sentimentAnalysisPreLoader,
    tikTokWidgets?.commentsVolumePreLoader,
    tikTokWidgets?.themeAnalysisPreLoader,
    tikTokWidgets?.themeTrendPreLoader,
    tikTokWidgets?.associatedTopicsPreLoader,
    tikTokWidgets?.topKeywordsPreLoader,
    tikTokWidgets?.topHashtagsPreLoader,
    tikTokWidgets?.topLanguagesPreLoader,
    tikTokWidgets?.dialectsPreLoader,
    tikTokWidgets?.genderDistributionPreLoader,
    tikTokWidgets?.topEngagersPreLoader,
    tikTokWidgets?.customerCarePreloader,
    tikTokWidgets?.latestInteractionsPreLoaderDataSources,
  ]);
  useEffect(() => {
    let excelData = { ...tikTokWidgets };
    let authorAcc = [
      tikTokWidgets?.authorActivity,
      tikTokWidgets?.minValueAuthorActivity,
      tikTokWidgets?.maxValueAuthorActivity,
    ];
    excelData["authorActivity"] = [...authorAcc];
    setExcelSheetData([{ ...excelData }]);
  }, [tikTokWidgets]);
  const unixDate = {
    start: startDate,
    end: endDate,
  };
  return (
    <>
      <OverView
        data={tikTokWidgets?.overview}
        overViewPreLoaderDataSources={
          tikTokWidgets?.overViewPreLoaderDataSources
        }
        preLoaderTrackerDataSources={props?.preLoaderTrackerDataSources}
        directMessage={false}
      />

      <AccountSection
        preLoaderTrackerDataSources={props?.preLoaderTrackerDataSources}
        tikTokIcon={tikTokIcon}
        tikTokWidgets={tikTokWidgets}
      />
      <EngagementsSection
        preLoaderTrackerDataSources={props?.preLoaderTrackerDataSources}
        tikTokIcon={tikTokIcon}
        tikTokWidgets={tikTokWidgets}
      />

      <CustomerCareSection
        preLoaderTrackerDataSources={props?.preLoaderTrackerDataSources}
        tikTokIcon={tikTokIcon}
        tikTokWidgets={tikTokWidgets}
      />

      <Box my={4}>
        <InteractionsList
          showProfile={true}
          unixDate={unixDate}
          data={tikTokWidgets?.latestInteractions?.slice(0, 5)}
          title={"latest_interactions"}
          dataSources={props?.dataSources}
          isTiktok
          latestInteractionsPreLoaderDataSources={
            tikTokWidgets?.latestInteractionsPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props?.preLoaderTrackerDataSources}
          showViewMoreButton={tikTokWidgets?.latestInteractionsCount > 5}
        />
      </Box>
    </>
  );
};

export default TikTokBody;
