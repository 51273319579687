import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Alert, Box, Container } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/pro-regular-svg-icons";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import UserInfo from "./components/userInfo";
import PageHeader from "components/pageHeader.js";
import "./profile.scss";
import { UserTeams } from "./components/userTeams";
import SecurityProfileSection from "./components/securityProfileSection";
import SaveChangesFooter from "pages/settings/components/saveChangesFooter";
import { useFormik } from "formik";

import { profileFormSchema } from "./profileFormikValidation/profileFormikValidation";
import { useGetuseInfo } from "./hooks/useGetUserInfo";
import { useEditUserInfo } from "./hooks/useEditUserInfo";
import SnackBar from "components/snackBar";

const ProfilePage = () => {
  const intl = useIntl();
  const [showAlert, setAlertShow] = useState(true);
  const [snackBar, setSnackBar] = useState({
    open: false,
    msg: "",
    title: "",
    severity: "",
  });

  //Hooks
  const { data: userInfo, isLoading, isFetching } = useGetuseInfo();
  const { mutate: handelEditUserInfo, isPending: isEditUserLoding } =
    useEditUserInfo();

  //if u need to display snackBar error
  const handleErrorSnackBar = (msg, title) => {
    setSnackBar({
      open: true,
      msg: msg || "",
      title: title || "",
      severity: "error",
    });
  };
  //if u need to display snackBar success
  const handleSuccessSnackBar = (msg, title) => {
    setSnackBar({
      open: true,
      msg: msg || "",
      title: title || "",
      severity: "success",
    });
  };

  // Submit Edit User Info
  const handleSubmitProfileSetting = () => {
    const user_info = new FormData();

    if (
      (formik?.values?.current_password ||
        formik?.values?.new_password ||
        formik?.values?.confirm_password) &&
      !(
        formik?.values?.current_password &&
        formik?.values?.new_password &&
        formik?.values?.confirm_password
      )
    ) {
      if (!formik?.values?.current_password)
        formik?.setFieldError(
          "current_password",
          "please_enter_your_current_password_to_proceed",
        );
      if (!formik?.values?.new_password)
        formik?.setFieldError("new_password", "please_enter_new_password");
      if (!formik?.values?.confirm_password)
        formik?.setFieldError(
          "confirm_password",
          "please_enter_confirm_password",
        );

      return;
    }
    for (const key of Object.keys(formik?.values)) {
      if (
        (key != "avatar" || formik?.values?.[key] instanceof File) &&
        formik?.values?.[key] != null
      )
        user_info.append(key, formik?.values?.[key]);
    }
    handelEditUserInfo(
      { userInfo: user_info },
      {
        onSuccess: () => {
          handleSuccessSnackBar("edit_success");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        },
        onError: (error) => {
          handleErrorSnackBar("try_again", "something_went_wrong");
          //handle error from Back-end
          if (
            error?.errorMsg?.response?.data?.error ===
            "Old password is incorrect"
          )
            formik?.setFieldError(
              "current_password",
              "incorrect_password_try_again.",
            );
          else if (
            error?.errorMsg?.response?.data?.error ===
            "New password cannot be the same as the old password"
          )
            formik?.setFieldError(
              "new_password",
              "new_password_cannot_be_old_password",
            );
        },
      },
    );
  };

  const formik = useFormik({
    initialValues: {
      first_name: userInfo?.first_name || "",
      last_name: userInfo?.last_name || "",
      email: userInfo?.email || "",
      phone_number: userInfo?.phone_number || "",
      job_title: userInfo?.job_title || "",
      avatar: userInfo?.avatar || "",
      current_password: "",
      new_password: "",
      confirm_password: "",
      ...userInfo,
    },
    validationSchema: profileFormSchema,
    enableReinitialize: true,
    onSubmit: handleSubmitProfileSetting,
  });
  return (
    <Container className="profile-container" maxWidth="xl">
      <PageHeader title="profile_settings" />
      <Box id="profile">
        {showAlert ? (
          <Alert severity="info" id="profile-page-accounts-management-alert">
            <Box className="alert-content">
              {CheckValueLocale(
                "profile_page_accounts_management_alert_msg",
                "",
                {},
                intl,
              )}
              <Box className="btns-wrapper">
                <Link
                  to={"/settings/accounts_management/users_accounts"}
                  id="accounts-management-alert-view-btn"
                  className="view-btn"
                >
                  {CheckValueLocale(
                    "accounts_managemnt_view_btn",
                    "",
                    {},
                    intl,
                  )}
                </Link>
                <Box
                  component={"span"}
                  id="profile-page-accounts-management-alert-close-btn"
                  className="close-btn"
                  onClick={() => setAlertShow(false)}
                >
                  <FontAwesomeIcon icon={faClose} size="lg" />
                </Box>
              </Box>
            </Box>
          </Alert>
        ) : null}

        <UserInfo
          formik={formik}
          isLoading={isLoading}
          tempUserInfo={userInfo}
        />
        <SecurityProfileSection
          formik={formik}
          isLoading={isLoading}
          handleErrorSnackBar={handleErrorSnackBar}
          handleSuccessSnackBar={handleSuccessSnackBar}
        />
        <UserTeams />
      </Box>

      {formik?.dirty && !isFetching ? (
        <SaveChangesFooter
          handleSaveChanges={formik?.handleSubmit}
          disabledSaveChanges={
            !formik?.isValid ||
            !formik?.dirty ||
            snackBar?.msg === "edit_success"
          }
          loadingSaveChanges={isEditUserLoding}
          handleCancelChanges={formik?.resetForm}
        />
      ) : null}

      <SnackBar
        open={snackBar?.open}
        handleClose={() =>
          setSnackBar({
            open: false,
            msg: "",
            title: "",
            severity: "",
          })
        }
        title={CheckValueLocale(snackBar?.title, "", {}, intl)}
        message={CheckValueLocale(snackBar?.msg, "", {}, intl)}
        severity={snackBar?.severity}
        alertStyle={{ maxWidth: "320px" }}
      />
    </Container>
  );
};
export default ProfilePage;
