import { Box, Divider, Typography, Chip } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import FeatureOptionBody from "./featureOptionBody";
import TwoFAMethodNameWithIcon from "../../../../profile/components/securityProfileSection/components/twoFactorAuthentication/components/twoFAMethodNameWithIcon/twoFAMethodNameWithIcon";
import { useEffect, useState } from "react";
const TwoFactorAuthActivated = (props) => {
  const [methodStatus, setMethodStatus] = useState({
    email: "inactive",
    sms: "inactive",
    totp: "inactive",
    rememberMe: "inactive",
  });

  useEffect(() => {
    const newValues = {};

    Object?.keys(props?.activatedMethods)?.forEach((key) => {
      newValues[key] = props?.activatedMethods?.[key]
        ? "active_feature"
        : "inactive";
    });
    setMethodStatus(newValues);
  }, [props?.activatedMethods]);

  const intl = useIntl();
  return (
    <Box className="security-page-sections-container-enabled">
      <Box className="feature-details">
        <FeatureOptionBody
          featureName={props?.featureName}
          featureDescription={props?.featureDescription}
          openMethodPopup={props?.openEdit}
          openDisable={props?.openDisable}
          isAvailable={props?.isAvailable}
          buttonTitle={"edit"}
          disabled={props?.disabled}
        />
      </Box>
      <Divider className="security-feature-divider"></Divider>

      <Typography className="selected-methods">
        {CheckValueLocale("selected_methods", "", {}, intl)}
      </Typography>
      {["email", "sms", "totp"]?.map((method, index) => (
        <Box className="two-factor-authentation-method" key={index}>
          <TwoFAMethodNameWithIcon
            method={method}
            chipLabel={methodStatus?.[method]}
            page={"settings"}
          />
        </Box>
      ))}

      <Divider className="security-feature-divider"></Divider>
      <Box className="remember-me">
        <Typography className="remember-me-title">
          {CheckValueLocale("remember_me", "", {}, intl)}
          <Chip
            label={CheckValueLocale(methodStatus?.rememberMe, "", {}, intl)}
            size="small"
            className={`feature-status-chip ${methodStatus?.["rememberMe"]}`}
          />
        </Typography>
      </Box>
      <Typography className="remember-me-desc-feature-enabled">
        {CheckValueLocale("remember_me_desc", "", {}, intl)}
      </Typography>
    </Box>
  );
};
export default TwoFactorAuthActivated;
