import dayjs from "dayjs";

export const useCreateReportInitialValues = () => {
  return {
    // Step 1: Report Information
    title: "",
    product_id: "",

    // Step 2: Report Setting
    channel_type: "",
    report_type_id: "",
    data_source_id: [], // Not sent to Backend in create
    monitor_list: [],
    data_included: "public", // Not sent to Backend in create

    // Step 3: Report Delivery
    users: [],
    language: "",
    frequency: "",
    report_recurrence_id: "",
    on_specific_day: "",
    start_date_time: dayjs(new Date()?.toLocaleDateString()),
    is_branding: false,
    is_luci_report: false,
  };
};
