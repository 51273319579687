import BigTableWidget from "components/widgets/bigTableWidget/bigTableWidget";
import { useMemo, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  getTableHeader,
  RenderAgentColumn,
  RenderNormalColumn,
} from "./helpersComponents";
import {
  displayRemainingTime,
  renderRemainingTime,
} from "../sharedFunctions/datesUtils";
import _ from "lodash";
import moment from "moment";

const tablesHeaders = [
  {
    name: "agent_performance_grouped_agents_column",
  },
  {
    name: "total_available_time",
  },
  {
    name: "total_busy_time",
  },
  {
    name: "total_meeting_time",
  },
  {
    name: "total_break_time",
  },
  {
    name: "total_unavailable_time",
  },
];

const createTableRow = (rowData, agents, intl) => {
  const agent = agents?.find((agent) => +agent.id === +rowData?.user_id);
  const rows = [];
  // if the agent is not found we will return null
  if (!agent) {
    return null;
  }
  rows.push(<RenderAgentColumn agent={agent} />);

  const { available, break: onbreak, busy, meeting, unavailable } = rowData;
  const values = [
    renderRemainingTime(displayRemainingTime(available), intl),
    renderRemainingTime(displayRemainingTime(busy), intl),
    renderRemainingTime(displayRemainingTime(meeting), intl),
    renderRemainingTime(displayRemainingTime(onbreak), intl),
    renderRemainingTime(displayRemainingTime(unavailable), intl),
  ];
  values.forEach((value) => {
    rows.push(<RenderNormalColumn value={value} />);
  });
  return rows;
};

const rowsPerPage = 5;

const AgentStatusSummaryTable = ({
  agentStatusSummaryeData,
  agents,
  isLoading,
  deploymentDate,
  outdatedDateRange,
  noFiltersHasBeenApplied,
  ...props
}) => {
  const intl = useIntl();
  const [page, setPage] = useState(0);
  const [tableData, setTableData] = useState([]);

  const tableHeaders = useMemo(() => {
    return getTableHeader(tablesHeaders, intl);
  }, [intl?.locale]);

  const paginatedTableData = useMemo(() => {
    const start = page * rowsPerPage;
    const end = start + rowsPerPage;
    return tableData?.slice?.(start, end) || [];
  }, [tableData, page]);

  const handleTablePagination = (page) => {
    setPage(page);
  };

  useEffect(() => {
    const stillFetchingData =
      _.isEqual(
        props?.statisticsPreLoaderDataSources?.sort(),
        props?.preLoaderTrackerDataSources?.sort(),
      ) !== true;

    if (isLoading || stillFetchingData) {
      return;
    }

    const newTableData = agentStatusSummaryeData
      ?.map((data) => {
        return createTableRow(data, agents, intl);
      })
      ?.filter(Boolean);
    setPage(0);
    setTableData(newTableData);
  }, [
    intl?.locale,
    isLoading,
    props?.statisticsPreLoaderDataSources,
    props?.preLoaderTrackerDataSources,
  ]);

  return (
    <BigTableWidget
      chartId="agents-status-summary"
      title="agent_status_summary_title"
      titleToolTip="agent_status_summary_tooltip"
      handleTablePagination={handleTablePagination}
      stickyColumn={true}
      tableHeaders={tableHeaders}
      tableData={paginatedTableData}
      isLoading={isLoading}
      count={tableData?.length}
      page={page}
      rowsPerPage={rowsPerPage}
      {...props}
      showReportIcon={!noFiltersHasBeenApplied}
      alertType="warning"
      alertMessage={outdatedDateRange ? "date_range_accurate_data" : ""}
      alertMessagedynamicValues={{
        deploymentDate: moment.unix(deploymentDate).format("DD-MM-YYYY"),
      }}
    />
  );
};

export default AgentStatusSummaryTable;
