import { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import {
  CheckValueLocale,
  commasAfterDigit,
  handleUnixFormat,
  translateLocalsFromRabbitMQ,
} from "utils/helpers";
import publicFacebook from "images/social-icons/facebook-public-icon.png";
import PieChartWidget from "components/widgets/pieChartWidget";
import StackedBarWidget from "components/widgets/stackedBarWidget";
import LineChartWidget from "components/widgets/lineChartWidget";
import StackedLineWidget from "components/widgets/stackedLineWidget";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faQuestion, faPercent } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/pro-regular-svg-icons";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import PunchCardWidget from "components/widgets/punchCardWidget";
import QuestionCard from "pages/Monitors/Components/questionCard";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import ThemeSection from "pages/Monitors/Components/themeSection";
import MediaFb from "components/media/media-fb/mediaFb";
import BarChartWidget from "components/widgets/barChartWidget";
import DragHandleIcon from "../dragHandleIcon";

const Container = SortableContainer(({ children }) => {
  return (
    <Grid container spacing={1} className="live-dashboard-widget-container">
      {children}
    </Grid>
  );
});

const DragHandle = SortableHandle(() => <DragHandleIcon />);

const Element = SortableElement(
  ({
    widgetItem,
    showEditBtn,
    widgetSection,
    isLiveDashboardPublic,
    themesOptions,
  }) => {
    const intl = useIntl();
    if (widgetItem?.typeWidget === "pieChart") {
      return (
        <Grid
          item
          lg={6}
          md={6}
          sm={6}
          xs={12}
          className="dashboard-widget-wrapper-column"
        >
          <Box className="live-dashboard-widget-wrapper">
            {showEditBtn ? <DragHandle /> : null}
            <PieChartWidget
              title={widgetItem?.title}
              titleToolTip={widgetItem?.titleToolTip}
              data={widgetItem?.data}
              subData={widgetItem?.subData}
              color={widgetItem?.color}
              areaStyle={widgetItem?.areaStyle}
              toolTipLabel={widgetItem?.toolTipLabel}
              showDownloadIcon={widgetItem?.showDownloadIcon}
              showAI={widgetItem?.showAI}
              twitterIcon={widgetItem?.twitterIcon}
              pieChartPreLoaderDataSources={
                widgetItem?.pieChartPreLoaderDataSources
              }
              preLoaderTrackerDataSources={
                widgetItem?.preLoaderTrackerDataSources
              }
              iconAfterTitle={widgetItem?.iconAfterTitle}
              inspectorName={widgetItem?.inspectorName}
              handleOpenQuickInspect={widgetItem?.handleOpenQuickInspect}
              liveDashboardWidget
              widgetSection={widgetSection}
            />
          </Box>
        </Grid>
      );
    } else if (widgetItem?.typeWidget === "lineChart") {
      return (
        <Grid item lg={6} xs={12} className="dashboard-widget-wrapper-column">
          <Box className="live-dashboard-widget-wrapper">
            {showEditBtn ? <DragHandle /> : null}
            <LineChartWidget
              title={widgetItem?.title}
              titleToolTip={widgetItem?.titleToolTip}
              totalLabel={widgetItem?.totalLabel}
              totalValue={widgetItem?.totalValue}
              data={widgetItem?.data}
              showGroupBy={widgetItem?.showGroupBy}
              toolTipLabel={widgetItem?.toolTipLabel}
              labelColor={widgetItem?.labelColor}
              showDownloadIcon={widgetItem?.showDownloadIcon}
              bigImg={widgetItem?.bigImg}
              lineChartPreLoaderDataSources={
                widgetItem?.lineChartPreLoaderDataSources
              }
              preLoaderTrackerDataSources={
                widgetItem?.preLoaderTrackerDataSources
              }
              iconAfterTitle={widgetItem?.iconAfterTitle}
              handleOpenQuickInspect={widgetItem?.handleOpenQuickInspect}
              inspectorName={widgetItem?.inspectorName}
              haveData={widgetItem?.haveData}
              hideLegend={widgetItem?.hideLegend}
              showReportIcon={widgetItem?.showReportIcon}
              liveDashboardWidget
              widgetSection={widgetSection}
            />
          </Box>
        </Grid>
      );
    } else if (widgetItem?.typeWidget === "stackedLineChart") {
      return (
        <Grid item lg={6} xs={12} className="dashboard-widget-wrapper-column">
          <Box className="live-dashboard-widget-wrapper">
            {showEditBtn ? <DragHandle /> : null}
            <StackedLineWidget
              title={widgetItem?.title}
              titleToolTip={widgetItem?.titleToolTip}
              data={widgetItem?.data}
              showGroupBy={widgetItem?.showGroupBy}
              showScales={widgetItem?.showScales}
              areaStyle={widgetItem?.areaStyle}
              toolTipLabel={widgetItem?.toolTipLabel}
              showAI={widgetItem?.showAI}
              showDownloadIcon={widgetItem?.showDownloadIcon}
              bigImg={widgetItem?.bigImg}
              handleOpenQuickInspect={widgetItem?.handleOpenQuickInspect}
              inspectorName={widgetItem?.inspectorName}
              dataPiechart={widgetItem?.dataPiechart}
              hideLegend={widgetItem?.hideLegend}
              labelColors={widgetItem?.labelColors}
              isSwitchCase={widgetItem?.isSwitchCase}
              isSentimentAnalysisWidget={widgetItem?.isSentimentAnalysisWidget}
              isSentimentAnalysisShowCharts={
                widgetItem?.isSentimentAnalysisShowCharts
              }
              stackedLineWidgetPreLoaderDataSources={
                widgetItem?.stackedLineWidgetPreLoaderDataSources
              }
              preLoaderTrackerDataSources={
                widgetItem?.preLoaderTrackerDataSources
              }
              iconAfterTitle={widgetItem?.iconAfterTitle}
              showGroupByClientAgent={widgetItem?.showGroupByClientAgent}
              isPostsReachImpression={widgetItem?.isPostsReachImpression}
              labels={widgetItem?.labels}
              colors={widgetItem?.colors}
              showReportIcon={widgetItem?.showReportIcon}
              liveDashboardWidget
              widgetSection={widgetSection}
            />
          </Box>
        </Grid>
      );
    } else if (widgetItem?.typeWidget === "stackedBarChart") {
      return (
        <Grid item lg={6} xs={12} className="dashboard-widget-wrapper-column">
          <Box className="live-dashboard-widget-wrapper">
            {showEditBtn ? <DragHandle /> : null}
            <StackedBarWidget
              title={widgetItem?.title}
              titleToolTip={widgetItem?.titleToolTip}
              showDownloadIcon={widgetItem?.showDownloadIcon}
              bigImg={widgetItem?.bigImg}
              posts={widgetItem?.posts}
              colors={widgetItem?.colors}
              labels={widgetItem?.labels}
              showGroupBy={widgetItem?.showGroupBy}
              showScales={widgetItem?.showScales}
              showZoomPercentage={widgetItem?.showZoomPercentage}
              postsColumnPreLoaderDataSources={
                widgetItem?.postsColumnPreLoaderDataSources
              }
              hideLegend={widgetItem?.hideLegend}
              dataPiechart={widgetItem?.dataPiechart}
              showChartDetailsWidget={widgetItem?.showChartDetailsWidget}
              statsDetailsData={widgetItem?.statsDetailsData}
              isInteractionStatsWrapperStyle={
                widgetItem?.isInteractionStatsWrapperStyle
              }
              reachFunnel={widgetItem?.reachFunnel}
              iconAfterTitle={widgetItem?.iconAfterTitle}
              preLoaderTrackerDataSources={
                widgetItem?.preLoaderTrackerDataSources
              }
              showGroupByClientAgent={widgetItem?.showGroupByClientAgent}
              showReportIcon={widgetItem?.showReportIcon}
              isGenderAgeSorted={widgetItem?.isGenderAgeSorted}
              showPostsInteractionStats={widgetItem?.showPostsInteractionStats}
              liveDashboardWidget
              widgetSection={widgetSection}
            />
          </Box>
        </Grid>
      );
    } else if (widgetItem?.typeWidget === "tableWidget") {
      return (
        <Grid item lg={6} xs={12} className="dashboard-widget-wrapper-column">
          <Box className="live-dashboard-widget-wrapper">
            {showEditBtn ? <DragHandle /> : null}
            <TableWidget
              unixDate={widgetItem?.unixDate}
              showProfile={widgetItem?.showProfile}
              productId={widgetItem?.productId}
              monitorId={widgetItem?.monitorId}
              circularPP={widgetItem?.circularPP}
              itemsList={widgetItem?.itemsList}
              title={widgetItem?.title}
              titleToolTip={widgetItem?.titleToolTip}
              data={widgetItem?.data}
              leftColomnTitle={widgetItem?.leftColomnTitle}
              rightColomnTitle={widgetItem?.rightColomnTitle}
              tableChartPreLoaderDataSources={
                widgetItem?.tableChartPreLoaderDataSources
              }
              preLoaderTrackerDataSources={
                widgetItem?.preLoaderTrackerDataSources
              }
              twitterIcon={widgetItem?.twitterIcon}
              tablePagination={widgetItem?.tablePagination}
              count={widgetItem?.count}
              iconAfterTitle={widgetItem?.iconAfterTitle}
              wordsList={widgetItem?.wordsList}
              keywords={widgetItem?.keywords}
              handleOpenQuickInspect={widgetItem?.handleOpenQuickInspect}
              inspectorName={widgetItem?.inspectorName}
              dynamicClass={widgetItem?.dynamicClass}
              hashtags={widgetItem?.hashtags}
              showDownloadIcon={widgetItem?.showDownloadIcon}
              widgetSection={widgetSection}
              withWordCloud={widgetItem?.withWordCloud}
            />
          </Box>
        </Grid>
      );
    } else if (widgetItem?.typeWidget === "questionCard") {
      return (
        <Grid item xs={12} className="dashboard-widget-wrapper-column">
          <Box className="live-dashboard-widget-wrapper">
            {showEditBtn ? <DragHandle /> : null}
            <Box component="p" className="question-card-title">
              <Box component="span" className="widget-title">
                {CheckValueLocale(widgetItem?.questionCardTitle, "", {}, intl)}
                {widgetSection ? (
                  <span className="widget-title-section">
                    ({CheckValueLocale(widgetSection, "", {}, intl)})
                  </span>
                ) : (
                  ""
                )}
              </Box>
              {widgetItem?.questionCardIcon}
            </Box>
            <QuestionCard
              unixDate={widgetItem?.unixDate}
              showProfile={widgetItem?.showProfile}
              productId={widgetItem?.productId}
              latestUnanswer={widgetItem?.latestUnanswer}
              icon={widgetItem?.icon}
              iconColor={widgetItem?.iconColor}
              showBanIcon={widgetItem?.showBanIcon}
              latestUnanswerPreloader={widgetItem?.latestUnanswerPreloader}
              preLoaderTrackerDataSources={
                widgetItem?.preLoaderTrackerDataSources
              }
              updateAnalysis={widgetItem?.updateAnalysis}
              noOfChar={widgetItem?.noOfChar}
              singleTweetAnalysis={widgetItem?.singleTweetAnalysis}
              hideWhenRetweeted={widgetItem?.hideWhenRetweeted}
              showRetweet={widgetItem?.showRetweet}
              showHeart={widgetItem?.showHeart}
              showScreenName={widgetItem?.showScreenName}
              showDefaultImg={widgetItem?.showDefaultImg}
              hideChartBar={widgetItem?.hideChartBar}
              className={widgetItem?.className}
              widget={widgetItem?.widget}
              monitorType={widgetItem?.monitorType}
              insightsID={widgetItem?.insightsID}
              response={widgetItem?.response}
              dates={widgetItem?.dates}
              hideChangeSentiment={widgetItem?.hideChangeSentiment}
              isLiveDashboardPublic={isLiveDashboardPublic}
              datasourcePostsType="FACEBOOK"
              isLiveDashboard={true}
            />
          </Box>
        </Grid>
      );
    } else if (widgetItem?.typeWidget === "punchCard") {
      return (
        <Grid item lg={6} xs={12} className="dashboard-widget-wrapper-column">
          <Box className="live-dashboard-widget-wrapper">
            {showEditBtn ? <DragHandle /> : null}
            <PunchCardWidget
              title={widgetItem?.title}
              titleToolTip={widgetItem?.titleToolTip}
              clientActivity={widgetItem?.clientActivity}
              minValueClient={widgetItem?.minValueClient}
              maxValueClient={widgetItem?.maxValueClient}
              showDownloadIcon={widgetItem?.showDownloadIcon}
              bigImg={widgetItem?.bigImg}
              iconAfterTitle={widgetItem?.iconAfterTitle}
              authorActivityPreLoaderDataSources={
                widgetItem?.authorActivityPreLoaderDataSources
              }
              preLoaderTrackerDataSources={
                widgetItem?.preLoaderTrackerDataSources
              }
              liveDashboardWidget
              widgetSection={widgetSection}
            />
          </Box>
        </Grid>
      );
    } else if (widgetItem?.typeWidget === "themeSection") {
      return (
        <Grid item xs={12} className="dashboard-widget-wrapper-column">
          <Box className="live-dashboard-widget-wrapper">
            {showEditBtn ? <DragHandle /> : null}
            <ThemeSection
              iconAfterTitle={widgetItem?.iconAfterTitle}
              preLoaderTrackerDataSources={
                widgetItem?.preLoaderTrackerDataSources
              }
              eventName={widgetItem?.eventName}
              resetAllFields={widgetItem?.resetAllFields}
              response={widgetItem?.response}
              themeTrendPreLoader={widgetItem?.themeTrendPreLoader}
              setThemeTrendPreLoader={widgetItem?.setThemeTrendPreLoader}
              themeAnalysisPreLoader={widgetItem?.themeAnalysisPreLoader}
              setThemeAnalysisPreLoader={widgetItem?.setThemeAnalysisPreLoader}
              liveDashboardWidget
              widgetSection={widgetSection}
              isLiveDashboardPublic={isLiveDashboardPublic}
              themesOptions={themesOptions}
              isLiveDashboard
            />
          </Box>
        </Grid>
      );
    } else if (widgetItem?.typeWidget === "mediaFb") {
      return (
        <Grid item xs={12} className="dashboard-widget-wrapper-column">
          <Box className="live-dashboard-widget-wrapper media-facebook-wrapper">
            {showEditBtn ? <DragHandle /> : null}
            <MediaFb
              title={widgetItem?.title}
              data={widgetItem?.data}
              errorMessage={widgetItem?.errorMessage}
              iconAfterTitle={widgetItem?.iconAfterTitle}
              mediaFbPreLoaderDataSources={
                widgetItem?.mediaFbPreLoaderDataSources
              }
              preLoaderTrackerDataSources={
                widgetItem?.preLoaderTrackerDataSources
              }
              showDownloadIcon={widgetItem?.showDownloadIcon}
              questions={widgetItem?.questions}
              titleToolTip={widgetItem?.titleToolTip}
              insightsID={widgetItem?.insightsID}
              response={widgetItem?.response}
              dates={widgetItem?.dates}
              liveDashboardWidget
              widgetSection={widgetSection}
              isCustomDashboard={isLiveDashboardPublic}
            />
          </Box>
        </Grid>
      );
    } else if (widgetItem?.typeWidget === "barChart") {
      return (
        <Grid item lg={6} xs={12} className="dashboard-widget-wrapper-column">
          <Box className="live-dashboard-widget-wrapper">
            {showEditBtn ? <DragHandle /> : null}
            <div className="chart-body">
              <BarChartWidget
                title={widgetItem?.title}
                titleToolTip={widgetItem?.titleToolTip}
                data={widgetItem?.data}
                showAI={widgetItem?.showAI}
                showDownloadIcon={widgetItem?.showDownloadIcon}
                showChartStatsWidget={widgetItem?.showChartStatsWidget}
                showZoomPercentage={widgetItem?.showZoomPercentage}
                customerCareChart={widgetItem?.customerCareChart}
                gridHeight={widgetItem?.gridHeight}
                respTimeDistributionTooltip={
                  widgetItem?.respTimeDistributionTooltip
                }
                statsData={widgetItem?.statsData}
                dataColor={widgetItem?.dataColor}
                toolTipLabel={widgetItem?.toolTipLabel}
                isTwitterAccount={widgetItem?.isTwitterAccount}
                iconAfterTitle={widgetItem?.iconAfterTitle}
                barChartPreLoaderDataSources={
                  widgetItem?.barChartPreLoaderDataSources
                }
                preLoaderTrackerDataSources={
                  widgetItem?.preLoaderTrackerDataSources
                }
                bigImg={widgetItem?.bigImg}
                showFollowersGrowthStats={widgetItem?.showFollowersGrowthStats}
                showGroupBy={widgetItem?.showGroupBy}
                showChartDetailsWidget={widgetItem?.showChartDetailsWidget}
                statsDetailsData={widgetItem?.statsDetailsData}
                liveDashboardWidget
                widgetSection={widgetSection}
              />
            </div>
          </Box>
        </Grid>
      );
    } else {
      return "";
    }
  },
);

const DataSourceContent = ({
  resetAllFields,
  response,
  preLoaderTrackerDataSources,
  unixDate,
  singleTweetAnalysis,
  facebookWidgetTypes,
  setFacebookWidgetTypes,
  sortWidgets,
  getNameKeyWidget,
  showEditBtn,
  setTotalStatisticWidgets,
  setLoadingStatisticWidgets,
  themesOptions,
}) => {
  const intl = useIntl();
  const urlParams = useParams();
  const monitorId = urlParams?.monitorId;
  const monitorType = urlParams?.monitorType;
  const isLiveDashboardPublic = window?.location?.href?.includes(
    "/live-dashboard/public/",
  );

  const [insightsID, setInsightsID] = useState([]);
  const [insightsResponse, setInsightsResponse] = useState([]);
  const [dates, setDates] = useState([]);

  const [commentsVolumePreLoader, setCommentsVolumePreLoader] = useState([]);
  const [commentsVolume, setCommentsVolume] = useState([]);
  const [totalCommentsVolumeCount, setTotalCommentsVolumeCount] = useState([]);
  // _____________________________________________
  const [reactions, setReactions] = useState([]);
  const [reactionsPreLoader, setReactionsPreLoader] = useState([]);
  const [pageReactionPieChartValue, setPageReactionPieChartValue] = useState(
    [],
  );
  //______________________________________________
  const [sentimentAnalysis, setSentimentAnalysis] = useState([]);
  const [sentimentAnalysisPreLoader, setSentimentAnalysisPreLoader] = useState(
    [],
  );
  const [sentimentPieChart, setSentimentAnalysisPieChart] = useState([]);
  const [negativeArray, setNegativeArray] = useState([]);
  const [positiveArray, setPositiveArray] = useState([]);
  const [neutralArray, setNeutralArray] = useState([]);
  //_________________________________________
  const [commentsInteractions, setCommentsInteractions] = useState([]);
  const [commentsInteractionsPreLoader, setCommentsInteractionsPreLoader] =
    useState([]);
  const [commentsInteractionsLegend, setCommentsInteractionsLegend] = useState(
    [],
  );

  //_________________________________________
  const [topKeywords, setTopKeywords] = useState([]);
  const [topKeywordsPreLoader, setTopKeywordsPreLoader] = useState([]);

  // _____________________________________________
  const [topHashtags, setTopHashtags] = useState([]);
  const [topHashtagsPreLoader, setTopHashtagsPreLoader] = useState([]);
  // _____________________________________________

  const [associatedTopics, setAssociatedTopics] = useState([]);
  const [associatedTopicsPreLoader, setAssociatedTopicsPreLoader] = useState(
    [],
  );
  // _____________________________________________
  const [topPosts, setTopPosts] = useState([]);
  const [topPostsCount, setTopPostsCount] = useState(0);
  const [topPostsPreLoader, setTopPostsPreLoader] = useState([]);
  // ___________________________________________________________
  const [dialects, setDialects] = useState([]);
  const [subDialects, setSubDialects] = useState([]);
  const [dialectsPreLoader, setDialectsPreLoader] = useState([]);
  // ___________________________________________________________
  const [totalValuePostsInteractions, setTotalValuePostsInteractions] =
    useState(0);
  const [maxValuePostsInteractions, setMaxValuePostsInteractions] = useState(0);
  const [maxValueInteractionsDate, setMaxValueInteractionsDate] = useState(0);
  const [avgValuePostsInteractions, setAvgValuePostsInteractions] = useState(0);

  //____________________________________________
  const [authorActivity, setAuthorActivity] = useState([]);
  const [
    authorActivityPreLoaderDataSources,
    setAuthorActivityPreLoaderDataSources,
  ] = useState([]);
  const [minValueAuthorActivity, setMinValueAuthorActivity] = useState(0);
  const [maxValueAuthorActivity, setMaxValueAuthorActivity] = useState(0);

  const [engagersVolume, setEngagersVolume] = useState([]);
  const [totalEngagersVolumeCount, setTotalEngagersVolumeCount] = useState([]);
  const [engagersVolumePreLoader, setEngagersVolumePreLoader] = useState([]);
  const [genderDistribution, setGenderDistribution] = useState([]);
  const [genderDistributionPreLoader, setGenderDistributionPreLoader] =
    useState([]);
  const [genderDistributionPieChartValue, setGenderDistributionPieChartValue] =
    useState([]);
  const [topLanguages, setTopLanguages] = useState([]);
  const [topLanguagesPreLoader, setTopLanguagesPreLoader] = useState([]);
  const [topCities, setTopCities] = useState([]);
  const [topCitiesPreLoader, setTopCitiesPreLoader] = useState([]);
  const [topCountries, setTopCountries] = useState([]);
  const [topCountriesPreLoader, setTopCountriesPreLoader] = useState([]);
  const [themeTrendPreLoader, setThemeTrendPreLoader] = useState([]);
  const [themeAnalysisPreLoader, setThemeAnalysisPreLoader] = useState([]);
  // ___________________________________________

  // account section
  const [pageViews, setPageViews] = useState([]);
  const [pageLikeUnlike, setPageLikeUnlike] = useState([]);
  const [pageLikeUnlikePreLoader, setPageLikeUnlikePreLoader] = useState([]);
  const [pageLikeUnlikeLegend, setPageLikeUnlikeLegend] = useState([]);
  const [pageReachImpression, setPageReachImpression] = useState([]);
  const [pageReachImpressionPreLoader, setPageReachImpressionPreLoader] =
    useState([]);
  const [pageReachImpressionLegend, setPageReachImpressionLegend] = useState(
    [],
  );
  const [reachArray, setReachArray] = useState([]);
  const [impressionArray, setImpressionArray] = useState([]);
  const [pageContactDetailsInteractions, setPageContactDetailsInteractions] =
    useState([]);
  const [
    pageContactDetailsInteractionsPreLoader,
    setPageContactDetailsInteractionsPreLoader,
  ] = useState([]);
  const [pageContactDetailsLegend, setPageContactDetailsLegend] = useState([]);
  const [pageActions, setPageActions] = useState([]);
  const [pageActionsPreLoader, setPageActionsPreLoader] = useState([]);
  const [pageActionsLegend, setPageActionsLegend] = useState([]);
  const [accountAuthorActivity, setAccountAuthorActivity] = useState([]);
  const [accountAuthorActivityPreLoader, setAccountAuthorActivityPreLoader] =
    useState([]);
  const [pageViewsPreLoader, setPageViewsPreLoader] = useState([]);

  const [postsVolume, setPostsVolume] = useState([]);
  const [totalPostsVolumeCount, setTotalPostsVolumeCount] = useState([]);
  const [postsVolumePreLoader, setPostsVolumePreLoader] = useState([]);
  const [videosViews, setVideosViews] = useState([]);
  const [totalVideosViewsCount, setTotalVideosViewsCount] = useState([]);
  const [videosViewsPreLoader, setVideosViewsPreLoader] = useState([]);
  const [postReachImpression, setPostReachImpression] = useState([]);
  const [totalPostReachImpression, setTotalPostReachImpression] = useState([]);
  const [postReachImpressionPreLoader, setPostReachImpressionPreLoader] =
    useState([]);
  const [reachAnalyticsArray, setReachAnalyticsArray] = useState([]);
  const [impressionAnalyticsArray, setImpressionAnalyticsArray] = useState([]);

  // ___________________________________________________________

  const [postsInteractions, setPostsInteractions] = useState([]);
  const [postsInteractionsPreLoader, setPostsInteractionsPreLoader] = useState(
    [],
  );
  const [postsInteractionsLegend, setPostsInteractionsLegend] = useState([]);
  const [reactionsAcount, setReactionsAcount] = useState([]);
  const [reactionsAcountPreLoader, setReactionsAcountPreLoader] = useState([]);
  const [postsContentType, setPostsContentType] = useState([]);
  const [postsContentTypePreLoader, setPostsContentTypePreLoader] = useState(
    [],
  );
  const [topKeywordsAcount, setTopKeywordsAcount] = useState([]);
  const [topKeywordsAcountPreLoader, setTopKeywordsAcountPreLoader] = useState(
    [],
  );
  const [topHashtagsAcount, setTopHashtagsAcount] = useState([]);
  const [topHashtagsAcountPreLoader, setTopHashtagsAcountPreLoader] = useState(
    [],
  );
  const [topPostsAcount, setTopPostsAcount] = useState([]);
  const [topPostsAcountPreLoader, setTopPostsAcountPreLoader] = useState([]);
  const [topVideos, setTopVideos] = useState([]);
  const [topVideosPreLoader, setTopVideosPreLoader] = useState([]);
  const [topReels, setTopReels] = useState([]);
  const [topReelsPreLoader, setTopReelsPreLoader] = useState([]);
  const [topImages, setTopImages] = useState([]);
  const [topImagesPreLoader, setTopImagesPreLoader] = useState([]);
  const [topPostsAcountCount, setTopPostsAcountCount] = useState([]);
  const [maxValuePostsInteractionsAcount, setMaxValuePostsInteractionsAcount] =
    useState(0);
  const [minValuePostsInteractionsAcount, setMinValuePostsInteractionsAcount] =
    useState(0);
  const [avgValuePostsInteractionsAcount, setAvgValuePostsInteractionsAcount] =
    useState(0);
  const [maxValueInteractionsDateAcount, setMaxValueInteractionsDateAcount] =
    useState(0);
  const [minValueInteractionsDate, setMinValueInteractionsDate] = useState(0);
  const [pageReactionPieChartValueAcount, setPageReactionPieChartValueAcount] =
    useState([]);
  const [postContentPieChartValue, setPostContentPieChartValue] = useState([]);
  const [totalPageViewsCount, setTotalPageViewsCount] = useState([]);
  const [minValueAuthorActivityAcount, setMinValueAuthorActivityAcount] =
    useState(0);
  const [maxValueAuthorActivityAcount, setMaxValueAuthorActivityAcount] =
    useState(0);

  // customer care section
  const [latestUnanswer, setLatestUnanswer] = useState([]);
  const [customerCarePerformance, setCustomercarePerformance] = useState({});
  const [latestUnanswerPreloader, setLatestUnanswerPreloader] = useState([]);
  const [customerCarePreloader, setCustomercarePreloader] = useState([]);
  const [unAnsweredQuestion, setUnanswerQuestion] = useState(0);
  const [respRate, setRespRate] = useState(0);
  const [totalQuestion, setTotalQuestion] = useState(0);
  const [customerCareData, setcustomerCareData] = useState([]);

  // posts section
  const [topPostsPosts, setTopPostsPosts] = useState([]);
  const [
    topTopPostsPostsPreLoaderDataSources,
    setTopPostsPostsPreLoaderDataSources,
  ] = useState([]);

  // comments mentions section
  const [commentsMentions, setCommentsMentions] = useState([]);

  const [commentsMentionsPreloader, setCommentsMentionsPreloader] = useState(
    [],
  );

  // questions section
  const [commentsMentionsQuestions, setCommentsMentionsQuestions] = useState(
    [],
  );
  const [
    commentsMentionsQuestionsPreloader,
    setCommentsMentionsQuestionsPreloader,
  ] = useState([]);

  useEffect(() => {
    setInsightsResponse(
      localStorage.getItem("moreInsightsResp") !== null
        ? JSON.parse(localStorage.getItem("moreInsightsResp"))
        : [],
    );
    setInsightsID(
      localStorage.getItem("moreInsightsIds") !== null
        ? JSON.parse(localStorage.getItem("moreInsightsIds"))
        : [],
    );
    setDates(
      localStorage.getItem("moreInsightsDates") !== null
        ? JSON.parse(localStorage.getItem("moreInsightsDates"))
        : [],
    );
  }, []);

  let interactionsStats = [
    {
      name: "total_interactions",
      value: totalValuePostsInteractions,
    },
    {
      name: "max_interaction",
      value: maxValuePostsInteractions,
      date: maxValueInteractionsDate,
    },
    {
      name: "avg_interaction_per_day",
      value: avgValuePostsInteractions,
    },
  ];
  // ___________________________________________________________

  const FacebookIcon = (
    <span className="topics-twitter-icon">
      <img src={publicFacebook} className="icon-after-title facebook" />
    </span>
  );

  const facebookWidgetObj = {
    Facebook__EngagementsPage__volume_overtime: {
      typeWidget: "lineChart",
      title: getNameKeyWidget("Facebook__EngagementsPage__volume_overtime"),
      totalLabel: "posts",
      totalValue: totalCommentsVolumeCount,
      data: commentsVolume,
      showGroupBy: !isLiveDashboardPublic,
      toolTipLabel: "posts",
      titleToolTip: "posts_volume_fb_tooltip",
      showDownloadIcon: !isLiveDashboardPublic,
      bigImg: !isLiveDashboardPublic,
      lineChartPreLoaderDataSources: commentsVolumePreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      iconAfterTitle: FacebookIcon,
      labelColor: "#2AAAE2",
    },
    Facebook__EngagementsPage__posts_interactions: {
      typeWidget: "stackedBarChart",
      title: getNameKeyWidget("Facebook__EngagementsPage__posts_interactions"),
      titleToolTip: "posts_interactions_fb_tooltip",
      showDownloadIcon: !isLiveDashboardPublic,
      bigImg: !isLiveDashboardPublic,
      posts: commentsInteractions,
      colors: ["#2AAAE2", "#80868C"],
      labels: ["comments", "reactions"],
      showZoomPercentage: true,
      iconAfterTitle: FacebookIcon,
      postsColumnPreLoaderDataSources: commentsInteractionsPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      showGroupByClientAgent: true,
      showScales: !isLiveDashboardPublic,
      hideLegend: true,
      dataPiechart: commentsInteractionsLegend,
      showChartDetailsWidget: true,
      statsDetailsData: interactionsStats,
      isInteractionStatsWrapperStyle: true,
    },
    Facebook__EngagementsPage__reactions: {
      typeWidget: "stackedBarChart",
      title: getNameKeyWidget("Facebook__EngagementsPage__reactions"),
      titleToolTip: "reactions_fb_tooltip",
      showDownloadIcon: !isLiveDashboardPublic,
      bigImg: !isLiveDashboardPublic,
      posts: reactions,
      showZoomPercentage: true,
      iconAfterTitle: FacebookIcon,
      postsColumnPreLoaderDataSources: reactionsPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      showGroupByClientAgent: true,
      showScales: !isLiveDashboardPublic,
      labels: [
        "like_reaction",
        "love_reaction",
        "care_reaction",
        "haha_reaction",
        "wow_reaction",
        "angry_reaction",
        "sad_reaction",
      ],
      hideLegend: true,
      dataPiechart: pageReactionPieChartValue,
      colors: [
        "#1892F2",
        "#FB5C77",
        "#F7B94D",
        "#FEDC5F",
        "#00D7B5",
        "#EC8A92",
        "#1C404E",
      ],
    },
    Facebook__EngagementsPage__sentiment_analysis: {
      typeWidget: "stackedLineChart",
      title: getNameKeyWidget("Facebook__EngagementsPage__sentiment_analysis"),
      titleToolTip: "comments_sentiment_analysis_fb_tooltip",
      data: sentimentAnalysis,
      showGroupBy: !isLiveDashboardPublic,
      areaStyle: true,
      toolTipLabel: "toolTipLabel",
      showAI: false,
      hideLegend: true,
      showDownloadIcon: !isLiveDashboardPublic,
      bigImg: !isLiveDashboardPublic,
      stackedLineWidgetPreLoaderDataSources: sentimentAnalysisPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      isSwitchCase: true,
      iconAfterTitle: FacebookIcon,
      showGroupByClientAgent: true,
      showScales: !isLiveDashboardPublic,
      dataPiechart: sentimentPieChart,
      labelColors: ["#89BB2A", "#E50035", "#F9A700"],
      isSentimentAnalysisWidget: true,
      isSentimentAnalysisShowCharts: true,
    },
    Facebook__EngagementsPage__top_keywords: {
      typeWidget: "tableWidget",
      wordsList: true,
      title: getNameKeyWidget("Facebook__EngagementsPage__top_keywords"),
      titleToolTip: "posts_top_keywords_fb_tooltip",
      data: topKeywords,
      leftColomnTitle: "keywords",
      rightColomnTitle: "number_of_posts",
      tableChartPreLoaderDataSources: topKeywordsPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      keywords: true,
      tablePagination: true,
      count: topKeywords?.length,
      iconAfterTitle: FacebookIcon,
      withWordCloud: true,
      showDownloadIcon: true,
    },
    Facebook__EngagementsPage__top_hashtags: {
      typeWidget: "tableWidget",
      wordsList: true,
      title: getNameKeyWidget("Facebook__EngagementsPage__top_hashtags"),
      titleToolTip: "posts_top_hashtags_ig_tooltip",
      data: topHashtags,
      leftColomnTitle: "hashtags",
      rightColomnTitle: "number_of_posts",
      tableChartPreLoaderDataSources: topHashtagsPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      hashtags: true,
      tablePagination: true,
      count: topHashtags?.length,
      iconAfterTitle: FacebookIcon,
      withWordCloud: true,
      showDownloadIcon: true,
    },
    Facebook__EngagementsPage__associated_topics: {
      typeWidget: "pieChart",
      title: getNameKeyWidget("Facebook__EngagementsPage__associated_topics"),
      titleToolTip: "posts_associated_topics_fb_tooltip",
      data: associatedTopics,
      areaStyle: true,
      toolTipLabel: "toolTipLabel",
      showDownloadIcon: !isLiveDashboardPublic,
      showAI: !isLiveDashboardPublic,
      pieChartPreLoaderDataSources: associatedTopicsPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      iconAfterTitle: FacebookIcon,
    },
    Facebook__EngagementsPage__top_posts_comments: {
      typeWidget: "questionCard",
      questionCardTitle: getNameKeyWidget(
        "Facebook__EngagementsPage__top_posts_comments",
      ),
      questionCardIcon: FacebookIcon,
      unixDate: unixDate,
      // showProfile: true,
      productId: window.localStorage?.getItem("sm_id"),
      latestUnanswer: topPosts?.slice(0, 6),
      icon: faFacebook,
      iconColor: "facebook_icon",
      showHeart: true,
      hideChartBar: true,
      showDefaultImg: true,
      className: "no-data-found-question",
      latestUnanswerPreloader: topPostsPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      widget: "top_posts",
      monitorType: monitorType === "KEYWORD" ? "keyword" : null,
    },
    Facebook__EngagementsPage__dialects_and_sub_dialects: {
      typeWidget: "pieChart",
      title: getNameKeyWidget(
        "Facebook__EngagementsPage__dialects_and_sub_dialects",
      ),
      titleToolTip: "dialects_subdialects_ig_tooltip",
      data: dialects,
      subData: subDialects,
      areaStyle: true,
      toolTipLabel: "toolTipLabel",
      iconAfterTitle: FacebookIcon,
      showAI: !isLiveDashboardPublic,
      showDownloadIcon: !isLiveDashboardPublic,
      pieChartPreLoaderDataSources: dialectsPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
    },
    Facebook__EngagementsPage__account_author_activity: {
      typeWidget: "punchCard",
      title: getNameKeyWidget(
        "Facebook__EngagementsPage__account_author_activity",
      ),
      titleToolTip: "engagers_activity__account_fb_tooltip",
      clientActivity: authorActivity,
      minValueClient: minValueAuthorActivity,
      maxValueClient: maxValueAuthorActivity,
      showDownloadIcon: !isLiveDashboardPublic,
      bigImg: !isLiveDashboardPublic,
      iconAfterTitle: FacebookIcon,
      authorActivityPreLoaderDataSources: authorActivityPreLoaderDataSources,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
    },
    Facebook__EngagementsPage__engagers_volume: {
      typeWidget: "lineChart",
      title: getNameKeyWidget("Facebook__EngagementsPage__engagers_volume"),
      titleToolTip: "engagers_volume_fb_tooltip",
      totalLabel: "engagers",
      totalValue: totalEngagersVolumeCount,
      data: engagersVolume,
      showGroupBy: !isLiveDashboardPublic,
      toolTipLabel: "engagers",
      showDownloadIcon: !isLiveDashboardPublic,
      bigImg: !isLiveDashboardPublic,
      hideLegend: true,
      lineChartPreLoaderDataSources: engagersVolumePreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      iconAfterTitle: FacebookIcon,
      labelColor: "#2AAAE2",
    },
    Facebook__EngagementsPage__gender_distribution: {
      typeWidget: "stackedBarChart",
      title: getNameKeyWidget("Facebook__EngagementsPage__gender_distribution"),
      titleToolTip: "gender_distribution_fb_tooltip",
      showDownloadIcon: !isLiveDashboardPublic,
      bigImg: !isLiveDashboardPublic,
      posts: genderDistribution,
      labels: ["male", "female"],
      hideLegend: true,
      dataPiechart: genderDistributionPieChartValue,
      colors: ["#2AAAE2", "#FF4081"],
      showZoomPercentage: true,
      showScales: !isLiveDashboardPublic,
      iconAfterTitle: FacebookIcon,
      postsColumnPreLoaderDataSources: genderDistributionPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      showGroupByClientAgent: true,
      showReportIcon: true,
      reachFunnel: true,
      isGenderAgeSorted: true,
    },
    Facebook__EngagementsPage__top_languages: {
      typeWidget: "pieChart",
      title: getNameKeyWidget("Facebook__EngagementsPage__top_languages"),
      titleToolTip: "top_languages_fb_tooltip",
      data: topLanguages,
      areaStyle: true,
      toolTipLabel: "toolTipLabel",
      showDownloadIcon: !isLiveDashboardPublic,
      showAI: !isLiveDashboardPublic,
      pieChartPreLoaderDataSources: topLanguagesPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      iconAfterTitle: FacebookIcon,
      showReportIcon: true,
    },
    Facebook__EngagementsPage__top_countries: {
      typeWidget: "pieChart",
      title: getNameKeyWidget("Facebook__EngagementsPage__top_countries"),
      titleToolTip: "top_countries_fb_tooltip",
      data: topCountries,
      areaStyle: true,
      toolTipLabel: "toolTipLabel",
      showDownloadIcon: !isLiveDashboardPublic,
      pieChartPreLoaderDataSources: topCountriesPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      iconAfterTitle: FacebookIcon,
      showReportIcon: true,
    },
    Facebook__EngagementsPage__top_cities: {
      typeWidget: "pieChart",
      title: getNameKeyWidget("Facebook__EngagementsPage__top_cities"),
      titleToolTip: "top_cities_fb_tooltip",
      data: topCities,
      areaStyle: true,
      toolTipLabel: "toolTipLabel",
      showDownloadIcon: !isLiveDashboardPublic,
      pieChartPreLoaderDataSources: topCitiesPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      iconAfterTitle: FacebookIcon,
      showReportIcon: true,
    },
    Facebook__EngagementsPage__themes_and_sub_themes: {
      typeWidget: "themeSection",
      iconAfterTitle: FacebookIcon,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      eventName: "Facebook__EngagementsPage__themes_and_sub_themes",
      resetAllFields: resetAllFields,
      response: response,
      themeTrendPreLoader: themeTrendPreLoader,
      setThemeTrendPreLoader: setThemeTrendPreLoader,
      themeAnalysisPreLoader: themeAnalysisPreLoader,
      setThemeAnalysisPreLoader: setThemeAnalysisPreLoader,
    },
    // account section
    Facebook__AccountPage__volume_overtime: {
      typeWidget: "lineChart",
      title: getNameKeyWidget("Facebook__AccountPage__volume_overtime"),
      totalLabel: "posts",
      totalValue: totalPostsVolumeCount,
      data: postsVolume,
      showGroupBy: !isLiveDashboardPublic,
      toolTipLabel: "posts",
      showDownloadIcon: !isLiveDashboardPublic,
      bigImg: !isLiveDashboardPublic,
      hideLegend: true,
      lineChartPreLoaderDataSources: postsVolumePreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      iconAfterTitle: FacebookIcon,
      labelColor: "#2AAAE2",
      titleToolTip: "posts_volume_fb_tooltip",
    },
    Facebook__AccountPage__reach_funnel: {
      typeWidget: "stackedLineChart",
      title: getNameKeyWidget("Facebook__AccountPage__reach_funnel"),
      data: postReachImpression,
      showGroupBy: !isLiveDashboardPublic,
      areaStyle: true,
      toolTipLabel: "toolTipLabel",
      showAI: false,
      showDownloadIcon: !isLiveDashboardPublic,
      bigImg: !isLiveDashboardPublic,
      isPostsReachImpression: true,
      stackedLineWidgetPreLoaderDataSources: postReachImpressionPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      isSwitchCase: true,
      iconAfterTitle: FacebookIcon,
      showGroupByClientAgent: true,
      showScales: !isLiveDashboardPublic,
      labels: ["reach", "impressions"],
      labelColors: ["#2AAAE2", "#80868C"],
      colors: ["#2AAAE2", "#80868C"],
      titleToolTip: "posts_reach_impressions_fb_tooltip",
      hideLegend: true,
      dataPiechart: totalPostReachImpression,
    },
    Facebook__AccountPage__posts_interactions: {
      typeWidget: "stackedBarChart",
      title: getNameKeyWidget("Facebook__AccountPage__posts_interactions"),
      showDownloadIcon: !isLiveDashboardPublic,
      bigImg: !isLiveDashboardPublic,
      posts: postsInteractions,
      showChartDetailsWidget: true,
      showScales: !isLiveDashboardPublic,
      showGroupBy: false,
      statsDetailsData: [
        {
          name: "max_interaction",
          value: maxValuePostsInteractionsAcount,
          date: maxValueInteractionsDateAcount,
        },
        {
          name: "min_interaction",
          value: minValuePostsInteractionsAcount,
          date: minValueInteractionsDate,
        },
        {
          name: "avg_interaction_per_day",
          value: avgValuePostsInteractionsAcount,
        },
      ],
      colors: ["#2AAAE2", "#80868C"],
      labels: ["comments", "reactions"],
      showPostsInteractionStats: true,
      showZoomPercentage: true,
      iconAfterTitle: FacebookIcon,
      postsColumnPreLoaderDataSources: postsInteractionsPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      hideLegend: true,
      dataPiechart: postsInteractionsLegend,
      titleToolTip: "posts_interactions_fb_tooltip",
    },
    Facebook__AccountPage__reactions: {
      typeWidget: "stackedBarChart",
      title: getNameKeyWidget("Facebook__AccountPage__reactions"),
      showDownloadIcon: !isLiveDashboardPublic,
      bigImg: !isLiveDashboardPublic,
      posts: reactionsAcount,
      showZoomPercentage: true,
      iconAfterTitle: FacebookIcon,
      postsColumnPreLoaderDataSources: reactionsAcountPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      showGroupByClientAgent: true,
      showScales: !isLiveDashboardPublic,
      labels: [
        "like_reaction",
        "love_reaction",
        "care_reaction",
        "haha_reaction",
        "wow_reaction",
        "angry_reaction",
        "sad_reaction",
      ],
      hideLegend: true,
      dataPiechart: pageReactionPieChartValueAcount,
      colors: [
        "#1892F2",
        "#FB5C77",
        "#F7B94D",
        "#FEDC5F",
        "#00D7B5",
        "#EC8A92",
        "#1C404E",
      ],
      titleToolTip: "reactions_fb_tooltip",
    },
    Facebook__AccountPage__content_type: {
      typeWidget: "stackedBarChart",
      title: getNameKeyWidget("Facebook__AccountPage__content_type"),
      showDownloadIcon: !isLiveDashboardPublic,
      bigImg: !isLiveDashboardPublic,
      posts: postsContentType,
      labels: ["post_content_type", "photo_content_type", "video_content_type"],
      hideLegend: true,
      dataPiechart: postContentPieChartValue,
      colors: ["#2AAAE2", "#80868C", "#334155"],
      showZoomPercentage: true,
      iconAfterTitle: FacebookIcon,
      postsColumnPreLoaderDataSources: postsContentTypePreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      showGroupByClientAgent: true,
      showScales: !isLiveDashboardPublic,
      titleToolTip: "posts_content_type_fb_tooltip",
    },
    Facebook__AccountPage__page_videos_views: {
      typeWidget: "lineChart",
      title: getNameKeyWidget("Facebook__AccountPage__page_videos_views"),
      totalLabel: "video_content_type",
      totalValue: totalVideosViewsCount,
      data: videosViews,
      showGroupBy: !isLiveDashboardPublic,
      toolTipLabel: "video_content_type",
      showDownloadIcon: !isLiveDashboardPublic,
      bigImg: !isLiveDashboardPublic,
      lineChartPreLoaderDataSources: videosViewsPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      iconAfterTitle: FacebookIcon,
      labelColor: "#2AAAE2",
      showReportIcon: true,
      titleToolTip: "videos_views_fb_tooltip",
    },
    Facebook__AccountPage__top_keywords: {
      typeWidget: "tableWidget",
      wordsList: true,
      title: getNameKeyWidget("Facebook__AccountPage__top_keywords"),
      data: topKeywordsAcount,
      leftColomnTitle: "keywords",
      rightColomnTitle: "number_of_posts",
      tableChartPreLoaderDataSources: topKeywordsAcountPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      keywords: true,
      tablePagination: true,
      count: topKeywordsAcount.length,
      showDownloadIcon: !isLiveDashboardPublic,
      iconAfterTitle: FacebookIcon,
      titleToolTip: "posts_top_keywords_fb_tooltip",
      withWordCloud: true,
    },
    Facebook__AccountPage__top_hashtags: {
      typeWidget: "tableWidget",
      wordsList: true,
      title: getNameKeyWidget("Facebook__AccountPage__top_hashtags"),
      data: topHashtagsAcount,
      leftColomnTitle: "hashtags",
      rightColomnTitle: "number_of_posts",
      tableChartPreLoaderDataSources: topHashtagsAcountPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      hashtags: true,
      tablePagination: true,
      count: topHashtagsAcount.length,
      showDownloadIcon: !isLiveDashboardPublic,
      iconAfterTitle: FacebookIcon,
      titleToolTip: "posts_top_hashtags_fb_tooltip",
      withWordCloud: true,
    },
    Facebook__AccountPage__top_images: {
      typeWidget: "mediaFb",
      title: CheckValueLocale(
        getNameKeyWidget("Facebook__AccountPage__top_images"),
        "",
        {},
        intl,
      ),
      data: topImages,
      errorMessage: CheckValueLocale("no_images_found", "", {}, intl),
      iconAfterTitle: FacebookIcon,
      mediaFbPreLoaderDataSources: topImagesPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      showDownloadIcon: !isLiveDashboardPublic,
      questions: topImages,
      titleToolTip: "posts_top_images_tooltip",
      insightsID: insightsID,
      response: insightsResponse,
      dates: dates,
    },
    Facebook__AccountPage__top_videos: {
      typeWidget: "mediaFb",
      title: CheckValueLocale(
        getNameKeyWidget("Facebook__AccountPage__top_videos"),
        "",
        {},
        intl,
      ),
      data: topVideos,
      errorMessage: CheckValueLocale("no_videos_found", "", {}, intl),
      iconAfterTitle: FacebookIcon,
      mediaFbPreLoaderDataSources: topVideosPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      showDownloadIcon: !isLiveDashboardPublic,
      questions: topVideos,
      titleToolTip: "posts_top_videos_tooltip",
      insightsID: insightsID,
      response: insightsResponse,
      dates: dates,
    },
    Facebook__AccountPage__top_reels: {
      typeWidget: "mediaFb",
      title: CheckValueLocale(
        getNameKeyWidget("Facebook__AccountPage__top_reels"),
        "",
        {},
        intl,
      ),
      data: topReels,
      iconAfterTitle: FacebookIcon,
      mediaFbPreLoaderDataSources: topReelsPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      showDownloadIcon: !isLiveDashboardPublic,
      questions: topReels,
      titleToolTip: "posts_top_reels_tooltip",
      insightsID: insightsID,
      response: insightsResponse,
      dates: dates,
    },
    Facebook__AccountPage__top_posts_comments: {
      typeWidget: "questionCard",
      questionCardTitle: getNameKeyWidget(
        "Facebook__AccountPage__top_posts_comments",
      ),
      questionCardIcon: FacebookIcon,
      latestUnanswer: topPostsAcount,
      widget: "top_posts",
      latestUnanswerPreloader: topPostsAcountPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      icon: faFacebook,
      iconColor: "facebook_icon",
      showHeart: true,
      showDefaultImg: true,
      className: "no-data-found-question",
      insightsID: insightsID,
      response: insightsResponse,
      dates: dates,
      hideChangeSentiment: true,
    },
    Facebook__AccountPage__page_views: {
      typeWidget: "lineChart",
      title: getNameKeyWidget("Facebook__AccountPage__page_views"),
      totalLabel: "views",
      totalValue: totalPageViewsCount,
      data: pageViews,
      showGroupBy: !isLiveDashboardPublic,
      toolTipLabel: "views",
      showDownloadIcon: !isLiveDashboardPublic,
      bigImg: !isLiveDashboardPublic,
      lineChartPreLoaderDataSources: pageViewsPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      iconAfterTitle: FacebookIcon,
      labelColor: "#2AAAE2",
      showReportIcon: true,
      titleToolTip: "page_views_fb_tooltip",
    },
    Facebook__AccountPage__page_likes_unlikes: {
      typeWidget: "stackedBarChart",
      title: getNameKeyWidget("Facebook__AccountPage__page_likes_unlikes"),
      showDownloadIcon: !isLiveDashboardPublic,
      bigImg: !isLiveDashboardPublic,
      posts: pageLikeUnlike,
      showZoomPercentage: true,
      iconAfterTitle: FacebookIcon,
      postsColumnPreLoaderDataSources: pageLikeUnlikePreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      showGroupByClientAgent: true,
      showScales: !isLiveDashboardPublic,
      colors: ["#2AAAE2", "#80868C"],
      labels: ["unlike_page_over_time", "like_page_over_time"],
      hideLegend: true,
      dataPiechart: pageLikeUnlikeLegend,
      showReportIcon: true,
      titleToolTip: "page_like_unlikes_fb_tooltip",
    },
    Facebook__AccountPage__page_contact_details_interactions: {
      typeWidget: "stackedBarChart",
      title: getNameKeyWidget(
        "Facebook__AccountPage__page_contact_details_interactions",
      ),
      showDownloadIcon: !isLiveDashboardPublic,
      bigImg: !isLiveDashboardPublic,
      posts: pageContactDetailsInteractions,
      colors: ["#2AAAE2", "#80868C", "#334155"],
      labels: [
        "page_website_clicks_over_time",
        "page_call_phone_clicks_over_time",
        "page_directions_clicks_over_time",
      ],
      showZoomPercentage: true,
      iconAfterTitle: FacebookIcon,
      postsColumnPreLoaderDataSources: pageContactDetailsInteractionsPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      showGroupByClientAgent: true,
      showScales: !isLiveDashboardPublic,
      showReportIcon: true,
      titleToolTip: "page_contact_details_interactions_fb_tooltip",
      hideLegend: true,
      dataPiechart: pageContactDetailsLegend,
    },
    Facebook__AccountPage__page_actions: {
      typeWidget: "stackedBarChart",
      title: getNameKeyWidget("Facebook__AccountPage__page_actions"),
      showDownloadIcon: !isLiveDashboardPublic,
      bigImg: !isLiveDashboardPublic,
      posts: pageActions,
      colors: ["#C51162", "#4CAF50"],
      labels: [
        "page_negative_interactions_over_time",
        "page_positive_interactions_over_time",
      ],
      showZoomPercentage: true,
      iconAfterTitle: FacebookIcon,
      postsColumnPreLoaderDataSources: pageActionsPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      showGroupByClientAgent: true,
      showScales: !isLiveDashboardPublic,
      showReportIcon: true,
      titleToolTip: "page_actions_fb_tooltip",
      hideLegend: true,
      dataPiechart: pageActionsLegend,
    },
    Facebook__AccountPage__account_author_activity: {
      typeWidget: "punchCard",
      title: getNameKeyWidget("Facebook__AccountPage__account_author_activity"),
      clientActivity: accountAuthorActivity,
      minValueClient: minValueAuthorActivityAcount,
      maxValueClient: maxValueAuthorActivityAcount,
      showDownloadIcon: !isLiveDashboardPublic,
      bigImg: !isLiveDashboardPublic,
      iconAfterTitle: FacebookIcon,
      authorActivityPreLoaderDataSources: accountAuthorActivityPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      titleToolTip: "account_authors_activity_fb_tooltip",
    },
    Facebook__AccountPage__page_reach_impressions: {
      typeWidget: "stackedLineChart",
      title: getNameKeyWidget("Facebook__AccountPage__page_reach_impressions"),
      data: pageReachImpression,
      showGroupBy: !isLiveDashboardPublic,
      areaStyle: true,
      toolTipLabel: "toolTipLabel",
      showAI: false,
      showDownloadIcon: !isLiveDashboardPublic,
      bigImg: !isLiveDashboardPublic,
      stackedLineWidgetPreLoaderDataSources: pageReachImpressionPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      isSwitchCase: true,
      iconAfterTitle: FacebookIcon,
      showGroupByClientAgent: true,
      showScales: !isLiveDashboardPublic,
      colors: ["#2AAAE2", "#80868C"],
      labelColors: ["#2AAAE2", "#80868C"],
      showReportIcon: true,
      titleToolTip: "page_reach_impressions_fb_tooltip",
      hideLegend: true,
      dataPiechart: pageReachImpressionLegend,
      isPostsReachImpression: true,
    },
    // customer care section
    Facebook__CustomerCarePage__customer_care_performance: {
      typeWidget: "barChart",
      title: getNameKeyWidget(
        "Facebook__CustomerCarePage__customer_care_performance",
      ),
      titleToolTip: "customer_performance_fb_tooltip",
      data: customerCareData,
      showAI: !isLiveDashboardPublic,
      showDownloadIcon: !isLiveDashboardPublic,
      showChartStatsWidget: true,
      showZoomPercentage: true,
      customerCareChart: true,
      respTimeDistributionTooltip: "respone_time_distribution_tooltip",
      gridHeight: "70%",
      statsData: [
        {
          name: "total_ques",
          value:
            customerCarePerformance?.total_number_of_questions &&
            commasAfterDigit(
              customerCarePerformance?.total_number_of_questions,
            ),
          icon: faQuestion,
          titleToolTip: "number_of_ques_tooltip",
        },
        {
          name: "number_of_answered_questions",
          value: isNaN(respRate * 100)
            ? 0 + "%"
            : respRate.toFixed(2) * 100 + "%",
          icon: faPercent,
          titleToolTip: "response_rate_tooltip",
        },
        {
          name: "avg_response_time_in_seconds",
          value: customerCarePerformance?.avg_response_time_in_seconds,
          icon: faClock,
          type: "time",
          titleToolTip: "avg_response_time_tooltip",
        },
      ],
      dataColor: "#2AAAE2",
      toolTipLabel: "posts",
      isTwitterAccount: true,
      barChartPreLoaderDataSources: customerCarePreloader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      iconAfterTitle: FacebookIcon,
    },
    Facebook__CustomerCarePage__latest_unanswered_questions: {
      typeWidget: "questionCard",
      questionCardTitle: getNameKeyWidget(
        "Facebook__CustomerCarePage__latest_unanswered_questions",
      ),
      questionCardIcon: FacebookIcon,
      unixDate: unixDate,
      // showProfile: true,
      productId: window.localStorage?.getItem("sm_id"),
      latestUnanswer: latestUnanswer,
      latestUnanswerPreloader: latestUnanswerPreloader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      icon: faFacebook,
      iconColor: "facebook_icon",
      showDefaultImg: true,
      className: "no-data-found-question",
      hideChartBar: true,
    },
    // posts section
    Facebook__PostsPage__top_posts_comments: {
      typeWidget: "questionCard",
      questionCardTitle: getNameKeyWidget(
        "Facebook__PostsPage__top_posts_comments",
      ),
      questionCardIcon: FacebookIcon,
      latestUnanswer: topPostsPosts,
      latestUnanswerPreloader: topTopPostsPostsPreLoaderDataSources,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      icon: faFacebook,
      iconColor: "facebook_icon",
      showHeart: true,
      showDefaultImg: true,
      className: "no-data-found-question",
      // ShowPagination: true,
      // setPage: setPage,
      // page: page,
      widget: "top_posts",
      hideChartBar: monitorType === "ACCOUNT_ANALYSIS" ? true : false,
      insightsID: insightsID,
      response: insightsResponse,
      dates: dates,
      hideChangeSentiment: true,
    },
    // comments mentions section
    Facebook__CommentsMentionsPage__top_posts_comments: {
      typeWidget: "questionCard",
      questionCardTitle: getNameKeyWidget(
        "Facebook__CommentsMentionsPage__top_posts_comments",
      ),
      questionCardIcon: FacebookIcon,
      unixDate: unixDate,
      // showProfile: true,
      productId: window.localStorage?.getItem("sm_id"),
      latestUnanswer: commentsMentions,
      // unAnsweredQuestion: totalPage,
      // ShowPagination: true,
      // changePagination: changePagination,
      latestUnanswerPreloader: commentsMentionsPreloader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      icon: faFacebook,
      iconColor: "facebook_icon",
      hideChartBar: true,
      showHeart: true,
      showDefaultImg: true,
      className: "no-data-found-question",
      widget: "comments_mentions",
      monitorType: monitorType === "KEYWORD" ? "keyword" : null,
    },
    // questions section
    Facebook__QuestionsPage__latest_unanswered_questions: {
      typeWidget: "questionCard",
      questionCardTitle: getNameKeyWidget(
        "Facebook__QuestionsPage__latest_unanswered_questions",
      ),
      questionCardIcon: FacebookIcon,
      unixDate: unixDate,
      // showProfile: true,
      productId: window.localStorage?.getItem("sm_id"),
      latestUnanswer: commentsMentionsQuestions,
      // unAnsweredQuestion: totalPage,
      // ShowPagination: true,
      // changePagination: changePagination,
      latestUnanswerPreloader: commentsMentionsQuestionsPreloader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      icon: faFacebook,
      iconColor: "facebook_icon",
      hideChartBar: true,
      showHeart: true,
      showDefaultImg: true,
      className: "no-data-found-question",
    },
  };

  const handleResponseFunc = async () => {
    if (response?.eventName === "Facebook__EngagementsPage__volume_overtime") {
      setCommentsVolume(response?.eventData?.posts_over_time);
      setTotalCommentsVolumeCount(response?.eventData?.total_posts_count);
      setCommentsVolumePreLoader([response?.monitor_id]);
    } else if (response?.eventName === "Facebook__EngagementsPage__reactions") {
      setReactions(
        response?.eventData?.posts_reactions?.posts_reactions_over_time,
      );
      setReactionsPreLoader([response?.monitor_id]);

      setPageReactionPieChartValue(
        response?.eventData?.posts_reactions?.posts_reactions_piechart,
      );
    } else if (
      response?.eventName === "Facebook__EngagementsPage__sentiment_analysis"
    ) {
      Object.entries(response?.eventData?.sentiment_overtime)?.forEach(
        ([key, value]) => {
          switch (key) {
            case "negative":
              value?.map((i) => {
                return negativeArray.push(i);
              });
              break;
            case "positive":
              value?.map((i) => {
                return positiveArray.push(i);
              });
              break;
            case "neutral":
              value?.map((i) => {
                return neutralArray.push(i);
              });
              break;
            default:
              break;
          }
        },
      );
      setSentimentAnalysisPieChart(response?.eventData?.sentiment_piechart);
      setSentimentAnalysis({
        negative: negativeArray,
        positive: positiveArray,
        neutral: neutralArray,
      });
      setSentimentAnalysisPreLoader([response?.monitor_id]);
    } else if (
      response?.eventName === "Facebook__EngagementsPage__posts_interactions"
    ) {
      setCommentsInteractions(
        response?.eventData?.posts_interactions?.posts_interactions_over_time,
      );
      setCommentsInteractionsLegend(
        response?.eventData?.posts_interactions?.posts_interactions_legends,
      );

      let totalInteractions = response?.eventData?.posts_interactions
        ?.total_interactions
        ? response?.eventData?.posts_interactions?.total_interactions
        : 0;

      setTotalValuePostsInteractions(totalInteractions);

      setMaxValuePostsInteractions(
        response?.eventData?.posts_interactions?.max_interactions,
      );
      let date = handleUnixFormat(
        response?.eventData?.posts_interactions?.max_interaction_date,
      );
      setMaxValueInteractionsDate(date);

      setAvgValuePostsInteractions(
        response?.eventData?.posts_interactions?.average_interactions,
      );

      setCommentsInteractionsPreLoader([response?.monitor_id]);
    } else if (
      response?.eventName === "Facebook__EngagementsPage__top_keywords"
    ) {
      setTopKeywords(response?.eventData?.top_keywords);
      setTopKeywordsPreLoader([response?.monitor_id]);
    } else if (
      response?.eventName === "Facebook__EngagementsPage__top_hashtags"
    ) {
      setTopHashtags(response?.eventData?.top_hashtags);
      setTopHashtagsPreLoader([response?.monitor_id]);
    } else if (
      response?.eventName === "Facebook__EngagementsPage__associated_topics"
    ) {
      setAssociatedTopics(response?.eventData?.associated_topics);
      setAssociatedTopicsPreLoader([response?.monitor_id]);
    } else if (
      response?.eventName === "Facebook__EngagementsPage__top_posts_comments"
    ) {
      setTopPosts(response?.eventData?.top_posts);
      setTopPostsCount(response?.eventData?.top_posts_count);
      setTopPostsPreLoader([response?.monitor_id]);
    } else if (
      response?.eventName ===
      "Facebook__EngagementsPage__dialects_and_sub_dialects"
    ) {
      setDialects(response?.eventData?.dialects);
      setSubDialects(response?.eventData?.sub_dialects);
      setDialectsPreLoader([response?.monitor_id]);
    } else if (
      response?.eventName ===
      "Facebook__EngagementsPage__account_author_activity"
    ) {
      setMinValueAuthorActivity(response?.eventData?.min_value);
      setMaxValueAuthorActivity(response?.eventData?.max_value);
      setAuthorActivity(response?.eventData?.account_author_activity);
      setAuthorActivityPreLoaderDataSources([response?.monitor_id]);
    } else if (
      response?.eventName === "Facebook__EngagementsPage__engagers_volume"
    ) {
      setEngagersVolume(
        response.eventData.engagers_volume.engagers_volume_over_time,
      );
      setTotalEngagersVolumeCount(
        response.eventData.engagers_volume.total_engagers,
      );
      setEngagersVolumePreLoader([response?.monitor_id]);
    } else if (
      response?.eventName === "Facebook__EngagementsPage__gender_distribution"
    ) {
      if (response.eventData.gender_distribution) {
        response.eventData.gender_distribution.map((gd) => {
          Object.keys(gd).map((val, index) => {
            setGenderDistribution((oldArray) => [
              ...oldArray,
              {
                name: val,
                value: Object.values(gd)[index],
              },
            ]);
          });
        });
      }
      setGenderDistributionPreLoader([response?.monitor_id]);
      setGenderDistributionPieChartValue(response.eventData.gender_piechart);
    } else if (
      response?.eventName === "Facebook__EngagementsPage__top_languages"
    ) {
      setTopLanguages(
        translateLocalsFromRabbitMQ(
          "Facebook__EngagementsPage__top_languages",
          response.eventData.top_languages,
        ),
      );

      setTopLanguagesPreLoader([response?.monitor_id]);
    } else if (
      response?.eventName === "Facebook__EngagementsPage__top_cities"
    ) {
      setTopCities(response.eventData.top_cities);
      setTopCitiesPreLoader([response?.monitor_id]);
    } else if (
      response?.eventName === "Facebook__EngagementsPage__top_countries"
    ) {
      setTopCountries(response.eventData.top_countries);
      setTopCountriesPreLoader([response?.monitor_id]);
    } else if (
      response?.eventName === "Facebook__EngagementsPage__dashboard_data"
    ) {
      // sum in back-end
      setTotalStatisticWidgets((prev) => ({
        ...prev,
        facebookDataSource: {
          totalPosts: response?.eventData?.total_posts,
          totalAuthors: response?.eventData?.total_authors,
          totalInteractions: response?.eventData?.total_interactions,
        },
      }));
      setLoadingStatisticWidgets(false);
      // account section
    } else if (
      response?.eventName === "Facebook__AccountPage__volume_overtime"
    ) {
      setPostsVolume(response.eventData.posts_over_time);
      setTotalPostsVolumeCount(response.eventData.total_posts_count);
      setPostsVolumePreLoader([response?.monitor_id]);
    } else if (response?.eventName === "Facebook__AccountPage__reach_funnel") {
      Object.entries(response.eventData.reach_funnel_overtime).forEach(
        ([key, value]) => {
          switch (key) {
            case "page_posts_impressions_unique":
              value?.map((i) => {
                return reachAnalyticsArray.push(i);
              });
              break;
            case "page_posts_impressions":
              value?.map((i) => {
                return impressionAnalyticsArray.push(i);
              });
              break;

            default:
              break;
          }
        },
      );

      (reachAnalyticsArray.length > 0 || impressionAnalyticsArray.length > 0) &&
        setPostReachImpression({
          reach: reachAnalyticsArray,
          impression: impressionAnalyticsArray,
        });

      setTotalPostReachImpression(response.eventData.reach_funnel_legends);
      setPostReachImpressionPreLoader([response?.monitor_id]);
    } else if (
      response?.eventName === "Facebook__AccountPage__posts_interactions"
    ) {
      setPostsInteractions(
        response.eventData.posts_interactions.posts_interactions_over_time,
      );
      setPostsInteractionsLegend(
        response.eventData.posts_interactions.posts_interactions_legends,
      );
      setPostsInteractionsPreLoader([response?.monitor_id]);
      setMaxValuePostsInteractionsAcount(
        response.eventData.posts_interactions.max_interactions,
      );
      setMinValuePostsInteractionsAcount(
        response.eventData.posts_interactions.min_interactions,
      );
      setAvgValuePostsInteractionsAcount(
        response.eventData.posts_interactions.average_interactions,
      );
      let date = handleUnixFormat(
        response.eventData.posts_interactions.max_interaction_date,
      );
      setMaxValueInteractionsDateAcount(date);
      date = handleUnixFormat(
        response.eventData.posts_interactions.min_interaction_date,
      );
      setMinValueInteractionsDate(date);
    } else if (response?.eventName === "Facebook__AccountPage__reactions") {
      setReactionsAcount(
        response.eventData.posts_reactions.posts_reactions_over_time,
      );
      setReactionsAcountPreLoader([response?.monitor_id]);
      setPageReactionPieChartValueAcount(
        response.eventData.posts_reactions.posts_reactions_piechart,
      );
    } else if (response?.eventName === "Facebook__AccountPage__content_type") {
      setPostsContentType(
        response.eventData.posts_content_type.posts_content_type,
      );
      setPostsContentTypePreLoader([response?.monitor_id]);
      setPostContentPieChartValue(
        response.eventData.posts_content_type.posts_content_types_legends,
      );
    } else if (
      response?.eventName === "Facebook__AccountPage__page_videos_views"
    ) {
      setVideosViews(response.eventData.page_videos_views_overtime);
      setTotalVideosViewsCount(
        response.eventData.total_page_videos_views[0].value,
      );
      setVideosViewsPreLoader([response?.monitor_id]);
    } else if (response?.eventName === "Facebook__AccountPage__top_keywords") {
      setTopKeywordsAcount(response.eventData.top_keywords);
      setTopKeywordsAcountPreLoader([response?.monitor_id]);
    } else if (response?.eventName === "Facebook__AccountPage__top_hashtags") {
      setTopHashtagsAcount(response.eventData.top_hashtags);
      setTopHashtagsAcountPreLoader([response?.monitor_id]);
    } else if (response?.eventName === "Facebook__AccountPage__top_videos") {
      setTopVideos(response.eventData.top_videos);
      setTopVideosPreLoader([response?.monitor_id]);
    } else if (response?.eventName === "Facebook__AccountPage__top_reels") {
      setTopReels(response?.eventData?.top_reels);
      setTopReelsPreLoader([response?.monitor_id]);
    } else if (response?.eventName === "Facebook__AccountPage__top_images") {
      setTopImages(response.eventData.top_images);
      setTopImagesPreLoader([response?.monitor_id]);
    } else if (
      response?.eventName === "Facebook__AccountPage__top_posts_comments"
    ) {
      setTopPostsAcount(response.eventData.top_posts);
      setTopPostsAcountCount(response.eventData.top_posts_count);
      setTopPostsAcountPreLoader([response?.monitor_id]);
    } else if (response?.eventName === "Facebook__AccountPage__page_views") {
      setPageViews(response.eventData.page_views_overtime);
      setTotalPageViewsCount(response.eventData.total_page_views[0].value);
      setPageViewsPreLoader([response?.monitor_id]);
    } else if (
      response?.eventName === "Facebook__AccountPage__page_likes_unlikes"
    ) {
      setPageLikeUnlike(response.eventData.page_likes_unlikes_overtime);
      setPageLikeUnlikeLegend(response.eventData.pie_chart_page_likes_unlikes);
      setPageLikeUnlikePreLoader([response?.monitor_id]);
    } else if (
      response?.eventName === "Facebook__AccountPage__page_reach_impressions"
    ) {
      Object.entries(
        response.eventData.page_reach_impressions_overtime,
      ).forEach(([key, value]) => {
        switch (key) {
          case "page_reach_over_time":
            value?.map((i) => {
              return reachArray.push(i);
            });
            break;
          case "page_impressions_over_time":
            value?.map((i) => {
              return impressionArray.push(i);
            });
            break;

          default:
            break;
        }
      });

      (reachArray.length > 0 || impressionArray.length > 0) &&
        setPageReachImpression({
          reach: reachArray,
          impression: impressionArray,
        });
      setPageReachImpressionLegend(
        response.eventData.total_page_reach_impressions,
      );
      setPageReachImpressionPreLoader([response?.monitor_id]);
    } else if (
      response?.eventName ===
      "Facebook__AccountPage__page_contact_details_interactions"
    ) {
      setPageContactDetailsInteractions(
        response.eventData.page_contact_details_interactions_overtime,
      );
      setPageContactDetailsLegend(
        response.eventData.pie_chart_page_contact_details_interactions,
      );
      setPageContactDetailsInteractionsPreLoader([response?.monitor_id]);
    } else if (response?.eventName === "Facebook__AccountPage__page_actions") {
      setPageActions(response.eventData.page_actions_overtime);
      setPageActionsLegend(response.eventData.pie_chart_page_actions);
      setPageActionsPreLoader([response?.monitor_id]);
    } else if (
      response?.eventName === "Facebook__AccountPage__account_author_activity"
    ) {
      setMinValueAuthorActivityAcount(response.eventData.min_value);
      setMaxValueAuthorActivityAcount(response.eventData.max_value);
      setAccountAuthorActivity(response.eventData.account_author_activity);
      setAccountAuthorActivityPreLoader([response?.monitor_id]);
      // customer care section
    } else if (
      response?.eventName ===
      "Facebook__CustomerCarePage__customer_care_performance"
    ) {
      setCustomercarePerformance(response.eventData);
      setCustomercarePreloader([parseInt(response?.monitor_id)]);
      if (response?.eventData?.response_time_segment) {
        Object.keys(response.eventData?.response_time_segment).map(
          (val, index) => {
            setcustomerCareData((oldArray) => [
              ...oldArray,
              {
                name: val,
                value: Object.values(response.eventData?.response_time_segment)[
                  index
                ],
              },
            ]);
          },
        );
      }
      setRespRate(
        response.eventData.number_of_answered_questions /
          response.eventData.total_number_of_questions,
      );
      setTotalQuestion(response.eventData.total_number_of_questions);
    } else if (
      response?.eventName ===
      "Facebook__CustomerCarePage__latest_unanswered_questions"
    ) {
      setLatestUnanswer(response.eventData.latest_unanswered_questions);
      setUnanswerQuestion(response.eventData.number_of_unanswered_questions);
      setLatestUnanswerPreloader([parseInt(response?.monitor_id)]);
      // posts section
    } else if (
      response?.eventName === "Facebook__PostsPage__top_posts_comments"
    ) {
      setTopPostsPosts(response.eventData.top_posts);
      setTopPostsPostsPreLoaderDataSources([response?.monitor_id]);
      // comments mentions section
    } else if (
      response?.eventName ===
      "Facebook__CommentsMentionsPage__top_posts_comments"
    ) {
      setCommentsMentions(response.eventData.top_posts);
      setCommentsMentionsPreloader([response?.monitor_id]);
      // questions section
    } else if (
      response?.eventName ===
      "Facebook__QuestionsPage__latest_unanswered_questions"
    ) {
      setCommentsMentionsQuestions(
        response.eventData.latest_unanswered_questions,
      );
      setCommentsMentionsQuestionsPreloader([response?.monitor_id]);
    } else {
      return null;
    }
  };
  useEffect(() => {
    handleResponseFunc();
  }, [response]);

  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (resetAllFields !== 1) {
      setCommentsVolume([]);
      setTotalCommentsVolumeCount([]);
      setCommentsVolumePreLoader([]);

      setReactions([]);
      setReactionsPreLoader([]);
      setPageReactionPieChartValue([]);

      setSentimentAnalysis([]);
      setSentimentAnalysisPreLoader([]);
      setNegativeArray([]);
      setPositiveArray([]);
      setNeutralArray([]);
      setSentimentAnalysisPieChart([]);

      setCommentsInteractions([]);
      setCommentsInteractionsLegend([]);
      setCommentsInteractionsPreLoader([]);

      setTopKeywords([]);
      setTopKeywordsPreLoader([]);
      setTopHashtags([]);
      setTopHashtagsPreLoader([]);

      setAssociatedTopics([]);
      setAssociatedTopicsPreLoader([]);

      setTopPosts([]);
      setTopPostsCount(0);
      setTopPostsPreLoader([]);
      setDialects([]);
      setSubDialects([]);
      setDialectsPreLoader([]);

      setTotalValuePostsInteractions(0);
      setMaxValuePostsInteractions(0);
      setMaxValueInteractionsDate(0);
      setAvgValuePostsInteractions(0);

      setMinValueAuthorActivity(0);
      setMaxValueAuthorActivity(0);
      setAuthorActivity([]);
      setAuthorActivityPreLoaderDataSources([]);

      setEngagersVolume([]);
      setTotalEngagersVolumeCount([]);
      setEngagersVolumePreLoader([]);

      setGenderDistribution([]);
      setGenderDistributionPreLoader([]);
      setGenderDistributionPieChartValue([]);

      setTopLanguages([]);
      setTopLanguagesPreLoader([]);
      setTopCities([]);
      setTopCitiesPreLoader([]);
      setTopCountries([]);
      setTopCountriesPreLoader([]);

      // account section
      setPageViews([]);
      setTotalPageViewsCount([]);

      setPageLikeUnlike([]);
      setPageLikeUnlikeLegend([]);
      setPageLikeUnlikePreLoader([]);
      setPageReachImpression([]);
      setPageReachImpressionLegend([]);
      setPageReachImpressionPreLoader([]);
      setImpressionArray([]);
      setReachArray([]);

      setPageContactDetailsInteractions([]);
      setPageContactDetailsLegend([]);
      setPageContactDetailsInteractionsPreLoader([]);

      setPageActions([]);
      setPageActionsLegend([]);
      setPageActionsPreLoader([]);
      setAccountAuthorActivity([]);
      setAccountAuthorActivityPreLoader([]);
      setMinValueAuthorActivityAcount(0);
      setMaxValueAuthorActivityAcount(0);
      setPageViewsPreLoader([]);
      setPostsVolume([]);
      setTotalPostsVolumeCount([]);
      setPostsVolumePreLoader([]);

      setPostReachImpression([]);
      setImpressionAnalyticsArray([]);
      setReachAnalyticsArray([]);
      setPostReachImpressionPreLoader([]);
      setTotalPostReachImpression([]);

      setPostsInteractions([]);
      setPostsInteractionsLegend([]);
      setPostsInteractionsPreLoader([]);
      setMaxValuePostsInteractionsAcount(0);
      setMinValuePostsInteractionsAcount(0);
      setAvgValuePostsInteractionsAcount(0);
      setMaxValueInteractionsDateAcount(0);
      setMinValueInteractionsDate(0);

      setReactionsAcount([]);
      setReactionsAcountPreLoader([]);
      setPageReactionPieChartValueAcount([]);

      setPostsContentType([]);
      setPostsContentTypePreLoader([]);
      setPostContentPieChartValue([]);
      setVideosViews([]);
      setTotalVideosViewsCount([]);
      setVideosViewsPreLoader([]);

      setTopKeywordsAcount([]);
      setTopKeywordsAcountPreLoader([]);
      setTopHashtagsAcount([]);
      setTopHashtagsAcountPreLoader([]);

      setTopVideos([]);
      setTopVideosPreLoader([]);
      setTopReels([]);
      setTopReelsPreLoader([]);
      setTopImages([]);
      setTopImagesPreLoader([]);

      setTopPostsAcount([]);
      setTopPostsAcountCount([]);
      setTopPostsAcountPreLoader([]);

      // customer care section
      setLatestUnanswer([]);
      setCustomercarePreloader({});
      setLatestUnanswerPreloader([]);
      setCustomercarePreloader([]);
      setUnanswerQuestion(0);
      setcustomerCareData([]);

      // posts section
      setTopPostsPosts([]);
      setTopPostsPostsPreLoaderDataSources([]);

      // comments mentions section
      setCommentsMentions([]);
      setCommentsMentionsPreloader([]);

      // questions section
      setCommentsMentionsQuestions([]);
      setCommentsMentionsQuestionsPreloader([]);
    }
  }, [resetAllFields]);

  //this function is used for open the quick-inspect-model
  // const handleOpenQuickInspect = (value, name, sentimentValue) => {
  //   switch (true) {
  //     case name === "comments_overtime":
  //       setDataValue(
  //         props.engagementsKeyword ? "posts_mentions" : "comments_mentions",
  //       );
  //       setInspectorValue(props.engagementsKeyword ? "posts" : "comments");
  //       setColor("#2AAAE2");
  //       setDate(value.split("-").reverse().join("/"));
  //       break;
  //     case name === "sentiment_overtime":
  //       setDataValue(sentimentValue);
  //       setInspectorValue(
  //         CheckValueLocale(sentimentValue, "", {}, intl) +
  //           " " +
  //           CheckValueLocale("volume", "", {}, intl),
  //       );
  //       sentimentValue === "positive"
  //         ? setColor("#BAD532")
  //         : sentimentValue === "negative"
  //           ? setColor("#ED1846")
  //           : setColor("#F5B819");
  //       setDate(value.split("-").reverse().join("/"));

  //       break;
  //     //incase of new design for quick inspect from sentiment legend
  //     case name === "sentiment_legend":
  //       setDataValue(
  //         sentimentValue + " " + CheckValueLocale(value, "", {}, intl),
  //       );
  //       setInspectorValue(CheckValueLocale(value + "_volume", "", {}, intl));
  //       value === "positive"
  //         ? setColor("#8CC63F")
  //         : value === "negative"
  //           ? setColor("#ED1846")
  //           : setColor("#F5B819");

  //       setDate(
  //         CheckValueLocale("all_time_sentiment", "", {}, intl) +
  //           dateFormRefactor(reduxFilterStartDateUX) +
  //           " - " +
  //           dateFormRefactor(reduxFilterEndDateUX),
  //       );
  //       break;
  //     case name === "top_hashtags" || name === "top_keywords":
  //       setDataValue(value);
  //       setInspectorValue(value);
  //       setColor("#2AAAE2");
  //       setDate(
  //         dateFormRefactor(reduxFilterStartDateUX) +
  //           "-" +
  //           dateFormRefactor(reduxFilterEndDateUX),
  //       );
  //       break;
  //     default:
  //       setDataValue(sentimentValue + "% " + value);
  //       setInspectorValue(value);
  //       setColor("#2AAAE2");
  //       setDate(
  //         dateFormRefactor(reduxFilterStartDateUX) +
  //           "-" +
  //           dateFormRefactor(reduxFilterEndDateUX),
  //       );
  //   }
  //   setQuickResponse([]);
  //   setInspectorName(name);
  //   setOpen(true);

  //   let valueToSend;
  //   if (dataToSend[name] !== undefined && dataToSend[name].length !== 0) {
  //     valueToSend = dataToSend[name].filter(
  //       (data) =>
  //         CheckValueLocale(data.name, "", {}, intl).toUpperCase() ===
  //         value.toUpperCase(),
  //     );
  //   }
  //   value =
  //     valueToSend && valueToSend.length !== 0 ? valueToSend[0].name : value;
  //   name === "sentiment_overtime"
  //     ? getQuickInspectData(
  //         reduxFilterStartDateUX,
  //         reduxFilterEndDateUX,
  //         name,
  //         value,
  //         sentimentValue,
  //       )
  //     : getQuickInspectData(
  //         reduxFilterStartDateUX,
  //         reduxFilterEndDateUX,
  //         name,
  //         value,
  //       );
  // };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    sortWidgets(
      oldIndex,
      newIndex,
      facebookWidgetTypes,
      setFacebookWidgetTypes,
    );
  };
  return (
    <>
      <Container
        axis={"xy"}
        onSortEnd={onSortEnd}
        useDragHandle
        useWindowAsScrollContainer
      >
        {facebookWidgetTypes
          ?.filter((item) => facebookWidgetObj[item?.name])
          ?.map((item, index) => (
            <Element
              key={index}
              index={index}
              widgetItem={facebookWidgetObj[item?.name]}
              showEditBtn={showEditBtn}
              widgetSection={item?.section}
              isLiveDashboardPublic={isLiveDashboardPublic}
              themesOptions={themesOptions}
            />
          ))}
      </Container>
    </>
  );
};
export default DataSourceContent;
