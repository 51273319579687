import { useIntl } from "react-intl";
import { Box, Typography } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import DisplayOptionSelect from "./components/displayOptionSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faInfo } from "@fortawesome/pro-regular-svg-icons";

const FrequencyOptions = (props) => {
  const { embeddedOptions, surveyData, setSurveyData, savedSurveyData } = props;

  const intl = useIntl();

  const selectedOptions = surveyData?.embedded_config?.frequency_settings || {};

  const handleChangeOption = (key, value) => {
    if (
      key === "display_frequency" &&
      value === "once_per_user" &&
      !selectedOptions?.stop_displaying_after?.length
    ) {
      setSurveyData((prev) => ({
        ...prev,
        embedded_config: {
          ...prev?.embedded_config,
          frequency_settings: {
            ...prev?.embedded_config?.frequency_settings,
            stop_displaying_after: ["click_submit_button"],
          },
        },
      }));
    }

    setSurveyData((prev) => ({
      ...prev,
      embedded_config: {
        ...prev?.embedded_config,
        frequency_settings: {
          ...prev?.embedded_config?.frequency_settings,
          [key]: value,
        },
      },
    }));
  };

  const showWarningMessage =
    savedSurveyData?.embedded_config?.frequency_settings?.display_frequency ===
      "once_per_user" && selectedOptions?.display_frequency !== "once_per_user";

  return (
    <Box className="display-settings-options-accordion-content">
      <Box className="display-settings-options-accordion-content-option-wrapper">
        <Typography className="display-settings-options-subtitle">
          {CheckValueLocale("display_settings_display_frequency", "", {}, intl)}
        </Typography>
        <DisplayOptionSelect
          id="display-settings-display-frequency-select"
          options={(
            embeddedOptions?.frequency_settings?.display_frequency?.values?.sort(
              (a, b) => a?.id - b?.id,
            ) || []
          )?.map((item) => {
            return {
              label: `display_settings_display_frequency_${item?.value}`,
              value: item?.value,
            };
          })}
          value={selectedOptions?.display_frequency}
          onChange={(e) =>
            handleChangeOption("display_frequency", e?.target?.value)
          }
        />
        {showWarningMessage ? (
          <Typography className="display-settings-warning-message">
            <Box component="span">
              <FontAwesomeIcon
                className="display-settings-warning-icon"
                icon={faCircleInfo}
                fixedWidth
              />
            </Box>
            {CheckValueLocale(
              "display_settings_display_frequency_warning_msg",
              "",
              {},
              intl,
            )}
          </Typography>
        ) : null}
      </Box>

      <Box className="display-settings-options-accordion-content-option-wrapper">
        <Typography className="display-settings-options-subtitle">
          {CheckValueLocale(
            "display_settings_stop_displaying_after",
            "",
            {},
            intl,
          )}
        </Typography>
        <DisplayOptionSelect
          id="display-settings-stop-displaying-after-select"
          options={(
            embeddedOptions?.frequency_settings?.stop_displaying_after?.values?.sort(
              (a, b) => a?.id - b?.id,
            ) || []
          )?.map((item) => {
            return {
              label: `display_settings_stop_displaying_after_${item?.value}`,
              value: item?.value,
            };
          })}
          value={selectedOptions?.stop_displaying_after || []}
          onChange={(e) =>
            handleChangeOption("stop_displaying_after", e?.target?.value)
          }
          renderValue={(selected) =>
            !selected?.length
              ? CheckValueLocale("select", "", {}, intl)
              : selected?.length > 1
                ? `${selected?.length} ${CheckValueLocale(
                    "display_settings_options_selected",
                    "",
                    {},
                    intl,
                  )}`
                : CheckValueLocale(
                    `display_settings_stop_displaying_after_${selected?.[0]}`,
                    "",
                    {},
                    intl,
                  )
          }
          disabledMenuItems={
            selectedOptions?.display_frequency === "once_per_user" &&
            selectedOptions?.stop_displaying_after?.length < 2
          }
          displayEmpty
          multiple
          menuDirection={"top"}
        />
      </Box>
    </Box>
  );
};

export default FrequencyOptions;
