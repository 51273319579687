import { Menu } from "@mui/material";
import { useIntl } from "react-intl";

const SharedMenuTags = ({
  anchorEl,
  setAnchorEl,
  children,
  id,
  anchorOrigin,
  transformOrigin,
  close,
}) => {
  const intl = useIntl();
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
    if (close) close();
  };

  return (
    <Menu
      id={id}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      getContentAnchorEl={null}
    >
      {children}
    </Menu>
  );
};

export default SharedMenuTags;
