import { useQuery } from "@tanstack/react-query";
import AccountsListController from "services/controllers/accountsListController";

export const useGetUsernameAccountLists = (username, search) => {
  return useQuery({
    queryKey: ["account_lists", username, search],
    queryFn: () =>
      AccountsListController?.getUsernameAccountLists(username, search),
    retry: false,
  });
};
