import { useMutation, useQueryClient } from "@tanstack/react-query";
import Engagements from "../../../../../services/api/engagements";

export const uesEditTag = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, data }) => Engagements.editTag(id, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["engagementTagsList"] });
      queryClient.invalidateQueries({ queryKey: ["engagementTagsInformal"] });
    },
  });
};
