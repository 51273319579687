import { useIntl } from "react-intl";
import { InputField } from "../inputField/inputField";
import { SelectFieldDropdown } from "../selectFieldDropdown/selectFieldDropdown";
import {
  CheckValueLocale,
  classNames,
  getResetPhoneNumber,
  getSocialIcon,
} from "utils/helpers";
import {
  Box,
  Button,
  Checkbox,
  InputAdornment,
  Typography,
} from "@mui/material";
import { AutoCompleteField } from "../autocompleteField/autocompleteField";
import DefaultUser from "images/shared-images/user-management-default.svg";
import { useEffect, useState } from "react";
import SharedFunctionsKeywords from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/sharedFunctionsKeywords";
import KeywordTooltipChip from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/keywordTypeComponent/keywordTooltipChip";
import CopyIcon from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/keywordTypeComponent/copyIcon";
import KeywordPopupModal from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/keywordPoupModal/keywordPopupModal";
import { checkValue } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/monitorsSharedFunctions";

// we have grouped the similar conditions value into different optionsRenderer
// 1. Unique optionsRenderer for each condition value. e.g. platforms, accounts, followes count, topics, and keywords.
// 2. DualSelect optionsRenderer for the conditions that have two values. message type and verified X account.
// 3. MultiSelect optionsRenderer for the conditions that have multiple values. e.g. languages, countries, sentiments, subthemes, themes, and segments.

const selectValueRenderer =
  (intl) =>
  (selected = []) => {
    return (
      <Typography className="inbound-routing-select-dropdown-input-placeholder">
        {`${selected?.length} ${CheckValueLocale(
          "tab_selected_options",
          "",
          {},
          intl,
        )}`}
      </Typography>
    );
  };

const autoCompValueRenderer = (intl) => (value, _getTagProps, isOpen) => {
  if (!isOpen && value?.length > 0) {
    return (
      <Typography className="inbound-routing-select-dropdown-input-placeholder auto-comp-empty">
        {`${value?.length} ${CheckValueLocale(
          "tab_selected_options",
          "",
          {},
          intl,
        )}`}
      </Typography>
    );
  }
  return null;
};
export const PlatformsOptionsRenderer = ({
  options,
  handleChange,
  value,
  disabled,
}) => {
  const intl = useIntl();
  return (
    <SelectFieldDropdown
      label={CheckValueLocale("routing_select_platform", "", {}, intl)}
      name="platform"
      value={value || []}
      multiple
      onChange={(e) => handleChange(e?.target?.value)}
      options={options}
      useLocales={true}
      listItemClassName="inbound-routing-select-dropdown-list-item"
      disabled={disabled}
      renderValue={selectValueRenderer(intl)}
      renderItem={(option, selected) => {
        return (
          <>
            <Checkbox checked={selected} />
            <Box className="inbound-routing-select-dropdown-list-social platform-wrapper">
              <Box
                className={classNames(
                  "inbound-routing-select-dropdown-list-social-icon",
                  option?.id,
                )}
              >
                {getSocialIcon(option?.iconKey)}
              </Box>
              <Typography
                component="span"
                className="select-dropdown-field-controll-list-label"
              >
                {CheckValueLocale(option?.name, "", {}, intl)}
              </Typography>
            </Box>
          </>
        );
      }}
    />
  );
};

export const AccountsOptionsRenderer = ({
  options,
  value = "",
  handleChange,
  disabled,
}) => {
  const intl = useIntl();
  return (
    <AutoCompleteField
      label={CheckValueLocale("routing_select_account", "", {}, intl)}
      options={options}
      value={value || []}
      onChange={handleChange}
      multiple
      nameKey="name"
      emptyListMessage={CheckValueLocale(
        "no_accounts_found_to_show",
        "",
        {},
        intl,
      )}
      disabled={disabled}
      renderTags={autoCompValueRenderer(intl)}
      className="inbound-routing-select-dropdown-list-item autocomplete-select-controll"
      renderItem={(option, selected, nameKey) => {
        return (
          <>
            <Checkbox checked={selected} />
            <Box className="inbound-routing-select-dropdown-list-social">
              <Box className="acount-img-container">
                <img
                  alt={option?.name}
                  src={option?.image_url || DefaultUser}
                  onError={(e) => {
                    e.target.src = DefaultUser;
                  }}
                />
                <Box className="account-img-icon">
                  {getSocialIcon(option?.type)}
                </Box>
              </Box>
              <Box className="account-description-box">
                <Box className="account-description-box-handle">
                  <Typography className="account-description-box-name">
                    {option?.type === "WHATSAPP"
                      ? getResetPhoneNumber(option?.phone_number)
                      : option?.[nameKey]}
                  </Typography>
                </Box>
                <Typography
                  component="span"
                  className="account-description-box-type"
                >
                  {CheckValueLocale(option?.type, "", {}, intl)}
                </Typography>
              </Box>
            </Box>
          </>
        );
      }}
    />
  );
};

export const FollowersCountOptionsRenderer = ({
  value,
  handleChange,
  disabled,
}) => {
  const intl = useIntl();
  const handleInputChange = (e) => {
    let val = e?.target?.value?.trim();
    let selectedValue = "";
    if (!val?.length) {
      selectedValue = "";
    } else {
      if (val?.length == 1 && val[0] == "0") {
        selectedValue = "0";
      } else {
        if (val[0] == "0") {
          selectedValue = "";
        } else {
          val?.split("")?.map((dig) => {
            if (/^\d+$/.test(dig)) {
              selectedValue += dig;
            }
          });
        }
      }
    }
    handleChange(selectedValue);
  };
  return (
    <InputField
      label={CheckValueLocale("add_follower_count", "", {}, intl)}
      name="followersCount"
      fullWidth
      value={value}
      min={0}
      onChange={handleInputChange}
      disabled={disabled}
    />
  );
};

const KeywordRegex = ({ value, handleChange }) => {
  const intl = useIntl();

  return (
    <InputField
      label={CheckValueLocale("routing_add_regex", "", {}, intl)}
      name="regex"
      fullWidth
      value={value}
      onChange={(e) => handleChange(e.target.value)}
    />
  );
};

const KeywordsInputField = ({ value, handleChange, disabled }) => {
  const intl = useIntl();
  const [keywordsFocused, setKeywordsFocused] = useState(false);
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [keywordError, setKeywordError] = useState("");
  const [dataExist, setDataExist] = useState(true);
  const { handleValidationKeywords, handlekeywordsShared } =
    SharedFunctionsKeywords();
  const [isCopied, setIsCopied] = useState(false);

  const handleInputMouseDown = (event) => {
    event.preventDefault();
    setKeywordsFocused(true);
  };
  const handleKeywordChange = (event) => {
    const { value } = event.target;
    setSelectedKeywords(value);
    let keywordsCopy = [...keywords];

    //using shared
    let errorMessage = handleValidationKeywords(
      value,
      keywordsCopy?.length ? keywordsCopy : [],
      [],
      "keywords",
      setDataExist,
      "twitter",
    );
    setKeywordError(errorMessage);
  };
  const handleKeywords = (event, clickType) => {
    const { value } = event.target;
    if (
      (event.keyCode === 13 || (clickType === "btn" && value)) &&
      dataExist &&
      value?.trim() !== ""
    ) {
      const keywordsCopy = [...keywords];

      let objData = handlekeywordsShared(
        value,
        "keywords",
        keywordsCopy?.length ? keywordsCopy : [],
        [],
        "twitter",
        [
          {
            keywords: keywordsCopy?.length ? keywordsCopy : [],
          },
          {
            spam_keywords: [],
          },
        ], //keywords such as [{keywords:[]},{spamKeywords:[]}]
        100,
        undefined, //in case newsblogs,
        undefined,
      );

      if (!objData?.errorMsg) {
        setKeywords([...objData?.keywords]);
        handleChange([...objData?.keywords]);
        setSelectedKeywords("");
        setKeywordError("");
        setIsCopied(false);
      } else {
        setSelectedKeywords(value);
        setKeywordError(objData?.errorMsg);
      }
    }
  };
  const handleDeleteInputField = (index, data, setData) => {
    data?.splice(index, 1);
    setData([...data]);
    handleChange([...data]);
    setKeywordError("");
    setSelectedKeywords("");
  };
  const handleData = () => {
    const copiedData = keywords?.join(",");
    navigator.clipboard.writeText(copiedData);
    setIsCopied(true);
  };
  useEffect(() => {
    setKeywords(value || []);
  }, []);
  return (
    <Box>
      <InputField
        label={CheckValueLocale("routing_add_keyword", "", {}, intl)}
        name="keywords"
        fullWidth
        onChange={(event) => handleKeywordChange(event)}
        disabled={disabled}
        onFocus={() => setKeywordsFocused(true)}
        onBlur={() => setKeywordsFocused(true)}
        error={keywordError !== "" ? true : false}
        helperText={
          keywordError
            ? CheckValueLocale(keywordError, "", {}, intl)
            : undefined
        }
        onKeyDown={(event) => handleKeywords(event)}
        value={selectedKeywords}
        InputProps={{
          endAdornment: keywordsFocused ? (
            <InputAdornment onMouseDown={handleInputMouseDown}>
              <Button
                className="add-btn-with-plus alert-add-btn-keyword"
                onClick={(event) => {
                  handleKeywords(event, "btn");
                }}
                value={selectedKeywords}
                id="alert-form-add-keywords-field-btn"
              >
                {CheckValueLocale("add_plus", "", {}, intl)}
              </Button>
            </InputAdornment>
          ) : null,
        }}
      />
      <Box className="keyword-spam-wrapper">
        {checkValue(keywords)?.map((keyword, i) => {
          return (
            <KeywordTooltipChip
              onDelete={() => handleDeleteInputField(i, keywords, setKeywords)}
              index={i}
              dataSource={"twitter"}
              labels={keyword}
              key={i}
            />
          );
        })}
        <KeywordPopupModal
          keywords={keywords}
          handleDelete={(e, index) =>
            handleDeleteInputField(index, keywords, setKeywords)
          }
          keywordType={"keywords"}
          dataSourceIndex={2} // dataSource index to handle delete keyword
        />
        {keywords.length !== 0 && (
          <CopyIcon
            checkCopyValue={isCopied}
            handleData={(e) => handleData(e, keywords[0])}
          />
        )}
      </Box>
    </Box>
  );
};
export const KeywordsOptionsRenderer = ({
  handleChange,
  operator,
  disabled,
  value,
}) => {
  if (operator?.toLowerCase() === "regex") {
    return (
      <KeywordRegex
        disabled={disabled}
        value={value}
        handleChange={handleChange}
      />
    );
  }
  return (
    <KeywordsInputField
      value={value}
      handleChange={handleChange}
      disabled={disabled}
    />
  );
};

export const DualSelectOptionsRenderer = ({
  value,
  options,
  handleChange,
  condition,
  disabled,
}) => {
  const intl = useIntl();
  return (
    <SelectFieldDropdown
      label={CheckValueLocale(
        condition === "message_type"
          ? "select_message_type"
          : "select_verification_status",
        "",
        {},
        intl,
      )}
      value={value}
      multiple={false}
      onChange={(e) => handleChange(e?.target?.value)}
      options={options}
      disabled={disabled}
      listItemClassName="inbound-routing-select-dropdown-list-item"
    />
  );
};

export const MultiSelectOptionsRenderer = ({
  value,
  options,
  handleChange,
  disabled,
  label,
}) => {
  const intl = useIntl();
  return (
    <SelectFieldDropdown
      label={CheckValueLocale(label, "", {}, intl)}
      value={value || []}
      multiple
      onChange={(e) => handleChange(e?.target?.value)}
      options={options}
      listItemClassName="inbound-routing-select-dropdown-list-item"
      disabled={disabled}
      renderValue={selectValueRenderer(intl)}
      renderItem={(option, selected) => {
        return (
          <>
            <Checkbox checked={selected} />
            <Typography
              component="span"
              className="select-dropdown-field-controll-list-label"
            >
              {CheckValueLocale(option?.id?.toLowerCase?.(), "", {}, intl)}
            </Typography>
          </>
        );
      }}
    />
  );
};

export const LanguagesAndCountriesOptionsRenderer = ({
  value,
  options,
  handleChange,
  condition,
  disabled,
  label,
}) => {
  const intl = useIntl();
  const emptyListMessage = `no_${condition}_options`;
  return (
    <AutoCompleteField
      label={CheckValueLocale(label, "", {}, intl)}
      options={options}
      value={value || []}
      onChange={handleChange}
      multiple
      emptyListMessage={CheckValueLocale(emptyListMessage, "", {}, intl)}
      disabled={disabled}
      renderTags={autoCompValueRenderer(intl)}
      className="inbound-routing-select-dropdown-list-item autocomplete-select-controll languages-countries-autocomplete"
      renderItem={(option, selected) => {
        const keyName =
          intl.locale === "ar"
            ? option?.ar_name
              ? "ar_name"
              : "arabic_name"
            : "name";
        return (
          <>
            <Checkbox checked={selected} />
            <Typography
              component="span"
              className="select-dropdown-field-controll-list-label"
            >
              {CheckValueLocale(option?.[keyName], "", {}, intl)}
            </Typography>
          </>
        );
      }}
    />
  );
};
