import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import { faPause, faPlay } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import LucButton from "shared/lucButton/lucButton";
import WaveSurfer from "wavesurfer.js";

const formatTime = (time) => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = Math.floor(time % 60);

  const parts = [
    hours > 0 ? hours?.toString() : null,
    minutes?.toString(),
    seconds?.toString(),
  ].filter(Boolean);

  return parts.join(":");
};

export const AudioMessage = ({
  message = {},
  currentPlayedAudioId,
  setCurrentPlayedAudioId,
  messageId: audioId,
}) => {
  const url = message?.media?.media_url;
  const waveformRef = useRef(null);
  const wavesurferRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [error, setError] = useState(null);
  const formattedTime = useMemo(
    () => formatTime(duration - currentTime),
    [duration, currentTime],
  );
  useEffect(() => {
    if (!waveformRef.current) return;

    // Initialize WaveSurfer
    const wavesurfer = WaveSurfer.create({
      container: waveformRef.current,
      waveColor: "#8FADCC",
      progressColor: "#334155",
      height: 33,
      responsive: true,
      normalize: true,
      barWidth: 3.3,
      cursorWidth: 0,
      barGap: 4.3,
      width: 127,
      barRadius: 2,
      hideScrollbar: true,
    });

    wavesurfer.load(url).catch((e) => {
      // error when loading audio
    });

    wavesurfer.on("ready", () => {
      setIsReady(true);
      setDuration(wavesurfer.getDuration());
    });

    wavesurfer.on("error", (e) => {
      setError(e ?? "Error loading audio");
    });

    wavesurfer.on("play", () => {
      setIsPlaying(true);
    });

    wavesurfer.on("pause", () => {
      setIsPlaying(false);
    });

    wavesurfer.on("finish", () => {
      setIsPlaying(false);
    });

    wavesurfer.on("seek", () => {
      setCurrentTime(wavesurfer?.getCurrentTime?.());
    });
    wavesurfer.on("audioprocess", () => {
      setCurrentTime(wavesurfer?.getCurrentTime?.());
    });

    wavesurferRef.current = wavesurfer;

    // Cleanup on unmount
    return () => {
      wavesurfer?.destroy?.();
    };
  }, [url]);

  useEffect(() => {
    if (currentPlayedAudioId !== audioId && isPlaying) {
      wavesurferRef.current?.playPause();
    }
  }, [currentPlayedAudioId]);
  const handlePlayPause = () => {
    if (isReady && wavesurferRef.current) {
      if (!isPlaying) {
        setCurrentPlayedAudioId(audioId);
      }
      wavesurferRef.current?.playPause();
    }
  };

  return (
    <Box className="audio-media-container">
      <Box className="audio-media-controls">
        <PlayPauseButton
          isPlaying={isPlaying}
          onClick={handlePlayPause}
          id={audioId}
          isLoading={!isReady}
        />
        <Box
          sx={{
            width: 127,
          }}
        >
          <Box ref={waveformRef} />
        </Box>
        <Box className="audio-media-time">{isReady ? formattedTime : null}</Box>
      </Box>
    </Box>
  );
};

const PlayPauseButton = ({ isPlaying, onClick, isLoading, id }) => {
  return (
    <Box className="audio-media-controls-play-pause">
      {isLoading ? (
        <FontAwesomeIcon spin icon={faSpinnerThird} />
      ) : (
        <LucButton
          variant="text"
          onClick={onClick}
          minWidth={33}
          size="small"
          className="chat-file-message-download"
          id={`audio-control-${id}`}
        >
          <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
        </LucButton>
      )}
    </Box>
  );
};
