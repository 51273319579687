import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Container } from "@mui/material";
import { Box } from "@mui/system";
import CircularLoading from "components/circularProgress";
import Snackbar from "components/snackBar";
import DashboardHeader from "./components/dashboardHeader/dashboardHeader";
import DashboardList from "./components/dashboardList/dashboardList";
import DashboardFunctions from "../dashboardFunctions";
import { CheckValueLocale } from "utils/helpers";
import SnackBar from "components/snackBar";
import { CustomPagination } from "components/CustomPagination/CustomPagination";

const DashboardListPage = () => {
  const {
    dashboards,
    page,
    loading,
    pagination,
    searchText,
    canCreateDashboard,
    getDashboards,
    setSearchText,
    setPage,
    companyHasDashoboards,
    companyHasMonitors,
    sortColumn,
    sortDirection,
    isSortableLoading,
    tableHead,
    dashboardSnackBar,
    handleCloseSnackBar,
    smMonitorsCount,
    cxmMonitorsCount,
  } = DashboardFunctions();
  const intl = useIntl();

  const [openSnakbar, setOpenSnakbar] = useState(false);
  const [dashboardAlertData, setDashboardAlertData] = useState({});
  const [searchActivated, setSearchActivated] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [snackBarData, setSnackBarData] = useState({
    severity: "",
    message: "",
    title: "",
  });
  const [openSnackBarDelete, setOpenSnackBarDelete] = useState(false);
  const [dashboardMonitorMessage, setDashboardMonitorMessage] = useState(true);

  useEffect(() => {
    if (localStorage.openSnakbar === "true") {
      let alertData = JSON.parse(
        localStorage.getItem("dashboardCreatedMessage"),
      );

      if (alertData !== {} || alertData !== undefined) {
        setDashboardAlertData(alertData);
        setOpenSnakbar(true);
      }
    }
    setTimeout(() => {
      localStorage.removeItem("dashboardCreatedMessage");
      localStorage.removeItem("openSnakbar");
      setOpenSnakbar(false);
    }, 7000);
  }, []);

  useEffect(() => {
    getDashboards();
  }, [page, sortColumn, sortDirection]);

  const handleChangePage = (event, page) => {
    if (Boolean(page) === false) return;
    setPage(page);
  };

  return (
    <Container maxWidth="xl">
      {loading ? (
        <CircularLoading />
      ) : (
        <Box>
          <DashboardHeader
            canCreateDashboard={canCreateDashboard}
            searchText={searchText}
            pagination={pagination}
            page={page}
            getDashboards={getDashboards}
            setPage={setPage}
            setSearchActivated={setSearchActivated}
            setSearchText={setSearchText}
            companyHasMonitors={companyHasMonitors}
            dashboards={dashboards}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            smMonitorsCount={smMonitorsCount}
            cxmMonitorsCount={cxmMonitorsCount}
          />

          <DashboardList
            dashboards={dashboards}
            searchActivated={searchActivated}
            getDashboards={getDashboards}
            snackBarData={snackBarData}
            setSnackBarData={setSnackBarData}
            openSnackBarDelete={openSnackBarDelete}
            setOpenSnackBarDelete={setOpenSnackBarDelete}
            companyHasDashoboards={companyHasDashoboards}
            companyHasMonitors={companyHasMonitors}
            page={page}
            setPage={setPage}
            isSortableLoading={isSortableLoading}
            tableHead={tableHead}
          />
          {pagination?.pages > 1 && !isSortableLoading ? (
            <CustomPagination
              onChange={handleChangePage}
              page={page}
              pagination={pagination}
            />
          ) : (
            <div className="pagination-one-page"></div>
          )}
        </Box>
      )}

      {openSnakbar && dashboardAlertData !== {} ? (
        <Snackbar
          open={openSnakbar}
          severity={dashboardAlertData.severity}
          message={CheckValueLocale(dashboardAlertData.message, "", {}, intl)}
        />
      ) : null}
      <SnackBar
        open={dashboardSnackBar?.openSnackBar}
        severity={dashboardSnackBar?.severity}
        message={CheckValueLocale(dashboardSnackBar?.message, "", {}, intl)}
        title={CheckValueLocale(dashboardSnackBar?.title, "", {}, intl)}
        handleClose={handleCloseSnackBar}
      />
    </Container>
  );
};

export default DashboardListPage;
