import React from "react";
import { Divider, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import QuestionTypeDropdown from "./questionTypeDropdown";
import "../surveyBuilder.scss";

const ConstraintsHeader = (props) => {
  const {
    filteredSections,
    surveySections,
    setSurveySections,
    ssIndex,
    sqIndex,
  } = props;
  const intl = useIntl();

  return (
    <>
      <Typography
        component="h4"
        className="constraints-sub-heading"
        data-testid="constraints_type_heading"
      >
        {CheckValueLocale("type", "", {}, intl)?.toUpperCase()}
      </Typography>
      <QuestionTypeDropdown
        filteredSections={filteredSections}
        surveySections={surveySections}
        setSurveySections={setSurveySections}
        ssIndex={ssIndex}
        sqIndex={sqIndex}
      />
      <Divider />
      <Typography
        component="h4"
        className="constraints-sub-heading"
        data-testid="constraints_settings_heading"
      >
        {CheckValueLocale("settings", "", {}, intl)?.toUpperCase()}
      </Typography>
    </>
  );
};

export default ConstraintsHeader;
