import { useMutation } from "@tanstack/react-query";
import SettingsController from "services/controllers/settingsController";

export const useRequestEnableSMS = () => {
  return useMutation({
    mutationFn: async (saveData) => {
      return await SettingsController?.requestDemoOrUpgrade(saveData);
    },
  });
};
