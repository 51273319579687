import { useQuery } from "@tanstack/react-query";
import { ReportServices } from "services/api/reports";

export const useReportMonitors = ({ enabled, queryData, datasourcesIds }) => {
  const { report_type_id, monitor_type_id, data_source_id } = queryData || {};

  return useQuery({
    queryKey: [
      "reportMonitors",
      report_type_id,
      monitor_type_id,
      data_source_id,
    ],
    queryFn: () => ReportServices.getReportMonitors(queryData),
    enabled:
      enabled &&
      !!report_type_id &&
      (Array.isArray(monitor_type_id)
        ? !!monitor_type_id?.length
        : !!monitor_type_id) &&
      (Array.isArray(data_source_id)
        ? !!data_source_id?.length
        : !!data_source_id),
    select: (data) =>
      data?.data?.data?.map((item) => {
        return {
          id: item?.attributes?.id,
          name: item?.attributes?.name,
          data_source_id: item?.attributes?.data_sources?.map(
            (datasource) => datasourcesIds?.[datasource],
          ),
        };
      }),
  });
};
