import React, { useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { Box, TextField } from "@mui/material";

const NameQuestion = (props) => {
  const { previewMode } = props;

  const intl = useIntl();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  return (
    <Box className="survey-builder-question-name">
      <TextField
        id="survey-builder-question-first-name-input"
        className="survey-builder-question-name-input"
        placeholder={CheckValueLocale("first_name", "", {}, intl)}
        value={firstName}
        onChange={previewMode ? (e) => setFirstName(e.target.value) : null}
        variant="standard"
        readOnly={!previewMode}
      />
      <TextField
        id="survey-builder-question-last-name-input"
        className="survey-builder-question-name-input"
        placeholder={CheckValueLocale("last_name", "", {}, intl)}
        value={lastName}
        onChange={previewMode ? (e) => setLastName(e.target.value) : null}
        variant="standard"
        readOnly={!previewMode}
      />
    </Box>
  );
};

export default NameQuestion;
