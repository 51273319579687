import { useState } from "react";
import "./tableWidget.scss";
import dummyImage from "images/shared-images/dummy-image-for-user.svg";
import { TableCell, TableRow, Tooltip } from "@mui/material";
import { faBadgeCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserProfileSideBar from "shared/userProfileSideBar/UserProfileSideBar";
import { useIntl } from "react-intl";
import { CheckValueLocale, isArabic } from "utils/helpers";

const ItemsList = (props) => {
  const intl = useIntl();
  const [openProfile, setOpenProfile] = useState(false);
  const {
    circularPP,
    showProfile,
    unixDate,
    monitorDataSource,
    monitorId,
    productId,
    isXPlatform,
  } = props;
  const handleImageError = (event) => {
    event.target.src = dummyImage;
    event.target.alt = "placeholder-image";
  };
  const handleSideBar = () => {
    if (showProfile) {
      setOpenProfile(true);
    }
  };
  return (
    <>
      {openProfile ? (
        <UserProfileSideBar
          unixDate={unixDate}
          openProfile={openProfile}
          setOpenProfile={setOpenProfile}
          selectedCard={{
            screen_name: props.row.id,
            user_name: props.row.name,
            profile_image: props.row.data,
          }}
          dataSourceName={monitorDataSource}
          monitorId={monitorId}
          productId={productId}
          isXPlatform={isXPlatform}
        />
      ) : null}
      <TableRow
        key={props.row.id}
        className={`tr-bg ${showProfile ? "hover-profile-tr" : ""}`}
      >
        <TableCell onClick={handleSideBar} className="text-row">
          <div
            className={`author-img-box ${showProfile ? "hover-profile" : ""}`}
          >
            {!props.linkedInPage && !props?.isTiktok && (
              <div className="author-profile-img">
                {props?.isEngagInbox ? (
                  <div
                    className={`agent-profile-active ${props?.row?.status}`}
                  ></div>
                ) : null}
                <img
                  style={{ borderRadius: circularPP ? "24px" : "4px" }}
                  src={
                    props.row.data == null && props.row.data !== undefined
                      ? dummyImage
                      : props.row.data !== undefined
                        ? props.row.data
                        : props.row.image_url
                  }
                  onError={handleImageError}
                  alt="author"
                />
              </div>
            )}
            {props.iconBeforeItemTitle
              ? props.iconBeforeItemTitle
              : props?.row?.sourceIcon}

            <div
              className={`authore-name-box ${
                circularPP ? " authore-circular-container" : ""
              }`}
            >
              <span className={`user_name`}>
                <span dir="ltr">
                  {props.isTiktok && "@"}
                  {props.row?.name?.length > 25 ? (
                    <Tooltip title={props?.row?.name} arrow placement="top">
                      {isArabic(props.row?.name)
                        ? `...${props.row.name?.substring(0, 25)}`
                        : `${props.row.name?.substring(0, 25)}...`}
                    </Tooltip>
                  ) : (
                    props.row?.name
                  )}
                </span>

                {props?.row?.verified && props.row.verified !== "false" && (
                  <FontAwesomeIcon
                    icon={faBadgeCheck}
                    color="#1D9BF0"
                    className="table-verified-icon"
                  />
                )}
              </span>
              {!props.linkedInPage && !props?.isTiktok && (
                <span className="user_id" dir="ltr">
                  {!props?.isEngagInbox
                    ? "@" + props.row.id
                    : CheckValueLocale(
                        `agent_status_${props?.row?.status}`,
                        "",
                        {},
                        intl,
                      )}
                </span>
              )}
            </div>
          </div>
        </TableCell>
        <TableCell>
          {Number(
            props?.totalValue
              ? props?.totalValue
              : (props?.row?.assignedValue ?? 0),
          )?.toLocaleString()}
        </TableCell>
        {props?.isEngagInbox ? (
          <TableCell>
            {Number(props?.row?.completedValue ?? 0)?.toLocaleString()}
          </TableCell>
        ) : null}
        {isXPlatform ? (
          <TableCell>
            {Number(props?.totalValueEng ?? 0)?.toLocaleString()}
          </TableCell>
        ) : null}
      </TableRow>
    </>
  );
};
export default ItemsList;
