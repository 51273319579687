import React, { useState } from "react";
import { Box, Button, Grid, InputLabel, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { faCircleX } from "@fortawesome/pro-solid-svg-icons";
import ReportSelectInput from "../reportSelectInput";
import ReportMonthlyOptionsSelect from "pages/reports/components/reportDeliverySharedStep/reportMonthlyOptionsSelect";
import CreateReportDateTimeSelect from "pages/reports/pages/create/components/createReportDateTimeSelect";
import ReportBrandingSelect from "pages/reports/components/reportBranding/reportBrandingSelect";
import ReportLuciSelect from "pages/reports/components/reportLuci/reportLuciSelect";
import "./style.scss";

const ReportDeliverySharedStep = ({
  formik,
  availableUsers,
  availableUsersLoading,
  reportFrequencies,
  reportFrequenciesLoading,
  isNewsletterReport,
  reportLuciTokens,
  reportLuciTokensLoading,
  isPublicAccountInstagram,
  showCustomDaySelect,
  setShowCustomDaySelect,
  isEdit,
}) => {
  const intl = useIntl();

  const [openDateModal, setOpenDateModal] = useState(false);

  const dailyRecurrences =
    reportFrequencies
      ?.find((item) => item?.name === "daily")
      ?.report_recurrences?.toReversed() || [];

  const monthlyRecurrences =
    reportFrequencies?.find((item) => item?.name === "monthly")
      ?.report_recurrences || [];

  const customDayReccurenceId = monthlyRecurrences?.find(
    (item) => item?.recurrence_type == "day",
  )?.id;

  // Handle change frequency ex: daily, monthly, etc
  const handleChangeFrequency = (e) => {
    const value = e?.target?.value;
    formik?.setFieldValue("frequency", value);
    if (value === "monthly") {
      formik?.setFieldValue("report_recurrence_id", customDayReccurenceId);
      formik?.setFieldValue("on_specific_day", 1);
    } else if (value === "daily") {
      formik?.setFieldValue("report_recurrence_id", dailyRecurrences?.[0]?.id);
      formik?.setFieldValue("on_specific_day", "");
    } else {
      const recurrenceId = reportFrequencies?.find(
        (item) => item?.name === value,
      )?.report_recurrences?.[0]?.id;
      formik?.setFieldValue("report_recurrence_id", recurrenceId);
      formik?.setFieldValue("on_specific_day", "");
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <ReportSelectInput
          id="create-report-users-select-input"
          label="report_report_emails"
          name="users"
          options={
            availableUsers?.map((item) => {
              return { value: item?.id, label: item?.email };
            }) || []
          }
          value={formik?.values?.users || []}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          loading={availableUsersLoading}
          renderValue={() => CheckValueLocale("select", "", {}, intl)}
          error={formik?.touched?.users && Boolean(formik?.errors?.users)}
          helperText={formik?.touched?.users && formik?.errors?.users}
          multiple
          disableTranslation
          isEdit={true}
        />
        {formik?.values?.users?.length > 0 ? (
          <Box className="report-delivery-selected-emails-wrapper">
            {formik?.values?.users?.map((item) => (
              <Button
                key={item}
                id="report-selected-email-chip"
                className="report-delivery-selected-email-chip"
                variant="outlined"
                size="small"
                endIcon={
                  <FontAwesomeIcon
                    icon={faCircleX}
                    fixedWidth
                    onClick={() =>
                      formik?.setFieldValue(
                        "users",
                        formik?.values?.users?.filter((user) => user !== item),
                      )
                    }
                  />
                }
              >
                <Typography>
                  {availableUsers
                    ?.find((user) => user?.id === item)
                    ?.email?.toLowerCase()}
                </Typography>
              </Button>
            ))}
          </Box>
        ) : null}
      </Grid>

      <Grid item xs={6}>
        <ReportSelectInput
          id="create-report-language-select-input"
          label="report_language"
          name="language"
          options={[
            { value: "ar-SA", label: "arabic" },
            { value: "en-US", label: "english" },
          ]}
          value={formik?.values?.language || []}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          error={formik?.touched?.language && Boolean(formik?.errors?.language)}
          helperText={formik?.touched?.language && formik?.errors?.language}
          isEdit={true}
        />
      </Grid>

      <Grid item xs={6}>
        <ReportSelectInput
          id="create-report-frequency-select-input"
          label="report_frequency"
          name="frequency"
          options={
            reportFrequencies?.map((item) => {
              return { value: item?.name, label: `report_${item?.name}` };
            }) || []
          }
          value={formik?.values?.frequency || ""}
          onChange={handleChangeFrequency}
          onBlur={formik?.handleBlur}
          loading={reportFrequenciesLoading}
          error={
            formik?.touched?.frequency && Boolean(formik?.errors?.frequency)
          }
          helperText={formik?.touched?.frequency && formik?.errors?.frequency}
          isEdit={true}
        />
      </Grid>

      {formik?.values?.frequency === "daily" ? (
        <Grid item xs={6}>
          <ReportSelectInput
            id="create-report-daily-recurrence-select-input"
            label="daily_recurrence"
            name="report_recurrence_id"
            options={
              dailyRecurrences?.map((item) => {
                return {
                  value: item?.id,
                  label: `${CheckValueLocale(
                    "every",
                    "",
                    {},
                    intl,
                  )} ${CheckValueLocale(
                    `${item?.recurrence}_hours`,
                    "",
                    {},
                    intl,
                  )} (${CheckValueLocale(
                    `${24 / item?.recurrence}_reports_per_day`,
                    "",
                    {},
                    intl,
                  )})`,
                };
              }) || []
            }
            value={formik?.values?.report_recurrence_id || ""}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            loading={reportFrequenciesLoading}
            error={
              formik?.touched?.report_recurrence_id &&
              Boolean(formik?.errors?.report_recurrence_id)
            }
            helperText={
              formik?.touched?.report_recurrence_id &&
              formik?.errors?.report_recurrence_id
            }
            isEdit={true}
          />
        </Grid>
      ) : null}

      <Grid item xs={6}>
        <CreateReportDateTimeSelect
          open={openDateModal}
          setOpen={setOpenDateModal}
          date={formik?.values?.start_date_time}
          handleChangeDate={(date) => {
            formik?.setFieldValue("start_date_time", date);
          }}
          helperText={
            Boolean(formik?.touched?.start_date_time) &&
            formik?.errors?.start_date_time
          }
          error={
            formik?.touched?.start_date_time && formik?.errors?.start_date_time
          }
          isEdit={true}
        />
      </Grid>

      {formik?.values?.frequency === "monthly" ? (
        <Grid item xs={6}>
          <InputLabel className="report-monthly-recurrence-select-input-label">
            {CheckValueLocale("sending_recurrence", "", {}, intl)}
          </InputLabel>
          <ReportMonthlyOptionsSelect
            formik={formik}
            recurrences={monthlyRecurrences}
            showCustomDaySelect={showCustomDaySelect}
            setShowCustomDaySelect={setShowCustomDaySelect}
            isEdit={isEdit}
          />
        </Grid>
      ) : null}

      {!isNewsletterReport ? (
        <Grid item xs={12}>
          <ReportBrandingSelect
            brandingValue={formik?.values?.is_branding}
            handleChangeBranding={(e) => {
              const value = e?.target?.value;
              formik?.setFieldValue(
                "is_branding",
                value === true || value === "true",
              );
            }}
            lastUpdated={reportLuciTokens?.company_branding_last_update}
            companyHasBranding={reportLuciTokens?.is_company_branding || false}
            isCreate={true}
          />
        </Grid>
      ) : null}

      {!isNewsletterReport && !isPublicAccountInstagram ? (
        <Grid item xs={12}>
          <ReportLuciSelect
            loading={reportLuciTokensLoading}
            tokens={reportLuciTokens?.luci_token}
            value={formik?.values?.is_luci_report || false}
            handleChange={(e) => {
              const value = e?.target?.checked;
              formik?.setFieldValue(
                "is_luci_report",
                value === true || value === "true",
              );
            }}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default ReportDeliverySharedStep;
