import { Box, FormControl, TextField, Typography } from "@mui/material";
import PopupModal from "components/popupModal";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { useFormik } from "formik";
import { requestVerificationURLSchema } from "../utils";
import { useRequestResourceVerification } from "../../../../hooks/useRequestResourceVerification";

export const RequestSourceVerification = ({
  open,
  onClose,
  setSnackbarState,
}) => {
  const intl = useIntl();
  const { mutate: requestVerification, isPending } =
    useRequestResourceVerification();
  const closeModal = () => {
    onClose();
    formik.resetForm();
  };

  const handleRequestVerification = (values) => {
    const queryData = {
      url: values?.sourcesUrls,
    };
    requestVerification(queryData, {
      onSuccess: () => {
        setSnackbarState({
          open: true,
          message: CheckValueLocale(
            "sources_verification_request_success_title",
            "",
            {},
            intl,
          ),
          severity: "success",
        });
      },
      onError: () => {
        setSnackbarState({
          open: true,
          title: CheckValueLocale(
            "settings_some_thing_went_wrong",
            "",
            {},
            intl,
          ),
          message: CheckValueLocale(
            "settings_please_try_again_later",
            "",
            {},
            intl,
          ),
          severity: "error",
        });
      },
      onSettled: () => {
        closeModal();
      },
    });
  };

  const formik = useFormik({
    initialValues: { sourcesUrls: "" },
    validationSchema: requestVerificationURLSchema,
    onSubmit: handleRequestVerification,
  });

  const modalBody = (
    <Box className="request-source-verification-modal-body">
      <Typography className="request-source-verification-modal-body-text">
        {CheckValueLocale(
          "request_source_verification_modalBody",
          "",
          {},
          intl,
        )}
      </Typography>
      <FormControl fullWidth>
        <Typography component="span" className="request-source-text-area-label">
          {CheckValueLocale("sources_urls", "", {}, intl)}
        </Typography>
        <TextField
          multiline
          fullWidth
          variant="outlined"
          rows={5}
          name="sourcesUrls"
          value={formik?.values?.sourcesUrls}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          error={
            formik?.touched?.sourcesUrls && Boolean(formik?.errors?.sourcesUrls)
          }
          helperText={
            formik?.touched?.sourcesUrls &&
            CheckValueLocale(formik?.errors?.sourcesUrls, "", {}, intl)
          }
          FormHelperTextProps={{ component: "span" }}
          placeholder={CheckValueLocale(
            "request_source_verification_text_area_placeholder",
            "",
            {},
            intl,
          )}
          className="request-source-verification-text-area"
        />
      </FormControl>
    </Box>
  );

  const isFieldEmpty = !formik?.values?.sourcesUrls?.trim();

  return (
    <PopupModal
      title={CheckValueLocale("request_source_verification", "", {}, intl)}
      body={modalBody}
      open={open}
      close={closeModal}
      accept={formik?.submitForm}
      rightBtn={CheckValueLocale("submit", "", {}, intl)}
      leftBtn={CheckValueLocale("cancel", "", {}, intl)}
      addClasses="request-verification-modal-added-classes"
      disabled={isFieldEmpty}
      isRightBtnLoading={isPending}
    />
  );
};
