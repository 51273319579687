import { Box, Grid, Typography } from "@mui/material";
import LineChartWidget from "components/widgets/lineChartWidget";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import StackedBarWidget from "components/widgets/stackedBarWidget";
import StackedLineWidget from "components/widgets/stackedLineWidget";
import PieChartWidget from "components/widgets/pieChartWidget";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import OverView from "components/widgets/overViewWidget";
import AllTopMedia from "../../components/allTopMeda/allTopMedia";
import AllPosts from "../../components/allPosts/allPosts";
import {
  handleAggregateLineChartOneValue,
  handleAggregateLegends,
  handleAggregateBarCharts,
  handleAggregatePieChart,
  handleAggregateLineChartMultipleValues,
  handlePostsInteractionsLegends,
} from "utils/helpers/aggregateFunctions";
import _ from "lodash";
import {
  handleDates,
  getNoOfDays,
  CheckValueLocale,
} from "utils/helpers/index";

const OverViewSection = (props) => {
  const intl = useIntl();
  const {
    dashboardID,
    response,
    insightsID,
    insightsResponse,
    dates,
    widgetsName,
    getIcons,
    metricsValues,
    monitorType,
    widgetsTypeTitle,
    isKeywordType,
    overView,
    setOverView,
    overViewPreLoader,
    setOverViewPreLoader,
    contentStylesPreLoader,
    setContentStylesPreLoader,
    contentStyles,
    setContentStyles,
    associatedTopicsPreLoader,
    setAssociatedTopicsPreLoader,
    associatedTopics,
    setAssociatedTopics,
    volumeOvertimePreLoader,
    setVolumeOvertimePreLoader,
    topHashtagsPreLoader,
    setTopHashtagsPreLoader,
    topHashtags,
    setTopHashtags,
    topKeywordsPreLoader,
    setTopKeywordsPreLoader,
    topKeywords,
    setTopKeywords,
    sentimentAnalysisPreLoader,
    setSentimentAnalysisPreLoader,
    sentimentAnalysis,
    setSentimentAnalysis,
    interactionsLegend,
    setInteractionsLegend,
    interactionsPreLoader,
    setInteractionsPreLoader,
    interactions,
    setInteractions,
    totalVolumeOvertimeCount,
    setTotalVolumeOvertimeCount,
    volumeOvertime,
    setVolumeOvertime,
    articlesEngagements,
    setArticlesEngagements,
    articlesEngagementsPreloader,
    setArticlesEngagementsPreloader,
    reactions,
    setReactions,
    reachFunnel,
    setReachFunnel,
    reactionsPreLoader,
    setReactionsPreLoader,
    reachFunnelPreLoaderDataSources,
    setReachFunnelPreLoaderDataSources,
    topEngaged,
    setTopEngaged,
    topSites,
    setTopSites,
    topEngagedPreLoader,
    setTopEngagedPreLoader,
    topSitesPreLoader,
    setTopSitesPreLoader,
    twTopPosts,
    setTwTopPosts,
    igTopPosts,
    setIgTopPosts,
    fbTopPosts,
    setFbTopPosts,
    nbTopPosts,
    setNbTopPosts,
    twTopPostsPreloader,
    setTwTopPostsPreloader,
    igTopPostsPreloader,
    setIgTopPostsPreloader,
    fbTopPostsPreloader,
    setFbTopPostsPreloader,
    nbTopPostsPreloader,
    setNbTopPostsPreloader,
    setGenderAgeDistributionPreLoader,
    pageReactionPieChartValue,
    setPageReactionPieChartValue,
    sentimentPieChart,
    setSentimentPieChart,
    contentType,
    setContentType,
    contentTypePreLoader,
    setContentTypePreLoader,
    reachFunnelLegacy,
    setReachFunnelLegacy,
    reachFunnelLegacyPreLoaderDataSources,
    setReachFunnelLegacyPreLoaderDataSources,
    twTopImages,
    setTwTopImages,
    fbTopImages,
    setFbTopImages,
    igTopImages,
    setIgTopImages,
    twTopImagesPreLoader,
    setTwTopImagesPreLoader,
    fbTopImagesPreLoader,
    setFbTopImagesPreLoader,
    igTopImagesPreLoader,
    setIgTopImagesPreLoader,
    twTopVideos,
    setTwTopVideos,
    fbTopVideos,
    setFbTopVideos,
    igTopVideos,
    setIgTopVideos,
    twTopVideosPreLoader,
    setTwTopVideosPreLoader,
    fbTopVideosPreLoader,
    setFbTopVideosPreLoader,
    igTopVideosPreLoader,
    setIgTopVideosPreLoader,
    setTopPostsCount,
    topPostsCount,
    singleTweetAnalysis,
    isPublicAccount,
    startDate,
    endDate,
  } = props;
  const [associatedTopicsCopy, setAssociatedTopicsCopy] = useState([]);
  const [sentimentAnalysisCopy, setSentimentAnalysisCopy] = useState([]);

  const [noOfDatasources, setNoOfDatasources] = useState([]);

  const [totalValuePostsInteractions, setTotalValuePostsInteractions] =
    useState(0);
  const [maxValuePostsInteractions, setMaxValuePostsInteractions] = useState(0);
  const [maxValueInteractionsDate, setMaxValueInteractionsDate] = useState("");
  const [avgValuePostsInteractions, setAvgValuePostsInteractions] = useState(0);

  const handleSentimentZeroValObject = (
    valueToCheck,
    updatedValue,
    valueOnWhichCheck,
    isLastCheck,
  ) => {
    valueToCheck?.map((objVal, index) => {
      if (
        objVal.value === 0 &&
        (valueOnWhichCheck !== undefined
          ? valueOnWhichCheck?.some((el) => el.name === objVal.name)
          : true)
      ) {
        let tempDatasourceArr = noOfDatasources;
        if (
          isLastCheck !== undefined &&
          tempDatasourceArr[index] === undefined
        ) {
          tempDatasourceArr.push(0);
          setNoOfDatasources(tempDatasourceArr);
        }
        updatedValue.push(objVal);
      } else {
        let tempDatasourceArr = noOfDatasources;
        if (isLastCheck && tempDatasourceArr[index] !== undefined) {
          tempDatasourceArr[index] = tempDatasourceArr[index] + 1;
          setNoOfDatasources(tempDatasourceArr);
        }
        if (isLastCheck && tempDatasourceArr[index] === undefined) {
          tempDatasourceArr.push(1);
          setNoOfDatasources(tempDatasourceArr);
        }
      }
    });
  };

  //handle response with useEffect (when load page & any update)
  useEffect(() => {
    switch (response?.eventName) {
      case "Twitter__EngagementsPage__volume_overtime":
      case "Facebook__EngagementsPage__volume_overtime":
      case "Instagram__EngagementsPage__volume_overtime":
      case "Talkwalker__EngagementsPage__volume_overtime":
        handleAggregateLineChartOneValue(
          response?.eventData?.posts_over_time,
          volumeOvertime,
        );
        setTotalVolumeOvertimeCount(
          totalVolumeOvertimeCount + response?.eventData?.total_posts_count,
        );
        setVolumeOvertimePreLoader((oldArray) => [
          ...oldArray,
          response?.data_source,
        ]);
        break;
      case "Twitter__EngagementsPage__comments_interactions":
      case "Instagram__EngagementsPage__comments_interactions":
        handleAggregateBarCharts(
          response.eventData.comments_interactions,
          interactions,
        );
        handlePostsInteractionsLegends(
          response.eventData.comments_interactions_legends,
          interactionsLegend,
          metricsValues?.interactions &&
            _.cloneDeep([...metricsValues?.interactions?.data_sources]),
        );
        setTotalValuePostsInteractions(
          totalValuePostsInteractions + response?.eventData?.total_interactions,
        );
        setInteractionsPreLoader((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;

      case "Twitter__EngagementsPage__posts_interactions":
      case "Facebook__EngagementsPage__posts_interactions":
      case "Instagram__EngagementsPage__posts_interactions":
        handleAggregateBarCharts(
          response.eventData.posts_interactions.posts_interactions_over_time,
          interactions,
        );
        handlePostsInteractionsLegends(
          response.eventData.posts_interactions.posts_interactions_legends,
          interactionsLegend,
          metricsValues?.interactions &&
            _.cloneDeep([...metricsValues?.interactions?.data_sources]),
        );
        setTotalValuePostsInteractions(
          totalValuePostsInteractions +
            response?.eventData?.posts_interactions?.total_interactions,
        );
        setInteractionsPreLoader((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;

      case "Twitter__EngagementsPage__content_style":
        handleAggregatePieChart(response.eventData, contentStyles);
        setContentStylesPreLoader((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Twitter__EngagementsPage__associated_topics":
      case "Facebook__EngagementsPage__associated_topics":
      case "Instagram__EngagementsPage__associated_topics":
        handleAggregatePieChart(
          response.eventData.associated_topics,
          associatedTopicsCopy,
        );
        setAssociatedTopicsPreLoader((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;

      case "Twitter__EngagementsPage__top_keywords":
      case "Facebook__EngagementsPage__top_keywords":
      case "Instagram__EngagementsPage__top_keywords":
        handleAggregatePieChart(
          response.eventData.top_keywords,
          topKeywords,
          "id",
          "stats_count",
          true,
        );
        setTopKeywordsPreLoader((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Twitter__EngagementsPage__top_hashtags":
      case "Facebook__EngagementsPage__top_hashtags":
      case "Instagram__EngagementsPage__top_hashtags":
        handleAggregatePieChart(
          response.eventData.top_hashtags,
          topHashtags,
          "id",
          "stats_count",
          true,
        );
        setTopHashtagsPreLoader((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Twitter__EngagementsPage__sentiment_analysis":
      case "Facebook__EngagementsPage__sentiment_analysis":
      case "Talkwalker__EngagementsPage__sentiment_analysis":
      case "Instagram__EngagementsPage__sentiment_analysis":
        let zeroValArr = {
          negative: [],
          positive: [],
          neutral: [],
        };
        handleSentimentZeroValObject(
          response.eventData.sentiment_overtime?.negative,
          zeroValArr.negative,
        );
        handleSentimentZeroValObject(
          response.eventData.sentiment_overtime?.positive,
          zeroValArr.positive,
          zeroValArr.negative,
        );
        handleSentimentZeroValObject(
          response.eventData.sentiment_overtime?.neutral,
          zeroValArr.neutral,
          zeroValArr.positive,
          true,
        );

        handleAggregateLegends(
          response.eventData.sentiment_piechart,
          sentimentPieChart,
        );

        handleAggregateLineChartMultipleValues(
          response.eventData.sentiment_overtime,
          sentimentAnalysisCopy,
          setSentimentAnalysisCopy,
        );
        setSentimentAnalysisPreLoader((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Talkwalker__EngagementsPage__posts_interactions":
        setArticlesEngagements(response.eventData.posts_interactions);
        setArticlesEngagementsPreloader((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Talkwalker__EngagementsPage__top_engaged_sites":
        setTopEngaged(response.eventData.top_engaged_sites);
        setTopEngagedPreLoader((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Talkwalker__EngagementsPage__top_sites":
        setTopSites(response.eventData.top_sites);
        setTopSitesPreLoader((oldArray) => [...oldArray, response.data_source]);
        break;
      case "Talkwalker__EngagementsPage__overview":
        let overViewArray = [];
        if (response.eventData) {
          for (let [key, values] of Object.entries(response.eventData)) {
            overViewArray.push({ name: key, value: values });
          }
        }
        setOverView([...overViewArray]);
        setOverViewPreLoader((oldArray) => [...oldArray, response.data_source]);
        break;
      case "Twitter__EngagementsPage__reach_funnel":
        setReachFunnel(response.eventData.reach_funnel);
        setReachFunnelPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Twitter__EngagementsPage__top_posts_comments":
        setTwTopPosts(response.eventData.top_posts);
        setTopPostsCount(topPostsCount + response.eventData.top_posts_count);
        setTwTopPostsPreloader((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Facebook__EngagementsPage__top_posts_comments":
        setFbTopPosts(response.eventData.top_posts);
        setTopPostsCount(topPostsCount + response.eventData.top_posts_count);
        setFbTopPostsPreloader((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Instagram__EngagementsPage__top_posts_comments":
        setIgTopPosts(response.eventData.top_posts);
        setTopPostsCount(topPostsCount + response.eventData.top_posts_count);
        setIgTopPostsPreloader((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Talkwalker__EngagementsPage__top_posts_comments":
        setNbTopPosts(response.eventData.top_posts);
        setTopPostsCount(topPostsCount + response.eventData.top_posts_count);
        setNbTopPostsPreloader((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Twitter__EngagementsPage__top_videos":
        setTwTopVideos(response.eventData.top_videos);
        setTwTopVideosPreLoader((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Twitter__EngagementsPage__top_images":
        setTwTopImages(response.eventData.top_images);
        setTwTopImagesPreLoader((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Facebook__EngagementsPage__top_videos":
        setTwTopVideos(response.eventData.top_videos);
        setTwTopVideosPreLoader((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Facebook__EngagementsPage__top_images":
        setFbTopImages(response.eventData.top_images);
        setFbTopImagesPreLoader((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Instagram__EngagementsPage__top_videos":
        setIgTopVideos(response.eventData.top_videos);
        setIgTopVideosPreLoader((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Instagram__EngagementsPage__top_images":
        setIgTopImages(response.eventData.top_images);
        setIgTopImagesPreLoader((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Facebook__EngagementsPage__reactions":
        setReactions(
          response.eventData.posts_reactions.posts_reactions_over_time,
        );
        setPageReactionPieChartValue(
          response.eventData.posts_reactions.posts_reactions_piechart,
        );
        setReactionsPreLoader((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Instagram__EngagementsPage__content_type":
        setContentType(response.eventData.posts_content_types_legends);
        setContentTypePreLoader((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Talkwalker__EngagementsPage__reach_funnel":
        setReachFunnelLegacy(response.eventData);
        setReachFunnelLegacyPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      default:
        break;
    }
  }, [response]);

  const handleSentimentAggregate = (valueToAggregate, updatedValue) => {
    valueToAggregate?.map((arr, index) => {
      let updatedVal = 0;
      if (noOfDatasources[index] !== 0)
        updatedVal = arr?.value / noOfDatasources[index];
      else updatedVal = arr?.value;
      updatedValue.push({
        name: arr?.name,
        value: updatedVal,
        native_value: arr?.native_value,
      });
    });
  };

  useEffect(() => {
    if (
      metricsValues?.associated_topics &&
      _.isEqual(
        associatedTopicsPreLoader?.sort(),
        [...metricsValues?.associated_topics?.data_sources]?.sort(),
      ) === true
    )
      setAssociatedTopics(associatedTopicsCopy);
    if (
      metricsValues?.sentiment_analysis &&
      _.isEqual(
        sentimentAnalysisPreLoader?.sort(),
        [...metricsValues?.sentiment_analysis?.data_sources]?.sort(),
      ) === true
    ) {
      let updatedSentimentAnalysisCopy = {
        negative: [],
        positive: [],
        neutral: [],
      };
      handleSentimentAggregate(
        sentimentAnalysisCopy?.negative,
        updatedSentimentAnalysisCopy.negative,
      );
      handleSentimentAggregate(
        sentimentAnalysisCopy?.neutral,
        updatedSentimentAnalysisCopy.neutral,
      );
      handleSentimentAggregate(
        sentimentAnalysisCopy?.positive,
        updatedSentimentAnalysisCopy.positive,
      );
      setSentimentAnalysis(updatedSentimentAnalysisCopy);
    }
    if (
      metricsValues?.interactions &&
      _.isEqual(
        interactionsPreLoader?.sort(),
        [...metricsValues?.interactions?.data_sources]?.sort(),
      ) === true
    ) {
      let tempArr = [];
      interactions?.map((interaction) => {
        tempArr?.push({
          date: interaction?.name,
          totalValue: interaction?.value?.reduce((sum, val) => sum + Number(val), 0),
        });
      });
      let maxValueObject =
        tempArr?.length !== 0 &&
        tempArr?.reduce((max, arr) =>
          max.totalValue > arr?.totalValue ? max : arr,
        );
      setMaxValuePostsInteractions(maxValueObject?.totalValue);
      handleDates(maxValueObject?.date, setMaxValueInteractionsDate);
      const noOfDays = getNoOfDays(startDate, endDate);
      const avg = totalValuePostsInteractions / noOfDays;
      setAvgValuePostsInteractions(avg % 1 !== 0 ? avg.toFixed(2) : avg);
    }
  }, [
    associatedTopicsPreLoader,
    sentimentAnalysisPreLoader,
    interactionsPreLoader,
  ]);

  let interactionsLabels = interactionsLegend?.map((item) => item.name);
  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (props.resetAllFields !== 1) {
      setAssociatedTopics([]);
      setVolumeOvertime([]);
      setTotalVolumeOvertimeCount(0);
      setOverView([]);
      setInteractions([]);
      setInteractionsLegend([]);
      setTotalValuePostsInteractions(0);
      setMaxValuePostsInteractions(0);
      setMaxValueInteractionsDate("");
      setAvgValuePostsInteractions(0);
      setSentimentAnalysis([]);
      setSentimentPieChart([]);
      setTopKeywords([]);
      setTopHashtags([]);
      setAssociatedTopics([]);
      setContentStyles([]);
      setContentType([]);
      setArticlesEngagements([]);
      setReactions([]);
      setPageReactionPieChartValue([]);
      setReachFunnel([]);
      setTopEngaged([]);
      setTopSites([]);
      setTwTopPosts([]);
      setIgTopPosts([]);
      setFbTopPosts([]);
      setNbTopPosts([]);
      setReachFunnelLegacy([]);
      setTwTopImages([]);
      setFbTopImages([]);
      setIgTopImages([]);
      setTwTopVideos([]);
      setFbTopVideos([]);
      setIgTopVideos([]);
      setTopPostsCount(0);
      setVolumeOvertimePreLoader([]);
      setOverViewPreLoader([]);
      setInteractionsPreLoader([]);
      setSentimentAnalysisPreLoader([]);
      setTopKeywordsPreLoader([]);
      setTopHashtagsPreLoader([]);
      setAssociatedTopicsPreLoader([]);
      setContentStylesPreLoader([]);
      setContentTypePreLoader([]);
      setArticlesEngagementsPreloader([]);
      setReachFunnelPreLoaderDataSources([]);
      setTopEngagedPreLoader([]);
      setReactionsPreLoader([]);
      setReachFunnelPreLoaderDataSources([]);
      setTopEngagedPreLoader([]);
      setTopSitesPreLoader([]);
      setTwTopPostsPreloader([]);
      setIgTopPostsPreloader([]);
      setFbTopPostsPreloader([]);
      setNbTopPostsPreloader([]);
      setTwTopImagesPreLoader([]);
      setFbTopImagesPreLoader([]);
      setIgTopImagesPreLoader([]);
      setTwTopVideosPreLoader([]);
      setFbTopVideosPreLoader([]);
      setIgTopVideosPreLoader([]);
      setGenderAgeDistributionPreLoader([]);
      setReachFunnelLegacyPreLoaderDataSources([]);
      setAssociatedTopicsCopy([]);
      setNoOfDatasources([]);
    }
  }, [props.resetAllFields]);

  let interactionsStats = [
    {
      name: "total_interactions",
      value: totalValuePostsInteractions,
    },
    {
      name: "max_interaction",
      value: maxValuePostsInteractions,
      date: maxValueInteractionsDate,
    },
    {
      name: "avg_interaction_per_day",
      value: avgValuePostsInteractions,
    },
  ];

  return (
    <>
      {widgetsName?.length > 1 && (
        <Typography className="section-name">
          {CheckValueLocale("overview", "", {}, intl).toUpperCase()}
        </Typography>
      )}
      {widgetsName?.includes("overview") && (
        <OverView
          data={overView}
          overViewPreLoaderDataSources={overViewPreLoader}
          preLoaderTrackerDataSources={[
            ...metricsValues?.overview?.data_sources,
          ]}
          isAggregateDashboard
        />
      )}
      {widgetsName?.includes("volume_overtime") && (
        <Box mt={3}>
          <LineChartWidget
            title={`${isKeywordType ? "posts_volume_kw" : "comments_volume"}`}
            titleToolTip={
              isPublicAccount
                ? "comments_volume_tw_tooltip"
                : `${widgetsTypeTitle}_volume_tooltip_dash`
            }
            totalLabel={`${widgetsTypeTitle}`}
            totalValue={totalVolumeOvertimeCount}
            data={volumeOvertime}
            showGroupBy={true}
            toolTipLabel={`${widgetsTypeTitle}`}
            showDownloadIcon
            bigImg
            lineChartPreLoaderDataSources={volumeOvertimePreLoader}
            iconAfterTitle={getIcons([
              ...metricsValues?.volume_overtime?.data_sources,
            ])}
            preLoaderTrackerDataSources={[
              ...metricsValues?.volume_overtime?.data_sources,
            ]}
            haveData
            labelColor={"#2AAAE2"}
          />
        </Box>
      )}
      {widgetsName?.includes("interactions") && (
        <Box mt={3}>
          <StackedBarWidget
            title={`${widgetsTypeTitle}_interactions`}
            titleToolTip={
              isPublicAccount
                ? `${widgetsTypeTitle}_interactions_tooltip_public_dash`
                : `${widgetsTypeTitle}_interactions_tooltip_dash`
            }
            showDownloadIcon
            bigImg
            posts={interactions}
            colors={["#2AAAE2", "#80868C", "#334155"]}
            labels={interactionsLabels}
            showGroupBy={false}
            showChartDetailsWidget
            isInteractionStatsWrapperStyle
            statsDetailsData={interactionsStats}
            showZoomPercentage
            iconAfterTitle={getIcons([
              ...metricsValues?.interactions?.data_sources,
            ])}
            showScales
            postsColumnPreLoaderDataSources={interactionsPreLoader}
            preLoaderTrackerDataSources={[
              ...metricsValues?.interactions?.data_sources,
            ]}
            hideLegend
            dataPiechart={interactionsLegend}
          />
        </Box>
      )}
      {widgetsName?.includes("articles_engagements") && (
        <Box mt={3}>
          <PieChartWidget
            title="dashboards_posts_interactions"
            titleToolTip={"article_engagements_nb_tooltip_dash"}
            showDownloadIcon
            showAI
            data={articlesEngagements}
            pieChartPreLoaderDataSources={articlesEngagementsPreloader}
            preLoaderTrackerDataSources={[
              ...metricsValues?.articles_engagements?.data_sources,
            ]}
            iconAfterTitle={getIcons([
              ...metricsValues?.articles_engagements?.data_sources,
            ])}
          />
        </Box>
      )}
      {widgetsName?.includes("reactions") && (
        <Box mt={3}>
          <StackedBarWidget
            title={"dashboard_posts_reactions"}
            titleToolTip={"reactions_fb_tooltip"}
            showDownloadIcon
            bigImg
            posts={reactions}
            showZoomPercentage
            iconAfterTitle={getIcons([
              ...metricsValues?.reactions?.data_sources,
            ])}
            postsColumnPreLoaderDataSources={reactionsPreLoader}
            preLoaderTrackerDataSources={[
              ...metricsValues?.reactions?.data_sources,
            ]}
            showGroupByClientAgent={true}
            showScales
            labels={[
              "like_reaction",
              "love_reaction",
              "care_reaction",
              "haha_reaction",
              "wow_reaction",
              "angry_reaction",
              "sad_reaction",
            ]}
            hideLegend
            dataPiechart={pageReactionPieChartValue}
            colors={[
              "#1892F2",
              "#FB5C77",
              "#F7B94D",
              "#FEDC5F",
              "#00D7B5",
              "#EC8A92",
              "#1C404E",
            ]}
          />
        </Box>
      )}
      {widgetsName?.includes("sentiment_analysis") && (
        <Box my={3}>
          <StackedLineWidget
            title={`${widgetsTypeTitle}_sentiment_analysis`}
            titleToolTip={
              isKeywordType
                ? `comments_sentiment_analysis_fb_tooltip`
                : "comments_sentiment_analysis_tooltip"
            }
            data={sentimentAnalysis}
            showGroupBy={true}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            showAI
            showDownloadIcon
            bigImg
            isSwitchCase
            showScales
            iconAfterTitle={getIcons([
              ...metricsValues?.sentiment_analysis?.data_sources,
            ])}
            isSentimentAnalysisWidget
            isSentimentAnalysisShowCharts
            dataPiechart={sentimentPieChart}
            hideLegend
            labelColors={["#89BB2A", "#E50035", "#F9A700"]}
            stackedLineWidgetPreLoaderDataSources={sentimentAnalysisPreLoader}
            preLoaderTrackerDataSources={[
              ...metricsValues?.sentiment_analysis?.data_sources,
            ]}
          />
        </Box>
      )}
      {widgetsName?.includes("reach_funnel") && (
        <Grid mb={3}>
          <StackedBarWidget
            title={"reach_funnel"}
            titleToolTip="reach_funnel_tw_tooltip"
            showDownloadIcon
            bigImg
            showScales
            posts={reachFunnel}
            colors={["#64748B", "#2AAAE2"]}
            labels={["Retweets", "original"]}
            reachFunnel
            iconAfterTitle={getIcons([
              ...metricsValues?.reach_funnel?.data_sources,
            ])}
            postsColumnPreLoaderDataSources={reachFunnelPreLoaderDataSources}
            preLoaderTrackerDataSources={[
              ...metricsValues?.reach_funnel?.data_sources,
            ]}
          />
        </Grid>
      )}
      <Box mt={3}>
        <Grid container spacing={3}>
          {widgetsName?.includes("top_keywords") && (
            <Grid item xs={6}>
              <TableWidget
                wordsList
                title={`${widgetsTypeTitle}_top_keywords`}
                titleToolTip={
                  isKeywordType
                    ? "posts_top_keywords_tw_tooltip"
                    : "comments_top_keywords_tooltip"
                }
                showDownloadIcon={true}
                data={topKeywords}
                leftColomnTitle={"keywords"}
                rightColomnTitle={"number_of_posts"}
                tableChartPreLoaderDataSources={topKeywordsPreLoader}
                preLoaderTrackerDataSources={[
                  ...metricsValues?.top_keywords?.data_sources,
                ]}
                keywords
                tablePagination
                count={topKeywords?.length}
                iconAfterTitle={getIcons([
                  ...metricsValues?.top_keywords?.data_sources,
                ])}
                withWordCloud
              />
            </Grid>
          )}
          {widgetsName?.includes("top_hashtags") && (
            <Grid item xs={6}>
              <TableWidget
                wordsList
                title={`${widgetsTypeTitle}_top_hashtags`}
                titleToolTip={
                  isKeywordType
                    ? "posts_top_hashtags_tw_tooltip"
                    : "comments_top_hashtags_tooltip"
                }
                showDownloadIcon={true}
                data={topHashtags}
                leftColomnTitle={"hashtags"}
                rightColomnTitle={"number_of_posts"}
                tableChartPreLoaderDataSources={topHashtagsPreLoader}
                preLoaderTrackerDataSources={[
                  ...metricsValues?.top_hashtags?.data_sources,
                ]}
                hashtags
                tablePagination
                count={topHashtags?.length}
                iconAfterTitle={getIcons([
                  ...metricsValues?.top_hashtags?.data_sources,
                ])}
                withWordCloud
              />
            </Grid>
          )}
        </Grid>
      </Box>
      <Box mt={3}>
        <Grid container spacing={3}>
          {widgetsName?.includes("content_style") && (
            <Grid item xs={6}>
              <PieChartWidget
                title={`${widgetsTypeTitle}_content_style`}
                titleToolTip={"comments_content_style_tw_tooltip"}
                showDownloadIcon={true}
                color={["#ED6C02", "#2AAAE2", "#80868C", "#8CC63F"]}
                data={contentStyles}
                pieChartPreLoaderDataSources={contentStylesPreLoader}
                preLoaderTrackerDataSources={[
                  ...metricsValues?.content_style?.data_sources,
                ]}
                iconAfterTitle={getIcons([
                  ...metricsValues?.content_style?.data_sources,
                ])}
                showAI
              />
            </Grid>
          )}
          {widgetsName?.includes("associated_topics") && (
            <Grid item xs={6}>
              <PieChartWidget
                title={`${widgetsTypeTitle}_associated_topics`}
                titleToolTip={
                  isKeywordType
                    ? "posts_associated_topics_ig_tooltip"
                    : "comments_associated_topics_tooltip"
                }
                data={associatedTopics}
                areaStyle={true}
                toolTipLabel={"toolTipLabel"}
                showDownloadIcon
                showAI
                pieChartPreLoaderDataSources={associatedTopicsPreLoader}
                preLoaderTrackerDataSources={[
                  ...metricsValues?.associated_topics?.data_sources,
                ]}
                iconAfterTitle={getIcons([
                  ...metricsValues?.associated_topics?.data_sources,
                ])}
              />
            </Grid>
          )}
        </Grid>
      </Box>
      <Box mt={3}>
        <Grid container spacing={3}>
          {widgetsName?.includes("content_type") && (
            <Grid item xs={6}>
              <PieChartWidget
                title={`${widgetsTypeTitle}_content_type`}
                titleToolTip={"posts_content_type_tw_tooltip"}
                showDownloadIcon={false}
                data={contentType}
                pieChartPreLoaderDataSources={contentTypePreLoader}
                preLoaderTrackerDataSources={[
                  ...metricsValues?.content_type?.data_sources,
                ]}
                iconAfterTitle={getIcons([
                  ...metricsValues?.content_type?.data_sources,
                ])}
              />
            </Grid>
          )}
          {widgetsName?.includes("posts_reach") && (
            <Grid item md={6}>
              <StackedBarWidget
                title={"posts_reach"}
                titleToolTip={"posts_reach_nb_tooltip"}
                bigImg
                posts={reachFunnelLegacy}
                colors={["#64748B"]}
                labels={["original"]}
                xAxisValue
                iconAfterTitle={getIcons([
                  ...metricsValues?.posts_reach?.data_sources,
                ])}
                showDownloadIcon
                showLogChart
                postsColumnPreLoaderDataSources={
                  reachFunnelLegacyPreLoaderDataSources
                }
                preLoaderTrackerDataSources={[
                  ...metricsValues?.posts_reach?.data_sources,
                ]}
              />
            </Grid>
          )}
        </Grid>
      </Box>
      {widgetsName?.includes("top_images") && (
        <Box my={4}>
          <AllTopMedia
            title={CheckValueLocale("dashboard_top_images", "", {}, intl)}
            titleToolTip={
              isKeywordType
                ? "top_images_ig_tooltip"
                : "comments_top_images_tw_tooltip"
            }
            twData={twTopImages}
            igData={igTopImages}
            fbData={fbTopImages}
            errorMessage={CheckValueLocale("no_images_found", "", {}, intl)}
            iconAfterTitle={getIcons([
              ...metricsValues?.top_images?.data_sources,
            ])}
            showDownloadIcon
            hideMoreInsights
            mediaTwPreLoaderDataSources={twTopImagesPreLoader}
            mediaInstaPreLoaderDataSources={igTopImagesPreLoader}
            mediaFbPreLoaderDataSources={fbTopImagesPreLoader}
            preLoaderTrackerDataSources={[
              ...metricsValues?.top_images?.data_sources,
            ]}
            allDatasources={[...metricsValues?.top_images?.data_sources]}
          />
        </Box>
      )}
      {widgetsName?.includes("top_videos") && (
        <Box my={4}>
          <AllTopMedia
            title={CheckValueLocale("dashboard_top_videos", "", {}, intl)}
            titleToolTip={
              isKeywordType
                ? "top_videos_ig_tooltip"
                : "comments_top_videos_tw_tooltip"
            }
            twData={twTopVideos}
            igData={igTopVideos}
            fbData={fbTopVideos}
            errorMessage={CheckValueLocale("no_videos_found", "", {}, intl)}
            iconAfterTitle={getIcons([
              ...metricsValues?.top_videos?.data_sources,
            ])}
            showDownloadIcon
            hideMoreInsights
            mediaTwPreLoaderDataSources={twTopVideosPreLoader}
            mediaInstaPreLoaderDataSources={igTopVideosPreLoader}
            mediaFbPreLoaderDataSources={fbTopVideosPreLoader}
            preLoaderTrackerDataSources={[
              ...metricsValues?.top_videos?.data_sources,
            ]}
            allDatasources={[...metricsValues?.top_videos?.data_sources]}
          />
        </Box>
      )}
      <Box mt={3}>
        <Grid container spacing={3}>
          {widgetsName?.includes("top_engaged_sites") && (
            <Grid item xs={6}>
              <TableWidget
                wordsList
                title={"top_engaged_sites"}
                titleToolTip={"top_engaged_sites_nb_tooltip"}
                data={topEngaged}
                leftColomnTitle={"links"}
                rightColomnTitle={"nb_posts"}
                tableChartPreLoaderDataSources={topEngagedPreLoader}
                preLoaderTrackerDataSources={[
                  ...metricsValues?.top_engaged_sites?.data_sources,
                ]}
                keywords
                tablePagination
                count={topEngaged?.length}
                iconAfterTitle={getIcons([
                  ...metricsValues?.top_engaged_sites?.data_sources,
                ])}
              />
            </Grid>
          )}
          {widgetsName?.includes("top_sites") && (
            <Grid item xs={6}>
              <TableWidget
                wordsList
                title={"top_sites"}
                titleToolTip={"top_sites_nb_tooltip"}
                data={topSites}
                leftColomnTitle={"links"}
                rightColomnTitle={"nb_reach"}
                tableChartPreLoaderDataSources={topSitesPreLoader}
                preLoaderTrackerDataSources={[
                  ...metricsValues?.top_sites?.data_sources,
                ]}
                hashtags
                tablePagination
                count={topSites?.length}
                iconAfterTitle={getIcons([
                  ...metricsValues?.top_sites?.data_sources,
                ])}
              />
            </Grid>
          )}
        </Grid>
      </Box>
      {isKeywordType && widgetsName?.includes("top_posts_comments") && (
        <Box>
          <Box className="top-posts-head">
            <Box>{CheckValueLocale("top_posts", "", {}, intl)}</Box>
            <Link
              to={{
                pathname: `/dashboard/${monitorType}/posts_page/${dashboardID}`,
                state: { dashboardLinkFlag: true },
              }}
              className="top-posts-all"
              id={`check-all-posts-dashboard-${monitorType?.toLowerCase() ?? "monitor-type"}-posts-page-${dashboardID ?? "dashboard-id"}`}
            >
              {CheckValueLocale("check_all_posts", "", {}, intl)} (
              {topPostsCount})
            </Link>
          </Box>
          <AllPosts
            igTopPosts={igTopPosts}
            twTopPosts={twTopPosts}
            fbTopPosts={fbTopPosts}
            nbTopPosts={nbTopPosts}
            insightsID={insightsID}
            response={insightsResponse}
            dates={dates}
            updateAnalysis={props?.updateAnalysis}
            igTopPostsPreLoaderDataSources={igTopPostsPreloader}
            twTopPostsPreLoaderDataSources={twTopPostsPreloader}
            fbTopPostsPreLoaderDataSources={fbTopPostsPreloader}
            nbTopPostsPreLoaderDataSources={nbTopPostsPreloader}
            preLoaderTrackerDataSources={[
              ...metricsValues?.top_posts_comments?.data_sources,
            ]}
            allDatasources={[
              ...metricsValues?.top_posts_comments?.data_sources,
            ]}
            isPostsTabs
            singleTweetAnalysis={singleTweetAnalysis}
          />
        </Box>
      )}
    </>
  );
};

export default OverViewSection;
