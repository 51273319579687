import React from "react";
import { useFormik } from "formik";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import {
  IconButton,
  OutlinedInput,
  InputAdornment,
  FormControl,
  FormHelperText,
  Box,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { searchAccountsListsValidationSchema } from "../accountsListsValidation/searchAccountsListsValidationSchema";

const ListSearchFilter = ({ handelTextSearch, searchText, setSearchText }) => {
  const intl = useIntl();

  const formik = useFormik({
    initialValues: {
      search: searchText || "",
    },
    validationSchema: searchAccountsListsValidationSchema(),
    onSubmit: () => {
      // Update parent state
      handelTextSearch(); // Trigger the search function
    },
  });

  return (
    <form onSubmit={formik?.handleSubmit}>
      <FormControl
        variant="outlined"
        className="accounts-list-list-search-field"
        error={!!formik?.errors?.search && formik?.touched?.search}
      >
        <OutlinedInput
          type="text"
          name="search"
          value={formik?.values?.search}
          onChange={(e) => {
            setSearchText(e?.target?.value);
            formik?.handleChange(e);
          }}
          onBlur={formik?.handleBlur}
          endAdornment={
            <InputAdornment position="end">
              <IconButton edge="end" type="submit">
                <SearchIcon id="accounts-list-new-search-icon" />
              </IconButton>
            </InputAdornment>
          }
          placeholder={CheckValueLocale(
            "account_lists_search_by_list_name",
            "",
            {},
            intl,
          )}
          id="accounts-list-new-search-text"
        />
        <Box className="accounts-list-search-error-msg-container">
          {formik?.errors?.search && formik?.touched?.search && (
            <FormHelperText classeName="accounts-list-search-error-msg">
              {formik?.errors?.search}
            </FormHelperText>
          )}
        </Box>
      </FormControl>
    </form>
  );
};

export default ListSearchFilter;
