import { Box } from "@mui/material";
import "./mainEdit.scss";
import { useEffect } from "react";
import { CheckValueLocale } from "../../../../../utils/helpers/index.js";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import facebook from "images/engagements-images/facebook.svg";
import Instagram from "images/engagements-images/Instagram.svg";
import xPlatform from "images/social-icons/x-platform-icon.svg";
import facebookCxm from "images/engagements-images/fb-dm-eng.svg";
import InstagramCxm from "images/engagements-images/ig-dm-eng.svg";
import twitterCxm from "images/engagements-images/xp-dm-eng.svg";
import WhatsAppCXM from "images/social-icons/whatsapp-icon.svg";

const socialIcons = (type) => {
  let icons = {
    FACEBOOK: <img src={facebook} />,
    INSTAGRAM: <img src={Instagram} />,
    TWITTER: <img src={xPlatform} alt="x-platform-logo" />,
    FACEBOOK_cxm: <img src={facebookCxm} />,
    INSTAGRAM_cxm: <img src={InstagramCxm} />,
    TWITTER_cxm: <img src={twitterCxm} alt="x-platform-dm-logo" />,
    WHATSAPP_cxm: <img src={WhatsAppCXM} />,
    WHATSAPP: <img src={WhatsAppCXM} />,
  };
  return icons[type];
};
// shared select card and handle selection
const MainSelsctCard = ({
  type,
  isAcc,
  isborder,
  selectedData,
  setSelectedData,
  selectId,
  companyAuthId,
}) => {
  const intl = useIntl();

  //start of check if channels include public
  useEffect(() => {
    if (selectedData?.stepOne?.channels?.length) {
      let ispublic = false;
      selectedData?.stepOne?.channels?.map((item1) => {
        selectedData?.mainData?.channels?.map((item2) => {
          if (item2?.attributes?.id == item1) {
            if (!item2?.attributes?.is_dm) {
              ispublic = true;
            }
          }
        });
      });
      if (ispublic) {
        setSelectedData({
          ...selectedData,
          stepOne: {
            ...selectedData?.stepOne,
            isCxmAcc: true,
          },
        });
      } else {
        setSelectedData({
          ...selectedData,
          stepOne: {
            ...selectedData?.stepOne,
            isCxmAcc: false,
            TWITTER_CxmAcc: [],
            INSTAGRAM_CxmAcc: [],
            FACEBOOK_CxmAcc: [],
          },
        });
      }
    } else {
      setSelectedData({
        ...selectedData,
        stepOne: {
          ...selectedData?.stepOne,
          isCxmAcc: false,
          TWITTER_CxmAcc: [],
          INSTAGRAM_CxmAcc: [],
          FACEBOOK_CxmAcc: [],
        },
      });
    }
  }, [selectedData?.stepOne?.channels]);
  //end of check if channels include public
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value[0] == "empty") {
      value.splice(0, 1);
    }
    if (isAcc) {
      let filteredArr = value?.filter(
        (item) =>
          value[value?.length - 1]?.attributes?.id == item?.attributes?.id &&
          value[value?.length - 1]?.attributes?.data_source ==
            item?.attributes?.data_source,
      );
      if (filteredArr?.length > 1) {
        let arr = selectedData?.stepOne[
          `${filteredArr[0]?.attributes?.data_source}_${type}`
        ].filter(
          (item) => filteredArr[0]?.attributes?.id != item?.attributes?.id,
        );
        setSelectedData({
          ...selectedData,
          stepOne: {
            ...selectedData?.stepOne,
            [`${filteredArr[0]?.attributes?.data_source}_${type}`]: [...arr],
          },
        });
      } else {
        let arr = [
          ...selectedData?.stepOne[
            `${value[value?.length - 1]?.attributes?.data_source}_${type}`
          ],
          { ...value[value?.length - 1] },
        ];
        setSelectedData({
          ...selectedData,
          stepOne: {
            ...selectedData?.stepOne,
            [`${value[value?.length - 1]?.attributes?.data_source}_${type}`]: [
              ...arr,
            ],
          },
        });
      }
    } else {
      setSelectedData({
        ...selectedData,
        stepOne: {
          ...selectedData?.stepOne,
          [type]: [...value],
        },
      });
    }
  };
  return (
    <Box className={isborder ? "main-select-card border" : "main-select-card"}>
      <Box className="select-card-header">
        <Box>{CheckValueLocale(`${type}_header`, "", {}, intl)}</Box>
        <Box className="main-select-pragrf">
          {CheckValueLocale(`${type}_prag`, "", {}, intl)}
          {type == "CxmAcc" ? (
            <Box className="cxm-prag2">
              {CheckValueLocale("CxmAcc_prag2", "", {}, intl)}
            </Box>
          ) : null}
        </Box>
      </Box>
      <Box className={`select-card-input ${type}`}>
        {selectedData?.mainData[isAcc ? "accounts" : type]?.length ? (
          <Box>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="demo-multiple-checkbox-label">
                {CheckValueLocale(
                  isAcc ? "acc_label" : `${type}_label`,
                  "",
                  {},
                  intl,
                )}
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id={selectId ? selectId : "demo-multiple-checkbox"}
                multiple
                value={
                  isAcc
                    ? [
                        ...selectedData?.stepOne[`TWITTER_${type}`],
                        ...selectedData?.stepOne[`INSTAGRAM_${type}`],
                        ...selectedData?.stepOne[`FACEBOOK_${type}`],
                      ]?.length
                      ? [
                          ...selectedData?.stepOne[`TWITTER_${type}`],
                          ...selectedData?.stepOne[`INSTAGRAM_${type}`],
                          ...selectedData?.stepOne[`FACEBOOK_${type}`],
                        ]
                      : ["empty"]
                    : selectedData?.stepOne[type]?.length
                      ? selectedData?.stepOne[type]
                      : ["empty"]
                }
                onChange={handleChange}
                input={<OutlinedInput label="Tag" />}
                disabled={
                  !selectedData?.mainData[isAcc ? "accounts" : type]?.length
                }
                renderValue={() =>
                  CheckValueLocale(
                    "num_select",
                    "",
                    {
                      num: isAcc
                        ? [
                            ...selectedData?.stepOne[`TWITTER_${type}`],
                            ...selectedData?.stepOne[`INSTAGRAM_${type}`],
                            ...selectedData?.stepOne[`FACEBOOK_${type}`],
                          ]?.length
                        : selectedData?.stepOne[type]?.length,
                    },
                    intl,
                  )
                }
              >
                {selectedData?.mainData[isAcc ? "accounts" : type]?.map(
                  (item, i) => (
                    <MenuItem
                      key={`${item?.attributes?.id}-${i}`}
                      value={isAcc ? item : item?.attributes?.id}
                      id="main-select-li-item"
                      className={
                        isAcc &&
                        [
                          ...selectedData?.stepOne[`TWITTER_${type}`],
                          ...selectedData?.stepOne[`INSTAGRAM_${type}`],
                          ...selectedData?.stepOne[`FACEBOOK_${type}`],
                        ]?.filter(
                          (filterd) =>
                            filterd?.attributes?.id == item?.attributes?.id &&
                            filterd?.attributes?.data_source ==
                              item?.attributes?.data_source,
                        )?.length > 0
                          ? "Mui-selected"
                          : ""
                      }
                    >
                      <Checkbox
                        checked={
                          isAcc
                            ? [
                                ...selectedData?.stepOne[`TWITTER_${type}`],
                                ...selectedData?.stepOne[`INSTAGRAM_${type}`],
                                ...selectedData?.stepOne[`FACEBOOK_${type}`],
                              ]?.filter(
                                (filterd) =>
                                  filterd?.attributes?.id ==
                                    item?.attributes?.id &&
                                  filterd?.attributes?.data_source ==
                                    item?.attributes?.data_source,
                              )?.length > 0
                            : selectedData?.stepOne[type]?.indexOf(
                                item?.attributes?.id,
                              ) > -1
                        }
                      />
                      <ListItemText primary={item?.attributes?.name} />
                      <Box className="main-select-icons">
                        {item?.attributes?.data_sources?.map((type) =>
                          socialIcons(
                            item?.attributes?.is_dm ? `${type}_cxm` : type,
                          ),
                        )}
                      </Box>
                      {type == "monitors" ? (
                        <Box className="main-select-type">
                          {CheckValueLocale(
                            item?.attributes?.monitor_type_name ==
                              "MANAGED_PAGE"
                              ? "MANAGED_ACCOUNT"
                              : item?.attributes?.monitor_type_name,
                            "",
                            {},
                            intl,
                          )}
                        </Box>
                      ) : null}
                      <Box
                        className={
                          item?.attributes?.paused ||
                          (isAcc && item?.attributes?.status != "active")
                            ? isAcc
                              ? "monitor-state paused-ai"
                              : "monitor-state paused"
                            : "monitor-state collecting"
                        }
                      >
                        [
                        {CheckValueLocale(
                          item?.attributes?.paused ||
                            (isAcc && item?.attributes?.status != "active")
                            ? isAcc
                              ? "re_auth"
                              : "paused"
                            : isAcc
                              ? "active_status"
                              : "collecting",
                          "",
                          {},
                          intl,
                        )}
                        ]
                      </Box>
                    </MenuItem>
                  ),
                )}
              </Select>
            </FormControl>
          </Box>
        ) : null}
        {isAcc ? (
          <Box className="cards-link-auth">
            <Box>
              {CheckValueLocale(
                !selectedData?.mainData[isAcc ? "accounts" : type]?.length
                  ? "empty_acc"
                  : "new_acc",
                "",
                {},
                intl,
              )}
            </Box>
            <Link
              target="_blank"
              to="/settings"
              id={companyAuthId}
              className="link-auth"
            >
              <Box className="link-text">
                {CheckValueLocale("company_auth", "", {}, intl)}
              </Box>
              <Box>
                <FontAwesomeIcon icon={faExternalLink} />
              </Box>
            </Link>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default MainSelsctCard;
