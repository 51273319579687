import axios from "axios";
import Filters from "../api/filters";

var FiltersController = {};
let getMonitorFiltersFiledsCancelToken,
getAllSavedFiltersCancelToken;

FiltersController.getSocialMediaFiltersFileds = (
  product_id,
  data_source_id,
) => {
  var queryData = {
    product_id,
    data_source_id,
  };
  return new Promise(function (resolve) {
    Filters.getSocialMediaFiltersFileds(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
FiltersController.getFacebookFiltersFileds = (
  product_id,
  data_source_id,
  channel_type,
) => {
  var queryData = {
    product_id,
    data_source_id,
    channel_type,
  };
  return new Promise(function (resolve) {
    Filters.getFacebookFiltersFileds(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
FiltersController.getTwitterFiltersFileds = (
  product_id,
  data_source_id,
  channel_type,
) => {
  var queryData = {
    product_id,
    data_source_id,
    channel_type,
  };
  return new Promise(function (resolve) {
    Filters.getTwitterFiltersFileds(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
FiltersController.getTiktokFiltersFileds = (
  product_id,
  data_source_id,
  channel_type,
) => {
  var queryData = {
    product_id,
    data_source_id,
    channel_type,
  };
  return new Promise(function (resolve) {
    Filters.getTiktokFiltersFileds(queryData)
      .then((response) => {
        resolve({
          data: response?.data?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
FiltersController.getInstagramFiltersFileds = (
  product_id,
  data_source_id,
  channel_type,
) => {
  var queryData = {
    product_id,
    data_source_id,
    channel_type,
  };
  return new Promise(function (resolve) {
    Filters.getInstagramFiltersFileds(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
FiltersController.getLinkedInFiltersFileds = (
  product_id,
  data_source_id,
  channel_type,
) => {
  var queryData = {
    product_id,
    data_source_id,
    channel_type,
  };
  return new Promise(function (resolve) {
    Filters.getLinkedInFiltersFileds(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
FiltersController.getInsightsFiltersFileds = (product_id) => {
  var queryData = {
    product_id,
  };
  return new Promise(function (resolve) {
    Filters.getInsightsFiltersFileds(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

FiltersController.getInteractionsFiltersFileds = (product_id) => {
  var queryData = {
    product_id,
  };
  return new Promise(function (resolve) {
    Filters.getInteractionsFiltersFileds(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

//chat filters
// Chat Interactions filters
FiltersController.getChatInteractionsFiltersFileds = (product_id) => {
  var queryData = {
    product_id,
  };
  return new Promise(function (resolve) {
    Filters.getChatInteractionsFiltersFileds(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

FiltersController.getChatFiltersFileds = (product_id) => {
  var queryData = {
    product_id,
  };
  return new Promise(function (resolve) {
    Filters.getChatFiltersFileds(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

//Intercom filters
FiltersController.getIntercomFiltersFileds = (product_id, data_source_id) => {
  var queryData = {
    product_id,
    data_source_id,
  };
  return new Promise(function (resolve) {
    Filters.getIntercomFiltersFileds(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

//Facebook Interactions filters
FiltersController.getFacebookInteractionsFiltersFileds = (
  product_id,
  data_source_id,
  channel_type,
) => {
  var queryData = {
    product_id,
    data_source_id,
    channel_type,
  };
  return new Promise(function (resolve) {
    Filters.getFacebookInteractionsFiltersFileds(queryData)
      .then((response) => {
        resolve({
          data: response?.data?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
//Instagram Interactions filters
FiltersController.getInstagramInteractionsFiltersFileds = (
  product_id,
  data_source_id,
  channel_type,
) => {
  var queryData = {
    product_id,
    data_source_id,
    channel_type,
  };
  return new Promise(function (resolve) {
    Filters.getInstagramInteractionsFiltersFileds(queryData)
      .then((response) => {
        resolve({
          data: response?.data?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
// Social Interactions filters
FiltersController.getSocialInteractionsFiltersFileds = (product_id) => {
  var queryData = {
    product_id,
  };
  return new Promise(function (resolve) {
    Filters.getSocialInteractionsFiltersFileds(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
FiltersController.getTiktokInteractionsFiltersFileds = (
  product_id,
  data_source_id,
) => {
  var queryData = {
    product_id,
    data_source_id,
  };
  return new Promise(function (resolve) {
    Filters.getTiktokInteractionsFiltersFileds(queryData)
      .then((response) => {
        resolve({
          data: response?.data?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
//Twitter Interactions filters
FiltersController.getTwitterInteractionsFiltersFileds = (
  product_id,
  data_source_id,
  channel_type,
) => {
  var queryData = {
    product_id,
    data_source_id,
    channel_type,
  };
  return new Promise(function (resolve) {
    Filters.getTwitterInteractionsFiltersFileds(queryData)
      .then((response) => {
        resolve({
          data: response?.data?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
//Gmail Interactions filters
FiltersController.getGmailInteractionsFiltersFileds = (
  product_id,
  data_source_id,
) => {
  var queryData = {
    product_id,
    data_source_id,
  };
  return new Promise(function (resolve) {
    Filters.getGmailInteractionsFiltersFileds(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

//whatsApp filters
FiltersController.getWhatsappFiltersFileds = (product_id, data_source_id) => {
  var queryData = {
    product_id,
    data_source_id,
  };
  return new Promise(function (resolve) {
    Filters.getWhatsappFiltersFileds(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

FiltersController.getWhatsFiltersFileds = (product_id, data_source_id) => {
  var queryData = {
    product_id,
    data_source_id,
  };
  return new Promise(function (resolve) {
    Filters.getWhatsFiltersFileds(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

FiltersController.getIntercomInteractionsFiltersFileds = (
  product_id,
  data_source_id,
) => {
  var queryData = {
    product_id,
    data_source_id,
  };
  return new Promise(function (resolve) {
    Filters.getIntercomInteractionsFiltersFileds(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

//email & gmail filters
FiltersController.getEmailFiltersFileds = (product_id) => {
  var queryData = {
    product_id,
  };
  return new Promise(function (resolve) {
    Filters.getEmailFiltersFileds(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
FiltersController.getGmailFiltersFileds = (product_id, data_source_id) => {
  var queryData = {
    product_id,
    data_source_id,
  };
  return new Promise(function (resolve) {
    Filters.getGmailFiltersFileds(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

FiltersController.getGmbFiltersFields = (product_id, data_source_id) => {
  var queryData = {
    product_id,
    data_source_id,
  };
  return new Promise(function (resolve) {
    Filters.getGmbFiltersFields(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

FiltersController.getGmbInteractionsFiltersFields = (
  product_id,
  data_source_id,
) => {
  var queryData = {
    product_id,
    data_source_id,
  };
  return new Promise(function (resolve) {
    Filters.getGmbInteractionsFiltersFields(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

FiltersController.getLinkedInInteractionsFiltersFields = (
  product_id,
  data_source_id,
) => {
  var queryData = {
    product_id,
    data_source_id,
  };
  return new Promise(function (resolve) {
    Filters.getLinkedInInteractionsFiltersFields(queryData)
      .then((response) => {
        resolve({
          data: response?.data?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

FiltersController.getMonitorFiltersFileds = (
  section,
  monitor_id,
  source,
  product_id,
  monitor_type,
) => {
  var queryData = {
    section,
    monitor_id,
    product_id,
    monitor_type,
  };
  if (getMonitorFiltersFiledsCancelToken) {
    getMonitorFiltersFiledsCancelToken.cancel(
      "Operation canceled due to a new request.",
    );
  }
  getMonitorFiltersFiledsCancelToken = axios.CancelToken.source();

  return new Promise(function (resolve) {
    Filters.getMonitorFiltersFileds(
      queryData, 
      source,
      getMonitorFiltersFiledsCancelToken?.token
    )
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
FiltersController.getAllSavedFilters = (
  monitor_id,
  product_id,
  data_source_id,
  section_name,
) => {
  var queryData = {
    monitor_id,
    product_id,
    data_source_id,
    section_name,
  };

  if (getAllSavedFiltersCancelToken) {
    getAllSavedFiltersCancelToken.cancel(
      "Operation canceled due to a new request.",
    );
  }
  getAllSavedFiltersCancelToken = axios.CancelToken.source();

  return new Promise(function (resolve) {
    Filters.getAllSavedFilters(
      queryData, getAllSavedFiltersCancelToken?.token
    )
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

FiltersController.getAllSavedFiltersRedux = (monitor_id, product_id) => {
  var queryData = {
    monitor_id,
    product_id,
  };

  return new Promise(function (resolve) {
    Filters.getAllSavedFiltersRedux(queryData)
      .then((response) => {
        resolve({
          data: response?.data?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

FiltersController.createSavedFilter = (
  monitor_id,
  product_id,
  section_name,
  filter_name,
  filter_params,
  data_source_id,
  startDate,
  endDate,
) => {
  let queryData = {
    monitor_id,
    product_id,
    section_name,
    filter_name,
    filter_params,
    data_source_id,
    startDate,
    endDate,
  };
  return new Promise(function (resolve) {
    Filters.createSavedFilter(queryData)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: error.response,
        });
      });
  });
};

FiltersController.getSavedFilterData = (
  saved_filter_id,
  monitor_id,
  product_id,
  data_source_id,
) => {
  var queryData = {
    monitor_id,
    product_id,
    data_source_id,
  };

  return new Promise(function (resolve) {
    Filters.getSavedFilterData(queryData, saved_filter_id)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

FiltersController.deleteSavedFilterData = (
  saved_filter_id,
  monitor_id,
  product_id,
  data_source_id,
) => {
  var queryData = {
    monitor_id,
    product_id,
    data_source_id,
  };
  return new Promise((resolve) => {
    Filters.deleteSavedFilterData(queryData, saved_filter_id)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: error.response.status,
        });
      });
  });
};

FiltersController.getAudienceFiltersFileds = (section, product_id) => {
  var queryData = {
    section,
    product_id,
  };

  return new Promise(function (resolve) {
    Filters.getAudienceFiltersFileds(queryData)
      .then((response) => {
        resolve({
          data: response?.data?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

FiltersController.getGenesysFiltersFields = (product_id, data_source_id) => {
  var queryData = {
    product_id,
    data_source_id,
    channel_type: 1,
  };
  return new Promise(function (resolve) {
    Filters.getGenesysFiltersFields(queryData)
      .then((response) => {
        resolve({
          data: response?.data?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

FiltersController.getEngagementAnalyticsFiltersFields = (
  product_id,
  section,
) => {
  var queryData = {
    product_id,
    section,
  };
  return new Promise(function (resolve) {
    Filters.getEngagementAnalyticsFiltersFields(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
FiltersController.getBenchmarkFiltersFileds = (
  monitor_list,
  product_id,
  monitor_type,
) => {
  var queryData = {
    monitor_list,
    product_id,
    monitor_type,
  };

  if (monitor_list !== "" && monitor_list !== undefined) {
    queryData.monitor_list = JSON.stringify(monitor_list);
  }
  if (monitor_type !== "" && monitor_type !== undefined) {
    queryData.monitor_type = JSON.stringify(monitor_type);
  }
  return new Promise(function (resolve) {
    Filters.getBenchmarkFiltersFileds(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

export default FiltersController;
