// Start Functions for Sourcse URLs in News & Blogs Keywords

import { isEmojis } from "./specialCharOrEmojis";

// For Not Allowed Special Characters and Emojis
export const isNotValidExcludedSource = (source) => {
  let result =
    source.search(/[§±!^\#\@\$%&\*\(\)\+=\[\]{};\\\|~<>\?,؛؟، ]/g) !== -1 ||
    isEmojis(source);

  return result;
};
// For Displaying error messages for unallowed Source & label lengths in URL Domain
export const isValidSourceLengthMessage = (URL) => {
  let source = URL,
    labels = [],
    exceedLabelsCount = false,
    validURLErrorMsg = "";

  if (source.includes("http://") || source.includes("https://")) {
    source = source.replace("http://", "");
    source = source.replace("https://", "");
  }

  labels = source.split(".");
  labels.map((l) => {
    if (l.length > 63) {
      exceedLabelsCount = true;
    }
  });

  if (exceedLabelsCount) {
    validURLErrorMsg = "invalid_source_label_length";
  } else if (source.length > 265) {
    validURLErrorMsg = "invalid_source_url_length";
  } else {
    validURLErrorMsg = "";
  }

  return validURLErrorMsg;
};
// to remove quotes and  last slash before sending URLs
export const changeSourceURLToValidFormat = (URL) => {
  let newURL = URL,
    includeHttp = newURL.includes("http://") || newURL.includes("https://");

  // check number of labels
  let labels = [],
    domainLabel = "";
  labels = URL.split(".");

  labels.map((l, index) => {
    if (index === 2) {
      domainLabel = l;
    }
  });
  let noOfSlashesAfterDomain = domainLabel.split("/").length;

  if (newURL.includes('"') || newURL.includes("'") || newURL.includes("“")) {
    if (!includeHttp && newURL.charAt(newURL.length - 2).includes("/")) {
      newURL = newURL.replace(/[\"\'\“/]/g, "");
    } else {
      newURL = newURL.replace(/[\"\'\“]/g, "");
    }
  }

  if (!includeHttp) {
    if (newURL.includes("/")) {
      let slashIndex = newURL.indexOf("/");
      newURL = newURL.substring(0, slashIndex);
    } else {
      newURL = newURL.replace(/[/]/g, "");
    }
  } else if (
    includeHttp &&
    domainLabel !== "" &&
    noOfSlashesAfterDomain === 2 &&
    domainLabel.includes("/")
  ) {
    let slashIndex = newURL.lastIndexOf("/");
    newURL = newURL.substring(0, slashIndex);
  } else if (
    includeHttp &&
    domainLabel !== "" &&
    noOfSlashesAfterDomain > 2 &&
    domainLabel.includes("/")
  ) {
    let i = domainLabel.indexOf("/");
    newURL = newURL.substring(0, i);
  } else if (includeHttp && newURL[newURL.length - 1].includes("/")) {
    newURL = newURL.slice(0, -1);
  } else {
    // eslint-disable-next-line no-self-assign
    newURL = newURL;
  }

  return newURL;
};

export const changeNBSourceURLToValidFormat = (URL) => {
  let newURL = URL?.trim();

  // Remove any surrounding quotes
  newURL = newURL?.replace(/['"“”]/g, "");

  // Ensure the URL starts with http:// or https://
  if (!newURL?.startsWith("http://") && !newURL?.startsWith("https://")) {
    newURL = "http://" + newURL;
  }

  // Remove trailing slash if present
  if (newURL?.endsWith("/")) {
    newURL = newURL.slice(0, -1);
  }

  return newURL;
};

// To check if the URL is Valid or Not
export const isValidURL = (str) => {
  var pattern = new RegExp(
    "^((ft|htt)ps?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|(/)?|" + // domain name and extension
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?" + // port
      "(\\/[-a-z\\d%@_.~+&:]*)*" + // path
      "(\\?[;&a-z\\d%@_.,~+&:=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i",
  );

  return pattern.test(str);
};
