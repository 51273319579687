import { Box } from "@mui/material";
import "./mainEdit.scss";
import { useEffect, useState } from "react";
import { CheckValueLocale } from "../../../../../utils/helpers/index.js";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import EngagementsController from "services/controllers/engagementsController";
import LucButton from "shared/lucButton/lucButton";
import { useEngagementPermissions } from "hooks/useEngagementPermissions";
import {
  countChangesInFarewellAndGreetingMessages,
  countChangesInOutsideWorkingHours,
  transformMessageDataPayload,
} from "../utils/farewellGreetingUtils";
import {
  automatedReplyDataNotCompleted,
  countChangesInAutomatedPublicReplies,
  getAutomatedReplyEmptyFields,
  transformAutomatedPublicRepliesPayload,
} from "../utils/publicRepliesUtils";
import { surveyValidation } from "./egagementSurvey/surveyValidation";
import _ from "lodash";

const MainEditFooter = ({
  setSnackBar,
  initialselectedData,
  setInitialselectedData,
  selectedData,
  messageData,
  aiAgentActive,
  automatedPublicRepliesData,
  setAutoReplyErrorFields,
  setAutoReplySelectedTab,
  autoReplySelectedTab,
  surveyData,
  setSurveyData,
  isSurveyFeaching,
  initialSurveySelectedData,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const { engagementPriorityProductPath } = useEngagementPermissions();
  //start of number of changes
  let areNotSame = (x, y, isObject, isObjectFlatend = false) => {
    var objectsAreNotSame = false;
    if (x?.length == y?.length) {
      for (var i in x) {
        let isExit = false;
        for (var item in y) {
          if (isObject) {
            if (
              y[item]?.attributes?.id == x[i]?.attributes?.id &&
              y[item]?.attributes?.data_source == x[i]?.attributes?.data_source
            ) {
              isExit = true;
              break;
            }
          } else if (isObjectFlatend) {
            if (
              y[item]?.id == x[i]?.id &&
              y[item]?.reply_text == x[i]?.reply_text
            ) {
              isExit = true;
              break;
            }
          } else {
            if (y[item] == x[i]) {
              isExit = true;
              break;
            }
          }
        }
        if (!isExit) {
          objectsAreNotSame = true;
          break;
        }
      }
    } else {
      objectsAreNotSame = true;
    }

    return objectsAreNotSame;
  };

  useEffect(() => {
    let numberOfChanged = 0;
    if (!selectedData?.mainData?.feaching) {
      if (selectedData?.stepTwo?.isFileChanged) {
        ++numberOfChanged;
      }
      if (
        selectedData?.stepTwo?.aboutYour !==
        initialselectedData?.stepTwo?.aboutYour
      ) {
        ++numberOfChanged;
      }
      if (
        selectedData?.stepTwo?.brand_tone !==
        initialselectedData?.stepTwo?.brand_tone
      ) {
        ++numberOfChanged;
      }
      if (
        selectedData?.stepTwo?.enabled !== initialselectedData?.stepTwo?.enabled
      ) {
        ++numberOfChanged;
      }
      if (
        selectedData?.stepTwo?.luci_business_hq !==
        initialselectedData?.stepTwo?.luci_business_hq
      ) {
        ++numberOfChanged;
      }
      if (
        selectedData?.stepTwo?.luci_business_type !==
        initialselectedData?.stepTwo?.luci_business_type
      ) {
        ++numberOfChanged;
      }
      if (
        selectedData?.stepTwo?.luci_industry !==
        initialselectedData?.stepTwo?.luci_industry
      ) {
        ++numberOfChanged;
      }
      if (
        areNotSame(
          [
            ...initialselectedData?.stepOne[`TWITTER_SlAcc`],
            ...initialselectedData?.stepOne[`INSTAGRAM_SlAcc`],
            ...initialselectedData?.stepOne[`FACEBOOK_SlAcc`],
          ],
          [
            ...selectedData?.stepOne[`TWITTER_SlAcc`],
            ...selectedData?.stepOne[`INSTAGRAM_SlAcc`],
            ...selectedData?.stepOne[`FACEBOOK_SlAcc`],
          ],
          true,
        )
      ) {
        ++numberOfChanged;
      }
      if (
        areNotSame(
          [
            ...initialselectedData?.stepOne[`TWITTER_PubAcc`],
            ...initialselectedData?.stepOne[`INSTAGRAM_PubAcc`],
            ...initialselectedData?.stepOne[`FACEBOOK_PubAcc`],
          ],
          [
            ...selectedData?.stepOne[`TWITTER_PubAcc`],
            ...selectedData?.stepOne[`INSTAGRAM_PubAcc`],
            ...selectedData?.stepOne[`FACEBOOK_PubAcc`],
          ],
          true,
        )
      ) {
        ++numberOfChanged;
      }
      if (
        areNotSame(
          [
            ...initialselectedData?.stepOne[`TWITTER_CxmAcc`],
            ...initialselectedData?.stepOne[`INSTAGRAM_CxmAcc`],
            ...initialselectedData?.stepOne[`FACEBOOK_CxmAcc`],
          ],
          [
            ...selectedData?.stepOne[`TWITTER_CxmAcc`],
            ...selectedData?.stepOne[`INSTAGRAM_CxmAcc`],
            ...selectedData?.stepOne[`FACEBOOK_CxmAcc`],
          ],
          true,
        )
      ) {
        ++numberOfChanged;
      }
      if (
        areNotSame(
          initialselectedData?.stepOne[`channels`],
          selectedData?.stepOne[`channels`],
        )
      ) {
        ++numberOfChanged;
      }
      if (
        areNotSame(
          initialselectedData?.stepOne[`monitors`],
          selectedData?.stepOne[`monitors`],
        )
      ) {
        ++numberOfChanged;
      }
      setInitialselectedData({
        ...initialselectedData,
        changedNum: numberOfChanged,
      });
    }
    if (!aiAgentActive) {
      const changesAutomated = countChangesInAutomatedPublicReplies(
        { ...initialselectedData?.initialAutomatedPublicReplies },
        automatedPublicRepliesData,
        areNotSame,
      );
      const changes = countChangesInFarewellAndGreetingMessages(
        initialselectedData,
        messageData,
      );
      const outsideHoursChanges = countChangesInOutsideWorkingHours(
        initialselectedData?.engagements_outside_hours_settings,
        messageData?.engagements_outside_hours_settings,
      );
      numberOfChanged =
        numberOfChanged + changes + changesAutomated + outsideHoursChanges;
      setInitialselectedData({
        ...initialselectedData,
        changedNum:
          numberOfChanged + changes + changesAutomated + outsideHoursChanges,
      });
    }
    if (!isSurveyFeaching) {
      let surveySelectedData = surveyData?.selectedData;
      Object.keys(surveySelectedData)?.map((dataSorce) => {
        Object.keys(surveySelectedData?.[dataSorce]).map((feild) => {
          if (
            surveySelectedData?.[dataSorce]?.[feild] !=
            initialSurveySelectedData?.[dataSorce]?.[feild]
          ) {
            ++numberOfChanged;
          }
        });
      });
      setInitialselectedData({
        ...initialselectedData,
        changedNum: numberOfChanged,
      });
    }
  }, [
    selectedData,
    messageData,
    automatedPublicRepliesData,
    isSurveyFeaching,
    surveyData,
  ]);
  //end of number of changes

  //start of check if errors or send data
  const sendAllData = () => {
    let { surveyDataSorceLimitError, updatedSurveyData } = surveyValidation(
      _.cloneDeep({ ...surveyData }),
    );

    if (
      !selectedData?.stepOne?.monitors?.length &&
      !selectedData?.stepOne?.channels?.length
    ) {
      setSnackBar({
        open: true,
        title: "un_save",
        message: "no_monitors_an_ch_msg",
        severity: "error",
      });
    } else if (
      selectedData?.stepOne?.monitors?.length &&
      !selectedData?.stepOne?.INSTAGRAM_SlAcc?.length &&
      !selectedData?.stepOne?.TWITTER_SlAcc?.length &&
      !selectedData?.stepOne?.FACEBOOK_SlAcc?.length
    ) {
      setSnackBar({
        open: true,
        title: "un_save",
        message: "no_sm_acc",
        severity: "error",
      });
    } else if (
      selectedData?.stepOne?.isCxmAcc &&
      !selectedData?.stepOne?.INSTAGRAM_CxmAcc?.length &&
      !selectedData?.stepOne?.TWITTER_CxmAcc?.length &&
      !selectedData?.stepOne?.FACEBOOK_CxmAcc?.length
    ) {
      setSnackBar({
        open: true,
        title: "un_save",
        message: "no_cxm_acc",
        severity: "error",
      });
    } else if (
      !selectedData?.stepOne?.INSTAGRAM_PubAcc?.length &&
      !selectedData?.stepOne?.TWITTER_PubAcc?.length &&
      !selectedData?.stepOne?.FACEBOOK_PubAcc?.length
    ) {
      setSnackBar({
        open: true,
        title: "un_save",
        message: "no_pub_acc",
        severity: "error",
      });
    } else if (
      !selectedData?.stepTwo?.luci_industry?.length ||
      !selectedData?.stepTwo?.luci_business_type?.length ||
      !selectedData?.stepTwo?.luci_business_hq?.length ||
      !selectedData?.stepTwo?.brand_tone?.length ||
      !selectedData?.stepTwo?.aboutYour?.length ||
      !selectedData?.stepTwo?.knowledge_base_url?.length
    ) {
      if (
        (!selectedData?.stepTwo?.luci_industry?.length ||
          !selectedData?.stepTwo?.luci_business_type?.length ||
          !selectedData?.stepTwo?.luci_business_hq?.length ||
          !selectedData?.stepTwo?.brand_tone?.length ||
          !selectedData?.stepTwo?.aboutYour?.length) &&
        !selectedData?.stepTwo?.knowledge_base_url?.length
      ) {
        setSnackBar({
          open: true,
          title: "ai_tit_err",
          message: "ai_all_fields_msg",
          severity: "error",
        });
      } else if (
        !selectedData?.stepTwo?.luci_industry?.length ||
        !selectedData?.stepTwo?.luci_business_type?.length ||
        !selectedData?.stepTwo?.luci_business_hq?.length ||
        !selectedData?.stepTwo?.brand_tone?.length ||
        !selectedData?.stepTwo?.aboutYour?.length
      ) {
        setSnackBar({
          open: true,
          title: "ai_tit_err",
          message: "ai_inputs_msg",
          severity: "error",
        });
      } else {
        setSnackBar({
          open: true,
          title: "ai_tit_err",
          message: "ai_file_msg",
          severity: "error",
        });
      }
    } else if (surveyDataSorceLimitError?.length) {
      setSurveyData(
        _.cloneDeep({ ...surveyData, limit: surveyDataSorceLimitError }),
      );
      setSnackBar({
        open: true,
        title: "",
        message: "survey_engag_limit_error",
        severity: "error",
      });
    } else {
      const transformedMessagesPayload = !aiAgentActive
        ? transformMessageDataPayload(messageData)
        : {};
      const transformedAutomatedPublicRepliesPayload = !aiAgentActive
        ? transformAutomatedPublicRepliesPayload(automatedPublicRepliesData)
        : {};

      if (!aiAgentActive) {
        // in auto public replies check on monitors and reply by selects if they are empty
        if (
          automatedReplyDataNotCompleted(
            transformedAutomatedPublicRepliesPayload,
          )
        ) {
          const errorFields = getAutomatedReplyEmptyFields(
            transformedAutomatedPublicRepliesPayload,
          );
          setAutoReplyErrorFields(errorFields);
          if (
            !errorFields?.hasOwnProperty(autoReplySelectedTab.toUpperCase())
          ) {
            setAutoReplySelectedTab(Object.keys(errorFields)[0]?.toLowerCase());
            // if the error is not in the current tab
            // then navigate to it
          }

          return; // stop executing the function and prevent sending the data to the Be
        }
      }

      let data = {
        ...selectedData,
        stepOne: {
          ...selectedData?.stepOne,
          TWITTER_SlAcc: [
            ...selectedData?.stepOne?.TWITTER_SlAcc?.map(
              (item) => item?.attributes?.id,
            ),
          ],
          INSTAGRAM_SlAcc: [
            ...selectedData?.stepOne?.INSTAGRAM_SlAcc?.map(
              (item) => item?.attributes?.id,
            ),
          ],
          FACEBOOK_SlAcc: [
            ...selectedData?.stepOne?.FACEBOOK_SlAcc?.map(
              (item) => item?.attributes?.id,
            ),
          ],
          cxm: [...selectedData?.stepOne?.channels],
          TWITTER_CxmAcc: [
            ...selectedData?.stepOne?.TWITTER_CxmAcc?.map(
              (item) => item?.attributes?.id,
            ),
          ],
          INSTAGRAM_CxmAcc: [
            ...selectedData?.stepOne?.INSTAGRAM_CxmAcc?.map(
              (item) => item?.attributes?.id,
            ),
          ],
          FACEBOOK_CxmAcc: [
            ...selectedData?.stepOne?.FACEBOOK_CxmAcc?.map(
              (item) => item?.attributes?.id,
            ),
          ],
          TWITTER_PubAcc: [
            ...selectedData?.stepOne?.TWITTER_PubAcc?.map(
              (item) => item?.attributes?.id,
            ),
          ],
          INSTAGRAM_PubAcc: [
            ...selectedData?.stepOne?.INSTAGRAM_PubAcc?.map(
              (item) => item?.attributes?.id,
            ),
          ],
          FACEBOOK_PubAcc: [
            ...selectedData?.stepOne?.FACEBOOK_PubAcc?.map(
              (item) => item?.attributes?.id,
            ),
          ],
        },
        ...(!aiAgentActive ? transformedMessagesPayload : {}),
        ...(!aiAgentActive ? transformedAutomatedPublicRepliesPayload : {}),
        ...updatedSurveyData,
      };

      setIsLoading(true);
      EngagementsController.editUserInfo({
        product_id: window?.localStorage?.engagements_id,
        ...data,
      }).then((data) => {
        if (data?.errorMsg) {
          if (
            data?.errorMsg?.response?.data?.exception?.message?.toLowerCase?.() ==
            "reply text must be unique"
          ) {
            setSnackBar({
              open: true,
              title: "",
              message: "auto_reply_be_unique_msg",
              severity: "error",
              limitWidth: "exceed_width",
            });
          } else if (
            data?.errorMsg?.response?.data?.exception?.message?.toLowerCase?.() ==
            "you have reached the maximum reply limit of 15 for this data source and company."
          ) {
            setSnackBar({
              open: true,
              title: "",
              message: "auto_reply_be_limit_reach_msg",
              severity: "error",
              limitWidth: "exceed_width",
            });
          } else {
            setSnackBar({
              open: true,
              title: "",
              message: "update_not",
              severity: "error",
            });
          }
        } else {
          // localStorage?.setItem("engagSetupDone", true);
          navigate(engagementPriorityProductPath);
        }
        setIsLoading(false);
      });
    }
    setTimeout(() => {
      setSnackBar({
        open: false,
        title: "",
        message: "",
        severity: "",
      });
    }, 7000);
  };
  //end of check if errors or send data

  return (
    <>
      {initialselectedData?.changedNum ? (
        <Box className="edit-engagment-footer">
          <LucButton loading={isLoading} onClick={() => sendAllData()}>
            {CheckValueLocale("report_save_changes", "", {}, intl)}
          </LucButton>
          <LucButton
            disabled={isLoading}
            type="secondary"
            variant="outline"
            onClick={() => navigate(engagementPriorityProductPath)}
          >
            {CheckValueLocale("cancel_btn", "", {}, intl)}
          </LucButton>
          {CheckValueLocale(
            "change_num",
            "",
            { num: `${initialselectedData?.changedNum}` },
            intl,
          )}
        </Box>
      ) : null}
    </>
  );
};

export default MainEditFooter;
