import { Box, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DropDownMenuOptions = (props) => {
  const { open, stats, handleClose, dropMenuOptions } = props;

  const intl = useIntl();

  return (
    <Menu
      id="survey-cards-options-dropdown"
      MenuListProps={{
        "aria-labelledby": "demo-customized-button",
      }}
      anchorEl={stats?.anchorEl}
      open={open}
      onClose={handleClose}
      className="manage-menu menu-serveys"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: intl?.locale == "en" ? "right" : "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: intl?.locale == "en" ? "right" : "left",
      }}
    >
      {dropMenuOptions
        ?.filter((btn) => btn?.show)
        ?.map((btn) => {
          return (
            <Tooltip
              {...btn?.tooltip}
              title={
                btn?.tooltip?.show
                  ? CheckValueLocale(btn?.tooltip?.title, "", {}, intl)
                  : ""
              }
              arrow
            >
              <Box component="span">
                <MenuItem
                  key={btn?.title}
                  id={btn?.id}
                  disableRipple
                  disabled={btn?.disabled}
                  onClick={() => {
                    handleClose();
                    btn?.onClick();
                  }}
                >
                  <FontAwesomeIcon
                    className="header-icons"
                    icon={btn?.icon}
                    fixedWidth
                  />
                  <Typography>
                    {CheckValueLocale(btn?.title, "", {}, intl)}
                  </Typography>
                </MenuItem>
              </Box>
            </Tooltip>
          );
        })}
    </Menu>
  );
};

export default DropDownMenuOptions;
