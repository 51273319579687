import { Box, Button } from "@mui/material";
import "./chat.scss";
import { useIntl } from "react-intl";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { CheckValueLocale } from "utils/helpers";
import ReplyModalChat from "../../replyModal/replyModalChat";
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import { faSparkles } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ezgif from "images/engagements-images/ezgif.gif";
import LucButton from "shared/lucButton/lucButton";
import { LoadEngagementHistoryButton } from "./components/loadEngagementHistoryButton";
import { useSLACounterUpdater } from "./chatHelpers";
import { AlertBanners } from "./components/alertBanners";
import { ChatMessagesContainer } from "./chatMessagesContainer";
import { useSelector } from "react-redux";

const Chat = ({
  selectedCard,
  itemSelected,
  setSelectedCardIteractionID,
  headerData,
  setPage,
  page,
  setOpenDetailsSideBar,
  setOpenDetailsSideBarReplay,
  setOpenSlaSideBar,
  text,
  setText,
  selectedValue,
  setSelectedValue,
  chatData,
  setChatData,
  setOpenSidebar,
  setCheckAiSuggestions,
  checkAiSuggestions,
  openSidebar,
  handelOpenSidebar,
  translationActive,
  setTranslationActive,
  translationSource,
  setTranslationSource,
  translationTarget,
  setTranslationTarget,
  stats,
  setStats,
  languagesArraySource,
  languagesArrayTarget,
  openDetailsSideBarReplay,
  savedSuggTab,
  aiRepsTab,
  savedSuggTabDate,
  isCashedReplays,
  setIsCashedReplays,
  setAiReplaysRefresh,
  isSidebarsOpened,
  showAllIcon,
  aiAgentList,
  setRefresh,
  setOpenSnackBarAiAgent,
  setSnackBarDataAiAgent,
  setInjectedMessage,
  aiAgentId,
  replyBoxInputTextRef,
  slaKey,
  isLoadingDmHistory,
  getInteractionDmHistory,
  setIsLoadingDmHistory,
  socket,
}) => {
  const intl = useIntl();
  const [statusLogs, setStatusLogs] = useState([]);
  const [loadMore, setLoadMore] = useState(false);
  const [stopPagination, setStopPagination] = useState(false);
  const [translateStatus, setTranslateStatus] = useState(false);
  const [deletedMessagesIds, setDeletedMesssagesIds] = useState([]);
  const chatContainerRef = useRef(null);
  const [shouldLoadHistorybeVisible, setShouldLoadHistorybeVisible] =
    useState(true);
  const chatHeightBeforePagination = useRef(0);
  const { mainDates } = useSLACounterUpdater(chatData, itemSelected); // Hanlde SLA counters when chatData changes
  const reduxSlaData = useSelector((state) => state?.engagements?.sla);
  const userSignedIn = localStorage?.getItem("user_id");
  const assignedUser = reduxSlaData?.[slaKey]?.userId;
  const authorizedToReply = +userSignedIn == +assignedUser;
  // ==================================================================================================
  // Appending new chat messages that comes from RabbitMQ SingleInteraction and setting status logs state
  useEffect(() => {
    if (selectedCard?.conversation?.length) {
      if (selectedCard?.staticUpdated === true) return;
      let status_logs = selectedCard?.system_replies?.status_logs?.length
        ? selectedCard?.system_replies?.status_logs?.sort(function (a, b) {
            return new Date(a?.created_at) - new Date(b?.created_at);
          })
        : [];
      setStatusLogs(status_logs?.length ? [...status_logs] : []);
      setChatData([
        ...selectedCard?.conversation?.reverse()?.map((messgae) => ({
          ...messgae,
          isFullDmHistory: isLoadingDmHistory,
        })),
        ...chatData,
      ]);
    } else {
      setStopPagination(true);
      setLoadMore(false);
    }
  }, [selectedCard]);
  // ==================================================================================================
  // Handling scroll position
  // we choose to use useLayoutEffect to not case any flickering, when the chat container mounts the scroll will be at the position it was before the new chat data is added
  useLayoutEffect(() => {
    const chatContainer = chatContainerRef?.current;
    if (!chatContainer) return;

    const scrollTo = (top) => {
      chatContainer?.scrollTo({
        top,
      });
    };

    if (!loadMore && !isLoadingDmHistory) {
      // Scroll to the bottom when we first open the chat dm, or when we send a new message received from the socket
      scrollTo(chatContainer?.scrollHeight);
    } else {
      // Maintain scroll position after loading more messages
      scrollTo(
        chatContainer?.scrollHeight - chatHeightBeforePagination.current,
      );
      setLoadMore(false);
      if (isLoadingDmHistory) {
        setIsLoadingDmHistory(false);
        setShouldLoadHistorybeVisible(false);
      }
    }
  }, [chatData]);

  useEffect(() => {
    const chatContainer = chatContainerRef?.current;
    if (!chatContainer) return;

    const handleScroll = () => {
      if (chatContainer.scrollTop === 0) {
        // User has scrolled to the top, save current scroll height before pagination
        chatHeightBeforePagination.current = chatContainer.scrollHeight;
        if (!stopPagination) {
          // here we handle pagination for chat data in all datasources dm only
          const hasMorePages = page < (selectedCard?.pages || 0);
          if (hasMorePages && !loadMore && chatData?.length) {
            setLoadMore(true);
            setPage(page + 1);
          }
        }
      }
    };
    chatContainer.addEventListener("scroll", handleScroll);
    return () => {
      chatContainer.removeEventListener("scroll", handleScroll);
    };
  }, [page, selectedCard?.pages, stopPagination, loadMore, chatData?.length]);

  // ==================================================================================================
  // Handling cashed replays and open sidebar
  useEffect(() => {
    setIsCashedReplays(
      chatData[chatData?.length - 1]?.created_at < savedSuggTabDate,
    );
  }, [savedSuggTabDate, chatData]);

  useEffect(() => {
    if (
      !(
        selectedCard?.status === "closed" ||
        selectedCard?.system_replies?.status === "complete" ||
        headerData?.paused === 1
      ) &&
      (savedSuggTab || aiRepsTab)
    ) {
      handelOpenSidebar();
    }
  }, [selectedCard, headerData, savedSuggTab, aiRepsTab]);

  // indicates if the warning alert banner should be visible, if yes the reply box hidden.
  const shouldAlertBannerBeVisible =
    selectedCard?.status === "closed" ||
    selectedCard?.system_replies?.status === "complete" ||
    headerData?.paused === 1 ||
    itemSelected?.exceed_whatsapp_window === 1;

  return (
    <Box className="main-engagment-chat">
      <Box
        className={
          selectedCard?.status === "closed" ||
          selectedCard?.system_replies?.status === "complete" ||
          headerData?.paused === 1
            ? "main-chat-contant no-rep"
            : "main-chat-contant"
        }
      >
        {/* Chat container box */}
        <Box
          className="message-container message-container-private chat-main"
          id="message-container"
          ref={chatContainerRef}
        >
          {/* Handle Loading pagination at the top of the chat box */}
          <LoadEngagementHistoryButton
            show={
              selectedCard?.pages <= page &&
              !loadMore &&
              selectedCard?.has_multiple_conversations &&
              shouldLoadHistorybeVisible
            }
            getInteractionDmHistory={() => {
              chatHeightBeforePagination.current =
                chatContainerRef?.current?.scrollHeight; // Save current scroll height before pagination
              getInteractionDmHistory();
            }}
            isLoadingDmHistory={isLoadingDmHistory}
          />
          {loadMore ? (
            <Box className="pagination-loader">
              {CheckValueLocale("loading", "", {}, intl)}
              <FontAwesomeIcon icon={faSpinnerThird} spin />
            </Box>
          ) : null}
          {/* Handle messages rendering */}
          <ChatMessagesContainer
            chatData={chatData}
            deletedMessagesIds={deletedMessagesIds}
            mainDates={mainDates}
            selectedCard={selectedCard}
            setStats={setStats}
            translationTarget={translationTarget}
            statusLogs={statusLogs}
            translationActive={translationActive}
            translationSource={translationSource}
          />
          {!isCashedReplays && openSidebar ? (
            <Box className="cashed-replays">
              <Box className="cashed-replays-msg">
                {CheckValueLocale("cashed_msg", "", {}, intl)}
              </Box>
              <LucButton
                className="update-ai-cashed"
                onClick={() => setAiReplaysRefresh(true)}
                variant="outline"
                startIcon={<FontAwesomeIcon icon={faSparkles} />}
              >
                {CheckValueLocale("update_ai", "", {}, intl)}
              </LucButton>
            </Box>
          ) : null}
        </Box>
      </Box>
      {/* Alert Banners handling */}
      {shouldAlertBannerBeVisible ? (
        <AlertBanners
          headerData={headerData}
          selectedCard={selectedCard}
          exceedWhatsappWindow={itemSelected?.exceed_whatsapp_window}
        />
      ) : (
        <Box className="main-posts-footer-parant">
          {!openSidebar && !aiAgentId && authorizedToReply ? (
            <Button
              className="ai-suggestions"
              onClick={() => handelOpenSidebar()}
            >
              <img src={ezgif} />
              {CheckValueLocale("ai_suggestions", "", {}, intl)}
            </Button>
          ) : null}
          <ReplyModalChat
            setOpenDetailsSideBar={setOpenDetailsSideBar}
            setOpenDetailsSideBarReplay={setOpenDetailsSideBarReplay}
            setOpenSlaSideBar={setOpenSlaSideBar}
            selectedCard={selectedCard}
            setChatData={setChatData}
            setStatusLogs={setStatusLogs}
            setSelectedCardIteractionID={setSelectedCardIteractionID}
            chatData={chatData}
            text={text}
            setText={setText}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            setOpenSidebar={setOpenSidebar}
            setCheckAiSuggestions={setCheckAiSuggestions}
            translationActive={translationActive}
            setTranslationActive={setTranslationActive}
            translationSource={translationSource}
            setTranslationSource={setTranslationSource}
            translationTarget={translationTarget}
            setTranslationTarget={setTranslationTarget}
            stats={stats}
            setStats={setStats}
            languagesArraySource={languagesArraySource}
            languagesArrayTarget={languagesArrayTarget}
            translateStatus={translateStatus}
            setTranslateStatus={setTranslateStatus}
            headerData={headerData}
            setDeletedMesssagesIds={setDeletedMesssagesIds}
            openSidebar={openSidebar}
            openDetailsSideBarReplay={openDetailsSideBarReplay}
            isSidebarsOpened={isSidebarsOpened}
            showAllIcon={showAllIcon}
            aiAgentList={aiAgentList}
            setRefresh={setRefresh}
            setOpenSnackBarAiAgent={setOpenSnackBarAiAgent}
            setSnackBarDataAiAgent={setSnackBarDataAiAgent}
            setInjectedMessage={setInjectedMessage}
            aiAgentId={aiAgentId}
            replyBoxInputTextRef={replyBoxInputTextRef}
            reduxSlaKeys={slaKey}
            itemSelected={itemSelected}
            socket={socket}
          />
        </Box>
      )}
    </Box>
  );
};
export default Chat;
