import { useMutation, useQueryClient } from "@tanstack/react-query";
import Engagements from "services/api/engagements";

const useDeleteNote = () => {
  return useMutation({
    mutationFn: ({ params, noteId }) => Engagements.deleteNote(params, noteId),
  });
};

export default useDeleteNote;
