import xPlatform from "images/social-icons/x-platform-white-icon.svg";
import { Box } from "@mui/material";
import PopupModal from "components/popupModal";
import SnackBar from "components/snackBar";
import CommonFunctions from "pages/Monitors/Components/commonFunctions";
import QuestionCard from "pages/Monitors/Components/questionCard/index";
import "pages/Monitors/monitors.scss";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { CustomPagination } from "components/CustomPagination/CustomPagination";

/*---------------------------------------------------------*/
const CommentsMentionsBody = (props) => {
  const {
    response,
    preLoaderTrackerDataSources,
    resetAllFields,
    changePagination,
    setTotalPage,
    totalPage,
  } = props;
  const intl = useIntl();
  const [commentsMentions, setCommentsMentions] = useState([]);

  const [commentsMentionsPreloader, setCommentsMentionsPreloader] = useState(
    [],
  );

  const [page, setPage] = useState(1);

  const handleChangePage = (event, page) => {
    if (Boolean(page) === false) return;
    setPage(page);
    changePagination(page);
  };

  // Refresh all values at Each request to default values
  const handleResponseFunc = async () => {
    if (
      response.eventName ===
      "Twitter__QuestionsPage__latest_unanswered_questions"
    ) {
      setCommentsMentions(response.eventData.latest_unanswered_questions);
      if (props.pageNumber == 1) {
        setTotalPage(response.eventData.number_of_unanswered_questions);
        setPage(1);
      }
      setCommentsMentionsPreloader((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else {
      return null;
    }
  };
  useEffect(() => {
    handleResponseFunc();
  }, [response]);

  //handle response with useEffect (when load page & any update)
  useEffect(() => {
    if (resetAllFields !== 1) {
      setCommentsMentions([]);
      setCommentsMentionsPreloader([]);
    }
  }, [resetAllFields]);
  const {
    updateAnalysis,
    handleCloseSnackBar,
    showAnalyzeTweetSnackBar,
    analyzeTweetSnackBar,
    stats,
    handleCloseCreateModal,
    modalBody,
  } = CommonFunctions();

  useEffect(() => {
    let totalPreLoaderDataSouces = [];

    totalPreLoaderDataSouces.push(commentsMentionsPreloader);

    props.checkAllDataFounded(
      totalPreLoaderDataSouces,
      props.preLoaderTrackerDataSources,
    );

    props.checkDataIsFounded(commentsMentions);
  }, [commentsMentionsPreloader, commentsMentions]);

  //Luci check [ noDataFound , Analyzing ]
  useEffect(() => {
    let x = props.checkLuciAllDataFounded(
      commentsMentionsPreloader,
      props.preLoaderTrackerDataSources,
    );
    let y = props.checkLuciDataIsFounded([commentsMentions]);
    props.setLuciCheckAllDataExist(x);
    props.setLuciCheckDataIsEmpty(y);
  }, [
    commentsMentions,
    commentsMentionsPreloader,
    props.preLoaderTrackerDataSources,
  ]);
  useEffect(() => {
    props?.setTotalPostsCount?.(totalPage);
  }, [totalPage]);
  return (
    <Box mt={2}>
      <QuestionCard
        unixDate={props.unixDate}
        showProfile={true}
        productId={window.localStorage?.getItem("sm_id")}
        latestUnanswer={commentsMentions}
        unAnsweredQuestion={totalPage}
        ShowPagination
        changePagination={changePagination}
        latestUnanswerPreloader={commentsMentionsPreloader}
        preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        icon={xPlatform}
        iconColor={"twitter_icon"}
        showRetweet={true}
        showBanIcon={true}
        showScreenName={true}
        updateAnalysis={updateAnalysis}
        noOfChar={15}
        singleTweetAnalysis={props.singleTweetAnalysis}
        showHeart
        showDefaultImg
        getFiltersFileds={props?.getFiltersFileds}
      />
      {totalPage !== 0 && totalPage > 10 && commentsMentions?.length > 0 && (
        <CustomPagination
          onChange={handleChangePage}
          page={page}
          pagesCount={Math.ceil(totalPage / 10)}
          itemsCount={totalPage}
        />
      )}
      {showAnalyzeTweetSnackBar && (
        <SnackBar
          open={true}
          handleClose={handleCloseSnackBar}
          title={CheckValueLocale(analyzeTweetSnackBar.title, "", {}, intl)}
          message={CheckValueLocale(analyzeTweetSnackBar.message, "", {}, intl)}
          severity={analyzeTweetSnackBar.severity}
        />
      )}
      <PopupModal
        title={CheckValueLocale("single_tweet_analysis", "", {}, intl)}
        body={modalBody}
        open={stats?.openCreateModal}
        close={handleCloseCreateModal}
        warning="warning"
        classeName="create-historical-popup"
        addClasses="create-single-post-analysis-modal"
      />
    </Box>
  );
};

export default CommentsMentionsBody;
