import { useState } from "react";
import { Box, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import moment from "moment";
import { useSurveyExcelExport } from "../hooks/useSurveyExcelExport";
import { CheckValueLocale } from "utils/helpers";
import CreateSurveyModal from "../components/createSurveyModal";
import DropDownMenuOptions from "./dropDownMenuOptions/dropDownMenuOptions";
import LucButton from "shared/lucButton/lucButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowToBottom, faClone } from "@fortawesome/pro-solid-svg-icons";
import {
  faArchive,
  faShareAlt,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import {
  faChartPie,
  faEdit,
  faEllipsisVertical,
} from "@fortawesome/pro-regular-svg-icons";
import { handlelUserRoles } from "utils/helpers";
import CustomizedTooltip from "components/customizedTooltip";

const OptionsDropDown = (props) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const {
    setOpenPublishSurveyModal,
    setOpenShareSurveyModal,
    openPublishSurveyModal,
    setOpenDeleteSurveyModal,
    setOpenArchiveSurveyModal,
    survey,
    surveyLimitReach,
    setSnackbar,
  } = props;

  const [openDuplicateModal, setOpenDuplicateModal] = useState(false);

  const [stats, setStats] = useState({ anchorEl: null }); // DropDown Menu Stats
  const open = Boolean(stats?.anchorEl);
  const handleOpenDropdownMenu = (event) =>
    setStats({ ...stats, anchorEl: event.currentTarget });
  const handleCloseDropdownMenu = () => setStats({ ...stats, anchorEl: null });

  const { mutate: exportExcel } = useSurveyExcelExport(); // Export Excel Mutation

  const handleApplyExport = () => {
    exportExcel(
      {
        queryData: {
          data: {
            survey: {
              start_date: survey?.oldest_date,
              end_date: moment()?.unix(),
              survey_id: survey?.id,
            },
          },
        },
      },
      {
        onSuccess: () => {
          setSnackbar({
            open: true,
            severity: "success",
            title: "export_alert_title",
            message: "export_alert_msg",
          });
        },
        onError: () => {
          setSnackbar({
            open: true,
            severity: "error",
            title: "failed_error_message",
            message: "try_again_error_message",
          });
        },
        onSettled: () => handleCloseDropdownMenu(),
      },
    );
  };

  const actionBtns = [
    {
      id: "survey-card-survey-insights-button",
      icon: faChartPie,
      show: true,
      disabled: survey?.status === "draft",
      onClick: () => {
        navigate(`/survey/survey-insights/${survey.id}`, { state: survey });
      },
      tooltip_title:
        survey?.status === "draft"
          ? "survey_not_published"
          : "survey_insights_tooltip",
    },
    {
      id: "survey-card-survey-builder-button",
      icon: faEdit,
      show: handlelUserRoles("SURVEY", "EDIT_SURVEY"),
      disabled: survey?.status === "archived",
      onClick: () => {
        navigate(`/survey/survey-builder/${survey.id}`);
      },
      tooltip_title:
        survey?.status === "archived" ? "survey_is_unpublished" : "edit",
    },
  ];

  const dropMenuOptions = [
    {
      id: "survey-card-dropdown-share-button",
      title: "share",
      icon: faShareAlt,
      show:
        survey?.status === "active" &&
        survey?.survey_type?.toLowerCase() !== "embedded_survey",
      onClick: () => {
        setOpenPublishSurveyModal(!openPublishSurveyModal);
        setOpenShareSurveyModal(true);
      },
    },
    {
      id: "survey-card-dropdown-duplicate-button",
      title: "survey_duplicate",
      icon: faClone,
      show: handlelUserRoles("SURVEY", "CREATE_SURVEY"),
      onClick: () => setOpenDuplicateModal(true),
      disabled: surveyLimitReach,
      tooltip: {
        show: surveyLimitReach,
        title: "max_survey_count",
        placement: "bottom",
        id: "survey-max-btn-tooltip",
      },
    },
    {
      id: "survey-card-dropdown-export-as-excel-button",
      title: "export_as_excel",
      icon: faArrowToBottom,
      show:
        survey?.status !== "draft" &&
        handlelUserRoles("SURVEY", "EXPORT_SURVEY"),
      onClick: () => handleApplyExport(),
    },
    {
      id: "survey-card-dropdown-archive-button",
      title: "archive",
      icon: faArchive,
      show:
        survey?.status === "active" &&
        handlelUserRoles("SURVEY", "DESTROY_SURVEY"),
      onClick: () => setOpenArchiveSurveyModal(true),
    },
    {
      id: "survey-card-dropdown-delete-button",
      title: "delete",
      icon: faTrashCan,
      show: handlelUserRoles("SURVEY", "DESTROY_SURVEY"),
      onClick: () => setOpenDeleteSurveyModal(true),
    },
  ];

  const showDropdownOptions = dropMenuOptions?.some((item) => item?.show);

  return (
    <Box className="btn-box-syrvey-card">
      {actionBtns
        ?.filter((btn) => btn?.show)
        ?.map((btn) => {
          return (
            <CustomizedTooltip
              title={
                <Box className="item-title-tooltip">
                  {CheckValueLocale(btn?.tooltip_title, "", {}, intl)}
                </Box>
              }
              placement="top"
              arrow
            >
              <Box className="syrvey-card-btn">
                <LucButton
                  id={btn?.id}
                  type="secondary"
                  variant="flat"
                  size="small"
                  className="syrvey-icon-card"
                  disableRipple
                  onClick={() => btn?.onClick()}
                  disabled={btn?.disabled}
                >
                  <FontAwesomeIcon fixedWidth icon={btn?.icon} />
                </LucButton>
              </Box>
            </CustomizedTooltip>
          );
        })}

      {showDropdownOptions ? (
        <CustomizedTooltip
          id="menu-item-list-tooltip"
          title={
            <Box className="item-title-tooltip">
              {CheckValueLocale("items_ellipsis", "", {}, intl)}
            </Box>
          }
          arrow
          placement="top"
        >
          <IconButton
            aria-label="more"
            id="survey-cards-long-button"
            className={`drop-action-btn ${open && "opened-drop-actions"}`}
            aria-controls="long-menu"
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleOpenDropdownMenu}
            onClose={handleCloseDropdownMenu}
          >
            <FontAwesomeIcon icon={faEllipsisVertical} />
          </IconButton>
        </CustomizedTooltip>
      ) : null}

      {showDropdownOptions ? (
        <DropDownMenuOptions
          open={open}
          stats={stats}
          handleClose={handleCloseDropdownMenu}
          dropMenuOptions={dropMenuOptions}
        />
      ) : null}

      {openDuplicateModal ? (
        <CreateSurveyModal
          isDuplicateSurvey
          open={openDuplicateModal}
          setOpen={setOpenDuplicateModal}
          setSnackbar={setSnackbar}
          surveyToDuplicate={survey}
        />
      ) : null}
    </Box>
  );
};
export default OptionsDropDown;
