import { useQuery } from "@tanstack/react-query";
import { ReportServices } from "services/api/reports";

export const useReportUsers = () => {
  return useQuery({
    queryKey: ["reportUsers"],
    queryFn: () => ReportServices.getReportUsers(),
    select: (data) => data?.data?.data?.map((item) => item?.attributes),
  });
};
