import { Link } from "react-router-dom";
import {
  Alert,
  Box,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckValueLocale, getSocialIcon } from "utils/helpers";
import Services from "services/api/Services.js";
import {
  sidebarGeneralOptions,
  sidebarOptionsMapper,
} from "shared/customDateRangePicker/config/sidebarOptions";
import CustomDateRangePicker from "shared/customDateRangePicker/customDateRangePicker";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

const HistoricalRequestStepOne = (props) => {
  const {
    companyProductsData,
    companyProducts,
    companyProductsLoading,
    dataSourcesData,
    dataSourcesLoading,
    monitorsData,
    monitorsLoading,
    applyFilter,
    quotaLimitMsg,
    xPlatformId,
    startNewDate,
    endNewDate,
    defaultDateRange,
    startTime,
    setStartTime,
    setDefaultDateRange,
    oldestDate,
    xPlatformAvailableDataLoading,
    endTime,
    setEndTime,
    xPlatformDuplicatesCount,
    xPlatformActualCount,
    formik,
    isMonitorOption,
    monitorInfo,
    handleResetTime,
    setHasKeyword,
    checkIfFBPage,
    updateDatePicker,
    datePickerRestrictionConfirmation,
    resetCalendar,
    startDate,
    endDate,
    dateRangePickerParamsRef,
  } = props;

  const intl = useIntl();

  const { values, handleChange, setFieldValue } = formik || {};

  const MenuProps = { PaperProps: { style: { maxHeight: 200 } } };

  return (
    <Box id="historical-step-one">
      <Box>
        <InputLabel className="body-input-label show-red-icon">
          {CheckValueLocale("platform", "", {}, intl)}
        </InputLabel>
        <Select
          labelId="historical-platform-select-label"
          id="create-historical-product-select"
          className="create-historical-select-input"
          name="product_id"
          value={values?.["product_id"]}
          onChange={(e) => {
            formik?.handleChange(e);
            setFieldValue("data_source_id", ""); // Reset data source
            setHasKeyword(false);
            handleResetTime();
            if (!isMonitorOption) setFieldValue("monitor_model_id", ""); // Reset monitor model id
          }}
          disabled={companyProductsLoading || isMonitorOption}
          fullWidth
          size="small"
          displayEmpty
        >
          <MenuItem value="" disabled className="platform-select-item">
            {CheckValueLocale("select_placeholder", "", {}, intl)}
          </MenuItem>
          {companyProducts?.map((platform) => {
            return (
              <MenuItem
                key={platform?.attributes?.id}
                value={platform?.attributes?.id}
                className="platform-select-item"
                id={`settings-historical-request-platform-${platform?.attributes?.name ?? "name"}`}
              >
                {CheckValueLocale(platform?.attributes?.name, "", {}, intl)}
              </MenuItem>
            );
          })}
        </Select>
      </Box>

      <Box>
        <InputLabel className="body-input-label show-red-icon">
          {CheckValueLocale("data_source_channel", "", {}, intl)}
        </InputLabel>
        <Select
          labelId="report-platform-select-label"
          id="create-historical-datasource-select"
          className="create-historical-select-input"
          name="data_source_id"
          value={values?.["data_source_id"]}
          onChange={(e) => {
            handleChange(e);
            setHasKeyword(false);
            handleResetTime();
            if (!isMonitorOption) setFieldValue("monitor_model_id", ""); // Reset monitor model id
          }}
          disabled={
            dataSourcesLoading || monitorInfo?.data_sources?.length == 1
          }
          fullWidth
          size="small"
          displayEmpty
          renderValue={() => {
            const selectedName = dataSourcesData?.sources?.data?.find(
              (item) => item?.attributes?.id == values?.["data_source_id"],
            )?.attributes?.source;

            if (!values?.["data_source_id"])
              return CheckValueLocale("select_placeholder", "", {}, intl);
            else
              return (
                <Box className="create-historical-select-input-rendered-value">
                  <Box>{getSocialIcon(selectedName)}</Box>
                  {CheckValueLocale(selectedName?.toLowerCase(), "", {}, intl)}
                </Box>
              );
          }}
        >
          {dataSourcesData?.sources?.data
            ?.filter((source) => {
              // To return only the datasources already in monitor in case of monitor options
              if (isMonitorOption)
                return monitorInfo?.data_sources?.includes(
                  source?.attributes?.source,
                );
              else return true;
            })
            ?.map((datasource, i) => {
              let isIgKeyword =
                isMonitorOption &&
                monitorInfo?.type === "KEYWORD" &&
                !Services.enableExtraSources &&
                datasource?.attributes?.source === "INSTAGRAM";

              if (isIgKeyword) {
                return (
                  <Tooltip
                    key={i}
                    id="monitor-options-hr-item-tooltip"
                    placement="top"
                    arrow
                    title={
                      isIgKeyword
                        ? CheckValueLocale(
                            "historical_request_monitor_option_tooltip",
                            "",
                            {},
                            intl,
                          )
                        : ""
                    }
                  >
                    <Box id="historical-request-menu-item-wrapper">
                      <MenuItem
                        value={datasource?.attributes?.id}
                        className={`platform-select-item ${
                          isIgKeyword ? "disabled-grey-item" : ""
                        }`}
                        disabled={
                          datasource?.attributes?.source === "INTERCOM" ||
                          (isMonitorOption &&
                            monitorInfo?.type === "KEYWORD" &&
                            datasource?.attributes?.source === "INSTAGRAM")
                        }
                        id={`settings-historical-request-datasource-${
                          datasource?.attributes?.source ?? "data-source-name"
                        }`}
                      >
                        <Box>
                          {getSocialIcon(datasource?.attributes?.source)}
                        </Box>
                        {CheckValueLocale(
                          datasource?.attributes?.source?.toLowerCase(),
                          "",
                          {},
                          intl,
                        )}
                      </MenuItem>
                    </Box>
                  </Tooltip>
                );
              } else
                return (
                  <MenuItem
                    key={i}
                    value={datasource?.attributes?.id}
                    className="platform-select-item"
                    disabled={datasource?.attributes?.source === "INTERCOM"}
                    id={`settings-historical-request-datasource-${datasource?.attributes?.source ?? "data-source-name"}`}
                  >
                    <Box>{getSocialIcon(datasource?.attributes?.source)}</Box>
                    {CheckValueLocale(
                      datasource?.attributes?.source?.toLowerCase(),
                      "",
                      {},
                      intl,
                    )}
                  </MenuItem>
                );
            })}
        </Select>
      </Box>

      {quotaLimitMsg && (
        <Alert severity={"error"}>
          <strong>
            {values?.["data_source_id"] === companyProductsData?.ids?.["SM"]
              ? CheckValueLocale(
                  "quota_limit_header_historical_SL",
                  "",
                  {},
                  intl,
                )
              : CheckValueLocale(
                  "quota_limit_header_historical_OM",
                  "",
                  {},
                  intl,
                )}
          </strong>
          <Box>
            {values?.["data_source_id"] === companyProductsData?.ids?.["SM"]
              ? CheckValueLocale("quota_limit_historical_SL", "", {}, intl)
              : CheckValueLocale("quota_limit_historical_OM", "", {}, intl)}
            <Link
              to={"/settings/subscription"}
              className="historical-link-subscription"
            >
              {CheckValueLocale("link_subscription", "", {}, intl)}
            </Link>
          </Box>
        </Alert>
      )}

      <Box>
        <InputLabel className="body-input-label show-red-icon">
          {CheckValueLocale("monitor_account", "", {}, intl)}
        </InputLabel>
        <Select
          labelId="report-platform-select-label"
          id="create-historical-monitor-select"
          className="create-historical-select-input"
          name="monitor_model_id"
          value={values?.["monitor_model_id"]}
          onChange={(e) => {
            handleResetTime();
            handleChange(e);
            checkIfFBPage(e);
            updateDatePicker(e);
          }}
          disabled={monitorsLoading || isMonitorOption}
          MenuProps={MenuProps}
          fullWidth
          size="small"
          displayEmpty
        >
          <MenuItem value="" disabled className="platform-select-item">
            {CheckValueLocale("select_placeholder", "", {}, intl)}
          </MenuItem>

          {isMonitorOption ? (
            <MenuItem className="platform-select-item" value={monitorInfo?.id}>
              {monitorInfo?.name}
            </MenuItem>
          ) : (
            monitorsData?.map((monitor) => (
              <MenuItem
                key={monitor?.attributes?.id}
                value={monitor?.attributes?.id}
                className="platform-select-item"
                id={`settings-historical-request-monitor-${monitor?.attributes?.name ?? "name"}`}
              >
                {monitor?.attributes?.name}
              </MenuItem>
            ))
          )}
        </Select>
      </Box>

      <Box className="create-historical-calendar">
        <InputLabel className="body-input-label show-red-icon">
          {CheckValueLocale("dates_range", "", {}, intl)}
        </InputLabel>

        <CustomDateRangePicker
          initialSidebarLabel={sidebarOptionsMapper.lastsevendays}
          startDate={startDate}
          endDate={endDate}
          popperPositioning="bottom-start"
          applyFilter={applyFilter}
          dateRangePickerParamsRef={dateRangePickerParamsRef}
          maxDate={new Date()}
          minDate={
            datePickerRestrictionConfirmation && Services.enableExtraSources
              ? moment().subtract(30, "days")
              : oldestDate
          }
          sidebarOptions={sidebarGeneralOptions}
          enableResetting={true}
          resetCalendar={resetCalendar}
          hasTimePicker
          customStyles={{
            type: "secondary",
            variant: "outline",
            size: "large",
            width: "100%",
            fixedWidth: "100%",
            startIcon: (
              <FontAwesomeIcon
                icon={faCalendar}
                className="custom-input-icon"
              />
            ),
          }}
        />
      </Box>

      {values?.["data_source_id"] === xPlatformId && !quotaLimitMsg ? (
        <Box className="box-statistics-historical">
          <Box component={"h4"} className="statistics-historical-header">
            {CheckValueLocale("statistics_historical", "", {}, intl)}
          </Box>
          <Box component={"ul"}>
            <Box component={"li"}>
              <Box component="span" className="item-statistics_historical">
                <Box component="span">
                  {CheckValueLocale("estimated_twitter", "", {}, intl)}
                </Box>
                <Box component="span" fontSize={16}>
                  {!xPlatformAvailableDataLoading
                    ? xPlatformActualCount
                    : CheckValueLocale("loading_dotted", "", {}, intl)}
                </Box>
              </Box>
            </Box>
            <Box component={"li"}>
              <Box component="span" className="item-statistics_historical">
                <Box component="span">
                  {CheckValueLocale("estimated_duplicates", "", {}, intl)}
                </Box>
                <Box component="span" fontSize={16}>
                  {!xPlatformAvailableDataLoading
                    ? xPlatformDuplicatesCount
                    : CheckValueLocale("loading_dotted", "", {}, intl)}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : null}

      <Box className="historical-request-step-one-alert">
        <Alert severity={"info"} className="alert-time-historical">
          {CheckValueLocale("alert_time_historical", "", {}, intl)}
        </Alert>
      </Box>
    </Box>
  );
};

export default HistoricalRequestStepOne;
