import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import "./formSide.scss";
import FormFooter from "./formFooter";
import FormHeader from "./formHeader";
import FormLogin from "./formLogin";
import FormForgetPassword from "./formForgetPassword";
import FormResetPassword from "./formResetPassword";
import SnackBar from "components/snackBar";
import { CheckValueLocale } from "utils/helpers";
import LoginFormFunctions from "./loginFormFunctions/loginFormFunctions";
import FormNewUser from "./formNewUser";
import { useState } from "react";
import TwoFactorAuthLogin from "./twoFactorAuthLogin/twoFactorAuthLogin";

const FormSide = () => {
  const intl = useIntl();
  const {
    stats,
    errors,
    handleSnackbarClose,
    handleUserLogin,
    handleInputChange,
    errorFields,
    handleShowForgetPassword,
    handleBkToSignIn,
    handleUserForgetPassword,
    handleUserResetPassword,
    handleActivateSignUp,
    handleSSOlogin,
    handleVerifySuccess,
    handleVerifyExpire,
    data2FALoginPage,
    setData2FALoginPage,
    callApiLogin2FA,
    errorCodeMsg,
    setErrorCodeMsg,
    codeVerify,
    setCodeVerify,
    snackBar,
    handleErrorSnackBar,
    handleCloseSnackBar,
  } = LoginFormFunctions();

  const alertStyleCxmCore =
    stats?.showSnackBarError === "showCxmCore" ||
    stats?.showSnackBarError === "showNotStarted"
      ? localStorage?.lang == "ar"
        ? "75%"
        : "70%"
      : "55%";

  const resetPassword = window?.location?.pathname?.includes("/reset_password");
  const invitationLogin =
    window?.location?.pathname?.includes("/invitation_login");

  const getLoginComonent = () => (
    <>
      {data2FALoginPage?.step === 0 ? (
        // step 0 for first page login "(email, password)"
        <FormLogin
          stats={stats}
          handleUserLogin={handleUserLogin}
          handleInputChange={handleInputChange}
          errorFields={errorFields}
          handleShowForgetPassword={handleShowForgetPassword}
          handleSSOlogin={handleSSOlogin}
          handleVerifySuccess={handleVerifySuccess}
          handleVerifyExpire={handleVerifyExpire}
        />
      ) : (
        <TwoFactorAuthLogin
          stats={stats}
          data2FALoginPage={data2FALoginPage}
          setData2FALoginPage={setData2FALoginPage}
          callApiLogin2FA={callApiLogin2FA}
          errorCodeMsg={errorCodeMsg}
          setErrorCodeMsg={setErrorCodeMsg}
          codeVerify={codeVerify}
          setCodeVerify={setCodeVerify}
          handleErrorSnackBar={handleErrorSnackBar}
        />
      )}
    </>
  );
  return (
    <Box className="form-wrapper">
      <FormHeader />
      <Box className="main-form-box">
        {stats?.showForgetPassword === true ? (
          <FormForgetPassword
            stats={stats}
            handleInputChange={handleInputChange}
            errorFields={errorFields}
            handleUserForgetPassword={handleUserForgetPassword}
            handleBkToSignIn={handleBkToSignIn}
            handleVerifySuccess={handleVerifySuccess}
            handleVerifyExpire={handleVerifyExpire}
          />
        ) : resetPassword ? (
          !stats?.isResetPassword ? (
            <FormResetPassword
              stats={stats}
              errors={errors}
              handleInputChange={handleInputChange}
              errorFields={errorFields}
              handleUserResetPassword={handleUserResetPassword}
            />
          ) : (
            getLoginComonent()
          )
        ) : invitationLogin ? (
          !stats?.isNewUser ? (
            <FormNewUser
              stats={stats}
              errors={errors}
              handleInputChange={handleInputChange}
              errorFields={errorFields}
              handleActivateSignUp={handleActivateSignUp}
            />
          ) : (
            getLoginComonent()
          )
        ) : (
          getLoginComonent()
        )}
      </Box>
      <FormFooter />
      {stats?.showSnackBarError ||
      stats?.showSnackBarWarning ||
      (stats?.showSnackBarSuccess &&
        (stats?.isForgetPassword ||
          stats?.isResetPassword ||
          stats?.isNewUser)) ? (
        <SnackBar
          open={true}
          severity={
            stats?.showSnackBarError
              ? "error"
              : stats?.showSnackBarWarning
                ? "warning"
                : "success"
          }
          message={
            stats?.isResetPassword && !stats?.isForgetPassword
              ? CheckValueLocale("log_in_to_check", "", {}, intl)
              : stats?.isNewUser && !stats?.isForgetPassword
                ? CheckValueLocale("msg_newuser_sign", "", {}, intl)
                : stats?.showSnackBarError === "showCxmCore" ||
                    stats?.showSnackBarError === "showNotStarted"
                  ? CheckValueLocale("account_cxm_core_body", "", {}, intl)
                  : stats?.showSnackBarError === "showError"
                    ? CheckValueLocale("suspended_company_msg", "", {}, intl)
                    : stats?.showSnackBarWarning === "showDeactivated"
                      ? CheckValueLocale(
                          "account_deactivated_body",
                          "",
                          {},
                          intl,
                        )
                      : CheckValueLocale("check_ur_email_login", "", {}, intl)
          }
          title={
            stats?.isResetPassword && !stats?.isForgetPassword
              ? CheckValueLocale(
                  "the_password_reset_successfully",
                  "",
                  {},
                  intl,
                )
              : stats?.isNewUser && !stats?.isForgetPassword
                ? CheckValueLocale("account_created_successfully", "", {}, intl)
                : stats?.showSnackBarError === "showNotStarted"
                  ? CheckValueLocale("COMPANY_NOT_STARTED", "", {}, intl)
                  : stats?.showSnackBarError === "showCxmCore"
                    ? CheckValueLocale("account_cxm_core_head", "", {}, intl)
                    : stats?.showSnackBarError === "showError"
                      ? CheckValueLocale("SUSPENDED_COMPANY", "", {}, intl)
                      : stats?.showSnackBarWarning === "showDeactivated"
                        ? CheckValueLocale(
                            "account_deactivated_head",
                            "",
                            {},
                            intl,
                          )
                        : CheckValueLocale(
                            "email_sent_successfully_login",
                            "",
                            {},
                            intl,
                          )
          }
          alertStyle={
            stats?.showSnackBarError === "showError" ||
            stats?.showSnackBarWarning === "showDeactivated" ||
            stats?.showSnackBarError === "showCxmCore" ||
            stats?.showSnackBarError === "showNotStarted"
              ? {
                  width: alertStyleCxmCore,
                  boxShadow: "1px 3px 5px rgb(0 0 0 / 20%)",
                }
              : { width: "100%" }
          }
          handleClose={handleSnackbarClose}
        />
      ) : null}

      <SnackBar
        open={snackBar?.open}
        handleClose={handleCloseSnackBar}
        title={CheckValueLocale(snackBar?.title, "", {}, intl)}
        message={CheckValueLocale(snackBar?.msg, "", {}, intl)}
        severity={snackBar?.severity}
        alertStyle={{ maxWidth: "320px" }}
      />
    </Box>
  );
};
export default FormSide;
