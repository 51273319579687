import React from "react";
import {
  Box,
  Checkbox,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale, getSocialIcon } from "utils/helpers";

const CreateReportChannelsSelect = ({
  options = [],
  value = [],
  onChange = () => {},
  onBlur = () => {},
  loading = false,
  error = false,
  helperText = "",
}) => {
  const intl = useIntl();

  const allSelected = value?.length === options?.length;

  return (
    <Box className="create-report-input-wrapper">
      <InputLabel className="create-report-input-label">
        {CheckValueLocale("select_accounts", "", {}, intl)}
      </InputLabel>
      <Select
        variant="outlined"
        size="small"
        fullWidth
        id="create-report-select-channels-input"
        name="monitor_list"
        value={value || []}
        onChange={onChange}
        onBlur={onBlur}
        renderValue={(value) => {
          if (!value?.length) return CheckValueLocale("select", "", {}, intl);
          else return value?.map((item) => item?.name)?.join(", ");
        }}
        MenuProps={{
          PaperProps: { style: { maxHeight: 160, maxWidth: 100 } },
          getContentAnchorEl: null,
          anchorOrigin: { vertical: "bottom" },
          transformOrigin: { vertical: "top" },
        }}
        multiple
        displayEmpty
        error={error}
      >
        {!loading && options?.length >= 1 ? (
          <MenuItem
            id="create-report-channels-select-menu-item"
            className="create-report-select-all-menu-item"
            value={allSelected ? "unselect_all" : "select_all"}
          >
            <Checkbox checked={allSelected} />
            {CheckValueLocale("select_all", "", {}, intl)}
          </MenuItem>
        ) : null}

        {loading ? (
          <MenuItem id="create-report-select-input-menu-item" disabled>
            {CheckValueLocale("dropdown_loading", "", {}, intl)}
          </MenuItem>
        ) : options?.length >= 1 ? (
          options?.map((option) => {
            const collecting = !Boolean(option?.paused);

            return (
              <MenuItem
                key={option?.value}
                id="create-report-channels-select-menu-item"
                className={"create-report-multiple-select-input-menu-item"}
                value={option}
              >
                <Checkbox
                  checked={!!value?.find((item) => item?.id === option?.id)}
                />
                <Box className="channel-info-wrapper">
                  <Typography className="channel-info-name">
                    {CheckValueLocale(option?.name, "", {}, intl)}
                    {getSocialIcon(
                      option?.type?.toLowerCase() === "private"
                        ? `${option?.data_source}_private`
                        : option?.data_source,
                    )}
                  </Typography>
                  <Typography
                    className={`channel-info-paused-status ${collecting ? "collecting" : "paused"}`}
                  >
                    {CheckValueLocale(
                      collecting ? "collecting" : "paused",
                      "",
                      {},
                      intl,
                    )}
                  </Typography>
                </Box>
              </MenuItem>
            );
          })
        ) : (
          <MenuItem id="create-report-select-input-menu-item" disabled>
            {CheckValueLocale(
              "searchable_multi_select_filter_no_options",
              "",
              {},
              intl,
            )}
            ...
          </MenuItem>
        )}
      </Select>
      {error ? (
        <FormHelperText error>
          {CheckValueLocale(helperText || "", "", {}, intl)}
        </FormHelperText>
      ) : null}
    </Box>
  );
};

export default CreateReportChannelsSelect;
