import { useEffect, useState } from "react";
import { Box, Grid, Pagination } from "@mui/material";
import LineChartWidget from "components/widgets/lineChartWidget";
import PieChartWidget from "components/widgets/pieChartWidget";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import SectionTitle from "pages/alerts/components/sectionTitle/sectionTitle";
import AlertStatistics from "pages/alerts/components/alertStatistics/alertStatistics";
import InteractionsList from "components/interactions-list/InteractionsList.js";
import { useIntl } from "react-intl";

const TwiterPublic = ({
  alertDetailMonitorId,
  response,
  dataSources,
  alertDetailType,
  resetAllFields,
  page,
  setPage,
}) => {
  const intl = useIntl();

  const initialWidgetsState = {
    //header alert widget
    headerAlertWidget: {},
    headerAlertWidgetPreLoaderDataSources: [],

    //posts volume widget
    postsVolume: [],
    postsVolumePreLoader: [],
    isPostsVolumeHourlyFormat: false,

    //top keywords widget
    topKeywords: [],
    topKeywordsPreLoaderDataSources: [],

    //top hastags widget
    topHashtags: [],
    topHashtagsPreLoaderDataSources: [],

    //top engaged sites widget
    topEngagedSites: [],
    topEngagedSitesPreLoaderDataSources: [],

    //top engaged sites widget
    topSites: [],
    topSitesPreLoaderDataSources: [],

    //top posts widget
    topPosts: [],
    topPostsPreLoaderDataSources: [],

    //top engagers widget
    topEngagers: [],
    topEngagersPreLoaderDataSources: [],

    //top languages widget
    topLanguage: [],
    topLanguagePreLoaderDataSources: [],

    //engagaments distrbution widget
    engagementDistribtion: [],
    engagementDistribtionPreLoaderDataSources: [],

    //top influncers widget
    topInfleuncers: [],
    topInfleuncersPreLoaderDataSources: [],

    //top verified widget
    topVerifiedEngagers: [],
    topVerifiedEngagersPreLoaderDataSources: [],

    //comments setntiment analysis widget
    commentsSentimentAnalysis: [],
    commentsSentimentAnalysisPreLoaderDataSources: [],

    //dialects & subDialects widget
    dialects: [],
    subDialects: [],
    dialectsSubDialectsPreLoaderDataSources: [],

    //account type widget
    accountTypes: [],
    accountTypesPreLoaderDataSources: [],
  };

  const [alertViewWidgets, setAlertViewWidgets] = useState(initialWidgetsState);

  const [topPostCount, setTopPostCount] = useState("");

  const handleChangePage = (event, page) => {
    if (Boolean(page) === false) return;
    setPage(page);
  };

  useEffect(() => {
    switch (response?.eventName) {
      case "Twitter__NewPostAlert__header_alert_widget":
      case "Twitter__TrendingAlert__header_alert_widget":
      case "Twitter__VerifiedAuthorAlert__header_alert_widget":
      case "Twitter__NegativePostsAlert__header_alert_widget":
      case "Twitter__HighVolumeReachAlert__header_alert_widget":
      case "Twitter__InfluencerAlert__header_alert_widget":
      case "Twitter__ViralTweetAlert__header_alert_widget":
        setAlertViewWidgets({
          ...alertViewWidgets,
          headerAlertWidget: response?.eventData,
          headerAlertWidgetPreLoaderDataSources: [response?.monitor_id],
        });
        break;
      case "Twitter__NewPostAlert__volume_overtime":
      case "Twitter__VerifiedAuthorAlert__volume_overtime":
      case "Twitter__NegativePostsAlert__volume_overtime":
      case "Twitter__TrendingAlert__volume_overtime":
      case "Twitter__HighVolumeReachAlert__volume_overtime":
      case "Twitter__ViralTweetAlert__volume_overtime":
      case "Twitter__InfluencerAlert__volume_overtime":
        setAlertViewWidgets({
          ...alertViewWidgets,
          postsVolume: response?.eventData?.posts_over_time,
          postsVolumePreLoader: [response?.monitor_id],
          isPostsVolumeHourlyFormat: response?.eventData?.is_24_hours_format,
        });
        break;
      case "Twitter__NewPostAlert__top_keywords":
      case "Twitter__VerifiedAuthorAlert__top_keywords":
      case "Twitter__TrendingAlert__top_keywords":
      case "Twitter__NegativePostsAlert__top_keywords":
      case "Twitter__HighVolumeReachAlert__top_keywords":
      case "Twitter__ViralTweetAlert__top_keywords":
      case "Twitter__InfluencerAlert__top_keywords":
        setAlertViewWidgets({
          ...alertViewWidgets,
          topKeywords: response?.eventData?.top_keywords,
          topKeywordsPreLoaderDataSources: [response?.monitor_id],
        });
        break;
      case "Twitter__NewPostAlert__top_hashtags":
      case "Twitter__VerifiedAuthorAlert__top_hashtags":
      case "Twitter__TrendingAlert__top_hashtags":
      case "Twitter__NegativePostsAlert__top_hashtags":
      case "Twitter__HighVolumeReachAlert__top_hashtags":
      case "Twitter__ViralTweetAlert__top_hashtags":
      case "Twitter__InfluencerAlert__top_hashtags":
        setAlertViewWidgets({
          ...alertViewWidgets,
          topHashtags: response?.eventData?.top_hashtags,
          topHashtagsPreLoaderDataSources: [response?.monitor_id],
        });
        break;

      case "Twitter__NewPostAlert__top_posts_comments":
      case "Twitter__VerifiedAuthorAlert__top_posts_comments":
      case "Twitter__TrendingAlert__top_posts_comments":
      case "Twitter__NegativePostsAlert__top_posts_comments":
      case "Twitter__HighVolumeReachAlert__top_posts_comments":
      case "Twitter__ViralTweetAlert__top_posts_comments":
      case "Twitter__InfluencerAlert__top_posts_comments":
        setAlertViewWidgets({
          ...alertViewWidgets,
          topPosts: response?.eventData?.top_posts,
          topPostsPreLoaderDataSources: [response?.monitor_id],
        });
        if (page == 1 && response?.eventData?.top_posts_count) {
          setTopPostCount(response?.eventData?.top_posts_count);
        }
        break;
      case "Twitter__NewPostAlert__top_engagers":
      case "Twitter__VerifiedAuthorAlert__top_engagers":
      case "Twitter__TrendingAlert__top_engagers":
      case "Twitter__NegativePostsAlert__top_engagers":
      case "Twitter__HighVolumeReachAlert__top_engagers":
      case "Twitter__ViralTweetAlert__top_engagers":
      case "Twitter__InfluencerAlert__top_engagers":
        setAlertViewWidgets({
          ...alertViewWidgets,
          topEngagers: response?.eventData?.top_engagers,
          topEngagersPreLoaderDataSources: [response?.monitor_id],
        });
        break;
      case "Twitter__NewPostAlert__top_verified_engagers":
      case "Twitter__TrendingAlert__top_verified_engagers":
      case "Twitter__VerifiedAuthorAlert__top_verified_engagers":
      case "Twitter__NegativePostsAlert__top_verified_engagers":
      case "Twitter__HighVolumeReachAlert__top_verified_engagers":
      case "Twitter__ViralTweetAlert__top_verified_engagers":
      case "Twitter__InfluencerAlert__top_verified_engagers":
        setAlertViewWidgets({
          ...alertViewWidgets,
          topVerifiedEngagers: response?.eventData?.top_verified_engagers,
          topVerifiedEngagersPreLoaderDataSources: [response?.monitor_id],
        });
        break;
      case "Twitter__NewPostAlert__top_influencers":
      case "Twitter__VerifiedAuthorAlert__top_influencers":
      case "Twitter__TrendingAlert__top_influencers":
      case "Twitter__NegativePostsAlert__top_influencers":
      case "Twitter__HighVolumeReachAlert__top_influencers":
      case "Twitter__ViralTweetAlert__top_influencers":
      case "Twitter__InfluencerAlert__top_influencers":
        setAlertViewWidgets({
          ...alertViewWidgets,
          topInfleuncers: response?.eventData?.top_influencers,
          topInfleuncersPreLoaderDataSources: [response?.monitor_id],
        });
        break;
      case "Twitter__NewPostAlert__account_types":
      case "Twitter__VerifiedAuthorAlert__account_types":
      case "Twitter__TrendingAlert__account_types":
      case "Twitter__NegativePostsAlert__account_types":
      case "Twitter__HighVolumeReachAlert__account_types":
      case "Twitter__ViralTweetAlert__account_types":
      case "Twitter__InfluencerAlert__account_types":
        setAlertViewWidgets({
          ...alertViewWidgets,
          accountTypes: response?.eventData?.account_types,
          accountTypesPreLoaderDataSources: [response?.monitor_id],
        });
        break;
      case "Twitter__NewPostAlert__dialects_and_sub_dialects":
      case "Twitter__VerifiedAuthorAlert__dialects_and_sub_dialects":
      case "Twitter__TrendingAlert__dialects_and_sub_dialects":
      case "Twitter__NegativePostsAlert__dialects_and_sub_dialects":
      case "Twitter__HighVolumeReachAlert__dialects_and_sub_dialects":
      case "Twitter__ViralTweetAlert__dialects_and_sub_dialects":
      case "Twitter__InfluencerAlert__dialects_and_sub_dialects":
        setAlertViewWidgets({
          ...alertViewWidgets,
          dialects: response?.eventData?.dialects,
          subDialects: response?.eventData?.sub_dialects,
          dialectsSubDialectsPreLoaderDataSources: [response?.monitor_id],
        });
        break;
      case "Twitter__NewPostAlert__sentiment_analysis":
      case "Twitter__VerifiedAuthorAlert__sentiment_analysis":
      case "Twitter__TrendingAlert__sentiment_analysis":
      case "Twitter__NegativePostsAlert__sentiment_analysis":
      case "Twitter__HighVolumeReachAlert__sentiment_analysis":
      case "Twitter__ViralTweetAlert__sentiment_analysis":
      case "Twitter__InfluencerAlert__sentiment_analysis":
        setAlertViewWidgets({
          ...alertViewWidgets,
          commentsSentimentAnalysis: response?.eventData?.sentiment_piechart,
          commentsSentimentAnalysisPreLoaderDataSources: [response?.monitor_id],
        });
        break;

      default:
        break;
    }
  }, [response]);

  useEffect(() => {
    // Avoid Initial State Calling
    if (resetAllFields !== 1) {
      setAlertViewWidgets({ ...initialWidgetsState });
      setPage(1);
    }
  }, [intl.locale]);

  useEffect(() => {
    // check if posts have pagination to load posts only not all page
    if (page >= 1) {
      setAlertViewWidgets({
        ...alertViewWidgets,
        topPosts: [],
        topPostsPreLoaderDataSources: [],
      });
    }
  }, [page]);

  useEffect(() => {
    // Avoid Initial State Calling
    if (resetAllFields !== 1) {
      setAlertViewWidgets({ ...alertViewWidgets });
    }
  }, [resetAllFields]);

  return (
    <>
      <AlertStatistics
        alertDataSource={"twitter"}
        headerAlertWidget={alertViewWidgets?.headerAlertWidget}
        headerAlertWidgetPreLoaderDataSources={
          alertViewWidgets?.headerAlertWidgetPreLoaderDataSources
        }
        preLoaderTrackerDataSources={alertDetailMonitorId}
        isCA
        alertDetailType={alertDetailType}
      />
      <Box mb={3}>
        <LineChartWidget
          title={"alerts_posts_overtime"}
          toolTipLabel={"total_interactions"}
          data={alertViewWidgets?.postsVolume}
          showDataZoom={false}
          lineChartPreLoaderDataSources={alertViewWidgets?.postsVolumePreLoader}
          preLoaderTrackerDataSources={alertDetailMonitorId}
          checkSymbolZize
          isHourlyFormat={alertViewWidgets?.isPostsVolumeHourlyFormat}
        />
      </Box>
      <Box mb={5} className="new-posts-countiner">
        <SectionTitle sectionTitle="alert_new_posts_section" />
        <InteractionsList
          data={alertViewWidgets?.topPosts?.slice(0, 6)}
          dataSources={dataSources}
          latestInteractionsPreLoaderDataSources={
            alertViewWidgets?.topPostsPreLoaderDataSources
          }
          preLoaderTrackerDataSources={alertDetailMonitorId}
        />
        {topPostCount !== 0 &&
        topPostCount > 10 &&
        alertViewWidgets?.topPosts?.length > 0 ? (
          <Pagination
            onChange={handleChangePage}
            page={page}
            className="pagination-alert-detail"
            count={Math.ceil(topPostCount / 10)}
            variant="outlined"
          />
        ) : null}
      </Box>
      <SectionTitle sectionTitle="metrics" />
      <Grid container spacing={2.5}>
        <Grid item xs={4}>
          <PieChartWidget
            title={"alert_sentiment_analysis"}
            data={alertViewWidgets?.commentsSentimentAnalysis}
            areaStyle={true}
            showDownloadIcon
            chartType={"sentiment"}
            pieChartPreLoaderDataSources={
              alertViewWidgets?.commentsSentimentAnalysisPreLoaderDataSources
            }
            preLoaderTrackerDataSources={alertDetailMonitorId}
            showAI
            color={["#89BB2A", "#E50035", "#F9A700"]}
          />
        </Grid>

        <Grid item xs={4}>
          <PieChartWidget
            title={"dialects_subdialects"}
            data={alertViewWidgets?.dialects}
            subData={alertViewWidgets?.subDialects}
            areaStyle={true}
            showAI
            showDownloadIcon
            pieChartPreLoaderDataSources={
              alertViewWidgets?.dialectsSubDialectsPreLoaderDataSources
            }
            preLoaderTrackerDataSources={alertDetailMonitorId}
          />
        </Grid>

        <Grid item xs={4}>
          <PieChartWidget
            title={"account_types"}
            data={alertViewWidgets?.accountTypes}
            areaStyle={true}
            showDownloadIcon
            pieChartPreLoaderDataSources={
              alertViewWidgets?.accountTypesPreLoaderDataSources
            }
            preLoaderTrackerDataSources={alertDetailMonitorId}
            showAI
          />
        </Grid>
      </Grid>
      <Grid container spacing={2.5} mt={4} mb={4}>
        <Grid item md={6}>
          <TableWidget
            wordsList
            title={"alert_top_keywords"}
            leftColomnTitle={"keywords_tab_title"}
            rightColomnTitle={"number_of_posts"}
            data={alertViewWidgets?.topKeywords}
            errorMessage={"no_keywords_found"}
            showDownloadIcon
            bigImg
            keywords
            tablePagination
            count={alertViewWidgets?.topKeywords?.length}
            preLoaderTrackerDataSources={alertDetailMonitorId}
            tableChartPreLoaderDataSources={
              alertViewWidgets?.topKeywordsPreLoaderDataSources
            }
            withWordCloud
          />
        </Grid>
        <Grid item md={6}>
          <TableWidget
            wordsList
            title={"alert_top_hashtags"}
            leftColomnTitle={"hashtags"}
            rightColomnTitle={"number_of_posts"}
            data={alertViewWidgets?.topHashtags}
            errorMessage={"no_hashtags_found"}
            showDownloadIcon
            bigImg
            keywords
            tablePagination
            count={alertViewWidgets?.topHashtags?.length}
            preLoaderTrackerDataSources={alertDetailMonitorId}
            tableChartPreLoaderDataSources={
              alertViewWidgets?.topHashtagsPreLoaderDataSources
            }
            withWordCloud
          />
        </Grid>
      </Grid>
      <Grid container spacing={2.5}>
        <Grid item xs={4}>
          <TableWidget
            circularPP={true}
            itemsList
            title={"alert_most_engaged_contacts"}
            data={alertViewWidgets?.topEngagers}
            leftColomnTitle={"users"}
            rightColomnTitle={"engagements"}
            tableChartPreLoaderDataSources={
              alertViewWidgets?.topEngagersPreLoaderDataSources
            }
            preLoaderTrackerDataSources={alertDetailMonitorId}
            tablePagination
            count={alertViewWidgets?.topEngagers?.length}
            showDownloadIcon
          />
        </Grid>
        <Grid item xs={4}>
          <TableWidget
            circularPP={true}
            itemsList
            title={"alert_most_influential_contacts"}
            data={alertViewWidgets?.topInfleuncers}
            leftColomnTitle={"user"}
            rightColomnTitle={"followers"}
            tableChartPreLoaderDataSources={
              alertViewWidgets?.topInfleuncersPreLoaderDataSources
            }
            preLoaderTrackerDataSources={alertDetailMonitorId}
            tablePagination
            count={alertViewWidgets?.topInfleuncers?.length}
            showDownloadIcon
          />
        </Grid>
        <Grid item xs={4}>
          <TableWidget
            circularPP={true}
            itemsList
            title={"alert_verified_contacts"}
            data={alertViewWidgets?.topVerifiedEngagers}
            leftColomnTitle={"users"}
            rightColomnTitle={"followers"}
            tableChartPreLoaderDataSources={
              alertViewWidgets?.topVerifiedEngagersPreLoaderDataSources
            }
            preLoaderTrackerDataSources={alertDetailMonitorId}
            tablePagination
            count={alertViewWidgets?.topVerifiedEngagers?.length}
            showDownloadIcon
          />
        </Grid>
      </Grid>
    </>
  );
};

export default TwiterPublic;
