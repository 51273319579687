import { useIntl } from "react-intl";
import { Box, TextField, Typography } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import TextDirectionSwitcher from "pages/SurveyBuilder/EditSection/textDirectionSwitcher";

const SectionHeader = (props) => {
  const {
    section,
    sectionIndex,
    emptySection,
    secTitleErr,
    handleSectionTitle,
    filteredSections,
    handleSectionDir,
    hideNumber,
    previewMode,
  } = props;

  const intl = useIntl();

  const titleError =
    emptySection === sectionIndex || secTitleErr === sectionIndex;

  return (
    <Box>
      <Box
        id={`section-header-${sectionIndex}`}
        className={`survey-section-header ${previewMode ? "preview-mode" : ""} ${
          titleError ? "section-error" : ""
        }`}
      >
        {!hideNumber && !previewMode ? (
          <Box className="section-number">
            {CheckValueLocale(
              "section_of",
              "",
              { num1: sectionIndex + 1, num2: filteredSections?.length },
              intl,
            )}
          </Box>
        ) : null}

        <Box className="survey-section-title">
          {previewMode ? (
            <Typography className="preview-survey-section-title">
              {section?.name || ""}
            </Typography>
          ) : (
            <TextField
              variant="standard"
              id={`survey-section-title-${sectionIndex}`}
              className="survey-section-title-textfield"
              onChange={(e) => handleSectionTitle(e, sectionIndex)}
              value={section?.name}
            />
          )}

          {!previewMode ? (
            <TextDirectionSwitcher
              handleChangeDir={handleSectionDir}
              value={filteredSections?.[sectionIndex]?.text_direction}
              sectionIndex={sectionIndex}
            />
          ) : null}
        </Box>
      </Box>

      {titleError && !previewMode ? (
        <Box mt={1}>
          <Typography component="h1" className="empty-survey-section-error-msg">
            {CheckValueLocale(
              emptySection === sectionIndex
                ? "empty_section_error_msg"
                : secTitleErr === sectionIndex
                  ? "empty_section_title_error_msg"
                  : null,
              "",
              {},
              intl,
            )}
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};

export default SectionHeader;
