import { useMutation } from "@tanstack/react-query";
import SettingsController from "services/controllers/settingsController";

export const useEditTwoFaMethods = () => {
  return useMutation({
    mutationFn: async (saveData) => {
      return await SettingsController?.editTwoFaMethods(saveData);
    },
  });
};
