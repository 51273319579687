import { Tooltip, Typography, Box, Grid } from "@mui/material";
import FetchingData from "components/fetchingData/fetchingData";
import NoDataFound from "components/no-Data/noDataFound";
import "./monitorStatistics.scss";

const formatValue = (value, preserveDecimals = false) => {
  if (value === null || value === undefined) {
    return "0";
  }

  const numValue =
    typeof value === "string"
      ? Number(value?.replace(/,/g, ""))
      : Number(value);

  if (isNaN(numValue)) return "0";
  if (numValue === 0) return "0";

  return numValue?.toLocaleString(undefined, {
    minimumFractionDigits: preserveDecimals ? 1 : 0,
    maximumFractionDigits: preserveDecimals ? 1 : 0,
  });
};

export const MonitorStatistics = ({ data }) => {
  const isAnyItemLoading = data?.some((item) => !item?.isLoading);
  const isAllItemsEmpty = data?.every((item) => {
    const value = formatValue(item?.value);
    return !value || value === "0" || value === "0.0";
  });

  const shouldPreserveDecimals = (metric) => {
    const title = metric?.title?.toLowerCase();
    return title?.includes("average") || title?.includes("متوسط");
  };

  return (
    <Grid container className="monitor-statistics-grid-container">
      {isAnyItemLoading ? (
        <Grid item xs={12} className="monitor-statistics-loading-grid-item">
          <FetchingData />
        </Grid>
      ) : isAllItemsEmpty ? (
        <Grid item xs={12} className="monitor-statistics-loading-grid-item">
          <NoDataFound />
        </Grid>
      ) : (
        data?.map((metric, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={index}
            className={`monitor-statistics-metric-grid-item ${
              index < 3 ? "monitor-statistics-with-border" : ""
            }`}
          >
            <Box className="monitor-statistics-metric-box">
              <Box className="monitor-statistics-icon-box">{metric?.icon}</Box>
              <Box className="monitor-statistics-text-container">
                <Tooltip
                  classes={{ tooltip: "monitor-statistics-tooltip" }}
                  arrow
                  title={metric?.tooltipTitle}
                  placement="top"
                >
                  <Typography className="monitor-statistics-metric-title">
                    {metric?.title}
                  </Typography>
                </Tooltip>
                <Typography className="monitor-statistics-metric-value">
                  {formatValue(metric?.value, shouldPreserveDecimals(metric))}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))
      )}
    </Grid>
  );
};
