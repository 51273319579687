import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
// import UpdateIcon from "@mui/icons-material/Update";
import CommonFunctions from "pages/Monitors/Components/commonFunctions";
import PopupModal from "components/popupModal";
import SnackBar from "components/snackBar";

export const SinglePostAnalysisHeader = () =>
  // {
  // monitorID,
  // monitorName,
  // screenName,
  // trackerID,
  // createdAt,
  // tweetID,
  // },
  {
    const intl = useIntl();
    // const links = [
    //   {
    //     name: "monitors_list",
    //     url: "social/monitor_list",
    //     addNameLocal: true,
    //   },
    //   {
    //     name: `${monitorID} - ${monitorName}`,
    //     addNameLocal: false,
    //     disableURL: true,
    //   },
    // ];

    const {
      // updateAnalysis,
      handleCloseSnackBar,
      showAnalyzeTweetSnackBar,
      analyzeTweetSnackBar,
      stats,
      handleCloseCreateModal,
      modalBody,
    } = CommonFunctions();

    // const handleOpenSPAModal = () => {
    //   updateAnalysis(tweetID, screenName, trackerID, createdAt);
    // };

    // const hasSingleTweetSubscription = handlelUserRoles(
    //   "SM",
    //   "CREATE_POST_ANALYSIS",
    // );
    return (
      <Box className="spa-main-header-container">
        <Box>
          {/* <BreadCrumb links={links} /> */}
          <Typography className="spa-main-header-title">
            {CheckValueLocale("single_tweet_analysis", "", {}, intl)}
          </Typography>
        </Box>
        {/* <Box className="spa-main-header-action-container">
        {hasSingleTweetSubscription ? (
          <LucButton
            startIcon={<UpdateIcon />}
            onClick={handleOpenSPAModal}
            size="large"
          >
            {CheckValueLocale("update_analysis", "", {}, intl)}
          </LucButton>
        ) : null}
        <Typography className="spa-main-header-data-text">
          Last update: (an hour ago) 09/10/2024, 5:06 AM (GMT+3)
        </Typography>
      </Box> */}
        <PopupModal
          title={CheckValueLocale("single_tweet_analysis", "", {}, intl)}
          body={modalBody}
          open={stats?.openCreateModal}
          close={handleCloseCreateModal}
          warning="warning"
          classeName="create-historical-popup"
          addClasses="create-single-post-analysis-modal"
        />
        {showAnalyzeTweetSnackBar && (
          <SnackBar
            open={true}
            handleClose={handleCloseSnackBar}
            title={CheckValueLocale(analyzeTweetSnackBar.title, "", {}, intl)}
            message={CheckValueLocale(
              analyzeTweetSnackBar.message,
              "",
              {},
              intl,
            )}
            severity={analyzeTweetSnackBar.severity}
          />
        )}
      </Box>
    );
  };
