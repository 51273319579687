import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import LineChartWidget from "components/widgets/lineChartWidget";
// import "pages/Monitors/monitors.scss";
import PunchCardWidget from "components/widgets/punchCardWidget";
import StackedBarWidget from "components/widgets/stackedBarWidget";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import QuestionCard from "pages/Monitors/Components/questionCard";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { handleUnixFormat, CheckValueLocale } from "utils/helpers";
import { faComments, faPercent } from "@fortawesome/free-solid-svg-icons";
import { faChartMixed } from "@fortawesome/pro-solid-svg-icons";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MonitorStatistics } from "components/widgets/monitorStatistics";

/*---------------------------------------------------------*/
const PublicFBAccountBody = (props) => {
  const intl = useIntl();
  const { monitorType, monitorDataSource, monitorId } = useParams();
  let { response } = props;

  const monitorDetails = props.monitorData;

  //handle response
  const [postsVolume, setPostsVolume] = useState([]);
  const [totalPostsVolumeCount, setTotalPostsVolumeCount] = useState([]);

  const [topPosts, setTopPosts] = useState([]);
  const [topPostsCount, setTopPostsCount] = useState(0);
  const [topPostsPreLoaderDataSources, setTopPostsPreLoaderDataSources] =
    useState([]);

  const [authorActivity, setAuthorActivity] = useState([]);
  const [minValueAuthorActivity, setMinValueAuthorActivity] = useState(0);
  const [maxValueAuthorActivity, setMaxValueAuthorActivity] = useState(0);

  const [postsInteractions, setPostsInteractions] = useState([]);
  const [postsInteractionsLegend, setPostsInteractionsLegend] = useState([]);
  const [maxValuePostsInteractions, setMaxValuePostsInteractions] = useState(0);
  const [minValuePostsInteractions, setMinValuePostsInteractions] = useState(0);
  const [avgValuePostsInteractions, setAvgValuePostsInteractions] = useState(0);
  const [maxValueInteractionsDate, setMaxValueInteractionsDate] = useState(0);
  const [minValueInteractionsDate, setMinValueInteractionsDate] = useState(0);

  // Values of DataSources totrack each widget preloader
  const [postsVolumePreLoaderDataSources, setPostsVolumePreLoaderDataSources] =
    useState([]);

  const [
    authorActivityPreLoaderDataSources,
    setAuthorActivityPreLoaderDataSources,
  ] = useState([]);
  const [
    postsInteractionsPreLoaderDataSources,
    setPostsInteractionsPreLoaderDataSources,
  ] = useState([]);

  // ___________________________________________________________
  const [reactions, setReactions] = useState([]);
  const [reactionsPreLoader, setReactionsPreLoader] = useState([]);
  const [pageReactionPieChartValue, setPageReactionPieChartValue] = useState(
    [],
  );

  const [topKeywords, setTopKeywords] = useState([]);
  const [topKeywordsPreLoader, setTopKeywordsPreLoader] = useState([]);

  // _____________________________________________
  const [topHashtags, setTopHashtags] = useState([]);
  const [topHashtagsPreLoader, setTopHashtagsPreLoader] = useState([]);

  //handle response with useEffect (when load page & any update)
  const handleResponseFunc = async () => {
    switch (response.eventName) {
      case "Facebook__AccountPage__volume_overtime":
        setPostsVolume(response.eventData.posts_over_time);
        setTotalPostsVolumeCount(response.eventData.total_posts_count);
        setPostsVolumePreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Facebook__AccountPage__reactions":
        setReactions(
          response.eventData.posts_reactions.posts_reactions_over_time,
        );
        setReactionsPreLoader((oldArray) => [...oldArray, response.monitor_id]);
        setPageReactionPieChartValue(
          response.eventData.posts_reactions.posts_reactions_piechart,
        );
        break;
      case "Facebook__AccountPage__account_author_activity":
        setMinValueAuthorActivity(response.eventData.min_value);
        setMaxValueAuthorActivity(response.eventData.max_value);
        setAuthorActivity(response.eventData.account_author_activity);
        setAuthorActivityPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Facebook__AccountPage__posts_interactions":
        setPostsInteractions(
          response.eventData.posts_interactions.posts_interactions_over_time,
        );
        setPostsInteractionsLegend(
          response.eventData.posts_interactions.posts_interactions_legends,
        );
        setMaxValuePostsInteractions(
          response.eventData.posts_interactions.max_interactions,
        );
        setMinValuePostsInteractions(
          response.eventData.posts_interactions.min_interactions,
        );
        setAvgValuePostsInteractions(
          response.eventData.posts_interactions.average_interactions,
        );
        let date = handleUnixFormat(
          response.eventData.posts_interactions.max_interaction_date,
        );
        setMaxValueInteractionsDate(date);
        date = handleUnixFormat(
          response.eventData.posts_interactions.min_interaction_date,
        );
        setMinValueInteractionsDate(date);
        setPostsInteractionsPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Facebook__AccountPage__top_keywords":
        setTopKeywords(response.eventData.top_keywords);
        setTopKeywordsPreLoader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Facebook__AccountPage__top_hashtags":
        setTopHashtags(response.eventData.top_hashtags);
        setTopHashtagsPreLoader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Facebook__AccountPage__top_posts_comments":
        setTopPosts(response.eventData.top_posts);
        setTopPostsCount(response.eventData.top_posts_count);
        setTopPostsPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    handleResponseFunc();
  }, [response]);
  //_____________________________________________________________________________________
  //getting Data to export xls sheet
  useEffect(() => {
    props.setExcelSheetData([
      {
        authorActivity: [
          authorActivity,
          maxValueAuthorActivity,
          minValueAuthorActivity,
        ],
      },
      { postsVolume: postsVolume },
      { postsInteractions: postsInteractions },
      { reactions: reactions },
      { topKeywords: topKeywords },
      { topHashtags: topHashtags },
      { topPosts: topPosts },
    ]);
  }, [
    authorActivity,
    postsVolume,
    postsInteractions,
    reactions,
    topKeywords,
    topHashtags,
    topPosts,
  ]);
  //_____________________________________________________________________________________
  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (props.resetAllFields !== 1) {
      setPostsVolume([]);
      setTotalPostsVolumeCount([]);
      setPostsVolumePreLoaderDataSources([]);

      setTopKeywords([]);
      setTopKeywordsPreLoader([]);

      setTopHashtags([]);
      setTopHashtagsPreLoader([]);

      setTopPosts([]);
      setTopPostsCount(0);
      setTopPostsPreLoaderDataSources([]);

      setPostsInteractions([]);
      setPostsInteractionsLegend([]);
      setPostsInteractionsPreLoaderDataSources([]);
      setMaxValuePostsInteractions(0);
      setMinValuePostsInteractions(0);
      setAvgValuePostsInteractions(0);
      setMaxValueInteractionsDate(0);
      setMinValueInteractionsDate(0);

      setMinValueAuthorActivity(0);
      setMaxValueAuthorActivity(0);
      setAuthorActivity([]);
      setAuthorActivityPreLoaderDataSources([]);

      setReactions([]);
      setReactionsPreLoader([]);
      setPageReactionPieChartValue([]);
    }
  }, [props.resetAllFields]);

  useEffect(() => {
    let totalPreLoaderDataSouces = [];

    totalPreLoaderDataSouces.push(
      postsVolumePreLoaderDataSources,
      reactionsPreLoader,
      authorActivityPreLoaderDataSources,
      postsInteractionsPreLoaderDataSources,
      topKeywordsPreLoader,
      topHashtagsPreLoader,
      topPostsPreLoaderDataSources,
    );

    props.checkAllDataFounded(
      totalPreLoaderDataSouces,
      props.preLoaderTrackerDataSources,
    );

    props.checkDataIsFounded(
      authorActivity,
      postsVolume,
      postsInteractions,
      reactions,
      topKeywords,
      topHashtags,
      topPosts,
    );
  }, [
    authorActivity,
    postsVolume,
    postsInteractions,
    reactions,
    topKeywords,
    topHashtags,
    topPosts,
    postsVolumePreLoaderDataSources,
    reactionsPreLoader,
    authorActivityPreLoaderDataSources,
    postsInteractionsPreLoaderDataSources,
    topKeywordsPreLoader,
    topHashtagsPreLoader,
    topPostsPreLoaderDataSources,
  ]);

  const totalEngagement = postsInteractionsLegend?.reduce(
    (acc, curr) => acc + curr.value,
    0,
  );

  const monitorStatistics = [
    {
      title: CheckValueLocale(
        "monitor_statistics_fb_top_posts_title",
        "",
        {},
        intl,
      ),
      icon: (
        <FontAwesomeIcon
          className="monitor-widget-summary-icon"
          icon={faChartMixed}
        />
      ),
      tooltipTitle: CheckValueLocale(
        "monitor_statistics_fb_top_posts_tooltip",
        "",
        {},
        intl,
      ),
      value: totalPostsVolumeCount?.toLocaleString(),
      isLoading: _.isEqual(
        postsVolumePreLoaderDataSources?.sort(),
        props?.preLoaderTrackerDataSources?.sort(),
      ),
    },
    {
      title: CheckValueLocale(
        "monitor_statistics_fb_total_engagements_title",
        "",
        {},
        intl,
      ),
      icon: (
        <FontAwesomeIcon
          className="monitor-widget-summary-icon"
          icon={faComments}
        />
      ),
      tooltipTitle: CheckValueLocale(
        "monitor_statistics_fb_total_engagements_tooltip",
        "",
        {},
        intl,
      ),
      value: totalEngagement?.toLocaleString(),
      isLoading: _.isEqual(
        postsInteractionsPreLoaderDataSources?.sort(),
        props?.preLoaderTrackerDataSources?.sort(),
      ),
    },
    {
      title: CheckValueLocale(
        "monitor_statistics_fb_average_engagement_title",
        "",
        {},
        intl,
      ),
      icon: (
        <FontAwesomeIcon
          className="monitor-widget-summary-icon"
          icon={faPercent}
        />
      ),
      tooltipTitle: CheckValueLocale(
        "monitor_statistics_fb_average_engagement_tooltip",
        "",
        {},
        intl,
      ),
      value: (totalEngagement / totalPostsVolumeCount)?.toLocaleString(
        undefined,
        {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        },
      ),
      isLoading:
        _.isEqual(
          postsVolumePreLoaderDataSources?.sort(),
          props?.preLoaderTrackerDataSources?.sort(),
        ) &&
        _.isEqual(
          postsInteractionsPreLoaderDataSources?.sort(),
          props?.preLoaderTrackerDataSources?.sort(),
        ),
    },
  ];

  return (
    <>
      <Typography className="section-name">
        {CheckValueLocale("overview", "", {}, intl).toUpperCase()}
      </Typography>
      <MonitorStatistics data={monitorStatistics} />

      <Box my={3} className="channel-activity">
        <PunchCardWidget
          title="account_authors_activity"
          titleToolTip="account_authors_activity_fb_tooltip"
          clientActivity={authorActivity}
          minValueClient={minValueAuthorActivity}
          maxValueClient={maxValueAuthorActivity}
          showDownloadIcon
          bigImg
          iconAfterTitle={props.FacebookIcon}
          authorActivityPreLoaderDataSources={
            authorActivityPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Box>
      <Box mt={3}>
        <Typography className="section-name-secondary">
          {CheckValueLocale("posts_analytics", "", {}, intl).toUpperCase()}
        </Typography>
      </Box>
      <Box mt={3} className="posts-volume-chart">
        <LineChartWidget
          title={"posts_volume"}
          titleToolTip="posts_volume_fb_tooltip"
          totalLabel={"posts"}
          totalValue={totalPostsVolumeCount}
          data={postsVolume}
          showGroupBy={true}
          toolTipLabel={"posts"}
          labelColor={"#2AAAE2"}
          showDownloadIcon
          bigImg
          iconAfterTitle={props.FacebookIcon}
          lineChartPreLoaderDataSources={postsVolumePreLoaderDataSources}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Box>
      <Box mt={3}>
        <StackedBarWidget
          title={"posts_interactions"}
          titleToolTip="posts_interactions_fb_tooltip"
          showDownloadIcon
          bigImg
          posts={postsInteractions}
          showChartDetailsWidget
          showScales
          statsDetailsData={[
            {
              name: "max_interaction",
              value: maxValuePostsInteractions,
              date: maxValueInteractionsDate,
            },
            {
              name: "min_interaction",
              value: minValuePostsInteractions,
              date: minValueInteractionsDate,
            },
            {
              name: "avg_interaction_per_day",
              value: avgValuePostsInteractions,
            },
          ]}
          colors={["#2AAAE2", "#80868C", "#334155"]}
          labels={["comments", "reactions"]}
          showPostsInteractionStats
          showZoomPercentage
          iconAfterTitle={props.FacebookIcon}
          postsColumnPreLoaderDataSources={
            postsInteractionsPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          hideLegend
          dataPiechart={postsInteractionsLegend}
        />
      </Box>
      <Box my={3}>
        <StackedBarWidget
          title={"posts_reactions"}
          titleToolTip="posts_reactions_fb_tooltip"
          showDownloadIcon
          bigImg
          posts={reactions}
          showZoomPercentage
          iconAfterTitle={props.FacebookIcon}
          postsColumnPreLoaderDataSources={reactionsPreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          showGroupByClientAgent={true}
          showScales
          labels={[
            "like_reaction",
            "love_reaction",
            "care_reaction",
            "haha_reaction",
            "wow_reaction",
            "angry_reaction",
            "sad_reaction",
          ]}
          hideLegend
          dataPiechart={pageReactionPieChartValue}
          colors={[
            "#1892F2",
            "#FB5C77",
            "#F7B94D",
            "#FEDC5F",
            "#00D7B5",
            "#EC8A92",
            "#1C404E",
          ]}
        />
      </Box>
      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TableWidget
              wordsList
              title={"posts_top_keywords"}
              titleToolTip="posts_top_keywords_fb_tooltip"
              data={topKeywords}
              leftColomnTitle={"keywords"}
              rightColomnTitle={"number_of_posts"}
              tableChartPreLoaderDataSources={topKeywordsPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              keywords
              tablePagination
              count={topKeywords.length}
              iconAfterTitle={props.FacebookIcon}
              showDownloadIcon
              withWordCloud
            />
          </Grid>
          <Grid item xs={6}>
            <TableWidget
              wordsList
              title={"posts_top_hashtags"}
              titleToolTip="posts_top_hashtags_fb_tooltip"
              data={topHashtags}
              leftColomnTitle={"hashtags"}
              rightColomnTitle={"number_of_posts"}
              tableChartPreLoaderDataSources={topHashtagsPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              hashtags
              tablePagination
              count={topHashtags.length}
              iconAfterTitle={props.FacebookIcon}
              showDownloadIcon
              withWordCloud
            />
          </Grid>
        </Grid>
      </Box>
      <Box className="top-posts-head">
        <Box>{CheckValueLocale("top_posts", "", {}, intl)}</Box>
        <Link
          to={{
            pathname: `/monitor/${monitorType}/${monitorDataSource}/posts/${monitorId}`,
            state: monitorDetails,
          }}
          className="top-posts-all"
          id={`check-all-posts-${
            monitorType?.toLowerCase() ?? "monitor-type"
          }-${
            monitorDataSource?.toLowerCase() ?? "monitor-data-source"
          }-posts-${monitorId ?? "monitor-id"}`}
        >
          {CheckValueLocale("check_all_posts", "", {}, intl)} ({topPostsCount})
        </Link>
      </Box>
      <Box>
        <QuestionCard
          latestUnanswer={topPosts}
          latestUnanswerPreloader={topPostsPreLoaderDataSources}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          icon={faFacebook}
          iconColor={"facebook_icon"}
          showHeart={true}
          showDefaultImg={true}
          className={"no-data-found-question"}
          hideChartBar
          widget={"top_posts"}
          hideChangeSentiment
        />
      </Box>
    </>
  );
};

export default PublicFBAccountBody;
