import { useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { Box, FormControl, TextField, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { faX } from "@fortawesome/pro-regular-svg-icons";
import { checkKeyboardLanguage, CheckValueLocale } from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";
import SectionContainer from "pages/SurveyBuilder/EditSection/components/section/sectionContainer";
import AnsweringFooter from "pages/SurveyBuilder/PreviewSurvey/components/answeringFooter/answeringFooter";
import "./style.scss";

const SurveyPopup = (props) => {
  const {
    openedPopup,
    setOpenedPopup,
    surveyData,
    setSurveyData,
    filteredSections,
    ssIndex,
    embeddedRef,
    previewMode,
    submitted,
    progressValue,
  } = props;

  const intl = useIntl();

  const inputRef = useRef(null);

  useEffect(() => {
    if (
      surveyData?.title === "Untitled Survey" ||
      surveyData?.title === "نموذج بدون عنوان"
    ) {
      inputRef?.current?.focus();
      inputRef?.current?.select();
    }
  }, []);

  const popupSize = surveyData?.embedded_config?.embed_options?.embed_size;

  const popupRadius = surveyData?.embedded_config?.popup_style?.corner_radius;

  const popupPosition =
    surveyData?.embedded_config?.embed_options?.type === "button"
      ? "center"
      : surveyData?.embedded_config?.embed_options?.embed_position;

  const hideSectionsHeader =
    surveyData?.embedded_config?.hide_sections_header?.enabled;

  const showProgress = surveyData?.embedded_config?.progress_bar?.enabled;

  if (!openedPopup) return null; // To avoid rendering Popup component if openedPopup is falsy

  return (
    <Box
      id="embedded-survey-popup-wrapper"
      className={`embedded-${popupSize}-popup ${previewMode ? "preview-mode" : ""} ${popupPosition} ${popupRadius}`}
    >
      <Box className="embedded-survey-popup" ref={embeddedRef}>
        <Box
          className={`embedded-survey-popup-content ${showProgress && filteredSections?.length > 1 ? `progress-bar ${surveyData?.text_direction || ""}` : ""}`}
          sx={{
            "&::after": {
              width: previewMode ? `${progressValue || 0}%` : "50%",
              backgroundColor:
                surveyData?.embedded_config?.popup_style?.primary_color,
            },
          }}
        >
          <Box
            className={`embedded-popup-header ${surveyData?.text_direction || (intl?.locale === "ar" ? "rtl" : "ltr")}`}
          >
            {previewMode ? (
              <Typography className="survey-popup-preview-title">
                {surveyData?.title}
              </Typography>
            ) : (
              <FormControl id="embedded-popup-title-control">
                <TextField
                  id="embedded-popup-title-input"
                  inputRef={inputRef}
                  variant="standard"
                  style={{
                    direction: checkKeyboardLanguage(surveyData?.title)
                      ? "rtl"
                      : "ltr",
                  }}
                  value={surveyData?.title}
                  onChange={(e) => {
                    setSurveyData({ ...surveyData, title: e.target.value });
                  }}
                />
              </FormControl>
            )}
            <Box>
              <LucButton
                id="embedded-popup-close-btn"
                type="secondary"
                variant="flat"
                size="small"
                minWidth={36}
                onClick={() => {
                  if (
                    surveyData?.embedded_config?.embed_options?.type ===
                    "button"
                  ) {
                    setOpenedPopup(false);
                  }
                }}
              >
                <FontAwesomeIcon icon={faX} />
              </LucButton>
            </Box>
          </Box>

          <Box className="embedded-popup-body">
            {previewMode && submitted ? (
              <Box id="survey-embedded-submitted-container">
                <FontAwesomeIcon icon={faCircleCheck} fixedWidth />
                <Typography variant="h4">
                  {CheckValueLocale(
                    "thank_you_for_your_participation",
                    "",
                    {},
                    intl,
                  )}
                </Typography>
              </Box>
            ) : (
              <SectionContainer
                {...props}
                section={filteredSections?.[ssIndex - 1]} // ------------------
                sectionIndex={ssIndex - 1} // ------------------
                hideSecHeader={hideSectionsHeader}
                previewMode={previewMode}
              />
            )}
          </Box>

          {previewMode ? <AnsweringFooter {...props} isEmbedded /> : null}
        </Box>
      </Box>
    </Box>
  );
};

export default SurveyPopup;
