import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import LineChartWidget from "components/widgets/lineChartWidget";
import StackedBarWidget from "components/widgets/stackedBarWidget";
import { useIntl } from "react-intl";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import QuestionCard from "../questionCard";
import xPlatform from "images/social-icons/x-platform-white-icon.svg";
import WidgetDownloader from "components/widgets/WidgetDownloader";
import CommonFunctions from "../commonFunctions";
import SnackBar from "components/snackBar";
import {
  handleDates,
  CheckValueLocale,
  extractPathfromURLForIds,
} from "utils/helpers/index";
import PopupModal from "components/popupModal";

const VolumeSentimentModal = (props) => {
  const intl = useIntl();
  let { response, color, quickInspectKeywordMonitor, inspectorValue } = props;

  //handle response
  const [commentsVolume, setCommentsVolume] = useState([]);
  const [totalCommentsVolumeCount, setTotalCommentsVolumeCount] = useState([]);
  // _____________________________________________
  const [commentsInteractions, setCommentsInteractions] = useState([]);
  const [commentsInteractionsPreLoader, setCommentsInteractionsPreLoader] =
    useState([]);
  const [maxInteractions, setMaxInteractions] = useState(0);
  const [totalInteractions, setTotalInteractions] = useState(0);
  const [maxInteractionsDate, setMaxInteractionsDate] = useState("");
  const [avgInteractions, setAvgInteractions] = useState(0);
  //_____________________________________________
  const [topComments, setTopComments] = useState([]);
  const [topCommentsPreLoader, setTopCommentsPreLoader] = useState([]);
  // _____________________________________________

  const [topKeywords, setTopKeywords] = useState([]);
  const [topKeywordsPreLoader, setTopKeywordsPreLoader] = useState([]);
  // _____________________________________________
  const [topHashtags, setTopHashtags] = useState([]);
  const [topHashtagsPreLoader, setTopHashtagsPreLoader] = useState([]);

  const [commentsVolumePreLoader, setCommentsVolumePreLoader] = useState([]);
  const [commentsInteractionChart, setCommentsInteractionChart] = useState([]);

  //handle response with useEffect (when load page & any update)
  const handleResponseFunc = async () => {
    if (props.open) {
      if (
        response.eventName ===
          "Twitter__QuickInspectOverTimePage__hourly_volume" &&
        props.inspectorName !== "sentiment_legend"
      ) {
        setCommentsVolume(response.eventData.overtime_data);
        !commentsVolumePreLoader.includes(response.monitor_id) &&
          setCommentsVolumePreLoader((oldArray) => [
            ...oldArray,
            response.monitor_id,
          ]);
      } else if (
        response.eventName ===
        "Twitter__QuickInspectOverTimePage__volume_overtime"
      ) {
        setCommentsVolume(response.eventData.posts_over_time);
        !commentsVolumePreLoader.includes(response.monitor_id) &&
          setCommentsVolumePreLoader((oldArray) => [
            ...oldArray,
            response.monitor_id,
          ]);
      } else if (
        response.eventName ===
          "Twitter__QuickInspectOverTimePage__posts_interactions" &&
        response?.eventData?.overtime_data
      ) {
        setCommentsInteractions(response.eventData.overtime_data);
        !commentsInteractionsPreLoader.includes(response.monitor_id) &&
          setCommentsInteractionsPreLoader((oldArray) => [
            ...oldArray,
            response.monitor_id,
          ]);
        setCommentsInteractionChart(response.eventData.overtime_count);
        setTotalInteractions(response?.eventData?.total_interactions);
        setAvgInteractions(response?.eventData?.average_interactions);
        setMaxInteractions(response?.eventData?.max_interaction);
        handleDates(
          response?.eventData?.max_interaction_hour,
          setMaxInteractionsDate,
        );
      } else if (
        response.eventName ===
        "Twitter__QuickInspectOverTimePage__top_posts_comments"
      ) {
        setTopComments(response.eventData.top_posts);
        !topCommentsPreLoader.includes(response.monitor_id) &&
          setTopCommentsPreLoader((oldArray) => [
            ...oldArray,
            response.monitor_id,
          ]);
      } else if (
        response.eventName === "Twitter__QuickInspectOverTimePage__top_keywords"
      ) {
        setTopKeywords(response.eventData.top_keywords);
        !topKeywordsPreLoader.includes(response.monitor_id) &&
          setTopKeywordsPreLoader((oldArray) => [
            ...oldArray,
            response.monitor_id,
          ]);
      } else if (
        response.eventName === "Twitter__QuickInspectOverTimePage__top_hashtags"
      ) {
        setTopHashtags(response.eventData.top_hashtags);
        !topHashtagsPreLoader.includes(response.monitor_id) &&
          setTopHashtagsPreLoader((oldArray) => [
            ...oldArray,
            response.monitor_id,
          ]);
      } else {
        return null;
      }
    }
  };
  useEffect(() => {
    handleResponseFunc();
  }, [response]);
  // _____________________________________________

  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (!props.open) {
      setCommentsVolume([]);
      setCommentsVolumePreLoader([]);
      setCommentsInteractions([]);
      setCommentsInteractionsPreLoader([]);
      setMaxInteractions(0);
      setTotalInteractions(0);
      setMaxInteractionsDate("");
      setAvgInteractions(0);
      setTopKeywords([]);
      setTopKeywordsPreLoader([]);
      setTopHashtags([]);
      setTopHashtagsPreLoader([]);
      setTopComments([]);
      setTopCommentsPreLoader([]);
      setCommentsInteractionChart([]);
    }
  }, [props.open]);

  const {
    updateAnalysis,
    handleCloseSnackBar,
    showAnalyzeTweetSnackBar,
    analyzeTweetSnackBar,
    stats,
    handleCloseCreateModal,
    modalBody,
  } = CommonFunctions();

  const getVolumeTitle = () => {
    let data = "";
    props.inspectorName === "sentiment_legend" && quickInspectKeywordMonitor
      ? (data = "posts_daily_volume")
      : props.inspectorName === "sentiment_legend"
        ? (data = "comments_daily_volume")
        : quickInspectKeywordMonitor
          ? (data = "posts_hourly_volume")
          : (data = "comments_hourly_volume");
    return data;
  };

  let interactionsStats = [
    {
      name: "total_interactions",
      value: totalInteractions,
    },
    {
      name: "max_interaction",
      value: maxInteractions,
      date: maxInteractionsDate,
    },
    {
      name: "avg_interaction_per_day",
      value: avgInteractions,
    },
  ];

  return (
    <>
      <Box mt={3}>
        <LineChartWidget
          title={getVolumeTitle()}
          totalValue={totalCommentsVolumeCount}
          data={commentsVolume}
          toolTipLabel={"posts"}
          showDownloadIcon
          bigImg
          lineChartPreLoaderDataSources={commentsVolumePreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          iconAfterTitle={props.twitterIcon}
          showDataZoom={false}
          inspectorName={props.inspectorName}
          quickInspectData
          hideLegend
          lineColor={color}
        />
      </Box>
      <Box mt={3}>
        <StackedBarWidget
          title={
            quickInspectKeywordMonitor
              ? "posts_interactions"
              : "comments_interactions"
          }
          showDownloadIcon
          bigImg
          showScales
          xAxisValue
          showChartDetailsWidget
          isInteractionStatsWrapperStyle
          statsDetailsData={interactionsStats}
          posts={commentsInteractions}
          colors={["#2AAAE2", "#80868C"]}
          labels={["likes", "retweet"]}
          showZoomPercentage
          hideLegend
          iconAfterTitle={props.twitterIcon}
          dataPiechart={commentsInteractionChart}
          postsColumnPreLoaderDataSources={commentsInteractionsPreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Box>

      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TableWidget
              wordsList
              title={
                quickInspectKeywordMonitor
                  ? "posts_top_keywords"
                  : "comments_top_keywords"
              }
              data={topKeywords}
              leftColomnTitle={"keywords"}
              rightColomnTitle={"number_of_posts"}
              tableChartPreLoaderDataSources={topKeywordsPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              keywords
              tablePagination
              showDownloadIcon
              count={topKeywords.length}
              iconAfterTitle={props.twitterIcon}
            />
          </Grid>
          <Grid item xs={6}>
            <TableWidget
              wordsList
              title={
                quickInspectKeywordMonitor
                  ? "posts_top_hashtags"
                  : "comments_top_hashtags"
              }
              data={topHashtags}
              leftColomnTitle={"hashtags"}
              rightColomnTitle={"number_of_posts"}
              tableChartPreLoaderDataSources={topHashtagsPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              hashtags
              tablePagination
              showDownloadIcon
              iconAfterTitle={props.twitterIcon}
              count={topHashtags.length}
            />
          </Grid>
        </Grid>
        <Box mt={3} id="sentiment_overtime">
          <Box className="comments-header">
            <Box className="comments-title">
              {CheckValueLocale(
                quickInspectKeywordMonitor ? "top_posts" : "top_comments",
                "",
                {},
                intl,
              )}
              {props.twitterIcon}
            </Box>
            <WidgetDownloader
              chartId={"sentiment_overtime"}
              bigImg
              downloadBtnId={`widget-downloader-sentiment-over-time-${
                extractPathfromURLForIds(window?.location?.pathname) ??
                "pathname"
              }`}
            />
          </Box>

          <QuestionCard
            latestUnanswer={topComments}
            latestUnanswerPreloader={topCommentsPreLoader}
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
            icon={xPlatform}
            iconColor={"twitter_icon"}
            showRetweet={true}
            showBanIcon={true}
            showScreenName={true}
            quickInspectClass={"quick-inspect-grid"}
            showDefaultImg
            updateAnalysis={updateAnalysis}
            singleTweetAnalysis={props.singleTweetAnalysis}
          />
        </Box>
        {showAnalyzeTweetSnackBar && (
          <SnackBar
            open={true}
            handleClose={handleCloseSnackBar}
            title={CheckValueLocale(analyzeTweetSnackBar.title, "", {}, intl)}
            message={CheckValueLocale(
              analyzeTweetSnackBar.message,
              "",
              {},
              intl,
            )}
            severity={analyzeTweetSnackBar.severity}
          />
        )}
        <PopupModal
          title={CheckValueLocale("single_tweet_analysis", "", {}, intl)}
          body={modalBody}
          open={stats?.openCreateModal}
          close={handleCloseCreateModal}
          warning="warning"
          classeName="create-historical-popup"
          addClasses="create-single-post-analysis-modal"
        />
      </Box>
    </>
  );
};

export default VolumeSentimentModal;
