import { Box, Tooltip, Typography } from "@mui/material";
import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import DOMPurify from "dompurify";

export const AIAgentResponseTime = ({ aiAgentDetails }) => {
  const intl = useIntl();
  return (
    <Box className="ai-agent-response-time-reply-container">
      <FontAwesomeIcon
        className="ai-agent-response-time-reply-text-icon"
        icon={faLock}
      />
      <Typography className="ai-agent-response-time-reply-text">
        {CheckValueLocale("engagement_ai_agent_time_title", "", {}, intl)}
        <Typography
          component="span"
          className="ai-agent-response-time-reply-text time-style"
        >
          {CheckValueLocale(
            aiAgentDetails?.grouping_threshold_time
              ? `ai_agent_response_time_end_after_${aiAgentDetails?.grouping_threshold_time}`
              : "instant_reply",
            "",
            {},
            intl,
          )}
        </Typography>
        {CheckValueLocale("engagement_ai_agent_end_text", "", {}, intl)}
        <Tooltip
          title={
            <>
              <Box
                component="span"
                className="engagement-response-time-tooltip-title"
              >
                {CheckValueLocale("message_grouping", "", {}, intl)}
              </Box>
              <Box
                component="span"
                className="engagement-response-time-tooltip-description"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    CheckValueLocale(
                      "engagement_response_time_tooltip_title",
                      "",
                      {
                        time: CheckValueLocale(
                          aiAgentDetails?.grouping_threshold_time
                            ? `ai_agent_response_time_end_after_${aiAgentDetails?.grouping_threshold_time}`
                            : "instant_reply",
                          "",
                          {},
                          intl,
                        ),
                      },
                      intl,
                    ),
                  ),
                }}
              />
            </>
          }
          arrow
          placement="top"
        >
          <FontAwesomeIcon
            icon={faCircleInfo}
            className="engagement-response-time-info-icon"
          />
        </Tooltip>
      </Typography>
    </Box>
  );
};
