import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const HeaderSectionProfile = ({ titleHeader, descriptionHeader }) => {
  const intl = useIntl();
  return (
    <Box className="header-section-profile">
      <Box className="profile-section-header">
        {CheckValueLocale(titleHeader, "", {}, intl)}
      </Box>
      {descriptionHeader ? (
        <Box className="profile-section-description">
          {CheckValueLocale(descriptionHeader, "", {}, intl)}
        </Box>
      ) : null}
    </Box>
  );
};

export default HeaderSectionProfile;
