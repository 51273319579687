import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import aiAgentsIcon from "images/ai-agents/ai-agents-icon.svg";
import { CheckValueLocale, classNames, handlelUserRoles } from "utils/helpers";
import "./navigationContent.scss";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import PublishIcon from "images/engagements-images/publish-icon.svg";
import CommentIcon from "@mui/icons-material/Comment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { faAnalytics } from "@fortawesome/pro-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";

const SwitchMenuList = ({
  openNavigationMenu,
  setOpenNavigationMenu,
  navigationMenuStatus,
  setNavigationMenuStatus,
  className,
}) => {
  const navigate = useNavigate();

  const showPublish =
    handlelUserRoles("ENGAGEMENT_ACCESS", "PUBLISH") &&
    navigationMenuStatus !== "publish";
  const showInbox =
    handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX") &&
    navigationMenuStatus !== "inbox";
  const showAnalytics =
    (handlelUserRoles("ENGAGEMENT_ACCESS", "FULL_ACCESS") ||
      handlelUserRoles("ENGAGEMENT_ACCESS", "ENGAGEMENT_ANALYTICS")) &&
    navigationMenuStatus !== "analytics";

  var activeProducts = JSON.parse(window.localStorage.activeProducts || null);
  let aiAgentActive = activeProducts?.find(
    (product) => product?.name == "AI_AGENT",
  );
  const showAiAgents =
    handlelUserRoles("AI_AGENT", "VIEW_AI_AGENT_SETTINGS") &&
    handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX") &&
    aiAgentActive &&
    navigationMenuStatus !== "ai_agent";

  const intl = useIntl();
  const handleClickNavigation = (path) => {
    navigate(`/engagements/main/${path}`);
    setOpenNavigationMenu(false);
  };
  return openNavigationMenu ? (
    <ClickAwayListener onClickAway={() => setOpenNavigationMenu(false)}>
      <Box className={classNames("switch-navigation-container", className)}>
        <Box className="switch-navigation-content">
          <Box className="switch-navigation-inbox">
            <Typography component={"p"} className="switch-to-engagement">
              {CheckValueLocale("switch_to_engagement", "", {}, intl)}
            </Typography>
            {showAiAgents ? (
              <MenuLinkItem
                id="engagements-analytics-link-btn"
                to="/engagements/ai-agents"
                icon={<img src={aiAgentsIcon} alt="AI Agents" />}
                title={CheckValueLocale("AI_AGENT_v2", "", {}, intl)}
                desc={CheckValueLocale("ai_agent_describtion", "", {}, intl)}
                imgIcon
              />
            ) : null}
            {
              <>
                {showInbox ? (
                  <SwitchMenuItem
                    navigationMenuStatus="inbox"
                    handleClickNavigation={handleClickNavigation}
                  />
                ) : null}
                {showPublish ? (
                  <SwitchMenuItem
                    navigationMenuStatus="publish"
                    handleClickNavigation={handleClickNavigation}
                  />
                ) : null}
              </>
            }
            {showAnalytics ? (
              <>
                {showPublish ? (
                  <Box className="switch-navigation-divider" />
                ) : null}
                <MenuLinkItem
                  id="engagements-analytics-link-btn"
                  to="/engagements/analytics/inbox"
                  icon={<FontAwesomeIcon icon={faAnalytics} />}
                  title={CheckValueLocale(
                    "analytics_engagement_v2",
                    "",
                    {},
                    intl,
                  )}
                />
              </>
            ) : null}
          </Box>
        </Box>
      </Box>
    </ClickAwayListener>
  ) : null;
};

const SwitchMenuItem = ({ navigationMenuStatus, handleClickNavigation }) => {
  const intl = useIntl();
  return (
    <>
      <Box
        id={
          navigationMenuStatus === "inbox"
            ? "engagements-publish-btn"
            : "engagements-inbox-btn"
        }
        className="navigation-content-types"
        onClick={() => handleClickNavigation(navigationMenuStatus)}
      >
        <Box className="navigation-content-types-main">
          <Box className="publish-icon-container">
            {navigationMenuStatus === "publish" ? (
              <img src={PublishIcon} alt="publish-icon" />
            ) : (
              <CommentIcon className="comment-icon-engagement comment-icon-inbox" />
            )}
          </Box>
          <Box className="switch-type-wrapper">
            <Typography component={"p"} className="switch-type-enagement">
              {CheckValueLocale(
                `${navigationMenuStatus}_engagement`,
                "",
                {},
                intl,
              )}
            </Typography>
            <Typography component={"p"} className="switch-desc-enagement">
              {CheckValueLocale(
                `${navigationMenuStatus}_describtion`,
                "",
                {},
                intl,
              )}
            </Typography>
          </Box>
          <Box className="arrow-icon-navigate-wrapper">
            <Box className="arrow-icon-navigate-wrapper-icon">
              <FontAwesomeIcon
                icon={faArrowRight}
                className={`arrow-icon-navigate arrow-icon-navigate-${navigationMenuStatus}`}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export const MenuLinkItem = ({ id, to, icon, title, desc, imgIcon }) => {
  return (
    <Link id={id} to={to}>
      <Box className="switch-navigation-inbox-dm">
        <Box className="switch-navigation-inbox-dm-content">
          <Box
            className={`switch-navigation-inbox-dm-content-icon ${imgIcon ? "switch-navigation-inbox-img" : ""}`}
          >
            {icon}
          </Box>
          <Box>
            <Typography className="switch-navigation-inbox-dm-content-text">
              {title}
            </Typography>
            {desc ? (
              <Typography className="switch-navigation-inbox-dm-content-desc">
                {desc}
              </Typography>
            ) : null}
          </Box>
        </Box>
        <Box className="switch-navigation-inbox-dm-arrow">
          <FontAwesomeIcon icon={faArrowRight} />
        </Box>
      </Box>
    </Link>
  );
};

export default SwitchMenuList;
