import { useEffect, useState } from "react";
import _ from "lodash";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import { Box, Typography } from "@mui/material";
import { CheckValueLocale, validateUrlInjection } from "utils/helpers";
import { useGetCompanyDomains } from "./hooks/useGetCompanyDomains";
import { useAddCompanyDomain } from "./hooks/useAddCompanyDomain";
import PopupModal from "components/popupModal";
import AddDomainModalBody from "./components/addDomainModalBody";
import SelectDomainModalBody from "./components/selectDomainModalBody";
import SelectDisplayScopeBody from "./components/selectDisplayScopeBody";
import VerifyDomainBody from "./components/verifyDomainBody";

const EmbeddedPublishModal = (props) => {
  const {
    surveyData,
    step,
    setStep,
    openedEmbeddedPublish,
    setOpenedEmbeddedPublish,
    currentDomainsPage,
    setCurrentDomainsPage,
    companyDomainsList,
    setCompanyDomainsList,
    companyDomainsData,
    setCompanyDomainsData,
    selectedDomains,
    setSelectedDomains,
    handlePublishEmbedded,
    saveLoading,
    setSnackBar,
  } = props;

  const intl = useIntl();

  const [btnsProps, setBtnsProps] = useState({});

  const { mutate: addCompanyDomain, isPending: addCompanyDomainLoading } =
    useAddCompanyDomain();

  const handleAddCompanyDomain = (data) => {
    addCompanyDomain(
      { data },
      {
        onSuccess: async (data) => {
          const createdDomain = data?.data?.data?.data;
          setSelectedDomains([
            {
              id: createdDomain?.id,
              domain: createdDomain?.domain,
              is_verified: createdDomain?.is_verified,
              token: createdDomain?.domain_verification_token,
              allow_all_pages: true,
              allowed_pages: [],
            },
          ]);
          await setStep("select_display");
          addDomainFormik?.resetForm();
        },
        onError: (error) => {
          const errorMsg = error?.error?.message;
          if (errorMsg === "INVALID_DOMAIN_FORMAT") {
            addDomainFormik?.setFieldError(
              "domain",
              "embedded_survey_add_new_domain_invalid_format_error_msg",
            );
          } else if (errorMsg === "DUPLICATE_DOMAIN_FOUND") {
            addDomainFormik?.setFieldError(
              "domain",
              "embedded_survey_add_new_domain_duplicate_error_msg",
            );
          } else {
            setSnackBar({
              open: true,
              severity: "error",
              title: "something_went_wrong",
              message: "try_again",
            });
          }
        },
      },
    );
  };

  const addDomainFormik = useFormik({
    initialValues: { domain: "" },
    validationSchema: Yup.object({
      domain: Yup?.string()
        ?.required("this_field_required")
        ?.test(
          "validate_format",
          "embedded_survey_add_new_domain_invalid_format_error_msg",
          (value) => !validateUrlInjection(value, true)?.error,
        )
        ?.test(
          "validate_format",
          "embedded_survey_add_new_domain_invalid_format_error_msg",
          (value) => !value?.endsWith("/"),
        ),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => handleAddCompanyDomain(values),
  });

  const { mutate: getCompanyDomains, isPending: getDomainsLoading } =
    useGetCompanyDomains();

  const handleBackToSelectDomain = async () => {
    setCurrentDomainsPage(1);
    setCompanyDomainsList([]);
    setCompanyDomainsData({});

    await getCompanyDomains(
      { page: 1 },
      {
        onSuccess: async (data) => {
          setCompanyDomainsData(data?.data?.domains);
          setCompanyDomainsList([...data?.data?.domains?.data]);
          await setStep("select_domain");
        },
        onError: () => {
          setSnackBar({
            open: true,
            severity: "error",
            title: "something_went_wrong",
            message: "try_again",
          });
        },
      },
    );
  };

  const handleCloseModal = async () => {
    await setOpenedEmbeddedPublish(false);
    setStep(null);
    setCurrentDomainsPage(1);
    setCompanyDomainsList([]);
    setCompanyDomainsData({});
    setSelectedDomains(surveyData?.selected_domains || []);
  };

  useEffect(() => {
    if (step === "add_domain") {
      setBtnsProps({
        leftBtn: CheckValueLocale("cancel", "", {}, intl),
        rightBtn: CheckValueLocale("add", "", {}, intl),
        accept: addDomainFormik?.handleSubmit,
        isRightBtnLoading: addCompanyDomainLoading,
        disabled: !(addDomainFormik?.isValid && addDomainFormik?.dirty),
      });
    } else if (step === "select_domain") {
      setBtnsProps({
        leftBtn: CheckValueLocale("cancel", "", {}, intl),
        rightBtn: CheckValueLocale("embedded_next", "", {}, intl),
        accept: () => setStep("select_display"),
        disabled: !selectedDomains?.length,
      });
    } else if (step === "select_display") {
      setBtnsProps({
        leftBtn: CheckValueLocale("cancel", "", {}, intl),
        rightBtn: CheckValueLocale("embedded_publish", "", {}, intl),
        isRightBtnLoading: saveLoading,
        disabled:
          selectedDomains?.[0]?.allow_all_pages == false &&
          !selectedDomains?.[0]?.allowed_pages?.length,
        accept: () => {
          if (_?.isEqual(selectedDomains, surveyData?.selected_domains))
            setStep("verify_domain");
          else handlePublishEmbedded("verify_domain");
        },
        showBackBtn: true,
        backBtn: CheckValueLocale("embedded_back", "", {}, intl),
        backBtnLoading: getDomainsLoading ? true : false,
        backBtnAction: () => handleBackToSelectDomain(),
      });
    } else if (step === "verify_domain") {
      setBtnsProps({
        rightBtn: CheckValueLocale("close", "", {}, intl),
        accept: handleCloseModal,
        showBackBtn: true,
        backBtn: CheckValueLocale("embedded_back", "", {}, intl),
        backBtnAction: () => setStep("select_display"),
      });
    }
  }, [
    step,
    selectedDomains,
    addDomainFormik?.isValid,
    addDomainFormik?.dirty,
    getDomainsLoading,
    addCompanyDomainLoading,
    surveyData?.selected_domains,
    saveLoading,
    intl,
  ]);

  const handlePopupTitle = () => {
    if (step === "add_domain") return "embedded_survey_add_your_domain";
    else if (step === "select_domain") return "embedded_survey_select_domain";
    else if (step === "select_display")
      return "embedded_survey_select_display_scope";
    else if (step === "verify_domain")
      return "embedded_survey_verify_your_domain";
    else return "";
  };

  const handlePopupStepNumber = () => {
    if (step === "verify_domain") return 3;
    else if (step === "select_display") return 2;
    else return 1;
  };

  return (
    <PopupModal
      id="embedded-survey-publish-modal"
      open={openedEmbeddedPublish}
      close={handleCloseModal}
      title={
        <Box id="embedded-survey-publish-modal-title">
          <Typography className="embedded-survey-publish-modal-title-text ">
            {CheckValueLocale(handlePopupTitle(), "", {}, intl)}
          </Typography>
          <Typography className="embedded-survey-publish-modal-title-steps-count">
            {CheckValueLocale(
              "embedded_survey_publish_steps_count",
              "",
              { currentStep: handlePopupStepNumber(), totalSteps: 3 },
              intl,
            )}
          </Typography>
        </Box>
      }
      body={
        step === "add_domain" ? (
          <AddDomainModalBody formik={addDomainFormik} />
        ) : step === "select_display" ? (
          <SelectDisplayScopeBody
            selectedDomains={selectedDomains}
            setSelectedDomains={setSelectedDomains}
          />
        ) : step === "verify_domain" ? (
          <VerifyDomainBody
            selectedDomains={selectedDomains}
            setSnackBar={setSnackBar}
          />
        ) : step === "select_domain" ? (
          <SelectDomainModalBody
            surveyData={surveyData}
            currentDomainsPage={currentDomainsPage}
            setCurrentDomainsPage={setCurrentDomainsPage}
            companyDomainsData={companyDomainsData}
            setCompanyDomainsData={setCompanyDomainsData}
            companyDomainsList={companyDomainsList}
            setCompanyDomainsList={setCompanyDomainsList}
            setStep={setStep}
            selectedDomains={selectedDomains}
            setSelectedDomains={setSelectedDomains}
            setSnackBar={setSnackBar}
          />
        ) : null
      }
      modalStyle="embedded-publish-modal-body"
      addClasses="actions-buttons-shared"
      minWidth="600px"
      {...(btnsProps || {})}
    />
  );
};

export default EmbeddedPublishModal;
