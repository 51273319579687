import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
// files
import { isPublicSpecialCharactersOrEmojis } from "../socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/specialCharOrEmojis";
import { removeSpecialCharacters } from "../socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/keywordLanguage.js";
import {
  CheckValueLocale,
  isEmptyObj,
  getSocialIcon,
  handleActiveFeature,
  handlelUserRoles,
} from "../../utils/helpers/index.js";

import dashboardController from "../../services/controllers/dashboardController";
import { useNavigate } from "react-router-dom";

import "./style.scss";
import { getDashboardTableHeader } from "./dashboardList/components/dashboardList/dashboardList";
import { useSortableTable } from "hooks/useSortableTable";

const DashboardFunctions = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  const steps = ["dashboard_information", "monitors"];
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});

  const [errorMsg, setErrorMsg] = useState("");

  const [messageSnackBar, setMessageSnackBar] = useState("");
  const [severitySnackBar, setSeveritySnackBar] = useState("info");
  const [selectedMonitors, setSelectedMonitors] = useState([]);
  const [countMonitorsSelected, setCountMonitorsSelected] = useState(0);
  const [exceedLimit, setExceedLimit] = useState(false);
  const [selectedDatasourcesMonitors, setSelectedDatasourcesMonitors] =
    useState([]);

  const [monitorsList, setMonitorsList] = useState([]);
  const [monitorsListExist, setMonitorsListExist] = useState(true);

  const [dashboardTypesLoading, setDashboardTypesLoading] = useState(false);
  const [dashboardMonitorsLoading, setDashboardMonitorsLoading] =
    useState(false);

  const [displayMonitorNameTooltip, setDisplayMonitorNameTooltip] =
    useState(false);

  const [dashboardList, setDashboardList] = useState([]);
  const [selectedDashboardType, setSelectedDashboardType] = useState("");
  // Control show all & show less buttons
  const [showAllMonitors, setShowAllMonitors] = useState(true);
  const [showLessMonitors, setShowLessMonitors] = useState(false);

  const [dashboardData, setdashboardData] = useState({
    dashabordName: "",
    dashboardType: "",
    dashboardTypeID: null,
    monitorValue: "MANAGED_ACCOUNT",
    dashabordMonitors: [],
  });
  const [dashboardErrors, setdashboardErrors] = useState({
    dashabordName: "",
    dashboardType: "",
    monitorValue: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isVaildDashboard, setIsValidDashboard] = useState(false);
  const [isDashboardCreated, setIsDashboardCreated] = useState(false);

  const [dashboards, setDashboards] = useState([]);
  const [companyHasDashoboards, setCompanyHasDashoboards] = useState(false);
  const [companyHasMonitors, setCompanyHasMonitors] = useState(false);
  const [cxmMonitorsCount, setCxmMonitorsCount] = useState(0);
  const [smMonitorsCount, setSmMonitorsCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState(1);
  const [canCreateDashboard, setCanCreateDashboard] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [isCustomDashboard, setIsCustomDashboard] = useState(false);
  const [updatedValuesParams, setUpdatedValuesParams] = useState({});
  const [countMonitorsSelectedEdit, setCountMonitorsSelectedEdit] = useState(0);
  const [dashboardDynamicData, setDashboardDynamicData] = useState([]);
  const [activeTab, setActiveTab] = useState("");
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [dashboardSnackBar, setDashboardSnackBar] = useState({
    message: "",
    severity: "",
    title: "",
    openSnackBar: false,
  });

  let enabledAggregate =
    handlelUserRoles("SM", "VIEW_DASHBOARD_LIST") &&
    handleActiveFeature("SM", "is_dashboards_enabled") &&
    smMonitorsCount >= 2 &&
    canCreateDashboard;

  let enabledCustomDashboard =
    handleActiveFeature("SM", "is_sl_custom_dashboards_enabled") ||
    handleActiveFeature("CXM", "is_cxm_custom_dashboards_enabled");

  const handleCloseSnackBar = () => {
    setDashboardSnackBar({
      openSnackBar: false,
    });
  };
  const onSortChangeCallback = () => {
    setIsSortableLoading(true);
    setPage(1);
  };
  const {
    columns: tableHead,
    sortColumn,
    sortDirection,
    isSortableLoading,
    setIsSortableLoading,
  } = useSortableTable({
    initialColumns: getDashboardTableHeader(intl),
    onSortChangeCallback,
  });

  var fieldIsRequired = CheckValueLocale(
      "dashboard_field_required",
      "",
      {},
      intl,
    ),
    dashboardNameLimit = CheckValueLocale("dashboard_name_limit", "", {}, intl),
    spamKeywordsRules = CheckValueLocale(
      "dashboard_spam_keywords_rules",
      "",
      {},
      intl,
    );

  const [widgetsName, setWidgetsName] = useState([]);

  const handleMonitorValueChange = (event) => {
    setdashboardData({
      ...dashboardData,
      monitorValue: event.target.value,
      dashabordMonitors: [],
    });
    setSelectedMonitors([]);
    setExceedLimit(false);
    setCountMonitorsSelected(0);
    setCountMonitorsSelectedEdit(0);

    setShowLessMonitors(false);
    setIsValidDashboard(false);
  };

  //These functions are for handleing back/next steps
  const handleNext = () => {
    handleComplete();
    const newActiveStep = activeStep + 1;
    setActiveStep(newActiveStep);
  };

  //These functions are for handleing complete steps of create new monitor
  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
  };

  const selectDashboardType = (DashboardTypeName, DashboardTypeID) => {
    if (DashboardTypeName === "CUSTOM_DASHBOARD") setIsCustomDashboard(true);
    else setIsCustomDashboard(false);
    setdashboardData({
      ...dashboardData,
      dashboardType: DashboardTypeName,
      dashboardTypeID: DashboardTypeID,
    });
    setSelectedDashboardType(DashboardTypeName);
  };

  const handleSnackBar = (showSnackBar, Severity, MessageSnackBar) => {
    setShowSnackBar(showSnackBar);
    setSeveritySnackBar(Severity);
    setMessageSnackBar(MessageSnackBar);
  };

  const handleInputChange = (e) => {
    let name = e.target.value;

    if (name === "") {
      setdashboardErrors({
        ...dashboardErrors,
        dashabordName: fieldIsRequired,
      });
    } else if (name?.length > 50) {
      setdashboardErrors({
        ...dashboardErrors,
        dashabordName: dashboardNameLimit,
      });
    } else if (
      isPublicSpecialCharactersOrEmojis(name, undefined, true) ||
      removeSpecialCharacters(name)?.length === 0
    ) {
      setdashboardErrors({
        ...dashboardErrors,
        dashabordName: spamKeywordsRules,
      });
    } else {
      setdashboardErrors({ ...dashboardErrors, dashabordName: "" });
    }

    setdashboardData({ ...dashboardData, dashabordName: name });
  };

  const handleSelectMonitor = (event, id, dataSources, isEditDashboard) => {
    var monitorsArray = [...selectedMonitors];
    var newCount = countMonitorsSelected;
    var datasourcesCount = [...selectedDatasourcesMonitors];
    if (
      event.target.checked &&
      ((newCount <= 8 && !isEditDashboard) ||
        (isEditDashboard && datasourcesCount?.length <= 8))
    ) {
      monitorsArray = [...selectedMonitors, id];
      newCount += dataSources?.length;
      datasourcesCount = [...selectedDatasourcesMonitors, ...dataSources];
    } else {
      monitorsArray.splice(selectedMonitors.indexOf(id), 1);
      newCount -= dataSources?.length;
      datasourcesCount = datasourcesCount.slice(dataSources?.length);
    }
    if (
      (newCount <= 8 && !isEditDashboard) ||
      (isEditDashboard && datasourcesCount?.length <= 8)
    ) {
      setExceedLimit(false);
      setSelectedMonitors(monitorsArray);
      setdashboardData({ ...dashboardData, dashabordMonitors: monitorsArray });
      //in case of edit monitor only
      isEditDashboard &&
        setUpdatedValuesParams({
          ...updatedValuesParams,
          monitors: monitorsArray?.toString(),
        });
    } else {
      setExceedLimit(true);
      newCount -= dataSources?.length;
      datasourcesCount = datasourcesCount.slice(dataSources?.length);
    }
    setSelectedDatasourcesMonitors(datasourcesCount);
    isEditDashboard
      ? setCountMonitorsSelectedEdit(datasourcesCount?.length)
      : setCountMonitorsSelected(newCount);
    setIsValidDashboard(newCount > 1);
  };

  const getMonitorsListForDashboard = (monitor_type) => {
    setDashboardMonitorsLoading(true);
    dashboardController.getMonitorsForDashboard(monitor_type).then((data) => {
      if (data?.data !== undefined && data?.data?.length !== 0) {
        setMonitorsList(data?.data);
        setMonitorsListExist(true);
      } else {
        setMonitorsList([]);
        setMonitorsListExist(false);
      }
      setDashboardMonitorsLoading(false);
    });
  };

  const getTypesForDashboard = () => {
    setDashboardTypesLoading(true);
    dashboardController.getDashboardTypes().then((data) => {
      if (data?.data) {
        setSmMonitorsCount(data?.data?.sm_monitors_count);
        setCxmMonitorsCount(data?.data?.cxm_monitors_count);
        setDashboardList(data?.data?.types);
        setCanCreateDashboard(!data?.data?.dashboard_limit_reach);
      } else {
        setDashboardList([]);
      }
      setDashboardTypesLoading(false);
    });
  };

  // Set default checked type after checking is enabled or not
  useEffect(() => {
    dashboardList?.some((dashboard) => {
      if (
        dashboard?.attributes?.type_name === "AGGREGATE_DASHBOARD" &&
        dashboard?.attributes?.is_enabled &&
        enabledAggregate
      ) {
        selectDashboardType("AGGREGATE_DASHBOARD", dashboard?.attributes?.id);
        return true;
      } else if (
        dashboard?.attributes?.type_name === "CUSTOM_DASHBOARD" &&
        dashboard?.attributes?.is_enabled &&
        enabledCustomDashboard
      ) {
        selectDashboardType("CUSTOM_DASHBOARD", dashboard?.attributes?.id);
        return true;
      }
      return false;
    });
  }, [dashboardList, enabledAggregate, enabledCustomDashboard]);

  const handleShowAllMonitors = (event) => {
    if (event) {
      setShowAllMonitors(false);
      setShowLessMonitors(true);
    } else {
      setShowAllMonitors(false);
      setShowLessMonitors(true);
    }
  };

  const handleShowLessMonitors = (event) => {
    if (event) {
      setShowLessMonitors(false);
      setShowAllMonitors(true);
    } else {
      setShowLessMonitors(false);
      setShowAllMonitors(true);
    }
  };
  const truncateName = (input, count) => {
    let val;
    input?.length > count
      ? window.localStorage.lang === "ar"
        ? (val = `...${input?.substring(0, count)}`)
        : (val = `${input?.substring(0, count)}...`)
      : (val = input);
    return val;
  };

  const displayMonitorNameAndUsername = (monitorName, monitorUsername) => {
    let monitorDisplayName = monitorName + " " + monitorUsername;

    if (monitorDisplayName?.length > 22) {
      if (window.localStorage.lang === "ar") {
        monitorDisplayName = "..." + monitorDisplayName.substring(0, 22);
      } else {
        monitorDisplayName = monitorDisplayName.substring(0, 22) + "...";
      }
    } else {
      monitorDisplayName = monitorDisplayName;
    }

    return monitorDisplayName;
  };

  const submitCreateDashboard = (dashboardDetails) => {
    var snackbarCreateDbData = {
      severity: "",
      message: "",
    };

    dashboardController.createNewDashboard(dashboardDetails).then((res) => {
      if (res?.data !== undefined && res?.data !== []) {
        if (res.data.status === 201) {
          setIsDashboardCreated(true);
          snackbarCreateDbData = {
            severity: "success",
            message: "dashboard_created_successfully",
          };
        } else {
          setIsDashboardCreated(false);
          snackbarCreateDbData = {
            severity: "error",
            message: res?.errorMsg?.response?.data?.exception?.message
              ? res?.errorMsg?.response?.data?.exception?.message
              : "failed_error",
          };
        }

        localStorage.setItem("openSnakbar", "true");
        localStorage.setItem(
          "dashboardCreatedMessage",
          JSON.stringify(snackbarCreateDbData),
        );

        navigate("/dashboard/dashboard_list");
      }
    });
  };

  // _____________________________________________________________________________
  // Function for dashboard list page
  const getDashboards = () => {
    if (!isSortableLoading) setLoading(true);
    dashboardController
      .getDashboardList(searchText, page, sortColumn, sortDirection)
      .then((res) => {
        if (res?.errorMsg) {
          setDashboards([]);
          setCompanyHasDashoboards(false);
          setCompanyHasMonitors(false);
          setCxmMonitorsCount(0);
          setSmMonitorsCount(0);
          setPagination(1);
          setDashboardSnackBar({
            message: "try_again_error_message",
            severity: "error",
            title: "failed_error_message",
            openSnackBar: true,
          });
        } else {
          setDashboards(res?.data?.data);
          setCompanyHasDashoboards(res?.data?.company_has_dashoboards);
          setCompanyHasMonitors(res?.data?.company_has_monitors);
          setPagination(res?.data?.pagination);
          setCanCreateDashboard(!res?.data?.dashboard_limit_reach);
          setCxmMonitorsCount(res?.data?.cxm_monitors_count);
          setSmMonitorsCount(res?.data?.sm_monitors_count);
        }
        setLoading(false);
        setIsSortableLoading(false);
      });
  };

  // _____________________________________________________________________________

  const getDashboardWidgetsName = (data) => {
    let allWidgetsNames = [];
    data?.metrics_values &&
      !isEmptyObj(data?.metrics_values) &&
      Object.keys(data?.metrics_values)?.map((key, index) => {
        allWidgetsNames.push(key);
      });
    setWidgetsName(allWidgetsNames);
  };

  const getIcons = (allDatasouces) => {
    return (
      <div className="icons-dashboard-page dashboard-icons-widgets">
        {allDatasouces?.map((social, i) => {
          return getSocialIcon(social);
        })}
      </div>
    );
  };

  const tooltipText = (monitorName, status, tooltipStyle) => {
    var tooltipMsg = "";

    const dashboardMonitors = {
      "re-authentication": "reauthenticate_dashboard_tooltip",
      deleted: "deleted_dashboard_tooltip",
      paused: "paused_dashboard_tooltip",
      "under approval": "under_approval_dashboard_tooltip",
      "data limit reached": "limit_reached_dashboard_tooltip",
      "limit reached": "limit_reached_dashboard_tooltip",
    };

    tooltipMsg = dashboardMonitors[status] || "";

    return (
      <>
        {
          <div className={`display-in-row ${tooltipStyle}`}>
            <div>
              {CheckValueLocale(
                tooltipMsg,
                "",
                { monitorName: monitorName },
                intl,
              )}
            </div>
          </div>
        }
      </>
    );
  };

  return {
    dashboards,
    loading,
    page,
    pagination,
    searchText,
    canCreateDashboard,
    getDashboards,
    showSnackBar,
    setShowSnackBar,
    messageSnackBar,
    severitySnackBar,
    handleSnackBar,
    handleInputChange,
    selectDashboardType,
    isLoading,
    setIsLoading,
    dashboardData,
    setdashboardData,
    dashboardErrors,
    setdashboardErrors,
    steps,
    handleNext,
    completed,
    setCompleted,
    errorMsg,
    setErrorMsg,
    activeStep,
    setActiveStep,
    handleMonitorValueChange,
    selectedMonitors,
    setSelectedMonitors,
    handleSelectMonitor,
    countMonitorsSelected,
    exceedLimit,
    getMonitorsListForDashboard,
    monitorsList,
    monitorsListExist,
    showAllMonitors,
    showLessMonitors,
    handleShowAllMonitors,
    handleShowLessMonitors,
    getTypesForDashboard,
    dashboardList,
    selectedDashboardType,
    displayMonitorNameAndUsername,
    displayMonitorNameTooltip,
    dashboardTypesLoading,
    dashboardMonitorsLoading,
    submitCreateDashboard,
    isVaildDashboard,
    setIsValidDashboard,
    isDashboardCreated,
    setSearchText,
    setPage,
    updatedValuesParams,
    setUpdatedValuesParams,
    setSelectedDatasourcesMonitors,
    selectedDatasourcesMonitors,
    countMonitorsSelectedEdit,
    truncateName,
    setDashboardDynamicData,
    setActiveTab,
    activeTab,
    dashboardDynamicData,
    companyHasDashoboards,
    companyHasMonitors,
    widgetsName,
    getDashboardWidgetsName,
    getIcons,
    tooltipText,
    setWidgetsName,
    sortColumn,
    sortDirection,
    isSortableLoading,
    tableHead,
    dashboardSnackBar,
    handleCloseSnackBar,
    isCustomDashboard,
    setIsCustomDashboard,
    enabledAggregate,
    enabledCustomDashboard,
    smMonitorsCount,
    cxmMonitorsCount,
  };
};
export default DashboardFunctions;
