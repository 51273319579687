import {
  Box,
  FormControl,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import DisplayOptionSelect from "./components/displayOptionSelect";

const OpeningOptions = (props) => {
  const { embeddedOptions, surveyData, setSurveyData, savedSurveyData } = props;

  const intl = useIntl();

  const isPopup = surveyData?.embedded_config?.embed_options?.type === "pop-up";

  const openingAfterDefaultValues =
    embeddedOptions?.opening_settings?.opening_after?.values?.map(
      (item) => item?.value,
    ) || [];

  const selectedOptions = surveyData?.embedded_config?.opening_settings || {};

  const handleChangeOption = (key, value) => {
    setSurveyData((prev) => ({
      ...prev,
      embedded_config: {
        ...prev?.embedded_config,
        opening_settings: {
          ...prev?.embedded_config?.opening_settings,
          [key]: value,
        },
      },
    }));
  };

  const handleChangeOpeningAfter = (value, key) => {
    setSurveyData((prev) => ({
      ...prev,
      embedded_config: {
        ...prev?.embedded_config,
        opening_settings: {
          ...prev?.embedded_config?.opening_settings,
          opening_after: {
            ...prev?.embedded_config?.opening_settings?.opening_after,
            value: openingAfterDefaultValues?.find(
              (item) => item?.name === value,
            )?.default_value, // To set value with defaults (for ex: 10s or 80%)
            [key]: value, // To set name (after_scrolling or after_seconds)
          },
        },
      },
    }));
  };

  const validateValues = (e) => {
    const value = e?.target?.value;

    const numRegex = /^[0-9 ]+$/;

    if (value?.length) {
      if (numRegex?.test(value)) {
        if (
          selectedOptions?.opening_after?.name === "after_scrolling" &&
          value >= 0 &&
          value <= 100
        ) {
          handleChangeOpeningAfter(value, "value");
        } else if (selectedOptions?.opening_after?.name === "after_seconds") {
          handleChangeOpeningAfter(value, "value");
        }
      }
    } else handleChangeOpeningAfter(value, "value");
  };

  return (
    <Box className="display-settings-options-accordion-content">
      {isPopup ? (
        <Box className="display-settings-options-accordion-content-option-wrapper">
          <Typography className="display-settings-options-subtitle">
            {CheckValueLocale("display_settings_opening_after", "", {}, intl)}
          </Typography>
          <Box className="display-settings-opening-after-select-wrapper">
            <DisplayOptionSelect
              id="display-settings-opening-after-select"
              className="display-settings-opening-after-select"
              options={embeddedOptions?.opening_settings?.opening_after?.values
                ?.sort((a, b) => a?.id - b?.id)
                ?.map((option) => ({
                  label: `display_settings_opening_${option?.value?.name}`,
                  value: option?.value?.name,
                }))}
              value={selectedOptions?.opening_after?.name}
              onChange={(e) =>
                handleChangeOpeningAfter(e?.target?.value, "name")
              }
            />
            <FormControl
              id="embedded-survey-display-settings-opening-after-form-control"
              className="embedded-survey-display-settings-opening-after-form-control"
              variant="outlined"
              size="small"
            >
              <TextField
                id="embedded-survey-display-settings-opening-after-field"
                className="embedded-survey-display-settings-text-field opening-after-text-field"
                value={selectedOptions?.opening_after?.value}
                onChange={validateValues}
                onBlur={(e) => {
                  if (e?.target?.value === "") {
                    setSurveyData((prev) => ({
                      ...prev,
                      embedded_config: {
                        ...prev?.embedded_config,
                        opening_settings: {
                          ...prev?.embedded_config?.opening_settings,
                          opening_after: {
                            ...prev?.embedded_config?.opening_settings
                              ?.opening_after,
                            value:
                              savedSurveyData?.embedded_config?.opening_settings
                                ?.opening_after?.value,
                          },
                        },
                      },
                    }));
                  }
                }}
                variant="outlined"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      className="display-settings-adornment"
                      position="start"
                      size="small"
                    >
                      {selectedOptions?.opening_after?.name === "after_seconds"
                        ? CheckValueLocale("S", "", {}, intl)?.toUpperCase()
                        : "%"}
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Box>
        </Box>
      ) : null}

      <Box className="display-settings-options-accordion-content-option-wrapper">
        <Typography className="display-settings-options-subtitle">
          {CheckValueLocale("display_settings_opening_effect", "", {}, intl)}
        </Typography>
        <DisplayOptionSelect
          options={(
            embeddedOptions?.opening_settings?.opening_effect?.values?.sort(
              (a, b) => a?.id - b?.id,
            ) || []
          )?.map((option) => {
            return {
              label: `display_settings_opening_effect_${option?.value}`,
              value: option?.value,
            };
          })}
          value={selectedOptions?.opening_effect}
          onChange={(e) =>
            handleChangeOption("opening_effect", e?.target?.value)
          }
          menuDirection={"top"}
        />
      </Box>
    </Box>
  );
};

export default OpeningOptions;
