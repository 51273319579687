import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Typography, Grid, Box } from "@mui/material";
import LineChartWidget from "components/widgets/lineChartWidget";
import StackedLineWidget from "components/widgets/stackedLineWidget.js";
import PieChartWidget from "components/widgets/pieChartWidget.js";
import InteractionsList from "components/interactions-list/InteractionsList.js";
import ThemesAnalysis from "components/widgets/themes/themeAnalysis/ThemeAnalysis";
import ThemesTrends from "components/widgets/themes/themesTrends/ThemesTrends";
import OverView from "components/widgets/overViewWidget";
import { CheckValueLocale } from "utils/helpers/index";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import PunchCardWidget from "components/widgets/punchCardWidget";
import { getNoOfDays } from "utils/helpers";
import LuciFunctions from "pages/luci/components/luciFunctions/luciFunction";
import publicInstagram from "images/social-icons/instagram-public-icon.svg";

const InstagramBody = (props) => {
  const intl = useIntl();
  let { response, startDate, endDate } = props;

  const initialWidgetsState = {
    //overview widget
    overview: [],
    overViewPreLoaderDataSources: [],

    //top keywords widget
    topKeywords: [],
    topKeywordsPreLoaderDataSources: [],

    //dialects sub dialects widget
    dialects: [],
    subDialects: [],
    dialectsPreLoaderDataSources: [],

    //gender widget
    genders: [],
    gendersPreLoaderDataSources: [],

    //channel account widget
    channelAccounts: [],
    channelAccountsPreLoaderDataSources: [],

    //content type widget
    contentType: [],
    contentTypePreLoaderDataSources: [],

    //interaction widget
    interactions: [],
    totalInteractions: [],
    avgInteractions: 0,
    interactionsPreLoaderDataSources: [],

    //latest interaction widget
    latestInteractions: [],
    latestInteractionsCount: 0,
    latestInteractionsPreLoaderDataSources: [],

    //sentiment widget
    sentimentOvertime: [],
    sentimentPiechart: [],
    sentimentAnalysisPreLoaderDataSources: [],
    negativeArray: [],
    positiveArray: [],
    neutralArray: [],

    //theme widget
    themeAnalysis: [],
    themeTrend: [],
    themeAnalysisPreLoaderDataSources: [],
    themeTrendPreLoaderDataSources: [],

    //contact activity widget
    contactActivity: [],
    minValueContactActivity: 0,
    maxValueContactActivity: 0,
    contactActivityPreLoaderDataSources: [],

    //most engaged widget
    mostEngagedContacts: [],
    mostInfluentialContacts: [],
    verifiedContacts: [],
    mostEngagedContactsPreLoaderDataSources: [],
    mostInfluentialContactsPreLoaderDataSources: [],
    verifiedContactsPreLoaderDataSources: [],
  };

  const [instagramWidgets, setInstagramWidgets] = useState(initialWidgetsState);
  const { checkLuciDataIsFounded, checkLuciAllDataFounded } = LuciFunctions();

  const instagramIcon = (
    <>
      <img src={publicInstagram} className="icon-after-title instagram" />
    </>
  );

  useEffect(() => {
    switch (response?.eventName) {
      case "overview":
        if (response?.eventData?.overview) {
          let overViewArray = Object?.entries(
            response?.eventData?.overview,
          )?.map((key) => ({
            name: key[0],
            value: key[1],
          }));
          setInstagramWidgets({
            ...instagramWidgets,
            overview: [...overViewArray],
            overViewPreLoaderDataSources: [response?.data_source],
          });
        }
        break;
      case "top_keywords":
        setInstagramWidgets({
          ...instagramWidgets,
          topKeywords: response?.eventData?.top_keywords,
          topKeywordsPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "dialects":
        setInstagramWidgets({
          ...instagramWidgets,
          dialects: response?.eventData?.dialects,
          subDialects: response?.eventData?.sub_dialects,
          dialectsPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "genders":
        setInstagramWidgets({
          ...instagramWidgets,
          genders: response?.eventData?.genders,
          gendersPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "channel_accounts":
        let newChannelAccounts = [];
        response?.eventData?.channel_accounts?.map((channel) => {
          newChannelAccounts?.push({
            name:
              window?.localStorage?.lang === "en"
                ? "@" + channel?.name
                : channel?.name + "@",
            value: channel?.value,
          });
        });
        setInstagramWidgets({
          ...instagramWidgets,
          channelAccounts: newChannelAccounts,
          channelAccountsPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "content_type":
        setInstagramWidgets({
          ...instagramWidgets,
          contentType: response?.eventData?.content_type_piechart,
          contentTypePreLoaderDataSources: [response?.data_source],
        });
        break;
      case "latest_interactions":
        setInstagramWidgets({
          ...instagramWidgets,
          latestInteractions: response?.eventData?.latest_interactions,
          latestInteractionsPreLoaderDataSources: [response?.data_source],
          latestInteractionsCount: response?.eventData?.interactions_count,
        });
        break;
      case "interactions":
        const noOfDays = getNoOfDays(startDate, endDate);
        const avg = response?.eventData?.total_interactions / noOfDays;
        setInstagramWidgets({
          ...instagramWidgets,
          interactions: response?.eventData?.interactions,
          avgInteractions: avg % 1 !== 0 ? avg.toFixed(2) : avg,
          totalInteractions: response?.eventData?.total_interactions,
          interactionsPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "sentiment":
        Object.entries(response?.eventData?.sentiment_overtime)?.forEach(
          ([key, value]) => {
            switch (key) {
              case "negative":
                value?.map((i) => {
                  return instagramWidgets?.negativeArray.push(i);
                });
                break;
              case "positive":
                value?.map((i) => {
                  return instagramWidgets?.positiveArray.push(i);
                });
                break;
              case "neutral":
                value?.map((i) => {
                  return instagramWidgets?.neutralArray.push(i);
                });
                break;
              default:
                break;
            }
          },
        );

        setInstagramWidgets({
          ...instagramWidgets,
          sentimentOvertime: {
            negative: instagramWidgets?.negativeArray,
            positive: instagramWidgets?.positiveArray,
            neutral: instagramWidgets?.neutralArray,
          },
          sentimentPiechart: response?.eventData?.sentiment_piechart,
          sentimentAnalysisPreLoaderDataSources: [response?.data_source],
        });

        break;
      case "themes":
        setInstagramWidgets({
          ...instagramWidgets,
          themeAnalysis: response?.eventData?.themes?.theme_analysis,
          themeTrend: response?.eventData?.themes?.theme_trend,
          themeAnalysisPreLoaderDataSources: [response?.data_source],
          themeTrendPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "top_engaged_authors":
        setInstagramWidgets({
          ...instagramWidgets,
          mostEngagedContacts: response?.eventData?.top_engaged_authors,
          mostEngagedContactsPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "top_influencers":
        setInstagramWidgets({
          ...instagramWidgets,
          mostInfluentialContacts: response?.eventData?.top_influencers,
          mostInfluentialContactsPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "verified_authors":
        setInstagramWidgets({
          ...instagramWidgets,
          verifiedContacts: response?.eventData?.verified_authors,
          verifiedContactsPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "channel_activity":
        setInstagramWidgets({
          ...instagramWidgets,
          minValueContactActivity:
            response?.eventData?.channel_activity?.min_value_client,
          maxValueContactActivity:
            response?.eventData?.channel_activity?.max_value_client,
          contactActivity: response?.eventData?.channel_activity?.client,
          contactActivityPreLoaderDataSources: [response?.data_source],
        });
        break;
      default:
        break;
    }
  }, [response]);

  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (props.resetAllFields !== 1) {
      setInstagramWidgets({ ...initialWidgetsState });
    }
  }, [props.resetAllFields]);

  const chartStatsData = [
    {
      name: "total_interactions",
      value: instagramWidgets?.totalInteractions,
    },
    {
      name: "insights_avg_interactions_per_day",
      value: instagramWidgets?.avgInteractions,
    },
  ];

  useEffect(() => {
    let x = checkLuciAllDataFounded(
      instagramWidgets?.latestInteractionsPreLoaderDataSources,
      props.preLoaderTrackerDataSources,
    );
    let y = checkLuciDataIsFounded([instagramWidgets?.latestInteractions]);
    props.setCheckAllDataExist(x);
    props.setCheckDataIsEmpty(y);
  }, [
    instagramWidgets?.latestInteractions,
    instagramWidgets?.latestInteractionsPreLoaderDataSources,
    props.preLoaderTrackerDataSources,
  ]);
  const unixDate = {
    start: startDate,
    end: endDate,
  };
  return (
    <>
      <OverView
        data={instagramWidgets?.overview}
        overViewPreLoaderDataSources={
          instagramWidgets?.overViewPreLoaderDataSources
        }
        preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        directMessage={true}
      />
      <Box my={4}>
        <LineChartWidget
          title={"conversation_interactions"}
          titleToolTip="conversation_interaction_tooltip"
          data={instagramWidgets?.interactions}
          labelColor={"#2AAAE2"}
          showGroupBy={true}
          showChartDetailsWidget
          statsDetailsData={chartStatsData}
          redirectTo={"/interactions/social-media/instagram-private/"}
          toolTipLabel={"total_interactions"}
          showDownloadIcon
          bigImg
          lineChartPreLoaderDataSources={
            instagramWidgets?.interactionsPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          iconAfterTitle={instagramIcon}
        />
      </Box>
      {/* //__________________________________________________ */}
      <Typography
        variant="h6"
        mt={5}
        mb={3}
        sx={{ color: "#A4A9AF", fontSize: "14px", fontWeight: 500 }}
      >
        {intl
          .formatMessage({ id: "closed_conversation_overview" })
          .toUpperCase()}
      </Typography>
      <Box>
        <StackedLineWidget
          title={"sentiment_analysis"}
          titleToolTip="sentiments_dm_tooltip"
          data={instagramWidgets?.sentimentOvertime}
          dataPiechart={instagramWidgets?.sentimentPiechart}
          showGroupBy={true}
          areaStyle={true}
          toolTipLabel={"toolTipLabel"}
          showAI={false}
          hideLegend
          showDownloadIcon
          bigImg
          stackedLineWidgetPreLoaderDataSources={
            instagramWidgets?.sentimentAnalysisPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          isSwitchCase
          showScales
          labelColors={["#89BB2A", "#E50035", "#F9A700"]}
          iconAfterTitle={instagramIcon}
          isSentimentAnalysisShowCharts
        />
      </Box>

      {/* Themes & Themes-Trend Statues CHART */}
      <Box mt={3}>
        <ThemesAnalysis
          title={"themes"}
          titleToolTip="themes_dm_tooltip"
          data={instagramWidgets?.themeAnalysis}
          showDownloadIcon
          bigImg
          iconAfterTitle={instagramIcon}
          themeAnalysisPreLoaderDataSources={
            instagramWidgets?.themeAnalysisPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Box>
      <Box mt={3}>
        <ThemesTrends
          title={"themes_trend"}
          titleToolTip="themes_trend_dm_tooltip"
          data={instagramWidgets?.themeTrend}
          showGroupBy={false}
          showDownloadIcon
          bigImg
          iconAfterTitle={instagramIcon}
          themeTrendPreLoaderDataSources={
            instagramWidgets?.themeTrendPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Box>
      <Grid container spacing={2} my={4}>
        <Grid item md={4} className="dialect-subdialect-widget">
          <PieChartWidget
            title={"dialects_subdialects"}
            titleToolTip="dialects_subdialects_dm_tooltip"
            data={instagramWidgets?.dialects}
            subData={instagramWidgets?.subDialects}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            showDownloadIcon
            bigImg
            iconAfterTitle={instagramIcon}
            pieChartPreLoaderDataSources={
              instagramWidgets?.dialectsPreLoaderDataSources
            }
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          />
        </Grid>

        <Grid item md={4}>
          <PieChartWidget
            title={"channel_accounts"}
            titleToolTip="channel_accounts_dm_tooltip"
            data={instagramWidgets?.channelAccounts}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            showDownloadIcon
            color={["#009BDF", "#003D59", "#45B9E9", "#97D7F3"]}
            pieChartPreLoaderDataSources={
              instagramWidgets?.channelAccountsPreLoaderDataSources
            }
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
            iconAfterTitle={instagramIcon}
            isChannelAccounts
            bigImg
          />
        </Grid>
        <Grid item md={4}>
          <PieChartWidget
            title={"content_type"}
            titleToolTip="content_type_dm_tooltip"
            data={instagramWidgets?.contentType}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            showDownloadIcon
            bigImg
            pieChartPreLoaderDataSources={
              instagramWidgets?.contentTypePreLoaderDataSources
            }
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
            iconAfterTitle={instagramIcon}
          />
        </Grid>
      </Grid>
      <Box my={4} className="channel-activity">
        <PunchCardWidget
          title="contact_activity"
          titleToolTip="channel_activity_dm_tooltip"
          clientActivity={instagramWidgets?.contactActivity}
          minValueClient={instagramWidgets?.minValueContactActivity}
          maxValueClient={instagramWidgets?.maxValueContactActivity}
          showDownloadIcon
          bigImg
          authorActivityPreLoaderDataSources={
            instagramWidgets?.contactActivityPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          iconAfterTitle={instagramIcon}
        />
      </Box>
      {/* //__________________________________________________ */}
      <Typography
        variant="h6"
        mt={5}
        mb={3}
        sx={{ color: "#A4A9AF", fontSize: "14px", fontWeight: 500 }}
      >
        {intl
          .formatMessage({ id: "closed_conversation_depth_analysis" })
          .toUpperCase()}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TableWidget
            wordsList
            title={"insights_top_keywords"}
            titleToolTip="top_kewords_dm_tooltip"
            data={instagramWidgets?.topKeywords}
            leftColomnTitle={"keywords_tab_title"}
            rightColomnTitle={"number_of_conversations"}
            tableChartPreLoaderDataSources={
              instagramWidgets?.topKeywordsPreLoaderDataSources
            }
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
            keywords
            bigImg
            tablePagination
            showDownloadIcon
            count={instagramWidgets?.topKeywords.length}
            iconAfterTitle={instagramIcon}
            withWordCloud
          />
        </Grid>
      </Grid>
      <Typography
        variant="h6"
        mt={5}
        mb={3}
        sx={{ color: "#A4A9AF", fontSize: "14px", fontWeight: 500 }}
      >
        {CheckValueLocale("contacts_overview", "", {}, intl).toUpperCase()}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <PieChartWidget
            title={"genders_distribution"}
            titleToolTip="genders_tooltip"
            data={instagramWidgets?.genders}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            color={["#2AAAE2", "#FF4081"]}
            chartType={"gender"}
            bigImg
            showDownloadIcon
            pieChartPreLoaderDataSources={
              instagramWidgets?.gendersPreLoaderDataSources
            }
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
            iconAfterTitle={instagramIcon}
          />
        </Grid>
        {/* {Keep it may be use them later} */}
        {/* <Grid item md={4}>
          <PieChartWidget
            title={"top_countries"}
            data={countries}
             titleToolTip="top_countries_tooltip"
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            showDownloadIcon
            iconAfterTitle={instagramIcon}
            pieChartPreLoaderDataSources={countriesPreLoaderDataSources}
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          />
        </Grid> */}

        {/* <Grid item md={4}>
          <PieChartWidget
            title={"top_cities"}
            titleToolTip="top_cities_tooltip"
            data={cities}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            showDownloadIcon
            iconAfterTitle={instagramIcon}
            pieChartPreLoaderDataSources={citiesPreLoaderDataSources}
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          />
        </Grid> */}
      </Grid>
      <Typography
        variant="h6"
        mt={5}
        mb={3}
        sx={{ color: "#A4A9AF", fontSize: "14px", fontWeight: 500 }}
      >
        {CheckValueLocale(
          "contacts_in_depth_analysis",
          "",
          {},
          intl,
        ).toUpperCase()}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TableWidget
            circularPP={true}
            itemsList
            titleToolTip="most_engaged_dm_tooltip"
            title={"most_engaged_contacts"}
            data={instagramWidgets?.mostEngagedContacts}
            leftColomnTitle={"user_author"}
            rightColomnTitle={"interactions"}
            tableChartPreLoaderDataSources={
              instagramWidgets?.mostEngagedContactsPreLoaderDataSources
            }
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
            tablePagination
            bigImg
            showDownloadIcon
            count={instagramWidgets?.mostEngagedContacts.length}
            iconAfterTitle={instagramIcon}
          />
        </Grid>
      </Grid>

      <Box my={4}>
        <InteractionsList
          showProfile={true}
          unixDate={unixDate}
          data={instagramWidgets?.latestInteractions.slice(0, 5)}
          title={"latest_interactions"}
          dataSources={props.dataSources}
          isPrivateInstagram
          latestInteractionsPreLoaderDataSources={
            instagramWidgets?.latestInteractionsPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          iconAfterTitle={instagramIcon}
          showViewMoreButton={instagramWidgets?.latestInteractionsCount > 5}
        />
      </Box>
    </>
  );
};

export default InstagramBody;
