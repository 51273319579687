import { Box, TextField, Divider } from "@mui/material";
import { useIntl } from "react-intl";
import "./sideBar.scss";
import {
  CheckValueLocale,
  isEmptyObj,
  handlelUserRoles,
  classNames,
} from "utils/helpers";
import SetupFlow from "../../setup-flow/setupFlow";
import { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "images/engagements-images/close.svg";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import EngagementsController from "services/controllers/engagementsController";
import SnackBar from "components/snackBar";
import HeadingSidebar from "../../headingSidebar/headingSidebar";
import SwitchMenuList from "../../navigationSidebar/switchNavigationContent";
import RefreshIcon from "@mui/icons-material/Refresh";
import EngagementAnalyticsBanner from "../../engagementAnalytics/engagementAnalyticsBanner/engagementAnalyticsBanner";
import { ReactComponent as NewestFisrt } from "images/engagements-images/sort-icons/newestFisrt.svg";
import { ReactComponent as NewestFisrtAr } from "images/engagements-images/sort-icons/newestFirstAr.svg";
import { ReactComponent as OldestFisrt } from "images/engagements-images/sort-icons/oldestFirst.svg";
import { ReactComponent as OldestFisrtAr } from "images/engagements-images/sort-icons/oldestFirstAr.svg";
import { ReactComponent as SmartSorting } from "images/engagements-images/sort-icons/smartSorting.svg";
import { ReactComponent as SmartSortingAr } from "images/engagements-images/sort-icons/smartSortingAr.svg";
import { ReactComponent as TimeToComplete } from "images/engagements-images/sort-icons/timeToComplete.svg";
import { ReactComponent as TimeToCompleteAr } from "images/engagements-images/sort-icons/timeToCompleteAr.svg";
import { ReactComponent as FirstResponseTime } from "images/engagements-images/sort-icons/firstRespnseTime.svg";
import { ReactComponent as FirstResponseTimeAr } from "images/engagements-images/sort-icons/firstResponseTimeAr.svg";
import { ReactComponent as NewxtResponseTime } from "images/engagements-images/sort-icons/nextResponseTime.svg";
import { ReactComponent as NewxtResponseTimeAr } from "images/engagements-images/sort-icons/nextResponseTimeAr.svg";
import { ReactComponent as TotalUnassignedTime } from "images/engagements-images/sort-icons/totalUnassignedTime.svg";
import { ReactComponent as TotalUnassignedTimeAr } from "images/engagements-images/sort-icons/totalUnassignedTimeAr.svg";
import { useSelector, useDispatch } from "react-redux";
import LucButton from "shared/lucButton/lucButton";
import { bulkData } from "utils/redux/features/Engagements/EngagementsSlice";

const SideBarHeading = ({
  filters,
  setFilters,
  openFilters,
  setOpenFilters,
  filtersChangesCount,
  applyFilters,
  dates,
  setDates,
  reduxSelectedCard,
  allData,
  openNavigationMenu,
  setOpenNavigationMenu,
  navigationMenuStatus,
  setNavigationMenuStatus,
  openSetupFlow,
  setOpenSetupFlow,
  openSetupFlowFirst,
  setOpenSetupFlowFirst,
  isDoneSetup,
  setIsDoneSetup,
  setOpenNewConversation,
  openNewConversation,
  samrtSortingNew,
  setSamrtSortingPopup,
  isSLASideBarOpen,
  handleExport,
  exportLoading,
  dateRangePickerParamsRef,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [openSearch, setOpenSearch] = useState(false);
  const [openSortBy, setOpenSortBy] = useState(false);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [monitorsSelectedData, setMonitorsSelectedData] = useState([]);
  const [channelSelectedData, setChannelSelectedData] = useState([]);
  const [snackBar, setSnackBar] = useState({
    open: false,
    title: "",
    msg: "",
    state: "",
  });
  const reduxBulkData = useSelector(
    (state) => state?.engagements?.selectedCardsBulk,
  );
  const [sortOptions] = useState([
    {
      title: "newest_first_sorting",
      value: "newest",
      tooltipText: "newest_first_sorting_tooltip",
      enIcon: <NewestFisrt />,
      arIcon: <NewestFisrtAr />,
    },
    {
      title: "oldest_first_sorting",
      value: "oldest",
      tooltipText: "oldest_first_sorting_tooltip",
      enIcon: <OldestFisrt />,
      arIcon: <OldestFisrtAr />,
    },
    {
      title: "eng_smart_sorting",
      value: "smart_sorting",
      tooltipText: "smart_sorting_tooltip",
      enIcon: <SmartSorting />,
      arIcon: <SmartSortingAr />,
    },
    {
      title: "eng_time_to_complete",
      value: "time_to_complete",
      tooltipText: "time_to_complete_tooltip",
      enIcon: <TimeToComplete />,
      arIcon: <TimeToCompleteAr />,
    },
    {
      title: "eng_first_response_time",
      value: "first_response_time",
      tooltipText: "first_response_time_tooltip",
      enIcon: <FirstResponseTime />,
      arIcon: <FirstResponseTimeAr />,
    },
    {
      title: "eng_next_response_time",
      value: "next_response_time",
      tooltipText: "next_response_time_tooltip",
      enIcon: <NewxtResponseTime />,
      arIcon: <NewxtResponseTimeAr />,
    },
    {
      title: "eng_unassigned_response_time",
      value: "unassigned_response_time",
      tooltipText: "unassigned_response_time_tooltip",
      enIcon: <TotalUnassignedTime />,
      arIcon: <TotalUnassignedTimeAr />,
    },
  ]);
  const [titleToShow, setTitleToShow] = useState({
    tooltipText: "newest_first_sorting_tooltip",
    shortcut: "newest_first_sorting_shortcut",
    arIcon: <NewestFisrtAr />,
    enIcon: <NewestFisrt />,
  });
  const [hasSavedMonitorsFinished, setHasSavedMonitorsFinished] =
    useState(false);
  let isActiveFilters =
    allData?.filters?.data_sources?.length ||
    allData?.filters?.engagement_id?.length ||
    allData?.filters?.engagement_types?.length ||
    allData?.filters?.is_question ||
    allData?.filters?.min_followers ||
    allData?.filters?.parent_id ||
    allData?.filters?.sentiment?.length ||
    allData?.filters?.sub_themes?.length ||
    allData?.filters?.themes?.length ||
    allData?.filters?.usernames?.length ||
    allData?.filters?.verified_status ||
    (allData.monitors?.length &&
      (allData?.monitors ===
      [...monitorsSelectedData, ...channelSelectedData].join(",")
        ? false
        : true));

  // handle date filters
  const handleDatePicker = (startDate, endDate) => {
    dates.startDate = startDate;
    dates.endDate = endDate;
    filters.page = 1;
    setFilters({
      ...filters,
    });
    setDates({ ...dates });
    applyFilters();
  };

  // handle search filters
  const handleSearch = (remove) => {
    if (remove) {
      setIsSearchFocused(true);
      setSearchInput("");
      if (filters?.keywords?.length) {
        filters.keywords = "";
        setFilters({ ...filters });
        applyFilters();
      }
    } else {
      if (filters?.keywords !== searchInput) {
        filters.keywords = searchInput;
        filters.page = 1;
        setFilters({ ...filters });
        applyFilters();
      }
    }
  };

  useEffect(() => {
    if (!window.location.href.includes("alert_id")) {
      EngagementsController.getSavedMonitors(
        window.localStorage.getItem("engagementsProductId"),
      ).then((data) => {
        setIsDoneSetup(data?.data?.engagements_setuped);
        setHasSavedMonitorsFinished(true);
      });
    }
  }, []);

  // this handle open popup to redirect to engagement setup
  useEffect(() => {
    if (!isDoneSetup) {
      setOpenSetupFlow(true);
      setOpenSetupFlowFirst(false);
    }
  }, [isDoneSetup]);

  // handle change sorting items filters
  const sortByItem = (item) => {
    filters.page = 1;
    filters.sort_by = item;
    setFilters({ ...filters });
    applyFilters();
    if (item === "smart_sorting" && !samrtSortingNew) {
      setSamrtSortingPopup(true);
    }
  };

  return (
    <Box className="main-eng-header">
      <Box
        className={classNames(
          "heading-wrapper inbox-main-header",
          navigationMenuStatus === "inbox" ? "inbox-header" : "",
        )}
      >
        <HeadingSidebar
          handleClickReload={() => {
            filters.page = 1;
            setFilters({ ...filters });
            applyFilters();
          }}
          handleSettings={() => setOpenSetupFlow(true)}
          setOpenNewConversation={setOpenNewConversation}
          openNewConversation={openNewConversation}
          openNavigationMenu={openNavigationMenu}
          setOpenNavigationMenu={setOpenNavigationMenu}
          navigationMenuStatus={navigationMenuStatus}
          setNavigationMenuStatus={setNavigationMenuStatus}
          showReload
          isDoneSetup={isDoneSetup}
          startDate={dates?.startDate}
          endDate={dates?.endDate}
          handleDatePicker={handleDatePicker}
          setOpenFilters={setOpenFilters}
          openFilters={openFilters}
          filtersChangesCount={filtersChangesCount}
          isEngagementSidebarMenuOpen={isSLASideBarOpen}
          handleExport={handleExport}
          exportLoading={exportLoading}
          dateRangePickerParamsRef={dateRangePickerParamsRef}
        />
        {openSetupFlow &&
        handlelUserRoles("ENGAGEMENTS", "ENGAGEMENT_SETTINGS") ? (
          <SetupFlow
            openSetupFlowFirst={openSetupFlowFirst}
            filters={filters}
            setFilters={setFilters}
            setSnackBar={setSnackBar}
            setClose={() => setOpenSetupFlow(false)}
            monitorsSelectedData={monitorsSelectedData}
            channelSelectedData={channelSelectedData}
            setMonitorsSelectedData={setMonitorsSelectedData}
            setChannelSelectedData={setChannelSelectedData}
            applyFilters={applyFilters}
            isDoneSetup={isDoneSetup}
          />
        ) : null}
        {isDoneSetup && hasSavedMonitorsFinished ? (
          <EngagementAnalyticsBanner />
        ) : null}
      </Box>
      <SwitchMenuList
        openNavigationMenu={openNavigationMenu}
        setOpenNavigationMenu={setOpenNavigationMenu}
        navigationMenuStatus={navigationMenuStatus}
        setNavigationMenuStatus={setNavigationMenuStatus}
      />
      <Box className="search-date-picker-container">
        {reduxBulkData?.length ? (
          <Box className="bulk-container">
            <Box>
              {CheckValueLocale(
                "bulk_selected",
                "",
                { num: reduxBulkData?.length },
                intl,
              )}
            </Box>
            <LucButton
              variant="flat"
              size="small"
              onClick={() => dispatch(bulkData([]))}
              id="bulk-clear-all"
            >
              {CheckValueLocale("bulk_clear", "", {}, intl)}
            </LucButton>
          </Box>
        ) : (
          <Box>
            <Box className="search-container">
              <Box>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  open={isSearchFocused && !searchInput?.length}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  arrow
                  placement="bottom"
                  // id="search-engag-tooltip"
                  title={CheckValueLocale("search_paragraph", "", {}, intl)}
                >
                  <TextField
                    id="search-input"
                    placeholder={CheckValueLocale(
                      "search_keyword",
                      "",
                      {},
                      intl,
                    )}
                    value={searchInput}
                    inputRef={(input) =>
                      input && isSearchFocused && input.focus()
                    }
                    onChange={(e) => setSearchInput(e.target.value)}
                    onFocus={() => setIsSearchFocused(true)}
                    onBlur={() => setIsSearchFocused(false)}
                    onKeyDown={(e) => {
                      e.key === "Enter" && handleSearch();
                    }}
                    InputProps={{
                      endAdornment: (
                        <Box className="search-icons">
                          {searchInput?.length ? (
                            <Tooltip
                              title={CheckValueLocale("clear", "", {}, intl)}
                              arrow
                              placement="bottom"
                            >
                              <img
                                src={CloseIcon}
                                onClick={() => {
                                  handleSearch(true);
                                }}
                              />
                            </Tooltip>
                          ) : null}
                          <Tooltip
                            title={CheckValueLocale("search", "", {}, intl)}
                            arrow
                            placement="top"
                          >
                            <SearchIcon
                              className={isSearchFocused && "is-focused"}
                              onClick={() => handleSearch()}
                            />
                          </Tooltip>
                          <Divider orientation="vertical" />
                        </Box>
                      ),
                    }}
                    inputProps={{
                      sx: {
                        "&::placeholder": {
                          opacity: 1,
                        },
                      },
                    }}
                  />
                </Tooltip>
              </Box>
              <Tooltip
                title={CheckValueLocale("inbox_refresh", "", {}, intl)}
                arrow
                placement="top"
              >
                <RefreshIcon
                  className="refrsh-icon"
                  onClick={() => {
                    filters.page = 1;
                    setFilters({ ...filters });
                    applyFilters();
                  }}
                />
              </Tooltip>

              <Box
                id="engagements-sort-by-btn"
                className={`icon-container main-sort-by active ${
                  openSortBy && "opened"
                }`}
                onClick={() => setOpenSortBy(!openSortBy)}
              >
                <Tooltip
                  title={CheckValueLocale(
                    titleToShow?.tooltipText,
                    "",
                    {},
                    intl,
                  )}
                  placement="top"
                  arrow
                >
                  <Box className="sort-tooltip-wrapper">
                    {intl?.locale === "ar"
                      ? titleToShow?.arIcon
                      : titleToShow?.enIcon}
                  </Box>
                </Tooltip>
                {openSortBy ? (
                  <ClickAwayListener onClickAway={() => setOpenSortBy(false)}>
                    <Box className="sort-items-container">
                      {sortOptions.map((item, index) => {
                        return (
                          <>
                            <Box
                              key={index}
                              className={`sort-item ${
                                filters?.sort_by === item?.value && "selected"
                              }`}
                              sx={{
                                borderBottom:
                                  index === sortOptions?.length - 1
                                    ? "none"
                                    : "1px solid rgba(241, 245, 249, 1)",
                              }}
                              onClick={() => {
                                sortByItem(item?.value);
                                setTitleToShow(item);
                              }}
                            >
                              <span className="sort-item-title">
                                {CheckValueLocale(item?.title, "", {}, intl)}
                              </span>

                              <Box className="sort-item-icon-wrapper">
                                {intl?.locale === "ar"
                                  ? item.arIcon
                                  : item.enIcon}
                              </Box>
                            </Box>
                          </>
                        );
                      })}
                    </Box>
                  </ClickAwayListener>
                ) : null}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <SnackBar
        open={snackBar?.open}
        severity={snackBar?.state}
        title={CheckValueLocale(snackBar?.title, "", {}, intl)}
        message={CheckValueLocale(snackBar?.msg, "", {}, intl)}
        handleClose={() => setSnackBar({ open: false, msg: "", title: "" })}
      />
    </Box>
  );
};
export default SideBarHeading;
