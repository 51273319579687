import { Box, Grid, Typography } from "@mui/material";
import MediaTw from "components/media/media-tw/mediaTw";
import LineChartWidget from "components/widgets/lineChartWidget";
import { useIntl } from "react-intl";
import BarChartWidget from "components/widgets/barChartWidget";
import PunchCardWidget from "components/widgets/punchCardWidget";
import StackedBarWidget from "components/widgets/stackedBarWidget";
import { CheckValueLocale } from "utils/helpers";

const AccountSection = (props) => {
  let { TwitterIcon, twitterWidgets, preLoaderTrackerDataSources } = props;

  const intl = useIntl();

  return (
    <>
      <Typography className="section-name">
        {CheckValueLocale("account_overview", "", {}, intl).toUpperCase()}
      </Typography>
      <Box mt={3}>
        {/* __________________________________ */}
        <BarChartWidget
          title={"followers_growth"}
          titleToolTip="followers_growth_tw_tooltip"
          data={twitterWidgets?.followersGrowth}
          showGroupBy={true}
          dataColor="#2AAAE2"
          showChartDetailsWidget
          showNotAppliedFilter
          showZoomPercentage
          statsDetailsData={[
            {
              name: "total_followers",
              value: twitterWidgets?.totalFollowersFollowersGrowth,
            },
            {
              name: "max_change_of_followers",
              value: twitterWidgets?.maxChangeFollowersGrowth,
            },
            {
              name: "avg_change_per_day",
              value: twitterWidgets?.avgChangeFollowersGrowth,
            },
          ]}
          toolTipLabel={"posts"}
          showFollowersGrowthStats
          showDownloadIcon
          bigImg
          iconAfterTitle={TwitterIcon}
          barChartPreLoaderDataSources={
            twitterWidgets?.followersGrowthPreLoaderDataSources
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
        {/* __________________________________ */}
      </Box>
      <Box my={3} className="channel-activity">
        <PunchCardWidget
          title="account_authors_activity"
          titleToolTip="account_authors_activity_tw_tooltip"
          clientActivity={twitterWidgets?.authorActivity}
          minValueClient={twitterWidgets?.minValueAuthorActivity}
          maxValueClient={twitterWidgets?.maxValueAuthorActivity}
          showDownloadIcon
          showNotAppliedFilter
          bigImg
          iconAfterTitle={TwitterIcon}
          authorActivityPreLoaderDataSources={
            twitterWidgets?.authorActivityPreLoaderDataSources
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Box>
      <Grid mb={3}>
        <StackedBarWidget
          title={"reach_funnel"}
          titleToolTip="reach_funnel_tw_tooltip"
          showDownloadIcon
          bigImg
          posts={twitterWidgets?.reachFunnel}
          colors={["#64748B", "#2AAAE2"]}
          labels={["Retweets", "original"]}
          reachFunnel
          showScales
          showNotAppliedFilter
          iconAfterTitle={props.TwitterIcon}
          postsColumnPreLoaderDataSources={
            twitterWidgets?.reachFunnelPreLoaderDataSources
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Grid>
      <Typography className="section-name">
        {CheckValueLocale("posts_analytics", "", {}, intl).toUpperCase()}
      </Typography>
      <Box mt={3} className="posts-volume-chart">
        <LineChartWidget
          title={"posts_volume"}
          titleToolTip="posts_volume_public_ig_tooltip"
          totalLabel={"posts"}
          totalValue={twitterWidgets?.totalPostsVolumeCount}
          data={twitterWidgets?.postsVolume}
          showGroupBy={true}
          toolTipLabel={"posts"}
          labelColor={"#2AAAE2"}
          showDownloadIcon
          bigImg
          showNotAppliedFilter
          iconAfterTitle={TwitterIcon}
          lineChartPreLoaderDataSources={
            twitterWidgets?.postsVolumePreLoaderDataSources
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          isHourlyFormat={twitterWidgets?.isPostsVolumeHourlyFormat}
        />
      </Box>
      <Box mt={3}>
        <StackedBarWidget
          title={"posts_interactions"}
          titleToolTip="posts_interactions_tw_tooltip"
          showDownloadIcon
          bigImg
          posts={twitterWidgets?.postsInteractions}
          showChartDetailsWidget
          showScales
          showNotAppliedFilter
          showGroupBy={false}
          statsDetailsData={[
            {
              name: "max_interaction",
              value: twitterWidgets?.maxValuePostsInteractions,
              date: twitterWidgets?.maxValueInteractionsDate,
            },
            {
              name: "min_interaction",
              value: twitterWidgets?.minValuePostsInteractions,
              date: twitterWidgets?.minValueInteractionsDate,
            },
            {
              name: "avg_interaction_per_day",
              value: twitterWidgets?.avgValuePostsInteractions,
            },
          ]}
          colors={["#2AAAE2", "#80868C", "#334155"]}
          labels={["Retweets", "likes", "replies"]}
          showPostsInteractionStats
          showZoomPercentage
          iconAfterTitle={TwitterIcon}
          postsColumnPreLoaderDataSources={
            twitterWidgets?.postsInteractionsPreLoaderDataSources
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          hideLegend
          dataPiechart={twitterWidgets?.postInteractionsLegend}
          isHourlyFormat={twitterWidgets?.isPostsInteractionsHourlyFormat}
        />
      </Box>
      <Box mt={3}>
        <StackedBarWidget
          title={"posts_content_type"}
          titleToolTip="posts_content_type_tw_tooltip"
          posts={twitterWidgets?.postsContentType}
          colors={["#2AAAE2", "#80868C", "#334155", "#AFBCCE"]}
          labels={["video", "image", "url", "text"]}
          showDownloadIcon
          showScales
          showNotAppliedFilter
          bigImg
          showGroupBy={false}
          showZoomPercentage
          iconAfterTitle={TwitterIcon}
          postsColumnPreLoaderDataSources={
            twitterWidgets?.postsContentTypePreLoaderDataSources
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          hideLegend
          dataPiechart={twitterWidgets?.contentTypeLegend}
        />
      </Box>
      <Box my={4}>
        <MediaTw
          title={CheckValueLocale("top_videos", "", {}, intl)}
          titleToolTip="top_videos_tw_tooltip"
          data={twitterWidgets?.topVideos}
          errorMessage={CheckValueLocale("no_videos_found", "", {}, intl)}
          iconAfterTitle={TwitterIcon}
          showNotAppliedFilter
          mediaTwPreLoaderDataSources={
            twitterWidgets?.topVideosPreLoaderDataSources
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Box>
      <Box my={4}>
        <MediaTw
          title={CheckValueLocale("top_images", "", {}, intl)}
          titleToolTip="top_images_tw_tooltip"
          data={twitterWidgets?.topImages}
          errorMessage={CheckValueLocale("no_images_found", "", {}, intl)}
          iconAfterTitle={TwitterIcon}
          showNotAppliedFilter
          mediaTwPreLoaderDataSources={
            twitterWidgets?.topImagesPreLoaderDataSources
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Box>
    </>
  );
};

export default AccountSection;
