import { useMutation } from "@tanstack/react-query";
import { SingleTweetAnalysisServices } from "services/api/singleTweetAnalysisServices";

export const useUpdateSingleTweetAnalysis = () => {
  return useMutation({
    mutationFn: (queryData) => {
      return SingleTweetAnalysisServices.createAnalysisSingleTweet(queryData);
    },
  });
};
