import { useQuery } from "@tanstack/react-query";
import { ReportServices } from "services/api/reports";

export const useReportDatasources = ({ queryData }) => {
  return useQuery({
    queryKey: ["reportDatasources", queryData?.monitor_type_id],
    queryFn: () => ReportServices.getReportDatasources(queryData),
    enabled: Array.isArray(queryData?.monitor_type_id)
      ? !!queryData?.monitor_type_id?.length
      : !!queryData?.monitor_type_id,
    select: (data) => {
      const filteredData = data?.data?.data?.filter(
        (item) =>
          ![
            "GMAIL",
            "INTERCOM",
            "WHATSAPP",
            "GOOGLEMYBUSINESS",
            "CDP",
            "LINKEDIN",
            "TIKTOK",
            "PUBLIC_API",
            "AI_API",
            "GENESYS",
          ]?.includes(item?.attributes?.source) && // We exclude these datasources as they are not supported in Social Listening product
          item?.attributes?.status === "launched", // We exclude datasources that are not launched
      );

      const idsToNames = filteredData?.reduce((acc, item) => {
        acc[item?.attributes?.id] = item?.attributes?.source;
        return acc;
      }, {});

      const namesToIds = filteredData?.reduce((acc, item) => {
        acc[item?.attributes?.source] = item?.attributes?.id;
        return acc;
      }, {});

      return { filteredData, idsToNames, namesToIds };
    },
  });
};
