import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Typography, Box, Grid } from "@mui/material";
import LineChartWidget from "components/widgets/lineChartWidget";
import StackedLineWidget from "components/widgets/stackedLineWidget.js";
import PieChartWidget from "components/widgets/pieChartWidget";
import StackedBarWidget from "components/widgets/stackedBarWidget";
import InteractionsList from "components/interactions-list/InteractionsList.js";
import OverView from "components/widgets/overViewWidget";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import RateReviewWidget from "components/widgets/rateReviewWidget/rateReviewWidget";
import { CheckValueLocale } from "utils/helpers/index";
import LuciFunctions from "pages/luci/components/luciFunctions/luciFunction";

const RatingBody = (props) => {
  let { response } = props;
  const intl = useIntl();

  //___________________________________________________________________________
  const initialWidgetsState = {
    //overview widget
    overView: [],
    overViewPreLoader: [],

    //top keywords widget
    topKeywords: [],
    topKeywordsPreLoader: [],

    //dialects sub dialects widget
    dialects: [],
    subDialects: [],
    dialectsPreLoader: [],

    //countries widget
    topCountries: [],
    topCountriesPreLoader: [],

    //cities widget
    topCities: [],
    topCitiesPreLoader: [],

    //interaction widget
    interactions: [],
    totalInteractions: 0,
    avgInteractions: 0,
    interactionsPreLoaderDataSources: [],

    //sentiment widget
    sentimentAnalysis: [],
    sentimentPieChart: [],
    negativeArray: [],
    positiveArray: [],
    neutralArray: [],
    sentimentAnalysisPreLoader: [],

    //whereFindBusiness widget
    whereFindBusiness: [],
    whereFindBusinessPreLoader: [],
    whereFindBusinessLegend: [],

    //genderDistribution widget
    genderDistribution: [],
    genderDistributionPreLoader: [],

    //rateReviews widget
    rateReviews: [],
    rateReviewsPreLoader: [],

    //listOfReviews widget
    listOfReviews: [],
    listOfReviewsCount: 0,
    listOfReviewsPreLoader: [],
  };

  const [ratingsWidgets, setRatingsWidgets] = useState(initialWidgetsState);

  const { checkLuciDataIsFounded, checkLuciAllDataFounded } = LuciFunctions();

  useEffect(() => {
    switch (response?.eventName) {
      case "Googlemybusiness__CASingleDataSourceInsightsPage__overview":
        let overViewArray = Object?.entries(response?.eventData?.overview)?.map(
          (key) => ({
            name: key[0],
            value: key[1],
          }),
        );
        setRatingsWidgets({
          ...ratingsWidgets,
          overView: [...overViewArray],
          overViewPreLoader: [response?.data_source],
        });
        break;
      case "Googlemybusiness__CASingleDataSourceInsightsPage__volume_overtime":
        setRatingsWidgets({
          ...ratingsWidgets,
          interactions: response?.eventData?.reviews,
          totalInteractions: response?.eventData?.total_reviews,
          interactionsPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Googlemybusiness__CASingleDataSourceInsightsPage__sentiment_analysis":
        Object.entries(response?.eventData?.sentiment_overtime).forEach(
          ([key, value]) => {
            switch (key) {
              case "negative":
                value?.map((i) => {
                  return ratingsWidgets?.negativeArray.push(i);
                });
                break;
              case "positive":
                value?.map((i) => {
                  return ratingsWidgets?.positiveArray.push(i);
                });
                break;
              case "neutral":
                value?.map((i) => {
                  return ratingsWidgets?.neutralArray.push(i);
                });
                break;
              default:
                break;
            }
          },
        );
        setRatingsWidgets({
          ...ratingsWidgets,
          sentimentAnalysis: {
            negative: ratingsWidgets?.negativeArray,
            positive: ratingsWidgets?.positiveArray,
            neutral: ratingsWidgets?.neutralArray,
          },
          sentimentPieChart: response?.eventData?.sentiment_piechart,
          sentimentAnalysisPreLoader: [response?.data_source],
        });
        break;
      case "Googlemybusiness__CASingleDataSourceInsightsPage__where_find_your_business":
        setRatingsWidgets({
          ...ratingsWidgets,
          whereFindBusiness: response?.eventData?.business_on_google,
          whereFindBusinessLegend:
            response?.eventData?.business_on_google_legends,
          whereFindBusinessPreLoader: [response?.data_source],
        });
        break;
      case "Googlemybusiness__CASingleDataSourceInsightsPage__top_countries":
        setRatingsWidgets({
          ...ratingsWidgets,
          topCountries: response?.eventData?.top_countries,
          topCountriesPreLoader: [response?.data_source],
        });
        break;
      case "Googlemybusiness__CASingleDataSourceInsightsPage__top_cities":
        setRatingsWidgets({
          ...ratingsWidgets,
          topCities: response?.eventData?.top_cities,
          topCitiesPreLoader: [response?.data_source],
        });
        break;
      case "Googlemybusiness__CASingleDataSourceInsightsPage__gender_distribution":
        setRatingsWidgets({
          ...ratingsWidgets,
          genderDistribution: response?.eventData?.gender_distribution,
          genderDistributionPreLoader: [response?.data_source],
        });
        break;
      case "Googlemybusiness__CASingleDataSourceInsightsPage__dialects_and_sub_dialects":
        setRatingsWidgets({
          ...ratingsWidgets,
          dialects: response?.eventData?.dialects,
          subDialects: response?.eventData?.sub_dialects,
          dialectsPreLoader: [response?.data_source],
        });
        break;
      case "Googlemybusiness__CASingleDataSourceInsightsPage__top_keywords":
        setRatingsWidgets({
          ...ratingsWidgets,
          topKeywords: response?.eventData?.top_keywords,
          topKeywordsPreLoader: [response?.data_source],
        });
        break;
      case "Googlemybusiness__CASingleDataSourceInsightsPage__rate_reviews":
        setRatingsWidgets({
          ...ratingsWidgets,
          rateReviews: response?.eventData?.rate_reviews,
          rateReviewsPreLoader: [response?.data_source],
        });
        break;
      case "Googlemybusiness__CASingleDataSourceInsightsPage__top_posts_comments":
        setRatingsWidgets({
          ...ratingsWidgets,
          listOfReviews: response?.eventData?.top_posts,
          listOfReviewsPreLoader: [response?.data_source],
          listOfReviewsCount: response?.eventData?.interactions_count,
        });
        break;
      default:
        break;
    }
  }, [response]);

  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (props.resetAllFields !== 1) {
      setRatingsWidgets({ ...initialWidgetsState });
    }
  }, [props.resetAllFields]);

  useEffect(() => {
    let x = checkLuciAllDataFounded(
      ratingsWidgets?.listOfReviewsPreLoader,
      props.preLoaderTrackerDataSources,
    );
    let y = checkLuciDataIsFounded([ratingsWidgets?.listOfReviews]);
    props.setCheckAllDataExist(x);
    props.setCheckDataIsEmpty(y);
  }, [
    ratingsWidgets?.listOfReviews,
    ratingsWidgets?.listOfReviewsPreLoader,
    props.preLoaderTrackerDataSources,
  ]);

  return (
    <>
      <OverView
        data={ratingsWidgets?.overView}
        isGmbPage
        overViewPreLoaderDataSources={ratingsWidgets?.overViewPreLoader}
        preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
      />

      <Typography
        variant="h6"
        mt={5}
        mb={3}
        sx={{ color: "#A4A9AF", fontSize: "14px", fontWeight: 500 }}
      >
        {CheckValueLocale("metrics", "", {}, intl).toUpperCase()}
      </Typography>

      <LineChartWidget
        title={"reviews"}
        titleToolTip="gmb_reviews_tooltip"
        totalLabel={"reviews"}
        totalValue={ratingsWidgets?.totalInteractions}
        data={ratingsWidgets?.interactions}
        showGroupBy={true}
        toolTipLabel={"reviews"}
        labelColor={"#2AAAE2"}
        showDownloadIcon
        bigImg
        lineChartPreLoaderDataSources={
          ratingsWidgets?.interactionsPreLoaderDataSources
        }
        preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
      />
      <Box my={3}>
        <StackedLineWidget
          title={"reviews_sentiment_analysis"}
          titleToolTip="reviews_sentiment_analysis_tooltip"
          data={ratingsWidgets?.sentimentAnalysis}
          dataPiechart={ratingsWidgets?.sentimentPieChart}
          hideLegend
          showGroupBy={true}
          showScales
          areaStyle={true}
          toolTipLabel={"toolTipLabel"}
          labelColors={["#89BB2A", "#E50035", "#F9A700"]}
          showDownloadIcon
          bigImg
          stackedLineWidgetPreLoaderDataSources={
            ratingsWidgets?.sentimentAnalysisPreLoader
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          isSwitchCase
          isSentimentAnalysisShowCharts
        />
      </Box>

      <Box mt={3}>
        <StackedBarWidget
          title={"where_find_your_business"}
          titleToolTip="where_find_business_tooltip"
          showDownloadIcon
          bigImg
          posts={ratingsWidgets?.whereFindBusiness}
          colors={["#2AAAE2", "#80868C"]}
          labels={["listing_on_search", "listing_on_maps"]}
          showScales
          showZoomPercentage
          postsColumnPreLoaderDataSources={
            ratingsWidgets?.whereFindBusinessPreLoader
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          hideLegend
          dataPiechart={ratingsWidgets?.whereFindBusinessLegend}
        />
      </Box>

      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <PieChartWidget
              title="top_countries"
              titleToolTip="gmb_top_countries_tooltip"
              showDownloadIcon={true}
              data={ratingsWidgets?.topCountries}
              pieChartPreLoaderDataSources={
                ratingsWidgets?.topCountriesPreLoader
              }
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
            />
          </Grid>
          <Grid item xs={6}>
            <PieChartWidget
              title={"top_cities"}
              titleToolTip="gmb_top_cities_tooltip"
              data={ratingsWidgets?.topCities}
              areaStyle={true}
              toolTipLabel={"toolTipLabel"}
              showDownloadIcon
              pieChartPreLoaderDataSources={ratingsWidgets?.topCitiesPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
            />
          </Grid>
        </Grid>
      </Box>

      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <PieChartWidget
              title={"gender_distribution"}
              titleToolTip="gmb_gender_dist_tooltip"
              data={ratingsWidgets?.genderDistribution}
              areaStyle={true}
              toolTipLabel={"toolTipLabel"}
              color={["#2AAAE2", "#FF4081"]}
              showDownloadIcon
              showAI
              pieChartPreLoaderDataSources={
                ratingsWidgets?.genderDistributionPreLoader
              }
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
            />
          </Grid>
          <Grid item xs={6}>
            <PieChartWidget
              title={"dialects_subdialects"}
              titleToolTip="gmb_dialects_subdialects_tooltip"
              data={ratingsWidgets?.dialects}
              subData={ratingsWidgets?.subDialects}
              areaStyle={true}
              toolTipLabel={"toolTipLabel"}
              showAI
              showDownloadIcon
              pieChartPreLoaderDataSources={ratingsWidgets?.dialectsPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
            />
          </Grid>
        </Grid>
      </Box>

      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TableWidget
              wordsList
              title={"reviews_top_keywords"}
              titleToolTip="gmb_top_keywords_tooltip"
              showDownloadIcon={true}
              data={ratingsWidgets?.topKeywords}
              leftColomnTitle={"keyword"}
              rightColomnTitle={"number_of_reviews"}
              tableChartPreLoaderDataSources={
                ratingsWidgets?.topKeywordsPreLoader
              }
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              hashtags
              tablePagination
              count={ratingsWidgets?.topKeywords?.length}
              reduceHeight
              dynamicClass={false}
              dataSource={"googlemybusiness"}
            />
          </Grid>
        </Grid>
      </Box>

      <Typography className="section-name">
        {CheckValueLocale("rate_reviews", "", {}, intl).toUpperCase()}
      </Typography>

      <Box my={3}>
        <RateReviewWidget
          rateReviews={ratingsWidgets?.rateReviews}
          rateReviewPreLoaderDataSources={ratingsWidgets?.rateReviewsPreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Box>

      <Box my={3}>
        <InteractionsList
          showProfile={true}
          data={ratingsWidgets?.listOfReviews?.slice(0, 10)}
          title={"latest_reviews"}
          dataSources={props.dataSources}
          isGoogleMyBusinessPage
          latestInteractionsPreLoaderDataSources={
            ratingsWidgets?.listOfReviewsPreLoader
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          component={"google_my_business"}
          showViewMoreButton={ratingsWidgets?.listOfReviewsCount > 10}
        />
      </Box>
    </>
  );
};

export default RatingBody;
