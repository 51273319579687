import { Box, Divider, Tooltip, Typography } from "@mui/material";
import profileImg from "images/engagements-images/profileImg.png";
import { faLink } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import {
  CheckValueLocale,
  extractDisplayInfoFromDataSource,
  handlelUserRoles,
} from "utils/helpers";
import { useIntl } from "react-intl";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

const formatFollowersCount = (followersCount) => {
  if (!followersCount) return "-";
  return Intl.NumberFormat("en-US", {
    notation: "compact",
  }).format(followersCount);
};

const SOCIAL_MEDIA_URLS = {
  twitter: (username) => `https://twitter.com/${username}`,
  instagram: (username) => `https://www.instagram.com/${username}`,
  facebook: (username) => `https://www.facebook.com/${username}`,
  whatsapp: (username) => `https://wa.me/${username}`,
};

const Profile = ({
  profileData,
  selectedCard,
  showTotalEngagementsHandler,
}) => {
  const intl = useIntl();
  const [copied, setCopied] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    navigator.clipboard.writeText(`
      ${window.location.origin}
      /audience/user-profile/
      ${profileData?.profile_id}
    `);
    setTimeout(() => {
      setCopied(false);
    }, 2500);
  };

  const activeCdp = JSON.parse(window.localStorage?.activeProducts).filter(
    (product) => product?.active && product?.name == "CDP",
  );

  const handleImageError = (event) => {
    event.target.src = profileImg;
  };
  const isTwitter = selectedCard?.data_source?.toLowerCase?.() === "twitter";
  const isInstagram =
    selectedCard?.data_source?.toLowerCase?.() === "instagram";
  const isFacebook = selectedCard?.data_source?.toLowerCase?.() === "facebook";
  const isWhatsapp = selectedCard?.data_source?.toLowerCase?.() === "whatsapp";
  const isDM = selectedCard?.isDm;
  const isRTL = intl?.locale === "ar";
  const { profilePicture, displayName, subName } =
    extractDisplayInfoFromDataSource(selectedCard, {
      displayName: selectedCard?.user_name
        ? isDM && (isTwitter || isInstagram)
          ? selectedCard?.name
          : selectedCard?.user_name
        : "user",
      subName:
        isTwitter || isInstagram
          ? isDM
            ? selectedCard?.user_name
            : selectedCard?.screen_name
          : null,
      profilePicture: profileData?.profile_image
        ? profileData?.profile_image
        : profileImg,
    });

  const handleVisitProfile = () => {
    window.open(`/audience/user-profile/${profileData?.profile_id}`, "_blank");
  };

  const isSocialMediaProfile =
    isTwitter || isInstagram || isFacebook || isWhatsapp;

  const handleVisitSocialMediaProfile = () => {
    if (!isSocialMediaProfile) return;
    const socialMediaDataSource = selectedCard?.data_source?.toLowerCase();
    const profileUrlGenerator = SOCIAL_MEDIA_URLS[socialMediaDataSource];
    if (profileUrlGenerator) {
      window.open(profileUrlGenerator(selectedCard?.user_name), "_blank");
    }
  };

  const canVisitCDP =
    profileData?.profile_id &&
    handlelUserRoles("CDP", "VIEW_PROFILE") &&
    activeCdp?.length;

  return (
    <Box className="engager-profile-box-wrapper">
      <Box className="engager-profile-info-wrapper">
        <img
          src={profilePicture || profileImg}
          onError={handleImageError}
          className="engager-profile-image"
        />
        <Box>
          <Typography className="engager-profile-followers-engagement-title">
            {CheckValueLocale("followers", "", {}, intl)}
          </Typography>
          <Typography className="engager-profile-followers-value">
            {formatFollowersCount(selectedCard?.followers_count)}
          </Typography>
        </Box>
        <Box>
          <Tooltip
            arrow
            placement="top-start"
            title={
              <Box className="engager-show-engagement-tooltip">
                {CheckValueLocale(
                  "engager_profile_engagements_tooltip",
                  "",
                  {
                    dataSource: CheckValueLocale(
                      selectedCard?.data_source,
                      "",
                      {},
                      intl,
                    ),
                  },
                  intl,
                )}
              </Box>
            }
          >
            <Typography className="engager-profile-followers-engagement-title with-border">
              {CheckValueLocale("engagements", "", {}, intl)}
            </Typography>
          </Tooltip>
          <Typography
            className="engager-profile-engagements-value-wrapper"
            onClick={showTotalEngagementsHandler}
          >
            <FontAwesomeIcon icon={faEye} />
            {CheckValueLocale("show", "", {}, intl)}
          </Typography>
        </Box>
      </Box>
      <Tooltip
        arrow
        placement="top"
        title={
          isSocialMediaProfile
            ? CheckValueLocale(
                "visit_engager_profile",
                "",
                {
                  dataSource: CheckValueLocale(
                    selectedCard?.data_source,
                    "",
                    {},
                    intl,
                  ),
                },
                intl,
              )
            : ""
        }
      >
        {(isTwitter || (isInstagram && isDM)) && subName ? (
          <Typography
            onClick={handleVisitSocialMediaProfile}
            className="engager-profile-actions"
          >
            {isRTL ? `${subName}@` : `@${subName}`}
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
          </Typography>
        ) : null}
      </Tooltip>
      {canVisitCDP ? (
        <Box className="engager-profile-actions-wrapper">
          <Typography
            className="engager-profile-actions"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={handleVisitProfile}
          >
            {CheckValueLocale("see_full_profile", "", {}, intl)}
          </Typography>
          {!isHovered ? (
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              className="engager-profile-divider"
            />
          ) : (
            <Box className="engager-divider-placeholder" />
          )}
          <Tooltip
            arrow
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            placement="top"
            id={`${copied ? "copied-tooltip" : "copy-tooltip"}`}
            title={
              copied
                ? CheckValueLocale("copied", "", {}, intl)
                : CheckValueLocale("copy_profile_link", "", {}, intl)
            }
          >
            <FontAwesomeIcon
              icon={faLink}
              onClick={handleCopy}
              className="copy-engager-profile-link-icon"
            />
          </Tooltip>
        </Box>
      ) : null}
    </Box>
  );
};
export default Profile;
