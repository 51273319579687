import { useQuery } from "@tanstack/react-query";
import { CreateMonitorServices } from "services/api/createMonitorServices";

export const useGetHashtagsLimit = (queryData) => {
  return useQuery({
    queryKey: ["hashtagsLimit", queryData],
    queryFn: () => CreateMonitorServices.getHashtagsLimit(queryData),
    select: (data) => data?.data,
  });
};
