import { useMutation, useQueryClient } from "@tanstack/react-query";
import UserAccount from "services/controllers/userAccountController";

export const useResendCode = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => UserAccount?.resendCode2FA(),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["userTwoFactorAuth"],
      });
    },
  });
};
