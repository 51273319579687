export const handleSinglePostOverviewResponse = (response, dispatch) => {
  switch (response?.eventName) {
    case "Twitter__SingleTweetAnalysisPage__single_tweet_overview":
      dispatch({
        type: "SET_MONITOR_OVERVIEW_DATA",
        payload: {
          overview: response?.eventData?.overview,
          monitor_id: response?.monitor_id,
        },
      });
      break;
    case "Twitter__SingleTweetAnalysisPage__single_tweet":
      dispatch({
        type: "SET_TWEET_DATA",
        payload: {
          tweet: response?.eventData?.tweet,
          monitor_id: response.monitor_id,
        },
      });
      break;
    default:
      break;
  }
};
