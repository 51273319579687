import {
  Alert,
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Switch,
  Tab,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { TabContext, TabList } from "@mui/lab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIntl } from "react-intl";
import { greetingFarewellMessagesTaps } from "../utils/farewellGreetingUtils";
import { CheckValueLocale } from "utils/helpers";
import AddEditPublicReply from "./addEditPublicReply";
import PublicReplyBox from "../components/publicReplyBox";
import {
  useSocialAccounts,
  useFilteredDataBySource,
  socialIcons,
  getActualSlectedMonitors,
  filterMonitorsAndIDs,
  MonitorAndChannelsMenuItem,
  SelectItemsTitle,
} from "../utils/publicRepliesUtils";
import CustomizedTooltip from "components/customizedTooltip";
import LucButton from "shared/lucButton/lucButton";
import _ from "lodash";
import DeletePublicReplyModal from "./deletePublicReplyModal";
import automated from "images/engagements-images/survey/automated.svg";

const AutomatedPublicRepliesBasicSwitch = ({
  title,
  description,
  checked,
  handleChange,
  id,
}) => {
  const intl = useIntl();
  return (
    <Box>
      <Box className="main-swich-container">
        <img src={automated} />
        <FormControlLabel
          className="greeting-farewell-message-switch-form-control-label"
          classes={{ label: "greeting-farewell-message-switch-label" }}
          control={
            <Switch
              size="small"
              className="greeting-farewell-message-switch"
              checked={checked}
              onChange={handleChange}
              id={id}
            />
          }
          labelPlacement="start"
          label={CheckValueLocale(title, "", {}, intl)}
        />
      </Box>

      <FormHelperText className="greeting-farewell-message-switch-helperText">
        {CheckValueLocale(description, "", {}, intl)}
      </FormHelperText>
    </Box>
  );
};

const AutomatedPublicReplies = ({
  className,
  selectedData,
  automatedPublicRepliesData,
  setAutomatedPublicRepliesData,
  autoReplySelectedTab,
  setAutoReplySelectedTab,
  autoReplyErrorFields,
  setAutoReplyErrorFields,
  initialselectedData,
}) => {
  const intl = useIntl();
  const [isAddReplyModalOpen, setIsAddReplyModalOpen] = useState(false);
  const [isEditingExistingReply, setIsEditingExistingReply] = useState(false);
  const [isDeleteReplyModalOpen, setIsDeleteReplyModalOpen] = useState(false);
  const [replyToBeDeleted, setReplyToBeDeleted] = useState({});
  const [currentReply, setCurrentReply] = useState({});

  const {
    facebook: facebookMonitors,
    instagram: instagramMonitors,
    twitter: xPlatformMonitors,
    isSetupFilteredDone: isSetupFilteredMonitors,
  } = useFilteredDataBySource(selectedData, "monitors");

  const monitorsFilteredData = useFilteredDataBySource(
    selectedData,
    "monitors",
  );
  const channelsFilteredData = useFilteredDataBySource(
    selectedData,
    "channels",
  );

  const {
    facebook: facebookChannels,
    instagram: instagramChannels,
    twitter: xPlatformChannels,
    isSetupFilteredDone: isSetupFilteredChannels,
  } = useFilteredDataBySource(selectedData, "channels");

  const {
    accounts: facebookAccounts,
    isSetupFilteredDone: facebookAccountsSetupDone,
  } = useSocialAccounts(selectedData, "FACEBOOK");
  const {
    accounts: xPlatformAccounts,
    isSetupFilteredDone: twitterAccountsSetupDone,
  } = useSocialAccounts(selectedData, "TWITTER");
  const {
    accounts: instagramAccounts,
    isSetupFilteredDone: instagramAccountsSetupDone,
  } = useSocialAccounts(selectedData, "INSTAGRAM");

  const handleSwitchChange = () => {
    setAutomatedPublicRepliesData((prev) => ({
      ...prev,
      [upperCaseSelectedTap]: {
        ...prev[upperCaseSelectedTap],
        status: !isCurrentTabReplySwitchEnabled,
      },
    }));
  };

  const handleMonitorsAndChannelsSelect = (e) => {
    const value = e?.target?.value;
    setAutomatedPublicRepliesData((prev) => ({
      ...prev,
      [upperCaseSelectedTap]: {
        ...prev[upperCaseSelectedTap],
        monitors: [...value],
      },
    }));
    if (e?.target?.value?.length) {
      setAutoReplyErrorFields((prev) => ({
        ...prev,
        [upperCaseSelectedTap]: {
          ...prev[upperCaseSelectedTap],
          monitors: false,
        },
      }));
    }
  };

  const handleReplyBySelect = (e) => {
    const value = e?.target?.value;
    setAutomatedPublicRepliesData((prev) => ({
      ...prev,
      [upperCaseSelectedTap]: {
        ...prev[upperCaseSelectedTap],
        accounts: [value],
      },
    }));
    if (value) {
      setAutoReplyErrorFields((prev) => ({
        ...prev,
        [upperCaseSelectedTap]: {
          ...prev[upperCaseSelectedTap],
          reply_by: false,
        },
      }));
    }
  };

  const openDeleteReplyModal = (reply) => {
    setReplyToBeDeleted(reply);
    setIsDeleteReplyModalOpen(true);
  };

  const handleDeleteReply = (reply) => {
    if (reply) {
      let index;
      let id;

      const clonedAutoReplyDataSourceData = _.cloneDeep({
        ...automatedPublicRepliesData,
      })[upperCaseSelectedTap];

      const existingReplies = [...clonedAutoReplyDataSourceData?.replies];
      if (reply?.hasOwnProperty("id")) {
        index = existingReplies?.findIndex((item) => item?.id === reply?.id);
        id = reply?.id;
      } else {
        index = existingReplies?.findIndex(
          (item) => item?.reply_text === reply?.reply_text,
        );
      }
      existingReplies?.splice(index, 1);

      setAutomatedPublicRepliesData((prev) => ({
        ...prev,
        [upperCaseSelectedTap]: {
          ...prev[upperCaseSelectedTap],
          replies: [...existingReplies],
        },
      }));
      if (id) storeDeletedReplyId(id);
    }
  };

  const storeDeletedReplyId = (id) => {
    const publicRepliesDataClone = _.cloneDeep({
      ...automatedPublicRepliesData,
    });
    const deletedRepliesArray =
      publicRepliesDataClone[upperCaseSelectedTap]?.deletedReplies || [];

    setAutomatedPublicRepliesData((prev) => ({
      ...prev,
      [upperCaseSelectedTap]: {
        ...prev[upperCaseSelectedTap],
        deletedReplies: [...deletedRepliesArray, id],
      },
    }));
  };

  const getReplies = (dataSource) => {
    return automatedPublicRepliesData[dataSource]?.replies;
  };

  const maxNumberOfRepliesPerDataSource = 15;
  const isTwitterSelected =
    autoReplySelectedTab === "twitter" &&
    automatedPublicRepliesData?.TWITTER?.status;
  const isIgSelected =
    autoReplySelectedTab === "instagram" &&
    automatedPublicRepliesData?.INSTAGRAM?.status;
  const upperCaseSelectedTap = autoReplySelectedTab?.toUpperCase();
  const isCurrentTabReplySwitchEnabled =
    !!automatedPublicRepliesData[upperCaseSelectedTap]?.status;
  const currentTapRepliesCount =
    automatedPublicRepliesData[upperCaseSelectedTap]?.replies?.length || 0;

  const monitors =
    autoReplySelectedTab === "twitter"
      ? xPlatformMonitors
      : autoReplySelectedTab === "instagram"
        ? instagramMonitors
        : facebookMonitors;

  const channels =
    autoReplySelectedTab === "twitter"
      ? xPlatformChannels
      : autoReplySelectedTab === "instagram"
        ? instagramChannels
        : facebookChannels;

  const accounts =
    autoReplySelectedTab === "twitter"
      ? xPlatformAccounts
      : autoReplySelectedTab === "instagram"
        ? instagramAccounts
        : facebookAccounts;

  useEffect(() => {
    if (
      facebookAccountsSetupDone &&
      instagramAccountsSetupDone &&
      twitterAccountsSetupDone &&
      initialselectedData?.initaledDataDone
    ) {
      let filteredTwitterAccounts = [
        ...filterMonitorsAndIDs(
          automatedPublicRepliesData?.TWITTER?.accounts,
          xPlatformAccounts,
        ),
      ];
      let filteredFacebookAccounts = [
        ...filterMonitorsAndIDs(
          automatedPublicRepliesData?.FACEBOOK?.accounts,
          facebookAccounts,
        ),
      ];
      let filteredInstagramAccounts = [
        ...filterMonitorsAndIDs(
          automatedPublicRepliesData?.INSTAGRAM?.accounts,
          instagramAccounts,
        ),
      ];

      // we check data after select/unselect monitors & channels above
      setAutomatedPublicRepliesData((prev) => ({
        ...prev,
        TWITTER: {
          ...prev["TWITTER"],
          accounts: [...filteredTwitterAccounts],
        },
        FACEBOOK: {
          ...prev["FACEBOOK"],
          accounts: [...filteredFacebookAccounts],
        },
        INSTAGRAM: {
          ...prev["INSTAGRAM"],
          accounts: [...filteredInstagramAccounts],
        },
      }));
    }
  }, [selectedData, facebookAccounts, instagramAccounts, xPlatformAccounts]);

  useEffect(() => {
    if (
      isSetupFilteredMonitors &&
      initialselectedData.initaledDataDone &&
      isSetupFilteredChannels
    ) {
      let instagramMonitorsAndChannels = [
        ...monitorsFilteredData?.instagram,
        ...channelsFilteredData?.instagram,
      ];
      let facebookMonitorsAndChannels = [
        ...monitorsFilteredData?.facebook,
        ...channelsFilteredData?.facebook,
      ];
      let twitterMonitorsAndChannels = [
        ...monitorsFilteredData?.twitter,
        ...channelsFilteredData?.twitter,
      ];
      let filteredTWmonitorsChannels = [
        ...filterMonitorsAndIDs(
          automatedPublicRepliesData?.TWITTER?.monitors,
          twitterMonitorsAndChannels,
        ),
      ];
      let filteredFBmonitorsChannels = [
        ...filterMonitorsAndIDs(
          automatedPublicRepliesData?.FACEBOOK?.monitors,
          facebookMonitorsAndChannels,
        ),
      ];
      let filteredIGmonitorsChannels = [
        ...filterMonitorsAndIDs(
          automatedPublicRepliesData?.INSTAGRAM?.monitors,
          instagramMonitorsAndChannels,
        ),
      ];

      // we check data after select/unselect monitors & channels above
      setAutomatedPublicRepliesData((prev) => ({
        ...prev,
        TWITTER: {
          ...prev["TWITTER"],
          monitors: [...filteredTWmonitorsChannels],
        },
        FACEBOOK: {
          ...prev["FACEBOOK"],
          monitors: [...filteredFBmonitorsChannels],
        },
        INSTAGRAM: {
          ...prev["INSTAGRAM"],
          monitors: [...filteredIGmonitorsChannels],
        },
      }));
    }
  }, [selectedData, monitorsFilteredData, channelsFilteredData]);

  return (
    <Box className={`greeting-farewell-message-wrapper ${className}`}>
      <Stack direction="row" className="automated-replies-header-stack">
        <TabContext value={autoReplySelectedTab}>
          <Box className="data-sources-tap-container">
            <TabList
              onChange={(_, newValue) => setAutoReplySelectedTab(newValue)}
            >
              {greetingFarewellMessagesTaps(intl)
                ?.filter((item) => item?.id !== "whatsapp")
                ?.map((tap) => (
                  <Tab
                    key={tap?.label}
                    label={tap?.label}
                    icon={<FontAwesomeIcon icon={tap?.icon} />}
                    iconPosition="start"
                    value={tap?.id}
                  />
                ))}
            </TabList>
          </Box>
        </TabContext>
        {isCurrentTabReplySwitchEnabled ? (
          <CustomizedTooltip
            title={
              currentTapRepliesCount >= maxNumberOfRepliesPerDataSource
                ? CheckValueLocale(
                    "reached_max_number_of_replies",
                    "",
                    {},
                    intl,
                  )
                : null
            }
            placement="bottom"
            arrow
            id="max-public-replies-tooltip"
          >
            <Box>
              <LucButton
                id="engagement-settings-new-public-reply-btn"
                variant="outline"
                onClick={() => {
                  if (
                    currentTapRepliesCount < maxNumberOfRepliesPerDataSource
                  ) {
                    setIsAddReplyModalOpen(true);
                    setIsEditingExistingReply(false);
                  }
                }}
                disabled={
                  currentTapRepliesCount >= maxNumberOfRepliesPerDataSource
                }
              >
                <Typography sx={{ marginInlineEnd: "6px" }}>+</Typography>
                {CheckValueLocale("new_public_reply_button", "", {}, intl)}
              </LucButton>
            </Box>
          </CustomizedTooltip>
        ) : null}
      </Stack>
      <Box className={"main-grating-countainer"}>
        <AutomatedPublicRepliesBasicSwitch
          title="send_automated_public_reply"
          description="send_automated_public_reply_description"
          checked={isCurrentTabReplySwitchEnabled}
          handleChange={handleSwitchChange}
          id={`${autoReplySelectedTab}-enable-switch`}
        />
        {isIgSelected || isTwitterSelected ? (
          <Alert
            severity={isTwitterSelected ? "info" : "warning"}
            className="automated-replies-twitter-alert"
          >
            {CheckValueLocale(
              isTwitterSelected
                ? "automated_public_reply_x_platform_alert_box"
                : "automated_public_reply_ig_kw_alert_box",
              "",
              {},
              intl,
            )}
          </Alert>
        ) : null}

        {isCurrentTabReplySwitchEnabled ? (
          <Box>
            <Stack
              direction="row"
              sx={{
                justifyContent: "center",
                alignItems: "flex-start",
                gap: "30px",
                marginBottom: "30px",
              }}
            >
              <FormControl
                sx={{ width: "100%" }}
                className="select-menu"
                size="small"
              >
                <InputLabel
                  id="monitors-select-label"
                  error={autoReplyErrorFields[upperCaseSelectedTap]?.monitors}
                >
                  {CheckValueLocale(
                    "automated_reply_public_monitors_channels",
                    "",
                    {},
                    intl,
                  )}
                </InputLabel>
                <Select
                  id="automated-reply-monitors-select"
                  labelId="monitors-select-label"
                  multiple
                  error={autoReplyErrorFields[upperCaseSelectedTap]?.monitors}
                  value={
                    automatedPublicRepliesData[upperCaseSelectedTap]?.monitors
                  }
                  onChange={(e) => {
                    handleMonitorsAndChannelsSelect(e);
                  }}
                  input={
                    <OutlinedInput
                      label={CheckValueLocale(
                        "automated_reply_public_monitors_channels",
                        "",
                        {},
                        intl,
                      )}
                      size="small"
                    />
                  }
                  sx={{ height: "40px" }}
                  size="small"
                  renderValue={(selected) =>
                    CheckValueLocale(
                      "num_select",
                      "",
                      {
                        num: getActualSlectedMonitors(
                          selected,
                          monitors,
                          channels,
                        ),
                      },
                      intl,
                    )
                  }
                >
                  {monitors?.length > 0 ? (
                    <SelectItemsTitle
                      title="select_engagement_monitors"
                      intl={intl}
                    />
                  ) : null}

                  {monitors?.length > 0
                    ? monitors?.map((monitor) => (
                        <MenuItem
                          key={`monitor-${monitor?.id}`}
                          value={monitor?.attributes?.id}
                          className="public-reply-monitor-item"
                        >
                          <MonitorAndChannelsMenuItem
                            item={monitor}
                            isSelected={automatedPublicRepliesData[
                              upperCaseSelectedTap
                            ]?.monitors?.some(
                              (id) => id === monitor?.attributes?.id,
                            )}
                          />
                        </MenuItem>
                      ))
                    : null}

                  {channels?.length > 0 ? (
                    <SelectItemsTitle
                      title="select_engagement_channels"
                      intl={intl}
                    />
                  ) : null}

                  {channels?.length > 0
                    ? channels?.map((channel) => (
                        <MenuItem
                          key={`monitor-${channel.id}`}
                          value={channel?.attributes?.id}
                          className="public-reply-monitor-item"
                        >
                          <MonitorAndChannelsMenuItem
                            item={channel}
                            isSelected={automatedPublicRepliesData[
                              upperCaseSelectedTap
                            ]?.monitors?.some(
                              (id) => id === channel?.attributes?.id,
                            )}
                          />
                        </MenuItem>
                      ))
                    : null}
                </Select>
                {autoReplyErrorFields[upperCaseSelectedTap]?.monitors ? (
                  <FormHelperText
                    error
                    component="div"
                    className="select-error-message"
                  >
                    {CheckValueLocale("this_field_required", "", {}, intl)}
                  </FormHelperText>
                ) : null}
              </FormControl>
              <FormControl
                sx={{ width: "100%" }}
                className="select-menu"
                size="small"
              >
                <InputLabel
                  id="reply-by-select-label"
                  error={autoReplyErrorFields[upperCaseSelectedTap]?.reply_by}
                >
                  {CheckValueLocale(
                    "automated_public_replies_reply_by",
                    "",
                    {},
                    intl,
                  )}
                </InputLabel>
                <Select
                  labelId="reply-by-select-label"
                  id="reply-by-select"
                  sx={{ height: "40px" }}
                  error={autoReplyErrorFields[upperCaseSelectedTap]?.reply_by}
                  value={
                    automatedPublicRepliesData[upperCaseSelectedTap]?.accounts
                  }
                  onChange={(e) => {
                    handleReplyBySelect(e);
                  }}
                  input={
                    <OutlinedInput
                      label={CheckValueLocale(
                        "automated_public_replies_reply_by",
                        "",
                        {},
                        intl,
                      )}
                      size="small"
                    />
                  }
                  size="small"
                  renderValue={(selected) => {
                    let selectedAccount = {};
                    accounts?.forEach((account) => {
                      if (+account?.attributes?.id === +selected)
                        selectedAccount = { ...account };
                    });
                    return selectedAccount?.attributes?.name;
                  }}
                >
                  {accounts?.length > 0
                    ? accounts.map((account, i) => {
                        return (
                          <MenuItem
                            key={`${account?.attributes?.id}-${i}`}
                            value={account?.attributes?.id}
                            id="main-select-li-item"
                          >
                            <Box className="main-select-icons public-reply-count-icons">
                              {account?.attributes?.data_sources?.map((type) =>
                                socialIcons(
                                  account?.attributes?.is_dm
                                    ? `${type}_cxm`
                                    : type,
                                ),
                              )}
                            </Box>
                            <ListItemText primary={account?.attributes?.name} />
                          </MenuItem>
                        );
                      })
                    : null}
                </Select>
                {autoReplyErrorFields[upperCaseSelectedTap]?.reply_by ? (
                  <FormHelperText
                    error
                    component="div"
                    className="select-error-message"
                  >
                    {CheckValueLocale("this_field_required", "", {}, intl)}
                  </FormHelperText>
                ) : null}
              </FormControl>
            </Stack>

            <Box className="greeting-messages-farewell-background-container">
              <Grid container alignItems="stretch" spacing="18px">
                {getReplies(upperCaseSelectedTap)?.map((reply) => (
                  <Grid key={reply?.id} item xs={4}>
                    <PublicReplyBox
                      key={reply?.id}
                      handleEditClick={(e) => {
                        e.stopPropagation();
                        setIsAddReplyModalOpen(true);
                        setIsEditingExistingReply(true);
                        setCurrentReply(reply);
                      }}
                      message={reply?.reply_text}
                      disableDelete={currentTapRepliesCount === 1}
                      handleDelete={() => openDeleteReplyModal(reply)}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        ) : null}
      </Box>

      {isAddReplyModalOpen ? (
        <AddEditPublicReply
          close={() => {
            setIsAddReplyModalOpen(false);
            setIsEditingExistingReply(false);
          }}
          open={isAddReplyModalOpen}
          isEditingExistingReply={isEditingExistingReply}
          automatedPublicRepliesData={automatedPublicRepliesData}
          setAutomatedPublicRepliesData={setAutomatedPublicRepliesData}
          selectedTab={autoReplySelectedTab}
          currentReply={currentReply}
        />
      ) : null}

      {isDeleteReplyModalOpen ? (
        <DeletePublicReplyModal
          close={() => {
            setIsDeleteReplyModalOpen(false);
            setReplyToBeDeleted({});
          }}
          open={isDeleteReplyModalOpen}
          handleDeleteReply={handleDeleteReply}
          replyToBeDeleted={replyToBeDeleted}
        />
      ) : null}
    </Box>
  );
};

export default AutomatedPublicReplies;
