import { Paper, Typography } from "@mui/material";
import "./addChannel.scss";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import WhatsappStepsForAuth from "./whatsAppStepsForAuth";
import SnackBar from "components/snackBar";
import { useState } from "react";

const WhatsappAuthStep = ({
  selectedAccount,
  includedAccounts,
  whatsappAccAuthinticated,
  setWhatsappAccAuthinticated,
}) => {
  const intl = useIntl();
  const [whatsappAuthStepSnackBar, setWhatsappAuthStepSnackBar] = useState({
    openSnackBar: false,
    severity: null,
    title: "",
    message: "",
  });
  return (
    <>
      <Paper className="panel-body">
        <Typography className="whatsapp-auth-title">
          3. {CheckValueLocale("auth_step", "", {}, intl)}
        </Typography>
        <WhatsappStepsForAuth
          selectedAccount={selectedAccount}
          includedAccounts={includedAccounts}
          whatsappAccAuthinticated={whatsappAccAuthinticated}
          setWhatsappAccAuthinticated={setWhatsappAccAuthinticated}
          setWhatsappAuthStepSnackBar={setWhatsappAuthStepSnackBar}
        />
        <SnackBar
          snackBarId="whatsapp-auth-step-three-snackbar"
          open={whatsappAuthStepSnackBar?.openSnackBar}
          handleClose={() => setWhatsappAuthStepSnackBar({})}
          severity={whatsappAuthStepSnackBar?.severity}
          title={CheckValueLocale(
            whatsappAuthStepSnackBar?.title,
            "",
            {},
            intl,
          )}
          message={CheckValueLocale(
            whatsappAuthStepSnackBar?.message,
            "",
            {},
            intl,
          )}
        />
      </Paper>
    </>
  );
};

export default WhatsappAuthStep;
