import "./mainSavedReply.scss";
import { Box, Button, Divider, Tooltip, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import {
  CheckValueLocale,
  classNames,
  handlelUserRoles,
} from "utils/helpers/index.js";
import { useIntl } from "react-intl";
import {
  faTimes,
  faSpinnerThird,
  faTrash,
  faArrowDown,
  faSearch,
  faPenToSquare,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import emptyReply from "images/engagements-images/empty-reply-new.svg";
import NewReplayPopup from "../../setup-flow/setup/newReplayPopup";
import InputAdornment from "@mui/material/InputAdornment";
import PopupModal from "components/popupModal";
import SnackBar from "components/snackBar";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useReplaies } from "../../setup-flow/setup/replayCards/hooks/useReplaies";
import LucButton from "shared/lucButton/lucButton";
import { useAllCollections } from "../../setup-flow/setup/replayCards/hooks/useAllCollections";
import { useCollections } from "../../setup-flow/setup/replayCards/hooks/useCollections";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { useDelReplaies } from "../../setup-flow/setup/replayCards/hooks/useDelReplaies";
import { useCreateReplaies } from "../../setup-flow/setup/replayCards/hooks/useCreateReplaies";
import { useEditReplaies } from "../../setup-flow/setup/replayCards/hooks/useEditReplaies";
import SavedReplyCard from "./savedReplyCard";

const MainSavedReply = ({
  setOpenDetailsSideBarReplay,
  text,
  setText,
  replyBoxInputTextRef,
}) => {
  const intl = useIntl();
  const [repsPage, setRepsPage] = useState(1);
  const [repsList, setRepsList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedText, setSearchedText] = useState("");
  const [collectionLoading, setCollectionLoading] = useState(true);

  const [insertedReplyId, setInsertedReplyId] = useState(null);
  const [snackBar, setSnackBar] = useState({
    open: false,
    title: "",
    message: "",
    severity: "",
  });
  const [newRepPopup, setNewRepPopup] = useState({
    open: false,
    id: null,
    isDuplicate: false,
    title: "",
    contant: "",
    ai: false,
    loading: false,
    done: false,
  });
  const [delPopup, setDelPopup] = useState({
    open: false,
    item: {},
    loading: false,
    done: false,
  });
  const [totalReplaies, setTotalReplaies] = useState(0);

  const [expanded, setExpanded] = useState(null);
  const [lastExpanded, setLastExpanded] = useState(null);
  const [isReplaiesNextPage, setIsReplaiesNextPage] = useState(false);
  const [isReplayLimit, setIsReplayLimit] = useState(true);
  const [isCollectionNextPage, setIsCollectionNextPage] = useState(false);
  const [collectionPage, setCollectionPage] = useState(1);
  const [collections, setCollections] = useState([]);
  const [allCollections, setAllCollections] = useState([]);
  const [isFocused, setIsFocused] = useState(false);

  const { mutate: getAllCollectionsMutation } = useAllCollections();

  const getAllCollections = () => {
    getAllCollectionsMutation(
      { product_id: window?.localStorage?.engagements_id },
      {
        onSuccess: ({ data }) => {
          let arrList = data?.data?.map((item) => item?.attributes) || [];
          setAllCollections([...arrList]);
        },
      },
    );
  };

  const { mutate: getCollectionsMutation, isPending: isCollectionLoading } =
    useCollections();

  const getCollections = (
    pageNumber = collectionPage,
    prevList = [...(collections || [])],
    textSearch = searchedText,
  ) => {
    if (pageNumber == 1) {
      setCollectionLoading(true);
      getAllCollections();
      setIsReplayLimit(true);
      setIsCollectionNextPage(false);
      setCollections([]);
      setExpanded(null);
      setCollectionPage(1);
      setRepsList([]);
      setRepsPage(1);
    }
    getCollectionsMutation(
      {
        page: pageNumber,
        product_id: window?.localStorage?.engagements_id,
        search_title: textSearch?.length ? textSearch : undefined,
      },
      {
        onSuccess: ({ data }) => {
          setNewRepPopup({
            open: false,
            id: null,
            isDuplicate: false,
            title: "",
            contant: "",
            ai: false,
            loading: false,
            done: false,
          });
          let arrList = data?.data?.map((item) => item?.attributes) || [];
          if (pageNumber == 1 && arrList?.length) {
            setTotalReplaies(data?.total_saved_replies);
            setLastExpanded(arrList?.[0]?.id);
            setExpanded(arrList?.[0]?.id);
            arrList?.[0]?.total_saved_replies &&
              getReplaies(1, arrList?.[0]?.id, [], textSearch);
          }
          setIsReplayLimit(data?.saved_replies_limit_reached);
          setIsCollectionNextPage(data?.pagination?.next);
          setCollections([...prevList, ...arrList]);
          setCollectionLoading(false);
        },
      },
    );
  };

  const { mutate: getReplaiesMutation, isPending: isReplaiesLoading } =
    useReplaies();

  const getReplaies = (
    pageNumber = repsPage,
    collictionId = expanded,
    prevList = [...(repsList || [])],
    textSearch = searchedText,
  ) => {
    if (pageNumber == 1) {
      setIsReplaiesNextPage(false);
      setRepsList([]);
      setRepsPage(1);
    }
    getReplaiesMutation(
      {
        page: pageNumber,
        product_id: window?.localStorage?.engagements_id,
        id: collictionId,
        search_title: textSearch?.length ? textSearch : undefined,
      },
      {
        onSuccess: ({ data }) => {
          setNewRepPopup({
            open: false,
            id: null,
            isDuplicate: false,
            title: "",
            contant: "",
            ai: false,
            loading: false,
            done: false,
          });
          let arrList = data?.data?.map((item) => item?.attributes) || [];
          setIsReplaiesNextPage(data?.pagination?.next);
          setRepsList([...prevList, ...arrList]);
        },
      },
    );
  };

  const { mutate: delReplaiesMutation } = useDelReplaies();

  const handleDeleteSavedReplies = () => {
    setDelPopup({
      ...delPopup,
      loading: true,
    });
    delReplaiesMutation(
      {
        product_id: window?.localStorage?.engagements_id,
        id: delPopup?.item?.id,
      },
      {
        onSuccess: ({ data }) => {
          setRepsPage(1);
          setCollectionPage(1);
          getCollections(1, []);

          setSnackBar({
            open: true,
            title: "",
            message: "rep_deleted",
            severity: "success",
          });
          setDelPopup({
            item: {},
            open: false,
            loading: false,
          });
        },
        onError: (error) => {
          setSnackBar({
            open: true,
            title: "wrong_request_parameter",
            message: "please_try_again",
            severity: "error",
          });
          setDelPopup({
            item: {},
            open: false,
            loading: false,
          });
        },
      },
    );
    setTimeout(() => {
      setSnackBar({
        open: false,
        title: "",
        message: "",
        severity: "",
      });
    }, 7000);
  };

  const { mutate: createReplaiesMutation } = useCreateReplaies();

  const handleCreateReplies = () => {
    createReplaiesMutation(
      {
        product_id: window?.localStorage?.engagements_id,
        title: newRepPopup?.title?.trim(),
        text: newRepPopup?.contant?.trim(),
        collection_id: newRepPopup?.collection_id,
      },
      {
        onSuccess: ({ data }) => {
          setRepsPage(1);
          setCollectionPage(1);
          getCollections(1, []);

          setSnackBar({
            open: true,
            title: "",
            message: "new_rep_added",
            severity: "success",
          });
        },
        onError: (error) => {
          let isDuplicate =
            error?.response?.data?.exception?.message ==
            "DUPLICATE_REPLY_FOUND";
          if (isDuplicate) {
            setNewRepPopup({
              ...newRepPopup,
              isDuplicate: true,
              loading: false,
              done: false,
            });
          } else {
            setNewRepPopup({
              open: false,
              id: null,
              isDuplicate: false,
              title: "",
              contant: "",
              ai: false,
              loading: false,
              done: false,
            });
            setSnackBar({
              open: true,
              title: "wrong_request_parameter",
              message: "please_try_again",
              severity: "error",
            });
          }
        },
      },
    );
    setTimeout(() => {
      setSnackBar({
        open: false,
        title: "",
        message: "",
        severity: "",
      });
    }, 7000);
  };

  const { mutate: editReplaiesMutation } = useEditReplaies();

  const handleEditReplies = () => {
    editReplaiesMutation(
      {
        product_id: window?.localStorage?.engagements_id,
        id: newRepPopup?.id,
        title: newRepPopup?.title?.trim(),
        text: newRepPopup?.contant?.trim(),
        collection_id: newRepPopup?.collection_id,
      },
      {
        onSuccess: ({ data }) => {
          setRepsPage(1);
          setCollectionPage(1);
          getCollections(1, []);

          setSnackBar({
            open: true,
            title: "",
            message: "rep_updated_success",
            severity: "success",
          });
        },
        onError: (error) => {
          let isDuplicate =
            error?.response?.data?.exception?.message ==
            "DUPLICATE_REPLY_FOUND";
          if (isDuplicate) {
            setNewRepPopup({
              ...newRepPopup,
              isDuplicate: true,
              loading: false,
              done: false,
            });
          } else {
            setNewRepPopup({
              open: false,
              id: null,
              isDuplicate: false,
              title: "",
              contant: "",
              ai: false,
              loading: false,
              done: false,
            });
            setSnackBar({
              open: true,
              title: "wrong_request_parameter",
              message: "please_try_again",
              severity: "error",
            });
          }
        },
      },
    );
    setTimeout(() => {
      setSnackBar({
        open: false,
        title: "",
        message: "",
        severity: "",
      });
    }, 7000);
  };

  useEffect(() => {
    getCollections(1, []);
  }, []);

  useEffect(() => {
    if (newRepPopup?.done) {
      if (newRepPopup?.isEdit) {
        handleEditReplies();
      } else {
        handleCreateReplies();
      }
    }
  }, [newRepPopup]);

  const delBody = () => {
    return (
      <Box className="shared-dialog-body">
        <p>
          {`${CheckValueLocale("delete_saved_replies_body_engag", "", { name: delPopup?.item?.title }, intl)}`}
        </p>
        <h2 className="sub-text-desc">
          {CheckValueLocale("deleting_msg", "", {}, intl)}
        </h2>
      </Box>
    );
  };

  const handleOnInsert = (item) => {
    const pointerPosition =
      replyBoxInputTextRef?.current?.getCaretPosition?.() || 0;
    const insertText = item?.text;
    setText((prevText) => {
      return (
        prevText.slice(0, pointerPosition) +
        insertText +
        prevText.slice(pointerPosition)
      );
    });
    setInsertedReplyId(item?.id);
    replyBoxInputTextRef?.current?.focus?.(
      pointerPosition + insertText?.length,
    );
  };

  return (
    <Box className="main-rep-bar">
      <Box className="rep-bar-header">
        {CheckValueLocale("saved_replies", "", {}, intl)}
        <FontAwesomeIcon
          icon={faTimes}
          onClick={() => setOpenDetailsSideBarReplay(false)}
        />
      </Box>
      {totalReplaies ? (
        <Box className="rep-bar-search">
          <OutlinedInput
            placeholder={CheckValueLocale(
              "search_replay_placeholder",
              "",
              {},
              intl,
            )}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <FontAwesomeIcon
                  icon={faSearch}
                  flip={intl.locale == "ar" && "horizontal"}
                />
              </InputAdornment>
            }
            endAdornment={
              isFocused || searchText?.length || searchText !== searchedText ? (
                <Box id="end-search-main">
                  {!searchText?.length || searchText !== searchedText ? (
                    <>
                      <LucButton
                        size="small"
                        onClick={() => {
                          setRepsPage(1);
                          setCollectionPage(1);
                          getCollections(1, [], searchText);
                          setSearchedText(searchText);
                        }}
                        variant="flat"
                        disabled={
                          (searchedText?.length &&
                            isCollectionLoading &&
                            collectionPage == 1) ||
                          (!searchedText?.length && !searchText?.length) ||
                          searchText == searchedText
                        }
                        loading={isCollectionLoading && collectionPage == 1}
                        id="replaies-load-more"
                      >
                        {CheckValueLocale("search", "", {}, intl)}
                      </LucButton>
                      <Divider orientation="vertical" />
                    </>
                  ) : null}
                  <FontAwesomeIcon
                    icon={faCircleXmark}
                    onClick={() => {
                      if (searchedText?.length) {
                        setRepsPage(1);
                        setCollectionPage(1);
                        getCollections(1, [], "");
                      }
                      setSearchText("");
                      setSearchedText("");
                    }}
                  />
                </Box>
              ) : null
            }
          />
        </Box>
      ) : null}
      <Box
        className={
          collectionPage > 1 || collections?.length
            ? !totalReplaies
              ? "rep-bar-contant scroll no-search"
              : "rep-bar-contant scroll"
            : "rep-bar-contant"
        }
      >
        {!collections?.length && collectionLoading ? (
          <Box className="fetching-loader">
            <FontAwesomeIcon icon={faSpinnerThird} spin />
          </Box>
        ) : !collections?.length ? (
          <Box className="rep-bar-contant-empty saved-empty ">
            <img className="contant-empty-img" src={emptyReply} />
            <Box component={"h3"} className="empty-title">
              {CheckValueLocale("empty_reply_tit", "", {}, intl)}
            </Box>
            {CheckValueLocale("empty_reply_desc", "", {}, intl)}
          </Box>
        ) : (
          <Box className="replay-section-accordion">
            {collections?.map((collection) => (
              <Accordion
                key={collection?.id}
                expanded={expanded === collection?.id}
                onChange={() => {
                  if (
                    expanded !== collection?.id &&
                    collection?.id !== lastExpanded
                  ) {
                    setIsReplaiesNextPage(false);
                    setRepsList([]);
                    setRepsPage(1);
                    collection?.total_saved_replies &&
                      getReplaies(1, collection?.id, []);
                  }
                  setLastExpanded(collection?.id);
                  setExpanded(
                    expanded === collection?.id ? null : collection?.id,
                  );
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className="accordion-header" component="span">
                    {collection?.name}
                  </Typography>
                  <Typography
                    className="accordion-header number"
                    component="span"
                  >
                    {collection?.total_saved_replies}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Divider className="collaps-hr" />
                  {!isReplaiesLoading && !repsList.length ? (
                    <Box className="empty-loading-reps-list">
                      {CheckValueLocale("saved_reps_empty", "", {}, intl)}
                    </Box>
                  ) : isReplaiesLoading && repsPage == 1 ? (
                    <Box className="empty-loading-reps-list">
                      <FontAwesomeIcon icon={faSpinnerThird} spin />
                    </Box>
                  ) : (
                    <>
                      <Box className="rep-bar-contant-container">
                        {repsList?.map((item) => (
                          <SavedReplyCard
                            key={item?.id}
                            item={item}
                            isInserted={insertedReplyId == item?.id}
                            handleOnInsert={handleOnInsert}
                            setNewRepPopup={setNewRepPopup}
                            collection={collection}
                            setDelPopup={setDelPopup}
                          />
                        ))}
                      </Box>
                      {isReplaiesNextPage ? (
                        <Box className="replaies-page-counter">
                          <LucButton
                            onClick={() => {
                              setRepsPage(repsPage + 1);
                              getReplaies(repsPage + 1, expanded);
                            }}
                            variant="flat"
                            disabled={isReplaiesLoading}
                            loading={isReplaiesLoading}
                            id="replaies-load-more"
                          >
                            {CheckValueLocale("show_more_page", "", {}, intl)}
                          </LucButton>
                        </Box>
                      ) : null}
                    </>
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
            {isCollectionNextPage ? (
              <Box className="collection-page-counter">
                <LucButton
                  onClick={() => {
                    setCollectionPage(collectionPage + 1);
                    getCollections(collectionPage + 1);
                  }}
                  variant="flat"
                  disabled={isCollectionLoading}
                  loading={isCollectionLoading}
                  id="collection-load-more"
                >
                  {CheckValueLocale("show_more_page", "", {}, intl)}
                </LucButton>
              </Box>
            ) : null}
          </Box>
        )}
      </Box>
      {handlelUserRoles("SAVED_REPLIES", "CREATE_SAVED_REPLY") ||
      handlelUserRoles("SAVED_REPLIES", "FULL_ACCESS") ? (
        <Box className="rep-bar-footer">
          <Tooltip
            title={
              isReplayLimit
                ? CheckValueLocale("limit_saved_replais", "", {}, intl)
                : null
            }
            placement="top"
            arrow
          >
            <Box sx={{ width: "100%" }}>
              <Button
                id="engagements-new-reply-btn"
                className="new-rep-btn"
                disabled={isReplayLimit}
                onClick={() =>
                  setNewRepPopup({
                    open: true,
                    id: null,
                    isDuplicate: false,
                    title: "",
                    contant: "",
                    ai: false,
                    loading: false,
                    done: false,
                  })
                }
              >
                <span>+</span>
                {CheckValueLocale("new_rep", "", {}, intl)}
              </Button>
            </Box>
          </Tooltip>
        </Box>
      ) : null}
      {delPopup?.open ? (
        <PopupModal
          title={CheckValueLocale("delete_saved_replies_engag", "", {}, intl)}
          body={delBody()}
          leftBtn={CheckValueLocale("cancel", "", {}, intl)}
          rightBtn={CheckValueLocale("delete_confirm", "", {}, intl)}
          open={delPopup?.open}
          close={() =>
            setDelPopup({
              open: false,
              item: {},
              loading: false,
            })
          }
          disableLeftBtn={delPopup?.loading}
          disabled={delPopup?.loading}
          isRightBtnLoading={delPopup?.loading}
          acceptOtherOption={() =>
            setDelPopup({
              item: {},
              open: false,
              loading: false,
            })
          }
          accept={() => {
            handleDeleteSavedReplies();
          }}
          rightBtnId="delete-saved-reply-engag"
          leftBtnId="cancel-saved-reply-engag"
          warning
          minWidth="600px"
          classeName="actions-buttons-shared"
        />
      ) : null}
      {newRepPopup?.open ? (
        <NewReplayPopup
          newRepPopup={newRepPopup}
          setNewRepPopup={setNewRepPopup}
          collections={allCollections}
        />
      ) : null}
      <SnackBar
        open={snackBar.open}
        severity={snackBar.severity}
        message={CheckValueLocale(snackBar.message, "", {}, intl)}
        title={CheckValueLocale(snackBar.title, "", {}, intl)}
      />
    </Box>
  );
};
export default MainSavedReply;
