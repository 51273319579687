import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import CompaniesController from "services/controllers/companiesController";
import {
  getGmtOffsetDate,
  CheckValueLocale,
  formatTimeZone,
  calculateUnixTimeZone,
} from "utils/helpers";

const InfoSection = (props) => {
  const intl = useIntl();
  const { monitorDetails } = props;
  const companyTimezone = localStorage.getItem("companyInfo");

  const numberWithCommas = (x) => {
    x = x?.toString();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
    return x;
  };

  const infoBoxes = [
    {
      title: "monitor_type",
      data: CheckValueLocale(
        monitorDetails?.attributes?.monitor_type_name,
        "",
        {},
        intl,
      ),
    },
    {
      title: "status",
      data:
        monitorDetails?.attributes?.stream_status &&
        CheckValueLocale(
          monitorDetails?.attributes?.stream_status.toLowerCase(),
          "",
          {},
          intl,
        ),
      name: monitorDetails?.attributes?.stream_status,
    },
    {
      title: "creator",
      data: monitorDetails?.attributes?.monitor_creator?.name,
    },
    {
      title: "start_dates",
      data: formatTimeZone(
        calculateUnixTimeZone(monitorDetails?.attributes?.created_at_in_unix, {
          utc: true,
        }),
        intl,
        {},
        companyTimezone,
      ).completeFormat,
    },
    {
      title: "total_posts_used",
      data:
        monitorDetails?.attributes?.posts_count_stats?.total_posts?.length > 0
          ? numberWithCommas(
              monitorDetails?.attributes?.posts_count_stats?.total_posts,
            )
          : monitorDetails?.attributes?.posts_count_stats?.total_posts,
    },
  ];
  const handleUpgradeBtn = () => {
    CompaniesController.sendRequestPackageUpgrade(
      window.localStorage.sm_id,
    ).then((data) => {
      if (data?.data?.status === 200) {
        props.setShowSnackBar(true);
        props.setSnackBarData({
          message: CheckValueLocale(
            "upgrade_monitor_success_msg",
            "",
            {},
            intl,
          ),
          severity: "success",
        });
      } else {
        props.setShowSnackBar(true);
        props.setSnackBarData({
          message: CheckValueLocale("failed_error", "", {}, intl),
          severity: "error",
        });
      }
    });
  };

  return (
    <>
      <Typography className="section-header">
        {CheckValueLocale("monitor_information", "", {}, intl)}
      </Typography>
      <Box className="info-section">
        {infoBoxes?.map((info, index) => {
          return (
            <Box className="info-box" key={index}>
              <Box>
                <Typography>
                  {CheckValueLocale(info?.title, "", {}, intl)}
                </Typography>
                {info?.title === "status" &&
                  monitorDetails?.attributes?.status === "limit-reached" && (
                    <Link onClick={handleUpgradeBtn}>
                      {CheckValueLocale("upgrade", "", {}, intl)}
                    </Link>
                  )}
              </Box>
              {info?.title === "status" ? (
                <Box component="span" className={"status " + info?.name}>
                  {info?.data}
                </Box>
              ) : (
                <Box component="span" className="main-titles">
                  {info?.data}
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default InfoSection;
