import {
  faCalendar,
  faMapMarkerAlt,
  faUserFriends,
} from "@fortawesome/free-solid-svg-icons";
import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";
import { faBadgeCheck } from "@fortawesome/pro-solid-svg-icons";
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Typography,
  Tooltip,
  Grid,
  TextField,
  Button,
  FormHelperText,
  InputLabel,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import FetchingData from "components/fetchingData/fetchingData";
import NoDataFound from "components/no-Data/noDataFound";
import SnackBar from "components/snackBar";
import AiIcon from "images/ai_icon_big.svg";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import {
  handlelUserRoles,
  numberFormatter,
  CheckValueLocale,
  convertXPlatformToTwitter,
} from "utils/helpers";
import "../authorProfile.scss";
import { MonitorButton } from "pages/Monitors/Components/sidebar/monitor.styles";
import MonitorsController from "services/controllers/monitorsController";
import PopupModal from "components/popupModal";
import DateRangePicker from "shared/dateRangePicker/dateRangePicker";
import { LoadingButton } from "@mui/lab";
import xPlatform from "images/social-icons/x-platform-white-icon.svg";

/*---------------------------------------------------------*/

const PersonalInformation = (props) => {
  const intl = useIntl();
  let {
    response,
    analyzeProfile,
    postsUsedLimit,
    isProfileAnalyzed,
    getCompanyDataQuota,
  } = props;
  const monitorDetails = props.monitorData;
  const navigate = useNavigate();

  /*______________________________________________________________*/
  //get All used monitor details from url link
  const urlParams = useParams();
  const monitorType = urlParams.monitorType;
  const monitorDataSource = convertXPlatformToTwitter(
    urlParams.monitorDataSource,
  );
  const activeTab = urlParams.activeTab;
  const monitorId = urlParams.monitorId;
  const userId = urlParams.userId;
  /*______________________________________________________________*/

  const [showDeepInsightMsg, setShowDeepInsightMsg] = useState({
    type: "",
    message: "",
    title: "",
  });
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [stats, setStats] = useState({
    anchorEl: null,
    openCreateModal: false,
  });
  /*______________________________________________________________*/
  const [personalUserName, setPersonalUserName] = useState("");
  const [personalScreenName, setPersonalScreenName] = useState("");
  const [personalJoined, setPersonalJoined] = useState("");
  const [personalLocation, setPersonalLocation] = useState("");
  const [personalFollowing, setPersonalFollowing] = useState("");
  const [personalFollowers, setPersonalFollowers] = useState("");
  const [personalTotalPosts, setPersonalTotalPosts] = useState("");
  const [personalUserBio, setPersonalUserBio] = useState("");
  const [personalVerified, setPersonalVerified] = useState(false);
  const [personalLink, setPersonalLink] = useState("");
  const [personalImg, setPersonalImg] = useState("");

  // AI insights data
  const [aiAccountType, setAccountType] = useState("");
  const [aiCountry, setCountry] = useState("");
  const [aiDialect, setDialect] = useState("");
  const [aiGender, setGender] = useState("");
  const [aiLanguage, setLanguage] = useState("");
  const [
    personalInformationPreLoaderDataSources,
    setPersonalInformationPreLoaderDataSources,
  ] = useState([]);

  //deep insight popup
  const [quotaCount, setQuotaCount] = useState("");
  const [analyzedBtn, setAnalyzedBtn] = useState(false);
  const [deepInsightError, setDeepInsightError] = useState(false);
  const [formFieldErrors, setFormFieldErrors] = useState({
    quotaLimit: "",
  });

  const [startDate, setStartDate] = useState(
    moment().subtract(6, "d").startOf("day").unix(),
  );
  const [endDate, setEndDate] = useState(moment().unix());
  // ___________________________________________________________

  const handleResponseFunc = async () => {
    if (
      response.eventName === "Twitter__EngagerProfilePage__personal_information"
    ) {
      setPersonalUserName(response.eventData.personal_information.user_name);
      setPersonalScreenName(
        response.eventData.personal_information.screen_name,
      );
      setPersonalJoined(response.eventData.personal_information.joined_since);
      setPersonalLocation(
        response.eventData.personal_information.user_location,
      );
      setPersonalFollowing(response.eventData.personal_information.following);
      setPersonalFollowers(response.eventData.personal_information.followers);
      setPersonalTotalPosts(
        response.eventData.personal_information.total_posts,
      );
      setPersonalUserBio(response.eventData.personal_information.user_bio);
      setPersonalVerified(response.eventData.personal_information.verified);
      setPersonalLink(response.eventData.personal_information.profile_link);
      setPersonalImg(
        response.eventData.personal_information.profile_image_link,
      );
      // ai insights data
      setAccountType(response.eventData.ai_insights.account_type);
      setCountry(response.eventData.ai_insights.country);
      setDialect(response.eventData.ai_insights.dialect);
      setGender(response.eventData.ai_insights.gender);
      setLanguage(response.eventData.ai_insights.language);

      setPersonalInformationPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else {
      return null;
    }
  };
  useEffect(() => {
    handleResponseFunc();
  }, [response]);

  /*---------------------------------------------------------*/
  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (props.resetAllFields !== 1) {
      setPersonalUserName("");
      setPersonalScreenName("");
      setPersonalJoined("");
      setPersonalLocation("");
      setPersonalFollowing("");
      setPersonalFollowers("");
      setPersonalTotalPosts("");
      setPersonalUserBio("");
      setPersonalVerified(false);
      setPersonalLink("");
      setPersonalImg("");

      // Values of DataSources toTrack each widget preloader
      setPersonalInformationPreLoaderDataSources([]);
    }
  }, [props.resetAllFields]);

  /*---------------------------------------------------------*/

  const getDeepInsightData = () => {
    props?.setDeepInsightsLoading(true);
    MonitorsController.getDeepInsightData(
      monitorDataSource,
      monitorId,
      window.localStorage.sm_id,
      personalScreenName, //user name
      userId, //user id
      quotaCount,
      startDate,
      endDate,
    ).then((data) => {
      setShowSnackBar(true);
      if (data?.data?.status === 200 && data?.data.data !== " ") {
        setStats({ ...stats, openCreateModal: false });
        setQuotaCount("");
        navigate(
          `/monitor/${monitorType}/${urlParams.monitorDataSource}/${activeTab}/${monitorId}/deep-insights/${personalScreenName}/${userId}`,
          {
            state: monitorDetails,
          },
        );
      } else if (data?.data?.status === 200 && data?.data.data === " ") {
        setStats({ ...stats, openCreateModal: false });
        setQuotaCount("");
        setAnalyzedBtn(true);
        setShowDeepInsightMsg({
          type: "success",
          title: CheckValueLocale("deep_insights_in_progress", "", {}, intl),
          message: CheckValueLocale(
            "deep_insights_receive_email",
            "",
            {},
            intl,
          ),
        });
      } else if (
        data.status === 400 &&
        data.errorMsg === "NO_TWITTER_TOKENS_AVAILABLE"
      ) {
        setStats({ ...stats, openCreateModal: false });
        setQuotaCount("");
        setShowDeepInsightMsg({
          type: "error",
          title: CheckValueLocale(
            "please_wait_and_try_again_later",
            "",
            {},
            intl,
          ),
          message: CheckValueLocale(
            "please_authenticate_your_twitter_account_first",
            "",
            {},
            intl,
          ),
        });
      } else {
        setStats({ ...stats, openCreateModal: false });
        setQuotaCount("");
        setShowDeepInsightMsg({
          type: "error",
          title: CheckValueLocale("request_failed_deep_insights", "", {}, intl),
          message: CheckValueLocale("update_not_deep_insights", "", {}, intl),
        });
      }
      props?.setDeepInsightsLoading(false);
    });
  };

  /*---------------------------------------------------------*/

  const inputValidator = (inputValue) => {
    const value = /^\d*\d?((10)|(0))\.?((0)|(00))?$/;
    return value.test(inputValue);
  };

  const handleOpenCreateModal = () => {
    setStats({
      ...stats,
      openCreateModal: true,
      modalTitle: `${CheckValueLocale("deep_insights_header", "", {}, intl)}`,
      anchorEl: null,
    });
  };

  const handleCloseCreateModal = () => {
    setStats({ ...stats, openCreateModal: false });
    setQuotaCount("");
    setFormFieldErrors({
      quotaLimit: "",
    });
  };

  const handlequotaCount = (e) => {
    let value = e?.target?.value;
    setQuotaCount(value);
    let deepInsightLimitError = "";

    setDeepInsightError(false);

    if (Number(value) === 0) {
      deepInsightLimitError = "deep_insight_ten_multiple";
    } else if (postsUsedLimit?.limit < value) {
      deepInsightLimitError = "quota_out";
    } else if (!inputValidator(value)) {
      deepInsightLimitError = "deep_insight_ten_multiple";
    } else if (value > 200 && postsUsedLimit?.type === 2) {
      deepInsightLimitError = "deep_insight_over_200";
    } else if (postsUsedLimit?.limit === 0) {
      deepInsightLimitError = "deep_insight_limit_zero";
    }

    setFormFieldErrors({
      ...formFieldErrors,
      quotaLimit: deepInsightLimitError,
    });
  };

  const applyFilter = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
    getCompanyDataQuota(startDate, endDate);
  };

  const modalBody = (
    <Box className="deep-insights-modal">
      <Box className="shared-dialog-body">
        <p>{CheckValueLocale("enter_number_posts_deep", "", {}, intl)}</p>
      </Box>
      <Box className="deep-posts-remainig-content">
        <Box component="span">
          {CheckValueLocale(
            "deep_insights_remainig_post",
            "",
            {
              remaining: (
                <Box
                  component="span"
                  className={
                    props?.deepInsightsLoading
                      ? "deep-number-loader"
                      : "deep-number"
                  }
                >
                  {postsUsedLimit?.limit?.toLocaleString() ?? 0}
                </Box>
              ),
            },
            intl,
          )}
          <Tooltip
            title={
              <Box className="deep-insight-tooltip">
                {CheckValueLocale("deep_tweet_tooltip_quota", "", {}, intl)}
              </Box>
            }
            arrow
            placement={window?.localStorage?.lang !== "en" ? "left" : "right"}
            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  padding: "10px",
                  width: 250,
                  lineHeight: 1.5,
                },
              },
            }}
          >
            <Box component="span">
              <FontAwesomeIcon icon={faExclamationCircle} />
            </Box>
          </Tooltip>
        </Box>
      </Box>
      <TextField
        variant="outlined"
        placeholder={CheckValueLocale("EX:30", "", {}, intl)}
        onChange={handlequotaCount}
        className="single-tweet-input"
        value={quotaCount}
        disabled={props?.deepInsightsLoading}
        type="number"
        min="10"
        error={formFieldErrors?.quotaLimit || deepInsightError ? true : false}
      />

      {formFieldErrors?.quotaLimit || deepInsightError ? (
        <FormHelperText error className="deep-insight-validation">
          {CheckValueLocale(
            deepInsightError
              ? "custom_domain_validation"
              : formFieldErrors?.quotaLimit,
            "",
            {},
            intl,
          )}
        </FormHelperText>
      ) : null}

      <Box className="text-field create-historical-calendar" mt={3}>
        <InputLabel className="body-input-label show-red-icon">
          {CheckValueLocale("dates_range", "", {}, intl)}
        </InputLabel>
        <DateRangePicker
          disablePast
          startDate={startDate}
          endDate={endDate}
          oldestDate={moment.unix(1142899200).format("YYYY/MM/DD")} // this number required to not allow user to get data less than 2006
          openCustomDateLeft={true}
          applyFilter={applyFilter}
          isActive={!props?.deepInsightsLoading}
          datePickerPosition="create-historical-calendar-datepicker-position"
          className="create-historical-select reate-historical-select"
        />
      </Box>
    </Box>
  );

  const checkProfileIsAnalyzed = () => {
    return isProfileAnalyzed
      ? navigate(
          `/monitor/${monitorType}/${urlParams.monitorDataSource}/${activeTab}/${monitorId}/deep-insights/${personalScreenName}/${userId}`,
          {
            state: monitorDetails,
          },
        )
      : handleOpenCreateModal();
  };

  /*---------------------------------------------------------*/

  return (
    <>
      {_.isEqual(
        personalInformationPreLoaderDataSources?.sort(),
        props?.preLoaderTrackerDataSources?.sort(),
      ) !== true ||
      !personalInformationPreLoaderDataSources?.length ||
      !props?.preLoaderTrackerDataSources?.length ? (
        <Box>
          <FetchingData />
        </Box>
      ) : _.isEmpty(response) ? (
        <NoDataFound />
      ) : (
        <>
          {/*---------------------------------------------------------*/}
          <Box my={2} mb={5} className="personal-info-wrapper">
            <Box className="user-info-box">
              <Box className="personal-info-stats">
                <Box className="personal-info-img">
                  <Box className="author-twitter-icon">
                    <img src={xPlatform} alt="x-platform-logo" />
                  </Box>
                  <img src={personalImg} />
                </Box>

                <Box className="account-data">
                  <Box className="personal-info-style">
                    <Box className="personal-info-name">
                      <Box className="screen-name">{personalUserName}</Box>
                      <Box component="span" marginX={0.5}>
                        @{personalScreenName}
                      </Box>
                      {personalVerified && (
                        <FontAwesomeIcon
                          icon={faBadgeCheck}
                          color="#1D9BF0"
                          className="verified-icon"
                        />
                      )}
                    </Box>
                    <Grid container spacing={2} className="grid-width">
                      <>
                        <Grid item xs={6}>
                          <Box className="personal-info">
                            <FontAwesomeIcon icon={faCalendar} />
                            {CheckValueLocale("joined", "", {}, intl)}
                            <Box component="p" className="personal-joined-date">
                              {moment(personalJoined).format("YYYY-MM-DD")}
                            </Box>
                          </Box>
                          <Box className="personal-info">
                            <Typography variant="inherit" component={"span"}>
                              <FontAwesomeIcon icon={faMapMarkerAlt} />
                              {personalLocation}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box className="grid-style">
                            <Box className="personal-info">
                              {`${CheckValueLocale("following", "", {}, intl)} :
                                    `}
                              <Typography variant="inherit" component={"span"}>
                                {personalFollowing !== null &&
                                  personalFollowing.toLocaleString()}
                              </Typography>
                            </Box>

                            <Box className="personal-info">
                              {`${CheckValueLocale(
                                "total_posts",
                                "",
                                {},
                                intl,
                              )} :
                                    `}
                              <Typography variant="inherit" component={"span"}>
                                {personalTotalPosts !== null &&
                                  personalTotalPosts.toLocaleString()}
                              </Typography>
                            </Box>

                            <Box className="personal-info">
                              <Box>
                                <Typography
                                  variant="inherit"
                                  component={"span"}
                                >
                                  <FontAwesomeIcon icon={faUserFriends} />
                                  {numberFormatter(personalFollowers)}
                                </Typography>
                                {CheckValueLocale(
                                  "personal_followers",
                                  "",
                                  {},
                                  intl,
                                )}
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      </>
                    </Grid>
                  </Box>
                </Box>

                <a
                  href={`${personalLink}`}
                  target="_blank"
                  className="external-link"
                  id="author-profile-external-link"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faExternalLink} solid />
                </a>
              </Box>
              {/*---------------------------------------------------------*/}

              <Box className="user-bio">
                {CheckValueLocale("user_bio", "", {}, intl)} :
                <Box>{personalUserBio}</Box>
              </Box>
            </Box>
            {/*---------------------------------------------------------*/}

            <Box className="user-info-box">
              <Box className="ai-insights">
                <img src={AiIcon} />
                {CheckValueLocale("ai_insights", "", {}, intl)}
              </Box>
              <Box className="profile-details-wrapper">
                <Box className="profile-detail">
                  <Box component="span">
                    {CheckValueLocale("gender", "", {}, intl)}:
                  </Box>
                  {aiGender !== "" && aiGender !== null
                    ? CheckValueLocale(`${aiGender}`, "", {}, intl)
                    : CheckValueLocale("n/a", "", {}, intl)}
                </Box>
                <Box className="profile-detail">
                  <Box component="span">
                    {CheckValueLocale("language", "", {}, intl)}:
                  </Box>
                  {aiLanguage !== "" && aiLanguage !== "null"
                    ? CheckValueLocale(`${aiLanguage}`, "", {}, intl)
                    : CheckValueLocale("n/a", "", {}, intl)}
                </Box>
                <Box className="profile-detail">
                  <Box component="span">
                    {CheckValueLocale("dialect", "", {}, intl)}:
                  </Box>
                  {aiDialect !== "" && aiDialect !== null
                    ? CheckValueLocale(`${aiDialect}`, "", {}, intl)
                    : CheckValueLocale("n/a", "", {}, intl)}
                </Box>
                <Box className="profile-detail">
                  <Box component="span">
                    {CheckValueLocale("country", "", {}, intl)}:
                  </Box>
                  {aiCountry !== ""
                    ? CheckValueLocale(`${aiCountry}`, "", {}, intl)
                    : CheckValueLocale("n/a", "", {}, intl)}
                </Box>
                <Box className="profile-detail">
                  <Box component="span">
                    {CheckValueLocale("account_type", "", {}, intl)}:
                  </Box>
                  {aiAccountType !== ""
                    ? CheckValueLocale(`${aiAccountType}`, "", {}, intl)
                    : CheckValueLocale("n/a", "", {}, intl)}
                </Box>
              </Box>
            </Box>
            {/*---------------------------------------------------------*/}

            <Box className="discover-deep-wrapper user-info-box">
              <Box>
                <img src={AiIcon} />
              </Box>
              <Box className="discover-deep">
                {CheckValueLocale("discover_deep_insights_about", "", {}, intl)}
                <Box className="profile-user-name">{personalUserName}</Box>
              </Box>

              {/* check when user have permission to redirect to Deep insights page OR not */}
              {handlelUserRoles("SM", "CREATE_MONITOR") && analyzeProfile ? (
                <Button
                  className="analyze-profile-btn"
                  onClick={() => checkProfileIsAnalyzed()}
                  id="author-profile-analyze-btn"
                  disabled={analyzedBtn}
                >
                  {CheckValueLocale("analyze_profile", "", {}, intl)}
                </Button>
              ) : (
                <Tooltip
                  title={CheckValueLocale(
                    "you_dont_have_permission_to_use_this_feature",
                    "",
                    {},
                    intl,
                  )}
                  arrow
                  placement="top"
                >
                  <IconButton disableRipple>
                    <MonitorButton
                      variant="contained"
                      disabled
                      disableRipple
                      id="author-profile-monitors-analyze-btn"
                    >
                      {CheckValueLocale("analyze_profile", "", {}, intl)}
                    </MonitorButton>
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Box>
          {/*---------------------------------------------------------*/}
        </>
      )}
      {showSnackBar && (
        <SnackBar
          open={showSnackBar}
          severity={showDeepInsightMsg.type}
          message={showDeepInsightMsg.message}
          title={showDeepInsightMsg.title}
          handleClose={() => {
            setShowSnackBar(false);
          }}
        />
      )}
      <PopupModal
        title={stats?.modalTitle}
        body={modalBody}
        open={stats?.openCreateModal}
        close={handleCloseCreateModal}
        leftBtn={CheckValueLocale("cancel", "", {}, intl)}
        rightBtn={CheckValueLocale("request", "", {}, intl)}
        accept={getDeepInsightData}
        isRightBtnLoading={props?.deepInsightsLoading}
        disabled={
          quotaCount <= 0 ||
          (postsUsedLimit?.type === 2 &&
            (quotaCount === "" || quotaCount < 10)) ||
          formFieldErrors?.quotaLimit !== "" ||
          postsUsedLimit?.limit <= 0 ||
          props?.deepInsightsLoading
        }
        classeName="create-historical-popup actions-buttons-shared"
        minWidth="600px"
      />
    </>
  );
};

export default PersonalInformation;
