import { useState } from "react";
import InputLabelFormik from "./inputLabelFormik";
import { IconButton, InputAdornment, Tooltip } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const InputPassword = ({ ...props }) => {
  const intl = useIntl();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <InputLabelFormik
      type={showPassword ? "text" : "password"}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label={
              showPassword ? "hide the password" : "display the password"
            }
            onClick={handleClickShowPassword}
            edge="end"
          >
            {showPassword ? (
              <Tooltip title={CheckValueLocale("hide_pass", "", {}, intl)}>
                <VisibilityOff />
              </Tooltip>
            ) : (
              <Tooltip title={CheckValueLocale("show_pass", "", {}, intl)}>
                <Visibility />
              </Tooltip>
            )}
          </IconButton>
        </InputAdornment>
      }
      autoComplete="new-password"
      {...props}
    />
  );
};

export default InputPassword;
