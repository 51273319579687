import { useState } from "react";
import { Grid, Typography, Divider, Chip, Box, Tooltip } from "@mui/material";
//Locals
import { useIntl } from "react-intl";
import moment from "moment";
import { handlelUserRoles } from "utils/helpers";
import DeleteArchiveSurveyModal from "../components/deleteArchiveSurveyModal";
import PublishSurveyModal from "../../SurveyBuilder/Header/publishSurveyModal";
import OptionsDropDown from "./optionsDropDown";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faWindow } from "@fortawesome/pro-regular-svg-icons";

const CardData = (props) => {
  const intl = useIntl();

  const { survey, setSnackbar } = props;
  const hideOptionsDropDown =
    survey?.status === "draft"
      ? handlelUserRoles("SURVEY", "EDIT_SURVEY")
        ? true
        : false
      : true;
  const [openDeleteSurveyModal, setOpenDeleteSurveyModal] = useState(false);
  const [openArchiveSurveyModal, setOpenArchiveSurveyModal] = useState(false);

  const [openPublishSurveyModal, setOpenPublishSurveyModal] = useState(false);
  const [openShareSurveyModal, setOpenShareSurveyModal] = useState(false);
  const icons = (
    <Grid container justifyContent="flex-end">
      {hideOptionsDropDown && (
        <OptionsDropDown
          setOpenPublishSurveyModal={setOpenPublishSurveyModal}
          setOpenShareSurveyModal={setOpenShareSurveyModal}
          openPublishSurveyModal={openPublishSurveyModal}
          setOpenDeleteSurveyModal={setOpenDeleteSurveyModal}
          setOpenArchiveSurveyModal={setOpenArchiveSurveyModal}
          survey={survey}
          surveyLimitReach={props.surveyLimitReach}
          setSnackbar={setSnackbar}
        />
      )}
    </Grid>
  );

  const localizedDate =
    intl?.locale == "en"
      ? moment.unix(survey?.published_at).utc().format("D MMM, YYYY")
      : `${moment.unix(survey?.published_at).utc().format("D")} ${CheckValueLocale(
          moment.unix(survey?.published_at).utc().format("MMM"),
          "",
          {},
          intl,
        )} ${moment.unix(survey?.published_at).utc().format("YYYY")} `;

  const publishedAt =
    CheckValueLocale(
      survey?.status == "active" ? "publish_date_survey" : "unpublish_date",
      "",
      {},
      intl,
    ) +
    " :   " +
    localizedDate;
  return (
    <>
      <Box className="survey-card-header">
        <Typography className="survey-card-name">{survey?.name}</Typography>
        <Tooltip
          title={
            <Box id="sruvey-card-status-tooltip">
              {`${CheckValueLocale("type", "", {}, intl)}: ${CheckValueLocale(
                survey?.survey_type?.toLowerCase() === "embedded_survey"
                  ? "survey_status_type_embedded"
                  : "survey_status_type_link",
                "",
                {},
                intl,
              )}`}
              <br />
              {`${CheckValueLocale("status", "", {}, intl)}: ${CheckValueLocale(
                survey?.status == "archived"
                  ? "archive_status"
                  : survey?.status + "_status_survey",
                "",
                {},
                intl,
              )}.`}
            </Box>
          }
          placement="top"
          arrow
        >
          <Box component="span" className="survey-card-status">
            <Chip
              className={`header-status header-status-` + survey?.status}
              label={
                <Box className="status-wrapper">
                  <FontAwesomeIcon
                    icon={
                      survey?.survey_type?.toLowerCase() == "embedded_survey"
                        ? faWindow
                        : faLink
                    }
                    fixedWidth
                  />
                  <Typography>
                    {CheckValueLocale(
                      survey?.status == "archived"
                        ? "archive_status"
                        : survey?.status + "_status_survey",
                      "",
                      {},
                      intl,
                    )}
                  </Typography>
                </Box>
              }
              variant="outlined"
            />
          </Box>
        </Tooltip>
        <Box component="span" className="survey-card-icons">
          {icons}
        </Box>
      </Box>
      {survey?.published_at ? (
        <Box component={"span"} className="publish-date">
          {publishedAt}
        </Box>
      ) : (
        <Box className="empty-publish-date"></Box>
      )}
      <Divider />
      {openArchiveSurveyModal || openDeleteSurveyModal ? (
        <DeleteArchiveSurveyModal
          surveyName={survey?.name}
          surveyId={survey?.id}
          openArchiveSurveyModal={openArchiveSurveyModal}
          setOpenArchiveSurveyModal={setOpenArchiveSurveyModal}
          openDeleteSurveyModal={openDeleteSurveyModal}
          setOpenDeleteSurveyModal={setOpenDeleteSurveyModal}
          surveyAiAgentName={survey?.ai_agent_name}
          isFarewellSurvey={survey?.is_farewell_survey}
          setSnackbar={setSnackbar}
        />
      ) : null}
      {openPublishSurveyModal && (
        <PublishSurveyModal
          openPublishSurveyModal={openPublishSurveyModal}
          setOpenPublishSurveyModal={setOpenPublishSurveyModal}
          surveyData={survey}
          openShareSurveyModal={openShareSurveyModal}
        />
      )}
    </>
  );
};
export default CardData;
