import { useLocation } from "react-router-dom";
import { useContext, useEffect } from "react";
import { Box, FormHelperText, Grid, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { useReportMonitors } from "../hooks/useReportMonitors";
import { CreateReportContext } from "pages/reports/context/reportContext";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BusinessIcon from "@mui/icons-material/Business";
import SmsIcon from "@mui/icons-material/Sms";
import CircularLoading from "components/circularProgress";
import ReportSelectInput from "pages/reports/components/reportSelectInput";
import CreateReportMonitorsSelect from "../components/createReportMonitorsSelect";

const monitorTypeIcon = {
  keyword: <SmsIcon />,
  account_analysis: <BusinessIcon />,
  managed_account: <AccountCircleIcon />,
};

const ReportSettingSocialListening = () => {
  const {
    formik,
    availableMonitorTypes,
    availableMonitorTypesLoading,
    availableReportTypes,
    availableDatasources,
    availableDatasourcesLoading,
    setSnackbar,
    isMonitorAction,
    selectedMonitorTypes,
  } = useContext(CreateReportContext);

  const location = useLocation();

  const intl = useIntl();

  // Check if the selected monitor type is keyword
  const isKeywordMonitorType = formik?.values?.channel_type === "KEYWORD";

  // Enable multiple datasources for keyword monitor type
  const isMultiDatasources = isKeywordMonitorType;

  // Check if the selected report type is newsletter
  const isNewsletterReport =
    formik?.values?.report_type_id ===
    availableReportTypes?.namesToIds?.["newsletter"];

  /** -----------------------------------------------------
   * Get available report monitors step (Report Setting)
   * ----------------------------------------------------- */
  const {
    data: availableMonitors,
    isLoading: availableMonitorsLoading,
    isError: availableMonitorsError,
  } = useReportMonitors({
    enabled: !!formik?.values?.report_type_id,
    queryData: {
      report_type_id: formik?.values?.report_type_id,
      monitor_type_id: selectedMonitorTypes || [],
      data_source_id: isKeywordMonitorType
        ? formik?.values?.data_source_id // If channel_type is keyword we send data_source_id as array
        : formik?.values?.data_source_id?.[0], // else we send data_source_id as single value
    },
    datasourcesIds: availableDatasources?.namesToIds,
  });

  useEffect(() => {
    if (availableMonitorsError) {
      setSnackbar({
        open: true,
        severity: "error",
        title: "something_went_wrong",
        message: "try_again",
      });
    }
  }, [availableMonitorsError]);

  const handleMonitorType = (name) => {
    formik?.setValues({
      ...formik?.values,
      channel_type: name,
      report_type_id:
        name === "KEYWORD" // If the selected monitor type is not keyword, set the report type to monitor
          ? ""
          : availableReportTypes?.namesToIds?.["monitor"],
      data_source_id: [],
      monitor_list: [],
    });
    formik?.setTouched({});
    formik?.setSubmitting(false);
  };

  const handleChangeReportType = (e) => {
    const value = e.target.value;
    formik?.setValues({
      ...formik?.values,
      report_type_id: value,
      data_source_id:
        value === availableReportTypes?.namesToIds?.["newsletter"]
          ? [availableDatasources?.namesToIds?.["TALKWALKER"]]
          : [],
      monitor_list: [],
    });
    formik?.setTouched({});
    formik?.setSubmitting(false);
  };

  const handleChangeMonitors = (e) => {
    const value = e.target.value;
    if (isNewsletterReport) {
      const { monitor_list } = formik?.values;
      if (monitor_list?.includes(value)) {
        const filteredList = monitor_list?.filter((item) => item !== value);
        formik?.setFieldValue("monitor_list", filteredList);
      } else formik?.setFieldValue("monitor_list", value);
    } else formik?.setFieldValue("monitor_list", [value]);
  };

  const mapDatasourcesForOptions = (data) => {
    // Filter datasources based on monitor action datasources
    const monitorActionAvailableSources =
      data?.filter((datasource) =>
        location?.state?.preSelected?.dataSources
          ?.map((item) => availableDatasources?.namesToIds?.[item])
          ?.includes(datasource?.attributes?.id),
      ) || [];

    const filteredData = isMonitorAction ? monitorActionAvailableSources : data;

    return filteredData?.map((item) => {
      return {
        value: item?.attributes?.id,
        label: `report_${item?.attributes?.source}`,
      };
    });
  };

  // Set pre-selected values, when creating report from monitor header button
  useEffect(() => {
    if (isMonitorAction) {
      const preSelectedData = location?.state?.preSelected; // Get pre-selected data

      // Set pre-selected channel_type
      if (!!availableMonitorTypes) {
        formik?.setFieldValue("channel_type", preSelectedData?.monitorType);
      }
      // Set pre-selected report_type
      if (!!availableReportTypes) {
        formik?.setFieldValue(
          "report_type_id",
          availableReportTypes?.namesToIds?.["monitor"],
        );
      }
      // Set pre-selected data_source only if only one datasource is available
      if (!!availableDatasources && preSelectedData?.dataSources?.length == 1) {
        formik?.setFieldValue("data_source_id", [
          availableDatasources?.namesToIds?.[preSelectedData?.dataSources?.[0]],
        ]);
      }
      // Set pre-selected monitor_list
      formik?.setFieldValue("monitor_list", [
        {
          id: preSelectedData?.monitorId,
          name: preSelectedData?.monitorName,
          data_source_id: preSelectedData?.dataSources?.map(
            (datasource) => availableDatasources?.namesToIds?.[datasource],
          ),
        },
      ]);
    }
  }, [
    isMonitorAction,
    availableReportTypes,
    availableMonitorTypes,
    availableDatasources,
  ]);

  return (
    <>
      {availableMonitorTypesLoading ? (
        <CircularLoading />
      ) : (
        <Box>
          <Grid
            className="create-report-monitor-type-container"
            container
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography>
                {CheckValueLocale("choose_monitor_type", "", {}, intl)}:
              </Typography>
            </Grid>
            {availableMonitorTypes?.filteredData?.map((type) => {
              const typeId = type?.monitor_type_id;
              const typeName = type?.monitor_type_name?.toLowerCase();
              const selected =
                formik?.values?.channel_type?.toLowerCase() === typeName;
              const disabled = isMonitorAction;
              return (
                <Grid key={typeId} lg={6} item>
                  <Box
                    className={`create-report-monitor-type-item ${disabled ? "" : "clickable"} ${selected ? "active" : ""}`}
                    onClick={() =>
                      disabled
                        ? null
                        : handleMonitorType(typeName?.toUpperCase())
                    }
                  >
                    <Typography
                      variant="h5"
                      className="monitor-type-title-wrapper"
                    >
                      {monitorTypeIcon?.[typeName]}
                      {CheckValueLocale(typeName, "", {}, intl)}
                    </Typography>
                    <Typography className="monitor-type-desc">
                      {CheckValueLocale(
                        `${typeName}_monitor_type_desc`,
                        "",
                        {},
                        intl,
                      )}
                    </Typography>
                  </Box>
                </Grid>
              );
            })}
            {formik?.touched?.channel_type &&
            Boolean(formik?.errors?.channel_type) ? (
              <Grid item xs={12}>
                <FormHelperText error>
                  {CheckValueLocale(formik?.errors?.channel_type, "", {}, intl)}
                </FormHelperText>
              </Grid>
            ) : null}
          </Grid>

          <Grid container spacing={2}>
            {/* Check if the selected monitor type is keyword to show the report type select input */}
            {isKeywordMonitorType ? (
              <Grid item xs={6}>
                <ReportSelectInput
                  id="create-report-repor-type-input"
                  label="report_type"
                  name="report_type_id"
                  options={
                    availableReportTypes?.filteredData?.map((item) => {
                      return {
                        value: item?.attributes?.report_type,
                        label: `create_report_${item?.attributes?.name}_type`,
                      };
                    }) || []
                  }
                  disabled={isMonitorAction}
                  value={formik?.values?.report_type_id}
                  onChange={handleChangeReportType}
                  onBlur={formik?.handleBlur}
                  error={
                    formik?.touched?.report_type_id &&
                    Boolean(formik?.errors?.report_type_id)
                  }
                  helperText={
                    formik?.touched?.report_type_id &&
                    formik?.errors?.report_type_id
                  }
                />
              </Grid>
            ) : null}

            {/* Check if the selected report type is not newsletter */}
            {!!formik?.values?.report_type_id && !isNewsletterReport ? (
              <Grid item xs={6}>
                <ReportSelectInput
                  id="create-report-repor-type-input"
                  label="data_source"
                  name="data_source_id"
                  options={
                    mapDatasourcesForOptions(
                      availableDatasources?.filteredData,
                    ) || []
                  }
                  disabled={
                    isMonitorAction &&
                    location?.state?.preSelected?.dataSources?.length == 1
                  }
                  value={
                    isMultiDatasources
                      ? formik?.values?.data_source_id || []
                      : formik?.values?.data_source_id?.[0] || ""
                  }
                  onChange={(e) => {
                    formik?.setValues({
                      ...formik?.values,
                      data_source_id: isMultiDatasources
                        ? e.target.value
                        : [e.target.value],
                      monitor_list: isMonitorAction
                        ? formik?.values?.monitor_list
                        : [],
                    });
                  }}
                  onBlur={formik?.handleBlur}
                  loading={availableDatasourcesLoading}
                  error={
                    formik?.touched?.data_source_id &&
                    Boolean(formik?.errors?.data_source_id)
                  }
                  helperText={
                    formik?.touched?.data_source_id &&
                    formik?.errors?.data_source_id
                  }
                  multiple={isMultiDatasources}
                />
              </Grid>
            ) : null}

            {/* Check if has selected report type */}
            {!!formik?.values?.report_type_id ? (
              <Grid item xs={6}>
                <CreateReportMonitorsSelect
                  options={availableMonitors || []}
                  disabled={isMonitorAction}
                  value={
                    isNewsletterReport
                      ? formik?.values?.monitor_list || []
                      : formik?.values?.monitor_list?.[0] || ""
                  }
                  onChange={handleChangeMonitors}
                  onBlur={formik?.handleBlur}
                  loading={availableMonitorsLoading}
                  error={
                    formik?.touched?.monitor_list &&
                    Boolean(formik?.errors?.monitor_list)
                  }
                  helperText={
                    formik?.touched?.monitor_list &&
                    formik?.errors?.monitor_list
                  }
                  multiple={isNewsletterReport}
                />
              </Grid>
            ) : null}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default ReportSettingSocialListening;
