import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { CheckValueLocale } from "utils/helpers";
import Services from "services/api/Services";
import LucButton from "shared/lucButton/lucButton";

const AnsweringFooter = (props) => {
  const {
    isEmbedded,
    handleClickNext,
    handleClickBack,
    handleSubmit,
    filteredSections,
    sectionsStack,
    btnVal,
    showPoweredBy,
    submitted,
    handleCloseEmbedded,
  } = props;

  const intl = useIntl();

  const isSubmit =
    btnVal === -1 || btnVal === null || filteredSections?.length < 2;

  return (
    <Box id="survey-answering-footer">
      {isEmbedded ? (
        <Box className="embedded-answering-footer-conetnt-container">
          {showPoweredBy ? (
            <Box className="powered-by-container">
              <Typography>
                {CheckValueLocale("powered_by", "", {}, intl)}
              </Typography>
              <img
                onClick={() => window.open(Services?.companyURL, "_blank")}
                src={Services?.projectLogoSVG}
                alt=""
              />
            </Box>
          ) : (
            <Box />
          )}

          <Box className="embedded-survey-answering-btns-container">
            {!submitted && sectionsStack?.length > 1 ? (
              <LucButton
                id="embedded-answering-back-btn"
                variant="outline"
                size="small"
                onClick={handleClickBack}
              >
                {CheckValueLocale("survey_back", "", {}, intl)}
              </LucButton>
            ) : null}

            {submitted ? (
              <LucButton
                id="embedded-answering-footer-close-btn"
                size="small"
                onClick={handleCloseEmbedded}
              >
                {CheckValueLocale("survey_close", "", {}, intl)}
              </LucButton>
            ) : (
              <LucButton
                id="embedded-answering-next-btn"
                size="small"
                endIcon={
                  isSubmit ? null : (
                    <FontAwesomeIcon
                      icon={intl?.locale === "ar" ? faArrowLeft : faArrowRight}
                      fixedWidth
                    />
                  )
                }
                onClick={isSubmit ? handleSubmit : handleClickNext}
              >
                {CheckValueLocale(
                  isSubmit ? "survey_submit" : "survey_next",
                  "",
                  {},
                  intl,
                )}
              </LucButton>
            )}
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default AnsweringFooter;
