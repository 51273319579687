import { createContext, useState } from "react";

export const ReportContext = createContext();

export const ReportProvider = ({ children }) => {
  // to save report name , report date , report id
  const [reportData, setReportData] = useState({});
  //to save active monitor with all data for this monitor to show
  const [activeMonitor, setActiveMonitor] = useState(false);
  const [loadindExport, setLoadindExport] = useState(false);
  const [snackBar, setSnackBar] = useState({
    open: false,
    title: "",
    msg: "",
    severity: "success",
  });

  const handleSnackBarSuccess = (title, message) => {
    setSnackBar({
      open: true,
      title: title,
      msg: message,
      severity: "success",
    });
  };
  const handleSnackBarError = (title, message) => {
    setSnackBar({
      open: true,
      title: title,
      msg: message,
      severity: "error",
    });
  };

  return (
    <ReportContext.Provider
      value={{
        reportData,
        setReportData,
        loadindExport,
        setLoadindExport,
        activeMonitor,
        setActiveMonitor,
        snackBar,
        setSnackBar,
        handleSnackBarSuccess,
        handleSnackBarError,
      }}
    >
      {children}
    </ReportContext.Provider>
  );
};
