import { useQuery } from "@tanstack/react-query";
import { ReportServices } from "services/api/reports";

export const useReportLuciTokens = ({ queryData }) => {
  return useQuery({
    queryKey: ["reportLuciTokens"],
    queryFn: () => ReportServices.getReportLuciTokens(queryData),
    enabled: !!queryData?.report_type_id,
    select: (data) => data?.data,
  });
};
