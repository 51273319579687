import { useQuery } from "@tanstack/react-query";
import Engagements from "services/api/engagements";

export const useSurveySelectedData = (payLoad) => {
  return useQuery({
    queryKey: ["SurveySelectedData"],
    queryFn: () => Engagements.getSurveySelectedData(payLoad),
    enabled: !!payLoad,
  });
};
