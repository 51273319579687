import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale, emailValidator, getSocialIcon } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { processEngagerSocialMediaData } from "../utils/processEngagerSocialMediaData";
import { UserInfoItemList } from "./userInfoItemList";
import { socialMediaValidationSchemaMap } from "../utils/validationSchemas";
import { useContext } from "react";
import { EditContext } from "../context";

const socialMediaNameMap = {
  fb: "facebook",
  tw: "twitter",
  ig: "instagram",
  gmail: "gmail",
  intercom: "intercom",
  whatsapp: "whatsapp",
  tiktok: "tiktok",
};

const getSocialLink = (type, name) => {
  const SocialIcons = {
    fb: "https://www.facebook.com/" + name,
    fb_dm: "https://www.facebook.com/" + name,
    tw: "https://twitter.com/" + name,
    tw_dm: "https://twitter.com/" + name,
    ig: "https://www.instagram.com/" + name,
    ig_dm: "https://www.instagram.com/" + name,
    intercom: emailValidator(name) ? "mailto:" + name : "tel:" + name,
    whatsapp: "https://api.whatsapp.com/send?phone=" + name?.replace("+", ""),
    gmail: "mailto:" + name,
    tiktok: "https://www.tiktok.com/@" + name,
  };
  return SocialIcons[type] || "";
};

const SocialMedia = ({ socialMediaData }) => {
  const intl = useIntl();
  const { isEditing } = useContext(EditContext);
  const transformedSocialMediaData =
    processEngagerSocialMediaData(socialMediaData);

  const handleClick = (type, name) => {
    window.open(getSocialLink(type, name), "_blank");
  };

  return (
    <>
      <div>
        <Accordion
          disabled={isEditing}
          classes={{ disabled: "engager-user-info-accordion-disabled" }}
          defaultExpanded
          className="engager-user-info-accordion"
        >
          <AccordionSummary
            expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
          >
            <Typography className="engager-user-info-title">
              {CheckValueLocale("user_accounts", "", {}, intl)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box className="engager-social-media-list">
              {Object.keys(transformedSocialMediaData)?.map((social) => {
                if (social?.includes("dm")) return null;
                const validationSchema =
                  socialMediaValidationSchemaMap[social] || null;

                return (
                  <UserInfoItemList
                    key={social}
                    title={CheckValueLocale(
                      `engager_profile_${social}_title`,
                      "",
                      {},
                      intl,
                    )}
                    isSocial
                    onItemClick={(item) => handleClick(social, item?.value)}
                    validationSchema={validationSchema}
                    data={transformedSocialMediaData[social]}
                    fieldType={socialMediaNameMap[social]}
                    emptyText={CheckValueLocale(
                      `no_attached_${social}_yet`,
                      "",
                      {},
                      intl,
                    )}
                    titleIcon={getSocialIcon(social)}
                  />
                );
              })}
            </Box>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};
export default SocialMedia;
