import React from "react";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  Box,
  Typography,
  Switch,
  Divider,
} from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import ranksArrow from "images/ranksArrow.svg";
import RanksComponent from "./ranksComponent";
import CustomSourcesChipsInput from "./customSourcesChipsInput/customSourcesChipsInput";

const SourcesSection = (props) => {
  const intl = useIntl();
  const {
    selectedSource,
    setSelectedSource,
    setMajorSource,
    nbRanks,
    setNbRanks,
    nbDuplicated,
    setNbDuplicated,
    setNbExcludedSource,
    nbExcludedSource,
  } = props;
  return (
    <>
      <Box>
        <Typography
          className="keywords keyword-head-title dropdown-keyword-head"
          variant="caption"
        >
          {CheckValueLocale("sources", "", {}, intl)}
        </Typography>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          className="news-source-inputs"
        >
          <Grid item xs={7}>
            <FormControl className="nb-sources-wrapper">
              <RadioGroup
                className="create-mopnitor-radio-group"
                defaultValue={
                  selectedSource === "major_sources" ? "major" : "all"
                }
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="all"
                  control={<Radio />}
                  label={CheckValueLocale("all_sources", "", {}, intl)}
                  onClick={() => {
                    setSelectedSource("all_sources");
                    setMajorSource([]);
                    setNbRanks([1, 2, 3, 4, 5]);
                    setNbExcludedSource(nbExcludedSource);
                  }}
                />
                {selectedSource !== "major_sources" ? (
                  <Box className="ranks-container">
                    <img className="arrow-icon" src={ranksArrow} alt="" />
                    <RanksComponent
                      isCreateMonitor={true}
                      nbRanks={nbRanks}
                      setNbRanks={setNbRanks}
                      setNbDuplicated={setNbDuplicated}
                      selectedSource={selectedSource}
                    />
                  </Box>
                ) : null}
                <FormControlLabel
                  value="major"
                  control={<Radio />}
                  label={CheckValueLocale("custom_sources", "", {}, intl)}
                  onClick={() => {
                    setSelectedSource("major_sources");
                    setNbRanks([]);
                    setNbExcludedSource([]);
                  }}
                />
                {selectedSource === "major_sources" ? (
                  <>
                    <CustomSourcesChipsInput {...props} />
                  </>
                ) : null}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={5}>
            {selectedSource !== "major_sources" ? (
              <CustomSourcesChipsInput
                {...props}
                showCustomSourcesInputTitle
                type="excludeSources"
              />
            ) : null}
          </Grid>
        </Grid>
      </Box>

      {/* ---------------- Duplicated Articles Switch ---------------- */}
      <Divider sx={{ paddingBottom: 4 }} />
      <Box className="duplicated-articles-container">
        <Typography
          className="keywords keyword-head-title dropdown-keyword-head duplicated-switch-title"
          variant="caption"
        >
          {CheckValueLocale("include_duplicated_articles", "", {}, intl)}
          <Box component={"span"} className="duplicated-articles-switch">
            <Switch
              id="create-monitor-duplicated-switch"
              size="small"
              checked={nbDuplicated}
              onChange={(e) => {
                setNbDuplicated(e.target.checked);
              }}
            />
          </Box>
        </Typography>
        <Typography className="duplicated-articles-description" variant="p">
          {CheckValueLocale(
            "include_duplicated_articles_description",
            "",
            {},
            intl,
          )}
        </Typography>
      </Box>
    </>
  );
};

export default SourcesSection;
