import { Box } from "@mui/material";
import SentimentCategoriesThemes from "components/sentimentCategoriesThemes";
import { useIntl } from "react-intl";
import LucButton from "shared/lucButton/lucButton";
import { CheckValueLocale } from "utils/helpers";
import { extractSelectedThemesAndSentiments } from "../postAndRepliesUtils";

export const ThemesAndSentiments = ({
  replyId,
  handleChangeInteraction,
  selectedInteractions,
  interactionLoading,
  sentimentsOptions,
  themesOptions,
  subthemesOptions,
  showThemes,
  toggleShowThemes,
  mainPost,
  isThreadPost,
}) => {
  const intl = useIntl();
  if (!mainPost) return null;

  // Destructure the selected themes, sub-themes, and sentiments,
  // along with their loading states for this specific reply.
  const {
    selectedThemes,
    selectedSentiment,
    selectedSubthemes,
    isThemesLoading,
    isSentimentLoading,
    isSubthemesLoading,
  } = extractSelectedThemesAndSentiments(
    selectedInteractions,
    interactionLoading,
    replyId,
  );

  return (
    <>
      <Box className="card-sentiment">
        <SentimentCategoriesThemes
          sentimentData={{
            btnId: "engagement-card-sentiment-btn",
            options: sentimentsOptions,
            selected: selectedSentiment,
            handleChange: (newValue) =>
              handleChangeInteraction(
                "sentiment",
                newValue,
                replyId,
                selectedSentiment,
              ),
            loading: isSentimentLoading,
          }}
          dividers={isThreadPost ? [2] : [2, 3]}
        />
      </Box>
      {isThreadPost ? null : (
        <>
          {showThemes ? (
            <SentimentCategoriesThemes
              categoriesData={{
                btnId: "engagement-card-categories-btn",
                options: themesOptions,
                selected: selectedThemes,
                handleChange: (newValue) =>
                  handleChangeInteraction(
                    "themes",
                    newValue,
                    replyId,
                    selectedThemes,
                  ),
                loading: isThemesLoading,
              }}
              themesData={{
                btnId: "engagement-card-themes-btn",
                options: subthemesOptions,
                selected: selectedSubthemes,
                handleChange: (newValue) =>
                  handleChangeInteraction(
                    "sub_themes",
                    newValue,
                    replyId,
                    selectedSubthemes,
                  ),
                loading: isSubthemesLoading,
              }}
              dividers={[1]}
            />
          ) : (
            <LucButton
              type="secondary"
              variant="flat"
              size="small"
              onClick={toggleShowThemes}
            >
              {CheckValueLocale("show_more_btn", "", {}, intl)}
            </LucButton>
          )}
        </>
      )}
    </>
  );
};
