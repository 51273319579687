import { faCalendar, faRetweet } from "@fortawesome/free-solid-svg-icons";
import {
  faChartColumn,
  faEllipsisVertical,
} from "@fortawesome/pro-regular-svg-icons";

import { faBadgeCheck, faComments } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Tooltip, Typography, Divider } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import FetchingData from "components/fetchingData/fetchingData";
import NoDataFound from "components/no-Data/noDataFound";
import SnackBar from "components/snackBar";
import penSquare from "images/penSquare.svg";
import _ from "lodash";
import moment from "moment";
import CommonFunctions from "pages/Monitors/Components/commonFunctions";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Link, useParams } from "react-router-dom";
import {
  CheckValueLocale,
  calculateUnixTimeZone,
  checkIfWeNeedShowMoreTweet,
  convertXPlatformToTwitter,
  handlelUserRoles,
  formatTimeZone,
  isEmptyArray,
  numberFormatter,
} from "utils/helpers";
import "./authors.scss";
import ListItemIcon from "@mui/material/ListItemIcon";
import InsightsIcon from "@mui/icons-material/Insights";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import IconFunctions from "pages/Monitors/Components/headerIconFunctions";
import PopupModal from "components/popupModal";
import xPlatform from "images/social-icons/x-platform-white-icon.svg";
import user from "images/monitor_revamp/user.svg";
import CustomizedTooltip from "components/customizedTooltip";
import FullTextTweetPopUp from "components/fullTextTweetPopUp";
import { CustomPagination } from "components/CustomPagination/CustomPagination";

const AuthorsBody = (props) => {
  const { snackBarData, openSnackBar, handleCloseSnack, postSpamTweets } =
    IconFunctions();
  const {
    response,
    preLoaderTrackerDataSources,
    resetAllFields,
    changePagination,
    totalPage,
    pageNumber,
    setTotalPage,
    monitorData,
    singleTweetAnalysis,
  } = props;

  /*______________________________________________________________*/
  //get All used monitor details from url link
  const urlParams = useParams();
  const monitorType = urlParams.monitorType;
  const monitorDataSource = convertXPlatformToTwitter(
    urlParams.monitorDataSource,
  );
  const activeTab = urlParams.activeTab;
  const monitorId = urlParams.monitorId;
  /*______________________________________________________________*/

  const intl = useIntl();
  const monitorDetails = monitorData;
  const [authorPreloader, setAuthorPreloader] = useState([]);
  const [authorsData, setAuthorData] = useState([]);
  const [page, setPage] = useState(1);
  const [openMoreVertIcon, setOpenMoreVertIcon] = useState(false);
  const [clickedCard, setClickedCard] = useState();
  const [showFullTextTweet, setShowFullTextTweet] = useState([]);

  useEffect(() => {
    if (resetAllFields !== 1) {
      setAuthorPreloader([]);
      setAuthorData([]);
    }
  }, [resetAllFields]);

  const handleResponseFunc = async () => {
    if (response.eventName === "Twitter__AuthorsPage__top_authors") {
      setAuthorData(response?.eventData?.top_authors);
      const listShowFullText = response?.eventData?.top_authors?.map(
        () => false,
      );
      setShowFullTextTweet(listShowFullText);
      if (pageNumber === 1) {
        setTotalPage(response.eventData.top_authors_count);
        setPage(1);
      }
      if (!authorPreloader.includes(parseInt(response?.monitor_id))) {
        setAuthorPreloader([...authorPreloader, parseInt(response.monitor_id)]);
      }
    } else {
      return null;
    }
  };
  useEffect(() => {
    handleResponseFunc();
  }, [response]);

  const handleChangePage = (event, page) => {
    if (Boolean(page) === false) return;
    setPage(page);
    changePagination(page);
  };

  const handleClick = (index) => {
    setOpenMoreVertIcon(!openMoreVertIcon);
    setClickedCard(index);
  };

  const {
    updateAnalysis,
    handleCloseSnackBar,
    showAnalyzeTweetSnackBar,
    analyzeTweetSnackBar,
    stats,
    handleCloseCreateModal,
    modalBody,
  } = CommonFunctions();

  const updateTweets = (tweetID, screenName, tweetTime) => {
    let monitorID = props?.monitorId;
    updateAnalysis(tweetID, screenName, monitorID, tweetTime);
  };
  useEffect(() => {
    let totalPreLoaderDataSouces = [];

    totalPreLoaderDataSouces.push(authorPreloader);

    props.checkAllDataFounded(
      totalPreLoaderDataSouces,
      props.preLoaderTrackerDataSources,
    );

    props.checkDataIsFounded(authorsData);
  }, [authorPreloader, authorsData]);

  useEffect(() => {
    props?.setTotalPostsCount?.(totalPage);
  }, [totalPage]);
  const getFollowersNum = (follower) => {
    if (follower > 999) {
      return `${numberFormatter(follower)}+`;
    } else {
      return follower.toLocaleString();
    }
  };
  const handleImageError = (event) => {
    event.target.src = user;
    event.target.alt = "placeholder-image";
  };

  const hasSingleTweetSubscription = handlelUserRoles(
    "SM",
    "CREATE_POST_ANALYSIS",
  );

  const companyTimezone = localStorage.getItem("companyInfo");
  return (
    <>
      {_.isEqual(
        preLoaderTrackerDataSources?.sort(),
        authorPreloader?.sort(),
      ) !== true ||
      !preLoaderTrackerDataSources?.length ||
      !authorPreloader?.length ? (
        <FetchingData />
      ) : isEmptyArray(authorsData) ? (
        <NoDataFound />
      ) : (
        authorsData?.map((cardData, index) => {
          const comments = [
            {
              name: "comments_mentions_monitor",
              value: cardData.comments_mentions_count,
              icon: penSquare,
            },
            {
              name: "total_interactions",
              tooltip: "total_interactions_tooltip_title",
              value: cardData.total_interactions,
              icon: faComments,
            },
          ];
          return (
            <Box className={"post-item authors-item"}>
              <Box className="post-head" id={`authors-profile-post-head`}>
                <Link
                  className="authors-link"
                  to={{
                    pathname: `/monitor/${monitorType}/${urlParams.monitorDataSource}/${activeTab}/${monitorId}/author-profile/${cardData.tweet_id}/${cardData.user_id}`,
                    state: monitorDetails,
                  }}
                >
                  <Box className="post-title-date">
                    <Box className="authors-user-img">
                      <Box className="authors-img">
                        <img
                          src={cardData.user_profile_image_url}
                          alt=""
                          onError={handleImageError}
                        />
                      </Box>
                      <Box className="authors-icon">
                        <img
                          src={xPlatform}
                          alt="x-platform-logo"
                          className="twitter_icon x-platform-img"
                        />
                      </Box>
                    </Box>

                    <Box className="post-title">
                      <Box className="flex-Stylee">
                        <Box className="post-name-authors">
                          {cardData.user_name}
                        </Box>
                        {cardData.verified !== "false" && (
                          <FontAwesomeIcon
                            icon={faBadgeCheck}
                            color="#1D9BF0"
                          />
                        )}

                        <Box className="post-date">
                          <Divider
                            orientation="vertical"
                            className="author-divider"
                          />
                          <Typography
                            variant="inherit"
                            className="ltr-direction"
                          >
                            {getFollowersNum(cardData.followers_count)}
                          </Typography>

                          {cardData?.country ? (
                            <Divider
                              orientation="vertical"
                              className="author-divider"
                            />
                          ) : null}
                          {CheckValueLocale(cardData?.country, "", {}, intl)}
                        </Box>
                      </Box>
                      <Box
                        className="post-user-name"
                        id={`authors-profile-post-head-${
                          cardData?.user_name ?? "card-data-user-name"
                        }`}
                      >
                        @{cardData.screen_name}
                      </Box>
                    </Box>
                  </Box>
                </Link>

                <Box className="post-top-icons more-vert-icon">
                  <CustomizedTooltip
                    id={
                      openMoreVertIcon && clickedCard === index
                        ? "author-item-list-tooltip"
                        : ""
                    }
                    title={
                      <Box className="item-title-tooltip">
                        {CheckValueLocale("items_ellipsis", "", {}, intl)}
                      </Box>
                    }
                    arrow
                    placement="bottom"
                  >
                    <IconButton
                      disableRipple
                      aria-label="more"
                      aria-haspopup="true"
                      onClick={() => handleClick(index)}
                      className={`authors-header-style ${
                        openMoreVertIcon &&
                        clickedCard === index &&
                        "author-post-open"
                      }`}
                      id="author-post-choices-icon-btn"
                    >
                      <FontAwesomeIcon
                        icon={faEllipsisVertical}
                        className={
                          openMoreVertIcon && clickedCard === index
                            ? "menu-style menu-style-open"
                            : "menu-style"
                        }
                      />
                    </IconButton>
                  </CustomizedTooltip>
                  {openMoreVertIcon && clickedCard === index && (
                    <div className="custom-dropdown">
                      {hasSingleTweetSubscription ? (
                        <Tooltip
                          title={
                            props.singleTweetAnalysis
                              ? CheckValueLocale(
                                  "analyze_post_tooltip",
                                  "",
                                  {},
                                  intl,
                                )
                              : CheckValueLocale(
                                  "analyze_tweet_upgrade",
                                  "",
                                  {},
                                  intl,
                                )
                          }
                        >
                          <div
                            className="interaction-header-align custom-dropdown-items"
                            onClick={() =>
                              !singleTweetAnalysis
                                ? false
                                : updateTweets(
                                    cardData?.tweet_id,
                                    cardData?.screen_name,
                                    cardData?.tweet_created_at,
                                  )
                            }
                            id="author-post-choices-analyze-tweet-btn"
                          >
                            <ListItemIcon>
                              <InsightsIcon fontSize="small" />
                            </ListItemIcon>

                            {props.singleTweetAnalysis
                              ? CheckValueLocale("analyze_tweet", "", {}, intl)
                              : CheckValueLocale("analyze_tweet", "", {}, intl)}
                          </div>
                        </Tooltip>
                      ) : null}

                      <div
                        className="interaction-header-align custom-dropdown-items"
                        onClick={() => {
                          postSpamTweets(cardData, monitorDataSource);
                          handleClick(index);
                        }}
                        id="author-post-choices-spam-tweet-btn"
                      >
                        <ListItemIcon>
                          <NotInterestedIcon fontSize="small" />
                        </ListItemIcon>
                        {CheckValueLocale("spam_tweeet", "", {}, intl)}
                      </div>
                    </div>
                  )}
                </Box>
              </Box>
              <Box className={"flex-style-box authors-margin"}>
                <Box style={{ paddingTop: "16px" }}>
                  {comments.map((i) => {
                    return (
                      <Box className="total-interactions-style">
                        <Box className="author-icons">
                          {i.name !== "comments_mentions_monitor" ? (
                            <FontAwesomeIcon
                              icon={i.icon}
                              className="author-font-icons"
                            />
                          ) : (
                            <img src={i.icon} alt={i.name} />
                          )}
                        </Box>

                        <Box className="author-details">
                          <Typography className="author-body-title">
                            {i.value.toLocaleString()}
                          </Typography>
                          {i?.tooltip ? (
                            <Tooltip
                              title={
                                i.tooltip &&
                                CheckValueLocale(i.tooltip, "", {}, intl)
                              }
                              arrow
                              placement="top"
                            >
                              <Typography className="author-body-subtitle-tooltip">
                                {CheckValueLocale(i.name, "", {}, intl)}
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography className="author-body-subtitle">
                              {CheckValueLocale(i.name, "", {}, intl)}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>

                <Box className={"question-content authors-card"}>
                  <Box className="widget-box">
                    <Box className="authorsCard">
                      <Box>
                        {CheckValueLocale(
                          "top_comments_mentions",
                          "",
                          {},
                          intl,
                        )}
                      </Box>
                      {
                        <>
                          {/* analyze_tweet icon_____________________________________________*/}
                          {/* <Tooltip
                          title={CheckValueLocale("analyze_tweet"  ,"",{},intl) }
                          arrow
                          placement="top"
                        >
                          <IconButton>
                            <FontAwesomeIcon
                              icon={faChartColumn}
                              className="chart-icon"
                              solid
                              onClick={() =>
                                updateAnalysis(
                                  cardData?.tweet_id,
                                  cardData?.screen_name
                                )
                              }
                            />
                          </IconButton>
                        </Tooltip> */}
                        </>
                      }
                    </Box>
                    {cardData?.tweet_text}
                    {checkIfWeNeedShowMoreTweet(
                      cardData?.tweet_text,
                      cardData?.tweet_full_text,
                    ) ? (
                      <Box
                        component="span"
                        className="tweet-full-text-read-all-btn"
                        onClick={() =>
                          setShowFullTextTweet((prev) => {
                            let newPrev = [...prev];
                            newPrev[index] = true;
                            return newPrev;
                          })
                        }
                      >
                        {CheckValueLocale("read_all", "", {}, intl)}
                      </Box>
                    ) : null}
                    <Box className={"statistics-icons authors-margin"}>
                      <Box>
                        <FontAwesomeIcon icon={faRetweet} />{" "}
                        {cardData.retweet_count.toLocaleString()}
                      </Box>
                      <Box>
                        <FontAwesomeIcon icon={faCalendar} />{" "}
                        {
                          formatTimeZone(
                            calculateUnixTimeZone(cardData?.tweet_created_at, {
                              utc: true,
                            }),
                            intl,
                            {},
                            companyTimezone,
                          ).completeFormat
                        }
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {showFullTextTweet[index] ? (
                <FullTextTweetPopUp
                  closeFilter={() =>
                    setShowFullTextTweet((prev) => {
                      let newPrev = [...prev];
                      newPrev[index] = false;
                      return newPrev;
                    })
                  }
                  post={cardData}
                  showRetweet={true}
                />
              ) : null}
            </Box>
          );
        })
      )}

      {/* pagination & openSnackBar */}
      {authorsData?.length > 0 && totalPage > 10 && (
        <CustomPagination
          onChange={handleChangePage}
          page={page}
          pagesCount={Math.ceil(totalPage / 10)}
          itemsCount={totalPage}
        />
      )}
      {showAnalyzeTweetSnackBar && (
        <SnackBar
          open={true}
          handleClose={handleCloseSnackBar}
          title={CheckValueLocale(analyzeTweetSnackBar.title, "", {}, intl)}
          message={CheckValueLocale(analyzeTweetSnackBar.message, "", {}, intl)}
          severity={analyzeTweetSnackBar.severity}
        />
      )}
      {openSnackBar && (
        <SnackBar
          open={openSnackBar}
          severity={snackBarData.severity}
          message={CheckValueLocale(snackBarData.message, "", {}, intl)}
          handleClose={handleCloseSnack}
        />
      )}
      <PopupModal
        title={CheckValueLocale("single_tweet_analysis", "", {}, intl)}
        body={modalBody}
        open={stats?.openCreateModal}
        close={handleCloseCreateModal}
        warning="warning"
        classeName="create-historical-popup"
        addClasses="create-single-post-analysis-modal"
      />
    </>
  );
};

export default AuthorsBody;
