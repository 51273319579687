import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import SharedExportButton from "shared/exportButton/sharedExportButton";
import moment from "moment";

const Header = ({
  selectedTab,
  startDate,
  endDate,
  excelSheetData,
  checkAllDataExist,
  checkDataIsEmpty,
}) => {
  const intl = useIntl();

  const savedFileName = `${selectedTab || "inbox"}-${
    startDate && endDate
      ? `${moment?.unix(startDate)?.utc()?.format("DD/MM/YYYY")} - ${moment?.unix(endDate)?.utc()?.format("DD/MM/YYYY")}`
      : ""
  }`;

  return (
    <Box className={"engag-analytics-header"}>
      {CheckValueLocale(`${selectedTab}_analytics`, "", {}, intl)}
      <SharedExportButton
        hidePPTExport
        defaultExportType="excel"
        isDataLoading={!checkAllDataExist}
        dataLoadedButEmpty={!checkDataIsEmpty}
        exportButtonId="engagement-analytics-export-btn"
        savedFileName={savedFileName}
        excelSheetDataInfo={{
          excelSheetData,
          pageName: "engagement_analytics",
          activeTabName: selectedTab || "inbox",
        }}
      />
    </Box>
  );
};

export default Header;
