import { useMutation, useQueryClient } from "@tanstack/react-query";
import SurveyController from "services/controllers/surveyController";

export const useArchiveSurvey = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ id }) => await SurveyController.archiveSurvey(id),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["surveysList"] }),
  });
};
