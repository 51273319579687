import * as XLSX from "xlsx";
import _ from "lodash";

export const importExcelFileValidationSchema = (
  files,
  excelFileName,
  intl,
  setErrorMessages,
  setImportedUsernames,
  isValidFileName,
  excelFileErrorMsg,
) => {
  if (files?.length === 0) {
    return [];
  }
  const usersregex = /^[a-zA-Z0-9_]*$/;
  const messages = [];

  // File format and size checks

  if (
    !isValidFileName(excelFileName) ||
    excelFileErrorMsg === "file-invalid-type"
  ) {
    messages?.push("accounts_list_file_format_not_supported");
    setErrorMessages(messages);
    return;
  }
  if (excelFileErrorMsg === "file-too-large") {
    messages?.push("accounts_list_file_exceed_size_limit");
    setErrorMessages(messages);
    return;
  }
  const file = files[0]?.file;

  const reader = new FileReader();
  reader.onload = (e) => {
    const data = e?.target?.result;
    const workbook = XLSX?.read(data, { type: "binary" });
    const sheet = workbook?.Sheets[workbook?.SheetNames[0]];

    // Convert the first column into an array of rows
    const rows = XLSX?.utils?.sheet_to_json(sheet, { header: 1, raw: true });
    const firstColumn = rows?.map((row) => row[0]);

    // Check number of rows in the first column
    const validRows = firstColumn?.filter(
      (cell) => cell != undefined && cell !== "",
    );
    const rowCount = validRows?.length;

    if (rowCount < 3) {
      messages?.push("accounts_list_file_empty_error");
    }
    if (rowCount >= 203) {
      messages?.push("accounts_list_file_big_error");
    }
    const lengthErrorRows = [];
    const specialCharErrorRows = [];
    firstColumn?.forEach((row, index) => {
      // Skip rows 1 and 2
      if (index <= 2) return;

      if (row == null || String(row)?.length === 0) return;

      if (!usersregex?.test(row)) {
        specialCharErrorRows?.push(index + 1);
      }

      if (String(row)?.length > 50) {
        lengthErrorRows?.push(index + 1);
      }
    });

    if (specialCharErrorRows?.length > 0) {
      messages?.push({
        rows: specialCharErrorRows,
        message: "accounts_list_special_characters_error",
      });
    }

    if (lengthErrorRows?.length > 0) {
      messages?.push({
        rows: lengthErrorRows,
        message: "accounts_list_length_error",
      });
    }
    setErrorMessages(messages);
    messages?.length === 0
      ? setImportedUsernames(
          _?.uniq(
            firstColumn
              ?.filter((item) => item != null && item !== "")
              ?.slice(
                2,
                firstColumn?.length >= 203 ? 202 : firstColumn?.length,
              ),
          ),
        )
      : setImportedUsernames([]);
  };
  reader?.readAsBinaryString(file);
  return messages;
};
