import React, { useState } from "react";
import { Box, Tooltip, Zoom } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useIntl } from "react-intl";
import "../../publicAccount/twitterAccountAnalysis/customerCare/customerCare.scss";
import MonitorMediaInstaItem from "components/media/media-insta/monitorMediainstaItem/monitorMediaInstaItem";
import MediaFbItem from "components/media/media-fb/mediaFbItem";
import { CheckValueLocale, checkIfWeNeedShowMoreTweet } from "utils/helpers";
import DOMPurify from "dompurify";
import EngagementsController from "services/controllers/engagementsController";
import SnackBar from "components/snackBar.js";
import { tooltipClasses } from "@mui/material/Tooltip";
import EditReply from "pages/engagements/components/details/postsAndReplies/editReply/editReply";
import moment from "moment";
import { MediaPreviewer } from "pages/engagements/components/mediaPreviewer/mediaPreviewer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import FullTextTweetPopUp from "components/fullTextTweetPopUp";

export const handleClassNameForMediaPreview = (postMediaLength) => {
  return `post-image-box ${
    postMediaLength == 1
      ? "one-img"
      : postMediaLength == 2
        ? "two-img"
        : postMediaLength == 3
          ? "three-img"
          : postMediaLength >= 4
            ? "four-img"
            : ""
  }`;
};

const CardBody = (props) => {
  const intl = useIntl();
  const {
    question,
    showRetweet,
    showHeart,
    widget,
    monitorDataSource,
    datasourcePostsType,
    isAggregateDashboard,
    monitorType,
    activePostsTab,
    activeTab,
    activeReplyEdit,
    setActiveReplyEdit,
    index,
  } = props;

  //check if text contain Arabic character or not to handle [ RTL, LTR ]
  let arabic = /[\u0600-\u06FF]/;
  let english = /[\U+0000-\U+007F]/;
  let text = props?.question?.tweet_text;
  let textArr = text?.split(" ");
  let checkArabicText = arabic.test(text !== null ? text : "");
  let checkEnglishText = english.test(
    text !== null ? textArr?.[0].toUpperCase() : "",
  );

  let invalidText = text?.includes("message cannot be displayed!");
  // edit reply state
  const [maxChar, setMaxChar] = useState(280);
  const [editText, setEditText] = useState(props?.question?.tweet_text);
  const [btnLoading, setBtnLoading] = useState(false);
  const [editReplyDisable, setEditReplyDisable] = useState(false);
  const [editTooltipActive, setEditTooltipActive] = useState(false);
  const [editTooltipDate, setEditTooltipDate] = useState("");
  const [openSnackBarDelete, setOpenSnackBarDelete] = useState(false);
  const [snackBarDeleteData, setSnackBarDeleteData] = useState({
    severity: "",
    message: "",
    title: "",
  });
  const [showMediaPreviewer, setShowMediaPreviewer] = useState(false);
  const [defaultMediaIndex, setDefaultMediaIndex] = useState(0);
  const [media, setMedia] = useState([]);
  const [tweetText, setTweetText] = useState("");
  const [showFullTextTweet, setShowFullTextTweet] = useState(false);

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses?.arrow}`]: {
      color: "rgba(97, 97, 97, 0.9)",
    },
    [`& .${tooltipClasses?.tooltip}`]: {
      backgroundColor: "rgba(97, 97, 97, 0.9)",
      fontSize: "12px",
      lineHeight: "14px",
      padding: "10px 8px",
      fontWeight: "500",
    },
  }));

  const editDateTooltip = (editDate) => {
    const editDateTooltipVal = moment
      .unix(editDate)
      .utc()
      .format("LT")
      .split(" ");
    return (
      <Box className="edit-date-tooltip">
        <Box component="span">
          {moment.unix(editDate).utc().format("YYYY/MM/DD")}
        </Box>
        <Box component="span">{CheckValueLocale("at", "", {}, intl)}</Box>
        <Box component="span">
          {editDateTooltipVal?.length
            ? ` ${editDateTooltipVal[0]} ${CheckValueLocale(
                editDateTooltipVal[1] == "AM"
                  ? "hashtag_period_am"
                  : "hashtag_period_pm",
                "",
                {},
                intl,
              )}`
            : null}
        </Box>
      </Box>
    );
  };

  const handleCloseSnackBar = () => {
    setOpenSnackBarDelete(false);
  };

  const disableActiveEditMode = () => {
    if (activeReplyEdit) {
      activeReplyEdit[index] = false;
      setActiveReplyEdit([...activeReplyEdit]);
    }
  };

  //Handle Edit reply
  const handleEditReply = (replyId) => {
    const formData = new FormData();
    let objData = {
      id: replyId,
      data_source_id: +question?.CA_channel_id,
      monitor_model_id: +question?.tracker_id,
      text: editText,
      product_id: window?.localStorage?.sm_id,
    };
    formData.append("published", JSON.stringify(objData));
    setBtnLoading(true);
    setEditReplyDisable(true);
    EngagementsController.editReply(formData).then((res) => {
      if (res?.data?.status == 200 || res?.data?.status == 204) {
        setOpenSnackBarDelete(true);
        setEditTooltipActive(true);
        setEditReplyDisable(false);
        setBtnLoading(false);
        setEditTooltipDate(res?.data?.data?.date);
        disableActiveEditMode();
        setSnackBarDeleteData({
          severity: "success",
          title:
            activeTab == "comments_mentions"
              ? "comment_edited_successfully"
              : "post_edited_successfully",
        });
      } else {
        setOpenSnackBarDelete(true);
        setEditReplyDisable(false);
        setBtnLoading(false);
        disableActiveEditMode();
        setSnackBarDeleteData({
          title: "something_went_wrong",
          severity: "error",
        });
      }
    });
  };

  //Handle Media Click [ imgs - videos ]
  const handleMediaClick = (mediaArray, index) => {
    if (mediaArray?.length > 0) {
      setShowMediaPreviewer(true);
      const formattedMedia = mediaArray?.map((item) =>
        monitorDataSource === "TWITTER" ||
        datasourcePostsType === "TWITTER" ||
        datasourcePostsType === "TWITTER_PUBLIC"
          ? {
              url:
                item?.type === "photo" ? item?.media_url : item?.expanded_url,
              type: item?.type === "photo" ? "image" : "video",
              postText: props?.post?.tweet_text,
              img_preview: item?.media_url,
            }
          : {
              url:
                monitorDataSource === "NEWSBLOGS" ||
                datasourcePostsType === "NEWSBLOGS"
                  ? item?.url
                  : item?.expanded_url,
              type:
                monitorDataSource === "NEWSBLOGS" ||
                datasourcePostsType === "NEWSBLOGS"
                  ? "image"
                  : item?.type === "photo"
                    ? "image"
                    : "video",
              postText: props?.post?.tweet_text,
            },
      );
      setMedia(formattedMedia);
    }
    setDefaultMediaIndex(index);
  };

  const quotedPostMedia =
    props?.question?.quoted_status?.extended_tweet?.entities?.media ||
    props?.question?.quoted_status?.extended_tweet?.extended_entities?.media;

  return (
    <Box
      className={`${
        widget === "top_posts" ? "question-instagram" : ""
      } question-content  ${
        props?.question?.retweeted_status !== null &&
        props?.question?.quoted_status !== null
          ? "question-content-wrapper"
          : "question-content"
      }`}
    >
      {/* INSTAGRAM media */}
      {(monitorDataSource === "INSTAGRAM" ||
        datasourcePostsType === "INSTAGRAM" ||
        (activePostsTab === "INSTAGRAM" && isAggregateDashboard)) &&
      widget !== "comments_mentions" &&
      !activeReplyEdit[index] ? (
        <Box
          mt={
            (monitorDataSource === "INSTAGRAM" ||
              datasourcePostsType === "INSTAGRAM") &&
            widget === "top_posts"
              ? 3
              : 0
          }
        >
          <MonitorMediaInstaItem
            media={question?.tweet_link}
            hideMoreInsights
            hideLikeComments
          />
        </Box>
      ) : null}

      {/* Hanlde Media previewer component */}
      {showMediaPreviewer ? (
        <MediaPreviewer
          media={media}
          postText={tweetText}
          showMediaPreviewer={showMediaPreviewer}
          setShowMediaPreviewer={setShowMediaPreviewer}
          defaultIndex={defaultMediaIndex} // default is 0
          monitorDataSource={monitorDataSource || datasourcePostsType}
        />
      ) : null}

      {(monitorDataSource === "FACEBOOK" ||
        datasourcePostsType === "FACEBOOK" ||
        (activePostsTab === "FACEBOOK" && isAggregateDashboard)) &&
      widget === "top_posts" &&
      !activeReplyEdit[index] ? (
        <Box mt={3}>
          <MediaFbItem
            media={{ page_id: question?.user_id, post_id: question?.post_id }}
            hideTotalInteractions={true}
          />
        </Box>
      ) : null}
      <Box
        className={`${
          question?.media !== null &&
          question?.media !== undefined &&
          (monitorDataSource !== "INSTAGRAM" ||
            monitorDataSource !== "FACEBOOK" ||
            datasourcePostsType !== "INSTAGRAM" ||
            datasourcePostsType !== "FACEBOOK")
            ? "margin-style"
            : widget === "top_posts"
              ? "margin-style-insta"
              : ""
        }`}
      >
        {question?.reply_to &&
          props.question?.retweeted_status == null &&
          props.question?.quoted_status == null && (
            <Box className="reply-text">
              <Box style={{ color: "#80868c" }}>
                {CheckValueLocale("reply_to", "", {}, intl)}
              </Box>
              <Box className="reply-text-style">@{question?.reply_to}</Box>
            </Box>
          )}

        <Box className="card-tweet-text">
          {/* // ______________Default Case with show tweet text_____________________________________________ */}
          <Box
            className={`${
              checkArabicText && !checkEnglishText
                ? "tweetTextRtl"
                : "hybird-text post-tweet-text-default"
            } ${
              question?.media !== null &&
              checkArabicText &&
              checkEnglishText &&
              "display-in-row"
            }`}
            sx={editTooltipActive && { justifyContent: "unset" }}
          >
            {activeReplyEdit[index] ? (
              <EditReply
                slEdit
                disableActiveEditMode={disableActiveEditMode}
                question={question}
                editReplyDisable={editReplyDisable}
                maxChar={maxChar}
                handleEditReply={handleEditReply}
                btnLoading={btnLoading}
                editText={editText}
                setEditText={setEditText}
              />
            ) : (
              (props.question?.retweeted_status == null ||
                props.question?.quoted_status == null) && (
                <>
                  {editTooltipActive ? (
                    <>
                      {editText}
                      <BootstrapTooltip
                        title={editDateTooltip(editTooltipDate)}
                        arrow
                        placement="top"
                        TransitionComponent={Zoom}
                      >
                        <Box component="span" className="edited-tooltip">
                          ({CheckValueLocale("edited", "", {}, intl)})
                        </Box>
                      </BootstrapTooltip>
                    </>
                  ) : invalidText ||
                    (monitorType === "MANAGED_PAGE" &&
                      (activeTab === "posts" || activeTab === "account") &&
                      (monitorDataSource === "FACEBOOK" ||
                        datasourcePostsType === "FACEBOOK") &&
                      question?.item !== "photo" &&
                      question?.item !== "video") ? null : (
                    <Box
                      className={`${
                        props?.isSinglePostAnalysis && question?.media
                          ? "single-post-analysis-tweet-text"
                          : ""
                      }`}
                    >
                      <Box
                        component="span"
                        dangerouslySetInnerHTML={{
                          //dangerouslySetInnerHTML is React’s replacement for using innerHTML in the browser DOM
                          __html: DOMPurify.sanitize(
                            props?.isSinglePostAnalysis
                              ? props?.question?.tweet_full_text
                              : props.question?.tweet_text,
                          ),
                        }}
                      />
                      {!props?.isSinglePostAnalysis ? (
                        checkIfWeNeedShowMoreTweet(
                          props.question?.tweet_text,
                          props?.question?.tweet_full_text,
                        ) ? (
                          <Box
                            component="span"
                            className="tweet-full-text-read-all-btn"
                            onClick={() => setShowFullTextTweet(true)}
                          >
                            {CheckValueLocale("read_all", "", {}, intl)}
                          </Box>
                        ) : null
                      ) : null}
                    </Box>
                  )}
                  {props?.question?.media !== null &&
                    (monitorDataSource === "NEWSBLOGS" ||
                      datasourcePostsType === "NEWSBLOGS") &&
                    (question?.media ? (
                      <div
                        className={handleClassNameForMediaPreview(
                          Object.keys(question?.media)?.length,
                        )}
                      >
                        {Object.keys(question?.media)
                          ?.slice(0, 4)
                          ?.map((key, index) => {
                            const image = question?.media[key];
                            const mediaArray = Object.values(question?.media);
                            return (
                              <div
                                key={image?.id}
                                className="image-container-card-body"
                                onClick={() => {
                                  handleMediaClick(mediaArray, index);
                                  setTweetText(question?.tweet_text);
                                }}
                              >
                                {image?.type === "video" ||
                                image?.type === "animated_gif" ? (
                                  <Box
                                    component={"span"}
                                    className="video-icon"
                                  >
                                    <FontAwesomeIcon icon={faPlay} />
                                  </Box>
                                ) : null}
                                <img
                                  onClick={() => {
                                    handleMediaClick(mediaArray, index);
                                    setTweetText(question?.tweet_text);
                                  }}
                                  src={image?.url}
                                  alt=""
                                />
                                {index === 3 && question?.media?.length > 4 ? (
                                  <Box className="image-container-card-body-overlay">
                                    +{question?.media?.length - 4}
                                  </Box>
                                ) : null}
                              </div>
                            );
                          })}
                      </div>
                    ) : null)}
                </>
              )
            )}
            {(monitorDataSource === "TWITTER" ||
              datasourcePostsType === "TWITTER" ||
              datasourcePostsType === "TWITTER_PUBLIC" ||
              (activePostsTab === "TWITTER" && isAggregateDashboard)) &&
            question?.media ? (
              <div
                className={handleClassNameForMediaPreview(
                  Object.keys(question?.media)?.length,
                )}
              >
                {Object.keys(question?.media)
                  ?.slice(0, 4)
                  ?.map((key, index) => {
                    const image = question?.media[key];
                    const mediaArray = Object.values(question?.media);
                    return (
                      <div
                        key={image?.id}
                        className="image-container-card-body"
                        onClick={() => {
                          handleMediaClick(mediaArray, index);
                          setTweetText(question?.tweet_text);
                        }}
                      >
                        {image?.type === "video" ||
                        image?.type === "animated_gif" ? (
                          <Box component={"span"} className="video-icon">
                            <FontAwesomeIcon icon={faPlay} />
                          </Box>
                        ) : null}
                        <img src={image?.media_url} alt="" />
                        {index === 3 && question?.media?.length > 4 ? (
                          <Box className="image-container-card-body-overlay">
                            +{question?.media?.length - 4}
                          </Box>
                        ) : null}
                      </div>
                    );
                  })}
              </div>
            ) : null}
          </Box>
          {/* // ______________Quoted Case_____________________________________________ */}
          {/* <Box
            className={`${
              checkArabicText ? "tweetTextRtl" : "post-tweet-text-default"
            }`}
          >
            {props.question?.quoted_status &&
            (props?.monitorDataSource === "TWITTER" ||
              datasourcePostsType === "TWITTER" ||
              datasourcePostsType === "TWITTER_PUBLIC" ||
              (activePostsTab === "TWITTER" && isAggregateDashboard))
              ? props.question?.tweet_text
              : null}
          </Box> */}
          {props?.question?.quoted_status !== null &&
          (props?.monitorDataSource === "TWITTER" ||
            datasourcePostsType === "TWITTER" ||
            datasourcePostsType === "TWITTER_PUBLIC" ||
            (activePostsTab === "TWITTER" && isAggregateDashboard)) ? (
            <Box className="retweeted-quoted-wrapper">
              <Box className="retweeted-quoted-name-wrapper">
                <Box className="retweeted-quoted-user-name">
                  {props?.question?.quoted_status?.user?.name}
                </Box>
                <Box className="retweeted-quoted-screen-name">
                  @{props?.question?.quoted_status?.user?.screen_name}
                </Box>
              </Box>
              <Box className="retweeted-quote-text">
                {quotedPostMedia ? (
                  <div
                    className={handleClassNameForMediaPreview(
                      Object.keys(quotedPostMedia)?.length,
                    )}
                  >
                    {Object.keys(quotedPostMedia)
                      ?.slice(0, 4)
                      ?.map((key, index) => {
                        const image = quotedPostMedia[key];
                        const mediaArray = Object.values(quotedPostMedia);
                        return (
                          <div
                            key={image?.id}
                            className="image-container-card-body"
                            onClick={() => {
                              handleMediaClick(mediaArray, index);
                              setTweetText(question?.tweet_text);
                            }}
                          >
                            {image?.type === "video" ||
                            image?.type === "animated_gif" ? (
                              <Box component={"span"} className="video-icon">
                                <FontAwesomeIcon icon={faPlay} />
                              </Box>
                            ) : null}
                            <img
                              onClick={() => {
                                handleMediaClick(mediaArray, index);
                                setTweetText(question?.tweet_text);
                              }}
                              src={image?.media_url}
                              alt=""
                            />
                            {index === 3 && quotedPostMedia?.length > 4 ? (
                              <Box className="image-container-card-body-overlay">
                                +{quotedPostMedia?.length - 4}
                              </Box>
                            ) : null}
                          </div>
                        );
                      })}
                  </div>
                ) : null}

                {props?.question?.quoted_status !== null &&
                props?.question?.quoted_status?.extended_tweet?.full_text !==
                  undefined
                  ? props?.question?.quoted_status?.extended_tweet?.full_text
                  : props?.question?.quoted_status?.text}
              </Box>
            </Box>
          ) : null}
          {/* // ______________Retweeted Case_____________________________________________ */}

          {props?.question?.retweeted_status !== null &&
          (props?.monitorDataSource === "TWITTER" ||
            datasourcePostsType === "TWITTER" ||
            datasourcePostsType === "TWITTER_PUBLIC" ||
            (activePostsTab === "TWITTER" && isAggregateDashboard)) ? (
            <Box className="retweeted-quoted-wrapper">
              <Box className="retweeted-quoted-name-wrapper">
                <Box className="retweeted-quoted-user-name">
                  {props?.question?.retweeted_status?.user?.name}
                </Box>
                <Box className="retweeted-quoted-screen-name">
                  @{props?.question?.retweeted_status?.user?.screen_name}
                </Box>
              </Box>
              <Box className="retweeted-quote-text">
                {props?.question?.retweeted_status?.extended_entities?.media !==
                  undefined &&
                  (question?.media ? (
                    <div
                      className={handleClassNameForMediaPreview(
                        Object.keys(question?.media)?.length,
                      )}
                    >
                      {Object.keys(
                        props?.question?.retweeted_status?.extended_entities
                          ?.media,
                      )
                        ?.slice(0, 4)
                        ?.map((key, index) => {
                          const image =
                            props?.question?.retweeted_status?.extended_entities
                              ?.media[key];
                          const mediaArray = Object.values(
                            props?.question?.retweeted_status?.extended_entities
                              ?.media,
                          );

                          return (
                            <div
                              key={image?.id}
                              className="image-container-card-body"
                              onClick={() => {
                                handleMediaClick(
                                  mediaArray,
                                  index,
                                  props?.question?.retweeted_status?.retweeted,
                                );
                                setTweetText(question?.tweet_text);
                              }}
                            >
                              {image?.type === "video" ||
                              image?.type === "animated_gif" ? (
                                <Box component={"span"} className="video-icon">
                                  <FontAwesomeIcon icon={faPlay} />
                                </Box>
                              ) : null}
                              <img
                                onClick={() => {
                                  handleMediaClick(mediaArray, index);
                                  setTweetText(question?.tweet_text);
                                }}
                                src={image?.media_url}
                                alt=""
                                className="customer_media"
                              />
                              {index === 3 && question?.media?.length > 4 ? (
                                <Box className="image-container-card-body-overlay">
                                  +{question?.media?.length - 4}
                                </Box>
                              ) : null}
                            </div>
                          );
                        })}
                    </div>
                  ) : null)}

                {props?.question?.retweeted_status !== null &&
                props?.question?.retweeted_status?.extended_tweet?.full_text !==
                  undefined
                  ? props?.question?.retweeted_status?.extended_tweet?.full_text
                  : props?.question?.retweeted_status?.text}
              </Box>
            </Box>
          ) : null}
          {/* // ______________Reply Case_____________________________________________ */}

          {props?.question?.reply_status !== null &&
          (props?.monitorDataSource === "TWITTER" ||
            datasourcePostsType === "TWITTER" ||
            datasourcePostsType === "TWITTER_PUBLIC" ||
            (activePostsTab === "TWITTER" && isAggregateDashboard)) ? (
            <Box className="retweeted-quoted-wrapper">
              <Box className="retweeted-quoted-name-wrapper">
                <Box className="retweeted-quoted-user-name">
                  {props?.question?.reply_status?.user?.name}
                </Box>
                <Box className="retweeted-quoted-screen-name">
                  @{props?.question?.reply_status?.user?.screen_name}
                </Box>
              </Box>
              <Box className="retweeted-quote-text">
                {props?.question?.reply_status?.extended_tweet?.media !==
                  undefined &&
                  (question?.media ? (
                    <div
                      className={handleClassNameForMediaPreview(
                        Object.keys(question?.media)?.length,
                      )}
                    >
                      {Object.keys(
                        props?.question?.reply_status?.extended_tweet?.media,
                      )
                        ?.slice(0, 4)
                        ?.map((key, index) => {
                          const image =
                            props?.question?.reply_status?.extended_tweet
                              ?.media[key];
                          const mediaArray = Object.values(
                            props?.question?.reply_status?.extended_tweet
                              ?.media,
                          );

                          return (
                            <div
                              key={image?.id}
                              className="image-container-card-body"
                              onClick={() => {
                                handleMediaClick(mediaArray, index);
                                setTweetText(question?.tweet_text);
                              }}
                            >
                              {image?.type === "video" ||
                              image?.type === "animated_gif" ? (
                                <Box component={"span"} className="video-icon">
                                  <FontAwesomeIcon icon={faPlay} />
                                </Box>
                              ) : null}
                              <img
                                onClick={() => {
                                  handleMediaClick(mediaArray, index);
                                  setTweetText(question?.tweet_text);
                                }}
                                src={image?.media_url}
                                alt=""
                                className="customer_media"
                              />
                              {index === 3 && question?.media?.length > 4 ? (
                                <Box className="image-container-card-body-overlay">
                                  +{question?.media?.length - 4}
                                </Box>
                              ) : null}
                            </div>
                          );
                        })}
                    </div>
                  ) : null)}
                {props?.question?.reply_status !== null &&
                props?.question?.reply_status?.extended_tweet?.full_text !==
                  undefined
                  ? props?.question?.reply_status?.extended_tweet?.full_text
                  : props?.question?.reply_status?.text}
              </Box>
            </Box>
          ) : null}
        </Box>
      </Box>

      <SnackBar
        open={openSnackBarDelete}
        handleClose={handleCloseSnackBar}
        title={CheckValueLocale(snackBarDeleteData?.title, "", {}, intl)}
        severity={snackBarDeleteData?.severity}
        message={CheckValueLocale(snackBarDeleteData?.message, "", {}, intl)}
      />
      {showFullTextTweet ? (
        <FullTextTweetPopUp
          closeFilter={() => setShowFullTextTweet(false)}
          post={props?.question}
          showRetweet={
            showRetweet && props?.question?.retweet_count !== undefined
          }
          showHeart={showHeart && props?.question?.heart_count !== undefined}
          ShowComments={true}
        />
      ) : null}
    </Box>
  );
};

export default CardBody;
