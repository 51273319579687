import { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { CheckValueLocale, isEmptyArray, syncUnixToUTC } from "utils/helpers";
import _ from "lodash";
import CompaniesController from "services/controllers/companiesController";
import {
  Box,
  Tooltip,
  TextField,
  Button,
  FormHelperText,
  InputLabel,
  CircularProgress,
  Typography,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateRangePicker from "shared/dateRangePicker/dateRangePicker";
import "../publicAccount/twitterAccountAnalysis/authors/authorProfile/authorProfile.scss";
import LucButton from "shared/lucButton/lucButton";
import { useSummaryStaticQuestions } from "../hooks/useSummaryStaticQuestions";
import { useGetCompanySingleTweetsQuota } from "../hooks/useGetCompanySingleTweetsQuota";
import { useUpdateSingleTweetAnalysis } from "../hooks/useUpdateSingleTweetAnalysis";
import { faCircleExclamation } from "@fortawesome/pro-regular-svg-icons";
import {
  sidebarGeneralOptions,
  sidebarOptionsMapper,
} from "shared/customDateRangePicker/config/sidebarOptions";
import CustomDateRangePicker from "shared/customDateRangePicker/customDateRangePicker";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { handleSidebarAvailableOptions } from "shared/customDateRangePicker/utils/dateUtils";

const CommonFunctions = (props) => {
  const intl = useIntl();

  const [endDate, setEndDate] = useState(moment().unix());
  const [startDate, setStartDate] = useState(moment().subtract(0, "d").unix());
  const [tweetCreateAt, setTweetCreatedAt] = useState(0);
  const [checkAllDataExist, setCheckAllDataExist] = useState(false);
  const [checkDataIsEmpty, setCheckDataIsEmpty] = useState(false);
  const { monitorId, monitorType, activeTab } = useParams();
  const [showAnalyzeTweetSnackBar, setShowAnalyzeTweetSnackBar] =
    useState(false);
  const [analyzeTweetSnackBar, setAnalyzeTweetSnackBar] = useState({
    title: "",
    message: "",
    severity: "",
  });

  /* 
   - limit --> social_remaining_quota
   - used --> quota_consumer
   - type --> 1 [Single Analytics] , 2 [Data insights] 
   */
  const [postsUsedLimit, setPostsUsedLimit] = useState({
    used: 0,
    limit: 0,
    type: 0,
  });

  //deep insight popup
  const [quotaCount, setQuotaCount] = useState("");
  const [analyzeProfileError, setAnalyzeProfileError] = useState(false);
  const [formFieldErrors, setFormFieldErrors] = useState({
    quotaLimit: "",
  });
  const [tweetInfo, setTweetInfo] = useState({
    tweetId: "",
    screenName: "",
    trackerId: "",
  });

  const [enterFirstTime, setEnterFirstTime] = useState(false);
  const [totalPostsCount, setTotalPostsCount] = useState(0);
  const [postsSortBy, setPostsSortBy] = useState();
  const [summaryStaticQuestions, setSummaryStaticQuestions] = useState(null);
  const [openSnack, setOpenSnack] = useState(true);

  const dateRangePickerParamsRef = useRef();
  const { startTimeUTC, endTimeUTC } = dateRangePickerParamsRef?.current || {};
  const singleTweetCreateTime = moment
    .unix(tweetCreateAt)
    .utc()
    .format("DD/MM/YYYY");
  const minDate = moment.unix(tweetCreateAt).utc().format("YYYY/MM/DD");

  const handleHideSnackBar = () => {
    setOpenSnack(false);
  };
  // get Company Quota
  const getCompanyDataInsightsQuota = () => {
    CompaniesController.getCompanyDataInsightsQuota().then((result) => {
      var smQuota = result?.data;
      for (let i = 0; i < smQuota?.length; i++) {
        if (smQuota[i]?.name === "SM") {
          setPostsUsedLimit({
            limit:
              smQuota[i]?.quota?.posts_limit?.limit -
              smQuota[i]?.quota?.posts_limit?.used,
            used: smQuota[i]?.quota?.posts_limit?.used,
            type: 2,
          });
        }
      }
    });
  };

  // get Company Quota - Single Tweets Insights
  const {
    mutate: getCompanySingleTweetsQuotaAction,
    isPending: singleTweetPending,
  } = useGetCompanySingleTweetsQuota();
  const singleTweetLoading = singleTweetPending && !enterFirstTime;

  const getCompanySingleTweetsQuota = (
    tweetID,
    monitorID,
    productID,
    tweetTime,
    screenName,
    startDate,
    endDate,
  ) => {
    getCompanySingleTweetsQuotaAction(
      {
        tweetID,
        monitorID,
        productID,
        tweetTime,
        screenName,
        startDate,
        endDate,
      },
      {
        onSuccess: (result) => {
          const smQuota = result?.data?.data;
          setPostsUsedLimit({
            limit: smQuota?.social_remaining_quota,
            used: smQuota?.quota_consumer,
            type: 1,
          });
        },
        onSettled: () => {
          setEnterFirstTime(false);
        },
      },
    );
  };

  /* Single Tweet Analysis Btn____________________________________________________________________________________ */
  const handleCloseSnackBar = () => {
    setShowAnalyzeTweetSnackBar(false);
  };
  const [stats, setStats] = useState({
    anchorEl: null,
    openCreateModal: false,
  });

  const updateAnalysis = (
    tweetId,
    screenName,
    trackerId,
    tweetTime,
    enteredFirstTime = true,
  ) => {
    setTweetCreatedAt(tweetTime);
    setEnterFirstTime(enteredFirstTime);
    setTweetInfo({
      tweetId,
      screenName,
      trackerId,
    });

    handleOpenCreateModal();
  };

  const {
    mutate: updateSingleTweetAnalysisAction,
    isPending: isButtonLoading,
  } = useUpdateSingleTweetAnalysis();

  const updateSingleTweetAnalysis = (
    tweetId,
    screenName,
    trackerId,
    quotaCount,
    startDate,
    endDate,
  ) => {
    const queryData = {
      product_id: Number(localStorage.sm_id),
      monitor_id: monitorId ? monitorId : trackerId,
      tweet_id: tweetId,
      screen_name: screenName,
      requested_quota: parseInt(quotaCount),
      start_date: syncUnixToUTC(startDate, "start"),
      end_date: syncUnixToUTC(endDate, "end"),
    };

    updateSingleTweetAnalysisAction(queryData, {
      onSuccess: () => {
        setStats({ ...stats, openCreateModal: false });
        setQuotaCount("");
        setStats({ ...stats, openCreateModal: false });
        setQuotaCount("");
        setAnalyzeTweetSnackBar({
          title: "request_sent_successfully",
          message: "analyze_spa_success_msg",
          severity: "success",
        });
      },
      onError: (error) => {
        const errorResponse = error?.response?.data;
        const errorMessage = errorResponse?.exception?.message;
        const isTweetNotFound = errorMessage === "Tweet not found";

        setStats({ ...stats, openCreateModal: false });
        setQuotaCount("");
        setAnalyzeTweetSnackBar({
          title: !isTweetNotFound
            ? CheckValueLocale("settings_some_thing_went_wrong", "", {}, intl)
            : null,
          message: CheckValueLocale(
            isTweetNotFound ? "spa_tweet_not_found" : "try_again",
            "",
            {},
            intl,
          ),
          severity: "error",
        });
      },
      onSettled: () => {
        setShowAnalyzeTweetSnackBar(true);
      },
    });
  };

  const inputValidator = (inputValue) => {
    const value = /^\d*\d?((10)|(0))\.?((0)|(00))?$/;
    return value.test(inputValue);
  };

  const handleOpenCreateModal = () => {
    setStats({
      ...stats,
      openCreateModal: true,
      modalTitle: `${CheckValueLocale("single_tweet_analysis", "", {}, intl)}`,
      anchorEl: null,
    });
  };
  const handleCloseCreateModal = () => {
    setStats({ ...stats, openCreateModal: false });
    setQuotaCount("");
    setFormFieldErrors({
      quotaLimit: "",
    });
  };

  const handlequotaCount = (e) => {
    let value = e?.target?.value;
    setQuotaCount(value);
    let analyzeProfileError = "";

    setAnalyzeProfileError(false);

    if (!value) {
      analyzeProfileError = "this_field_is_required";
    } else if (Number(value) === 0) {
      analyzeProfileError = " ";
    } else if (postsUsedLimit?.limit < value && postsUsedLimit?.type === 2) {
      analyzeProfileError = "quota_out";
    } else if (postsUsedLimit?.limit === 0 && postsUsedLimit?.type === 2) {
      analyzeProfileError = "deep_insight_limit_zero";
    } else if (value > 200 && postsUsedLimit?.type === 2) {
      analyzeProfileError = "deep_insight_over_200";
    } else if (postsUsedLimit?.limit < value) {
      analyzeProfileError = "quota_out";
    }

    setFormFieldErrors({
      ...formFieldErrors,
      quotaLimit: analyzeProfileError,
    });
  };

  const applyFilter = (startDate, endDate) => {
    let productID = window?.localStorage?.sm_id;
    setStartDate(startDate);
    setEndDate(endDate);
    getCompanySingleTweetsQuota(
      tweetInfo.tweetId,
      tweetInfo.trackerId,
      productID,
      tweetCreateAt,
      tweetInfo.screenName,
      syncUnixToUTC(startDate, "start"),
      syncUnixToUTC(endDate, "end"),
    );
  };

  useEffect(() => {
    if (stats.openCreateModal) {
      const endDateUnix = moment().unix();
      const startDateUnix = moment().subtract(0, "d").unix();
      applyFilter(endDateUnix, startDateUnix);
    }
  }, [stats.openCreateModal]);

  const isPostLimitReached = postsUsedLimit?.limit <= 0 && !singleTweetPending;
  const modalBody = (
    <Box className="deep-insights-modal deep-insights-content-modal">
      <Box className="shared-dialog-body">
        <p>{CheckValueLocale("single_tweet_popup_desc", "", {}, intl)}</p>
      </Box>
      <Box className="collected-interactions-label-container">
        <InputLabel component="p" className="body-input-label show-red-icon">
          {CheckValueLocale("collected_interactions", "", {}, intl)}
        </InputLabel>
        <Box
          className={`collected-interactions-remaining-section ${
            isPostLimitReached ? "limit-error" : ""
          }`}
        >
          {CheckValueLocale(
            "deep_insights_remainig_post",
            "",
            {
              remaining: postsUsedLimit?.limit?.toLocaleString() ?? 0,
            },
            intl,
          )}
        </Box>
      </Box>

      <TextField
        variant="outlined"
        placeholder={CheckValueLocale("EX:30", "", {}, intl)}
        onChange={handlequotaCount}
        className="single-tweet-input"
        value={quotaCount}
        type="number"
        onKeyDown={(e) => {
          if (
            e.key === "." ||
            e.key === "e" ||
            e.key === "+" ||
            e.key === "-"
          ) {
            e.preventDefault();
          }
        }}
        disabled={singleTweetLoading}
        error={
          formFieldErrors?.quotaLimit ||
          analyzeProfileError ||
          isPostLimitReached
            ? true
            : false
        }
      />
      {formFieldErrors?.quotaLimit || analyzeProfileError ? (
        <FormHelperText error className="deep-insight-validation">
          {CheckValueLocale(
            analyzeProfileError
              ? "custom_domain_validation"
              : formFieldErrors?.quotaLimit,
            "",
            {},
            intl,
          )}
        </FormHelperText>
      ) : (
        <FormHelperText className="deep-insight-validation" />
      )}
      <Box className="create-historical-calendar single-post-analysis-calender">
        <Box className="label-container-calendar">
          <InputLabel component="p" className="body-input-label show-red-icon">
            {CheckValueLocale("dates_range", "", {}, intl)}
          </InputLabel>
          <Box className="post-publish-date">
            {CheckValueLocale("post_publish_date", "", {}, intl)}
            <div className="single-date">{singleTweetCreateTime}</div>
          </Box>
        </Box>
        <CustomDateRangePicker
          initialSidebarLabel={sidebarOptionsMapper.today}
          startDate={startDate}
          endDate={endDate}
          applyFilter={applyFilter}
          dateRangePickerParamsRef={dateRangePickerParamsRef}
          maxDate={new Date()}
          minDate={minDate}
          sidebarOptions={handleSidebarAvailableOptions(
            sidebarGeneralOptions,
            minDate,
          )}
          popperPositioning="bottom-start"
          customStyles={{
            type: "secondary",
            variant: "outline",
            size: "large",
            width: "100%",
            fixedWidth: "100%",
            startIcon: (
              <FontAwesomeIcon
                icon={faCalendar}
                className="custom-input-icon"
              />
            ),
          }}
        />
      </Box>

      {singleTweetPending ? (
        <Box className="single-tweet-quota">
          <CircularProgress />
        </Box>
      ) : (
        <Box className={`post-limit-box ${isPostLimitReached ? "error" : ""}`}>
          <Typography component="span" className="post-limit-text">
            {isPostLimitReached ? (
              <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <FontAwesomeIcon icon={faCircleExclamation} />
                {CheckValueLocale(
                  "total_estimate_number_of_replies_single_post_analysis_error",
                  "",
                  {},
                  intl,
                )}
              </Box>
            ) : (
              CheckValueLocale(
                "total_estimate_number_of_replies_single_post_analysis",
                "",
                {},
                intl,
              )
            )}
          </Typography>
          {!isPostLimitReached ? (
            <Typography component="span" className="post-limit-value">
              {postsUsedLimit?.used?.toLocaleString() ?? 0}
            </Typography>
          ) : null}
        </Box>
      )}

      <Box className="step-footer deep-insight">
        <LucButton
          variant="flat"
          type="secondary"
          id="cancel-btn-deep-insight"
          onClick={() => handleCloseCreateModal()}
        >
          {CheckValueLocale("cancel", "", {}, intl)}
        </LucButton>
        <LucButton
          id="request-btn-deep-insight"
          onClick={() =>
            updateSingleTweetAnalysis(
              tweetInfo?.tweetId,
              tweetInfo?.screenName,
              tweetInfo?.trackerId,
              quotaCount,
              startDate,
              endDate,
            )
          }
          disabled={
            quotaCount <= 0 ||
            (postsUsedLimit.type === 2 &&
              (quotaCount === "" || quotaCount < 10)) ||
            formFieldErrors?.quotaLimit !== "" ||
            postsUsedLimit?.limit <= 0 ||
            singleTweetLoading ||
            isButtonLoading
          }
          loading={singleTweetLoading || isButtonLoading}
        >
          {CheckValueLocale("request", "", {}, intl)}
        </LucButton>
      </Box>
    </Box>
  );

  // ____________________________________________________________________________________________________________________
  //format FilterParams to be string instead of array and remove empty array params
  const changeFilterParamsToString = (filterParams, isFormattedString) => {
    for (let [key, value] of Object.entries(filterParams)) {
      if (key != "manual_topics") {
        if (Array.isArray(value) && !isEmptyArray(value)) {
          //if value is array change it to string
          var filterParamsToString = {
            ...filterParamsToString,
            [key]: isFormattedString ? value : value.join(),
          };
        } else if (
          (typeof value === "string" && value !== "") ||
          typeof value === "boolean"
        ) {
          //if value is not array keep it string as it is
          //in case if hide spam/retweet
          filterParamsToString = {
            ...filterParamsToString,
            [key]: value,
          };
        }
      } else {
        filterParamsToString = {
          ...filterParamsToString,
          [key]: value,
        };
      }
    }
    return filterParamsToString;
  };
  /*______________________________________________________________*/
  // this fn used for check all data between preloader and tracker datasource is loaded
  const checkAllDataFounded = (totalPreLoaderDataSources, preLoaderTracker) => {
    if (preLoaderTracker?.length == 0) return false;
    else {
      let result = totalPreLoaderDataSources?.every(
        (item) => _.isEqual(item, preLoaderTracker) == true,
      );
      setCheckAllDataExist(result);
      return result;
    }
  };
  /*______________________________________________________________*/
  // this fn to check if any data founded or not
  const checkDataIsFounded = (totalData) => {
    let data = totalData.some((item) => !isEmptyArray(item));
    setCheckDataIsEmpty(data);
    return data;
  };

  //handle Auto summary widget in monitor
  const autoSummaryActive = {
    ACCOUNT_ANALYSIS: [
      "engagements",
      "customer_care",
      "comments_mentions",
      "questions",
    ],
    MANAGED_ACCOUNT: [
      "engagements",
      "customer_care",
      "comments_mentions",
      "questions",
    ],
    MANAGED_PAGE: [
      "engagements",
      "customer_care",
      "comments_mentions",
      "questions",
    ],
    KEYWORD: ["engagements", "posts", "comments_mentions"],
  };

  const getLuciFeature = (monitorType) => {
    let monitorTypes = {
      ACCOUNT_ANALYSIS: "Account Analysis",
      MANAGED_ACCOUNT: "Managed Account",
      MANAGED_PAGE: "Managed Account",
      KEYWORD: "Keywords",
      default: "Account Analysis",
    };
    return monitorTypes[monitorType] || monitorTypes.default;
  };

  const {
    mutate: getSummaryStaticQuestionsAction,
    isPending: summaryStaticQuestionsLoading,
  } = useSummaryStaticQuestions();

  const getSummaryStaticQuestions = (dataSourceId) => {
    const queryData = {
      section_name: activeTab,
      product_id: window.localStorage.sm_id,
      luci_feature: getLuciFeature(monitorType),
      data_source_id: dataSourceId,
    };
    getSummaryStaticQuestionsAction(
      {
        queryData: {
          params: queryData,
        },
      },
      {
        onSuccess: (result) => {
          if (result?.data?.data) {
            setSummaryStaticQuestions(result?.data?.data);
          } else {
            setSummaryStaticQuestions(null);
          }
        },
      },
    );
  };

  return {
    updateAnalysis,
    handleCloseSnackBar,
    showAnalyzeTweetSnackBar,
    analyzeTweetSnackBar,
    changeFilterParamsToString,
    checkAllDataFounded,
    checkDataIsFounded,
    checkAllDataExist,
    checkDataIsEmpty,
    setCheckDataIsEmpty,
    postsUsedLimit,
    handleCloseCreateModal,
    modalBody,
    stats,
    getCompanySingleTweetsQuota,
    getCompanyDataInsightsQuota,
    totalPostsCount,
    setTotalPostsCount,
    postsSortBy,
    setPostsSortBy,
    autoSummaryActive,
    getSummaryStaticQuestions,
    summaryStaticQuestions,
    summaryStaticQuestionsLoading,
    openSnack,
    handleHideSnackBar,
  };
};
export default CommonFunctions;
