import { noOnlySpaces } from "pages/engagements/components/aiAgents/aiAgentsValidation/publishValidationSchema";
import * as yup from "yup";

export const newsSourcesValidationSchema = yup.object({
  sourceUrl: yup
    .string()
    .test("url-validation", "custom_sources_error_message", (value) => {
      if (!value) {
        return true;
      }

      if (!value || !value?.includes(".")) {
        return true;
      }

      const domainRegex =
        /^(https?:\/\/)?[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9](\.[a-zA-Z0-9]{1,})+\/?$/;
      return domainRegex.test(value);
    })
    .nullable()
    .test("no-only-spaces", "custom_sources_error_message", (value) => {
      return !value || value?.trim()?.length > 0;
    }),
});

export const DEBOUNCE_DELAY = 500;

export const requestVerificationURLSchema = yup.object({
  sourcesUrls: yup
    .string()
    .required("this_field_is_required")
    .test(
      "valid-urls",
      "request_source_verification_text_area_error_message",
      (value) => {
        const urls = (value || "")?.split(",")?.map((v) => v?.trim());
        const urlRegex = /^(https?:\/\/)?([\w\-]+\.)+\w{2,}(\/\S*)?$/;
        return urls?.every((url) => urlRegex.test(url));
      },
    )
    .concat(noOnlySpaces("this_field_is_required")),
});

export const SNACKBAR_INITIAL_STATE = {
  open: false,
  message: "",
  severity: "",
  title: "",
};
