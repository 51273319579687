import moment from "moment";

/**
 * Calculate the working hours between two dates based on the working hours
 * @param {Moment} start - Start date moment object
 * @param {Moment} end - End date moment object
 * @param {Array} workingHours - Working hours .e.g {"sunday", [{from: "09:00", to: "14:00"}, {from: "15:00", to: "17:00"}] , "monday", [{from: "09:00", to: "14:00"}, {from: "15:00", to: "17:00"}] ...}
 * @returns {Number} - Working hours between two dates in seconds
 * @example
 * // returns 32400
 * calculateWorkingHours(1620000000, 1620000000, ["sunday", [{from: "09:00", to: "14:00"}, {from: "15:00", to: "17:00"}], "monday", [{from: "09:00", to: "14:00"}, {from: "15:00", to: "17:00"}])
 */
export const calculateWorkingHours = (start, end, workingHours) => {
  /* 
  the brute force way is to loop through the days and calculate the working hours for each day,
  but the timeline(range between start and end inclusive) could be in years, so this soltuion is not efficient.
  but if we looked closely to the timeline, we can see that the working hours are repeated every week, and we can
  calculate the working hours for a day and then multiply it by the number of the days in the timeline.
  but we need to consider the working hours for the start and end days, since start day could be starting in the middle of the working hours
  and the end day could be ending in the middle of the working hours.
                 14:00    00:00                 00:00     17:00
  timeline  start<-------|---------------------|--------->end

  so we need to calculate the working hours for the start day and the end day and then calculate the working hours for the days in between.
  */

  let totalSeconds = 0;

  // 1- First we need to precompute total working hours for each day in minutes
  const dailyWorkingMinutes = {};
  for (const [day, shifts] of Object.entries(workingHours)) {
    dailyWorkingMinutes[day] = shifts.reduce((total, shift) => {
      const from = moment(shift?.from, "HH:mm");
      const to = moment(shift?.to, "HH:mm");
      if (to.isBefore(from)) to?.add?.(1, "day"); // if the shift ends in the next day (will not happen in the same day) but just in case
      return total + to.diff(from, "minutes");
    }, 0);
  }

  // 2- Check if the start and end days are the same
  if (start.isSame(end, "day")) {
    return calculateDayOverlap(start, end, workingHours);
  }

  // 3- Calculate the working hours for the start day
  totalSeconds += calculateDayOverlap(
    start,
    start.clone().endOf("day"),
    workingHours,
  );

  // 4- Calculate the working hours for the end day
  totalSeconds += calculateDayOverlap(
    end.clone().startOf("day"),
    end,
    workingHours,
  );

  // 5- Calculate the working hours for the days in between
  const startFullDay = start.clone().add(1, "day").startOf("day");
  const endFullDay = end.clone().startOf("day");

  if (startFullDay.isBefore(endFullDay)) {
    const daysCounts = calculateDaysBetweenDates(startFullDay, endFullDay);

    // Multiply the working hours for each day by the number of days
    Object.entries(daysCounts).forEach(([day, count]) => {
      if (dailyWorkingMinutes[day]) {
        totalSeconds += dailyWorkingMinutes[day] * count * 60;
      }
    });
  }

  return totalSeconds;
};

/**
 * Calculate the number of days between two dates
 * @param {Object} start - Start date moment object
 * @param {Object} end - End date moment object
 * @returns {Object} - Number of days between two dates
 */
const calculateDaysBetweenDates = (start, end) => {
  const days = [
    "sunday", // 0
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday", // 6
  ];
  let daysCounts = {};

  // Get start and end day indexes 0 - 6
  const startDay = start.day();

  // total number of days between the two dates
  const totalDays = end.diff(start, "days");

  // calculate complete weeks
  const weeks = Math.floor(totalDays / 7);

  // Initialize counts for each day from complete weeks
  days.forEach((day) => {
    daysCounts[day] = weeks;
  });

  // Calculate the remaining days
  const remainingDays = totalDays % 7;
  let currentDay = startDay;
  for (let i = 0; i < remainingDays; i++) {
    const dayName = days[currentDay];
    daysCounts[dayName]++;
    currentDay = (currentDay + 1) % 7;
  }

  return daysCounts;
};

/**
 * Calculate the working hours between two dates in the same day
 * @param {Object} start - Start date  moment object
 * @param {Object} end - End date moment object
 * @param {Array} workingHours - Working hours .e.g {"sunday", [{from: "09:00", to: "14:00"}, {from: "15:00", to: "17:00"}] , "monday", [{from: "09:00", to: "14:00"}, {from: "15:00", to: "17:00"}] ...}
 * @returns {Number} - Working hours between two dates in seconds
 */

const calculateDayOverlap = (start, end, workingHours) => {
  const dayName = start.format("dddd")?.toLowerCase?.();
  const shifts = workingHours?.[dayName] || [];
  let dayTotal = 0;
  for (const shift of shifts) {
    const shiftStart = moment(start)
      .hours(shift?.from?.split(":")[0])
      .minutes(shift?.from?.split(":")[1])
      .seconds(0);
    const shiftEnd = moment(start)
      .hours(shift?.to?.split(":")[0])
      .minutes(shift?.to?.split(":")[1])
      .seconds(0);

    const overlapStart = moment.max(start, shiftStart);
    const overlapEnd = moment.min(end, shiftEnd);

    if (overlapStart.isBefore(overlapEnd)) {
      dayTotal += overlapEnd.diff(overlapStart, "seconds");
    }
  }
  return dayTotal;
};

/**
 * Check if the Agent is working now
 * @param {Moment} now - Current date moment object
 * @param {Array} workingHours - Working hours .e.g {"sunday", [{from: "09:00", to: "14:00"}, {from: "15:00", to: "17:00"}] , "monday", [{from: "09:00", to: "14:00"}, {from: "15:00", to: "17:00"}] ...}
 * @returns {Boolean} - True if the business is working now, False otherwise
 */
export const isWorkingNow = (now, workingHours) => {
  const dayName = now.format("dddd")?.toLowerCase?.();
  const shifts = workingHours?.[dayName] || [];
  const isWorking = shifts.some((shift) => {
    const shiftStart = now
      .clone()
      .hours(shift?.from?.split(":")[0])
      .minutes(shift?.from?.split(":")[1])
      .seconds(0);
    const shiftEnd = now
      .clone()
      .hours(shift?.to?.split(":")[0])
      .minutes(shift?.to?.split(":")[1])
      .seconds(0);
    // is Between is inclusive
    return now.isSameOrAfter(shiftStart) && now.isSameOrBefore(shiftEnd);
  });
  return isWorking;
};

/**
 * Get the seconds until the next shift starts
 * @param {*} now - Current date moment object
 * @param {*} workingHours
 * @param {*} offset
 */
export const getSecondsUntilNextShift = (now, workingHours) => {
  let nextShiftStart = null;
  let currentDay = now.clone();
  let daysChecked = 0;
  while (daysChecked < 7) {
    const dayName = currentDay.format("dddd")?.toLowerCase?.();
    const shifts = workingHours?.[dayName] || [];

    for (const shift of shifts) {
      const shiftStart = currentDay
        .clone()
        .hours(shift?.from?.split(":")[0])
        .minutes(shift?.from?.split(":")[1])
        .seconds(0);
      // if the shiftstart is after now and it's the  closest to now then set it as the next shift start
      if (shiftStart.isAfter(now)) {
        if (!nextShiftStart || shiftStart.isBefore(nextShiftStart)) {
          nextShiftStart = shiftStart;
        }
      }
    }
    if (nextShiftStart) break;
    currentDay.add(1, "day");
    daysChecked++;
  }
  // return the seconds until the next shift starts or null if there is no next shift
  return nextShiftStart ? nextShiftStart.diff(now, "seconds") : null;
};

/**
 * Computes agent working hours information for a given interaction.
 *
 * @function getWorkingSecondsInTimeRange
 * @param {number} startUnix - Unix timestamp representing the  start time range time.
 * @param {number} endUnix - Unix timestamp representing the end time up to which we calculate working hours.
 * @param {Array} workingHours - Working hours for the team.
 * @returns {Object} - An object containing:
 *   @property {boolean} hasWorkingHours - Indicates if the team working hours are defined.
 *   @property {number} totalWorkingSecondsInRange - Total agent working seconds within the specified time range.
 *   @property {boolean} isAgentWorkingNow - Indicates if the agent is currently within working hours.
 *   @property {number} secondsUntilNextShift - Seconds remaining until the next working shift starts, if applicable.
 */
export const getWorkingSecondsInTimeRange = (start, end, teamWorkingHours) => {
  const isStartValid = moment.unix(start).isValid();
  const isEndValid = moment.unix(end).isValid();
  const hasTeamWorkingHours = !!(
    typeof teamWorkingHours === "object" &&
    Object.keys(teamWorkingHours)?.length &&
    isStartValid &&
    isEndValid
  );

  let totalWorkingSecondsInRange = 0;
  let isAgentWorkingNow = false;
  let secondsUntilNextShift = 0;

  if (hasTeamWorkingHours) {
    const companyTimeZone = parseInt(localStorage.companyInfo || 0);
    // added utc to remove browser timezone offset
    const momentCreatedAt = moment.unix(start).utc();
    const momentEndTime = moment.unix(end).utc();
    totalWorkingSecondsInRange = calculateWorkingHours(
      momentCreatedAt,
      momentEndTime,
      teamWorkingHours,
    );
    // added company timezone to the current time to get the current time in the company timezone
    // this is needed to check if the agent is working now
    const currentTimeForCompanyTimeZone = moment
      .unix(moment().unix() + companyTimeZone * 60 * 60)
      .utc();
    isAgentWorkingNow = isWorkingNow(
      currentTimeForCompanyTimeZone,
      teamWorkingHours,
    );
    secondsUntilNextShift = !isAgentWorkingNow
      ? getSecondsUntilNextShift(
          currentTimeForCompanyTimeZone,
          teamWorkingHours,
        )
      : 0;
  }
  return {
    hasWorkingHours: hasTeamWorkingHours,
    workingSeconds: totalWorkingSecondsInRange,
    isAgentWorkingNow,
    secondsUntilNextShift,
    teamWorkingHours,
  };
};
