import { FormControl, MenuItem, TextField } from "@mui/material";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale, classNames } from "utils/helpers";

export const GeneralGroupBy = ({
  options,
  selectedValue,
  onChange,
  groupByWidgetId,
  disabled,
  noGroubByLabel,
}) => {
  const [value, setValue] = useState(selectedValue);
  const intl = useIntl();

  const handleChange = (event) => {
    setValue(event.target.value);
    onChange(event.target.value);
  };
  return (
    <FormControl
      className={classNames(
        "select-widget-dropdown select-groupby-dropdown general-groupby-dropdown",
        noGroubByLabel ? "no-groupby-label" : "",
      )}
      id={groupByWidgetId ?? "select-groupby-dropdown"}
    >
      <TextField
        select
        label={
          !noGroubByLabel ? CheckValueLocale("group_by", "", {}, intl) : ""
        }
        InputLabelProps={{
          className: "genral-groupby-label",
        }}
        value={value}
        onChange={handleChange}
        className="dropdown-field"
        disabled={disabled}
      >
        {options?.map?.((option) => (
          <MenuItem
            key={option}
            value={option}
            id={`${groupByWidgetId ?? "groupByWidgetId"}-${
              option ?? "option"
            }-item`}
          >
            {CheckValueLocale(option, "", {}, intl)}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
};
