import { Box, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EnImg from "images/engagements-images/survey/enExample.svg";
import ArImg from "images/engagements-images/survey/arExample.svg";

import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import SurveyContent from "./SurveyContent";
import SurveySetting from "./SurveySetting";

const ChatSurvey = ({ surveyData, setSurveyData, selectedTap }) => {
  const intl = useIntl();
  const [expanded, setExpanded] = useState("1");
  const [selectedTapContent, setSelectedTapContent] = useState("en_content");

  useEffect(() => {
    setExpanded("1");
    setSelectedTapContent("en_content");
  }, [selectedTap]);

  return (
    <Box className="main-chat-survey">
      <Box className="engag-survey-disc">
        {CheckValueLocale("chat_survey_disc", "", {}, intl)}
      </Box>
      <Box className="chat-survey-container">
        <Box className="engag-survey-accordion">
          <Accordion
            expanded={expanded === "1"}
            onChange={() => setExpanded(expanded === "1" ? null : "1")}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className="accordion-header" component="span">
                {CheckValueLocale("survey_contant", "", {}, intl)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <SurveyContent
                surveyData={surveyData}
                setSurveyData={setSurveyData}
                selectedTap={selectedTap}
                selectedTapContent={selectedTapContent}
                setSelectedTapContent={setSelectedTapContent}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "2"}
            onChange={() => setExpanded(expanded === "2" ? null : "2")}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className="accordion-header" component="span">
                {CheckValueLocale("survey_setting", "", {}, intl)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <SurveySetting
                  surveyData={surveyData}
                  setSurveyData={setSurveyData}
                  selectedTap={selectedTap}
                />
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box className="chat-survey-img">
          <Box>{CheckValueLocale("branding_preview_title", "", {}, intl)}</Box>
          <img src={selectedTapContent === "en_content" ? EnImg : ArImg} />
        </Box>
      </Box>
    </Box>
  );
};

export default ChatSurvey;
