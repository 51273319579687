import { useQuery } from "@tanstack/react-query";
import Engagements from "services/api/engagements";

export const useSurveyPredefinedOptions = (payLoad) => {
  return useQuery({
    queryKey: ["SurveyPredefinedOptions"],
    queryFn: () => Engagements.getSurveyPredefinedOptions(payLoad),
    enabled: !!payLoad,
  });
};
