import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";

const YesNoQuestion = (props) => {
  const {
    filteredSections,
    rootQuestion,
    question,
    questionIndex,
    sectionIndex,
    handleChangeProceed,
    handleProceedAnswer,
    previewMode,
  } = props;

  const intl = useIntl();
  const [answer, setAnswer] = useState(null);
  const [proceedSelects, setProceedSelects] = useState([]);

  const optionNo = [
    CheckValueLocale("a", "", {}, intl),
    CheckValueLocale("b", "", {}, intl),
  ];

  useEffect(() => {
    if (filteredSections) {
      let selectsArray = [];
      filteredSections?.map((sec, index) => {
        if (index !== sectionIndex && index !== sectionIndex + 1)
          selectsArray?.push(sec);
      });
      setProceedSelects(selectsArray);
    }
  }, [filteredSections?.length]);

  return (
    <Box className="survey-builder-question-yes-no">
      {question?.items?.map((val, index) => (
        <Box
          className="answer-container"
          key={index}
          onClick={() => {
            if (previewMode) {
              setAnswer(index);
              if (rootQuestion?.is_proceed_on_answer)
                handleProceedAnswer(val?.next_section);
            }
          }}
        >
          <Typography
            component="body2"
            id="ahmeddd"
            className={`yes-no-answer ${previewMode ? "preview" : ""} ${answer === index ? "selected" : ""}`}
          >
            <Typography component="body2">{`${optionNo?.[index]}- `}</Typography>
            {CheckValueLocale(val?.[index + 1], "", {}, intl)}
          </Typography>
          {!previewMode && rootQuestion?.is_proceed_on_answer ? (
            <Box component="span" className="proceed-select-container">
              <FormControl fullWidth size="small">
                <Select
                  id={`yesno-next-section-select-${sectionIndex}`}
                  value={val?.next_section}
                  onChange={(e) =>
                    handleChangeProceed(e, sectionIndex, questionIndex, index)
                  }
                  MenuProps={{ PaperProps: { style: { maxHeight: 265 } } }}
                  variant="standard"
                  size="small"
                  fullWidth
                >
                  {sectionIndex !== filteredSections?.length - 1 && (
                    <MenuItem value={filteredSections[sectionIndex]?.order + 1}>
                      {`${CheckValueLocale(
                        "continue_next_section",
                        "",
                        {},
                        intl,
                      )} (${filteredSections[sectionIndex + 1]?.name})`}
                    </MenuItem>
                  )}
                  <MenuItem value={-1}>
                    {CheckValueLocale("submit_survey", "", {}, intl)}
                  </MenuItem>
                  {proceedSelects?.map((section, i) => {
                    return (
                      <MenuItem key={i} value={section?.order}>
                        {`${CheckValueLocale(
                          "go_to_section",
                          "",
                          {},
                          intl,
                        )} ${section?.order} (${section?.name})`}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          ) : null}
        </Box>
      ))}
    </Box>
  );
};

export default YesNoQuestion;
