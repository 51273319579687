import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";

const LinearScaleQuestion = (props) => {
  const { question, previewMode, handleProceedAnswer, rootQuestion } = props;

  const [lowValue, setLowValue] = useState("");
  const [highValue, setHighValue] = useState("");
  const [selectedAnswer, setSelectedAnswer] = useState(null);

  useEffect(() => {
    setLowValue(question?.constraints?.low_value);
    setHighValue(question?.constraints?.high_value);
  }, [question?.constraints?.low_value, question?.constraints?.high_value]);

  return (
    <Box className="survey-builder-question-nps-scale">
      <Box className="question-nps-scale-items-container">
        {question?.items?.map((item, index) => {
          const itemValue =
            Number(question?.constraints?.min_value) === 0
              ? item?.[index]
              : item?.[index + 1];

          const nextSection = item?.next_section;

          return (
            <Box
              key={index}
              component="span"
              className={`nps-scale-item ${itemValue === selectedAnswer ? "selected-nps-scale-item" : ""}`}
              onClick={() => {
                if (previewMode) {
                  setSelectedAnswer(itemValue);
                  if (rootQuestion?.is_proceed_on_answer)
                    handleProceedAnswer(nextSection);
                }
              }}
            >
              <Typography className="nps-scale-item-value">
                {itemValue}
              </Typography>
            </Box>
          );
        })}
      </Box>
      {lowValue || highValue ? (
        <Box className="question-nps-scale-items-text-container">
          <Typography component="body2" className="nps-scale-item-text">
            {lowValue || ""}
          </Typography>
          <Typography component="body2" className="nps-scale-item-text">
            {highValue || ""}
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};

export default LinearScaleQuestion;
