import { api } from "./newServices";

const getReportsList = (queryData) => {
  return api.get("api/v3/reports", { params: queryData });
};

const applyReportsFilter = (queryData) => {
  return api.get("api/v3/reports/filter", { params: queryData });
};

const getReportProducts = () => {
  return api.get("api/v3/reports/products");
};

const getReportMonitorTypes = (id) => {
  return api.get(`api/v3/authorized_monitor_type/${id}`);
};

const getReportTypes = () => {
  return api.get("api/v3/report_types");
};

const getReportDatasources = (queryData) => {
  return api.get("api/v3/data_sources", { params: queryData });
};

const getReportMonitors = (queryData) => {
  return api.get("api/v3/report_monitors_list", { params: queryData });
};

const getReportCategories = (queryData) => {
  return api.get("api/v3/reports/categories", { params: queryData });
};

const getReportChannels = (queryData) => {
  return api.get("api/v3/reports/monitors", { params: queryData });
};

const getReportUsers = () => {
  return api.get("api/v3/company/user_managements_active_users");
};

const getReportFrequencies = () => {
  return api.get("api/v3/reports/frequencies");
};

const getReportLuciTokens = (queryData) => {
  return api.get("api/v3/reports/luci_token", { params: queryData });
};

const createReport = (queryData) => {
  return api.post("api/v3/reports", queryData);
};

const getReportRecords = (queryData) => {
  return api.get(`api/v3/reports/report_records`, { params: queryData });
};

const editReport = ({ id, queryData }) => {
  return api.put(`api/v3/reports/${id}`, queryData);
};

const deleteReport = (id) => {
  return api.delete(`api/v3/reports/${id}`);
};

const updateReportStatus = ({ id, queryData }) => {
  return api.patch(`api/v3/reports/update_status/${id}`, queryData);
};

const getReportsFilterDatasources = () => {
  return api.get(`api/v4/data_sources`);
};

const exportEnhancedReport = (queryData) => {
  return api.post(`api/v3/reports/export`, queryData);
};

const getNewsLetterReport = (queryData) => {
  return api.get("api/v5/reports/newsletter_report_data", {
    params: queryData,
  });
};

export const ReportServices = {
  getReportsList,
  applyReportsFilter,
  getReportProducts,
  getReportMonitorTypes,
  getReportTypes,
  getReportDatasources,
  getReportMonitors,
  getReportCategories,
  getReportChannels,
  getReportUsers,
  getReportFrequencies,
  getReportLuciTokens,
  createReport,
  getReportRecords,
  editReport,
  deleteReport,
  updateReportStatus,
  getReportsFilterDatasources,
  exportEnhancedReport,
  getNewsLetterReport,
};
