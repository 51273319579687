// @ts-nocheck
import moment from "moment";
import { useIntl } from "react-intl";
import {
  calculateUnixTimeZone,
  formatTimeZone,
  isEmptyObj,
} from "utils/helpers";

const getGroupedData = (data, duration, intl) => {
  let groupedData = {},
    themeData = { ...data };
  for (let key in themeData) {
    let period = formatTimeZone(calculateUnixTimeZone(Number(key)), intl).date;

    if (duration === "yearly") {
      period = moment(
        period,
        intl.locale === "ar" ? "YYYY/MM/DD" : "DD/MM/YYYY",
      ).year();
    } else if (duration === "weekly") {
      const beginningOfWeek = formatTimeZone(
        calculateUnixTimeZone(
          moment(period, intl.locale === "ar" ? "YYYY/MM/DD" : "DD/MM/YYYY")
            .week(Number(period))
            .startOf("week")
            .unix(),
        ),
        intl,
        { dateFormat: intl.locale === "ar" ? "YYYY/MM/DD" : "DD/MM/YYYY" },
      ).date;
      const endOfWeek = formatTimeZone(
        calculateUnixTimeZone(
          moment(period, intl.locale === "ar" ? "YYYY/MM/DD" : "DD/MM/YYYY")
            .week(Number(period))
            .startOf("week")
            .add(6, "days")
            .unix(),
        ),
        intl,
        { dateFormat: intl.locale === "ar" ? "YYYY/MM/DD" : "DD/MM/YYYY" },
      ).date;

      period = beginningOfWeek + "\n" + endOfWeek;
    } else if (duration === "monthly") {
      period =
        moment(
          period,
          intl.locale === "ar" ? "YYYY/MM/DD" : "DD/MM/YYYY",
        ).year() +
        "-" +
        moment(period, intl.locale === "ar" ? "YYYY/MM/DD" : "DD/MM/YYYY")
          .month(period)
          .format("MMM");
    }

    let lastObj = groupedData[period]
        ? JSON.parse(JSON.stringify(groupedData[period]))
        : {},
      value = { ...themeData[key] };
    if (!isEmptyObj(lastObj)) {
      for (let [mainThemeKey, mainThemeVal] of Object.entries(
        themeData[key].main_themes_view,
      )) {
        if (lastObj.main_themes_view[mainThemeKey] === undefined) {
          lastObj.main_themes_view[mainThemeKey] = {
            count: mainThemeVal.count,
          };
        } else {
          lastObj.main_themes_view[mainThemeKey].count =
            mainThemeVal.count + lastObj.main_themes_view[mainThemeKey].count;
        }

        let lastObjSubThemes =
          lastObj.main_themes_view[mainThemeKey].sub_themes;

        for (let [subThemekey, subThemeValue] of Object.entries(
          mainThemeVal.sub_themes,
        )) {
          if (lastObjSubThemes !== undefined) {
            lastObj.main_themes_view[mainThemeKey].sub_themes[subThemekey] =
              lastObjSubThemes[subThemekey] + subThemeValue;
          } else {
            lastObj.main_themes_view[mainThemeKey].sub_themes =
              mainThemeVal.sub_themes;
          }
        }
      }

      for (let [subThemeKey, subThemeVal] of Object.entries(
        themeData[key].sub_themes_view,
      )) {
        if (lastObj.sub_themes_view[subThemeKey] === undefined) {
          lastObj.sub_themes_view[subThemeKey] = { count: subThemeVal.count };
        } else {
          lastObj.sub_themes_view[subThemeKey].count =
            subThemeVal.count + lastObj.sub_themes_view[subThemeKey].count;
        }

        let lastObjMainThemes =
          lastObj.sub_themes_view[subThemeKey].main_themes;

        for (let [mainThemekey, mainThemeValue] of Object.entries(
          subThemeVal.main_themes,
        )) {
          if (lastObjMainThemes !== undefined) {
            lastObj.sub_themes_view[subThemeKey].main_themes[mainThemekey] =
              lastObjMainThemes[mainThemekey] + mainThemeValue;
          } else {
            lastObj.sub_themes_view[subThemeKey].main_themes =
              subThemeVal.main_themes;
          }
        }
      }
      value = lastObj;
    }

    groupedData[period] = value;
  }
  return groupedData;
};

export default getGroupedData;
