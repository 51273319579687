import { Box, Pagination, PaginationItem, TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesLeft, faAnglesRight } from "@fortawesome/pro-solid-svg-icons";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import "./CustomPagination.scss";
import { useEffect, useState } from "react";

export const CustomPagination = ({
  pagination,
  onChange,
  page = 1,
  pagesCount = 1,
  itemsCount = 0,
  itemsPerPage = 10,
  className,
  variant = "text",
  shape = "rounded",
  color = "primary",
}) => {
  const intl = useIntl();
  const [pageInput , setPageInput] = useState(page) 
  const pagesCountValue = pagination?.pages || pagesCount;
  const itemCountValue = pagination?.count || itemsCount;
  const itemsPerPageValue = pagination?.items || itemsPerPage;

  const handleGoToPage = (event) => {
    if (event?.key === "Enter") {
      const pageNumber = event?.target?.value;
      let currentPage = page;
      if (pageNumber < 1) {
        currentPage = 1;
      } else if (pageNumber > pagesCountValue) {
        currentPage = Number(pagesCountValue);
      } else {
        currentPage = Number(pageNumber);
      }

      onChange(event, currentPage);
    }
  };

  const getItemRange = (page, itemsPerPageValue, itemCountValue) => {
    const startRow = pagination?.from
      ? pagination?.from
      : (page - 1) * itemsPerPageValue + 1;

    const endRow = pagination?.to
      ? pagination?.to
      : Math.min(page * itemsPerPageValue, itemCountValue);

    return `${startRow} - ${endRow}`;
  };

  useEffect(()=>{
    setPageInput(page)
  },[page])

  return (
    <Box className={"custom-pagination-container"}>
      <span className="pagination-rows-indicator">
        {getItemRange(page, itemsPerPageValue, itemCountValue)}
        <span className="pagination-rows-count">
          {" " + CheckValueLocale("of", "", {}, intl) + " " + itemCountValue}
        </span>
      </span>

      <Pagination
        onChange={onChange}
        page={Number(page)}
        className={`custom-pagination ${className}`}
        count={pagesCountValue}
        rowsPerPage={itemsPerPageValue}
        variant={variant}
        shape={shape}
        color={color}
        boundaryCount={1}
        siblingCount={1}
        showFirstButton
        showLastButton
        renderItem={(item) => (
          <PaginationItem
            slots={{
              first: () => <FontAwesomeIcon icon={faAnglesLeft} />,
              last: () => <FontAwesomeIcon icon={faAnglesRight} />,
            }}
            {...item}
          />
        )}
      />
      <span className="pagination-input-container">
        {CheckValueLocale("go_to_page", "", {}, intl)}
        <TextField
          className="pagination-input"
          variant="outlined"
          size="small"
          type="number"
          value={pageInput}
          onChange={(e) => setPageInput(e?.target?.value)}
          onKeyDown={handleGoToPage}
          onInput={(event) => {
            // Remove any special characters
            event.target.value = event.target.value.replace(/[^0-9]/g, "");
          }}
          inputProps={{
            min: 1,
            max: pagesCountValue,
          }}
        />
        {`${CheckValueLocale("of", "", {}, intl)} ${pagesCountValue}`}
      </span>
    </Box>
  );
};
