import { isValidPhoneNumber } from "libphonenumber-js";
import * as Yup from "yup";
const rexSpecialChar = /^[\u0621-\u064A0-9a-zA-Z0-9-_ ]+$/;

export const profileFormSchema = Yup.object().shape({
  first_name: Yup?.string()
    ?.trim()
    ?.required("this_field_required")
    ?.max(30, "Name_must_be_under_30_characters")
    ?.matches(rexSpecialChar, "please_avoid_using_special_characters"),
  last_name: Yup?.string()
    ?.trim()
    ?.required("this_field_required")
    ?.max(30, "Name_must_be_under_30_characters")
    ?.matches(rexSpecialChar, "please_avoid_using_special_characters"),
  email: Yup?.string(), // always disabled
  phone_number: Yup?.string()?.test(
    "isValidPhone",
    "invalid_phone_number",
    (value) => {
      if (!value) return true;
      return isValidPhoneNumber(value);
    },
  ),
  job_title: Yup?.string()?.matches(
    rexSpecialChar,
    "please_avoid_using_special_characters",
  ),

  new_password: Yup?.string()
    ?.min(8, "new_password_is_too_weak")
    ?.matches(
      /(?=(.*[A-Z]))(?=(.*[a-z]))(?=(.*[0-9]))/,
      "new_password_is_too_weak",
    ),

  confirm_password: Yup?.string()?.oneOf(
    [Yup.ref("new_password")],
    "passwords_do_not_match",
  ),
});
