import { Box, Typography } from "@mui/material";
import PageHeader from "components/pageHeader";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const EditReportHeader = () => {
  const intl = useIntl();

  return (
    <Box className="edit-report-header-container">
      <PageHeader title="edit_report" />
      <Box className="edit-report-header-text-wrapper">
        <Typography className="important-text">
          {CheckValueLocale("edit_report_text1", "", {}, intl)}:
        </Typography>
        <Typography>
          {CheckValueLocale("edit_report_text2", "", {}, intl)}
        </Typography>
      </Box>
    </Box>
  );
};

export default EditReportHeader;
