import { useState } from "react";
import { Menu, MenuItem, IconButton, Box } from "@mui/material";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { faTrash, faPenToSquare } from "@fortawesome/pro-regular-svg-icons";
import { faArrowRightArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "./replayCard.scss";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";

const OptionsDropDown = ({
  repsCount,
  isUncategorized,
  item,
  setNewRepPopup,
  setDelPopup,
}) => {
  const intl = useIntl();
  const [stats, setStats] = useState({
    anchorEl: null,
    openDeleteModal: false,
    title: "",
    body: "",
    modalRightBtn: "",
    modalLeftBtn: "",
    warning: false,
  });

  const open = Boolean(stats.anchorEl);

  const handleClick = (event) => {
    event?.stopPropagation?.();
    setStats({
      ...stats,
      anchorEl: event.currentTarget,
    });
  };
  const handleClose = (event) => {
    event?.stopPropagation?.();

    setStats({ ...stats, anchorEl: null });
  };
  return (
    <Box className="collection-menu-list">
      <IconButton
        aria-label="more"
        id="collection-menu-button"
        aria-controls="long-menu"
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        onClose={handleClose}
        className={
          "menu-items menu-item-container " + (open ? "menu-item-active" : "")
        }
      >
        <FontAwesomeIcon className="ellipsis-icon" icon={faEllipsisH} />
      </IconButton>

      <Menu
        id="collection-menu-dropdown"
        MenuListProps={{
          "aria-labelledby": "collection-menu-list-container",
        }}
        anchorEl={stats.anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          horizontal: intl?.locale == "en" ? -100 : -10,
          vertical: "bottom",
        }}
      >
        {handlelUserRoles("COLLECTION_MANAGEMENT", "EDIT_COLLECTION") ||
        handlelUserRoles("COLLECTION_MANAGEMENT", "FULL_ACCESS") ? (
          <MenuItem
            onClick={(event) => {
              event?.stopPropagation?.();
              setNewRepPopup({
                open: true,
                collection_id: null,
                fromCollection: item?.id,
                selectedRepsCount: item?.total_saved_replies,
                isBulk: true,
                id: null,
                isDuplicate: false,
                title: "",
                contant: "",
                ai: false,
                loading: false,
                done: false,
              });
            }}
            disableRipple
            disabled={!repsCount}
          >
            <FontAwesomeIcon icon={faArrowRightArrowLeft} />

            {CheckValueLocale("move_all_replies", "", {}, intl)}
          </MenuItem>
        ) : null}
        {handlelUserRoles("SAVED_REPLIES", "DELETE_SAVED_REPLY") ||
        handlelUserRoles("SAVED_REPLIES", "FULL_ACCESS") ? (
          <MenuItem
            onClick={(event) => {
              event?.stopPropagation?.();
              setDelPopup({
                open: true,
                isBulk: true,
                fromCollection: item?.id,
                count: item?.total_saved_replies,
              });
            }}
            disableRipple
            disabled={!repsCount}
          >
            <FontAwesomeIcon icon={faTrash} />
            {CheckValueLocale("del_all_replies", "", {}, intl)}
          </MenuItem>
        ) : null}
        {!isUncategorized ? (
          <>
            {handlelUserRoles("COLLECTION_MANAGEMENT", "EDIT_COLLECTION") ||
            handlelUserRoles("COLLECTION_MANAGEMENT", "FULL_ACCESS") ? (
              <MenuItem
                onClick={(event) => {
                  event?.stopPropagation?.();
                  setNewRepPopup({
                    open: true,
                    id: null,
                    isDuplicate: false,
                    editId: item?.id,
                    title: item?.name,
                    collectonName: item?.name,
                    contant: "",
                    ai: false,
                    loading: false,
                    isCollection: true,
                    done: false,
                  });
                }}
                disableRipple
              >
                <FontAwesomeIcon icon={faPenToSquare} />

                {CheckValueLocale("edit_collection", "", {}, intl)}
              </MenuItem>
            ) : null}
            {handlelUserRoles("COLLECTION_MANAGEMENT", "DELETE_COLLECTION") ||
            handlelUserRoles("COLLECTION_MANAGEMENT", "FULL_ACCESS") ? (
              <MenuItem
                className="del-collection-item"
                onClick={(event) => {
                  event?.stopPropagation?.();
                  setDelPopup({
                    open: true,
                    delId: item?.id,
                  });
                }}
                disableRipple
              >
                <FontAwesomeIcon icon={faTrash} />

                {CheckValueLocale("del_collection", "", {}, intl)}
              </MenuItem>
            ) : null}
          </>
        ) : null}
      </Menu>
    </Box>
  );
};
export default OptionsDropDown;
