import { useState, useEffect, useRef, useCallback, memo } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./customDateRangePicker.scss";
import { useIntl } from "react-intl";
import moment from "moment";

import CustomCalendarContainer from "./components/CustomCalendarContainer";
import { formatFullRange, isAM } from "./utils/dateUtils";
import { CheckValueLocale, syncUnixToUTC } from "utils/helpers";
import CustomDatePickerInput from "./components/customInput/customDatePickerInput";
import DatePickerCustomHeader from "./components/datePickerCustomHeader";
import { Box, Typography } from "@mui/material";
import {
  sidebarLastHourOptions,
  sidebarOptionsMapper,
} from "./config/sidebarOptions";

/**
 * "Date Till Now" Behavior:
 *  - When chosen => tempEnd = today, tempStart = null, but visually highlight "today."
 *  - If the user never picks a start => on Apply => of today.
 *  - If the user does pick a start => keep that, end remains pinned to now, no switch to custom.
 */

const dayMap = {
  Sunday: "week_sunday",
  Monday: "week_monday",
  Tuesday: "week_tuesday",
  Wednesday: "week_wednesday",
  Thursday: "week_thursday",
  Friday: "week_friday",
  Saturday: "week_saturday",
};

const CustomDateRangePicker = ({
  initialSidebarLabel,
  customStyles,
  maxDate,
  popperPositioning = "bottom-start",
  sidebarOptions,
  applyFilter,
  startDate,
  endDate,
  dateRangePickerParamsRef,
  hasTimePicker = false,
  minDate,
  allTimeStartDate,
  resetCalendar,
  enableResetting,
  hasSidebarOnly,
  startTime,
  endTime,
}) => {
  const intl = useIntl();
  // (A) COMMITTED (final) states
  const [committedStart, setCommittedStart] = useState(null);
  const [committedEnd, setCommittedEnd] = useState(null);
  const [committedStartTime, setCommittedStartTime] = useState(null);
  const [committedEndTime, setCommittedEndTime] = useState(null);
  const [committedSidebarLabel, setCommittedSidebarLabel] =
    useState(initialSidebarLabel);

  // Display text in the input
  const [displayValue, setDisplayValue] = useState("");
  // (B) EPHEMERAL states
  const [tempStart, setTempStart] = useState(null);
  const [tempEnd, setTempEnd] = useState(null);
  const [tempStartTime, setTempStartTime] = useState("");
  const [tempEndTime, setTempEndTime] = useState("");
  const [tempSidebarLabel, setTempSidebarLabel] = useState(initialSidebarLabel);
  const [hoveredDate, setHoveredDate] = useState(null);
  // Month/year pickers
  const [isMonthPickerOpen, setIsMonthPickerOpen] = useState(false);
  const [isYearPickerOpen, setIsYearPickerOpen] = useState(false);
  const [yearRange, setYearRange] = useState([2020, 2031]);
  // Popper
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef(null);

  // ephemeral "selectedMoment" for day-grid navigation
  const [tempSelectedMoment, setTempSelectedMoment] = useState(moment());

  /** 1) On mount => set ephemeral & committed from initialSidebarLabel */
  useEffect(() => {
    if (startDate && endDate) {
      const momentStart = moment.unix(startDate);
      const momentEnd = moment.unix(endDate);
      setCommittedStart(momentStart);
      setCommittedEnd(momentEnd);
      setTempStart(momentStart);
      setTempEnd(momentEnd);
      setTempSelectedMoment(momentEnd);
      setCommittedSidebarLabel(initialSidebarLabel);
      setTempSidebarLabel(initialSidebarLabel);
      let defaultStartTime = startTime;
      let defaultEndTime = endTime;
      if (!startTime && !endTime) {
        defaultStartTime = null;
        defaultEndTime = null;
      } else if (!startTime && endTime) {
        defaultStartTime = moment("00:00:00", "HH:mm:ss");
      } else if (startTime && !endTime) {
        defaultEndTime = moment("23:59:59", "HH:mm:ss");
      }
      setTempStartTime(defaultStartTime?.clone() || null);
      setTempEndTime(defaultEndTime?.clone() || null);
      setCommittedStartTime(defaultStartTime?.clone() || null);
      setCommittedEndTime(defaultEndTime?.clone() || null);
      return;
    }

    if (!initialSidebarLabel) {
      setCommittedStart(null);
      setCommittedEnd(null);
      setTempStart(null);
      setTempEnd(null);
      setCommittedSidebarLabel(null);
      setTempSidebarLabel(null);
      return;
    }

    const found = sidebarOptions?.find(
      (option) => option?.label === initialSidebarLabel,
    );

    if (found && found?.daysAgoStart !== undefined) {
      // Example: last 7 days, etc.
      const now = moment();
      const startDate = now.clone().subtract(found.daysAgoStart, "days");
      if (found.daysAgoEnd) {
        now.subtract(found.daysAgoEnd, "days");
      }
      setCommittedStart(startDate);
      setCommittedEnd(now);
      setTempStart(startDate);
      setTempEnd(now);
      setTempSelectedMoment(now.clone());
    }

    setCommittedSidebarLabel(initialSidebarLabel);
    setTempSidebarLabel(initialSidebarLabel);
  }, []);

  useEffect(() => {
    if (enableResetting && resetCalendar !== 1) {
      // Reset calendar
      const labelReset = initialSidebarLabel;
      if (
        [
          sidebarOptionsMapper.customdates,
          sidebarOptionsMapper.datetillnow,
        ].includes(labelReset) &&
        startDate &&
        endDate
      ) {
        const momentStart = moment.unix(startDate);
        const momentEnd = moment.unix(endDate);
        setCommittedStart(momentStart);
        setCommittedEnd(momentEnd);
        setTempStart(momentStart);
        setTempEnd(momentEnd);
        setTempSelectedMoment(momentEnd);
        setCommittedSidebarLabel(initialSidebarLabel);
        setTempSidebarLabel(initialSidebarLabel);
        let defaultStartTime = startTime;
        let defaultEndTime = endTime;
        if (!startTime && !endTime) {
          defaultStartTime = null;
          defaultEndTime = null;
        } else if (!startTime && endTime) {
          defaultStartTime = moment("00:00:00", "HH:mm:ss");
        } else if (startTime && !endTime) {
          defaultEndTime = moment("23:59:59", "HH:mm:ss");
        }
        setTempStartTime(defaultStartTime?.clone() || null);
        setTempEndTime(defaultEndTime?.clone() || null);
        setCommittedStartTime(defaultStartTime?.clone() || null);
        setCommittedEndTime(defaultEndTime?.clone() || null);
        return;
      }
      const found = sidebarOptions?.find(
        (option) => option.label === labelReset,
      );
      if (found && found.daysAgoStart !== undefined) {
        const now = moment();
        const startDateObj = now.clone().subtract(found.daysAgoStart, "days");
        if (found.daysAgoEnd) {
          now.subtract(found.daysAgoEnd, "days");
        }
        // ephemeral
        setTempStart(startDateObj);
        setTempEnd(now);
        setTempSidebarLabel(labelReset);
        setTempSelectedMoment(now.clone());
        // committed
        setCommittedStart(startDateObj);
        setCommittedEnd(now);
        setCommittedSidebarLabel(labelReset);
        setCommittedEndTime(null);
        setCommittedStartTime(null);
        setTempEndTime(null);
        setTempStartTime(null);
      }
    }
  }, [resetCalendar, sidebarOptions]);

  /** 2) Recompute input text whenever final states or intl changes */
  useEffect(() => {
    updateDisplayValue(
      committedSidebarLabel,
      committedStart,
      committedEnd,
      committedStartTime,
      committedEndTime,
    );
  }, [
    committedSidebarLabel,
    committedStart,
    committedEnd,
    committedStartTime,
    committedEndTime,
    intl,
  ]);

  /** Builds the final input text */
  function updateDisplayValue(label, startM, endM, sTime, eTime) {
    const shortLabels = [
      "picker_siderbar_last_hour_option",
      "picker_siderbar_all_time_option",
      "picker_siderbar_today_option",
      "picker_siderbar_yesterday_option",
      "picker_siderbar_last_seven_days_option",
      "picker_siderbar_last_fifteen_days_option",
      "picker_siderbar_last_thirty_days_option",
    ];

    // If no end time is provided, default to 23:59 (or 23:59:59 if desired)
    let finalEndTime = eTime;
    if (sTime && !eTime) {
      finalEndTime = "23:59"; // or "23:59:59"
    }
    let finalStartTime = sTime;
    if (!sTime && eTime) {
      finalStartTime = "00:00"; // or "23:59:59"
    }

    if (shortLabels.includes(label)) {
      // 1) Display the base label
      let displayText = CheckValueLocale(label, "", {}, intl);

      // 2) If start time and end time exist, show the time range
      if (finalStartTime && finalEndTime) {
        const adjustedStartDateTime = moment(finalStartTime, "HH:mm");
        const adjustedEndDateTime = moment(finalEndTime, "HH:mm");

        // a) Numeric portion: h:mm
        const startTimeNumeric = adjustedStartDateTime.format("h:mm");
        const endTimeNumeric = adjustedEndDateTime.format("h:mm");

        // b) Extract AM / PM (or localized equivalents)
        const startMeridiemKey = isAM(adjustedStartDateTime.format("A"))
          ? "hour_AM"
          : "hour_PM";
        const endMeridiemKey = isAM(adjustedEndDateTime.format("A"))
          ? "hour_AM"
          : "hour_PM";

        // c) Localize AM/PM
        const localizedStartMeridiem = CheckValueLocale(
          startMeridiemKey,
          "",
          {},
          intl,
        );
        const localizedEndMeridiem = CheckValueLocale(
          endMeridiemKey,
          "",
          {},
          intl,
        );

        // Final display of times
        const startTimeFormatted = `${startTimeNumeric} ${localizedStartMeridiem}`;
        const endTimeFormatted = `${endTimeNumeric} ${localizedEndMeridiem}`;

        // Combine label with the time range
        displayText += ` . ${startTimeFormatted} - ${endTimeFormatted}`;
      }

      setDisplayValue(displayText);
    } else if (hasSidebarOnly) {
      setDisplayValue(CheckValueLocale(label, "", {}, intl));
    } else {
      // For custom ranges or "Date Till Now,"
      // show the full range including date & times using an external helper.
      if (!startM || !endM) {
        setDisplayValue("");
        return;
      }
      // Pass finalEndTime instead of eTime
      const rangeStr = formatFullRange(startM, endM, sTime, finalEndTime, intl);
      setDisplayValue(rangeStr);
    }
  }

  /** 3) Clicking the input => ephemeral <- committed + open popper */
  function handleInputClick() {
    setTempStart(committedStart);
    setTempEnd(committedEnd);
    setTempStartTime(committedStartTime);
    setTempEndTime(committedEndTime);
    setTempSidebarLabel(committedSidebarLabel);

    if (committedEnd?.isValid()) {
      setTempSelectedMoment(committedEnd.clone());
    } else {
      setTempSelectedMoment(moment());
    }
    setIsOpen(true);
  }

  /** 4) onClose => revert ephemeral => committed, also close month/year pickers. */
  function handleCalendarClose() {
    setIsMonthPickerOpen(false);
    setIsYearPickerOpen(false);
    setYearRange([2020, 2031]);
    setIsOpen(false);
  }

  function handleClickOutside(event) {
    if (!containerRef.current) {
      // No container, fall back to default
      handleCalendarClose();
      return;
    }
    // If user clicked inside the container, do NOT close
    if (containerRef.current.contains(event.target)) {
      return;
    }
    // Otherwise, close
    handleCalendarClose();
  }

  /**
   * 5) onChange:
   *   - If "Date Till Now" => always keep tempEnd= now, let user pick only start date (no switching to custom)
   *   - Otherwise normal 2-click range logic with backward detection
   */
  function handleDateChange([clickedDate, end]) {
    // "Date Till Today" scenario
    if (tempSidebarLabel === "picker_siderbar_date_till_today_option") {
      // If user picks a date => that's start, end= now
      if (clickedDate) {
        setTempStart(moment(clickedDate));
        setTempEnd(moment()); // pinned to now
        setTempSelectedMoment(moment(clickedDate));
      }
      // DO NOT switch to custom
      return;
    }

    // Normal range logic
    // (A) If we already have a full range, a new click resets
    if (tempStart && tempEnd && clickedDate) {
      setTempStart(moment(clickedDate));
      setTempEnd(null);
      // Switch to custom
      setTempSidebarLabel("picker_siderbar_custom_date_range_option");
      setTempSelectedMoment(moment(clickedDate));
      return;
    }

    // (B) standard first click / second click
    if (clickedDate && !end) {
      if (tempStart && moment(clickedDate).isBefore(tempStart)) {
        // backward range
        setTempEnd(moment(tempStart));
        setTempStart(moment(clickedDate));
      } else {
        setTempStart(moment(clickedDate));
        setTempEnd(null);
      }
      setTempSidebarLabel("picker_siderbar_custom_date_range_option");
      setTempSelectedMoment(moment(clickedDate));
    } else if (clickedDate && end) {
      // second date chosen after first
      setTempStart(moment(clickedDate));
      setTempEnd(moment(end));
      setTempSidebarLabel("picker_siderbar_custom_date_range_option");
      setTempSelectedMoment(moment(end));
    } else {
      // cleared
      setTempStart(null);
      setTempEnd(null);
      setTempSidebarLabel("picker_siderbar_custom_date_range_option");
      setTempSelectedMoment(moment());
    }
  }

  /**
   * 6) user picks a sidebar => ephemeral is overwritten:
   *   - "Date Till Now": end= now, start= null
   *   - "Custom": keep ephemeral (we do NOT clear automatically)
   *   - Others => typical daysAgo logic
   *   - Also close month/year pickers
   */
  const handleSidebarClick = useCallback(
    (label) => {
      setYearRange([2020, 2031]);
      if (hasSidebarOnly) {
        const dateLabelValue = sidebarOptions?.find(
          (option) => option?.label === label,
        )?.value;
        applyFilter(startDate, endDate, dateLabelValue);
        setTempSidebarLabel(label);
        setCommittedSidebarLabel(label);
        setIsOpen(false);
      } else {
        setIsMonthPickerOpen(false);
        setIsYearPickerOpen(false);

        setTempSidebarLabel(label);

        if (label === "picker_siderbar_custom_date_range_option") {
          // do NOT clear ephemeral => user can adjust the existing range
          if (
            tempSidebarLabel === "picker_siderbar_date_till_today_option" &&
            !tempStart
          ) {
            setTempStart(tempEnd.clone().startOf("day"));
          }
          return;
        }
        if (label === "picker_siderbar_date_till_today_option") {
          // end= now, start= null
          const now = moment();
          setTempStart(null);
          setTempEnd(now);
          setTempSelectedMoment(now);
          return;
        }

        if (label === "picker_siderbar_all_time_option") {
          const startDate = moment.unix(parseInt(allTimeStartDate));
          const endDate = moment();
          setTempStart(startDate);
          setTempEnd(endDate);
          setTempSelectedMoment(endDate.clone());
          return;
        }

        if (label === "picker_siderbar_last_hour_option") {
          const now = moment();
          const oneHourAgo = now.clone().subtract(1, "hour");
          setTempStart(oneHourAgo);
          setTempEnd(now);
          setTempSelectedMoment(now.clone());
          return;
        }

        // E.g. last 7 days
        const found = sidebarOptions?.find((option) => option.label === label);
        if (found && found.daysAgoStart !== undefined) {
          const now = moment();
          const startDayesAgo = now
            .clone()
            .subtract(found.daysAgoStart, "days");
          if (found.daysAgoEnd) {
            now.subtract(found.daysAgoEnd, "days");
          }
          setTempStart(startDayesAgo);
          setTempEnd(now);
          setTempSelectedMoment(now.clone());
        }
      }
    },
    [sidebarOptions, tempStart, tempEnd, tempSidebarLabel],
  );

  /**
   * 7) Apply => ephemeral -> committed.
   *    - If "Date Till Now" and user never picked start => startOf(today)
   *    - If other scenario => if user only has start => endOf day, etc.
   */
  const handleApplyClick = useCallback(() => {
    // 1) Convert the selected "tempStart/tempEnd" into finalStart/finalEnd
    let finalStart = tempStart ? tempStart.clone() : null;
    let finalEnd = tempEnd ? tempEnd.clone() : null;
    if (!finalStart && !finalEnd) {
      setIsMonthPickerOpen(false);
      setIsYearPickerOpen(false);
      setIsOpen(false);
      return;
    } else {
      // 2) Handle date-only corner cases (e.g. "Date Till Now", missing start, etc.)
      if (tempSidebarLabel === "picker_siderbar_date_till_today_option") {
        if (!finalStart && finalEnd) {
          finalStart = finalEnd.clone();
        }
      } else {
        if (finalStart && !finalEnd) {
          finalEnd = finalStart.clone();
        }
        if (!finalStart && finalEnd) {
          finalStart = finalEnd.clone();
        }
      }

      // 3) Time picker logic
      let hasStartTime = false;
      let hasEndTime = false;

      if (hasTimePicker) {
        // Make local copies of ephemeral times, so we can override them if needed
        let localStartTime = tempStartTime;
        let localEndTime = tempEndTime;

        // If user did pick a start time, set finalStart's hours/min/sec accordingly
        if (localStartTime) {
          const [hours, minutes, seconds] = localStartTime
            .format("HH:mm:ss")
            .split(":");
          finalStart.set({ hours, minutes, seconds });
          hasStartTime = true;
        } else {
          // Else fallback to day-start or day-end logic, if desired
          // const timezoneCheck = new Date().getTimezoneOffset();
          // if (timezoneCheck < 0) {
          //   finalStart = finalStart?.endOf("day");
          // } else {
          //   finalStart = finalStart?.startOf("day");
          // }
        }

        // If user now has an end time (either chosen or defaulted to 23:59:59)
        if (localEndTime) {
          // Make sure finalEnd is not null
          if (!finalEnd) {
            finalEnd = finalStart.clone();
          }
          const [hours, minutes, seconds] = localEndTime
            .format("HH:mm:ss")
            .split(":");
          finalEnd.set({ hours, minutes, seconds });
          hasEndTime = true;
        } else {
          // // Else fallback to day-start or day-end logic, if desired
          // const timezoneCheck = new Date().getTimezoneOffset();
          // if (timezoneCheck < 0) {
          //   finalEnd = finalEnd?.endOf("day");
          // } else {
          //   finalEnd = finalEnd?.startOf("day");
          // }
        }
      } else {
        // // (Optional) Date-only scenario => set to start/endOf day
        // const timezoneCheck = new Date().getTimezoneOffset();
        // if (timezoneCheck < 0) {
        //   finalEnd = finalEnd?.endOf("day");
        //   finalStart = finalStart?.endOf("day");
        // } else {
        //   finalEnd = finalEnd?.startOf("day");
        //   finalStart = finalStart?.startOf("day");
        // }
      }

      // 4) Commit final states to your component
      setCommittedStart(finalStart);
      setCommittedEnd(finalEnd);

      if (!tempStartTime && tempEndTime) {
        setCommittedStartTime(moment("00:00:00", "HH:mm:ss"));
      } else {
        setCommittedStartTime(tempStartTime);
      }
      if (tempStartTime && !tempEndTime) {
        setCommittedEndTime(moment("23:59:59", "HH:mm:ss"));
      } else {
        setCommittedEndTime(tempEndTime);
      }
      setCommittedSidebarLabel(tempSidebarLabel);

      // 5) Optionally convert to Unix and call applyFilter
      if (applyFilter) {
        const startUnix = finalStart ? finalStart.unix() : null;
        const endUnix = finalEnd ? finalEnd.unix() : null;
        if (dateRangePickerParamsRef) {
          let formattedSidebarLabel = tempSidebarLabel;
          if (
            [
              sidebarOptionsMapper.customdates,
              sidebarOptionsMapper.datetillnow,
            ].includes(tempSidebarLabel)
          ) {
            formattedSidebarLabel = formatFullRange(
              finalStart,
              finalEnd,
              tempStartTime,
              tempEndTime,
              intl,
            );
          }
          dateRangePickerParamsRef.current = {
            startDate: startUnix,
            endDate: endUnix,
            startTime: tempStartTime,
            endTime: tempEndTime,
            sidebarOption: tempSidebarLabel,
            formattedSidebarLabel,
            startTimeUTC: syncUnixToUTC(startUnix, "start", {
              hasTime: hasStartTime,
            }),
            endTimeUTC: syncUnixToUTC(endUnix, "end", { hasTime: hasEndTime }),
            hasStartTime,
            hasEndTime,
            sidebarLabelValue: sidebarLastHourOptions.find(
              (option) => option.label === tempSidebarLabel,
            )?.value,
          };
        }
        applyFilter(startUnix, endUnix);
      }
    }
    // 6) Close pickers
    setIsMonthPickerOpen(false);
    setIsYearPickerOpen(false);
    setIsOpen(false);
  }, [
    tempSidebarLabel,
    tempStart,
    tempEnd,
    tempStartTime,
    tempEndTime,
    applyFilter,
    dateRangePickerParamsRef,
  ]);

  /** month/year pickers */
  const openMonthPicker = useCallback(() => {
    setIsMonthPickerOpen(true);
    setIsYearPickerOpen(false);
  }, []);

  const openYearPicker = useCallback(() => {
    setIsMonthPickerOpen(false);
    setIsYearPickerOpen(true);
  }, []);

  const handleMonthSelect = useCallback(
    (monthIndex) => {
      setTempSelectedMoment((prev) =>
        prev ? prev.clone().month(monthIndex) : moment().month(monthIndex),
      );
      if (tempSidebarLabel !== "picker_siderbar_date_till_today_option") {
        setTempSidebarLabel("picker_siderbar_custom_date_range_option");
      }
      setIsMonthPickerOpen(false);
    },
    [tempSidebarLabel],
  );

  const handleYearSelect = useCallback(
    (year) => {
      setTempSelectedMoment((prev) =>
        prev ? prev.clone().year(year) : moment().year(year),
      );
      if (tempSidebarLabel !== "picker_siderbar_date_till_today_option") {
        setTempSidebarLabel("picker_siderbar_custom_date_range_option");
      }
      setIsYearPickerOpen(false);
    },
    [tempSidebarLabel],
  );

  const handleYearRangeChange = useCallback(
    (direction) => {
      const size = 12;
      const [start, end] = yearRange;
      if (direction === "previous") {
        setYearRange([start - size, end - size]);
      } else {
        setYearRange([start + size, end + size]);
      }
    },
    [yearRange],
  );

  // BIG DIFFERENCE: We pass a computed startDate if we are in "Date Till Now"
  // and the ephemeral start is null. This ensures React DatePicker sees
  // a single-day selection for 'endDate' == today.
  const computedStartDate =
    tempSidebarLabel === "picker_siderbar_date_till_today_option"
      ? // If no real start is chosen, we fallback to the end date
        tempStart
        ? tempStart
        : tempEnd // typically moment() for "today"
      : tempStart; // normal logic

  // Memoize functions using useCallback
  const calendarContainerRenderer = useCallback(
    (calendarProps = {}) => {
      return (
        <Box ref={containerRef}>
          <CustomCalendarContainer
            {...calendarProps}
            selectedMoment={tempSelectedMoment}
            isMonthPickerOpen={isMonthPickerOpen}
            isYearPickerOpen={isYearPickerOpen}
            openMonthPicker={openMonthPicker}
            openYearPicker={openYearPicker}
            handleMonthSelect={handleMonthSelect}
            handleYearSelect={handleYearSelect}
            yearRange={yearRange}
            handleYearRangeChange={handleYearRangeChange}
            sidebarOptions={sidebarOptions}
            tempSidebarLabel={tempSidebarLabel}
            onSidebarClick={handleSidebarClick}
            tempStartTime={tempStartTime}
            tempEndTime={tempEndTime}
            setTempStartTime={setTempStartTime}
            setTempEndTime={setTempEndTime}
            handleApplyClick={handleApplyClick}
            intl={intl}
            maxDate={maxDate}
            minDate={minDate}
            hasTimePicker={hasTimePicker}
            hasSidebarOnly={hasSidebarOnly}
          />
        </Box>
      );
    },
    [
      tempSelectedMoment,
      isMonthPickerOpen,
      isYearPickerOpen,
      openMonthPicker,
      openYearPicker,
      handleMonthSelect,
      handleYearSelect,
      yearRange,
      handleYearRangeChange,
      sidebarOptions,
      tempSidebarLabel,
      handleSidebarClick,
      tempStartTime,
      tempEndTime,
      setTempStartTime,
      setTempEndTime,
      handleApplyClick,
      intl,
      maxDate,
      hasTimePicker,
    ],
  );

  return (
    <Box
      className={`date-range-picker-container ${hasSidebarOnly ? "has-only-sidebar" : ""}`}
    >
      <DatePicker
        customInput={<CustomDatePickerInput customStyles={customStyles} />}
        value={displayValue}
        disabledKeyboardNavigation
        open={isOpen}
        onInputClick={handleInputClick}
        onClickOutside={handleClickOutside}
        onCalendarClose={handleCalendarClose}
        shouldCloseOnSelect={false}
        maxDate={maxDate}
        minDate={minDate}
        selectsRange
        onMonthChange={(newDate) => {
          // Update your ephemeral state
          setTempSelectedMoment(moment(newDate));
        }}
        onYearChange={(newDate) => {
          // Update ephemeral state
          setTempSelectedMoment(moment(newDate));
        }}
        formatWeekDay={(dayName) => {
          // Return the mapped abbreviation (or the original if not found)
          return CheckValueLocale(dayMap[dayName], "", {}, intl) || dayName;
        }}
        // >>> If "Date Till Now" and tempStart is null, we pass the same day for both start/end
        // so that "today" is visually highlighted.
        startDate={computedStartDate ? computedStartDate.toDate() : null}
        endDate={tempEnd ? tempEnd.toDate() : null}
        onChange={handleDateChange}
        openToDate={
          tempSelectedMoment?.isValid()
            ? tempSelectedMoment.toDate()
            : new Date()
        }
        popperPlacement={popperPositioning}
        popperModifiers={{
          preventOverflow: { enabled: false },
          hide: { enabled: false },
        }}
        calendarContainer={calendarContainerRenderer}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          decreaseYear,
          increaseYear,
        }) => (
          <DatePickerCustomHeader
            date={date}
            decreaseMonth={decreaseMonth}
            increaseMonth={increaseMonth}
            decreaseYear={decreaseYear}
            increaseYear={increaseYear}
            intl={intl}
            moment={moment}
            setIsMonthPickerOpen={setIsMonthPickerOpen}
            setIsYearPickerOpen={setIsYearPickerOpen}
          />
        )}
        /* Hover-based highlight if user partially picks a range (not in dateTillNow mode) */
        renderDayContents={(dayOfMonth, date) => {
          return (
            <Typography
              variant="span"
              className="day-of-month"
              onMouseEnter={() => {
                if (
                  tempSidebarLabel === "picker_siderbar_date_till_today_option"
                ) {
                  // "Date Till Now" => ignore hover highlight
                  return;
                }
                const current = moment(date);
                if (!hoveredDate || !current.isSame(hoveredDate, "day")) {
                  setHoveredDate(current);
                }
              }}
              onMouseLeave={() => {
                if (
                  tempSidebarLabel === "picker_siderbar_date_till_today_option"
                )
                  return;
                setHoveredDate(null);
              }}
            >
              {dayOfMonth}
            </Typography>
          );
        }}
        dayClassName={(dateObj) => {
          // Only highlight hover if not dateTillNow, no final end date
          if (
            tempSidebarLabel === "picker_siderbar_date_till_today_option" ||
            !tempStart ||
            !hoveredDate ||
            tempEnd
          ) {
            return undefined;
          }
          const mDate = moment(dateObj);
          const rangeStart = moment.min(tempStart, hoveredDate);
          const rangeEnd = moment.max(tempStart, hoveredDate);
          if (
            mDate.isBetween(rangeStart, rangeEnd, "day", "[]") &&
            mDate.isBefore(maxDate)
          ) {
            return "range-hover";
          }
          return undefined;
        }}
      />
    </Box>
  );
};

export default memo(CustomDateRangePicker);
