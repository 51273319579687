import User from "../api/users";
import { SetUserData } from "../../shared/set-user-data/set-user-data.js";

var UserAccount = {};

// Get User Info
UserAccount.getUserInfo = () => {
  return new Promise((resolve, reject) => {
    User.getUserInfo()
      .then((response) => {
        resolve({
          user: response.data,
          errorMsg: response.msg,
        });
      })
      .catch((err) => {
        resolve({
          user: {},
          errorMsg: err,
          status: err.status,
        });
      });
  });
};
// Get User Info
UserAccount.getUserInfoV4 = () => {
  return new Promise((resolve, reject) => {
    User.getUserInfoV4()
      .then((response) => {
        resolve({
          user: response?.data,
          errorMsg: response?.msg,
        });
      })
      .catch((err) => {
        resolve({
          user: {},
          errorMsg: err,
          status: err?.status,
        });
      });
  });
};

// Get User Info
UserAccount.getUserInfoProfile = () => {
  return new Promise((resolve, reject) => {
    User.getUserInfoV4() // will change to new version
      .then((response) => {
        resolve({
          data: response?.data,
          errorMsg: response?.msg,
        });
      })
      .catch((err) => {
        reject({
          errorMsg: err,
          status: err?.status,
        });
      });
  });
};
// Get User Teams
UserAccount.getUserTeams = (page) => {
  const queryData = {
    page: page,
    limit_per_page: 4,
  };
  return new Promise((resolve, reject) => {
    User.getUserTeams(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
          errorMsg: response?.msg,
        });
      })
      .catch((err) => {
        resolve({
          user: {},
          errorMsg: err,
          status: err?.status,
        });
      });
  });
};

// Edit User Info
UserAccount.editUserInfo = (user_info) => {
  var queryData = user_info;
  return new Promise((resolve, reject) => {
    User.editUserInfo(queryData)
      .then((response) => {
        resolve({
          response,
        });
      })
      .catch((err) => {
        reject({
          errorMsg: err,
          status: err.status,
        });
      });
  });
};

// Get User Authorized Accounts
UserAccount.getAuthorizedAccounts = (queryData) => {
  return new Promise((resolve, reject) => {
    User.getAuthorizedAccounts(queryData)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((err) => {
        resolve({
          status: err,
        });
      });
  });
};

// Get User Authorized Accounts
UserAccount.getAuthorizedAccounts = (queryData) => {
  return new Promise((resolve, reject) => {
    User.getAuthorizedAccounts(queryData)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((err) => {
        resolve({
          status: err,
        });
      });
  });
};

// Get User Authorized Accounts
UserAccount.getNewUserData = (queryData) => {
  return new Promise((resolve, reject) => {
    User.getNewUserData(queryData)
      .then((response) => {
        SetUserData(response);
        resolve({
          data: response.data,
        });
      })
      .catch((err) => {
        resolve({
          status: err,
        });
      });
  });
};

UserAccount.getSmData = (queryData) => {
  return new Promise((resolve, reject) => {
    User.getSmData(queryData)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((err) => {
        resolve({
          status: err,
        });
      });
  });
};

UserAccount.setUserLang = (lang) => {
  var queryData = {
    lang: lang,
  };
  return new Promise((resolve, reject) => {
    User.setUserLang(queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((err) => {
        resolve({
          errorMsg: err,
        });
      });
  });
};

//user hutspot

UserAccount.createUserHutspot = () => {
  const queryData = {
    token: window.localStorage.user_token,
  };
  return new Promise(function (resolve) {
    User.createUserHutspot(queryData)
      .then((response) => {
        resolve({
          response: response,
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// user 2FA
UserAccount.getUser2FA = () => {
  return new Promise(function (resolve, reject) {
    User.getUser2FA()
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        reject({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// enable user 2FA
UserAccount.enableUser2FA = (queryData) => {
  return new Promise(function (resolve, reject) {
    User.enableUser2FA(queryData)
      .then((response) => {
        if (response?.status === 401) {
          reject({
            errorMsg: response?.response?.data?.message,
          });
        } else
          resolve({
            data: response?.data,
          });
      })
      .catch((error) => {
        reject({
          data: error?.response?.data?.data || [],
          errorMsg: error?.response?.data?.message,
        });
      });
  });
};
// disabled user 2FA
UserAccount.disableUser2FAFirst = (queryData) => {
  return new Promise(function (resolve, reject) {
    User.disableUser2FAFirst(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        reject({
          data: error?.response?.data?.data || [],
          errorMsg: error?.response?.data?.message,
        });
      });
  });
};

//verify code for disable 2FA
UserAccount.disableUser2FAVerify = (queryData) => {
  return new Promise(function (resolve, reject) {
    User.disableUser2FAVerify(queryData)
      .then((response) => {
        if (response?.status === 401) {
          reject({
            errorMsg: response?.response?.data?.message,
          });
        } else
          resolve({
            data: response?.data,
          });
      })
      .catch((error) => {
        reject({
          data: error?.response?.data?.data || [],
          errorMsg: error?.response?.data?.message,
        });
      });
  });
};

//resend code for 2FA
UserAccount.resendCode2FA = () => {
  return new Promise(function (resolve, reject) {
    User.resendCode2FA()
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        reject({
          data: [],
          errorMsg: error?.response?.data?.message,
        });
      });
  });
};

export default UserAccount;
