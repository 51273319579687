// Products Icons
import slIcon from "images/products-addons/products/inactive/social-listening.svg";
import slIconActive from "images/products-addons/products/active/social-listening.svg";
import cxmIcon from "images/products-addons/products/inactive/omnichannel.svg";
import cxmIconActive from "images/products-addons/products/active/omnichannel.svg";
import engagementIcon from "images/products-addons/products/inactive/engagement.svg";
import engagementIconActive from "images/products-addons/products/active/engagement.svg";
import surveyIcon from "images/products-addons/products/inactive/survey.svg";
import surveyIconActive from "images/products-addons/products/active/survey.svg";
import cdpIcon from "images/products-addons/products/inactive/cdp.svg";
import cdpIconActive from "images/products-addons/products/active/cdp.svg";
import aiApiIcon from "images/products-addons/products/inactive/ai-api.svg";
import aiApiIconActive from "images/products-addons/products/active/ai-api.svg";

// Addons Icons
import publicApiIcon from "images/products-addons/addons/inactive/lucidya-api.svg";
import publicApiIconActive from "images/products-addons/addons/active/lucidya-api.svg";
import aiAgentIcon from "images/products-addons/addons/inactive/ai-agent.svg";
import aiAgentIconActive from "images/products-addons/addons/active/ai-agent.svg";
import tvRadioMonitoringIcon from "images/products-addons/addons/inactive/tv-radio-monitoring.svg";
import tvRadioMonitoringIconActive from "images/products-addons/addons/active/tv-radio-monitoring.svg";
import socialMediaManagementIcon from "images/products-addons/addons/inactive/social-media-management.svg";
import socialMediaManagementIconActive from "images/products-addons/addons/active/social-media-management.svg";
import socialMediaMigrationIcon from "images/products-addons/addons/inactive/social-media-migration.svg";
import socialMediaMigrationIconActive from "images/products-addons/addons/active/social-media-migration.svg";
import brandedLiveDashboardIcon from "images/products-addons/addons/inactive/branded-live-dashboard.svg";
import brandedLiveDashboardIconActive from "images/products-addons/addons/active/branded-live-dashboard.svg";
import professionalReportsIcon from "images/products-addons/addons/inactive/professional-reports.svg";
import professionalReportsIconActive from "images/products-addons/addons/active/professional-reports.svg";
import nbMonitoringIcon from "images/products-addons/addons/inactive/nb-monitoring.svg";
import nbMonitoringIconActive from "images/products-addons/addons/active/nb-monitoring.svg";
import Services from "services/api/Services";

const productsAddonsIcons = {
  // Products
  socialListening: { default: slIconActive, inactive: slIcon },
  cxm: { default: cxmIconActive, inactive: cxmIcon },
  engagement: { default: engagementIconActive, inactive: engagementIcon },
  survey: { default: surveyIconActive, inactive: surveyIcon },
  cdp: { default: cdpIconActive, inactive: cdpIcon },
  aiApi: { default: aiApiIconActive, inactive: aiApiIcon },

  // Addons
  nbMonitoring: { default: nbMonitoringIconActive, inactive: nbMonitoringIcon },
  brandedLiveDashboard: {
    default: brandedLiveDashboardIconActive,
    inactive: brandedLiveDashboardIcon,
  },
  professionalReports: {
    default: professionalReportsIconActive,
    inactive: professionalReportsIcon,
  },
  aiAgent: { default: aiAgentIconActive, inactive: aiAgentIcon },
  tvRadioMonitoring: {
    default: tvRadioMonitoringIconActive,
    inactive: tvRadioMonitoringIcon,
  },
  socialMediaManagement: {
    default: socialMediaManagementIconActive,
    inactive: socialMediaManagementIcon,
  },
  socialMediaMigration: {
    default: socialMediaMigrationIconActive,
    inactive: socialMediaMigrationIcon,
  },
  publicApi: { default: publicApiIconActive, inactive: publicApiIcon },
  luci: {
    default: Services?.aiProductActiveLogo,
    inactive: Services?.aiProductInactiveLogo,
  },
};

const iconMappings = {
  socialListening: ["sl", "sm", "social-listening", "sociallistening"],
  cxm: ["cxm", "ca", "omnichannel"],
  engagement: ["engagement", "engagements"],
  survey: ["survey"],
  cdp: ["cdp"],
  aiApi: ["ai-api", "aiapi"],
  nbMonitoring: ["nbmonitoring"],
  brandedLiveDashboard: ["brandedlivedashboard"],
  professionalReports: ["professionalreports"],
  aiAgent: ["ai-agent", "aiagent"],
  tvRadioMonitoring: ["tvradiomonitoring"],
  socialMediaManagement: ["socialmediamanagement"],
  socialMediaMigration: ["socialmediamigration"],
  publicApi: ["lucidyaapi", "publicapi", "public-api"],
  luci: ["luci"],
};

export const useProductOrAddonImage = (name, status) => {
  for (const [key, value] of Object.entries(iconMappings))
    if (value?.includes(name?.toLowerCase()))
      return productsAddonsIcons?.[key]?.[
        status === "inactive" ? "inactive" : "default"
      ];
};
