import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CustomDashboardController from "services/controllers/customDashboardController";

const EditorFunctions = (params) => {
  const navigate = useNavigate();
  const location = useLocation();
  // *** Content States ***
  const [contentLoading, setContentLoading] = useState(true);
  const [notFoundDashboard, setnotFoundDashboard] = useState(false);
  const [changesFlag, setChangesFlag] = useState(false);
  const [contentWidgets, setContentWidgets] = useState([]);
  const [deletedWidgets, setDeletedWidgets] = useState([]);

  // *** Sidebar States ***
  const [sidebarLoading, setSidebarLoading] = useState(true);
  const [sidebarWidgets, setSidebarWidgets] = useState([]);
  const [dashboardInfo, setDashboardInfo] = useState({});
  const [expandedAccordion, setExpandedAccordion] = useState(null);

  // Manage (Mointors or Accounts) states
  const [manageDialog, setManageDialog] = useState(false);
  const [monitorsLoading, setMonitorsLoading] = useState(true);
  const [monitorsChanges, setMonitorsChanges] = useState(false);
  const [availableMonitors, setAvailableMonitors] = useState([]);
  const [monitorsPagination, setMonitorsPagination] = useState({});
  const [selectedMonitors, setSelectedMonitors] = useState([]);

  const [snackBarData, setSnackBarData] = useState({
    open: false,
    severity: "",
    title: "",
    message: "",
  });

  // Set dashboard id, get sidebar widgets, and get content widgets
  useEffect(() => {
    if (params?.dashboardId) {
      setDashboardInfo({ ...dashboardInfo, id: +params?.dashboardId });
      getSidebarWidgets(params?.dashboardId);
      getContentWidgets(params?.dashboardId);
    }
  }, [params?.dashboardId]);

  // Sorting and reseting order with any change in contentWidgets
  useEffect(() => {
    if (contentWidgets) {
      let sortedContentWidgets =
        contentWidgets?.sort((a, b) => (a?.order ?? 0) - (b?.order ?? 0)) || [];
      sortedContentWidgets?.map((widget, index) => {
        widget.order = index + 1;
      });
      setContentWidgets(sortedContentWidgets);
    }
  }, [contentWidgets]);

  // Show successfully created snackbar after redirected to editor
  useEffect(() => {
    if (location?.state?.showCreatedSuccess) {
      setSnackBarData({
        open: true,
        severity: "success",
        title: "",
        message: "custom_dashboard_created_successfully_msg",
      });
    }
  }, [location?.state?.showCreatedSuccess]);

  // Get all widget for selected monitors in left sidebar
  const getSidebarWidgets = (id) => {
    setSidebarLoading(true);
    setExpandedAccordion(null);
    CustomDashboardController.getSidebarWidgets(id).then((data) => {
      if (!data?.errorMsg) {
        setSidebarWidgets(data?.data?.all_widgets_for_all_monitors);
        setSelectedMonitors(data?.data?.monitor_ids);
        setDashboardInfo({
          id,
          name: data?.data?.name,
          product_id: data?.data?.product_id,
          product: data?.data?.product_id == localStorage?.sm_id ? "sm" : "cxm",
          max_monitors: data?.data?.max_monitors,
          max_widgets: data?.data?.max_widgets,
          monitor_ids: data?.data?.monitor_ids,
        });
      } else {
        if (data?.status == 404 || data?.errorMsg === "RECORD_NOT_FOUND") {
          setnotFoundDashboard(true);
        }
        setSnackBarData({
          open: true,
          severity: "error",
          title: "something_went_wrong",
          message: "try_again",
        });
      }
      setMonitorsChanges(false);
      setAvailableMonitors([]);
      setMonitorsPagination({});
      setSidebarLoading(false);
    });
  };

  // Get selected widgets for dashboard in right content
  const getContentWidgets = (id) => {
    setContentLoading(true);
    CustomDashboardController.getContentWidgets(id).then((data) => {
      if (!data?.errorMsg) {
        setContentWidgets(data?.data?.data);
      } else {
        if (data?.status == 404 || data?.errorMsg === "RECORD_NOT_FOUND") {
          setnotFoundDashboard(true);
        }
        setSnackBarData({
          open: true,
          severity: "error",
          title: "something_went_wrong",
          message: "try_again",
        });
      }
      setContentLoading(false);
    });
  };

  // Handle add new widget to dashboard
  const handleAddWidget = (monitorId, widget, isDm) => {
    let maxWidgetsSnackData = {
      open: true,
      severity: "info",
      title: "custom_dashboard_manage_max_widgets_title",
      message: "custom_dashboard_manage_max_widgets_msg",
    };

    if (contentWidgets?.length < dashboardInfo?.max_widgets) {
      let newWidget = {
        order: contentWidgets?.length + 1,
        widget_id: widget?.id,
        monitor_id: monitorId,
        widget_name: widget?.name,
        width: 50,
        height: 100,
        data_source: widget?.data_source_name,
        monitor_type: { name: widget?.monitor_type },
        dashboard_page_name: widget?.dashboard_page_name,
        is_dm: isDm,
      };
      setContentWidgets([...contentWidgets, newWidget]);
      if (contentWidgets?.length == dashboardInfo?.max_widgets - 1)
        setSnackBarData(maxWidgetsSnackData);
      setChangesFlag(true);
    } else setSnackBarData(maxWidgetsSnackData);
  };

  // Handle delete widget and reset orders after deleting
  const handleDeleteWidget = (index) => {
    let widget = contentWidgets?.[index];
    if (widget?.id) setDeletedWidgets([...deletedWidgets, widget?.id]);
    let newContentWidgets = contentWidgets?.filter((item, i) => index != i);
    setContentWidgets(newContentWidgets);
    setChangesFlag(true);
  };

  // Update custom dashboard monitors and content widgets
  const updateCustomDashboard = (monitorsChange) => {
    let queryData = {
      monitors: selectedMonitors?.join(","),
      deleted_metrics: deletedWidgets,
      custom_metrics: contentWidgets,
    };

    setContentLoading(true);
    CustomDashboardController.updateCustomDashboard(
      dashboardInfo?.id,
      queryData,
    ).then((data) => {
      if (!data?.errorMsg) {
        setDeletedWidgets([]);
        setContentWidgets(data?.data);
        if (monitorsChange == true) {
          setManageDialog(false);
          setMonitorsChanges(false);
          getSidebarWidgets(dashboardInfo?.id);
          setSnackBarData({
            open: true,
            severity: "success",
            title: "",
            message: "custom_dashboard_updated_successfully_msg",
          });
        } else {
          navigate(`/dashboard/custom/view/${dashboardInfo?.id}`, {
            state: { successEdit: true },
          });
        }
        setChangesFlag(false);
      } else {
        setSnackBarData({
          open: true,
          severity: "error",
          title: "something_went_wrong",
          message: "try_again",
        });
      }
      setContentLoading(false);
    });
  };

  // Get availble monitors for manage monitors popup
  const getCustomDashboardMonitors = () => {
    setMonitorsLoading(true);
    CustomDashboardController.getCustomDashboardMonitors(
      dashboardInfo?.product_id,
      monitorsPagination?.next || 1,
    ).then((data) => {
      if (!data?.errorMsg) {
        setAvailableMonitors([...availableMonitors, ...data?.data?.data]);
        setMonitorsPagination(data?.data?.pagination);
      } else {
        setSnackBarData({
          open: true,
          severity: "error",
          title: "something_went_wrong",
          message: "try_again",
        });
      }
      setMonitorsLoading(false);
    });
  };

  // Get availble accounts for manage accounts popup
  const getCustomDashboardAccounts = () => {
    setMonitorsLoading(true);
    CustomDashboardController.getCustomDashboardAccounts(
      dashboardInfo?.product_id,
    ).then((data) => {
      if (!data?.errorMsg) {
        setAvailableMonitors(data?.data?.grouped_monitors);
      } else {
        setSnackBarData({
          open: true,
          severity: "error",
          title: "something_went_wrong",
          message: "try_again",
        });
      }
      setMonitorsLoading(false);
    });
  };

  // Handle Select or Unselect (Monitor or Account)
  const handleChangeMonitors = (monitorId) => {
    let newSelected = selectedMonitors;
    if (selectedMonitors?.includes(monitorId)) {
      setMonitorsChanges(true);
      newSelected = newSelected?.filter((item) => item != monitorId);
      setSelectedMonitors(newSelected);
    } else {
      if (selectedMonitors?.length < dashboardInfo?.max_monitors) {
        setMonitorsChanges(true);
        setSelectedMonitors([...newSelected, monitorId]);
      }
    }
  };

  // Dynamic import widget image by giving the function the path of file
  const handleWidgetImage = (product, platform, type, filename, lang) => {
    const context = require?.context(
      "images/custom-dashboard", // main directory to search in
      true, // to search with sub-directories
      /\.(svg)$/, // extensions of files to search with
    );

    let imagePath =
      product === "SocialListening"
        ? `./${product}/${platform}/${type}/${filename}_${lang || "en"}.svg`
        : `./${product}/${platform}/${filename}_${lang || "en"}.svg`;

    // Printing error to catch the wrong path
    // if (!context?.keys()?.includes(imagePath)) console.log(imagePath);

    return context?.keys()?.includes(imagePath)
      ? context(imagePath)
      : filename?.includes("6-col")
        ? context("images/custom-dashboard/editor-widget-placeholder-6-col.svg")
        : context("images/custom-dashboard/editor-widget-placeholder.svg");
  };

  return {
    contentLoading,
    sidebarLoading,
    dashboardInfo,
    sidebarWidgets,
    contentWidgets,
    setContentWidgets,
    updateCustomDashboard,
    getCustomDashboardMonitors,
    getCustomDashboardAccounts,
    availableMonitors,
    setAvailableMonitors,
    selectedMonitors,
    setSelectedMonitors,
    monitorsLoading,
    monitorsPagination,
    setMonitorsPagination,
    handleAddWidget,
    handleDeleteWidget,
    monitorsChanges,
    setMonitorsChanges,
    handleChangeMonitors,
    changesFlag,
    setChangesFlag,
    snackBarData,
    setSnackBarData,
    handleWidgetImage,
    manageDialog,
    setManageDialog,
    expandedAccordion,
    setExpandedAccordion,
    notFoundDashboard,
  };
};

export default EditorFunctions;
