import axios from "axios";
import { setCookie, deleteCookie } from "utils/helpers";
import { jwtDecode as jwt_decode } from "jwt-decode";
import store from "utils/redux/store/store";
import { resetChatMessages } from "utils/redux/features/AiAgentChat/AiAgentChatSlice";
import { envConfig } from "./envConfig";

const currentEnv = process.env.REACT_APP_ENV || "staging";
const Services = envConfig[currentEnv];

const clearDataUponLogout = () => {
  localStorage.clear();
  store.dispatch(resetChatMessages());
  deleteCookie("email");
};

const handleSessionExpired = () => {
  clearDataUponLogout();
  localStorage.setItem("sessionExpired", true);
  window.location.href = "/login";
};

const handleUnauthorized = () => {
  clearDataUponLogout();
  localStorage.setItem("loginRedirection", true);
  if (document.location.pathname !== "/login") {
    window.location.href = "/login";
  }
};

export const api = axios.create({
  baseURL: Services.baseRoute,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("user_token");
    if (token) {
      config.headers["luc-authorization"] = token;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

api.interceptors.response.use(
  (response) => {
    const newToken = response.headers["luc-authorization"];
    if (newToken) {
      localStorage.setItem("user_token", newToken);
      setCookie("email", response?.data?.data?.attributes?.email);
      const decodedToken = jwt_decode(newToken);
      localStorage.setItem(
        "user_roles",
        JSON.stringify(decodedToken.permissions),
      );
      if (decodedToken?.product_feature_permissions) {
        window.localStorage.setItem(
          "advansed_roles",
          JSON.stringify(decodedToken?.product_feature_permissions),
        );
      }
      window.localStorage.setItem(
        "active_features",
        JSON.stringify(decodedToken?.active_features),
      );
    }
    if (response.status === 401) {
      clearDataUponLogout();
      localStorage.setItem("loginRedirection", true);
      if (document.location.pathname !== "/login") {
        window.location.href = "/login";
      }
    }
    return response;
  },
  (error) => {
    if (error?.response) {
      switch (error.response.status) {
        case 401:
          handleUnauthorized();
          break;
        case 500:
          handleSessionExpired();
          break;
        default:
          break;
      }
    }
    return Promise.reject(error);
  },
);
