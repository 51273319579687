import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import xPlatform from "images/social-icons/x-platform-white-icon.svg";
import { Box, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CheckValueLocale,
  extractDisplayInfoFromDataSource,
} from "utils/helpers";
import { useIntl } from "react-intl";
import { ReactComponent as WhatsappIcon } from "images/social-icons/whatsapp-icon.svg";
import { ReactComponent as NewTabIcon } from "images/engagements-images/new-tab-icon.svg";

export const getSocialIconForEngagementTabs = (type) => {
  const SocialIcons = {
    facebook: (
      <Box className={type + "-icon user-source"}>
        <FontAwesomeIcon className="facebook" icon={faFacebook} />
      </Box>
    ),
    twitter: (
      <Box className={type + "-icon user-source"}>
        <img src={xPlatform} alt="x-platform-logo" className="twitter" />
      </Box>
    ),
    instagram: (
      <Box className={type + "-icon user-source"}>
        <FontAwesomeIcon className="instagram" icon={faInstagram} />
      </Box>
    ),
    whatsapp: (
      <Box className={type + "-icon user-source"}>
        <WhatsappIcon className="whatsapp" />
      </Box>
    ),
  };
  return SocialIcons[type];
};

export const getEngagementTabInfo = (engagement) => {
  const isTwitter = engagement?.data_source?.toLowerCase?.() === "twitter";
  const isInstagram = engagement?.data_source?.toLowerCase?.() === "instagram";
  const isDM = engagement?.isDm;
  const { displayName } = extractDisplayInfoFromDataSource(engagement, {
    displayName: engagement?.user_name
      ? (isTwitter || isInstagram) && isDM && engagement?.name
        ? engagement?.name
        : engagement?.user_name
      : "user",
  });
  return {
    displayName,
    dataSource: engagement?.data_source,
    isDM,
    sidebarTab: engagement?.sidebarTab,
    currentActiveTab: engagement?.currentActiveTab,
    status: engagement?.engagementStatus,
  };
};

export const EngagementTabTooltitBody = ({ engagement }) => {
  const intl = useIntl();
  const { displayName, sidebarTab, currentActiveTab, status } =
    getEngagementTabInfo(engagement);
  return (
    <Box className="engagement-tabs-list-item-tooltip-container">
      <Box className="engagement-tabs-list-item-tooltip-title">
        {displayName}
      </Box>
      <Box className="engagement-tabs-list-item-tooltip-viewed">
        <NewTabIcon />
        <Typography component="span">
          {CheckValueLocale("viewed_from", "", {}, intl)}
        </Typography>
      </Box>
      <Box className="engagement-tabs-list-item-tooltip-info">
        {CheckValueLocale(sidebarTab, "", {}, intl)} {" > "}
        {currentActiveTab === "all"
          ? CheckValueLocale(currentActiveTab, "", {}, intl) + " > "
          : null}
        {CheckValueLocale(status, "", {}, intl)}
      </Box>
    </Box>
  );
};
