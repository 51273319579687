import * as Yup from "yup";

export const createAccountsListsValidationSchema = (isCorrectFormation) => {
  const isNotOnlySpaces = (value) => {
    const noOnlySpacesRegex = /^(?!\s*$).+/;
    return noOnlySpacesRegex.test(value);
  };

  return Yup.object().shape({
    name: Yup?.string()
      ?.trim()
      ?.required("this_field_required")
      ?.max(50, "exceeds_accounts_list_name_length")
      ?.test(
        "special-characters-name",
        "account_lists_characters_allowed",
        isCorrectFormation,
      ),
    usernames: Yup?.array()
      ?.min(1, "this_field_required")
      .max(200, "accounts_lists_limit_reached"),
    description: Yup?.string()
      ?.trim()
      ?.max(100, "exceeds_accounts_list_description_length")
      ?.test(
        "special-characters-description",
        "account_lists_characters_allowed",
        isCorrectFormation,
      )
      ?.test(
        "special-characters-description",
        "account_lists_characters_allowed",
        isNotOnlySpaces,
      ),
  });
};
