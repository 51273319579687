import { Grid, Typography, Box } from "@mui/material";
import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import LineChartWidget from "components/widgets/lineChartWidget";
import BarChartWidget from "components/widgets/barChartWidget";
import StackedBarWidget from "components/widgets/stackedBarWidget";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import faInstagram from "images/social-icons/ig-profile-icon.svg";
import QuestionCard from "pages/Monitors/Components/questionCard";
import MediaInstagram from "components/media/media-insta/mediaInsta";
import { CheckValueLocale } from "utils/helpers";

/*---------------------------------------------------------*/

const InstagramAccountBody = (props) => {
  const intl = useIntl();
  let { response } = props;
  const { monitorType, monitorDataSource, monitorId } = useParams();
  const monitorDetails = props.monitorData;

  //handle response
  const [postsVolume, setPostsVolume] = useState([]);
  const [totalPostsVolumeCount, setTotalPostsVolumeCount] = useState([]);
  const [topVideos, setTopVideos] = useState([]);
  const [topImages, setTopImages] = useState([]);
  const [topPosts, setTopPosts] = useState([]);
  const [topPostsCount, setTopPostsCount] = useState(0);

  const [postsContentType, setPostsContentType] = useState([]);

  const [followersGrowth, setFollowersGrowth] = useState([]);
  const [totalFollowersFollowersGrowth, setTotalFollowersGrowth] = useState(0);
  const [maxChangeFollowersGrowth, setMaxChangeFollowersGrowth] = useState(0);
  const [avgChangeFollowersGrowth, setAvgChangeFollowersGrowth] = useState(0);

  // Values of DataSources totrack each widget preloader
  const [postsVolumePreLoaderDataSources, setPostsVolumePreLoaderDataSources] =
    useState([]);
  const [topVideosPreLoaderDataSources, setTopVideosPreLoaderDataSources] =
    useState([]);
  const [topImagesPreLoaderDataSources, setTopImagesPreLoaderDataSources] =
    useState([]);
  const [topTopPostsPreLoaderDataSources, setTopPostsPreLoaderDataSources] =
    useState([]);

  const [topKeywords, setTopKeywords] = useState([]);
  const [topKeywordsPreLoader, setTopKeywordsPreLoader] = useState([]);
  // _____________________________________________
  const [topHashtags, setTopHashtags] = useState([]);
  const [topHashtagsPreLoader, setTopHashtagsPreLoader] = useState([]);

  const [
    postsContentTypePreLoaderDataSources,
    setPostsContentTypePreLoaderDataSources,
  ] = useState([]);

  const [postContentPieChart, setPostContentPieChart] = useState([]);
  const [
    followersGrowthPreLoaderDataSources,
    setFollowersGrowthPreLoaderDataSources,
  ] = useState([]);

  //handle response with useEffect (when load page & any update)
  const handleResponseFunc = async () => {
    switch (response.eventName) {
      case "Instagram__AccountPage__volume_overtime":
        setPostsVolume(response.eventData.posts_over_time);
        setTotalPostsVolumeCount(response.eventData.total_posts_count);
        setPostsVolumePreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Instagram__AccountPage__followers_growth":
        setFollowersGrowth(response.eventData.followers_change_over_time);
        setTotalFollowersGrowth(response.eventData.total_followers);
        setMaxChangeFollowersGrowth(response.eventData.max_change_of_followers);
        setAvgChangeFollowersGrowth(response.eventData.avg_chage_of_day);
        setFollowersGrowthPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Instagram__AccountPage__content_type":
        setPostsContentType(response.eventData.posts_content_type);
        setPostsContentTypePreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        setPostContentPieChart(response.eventData.posts_content_types_legends);
        break;
      case "Instagram__AccountPage__top_videos":
        setTopVideos(response.eventData.top_videos);
        setTopVideosPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Instagram__AccountPage__top_images":
        setTopImages(response.eventData.top_images);
        setTopImagesPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Instagram__AccountPage__top_keywords":
        setTopKeywords(response.eventData.top_keywords);
        setTopKeywordsPreLoader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Instagram__AccountPage__top_hashtags":
        setTopHashtags(response.eventData.top_hashtags);
        setTopHashtagsPreLoader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Instagram__AccountPage__top_posts_comments":
        setTopPosts(response.eventData.top_posts);
        setTopPostsCount(response.eventData.top_posts_count);
        setTopPostsPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    handleResponseFunc();
  }, [response]);
  //_____________________________________________________________________________________
  //getting Data to export xls sheet
  useEffect(() => {
    props.setExcelSheetData([
      { followersGrowth: followersGrowth },
      { postsVolume: postsVolume },
      { postsContentType: postsContentType },
      { topKeywords: topKeywords },
      { topHashtags: topHashtags },
      { topImages: topImages },
      { topVideos: topVideos },
      { topPosts: topPosts },
    ]);
  }, [
    followersGrowth,
    postsVolume,
    postsContentType,
    topKeywords,
    topHashtags,
    topImages,
    topVideos,
    topPosts,
  ]);
  //_____________________________________________________________________________________
  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (props.resetAllFields !== 1) {
      setPostsVolume([]);
      setTotalPostsVolumeCount([]);
      setTopImages([]);
      setTopVideos([]);
      setTopPosts([]);
      setPostContentPieChart([]);
      setTopPostsCount(0);
      setTopKeywords([]);
      setTopKeywordsPreLoader([]);
      setTopHashtags([]);
      setTopHashtagsPreLoader([]);

      setPostsContentType([]);
      setFollowersGrowth([]);
      setTotalFollowersGrowth(0);
      setMaxChangeFollowersGrowth(0);
      setAvgChangeFollowersGrowth(0);

      // Values of DataSources toTrack each widget preloader
      setPostsVolumePreLoaderDataSources([]);
      setTopVideosPreLoaderDataSources([]);
      setTopImagesPreLoaderDataSources([]);
      setTopPostsPreLoaderDataSources([]);

      setPostsContentTypePreLoaderDataSources([]);
      setFollowersGrowthPreLoaderDataSources([]);
    }
  }, [props.resetAllFields]);

  useEffect(() => {
    let totalPreLoaderDataSouces = [];

    totalPreLoaderDataSouces.push(
      postsVolumePreLoaderDataSources,
      followersGrowthPreLoaderDataSources,
      postsContentTypePreLoaderDataSources,
      topVideosPreLoaderDataSources,
      topImagesPreLoaderDataSources,
      topKeywordsPreLoader,
      topHashtagsPreLoader,
      topTopPostsPreLoaderDataSources,
    );

    props.checkAllDataFounded(
      totalPreLoaderDataSouces,
      props.preLoaderTrackerDataSources,
    );

    props.checkDataIsFounded(
      followersGrowth,
      postsVolume,
      postsContentType,
      topKeywords,
      topHashtags,
      topPosts,
      topImages,
      topVideos,
    );
  }, [
    followersGrowth,
    postsVolume,
    postsContentType,
    topKeywords,
    topHashtags,
    topPosts,
    topImages,
    topVideos,
    postsVolumePreLoaderDataSources,
    followersGrowthPreLoaderDataSources,
    postsContentTypePreLoaderDataSources,
    topVideosPreLoaderDataSources,
    topImagesPreLoaderDataSources,
    topKeywordsPreLoader,
    topHashtagsPreLoader,
    topTopPostsPreLoaderDataSources,
  ]);

  return (
    <>
      <Typography className="section-name">
        {CheckValueLocale("overview", "", {}, intl).toUpperCase()}
      </Typography>
      <Box mt={3}>
        {/* __________________________________ */}
        <BarChartWidget
          title={"followers_growth"}
          titleToolTip={"followers_growth_ig_tooltip"}
          data={followersGrowth}
          showGroupBy={true}
          dataColor="#2AAAE2"
          showChartDetailsWidget
          statsDetailsData={[
            {
              name: "total_followers",
              value: totalFollowersFollowersGrowth,
            },
            {
              name: "max_change_of_followers",
              value: maxChangeFollowersGrowth,
            },
            {
              name: "avg_change_per_day",
              value: avgChangeFollowersGrowth,
            },
          ]}
          toolTipLabel={"posts"}
          showFollowersGrowthStats
          showDownloadIcon
          showZoomPercentage
          bigImg
          iconAfterTitle={props.instagramIcon}
          barChartPreLoaderDataSources={followersGrowthPreLoaderDataSources}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
        {/* __________________________________ */}
      </Box>

      <Typography className="section-name-secondary">
        {CheckValueLocale("posts_analytics", "", {}, intl).toUpperCase()}
      </Typography>
      <Box mt={3} className="posts-volume-chart">
        <LineChartWidget
          title={"posts_volume"}
          titleToolTip={"posts_volume_public_ig_tooltip"}
          totalLabel={"posts"}
          totalValue={totalPostsVolumeCount}
          data={postsVolume}
          showGroupBy={true}
          toolTipLabel={"posts"}
          labelColor={"#2AAAE2"}
          showDownloadIcon
          bigImg
          iconAfterTitle={props.instagramIcon}
          lineChartPreLoaderDataSources={postsVolumePreLoaderDataSources}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Box>

      <Box mt={3}>
        <StackedBarWidget
          title={"posts_content_type"}
          titleToolTip={"posts_content_type_fb_tooltip"}
          posts={postsContentType}
          colors={["#2AAAE2", "#80868C", "#334155"]}
          labels={["video", "photo", "catalog"]}
          hideLegend
          dataPiechart={postContentPieChart}
          showDownloadIcon
          bigImg
          showGroupBy={false}
          showScales
          showZoomPercentage
          iconAfterTitle={props.instagramIcon}
          postsColumnPreLoaderDataSources={postsContentTypePreLoaderDataSources}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Box>
      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TableWidget
              wordsList
              title={"posts_top_keywords"}
              titleToolTip={"posts_top_keywords_ig_tooltip"}
              data={topKeywords}
              leftColomnTitle={"keywords"}
              rightColomnTitle={"number_of_posts"}
              tableChartPreLoaderDataSources={topKeywordsPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              keywords
              tablePagination
              count={topKeywords.length}
              iconAfterTitle={props.instagramIcon}
              showDownloadIcon
              withWordCloud
            />
          </Grid>
          <Grid item xs={6}>
            <TableWidget
              wordsList
              title={"posts_top_hashtags"}
              titleToolTip={"posts_top_hashtags_ig_tooltip"}
              data={topHashtags}
              leftColomnTitle={"hashtags"}
              rightColomnTitle={"number_of_hashtags"}
              tableChartPreLoaderDataSources={topHashtagsPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              hashtags
              tablePagination
              count={topHashtags.length}
              iconAfterTitle={props.instagramIcon}
              showDownloadIcon
              withWordCloud
            />
          </Grid>
        </Grid>
      </Box>
      <Box my={4}>
        <MediaInstagram
          title={CheckValueLocale("top_images", "", {}, intl)}
          titleToolTip={"posts_top_images_tooltip"}
          data={topImages}
          errorMessage={CheckValueLocale("no_images_found", "", {}, intl)}
          iconAfterTitle={props.instagramIcon}
          hideMoreInsights
          monitorInstagramMedia
          mediaInstaPreLoaderDataSources={topImagesPreLoaderDataSources}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Box>
      <Box my={4}>
        <MediaInstagram
          title={CheckValueLocale("top_reels", "", {}, intl)}
          titleToolTip={"posts_top_reels_tooltip"}
          data={topVideos}
          errorMessage={CheckValueLocale("no_videos_found", "", {}, intl)}
          iconAfterTitle={props.instagramIcon}
          hideMoreInsights
          monitorInstagramMedia
          mediaInstaPreLoaderDataSources={topVideosPreLoaderDataSources}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Box>

      <Box className="top-posts-head">
        <Box>{CheckValueLocale("top_posts", "", {}, intl)}</Box>
        <Link
          to={{
            pathname: `/monitor/${monitorType}/${monitorDataSource}/posts/${monitorId}`,
            state: monitorDetails,
          }}
          className="top-posts-all"
          id={`check-all-posts-${
            monitorType?.toLowerCase() ?? "monitor-type"
          }-${
            monitorDataSource?.toLowerCase() ?? "monitor-data-source"
          }-posts-${monitorId ?? "monitor-id"}`}
        >
          {CheckValueLocale("check_all_posts", "", {}, intl)} ({topPostsCount})
        </Link>
      </Box>
      <Box>
        <QuestionCard
          latestUnanswer={topPosts}
          latestUnanswerPreloader={topTopPostsPreLoaderDataSources}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          icon={faInstagram}
          iconColor={"instagram_icon"}
          showHeart={true}
          hideChartBar={true}
          widget={"top_posts"}
          showDefaultImg
          className={"no-data-found-question"}
          hideChangeSentiment
        />
      </Box>
    </>
  );
};

export default InstagramAccountBody;
