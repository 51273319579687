import Services from "./Services";

var Reports = {};

Reports.getTwitterReport = (queryData) =>
  Services.downloadTwitterReport("api_export_report_from_file", queryData);

Reports.exportReportFromFile = (queryData) =>
  Services.nodeGetData("api_export_report_from_file", queryData);

Reports.getPptLink = (queryData) =>
  Services.getData("api/v3/reports/ppt_link", queryData);

Reports.getMonitorSentimentCategories = function (monitorId) {
  return Services.getData(
    "api/v3/monitors/sentiment_categories_stats/" + monitorId,
  );
};

export default Reports;
