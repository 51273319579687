import { useState } from "react";
import { useIntl } from "react-intl";
import { Box, Grid, Typography } from "@mui/material";
import { faCheck, faLock } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import { useRequestDemoOrUpgrade } from "../../../hooks/useRequestDemoOrUpgrade";
import { useProductOrAddonImage } from "../../../../../hooks/useProductOrAddonImage";
import LucButton from "shared/lucButton/lucButton";
import Services from "services/api/Services";

const ProductAddonItem = (props) => {
  const intl = useIntl();
  const { item, setSnackbar, setExpanded, isAddonsTab } = props;

  const [requested, setRequested] = useState(false);

  const { mutate: requestDemoOrUpgrade, isPending: requestLoading } =
    useRequestDemoOrUpgrade();

  const handleRquestDemoOrUpgrade = (service_name, type) => {
    requestDemoOrUpgrade(
      { queryData: { service_name } },
      {
        onSuccess: () => {
          setRequested(true);
          setSnackbar({
            open: true,
            severity: "success",
            title:
              type === "demo"
                ? isAddonsTab
                  ? "subscriptions_addons_request_demo_succes_title"
                  : "subscriptions_request_demo_succes_title"
                : "",
            message:
              type === "demo"
                ? isAddonsTab
                  ? "subscriptions_addons_request_demo_succes_message"
                  : "subscriptions_request_demo_succes_message"
                : "subscriptions_request_upgrade_succes_message",
          });
        },
        onError: () =>
          setSnackbar({
            open: true,
            severity: "error",
            title:
              type === "demo" && !isAddonsTab
                ? "subscriptions_request_demo_failed_title"
                : "subscriptions_request_upgrade_failed_title",
            message:
              type === "demo" && !isAddonsTab
                ? "subscriptions_request_demo_failed_message"
                : isAddonsTab && !item?.active
                  ? "subscriptions_request_contact_failed_message"
                  : "subscriptions_request_upgrade_failed_message",
          }),
      },
    );
  };

  const activeItem = isAddonsTab && !item?.isProduct ? true : item?.active;

  const productNameMapping = {
    subscriptions_social_listening_title:
      "subscriptions_social_listening_title_v2",
    subscriptions_cdp_title: "subscriptions_cdp_title_v2",
    subscriptions_survey_title: "subscriptions_survey_title_v2",
    subscriptions_engagements_title: "subscriptions_engagements_title_v2",
    subscriptions_ai_agent_title: "subscriptions_ai_agent_title_v2",
  };
  return (
    <Grid xs={3} item>
      <Box
        className={`products-addons-item-box ${!activeItem ? "inactive" : ""}`}
      >
        <Box className="item-header">
          <Box className="item-icon-container">
            <img
              src={
                useProductOrAddonImage(
                  item?.productKey,
                  item?.active ? "active" : "inactive",
                ) || ""
              }
              alt={item?.productKey || ""}
            />
          </Box>
          {!isAddonsTab || (isAddonsTab && item?.isProduct) ? (
            <Box className={`item-status-icon ${activeItem ? "active" : ""}`}>
              {item?.active ? (
                <FontAwesomeIcon icon={faCheck} />
              ) : (
                <FontAwesomeIcon icon={faLock} />
              )}
            </Box>
          ) : null}
        </Box>
        <Box className="details-container">
          <Box>
            <Typography className="item-main-title">
              {CheckValueLocale(
                productNameMapping[item?.title] || item?.title,
                "",
                {},
                intl,
              )}
            </Typography>
            <Typography className="item-sub-title">
              {CheckValueLocale(item?.sub_title, "", {}, intl)}
            </Typography>
          </Box>

          {isAddonsTab ? (
            <Box className="item-btns-container">
              {item?.active ? (
                <LucButton
                  id="settings-subscriptions-usage-details-btn"
                  variant="flat"
                  onClick={async () => {
                    setExpanded(item?.productKey);
                    const element = document.getElementById(
                      `subscriptions-details-accordion-${item?.productKey}`,
                    );
                    await element?.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                    });
                  }}
                >
                  {CheckValueLocale(
                    "subscriptions_usage_details",
                    "",
                    {},
                    intl,
                  )}
                </LucButton>
              ) : (
                <LucButton
                  id="settings-subscriptions-request-demo-btn"
                  variant="flat"
                  className={`request-demo-btn ${requested ? "requested" : ""}`}
                  loading={requestLoading}
                  disabled={
                    requested || !handlelUserRoles("GENERAL", "CREATE_REQUEST")
                  }
                  onClick={() =>
                    !requested &&
                    handlelUserRoles("GENERAL", "CREATE_REQUEST") &&
                    handleRquestDemoOrUpgrade(item?.demoKey, "demo")
                  }
                >
                  {CheckValueLocale(
                    requested
                      ? "subscriptions_requested_contact"
                      : "subscriptions_request",
                    "",
                    {},
                    intl,
                  )}
                </LucButton>
              )}
            </Box>
          ) : (
            <Box className="item-btns-container">
              {item?.active ? (
                <LucButton
                  id="settings-subscriptions-usage-details-btn"
                  variant="flat"
                  onClick={async () => {
                    setExpanded(item?.usageId);
                    const element = document.getElementById(
                      `subscriptions-details-accordion-${item?.usageId}`,
                    );
                    await element?.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                    });
                  }}
                >
                  {CheckValueLocale(
                    "subscriptions_usage_details",
                    "",
                    {},
                    intl,
                  )}
                </LucButton>
              ) : (
                <LucButton
                  id="settings-subscriptions-request-demo-btn"
                  variant="flat"
                  className={`request-demo-btn ${requested ? "requested" : ""}`}
                  loading={requestLoading}
                  disabled={
                    requested || !handlelUserRoles("GENERAL", "CREATE_REQUEST")
                  }
                  onClick={() =>
                    !requested &&
                    handlelUserRoles("GENERAL", "CREATE_REQUEST") &&
                    handleRquestDemoOrUpgrade(item?.demoKey, "demo")
                  }
                >
                  {CheckValueLocale(
                    requested
                      ? "subscriptions_requested_demo"
                      : "subscriptions_request_demo",
                    "",
                    {},
                    intl,
                  )}
                </LucButton>
              )}
              {item?.active ? (
                <LucButton
                  id="settings-subscriptions-request-upgrade-btn"
                  variant="flat"
                  className={`request-upgrade-btn ${requested ? "requested" : ""}`}
                  loading={requestLoading}
                  disabled={
                    requested || !handlelUserRoles("GENERAL", "CREATE_REQUEST")
                  }
                  onClick={() =>
                    !requested &&
                    handlelUserRoles("GENERAL", "CREATE_REQUEST") &&
                    handleRquestDemoOrUpgrade(item?.upgradeKey, "upgrade")
                  }
                >
                  {CheckValueLocale(
                    requested
                      ? "subscriptions_requested_upgrade"
                      : "subscriptions_request_upgrade",
                    "",
                    {},
                    intl,
                  )}
                </LucButton>
              ) : item?.learnMoreUrl && Services.showThirdCompany ? (
                <LucButton
                  id="settings-subscriptions-learn-more-btn"
                  variant="flat"
                  onClick={() => window.open(item?.learnMoreUrl, "_blank")}
                >
                  {CheckValueLocale("subscriptions_learn_more", "", {}, intl)}
                </LucButton>
              ) : null}
            </Box>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

export default ProductAddonItem;
