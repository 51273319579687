import { Box } from "@mui/material";
import "./sideBarTabs.scss";
import NoDataSideBarTab from "./noDataSideBarTab";
import Skeleton from "@mui/material/Skeleton";
import {
  CheckValueLocale,
  getEngagementId,
  handlelUserRoles,
  isEmptyObj,
  isNullish,
} from "utils/helpers";
import {
  faSpinnerThird,
  faArrowDown,
} from "@fortawesome/pro-regular-svg-icons";
import { useIntl } from "react-intl";
import profileImg from "images/engagements-images/profileImg.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef } from "react";
import CardItem from "./cardItem";
import { useDispatch, useSelector } from "react-redux";
import {
  slaData,
  clearSla,
  cardsAssignAndStateData,
  clearCardsAssignAndState,
  clearBulk,
} from "utils/redux/features/Engagements/EngagementsSlice.js";
import CompaniesController from "services/controllers/companiesController";
import EngagementsController from "services/controllers/engagementsController";
import { maxTabsCount } from "../hooks/constant";
import { useEngagementTabs } from "pages/engagements/providers/engagementsTabsProvider";
import { AiAgentsServices } from "services/api/aiAgents";

const counterArr = [1, 2, 3, 4];
const cardLoading = () => {
  return (
    <Box className="main-cards-loader">
      <Box className="cards-loader-header">
        <Skeleton
          animation="pulse"
          variant="circular"
          width={32}
          height={32}
          sx={{ bgcolor: "white" }}
        />
        <Box className="cards-loader-title">
          <Skeleton
            animation="pulse"
            variant="text"
            width={100}
            height={10}
            sx={{ bgcolor: "white" }}
          />
          <Skeleton
            animation="pulse"
            variant="text"
            width={50}
            height={10}
            sx={{ bgcolor: "white" }}
          />
        </Box>
      </Box>
    </Box>
  );
};

const TabContent = ({
  response,
  setFilters,
  filters,
  applyFilters,
  allData,
  preLoader,
  slaCounterData,
  setSlaCounterData,
  setCountLoading,
  setLastOperation,
  setEngagementPostId,
  setTabValue,
  searchInput,
  setOpenSidebarToAssignTeam,
  aiAgentList,
  setAiAgentList,
  setIsAiAgentDown,
  setIsLimitReached,
  setIsDMLimitReached,
  setAiAgentDMReachDetails,
  tabsContentCount,
  setTabsContentCount,
  resetTabsContentCount,
  setIsSlaInitialLoad,
  filterTypeChange,
  hasNextPage,
  setHasNextPage,
  setDataSourceCount,
  itemsData,
  setItemsData,
}) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const [slasList, setSlasList] = useState([]);
  const [loadMore, setLoadMore] = useState(false);
  const listRef = useRef(null);
  const loadMoreRef = useRef(null);
  const [openUserList, setOpenUserList] = useState("");
  const { cardState, setCardState } = useEngagementTabs();
  const [hasVerticalScrollbar, setHasVerticalScrollbar] = useState(false);
  const dispatch = useDispatch();
  const reduxSlaData = useSelector((state) => state?.engagements?.sla);
  const reduxCardsAssignAndState = useSelector(
    (state) => state?.engagements?.cardsAssignAndState,
  );

  const getCardId = (item) => {
    return `${item?.tweet_id ? item?.tweet_id : item?.id}-${item?.tracker_id}`;
  };
  // handle card removal animation
  const animateCardRemoval = (cardElement) => {
    const cardHeight = cardElement?.getBoundingClientRect?.()?.height;
    const heightPercentage = (percentage) =>
      `${(cardHeight * percentage) / 100}px`;

    const keyframes = [
      { height: heightPercentage(100), opacity: 1, filter: "blur(4px)" },
      { height: heightPercentage(75), opacity: 0.75, filter: "blur(6px)" },
      { height: heightPercentage(50), opacity: 0.5, filter: "blur(8px)" },
      { height: heightPercentage(25), opacity: 0.25, filter: "blur(8px)" },
      {
        height: heightPercentage(0),
        opacity: 0,
        display: "none",
        filter: "blur(10px)",
      },
    ];

    const options = {
      duration: 500,
      easing: "ease-in-out",
      iterations: 1,
    };

    // return animation ref to handle onfinish event
    return cardElement?.animate?.(keyframes, options);
  };

  const handleCardRemoval = (cardId) => {
    const cardElement = document.getElementById(cardId);

    if (cardElement) {
      // befroe animation we add class to card to make tp prepare for animation
      cardElement?.classList?.add?.("delete-card-animation");
      const animationRef = animateCardRemoval(cardElement) || {};

      animationRef.onfinish = () => {
        setItemsData((prev) =>
          prev.filter((item) => getCardId(item) !== cardId),
        );
      };
    }
  };
  useEffect(() => {
    if (allData?.page_number == 1) {
      setLoading(true);
      setLoadMore(false);
      setItemsData([]);
      setOpenUserList("");
      // dispatch(clearSla());
      // setSelectedCard({});
      dispatch(clearBulk());
      dispatch(clearCardsAssignAndState());
      setHasNextPage(false);
      setLastOperation(0);
      setEngagementPostId(null);
      setCountLoading(true);
      setSlaCounterData({ ...slaCounterData, loading: true });
      setAiAgentList({});
      setIsAiAgentDown(false);
      setIsLimitReached(false);
      setIsDMLimitReached(false);
      setAiAgentDMReachDetails(null);
    }
  }, [allData]);

  // handle cards list data
  useEffect(() => {
    let slaSideData = { ...slaCounterData };
    setLoadMore(true);
    if (allData?.page_number == 1 && isEmptyObj(response)) {
      //reset all data at page 1
      setLoading(true);
      setItemsData([]);
      setOpenUserList("");
      // dispatch(clearSla());
      // setSelectedCard({});
      dispatch(clearBulk());
      dispatch(clearCardsAssignAndState());
      setHasNextPage(false);
      setLastOperation(0);
      setEngagementPostId(null);
      setCountLoading(true);
      setSlaCounterData({
        ...slaSideData,
        loading: true,
      });
      resetTabsContentCount();
      setAiAgentList({});
      setIsAiAgentDown(false);
      setIsLimitReached(false);
      setIsDMLimitReached(false);
      setAiAgentDMReachDetails(null);
    } else if (allData?.page_number == 1 && !itemsData?.length) {
      setLoading(true);
    }
    switch (response?.eventName) {
      case "EngagementsProductPage":
        if (
          response?.eventData?.engagements_status_count
            ?.engagements_status_count
        ) {
          if (filters?.page == 1) {
            const newTabsContentCount =
              response?.eventData?.engagements_status_count
                ?.engagements_status_count || {};
            // mutableTabsContent will hold the tabs that can be changed that less than maxTabsCount
            const mutableTabsContent = [];
            Object.keys(newTabsContentCount)?.forEach?.((key) => {
              if (newTabsContentCount[key] < maxTabsCount) {
                mutableTabsContent.push(key);
              }
            });
            setTabsContentCount(
              {
                ...newTabsContentCount,
                mutableTabs: mutableTabsContent,
              },
              filterTypeChange,
            );
            setDataSourceCount(response?.eventData?.data_source_counts);
          }
          // at case is_alert we make active tab is all
          if (
            response?.eventData?.is_alert &&
            window.location.href?.includes("alert_id")
          ) {
            let newValTab = 0;
            let newStatusVal = "all";
            // may be used later
            // if (
            //   response?.eventData?.engagements_status_count
            //     ?.engagements_status_count?.new
            // ) {
            //   newValTab = 1;
            //   newStatusVal = "new";
            // } else if (
            //   response?.eventData?.engagements_status_count
            //     ?.engagements_status_count?.active
            // ) {
            //   newValTab = 2;
            //   newStatusVal = "active";
            // } else if (
            //   response?.eventData?.engagements_status_count
            //     ?.engagements_status_count?.complete
            // ) {
            //   newValTab = 3;
            //   newStatusVal = "complete";
            // } else {
            //   newValTab = 0;
            //   newStatusVal = "all";
            // }

            setFilters({ ...filters, engagement_status: newStatusVal });

            setTabValue(newValTab);
          }
        }
        setHasNextPage(response?.eventData?.next_page);
        response?.eventData?.last_operation != undefined
          ? setLastOperation(response?.eventData?.last_operation)
          : setLastOperation(0);
        response?.eventData?.engagement_post_id != undefined
          ? setEngagementPostId(response?.eventData?.engagement_post_id)
          : setEngagementPostId(null);
        let data = response?.eventData?.top_engagements
          ? response?.eventData?.top_engagements
          : [];
        // set sla tabs counter
        if (response?.eventData?.engagements_tabs_count && filters.page == 1) {
          slaSideData.values = {
            ...response?.eventData?.engagements_tabs_count,
          };
          const mutableTabsContent = [];
          Object.keys(slaSideData?.values)?.forEach?.((key) => {
            if (slaSideData?.values[key] < maxTabsCount) {
              mutableTabsContent.push(key);
            }
          });
          slaSideData.mutableTabs = mutableTabsContent;
        }
        if (data?.length) {
          // every change of page we call getUsers ,getCompanySLAs and getAllCompanyTeams to update list of slas, users and teams
          let slaObj = { ...reduxSlaData };
          let cardsStateObj = { ...reduxCardsAssignAndState };
          EngagementsController.getCompanySLAs().then((res) => {
            if (!res?.errorMsg) {
              let slasList = res?.data?.data?.map((item) => item?.attributes);
              setSlasList([...slasList]);
            }
          });
          if (filters?.sla_status === "ai_agent_sla") {
            AiAgentsServices.getAiAgentsLightList().then((res) => {
              if (!res?.errorMsg) {
                setAiAgentList(res?.data);
              }
            });
          }
          CompaniesController.getAllCompanyTeams().then((res) => {
            if (!res?.errorMsg && res?.data?.teams?.length) {
              slaObj.teams = [...res?.data?.teams];
            } else {
              slaObj.teams = [];
            }
            slaObj.teamsLoaded = true;
            dispatch(slaData(slaObj));
          });

          EngagementsController.getUsers(
            window.localStorage.getItem("engagementsProductId"),
          ).then((res) => {
            if (!res?.errorMsg && res?.data?.data?.length) {
              slaObj.users = [...res?.data?.data];
            } else {
              slaObj.users = [];
            }
            dispatch(slaData(slaObj));
          });

          setItemsData([
            ...itemsData,
            ...data?.map((item, i) => {
              cardsStateObj[
                `${item?.tweet_id ? item?.tweet_id : item?.id}-${
                  item?.tracker_id
                }`
              ] = {
                userId: item?.assigning_info?.user_id,
                teamId: item?.assigning_info?.team_id,
                statusItemCard: item?.system_replies
                  ? item?.system_replies?.status
                  : item?.status,
              };
              const engagmentId = `${item?.tweet_id ? item?.tweet_id : item?.id}-${
                item?.tracker_id
              }`;
              const slaObjInfo = slaObj?.[engagmentId];
              slaObj[engagmentId] = {
                // set sla card data at redux
                frtSec: item?.sla_info?.first_response_time,
                ttcSec: item?.sla_info?.time_to_complete,
                lastMsgDirecton: item?.message?.content?.direction,
                nrtSec:
                  item?.message?.content?.direction == "inbound"
                    ? null
                    : item?.sla_info?.next_response_time,
                nrtDate:
                  item?.message?.content?.created_at || slaObjInfo?.nrtDate,
                tutSec: item?.sla_info?.unassigned_response_time,
                slaId: item?.sla_info?.sla_id || item?.assigning_info?.sla_id,
                userId: item?.assigning_info?.user_id,
                teamId: item?.assigning_info?.team_id,
                reload: 1,
                frtDate: slaObjInfo?.frtDate,
                ttcCompletedDate: slaObjInfo?.ttcCompletedDate,
                tutDate: slaObjInfo?.tutDate,
                cardText: item?.tweet_text
                  ? item?.tweet_text
                  : item?.message?.content?.text,
              };
              return {
                ...item,
                data_source: item?.data_source?.split("_")[0],
                isDm: item?.data_source?.includes("private"),
                user_profile_image_url: item?.user_profile_image_url
                  ? item?.user_profile_image_url
                  : profileImg,
              };
            }),
          ]);
          dispatch(slaData(slaObj));
          dispatch(
            cardsAssignAndStateData({
              ...cardsStateObj,
            }),
          );
        } else {
          setItemsData([...itemsData]);
        }
        if (
          window.location.href.includes("alert_id") ||
          window.location.href.includes("interaction_id")
        ) {
          window.history.pushState({}, "", window.location.pathname);
        }
        // here we stop all loading
        setLoadMore(false);
        setLoading(false);
        setCountLoading(false);
        slaSideData.loading = false;
        setSlaCounterData({ ...slaSideData }, filterTypeChange);
        setIsSlaInitialLoad(false);

        break;
      default:
        break;
    }
  }, [response]);

  useEffect(() => {
    if (cardState?.changeStateDone) {
      if (!cardState?.notClose) {
        // setSelectedCard({});
      }
      // check if the engagement is in the engagement list in the active tab.
      const isEngagementInListForCurrentActiveTab = itemsData?.find(
        (engagement) => {
          return (
            getEngagementId(engagement) === getEngagementId(cardState?.cardData)
          );
        },
      );

      if (cardState?.activeTab == "all") {
        let cardObject = {
          ...cardState?.cardData,
          status: cardState?.changeStateTo,
          system_replies: {
            ...cardState?.cardData?.system_replies,
            status: cardState?.changeStateTo,
          },
        };
        const itemElement = document.getElementById(
          `card-engag-state-${cardState?.cardIndex}`,
        );

        if (itemElement) {
          itemElement.classList.add("card-state-animation");
          setTimeout(() => {
            itemsData[cardState?.cardIndex] = { ...cardObject };
            setItemsData([...itemsData]);
          }, 300);
          setTimeout(() => {
            itemElement.classList.remove("card-state-animation");
          }, 600);
        }
      } else if (isEngagementInListForCurrentActiveTab) {
        const cardData = cardState?.cardData;
        const cardId = getCardId(cardData);
        if (isEngagementInListForCurrentActiveTab) {
          handleCardRemoval(cardId);
        }
      }
      // check if the engagement is in the engagement list in the active tab. before update the tabs counter
      if (isEngagementInListForCurrentActiveTab) {
        let newUpdatedTabsContentCount = {};
        if (cardState?.cardInitialState == "complete") {
          newUpdatedTabsContentCount.complete = +tabsContentCount?.complete - 1;
        } else if (
          cardState?.cardInitialState == "active" ||
          cardState?.cardInitialState == "reopen"
        ) {
          newUpdatedTabsContentCount.active = +tabsContentCount?.active - 1;
        } else {
          newUpdatedTabsContentCount.new = +tabsContentCount?.new - 1;
        }

        if (cardState?.changeStateTo == "complete") {
          newUpdatedTabsContentCount.complete = +tabsContentCount?.complete + 1;
        } else {
          newUpdatedTabsContentCount.active = +tabsContentCount?.active + 1;
        }
        setTabsContentCount(newUpdatedTabsContentCount, "content_tabs");
      }

      if (cardState?.updateInitialState) {
        cardState.cardInitialState = cardState.changeStateTo;
        cardState.updateInitialState = false;
      }
      setCardState({ ...cardState, changeStateDone: false });
    }
  }, [cardState]);

  // Here we handle second call of applyFilters and this for performance with back-end
  useEffect(() => {
    if (!loading && !loadMore && hasNextPage) {
      applyFilters(false);
    }
  }, [loading, loadMore, hasNextPage]);

  // Here we handle first call of applyFilters and this for get cards data
  const handlePagenition = () => {
    if (!loading && !loadMore && hasNextPage) {
      setLoadMore(true);
      filters.page = filters.page + 1;
      setFilters({ ...filters });
      applyFilters();
    }
  };

  const handleScroll = (event) => {
    const target = event.target;
    if (
      Math.trunc(target.scrollHeight) -
        Math.trunc(target.scrollTop) -
        Math.trunc(target.clientHeight) <=
      4
    ) {
      handlePagenition();
    }
  };

  useEffect(() => {
    const element = listRef.current;
    const checkScroll = () => {
      const element = listRef.current;
      const btn = loadMoreRef.current;

      if (element) {
        let btnHeight = btn?.clientHeight ? btn?.clientHeight : 20;
        setHasVerticalScrollbar(
          element.scrollHeight > element.clientHeight + btnHeight,
        );
      }
    };

    if (element) {
      // Initial check
      checkScroll();
      window.addEventListener("resize", checkScroll);
      return () => {
        window.removeEventListener("resize", checkScroll);
      };
    }
  }, [itemsData]);

  return (
    <Box
      className="tab-content-wrapper"
      id="handle-pagination-scroll"
      onScroll={handleScroll}
      ref={listRef}
    >
      {loading ? (
        counterArr?.map((i) => cardLoading())
      ) : !itemsData?.length && !hasNextPage ? (
        <NoDataSideBarTab filters={filters} />
      ) : (
        <>
          {itemsData?.map((item, index) => {
            const cardKey = getCardId(item);
            const id = `${item?.tweet_id ? item?.tweet_id : item?.id}-${item?.tracker_id}`;
            const frtSec = reduxSlaData[id]?.frtSec;
            const ttcSec = reduxSlaData[id]?.ttcSec;
            const nrtSec = reduxSlaData[id]?.nrtSec;
            const tutSec = reduxSlaData[id]?.tutSec;
            return (
              <CardItem
                openUserList={openUserList}
                setOpenUserList={setOpenUserList}
                id={cardKey}
                item={item}
                cardText={
                  reduxSlaData[
                    `${item?.tweet_id ? item?.tweet_id : item?.id}-${item?.tracker_id}`
                  ]?.cardText
                }
                lastMsgDirecton={
                  reduxSlaData[
                    `${item?.tweet_id ? item?.tweet_id : item?.id}-${item?.tracker_id}`
                  ]?.lastMsgDirecton
                }
                reload={
                  reduxSlaData[
                    `${item?.tweet_id ? item?.tweet_id : item?.id}-${item?.tracker_id}`
                  ]?.reload
                }
                frtSec={isNullish(frtSec) ? null : frtSec}
                ttcSec={isNullish(ttcSec) ? null : ttcSec}
                nrtSec={isNullish(nrtSec) ? null : nrtSec}
                nrtDate={
                  reduxSlaData[
                    `${item?.tweet_id ? item?.tweet_id : item?.id}-${item?.tracker_id}`
                  ]?.nrtDate
                }
                tutSec={isNullish(tutSec) ? null : tutSec}
                slaId={
                  reduxSlaData[
                    `${item?.tweet_id ? item?.tweet_id : item?.id}-${item?.tracker_id}`
                  ]?.slaId
                }
                userId={
                  reduxSlaData[
                    `${item?.tweet_id ? item?.tweet_id : item?.id}-${item?.tracker_id}`
                  ]?.userId
                }
                teamId={
                  reduxSlaData[
                    `${item?.tweet_id ? item?.tweet_id : item?.id}-${item?.tracker_id}`
                  ]?.teamId
                }
                teams={reduxSlaData?.teams}
                users={reduxSlaData?.users}
                slasList={slasList}
                aiAgentList={aiAgentList}
                allData={allData}
                index={index}
                zIndex={itemsData?.length - index}
                morThanOneItem={itemsData?.length > 1}
                setOpenSidebarToAssignTeam={setOpenSidebarToAssignTeam}
                sidebarTab={filters?.sla_status}
                currentActiveTab={filters?.engagement_status}
                hasTeamsLoaded={reduxSlaData?.teamsLoaded}
              />
            );
          })}
          {hasNextPage && (!hasVerticalScrollbar || loadMore) ? (
            <Box className="pagination-loader" ref={loadMoreRef}>
              <Box
                id={"engagement-load-more"}
                className={`pagination-loader-btn ${loadMore && "disabled"}`}
                onClick={!loadMore && handlePagenition}
              >
                {loadMore ? (
                  <FontAwesomeIcon icon={faSpinnerThird} spin />
                ) : (
                  <FontAwesomeIcon icon={faArrowDown} />
                )}
              </Box>
            </Box>
          ) : null}
        </>
      )}
    </Box>
  );
};
export default TabContent;
