import { Box } from "@mui/material";
import React from "react";
import WidgetHeader from "../widgetHeader";
import FetchingData from "components/fetchingData/fetchingData";
import ChartAvgStatsWidget from "./chartAvgStatsWidget";
import _ from "lodash";
import "./avgStatsWidget.scss";
import NoDataFound from "components/no-Data/noDataFound";
/**
 * AvgStatswWidget component is used to display the average overview widget.
 * @param {string} title - The title of the widget.
 * @param {string} titleToolTip - The tooltip for the title.
 * @param {string} chartId - The id of the chart.
 * @param {object} overview - The statsData data
 * statsData = {
 *  name: "string",
 *  value: "string",
 *  percentage: "string",
 *  up: "boolean",
 *  fromDate: "string",
 *  toDate: "string",
 *  fromValue: "string",
 * }
 *
 */
const AvgStatswWidget = ({
  title,
  titleToolTip,
  chartId,
  statsData,
  dates,
  isLoading = false,
  ...props
}) => {
  return (
    <Box className="avgerage-stats-widget" id={chartId}>
      <WidgetHeader
        title={title}
        showDownloadIcon
        titleToolTip={titleToolTip}
        chartId={chartId}
        bigImg={true}
      />
      {_.isEqual(
        props?.statisticsPreLoaderDataSources?.sort(),
        props?.preLoaderTrackerDataSources?.sort(),
      ) !== true ||
      isLoading ||
      !props?.statisticsPreLoaderDataSources?.length ||
      !props?.preLoaderTrackerDataSources?.length ? (
        <Box className="avgerage-stats-widget-fetching">
          <FetchingData />
        </Box>
      ) : !statsData || statsData?.length === 0 ? (
        <Box className="avgerage-stats-widget-fetching">
          <NoDataFound />
        </Box>
      ) : (
        <>
          <ChartAvgStatsWidget
            statsData={statsData}
            toolTipPosition={props?.toolTipPosition}
            tooltipID={props?.tooltipID}
            dates={dates}
            {...props}
          />
        </>
      )}
    </Box>
  );
};

export default AvgStatswWidget;
