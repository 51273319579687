import { Box } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import "./noteSidebarCardLoader.scss";
const NoteSidebarCardLoader = () => {
  let number = [
    { name: "note 1" },
    { name: "note 2" },
    { name: "note 3" },
    { name: "note 4" },
  ];
  return (
    <Box className="main-note-loader-container">
      {number?.map(() => (
        <Box className="main-note-cards-loader">
          <Box className="note-cards-loader-header">
            <Skeleton
              animation="pulse"
              variant="circular"
              width={32}
              height={32}
              sx={{ bgcolor: "white" }}
            />
            {/* long one */}
            <Skeleton
              animation="pulse"
              variant="text"
              width={100}
              sx={{ bgcolor: "white" }}
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
};
export default NoteSidebarCardLoader;
