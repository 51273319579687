// Common file used in whole app to BreadCrumb on top of the screens !
// Don't repeat/update or remove this file!
// This files accepts an array :
//  links =[{
//       name: string,//without local ,name of the page
//       url: string,//without local, page url
//       addNameLocal: boolean //true if you want the passed name prop to be localized into En/Ar
//       state:object // sending data between pages within the link
//     }]

import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import "components/widgets/styles/styles.scss";
import { CheckValueLocale, newProductNameMap } from "utils/helpers";
import { useIntl } from "react-intl";

const BreadCrumb = (props) => {
  const intl = useIntl();

  let breadcrumbID = props.id ? props.id : "breadcrumb-style";

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      id={breadcrumbID}
      className="bread-crumb"
    >
      {props.links?.map((link, i) => {
        return (
          <Link
            underline="hover"
            color="inherit"
            className={link?.disableURL && "disabled-url"}
            to={
              !link?.disableURL
                ? {
                    pathname: `/${link.url}`,
                    state: link?.state ? link?.state : {},
                  }
                : {
                    pathname: "",
                    state: {},
                  }
            }
            key={i}
          >
            {link?.addNameLocal === false
              ? link?.name
              : link?.name
                ? CheckValueLocale(
                    newProductNameMap[link?.name] || link?.name,
                    "",
                    {},
                    intl,
                  )
                : ""}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};
export default BreadCrumb;
