import { Box } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import "./slaDetailsSideBar.scss";
import React, { useState, useEffect } from "react";
import { CheckValueLocale, newProductNameMap } from "utils/helpers/index.js";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone } from "@fortawesome/free-regular-svg-icons";

const SlaInfo = ({ selectedCard, headerData }) => {
  const intl = useIntl();
  const [copied, setCopied] = useState(false);
  const [infoObjData, setInfoObjData] = useState({
    routing: headerData?.routing_info?.data?.attributes?.name
      ? headerData?.routing_info?.data?.attributes?.is_deleted
        ? headerData?.routing_info?.data?.attributes?.name + " deleted"
        : headerData?.routing_info?.data?.attributes?.name
      : "-",
    product: headerData?.product
      ? CheckValueLocale(headerData?.product, "", {}, intl)
      : "-",
    monitor_name_sla: headerData?.monitor_name || "-",
    engag_id: selectedCard?.system_replies?.engagement_id
      ? selectedCard?.system_replies?.engagement_id
      : "-",
    slas_name: headerData?.sla_info?.data?.attributes?.name
      ? headerData?.sla_info?.data?.attributes?.name
      : "-",
  });

  useEffect(() => {
    setInfoObjData({
      routing: headerData?.routing_info?.data?.attributes?.name
        ? headerData?.routing_info?.data?.attributes?.is_deleted
          ? headerData?.routing_info?.data?.attributes?.name + " deleted"
          : headerData?.routing_info?.data?.attributes?.name
        : "-",
      product: headerData?.product
        ? CheckValueLocale(
            newProductNameMap[headerData?.product] || headerData?.product,
            "",
            {},
            intl,
          )
        : "-",
      monitor_name_sla: headerData?.monitor_name || "-",
      engag_id: selectedCard?.system_replies?.engagement_id
        ? selectedCard?.system_replies?.engagement_id
        : "-",
      slas_name: headerData?.sla_info?.data?.attributes?.name
        ? headerData?.sla_info?.data?.attributes?.name
        : "-",
    });
  }, [intl.locale]);

  const handleCopy = () => {
    setCopied(true);
    navigator.clipboard.writeText(infoObjData["engag_id"]);

    setTimeout(() => {
      setCopied(false);
    }, 2300);
  };
  return (
    <Box className="main-sla-contant">
      <Box className="sla-contant-title" id="sla-sidebar-main-info-title">
        {CheckValueLocale("main_info", "", {}, intl)}
      </Box>
      {Object.keys(infoObjData)?.map((item) => (
        <Box
          className="sla-info-item"
          key={item}
          id={`sla-sidebar-${item}-key`}
        >
          {CheckValueLocale(item, "", {}, intl)}
          <Box className="sla-info-tooltips" id={`sla-sidebar-${item}-value`}>
            {item == "engag_id" && infoObjData["engag_id"] !== "-" ? (
              <Tooltip
                title={
                  copied
                    ? CheckValueLocale("copied_sla", "", {}, intl)
                    : CheckValueLocale("take_copy", "", {}, intl)
                }
                id="engag-copy-id"
                placement="bottom"
                arrow
              >
                <Box onClick={handleCopy} className="sla-engag-copy">
                  <FontAwesomeIcon icon={faClone} />
                </Box>
              </Tooltip>
            ) : null}
            {(item == "engag_id" && infoObjData[item]?.length > 9) ||
            (item !== "engag_id" && infoObjData[item]?.length > 20) ? (
              <Tooltip title={infoObjData[item]} arrow placement="bottom">
                {`${infoObjData[item]?.substring(
                  0,
                  item == "engag_id" ? 9 : 20,
                )}...`}
              </Tooltip>
            ) : (
              infoObjData[item]
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
};
export default SlaInfo;
