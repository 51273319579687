import { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Alert,
  DialogContentText,
  Typography,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import {
  CheckValueLocale,
  getKnowledgeBaseFileSizeInKBOrMB,
} from "utils/helpers";
import _ from "lodash";
import { useIntl } from "react-intl";
import CloseIcon from "@mui/icons-material/Close";
import "./style.scss";
import ExcelIcon from "images/excel-icon.svg";
import UploadExceFile from "./components/uploadFileExcel";
import DownloadFileExcel from "./components/downloadFileExcel";
import "./style.scss";
import LucButton from "shared/lucButton/lucButton";

const AccountsListImportExcelPopUp = (props) => {
  const intl = useIntl();
  const {
    setFiles,
    excelFileName,
    isFileUploaded,
    errorMessages,
    importedUsernames,
    handleResetImportPopUp,
    setSnackBarData,
    handleExcelGuideData,
    handleCancelUploadFile,
    excelGuideData,
    handleXlsImportedUsernames,
    excelGuideLoading,
  } = props;

  const [buttonLoading, setButtonLoading] = useState(false);
  const [fileSize, setFileSize] = useState(0);

  const isDisableImport = !isFileUploaded;

  const handleUploadData = () => {
    setButtonLoading(true);
    try {
      const usernames = importedUsernames;
      const validUsernames = handleXlsImportedUsernames(usernames);
      setSnackBarData({
        message: CheckValueLocale(
          "accounts_list_import_excel_success_message",
          "",
          {
            numOfAccounts: _.uniq(validUsernames)?.filter(
              (item) => item != null && item !== "",
            )?.length,
          },
          intl,
        ),
        severity: "success",
        openSnackBar: true,
      });
    } catch (error) {
      setSnackBarData({
        message: CheckValueLocale(
          "accounts_list_import_excel_error_message",
          "",
          {},
          intl,
        ),
        severity: "error",
        openSnackBar: true,
      });
    } finally {
      setButtonLoading(false);
      handleResetImportPopUp();
    }
    setButtonLoading(false);
    handleResetImportPopUp();
  };

  useEffect(() => {
    handleExcelGuideData();
  }, [excelGuideLoading]);

  return (
    <Dialog
      open={true}
      onClose={handleResetImportPopUp}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      id="alert-dialog"
      className="accounts-list-import-pop-up-container"
    >
      <Box className="accounts-list-import-pop-up actions-buttons-shared">
        <DialogTitle id="accounts-list-import-dialog-title">
          {CheckValueLocale("accounts_list_import_title", "", {}, intl)}
        </DialogTitle>
        <DialogContent
          id="dialog-content-import-popup"
          className={`${!isFileUploaded ? "dialog-content-import-uploaded" : ""}`}
        >
          {excelGuideLoading ? (
            <Box className="loading-dialog-import-popup">
              <CircularProgress />
            </Box>
          ) : (
            <>
              {!isFileUploaded ? (
                <Alert
                  severity="info"
                  id="import-accounts-alert-msg"
                  className="custom-alert-position"
                >
                  {CheckValueLocale("import_accounts_info_msg", "", {}, intl)}
                </Alert>
              ) : null}
              {!isFileUploaded ? (
                <Box>
                  <DialogContentText className="accounts-list-import-desc">
                    {CheckValueLocale(
                      "accounts_list_import_description",
                      "",
                      {},
                      intl,
                    )}
                  </DialogContentText>
                  <DownloadFileExcel excelGuideData={excelGuideData} />
                  <UploadExceFile
                    setFiles={setFiles}
                    errorMessages={errorMessages}
                    setFileSize={setFileSize}
                  />
                </Box>
              ) : (
                <Box className="excel-file-container">
                  <Box className="excel-file-content">
                    <img
                      src={ExcelIcon}
                      className="excel-file-icon"
                      alt="excel icon"
                    />
                    <Box className="uploaded-file-content">
                      <Typography
                        component="span"
                        className="excel-file-name-title"
                      >
                        {excelFileName}
                      </Typography>
                      <Typography className="upload-file-progress">
                        {CheckValueLocale(
                          "account_lists_file_size",
                          "",
                          {
                            fileSize:
                              getKnowledgeBaseFileSizeInKBOrMB(fileSize),
                          },
                          intl,
                        )}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Tooltip
                      title={CheckValueLocale(
                        "import_cancel_upload_file_tooltip",
                        "",
                        {},
                        intl,
                      )}
                      arrow
                    >
                      <Box
                        className="close-upload-file"
                        onClick={handleCancelUploadFile}
                      >
                        <CloseIcon />
                      </Box>
                    </Tooltip>
                  </Box>
                </Box>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions className={`import-action-container`}>
          <Typography
            component="p"
            className="manage-import-file-title"
          ></Typography>

          <Box>
            <LucButton
              onClick={handleResetImportPopUp}
              variant="flat"
              type="secondary"
              className="cancel-popup-btn"
            >
              {CheckValueLocale("cancel", "", {}, intl)}
            </LucButton>
            <LucButton
              disabled={isDisableImport || buttonLoading}
              onClick={handleUploadData}
              loading={buttonLoading}
            >
              {CheckValueLocale("accounts_list_import", "", {}, intl)}
            </LucButton>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
export default AccountsListImportExcelPopUp;
