import React, { useState, useRef, useEffect } from "react";
import LucButton from "shared/lucButton/lucButton";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTag } from "@fortawesome/pro-regular-svg-icons";
import OptionDropdownTags from "./optionDropdownTags";
import { Typography, Box } from "@mui/material";
import CustomizedTooltip from "components/customizedTooltip";

const CustomSelectTags = (props) => {
  const intl = useIntl();
  const {
    selectedOptions,
    applyOpacity,
    setSelectedOptions,
    initialvlue,
    setOpen,
    informalTags,
    setOpenDropdown,
    menuType,
    enabledCreate,
    loading,
    setLoading,
    setMenuType,
    selectedColor,
    setSelectedColor,
    tagName,
    setTagName,
    handleApplyTags,
    isSettingPage,
    isOptionTable,
    setIsEnabledEdit,
    editTagData,
    setSnackBar,
    dropdownEvent,
    anchorElTagList,
    setAnchorElTagList,
    setSnackBarTagsTable,
    itemData,
    tagsLimitReached,
    tagsTableData,
    hideTagText,
    informalTagsLoading,
  } = props;

  let checkHiddenNewTag = !enabledCreate || tagsLimitReached;
  const [colorPickerAnchor, setColorPickerAnchor] = useState(null);
  const popupRef = useRef(null);

  const handleOpenColorPicker = () => {
    setColorPickerAnchor(popupRef.current);
  };

  // open dropdown menu tags list
  const handleOpenDropdownList = (event, type) => {
    if (isOptionTable) {
      setAnchorElTagList(event);
    } else {
      setAnchorElTagList(event?.currentTarget);
    }
    setMenuType(type);
  };
  // Sets the menu type to 'addTag' to create a new tag popup.
  const handleCreateTag = () => {
    setMenuType("addTag");
  };
  // Closes the dropdown and resets related states.
  const handleClose = () => {
    setAnchorElTagList(null);
    setMenuType("");
    setTagName("");
    setIsEnabledEdit?.(false);
    setSelectedColor("");
  };

  // Opens the dropdown automatically if in a table view.
  useEffect(() => {
    if (isOptionTable) {
      handleOpenDropdownList(dropdownEvent, "addTag");
    }
  }, [isOptionTable, dropdownEvent]);

  return (
    <Box>
      {isSettingPage ? (
        <CustomizedTooltip
          id="tag-menu-list-tooltip"
          title={
            checkHiddenNewTag ? (
              <Box className="item-title-tooltip">
                {CheckValueLocale(
                  !enabledCreate
                    ? "dont_have_a_permission"
                    : "maximum_limit_tags",
                  "",
                  {},
                  intl,
                )}
              </Box>
            ) : null
          }
          placement="bottom"
          arrow
        >
          <Box>
            <LucButton
              id="add-tag-table"
              type="primary"
              variant="outline"
              disabled={checkHiddenNewTag}
              onClick={(e) => {
                if (isSettingPage || isOptionTable)
                  handleOpenDropdownList(e, "addTag");
                else handleCreateTag;
              }}
              startIcon={<FontAwesomeIcon icon={faPlus} />}
            >
              {CheckValueLocale("new_tag", "", {}, intl)}
            </LucButton>
          </Box>
        </CustomizedTooltip>
      ) : isOptionTable ? null : (
        <LucButton
          id="engagement-tags"
          className="engagement-tags"
          onClick={(e) => handleOpenDropdownList(e, "tags")}
          variant="Flat"
          startIcon={<FontAwesomeIcon icon={faTag} />}
        >
          {!hideTagText ? (
            <Box className="engagement_tags-name">
              {selectedOptions?.length ? (
                <Typography component={"span"}>
                  {selectedOptions?.length}
                </Typography>
              ) : null}
              {CheckValueLocale("engagement_tags", "", {}, intl)}
            </Box>
          ) : null}
        </LucButton>
      )}

      {anchorElTagList ? (
        <OptionDropdownTags
          setOpen={setOpen}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          initialvlue={initialvlue}
          applyOpacity={applyOpacity}
          handleCreateTag={handleCreateTag}
          menuType={menuType}
          informalTags={informalTags}
          setOpenDropdown={setOpenDropdown}
          enabledCreate={enabledCreate}
          loading={loading}
          setLoading={setLoading}
          setMenuType={setMenuType}
          selectedColor={selectedColor}
          setSelectedColor={setSelectedColor}
          tagName={tagName}
          setTagName={setTagName}
          handleApplyTags={handleApplyTags}
          setIsEnabledEdit={setIsEnabledEdit}
          editTagData={editTagData}
          setSnackBar={setSnackBar}
          setSnackBarTagsTable={setSnackBarTagsTable}
          itemData={itemData}
          anchorElTagList={anchorElTagList}
          handleClose={handleClose}
          setAnchorElTagList={setAnchorElTagList}
          checkHiddenNewTag={checkHiddenNewTag}
          tagsTableData={tagsTableData}
          handleOpenColorPicker={handleOpenColorPicker}
          colorPickerAnchor={colorPickerAnchor}
          setColorPickerAnchor={setColorPickerAnchor}
          popupRef={popupRef}
          tagsLimitReached={tagsLimitReached}
          informalTagsLoading={informalTagsLoading}
        />
      ) : null}
    </Box>
  );
};

export default CustomSelectTags;
