import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Collapse } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import EngagementsController from "services/controllers/engagementsController";
import { CheckValueLocale } from "utils/helpers";
import TranslationRating from "../../translationRating/translationRating";

export const TranslationText = ({
  data,
  translationActive,
  text,
  index,
  translationTarget,
  translationSource,
  setStats,
  selectedCard,
}) => {
  const intl = useIntl();
  const [openTranslationCollapse, setOpenTranslationCollapse] = useState({});
  const [openReplyCollapse, setOpenReplyCollapse] = useState({});
  const [translationStatus, setTranslationStatus] = useState({});
  const [translationButtonLoading, setTranslationButtonLoading] = useState({});
  const [translationButtonResult, setTranslationButtonResult] = useState({});

  const translationCollapseHandler = (index) => {
    setOpenTranslationCollapse({
      ...openTranslationCollapse,
      [index]: !openTranslationCollapse[index],
    });
  };
  const translationReplyHandler = (index) => {
    setOpenReplyCollapse({
      ...openReplyCollapse,
      [index]: !openReplyCollapse[index],
    });
  };

  const translationButtonHandler = (text, index) => {
    setTranslationStatus({
      ...translationStatus,
      [index]: true,
    });
    setTranslationButtonLoading({
      ...translationButtonLoading,
      [index]: true,
    });
    const queryData = {
      text,
      target_language: translationTarget,
      source_language:
        translationSource !== "auto_detect" ? translationSource : null,
      product_id: window.localStorage.getItem("engagementsProductId"),
    };
    EngagementsController.detectTranslate(queryData).then((res) => {
      if (!res?.errorMsg) {
        setTranslationButtonResult({
          ...translationButtonResult,
          [index]: res?.data,
        });
        setOpenTranslationCollapse({
          ...openTranslationCollapse,
          [index]: true,
        });
      } else {
        setTranslationButtonResult({
          ...translationButtonResult,
          [index]: null,
        });
        setTranslationStatus({
          ...translationStatus,
          [index]: false,
        });
        setStats((prev = {}) => ({
          ...prev,
          open: true,
          title: CheckValueLocale("request_access_error_heading", "", {}, intl),
          body: CheckValueLocale("request_access_error_desc", "", {}, intl),
          severity: "error",
        }));
        setTimeout(() => {
          setStats((prev = {}) => ({
            ...prev,
            open: false,
          }));
        }, 7000);
      }
      setTranslationButtonLoading({
        ...translationButtonLoading,
        [index]: false,
      });
    });
  };

  useEffect(() => {
    setTranslationStatus({});
    setOpenTranslationCollapse({});
    setTranslationButtonLoading({});
    setTranslationButtonResult({});
  }, [translationTarget, translationSource]);

  return (
    <>
      {translationActive && !data?.original_replay ? (
        <Box className="card-translation-wrapper">
          <span
            id="engagements-dm-translation-btn"
            className="translation-button"
            onClick={() => translationButtonHandler(text, index)}
          >
            {CheckValueLocale("translate_from", "", {}, intl)} (
            {CheckValueLocale(translationSource, "", {}, intl)}){" "}
            {CheckValueLocale("to_lang", "", {}, intl)} (
            {CheckValueLocale(translationTarget, "", {}, intl)})
          </span>
          {translationStatus[index] ? (
            <Box className="card-translation-box">
              {translationButtonLoading[index] ? (
                <span className="translation-loading">
                  {CheckValueLocale("loading_please_wait", "", {}, intl)}
                </span>
              ) : (
                <Box className="card-translation-collapse">
                  <Box className="collapse-heading">
                    <span className="translation-lang">
                      {CheckValueLocale("from_lang", "", {}, intl)}
                      <span>
                        {" "}
                        {CheckValueLocale(
                          translationButtonResult[index]?.source_language,
                          "",
                          {},
                          intl,
                        )}{" "}
                      </span>
                      {CheckValueLocale("to_lang", "", {}, intl)}
                      <span>
                        {" "}
                        {CheckValueLocale(translationTarget, "", {}, intl)}{" "}
                      </span>
                    </span>
                    <span
                      className="arrow-icon-translation"
                      onClick={() => translationCollapseHandler(index)}
                    >
                      {openTranslationCollapse[index] ? (
                        <ExpandMore />
                      ) : (
                        <ExpandLess />
                      )}
                    </span>
                  </Box>
                  <Collapse in={openTranslationCollapse[index]}>
                    <span className="translation-text">
                      {translationButtonResult[index]?.translation}
                    </span>
                    <TranslationRating
                      translationSource={
                        translationButtonResult[index]?.source_language
                      }
                      translationTarget={translationTarget}
                      translation={translationButtonResult[index]?.translation}
                      selectedCard={selectedCard}
                      engagementId={data?.message_id}
                    />
                  </Collapse>
                </Box>
              )}
            </Box>
          ) : null}
        </Box>
      ) : null}
      {/* translation original replay */}
      {data?.original_replay ? (
        <Box className="card-translation-box">
          <Box className="card-translation-collapse">
            <Box className="collapse-heading">
              <span className="translation-lang">
                <span>
                  {CheckValueLocale("your_original_replay", "", {}, intl)}
                </span>
              </span>
              <span
                className="arrow-icon-translation"
                onClick={() => translationReplyHandler(index)}
              >
                {!openReplyCollapse[index] ? <ExpandMore /> : <ExpandLess />}
              </span>
            </Box>
            <Collapse in={!openReplyCollapse[index]}>
              <span className="translation-text">{data?.original_replay}</span>
            </Collapse>
          </Box>
        </Box>
      ) : null}
    </>
  );
};
