import React from "react";
import { Box, InputLabel, TextField } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import "./style.scss";

const ReportTextInput = ({
  id,
  label,
  placeholder,
  name,
  value,
  disabled,
  onChange = () => {},
  onBlur = () => {},
  error = false,
  helperText,
  isEdit,
}) => {
  const intl = useIntl();

  return (
    <Box className="report-input-wrapper">
      <InputLabel className="report-input-label" disabled={disabled}>
        {CheckValueLocale(label, "", {}, intl)}
      </InputLabel>
      <TextField
        className={
          isEdit ? "edit-report-text-input" : "create-report-text-input"
        }
        variant="outlined"
        size="small"
        fullWidth
        id={id || ""}
        name={name || ""}
        placeholder={CheckValueLocale(placeholder, "", {}, intl) || ""}
        value={value || ""}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled || false}
        error={error || false}
        helperText={CheckValueLocale(helperText, "", {}, intl) || ""}
      />
    </Box>
  );
};

export default ReportTextInput;
