import { useMutation, useQueryClient } from "@tanstack/react-query";
import SettingsController from "services/controllers/settingsController";

export const usePostVerificationCode = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (saveData) => {
      return await SettingsController?.postVerificationCode(saveData);
    },
    onSuccess: () => {
      queryClient?.invalidateQueries({
        queryKey: ["SecruityActivatedFeatures"],
      });
    },
  });
};
