import { Box, Divider } from "@mui/material";
import ThemesMenu from "./components/themesMenu.js";
import CategoriesMenu from "./components/categoriesMenu.js";
import SentimentsMenu from "./components/sentimentsMenu.js";
import "./style.scss";

const SentimentCategoriesThemes = (props) => {
  const {
    sentimentData,
    categoriesData,
    themesData,
    dividers,
    isOnlyView,
  } = props;

  return (
    <Box id="sentiment-categories-themes-wrapper">
      {dividers?.includes(0) ? (
        <Divider
          orientation="vertical"
          className="btns-divider"
          sx={{ marginInlineStart: "20px" }}
        />
      ) : null}

      {!themesData ? null : (
        <ThemesMenu
          btnId={themesData?.btnId}
          tooltipText={themesData?.tooltipText}
          options={themesData?.options || []}
          optionsLoading={themesData?.optionsLoading}
          selectedThemes={themesData?.selected || []}
          handleChange={themesData?.handleChange}
          loading={themesData?.loading}
          disabled={isOnlyView ? false : themesData?.disabled}
          isOnlyView={isOnlyView}
        />
      )}

      {dividers?.includes(1) ? (
        <Divider orientation="vertical" className="btns-divider" />
      ) : null}

      {!categoriesData ? null : (
        <CategoriesMenu
          btnId={categoriesData?.btnId}
          tooltipText={categoriesData?.tooltipText}
          options={categoriesData?.options || []}
          optionsLoading={categoriesData?.optionsLoading}
          selectedCategories={categoriesData?.selected || []}
          handleChange={categoriesData?.handleChange}
          loading={categoriesData?.loading}
          disabled={isOnlyView ? false : categoriesData?.disabled}
          isOnlyView={isOnlyView}
        />
      )}

      {dividers?.includes(2) ? (
        <Divider orientation="vertical" className="btns-divider" />
      ) : null}

      {!sentimentData ? null : (
        <SentimentsMenu
          btnId={sentimentData?.btnId}
          tooltipText={sentimentData?.tooltipText}
          options={sentimentData?.options || {}}
          optionsLoading={sentimentData?.optionsLoading}
          selectedSentiment={sentimentData?.selected}
          handleChange={sentimentData?.handleChange}
          loading={sentimentData?.loading}
          disabled={isOnlyView ? false : sentimentData?.disabled}
          isOnlyView={isOnlyView}
        />
      )}

      {dividers?.includes(3) ? (
        <Divider orientation="vertical" className="btns-divider" />
      ) : null}
    </Box>
  );
};

export default SentimentCategoriesThemes;
