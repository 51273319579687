import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import { Fragment, useContext } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { UserInfoItemList } from "./userInfoItemList";
import {
  nameValidationSchema,
  emailValidationSchema,
  phoneValidationSchema,
} from "../utils/validationSchemas";
import { EditContext } from "../context";

const UserInfoItemGrid = ({ data = [] }) => {
  return (
    <Grid container rowSpacing="14px" columnSpacing="20px">
      {data?.map((item, index) => (
        <Fragment key={index}>
          <Grid item md={4} key={`title-${index}`}>
            <Typography className="engager-user-info-item-list-label">
              {item?.title}
            </Typography>
          </Grid>
          <Grid item md={8} key={`data-${index}`}>
            <Typography className="engager-user-info-item-list-label">
              {item?.data || "-"}
            </Typography>
          </Grid>
        </Fragment>
      ))}
    </Grid>
  );
};

const UserInfo = ({ profileData }) => {
  const intl = useIntl();

  const { isEditing } = useContext(EditContext);

  const userInfoData = [
    {
      title: CheckValueLocale("engager_profile_gender", "", {}, intl),
      data: CheckValueLocale(`${profileData?.gender}_gender`, "", {}, intl),
    },
    {
      title: CheckValueLocale("engager_profile_dialects", "", {}, intl),
      data: CheckValueLocale(profileData?.dialects, "", {}, intl),
    },
    {
      title: CheckValueLocale("engager_profile_locations", "", {}, intl),
      data: CheckValueLocale(profileData?.location?.country, "", {}, intl),
    },
    {
      title: CheckValueLocale("engager_profile_sentiments", "", {}, intl),
      data: CheckValueLocale(profileData?.sentiment, "", {}, intl),
    },
  ];

  return (
    <div>
      <Accordion
        defaultExpanded
        disabled={isEditing}
        classes={{ disabled: "engager-user-info-accordion-disabled" }}
        className="engager-user-info-accordion"
      >
        <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
          <Typography className="engager-user-info-title">
            {CheckValueLocale("engager_profile_user_info_title", "", {}, intl)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box className="engager-user-info-wrapper">
            <UserInfoItemList
              title={CheckValueLocale("engager_profile_name", "", {}, intl)}
              data={profileData?.user_data?.name}
              validationSchema={nameValidationSchema}
              fieldType="name"
              emptyText={CheckValueLocale(
                "engager_profile_name_empty",
                "",
                {},
                intl,
              )}
            />
            <UserInfoItemList
              title={CheckValueLocale(
                "engager_profile_phone_number",
                "",
                {},
                intl,
              )}
              data={profileData?.phone_number}
              fieldType="phone_number"
              validationSchema={phoneValidationSchema}
              emptyText={CheckValueLocale(
                "engager_profile_mobile_number_empty",
                "",
                {},
                intl,
              )}
            />
            <UserInfoItemList
              title={CheckValueLocale("engager_profile_email", "", {}, intl)}
              data={profileData?.email}
              fieldType="email"
              validationSchema={emailValidationSchema}
              emptyText={CheckValueLocale(
                "engager_profile_email_empty",
                "",
                {},
                intl,
              )}
            />
            <UserInfoItemGrid data={userInfoData} />
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default UserInfo;
