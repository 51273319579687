import _ from "lodash";
import { Box, Tooltip, Button, Typography, Divider } from "@mui/material";
import "./replayCard.scss";
import React, { useState, useEffect } from "react";
import PopupModal from "components/popupModal";
import {
  CheckValueLocale,
  isEmptyObj,
  handlelUserRoles,
} from "../../../../../../utils/helpers/index.js";
import { useIntl } from "react-intl";
import sparkIcon from "images/engagements-images/spark.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import Pagination from "@mui/material/Pagination";
import NewReplayPopup from "../../setup/newReplayPopup";
import SnackBar from "components/snackBar.js";
import EngagementsController from "services/controllers/engagementsController";
import mqttRabbitMQResponce from "services/controllers/mqttRabbitMQResponce";
import Services from "services/api/Services";
import ReplayCard from "./replayCard";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OptionsDropDown from "./optionsDropDown";
import LucButton from "shared/lucButton/lucButton";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { useCollections } from "./hooks/useCollections";
import { useReplaies } from "./hooks/useReplaies";
import { useCreateReplaies } from "./hooks/useCreateReplaies";
import { useDelReplaies } from "./hooks/useDelReplaies";
import { useEditReplaies } from "./hooks/useEditReplaies";
import { useCreateCollection } from "./hooks/useCreateCollection";
import { faArrowRightArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useMoveReplaies } from "./hooks/useMoveReplaies";
import { useDelBulkReplaies } from "./hooks/useDelBulkReplaies";
import { useEditCollection } from "./hooks/useEditCollection";
import { useDelCollection } from "./hooks/useDelCollection";
import { useAllCollections } from "./hooks/useAllCollections";

const ReplaySection = ({ selectedData, setSelectedData }) => {
  const intl = useIntl();
  const mqttConnectionClosed = React.useRef(false);
  const clientMQTT = React.useRef();
  const [response, setResponse] = useState({});
  const [expanded, setExpanded] = useState(null);
  const [lastExpanded, setLastExpanded] = useState(null);
  const [repsAiLoading, setRepsAiLoading] = useState(false);
  const [aiPage, setAiPage] = useState(1);
  const [repsPage, setRepsPage] = useState(1);
  const [isReplaiesNextPage, setIsReplaiesNextPage] = useState(false);
  const [repsList, setRepsList] = useState([]);
  const [selectedRepsList, setSelectedRepsList] = useState([]);
  const [snackBar, setSnackBar] = useState({
    open: false,
    title: "",
    message: "",
    severity: "",
  });
  const [paganationAiList, setPaganationAiList] = useState([]);
  const [newRepPopup, setNewRepPopup] = useState({
    open: false,
    id: null,
    isDuplicate: false,
    title: "",
    contant: "",
    collection_id: null,
    isEdit: false,
    isCollection: false,
    ai: false,
    loading: false,
    done: false,
  });
  const [aiList, setAiList] = useState([]);

  //collections
  const [totalReplaies, setTotalReplaies] = useState(0);
  const [isCollectionLimit, setIsCollectionLimit] = useState(true);
  const [isReplayLimit, setIsReplayLimit] = useState(true);
  const [isCollectionNextPage, setIsCollectionNextPage] = useState(false);
  const [collectionPage, setCollectionPage] = useState(1);
  const [collections, setCollections] = useState([]);
  const [allCollections, setAllCollections] = useState([]);

  const [delPopup, setDelPopup] = useState({
    open: false,
    item: {},
    loading: false,
    done: false,
  });

  const { mutate: getAllCollectionsMutation } = useAllCollections();

  const getAllCollections = () => {
    getAllCollectionsMutation(
      { product_id: window?.localStorage?.engagements_id },
      {
        onSuccess: ({ data }) => {
          let arrList = data?.data?.map((item) => item?.attributes) || [];
          setAllCollections([...arrList]);
        },
      },
    );
  };

  const { mutate: getCollectionsMutation, isPending: isCollectionLoading } =
    useCollections();

  const getCollections = (
    pageNumber = collectionPage,
    prevList = [...(collections || [])],
  ) => {
    if (pageNumber == 1) {
      getAllCollections();
      setTotalReplaies(0);
      setIsCollectionLimit(true);
      setIsReplayLimit(true);
      setIsCollectionNextPage(false);
      setCollections([]);
      setExpanded(null);
      setCollectionPage(1);
      setSelectedRepsList([]);
      setRepsList([]);
      setRepsPage(1);
    }
    getCollectionsMutation(
      { page: pageNumber, product_id: window?.localStorage?.engagements_id },
      {
        onSuccess: ({ data }) => {
          setNewRepPopup({
            open: false,
            id: null,
            isDuplicate: false,
            title: "",
            contant: "",
            ai: false,
            loading: false,
            done: false,
          });
          let arrList = data?.data?.map((item) => item?.attributes) || [];
          if (pageNumber == 1 && arrList?.length) {
            setLastExpanded(arrList?.[0]?.id);
            setExpanded(arrList?.[0]?.id);
            arrList?.[0]?.total_saved_replies &&
              getReplaies(1, arrList?.[0]?.id, []);
          }
          setTotalReplaies(data?.total_saved_replies);
          setIsCollectionLimit(data?.collections_limit_reached);
          setIsReplayLimit(data?.saved_replies_limit_reached);
          setIsCollectionNextPage(data?.pagination?.next);
          setCollections([...prevList, ...arrList]);
        },
      },
    );
  };

  const { mutate: getReplaiesMutation, isPending: isReplaiesLoading } =
    useReplaies();

  const getReplaies = (
    pageNumber = repsPage,
    collictionId = expanded,
    prevList = [...(repsList || [])],
  ) => {
    if (pageNumber == 1) {
      setIsReplaiesNextPage(false);
      setRepsList([]);
      setRepsPage(1);
      setSelectedRepsList([]);
    }
    getReplaiesMutation(
      {
        page: pageNumber,
        product_id: window?.localStorage?.engagements_id,
        id: collictionId,
      },
      {
        onSuccess: ({ data }) => {
          setNewRepPopup({
            open: false,
            id: null,
            isDuplicate: false,
            title: "",
            contant: "",
            ai: false,
            loading: false,
            done: false,
          });
          let arrList = data?.data?.map((item) => item?.attributes) || [];
          setIsReplaiesNextPage(data?.pagination?.next);
          setRepsList([...prevList, ...arrList]);
        },
      },
    );
  };

  const { mutate: createReplaiesMutation } = useCreateReplaies();

  const handleCreateReplies = () => {
    createReplaiesMutation(
      {
        product_id: window?.localStorage?.engagements_id,
        title: newRepPopup?.title?.trim(),
        text: newRepPopup?.contant?.trim(),
        collection_id: newRepPopup?.collection_id,
      },
      {
        onSuccess: ({ data }) => {
          if (newRepPopup?.ai) {
            handleAddAirep(newRepPopup?.id);
          }
          setRepsPage(1);
          setCollectionPage(1);
          getCollections(1, []);
          setSelectedRepsList([]);

          setSnackBar({
            open: true,
            title: "",
            message: "new_rep_added",
            severity: "success",
          });
        },
        onError: (error) => {
          let isDuplicate =
            error?.response?.data?.exception?.message ==
            "DUPLICATE_REPLY_FOUND";
          if (isDuplicate) {
            setNewRepPopup({
              ...newRepPopup,
              isDuplicate: true,
              loading: false,
              done: false,
            });
          } else {
            setNewRepPopup({
              open: false,
              id: null,
              isDuplicate: false,
              title: "",
              contant: "",
              ai: false,
              loading: false,
              done: false,
            });
            setSnackBar({
              open: true,
              title: "wrong_request_parameter",
              message: "please_try_again",
              severity: "error",
            });
          }
        },
      },
    );
    setTimeout(() => {
      setSnackBar({
        open: false,
        title: "",
        message: "",
        severity: "",
      });
    }, 7000);
  };

  const { mutate: moveReplaiesMutation } = useMoveReplaies();

  const handleMoveReplies = () => {
    const clonedSelectedLength = _.cloneDeep(
      newRepPopup?.isBulk
        ? newRepPopup?.selectedRepsCount
        : selectedRepsList?.length,
    );
    moveReplaiesMutation(
      {
        product_id: window?.localStorage?.engagements_id,
        id: newRepPopup?.fromCollection,
        target_collection_id: newRepPopup?.collection_id,
        saved_reply_ids: newRepPopup?.isBulk ? [] : selectedRepsList || [],
      },
      {
        onSuccess: ({ data }) => {
          setRepsPage(1);
          setCollectionPage(1);
          getCollections(1, []);

          setSnackBar({
            open: true,
            title: "",
            message: "move_rep_success",
            num: clonedSelectedLength,
            severity: "success",
          });
          setSelectedRepsList([]);
        },
        onError: (error) => {
          setNewRepPopup({
            open: false,
            id: null,
            isDuplicate: false,
            title: "",
            contant: "",
            ai: false,
            loading: false,
            done: false,
          });
          setSnackBar({
            open: true,
            title: "wrong_request_parameter",
            message: "please_try_again",
            severity: "error",
          });
        },
      },
    );
    setTimeout(() => {
      setSnackBar({
        open: false,
        title: "",
        message: "",
        severity: "",
      });
    }, 7000);
  };

  const { mutate: createCollectionsMutation } = useCreateCollection();

  const handleCreateCollection = () => {
    createCollectionsMutation(
      {
        product_id: window?.localStorage?.engagements_id,
        name: newRepPopup?.title?.trim(),
      },
      {
        onSuccess: ({ data }) => {
          setCollections([]);
          setRepsPage(1);
          setCollectionPage(1);
          getCollections(1, []);

          setSnackBar({
            open: true,
            title: "",
            message: "new_collection_added",
            severity: "success",
          });
          setSelectedRepsList([]);
        },
        onError: (error) => {
          let isDuplicate =
            error?.response?.data?.exception?.message ==
            "COLLECTION_NAME_EXISTS";
          if (isDuplicate) {
            setNewRepPopup({
              ...newRepPopup,
              isDuplicate: true,
              loading: false,
              done: false,
            });
          } else {
            setNewRepPopup({
              open: false,
              id: null,
              isDuplicate: false,
              title: "",
              contant: "",
              ai: false,
              loading: false,
              done: false,
            });
            setSnackBar({
              open: true,
              title: "wrong_request_parameter",
              message: "please_try_again",
              severity: "error",
            });
          }
        },
      },
    );
    setTimeout(() => {
      setSnackBar({
        open: false,
        title: "",
        message: "",
        severity: "",
      });
    }, 7000);
  };

  const { mutate: editCollectionsMutation } = useEditCollection();

  const handleEditCollection = () => {
    editCollectionsMutation(
      {
        product_id: window?.localStorage?.engagements_id,
        name: newRepPopup?.title?.trim(),
        id: newRepPopup?.editId,
      },
      {
        onSuccess: ({ data }) => {
          setCollections([]);
          setRepsPage(1);
          setCollectionPage(1);
          getCollections(1, []);

          setSnackBar({
            open: true,
            title: "",
            message: "rep_updated_success",
            severity: "success",
          });
          setSelectedRepsList([]);
        },
        onError: (error) => {
          let isDuplicate =
            error?.response?.data?.exception?.message ==
            "COLLECTION_NAME_EXISTS";
          if (isDuplicate) {
            setNewRepPopup({
              ...newRepPopup,
              isDuplicate: true,
              loading: false,
              done: false,
            });
          } else {
            setNewRepPopup({
              open: false,
              id: null,
              isDuplicate: false,
              title: "",
              contant: "",
              ai: false,
              loading: false,
              done: false,
            });
            setSnackBar({
              open: true,
              title: "wrong_request_parameter",
              message: "please_try_again",
              severity: "error",
            });
          }
        },
      },
    );
    setTimeout(() => {
      setSnackBar({
        open: false,
        title: "",
        message: "",
        severity: "",
      });
    }, 7000);
  };

  const { mutate: delCollectionMutation } = useDelCollection();

  const handleDeleteCollection = () => {
    setDelPopup({
      ...delPopup,
      loading: true,
    });
    delCollectionMutation(
      {
        product_id: window?.localStorage?.engagements_id,
        id: delPopup?.delId,
      },
      {
        onSuccess: ({ data }) => {
          setRepsPage(1);
          setCollectionPage(1);
          getCollections(1, []);
          setSnackBar({
            open: true,
            title: "",
            message: "collection_deleted_bulk",
            severity: "success",
          });
          setDelPopup({
            item: {},
            open: false,
            loading: false,
          });
          setSelectedRepsList([]);
        },
        onError: (error) => {
          setSnackBar({
            open: true,
            title: "wrong_request_parameter",
            message: "please_try_again",
            severity: "error",
          });
          setDelPopup({
            item: {},
            open: false,
            loading: false,
          });
        },
      },
    );
    setTimeout(() => {
      setSnackBar({
        open: false,
        title: "",
        message: "",
        severity: "",
      });
    }, 7000);
  };
  const { mutate: delReplaiesMutation } = useDelReplaies();

  const handleDeleteSavedReplies = () => {
    setDelPopup({
      ...delPopup,
      loading: true,
    });
    delReplaiesMutation(
      {
        product_id: window?.localStorage?.engagements_id,
        id: delPopup?.item?.id,
      },
      {
        onSuccess: ({ data }) => {
          setRepsPage(1);
          setCollectionPage(1);
          getCollections(1, []);
          setSelectedRepsList([]);

          setSnackBar({
            open: true,
            title: "",
            message: "rep_deleted",
            severity: "success",
          });
          setDelPopup({
            item: {},
            open: false,
            loading: false,
          });
        },
        onError: (error) => {
          setSnackBar({
            open: true,
            title: "wrong_request_parameter",
            message: "please_try_again",
            severity: "error",
          });
          setDelPopup({
            item: {},
            open: false,
            loading: false,
          });
        },
      },
    );
    setTimeout(() => {
      setSnackBar({
        open: false,
        title: "",
        message: "",
        severity: "",
      });
    }, 7000);
  };

  const { mutate: delBulkReplaiesMutation } = useDelBulkReplaies();

  const handleDeleteBulkReplies = () => {
    const clonedSelectedLength = _.cloneDeep(
      delPopup?.isBulk
        ? collections?.filter(
            (item) => item?.id == delPopup?.fromCollection,
          )?.[0]?.total_saved_replies
        : selectedRepsList?.length,
    );

    setDelPopup({
      ...delPopup,
      loading: true,
    });
    delBulkReplaiesMutation(
      {
        product_id: window?.localStorage?.engagements_id,
        id: delPopup?.fromCollection,
        saved_reply_ids: delPopup?.isBulk ? [] : selectedRepsList || [],
      },
      {
        onSuccess: ({ data }) => {
          setRepsPage(1);
          setCollectionPage(1);
          getCollections(1, []);
          setSnackBar({
            open: true,
            title: "",
            message: "rep_deleted_bulk",
            severity: "success",
            num: clonedSelectedLength,
          });
          setDelPopup({
            item: {},
            open: false,
            loading: false,
          });
          setSelectedRepsList([]);
        },
        onError: (error) => {
          setSnackBar({
            open: true,
            title: "wrong_request_parameter",
            message: "please_try_again",
            severity: "error",
          });
          setDelPopup({
            item: {},
            open: false,
            loading: false,
          });
        },
      },
    );
    setTimeout(() => {
      setSnackBar({
        open: false,
        title: "",
        message: "",
        severity: "",
      });
    }, 7000);
  };

  const { mutate: editReplaiesMutation } = useEditReplaies();

  const handleEditReplies = () => {
    editReplaiesMutation(
      {
        product_id: window?.localStorage?.engagements_id,
        id: newRepPopup?.id,
        title: newRepPopup?.title?.trim(),
        text: newRepPopup?.contant?.trim(),
        collection_id: newRepPopup?.collection_id,
      },
      {
        onSuccess: ({ data }) => {
          setRepsPage(1);
          setCollectionPage(1);
          getCollections(1, []);
          setSelectedRepsList([]);

          setSnackBar({
            open: true,
            title: "",
            message: "rep_updated_success",
            severity: "success",
          });
        },
        onError: (error) => {
          let isDuplicate =
            error?.response?.data?.exception?.message ==
            "DUPLICATE_REPLY_FOUND";
          if (isDuplicate) {
            setNewRepPopup({
              ...newRepPopup,
              isDuplicate: true,
              loading: false,
              done: false,
            });
          } else {
            setNewRepPopup({
              open: false,
              id: null,
              isDuplicate: false,
              title: "",
              contant: "",
              ai: false,
              loading: false,
              done: false,
            });
            setSnackBar({
              open: true,
              title: "wrong_request_parameter",
              message: "please_try_again",
              severity: "error",
            });
          }
        },
      },
    );
    setTimeout(() => {
      setSnackBar({
        open: false,
        title: "",
        message: "",
        severity: "",
      });
    }, 7000);
  };

  const delBody = () => {
    return (
      <Box className="shared-dialog-body">
        {delPopup?.fromCollection || delPopup?.delId ? (
          <p>
            {`${CheckValueLocale(
              delPopup?.delId
                ? "delete_collection_body_bulk"
                : "delete_saved_replies_body_bulk",
              "",
              {
                name: collections?.filter((item) =>
                  delPopup?.delId
                    ? item?.id == delPopup?.delId
                    : item?.id == delPopup?.fromCollection,
                )?.[0]?.name,
                num: delPopup?.count || 0,
              },
              intl,
            )}`}
          </p>
        ) : null}
        <p>
          {`${CheckValueLocale(delPopup?.fromCollection ? "delete_saved_replay_bulk" : delPopup?.delId ? "delete_collection_bulk" : "delete_saved_replies_body_engag", "", { name: delPopup?.item?.title }, intl)}`}
        </p>
        <h2 className="sub-text-desc">
          {CheckValueLocale("deleting_msg", "", {}, intl)}
        </h2>
      </Box>
    );
  };

  const getAiReplais = () => {
    setRepsAiLoading(true);
    EngagementsController.getAiReplaies(
      selectedData?.stepTwo?.luci_industry,
      selectedData?.stepTwo?.luci_business_type,
      selectedData?.stepTwo?.luci_business_hq,
      selectedData?.stepTwo?.brand_tone,
      selectedData?.stepTwo?.aboutYour,
      selectedData?.stepTwo?.knowledge_base_url,
      window?.localStorage?.engagements_id,
    ).then((data) => {
      if (data?.data?.data?.password && !isEmptyObj(data?.data?.data)) {
        setResponse({});
        // connectWithRabbitMQ(
        //   mqttConnectionClosed,
        //   clientMQTT,
        //   data?.data?.data,
        //   setResponse
        // );
        mqttRabbitMQResponce(clientMQTT, data?.data?.data, setResponse);
      } else {
        setRepsAiLoading(false);
        mqttConnectionClosed.current = true;
      }
    });
  };

  useEffect(() => {
    if (response?.responses) {
      if (response?.responses?.length) {
        let arr = [];
        response?.responses?.map((rep, i) => arr.push({ id: i, text: rep }));
        setAiPage(1);
        setAiList([...arr]);
      } else {
        setRepsAiLoading(false);
      }
    }
  }, [response]);

  useEffect(() => {
    getCollections(1, []);
  }, []);

  useEffect(() => {
    if (newRepPopup?.done) {
      if (newRepPopup?.isEdit) {
        handleEditReplies();
      } else if (newRepPopup?.isCollection) {
        if (newRepPopup?.editId) {
          handleEditCollection();
        } else {
          handleCreateCollection();
        }
      } else if (newRepPopup?.fromCollection) {
        handleMoveReplies();
      } else {
        handleCreateReplies();
      }
    }
  }, [newRepPopup]);

  useEffect(() => {
    let newArr = [];
    let inatialArr = [...aiList];
    while (inatialArr?.length) newArr.push(inatialArr?.splice(0, 8));
    setPaganationAiList([...newArr]);
    setRepsAiLoading(false);
  }, [aiList]);

  const handleAddAirep = (id) => {
    if (paganationAiList[aiPage - 1]?.length == 1) {
      setAiPage(aiPage == 1 ? aiPage : aiPage - 1);
    }
    setAiList([...aiList?.filter((i) => i?.id !== id)]);
  };

  const handleChangeAiPage = (event, page) => {
    if (Boolean(page) === false) return;
    if (paganationAiList[page - 1]?.length) {
      setAiPage(page);
    } else {
      setAiPage(page - 1);
    }
  };

  let isCollectionOptions = (name) => {
    return "Uncategorized" != name
      ? handlelUserRoles("COLLECTION_MANAGEMENT", "EDIT_COLLECTION") ||
          handlelUserRoles("COLLECTION_MANAGEMENT", "FULL_ACCESS") ||
          handlelUserRoles("COLLECTION_MANAGEMENT", "DELETE_COLLECTION") ||
          handlelUserRoles("SAVED_REPLIES", "DELETE_SAVED_REPLY") ||
          handlelUserRoles("SAVED_REPLIES", "FULL_ACCESS")
      : handlelUserRoles("SAVED_REPLIES", "DELETE_SAVED_REPLY") ||
          handlelUserRoles("SAVED_REPLIES", "FULL_ACCESS") ||
          handlelUserRoles("COLLECTION_MANAGEMENT", "EDIT_COLLECTION") ||
          handlelUserRoles("COLLECTION_MANAGEMENT", "FULL_ACCESS");
  };
  return (
    <Box className="main-step-three-replay-section">
      <Box className="reps-list">
        <Box className="reps-list-header">
          <Box>
            {CheckValueLocale("saved_reps", "", { num: totalReplaies }, intl)}
          </Box>
          <Box className="reps-list-header-btns">
            {handlelUserRoles("SAVED_REPLIES", "CREATE_SAVED_REPLY") ||
            handlelUserRoles("SAVED_REPLIES", "FULL_ACCESS") ? (
              <Tooltip
                title={
                  isReplayLimit
                    ? CheckValueLocale("limit_saved_replais", "", {}, intl)
                    : null
                }
                placement="top"
                arrow
              >
                <Box>
                  <LucButton
                    id="engagement-settings-new-reply-btn"
                    variant="outline"
                    startIcon={<FontAwesomeIcon icon={faPlus} />}
                    disabled={isReplayLimit}
                    onClick={() =>
                      setNewRepPopup({
                        open: true,
                        id: null,
                        isDuplicate: false,
                        title: "",
                        contant: "",
                        ai: false,
                        loading: false,
                        done: false,
                      })
                    }
                  >
                    {CheckValueLocale("new_rep", "", {}, intl)}
                  </LucButton>
                </Box>
              </Tooltip>
            ) : null}
            {handlelUserRoles("COLLECTION_MANAGEMENT", "CREATE_COLLECTION") ||
            handlelUserRoles("COLLECTION_MANAGEMENT", "FULL_ACCESS") ? (
              <Tooltip
                title={
                  isCollectionLimit
                    ? CheckValueLocale("limit_saved_collections", "", {}, intl)
                    : null
                }
                placement="top"
                arrow
              >
                <Box>
                  <LucButton
                    id="engagement-settings-new-collection-btn"
                    variant="outline"
                    startIcon={<FontAwesomeIcon icon={faPlus} />}
                    disabled={isCollectionLimit}
                    onClick={() =>
                      setNewRepPopup({
                        open: true,
                        id: null,
                        isDuplicate: false,
                        title: "",
                        contant: "",
                        ai: false,
                        loading: false,
                        isCollection: true,
                        done: false,
                      })
                    }
                  >
                    {CheckValueLocale("new_collection", "", {}, intl)}
                  </LucButton>
                </Box>
              </Tooltip>
            ) : null}
          </Box>
        </Box>
        <Box className="reps-list-contant">
          {!collections?.length ? (
            <Box className="fetching-loader">
              <FontAwesomeIcon icon={faSpinnerThird} spin />
            </Box>
          ) : (
            <Box className="replay-section-accordion">
              {collections?.map((collection) => (
                <Accordion
                  key={collection?.id}
                  expanded={expanded === collection?.id}
                  onChange={() => {
                    if (
                      expanded !== collection?.id &&
                      collection?.id !== lastExpanded
                    ) {
                      setIsReplaiesNextPage(false);
                      setRepsList([]);
                      setRepsPage(1);
                      setSelectedRepsList([]);
                      collection?.total_saved_replies &&
                        getReplaies(1, collection?.id, []);
                    }
                    setLastExpanded(collection?.id);
                    setExpanded(
                      expanded === collection?.id ? null : collection?.id,
                    );
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className="accordion-header" component="span">
                      {collection?.name}
                      <span>({collection?.total_saved_replies})</span>
                    </Typography>
                    {isCollectionOptions(collection?.name) ? (
                      <OptionsDropDown
                        repsCount={collection?.total_saved_replies}
                        isUncategorized={"Uncategorized" == collection?.name}
                        item={collection}
                        setNewRepPopup={setNewRepPopup}
                        setDelPopup={setDelPopup}
                      />
                    ) : (
                      <Box className="menu-hidden"></Box>
                    )}
                  </AccordionSummary>
                  <AccordionDetails>
                    <Divider className="collaps-hr" />
                    {selectedRepsList?.length ? (
                      <Box className="multi-selected-replaies">
                        <Box>
                          {CheckValueLocale(
                            "selected_replaies",
                            "",
                            { num1: selectedRepsList?.length, num2: 10 },
                            intl,
                          )}
                        </Box>
                        <Box className="multi-actions-replaies">
                          {handlelUserRoles(
                            "COLLECTION_MANAGEMENT",
                            "EDIT_COLLECTION",
                          ) ||
                          handlelUserRoles(
                            "COLLECTION_MANAGEMENT",
                            "FULL_ACCESS",
                          ) ? (
                            <>
                              <Tooltip
                                title={CheckValueLocale(
                                  "move_to_collection",
                                  "",
                                  {},
                                  intl,
                                )}
                                placement="bottom"
                                arrow
                              >
                                <Box
                                  className="multi-actions-btn"
                                  onClick={(event) => {
                                    event?.stopPropagation?.();
                                    setNewRepPopup({
                                      open: true,
                                      collection_id: null,
                                      fromCollection: expanded,
                                      selectedRepsCount:
                                        selectedRepsList?.length,
                                      id: null,
                                      isDuplicate: false,
                                      title: "",
                                      contant: "",
                                      ai: false,
                                      loading: false,
                                      done: false,
                                    });
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faArrowRightArrowLeft}
                                  />
                                </Box>
                              </Tooltip>
                              <Divider orientation="vertical" />
                            </>
                          ) : null}

                          {handlelUserRoles(
                            "SAVED_REPLIES",
                            "DELETE_SAVED_REPLY",
                          ) ||
                          handlelUserRoles("SAVED_REPLIES", "FULL_ACCESS") ? (
                            <>
                              <Tooltip
                                title={CheckValueLocale(
                                  "dashboard_delete",
                                  "",
                                  {},
                                  intl,
                                )}
                                placement="bottom"
                                arrow
                              >
                                <Box
                                  className="multi-actions-btn"
                                  onClick={(event) => {
                                    event?.stopPropagation?.();
                                    setDelPopup({
                                      open: true,
                                      fromCollection: expanded,
                                      count: selectedRepsList?.length,
                                    });
                                  }}
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </Box>
                              </Tooltip>
                              <Divider orientation="vertical" />
                            </>
                          ) : null}

                          <LucButton
                            onClick={() => setSelectedRepsList([])}
                            id="unselect-replaies"
                            variant="flat"
                            size="small"
                          >
                            {CheckValueLocale(
                              "unselect_replaies",
                              "",
                              {},
                              intl,
                            )}
                          </LucButton>
                        </Box>
                      </Box>
                    ) : null}
                    {!isReplaiesLoading && !repsList.length ? (
                      <Box className="empty-loading-reps-list">
                        {CheckValueLocale("saved_reps_empty", "", {}, intl)}
                      </Box>
                    ) : isReplaiesLoading && repsPage == 1 ? (
                      <Box className="empty-loading-reps-list">
                        <FontAwesomeIcon icon={faSpinnerThird} spin />
                      </Box>
                    ) : (
                      <>
                        <Box className="list-container">
                          {repsList?.map((item) => (
                            <ReplayCard
                              collections={collections}
                              selectedRepsList={selectedRepsList}
                              setSelectedRepsList={setSelectedRepsList}
                              item={item}
                              setNewRepPopup={setNewRepPopup}
                              setRepsPage={setRepsPage}
                              isReplayLimit={isReplayLimit}
                              setDelPopup={setDelPopup}
                              collectionId={expanded}
                            />
                          ))}
                        </Box>
                        {isReplaiesNextPage ? (
                          <Box className="replaies-page-counter">
                            <LucButton
                              onClick={() => {
                                setRepsPage(repsPage + 1);
                                getReplaies(repsPage + 1, expanded);
                              }}
                              variant="outline"
                              disabled={isReplaiesLoading}
                              loading={isReplaiesLoading}
                              id="replaies-load-more"
                            >
                              {CheckValueLocale("see_more_page", "", {}, intl)}
                            </LucButton>
                          </Box>
                        ) : null}
                      </>
                    )}
                  </AccordionDetails>
                </Accordion>
              ))}
              {isCollectionNextPage ? (
                <Box className="collection-page-counter">
                  <LucButton
                    onClick={() => {
                      setCollectionPage(collectionPage + 1);
                      getCollections(collectionPage + 1);
                    }}
                    variant="outline"
                    disabled={isCollectionLoading}
                    loading={isCollectionLoading}
                    id="collection-load-more"
                  >
                    {CheckValueLocale("see_more_page", "", {}, intl)}
                  </LucButton>
                </Box>
              ) : null}
            </Box>
          )}
        </Box>
      </Box>
      <Box className="ai-list">
        <Box
          className={
            paganationAiList?.length
              ? "ai-list-header not-empty"
              : "ai-list-header"
          }
        >
          <Box className="ai-header-title">
            <Box className="header-title-main">
              {CheckValueLocale("ai_res", "", {}, intl)}
            </Box>
            {Services.showThirdCompany ? (
              <Box className="header-title-sub">
                {CheckValueLocale("ai_prag", "", {}, intl)}
              </Box>
            ) : null}
          </Box>
          <Button
            disabled={
              repsAiLoading ||
              !selectedData?.stepTwo?.luci_industry?.length ||
              !selectedData?.stepTwo?.luci_business_type?.length ||
              !selectedData?.stepTwo?.luci_business_hq?.length ||
              !selectedData?.stepTwo?.brand_tone?.length ||
              !selectedData?.stepTwo?.aboutYour?.length ||
              !selectedData?.stepTwo?.knowledge_base_url?.length
            }
            id="engagement-settings-suggest-responses-btn"
            className="ai-sug-btn"
            onClick={() => getAiReplais()}
          >
            {repsAiLoading ? (
              <FontAwesomeIcon icon={faSpinnerThird} spin />
            ) : (
              <img src={sparkIcon} />
            )}

            <Box>
              {CheckValueLocale(
                !paganationAiList?.length
                  ? repsAiLoading
                    ? "genrate"
                    : "ai_sug"
                  : repsAiLoading
                    ? "genrate"
                    : "re_gen",
                "",
                {},
                intl,
              )}
            </Box>
          </Button>
        </Box>
        {paganationAiList?.length ? (
          <Box className="ai-list-contant">
            <Box>
              {CheckValueLocale("ai_reps", "", { num: aiList?.length }, intl)}
            </Box>
            <Box className="list-container">
              {paganationAiList[aiPage - 1]?.map((item) => (
                <ReplayCard
                  item={item}
                  isAi={true}
                  setNewRepPopup={setNewRepPopup}
                  totalReplaies={totalReplaies}
                />
              ))}
            </Box>
            <Box className="reps-list-pagonation">
              <Pagination
                onChange={handleChangeAiPage}
                page={aiPage}
                count={paganationAiList?.length}
                variant="outlined"
              />
            </Box>
          </Box>
        ) : null}
      </Box>
      {newRepPopup?.open ? (
        <NewReplayPopup
          newRepPopup={newRepPopup}
          setNewRepPopup={setNewRepPopup}
          collections={allCollections}
        />
      ) : null}
      {delPopup?.open ? (
        <PopupModal
          title={CheckValueLocale(
            delPopup?.fromCollection
              ? "delete_saved_replies_bulk"
              : delPopup?.delId
                ? "delete_collection_tit"
                : "delete_saved_replies_engag",
            "",
            {},
            intl,
          )}
          body={delBody()}
          leftBtn={CheckValueLocale("cancel", "", {}, intl)}
          rightBtn={CheckValueLocale("delete_confirm", "", {}, intl)}
          open={delPopup?.open}
          close={(event) => {
            event?.stopPropagation?.();

            !delPopup?.loading &&
              setDelPopup({
                open: false,
                item: {},
                loading: false,
              });
          }}
          disableLeftBtn={delPopup?.loading}
          disabled={delPopup?.loading}
          acceptOtherOption={(event) => {
            event?.stopPropagation?.();

            setDelPopup({
              item: {},
              open: false,
              loading: false,
            });
          }}
          accept={(event) => {
            event?.stopPropagation?.();
            setDelPopup({
              ...delPopup,
              loading: true,
            });
            if (delPopup?.fromCollection) {
              handleDeleteBulkReplies();
            } else if (delPopup?.delId) {
              handleDeleteCollection();
            } else {
              handleDeleteSavedReplies();
            }
          }}
          rightBtnId="delete-saved-reply-engag"
          leftBtnId="cancel-saved-reply-engag"
          warning
          minWidth="600px"
          classeName="actions-buttons-shared"
          isRightBtnLoading={delPopup?.loading}
        />
      ) : null}
      <SnackBar
        open={snackBar?.open}
        severity={snackBar?.severity}
        message={CheckValueLocale(
          snackBar?.message,
          "",
          { num: snackBar?.num || 0 },
          intl,
        )}
        title={CheckValueLocale(snackBar?.title, "", {}, intl)}
      />
    </Box>
  );
};

export default ReplaySection;
