import { useQuery } from "@tanstack/react-query";
import { MainMonitorsPage } from "services/api/mainMonitorsPage";

export const useDeepInsightData = (
  monitorDataSource,
  monitorId,
  productId,
  username,
  userAccountId,
  capCount,
  startDate,
  endDate,
) => {
  return useQuery({
    queryKey: ["deepInsightData"],
    queryFn: async () =>
      MainMonitorsPage.getDeepInsightData(monitorDataSource, monitorId, {
        product_id: productId,
        username,
        user_account_id: userAccountId,
        cap_count: capCount,
        start_date: startDate,
        end_date: endDate,
      }),

    select: (data) => data?.data,
  });
};
