import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Box, Tabs, Tab, Divider, Tooltip } from "@mui/material";
import "./sideBarTabs.scss";
import {
  CheckValueLocale,
  classNames,
  handlelUserRoles,
  handleShowTabsCounter,
} from "utils/helpers";
import TabContent from "./tabContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faSpinnerThird,
  faUserPlus,
} from "@fortawesome/pro-regular-svg-icons";
import { allowedTabsChange } from "../hooks/constant";
import { useTabsContentCounter } from "../hooks/useTabsContentCounter";
import { useEngagementTabs } from "pages/engagements/providers/engagementsTabsProvider";
import { useSelector, useDispatch } from "react-redux";
import {
  bulkData,
  cardsAssignAndStateData,
  slaData,
} from "utils/redux/features/Engagements/EngagementsSlice";
import EngagementComplete from "../../details/engagementHeader/engagementComplete";
import EngagementsController from "services/controllers/engagementsController";
import { LockedEngagement } from "../../replyModal/components";
import { useBulkComplete } from "./bulkApisHooks/useBulkComplete";
import { useBulkAssign } from "./bulkApisHooks/useBulkAssign";
import SnackBar from "components/snackBar";

export const tabsNameGeneral = [
  {
    name: "all",
    key: "all",
  },
  {
    name: "new",
    key: "new",
  },
  {
    name: "active_status",
    key: "active",
  },
  {
    name: "complete",
    key: "complete",
  },
];
export const tabsNameAiAgent = [
  {
    name: "all",
    key: "all",
  },
  {
    name: "active_status",
    key: "active",
  },
  {
    name: "complete",
    key: "complete",
  },
];
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const shouldShowLoading = (
  countLoading,
  filterTypeChange,
  isCurrentTab,
  isInitialLoad,
) => {
  // we show loading in the tab if the following conditions are met
  return (
    // if the countLoading is true
    countLoading &&
    // if the countLoading is true we will show the loading if one of the following conditions are met
    // 1- filterTypeChange if is not included in the allowedTabsChange this means it the tab should be loading
    //    e.g. when the user clicks on referesh btn, change the filters, or change the datapicker
    (!allowedTabsChange.includes(filterTypeChange) ||
      // 2- if we are in the current tab we should show a loading
      isCurrentTab ||
      // 3- if it's the first time the component is loaded we should show a loading
      isInitialLoad)
  );
};

const SideBarTabs = ({
  setFilters,
  filters,
  applyFilters,
  response,
  allData,
  preLoader,
  tabValue,
  setTabValue,
  completionContent,
  valueInput,
  completionReason,
  setLastOperation,
  setEngagementPostId,
  setSlaCounterData,
  slaCounterData,
  setOpenSidebarToAssignTeam,
  aiAgentList,
  setAiAgentList,
  setIsAiAgentDown,
  setIsLimitReached,
  setIsDMLimitReached,
  setAiAgentDMReachDetails,
  setIsSlaInitialLoad,
  filterTypeChange,
  setDataSourceCount,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const {
    setCardState,
    engagementTabs,
    setEngagementTabs,
    setActiveEngagement,
  } = useEngagementTabs();
  const [countLoading, setCountLoading] = useState(true);
  const [itemsData, setItemsData] = useState([]);
  // hasNextPage will be true if there are more engagements to load
  // it will be returned from the event "EngagementsProductPage" in the TabContent component
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasCompletAccess, setHasCompletAccess] = useState(false);
  const [isOnecompleted, setIsOnecompleted] = useState(true);
  const [numberOfUncomplete, setNumberOfUncomplete] = useState(0);
  const { tabsContentCount, setTabsContentCount, resetTabsContentCount } =
    useTabsContentCounter(filters, countLoading);

  const [openCompletePopup, setOpenCompletePopup] = useState(false);
  const [openAssignPopup, setOpenAssignPopup] = useState(false);
  const [completionReasonBulk, setCompletionReasonBulk] = useState("");
  const [valueInputBulk, setValueInputBulk] = useState("");
  const [completionContentBulk, setCompletionContentBulk] = useState("");
  const [sendSurvey, setSendSurvey] = useState(false);
  const [disabledAction, setDisabledAction] = useState(false);
  const [snackBar, setSnackBar] = useState({
    openSnackBar: false,
    severity: null,
    title: "",
    message: "",
  });

  const isTeamLeader = handlelUserRoles("ENGAGEMENTS", "ENGAGEMENT_SETTINGS");

  const reduxBulkData = useSelector(
    (state) => state?.engagements?.selectedCardsBulk,
  );
  const reduxSlaData = useSelector((state) => state?.engagements?.sla);
  const reduxCardsAssignAndState = useSelector(
    (state) => state?.engagements?.cardsAssignAndState,
  );
  const userSignedIn = localStorage?.getItem("user_id");

  const { mutate: bulkCompleteUpdate } = useBulkComplete();
  const { mutate: bulkAssignUpdate } = useBulkAssign();

  const CompletionReasonsApi = () => {
    EngagementsController.getCompletionReasons(
      window.localStorage.getItem("engagementsProductId"),
    ).then((result) => {
      setCompletionContentBulk(result?.data?.completion_reason_tooltips);
    });
  };
  useEffect(() => {
    CompletionReasonsApi();
  }, []);

  useEffect(() => {
    let updatedCardstates = { ...reduxCardsAssignAndState };
    Object.keys(reduxSlaData)?.map((key) => {
      updatedCardstates[key] = {
        ...updatedCardstates[key],
        userId: reduxSlaData[key]?.userId,
        teamId: reduxSlaData[key]?.teamId,
      };
    });
    dispatch(
      cardsAssignAndStateData({
        ...updatedCardstates,
      }),
    );
  }, [reduxSlaData]);

  useEffect(() => {
    // handle update assign
    let updatedReduxBulkData = reduxBulkData?.map((item) => {
      return {
        ...item,
        ...reduxCardsAssignAndState[item?.mainId],
      };
    });
    dispatch(bulkData(updatedReduxBulkData));
  }, [reduxCardsAssignAndState]);

  useEffect(() => {
    let isOneUnassigned = false;
    let isOnecompleted = false;
    let uncompletedCount = 0;
    reduxBulkData?.map((item) => {
      if (+userSignedIn != +item?.userId) {
        isOneUnassigned = true;
      }
      if (item?.statusItemCard != "complete") {
        ++uncompletedCount;
      } else {
        isOnecompleted = true;
      }
    });
    setHasCompletAccess(!isOneUnassigned);
    setIsOnecompleted(isOnecompleted);
    setNumberOfUncomplete(uncompletedCount);
  }, [reduxBulkData]);

  useEffect(() => {
    let updatedReduxBulkData = [];
    reduxBulkData?.map((item) => {
      let isExist = itemsData?.filter(
        (mainItem) =>
          item?.mainId ==
          `${mainItem?.tweet_id ? mainItem?.tweet_id : mainItem?.id}-${
            mainItem?.tracker_id
          }`,
      )?.length;
      if (isExist) {
        updatedReduxBulkData.push({ ...item });
      }
    });
    dispatch(bulkData(updatedReduxBulkData));
  }, [itemsData]);

  useEffect(() => {
    setCardState((prev = {}) => ({
      ...prev,
      activeTab: filters.engagement_status,
    }));
    setHasNextPage(false);
    setLastOperation(0);
    setEngagementPostId(null);

    setCountLoading(true);
    setSlaCounterData({ ...slaCounterData, loading: true });
    if (filters.engagement_status == "all") {
      setTabValue(0);
    }
  }, [filters.engagement_status]);

  const updateStatus = (status, closeTap) => {
    const activeTab = filters.engagement_status;
    let updatedCardsTabs = [...engagementTabs];
    if (!disabledAction) {
      setDisabledAction(true);
      bulkCompleteUpdate(
        {
          send_survey: sendSurvey,
          product_id: window.localStorage.getItem("engagementsProductId"),
          engagements: reduxBulkData?.map((item) => {
            return {
              agent_id: item?.isDm ? item?.agent_id : undefined,
              assigned_team_id: item?.teamId,
              assigned_user_id: item?.userId,
              client_id: item?.isDm ? item?.client_id : undefined,
              last_message_text: item?.isDm
                ? reduxSlaData[item?.mainId]?.cardText
                : undefined,
              completion_reason: completionReasonBulk,
              conversation_created_at: item?.created_at,
              conversation_status: item?.isDm ? item?.status : undefined,
              data_source_name: item?.data_source?.toUpperCase(),
              interaction_id: item?.system_replies?.interaction_id || 0,
              monitor_id: item?.tracker_id,
              other_reason: valueInputBulk,
              parent_id: item?.tweet_id || item?.id,
              product_id: window.localStorage.getItem("engagementsProductId"),
              routing_id: item?.sla_info?.routing_id,
              sla_id: item?.sla_info?.sla_id,
              status: "complete",
            };
          }),
        },
        {
          onSuccess: (data) => {
            let cardsIds = [];
            let changedReduxBulkData = [...reduxBulkData];
            if (data?.data?.invalid_interactions?.length) {
              let ids = data?.data?.invalid_interactions?.map(
                (item) => `${item?.interaction_id}-${item?.monitor_id}`,
              );
              changedReduxBulkData = reduxBulkData?.filter(
                (item) => !ids?.includes(item?.mainId),
              );
            }
            changedReduxBulkData?.map((item) => {
              cardsIds.push(item?.mainId);
              if (closeTap) {
                updatedCardsTabs = updatedCardsTabs?.filter(
                  (tab) => tab?.engId != item?.mainId,
                );
              }
            });
            if (cardsIds?.length) {
              if (activeTab !== "complete" && activeTab !== "all") {
                const animateCardRemoval = (cardElement) => {
                  const cardHeight =
                    cardElement?.getBoundingClientRect?.()?.height;
                  const heightPercentage = (percentage) =>
                    `${(cardHeight * percentage) / 100}px`;

                  const keyframes = [
                    {
                      height: heightPercentage(100),
                      opacity: 1,
                      filter: "blur(4px)",
                    },
                    {
                      height: heightPercentage(75),
                      opacity: 0.75,
                      filter: "blur(6px)",
                    },
                    {
                      height: heightPercentage(50),
                      opacity: 0.5,
                      filter: "blur(8px)",
                    },
                    {
                      height: heightPercentage(25),
                      opacity: 0.25,
                      filter: "blur(8px)",
                    },
                    {
                      height: heightPercentage(0),
                      opacity: 0,
                      filter: "blur(10px)",
                    },
                  ];

                  const options = {
                    duration: 500,
                    easing: "ease-in-out",
                    iterations: 1,
                  };

                  return cardElement?.animate?.(keyframes, options);
                };

                const handleMultipleCardRemoval = (cardIds) => {
                  const animations = [];

                  cardIds.forEach((cardId) => {
                    const cardElement = document.getElementById(cardId);
                    if (cardElement) {
                      cardElement?.classList?.add?.("delete-card-animation");
                      const animationRef = animateCardRemoval(cardElement);
                      if (animationRef) animations.push(animationRef.finished);
                    }
                  });

                  Promise.all(animations).then(() => {
                    setItemsData((prev) =>
                      prev.filter(
                        (item) =>
                          !cardIds.includes(
                            `${item?.tweet_id ? item?.tweet_id : item?.id}-${item?.tracker_id}`,
                          ),
                      ),
                    );
                    let newUpdatedTabsContentCount = {};
                    if (activeTab == "active") {
                      newUpdatedTabsContentCount.active =
                        +tabsContentCount?.active -
                        changedReduxBulkData?.length;
                    } else if (activeTab == "new") {
                      newUpdatedTabsContentCount.new =
                        +tabsContentCount?.new - changedReduxBulkData?.length;
                    }
                    newUpdatedTabsContentCount.complete =
                      +tabsContentCount?.complete +
                      changedReduxBulkData?.length;
                    setTabsContentCount(
                      newUpdatedTabsContentCount,
                      "content_tabs",
                    );
                  });
                };
                handleMultipleCardRemoval(cardsIds);
              } else if (activeTab == "all") {
                setItemsData((prev) =>
                  prev.map((item) => {
                    if (
                      cardsIds.includes(
                        `${item?.tweet_id ? item?.tweet_id : item?.id}-${item?.tracker_id}`,
                      )
                    ) {
                      return {
                        ...item,
                        system_replies: {
                          ...item?.system_replies,
                          status: "complete",
                        },
                      };
                    }
                    return { ...item };
                  }),
                );
                let newUpdatedTabsContentCount = {};
                newUpdatedTabsContentCount.complete =
                  +tabsContentCount?.complete + numberOfUncomplete;
                setTabsContentCount(newUpdatedTabsContentCount, "content_tabs");
              }
              if (closeTap) {
                setEngagementTabs([...updatedCardsTabs]);
                setActiveEngagement(updatedCardsTabs?.[0] || {});
              }
            }
            if (changedReduxBulkData?.length !== reduxBulkData?.length) {
              setSnackBar({
                openSnackBar: true,
                severity: "error",
                title: "failed_to_delete_survey",
                message: "some_cards_failed",
                num: reduxBulkData?.length - changedReduxBulkData?.length,
              });
            }
            setOpenCompletePopup(false);
            setValueInputBulk("");
            setSendSurvey(false);
            setCompletionReasonBulk("");
            setDisabledAction(false);
          },
          onError: (error) => {
            setSnackBar({
              openSnackBar: true,
              severity: "error",
              title: "failed_to_delete_survey",
              message: "request_access_error_desc",
            });
            setDisabledAction(false);
          },
        },
      );
    }
  };

  const updateReduxAfterAssign = (reduxData) => {
    EngagementsController.getUsers(
      window.localStorage.getItem("engagementsProductId"),
    ).then((usersRes) => {
      if (!usersRes?.errorMsg) {
        let slaDataCopy = { ...reduxData };
        slaDataCopy.users =
          usersRes?.data?.data > 0
            ? usersRes?.data?.data
            : [...reduxSlaData?.users];
        dispatch(slaData(slaDataCopy));
      }
    });
  };

  const handelClosePopup = () => {
    if (!disabledAction) {
      setValueInputBulk("");
      setSendSurvey(false);
      setCompletionReasonBulk("");
      setOpenCompletePopup(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setHasNextPage(false);
    setLastOperation(0);
    setEngagementPostId(null);
    setCountLoading(true);
    setSlaCounterData({ ...slaCounterData, loading: true });
    const tabsNames =
      filters?.sla_status === "ai_agent_sla"
        ? ["all", "active", "complete"]
        : ["all", "new", "active", "complete"];
    filters.page = 1;
    filters.engagement_status = tabsNames[newValue];
    setFilters({ ...filters });
    applyFilters(undefined, undefined, "content_tabs");
    setTabValue(newValue);
  };
  let showLoading =
    (!countLoading && window.location.href.includes("alert_id")) ||
    !window.location.href.includes("alert_id");
  const tabsName =
    filters?.sla_status === "ai_agent_sla" ? tabsNameAiAgent : tabsNameGeneral;

  const isBulkCompleteDisabled =
    filters?.engagement_status == "complete" ||
    !hasCompletAccess ||
    isOnecompleted;
  return (
    <>
      <Box className="tabs-wrapper">
        {reduxBulkData?.length ? (
          <Box className="bulk-wrapper">
            <LockedEngagement
              userSignedIn={userSignedIn}
              openAssignPopup={openAssignPopup}
              setOpenAssignPopup={setOpenAssignPopup}
              isBulk={true}
              reduxBulkData={reduxBulkData}
              updateReduxAfterAssign={updateReduxAfterAssign}
              bulkAssignUpdate={bulkAssignUpdate}
              setEngamentAssignedSnackBar={setSnackBar}
            />
            <Tooltip
              title={CheckValueLocale(
                isTeamLeader ? "assign_to_agent" : "assign_to_me",
                "",
                {},
                intl,
              )}
              arrow
              id="bulk_tooltip"
              placement="bottom"
            >
              <FontAwesomeIcon
                icon={faUserPlus}
                onClick={() => setOpenAssignPopup(true)}
              />
            </Tooltip>
            <Divider orientation="vertical" />
            <Tooltip
              title={CheckValueLocale(
                !hasCompletAccess
                  ? "bulk_assign_error"
                  : isBulkCompleteDisabled
                    ? "bulk_all_complete"
                    : "bulk_complete",
                "",
                {},
                intl,
              )}
              arrow
              id="bulk_tooltip"
              placement="bottom"
            >
              <FontAwesomeIcon
                className={isBulkCompleteDisabled ? "bulk-disabled" : ""}
                icon={faCircleCheck}
                onClick={() =>
                  !isBulkCompleteDisabled && setOpenCompletePopup(true)
                }
              />
            </Tooltip>
          </Box>
        ) : showLoading ? (
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            {tabsName?.map((tab, i) => {
              const { isInitialLoad, changedCountKeys } = tabsContentCount;
              const isMutableTab = tabsContentCount?.mutableTabs?.includes(
                tab?.key,
              );
              const isCurrentTab = tab?.key == filters?.engagement_status;
              return (
                <Tab
                  key={i}
                  label={
                    <p id={`engagements-tab-${tab?.name}`} className="tab-name">
                      {CheckValueLocale(tab?.name, "", {}, intl)}
                      {/* show loading only when coutLoading and only in the current tab*/}
                      {shouldShowLoading(
                        countLoading,
                        filterTypeChange,
                        isCurrentTab,
                        isInitialLoad,
                      ) ? (
                        <FontAwesomeIcon icon={faSpinnerThird} spin />
                      ) : (
                        <span
                          className={classNames(
                            "tab-number",
                            changedCountKeys?.includes(tab?.key) && isMutableTab
                              ? "animated-number-bounce"
                              : "",
                          )}
                        >
                          {handleShowTabsCounter(
                            tabsContentCount?.[tab?.key],
                            isMutableTab,
                          )}
                        </span>
                      )}
                    </p>
                  }
                  {...a11yProps(0)}
                />
              );
            })}
          </Tabs>
        ) : null}
      </Box>
      <Box className="tabs-panel-wrapper">
        {tabsName?.map((tab, i) => (
          <TabPanel
            value={tabValue}
            key={i}
            index={i}
            className="tabs-panel-box"
          >
            <TabContent
              response={response}
              setDataSourceCount={setDataSourceCount}
              setFilters={setFilters}
              filters={filters}
              applyFilters={applyFilters}
              allData={allData}
              preLoader={preLoader}
              setCountLoading={setCountLoading}
              setSlaCounterData={setSlaCounterData}
              slaCounterData={slaCounterData}
              completionContent={completionContent}
              valueInput={valueInput}
              completionReason={completionReason}
              setLastOperation={setLastOperation}
              setEngagementPostId={setEngagementPostId}
              setTabValue={setTabValue}
              setOpenSidebarToAssignTeam={setOpenSidebarToAssignTeam}
              aiAgentList={aiAgentList}
              setAiAgentList={setAiAgentList}
              setIsAiAgentDown={setIsAiAgentDown}
              setIsLimitReached={setIsLimitReached}
              setIsDMLimitReached={setIsDMLimitReached}
              setAiAgentDMReachDetails={setAiAgentDMReachDetails}
              // new props
              tabsContentCount={tabsContentCount}
              setTabsContentCount={setTabsContentCount}
              resetTabsContentCount={resetTabsContentCount}
              setIsSlaInitialLoad={setIsSlaInitialLoad}
              filterTypeChange={filterTypeChange}
              hasNextPage={hasNextPage}
              setHasNextPage={setHasNextPage}
              itemsData={itemsData}
              setItemsData={setItemsData}
            />
          </TabPanel>
        ))}
      </Box>
      {openCompletePopup ? (
        <EngagementComplete
          updateStatus={updateStatus}
          openPopup={openCompletePopup}
          completionReason={completionReasonBulk}
          setCompletionReason={setCompletionReasonBulk}
          valueInput={valueInputBulk}
          setValueInput={setValueInputBulk}
          completionContent={completionContentBulk}
          setCompletionContent={setCompletionContentBulk}
          handelClosePopup={handelClosePopup}
          disabledAction={disabledAction}
          csatEnabled={true}
          isBulk={true}
          sendSurvey={sendSurvey}
          setSendSurvey={setSendSurvey}
        />
      ) : null}
      <SnackBar
        snackBarId="bulk-snackbar"
        open={snackBar?.openSnackBar}
        handleClose={() => setSnackBar({})}
        severity={snackBar?.severity}
        title={CheckValueLocale(snackBar?.title, "", {}, intl)}
        message={CheckValueLocale(
          snackBar?.message,
          "",
          { num: snackBar?.num || 0 },
          intl,
        )}
      />
    </>
  );
};
export default SideBarTabs;
