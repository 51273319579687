import { Box, Typography, Button } from "@mui/material";
import { useEffect } from "react";
import PopupModal from "components/popupModal";
import { CheckValueLocale } from "../../../../utils/helpers/index.js";
import { useIntl } from "react-intl";
import Sales_funnel_management from "images/engagements-images/Sales_funnel_management.svg";
import "./setupFlow.scss";
import { useNavigate } from "react-router-dom";

const SetupFlow = ({ setClose, openSetupFlowFirst, isDoneSetup }) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const welcomeScreen = () => {
    return (
      <Box className="welcome-setup">
        <img src={Sales_funnel_management} />
        <Typography className="setup-title">
          {CheckValueLocale("welcome_to_engagement", "", {}, intl)}
        </Typography>
        <Typography id="setup-pragraph">
          {CheckValueLocale("welcome_to_engagement_pragraph", "", {}, intl)}
        </Typography>
        <Box className="btns-countainer">
          <Button className="setup-btn skip-btn" onClick={() => handleClose()}>
            {CheckValueLocale("skip", "", {}, intl)}
          </Button>
          <Button
            className="setup-btn"
            onClick={() =>
              navigate(isDoneSetup ? "/engagements/edit" : "/engagements/setup")
            }
          >
            {CheckValueLocale("setup_engagement", "", {}, intl)}
          </Button>
        </Box>
      </Box>
    );
  };
  const body = () => {
    return <Box className="main-setup-countainer">{welcomeScreen()}</Box>;
  };

  const handleClose = () => {
    setClose();
  };
  useEffect(() => {
    if (isDoneSetup && !openSetupFlowFirst) {
      navigate("/engagements/edit");
    }
  }, [isDoneSetup, openSetupFlowFirst]);

  return (
    <PopupModal
      body={body()}
      open={!isDoneSetup || openSetupFlowFirst}
      close={handleClose}
      leftBtnId="audience-segment-pop-up-cancel-left-btn"
      rightBtnId="audience-segment-pop-up-add-segment-right-btn"
    />
  );
};

export default SetupFlow;
