import { Box, Typography } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import "./style.scss";

const OpenDisablePopup = () => {
  const intl = useIntl();

  return (
    <Box id="disable-feature-popup">
      <Typography className="security-desc">
        {CheckValueLocale(
          "disable_two_factor_authentication_one",
          "",
          {},
          intl,
        )}
      </Typography>
      <Typography className="security-desc-second">
        {CheckValueLocale(
          "disable_two_factor_authentication_two",
          "",
          {},
          intl,
        )}
      </Typography>
    </Box>
  );
};
export default OpenDisablePopup;
