import { Box } from "@mui/material";
import BreadCrumb from "components/breadCrumb";
import { useIntl } from "react-intl";
import { useLocation, useParams } from "react-router-dom";
import { CheckValueLocale } from "utils/helpers";

const LiveDashBoardHeader = ({
  isEditLiveDashboard,
  getMonitorUrl,
  monitorWidgets,
}) => {
  const intl = useIntl();
  const urlParams = useParams();
  const location = useLocation();
  const monitorUrl = getMonitorUrl(
    urlParams,
    Object.keys(monitorWidgets || {})?.[0],
  );

  const montitorDetails = location?.state;
  const montitorDetailsName = montitorDetails?.attributes?.name;
  const montitorName = montitorDetailsName ? " - " + montitorDetailsName : "";

  const links = [
    {
      name: "monitors_list",
      url: "social/monitor_list",
      addNameLocal: true,
    },
    {
      name: urlParams?.monitorId + montitorName,
      url: monitorUrl?.pathname,
      state: monitorUrl?.state,
      addNameLocal: true,
    },
  ];

  return (
    <Box className="create-live-dashboard-heading">
      <BreadCrumb id="breadcrumb-live-dashboard" links={links} />
      <Box component="h2">
        {CheckValueLocale(
          isEditLiveDashboard ? "edit_live_dashboard" : "create_live_dashboard",
          "",
          {},
          intl,
        )}
      </Box>
    </Box>
  );
};

export default LiveDashBoardHeader;
