import { useCallback, useEffect, useRef } from "react";

export const useMemoizedFn = (fn) => {
  const ref = useRef(fn);

  useEffect(() => {
    ref.current = fn;
  }, [fn]);

  return useCallback((...args) => ref.current(...args), []);
};
