import { Box } from "@mui/material";
import "./TextContent2FA.scss";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";

const TextContent2FA = ({ bodyText, contact }) => {
  const intl = useIntl();
  return (
    <Box id="text-content-to-2fa">
      {CheckValueLocale(
        bodyText,
        "",
        {
          digits: (
            <strong>{CheckValueLocale("six_digit", "", {}, intl)}</strong>
          ),
          contact: contact,
        },
        intl,
      )}
    </Box>
  );
};

export default TextContent2FA;
