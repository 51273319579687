// ********COMMON FILE,Any change in this file will have effect on many pages
// Please Don't Change, delete or update in this file without perrmission
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import BarChart from "components/echarts/barChart";
import FetchingData from "components/fetchingData/fetchingData";
import NoDataFound from "components/no-Data/noDataFound";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import ChartDetailsWidget from "./customStatisctics/chartDetailsWidget";
import ChartStatsWidget from "./customStatisctics/chartStatsWidget";
import WidgetHeader from "./widgetHeader";
import {
  calculateUnixTimeZone,
  CheckValueLocale,
  converToArabicNumbers,
  formatTimeZone,
  truncate,
} from "utils/helpers";

const BarChartWidget = (props) => {
  const intl = useIntl();
  const [dataForChart, setDataForChart] = useState(props.data);
  const [duration, setDuration] = useState();
  const getChartMeta = (data, duration) => {
    setDataForChart(data);
    setDuration(duration);
  };
  useEffect(() => {
    getChartMeta(props.data);
  }, [props.data]);
  let xAxisData = [],
    seriesData = [],
    tooltipData = [],
    zoomPercentage = 0;
  if (dataForChart) {
    if (props?.isComparison)
      tooltipData = dataForChart?.map((data) => data?.tooltipData);

    if (
      (duration === undefined || duration === "day") &&
      props?.customerCareChart !== true &&
      props?.isSurveyInsights !== true
    ) {
      xAxisData = dataForChart?.map((data) => data.value);
      seriesData = dataForChart?.map((data) =>
        props?.isComparison
          ? props?.isControlWidget
            ? dataForChart?.length < 4
              ? truncate(data?.name, 12)
              : dataForChart?.length < 6
                ? truncate(data?.name, 5)
                : truncate(data?.name, 2)
            : data?.name
          : props?.showAuthorProfile
            ? CheckValueLocale(`${data?.name}`, "", {}, intl)
            : formatTimeZone(
                calculateUnixTimeZone(Number(data?.name, { utc: true })),
                intl,
                {
                  dateFormat:
                    intl.locale === "ar" ? "YYYY/MM/DD" : "DD/MM/YYYY",
                },
              ).date,
      );
    } else {
      xAxisData = dataForChart?.map((data) => data.value);
      seriesData = dataForChart?.map((data) =>
        props?.customerCareChart
          ? CheckValueLocale(`${data.name}`, "", {}, intl)
          : props?.isSurveyInsights && data?.name
            ? `${CheckValueLocale(
                `${data?.name[0]?.toLowerCase()}`,
                "",
                {},
                intl,
              )}${
                props.isSurvey
                  ? data?.name?.slice(1)
                  : localStorage.lang === "en"
                    ? data?.name?.slice(1)
                    : converToArabicNumbers(data?.name.slice(1))
              }`
            : data.name,
      );
    }
    if (dataForChart?.length > 30) {
      zoomPercentage = Math.floor(100 - (10 / dataForChart?.length) * 100);
    }
  } else {
    seriesData = [0];
    xAxisData.push(moment().format("DD/MM/YYYY"));
  }

  let valueExist = seriesData.every((val) => val.value === 0);
  let valueEmpty = xAxisData.every((val) => val === "");
  let nameExist = seriesData.every((val) => val.name === null);
  return (
    <div
      className={`chart ${props?.isSurveyInsights ? "survey-insights-chart" : ""}`}
      id={
        props?.isCustomDashboard
          ? `${props?.title}-_-${props?.customDashboardData?.widget?.id || ""}`
          : props?.title
      }
      style={
        props?.isCustomDashboard
          ? { height: props?.customHeight }
          : props?.showChartStatsWidget
            ? { height: "545px" }
            : {}
      }
    >
      <WidgetHeader
        title={props.title}
        showNotAppliedFilter={props?.showNotAppliedFilter}
        showAI={props.showAI}
        showDownloadIcon={props.showDownloadIcon}
        chartId={
          props?.isCustomDashboard
            ? `${props?.title}-_-${props?.customDashboardData?.widget?.id || ""}`
            : props?.title
        }
        showGroupBy={props?.showGroupBy}
        showScales={props?.showScales}
        data={props.data}
        getChartMeta={getChartMeta}
        totalLabel={
          _.isEqual(
            props?.barChartPreLoaderDataSources?.sort(),
            props?.preLoaderTrackerDataSources?.sort(),
          ) !== true ||
          !props?.barChartPreLoaderDataSources?.length ||
          !props?.preLoaderTrackerDataSources?.length
            ? undefined
            : props?.totalLabel
        }
        totalValue={
          _.isEqual(
            props?.barChartPreLoaderDataSources?.sort(),
            props?.preLoaderTrackerDataSources?.sort(),
          ) !== true ||
          !props?.barChartPreLoaderDataSources?.length ||
          !props?.preLoaderTrackerDataSources?.length
            ? undefined
            : props?.totalValue
        }
        bigImg={props.bigImg}
        iconAfterTitle={props.iconAfterTitle}
        titleToolTip={props.titleToolTip}
        showReportIcon={props.showReportIcon}
        widgetSection={props?.widgetSection}
        isCustomDashboard={props?.isCustomDashboard} // To identify that widget will be rendered in Custom Dashboard
        isControlWidget={props?.isControlWidget} // To identify that widget will be rendered in Custom Dashboard control widget sidebar
        customDashboardData={props?.customDashboardData} // The needed data in widget component for case of Custom Dashboard only
      />
      {_.isEqual(
        props?.barChartPreLoaderDataSources?.sort(),
        props?.preLoaderTrackerDataSources?.sort(),
      ) !== true ||
      !props?.barChartPreLoaderDataSources?.length ||
      !props?.preLoaderTrackerDataSources?.length ? (
        <FetchingData />
      ) : seriesData?.length === 0 ||
        seriesData === [] ||
        valueExist ||
        props.data.length == 0 ||
        valueEmpty ||
        nameExist ||
        dataForChart?.every((item) => item?.value == 0) ? (
        <NoDataFound />
      ) : (
        <>
          {/* THREE BOXS OF Total Followers & Max Change of Followers & Avg. Interaction per Day */}
          {props.showChartDetailsWidget && (
            <ChartDetailsWidget statsDetailsData={props.statsDetailsData} />
          )}
          {/* THREE BOXS OF Total Followers & Max Change of Followers & Avg. Interaction per Day */}
          {props.showChartStatsWidget && (
            <ChartStatsWidget
              statsData={props.statsData}
              liveDashboardWidget={props?.liveDashboardWidget}
            />
          )}
          {props?.customerCareChart && (
            <Tooltip
              title={
                <Box className="tooltip-custom-new">
                  {props?.respTimeDistributionTooltip &&
                    CheckValueLocale(
                      props?.respTimeDistributionTooltip,
                      "",
                      {},
                      intl,
                    )}
                </Box>
              }
              arrow
              placement="top"
            >
              <Typography
                className="chart-heading
            "
                marginX={1}
                variant="subtitle2"
              >
                {CheckValueLocale("resp_time_distribution", "", {}, intl)}
              </Typography>
            </Tooltip>
          )}

          {/* MAKE A GRID TO HANDEL BARCHART WITH RIGHT SIDE STATS [AUTHOR PROFILE PAGE] */}
          <Grid
            container
            className={`${props.showAuthorProfile ? "monitor-engagement" : ""}`}
          >
            <Grid item xs={props.showAuthorProfile ? 9 : 12}>
              <BarChart
                isSurvey={props.isSurvey}
                colorsSurvey={props.colorsSurvey}
                getSurveyName={props.getSurveyName}
                zoomPercentage={zoomPercentage}
                showZoomPercentage={props.showZoomPercentage}
                dataValues={xAxisData}
                dataLabels={
                  props?.isComparison
                    ? seriesData?.map((label, index) => label + "-" + index) // add "-" and index to can display datasource image
                    : seriesData
                }
                dataColor={props.dataColor}
                legendPosition={
                  window.localStorage.lang === "ar" ? "topRight" : "topRight"
                }
                customBarColor={props.customBarColor} //in case single bar chart but each bar has different color pass an array of colors
                applyAxisFormatter={props.applyAxisFormatter}
                gridHeight={props.gridHeight ? props.gridHeight : "auto"}
                xAxisFontColor={
                  props.xAxisFontColor
                    ? props.xAxisFontColor
                    : "rgba(109, 115, 122, 0.74)"
                }
                xAxisFontWeight={
                  props.xAxisFontWeight ? props.xAxisFontWeight : "normal"
                }
                yAxisGridLeft={props.yAxisGridLeft ? props.yAxisGridLeft : "4%"}
                isScaleQues={props?.isScaleQues}
                isComparison={props?.isComparison}
                tooltipData={tooltipData}
                listDataSourceIcon={
                  props?.isComparison
                    ? props?.data?.map((monitor) => monitor?.dataSource)
                    : []
                }
              />
            </Grid>
            {/* RIGHT ENGAGEMENT STATS IN [AUTHOR PROFILE PAGE] */}
            {props.showAuthorProfile && (
              <Grid item xs={3} className="engagement-grid">
                <Box className="engagement-right">
                  {CheckValueLocale("total_engagements", "", {}, intl)}
                  <Box mt={1.5}>{props.totalEngagements.toLocaleString()}</Box>
                </Box>

                <Box className="engagement-right rate">
                  {props.engagementsRate
                    ? CheckValueLocale("engagements_rate", "", {}, intl)
                    : CheckValueLocale("avg_engagements_post", "", {}, intl)}

                  <Box mt={2.5}>
                    {props.engagementsRate
                      ? CheckValueLocale(
                          `${props?.engagementsRate}`,
                          "",
                          {},
                          intl,
                        )
                      : props.averageEngagementsRate.toLocaleString()}

                    <Tooltip
                      title={CheckValueLocale(
                        `${props.engagmentToolTip}`,
                        "",
                        {},
                        intl,
                      )}
                      className="rate-tooltip"
                    >
                      <IconButton>
                        <FontAwesomeIcon icon={faQuestionCircle} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </div>
  );
};

export default BarChartWidget;
