import { Box, Chip, FormControlLabel, Radio } from "@mui/material";
import { useIntl } from "react-intl";
import {
  CheckValueLocale,
  truncate,
  removeStartingProtocol,
} from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleExclamation,
  faTrashCan,
} from "@fortawesome/pro-regular-svg-icons";
import { useGetCompanyDomains } from "../hooks/useGetCompanyDomains";
import { useDeleteCompanyDomain } from "../hooks/useDeleteCompanyDomain";
import CustomizedTooltip from "components/customizedTooltip";
import LucButton from "shared/lucButton/lucButton";

const DomainListItem = ({
  domain,
  setCurrentDomainsPage,
  setCompanyDomainsData,
  setCompanyDomainsList,
  selectedDomains,
  setSelectedDomains,
  setSnackBar,
}) => {
  const intl = useIntl();

  const cleanedDomain = removeStartingProtocol(domain?.domain) || "";

  const { mutate: deleteDomain, isPending: deleteLoading } =
    useDeleteCompanyDomain();

  const { mutate: getCompanyDomains } = useGetCompanyDomains();

  const handleDeleteDomain = async (id) => {
    deleteDomain(
      { id },
      {
        onSuccess: () => {
          if (selectedDomains?.[0]?.id === id) setSelectedDomains([]);
          setCurrentDomainsPage(1);
          getCompanyDomains(
            { page: 1 },
            {
              onSuccess: async (data) => {
                setCompanyDomainsData(data?.data?.domains);
                setCompanyDomainsList([...data?.data?.domains?.data]);
              },
              onError: () => {
                setSnackBar({
                  open: true,
                  severity: "error",
                  title: "something_went_wrong",
                  message: "try_again",
                });
              },
            },
          );
        },
        onError: () => {
          setSnackBar({
            open: true,
            severity: "error",
            title: "something_went_wrong",
            message: "try_again",
          });
        },
      },
    );
  };

  const isSelected = selectedDomains?.find(
    (item) => Number(item?.id) === Number(domain?.id),
  );

  const disabledDelete = domain?.attached_surveys?.length > 0;

  return (
    <Box key={domain?.id} className="select-domain-list-item">
      <Box className="select-domain-list-item-label-count-container">
        <FormControlLabel
          className="select-domain-list-item-label"
          control={<Radio checked={isSelected} />}
          label={
            domain?.domain?.length > 14 ? (
              <CustomizedTooltip
                title={""} // title={cleanedDomain}
                placement="top"
                styles={{
                  fontSize: "12px",
                  fontWeight: "500",
                  maxWidth: "244px",
                }}
                arrow
              >
                {truncate(cleanedDomain, 14)}
              </CustomizedTooltip>
            ) : (
              cleanedDomain
            )
          }
          value={domain?.id}
          onClick={() =>
            setSelectedDomains([
              {
                id: domain?.id,
                domain: domain?.domain,
                is_verified: domain?.is_verified,
                attached_surveys: domain?.attached_surveys,
                token: domain?.domain_verification_token,
                allow_all_pages: true,
                allowed_pages: [],
              },
            ])
          }
        />

        <CustomizedTooltip
          title={
            domain?.attached_surveys?.length > 0 ? (
              <ul id="embedded-survey-active-surveys-tooltip-list">
                {domain?.attached_surveys?.map((survey) => (
                  <li key={survey?.id}>{survey?.name}</li>
                ))}
              </ul>
            ) : (
              ""
            )
          }
          placement={intl?.locale === "ar" ? "left" : "right"}
          styles={{ fontSize: "12px", fontWeight: "500", maxWidth: "244px" }}
          arrow
        >
          <Box className="select-domain-list-item-label-count-wrapper">
            <Box className="select-domain-list-item-label-count">
              {`${domain?.attached_surveys?.length} ${CheckValueLocale("embedded_survey_active_surveys", "", {}, intl)}`}
            </Box>
          </Box>
        </CustomizedTooltip>
      </Box>

      <Box className="select-domain-list-item-btns-container">
        <Chip
          size="small"
          className={
            domain?.is_verified
              ? "verified-domain-badge"
              : "unverfied-domain-badge"
          }
          icon={
            <FontAwesomeIcon
              icon={domain?.is_verified ? faCircleCheck : faCircleExclamation}
              fixedWidth
            />
          }
          label={CheckValueLocale(
            domain?.is_verified
              ? "embedded_survey_verified"
              : "embedded_survey_unverified",
            "",
            {},
            intl,
          )}
        />

        <CustomizedTooltip
          title={CheckValueLocale(
            disabledDelete
              ? "embedded_survey_delete_domain_disabled_msg"
              : "delete",
            "",
            {},
            intl,
          )}
          placement={
            disabledDelete
              ? intl?.locale === "ar"
                ? "right"
                : "left"
              : "bottom"
          }
          styles={{
            fontSize: "14px",
            padding: "6px 8px",
            width: disabledDelete ? "244px" : "auto",
          }}
          arrow
        >
          <Box>
            <LucButton
              id="embedded-survey-delete-domain-btn"
              type="secondary"
              variant="flat"
              size="small"
              minWidth={36}
              loading={deleteLoading}
              disabled={disabledDelete}
              onClick={() => handleDeleteDomain(domain?.id)}
            >
              <FontAwesomeIcon icon={faTrashCan} fixedWidth />
            </LucButton>
          </Box>
        </CustomizedTooltip>
      </Box>
    </Box>
  );
};

export default DomainListItem;
