import { useQuery } from "@tanstack/react-query";
import { ReportServices } from "services/api/reports";

export const useGetNewsLetterReport = (reportDataId) => {
  return useQuery({
    queryKey: ["newsLetterReport", reportDataId],
    queryFn: () =>
      ReportServices.getNewsLetterReport({ report_data_id: reportDataId }),
    enabled: !!reportDataId,
    select: (data) => data?.data,
    retry: false,
  });
};
