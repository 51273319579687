import HeaderSectionProfile from "../shared/headerSectionProfile";
import CardSecuritySection from "./components/cardSecuritySection";
import NewPasswordForm from "./components/newPasswordForm";
import TwoFactorAuthentication from "./components/twoFactorAuthentication/twoFactorAuthentication";
import "./style.scss";
import { Box, Grid } from "@mui/material";

const SecurityProfileSection = ({
  formik,
  isLoading,
  handleErrorSnackBar,
  handleSuccessSnackBar,
}) => {
  return (
    <Box id="security-section-profile">
      <HeaderSectionProfile
        titleHeader="security_title"
        descriptionHeader="security_description"
      />

      <Grid container className="security-section-container" spacing={2.5}>
        <Grid item md={6} sm={9}>
          <CardSecuritySection
            title={"change_your_password_title"}
            description={"change_your_password_description"}
            bodyCard={<NewPasswordForm formik={formik} />}
          />
        </Grid>
        <Grid item md={6} sm={9}>
          <CardSecuritySection
            title={"two_factor_authentication_title"}
            description={"two_factor_authentication_description"}
            bodyCard={
              <TwoFactorAuthentication
                formik={formik}
                isLoading={isLoading}
                handleErrorSnackBar={handleErrorSnackBar}
                handleSuccessSnackBar={handleSuccessSnackBar}
              />
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SecurityProfileSection;
