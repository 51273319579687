import { Box } from "@mui/material";
import SurveyQuestionTitle from "./questionTitle/surveyQuestionTitle";
import EmailQuestion from "./questionsTypes/emailQuestion";
import FreeAnswerQuestion from "./questionsTypes/freeAnswerQuestion";
import MultipleChoiceQuestion from "./questionsTypes/mcqQuestion/mcqQuestion";
import DropdownQuestion from "./questionsTypes/dropdownQuestion";
import YesNoQuestion from "./questionsTypes/yesNoQuestion";
import NameQuestion from "./questionsTypes/nameQuestion";
import PhoneNumberQuestion from "./questionsTypes/phoneNumberQuestion";
import NumberQuestion from "./questionsTypes/numberQuestion";
import SocialMediaQuestion from "./questionsTypes/socialMediaQuestion";
import NpsQuestion from "./questionsTypes/npsQuestion";
import LinearScaleQuestion from "./questionsTypes/linearScaleQuestion";
import "./style.scss";

const SurveyBuilderQuestion = (props) => {
  const {
    questionIndex,
    question,
    sectionIndex,
    handleCardClick,
    handleQuestionTitle,
    handleQuestionDesc,
    isEmbedded,
    filteredSections,
    setSurveySections,
    surveySections,
    setSaveChangesFlag,
    proceedError,
    ssIndex,
    sqIndex,
    handleChangeProceed,
    handleProceedAnswer,
    previewMode,
    required,
    setErrorForm,
  } = props;

  const questionType = question?.question?.type;

  const selectedQuestion =
    ssIndex === sectionIndex + 1 && sqIndex === questionIndex + 1;

  const questionError = selectedQuestion && proceedError === questionIndex;

  return (
    <Box
      id={`section-${sectionIndex}-question-${questionIndex}`}
      className={`survey-builder-question-card ${questionError ? "error-question" : selectedQuestion ? "selected-question" : ""} card-direction-container`}
      onClick={() =>
        !previewMode && handleCardClick(sectionIndex, questionIndex)
      }
    >
      <SurveyQuestionTitle
        number={questionIndex + 1}
        title={question?.question?.text}
        subtitle={question?.question?.description}
        handleChangeTitle={(e) =>
          handleQuestionTitle(e, sectionIndex, questionIndex)
        }
        handleChangeSubtitle={(e) =>
          handleQuestionDesc(e, sectionIndex, questionIndex)
        }
        hideNumber={isEmbedded}
        previewMode={previewMode}
        required={required}
      />

      {questionType === "short_text" ? (
        <FreeAnswerQuestion
          question={question?.question}
          questionIndex={questionIndex}
          setErrorForm={setErrorForm}
          previewMode={previewMode}
        />
      ) : questionType === "multiple_choice" ? (
        <MultipleChoiceQuestion
          filteredSections={filteredSections}
          surveySections={surveySections}
          setSurveySections={setSurveySections}
          sectionIndex={sectionIndex}
          questionIndex={questionIndex}
          rootQuestion={question}
          question={question?.question}
          setSaveChangesFlag={setSaveChangesFlag}
          handleChangeProceed={handleChangeProceed}
          handleProceedAnswer={handleProceedAnswer}
          previewMode={previewMode}
        />
      ) : questionType === "dropdown" ? (
        <DropdownQuestion
          filteredSections={filteredSections}
          setSurveySections={setSurveySections}
          surveySections={surveySections}
          rootQuestion={question}
          question={question?.question}
          questionIndex={questionIndex}
          sectionIndex={sectionIndex}
          handleChangeProceed={handleChangeProceed}
          handleProceedAnswer={handleProceedAnswer}
          previewMode={previewMode}
        />
      ) : questionType === "yes_no" ? (
        <YesNoQuestion
          filteredSections={filteredSections}
          rootQuestion={question}
          question={question?.question}
          questionIndex={questionIndex}
          sectionIndex={sectionIndex}
          handleChangeProceed={handleChangeProceed}
          handleProceedAnswer={handleProceedAnswer}
          previewMode={previewMode}
        />
      ) : questionType === "email" ? (
        <EmailQuestion previewMode={previewMode} />
      ) : questionType === "name" ? (
        <NameQuestion previewMode={previewMode} />
      ) : questionType === "social_media" ? (
        <SocialMediaQuestion
          filteredSections={filteredSections}
          setSurveySections={setSurveySections}
          surveySections={surveySections}
          question={question?.question}
          questionIndex={questionIndex}
          sectionIndex={sectionIndex}
          previewMode={previewMode}
        />
      ) : questionType === "phone_number" ? (
        <PhoneNumberQuestion
          question={question?.question}
          previewMode={previewMode}
        />
      ) : questionType === "number" ? (
        <NumberQuestion
          question={question?.question}
          questionIndex={questionIndex}
          setErrorForm={setErrorForm}
          previewMode={previewMode}
        />
      ) : questionType === "nps" ? (
        <NpsQuestion
          question={question?.question}
          rootQuestion={question}
          previewMode={previewMode}
          handleProceedAnswer={handleProceedAnswer}
        />
      ) : questionType === "scale" ? (
        <LinearScaleQuestion
          question={question?.question}
          rootQuestion={question}
          previewMode={previewMode}
          handleProceedAnswer={handleProceedAnswer}
        />
      ) : null}
    </Box>
  );
};

export default SurveyBuilderQuestion;
