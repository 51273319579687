import { useIntl } from "react-intl";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import { Box, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LucButton from "shared/lucButton/lucButton";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import PopupModal from "components/popupModal";
import { useEffect, useState } from "react";
import LockedEngagementAssignModal from "./lockedEngagementAssignModal";
import EngagementsController from "services/controllers/engagementsController";
import { useDispatch } from "react-redux";
import { slaData } from "utils/redux/features/Engagements/EngagementsSlice";
const LockedEngagement = ({
  reduxSlaKey,
  userSignedIn,
  selectedCard,
  setEngamentAssignedSnackBar,
  trackerId,
  updateReduxAfterAssign,
  openAssignPopup,
  setOpenAssignPopup,
  isBulk,
  reduxBulkData,
  bulkAssignUpdate,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isTeamLeader = handlelUserRoles("ENGAGEMENTS", "ENGAGEMENT_SETTINGS");
  const [openPopup, setOpenPopup] = useState(false);
  const [assigneeUserData, setAssigneeUserData] = useState(null);
  const [assigneeTeamData, setAssigneeTeamData] = useState(null);
  const [agentEmptyError, setAgentEmptyError] = useState(false);
  const [teamEmptyError, setTeamEmptyError] = useState(false);
  const reduxSlaData = useSelector((state) => state?.engagements?.sla);
  const assignedUser = isBulk ? null : reduxSlaData?.[reduxSlaKey]?.userId;
  const assignedUserData = isBulk
    ? []
    : reduxSlaData?.users?.filter((user) => {
        return user?.id === String(assignedUser);
      });
  const [teamsDataToUpdate, setTeamsDataToUpdate] = useState({
    users: [],
    teams: [],
  });
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [teamAlgorithms, setTeamAlgorithms] = useState([]);
  const [choosenAlgorithmId, setChoosenAlgorithmId] = useState(null);
  const [choosenAssign, setChoosenAssign] = useState(
    isTeamLeader && isBulk ? 1 : 2,
  );

  const assignedUserName = assignedUserData?.[0]?.attributes?.name;

  useEffect(() => {
    if (isTeamLeader && isBulk) {
      EngagementsController.getRoutingAlgorithms().then((res) => {
        if (!res?.errorMsg) {
          const algorithms = res?.data?.data?.map?.((algo) => {
            const { name, id } = algo?.attributes || {};
            const algoName = name?.toLowerCase()?.split(" ")?.join("_");
            return {
              id: id,
              label: name,
              name: "routing_" + algoName,
              desc: "routing_" + algoName + "_desc",
            };
          });
          setChoosenAlgorithmId(
            algorithms?.filter(
              (item) => item?.name == "routing_round_robin",
            )?.[0]?.id,
          );
          setTeamAlgorithms([...algorithms]);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (isBulk) {
      setChoosenAssign(isTeamLeader ? 1 : 2);
      if (teamAlgorithms?.length) {
        setChoosenAlgorithmId(
          teamAlgorithms?.filter(
            (item) => item?.name == "routing_round_robin",
          )?.[0]?.id,
        );
      } else {
        setChoosenAlgorithmId(null);
      }
    }
  }, [openAssignPopup]);

  const getAssigneeUserData = (data) => {
    setAssigneeUserData(data);
    if (data !== null) setAgentEmptyError(false);
  };
  const getAssigneeTeamData = (data) => {
    setAssigneeTeamData(data);
    setTeamEmptyError(false);
  };
  const getTeamsDataToUpdate = (data) => {
    setTeamsDataToUpdate(data);
    let slaDataCopy = { ...reduxSlaData };
    slaDataCopy.teams =
      data?.teams?.length > 0 ? data?.teams : [...reduxSlaData?.teams];
    dispatch(slaData(slaDataCopy));
  };

  const checkInputsValidValues = (selectedOptions) => {
    if (selectedOptions?.userId === null && selectedOptions?.teamId === null) {
      setAgentEmptyError(true);
      setTeamEmptyError(true);
    } else if (selectedOptions?.userId === null) {
      setAgentEmptyError(true);
      return;
    } else if (selectedOptions?.teamId === null) {
      setTeamEmptyError(true);
      return;
    } else {
      setAgentEmptyError(false);
      setTeamEmptyError(false);
    }
  };

  const handleAssignSubmit = (selectedOptions) => {
    checkInputsValidValues({
      teamId: selectedOptions?.teamId,
      userId:
        isTeamLeader && choosenAssign == 1 && isBulk
          ? 1
          : selectedOptions?.userId,
    });
    if (
      (selectedOptions?.userId && selectedOptions?.teamId) ||
      (isTeamLeader && choosenAssign == 1 && isBulk && selectedOptions?.teamId)
    ) {
      setIsButtonLoading(true);
      if (isBulk) {
        let queryData = {
          auto_assign: choosenAssign == 1,
          algorithm_type:
            choosenAssign == 1
              ? teamAlgorithms?.filter(
                  (item) => item?.id == choosenAlgorithmId,
                )?.[0]?.label
              : undefined,
          product_id: +window.localStorage.getItem("engagementsProductId"),
          team_id: choosenAssign == 1 ? selectedOptions?.teamId : undefined,
          engagements: reduxBulkData?.map((item) => {
            let agentFields = {
              new_user_id: selectedOptions?.userId,
              new_user_team_id: selectedOptions?.teamId,
              old_user_id: item?.userId,
              old_user_team_id: item?.teamId,
              product_id: +window.localStorage.getItem("engagementsProductId"),
            };
            return {
              monitor_id: item?.tracker_id,
              interaction_id: item?.tweet_id || item?.id,
              data_source_name: item?.data_source?.toUpperCase(),
              routing_id: item?.sla_info?.routing_id,
              sla_id: item?.sla_info?.sla_id,
              ...(choosenAssign == 1 ? {} : agentFields),
            };
          }),
        };
        bulkAssignUpdate(queryData, {
          onSuccess: (data) => {
            let slaDataCopy = { ...reduxSlaData };
            let changedReduxBulkData = [...reduxBulkData];
            if (data?.data?.invalid_interactions?.length) {
              let ids = data?.data?.invalid_interactions?.map(
                (item) => `${item?.interaction_id}-${item?.monitor_id}`,
              );
              changedReduxBulkData = reduxBulkData?.filter(
                (item) => !ids?.includes(item?.mainId),
              );
            }
            slaDataCopy.teams =
              teamsDataToUpdate?.teams?.length > 0
                ? teamsDataToUpdate?.teams
                : [...reduxSlaData?.teams];
            let updatedReduxSlaData = {
              ...slaDataCopy,
            };
            changedReduxBulkData?.map((item) => {
              let updatedSlaData = {
                ...slaDataCopy[item?.mainId],
                userId: +selectedOptions?.userId || null,
                teamId: +selectedOptions?.teamId || null,
              };
              updatedReduxSlaData[item?.mainId] = updatedSlaData;
            });
            if (changedReduxBulkData?.length !== reduxBulkData?.length) {
              setEngamentAssignedSnackBar({
                openSnackBar: true,
                severity: "error",
                title: "failed_to_delete_survey",
                message: "some_cards_failed_assign",
                num: reduxBulkData?.length - changedReduxBulkData?.length,
              });
            }
            // setEngamentAssignedSnackBar({
            //   openSnackBar: true,
            //   severity: "success",
            //   title: "",
            //   message: isTeamLeader
            //     ? "locked_engament_assigned_successfully"
            //     : "locked_engament_assigned_to_you_successfully",
            // });
            changedReduxBulkData?.length &&
              updateReduxAfterAssign(updatedReduxSlaData);
            setOpenAssignPopup?.(false);

            setOpenPopup(false);
            setAgentEmptyError(false);
            setTeamEmptyError(false);
            setAssigneeTeamData(null);
            setAssigneeUserData(null);
            setIsButtonLoading(false);
          },
          onError: (error) => {
            setEngamentAssignedSnackBar({
              openSnackBar: true,
              severity: "error",
              title: "locked_engament_assign_failed_title",
              message: isTeamLeader
                ? "locked_engament_assign_failed"
                : "locked_engament_assign_to_you_failed",
            });
            setIsButtonLoading(false);
          },
        });
      } else {
        let queryData = {
          new_user_id: selectedOptions?.userId
            ? +selectedOptions?.userId
            : null,
          old_user_id: reduxSlaData[reduxSlaKey]?.userId
            ? +reduxSlaData[reduxSlaKey]?.userId
            : null,
          //selected team
          new_user_team_id: selectedOptions?.teamId
            ? +selectedOptions?.teamId
            : null,
          // previos selected team
          old_user_team_id: reduxSlaData[reduxSlaKey]?.teamId
            ? +reduxSlaData[reduxSlaKey]?.teamId
            : null,
          interaction_id: selectedCard?.tweet_id || selectedCard?.id,
          monitor_id: selectedCard?.tracker_id,
          data_source_name: selectedCard?.data_source,
          product_id: +window.localStorage.getItem("engagementsProductId"),
        };
        EngagementsController.assignTeamsAndUsers({ ...queryData }).then(
          (res) => {
            if (!res?.errorMsg) {
              // if success we update userId and teamId at redux to reflect here and at card
              let slaDataCopy = { ...reduxSlaData };
              slaDataCopy.teams =
                teamsDataToUpdate?.teams?.length > 0
                  ? teamsDataToUpdate?.teams
                  : [...reduxSlaData?.teams];
              let updatedSlaData = {
                ...slaDataCopy[reduxSlaKey],
                userId: +selectedOptions?.userId || null,
                teamId: +selectedOptions?.teamId || null,
              };
              let updatedReduxSlaData = {
                ...slaDataCopy,
                [reduxSlaKey]: { ...updatedSlaData },
              };
              setEngamentAssignedSnackBar({
                openSnackBar: true,
                severity: "success",
                title: "",
                message: isTeamLeader
                  ? "locked_engament_assigned_successfully"
                  : "locked_engament_assigned_to_you_successfully",
              });
              updateReduxAfterAssign(updatedReduxSlaData);
              // dispatch(slaData(updatedReduxSlaData));
              setOpenPopup(false);
              setAgentEmptyError(false);
              setTeamEmptyError(false);
              setAssigneeTeamData(null);
              setAssigneeUserData(null);
            } else {
              setEngamentAssignedSnackBar({
                openSnackBar: true,
                severity: "error",
                title: "locked_engament_assign_failed_title",
                message: isTeamLeader
                  ? "locked_engament_assign_failed"
                  : "locked_engament_assign_to_you_failed",
              });
            }
            setIsButtonLoading(false);
          },
        );
      }
    }
  };

  const handleLockedPopupClose = () => {
    if (isButtonLoading) return;
    setOpenAssignPopup?.(false);
    setOpenPopup(false);
    setAgentEmptyError(false);
    setTeamEmptyError(false);
    setAssigneeTeamData(null);
    setAssigneeUserData(null);
  };
  return (
    <Box className="locked-engagement">
      <Box className="locked-engagement-content">
        <FontAwesomeIcon icon={faLock} />
        <Box className="locked-engagement-context">
          <Typography variant="h6">
            {!assignedUserData?.length
              ? CheckValueLocale("unassigned_engagement", "", {}, intl)
              : CheckValueLocale(
                  "assigned_engagement_to_someone",
                  "",
                  { name: assignedUserName },
                  intl,
                )}
          </Typography>
          <Typography variant="p" className="locked-engagement-desc">
            {CheckValueLocale("locked_engagement_desc", "", {}, intl)}
          </Typography>
        </Box>
      </Box>

      <LucButton
        size="small"
        id="locked-engagement-assign-to-me-btn"
        onClick={() => setOpenPopup(true)}
      >
        {CheckValueLocale(
          isTeamLeader ? "assign_to_agent" : "assign_to_me",
          "",
          {},
          intl,
        )}
      </LucButton>

      <PopupModal
        classeName="locked-engagement-assign-modal"
        addClasses="actions-buttons-shared"
        minWidth="600px"
        open={isBulk ? openAssignPopup : openPopup}
        accept={() =>
          handleAssignSubmit({
            userId: assigneeUserData,
            teamId: assigneeTeamData,
          })
        }
        close={() => handleLockedPopupClose()}
        body={
          <LockedEngagementAssignModal
            userSignedIn={userSignedIn}
            isTeamLeader={isTeamLeader}
            reduxSlaKey={reduxSlaKey}
            getAssigneeTeamData={getAssigneeTeamData}
            getAssigneeUserData={getAssigneeUserData}
            getTeamsDataToUpdate={getTeamsDataToUpdate}
            trackerId={trackerId}
            teamEmptyError={teamEmptyError}
            agentEmptyError={agentEmptyError}
            isBulk={isBulk}
            reduxBulkData={reduxBulkData}
            teamAlgorithms={teamAlgorithms}
            choosenAlgorithmId={choosenAlgorithmId}
            setChoosenAlgorithmId={setChoosenAlgorithmId}
            choosenAssign={choosenAssign}
            setChoosenAssign={setChoosenAssign}
          />
        }
        title={CheckValueLocale(
          isTeamLeader
            ? "leader_engagement_assign_modal_title"
            : "agent_engagement_assign_modal_title",
          "",
          {},
          intl,
        )}
        leftBtn={CheckValueLocale("cancel", "", {}, intl)}
        rightBtn={CheckValueLocale("assign_eng_modal_btn", "", {}, intl)}
        isRightBtnLoading={isButtonLoading}
      />
    </Box>
  );
};

export default LockedEngagement;
