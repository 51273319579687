import {
  Alert,
  Box,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import "./engagementSurvey.scss";
import ChatSurvey from "./ChatSurvey";
import ShareSurvey from "./ShareSurvey";

const EngagementSurvey = ({
  surveyData,
  setSurveyData,
  GreetingAndFarewellBasicSwitch,
  selectedTap,
}) => {
  const intl = useIntl();
  const selectedTabData = surveyData?.selectedData?.[selectedTap];
  const handleSwitchChange = () => {
    surveyData.selectedData[selectedTap].isEnabled =
      !surveyData.selectedData[selectedTap].isEnabled;
    setSurveyData({ ...surveyData });
  };

  const handleChangeSendAs = (e) => {
    surveyData.selectedData[selectedTap].sendAs = e.target.value;
    setSurveyData({ ...surveyData });
  };

  return (
    <Box id="main-engagement-survey" className={"main-grating-countainer"}>
      <GreetingAndFarewellBasicSwitch
        title="engag_survey_tit"
        description="engag_survey_disc"
        checked={selectedTabData?.isEnabled}
        handleChange={() => handleSwitchChange()}
      />
      {surveyData.selectedData[selectedTap].isEnabled ? (
        <>
          <Divider />
          <Alert severity={"info"} className="survey-egag-alert">
            {CheckValueLocale("survey-engag-alert", "", {}, intl)}
          </Alert>
          <RadioGroup
            value={selectedTabData?.sendAs}
            onChange={handleChangeSendAs}
          >
            {surveyData?.mainData?.sendAs?.map((item) => (
              <FormControlLabel
                value={item?.id}
                control={<Radio />}
                label={CheckValueLocale(item?.text, "", {}, intl)}
              />
            ))}
          </RadioGroup>
          {surveyData?.mainData?.sendAs?.filter(
            (item) => item?.id == surveyData.selectedData[selectedTap].sendAs,
          )?.[0]?.text === "send_in_chat" ? (
            <ChatSurvey
              surveyData={surveyData}
              setSurveyData={setSurveyData}
              selectedTap={selectedTap}
            />
          ) : (
            <ShareSurvey
              surveyData={surveyData}
              setSurveyData={setSurveyData}
              selectedTap={selectedTap}
            />
          )}
        </>
      ) : null}
    </Box>
  );
};

export default EngagementSurvey;
