import { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import LineChartWidget from "components/widgets/lineChartWidget";
import PieChartWidget from "components/widgets/pieChartWidget";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import SectionTitle from "pages/alerts/components/sectionTitle/sectionTitle";
import AlertStatistics from "pages/alerts/components/alertStatistics/alertStatistics";
import InteractionsList from "components/interactions-list/InteractionsList.js";

const InstagramPublic = ({
  alertDetailMonitorId,
  response,
  dataSources,
  resetAllFields,
}) => {
  const initialWidgetsState = {
    //header alert widget
    headerAlertWidget: {},
    headerAlertWidgetPreLoaderDataSources: [],

    //posts volume widget
    postsVolume: [],
    postsVolumePreLoader: [],

    //top keywords widget
    topKeywords: [],
    topKeywordsPreLoaderDataSources: [],

    //top hastags widget
    topHashtags: [],
    topHashtagsPreLoaderDataSources: [],

    //top engaged sites widget
    topEngagedSites: [],
    topEngagedSitesPreLoaderDataSources: [],

    //top engaged sites widget
    topSites: [],
    topSitesPreLoaderDataSources: [],

    //top posts widget
    topPosts: [],
    topPostsPreLoaderDataSources: [],

    //top engagers widget
    topEngagers: [],
    topEngagersPreLoaderDataSources: [],

    //top languages widget
    topLanguage: [],
    topLanguagePreLoaderDataSources: [],

    //engagaments distrbution widget
    engagementDistribtion: [],
    engagementDistribtionPreLoaderDataSources: [],

    //comments setntiment analysis widget
    commentsSentimentAnalysis: [],
    commentsSentimentAnalysisPreLoaderDataSources: [],

    //dialects & subDialects widget
    dialects: [],
    subDialects: [],
    dialectsSubDialectsPreLoaderDataSources: [],
  };

  const [alertViewWidgets, setAlertViewWidgets] = useState(initialWidgetsState);

  useEffect(() => {
    switch (response?.eventName) {
      case "Instagram__NewPostAlert__header_alert_widget":
      case "Instagram__TrendingAlert__header_alert_widget":
      case "Instagram__VerifiedAuthorAlert__header_alert_widget":
      case "Instagram__NegativePostsAlert__header_alert_widget":
      case "Instagram__HighVolumeReachAlert__header_alert_widget":
      case "Instagram__InfluencerAlert__header_alert_widget":
      case "Instagram__ViralTweetAlert__header_alert_widget":
        setAlertViewWidgets({
          ...alertViewWidgets,
          headerAlertWidget: response?.eventData,
          headerAlertWidgetPreLoaderDataSources: [response?.monitor_id],
        });
        break;
      case "Instagram__NewPostAlert__volume_overtime":
      case "Instagram__VerifiedAuthorAlert__volume_overtime":
      case "Instagram__NegativePostsAlert__volume_overtime":
      case "Instagram__TrendingAlert__volume_overtime":
      case "Instagram__HighVolumeReachAlert__volume_overtime":
      case "Instagram__ViralTweetAlert__volume_overtime":
      case "Instagram__InfluencerAlert__volume_overtime":
        setAlertViewWidgets({
          ...alertViewWidgets,
          postsVolume: response?.eventData?.posts_over_time,
          postsVolumePreLoader: [response?.monitor_id],
        });
        break;
      case "Instagram__NewPostAlert__top_keywords":
      case "Instagram__VerifiedAuthorAlert__top_keywords":
      case "Instagram__TrendingAlert__top_keywords":
      case "Instagram__NegativePostsAlert__top_keywords":
      case "Instagram__HighVolumeReachAlert__top_keywords":
      case "Instagram__ViralTweetAlert__top_keywords":
      case "Instagram__InfluencerAlert__top_keywords":
        setAlertViewWidgets({
          ...alertViewWidgets,
          topKeywords: response?.eventData?.top_keywords,
          topKeywordsPreLoaderDataSources: [response?.monitor_id],
        });
        break;
      case "Instagram__NewPostAlert__top_hashtags":
      case "Instagram__VerifiedAuthorAlert__top_hashtags":
      case "Instagram__TrendingAlert__top_hashtags":
      case "Instagram__NegativePostsAlert__top_hashtags":
      case "Instagram__HighVolumeReachAlert__top_hashtags":
      case "Instagram__ViralTweetAlert__top_hashtags":
      case "Instagram__InfluencerAlert__top_hashtags":
        setAlertViewWidgets({
          ...alertViewWidgets,
          topHashtags: response?.eventData?.top_hashtags,
          topHashtagsPreLoaderDataSources: [response?.monitor_id],
        });
        break;

      case "Instagram__NewPostAlert__top_posts_comments":
      case "Instagram__VerifiedAuthorAlert__top_posts_comments":
      case "Instagram__TrendingAlert__top_posts_comments":
      case "Instagram__NegativePostsAlert__top_posts_comments":
      case "Instagram__HighVolumeReachAlert__top_posts_comments":
      case "Instagram__ViralTweetAlert__top_posts_comments":
      case "Instagram__InfluencerAlert__top_posts_comments":
        setAlertViewWidgets({
          ...alertViewWidgets,
          topPosts: response?.eventData?.top_posts,
          topPostsPreLoaderDataSources: [response?.monitor_id],
        });
        break;
      case "Instagram__NewPostAlert__top_engagers":
      case "Instagram__VerifiedAuthorAlert__top_engagers":
      case "Instagram__TrendingAlert__top_engagers":
      case "Instagram__NegativePostsAlert__top_engagers":
      case "Instagram__HighVolumeReachAlert__top_engagers":
      case "Instagram__ViralTweetAlert__top_engagers":
      case "Instagram__InfluencerAlert__top_engagers":
        setAlertViewWidgets({
          ...alertViewWidgets,
          topEngagers: response?.eventData?.top_engagers,
          topEngagersPreLoaderDataSources: [response?.monitor_id],
        });
        break;
      case "Instagram__NewPostAlert__top_verified_engagers":
      case "Instagram__TrendingAlert__top_verified_engagers":
      case "Instagram__VerifiedAuthorAlert__top_verified_engagers":
      case "Instagram__NegativePostsAlert__top_verified_engagers":
      case "Instagram__HighVolumeReachAlert__top_verified_engagers":
      case "Instagram__ViralTweetAlert__top_verified_engagers":
      case "Instagram__InfluencerAlert__top_verified_engagers":
        setAlertViewWidgets({
          ...alertViewWidgets,
          topVerifiedEngagers: response?.eventData?.top_verified_engagers,
          topVerifiedEngagersPreLoaderDataSources: [response?.monitor_id],
        });
        break;
      case "Instagram__NewPostAlert__top_influencers":
      case "Instagram__VerifiedAuthorAlert__top_influencers":
      case "Instagram__TrendingAlert__top_influencers":
      case "Instagram__NegativePostsAlert__top_influencers":
      case "Instagram__HighVolumeReachAlert__top_influencers":
      case "Instagram__ViralTweetAlert__top_influencers":
      case "Instagram__InfluencerAlert__top_influencers":
        setAlertViewWidgets({
          ...alertViewWidgets,
          topInfleuncers: response?.eventData?.top_influencers,
          topInfleuncersPreLoaderDataSources: [response?.monitor_id],
        });
        break;
      case "Instagram__NewPostAlert__account_types":
      case "Instagram__VerifiedAuthorAlert__account_types":
      case "Instagram__TrendingAlert__account_types":
      case "Instagram__NegativePostsAlert__account_types":
      case "Instagram__HighVolumeReachAlert__account_types":
      case "Instagram__ViralTweetAlert__account_types":
      case "Instagram__InfluencerAlert__account_types":
        setAlertViewWidgets({
          ...alertViewWidgets,
          accountTypes: response?.eventData?.account_types,
          accountTypesPreLoaderDataSources: [response?.monitor_id],
        });
        break;
      case "Instagram__NewPostAlert__dialects_and_sub_dialects":
      case "Instagram__VerifiedAuthorAlert__dialects_and_sub_dialects":
      case "Instagram__TrendingAlert__dialects_and_sub_dialects":
      case "Instagram__NegativePostsAlert__dialects_and_sub_dialects":
      case "Instagram__HighVolumeReachAlert__dialects_and_sub_dialects":
      case "Instagram__ViralTweetAlert__dialects_and_sub_dialects":
        setAlertViewWidgets({
          ...alertViewWidgets,
          dialects: response?.eventData?.dialects,
          subDialects: response?.eventData?.sub_dialects,
          dialectsSubDialectsPreLoaderDataSources: [response?.monitor_id],
        });
        break;
      case "Instagram__NewPostAlert__sentiment_analysis":
      case "Instagram__VerifiedAuthorAlert__sentiment_analysis":
      case "Instagram__TrendingAlert__sentiment_analysis":
      case "Instagram__NegativePostsAlert__sentiment_analysis":
      case "Instagram__HighVolumeReachAlert__sentiment_analysis":
      case "Instagram__ViralTweetAlert__sentiment_analysis":
      case "Instagram__InfluencerAlert__sentiment_analysis":
        setAlertViewWidgets({
          ...alertViewWidgets,
          commentsSentimentAnalysis: response?.eventData?.sentiment_piechart,
          commentsSentimentAnalysisPreLoaderDataSources: [response?.monitor_id],
        });
        break;

      default:
        break;
    }
  }, [response]);

  useEffect(() => {
    // Avoid Initial State Calling
    if (resetAllFields !== 1) {
      setAlertViewWidgets({ ...initialWidgetsState });
    }
  }, [resetAllFields]);
  return (
    <>
      <AlertStatistics
        alertDataSource={"instagram"}
        headerAlertWidget={alertViewWidgets?.headerAlertWidget}
        headerAlertWidgetPreLoaderDataSources={
          alertViewWidgets?.headerAlertWidgetPreLoaderDataSources
        }
        preLoaderTrackerDataSources={alertDetailMonitorId}
        isCA
      />
      <Box mb={3}>
        <LineChartWidget
          title={"alerts_posts_overtime"}
          toolTipLabel={"total_interactions"}
          data={alertViewWidgets?.postsVolume}
          showDataZoom={false}
          lineChartPreLoaderDataSources={alertViewWidgets?.postsVolumePreLoader}
          preLoaderTrackerDataSources={alertDetailMonitorId}
          checkSymbolZize
        />
      </Box>
      <Box mb={5} className="new-posts-countiner">
        <SectionTitle sectionTitle="alert_new_posts_section" />
        <InteractionsList
          data={alertViewWidgets?.topPosts?.slice(0, 6)}
          dataSources={dataSources}
          latestInteractionsPreLoaderDataSources={
            alertViewWidgets?.topPostsPreLoaderDataSources
          }
          preLoaderTrackerDataSources={alertDetailMonitorId}
        />
      </Box>
      <SectionTitle sectionTitle="metrics" />
      <Grid container spacing={2.5}>
        <Grid item xs={6}>
          <PieChartWidget
            title={"alert_sentiment_analysis"}
            data={alertViewWidgets?.commentsSentimentAnalysis}
            areaStyle={true}
            showDownloadIcon
            chartType={"sentiment"}
            pieChartPreLoaderDataSources={
              alertViewWidgets?.commentsSentimentAnalysisPreLoaderDataSources
            }
            preLoaderTrackerDataSources={alertDetailMonitorId}
            showAI
            color={["#89BB2A", "#E50035", "#F9A700"]}
          />
        </Grid>

        <Grid item xs={6}>
          <PieChartWidget
            title={"dialects_subdialects"}
            data={alertViewWidgets?.dialects}
            subData={alertViewWidgets?.subDialects}
            areaStyle={true}
            showAI
            showDownloadIcon
            pieChartPreLoaderDataSources={
              alertViewWidgets?.dialectsSubDialectsPreLoaderDataSources
            }
            preLoaderTrackerDataSources={alertDetailMonitorId}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2.5} mt={4} mb={4}>
        <Grid item md={6}>
          <TableWidget
            wordsList
            title={"alert_top_keywords"}
            leftColomnTitle={"keywords_tab_title"}
            rightColomnTitle={"number_of_posts"}
            data={alertViewWidgets?.topKeywords}
            errorMessage={"no_keywords_found"}
            showDownloadIcon
            bigImg
            keywords
            tablePagination
            count={alertViewWidgets?.topKeywords?.length}
            preLoaderTrackerDataSources={alertDetailMonitorId}
            tableChartPreLoaderDataSources={
              alertViewWidgets?.topKeywordsPreLoaderDataSources
            }
            withWordCloud
          />
        </Grid>
        <Grid item md={6}>
          <TableWidget
            wordsList
            title={"alert_top_hashtags"}
            leftColomnTitle={"hashtags"}
            rightColomnTitle={"number_of_posts"}
            data={alertViewWidgets?.topHashtags}
            errorMessage={"no_hashtags_found"}
            showDownloadIcon
            bigImg
            keywords
            tablePagination
            count={alertViewWidgets?.topHashtags?.length}
            preLoaderTrackerDataSources={alertDetailMonitorId}
            tableChartPreLoaderDataSources={
              alertViewWidgets?.topHashtagsPreLoaderDataSources
            }
            withWordCloud
          />
        </Grid>
      </Grid>
      <Grid container spacing={2.5} mt={4} mb={4}>
        <Grid item md={6}>
          <TableWidget
            wordsList
            title={"alert_most_engaged_contacts"}
            leftColomnTitle={"alert_username"}
            rightColomnTitle={"number_of_posts"}
            data={alertViewWidgets?.topEngagers}
            errorMessage={"no_hashtags_found"}
            showDownloadIcon
            bigImg
            keywords
            tablePagination
            count={alertViewWidgets?.topEngagers?.length}
            preLoaderTrackerDataSources={alertDetailMonitorId}
            tableChartPreLoaderDataSources={
              alertViewWidgets?.topEngagersPreLoaderDataSources
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default InstagramPublic;
