import { Alert, Box, Skeleton } from "@mui/material";
import { useCategoriesOptions } from "components/sentimentCategoriesThemes/hooks/useCategoriesOptions";
import { useSentimentsOptions } from "components/sentimentCategoriesThemes/hooks/useSentimentsOptions";
import { useThemesOptions } from "components/sentimentCategoriesThemes/hooks/useThemesOptions";
import WidgetHeader from "components/widgets/widgetHeader";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { CsatFeedbackItem } from "./CsatFeedbackItem";
import "./AgentCsatFeedback.scss";
import FetchingData from "components/fetchingData/fetchingData";
import NoDataFound from "components/no-Data/noDataFound";
import LucButton from "shared/lucButton/lucButton";

export const AgentCsatFeedback = ({
  unixDate,
  title,
  titleToolTip,
  agentCsatPage,
  handleChangePage,
  agentCsatCount,
  data,
  agentCsatLoading,
  isAgentCsatPagination,
}) => {
  const intl = useIntl();

  const { data: themesOptions } = useThemesOptions({ locale: intl?.locale });
  const { data: categoriesOptions } = useCategoriesOptions({
    locale: intl?.locale,
  });
  const { data: sentimentsOptions } = useSentimentsOptions({
    locale: intl?.locale,
  });

  return (
    <Box className="survey-csat-main-widget">
      <WidgetHeader title={title} titleToolTip={titleToolTip} />
      <Box className="csat-responses">
        {agentCsatPage < 2 && agentCsatLoading ? (
          <Skeleton variant="rounded" width={150} height={15} />
        ) : (
          CheckValueLocale("csat_responses", "", { num: agentCsatCount }, intl)
        )}
      </Box>
      <Box className="main-csat-items">
        {data?.length ? (
          <>
            <Alert severity={"info"} className="survey-egag-alert">
              {CheckValueLocale("agent_feedback_alert", "", {}, intl)}
            </Alert>
            {data?.map((item) => (
              <CsatFeedbackItem
                data={item}
                unixDate={unixDate}
                themesOptions={themesOptions}
                categoriesOptions={categoriesOptions}
                sentimentsOptions={sentimentsOptions}
              />
            ))}
          </>
        ) : agentCsatLoading ? (
          <>
            <FetchingData />
          </>
        ) : (
          <NoDataFound />
        )}

        {data?.length > 0 &&
        data?.length < agentCsatCount &&
        isAgentCsatPagination ? (
          <Box className="agent-feedback-load-more-btn">
            <LucButton
              onClick={() => handleChangePage(agentCsatPage + 1)}
              variant="outline"
              disabled={agentCsatLoading}
              loading={agentCsatLoading}
              id="agent-feedback-load-more"
            >
              {CheckValueLocale("show_more_page", "", {}, intl)}
            </LucButton>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};
