import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import FetchingData from "components/fetchingData/fetchingData";
import WidgetHeader from "./widgetHeader.js";
import _ from "lodash";
import { CheckValueLocale } from "utils/helpers/index.js";
const ClientEnangement = (props) => {
  const intl = useIntl();
  let clientEngagement = props.clientEngagement;
  return (
    <div className="chart" id={props.title}>
      <WidgetHeader
        title={props.title}
        chartId={props.title}
        showDownloadIcon={props.showDownloadIcon}
      />
      {_.isEqual(
        props?.clientEngagementPreLoaderDataSources?.sort(),
        props?.preLoaderTrackerDataSources?.sort(),
      ) !== true ||
      !props?.clientEngagementPreLoaderDataSources?.length ||
      !props?.preLoaderTrackerDataSources?.length ? (
        <Box sx={{ position: "relative", top: "45%", right: 0 }}>
          <FetchingData />
        </Box>
      ) : (
        <div className="client-engagement">
          <div className="content-engagement">
            <div className="info-engagement">
              <div className="info">
                <div className="fake_line"></div>
                <div className="title_wrapper">
                  <p className="label">
                    {CheckValueLocale("total_sent", "", {}, intl)}
                  </p>
                  <p>{clientEngagement && clientEngagement.total_sent}</p>
                </div>
              </div>

              <div className="info">
                <div className="fake_line"></div>
                <div className="title_wrapper">
                  <p className="label">
                    {CheckValueLocale("avg_time", "", {}, intl)}
                  </p>
                  <p className="avg-time response-time">
                    {clientEngagement !== undefined ? (
                      <>
                        <p>
                          <b>{clientEngagement?.avg_time_until_read.hour}</b>
                          <span>{CheckValueLocale("hour", "", {}, intl)}</span>
                        </p>
                        <p>
                          <b>{clientEngagement?.avg_time_until_read.minute}</b>
                          <span>
                            {CheckValueLocale("minutes", "", {}, intl)}
                          </span>
                        </p>
                        <p>
                          <b>{clientEngagement?.avg_time_until_read.second}</b>
                          <span>
                            {CheckValueLocale("seconds", "", {}, intl)}
                          </span>
                        </p>
                      </>
                    ) : null}
                  </p>
                </div>
              </div>

              <div className="info">
                <div className="fake_line"></div>
                <div className="title_wrapper">
                  <p className="label">
                    {CheckValueLocale("instant_response", "", {}, intl)}
                  </p>
                  <p>{clientEngagement && clientEngagement.instant_response}</p>
                </div>
              </div>
            </div>
            <div className="info-engagement">
              <div className="info">
                <div className="fake_line"></div>
                <div className="title_wrapper">
                  <p className="label">
                    {CheckValueLocale("read_messages_rate", "", {}, intl)}
                  </p>
                  <p>
                    {clientEngagement &&
                      clientEngagement.read_messages_rate.toFixed(2)}
                    %
                  </p>
                </div>
              </div>
              <div className="info">
                <div className="fake_line"></div>
                <div className="title_wrapper">
                  <p className="label">
                    {CheckValueLocale("response_rate", "", {}, intl)}
                  </p>
                  <p>
                    {clientEngagement &&
                      clientEngagement.response_rate.toFixed(2)}
                    %
                  </p>
                </div>
              </div>
              <div className="info">
                <div className="fake_line"></div>
                <div className="title_wrapper">
                  <p className="label">
                    {CheckValueLocale("response_time", "", {}, intl)}
                  </p>

                  <p className="response-time">
                    {clientEngagement !== undefined ? (
                      <>
                        <p>
                          <b>{clientEngagement?.response_time.day}</b>
                          <span>
                            {CheckValueLocale(
                              "response_time_day",
                              "",
                              {},
                              intl,
                            )}
                          </span>
                        </p>
                        <p>
                          <b>{clientEngagement?.response_time.hour}</b>
                          <span>{CheckValueLocale("hour", "", {}, intl)}</span>
                        </p>
                        <p>
                          <b>{clientEngagement?.response_time.minutes}</b>
                          <span>
                            {CheckValueLocale("minutes", "", {}, intl)}
                          </span>
                        </p>
                        <p>
                          <b>{clientEngagement?.response_time.seconds}</b>
                          <span>
                            {CheckValueLocale("seconds", "", {}, intl)}
                          </span>
                        </p>
                      </>
                    ) : null}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientEnangement;
