import { Box, Divider } from "@mui/material";
import "./twoFactorAuthLogin.scss";
import FormTitle from "../components/formTitle";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import TextContent2FA from "pages/profile/components/securityProfileSection/components/twoFactorAuthentication/components/popupToEnable2FA/popupBody/TextContent2FA/TextContent2FA";
import LucButton from "shared/lucButton/lucButton";
import TwoFAMethodNameWithIcon from "pages/profile/components/securityProfileSection/components/twoFactorAuthentication/components/twoFAMethodNameWithIcon/twoFAMethodNameWithIcon";
import SixDigitsCodeInput from "pages/profile/components/securityProfileSection/components/twoFactorAuthentication/components/popupToEnable2FA/popupBody/sixDigitsCodeInput/sixDigitsCodeInput";
import ResendCode from "pages/profile/components/securityProfileSection/components/twoFactorAuthentication/components/popupToEnable2FA/popupBody/resendCode/resendCode";
import RememberMeCheckBox from "./components/rememberMeCheckBox";
import PhoneNumberInput from "pages/profile/components/securityProfileSection/components/twoFactorAuthentication/components/popupToEnable2FA/popupBody/phoneNumberInput/phoneNumberInput";
import FormButton from "../components/formButton";
import QRTwoFactorAuthCode from "pages/profile/components/securityProfileSection/components/twoFactorAuthentication/components/popupToEnable2FA/popupBody/qrTwoFactorAuthCode/QRTwoFactorAuthCode";
import { useResendCodeLogin } from "../hooks/useResendCodeLogin";

const TwoFactorAuthLogin = ({
  stats,
  data2FALoginPage,
  setData2FALoginPage,
  callApiLogin2FA,
  errorCodeMsg,
  setErrorCodeMsg,
  codeVerify,
  setCodeVerify,
  handleErrorSnackBar,
}) => {
  const intl = useIntl();
  const [showFirstPageToEnable2FA, setShowFirstPageToEnable2FA] =
    useState(true);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [showQRcode, setShowQRcode] = useState(true);
  const [rememberMe, setRememberMe] = useState(false);
  const [tryAnotherWay, setTryAnotherWay] = useState(false);

  //hooks
  const { mutate: resenCodeUser2FA, isPending: isLoadingResendCode } =
    useResendCodeLogin();

  const handleTwoFactorAuthLogin = () => {
    if (data2FALoginPage?.step == 1 && showFirstPageToEnable2FA) {
      setShowFirstPageToEnable2FA(false);
      //to show snackbar in home page if login success after step 5
      localStorage?.setItem("two_fa_success_login", false);
      setData2FALoginPage((prev) => ({
        ...prev,
        title: "choose_your_login_method",
        description: "choose_your_login_method_description",
        textBtn: "",
      }));
    } else {
      //handle with integration
      // if step 2 or 5 , it will send otp tp back-end to verify
      //if step 3 , it will send phone number
      //if step 4 , it will not send any things but must call api
      if (data2FALoginPage?.step == 2 || data2FALoginPage?.step == 5)
        callApiLogin2FA({
          otp: codeVerify,
          ...(data2FALoginPage?.step === 5
            ? {
                two_fa_remember_me: rememberMe,
                lang: localStorage?.getItem("lang") || "en",
              }
            : {}),
        });
      else if (data2FALoginPage?.step == 3)
        callApiLogin2FA({ phone_number: phoneNumber });
      else if (data2FALoginPage?.step == 4) callApiLogin2FA({});
    }
  };

  const chooseMethodToLogin = (method) => {
    callApiLogin2FA({ type: method });
    setData2FALoginPage((prev) => ({
      ...prev,
      method: method,
    }));
  };

  const handleTryAnotherWay = () => {
    setTryAnotherWay(true);
    callApiLogin2FA({ try_another_way: true });
    setShowFirstPageToEnable2FA(false);
  };

  //handle resend code
  const handleResendCode = () => {
    resenCodeUser2FA(undefined, {
      onSuccess: (response) => {
        const data = response?.data?.data?.data;
        setCodeVerify("");
        setErrorCodeMsg("");
        setData2FALoginPage((prev) => ({
          ...prev,
          expires_at: data?.expires_at,
        }));
      },
      onError: (error) => {
        // to handle Unauthorized error for 2FA login (reload page)

        if (data2FALoginPage?.step !== 0 && error?.error?.status === 401) {
          window?.location?.reload();
        }

        if (
          error?.errorMsg?.response?.data?.message === "User email is bounced"
        ) {
          handleErrorSnackBar(
            "email_bounced_description",
            "email_bounced_title",
          );
        } else handleErrorSnackBar("try_again", "something_went_wrong");
      },
    });
  };

  //to change text for popup with QR code or TOTP
  useEffect(() => {
    if (data2FALoginPage?.qr_code)
      setData2FALoginPage((prev) => ({
        ...prev,
        description: showQRcode
          ? "text_scan_qr_code_to_enable_app"
          : "text_enter_totp_secret_to_enable_app",
      }));
  }, [showQRcode]);

  useEffect(() => {
    //to set the page methods when click on try anthor way
    if (data2FALoginPage?.step == 1 && !showFirstPageToEnable2FA)
      setData2FALoginPage((prev) => ({
        ...prev,
        title: "choose_your_login_method",
        description: "choose_your_login_method_description",
        textBtn: "",
      }));
  }, [data2FALoginPage?.step]);

  useEffect(() => {
    if (!stats?.loginLoading) {
      setTryAnotherWay(false);
    }
  }, [stats?.loginLoading]);

  return (
    <Box id="two-factor-auth-login-page">
      <FormTitle
        text={CheckValueLocale(data2FALoginPage?.title, "", {}, intl)}
      />
      <TextContent2FA
        bodyText={data2FALoginPage?.description}
        contact={data2FALoginPage?.contact}
      />

      {/* this to choose one method  */}
      {data2FALoginPage?.step == 1 && !showFirstPageToEnable2FA ? (
        <Box>
          {data2FALoginPage?.methods?.map((method, index) => (
            <>
              <Box
                className="two-factor-auth-login-method-item"
                onClick={() =>
                  !stats?.loginLoading && chooseMethodToLogin(method)
                }
                key={index}
              >
                <TwoFAMethodNameWithIcon method={method} />
              </Box>

              {index != data2FALoginPage?.methods?.length - 1 ? (
                <Divider className="divider-two-factor-auth-login-method-item" />
              ) : null}
            </>
          ))}
        </Box>
      ) : null}

      {data2FALoginPage?.showSixCodeInput ? (
        <SixDigitsCodeInput
          setCodeVerify={setCodeVerify}
          codeVerify={codeVerify}
          errorCodeMsg={errorCodeMsg}
          setErrorCodeMsg={setErrorCodeMsg}
        />
      ) : null}

      {data2FALoginPage?.showPhoneNumber ? (
        <PhoneNumberInput
          setPhoneNumber={setPhoneNumber}
          setDisabledBtn={setDisabledBtn}
        />
      ) : null}

      {data2FALoginPage?.qr_code ? (
        <QRTwoFactorAuthCode
          qrCode={data2FALoginPage?.qr_code}
          totpSecret={data2FALoginPage?.totp_secret}
          showQRcode={showQRcode}
          setShowQRcode={setShowQRcode}
        />
      ) : null}

      {data2FALoginPage?.textBtn ? (
        <Box>
          {data2FALoginPage?.showRememberMe ? (
            <RememberMeCheckBox
              rememberMe={rememberMe}
              setRememberMe={setRememberMe}
              disabled={stats?.loginLoading}
            />
          ) : null}
          <FormButton
            onClick={handleTwoFactorAuthLogin}
            loading={stats?.loginLoading && !tryAnotherWay}
            disabled={
              (data2FALoginPage?.showSixCodeInput &&
                codeVerify?.length !== 6) ||
              errorCodeMsg ||
              disabledBtn ||
              stats?.loginLoading
            }
            text={CheckValueLocale(data2FALoginPage?.textBtn, "", {}, intl)}
            id="two-factor-auth-login-page-btn"
          />
        </Box>
      ) : null}

      {data2FALoginPage?.showResendCode ||
      data2FALoginPage?.showTryAnotherWay ? (
        <Box className="resend-code-try-another-way-container">
          {data2FALoginPage?.showResendCode ? (
            <ResendCode
              textDisplay={"available_in"}
              expiredDate={data2FALoginPage?.expires_at}
              handleResendCode={handleResendCode}
              loadingBtn={isLoadingResendCode}
            />
          ) : null}

          {data2FALoginPage?.showTryAnotherWay ? (
            <LucButton
              onClick={handleTryAnotherWay}
              variant={"flat"}
              size="small"
              id="try-another-way-login-page"
              className={"try-another-way-position"}
              loading={stats?.loginLoading && tryAnotherWay}
              disabled={stats?.loginLoading}
            >
              {CheckValueLocale("try_another_way", "", {}, intl)}
            </LucButton>
          ) : null}
        </Box>
      ) : null}
    </Box>
  );
};

export default TwoFactorAuthLogin;
