import { Box, TextField, Typography } from "@mui/material";
import { WorkingHours } from "pages/settings/pages/userManagementPage/subPages/shardComponents/tabs/workingHours";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

export const TeamWorkingHours = ({ workingHours, autoReplyMessages }) => {
  const intl = useIntl();
  const companyTimezone = parseInt(localStorage.getItem("companyInfo") || 0);
  return (
    <Box className="view-team-details-section">
      <Typography className="working-hours-days-title">
        {CheckValueLocale("working_hours_days", "", {}, intl).toUpperCase()}
      </Typography>
      <Box className="view-team-working-hours-container">
        <Box className="tabs-section-container">
          <Box className="user-tabs-container">
            <Box className="user-tabs-content create-team-working-hours-row-container">
              <Box className="create-team-working-hours-container">
                <WorkingHours
                  tabsData={workingHours}
                  setTabsData={() => {}}
                  setTabsErrors={() => {}}
                  tabsErrors={{}}
                  disabled={true}
                  subtitle="set_team_working_days_hours"
                  type="team"
                  workingHoursTimezoneText={
                    <Typography
                      onClick={() => window.open("/settings", "_blank")}
                      className="create-team-working-hours-time-zone-title"
                    >
                      {CheckValueLocale(
                        "team_working_hours_company_timezone",
                        "",
                        {
                          tz:
                            companyTimezone >= 0
                              ? `+${companyTimezone}`
                              : companyTimezone,
                        },
                        intl,
                      )}
                      <Typography
                        className="create-team-working-hours-time-zone-title view-settings"
                        component="span"
                      >
                        {CheckValueLocale("view_settings", "", {}, intl)}
                      </Typography>
                    </Typography>
                  }
                />
              </Box>
              <Box className="team-auto-complete-column-container">
                <Typography className="create-team-auto-reply-text-area-label">
                  {CheckValueLocale(
                    "create_team_auto_reply_label",
                    "",
                    {},
                    intl,
                  )}
                </Typography>
                <TextField
                  fullWidth
                  className="create-team-auto-reply-text-area"
                  multiline
                  disabled
                  value={autoReplyMessages}
                  rows={4.7}
                  placeholder={CheckValueLocale(
                    "auto_reply_placeholder",
                    "",
                    {},
                    intl,
                  )}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
