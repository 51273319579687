import { useMutation } from "@tanstack/react-query";
import { InteractionsServices } from "services/api/interactionService";

export const useGetDatasources = () => {
  return useMutation({
    mutationFn: () => {
      return InteractionsServices.getDatasources();
    },
  });
};
