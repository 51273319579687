import React, { useEffect, useState } from "react";
import { Box, Container } from "@mui/material";
import InsightsPageHeader from "../insightsPageHeader.js";
import FiltersController from "services/controllers/filtersController";
import InsightsController from "services/controllers/insightsController.js";
import moment from "moment";
import {
  isEmptyObj,
  handleCAFiltersIds,
  handleCAFiltersVals,
  checkEmptyValuesinObjectOfArrays,
} from "utils/helpers";
import mqttRabbitMQResponce from "services/controllers/mqttRabbitMQResponce.js";
import {
  getDataSources,
  getDataSourceCategories,
  getChannelsUsage,
} from "../../channelAnalytics.hooks.js";
import { useParams } from "react-router-dom";
import styles from "../insightsPage.module.css";
import RatingBody from "./ratingBody";
import GoogleMyBusiness from "../../../../images/shared-images/gmb.svg";
import EmptyPage from "../emptyPage.js";
import EmptyHeader from "../emptyHeader.js";
import CircularLoading from "components/circularProgress.js";
import AlertMessage from "shared/alert/alertMessage.js";
import { getActiveParams } from "shared/channel-analytics-filter/activeFilters/activeFilters.js";
import { useSelector } from "react-redux";
import NoDataFoundProducts from "components/no-Data/index.js";
import NoDataLinks from "components/no-Data/noDataLinks.js";

const RatingPage = (props) => {
  /* Start Redux Declarations */
  const reduxStartDateUx = useSelector(
    (state) => state?.channelAnalytics?.startDateUnix,
  );
  const reduxEndDateUx = useSelector(
    (state) => state?.channelAnalytics?.endDateUnix,
  );
  const reduxFilterParams = useSelector(
    (state) => state?.channelAnalytics?.filterParams,
  );
  /* End Redux Declarations */

  const [response, setResponse] = useState([]);
  const [filterFields, setFilterFields] = useState({});
  const [dataSources, setDataSources] = useState([]);
  let startDate = reduxStartDateUx;
  let endDate = reduxEndDateUx;
  const mqttConnectionClosed = React.useRef(false);
  const clientMQTT = React.useRef();
  const urlParams = useParams();
  const dataSourceId = urlParams.data_source_id;
  const [resetAllFields, setResetAllFields] = useState(0);
  const [preLoaderTrackerDataSources, setPreLoaderTrackerDataSources] =
    useState([]);
  const [assignActiveFilters, setAssignActiveFilters] = useState({});
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [reachInteractionsLimit, setReachInteractionsLimit] = useState(false);
  const [reachInteractionsLimitAlert, setReachInteractionsLimitAlert] =
    useState(true);
  const [companyCanCreateChannel, setCompanyCanCreateChannel] = useState(false);
  const [checkAllDataExist, setCheckAllDataExist] = useState(false);
  const [checkDataIsEmpty, setCheckDataIsEmpty] = useState(false);
  const getCredentials = (startDate, endDate, filterParams) => {
    setResponse([]);
    InsightsController.getGMBCredentials(
      startDate,
      endDate,
      filterParams,
      window.localStorage.cxm_id,
      dataSourceId,
    ).then((data) => {
      if (!isEmptyObj(data?.data)) {
        setReachInteractionsLimit(data?.data?.interactions_limit_reach);
        setPreLoaderTrackerDataSources(data?.data?.data_sources);
        mqttRabbitMQResponce(clientMQTT, data?.data, setResponse);
      } else {
        mqttConnectionClosed.current = true;
      }
    });
  };

  const applyFilter = (startDate, endDate, filterParams) => {
    let selectedFilterParams = !checkEmptyValuesinObjectOfArrays(
      reduxFilterParams,
    )
      ? reduxFilterParams
      : assignActiveFilters;

    const resp = handleCAFiltersIds(selectedFilterParams, filterFields);
    const {
      dmMonitorListFullName,
      waitingFilterFullName,
      monitorListFullName,
    } = resp;
    selectedFilterParams = resp?.selectedFilterParams;
    setResetAllFields((prev) => prev + 1);

    selectedFilterParams = handleCAFiltersVals(
      dmMonitorListFullName,
      monitorListFullName,
      waitingFilterFullName,
      selectedFilterParams,
    );
    setAssignActiveFilters(selectedFilterParams);
    getCredentials(startDate, endDate, selectedFilterParams);
  };

  const getFiltersFileds = () => {
    FiltersController.getGmbFiltersFields(
      window.localStorage.cxm_id,
      dataSourceId,
    ).then((data) => {
      setFilterFields(data.data);
    });
  };

  useEffect(() => {
    getCategories();
    getChannelsUsage(setCompanyCanCreateChannel);
  }, []);

  const getCategories = () => {
    getDataSourceCategories("Ratings", setIsActive, setIsLoading);
  };

  useEffect(() => {
    if (isActive) {
      getDataSources(setDataSources);
      getFiltersFileds();
    }
  }, [isActive]);

  let activeFiltersArr = getActiveParams(assignActiveFilters);

  const icon = (
    <img
      src={GoogleMyBusiness}
      className="icon-after-title"
      alt="icon-google-my-business"
      width={"18px"}
    />
  );
  return (
    <Container maxWidth="xl" className={styles.insightsStyle}>
      {isLoading === true ? (
        <CircularLoading />
      ) : (
        <>
          {isActive ? (
            <>
              <InsightsPageHeader
                title={"google_my_business"}
                showNewChannelBtn={true}
                showDatePicker={true}
                showFiltersBtn={true}
                filterFields={filterFields}
                applyFilter={applyFilter}
                startDate={startDate}
                endDate={endDate}
                assignActiveFilters={assignActiveFilters}
                allActiveFilters={activeFiltersArr}
                showCheckbox
                isActive={isActive}
                companyCanCreateChannel={companyCanCreateChannel}
                sectionName="Ratings"
                luciFeature="Analytics"
                dataLoaded={checkAllDataExist}
                dataEmpty={checkDataIsEmpty}
              />

              <AlertMessage
                mainFlag={reachInteractionsLimit}
                alertMainFlag={reachInteractionsLimitAlert}
                setAlertMainFlag={setReachInteractionsLimitAlert}
                alertMessageId={"interactions_limit_alert"}
              />

              <RatingBody
                response={response}
                dataSources={dataSources}
                resetAllFields={resetAllFields}
                preLoaderTrackerDataSources={preLoaderTrackerDataSources}
                assignActiveFilters={assignActiveFilters}
                setAssignActiveFilters={setAssignActiveFilters}
                startDate={startDate}
                endDate={endDate}
                applyFilter={applyFilter}
                allActiveFilters={activeFiltersArr}
                filterFields={filterFields}
                icon={icon}
                setCheckAllDataExist={setCheckAllDataExist}
                setCheckDataIsEmpty={setCheckDataIsEmpty}
              />
            </>
          ) : (
            <div className="empty-box">
              <EmptyHeader
                title="Rating"
                showNewChannelBtn={true}
                showDatePicker={true}
                showFiltersBtn={true}
                companyCanCreateChannel={companyCanCreateChannel}
              />
              <Box className="empty-box-insights-page">
                <NoDataFoundProducts
                  image="ratings"
                  title="analyze_your_ratings"
                  description="click_new_channel"
                  linkOne="how_to_use"
                  linkOneHref={NoDataLinks.insights_link}
                  orCase="or_visit_the"
                  linkTwo="knowledge_base"
                />
              </Box>
            </div>
          )}
        </>
      )}
    </Container>
  );
};

export default RatingPage;
