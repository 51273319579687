import { useContext } from "react";
import { Box } from "@mui/material";
import { CreateReportContext } from "pages/reports/context/reportContext";
import ReportInformationStep from "../steps/reportInformationStep";
import ReportSettingSocialListening from "../steps/reportSettingSocialListening";
import ReportSettingsOmnichannel from "../steps/reportSettingsOmnichannel";
import ReportDeliveryStep from "../steps/reportDeliveryStep";

const CreateReportStepperContent = (props) => {
  const { step, formik, availableProducts } = useContext(CreateReportContext);

  const isSocialListeningReport =
    availableProducts?.idsToNames?.[formik?.values?.product_id] === "SM";

  return (
    <Box className="create-report-stepper-content-wrapper">
      {step === 0 ? (
        <ReportInformationStep />
      ) : step === 1 ? (
        isSocialListeningReport ? (
          <ReportSettingSocialListening />
        ) : (
          <ReportSettingsOmnichannel />
        )
      ) : (
        <ReportDeliveryStep />
      )}
    </Box>
  );
};

export default CreateReportStepperContent;
