import { memo, useContext } from "react";
import BreadCrumb from "components/breadCrumb";
import { CheckValueLocale } from "utils/helpers/index.js";
import { useIntl } from "react-intl";
import { ReportContext } from "../../context/reportContext";
import { useParams } from "react-router-dom";

const links = (reportId, title) => [
  {
    name: "reports",
    url: "reports/list",
    addNameLocal: true,
  },
  {
    name: title,
    url: `reports/view-report/${reportId}`,
    addNameLocal: true,
  },
];

const LinkReports = () => {
  const intl = useIntl();
  const { reportData } = useContext(ReportContext);
  const urlParams = useParams();

  return (
    <BreadCrumb
      id="breadcrumb-style-monitors"
      links={links(
        urlParams?.id,
        CheckValueLocale(
          "received_reports_for",
          "",
          { title: reportData?.report_name },
          intl,
        ),
      )}
    />
  );
};

export default memo(LinkReports);
