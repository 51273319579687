import DropdownQuestion from "./component/dropdownQuestion";
import EmailQuestion from "./component/emailQuestion";
import MultipleChoiceQuestion from "./component/multipleChoiceQuestion";
import NameQuestion from "./component/nameQuestion";
import NumberQuestion from "./component/numberQuestion";
import PhoneNoQuestion from "./component/phoneNoQuestion";
import ShortTextQuestion from "./component/shortTextQuestion";
import SocialMediaQuestion from "./component/socialMediaQuestion";
import YesNoQuestions from "./component/yesNoQuestions";
import LinearScaleQuestion from "./component/linearScaleQuestion";
import { Box } from "@mui/material";
import NpsQuestion from "./component/npsQuestion";

const SurveyQuestion = (props) => {
  const { questions, isAlert } = props;
  return (
    <>
      {questions?.map((section) => {
        return (
          <>
            {questions?.length > 1 ? (
              <Box
                className={`section-name-audience question-direction-${section?.text_direction || ""}`}
              >
                {section?.name ? section?.name : section?.section_name}
              </Box>
            ) : null}
            <Box
              className={`question-audience-box question-direction-${section?.text_direction || ""}`}
            >
              {section?.questions?.map((question, index) => {
                if (question?.type === "short_text") {
                  return (
                    <ShortTextQuestion
                      question={question}
                      questionNo={index + 1}
                      key={index}
                      questionAnswer={
                        question?.answer
                          ? question?.answer
                          : question?.answer_list
                      }
                    />
                  );
                } else if (question?.type === "multiple_choice") {
                  return (
                    <MultipleChoiceQuestion
                      question={question}
                      questionNo={index + 1}
                      questionAnswer={
                        question?.answer
                          ? question?.answer
                          : question?.answer_list
                      }
                    />
                  );
                } else if (question?.type === "yes_no") {
                  return (
                    <YesNoQuestions
                      question={question}
                      questionNo={index + 1}
                      questionAnswer={
                        question?.answer
                          ? question?.answer
                          : question?.answer_list
                      }
                    />
                  );
                } else if (question?.type === "number") {
                  return (
                    <NumberQuestion
                      question={question}
                      questionNo={index + 1}
                      questionAnswer={
                        question?.answer
                          ? question?.answer
                          : question?.answer_list
                      }
                    />
                  );
                } else if (question?.type === "phone_number") {
                  return (
                    <PhoneNoQuestion
                      question={question}
                      questionNo={index + 1}
                      questionAnswer={
                        question?.answer
                          ? question?.answer
                          : question?.answer_list
                      }
                    />
                  );
                } else if (question?.type === "email") {
                  return (
                    <EmailQuestion
                      question={question}
                      questionNo={index + 1}
                      questionAnswer={
                        question?.answer
                          ? question?.answer
                          : question?.answer_list
                      }
                    />
                  );
                } else if (question?.type === "name") {
                  return (
                    <NameQuestion
                      question={question}
                      questionNo={index + 1}
                      questionAnswer={
                        question?.answer
                          ? question?.answer
                          : question?.answer_list
                      }
                    />
                  );
                } else if (question?.type === "dropdown") {
                  return (
                    <DropdownQuestion
                      question={question}
                      questionNo={index + 1}
                      questionAnswer={
                        question?.answer
                          ? question?.answer
                          : question?.answer_list
                      }
                    />
                  );
                } else if (question?.type === "social_media") {
                  return (
                    <SocialMediaQuestion
                      question={question}
                      questionNo={index + 1}
                      questionAnswer={
                        question?.answer
                          ? question?.answer
                          : question?.answer_list
                      }
                    />
                  );
                } else if (question?.type === "scale") {
                  return (
                    <LinearScaleQuestion
                      question={question}
                      questionNo={index + 1}
                      questionAnswer={question?.answer_list}
                      isCDP={props?.isCDP}
                    />
                  );
                } else if (question?.type === "nps") {
                  return (
                    <NpsQuestion
                      question={question}
                      questionNum={index + 1}
                      questionIndex={index}
                      questionAnswer={question?.answer}
                      isCDP={props?.isCDP}
                      isAlert={props?.isAlert}
                    />
                  );
                }
              })}
            </Box>
          </>
        );
      })}
    </>
  );
};

export default SurveyQuestion;
