import moment from "moment";
import { isEmptyArray } from "utils/helpers";
import { handleXlsData, handleAuthorActivity } from "../excelCommonFn";

const account_tw_AA = (response, lang, isHourlyFormatObj) => {
  if (isEmptyArray(response)) {
    return [];
  } else {
    var followersGrowth = [],
      authorActivity = [],
      reachFunnel = [],
      reachFunnelLegacy = [],
      postsVolume = [],
      postsInteractions = [],
      postsContentType = [],
      topPosts = [],
      topImages = [],
      topVideos = [],
      allSheetData = [];
    //_______________________________________________________________________________
    response?.map((i) => {
      Object.entries(i).forEach(([key, value]) => {
        switch (key) {
          case "followersGrowth":
            value?.map((i) => {
              followersGrowth?.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.number]: i?.value,
              });
            });
            allSheetData?.push([followersGrowth, [lang.followers_growth]]);
            break;
          // _______________________________________________________________________________

          case "reachFunnel":
            value?.map((i) => {
              reachFunnel?.push({
                [lang.reach_funnel]: lang[i?.name],
                [lang.Retweets]: i?.value[0],
                [lang.original]: i?.value[1],
                [lang.total]: parseInt(i?.value[0]) + parseInt(i?.value[1]),
              });
            });
            allSheetData?.push([reachFunnel, [lang.reach_funnel]]);
            break;
          // // _______________________________________________________________________________

          case "postsInteractions":
            value?.map((i) => {
              postsInteractions?.push({
                [lang.date]: isHourlyFormatObj?.isPostsInteractionsHourlyFormat
                  ? moment.unix(i?.name).utc().format("hh") +
                    lang?.[
                      moment.unix(i?.name)?.utc()?.format("A")
                    ]?.toLowerCase()
                  : moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.Retweets]: i?.value[0],
                [lang.likes]: i?.value[1],
                [lang.replies]: i?.value[2],
                [lang.total]: i?.value[0] + i?.value[1] + i?.value[2],
              });
            });
            allSheetData?.push([postsInteractions, lang.posts_interactions]);
            break;
          // // _______________________________________________________________________________

          case "authorActivity":
            handleAuthorActivity(value[0], value[1], value[2], lang)?.map(
              (i) => {
                authorActivity?.push({
                  [lang.day]: i[0],
                  [lang.hour_group]: i[1],
                  [lang.no_of_activity]: i[2],
                });
              },
            );
            allSheetData?.push([authorActivity, lang.account_authors_activity]);
            break;
          // //_______________________________________________________________________________

          case "reachFunnelLegacy":
            value?.map((i) => {
              reachFunnelLegacy?.push({
                [lang.reach_funnel_legacy]: lang[i?.name],
                [lang.Retweets]: i?.value[0],
                [lang.original]: i?.value[0],
                [lang.total]: parseInt(i?.value[0]) + parseInt(i?.value[1]),
              });
            });
            allSheetData?.push([reachFunnelLegacy, lang.reach_funnel_legacy]);
            break;
          // //_______________________________________________________________________________

          case "postsContentType":
            value?.map((i) => {
              postsContentType?.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.video]: i?.value[0],
                [lang.image]: i?.value[1],
                [lang.url]: i?.value[2],
                [lang.text]: i?.value[3],
                [lang.total]:
                  i?.value[0] + i?.value[1] + i?.value[2] + i?.value[3],
              });
            });
            allSheetData?.push([postsContentType, lang.posts_content_type]);
            break;
          // // _______________________________________________________________________________

          case "topImages":
            value?.map((i) => {
              topImages?.push({ [lang.image]: i?.id });
            });
            allSheetData?.push([topImages, lang.top_images]);

            break;
          // _______________________________________________________________________________

          case "topVideos":
            value?.map((i) => {
              topVideos?.push({ [lang.video]: i?.id });
            });

            allSheetData?.push([topVideos, lang.top_videos]);

            break;
          // // _______________________________________________________________________________

          case "postsVolume":
            value?.map((i) => {
              postsVolume.push({
                [lang.date]: isHourlyFormatObj?.isPostsVolumeHourlyFormat
                  ? moment.unix(i?.name).utc().format("hh") +
                    lang?.[
                      moment.unix(i?.name)?.utc()?.format("A")
                    ]?.toLowerCase()
                  : moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.posts_word]: i?.value,
              });
            });
            allSheetData?.push([postsVolume, lang.posts_volume]);
            break;

          // // _______________________________________________________________________________

          case "topPosts":
            value?.map((i) => {
              topPosts?.push({ [lang.posts_word]: i?.tweet_link });
            });
            allSheetData?.push([topPosts, lang.top_posts]);

            break;
          //_______________________________________________________________________________
          default:
            break;
        }
      });
    });
    //_______________________________________________________________________________

    return handleXlsData(allSheetData);
  }
};
export { account_tw_AA };
