import { useEffect, memo, useContext, useState } from "react";
import { Typography, Box, Chip } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { useIntl } from "react-intl";
import { ReportContext } from "../../context/reportContext";
import CustomizedTooltip, {
  customizedStyledTooltip,
} from "components/customizedTooltip";
import { CheckValueLocale, numberFormatter } from "utils/helpers";

const getMonitorsData = (monitorsList) => {
  try {
    const monitors = [];
    Object?.entries(monitorsList)?.forEach(([key, value]) => {
      monitors?.push({
        name: value?.[0]?.name,
        count_articles: value?.[0]?.posts_count,
        id: key,
        articles: value?.[0]?.posts,
      });
    });

    return monitors;
  } catch {}
};

const TabSwiperMonitors = ({ monitorsList }) => {
  const intl = useIntl();
  const { activeMonitor, setActiveMonitor, loadindExport } =
    useContext(ReportContext);
  const [monitorsData, setMonitorsData] = useState([]);

  useEffect(() => {
    const monitors = getMonitorsData(monitorsList);
    setMonitorsData(monitors);
    setActiveMonitor(monitors?.[0]);
  }, [monitorsList]);

  const handleSelectMonitor = (monitor) => {
    //disabled change monitor when export pdf
    if (!loadindExport) setActiveMonitor(monitor);
  };

  return (
    <Box className="tab-swiper-monitors-report">
      <Box className="title-tab-swiper">
        {CheckValueLocale("keyword_monitors", "", {}, intl)}
      </Box>
      <Swiper
        navigation
        observer={true}
        slidesPerView="auto"
        key={intl.locale}
        className="tab-swiper-monitors"
      >
        {monitorsData?.map((monitor) => (
          <SwiperSlide
            key={monitor?.id}
            className={activeMonitor?.id == monitor?.id ? "active-monitor" : ""}
            onClick={() => handleSelectMonitor(monitor)}
          >
            <Typography>{monitor?.name}</Typography>
            <CustomizedTooltip
              title={`${monitor?.count_articles} ${CheckValueLocale("articles", "", {}, intl)}`}
              arrow
              placement="top"
              styles={customizedStyledTooltip}
            >
              <Chip
                label={numberFormatter(monitor?.count_articles)}
                className="count-articles-monitor"
                size="small"
              />
            </CustomizedTooltip>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default memo(TabSwiperMonitors);
