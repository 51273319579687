import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import TopicsHeader from "components/widgets/topicsHeader/topicsHeader";
import StackedLineWidget from "components/widgets/stackedLineWidget.js";
import StackedHorizontalBarWidget from "components/widgets/stackedHorizontalBarWidget";
import StackedBarWidget from "components/widgets/stackedBarWidget.js";
import PieChartWidget from "components/widgets/pieChartWidget";
import TopicsNoDataFound from "components/no-Data/topicsNoDataFound";
import UpdateTopics from "./updateTopics";
import NoDataFound from "components/no-Data/noDataFound";

const TopicsSection = (props) => {
  const { response } = props;

  const [collectedPosts, setCollectedPosts] = useState(0);

  let { monitorDataSource, monitorType, activeTab, monitorData } = props;
  let dynamicId = `${monitorDataSource?.toLowerCase()}-${monitorType?.toLowerCase()}-${activeTab}-${monitorData?.id}`;

  const [mentionsTrend, setMentionsTrend] = useState([]);
  const [mentionsTrendPieChart, setMentionsTrendPieChart] = useState([]);
  const [mentionsTrendPreloader, setMentionsTrendPreloader] = useState([]);

  const [audienceDistributionByGender, setAudienceDistributionByGender] =
    useState([]);
  const [
    audienceDistributionByGenderLegends,
    setAudienceDistributionByGenderLegends,
  ] = useState([]);
  const [
    audienceDistributionByGenderPreloader,
    setAudienceDistributionByGenderPreloader,
  ] = useState([]);

  const [shareOfVoice, setShareOfVoice] = useState([]);
  const [shareOfVoicePreloader, setShareOfVoicePreloader] = useState([]);

  const [sentimentAnalysis, setSentimentAnalysis] = useState([]);
  const [sentimentPieChart, setSentimentPieChart] = useState([]);
  const [sentimentAnalysisPreloader, setSentimentAnalysisPreloader] = useState(
    [],
  );

  const [postsByDomain, setPostsByDomain] = useState([]);
  const [postsByDomainPreloader, setPostsByDomainPreloader] = useState([]);

  const [topHashtags, setTopHashtags] = useState([]);
  const [topHashtagsPreloader, setTopHashtagsPreloader] = useState([]);

  const [topCountries, setTopCountries] = useState([]);
  const [topCountriesPreloader, setTopCountriesPreloader] = useState([]);

  const COLORS_ARR = [
    "#1892F2",
    "#FB5C77",
    "#F7B94D",
    "#FEDC5F",
    "#AF8AEC",
    "#00D7B5",
    "#1C404E",
    "#EC8A92",
  ];

  const ALL_SENTIMENT_VALUES = {
    positive: "#57C242",
    negative: "#FD0F47",
    neutral: "#F9C92E",
  };

  const checkValues = (data, func) => {
    let isEmpty = true;
    data.series_data.map((sd) => {
      sd.data.map((value) => {
        if (value !== 0) {
          isEmpty = false;
        }
      });
    });
    if (isEmpty) {
      func({ y_axis_data: [], series_data: [] });
    } else {
      func(data);
    }
  };

  //handle response with useEffect (when load page & any update)
  useEffect(() => {
    switch (response.eventName) {
      case "Twitter__EngagementsPage__manual_topics_collected_posts":
        setCollectedPosts(response.eventData.collected_posts);
        break;
      case "Twitter__EngagementsPage__manual_topics_mentions_trend":
        setMentionsTrend(response.eventData.mentions_overtime);
        setMentionsTrendPieChart(response.eventData.topics_legends);
        setMentionsTrendPreloader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Twitter__EngagementsPage__manual_topics_share_of_voice":
        setShareOfVoice(response.eventData.topics);
        setShareOfVoicePreloader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Twitter__EngagementsPage__manual_topics_sentiment_analysis":
        checkValues(response.eventData, setSentimentAnalysis);
        setSentimentPieChart(response.eventData.sentiment_legends);
        setSentimentAnalysisPreloader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Twitter__EngagementsPage__manual_topics_posts_distribution_by_domains":
        checkValues(response.eventData, setPostsByDomain);
        setPostsByDomainPreloader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Twitter__EngagementsPage__manual_topics_top_hashtags":
        setTopHashtags(response.eventData);
        setTopHashtagsPreloader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Twitter__EngagementsPage__manual_topics_top_countries":
        setTopCountries(response.eventData);
        setTopCountriesPreloader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;

      case "Twitter__EngagementsPage__manual_topics_gender_distribution":
        const gender_dist_data = [];
        response.eventData.gender_distribution.map((gd) => {
          const valArr = gd?.value?.map((val) => val?.value);
          gender_dist_data.push({ name: gd.name, value: valArr });
        });
        setAudienceDistributionByGender(gender_dist_data);

        const gender_dist_legends = [];
        if (response.eventData.top_genders.length > 0) {
          if (response.eventData.top_genders[0].name === "male") {
            gender_dist_legends.push(response.eventData.top_genders[0]);
            gender_dist_legends.push(response.eventData.top_genders[1]);
          } else {
            gender_dist_legends.push(response.eventData.top_genders[1]);
            gender_dist_legends.push(response.eventData.top_genders[0]);
          }
        }
        setAudienceDistributionByGenderLegends(gender_dist_legends);
        setAudienceDistributionByGenderPreloader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Facebook__EngagementsPage__manual_topics_collected_posts":
        setCollectedPosts(response.eventData.collected_posts);
        break;
      case "Facebook__EngagementsPage__manual_topics_mentions_trend":
        setMentionsTrend(response.eventData.mentions_overtime);
        setMentionsTrendPieChart(response.eventData.topics_legends);
        setMentionsTrendPreloader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Facebook__EngagementsPage__manual_topics_share_of_voice":
        setShareOfVoice(response.eventData.topics);
        setShareOfVoicePreloader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Facebook__EngagementsPage__manual_topics_sentiment_analysis":
        checkValues(response.eventData, setSentimentAnalysis);
        setSentimentPieChart(response.eventData.sentiment_legends);
        setSentimentAnalysisPreloader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Facebook__EngagementsPage__manual_topics_posts_distribution_by_domains":
        checkValues(response.eventData, setPostsByDomain);
        setPostsByDomainPreloader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Facebook__EngagementsPage__manual_topics_top_hashtags":
        setTopHashtags(response.eventData);
        setTopHashtagsPreloader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Instagram__EngagementsPage__manual_topics_collected_posts":
        setCollectedPosts(response.eventData.collected_posts);
        break;
      case "Instagram__EngagementsPage__manual_topics_mentions_trend":
        setMentionsTrend(response.eventData.mentions_overtime);
        setMentionsTrendPieChart(response.eventData.topics_legends);
        setMentionsTrendPreloader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Instagram__EngagementsPage__manual_topics_share_of_voice":
        setShareOfVoice(response.eventData.topics);
        setShareOfVoicePreloader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Instagram__EngagementsPage__manual_topics_sentiment_analysis":
        checkValues(response.eventData, setSentimentAnalysis);
        setSentimentPieChart(response.eventData.sentiment_legends);
        setSentimentAnalysisPreloader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Instagram__EngagementsPage__manual_topics_posts_distribution_by_domains":
        checkValues(response.eventData, setPostsByDomain);
        setPostsByDomainPreloader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Instagram__EngagementsPage__manual_topics_top_hashtags":
        setTopHashtags(response.eventData);
        setTopHashtagsPreloader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Talkwalker__EngagementsPage__manual_topics_collected_posts":
        setCollectedPosts(response.eventData.collected_posts);
        break;
      case "Talkwalker__EngagementsPage__manual_topics_mentions_trend":
        setMentionsTrend(response.eventData.mentions_overtime);
        setMentionsTrendPieChart(response.eventData.topics_legends);
        setMentionsTrendPreloader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Talkwalker__EngagementsPage__manual_topics_share_of_voice":
        setShareOfVoice(response.eventData.topics);
        setShareOfVoicePreloader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Talkwalker__EngagementsPage__manual_topics_sentiment_analysis":
        checkValues(response.eventData, setSentimentAnalysis);
        setSentimentPieChart(response.eventData.sentiment_legends);
        setSentimentAnalysisPreloader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Talkwalker__EngagementsPage__manual_topics_top_countries":
        setTopCountries(response.eventData);
        setTopCountriesPreloader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      default:
        break;
    }
  }, [response]);

  useEffect(() => {
    // Avoid Initial State Calling
    if (props.resetAllFields !== 1) {
      setCollectedPosts(0);

      setMentionsTrend([]);
      setMentionsTrendPieChart([]);
      setMentionsTrendPreloader([]);

      setSentimentAnalysis([]);
      setSentimentPieChart([]);
      setSentimentAnalysisPreloader([]);

      setTopHashtags([]);
      setTopHashtagsPreloader([]);

      setTopCountries([]);
      setTopCountriesPreloader([]);

      setPostsByDomain([]);
      setPostsByDomainPreloader([]);

      setShareOfVoice([]);
      setShareOfVoicePreloader([]);

      setAudienceDistributionByGender([]);
      setAudienceDistributionByGenderPreloader([]);
      setAudienceDistributionByGenderLegends([]);
    }
  }, [props.resetAllFields]);

  return (
    <>
      {props.isTopicsEnabled ? (
        <>
          <TopicsHeader
            title={"topics"}
            subtitle={"topics_collected"}
            topicsCount={collectedPosts}
            btnText={"topics_settings"}
            btnIcon={<SettingsIcon />}
            dynamicId={dynamicId ?? "dynamicId"}
          />
          {props?.topicsCount === 0 ? (
            <TopicsNoDataFound />
          ) : (
            <>
              <Box mb={3}>
                <StackedLineWidget
                  title={"mentions_trends"}
                  titleToolTip={"manual_topics_mentions_trends"}
                  data={mentionsTrend}
                  showGroupBy={true}
                  areaStyle={true}
                  isTopicSection
                  hideLegend
                  showDownloadIcon
                  bigImg
                  stackedLineWidgetPreLoaderDataSources={mentionsTrendPreloader}
                  preLoaderTrackerDataSources={
                    props.preLoaderTrackerDataSources
                  }
                  isSwitchCase
                  hidePercentage
                  isSentimentAnalysis={false}
                  showGroupByClientAgent={true}
                  showScales
                  dataPiechart={mentionsTrendPieChart}
                  iconAfterTitle={props.iconToDisplay}
                  labelColors={COLORS_ARR}
                  colors={COLORS_ARR}
                  isCityAnalysisWidget={true}
                />
              </Box>

              {props.audienceDistribution && (
                <Box my={3}>
                  <StackedBarWidget
                    title={"audience_distribution_by_gender"}
                    titleToolTip={"audience_distribution_by_gender_tooltip"}
                    showDownloadIcon
                    showAI
                    bigImg
                    posts={audienceDistributionByGender}
                    labels={["male", "female"]}
                    hideLegend
                    dataPiechart={audienceDistributionByGenderLegends}
                    colors={["#2AAAE2", "#FF4081"]}
                    showZoomPercentage
                    iconAfterTitle={props.iconToDisplay}
                    postsColumnPreLoaderDataSources={
                      audienceDistributionByGenderPreloader
                    }
                    preLoaderTrackerDataSources={
                      props.preLoaderTrackerDataSources
                    }
                    showGroupByClientAgent={true}
                    xAxisValue
                  />
                </Box>
              )}

              {/* *************** Manual Topics Sentiment / Domains *********************** */}
              {!props.isNB && (
                <Box mt={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <StackedHorizontalBarWidget
                        itemsList
                        title={"sentiment_analysis"}
                        titleToolTip={"manual_topics_sentiment_analysis"}
                        data={sentimentAnalysis}
                        showDownloadIcon
                        bigImg
                        showAI
                        stackedHorizontalChartPreLoaderDataSources={
                          sentimentAnalysisPreloader
                        }
                        preLoaderTrackerDataSources={
                          props.preLoaderTrackerDataSources
                        }
                        iconAfterTitle={props.iconToDisplay}
                        isSentimentAnalysis
                        hideLegend
                        increaseHeight
                        dataPiechart={sentimentPieChart}
                        labelColors={["#E50035", "#89BB2A", "#F9A700"]}
                        all_topics_colors={COLORS_ARR}
                        all_sentiment_values={ALL_SENTIMENT_VALUES}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <StackedHorizontalBarWidget
                        itemsList
                        title={"posts_distributed_by_domains"}
                        titleToolTip={"manual_topics_domains"}
                        data={postsByDomain}
                        stackedHorizontalChartPreLoaderDataSources={
                          postsByDomainPreloader
                        }
                        preLoaderTrackerDataSources={
                          props.preLoaderTrackerDataSources
                        }
                        iconAfterTitle={props.iconToDisplay}
                        showAI
                        bigImg
                        showDownloadIcon
                        increaseHeight
                        hideLegend={false}
                        all_topics_colors={COLORS_ARR}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}

              {/* *************** Manual Topics Country / Share of voice *********************** */}
              {!(props.isFacebook || props.isInstagram || props.isNB) && ( //chart will not be displayed in case of IG/FB/NB
                <Box mt={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <StackedHorizontalBarWidget
                        itemsList
                        title={"posts_distributed_by_country"}
                        titleToolTip={"manual_topics_country"}
                        data={topCountries}
                        stackedHorizontalChartPreLoaderDataSources={
                          topCountriesPreloader
                        }
                        preLoaderTrackerDataSources={
                          props.preLoaderTrackerDataSources
                        }
                        iconAfterTitle={props.iconToDisplay}
                        showAI
                        bigImg
                        showDownloadIcon
                        hideLegend
                        all_topics_colors={COLORS_ARR}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <PieChartWidget
                        title={"share_of_voice"}
                        titleToolTip={"share_of_voice_tooltip"}
                        data={shareOfVoice}
                        areaStyle={true}
                        toolTipLabel={"toolTipLabel"}
                        showDownloadIcon
                        pieChartPreLoaderDataSources={shareOfVoicePreloader}
                        preLoaderTrackerDataSources={
                          props.preLoaderTrackerDataSources
                        }
                        iconAfterTitle={props.iconToDisplay}
                        customStyle="chart chart-height-dynamic"
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}

              {props.isNB && ( //chart will be displayed in case of NB
                <Box mt={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <StackedHorizontalBarWidget
                        itemsList
                        title={"sentiment_analysis"}
                        titleToolTip={"manual_topics_sentiment_analysis"}
                        data={sentimentAnalysis}
                        showDownloadIcon
                        bigImg
                        showAI
                        stackedHorizontalChartPreLoaderDataSources={
                          sentimentAnalysisPreloader
                        }
                        preLoaderTrackerDataSources={
                          props.preLoaderTrackerDataSources
                        }
                        iconAfterTitle={props.iconToDisplay}
                        hideLegend
                        dataPiechart={sentimentPieChart}
                        labelColors={["#E50035", "#89BB2A", "#F9A700"]}
                        increaseHeight
                        isSentimentAnalysis
                        all_topics_colors={COLORS_ARR}
                        all_sentiment_values={ALL_SENTIMENT_VALUES}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <StackedHorizontalBarWidget
                        itemsList
                        title={"posts_distributed_by_country"}
                        titleToolTip={"manual_topics_country"}
                        data={topCountries}
                        stackedHorizontalChartPreLoaderDataSources={
                          topCountriesPreloader
                        }
                        preLoaderTrackerDataSources={
                          props.preLoaderTrackerDataSources
                        }
                        iconAfterTitle={props.iconToDisplay}
                        showAI
                        bigImg
                        showDownloadIcon
                        increaseHeight
                        hideLegend
                        all_topics_colors={COLORS_ARR}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}

              {/* *************** Manual Topics Top Trending hashtags *********************** */}
              {!props.isNB && (
                <Box
                  mt={3}
                  mb={!(props.isFacebook || props.isInstagram) ? 10 : 0}
                >
                  <StackedHorizontalBarWidget
                    itemsList
                    title={"top_trending_hashtags"}
                    titleToolTip={"manual_topics_hashtags"}
                    data={topHashtags}
                    stackedHorizontalChartPreLoaderDataSources={
                      topHashtagsPreloader
                    }
                    preLoaderTrackerDataSources={
                      props.preLoaderTrackerDataSources
                    }
                    iconAfterTitle={props.iconToDisplay}
                    showDownloadIcon
                    bigImg
                    all_topics_colors={COLORS_ARR}
                    hideLegend
                  />
                </Box>
              )}

              {/* Show Share of voice in case of FB / IG */}
              {(props.isFacebook || props.isInstagram || props.isNB) && (
                <Box mt={3} mb={10}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <PieChartWidget
                        title={"share_of_voice"}
                        titleToolTip={"share_of_voice_tooltip"}
                        data={shareOfVoice}
                        areaStyle={true}
                        toolTipLabel={"toolTipLabel"}
                        showDownloadIcon
                        pieChartPreLoaderDataSources={shareOfVoicePreloader}
                        preLoaderTrackerDataSources={
                          props.preLoaderTrackerDataSources
                        }
                        iconAfterTitle={props.iconToDisplay}
                        customStyle="chart chart-height-dynamic"
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
            </>
          )}
        </>
      ) : (
        <UpdateTopics showHeader />
      )}
    </>
  );
};

export default TopicsSection;
