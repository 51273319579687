import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { useCompanySubscriptions } from "./hooks/useCompanySubscriptions";
import { useGenerateUsageData } from "./hooks/useGenerateUsageData";
import PageHeader from "components/pageHeader";
import CircularLoading from "components/circularProgress";
import SnackBar from "components/snackBar";
import CxmCore from "./components/cxmCore";
import ProductsAddons from "./components/productsAddons/index";
import SubscriptionsDetails from "./components/subscriptionsDetails";
import "./style.scss";
import { useLocation } from "react-router-dom";

const Subscriptions = () => {
  const intl = useIntl();
  const location = useLocation();
  const [snackbar, setSnackbar] = useState({});
  const [expanded, setExpanded] = useState(""); // Usage Details Accordion State

  const {
    data: subscriptionsData,
    isPending: subscriptionsLoading,
    isError: subscriptionsError,
  } = useCompanySubscriptions();

  const activeProducts =
    JSON?.parse(window?.localStorage?.activeProducts || [])
      ?.filter((item) => item?.active)
      ?.map((item) => item?.name?.toLowerCase()) || [];

  const usageData = useGenerateUsageData(
    activeProducts,
    subscriptionsData?.data?.attributes,
    intl,
  );

  // Set expanded only on first data load and do not reset it on subsequent renders
  useEffect(() => {
    if (expanded === "" && usageData?.length)
      setExpanded(usageData?.[0]?.productKey || null);
  }, [usageData]);

  const handleExpand = (value) =>
    setExpanded(value === expanded ? null : value); // Usage Details Accordion State Handler

  useEffect(() => {
    if (subscriptionsError) {
      setSnackbar({
        open: true,
        severity: "error",
        title: "settings_some_thing_went_wrong",
        message: "settings_please_try_again_later",
      });
    }
  }, [subscriptionsError]);

  // Scroll to usage details section for specific product
  const scrollToElement = (sectionId) => {
    setExpanded(sectionId);
    const element = document.getElementById(
      `subscriptions-details-accordion-${sectionId}`,
    );
    element?.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  useEffect(() => {
    if (!!usageData && !!subscriptionsData) {
      const errorType = location?.state?.needsAttentionLimitError;

      const isCombinedPackage =
        subscriptionsData?.data?.attributes?.sm_package?.attributes
          ?.combined_packages &&
        subscriptionsData?.data?.attributes?.ca_package?.attributes
          ?.combined_packages;

      if (errorType == "sm_limit" || errorType == "news_blogs_limit") {
        scrollToElement(
          isCombinedPackage ? "combined-package" : "social-listening",
        );
      } else if (errorType == "cxm_limit") {
        scrollToElement(isCombinedPackage ? "combined-package" : "omnichannel");
      }
    }
  }, [location, subscriptionsData, usageData]);

  return (
    <Box id="settings-subscriptions-page">
      {subscriptionsLoading ? (
        <CircularLoading />
      ) : (
        <Box id="settings-subscriptions-container">
          <PageHeader
            title="subscriptions_title"
            titleId="subscriptions"
            isCreditCard={subscriptionsData?.payment_type === "credit_card"}
            subscriptionLink={subscriptionsData?.customer_portal_link || ""}
            hasEndSide={true}
          />

          <CxmCore
            info={
              subscriptionsData?.data?.attributes?.cxm_core_package
                ?.attributes || {}
            }
          />

          <ProductsAddons
            data={subscriptionsData?.data?.attributes || {}}
            activeProducts={activeProducts}
            setSnackbar={setSnackbar}
            setExpanded={setExpanded}
          />

          <SubscriptionsDetails
            usageData={usageData || []}
            expanded={expanded}
            handleExpand={handleExpand}
            setSnackbar={setSnackbar}
          />
        </Box>
      )}

      <SnackBar
        open={snackbar?.open}
        handleClose={() => setSnackbar({})}
        severity={snackbar?.severity}
        title={CheckValueLocale(snackbar?.title, "", {}, intl)}
        message={CheckValueLocale(snackbar?.message, "", {}, intl)}
        alertStyle={{ maxWidth: "320px" }}
      />
    </Box>
  );
};

export default Subscriptions;
