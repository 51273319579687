import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";
import { useSurveysPublishedList } from "pages/engagements/components/aiAgents/aiAgentsHooks/useSurveysPublishedList";
import AiAgentsFields from "../aiAgentsFields";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { useEffect, useState } from "react";

const SurveyField = ({
  formik,
  surveyActive,
  customHandleChange,
  disabled,
}) => {
  const intl = useIntl();
  const [isSurveysLoading, setIsSurveysLoading] = useState(false);

  const {
    data: surveysList,
    isLoading: surveysListLoading,
    refetch: refetchSurveysList,
    isRefetching: isRefetchSurveysList,
  } = useSurveysPublishedList(surveyActive);

  const onOpenSurveysDropdown = () => {
    setIsSurveysLoading(true);
    refetchSurveysList();
  };

  useEffect(() => {
    if (surveysList && !surveysListLoading && !isRefetchSurveysList) {
      setIsSurveysLoading(false);
    }
  }, [surveysList, surveysListLoading, isRefetchSurveysList]);

  const surveyOptionsList =
    surveysList?.map((item) => {
      return item?.id;
    }) || [];

  const surveyOptionsListObj =
    surveysList?.map((item) => {
      return {
        name: item?.name,
        id: item?.id,
      };
    }) || [];

  const onClickAddNewSurvey = () => {
    window.open("/survey/list", "_blank");
  };

  return (
    <AiAgentsFields
      disabled={disabled}
      type="autoComplete"
      id="ai-agent-survey-select"
      name="survey_id"
      value={formik?.values?.survey_id}
      optionsList={surveyOptionsList}
      optionsListObj={surveyOptionsListObj}
      handleChange={(e, value) => customHandleChange("survey_id", value)}
      label={CheckValueLocale("surveys_engag", "", {}, intl)}
      placeholder={CheckValueLocale("survey_select", "", {}, intl)}
      emptyListMessage={CheckValueLocale("no_surveys_show", "", {}, intl)}
      customOptionLabel={CheckValueLocale("new_survey", "", {}, intl)}
      onCustomOptionClick={onClickAddNewSurvey}
      icon={faExternalLink}
      isLoading={isSurveysLoading}
      onOpen={onOpenSurveysDropdown}
      error={!!formik?.errors?.survey_id}
      onBlur={formik?.handleBlur}
      helperText={CheckValueLocale(formik?.errors?.survey_id, "", {}, intl)}
      disableClearable={true}
      required
    />
  );
};

export default SurveyField;
