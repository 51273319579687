import Services from "./Services";

var Dashboards = {};

Dashboards.getMonitorsForDashboard = (monitor_type, queryData) => {
  return Services.getData(
    `api/v3/dashboards/${monitor_type}/monitors`,
    queryData,
  );
};

Dashboards.getDashboardTypes = (queryData) => {
  return Services.getData(`api/v3/dashboards/types`, queryData);
};

Dashboards.createNewDashboard = (queryData) => {
  return Services.postData("api/v3/dashboards", queryData);
};
Dashboards.getDashboardInfo = (dashboard_id) => {
  return Services.getData(`api/v3/dashboards/${dashboard_id}`);
};
Dashboards.editDashboardInfo = (queryData, dashboard_id) => {
  return Services.putData(`api/v3/dashboards/${dashboard_id}`, queryData);
};

Dashboards.getDashboardList = (queryData) => {
  return Services.getData(`api/v4/dashboards`, queryData);
};

Dashboards.getDashboardData = (tab, id, queryData, cancelToken) => {
  return Services.getData(
    `api/v3/dashboards/${tab}/${id}`,
    queryData,
    undefined,
    cancelToken,
  );
};
Dashboards.deleteDashboard = (dashboard_id) => {
  return Services.deleteData(`api/v3/dashboards/${dashboard_id}`);
};

// Active Filters
Dashboards.getDashboardEnabledFilters = (queryData) => {
  return Services.getData(
    "api/v3/dashboards/filters/enabled_filters",
    queryData,
  );
};

// Saved Filters
Dashboards.getDashboardSavedFilters = (dashboard_id, queryData) => {
  return Services.getData(
    `api/v3/dashboards/${dashboard_id}/saved_filters`,
    queryData,
  );
};

Dashboards.createDashboardSavedFilter = (dashboard_id, queryData) => {
  return Services.postData(
    `api/v3/dashboards/${dashboard_id}/saved_filters`,
    queryData,
  );
};

Dashboards.getDashboardSavedFilterInfo = (
  dashboard_id,
  saved_filter_id,
  queryData,
) => {
  return Services.getData(
    `api/v3/dashboards/${dashboard_id}/saved_filters/${saved_filter_id}`,
    queryData,
  );
};
Dashboards.updateDashboardSavedFilterInfo = (
  dashboard_id,
  saved_filter_id,
  queryData,
) => {
  return Services.putData(
    `api/v3/dashboards/${dashboard_id}/saved_filters/${saved_filter_id}`,
    queryData,
  );
};
Dashboards.deleteDashboardSavedFilterInfo = (
  dashboard_id,
  saved_filter_id,
  queryData,
) => {
  return Services.deleteData(
    `api/v3/dashboards/${dashboard_id}/saved_filters/${saved_filter_id}`,
    queryData,
  );
};

export default Dashboards;
