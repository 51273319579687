import { useEngagementTabs } from "pages/engagements/providers/engagementsTabsProvider";
import { useEffect, useRef, useState } from "react";

const getElementWidth = (element) => {
  return element?.getBoundingClientRect?.()?.width ?? 0;
};

export const useEngagementTabsResizable = () => {
  const {
    engagementTabs,
    activeEngagement,
    handleEngagementTabs,
    hiddenTabsIds,
  } = useEngagementTabs();

  const [visibleEngagementTabs, setVisibleEngagementTabs] = useState([]);
  const [engagementsInMenu, setEngagementsInMenu] = useState([]);

  const containerRef = useRef(null);
  const engagementTabsRef = useRef(null);

  const updateHiddenTabsIds = (hiddenEngagements) => {
    if (hiddenTabsIds?.current) {
      hiddenTabsIds.current = hiddenEngagements?.map((eng) => eng?.engId) || [];
    }
  };

  const calculateVisibleTabs = () => {
    const tabsElement = engagementTabsRef.current;
    const containerElement = containerRef.current;
    if (!tabsElement || !containerElement) return;

    const containerWidth = getElementWidth(containerElement);
    const totalTabsWidth = tabsElement.scrollWidth;

    const shouldShowMenu = totalTabsWidth > containerWidth;

    if (!shouldShowMenu) {
      setVisibleEngagementTabs([...engagementTabs]);
      setEngagementsInMenu([]);
      updateHiddenTabsIds([]);
      return;
    }

    const padding = 15;
    const availableWidth = containerWidth - padding;
    const menuWidth = 55 + 8; // 8 is the divider width of the end of the menu
    const dividerWidth = 8;

    const tabsElements = Array.from(tabsElement?.children || [])?.filter?.(
      (
        child, // select only children that has the class name of the engagement-tabs-list-item
      ) => child?.classList?.contains?.("engagement-tabs-list-item"),
    );

    let accumulatedWidth = 0;
    let visibleCount = 0;

    for (let idx = 0; idx < tabsElements?.length; idx++) {
      const tab = tabsElements[idx];
      const tabWidth = getElementWidth(tab);
      accumulatedWidth += tabWidth + dividerWidth;

      if (accumulatedWidth + menuWidth <= availableWidth) {
        visibleCount++;
      } else {
        break;
      }
    }

    let visibleTabs = engagementTabs?.slice?.(0, visibleCount) || [];
    let hiddenTabs = engagementTabs?.slice?.(visibleCount) || [];

    // Ensure the active tab is visible
    const activeTabIndex = engagementTabs?.findIndex(
      (tab) => tab?.engId === activeEngagement?.engId,
    );

    // If the active tab is not visible, move it to the visible tabs
    if (
      activeTabIndex >= 0 &&
      visibleTabs?.length &&
      !visibleTabs?.some?.((tab) => tab?.engId === activeEngagement?.engId)
    ) {
      // Replace the last visible tab with the active tab
      const lastVisibleTab = visibleTabs?.pop();
      if (lastVisibleTab) {
        hiddenTabs?.unshift(lastVisibleTab);
      }
      const activeTab = engagementTabs?.[activeTabIndex];
      visibleTabs?.push(activeTab);
      hiddenTabs = hiddenTabs?.filter(
        (tab) => tab?.engId !== activeEngagement?.engId,
      );
    }
    setVisibleEngagementTabs(visibleTabs);
    setEngagementsInMenu(hiddenTabs);
    updateHiddenTabsIds(hiddenTabs);
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      setTimeout(() => {
        calculateVisibleTabs();
      }, 0); // Defer the layout calculation to avoid ResizeObserver loop errors
    });
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }
    return () => resizeObserver?.disconnect();
  }, [engagementTabs]);

  return {
    visibleEngagementTabs,
    engagementsInMenu,
    handleEngagementTabs,
    engagementTabsRef,
    engagementTabs,
    containerRef,
    activeEngagement,
  };
};
