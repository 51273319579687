import { useMutation, useQueryClient } from "@tanstack/react-query";
import Engagements from "services/api/engagements";

const useUpdateNote = () => {
  return useMutation({
    mutationFn: ({ params, noteId }) => Engagements.updateNote(params, noteId),
    select: (data) => {
      return data?.data;
    },
  });
};

export default useUpdateNote;
