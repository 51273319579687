import { Box, Divider } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import moment from "moment";
/** Format date with time zone */
const dateWithTimeZone = (date, intl) => {
  const companyTimeZone = window.localStorage.companyInfo || 0;

  const unixDate = moment(date).unix();
  const [datePart, timePart, meridiem] = moment
    .unix(unixDate)
    .format("YYYY/MM/DD hh:mm a")
    .split(" ");
  const periodKey =
    meridiem === "pm" ? "hashtag_period_pm" : "hashtag_period_am";
  const timeZoneLabel =
    companyTimeZone >= 0 ? `+${companyTimeZone}` : `${companyTimeZone}`;
  return `${datePart} - ${timePart} ${CheckValueLocale(periodKey, "", {}, intl)} (${CheckValueLocale(
    "gmt",
    "",
    {},
    intl,
  )} ${timeZoneLabel})`;
};

export const DMStatusLogs = ({
  statusLogs = [],
  message,
  chatData = [],
  index,
  selectedCard,
}) => {
  const intl = useIntl();

  if (!statusLogs?.length) return null;

  const latestStatusLog = statusLogs[statusLogs.length - 1] || {};
  const latestStatusLogDate = new Date(latestStatusLog?.created_at);
  const currentMessageCreatedAtDate = new Date(message?.created_at);
  const nextMessage = chatData[index + 1];
  const nextMessageDataCreatedAtDate = nextMessage
    ? new Date(nextMessage?.created_at)
    : null;

  // we should display status log if it's created after current message creation date
  // and before the next message creation date (if exists) or if it's the last message.
  const shouldDisplayStatusLog =
    latestStatusLogDate >= currentMessageCreatedAtDate &&
    (!nextMessageDataCreatedAtDate ||
      latestStatusLogDate <= nextMessageDataCreatedAtDate);

  if (!shouldDisplayStatusLog) return null;

  const { status, user_email, created_at } = latestStatusLog;
  const formattedDate = dateWithTimeZone(created_at, intl);

  const getCompletionReason = () => {
    const completionReason = selectedCard?.system_replies?.completion_reason;
    if (completionReason === "other") {
      const otherReason = selectedCard?.system_replies?.other_reason;
      return (
        <Box component="span" className="completion-reason-span">
          {CheckValueLocale(completionReason, "", {}, intl)} (
          {CheckValueLocale(otherReason, "", {}, intl)})
        </Box>
      );
    }
    return CheckValueLocale(completionReason, "", {}, intl);
  };

  const statusComponents = {
    complete: (
      <Box className="main-post-state">
        <Box>
          {CheckValueLocale(
            "marked_as_completed",
            "",
            { name: user_email, date: formattedDate },
            intl,
          )}{" "}
          {getCompletionReason()}
        </Box>
      </Box>
    ),
    active: (
      <Box className="main-post-state">
        <Box>
          {CheckValueLocale(
            "marked_as_active",
            "",
            { name: user_email, date: formattedDate },
            intl,
          )}
        </Box>
      </Box>
    ),
    reopen: (
      <Box className="main-post-state">
        <Box>
          {CheckValueLocale(
            "marked_as_reopen",
            "",
            { name: user_email, date: formattedDate },
            intl,
          )}
        </Box>
        <Divider />
      </Box>
    ),
  };

  return statusComponents[status] || null;
};
