import { Box } from "@mui/material";
import CustomizedTooltip, {
  customizedStyledTooltip,
} from "components/customizedTooltip";
import InputPassword from "pages/settings/pages/userManagementPage/subPages/shardComponents/userForm/components/inputLabelFormik/inputPassword";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const NewPasswordTooltip = ({ newPassword }) => {
  const intl = useIntl();
  const [errors, setErrors] = useState([]);

  const validatePassword = (passwordText = "") => {
    const regex_uppercase = /[A-Z]/;
    const regex_lowercase = /[a-z]/;
    const regex_number = /[0-9]/;

    let errors = [];

    if (passwordText?.length < 8) {
      errors?.push("chars_num_err");
    }
    if (!passwordText?.match(regex_uppercase)) {
      errors?.push("uppercase_err");
    }
    if (!passwordText?.match(regex_lowercase)) {
      errors?.push("lowercase_err");
    }
    if (!passwordText?.match(regex_number)) {
      errors?.push("number_err");
    }
    setErrors(errors);
  };

  useEffect(() => {
    validatePassword(newPassword);
  }, [newPassword]);

  return (
    <Box className="tooltip-new-password-container">
      <Box>{CheckValueLocale("password_must_contain", "", {}, intl)}</Box>
      <Box component={"ul"}>
        {["chars_num_err", "uppercase_err", "lowercase_err", "number_err"]?.map(
          (error, index) => (
            <Box
              component={"li"}
              key={index}
              className={!errors?.includes(error) ? "checked" : ""}
            >
              {CheckValueLocale(error, "", {}, intl)}
            </Box>
          ),
        )}
      </Box>
    </Box>
  );
};

const NewPasswordForm = ({ formik }) => {
  const intl = useIntl();

  const handelErrorMsg = (nameField) => {
    return formik?.touched?.[nameField] ? formik?.errors?.[nameField] : "";
  };

  return (
    <Box className="new-password-form">
      {["current_password", "new_password", "confirm_password"]?.map(
        (inputName, index) => (
          <Box className="password-input-security-section" key={index}>
            <CustomizedTooltip
              title={
                inputName === "new_password" ? (
                  <NewPasswordTooltip
                    newPassword={formik?.values?.[inputName]}
                  />
                ) : (
                  ""
                )
              }
              key={index}
              placement={intl?.locale === "en" ? "right" : "left"}
              arrow
              styles={customizedStyledTooltip}
            >
              <Box>
                <InputPassword
                  value={formik?.values?.[inputName]}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  labelId={inputName}
                  name={inputName}
                  placeHolderId={`${inputName}_placeholder`}
                  errorMsg={handelErrorMsg(inputName)}
                />
              </Box>
            </CustomizedTooltip>
          </Box>
        ),
      )}
    </Box>
  );
};

export default NewPasswordForm;
