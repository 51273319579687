import {
  Typography,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Box,
  InputLabel,
  FormGroup,
  FormControlLabel,
  InputAdornment,
  FormHelperText,
  MenuItem,
  Button,
  Chip,
  Tooltip,
  ClickAwayListener,
  Alert,
} from "@mui/material";
import { isEmpty } from "lodash";
import { useState, useEffect, Fragment } from "react";
import { useIntl } from "react-intl";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  CustomMenuItem,
  CustomSelect,
  CustomFormControl,
  CustomAlert,
} from "shared/channel-analytics-filter/filter.styles";
import {
  isEmojis,
  isEmptyArray,
  CheckValueLocale,
  handleTwitterURLPaste,
} from "utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { filters } from "utils/redux/features/SocialListening/socialListeningSlice";
import SharedFunctionsKeywords from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/sharedFunctionsKeywords";
import { checkDirectionLang } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/kewordsSharedFunctions";
import KeywordTooltipChip from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/keywordTypeComponent/keywordTooltipChip";
import LucButton from "shared/lucButton/lucButton";
import SearchableMultiSelectFilter from "../sharedFilters/searchableMultiSelectFilter/component/searchableMultiSelectFilter";
import { useCallback } from "react";
import createListButton from "pages/accountsLists/sharedComponents/createListButton";

const FilterDropDownMenu = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { isAudiencePage, setErrorMessageKeywords, errorMessageKeywords } =
    props;
  const [dataExist, setDataExist] = useState(true);
  const [isParentIdTooltipOpen, setIsParentIdTooltipOpen] = useState();

  const { handleValidationKeywords, handlekeywordsShared } =
    SharedFunctionsKeywords();
  const reduxFilterParams = useSelector(
    (state) => state?.socialListening?.newFilterParams,
  );
  const [focused, setFocused] = useState(false);
  const handleInputMouseDown = (event) => {
    event.preventDefault();
  };
  const [selectedAccountList, setSelectedAccountList] = useState([]);
  const [excludedSelectedAccountList, setExcludedSelectedAccountList] =
    useState([]);
  let allowUsersInput =
    reduxFilterParams?.account_lists?.length ||
    reduxFilterParams?.exclude_account_lists?.length;

  /* ______________________________________________________________________________ */
  //handleChange function for all filters except input type:keyword,user,excludeuser,excludekeyword

  const handleChange = (event) => {
    const {
      target: { value },
      target: { name },
      target: { checked },
    } = event;
    if (name == "manual_topics") {
      var obj = {
        ...reduxFilterParams,
        [name]: value,
      };
    } else if (
      (name === "min_followers" && value < 0) ||
      (name === "parent_id" && value < 0)
    ) {
      return false;
    } else if (
      (name === "min_followers" && value >= 0) ||
      (name === "parent_id" && value >= 0)
    ) {
      if (name === "parent_id" && value?.length > 19) {
        return;
      }
      var obj = {
        ...reduxFilterParams,
        [name]: value,
      };
    } else {
      obj = {
        ...reduxFilterParams,
        //case of check box
        [name]:
          checked === true
            ? "True"
            : checked === false
              ? "False"
              : typeof value === "string" //case of multiple inputs
                ? value.split(",")
                : value, //case of single input
      };
    }
    props?.setFilterParams(obj);
    dispatch(filters(obj));
  };

  /* ______________________________________________________________________________ */
  //handle change in case of input fields
  const [inputValue, setInputValue] = useState([]);
  const handleChangeInput = (event, inputName) => {
    var value = event.target.value;

    if (inputName === "exclude_user" || inputName === "users") {
      // users / exclude users: should accept only underscore + characters + numbers
      if (
        value.search(/[§±!^\#\@\$%&\*\(\)\-\+=\[\]{};:\\\|~<>\/\?\.,؛؟، ]/g) ===
          -1 &&
        !isEmojis(value)
      ) {
        setInputValue({ name: inputName, value: value.trim() });
      }
    } else if (inputName === "NBSourceName") {
      const sourceNameList = reduxFilterParams?.NBSourceName
        ? reduxFilterParams?.NBSourceName?.split(",")
        : [];
      const isDuplicate = sourceNameList?.includes(value);
      const sourceNameArray = reduxFilterParams?.NBSourceName?.split(",");

      if (value?.length > 50) {
        setErrorMessageKeywords("characters_limi_msg");
      } else {
        if (isDuplicate) {
          setErrorMessageKeywords("source_name_cant_be_duplicate");
        } else if (sourceNameArray?.length > 100) {
          setErrorMessageKeywords("maximum_source_name_limit");
        } else {
          setErrorMessageKeywords("");
        }
      }
      setInputValue({ name: inputName, value: value });
    } else {
      setInputValue({ name: inputName, value: value });
    }
    if (inputName === "keywords" || inputName === "exclude_keywords") {
      setInputValue({
        name: inputName,
        value: value,
      });

      //using shared
      let errorMessage = handleValidationKeywords(
        value,
        reduxFilterParams["keywords"]
          ? reduxFilterParams["keywords"]?.split(",")
          : [],
        reduxFilterParams["exclude_keywords"]
          ? reduxFilterParams["exclude_keywords"]?.split(",")
          : [],
        inputName === "keywords" ? "keywords" : "spam_keywords",
        setDataExist,
        "twitter",
      );
      setErrorMessageKeywords(errorMessage);
    }
  };
  const handleKeyFunc = (inputName, inputName1, inputName2) => {
    if (reduxFilterParams[inputName1] && reduxFilterParams[inputName2]) {
      let removeDuplicateUser = reduxFilterParams[inputName1]?.split(",");
      let removeDuplicateExUser = reduxFilterParams[inputName2]?.split(",");
      if (
        removeDuplicateUser.includes(inputValue.value.trim()) ||
        removeDuplicateExUser.includes(inputValue.value.trim())
      ) {
        setInputValue({ name: inputName, value: "" });
        return false;
      }
    } else if (reduxFilterParams[inputName1]) {
      let removeDuplicateUser = reduxFilterParams[inputName1]
        ?.toString()
        ?.split(",");
      if (removeDuplicateUser.includes(inputValue.value.trim())) {
        setInputValue({ name: inputName, value: "" });
        return false;
      }
    } else if (reduxFilterParams[inputName2]) {
      let removeDuplicateUser = reduxFilterParams[inputName2]
        ?.toString()
        ?.split(",");
      if (removeDuplicateUser.includes(inputValue.value.trim())) {
        setInputValue({ name: inputName, value: "" });
        return false;
      }
    }
  };

  /* ______________________________________________________________________________ */
  //This code section is used to searchableMultiSelectFilter component.
  //This code provides the functionality for managing and rendering multi-select dropdown filters in a React application.
  // It includes logic for enabling or disabling dropdown options based on specific conditions (disableSearchableMultiSelect), controlling
  // the maximum number of selections allowed (checkDisableOptions), resetting selected lists on certain state changes (useEffect),
  // and handling updates to the selected and excluded lists (handleListChange).
  // Additionally, it includes utility functions to transform fetched options into a usable format (responseOptionsTransformer) and
  // render custom dropdown options (renderOption and renderOptionDropdown) with support for various filter types.

  const disableSearchableMultiSelect = (filterName) => {
    if (
      filterName == "exclude_account_lists" &&
      !isEmpty(selectedAccountList)
    ) {
      return true;
    } else if (
      filterName == "account_lists" &&
      !isEmpty(excludedSelectedAccountList)
    ) {
      return true;
    } else if (
      (reduxFilterParams?.users != "" && reduxFilterParams?.users) ||
      (reduxFilterParams?.exclude_user != "" && reduxFilterParams?.exclude_user)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const checkDisableOptions = useCallback(
    (option) => {
      const findById = (item) => item?.value === option?.value;
      return (
        (selectedAccountList?.length === 5 ||
          excludedSelectedAccountList?.length === 5) &&
        !selectedAccountList?.find(findById) &&
        !excludedSelectedAccountList?.find(findById)
      );
    },
    [selectedAccountList, excludedSelectedAccountList],
  );

  useEffect(() => {
    if (
      props?.filterParams?.account_lists == undefined &&
      props?.filterParams?.exclude_account_lists == undefined
    ) {
      {
        setSelectedAccountList([]);
        setExcludedSelectedAccountList([]);
      }
    }
  }, [props?.filterParams]);

  const handleListChange = (value, filterName) => {
    if (filterName == "account_lists") {
      setSelectedAccountList(
        typeof value === "string" ? value?.split(",") : value,
      );
    } else {
      setExcludedSelectedAccountList(
        typeof value === "string" ? value?.split(",") : value,
      );
    }

    const ids = value?.map((item) => item?.value);
    let obj = {
      ...reduxFilterParams,
      [filterName]: ids,
    };
    props?.setFilterParams(obj);
    dispatch(filters(obj));
  };

  const valueToRender = (filterName) => {
    return filterName === "account_lists"
      ? selectedAccountList
      : excludedSelectedAccountList;
  };

  useEffect(() => {
    let options = props?.filterFields?.find(
      (item) => item?.name === "account_lists",
    )?.options;

    if (props?.savedFilterId === undefined) {
      return;
    }

    const matchedItem = props?.reduxSavedFilter?.find(
      (item) => item?.id?.toString() === props?.savedFilterId?.toString(),
    );

    if (matchedItem) {
      if (matchedItem?.attributes?.params_filter?.account_lists) {
        setExcludedSelectedAccountList([]);
        const matchedOptions = options?.filter((item) =>
          matchedItem?.attributes?.params_filter?.account_lists?.includes(
            item?.value?.toString(),
          ),
        );
        if (matchedOptions) {
          setSelectedAccountList(
            typeof matchedOptions === "string"
              ? matchedOptions?.split(",")
              : matchedOptions,
          );
        }
      }

      if (matchedItem?.attributes?.params_filter?.exclude_account_lists) {
        setSelectedAccountList([]);
        const matchedOptions = options?.filter((item) =>
          matchedItem?.attributes?.params_filter?.exclude_account_lists?.includes(
            item?.value?.toString(),
          ),
        );
        if (matchedOptions) {
          setExcludedSelectedAccountList(
            typeof matchedOptions === "string"
              ? matchedOptions?.split(",")
              : matchedOptions,
          );
        }
      }
    }
  }, [props?.savedFilterId, props?.filterFields]);

  const reserveRenderedValue = (options) => {
    if (reduxFilterParams?.account_lists && selectedAccountList.length === 0) {
      const matchedOptions = options?.filter((item) =>
        reduxFilterParams.account_lists.includes(item.value),
      );
      if (matchedOptions.length > 0) {
        setSelectedAccountList(
          typeof matchedOptions === "string"
            ? matchedOptions?.split(",")
            : matchedOptions,
        );
      }
    }

    if (
      reduxFilterParams?.exclude_account_lists &&
      excludedSelectedAccountList.length === 0
    ) {
      const matchedOptions = options?.filter((item) =>
        reduxFilterParams.exclude_account_lists.includes(item.value),
      );
      if (matchedOptions.length > 0) {
        setExcludedSelectedAccountList(
          typeof matchedOptions === "string"
            ? matchedOptions?.split(",")
            : matchedOptions,
        );
      }
    }
  };

  const responseOptionsTransformer = (options) => {
    return options?.map((item) => ({
      value: item?.value,
      label: item?.label,
      count: item?.count,
    }));
  };

  const renderOption = (props, option, { selected }) => {
    const { key, ...optionProps } = props;
    return renderOptionDropdown({
      option,
      selected,
      key,
      optionProps,
    });
  };

  const renderOptionDropdown = ({
    filter = null,
    val = null,
    i = null,
    option = null,
    selected = null,
    key = null,
    optionProps = null,
  }) => {
    return (
      <CustomMenuItem
        key={option == null ? i : key}
        value={
          filter?.name === "manual_topics"
            ? val
            : filter?.name === "LanguagesDistribution" ||
                filter?.name === "CountriesDistribution" ||
                filter?.name === "NBSourceRank"
              ? filter?.name === "NBSourceRank"
                ? val?.value?.replace(/"/g, "")
                : val?.value
              : val?.label && filter?.name !== "sub_themes"
                ? val?.label
                : val?.value
        } //some filters doesn't have label so we use value instead for those cases
        className="monitor-filter-item"
        {...optionProps}
      >
        <Checkbox
          sx={{ margin: "5px" }}
          checked={
            option == null
              ? reduxFilterParams[filter?.name]?.indexOf(
                  filter?.name === "manual_topics"
                    ? val
                    : filter?.name === "LanguagesDistribution" ||
                        filter?.name === "CountriesDistribution" ||
                        filter?.name === "NBSourceRank"
                      ? filter?.name === "NBSourceRank"
                        ? val?.value?.replace(/"/g, "")
                        : val?.value
                      : val?.label && filter?.name !== "sub_themes"
                        ? val?.label
                        : val?.value,
                ) > -1
              : selected
          }
        />
        <ListItemText
          primary={
            option == null ? (
              filter?.name === "LanguagesDistribution" &&
              val?.label === "no" ? (
                CheckValueLocale("norwegian", "", {}, intl)
              ) : filter?.name !== "SourceDistribution" && val?.label ? (
                filter?.name === "segment" || filter?.name === "sub_themes" ? (
                  val?.label
                ) : filter?.name === "NBSourceRank" ? (
                  CheckValueLocale("rank", "", {}, intl) +
                  " " +
                  val?.value?.replace(/"/g, "")
                ) : (
                  CheckValueLocale(
                    checkLabelFilter(val?.label?.toLowerCase(), filter?.name),
                    "",
                    {},
                    intl,
                  )
                )
              ) : val?.value ? (
                CheckValueLocale(val?.value.toLowerCase(), "", {}, intl)
              ) : (
                ""
              )
            ) : (
              <Box className="searchable-checkbox-labels-item-container">
                <Box className="options-with-count-label">{option?.label}</Box>
                <Box className="options-with-count">({option?.count})</Box>
              </Box>
            )
          }
        />
      </CustomMenuItem>
    );
  };
  /* ______________________________________________________________________________ */
  //hande inputfileds after user click "enter" btn
  const handleKeyDown = (event, inputName) => {
    var value = event.target.value;

    //inputValue.value.trim().length !== 0 is added to refuse empty input or contating spaces only
    if (
      (event.keyCode === 13 || event.type === "click") &&
      inputValue?.value?.trim()?.length !== 0
    ) {
      //Prevent user to enter same value twice
      if (inputName === "exclude_user" || inputName === "users") {
        const val = handleKeyFunc(inputName, "users", "exclude_user");
        if (val !== undefined) {
          return val;
        }
        setInputValue({ name: inputName, value: "" });
      }
      if (inputName === "keywords" || inputName === "exclude_keywords") {
        if (event.keyCode === 13 && dataExist && value?.trim().length !== 0) {
          let objData = handlekeywordsShared(
            value,
            inputName === "keywords" ? "keywords" : "spam_keywords",
            reduxFilterParams["keywords"]
              ? reduxFilterParams["keywords"]?.split(",")
              : [],
            reduxFilterParams["exclude_keywords"]
              ? reduxFilterParams["exclude_keywords"]?.split(",")
              : [],
            "twitter",
            [
              {
                keywords: reduxFilterParams["keywords"]
                  ? reduxFilterParams["keywords"]?.split(",")
                  : [],
              },
              {
                spam_keywords: reduxFilterParams["exclude_keywords"]
                  ? reduxFilterParams["exclude_keywords"]?.split(",")
                  : [],
              },
            ], //keywords such as [{keywords:[]},{spamKeywords:[]}]
            100,
            undefined, //in case newsblogs,
            undefined,
          );
          if (!objData?.errorMsg) {
            var obj = {
              ...reduxFilterParams,
              keywords:
                reduxFilterParams["keywords"] &&
                !isEmptyArray(reduxFilterParams["keywords"]) &&
                !!reduxFilterParams["keywords"]
                  ? [...objData?.keywords].join()
                  : [...objData?.keywords].join(),
              exclude_keywords:
                reduxFilterParams["exclude_keywords"] &&
                !isEmptyArray(reduxFilterParams["exclude_keywords"]) &&
                !!reduxFilterParams["exclude_keywords"]
                  ? [...objData?.spamKeywords].join()
                  : [...objData?.spamKeywords].join(),
            };
            if (!checkExludeExist) {
              delete obj.exclude_keywords;
            }
            if (!checkKeywordsExist) {
              delete obj.keywords;
            }
            props?.setFilterParams(obj);
            dispatch(filters(obj));
            if (!objData.exccedKeywordsLimit) {
              setInputValue({
                name: inputName,
                value: "",
              });
            }
          } else {
            setInputValue({
              name: inputName,
              value: value,
            });
          }

          setErrorMessageKeywords(objData?.errorMsg);
        } else {
          if (!errorMessageKeywords)
            setInputValue({
              name: "",
              value: "",
            });
        }
      } else {
        [inputValue]?.map((i) => {
          if (i.name === inputName && i.value !== "") {
            var obj = {
              ...reduxFilterParams,
              [inputName]:
                reduxFilterParams[inputName] &&
                !isEmptyArray(reduxFilterParams[inputName]) &&
                !!reduxFilterParams[inputName]
                  ? [
                      ...reduxFilterParams[inputName]?.toString()?.split(","),
                      i.value.trim(),
                    ].join()
                  : [i.value.trim()].join(),
            };
            props?.setFilterParams(obj);
            dispatch(filters(obj));
          }
        });
        setInputValue({ name: inputName, value: "" }); //empty filed after user press enter
      }
    }
  };
  /* ______________________________________________________________________________ */
  //handle delete chip
  const handleDeleteWord = (e, index, inputName) => {
    let words = props?.filterParams[inputName]
      ? reduxFilterParams[inputName]?.toString()?.split(",")
      : [props?.filterParams[inputName]];
    if (Array.isArray(words) && words?.length > 0) {
      words?.splice(index, 1);
    }
    let obj = {
      ...props?.filterParams,
      [inputName]:
        Array.isArray(words) && words?.length > 0 ? words?.join() : "",
    };
    props?.setFilterParams(obj);
    dispatch(filters(obj));
  };

  const checkLabelFilter = (label, filterName) => {
    return isAudiencePage && filterName === "location"
      ? `${label}_location`
      : label;
  };
  let checkExludeExist = props?.filterFields
    ?.map((item) => item?.name)
    ?.includes("exclude_keywords");
  let checkKeywordsExist = props?.filterFields
    ?.map((item) => item?.name)
    ?.includes("keywords");

  const handlePasteTwitterURL = (event) => {
    const pastedText = event.clipboardData.getData("text");
    event.preventDefault();

    const { extractedId, message } = handleTwitterURLPaste(pastedText);
    setIsParentIdTooltipOpen(message);

    handleChange({
      target: {
        value: extractedId || "",
        name: "parent_id",
      },
    });
  };

  return (
    <>
      {!isAudiencePage && (
        <Box className="filter_sort">
          <CustomFormControl
            sx={{ width: 300, marginBottom: "15px" }}
            className=" monitor-filter-item"
          >
            <Typography className="monitor_filter__label" mb={1}>
              <InputLabel id="demo-multiple-checkbox-label">
                {CheckValueLocale("select_saved_filter", "", {}, intl)}
              </InputLabel>
            </Typography>
            <CustomSelect
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              label="Select Saved Filters"
              onChange={props.handleSavedFilter}
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: 300,
                  },
                },
              }}
              className="filter_dropdown"
              renderValue={(selected) => props.filterName}
              value={props.savedFilterId}
              disabled={isEmpty(props?.reduxSavedFilter)}
            >
              {!isEmpty(props.savedFilters) &&
                props.savedFilters?.map((val, i) => {
                  return (
                    <MenuItem key={i} value={val?.attributes.id}>
                      {val?.attributes?.name_filter}
                    </MenuItem>
                  );
                })}
            </CustomSelect>
          </CustomFormControl>
          {props?.showDeleteFilterBtn && props?.savedFilterId ? (
            <Button
              variant="outlined"
              startIcon={<DeleteIcon />}
              color="error"
              onClick={props?.handleDeleteBtn}
              id="monitors-delete-filter-btn"
            >
              {CheckValueLocale("delete_filter", "", {}, intl)}
            </Button>
          ) : null}
        </Box>
      )}
      {/*case of Accounts Lists _____________________________________________________________ */}
      {(reduxFilterParams?.users != "" && reduxFilterParams?.users) ||
      (reduxFilterParams?.exclude_user != "" &&
        reduxFilterParams?.exclude_user) ? (
        <CustomAlert severity="info">
          {CheckValueLocale(
            "accounts_lists_first_alert",
            "",
            {
              accountsLists: (
                <strong>
                  {CheckValueLocale("activity_log_Account_Lists", "", {}, intl)}
                </strong>
              ),
              users: (
                <strong>
                  {CheckValueLocale("user_user_activity", "", {}, intl)}
                </strong>
              ),
            },
            intl,
          )}
        </CustomAlert>
      ) : null}
      {/*case of DropDowns _____________________________________________________________ */}
      {props?.filterFields
        ?.sort((a, b) => a.priority - b.priority)
        ?.map((filter, i) => {
          if (filter.selection_attribute == "searchable-checkbox-labels") {
            const transformedOptions = responseOptionsTransformer(
              filter?.options,
            );
            reserveRenderedValue(filter?.options);
            return (
              <SearchableMultiSelectFilter
                style={{ width: 300, marginBottom: "15px" }}
                filterName={filter?.name}
                disable={disableSearchableMultiSelect(filter?.name)}
                checkDisableOptions={checkDisableOptions}
                handleListChange={handleListChange}
                valueToRender={valueToRender}
                renderOption={renderOption}
                noOptionsText={createListButton}
                optionList={transformedOptions}
              />
            );
          }
          if (
            filter.selection_attribute !== "checkbox-inline" &&
            filter.selection_attribute !== "input-add" &&
            filter.selection_attribute !== "null" &&
            filter.name !== undefined &&
            filter.name !== "seperator" && //hide seperator option
            filter.name !== "export" && //hide export option
            filter.name !== "parent_id"
          ) {
            return (
              <CustomFormControl
                sx={{
                  width: 300,
                  marginBottom: "15px",
                  display:
                    isEmpty(filter.options) &&
                    filter.selection_attribute !== "input-number" &&
                    "none", //in case the dropdown options is empty from BE it won't be shown to user
                }}
                key={i}
                className="monitor-filter-item"
              >
                <Typography className="monitor_filter__label" mb={1}>
                  {/* User Translation method for all types*/}
                  <InputLabel id="demo-multiple-checkbox-label">
                    {CheckValueLocale(
                      (filter?.name === "LanguagesDistribution" ||
                        filter?.name === "CountriesDistribution") &&
                        props?.dataSourceName === "NEWSBLOGS"
                        ? filter?.name + "_nb"
                        : filter?.name === "themes"
                          ? "filter_category"
                          : filter?.name == "sub_themes"
                            ? "themes"
                            : filter?.name,
                      "",
                      {},
                      intl,
                    )}
                  </InputLabel>
                </Typography>
                {filter.selection_attribute !== "input-number" ? (
                  <CustomSelect
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple={filter?.name === "verified_only" ? false : true}
                    value={
                      reduxFilterParams[filter?.name] !== undefined
                        ? !Array.isArray(reduxFilterParams[filter?.name])
                          ? reduxFilterParams[filter?.name]?.split(",")
                          : reduxFilterParams[filter?.name]
                        : []
                    }
                    label={CheckValueLocale(
                      (filter?.name === "LanguagesDistribution" ||
                        filter?.name === "CountriesDistribution") &&
                        props?.dataSourceName === "NEWSBLOGS"
                        ? filter?.name + "_nb"
                        : filter?.name === "themes"
                          ? "filter_category"
                          : filter?.name == "sub_themes"
                            ? "themes"
                            : filter?.name,
                      "",
                      {},
                      intl,
                    )}
                    name={filter?.name}
                    onChange={handleChange}
                    className="filter_dropdown monitor-filter-item"
                    renderValue={(selected) =>
                      Array.isArray(selected)
                        ? selected.map((i, index) => {
                            if (filter?.name == "manual_topics") {
                              return (
                                filter?.options?.filter(
                                  (item) => item?.id == i?.id,
                                )?.[0]?.value + ", "
                              );
                            } else {
                              let labels =
                                filter?.options?.filter(
                                  (key) => key?.value === i,
                                ) ?? "";
                              if (labels[0]?.label) {
                                i =
                                  filter?.name === "segment"
                                    ? labels[0]?.label
                                    : CheckValueLocale(
                                        checkLabelFilter(
                                          labels[0]?.label?.toLowerCase(),
                                          filter?.name,
                                        ),
                                        "",
                                        {},
                                        intl,
                                      );
                              }

                              if (
                                selected?.length > 1 &&
                                index !== selected?.length - 1
                              ) {
                                return [
                                  filter?.name === "segment"
                                    ? i + ", "
                                    : CheckValueLocale(
                                        i.toLowerCase(),
                                        "",
                                        {},
                                        intl,
                                      ) + ", ",
                                ];
                              } else {
                                let labels =
                                  filter?.options?.filter(
                                    (key) => key?.value === i,
                                  ) ?? "";
                                if (labels[0]?.label) {
                                  i =
                                    filter?.name === "segment"
                                      ? labels[0]?.label
                                      : CheckValueLocale(
                                          checkLabelFilter(
                                            labels[0]?.label?.toLowerCase(),
                                            filter?.name,
                                          ),
                                          "",
                                          {},
                                          intl,
                                        );
                                }

                                if (
                                  selected?.length > 1 &&
                                  index !== selected?.length - 1
                                ) {
                                  return [
                                    filter?.name === "segment"
                                      ? i + ", "
                                      : CheckValueLocale(
                                          i.toLowerCase(),
                                          "",
                                          {},
                                          intl,
                                        ) + ", ",
                                  ];
                                } else {
                                  return [
                                    filter?.name === "segment"
                                      ? i
                                      : CheckValueLocale(
                                          i.toLowerCase(),
                                          "",
                                          {},
                                          intl,
                                        ),
                                  ];
                                }
                              }
                            }
                          })
                        : selected.split(",").map((i, index) => {
                            let labels = filter?.options?.filter(
                              (key) => key?.value === i,
                            );
                            if (labels[0]?.label) {
                              i =
                                filter?.name === "segment"
                                  ? labels[0]?.label
                                  : CheckValueLocale(
                                      labels[0]?.label.toLowerCase(),
                                      "",
                                      {},
                                      intl,
                                    );
                            }

                            if (
                              selected?.length > 1 &&
                              index !== selected?.length - 1
                            ) {
                              return [
                                filter?.name === "segment"
                                  ? i
                                  : CheckValueLocale(
                                      checkLabelFilter(
                                        i.toLowerCase(),
                                        filter?.name,
                                      ),
                                      "",
                                      {},
                                      intl,
                                    ) + ", ",
                              ];
                            } else {
                              return [
                                filter?.name === "segment"
                                  ? i
                                  : CheckValueLocale(
                                      i.toLowerCase(),
                                      "",
                                      {},
                                      intl,
                                    ),
                              ];
                            }
                          })
                    }
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: 300,
                        },
                      },
                    }}
                    disabled={isEmpty(filter?.options)}
                  >
                    {!isEmpty(filter?.options) &&
                      filter?.options?.map((val, i) => {
                        return (filter?.name === "LanguagesDistribution" &&
                          (val?.label === "qme" ||
                            val?.label === "zxx" ||
                            val?.label === "qam")) ||
                          (filter?.name === "location" && val?.label === "IL")
                          ? null
                          : renderOptionDropdown({ filter, val, i });
                      })}
                  </CustomSelect>
                ) : (
                  //case of Number Input filed
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    type="number"
                    label={CheckValueLocale(filter?.name, "", {}, intl)}
                    name={filter?.name}
                    onChange={handleChange}
                    value={
                      reduxFilterParams[filter?.name]
                        ? reduxFilterParams[filter?.name]
                        : ""
                    }
                    className="filter_dropdown monitor-filter-item"
                  />
                )}
              </CustomFormControl>
            );
          }
        })}
      {/* ______________________________________________________________________________ */}
      {props?.filterFields?.length > 0 && <Box className="filter_sort"></Box>}
      {props?.filterFields !== undefined &&
        props?.filterFields
          .sort((a, b) => a.priority - b.priority)
          .map((filter, key) => {
            if (
              filter.selection_attribute === "checkbox-inline" ||
              filter.selection_attribute === "input-add"
            ) {
              return (
                <Fragment key={key}>
                  {filter.selection_attribute !== "checkbox-inline" ? (
                    //case of Inputs
                    <>
                      {filter?.name === "users" &&
                      (reduxFilterParams?.account_lists?.length ||
                        reduxFilterParams?.exclude_account_lists?.length) ? (
                        <CustomAlert severity="info">
                          {CheckValueLocale(
                            "accounts_lists_second_alert",
                            "",
                            {
                              accountsLists: (
                                <strong>
                                  {CheckValueLocale(
                                    "activity_log_Account_Lists",
                                    "",
                                    {},
                                    intl,
                                  )}
                                </strong>
                              ),
                              users: (
                                <strong>
                                  {CheckValueLocale(
                                    "user_user_activity",
                                    "",
                                    {},
                                    intl,
                                  )}
                                </strong>
                              ),
                            },
                            intl,
                          )}
                        </CustomAlert>
                      ) : null}
                      <CustomFormControl
                        sx={{ width: 300, marginBottom: "15px" }}
                        className="textfield-label monitor-filter-item"
                      >
                        <InputLabel
                          htmlFor="outlined-adornment-amount"
                          className={
                            inputValue.name === filter?.name &&
                            errorMessageKeywords !== ""
                              ? "label-error"
                              : ""
                          }
                        >
                          {CheckValueLocale(filter?.name, "", {}, intl)}
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-amount"
                          disabled={
                            (filter?.name === "users" ||
                              filter?.name === "exclude_user") &&
                            allowUsersInput
                          }
                          startAdornment={
                            (filter?.name === "users" ||
                              filter?.name === "exclude_user") && (
                              <InputAdornment position="start">
                                @
                              </InputAdornment>
                            )
                          }
                          onFocus={() =>
                            filter?.name === "users" ||
                            filter?.name === "NBSourceName"
                              ? setFocused(true)
                              : null
                          }
                          onBlur={() =>
                            filter?.name === "users" ||
                            filter?.name === "NBSourceName"
                              ? setFocused(false)
                              : null
                          }
                          endAdornment={
                            (filter?.name === "users" ||
                              filter?.name === "NBSourceName") &&
                            focused ? (
                              <InputAdornment
                                position="end"
                                onMouseDown={handleInputMouseDown}
                              >
                                <LucButton
                                  className={`hashtags-btn-field ${errorMessageKeywords !== "" && filter?.name === "NBSourceName" ? "add-plus-error" : ""}`}
                                  variant="flat"
                                  onClick={(e) => {
                                    e.target.value =
                                      inputValue?.name === filter?.name
                                        ? inputValue?.value
                                        : "";
                                    if (!errorMessageKeywords)
                                      handleKeyDown(e, filter?.name);
                                  }}
                                  id="filter-dropdown-add-user-btn"
                                >
                                  {CheckValueLocale("add_plus", "", {}, intl)}
                                </LucButton>
                              </InputAdornment>
                            ) : null
                          }
                          label={CheckValueLocale(filter?.name, "", {}, intl)}
                          className={`filter_dropdown ${checkDirectionLang(
                            inputValue?.value,
                          )}`}
                          onKeyDown={(e) => {
                            if (!errorMessageKeywords)
                              handleKeyDown(e, filter?.name);
                          }}
                          onChange={(e) => handleChangeInput(e, filter?.name)}
                          value={
                            inputValue?.name === filter?.name
                              ? inputValue?.value
                              : ""
                          }
                          name={filter?.name}
                          error={
                            inputValue?.name === filter?.name &&
                            errorMessageKeywords !== ""
                          }
                        />
                        {errorMessageKeywords &&
                        inputValue?.name === filter?.name ? (
                          <Box className="err-msg">
                            {CheckValueLocale(
                              errorMessageKeywords,
                              "",
                              {
                                limit:
                                  filter?.name === "NBSourceName" ? 50 : 100,
                              },
                              intl,
                            )}
                          </Box>
                        ) : (
                          <FormHelperText
                            id="filled-weight-helper-text"
                            style={{ marginBottom: "5px" }}
                          >
                            {CheckValueLocale(
                              "click_enter_to_add",
                              "",
                              {},
                              intl,
                            )}
                          </FormHelperText>
                        )}
                      </CustomFormControl>
                      {/* Chip Section__________________________________________________ */}
                      {!isEmptyArray(reduxFilterParams[filter?.name]) &&
                        reduxFilterParams[filter?.name] !== undefined &&
                        reduxFilterParams[filter?.name] !== "" && (
                          <div className="section-box-data box-margin">
                            {/* this part has two cases if user add keywords for 1st time it will be added in type of string 
                            BUT if user choose old saved filter that contain keywords it will be in type of Array
                            so we do split in first case to avoid map crash  */}
                            {typeof reduxFilterParams[filter?.name] === "string"
                              ? reduxFilterParams[filter?.name]
                                  ?.split(",")
                                  .map((word, index) => {
                                    return (
                                      <KeywordTooltipChip
                                        key={index}
                                        onDelete={(e) =>
                                          handleDeleteWord(
                                            e,
                                            index,
                                            filter?.name,
                                          )
                                        }
                                        dataSource={"twitter"}
                                        index={index}
                                        labels={word}
                                      />
                                    );
                                  })
                              : reduxFilterParams[filter?.name].map(
                                  (word, index) => {
                                    return (
                                      <Chip
                                        key={index}
                                        label={word}
                                        className={"chip-style"}
                                        onDelete={(e) =>
                                          handleDeleteWord(
                                            e,
                                            index,
                                            filter?.name,
                                          )
                                        }
                                      />
                                    );
                                  },
                                )}
                          </div>
                        )}
                    </>
                  ) : (
                    //case of checkBoxes
                    <FormGroup className="check_box">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              reduxFilterParams[filter?.name] == "True"
                                ? true
                                : false
                            }
                            onChange={handleChange}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label={CheckValueLocale(filter?.name, "", {}, intl)}
                        name={filter?.name}
                      />
                    </FormGroup>
                  )}
                </Fragment>
              );
            }
          })}
      {props?.filterFields !== undefined &&
        props?.filterFields
          .sort((a, b) => a.priority - b.priority)
          .map((filter, key) => {
            if (filter?.name === "parent_id") {
              return (
                <Fragment key={key}>
                  <Box className="filter_sort"></Box>
                  <CustomFormControl
                    sx={{
                      width: 300,
                      marginBottom: "15px",
                      display:
                        isEmpty(filter.options) &&
                        filter.selection_attribute !== "input-number" &&
                        "none",
                    }}
                    className="monitor-filter-item"
                  >
                    <Typography className="monitor_filter__label" mb={1}>
                      <InputLabel id="demo-multiple-checkbox-label">
                        {CheckValueLocale(
                          filter?.name + "_filter_label",
                          "",
                          {},
                          intl,
                        )}
                      </InputLabel>
                    </Typography>
                    <ClickAwayListener
                      onClickAway={() => setIsParentIdTooltipOpen(false)}
                    >
                      <Tooltip
                        arrow
                        id="engagement-tooltip-parent-id"
                        placement="left"
                        onClose={() => setIsParentIdTooltipOpen(false)}
                        open={!!isParentIdTooltipOpen}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={CheckValueLocale(
                          isParentIdTooltipOpen,
                          "",
                          {},
                          intl,
                        )}
                      >
                        <OutlinedInput
                          id="outlined-adornment-amount"
                          label={CheckValueLocale(
                            filter?.name + "_filter_label",
                            "",
                            {},
                            intl,
                          )}
                          name={filter?.name}
                          onChange={handleChange}
                          type="number"
                          // inputProps={{
                          //   maxLength: 19,
                          // }}
                          onPaste={(e) => handlePasteTwitterURL(e)}
                          onWheel={(e) => e.target.blur()}
                          value={
                            reduxFilterParams[filter?.name]
                              ? reduxFilterParams[filter?.name]
                              : ""
                          }
                          className="filter_dropdown monitor-filter-item"
                        />
                      </Tooltip>
                    </ClickAwayListener>
                    <Typography className="post-parent-id-helper-text">
                      {CheckValueLocale(
                        "parent_id_filter_tooltip_title",
                        "",
                        {},
                        intl,
                      )}
                    </Typography>
                  </CustomFormControl>
                </Fragment>
              );
            }
          })}
    </>
  );
};
export default FilterDropDownMenu;
