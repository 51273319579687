import { FormControl, MenuItem, TextField } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const MenuProps = {
  style: {
    zIndex: 99999,
  },
};

export const WidgetViewType = (props) => {
  const intl = useIntl();
  return (
    <FormControl
      className="view-as-select-widget-dropdown"
      id="select-view-as-dropdown"
    >
      <TextField
        select
        label={CheckValueLocale("view_as", "", {}, intl)}
        value={props?.viewType}
        defaultValue="table"
        onChange={(e) => props?.handleViewTypeChange(e)}
        className="view-widget-dropdown-field"
        SelectProps={{
          MenuProps,
        }}
      >
        <MenuItem value="table">
          {CheckValueLocale("table", "", {}, intl)}
        </MenuItem>
        <MenuItem value="wordCloud">
          {CheckValueLocale("word_cloud", "", {}, intl)}
        </MenuItem>
      </TextField>
    </FormControl>
  );
};
