import PopupModal from "components/popupModal";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { Box } from "@mui/material";

const DeletePublicReplyModal = ({
  open,
  close,
  handleDeleteReply,
  replyToBeDeleted,
}) => {
  const intl = useIntl();

  const handleDelete = () => {
    handleDeleteReply(replyToBeDeleted);
    close();
  };

  return (
    <PopupModal
      title={CheckValueLocale("delete_public_reply", "", {}, intl)}
      body={
        <Box className="shared-dialog-body">
          <p>{`${CheckValueLocale(
            "delete_public_reply_message",
            "",
            {},
            intl,
          )}`}</p>
          <h2 className="sub-text-desc">
            {CheckValueLocale("deleting_msg", "", {}, intl)}
          </h2>
        </Box>
      }
      open={open}
      close={close}
      leftBtn={CheckValueLocale("cancel", "", {}, intl)}
      rightBtn={CheckValueLocale("delete_confirm", "", {}, intl)}
      leftBtnId="cancel-delete-reply-button"
      rightBtnId="delete-reply-button"
      classeName="delete-public-reply-message-modal actions-buttons-shared"
      warning
      accept={handleDelete}
    />
  );
};

export default DeletePublicReplyModal;
