import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { Box, Container } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useCreateReportInitialValues } from "./hooks/useCreateReportInitialValues";
import { useCreateReportValidation } from "./hooks/useCreateReportValidation";
import { useHandleMonitorsList } from "./hooks/useHandleMonitorsList";
import { useReportProducts } from "./hooks/useReportProduct";
import { useReportMonitorTypes } from "./hooks/useReportMonitorTypes";
import { useReportTypes } from "./hooks/useReportTypes";
import { useReportDatasources } from "./hooks/useReportDatasources";
import { useCreateReport } from "./hooks/useCreateReport";
import { CreateReportContext } from "../../context/reportContext";
import CircularLoading from "components/circularProgress";
import SnackBar from "components/snackBar";
import PageHeader from "components/pageHeader";
import CreateReportStepperHeader from "./components/createReportStepperHeader";
import CreateReportStepperContent from "./components/createReportStepperContent";
import CreateReportStepperFooter from "./components/createReportStepperFooter";
import "./style.scss";

const CreateReport = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const intl = useIntl();

  const [step, setStep] = useState(0);
  const [snackbar, setSnackbar] = useState({});

  const isMonitorAction = location?.state?.isMonitorAction; // Check if the report is created from monitor header button

  // Add step name to pathname depending on step number
  useEffect(() => {
    const stepRoutes = { 0: "information", 1: "setting", 2: "delivery" };
    navigate(`/reports/add-report/${stepRoutes?.[step]}`, {
      state: { ...location?.state },
    });
  }, [step]);

  // Create report mutation hook
  const { mutate: createReport, isPending: createReportLoading } =
    useCreateReport();

  const handleCreateReport = () => {
    const { values } = formik || {};

    // Check if the selected report type is newsletter
    const isNewsletterReport =
      availableReportTypes?.idsToNames?.[values?.report_type_id] ==
      "newsletter";

    // Check if the selected channel_type is ACCOUNT_ANALYSIS and data source is instagram
    const isPublicAccountInstagram =
      formik?.values?.channel_type === "ACCOUNT_ANALYSIS" &&
      availableDatasources?.idsToNames?.[
        formik?.values?.data_source_id?.[0]
      ] === "INSTAGRAM";

    const queryData = {
      ...values,
      monitor_list:
        useHandleMonitorsList(
          values?.monitor_list,
          formik?.values?.data_source_id,
        ) || [], // Handle monitors list to be grouped by data_source_id
      start_date_time: values?.start_date_time?.unix(), // Convert to unix timestamp
      report_template:
        isNewsletterReport && values?.channel_type === "KEYWORD"
          ? "old_design" // Use old_design for newsletter report with keyword channel type
          : "dynamic_design",
      is_luci_report: isNewsletterReport
        ? undefined // Don't send is_luci_report to Backend for newsletter report
        : isPublicAccountInstagram
          ? false // Send is_luci_report as false when is public_account >>> instagram
          : values?.is_luci_report,
      is_branding: isNewsletterReport ? undefined : values?.is_branding, // Don't send is_branding to Backend for newsletter report
    };

    createReport(
      {
        queryData: Object.fromEntries(
          Object.entries(queryData)?.filter(
            ([key, value]) =>
              value !== undefined && // Remove undefined values from queryData sent to Backend
              !["data_source_id", "data_included"]?.includes(key), // Remove these keys from queryData sent to Backend
          ),
        ),
      },
      {
        onSuccess: (data) => {
          if (isMonitorAction) {
            navigate(location?.state?.monitorPath, {
              state: {
                showSnackBar: true,
                snackBarStatus: data?.status === 201 ? "success" : "error",
                snackBarMsg:
                  data?.status === 201
                    ? CheckValueLocale("report_added_success", "", {}, intl)
                    : CheckValueLocale("report_added_fail", "", {}, intl),
              },
            });
          } else {
            navigate("/reports/list", {
              state: { ...location?.state, create_report: "success" },
            });
          }
        },
        onError: () =>
          navigate("/reports/list", {
            state: { ...location?.state, create_report: "error" },
          }),
      },
    );
  };

  const handleNextStep = () => {
    setStep((prevActiveStep) => prevActiveStep + 1);
    formik?.setTouched({});
    formik?.setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: useCreateReportInitialValues() || {},
    validationSchema: useCreateReportValidation(step),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: step === 2 ? handleCreateReport : handleNextStep,
  });

  /** ----------------------------------------------- 
   * Get available products step (Report Information) 
   ----------------------------------------------- */
  const {
    data: availableProducts,
    isLoading: availableProductsLoading,
    isError: availableProductsError,
  } = useReportProducts();

  /** ------------------------------------------------
   * Get available monitor types "Social Listening" step (Report Setting) 
  ------------------------------------------------- */
  const {
    data: availableMonitorTypes,
    isLoading: availableMonitorTypesLoading,
    isError: availableMonitorTypesError,
  } = useReportMonitorTypes({
    enabled:
      step === 1 && // Enable the query when the step is 1: Report Setting
      availableProducts?.idsToNames?.[formik?.values?.product_id] === "SM", // Enable the query when the selected product is Social Listening
    productId: formik?.values?.product_id,
  });

  const isManagedAccount = formik?.values?.channel_type === "MANAGED_ACCOUNT";

  const managedPageTypeId = availableMonitorTypes?.namesToIds?.["MANAGED_PAGE"];

  // If we select MANAGED_ACCOUNT we need to send also MANAGED_PAGE id as an array only to be array in this case
  const selectedMonitorTypes = isManagedAccount
    ? [
        availableMonitorTypes?.namesToIds?.[formik?.values?.channel_type],
        ...(!!managedPageTypeId ? [managedPageTypeId] : []),
      ]
    : availableMonitorTypes?.namesToIds?.[formik?.values?.channel_type];

  /** -----------------------------------------------
   * Get available report types step (Report Setting)
  ----------------------------------------------- */
  const {
    data: availableReportTypes,
    isLoading: availableReportTypesLoading,
    isError: availableReportTypesError,
  } = useReportTypes();

  /** --------------------------------------------------------
   * Get available report datasources step (Report Setting)
  -------------------------------------------------------- */
  const {
    data: availableDatasources,
    isLoading: availableDatasourcesLoading,
    isError: availableDatasourcesError,
  } = useReportDatasources({
    queryData: { monitor_type_id: selectedMonitorTypes },
  });

  useEffect(() => {
    if (
      availableProductsError ||
      availableMonitorTypesError ||
      availableReportTypesError ||
      availableDatasourcesError
    ) {
      setSnackbar({
        open: true,
        severity: "error",
        title: "something_went_wrong",
        message: "try_again",
      });
    }
  }, [
    availableProductsError,
    availableMonitorTypesError,
    availableReportTypesError,
    availableDatasourcesError,
  ]);

  const contextProps = {
    formik,
    step,
    setStep,
    availableProducts,
    availableReportTypes,
    availableMonitorTypes,
    availableMonitorTypesLoading,
    availableDatasources,
    availableDatasourcesLoading,
    availableMonitorTypesLoading,
    createReportLoading,
    setSnackbar,
    isMonitorAction,
    selectedMonitorTypes,
  };

  return (
    <CreateReportContext.Provider value={contextProps || {}}>
      <Container maxWidth="xl">
        <PageHeader title="create_report" />
        {availableProductsLoading || availableReportTypesLoading ? (
          <CircularLoading />
        ) : (
          <Box id="create-report-stepper-container">
            <CreateReportStepperHeader />
            <CreateReportStepperContent />
            <CreateReportStepperFooter />
          </Box>
        )}
        {snackbar?.open ? (
          <SnackBar
            open={snackbar?.open}
            severity={snackbar?.severity}
            title={CheckValueLocale(snackbar?.title, "", {}, intl)}
            message={CheckValueLocale(snackbar?.message, "", {}, intl)}
            handleClose={() => setSnackbar({})}
          />
        ) : null}
      </Container>
    </CreateReportContext.Provider>
  );
};

export default CreateReport;
