import { useQuery } from "@tanstack/react-query";
import AccountsListController from "services/controllers/accountsListController";

export const useAccountsList = (searchText, page) => {
  return useQuery({
    queryKey: ["accountsList", searchText, page],
    queryFn: () => AccountsListController.getAccountsList(searchText, page),
    retry: false,
  });
};
