import { useIntl } from "react-intl";
import {
  Box,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import DisplayOptionSelect from "./components/displayOptionSelect";
import ColorCodeAndPicker from "./components/colorCodeAndPicker";

const ButtonStyleOptions = (props) => {
  const {
    embeddedOptions,
    surveyData,
    setSurveyData,
    savedSurveyData,
    embeddedFonts,
  } = props;

  const intl = useIntl();

  const selectedOptions = surveyData?.embedded_config?.button_style || {};

  const handleChangeOption = (key, value) => {
    setSurveyData((prev) => ({
      ...prev,
      embedded_config: {
        ...prev?.embedded_config,
        button_style: {
          ...prev?.embedded_config?.button_style,
          [key]: value,
        },
      },
    }));
  };

  const handleBlurTitleOption = (key, value) => {
    if (value?.trim()?.length < 2 || value?.trim()?.length > 25)
      setSurveyData((prev) => ({
        ...prev,
        embedded_config: {
          ...prev?.embedded_config,
          button_style: {
            ...prev?.embedded_config?.button_style,
            [key]:
              savedSurveyData?.embedded_config?.button_style?.[key] ||
              embeddedOptions?.button_style?.button_title?.default_value,
          },
        },
      }));
  };

  const titleError =
    surveyData?.embedded_config?.button_style?.button_title?.length < 2 ||
    surveyData?.embedded_config?.button_style?.button_title?.length > 25;

  return (
    <Box className="display-settings-options-accordion-content">
      <Box className="display-settings-options-accordion-content-option-wrapper">
        <Typography className="display-settings-options-subtitle">
          {CheckValueLocale("display_settings_button_title", "", {}, intl)}
        </Typography>
        <FormControl
          id="embedded-survey-display-settings-button-title-form-control"
          variant="outlined"
          size="small"
          fullWidth
        >
          <TextField
            id="embedded-survey-display-settings-button-title-field"
            className="embedded-survey-display-settings-text-field"
            value={selectedOptions?.button_title || ""}
            onChange={(e) => handleChangeOption("button_title", e.target.value)}
            onBlur={(e) =>
              handleBlurTitleOption("button_title", e.target.value)
            }
            variant="outlined"
            size="small"
            fullWidth
            error={titleError}
          />
          {titleError ? (
            <FormHelperText
              id="embedded-survey-display-settings-text-field-helper-text"
              error
            >
              {CheckValueLocale(
                "display_settings_button_title_error_msg",
                "",
                {},
                intl,
              )}
            </FormHelperText>
          ) : null}
        </FormControl>
      </Box>

      <Box className="display-settings-options-accordion-content-option-wrapper">
        <Typography className="display-settings-options-subtitle">
          {CheckValueLocale("display_settings_font_color", "", {}, intl)}
        </Typography>
        <ColorCodeAndPicker
          id="button-style-font-color-field"
          value={selectedOptions?.font_color}
          onChange={(e) => handleChangeOption("font_color", e?.target?.value)}
          defaultValue={
            savedSurveyData?.embedded_config?.button_style?.font_color
          }
        />
      </Box>

      <Box className="display-settings-options-accordion-content-option-wrapper">
        <Typography className="display-settings-options-subtitle">
          {CheckValueLocale("display_settings_font_type", "", {}, intl)}
        </Typography>
        <DisplayOptionSelect
          id="display-settings-button-font-type-select"
          options={embeddedFonts?.map((font) => {
            return {
              label: font?.attributes?.font_name,
              value: font?.attributes?.css_identifier,
            };
          })}
          value={selectedOptions?.font_type}
          onChange={(e) => handleChangeOption("font_type", e?.target?.value)}
          fontsList
        />
      </Box>

      <Box className="display-settings-options-accordion-content-option-wrapper">
        <Typography className="display-settings-options-subtitle">
          {CheckValueLocale("display_settings_background_color", "", {}, intl)}
        </Typography>
        <ColorCodeAndPicker
          id="button-style-background-color-field"
          value={selectedOptions?.background_color}
          onChange={(e) =>
            handleChangeOption("background_color", e?.target?.value)
          }
          defaultValue={
            savedSurveyData?.embedded_config?.button_style?.background_color
          }
        />
      </Box>

      <Box className="display-settings-options-accordion-content-option-wrapper">
        <Typography className="display-settings-options-subtitle">
          {CheckValueLocale("display_settings_corner_radius", "", {}, intl)}
        </Typography>
        <DisplayOptionSelect
          id="embedded-survey-display-settings-button-corner-radius-select"
          options={(
            embeddedOptions?.button_style?.corner_radius?.values?.sort(
              (a, b) => a?.id - b?.id,
            ) || []
          )?.map((option) => {
            return {
              value: option?.value,
              label: `display_settings_corner_radius_${option?.value}`,
            };
          })}
          value={selectedOptions?.corner_radius}
          onChange={(e) =>
            handleChangeOption("corner_radius", e?.target?.value)
          }
        />
      </Box>
    </Box>
  );
};

export default ButtonStyleOptions;
