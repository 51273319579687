import { useQuery } from "@tanstack/react-query";
import { useIntl } from "react-intl";
import { SingleTweetAnalysisServices } from "services/api/singleTweetAnalysisServices";

export const useSingleTweetAnalysisComments = ({ monitorID, params }) => {
  const intl = useIntl();
  const local = intl?.locale;
  return useQuery({
    queryKey: ["singleTweetAnalysisComments", monitorID, params, local],
    queryFn: () =>
      SingleTweetAnalysisServices.getSingleTweetAnalysisComments({
        monitorID,
        params,
      }),
  });
};
