import React, { useEffect, useMemo } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import {
  Box,
  Breadcrumbs,
  FormControlLabel,
  Skeleton,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/pro-regular-svg-icons";
import LucButton from "shared/lucButton/lucButton";
import "./style.scss";
import ExportButton from "./exportButton/exportButton";
import {
  sidebarGeneralOptions,
  sidebarOptionsMapper,
} from "shared/customDateRangePicker/config/sidebarOptions";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import CustomDateRangePicker from "shared/customDateRangePicker/customDateRangePicker";
import { useMemoizedFn } from "hooks/useMemoizedFn";

const CustomDashboardHeader = (props) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    isLoading,
    dashboardInfo,
    updateCustomDashboard,
    changesFlag,
    isEdit,
    applyFilter,
    startDate,
    endDate,
    isLiveData,
    handleChangeLiveData,
    hasWidgets,
    disabledLive,
    dashboardWidgets,
    loadingExport,
    dateRangePickerParamsRef,
  } = props;

  let slProduct = +localStorage?.getItem("sm_id");

  let enabledEdit =
    slProduct == dashboardInfo?.product_id
      ? handlelUserRoles("SM", "DESTROY_DASHBOARD")
        ? true
        : dashboardInfo?.dashboard_owner &&
          handlelUserRoles("SM", "EDIT_DASHBOARD")
      : handlelUserRoles("CXM", "DESTROY_DASHBOARD")
        ? true
        : dashboardInfo?.dashboard_owner &&
          handlelUserRoles("CXM", "EDIT_DASHBOARD");

  useEffect(() => {
    if (!isEdit) applyFilter(startDate, endDate);
  }, [intl.locale]);

  // memoized functions and object to avoid re-rendering in date picker component
  const newDate = useMemo(() => {
    return new Date();
  }, []);
  const buttonProps = useMemo(() => {
    return {
      type: "secondary",
      variant: "outline",
      size: "small",
      startIcon: (
        <FontAwesomeIcon icon={faCalendar} className="custom-input-icon" />
      ),
    };
  }, []);
  const applyFilterMemo = useMemoizedFn(applyFilter);

  return (
    <Box id="custom-dashboard-header">
      <Box>
        <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
          <Typography>{CheckValueLocale("utilities", "", {}, intl)}</Typography>
          <Link to="/dashboard/dashboard_list">
            {CheckValueLocale("dashboards", "", {}, intl)}
          </Link>
        </Breadcrumbs>
        {isLoading && !dashboardInfo?.name?.length ? (
          <Skeleton variant="text" animation="wave" className="title-skelton" />
        ) : (
          <Typography className="title">
            {dashboardInfo?.name || ""}
            {!isEdit && enabledEdit ? (
              <Box component={"span"}>
                <Link to={`/dashboard/custom/edit/${dashboardInfo?.id}`}>
                  <FontAwesomeIcon icon={faPenToSquare} size="small" />
                </Link>
              </Box>
            ) : null}
          </Typography>
        )}
      </Box>
      {isEdit ? (
        <Box className="editor-btns-container">
          <LucButton
            id="custom-dashboard-editor-cancel-btn"
            variant={"outline"}
            type="secondary"
            onClick={() =>
              navigate(`/dashboard/custom/view/${dashboardInfo?.id}`)
            }
          >
            {CheckValueLocale("cancel", "", {}, intl)}
          </LucButton>
          <LucButton
            id="custom-dashboard-editor-save-btn"
            loading={isLoading}
            disabled={!changesFlag}
            onClick={changesFlag ? updateCustomDashboard : null}
          >
            {CheckValueLocale("custom_dashboard_apply", "", {}, intl)}
          </LucButton>
        </Box>
      ) : (
        <Box component={"span"} className="custom-dashboard-view-btns-wrapper">
          {hasWidgets ? (
            <Tooltip
              arrow
              placement="bottom"
              title={CheckValueLocale(
                isLiveData
                  ? "custom_dashboard_live_pause_tooltip_msg"
                  : "custom_dashboard_live_run_tooltip_msg",
                "",
                { br: <br /> },
                intl,
              )}
            >
              <FormControlLabel
                id="custom-dashboard-view-live-data-switch"
                className={`live-data-switch ${isLiveData ? "active" : ""}`}
                control={
                  <Switch
                    color="success"
                    checked={isLiveData}
                    onChange={handleChangeLiveData}
                    disabled={disabledLive}
                  />
                }
                label={CheckValueLocale("custom_dashboard_live", "", {}, intl)}
              />
            </Tooltip>
          ) : null}

          {hasWidgets ? (
            <Box component={"span"} id="custom-dashboard-export-button">
              <ExportButton
                nameDashboard={dashboardInfo?.name}
                dashboardWidgets={dashboardWidgets}
                loadingExport={loadingExport}
              />
            </Box>
          ) : null}

          <Box
            component={"span"}
            id="custom-dashboard-view-date-picker"
            className={!hasWidgets ? "hidden" : ""}
          >
            <CustomDateRangePicker
              initialSidebarLabel={sidebarOptionsMapper.lastsevendays}
              startDate={startDate}
              endDate={endDate}
              applyFilter={applyFilterMemo}
              dateRangePickerParamsRef={dateRangePickerParamsRef}
              maxDate={newDate}
              sidebarOptions={sidebarGeneralOptions}
              customStyles={buttonProps}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CustomDashboardHeader;
