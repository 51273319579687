import { Box } from "@mui/material";
import "./resendCode.scss";
import { CheckValueLocale, differenceSecondsBetweenDates } from "utils/helpers";
import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import LucButton from "shared/lucButton/lucButton";

const ResendCode = ({
  handleResendCode,
  textDisplay,
  expiredDate,
  loadingBtn,
}) => {
  const intl = useIntl();
  const [timeLeft, setTimeLeft] = useState(0);
  const [resendEnabled, setResendEnabled] = useState(false);

  // Countdown timer
  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setResendEnabled(true);
    }
  }, [timeLeft]);

  useEffect(() => {
    // for resend code and new
    if (expiredDate) {
      const newTimeCount = differenceSecondsBetweenDates(
        new Date(),
        new Date(expiredDate),
      );
      setResendEnabled(false);
      setTimeLeft(newTimeCount);
    }
  }, [expiredDate]);
  return (
    <Box id="resend-code-time-btn">
      {!resendEnabled ? (
        <Box component={"span"} className="resend-code-time">
          {CheckValueLocale(textDisplay, "", {}, intl)}{" "}
          {Math?.floor(timeLeft / 60)}:{("0" + (timeLeft % 60))?.slice(-2)}{" "}
        </Box>
      ) : null}

      <LucButton
        variant={"flat"}
        size="small"
        disabled={!resendEnabled}
        onClick={handleResendCode}
        id="resend-code-btn"
        loading={loadingBtn}
      >
        {CheckValueLocale("resend_code", "", {}, intl)}
      </LucButton>
    </Box>
  );
};

export default ResendCode;
