import TopPosts from "pages/Monitors/Components/topPosts/topPosts";
import { useEffect, useReducer, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale, isEmptyObj } from "utils/helpers";
import mqttRabbitMQResponse from "services/controllers/mqttRabbitMQResponce";
import xPlatform from "images/social-icons/x-platform-white-icon.svg";
import {
  commentsViewReducer,
  singleTweetCommentsInitialState,
} from "../utils/singleTweetCommentsReducer";
import { handleCommentsViewResponse } from "../utils/handleCommentsViewResponse";
import { useSingleTweetAnalysisComments } from "../hooks/useSingleTweetComments";

const twitterIcon = (
  <span className="topics-twitter-icon">
    <img
      src={xPlatform}
      alt="x-platform-logo"
      className={"icon-after-title twitter-icon twitter-datasource-icon"}
    />
  </span>
);

export const CommentsView = ({
  startDate,
  endDate,
  setPage,
  page,
  filterParams,
  setFilterParams,
  monitorID
}) => {
  const intl = useIntl();
  const clientMQTT = useRef();
  const [response, setResponse] = useState({});
  const [preLoaderTrackerDataSources, setPreLoaderTrackerDataSources] =
    useState([]);
  const [state, dispatch] = useReducer(
    commentsViewReducer,
    singleTweetCommentsInitialState,
  );
  const params = {
    product_id: localStorage.getItem("sm_id"),
    section_name: "posts",
    start_date: startDate,
    end_date: endDate,
    page_number: page,
    filters: isEmptyObj(filterParams) ? null : JSON.stringify(filterParams),
  };
  const { data: commentsData } =
  useSingleTweetAnalysisComments({
    params,
    monitorID,
  });

  useEffect(() => {
    if (commentsData) {
      setPreLoaderTrackerDataSources([
        parseInt(commentsData?.data?.monitor_id),
      ]);
      mqttRabbitMQResponse(clientMQTT, commentsData?.data, setResponse);
    }
  }, [commentsData]);

  useEffect(() => {
    handleCommentsViewResponse(response, dispatch);
  }, [response]);

  const handlePageChange = (page) => {
    dispatch({
      type: "RESET_COMMENTS_VIEW",
    });
    setPage(page);
  };

  const handleFilterChange = (_, __, newFilterParams) => {
    dispatch({
      type: "RESET_COMMENTS_VIEW",
    });

    setFilterParams(newFilterParams);
  };

  return (
    <>
      <TopPosts
        data={state?.topPosts}
        topPostsCount={state?.topPostsCount}
        errorMessage={CheckValueLocale("no_posts_found", "", {}, intl)}
        ShowPagination
        topPostsPageHeader
        changePagination={() => {}}
        topTopPostsPreLoaderDataSources={state?.topTopPostsPreLoaderDataSources}
        preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        setPage={handlePageChange}
        page={page}
        monitorData={{}}
        setFilterParams={setFilterParams}
        filterParams={filterParams}
        applyFilter={handleFilterChange}
        startDate={startDate}
        endDate={endDate}
        iconAfterTitle={twitterIcon}
        response={response}
        hideChangeSentiment
        quickInspectClass=""
        hideTweetAnalysis={true}
        hideManageList={true}
        isSinglePost={true}
      />
    </>
  );
};
