import MonitorListPage from "pages/socialListing/MonitorList/monitorList/monitorListPage";
import React from "react";
import CreateMonitor from "pages/socialListing/MonitorsOptions/createMonitor/createMonitor";
import Benchmark from "pages/socialListing/benchmark";
import ExplorePage from "pages/socialListing/ExplorePage";
import InteractionsPage from "pages/channelAnalytics/interactionsPage/interactionsPage.js";
import GMBInteractionPage from "pages/channelAnalytics/InsightsPage/ratingPage/googleMyBusiness/Interaction-list/gmbInteractionPage";
import GenesysInteractionPage from "pages/channelAnalytics/InsightsPage/callsPage/genesys/Interaction-list/genesysInteractionsPage";
import NotFoundPage from "pages/not-found/NotFoundPage.js";

//twitter interactions Pages
import TwitterInteractionPage from "pages/channelAnalytics/InsightsPage/socialMediaPage/publicChannel/twitterChannel/Interaction-list/TwitterInteractionPage";
import SingleTwitterInteraction from "pages/channelAnalytics/InsightsPage/socialMediaPage/publicChannel/twitterChannel/Interaction-list/SingleTweetInteraction/SingleTweetInteraction.js";
//private
import TwitterInteractionPrivatePage from "pages/channelAnalytics/InsightsPage/socialMediaPage/privateChannel/twitterPrivateChannel/Interaction-list/TwitterInteractionPage";
import SingleTwitterPrivateInteraction from "pages/channelAnalytics/InsightsPage/socialMediaPage/privateChannel/twitterPrivateChannel/Interaction-list/SingleTweetInteraction/SingleTweetInteraction.js";
//whatsapp interactions Pages
import WhatsappInteractionPage from "pages/channelAnalytics/InsightsPage/chatPage/WhatsappPage/Interaction-list/WhatsAppInteractionPage";
import SingleWhatsappInteraction from "pages/channelAnalytics/InsightsPage/chatPage/WhatsappPage/Interaction-list/SingleWhatsappInteraction/SingleWhatsappInteraction.js";

//facebook interactions Pages
import FacebookInteractionPage from "pages/channelAnalytics/InsightsPage/socialMediaPage/publicChannel/facebookChannel/Interaction-list/FacebookInteractionPage";
import SingleFacebookInteraction from "pages/channelAnalytics/InsightsPage/socialMediaPage/publicChannel/facebookChannel/Interaction-list/SingleFacebookInteraction/SingleFacebookInteraction.js";
//private
import FacebookInteractionPrivatePage from "pages/channelAnalytics/InsightsPage/socialMediaPage/privateChannel/facebookPrivateChannel/Interaction-list/FacebookInteractionPage";
import SingleFacebookPrivateInteraction from "pages/channelAnalytics/InsightsPage/socialMediaPage/privateChannel/facebookPrivateChannel/Interaction-list/SingleFacebookInteraction/SingleFacebookInteraction.js";
//instagram interactions Pages
import InstagramInteractionPage from "pages/channelAnalytics/InsightsPage/socialMediaPage/publicChannel/instagramChannel/Interaction-list/InstagramInteractionPage";
import SingleInstagramInteraction from "pages/channelAnalytics/InsightsPage/socialMediaPage/publicChannel/instagramChannel/Interaction-list/SingleInstagramInteraction/SingleInstagramInteraction.js";
//private
import InstagramInteractionPrivatePage from "pages/channelAnalytics/InsightsPage/socialMediaPage/privateChannel/instagramPrivateChannel/Interaction-list/InstagramInteractionPage";
import SingleInstagramPrivateInteraction from "pages/channelAnalytics/InsightsPage/socialMediaPage/privateChannel/instagramPrivateChannel/Interaction-list/SingleInstagramInteraction/SingleInstagramInteraction.js";
//gmail interactions Pages
import GmailInteractionPage from "pages/channelAnalytics/InsightsPage/emailPage/GmailPage/Interaction-list/GmailInteractionPage";
import SingleEmailInteraction from "pages/channelAnalytics/InsightsPage/emailPage/GmailPage/Interaction-list/SingleEmailInteraction/SingleEmailInteraction.js";

//whatsapp interactions Pages
import IntercomInteractionPage from "pages/channelAnalytics/InsightsPage/chatPage/intercomPage/Interaction-list/IntercomInteractionPage";
import SingleIntercomInteraction from "pages/channelAnalytics/InsightsPage/chatPage/intercomPage/Interaction-list/SingleIntercomInteraction/SingleIntercomInteraction.js";
import SocialMediaInteractionPage from "pages/channelAnalytics/InsightsPage/socialMediaPage/SocialMediaInteractionList/SocialMediaInteractionPage";
import GMBSingleInteractionPage from "pages/channelAnalytics/InsightsPage/ratingPage/googleMyBusiness/Interaction-list/singleGmbInteraction/singleGmbInteractions";
import SingleLinkedInInteraction from "pages/channelAnalytics/InsightsPage/linkedInPage/Interaction-list/SingleLinkedInInteraction/singleLinkedInInteraction";
import LinkedInInteractionPage from "pages/channelAnalytics/InsightsPage/linkedInPage/Interaction-list/linkedInInteractionPage";
import TiktokInteractionsPage from "pages/channelAnalytics/InsightsPage/socialMediaPage/publicChannel/tikTokChannel/Interaction-list/tiktokInteractionPage";
import ChatInteractionsPage from "pages/channelAnalytics/InsightsPage/chatPage/ChatInteractionsPage/ChatInteractionsPage";
import SingleGenesysInteraction from "pages/channelAnalytics/InsightsPage/callsPage/genesys/Interaction-list/singleGenesysInteraction/singleGenesysInterations.js";
import SingleTiktokInteraction from "pages/channelAnalytics/InsightsPage/socialMediaPage/publicChannel/tikTokChannel/Interaction-list/SingleTiktokInteraction/SingleTiktokInteraction";
import ManageChannels from "pages/channelAnalytics/manageChannels/manageChannels.js";
import AddChannel from "pages/channelAnalytics/manageChannels/addChannel/addChannel.js";
// InsightsPage
import InsightsPage from "pages/channelAnalytics/InsightsPage/insightsPage.js";
import SocialMediaPage from "pages/channelAnalytics/InsightsPage/socialMediaPage/SocialPage.js";
import ChatPage from "pages/channelAnalytics/InsightsPage/chatPage/chatPage.js";
import TwitterChannel from "pages/channelAnalytics/InsightsPage/socialMediaPage/publicChannel/twitterChannel/twitterPage.js";
import TwitterPrivateChannel from "pages/channelAnalytics/InsightsPage/socialMediaPage/privateChannel/twitterPrivateChannel/twitterPrivatePage.js";
import InstagramChannel from "pages/channelAnalytics/InsightsPage/socialMediaPage/publicChannel/instagramChannel/instagramPage";
import InstagramPrivateChannel from "pages/channelAnalytics/InsightsPage/socialMediaPage/privateChannel/instagramPrivateChannel/instagramPrivatePage";
import FacebookChannel from "pages/channelAnalytics/InsightsPage/socialMediaPage/publicChannel/facebookChannel/facebookPage.js";
import FacebookPrivateChannel from "pages/channelAnalytics/InsightsPage/socialMediaPage/privateChannel/facebookPrivateChannel/facebookPrivatePage.js";
import LinkedInPage from "pages/channelAnalytics/InsightsPage/linkedInPage/linkedInPage";
import TikTokPage from "pages/channelAnalytics/InsightsPage/socialMediaPage/publicChannel/tikTokChannel/tikTokPage.js";
import WhatsAppPage from "pages/channelAnalytics/InsightsPage/chatPage/WhatsappPage/whatsAppPage.js";
import IntercomPage from "pages/channelAnalytics/InsightsPage/chatPage/intercomPage/IntercomPage.js";
import EmailPage from "pages/channelAnalytics/InsightsPage/emailPage/emailPage.js";
import GmailPage from "pages/channelAnalytics/InsightsPage/emailPage/GmailPage/gmailPage.js";
import RatingPage from "pages/channelAnalytics/InsightsPage/ratingPage/ratingPage.js";
import GoogleMyBusinessPage from "pages/channelAnalytics/InsightsPage/ratingPage/googleMyBusiness/googleMyBusinessPage";
import GenesysPage from "pages/channelAnalytics/InsightsPage/callsPage/genesys/genesysPage.js";
import CallsPage from "pages/channelAnalytics/InsightsPage/callsPage/callsPage.js";
// Accounts Lists
import AccountsLists from "pages/accountsLists";
import AddAccountsList from "pages/accountsLists/createAndEditAccountsLists/index.js";
// Reports
import ReportsList from "pages/reports/pages/list";
import CreateReport from "pages/reports/pages/create";
import ViewReport from "pages/reports/pages/view";
import EditReport from "pages/reports/pages/edit";
import ViewNewsLetter from "pages/reports/pages/viewNewsLetter/index";
// Audience interactions Pages
import Audience from "pages/audience";
import MainUserProfile from "pages/audience/userProfile/mainUserProfile";
import SurveyBody from "pages/audience/userActivites/survey/surveyBody";
import ManageAudience from "pages/audience/manageAudience";
// Survey
import SurveyMainPage from "pages/survey/surveyMainPage";
import SurveyBuilder from "pages/SurveyBuilder";
import SurveyInsights from "pages/survey/surveyInsights/surveyInsights";
// Notification
import NotificationPage from "pages/notifications/index";
// dashboard Pages
import CustomWidgetEdit from "pages/dashboard/dashboardMainPages/customDashboardPage/customWidgetEdit/customWidgetEdit";
import CustomDashboardView from "pages/dashboard/dashboardMainPages/customDashboardPage/customDashboardView";
import DashboardListPage from "pages/dashboard/dashboardList/dashboardListPage";
import CreateDashboard from "pages/dashboard/createDashboard/createDashboard";
import EditDashboard from "pages/dashboard/editDashboard";
import MainDashboardPage from "pages/dashboard/dashboardMainPages/mainDashboardPage";
// Settings Pages
import EditUser from "pages/settings/pages/userManagementPage/subPages/editNewUser/editUser";
import { ViewUser } from "pages/settings/pages/userManagementPage/subPages/viewUser/viewUser";
import { ViewTeam } from "pages/settings/pages/teamsPage/viewTeam/viewTeam";
import { EditTeam } from "pages/settings/pages/teamsPage/editTeam/editTeam";
import { AddTeam } from "pages/settings/pages/teamsPage/addTeam/addTeam";
import AddNewUser from "pages/settings/pages/userManagementPage/subPages/addNewUser";
import AuthenticationPage from "pages/settings/pages/authenticationPage";

// alert
import AlertsPage from "pages/alerts";
import AlertDetailPage from "pages/alerts/components/AlertDetailPage.js";
import DeleteAlertEngagement from "pages/engagements/components/deleteEngagementAlert/deleteAlert";

const monitorRoutes = [
  { path: "monitor_list", component: MonitorListPage },
  { path: "monitors/create_monitor", component: CreateMonitor },
  { path: "monitors/create_monitor/step_1", component: CreateMonitor },
  { path: "monitors/create_monitor/step_2", component: CreateMonitor },
  { path: "benchmark", component: Benchmark },
  { path: "benchmark/social-listening", component: Benchmark },
  { path: "benchmark/omnichannel", component: Benchmark },
  { path: "explore", component: ExplorePage },
  { path: "*", component: NotFoundPage },
];
const interactionsRoutes = [
  { path: "", component: InteractionsPage },
  {
    path: "ratings/google-my-business/:data_source_id",
    component: GMBInteractionPage,
  },
  { path: "calls/genesys/:data_source_id", component: GenesysInteractionPage },
  {
    path: "social-media/linkedin-channel/:data_source_id",
    component: LinkedInInteractionPage,
  },
  {
    path: "social-media/tikTok-channel/:data_source_id",
    component: TiktokInteractionsPage,
  },
  { path: "chat/whatsapp/:data_source_id", component: WhatsappInteractionPage },
  { path: "chat/intercom/:data_source_id", component: IntercomInteractionPage },
  {
    path: "social-media/x-platform-public/:data_source_id",
    component: TwitterInteractionPage,
  },
  {
    path: "social-media/x-platform-private/:data_source_id",
    component: TwitterInteractionPrivatePage,
  },
  {
    path: "social-media/facebook-public/:data_source_id",
    component: FacebookInteractionPage,
  },
  {
    path: "social-media/facebook-private/:data_source_id",
    component: FacebookInteractionPrivatePage,
  },
  {
    path: "social-media/instagram-public/:data_source_id",
    component: InstagramInteractionPage,
  },
  {
    path: "social-media/instagram-private/:data_source_id",
    component: InstagramInteractionPrivatePage,
  },
  { path: "email/gmail/:data_source_id", component: GmailInteractionPage },
  { path: "social-media", component: SocialMediaInteractionPage },
  { path: "chat", component: ChatInteractionsPage },
  {
    path: "ratings/google-my-business/:id/:tracker_id/:data_source_id",
    component: GMBSingleInteractionPage,
  },
  {
    path: "calls/genesys/:id/:tracker_id/:data_source_id",
    component: SingleGenesysInteraction,
  },
  {
    path: "social-media/x-platform-public/:id/:tracker_id/:data_source_id",
    component: SingleTwitterInteraction,
  },
  {
    path: "social-media/tikTok-channel/:id/:tracker_id/:data_source_id/:type",
    component: SingleTiktokInteraction,
  },
  {
    path: "social-media/x-platform-private/:id/:tracker_id/:data_source_id",
    component: SingleTwitterPrivateInteraction,
  },
  {
    path: "social-media/facebook-public/:id/:tracker_id/:data_source_id",
    component: SingleFacebookInteraction,
  },
  {
    path: "social-media/facebook-private/:id/:tracker_id/:data_source_id",
    component: SingleFacebookPrivateInteraction,
  },
  {
    path: "chat/whatsapp/:id/:tracker_id/:data_source_id",
    component: SingleWhatsappInteraction,
  },
  {
    path: "social-media/instagram-public/:id/:tracker_id/:data_source_id",
    component: SingleInstagramInteraction,
  },
  {
    path: "social-media/instagram-private/:id/:tracker_id/:data_source_id",
    component: SingleInstagramPrivateInteraction,
  },
  {
    path: "chat/intercom/:id/:tracker_id/:data_source_id",
    component: SingleIntercomInteraction,
  },
  {
    path: "email/gmail/:id/:tracker_id/:data_source_id",
    component: SingleEmailInteraction,
  },
  {
    path: "social-media/linkedin-channel/:id/:tracker_id/:data_source_id",
    component: SingleLinkedInInteraction,
  },
  { path: "*", component: NotFoundPage },
];
const manageChannelsRoutes = [
  { path: "list", component: ManageChannels },
  { path: "add-channel", component: AddChannel },
  { path: "add-channel/Social", component: AddChannel },
  { path: "add-channel/chats", component: AddChannel },
  { path: "add-channel/emails", component: AddChannel },
  { path: "add-channel/Ratings", component: AddChannel },
  { path: "add-channel/Calls", component: AddChannel },
  { path: "add-channel/bot-config", component: AddChannel },
  { path: "add-channel/session-config", component: AddChannel },
  { path: "*", component: NotFoundPage },
];
const insightsRoutes = [
  { path: "main", component: InsightsPage },
  { path: "social-media", component: SocialMediaPage },
  { path: "chat", component: ChatPage },
  {
    path: "social-media/x-platform-channel/:data_source_id",
    component: TwitterChannel,
  },
  {
    path: "social-media/x-platform-private-channel/:data_source_id",
    component: TwitterPrivateChannel,
  },
  {
    path: "social-media/instagram-channel/:data_source_id",
    component: InstagramChannel,
  },
  {
    path: "social-media/instagram-private-channel/:data_source_id",
    component: InstagramPrivateChannel,
  },
  {
    path: "social-media/facebook-channel/:data_source_id",
    component: FacebookChannel,
  },
  {
    path: "social-media/facebook-private-channel/:data_source_id",
    component: FacebookPrivateChannel,
  },
  {
    path: "social-media/linkedin-channel/:data_source_id",
    component: LinkedInPage,
  },
  {
    path: "social-media/tikTok-channel/:data_source_id",
    component: TikTokPage,
  },
  { path: "chat/whatsapp/:data_source_id", component: WhatsAppPage },

  { path: "chat/intercom/:data_source_id", component: IntercomPage },
  { path: "email", component: EmailPage },
  { path: "email/gmail/:data_source_id", component: GmailPage },
  { path: "ratings", component: RatingPage },
  {
    path: "ratings/google-my-business/:data_source_id",
    component: GoogleMyBusinessPage,
  },
  { path: "calls/genesys/:data_source_id", component: GenesysPage },
  { path: "chat/:data_source_id", component: ChatPage },
  { path: "calls", component: CallsPage },
  { path: "email/:data_source_id", component: EmailPage },

  { path: "*", component: NotFoundPage },
];
const accountListsRoutes = [
  { path: "list", component: AccountsLists },
  { path: "add-accounts-list", component: AddAccountsList },
  { path: "edit-accounts-list/:id", component: AddAccountsList },
  { path: "*", component: NotFoundPage },
];
const reportsRoutes = [
  { path: "list", component: ReportsList },
  { path: "add-report", component: CreateReport },
  { path: "add-report/information", component: CreateReport },
  { path: "add-report/setting", component: CreateReport },
  { path: "add-report/delivery", component: CreateReport },
  { path: "edit-report/:id", component: EditReport },
  { path: "view-report/:id", component: ViewReport },
  { path: "newslettersreport_details/:id", component: ViewNewsLetter },
  { path: "*", component: NotFoundPage },
];
const audienceRoutes = [
  { path: "list", component: Audience },
  { path: "user-profile/:id", component: MainUserProfile },
  { path: "survey/:profileId/:surveyId/:respondantId", component: SurveyBody },
  { path: "manage-audience", component: ManageAudience },
  { path: "*", component: NotFoundPage },
];
const surveyRoutes = [
  { path: "list", component: SurveyMainPage },
  { path: "survey-builder/:id", component: SurveyBuilder },
  { path: "add-survey/survey-builder/:id", component: SurveyBuilder },
  { path: "edit-survey/survey-builder/:id", component: SurveyBuilder },
  { path: "survey-insights/:id", component: SurveyInsights },
  { path: "*", component: NotFoundPage },
];
const notificationRoutes = [
  { path: "", component: NotificationPage },
  { path: ":id/download", component: NotificationPage },
  { path: ":id/xlsx_download", component: NotificationPage },
  { path: "*", component: NotFoundPage },
];
const dashboardRoutes = [
  { path: "dashboard_list", component: DashboardListPage },
  { path: "custom/edit/:dashboardId", component: CustomWidgetEdit },
  { path: "custom/view/:dashboardId", component: CustomDashboardView },
  { path: "create_dashboard", component: CreateDashboard },
  { path: "edit_dashboard/:dashboardId", component: EditDashboard },
  {
    path: ":monitorType/:dashboardActiveTab/:dashboardID",
    component: MainDashboardPage,
  },
  { path: "*", component: NotFoundPage },
];
const settingsRoutes = [
  {
    path: "accounts_management/company_accounts",
    component: AuthenticationPage,
  },
  { path: "accounts_management/users_accounts", component: AuthenticationPage },

  { path: "user_managements/add", component: AddNewUser },
  { path: "user_managements/view/:id", component: ViewUser },
  { path: "user_managements/edit/:id", component: EditUser },
  { path: "teams/view/:id", component: ViewTeam },
  { path: "teams/edit/:id", component: EditTeam },
  { path: "teams/add", component: AddTeam },

  // { path: "*", component: Settings },
];
const alertsRoutes = [
  { path: "", component: AlertsPage },
  { path: ":alertType/:monitorDataSource/:id", component: AlertDetailPage },
  { path: "deletedAlerts/engagement", component: DeleteAlertEngagement },
  { path: "*", component: NotFoundPage },
];
export {
  monitorRoutes,
  interactionsRoutes,
  manageChannelsRoutes,
  insightsRoutes,
  accountListsRoutes,
  reportsRoutes,
  audienceRoutes,
  surveyRoutes,
  notificationRoutes,
  dashboardRoutes,
  settingsRoutes,
  alertsRoutes,
};
