import { useEffect } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import ConfigsTab from "./configsTab/ConfigsTab";
import ContentTab from "./contentTab/ContentTab";
import "../surveyBuilder.scss";

const ControlSection = (props) => {
  const intl = useIntl();
  const {
    surveySections,
    setSurveySections,
    filteredSections,
    handleNewSection,
    handleDuplicateSection,
    handleDeleteSection,
    emptySection,
    setEmptySection,
    handleDeleteQuestion,
    setSQIndex,
    sqIndex,
    setSSIndex,
    ssIndex,
    handleSaveChanges,
    setSurveyData,
    setLogoData,
    logoData,
    surveyData,
    setEmptySurveyQuestions,
    emptySurveyQuestions,
    setOperationPerformed,
    multiRes,
    handleMultiRes,
    setSaveChangesFlag,
    scrollToIdFunc,
    proceedError,
    afterSecError,
    secTitleErr,
    handleSectionDir,
    handleAddQuestion,
    setControlTab,
    controlTab,
    handleChangeControlTab,
    isEmbedded,
  } = props;

  useEffect(() => {
    if (isEmbedded) setControlTab("content");
  }, [isEmbedded]);

  return (
    <Box className="control-section-container">
      <Box
        id="tab-style"
        className={`tab-style ${isEmbedded ? "embedded-survey" : ""}`}
      >
        <Tabs
          id={
            isEmbedded
              ? "embedded-survey-control-sidebar-tabs"
              : "survey-control-sidebar-tabs"
          }
          value={controlTab}
          onChange={handleChangeControlTab}
          aria-label="icon tabs example"
        >
          <Tab
            label={CheckValueLocale("content_tab", "", {}, intl)}
            value={"content"}
            data-testid="content"
            disabled={isEmbedded}
          />
          {!isEmbedded ? (
            <Tab
              label={CheckValueLocale("configs_tab", "", {}, intl)}
              value={"configs"}
              data-testid="configs"
            />
          ) : null}
        </Tabs>
      </Box>
      <Box className="scroll-bar-border" />
      <Box className="scroll-bar-control-section">
        {controlTab === "content" ? (
          <ContentTab
            surveySections={surveySections}
            setSurveySections={setSurveySections}
            filteredSections={filteredSections}
            handleNewSection={handleNewSection}
            handleDuplicateSection={handleDuplicateSection}
            handleDeleteSection={handleDeleteSection}
            emptySection={emptySection}
            setEmptySection={setEmptySection}
            setEmptySurveyQuestions={setEmptySurveyQuestions}
            emptySurveyQuestions={emptySurveyQuestions}
            handleDeleteQuestion={handleDeleteQuestion}
            setSQIndex={setSQIndex}
            sqIndex={sqIndex}
            setSSIndex={setSSIndex}
            ssIndex={ssIndex}
            setOperationPerformed={setOperationPerformed}
            scrollToIdFunc={scrollToIdFunc}
            proceedError={proceedError}
            afterSecError={afterSecError}
            secTitleErr={secTitleErr}
            handleSectionDir={handleSectionDir}
            handleAddQuestion={handleAddQuestion}
            isEmbedded={isEmbedded}
          />
        ) : null}

        {controlTab === "configs" && !isEmbedded ? (
          <ConfigsTab
            surveyData={surveyData}
            setSurveyData={setSurveyData}
            setLogoData={setLogoData}
            logoData={logoData}
            handleSaveChanges={handleSaveChanges}
            multiRes={multiRes}
            handleMultiRes={handleMultiRes}
            setSaveChangesFlag={setSaveChangesFlag}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default ControlSection;
