/**
 * Extract themes, sub-themes, and sentiments from an array of replies.
 *
 * @param {Array} replies - Array of post objects (each contains tweet_id, themes, sub_themes, sentiment).
 * @param {Object} options - Configuration object.
 * @param {Object} options.sentimentsOptions - Mapping from sentiment to display value or localized string.
 * @returns {Object} { themes, subthemes, sentiments }
 */
export const extractThemesAndSentiments = (
  replies = [],
  { sentimentsOptions = {}, selectedInteractions } = {},
) => {
  const themesMap = {};
  const subThemesMap = {};
  const sentimentsMap = {};

  replies.forEach((reply = {}) => {
    const { tweet_id, themes, sub_themes, sentiment } = reply;

    if (!tweet_id) return; // Safeguard in case of missing tweet_id

    // We want to check if the values is already selected by the user
    const selectedSentiment = selectedInteractions?.sentiment?.[tweet_id];
    const selectedThemes = selectedInteractions?.themes?.[tweet_id];
    const selectedSubthemes = selectedInteractions?.sub_themes?.[tweet_id];

    themesMap[tweet_id] = selectedThemes || themes || [];
    subThemesMap[tweet_id] = selectedSubthemes || sub_themes || [];

    // If sentiment exists, try to map it via sentimentsOptions;
    // otherwise, use the raw sentiment value.
    if (selectedSentiment) {
      sentimentsMap[tweet_id] = selectedSentiment;
    } else if (sentiment) {
      sentimentsMap[tweet_id] =
        sentimentsOptions[sentiment]?.toString() || sentiment;
    }
  });

  return {
    themes: themesMap,
    subthemes: subThemesMap,
    sentiments: sentimentsMap,
  };
};

/**
 * Extracts data about a specific reply’s sentiment, themes, and subthemes,
 * along with loading states for each.
 *
 * @param {Object} interactionData - Object containing {sentiment, themes, sub_themes} by replyId.
 * @param {Object} loadingData - Object containing loading states by replyId.
 * @param {string|number} replyId - Unique identifier for the reply (tweet_id).
 * @returns {Object}
 * {
 *   selectedSentiment,
 *   selectedThemes,
 *   selectedSubthemes,
 *   isSentimentLoading,
 *   isThemesLoading,
 *   isSubthemesLoading,
 * }
 */
export const extractSelectedThemesAndSentiments = (
  interactionData,
  loadingData,
  replyId,
) => {
  const selectedSentiment = interactionData?.sentiment?.[replyId];
  const selectedThemes = interactionData?.themes?.[replyId];
  const selectedSubthemes = interactionData?.sub_themes?.[replyId];

  const isSentimentLoading = loadingData?.sentiment?.[replyId];
  const isThemesLoading = loadingData?.themes?.[replyId];
  const isSubthemesLoading = loadingData?.sub_themes?.[replyId];

  return {
    selectedSentiment,
    selectedThemes,
    selectedSubthemes,
    isSentimentLoading,
    isThemesLoading,
    isSubthemesLoading,
  };
};

export const postTypes = {
  root: "root", // original post.
  reply: "reply", // normal reply to a post.
  agentResponseTarget: "agentResponseTarget", // specific comment or reply that the agent is responding to.
  agentResponse: "agentResponse", // agent's response to a reply.
  missingReply: "missingReply", // missing reply indicate the reply did't collected from the backend
  loadMoreReplies: "loadMoreReplies", // load more replies indicate the reply is a button to load more replies
};

/**
 * Handles public thread interaction data by merging current and new interactions
 * based on different request types.
 *
 * Request Types:
 * - Initial Load (NORMAL): Merges new interaction with empty current thread
 * - Pagination: Appends new thread posts to existing ones
 * - Historical: Similar to pagination, but can also apply to parent posts
 *
 * @param {Object} currentInteraction - Current interaction state
 * @param {Object} newInteraction - New interaction data to be merged
 * @param {string} currentInteractionId - Current interaction ID which is open from the list.
 * @returns {Object} Merged interaction data
 */
export const publicThreadInteractionDataHandler = (
  currentInteraction = {},
  newInteraction = {},
  currentInteractionId,
) => {
  // Extract thread posts and request type
  const {
    request_type: requestType,
    thread: newThreadPosts = [],
    root_interaction: newRootInteraction,
    error: requestError,
  } = newInteraction;

  const {
    thread: currentThreadPosts = [],
    root_interaction: currentRootInteraction,
  } = currentInteraction;

  // Determine the main interaction data (selectedCard)
  // first we will search for the current thread by tweet_id, if not found we will check the current root interaction if it has tweet_id
  // if not found we will check the new thread posts, if not found we will check the new root interaction
  // if not found we will return an empty object

  const findMainInteraction = () => {
    if (!currentInteractionId) return {};

    // Search hierarchy: current thread -> current root -> new thread -> new root
    const searchLocations = [
      () =>
        currentThreadPosts?.find(
          (post) => post?.tweet_id === currentInteractionId,
        ), // if it's a comment the current thread will have the tweet_id
      () =>
        currentRootInteraction?.tweet_id === currentInteractionId
          ? currentRootInteraction
          : null, // if it's a post then it will be in the root interaction
      () =>
        newThreadPosts?.find((post) => post?.tweet_id === currentInteractionId), // if it's a comment and its the initial load the new thread will have the tweet_id
      () =>
        newRootInteraction?.tweet_id === currentInteractionId
          ? newRootInteraction
          : null, // if it's a post and its the initial load the new root interaction will have the tweet_id
    ];

    for (const search of searchLocations) {
      const result = search();
      if (result) return result;
    }
    return {};
  };

  // Get main interaction
  const mainInteraction = findMainInteraction();

  // Use the root interaction that contains tweet_id
  const rootInteraction = currentRootInteraction?.tweet_id
    ? currentRootInteraction
    : newRootInteraction;

  // in thread public interaction, we have different cases payload to handle
  // 1- first initial load, the current thread is empty and we have new thread posts
  // 2- load more replies, we have current thread posts and new thread posts and we want to append the new thread posts to the current thread posts
  // 3- hostorical request, which similar to load more replies, but this can be applied to also the parent post.
  // 4- error in the request for any of the blew cases

  if (requestError) {
    return {
      ...currentInteraction,
      error: requestError,
    };
  }
  // Handle pagination and historical requests
  if (["PAGINATION", "HISTORICAL"].includes(requestType)) {
    return {
      ...mainInteraction,
      ...newInteraction,
      thread: [...currentThreadPosts, ...newThreadPosts],
      root_interaction: rootInteraction,
      error: null,
    };
  }
  // Handle initial load
  return {
    ...newInteraction,
    ...mainInteraction,
    error: null,
  };
};

export const historicalRequestSnackbars = {
  MISSING_DATA_FROM_TWITTER: {
    message: "public_thread_missing_data_from_twitter_message",
  },
  QUOTA_EXCEEDED: {
    title: "public_thread_quota_exceeded",
    message: "public_thread_quota_exceeded_message",
  },
  RATE_LIMIT_EXCEEDED: {
    title: "public_thread_rate_limit_exceeded",
    message: "public_thread_rate_limit_exceeded_message",
  },
  NOT_AUTHORIZED: {
    message: "public_thread_not_authorized_message",
  },
  NoAvailableAccount: {
    title: "public_thread_need_reply_account",
    message: "public_thread_need_reply_account_message",
  },
  SOMETHING_WENT_WRONG: {
    message: "try_again_error_message",
    title: "failed_error_message",
  },
};
