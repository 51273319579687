import "./style.scss";
import InboundedRoutingHeader from "./inboundedRoutingList/inboundedRoutingHeader";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { useEffect, useState } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import OptionsDropDown from "./components/optionsDropdown";
import { ReactComponent as EmptyRoutingIcon } from "images/shared-images/no-routing-found.svg";
import OrderedTable from "./components/orderedTable/orderedTable";
import TruncatedTooltip from "shared/truncatedTooltip/truncatedTooltip";
import { useInboundRoutingsList } from "./hooks/useInboundRoutingsList";
import { useUpdateRoutingPriority } from "./hooks/useUpdateRoutingPriority";
import { useDeleteInboundRouting } from "./hooks/useDeleteInboundRouting";
import SnackBar from "components/snackBar";
import CircularLoading from "components/circularProgress";
import PopupModal from "components/popupModal";
import DOMPurify from "dompurify";
import { useNavigate, useLocation } from "react-router";
import { SLATimeStringify } from "./sharedLogic/inboundRouteAdapter";

const InboundedRouting = () => {
  const [tableBody, setTableBody] = useState([]);
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const [routingsSnackBar, setroutingsSnackBar] = useState({
    message: "",
    severity: "",
    title: "",
    openSnackBar: false,
  });
  const [deletModalState, setDeleteModalState] = useState({
    routingID: null,
    openDeleteModal: false,
    routingName: "",
    aiAgentNames: null,
  });
  const tableHeader = [
    {
      width: "6.66%",
    },
    {
      body: (
        <CellWithTooltip
          title="routing_priority"
          desc="routing_priority_tooltip"
        />
      ),
      wdith: "8.45%",
    },
    {
      body: CheckValueLocale("routing_table_name", "", {}, intl),
      width: "15.02%",
    },
    {
      body: CheckValueLocale("routing_table_team", "", {}, intl),
      width: "15.02%",
    },
    {
      body: (
        <CellWithTooltip
          title="routing_table_sla"
          desc="routing_table_sla_tooltip"
        />
      ),
      width: "25.60%",
    },
    {
      body: CheckValueLocale("description", "", {}, intl),
      width: "22.89%",
    },
    {
      width: "6.36%",
    },
  ];

  // ===============================================================
  // deleting routing logic

  const { mutate: deleteRouting, isPending: isDeleteLoading } =
    useDeleteInboundRouting();

  const handleCloseDeleteModal = () => {
    if (isDeleteLoading) return;
    setDeleteModalState({
      openDeleteModal: false,
    });
  };

  const selectedTeamHasAiAgents = deletModalState?.aiAgentNames?.length > 0;

  const handleDeleteRouting = (routing) => {
    setDeleteModalState({
      routingID: routing?.id,
      routingName: routing?.name,
      openDeleteModal: true,
      aiAgentNames: routing?.used_by_ai_agent_name,
    });
  };

  const deleteInboundRouting = () => {
    if (selectedTeamHasAiAgents) {
      handleCloseDeleteModal();
    } else {
      deleteRouting(
        { id: deletModalState?.routingID },
        {
          onSuccess: () =>
            setroutingsSnackBar({
              message: "routing_deleted_successfully",
              severity: "success",
              openSnackBar: true,
            }),
          onError: (error) => {
            const isConnectedToAIAgent =
              error?.errorMsg?.response?.data?.error?.detail ===
              "used_inbound_routing_by_ai_agent";

            setroutingsSnackBar({
              message: isConnectedToAIAgent
                ? "delete_route_connected_to_ai_agent_desc"
                : "routing_deleted_failed_body",
              severity: "error",
              title: isConnectedToAIAgent
                ? "delete_route_connected_to_ai_agent_title"
                : "routing_deleted_failed_title",
              openSnackBar: true,
            });
          },
          onSettled: () => handleCloseDeleteModal(),
        },
      );
    }
  };

  // ===============================================================
  // updating routing priority logic

  const handleDragEnd = (result) => {
    if (!result?.destination) return;
    // if the source and destination are the same, then do nothing
    if (result?.source?.index === result?.destination?.index) return;
    const reorderedRows = Array.from(routings);
    const [reorderedItem] = reorderedRows?.splice(result?.source?.index, 1);
    reorderedRows.splice(result?.destination?.index, 0, reorderedItem);
    updateRoutingPriority(reorderedItem?.id, result?.destination?.index + 1);
  };

  const { mutate: updatePriority, isPending: isUpdatePriorityLoading } =
    useUpdateRoutingPriority();

  const updateRoutingPriority = (id, priority) => {
    updatePriority(
      { id, queryData: { priority } },
      {
        onError: () =>
          setroutingsSnackBar({
            message: "try_again_error_message",
            severity: "error",
            title: "failed_error_message",
            openSnackBar: true,
          }),
      },
    );
  };

  // ===============================================================
  // getting and initializing routings
  // const getInboundRoutings = (isUpdatePriorityLoading = false) => {
  //   if (!isUpdatePriorityLoading) setIsLoadngInboundRoutings(true);

  //   EngagementsController.getInboundRoutings().then((res) => {
  //     if (!res.errorMsg) {
  //       const routings = res?.data?.data?.map((routing) => routing?.attributes);
  //       setRoutings(routings);
  //     } else {
  //       setroutingsSnackBar({
  //         message: "try_again_error_message",
  //         severity: "error",
  //         title: "failed_error_message",
  //         openSnackBar: true,
  //       });
  //     }
  //     setIsLoadngInboundRoutings(false);
  //   });
  // };
  const handleCloseSnackBar = () => {
    setroutingsSnackBar({
      openSnackBar: false,
    });
  };

  const {
    data: routings,
    isPending: isLoadingInboundRoutings,
    isError: isErrorInboundRoutings,
  } = useInboundRoutingsList(intl);

  useEffect(() => {
    if (isErrorInboundRoutings) {
      setroutingsSnackBar({
        message: "try_again_error_message",
        severity: "error",
        title: "failed_error_message",
        openSnackBar: true,
      });
    }
  }, [isErrorInboundRoutings]);

  const routingTeamCell = (team) => {
    if (!team?.name) return "—";
    return (
      <TruncatedTooltip
        title={team?.name}
        maxChars={12}
        className="routing-table-description"
        placement="bottom"
      />
    );
  };

  const routingSLACell = (sla) => {
    if (!sla?.name) return "—";
    return (
      <Box className="routing-table-sla">
        <TruncatedTooltip
          title={sla?.name}
          maxChars={22}
          className="routing-table-sla-name"
          placement="bottom"
        />
        <Typography className="routing-table-sla-values">
          {SLATimeStringify(sla)}
        </Typography>
      </Box>
    );
  };

  useEffect(() => {
    const tabBody = routings?.map?.((routing) => {
      return {
        id: routing?.id,
        data: [
          {
            body: (
              <Typography className="routing-table-priority-icon">
                <DragIndicatorIcon />
              </Typography>
            ),
            draggable: true,
          },
          {
            body: (
              <Typography className="routing-table-priority">
                {routing?.priority}
              </Typography>
            ),
          },
          {
            body: (
              <TruncatedTooltip
                title={routing?.name}
                maxChars={13}
                className="routing-table-description"
                placement="bottom"
              />
            ),
          },
          {
            body: routingTeamCell(routing?.team?.data?.attributes),
          },
          {
            body: routingSLACell(routing?.sla?.data?.attributes),
          },
          {
            body: (
              <TruncatedTooltip
                title={routing?.description}
                maxChars={20}
                className="routing-table-description"
                tooltipClassName="routing-cell-with-tooltip-desc"
                placement="bottom"
              />
            ),
          },
          {
            body: (
              <OptionsDropDown
                openDeleteModal={handleDeleteRouting}
                routing={routing}
              />
            ),
          },
        ],
      };
    });
    setTableBody(tabBody);
  }, [routings, intl?.locale]);

  useEffect(() => {
    // getInboundRoutings();
    // if the search has a added_successfully message status,
    // then show a snackbar with the message added_successfully
    const search = location?.search;
    if (search) {
      let message, title;
      if (search?.includes("status=added_successfully")) {
        message = "routing_added_successfully_snackbar";
      } else if (search?.includes("status=updated_successfully")) {
        message = "routing_updated_successfully_snackbar";
        title = "routing_title_updated_successfully_snackbar";
      }
      if (message) {
        setroutingsSnackBar({
          message,
          title,
          severity: "success",
          openSnackBar: true,
        });
      }
      navigate(window.location.pathname);
    }
  }, []);
  if (isLoadingInboundRoutings) return <CircularLoading />;

  return (
    <Box className="inbound-routing-container">
      <InboundedRoutingHeader />
      <OrderedTable
        isSharedStyle
        tableHeader={tableHeader}
        tableBody={tableBody}
        handleDragEnd={handleDragEnd}
        emptyBody={<EmptyRoutingCase />}
        loading={isUpdatePriorityLoading}
      />
      {deletModalState?.openDeleteModal ? (
        <PopupModal
          title={CheckValueLocale(
            selectedTeamHasAiAgents
              ? "delete_inbound_routing"
              : "delete_routing_modal_title",
            "",
            {
              routing: deletModalState?.routingName,
            },
            intl,
          )}
          body={
            <>
              {selectedTeamHasAiAgents ? (
                <>
                  <Box>
                    {CheckValueLocale(
                      "delete_routing_connected_ai_agent_message",
                      "",
                      {
                        routeName: deletModalState?.routingName,
                        aiAgentName: deletModalState?.aiAgentNames
                          ?.map((name) => `[${name}]`)
                          .join(", "),
                      },
                      intl,
                    )}
                  </Box>
                  <h2 className="delete-team-with-ai-agents-confirm">
                    {CheckValueLocale(
                      "delete_routing_with_ai_agents_confirmation",
                      "",
                      {},
                      intl,
                    )}
                  </h2>
                </>
              ) : (
                <Box
                  className="delete-routing-modal-warn-message"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      CheckValueLocale(
                        "delete_routing_modal_body",
                        "",
                        {},
                        intl,
                      ),
                    ),
                  }}
                />
              )}
            </>
          }
          open={deletModalState?.openDeleteModal}
          leftBtn={CheckValueLocale("cancel", "", {}, intl)}
          rightBtn={CheckValueLocale(
            selectedTeamHasAiAgents ? "okay" : "delete",
            "",
            {},
            intl,
          )}
          close={handleCloseDeleteModal}
          accept={() => deleteInboundRouting()}
          warning={!selectedTeamHasAiAgents}
          classeName="inbound-routing-delete-modal delete-user-modal"
          disabled={isDeleteLoading}
          isRightBtnLoading={isDeleteLoading}
        />
      ) : null}
      <SnackBar
        open={routingsSnackBar?.openSnackBar}
        severity={routingsSnackBar?.severity}
        message={
          <Box className="inbound-routing-snackbar-message">
            {CheckValueLocale(routingsSnackBar?.message, "", {}, intl)}
          </Box>
        }
        title={CheckValueLocale(routingsSnackBar?.title, "", {}, intl)}
        handleClose={handleCloseSnackBar}
      />
    </Box>
  );
};

const EmptyRoutingCase = () => {
  const intl = useIntl();
  return (
    <Box className="empty-routing-case">
      <EmptyRoutingIcon className="empty-routing-icon" />
      <Typography className="empty-routing-text">
        {CheckValueLocale("no_routings_found", "", {}, intl)}
      </Typography>
      <Typography className="empty-routing-subtext">
        {CheckValueLocale("no_routings_found_subtext", "", {}, intl)}
      </Typography>
    </Box>
  );
};

const CellWithTooltip = ({ title, desc }) => {
  const intl = useIntl();

  return (
    <Box>
      <Tooltip
        title={CheckValueLocale(desc, "", {}, intl)}
        arrow
        placement="bottom"
        classes={{ tooltip: "routing-cell-with-tooltip-desc" }}
      >
        <Box className="routing-cell-with-tooltip-title">
          {CheckValueLocale(title, "", {}, intl)}
        </Box>
      </Tooltip>
    </Box>
  );
};

export default InboundedRouting;
