import { Box, Grid } from "@mui/material";
import PieChartWidget from "components/widgets/pieChartWidget";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import PunchCardWidget from "components/widgets/punchCardWidget";
import StackedLineWidget from "components/widgets/stackedLineWidget.js";
import StatisticsWidget from "components/widgets/statisticsWidget/statisticsWidget";
import {
  commasAfterDigit,
  handleMinMaxValues,
  getSortedArray,
} from "utils/helpers";
import {
  faMessage,
  faComments,
  faGlobe,
  faReply,
  faCircleCheck,
  faClock,
} from "@fortawesome/pro-solid-svg-icons";
import Header from "../header";
import AvgStatswWidget from "components/widgets/avgStatsWidget/avgStatsWidget";
import _ from "lodash";
import CircularLoading from "components/circularProgress";
import CompaniesController from "services/controllers/companiesController";
import {
  handleAggregatePieChart,
  handleAggregatePunchCardCharts,
  handleAggregateLegends,
  handleAggregateLineChartMultipleValues,
} from "utils/helpers/aggregateFunctions";
import {
  handleAvgSLAs,
  getHandleSortedArray,
  orderObjectByArray,
  mergeHandleAvgTime,
  calcAverage,
} from "../../sharedFunctions/aggregateFunctions";
import {
  displayRangeDate,
  displayRemainingTime,
  renderRemainingTime,
} from "../../sharedFunctions/datesUtils";
import { checkIsDataFecthing } from "pages/channelAnalytics/InsightsPage/insightsCommonFunctions";
import EngagementsController from "services/controllers/engagementsController";
const COLORS_ARR = ["#304D7D", "#6CADE9"];

const InboxTab = ({
  response,
  selectedTab,
  preLoaderTrackerDataSources,
  isLoading,
  resetAllFields,
  currDate,
  startDate,
  endDate,
  hasAppliedTimeFilter,
}) => {
  const [teams, setTeams] = useState([]);
  const [routings, setRoutings] = useState([]);
  const [isLoadingTeams, setIsLoadingTeams] = useState(0);
  const [isLoadingRoutings, setIsLoadingRoutings] = useState(0);
  const intl = useIntl();
  // avg sla
  const [avgStatsData, setAvgStatsData] = useState([]);
  const [avgStatsDataPreLoader, setAvgStatsDataPreLoader] = useState([]);
  const [avgStatsPrevDates, setAvgStatsPrevDates] = useState({
    prevStartDate: 0,
    prevEndDate: 0,
  });
  const prevDate = displayRangeDate(
    avgStatsPrevDates?.prevStartDate,
    avgStatsPrevDates?.prevEndDate,
  );
  // routings distribution
  const [routingsDistribution, setRoutingsDistribution] = useState([]);
  const [routingsDistributionPreLoader, setRoutingsDistributionPreLoader] =
    useState([]);
  const [routingsDistributionCopy, setRoutingsDistributionCopy] = useState([]);
  const [routingsDistributionXlxs, setRoutingsDistributionXlxs] = useState([]);
  // data sources
  const [dataSources, setDataSources] = useState([]);
  const [dataSourcesPreLoader, setDataSourcesPreLoader] = useState([]);
  const [dataSourcesCopy, setDataSourcesCopy] = useState([]);
  const [dataSourcesXlxs, setDataSourcesXlxs] = useState([]);
  // completion reasons
  const [completionReasons, setCompletionReasons] = useState([]);
  const [completionReasonsPreLoader, setCompletionReasonsPreLoader] = useState(
    [],
  );
  const [completionReasonsCopy, setCompletionReasonsCopy] = useState([]);
  const [completionReasonsXlxs, setCompletionReasonsXlxs] = useState([]);
  // completed by teams
  const [completedByTeams, setCompletedByTeams] = useState([]);
  const [completedByTeamsPreLoader, setCompletedByTeamsPreLoader] = useState(
    [],
  );
  // enagegement tags
  const [tagsPerformance, setTagsPerformance] = useState([]);
  const [tagsPerformancePreLoader, setTagsPerformancePreLoader] = useState([]);
  const [tagsPerformanceCopy, setTagsPerformanceCopy] = useState([]);
  const [tagsPerformanceXlxs, setTagsPerformanceXlxs] = useState([]);
  const [tagsUsageOvertime, setTagsUsageOvertime] = useState([]);
  const [tagsUsageOvertimePreLoader, setTagsUsageOvertimePreLoader] = useState(
    [],
  );
  const [tagsUsageOvertimePieChart, setTagsUsageOvertimePieChart] = useState(
    [],
  );

  const [completedByTeamsCopy, setCompletedByTeamsCopy] = useState([]);
  const [completedByTeamsXlxs, setCompletedByTeamsXlxs] = useState([]);
  // excel State
  const [checkAllDataFetchingExport, setCheckAllDataFetchingExport] =
    useState(false);
  const [excelSheetData, setExcelSheetData] = useState([]);
  //engagement volume
  const [engagVolume, setEngagVolume] = useState([]);
  const [engagPieChart, setEngagPieChart] = useState([]);
  const [engagVolumePreLoader, setEngagVolumePreLoader] = useState([]);

  const [engagOverView, setEngagOverView] = useState({});
  const [engagOverViewPreLoader, setEngagOverViewPreLoader] = useState([]);

  const [agentsList, setAgentsList] = useState([]);
  const [agentsPerformancePage, setAgentsPerformancePage] = useState(1);
  const [agentsPerformance, setAgentsPerformance] = useState([]);
  const [agentsPerformanceLoading, setAgentsPerformanceLoading] = useState(0);
  const [agentsPerformancePreLoader, setAgentsPerformancePreLoader] = useState(
    [],
  );
  const [agentsPerformanceCount, setAgentsPerformanceCount] = useState(0);

  const [engagersActivity, setEngagersActivity] = useState([]);
  const [
    engagersActivityPreLoaderDataSources,
    setEngagersActivityPreLoaderDataSources,
  ] = useState([]);
  const [minValueEngagersActivity, setMinValueEngagersActivity] = useState(0);
  const [maxValueEngagersActivity, setMaxValueEngagersActivity] = useState(0);

  // enagegement completed volume
  const [engagCompletedVolume, setEngagCompletedVolume] = useState([]);
  const [engagCompletedPieChart, setEngagCompletedPieChart] = useState([]);
  const [engagCompletedVolumePreLoader, setEngagCompletedVolumePreLoader] =
    useState([]);
  // Handle Avg Time
  const [handleAvgTime, setHandleAvgTime] = useState({});

  let typesSorted = ["direct_message", "comments_mentions"],
    sortedObj = {
      direct_message: [],
      comments_mentions: [],
    };
  const handleLoadingInitialData = () => {
    CompaniesController.getAllCompanyTeams().then((res) => {
      if (!res?.errorMsg && res?.data?.teams?.length) {
        setTeams(res?.data?.teams);
      }

      setIsLoadingTeams(1);
    });
    CompaniesController.getAllCompanyRoutings(
      window?.localStorage?.engagements_id,
    ).then((res) => {
      if (!res?.errorMsg && res?.data?.data?.length) {
        setRoutings(res?.data?.data);
      }
      setIsLoadingRoutings(1);
    });
    EngagementsController.getUsers(window?.localStorage?.engagements_id).then(
      (res) => {
        if (!res?.errorMsg && res?.data?.data?.length) {
          setAgentsList([...res?.data?.data]);
        }
        setAgentsPerformanceLoading(1);
      },
    );
  };

  useEffect(() => {
    switch (response?.eventName) {
      case "Facebook__EngagementAnalyticsV2Page__average_slas":
      case "Twitter__EngagementAnalyticsV2Page__average_slas":
      case "Instagram__EngagementAnalyticsV2Page__average_slas":
      case "Facebookprivate__EngagementAnalyticsV2Page__average_slas":
      case "Instagramprivate__EngagementAnalyticsV2Page__average_slas":
      case "Twitterprivate__EngagementAnalyticsV2Page__average_slas":
      case "Whatsappprivate__EngagementAnalyticsV2Page__average_slas":
        const aggAvgStatsData = handleAvgSLAs(
          avgStatsData,
          response?.eventData?.data,
        );
        const prevStartData =
          response?.eventData?.data?.previous_period?.previous_start_date ||
          avgStatsPrevDates?.prevStartDate;
        const prevEndData =
          response?.eventData?.data?.previous_period?.previous_end_date ||
          avgStatsPrevDates?.prevEndDate;
        setAvgStatsData(aggAvgStatsData);
        setAvgStatsPrevDates({
          prevStartDate: prevStartData,
          prevEndDate: prevEndData,
        });
        setAvgStatsDataPreLoader((prev) => [...prev, response?.data_source]);
        break;
      case "Twitter__EngagementAnalyticsV2Page__engager_activity":
      case "Facebook__EngagementAnalyticsV2Page__engager_activity":
      case "Twitterprivate__EngagementAnalyticsV2Page__engager_activity":
      case "Facebookprivate__EngagementAnalyticsV2Page__engager_activity":
      case "Instagram__EngagementAnalyticsV2Page__engager_activity":
      case "Instagramprivate__EngagementAnalyticsV2Page__engager_activity":
      case "Whatsappprivate__EngagementAnalyticsV2Page__engager_activity":
        handleAggregatePunchCardCharts(
          response?.eventData?.engager_activity,
          engagersActivity,
        );
        handleMinMaxValues(
          response?.eventData?.max_value,
          undefined,
          setMaxValueEngagersActivity,
          maxValueEngagersActivity,
          undefined,
          "max",
        );
        handleMinMaxValues(
          response?.eventData?.min_value,
          undefined,
          setMinValueEngagersActivity,
          minValueEngagersActivity,
          undefined,
          "min",
        );
        setEngagersActivityPreLoaderDataSources([
          ...engagersActivityPreLoaderDataSources,
          response?.data_source,
        ]);
        break;
      case "Twitter__EngagementAnalyticsV2Page__agents_performance":
      case "Facebook__EngagementAnalyticsV2Page__agents_performance":
      case "Twitterprivate__EngagementAnalyticsV2Page__agents_performance":
      case "Facebookprivate__EngagementAnalyticsV2Page__agents_performance":
      case "Instagram__EngagementAnalyticsV2Page__agents_performance":
      case "Instagramprivate__EngagementAnalyticsV2Page__agents_performance":
      case "Whatsappprivate__EngagementAnalyticsV2Page__agents_performance":
        let dataArr = [...agentsPerformance];
        let responseData = [...response?.eventData];
        responseData?.map((newItem) => {
          let exist = false;
          let existInbox = null;
          agentsPerformance?.map((item, index) => {
            if (+newItem?.agent_id == +item?.id) {
              existInbox = index;
              exist = true;
            }
          });
          if (exist) {
            dataArr[existInbox] = {
              ...dataArr[existInbox],
              assignedValue:
                +dataArr[existInbox]?.assignedValue + newItem?.assigned,
              completedValue:
                +dataArr[existInbox]?.completedValue + newItem?.completed,
            };
          } else {
            dataArr.push({
              id: +newItem?.agent_id,
              data: "",
              active: false,
              name: "",
              assignedValue: +newItem?.assigned,
              completedValue: +newItem?.completed,
            });
          }
        });
        setAgentsPerformance([...dataArr]);
        setAgentsPerformanceCount(dataArr?.length);
        setAgentsPerformancePreLoader([
          ...agentsPerformancePreLoader,
          response?.data_source,
        ]);
        break;
      case "Twitter__EngagementAnalyticsV2Page__overview":
      case "Facebook__EngagementAnalyticsV2Page__overview":
      case "Twitterprivate__EngagementAnalyticsV2Page__overview":
      case "Facebookprivate__EngagementAnalyticsV2Page__overview":
      case "Instagram__EngagementAnalyticsV2Page__overview":
      case "Instagramprivate__EngagementAnalyticsV2Page__overview":
      case "Whatsappprivate__EngagementAnalyticsV2Page__overview":
        setEngagOverView({
          total_engag:
            (+engagOverView?.total_engag || 0) +
            (+response?.eventData?.overview_result?.total_engagements || 0),
          total_dms: response?.data_source?.includes("private")
            ? (+engagOverView?.total_dms || 0) +
              (+response?.eventData?.overview_result?.total_engagements || 0)
            : +engagOverView?.total_dms || 0,
          total_posts: !response?.data_source?.includes("private")
            ? (+engagOverView?.total_posts || 0) +
              (+response?.eventData?.overview_result?.total_engagements || 0)
            : +engagOverView?.total_posts || 0,
          total_replied:
            (+engagOverView?.total_replied || 0) +
            (+response?.eventData?.overview_result?.total_replied || 0),
          total_completed:
            (+engagOverView?.total_completed || 0) +
            (+response?.eventData?.overview_result?.total_completed || 0),
        });
        const handlingTime =
          response?.eventData?.overview_result?.handling_time;
        const avgCount =
          (+handlingTime?.count || 0) + (+handleAvgTime?.avgCount || 0);
        const avgValue =
          (+handlingTime?.value || 0) + (+handleAvgTime?.avgValue || 0);
        const totalAvgTime = calcAverage(avgValue, avgCount);
        setHandleAvgTime({
          avgCount,
          avgValue,
          totalAvgTime,
        });
        setEngagOverViewPreLoader([
          ...engagOverViewPreLoader,
          response?.data_source,
        ]);
        break;
      case "Facebookprivate__EngagementAnalyticsV2Page__engagement_distribution_over_routings":
      case "Twitter__EngagementAnalyticsV2Page__engagement_distribution_over_routings":
      case "Twitterprivate__EngagementAnalyticsV2Page__engagement_distribution_over_routings":
      case "Facebook__EngagementAnalyticsV2Page__engagement_distribution_over_routings":
      case "Instagram__EngagementAnalyticsV2Page__engagement_distribution_over_routings":
      case "Instagramprivate__EngagementAnalyticsV2Page__engagement_distribution_over_routings":
      case "Whatsappprivate__EngagementAnalyticsV2Page__engagement_distribution_over_routings":
        const newRoutings =
          response?.eventData?.data?.routings?.map?.((item) => {
            return {
              name: item?.routing_id,
              value: item?.value,
            };
          }) || [];
        handleAggregatePieChart(newRoutings, routingsDistributionCopy);
        setRoutingsDistributionPreLoader((prev) => [
          ...prev,
          response?.data_source,
        ]);
        break;

      case "Facebookprivate__EngagementAnalyticsV2Page__datasource_and_account_distribution":
      case "Twitter__EngagementAnalyticsV2Page__datasource_and_account_distribution":
      case "Twitterprivate__EngagementAnalyticsV2Page__datasource_and_account_distribution":
      case "Facebook__EngagementAnalyticsV2Page__datasource_and_account_distribution":
      case "Instagram__EngagementAnalyticsV2Page__datasource_and_account_distribution":
      case "Instagramprivate__EngagementAnalyticsV2Page__datasource_and_account_distribution":
      case "Facebook__EngagementAnalyticsV2Page__datasource_and_account_distribution":
      case "Whatsappprivate__EngagementAnalyticsV2Page__datasource_and_account_distribution":
        handleAggregatePieChart(
          response?.eventData?.data?.map((item) => {
            return {
              name: "engag_" + item?.name,
              value: item?.value,
            };
          }) || [],
          dataSourcesCopy,
        );
        setDataSourcesPreLoader((prev) => [...prev, response?.data_source]);
        break;

      case "Facebookprivate__EngagementAnalyticsV2Page__completion_reason":
      case "Twitter__EngagementAnalyticsV2Page__completion_reason":
      case "Twitterprivate__EngagementAnalyticsV2Page__completion_reason":
      case "Facebook__EngagementAnalyticsV2Page__completion_reason":
      case "Instagram__EngagementAnalyticsV2Page__completion_reason":
      case "Instagramprivate__EngagementAnalyticsV2Page__completion_reason":
      case "Whatsappprivate__EngagementAnalyticsV2Page__completion_reason":
        handleAggregatePieChart(
          response?.eventData?.data || [],
          completionReasonsCopy,
        );
        setCompletionReasonsPreLoader((prev) => [
          ...prev,
          response?.data_source,
        ]);
        break;
      case "Facebookprivate__EngagementAnalyticsV2Page__tags_performance":
      case "Twitter__EngagementAnalyticsV2Page__tags_performance":
      case "Twitterprivate__EngagementAnalyticsV2Page__tags_performance":
      case "Facebook__EngagementAnalyticsV2Page__tags_performance":
      case "Instagram__EngagementAnalyticsV2Page__tags_performance":
      case "Instagramprivate__EngagementAnalyticsV2Page__tags_performance":
      case "Whatsappprivate__EngagementAnalyticsV2Page__tags_performance":
        const newTagsDistribution =
          response?.eventData?.tags_performance_result?.map((item) => {
            return {
              name: item?.label,
              value: item?.value,
            };
          }) || [];
        handleAggregatePieChart(newTagsDistribution, tagsPerformanceCopy);
        setTagsPerformancePreLoader((prev) => [...prev, response?.data_source]);
        break;
      case "Twitter__EngagementAnalyticsV2Page__engagements_completed_by_teams":
      case "Twitterprivate__EngagementAnalyticsV2Page__engagements_completed_by_teams":
      case "Facebook__EngagementAnalyticsV2Page__engagements_completed_by_teams":
      case "Facebookprivate__EngagementAnalyticsV2Page__engagements_completed_by_teams":
      case "Instagram__EngagementAnalyticsV2Page__engagements_completed_by_teams":
      case "Instagramprivate__EngagementAnalyticsV2Page__engagements_completed_by_teams":
      case "Whatsappprivate__EngagementAnalyticsV2Page__engagements_completed_by_teams":
        const newByTeams =
          response?.eventData?.data?.map((item) => {
            return {
              name: item?.team_id,
              value: item?.value,
            };
          }) || [];
        handleAggregatePieChart(newByTeams, completedByTeamsCopy);
        setCompletedByTeamsPreLoader((prev) => [
          ...prev,
          response?.data_source,
        ]);
        break;
      case "Twitter__EngagementAnalyticsV2Page__engagements_overtime":
      case "Twitterprivate__EngagementAnalyticsV2Page__engagements_overtime":
      case "Facebook__EngagementAnalyticsV2Page__engagements_overtime":
      case "Facebookprivate__EngagementAnalyticsV2Page__engagements_overtime":
      case "Instagram__EngagementAnalyticsV2Page__engagements_overtime":
      case "Instagramprivate__EngagementAnalyticsV2Page__engagements_overtime":
      case "Whatsappprivate__EngagementAnalyticsV2Page__engagements_overtime":
        handleAggregateLegends(
          response?.eventData?.data?.engagements_overtime_legends,
          engagPieChart,
        );
        let objDataOvertime = response?.eventData?.data?.posts_over_time;
        let newResponseOvertime = {
          ...sortedObj,
          ...objDataOvertime,
        };
        handleAggregateLineChartMultipleValues(
          newResponseOvertime,
          engagVolume,
          setEngagVolume,
        );

        setEngagVolumePreLoader((oldArray) => [
          ...oldArray,
          response?.data_source,
        ]);
        break;

      case "Twitter__EngagementAnalyticsV2Page__engagement_tags_usage_overtime":
      case "Twitterprivate__EngagementAnalyticsV2Page__engagement_tags_usage_overtime":
      case "Facebook__EngagementAnalyticsV2Page__engagement_tags_usage_overtime":
      case "Facebookprivate__EngagementAnalyticsV2Page__engagement_tags_usage_overtime":
      case "Instagram__EngagementAnalyticsV2Page__engagement_tags_usage_overtime":
      case "Instagramprivate__EngagementAnalyticsV2Page__engagement_tags_usage_overtime":
      case "Whatsappprivate__EngagementAnalyticsV2Page__engagement_tags_usage_overtime":
        handleAggregateLegends(
          response?.eventData?.data?.engagements_overtime_legends,
          tagsUsageOvertimePieChart,
        );
        let objDataTagsUsage = response?.eventData?.data?.posts_over_time;
        let newResponseTagsUsage = {
          ...sortedObj,
          ...objDataTagsUsage,
        };
        handleAggregateLineChartMultipleValues(
          newResponseTagsUsage,
          tagsUsageOvertime,
          setTagsUsageOvertime,
        );

        setTagsUsageOvertimePreLoader((oldArray) => [
          ...oldArray,
          response?.data_source,
        ]);
        break;

      case "Twitter__EngagementAnalyticsV2Page__completed_engagements_overtime":
      case "Twitterprivate__EngagementAnalyticsV2Page__completed_engagements_overtime":
      case "Facebook__EngagementAnalyticsV2Page__completed_engagements_overtime":
      case "Facebookprivate__EngagementAnalyticsV2Page__completed_engagements_overtime":
      case "Instagram__EngagementAnalyticsV2Page__completed_engagements_overtime":
      case "Instagramprivate__EngagementAnalyticsV2Page__completed_engagements_overtime":
      case "Whatsappprivate__EngagementAnalyticsV2Page__completed_engagements_overtime":
        handleAggregateLegends(
          response?.eventData?.data?.engagements_overtime_legends,
          engagCompletedPieChart,
        );

        let objDataCompleted = response?.eventData?.data?.posts_over_time;
        let newResponseCompleted = {
          ...sortedObj,
          ...objDataCompleted,
        };
        handleAggregateLineChartMultipleValues(
          newResponseCompleted,
          engagCompletedVolume,
          setEngagCompletedVolume,
        );

        setEngagCompletedVolumePreLoader((oldArray) => [
          ...oldArray,
          response?.data_source,
        ]);
        break;

      default:
        break;
    }
  }, [response]);

  useEffect(() => {
    if (agentsPerformancePage > 1) {
      setAgentsPerformancePreLoader([1]);
    }
  }, [agentsPerformancePage]);

  useEffect(() => {
    handleLoadingInitialData();
  }, []);

  useEffect(() => {
    if (resetAllFields !== 1) {
      setAvgStatsData([]);
      setAvgStatsDataPreLoader([]);
      setAvgStatsPrevDates({
        prevStartDate: 0,
        prevEndDate: 0,
      });
      setRoutingsDistribution([]);
      setRoutingsDistributionPreLoader([]);
      setRoutingsDistributionCopy([]);
      setDataSources([]);
      setDataSourcesPreLoader([]);
      setDataSourcesCopy([]);
      setCompletionReasons([]);
      setCompletionReasonsPreLoader([]);
      setCompletionReasonsCopy([]);
      setCompletedByTeams([]);
      setCompletedByTeamsPreLoader([]);
      setCompletedByTeamsCopy([]);
      // Enagement Tags
      setTagsPerformance([]);
      setTagsPerformancePreLoader([]);
      setTagsPerformanceCopy([]);
      setTagsPerformanceXlxs([]);
      setTagsUsageOvertime([]);
      setTagsUsageOvertimePreLoader([]);

      setEngagOverView({});
      setEngagOverViewPreLoader([]);
      setEngagersActivity([]);
      setEngagersActivityPreLoaderDataSources([]);
      setMinValueEngagersActivity(0);
      setMaxValueEngagersActivity(0);
      setAgentsPerformancePage(1);
      setAgentsPerformance([]);
      setAgentsPerformancePreLoader([]);
      agentsPerformanceLoading && setAgentsPerformanceLoading(1);
      isLoadingRoutings && setIsLoadingRoutings(1);
      isLoadingTeams && setIsLoadingTeams(1);
      setAgentsPerformanceCount(0);
      // enagement volumn
      setEngagVolume([]);
      setEngagPieChart([]);
      setEngagVolumePreLoader([]);

      // enagement completed volumn
      setEngagCompletedVolume([]);
      setEngagCompletedPieChart([]);
      setTagsUsageOvertimePieChart([]);
      setEngagCompletedVolumePreLoader([]);
      // handle Avg Time
      setHandleAvgTime({});
    }
  }, [resetAllFields]);

  // handle agent performance table
  useEffect(() => {
    if (
      _.isEqual(
        agentsPerformancePreLoader?.sort(),
        preLoaderTrackerDataSources?.sort(),
      ) == true &&
      preLoaderTrackerDataSources?.length &&
      agentsPerformancePreLoader?.length
    ) {
      if (agentsPerformanceLoading == 1) {
        let dataArr = [];
        let count = agentsPerformanceCount;
        agentsPerformance?.map((item) => {
          let itemData = agentsList?.filter(
            (filteredItem) => +filteredItem?.attributes?.id == +item?.id,
          )?.[0];

          if (itemData?.attributes?.id) {
            dataArr.push({
              ...item,
              data: itemData?.attributes?.avatar,
              active:
                itemData?.attributes?.availability_status !== "unavailable",
              status: itemData?.attributes?.availability_status,
              name: itemData?.attributes?.name,
            });
          } else {
            // handle pagination
            --count;
          }
        });
        setAgentsPerformanceCount(count);
        setAgentsPerformance([...dataArr]);
        if (agentsPerformanceLoading == 1) {
          setAgentsPerformanceLoading(2);
        }
      }
    }
  }, [
    preLoaderTrackerDataSources,
    agentsPerformancePreLoader,
    agentsList,
    agentsPerformanceLoading,
  ]);

  // handle routings
  useEffect(() => {
    if (
      isLoadingRoutings == 1 &&
      preLoaderTrackerDataSources?.length &&
      _.isEqual(
        routingsDistributionPreLoader?.sort(),
        preLoaderTrackerDataSources?.sort(),
      )
    ) {
      const newRoutings = routingsDistributionCopy
        ?.map((item) => {
          const routingName =
            routings?.find((routing) => routing?.id == item?.name)?.attributes
              ?.name || null;
          if (routingName) {
            return {
              name: routingName,
              value: item?.value,
            };
          }

          return null;
        })
        ?.filter(Boolean);
      setRoutingsDistributionXlxs(_.cloneDeep(newRoutings));
      setRoutingsDistribution(_.cloneDeep(newRoutings));
      setIsLoadingRoutings(2);
    }
  }, [routingsDistributionPreLoader, isLoadingRoutings]);

  // handle tags
  useEffect(() => {
    if (
      _.isEqual(
        tagsPerformancePreLoader?.sort(),
        preLoaderTrackerDataSources?.sort(),
      )
    ) {
      setTagsPerformanceXlxs(_.cloneDeep(tagsPerformanceCopy));
      setTagsPerformance(_.cloneDeep(tagsPerformanceCopy));
    }
  }, [tagsPerformancePreLoader]);
  // handle teams
  useEffect(() => {
    if (
      isLoadingTeams == 1 &&
      preLoaderTrackerDataSources?.length &&
      _.isEqual(
        completedByTeamsPreLoader?.sort(),
        preLoaderTrackerDataSources?.sort(),
      )
    ) {
      const newByTeams = completedByTeamsCopy
        ?.map((item) => {
          const teamName =
            teams?.find((team) => team?.id == item?.name)?.name || null;
          if (teamName) {
            return {
              name: teamName,
              value: item?.value,
            };
          }
          return null;
        })
        ?.filter(Boolean);
      setCompletedByTeamsXlxs(_.cloneDeep(newByTeams));
      setCompletedByTeams(_.cloneDeep(newByTeams));
      setIsLoadingTeams(2);
    }
  }, [isLoadingTeams, completedByTeamsPreLoader]);

  // in case of piechart aggregation (data sources, completion reasons)
  useEffect(() => {
    if (
      _.isEqual(
        dataSourcesPreLoader?.sort(),
        preLoaderTrackerDataSources?.sort(),
      )
    ) {
      setDataSourcesXlxs(_.cloneDeep(dataSourcesCopy));
      setDataSources(_.cloneDeep(dataSourcesCopy));
    }

    if (
      _.isEqual(
        completionReasonsPreLoader?.sort(),
        preLoaderTrackerDataSources?.sort(),
      )
    ) {
      setCompletionReasonsXlxs(_.cloneDeep(completionReasonsCopy));
      setCompletionReasons(_.cloneDeep(completionReasonsCopy));
    }
  }, [dataSourcesPreLoader, completionReasonsPreLoader]);

  // handle excel data
  useEffect(() => {
    let totalPreLoadersDataSources = [
      routingsDistributionPreLoader,
      dataSourcesPreLoader,
      completionReasonsPreLoader,
      completedByTeamsPreLoader,
      avgStatsDataPreLoader,
      engagOverViewPreLoader,
      engagVolumePreLoader,
      tagsUsageOvertimePreLoader,
      engagCompletedVolumePreLoader,
      engagersActivityPreLoaderDataSources,
      agentsPerformancePreLoader,
    ];

    const isAllPreLoadersLoaded =
      checkIsDataFecthing(
        totalPreLoadersDataSources,
        preLoaderTrackerDataSources,
      ) &&
      agentsPerformanceLoading >= 2 &&
      isLoadingRoutings >= 2 &&
      isLoadingTeams >= 2;

    setCheckAllDataFetchingExport(isAllPreLoadersLoaded);
    const excelData = {
      engagOverView: engagOverView,
      avgSla: {
        data: mergeHandleAvgTime(avgStatsData, handleAvgTime),
        currDate,
        prevDate,
      },
      engagVolume: engagVolume,
      tagsUsageOvertime: tagsUsageOvertime,
      engagCompletedVolume: engagCompletedVolume,
      engagersActivity: [
        engagersActivity,
        minValueEngagersActivity,
        maxValueEngagersActivity,
      ],
      routings: routingsDistributionXlxs,
      dataSources: dataSourcesXlxs,
      completionReasons: completionReasonsXlxs,
      completedByTeams: completedByTeamsXlxs,

      tagsPerformance: tagsPerformanceXlxs,
      agentsPerformance: agentsPerformance,
    };
    setExcelSheetData(excelData);
  }, [
    routingsDistributionPreLoader,
    dataSourcesPreLoader,
    completionReasonsPreLoader,
    completedByTeamsPreLoader,
    completedByTeamsXlxs,
    routingsDistributionXlxs,
    dataSourcesXlxs,
    completionReasonsXlxs,
    avgStatsData,
    avgStatsDataPreLoader,
    engagOverViewPreLoader,
    engagOverView,
    engagVolume,
    engagVolumePreLoader,
    tagsUsageOvertime,
    tagsUsageOvertimePreLoader,
    engagCompletedVolumePreLoader,
    engagCompletedVolume,
    engagersActivity,
    maxValueEngagersActivity,
    minValueEngagersActivity,
    engagersActivityPreLoaderDataSources,
    agentsPerformance,
    agentsPerformancePreLoader,
    agentsPerformanceLoading,
    isLoadingRoutings,
    isLoadingTeams,
    tagsPerformance,
  ]);

  if (isLoading) {
    return <CircularLoading />;
  }

  const isEngagementOverviewStillLoading =
    _.isEqual(
      preLoaderTrackerDataSources?.sort?.(),
      engagOverViewPreLoader?.sort?.(),
    ) !== true;

  return (
    <>
      <Header
        selectedTab={selectedTab}
        checkAllDataExist={checkAllDataFetchingExport}
        excelSheetData={excelSheetData}
        checkDataIsEmpty={engagOverView?.total_engag > 0}
        startDate={startDate}
        endDate={endDate}
      />
      <Box className={"engag-dashboard-widgets-container"}>
        <Box mb={5}>
          <StatisticsWidget
            title={"engag_overview"}
            titleToolTip={"engag_overview_tooltip"}
            chartId={"agent_performance"}
            toolTipLabel={"agent_performance_tooltip"}
            showDownloadIcon
            bigImg
            statsData={[
              {
                name: "total_engag",
                value: engagOverView?.total_engag
                  ? commasAfterDigit(engagOverView?.total_engag)
                  : 0,
                icon: faMessage,
                titleToolTip: "total_engag_tooltip",
              },
              {
                name: "total_dms",
                value: engagOverView?.total_dms
                  ? commasAfterDigit(engagOverView?.total_dms)
                  : 0,
                icon: faComments,
                titleToolTip: "total_dms_tooltip",
              },
              {
                name: "total_posts_engag",
                value: engagOverView?.total_posts
                  ? commasAfterDigit(engagOverView?.total_posts)
                  : 0,
                icon: faGlobe,
                titleToolTip: "total_posts_tooltip",
              },
              {
                name: "total_replied",
                value: engagOverView?.total_replied
                  ? commasAfterDigit(engagOverView?.total_replied)
                  : 0,
                icon: faReply,
                titleToolTip: "total_replied_tooltip",
              },
              {
                name: "total_completed",
                value:
                  engagOverView?.total_completed || 0
                    ? commasAfterDigit(engagOverView?.total_completed)
                    : 0,
                icon: faCircleCheck,
                titleToolTip: "total_completed_tooltip",
              },
            ]}
            containerClassName={"engag-overview"}
            statisticsPreLoaderDataSources={engagOverViewPreLoader}
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
            toolTipPosition="top"
          />
        </Box>
        <Box mb={5}>
          <AvgStatswWidget
            title="avg_slas"
            titleToolTip="avg_slas_desc"
            chartId="insight-tab-avg-slas"
            statsData={mergeHandleAvgTime(avgStatsData, handleAvgTime)}
            dates={{
              currDate: currDate,
              prevDate: prevDate,
            }}
            statisticsPreLoaderDataSources={avgStatsDataPreLoader}
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
            isLoading={isEngagementOverviewStillLoading}
          />
        </Box>
        <Box mb={5}>
          <StackedLineWidget
            title={"engag_volume"}
            titleToolTip={"engag_volume_tooltip"}
            data={orderObjectByArray(engagVolume, typesSorted)}
            areaStyle={true}
            isTopicSection
            hideLegend
            showDownloadIcon
            bigImg
            stackedLineWidgetPreLoaderDataSources={engagVolumePreLoader}
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
            isSwitchCase
            hidePercentage
            isSentimentAnalysis={false}
            showGroupByClientAgent={true}
            dataPiechart={getHandleSortedArray(typesSorted, engagPieChart)}
            labelColors={COLORS_ARR}
            colors={COLORS_ARR}
            isCityAnalysisWidget={true}
            showReportIcon={hasAppliedTimeFilter}
            reportIconTooltip={"time_picker_no_data_affect"}
          />
        </Box>
        <Box mb={5}>
          <StackedLineWidget
            title={"engag_completed_volume"}
            titleToolTip={"engag_completed_tooltip"}
            data={orderObjectByArray(engagCompletedVolume, typesSorted)}
            areaStyle={true}
            isTopicSection
            hideLegend
            showDownloadIcon
            bigImg
            stackedLineWidgetPreLoaderDataSources={
              engagCompletedVolumePreLoader
            }
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
            isSwitchCase
            hidePercentage
            isSentimentAnalysis={false}
            showGroupByClientAgent={true}
            dataPiechart={getHandleSortedArray(
              typesSorted,
              engagCompletedPieChart,
            )}
            labelColors={COLORS_ARR}
            colors={COLORS_ARR}
            isCityAnalysisWidget={true}
            showReportIcon={hasAppliedTimeFilter}
            reportIconTooltip={"time_picker_no_data_affect"}
          />
        </Box>
        <Box mb={5}>
          <PunchCardWidget
            title="engag_activity"
            titleToolTip="engag_activity_tooltip"
            clientActivity={engagersActivity}
            minValueClient={minValueEngagersActivity}
            maxValueClient={maxValueEngagersActivity}
            showDownloadIcon
            bigImg
            authorActivityPreLoaderDataSources={
              engagersActivityPreLoaderDataSources
            }
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          />
        </Box>
        <Box mb={5}>
          <Grid container spacing={3}>
            <Grid item md={6}>
              <PieChartWidget
                title="engag_routings_distribution"
                titleToolTip="engag_routings_distribution_tooltip"
                data={routingsDistribution}
                areaStyle={true}
                toolTipLabel={"toolTipLabel"}
                showDownloadIcon
                bigImg
                disableLocales
                pieChartPreLoaderDataSources={
                  isLoadingRoutings < 2 ? [] : routingsDistributionPreLoader
                }
                preLoaderTrackerDataSources={preLoaderTrackerDataSources}
              />
            </Grid>
            <Grid item md={6}>
              <PieChartWidget
                title="engag_data_sources"
                titleToolTip="engag_data_sources_tooltip"
                data={dataSources}
                areaStyle={true}
                toolTipLabel={"toolTipLabel"}
                showDownloadIcon
                bigImg
                pieChartPreLoaderDataSources={dataSourcesPreLoader}
                preLoaderTrackerDataSources={preLoaderTrackerDataSources}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mb={5}>
          <Grid container spacing={3}>
            <Grid item md={6}>
              <PieChartWidget
                title="engag_completion_reasons"
                titleToolTip="engag_completion_reasons_tooltip"
                data={completionReasons}
                areaStyle={true}
                toolTipLabel={"toolTipLabel"}
                showDownloadIcon
                bigImg
                pieChartPreLoaderDataSources={completionReasonsPreLoader}
                preLoaderTrackerDataSources={preLoaderTrackerDataSources}
              />
            </Grid>
            <Grid item md={6}>
              <PieChartWidget
                title="engag_completed_by_teams"
                titleToolTip="engag_completed_by_teams_tooltip"
                data={completedByTeams}
                areaStyle={true}
                toolTipLabel={"toolTipLabel"}
                showDownloadIcon
                bigImg
                disableLocales
                pieChartPreLoaderDataSources={
                  isLoadingTeams < 2 ? [] : completedByTeamsPreLoader
                }
                preLoaderTrackerDataSources={preLoaderTrackerDataSources}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mb={5}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TableWidget
                circularPP={true}
                itemsList
                title={"agents_performance"}
                isEngagInbox
                titleToolTip={"agents_performance_tooltip"}
                data={agentsPerformance}
                leftColomnTitle={"engag_agents"}
                rightColomnTitle={"engag_assigned"}
                thirdColomnTitle={"engag_completed"}
                page={agentsPerformancePage}
                setGlobalPage={(val) => {
                  agentsPerformancePage(val);
                }}
                tableChartPreLoaderDataSources={
                  agentsPerformanceLoading < 2 ? [] : agentsPerformancePreLoader
                }
                preLoaderTrackerDataSources={preLoaderTrackerDataSources}
                tablePagination
                count={agentsPerformanceCount}
              />
            </Grid>
            <Grid item md={6} className="tags-distribution-chart">
              <PieChartWidget
                title="tags_distribution"
                titleToolTip="tags_distribution_tooltip"
                data={tagsPerformance}
                areaStyle={true}
                toolTipLabel={"toolTipLabel"}
                showDownloadIcon
                bigImg
                pieChartPreLoaderDataSources={tagsPerformancePreLoader}
                preLoaderTrackerDataSources={preLoaderTrackerDataSources}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mb={5}>
          <StackedLineWidget
            title={"tags_usage_over_time"}
            titleToolTip={"tags_usage_over_time_tooltip"}
            data={orderObjectByArray(tagsUsageOvertime, typesSorted)}
            areaStyle={true}
            isTopicSection
            hideLegend
            showDownloadIcon
            bigImg
            stackedLineWidgetPreLoaderDataSources={tagsUsageOvertimePreLoader}
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
            isSwitchCase
            hidePercentage
            isSentimentAnalysis={false}
            showGroupByClientAgent={true}
            dataPiechart={getHandleSortedArray(
              typesSorted,
              tagsUsageOvertimePieChart,
            )}
            labelColors={COLORS_ARR}
            colors={COLORS_ARR}
            isCityAnalysisWidget={true}
          />
        </Box>
      </Box>
    </>
  );
};

export default InboxTab;
