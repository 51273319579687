import axios from "axios";
import Dashboards from "../api/dashboards.js";

var DashboardsController = {};
let getDashboardDataCancelToken;

DashboardsController.getMonitorsForDashboard = (monitor_type) => {
  return new Promise(function (resolve, reject) {
    Dashboards.getMonitorsForDashboard(monitor_type)
      .then(function (response) {
        resolve({
          data: response?.data,
        });
      })
      .catch(function (error) {
        resolve({
          errorMsg: error,
          status: error?.status,
        });
      });
  });
};

DashboardsController.getDashboardTypes = (queryData) => {
  return new Promise(function (resolve) {
    Dashboards.getDashboardTypes(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

DashboardsController.createNewDashboard = (queryData) => {
  return new Promise(function (resolve) {
    Dashboards.createNewDashboard(queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

DashboardsController.getDashboardList = (search, page, sort_by, order_by) => {
  var queryData = {
    search,
    page,
    sort_by,
    order_by,
  };
  return new Promise(function (resolve) {
    Dashboards.getDashboardList(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
//get dashboard info
DashboardsController.getDashboardInfo = (dashboard_id) => {
  return new Promise(function (resolve, reject) {
    Dashboards.getDashboardInfo(dashboard_id)
      .then(function (response) {
        resolve({
          data: response?.data,
        });
      })
      .catch(function (error) {
        resolve({
          errorMsg: error,
          status: error?.status,
        });
      });
  });
};

DashboardsController.editDashboardInfo = (
  dashboard_id,
  updatedValuesParams,
) => {
  return new Promise(function (resolve) {
    Dashboards.editDashboardInfo(updatedValuesParams, dashboard_id)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
DashboardsController.deleteDashboard = (dashboardId) => {
  return new Promise(function (resolve) {
    Dashboards.deleteDashboard(dashboardId)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

DashboardsController.getDashboardData = (
  activeTab,
  id,
  product_id,
  start_date,
  end_date,
  filters,
  page_number,
) => {
  let queryData = {
    product_id,
    start_date,
    end_date,
    filters: JSON.stringify(filters),
    page_number,
  };

  if (getDashboardDataCancelToken) {
    getDashboardDataCancelToken.cancel(
      "Operation canceled due to a new request.",
    );
  }
  getDashboardDataCancelToken = axios.CancelToken.source();

  return new Promise(function (resolve, reject) {
    Dashboards.getDashboardData(
      activeTab,
      id,
      queryData,
      getDashboardDataCancelToken?.token,
    )
      .then(function (response) {
        resolve({
          data: response?.data,
        });
      })
      .catch(function (error) {
        resolve({
          errorMsg: error,
          status: error?.status,
        });
      });
  });
};
// Active Filters
DashboardsController.getDashboardEnabledFilters = (dashboard_id, page) => {
  var queryData = {
    dashboard_id,
    page,
  };

  return new Promise(function (resolve) {
    Dashboards.getDashboardEnabledFilters(queryData)
      .then((response) => {
        resolve({
          data: response?.data?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// Saved Filters
DashboardsController.getDashboardSavedFilters = (dashboard_id, page) => {
  let queryData = {
    page,
  };
  return new Promise(function (resolve) {
    Dashboards.getDashboardSavedFilters(dashboard_id, queryData)
      .then((response) => {
        resolve({
          data: response?.data?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

DashboardsController.createDashboardSavedFilter = (
  dashboard_id,
  page,
  name,
  filter_params,
) => {
  let queryData = {
    page,
    name,
    filter_params,
  };
  return new Promise(function (resolve) {
    Dashboards.createDashboardSavedFilter(dashboard_id, queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

DashboardsController.getDashboardSavedFilterInfo = (
  dashboard_id,
  saved_filter_id,
  page,
) => {
  var queryData = {
    page,
  };

  return new Promise(function (resolve) {
    Dashboards.getDashboardSavedFilterInfo(
      dashboard_id,
      saved_filter_id,
      queryData,
    )
      .then((response) => {
        resolve({
          data: response?.data?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// Note: we didn't use update Dashboard Api in Saved Filters
DashboardsController.updateDashboardSavedFilterInfo = (
  dashboard_id,
  saved_filter_id,
  page,
) => {
  var queryData = {
    page,
  };

  return new Promise(function (resolve) {
    Dashboards.updateDashboardSavedFilterInfo(
      dashboard_id,
      saved_filter_id,
      queryData,
    )
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

DashboardsController.deleteDashboardSavedFilterInfo = (
  dashboard_id,
  saved_filter_id,
  page,
) => {
  var queryData = {
    page,
  };

  return new Promise(function (resolve) {
    Dashboards.deleteDashboardSavedFilterInfo(
      dashboard_id,
      saved_filter_id,
      queryData,
    )
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

export default DashboardsController;
