import { useQuery } from "@tanstack/react-query";
import { ReportServices } from "services/api/reports";

export const useReportTypes = () => {
  return useQuery({
    queryKey: ["reportTypes"],
    queryFn: () => ReportServices.getReportTypes(),
    select: (data) => {
      const filteredData = data?.data?.filter(
        (item) => item?.attributes?.name !== "channel",
      );

      const idsToNames = data?.data?.reduce((acc, item) => {
        acc[item?.attributes?.report_type] = item?.attributes?.name;
        return acc;
      }, {});

      const namesToIds = data?.data?.reduce((acc, item) => {
        acc[item?.attributes?.name] = item?.attributes?.report_type;
        return acc;
      }, {});

      return { filteredData, idsToNames, namesToIds };
    },
  });
};
