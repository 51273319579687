import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Box, Menu, MenuItem } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisH,
  faPenToSquare,
  faTrash,
} from "@fortawesome/pro-regular-svg-icons";
import LucButton from "shared/lucButton/lucButton";
import CustomizedTooltip from "components/customizedTooltip";
import "./tagList.scss";
import EngagementTags from "..";

const OptionsDropdown = (props) => {
  const intl = useIntl();
  const {
    enabledEdit,
    enabledDelete,
    setOpenPopUp,
    setPopUpState,
    setContentModalBody,
    editTagData,
    tagsTableLength,
    setSelectedTag,
    setSnackBarTagsTable,
    tagsLimitReached,
    tagsTableData,
  } = props;

  const [menuStats, setMenuStats] = useState({ anchorEl: null });
  const [isEnabledEdit, setIsEnabledEdit] = useState(false);
  const [dropdownEvent, setDropdownEvent] = useState(null);
  const menuOpened = Boolean(menuStats?.anchorEl);

  // Open menu dropdown
  const handleOpenMenuDropDown = (event) => {
    setMenuStats({ ...menuStats, anchorEl: event?.currentTarget });
    setDropdownEvent(event?.currentTarget);
    if (editTagData) setSelectedTag(editTagData);
  };

  // Close menu dropdown
  const handleCloseMenuDropdown = () => {
    setMenuStats({ ...menuStats, anchorEl: null });
  };

  // Open delete tag confirmation popup
  const handleOpenDeleteTag = () => {
    setOpenPopUp(true);
    setPopUpState({
      title: "delete_tag",
      leftBtn: "cancel",
      rightBtn: "confirm_delete_tag",
      status: "delete",
    });
    setContentModalBody({
      title: "delete_tag_title",
      subTitle: "delete_tag_dec",
      listOne: "delete_tag_dec_one",
      listTwo: "delete_tag_dec_two",
      listThree: "delete_tag_dec_three",
      lastText: "delete_tag_dec_last_title",
    });
    setMenuStats({ ...menuStats, anchorEl: null });
    handleCloseMenuDropdown();
  };

  // Open edit mode for tag
  const handleOpenEditMenu = () => {
    setIsEnabledEdit(true);
    setMenuStats({ anchorEl: null });
  };

  return (
    <Box className="options-tag-table">
      <CustomizedTooltip
        id="tags-option-tooltip"
        title={
          !isEnabledEdit ? (
            <Box className="item-title-tooltip">
              {CheckValueLocale(
                !enabledEdit && !enabledDelete
                  ? "dont_have_a_permission"
                  : "items_ellipsis",
                "",
                {},
                intl,
              )}
            </Box>
          ) : null
        }
        placement="bottom"
        arrow
      >
        <Box>
          <LucButton
            id="tag-more-settings-btn"
            className="menu-items"
            type="secondary"
            variant="flat"
            minWidth={42}
            aria-expanded={menuOpened ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleOpenMenuDropDown}
            onClose={handleCloseMenuDropdown}
          >
            <FontAwesomeIcon className="ellipsis-icon" icon={faEllipsisH} />
          </LucButton>
        </Box>
      </CustomizedTooltip>
      {isEnabledEdit ? (
        <EngagementTags
          isOptionTable={true}
          setIsEnabledEdit={setIsEnabledEdit}
          editTagData={editTagData}
          tagsTableLength={tagsTableLength}
          dropdownEvent={dropdownEvent}
          setSnackBarTagsTable={setSnackBarTagsTable}
          tagsLimitReached={tagsLimitReached}
          tagsTableData={tagsTableData}
        />
      ) : null}
      {!isEnabledEdit ? (
        <Menu
          id="tag-table-options-menu"
          anchorEl={menuStats?.anchorEl}
          open={menuOpened}
          onClose={handleCloseMenuDropdown}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: intl?.locale === "ar" ? "left" : "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: intl?.locale === "ar" ? "left" : "right",
          }}
        >
          <MenuItem
            id="tag-table-more-options-edit-btn"
            disableRipple
            disabled={!enabledEdit}
            onClick={handleOpenEditMenu}
          >
            <FontAwesomeIcon icon={faPenToSquare} fixedWidth />
            {CheckValueLocale("edit", "", {}, intl)}
          </MenuItem>
          <MenuItem
            id="tag-table-more-options-delete-btn"
            disableRipple
            disabled={!enabledDelete}
            onClick={handleOpenDeleteTag}
          >
            <FontAwesomeIcon icon={faTrash} fixedWidth />
            {CheckValueLocale("delete", "", {}, intl)}
          </MenuItem>
        </Menu>
      ) : null}
    </Box>
  );
};

export default OptionsDropdown;
