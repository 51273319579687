import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import ListOptions from "./accontListOptions";
import "./AccountsListsList.scss";
import SharedTable from "../../../shared/table/sharedTable";
import NoDataFoundProducts from "components/no-Data";
import NoDataLinks from "components/no-Data/noDataLinks";
import CustomizedTooltip, {
  customizedStyledTooltip,
} from "components/customizedTooltip";

const AccountsListsList = ({
  list,
  handleOpenDeleteModal,
  handleOpenDuplicateModal,
  searchText,
  totalAccountsLists,
}) => {
  const companyTimeZone = Number.parseInt(window.localStorage.companyInfo) || 0;

  const intl = useIntl();
  const navigate = useNavigate();

  const [tableBody, setTableBody] = useState([]);
  const [tableEmptyBody, setTableEmptyBody] = useState([]);
  const formatUsernames = (usernames) => {
    const maxLength = 75;
    const maxUsernames = 3;
    let formattedUsernames = [];
    let totalLength = 0;

    for (let i = 0; i < usernames.length; i++) {
      const username = usernames[i];
      const newLength = totalLength + username?.length + (i > 0 ? 2 : 0); // 2 for ", "

      if (newLength > maxLength || formattedUsernames?.length >= maxUsernames) {
        break;
      }

      formattedUsernames?.push(username);
      totalLength = newLength;
    }

    const remainingUsernames = usernames?.length - formattedUsernames?.length;
    if (remainingUsernames > 0) {
      formattedUsernames?.push("...");
    }

    return {
      formattedUsernames: formattedUsernames?.join(", "),
      remainingUsernames,
    };
  };
  const showTooltipText = (text, textLength) => {
    return (
      <Box className="accounts-list-name-container">
        {text?.length > textLength ? (
          <CustomizedTooltip
            title={text}
            arrow
            placement="top"
            styles={{
              ...customizedStyledTooltip,
              maxWidth: "200px",
            }}
          >
            <Typography variant="subtitle2" className="accounts-list-title">
              {text?.substring(0, textLength)}...
            </Typography>
          </CustomizedTooltip>
        ) : (
          <Typography variant="subtitle2" className="monitor-title">
            {text}
          </Typography>
        )}
      </Box>
    );
  };

  const options = (item) => {
    return (
      <ListOptions
        accountsListData={item}
        handleOpenDeleteModal={handleOpenDeleteModal}
        handleOpenDuplicateModal={handleOpenDuplicateModal}
        navigate={navigate}
        totalAccountsLists={totalAccountsLists}
      />
    );
  };
  useEffect(() => {
    const tabBody =
      list?.length == 0
        ? []
        : list?.map((item) => {
            return {
              id: item?.id,
              data: [
                {
                  body: item?.attributes?.name?.length ? (
                    <Box>{showTooltipText(item?.attributes?.name, 47)}</Box>
                  ) : (
                    "—"
                  ),
                },
                {
                  body: item?.attributes?.usernames?.length ? (
                    <>
                      <Box component="span" className="username-span">
                        {
                          formatUsernames(item?.attributes?.usernames)
                            ?.formattedUsernames
                        }
                      </Box>
                      <Box component="span" className="username-span-num">
                        {formatUsernames(item?.attributes?.usernames)
                          ?.remainingUsernames > 0 &&
                          `+${formatUsernames(item?.attributes?.usernames)?.remainingUsernames}`}
                      </Box>
                    </>
                  ) : (
                    "—"
                  ),
                },
                {
                  body: item?.attributes?.updated_at?.length ? (
                    <Box component="span">
                      <Box component="span" className="date-span">
                        {moment(item?.attributes?.updated_at)
                          ?.utcOffset(companyTimeZone)
                          ?.format("YYYY/MM/DD")}
                      </Box>
                      <Box component="span" className="time-span">
                        {moment(item?.attributes?.updated_at)
                          ?.utcOffset(companyTimeZone)
                          ?.format("h:mm A")
                          ?.replace(
                            "AM",
                            CheckValueLocale("hour_AM", "", {}, intl),
                          )
                          ?.replace(
                            "PM",
                            CheckValueLocale("hour_PM", "", {}, intl),
                          )}
                        {` (GMT${companyTimeZone >= 0 ? "+" : "-"}${Math.abs(companyTimeZone)})`}
                      </Box>
                    </Box>
                  ) : (
                    "—"
                  ),
                },

                {
                  body: item?.attributes?.created_by_user_name?.length ? (
                    <Box className="created-by">
                      {showTooltipText(
                        item?.attributes?.created_by_user_name,
                        15,
                      )}
                      <Box className="created-by-deleted-user">
                        {item?.attributes?.is_created_by_user_deleted
                          ? `(${CheckValueLocale("user_deleted", "", {}, intl)})`
                          : ""}
                      </Box>
                    </Box>
                  ) : (
                    "—"
                  ),
                },
                {
                  body: options(item),
                },
              ],
            };
          });
    setTableBody(tabBody);
    setTableEmptyBody(emptyBody(list));
  }, [list, intl?.locale]);

  const tableHead = [
    {
      body: CheckValueLocale("list_name", "", {}, intl),
      width: "25%",
    },

    {
      body: CheckValueLocale("added_accounts", "", {}, intl),
      width: "38%",
    },
    {
      body: CheckValueLocale("last_modified", "", {}, intl),
      width: "15%",
    },
    {
      body: CheckValueLocale("list_creator", "", {}, intl),
      width: "13%",
    },
    {
      body: null,
      width: "5%",
    },
  ];
  const emptyBody = (list) => {
    return (
      <>
        {searchText !== "" && list?.length == 0 ? (
          <Box className="no-data-cdp">
            <NoDataFoundProducts
              image={"noDataImg"}
              title={"no_data_found"}
              subTitle="sorry_adjust_your_search"
            />
          </Box>
        ) : list?.length == 0 ? (
          <Box className="empty-item">
            <NoDataFoundProducts
              image="EmptyAccountsLists"
              title="create_your_list"
              description="create_your_list_dec"
              linkOne="how_to_use"
              linkOneHref={NoDataLinks?.accounts_list_link}
              orCase="or_visit_the"
              linkTwo="knowledge_base"
            />
          </Box>
        ) : null}
      </>
    );
  };
  return (
    <>
      <SharedTable
        className="accounts-lists-table"
        tableHeader={tableHead}
        tableBody={tableBody}
        isSharedStyle
        emptyBody={tableEmptyBody}
      />
    </>
  );
};
export default AccountsListsList;
