import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MainMonitorsPage } from "services/api/mainMonitorsPage";

export const usePauseOrResumeMonitor = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (monitorId) =>
      MainMonitorsPage.resumeOrPauseMonitors(monitorId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["monitors"],
      });
    },
  });
};
