import { memo } from "react";
import DesginPDF from "./components/headerNewsLetter/exportButton/pdf/desginPDF/desginPDF";
import { ReportProvider } from "./context/reportContext";
import NewsLetterPage from "./components/newsLetterPage";
import "./style.scss";
import CoverPdfNewsLetter from "./components/headerNewsLetter/component/coverPdfNewsLetter";

const ViewNewsLetter = () => {
  return (
    <ReportProvider>
      <NewsLetterPage />
      <DesginPDF coverPdf={<CoverPdfNewsLetter />} />
    </ReportProvider>
  );
};

export default memo(ViewNewsLetter);
