import { Stack, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import LucButton from "shared/lucButton/lucButton";
import { CheckValueLocale, getResetPhoneNumber } from "utils/helpers";
import DefaultUserAvtar from "images/shared-images/user-management-default.svg";
import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { faCheckDouble } from "@fortawesome/pro-solid-svg-icons";
import { useGetWhatsappAccountAuthLink } from "../hooks/useGetWhatsappAuthLink";
import { useCheckWhatsappAccAuthStatus } from "../hooks/useCheckWhatsappAccAuthStatus";
import Snackbar from "components/snackBar";
import CircularLoading from "components/circularProgress";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { resetSnackbar } from "utils/redux/features/ManageChannels/ManageChannels";

const WhatsappStepsForAuth = ({
  selectedAccount,
  includedAccounts,
  whatsappAccAuthinticated,
  setWhatsappAccAuthinticated,
  setWhatsappAuthStepSnackBar,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [loadingStatus, setLoadingStatus] = useState(true);
  const { channelFailed, channelFailedMsg } = useSelector(
    (state) => state.manageChannels,
  );

  const isFailMsgSomethingWrong =
    channelFailedMsg === "SOMETHINGS_WENT_WRONG_PLEASE_TRY_AGAIN_LATER";
  const isWhatsappAppNotLiveMsg = channelFailedMsg === "whatsapp_app_not_live";

  // Here we are filtering the account from included accounts to get the app_id
  const appId = includedAccounts?.find(
    (account) =>
      account?.attributes?.account_info_id === selectedAccount?.attributes?.id,
  )?.attributes?.business_account_id;
  const { mutate: getWhatsappAuthLink, isPending } =
    useGetWhatsappAccountAuthLink();
  const { mutate: checkWhatsappAuthIsDone, isPending: isChecking } =
    useCheckWhatsappAccAuthStatus();

  const handleWhatsappAuthRedirection = () => {
    // Here we Generate authintication link by calling this endpoint
    getWhatsappAuthLink(
      {
        app_id: appId,
        user_name: selectedAccount?.attributes?.name,
      },
      {
        onSuccess: (data) => {
          // After authintication link is generated redirect to that link
          if (data?.data?.embed_link) {
            window.open(data?.data?.embed_link, "_blank");
          }
        },
        onError: (error) => {
          const isMaxLimitReached =
            error?.errorMsg?.response?.data?.exception?.message?.includes?.(
              "Max Links Already sent",
            );
          if (isMaxLimitReached) {
            setWhatsappAuthStepSnackBar({
              openSnackBar: true,
              severity: "error",
              title: "whatsapp_limit_reached_title",
              message: "whatsapp_limit_reached_msg",
            });
          } else {
            setWhatsappAuthStepSnackBar({
              openSnackBar: true,
              message: "try_again_error_message",
              severity: "error",
              title: "failed_error_message",
            });
          }
        },
      },
    );
  };

  const checkAuthIsDone = (skipSnackBar) => {
    // Here we Check if the authintication process is Done or not
    checkWhatsappAuthIsDone(
      { app_id: appId },
      {
        onSuccess: (data) => {
          if (data?.data?.app_status?.is_live) {
            setWhatsappAccAuthinticated(true);
          } else {
            !skipSnackBar &&
              setWhatsappAuthStepSnackBar({
                openSnackBar: true,
                severity: "error",
                title: "whatsapp_auth_status_check_failed_title",
                message: "whatsapp_auth_status_check_failed_msg",
              });
            setWhatsappAccAuthinticated(false);
          }
          setLoadingStatus(false);
        },
        onError: () => {
          !skipSnackBar &&
            setWhatsappAuthStepSnackBar({
              openSnackBar: true,
              severity: "error",
              title: "whatsapp_auth_status_check_failed_title",
              message: "whatsapp_auth_status_check_failed_msg",
            });
          setWhatsappAccAuthinticated(false);
          setLoadingStatus(false);
        },
      },
    );
  };

  useEffect(() => {
    setWhatsappAccAuthinticated(false);
    checkAuthIsDone(true);
  }, []);

  useEffect(() => {
    if (channelFailed && channelFailedMsg === "whatsapp_app_not_live") {
      setWhatsappAccAuthinticated(false);
    }
  }, [channelFailed]);
  return (
    <Stack direction="column" className="whatsapp-auth-step-wrapper">
      <Stack direction="column" className="whatsapp-auth-step-one">
        <Typography>
          a. {CheckValueLocale("whatsapp_auth_step_one", "", {}, intl)}
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="whatsapp-auth-step-one-wrapper"
        >
          <Stack
            direction="row"
            gap="10px"
            className="whatsapp-auth-step-one-content"
            alignItems="center"
          >
            <img src={DefaultUserAvtar} alt="userImg" />
            <Stack direction="column" justifyContent="center">
              <Typography variant="h6">
                {selectedAccount?.attributes?.name}
              </Typography>
              <Typography component="span">
                {getResetPhoneNumber(selectedAccount?.attributes?.user_name)}
              </Typography>
            </Stack>
          </Stack>
          {!whatsappAccAuthinticated || loadingStatus ? (
            <LucButton
              id="whatsapp-auth-submit-buttuon"
              variant="outline"
              type="primary"
              startIcon={<FontAwesomeIcon icon={faExternalLink} />}
              onClick={handleWhatsappAuthRedirection}
              loading={loadingStatus || isPending}
              disabled={isChecking && !loadingStatus}
            >
              {CheckValueLocale("whatsapp_auth_step_one_button", "", {}, intl)}
            </LucButton>
          ) : (
            <Stack
              direction="row"
              alignItems="center"
              className="whatsapp-auth-step-one-authinticated-msg"
              gap="8px"
            >
              <Typography component="span">
                {CheckValueLocale(
                  "whatsapp_step_one_authinticated",
                  "",
                  {},
                  intl,
                )}
              </Typography>
              <FontAwesomeIcon icon={faCheckDouble} />
            </Stack>
          )}
        </Stack>
      </Stack>
      <Stack direction="column" className="whatsapp-auth-step-one">
        <Typography>
          b. {CheckValueLocale("whatsapp_auth_step_two", "", {}, intl)}
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="whatsapp-auth-step-one-wrapper step-two"
        >
          <Typography>
            {CheckValueLocale(
              "whatsapp_auth_step_two_description",
              "",
              {},
              intl,
            )}
          </Typography>
          <LucButton
            id="whatsapp-auth-complete-buttuon"
            variant="outline"
            type="primary"
            disabled={loadingStatus || whatsappAccAuthinticated || isPending}
            onClick={() => checkAuthIsDone(false)}
            loading={isChecking && !loadingStatus}
          >
            {CheckValueLocale("whatsapp_auth_step_two_button", "", {}, intl)}
          </LucButton>
        </Stack>
      </Stack>

      <Snackbar
        snackBarId=" whatsapp-add-channel-snackbar"
        alertStyle={isWhatsappAppNotLiveMsg && { maxWidth: "422px" }}
        open={channelFailed}
        severity={"error"}
        title={CheckValueLocale(
          isFailMsgSomethingWrong
            ? "add_channel_whatsapp_failed_title"
            : isWhatsappAppNotLiveMsg
              ? "add_channel_whatsapp_app_not_live_title"
              : channelFailedMsg,
          "",
          {},
          intl,
        )}
        message={
          isFailMsgSomethingWrong
            ? CheckValueLocale("add_channel_whatsapp_failed_msg", "", {}, intl)
            : isWhatsappAppNotLiveMsg
              ? CheckValueLocale(
                  "add_channel_whatsapp_app_not_live_msg",
                  "",
                  {},
                  intl,
                )
              : null
        }
        handleClose={() => dispatch(resetSnackbar())}
      />
    </Stack>
  );
};

export default WhatsappStepsForAuth;
