import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  Grid,
  Box,
  Typography,
  Tooltip,
  InputAdornment,
  Button,
  OutlinedInput,
} from "@mui/material";

import { CheckValueLocale, isEmptyArray } from "utils/helpers";
import CircularLoading from "components/circularProgress";

import "../keywords.scss";
import SocialMediaAccountButton from "../../../Components/SocialMediaAuthButton/socialMediaAuthButton";
import KeywordTooltipChip from "../../../../MonitorsShared/SharedComponent/keywordTypeComponent/keywordTooltipChip";
import KeywordPopupModal from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/keywordPoupModal/keywordPopupModal";
import CopyIcon from "../../../../MonitorsShared/SharedComponent/keywordTypeComponent/copyIcon";
import {
  handleInputChange,
  checkPlaceHolderText,
  handleInputFieldName,
  checkAllInputsField,
  checkKeywordPopup,
  checkDirectionLang,
  handleInputMouseDown,
} from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/kewordsSharedFunctions";
import {
  handleKeywordsPopUp,
  checkValue,
} from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/monitorsSharedFunctions";
import MonitorDropdownsSearch from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/DropdownSearch/monitorDropdownsSearch";
import ShowRefreshAlert from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/ShowRefreshAlert/showRefreshAlert";

const FaceBookKeyword = (props) => {
  const intl = useIntl();
  const isRTL = intl?.locale === "ar";
  const {
    handleKeywords,
    keywords,
    indexes,
    handleDelete,
    handleValidation,
    handleData,
    wordCountFacebook,
    facebookAccounts,
    selectedFacebookPages,
    setSelectedFacebookPages,
    facebookPages,
    setFacebookPages,
    getFacebookPages,
    isLoadingSelectList,
    monitorFaceBookKeywordLimit,
    monitorFaceBookOtherFieldLimit,
    setShowFbAlert,
    showFbAlert,
  } = props;

  // input Foucsed Case
  const [focused, setFocused] = useState(false);
  const [err, setErr] = useState({
    errMsg: "",
    keyword: "",
  });
  let keywordsInfo = ["keywords", "spam_keywords"];

  const [optionPages, setOptionPages] = useState([]);
  const [limitErrorPages, setLimitErrorPages] = useState("");

  const handleKeyDown = (event, keyword, index, clickType) => {
    if (keyword === "keywords" || keyword === "spam_keywords") {
      handleKeywords(
        event,
        keyword,
        "facebook",
        index,
        clickType,
        monitorFaceBookKeywordLimit,
      );
    }
  };

  const onPagesChange = (newPages = []) => {
    let selectedList = optionPages?.filter((page) =>
      newPages?.includes(page.id),
    );
    setSelectedFacebookPages(selectedList);
    if (selectedList?.length === monitorFaceBookOtherFieldLimit) {
      setLimitErrorPages("max_FB_pages_limit_excceds");
    } else if (selectedList?.length > monitorFaceBookOtherFieldLimit) {
      setLimitErrorPages("error_FB_pages_limit_excceds");
    } else {
      setLimitErrorPages("");
    }
  };



  useEffect(() => {
    let newList = [...selectedFacebookPages, ...facebookPages];
    const seen = new Set();
    const AllOptionList = newList.filter((el) => {
      const duplicate = seen.has(+el.id);
      seen.add(+el.id);
      return !duplicate;
    });

    setOptionPages(AllOptionList);
  }, [facebookPages]);

  return (
    <Box className="data-source-keyword">
      {showFbAlert && (
        <ShowRefreshAlert setShowAlert={setShowFbAlert} />
      )}
      {!facebookAccounts || props.isLoading ? (
        <CircularLoading />
      ) : isEmptyArray(facebookAccounts) ? (
        <>
          <SocialMediaAccountButton
            authHead={"authinticate_new_facebook_account"}
            companyApps={props.companyApps}
            authDataSourceName={"add_facebook_account_profile"}
            dataSourceName={"facebook"}
            isPublicAccount
            isCompanyAccount={false}
            redirectUrl={
              window.location.origin +
              "/settings/accounts_management/users_accounts"
            }
          />
        </>
      ) : (
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {keywordsInfo.map((keyword, index) => {
            const fbValues = Object.values(keywords[indexes]?.facebook[index]);
            return (
              <>
                <Grid item xs={6} className="keywords-inputs-fields">
                  <Typography
                    variant="caption"
                    className="keywords keyword-head-title"
                  >
                    {
                      <Tooltip
                        title={CheckValueLocale(
                          "keywords_spam_keywords_tooltip",
                          "",
                          {},
                          intl,
                        )}
                        arrow
                        placement="bottom"
                      >
                        <Typography
                          variant="caption"
                          className="media-input-label"
                        >
                          {CheckValueLocale(
                            handleInputFieldName(keyword),
                            "",
                            {},
                            intl,
                          )}
                        </Typography>
                      </Tooltip>
                    }
                  </Typography>
                  {/* input field */}
                  <OutlinedInput
                    variant="outlined"
                    className={` keywords-field keyword-input-field ${checkDirectionLang(
                      keywords[indexes].facebook[index]?.answer,
                    )}`}
                    onFocus={() => setFocused(true)}
                    onBlur={() => {
                      // Delete Error message when leaving the Keyword box
                      if (
                        keywords[indexes]?.facebook[index]?.answer?.length === 0
                      ) {
                        keywords[indexes].facebook[index].errorMsg = "";
                      }
                      setFocused(false);
                    }}
                    endAdornment={
                      focused && (
                        <InputAdornment onMouseDown={handleInputMouseDown}>
                          <Button
                            className="add-btn-with-plus"
                            onClick={(event) =>
                              handleKeyDown(event, keyword, index, "btn")
                            }
                            value={
                              Object.keys(
                                keywords[indexes]?.facebook[index],
                              )[0] === keyword
                                ? keywords[indexes].facebook[index].answer
                                : ""
                            }
                            id="monitor-options-fb-add-keyword-btn"
                          >
                            {CheckValueLocale("add_plus", "", {}, intl)}
                          </Button>
                        </InputAdornment>
                      )
                    }
                    error={
                      keywords[indexes]?.facebook[index]?.errorMsg !== "" &&
                      Object.keys(keywords[indexes]?.facebook[index])[0] ===
                        keyword
                    }
                    placeholder={CheckValueLocale(
                      checkPlaceHolderText(keyword),
                      "",
                      {},
                      intl,
                    )}
                    onChange={(e) =>
                      handleInputChange(
                        e,
                        keyword,
                        "facebook",
                        index,
                        indexes,
                        keywords,
                        setErr,
                        handleValidation,
                      )
                    }
                    value={
                      Object.keys(keywords[indexes]?.facebook[index])[0] ===
                      keyword
                        ? keywords[indexes].facebook[index].answer
                        : ""
                    }
                    onKeyDown={(event) => handleKeyDown(event, keyword, index)}
                  />

                  {keywords[indexes]?.facebook?.map((obj) => {
                    if (
                      Object.values(obj)[1] !== "" &&
                      Object.keys(obj)[0] === keyword
                    ) {
                      return (
                        <Box className="err-msg">
                          {CheckValueLocale(
                            Object.values(obj)[1],
                            "",
                            { limit: monitorFaceBookKeywordLimit },
                            intl,
                          )}
                        </Box>
                      );
                    }
                  })}

                  <Box className="keyword-spam-wrapper">
                    {keywords[indexes]?.facebook?.map((obj) => {
                      if (
                        Object.values(obj)[0].length > 0 &&
                        Object.keys(obj)[0] === keyword
                      ) {
                        return checkValue(Object.values(obj)[0]).map(
                          (labels, index) => (
                            <>
                              <KeywordTooltipChip
                                onDelete={(e) =>
                                  handleDelete(
                                    e,
                                    index,
                                    Object.keys(obj)[0],
                                    indexes,
                                    "facebook",
                                  )
                                }
                                dataSource={"facebook"}
                                index={index}
                                indexes={indexes}
                                labels={labels}
                              />
                            </>
                          ),
                        );
                      }
                    })}

                    {checkAllInputsField(keyword, keywordsInfo) && (
                      <KeywordPopupModal
                        keywords={handleKeywordsPopUp(
                          keyword,
                          keywords[2].facebook[0]?.keywords,
                          keywords[2].facebook[1]?.spam_keywords,
                        )}
                        handleDelete={handleDelete}
                        dataSourceType={"facebook"}
                        keywordType={checkKeywordPopup(keyword)}
                        dataSourceIndex={2} // dataSource index to handle delete keyword
                      />
                    )}
                    {fbValues[0].length !== 0 && (
                      <CopyIcon
                        checkCopyValue={fbValues[2]}
                        handleData={(e) =>
                          handleData(e, fbValues[0], indexes, index)
                        }
                      />
                    )}
                  </Box>
                </Grid>
              </>
            );
          })}

          <Grid item xs={6}>
            <MonitorDropdownsSearch
              options={optionPages?.length ? optionPages : []}
              placeholder={CheckValueLocale("select", "", {}, intl)}
              label={CheckValueLocale("facebook_pages", "", {}, intl)}
              tooltip={CheckValueLocale("facebook_pages_tooltip", "", {}, intl)}
              tooltipPosition={isRTL ? "left" : "right"}
              onChange={onPagesChange}
              getApiSearch={getFacebookPages}
              searchApi={true}
              defaultValue={
                selectedFacebookPages?.length
                  ? selectedFacebookPages.map((page) => page.id)
                  : []
              }
              isLoadingSelectList={isLoadingSelectList}
              resetSearchList={setFacebookPages}
              limitError={limitErrorPages === "error_FB_pages_limit_excceds"}
            />
            <Box
              className={
                limitErrorPages === "error_FB_pages_limit_excceds"
                  ? "err-msg"
                  : "note-limit-msg"
              }
            >
              {CheckValueLocale(
                limitErrorPages,
                "",
                { limit: monitorFaceBookOtherFieldLimit },
                intl,
              )}
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default FaceBookKeyword;
