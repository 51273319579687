import { useEffect, useState, useContext, useRef } from "react";
import { useIntl } from "react-intl";
import AudienceList from "./audiencePage/audienceList";
import AudienceHeader from "./audiencePage/audienceHeader";
import AudienceController from "services/controllers/audienceController";
import { Box } from "@mui/system";
import { Container, Pagination } from "@mui/material";
import moment from "moment";
import CircularLoading from "components/circularProgress";
import { CheckValueLocale, syncUnixToUTC } from "../../utils/helpers/index.js";
import Snackbar from "components/snackBar";
import FiltersFunctions from "./audiencePage/filterSharedFunctions";
import { getActiveParams } from "../../shared/general-filter/activeFilters/activeFilters";
import ActiveFilters from "../../shared/general-filter/activeFilters/activeFilters";
import { isEmptyObj } from "utils/helpers";
import CommonFunctions from "pages/Monitors/Components/commonFunctions";
import UserContext from "context/audienceContext";
import AudienceImportExcelPopUp from "./components/audienceImportPopUp";
import ImportFunctions from "./components/audienceImportPopUp/components/importSharedFunction.js";
const Audience = () => {
  const intl = useIntl();
  const [audience, setAudience] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState(1);
  const [segmentList, setSegmentList] = useState([]);
  const [startDate, setStartDate] = useState(
    syncUnixToUTC(moment().subtract(6, "d").unix(), "start"),
  );
  const [endDate, setEndDate] = useState(syncUnixToUTC(moment().unix(), "end"));
  const [pickerStartDate, setPickerStartDate] = useState(
    moment().subtract(6, "d").unix(),
  );
  const [pickerEndDate, setPickerEndDate] = useState(moment().unix());
  const [searchActivated, setSearchActivated] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [openExportPopUp, setOpenExportPopUp] = useState(false);
  const [exportSnackBar, setExportSnackBar] = useState({
    message: "",
    severity: "",
    title: "",
    openSnackBar: false,
  });
  const dateRangePickerParamsRef = useRef();

  const ctx = useContext(UserContext);
  const handleCloseAudienceExportSnackBar = () => {
    setExportSnackBar({ openSnackBar: false });
  };
  const [alertData, setAlertData] = useState({
    number: "",
    message: "",
    title: "",
    severity: "",
    openAlert: false,
  });
  const { changeFilterParamsToString } = CommonFunctions();
  const handleAlert = (severity, msg, tit, number, isAddOrExclude) => {
    setAlertData({
      number: number,
      message: msg,
      title: tit,
      severity: severity,
      openAlert: severity && msg ? true : false,
    });
    if (severity === "success") {
      getSegmentList();
      if ((number && number !== "0") || isAddOrExclude === true) {
        getAudience(startDate, endDate, filterParams, page);
      }
    }
  };

  const {
    handleShowSaveAndApplyModal,
    handleCloseSnackBar,
    showSnackBar,
    handleClearFilterParams,
    setFilterParams,
    filterParams,
    snackBarDetails,
    filterFields,
    getFiltersFileds,
    savedFilters,
    setSavedFilterId,
    savedFilterId,
    showDeleteFilterModal,
    handleCloseDeleteFilterModal,
    setShowDeleteFilterModal,
    filterName,
    assignActiveFilters,
    setAssignActiveFilters,
  } = FiltersFunctions();
  let {
    files,
    setFiles,
    excelFileName,
    excelFileError,
    excelFileErrorMsg,
    isFileUploaded,
    fileName,
    displayUploadErrorMessage,
    handleImportAudience,
    handleResetImportPopUp,
    setOpenImportPopUp,
    enableProfileAudience,
    setEnableProfileAudience,
    enableUncomplyingAudience,
    setEnableUncomplyingAudience,
    openImportPopUp,
    getTitleImportExcel,
    handleExcelGuideData,
    handleCancelUploadFile,
    excelGuideData,
  } = ImportFunctions();

  const getAudience = (startDate, endDate, filterParams, page) => {
    setLoading(true);
    AudienceController.getAudience(
      searchText,
      startDate,
      endDate,
      page,
      filterParams && changeFilterParamsToString(filterParams, true),
    ).then((result) => {
      setAudience(result);
      setPagination(result?.data?.total_pages);
      setLoading(false);
    });
  };

  const getSegmentList = () => {
    AudienceController.getSegmentList().then((result) => {
      setSegmentList(result?.data?.data);
    });
  };
  const handleChangePage = (event, page) => {
    if (Boolean(page) === false) return;
    setPage(page);
    getAudience(startDate, endDate, filterParams, page);
  };

  const applyFilter = (
    startDate,
    endDate,
    activeFilterParams,
    pageNo,
    isAppliedFilter,
  ) => {
    setPage(1);
    let selectedFilterParams =
      !isAppliedFilter && isEmptyObj(ctx?.audienceFilters)
        ? assignActiveFilters
        : activeFilterParams !== undefined
          ? activeFilterParams
          : !isEmptyObj(ctx?.audienceFilters)
            ? ctx?.audienceFilters
            : filterParams;

    ctx.audienceFilters = selectedFilterParams;
    getAudience(startDate, endDate, selectedFilterParams, 1);
    setAssignActiveFilters(selectedFilterParams);
  };

  const handleApplyExport = () => {
    const queryData = {
      data: {
        cdp: {
          filters:
            filterParams && changeFilterParamsToString(filterParams, true),
          start_date: startDate,
          end_date: endDate,
          identifier: searchText,
        },
      },
    };
    AudienceController.exportCDPExcelFile(queryData).then((result) => {
      if (result?.data?.status == 204 || result?.data?.status == 200) {
        setExportSnackBar({
          title: CheckValueLocale(
            "segments_exported_excel_file_in_the_way",
            "",
            {},
            intl,
          ),
          message: CheckValueLocale("segments_success_csv", "", {}, intl),
          severity: "success",
          openSnackBar: true,
        });
      } else {
        setExportSnackBar({
          title: CheckValueLocale("segments_export_failed", "", {}, intl),
          severity: "error",
          message: CheckValueLocale("segments_failed_msg", "", {}, intl),
          openSnackBar: true,
        });
      }
    });
  };

  useEffect(() => {
    getSegmentList();
    localStorage.removeItem("importState");
  }, []);

  const handlelAudienceListExport = () => {
    setOpenExportPopUp(true);
  };

  const childProps = {
    audience,
    page,
    setPage,
    setSearchActivated,
    searchText,
    setSearchText,
    searchInput,
    setSearchInput,
    getAudience,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    pickerStartDate,
    setPickerStartDate,
    pickerEndDate,
    setPickerEndDate,
    applyFilter,
    searchActivated,
    handleShowSaveAndApplyModal,
    dateRangePickerParamsRef: dateRangePickerParamsRef,
    handleCloseSnackBar,
    showSnackBar,
    handleClearFilterParams,
    setFilterParams,
    filterParams,
    snackBarDetails,
    filterFields,
    getFiltersFileds,
    savedFilters,
    setSavedFilterId,
    savedFilterId,
    showDeleteFilterModal,
    handleCloseDeleteFilterModal,
    setShowDeleteFilterModal,
    filterName,
    assignActiveFilters,
    setAssignActiveFilters,
    allActiveFilters: getActiveParams(assignActiveFilters, filterFields), //showing active selected filters on top of the page
    handlelAudienceListExport,
    handleApplyExport,
    setExportSnackBar,
    files,
    setFiles,
    excelFileName,
    excelFileError,
    excelFileErrorMsg,
    isFileUploaded,
    fileName,
    displayUploadErrorMessage,
    handleImportAudience,
    handleResetImportPopUp,
    setOpenImportPopUp,
    enableProfileAudience,
    setEnableProfileAudience,
    enableUncomplyingAudience,
    setEnableUncomplyingAudience,
    openImportPopUp,
    getTitleImportExcel,
    handleExcelGuideData,
    handleCancelUploadFile,
    excelGuideData,
  };
  return (
    <Container maxWidth="xl">
      {openImportPopUp ? <AudienceImportExcelPopUp {...childProps} /> : null}
      <AudienceHeader
        isActive={loading}
        {...childProps}
        audienceData={audience?.data?.data}
      />

      {loading ? (
        <CircularLoading />
      ) : (
        <Box>
          <ActiveFilters {...childProps} isAudiencePage />
          <Box mb={3}>
            <AudienceList
              audience={audience}
              page={page}
              setPage={setPage}
              segmentList={segmentList}
              getAudience={getAudience}
              searchActivated={searchActivated}
              snackbar={handleAlert}
              handleAlert={handleAlert}
              searchText={searchText}
              startDate={startDate}
              endDate={endDate}
              identifier={searchText}
              filters={filterParams}
            />
          </Box>

          {pagination > 1 && audience.data?.data?.length > 0 && (
            <Pagination
              onChange={handleChangePage}
              className="pagination-monitor-list"
              count={pagination > 1 ? pagination : 1}
              page={page}
              variant="outlined"
            />
          )}
        </Box>
      )}
      <Snackbar
        open={alertData.openAlert}
        handleClose={() =>
          setAlertData({
            number: "",
            message: "",
            title: "",
            severity: "",
            openAlert: false,
          })
        }
        severity={alertData.severity}
        title={CheckValueLocale(alertData.title, "", {}, intl)}
        message={CheckValueLocale(
          alertData.message,
          "",
          {
            num: alertData.number,
          },
          intl,
        )}
      />

      <Snackbar
        open={exportSnackBar?.openSnackBar}
        severity={exportSnackBar?.severity}
        message={exportSnackBar?.message}
        title={exportSnackBar?.title}
        handleClose={handleCloseAudienceExportSnackBar}
      />
    </Container>
  );
};

export default Audience;
