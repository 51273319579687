import axios from "axios";
import Insights from "../api/insights";

var InsightsController = {};
let getGenesysCredentialsCancelToken,
  getMainChatCredentialsCancelToken,
  getIntercomCredentialsCancelToken,
  getWhatsappCredentialsCancelToken,
  getEmailCredentialsCancelToken,
  getGmailCredentialsCancelToken,
  getInsightsCredentialsCancelToken,
  getLinkedInCredentialsCancelToken,
  getGMBCredentialsCancelToken,
  getPrivateFacebookCredentialsCancelToken,
  getPrivateInstagramCredentialsCancelToken,
  getPrivateTwitterCredentialsCancelToken,
  getPublicFacebookCredentialsCancelToken,
  getPublicInstagramCredentialsCancelToken,
  getPublicTiktokCredentialsCancelToken,
  getPublicTwitterCredentialsCancelToken,
  getSocialMediaCredentialsCancelToken;

InsightsController.getInsightsCredentials = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
  };

  if (getInsightsCredentialsCancelToken) {
    getInsightsCredentialsCancelToken.cancel(
      "Operation canceled due to a new request.",
    );
  }
  getInsightsCredentialsCancelToken = axios.CancelToken.source();

  return new Promise(function (resolve) {
    Insights.getInsightsCredentials(
      queryData,
      getInsightsCredentialsCancelToken?.token,
    )
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

InsightsController.getEmailCredentials = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
  data_source_id,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
    data_source_id,
  };

  if (getEmailCredentialsCancelToken) {
    getEmailCredentialsCancelToken.cancel(
      "Operation canceled due to a new request.",
    );
  }
  getEmailCredentialsCancelToken = axios.CancelToken.source();

  return new Promise(function (resolve) {
    Insights.getEmailCredentials(
      queryData,
      getEmailCredentialsCancelToken?.token,
    )
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
InsightsController.getGmailCredentials = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
  data_source_id,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
    data_source_id,
  };

  if (getGmailCredentialsCancelToken) {
    getGmailCredentialsCancelToken.cancel(
      "Operation canceled due to a new request.",
    );
  }
  getGmailCredentialsCancelToken = axios.CancelToken.source();

  return new Promise(function (resolve) {
    Insights.getGmailCredentials(
      queryData,
      getGmailCredentialsCancelToken?.token,
    )
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
// public&private
InsightsController.getPublicTwitterCredentials = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
  data_source_id,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
    data_source_id,
  };

  if (getPublicTwitterCredentialsCancelToken) {
    getPublicTwitterCredentialsCancelToken.cancel(
      "Operation canceled due to a new request.",
    );
  }
  getPublicTwitterCredentialsCancelToken = axios.CancelToken.source();

  return new Promise(function (resolve) {
    Insights.getPublicTwitterCredentials(
      queryData,
      getPublicTwitterCredentialsCancelToken?.token,
    )
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
//private
InsightsController.getPrivateTwitterCredentials = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
  data_source_id,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
    data_source_id,
  };

  if (getPrivateTwitterCredentialsCancelToken) {
    getPrivateTwitterCredentialsCancelToken.cancel(
      "Operation canceled due to a new request.",
    );
  }
  getPrivateTwitterCredentialsCancelToken = axios.CancelToken.source();

  return new Promise(function (resolve) {
    Insights.getPrivateTwitterCredentials(
      queryData,
      getPrivateTwitterCredentialsCancelToken?.token,
    )
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

InsightsController.getSocialMediaCredentials = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
  data_source_id,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
    data_source_id,
  };

  if (getSocialMediaCredentialsCancelToken) {
    getSocialMediaCredentialsCancelToken.cancel(
      "Operation canceled due to a new request.",
    );
  }
  getSocialMediaCredentialsCancelToken = axios.CancelToken.source();

  return new Promise(function (resolve) {
    Insights.getSocialMediaCredentials(
      queryData,
      getSocialMediaCredentialsCancelToken?.token,
    )
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
InsightsController.getPublicFacebookCredentials = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
  data_source_id,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
    data_source_id,
  };

  if (getPublicFacebookCredentialsCancelToken) {
    getPublicFacebookCredentialsCancelToken.cancel(
      "Operation canceled due to a new request.",
    );
  }
  getPublicFacebookCredentialsCancelToken = axios.CancelToken.source();

  return new Promise(function (resolve) {
    Insights.getPublicFacebookCredentials(
      queryData,
      getPublicFacebookCredentialsCancelToken?.token,
    )
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
//private
InsightsController.getPrivateFacebookCredentials = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
  data_source_id,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
    data_source_id,
  };

  if (getPrivateFacebookCredentialsCancelToken) {
    getPrivateFacebookCredentialsCancelToken.cancel(
      "Operation canceled due to a new request.",
    );
  }
  getPrivateFacebookCredentialsCancelToken = axios.CancelToken.source();

  return new Promise(function (resolve) {
    Insights.getPrivateFacebookCredentials(
      queryData,
      getPrivateFacebookCredentialsCancelToken?.token,
    )
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
InsightsController.getPublicInstagramCredentials = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
  data_source_id,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
    data_source_id,
  };

  if (getPublicInstagramCredentialsCancelToken) {
    getPublicInstagramCredentialsCancelToken.cancel(
      "Operation canceled due to a new request.",
    );
  }
  getPublicInstagramCredentialsCancelToken = axios.CancelToken.source();

  return new Promise(function (resolve) {
    Insights.getPublicInstagramCredentials(
      queryData,
      getPublicInstagramCredentialsCancelToken?.token,
    )
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
InsightsController.getLinkedInCredentials = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
  data_source_id,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
    data_source_id,
  };

  if (getLinkedInCredentialsCancelToken) {
    getLinkedInCredentialsCancelToken.cancel(
      "Operation canceled due to a new request.",
    );
  }
  getLinkedInCredentialsCancelToken = axios.CancelToken.source();

  return new Promise(function (resolve) {
    Insights.getLinkedInCredentials(
      queryData,
      getLinkedInCredentialsCancelToken?.token,
    )
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
//private
InsightsController.getPrivateInstagramCredentials = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
  data_source_id,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
    data_source_id,
  };

  if (getPrivateInstagramCredentialsCancelToken) {
    getPrivateInstagramCredentialsCancelToken.cancel(
      "Operation canceled due to a new request.",
    );
  }
  getPrivateInstagramCredentialsCancelToken = axios.CancelToken.source();

  return new Promise(function (resolve) {
    Insights.getPrivateInstagramCredentials(
      queryData,
      getPrivateInstagramCredentialsCancelToken?.token,
    )
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
InsightsController.getWhatsappCredentials = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
  data_source_id,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
    data_source_id,
  };

  if (getWhatsappCredentialsCancelToken) {
    getWhatsappCredentialsCancelToken.cancel(
      "Operation canceled due to a new request.",
    );
  }
  getWhatsappCredentialsCancelToken = axios.CancelToken.source();

  return new Promise(function (resolve) {
    Insights.getWhatsappCredentials(
      queryData,
      getWhatsappCredentialsCancelToken?.token,
    )
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
InsightsController.getIntercomCredentials = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
  data_source_id,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
    data_source_id,
  };

  if (getIntercomCredentialsCancelToken) {
    getIntercomCredentialsCancelToken.cancel(
      "Operation canceled due to a new request.",
    );
  }
  getIntercomCredentialsCancelToken = axios.CancelToken.source();

  return new Promise(function (resolve) {
    Insights.getIntercomCredentials(
      queryData,
      getIntercomCredentialsCancelToken?.token,
    )
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

InsightsController.getMainChatCredentials = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
  data_source_id,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
    data_source_id,
  };

  if (getMainChatCredentialsCancelToken) {
    getMainChatCredentialsCancelToken.cancel(
      "Operation canceled due to a new request.",
    );
  }
  getMainChatCredentialsCancelToken = axios.CancelToken.source();

  return new Promise(function (resolve) {
    Insights.getMainChatCredentials(
      queryData,
      getMainChatCredentialsCancelToken?.token,
    )
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

//get active channels
InsightsController.getActiveChannels = () => {
  var queryData = {
    product_id: window.localStorage.cxm_id,
  };
  return new Promise(function (resolve) {
    Insights.getActiveChannels(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

InsightsController.getGMBCredentials = (
  start_date,
  end_date,
  filterParams,
  product_id,
  data_source_id,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    product_id,
    data_source_id,
  };

  if (getGMBCredentialsCancelToken) {
    getGMBCredentialsCancelToken.cancel(
      "Operation canceled due to a new request.",
    );
  }
  getGMBCredentialsCancelToken = axios.CancelToken.source();

  return new Promise(function (resolve) {
    Insights.getGMBCredentials(queryData, getGMBCredentialsCancelToken?.token)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

InsightsController.getGenesysCredentials = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
  data_source_id,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
    data_source_id,
  };

  if (getGenesysCredentialsCancelToken) {
    getGenesysCredentialsCancelToken.cancel(
      "Operation canceled due to a new request.",
    );
  }
  getGenesysCredentialsCancelToken = axios.CancelToken.source();

  return new Promise(function (resolve) {
    Insights.getGenesysCredentials(
      queryData,
      getGenesysCredentialsCancelToken?.token,
    )
      .then((response) => {
        resolve({
          data: response?.data?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

InsightsController.updateGenesysAudio = (
  product_id,
  data_source_id,
  monitor_id,
  job_id,
) => {
  var queryData = {
    product_id,
    data_source_id,
    monitor_id,
    job_id,
  };
  return new Promise(function (resolve) {
    Insights.updateGenesysAudio(queryData)
      .then((response) => {
        resolve({
          data: response?.data?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

///Export Public PPT
InsightsController.getPublicExportPPT = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
  data_source_id,
  language,
  exportPPT,
  datasourcePath,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
    data_source_id,
    language,
    export: exportPPT,
  };
  return new Promise(function (resolve) {
    Insights.getPublicExportPPT(queryData, datasourcePath)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

InsightsController.getDialectsSubDialects = () => {
  return new Promise(function (resolve) {
    Insights.getDialectsSubDialects()
      .then((response) => {
        resolve({
          data: response?.data?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

InsightsController.getPublicTiktokCredentials = (
  start_date,
  end_date,
  filterParams,
  product_id,
  data_source_id,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    product_id,
    data_source_id,
  };

  if (getPublicTiktokCredentialsCancelToken) {
    getPublicTiktokCredentialsCancelToken.cancel(
      "Operation canceled due to a new request.",
    );
  }
  getPublicTiktokCredentialsCancelToken = axios.CancelToken.source();

  return new Promise(function (resolve) {
    Insights.getPublicTiktokCredentials(
      queryData,
      getPublicTiktokCredentialsCancelToken?.token,
    )
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
InsightsController.getPolicy = (policy_type) => {
  return new Promise(function (resolve) {
    Insights.getPolicy(policy_type)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
InsightsController.getApplyPolicy = () => {
  return new Promise(function (resolve) {
    Insights.getApplyPolicy()
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

export default InsightsController;
