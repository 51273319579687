import React from "react";
import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { usePauseResumeReport } from "../../hooks/usePauseResumeReport";
import PopupModal from "components/popupModal";

const PauseResumeModal = ({
  id,
  title,
  paused,
  open,
  setOpen,
  setSnackbar,
}) => {
  const intl = useIntl();

  const { mutate: pauseResumeReport, isPending: pauseResumeLoading } =
    usePauseResumeReport();

  const handlePauseResumeReport = () => {
    pauseResumeReport(
      { id, queryData: { status: paused ? true : false } },
      {
        onSuccess: () => {
          setSnackbar({
            open: true,
            severity: "success",
            title: "",
            message: paused
              ? "report_resumed_successfully"
              : "report_paused_successfully",
          });
        },
        onError: () => {
          setSnackbar({
            open: true,
            severity: "error",
            title: "failed_error",
            message: "wrong_msg",
          });
        },
        onSettled: () => setOpen(false),
      },
    );
  };

  return (
    <PopupModal
      open={open}
      close={() => setOpen(false)}
      title={CheckValueLocale(
        paused ? "resume_report_title" : "pause_report_title",
        "",
        {},
        intl,
      )}
      body={
        <Box id="pause-resume-report-modal">
          <Typography>
            {CheckValueLocale(
              paused ? "resume_report_msg" : "pause_report_msg",
              "",
              { name: title },
              intl,
            )}
          </Typography>
          <Typography variant="h6">
            {CheckValueLocale(
              paused ? "resume_report_sub_msg" : "pause_report_sub_msg",
              "",
              {},
              intl,
            )}
          </Typography>
        </Box>
      }
      leftBtn={CheckValueLocale("cancel", "", {}, intl)}
      rightBtn={CheckValueLocale(
        paused ? "confirm_resume" : "confirm_rpause",
        "",
        {},
        intl,
      )}
      isRightBtnLoading={pauseResumeLoading}
      accept={() => handlePauseResumeReport()}
      warning={!paused}
      classeName="actions-buttons-shared"
      minWidth="600px"
    />
  );
};

export default PauseResumeModal;
