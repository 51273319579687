import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQueryClient } from "@tanstack/react-query";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Box, Card, Container, Typography, Divider } from "@mui/material";
import SnackBar from "components/snackBar.js";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CheckValueLocale } from "utils/helpers";
import DeleteAccountsListModal from "../DeleteAccountsListModal";
import { createAccountsListsValidationSchema } from "../accountsListsValidation/createAccountsListsValidationSchema";
import { useFormik } from "formik";
import CircularLoading from "components/circularProgress";
import LucButton from "shared/lucButton/lucButton";
import { isPublicSpecialCharactersOrEmojis } from "../../socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/specialCharOrEmojis";
import { useCreatAccountsList } from "../hooks/useCreatAccountsList";
import { useDeleteAccountsList } from "../hooks/useDeleteAccountsList";
import { useEditAccountsList } from "../hooks/useEditAccountsList";
import { useGetAccountsListById } from "../hooks/useGetAccountsListById";
import CreateAndEditAccountsListsForm from "./createAndEditAccountsListsForm";
import "./createAndEditAccountsLists.scss";

const CreateAccountsLists = () => {
  const location = useLocation();
  const { id } = useParams();
  const [getAccountListEnabled, setGetAccountListEnabled] = useState(
    id != null,
  );
  const { data, isPending, isError } = useGetAccountsListById(
    id,
    getAccountListEnabled,
  );
  const intl = useIntl();
  const isRTL = intl.locale === "ar";
  const navigate = useNavigate();
  const [snackBarData, setSnackBarData] = useState({
    message: "",
    severity: "",
    title: "",
    openSnackBar: false,
  });
  const queryClient = useQueryClient();
  const [mode, setMode] = useState("");
  useEffect(() => {
    if (location?.pathname?.includes("/add-accounts-list")) {
      setMode("create");
    } else if (location?.pathname?.includes("/edit-accounts-list")) {
      setMode("edit");
    }
  }, [location?.pathname]);
  const [isDataRestored, setIsDataRestored] = useState(false);
  useEffect(() => {
    if (!isPending && !isError && data?.data) {
      // Simulating data restoration before proceeding
      formik.setValues({
        name: data?.data?.data?.data?.attributes?.name || "",
        usernames: data?.data?.data?.data?.attributes?.usernames || [],
        description: data?.data?.data?.data?.attributes?.description || "",
      });
      setIsDataRestored(true);
    }
  }, [data, isPending, isError]);

  const [valuesChanged, setValuesChanged] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState({
    id: "",
    name: "",
    isLinkedToSavedFilter: false,
    open: false,
  });
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal({
      id: "",
      name: "",
      isLinkedToSavedFilter: false,
      open: false,
    });
  };

  const handleOpenDeleteModal = (accountsListData) => {
    setOpenDeleteModal({ ...accountsListData, open: true });
  };

  const handleSubmit = () => {
    mode === "create" ? handleNextCreate() : handleNextEdit();
  };

  const isCorrectFormation = (value) => {
    if (!value) return true;
    return !isPublicSpecialCharactersOrEmojis(value, undefined, true);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      usernames: [],
      description: "",
    },
    validationSchema: createAccountsListsValidationSchema(isCorrectFormation),
    validateOnChange: true,
    validateOnBlur: true,
    isInitialValid: mode == "edit",
    onSubmit: handleSubmit,
  });

  const deleteMutation = useDeleteAccountsList();
  const createMutation = useCreatAccountsList();
  const editMutation = useEditAccountsList();
  const handleDeleteAccountsList = () => {
    if (id) {
      setGetAccountListEnabled(false);
      deleteMutation?.mutate(id, {
        onSuccess: () => {
          let type = "success";
          let message = CheckValueLocale(
            "accounts_list_delete_success",
            "",
            { name: openDeleteModal?.name },
            intl,
          );

          setOpenDeleteModal({
            id: "",
            name: "",
            open: false,
          });
          type = "success";
          message = CheckValueLocale(
            "accounts_list_delete_success",
            "",
            { name: openDeleteModal?.name },
            intl,
          );
          queryClient?.invalidateQueries(["accountsList"]);
          navigate("/accounts-list/list", {
            state: { deleteAccountsListData: { type, message } },
            replace: true,
          });
        },
        onError: () => {
          setGetAccountListEnabled(true);
          const name = data?.data?.data?.data?.attributes?.name;
          setSnackBarData({
            message: CheckValueLocale(
              "accounts_list_delete_fail",
              "",
              {},
              intl,
            )?.replace("#", `${name}`),
            severity: "error",
            openSnackBar: true,
          });
        },
      });
    }
  };
  const handleCloseSnackBar = () => {
    setSnackBarData({ openSnackBar: false });
  };

  const areArraysEqual = (arr1, arr2) => {
    if (arr2) {
      if (arr1?.length !== arr2?.length) return false;
      const sortedArr1 = [...arr1]?.sort();
      const sortedArr2 = [...arr2]?.sort();
      return sortedArr1?.every((value, index) => value === sortedArr2[index]);
    }
    return false;
  };

  useEffect(() => {
    setValuesChanged(
      formik?.values?.name != data?.data?.data?.data?.attributes?.name ||
        formik?.values?.description !=
          data?.data?.data?.data?.attributes?.description ||
        !areArraysEqual(
          formik?.values?.usernames,
          data?.data?.data?.data?.attributes?.usernames,
        ),
    );
  }, [formik?.values]);

  const handleNextCreate = () => {
    const saveData = formik?.values;

    createMutation?.mutate(saveData, {
      onSuccess: (res, saveData) => {
        let type = "success";
        let message = CheckValueLocale(
          "accounts_list_added_success",
          "",
          {},
          intl,
        )?.replace("#", `${saveData?.name}`);

        navigate("/accounts-list/list", {
          state: { createAccountsListData: { type, message } },
          replace: true,
        });
      },
      onError: (res, saveData) => {
        if (
          res?.errorMsg?.response?.data?.exception?.message ===
          "Name has already been taken"
        ) {
          formik?.setErrors({
            name: CheckValueLocale("account_lists_existing_name", "", {}, intl),
          });
        } else {
          setSnackBarData({
            message: CheckValueLocale(
              "accounts_list_added_fail",
              "",
              {},
              intl,
            )?.replace("#", `${saveData?.name}`),
            severity: "error",
            openSnackBar: true,
          });
        }
      },
    });
  };

  const handleNextEdit = () => {
    const saveData = formik?.values;
    editMutation?.mutate(
      { id, saveData },
      {
        onSuccess: (res, { saveData }) => {
          let type = "success";
          let message = CheckValueLocale(
            "accounts_list_edited_success",
            "",
            {},
            intl,
          )?.replace("#", `${saveData?.name}`);

          navigate("/accounts-list/list", {
            state: { editAccountsListData: { type, message } },
            replace: true,
          });
        },
        onError: (res, { saveData }) => {
          if (
            res?.errorMsg?.response?.data?.exception?.message ===
            "Name has already been taken"
          ) {
            formik?.setErrors({
              name: CheckValueLocale(
                "account_lists_existing_name",
                "",
                {},
                intl,
              ),
            });
          } else {
            setSnackBarData({
              message: CheckValueLocale(
                "accounts_list_edited_fail",
                "",
                {},
                intl,
              )?.replace("#", `${saveData?.name}`),
              severity: "error",
              openSnackBar: true,
            });
          }
        },
      },
    );
  };

  return (
    <Container maxWidth="xl" className="create-accounts-list">
      {mode === "edit" && (isPending || !isDataRestored) ? (
        <CircularLoading />
      ) : (
        <>
          <Box className="create-accounts-list-header">
            {mode === "edit" ? (
              <DeleteAccountsListModal
                modalData={openDeleteModal}
                handleClose={handleCloseDeleteModal}
                handleDeleteAccountsList={handleDeleteAccountsList}
                buttonLoading={deleteMutation?.isPending}
              />
            ) : null}
            <LucButton
              type="secondary"
              size="large"
              variant="flat"
              onClick={() => {
                navigate(`/accounts-list/list`);
              }}
              startIcon={isRTL ? <ArrowForward /> : <ArrowBack />}
            >
              {CheckValueLocale("back_to_accounts_list", "", {}, intl)}
            </LucButton>
            <Typography variant="h5">
              {CheckValueLocale(
                mode === "create"
                  ? "create_accounts_list"
                  : "edit_accounts_list",
                "",
                {},
                intl,
              )}
            </Typography>
          </Box>
          {mode === "edit" ? <Divider className="edit-mode-divider" /> : null}

          <Card className="create-accounts-list-card">
            <Box className="create-accounts-list-body">
              <CreateAndEditAccountsListsForm
                pageType={mode === "create" ? "create_list" : "edit_list"}
                setSnackBarData={setSnackBarData}
                formik={formik}
              />
            </Box>
            <Box className="accounts-list-step-footer">
              {mode === "edit" ? (
                <Box className="accounts-list-btn-edit">
                  <LucButton
                    onClick={() => {
                      handleOpenDeleteModal({
                        id: data?.data?.data?.data?.id,
                        name: data?.data?.data?.data?.attributes?.name,
                        isLinkedToSavedFilter:
                          data?.data?.data?.data?.attributes
                            ?.is_linked_to_saved_filters,
                      });
                    }}
                    type="red"
                    className="delete-btn"
                    variant="outline"
                    endIcon={<FontAwesomeIcon icon={faTrash} />}
                  >
                    {CheckValueLocale("delete_accounts_list", "", {}, intl)}
                  </LucButton>
                </Box>
              ) : null}
              <Box className="accounts-list-btn-create">
                <LucButton
                  variant="flat"
                  className="cancel-btn"
                  onClick={() => navigate("/accounts-list/list")}
                  id="accounts-list-create-accounts-list-cancel-btn"
                >
                  {CheckValueLocale("cancel", "", {}, intl)}
                </LucButton>
                <LucButton
                  onClick={() => formik?.handleSubmit()}
                  loading={createMutation?.isPending || editMutation?.isPending}
                  disabled={
                    !formik?.isValid ||
                    !valuesChanged ||
                    createMutation?.isPending ||
                    editMutation?.isPending
                  }
                  id="accounts-list-create-accounts-list-next-btn"
                >
                  {CheckValueLocale(
                    mode === "create" ? "create_list" : "edit_list",
                    "",
                    {},
                    intl,
                  )}
                </LucButton>
              </Box>
            </Box>
          </Card>
        </>
      )}
      <SnackBar
        open={snackBarData?.openSnackBar}
        severity={snackBarData?.severity}
        message={snackBarData?.message}
        handleClose={handleCloseSnackBar}
      />
    </Container>
  );
};

export default CreateAccountsLists;
