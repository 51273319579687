import { useIntl } from "react-intl";
import { Box, Button } from "@mui/material";
import { faAlignLeft, faAlignRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomizedTooltip from "components/customizedTooltip";
import { CheckValueLocale } from "utils/helpers";
import "./textDirectionSwitcher.scss";

const TextDirectionSwitcher = (props) => {
  const intl = useIntl();
  const { sectionIndex, handleChangeDir, value, className } = props;

  return (
    <Box className={`multiple-languages-container ${className || ""}`}>
      <Box className="align-box">
        <CustomizedTooltip
          id="align-left-btn-tooltip"
          title={
            <Box className="tooltip-custom-new">
              {CheckValueLocale("left_align", "", {}, intl)}
            </Box>
          }
          arrow
          placement="bottom"
        >
          <Box>
            <Button
              className={`align-left-btn ${value === "ltr" ? "active" : value ? "" : "active"}`}
              variant="flat"
              size="small"
              onClick={() => {
                handleChangeDir("ltr", sectionIndex);
              }}
              id="align-left-btn"
            >
              <FontAwesomeIcon icon={faAlignLeft} />
            </Button>
          </Box>
        </CustomizedTooltip>
        <CustomizedTooltip
          id="right_align-btn-tooltip"
          title={
            <Box className="tooltip-custom-new">
              {CheckValueLocale("right_align", "", {}, intl)}
            </Box>
          }
          arrow
          placement="bottom"
        >
          <Box>
            <Button
              variant="flat"
              size="small"
              className={`align-right-btn ${value === "rtl" ? "active" : ""}`}
              onClick={() => {
                handleChangeDir("rtl", sectionIndex);
              }}
              id="align-right-btn"
            >
              <FontAwesomeIcon icon={faAlignRight} />
            </Button>
          </Box>
        </CustomizedTooltip>
      </Box>
    </Box>
  );
};

export default TextDirectionSwitcher;
