import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { checkKeyboardLanguage, CheckValueLocale } from "utils/helpers";
import { Box, Card, CardContent, Typography } from "@mui/material";
import "../../../../SurveyBuilder/surveyBuilder.scss";

const NpsQuestion = (props) => {
  const { question, questionNum, isCDP, isAlert } = props;

  const intl = useIntl();
  const [value, setValue] = useState({});
  const answers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

  let checkQuestion = isCDP ? question?.answer : question?.answer_list;

  const filteredAnswers = answers?.filter((answer) => answer === checkQuestion);

  useEffect(() => {
    if (question) {
      setValue({ title: question?.text, description: question?.description });
    }
  }, [question]);

  return (
    <Card className="cardStyle">
      <CardContent
        className={`card-body title-question card-direction-container ${isCDP || isAlert ? "card-body-cdp" : ""}`}
      >
        <Typography className="questionStyle audience" mb={4}>
          <span>{`${questionNum}.`}</span>
          {question?.question}
        </Typography>

        <Box>
          <Box className="radio-item">
            {answers?.map((item, index) => {
              return (
                <label className="radio-item" key={index}>
                  <input
                    disabled
                    type="radio"
                    style={{
                      direction: checkKeyboardLanguage(value?.description)
                        ? "rtl"
                        : "ltr",
                    }}
                  />
                  <Box
                    component="span"
                    className={
                      isCDP || isAlert
                        ? index == filteredAnswers
                          ? "choose choose-cdp"
                          : "choose"
                        : question?.constraints?.colored_theme
                          ? index === 7 || index === 8
                            ? "choose yellow"
                            : index === 9 || index === 10
                              ? "choose green"
                              : "choose red"
                          : "choose"
                    }
                  >
                    <Box>{item}</Box>
                  </Box>
                </label>
              );
            })}
          </Box>
          <Box className="most-like">
            <Typography component="p">
              {CheckValueLocale("unlikely", "", {}, intl)}
            </Typography>
            <Typography component="p">
              {CheckValueLocale("very_likely", "", {}, intl)}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default NpsQuestion;
