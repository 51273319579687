import { useMutation } from "@tanstack/react-query";
import { CompaniesServices } from "services/api/companiesServices";

export const useGetCompanySingleTweetsQuota = () => {
  return useMutation({
    mutationFn: ({
      tweetID,
      monitorID,
      productID,
      tweetTime,
      screenName,
      startDate,
      endDate,
    }) => {
      const queryData = {
        tweet_id: tweetID,
        monitor_id: monitorID,
        product_id: productID,
        tweet_time: tweetTime,
        screen_name: screenName,
        start_date: startDate,
        end_date: endDate,
      };
      return CompaniesServices.getCompanySingleTweetsQuota({
        queryData,
        tweetId: tweetID,
      });
    },
  });
};
