import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

export const useGenerateReportsListHead = () => {
  const intl = useIntl();

  return [
    { body: CheckValueLocale("report_name", "", {}, intl), width: "12%" },
    { body: CheckValueLocale("report_type", "", {}, intl), width: "11.99%" },
    { body: CheckValueLocale("report_product", "", {}, intl), width: "10.53%" },
    {
      body: CheckValueLocale("report_data_source", "", {}, intl),
      width: "10.96%",
    },
    {
      body: CheckValueLocale("report_frequency", "", {}, intl),
      width: "8.56%",
    },
    {
      body: CheckValueLocale("report_next_delivery", "", {}, intl),
      width: "13.78%",
    },
    { body: CheckValueLocale("creator", "", {}, intl), width: "12.7%" },
    { body: CheckValueLocale("status", "", {}, intl), width: "10.36%" },
    { body: null, width: "5.74%" },
  ];
};
