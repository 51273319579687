import React, { useState } from "react";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faArrowDown,
  faPenToSquare,
} from "@fortawesome/pro-regular-svg-icons";
import {
  CheckValueLocale,
  classNames,
  handlelUserRoles,
  truncate,
} from "utils/helpers/index.js";

const SavedReplyCard = ({
  item,
  isInserted,
  handleOnInsert,
  setNewRepPopup,
  collection,
  setDelPopup,
}) => {
  const [expanded, setExpanded] = useState(false);

  const intl = useIntl();

  return (
    <Box key={item?.id} className="rep-bar-contant-card">
      <Box className="contant-title">{item?.title}</Box>
      <Box className="contant-contant">
        <Typography component="span">
          {expanded ? item?.text : truncate(item?.text, 250)}
        </Typography>
        {item?.text?.length > 250 ? (
          <Typography
            component="span"
            className="see-more-less-btn"
            onClick={() => setExpanded((prev) => !prev)}
          >
            {` ${CheckValueLocale(expanded ? "see_less" : "see_more", "", {}, intl)}`}
          </Typography>
        ) : null}
      </Box>
      <Box className="contant-footer">
        <Button
          className={classNames(
            "inset-btn",
            isInserted && "inserted-replay-button",
          )}
          onClick={() => handleOnInsert(item)}
          disabled={isInserted}
          id="insert-saved-replies-btn"
        >
          <FontAwesomeIcon icon={faArrowDown} />
          {CheckValueLocale(
            isInserted ? "suggestion_inserted" : "insert",
            "",
            {},
            intl,
          )}
        </Button>
        <Box className="contant-footer-btns">
          {handlelUserRoles("SAVED_REPLIES", "EDIT_SAVED_REPLY") ||
          handlelUserRoles("SAVED_REPLIES", "FULL_ACCESS") ? (
            <Tooltip
              title={CheckValueLocale("dashboard_edit", "", {}, intl)}
              placement="bottom"
              arrow
            >
              <Box>
                <FontAwesomeIcon
                  icon={faPenToSquare}
                  onClick={(event) => {
                    event?.stopPropagation?.();
                    setNewRepPopup({
                      open: true,
                      id: item?.id,
                      isDuplicate: false,
                      title: item?.title,
                      contant: item?.text,
                      collection_id: collection?.id,
                      isEdit: true,
                      initialData: {
                        title: item?.title,
                        contant: item?.text,
                        collection_id: collection?.id,
                      },
                      ai: false,
                      loading: false,
                      done: false,
                    });
                  }}
                />
              </Box>
            </Tooltip>
          ) : null}
          {handlelUserRoles("SAVED_REPLIES", "DELETE_SAVED_REPLY") ||
          handlelUserRoles("SAVED_REPLIES", "FULL_ACCESS") ? (
            <Tooltip
              title={CheckValueLocale("dashboard_delete", "", {}, intl)}
              placement="bottom"
              arrow
            >
              <Box>
                <FontAwesomeIcon
                  icon={faTrash}
                  onClick={() =>
                    setDelPopup({
                      open: true,
                      item: { ...item },
                    })
                  }
                />
              </Box>
            </Tooltip>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default SavedReplyCard;
