import React, { useContext, useState, useEffect, useMemo, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import MonitorsController from "services/controllers/monitorsController";
import mqttRabbitMQResponce from "services/controllers/mqttRabbitMQResponce";
import {
  convertTwitterToXPlatform,
  deleteCookie,
  handlelUserRoles,
  syncUnixToUTC,
} from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faNewspaper } from "@fortawesome/pro-regular-svg-icons";
import xPlatform from "images/social-icons/x-platform-white-icon.svg";
import moment from "moment";
import LiveDashboardController from "services/controllers/liveDashboardController";
import UserAccount from "services/controllers/userAccountController";
import LanguageContext from "context/Context";
import { data } from "pages/Monitors/Components/sidebar/dataSourceTabs";
import NameKeyWidget from "pages/createLiveDashboard/shard/nameKeyWidget";
import { arrayMoveImmutable } from "array-move";
import { resetChatMessages } from "utils/redux/features/AiAgentChat/AiAgentChatSlice";
import { useDispatch } from "react-redux";
import { useMonitorLiveDashboardDetails } from "pages/createLiveDashboard/liveDashboardHooks/useMonitorLiveDashboardDetails";
import dayjs from "dayjs";
import { useGetDataSources } from "pages/createLiveDashboard/liveDashboardHooks/useGetDataSources";
import useHandleSocket from "services/controllers/handleSocket";

const DashboardFunction = () => {
  const urlParams = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dateRangePickerParamsRef = useRef();
  const dispatch = useDispatch();
  const [liveDashboardName, setLiveDashboardName] = useState("");
  const [response, setResponse] = useState([]);
  const [responseSocket, setResponseSocket] = useState([]);
  const [startDate, setStartDate] = useState(moment().subtract(6, "d").unix());
  const [endDate, setEndDate] = useState(moment().unix());
  const [filterDate, setFilterDate] = useState("");

  const [resetAllFields, setResetAllFields] = useState(0);
  const [singleTweetAnalysis, setSingleTweetAnalysis] = useState(false);

  const [lastUpdated, setLastUpdated] = useState(moment());
  const { data: dataSources } = useGetDataSources();
  const {
    data: monitorLiveDashboardDetails,
    isLoading: loadingMonitorLiveDashboardDetails,
    isError: monitorLiveDashboardDetailsIsError,
    error: monitorLiveDashboardDetailsError,
    refetch: refetchMonitorLiveDashboardDetails,
  } = useMonitorLiveDashboardDetails(
    {
      monitor_id: urlParams?.monitorId,
      dashboard_user_id: urlParams?.userDashboardId,
    },
    true,
  );

  const [selectedWidgets, setSelectedWidgets] = useState(null);
  const [xPlatformWidgetTypes, setXPlatformWidgetTypes] = useState(null);
  const [facebookWidgetTypes, setFacebookWidgetTypes] = useState(null);
  const [instagramWidgetTypes, setInstagramWidgetTypes] = useState(null);
  const [newsBlogsWidgetTypes, setNewsBlogsWidgetTypes] = useState(null);
  const [orderFlag, setOrderFlag] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [snackBar, setSnackBar] = useState({
    message: "",
    severity: "",
    title: "",
    open: false,
  });
  const languageContext = useContext(LanguageContext);
  const [showEditBtn, setShowEditBtn] = useState(true);
  const [isDashboardDeleted, setIsDashboardDeleted] = useState(false);
  const [isDashboardNotAccess, setIsDashboardNotAccess] = useState(false);
  const [startSubscribe, setStartSubscribe] = useState(false);
  const [startUnSubscribe, setStartUnSubscribe] = useState(false);
  const [errorClosedSocket, setErrorClosedSocket] = useState(false);
  const [stats, setStats] = useState({
    lang: languageContext.lang,
  });
  const monitorId = urlParams?.monitorId;
  const monitorType = urlParams?.monitorType;
  const userDashboardId = urlParams?.userDashboardId;

  const preLoaderTrackerDataSources = [parseInt(monitorId)];
  const [countViewersPublic, setCountViewersPublic] = useState(0);
  const isLiveDashboardPublic = window?.location?.href?.includes(
    "/live-dashboard/public/",
  );
  const userToken = localStorage?.getItem("user_token");
  const queryURL = isLiveDashboardPublic
    ? `cable?monitor_id=${monitorId}&user_id=${userDashboardId}`
    : `cable?monitor_id=${monitorId}&user_id=${userDashboardId}&luc_authorization=${userToken}`;
  const [retryConnect, setRetryConnect] = useState(0);
  const { subscribeToChannel, unsubscribeToChannel, cable } = useMemo(
    () => useHandleSocket(queryURL, setResponseSocket),
    [monitorId, userDashboardId, retryConnect],
  );

  const handleErrorSnackBar = () => {
    setSnackBar({
      message: "try_again_error_message",
      severity: "error",
      title: "failed_error_message",
      open: true,
    });
  };
  const closeSnackBar = () => {
    setSnackBar({
      message: "",
      severity: "",
      title: "",
      open: false,
    });
  };
  cable.onclose = () => {
    if (window.location.host !== "cxm.lucidya.com") {
      console.log("WebSocket connection closed");
    }
    setStartUnSubscribe(false); // to reset unSubscribe
    setStartSubscribe(false); // to reset subscribe

    if (retryConnect < 3) {
      setRetryConnect((prev) => prev + 1); // to retry 3 times for connect socket
    } else {
      setErrorClosedSocket(true); // when don't connect after 3 times, it will appear alert
    }
  };

  let isSafari =
    navigator.userAgent.indexOf("Safari") != -1 &&
    navigator.userAgent.indexOf("Chrome") == -1;
  const handleSwitchLang = () => {
    let lang = "";
    if (stats.lang === "ar") {
      lang = "en";
    } else {
      lang = "ar";
    }
    if (startUnSubscribe) {
      handleUnsubscribe(filterDate);
    }
    if (isLiveDashboardPublic) {
      setStats({ ...stats, lang: lang });
      languageContext.switchLang(lang);
    } else {
      UserAccount.setUserLang(lang).then((res) => {
        if (res?.errorMsg?.response?.status === 401) {
          localStorage.clear();
          dispatch(resetChatMessages());
          deleteCookie("email");
          window.location.href = "/login";
        }
        if (res?.data?.status == 200) {
          setStats({ ...stats, lang: lang });
          languageContext.switchLang(lang);
        }
      });
    }
  };
  const handleSwitchLangSafari = async () => {
    let lang = "";
    if (stats.lang === "ar") {
      lang = "en";
    } else {
      lang = "ar";
    }
    if (startUnSubscribe) {
      handleUnsubscribe(filterDate);
    }
    if (isLiveDashboardPublic) {
      setStats({ ...stats, lang: lang });
      languageContext.switchLang(lang);
    } else {
      await UserAccount.setUserLang(lang).then((res) => {
        if (res?.data?.status == 200) {
          setStats({ ...stats, lang: lang });
          languageContext.switchLang(lang);
        }
      });
    }

    window.location.reload();
  };
  let changeLang = isSafari ? handleSwitchLangSafari : handleSwitchLang;

  const getSourceName = (id) =>
    (dataSources || [])?.find((singleDataSource) => singleDataSource?.id == id)
      ?.source;

  const getSourceId = (name) =>
    (dataSources || [])?.find(
      (singleDataSource) => singleDataSource?.source == name,
    )?.id;

  const unixRangeToDateRange = (unixRang) => {
    const startDate = dayjs?.unix(unixRang?.split("-")?.[0]);
    const endDate = dayjs?.unix(unixRang?.split("-")?.[1]);
    return `${dayjs(startDate).format("DD/MM/YYYY")} - ${dayjs(endDate).format("DD/MM/YYYY")}`;
  };

  const unixDate = {
    start: startDate,
    end: endDate,
  };

  const getSocialIcon = (type) => {
    const SocialIcons = {
      FACEBOOK: <FontAwesomeIcon className="facebook" icon={faFacebook} />,
      TWITTER: (
        <img src={xPlatform} alt="x-platform-logo" className="x-platform" />
      ),
      INSTAGRAM: <FontAwesomeIcon className="instagram" icon={faInstagram} />,
      TALKWALKER: <FontAwesomeIcon className="news-blogs" icon={faNewspaper} />,
    };
    return SocialIcons[type];
  };

  const handleSubscribe = (newFilterDate) => {
    const identifier = {
      channel: "LiveDashboardsChannel",
      filter_date: newFilterDate,
      language: stats?.lang,
    };
    subscribeToChannel(identifier);
    setResetAllFields((prev) => prev + 1); // to rest the all widgets with subscribe
  };
  const handleUnsubscribe = (oldFilterDate) => {
    setStartUnSubscribe(false); // to check if It happened subscribe before.
    const identifier = {
      channel: "LiveDashboardsChannel",
      filter_date: oldFilterDate,
      language: stats?.lang,
    };
    unsubscribeToChannel(identifier);
  };

  //this useEffect to handle web socket
  useEffect(() => {
    //"welcome" to check if the socket connection is opened.
    if (responseSocket?.type === "welcome") setStartSubscribe(true);
    //"confirm_subscription" to check if start subscription.
    else if (responseSocket?.type === "confirm_subscription")
      setStartUnSubscribe(true);
    else if (
      responseSocket?.type === "disconnect" &&
      responseSocket?.reason === "unauthorized"
    ) {
      localStorage.removeItem("challengeKey"); //to recaption with the public live dashboard if the Verify Key  is expired
    } else if (responseSocket?.message?.message) {
      setResponse(responseSocket?.message?.message);
    }

    //to handle count of public viewers
    if (responseSocket?.message?.event === "public_viewers_count")
      setCountViewersPublic(responseSocket?.message?.data);
    //to handle count of public viewers
    if (
      responseSocket?.message?.event === "Live Dashboard updated" ||
      responseSocket?.message?.event === "Dashboard Not Available" ||
      responseSocket?.message?.event === "Dashboard deleted"
    ) {
      if (isLiveDashboardPublic) {
        localStorage.removeItem("challengeKey");
        window.location.reload();
      } else refetchMonitorLiveDashboardDetails();
    }
    setLastUpdated(moment()); // to show time for last update
  }, [responseSocket]);

  useEffect(() => {
    // Clean up the WebSocket connection on component unmount
    return () => {
      cable?.close();
    };
  }, []);

  useEffect(() => {
    // --startSubscribe-- to check if connection is opened.
    // --!startUnSubscribe-- to check if It happened UnSubscribe before.
    // cable?.readyState to check if we can start subscribe
    if (
      startSubscribe &&
      !startUnSubscribe &&
      cable?.readyState === WebSocket.OPEN
    ) {
      handleSubscribe(filterDate);
      setRetryConnect(0); // to reset retry count
    }
  }, [filterDate, startSubscribe, stats?.lang, cable?.readyState]);

  const applyFilter = (startDate, endDate, dateRangeString) => {
    setStartDate(startDate);
    setEndDate(endDate);
    if (startUnSubscribe) {
      handleUnsubscribe(filterDate);
    }
    const { sidebarLabelValue } = dateRangePickerParamsRef.current || {};
    dateRangeString = sidebarLabelValue || dateRangeString;
    let newFilterDate = dateRangeString;
    if (
      dateRangeString === "custom_dates" ||
      dateRangeString === "date_till_now"
    ) {
      newFilterDate = `${syncUnixToUTC(startDate, "start")}-${syncUnixToUTC(
        endDate,
        "end",
      )}`;
    } else {
      if (
        dateRangeString ===
        unixRangeToDateRange(monitorLiveDashboardDetails?.[0]?.custom_date)
      )
        newFilterDate = "custom_dates";
    }
    setFilterDate(newFilterDate);
  };

  const removeRepeatedItems = (arr) =>
    arr.filter((widget, index) => arr.indexOf(widget) === index);

  const addEngagementSection = (arr) =>
    arr?.includes("engagements") ? arr : [...arr, "engagements"];

  const addDashboardStatisticWidgets = (widgets, dataSourceName) => {
    return [
      ...widgets,
      {
        id: "-1",
        name: dataSourceName + "__EngagementsPage__dashboard_data",
        section: "engagements",
        order: "-1",
      },
    ];
  };

  const sortWidgets = (oldIndex, newIndex, widgets, setWidgetTypes) => {
    const dashboardDataWidget = widgets?.find((widget) => widget?.id === "-1");
    const widgetsWithoutDashboardDataWidget = widgets?.filter(
      (widget) => widget?.id !== "-1",
    );
    const newWidgetOrderArr = arrayMoveImmutable(
      widgetsWithoutDashboardDataWidget,
      oldIndex,
      newIndex,
    )?.map((widget, i) => ({ ...widget, order: i + 1 }));
    setOrderFlag(true);
    setWidgetTypes([...newWidgetOrderArr, dashboardDataWidget]);
  };

  const capitalizeFirstLetter = (str) =>
    str?.[0]?.toUpperCase() + str?.slice(1);

  useEffect(() => {
    if (monitorLiveDashboardDetailsIsError) {
      if (monitorLiveDashboardDetailsError?.errorMsg === "Dashboard Deleted")
        setIsDashboardDeleted(true);
      else {
        //"No Access"
        setIsDashboardNotAccess(true);
        setLiveDashboardName(
          monitorLiveDashboardDetailsError?.data?.dashboard_name || "",
        );
      }
    } else {
      setIsDashboardNotAccess(false);
      setIsDashboardDeleted(false);
    }
  }, [monitorLiveDashboardDetailsIsError]);

  const handleLiveDashboardDetails = () => {
    if (Array.isArray(monitorLiveDashboardDetails)) {
      const dataSourcesListArr = monitorLiveDashboardDetails
        ?.map((dataSource) => dataSource?.data_sources[0])
        .map((dataSource) => ({
          data_source_id: dataSource?.data_source_id,
          widgets: addDashboardStatisticWidgets(
            dataSource?.widgets,
            capitalizeFirstLetter(
              getSourceName(dataSource?.data_source_id)?.toLowerCase(),
            ),
          ),
          section: addEngagementSection(
            removeRepeatedItems(
              dataSource?.widgets?.map((widget) => widget?.section),
            ),
          ),
          count: addEngagementSection(
            removeRepeatedItems(
              dataSource?.widgets?.map((widget) => widget?.section),
            ),
          )?.length,
        }));
      setLiveDashboardName(monitorLiveDashboardDetails?.[0]?.dashboard_name);
      setSelectedWidgets(dataSourcesListArr);

      const isLucidyaUser =
        localStorage.getItem("is_lucidya_account") == 1 ? true : false;

      setShowEditBtn(
        (isLucidyaUser ||
          handlelUserRoles("SM", "DESTROY_DASHBOARD") || // "DESTROY_DASHBOARD" --> this is only used with the manager
          (monitorLiveDashboardDetails?.[0]?.user_id ==
            localStorage.getItem("user_id") &&
            handlelUserRoles("SM", "EDIT_DASHBOARD"))) &&
          !isLiveDashboardPublic,
      );

      setSingleTweetAnalysis(
        monitorLiveDashboardDetails?.[0]?.single_tweet_analysis_enabled,
      );
    }
  };

  const editLiveDashboardHandler = () => {
    setIsActionLoading(true);
    LiveDashboardController.editLiveDashboard(getQueryData()).then((res) =>
      handleResponse(res),
    );
  };

  const getQueryData = () => {
    let dataSourceWidgets = [];
    if (xPlatformWidgetTypes) {
      dataSourceWidgets = [
        ...dataSourceWidgets,
        {
          data_source_id: getSourceId("TWITTER")?.toString(),
          widgets: xPlatformWidgetTypes
            ?.filter((widget) => widget?.id !== "-1")
            ?.map((widget) => ({
              widget_id: widget?.id,
              order: widget?.order,
            })),
        },
      ];
    }
    if (facebookWidgetTypes) {
      dataSourceWidgets = [
        ...dataSourceWidgets,
        {
          data_source_id: getSourceId("FACEBOOK")?.toString(),
          widgets: facebookWidgetTypes
            ?.filter((widget) => widget?.id !== "-1")
            ?.map((widget) => ({
              widget_id: widget?.id,
              order: widget?.order,
            })),
        },
      ];
    }
    if (instagramWidgetTypes) {
      dataSourceWidgets = [
        ...dataSourceWidgets,
        {
          data_source_id: getSourceId("INSTAGRAM")?.toString(),
          widgets: instagramWidgetTypes
            ?.filter((widget) => widget?.id !== "-1")
            ?.map((widget) => ({
              widget_id: widget?.id,
              order: widget?.order,
            })),
        },
      ];
    }
    if (newsBlogsWidgetTypes) {
      dataSourceWidgets = [
        ...dataSourceWidgets,
        {
          data_source_id: getSourceId("TALKWALKER")?.toString(),
          widgets: newsBlogsWidgetTypes
            ?.filter((widget) => widget?.id !== "-1")
            ?.map((widget) => ({
              widget_id: widget?.id,
              order: widget?.order,
            })),
        },
      ];
    }
    return {
      monitor_user_live_dashboard: {
        monitor_model_id: urlParams?.monitorId,
        dashboard_user_id: +urlParams?.userDashboardId,
        sharing_option_id:
          monitorLiveDashboardDetails?.[0]?.dashboard_sharing_id,
        dashboard_name: liveDashboardName,
        data_sources: dataSourceWidgets,
      },
    };
  };
  const handleResponse = (res) => {
    if (res?.status_code === 201 || res?.status_code === 200) {
      window.location.reload();
    } else {
      handleErrorSnackBar();
      setIsActionLoading(false);
    }
  };

  const getWidgetsSoretedArr = (WidgetsArr) =>
    WidgetsArr?.sort(
      (first, sec) => parseFloat(first.order) - parseFloat(sec.order),
    );

  const redirectToMonitor = (urlParams, selectedWidgets) => {
    const monitor_id = urlParams?.monitorId;
    const monitorDataSources =
      getSourceName(selectedWidgets?.[0]?.data_source_id) ||
      monitorLiveDashboardDetails?.data?.data_source?.source; //with no access error to can back monitor page
    const dataSourceName = convertTwitterToXPlatform(monitorDataSources);
    data.forEach((i) => {
      //'data' here is static array of object for all avaliable tabs for each datasource as per the its monitorType
      if (Object.keys(i)[0] == `${monitorDataSources}_${monitorType}`) {
        navigate(
          `/monitor/${monitorType}/${
            dataSourceName === "TALKWALKER" || dataSourceName === "NEWSBLOGS"
              ? "NEWSBLOGS"
              : dataSourceName
          }/${Object.values(i)[0][0]?.analytics[0]?.tabName}/${monitor_id}`,
          {
            state: location?.state,
            tabs: `${monitorDataSources}_${monitorType}`,
          },
        );
      }
    });
  };

  const getNameKeyWidget = (name) => {
    const widgetNameArr = name?.split("__");
    const widgetName = widgetNameArr?.[widgetNameArr?.length - 1];
    const widgetNameKey = NameKeyWidget(name, monitorType);
    return widgetNameKey || widgetName;
  };

  return {
    startDate,
    endDate,
    unixDate,
    response,
    selectedWidgets,
    monitorLiveDashboardDetails,
    monitorLiveDashboardDetailsError,
    loadingMonitorLiveDashboardDetails,
    lastUpdated,
    getSocialIcon,
    resetAllFields,
    preLoaderTrackerDataSources,
    singleTweetAnalysis,
    applyFilter,
    handleLiveDashboardDetails,
    xPlatformWidgetTypes,
    setXPlatformWidgetTypes,
    facebookWidgetTypes,
    setFacebookWidgetTypes,
    instagramWidgetTypes,
    setInstagramWidgetTypes,
    newsBlogsWidgetTypes,
    setNewsBlogsWidgetTypes,
    orderFlag,
    setOrderFlag,
    sortWidgets,
    editLiveDashboardHandler,
    closeSnackBar,
    snackBar,
    isActionLoading,
    getWidgetsSoretedArr,
    getSourceName,
    liveDashboardName,
    changeLang,
    redirectToMonitor,
    getNameKeyWidget,
    showEditBtn,
    isDashboardDeleted,
    isDashboardNotAccess,
    unixRangeToDateRange,
    countViewersPublic,
    dataSources,
    errorClosedSocket,
    dateRangePickerParamsRef,
  };
};

export default DashboardFunction;
