import moment from "moment";
import { isEmptyArray } from "utils/helpers";
import { handleXlsData, handleSortedCase } from "../excelCommonFn";

const engagements_tw_Keywords = (
  response,
  lang,
  themesTranslations,
  isHourlyFormatObj,
) => {
  if (isEmptyArray(response)) {
    return [];
  } else {
    var commentsVolume = [],
      commentsInteractions = [],
      reachFunnel = [],
      reachFunnelLegacy = [],
      sentimentAnalysis = [],
      theme = [],
      subTheme = [],
      themesTrend = [],
      subThemesTrend = [],
      contentStyles = [],
      associatedTopics = [],
      topKeywords = [],
      topHashtags = [],
      topImages = [],
      topVideos = [],
      topPosts = [],
      topLanguages = [],
      dialects = [],
      subDialects = [],
      topCountries = [],
      topCities = [],
      genderDistribution = [],
      accountTypes = [],
      topEngagers = [],
      topSources = [],
      topVerifiedEngagers = [],
      topInfleuncers = [],
      allSheetData = [];
    //_______________________________________________________________________________
    response?.map((i) => {
      Object.entries(i).forEach(([key, value]) => {
        switch (key) {
          case "commentsVolume":
            value?.map((i) => {
              commentsVolume.push({
                [lang.date]: isHourlyFormatObj?.isCommentsVolumeHourlyFormat
                  ? moment.unix(i?.name)?.utc()?.format("hh") +
                    lang?.[
                      moment.unix(i?.name)?.utc()?.format("A")
                    ]?.toLowerCase()
                  : moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.posts_word]: i?.value,
              });
            });
            allSheetData.push([commentsVolume, lang.posts_volume]);
            break;
          // _______________________________________________________________________________
          case "accountTypes":
            value?.map((i) => {
              accountTypes.push({
                [lang.account_types]: i?.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData.push([accountTypes, lang.account_types]);
            break;
          // _______________________________________________________________________________
          case "topInfleuncers":
            value?.map((i) => {
              topInfleuncers.push({
                [lang.top_infleuncers]: i?.name,
                [lang.followers]: i?.followers_count,
                [lang.engagements]: i?.total_engagements,
              });
            });
            allSheetData.push([topInfleuncers, lang.top_infleuncers]);
            break;
          // _______________________________________________________________________________
          case "reachFunnel":
            value?.map((i) => {
              reachFunnel.push({
                [lang.reach_funnel]: lang[i?.name],
                [lang.original]: i?.value[1],
                [lang.Retweets]: i?.value[0],

                [lang.total]: parseInt(i?.value[0]) + parseInt(i?.value[1]),
              });
            });
            allSheetData.push([reachFunnel, lang.reach_funnel]);
            break;
          // _______________________________________________________________________________
          case "topVerifiedEngagers":
            value?.map((i) => {
              topVerifiedEngagers?.push({
                [lang.users]: i?.name,
                [lang.followers]: i?.followers_count
                  ? i?.followers_count
                  : i?.stats_count,
                [lang.engagements]: i?.total_engagements,
              });
            });
            allSheetData?.push([
              topVerifiedEngagers,
              lang?.top_verified_engagers,
            ]);
            break;
          // _______________________________________________________________________________
          case "topEngagers":
            value?.map((i) => {
              topEngagers?.push({
                [lang.users]: i?.name,
                [lang.followers]: i?.followers_count,
                [lang.engagements]: i?.total_engagements,
              });
            });
            allSheetData?.push([topEngagers, lang?.top_engagers]);
            break;
          // _______________________________________________________________________________
          case "topSources":
            value?.map((i) => {
              if (i.name && i.value) {
                topSources?.push({
                  [lang.source]: i?.name,
                  [lang.number]: i?.value,
                });
              }
            });
            allSheetData?.push([topSources, lang?.top_sources]);
            break;
          //_______________________________________________________________________________
          case "genderDistribution":
            value?.map((i) => {
              genderDistribution?.push({
                [lang.gender_distribution]: i?.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData?.push([genderDistribution, lang?.gender_distribution]);
            break;
          //_______________________________________________________________________________
          case "topCountries":
            value?.map((i) => {
              topCountries?.push({
                [lang.countries]: i?.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData?.push([topCountries, lang?.top_countries]);
            break;

          // _______________________________________________________________________________

          case "reachFunnelLegacy":
            value?.map((i) => {
              reachFunnelLegacy?.push({
                [lang.reach_funnel]: lang[i?.name],
                [lang.Retweets]: i?.value[0],
                [lang.original]: i?.value[1],
                [lang.total]: parseInt(i?.value[0]) + parseInt(i?.value[1]),
              });
            });
            allSheetData?.push([reachFunnelLegacy, lang?.reach_funnel]);
            break;
          //_______________________________________________________________________________

          case "commentsInteractions":
            value?.map((i) => {
              commentsInteractions?.push({
                [lang.date]:
                  isHourlyFormatObj?.isCommentsInteractionsHourlyFormat
                    ? moment.unix(i?.name)?.utc()?.format("hh") +
                      lang?.[
                        moment.unix(i?.name)?.utc()?.format("A")
                      ]?.toLowerCase()
                    : moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.likes]: i?.value[0],
                [lang.comments]: i?.value[1],
                [lang.retweets]: i?.value[2],
                [lang.total]: i?.value[0] + i?.value[1] + i?.value[2],
              });
            });
            allSheetData?.push([
              commentsInteractions,
              lang?.posts_interactions,
            ]);
            break;
          //_______________________________________________________________________________

          case "contentStyles":
            value?.map((i) => {
              contentStyles?.push({
                [lang.comments_content_style_kw]: i?.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData?.push([
              contentStyles,
              lang?.comments_content_style_kw,
            ]);
            break;
          //_______________________________________________________________________________

          case "associatedTopics":
            value?.map((i) => {
              associatedTopics?.push({
                [lang.topic]: i?.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData?.push([
              associatedTopics,
              lang?.posts_associated_topics,
            ]);
            break;
          //_______________________________________________________________________________

          case "sentimentAnalysis":
            value?.negative?.map((i, index) => {
              if (i.name == value?.neutral[index]?.name) {
                let total =
                  value?.neutral[index]?.value +
                  value?.positive[index]?.value +
                  value?.negative[index]?.value;
                sentimentAnalysis.push({
                  [lang.date]:
                    isHourlyFormatObj?.isSentimentAnalysisHourlyFormat
                      ? moment.unix(i?.name)?.utc()?.format("hh") +
                        lang?.[
                          moment.unix(i?.name)?.utc()?.format("A")
                        ]?.toLowerCase()
                      : moment.unix(i?.name).utc().format("YYYY-MM-DD"),

                  [lang.neutral]:
                    total !== 0
                      ? ((value?.neutral[index]?.value / total) * 100).toFixed(
                          2,
                        ) + "%"
                      : 0,

                  [lang.positive]:
                    total !== 0
                      ? ((value?.positive[index]?.value / total) * 100).toFixed(
                          2,
                        ) + "%"
                      : 0,

                  [lang.negative]:
                    total !== 0
                      ? ((value?.negative[index]?.value / total) * 100).toFixed(
                          2,
                        ) + "%"
                      : 0,
                });
              }
            });
            allSheetData.push([
              sentimentAnalysis,
              lang.posts_sentiment_analysis,
            ]);
            break;

          //_______________________________________________________________________________

          case "themeXlxs":
            //themes
            let mainThemes = value?.theme_analysis?.main_themes_view;
            mainThemes &&
              Object?.entries(mainThemes)?.forEach(([key1, value1]) => {
                let subThemes = value1?.sub_themes;
                subThemes &&
                  Object?.entries(subThemes)?.forEach(([key2, value2]) => {
                    theme?.push({
                      [lang?.excel_categories]: `${lang?.[key1]} (${themesTranslations?.[key2]})`,
                      [lang?.total_interactions]: value2,
                    });
                  });
              });
            allSheetData?.push([theme, lang?.excel_categories]);

            //sub-themes
            let mainSubThemes = value?.theme_analysis?.sub_themes_view;
            mainSubThemes &&
              Object?.entries(mainSubThemes)?.forEach(([key1, value1]) => {
                let mainThemes = value1?.main_themes;
                mainThemes &&
                  Object?.entries(mainThemes)?.forEach(([key2, value2]) => {
                    subTheme?.push({
                      [lang?.excel_themes]: `${themesTranslations?.[key1]} (${lang?.[key2]})`,
                      [lang?.total_interactions]: value2,
                    });
                  });
              });
            allSheetData?.push([subTheme, lang?.excel_themes]);

            //themes-trend
            let mainthemesTrend = value?.theme_trend;
            mainthemesTrend &&
              Object?.entries(mainthemesTrend)?.forEach(([key1, value1]) => {
                let mainThemes = value1?.main_themes_view;
                let rawObj = {};
                mainThemes &&
                  Object?.entries(mainThemes)?.forEach(([key2, value2]) => {
                    let subThemes = value2?.sub_themes;
                    subThemes &&
                      Object?.entries(subThemes)?.forEach(([key3, value3]) => {
                        rawObj = {
                          ...rawObj,
                          [`${lang?.[key2]} (${themesTranslations?.[key3]})`]:
                            value3,
                        };
                      });
                  });
                themesTrend?.push({
                  [lang?.date]: moment.unix(key1).utc().format("YYYY-MM-DD"),
                  ...rawObj,
                });
              });
            allSheetData?.push([themesTrend, lang?.excel_categories_trend]);

            //sub-themes-trend
            mainthemesTrend &&
              Object?.entries(mainthemesTrend)?.forEach(([key1, value1]) => {
                let subThemesTrendObj = value1?.sub_themes_view;
                let rawObj = {};
                subThemesTrendObj &&
                  Object?.entries(subThemesTrendObj)?.forEach(
                    ([key2, value2]) => {
                      let mainThemes = value2?.main_themes;
                      mainThemes &&
                        Object?.entries(mainThemes)?.forEach(
                          ([key3, value3]) => {
                            rawObj = {
                              ...rawObj,
                              [`${themesTranslations?.[key2]} (${lang?.[key3]})`]:
                                value3,
                            };
                          },
                        );
                    },
                  );
                subThemesTrend?.push({
                  [lang?.date]: moment.unix(key1).utc().format("YYYY-MM-DD"),
                  ...rawObj,
                });
              });
            allSheetData?.push([subThemesTrend, lang?.excel_themes_trend]);
            break;

          //_______________________________________________________________________________

          case "dialects":
            handleSortedCase(value, dialects, lang.dialect, lang.number);
            allSheetData?.push([dialects, lang.main_dialect_filter]);
            break;
          //_______________________________________________________________________________

          case "subDialects":
            handleSortedCase(
              value,
              subDialects,
              lang.sub_dialects,
              lang.number,
            );
            allSheetData?.push([subDialects, lang.sub_dialects]);
            break;
          //_______________________________________________________________________________

          case "topLanguages":
            value?.map((i) => {
              topLanguages?.push({
                [lang.language]: i?.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData?.push([topLanguages, lang?.top_languages]);
            break;
          //_______________________________________________________________________________

          case "topKeywords":
            value?.map((i) => {
              topKeywords?.push({
                [lang.keywords]: i?.id,
                [lang.xlsx_number_of_posts]: i?.stats_count,
              });
            });
            allSheetData?.push([
              topKeywords,
              lang?.posts_top_keywords_xlsx_sheetname,
            ]);

            break;
          // _______________________________________________________________________________

          case "topVideos":
            value?.map((i) => {
              topVideos?.push({ [lang.video]: i?.id });
            });
            allSheetData?.push([topVideos, lang?.posts_top_videos]);

            break;
          //_______________________________________________________________________________

          case "topCities":
            value?.map((i) => {
              topCities?.push({
                [lang.cities]: i?.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData?.push([topCities, lang?.top_cities]);
            break;
          // _______________________________________________________________________________

          case "topImages":
            value?.map((i) => {
              topImages?.push({ [lang.image]: i?.id });
            });
            allSheetData?.push([topImages, lang?.posts_top_images]);

            break;
          // _______________________________________________________________________________

          case "topHashtags":
            value?.map((i) => {
              topHashtags?.push({
                [lang.xlsx_hashtags]: i?.id,
                [lang.xlsx_number_of_posts]: i?.stats_count,
              });
            });
            allSheetData?.push([topHashtags, lang?.posts_top_hashtags]);

            break;

          // _______________________________________________________________________________

          case "topPosts":
            value?.map((i) => {
              topPosts?.push({ [lang.posts_word]: i?.tweet_link });
            });
            allSheetData?.push([topPosts, lang?.top_posts]);

            break;
          //_______________________________________________________________________________
          default:
            break;
        }
      });
    });
    //_______________________________________________________________________________

    return handleXlsData(allSheetData);
  }
};
export { engagements_tw_Keywords };
