import {
  Box,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import "./mainSetup.scss";
import PopupModal from "components/popupModal";
import {
  CheckValueLocale,
  commasAfterDigit,
} from "../../../../../utils/helpers/index.js";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/pro-regular-svg-icons";
import { isPublicSpecialCharactersOrEmojis } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/specialCharOrEmojis";
// import { useDatasoucesValidations } from "./hooks/useDatasoucesValidations";

const NewReplayPopup = ({
  newRepPopup,
  setNewRepPopup,
  isButtonLoading,
  collections,
}) => {
  const intl = useIntl();
  const titleLimit = newRepPopup?.isCollection ? 50 : 35;

  // const { data: validations = {} } = useDatasoucesValidations({
  //   queryData: { product_id: localStorage?.engagements_id },
  //   enabled:
  //     !!localStorage?.engagements_id &&
  //     !newRepPopup?.isCollection &&
  //     !newRepPopup?.fromCollection,
  // });

  const dataSourcesLimits = {
    facebook_reply: 280,
    facebook_direct_message: 2000,
    twitter_reply: 280,
    twitter_direct_message: 10000,
    instagram_reply: 280,
    instagram_direct_message: 1000,
    whatsapp_direct_message: 4096,
  };

  let filteredCollections = [
    ...(newRepPopup?.isEdit || newRepPopup?.fromCollection
      ? collections?.filter((item) =>
          newRepPopup?.isEdit
            ? item?.id == newRepPopup?.collection_id
            : item?.id != newRepPopup?.fromCollection,
        )
      : collections),
  ];

  const replyContentLengthError = newRepPopup?.contant?.trim()?.length > 10000;

  const body = () => {
    return (
      <Box className="main-new-rep-popup">
        {newRepPopup?.fromCollection ? (
          <Box className="rep-popup-move-desc">
            {CheckValueLocale(
              newRepPopup?.isBulk ? "rep_bulk_move1" : "rep_move1",
              "",
              {},
              intl,
            )}
            <b>{newRepPopup?.selectedRepsCount}</b>
            {CheckValueLocale(
              newRepPopup?.isBulk ? "rep_bulk_move2" : "rep_move2",
              "",
              {},
              intl,
            )}
            "
            {
              collections?.filter(
                (item) => item?.id == newRepPopup?.fromCollection,
              )?.[0]?.name
            }
            "
            {CheckValueLocale(
              newRepPopup?.isBulk ? "rep_bulk_move3" : "rep_move3",
              "",
              {},
              intl,
            )}
          </Box>
        ) : null}
        {!newRepPopup?.isCollection || newRepPopup?.fromCollection ? (
          <Box className="rep-popup-collection">
            <Box className="input-label">
              <Box>{CheckValueLocale("rep_collection", "", {}, intl)}</Box>
            </Box>
            <FormControl className="input-field">
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={newRepPopup?.collection_id || ""}
                displayEmpty
                renderValue={(selected) => {
                  if (!selected) {
                    return (
                      <Box id="placeholder-collections-list">
                        {CheckValueLocale(
                          "select_collection_placeholder",
                          "",
                          {},
                          intl,
                        )}
                      </Box>
                    );
                  }

                  const selectedItem = filteredCollections?.find(
                    (item) => item.id === selected,
                  );
                  return selectedItem?.name || "Unknown Collection";
                }}
                onChange={(e) => {
                  setNewRepPopup({
                    ...newRepPopup,
                    collection_id: e?.target?.value,
                  });
                }}
              >
                {filteredCollections?.length ? (
                  filteredCollections?.map((item) => (
                    <MenuItem value={item?.id}>{item?.name}</MenuItem>
                  ))
                ) : (
                  <MenuItem value={null} disabled id="empty-collections-list">
                    <Box>
                      {CheckValueLocale("empty_collections_list", "", {}, intl)}
                    </Box>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>
        ) : null}

        {newRepPopup?.fromCollection ? null : (
          <>
            <Box className="rep-popup-title">
              <Box className="input-label">
                <Box className="body-input-label">
                  {CheckValueLocale(
                    newRepPopup?.isCollection ? "collection_tit" : "rep_tit",
                    "",
                    {},
                    intl,
                  )}
                </Box>
                <Box
                  className={`input-label-nums ${newRepPopup?.title?.trim()?.length > titleLimit ? "num-error" : ""}`}
                >{`${commasAfterDigit(
                  newRepPopup?.title?.trim()?.length,
                )} / ${newRepPopup?.isCollection ? "50" : "35"}`}</Box>
              </Box>
              <FormControl className="input-field">
                <TextField
                  disabled={newRepPopup?.loading}
                  value={newRepPopup?.title}
                  onChange={(e) =>
                    setNewRepPopup({
                      ...newRepPopup,
                      title: e.target.value,
                      isDuplicate: false,
                    })
                  }
                  variant="outlined"
                  error={
                    newRepPopup?.title?.trim()?.length > titleLimit ||
                    newRepPopup?.isDuplicate
                  }
                  placeholder={CheckValueLocale(
                    newRepPopup?.isCollection
                      ? "collection_placeholder_title"
                      : "rep_placeholder_title",
                    "",
                    {},
                    intl,
                  )}
                />
              </FormControl>
              {newRepPopup?.title?.trim()?.length > titleLimit ||
              newRepPopup?.isDuplicate ||
              isPublicSpecialCharactersOrEmojis(
                newRepPopup?.title?.trim(),
                undefined,
                true,
              ) ? (
                <FormHelperText error>
                  {CheckValueLocale(
                    newRepPopup?.title?.trim()?.length > titleLimit
                      ? newRepPopup?.isCollection
                        ? "collection_tit_max"
                        : "tit_max"
                      : isPublicSpecialCharactersOrEmojis(
                            newRepPopup?.title?.trim(),
                            undefined,
                            true,
                          )
                        ? "spam_keywords_rules"
                        : "saved_replies_title_duplicate_error",
                    "",
                    {},
                    intl,
                  )}
                </FormHelperText>
              ) : null}
            </Box>
            {!newRepPopup?.isCollection ? (
              <Box className="rep-popup-contant">
                <Box className="input-label">
                  <Box className="input-label-svg">
                    {CheckValueLocale("rep_cont", "", {}, intl)}
                    <Tooltip
                      title={
                        <Box id="rep-popup-contant-limit-warning">
                          <Box className="contant-limit-title">
                            {CheckValueLocale(
                              "rep_cont_limit_title",
                              "",
                              {},
                              intl,
                            )}
                          </Box>
                          <Box lassName="contant-limit-data-source">
                            <Box className="contant-limit-discreption">
                              {CheckValueLocale(
                                "rep_cont_limit_desc",
                                "",
                                {},
                                intl,
                              )}
                            </Box>
                            {Object.keys(dataSourcesLimits)?.map((item) => (
                              <Box>
                                {CheckValueLocale(
                                  `${item}_limit`,
                                  "",
                                  {
                                    num: commasAfterDigit(
                                      dataSourcesLimits?.[item] || 0,
                                    ),
                                  },
                                  intl,
                                )}
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      }
                      arrow
                      placement="top"
                    >
                      <FontAwesomeIcon icon={faCircleExclamation} />
                    </Tooltip>
                  </Box>
                  <Box
                    className={`input-label-nums ${replyContentLengthError ? "num-error" : ""}`}
                  >{`${commasAfterDigit(newRepPopup?.contant?.trim()?.length || 0)} / ${commasAfterDigit(10000)}`}</Box>
                </Box>
                <FormControl className="input-field">
                  <TextField
                    disabled={newRepPopup?.loading}
                    value={newRepPopup?.contant}
                    placeholder={CheckValueLocale(
                      "rep_placeholder_text",
                      "",
                      {},
                      intl,
                    )}
                    onChange={(e) =>
                      setNewRepPopup({
                        ...newRepPopup,
                        contant: e.target.value,
                      })
                    }
                    multiline
                    maxRows={6}
                    variant="outlined"
                    error={replyContentLengthError}
                  />
                </FormControl>
                {replyContentLengthError ? (
                  <FormHelperText error>
                    {CheckValueLocale("cont_max", "", {}, intl)}
                  </FormHelperText>
                ) : null}
              </Box>
            ) : null}
          </>
        )}
      </Box>
    );
  };
  const saveDisabled =
    !newRepPopup?.collection_id ||
    newRepPopup?.loading ||
    newRepPopup?.contant?.trim()?.length > 10000 ||
    isPublicSpecialCharactersOrEmojis(
      newRepPopup?.title?.trim(),
      undefined,
      true,
    ) ||
    newRepPopup?.title?.trim()?.length > 35 ||
    newRepPopup?.isDuplicate ||
    newRepPopup?.contant?.trim()?.length == 0 ||
    newRepPopup?.title?.trim()?.length == 0 ||
    isButtonLoading;
  return (
    <PopupModal
      enableDivider={!newRepPopup?.isCollection && !newRepPopup?.fromCollection}
      title={CheckValueLocale(
        newRepPopup?.isEdit
          ? "edit_rep"
          : newRepPopup?.isCollection
            ? newRepPopup?.collectonName?.length
              ? "collection_edit_tit"
              : "create_collection"
            : newRepPopup?.fromCollection
              ? "move_rep"
              : "saved_rep",
        "",
        {},
        intl,
      )}
      body={body()}
      leftBtn={CheckValueLocale("cancel", "", {}, intl)}
      rightBtn={CheckValueLocale(
        newRepPopup?.ai
          ? "move_save"
          : newRepPopup?.isEdit || newRepPopup?.collectonName?.length
            ? "settings_save_changes"
            : newRepPopup?.isCollection
              ? "create_alert_btn"
              : newRepPopup?.fromCollection
                ? "move_rep_btn"
                : "add",
        "",
        {},
        intl,
      )}
      rightBtnId="save-reply-engag"
      leftBtnId="cancel-reply-engag"
      open={newRepPopup?.open}
      minWidth="600px"
      classeName="actions-buttons-shared"
      close={() =>
        !newRepPopup?.loading &&
        setNewRepPopup({
          open: false,
          id: null,
          isDuplicate: false,
          title: "",
          contant: "",
          collection_id: null,
          isEdit: false,
          ai: false,
          loading: false,
          done: false,
        })
      }
      disableLeftBtn={newRepPopup?.loading}
      disabled={
        newRepPopup?.isCollection
          ? newRepPopup?.collectonName?.length
            ? newRepPopup?.loading ||
              isPublicSpecialCharactersOrEmojis(
                newRepPopup?.title?.trim(),
                undefined,
                true,
              ) ||
              newRepPopup?.title?.trim()?.length > 50 ||
              newRepPopup?.title?.trim()?.length == 0 ||
              newRepPopup?.title?.trim() == newRepPopup?.collectonName?.trim()
            : newRepPopup?.loading ||
              isPublicSpecialCharactersOrEmojis(
                newRepPopup?.title?.trim(),
                undefined,
                true,
              ) ||
              newRepPopup?.title?.trim()?.length > 50 ||
              newRepPopup?.title?.trim()?.length == 0
          : newRepPopup?.isEdit
            ? saveDisabled ||
              (newRepPopup?.title?.trim() ===
                newRepPopup?.initialData?.title?.trim() &&
                newRepPopup?.contant?.trim() ===
                  newRepPopup?.initialData?.contant?.trim())
            : newRepPopup?.fromCollection
              ? !newRepPopup?.collection_id
              : saveDisabled
      }
      isRightBtnLoading={isButtonLoading || newRepPopup?.loading}
      acceptOtherOption={() =>
        setNewRepPopup({
          open: false,
          id: null,
          isDuplicate: false,
          title: "",
          contant: "",
          collection_id: null,
          isEdit: false,
          ai: false,
          loading: false,
          done: false,
        })
      }
      accept={() =>
        setNewRepPopup({
          ...newRepPopup,
          loading: true,
          done: true,
        })
      }
    />
  );
};

export default NewReplayPopup;
