import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { FiltersServices } from "services/api/filterServices";

let allSavedFilterssCancelToken;
export const useGetAllSavedFilters = () => {
  return useMutation({
    mutationFn: ({ monitor_id, product_id, data_source_id, section_name }) => {
      if (allSavedFilterssCancelToken) {
        allSavedFilterssCancelToken.cancel(
          "Operation canceled due to a new request.",
        );
      }
      allSavedFilterssCancelToken = axios?.CancelToken?.source();
      const queryData = {
        params: {
          monitor_id,
          product_id,
          data_source_id,
          section_name,
        },
        cancelToken: allSavedFilterssCancelToken?.token
      };
      return FiltersServices.getAllSavedFilters({ queryData });
    },
  });
};
