import { Box, Button, Tooltip } from "@mui/material";
import profileImg from "images/engagements-images/profileImg.png";
import { faExternalLink, faLink } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import {
  CheckValueLocale,
  extractDisplayInfoFromDataSource,
  handlelUserRoles,
  convertXPlatformToTwitter,
} from "utils/helpers";
import { useIntl } from "react-intl";
import { Link, useParams } from "react-router-dom";

const CopyLinkButton = ({ profileData, activeCdp, selectedCard }) => {
  const intl = useIntl();
  const [copied, setCopied] = useState(false);
  const urlParams = useParams();
  const monitorType = urlParams.monitorType;
  const activeTab = urlParams.activeTab;
  const monitorId = urlParams.monitorId;
  const handleCopy = (link) => {
    setCopied(true);
    navigator.clipboard.writeText(link);
    setTimeout(() => {
      setCopied(false);
    }, 2500);
  };
  const monitorDataSource = convertXPlatformToTwitter(
    urlParams.monitorDataSource,
  );
  if (monitorDataSource == "TWITTER") {
    const link = `
    ${window.location.origin}/monitor/${monitorType}/${urlParams.monitorDataSource}/authors/${monitorId}/author-profile/${selectedCard.tweet_id}/${selectedCard.user_id}
  `;
    return (
      <>
        <Tooltip
          title={
            copied
              ? CheckValueLocale("link_copied", "", {}, intl)
              : CheckValueLocale("copy_author_link", "", {}, intl)
          }
          id={`${copied ? "copied-tooltip" : "copy-tooltip"}`}
          placement="bottom"
        >
          <Box className="link-copy" onClick={() => handleCopy(link)}>
            <FontAwesomeIcon icon={faLink} />
          </Box>
        </Tooltip>
      </>
    );
  } else {
    const link = `
    ${window.location.origin}
    /audience/user-profile/
    ${profileData?.profile_id}
  `;
    return (
      <>
        {/* /////copy link button  */}
        {profileData?.profile_id &&
        handlelUserRoles("CDP", "VIEW_PROFILE") &&
        activeCdp?.length ? (
          <Tooltip
            title={
              copied
                ? CheckValueLocale("link_copied", "", {}, intl)
                : CheckValueLocale("copy_profile_link", "", {}, intl)
            }
            id={`${copied ? "copied-tooltip" : "copy-tooltip"}`}
            placement="bottom"
          >
            <Box className="link-copy" onClick={() => handleCopy(link)}>
              <FontAwesomeIcon icon={faLink} />
            </Box>
          </Tooltip>
        ) : null}
      </>
    );
  }
};
const RedirectLinkButton = ({ profileData, activeCdp, selectedCard }) => {
  const intl = useIntl();

  const urlParams = useParams();
  const monitorType = urlParams.monitorType;
  const monitorId = urlParams.monitorId;
  const monitorDataSource = convertXPlatformToTwitter(
    urlParams.monitorDataSource,
  );
  if (monitorDataSource == "TWITTER") {
    return (
      <>
        <Button
          variant="outlined"
          id="visit-cdp"
          className="Visit-btn"
          onClick={() =>
            window.open(
              `/monitor/${monitorType}/${urlParams.monitorDataSource}/authors/${monitorId}/author-profile/${selectedCard?.tweet_id}/${selectedCard?.user_id}`,
              "_blank",
            )
          }
        >
          <FontAwesomeIcon icon={faExternalLink} />
          {CheckValueLocale("visit_author_profile", "", {}, intl)}
        </Button>
      </>
    );
  } else {
    return (
      <>
        {profileData?.profile_id &&
        handlelUserRoles("CDP", "VIEW_PROFILE") &&
        activeCdp?.length ? (
          <Button
            variant="outlined"
            id="visit-cdp"
            className="Visit-btn"
            onClick={() =>
              window.open(
                `/audience/user-profile/${profileData?.profile_id}`,
                "_blank",
              )
            }
          >
            <FontAwesomeIcon icon={faExternalLink} />
            {CheckValueLocale("visit_cdp_profile", "", {}, intl)}
          </Button>
        ) : null}
      </>
    );
  }
};
const Profile = ({ profileData, selectedCard }) => {
  const intl = useIntl();

  let activeCdp = JSON.parse(window.localStorage?.activeProducts).filter(
    (product) => product?.active && product?.name == "CDP",
  );

  const handleImageError = (event) => {
    event.target.src = profileImg;
  };

  const { profilePicture, displayName } = extractDisplayInfoFromDataSource(
    selectedCard,
    {
      displayName: selectedCard?.user_name ? selectedCard?.user_name : "user",
      profilePicture: profileData?.profile_image
        ? profileData?.profile_image
        : profileImg,
    },
  );

  return (
    <Box className="details-side-bar-profile">
      <CopyLinkButton
        profileData={profileData}
        selectedCard={selectedCard}
        activeCdp={activeCdp}
      />
      <img
        src={profilePicture}
        onError={handleImageError}
        className="profile-img"
      />
      <Box className="profile-user-box">
        <p className="profile-side-bar-name">{displayName}</p>

        {selectedCard?.screen_name ? (
          <p className="profile-side-bar-screen-name">
            {`@${selectedCard?.screen_name}`}
          </p>
        ) : null}
      </Box>

      {/* /// redirect button  */}
      <RedirectLinkButton
        profileData={profileData}
        selectedCard={selectedCard}
        activeCdp={activeCdp}
      />
    </Box>
  );
};
export default Profile;
