import React, { useContext } from "react";
import { Box } from "@mui/material";
import { ReportListContext } from "pages/reports/context/reportContext";
import { useGenerateReportsListHead } from "./useGenerateReportsListHead";
import { useGenerateReportsListBody } from "./useGenerateReportsListBody";
import NoDataFoundProducts from "components/no-Data";
import NoDataLinks from "components/no-Data/noDataLinks";
import SharedTable from "shared/table/sharedTable";
import "./style.scss";

const ReportsListTable = () => {
  const { search, tableData, setSnackbar } = useContext(ReportListContext);

  return (
    <SharedTable
      className="report-table"
      tableHeader={useGenerateReportsListHead() || []}
      tableBody={useGenerateReportsListBody(tableData, setSnackbar) || []}
      emptyBody={
        <>
          {search?.length >= 1 && !tableData?.length ? (
            <Box className="no-data-cdp">
              <NoDataFoundProducts
                image={"noDataImg"}
                title={"no_data_found"}
                subTitle="sorry_adjust_your_search"
              />
            </Box>
          ) : !tableData?.length ? (
            <Box className="empty-item">
              <NoDataFoundProducts
                image="reports"
                title="create_your_report"
                description="create_your_report_dec"
                linkOne="how_to_use"
                linkOneHref={NoDataLinks.reports_link}
                orCase="or_visit_the"
                linkTwo="knowledge_base"
              />
            </Box>
          ) : null}
        </>
      }
      isSharedStyle
    />
  );
};

export default ReportsListTable;
