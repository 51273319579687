import { useMutation } from "@tanstack/react-query";
import AccountsListController from "services/controllers/accountsListController";

export const useDeleteAccountsList = () => {
  return useMutation({
    mutationFn: async (id) => {
      await AccountsListController.deleteAccountsList(id);
    },
  });
};
