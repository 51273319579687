import { faCircleExclamation } from "@fortawesome/pro-regular-svg-icons";

/** Sidebar config by 'label' + days mapping. */

export const sidebarOptionsMapper = {
  lastHour: "picker_siderbar_last_hour_option",
  alltime: "picker_siderbar_all_time_option",
  today: "picker_siderbar_today_option",
  yesterday: "picker_siderbar_yesterday_option",
  lastsevendays: "picker_siderbar_last_seven_days_option",
  lastfifteendays: "picker_siderbar_last_fifteen_days_option",
  lastthirtydays: "picker_siderbar_last_thirty_days_option",
  datetillnow: "picker_siderbar_date_till_today_option",
  customdates: "picker_siderbar_custom_date_range_option",
};

export const sidebarLastHourOption = {
  label: sidebarOptionsMapper.lastHour,
  value: "last_hour",
};

export const sidebarAllTimeOption = {
  label: sidebarOptionsMapper.alltime,
  value: "all_time",
};

export const sidebarTodayOption = {
  label: sidebarOptionsMapper.today,
  value: "today",
  daysAgoStart: 0,
};

export const sidebarYesterdayOption = {
  label: sidebarOptionsMapper.yesterday,
  value: "yesterday",
  daysAgoStart: 1,
  daysAgoEnd: 1,
};

export const sidebarLastSevenDaysOption = {
  label: sidebarOptionsMapper.lastsevendays,
  value: "last_7_days",
  daysAgoStart: 6,
};

export const sidebarLastFifteenDaysOption = {
  label: sidebarOptionsMapper.lastfifteendays,
  value: "last_15_days",
  daysAgoStart: 14,
};

export const sidebarLastThirtyDaysOption = {
  label: sidebarOptionsMapper.lastthirtydays,
  value: "last_30_days",
  daysAgoStart: 29,
};

export const sidebarDateTillNowOption = {
  label: sidebarOptionsMapper.datetillnow,
  value: "date_till_now",
  daysAgoStart: null, // Allow dynamic behavior for start date
  icon: faCircleExclamation,
  iconTooltip: "picker_date_till_today_tooltip",
};

export const sidebarCustomDatesOption = {
  label: sidebarOptionsMapper.customdates,
  value: "custom_dates",
};

export const sidebarGeneralOptions = [
  sidebarTodayOption,
  sidebarYesterdayOption,
  sidebarLastSevenDaysOption,
  sidebarLastFifteenDaysOption,
  sidebarLastThirtyDaysOption,
  sidebarDateTillNowOption,
  sidebarCustomDatesOption,
];

export const sidebarTodayYesterdayOptions = [
  sidebarTodayOption,
  sidebarYesterdayOption,
  sidebarCustomDatesOption,
];

export const sidebarAllTimeOptions = [
  sidebarAllTimeOption,
  sidebarTodayOption,
  sidebarYesterdayOption,
  sidebarLastSevenDaysOption,
  sidebarLastFifteenDaysOption,
  sidebarLastThirtyDaysOption,
  sidebarDateTillNowOption,
  sidebarCustomDatesOption,
];

export const sidebarLastHourOptions = [
  sidebarLastHourOption,
  sidebarTodayOption,
  sidebarYesterdayOption,
  sidebarLastSevenDaysOption,
  sidebarLastFifteenDaysOption,
  sidebarLastThirtyDaysOption,
  sidebarDateTillNowOption,
  sidebarCustomDatesOption,
];

export const publicLiveDashboardSidebarOptions = [
  sidebarLastHourOption,
  sidebarTodayOption,
  sidebarYesterdayOption,
  sidebarLastSevenDaysOption,
  sidebarLastFifteenDaysOption,
  sidebarLastThirtyDaysOption,
];
