import React, { useContext, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { CreateReportContext } from "pages/reports/context/reportContext";
import ReportTextInput from "pages/reports/components/reportTextInput";
import ReportSelectInput from "pages/reports/components/reportSelectInput";

const ReportInformationStep = () => {
  const { formik, availableProducts, availableReportTypes, isMonitorAction } =
    useContext(CreateReportContext);

  const intl = useIntl();

  // If creating report from monitor header button, set the product to SM
  useEffect(() => {
    if (isMonitorAction && !!availableProducts) {
      formik?.setFieldValue(
        "product_id",
        availableProducts?.namesToIds?.["SM"],
      );
    }
  }, [isMonitorAction, availableProducts]);

  const handleChangeProduct = (e) => {
    const value = e.target.value;
    formik?.setValues({
      ...formik?.values,
      product_id: value,
      channel_type: "",
      report_type_id:
        availableProducts?.idsToNames?.[value] === "CXM"
          ? availableReportTypes?.namesToIds?.["channel"] // If CXM is selected, set the report type to channel
          : "",
      data_source_id: [],
      monitor_list: [],
    });
    formik?.setTouched({ title: formik?.touched?.title });
    formik?.setErrors({ title: formik?.errors?.title });
  };

  return (
    <Box>
      <Typography variant="h4" className="create-report-section-title">
        {CheckValueLocale("add_report_informations", "", {}, intl)}:
      </Typography>

      <Box className="create-report-information-step-content">
        <ReportTextInput
          id="create-report-name-input"
          label="name"
          placeholder="write_your_report_name"
          name="title"
          value={formik?.values?.title}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          error={formik?.touched?.title && Boolean(formik?.errors?.title)}
          helperText={formik?.touched?.title && formik?.errors?.title}
        />
        <ReportSelectInput
          id="create-report-platform-input"
          label="platform"
          placeholder="write_your_report_name"
          name="product_id"
          options={
            availableProducts?.filteredData?.map((item) => {
              return { value: item?.id, label: item?.name };
            }) || []
          }
          disabled={isMonitorAction}
          value={formik?.values?.product_id}
          onChange={handleChangeProduct}
          onBlur={formik?.handleBlur}
          error={
            formik?.touched?.product_id && Boolean(formik?.errors?.product_id)
          }
          helperText={formik?.touched?.product_id && formik?.errors?.product_id}
        />
      </Box>
    </Box>
  );
};

export default ReportInformationStep;
