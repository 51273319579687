import StackedBarWidget from "components/widgets/stackedBarWidget";
import LineChartWidget from "components/widgets/lineChartWidget";
import StackedLineWidget from "components/widgets/stackedLineWidget.js";
import MediaFb from "components/media/media-fb/mediaFb";
import { Box, Typography, Grid } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import TableWidget from "components/widgets/tableWidget/tableWidget";

const PostsAnalyticsSection = ({
  faceBookWidgets,
  preLoaderTrackerDataSources,
  FacebookIcon,
}) => {
  const intl = useIntl();

  return (
    <Box>
      <Typography variant="h6" className="ca-head">
        {CheckValueLocale("posts_analytics", "", {}, intl).toUpperCase()}
      </Typography>
      <Box mt={3}>
        <LineChartWidget
          title={"posts_volume"}
          totalLabel={"posts"}
          totalValue={faceBookWidgets?.totalPostsVolumeCount}
          data={faceBookWidgets?.postsVolume}
          showGroupBy={true}
          toolTipLabel={"posts"}
          showDownloadIcon
          bigImg
          hideLegend
          iconAfterTitle={FacebookIcon}
          labelColor={"#2AAAE2"}
          titleToolTip={"posts_volume_fb_tooltip"}
          showNotAppliedFilter
          lineChartPreLoaderDataSources={faceBookWidgets?.postsVolumePreLoader}
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Box>
      <Box my={3}>
        <StackedBarWidget
          title={"posts_interactions"}
          showDownloadIcon
          bigImg
          posts={faceBookWidgets?.postsInteractions}
          showChartDetailsWidget
          showScales
          showGroupBy={false}
          statsDetailsData={[
            {
              name: "max_interaction",
              value: faceBookWidgets?.maxValuePostsInteractions,
              date: `(${faceBookWidgets?.maxValueInteractionsDate})`,
            },
            {
              name: "min_interaction",
              value: faceBookWidgets?.minValuePostsInteractions,
              date: `(${faceBookWidgets?.minValueInteractionsDate})`,
            },
            {
              name: "avg_interaction_per_day",
              value: faceBookWidgets?.avgValuePostsInteractions,
            },
          ]}
          colors={["#2AAAE2", "#80868C"]}
          labels={["comments", "reactions"]}
          showPostsInteractionStats
          showZoomPercentage
          iconAfterTitle={FacebookIcon}
          showNotAppliedFilter
          hideLegend
          dataPiechart={faceBookWidgets?.postsInteractionsLegend}
          titleToolTip={"posts_interactions_fb_tooltip"}
          postsColumnPreLoaderDataSources={
            faceBookWidgets?.postsInteractionsPreLoader
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Box>

      {/* // NOTE: we commented this code until BE fixed these widgets. */}
      {/* <Box mt={3}>
        <StackedLineWidget
          title={"posts_reach_impressions"}
          data={faceBookWidgets?.postReachImpression}
          showGroupBy={true}
          areaStyle={true}
          toolTipLabel={"toolTipLabel"}
          showAI={false}
          showDownloadIcon
          bigImg
          isPostsReachImpression
          isSwitchCase
          iconAfterTitle={FacebookIcon}
          showGroupByClientAgent={true}
          showScales
          labels={["reach", "impressions"]}
          labelColors={["#2AAAE2", "#80868C"]}
          colors={["#2AAAE2", "#80868C"]}
          titleToolTip={"posts_reach_impressions_fb_tooltip"}
          hideLegend
          dataPiechart={faceBookWidgets?.totalPostReachImpression}
          stackedLineWidgetPreLoaderDataSources={
            faceBookWidgets?.postReachImpressionPreLoader
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Box> */}
      {/* <Box my={3}>
        <StackedBarWidget
          title={"reactions"}
          showDownloadIcon
          bigImg
          posts={faceBookWidgets?.postsInteractions}
          showChartDetailsWidget
          showScales
          showGroupBy={false}
          statsDetailsData={[
            {
              name: "max_interaction",
              value: faceBookWidgets?.maxValuePostsInteractions,
              date: faceBookWidgets?.maxValueInteractionsDate,
            },
            {
              name: "min_interaction",
              value: faceBookWidgets?.minValuePostsInteractions,
              date: faceBookWidgets?.minValueInteractionsDate,
            },
            {
              name: "avg_interaction_per_day",
              value: faceBookWidgets?.avgValuePostsInteractions,
            },
          ]}
          colors={["#2AAAE2", "#80868C"]}
          labels={["comments", "reactions"]}
          showPostsInteractionStats
          showZoomPercentage
          iconAfterTitle={FacebookIcon}
          hideLegend
          dataPiechart={faceBookWidgets?.postsInteractionsLegend}
          titleToolTip={"posts_interactions_fb_tooltip"}
          postsColumnPreLoaderDataSources={
            faceBookWidgets?.postsInteractionsPreLoader
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Box> */}

      <Box my={3}>
        <StackedBarWidget
          title={"reactions"}
          showDownloadIcon
          bigImg
          posts={faceBookWidgets?.reactions}
          showZoomPercentage
          iconAfterTitle={FacebookIcon}
          showGroupByClientAgent={true}
          showScales
          labels={[
            "like_reaction",
            "love_reaction",
            "care_reaction",
            "haha_reaction",
            "wow_reaction",
            "angry_reaction",
            "sad_reaction",
          ]}
          hideLegend
          dataPiechart={faceBookWidgets?.pageReactionPieChartValue}
          colors={[
            "#1892F2",
            "#FB5C77",
            "#F7B94D",
            "#FEDC5F",
            "#00D7B5",
            "#EC8A92",
            "#1C404E",
          ]}
          titleToolTip={"reactions_fb_tooltip"}
          showNotAppliedFilter
          postsColumnPreLoaderDataSources={faceBookWidgets?.reactionsPreLoader}
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Box>
      <Box my={3}>
        <StackedBarWidget
          title={"posts_content_type"}
          showDownloadIcon
          bigImg
          posts={faceBookWidgets?.postsContentType}
          labels={[
            "post_content_type",
            "photo_content_type",
            "video_content_type",
          ]}
          hideLegend
          dataPiechart={faceBookWidgets?.postContentPieChartValue}
          colors={["#2AAAE2", "#80868C", "#334155"]}
          showZoomPercentage
          iconAfterTitle={FacebookIcon}
          showGroupByClientAgent={true}
          showScales
          titleToolTip={"posts_content_type_fb_tooltip"}
          showNotAppliedFilter
          postsColumnPreLoaderDataSources={
            faceBookWidgets?.postsContentTypePreLoader
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Box>

      {/* // NOTE: we commented this code until BE fixed these widgets. */}
      {/* <Box mt={3}>
        <LineChartWidget
          title={"videos_views"}
          totalLabel={"video_content_type"}
          totalValue={faceBookWidgets?.totalVideosViewsCount}
          data={faceBookWidgets?.videosViews}
          showGroupBy={true}
          toolTipLabel={"video_content_type"}
          showDownloadIcon
          bigImg
          iconAfterTitle={FacebookIcon}
          labelColor={"#2AAAE2"}
          titleToolTip={"videos_views_fb_tooltip"}
          lineChartPreLoaderDataSources={faceBookWidgets?.videosViewsPreLoader}
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Box> */}

      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TableWidget
              wordsList
              title={"posts_top_keywords"}
              data={faceBookWidgets?.topKeywords}
              leftColomnTitle={"keywords"}
              rightColomnTitle={"number_of_posts"}
              keywords
              tablePagination
              count={faceBookWidgets?.topKeywords?.length}
              showDownloadIcon
              iconAfterTitle={FacebookIcon}
              titleToolTip={"posts_top_keywords_fb_tooltip"}
              showNotAppliedFilter
              tableChartPreLoaderDataSources={
                faceBookWidgets?.topKeywordsPreLoader
              }
              preLoaderTrackerDataSources={preLoaderTrackerDataSources}
              withWordCloud
            />
          </Grid>
          <Grid item xs={6}>
            <TableWidget
              wordsList
              title={"posts_top_hashtags"}
              data={faceBookWidgets?.topHashtags}
              leftColomnTitle={"hashtags"}
              rightColomnTitle={"number_of_posts"}
              hashtags
              tablePagination
              count={10}
              showDownloadIcon
              iconAfterTitle={FacebookIcon}
              titleToolTip={"posts_top_hashtags_fb_tooltip"}
              showNotAppliedFilter
              tableChartPreLoaderDataSources={
                faceBookWidgets?.topHashtagsPreLoader
              }
              preLoaderTrackerDataSources={preLoaderTrackerDataSources}
              withWordCloud
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={3}>
        <MediaFb
          title={CheckValueLocale("posts_top_videos", "", {}, intl)}
          data={faceBookWidgets?.topVideos}
          errorMessage={CheckValueLocale("no_videos_found", "", {}, intl)}
          iconAfterTitle={FacebookIcon}
          showDownloadIcon
          titleToolTip={"posts_top_videos_tooltip"}
          questions={faceBookWidgets?.topVideos}
          showNotAppliedFilter
          mediaFbPreLoaderDataSources={faceBookWidgets?.topVideosPreLoader}
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          datasourcePostsType="FACEBOOK"
          component="CA"
        />
      </Box>
      <Box mt={3}>
        <MediaFb
          title={CheckValueLocale("posts_top_images", "", {}, intl)}
          data={faceBookWidgets?.topImages}
          errorMessage={CheckValueLocale("no_images_found", "", {}, intl)}
          iconAfterTitle={FacebookIcon}
          showDownloadIcon
          titleToolTip={"posts_top_images_tooltip"}
          questions={faceBookWidgets?.topImages}
          showNotAppliedFilter
          mediaFbPreLoaderDataSources={faceBookWidgets?.topImagesPreLoader}
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          datasourcePostsType="FACEBOOK"
          component="CA"
        />
      </Box>
    </Box>
  );
};

export default PostsAnalyticsSection;
