import { Box } from "@mui/material";
import MylogoLoading from "images/luci-images/loader.svg";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import Services from "services/api/Services";

const SummaryLoading = () => {
  const intl = useIntl();
  return (
    <Box className="summary-Loading-wrapper">
      <Box className="summary-Loading-img">
        <img src={Services?.aiProductLogo} alt="" className="summary-logo" />
        <img src={MylogoLoading} className="summary-processing" alt="" />
      </Box>
      <Box component="p" className="summary-Loading-title">
        {" "}
        {CheckValueLocale("summary_Loading_title", "", {}, intl)}
      </Box>
    </Box>
  );
};

export default SummaryLoading;
