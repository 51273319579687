import { Box, Collapse } from "@mui/material";
import { useIntl } from "react-intl";
import EngagementsController from "services/controllers/engagementsController";
import { CheckValueLocale } from "utils/helpers";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import TranslationRating from "../../translationRating/translationRating";

export const PostTranslation = ({
  post,
  translationActive,
  setOpenTranslationCollapse,
  openTranslationCollapse,
  setOpenReplyCollapse,
  openReplyCollapse,
  translationButtonResult,
  setTranslationButtonResult,
  translationButtonLoading,
  setTranslationButtonLoading,
  translationStatus,
  setTranslationStatus,
  translationSource,
  translationTarget,
  text,
  selectedCard,
  postId,
  stats,
  setStats,
}) => {
  const intl = useIntl();

  const translationButtonHandler = (text) => {
    setTranslationStatus({
      ...translationStatus,
      [postId]: true,
    });
    setTranslationButtonLoading({
      ...translationButtonLoading,
      [postId]: true,
    });
    const queryData = {
      text,
      target_language: translationTarget,
      source_language:
        translationSource !== "auto_detect" ? translationSource : null,
      product_id: window.localStorage.getItem("engagementsProductId"),
    };
    EngagementsController.detectTranslate(queryData).then((res) => {
      if (!res?.errorMsg) {
        setTranslationButtonResult({
          ...translationButtonResult,
          [postId]: res?.data,
        });
        setOpenTranslationCollapse({
          ...openTranslationCollapse,
          [postId]: true,
        });
      } else {
        setTranslationButtonResult({
          ...translationButtonResult,
          [postId]: null,
        });
        setTranslationStatus({
          ...translationStatus,
          [postId]: false,
        });
        setStats({
          ...stats,
          open: true,
          title: CheckValueLocale("request_access_error_heading", "", {}, intl),
          body: CheckValueLocale("request_access_error_desc", "", {}, intl),
          severity: "error",
        });
        setTimeout(() => {
          setStats({
            ...stats,
            open: false,
          });
        }, 7000);
      }
      setTranslationButtonLoading({
        ...translationButtonLoading,
        [postId]: false,
      });
    });
  };

  const translationCollapseHandler = () => {
    setOpenTranslationCollapse({
      ...openTranslationCollapse,
      [postId]: !openTranslationCollapse[postId],
    });
  };

  const translationReplyHandler = (index) => {
    setOpenReplyCollapse({
      ...openReplyCollapse,
      [index]: !openReplyCollapse[index],
    });
  };
  return (
    <>
      {translationActive && !post?.original_replay ? (
        <Box className="card-translation-wrapper">
          <p
            id="engagements-posts-translation-btn"
            className="translation-button"
            onClick={() => translationButtonHandler(text)}
          >
            {CheckValueLocale("translate_from", "", {}, intl)} (
            {CheckValueLocale(translationSource, "", {}, intl)}){" "}
            {CheckValueLocale("to_lang", "", {}, intl)} (
            {CheckValueLocale(translationTarget, "", {}, intl)})
          </p>
          {translationStatus[postId] ? (
            <Box className="card-translation-box">
              {translationButtonLoading[postId] ? (
                <p className="translation-loading">
                  {CheckValueLocale("loading_please_wait", "", {}, intl)}
                </p>
              ) : (
                <Box className="card-translation-collapse">
                  <Box className="collapse-heading">
                    <p className="translation-lang">
                      {CheckValueLocale("from_lang", "", {}, intl)}
                      <span>
                        {" "}
                        {CheckValueLocale(
                          translationButtonResult[postId]?.source_language,
                          "",
                          {},
                          intl,
                        )}{" "}
                      </span>
                      {CheckValueLocale("to_lang", "", {}, intl)}
                      <span>
                        {" "}
                        {CheckValueLocale(translationTarget, "", {}, intl)}{" "}
                      </span>
                    </p>
                    <span
                      className="arrow-icon-translation"
                      onClick={translationCollapseHandler}
                    >
                      {openTranslationCollapse[postId] ? (
                        <ExpandMore />
                      ) : (
                        <ExpandLess />
                      )}
                    </span>
                  </Box>
                  <Collapse in={openTranslationCollapse[postId]}>
                    <p className="translation-text">
                      {translationButtonResult[postId]?.translation}
                    </p>
                    <TranslationRating
                      translationSource={
                        translationButtonResult[postId]?.source_language
                      }
                      translationTarget={translationTarget}
                      translation={translationButtonResult[postId]?.translation}
                      selectedCard={selectedCard}
                      engagementId={selectedCard?.tweet_id}
                    />
                  </Collapse>
                </Box>
              )}
            </Box>
          ) : null}
        </Box>
      ) : null}
      {post?.original_replay ? (
        <Box className="card-translation-box">
          <Box className="card-translation-collapse">
            <Box className="collapse-heading">
              <span className="translation-lang">
                <span>
                  {CheckValueLocale("your_original_replay", "", {}, intl)}
                </span>
              </span>
              <span
                className="arrow-icon-translation"
                onClick={() => translationReplyHandler(postId)}
              >
                {!openReplyCollapse[postId] ? <ExpandMore /> : <ExpandLess />}
              </span>
            </Box>
            <Collapse in={!openReplyCollapse[postId]}>
              <span className="translation-text">{post?.original_replay}</span>
            </Collapse>
          </Box>
        </Box>
      ) : null}
    </>
  );
};
