import { Box, Typography } from "@mui/material";
import { MonitorStatistics } from "components/widgets/monitorStatistics";
import QuestionCard from "pages/Monitors/Components/questionCard";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import xPlatform from "images/social-icons/x-platform-white-icon.svg";
import { useEffect, useReducer } from "react";
import {
  faComments,
  faUsers,
  faUsersViewfinder,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import {
  faArrowUpRight,
  faBadgeCheck,
  faSparkles,
} from "@fortawesome/pro-solid-svg-icons";
import { SinglePostAnalysisHeader } from "./singlePostAnalysisHeader";
import {
  singlePostOverviewInitialState,
  singlePostOverviewReducer,
} from "../utils/singleTweetOverviewReducer";
import { handleSinglePostOverviewResponse } from "../utils/handleSingleTweetOverviewResponse";

export const SinglePostAnalysisOverviewSection = ({
  response,
  preLoaderTrackerDataSources,
  monitorName,
  monitorID,
}) => {
  const intl = useIntl();
  const [state, dispatch] = useReducer(
    singlePostOverviewReducer,
    singlePostOverviewInitialState,
  );

  useEffect(() => {
    handleSinglePostOverviewResponse(response, dispatch);
  }, [response]);

  const monitorStatistics = [
    {
      title: CheckValueLocale("spa_tweet_analytics_engagement", "", {}, intl),
      icon: (
        <FontAwesomeIcon
          className="monitor-widget-summary-icon"
          icon={faComments}
        />
      ),
      tooltipTitle: CheckValueLocale(
        "spa_tweet_analytics_engagement_tooltip",
        "",
        {},
        intl,
      ),
      value: state?.monitorOverviewData?.total_engagement?.toLocaleString(),
      isLoading: _.isEqual(
        state?.singleTweetOverviewDataPreLoader?.sort(),
        preLoaderTrackerDataSources?.sort(),
      ),
    },
    {
      title: CheckValueLocale(
        "spa_tweet_analytics_potential_reach",
        "",
        {},
        intl,
      ),
      icon: (
        <FontAwesomeIcon
          className="monitor-widget-summary-icon"
          icon={faArrowUpRight}
        />
      ),
      tooltipTitle: CheckValueLocale(
        "spa_tweet_analytics_potential_reach_tooltip",
        "",
        {},
        intl,
      ),
      value: state?.monitorOverviewData?.potential_reach?.[0]?.toLocaleString(),
      isLoading: _.isEqual(
        state?.singleTweetOverviewDataPreLoader?.sort(),
        preLoaderTrackerDataSources?.sort(),
      ),
    },
    {
      title: CheckValueLocale("spa_tweet_analytics_impression", "", {}, intl),
      icon: (
        <FontAwesomeIcon
          className="monitor-widget-summary-icon"
          icon={faSparkles}
        />
      ),
      tooltipTitle: CheckValueLocale(
        "spa_tweet_analytics_impression_tooltip",
        "",
        {},
        intl,
      ),
      value: state?.monitorOverviewData?.total_impressions?.toLocaleString(),
      isLoading: _.isEqual(
        state?.singleTweetOverviewDataPreLoader?.sort(),
        preLoaderTrackerDataSources?.sort(),
      ),
    },
    {
      title: CheckValueLocale(
        "spa_tweet_analytics_total_engagers",
        "",
        {},
        intl,
      ),
      icon: (
        <FontAwesomeIcon
          className="monitor-widget-summary-icon"
          icon={faUsers}
        />
      ),
      tooltipTitle: CheckValueLocale(
        "spa_tweet_analytics_total_engagers_tooltip",
        "",
        {},
        intl,
      ),
      value: state?.monitorOverviewData?.total_engagers?.toLocaleString(),
      isLoading: _.isEqual(
        state?.singleTweetOverviewDataPreLoader?.sort(),
        preLoaderTrackerDataSources?.sort(),
      ),
    },
    {
      title: CheckValueLocale(
        "spa_tweet_analytics_total_verified_engagers",
        "",
        {},
        intl,
      ),
      icon: (
        <FontAwesomeIcon
          className="monitor-widget-summary-icon"
          icon={faBadgeCheck}
        />
      ),
      tooltipTitle: CheckValueLocale(
        "spa_tweet_analytics_total_verified_engagers_tooltip",
        "",
        {},
        intl,
      ),
      value:
        state?.monitorOverviewData?.total_verified_engagers?.toLocaleString(),
      isLoading: _.isEqual(
        state?.singleTweetOverviewDataPreLoader?.sort(),
        preLoaderTrackerDataSources?.sort(),
      ),
    },
    {
      title: CheckValueLocale(
        "spa_tweet_analytics_total_engagement_rate_for_verified_users",
        "",
        {},
        intl,
      ),
      icon: (
        <FontAwesomeIcon
          className="monitor-widget-summary-icon"
          icon={faUsersViewfinder}
        />
      ),
      tooltipTitle: CheckValueLocale(
        "spa_tweet_analytics_total_engagement_rate_for_verified_users_tooltip",
        "",
        {},
        intl,
      ),
      value:
        state?.monitorOverviewData?.verified_engagers_ratio?.toLocaleString(),
      isLoading: _.isEqual(
        state?.singleTweetOverviewDataPreLoader?.sort(),
        preLoaderTrackerDataSources?.sort(),
      ),
    },
  ];

  return (
    <>
      <SinglePostAnalysisHeader
      // monitorID={monitorID}
      // monitorName={monitorName}
      // screenName={state?.tweetData?.screen_name}
      // trackerID={state?.tweetData?.tracker_id}
      // createdAt={state?.tweetData?.created_at}
      // tweetID={state?.tweetData?.tweet_id}
      />
      <Box className="spa-overview-container">
        <Typography className="spa-overview-text">
          {CheckValueLocale("overview", "", {}, intl)}
        </Typography>
        <QuestionCard
          productId={window.localStorage?.getItem("sm_id")}
          latestUnanswer={[state?.tweetData]}
          latestUnanswerPreloader={
            state?.singleTweetPreLoaderTrackerDataSources
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          icon={xPlatform}
          iconColor={"twitter_icon"}
          showRetweet
          showScreenName
          isLiveDashboardPublic
          hideWhenRetweeted
          showHeart
          showDefaultImg
          quickInspectClass=""
          datasourcePostsType="TWITTER"
          showProfile={false}
          hideTweetAnalysis={true}
          hideManageList={true}
          isSinglePostAnalysis
        />
        <MonitorStatistics data={monitorStatistics} />
      </Box>
    </>
  );
};
