import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Container } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { useReportRecords } from "../../hooks/useReportRecords";
import { ViewReportContext } from "pages/reports/context/reportContext";
import CircularLoading from "components/circularProgress";
import SnackBar from "components/snackBar";
import ViewReportHeader from "./components/viewReportHeader";
import ViewReportInfoCards from "./components/viewReportInfoCards";
import ViewReportAlerts from "./components/viewReportAlerts";
import ViewReportRecordsTable from "./components/viewReportRecordsTable";
import "./style.scss";

const ViewReport = () => {
  const { id } = useParams();

  const intl = useIntl();

  const [page, setPage] = useState(1);
  const [reportData, setReportData] = useState({});
  const [snackbar, setSnackbar] = useState({});

  const {
    data,
    isLoading: reportDataLoading,
    isError: reportDataError,
  } = useReportRecords({ queryData: { report_id: id, page: page || 1 } });

  useEffect(() => {
    if (data) setReportData(data);
  }, [data]);

  const { reportInfo, reportRecords, pagination } = reportData || {};

  useEffect(() => {
    if (reportDataError) {
      setSnackbar({
        open: true,
        severity: "error",
        title: "something_went_wrong",
        message: "try_again",
      });
    }
  }, [reportDataError]);

  const contextProps = {
    reportId: id,
    reportInfo,
    reportRecords,
    reportDataLoading,
    pagination,
    page,
    setPage,
    setSnackbar,
  };

  return (
    <ViewReportContext.Provider value={contextProps || {}}>
      <Container maxWidth="xl">
        {reportDataLoading && !reportInfo ? (
          <CircularLoading />
        ) : (
          <Box id="view-report-page-container">
            <ViewReportHeader />
            <ViewReportInfoCards />
            <ViewReportAlerts />
            <ViewReportRecordsTable />
          </Box>
        )}
        {snackbar?.open ? (
          <SnackBar
            open={snackbar?.open}
            severity={snackbar?.severity}
            title={CheckValueLocale(snackbar?.title, "", {}, intl)}
            message={CheckValueLocale(snackbar?.message, "", {}, intl)}
            handleClose={() => setSnackbar({})}
          />
        ) : null}
      </Container>
    </ViewReportContext.Provider>
  );
};

export default ViewReport;
