import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import "../../surveyInsights.scss";
import { isEmptyArray } from "utils/helpers";
import FetchingData from "components/fetchingData/fetchingData";
import _ from "lodash";
import NoDataFound from "components/no-Data/noDataFound";
import OneQuestionsData from "../questionsTab/oneQuestionsData";
import { CheckValueLocale } from "utils/helpers";

const SummaryTab = (props) => {
  const intl = useIntl();
  const questions = props?.questions;
  return (
    <>
      {_.isEqual(
        props?.questionsPreLoader?.sort(),
        props?.preLoaderTrackerDataSources?.sort(),
      ) !== true ||
      !props?.questionsPreLoader?.length ||
      !props?.preLoaderTrackerDataSources?.length ? (
        <Box className="fetching-questions">
          <FetchingData />
        </Box>
      ) : props?.questions?.length === 0 || props?.questions == {} ? (
        <NoDataFound />
      ) : (
        <>
          {Object.keys(questions)?.map((question, index) => {
            return (
              <>
                {Object.getOwnPropertyNames(questions)?.length > 1 && (
                  <Box
                    className={`section-name-insights question-direction-${questions[question]?.text_direction}`}
                  >
                    {questions[question]?.section_name}
                  </Box>
                )}
                {questions[question]?.questions?.map((ques, i) => {
                  return (
                    <Box
                      className={`answers-box question-direction-${questions[question]?.text_direction}`}
                      key={index}
                    >
                      <Typography className="answers-title">
                        {i + 1}. {ques?.question}
                      </Typography>
                      {ques?.type !== "nps" && ques?.type !== "scale" && (
                        <Box className="answers-header">
                          {ques?.total_answer}{" "}
                          {CheckValueLocale("response", "", {}, intl)}
                        </Box>
                      )}
                      <Box
                        className={
                          ques?.type !== "multiple_choice" &&
                          ques?.type !== "dropdown" &&
                          ques?.type !== "yes_no" &&
                          ques?.type !== "social_media" &&
                          ques?.type !== "nps" &&
                          ques?.type !== "scale" &&
                          "box-scroll"
                        }
                      >
                        {isEmptyArray(ques?.answer_list) ? (
                          <NoDataFound />
                        ) : (
                          <OneQuestionsData
                            selectedQuestion={ques}
                            isSummary={true}
                            questionsPreLoader={props?.questionsPreLoader}
                            preLoaderTrackerDataSources={
                              props?.preLoaderTrackerDataSources
                            }
                            id={props?.id}
                            productId={props?.productId}
                            sentimentsOptions={props?.sentimentsOptions}
                            categoriesOptions={props?.categoriesOptions}
                            themesOptions={props?.themesOptions}
                          />
                        )}
                      </Box>
                    </Box>
                  );
                })}
              </>
            );
          })}
        </>
      )}
    </>
  );
};
export default SummaryTab;
