import { Box } from "@mui/material";
import "./popupHeader.scss";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";

const PopupHeader = ({ titleKey, step }) => {
  const intl = useIntl();
  return (
    <Box id="two-factor-authentication-modal-title">
      {CheckValueLocale(titleKey, "", {}, intl)}
      {step ? (
        <Box component="span" className="steps-counter">
          {CheckValueLocale(
            "popup_steps_count",
            "",
            { num: step, allSteps: 2 },
            intl,
          )}
        </Box>
      ) : null}
    </Box>
  );
};

export default PopupHeader;
