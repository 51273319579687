import { Box, Grid } from "@mui/material";
import { useIntl } from "react-intl";
// ___________________________________________________________
import DeepInsightsProfileDetails from "./components/profileDetails";
import DeepInsightsPersonality from "./components/personality";
import DeepInsightsInterests from "./components/interests";
import DeepInsightsEngagement from "./components/engagmentInTheMonitor";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import PunchCardWidget from "components/widgets/punchCardWidget";
import CircularLoading from "components/circularProgress";
import { MonitorContainer } from "../../../../Components/sidebar/monitor.styles";
import { useParams } from "react-router-dom";
import { convertXPlatformToTwitter } from "utils/helpers";
import { useDeepInsightData } from "pages/Monitors/hooks/useDeepInsightsData";

// ___________________________________________________________

const DeepInsightsPage = (props) => {
  const intl = useIntl();
  const urlParams = useParams();
  const monitorDataSource = convertXPlatformToTwitter(
    urlParams.monitorDataSource,
  );
  const monitorId = urlParams.monitorId;
  const userId = urlParams.userId;
  const accountName = urlParams.userName;

  /*---------------------------------------------------------*/

  const { data: deepInsightResponse, isPending: isLoading } =
    useDeepInsightData(
      monitorDataSource,
      monitorId,
      window.localStorage.sm_id,
      accountName,
      userId,
    );

  const userBio = deepInsightResponse?.user_desc;
  const userName = deepInsightResponse?.user_name;
  const screenName = deepInsightResponse?.user_screen_name;
  const profileImg = deepInsightResponse?.user_profile_url;
  const activityTimeMaxValue = deepInsightResponse?.activity_time_max_value;
  const activityTimeMinValue = deepInsightResponse?.activity_time_min_value;
  const activityTime = deepInsightResponse?.activity_time;
  const topKeywords = deepInsightResponse?.top_keywords;
  const interests = deepInsightResponse?.interests;
  const averageTweetPerTweet =
    deepInsightResponse?.average_engagement_per_tweet;
  const engagementPerFollower =
    deepInsightResponse?.engagement_per_1000_follower;
  const personalityName = deepInsightResponse?.personality;

  return (
    <MonitorContainer maxWidth="xl" className="monitor-container">
      {isLoading ? (
        <CircularLoading />
      ) : (
        <>
          {/* /*-------------------DeepInsightsProfileDetails--------------------------------------*/}
          <DeepInsightsProfileDetails
            userName={userName}
            screenName={screenName}
            profileImg={profileImg}
            userBio={userBio}
          />
          {/* /*---------------------DeepInsightsPersonality & DeepInsightsInterests------------------------------------*/}
          <Grid container spacing={3} mb={3}>
            <Grid item xs={6}>
              <DeepInsightsPersonality personalityName={personalityName} />
            </Grid>
            <Grid item xs={6}>
              <DeepInsightsInterests interests={interests} />
            </Grid>
          </Grid>

          {/* /*--------------------Activity Time-------------------------------------*/}
          <Box my={4}>
            <PunchCardWidget
              title="activity_time"
              titleToolTip="activity_time"
              clientActivity={activityTime}
              minValueClient={activityTimeMinValue}
              maxValueClient={activityTimeMaxValue}
              showDownloadIcon
              bigImg
              authorActivityPreLoaderDataSources={[1]}
              preLoaderTrackerDataSources={[1]}
            />
          </Box>
          {/* /*---------------------------------------------------------*/}

          {/* /*---------------------DeepInsightsEngagement && DeepInsightsExtraInformation && DeepInsightsOtherSocial------------------------------------*/}
          <Grid container spacing={3} className="deep-insight-body">
            <Grid item xs={6}>
              <TableWidget
                wordsList
                title={"top_keywords"}
                data={topKeywords}
                leftColomnTitle={"keywords"}
                rightColomnTitle={"number_of_posts"}
                tableChartPreLoaderDataSources={[1]}
                preLoaderTrackerDataSources={[1]}
                keywords
                tablePagination
                count={topKeywords.length}
                iconAfterTitle={props.twitterIcon}
              />
            </Grid>

            <Grid item xs={6}>
              <DeepInsightsEngagement
                averageTweetPerTweet={averageTweetPerTweet}
                engagementPerFollower={engagementPerFollower}
              />
            </Grid>
          </Grid>
        </>
      )}
    </MonitorContainer>
  );
};

export default DeepInsightsPage;
