import { useQuery } from "@tanstack/react-query";
import { MainMonitorsPage } from "services/api/mainMonitorsPage";

export const useMonitors = ({
  page = 1,
  search,
  sortColumn,
  sortDirection,
  redirectedMonitorId,
}) => {
  const activeProducts = JSON.parse(localStorage.getItem("activeProducts"));
  const isCXMActive = activeProducts
    .map((product) => product.name)
    .includes("CXM");
  const cxmId = localStorage.getItem("cxm_id");

  const queryParams = {
    params: {
      page,
      search_account_name: search,
      sort_by: sortColumn,
      order_by: sortDirection,
      redirected_monitor: redirectedMonitorId || undefined,
      product_id: cxmId,
    },
  };

  return useQuery({
    queryKey: [
      "monitors",
      page,
      sortColumn,
      sortDirection,
      redirectedMonitorId,
      search,
    ],
    queryFn: async () => MainMonitorsPage.getMonitors(queryParams),
    enabled: !!isCXMActive && !!cxmId,
    select: ({ data }) => {
      const newData = data?.data?.map((monitor) => {
        if (monitor?.attributes?.account_tiktok_token_error) {
          monitor?.attributes?.account_error?.push(
            "account_tiktok_token_error",
          );
        }
        return monitor;
      });
      return { ...data, data: newData };
    },
  });
};
