// conditionsNamesMapper is an object that maps the condition name to the key which come from backend
export const conditionsNamesMapper = {
  platform: "platform",
  account: "account",
  followers_count: "followers_count",
  message_type: "message_type",
  verified_account: "verified_account",
  sentiment: "sentiment",
  keyword: "keyword",
  themes: "themes",
  languages: "languages",
  countries: "countries",
};

//############################################################################################################

// Deserialize the values of the conditions to be used in the select component

// array values adapter takes an array of strings and return an array of objects to be used in the select component
// e.g. ["IS, "IS_NOT"] => [{id: "IS", name: "routing_is"}, {id: "IS_NOT", name: "routing_is_not"}]
// id is the unique value for each option and name is to map to a key in the locale file
export const arrayValuesAdapter = (arrayValues) => {
  return arrayValues?.map((value) => ({
    id: value,
    name: "routing_" + value?.toLowerCase(),
  }));
};

// values = ["twitter_private"] ==> [{id: "twitter_private", name: "routing_twitter_private", iconKey: "TWITTER_private"}]
const platformValuesAdapter = (values) => {
  return values?.map((value) => {
    const [platform, type] = value?.split("_");
    return {
      id: value,
      name: "routing_" + value,
      iconKey: platform?.toUpperCase?.() + "_" + type,
    };
  });
};

const accountValuesAdapter = (values) => {
  // in case of twitter we need to get the data from the data key, in other cases we need to get the data from the included key
  return Object.keys(values)
    ?.map((key) => {
      const accounts =
        key?.toLowerCase() === "twitter"
          ? values?.[key]?.data
          : values?.[key]?.included;
      return accounts?.map((value) => {
        return {
          type: key,
          ...(value?.attributes || {}),
          name:
            key?.toLowerCase() === "whatsapp"
              ? values?.[key]?.data?.find?.(
                  (item) => +item?.id === +value?.attributes?.account_info_id,
                )?.attributes?.name
              : value?.attributes?.name,
        };
      });
    })
    ?.flat();
};

export const inboundRoutingValuesAdapter = (conditionName, values) => {
  switch (conditionName) {
    case conditionsNamesMapper.platform:
      return platformValuesAdapter(values);
    case conditionsNamesMapper.account:
      return accountValuesAdapter(values);
    case conditionsNamesMapper.message_type:
    case conditionsNamesMapper.verified_account:
    case conditionsNamesMapper.sentiment:
    case conditionsNamesMapper.themes:
      return arrayValuesAdapter(values);
    //  language,country,keyword, followers_count are the same
    default:
      return values;
  }
};

export const displaySLAAttribute = (sla) => {
  return ` ${sla?.value}${sla?.unit?.[0]}`;
};

export const SLATimeStringify = (sla) => {
  const {
    first_response_time,
    next_response_time,
    time_to_complete,
    total_unassigned_time,
  } = sla || {};
  return `(FRT:${displaySLAAttribute(
    first_response_time,
  )}, NRT:${displaySLAAttribute(next_response_time)}, TTC:${displaySLAAttribute(
    time_to_complete,
  )}, TUT:${displaySLAAttribute(total_unassigned_time)})`;
};

export const DeserializeConditionsValueFromBackend = (contioinsObj) => {
  // desrialize the conditions to met with the front end logic
  const conditions = Object.keys(contioinsObj)?.map((conditionKey) => {
    let desrializeConditonValue = {};
    const condition = contioinsObj[conditionKey];
    switch (conditionKey) {
      case conditionsNamesMapper.account:
        desrializeConditonValue = Object.keys(condition?.values)
          ?.map((accountType) => {
            const accountData = condition?.values?.[accountType]?.map(
              (acc) => ({
                ...acc,
                type: accountType,
              }),
            );
            return accountData;
          })
          ?.flat();
        break;
      case conditionsNamesMapper.message_type:
      case conditionsNamesMapper.verified_account:
        desrializeConditonValue = condition?.values?.[0];
        break;
      default:
        desrializeConditonValue = condition?.values;
    }
    return {
      condition: conditionKey,
      operator: condition?.operator,
      value: desrializeConditonValue || "",
    };
  });
  return conditions;
};
//############################################################################################################

// Serialize the values of the conditions to be sent to the backend

const conditionValueSerializer = (conditionName, value) => {
  switch (conditionName) {
    case conditionsNamesMapper.platform:
      return value; // ["twitter_private"]
    case conditionsNamesMapper.account:
      return value?.map((account) => ({
        id: account?.id,
        data_source: account?.type,
      }));
    case conditionsNamesMapper.followers_count:
      return [+value]; // [1000]
    case conditionsNamesMapper.message_type:
    case conditionsNamesMapper.verified_account:
      return [value]; // ["IS"]
    case conditionsNamesMapper.languages:
    case conditionsNamesMapper.countries:
      return value?.map((item) => item?.id);
    case conditionsNamesMapper.keyword:
      // if the operator was regex that's mean the value is string
      // but if it was contain or not_contain that's mean the value is array
      // and we need to convert it to array of string anyway
      return typeof value === "string" ? [value] : value;
    default:
      // sentiment, themes are already array of strings
      return value;
  }
};

// serializeConditionsValuesForBackend is a function that generates the conditions object to be sent to the backend.
// check the required conditions here: https://lucidya.atlassian.net/browse/SQ2-5457
export const serializeConditionsValuesForBackend = (conditions) => {
  return conditions?.map((condition) => {
    return {
      filter_name: condition?.condition,
      operator: condition?.operator,
      value: conditionValueSerializer(condition?.condition, condition?.value),
    };
  });
};

//############################################################################################################
// Comparing

const isArrayOfStringDifferent = (array, originalArray) => {
  const arraySet = new Set(array);
  let isDifferent = false;
  array?.forEach((item, index) => {
    if (!arraySet?.has(originalArray[index])) {
      isDifferent = true;
      return;
    }
  });
  return isDifferent;
};

const isArrayOfObjectDifferent = (array, originalArray) => {
  const idsSet = new Set(array?.map((item) => item?.id));
  let isDifferent = false;
  array?.forEach((item, index) => {
    const id = originalArray?.[index]?.id;
    if (!idsSet?.has(id)) {
      isDifferent = true;
      return;
    }
  });
  return isDifferent;
};

const compareConditonValueArrays = (a1, a2) => {
  if (!Array.isArray(a1) || !Array.isArray(a2)) return a1 !== a2;
  if (a1?.length !== a2?.length) return true;
  if (a1?.length === 0) return false;
  const firstElement = a1[0];
  if (typeof firstElement === "string") return isArrayOfStringDifferent(a1, a2);
  else return isArrayOfObjectDifferent(a1, a2);
};

export const compareRoutingCondtions = (conditions1 = [], conditions2 = []) => {
  if (conditions1?.length !== conditions2?.length) return true;
  let isDiffer = false;
  conditions1.forEach((c1, idx) => {
    const c2 = conditions2[idx];
    // condition will always be string
    if (c1?.condition !== c2?.condition) isDiffer = true;
    // operator will always be string
    else if (c1?.operator !== c2?.operator) isDiffer = true;
    // value can be string, string[], or Array<Record<{id:string, name:string}>>.
    else {
      switch (typeof c1?.value) {
        case "string": {
          if (c1?.value != c2?.value) isDiffer = true;
          break;
        }
        case "object": {
          if (compareConditonValueArrays(c1?.value, c2?.value)) isDiffer = true;
          break;
        }
        default: {
          if (c1?.value != c2?.value) isDiffer = true;
        }
      }
    }
    if (isDiffer) return;
  });
  return isDiffer;
};
