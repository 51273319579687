import "components/media/Media.scss";
import MediaTwItem from "components/media/media-tw/mediaTwItem.js";
import { isEmptyArray } from "utils/helpers";
import FetchingData from "components/fetchingData/fetchingData";
import _ from "lodash";
import { Box } from "@mui/material";
import NoDataFound from "components/no-Data/noDataFound";

const MediaTw = (props) => {
  const { mediaTwPreLoaderDataSources, preLoaderTrackerDataSources, data } =
    props;
  return (
    <>
      {_.isEqual(
        mediaTwPreLoaderDataSources?.sort(),
        preLoaderTrackerDataSources?.sort(),
      ) !== true ||
      !mediaTwPreLoaderDataSources?.length ||
      !preLoaderTrackerDataSources?.length ? (
        <Box>
          <FetchingData />
        </Box>
      ) : isEmptyArray(props?.data) ? (
        <NoDataFound />
      ) : (
        <div className="topmedia-wrapper">
          {data?.map((media, index) => (
            <MediaTwItem key={index} media={media} />
          ))}
        </div>
      )}
    </>
  );
};

export default MediaTw;
