import axios from "axios";
import Survey from "../api/survey.js";

var SurveyController = {};
let getSurveyInsightsCancelToken;

SurveyController.getSurveysList = (queryData) => {
  return new Promise((resolve, reject) => {
    Survey.getSurveysList(queryData)
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        reject({ error });
      });
  });
};

SurveyController.getSurveyTypes = () => {
  return new Promise((resolve, reject) => {
    Survey.getSurveyTypes()
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        reject({ error });
      });
  });
};

SurveyController.getSurveyFilters = () => {
  return new Promise((resolve, reject) => {
    Survey.getSurveyFilters()
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        reject({ error });
      });
  });
};

SurveyController.getEmbeddedSurveyOptions = () => {
  return new Promise((resolve, reject) => {
    Survey.getEmbeddedSurveyOptions()
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        reject({ error });
      });
  });
};

SurveyController.getEmbeddedSurveyFonts = () => {
  return new Promise((resolve, reject) => {
    Survey.getEmbeddedSurveyFonts()
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        reject({ error });
      });
  });
};

SurveyController.getGetCompanyDomains = (queryData) => {
  return new Promise((resolve, reject) => {
    Survey.getGetCompanyDomains(queryData)
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        reject({ error });
      });
  });
};

SurveyController.createCompanyDomain = (queryData) => {
  return new Promise((resolve, reject) => {
    Survey.createCompanyDomain(queryData)
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        reject({ error: error?.response?.data?.exception });
      });
  });
};

SurveyController.deleteCompanyDomain = (id) => {
  return new Promise((resolve, reject) => {
    Survey.deleteCompanyDomain(id)
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        reject({ error });
      });
  });
};

SurveyController.getDomainVerificationStatus = (queryData) => {
  return new Promise((resolve, reject) => {
    Survey.getDomainVerificationStatus(queryData)
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        reject({ error });
      });
  });
};

SurveyController.getSurveysById = (id) => {
  return new Promise(function (resolve) {
    Survey.getSurveysById(id)
      .then((response) => {
        resolve({
          data: response.data.survey.data,
          custom_domain: response?.data?.company_custom_domain,
          custom_domain_status: response?.data?.company_custom_domain_status,
          survey_limit_reach: response?.data?.survey_limit_reach,
          is_survey_panel_enabled: response?.data?.is_survey_panel_enabled,
          is_skipped: response?.data?.is_skipped,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

SurveyController.saveSurveyChanges = (id, survey) => {
  return new Promise(function (resolve) {
    Survey.saveSurveyChanges(id, survey)
      .then((response) => {
        resolve({
          data: response.data.survey.data,
          status: response.data.survey.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

SurveyController.createSurveys = (queryData) => {
  return new Promise((resolve, reject) => {
    Survey.createSurveys(queryData)
      .then((response) => {
        resolve({ data: response });
      })
      .catch((error) => {
        reject({ error });
      });
  });
};

SurveyController.archiveSurvey = (id) => {
  return new Promise((resolve, reject) => {
    Survey.archiveSurvey(id)
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        reject({ error });
      });
  });
};

SurveyController.deleteSurvey = (id) => {
  return new Promise((resolve, reject) => {
    Survey.deleteSurvey(id)
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        reject({ error });
      });
  });
};

SurveyController.getSurveyInsights = (
  survey_id,
  product_id,
  start_date,
  end_date,
) => {
  var queryData = {
    survey_id,
    product_id,
    start_date,
    end_date,
  };

  if (getSurveyInsightsCancelToken) {
    getSurveyInsightsCancelToken.cancel(
      "Operation canceled due to a new request.",
    );
  }
  getSurveyInsightsCancelToken = axios.CancelToken.source();

  return new Promise(function (resolve) {
    Survey.getSurveyInsights(queryData, getSurveyInsightsCancelToken?.token)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

SurveyController.editSentiment = (queryData) => {
  return new Promise((resolve, reject) => {
    Survey.editSentiment(queryData)
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

SurveyController.editCategories = (queryData) => {
  return new Promise((resolve, reject) => {
    Survey.editCategories(queryData)
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

SurveyController.editThemes = (queryData) => {
  return new Promise((resolve, reject) => {
    Survey.editThemes(queryData)
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

SurveyController.exportSurvey = (queryData) => {
  return new Promise(function (resolve) {
    Survey.exportSurvey(queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: error,
        });
      });
  });
};

SurveyController.excelExport = (queryData) => {
  return new Promise((resolve, reject) => {
    Survey.excelExport(queryData)
      .then((response) => {
        resolve({ data: response });
      })
      .catch((error) => {
        reject({ data: error });
      });
  });
};

SurveyController.duplicateSurvey = (id, queryData) => {
  return new Promise((resolve, reject) => {
    Survey.duplicateSurvey(id, queryData)
      .then((response) => {
        resolve({ data: response?.data?.data });
      })
      .catch((error) => {
        reject({ error });
      });
  });
};

// SurveyController.getSurveyInsights = (
//   survey_id,
//   product_id,
//   start_date,
//   end_date
// ) => {
//   var queryData = {
//     survey_id,
//     product_id,
//     start_date,
//     end_date,
//   };
//   return new Promise(function (resolve) {
//     Survey.getSurveyInsights(queryData)
//       .then((response) => {
//         resolve({
//           data: response.data.data,
//         });
//       })
//       .catch((error) => {
//         resolve({
//           data: [],
//           errorMsg: error,
//         });
//       });
//   });
// };

SurveyController.getSurveyspanelList = () => {
  return new Promise(function (resolve) {
    Survey.getSurveyspanelList()
      .then((response) => {
        resolve({
          data: response?.data?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
SurveyController.submitSurveyspanel = (
  age,
  survey_id,
  gender,
  industry,
  survey_link,
  survey_name,
  country,
) => {
  let queryData = {
    age,
    survey_id,
    gender,
    industry,
    survey_link,
    survey_name,
    country,
  };
  return new Promise(function (resolve) {
    Survey.submitSurveyspanel(queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
SurveyController.SurveyspanelById = (id, queryData) => {
  return new Promise(function (resolve) {
    Survey.SurveyspanelById(id, queryData)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          error: error,
        });
      });
  });
};
SurveyController.submitSurveysSkip = (survey_id, queryData) => {
  return new Promise(function (resolve) {
    Survey.submitSurveysSkip(survey_id, queryData)
      .then((response) => {
        resolve({
          data: response?.data?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
export default SurveyController;
