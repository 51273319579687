import moment from "moment";
import { isEmptyArray } from "utils/helpers";
import {
  handleAuthorActivity,
  handleXlsData,
  handleSortedCase,
} from "../excelCommonFn";
import { handleShowRemainingTime } from "./inbox";

export const engagementAnalyticsSLAs = (response, lang) => {
  if (isEmptyArray(response)) {
    return [];
  } else {
    let avgSla = [],
      avgPerformanceAgents = [],
      avgPerformanceTeams = [],
      avgPerformanceRoutings = [],
      avgPerformanceSlas = [],
      engagVolume = [],
      hitsActivity = [],
      missesActivity = [],
      slaTimeDistribution = [],
      allSheetData = [];

    Object.entries(response)?.forEach(([key, value]) => {
      switch (key) {
        case "avgPerformanceAgents":
          const { data: agentsStats, agents } = value;
          avgPerformanceAgents = handleAgentPerformanceExcelPage(
            agentsStats,
            agents,
            "agent_id",
            lang?.agent_performance_grouped_agents_column,
            lang,
          );
          allSheetData.push([
            avgPerformanceAgents,
            lang?.agents_perfomance_excel_agent,
          ]);
          break;
        case "avgPerformanceTeams":
          const { data: teamsStats, teams } = value;
          avgPerformanceTeams = handleAgentPerformanceExcelPage(
            teamsStats,
            teams,
            "team_id",
            lang?.agent_performance_grouped_teams_column,
            lang,
          );
          allSheetData.push([
            avgPerformanceTeams,
            lang?.agents_perfomance_excel_team,
          ]);
          break;
        case "avgPerformanceRoutings":
          const { data: routingsStats, routings } = value;
          avgPerformanceRoutings = handleAgentPerformanceExcelPage(
            routingsStats,
            routings,
            "routing_id",
            lang?.agent_performance_grouped_routings_column,
            lang,
          );
          allSheetData.push([
            avgPerformanceRoutings,
            lang?.agents_perfomance_excel_routing,
          ]);
          break;
        case "avgPerformanceSlas":
          const { data: slasStats, slas } = value;
          avgPerformanceSlas = handleAgentPerformanceExcelPage(
            slasStats,
            slas,
            "sla_id",
            lang?.agent_performance_grouped_slas_column,
            lang,
          );
          allSheetData.push([
            avgPerformanceSlas,
            lang?.agents_perfomance_excel_sla,
          ]);
          break;
        case "avgSla":
          const { prevDate, currDate, data } = value;
          const cDate = lang?.[currDate]
            ? lang?.[currDate]
            : currDate?.replace?.(" ", "");
          const currValText = lang?.avg_slas_curr_val?.replace?.(
            "{time}",
            cDate,
          );
          const prevValText = lang?.avg_slas_prev_val?.replace?.(
            "{time}",
            prevDate?.replace?.(" ", ""),
          );
          data?.map((i) => {
            avgSla.push({
              [lang.avg_slas_metrics]: lang[i?.name] ? lang[i?.name] : i?.name,
              [currValText]:
                i?.name === "avgs_hits_count" || i?.name === "avgs_misses_count"
                  ? i?.currValue
                  : handleShowRemainingTime(i?.currValue, lang),
              [prevValText]:
                i?.name === "avgs_hits_count" || i?.name === "avgs_misses_count"
                  ? i?.prevValue
                  : handleShowRemainingTime(i?.prevValue, lang),
              [lang.avg_slas_change]: i?.percentage?.toFixed?.(2) + "%",
            });
          });
          allSheetData.push([avgSla, lang.avg_slas]);
          break;
        case "engagVolume":
          value?.hits?.map((item, i) => {
            engagVolume.push({
              [lang.date_quick]: moment
                .unix(item?.name)
                .utc()
                .format("YYYY-MM-DD"),
              [lang.xlsx_engag_hits]: item?.value,
              [lang.xlsx_engag_misses]: value?.misses?.[i]?.value,
            });
          });
          allSheetData.push([engagVolume, lang.engag_volume]);
          break;
        case "hitsActivity":
          handleAuthorActivity(value[0], value[1], value[2], lang)?.map((i) => {
            hitsActivity?.push({
              [lang?.day]: i[0],
              [lang?.hour_group]: i[1],
              [lang?.hits]: i[2],
            });
          });
          allSheetData.push([hitsActivity, lang?.hits_activity]);
          break;
        case "missesActivity":
          handleAuthorActivity(value[0], value[1], value[2], lang)?.map((i) => {
            missesActivity?.push({
              [lang?.day]: i[0],
              [lang?.hour_group]: i[1],
              [lang?.misses]: i[2],
            });
          });
          allSheetData.push([missesActivity, lang?.misses_activity]);
          break;
        case "slaTimeDistribution":
          value?.map((item) => {
            slaTimeDistribution.push({
              [lang?.name]: lang[item?.name],
              [lang?.sla_time_FRT]: item?.value?.[0],
              [lang?.sla_time_NRT]: item?.value?.[1],
              [lang?.sla_time_TTC]: item?.value?.[2],
              [lang?.sla_time_TUT]: item?.value?.[3],
              [lang?.total]:
                item?.value?.[0] +
                item?.value?.[1] +
                item?.value?.[2] +
                item?.value?.[3],
            });
          });
          allSheetData.push([
            slaTimeDistribution,
            lang?.slas_time_distribution,
          ]);
          break;
        default:
          break;
      }
    });
    return handleXlsData(allSheetData);
  }
};

const handleAgentPerformanceExcelPage = (
  stats,
  entities,
  entityId,
  name,
  lang,
) => {
  const data = [];
  stats?.map((item, i) => {
    const entity = entities?.find(
      (entity) => +entity?.id === +item?.[entityId],
    );
    let agentsObj = {};
    if (entityId !== "agent_id") {
      agentsObj = {
        [lang?.agent_performance_tut]: handleShowRemainingTime(item?.tut, lang),
      };
    }
    // if entity is not found we will skip this iteration since we can't find the entity
    if (!entity) return;
    data.push({
      [name]: entity?.name,
      ...agentsObj,
      [lang?.agent_performance_frt]: handleShowRemainingTime(item?.frt, lang),
      [lang?.agent_performance_nrt]: handleShowRemainingTime(item?.nrt, lang),
      [lang?.agent_performance_ttc]: handleShowRemainingTime(item?.ttc, lang),
      [lang?.agent_performance_hits]: item?.hits_count,
      [lang?.agent_performance_misses]: item?.misses_count,
      [lang?.agent_performance_compliance_rate]: item?.compliance_rate,
    });
  });
  return data;
};
