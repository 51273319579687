import { Box, Container, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/pro-light-svg-icons";
import { Link } from "react-router-dom";
import "./deletedAlertView.scss";
import DOMPurify from "dompurify";

const DeletedAlertView = (props) => {
  const intl = useIntl();

  return (
    <Container maxWidth="xl" className="">
      <Box className="deleted-alert-view-wrapper">
        <FontAwesomeIcon icon={faBan} className="alert-ban-icon" />
        <Typography>
          {CheckValueLocale(
            props?.deletedSurvey ? "alert_survey_deleted" : "alert_deleted",
            "",
            {},
            intl,
          )}
        </Typography>

        {props?.deletedSurvey ? (
          <Box className="deleted-survey-alert-desc">
            <span
              className="deleted-survey-alert-span"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  CheckValueLocale("alert_survey_deleted_desc", "", {}, intl),
                ),
              }}
            />
            <Link className="alerts-link" to="/survey/list">
              {CheckValueLocale("surveys", "", {}, intl)}
            </Link>
          </Box>
        ) : (
          <Box className="deleted-alert-desc">
            {CheckValueLocale("alert_deleted_desc", "", {}, intl)}
            <Link className="alerts-link" to="/alerts">
              {CheckValueLocale("alerts", "", {}, intl)}
            </Link>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default DeletedAlertView;
