import React, { useEffect, useRef } from "react";
import {
  Box,
  CircularProgress,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { useGetCompanyDomains } from "../hooks/useGetCompanyDomains";
import LucButton from "shared/lucButton/lucButton";
import DomainListItem from "./domainListItem";

const SelectDomainModalBody = (props) => {
  const {
    surveyData,
    currentDomainsPage,
    setCurrentDomainsPage,
    companyDomainsData,
    setCompanyDomainsData,
    companyDomainsList,
    setCompanyDomainsList,
    selectedDomains,
    setSelectedDomains,
    setSnackBar,
    setStep,
  } = props;

  const intl = useIntl();
  const listRef = useRef(null);

  useEffect(() => {
    if (!selectedDomains?.length && companyDomainsList?.length) {
      const verifiedDomains = companyDomainsList?.filter(
        (domain) => domain?.is_verified,
      );

      const defaultDomain = verifiedDomains?.length
        ? verifiedDomains?.[0]
        : companyDomainsList?.[0];

      setSelectedDomains([
        {
          id: defaultDomain?.id,
          domain: defaultDomain?.domain,
          is_verified: defaultDomain?.is_verified,
          attached_surveys: defaultDomain?.attached_surveys,
          token: defaultDomain?.domain_verification_token,
          allow_all_pages: true,
          allowed_pages: [],
        },
      ]);
    }
  }, [surveyData, companyDomainsList]);

  const { mutate: getCompanyDomains, isPending: showMoreLoading } =
    useGetCompanyDomains();

  const handleShowMore = async () => {
    await getCompanyDomains(
      { page: currentDomainsPage + 1 },
      {
        onSuccess: async (data) => {
          setCurrentDomainsPage((prev) => prev + 1);
          setCompanyDomainsData(data?.data?.domains);
          setCompanyDomainsList((prev) => [
            ...prev,
            ...data?.data?.domains?.data,
          ]);
        },
        onError: () => {
          setSnackBar({
            open: true,
            severity: "error",
            title: "something_went_wrong",
            message: "try_again",
          });
        },
      },
    );
  };

  const handleScroll = () => {
    if (listRef?.current) {
      const { scrollTop, scrollHeight, clientHeight } = listRef?.current;

      if (
        scrollTop + clientHeight >= scrollHeight - 10 &&
        !showMoreLoading &&
        currentDomainsPage < companyDomainsData?.pages_count
      )
        handleShowMore();
    }
  };

  useEffect(() => {
    const currentList = listRef?.current;
    if (currentList) currentList?.addEventListener("scroll", handleScroll);

    return () => {
      if (currentList) currentList?.removeEventListener("scroll", handleScroll);
    };
  }, [currentDomainsPage, companyDomainsData, showMoreLoading]);

  const hideStaticSelectedDomain =
    companyDomainsData?.pages_count === 1 && currentDomainsPage === 1;

  const domainsToDisplay = hideStaticSelectedDomain
    ? companyDomainsList
    : [
        ...(selectedDomains?.length
          ? [
              {
                id: selectedDomains?.[0]?.id,
                domain: selectedDomains?.[0]?.domain,
                attached_surveys: selectedDomains?.[0]?.attached_surveys || [],
                is_verified: selectedDomains?.[0]?.is_verified,
                domain_verification_token: selectedDomains?.[0]?.token,
              },
            ]
          : []),
        ...(companyDomainsList?.filter(
          (item) => Number(item?.id) !== Number(selectedDomains?.[0]?.id),
        ) || []),
      ];

  return (
    <Box className="select-domain-modal-body">
      {companyDomainsList?.length ? (
        <Typography className="select-domain-saved-domains-subtitle">
          {CheckValueLocale("embedded_survey_saved_domains", "", {}, intl)}
        </Typography>
      ) : null}

      <Box className="select-domain-list" ref={listRef}>
        {companyDomainsList?.length ? (
          <RadioGroup
            className="select-domain-list-group"
            value={Number(selectedDomains?.[0]?.id)}
          >
            {[...(domainsToDisplay || [])]?.map((domain) => (
              <DomainListItem
                key={domain?.id}
                surveyData={surveyData}
                domain={domain}
                setCurrentDomainsPage={setCurrentDomainsPage}
                setCompanyDomainsData={setCompanyDomainsData}
                setCompanyDomainsList={setCompanyDomainsList}
                selectedDomains={selectedDomains}
                setSelectedDomains={setSelectedDomains}
                setSnackBar={setSnackBar}
              />
            ))}
          </RadioGroup>
        ) : (
          <Typography id="select-domain-no-domains-desscription">
            {CheckValueLocale("embedded_survey_no_saved_domains", "", {}, intl)}
          </Typography>
        )}

        {showMoreLoading ? (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            my={2}
          >
            <CircularProgress size={24} />
          </Stack>
        ) : null}
      </Box>

      <Box className="select-domain-add-domain-btn-container">
        <LucButton
          id="embedded-survey-publish-add-domain-btn"
          variant="flat"
          size="small"
          startIcon={<FontAwesomeIcon icon={faPlus} fixedWidth />}
          onClick={() => setStep("add_domain")}
        >
          {CheckValueLocale("embedded_survey_add_new_domain", "", {}, intl)}
        </LucButton>
      </Box>
    </Box>
  );
};

export default SelectDomainModalBody;
