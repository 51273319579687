import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { CreateReportContext } from "pages/reports/context/reportContext";
import LucButton from "shared/lucButton/lucButton";

const CreateReportStepperFooter = () => {
  const {
    formik,
    step,
    setStep,
    availableMonitorTypesLoading,
    createReportLoading,
  } = useContext(CreateReportContext);

  const intl = useIntl();

  const navigate = useNavigate();

  const handleBackStep = () => {
    if (step > 0) {
      setStep((prevActiveStep) => prevActiveStep - 1);
      formik?.setTouched({});
      formik?.setSubmitting(false);
    }
  };

  return (
    <Box className="create-report-stepper-footer">
      {step > 0 ? (
        <LucButton
          id="create-report-back-btn"
          type="secondary"
          variant="outline"
          onClick={handleBackStep}
        >
          {CheckValueLocale("back", "", {}, intl)}
        </LucButton>
      ) : (
        <Box />
      )}

      <Box className="create-report-stepper-footer-next-cancel-btns-container">
        <LucButton
          id="report-create-report-cancel-btn"
          variant="flat"
          onClick={() => navigate("/reports/list")}
        >
          {CheckValueLocale("cancel", "", {}, intl)}
        </LucButton>
        <LucButton
          id={`create-report-${step > 1 ? "submit" : "next"}-btn`}
          disabled={availableMonitorTypesLoading}
          loading={createReportLoading}
          onClick={formik?.handleSubmit}
        >
          {CheckValueLocale(
            step > 1 ? "create_report" : "next_step",
            "",
            {},
            intl,
          )}
        </LucButton>
      </Box>
    </Box>
  );
};

export default CreateReportStepperFooter;
