import moment from "moment";
import { Link } from "react-router-dom";
import { Box, Button, Typography, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import { useIntl } from "react-intl";
import { CheckValueLocale, getSocialIcon } from "utils/helpers";
import ErrorIcon from "@mui/icons-material/Error";
import CustomizedTooltip from "components/customizedTooltip";
import ReportsListOptionsDropdown from "../options/reportsListOptionsDropdown";

export const useGenerateReportsListBody = (data, setSnackbar) => {
  const intl = useIntl();

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.white,
      "&::before": {
        backgroundColor: theme.palette.common.white,
        border: "1px solid #999",
      },
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  const showWarningMsg = (attributes) => {
    const { platform, issues, is_luci_report, has_luci_quota } =
      attributes || {};

    // Show warning icon if report has issues [is_limit_reached, are_monitors_deleted, paused_monitors, missing luci_quota]
    const reportHasIssues =
      issues?.is_limit_reached ||
      issues?.are_monitors_deleted ||
      issues?.paused_monitors?.length ||
      (is_luci_report && !has_luci_quota);

    if (reportHasIssues) {
      let tooltipTitle = "";

      // The priority is: is_limit_reached > are_monitors_deleted > paused_monitors > missing luci_quota
      if (issues?.is_limit_reached) {
        tooltipTitle = "report_list_paused_due_to_limit_reached";
      } else if (issues?.are_monitors_deleted) {
        tooltipTitle =
          platform === "CXM"
            ? "report_list_paused_due_to_channel_deleted"
            : "report_list_paused_due_to_monitor_deleted";
      } else if (issues?.paused_monitors?.length) {
        tooltipTitle =
          platform === "CXM"
            ? "report_list_paused_due_to_channel_paused"
            : "report_list_paused_due_to_monitor_paused";
      } else if (is_luci_report && !has_luci_quota) {
        tooltipTitle = "report_list_luci_issue";
      }

      return (
        <Box component="span">
          <CustomizedTooltip
            id="warning-tooltip-report"
            title={CheckValueLocale(tooltipTitle, "", {}, intl)}
            placement="bottom"
            arrow
            styles={{ height: "auto", width: "170px", padding: "10px" }}
          >
            <Box className="warning-icon-countainer">
              <ErrorIcon
                className="dashboard-warning-icon report-warning-icon"
                fontSize="small"
              />
            </Box>
          </CustomizedTooltip>
        </Box>
      );
    }

    return false; // To return false if report has no issues to not show highlighted row
  };

  const showReportDataSourcesData = (dataSources) => {
    let dataSourcesArray = [];

    dataSources?.map((item) =>
      item?.name === "TWITTER" ||
      item?.name === "FACEBOOK" ||
      item?.name === "INSTAGRAM"
        ? dataSourcesArray?.push(`${item?.name}_${item?.type}`)
        : dataSourcesArray?.push(`${item?.name}`),
    );

    const showDatasources = dataSourcesArray.slice(0, 7);
    const showLimitDatasources = dataSourcesArray.slice(
      7,
      dataSourcesArray?.length,
    );

    const getDatasoucrsContent = (data) => {
      return data?.map((item, key) => (
        <Box key={key}>{getSocialIcon(item)}</Box>
      ));
    };
    return (
      <>
        <Box className="tooltip-content">
          {dataSourcesArray?.length
            ? getDatasoucrsContent(showDatasources)
            : "-"}
          {dataSourcesArray?.length >= 8 ? (
            <LightTooltip
              title={
                <Box className="tooltip-content">
                  {getDatasoucrsContent(showLimitDatasources)}
                </Box>
              }
              arrow
              placement="top"
              id="tooltip-datasouces-report"
            >
              <Typography component="span" className="tooltip-content-count">
                {`(+${showLimitDatasources?.length})`}
              </Typography>
            </LightTooltip>
          ) : null}
        </Box>
      </>
    );
  };

  return data?.map((item) => {
    const reportHasIssues = showWarningMsg(item?.attributes);

    const { title, manual_status } = item?.attributes || {};

    return {
      id: item?.id,
      className: !reportHasIssues ? "" : "warning",
      data: [
        {
          body: (
            <Box className="report-name-container">
              {reportHasIssues ? reportHasIssues : null}
              <Link
                className="report-name-text"
                to={`/reports/view-report/${item?.id}`}
              >
                <Box className="report-name-container">
                  {title?.length > 18 ? (
                    <CustomizedTooltip
                      title={title}
                      placement="top"
                      arrow
                      styles={{ fontWeight: "500", fontSize: "12px" }}
                    >
                      <Button
                        variant="text"
                        sx={{ padding: 0, margin: 0, borderRadius: 0 }}
                        id="report-create-new-show-report-text-btn"
                      >
                        {title?.substring(0, 18)}...
                      </Button>
                    </CustomizedTooltip>
                  ) : (
                    <Typography variant="subtitle2">{title}</Typography>
                  )}
                </Box>
              </Link>
            </Box>
          ),
          className: "report-name-td",
        },
        {
          body: item?.attributes?.report_type?.length
            ? CheckValueLocale(
                `reports_${item?.attributes?.report_type}`,
                "—",
                {},
                intl,
              )
            : "—",
        },
        {
          body: item?.attributes?.platform?.length
            ? item?.attributes?.platform === "SM"
              ? CheckValueLocale("social_listening_v2", "—", {}, intl)
              : CheckValueLocale("channel_analytics", "—", {}, intl)
            : "—",
        },
        {
          body: item?.attributes?.data_sources?.length
            ? showReportDataSourcesData(item?.attributes?.data_sources)
            : "—",
          className: "reports-social-icons",
        },
        {
          body: item?.attributes?.frequency?.length
            ? CheckValueLocale(
                `report_${item?.attributes?.frequency?.toLowerCase()}`,
                "—",
                {},
                intl,
              )
            : "—",
        },
        {
          body:
            item?.attributes?.status === "active"
              ? item?.attributes?.next_delivery
                ? moment(item?.attributes?.next_delivery)
                    .utcOffset(item?.attributes?.next_delivery)
                    .format("YYYY-MM-DD (h:mma)")
                : "—"
              : "—",
          className: "date-direction",
        },
        {
          body: item?.attributes?.created_by
            ? item?.attributes?.created_by
            : "—",
          isCreatedBy: true,
        },
        {
          body: (
            <Box
              className={`status ${manual_status === true ? "active" : "paused"}`}
            >
              {CheckValueLocale(
                manual_status == true ? "Active" : "reports_paused",
                "",
                {},
                intl,
              )}
            </Box>
          ),
        },

        {
          body: (
            <ReportsListOptionsDropdown
              id={item?.id}
              title={title}
              paused={!manual_status}
              setSnackbar={setSnackbar}
            />
          ),
        },
      ],
    };
  });
};
