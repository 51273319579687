import { faCopy } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Tooltip } from "@mui/material";
import profileImg from "images/engagements-images/profileImg.png";
import { Fragment, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import LucButton from "shared/lucButton/lucButton";
import TruncatedTooltip from "shared/truncatedTooltip/truncatedTooltip";
import { CheckValueLocale, classNames } from "utils/helpers";

export const ContactMessage = ({ contact = {}, messageId }) => {
  const intl = useIntl();
  const [hasCopied, setHasCopied] = useState(false);
  const { name, phone } = contact;

  if (!phone) {
    return null;
  }

  const handleContactCopy = async () => {
    try {
      await navigator?.clipboard?.writeText?.(phone);
      setHasCopied(true);
      setTimeout(() => {
        setHasCopied(false);
      }, 2000);
    } catch (err) {
      // noop
    }
  };
  return (
    <Box className="chat-file-message">
      <Box className="chat-file-message-image">
        <img src={profileImg} alt="contact-icon" />
      </Box>
      <Box className="chat-file-message-info">
        <TruncatedTooltip
          className="chat-file-message-info-name"
          title={phone}
          placement="bottom"
          arrow
        />
        {name ? (
          <TruncatedTooltip
            className="chat-file-message-info-type"
            title={name}
            placement="bottom"
            arrow
          />
        ) : null}
      </Box>
      <Tooltip
        title={CheckValueLocale(
          hasCopied ? "custom_domain_copied" : "copy",
          "",
          {},
          intl,
        )}
        arrow
        placement="bottom"
        classes={{
          tooltip: classNames(
            "chat-media-tooltip-button",
            hasCopied ? "copied" : "",
          ),
        }}
      >
        <Box>
          <LucButton
            variant="text"
            onClick={handleContactCopy}
            minWidth={33}
            size="small"
            className="chat-file-message-download"
            id={`contact-field-${phone}`}
          >
            <FontAwesomeIcon icon={faCopy} />
          </LucButton>
        </Box>
      </Tooltip>
    </Box>
  );
};
