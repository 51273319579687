import AccountsListController from "services/controllers/accountsListController";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useDuplicateAccountsList = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id) => {
      await AccountsListController?.duplicateAccountsList(id);
      queryClient.invalidateQueries("accountsList");
    },
  });
};
