import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers/index";

const PageSelectBox = ({ pagesData, activeTabName }) => {
  const intl = useIntl();
  return (
    <>
      <Box id="shared-export-select-pages">
        <Box className="export-select-pages-head">
          {CheckValueLocale("export_select_pages", "", {}, intl)}
        </Box>
        <Box className="form-radio-parant">
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              defaultValue="currentPage"
              onChange={pagesData?.handleSelectPageChange}
            >
              <FormControlLabel
                value="currentPage"
                control={<Radio />}
                label={`${CheckValueLocale(
                  "export_current_page",
                  "",
                  {},
                  intl,
                )} (${CheckValueLocale(activeTabName, "", {}, intl)})`}
              />
              {pagesData?.selectPages && pagesData?.selectPages?.length ? (
                <FormControlLabel
                  value="analyticsPages"
                  control={<Radio />}
                  label={
                    <Box>
                      {CheckValueLocale("export_analytics_pages", "", {}, intl)}
                      <br />
                      {`(${pagesData?.selectPages
                        ?.map((page) => {
                          return CheckValueLocale(page, "", {}, intl);
                        })
                        .join(" , ")})`}
                    </Box>
                  }
                />
              ) : null}
            </RadioGroup>
          </FormControl>
        </Box>
      </Box>
      {pagesData?.showSelectPagesAlert ? (
        <Box className="filters-not-affect-export">
          {CheckValueLocale("filters_not_affect_exported", "", {}, intl)}
        </Box>
      ) : null}
    </>
  );
};

export default PageSelectBox;
