import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { MainMonitorsPage } from "services/api/mainMonitorsPage";

let getCredentialsCancelToken;
export const useGetCredentials = (
  monitorDataSource,
  monitorId,
  productId,
  sectionName,
  pageNumber,
  tweetId,
  accountUserId,
) => {
  return useQuery({
    queryKey: ["authorProfileData"],
    queryFn: () =>{
      if (getCredentialsCancelToken) {
        getCredentialsCancelToken.cancel(
          "Operation canceled due to a new request.",
        );
      }
      getCredentialsCancelToken = axios?.CancelToken?.source();
      return MainMonitorsPage.getAuthorProfileData(monitorDataSource, monitorId, { 
        params: {
          product_id: productId,
          section_name: sectionName,
          page_number: pageNumber,
          tweet_id: tweetId,
          account_user_id: accountUserId,
        },
        cancelToken: getCredentialsCancelToken?.token
      })
    },
    select: (data) => data?.data,
  });
};
