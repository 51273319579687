import { useMutation } from "@tanstack/react-query";
import SurveyController from "services/controllers/surveyController";

export const useCreateNewSurvey = () => {
  return useMutation({
    mutationFn: ({ queryData }) =>
      SurveyController.createSurveys({ ...queryData }),
    select: (data) => data?.data?.data,
    retry: false,
  });
};
