import { useEffect, useState } from "react";
import {
  Divider,
  Typography,
  Box,
  Tooltip,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale, isEmptyArray } from "utils/helpers";
import TestYourKeyword from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/testYourKeyword/testYourKeyword";
import HashtagUsedNumber from "pages/socialListing/MonitorsOptions/createMonitor/MonitorTypes/KeywordsMonitor/components/keywordHeader/hashtagUsedNumber";
import KeywordPopupModal from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/keywordPoupModal/keywordPopupModal";
import CopyIcon from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/keywordTypeComponent/copyIcon";
import KeywordTooltipChip from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/keywordTypeComponent/keywordTooltipChip";
import {
  checkValue,
  handleKeywordsPopUp,
} from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/monitorsSharedFunctions";
import {
  checkLabelTextEdit,
  handleInputFieldName,
  checkAllInputsField,
  checkKeywordPopup,
  handleInputChange,
  checkDirectionLang,
  handleInputMouseDown,
  checkKeywordsLimits,
} from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/kewordsSharedFunctions";
import { DropdownSection } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/monitorDropdowns/monitorDropdowns";
import SnackBar from "components/snackBar";
import SwitchElement from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/keywordTypeComponent/twitterKeywordCreateAndEdit/components/SwitchElement";
import CustomizedTooltip, {
  customizedStyledTooltip,
} from "components/customizedTooltip";
import DOMPurify from "dompurify";

const TwitterKeywords = (props) => {
  const intl = useIntl();
  const isRTL = intl?.locale === "ar";
  const {
    handleDelete,
    handleKeywords,
    keywords,
    handleValidation,
    handleData,
    setSQuestion,
    setUpdatedParams,
    updatedParams,
    monitorDetails,
    indexes,
    answer,
    spamAnswer,
    collectTweets,
    ignoreTweets,
    monitorErrors,
    setMonitorErrors,
    twitterCountries,
    setTwitterCountries,
    twitterLanguages,
    setTiwtterLanguages,
    twitterWordsCounter,
    getTwitterLanguagesAndCountries,
    twitterLanguagesAndCountries,
    monitorTwitterOtherFieldLimit,
    monitorTwitterKeywordLimit,
    isVarifiedAccounts,
    setIsVarifiedAccounts,
    ignoretweetsFlag,
    setIgnoreTweetsFlag,
  } = props;

  let keywordsInfo = [
    "keywords",
    "spam_keywords",
    "collect_tweets",
    "ignore_tweets",
  ];
  const valueArr = [answer, spamAnswer, collectTweets, ignoreTweets];
  const [focused, setFocused] = useState(false);

  const [err, setErr] = useState({
    errMsg: "",
    keyword: "",
  });
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  //keywords limit
  let twLimit = twitterWordsCounter >= monitorTwitterOtherFieldLimit;
  const { checkExceedLimited, messageLimits, titleLimits } =
    checkKeywordsLimits(twLimit);

  useEffect(() => {
    if (twitterWordsCounter >= monitorTwitterOtherFieldLimit) {
      setOpen(true);
    }
  }, [twitterWordsCounter]);

  const handleKeyDown = (event, keywordType, index, clickType) => {
    if (event.keyCode === 13 || clickType === "btn") {
      if (
        keywordType === "keywords" ||
        keywordType === "spam_keywords" ||
        keywordType === "collect_tweets" ||
        keywordType === "ignore_tweets"
      ) {
        const limit =
          keywordType === "keywords"
            ? monitorTwitterKeywordLimit
            : monitorTwitterOtherFieldLimit;
        handleKeywords(event, keywordType, "twitter", index, clickType, limit);
        handleUpdateParams(keywordType);
      }
    }
  };

  const handleDeleteWord = (e, index, keywordType) => {
    handleDelete(e, index, keywordType, indexes, "twitter");
    handleUpdateParams(keywordType);
  };
  /* __________________________________________________________ */
  /* This Function updates the parameters on add or delete______ */
  const handleUpdateParams = (keywordType) => {
    if (keywordType === "keywords") {
      setUpdatedParams({
        ...updatedParams,
        keywords: {
          ...updatedParams.keywords,
          TWITTER: Object.values(keywords[0])[0][0]?.keywords?.join(),
        },
      });
    }
    if (keywordType === "spam_keywords") {
      setUpdatedParams({
        ...updatedParams,
        spam_keywords: {
          ...updatedParams.spam_keywords,
          TWITTER: Object.values(keywords[0])[0][1].spam_keywords?.join(),
        },
      });
    }
    if (keywordType === "collect_tweets") {
      setUpdatedParams({
        ...updatedParams,
        included_twitter_accounts: Object.values(
          keywords[0],
        )[0][2].collect_tweets?.join(),
      });
    }
    if (keywordType === "ignore_tweets") {
      setUpdatedParams({
        ...updatedParams,
        excluded_twitter_accounts: Object.values(
          keywords[0],
        )[0][3].ignore_tweets?.join(),
      });
    }
  };

  // Keep tracking for any error related to Twitter keyword
  useEffect(() => {
    setMonitorErrors({
      ...monitorErrors,
      tw_keywords: keywords[indexes]?.twitter[0]?.errorMsg,
    });
  }, [keywords[indexes]?.twitter[0]?.errorMsg]);
  useEffect(() => {
    setMonitorErrors({
      ...monitorErrors,
      tw_spam_keywords: keywords[indexes]?.twitter[1]?.errorMsg,
    });
  }, [keywords[indexes]?.twitter[1]?.errorMsg]);
  useEffect(() => {
    setMonitorErrors({
      ...monitorErrors,
      tw_include_users: keywords[indexes]?.twitter[2]?.errorMsg,
    });
  }, [keywords[indexes]?.twitter[2]?.errorMsg]);
  useEffect(() => {
    setMonitorErrors({
      ...monitorErrors,
      tw_exclude_users: keywords[indexes]?.twitter[3]?.errorMsg,
    });
  }, [keywords[indexes]?.twitter[3]?.errorMsg]);

  const applySelectionConditionFn = (optionsToBeAdded) => {
    return (
      optionsToBeAdded + twitterWordsCounter <= monitorTwitterOtherFieldLimit
    );
  };

  const onCoutriesChange = (newCoutries = []) => {
    setUpdatedParams({
      ...updatedParams,
      twitterCountries: newCoutries,
    });
    setTwitterCountries(newCoutries);
  };
  const onLanguagesChange = (newLanguages = []) => {
    setUpdatedParams({
      ...updatedParams,
      twitterLanguages: newLanguages,
    });
    setTiwtterLanguages(newLanguages);
  };
  useEffect(() => {
    getTwitterLanguagesAndCountries();
  }, []);

  const handleUpdatesSwitch = (e) => {
    const { name, checked } = e?.target;
    if (name === "show-post-verifird-user") {
      setIsVarifiedAccounts(checked);
      setUpdatedParams({
        ...updatedParams,
        is_varified_accounts: checked,
      });
    } else if (name === "exclude-reposts-quotes") {
      setIgnoreTweetsFlag(checked);
      setUpdatedParams({
        ...updatedParams,
        ignore_retweets: checked,
      });
    }
  };

  return (
    <>
      <Box className="inline-align">
        <Box>
          <Typography className="section-header">
            {CheckValueLocale("twitter", "", {}, intl)}
          </Typography>
          <Typography className="posts-used">
            {CheckValueLocale("posts_used", "", {}, intl)}:{" "}
            {monitorDetails?.posts_count_stats?.TWITTER
              ? monitorDetails?.posts_count_stats?.TWITTER.toLocaleString()
              : 0}
          </Typography>
        </Box>
        {/* __________________________________________________________ */}

        <Box className="inline-flex data-source-setting">
          <TestYourKeyword
            keywords={monitorDetails.keywords.TWITTER}
            spamKeywords={monitorDetails.spam_keywords.TWITTER}
          />
        </Box>
      </Box>
      {/* __________________________________________________________ */}

      <Box className="section-box">
        {keywordsInfo.map((keyword, index) => {
          const twitterValues = Object.values(keywords[indexes].twitter[index]);
          return (
            <>
              <Box className="section-box-data-keyword-setting">
                {keyword === "keywords" || keyword === "spam_keywords" ? (
                  <CustomizedTooltip
                    title={CheckValueLocale(
                      "keywords_spam_keywords_tooltip",
                      "",
                      {},
                      intl,
                    )}
                    arrow
                    placement="bottom"
                    styles={{ ...customizedStyledTooltip, maxWidth: "200px" }}
                  >
                    <Typography className="main-titles underlined">
                      {CheckValueLocale(
                        handleInputFieldName(keyword),
                        "",
                        {},
                        intl,
                      )}
                      {keyword === "keywords" ? (
                        <Typography component="span" className="required">
                          *
                        </Typography>
                      ) : null}
                    </Typography>
                  </CustomizedTooltip>
                ) : (
                  <Typography className="main-titles">
                    {CheckValueLocale(
                      handleInputFieldName(keyword),
                      "",
                      {},
                      intl,
                    )}
                  </Typography>
                )}
                {/* __________________________________________________________ */}
                <TextField
                  id="outlined-email-input"
                  label={CheckValueLocale(
                    checkLabelTextEdit(keyword),
                    "",
                    {},
                    intl,
                  )}
                  className={`
                  spam-text-field input-field-edit 
                  ${
                    keyword === "keywords"
                      ? "input-keyword-field"
                      : "input-field-spam"
                  }
                  input-transform-label-${keyword}
                  ${checkDirectionLang(
                    keywords[indexes].twitter[index]?.answer,
                  )}`}
                  error={
                    keywords[indexes].twitter[index].errorMsg !== "" &&
                    Object.keys(keywords[indexes].twitter[index])[0] === keyword
                  }
                  variant="outlined"
                  onFocus={(e) => {
                    handleInputChange(
                      e,
                      keyword,
                      "twitter",
                      index,
                      indexes,
                      keywords,
                      setErr,
                      handleValidation,
                    );
                    setFocused(true);
                  }}
                  onBlur={() => setFocused(false)}
                  InputProps={{
                    endAdornment: focused && (
                      <InputAdornment onMouseDown={handleInputMouseDown}>
                        <Button
                          className="add-btn-with-plus add-btn-plus-setting"
                          onClick={(event) =>
                            handleKeyDown(event, keyword, index, "btn")
                          }
                          value={
                            Object.keys(
                              keywords[indexes]?.twitter[index],
                            )[0] === keyword
                              ? keywords[indexes].twitter[index].answer
                              : ""
                          }
                          id="monitor-options-tw-kw-add-keyword-btn"
                        >
                          {CheckValueLocale("add_plus", "", {}, intl)}
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    setSQuestion("twitter");
                    handleInputChange(
                      e,
                      keyword,
                      "twitter",
                      index,
                      indexes,
                      keywords,
                      setErr,
                      handleValidation,
                    );
                  }}
                  value={
                    Object.keys(keywords[indexes]?.twitter[index])[0] ===
                    keyword
                      ? keywords[indexes].twitter[index].answer
                      : ""
                  }
                  onKeyDown={(event) => handleKeyDown(event, keyword, index)}
                />
              </Box>
              {keywords[indexes]?.twitter?.map((obj) => {
                if (
                  Object.values(obj)[1] !== "" &&
                  Object.keys(obj)[0] === keyword
                ) {
                  const limit =
                    keyword === "keywords"
                      ? monitorTwitterKeywordLimit
                      : monitorTwitterOtherFieldLimit;
                  return (
                    <Box className="error-msg-keyword">
                      <Typography className="main-titles"></Typography>
                      <Box
                        component="p"
                        className="spam-text-field err-msg-text keywords-error-msg"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            CheckValueLocale(
                              Object.values(obj)[1],
                              "",
                              {
                                limit,
                              },
                              intl,
                            ),
                          ),
                        }}
                      />
                    </Box>
                  );
                }
              })}
              {/* ___________________________________________________________________________________ */}
              {/* Error Msg is fired in case of keywords input filed  is empty and save btn will not work  */}
              {keyword === "keywords" &&
                isEmptyArray(keywords[indexes]?.twitter[0]?.keywords) && (
                  <Box className="section-box-data">
                    <Typography className="main-titles"></Typography>
                    <Box
                      component="p"
                      className="spam-text-field err-msg-text keywords-error-msg"
                    >
                      {CheckValueLocale("this_field_required", "", {}, intl)}
                    </Box>
                  </Box>
                )}
              {/* Chip Section________________________________________________________________________ */}
              <Box className="chip-keywords-wrapper">
                <Box></Box>
                <Box className="spam-keywords-area">
                  {keywords[indexes]?.twitter?.map((obj) => {
                    if (
                      Object.values(obj)[0].length > 0 &&
                      Object.keys(obj)[0] === keyword
                    ) {
                      return checkValue(Object.values(obj)[0]).map(
                        (labels, index) => (
                          <>
                            <KeywordTooltipChip
                              onDelete={(e) =>
                                handleDeleteWord(e, index, Object.keys(obj)[0])
                              }
                              dataSource={"twitter"}
                              indexes={indexes}
                              index={index}
                              labels={labels}
                              keywordSetting
                            />
                          </>
                        ),
                      );
                    }
                  })}
                  {checkAllInputsField(keyword, keywordsInfo) && (
                    <KeywordPopupModal
                      keywords={handleKeywordsPopUp(
                        keyword,
                        keywords[0].twitter[0]?.keywords,
                        keywords[0].twitter[1]?.spam_keywords,
                        keywords[0].twitter[2]?.collect_tweets,
                        keywords[0].twitter[3]?.ignore_tweets,
                      )}
                      handleDelete={handleDeleteWord}
                      dataSourceType={"twitter"}
                      keywordType={checkKeywordPopup(keyword)}
                      dataSourceIndex={0} // dataSource index to handle delete keyword
                    />
                  )}
                  {/* Copy Icon___________________________________________________________________________ */}
                  {twitterValues[0].length !== 0 && (
                    <CopyIcon
                      checkCopyValue={twitterValues[2]}
                      handleData={(e) =>
                        handleData(e, twitterValues[0], indexes, index)
                      }
                    />
                  )}
                </Box>
              </Box>
              <Divider />
            </>
          );
        })}
        <DropdownSection
          options={
            twitterLanguagesAndCountries?.countries?.length
              ? twitterLanguagesAndCountries?.countries
              : []
          }
          placeholder={CheckValueLocale("select_countries", "", {}, intl)}
          label={CheckValueLocale("x_countries", "", {}, intl)}
          tooltip={CheckValueLocale("x_countries_tooltip", "", {}, intl)}
          onChange={onCoutriesChange}
          containerClassName="section-box-data-keyword-setting"
          selectWrapperClassName="select-wrapper-countries"
          tooltipPosition={isRTL ? "left" : "right"}
          defaultValue={twitterCountries}
          applySelectionConditionFn={applySelectionConditionFn}
          errorText="other_fields_keywords_error_limit"
          limit={monitorTwitterOtherFieldLimit}
        />
        <Box className="chip-keywords-wrapper" />
        <Divider />
        <DropdownSection
          options={
            twitterLanguagesAndCountries?.languages?.length
              ? twitterLanguagesAndCountries?.languages
              : []
          }
          placeholder={CheckValueLocale("select_languages", "", {}, intl)}
          label={CheckValueLocale("languages", "", {}, intl)}
          tooltip={CheckValueLocale("targeted_languages_tooltip", "", {}, intl)}
          onChange={onLanguagesChange}
          containerClassName="section-box-data-keyword-setting"
          selectWrapperClassName="select-wrapper-languages"
          tooltipPosition={isRTL ? "left" : "right"}
          defaultValue={twitterLanguages}
          applySelectionConditionFn={applySelectionConditionFn}
          errorText="other_fields_keywords_error_limit"
          limit={monitorTwitterOtherFieldLimit}
        />
        <Box className="chip-keywords-wrapper" />
        <Divider />
        <Box className="section-box-data-keyword-setting">
          <Typography className="main-titles">
            {CheckValueLocale("other_filters", "", {}, intl)}
          </Typography>
          <Box component={"span"} className="keyword-switch-box-form-edit">
            <SwitchElement
              checked={ignoretweetsFlag}
              onChange={handleUpdatesSwitch}
              name="exclude-reposts-quotes"
              label={"exclude_reposts_quotes"}
              descriptionLabel={"exclude_reposts_quotes_description"}
            />

            <SwitchElement
              checked={isVarifiedAccounts}
              onChange={handleUpdatesSwitch}
              name="show-post-verifird-user"
              label={"show_post_verifird_user"}
              descriptionLabel={"show_post_verifird_user_description"}
            />
          </Box>
        </Box>
        <Box className="chip-keywords-wrapper" />
        <Divider />
      </Box>
      {/* SnackBar that appear when get maximum of wordCount  */}
      {checkExceedLimited && (
        <>
          <SnackBar
            open={open}
            handleClose={handleClose}
            message={
              <p className="error-snackbar-message">
                {CheckValueLocale(
                  messageLimits,
                  "",
                  { limit: monitorTwitterOtherFieldLimit },
                  intl,
                )}
              </p>
            }
            title={CheckValueLocale(titleLimits, "", {}, intl)}
            severity="error"
          />
        </>
      )}
    </>
  );
};

export default TwitterKeywords;
