import { Box, Container, Typography } from "@mui/material";
import CircularLoading from "components/circularProgress";
import SnackBar from "components/snackBar";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { CheckValueLocale } from "utils/helpers";
import { ViewTeamHeader } from "./components/viewTeamHeader";
import "./viewTeam.scss";
import { TeamDetailsCards } from "./components/teamDetailsCards";
import { TeamMembersDetails } from "./components/teamMembersDetails";
import { workgingHoursData } from "../../userManagementPage/subPages/shardComponents/tabs/workingHours";
import { transformTramWorkingHoursResponse } from "../editTeam/editTeamUtils";
import { TeamWorkingHours } from "./components/teamWorkingHours";
import ActivityLog from "pages/settings/pages/userManagementPage/components/userManagementTable/userActivityLog/activityLog";
import { useGetTeamDetailsData } from "../hooks/useGetTeamDetailsData";
import { useGetTeamMembersForView } from "../hooks/useGetTeamMembersForView";

export const ViewTeam = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [team, setTeam] = useState({});
  const intl = useIntl();
  const [members, setMembers] = useState({});
  const [workingHours, setWorkingHours] = useState({
    workingHours: workgingHoursData,
  });
  const [autoReplyMessages, setAutoReplyMessages] = useState();

  const [teamsSnackBar, setTeamsSnackBar] = useState({
    message: "",
    severity: "",
    title: "",
    openSnackBar: false,
  });
  const handleCloseSnackBar = () => {
    setTeamsSnackBar({
      openSnackBar: false,
    });
  };

  const {
    mutate: handleTeamMembersForView,
    isPending: isTeamMemberForEditLoading,
  } = useGetTeamMembersForView();

  const {
    data: teamDetailsData,
    isPending: isPendingTeamDetailsData,
    isError: isErrorTeamDetailsData,
  } = useGetTeamDetailsData(id);
  useEffect(() => {
    if (!!teamDetailsData && !isErrorTeamDetailsData) {
      setTeam(teamDetailsData?.data?.data?.attributes);
      setAutoReplyMessages(
        teamDetailsData?.data?.data?.attributes?.auto_reply_message,
      );
      setWorkingHours({
        workingHours:
          transformTramWorkingHoursResponse(
            teamDetailsData?.data?.data?.attributes?.team_working_time?.[0]
              ?.working_time,
          ) || {},
      });
    } else if (isErrorTeamDetailsData) {
      setTeamsSnackBar({
        message: "try_again_error_message",
        severity: "error",
        title: "failed_error_message",
        openSnackBar: true,
      });
    }
  }, [teamDetailsData, isErrorTeamDetailsData]);

  const initializeTeamDetailsData = () => {
    handleTeamMembersForView(
      { id },
      {
        onSuccess: (res) => {
          setMembers(res?.data);
        },
        onError: () => {
          setTeamsSnackBar({
            message: "try_again_error_message",
            severity: "error",
            title: "failed_error_message",
            openSnackBar: true,
          });
        },
      },
    );
  };

  useEffect(() => {
    initializeTeamDetailsData();
  }, []);

  useEffect(() => {
    // if the search has a created_successfully or updated_successfully message status,
    // then show a snackbar with the message
    const search = location?.search;
    let snackbarSuccessMessage;
    if (search && search?.includes("status=added_successfully")) {
      snackbarSuccessMessage = "team_added_successfully";
    } else if (search && search?.includes("status=updated_successfully")) {
      snackbarSuccessMessage = "team_edits_saved_successfully";
    }

    if (snackbarSuccessMessage) {
      // show the message and then remove it from the url
      setTeamsSnackBar({
        message: snackbarSuccessMessage,
        severity: "success",
        openSnackBar: true,
      });
      navigate(window.location.pathname);
    }
  }, []);

  if (isPendingTeamDetailsData || isTeamMemberForEditLoading)
    return <CircularLoading />;
  return (
    <Container maxWidth="xl" className="view-team-container">
      <ViewTeamHeader team={team} id={id} />
      <Box className="view-team-main-container">
        <Box className="view-team-details">
          {/* Team Information (Team name and Team Product) */}
          <Box className="view-team-details-section">
            <Typography variant="h3" className="view-team-details-title">
              {CheckValueLocale("team_information", "", {}, intl)}
            </Typography>
            <TeamDetailsCards teamDetails={team} />
          </Box>
          <TeamWorkingHours
            workingHours={workingHours}
            autoReplyMessages={autoReplyMessages}
          />
          {/* Team Members */}
          <TeamMembersDetails
            members={members}
            setMembers={setMembers}
            teamId={id}
            workingHours={workingHours}
          />
          <Box className="view-team-activity-section">
            <Typography variant="h3">
              {CheckValueLocale("team_activity_log", "", {}, intl)}
            </Typography>
            <ActivityLog isTeamActivityLog teamId={id} />
          </Box>
        </Box>
      </Box>
      <SnackBar
        open={teamsSnackBar?.openSnackBar}
        severity={teamsSnackBar?.severity}
        message={CheckValueLocale(teamsSnackBar?.message, "", {}, intl)}
        title={CheckValueLocale(teamsSnackBar?.title, "", {}, intl)}
        handleClose={handleCloseSnackBar}
      />
    </Container>
  );
};
