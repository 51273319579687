import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Typography,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { CheckValueLocale } from "utils/helpers";
import "../../main.scss";
import "./luciAnswer.scss";
import { useIntl } from "react-intl";
import LuciHeader from "../luciHeader/luciHeader";
import AppliedFilters from "../appliedFilters.js/appliedFiltersContent";
import LuciController from "services/controllers/luciController";
import moment from "moment";
import { truncate } from "utils/helpers";
import Services from "services/api/Services";

const LuciAnswer = (props) => {
  const intl = useIntl();
  const [listAnswer, setListAnswer] = useState();
  const [requestsLoading, setRequestsLoading] = useState(true);
  const {
    filterParams,
    startDate,
    endDate,
    setShowLuciAnswers,
    setShowConfirmationPopup,
    setShowRequests,
    isAnswerPage,
    questionStatusNotification,
    questionNotificationId,
    showSurveyquestion,
    isBenchmark,
  } = props;
  const getLuciListAnswer = () => {
    setRequestsLoading(true);
    LuciController.getLuciListAnswer(props?.questionIdAnswer).then((result) => {
      setListAnswer(result?.data?.data);
    });
    setRequestsLoading(false);
  };
  const [isInValidImage, setIsInValidImage] = useState(false);

  const handleImageError = () => {
    setIsInValidImage(true);
  };
  useEffect(() => {
    getLuciListAnswer();
  }, [questionStatusNotification, questionNotificationId]);
  const localizedDateTime = (requestDate) => {
    return window.localStorage.lang === "en"
      ? moment.unix(parseInt(requestDate)).utc().format("D MMM YYYY - h:mm A")
      : moment.unix(requestDate).utc().format("D MMM - YYYY")?.split(" ")[0] +
          " " +
          CheckValueLocale(
            moment
              .unix(requestDate)
              .utc()
              .format("D MMM - YYYY")
              ?.split(" ")[1],
            "",
            {},
            intl,
          ) +
          ", " +
          moment.unix(requestDate).utc().format("D MMM - YYYY")?.split(" ")[3] +
          " " +
          moment
            ?.unix(requestDate)
            ?.utc()
            ?.format("D MMM - YYYY h:mm A")
            ?.split(" ")[4] +
          " " +
          moment
            ?.unix(requestDate)
            ?.utc()
            ?.format("D MMM - YYYY h:mm A")
            ?.split(" ")[5];
  };
  const getAnswerValue = () => {
    let valArr = listAnswer?.answer?.answer?.includes("\\n")
      ? listAnswer?.answer?.answer?.split("\\n")
      : listAnswer?.answer?.answer?.split("\n");

    return valArr?.length ? valArr : listAnswer?.answer?.answer;
  };
  return requestsLoading ? (
    <Box className="luci-loading">
      <CircularProgress />
    </Box>
  ) : (
    <Box>
      <LuciHeader
        handleCloseSideBar={props?.handleCloseSideBar}
        sectionName="luci_answer"
        setShowPage={setShowLuciAnswers}
        setShowConfirmationPopup={setShowConfirmationPopup}
        setShowRequests={setShowRequests}
        isAnswerPage={isAnswerPage}
      />

      <Box className="answer-parant">
        <Typography component={"span"} className="answer-date">
          {localizedDateTime(listAnswer?.question?.attributes?.created_at)}
        </Typography>
        <Box className="answer-items">
          <Typography component={"span"} className="logo-luci-answer">
            <img src={Services?.aiProductLogo} alt="" loading="lazy" />
          </Typography>

          <Box className="data-details-parant">
            <Box className="head-details">
              {isBenchmark == true ? (
                <Typography component={"span"}>
                  {CheckValueLocale("benchmark", "", {}, intl)}
                </Typography>
              ) : (
                <Tooltip
                  id="tooltip-anwers-section"
                  title={`${CheckValueLocale(
                    listAnswer?.question?.attributes?.product?.name,
                    "",
                    {},
                    intl,
                  )} > ${listAnswer?.question?.attributes?.luci_section_name}`}
                  placement={
                    window.localStorage.lang == "ar" ? "right" : "left"
                  }
                  arrow
                >
                  <Typography component={"span"}>
                    {CheckValueLocale(
                      listAnswer?.question?.attributes?.product?.name,
                      "",
                      {},
                      intl,
                    )}
                    {listAnswer?.question?.attributes?.product?.name
                      ? ">" + " "
                      : "-"}
                    {truncate(
                      listAnswer?.question?.attributes?.luci_section_name,
                      20,
                    )}
                  </Typography>
                </Tooltip>
              )}

              <Typography component={"span"} className="answer-quota">
                {listAnswer?.question?.attributes?.token
                  ? listAnswer?.question?.attributes?.token * -1
                  : listAnswer?.question?.attributes?.token}
                <img src={Services?.quotaImg} alt="" />
              </Typography>
            </Box>
            <Typography component={"span"} className="sub-title-answer">
              {CheckValueLocale("luci_answer_sub_title", "", {}, intl)}
            </Typography>
            <AppliedFilters
              filterParams={listAnswer?.question?.attributes?.filters?.filters}
              startDate={listAnswer?.question?.attributes?.filters?.start_date}
              endDate={listAnswer?.question?.attributes?.filters?.end_date}
              isAnswerPage
              showSurveyquestion={showSurveyquestion}
              isBenchmark={isBenchmark}
            />
          </Box>
        </Box>
        <Box className="user-question-parant">
          <Typography className="user-question">
            {listAnswer?.question?.attributes?.text}
          </Typography>
          <Typography className="user-images" component={"span"}>
            <Box className="luci-img-username">
              {listAnswer?.question?.attributes?.avatar == null ||
              isInValidImage ? (
                <Box className="luci-img-char">
                  {listAnswer?.question?.attributes?.user_name?.charAt(0)}
                </Box>
              ) : (
                <img
                  src={listAnswer?.question?.attributes?.avatar}
                  onError={handleImageError}
                />
              )}
            </Box>
          </Typography>
        </Box>
        <Box className="answer-items">
          <Typography component={"span"} className="logo-luci-answer">
            <img src={Services?.aiProductLogo} alt="" loading="lazy" />
          </Typography>
          <Typography
            className={
              listAnswer?.answer?.answer?.length
                ? "answer-user"
                : "answer-user answer-user-after"
            }
          >
            {listAnswer?.answer?.answer ? (
              getAnswerValue()?.map((el) => {
                if (el !== "") {
                  return (
                    <Typography component="span" className="answer-val-item">
                      {el}
                    </Typography>
                  );
                }
              })
            ) : (
              <Typography>
                {CheckValueLocale("answer_is_in_the_way", "", {}, intl)}
              </Typography>
            )}
          </Typography>
        </Box>
        {listAnswer?.answer?.answer?.length ? (
          <Button
            id="luci-ask-again"
            onClick={() => {
              setShowConfirmationPopup(false);
              setShowLuciAnswers(false);
            }}
          >
            {CheckValueLocale("luci_ask-again", "", {}, intl)}
          </Button>
        ) : null}
      </Box>
    </Box>
  );
};

export default LuciAnswer;
