import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useThemesOptions } from "components/sentimentCategoriesThemes/hooks/useThemesOptions";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { UserInfoItemList } from "./userInfoItemList";
import { EditContext } from "../context";
import { useContext } from "react";

const Themes = ({ themesData }) => {
  const intl = useIntl();
  const { isEditing } = useContext(EditContext);
  const { data: themesOptions } = useThemesOptions({ locale: intl?.locale });

  return (
    <div>
      <Accordion
        disabled={isEditing}
        classes={{ disabled: "engager-user-info-accordion-disabled" }}
        defaultExpanded
        className="engager-user-info-accordion"
      >
        <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
          <Typography className="engager-user-info-title">
            {CheckValueLocale("themes_profile", "", {}, intl)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <UserInfoItemList
            data={themesData}
            fieldType="theme"
            withColor
            themesOptions={themesOptions}
            emptyText={CheckValueLocale("no_attached_themes_yet", "", {}, intl)}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Themes;
