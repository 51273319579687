import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import SurveyPopup from "pages/SurveyBuilder/EditSection/embeddedEditor/components/surveyPopup/surveyPopup";
import LucButton from "shared/lucButton/lucButton";

const EmbeddedPreview = (props) => {
  const { openedPopup, setOpenedPopup, surveyData, sectionIndex } = props;

  const [hideSurvey, setHideSurvey] = useState(true);

  useEffect(() => {
    if (surveyData?.embedded_config?.embed_options?.type === "pop-up") {
      setOpenedPopup(true);
    }

    const afterSeconds =
      surveyData?.embedded_config?.opening_settings?.opening_after?.value;

    // To check if opening settings (opening_after) is set to 'seconds' and display after it
    if (
      surveyData?.embedded_config?.embed_options?.type === "pop-up" &&
      surveyData?.embedded_config?.opening_settings?.opening_after?.name ===
        "after_seconds" &&
      afterSeconds
    ) {
      setTimeout(() => setHideSurvey(false), afterSeconds * 1000);
    } else setHideSurvey(false);
  }, [surveyData]);

  const backdrop = surveyData?.embedded_config?.backdrop || {};

  const { button_title, font_color, background_color, corner_radius } =
    surveyData?.embedded_config?.button_style || {};

  const btnSize =
    surveyData?.embedded_config?.embed_options?.embed_size || "medium";

  return (
    <Box
      className="embedded-survey-live-preview-container"
      sx={{
        "&::before": {
          display: openedPopup ? "block" : "none",
          backgroundColor: backdrop?.color || "none",
          opacity: backdrop?.opacity / 100 || 0,
        },
      }}
    >
      {!hideSurvey &&
      surveyData?.embedded_config?.embed_options?.type === "button" ? (
        <Box
          id="survey-embedded-feedback-button-wrapper"
          className={
            surveyData?.embedded_config?.embed_options?.embed_position || ""
          }
        >
          <LucButton
            id="survey-embedded-feedback-button"
            className={`${corner_radius?.toLowerCase() || ""} ${btnSize}`}
            style={{ color: font_color, backgroundColor: background_color }}
            onClick={() => setOpenedPopup(true)}
          >
            {button_title || ""}
          </LucButton>
        </Box>
      ) : null}

      {!hideSurvey ? (
        <SurveyPopup
          {...props}
          ssIndex={sectionIndex + 1}
          isEmbedded
          previewMode
        />
      ) : null}
    </Box>
  );
};

export default EmbeddedPreview;
