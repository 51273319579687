import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Container, Box } from "@mui/material";
import { useIntl } from "react-intl";
import "../../../../../../shared/createHeader/createHeader.scss";
import MonitorInfoSections from "../../Components/MonitorInfoSections/monitorInfoSections";
import PublicAccountDataSourcesSM from "./publicAccountDataSources-SM";
import CommonFunctions from "../../createCommonFunctions/createMonitorCommonFunctions";
import { CheckValueLocale } from "utils/helpers";
import AddAutoSummaryWidget from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/AddAutoSummaryWidget/addAutoSummaryWidget";

const PublicAccount = (props) => {
  const intl = useIntl();
  const [value, setValue] = useState(1);
  const {
    monitorData,
    setMonitorData,
    setMonitorErrors,
    monitorErrors,
    handleCreateMonitorBtn,
    companyAllowedSources,
    monitorTopics,
    setMonitorTopics,
    errorKeywordsMsg,
    setErrorKewyordsMsg,
    errorExKeywordsMsg,
    setExErrorKewyordsMsg,
    displayNameMsgError,
    setDisplayNameMsgError,
    disabledManualTopic,
    manualTopicStats,
    getTwitterLanguagesAndCountries,
    twitterLanguagesAndCountries,
    setTwitterWordsCounter,
    twitterWordsCounter,
    summarySmTokensAccountAnalysis,
    handleMonitorTopicsUsage,
  } = props;

  const {
    getAuthorizedAccount,
    isLoading,
    authorizedAccounts,
    getDataSourceName,
  } = CommonFunctions();

  props.setAuthorizedAccounts(authorizedAccounts);

  useEffect(() => {
    getAuthorizedAccount();
  }, []);

  return (
    <Box className="monitor-type">
      <Container>
        <MonitorInfoSections
          monitorErrors={monitorErrors}
          monitorData={monitorData}
          setMonitorData={setMonitorData}
          setMonitorErrors={setMonitorErrors}
          handleCreateMonitorBtn={handleCreateMonitorBtn}
        />
        <Typography className="select-monitor-style" mt={4}>
          {CheckValueLocale("choose_social_media_source", "", {}, intl)}:
        </Typography>
        <PublicAccountDataSourcesSM
          setMonitorData={setMonitorData}
          monitorData={monitorData}
          setMonitorErrors={setMonitorErrors}
          monitorErrors={monitorErrors}
          handleCreateMonitorBtn={handleCreateMonitorBtn}
          companyAllowedSources={companyAllowedSources}
          dataSourcesId={props.dataSourcesId}
          setActiveDataSourceId={props.setActiveDataSourceId}
          setAllSocialAccounts={props.setAllSocialAccounts}
          authorizedAccounts={authorizedAccounts}
          isLoading={isLoading}
          monitorTopics={monitorTopics}
          setMonitorTopics={setMonitorTopics}
          errorKeywordsMsg={errorKeywordsMsg}
          setErrorKewyordsMsg={setErrorKewyordsMsg}
          errorExKeywordsMsg={errorExKeywordsMsg}
          setExErrorKewyordsMsg={setExErrorKewyordsMsg}
          displayNameMsgError={displayNameMsgError}
          setDisplayNameMsgError={setDisplayNameMsgError}
          disabledManualTopic={disabledManualTopic}
          manualTopicStats={manualTopicStats}
          getTwitterLanguagesAndCountries={getTwitterLanguagesAndCountries}
          twitterLanguagesAndCountries={twitterLanguagesAndCountries}
          setTwitterWordsCounter={setTwitterWordsCounter}
          twitterWordsCounter={twitterWordsCounter}
          value={value}
          setValue={setValue}
          handleMonitorTopicsUsage={handleMonitorTopicsUsage}
        />
        {props?.autoSummaryWidgetPackage && value !== 3 ? (
          <AddAutoSummaryWidget
            setWidgetData={setMonitorData}
            widgetData={monitorData}
            summaryTokens={
              summarySmTokensAccountAnalysis?.[getDataSourceName(value)]
            }
          />
        ) : null}
      </Container>
    </Box>
  );
};

export default PublicAccount;
