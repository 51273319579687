import { useEffect, useState } from "react";
import PopupToEnable2FA from "pages/profile/components/securityProfileSection/components/twoFactorAuthentication/components/popupToEnable2FA/popupToEnable2FA";
import EditAndEnablePopupBody from "./editAndEnablePopupBody";
import OpenDisablePopup from "../../../../profile/components/securityProfileSection/components/twoFactorAuthentication/components/popupToEnable2FA/popupBody/disableFeature/openDisablePopup";
import { useEditTwoFaMethods } from "../hooks/useEditTwoFaMethods";
import { useFormik } from "formik";
import { usePostVerificationCode } from "../hooks/usePostVerificationCode";
import { useResendVerificationCode } from "../hooks/useResendVerificationCode";
import { useQueryClient } from "@tanstack/react-query";

const SecurityPagePopup = ({ setOpenSnackBar, ...props }) => {
  const queryClient = useQueryClient();
  const [notSelectAnyMethod, setNotSelectAnyMethod] = useState(false);
  const [bodyToShow, setBodyToShow] = useState("");
  const [codeVerify, setCodeVerify] = useState("");
  const [errorCodeMsg, setErrorCodeMsg] = useState("");
  const [popupData, setPopupData] = useState({
    open: false,
    type: "",
    step: 1,
  });
  const handleEditData = useEditTwoFaMethods();
  const handlePortVerification = usePostVerificationCode();
  const handleResendCode = useResendVerificationCode();

  const resendCode = () => {
    handleResendCode?.mutate(undefined, {
      onSuccess: (data) => {
        setPopupData((prev) => ({
          ...prev,
          expires_at: data?.data?.data?.expires_at,
        }));
        setCodeVerify("");
        setErrorCodeMsg("");
      },
      onError: (error) => {
        if (
          error?.errorMsg?.response?.data?.message === "User email is bounced"
        ) {
          setOpenSnackBar({
            open: true,
            message: "email_bounced_description",
            type: "error",
            title: "email_bounced_title",
          });
        } else
          setOpenSnackBar({
            open: true,
            message: `failed_two_fa_${bodyToShow}`,
            type: "error",
            title: "something_went_wrong",
          });
      },
    }); // Call the mutate function
  };

  const handleCodeVerification = () => {
    const codeToSend = {
      otp: codeVerify,
    };
    handlePortVerification?.mutate(codeToSend, {
      onSuccess: () => {
        setOpenSnackBar({
          open: true,
          message: `success_two_fa_${bodyToShow}`,
          type: "success",
        });
        handleCloseFeature();
      },
      onError: (error) => {
        setErrorCodeMsg({
          msg:
            error?.errorMsg == "OTP Code is incorrect"
              ? error?.attempts < 4
                ? "error_two_factor_incorrect_remaining_attempts"
                : "error_two_factor_incorrect"
              : error?.errorMsg == "OTP code is expired"
                ? "error_two_factor_code_expired"
                : error?.errorMsg, // need to handle

          remaining_attempts: error?.attempts,
        });
        setOpenSnackBar({
          open: true,
          message: `failed_two_fa_${bodyToShow}`,
          type: "error",
          title: "something_went_wrong",
        });
      },
    });
  };

  const handleSubmitData = () => {
    const isDisable = bodyToShow === "disable";
    const dataToSend = {
      two_fa_types: {
        email: isDisable ? false : formik?.values?.email,
        sms: isDisable ? false : formik?.values?.sms,
        totp: isDisable ? false : formik?.values?.totp,
      },
      is_2fa_remember_me_enabled: isDisable
        ? false
        : formik?.values?.rememberMe,
    };

    handleEditData?.mutate(dataToSend, {
      onSuccess: (data) => {
        setPopupData((prev) => ({
          ...prev,
          step: 2,
          bodyText:
            prev?.textButton == "disable"
              ? "text_disable_two_factor_auth_email"
              : "text_enable_two_factor_auth_email",
          showSixCodeInput: true,
          showResendCodeBtn: true,
          contact: data?.data?.data?.send_to,
          textButton:
            prev?.textButton == "disable" ? "confirm_disable" : "verify",

          expires_at: data?.data?.data?.expires_at,
        }));
      },
      onError: (error) => {
        if (
          error?.errorMsg?.response?.data?.message === "User email is bounced"
        ) {
          setOpenSnackBar({
            open: true,
            message: "email_bounced_description",
            type: "error",
            title: "email_bounced_title",
          });
        } else
          setOpenSnackBar({
            open: true,
            message: `failed_two_fa_${bodyToShow}`,
            type: "error",
            title: "something_went_wrong",
          });
      },
    });
  };

  const formik = useFormik({
    initialValues: props?.initialValues,
    enableReinitialize: true,
    onSubmit: popupData?.step === 2 ? handleCodeVerification : handleSubmitData,
  });

  const handleCloseFeature = () => {
    if (popupData?.step === 2) {
      queryClient?.invalidateQueries({
        queryKey: ["SecruityActivatedFeatures"],
      });
    }
    setPopupData({
      open: false,
      step: 1,
    });
    props?.setStepEdit(false);
    props?.setStepEnable(false);
    props?.setStepDisable(false);
    formik?.resetForm();
  };

  const handleNextStep = () => {
    formik?.handleSubmit();
  };

  useEffect(() => {
    if (props?.stepDisable) {
      setPopupData({
        open: true,
        step: 1,
        headerTitle: "disable_two_fa_all",
        textButton: "disable",
        warning: "true",
      });
      setBodyToShow("disable");
    }
  }, [props?.stepDisable]);

  useEffect(() => {
    if (props?.stepEnable) {
      setPopupData({
        open: true,
        step: 1,
        headerTitle: "enable_two_factor_authentication",
        textButton: "activate_two",
      });
      setBodyToShow("enable");
    }
  }, [props?.stepEnable]);

  useEffect(() => {
    if (props?.stepEdit) {
      setPopupData({
        open: true,
        step: 1,
        headerTitle: "enable_two_factor_authentication",
        textButton: "activate_two",
      });
      setBodyToShow("edit");
    }
  }, [props?.stepEdit]);

  useEffect(() => {
    if (
      !formik?.values?.email &&
      !formik?.values?.sms &&
      !formik?.values?.totp
    ) {
      setNotSelectAnyMethod(true);
    } else setNotSelectAnyMethod(false);
  }, [formik?.values]);

  const editTwoFactorAuth = (
    <EditAndEnablePopupBody
      smsEnabled={props?.smsEnabled}
      activatedMethods={props?.initialValues}
      notSelectAnyMethod={notSelectAnyMethod}
      formik={formik}
    />
  );

  const enableTwoFactorAuth = () => {
    return (
      <EditAndEnablePopupBody
        smsEnabled={props?.smsEnabled}
        activatedMethods={props?.initialValues}
        notSelectAnyMethod={notSelectAnyMethod}
        formik={formik}
      />
    );
  };

  const bodyComponents = {
    edit: editTwoFactorAuth,
    enable: enableTwoFactorAuth(),
    disable: <OpenDisablePopup />,
  };

  const popupBody =
    popupData?.step === 1 ? bodyComponents?.[bodyToShow] || null : null;

  return (
    <>
      <PopupToEnable2FA
        handleClose={handleCloseFeature}
        modelData={popupData}
        handleAcceptPopup={handleNextStep}
        codeVerify={codeVerify}
        setCodeVerify={setCodeVerify}
        setErrorCodeMsg={setErrorCodeMsg}
        errorCodeMsg={errorCodeMsg}
        popupBody={popupBody}
        disableButtonStatus={
          bodyToShow == "disable" ? false : !formik?.dirty || notSelectAnyMethod
        }
        handleResendCode={() => resendCode()}
        isBtnLoading={
          handleEditData?.isPending || handlePortVerification?.isPending
        }
        isLoadingResendCode={handleResendCode?.isPending}
        enableDivider={
          bodyToShow == "disable" || popupData?.step == 2 ? false : true
        }
      />
    </>
  );
};

export default SecurityPagePopup;
