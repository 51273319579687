import { api } from "./newServices";

const getCompanySources = (queryData) => {
  return api.get("api/v3/company/sources", { params: queryData });
};

const getCompanyMonitorTypes = () => {
  return api.get(`api/v3/company/monitor_types`);
};

const getKeywordsLimit = () => {
  return api.get("api/v3/company/keywords_limit");
};

const getHashtagsLimit = (queryData) => {
  return api.get(
    `api/v3/social_listening/instagram/get_used_instagram_hashtags`,
    { params: queryData },
  );
};

const getSummarySmTokens = () => {
  return api.get("api/v3/luci/questions/summary_sm_tokens");
};

const getSocialAccounts = (queryData) => {
  return api.get("api/v3/company/social_accounts", { params: queryData });
};

const getUserSocialAccounts = (queryData) => {
  return api.get("api/v3/user/social_accounts", { params: queryData });
};

const getAuthorizedAccounts = () => {
  return api.get("api/v3/user/authorized_accounts");
};

const getTwitterSources = (queryData) => {
  return api.get("api/v3/twitter_sources", { params: queryData });
};

const getFacebookPages = (product_id, searchValue) => {
  return api.get(
    `api/v3/social_listening/facebook/filters/search_facebook_pages?product_id=${product_id}&q=${searchValue}`,
  );
};

const createNewMonitor = (queryData) => {
  return api.post("api/v3/monitors", queryData);
};

const editMonitorInfo = (monitor_id, queryData) => {
  return api.put(`api/v3/monitors/edit/${monitor_id}`, queryData);
};

const getNBMonitorData = (queryData) => {
  return api.get("api/v3/nb_sources", { params: queryData });
};

export const CreateMonitorServices = {
  getCompanySources,
  getCompanyMonitorTypes,
  getKeywordsLimit,
  getHashtagsLimit,
  getSummarySmTokens,
  getSocialAccounts,
  getUserSocialAccounts,
  getAuthorizedAccounts,
  getTwitterSources,
  getFacebookPages,
  createNewMonitor,
  editMonitorInfo,
  getNBMonitorData,
};
