import * as Yup from "yup";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import { CheckValueLocale, removeStartingProtocol } from "utils/helpers";
import {
  Box,
  Chip,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleX } from "@fortawesome/pro-regular-svg-icons";
import LucButton from "shared/lucButton/lucButton";
import allWesbitePages from "images/survey/all_website_pages.svg";
import specificWesbitePages from "images/survey/specific_pages.svg";

const SelectDisplayScopeBody = (props) => {
  const { selectedDomains, setSelectedDomains } = props;

  const intl = useIntl();

  const dummyTypes = [
    {
      value: true,
      icon: allWesbitePages,
      title: "embedded_survey_publish_select_display_scope_all_pages_title",
      description:
        "embedded_survey_publish_select_display_scope_all_pages_desc",
    },
    {
      value: false,
      icon: specificWesbitePages,
      title:
        "embedded_survey_publish_select_display_scope_specific_pages_title",
      description:
        "embedded_survey_publish_select_display_scope_specific_pages_desc",
    },
  ];

  const { domain, allow_all_pages, allowed_pages } = selectedDomains?.[0] || {};

  const cleanedDomain = removeStartingProtocol(domain) || "";

  const handleChange = (value) => {
    const newValue = value == "true" || value === true;
    setSelectedDomains([
      { ...selectedDomains?.[0], allow_all_pages: newValue, allowed_pages: [] },
    ]);
  };

  const addSubPageFormik = useFormik({
    initialValues: { sub_page: "" },
    validationSchema: Yup?.object({
      sub_page: Yup?.string()
        ?.required("")
        ?.test(
          "is-exceeded-limit",
          "embedded_survey_publish_select_display_scope_specific_pages_input_max_pages_error",
          (value) => value?.length && allowed_pages?.length < 10,
        )
        ?.test(
          "is-valid",
          "embedded_survey_publish_select_display_scope_specific_pages_input_invalid_error",
          (value) => {
            if (!value) return false; // Handle empty value case
            const pathRegex =
              /^(?!.*[\/_-]{2,})([\u0600-\u06FFa-zA-Z0-9]+[\/_-]?)*[\u0600-\u06FFa-zA-Z0-9]+(\.(html|php|xml|js|json))?$/;
            return pathRegex?.test(value); // Check if the value matches the regex
          },
        )
        ?.test(
          "is-not-duplicated",
          "embedded_survey_publish_select_display_scope_specific_pages_input_duplication_error",
          (value) => !allowed_pages?.includes(`/${value}`),
        ),
    }),
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      setSelectedDomains([
        {
          ...selectedDomains?.[0],
          allowed_pages: [...allowed_pages, `/${values?.sub_page}`],
        },
      ]);
      await addSubPageFormik?.setValues({ sub_page: "" });
    },
  });

  return (
    <Box className="embedded-publish-select-display-scope-body">
      <Typography className="select-display-scope-subtitle">
        {CheckValueLocale(
          "embedded_survey_publish_select_display_scope_subtitle",
          "",
          {},
          intl,
        )}
      </Typography>

      <Box className="selecting-group-wrapper">
        <RadioGroup
          className="select-display-scope-group"
          onChange={(e) => handleChange(e.target.value)}
          value={allow_all_pages}
        >
          {dummyTypes?.map((type) => {
            return (
              <Box
                className={`display-scope-item ${allow_all_pages === type?.value ? "selected" : ""}`}
                key={type?.value}
                onClick={() => handleChange(type?.value)}
              >
                <Box className="display-scope-item-header">
                  <Box className="display-scope-item-icon-wrapper">
                    <img src={type?.icon || ""} alt={type?.value || ""} />
                  </Box>
                  <Box>
                    <Radio
                      value={type?.value}
                      onChange={(e) => handleChange(e.target.value)}
                      checked={allow_all_pages === type?.value}
                    />
                  </Box>
                </Box>
                <Box className="display-scope-item-text">
                  <Typography variant="h4" className="display-scope-item-title">
                    {CheckValueLocale(type?.title, "", {}, intl)}
                  </Typography>
                  <Typography
                    variant="h6"
                    className="display-scope-item-description"
                  >
                    {CheckValueLocale(type?.description, "", {}, intl)}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </RadioGroup>
      </Box>

      {allow_all_pages === false ? (
        <Box className="select-display-scope-specific-pages-wrapper">
          <Typography className="select-display-scope-specific-pages-title">
            {CheckValueLocale(
              "embedded_survey_publish_select_display_scope_specific_pages_subtitle",
              "",
              {},
              intl,
            )}
          </Typography>
          <Box className="specific-pages-input-btn-wrapper">
            <Box className="specific-pages-input-field-wrapper">
              <OutlinedInput
                id="specific-pages-input-field"
                className="specific-pages-input-field"
                name="sub_page"
                placeholder="subpages"
                value={addSubPageFormik?.values?.sub_page}
                onChange={addSubPageFormik?.handleChange}
                onBlur={addSubPageFormik?.handleBlur}
                onKeyDown={(e) => {
                  if (e?.key === "Enter") addSubPageFormik?.handleSubmit();
                }}
                startAdornment={
                  <InputAdornment
                    id="specific-pages-input-field-start-adornment"
                    position="start"
                  >
                    <Box className="selected-domain-wrapper">
                      {cleanedDomain}
                    </Box>
                    <Typography id="selected-domain-static-slash">/</Typography>
                  </InputAdornment>
                }
                variant="outlined"
                size="small"
                fullWidth
                error={
                  addSubPageFormik?.touched?.sub_page &&
                  Boolean(addSubPageFormik?.errors?.sub_page)
                }
              />
              {addSubPageFormik?.touched?.sub_page &&
              Boolean(addSubPageFormik?.errors?.sub_page) ? (
                <FormHelperText id="specific-pages-input-field-error">
                  {CheckValueLocale(
                    addSubPageFormik?.errors?.sub_page || "",
                    "",
                    {},
                    intl,
                  )}
                </FormHelperText>
              ) : null}
            </Box>
            <LucButton
              id="specific-pages-input-add-btn"
              variant="outline"
              type="secondary"
              size="small"
              disabled={!addSubPageFormik?.isValid}
              onClick={addSubPageFormik?.handleSubmit}
            >
              {CheckValueLocale("add", "", {}, intl)}
            </LucButton>
          </Box>

          <Box className="select-display-scope-specific-pages-chips-container">
            {[...(selectedDomains?.[0]?.allowed_pages || [])]?.map(
              (item, itemIndex) => (
                <Chip
                  key={item}
                  className="select-display-scope-specific-pages-chip"
                  label={
                    <Box id="specific-pages-chip-label-wrapper">
                      <Typography id="specific-pages-chip-title">
                        {cleanedDomain}
                        <Box component="span" id="specific-page-path">
                          {item}
                        </Box>
                      </Typography>
                      <FontAwesomeIcon
                        className="specific-pages-chip-delete-icon"
                        onClick={() =>
                          setSelectedDomains([
                            {
                              ...selectedDomains?.[0],
                              allowed_pages:
                                selectedDomains?.[0]?.allowed_pages?.filter(
                                  (page, index) => index !== itemIndex,
                                ),
                            },
                          ])
                        }
                        icon={faCircleX}
                        fixedWidth
                      />
                    </Box>
                  }
                />
              ),
            )}
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default SelectDisplayScopeBody;
