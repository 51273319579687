import { Box } from "@mui/material";
import PageHeader from "components/pageHeader";
import "./style.scss";
import OptionsCard from "./components/optionsCard";
import { useState } from "react";
import TwoFactorAuthActivated from "./components/twoFactorAuthActivated";
import { useEffect } from "react";
import { useGetActivatedFeatures } from "./hooks/useGetActivatedFeatures";
import SecurityPagePopup from "./components/securityPagePopup";
import CircularLoading from "components/circularProgress";
import BouncedEmailsAlert from "./components/bouncedEmailsAlert/bouncedEmailsAlert";
import { useQueryClient } from "@tanstack/react-query";
import { differenceSecondsBetweenDates } from "utils/helpers";
import SnackBar from "components/snackBar";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const SecurityPage = () => {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const [stepEnable, setStepEnable] = useState(false);
  const [stepEdit, setStepEdit] = useState(false);
  const [stepDisable, setStepDisable] = useState(false);
  const [smsEnabled, setSmsEnabled] = useState(false);
  const { data, isLoading, isFetching } = useGetActivatedFeatures();
  const [renderOptions, setRenderOptions] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState();

  const [initialValues, setInitialValue] = useState("");

  useEffect(() => {
    if (data === undefined) return;
    const newValues = {};
    for (let i = 0; i < data?.two_fa_types?.length; i++) {
      const methodName = data?.two_fa_types[i]?.name;
      const methodStatus = data?.two_fa_types[i]?.is_enabled;
      newValues[methodName] = methodStatus;
    }
    newValues["rememberMe"] = data?.remember_me;
    setSmsEnabled(data?.is_sms_feature_enabled);
    setInitialValue(newValues);
    setRenderOptions(data?.is_enabled);
  }, [data]);

  useEffect(() => {
    let setTimeVar;
    if (data?.otp_enabled_at) {
      const timeCount = differenceSecondsBetweenDates(
        new Date(),
        new Date(data?.otp_enabled_at),
      );
      setTimeVar = setTimeout(
        () => {
          //to get enable from  Back-end after code is expired
          queryClient.invalidateQueries({
            queryKey: ["SecruityActivatedFeatures"],
          });
        },
        (timeCount + 1) * 1000,
      );
    }

    return () => {
      clearTimeout(setTimeVar);
    };
  }, [data?.otp_enabled_at]);

  return (
    <>
      {isLoading ? (
        <CircularLoading />
      ) : (
        <>
          <Box id="security-page-container">
            <Box id="security-page">
              <PageHeader
                title="security_title"
                titleId="secondary-pages-header"
              />
              <BouncedEmailsAlert />
              {renderOptions && (
                <TwoFactorAuthActivated
                  featureName="two_factor_authentication"
                  featureDescription="two_factor_authentication_desc"
                  isAvailable={true}
                  openEdit={() => setStepEdit(true)}
                  openDisable={() => setStepDisable(true)}
                  activatedMethods={initialValues}
                  disabled={!!data?.otp_enabled_at} // to disable button when code for 2FA is not expired
                />
              )}
              {!renderOptions && (
                <OptionsCard
                  featureName="two_factor_authentication"
                  featureDescription="two_factor_authentication_desc"
                  isAvailable={true}
                  openEnable={() => setStepEnable(true)}
                  disabled={!!data?.otp_enabled_at} // to disable button when code for 2FA is not expired
                />
              )}
              <OptionsCard
                featureName="strengthen_password"
                featureDescription="strengthen_password_desc"
              />
            </Box>
          </Box>
          {initialValues && !isFetching ? (
            <SecurityPagePopup
              smsEnabled={smsEnabled}
              initialValues={initialValues}
              stepEnable={stepEnable}
              setStepEnable={setStepEnable}
              stepEdit={stepEdit}
              setStepEdit={setStepEdit}
              stepDisable={stepDisable}
              setStepDisable={setStepDisable}
              setOpenSnackBar={setOpenSnackBar}
            />
          ) : null}
        </>
      )}
      <SnackBar
        open={openSnackBar?.open}
        handleClose={() => setOpenSnackBar(false)}
        message={CheckValueLocale(openSnackBar?.message, "", {}, intl)}
        severity={openSnackBar?.type}
        title={CheckValueLocale(openSnackBar?.title, "", {}, intl)}
        alertStyle={{ maxWidth: "320px" }}
      />
    </>
  );
};

export default SecurityPage;
