//  The core content, which could be text, media, or both.
import { MediaMessage } from "./messagesPreviewers/MediaMessage";
import { FileMessage } from "./messagesPreviewers/fileMessage";
import { AudioMessage } from "./messagesPreviewers/audioMessage/audioMessage";
import { ContactMessage } from "./messagesPreviewers/contactMessage";
import { LocationMessage } from "./messagesPreviewers/locationMessage";
import { classNames } from "utils/helpers";
import { Box } from "@mui/material";

// Map message types to their respective previewer components
const messagePreviewerComponents = {
  image: MediaMessage,
  video: MediaMessage,
  sticker: MediaMessage,
  audio: AudioMessage,
  contact: ContactMessage,
  location: LocationMessage,
  file: FileMessage,
};

// Message types that do not require a media URL
const typesWithoutMediaContent = ["location", "contact"];

const getMessagePreviewerComponent = (messageType) =>
  messagePreviewerComponents[messageType] || null;

export const MediaContentRenderer = ({
  message,
  handleMediaClick,
  dataSource,
  currentPlayedAudioId,
  setCurrentPlayedAudioId,
  messageId,
}) => {
  if (!message) {
    return null;
  }
  const { type: messageType, media, payload } = message;
  const isMediaUrlAvailable = media?.media_url;
  const isTypeWithoutMediaContent =
    typesWithoutMediaContent.includes(messageType);

  // If media URL is missing and the message type requires media content, return null
  if (!isMediaUrlAvailable && !isTypeWithoutMediaContent) {
    return null;
  }

  if (messageType === "contact") {
    return (
      <>
        {payload?.contacts?.map((contact, index) => {
          return (
            <ContentRenderer
              key={`${messageId}-${index}`}
              message={message}
              dataSource={dataSource}
              messageId={messageId}
              messageType={messageType}
              contact={contact}
            />
          );
        })}
      </>
    );
  }
  return (
    <ContentRenderer
      message={message}
      dataSource={dataSource}
      handleMediaClick={handleMediaClick}
      currentPlayedAudioId={currentPlayedAudioId}
      setCurrentPlayedAudioId={setCurrentPlayedAudioId}
      messageId={messageId}
      messageType={messageType}
    />
  );
};

const ContentRenderer = ({
  message,
  dataSource,
  handleMediaClick,
  currentPlayedAudioId,
  setCurrentPlayedAudioId,
  messageId,
  messageType,
  ...rest
}) => {
  const MessagePreviewerComponent = getMessagePreviewerComponent(messageType);
  return (
    <Box
      className={classNames(
        "chat-message-media",
        `chat-message-media-${dataSource}-${messageType}`,
        `chat-message-media-${dataSource}`,
      )}
    >
      <Box className="single-interactions-media">
        {/* show proper media/attachment based on type */}
        {MessagePreviewerComponent ? (
          <MessagePreviewerComponent
            message={message}
            handleMediaClick={handleMediaClick}
            dataSource={dataSource}
            currentPlayedAudioId={currentPlayedAudioId}
            setCurrentPlayedAudioId={setCurrentPlayedAudioId}
            messageId={messageId}
            {...rest}
          />
        ) : null}
      </Box>
    </Box>
  );
};
