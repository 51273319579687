import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import LaunchIcon from "@mui/icons-material/Launch";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import "./surveyPage.scss";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import { truncate } from "utils/helpers";
import moment from "moment";

const SurveyPage = (props) => {
  const intl = useIntl();
  let { userProfileData, survey } = props;
  const navigate = useNavigate();

  return (
    <Box>
      <Grid container spacing={2}>
        {survey?.data?.map((item) => {
          const isDeleted = item?.survey_is_deleted;
          const dateLabel = isDeleted
            ? CheckValueLocale("deletion_date", "", {}, intl)
            : CheckValueLocale("submitted_date", "", {}, intl);
          const surveyName = isDeleted
            ? CheckValueLocale("deleted_survey", "", {}, intl) // Use a localized string for "Deleted Survey"
            : truncate(item?.survey_name, 23);
          const surveyDate =
            isDeleted && item?.survey_deleted_at
              ? moment.unix(item?.survey_deleted_at).utc().format("D MMM, YYYY")
              : !isDeleted
                ? moment.unix(item?.submitted_date).utc().format("D MMM, YYYY")
                : "";
          return (
            <Grid item xs={4}>
              <Box className="audience-survey-box">
                <Box className="audience-survey-title">
                  <Typography variant="h5">{surveyName}</Typography>
                  <Typography variant="span">
                    {dateLabel}
                    <Box className="audience-survey-date">{surveyDate}</Box>
                  </Typography>
                </Box>
                <Box>
                  {handlelUserRoles(
                    "CDP",
                    "VIEW_PROFILE_INTERACTIONS_DETAILS",
                  ) && (
                    <Button
                      className="show-btn"
                      endIcon={<LaunchIcon />}
                      onClick={() => {
                        window.scrollTo(0, 0);
                        navigate(
                          `/audience/survey/${userProfileData?.data?.id}/${item?.survey?.[0]?.survey}/${item?.respondant_id}`,
                        );
                      }}
                      id="audience-user-activities-show-btn"
                      disabled={isDeleted} // Disable button if the survey is deleted
                    >
                      {CheckValueLocale("show", "", {}, intl)}
                    </Button>
                  )}
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
export default SurveyPage;
