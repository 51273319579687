import { useEffect } from "react";
import { Divider, Typography, Box, Tooltip, FormControl } from "@mui/material";

import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import RanksComponent from "./ranksComponent";
import CustomSourcesChipsInput from "./customSourcesChipsInput/customSourcesChipsInput";

const KeywordsInputComponent = (props) => {
  const intl = useIntl();
  const {
    selectedSource,
    excludedSourcesErrorMsg,
    monitorSetting, // props that pass from MonitorSetting File
    createMonitor, // props that paff from CreateMonitor File
    monitorErrors,
    setMonitorErrors,
    nbRanks,
    setNbRanks,
    updatedParams,
    setUpdatedParams,
  } = props;
  useEffect(() => {
    // In case of create we will not add this because we don't send monitor Errors
    // So we will just need in MonitorSettings
    if (setMonitorErrors) {
      setMonitorErrors({
        ...monitorErrors,
        nb_title: excludedSourcesErrorMsg,
      });
    }
  }, [excludedSourcesErrorMsg]);

  return (
    <>
      <Box>
        {monitorSetting && <Box></Box>}
        <Box
          className={monitorSetting && "sources-inputs-fields-higher-wrapper"}
        >
          {selectedSource === "major_sources" ? (
            <FormControl
              sx={{ mt: 1 }}
              className={
                monitorSetting
                  ? "monitor-setting-custom-sources-container"
                  : "news-blog-wrapper"
              }
            >
              <CustomSourcesChipsInput {...props} />
            </FormControl>
          ) : null}
        </Box>
      </Box>
      {monitorSetting && selectedSource !== "major_sources" && <Divider />}
      {monitorSetting && selectedSource !== "major_sources" ? (
        <>
          <Box className="section-box-data keywords-inputs-fields">
            <Typography className="main-titles">
              {CheckValueLocale("ranks", "", {}, intl)}
            </Typography>
            <Box>
              {selectedSource !== "major_sources" ? (
                <Box className="ranks-container">
                  <RanksComponent
                    nbRanks={nbRanks}
                    setNbRanks={setNbRanks}
                    selectedSource={selectedSource}
                    updatedParams={updatedParams}
                    setUpdatedParams={setUpdatedParams}
                  />
                </Box>
              ) : null}
            </Box>
          </Box>
        </>
      ) : null}
      {monitorSetting && selectedSource !== "major_sources" && <Divider />}
      {/* exclude_sources__________________________________________________ */}
      {selectedSource !== "major_sources" ? (
        <>
          <Box className="section-box-data keywords-inputs-fields">
            {createMonitor ? (
              <Typography
                variant="caption"
                className={`keyword-head-title ${createMonitor ? "exclude-sources-title" : ""}`}
              >
                {
                  <Tooltip
                    title={
                      <Box className="title-search-tooltip">
                        {intl.formatMessage(
                          {
                            id: "exclude_sources_tooltip",
                          },
                          {
                            break: <br />,
                          },
                        )}
                      </Box>
                    }
                    arrow
                    placement="bottom"
                  >
                    <Typography variant="caption" className="media-input-label">
                      {CheckValueLocale("exclude_sources", "", {}, intl)}
                    </Typography>
                  </Tooltip>
                }
              </Typography>
            ) : (
              <Typography className="main-titles">
                {CheckValueLocale("exclude_sources", "", {}, intl)}
              </Typography>
            )}
            <Box
              sx={{
                width: "455px",
              }}
            >
              <CustomSourcesChipsInput {...props} type="excludeSources" />
            </Box>
          </Box>
        </>
      ) : null}
    </>
  );
};

export default KeywordsInputComponent;
