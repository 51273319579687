import { useMutation, useQueryClient } from "@tanstack/react-query";
import { agentStatusServices } from "services/api/agentStatus";

export const useUpdateAgentStatus = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data) => agentStatusServices.updateAgentStatus(data),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["agentCurrentStatus"] });
    },
  });
};
