import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Typography, Box, Grid } from "@mui/material";
import LineChartWidget from "components/widgets/lineChartWidget";
import StackedLineWidget from "components/widgets/stackedLineWidget.js";
import WordCloudWidget from "components/widgets/wordCloudWidget.js";
import InteractionsList from "components/interactions-list/InteractionsList.js";
import ThemesAnalysis from "components/widgets/themes/themeAnalysis/ThemeAnalysis";
import ThemesTrends from "components/widgets/themes/themesTrends/ThemesTrends";
import OverView from "components/widgets/overViewWidget";
import { getNoOfDays } from "utils/helpers";
import { CheckValueLocale } from "utils/helpers/index";
import LuciFunctions from "pages/luci/components/luciFunctions/luciFunction";
import PieChartWidget from "components/widgets/pieChartWidget";
import { checkIsDataFecthing } from "pages/channelAnalytics/InsightsPage/insightsCommonFunctions";
import _ from "lodash";
import TableWidget from "components/widgets/tableWidget/tableWidget";
const GmailBody = (props) => {
  let { response, startDate, endDate, setExcelSheetData } = props;
  const intl = useIntl();

  //___________________________________________________________________________
  const initialWidgetsState = {
    //overview widget
    overview: [],
    overViewPreLoaderDataSources: [],

    //interaction widget
    interaction: [],
    totalInteraction: 0,
    avgInteractions: 0,
    interactionsPreLoaderDataSources: [],

    //sentiment widget
    sentimentOvertime: [],
    sentimentPiechart: [],
    sentimentAnalysisPreLoaderDataSources: [],
    negativeArray: [],
    positiveArray: [],
    neutralArray: [],

    //themes widget
    themeAnalysis: [],
    themeXlxs: [],
    themeTrend: [],
    themeTrendPreLoaderDataSources: [],
    themeAnalysisPreLoaderDataSources: [],

    //top keywords widget
    topKeywords: [],
    topKeywordsPreLoaderDataSources: [],

    //dialects
    dialects: [],
    subDialects: [],
    dialectsPreLoader: [],

    //latestInteractions widget
    latestInteractions: [],
    latestInteractionsCount: 0,
    latestInteractionsPreLoaderDataSources: [],
  };

  const [gmailWidgets, setGmailWidgets] = useState(initialWidgetsState);

  const { checkLuciDataIsFounded, checkLuciAllDataFounded } = LuciFunctions();

  useEffect(() => {
    switch (response?.eventName) {
      case "Gmail__CASingleDataSourceInsightsPage__overview":
        if (response?.eventData?.overview) {
          let overViewArray = [];
          for (let [key, values] of Object.entries(
            response?.eventData?.overview,
          )) {
            const condition =
              values === "None" || values === null || values === 0;
            if (key === "average_waiting_time" && condition) {
              overViewArray.push({ name: key, value: 0.0 });
            } else overViewArray.push({ name: key, value: values });
          }
          setGmailWidgets({
            ...gmailWidgets,
            overview: [...overViewArray],
            overViewPreLoaderDataSources: [response?.data_source],
          });
        }
        break;
      case "Gmail__CASingleDataSourceInsightsPage__top_posts_emails":
        setGmailWidgets({
          ...gmailWidgets,
          latestInteractions: response?.eventData?.latest_interactions,
          latestInteractionsPreLoaderDataSources: [response?.data_source],
          latestInteractionsCount: response?.eventData?.interactions_count,
        });
        break;
      case "Gmail__CASingleDataSourceInsightsPage__sentiment_analysis":
        Object.entries(response?.eventData?.sentiment_overtime)?.forEach(
          ([key, value]) => {
            switch (key) {
              case "negative":
                value?.map((i) => {
                  return gmailWidgets?.negativeArray.push(i);
                });
                break;
              case "positive":
                value?.map((i) => {
                  return gmailWidgets?.positiveArray.push(i);
                });
                break;
              case "neutral":
                value?.map((i) => {
                  return gmailWidgets?.neutralArray.push(i);
                });
                break;
              default:
                break;
            }
          },
        );

        setGmailWidgets({
          ...gmailWidgets,
          sentimentOvertime: {
            negative: gmailWidgets?.negativeArray,
            positive: gmailWidgets?.positiveArray,
            neutral: gmailWidgets?.neutralArray,
          },
          sentimentPiechart: response?.eventData?.sentiment_piechart,
          sentimentAnalysisPreLoaderDataSources: [response?.data_source],
        });

        break;
      case "Gmail__CASingleDataSourceInsightsPage__themes_and_sub_themes":
        setGmailWidgets({
          ...gmailWidgets,
          themeTrend: response?.eventData?.themes?.theme_trend,
          themeAnalysis: response?.eventData?.themes?.theme_analysis,
          themeTrendPreLoaderDataSources: [response?.data_source],
          themeAnalysisPreLoaderDataSources: [response?.data_source],
          themeXlxs: response?.eventData?.themes,
        });
        break;
      case "Gmail__CASingleDataSourceInsightsPage__top_keywords":
        setGmailWidgets({
          ...gmailWidgets,
          topKeywords: response?.eventData?.top_keywords?.map((item) => {
            return { name: item?.id, value: item?.stats_count };
          }),
          topKeywordsPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Gmail__CASingleDataSourceInsightsPage__dialects_and_sub_dialects":
        setGmailWidgets({
          ...gmailWidgets,
          dialects: response?.eventData?.dialects,
          subDialects: response?.eventData?.sub_dialects,
          dialectsPreLoader: [response?.data_source],
          excelDialects: _.cloneDeep([...response?.eventData?.dialects]),
          excelSubDialects: _.cloneDeep([...response?.eventData?.sub_dialects]),
        });
        break;
      case "Gmail__CASingleDataSourceInsightsPage__volume_overtime":
        const noOfDays = getNoOfDays(startDate, endDate);
        const avg = response?.eventData?.total_interactions / noOfDays;
        setGmailWidgets({
          ...gmailWidgets,
          interaction: response?.eventData?.interactions,
          avgInteractions: avg % 1 !== 0 ? avg.toFixed(2) : avg,
          totalInteraction: response?.eventData?.total_interactions,
          interactionsPreLoaderDataSources: [response?.data_source],
        });
        break;

      default:
        break;
    }
  }, [response]);

  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (props.resetAllFields !== 1) {
      setGmailWidgets({ ...initialWidgetsState });
    }
  }, [props.resetAllFields]);

  const chartStatsData = [
    {
      name: "total_interactions",
      value: gmailWidgets?.totalInteraction,
    },
    {
      name: "insights_avg_interactions_per_day",
      value: gmailWidgets?.avgInteractions,
    },
  ];

  useEffect(() => {
    let x = checkLuciAllDataFounded(
      gmailWidgets?.latestInteractionsPreLoaderDataSources,
      props.preLoaderTrackerDataSources,
    );
    let y = checkLuciDataIsFounded([gmailWidgets?.latestInteractions]);
    props.setCheckAllDataExist(x);
    props.setCheckDataIsEmpty(y);
  }, [
    gmailWidgets?.latestInteractions,
    gmailWidgets?.latestInteractionsPreLoaderDataSources,
    props.preLoaderTrackerDataSources,
  ]);
  useEffect(() => {
    let totalPreLoaderDataSouces = [
      gmailWidgets?.overViewPreLoaderDataSources,
      gmailWidgets?.topKeywordsPreLoaderDataSources,
      gmailWidgets?.interactionsPreLoaderDataSources,
      gmailWidgets?.dialectsPreLoader,
      gmailWidgets?.latestInteractionsPreLoaderDataSources,
      gmailWidgets?.sentimentAnalysisPreLoaderDataSources,
      gmailWidgets?.themeAnalysisPreLoaderDataSources,
      gmailWidgets?.themeTrendPreLoaderDataSources,
    ];
    let result = checkIsDataFecthing(
      totalPreLoaderDataSouces,
      props?.preLoaderTrackerDataSources,
    );
    props?.setCheckAllDataFetchingExport(result);
    let excelData = { ...gmailWidgets };
    props?.setExcelSheetData([{ ...excelData }]);
  }, [gmailWidgets]);

  const topKeywordChartData = gmailWidgets?.topKeywords?.map((item) => {
    return { id: item?.name, value: item?.value };
  });

  return (
    <>
      <OverView
        data={gmailWidgets?.overview}
        isGmail
        overViewPreLoaderDataSources={
          gmailWidgets?.overViewPreLoaderDataSources
        }
        preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
      />

      <Typography
        variant="h6"
        mt={5}
        mb={3}
        sx={{ color: "#A4A9AF", fontSize: "14px", fontWeight: 500 }}
      >
        {CheckValueLocale("metrics", "", {}, intl).toUpperCase()}
      </Typography>

      <LineChartWidget
        title={"emails"}
        data={gmailWidgets?.interaction}
        showGroupBy={true}
        showChartDetailsWidget
        statsDetailsData={chartStatsData}
        redirectTo={"/interactions/email/gmail/"}
        toolTipLabel={"total_interactions"}
        showDownloadIcon
        bigImg
        lineChartPreLoaderDataSources={
          gmailWidgets?.interactionsPreLoaderDataSources
        }
        preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
      />
      <Box my={3}>
        <StackedLineWidget
          title={"sentiment_analysis"}
          data={gmailWidgets?.sentimentOvertime}
          dataPiechart={gmailWidgets?.sentimentPiechart}
          showGroupBy={true}
          areaStyle={true}
          toolTipLabel={"toolTipLabel"}
          showAI
          showDownloadIcon
          bigImg
          stackedLineWidgetPreLoaderDataSources={
            gmailWidgets?.sentimentAnalysisPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Box>
      {/* Themes & Themes-Trend Statues CHART */}
      <Box mt={3}>
        <ThemesAnalysis
          title={"themes"}
          data={gmailWidgets?.themeAnalysis}
          themeAnalysisPreLoaderDataSources={
            gmailWidgets?.themeAnalysisPreLoaderDataSources
          }
          showAI
          showDownloadIcon
          bigImg
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Box>
      <Box mt={3}>
        <ThemesTrends
          title={"themes_trend"}
          data={gmailWidgets?.themeTrend}
          showGroupBy={false}
          showAI
          showDownloadIcon
          bigImg
          themeTrendPreLoaderDataSources={
            gmailWidgets?.themeTrendPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Box>

      <Grid container spacing={2} mt={3}>
        <Grid item md={6}>
          <Box className="topkewords_wrapper">
            <TableWidget
              wordsList
              title="top_keywords"
              data={topKeywordChartData}
              leftColomnTitle="keyword"
              rightColomnTitle="number_of_reviews"
              showDownloadIcon
              withWordCloud
              hashtags
              tablePagination
              count={topKeywordChartData?.length}
              reduceHeight
            />
          </Box>
        </Grid>
        <Grid item md={6}>
          <PieChartWidget
            title={"dialects_subdialects"}
            titleToolTip="dialects_subdialects_dm_tooltip"
            data={gmailWidgets?.dialects}
            subData={gmailWidgets?.subDialects}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            showDownloadIcon
            bigImg
            pieChartPreLoaderDataSources={gmailWidgets?.dialectsPreLoader}
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          />
        </Grid>
      </Grid>
      <Box my={3}>
        <InteractionsList
          showProfile={true}
          data={gmailWidgets?.latestInteractions.slice(0, 5)}
          title={"latest_interactions"}
          dataSources={props.dataSources}
          isGmail
          latestInteractionsPreLoaderDataSources={
            gmailWidgets?.latestInteractionsPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          showViewMoreButton={gmailWidgets?.latestInteractionsCount > 5}
        />
      </Box>
    </>
  );
};

export default GmailBody;
