import { useQuery } from "@tanstack/react-query";
import { ReportServices } from "services/api/reports";

export const useReportsList = ({ enabled, queryData }) => {
  return useQuery({
    queryKey: ["reportsList", queryData?.page, queryData?.search],
    queryFn: () => ReportServices.getReportsList(queryData),
    enabled,
    select: (data) => data?.data,
  });
};
