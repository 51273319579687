import { Box, Container, Typography } from "@mui/material";
import "./editTeam.scss";
import { useEffect, useState } from "react";
import CircularLoading from "components/circularProgress";
import { EditTeamHeader } from "./components/editTeamHeader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SnackBar from "components/snackBar";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { TeamSharedFuntions } from "../sharedFunctions/teamSharedFunctions";
import { TeamInformation } from "../components/teamInformation";
import { EditTeamMembers } from "./components/editTeamMembers";
import { EditTeamFooter } from "./components/editTeamFooter";
import {
  transformTramWorkingHoursResponse,
  transformWorkingHoursPayload,
} from "./editTeamUtils";
import { isWorkingHoursDifferent } from "../../userManagementPage/subPages/editNewUser/adapterFunctions";
import { useGetTeamDetailsData } from "../hooks/useGetTeamDetailsData";
import { useUpdateTeam } from "../hooks/useUpdateTeam";
import WorkHourAndDay from "../components/workHourAndDay";

const isSelectedMembersChanged = (selectedMembers, originalSelectedMembers) => {
  if (selectedMembers?.length !== originalSelectedMembers?.length) return true;
  const originalMembers = new Set(originalSelectedMembers);
  let isChanged = false;
  selectedMembers?.forEach((member) => {
    if (!originalMembers?.has(member)) {
      isChanged = true;
    }
  });
  return isChanged;
};

export const EditTeam = () => {
  const { id } = useParams();
  const [team, setTeam] = useState({});
  const intl = useIntl();
  const [numberOfChanges, setNumberOfChanges] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  const {
    members,
    setMembers,
    selectedMembers,
    setSelectedMembers,
    handleSelectMember,
    isTeamMemberLoading,
    isTeamProductsLoading,
    products,
    teamError,
    setTeamError,
    setWorkingHours,
    workingHours,
    tabsErrors,
    setTabsErrors,
    autoReplyMessages,
    setAutoReplyMessages,
    initializeDataForEditTeam,
    formik,
  } = TeamSharedFuntions();
  const isEditTeamFormValid =
    !teamError?.teamNameError &&
    numberOfChanges &&
    formik?.isValid &&
    !!selectedMembers?.length;

  const handleCloseSnackBar = () => {
    setTeamError({
      openSnackBar: false,
    });
  };

  const {
    data: teamDetailsData,
    isPending: isPendingTeamDetailsData,
    isError: isErrorTeamDetailsData,
  } = useGetTeamDetailsData(id);

  const { mutate: handleUpdateTeam, isPending: isPendingUpdateTeam } =
    useUpdateTeam();

  useEffect(() => {
    if (!!teamDetailsData && !isErrorTeamDetailsData) {
      const team = teamDetailsData?.data?.data?.attributes;
      const selectedTeams = team?.users_ids || [];
      formik?.setFieldValue("team_name", team?.team_name);
      formik?.setFieldValue("selected_product", team?.product_id);

      setSelectedMembers(selectedTeams);
      setWorkingHours({
        workingHours:
          transformTramWorkingHoursResponse(
            team?.team_working_time?.[0]?.working_time,
          ) || {},
      });
      setAutoReplyMessages(team?.auto_reply_message);
      setTeam(team);
    } else if (isErrorTeamDetailsData) {
      setTeamError({
        message: "try_again_error_message",
        severity: "error",
        title: "failed_error_message",
        openSnackBar: true,
      });
    }
  }, [teamDetailsData, isErrorTeamDetailsData]);

  const handleSave = () => {
    const updatedTeam = {
      team_info: {
        name: formik?.values?.team_name,
        product_id: formik?.values?.selected_product,
        auto_reply_message: autoReplyMessages,
      },
      users: selectedMembers,
      working_hours: transformWorkingHoursPayload(workingHours.workingHours),
    };
    handleUpdateTeam(
      { teamId: id, updatedTeam },
      {
        onSuccess: () => {
          navigate(`/settings/teams/view/${id}?status=updated_successfully`);
        },
        onError: (error) => {
          // check if duplicate team name
          const isDuplicateTeamName =
            error?.errorMsg?.response?.data?.exception?.message ===
            "TEAM_NAME_ALREADY_EXIST";
          if (isDuplicateTeamName) {
            formik.setFieldError("team_name", "team_name_already_exist");
            // go to the top of the page
            window.scrollTo(0, 0);
          } else {
            setTeamError({
              message: "try_again_error_message",
              severity: "error",
              title: "failed_error_message",
              openSnackBar: true,
            });
          }
        },
      },
    );
  };

  const handleCancel = () => {
    // go back to teams list or team details
    const backTo =
      location?.state?.from === "teamDetails"
        ? `/settings/teams/view/${id}`
        : "/settings/teams";
    navigate(backTo);
  };

  useEffect(() => {
    initializeDataForEditTeam(id);
  }, []);

  useEffect(() => {
    formik.validateForm();
    if (!formik?.values?.team_name || !team?.team_name) return;
    // here we will count the number of changes and if its valid, we will show the save button footer
    let counter = 0;
    if (formik?.values?.team_name?.trim() !== team?.team_name?.trim()) {
      counter++;
    }
    const isMembersChanged = isSelectedMembersChanged(
      selectedMembers || [],
      team?.users_ids || [],
    );
    if (isMembersChanged) counter++;

    const didDaysChanged = isWorkingHoursDifferent(
      workingHours.workingHours,
      transformTramWorkingHoursResponse(
        team?.team_working_time?.[0]?.working_time,
      ),
    );

    const didAutoReplyChanged = autoReplyMessages !== team?.auto_reply_message;

    if (didAutoReplyChanged) {
      counter++;
    }

    if (didDaysChanged) {
      counter++;
    }

    setNumberOfChanges(counter);
  }, [
    formik?.values?.team_name,
    selectedMembers,
    workingHours?.workingHours,
    autoReplyMessages,
  ]);

  if (isPendingTeamDetailsData || isTeamMemberLoading || isTeamProductsLoading)
    return <CircularLoading />;

  const isMaxCharactersError = autoReplyMessages?.length > 255;

  return (
    <>
      <Container maxWidth="xl" className="edit-team-container">
        <EditTeamHeader team={team} id={id} />
        <Box className="edit-team-main-container">
          <Box className="edit-team-details">
            {/* Team Information (Team name and Team Product) */}
            <Box className="edit-team-details-section">
              <Typography variant="h3" className="edit-team-details-title">
                {CheckValueLocale("team_details", "", {}, intl)}
              </Typography>
              <Box className="edit-team-details-content">
                <TeamInformation
                  isProductsDisabled={true}
                  options={products || []}
                  formik={formik}
                />
                <Box className="tabs-section-container tabs-divider">
                  <WorkHourAndDay
                    workingHours={workingHours}
                    setWorkingHours={setWorkingHours}
                    setTabsErrors={setTabsErrors}
                    tabsErrors={tabsErrors}
                    autoReplyMessages={autoReplyMessages}
                    setAutoReplyMessages={setAutoReplyMessages}
                    isMaxCharactersError={isMaxCharactersError}
                  />
                </Box>
                <EditTeamMembers
                  selectedMembers={selectedMembers}
                  handleSelectMember={handleSelectMember}
                  members={members}
                  setMembers={setMembers}
                  teamId={id}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <SnackBar
          open={teamError?.openSnackBar}
          severity={teamError?.severity}
          message={CheckValueLocale(teamError?.message, "", {}, intl)}
          title={CheckValueLocale(teamError?.title, "", {}, intl)}
          handleClose={handleCloseSnackBar}
        />
      </Container>
      {numberOfChanges > 0 ? (
        <EditTeamFooter
          handleCancel={handleCancel}
          numberOfChanges={numberOfChanges}
          handleSave={handleSave}
          isDisabled={!isEditTeamFormValid}
          isLoading={isPendingUpdateTeam}
          tabsErrors={tabsErrors}
          isMaxCharactersError={isMaxCharactersError}
        />
      ) : null}
    </>
  );
};
