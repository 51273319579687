export const useHandleMonitorsList = (monitors, selectedDatasources) => {
  const groupedByDatasources = [];

  monitors?.forEach((monitor) => {
    const sources = Array.isArray(monitor?.data_source_id)
      ? monitor?.data_source_id
      : [monitor?.data_source_id];

    sources
      ?.filter((source) => selectedDatasources?.includes(source)) // To not sent keyword monitor all datasources (must be selected by user from datasource input)
      ?.forEach((source) => {
        let existingSource = groupedByDatasources?.find(
          (item) => item?.data_source_id === source,
        );

        if (existingSource) {
          existingSource?.monitors?.push({
            monitor_id: monitor?.id,
            type: monitor?.type || "public",
          });
        } else {
          groupedByDatasources?.push({
            data_source_id: source,
            monitors: [
              { monitor_id: monitor?.id, type: monitor?.type || "public" },
            ],
          });
        }
      });
  });

  return groupedByDatasources || [];
};
