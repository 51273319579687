import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Box, Divider, Menu } from "@mui/material";
import SnackBar from "components/snackBar";
import { CheckValueLocale } from "utils/helpers/index";
import { ArrowDropDown } from "@mui/icons-material";
import excelImage from "images/excel-icon.svg";
import pptImage from "images/power-point-icon.svg";
import "./exportButton.scss";
import LucButton from "shared/lucButton/lucButton";
import PropTypes from "prop-types";
import { handleExcelExport, getDropDownTypes } from "./exportButtonUtils";
import { generateExcelData } from "./exportExcel/ExportExcelSheet/generateExcelData";
import PageSelectBox from "./pageSelectBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons";

const SharedExportButton = ({
  hidePPTExport = false, // hide PPT export option
  hideExcelExport = false, // hide Excel export option
  hideArabicLangExport = false, // hide Arabic language option
  hideEnglishLangExport = false, // hide English language option
  isDataLoading, // to show loading spinner on export button until the data is loaded
  dataLoadedButEmpty = false, // to show snackbar with a message if the data is empty
  exportButtonId = "shared-export-button", // the id of the export button (already has a default value so it's optional)
  exportMenuId = "shared-export-button-menu", // the id of the export menu (already has a default value so it's optional)
  defaultExportType = "excel", // the default export type (ppt or excel)
  savedFileName = "", // the first part of the file name (default is empty)
  excelSheetDataInfo = {}, // an object has the data to be exported in excel format and some info about the page and tab
  exportButtonOptions = {}, // export button options title, varaint, startIcon , type, etc...
  exportButtonTitle = "export",
  handlePPTExport, // the function to export the data in PPT format,
  handleExcelBEExport, // the functon that will call BE and export the excel of these tabs
  pagesData = {}, // an object that holds the pages data (holds bolean to show / hide it, the pages,a function to select between them)
  defaultExportLanguage = "en",
  hideExportArabic = false,
  logExcelExportProcess, // a function runs after exporting excel sheet used for logging
  handleExportButtonId, // get specific export id
  exportpopUpHeading, //  hides text unless in engagements
  menuDirectionPosition, //defines a custom direction for the menu
}) => {
  const intl = useIntl();
  const [openSnackBar, setOpenSnackBar] = useState(false); // to show snackbar
  const [snackBarData, setSnackBarData] = useState({
    // snackbar data
    message: "",
    severity: "",
    title: "",
  });

  const [anchorExport, setAnchorExport] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState({
    name: defaultExportLanguage,
  });
  const [selectedType, setSelectedType] = useState({
    name: defaultExportType,
  });

  const openExport = Boolean(anchorExport);
  let menuDirection =
    menuDirectionPosition ?? (intl?.locale === "en" ? "right" : "left");

  const dropDownTypes = getDropDownTypes(
    hidePPTExport,
    hideExcelExport,
    hideEnglishLangExport,
    hideExportArabic && selectedType?.name === "excel"
      ? true
      : hideArabicLangExport,
  );

  useEffect(() => {
    setSelectedType({
      name: defaultExportType,
    });
  }, [defaultExportType]);

  useEffect(() => {
    setSelectedLanguage({
      name: defaultExportLanguage,
    });
  }, [defaultExportLanguage]);

  //handle selection on lang and type to make export btn enabled
  const handleSelection = (label, option) => {
    label === "language" && setSelectedLanguage(option);
    label === "type" && setSelectedType(option);
  };

  const handleCloseSnackBar = () => {
    setOpenSnackBar(false);
  };

  const exportText = (type) => {
    if (type === "ppt" || type === "excel") {
      // if type is provided then it's a ppt or excel
      const imgSrc = type === "ppt" ? pptImage : excelImage;
      return (
        <Box className="export-file">
          <img src={imgSrc} alt={type} />
          {CheckValueLocale(type, "", {}, intl)}
        </Box>
      );
    }
    // it type is not (ppt || excel) then it's a language (en or ar)
    return CheckValueLocale(type, "", {}, intl);
  };

  const handleChecked = (type, val) => {
    return type === "language"
      ? selectedLanguage?.name === val
      : selectedType?.name === val;
  };

  const handleClickExport = (event) => {
    if (anchorExport !== event?.currentTarget) {
      setAnchorExport(event?.currentTarget);
    }
  };

  const handleCloseExport = () => {
    setAnchorExport(null);

    if (pagesData?.handleSelectPageChange) {
      pagesData?.handleSelectPageChange("currentPage"); // make the selected page current if the select pages is shown
    }
  };
  //handle close export popup modal to reset data after clicking export
  const handleClose = () => {
    setSelectedLanguage({
      name: defaultExportLanguage,
    });
    setSelectedType({
      name: defaultExportType,
    });
    handleCloseExport();
  };

  const handleExportData = () => {
    if (dataLoadedButEmpty) {
      setOpenSnackBar(true);
      setSnackBarData({
        severity: "info",
        title: "no_data_found_to_be_exported",
        message: "export_no_data",
      });
      return;
    } // early return if the data is empty

    if (selectedType?.name === "ppt" && handlePPTExport) {
      handlePPTExport({
        language: selectedLanguage?.name,
        handleClose,
      });
    } else if (selectedType?.name === "excel") {
      if (handleExcelBEExport) {
        handleExcelBEExport(handleClose);
      } else {
        const excelData =
          selectedLanguage && //we have to check language fist to avoid sending it as null
          generateExcelData({
            ...excelSheetDataInfo,
            language: selectedLanguage?.name,
          });
        handleExcelExport(excelData, savedFileName, logExcelExportProcess);
        handleClose();
      }
    }
  };

  useEffect(() => {
    if (hideArabicLangExport && selectedType?.name === "excel") {
      setSelectedLanguage({
        name: "en", // when exporting excel from BE we make it with english only
      });
    }
  }, [hideArabicLangExport, selectedType?.name]);
  return (
    <>
      <Box className="export-btn">
        <LucButton
          id="export-menu-button"
          aria-owns={anchorExport}
          aria-haspopup="true"
          aria-expanded={openExport}
          onClick={handleClickExport}
          endIcon={<ArrowDropDown />}
          variant="outline"
          {...exportButtonOptions}
        >
          {CheckValueLocale(exportButtonTitle, "", {}, intl)}
        </LucButton>

        <Menu
          id={exportMenuId}
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
            onMouseLeave: handleCloseExport,
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: menuDirection,
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: menuDirection,
          }}
          anchorEl={anchorExport}
          open={openExport}
          onClose={handleCloseExport}
          className="export-menu"
        >
          <Box className="export-btn-menu">
            <Box className="export-options-title">
              {CheckValueLocale("export_options", "", {}, intl)}
            </Box>
            <Box className="radio-item export-item">
              {dropDownTypes?.map((type, index) => {
                return (
                  <Box key={index}>
                    <Box className={`export-label ${type?.label}`}>
                      {type?.options?.map((val, i) => {
                        return (
                          <React.Fragment key={i}>
                            {!val?.hideOption ? (
                              <label className="radio-item">
                                <input
                                  type="radio"
                                  name={"export-" + type?.label}
                                  id={
                                    handleExportButtonId?.(val) ||
                                    "export-" + type?.label
                                  }
                                  onClick={() =>
                                    handleSelection(type?.label, val)
                                  }
                                  checked={handleChecked(
                                    type?.label,
                                    val?.name,
                                  )}
                                />
                                <Box component="span" className="export-choose">
                                  {exportText(val?.name)}
                                </Box>
                              </label>
                            ) : null}
                          </React.Fragment>
                        );
                      })}
                    </Box>
                  </Box>
                );
              })}

              {pagesData?.showSelectPagesBox &&
              selectedType?.name !== "excel" ? (
                <PageSelectBox
                  activeTabName={excelSheetDataInfo?.activeTabName}
                  pagesData={pagesData}
                />
              ) : null}
              {exportpopUpHeading ? (
                <Box className="export-all-engagements">
                  {CheckValueLocale("export_all_engagements", "", {}, intl)}
                </Box>
              ) : null}
              <Divider />

              <LucButton
                variant="filled"
                loading={isDataLoading}
                className="loading-export-btn-margin"
                onClick={handleExportData}
                id={handleExportButtonId?.() || exportButtonId}
                disabled={selectedLanguage && selectedType ? false : true}
              >
                {CheckValueLocale("export", "", {}, intl)}
              </LucButton>
            </Box>
          </Box>
        </Menu>
      </Box>

      {openSnackBar ? (
        <SnackBar
          open={openSnackBar}
          severity={snackBarData?.severity}
          message={CheckValueLocale(snackBarData?.message, "", {}, intl)}
          title={CheckValueLocale(snackBarData?.title, "", {}, intl)}
          handleClose={handleCloseSnackBar}
        />
      ) : null}
    </>
  );
};

SharedExportButton.propTypes = {
  isDataLoading: PropTypes.bool.isRequired,
  savedFileName: PropTypes.string.isRequired,
};
export default SharedExportButton;
