import { Box, Grid, Typography } from "@mui/material";
import MediaTw from "components/media/media-tw/mediaTw";
import LineChartWidget from "components/widgets/lineChartWidget";
import TopPosts from "pages/Monitors/Components/topPosts/topPosts";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import BarChartWidget from "components/widgets/barChartWidget";
import PunchCardWidget from "components/widgets/punchCardWidget";
import StackedBarWidget from "components/widgets/stackedBarWidget";
import SnackBar from "components/snackBar";
import { useParams } from "react-router-dom";
import CommonFunctions from "pages/Monitors/Components/commonFunctions";
import { handleUnixFormat, CheckValueLocale } from "utils/helpers";
import PopupModal from "components/popupModal";
import xPlatform from "images/social-icons/x-platform-white-icon.svg";
import { MonitorStatistics } from "components/widgets/monitorStatistics";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartMixed } from "@fortawesome/pro-solid-svg-icons";
import _ from "lodash";
import {
  faComments,
  faEarthAmerica,
  faPercent,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";

/*---------------------------------------------------------*/
const AccountBody = (props) => {
  const intl = useIntl();
  let { response, monitorData } = props;

  //handle response
  const [postsVolume, setPostsVolume] = useState([]);
  const [totalPostsVolumeCount, setTotalPostsVolumeCount] = useState([]);
  const [isPostsVolumeHourlyFormat, setIsPostsVolumeHourlyFormat] =
    useState(false);
  const [topVideos, setTopVideos] = useState([]);
  const [topImages, setTopImages] = useState([]);
  const [topPosts, setTopPosts] = useState([]);
  const [topPostsCount, setTopPostsCount] = useState(0);
  const [authorActivity, setAuthorActivity] = useState([]);
  const [minValueAuthorActivity, setMinValueAuthorActivity] = useState(0);
  const [maxValueAuthorActivity, setMaxValueAuthorActivity] = useState(0);
  const [postsContentType, setPostsContentType] = useState([]);
  const [contentTypeLegend, setContentTypeLegend] = useState([]);

  const [reachFunnel, setReachFunnel] = useState([]);

  const [postsInteractions, setPostsInteractions] = useState([]);
  const [maxValuePostsInteractions, setMaxValuePostsInteractions] = useState(0);
  const [minValuePostsInteractions, setMinValuePostsInteractions] = useState(0);
  const [avgValuePostsInteractions, setAvgValuePostsInteractions] = useState(0);
  const [maxValueInteractionsDate, setMaxValueInteractionsDate] = useState(0);
  const [minValueInteractionsDate, setMinValueInteractionsDate] = useState(0);
  const [postInteractionsLegend, setPostInteractionsLegend] = useState([]);
  const [isPostsInteractionsHourlyFormat, setIsPostsInteractionsHourlyFormat] =
    useState(false);

  const [followersGrowth, setFollowersGrowth] = useState([]);
  const [totalFollowersFollowersGrowth, setTotalFollowersGrowth] = useState(0);
  const [maxChangeFollowersGrowth, setMaxChangeFollowersGrowth] = useState(0);
  const [avgChangeFollowersGrowth, setAvgChangeFollowersGrowth] = useState(0);

  // Values of DataSources totrack each widget preloader
  const [postsVolumePreLoaderDataSources, setPostsVolumePreLoaderDataSources] =
    useState([]);
  const [topVideosPreLoaderDataSources, setTopVideosPreLoaderDataSources] =
    useState([]);
  const [topImagesPreLoaderDataSources, setTopImagesPreLoaderDataSources] =
    useState([]);
  const [topTopPostsPreLoaderDataSources, setTopPostsPreLoaderDataSources] =
    useState([]);
  const [
    authorActivityPreLoaderDataSources,
    setAuthorActivityPreLoaderDataSources,
  ] = useState([]);
  const [
    postsInteractionsPreLoaderDataSources,
    setPostsInteractionsPreLoaderDataSources,
  ] = useState([]);
  const [
    postsContentTypePreLoaderDataSources,
    setPostsContentTypePreLoaderDataSources,
  ] = useState([]);
  const [
    followersGrowthPreLoaderDataSources,
    setFollowersGrowthPreLoaderDataSources,
  ] = useState([]);
  const [reachFunnelPreLoaderDataSources, setReachFunnelPreLoaderDataSources] =
    useState([]);

  //handle response with useEffect (when load page & any update)

  const XIcon = (
    <span className="topics-twitter-icon">
      <img
        src={xPlatform}
        alt="x-platform-logo"
        className={"icon-after-title twitter-icon"}
      />
    </span>
  );
  const handleResponseFunc = async () => {
    if (response.eventName === "Twitter__AccountPage__volume_overtime") {
      setPostsVolume(response.eventData.posts_over_time);
      setTotalPostsVolumeCount(response.eventData.total_posts_count);
      setPostsVolumePreLoaderDataSources((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
      setIsPostsVolumeHourlyFormat(response?.eventData?.is_24_hours_format);
    } else if (
      response.eventName === "Twitter__AccountPage__account_author_activity"
    ) {
      setMinValueAuthorActivity(response.eventData.min_value);
      setMaxValueAuthorActivity(response.eventData.max_value);
      setAuthorActivity(response.eventData.account_author_activity);
      setAuthorActivityPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else if (
      response.eventName === "Twitter__AccountPage__followers_growth"
    ) {
      setFollowersGrowth(response.eventData.followers_change_over_time);
      setTotalFollowersGrowth(response.eventData.total_followers);
      setMaxChangeFollowersGrowth(response.eventData.max_change_of_followers);
      setAvgChangeFollowersGrowth(response.eventData.avg_chage_of_day);
      setFollowersGrowthPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else if (
      response.eventName === "Twitter__AccountPage__posts_interactions"
    ) {
      setPostsInteractions(
        response.eventData.posts_interactions.posts_interactions_over_time,
      );
      setMaxValuePostsInteractions(
        response.eventData.posts_interactions.max_interactions,
      );
      setMinValuePostsInteractions(
        response.eventData.posts_interactions.min_interactions,
      );
      setAvgValuePostsInteractions(
        response.eventData.posts_interactions.average_interactions,
      );
      let date = handleUnixFormat(
        response.eventData.posts_interactions.max_interaction_date,
      );
      setMaxValueInteractionsDate(date);
      date = handleUnixFormat(
        response.eventData.posts_interactions.min_interaction_date,
      );
      setMinValueInteractionsDate(date);
      setPostInteractionsLegend(
        response.eventData.posts_interactions.posts_interactions_legends,
      );
      setPostsInteractionsPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
      setIsPostsInteractionsHourlyFormat(
        response?.eventData?.is_24_hours_format,
      );
    } else if (response.eventName === "Twitter__AccountPage__content_type") {
      setPostsContentType(response.eventData.posts_content_type);
      setContentTypeLegend(response.eventData.posts_content_types_legends);
      setPostsContentTypePreLoaderDataSources((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else if (response.eventName === "Twitter__AccountPage__reach_funnel") {
      setReachFunnel(response.eventData.reach_funnel);
      setReachFunnelPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else if (response.eventName === "Twitter__AccountPage__top_videos") {
      setTopVideos(response.eventData.top_videos);
      setTopVideosPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else if (response.eventName === "Twitter__AccountPage__top_images") {
      setTopImages(response.eventData.top_images);
      setTopImagesPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else if (
      response.eventName === "Twitter__AccountPage__top_posts_comments"
    ) {
      setTopPosts(response.eventData.top_posts);
      setTopPostsCount(response.eventData.top_posts_count);
      setTopPostsPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else {
      return null;
    }
  };
  useEffect(() => {
    handleResponseFunc();
  }, [response]);
  //_____________________________________________________________________________________
  //getting Data to export xls sheet
  useEffect(() => {
    props.setExcelSheetData([
      { totalFollowersFollowersGrowth: totalFollowersFollowersGrowth },
      { maxChangeFollowersGrowth: maxChangeFollowersGrowth },
      { avgChangeFollowersGrowth: avgChangeFollowersGrowth },
      { followersGrowth: followersGrowth },
      {
        authorActivity: [
          authorActivity,
          minValueAuthorActivity,
          maxValueAuthorActivity,
        ],
      },
      { reachFunnel: reachFunnel },
      { postsVolume: postsVolume },
      { postsInteractions: postsInteractions },
      { postsContentType: postsContentType },
      { topVideos: topVideos },
      { topImages: topImages },
      { topPosts: topPosts },
      { minValueAuthorActivity: minValueAuthorActivity },
      { maxValueAuthorActivity: maxValueAuthorActivity },
      {
        isHourlyFormat: {
          isPostsVolumeHourlyFormat,
          isPostsInteractionsHourlyFormat,
        },
      },
    ]);
  }, [
    totalFollowersFollowersGrowth,
    maxChangeFollowersGrowth,
    avgChangeFollowersGrowth,
    followersGrowth,
    authorActivity,
    reachFunnel,
    postsVolume,
    isPostsVolumeHourlyFormat,
    postsInteractions,
    isPostsInteractionsHourlyFormat,
    postsContentType,
    topVideos,
    topImages,
    topPosts,
    minValueAuthorActivity,
    maxValueAuthorActivity,
  ]);
  //_____________________________________________________________________________________
  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (props.resetAllFields !== 1) {
      setPostsVolume([]);
      setTotalPostsVolumeCount([]);
      setIsPostsVolumeHourlyFormat(false);
      setTopImages([]);
      setTopVideos([]);
      setTopPosts([]);
      setTopPostsCount(0);
      setMinValueAuthorActivity(0);
      setMaxValueAuthorActivity(0);
      setPostsInteractions([]);
      setMaxValuePostsInteractions(0);
      setMinValuePostsInteractions(0);
      setAvgValuePostsInteractions(0);
      setMaxValueInteractionsDate(0);
      setMinValueInteractionsDate(0);
      setPostInteractionsLegend([]);
      setIsPostsInteractionsHourlyFormat(false);
      setPostsContentType([]);
      setContentTypeLegend([]);
      setFollowersGrowth([]);
      setTotalFollowersGrowth(0);
      setMaxChangeFollowersGrowth(0);
      setAvgChangeFollowersGrowth(0);
      setReachFunnel([]);

      // Values of DataSources toTrack each widget preloader
      setPostsVolumePreLoaderDataSources([]);
      setTopVideosPreLoaderDataSources([]);
      setTopImagesPreLoaderDataSources([]);
      setTopPostsPreLoaderDataSources([]);
      setAuthorActivityPreLoaderDataSources([]);
      setPostsInteractionsPreLoaderDataSources([]);
      setPostsContentTypePreLoaderDataSources([]);
      setFollowersGrowthPreLoaderDataSources([]);
      setReachFunnelPreLoaderDataSources([]);
    }
  }, [props.resetAllFields]);

  // ___________________________________________________________________________________________________________________
  const {
    updateAnalysis,
    handleCloseSnackBar,
    showAnalyzeTweetSnackBar,
    analyzeTweetSnackBar,
    stats,
    handleCloseCreateModal,
    modalBody,
  } = CommonFunctions();

  useEffect(() => {
    let totalPreLoaderDataSouces = [];

    totalPreLoaderDataSouces.push(
      postsVolumePreLoaderDataSources,
      authorActivityPreLoaderDataSources,
      followersGrowthPreLoaderDataSources,
      postsInteractionsPreLoaderDataSources,
      postsContentTypePreLoaderDataSources,
      reachFunnelPreLoaderDataSources,
      topVideosPreLoaderDataSources,
      topImagesPreLoaderDataSources,
      topTopPostsPreLoaderDataSources,
    );
    props.checkAllDataFounded(
      totalPreLoaderDataSouces,
      props.preLoaderTrackerDataSources,
    );

    props.checkDataIsFounded(
      authorActivity,
      reachFunnel,
      postsVolume,
      postsInteractions,
      postsContentType,
      topPosts,
      topImages,
      topVideos,
    );
  }, [
    postsVolumePreLoaderDataSources,
    authorActivityPreLoaderDataSources,
    followersGrowthPreLoaderDataSources,
    postsInteractionsPreLoaderDataSources,
    postsContentTypePreLoaderDataSources,
    reachFunnelPreLoaderDataSources,
    topVideosPreLoaderDataSources,
    topImagesPreLoaderDataSources,
    topTopPostsPreLoaderDataSources,
    followersGrowth,
    authorActivity,
    reachFunnel,
    postsVolume,
    postsInteractions,
    postsContentType,
    topPosts,
    topImages,
    topVideos,
  ]);

  const totalEngagement = postInteractionsLegend.reduce(
    (acc, curr) => acc + curr.value,
    0,
  );

  const totalPotentialImpression = reachFunnel
    .find((i) => i?.name === "potential_impression")
    ?.value?.reduce((acc, curr) => parseInt(acc, 10) + parseInt(curr, 10), 0);

  const totalPotentialReach = reachFunnel
    .find((i) => i?.name === "potential_reach")
    ?.value?.reduce((acc, curr) => parseInt(acc, 10) + parseInt(curr, 10), 0);

  const monitorStatistics = [
    {
      title: CheckValueLocale(
        "monitor_statistics_keyword_top_posts_title",
        "",
        {},
        intl,
      ),
      icon: (
        <FontAwesomeIcon
          className="monitor-widget-summary-icon"
          icon={faChartMixed}
        />
      ),
      tooltipTitle: CheckValueLocale(
        "monitor_statistics_keyword_top_posts_tooltip",
        "",
        {},
        intl,
      ),
      value: totalPostsVolumeCount?.toLocaleString(),
      isLoading: _.isEqual(
        postsVolumePreLoaderDataSources?.sort(),
        props?.preLoaderTrackerDataSources?.sort(),
      ),
    },
    {
      title: CheckValueLocale(
        "monitor_statistics_keyword_total_engagements_title",
        "",
        {},
        intl,
      ),
      icon: (
        <FontAwesomeIcon
          className="monitor-widget-summary-icon"
          icon={faComments}
        />
      ),
      tooltipTitle: CheckValueLocale(
        "monitor_statistics_keyword_total_engagements_tooltip",
        "",
        {},
        intl,
      ),
      value: totalEngagement?.toLocaleString(),
      isLoading: _.isEqual(
        postsInteractionsPreLoaderDataSources?.sort(),
        props?.preLoaderTrackerDataSources?.sort(),
      ),
    },
    {
      title: CheckValueLocale(
        "monitor_statistics_keyword_average_engagement_title",
        "",
        {},
        intl,
      ),
      icon: (
        <FontAwesomeIcon
          className="monitor-widget-summary-icon"
          icon={faPercent}
        />
      ),
      tooltipTitle: CheckValueLocale(
        "monitor_statistics_keyword_average_engagement_tooltip",
        "",
        {},
        intl,
      ),
      value: (totalEngagement / totalPostsVolumeCount).toLocaleString(
        undefined,
        {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        },
      ),
      isLoading:
        _.isEqual(
          postsVolumePreLoaderDataSources?.sort(),
          props?.preLoaderTrackerDataSources?.sort(),
        ) &&
        _.isEqual(
          postsInteractionsPreLoaderDataSources?.sort(),
          props?.preLoaderTrackerDataSources?.sort(),
        ),
    },
    {
      title: CheckValueLocale(
        "monitor_statistics_keyword_total_potential_reach_title",
        "",
        {},
        intl,
      ),
      icon: (
        <FontAwesomeIcon
          className="monitor-widget-summary-icon"
          icon={faEarthAmerica}
        />
      ),
      tooltipTitle: CheckValueLocale(
        "monitor_statistics_keyword_total_potential_reach_tooltip",
        "",
        {},
        intl,
      ),
      isLoading: _.isEqual(
        reachFunnelPreLoaderDataSources?.sort(),
        props?.preLoaderTrackerDataSources?.sort(),
      ),
      value: totalPotentialReach?.toLocaleString(undefined, {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      }),
    },
    {
      title: CheckValueLocale(
        "monitor_statistics_keyword_total_potential_impression_title",
        "",
        {},
        intl,
      ),
      icon: (
        <FontAwesomeIcon
          className="monitor-widget-summary-icon"
          icon={faUsers}
        />
      ),
      tooltipTitle: CheckValueLocale(
        "monitor_statistics_keyword_total_potential_impression_reach_title",
        "",
        {},
        intl,
      ),
      isLoading: _.isEqual(
        reachFunnelPreLoaderDataSources?.sort(),
        props?.preLoaderTrackerDataSources?.sort(),
      ),
      value: totalPotentialImpression?.toLocaleString(undefined, {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      }),
    },
  ];

  return (
    <>
      <Typography className="section-name">
        {CheckValueLocale("overview", "", {}, intl).toUpperCase()}
      </Typography>
      <Box mt={3}>
        <MonitorStatistics data={monitorStatistics} />

        {/* __________________________________ */}
        <BarChartWidget
          title={"followers_growth"}
          titleToolTip="followers_growth_tw_tooltip"
          data={followersGrowth}
          showGroupBy={true}
          dataColor="#2AAAE2"
          showChartDetailsWidget
          showZoomPercentage
          statsDetailsData={[
            {
              name: "total_followers",
              value: totalFollowersFollowersGrowth,
            },
            {
              name: "max_change_of_followers",
              value: maxChangeFollowersGrowth,
            },
            {
              name: "avg_change_per_day",
              value: avgChangeFollowersGrowth,
            },
          ]}
          toolTipLabel={"posts"}
          showFollowersGrowthStats
          showDownloadIcon
          bigImg
          iconAfterTitle={props.TwitterIcon}
          barChartPreLoaderDataSources={followersGrowthPreLoaderDataSources}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
        {/* __________________________________ */}
      </Box>
      <Box my={3} className="channel-activity">
        <PunchCardWidget
          title="account_authors_activity"
          titleToolTip="account_authors_activity_tw_tooltip"
          clientActivity={authorActivity}
          minValueClient={minValueAuthorActivity}
          maxValueClient={maxValueAuthorActivity}
          showDownloadIcon
          bigImg
          iconAfterTitle={props.TwitterIcon}
          authorActivityPreLoaderDataSources={
            authorActivityPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Box>
      <Grid mb={3}>
        <StackedBarWidget
          title={"reach_funnel"}
          titleToolTip="reach_funnel_tw_tooltip"
          showDownloadIcon
          bigImg
          showScales
          posts={reachFunnel}
          colors={["#64748B", "#2AAAE2"]}
          labels={["Retweets", "original"]}
          reachFunnel
          iconAfterTitle={props.TwitterIcon}
          postsColumnPreLoaderDataSources={reachFunnelPreLoaderDataSources}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Grid>
      {/* <Grid item md={6}>
        <StackedBarWidget
          title={"reach_funnel_legacy"}
          titleToolTip="reach_funnel_legacy_tw_tooltip"
          showDownloadIcon
          bigImg
          posts={reachFunnelLegacy}
          colors={["#64748B", "#2AAAE2"]}
          labels={["Retweets", "original"]}
          reachFunnel
          iconAfterTitle={props.TwitterIcon}
          postsColumnPreLoaderDataSources={
            reachFunnelLegacyPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Grid> */}

      <Typography className="section-title">
        {CheckValueLocale("posts_analytics", "", {}, intl).toUpperCase()}
      </Typography>
      <Box mt={3} className="posts-volume-chart">
        <LineChartWidget
          title={"posts_volume"}
          titleToolTip="posts_volume_public_ig_tooltip"
          totalLabel={"posts"}
          totalValue={totalPostsVolumeCount}
          data={postsVolume}
          showGroupBy={true}
          toolTipLabel={"posts"}
          labelColor={"#2AAAE2"}
          showDownloadIcon
          bigImg
          iconAfterTitle={props.TwitterIcon}
          lineChartPreLoaderDataSources={postsVolumePreLoaderDataSources}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          isHourlyFormat={isPostsVolumeHourlyFormat}
        />
      </Box>
      <Box mt={3}>
        <StackedBarWidget
          title={"posts_interactions"}
          titleToolTip="posts_interactions_tw_tooltip"
          showDownloadIcon
          bigImg
          posts={postsInteractions}
          showChartDetailsWidget
          showScales
          showGroupBy={false}
          statsDetailsData={[
            {
              name: "max_interaction",
              value: maxValuePostsInteractions,
              date: maxValueInteractionsDate,
            },
            {
              name: "min_interaction",
              value: minValuePostsInteractions,
              date: minValueInteractionsDate,
            },
            {
              name: "avg_interaction_per_day",
              value: avgValuePostsInteractions,
            },
          ]}
          colors={["#2AAAE2", "#80868C", "#334155"]}
          labels={["Retweets", "likes", "replies"]}
          showPostsInteractionStats
          showZoomPercentage
          iconAfterTitle={props.TwitterIcon}
          postsColumnPreLoaderDataSources={
            postsInteractionsPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          hideLegend
          dataPiechart={postInteractionsLegend}
          isHourlyFormat={isPostsInteractionsHourlyFormat}
        />
      </Box>
      <Box mt={3}>
        <StackedBarWidget
          title={"posts_content_type"}
          titleToolTip="posts_content_type_tw_tooltip"
          posts={postsContentType}
          colors={["#2AAAE2", "#80868C", "#334155", "#AFBCCE"]}
          labels={["video", "image", "url", "text"]}
          showDownloadIcon
          showScales
          bigImg
          showGroupBy={false}
          showZoomPercentage
          iconAfterTitle={props.TwitterIcon}
          postsColumnPreLoaderDataSources={postsContentTypePreLoaderDataSources}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          hideLegend
          dataPiechart={contentTypeLegend}
        />
      </Box>
      <Box my={4}>
        <MediaTw
          title={CheckValueLocale("top_videos", "", {}, intl)}
          titleToolTip="top_videos_tw_tooltip"
          data={topVideos}
          errorMessage={CheckValueLocale("no_videos_found", "", {}, intl)}
          iconAfterTitle={props.TwitterIcon}
          mediaTwPreLoaderDataSources={topVideosPreLoaderDataSources}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Box>
      <Box my={4}>
        <MediaTw
          title={CheckValueLocale("top_images", "", {}, intl)}
          titleToolTip="top_images_tw_tooltip"
          data={topImages}
          errorMessage={CheckValueLocale("no_images_found", "", {}, intl)}
          iconAfterTitle={props.TwitterIcon}
          mediaTwPreLoaderDataSources={topImagesPreLoaderDataSources}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Box>
      <Box mt={3}>
        <TopPosts
          data={topPosts.slice(0, 6)}
          topPostsCount={topPostsCount}
          errorMessage={CheckValueLocale("no_posts_found", "", {}, intl)}
          topPostsHeader
          iconAfterTitle={XIcon}
          topTopPostsPreLoaderDataSources={topTopPostsPreLoaderDataSources}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          updateAnalysis={updateAnalysis}
          setFilterParams={props.setFilterParams}
          filterParams={props.filterParams}
          applyFilter={props.applyFilter}
          startDate={props.startDate}
          endDate={props.endDate}
          singleTweetAnalysis={props.singleTweetAnalysis}
          monitorData={monitorData}
          hideChangeSentiment
        />
      </Box>

      {showAnalyzeTweetSnackBar && (
        <SnackBar
          open={true}
          handleClose={handleCloseSnackBar}
          title={CheckValueLocale(analyzeTweetSnackBar.title, "", {}, intl)}
          message={CheckValueLocale(analyzeTweetSnackBar.message, "", {}, intl)}
          severity={analyzeTweetSnackBar.severity}
        />
      )}
      <PopupModal
        title={CheckValueLocale("single_tweet_analysis", "", {}, intl)}
        body={modalBody}
        open={stats?.openCreateModal}
        close={handleCloseCreateModal}
        warning="warning"
        classeName="create-historical-popup"
        addClasses="create-single-post-analysis-modal"
      />
    </>
  );
};

export default AccountBody;
