import {
  Box,
  Checkbox,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const CreateReportMonitorsSelect = ({
  options,
  value,
  onChange = () => {},
  onBlur = () => {},
  loading = false,
  error = false,
  helperText = "",
  multiple,
  disabled,
}) => {
  const intl = useIntl();

  return (
    <Box className="create-report-input-wrapper">
      <InputLabel className="create-report-input-label">
        {CheckValueLocale("monitor", "", {}, intl)}
      </InputLabel>
      <Select
        variant="outlined"
        size="small"
        fullWidth
        id="create-report-select-channels-input"
        name="monitor_list"
        disabled={disabled}
        value={value || []}
        onChange={onChange}
        onBlur={onBlur}
        renderValue={(value) => {
          if (multiple) {
            if (!value?.length) return CheckValueLocale("select", "", {}, intl);
            else return value?.map((item) => item?.name)?.join(", ");
          } else {
            if (!value?.name) return CheckValueLocale("select", "", {}, intl);
            else return value?.name;
          }
        }}
        MenuProps={{
          PaperProps: { style: { maxHeight: 160, maxWidth: 100 } },
          getContentAnchorEl: null,
          anchorOrigin: { vertical: "bottom" },
          transformOrigin: { vertical: "top" },
        }}
        multiple={multiple}
        displayEmpty
        error={error}
      >
        {loading ? (
          <MenuItem id="create-report-select-input-menu-item" disabled>
            {CheckValueLocale("dropdown_loading", "", {}, intl)}
          </MenuItem>
        ) : options?.length >= 1 ? (
          options?.map((option) => {
            return (
              <MenuItem
                key={option?.value}
                id="create-report-select-input-menu-item"
                className={
                  multiple
                    ? "create-report-multiple-select-input-menu-item"
                    : "create-report-select-input-menu-item"
                }
                value={option}
              >
                {multiple ? (
                  <Checkbox
                    checked={!!value?.find((item) => item?.id === option?.id)}
                  />
                ) : null}
                {option?.name}
              </MenuItem>
            );
          })
        ) : (
          <MenuItem id="create-report-select-input-menu-item" disabled>
            {CheckValueLocale(
              "searchable_multi_select_filter_no_options",
              "",
              {},
              intl,
            )}
            ...
          </MenuItem>
        )}
      </Select>
      {error ? (
        <FormHelperText error>
          {CheckValueLocale(helperText || "", "", {}, intl)}
        </FormHelperText>
      ) : null}
    </Box>
  );
};

export default CreateReportMonitorsSelect;
