import { useMutation, useQueryClient } from "@tanstack/react-query";
import Engagements from "../../../../../services/api/engagements";

export const useAddTag = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data) => Engagements.addEngagementTag(data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["engagementTagsList"] });
    },
  });
};
