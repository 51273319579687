import { Container } from "@mui/material";
import { useParams } from "react-router-dom";
import SurveyDetails from "./surveyDetails";
import SurveyHeader from "./surveyHeader";
import SurveyQuestion from "./surveyQuestion";
import useAudienceUserProfile from "./hooks/useAudienceUserProfile";
import useAudienceSurveyDetails from "./hooks/useAudienceSurveyDetails";
import CircularLoading from "components/circularProgress";
import NotFound from "pages/not-found/NotFoundPage";

const SurveyBody = () => {
  const urlParams = useParams();
  const { profileId, surveyId, respondantId } = urlParams || {};

  const {
    data: userProfileDetails,
    isPending: userProfileDetailsLoading,
    isError: userProfileDetailsError,
  } = useAudienceUserProfile(profileId);

  const {
    data: surveyDetails,
    isPending: surveyDetailsLoading,
    isError: surveyDetailsError,
  } = useAudienceSurveyDetails(profileId, surveyId, respondantId);

  if (surveyDetailsError || userProfileDetailsError) {
    return <NotFound />;
  }

  return (
    <Container maxWidth="xl">
      {surveyDetailsLoading || userProfileDetailsLoading ? (
        <CircularLoading />
      ) : (
        <>
          <SurveyHeader
            id={respondantId}
            detail={surveyDetails}
            profile={userProfileDetails}
          />
          <SurveyDetails profile={userProfileDetails} detail={surveyDetails} />
          <SurveyQuestion
            data={surveyDetails}
            questions={surveyDetails?.survey}
            isCDP={true}
            isAlert={false}
          />
        </>
      )}
    </Container>
  );
};

export default SurveyBody;
