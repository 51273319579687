import React from "react";
import moment from "moment";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import "./style.scss";

const ReportBrandingSelect = (props) => {
  const {
    isCreate,
    brandingValue,
    handleChangeBranding,
    companyHasBranding,
    lastUpdated,
  } = props;

  const intl = useIntl();

  return (
    <FormControl id="report-branding-select-group">
      <FormLabel className="branding-label">
        {CheckValueLocale("report_branding", "", {}, intl)}
      </FormLabel>
      <RadioGroup
        aria-labelledby="controlled-branding-radio-group"
        name="controlled-branding-radio-group"
        value={brandingValue}
        onChange={handleChangeBranding}
      >
        <FormControlLabel
          className="branding-choice-input"
          value={false}
          control={<Radio disabled={!companyHasBranding} />}
          label={
            <Box className="radio-label">
              <Box className="title">
                {CheckValueLocale(
                  "report_branding_default_title",
                  "",
                  {},
                  intl,
                )}
              </Box>
              <Box className="description">
                {CheckValueLocale("report_branding_default_desc", "", {}, intl)}
              </Box>
            </Box>
          }
        />
        <FormControlLabel
          className="branding-choice-input"
          value={true}
          control={<Radio disabled={!companyHasBranding} />}
          label={
            <Box className="radio-label">
              <Box className="title">
                {CheckValueLocale(
                  "report_branding_company_colors_title",
                  "",
                  {},
                  intl,
                )}
                {companyHasBranding && lastUpdated ? (
                  <Box component={"span"} className="updated-date">
                    {`(${CheckValueLocale("last_updated", "", {}, intl)}: ${moment?.unix(lastUpdated)?.format("DD/MM/YYYY")})`}
                  </Box>
                ) : null}
              </Box>
              <Box className="description">
                {CheckValueLocale(
                  isCreate
                    ? "report_branding_company_colors_desc"
                    : "edit_report_branding_company_colors_desc",
                  "",
                  {
                    link: (
                      <a
                        className="link"
                        href="/settings/branding"
                        target="_blank"
                      >
                        {CheckValueLocale("here", "", {}, intl)}
                      </a>
                    ),
                  },
                  intl,
                )}
              </Box>
            </Box>
          }
        />
      </RadioGroup>
    </FormControl>
  );
};

export default ReportBrandingSelect;
