import CustomizedTooltip, {
  customizedStyledTooltip,
} from "components/customizedTooltip";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";

const ExtrnalLinkIcon = ({ urlLink }) => {
  const intl = useIntl();
  return (
    <CustomizedTooltip
      title={CheckValueLocale("external_media_tooltip", "", {}, intl)}
      styles={customizedStyledTooltip}
      arrow
      placement="bottom"
    >
      <a href={urlLink} target="_blank" rel="noreferrer">
        <Box className="external-media-link">
          <FontAwesomeIcon
            icon={faExternalLink}
            id="monitors-external-link-fa-icon"
          />
        </Box>
      </a>
    </CustomizedTooltip>
  );
};

export default ExtrnalLinkIcon;
