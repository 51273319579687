import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { ReportListContext } from "pages/reports/context/reportContext";
import SearchIcon from "@mui/icons-material/Search";
import PageHeader from "components/pageHeader";
import LucButton from "shared/lucButton/lucButton";
import ReportsListFilters from "./reportsListFilters/reportsListFilters";

const ReportsListHeader = () => {
  const { setPage, search, setSearch, setClickedFilter } =
    useContext(ReportListContext);

  const navigate = useNavigate();
  const intl = useIntl();

  const [tempSearch, setTempSearch] = useState("");

  useEffect(() => {
    setTempSearch(search || "");
  }, [search]);

  return (
    <Box id="reports-list-page-header-container">
      <Box className="reports-page-header-title-wrapper">
        <PageHeader title="reports" />
        <LucButton
          startIcon={<FontAwesomeIcon fixedWidth icon={faPlus} />}
          onClick={() => navigate("/reports/add-report")}
          id="report-create-new-report-btn"
        >
          {CheckValueLocale("new_report", "", {}, intl)}
        </LucButton>
      </Box>
      <Box className="reports-page-header-search-filter-wrapper">
        <FormControl variant="outlined" className="report-list-search-field">
          <OutlinedInput
            id="reports-list-search-input"
            type="text"
            placeholder={CheckValueLocale(
              "search_by_report_name",
              "",
              {},
              intl,
            )}
            value={tempSearch}
            onChange={(e) => setTempSearch(e?.target?.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setPage(1);
                setTempSearch(e.target.value);
                setSearch(tempSearch);
                setClickedFilter(false);
              }
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => {
                    setPage(1);
                    setSearch(tempSearch);
                    setClickedFilter(false);
                  }}
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <ReportsListFilters />
      </Box>
    </Box>
  );
};

export default ReportsListHeader;
