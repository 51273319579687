import _ from "lodash";

// TODO we need to try setState here
// ******************* PIE CHART / Table Charts Common Function **************************** //
// This function will be used to handle aggregate data for piecharts/table charts
// parameters:
// newArrayToAggregate (Array) required: The value of new array that should be added to array that will hold all data
// finalResult (Array) required: The value of final array that will hold all data to be displayed in chart
// Note: Each array should be array of objects where each object should have Name & Value
const handleAggregatePieChart = (
  newArrayToAggregate,
  finalResult,
  keyOne = "name",
  keyTwo = "value",
  sort,
) => {
  let newArrayToBeAdded = [];
  var found = false;
  if (finalResult?.length > 0) {
    newArrayToAggregate?.forEach(function (a) {
      finalResult?.some(function (b) {
        found = false;
        if (a[keyOne] === b[keyOne]) {
          b[keyTwo] += a[keyTwo];
          found = true;
          return true;
        }
      });
      if (!found) {
        newArrayToBeAdded.push(a);
      }
    });
  } else {
    finalResult.push(...newArrayToAggregate);
  }
  finalResult.push(...newArrayToBeAdded);
  if (sort) {
    finalResult?.sort((a, b) => (a[keyTwo] < b[keyTwo] ? 1 : -1));
  }
};
// ************************************************************************************** //
// ******************* Line Chart Common Function *************************************** //
// This function will be used to handle aggregate data for Line chart that has more than one line
// parameters:
// newObjectToAggregate (object) required: the object that contains keys where each key has array of objects, for example: {reach: [], impression: {}}
// finalResult (Array) required: The value of final object that will hold all data to be displayed in chart
const handleAggregateLineChartMultipleValues = (
  newObjectToAggregate,
  finalResult,
  setData,
  sort,
) => {
  var found;
  if (Object.keys(finalResult)?.length !== 0) {
    for (const fianlResultObject in finalResult) {
      for (const newObjectToAggregateObject in newObjectToAggregate) {
        if (fianlResultObject === newObjectToAggregateObject) {
          // Merge Equal data Together
          if (finalResult[fianlResultObject]?.length > 0) {
            var newArrayToBeAdded = [];
            newObjectToAggregate[newObjectToAggregateObject]?.forEach(
              function (a) {
                finalResult[fianlResultObject]?.some(function (b) {
                  found = false;
                  if (a.name === b.name) {
                    b.value += a.value; // TODO we need to try setState here
                    found = true;
                    return true;
                  }
                });
                if (!found) {
                  newArrayToBeAdded.push(a);
                }
              },
            );
            finalResult[fianlResultObject].push(...newArrayToBeAdded);
          } else {
            finalResult[fianlResultObject].push(
              ...newObjectToAggregate[newObjectToAggregateObject],
            );
          }
        }
      }
    }
  } else {
    finalResult = newObjectToAggregate;
  }
  if (sort) {
    finalResult?.sort((a, b) => (a.name > b.name ? 1 : -1));
  }
  setData(finalResult);
};

// This function will be used to handle aggregate data for line chart with just 1 line like posts over time
// parameters:
// newArrayToAggregate (Array) required: The value of new array that should be added to array that will hold all data
// finalResult (Array) required: The value of final array that will hold all data to be displayed in chart
// Note: Each array should be array of objects where each object should have Name & Value
const handleAggregateLineChartOneValue = (
  newArrayToAggregate,
  finalResult,
  sort,
) => {
  var found;
  if (finalResult?.length > 0) {
    var newArrayToBeAdded = [];
    newArrayToAggregate?.forEach(function (a) {
      finalResult?.some(function (b) {
        found = false;
        if (a.name === b.name) {
          b.value += a.value;
          found = true;
          return true;
        }
      });
      if (!found) {
        newArrayToBeAdded.push(a);
      }
    });
    finalResult.push(...newArrayToBeAdded);
  } else {
    finalResult.push(...newArrayToAggregate);
  }
  if (sort) {
    finalResult?.sort((a, b) => (a.name > b.name ? 1 : -1));
  }
};
// This function will be used to handle aggregate data legends
// parameters:
// newArrayToAggregate (Array) required: The value of new array that should be added to array that will hold all data
// finalResult (Array) required: The value of final array that will hold all data to be displayed as legends
// ***************************** Aggregate Legends *************************************
// parameters:
// newArrayToAggregate (Array) required: The value of new array that should be added to array that will hold all data
// finalResult (Array) required: The value of final array that will hold all data to be displayed in chart
// Note: Each array should be array of objects where each object should have Name & Value
const handleAggregateLegends = (newArrayToAggregate, finalResult) => {
  var found;
  if (finalResult?.length === 0) {
    finalResult.push(...newArrayToAggregate);
  } else {
    var newArrayToBeAdded = [];
    newArrayToAggregate?.forEach(function (a) {
      finalResult?.some(function (b) {
        found = false;
        if (a.name === b.name) {
          b.value += a.value;
          found = true;
          return true;
        }
      });
      if (!found) {
        newArrayToBeAdded.push(a);
      }
    });
    finalResult.push(...newArrayToBeAdded);
  }
};

// incase of interactions Legends only
const handlePostsInteractionsLegends = (
  newArrayToAggregate,
  finalResult,
  data_sources_array,
) => {
  var found;
  if (finalResult?.length === 0) {
    newArrayToAggregate?.forEach(function (c) {
      if (
        (c.name === "replies" || c.name === "comments") &&
        (_.isEqual(
          data_sources_array?.sort(),
          ["TWITTER", "FACEBOOK", "INSTAGRAM"].sort(),
        ) ||
          _.isEqual(
            data_sources_array?.sort(),
            ["TWITTER", "INSTAGRAM"].sort(),
          ) ||
          _.isEqual(data_sources_array?.sort(), ["TWITTER", "FACEBOOK"].sort()))
      ) {
        c.name = "comments/replies";
      } else if (
        (c.name === "likes" || c.name === "reactions") &&
        (_.isEqual(
          data_sources_array?.sort(),
          ["TWITTER", "FACEBOOK", "INSTAGRAM"].sort(),
        ) ||
          _.isEqual(
            data_sources_array?.sort(),
            ["TWITTER", "INSTAGRAM"].sort(),
          ) ||
          _.isEqual(
            data_sources_array?.sort(),
            ["TWITTER", "FACEBOOK"].sort(),
          ) ||
          _.isEqual(
            data_sources_array.sort(),
            ["INSTAGRAM", "FACEBOOK"].sort(),
          ))
      ) {
        c.name = "likes/reactions";
      }
      finalResult.push(c);
    });
  } else {
    var newArrayToBeAdded = [];
    newArrayToAggregate?.forEach(function (a) {
      finalResult?.some(function (b) {
        found = false;
        if (
          (["replies", "comments"].includes(a.name) &&
            ["replies", "comments", "comments/replies"].includes(b.name)) ||
          (["likes", "reactions"].includes(a.name) &&
            ["reactions", "likes", "likes/reactions"].includes(b.name)) ||
          (a.name === b.name) === "retweets"
        ) {
          b.value += a.value;
          found = true;
          return true;
        }
      });
      if (!found) {
        newArrayToBeAdded.push(a);
      }
    });
    finalResult.push(...newArrayToBeAdded);
  }
};
// ************************************************************************************** //
// incase of interactions Legends only
const handleContentTypeInteractionsLegends = (
  newArrayToAggregate,
  finalResult,
  data_sources_array,
) => {
  var found;
  if (finalResult?.length === 0) {
    newArrayToAggregate?.forEach(function (c) {
      if (
        (c.name === "text" || c.name === "catalog") &&
        _.isEqual(data_sources_array?.sort(), ["TWITTER", "INSTAGRAM"].sort())
      ) {
        c.name = "text/catalog";
      } else if (
        (c.name === "post" || c.name === "catalog") &&
        _.isEqual(data_sources_array?.sort(), ["FACEBOOK", "INSTAGRAM"].sort())
      ) {
        c.name = "post/catalog";
      }
      finalResult.push(c);
    });
  } else {
    var newArrayToBeAdded = [];
    newArrayToAggregate?.forEach(function (a) {
      finalResult?.some(function (b) {
        found = false;
        if (
          (["text", "catalog"].includes(a.name) &&
            ["text", "catalog", "text/catalog"].includes(b.name)) ||
          (["posts", "catalog"].includes(a.name) &&
            ["posts", "catalog", "post/catalog"].includes(b.name)) ||
          a.name === b.name
        ) {
          b.value += a.value;
          found = true;
          return true;
        }
      });
      if (!found) {
        newArrayToBeAdded.push(a);
      }
    });
    finalResult.push(...newArrayToBeAdded);
  }
};
// ************************************************************************************** //
// ******************* Bar Chart Common Function *************************************** //
// This function will be used to handle aggregate data legends
// parameters:
// newArrayToAggregate (Array) required: The value of new array that should be added to array that will hold all data
// finalResult (Array) required: The value of final array that will hold all data to be displayed as bar chart
const handleAggregateBarCharts = (newArrayToAggregate, finalResult, sort) => {
  var found, bigger_array_mapper, lower_array_mapper;
  if (finalResult?.length > 0 && newArrayToAggregate?.length > 0) {
    var newArrayToBeAdded = [];
    newArrayToAggregate?.forEach(function (a) {
      finalResult?.some(function (b) {
        found = false;
        if (a.name === b.name) {
          if (a?.value?.length > b?.value?.length) {
            bigger_array_mapper = a;
            lower_array_mapper = b;
          } else {
            bigger_array_mapper = b;
            lower_array_mapper = a;
          }
          let sum = bigger_array_mapper?.value?.map(function (num, idx) {
            return (
              num +
              (lower_array_mapper.value[idx]
                ? lower_array_mapper.value[idx]
                : 0)
            );
          });

          b.value = sum;
          found = true;
          return true;
        }
      });
      if (!found) {
        newArrayToBeAdded.push(a);
      }
    });
    finalResult.push(...newArrayToBeAdded);
  } else {
    finalResult.push(...newArrayToAggregate);
  }
  if (sort) {
    finalResult?.sort((a, b) => (a.name > b.name ? 1 : -1));
  }
};
// ************************************************************************************** //
// ******************* PunchCard Common Function *************************************** //
const handleAggregatePunchCardCharts = (newArrayToAggregate, finalResult) => {
  if (finalResult?.length > 0) {
    newArrayToAggregate?.forEach(function (a, index) {
      if (finalResult?.[index]) finalResult[index][2] += a[2];
    });
  } else {
    finalResult.push(...newArrayToAggregate);
  }
};
// ************************************************************************************** //
const handleAggregateTwoBarChart = (newArrayToAggregate, result) => {
  let aggregateArray = [];
  newArrayToAggregate?.map((item) => {
    let isDuplicate = false;
    if (!result?.length) {
      result.push({ name: item?.name, value: [] });
    }
    result?.map((item2, index) => {
      if (item2.name === item?.name) {
        aggregateArray.push({
          name: item2?.name,
          value: [...item2?.value, item?.value],
        });
        isDuplicate = true;
      }
      if (result?.length - 1 == index && !isDuplicate) {
        aggregateArray.push({ name: item?.name, value: [item?.value] });
      }
    });
  });
  return aggregateArray;
};
// **************************************************************************************
export {
  handleAggregateLegends,
  handleAggregateLineChartOneValue,
  handleAggregateLineChartMultipleValues,
  handleAggregatePieChart,
  handleAggregateBarCharts,
  handleAggregatePunchCardCharts,
  handlePostsInteractionsLegends,
  handleContentTypeInteractionsLegends,
  handleAggregateTwoBarChart,
};
