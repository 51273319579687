import React, { useState } from "react";
import { Box, FormHelperText, TextField } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const NumberQuestion = (props) => {
  const { question, questionIndex, setErrorForm, previewMode } = props;

  const intl = useIntl();

  const [answer, setAnswer] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const maxNumber = question?.constraints?.max_number;
  const minNumber = question?.constraints?.min_number;

  const handleChangeAnswer = (e) => {
    const value = e.target.value;

    setAnswer(value);

    if (value?.length && minNumber?.checked && +value < +minNumber?.value) {
      setErrorMsg("min_number_field_survey_error");
      setErrorForm((prev) => ({ ...prev, [questionIndex + 1]: true }));
    } else if (
      value?.length &&
      maxNumber?.checked &&
      +value > +maxNumber?.value
    ) {
      setErrorMsg("max_number_field_survey_error");
      setErrorForm((prev) => ({ ...prev, [questionIndex + 1]: true }));
    } else {
      setErrorMsg("");
      setErrorForm((prev) => ({ ...prev, [questionIndex + 1]: false }));
    }
  };

  return (
    <Box className="survey-builder-question-number">
      <TextField
        id="survey-builder-question-number-input"
        className="survey-builder-question-number-input"
        placeholder={CheckValueLocale("type_your_number_here", "", {}, intl)}
        variant="standard"
        type="number"
        value={answer}
        onChange={previewMode ? handleChangeAnswer : null}
        error={errorMsg?.length}
      />
      {errorMsg?.length ? (
        <FormHelperText error={true}>
          {CheckValueLocale(
            errorMsg,
            "",
            { minNum: minNumber?.value, maxNum: maxNumber?.value },
            intl,
          )}
        </FormHelperText>
      ) : null}
    </Box>
  );
};

export default NumberQuestion;
