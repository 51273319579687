import { useIntl } from "react-intl";
import { Box, Typography } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import "./luciThinkingPage.scss";
import Services from "services/api/Services";

const LuciThinkingPage = () => {
  const intl = useIntl();

  return (
    <Box className="luci-thinking-wrapper">
      <Box>
        <img src={Services?.aiProductThinkingLogo} alt="luci icon" />
      </Box>
      <Box className="luci-thinking-conainer">
        <Typography component={"p"} className="luci-thinking-title">
          {CheckValueLocale("luci_thinking_title", "", {}, intl)}
        </Typography>
        <Typography component={"p"} className="luci-thinking-desc">
          {CheckValueLocale("luci_thinking_desc", "", {}, intl)}
        </Typography>
      </Box>
    </Box>
  );
};

export default LuciThinkingPage;
