import PunchCard from "components/echarts/punchCard";
import WidgetHeader from "./widgetHeader";
import { useState } from "react";
import _ from "lodash";
import { Box } from "@mui/material";
import FetchingData from "../fetchingData/fetchingData";

const PunchCardWidget = (props) => {
  const [selectedValue, setSelectedValue] = useState("clients");
  return (
    <div className="chart" id={props.title}>
      <WidgetHeader
        title={props.title}
        showGroupByClientAgent={props.showGroupByClientAgent}
        toggleSelectedValue={setSelectedValue}
        showDownloadIcon={props.showDownloadIcon}
        chartId={props.title}
        bigImg={props.bigImg}
        channelActivityPreLoaderDataSources={
          props.channelActivityPreLoaderDataSources
        }
        preLoaderTrackerDataSources={props?.preLoaderTrackerDataSources}
        channelActivityUpdated={props?.clientActivity}
        titleToolTip={props?.titleToolTip}
      />
      {_.isEqual(
        props?.channelActivityPreLoaderDataSources?.sort(),
        props?.preLoaderTrackerDataSources?.sort(),
      ) !== true ||
      !props?.channelActivityPreLoaderDataSources?.length ||
      !props?.preLoaderTrackerDataSources?.length ? (
        <Box>
          <FetchingData />
        </Box>
      ) : (
        <PunchCard
          clientActivity={props.clientActivity}
          agentActivity={props.agentActivity}
          selectedValue={selectedValue}
          minValueAgent={props.minValueAgent}
          maxValueAgent={props.maxValueAgent}
          minValueClient={props.minValueClient}
          maxValueClient={props.maxValueClient}
        />
      )}
    </div>
  );
};

export default PunchCardWidget;
