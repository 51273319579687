export const singlePostOverviewInitialState = {
  tweetData: {},
  singleTweetPreLoaderTrackerDataSources: [],
  monitorOverviewData: {},
  singleTweetOverviewDataPreLoader: [],
};

export const singlePostOverviewReducer = (state, action) => {
  switch (action.type) {
    case "SET_MONITOR_OVERVIEW_DATA":
      return {
        ...state,
        monitorOverviewData: action.payload.overview,
        singleTweetOverviewDataPreLoader: action.payload.monitor_id,
      };
    case "SET_TWEET_DATA":
      return {
        ...state,
        tweetData: action.payload.tweet,
        singleTweetPreLoaderTrackerDataSources: action.payload.monitor_id,
      };
    default:
      return state;
  }
};
