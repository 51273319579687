import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormGroup,
  Grid,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import KeywordTooltipChip from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/keywordTypeComponent/keywordTooltipChip";
import { handleInputMouseDown } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/kewordsSharedFunctions";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const XTopTrendsFormSection = ({
  countriesList,
  selectedCountries,
  setSelectedCountries,
  xTrendKeywords,
  selectedXTrendKeywords,
  handleXTrendKeywordsSubmit,
  handleXTrendKeywordsChange,
  handleDeleteXTrendKeyword,
  setKeywordsFocused,
  keywordsFocused,
  isCreateAlert,
}) => {
  const intl = useIntl();
  const numOfSelected = selectedCountries?.length;
  const hasValues = numOfSelected > 0;
  return (
    <>
      <Grid item xs={12}>
        <Box className="input-form-control">
          <InputLabel className="input-title-label-field">
            {CheckValueLocale("top_trends_region", "", {}, intl)}
            <Typography component="span" className="required">
              *
            </Typography>
          </InputLabel>
          <FormControl className="alert-x-top-trend-auto-complete">
            <Autocomplete
              multiple
              options={countriesList}
              size="small"
              value={selectedCountries}
              disableClearable
              onChange={(_, values) => setSelectedCountries(values)}
              disableCloseOnSelect
              disabled={!isCreateAlert}
              className={`alert-x-top-trend-auto-complete ${
                hasValues ? "x-top-trends-has-data" : ""
              }
            `}
              renderTags={() => null}
              getOptionLabel={(option) => option?.location_name}
              renderOption={(props, option, { selected }) => {
                const { key, ...optionProps } = props;
                return (
                  <li
                    key={key}
                    {...optionProps}
                    className="alert-x-top-trend-li"
                  >
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      className="alert-x-top-trend-checkbox"
                      checked={selected}
                    />
                    {option?.location_name === "World Wide" ? (
                      <Box>
                        <Typography className="alert-x-top-trend-world-wide-title">
                          {CheckValueLocale(
                            option?.location_name,
                            "",
                            {},
                            intl,
                          )}
                        </Typography>
                        <Typography className="alert-x-top-trend-world-wide-description">
                          {CheckValueLocale(
                            "world_wide_x_trend_description",
                            "",
                            {},
                            intl,
                          )}
                        </Typography>
                      </Box>
                    ) : (
                      CheckValueLocale(option?.location_name, "", {}, intl)
                    )}
                  </li>
                );
              }}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={
                    hasValues
                      ? CheckValueLocale(
                          "region_selected",
                          "",
                          {
                            count: numOfSelected,
                          },
                          intl,
                        )
                      : CheckValueLocale("select", "", {}, intl)
                  }
                />
              )}
              ListboxProps={{
                style: { height: "250px" },
              }}
            />
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className="input-form-control">
          <InputLabel>
            {CheckValueLocale("top_trends_keywords_", "", {}, intl)}
            <Typography component="span" className="required">
              *
            </Typography>
          </InputLabel>
          <FormGroup className="keywords-form-group">
            <TextField
              variant="outlined"
              fullWidth
              placeholder={CheckValueLocale(
                "top_trends_keywords_placeholder",
                "",
                {},
                intl,
              )}
              value={xTrendKeywords}
              onKeyDown={(event) => handleXTrendKeywordsSubmit(event)}
              onFocus={() => setKeywordsFocused(true)}
              onBlur={() => setKeywordsFocused(true)}
              onChange={(e) => handleXTrendKeywordsChange(e)}
              InputProps={{
                endAdornment: keywordsFocused ? (
                  <InputAdornment onMouseDown={handleInputMouseDown}>
                    <Button
                      className="add-btn-with-plus alert-add-btn-keyword"
                      onClick={(event) => {
                        handleXTrendKeywordsSubmit(event, "btn");
                      }}
                      value={xTrendKeywords}
                      id="alert-form-add-keywords-btn"
                    >
                      {CheckValueLocale("add_plus", "", {}, intl)}
                    </Button>
                  </InputAdornment>
                ) : null,
              }}
              disabled={!isCreateAlert}
            />
          </FormGroup>
          <Box className="keyword-spam-wrapper">
            {selectedXTrendKeywords?.map((keyword, i) => {
              return (
                <KeywordTooltipChip
                  onDelete={() => handleDeleteXTrendKeyword(i)}
                  index={i}
                  labels={keyword}
                  disableChip={!isCreateAlert}
                />
              );
            })}
          </Box>
        </Box>
      </Grid>
    </>
  );
};
