import * as xlsx from "xlsx";
import * as fileSaver from "file-saver";

//dropdowns inside export popup modal
export const getDropDownTypes = (
  hidePPTExport,
  hideExcelExport,
  hideEnglishLangExport,
  hideArabicLangExport,
) => [
  {
    label: "type",
    options: [
      { id: "ppt", name: "ppt", hideOption: hidePPTExport },
      { id: "excel", name: "excel", hideOption: hideExcelExport },
    ],
  },
  {
    label: "language",
    options: [
      { id: "english", name: "en", hideOption: hideEnglishLangExport },
      {
        id: "arabic",
        name: "ar",
        hideOption: hideArabicLangExport,
      },
    ],
  },
];

export const handleExcelExport = (
  fileDetail,
  fileName,
  logExcelExportProcess,
) => {
  const fileType = "xlsx"; //XLSX file type

  fileDetail?.map((item, index) => {
    item["json"] = xlsx?.utils?.json_to_sheet(item[0]?.data); // loop on data that come from BE to convert it as JSON [ XLSX ]
  });

  const sheetsDataNames = {
    Sheets: {},
    SheetNames: [],
  };

  fileDetail?.map((item, index) => {
    return (
      (sheetsDataNames.Sheets[item[0]?.name] = item?.json), // sheet JSON Data push to every item
      sheetsDataNames.SheetNames?.push(item[0]?.name) // sheet Name
    );
  });

  const sheetData = { ...sheetsDataNames };
  const excelBuffer = xlsx?.write(sheetData, {
    bookType: "xlsx",
    type: "array",
  });

  const data = new Blob([excelBuffer], { type: fileType });
  fileSaver?.saveAs(data, `${fileName}.${fileType}`);

  if (logExcelExportProcess) {
    logExcelExportProcess(); // logging to BE
  }
};
