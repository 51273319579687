import { useFormik } from "formik";
import _ from "lodash";
import { useEffect, useState } from "react";
import { userFormSchema } from "../../../userManagementValidation/addUserValidation";
import { getResetPhoneNumber, getSortedArray } from "utils/helpers";
import { typeFormatMonitor } from "./inviteUserAdapter";
import { useGetAccount } from "../../../hooks/useGetAccount";
import { useGetMonitorsOrChannels } from "../../../hooks/useGetMonitors";
import { useEngagPermissions } from "../../../hooks/useEngagPermissions";
import {
  initialAccEngagId,
  initialAccTableData,
  initialAccTableDetailsData,
  initialAccTableSelectedData,
  initialAdvancedTabsData,
  initialInfoTabsData,
  initialStepTwoData,
  initialTabsErrors,
  initialTeamsData,
  userFormInitialValues,
} from "./initialValuesAddNewUser";
import { useQueryClient } from "@tanstack/react-query";

const useAddNewUserOrEdit = (userEditID) => {
  const [steps, setSteps] = useState(["user_det", "user_permissions"]);
  const [activeStep, setActiveStep] = useState(0);
  const [smId, setSmId] = useState(false);
  const [cxmId, setCxmId] = useState(false);
  const [cxmData, setCxmData] = useState({});
  const [smData, setSmData] = useState({});
  const [accData, setAccData] = useState({});
  const [callApiDone, setCallApiDone] = useState(false);
  const [enabledCallApi, setEnabledCallApi] = useState(false);
  const [nextLoading, setNextLoading] = useState(false);
  const limitNumRecord = 6;
  const [stepTwoData, setStepTwoData] = useState(
    _.cloneDeep(initialStepTwoData),
  );
  const [accTableDetailsData, setAccTableDetailsData] = useState(
    _.cloneDeep(initialAccTableDetailsData),
  );
  const [accTableData, setAccTableData] = useState(
    _.cloneDeep(initialAccTableData),
  );
  const [accTableSelectedData, setAccTableSelectedData] = useState(
    _.cloneDeep(initialAccTableSelectedData),
  );
  const [tableCols, setTableCols] = useState({});
  const [teamsData, setTeamsData] = useState(_.cloneDeep(initialTeamsData));
  const [advancedTabsData, setAdvancedTabsData] = useState(
    _.cloneDeep(initialAdvancedTabsData),
  );
  const [infoTabsData, setInfoTabsData] = useState(
    _.cloneDeep(initialInfoTabsData),
  );
  const [tabsErrors, setTabsErrors] = useState(_.cloneDeep(initialTabsErrors));

  const [accEngagId, setAccEngagId] = useState(_.cloneDeep(initialAccEngagId));

  const formik = useFormik({
    initialValues: userFormInitialValues,
    validationSchema: userFormSchema,
  });

  //call APIs
  const { data: dataEngagPermissions, isFetched: isFetchedEngagPermissions } =
    useEngagPermissions(enabledCallApi);
  const {
    data: dataPageAccount,
    isPending: isPendingAccount,
    isFetched: isFetchedAccount,
  } = useGetAccount(
    userEditID,
    limitNumRecord,
    accTableDetailsData?.account_access_page,
    enabledCallApi,
  );
  const {
    data: dataPageMonitors,
    isPending: isPendingMonitors,
    isFetched: isFetchedMonitors,
  } = useGetMonitorsOrChannels(
    userEditID,
    smId,
    limitNumRecord,
    accTableDetailsData?.monitors_access_page,
    enabledCallApi,
  );
  const {
    data: dataPageChannels,
    isPending: isPendingChannels,
    isFetched: isFetchedChannels,
  } = useGetMonitorsOrChannels(
    userEditID,
    cxmId,
    limitNumRecord,
    accTableDetailsData?.channels_access_page,
    enabledCallApi,
  );

  useEffect(() => {
    // handle Data and stop loading and redirct to step 3
    if (!callApiDone) {
      const socialId = !smId || isFetchedMonitors;
      const omniChannelId = !cxmId || isFetchedChannels;
      setCallApiDone(
        isFetchedEngagPermissions &&
          isFetchedAccount &&
          socialId &&
          omniChannelId,
      );
    }
  }, [
    isFetchedEngagPermissions,
    isFetchedAccount,
    isFetchedMonitors,
    isFetchedChannels,
  ]);

  useEffect(() => {
    if (!stepTwoData?.engagement || stepTwoData?.engagement == "no_acc") {
      setSteps(["user_det", "user_permissions"]);
    } else {
      setSteps(["user_det", "user_permissions", "Engag_perm"]);
    }
  }, [stepTwoData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  // first call
  const getTablesData = async () => {
    setNextLoading(true);
    setEnabledCallApi(true);
  };

  // handle Data and stop loading and redirct to step 3
  useEffect(() => {
    if (callApiDone) {
      let objColamsData = {};
      let objData = {
        ...accTableData,
        account_access: accData?.account_access?.length
          ? [...accData?.account_access]
          : [],
      };
      if (smId) {
        objData = {
          ...objData,
          monitors_access: smData?.monitors_access?.length
            ? [...smData?.monitors_access]
            : [],
        };
      }
      if (cxmId) {
        objData = {
          ...objData,
          channels_access: cxmData?.channels_access?.length
            ? [...cxmData?.channels_access]
            : [],
        };
      }
      if (tableCols?.engagement_features) {
        if (tableCols?.engagement_features?.engagement_access) {
          objData.engagement_features = ["engag_acc_title"];
          let dataArr =
            tableCols?.engagement_features?.engagement_access?.permissions?.map(
              (item) => item?.permission_name,
            );
          objColamsData.engagement_features0 = getSortedArray(
            [
              "FULL_ACCESS",
              "INBOX",
              "PUBLISH",
              "ENGAGEMENT_ANALYTICS",
              "SL_ENGAGEMENT",
              "OC_ENGAGEMENT",
            ],
            dataArr,
          );
        }
        if (tableCols?.engagement_features?.engagement_tabs) {
          objData.engagement_features = [
            ...objData?.engagement_features,
            "engag_tabs",
          ];
          let dataArr =
            tableCols?.engagement_features?.engagement_tabs?.permissions?.map(
              (item) => item?.permission_name,
            );
          objColamsData.engagement_features1 = getSortedArray(
            ["FULL_ACCESS", "ALL_TAB", "UNASSIGNED_TAB"],
            dataArr,
          );
        }
        if (tableCols?.engagement_features?.saved_replies) {
          objData.engagement_features = [
            ...objData?.engagement_features,
            "saved_reps_mang",
          ];
          let dataArr =
            tableCols?.engagement_features?.saved_replies?.permissions?.map(
              (item) => item?.permission_name,
            );
          objColamsData.engagement_features2 = getSortedArray(
            [
              "FULL_ACCESS",
              "CREATE_SAVED_REPLY",
              "EDIT_SAVED_REPLY",
              "DELETE_SAVED_REPLY",
            ],
            dataArr,
          );
        }
        if (tableCols?.engagement_features?.saved_replies_collections) {
          objData.engagement_features = [
            ...objData?.engagement_features,
            "saved_replies_collections",
          ];
          let dataArr =
            tableCols?.engagement_features?.saved_replies_collections?.permissions?.map(
              (item) => item?.permission_name,
            );
          objColamsData.engagement_features3 = getSortedArray(
            [
              "FULL_ACCESS",
              "CREATE_COLLECTION",
              "EDIT_COLLECTION",
              "DELETE_COLLECTION",
            ],
            dataArr,
          );
        }
        if (tableCols?.engagement_features?.export_engagements) {
          objData.engagement_features = [
            ...objData?.engagement_features,
            "export_engagements",
          ];
          let dataArr =
            tableCols?.engagement_features?.export_engagements?.permissions?.map(
              (item) => item?.permission_name,
            );
          objColamsData.engagement_features4 = getSortedArray(
            ["EXPORT_ENGAGEMENTS"],
            dataArr,
          );
        }
        if (tableCols?.engagement_features?.engagement_tag) {
          objData.engagement_features = [
            ...objData?.engagement_features,
            "engagement_tag",
          ];
          let dataArr =
            tableCols?.engagement_features?.engagement_tag?.permissions?.map(
              (item) => item?.permission_name,
            );
          objColamsData.engagement_features5 = getSortedArray(
            ["FULL_ACCESS", "CREATE_TAG", "EDIT_TAG", "DELETE_DISABLE_TAG"],
            dataArr,
          );
        }
      } else {
        delete objData?.engagement_features;
      }

      if (tableCols?.channels_access) {
        objColamsData.channels_access = tableCols?.channels_access?.map(
          (item) => item?.permission_name,
        );
      } else {
        delete objData?.channels_access;
      }
      if (tableCols?.monitors_access) {
        objColamsData.monitors_access = tableCols?.monitors_access?.map(
          (item) => item?.permission_name,
        );
      } else {
        delete objData?.monitors_access;
      }
      if (tableCols?.account_access) {
        let dataArr = tableCols?.account_access?.map(
          (item) => item?.permission_name,
        );
        objColamsData.account_access = getSortedArray(
          ["FULL_ACCESS", "REPLY", "PUBLISH"],
          dataArr,
        );
      } else {
        delete objData?.account_access;
      }
      setTableCols({ ...objColamsData });
      setAccTableData({
        ...objData,
      });
      setAccTableDetailsData({
        ...accTableDetailsData,
        ...smData,
        ...accData,
        ...cxmData,
      });
      setNextLoading(false);
      setActiveStep(2);
    }
  }, [callApiDone]);

  const queryClient = useQueryClient();
  useEffect(() => {
    // Clear cache for the query before fetching
    queryClient.removeQueries(["accessMonitorsOrChannels", "accessAccount"]);
    let activeProducts = JSON.parse(window?.localStorage?.activeProducts);
    let smID = activeProducts?.filter(
      (item) => item?.name == "SM" && item?.active,
    )?.[0]?.id;

    let cxmID = activeProducts?.filter(
      (item) => item?.name == "CXM" && item?.active,
    )?.[0]?.id;

    if (smID) {
      setSmId(smID);
    } else {
      delete accTableData?.monitors_access;
      setAccTableData({ ...accTableData });
    }
    if (cxmID) {
      setCxmId(cxmID);
    } else {
      delete accTableData?.channels_access;
      setAccTableData({ ...accTableData });
    }
  }, []);

  const handleDataAccount = (result) => {
    if (result?.data?.data?.length) {
      let data = [];
      result?.data?.data?.map((item) => {
        if (item?.attributes?.account_type == "TWITTER") {
          data.push({
            id: `TWITTER-${item?.attributes?.id}`,
            data_source: item?.attributes?.account_type,
            profileImg: item?.attributes?.image_url,
            name: item?.attributes?.name,
            handleName: item?.attributes?.user_name
              ? item?.attributes?.user_name?.includes("@")
                ? item?.attributes?.user_name
                : "@" + item?.attributes?.user_name
              : null,
          });
        }
      });

      result?.data?.included?.map((item) => {
        const isWhatsapp = item?.type == "whatsapp_account";
        data.push({
          id: `${isWhatsapp ? "WHATSAPP" : item?.type == "facebook_page" ? "FACEBOOK" : "INSTAGRAM"}-${
            item?.attributes?.id
          }`,
          data_source: isWhatsapp
            ? "WHATSAPP"
            : item?.type == "facebook_page"
              ? "FACEBOOK"
              : "INSTAGRAM",
          profileImg: item?.attributes?.image_url,
          name: isWhatsapp
            ? getResetPhoneNumber(item?.attributes?.phone_number)
            : item?.attributes?.name,
          handleName: isWhatsapp
            ? result?.data?.data?.find?.(
                (itemData) =>
                  +itemData?.id === +item?.attributes?.account_info_id,
              )?.attributes?.name
            : item?.attributes?.user_name
              ? item?.attributes?.user_name?.includes("@")
                ? item?.attributes?.user_name
                : "@" + item?.attributes?.user_name
              : null,
        });
      });
      setAccTableData({
        ...accTableData,
        account_access: [...accTableData?.account_access, ...data],
      });
      setAccTableDetailsData({
        ...accTableDetailsData,
        account_access: [...accTableDetailsData?.account_access, ...data],
        account_access_counter: result?.data?.pagination?.next
          ? result?.data?.pagination?.count +
            [...accTableDetailsData?.account_access, ...data]?.length
          : [...accTableDetailsData?.account_access, ...data]?.length,
        account_access_pages: result?.data?.pagination?.pages,
        account_access_loading: false,
      });
      setAccData({
        account_access: [...data],
        account_access_counter: result?.data?.pagination?.next
          ? result?.data?.pagination?.count +
            [...accTableDetailsData?.account_access, ...data]?.length
          : [...accTableDetailsData?.account_access, ...data]?.length,
        account_access_pages: result?.data?.pagination?.pages,
        account_access_loading: false,
      });
    }
  };

  const handleDataMonitors = (result) => {
    if (result?.data?.data?.length) {
      let data = [];
      result?.data?.data?.map((item) =>
        data.push({
          id: item?.attributes?.id,
          data_source: item?.attributes?.data_sources || [],
          type: typeFormatMonitor(item?.attributes?.monitor_type_name),
          name: item?.attributes?.account_name,
        }),
      );
      setAccTableData({
        ...accTableData,
        monitors_access: [...accTableData?.monitors_access, ...data],
      });
      setAccTableDetailsData({
        ...accTableDetailsData,
        monitors_access: [...accTableDetailsData?.monitors_access, ...data],
        monitors_access_counter: result?.data?.pagination?.count,
        monitors_access_pages: result?.data?.pagination?.pages,
        monitors_access_loading: false,
      });
      setSmData({
        monitors_access: [...data],
        monitors_access_counter: result?.data?.pagination?.count,
        monitors_access_pages: result?.data?.pagination?.pages,
        monitors_access_loading: false,
      });
    }
  };

  const handleDataChannels = (result) => {
    if (result?.data?.data?.length) {
      let data = [];
      result?.data?.data?.map((item) =>
        data.push({
          id: item?.attributes?.id,
          data_source: item?.attributes?.data_sources || [],
          type: item?.attributes?.is_dm ? "dm_cxm" : "public_cxm",
          name: item?.attributes?.account_name,
        }),
      );
      setAccTableData({
        ...accTableData,
        channels_access: [...accTableData?.channels_access, ...data],
      });
      setAccTableDetailsData({
        ...accTableDetailsData,
        channels_access: [...accTableDetailsData?.channels_access, ...data],
        channels_access_counter: result?.data?.pagination?.count,
        channels_access_pages: result?.data?.pagination?.pages,
        channels_access_loading: false,
      });
      setCxmData({
        channels_access: [...data],
        channels_access_counter: result?.data?.pagination?.count,
        channels_access_pages: result?.data?.pagination?.pages,
        channels_access_loading: false,
      });
    }
  };

  const handleEngagPermissions = async (result) => {
    if (result?.data) {
      setTableCols({ ...result?.data });
      setAccEngagId({
        engag_acc_title:
          result?.data?.engagement_features?.engagement_access?.id,
        engag_tabs: result?.data?.engagement_features?.engagement_tabs?.id,
        saved_reps_mang: result?.data?.engagement_features?.saved_replies?.id,
        saved_replies_collections:
          result?.data?.engagement_features?.saved_replies_collections?.id,
        export_engagements:
          result?.data?.engagement_features?.export_engagements?.id,
        engagement_tag: result?.data?.engagement_features?.engagement_tag?.id,
      });
    }
  };

  useEffect(() => {
    if (dataEngagPermissions) {
      handleEngagPermissions(dataEngagPermissions);
    }
  }, [dataEngagPermissions]);
  useEffect(() => {
    if (dataPageMonitors) {
      handleDataMonitors(dataPageMonitors);
    }
  }, [dataPageMonitors]);
  useEffect(() => {
    if (dataPageChannels) {
      handleDataChannels(dataPageChannels);
    }
  }, [dataPageChannels]);
  useEffect(() => {
    if (dataPageAccount) {
      handleDataAccount(dataPageAccount);
    }
  }, [dataPageAccount]);

  // handle pagination
  useEffect(() => {
    setAccTableDetailsData((prev) => ({
      ...prev,
      account_access_loading: isPendingAccount,
      monitors_access_loading: isPendingMonitors,
      channels_access_loading: isPendingChannels,
    }));
  }, [isPendingAccount, isPendingMonitors, isPendingChannels]);

  return {
    formik,
    stepTwoData,
    setStepTwoData,
    steps,
    activeStep,
    setActiveStep,
    accTableData,
    setAccTableData,
    accTableSelectedData,
    setAccTableSelectedData,
    accTableDetailsData,
    setAccTableDetailsData,
    tableCols,
    advancedTabsData,
    setAdvancedTabsData,
    infoTabsData,
    setInfoTabsData,
    tabsErrors,
    setTabsErrors,
    teamsData,
    setTeamsData,
    callApiDone,
    getTablesData,
    nextLoading,
    setNextLoading,
    accEngagId,
  };
};

export default useAddNewUserOrEdit;
