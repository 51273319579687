import moment from "moment";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  Box,
  Button,
  FormHelperText,
  InputLabel,
  TextField,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import { CheckValueLocale } from "utils/helpers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDateTimePicker } from "@mui/x-date-pickers/StaticDateTimePicker";
import PopupModal from "components/popupModal";
import dayjs from "dayjs";

const CreateReportDateTimeSelect = ({
  open,
  setOpen,
  date,
  handleChangeDate,
  helperText = "",
  error = false,
}) => {
  const intl = useIntl();

  const [tempDate, setTempDate] = useState(null);

  useEffect(() => {
    setTempDate(date);
  }, [open, date]);

  const handleOpenModal = () => setOpen(true);

  const handleCloseModal = () => {
    setOpen(false);
    setTempDate(date);
  };

  const handleSaveDate = async () => {
    handleChangeDate(tempDate);
    await setOpen(false);
  };

  const handleCancelChanges = () => {
    setOpen(false);
    setTempDate(date);
  };

  return (
    <Box className="report-date-select-input-wrapper">
      <InputLabel className="report-date-select-input-label">
        {CheckValueLocale("report_start_date", "", {}, intl)}
      </InputLabel>
      <Button id="report-date-select-input" onClick={handleOpenModal}>
        {!!date && date?.$d
          ? dayjs(date?.$d)?.format("DD/MM/YYYY hh:mm A")
          : ""}
        <FontAwesomeIcon icon={faCalendar} />
      </Button>

      {helperText?.length ? (
        <FormHelperText error={error}>
          {CheckValueLocale(helperText, "", {}, intl)}
        </FormHelperText>
      ) : null}

      {open ? (
        <PopupModal
          open={open}
          close={handleCloseModal}
          classeName="report-date-select-dialog-wrapper"
          body={
            <Box className="report-date-select-dialog-body">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDateTimePicker
                  className="report-date-select-dialog-body-content"
                  disablePast
                  renderInput={(params) => <TextField {...params} />}
                  value={tempDate}
                  onChange={(date) => setTempDate(date)}
                />
              </LocalizationProvider>
              <Box className="report-date-select-dialog-action-btns-wrapper">
                <Button onClick={handleCancelChanges}>CANCEL</Button>
                <Button onClick={handleSaveDate}>OK</Button>
              </Box>
            </Box>
          }
          minWidth="350px"
        />
      ) : null}
    </Box>
  );
};

export default CreateReportDateTimeSelect;
