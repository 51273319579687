import BigTableWidget from "components/widgets/bigTableWidget/bigTableWidget";
import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import _ from "lodash";
import { renderRemainingTime } from "../sharedFunctions/datesUtils";
import {
  getTableHeader,
  RenderAgentColumn,
  RenderNormalColumn,
  RenderSLAsColumn,
  RenderTeamColumn,
} from "./helpersComponents";
import { commasAfterDigit } from "utils/helpers";

// helper function for the table

const tablesHeaders = [
  {
    name: "agent_performance_tut",
  },
  {
    name: "agent_performance_frt",
  },
  {
    name: "agent_performance_nrt",
  },
  {
    name: "agent_performance_ttc",
  },
  {
    name: "agent_performance_hits",
    tooltip: "agent_performance_hits_desc",
  },
  {
    name: "agent_performance_misses",
    tooltip: "agent_performance_misses_desc",
  },
  {
    name: "agent_performance_compliance_rate",
  },
];

const groupByOptions = [
  "agent_performance_grouped_agents",
  "agent_performance_grouped_teams",
  "agent_performance_grouped_slas",
  "agent_performance_grouped_routings",
];

const getFirstColumn = (selectedGroupBy, rowObjectData) => {
  switch (selectedGroupBy) {
    case "agents":
      return <RenderAgentColumn agent={rowObjectData} />;
    case "routings":
      return <RenderNormalColumn value={rowObjectData?.name} />;
    case "slas":
      return <RenderSLAsColumn sla={rowObjectData} />;
    case "teams":
      return <RenderTeamColumn team={rowObjectData} />;
    default:
      return null;
  }
};

const createTableRow = (rowData, selectedGroupBy, data = {}, intl) => {
  const rowObjectData = data?.[selectedGroupBy]?.find((item) => {
    const keyId = selectedGroupBy?.slice(0, -1) + "_id";
    return +item?.id === +rowData?.[keyId];
  });

  // if the rowObjectData is not found we will return null
  if (!rowObjectData) {
    return null;
  }

  const rows = [getFirstColumn(selectedGroupBy, rowObjectData)];
  const { tut, nrt, frt, ttc, hits_count, misses_count, compliance_rate } =
    rowData;

  let agentsArray = [];
  // if the selected group by is agents we will not render the TUT column
  if (selectedGroupBy !== "agents") {
    agentsArray = [renderRemainingTime(tut, intl)];
  }

  const values = [
    ...agentsArray,
    renderRemainingTime(frt, intl),
    renderRemainingTime(nrt, intl),
    renderRemainingTime(ttc, intl),
    commasAfterDigit(hits_count),
    commasAfterDigit(misses_count),
    compliance_rate,
  ];
  values?.forEach((value) => {
    rows.push(<RenderNormalColumn value={value} />);
  });
  return rows;
};

const rowsPerPage = 5;

export const AgentPerformanceTable = ({
  agentPerformanceData,
  teams,
  slas,
  routings,
  agents,
  isLoading,
  ...props
}) => {
  const intl = useIntl();

  const [selectedGroupBy, setSelectedGroupBy] = useState(groupByOptions[0]);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);

  const paginatedTableData = useMemo(() => {
    const start = page * rowsPerPage;
    const end = start + rowsPerPage;
    return tableData?.slice?.(start, end) || [];
  }, [tableData, page]);

  const tableHeaders = useMemo(() => {
    const firstColumn = {
      name: `${selectedGroupBy}_column`,
    };

    const newTablesHeaders = tablesHeaders.filter((header) => {
      // remove the TUT column if the selected group by is agents
      if (
        header?.name === "agent_performance_tut" &&
        selectedGroupBy === groupByOptions[0]
      ) {
        return false;
      }
      return true;
    });
    return getTableHeader([firstColumn, ...newTablesHeaders], intl);
  }, [selectedGroupBy, intl?.locale]);

  const handleGroupByChange = (groupBy) => {
    setPage(0);
    setSelectedGroupBy(groupBy);
  };

  const handleTablePagination = (page) => {
    setPage(page);
  };

  useEffect(() => {
    const stillFetchingData =
      _.isEqual(
        props?.statisticsPreLoaderDataSources?.sort(),
        props?.preLoaderTrackerDataSources?.sort(),
      ) !== true;

    if (isLoading || stillFetchingData) {
      return;
    }
    const activeGroupByKey = selectedGroupBy?.replace(
      "agent_performance_grouped_",
      "",
    );

    const newTableData = agentPerformanceData?.[activeGroupByKey]
      ?.map((data) => {
        return createTableRow(
          data,
          activeGroupByKey,
          {
            agents,
            teams,
            slas,
            routings,
          },
          intl,
        );
      })
      ?.filter(Boolean);
    setPage(0);
    setTableData(newTableData);
  }, [
    selectedGroupBy,
    intl?.locale,
    isLoading,
    props?.statisticsPreLoaderDataSources,
    props?.preLoaderTrackerDataSources,
  ]);

  return (
    <BigTableWidget
      chartId="average-SLAs-performance"
      title="agent_performance_title"
      titleToolTip="agent_performance_desc"
      handleTablePagination={handleTablePagination}
      stickyColumn={false}
      selectedGroupBy={selectedGroupBy}
      groupByOptions={groupByOptions}
      onGroupByChange={handleGroupByChange}
      tableHeaders={tableHeaders}
      tableData={paginatedTableData}
      isLoading={isLoading}
      count={tableData?.length}
      page={page}
      rowsPerPage={rowsPerPage}
      alertMessage={
        // show alert message if the selected group by is agents
        selectedGroupBy === groupByOptions[0]
          ? "agent_performance_agent_alert"
          : null
      }
      {...props}
    />
  );
};
