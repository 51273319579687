import { Box, Typography } from "@mui/material";
import moment from "moment";
import { useIntl } from "react-intl";
import { calculateUnixTimeZone, formatTimeZone } from "utils/helpers";

const TeamCreatedAtCell = ({ createdAt }) => {
  const intl = useIntl();
  const companyTimeZone = parseInt(localStorage.getItem("companyInfo"));
  const dataInUnix = moment(createdAt).unix();
  const calculatedTime = calculateUnixTimeZone(dataInUnix, {});
  const { completeFormat } = formatTimeZone(
    calculatedTime,
    intl,
    {},
    companyTimeZone,
  );
  const [datePart, timeWithGMT] = completeFormat.split(", ");
  return (
    <Box>
      <Typography as="span" className="teams-table-created-at">
        {datePart}
      </Typography>
      <br />
      <Typography as="span" className="teams-table-created-at-time">
        {timeWithGMT}
      </Typography>
    </Box>
  );
};

export default TeamCreatedAtCell;
