// style
import "../Media.scss";
// Sub Component
import WidgetHeader from "components/widgets/widgetHeader";
import { isEmptyArray } from "utils/helpers";
import FetchingData from "components/fetchingData/fetchingData";
import _ from "lodash";
import { Box } from "@mui/material";
import NoDataFound from "components/no-Data/noDataFound";
import LinkedInMediaItem from "./linkedInMediaItem";

const LinkedInMedia = (props) => {
  let isEqualDataSources = _.isEqual(
    props?.mediaTwPreLoaderDataSources?.sort(),
    props?.preLoaderTrackerDataSources?.sort(),
  );

  let isEmptyDataArray = isEmptyArray(props?.data);

  return (
    <div className="chart_media">
      <WidgetHeader
        title={props?.title}
        showDownloadIcon={props?.showDownloadIcon}
        iconAfterTitle={props?.iconAfterTitle}
        titleToolTip={props?.titleToolTip}
        isCustomDashboard={props?.isCustomDashboard} // To identify that widget will be rendered in Custom Dashboard
        isControlWidget={props?.isControlWidget} // To identify that widget will be rendered in Custom Dashboard control widget sidebar
        customDashboardData={props?.customDashboardData} // The needed data in widget component for case of Custom Dashboard only
      />
      {isEqualDataSources !== true ||
      !props?.mediaTwPreLoaderDataSources?.length ||
      !props?.preLoaderTrackerDataSources?.length ? (
        <Box>
          <FetchingData />
        </Box>
      ) : isEmptyDataArray ? (
        <NoDataFound />
      ) : (
        <div className="top-linkedin-media">
          {props?.data?.map((media, index) => (
            <LinkedInMediaItem
              key={index}
              media={media}
              isLinkedInImage={props.isLinkedInImage}
              isLinkedInVideo={props.isLinkedInVideo}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default LinkedInMedia;
