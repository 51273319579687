import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
  Select,
  MenuItem,
  Checkbox,
  FormHelperText,
  InputAdornment,
  IconButton,
} from "@mui/material";
import UploadFields from "./uploadFields";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import AutoCompleteFields from "./autoCompleteFields";
import ClearIcon from "@mui/icons-material/Clear";
import { useState } from "react";

const AiAgentsFields = (props) => {
  const {
    switchLabel,
    label,
    labelHint,
    required,
    type,
    radioExtraChildrenChip,
    radioExtraChildrenChipIndexes,
    options,
    horizontalOptions,
    selectOptions,
    className,
    multiple,
    value,
    helperText,
    labelClassName,
    luciSelect,
    clearable,
    ...reset
  } = props;
  const intl = useIntl();
  const [selectHovered, setSelectHovered] = useState(false);

  const MenuProps = {
    style: { zIndex: 9999 },
    PaperProps: { style: { width: 250, maxHeight: 220 } },
  };

  const isSelectClearOptionShown =
    selectOptions?.length > 0 && clearable && value?.length > 0;

  return (
    <Box className={`ai-agents-form-group ${className}`}>
      {label ? (
        <Typography
          component="span"
          className={`ai-agents-form-label ${labelClassName}`}
        >
          {label}
          {labelHint ? (
            <Box component="span" className="ai-agents-form-hint-label">
              {labelHint}
            </Box>
          ) : null}
          {required ? (
            <Box component="span" className="red-star-req">
              {` *`}
            </Box>
          ) : null}
        </Typography>
      ) : null}
      {type === "text" ? (
        <TextField
          variant="outlined"
          size="small"
          className="ai-agents-form-input"
          value={value}
          helperText={helperText}
          {...reset}
        />
      ) : type === "textarea" ? (
        <TextField
          multiline
          variant="outlined"
          className="ai-agents-form-textarea"
          value={value}
          helperText={helperText}
          {...reset}
        />
      ) : type === "radio" ? (
        <>
          <RadioGroup
            className={`ai-agents-form-radio ${horizontalOptions ? "ai-agents-form-radio-horizontal" : ""}`}
            value={value}
            {...reset}
          >
            {options?.map((option) => (
              <FormControlLabel
                value={option?.value}
                control={<Radio />}
                disabled={option?.disabled}
                label={
                  <>
                    {option?.name}
                    {required ? (
                      <Box component="span" className="red-star-req">
                        {` *`}
                      </Box>
                    ) : null}
                    {option?.chip}
                  </>
                }
              />
            ))}
          </RadioGroup>
          {helperText ? (
            <FormHelperText className="ai-agents-form-helper-text">
              {helperText}
            </FormHelperText>
          ) : null}
        </>
      ) : type === "switch" ? (
        <>
          <FormControlLabel
            className="ai-agents-form-switch"
            control={<Switch color="primary" {...reset} />}
            label={switchLabel}
          />
          {helperText ? (
            <FormHelperText className="ai-agents-form-helper-text">
              {helperText}
            </FormHelperText>
          ) : null}
        </>
      ) : type === "upload" ? (
        <UploadFields {...reset} />
      ) : type === "select" ? (
        <>
          <Select
            size="small"
            displayEmpty
            className="ai-agents-form-select"
            MenuProps={MenuProps}
            onMouseEnter={() => setSelectHovered(true)}
            onMouseLeave={() => setSelectHovered(false)}
            onClose={() => setSelectHovered(false)}
            multiple={multiple}
            value={value}
            endAdornment={
              isSelectClearOptionShown && selectHovered ? (
                <InputAdornment
                  className="ai-agents-select-clear-end-adornment"
                  position="end"
                >
                  <IconButton
                    className="ai-agents-select-clear-icon"
                    onClick={() =>
                      reset.onChange({
                        target: { value: multiple ? [] : "", name: reset.name },
                      })
                    }
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ) : null
            }
            renderValue={
              multiple
                ? (selected) =>
                    selected?.length ? (
                      selected?.map((item, i) =>
                        i == selected?.length - 1
                          ? CheckValueLocale(
                              luciSelect ? `luci_${item}` : item,
                              "",
                              {},
                              intl,
                            )
                          : CheckValueLocale(
                              luciSelect ? `luci_${item}` : item,
                              "",
                              {},
                              intl,
                            ) + " , ",
                      )
                    ) : (
                      <span className="ai-agents-select">
                        {CheckValueLocale("select", "", {}, intl)}
                      </span>
                    )
                : null
            }
            {...reset}
          >
            <MenuItem
              disabled
              value=""
              className="ai-agents-select-placehoder ai-agents-select-item"
            >
              <span className="ai-agents-select">
                {CheckValueLocale("select", "", {}, intl)}
              </span>
            </MenuItem>
            {selectOptions?.map((item) => {
              return (
                <MenuItem
                  key={item?.id}
                  value={item?.id}
                  className="ai-agents-select-item"
                >
                  {multiple ? (
                    <Checkbox
                      checked={value?.indexOf(item?.id) > -1}
                      className="ai-agents-select-checkbox"
                    />
                  ) : (
                    ""
                  )}
                  {item?.name}
                </MenuItem>
              );
            })}
          </Select>
          {helperText ? (
            <FormHelperText className="ai-agents-form-helper-text">
              {helperText}
            </FormHelperText>
          ) : null}
        </>
      ) : type === "autoComplete" ? (
        <AutoCompleteFields value={value} helperText={helperText} {...reset} />
      ) : null}
    </Box>
  );
};

export default AiAgentsFields;
