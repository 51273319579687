import { Box, Tooltip } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LucButton from "shared/lucButton/lucButton";
import { faThumbsDown, faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import {
  faThumbsDown as faThumbsDownSolid,
  faThumbsUp as faThumbsUpSolid,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const SummaryFooter = ({
  summaryLastQuestion,
  isRemainingEnergy,
  pageParams,
  postQuestionStatus,
  setOpenLuci,
  summaryWidgetDisabled,
  summaryActionDisabled,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const likedStatus = summaryLastQuestion?.attributes?.liked;

  const addFooterAction = (status) =>
    !summaryActionDisabled ? postQuestionStatus(status, pageParams) : null;

  return (
    <Box className="summary-footer-wrapper">
      {isRemainingEnergy ? (
        <LucButton
          variant="outline"
          size="small"
          type="secondary"
          id="summary-widget-have-question"
          onClick={() => setOpenLuci(true)}
          disabled={summaryWidgetDisabled}
        >
          {CheckValueLocale("have_question", "", {}, intl)}
        </LucButton>
      ) : (
        <LucButton
          variant="outline"
          size="small"
          type="secondary"
          id="summary-widget-upgrade-subscription"
          onClick={() => {
            navigate(`/settings/subscription`);
          }}
          disabled={summaryWidgetDisabled}
        >
          {CheckValueLocale("upgrade_my_subscription", "", {}, intl)}
        </LucButton>
      )}
      <Box className="summary-footer-action">
        <Tooltip
          title={
            !summaryActionDisabled ? (
              <Box className="summary-span-tooltip" component="span">
                {CheckValueLocale("this_isnot_helpful", "", {}, intl)}
              </Box>
            ) : null
          }
          onClick={() => addFooterAction("dislike")}
          placement="top"
          arrow
        >
          <Box
            className={`footer-action-box 
              ${likedStatus === false ? "action-dislike" : ""}
              ${summaryActionDisabled ? "summary-widget-disabled" : ""}
            `}
          >
            <FontAwesomeIcon
              icon={likedStatus === false ? faThumbsDownSolid : faThumbsDown}
            />
          </Box>
        </Tooltip>
        <Tooltip
          title={
            !summaryActionDisabled ? (
              <Box className="summary-span-tooltip" component="span">
                {CheckValueLocale("i_like_this", "", {}, intl)}
              </Box>
            ) : null
          }
          onClick={() => addFooterAction("like")}
          placement="top"
          arrow
        >
          <Box
            className={`footer-action-box 
              ${likedStatus === true ? "action-like" : ""}
              ${summaryActionDisabled ? "summary-widget-disabled" : ""}
            `}
          >
            <FontAwesomeIcon
              icon={likedStatus === true ? faThumbsUpSolid : faThumbsUp}
            />
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default SummaryFooter;
