import React, { useCallback } from "react";
import Dropzone from "react-dropzone";
import { Box, Typography, FormHelperText } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const UploadExceFile = (props) => {
  const intl = useIntl();
  const { setFiles, errorMessages, setFileSize } = props;
  const uploadErrorMessage = errorMessages;
  const maxSize = 1048576; //this check if the file is less than or equal 1MB

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    setFiles([]);
    const mapAcc = acceptedFiles?.map((file) => ({ file, errors: [] }));
    setFiles((cur) => [...cur, ...mapAcc, ...rejectedFiles]);
    setFileSize(acceptedFiles?.[0]?.size);
  }, []);
  return (
    <Box className="accounts-list-upload-file-container">
      <Typography component="span" className="upload-file-title">
        {CheckValueLocale("accounts_list_upload_file_title", "", {}, intl)}
      </Typography>
      <Box
        className={`upload-file-card ${uploadErrorMessage?.length > 0 ? "upload-error-message" : ""}`}
      >
        <Dropzone
          onDrop={onDrop}
          multiple={false}
          accept=".xls,.xlsx,.csv"
          maxSize={maxSize}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Box className="upload-file-data">
                <CloudUploadIcon className="import-upload-icon" />
                <Box className="drop-file-content">
                  <Typography component="p" className="upload-file-header">
                    {CheckValueLocale(
                      "accounts_list_import_file_header",
                      "",
                      {},
                      intl,
                    )}
                  </Typography>
                  <Typography component="span" className="import-browse-file">
                    {CheckValueLocale(
                      "accounts_list_import_browse",
                      "",
                      {},
                      intl,
                    )}
                  </Typography>
                </Box>
              </Box>
            </div>
          )}
        </Dropzone>
      </Box>

      {Array?.isArray(uploadErrorMessage) &&
      uploadErrorMessage?.length !== 0 ? (
        <FormHelperText error className="upload-file-error-msg">
          {uploadErrorMessage?.map((msg, index) => (
            <Typography key={index} className="upload-file-error-msg-text">
              {msg?.rows
                ? CheckValueLocale(
                    msg?.message,
                    "",
                    { rows: msg?.rows?.join(", ") },
                    intl,
                  )
                : CheckValueLocale(msg, "", {}, intl)}
            </Typography>
          ))}
        </FormHelperText>
      ) : null}
      <>
        <Typography component="span" className="upload-accepted-formats-title">
          {CheckValueLocale(
            "accounts_list_accepted_formats_title",
            "",
            {},
            intl,
          )}
        </Typography>
        <Typography component="p" className="upload-accepted-formats-title">
          {CheckValueLocale(
            "accounts_list_accepted_size_limit_title",
            "",
            {},
            intl,
          )}
        </Typography>
      </>
    </Box>
  );
};

export default UploadExceFile;
