import {
  Box,
  FormHelperText,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import PopupModal from "components/popupModal";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { useFormik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef, useState } from "react";
import { faGrinAlt } from "@fortawesome/pro-regular-svg-icons";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import _ from "lodash";
import {
  socialIcons,
  publicReplyValidationSchema,
  socialMediaPlatforms,
} from "../utils/publicRepliesUtils";
import FormHelperError from "pages/alerts/components/alertForm/components/formHelperError";
import CustomizedTooltip from "components/customizedTooltip";

const AddEditPublicReply = ({
  open,
  close,
  currentReply,
  isEditingExistingReply,
  automatedPublicRepliesData,
  setAutomatedPublicRepliesData,
  selectedTab,
}) => {
  const intl = useIntl();
  const [anchorElEmoji, setAnchorElEmoji] = useState(null);
  const [replyAlreadyExists, setReplyAlreadyExists] = useState(false);
  const replyInputFieldRef = useRef(null);

  const handleClickEmoji = (event) => {
    setAnchorElEmoji(event?.currentTarget);
  };
  const handleCloseEmoji = () => {
    setAnchorElEmoji(null);
    if (replyInputFieldRef?.current) {
      replyInputFieldRef?.current?.focus();
    }
  };
  const openEmoji = Boolean(anchorElEmoji);
  // add emoji to text after select and hide emoji Dropdown
  const emojiSelect = (e) => {
    formik.setFieldValue("message", formik?.values?.message + e);

    handleCloseEmoji();
  };

  const anchorPosition =
    intl.locale === "en"
      ? {
          vertical: "top",
          horizontal: "right",
        }
      : {
          vertical: "top",
          horizontal: "left",
        };

  const transformPosition =
    intl.locale === "en"
      ? {
          vertical: "bottom",
          horizontal: "right",
        }
      : {
          vertical: "bottom",
          horizontal: "left",
        };

  const formik = useFormik({
    validateOnBlur: false,
    initialValues: {
      message: isEditingExistingReply ? currentReply?.reply_text?.trim() : "",
      dataSource: selectedTab,
    },
    validationSchema: publicReplyValidationSchema,
    onSubmit: (values) => {
      const clonedAutonmatedPublicReplies = _.cloneDeep({
        ...automatedPublicRepliesData,
      });
      const replyAlreadyExists = clonedAutonmatedPublicReplies[
        values?.dataSource?.toUpperCase()
      ].replies?.some((reply) => {
        return reply?.reply_text?.trim() === values?.message?.trim();
      });
      setReplyAlreadyExists(replyAlreadyExists);

      if (!replyAlreadyExists) {
        if (isEditingExistingReply) {
          //editing reply
          const existingReplies = _.cloneDeep([
            ...clonedAutonmatedPublicReplies[values?.dataSource?.toUpperCase()]
              ?.replies,
          ]);

          let index = "";
          if (currentReply?.hasOwnProperty("id")) {
            index = existingReplies?.findIndex(
              (reply) => reply?.id === currentReply?.id,
            );
            existingReplies[index].updated = true;
          } else {
            index = existingReplies?.findIndex(
              (reply) =>
                reply?.reply_text?.trim() === currentReply?.reply_text?.trim(),
            );
          }

          existingReplies[index].reply_text = values?.message;

          setAutomatedPublicRepliesData((prev) => ({
            ...prev,
            [values?.dataSource?.toUpperCase()]: {
              ...prev[values?.dataSource?.toUpperCase()],
              replies: [...existingReplies],
            },
          }));
        } else {
          // adding new reply
          const newAutomatedPublicRepliesDataObject = {
            ...clonedAutonmatedPublicReplies,
            [values?.dataSource?.toUpperCase()]: {
              ...clonedAutonmatedPublicReplies[
                values?.dataSource?.toUpperCase()
              ],
              replies: [
                ...clonedAutonmatedPublicReplies[
                  values?.dataSource?.toUpperCase()
                ]?.replies,
                { reply_text: values?.message, new: true },
              ],
            },
          };

          setAutomatedPublicRepliesData(newAutomatedPublicRepliesDataObject);
        }
      }

      if (!replyAlreadyExists) close(); // close modal
    },
  });

  const replyLength = formik.values?.message?.length || 0;
  const isSpacesAll = !formik.values?.message?.trim();
  const isMaxCharactersReached = replyLength > 280;
  const isDisabled = isSpacesAll || isMaxCharactersReached;

  const handleEditMessage = () => {
    if (isDisabled) return;
    formik.handleSubmit();
  };

  const modalBody = (
    <Box id="add-edit-public-reply-modal">
      <>
        {!isEditingExistingReply ? (
          <>
            <Typography className="add-public-reply-data-source-label">
              {CheckValueLocale("data_source", "", {}, intl)}
            </Typography>
            <Select
              fullWidth
              value={formik?.values?.dataSource}
              name="dataSource"
              sx={{ height: "40px" }}
              onChange={formik?.handleChange}
              error={!!formik?.errors?.dataSource}
              input={<OutlinedInput className="select-input-field" />}
              renderValue={(selected) =>
                CheckValueLocale(selected, "", {}, intl)
              }
            >
              {socialMediaPlatforms(intl).map((val) =>
                automatedPublicRepliesData[val?.id?.toUpperCase()]?.replies
                  ?.length >= 15 ? ( // check if it exceeds the number of replies limit will show tooltip and if not show menu item direct
                  //because showin tooltip under the select was causng isssues in onChange function
                  <CustomizedTooltip
                    key={val}
                    id="max-reply-limit-tooltip"
                    title={CheckValueLocale(
                      "reached_max_number_of_replies",
                      "",
                      {},
                      intl,
                    )}
                    placement={intl.locale === "ar" ? "right" : "left"}
                  >
                    <Box>
                      <MenuItem
                        className="report-filter-menu-item"
                        key={`MT-${val?.id}`}
                        value={val?.id}
                        id={`report-filter-data-source-menu-item-${
                          val?.id?.toLowerCase() ?? "title"
                        }`}
                        sx={{ marginBlock: "10px" }}
                        disabled={
                          !automatedPublicRepliesData[val?.id?.toUpperCase()]
                            ?.status ||
                          automatedPublicRepliesData[val?.id?.toUpperCase()]
                            ?.replies?.length >= 15
                        }
                      >
                        <Box
                          key={val?.id}
                          className="add-public-reply-social-icon"
                        >
                          {socialIcons(`${val?.id?.toUpperCase()}`)}
                        </Box>
                        <ListItemText primary={val?.label} />
                      </MenuItem>
                    </Box>
                  </CustomizedTooltip>
                ) : (
                  <MenuItem
                    className="report-filter-menu-item"
                    key={`MT-${val?.id}`}
                    value={val?.id}
                    id={`report-filter-data-source-menu-item-${
                      val?.id?.toLowerCase() ?? "title"
                    }`}
                    sx={{ marginBlock: "10px" }}
                    disabled={
                      !automatedPublicRepliesData[val?.id?.toUpperCase()]
                        ?.status
                    }
                  >
                    <Box key={val?.id} className="add-public-reply-social-icon">
                      {socialIcons(`${val?.id?.toUpperCase()}`)}
                    </Box>
                    <ListItemText primary={val?.label} />
                  </MenuItem>
                ),
              )}
            </Select>
            {formik?.errors?.dataSource && (
              <FormHelperText error component="div">
                {CheckValueLocale(formik?.errors?.dataSource, "", {}, intl)}
              </FormHelperText>
            )}
          </>
        ) : null}

        <Box className="add-edit-public-reply-input-label-container">
          <Typography className="add-edit-public-reply-input-label">
            {isEditingExistingReply
              ? CheckValueLocale("edit_public_reply", "", {}, intl)
              : CheckValueLocale(
                  "automated_public_replies_add_your_reply",
                  "",
                  {},
                  intl,
                )}
          </Typography>
          {intl.locale == "ar" ? (
            <Typography id="reply-count">280 / {replyLength}</Typography>
          ) : (
            <Typography id="reply-count">{replyLength} / 280</Typography>
          )}
        </Box>
        <Box className="add-public-reply-message-input-container">
          <div className="reply-container">
            <TextField
              value={formik?.values?.message}
              onChange={(e) => {
                formik?.handleChange(e);
                setReplyAlreadyExists(false);
              }}
              multiline
              inputRef={replyInputFieldRef}
              maxRows={4}
              variant="outlined"
              name="message"
              placeholder={CheckValueLocale("write_here", "", {}, intl)}
              className="reply-textarea"
              error={
                (formik?.errors?.message !== "required" &&
                  !!formik?.errors?.message) ||
                replyAlreadyExists
              }
              inputProps={{
                sx: {
                  "&::placeholder": {
                    color: "#00000099 !important",
                    opacity: 1,
                    fontSize: 14,
                    fontFamily: 400,
                  },
                },
              }}
            />
          </div>

          <Box className="reply-footer">
            <Box className="reply-footer-box">
              <Tooltip
                title={CheckValueLocale("emoji_tooltip", "", {}, intl)}
                placement="bottom"
                arrow
              >
                <Box
                  className="reply-footer-icon emoji-icon"
                  onClick={handleClickEmoji}
                >
                  <FontAwesomeIcon icon={faGrinAlt} />
                </Box>
              </Tooltip>
              <Popover
                anchorOrigin={anchorPosition}
                transformOrigin={transformPosition}
                onClose={handleCloseEmoji}
                open={openEmoji}
                anchorEl={anchorElEmoji}
              >
                <Picker
                  data={data}
                  onEmojiSelect={(e) => emojiSelect(e?.native)}
                />
              </Popover>
            </Box>
          </Box>
        </Box>
        <FormHelperError
          error
          className="reply-box-error-message"
          value={
            replyAlreadyExists
              ? CheckValueLocale("public_reply_already_exists", "", {}, intl)
              : formik?.errors?.message !== "required"
                ? CheckValueLocale(formik?.errors?.message, "", {}, intl)
                : ""
          }
        />
      </>
    </Box>
  );

  return (
    <PopupModal
      title={
        isEditingExistingReply
          ? CheckValueLocale("edit_public_reply_button", "", {}, intl)
          : CheckValueLocale("new_public_reply_button", "", {}, intl)
      }
      body={modalBody}
      open={open}
      close={close}
      leftBtn={CheckValueLocale("cancel", "", {}, intl)}
      rightBtn={
        isEditingExistingReply
          ? CheckValueLocale("save_changes", "", {}, intl)
          : CheckValueLocale("add", "", {}, intl)
      }
      rightBtnId={
        isEditingExistingReply
          ? "edit-reply-submit-button"
          : "add-reply-submit-button"
      }
      leftBtnId="cancel-add-edit-reply-button"
      classeName="edit-greeting-farewell-message-modal"
      accept={handleEditMessage}
      disabled={isDisabled}
      addClasses="edit-greeting-farewell-message-modal-buttons"
    />
  );
};

export default AddEditPublicReply;
