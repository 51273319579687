import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLink,
  faPlus,
  faSearch,
  faWindow,
} from "@fortawesome/pro-regular-svg-icons";
import PageHeader from "components/pageHeader";
import LucButton from "shared/lucButton/lucButton";

const SurveyListHeader = (props) => {
  const {
    listLoading,
    surveyTypes,
    surveyFilters,
    selectedFilters,
    setSelectedFilters,
    listData,
    tempSearchText,
    setTempSearchText,
    searchText,
    setSearchText,
    searchBoxClicked,
    setSearchBoxClicked,
    setOpenCreateModal,
    setSurveyTypeId,
  } = props;

  const intl = useIntl();

  const navigate = useNavigate();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const setUrlParams = (key, value) => searchParams?.set(key, value);

  const [openFilterMenu, setOpenFilterMenu] = useState({});
  const [createMenu, setCreateMenu] = useState(null);
  const openCreateMenu = Boolean(createMenu);

  const sruveyTypesDummy = [
    {
      title: "create_survey_type_link_title",
      description: "create_survey_type_link_desc",
      icon: faLink,
      value: surveyTypes?.find((type) => type?.name === "LINKED_SURVEY")?.id,
    },
    {
      title: "creaet_survey_type_embedded_title",
      description: "creaet_survey_type_embedded_desc",
      icon: faWindow,
      value: surveyTypes?.find((type) => type?.name === "EMBEDDED_SURVEY")?.id,
    },
  ];

  // If search is activated and search text is not empty and no data found
  const emptySearch =
    !listLoading && searchText?.length && !listData?.surveys?.data?.length;

  return (
    <Box className="survey-list-header">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <PageHeader title="survey_v2" />

        {handlelUserRoles("SURVEY", "CREATE_SURVEY") ? (
          <>
            <Tooltip
              id="survey-max-btn-tooltip"
              title={
                listData?.survey_limit_reach
                  ? CheckValueLocale("max_survey_count", "", {}, intl)
                  : ""
              }
              placement="bottom"
              arrow
            >
              <Box>
                <LucButton
                  id="create-survey-button"
                  startIcon={<FontAwesomeIcon icon={faPlus} />}
                  aria-controls="create-survey-menu"
                  aria-haspopup="true"
                  aria-expanded={openCreateMenu ? "true" : undefined}
                  disabled={listData?.survey_limit_reach !== false}
                  onClick={(e) => setCreateMenu(e.currentTarget)}
                  disableElevation
                >
                  {CheckValueLocale("new_survey", "", {}, intl)}
                </LucButton>
              </Box>
            </Tooltip>
            <Menu
              id={"create-survey-menu"}
              anchorEl={createMenu}
              open={openCreateMenu}
              onClose={() => setCreateMenu(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: intl?.locale == "en" ? "right" : "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: intl?.locale == "en" ? "right" : "left",
              }}
            >
              {sruveyTypesDummy
                ?.filter((item) => !!item?.value)
                ?.map((item) => {
                  return (
                    <MenuItem
                      key={item?.value}
                      id={`create-survey-menu-${item?.value}-item`}
                      className="create-survey-menu-item"
                      onClick={() => {
                        setCreateMenu(null);
                        setOpenCreateModal(true);
                        setSurveyTypeId(Number(item?.value));
                      }}
                      disableRipple
                    >
                      <Stack
                        direction="row"
                        alignItems="flex-start"
                        className="survey-type-menu-item-content"
                      >
                        <Box component="span" className="survey-type-icon">
                          <FontAwesomeIcon icon={item?.icon} />
                        </Box>
                        <Stack
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          spacing={1}
                        >
                          <Typography className="survey-type-title">
                            {CheckValueLocale(item?.title, "", {}, intl)}
                          </Typography>
                          <Box component="span" className="survey-type-desc">
                            {CheckValueLocale(item?.description, "", {}, intl)}
                          </Box>
                        </Stack>
                      </Stack>
                    </MenuItem>
                  );
                })}
            </Menu>
          </>
        ) : null}
      </Stack>

      {!listLoading ? (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box className="search-box-parant survey-search">
            <Box className="search-box">
              <TextField
                className="search-field"
                label={CheckValueLocale("search_by_name", "", {}, intl)}
                variant="outlined"
                value={tempSearchText}
                onChange={(e) => setTempSearchText(e?.target?.value)}
                onBlur={() => setSearchBoxClicked(false)}
                onFocus={() => setSearchBoxClicked(true)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    setSearchText(e.target.value);
                    setUrlParams("q", e.target.value);
                    setUrlParams("page", 1);
                    navigate({ search: searchParams.toString() });
                    e.target.blur();
                  }
                }}
                error={emptySearch}
                helperText={
                  emptySearch
                    ? CheckValueLocale("survey_search_error_msg", "", {}, intl)
                    : null
                }
              />
              <Box
                className={`search-icon-container ${emptySearch ? "empty-error" : searchBoxClicked ? "search-icon-container-blue" : ""}`}
                onClick={() => {
                  setSearchText(tempSearchText);
                  setUrlParams("q", tempSearchText);
                  setUrlParams("page", 1);
                  navigate({ search: searchParams.toString() });
                }}
              >
                <FontAwesomeIcon className="search-icon" icon={faSearch} />
              </Box>
            </Box>
            {tempSearchText ? (
              <Button
                id="clear-search"
                onClick={() => {
                  setTempSearchText("");
                  setSearchText("");
                  setUrlParams("q", "");
                  setUrlParams("page", 1);
                  navigate({ search: searchParams.toString() });
                }}
              >
                {CheckValueLocale("clear_search", "", {}, intl)}
              </Button>
            ) : null}
          </Box>

          <Stack
            direction="row"
            alignItems="center"
            className="survey-filters-wrapper"
          >
            {surveyFilters?.map((filter) => (
              <FormControl
                key={filter?.name}
                className="survey-filter-select"
                variant="outlined"
                size="small"
                fullWidth
              >
                <InputLabel id={`survey-filter-${filter?.name}-label`}>
                  {CheckValueLocale(`survey_${filter?.name}`, "", {}, intl)}
                </InputLabel>
                <Select
                  key={filter?.name}
                  labelId={`survey-filter-${filter?.name}-label`}
                  id={`survey-filter-${filter?.name}`}
                  open={openFilterMenu[filter?.name] || false}
                  onClose={() =>
                    setOpenFilterMenu((prev) => ({
                      ...prev,
                      [filter?.name]: false,
                    }))
                  }
                  onOpen={() =>
                    setOpenFilterMenu((prev) => ({
                      ...prev,
                      [filter?.name]: true,
                    }))
                  }
                  input={
                    <OutlinedInput
                      label={CheckValueLocale(
                        `survey_${filter?.name}`,
                        "",
                        {},
                        intl,
                      )}
                    />
                  }
                  renderValue={(selected) =>
                    selected?.length === filter?.options?.length
                      ? CheckValueLocale(
                          `survey_all_${filter?.name}`,
                          "",
                          {},
                          intl,
                        )
                      : selected
                          ?.map((item) =>
                            CheckValueLocale(
                              `survey_filter_${filter?.options
                                ?.find((opt) => opt?.value === item)
                                ?.name?.toLowerCase()}`,
                              "",
                              {},
                              intl,
                            ),
                          )
                          ?.join(", ")
                  }
                  value={selectedFilters?.[filter?.name] || []}
                  onChange={(e) => {
                    setUrlParams("page", 1);
                    setSelectedFilters((prev) => ({
                      ...prev,
                      [filter?.name]: e.target.value,
                    }));
                  }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: intl.locale === "ar" ? "right" : "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: intl.locale === "ar" ? "right" : "left",
                    },
                  }}
                  multiple
                >
                  {filter?.options?.map((option) => {
                    const isChecked = selectedFilters?.[filter?.name]?.includes(
                      option?.value,
                    );
                    return (
                      <MenuItem
                        id="survey-filter-menu-item"
                        key={option?.name}
                        value={option?.value}
                        onClick={() => {
                          setUrlParams("page", 1);
                          setSelectedFilters((prev) => {
                            const currentSelections =
                              prev?.[filter?.name] || [];
                            const updatedSelections = isChecked
                              ? currentSelections?.filter(
                                  (val) => val !== option?.value,
                                )
                              : [...currentSelections, option?.value];

                            return {
                              ...prev,
                              [filter?.name]: updatedSelections,
                            };
                          });
                          setOpenFilterMenu((prev) => ({
                            ...prev,
                            [filter?.name]: false,
                          }));
                        }}
                      >
                        <Checkbox checked={isChecked} />
                        <ListItemText
                          primary={CheckValueLocale(
                            `survey_filter_${option?.name?.toLowerCase()}`,
                            "",
                            {},
                            intl,
                          )}
                        />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            ))}
          </Stack>
        </Stack>
      ) : null}
    </Box>
  );
};

export default SurveyListHeader;
