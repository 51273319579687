import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Menu, MenuItem, Tooltip } from "@mui/material";
import { useMemo, useState } from "react";
import {
  EngagementTabTooltitBody,
  getEngagementTabInfo,
  getSocialIconForEngagementTabs,
} from "./engagementTabsHelpers";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import TruncatedTooltip from "shared/truncatedTooltip/truncatedTooltip";
import { engagementTabsActions } from "pages/engagements/providers/engagementsTabsProvider";
import { CheckValueLocale, classNames } from "utils/helpers";
import { useIntl } from "react-intl";

export const EngagmentMenuTabs = ({
  engagements,
  handleEngagementTabs,
  visibleEngagementTabsCount,
  refForLastDivider,
}) => {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const propperProps = useMemo(
    () => ({
      disablePortal: true,
      popperOptions: {
        positionFixed: true,
        modifiers: {
          preventOverflow: {
            enabled: true,
            boundariesElement: "window", // where "window" is the boundary
          },
        },
      },
    }),
    [],
  );

  const handleCloseMenu = () => {
    setAnchorEl(null);
    handleLastDividerVisibility("visible");
  };
  const handleOpenMenu = (event) => {
    setAnchorEl(event?.currentTarget);
    setTimeout(() => handleLastDividerVisibility("hidden"), 0);
  };

  const handeAddTabAtBegining = (idx) => {
    handleEngagementTabs?.(engagementTabsActions.move_tab, {
      srcIdx: idx + visibleEngagementTabsCount,
      destIdx: 0,
      activateTab: true,
    });
    handleCloseMenu();
  };

  const handleRemoveTab = (engagement) => {
    handleEngagementTabs?.(engagementTabsActions.remove_tab, {
      id: engagement?.engId,
    });
  };

  const handleLastDividerVisibility = (visibility) => {
    refForLastDivider?.current?.style?.setProperty?.("visibility", visibility);
  };

  const counter =
    intl?.locale === "en"
      ? `+${engagements?.length}`
      : `${engagements?.length}+`;

  return (
    <>
      <Tooltip
        title={CheckValueLocale("engagement_tabs_show_more", "", {}, intl)}
        arrow
        placement="bottom"
        classes={{
          tooltip: "engagement-tabs-list-item-tooltip-menu",
        }}
      >
        <Box
          className={classNames(
            "engagement-tabs-menu",
            open ? "active-menu" : "",
          )}
          onClick={handleOpenMenu}
          onMouseEnter={() => handleLastDividerVisibility("hidden")}
          onMouseLeave={() => handleLastDividerVisibility("visible")}
        >
          <Box className="engagement-tabs-menu-button">
            <Box className="engagement-tabs-menu-counter">{counter}</Box>
            <FontAwesomeIcon icon={faCaretDown} />
          </Box>
        </Box>
      </Tooltip>
      <Box className="engagement-tabs-list-divider">
        <Box className="engagement-tab-divider" />
      </Box>
      <Menu
        id="engagement-tabs-menu-list"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        classes={{ paper: "engagement-tabs-menu-list-paper" }}
      >
        {engagements?.map((engagement, idx) => {
          const { displayName, dataSource } = getEngagementTabInfo(engagement);
          return (
            <Tooltip
              propperProps={propperProps}
              title={<EngagementTabTooltitBody engagement={engagement} />}
              arrow
              placement={intl.locale === "ar" ? "left" : "right"}
              classes={{
                tooltip: "engagement-tabs-list-item-tooltip menu-item-tooltip",
              }}
              key={engagement?.engId}
            >
              <MenuItem
                key={engagement?.engId}
                className="engagement-tabs-menu-item"
                onClick={() => {
                  handeAddTabAtBegining(idx);
                }}
                id={`engagement-tabs-menu-item-${idx}`}
              >
                <Box className="engagement-tabs-menu-item-icon">
                  {getSocialIconForEngagementTabs(dataSource)}
                </Box>
                <TruncatedTooltip
                  title={displayName}
                  placement="bottom"
                  className="engagement-tabs-menu-item-text"
                  enablePropperProps={true}
                />
                <Box
                  className="engagement-tabs-menu-item-close"
                  onClick={(e) => {
                    e?.stopPropagation();
                    handleRemoveTab(engagement);
                  }}
                  id={`engagement-tabs-menu-item-close-${idx}`}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </Box>
              </MenuItem>
            </Tooltip>
          );
        })}
      </Menu>
    </>
  );
};
