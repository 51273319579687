import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  checkKeyboardLanguage,
  CheckValueLocale,
  isEmptyArray,
} from "utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  filters,
  reduxSavedFilterName,
  savedFilter,
} from "utils/redux/features/SocialListening/socialListeningSlice";
import CommonFunctions from "pages/Monitors/Components/commonFunctions";
import { useCreateSavedFilter } from "shared/reduxComponents/hooks/useCreateSavedFilter";
import { useGetSavedFilterData } from "shared/reduxComponents/hooks/useGetSavedFilterData";
import { useDeleteSavedFilterData } from "shared/reduxComponents/hooks/useDeleteSavedFilterData";
import { useGetMonitorFiltersFileds } from "shared/reduxComponents/hooks/useGetMonitorFiltersFileds";
import { useGetDatasources } from "shared/reduxComponents/hooks/useGetDatasources";
import { useGetAllSavedFilters } from "shared/reduxComponents/hooks/useGetAllSavedFilters";

const SavedFiltersFunctions = (
  monitorId,
  activeTab,
  monitorDataSource,
  monitorType,
  startDate,
  endDate,
  applyFilter,
) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const reduxFilterParams = useSelector(
    (state) => state?.socialListening?.newFilterParams,
  );

  const reduxSaveFilterId = useSelector(
    (state) => state?.socialListening?.savedFilterId,
  );

  const reduxSaveFilterName = useSelector(
    (state) => state?.socialListening?.savedFilterName,
  );

  const reduxSavedFilter = useSelector(
    (state) => state?.socialListening?.savedFilter,
  );

  const [showSaveAndApplyModal, setShowSaveAndApplyModal] = useState(false);
  const [filterName, setFilterName] = useState(reduxSaveFilterName);
  const [showSnackBar, setshowSnackBar] = useState(false);
  const [savedFilters, setSavedFilters] = useState(reduxSavedFilter);
  const [filterFields, setFilterFields] = useState([]);
  const [savedFilterId, setSavedFilterId] = useState(reduxSaveFilterId);
  const [showDeleteFilterModal, setShowDeleteFilterModal] = useState(false);
  const [assignActiveFilters, setAssignActiveFilters] =
    useState(reduxFilterParams);

  const handleCloseDeleteFilterModal = () => {
    setShowDeleteFilterModal(false);
  };
  const [snackBarDetails, setsnackBarDetails] = useState({
    severity: "",
    message: "",
    title: "",
  });
  const { changeFilterParamsToString } = CommonFunctions();
  // ______________________________________________________________________________________
  //set initial  Empty filterParams
  const [filterParams, setFilterParams] = useState(reduxFilterParams);
  // _____________________________________________________________________________________
  useEffect(() => {
    setSavedFilters(reduxSavedFilter);
  }, [reduxSavedFilter]);
  // ______________________________________________________________________________________
  // ______________________________________________________________________________________
  //this function empty all filter fields
  const handleClearFilterParams = () => {
    setSavedFilterId("");
    setFilterName("");
    setFilterParams({});
  };
  // ______________________________________________________________________________________
  //this one handle the new saved filter name
  const handleFilterName = (e) => {
    setFilterName(e.target.value);
  };
  // ______________________________________________________________________________________
  //this is the save filter Name Modal Body
  const modalBody = (
    <TextField
      fullWidth
      varient="outlined"
      label={CheckValueLocale("filter_name", "", {}, intl)}
      style={{
        direction: checkKeyboardLanguage(filterName) ? "rtl" : "ltr",
      }}
      value={filterName}
      onChange={handleFilterName}
      placeholder={CheckValueLocale("filter_name", "", {}, intl)}
    />
  );
  // ______________________________________________________________________________________

  const handleShowSaveAndApplyModal = (filterParams) => {
    setShowSaveAndApplyModal(true);
    setFilterName("");
    setFilterParams(filterParams);
  };
  // ______________________________________________________________________________________

  const handleCloseApplySaveFilter = () => {
    setShowSaveAndApplyModal(false);
    setFilterName("");
  };
  // ______________________________________________________________________________________

  const handleCloseSnackBar = () => {
    setshowSnackBar(false);
  };
  // ______________________________________________________________________________________
  //Click on Save & Apply in popup Modal to create new filter
  const { mutate: createSavedFilterAction } = useCreateSavedFilter();
  const handleApplySaveFilter = () => {
    if (filterName === "") {
      return false;
    } else {
      createSavedFilterAction(
        {
          monitorId,
          productId: window.localStorage.sm_id,
          sectionName: activeTab,
          filterName,
          filterParams:
            filterParams && changeFilterParamsToString(filterParams),
          dataSourceId,
          startDate,
          endDate,
        },
        {
          onSuccess: () => {
            setShowSaveAndApplyModal(false);
            setshowSnackBar(true); // show save and apply snackbar
            setFilterName(filterName); // remove old filter name
            setAssignActiveFilters(filterParams); // set selected data to be shown in active filters section
            applyFilter(startDate, endDate, filterParams);
            getAllSavedFilters();
            setsnackBarDetails({
              severity: "success",
              message: CheckValueLocale(
                "you_can_check_saved_filters_inside_filter_panel",
                "",
                {},
                intl,
              ),
              title: CheckValueLocale(
                "filter_saved_successfully",
                "",
                {},
                intl,
              ),
            });
          },
          onError: () => {
            setsnackBarDetails({
              severity: "error",
              message: CheckValueLocale("repeated_name_error", "", {}, intl),
            });
          },
        },
      );
    }
  };
  // ______________________________________________________________________________________

  const { mutate: getSavedFilterDataAction } = useGetSavedFilterData();

  const getSavedFilterData = (savedFilterId) => {
    getSavedFilterDataAction(
      {
        saved_filter_id: savedFilterId,
        monitor_id: monitorId,
        product_id: window.localStorage.sm_id,
        data_source_id: dataSourceId,
      },
      {
        onSuccess: (response) => {
          const data = response?.data?.data;
          setFilterName(data?.attributes?.name_filter);
          dispatch(reduxSavedFilterName(data?.attributes?.name_filter));
          setFilterParams(data?.attributes?.params_filter);
          dispatch(filters(data?.attributes?.params_filter));
        },
      },
    );
  };
  // ______________________________________________________________________________________
  //this fn deletes a previously saved filter
  const { mutate: deleteFilterAction } = useDeleteSavedFilterData();
  const handleDeleteFilter = () => {
    deleteFilterAction(
      {
        saved_filter_id: savedFilterId,
        monitor_id: monitorId,
        product_id: window.localStorage.sm_id,
        data_source_id: dataSourceId,
      },
      {
        onError: () => {
          setsnackBarDetails({
            severity: "error",
            message: CheckValueLocale("failed_error", "", {}, intl),
          });
        },
        onSuccess: () => {
          getAllSavedFilters();
          setsnackBarDetails({
            severity: "success",
            message: CheckValueLocale(
              "filter_deleted_successfully",
              "",
              {},
              intl,
            ),
          });
        },
        onSettled: () => {
          setShowDeleteFilterModal(false);
          setshowSnackBar(true);
          handleClearFilterParams(); //empty all selected filters
          applyFilter(startDate, endDate, {}); //request to connect with rabbit mq with empty active filters
        },
      },
    );
  };
  /*______________________________________________________________*/
  //This Call is to get All filter fields for active tab
  const { mutate: getFilterFieldsAction } = useGetMonitorFiltersFileds();
  const getFiltersFileds = () => {
    getFilterFieldsAction(
      {
        section: activeTab,
        monitor_id: monitorId,
        source: monitorDataSource,
        product_id: window.localStorage.sm_id,
        monitor_type: monitorType,
      },
      {
        onSuccess: (response) => {
          const data = response?.data;
          setFilterFields(data.data);
          getAllSavedFilters();
        },
      },
    );
  };
  // ______________________________________________________________________________________
  // this call return all Previously Saved Filters inside (saved filters) dropdown
  const [dataSourceId, setDataSourceId] = useState("");

  const { mutate: getAllSavedFiltersAction } = useGetDatasources();

  const getAllSavedFilters = () => {
    getAllSavedFiltersAction(undefined, {
      onSuccess: (response) => {
        const data = response?.data?.data;
        data?.filter((dataSource) => {
          if (monitorDataSource === "nb" || monitorDataSource === "NEWSBLOGS") {
            monitorDataSource = "TALKWALKER"; // we can't change it until it changed from backend as it crashes the filters
          }
          if (monitorDataSource === dataSource.source) {
            setDataSourceId(dataSource.id);
            getAllFilters(dataSource.id);
          }
        });
      },
    });
  };

  const { mutate: getAllFiltersAction } = useGetAllSavedFilters();
  const getAllFilters = (dataSourceId) => {
    getAllFiltersAction(
      {
        monitor_id: monitorId,
        product_id: window.localStorage.sm_id,
        data_source_id: dataSourceId,
        section_name: activeTab,
      },
      {
        onSuccess: (response) => {
          const data = response?.data;
          if (!isEmptyArray(data?.data)) {
            let savedFilterIds = data?.data?.map((a) => a?.id);
            let reduxSavedFiltersIDs = reduxSavedFilter?.map((a) => a?.id);
            let savedFilters = [];
            savedFilterIds?.map((id, index) => {
              if (!reduxSavedFiltersIDs?.includes(id)) {
                savedFilters?.push(data?.data[index]);
              }
            });
            dispatch(savedFilter([].concat(reduxSavedFilter, savedFilters)));
          }
        },
      },
    );
  };
  // ______________________________________________________________________________________

  return {
    modalBody,
    handleFilterName,
    handleDeleteFilter,
    getSavedFilterData,
    handleApplySaveFilter,
    handleShowSaveAndApplyModal,
    handleCloseSnackBar,
    showSaveAndApplyModal,
    setShowSaveAndApplyModal,
    showSnackBar,
    handleClearFilterParams,
    setFilterParams,
    filterParams,
    handleCloseApplySaveFilter,
    snackBarDetails,
    filterFields,
    getFiltersFileds,
    savedFilters,
    setSavedFilterId,
    savedFilterId,
    showDeleteFilterModal,
    handleCloseDeleteFilterModal,
    setShowDeleteFilterModal,
    filterName,
    assignActiveFilters,
    setAssignActiveFilters,
    dataSourceId,
  };
};
export default SavedFiltersFunctions;
