import { useQuery } from "@tanstack/react-query";
import Engagements from "services/api/engagements";
const useGetNotesUsersList = ({
  monitor_id,
  product_id,
  data_source_name,
  interaction_id,
}) => {
  return useQuery({
    queryKey: [
      "notesUsersList",
      {
        monitor_id,
        product_id,
        data_source_name,
        interaction_id,
      },
    ],
    queryFn: () =>
      Engagements.getNotesUsersList({
        monitor_id,
        product_id,
        data_source_name,
        interaction_id,
      }),
    staleTime: 0, // no cache
    select: (data) => data?.data,
  });
};
export default useGetNotesUsersList;
