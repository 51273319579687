import { useQuery } from "@tanstack/react-query";
import { MainMonitorsPage } from "services/api/mainMonitorsPage";

export const useCompanyList = ({ enabled }) => {
  return useQuery({
    queryKey: ["companies"],
    queryFn: () => MainMonitorsPage.getCompanyList(),
    select: (data) => data?.data?.data,
    enabled,
  });
};
