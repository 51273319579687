import { Box } from "@mui/material";
import "./loginPage.scss";
import { useLocation, Navigate } from "react-router-dom";
import FormSide from "./components/formSide/formSide";
import BackgroundSide from "./components/backgroundSide/backgroundSide";

export default function LoginPage(props) {
  const location = useLocation();

  const isInvitationLogin = location?.pathname?.includes("/invitation_login");

  return (
    <>
      {localStorage?.getItem("user_token") && !isInvitationLogin ? (
        <Navigate to="/home_page" />
      ) : (
        <Box className="login-wrapper">
          <FormSide />
          <BackgroundSide />
        </Box>
      )}
    </>
  );
}
