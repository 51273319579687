import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";
import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const AlertBanners = ({
  headerData = {},
  selectedCard = {},
  exceedWhatsappWindow,
}) => {
  const intl = useIntl();

  // Extract data from props with default values
  const { paused, type, monitor_name: monitorName } = headerData;

  const {
    closing_reason: closingReason,
    data_source: dataSource,
    system_replies: systemReplies = {},
  } = selectedCard;

  const isPaused = paused === 1;
  const isMonitor = type === "monitor";
  const isTwitterDataSource = dataSource === "TWITTER";
  const isWhatsapp = dataSource === "WHATSAPP";
  const systemStatus = systemReplies.status;
  const isWhatsappExceedingWindow =
    exceedWhatsappWindow === 1 && dataSource === "WHATSAPP";
  // Determine if we should show the mark completed banner
  const showMarkCompletedBanner =
    (!closingReason || closingReason === "closed_by_user") &&
    ((!isTwitterDataSource && !isWhatsapp) ||
      ((isTwitterDataSource || isWhatsapp) && systemStatus === "complete"));
  // Define message keys and paths based on conditions, if it's paused, show the paused banner
  const pausedHeaderMessage = isPaused
    ? isMonitor
      ? "paused_monitors_header"
      : "paused_channels_header"
    : "conversation_closed";

  const pausedContentMessage = isPaused
    ? isMonitor
      ? "paused_monitors_contant"
      : "paused_channels_contant"
    : "no_reply_text_dm";

  const linkPath = isMonitor ? "/social/monitor_list" : "/manage-channels/list";
  const linkTextKey = isMonitor ? "monitors_list" : "manage_channels";

  const cannotReplyTitleKey = isTwitterDataSource
    ? "can_not_replay_twitter"
    : "can_not_replay";
  const cannotReplyMsgKey =
    isWhatsappExceedingWindow || dataSource === "WHATSAPP"
      ? "can_not_replay_whatsapp_msg"
      : isTwitterDataSource
        ? "can_not_replay_msg_twitter"
        : "can_not_replay_msg";

  if (isPaused) {
    return (
      <Box className="main-posts-footer no-reply-footer chat">
        <Box className="main-no-reply">
          <Box className="main-sent-reply paused">
            <ErrorOutlineIcon />
            {CheckValueLocale(pausedHeaderMessage, "", {}, intl)}
          </Box>
          <Typography className="no-reply-class">
            {CheckValueLocale(
              pausedContentMessage,
              "",
              { name: monitorName },
              intl,
            )}
            <Link target="_blank" to={linkPath} className="paused-rediraction">
              {CheckValueLocale(linkTextKey, "", {}, intl)}
              <FontAwesomeIcon icon={faExternalLink} />
            </Link>
          </Typography>
        </Box>
      </Box>
    );
  }

  if (showMarkCompletedBanner) {
    return (
      <Box className="main-posts-footer no-reply-footer">
        <Box className="main-no-reply">
          <Box className="main-sent-reply">
            <CheckCircleIcon />
            {CheckValueLocale("mark_completed", "", {}, intl)}
          </Box>
          <Typography className="no-reply-class">
            {CheckValueLocale("mark_completed_msg", "", {}, intl)}
          </Typography>
        </Box>
      </Box>
    );
  }

  // Default case: show cannot reply banner
  return (
    <Box className="closed-by-time">
      <Box className="closed-by-time-main-warning">
        <WarningIcon />
        <Box className="closed-by-time-prag">
          <Box className="closed-by-time-title">
            {CheckValueLocale(cannotReplyTitleKey, "", {}, intl)}
          </Box>
          <Box
            className="closed-by-time-msg"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                CheckValueLocale(cannotReplyMsgKey, "", {}, intl),
              ),
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
