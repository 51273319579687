import Services from "./Services";

var Filters = {};

Filters.getSocialMediaFiltersFileds = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/social_media/filters",
    queryData,
  );
};
Filters.getFacebookFiltersFileds = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/social_media/facebook/filters",
    queryData,
  );
};
Filters.getTwitterFiltersFileds = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/social_media/twitter/filters",
    queryData,
  );
};
Filters.getTiktokFiltersFileds = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/social_media/tiktok/filters",
    queryData,
  );
};
Filters.getInstagramFiltersFileds = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/social_media/instagram/filters",
    queryData,
  );
};
Filters.getInsightsFiltersFileds = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/filters/insights",
    queryData,
  );
};
Filters.getInteractionsFiltersFileds = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/filters/interactions",
    queryData,
  );
};

//chat filters
Filters.getChatFiltersFileds = (queryData) => {
  return Services.getData("api/v3/channel_analytics/chat/filters", queryData);
};
Filters.getWhatsappFiltersFileds = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/chat/whatsapp/filters",
    queryData,
  );
};
Filters.getWhatsFiltersFileds = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/chat/whatsapp/interactions/filters",
    queryData,
  );
};
Filters.getIntercomInteractionsFiltersFileds = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/chat/intercom/interactions/filters",
    queryData,
  );
};
Filters.getIntercomFiltersFileds = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/chat/intercom/filters",
    queryData,
  );
};

Filters.getSocialInteractionsFiltersFileds = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/social_media/interactions/filters",
    queryData,
  );
};
Filters.getChatInteractionsFiltersFileds = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/chat/interactions/filters",
    queryData,
  );
};
Filters.getTwitterInteractionsFiltersFileds = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/social_media/twitter/interactions/filters",
    queryData,
  );
};
Filters.getTiktokInteractionsFiltersFileds = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/social_media/tiktok/interactions/filters",
    queryData,
  );
};
Filters.getFacebookInteractionsFiltersFileds = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/social_media/facebook/interactions/filters",
    queryData,
  );
};
Filters.getInstagramInteractionsFiltersFileds = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/social_media/instagram/interactions/filters",
    queryData,
  );
};
Filters.getGmailInteractionsFiltersFileds = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/email/gmail/filters",
    queryData,
  );
};

//email & gmail Filters
Filters.getEmailFiltersFileds = (queryData) => {
  return Services.getData("api/v3/channel_analytics/email/filters", queryData);
};
Filters.getGmailFiltersFileds = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/email/gmail/filters",
    queryData,
  );
};

Filters.getMonitorFiltersFileds = (queryData, source, cancelToken) => {
  return Services.getData(
    `api/v3/social_listening/${source.toLowerCase()}/filters/enabled_filters`,
    queryData,
    undefined,
    cancelToken,
  );
};
Filters.getAllSavedFilters = (queryData , cancelToken) => {
  return Services.getData(
    `api/v3/social_listening/filters/saved_filters`,
    queryData,
    undefined,
    cancelToken,
  );
};

Filters.getAllSavedFiltersRedux = (queryData, source) => {
  return Services.getData(
    `api/v3/social_listening/filters/saved_filters/tracker_filters`,
    queryData,
  );
};

Filters.getGmbFiltersFields = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/ratings/gmb/filters",
    queryData,
  );
};

Filters.getGmbInteractionsFiltersFields = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/ratings/gmb/interactions/filters",
    queryData,
  );
};

Filters.getLinkedInFiltersFileds = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/social_media/linkedin/filters",
    queryData,
  );
};

Filters.getLinkedInInteractionsFiltersFields = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/social_media/linkedin/interactions/filters",
    queryData,
  );
};

Filters.createSavedFilter = (queryData) => {
  return Services.postData(
    `api/v3/social_listening/filters/saved_filters`,
    queryData,
  );
};
Filters.getSavedFilterData = (queryData, saved_filter_id) => {
  return Services.getData(
    `api/v3/social_listening/filters/saved_filters/${saved_filter_id}`,
    queryData,
  );
};
Filters.deleteSavedFilterData = (queryData, saved_filter_id) => {
  return Services.deleteData(
    `api/v3/social_listening/filters/saved_filters/${saved_filter_id}`,
    queryData,
  );
};
Filters.getAudienceFiltersFileds = (queryData) => {
  return Services.getData(`api/v3/cdp/filters`, queryData);
};

Filters.getGenesysFiltersFields = (queryData) => {
  return Services.getData(
    "/api/v3/channel_analytics/calls/genesys/insights/filters",
    queryData,
  );
};

Filters.getEngagementAnalyticsFiltersFields = (queryData) => {
  return Services.getData(
    "api/v5/engagements/analytics/enabled_filters",
    queryData,
  );
};
Filters.getBenchmarkFiltersFileds = (queryData) => {
  return Services.getData(
    `api/v4/utilities/benchmark_filters/filters_data`,
    queryData,
  );
};

export default Filters;
