import { useState } from "react";

// files
import { isPublicSpecialCharactersOrEmojis } from "../../../SharedFunctions/components/specialCharOrEmojis";

import { removeSpecialCharacters } from "../../../SharedFunctions/components/keywordLanguage.js";

import { getTopicsData } from "../../../../createMonitor/createCommonFunctions/getDataCreateMonitor";
import SharedFunctionsKeywords from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/sharedFunctionsKeywords";

import { useTopicsUsage } from "pages/socialListing/MonitorsOptions/hooks/useTopicsUsage";

const TopicsFuntions = () => {
  const [keywords, setKeyword] = useState({});
  const [excludedKeyword, setExcludedKeyword] = useState({});

  const [keywordsFocused, setKeywordsFocused] = useState(false);
  const [excludeKeywordsFocused, setExcludeKeywordsFocused] = useState(false);

  const [isEmptyTopicsArray, setIsEmptyTopicsArray] = useState(false);
  const [isTopicsArrayMaxLimit, setIsTopicsArrayMaxLimit] = useState(false);

  const [showSnackBar, setShowSnackBar] = useState(false);
  const [messageSnackBar, setMessageSnackBar] = useState("");
  const [severitySnackBar, setSeveritySnackBar] = useState("info");

  const [disabledManualTopic, setdisabledManualTopics] = useState(false);
  const [manualTopicStats, setManualTopicsStats] = useState({});
  const [moniotrSelectedDataSources, setMoniotrSelectedDataSources] = useState(
    [],
  );
  //keywords shared
  const { handleValidationKeywords, handlekeywordsShared } =
    SharedFunctionsKeywords();
  const [dataExist, setDataExist] = useState(true);

  const handleSnackBar = (showSnackBar, Severity, MessageSnackBar) => {
    setShowSnackBar(showSnackBar);
    setSeveritySnackBar(Severity);
    setMessageSnackBar(MessageSnackBar);
  };

  //in case of monitor setting
  const handleUpdateParams = (
    value,
    setMonitorTopics,
    updatedParams,
    setUpdatedParams,
  ) => {
    //filtered topics
    let topicsData = getTopicsData(value);
    setMonitorTopics(value);
    setUpdatedParams({
      ...updatedParams,
      topics: topicsData,
    });
  };

  const resetKeywordsErrorMessage = (
    setErrorKewyordsMsg,
    setExErrorKewyordsMsg,
    index,
  ) => {
    setErrorKewyordsMsg({ i: index, errorMsg: "" });
    setExErrorKewyordsMsg({ i: index, errorMsg: "" });
  };

  const addNewTopicCard = (val) => {
    val.push({
      name: "",
      keywords: [],
      exclude_keywords: [],
    });
  };
  // Functions For Topics Index File
  const handleAddTopic = (e, monitorTopics, setMonitorTopics) => {
    let newArr = [...monitorTopics];
    if (newArr.length < 8) {
      addNewTopicCard(newArr);
      setMonitorTopics(newArr);
      setIsTopicsArrayMaxLimit(false);
    } else {
      setIsTopicsArrayMaxLimit(true);
    }
  };

  const handleTopicName = (
    e,
    index,
    monitorTopics,
    setMonitorTopics,
    setDisplayNameMsgError,
    isMonitorSetting,
    updatedParams,
    setUpdatedParams,
  ) => {
    let newArr = [...monitorTopics];
    newArr[index].name = e.target.value;
    let newTopicsArr = [];

    newArr.map((topic, i) => {
      if (i !== index) newTopicsArr.push(topic.name.trim());
    });
    if (newTopicsArr.includes(e.target.value.trim()) && e.target.value !== "") {
      setDisplayNameMsgError({
        i: index,
        errorMsg: "display_name_duplicate_error",
      });
    } else if (
      e.target.value !== "" &&
      (isPublicSpecialCharactersOrEmojis(e.target.value, undefined, true) ||
        removeSpecialCharacters(e.target.value).length === 0)
    ) {
      setDisplayNameMsgError({
        i: index,
        errorMsg: "spam_keywords_rules",
      });
    } else if (isMonitorSetting && e.target.value === "") {
      setDisplayNameMsgError({
        i: index,
        errorMsg: "this_field_required",
      });
    } else {
      setDisplayNameMsgError({ i: index, errorMsg: "" });
      isMonitorSetting &&
        handleUpdateParams(
          newArr,
          setMonitorTopics,
          updatedParams,
          setUpdatedParams,
        );
    }
  };

  const handleKeywordChange = (
    event,
    index,
    keywordType,
    monitorTopics,
    setErrorKewyordsMsg,
    setExErrorKewyordsMsg,
  ) => {
    resetKeywordsErrorMessage(
      setErrorKewyordsMsg,
      setExErrorKewyordsMsg,
      index,
    );
    setKeyword([]);
    setExcludedKeyword([]);
    const { value } = event.target;

    keywordType === "keywords"
      ? setKeyword({ indexNum: index, keyword: value })
      : setExcludedKeyword({ indexNum: index, keyword: value });

    const keywordsCopy = [...monitorTopics];
    let newKeywords = keywordsCopy[index].keywords;
    let newExcludedKeywords = keywordsCopy[index].exclude_keywords;

    let errorMessage = handleValidationKeywords(
      value,
      newKeywords?.length ? newKeywords : [],
      newExcludedKeywords?.length ? newExcludedKeywords : [],
      keywordType === "keywords" ? "keywords" : "spam_keywords",
      setDataExist,
      "twitter",
    );
    keywordType === "keywords"
      ? setErrorKewyordsMsg({ i: index, errorMsg: errorMessage })
      : setExErrorKewyordsMsg({ i: index, errorMsg: errorMessage });
  };

  const handleKeywords = (
    event,
    index,
    keywordType,
    monitorTopics,
    setMonitorTopics,
    clickType,
    setErrorKewyordsMsg,
    setExErrorKewyordsMsg,
    isMonitorSetting,
    updatedParams,
    setUpdatedParams,
  ) => {
    const { value } = event.target;
    if (
      (event.keyCode === 13 || (clickType === "btn" && value)) &&
      dataExist &&
      value.trim() !== ""
    ) {
      const keywordsCopy = [...monitorTopics];

      let objData = handlekeywordsShared(
        value,
        keywordType === "keywords" ? "keywords" : "spam_keywords",
        keywordsCopy[index].keywords?.length
          ? keywordsCopy[index].keywords
          : [],
        keywordsCopy[index].exclude_keywords
          ? keywordsCopy[index].exclude_keywords
          : [],
        "twitter",
        [
          {
            keywords: keywordsCopy[index].keywords?.length
              ? keywordsCopy[index].keywords
              : [],
          },
          {
            spam_keywords: keywordsCopy[index].exclude_keywords?.length
              ? keywordsCopy[index].exclude_keywords
              : [],
          },
        ], //keywords such as [{keywords:[]},{spamKeywords:[]}]
        100,
        undefined, //in case newsblogs,
        undefined,
      );

      if (!objData?.errorMsg) {
        keywordsCopy[index].keywords = [...objData?.keywords];
        keywordsCopy[index].exclude_keywords = [...objData?.spamKeywords];
        resetKeywordsErrorMessage(
          setErrorKewyordsMsg,
          setExErrorKewyordsMsg,
          index,
        );
        if (!objData.exccedKeywordsLimit) {
          setKeyword([]);
          setExcludedKeyword([]);
        }
      } else {
        if (keywordType === "keywords") {
          setErrorKewyordsMsg({
            i: index,
            errorMsg: objData?.errorMsg,
          });
          setKeyword({ indexNum: index, keyword: value });
        } else {
          setExErrorKewyordsMsg({
            i: index,
            errorMsg: objData?.errorMsg,
          });
          setExcludedKeyword({ indexNum: index, keyword: value });
        }
      }

      setMonitorTopics(keywordsCopy);

      isMonitorSetting &&
        handleUpdateParams(
          keywordsCopy,
          setMonitorTopics,
          updatedParams,
          setUpdatedParams,
        );
      if (objData?.count > 100)
        handleSnackBar(
          true,
          "warning",
          "keywords_and_exclude_keywords_count_error",
        );
    }
  };
  const handleDeleteKeyword = (
    i,
    index,
    keywordType,
    monitorTopics,
    setMonitorTopics,
    isMonitorSetting,
    updatedParams,
    setUpdatedParams,
    setErrorKewyordsMsg,
  ) => {
    const keywordsCopy = [...monitorTopics];
    let newKeywords = keywordsCopy[index].keywords;
    let newExcludedKeywords = keywordsCopy[index].exclude_keywords;

    if (keywordType === "keywords") {
      newKeywords.splice(i, 1);
      keywordsCopy[index].keywords = newKeywords;
    } else {
      newExcludedKeywords.splice(i, 1);
      keywordsCopy[index].exclude_keywords = newExcludedKeywords;
    }
    setMonitorTopics(keywordsCopy);

    isMonitorSetting &&
      !newKeywords.length &&
      monitorTopics[index].name &&
      setErrorKewyordsMsg({
        i: index,
        errorMsg: "this_field_required",
      });

    isMonitorSetting &&
      handleUpdateParams(
        keywordsCopy,
        setMonitorTopics,
        updatedParams,
        setUpdatedParams,
      );
  };

  const handleRemoveTopic = (
    event,
    i,
    monitorTopics,
    setMonitorTopics,
    isMonitorSetting,
    updatedParams,
    setUpdatedParams,
  ) => {
    let newArr = [...monitorTopics];

    if (newArr.length > 1) {
      newArr.splice(i, 1);
    } else if (newArr.length <= 1) {
      newArr.splice(i, 1);
      addNewTopicCard(newArr);
      checkManualTopicsEmptyArray(newArr, setMonitorTopics);
    }

    isMonitorSetting &&
      handleUpdateParams(
        newArr,
        setMonitorTopics,
        updatedParams,
        setUpdatedParams,
      );
    setMonitorTopics(newArr);
  };

  const checkManualTopicsEmptyArray = (newArr, setMonitorTopics) => {
    let isEmpty = false;
    if (Array.isArray(newArr) && newArr?.length === 0) {
      addNewTopicCard(newArr);
      setMonitorTopics(newArr);
    } else {
      let arrayhaveOnlyOneElement = newArr?.length === 1;
      newArr.map((topic) => {
        if (
          arrayhaveOnlyOneElement &&
          (topic?.name === "" || topic?.name === undefined) &&
          topic?.keywords.length === 0 &&
          topic?.exclude_keywords.length === 0
        ) {
          isEmpty = true;
        } else {
          isEmpty = false;
        }
      });
    }
    setIsEmptyTopicsArray(isEmpty);
  };

  //check topics fields
  const handleTopicsData = (monitorTopics) => {
    let isValid = true;
    monitorTopics?.map((item) => {
      if (
        (item?.name && !item?.keywords.length) ||
        (!item?.name && item?.keywords.length) ||
        (!item?.name &&
          !item?.keywords.length &&
          item?.exclude_keywords?.length)
      )
        isValid = false;
    });
    return isValid;
  };

  const collectDataSourcesArray = (monitorList) => {
    let newDataSourcesArray = [];

    monitorList.map((monitor) => {
      newDataSourcesArray.push({
        id: monitor.id,
        data_sources: monitor?.attributes?.data_sources,
      });
    });

    setMoniotrSelectedDataSources(newDataSourcesArray);
  };

  const { mutate: getTopicsUsageAction } = useTopicsUsage();

  const handleMonitorTopicsUsage = () => {
    getTopicsUsageAction(undefined, {
      onSuccess: (response) => {
        setManualTopicsStats(response?.data);
      },
      onError: (error) => {
        if (error?.status === 403) {
          setdisabledManualTopics(true);
        }
      },
    });
  };

  return {
    keywords,
    setKeyword,
    excludedKeyword,
    setExcludedKeyword,
    keywordsFocused,
    setKeywordsFocused,
    excludeKeywordsFocused,
    setExcludeKeywordsFocused,
    isEmptyTopicsArray,
    isTopicsArrayMaxLimit,
    handleAddTopic,
    handleTopicName,
    handleKeywordChange,
    handleKeywords,
    handleDeleteKeyword,
    handleRemoveTopic,
    showSnackBar,
    setShowSnackBar,
    messageSnackBar,
    severitySnackBar,
    checkManualTopicsEmptyArray,
    handleTopicsData,
    handleUpdateParams,
    handleMonitorTopicsUsage,
    manualTopicStats,
    disabledManualTopic,
    moniotrSelectedDataSources,
    collectDataSourcesArray,
  };
};
export default TopicsFuntions;
