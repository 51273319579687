import { Box } from "@mui/material";
import SnackBar from "components/snackBar";
import CommonFunctions from "pages/Monitors/Components/commonFunctions";
import TopPosts from "pages/Monitors/Components/topPosts/topPosts";
import "pages/Monitors/monitors.scss";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import PopupModal from "components/popupModal";
import xPlatform from "images/social-icons/x-platform-white-icon.svg";

/*---------------------------------------------------------*/
const PostsBody = (props) => {
  const intl = useIntl();
  let {
    response,
    changePagination,
    monitorData,
    setFilterParams,
    filterParams,
    applyFilter,
    startDate,
    endDate,
  } = props;
  //handle response
  const [topPosts, setTopPosts] = useState([]);
  const [topPostsCount, setTopPostsCount] = useState(null);
  const [page, setPage] = useState(1);

  // Values of DataSources totrack each widget preloader
  const [topTopPostsPreLoaderDataSources, setTopPostsPreLoaderDataSources] =
    useState([]);

  //handle response with useEffect (when load page & any update)
  const handleResponseFunc = async () => {
    if (response.eventName === "Twitter__PostsPage__top_posts_comments") {
      setTopPosts(response.eventData.top_posts);
      // handle case of topPostsCount when it's equal to = 0 and doen't pass the total count in second request with pagination
      if (props.pageNumber == 1) {
        setTopPostsCount(response.eventData.top_posts_count);
        setPage(1);
      }
      setTopPostsPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else {
      return null;
    }
  };
  useEffect(() => {
    handleResponseFunc();
  }, [response]);

  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (props.resetAllFields !== 1) {
      setTopPosts([]);

      // Values of DataSources toTrack each widget preloader
      setTopPostsPreLoaderDataSources([]);
    }
  }, [props.resetAllFields]);
  // ___________________________________________________________________________________________________________________
  const {
    updateAnalysis,
    handleCloseSnackBar,
    showAnalyzeTweetSnackBar,
    analyzeTweetSnackBar,
    stats,
    handleCloseCreateModal,
    modalBody,
  } = CommonFunctions();

  useEffect(() => {
    let totalPreLoaderDataSouces = [];

    totalPreLoaderDataSouces.push(topTopPostsPreLoaderDataSources);

    props.checkAllDataFounded(
      totalPreLoaderDataSouces,
      props.preLoaderTrackerDataSources,
    );

    props.checkDataIsFounded(topPosts);
  }, [topTopPostsPreLoaderDataSources, topPosts]);

  useEffect(() => {
    props?.setTotalPostsCount?.(topPostsCount);
  }, [topPostsCount]);
  const twitterIcon = (
    <span className="topics-twitter-icon">
      <img
        src={xPlatform}
        alt="x-platform-logo"
        className={"icon-after-title twitter-icon twitter-datasource-icon"}
      />
    </span>
  );
  return (
    <Box>
      <TopPosts
        data={topPosts}
        topPostsCount={topPostsCount}
        errorMessage={CheckValueLocale("no_posts_found", "", {}, intl)}
        ShowPagination
        topPostsPageHeader
        changePagination={changePagination}
        topTopPostsPreLoaderDataSources={topTopPostsPreLoaderDataSources}
        preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        setPage={setPage}
        page={page}
        monitorData={monitorData}
        updateAnalysis={updateAnalysis}
        setFilterParams={setFilterParams}
        filterParams={filterParams}
        applyFilter={applyFilter}
        startDate={startDate}
        endDate={endDate}
        iconAfterTitle={twitterIcon}
        singleTweetAnalysis={props.singleTweetAnalysis}
        response={response}
        hideChangeSentiment
      />
      {showAnalyzeTweetSnackBar && (
        <SnackBar
          open={true}
          handleClose={handleCloseSnackBar}
          title={CheckValueLocale(analyzeTweetSnackBar.title, "", {}, intl)}
          message={CheckValueLocale(analyzeTweetSnackBar.message, "", {}, intl)}
          severity={analyzeTweetSnackBar.severity}
        />
      )}
      <PopupModal
        title={CheckValueLocale("single_tweet_analysis", "", {}, intl)}
        body={modalBody}
        open={stats?.openCreateModal}
        close={handleCloseCreateModal}
        warning="warning"
        classeName="create-historical-popup"
        addClasses="create-single-post-analysis-modal"
      />
    </Box>
  );
};

export default PostsBody;
