import * as Yup from "yup";
import { removeSpecialCharacters } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/keywordLanguage";
import { isPublicSpecialCharactersOrEmojis } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/specialCharOrEmojis";

export const useEditReportValidation = () => {
  return Yup?.object({
    title: Yup?.string()
      ?.required("this_field_required")
      ?.max(100, "report_title_max_char_length")
      ?.test(
        "valid-title",
        "spam_keywords_rules",
        (value) =>
          !isPublicSpecialCharactersOrEmojis(value, undefined, true) &&
          !(removeSpecialCharacters(value)?.length === 0),
      ),
    users: Yup?.array()?.min(1, "this_field_required"),
    language: Yup?.string()?.required("this_field_required"),
    frequency: Yup?.string()?.required("this_field_required"),
    report_recurrence_id: Yup?.string()?.required("this_field_required"),
    start_date_time: Yup?.string()?.required("this_field_required"),
  });
};
