import { useState } from "react";
import { Box } from "@mui/material";
import Profile from "./component/profile";
import Hint from "./component/hint";
import Info from "./component/info";
import Themes from "./component/themes";
import SubThemes from "./component/subThemes";
import UserSegments from "./component/userSegments";
import Topics from "./component/topics";
import SocialMedia from "./component/socialMedia";
import Cdp from "pages/engagements/components/detailsSideBar/profileSideBar/component/cdp";
import Header from "./component/header";
import NotSupported from "./component/notSupported";
import { useNavigate, useParams } from "react-router-dom";
import Anonymous from "pages/engagements/components/detailsSideBar/profileSideBar/component/anonymous";
import Details from "./component/details";

const ProfileSideBar = ({
  dataSourceName,
  setOpenProfile,
  selectedCard,
  profileSideBarData,
  isSupported,
  engagementsDetails,
  queryData,
  isXPlatform,
}) => {
  const sideBarProfileData = profileSideBarData?.profile_data;
  const SideBarUserData = profileSideBarData?.user;
  const sideBarSocialMediaData = sideBarProfileData?.social_media;
  const navigate = useNavigate();

  const infoKeys = [
    "gender",
    "dialects",
    "location",
    "sentiment",
    "phone_number",
    "email",
  ];
  const infoArr = sideBarProfileData
    ? infoKeys.filter((key) => sideBarProfileData[key])
    : [];

  const socialMediaArray = sideBarSocialMediaData
    ? Object.keys(sideBarSocialMediaData)?.filter(
        (key) => sideBarSocialMediaData[key]?.length,
      )
    : [];

  if (
    socialMediaArray.includes("fb") &&
    socialMediaArray.includes("fb_dm") &&
    sideBarSocialMediaData["fb"] === sideBarSocialMediaData["fb_dm"]
  ) {
    socialMediaArray.splice(socialMediaArray.indexOf("fb_dm"), 1);
  }
  if (
    socialMediaArray.includes("tw") &&
    socialMediaArray.includes("tw_dm") &&
    sideBarSocialMediaData["tw"] === sideBarSocialMediaData["tw_dm"]
  ) {
    socialMediaArray.splice(socialMediaArray.indexOf("tw_dm"), 1);
  }
  if (
    socialMediaArray.includes("ig") &&
    socialMediaArray.includes("ig_dm") &&
    sideBarSocialMediaData["ig"] === sideBarSocialMediaData["ig_dm"]
  ) {
    socialMediaArray.splice(socialMediaArray.indexOf("ig_dm"), 1);
  }

  let activeCdp = JSON.parse(window.localStorage?.activeProducts).filter(
    (product) => product?.active && product?.name == "CDP",
  );
  const user_name = selectedCard?.screen_name;
  const urlParams = useParams();
  const monitorType = urlParams.monitorType;

  const showTotalEngagementsHandler = () => {
    const monitorId = urlParams?.monitorId;
    let path = "";
    if (monitorType === "ACCOUNT_ANALYSIS") {
      path = `/monitor/${monitorType}/${urlParams?.monitorDataSource}/comments_mentions/${monitorId}`;
    } else if (monitorType === "KEYWORD") {
      path = `/monitor/${monitorType}/${urlParams?.monitorDataSource}/posts/${monitorId}`;
    }

    navigate(path, {
      state: {
        user_name: user_name,
        showUserSnackbar: true,
      },
    });
  };

  const isShowMonitor =
    (monitorType == "ACCOUNT_ANALYSIS" || monitorType == "KEYWORD") &&
    urlParams.dashboardID == undefined;
  const showEngagementsHint =
    isShowMonitor && dataSourceName == "TWITTER" && selectedCard?.screen_name;

  return (
    <>
      {sideBarProfileData?.length !== 0 ? (
        <Box className="details-side-bar-content">
          <Header
            setOpenDetailsSideBar={setOpenProfile}
            dataSourceName={dataSourceName}
          />
          {isSupported ? (
            <>
              <Profile
                profileData={sideBarProfileData?.user_data}
                selectedCard={selectedCard}
              />
              <Hint
                showEngagementsHint={showEngagementsHint}
                showTotalEngagementsHandler={showTotalEngagementsHandler}
                selectedCard={selectedCard}
                isXPlatform={isXPlatform}
              />
              {isXPlatform && engagementsDetails ? (
                <Details
                  showEngagementsHint={showEngagementsHint}
                  showTotalEngagementsHandler={showTotalEngagementsHandler}
                  engagementsDetails={engagementsDetails}
                />
              ) : null}
              {infoArr?.length > 0 ? (
                <Info infoData={sideBarProfileData} infoArr={infoArr} />
              ) : null}
              {sideBarProfileData?.themes ? (
                <Themes themesData={sideBarProfileData?.themes} />
              ) : null}
              {sideBarProfileData?.sub_themes ? (
                <SubThemes subThemesData={sideBarProfileData?.sub_themes} />
              ) : null}
              {sideBarProfileData?.segments ? (
                <UserSegments userSegmentsData={sideBarProfileData?.segments} />
              ) : null}
              {sideBarProfileData?.topics ? (
                <Topics topicsData={sideBarProfileData?.topics} />
              ) : null}
              {sideBarSocialMediaData ? (
                <SocialMedia
                  socialMediaData={sideBarSocialMediaData}
                  socialMediaArray={socialMediaArray}
                />
              ) : null}
              {!activeCdp?.length && profileSideBarData?.is_cdp_data ? (
                <Cdp />
              ) : null}
            </>
          ) : (
            <NotSupported dataSourceName={dataSourceName}></NotSupported>
          )}
        </Box>
      ) : (
        <Anonymous />
      )}
    </>
  );
};
export default ProfileSideBar;
