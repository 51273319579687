import { api } from "./newServices";

const createSavedFilter = (queryData) => {
  return api.post("/api/v3/social_listening/filters/saved_filters", queryData);
};

const getSavedFilterData = ({ queryData, saved_filter_id }) => {
  return api.get(
    `api/v3/social_listening/filters/saved_filters/${saved_filter_id}`,
    queryData,
  );
};

const deleteSavedFilterData = ({ queryData, saved_filter_id }) => {
  return api.delete(
    `api/v3/social_listening/filters/saved_filters/${saved_filter_id}`,
    queryData,
  );
};

const getMonitorFiltersFileds = ({ queryData, source }) => {
  return api.get(
    `api/v3/social_listening/${source.toLowerCase()}/filters/enabled_filters`,
    queryData,
  );
};

const getAllSavedFilters = ({ queryData }) => {
  return api.get("api/v3/social_listening/filters/saved_filters", queryData);
};

export const FiltersServices = {
  createSavedFilter,
  getSavedFilterData,
  deleteSavedFilterData,
  getMonitorFiltersFileds,
  getAllSavedFilters,
};
