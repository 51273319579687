import { useMutation, useQueryClient } from "@tanstack/react-query";
import ThemesController from "services/controllers/themesController";

export const useAddTheme = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ data }) => ThemesController.createNewTheme({ ...data }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["themes"] });
    },
  });
};
