import { Box, Tooltip, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import LucButton from "shared/lucButton/lucButton";
import { CheckValueLocale } from "utils/helpers";

function CustomDatePickerInput({ value, onClick, customStyles }) {
  const intl = useIntl();
  const textRef = useRef();
  const [isTruncated, setIsTruncated] = useState(false);

  // This effect will run only once after the first render
  // it will check if the text is truncated and set the state accordingly
  useEffect(() => {
    if (!textRef.current) return;

    // Store the element in a variable
    const element = textRef.current;

    // Check if observers are supported
    if (
      typeof window === "undefined" ||
      !("ResizeObserver" in window) ||
      !("MutationObserver" in window)
    )
      return;

    const checkTruncation = () => {
      setIsTruncated(element.scrollWidth > element.clientWidth);
    };

    // Initial check
    checkTruncation();

    let mutationObserver;
    let resizeObserver;
    try {
      // Observe resizing
      resizeObserver = new ResizeObserver(checkTruncation);
      resizeObserver.observe(element); // Use the stored element
      // Observe content changes
      mutationObserver = new MutationObserver(checkTruncation);
      mutationObserver.observe(element, {
        childList: true, // Listen to changes in the child nodes
        subtree: true, // Listen to all descendants
        characterData: true, // Listen to changes in text content
      });
    } catch (e) {
      // ignore
    }

    // Cleanup
    return () => {
      try {
        resizeObserver.unobserve(element);
        mutationObserver.disconnect();
      } catch (e) {
        // ignore
      }
    };
  }, []);
  return (
    <Tooltip title={isTruncated ? value : null} arrow placement="bottom">
      <Box className="custom-date-picker-input-wrapper">
        <LucButton
          type={customStyles?.type || "secondary"}
          variant={customStyles?.variant || "outline"}
          size={customStyles?.size || "large"}
          onClick={onClick}
          className={`custom-date-picker-input`}
          style={{
            maxWidth: customStyles?.width || "400px",
            width: customStyles?.fixedWidth,
          }}
          startIcon={customStyles?.startIcon}
          id="custom-date-picker-range-input"
        >
          <Typography
            variant="span"
            className="date-picker-input-value"
            ref={textRef}
          >
            {value ||
              CheckValueLocale(
                "date_picker_select_date_empty_case",
                "",
                {},
                intl,
              )}
          </Typography>
        </LucButton>
      </Box>
    </Tooltip>
  );
}

export default CustomDatePickerInput;
