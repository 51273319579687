import * as Yup from "yup";
import { removeSpecialCharacters } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/keywordLanguage";
import { isPublicSpecialCharactersOrEmojis } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/specialCharOrEmojis";

export const useCreateReportValidation = (activeStep) => {
  const step = Number(activeStep);

  if (step === 0) {
    return Yup?.object({
      title: Yup?.string()
        ?.required("this_field_required")
        ?.max(100, "report_title_max_char_length")
        ?.test(
          "valid-title",
          "spam_keywords_rules",
          (value) =>
            !isPublicSpecialCharactersOrEmojis(value, undefined, true) &&
            !(removeSpecialCharacters(value)?.length === 0),
        ),
      product_id: Yup?.string()?.required("this_field_required"),
    });
  } else if (step === 1) {
    return Yup?.object({
      channel_type: Yup?.string()?.required("this_field_required"),
      report_type_id: Yup?.string()?.test(
        "report_type_id_required",
        "this_field_required",
        (value) => !!value,
      ),
      data_source_id: Yup?.mixed()
        ?.required("this_field_required")
        ?.test("valid-data-source", "this_field_required", (value) => {
          if (Array.isArray(value)) return value?.length > 0;

          return !!value;
        }),
      monitor_list: Yup?.array()?.min(1, "this_field_required"),
    });
  } else {
    return Yup.object({
      users: Yup?.array()?.min(1, "this_field_required"),
      language: Yup?.string()?.required("this_field_required"),
      frequency: Yup?.string()?.required("this_field_required"),
      report_recurrence_id: Yup?.string()?.required("this_field_required"),
      start_date_time: Yup?.string()?.required("this_field_required"),
    });
  }
};
