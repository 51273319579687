import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import DOMPurify from "dompurify";

const StatisticBox = ({ statistic }) => {
  const intl = useIntl();

  return (
    <Box className="statistic-box">
      <Box
        component="p"
        className={`statistic-box-num ${statistic?.type?.replaceAll("_", "-")}-statistic-num`}
      >
        {statistic?.num}
      </Box>
      <Box
        component="p"
        className="statistic-box-name"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            CheckValueLocale(`${statistic?.type}_statistic`, "", {}, intl),
          ),
        }}
      ></Box>
    </Box>
  );
};
export default StatisticBox;
