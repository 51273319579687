import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import LucButton from "shared/lucButton/lucButton";
import { CheckValueLocale } from "utils/helpers";

export const EditEngagerProfileFooter = ({
  handleCancel,
  submitButtonDisabled,
  onSave,
}) => {
  const intl = useIntl();
  return (
    <Box className="edit-engager-profile-footer">
      <LucButton
        onClick={onSave}
        // type="submit"
        disabled={submitButtonDisabled}
        size="small"
      >
        {CheckValueLocale("save", "", {}, intl)}
      </LucButton>
      <LucButton
        type="secondary"
        onClick={handleCancel}
        size="small"
        variant="text"
      >
        {CheckValueLocale("cancel", "", {}, intl)}
      </LucButton>
    </Box>
  );
};
