import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import UserAccount from "services/controllers/userAccountController";
import { CheckValueLocale, classNames, handlelUserRoles } from "utils/helpers";
import DefaultUser from "images/shared-images/user-management-default.svg";
import { Link } from "react-router-dom";
import { ShowMoreButton } from "pages/settings/pages/teamsPage/components/showMoreButton";
import { useShowMore } from "hooks/useShowMore";
import CircularLoading from "components/circularProgress";
import UserAvatar from "shared/unifiedUserImage/userAvatar";
import { iconObjectMapper } from "pages/settings/pages/teamsPage/components/tableCells/productAccessCell";
import CustomizedTooltip, {
  customizedStyledTooltip,
} from "components/customizedTooltip";
import HeaderSectionProfile from "./shared/headerSectionProfile";

const EmptyTeams = () => {
  const intl = useIntl();
  return (
    <Box className="profile-user-empty-teams">
      {CheckValueLocale("my_teams_empty", "", {}, intl)}
    </Box>
  );
};

const TeamCard = ({ team, isCardClickable }) => {
  const intl = useIntl();
  const { name, product, id } = team;
  const users = team?.users?.data || [];
  const icon = iconObjectMapper[product];
  const CardComponent = isCardClickable ? Link : Box;
  return (
    <CardComponent
      className={classNames(
        "profile-teams-card",
        isCardClickable && "profile-teams-card-clickable",
      )}
      to={`/settings/teams/view/${id}`}
    >
      <Box className="profile-teams-card-icon">
        <Tooltip
          title={CheckValueLocale(product, "", {}, intl)}
          placement="top"
          arrow
        >
          <img src={icon} alt={product} />
        </Tooltip>
      </Box>
      <Typography id="profile-teams-card-name">{name}</Typography>
      <Typography id="profile-teams-card-count">
        {CheckValueLocale(
          "my_teams_count",
          "",
          { count: users?.length || 0 },
          intl,
        )}
      </Typography>
      <Box className="profile-team-card-users">
        <>
          {users?.slice(0, 4)?.map((memberObj) => {
            const member = memberObj?.attributes;
            return (
              <Tooltip
                title={member?.name}
                key={member?.id}
                placement="top"
                arrow
              >
                <Box className="teams-table-member" key={member?.id}>
                  <UserAvatar
                    avatarSrc={member?.avatar}
                    fullName={member?.name}
                    sizes={{ ratio: "30px", fontSize: "12px" }}
                  />
                </Box>
              </Tooltip>
            );
          })}
          {users?.length > 4 ? (
            <CustomizedTooltip
              title={users
                ?.slice(4)
                ?.map((memberObj) => memberObj?.attributes?.name)
                .join(", ")}
              placement="top"
              arrow
              styles={customizedStyledTooltip}
              className={users?.length > 53 ? "teams-max-width-tooltip" : ""}
            >
              <Box className="teams-table-member teams-table-member-more">
                +{Math.min(users?.length - 4, 99)}
              </Box>
            </CustomizedTooltip>
          ) : null}
        </>
      </Box>
    </CardComponent>
  );
};

const RenderTeams = ({ teams, setTeams }) => {
  const isCardClickable = handlelUserRoles("GENERAL", "VIEW_SETTING");
  const {
    isSettled,
    setIsSettled,
    setShowedData: setShowedTeams,
    showedData: showedTeams,
    showMore,
    setShowMore,
    isLoading,
    setIsLoading,
    onShowLess,
    nextPage,
    hasPagination,
  } = useShowMore({
    data: teams?.data,
    pagination: teams?.pagination,
  });

  const onShowMore = () => {
    if (isSettled) {
      setShowedTeams(teams?.data);
      setShowMore(false);
      return;
    }
    setIsLoading(true);
    UserAccount.getUserTeams(nextPage).then((res) => {
      if (!res?.errorMsg) {
        const newTeams = res?.data;
        const newTeamsData = [...teams?.data, ...newTeams?.data];
        const nextPage = newTeams?.pagination?.next;
        setTeams({
          data: newTeamsData,
          pagination: newTeams?.pagination,
        });
        setShowedTeams(newTeamsData);
        if (!nextPage) {
          setIsSettled(true);
          setShowMore(false);
        }
      }
      setIsLoading(false);
    });
  };
  return (
    <Box className="profile-user-teams-section">
      <Grid
        className="profile-teams-cards-container"
        container
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 2 }}
      >
        {showedTeams?.map((teamObj) => {
          const team = teamObj?.attributes;
          return (
            <Grid item xs={12} sm={6} key={team?.id}>
              <TeamCard team={team} isCardClickable={isCardClickable} />
            </Grid>
          );
        })}
      </Grid>
      <ShowMoreButton
        show={hasPagination}
        loading={isLoading}
        hasMore={showMore}
        onShowLess={onShowLess}
        onShowMore={onShowMore}
      />
    </Box>
  );
};
export const UserTeams = () => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(true);
  const [teams, setTeams] = useState({ data: [], pagination: {} });
  const getUserTeams = () => {
    setIsLoading(true);
    UserAccount.getUserTeams().then((res) => {
      if (!res.errorMsg) {
        setTeams(res?.data);
      }
      setIsLoading(false);
    });
  };
  useEffect(() => {
    getUserTeams();
  }, []);

  if (isLoading) {
    return (
      <Box className="profile-user-loading-section">
        <CircularLoading />
      </Box>
    );
  }
  return (
    <Box className="profile-user-team-container">
      <HeaderSectionProfile
        titleHeader="my_teams"
        descriptionHeader="my_teams_statement"
      />
      <Box className="profile-user-team-content">
        <Box className="profile-user-teams">
          {teams?.data?.length > 0 ? (
            <RenderTeams teams={teams} setTeams={setTeams} />
          ) : (
            <EmptyTeams />
          )}
        </Box>
      </Box>
    </Box>
  );
};
