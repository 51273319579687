import { Box, CircularProgress, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

export const CustomSourcesLoading = () => {
  const intl = useIntl();
  return (
    <Box className="news-relevant-sources-loading-container">
      <CircularProgress size={20} />
      <Typography className="news-relevant-sources-loading-title">
        {CheckValueLocale("custom_sources_loading_message", "", {}, intl)}
      </Typography>
    </Box>
  );
};
