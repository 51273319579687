import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Container, Alert, Box, Button } from "@mui/material";
import "./style.scss";
import { CheckValueLocale } from "utils/helpers";
import DOMPurify from "dompurify";
import SnackBar from "components/snackBar";
//components

import ExploreHeader from "./components/exploreHeader";
import ExploreFields from "./components/exploreMainFields/exploreFields";
import ExploreFunctions from "./components/exploreFunctions";
import SavedResults from "./components/savedResultsList/savedResults";
import LatestSearchList from "./components/latestSearchList/latestSearchList";
import ExploreLoading from "./components/exploreLoadingPage/exploreLoadingPage";
import ExploreSearch from "./components/exploreSearch/exploreSearch";
import ExploreQuickSearchHeader from "./components/exploreQuickSearchHeader/exploreQuickSearchHeader";
import {
  handleAggregateLegends,
  handleAggregateLineChartMultipleValues,
  handleAggregateTwoBarChart,
} from "utils/helpers/aggregateFunctions";
import _ from "lodash";
import { handleArraySum } from "pages/channelAnalytics/InsightsPage/insightsCommonFunctions";
import { getActiveParams } from "shared/general-filter/activeFilters/activeFilters";

const ExplorePage = () => {
  const intl = useIntl();

  const {
    handleDatasourceChange,
    keywordsFocused,
    setKeywordsFocused,
    keywords,
    setKeywords,
    keywordsErrorMsg,
    setkeywordsErrorMsg,
    selectedDatasource,
    setSelectedDatasource,
    spamKeywords,
    setSpamKeywords,
    spamKeywordsFocused,
    setSpamKeywordsFocused,
    spamkeywordsErrorMsg,
    setSpamkeywordsErrorMsg,
    selectedKeyword,
    setSelectedKeyword,
    selectedSpamKeyword,
    setSelectedSpamKeyword,
    startDate,
    endDate,
    isLoading,
    getExploreData,
    informerExploreData,
    trendtingTopicsData,
    datasourceQuota,
    formFieldErrors,
    setFormFieldErrors,
    setStartDate,
    setEndDate,
    clearAllData,
    isClear,
    setIsClear,
    applyFilter,
    isDatePickerChange,
    setIsDatePickerChange,
    handleAlertLimitReach,
    handleDisbleCreateExplore,
    handleCreateExplore,
    showAlertMessage,
    showSnackBar,
    setShowSnackBar,
    handleCreateMonitor,
    getExploreSavedListData,
    exploreSavedListData,
    isSavedExploreLoading,
    getPostsCount,
    pagination,
    setPagination,
    page,
    setPage,
    statsExploreResults,
    setStatsExploreResults,
    openSnackBarDelete,
    setOpenSnackBarDelete,
    snackBarData,
    selectedSavedItem,
    setSetSelectedItem,
    handleDeleteSavedResult,
    showTooltipText,
    getExploreLatestSearchtData,
    isLatestSearchLoading,
    latestSearchListData,
    handleDiableClearAllExplore,
    setIsResetDate,
    loadingStatus,
    exploreDataCount,
    exploreDate,
    response,
    resetAllFields,
    ///
    pageNumber,
    setPageNumber,
    topPostsPreloader,
    setTopPostsPreloader,
    topPostsCount,
    setTopPostsCount,
    maxData,
    setMaxData,
    totalPage,
    setTotalPage,
    topPosts,
    setTopPosts,
    preLoaderTrackerDataSources,
    exploreId,
    getShowExploreData,
    activeExploreStep,
    setActiveExploreStep,
    setDisableCreateExplore,
    disableCreateExplore,
    handleShowSavedExplore,
    exploreInitialDate,
    getFiltersFileds,
    filterParams,
    setFilterParams,
    filterFields,
    handleClearFilterParams,
    checkDataSourceSelected,
    isFilteredFieldsLoaded,
    resetCalendar,
    dateRangePickerParamsRef,
    startTime,
    endTime,
  } = ExploreFunctions();

  const [netSentiment, setNetSentiment] = useState({
    negative: 0,
    positive: 0,
    neutral: 0,
  });
  const [sentimentAnalysis, setSentimentAnalysis] = useState([]);
  const [isSentimentAnalysisHourlyFormat, setIsSentimentAnalysisHourlyFormat] =
    useState(false);

  const [overViewPreLoaderDataSources, setOverViewPreLoaderDataSources] =
    useState([]);
  const [overview, setOverview] = useState([]);
  const [overviewArray, setOverviewArray] = useState([]);
  const [sentimentAnalysisPreLoader, setSentimentAnalysisPreLoader] = useState(
    [],
  );
  const [noOfDatasources, setNoOfDatasources] = useState([]);
  const [sentimentPieChart, setSentimentPieChart] = useState([]);
  const [sentimentAnalysisCopy, setSentimentAnalysisCopy] = useState([]);
  const [interactionsPreLoader, setInteractionsPreLoader] = useState([]);
  const [interactions, setInteractions] = useState([]);
  const [interactionsLegend, setInteractionsLegend] = useState([]);
  const [isInteractionsHourlyFormat, setIsInteractionsHourlyFormat] =
    useState(false);
  const [topPostsSumCount, setTopPostsSumCount] = useState(0);
  const [assignActiveFilters, setAssignActiveFilters] = useState({});

  // handle response with useEffect (when load page & any update)
  const handleSentimentZeroValObject = (
    valueToCheck,
    updatedValue,
    valueOnWhichCheck,
    isLastCheck,
  ) => {
    valueToCheck?.map((objVal, index) => {
      if (
        objVal?.value === 0 &&
        (valueOnWhichCheck !== undefined
          ? valueOnWhichCheck?.some((el) => el?.name === objVal?.name)
          : true)
      ) {
        let tempDatasourceArr = noOfDatasources;
        if (
          isLastCheck !== undefined &&
          tempDatasourceArr[index] === undefined
        ) {
          tempDatasourceArr.push(0);
          setNoOfDatasources(tempDatasourceArr);
        }
        updatedValue.push(objVal);
      } else {
        let tempDatasourceArr = noOfDatasources;
        if (isLastCheck && tempDatasourceArr[index] !== undefined) {
          tempDatasourceArr[index] = tempDatasourceArr[index] + 1;
          setNoOfDatasources(tempDatasourceArr);
        }
        if (isLastCheck && tempDatasourceArr[index] === undefined) {
          tempDatasourceArr.push(1);
          setNoOfDatasources(tempDatasourceArr);
        }
      }
    });
  };

  const handleNetSentimnet = (netSentiment) => {
    let highest_sentiment = Object.keys(netSentiment)?.reduce(function (a, b) {
      return netSentiment[a] > netSentiment[b] ? a : b;
    });
    // Don't put any widget if it is equals to 0
    if (netSentiment[highest_sentiment] <= 0) {
      highest_sentiment = "none";
    }
    handleArraySum(
      overviewArray,
      overviewResult,
      setOverview,
      false,
      highest_sentiment,
    );
  };
  //when click on explore  gain call this fn on page results
  const resetAllData = () => {
    setOverview([]);
    setSentimentAnalysis([]);
    setIsSentimentAnalysisHourlyFormat(false);
    setSentimentPieChart([]);
    setSentimentAnalysisPreLoader([]);
    setSentimentAnalysisCopy([]);
    setNoOfDatasources([]);
    setOverviewArray([]);
    setNetSentiment({
      negative: 0,
      positive: 0,
      neutral: 0,
    });
    setOverViewPreLoaderDataSources([]);
    // setShowAnalytics(false);
    setInteractionsPreLoader([]);
    setInteractions([]);
    setInteractionsLegend([]);
    setIsInteractionsHourlyFormat(false);
    setPage(1);
    setPageNumber(1);
    setTopPostsPreloader([]);
    setTopPostsCount(0);
    setTopPostsSumCount(0);
    setTopPosts([]);
    //reset data
  };

  const handleApplyFilter = (
    startDate,
    endDate,
    activeFilterParams,
    pageNo,
    isAppliedFilter,
  ) => {
    let selectedFilterParams = !isAppliedFilter
      ? assignActiveFilters
      : activeFilterParams !== undefined
        ? activeFilterParams
        : filterParams;
    setPageNumber(1);
    getShowExploreData(exploreId, 1, "latest_posts", selectedFilterParams);
    setAssignActiveFilters(selectedFilterParams);
    resetAllData();
  };

  useEffect(() => {
    getExploreLatestSearchtData();
  }, []);

  useEffect(() => {
    switch (response?.eventName) {
      case "Talkwalker__ExplorePage__overview":
      case "Twitter__ExplorePage__overview":
        if (
          response?.eventData?.overview &&
          page == 1 &&
          overViewPreLoaderDataSources?.length <
            preLoaderTrackerDataSources?.length
        ) {
          Object.entries(response?.eventData?.overview)?.map((i) => {
            if (i[0] === "net_sentiment") {
              i[1]?.map((sentiment) => {
                if (sentiment.name == "negative") {
                  setNetSentiment((netSentiment) => ({
                    ...netSentiment,
                    negative: netSentiment["negative"] + sentiment.value,
                  }));
                }
                if (sentiment.name == "positive") {
                  setNetSentiment((netSentiment) => ({
                    ...netSentiment,
                    positive: netSentiment["positive"] + sentiment.value,
                  }));
                }
                if (sentiment.name == "neutral") {
                  setNetSentiment((netSentiment) => ({
                    ...netSentiment,
                    neutral: netSentiment["neutral"] + sentiment.value,
                  }));
                }
              });
            } else {
              const condition = i[1] === "None" || i[1] === null || i[1] === 0;
              if (i[0] === "average_waiting_time" && condition) {
                overviewArray.push({ name: i[0], value: 0.0 });
              } else overviewArray.push({ name: i[0], value: i[1] });
            }
          });
          setOverViewPreLoaderDataSources((oldArray) => [
            ...oldArray,
            response?.data_source.toLowerCase(),
          ]);
        }
        break;

      case "Twitter__ExplorePage__sentiment_analysis":
      case "Talkwalker__ExplorePage__sentiment_analysis":
        if (
          page == 1 &&
          sentimentAnalysisPreLoader?.length <
            preLoaderTrackerDataSources?.length
        ) {
          let zeroValArr = {
            negative: [],
            positive: [],
            neutral: [],
          };
          handleSentimentZeroValObject(
            response.eventData.sentiment_overtime?.negative,
            zeroValArr.negative,
          );
          handleSentimentZeroValObject(
            response.eventData.sentiment_overtime?.positive,
            zeroValArr.positive,
            zeroValArr.negative,
          );
          handleSentimentZeroValObject(
            response.eventData.sentiment_overtime?.neutral,
            zeroValArr.neutral,
            zeroValArr.positive,
            true,
          );
          handleAggregateLegends(
            response?.eventData?.sentiment_piechart,
            sentimentPieChart,
          );

          handleAggregateLineChartMultipleValues(
            response?.eventData?.sentiment_overtime,
            sentimentAnalysisCopy,
            setSentimentAnalysisCopy,
          );
          setSentimentAnalysisPreLoader((oldArray) => [
            ...oldArray,
            response?.data_source?.toLowerCase(),
          ]);
          setIsSentimentAnalysisHourlyFormat(
            response?.eventData?.is_24_hours_format,
          );
        }
        break;

      case "Talkwalker__ExplorePage__volume_overtime":
      case "Twitter__ExplorePage__volume_overtime":
        if (
          page == 1 &&
          interactionsPreLoader?.length < preLoaderTrackerDataSources?.length
        ) {
          setInteractions(
            handleAggregateTwoBarChart(
              response.eventData.posts_over_time,
              interactions,
            ),
          );
          let obj = [
            {
              name: response?.data_source,
              value: response?.eventData?.total_posts_count,
            },
          ];
          setInteractionsLegend([...interactionsLegend, ...obj]);
          setInteractionsPreLoader((oldArray) => [
            ...oldArray,
            response.data_source?.toLowerCase(),
          ]);
          setIsInteractionsHourlyFormat(
            response?.eventData?.is_24_hours_format,
          );
        }
        break;

      case "Twitter__ExplorePage__top_posts_comments":
      case "Talkwalker__ExplorePage__top_posts_comments":
        response?.eventData?.top_posts?.map((questions) => {
          questions.datasourceTypePosts = response.data_source.toUpperCase();
          setTopPosts((oldArray) => [...oldArray, questions]);
        });

        if (response?.eventData?.top_posts_count !== undefined) {
          if (response?.eventData?.top_posts_count > topPostsCount) {
            setTopPostsCount(response?.eventData?.top_posts_count);
            setTopPostsSumCount(
              topPostsSumCount + response?.eventData?.top_posts_count,
            );
          }
        }

        setTopPostsPreloader((oldArray) => [
          ...oldArray,
          response.data_source?.toLowerCase(),
        ]);
        break;

      default:
        break;
    }
  }, [response]);
  var overviewResult = [];

  useEffect(() => {
    handleNetSentimnet(netSentiment);
  }, [netSentiment]);

  useEffect(() => {
    if (preLoaderTrackerDataSources?.length)
      if (
        _.isEqual(
          sentimentAnalysisPreLoader?.sort(),
          preLoaderTrackerDataSources?.sort(),
        ) === true ||
        _.isEqual(
          overViewPreLoaderDataSources?.sort(),
          preLoaderTrackerDataSources?.sort(),
        ) === true ||
        _.isEqual(
          interactionsPreLoader?.sort(),
          preLoaderTrackerDataSources?.sort(),
        ) === true ||
        _.isEqual(
          topPostsPreloader?.sort(),
          preLoaderTrackerDataSources?.sort(),
        ) === true
      ) {
        setActiveExploreStep(3);
      }
  }, [
    topPostsPreloader,
    interactionsPreLoader,
    overViewPreLoaderDataSources,
    sentimentAnalysisPreLoader,
  ]);

  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (resetAllFields !== 1) {
      setOverview([]);
      setSentimentAnalysis([]);
      setIsSentimentAnalysisHourlyFormat(false);
      setSentimentPieChart([]);
      setSentimentAnalysisPreLoader([]);
      setSentimentAnalysisCopy([]);
      setNoOfDatasources([]);
      setOverviewArray([]);
      setNetSentiment({
        negative: 0,
        positive: 0,
        neutral: 0,
      });
      setOverViewPreLoaderDataSources([]);
      setInteractionsPreLoader([]);
      setInteractions([]);
      setInteractionsLegend([]);
      setIsInteractionsHourlyFormat(false);
      setPage(1);
      setPageNumber(1);
      setTopPostsPreloader([]);
      setTopPostsCount(0);
      setTopPostsSumCount(0);
      setTopPosts([]);

      exploreId && getShowExploreData(exploreId, pageNumber, "latest_posts");
    }
  }, [intl.locale]);

  useEffect(() => {
    // Avoid Initial State Calling
    if (resetAllFields !== 1) {
      setTopPosts([]);
      setTopPostsPreloader([]);
      if (pageNumber == 1) {
        setTopPostsCount(0);
      }
      setTopPostsSumCount(0);
    }
  }, [resetAllFields]);

  const childProps = {
    keywordsFocused,
    setKeywordsFocused,
    keywords,
    setKeywords,
    keywordsErrorMsg,
    setkeywordsErrorMsg,
    selectedDatasource,
    setSelectedDatasource,
    spamKeywords,
    setSpamKeywords,
    spamKeywordsFocused,
    setSpamKeywordsFocused,
    spamkeywordsErrorMsg,
    setSpamkeywordsErrorMsg,
    selectedKeyword,
    setSelectedKeyword,
    selectedSpamKeyword,
    setSelectedSpamKeyword,
    startDate,
    endDate,
    dateRangePickerParamsRef,
    handleDatasourceChange,
    isLoading,
    getExploreData,
    informerExploreData,
    trendtingTopicsData,
    datasourceQuota,
    formFieldErrors,
    setFormFieldErrors,
    setStartDate,
    setEndDate,
    clearAllData,
    isClear,
    setIsClear,
    applyFilter,
    isDatePickerChange,
    setIsDatePickerChange,
    handleAlertLimitReach,
    handleDisbleCreateExplore,
    handleCreateExplore,
    pageNumber,
    setPageNumber,
    topPostsPreloader,
    setTopPostsPreloader,
    topPostsCount,
    setTopPostsCount,
    maxData,
    setMaxData,
    totalPage,
    setTotalPage,
    topPosts,
    setTopPosts,
    preLoaderTrackerDataSources,
    loadingStatus,
    exploreDataCount,
    exploreDate,
    response,
    resetAllFields,
    handleCreateMonitor,
    getExploreSavedListData,
    exploreSavedListData,
    isSavedExploreLoading,
    getPostsCount,
    pagination,
    setPagination,
    statsExploreResults,
    setStatsExploreResults,
    openSnackBarDelete,
    setOpenSnackBarDelete,
    snackBarData,
    page,
    setPage,
    selectedSavedItem,
    setSetSelectedItem,
    handleDeleteSavedResult,
    showTooltipText,
    getExploreLatestSearchtData,
    isLatestSearchLoading,
    latestSearchListData,
    handleDiableClearAllExplore,
    setIsResetDate,
    exploreId,
    getShowExploreData,
    activeExploreStep,
    //event data
    netSentiment,
    overViewPreLoaderDataSources,
    overview,
    setOverview,
    overviewArray,
    setOverviewArray,
    noOfDatasources,
    setNoOfDatasources,
    sentimentPieChart,
    setSentimentPieChart,
    sentimentAnalysisCopy,
    setSentimentAnalysisCopy,
    interactionsPreLoader,
    setInteractionsPreLoader,
    interactions,
    setInteractions,
    interactionsLegend,
    setInteractionsLegend,
    isInteractionsHourlyFormat,
    setNetSentiment,
    setOverViewPreLoaderDataSources,
    setActiveExploreStep,
    sentimentAnalysisPreLoader,
    setSentimentAnalysisPreLoader,
    setDisableCreateExplore,
    disableCreateExplore,
    sentimentAnalysis,
    setSentimentAnalysis,
    isSentimentAnalysisHourlyFormat,
    resetAllData,
    handleShowSavedExplore,
    exploreInitialDate,
    topPostsSumCount,
    setFilterParams,
    filterParams,
    filterFields,
    getFiltersFileds,
    handleClearFilterParams,
    handleApplyFilter,
    setAssignActiveFilters,
    assignActiveFilters,
    allActiveFilters: getActiveParams(assignActiveFilters, filterFields), //showing active selected filters on top of the page,
    checkDataSourceSelected,
    filterParams,
    isFilteredFieldsLoaded,
    resetCalendar,
    startTime,
    endTime,
  };
  return (
    <>
      {activeExploreStep == 3 ? (
        <ExploreQuickSearchHeader {...childProps} />
      ) : null}
      <Container
        maxWidth="xl"
        className={`explore-container ${
          activeExploreStep == 3 && "explore-results-container"
        }
        ${activeExploreStep == 1 && "explore-initial-container"}`}
      >
        {activeExploreStep == 2 ? <ExploreLoading /> : null}

        {activeExploreStep == 1 ? (
          <>
            <ExploreHeader {...childProps} />
            {handleAlertLimitReach() ? (
              <Alert severity="warning" className="explore-alert">
                <Box
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      CheckValueLocale(handleAlertLimitReach(), "", {}, intl),
                    ),
                  }}
                />
              </Alert>
            ) : null}
          </>
        ) : null}
        {activeExploreStep == 1 || activeExploreStep == 3 ? (
          <ExploreFields {...childProps} />
        ) : null}
        {activeExploreStep == 1 ? (
          <>
            {latestSearchListData?.length ? (
              <LatestSearchList {...childProps} />
            ) : null}
            <SavedResults {...childProps} />
          </>
        ) : null}
        {/* result page  */}
        {activeExploreStep == 3 ? <ExploreSearch {...childProps} /> : null}

        <SnackBar
          open={showSnackBar}
          severity={showAlertMessage?.severity}
          message={CheckValueLocale(showAlertMessage?.message, "", {}, intl)}
          title={showAlertMessage?.title}
          handleClose={() => {
            setShowSnackBar(false);
          }}
        />
      </Container>
    </>
  );
};

export default ExplorePage;
