import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import moment from "moment";
import { faAdd } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography, Box, Zoom, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import {
  calculateUnixTimeZone,
  CheckValueLocale,
  formatTimeZone,
  getSocialIcon,
  handlelUserRoles,
} from "utils/helpers";
import { sortedColumnTypes } from "hooks/useSortableTable";
import SharedTable from "shared/table/sharedTable";
import NoDataFoundProducts from "components/no-Data";
import LucButton from "shared/lucButton/lucButton";
import OptionsDropDown from "./optionsDropDown";
import NewUserAccount from "./newUserAccount";
import SnackBar from "components/snackBar";
import AuthController from "services/controllers/authController";
import InputSearch from "../inputSearch";
import Services from "services/api/Services";

export const getUsersAccountsTableHeader = (intl) => {
  return [
    {
      body: CheckValueLocale("account_identifier", "", {}, intl),
      name: "user_name",
      type: sortedColumnTypes.string,
    },
    {
      body: CheckValueLocale("account_type", "", {}, intl),
      name: "account_type",
      type: sortedColumnTypes.string,
    },
    {
      body: CheckValueLocale("used_in", "", {}, intl),
      name: "all_monitors_count",
      type: sortedColumnTypes.number,
    },
    {
      body: CheckValueLocale("configuration_date", "", {}, intl),
      name: "configuration_date",
      type: sortedColumnTypes.date,
    },
    {
      body: CheckValueLocale("created_by", "", {}, intl),
      name: "created_by",
      type: sortedColumnTypes.string,
    },
    {
      body: CheckValueLocale("status", "", {}, intl),
      name: "status",
      type: sortedColumnTypes.string,
    },
    {
      body: null,
      permission: handlelUserRoles("GENERAL", "DESTROY_COMPANY_AUTH"),
    },
  ];
};

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "rgba(97, 97, 97, 0.9)",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(97, 97, 97, 0.9)",
    fontSize: "12px",
    lineHeight: "14px",
    padding: "4px 8px",
    fontWeight: "500",
  },
}));

const UsersAccountsData = (props) => {
  const {
    usersAccounts,
    isSortableLoading,
    tableHead,
    apps,
    searchText,
    setSearchText,
    searchInput,
    setSearchInput,
    page,
    setPage,
    isNeedsAttentionData,
  } = props;

  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const [tableBody, setTableBody] = useState([]);
  const [tableEmptyBody, setTableEmptyBody] = useState([]);
  const [dialogOpen, setOpenDialog] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: "",
    title: "",
    message: "",
  });
  //filtered company apps
  const getCompanyAppDatasource = (companyApps, platform) => {
    let companyAppDatasource = companyApps?.filter(
      (el) => el?.attributes?.data_source_name === platform,
    );
    return companyAppDatasource?.[0];
  };

  const handleAccountAuth = (platform) => {
    let companyAppDatasource = getCompanyAppDatasource(apps, platform);
    let consumer_key = companyAppDatasource?.attributes.consumer_key,
      oauth2_flow_init_url =
        companyAppDatasource?.attributes?.oauth2_flow_init_url,
      redirect_url = window.location.href;
    AuthController.facebookAuth(
      consumer_key,
      redirect_url,
      window.localStorage.sm_id,
      oauth2_flow_init_url,
    ).then((result) => {
      if (!result?.errorMsg) {
        setSnackBar({
          open: true,
          severity: "error",
          title: "",
          message: CheckValueLocale(
            "auth_user_account_success_msg",
            "",
            {},
            intl,
          ),
        });
      } else {
        setSnackBar({
          open: true,
          severity: "error",
          title: CheckValueLocale(
            "unauth_user_account_error_title",
            "",
            {},
            intl,
          ),
          message: CheckValueLocale(
            "unauth_user_account_error_msg",
            "",
            {},
            intl,
          ),
        });
      }
    });
  };

  const getStatusLocalization = (status) => {
    const account_type = {
      active: CheckValueLocale("active", "", {}, intl),
      deactivated: CheckValueLocale("deactivated", "", {}, intl),
      invited: CheckValueLocale("invited", "", {}, intl),
      "Re-authenticate": CheckValueLocale("Re-authenticate", "", {}, intl),
    };
    return account_type[status];
  };

  const stateCell = (account) => {
    return (
      <>
        {getStatusLocalization(account?.attributes?.status) ? (
          <Box className={"status " + account?.attributes?.status}>
            {getStatusLocalization(account?.attributes?.status)}
          </Box>
        ) : null}
      </>
    );
  };

  const getGmtOffsetDate = (date) => {
    const timeZone = +localStorage?.getItem("companyInfo");
    const newDate = moment(date)
      ?.add(timeZone, "hours")
      ?.format("DD/MM/YYYY hh:mm A")
      ?.split(" ");
    const today = moment()?.format("DD/MM/YYYY");

    return `${newDate?.[0] === today ? CheckValueLocale("today_at", "", {}, intl) : `${newDate?.[0]},`} ${newDate?.[1]} ${CheckValueLocale(`hour_${newDate?.[2]}`, "", {}, intl)}`;
  };

  const getFormattedDate = (date) => {
    const timeZone = +localStorage?.getItem("companyInfo");
    const unixTimestamp = moment(date).unix();
    const adjustedTime = calculateUnixTimeZone(unixTimestamp, {
      timezone: timeZone,
    });
    const { completeFormat } = formatTimeZone(adjustedTime, intl, {}, timeZone); // Extract only the date
    const [datePart, timeWithGMT] = completeFormat.split(", ");
    return { date: datePart, time: timeWithGMT };
  };

  useEffect(() => {
    if (
      window?.location?.href?.includes(
        "/settings/accounts_management/users_accounts?success=1",
      )
    ) {
      setSnackBar({
        open: true,
        severity: "success",
        title: "",
        message: CheckValueLocale(
          "auth_user_account_success_msg",
          "",
          {},
          intl,
        ),
      });
      navigate("/settings/accounts_management/users_accounts");
    } else if (
      window?.location?.href?.includes(
        "/settings/accounts_management/users_accounts?success=0",
      ) ||
      window?.location?.href?.includes(
        "/settings/accounts_management/users_accounts?auth=failed",
      ) ||
      window?.location?.href?.includes(
        "/settings/accounts_management/users_accounts?auth=page_missing",
      )
    ) {
      setSnackBar({
        open: true,
        severity: "error",
        title: CheckValueLocale(
          "unauth_user_account_error_title",
          "",
          {},
          intl,
        ),
        message: CheckValueLocale(
          "unauth_user_account_error_msg",
          "",
          {},
          intl,
        ),
      });
      navigate("/settings/accounts_management/users_accounts");
    }
  }, [window?.location?.href]);

  const showTooltipText = (text, textLength) => {
    return (
      <Box className="report-name-container">
        {text?.length > textLength ? (
          <BootstrapTooltip
            title={text}
            arrow
            TransitionComponent={Zoom}
            placement="top"
          >
            <Box component="span">{text?.substring(0, textLength)}...</Box>
          </BootstrapTooltip>
        ) : (
          <Box component="p" variant="subtitle2">
            {text}
          </Box>
        )}
      </Box>
    );
  };

  const optionsCell = (account, index) => {
    return (
      <OptionsDropDown
        accountIdentifier={account?.attributes?.user_name}
        accountOwner={account?.attributes?.account_info_owner}
        accountId={account?.attributes?.id}
        dataSource={account?.attributes?.account_type}
        monitorsCount={account?.attributes?.all_monitors_count}
        setSnackBar={setSnackBar}
        handleAccountAuth={handleAccountAuth}
      />
    );
  };

  useEffect(() => {
    const tabBody =
      usersAccounts?.length == 0
        ? []
        : usersAccounts?.map((account, index) => {
            return {
              id: index,
              data: [
                {
                  body: showTooltipText(
                    account?.attributes?.user_name
                      ? account?.attributes?.user_name
                      : "—",
                    30,
                  ),
                },
                {
                  body: (
                    <Tooltip
                      id="dashed-tooltip-box"
                      arrow
                      placement="top"
                      title={
                        account?.attributes?.related_accounts_list?.length ? (
                          <Box component={"span"}>
                            {CheckValueLocale("have_pages", "", {}, intl)}:{" "}
                            <br />
                            {account?.attributes?.related_accounts_list?.join(
                              ", ",
                            )}
                          </Box>
                        ) : (
                          ""
                        )
                      }
                    >
                      <Box className="account-type-cell">
                        {getSocialIcon(account?.attributes?.account_type)}
                        {account?.attributes?.account_type ? (
                          <Typography
                            className={`dashed-tooltip-item ${
                              account?.attributes?.account_type !== "TIKTOK"
                                ? "company-account-type"
                                : ""
                            }`}
                          >
                            {CheckValueLocale(
                              account?.attributes?.account_type?.toLowerCase(),
                              "",
                              {},
                              intl,
                            )}
                          </Typography>
                        ) : (
                          "—"
                        )}
                      </Box>
                    </Tooltip>
                  ),
                  style: { verticalAlign: "middle" },
                },
                {
                  body: (
                    <Tooltip
                      arrow
                      placement="top"
                      title={
                        account?.attributes?.used_in_which_monitors?.length ? (
                          <Box component={"span"}>
                            {CheckValueLocale("used_in", "", {}, intl)}: <br />
                            {account?.attributes?.used_in_which_monitors?.join(
                              ", ",
                            )}
                          </Box>
                        ) : (
                          ""
                        )
                      }
                      id="dashed-tooltip-box"
                    >
                      <Box component={"span"} className="dashed-tooltip-item">
                        {account?.attributes?.used_in +
                          " " +
                          CheckValueLocale("monitors_name", "", {}, intl)}
                      </Box>
                    </Tooltip>
                  ),
                },
                {
                  body: (
                    <Box component={"span"}>
                      {
                        getFormattedDate(
                          account?.attributes?.configuration_date,
                        ).date
                      }
                      <br />
                      <Typography
                        variant="span"
                        className="time-with-gmt-table"
                      >
                        {
                          getFormattedDate(
                            account?.attributes?.configuration_date,
                          ).time
                        }
                      </Typography>
                    </Box>
                  ),
                },
                {
                  body: account?.attributes?.created_by
                    ? account?.attributes?.created_by
                    : "—",
                  isCreatedBy: true,
                },
                { body: stateCell(account) },
                {
                  body: optionsCell(account, index),
                  permission: handlelUserRoles(
                    "GENERAL",
                    "DESTROY_COMPANY_AUTH",
                  ),
                },
              ],
            };
          });
    setTableBody(tabBody);
    setTableEmptyBody(emptyBody(usersAccounts));
  }, [usersAccounts, intl?.locale]);

  const emptyBody = (audienceData) => {
    return (
      <>
        {usersAccounts?.length == 0 ? (
          <Box className="no-data-company-info">
            <NoDataFoundProducts
              image={isNeedsAttentionData ? "noDataImg" : "companyInfo"}
              title={
                isNeedsAttentionData
                  ? "account_not_found"
                  : "create_authenticated_account"
              }
              description={
                isNeedsAttentionData
                  ? "user_account_not_found_desc"
                  : "empty_users_accounts_table_description"
              }
              linkTwo={
                isNeedsAttentionData
                  ? ""
                  : Services.showThirdCompany
                    ? "knowledge_base"
                    : ""
              }
              needsAttentionUserName={
                location?.state?.needsAttentionData?.search_account_name
                  ? location?.state?.needsAttentionData?.search_account_name
                  : location?.state?.needsAttentionData?.search
              }
            />
          </Box>
        ) : null}
      </>
    );
  };

  const showSearchInput = usersAccounts?.length > 0 || searchInput;

  return (
    <Box id="users-accounts-container">
      <Box
        className={`tab-header-wrapper ${showSearchInput ? "" : "no-search"}`}
      >
        <Box className="desc-search-wrapper">
          <Typography className="description">
            {CheckValueLocale("users_accounts_description", "", {}, intl)}
          </Typography>
          {showSearchInput ? (
            <Box className="search-add-new-account">
              <InputSearch
                searchText={searchText}
                setSearchText={setSearchText}
                setValidationError={setValidationError}
                validationError={validationError}
                setSearchInput={setSearchInput}
                setPage={setPage}
                page={page}
                navigate={navigate}
                location={location}
              />
            </Box>
          ) : null}
        </Box>
        <LucButton
          id="settings-new-user-account-btn"
          startIcon={<FontAwesomeIcon size="small" icon={faAdd} />}
          onClick={() => setOpenDialog(true)}
          disabled={!handlelUserRoles("GENERAL", "VIEW_OTHERS_AUTH")}
        >
          {CheckValueLocale("user_account", "", {}, intl)}
        </LucButton>
      </Box>
      <SharedTable
        tableHeader={tableHead}
        className={" "}
        isSharedStyle
        tableAriaLabel="customized table"
        tableBody={tableBody}
        loading={isSortableLoading}
        emptyBody={tableEmptyBody}
      />
      <NewUserAccount
        dialogOpen={dialogOpen}
        setOpenDialog={setOpenDialog}
        handleAccountAuth={handleAccountAuth}
      />
      {snackBar?.open ? (
        <SnackBar
          autoHideDuration={5000}
          message={snackBar?.message}
          title={snackBar?.title}
          open={snackBar?.open}
          severity={snackBar?.severity}
          handleClose={() =>
            setSnackBar({ open: false, title: "", message: "" })
          }
        />
      ) : null}
    </Box>
  );
};

export default UsersAccountsData;
