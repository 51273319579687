import { Container, Box } from "@mui/material";
import { memo, useContext, useEffect } from "react";
import { useIntl } from "react-intl";
import { useLocation, useParams } from "react-router-dom";
import { CheckValueLocale } from "utils/helpers";
import LinkReports from "./headerNewsLetter/linkReports";
import HeaderTitleAndExport from "./headerNewsLetter/headerTitleAndExport";
import { ReportContext } from "../context/reportContext";
import StatisticReport from "./statisticReport/statisticReport";
import TabSwiperMonitors from "./tabSwiperMonitors/tabSwiperMonitors";
import LucButton from "shared/lucButton/lucButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";
import ArticlesCard from "./articlesCard";
import { useGetNewsLetterReport } from "../hooks/useGetNewsLetterReport";
import CircularLoading from "components/circularProgress";
import SnackBar from "components/snackBar";
import NoDataFoundProducts from "components/no-Data";
import NotFoundPage from "pages/not-found/NotFoundPage";

const NewsLetterPage = () => {
  const intl = useIntl();
  const {
    setReportData,
    activeMonitor,
    snackBar,
    setSnackBar,
    handleSnackBarError,
  } = useContext(ReportContext);
  const { articles } = activeMonitor;
  const { id } = useParams();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const reportDataId = searchParams.get("report_data_id");

  // to get report details for this reportDataId
  const {
    data: newsLetterReportData,
    isLoading,
    isError,
    error,
  } = useGetNewsLetterReport(reportDataId);

  useEffect(() => {
    if (isError) {
      if (error?.errorMsg?.status !== 404)
        handleSnackBarError("something_went_wrong", "try_again");
    }
    if (newsLetterReportData) {
      //to handle report name and date for create for used in "LinkReports" and "HeaderTitleAndExport"
      setReportData({
        ...newsLetterReportData?.report,
        id: id,
        articlesCount: newsLetterReport?.number_of_news,
        reportType: newsLetterReport?.report_type,
        startDate: newsLetterReport?.start_date,
        endDate: newsLetterReport?.end_date,
        isUnixDate: newsLetterReportData?.is_v2, // isUnixDate to handle date by nuix in version 2
      });
    }
  }, [isError, newsLetterReportData]);

  //newsLetterReportData?.statistics is object of all monitors in this report
  const newsLetterReport = newsLetterReportData?.statistics || {};

  return (
    <Container maxWidth="xl" id="newsletter-report">
      {isLoading ? (
        <CircularLoading />
      ) : (
        <>
          {!isError ? (
            <>
              {/* Header Report */}
              <LinkReports />

              <HeaderTitleAndExport />

              {/* Statistic report cards */}
              <StatisticReport />

              {/* to show no resault found when no have articles */}
              {newsLetterReport?.number_of_news > 0 ? (
                <>
                  {/* show monitor tabs */}
                  <TabSwiperMonitors
                    monitorsList={newsLetterReport?.sections}
                  />

                  {/* articles for specific monitor */}
                  <ArticlesCard />

                  {/* button to redirect specific monitor */}
                  {activeMonitor?.count_articles > articles?.length ? (
                    <Box className="open-in-monitor-btn-newsletter">
                      <LucButton
                        onClick={() => {
                          const url = `/monitor/KEYWORD/NEWSBLOGS/posts/${activeMonitor?.id}`;
                          window.open(`${url}`, "_blank");
                        }}
                        id={"open-in-monitor-btn-newsletter"}
                        endIcon={<FontAwesomeIcon icon={faExternalLink} />}
                      >
                        {CheckValueLocale("open_in_monitor_btn", "", {}, intl)}
                      </LucButton>
                    </Box>
                  ) : null}
                </>
              ) : (
                <Box className="repport-empty-case">
                  <NoDataFoundProducts
                    image="Monitor"
                    title="no_results_found"
                    description="no_articles_found_description"
                  />
                </Box>
              )}
            </>
          ) : error?.errorMsg?.status === 404 ? (
            <NotFoundPage />
          ) : (
            <Box className="view-report-no-data-container repport-empty-case">
              <NoDataFoundProducts
                image="Monitor"
                title="no_results_found"
                description="no_articles_found_description"
              />
            </Box>
          )}
        </>
      )}

      {/* please used "handleSnackBarError" or "handleSnackBarSuccess" form "ReportContext" file  to add any snackBar in NewsLetterPage  */}
      <SnackBar
        message={CheckValueLocale(snackBar?.msg, "", {}, intl)}
        title={CheckValueLocale(snackBar?.title, "", {}, intl)}
        open={snackBar?.open}
        severity={snackBar?.severity}
        handleClose={() => setSnackBar({ open: false, title: "", msg: "" })}
      />
    </Container>
  );
};

export default memo(NewsLetterPage);
