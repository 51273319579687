import { Box, Button } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import "./greetings.scss";
import Services from "services/api/Services";

const Greetings = ({
  pageSection,
  monitorName,
  productName,
  setShowHeaderFilters,
  isInactive,
  isChannelAnalytics,
  overAllChannels,
  channelAnayticsName,
  isBenchmark,
}) => {
  const intl = useIntl();
  const userName = localStorage.getItem("name");
  const time = new Date();
  const checkTimeAMPM = time.toLocaleString("en-US", {
    hour: "numeric",
    hour12: true,
  });

  return (
    <Box
      className={!isInactive ? "gretting-wrapper" : "gretting-wrapper-inactive"}
    >
      <Box className="gretting-logo">
        <img src={Services?.aiProductLogo} />
      </Box>
      <Box className="gretting-header">
        <Box className="gretting-hello">
          {CheckValueLocale(
            checkTimeAMPM.includes("AM")
              ? "luci_good_morning"
              : "luci_good_evening",
            "",
            {},
            intl,
          )}
          , {userName}
          <Box className="gretting-hand">👋</Box>
        </Box>
        {!isInactive ? (
          <Box className="getting-details">
            {CheckValueLocale("luci_glad_reached_out", "", {}, intl)}
            <Box component="span">
              {CheckValueLocale(
                isBenchmark
                  ? "benchmark"
                  : channelAnayticsName
                    ? channelAnayticsName
                    : pageSection?.toLowerCase(),
                "",
                {},
                intl,
              )}
            </Box>
            <Box component="span" className="luci-data-from">
              {CheckValueLocale(
                isBenchmark ? "luci_data_from" : "luci_data_from_your",
                "",
                {},
                intl,
              )}
            </Box>
            <Box component="span">
              {isChannelAnalytics || isBenchmark
                ? CheckValueLocale(
                    overAllChannels ? overAllChannels : monitorName,
                    "",
                    {},
                    intl,
                  )
                : monitorName}
            </Box>
            {isBenchmark
              ? null
              : CheckValueLocale("luci_in_word", "", {}, intl)}
            <Box component="span">
              {CheckValueLocale(productName?.toLowerCase(), "", {}, intl)}
            </Box>
            {CheckValueLocale("luci_using_the", "", {}, intl)}
            <Box
              component="span"
              className="luci-filter-applied"
              onClick={() => setShowHeaderFilters(true)}
            >
              {CheckValueLocale(
                isBenchmark ? "benchmark_criteria" : "luci_filter_applied",
                "",
                {},
                intl,
              )}
            </Box>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default Greetings;
