import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField, Box, Typography } from "@mui/material";
import LucButton from "shared/lucButton/lucButton";
import TagColorPicker from "./tagColorPicker";
import engagementTagsFunction from "../engagementTagsFunction";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";
import { uesEditTag } from "../hooks/uesEditTag";
import { useGetColorTags } from "../hooks/useGetColorTags";
import { useAddTag } from "../hooks/useAddTag";

// Validation schema for tag input
const tagValidationSchema = () =>
  Yup.object({
    tagName: Yup.string()
      .trim()
      .matches(
        /^(?![@'#^_() ]+$)(?!^[, ]+$)[\w\u0600-\u06FF@'#^_(), ]+$/,
        "tag_name_Tag_special_characters",
      )
      .max(20, "tag_name_Tag_characters_limit")
      .required(""),
  });

const AddTagBody = (props) => {
  const {
    loading,
    setLoading,
    selectedColor,
    setSelectedColor,
    tagName,
    setTagName,
    editTagData,
    setSnackBar,
    handleClose,
    setSnackBarTagsTable,
    tagsTableData,
    handleOpenColorPicker,
    colorPickerAnchor,
    popupRef,
    setColorPickerAnchor,
  } = props;
  const intl = useIntl();
  const { applyOpacity } = engagementTagsFunction();
  const { mutate: AddNewTag, isPending: addLoading } = useAddTag();
  const { mutate: Getcolors } = useGetColorTags();
  const { mutate: UpDateTag, isPending: loadingUpdat } = uesEditTag();
  const [colorsTags, setColorsTags] = useState([]);

  // Formik form management
  const formik = useFormik({
    initialValues: {
      tagName: editTagData ? editTagData.label : "",
    },
    validationSchema: tagValidationSchema(),
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values) => {
      setTagName(values?.tagName);
    },
  });
  // isDuplicateTag function: Checks if the entered tag name already exists in the list (excluding the original name when editing
  const isDuplicateTag = () =>
    tagsTableData?.engagement_tags?.some(
      ({ label }) =>
        label?.toLowerCase() === formik?.values?.tagName?.trim()?.toLowerCase(),
    );

  // Fun to add a new tag
  const handleAddNewTag = () => {
    setLoading(true);
    const requestData = {
      label: tagName,
      color: selectedColor,
    };
    AddNewTag(requestData, {
      onSuccess: () => {
        handleClose();
        setLoading(false);
        setSnackBar({
          open: true,
          message: "created_tag_successfully",
          severity: "success",
          tagName: tagName,
        });
      },
      onError: (error) => {
        if (
          error?.status === 409 &&
          error?.response?.data?.error?.detail == "TAG_NAME_ALREADY_EXIST"
        ) {
          formik.setErrors({ tagName: "tag_name_already_exists" });
        } else if (
          error?.status == 403 &&
          error?.response?.data?.error?.detail == "TAGS_LIMIT_EXCEED"
        ) {
          setSnackBar({
            open: true,
            title: "tag_creation_failed",
            message: "tag_creation_failed_msg",
            severity: "error",
          });
        } else {
          setSnackBar({
            open: true,
            title: "failed_error_message",
            message: "try_again_error_message",
            severity: "error",
          });
        }
      },
      onSettled: () => {
        setLoading(false);
      },
    });
    setTimeout(() => {
      setSnackBar({
        open: false,
        title: "",
        message: "",
        severity: "",
      });
    }, 4000);
  };

  // Fun to get available color tags
  const handleGetColorTags = () => {
    setLoading(true);
    const requestData = {};
    Getcolors(requestData, {
      onSuccess: (res) => {
        setColorsTags(res?.data?.data);
      },
      onSettled: () => {
        setLoading(false);
      },
    });
  };
  useEffect(() => {
    handleGetColorTags();
  }, []);
  useEffect(() => {
    if (editTagData) {
      setSelectedColor(editTagData?.color);
    }
  }, [editTagData]);

  // Fun to update an existing tag
  const handleEditTag = () => {
    const requestData = {
      data: {
        label: formik?.values?.tagName,
        color: selectedColor,
      },
      id: editTagData?.id,
    };

    UpDateTag(requestData, {
      onSuccess: () => {
        handleClose();
        setSnackBarTagsTable({
          open: true,
          message: "edited_tag_successfully",
          severity: "success",
          tagName: tagName,
        });
      },
      onSettled: () => {
        handleClose();
      },
      onError: () => {
        setSnackBarTagsTable({
          open: true,
          title: "failed_error_message",
          message: "try_again_error_message",
          severity: "error",
        });
      },
    });
    setTimeout(() => {
      setSnackBarTagsTable({
        open: false,
        title: "",
        message: "",
        severity: "",
      });
    }, 4000);
  };

  // Fun to create or edit a tag based on user input
  const handleCreateOrEditTag = () => {
    formik?.handleSubmit;
    if (editTagData) {
      handleEditTag();
    } else handleAddNewTag();
  };

  return (
    <Box className="add-tag-bady" ref={popupRef}>
      <Typography component={"h4"} className="add-tag-title">
        {CheckValueLocale(editTagData ? "edit_tag" : "new_tag", "", {}, intl)}
      </Typography>

      <TextField
        fullWidth
        id="tagName"
        name="tagName"
        value={formik.values.tagName}
        placeholder={CheckValueLocale("name", "", {}, intl)}
        onChange={(e) => {
          formik.handleChange(e);
          setTagName(e.target.value);
        }}
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
        onBlur={formik.handleBlur}
        error={Boolean(formik?.errors?.tagName)}
        helperText={CheckValueLocale(formik?.errors?.tagName, "", {}, intl)}
      />
      {loading ? (
        <Box className="loading-box">
          <FontAwesomeIcon icon={faSpinner} />
        </Box>
      ) : (
        <TagColorPicker
          applyOpacity={applyOpacity}
          selectedColor={selectedColor}
          setSelectedColor={setSelectedColor}
          tagName={tagName}
          colorsTags={colorsTags}
          editTagData={editTagData}
          handleOpenColorPicker={handleOpenColorPicker}
          colorPickerAnchor={colorPickerAnchor}
          handleClose={handleClose}
          setColorPickerAnchor={setColorPickerAnchor}
        />
      )}
      <LucButton
        id="creat-tag-name"
        type="primary"
        variant="filled"
        size="small"
        loading={editTagData ? loadingUpdat : addLoading}
        disabled={
          loading ||
          (isDuplicateTag() && selectedColor === editTagData?.color) ||
          !formik?.isValid
        }
        onClick={() => {
          handleCreateOrEditTag();
        }}
      >
        {CheckValueLocale(editTagData ? "save" : "create", "", {}, intl)}
      </LucButton>
    </Box>
  );
};

export default AddTagBody;
