import React, { useEffect } from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import ConstraintsSection from "../ConstraintsSection/constraintsSection";
import DisaplySettingsTab from "./displaySettingsTab/displaySettingsTab";
import "./style.scss";

const SurveySettingsSidebar = (props) => {
  const {
    settingsTab,
    setSettingsTab,
    handleChangeSettingsTab,
    surveyData,
    setSurveyData,
    savedSurveyData,
    isEmbedded,
    filteredSections,
    surveySections,
    setSurveySections,
    sqIndex,
    ssIndex,
    selectedCountryCode,
    setSelectedCountryCode,
    embeddedOptions,
    embeddedFonts,
  } = props;

  const intl = useIntl();

  // To set active default tab as Display tab for embedded survey and Question tab for others
  useEffect(() => {
    if (isEmbedded) setSettingsTab("display");
    else setSettingsTab("question");
  }, [isEmbedded]);

  return (
    <Box className="survey-builder-settings-sidebar-wrapper">
      <Box className="survey-builder-settings-tabs">
        <TabContext value={settingsTab}>
          <TabList
            onChange={handleChangeSettingsTab}
            id="survey-settings-sidebar-tabs-list"
            className={`survey-settings-sidebar-tabs-list ${isEmbedded ? "" : "not-embedded"}`}
          >
            {isEmbedded ? (
              <Tab
                className="survey-settings-sidebar-tabs-btn"
                label={CheckValueLocale(
                  "survey_display_settings",
                  "",
                  {},
                  intl,
                )}
                value={"display"}
                data-testid="display"
              />
            ) : null}
            <Tab
              className="survey-settings-sidebar-tabs-btn"
              label={CheckValueLocale("survey_question_settings", "", {}, intl)}
              value={"question"}
              disabled={!isEmbedded}
              data-testid="question"
            />
          </TabList>
          {isEmbedded ? (
            <TabPanel
              id="survey-settings-sidebar-content-wrapper"
              value="display"
            >
              <DisaplySettingsTab
                surveyData={surveyData}
                setSurveyData={setSurveyData}
                savedSurveyData={savedSurveyData}
                embeddedOptions={embeddedOptions}
                embeddedFonts={embeddedFonts}
              />
            </TabPanel>
          ) : null}
          <TabPanel
            id="survey-settings-sidebar-content-wrapper"
            value="question"
          >
            <ConstraintsSection
              filteredSections={filteredSections}
              surveySections={surveySections}
              setSurveySections={setSurveySections}
              sqIndex={sqIndex}
              ssIndex={ssIndex}
              selectedCountryCode={selectedCountryCode}
              setSelectedCountryCode={setSelectedCountryCode}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
};

export default SurveySettingsSidebar;
