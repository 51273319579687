import "./tableWidget.scss";
import { TableCell, TableRow, Tooltip } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale, isArabic } from "utils/helpers";
import lexisnexis from "../../../images/lexisnexis/lexisnexis.svg";

const WordsList = (props) => {
  const licensesExist = props?.row?.licenses && props?.row?.licenses?.length;
  const showlexisnexisLicenses = licensesExist
    ? typeof props?.row?.licenses[0] === "string"
      ? JSON.parse(props?.row?.licenses[0])?.some(
          (item) => item?.name === "LexisNexis Licensed",
        )
      : null
    : null;

  const intl = useIntl();
  return (
    <TableRow key={props.row.id} className="tr-bg">
      {props?.isSlasAgents ? (
        <>
          {Object.entries(props?.row)?.map(([key, value]) => (
            <TableCell
              key={key}
              className={`custom-row-style ${props?.index + 1 === props?.rowsLength ? "last-row" : ""}`}
            >
              {key === "type" ? CheckValueLocale(value, "", {}, intl) : value}
            </TableCell>
          ))}
        </>
      ) : (
        <>
          <TableCell className="text-row">
            {showlexisnexisLicenses && (
              <Tooltip
                title={CheckValueLocale(
                  "licensed_content_lexisNexis",
                  "",
                  {},
                  intl,
                )}
                arrow
                placement="top"
              >
                <img
                  className="lexisnexis-img"
                  src={lexisnexis}
                  alt="lexisnexis"
                />
              </Tooltip>
            )}
            {/* this case for keyword name in [social -> CA ] only */}
            {props?.socialKeywordName &&
              (props?.isGensys ? (
                <div>
                  {props?.iconBeforeItemTitle && props?.iconBeforeItemTitle}
                  <span className="rowid-name" dir="ltr">
                    <TooltipMaxWidth title={props.row?.name} />
                  </span>
                </div>
              ) : (
                <a className="rowid-name">
                  <TooltipMaxWidth title={props.row?.name} />
                </a>
              ))}

            {(props.activeTab === "engagements" &&
              props.monitorDataSource === "TALKWALKER") ||
            props.monitorDataSource === "NEWSBLOGS" ||
            (props?.isCustomDashboard && props?.links) ? (
              <a
                href={props.row.id}
                target="_blank"
                rel="noreferrer"
                className="rowid-name"
              >
                <TooltipMaxWidth title={props.row?.id} />
              </a>
            ) : props.handleOpenQuickInspect ? (
              <Tooltip
                title={CheckValueLocale("click_for_details", "", {}, intl)}
                placement="top"
                arrow
              >
                <span
                  className={
                    props.dynamicClass
                      ? `rowid-name ${props.dynamicClass}`
                      : (props.activeTab === "engagements" &&
                            props.monitorDataSource === "FACEBOOK") ||
                          (props.activeTab === "engagements" &&
                            props.monitorDataSource === "INSTAGRAM")
                        ? ""
                        : props.dataSource === "googlemybusiness" &&
                            !props.dynamicClass
                          ? ""
                          : "rowid-name"
                  } //add a dynamic class for quick-inspect-page
                  name={props.row.id}
                  onClick={(e) =>
                    props.inspectorName &&
                    props.handleOpenQuickInspect(
                      props.showAtIcon ? `@${props.row?.id}` : props.row?.id, // to get the full text not the cropped one
                      props.inspectorName,
                    )
                  }
                >
                  {props.showAtIcon && "@"}
                  {props.row.id?.length > 25
                    ? `${props.row.id?.substring(0, 25)}...`
                    : props.row.id}
                </span>
              </Tooltip>
            ) : (
              <span
                className={
                  props.dynamicClass
                    ? `rowid-name ${props.dynamicClass}`
                    : (props.activeTab === "engagements" &&
                          props.monitorDataSource === "FACEBOOK") ||
                        (props.activeTab === "engagements" &&
                          props.monitorDataSource === "INSTAGRAM")
                      ? ""
                      : props.dataSource === "googlemybusiness" &&
                          !props.dynamicClass
                        ? ""
                        : "rowid-name"
                } //add a dynamic class for quick-inspect-page
                name={props.row.id}
                onClick={(e) =>
                  props.inspectorName &&
                  props.handleOpenQuickInspect(
                    props.showAtIcon ? `@${props.row?.id}` : props.row?.id, // to get the full text not the cropped one
                    props.inspectorName,
                  )
                }
              >
                <TooltipMaxWidth
                  title={
                    props.showAtIcon ? `@${props?.row?.id}` : props?.row?.id
                  }
                />
              </span>
            )}
          </TableCell>
          <TableCell
            sx={{
              textAlign: "initial",
            }}
          >
            {parseInt(
              props?.row?.stats_count
                ? props?.row?.stats_count
                : props?.row?.value,
            )?.toLocaleString()}
          </TableCell>
        </>
      )}
    </TableRow>
  );
};
export default WordsList;

// truncate text and add tooltip if the text is too long
const TooltipMaxWidth = ({ maxChars = 25, title }) => {
  if (title?.length > maxChars) {
    const truncatedTitle = isArabic(title)
      ? `...${title?.substring(0, 25)}`
      : `${title?.substring(0, 25)}...`;
    return (
      <Tooltip title={title} arrow placement="top">
        {truncatedTitle}
      </Tooltip>
    );
  }
  return title;
};
