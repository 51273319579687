import { Box, Divider, Grid } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import AiAgentsFields from "../aiAgentsFields";
import AgentIdentityResponseLength from "./agentIdentityResponseLength";
import AgentIdentityToneOfVoiceStyle from "./agentIdentityToneOfVoiceStyle";
import AgentEmojiFrequency from "./agentEmojiFrequency";
import AgentAdvancedSettings from "./agentAdvancedSettings";
import { useCountries } from "pages/engagements/components/aiAgents/aiAgentsHooks/useCountries";
import { dialectsData } from "./dialectsData";

const AgentIdentity = ({
  formik,
  aiAgentshandleChange,
  isFormDisabled,
  customHandleChange,
}) => {
  const intl = useIntl();
  const isAr = intl.locale === "ar";
  const { data: countriesData } = useCountries();
  const countries = countriesData?.data?.countries || [];

  const countriesOptions = countries?.map((country) => ({
    name: isAr ? country?.arabic_name : country?.name,
    id: country?.name,
  }));

  return (
    <Box className="ai-agents-form-box-wrapper">
      <Box className="ai-agents-form-box-title" component="h4">
        {CheckValueLocale("ai_agent_identity", "", {}, intl)}
      </Box>
      <Box className="ai-agents-form-box">
        <Grid container spacing={3} rowGap="50px">
          <Grid item xs={5}>
            <Box className="ai-agents-form-box-description" component="p">
              {CheckValueLocale("ai_agent_gender_text", "", {}, intl)}
            </Box>
          </Grid>
          <Grid item xs={7}>
            <AiAgentsFields
              type="radio"
              id="ai-agent-type-luci"
              horizontalOptions
              name="gender"
              value={formik?.values?.gender}
              onChange={aiAgentshandleChange}
              error={!!formik?.errors?.gender}
              onBlur={formik?.handleBlur}
              helperText={CheckValueLocale(
                formik?.errors?.gender,
                "",
                {},
                intl,
              )}
              options={[
                {
                  value: "male",
                  name: CheckValueLocale("ai_agent_male", "", {}, intl),
                  disabled: isFormDisabled,
                },
                {
                  value: "female",
                  name: (
                    <>{CheckValueLocale("ai_agent_female", "", {}, intl)}</>
                  ),
                  disabled: isFormDisabled,
                },
              ]}
            />
            <Box className="ai-agents-form-fields-wrapper ai-agent-identity-container">
              <AiAgentsFields
                disabled={isFormDisabled}
                type="select"
                id="instructions-ai-agents-luci"
                label={CheckValueLocale("ai_agent_nationality", "", {}, intl)}
                placeholder={CheckValueLocale("write_here", "", {}, intl)}
                name="nationality"
                value={formik?.values?.nationality}
                onChange={aiAgentshandleChange}
                error={!!formik?.errors?.nationality}
                onBlur={formik?.handleBlur}
                selectOptions={countriesOptions}
                helperText={CheckValueLocale(
                  formik?.errors?.nationality,
                  "",
                  {},
                  intl,
                )}
                clearable
              />
              <AiAgentsFields
                disabled={isFormDisabled}
                type="select"
                id="instructions-ai-agents-luci"
                label={CheckValueLocale("ai_agent_dialect", "", {}, intl)}
                placeholder={CheckValueLocale("write_here", "", {}, intl)}
                name="dialect"
                selectOptions={dialectsData(intl)}
                value={formik?.values?.dialect}
                onChange={aiAgentshandleChange}
                error={!!formik?.errors?.dialect}
                onBlur={formik?.handleBlur}
                helperText={CheckValueLocale(
                  formik?.errors?.dialect,
                  "",
                  {},
                  intl,
                )}
                clearable
              />
            </Box>
            <AiAgentsFields
              disabled={isFormDisabled}
              type="text"
              id="instructions-ai-agents-luci"
              label={CheckValueLocale("ai_agent_department", "", {}, intl)}
              placeholder={CheckValueLocale(
                "ai_Agent_departement_placeholder",
                "",
                {},
                intl,
              )}
              name="department"
              value={formik?.values?.department}
              onChange={aiAgentshandleChange}
              error={!!formik?.errors?.department}
              onBlur={formik?.handleBlur}
              helperText={CheckValueLocale(
                formik?.errors?.department,
                "",
                {},
                intl,
              )}
            />
          </Grid>
          <AgentIdentityResponseLength
            customHandleChange={customHandleChange}
            formik={formik}
            isFormDisabled={isFormDisabled}
          />
          <AgentIdentityToneOfVoiceStyle
            customHandleChange={customHandleChange}
            formik={formik}
            isFormDisabled={isFormDisabled}
          />
          <AgentEmojiFrequency
            customHandleChange={customHandleChange}
            formik={formik}
            isFormDisabled={isFormDisabled}
          />
        </Grid>
        <Divider orientation="horizontal" className="ai-agents-form-divider" />
        <AgentAdvancedSettings
          aiAgentshandleChange={aiAgentshandleChange}
          customHandleChange={customHandleChange}
          formik={formik}
          isFormDisabled={isFormDisabled}
        />
      </Box>
    </Box>
  );
};

export default AgentIdentity;
