import { Container } from "@mui/material";
import { styles } from "pages/profile/components/imageEditorComponents/imageModalStyles";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import InteractionsController from "services/controllers/interactionsController";
import mqttRabbitMQResponce from "services/controllers/mqttRabbitMQResponce";
import {
  checkEmptyValuesinObjectOfArrays,
  getIdsFromFilter,
  handleApplicableFiltersCA,
  handleCAFiltersIds,
  handleCAFiltersVals,
  handleFiltersParamsIds,
  isEmptyObj,
} from "utils/helpers";
import InsightsPageHeader from "../../insightsPageHeader";
import { getActiveParams } from "shared/channel-analytics-filter/activeFilters/activeFilters";
import { reduxApplicableFilters } from "utils/redux/features/SocialListening/socialListeningSlice";
import { filtersDropDownToDisplay } from "utils/redux/features/ChannelAnalytics/channelAnalyticsSlice";
import InteractionsBody from "pages/channelAnalytics/interactionsPage/interactionsBody";
import { getDataSources } from "pages/channelAnalytics/channelAnalytics.hooks.js";
import FiltersController from "services/controllers/filtersController";
import ChatInteractionsBody from "./ChatInteractionsBody";
import { useIntl } from "react-intl";

const ChatInteractionsPage = () => {
  const intl = useIntl();
  const urlParams = useParams();
  const dispatch = useDispatch();
  //breadcrumb Links
  const links = [
    {
      name: "analytics",
      url: "insights/main",
    },
    {
      name: "chat",
      url: "insights/chat",
    },
  ];
  /* Start Redux Declarations */
  const startDate = useSelector(
    (state) => state?.channelAnalytics?.startDateUnix,
  );

  const endDate = useSelector((state) => state?.channelAnalytics?.endDateUnix);

  const reduxFilterParams = useSelector(
    (state) => state?.channelAnalytics?.filterDropDownParams,
  );
  const reduxApplicableFiltersCA = useSelector(
    (state) => state?.channelAnalytics?.applicableFilters,
  );
  /* End Redux Declarations */
  const [response, setResponse] = useState([]);
  const [dataSources, setDataSources] = useState([]);
  const [filterFields, setFilterFields] = useState({});
  const mqttConnectionClosed = useRef(false);
  const clientMQTT = useRef();
  const [resetAllFields, setResetAllFields] = useState(0);
  const [page, setPage] = useState(1);
  const [filterParamsMainPage, setFilterParamsMainPage] = useState();
  const [loader, setLoader] = useState();
  const [assignActiveFilters, setAssignActiveFilters] = useState({});
  const [checkAllDataExist, setCheckAllDataExist] = useState(false);
  const [checkDataIsEmpty, setCheckDataIsEmpty] = useState(false);
  const [isApplicableFilterCalculated, setIsApplicableFilterCalculated] =
    useState(false);
  const [preLoaderTrackerDataSources, setPreLoaderTrackerDataSources] =
    useState([]);

  const handleChangePage = (event, page) => {
    setLoader(true);
    setPage(page);
    getCredentials(startDate, endDate, assignActiveFilters, "", page);
  };
  const getCredentials = (startDate, endDate, filterParams, custom, page) => {
    var pageParam = "";
    if (page === undefined || page === "") {
      pageParam = 1;
    } else {
      pageParam = page;
    }
    setResponse([]);
    setResetAllFields((prev) => prev + 1);
    InteractionsController.getInteractionsChatCredentials(
      startDate,
      endDate,
      filterParams,
      custom,
      window.localStorage.cxm_id,
      pageParam,
    ).then((data) => {
      if (!isEmptyObj(data.data)) {
        let filteredDatasources = data?.data?.data_sources?.filter(
          (item) => item?.toLowerCase?.() != "whatsapp",
        );
        setPreLoaderTrackerDataSources([...filteredDatasources]);
        mqttRabbitMQResponce(clientMQTT, data?.data, setResponse);
      } else {
        mqttConnectionClosed.current = true;
      }
      setLoader(false);
    });
  };

  const applyFilter = (
    startDate,
    endDate,
    applicableFilters,
    applicableFilterCalculated,
  ) => {
    if (isApplicableFilterCalculated || applicableFilterCalculated) {
      let selectedFilterParams = applicableFilters
        ? applicableFilters
        : !checkEmptyValuesinObjectOfArrays(reduxApplicableFiltersCA)
          ? reduxApplicableFiltersCA
          : assignActiveFilters;

      if (
        applicableFilters === undefined &&
        !checkEmptyValuesinObjectOfArrays(reduxApplicableFiltersCA)
      ) {
        const commonObject = {};
        const obj = {
          ...filterFields?.by_interactions,
          ...filterFields?.by_user,
        };

        const finalArr = getIdsFromFilter(obj);
        Object.keys(reduxApplicableFiltersCA).forEach((key) => {
          if (
            Array.isArray(reduxApplicableFiltersCA[key]) &&
            Array.isArray(finalArr[key])
          ) {
            const commonValues = reduxApplicableFiltersCA[key]?.filter(
              (value) => {
                return (
                  finalArr[key].findIndex(
                    (item) =>
                      item?.toString()?.toLowerCase() ===
                      value?.toString()?.toLowerCase(),
                  ) !== -1
                );
              },
            );
            commonObject[key] = commonValues;
          } else {
            commonObject[key] = [];
          }
        });
        selectedFilterParams = { ...commonObject };
        selectedFilterParams = handleFiltersParamsIds(
          selectedFilterParams,
          filterFields,
        );
      }

      const resp = handleCAFiltersIds(selectedFilterParams, filterFields);
      const {
        dmMonitorListFullName,
        waitingFilterFullName,
        monitorListFullName,
      } = resp;

      selectedFilterParams = resp?.selectedFilterParams;
      setResetAllFields((prev) => prev + 1);

      selectedFilterParams = handleCAFiltersVals(
        dmMonitorListFullName,
        monitorListFullName,
        waitingFilterFullName,
        selectedFilterParams,
      );

      setPage(1);
      setAssignActiveFilters(selectedFilterParams);

      getCredentials(startDate, endDate, selectedFilterParams, true, 1);
    }
  };
  const getFiltersFileds = (productId) => {
    FiltersController.getChatInteractionsFiltersFileds(
      window?.localStorage?.cxm_id,
    ).then((data) => {
      setFilterFields(data?.data);
    });
  };

  let activeFiltersArr = getActiveParams(assignActiveFilters);
  useEffect(() => {
    getDataSources(setDataSources);
    getFiltersFileds();
  }, []);
  useEffect(() => {
    if (!isEmptyObj(filterFields)) {
      let applicableFilters = handleApplicableFiltersCA(
        filterFields,
        reduxFilterParams,
      );
      let commonObject = {};
      const obj = {
        ...filterFields?.by_interactions,
        ...filterFields?.by_user,
      };

      const finalArr = getIdsFromFilter(obj);

      Object.keys(applicableFilters).forEach((key) => {
        if (
          Array.isArray(applicableFilters[key]) &&
          Array.isArray(finalArr[key])
        ) {
          const commonValues = applicableFilters[key].filter((value) => {
            return (
              finalArr[key].findIndex((item) =>
                key === "sub_themes_filter"
                  ? item?.value?.toString() === value?.toString()
                  : item?.toString()?.toLowerCase() ===
                    value?.toString()?.toLowerCase(),
              ) !== -1
            );
          });
          commonObject[key] = commonValues;
        } else {
          commonObject[key] = [];
        }
      });
      setIsApplicableFilterCalculated(true);
      applyFilter(
        startDate,
        endDate,
        handleFiltersParamsIds(commonObject, filterFields),
        true,
      );
      dispatch(reduxApplicableFilters(applicableFilters));
      dispatch(filtersDropDownToDisplay(commonObject));
    }
  }, [filterFields]);
  return (
    <Container
      maxWidth="xl"
      className={`${styles.insightsStyle} bread-crumb-page-style`}
    >
      <InsightsPageHeader
        title="interactions"
        showNewChannelBtn={false}
        showDatePicker={true}
        showFiltersBtn={true}
        filterFields={filterFields}
        applyFilter={applyFilter}
        startDate={startDate}
        endDate={endDate}
        showBreadCrumb={false}
        breadCrumbLinks={links}
        setFilterParamsMainPage={setFilterParamsMainPage}
        assignActiveFilters={assignActiveFilters}
        allActiveFilters={activeFiltersArr}
        sectionName="chat"
        luciFeature="Analytics"
        isInteractionsPage
        isSocialPage
        dataLoaded={checkAllDataExist}
        dataEmpty={checkDataIsEmpty}
      />
      <ChatInteractionsBody
        response={response}
        dataSources={dataSources}
        resetAllFields={resetAllFields}
        preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        handleChangePage={handleChangePage}
        page={page}
        loader={loader}
        assignActiveFilters={assignActiveFilters}
        setAssignActiveFilters={setAssignActiveFilters}
        startDate={startDate}
        endDate={endDate}
        applyFilter={applyFilter}
        allActiveFilters={activeFiltersArr}
        filterFields={filterFields}
        setCheckAllDataExist={setCheckAllDataExist}
        setCheckDataIsEmpty={setCheckDataIsEmpty}
      />
    </Container>
  );
};

export default ChatInteractionsPage;
