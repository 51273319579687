import { useQuery } from "@tanstack/react-query";
import SurveyController from "services/controllers/surveyController";

export const useSurveyTypes = () => {
  return useQuery({
    queryKey: ["surveyTypes"],
    queryFn: () => SurveyController.getSurveyTypes(),
    select: (data) => data?.data?.types,
    retry: false,
  });
};
