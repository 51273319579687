import { useState } from "react";
import LoadingSpinner from "../loadingSpinner/loadingSpinner.js";
// Save widget image
import * as htmlToImage from "html-to-image";
import download from "downloadjs";
//Save widget PDF
import jsPDF from "jspdf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowToBottom, faSpinner } from "@fortawesome/pro-regular-svg-icons";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers/index.js";
import html2canvas from "html2canvas";

const WidgetDownloader = (props) => {
  const intl = useIntl();
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [showDownloadBox, setShowDownloadBox] = useState(true);

  let { downloadBtnId, showAILine = null } = props;

  //handle download IMG function
  const downloadImg = (widgetId, downLoadType, imgSize) => {
    const nameFile = props?.isCustomDashboard
      ? widgetId?.split("-_-")?.[0]
      : widgetId;
    setShowDownloadBox(false);
    setDownloadLoader(true);
    setTimeout(() => {
      var node = document.getElementById(widgetId);
      htmlToImage
        .toPng(node)
        .then(function (dataUrl) {
          if (downLoadType === "PNG") {
            download(dataUrl, nameFile + ".png");
          } else if (downLoadType === "PDF") {
            var pdf = new jsPDF({ orientation: "landscape" });
            if (imgSize != undefined && imgSize === true) {
              const imgProps = pdf.getImageProperties(dataUrl);
              //get PdfWidth
              const pdfWidth = pdf.internal.pageSize.getWidth();
              //get PdfHeight
              const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
              pdf.addImage(dataUrl, "PNG", 0, 0, pdfWidth, pdfHeight);
            } else {
              pdf = new jsPDF({ orientation: "portrait" });
              const imgProps = pdf.getImageProperties(dataUrl);
              //get PdfWidth
              const pdfWidth = pdf.internal.pageSize.getWidth();
              //get PdfHeight
              const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
              pdf.addImage(dataUrl, "PNG", 0, 0, pdfWidth, pdfHeight);
            }
            const isFirefox =
              window.navigator.userAgent.match(/Firefox\/([0-9]+)\./);
            {
              isFirefox
                ? pdf.output("dataurlnewwindow")
                : pdf.save(nameFile + ".pdf");
            }
          }
          setDownloadLoader(false);
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });
    }, 3000);
  };

  const exportPdfForTwoWidgets = async (fileName) => {
    const nameFile = props?.isCustomDashboard
      ? fileName?.split("-_-")?.[0]
      : fileName;
    setShowDownloadBox(false);
    setDownloadLoader(true);

    try {
      const doc = new jsPDF("p", "mm", [250, 400]);
      const pageWidth = 250;
      const pageHeight = 400;
      const padding = 20;

      const hiddenContainer = document.getElementById(props?.hiddenChartId);
      const widgetHeader = document.getElementById(props?.widgetTitleHeaderID);
      if (!hiddenContainer) {
        throw new Error("Hidden container not found");
      }

      if (widgetHeader) {
        const widgetHeaderHeight = widgetHeader.offsetHeight;
        hiddenContainer.style.paddingTop = `${widgetHeaderHeight}px`;
        const headerCanvas = await html2canvas(widgetHeader, {
          useCORS: true,
          allowTaint: false,
          logging: false,
          backgroundColor: null,
          scale: 3,
          width: widgetHeader.scrollWidth,
          height: widgetHeader.scrollHeight,
        });

        const headerImgData = headerCanvas.toDataURL("image/png");
        const headerImgHeight =
          (headerCanvas.height * (pageWidth - 2 * padding)) /
          headerCanvas.width;

        doc.addImage(
          headerImgData,
          "PNG",
          padding,
          padding,
          pageWidth - 2 * padding,
          headerImgHeight,
        );
      }

      const canvas = await html2canvas(hiddenContainer, {
        useCORS: true,
        allowTaint: false,
        logging: false,
        backgroundColor: null,
        scale: 2,
        width: hiddenContainer.scrollWidth,
        height: hiddenContainer.scrollHeight,
      });

      const imgData = canvas.toDataURL("image/png");
      const imgHeight =
        (canvas.height * (pageWidth - 2 * padding)) / canvas.width;

      if (imgHeight <= pageHeight) {
        doc.addImage(
          imgData,
          "PNG",
          padding,
          padding,
          pageWidth - 2 * padding,
          imgHeight,
        );
      } else {
        let heightLeft = imgHeight;
        let position = 0;

        while (heightLeft > 0) {
          doc.addImage(
            imgData,
            "PNG",
            padding,
            position,
            pageWidth - 2 * padding,
            imgHeight,
          );
          heightLeft -= pageHeight;
          position -= pageHeight;

          if (heightLeft > 0) {
            doc.addPage();
          }
        }
      }

      doc.save(nameFile || "dashboard");
    } catch (error) {
      console.error("PDF Export Error:", error);
    } finally {
      setShowDownloadBox(false);
      setDownloadLoader(false);
    }
  };

  return (
    <div
      id={downloadBtnId ?? "widget-download-btn"}
      onMouseOver={() => setShowDownloadBox(true)}
      onMouseLeave={() => setShowDownloadBox(true)}
      className={`${showAILine ? "show-ai-line" : ""}`}
    >
      <button className="arrowBtn">
        {downloadLoader ? (
          <LoadingSpinner />
        ) : (
          <FontAwesomeIcon icon={faArrowToBottom} />
        )}
        {showDownloadBox ? (
          <div className="dropdown-contentDiv">
            <button
              className="exportBtn"
              onClick={() => downloadImg(props.chartId, "PNG")}
              id={`widget-download-${downloadBtnId ?? "downloadBtnId"}-as-png-btn`}
            >
              {CheckValueLocale("download_as_png", "", {}, intl)}
            </button>
            <button
              className="exportBtn"
              onClick={() => {
                if (props?.withWordCloud) {
                  exportPdfForTwoWidgets(props.chartId);
                } else {
                  downloadImg(props.chartId, "PDF", props.bigImg);
                }
              }}
              id={`widget-download-${downloadBtnId ?? "downloadBtnId"}-as-pdf-btn"`}
            >
              {CheckValueLocale("download_as_pdf", "", {}, intl)}
            </button>
          </div>
        ) : null}
      </button>
    </div>
  );
};

export default WidgetDownloader;
