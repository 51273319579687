import { Grid } from "@mui/material";
import LineChartWidget from "components/widgets/lineChartWidget";
import PieChartWidget from "components/widgets/pieChartWidget";
import StackedLineWidget from "components/widgets/stackedLineWidget";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import { useIntl } from "react-intl";

export const AnalyticsView = ({ preLoaderTrackerDataSources, state }) => {
  const intl = useIntl();

  return (
    <Grid container rowSpacing="24px" columnSpacing="26px">
      <Grid item xs={12}>
        <LineChartWidget
          title={"comments_volume"}
          titleToolTip={"spa_comments_volume_tooltip"}
          data={state?.postsVolume}
          totalValue={state?.totalPostsVolume}
          totalLabel={"comments"}
          labelColor={"#2bace3"}
          showGroupBy
          showChartDetailsWidget
          toolTipLabel={"posts"}
          showDownloadIcon
          bigImg
          lineChartPreLoaderDataSources={
            state?.commentsOverTimePreLoaderDataSources
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Grid>
      <Grid item xs={12}>
        <StackedLineWidget
          title={"sentiment_overtime_legend"}
          titleToolTip="spa_comments_sentiment_tooltip"
          data={state?.sentimentAnalysis}
          showGroupBy={true}
          areaStyle={true}
          toolTipLabel={"toolTipLabel"}
          showAI
          showDownloadIcon
          bigImg
          isSwitchCase
          showScales
          isSentimentAnalysisWidget
          isSentimentAnalysisShowCharts
          dataPiechart={state?.sentimentPieChart}
          hideLegend
          stackedLineWidgetPreLoaderDataSources={
            state?.sentimentAnalysisPreLoader
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          labelColors={["#89BB2A", "#E50035", "#F9A700"]}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TableWidget
          wordsList
          title={"top_keywords"}
          titleToolTip={"spa_top_keywords_tooltip"}
          leftColomnTitle={"keywords_tab_title"}
          rightColomnTitle={"number_of_posts"}
          data={state?.topKeywords}
          errorMessage={"no_keywords_found"}
          showDownloadIcon
          bigImg
          keywords
          tablePagination
          count={state?.topKeywords?.length}
          tableChartPreLoaderDataSources={
            state?.topKeywordsPreLoaderDataSources
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          withWordCloud
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TableWidget
          wordsList
          title={"top_hashtags"}
          titleToolTip={"spa_top_hashtags_tooltip"}
          leftColomnTitle={"hashtags"}
          rightColomnTitle={"number_of_posts"}
          data={state?.topHashtags}
          errorMessage={"no_hashtags_found"}
          showDownloadIcon
          bigImg
          keywords
          tablePagination
          count={state?.topKeywords?.length}
          tableChartPreLoaderDataSources={
            state?.topHashtagsPreLoaderDataSources
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          withWordCloud
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <PieChartWidget
          showAI
          title={"top_countries"}
          titleToolTip="spa_top_countries_tooltip"
          data={state?.topCountries}
          showDownloadIcon
          pieChartPreLoaderDataSources={state?.topCountriesPreLoaderDataSources}
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <PieChartWidget
          showAI
          title={"top_cities"}
          titleToolTip="spa_top_cities"
          data={state?.topCities}
          showDownloadIcon
          pieChartPreLoaderDataSources={state?.topCitiesPreLoaderDataSources}
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <PieChartWidget
          showAI
          title={"top_languages"}
          titleToolTip="spa_top_languages_tooltip"
          data={state?.topLanguages}
          showDownloadIcon
          pieChartPreLoaderDataSources={state?.topLanguagesPreLoaderDataSources}
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <PieChartWidget
          showAI
          title={"dialects_and_sub_dialects"}
          titleToolTip="spa_dialects_sub_dialects_tooltip"
          data={state?.dialects}
          subData={state?.subDialects}
          showDownloadIcon
          pieChartPreLoaderDataSources={
            state?.dialectsSubDialectsPreLoaderDataSources
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          isDialectsSubDialects
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <PieChartWidget
          showAI
          title="gender_distribution"
          titleToolTip="spa_top_genders_tooltip"
          data={state?.gender}
          showDownloadIcon
          pieChartPreLoaderDataSources={state?.genderPreLoaderDataSources}
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <PieChartWidget
          showAI
          title="account_types"
          titleToolTip="spa_account_types_tooltip"
          data={state?.accountTypes}
          showDownloadIcon
          pieChartPreLoaderDataSources={state?.accountTypesPreLoaderDataSources}
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TableWidget
          title={"top_engagers"}
          titleToolTip={"spa_top_engagers_tooltip"}
          leftColomnTitle={"users"}
          rightColomnTitle={"followers"}
          secondaryTitle={"engagement_overview_title"}
          itemsList
          data={state?.topEngagers}
          errorMessage={"no_influencers_found"}
          bigImg
          keywords
          isXPlatform
          tablePagination
          count={state?.topEngagers?.length}
          tableChartPreLoaderDataSources={
            state?.topEngagersPreLoaderDataSources
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TableWidget
          title={"top_verified_engagers"}
          titleToolTip={"spa_top_verified_engagers_tooltip"}
          leftColomnTitle={"users"}
          rightColomnTitle={"followers"}
          secondaryTitle={"engagement_overview_title"}
          itemsList
          data={state?.topVerifiedEngagers}
          errorMessage={"no_verified_engagers_found"}
          bigImg
          keywords
          isXPlatform
          tablePagination
          count={state?.topVerifiedEngagers?.length}
          tableChartPreLoaderDataSources={
            state?.topVerifiedEngagersPreLoaderDataSources
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TableWidget
          title={"top_influencers"}
          titleToolTip={"spa_top_influencers_tooltip"}
          leftColomnTitle={"users"}
          rightColomnTitle={"followers"}
          secondaryTitle={"engagement_overview_title"}
          data={state?.topInfluencers}
          errorMessage={"no_influencers_found"}
          bigImg
          keywords
          isXPlatform
          itemsList
          tablePagination
          count={state?.topInfluencers?.length}
          tableChartPreLoaderDataSources={
            state?.topInfluencersPreLoaderDataSources
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Grid>
    </Grid>
  );
};
