import { useEffect, useState } from "react";
import moment from "moment";
import {
  OutlinedInput,
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
  TextField,
  Typography,
} from "@mui/material";

import { CheckValueLocale } from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";
import CustomDateRangePicker from "shared/customDateRangePicker/customDateRangePicker";
import {
  sidebarGeneralOptions,
  sidebarOptionsMapper,
} from "shared/customDateRangePicker/config/sidebarOptions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 268,
      width: "auto",
    },
  },
};

const maxLength = "maxLength";
const disallowedCharacters = "disallowedCharacters";
const disallowedSpaced = "disallowedSpaced";
const repeatedHashtag = "repeatedHashtag";
const invalidHashtag = "invalidHashtag";
const hashtagErrorMessages = {
  [maxLength]: "hashtag_max_length", // MAX 280 CHARACTERS
  [disallowedCharacters]: "hashtag_disallowed_characters", // ONLY LETTERS, NUMBERS, HASHTAGS AND UNDERSCORES.
  [disallowedSpaced]: "hashtag_disallowed_spaces", // SPACES ARE NOT ALLOWED
  [repeatedHashtag]: "hashtag_repeated", // HASHTAGS MUST BE UNIQUE
  [invalidHashtag]: "hashtag_invalid", // EX: '_'
};
const TopHashtagsFilter = ({
  intl,
  tabNumber,
  locations,
  handeAppliedFilters,
  tableDescriptionTitle,
  appliedFilterStartDate,
  appliedFilterEndDate,
  appliedFilterPeriod,
  setRemoveSearch,
  setClearSearchFlag,
  clearSearchFlag,
  setAppliedFilter,
  dateRangePickerParamsRef,
}) => {
  const [filterData, setFilterData] = useState({
    location: [23424938],
    hashtag: "",
    hashtagText: "",
    period: appliedFilterPeriod,
    startDate: appliedFilterStartDate,
    endDate: appliedFilterEndDate,
  });
  const [inputValue, setInputValue] = useState("");
  const [resetCalendar, setResetCalendar] = useState(1);
  const periodType = tabNumber === 1 ? "Day" : "Hour";

  const applyFilters = () => {
    let { location, hashtag, startDate, endDate, period } = filterData;
    location = location?.join(",");
    handeAppliedFilters({
      location,
      hashtag: hashtag?.trim()?.replace(/\s{2,}/g, " "),
      startDate: startDate,
      endDate: endDate,
      period,
    });
  };

  useEffect(() => {
    if (tabNumber === 1) {
      setFilterData((val) => ({ ...val, period: 0 }));
    } else if (tabNumber === 2) {
      setFilterData((val) => ({ ...val, period: 4 }));
    } else {
      setFilterData((val) => ({ ...val }));
    }
  }, [tabNumber]);

  const handleFilterChange = (event) => {
    const {
      target: { value, name },
    } = event;

    setFilterData((val) => ({ ...val, [name]: value }));
  };

  const applyDateFilter = (startDate, endDate, filterParams) => {
    setFilterData((val) => ({
      ...val,
      startDate,
      endDate,
    }));
  };

  const handleChange = (event) => {
    const originalValue = event.target.value;
    const validValue = originalValue.replace(
      /[^a-zA-Z0-9#_\s!@#$%^&*()-_=+{}[\];:'",.<>?/\\|`~ء-ي]/g,
      "",
    );

    if (validValue?.length <= 40) {
      setInputValue(originalValue);
      const searchTerm = validValue?.replace(/[^a-zA-Z0-9_\sء-ي]/g, "");
      setFilterData((val) => ({
        ...val,
        hashtag: searchTerm,
      }));
    }

    setRemoveSearch(true);
  };

  const removeFilterTrend = () => {
    setResetCalendar((prev) => prev + 1);
    setFilterData({
      location: [23424938],
      hashtag: "",
      period: periodType === "Hour" ? 4 : 0,
      startDate: moment()?.subtract(6, "d").unix(),
      endDate: moment()?.unix(),
    });
    setAppliedFilter({
      location: "23424938",
      hashtag: "",
      startDate: moment()?.subtract(6, "d").unix(),
      endDate: moment()?.unix(),
      period: periodType === "Hour" ? 4 : 0,
    });
    setInputValue("");
    setRemoveSearch(false);
    setClearSearchFlag(false);
  };

  useEffect(() => {
    if (clearSearchFlag) removeFilterTrend();
  }, [clearSearchFlag]);

  return (
    <Box className="top-hashtags-filter">
      <Typography>
        {CheckValueLocale(tableDescriptionTitle, "", {}, intl)}
      </Typography>
      <Box>
        <Box className="top-hashtags-filter-field-box">
          <Box className="search-input-top-trend-box">
            <TextField
              id="top-trend-search-input"
              label={CheckValueLocale("search_by_trend", "", {}, intl)}
              variant="outlined"
              value={inputValue}
              onChange={handleChange}
              fullWidth
            />
          </Box>
          <Box className="select-region-container">
            <FormControl className="select-location-dual-label select-location-form-control">
              <InputLabel id="select-location-checkbox-label">
                {CheckValueLocale("select_location", "", {}, intl)}
              </InputLabel>
              <Select
                labelId="select-location-checkbox-label"
                id="select-location-checkbox"
                multiple
                name="location"
                value={filterData?.location}
                onChange={handleFilterChange}
                input={
                  <OutlinedInput
                    label={CheckValueLocale("select_location", "", {}, intl)}
                  />
                }
                renderValue={(selected) => {
                  const selectedLocations = locations?.filter((val) =>
                    selected?.includes(val?.woe_id),
                  );
                  const count = selectedLocations?.length;
                  return (
                    count +
                    " " +
                    CheckValueLocale(`top_trend_selected`, "", {}, intl)
                  );
                }}
                MenuProps={MenuProps}
              >
                {locations?.map((val) =>
                  val?.location_name != "Israel" ? (
                    <MenuItem
                      key={val?.woe_id}
                      value={val?.woe_id}
                      className="top-hashtags-location-option"
                      id={`top-hashtags-location-option-${
                        val?.location_name ?? "location-name"
                      }`}
                    >
                      <Checkbox
                        checked={
                          filterData?.location?.indexOf(val?.woe_id) > -1
                        }
                      />
                      <ListItemText
                        primary={CheckValueLocale(
                          val?.location_name,
                          "",
                          {},
                          intl,
                        )}
                      />
                    </MenuItem>
                  ) : null,
                )}
              </Select>
            </FormControl>
          </Box>
          <Box className="date-period-grid">
            {tabNumber === 0 && (
              <CustomDateRangePicker
                initialSidebarLabel={sidebarOptionsMapper.lastsevendays}
                startDate={filterData?.startDate}
                endDate={filterData?.endDate}
                applyFilter={applyDateFilter}
                maxDate={new Date()}
                resetCalendar={resetCalendar}
                enableResetting={true}
                sidebarOptions={sidebarGeneralOptions}
                dateRangePickerParamsRef={dateRangePickerParamsRef}
                customStyles={{
                  type: "secondary",
                  variant: "outline",
                  size: "large",
                  fixedWidth: "232px",
                  startIcon: (
                    <FontAwesomeIcon
                      icon={faCalendar}
                      className="custom-input-icon"
                    />
                  ),
                }}
              />
            )}

            {(tabNumber === 1 || tabNumber === 2) && (
              <FormControl className="select-location-form-control">
                <InputLabel id="select-period-label">
                  {CheckValueLocale("period", "", {}, intl)}
                </InputLabel>
                <Select
                  labelId="select-period-label"
                  id="select-period"
                  label="Period"
                  name="period"
                  value={filterData.period}
                  onChange={handleFilterChange}
                  MenuProps={MenuProps}
                >
                  {periodType === "Day" && (
                    <MenuItem
                      key={0}
                      value={0}
                      className="top-hashtags-location-option"
                      id={`top-hashtags-period-option-today`}
                    >
                      {periodType === "Day" &&
                        CheckValueLocale("today", "", {}, intl)}
                    </MenuItem>
                  )}

                  {periodType === "Day" &&
                    [...Array(28)].map((x, i) => (
                      <MenuItem
                        key={`${periodType}-${i + 1}`}
                        value={i + 1}
                        className="top-hashtags-location-option"
                        id={`top-hashtags-period-option-${
                          periodType ?? "period-type"
                        }-${i + 1 ?? "index"}`}
                      >
                        {window.localStorage.lang == "en" ? (
                          <>
                            {periodType === "Day" ? (
                              <span>
                                {i +
                                  1 +
                                  " " +
                                  CheckValueLocale(
                                    `${
                                      periodType.toLowerCase() + "_top_hashtags"
                                    }`,
                                    "",
                                    {},
                                    intl,
                                  )}
                                {i > 0
                                  ? CheckValueLocale(
                                      "s_for_plural",
                                      "",
                                      {},
                                      intl,
                                    )
                                  : ""}{" "}
                                {CheckValueLocale(
                                  "ago_top_hashtags",
                                  "",
                                  {},
                                  intl,
                                )}
                              </span>
                            ) : periodType === "Hour" && i >= 3 ? (
                              <span>
                                {i +
                                  1 +
                                  " " +
                                  CheckValueLocale(
                                    `${
                                      periodType.toLowerCase() + "_top_hashtags"
                                    }`,
                                    "",
                                    {},
                                    intl,
                                  )}
                                {i > 0
                                  ? CheckValueLocale(
                                      "s_for_plural",
                                      "",
                                      {},
                                      intl,
                                    )
                                  : ""}{" "}
                                {CheckValueLocale(
                                  "ago_top_hashtags",
                                  "",
                                  {},
                                  intl,
                                )}
                              </span>
                            ) : null}
                          </>
                        ) : (
                          <>
                            {CheckValueLocale("ago_top_hashtags", "", {}, intl)}

                            {i + 1 === 1
                              ? " " +
                                CheckValueLocale(
                                  periodType.toLowerCase() + "_top_hashtags",
                                  "",
                                  {},
                                  intl,
                                )
                              : i + 1 === 2
                                ? " " +
                                  CheckValueLocale(
                                    "two_days_top_hashtags",
                                    "",
                                    {},
                                    intl,
                                  )
                                : i + 1 <= 10
                                  ? " " +
                                    (i + 1) +
                                    " " +
                                    CheckValueLocale(
                                      periodType.toLowerCase() +
                                        "_top_hashtags_plurals",
                                      "",
                                      {},
                                      intl,
                                    )
                                  : " " +
                                    (i + 1) +
                                    " " +
                                    CheckValueLocale(
                                      periodType.toLowerCase() +
                                        "_top_hashtags",
                                      "",
                                      {},
                                      intl,
                                    )}

                            {i > 0
                              ? CheckValueLocale("s_for_plural", "", {}, intl)
                              : ""}
                          </>
                        )}
                      </MenuItem>
                    ))}

                  {periodType === "Hour" &&
                    [...Array(24)].map(
                      (x, i) =>
                        i >= 3 && (
                          <MenuItem
                            key={`${periodType}-${i + 1}`}
                            value={i + 1}
                            className="top-hashtags-location-option"
                          >
                            {window.localStorage.lang == "en" ? (
                              <>
                                {periodType === "Day" ? (
                                  <span>
                                    {i +
                                      1 +
                                      " " +
                                      CheckValueLocale(
                                        `${
                                          periodType.toLowerCase() +
                                          "_top_hashtags"
                                        }`,
                                        "",
                                        {},
                                        intl,
                                      )}
                                    {i > 0
                                      ? CheckValueLocale(
                                          "s_for_plural",
                                          "",
                                          {},
                                          intl,
                                        )
                                      : ""}{" "}
                                    {CheckValueLocale(
                                      "ago_top_hashtags",
                                      "",
                                      {},
                                      intl,
                                    )}
                                  </span>
                                ) : periodType === "Hour" && i >= 3 ? (
                                  <span>
                                    {i +
                                      1 +
                                      " " +
                                      CheckValueLocale(
                                        `${
                                          periodType.toLowerCase() +
                                          "_top_hashtags"
                                        }`,
                                        "",
                                        {},
                                        intl,
                                      )}
                                    {i > 0
                                      ? CheckValueLocale(
                                          "s_for_plural",
                                          "",
                                          {},
                                          intl,
                                        )
                                      : ""}{" "}
                                    {CheckValueLocale(
                                      "ago_top_hashtags",
                                      "",
                                      {},
                                      intl,
                                    )}
                                  </span>
                                ) : null}
                              </>
                            ) : (
                              <>
                                {CheckValueLocale(
                                  "ago_top_hashtags_hourly",
                                  "",
                                  {},
                                  intl,
                                )}

                                {i + 1 <= 10
                                  ? " " +
                                    (i + 1) +
                                    " " +
                                    CheckValueLocale(
                                      periodType.toLowerCase() +
                                        "_top_hashtags_plurals",
                                      "",
                                      {},
                                      intl,
                                    )
                                  : " " +
                                    (i + 1) +
                                    " " +
                                    CheckValueLocale(
                                      periodType.toLowerCase() +
                                        "_top_hashtags",
                                      "",
                                      {},
                                      intl,
                                    )}

                                {i > 0
                                  ? CheckValueLocale(
                                      "s_for_plural",
                                      "",
                                      {},
                                      intl,
                                    )
                                  : ""}
                              </>
                            )}
                          </MenuItem>
                        ),
                    )}
                </Select>
              </FormControl>
            )}
          </Box>
          <Box className="top-hashtags-filter-results-btn">
            <LucButton
              variant="filled"
              onClick={applyFilters}
              id="top-hashtags-filter-result-btn"
            >
              {CheckValueLocale("top_trends_filter", "", {}, intl)}
            </LucButton>
          </Box>
          <Box>
            <LucButton
              variant="flat"
              id="top-trend-clear-search"
              onClick={removeFilterTrend}
            >
              {CheckValueLocale("top_trend_clear_search", "", {}, intl)}
            </LucButton>
          </Box>
        </Box>
      </Box>

      {/* <Grid container spacing={1} columns={12}>
        <Grid item xs={4}>
          <span className="hide-hashtags">
            {filterData?.hashtag?.slice(0, size)?.map((val, index) => (
              <Chip
                className="hashtag-chip"
                color="primary"
                key={`hashtag-${index}`}
                label={val}
                variant="outlined"
                onDelete={() => removeAddHashtag(index)}
              />
            ))}
          </span>
          {filterData?.hashtag.length > 3 && (
            <Chip
              className="hashtag-show-chip"
              label={CheckValueLocale(
                showAll ? "show_less" : "show_all",
                "",
                {},
                intl,
              )}
              variant="outlined"
              onClick={showAll ? hideHashtags : seeAllHashtags}
            />
          )}
        </Grid>
        {hashtagError && (
          <Grid xs={12}>
            <Typography id="hashtag-error" className="hashtag-error-message">
              {CheckValueLocale(
                hashtagErrorMessages?.[hashtagError],
                "",
                {},
                intl,
              )}
            </Typography>
          </Grid>
        )}
      </Grid> */}
    </Box>
  );
};
export default TopHashtagsFilter;
