import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Box, Typography } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/pro-regular-svg-icons";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { Button } from "@mui/material";
import { useGetVerificationStatus } from "../hooks/useGetVerificationStatus";
import LucButton from "shared/lucButton/lucButton";

const VerifyDomainBody = (props) => {
  const { selectedDomains, setSnackBar } = props;

  const intl = useIntl();

  const [verifiedDomain, setVerifiedDomain] = useState(false);
  const [verifyFailed, setVerifyFailed] = useState(false);
  const [copiedCode, setCopiedCode] = useState(false); // copied code to clipboard

  const { domain, token, is_verified } = selectedDomains?.[0] || {};

  // check if domain is verified or not when entering the verify domain popup step
  useEffect(() => {
    if (is_verified) {
      setVerifiedDomain(true);
      setVerifyFailed(false);
    } else {
      setVerifiedDomain(false);
      setVerifyFailed(false);
    }
  }, [is_verified]);

  // reset copied code state after 2 seconds
  useEffect(() => {
    if (copiedCode) setTimeout(() => setCopiedCode(false), 2000);
  }, [copiedCode]);

  const cdnBaseUrl = "https://survey.lucidya.com/bundle.js";

  const scriptCode = `<script src="${cdnBaseUrl}" luc-data-id="${token}" defer></script>`;

  const sciptTextParts = [
    { text: "<script " },
    { text: "src", color: "#F8D06B" },
    { text: `="${cdnBaseUrl}"`, color: "#81E985" },
    { text: " luc-data-id", color: "#F8D06B" },
    { text: `="${token}"`, color: "#81E985" },
    { text: " defer", color: "#81E985" },
    { text: "></script>" },
  ];

  const { mutate: verifyDomain, isPending: verifyDomainLoading } =
    useGetVerificationStatus();

  const handleVerifyDomain = () => {
    verifyDomain(
      { queryData: { token } },
      {
        onSuccess: (data) => {
          if (data?.data?.is_verified) {
            setVerifiedDomain(true);
            setVerifyFailed(false);
          } else {
            setVerifiedDomain(false);
            setVerifyFailed(true);
          }
        },
        onError: () => {
          setSnackBar({
            open: true,
            severity: "error",
            title: "something_went_wrong",
            message: "try_again",
          });
        },
      },
    );
  };

  return (
    <Box className="embedded-publish-verify-domain-body">
      <Box className="verifying-domain-step-one">
        <Box className="verify-domain-title-desc-wrapper">
          <Typography className="verify-domain-step-title">
            {CheckValueLocale(
              "embedded_survey_copy_snippet_code_title",
              "",
              {},
              intl,
            )}
          </Typography>
          <Typography className="verify-domain-step-desc">
            {CheckValueLocale(
              "embedded_survey_copy_snippet_code_desc",
              "",
              {},
              intl,
            )}
          </Typography>
        </Box>
        <Box className="embedded-publish-verify-domain-code-block">
          <Typography id="embedded-publish-verify-domain-script-code">
            {sciptTextParts?.map((part, index) => (
              <Box
                key={index}
                component="span"
                sx={{ color: part?.color || "#ffffff" }}
              >
                {part?.text}
              </Box>
            ))}
          </Typography>

          <Box className="copy-code-btn-wrapper">
            <Button
              className={`copy-code-btn ${copiedCode ? "copied-code" : ""}`}
              size="small"
              onClick={() => {
                navigator?.clipboard?.writeText(scriptCode);
                setCopiedCode(true);
              }}
            >
              <FontAwesomeIcon icon={faCopy} />
              {CheckValueLocale(copiedCode ? "copied" : "copy", "", {}, intl)}
            </Button>
          </Box>
        </Box>
      </Box>

      <Box className="verifying-domain-step-two">
        <Box className="verify-domain-title-desc-wrapper">
          <Typography className="verify-domain-step-title">
            {CheckValueLocale(
              "embedded_survey_verify_your_domain_title",
              "",
              {},
              intl,
            )}
          </Typography>
        </Box>

        <Box
          className={`embedded-publish-verify-domain-input-btn-wrapper ${verifiedDomain ? "verified-domain" : verifyFailed ? "verify-domain-error" : ""}`}
        >
          <Box className="embedded-publish-verify-domain-input-wrapper">
            <Box className="embedded-publish-verify-domain-input">
              {domain || ""}
            </Box>
            {!verifiedDomain ? (
              <Typography id="embedded-publish-verify-domain-input-helper-text">
                {CheckValueLocale(
                  verifyFailed
                    ? "embedded_survey_verify_your_domain_error_msg"
                    : "embedded_survey_verify_your_domain_helper_msg",
                  "",
                  { domain: domain || "" },
                  intl,
                )}
              </Typography>
            ) : null}
          </Box>
          <Box className="embedded-publish-verify-domain-verify-btn-wrapper">
            <LucButton
              id="embedded-publish-verify-domain-verify-btn"
              className="embedded-publish-verify-domain-verify-btn"
              variant="outline"
              size="small"
              fullWidth
              startIcon={
                verifiedDomain ? <FontAwesomeIcon icon={faCircleCheck} /> : null
              }
              disabled={verifiedDomain}
              loading={verifyDomainLoading}
              onClick={handleVerifyDomain}
            >
              {CheckValueLocale(
                verifiedDomain
                  ? "survey_domain_verified"
                  : verifyFailed
                    ? "verify_again"
                    : "verify",
                "",
                {},
                intl,
              )}
            </LucButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default VerifyDomainBody;
