import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { isPublicSpecialCharactersOrEmojis } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/specialCharOrEmojis";
import { removeSpecialCharacters } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/keywordLanguage";
import KeywordTooltipChip from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/keywordTypeComponent/keywordTooltipChip";
import { useThemesColors } from "pages/themes/hooks/useThemesColors";
import CircularLoading from "components/circularProgress";
import CustomizedTooltip from "components/customizedTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import ThemeColorMenu from "./themeColorMenu";

const ThemeDialogBody = ({
  formik,
  openDialog,
  themeDialogStats,
  setInitialValue,
  setSnackBarData,
}) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
  } = formik;

  const intl = useIntl();

  const [keywordFocused, setKeywordFocused] = useState(false);

  const {
    data: colors,
    isPending: isColorsPending,
    isError: isColorsError,
  } = useThemesColors();

  // To set color automatically with first unused color (NEW THEME CASE ONLY)
  useEffect(() => {
    if (openDialog === true) {
      if (themeDialogStats?.isEdit == true && themeDialogStats?.themeToEdit) {
        const themeToEdit = themeDialogStats?.themeToEdit;
        setInitialValue({
          sub_theme_color: themeToEdit?.sub_theme_color,
          sub_theme_en_name: themeToEdit?.sub_theme_en_name,
          sub_theme_ar_name: themeToEdit?.sub_theme_ar_name,
          sub_theme_description: themeToEdit?.sub_theme_description,
          sub_themes_topkeywords:
            themeToEdit?.sub_themes_topkeywords?.split(","),
        });
      } else if (colors?.length) {
        const notUsedColors = colors?.filter((item) => item?.status !== "used");
        setInitialValue({
          sub_theme_color: notUsedColors?.[0]?.value,
          sub_theme_en_name: "",
          sub_theme_ar_name: "",
          sub_theme_description: "",
          sub_themes_topkeywords: [],
        });
      }
    } else formik?.resetForm();
  }, [
    openDialog,
    themeDialogStats?.isEdit,
    themeDialogStats?.themeToEdit,
    colors,
  ]);

  useEffect(() => {
    if (isColorsError)
      setSnackBarData({
        open: true,
        severity: "error",
        title: "something_went_wrong",
        message: "try_again",
      });
  }, [isColorsError]);

  // To out focus from keyword input field when reached limit
  useEffect(() => {
    if (values?.sub_themes_topkeywords?.length > 9) setKeywordFocused(false);
  }, [values?.sub_themes_topkeywords?.length]);

  const keywordFormik = useFormik({
    initialValues: { keyword_input: "" },
    validationSchema: Yup.object({
      keyword_input: Yup.string()
        .max(30, "keyword_must_be_under_30_characters")
        .test(
          "",
          "save_results_validation",
          (value) =>
            !value ||
            (!isPublicSpecialCharactersOrEmojis(value, undefined, true) &&
              removeSpecialCharacters(value)?.length),
        )
        .test(
          "",
          "this_keyword_already_exists",
          (value) => !formik?.values?.sub_themes_topkeywords?.includes(value),
        )
        .test(
          "",
          "keywords_field_is_required",
          (value) =>
            value?.length || formik?.values?.sub_themes_topkeywords?.length > 0,
        ),
    }),
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: ({ keyword_input }) => {
      if (keyword_input) {
        setFieldValue("sub_themes_topkeywords", [
          ...formik?.values?.sub_themes_topkeywords,
          keyword_input,
        ]);
        keywordFormik.resetForm();
      }
    },
  });

  // To check if there is any error in keywords array sent to backend
  const keywordsError =
    formik?.touched?.sub_themes_topkeywords &&
    Boolean(formik?.errors?.sub_themes_topkeywords);

  // To check if there is any error in keyword input
  const keywordInputError =
    keywordFormik?.touched?.keyword_input &&
    Boolean(keywordFormik?.errors?.keyword_input);

  return (
    <Box id="theme-dialog-body">
      {isColorsPending ? (
        <CircularLoading />
      ) : (
        <Grid container spacing={2}>
          <ThemeColorMenu
            colors={colors}
            formik={formik}
            themeDialogStats={themeDialogStats}
          />

          <Grid item xs={5}>
            <Typography className="theme-dialog-input-label">
              {CheckValueLocale("english_label", "", {}, intl)}
              <Typography component="span" className="required">
                *
              </Typography>
            </Typography>
            <FormControl className="theme-dialog-form-control">
              <TextField
                id="theme-form-input-english-label"
                variant="outlined"
                size="small"
                placeholder={CheckValueLocale(
                  "english_label_placeholder",
                  "",
                  {},
                  intl,
                )}
                value={values?.sub_theme_en_name}
                onChange={async (e) => {
                  await handleChange(e);
                  setFieldTouched(e?.target?.name, true);
                }}
                onBlur={handleBlur}
                name="sub_theme_en_name"
                error={
                  touched?.sub_theme_en_name && !!errors?.sub_theme_en_name
                }
                helperText={
                  touched?.sub_theme_en_name &&
                  CheckValueLocale(errors?.sub_theme_en_name, "", {}, intl)
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={5}>
            <FormControl className="theme-dialog-form-control">
              <Typography className="theme-dialog-input-label">
                {CheckValueLocale("arabic_label", "", {}, intl)}
                <Typography component="span" className="required">
                  *
                </Typography>
              </Typography>
              <TextField
                id="theme-form-input-arabic-label"
                variant="outlined"
                size="small"
                placeholder={CheckValueLocale(
                  "arabic_label_placeholder",
                  "",
                  {},
                  intl,
                )}
                value={values?.sub_theme_ar_name}
                onChange={async (e) => {
                  await handleChange(e);
                  setFieldTouched(e?.target?.name, true);
                }}
                onBlur={handleBlur}
                name="sub_theme_ar_name"
                error={
                  touched?.sub_theme_ar_name && !!errors?.sub_theme_ar_name
                }
                helperText={
                  touched?.sub_theme_ar_name &&
                  CheckValueLocale(errors?.sub_theme_ar_name, "", {}, intl)
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box className="theme-dialog-input-label-wrapper">
              <Typography className="theme-dialog-input-label">
                {CheckValueLocale("describe_theme", "", {}, intl)}
                <Typography component="span" className="required">
                  *
                </Typography>
                <CustomizedTooltip
                  placement={intl?.locale == "ar" ? "left" : "right"}
                  title={CheckValueLocale(
                    "describe_theme_tooltip_msg",
                    "",
                    {},
                    intl,
                  )}
                  arrow
                  styles={{ width: "250px", padding: "13px" }}
                >
                  <Box component="span" className="description-info-icon">
                    <FontAwesomeIcon icon={faCircleInfo} fixedWidth />
                  </Box>
                </CustomizedTooltip>
              </Typography>
              <Typography
                className={`max-char-counter ${
                  values?.sub_theme_description.length > 300 ? "reached" : ""
                }`}
              >
                {values?.sub_theme_description.length}/300
              </Typography>
            </Box>
            <FormControl className="theme-dialog-form-control">
              <TextField
                id="theme-form-input-describe-theme"
                variant="outlined"
                size="small"
                multiline
                minRows={5}
                placeholder={CheckValueLocale(
                  "describe_theme_placeholder",
                  "",
                  {},
                  intl,
                )}
                value={values?.sub_theme_description}
                onChange={async (e) => {
                  await handleChange(e);
                  setFieldTouched(e?.target?.name, true);
                }}
                onBlur={handleBlur}
                disabled={themeDialogStats?.isEdit}
                name="sub_theme_description"
                error={
                  touched?.sub_theme_description &&
                  !!errors?.sub_theme_description
                }
                helperText={
                  touched?.sub_theme_description &&
                  CheckValueLocale(errors?.sub_theme_description, "", {}, intl)
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className="theme-dialog-form-control">
              <Typography className="theme-dialog-input-label">
                {CheckValueLocale("keywords", "", {}, intl)}
                <Typography component="span" className="required">
                  *
                </Typography>
                <Typography component="span" className="maximum-keywords">
                  {CheckValueLocale("maximum_10", "", {}, intl)}
                </Typography>
              </Typography>
              <OutlinedInput
                variant="outlined"
                className="keywords-field keyword-input-field"
                name="keyword_input"
                placeholder={CheckValueLocale("enter_keywords", "", {}, intl)}
                value={keywordFormik?.values?.keyword_input || ""}
                onChange={keywordFormik?.handleChange}
                onFocus={() => setKeywordFocused(true)}
                onBlur={(e) => {
                  setKeywordFocused(false);
                  keywordFormik?.handleBlur(e);
                }}
                onKeyDown={(e) => {
                  if (e?.key === "Enter") {
                    e?.preventDefault();
                    keywordFormik?.handleSubmit();
                  }
                }}
                disabled={
                  themeDialogStats?.isEdit ||
                  values?.sub_themes_topkeywords?.length > 9
                }
                endAdornment={
                  keywordFocused ? (
                    <InputAdornment onMouseDown={(e) => e?.preventDefault()}>
                      <Button
                        id="theme-dialog-add-keyword-btn"
                        className="add-btn-with-plus"
                        disabled={
                          !keywordFormik?.dirty ||
                          !keywordFormik?.isValid ||
                          themeDialogStats?.isEdit ||
                          values?.sub_themes_topkeywords?.length > 9
                        }
                        onClick={keywordFormik?.handleSubmit}
                      >
                        {CheckValueLocale("add_plus", "", {}, intl)}
                      </Button>
                    </InputAdornment>
                  ) : null
                }
                error={keywordInputError || keywordsError}
              />
              <FormHelperText className="error-helper-text">
                {CheckValueLocale(
                  keywordInputError
                    ? keywordFormik?.errors?.keyword_input
                    : keywordsError
                      ? formik?.errors?.sub_themes_topkeywords
                      : "",
                  "",
                  {},
                  intl,
                )}
              </FormHelperText>
              <Box className="keyword-spam-wrapper">
                {values?.sub_themes_topkeywords?.map((item, index) => (
                  <KeywordTooltipChip
                    key={index}
                    onDelete={() => {
                      const newKeywords =
                        values?.sub_themes_topkeywords?.filter(
                          (_, i) => i !== index,
                        );
                      setFieldValue("sub_themes_topkeywords", newKeywords);
                    }}
                    disableChip={themeDialogStats?.isEdit}
                    index={index}
                    labels={item}
                  />
                ))}
              </Box>
            </FormControl>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default ThemeDialogBody;
