import { Box, Typography } from "@mui/material";
import AddQuestionsToggle from "../addQuestionsToggle/addQuestionsToggle";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";

const EmptyEmbeddedSection = (props) => {
  const {
    addQuestionExpanded,
    setAddQuestionExpanded,
    handleAddQuestion,
    scrollToIdFunc,
  } = props;

  const isEmptySection = true;

  const intl = useIntl();

  return (
    <Box
      className={`${isEmptySection ? "embedded-popup-empty-section" : ""} ${addQuestionExpanded ? "expanded-add-question" : ""}`}
    >
      {isEmptySection ? (
        <Box className="embedded-popup-empty-survey">
          <Typography variant="h4" className="empty-survey-title">
            {CheckValueLocale("embedded_survey_no_questions_yet", "", {}, intl)}
          </Typography>
          <Typography variant="h6" className="empty-survey-desc">
            {CheckValueLocale(
              "embedded_survey_no_questions_desc",
              "",
              { br: <br /> },
              intl,
            )}
          </Typography>
        </Box>
      ) : null}

      <AddQuestionsToggle
        addQuestionExpanded={addQuestionExpanded}
        setAddQuestionExpanded={setAddQuestionExpanded}
        handleAddQuestion={handleAddQuestion}
        scrollToIdFunc={scrollToIdFunc}
      />
    </Box>
  );
};

export default EmptyEmbeddedSection;
