import React, { useEffect, useState } from "react";
import { Box, FormControl, MenuItem, Select } from "@mui/material";
import { dummyQuestions } from "../dummyQuestions";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import "../surveyBuilder.scss";

const QuestionTypeDropdown = (props) => {
  const {
    filteredSections,
    surveySections,
    setSurveySections,
    ssIndex,
    sqIndex,
  } = props;

  const intl = useIntl();
  const [editable, setEditable] = useState(false);
  const [questionType, setQuestionType] = useState("");

  useEffect(() => {
    if (filteredSections) {
      setEditable(
        filteredSections?.[ssIndex - 1]?.questions?.filter(
          (question) => question?.is_deleted !== true,
        )?.[sqIndex - 1]?.question?.editable,
      );
      setQuestionType(
        filteredSections?.[ssIndex - 1]?.questions?.filter(
          (question) => question?.is_deleted !== true,
        )?.[sqIndex - 1]?.question?.type,
      );
    }

    if (
      filteredSections?.[ssIndex - 1]?.questions?.filter(
        (question) => question?.is_deleted !== true,
      )?.[sqIndex - 1]?.question?.type !== questionType
    ) {
      setQuestionType(
        filteredSections?.[ssIndex - 1]?.questions?.filter(
          (question) => question?.is_deleted !== true,
        )?.[sqIndex - 1]?.question?.type,
      );
    }

    if (
      filteredSections?.length < 2 &&
      filteredSections?.[0]?.questions?.filter(
        (question) => question?.is_deleted !== true,
      )?.length < 1
    ) {
      setEditable(false);
    }
  }, [filteredSections, ssIndex, sqIndex]);

  const dropdownOptions = [
    "name",
    "multiple_choice",
    "short_text",
    "social_media",
    "phone_number",
    "dropdown",
    "email",
    "number",
    "yes_no",
    "nps",
    "scale",
  ];

  const handleChange = (event) => {
    dummyQuestions?.map((question) => {
      if (question?.question?.type === event?.target?.value) {
        const items = {};
        items.question = {};
        items.question.text = CheckValueLocale(
          question?.question?.text,
          "",
          {},
          intl,
        );
        items.question.description = question?.question?.description;
        items.question.editable = question?.question?.editable;
        items.question.type = question?.question?.type;
        items.is_deleted = question?.is_deleted;
        if (question?.is_proceed_on_answer !== undefined)
          items.is_proceed_on_answer = false;
        if (question?.is_proceed_editable !== undefined)
          items.is_proceed_editable = true;

        items.question.constraints = {};

        Object.keys(question?.question?.constraints).forEach((key) => {
          if (key === "min_number" || key === "max_number") {
            items.question.constraints[key] = {};
            items.question.constraints[key].checked =
              question?.question?.constraints?.[key]?.checked;
            items.question.constraints[key].value =
              question?.question?.constraints?.[key]?.value;
          } else {
            items.question.constraints[key] =
              question?.question?.constraints?.[key];
          }
        });

        if (
          question?.question?.items !== undefined ||
          question?.question?.items?.length >= 0
        ) {
          let arr = [];
          if (question?.question?.items?.length > 0) {
            question?.question?.items?.map((i) => {
              const items = {};
              items[Object.keys(i)[0]] = Object?.values(i)?.[0];
              arr?.push(items);
            });
          }
          items.question.items = arr;
        }
        filteredSections[ssIndex - 1].questions[sqIndex - 1] = items;
      }
    });
    setSurveySections([...surveySections]);
    setQuestionType(event?.target?.value);
  };

  return (
    <>
      <Box mx={2} mb={2}>
        <FormControl fullWidth>
          <Select
            value={questionType}
            onChange={handleChange}
            inputProps={{ "aria-label": "Without label" }}
            style={{ height: "46px" }}
            className={"questionTypeDropdown"}
            data-testid="constraints_dropdown"
            disabled={editable === false ? true : false}
          >
            {dropdownOptions?.map((option, index) => {
              return (
                <MenuItem
                  value={option}
                  id={`survey-question-type-${option ?? "option"}`}
                  key={index}
                >
                  {CheckValueLocale(option, "", {}, intl)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
    </>
  );
};

export default QuestionTypeDropdown;
