import { useQuery } from "@tanstack/react-query";
import { ReportServices } from "services/api/reports";

export const useReportRecords = ({ queryData }) => {
  return useQuery({
    queryKey: ["reportRecords", queryData?.report_id],
    queryFn: () => ReportServices.getReportRecords(queryData),
    enabled: !!queryData?.report_id,
    select: (data) => data?.data?.data,
  });
};
