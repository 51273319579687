import React, { useEffect } from "react";
import { Box, FormGroup, Switch, Typography } from "@mui/material";
import engagementTagsFunction from "../engagementTagsFunction";
import SharedTable from "shared/table/sharedTable";
import { CheckValueLocale, commasAfterDigit } from "utils/helpers";
import { useIntl } from "react-intl";
import "./tagList.scss";
import { CustomPagination } from "components/CustomPagination/CustomPagination";
import PopupModal from "components/popupModal";
import OptionDropdown from "./optionDropdown";
import CustomizedTooltip from "components/customizedTooltip";
import EngagementTags from "..";
import { useDeleteTag } from "../hooks/useDeleteTag";

const EngagementTagsList = (props) => {
  const { applyOpacity } = engagementTagsFunction();
  const { mutate: deleteTagFun, isPending: deleteLoading } = useDeleteTag();

  const {
    tableBody,
    tagsData,
    setTableBody,
    pagination,
    tableHead,
    pageNumber,
    openPopUp,
    setOpenPopUp,
    handleSwitchClick,
    handleOpenPopUp,
    selectedTag,
    setSnackBarTagsTable,
    loading,
    setPageNumber,
    enabledEdit,
    enabledDelete,
    popUpState,
    setPopUpState,
    enabledCreate,
    handleClosePopup,
    contentModalBody,
    setContentModalBody,
    setSelectedTag,
    tagsDataLoading,
    isLoadingStatus,
    tagsLimitReached,
  } = props;
  const intl = useIntl();

  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };

  const tagColors = (label, color) => {
    return (
      <Box className="tags-label-color">
        <Box
          className="tags-label"
          sx={{
            backgroundColor: color ? applyOpacity(color, 0.1) : null,
          }}
        >
          <Box
            className="tags-box-color"
            sx={{
              backgroundColor: color ? color : null,
            }}
            component={"span"}
          ></Box>
          {label}
        </Box>
      </Box>
    );
  };

  const tagStatus = (data) => {
    return (
      <CustomizedTooltip
        id="tag-status-list-tooltip"
        title={
          !enabledDelete ? (
            <Box className="item-title-tooltip">
              {CheckValueLocale("dont_have_a_permission", "", {}, intl)}
            </Box>
          ) : null
        }
        placement="bottom"
        arrow
      >
        <FormGroup id="status-switch-tags">
          <Switch
            checked={data?.is_enabled}
            disabled={!enabledDelete}
            onClick={() => handleOpenPopUp(data)}
          />
        </FormGroup>
      </CustomizedTooltip>
    );
  };
  const optionDropdownBox = (item) => {
    return (
      <OptionDropdown
        enabledEdit={enabledEdit}
        enabledDelete={enabledDelete}
        enabledCreate={enabledCreate}
        setOpenPopUp={setOpenPopUp}
        setPopUpState={setPopUpState}
        setContentModalBody={setContentModalBody}
        editTagData={item}
        tagsTableData={tagsData}
        tagsTableLength={tagsData?.engagement_tags?.length}
        setSelectedTag={setSelectedTag}
        setSnackBarTagsTable={setSnackBarTagsTable}
        tagsLimitReached={tagsLimitReached}
      />
    );
  };

  useEffect(() => {
    const tabBody =
      tagsData?.engagement_tags?.length == 0
        ? []
        : tagsData?.engagement_tags?.map((item) => {
            return {
              id: item?.id,
              data: [
                {
                  body: tagColors(item?.label, item?.color),
                },
                {
                  body: item?.created_by || "_",
                },
                {
                  body: item?.created_at || "_",
                },
                {
                  body: commasAfterDigit(item?.total_engagements) || "_",
                },
                {
                  body: tagStatus(item),
                },
                {
                  body: optionDropdownBox(item),
                },
              ],
            };
          });
    setTableBody(tabBody);
  }, [tagsData?.engagement_tags]);

  const emptyBody = () => {
    return (
      <Box className="empty-table-body">
        {CheckValueLocale("no_tags_created", "", {}, intl)}
      </Box>
    );
  };

  const modalBody = () => {
    const name = selectedTag?.label;
    return (
      <Box className="popup-tags-container">
        <Typography className="status-tag-dec" component={"h4"}>
          {CheckValueLocale(
            contentModalBody?.title,
            "",
            { tagName: name },
            intl,
          )}
        </Typography>
        <Typography className="status-tag-dec">
          {CheckValueLocale(contentModalBody?.subTitle, "", {}, intl)}
        </Typography>
        <Box className="status-tag-list">
          <ul>
            <li>{CheckValueLocale(contentModalBody?.listOne, "", {}, intl)}</li>
            <li>{CheckValueLocale(contentModalBody?.listTwo, "", {}, intl)}</li>
            {contentModalBody?.listThree ? (
              <li>
                {CheckValueLocale(contentModalBody?.listThree, "", {}, intl)}
              </li>
            ) : null}
          </ul>
        </Box>
        <Typography className="last-text">
          {CheckValueLocale(contentModalBody?.lastText, "", {}, intl)}
        </Typography>
      </Box>
    );
  };

  const handleDeleteTag = () => {
    const requestData = {
      id: selectedTag?.id,
    };
    deleteTagFun(requestData, {
      onSuccess: () => {
        handleClosePopup();
        setSnackBarTagsTable({
          open: true,
          message: "tag_deleted_tag",
          severity: "success",
        });
      },
      onError: (error) => {
        if (error?.status == 404)
          setSnackBarTagsTable({
            open: true,
            message: "tag_already_deleted",
            severity: "error",
          });
      },
      onSettled: () => {
        handleClosePopup();
      },
    });
    setTimeout(() => {
      setSnackBarTagsTable({
        open: false,
        message: "",
        severity: "",
      });
    }, 4000);
  };

  return (
    <Box className="table-tags-main-container">
      <Box className="rep-card-header">
        <Box className="tag-list-header">
          <Box className="engagement-tags-title-box">
            {CheckValueLocale("engagement_tags", "", {}, intl)}
            {pagination?.count ? (
              <Typography>({pagination?.count})</Typography>
            ) : null}
          </Box>
          <EngagementTags isSettingPage tagsLimitReached={tagsLimitReached} />
        </Box>
        <SharedTable
          className="tags-table"
          isSharedStyle
          tableHeader={tableHead}
          tableBody={tableBody || []}
          loading={tagsDataLoading}
          emptyBody={emptyBody()}
        />

        {pagination?.pages > 1 && !loading ? (
          <CustomPagination
            key={pageNumber}
            onChange={handlePageChange}
            page={pageNumber || 1}
            pagination={pagination}
          />
        ) : null}
      </Box>
      <PopupModal
        body={modalBody()}
        open={openPopUp}
        close={() => handleClosePopup()}
        title={CheckValueLocale(popUpState?.title, "", {}, intl)}
        leftBtn={CheckValueLocale(popUpState?.leftBtn, "", {}, intl)}
        rightBtn={CheckValueLocale(popUpState?.rightBtn, "", {}, intl)}
        isRightBtnLoading={
          popUpState?.status == "delete" ? deleteLoading : isLoadingStatus
        }
        accept={
          popUpState?.status == "delete" ? handleDeleteTag : handleSwitchClick
        }
        classeName={`shared-popup-tags popup-tag-${popUpState?.status}`}
      />
    </Box>
  );
};

export default EngagementTagsList;
