import { useQuery } from "@tanstack/react-query";
import AudienceController from "services/controllers/audienceController";

const useAudienceUserProfile = (profileId) => {
  return useQuery({
    queryKey: ["audienceUserProfile", profileId],
    queryFn: () => AudienceController.audienceUserProfile(profileId),
    enabled: !!profileId,
    retry: false,
  });
};

export default useAudienceUserProfile;
