import { Box } from "@mui/material";
import DisplayMediaCard from "./subComponents/displayMediaCard";
import { checkArabicText } from "utils/helpers";

const BodyArticleCard = ({ mediaUrls, tweetText }) => {
  const firstLetter = tweetText?.charAt(0);
  const isArabicMatch = checkArabicText(firstLetter);

  return (
    <Box className="body-article-card">
      {mediaUrls ? (
        <DisplayMediaCard
          mediaUrls={mediaUrls || [{}]}
          tweetText={tweetText}
          monitorDataSource="NEWSBLOGS"
        />
      ) : null}

      <Box
        className={`text-article-card ${isArabicMatch ? "text-arabic" : "text-English"}`}
      >
        {tweetText}
      </Box>
    </Box>
  );
};

export default BodyArticleCard;
