import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
} from "@mui/material";
import ChartDetailsWidget from "../customStatisctics/chartDetailsWidget";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import ItemsList from "./itemsList.js";
import WordsList from "./wordsList";
import WordCloud from "components/echarts/WordCloud";
import CustomizedTooltip from "components/customizedTooltip";

export const TableWordCloudHiddenView = (props) => {
  const intl = useIntl();
  return (
    <Box
      id={props?.hiddenChartId}
      className="table-word-cloud-hidden-wrapper-container"
    >
      <TableContainer>
        {props.showChartDetailsWidget && (
          <ChartDetailsWidget statsDetailsData={props.statsDetailsData} />
        )}
        <Table className="tablemargin">
          <TableHead className="border-none">
            <TableRow>
              <TableCell
                className={`ar-postuser-heading1 ${
                  props.wordsList && "table-cell-keyword"
                }`}
              >
                {CheckValueLocale(props?.leftColomnTitle, "", {}, intl)}
              </TableCell>
              <TableCell
                className={`ar-postuser-heading1 ${
                  props.wordsList && "table-cell-keyword"
                }`}
              >
                {CheckValueLocale(props?.rightColomnTitle, "", {}, intl)}
              </TableCell>
              {props?.thirdColomnTitle ? (
                <TableCell
                  className={`ar-postuser-heading1 ${
                    props?.wordsList ? "table-cell-keyword" : ""
                  }`}
                >
                  {CheckValueLocale(props?.thirdColomnTitle, "", {}, intl)}
                </TableCell>
              ) : null}
              {props?.isXPlatform ? (
                <TableCell
                  className={`ar-postuser-heading1 ${
                    props.wordsList && "table-cell-keyword"
                  }`}
                >
                  <CustomizedTooltip
                    id="total-number-of-engagements-tooltip"
                    title={
                      <div className="tooltip-custom-new">
                        {CheckValueLocale(
                          "total_number_of_engagements_tooltip",
                          "",
                          {},
                          intl,
                        )}
                      </div>
                    }
                    arrow
                    placement="top"
                  >
                    <Typography
                      variant={"h7"}
                      className="widget-title-table-header"
                    >
                      {CheckValueLocale(props?.secondaryTitle, "", {}, intl)}
                    </Typography>
                  </CustomizedTooltip>
                </TableCell>
              ) : null}
            </TableRow>
          </TableHead>
          {/*  */}
          {props?.itemsList && (
            <TableBody>
              {props?.data?.map((row, index) => (
                <>
                  <ItemsList
                    unixDate={props?.unixDate}
                    showProfile={props?.showProfile}
                    monitorId={props?.monitorId}
                    productId={props?.productId}
                    circularPP={props?.circularPP}
                    linkedInPage={props?.linkedInPage}
                    key={index}
                    totalValue={
                      row?.followers_count && props?.isXPlatform
                        ? row?.followers_count
                        : row?.value
                          ? row?.value
                          : row?.stats_count
                    }
                    isEngagInbox={props?.isEngagInbox}
                    totalValueEng={
                      row?.value !== undefined ? row?.total_engagements : null
                    }
                    isXPlatform={props?.isXPlatform}
                    row={row}
                    isTiktok={props?.isTiktok}
                    iconBeforeItemTitle={props?.iconBeforeItemTitle}
                    monitorDataSource={props?.monitorDataSource}
                  />
                </>
              ))}
            </TableBody>
          )}
          {/* Case of words list which includes words _________________________________________________________ */}
          {props?.wordsList && (
            <TableBody>
              {props?.data?.map((row) => (
                <WordsList
                  row={row}
                  activeTab={props?.activeTab}
                  monitorDataSource={props?.monitorDataSource}
                  showAtIcon={props.showAtIcon} // showAtIcon is to display '@' icon
                  socialKeywordName={props.socialKeywordName} // to handle name of keyword in social --> CA
                  //this props used for handle QuickInspect
                  handleOpenQuickInspect={props.handleOpenQuickInspect}
                  inspectorName={props.inspectorName}
                  dynamicClass={props.dynamicClass}
                  dataSource={props.dataSource}
                  iconBeforeItemTitle={props?.iconBeforeItemTitle}
                  isGensys={props?.isGensys}
                  isCustomDashboard={props?.isCustomDashboard}
                  links={props?.links}
                />
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <WordCloud
        data={props?.data}
        inspectorName={props?.inspectorName}
        handleOpenQuickInspect={props?.handleOpenQuickInspect}
      />
    </Box>
  );
};
