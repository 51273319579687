import { Box } from "@mui/material";
import DefaultUserAvtar from "images/shared-images/user-management-default.svg";
import PopupModal from "components/popupModal";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { useFormik } from "formik";
import AiAgentsFields from "../aiAgents/component/aiAgentsContent/aiAgentsFormContent/component/aiAgentsFields";
import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";
import { useTeamList } from "../aiAgents/aiAgentsHooks/useTeamList";
import { useTeamUsersList } from "../aiAgents/aiAgentsHooks/useTeamUsersList";
import { AssignHumanValidationSchema } from "../aiAgents/aiAgentsValidation/assignHumanValidationSchema";
import { useAssignTeamsAndUsers } from "../aiAgents/aiAgentsHooks/useAssignTeamsAndUsers";
import { useEffect, useState } from "react";

const AssignHumanAiAgentModal = ({
  selectedCard,
  openPopupAssignHuman,
  setOpenPopupAssignHuman,
  setOpenSnackBarAiAgent,
  setSnackBarDataAiAgent,
  setInjectedMessage,
  aiAgentDetails,
  setRefresh,
  chatData,
}) => {
  const intl = useIntl();
  const [isTeamLoading, setIsTeamLoading] = useState(false);
  const {
    data: teamList,
    isLoading: teamListLoading,
    refetch: refetchTeamList,
    isRefetching: isRefetchTeamList,
  } = useTeamList(true);

  const onOpenTeamDropdown = () => {
    setIsTeamLoading(true);
    refetchTeamList();
  };

  useEffect(() => {
    if (teamList && !teamListLoading && !isRefetchTeamList) {
      setIsTeamLoading(false);
    }
  }, [teamList, teamListLoading, isRefetchTeamList]);

  const { mutate: assignTeamsAndUsers, isPending: assignTeamsAndUsersLoading } =
    useAssignTeamsAndUsers();

  const assignHumanInitialValues = {
    new_user_team_id: aiAgentDetails?.team_id || null,
    new_user_id: null,
  };

  const submitAssignHuman = (values) => {
    let chatDataCopy = [...chatData];
    let lastMsg =
      chatDataCopy
        ?.reverse()
        ?.find((item) => item?.direction === "inbound" && item?.content?.text)
        ?.content?.text || chatDataCopy?.reverse()?.[0]?.content?.text;
    let queryData = {
      new_user_id: values?.new_user_id,
      old_user_id: selectedCard?.assigning_info?.user_id,
      new_user_team_id: values?.new_user_team_id,
      old_user_team_id: selectedCard?.assigning_info?.team_id,
      interaction_id: selectedCard?.conversation_id,
      monitor_id: selectedCard?.tracker_id,
      data_source_name: selectedCard?.data_source,
      product_id: +window.localStorage.getItem("engagementsProductId"),
      message: {
        text: lastMsg || "",
        user: selectedCard?.client_name,
        url: selectedCard?.client_profile_image_url,
      },
      from_ai_agent: true,
      ai_agent_id: selectedCard?.ai_agent?.ai_agent_id || null,
    };
    assignTeamsAndUsers(queryData, {
      onSuccess: () => {
        setInjectedMessage({
          message: {},
          title: {},
        });
        closeModal();
        setRefresh();
        setOpenSnackBarAiAgent(true);
        setInjectedMessage({ message: { name: aiAgentDetails?.name } });
        setSnackBarDataAiAgent({
          severity: "success",
          message: "assign_human_success_message",
        });
      },
      onError: () => {
        setOpenSnackBarAiAgent(true);
        setSnackBarDataAiAgent({
          severity: "error",
          title: "something_wrong",
          message: "snack_assigned_user_error",
        });
      },
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: assignHumanInitialValues,
    validationSchema: AssignHumanValidationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => submitAssignHuman(values),
  });

  const queryData = {
    id: formik?.values?.new_user_team_id || aiAgentDetails?.team_id,
    product_id: window.localStorage.getItem("engagementsProductId"),
  };

  const teamOptionsList =
    teamList?.map((item) => {
      return item?.id;
    }) || [];

  const teamOptionsListObj =
    teamList?.map((item) => {
      return {
        name: item?.name,
        id: item?.id,
        user_teams_count: item?.user_teams_count,
      };
    }) || [];

  const { data: teamUsersList, isLoading: isTeamUsersListLoading } =
    useTeamUsersList(queryData);

  const teamUsersOptionsList =
    teamUsersList?.map((item) => {
      return item?.attributes?.id;
    }) || [];

  const teamUsersOptionsListObj =
    teamUsersList?.map((item) => {
      return {
        id: item?.attributes?.id,
        name: item?.attributes?.name,
        avatar: item?.attributes?.avatar,
        availability_status: item?.attributes?.availability_status,
      };
    }) || [];

  const closeModal = () => {
    setOpenPopupAssignHuman(null);
    formik?.resetForm();
  };

  const onClickAddNewTeam = () => {
    window.open("/settings/teams/add", "_blank");
  };

  const onClickAddNewUser = () => {
    window.open("/settings/user_managements/add", "_blank");
  };

  let modalBody = (
    <Box>
      <Box component="p" className="ai-agent-modal-text">
        {CheckValueLocale("auto_assign_modal_text", "", {}, intl)}
        <Box component="span">
          {CheckValueLocale("auto_assign_modal_hint", "", {}, intl)}
        </Box>
      </Box>
      <AiAgentsFields
        type="autoComplete"
        id="engagment-team-human-select"
        name="new_user_team_id"
        value={formik?.values?.new_user_team_id}
        optionsList={teamOptionsList?.filter(
          (id) => formik?.values?.new_user_team_id !== id,
        )}
        optionsListObj={teamOptionsListObj}
        handleChange={(e, value) => {
          formik?.setFieldValue("new_user_id", null);
          formik?.setFieldValue("new_user_team_id", value);
        }}
        error={!!formik.errors?.new_user_team_id}
        onBlur={formik.handleBlur}
        helperText={CheckValueLocale(
          formik.errors?.new_user_team_id,
          "",
          {},
          intl,
        )}
        label={CheckValueLocale("assign_team", "", {}, intl)}
        placeholder={CheckValueLocale("select", "", {}, intl)}
        emptyListMessage={CheckValueLocale("no_result", "", {}, intl)}
        customOptionLabel={CheckValueLocale(
          "routing_add_new_team",
          "",
          {},
          intl,
        )}
        onCustomOptionClick={onClickAddNewTeam}
        icon={faExternalLink}
        isLoading={isTeamLoading}
        onOpen={onOpenTeamDropdown}
        required
        renderOptionContent={(option) => {
          const optionObj = teamOptionsListObj?.find(
            (item) => item?.id == option,
          );
          return (
            <Box className={`assign-item-countainer-sla-sidebar`}>
              <Box className="assign-item-details">
                <Box>{optionObj?.name}</Box>
                <Box className="assign-item-state team-assign-item">
                  {optionObj?.user_teams_count ? <Box></Box> : null}
                  {CheckValueLocale(
                    optionObj?.user_teams_count
                      ? "users_num"
                      : "users_unavailable",
                    "",
                    { num: optionObj?.user_teams_count },
                    intl,
                  )}
                </Box>
              </Box>
            </Box>
          );
        }}
      />
      <AiAgentsFields
        type="autoComplete"
        id="engagment-user-human-select"
        name="new_user_id"
        value={formik?.values?.new_user_id}
        optionsList={
          isTeamUsersListLoading
            ? []
            : teamUsersOptionsList?.filter(
                (id) => formik?.values?.new_user_id !== id,
              )
        }
        optionsListObj={isTeamUsersListLoading ? [] : teamUsersOptionsListObj}
        handleChange={(e, value) => formik?.setFieldValue("new_user_id", value)}
        error={!!formik.errors?.new_user_id}
        onBlur={formik.handleBlur}
        helperText={CheckValueLocale(formik.errors?.new_user_id, "", {}, intl)}
        label={CheckValueLocale("assign_user", "", {}, intl)}
        placeholder={CheckValueLocale("select", "", {}, intl)}
        emptyListMessage={CheckValueLocale("no_result", "", {}, intl)}
        customOptionLabel={CheckValueLocale("add_new_user", "", {}, intl)}
        onCustomOptionClick={onClickAddNewUser}
        icon={faExternalLink}
        isLoading={isTeamUsersListLoading}
        required
        renderOptionContent={(option) => {
          const optionObj = teamUsersOptionsListObj?.find(
            (item) => item?.id == option,
          );
          return (
            <Box className={`assign-item-countainer-sla-sidebar`}>
              <Box className="assign-item-profile">
                <img
                  src={optionObj?.avatar || DefaultUserAvtar}
                  onError={(e) => {
                    e.target.src = DefaultUserAvtar;
                  }}
                />
                <Box
                  className={
                    optionObj?.availability_status !== "unavailable"
                      ? "available"
                      : "unavailable"
                  }
                ></Box>
              </Box>
              <Box className="assign-item-details">
                <Box>{optionObj?.name}</Box>
                <Box className="assign-item-state">
                  {CheckValueLocale(
                    optionObj?.availability_status !== "unavailable"
                      ? "available"
                      : "unavailable",
                    "",
                    {},
                    intl,
                  )}
                </Box>
              </Box>
            </Box>
          );
        }}
      />
    </Box>
  );

  return (
    <PopupModal
      title={CheckValueLocale("assign_to_human_agent", "", {}, intl)}
      body={modalBody}
      leftBtn={CheckValueLocale("cancel", "", {}, intl)}
      rightBtn={CheckValueLocale("assign_button", "", {}, intl)}
      disabled={assignTeamsAndUsersLoading}
      isRightBtnLoading={assignTeamsAndUsersLoading}
      open={openPopupAssignHuman || false}
      close={!assignTeamsAndUsersLoading ? closeModal : null}
      accept={() => formik?.submitForm()}
      dialogContentId="pause-ai-agent"
    />
  );
};
export default AssignHumanAiAgentModal;
