import { Box, Typography } from "@mui/material";
import Profile from "./component/profile";
import Category from "./component/category";
import Themes from "./component/themes";
import UserSegments from "./component/userSegments";
import SocialMedia from "./component/socialMedia";
import Cdp from "./component/cdp";
import Anonymous from "./component/anonymous";
import "./profileSideBar.scss";
import ProfileSideBarLoading from "./profileSideBarLoading";
import { ReactComponent as EmptySidebarProfile } from "images/engagements-images/empty-sidebar-profile.svg";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import UserInfo from "./component/userInfo";
import { EditProvider } from "./context";

const ProfileSideBar = ({
  selectedCard,
  fetchSideBar,
  profileSideBarData,
  showTotalEngagementsHandler,
  callSingleinteraction,
  setStats,
}) => {
  const intl = useIntl();
  const sideBarProfileData = profileSideBarData?.profile_data;
  const sideBarSocialMediaData = sideBarProfileData?.social_media;

  const activeCdp = JSON.parse(window.localStorage?.activeProducts || null)
    ?.filter((product) => product?.active && product?.name == "CDP");

  if (selectedCard?.data_source?.toLowerCase() === "whatsapp")
    return (
      <Box className="details-side-bar-content-empty">
        <EmptySidebarProfile />
        <Typography className="details-side-bar-content-empty-text">
          {CheckValueLocale("empty_sidebar_whatsapp_text", "", {}, intl)}
        </Typography>
      </Box>
    );

  const hasCDPSubscription =
    activeCdp?.length && profileSideBarData?.is_cdp_data;

  return !fetchSideBar ? (
    <EditProvider
      callSingleinteraction={callSingleinteraction}
      userId={sideBarProfileData?.user_data?.profile_id}
      hasCDPSubscription={hasCDPSubscription}
      setStats={setStats}
    >
      {sideBarProfileData?.length !== 0 ? (
        <Box className="details-side-bar-content">
          <Profile
            profileData={sideBarProfileData?.user_data}
            selectedCard={selectedCard}
            showTotalEngagementsHandler={showTotalEngagementsHandler}
          />
          <UserInfo
            profileData={sideBarProfileData}
            selectedCard={selectedCard}
          />
          <Category categoryData={sideBarProfileData?.themes} />
          <Themes themesData={sideBarProfileData?.sub_themes} />
          <UserSegments userSegmentsData={sideBarProfileData?.segments} />
          <SocialMedia socialMediaData={sideBarSocialMediaData} />
          {!hasCDPSubscription ? <Cdp /> : null}
        </Box>
      ) : (
        <Anonymous />
      )}
    </EditProvider>
  ) : (
    <ProfileSideBarLoading />
  );
};
export default ProfileSideBar;
