import { useMutation } from "@tanstack/react-query";
import { FiltersServices } from "services/api/filterServices";

export const useCreateSavedFilter = () => {
  return useMutation({
    mutationFn: ({
      monitorId,
      productId,
      sectionName,
      filterName,
      filterParams,
      dataSourceId,
      startDate,
      endDate,
    }) => {
      const queryData = {
        monitor_id: monitorId,
        product_id: productId,
        section_name: sectionName,
        filter_name: filterName,
        filter_params: filterParams,
        data_source_id: dataSourceId,
        startDate,
        endDate,
      };
      return FiltersServices.createSavedFilter(queryData);
    },
  });
};
